import React from "react";
import { Dropdown, Modal, Button, Spinner } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { FiChevronDown } from "react-icons/fi";
import FilterModal from "./Components/FilterModal";
import { IChangeLogsProps } from "../../Contracts/IChangeLog";
import { ReactPageLoader } from "../ReactPageLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";

export const ChangeLogs = (props: IChangeLogsProps) => {
  const {
    title,
    hotelName,
    startDate,
    endDate,
    handleDatesChange,
    tableColumns,
    filteredData,
    showChangeLogModal,
    showHideChangeLogModal,
    keyFieldName,
    downloadChangeLogData,
    updateFilters,
    editFilter,
    onFilterTextChange,
    onFilterChange,
    removeFilter,
    hideFilterPopUp,
    clearFilters,
    filterColumns,
    filterPopUps,
    showFilterPopUp,
    currentFilter,
    popClassName,
    isDrillDownView,
    tableClassName,
    hideCrossAndClearFilters,
    isDataLoading,
    validateDates,
    onPaginationchange,
    pageSize,
    currentPageNo,
    onFilterDropDownChange,
    totalDataLength,
    reactTableClassName,
    accType,
    loader,
    showPerviousLogBanner, 
  } = props;

  // const {loader} = state;

  const handleViewPastChanges = () => {
    props.showHideChangeLogModal("View Past Changes");
  };

  return (
    <div>
      <Modal
        className={`${
          props.accType === "VendorHistorical" ? "vhChangeLogModal" : ""
        } change-log-modal ${
          title === "Users History" ? "change-log-users-history" : ""
        }
          ${title === "Payroll Deduction Settings History" || title === "Users History" || title === "User History" ? "set-filter-items-width" : ""}
        `}
        show={showChangeLogModal}
        onHide={showHideChangeLogModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        keyboard={false}
      >
        <div className="add-changelog-filter">
          {showFilterPopUp && popClassName === "add-new-filter-popup" && (
            <FilterModal
              key={popClassName}
              onFilterTextChange={onFilterTextChange}
              popClassName={popClassName}
              hideFilterPopUp={hideFilterPopUp}
              onApplyFilter={updateFilters}
              showFilterPopUp={showFilterPopUp}
              handleDatesChange={handleDatesChange}
              startDate={startDate}
              endDate={endDate}
              onFilterDropDownChange={onFilterDropDownChange as any}
              dropDownProps={currentFilter.dropDownProps}
              validateDates={validateDates}
              {...currentFilter}
            />
          )}
        </div>
        <Modal.Header closeButton>
          {hotelName && (
            <div className="hid-set d-flex align-items-center">{hotelName}</div>
          )}
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {(props?.accType === "vendorlog" || showPerviousLogBanner) && (
            <div className="notificationBannersPay blue-bg">
              <div className="ml-auto">
                <span className="bold d-flex align-items-center">
                  To view change log history prior to {props?.accReleaseDate},
                  Please click
                  <div className="hasOTPLink d-flex align-items-center">
                    <Button
                      className="pt-0 pb-0 btn-ghost pl-2 pr-2"
                      onClick={() => handleViewPastChanges()}
                    >
                      View
                    </Button>
                  </div>
                </span>
              </div>
              {/* <div className="d-flex hasOTPLink">
                <strong>Please click</strong>
                <Button
                  // disabled={isDataLoading}
                  className="btn-ghost pl-2 pr-2" onClick={() => handleViewPastChanges()}>View</Button>
              </div> */}
            </div>
          )}
          <div className="navigation-menus d-flex">
            {filterColumns?.length > 0 && !isDrillDownView && (
              <div
                className={`add-filters ${
                  showFilterPopUp && popClassName === "add-new-filter-popup"
                    ? "add-filters-blue"
                    : ""
                }`}
              >
                <Dropdown
                  onClick={() => hideFilterPopUp()}
                  className="more-action bg-blue "
                >
                  <Dropdown.Toggle id="dropdown-ptoType">
                    <div className="d-flex align-items-center">
                      <svg
                        width="14"
                        height="8"
                        viewBox="0 0 14 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M2.625 3.2H11.375V4.8H2.625V3.2ZM0 0H14V1.6H0V0ZM5.25 6.4H8.75V8H5.25V6.4Z" />
                      </svg>

                      {"Add Filter"}
                      <div className="drop-arrow d-flex">
                        <FiChevronDown />
                      </div>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div className="item-list-title d-flex">
                      <div className="title mr-auto">Select Filter Type</div>
                      {/* <button className="close">&times;</button> */}
                    </div>
                    {filterColumns.map((item: any, idx: any) => {
                      return item?.isFilterApplied ? (
                        <Dropdown.Item
                          key={item?.key}
                          eventKey={item?.key}
                          className="disabled"
                        >
                          {item?.text}
                        </Dropdown.Item>
                      ) : (
                        <Dropdown.Item
                          key={item?.key}
                          eventKey={item?.key}
                          onClick={(e: any) =>
                            onFilterChange(item, "add-new-filter-popup")
                          }
                        >
                          {item?.text}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
            <div className="added-filter-grp d-inline-flex mr-auto">
              {filterPopUps?.length > 0 &&
                filterPopUps.map((filter: any, index) => {
                  return (
                    <div className="">
                      <div
                        className={`active-filter d-flex ${
                          showFilterPopUp &&
                          popClassName === "filter-edit-popup-" + index
                            ? "filter-blue-" + index
                            : ""
                        }`}
                      >
                        {/* <EllipsisWithTooltip placement="bottom"> */}
                        <span className="filter-name">
                          {filter?.filterName}
                        </span>{" "}
                        {/* </EllipsisWithTooltip> */}
                        {/* <EllipsisWithTooltip placement="bottom"> */}
                        {!isDrillDownView ? (
                          <div
                            className={`filter-value ${
                              filter?.filterName.toLowerCase() == "user name"
                                ? "set-width"
                                : ""
                            }`}
                            onClick={() =>
                              editFilter(filter, filter?.popClassName)
                            }
                          >
                            <EllipsisWithTooltip placement="bottom">
                              {filter?.filterValue}
                            </EllipsisWithTooltip>
                          </div>
                        ) : (
                          <div
                            className={`filter-value ${
                              filter?.filterName.toLowerCase() == "user name"
                                ? "set-width"
                                : ""
                            }`}
                          >
                            <EllipsisWithTooltip placement="bottom">
                              {filter?.filterValue}
                            </EllipsisWithTooltip>
                          </div>
                        )}{" "}
                        {/* </EllipsisWithTooltip> */}
                        {!hideCrossAndClearFilters &&
                          !filter?.hideClearAndCancelBtn && (
                            <span
                              className="filter-close"
                              onClick={() => removeFilter(filter?.filterName)}
                            >
                              &times;
                            </span>
                          )}
                      </div>
                      <div>
                        {showFilterPopUp &&
                          popClassName === "filter-edit-popup-" + index && (
                            <FilterModal
                              key={popClassName}
                              onFilterTextChange={onFilterTextChange}
                              popClassName={popClassName}
                              hideFilterPopUp={hideFilterPopUp}
                              onApplyFilter={updateFilters}
                              showFilterPopUp={showFilterPopUp}
                              handleDatesChange={handleDatesChange}
                              startDate={startDate}
                              endDate={endDate}
                              validateDates={validateDates}
                              onFilterDropDownChange={
                                onFilterDropDownChange as any
                              }
                              dropDownProps={currentFilter.dropDownProps}
                              hideCrossAndClearFilters={
                                hideCrossAndClearFilters ||
                                filter?.hideClearAndCancelBtn
                              }
                              removeFilter={() =>
                                removeFilter(filter?.filterName)
                              }
                              {...currentFilter}
                            />
                          )}
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="d-flex">
              {!hideCrossAndClearFilters &&
                filterPopUps.filter((x) => !x?.hideClearAndCancelBtn)?.length >
                  0 && (
                  <button
                    className="btn btn-ghost"
                    onClick={() => clearFilters()}
                    style={{ width: "120px" }}
                  >
                    Clear Filters
                  </button>
                )}
              <Button
                onClick={() => downloadChangeLogData()}
                className="d-flex align-items-center btn-ghost ml-auto"
                // style={{ width: "165px" }}
                disabled={loader || isDataLoading}
              >
                Export to Excel
                {loader && (
                  <Spinner className="ml-1" size="sm" animation="border" />
                )}
              </Button>
            </div>
          </div>

          {isDataLoading && currentPageNo === 1 ? (
            <>
              {props.accType === "VendorHistorical" ? (
                <ReactPageLoader useas={"vendorHistoryChangeLog"} />
              ) : (
                <ReactPageLoader useas={"changeLog"} />
              )}
            </>
          ) : (
            <div className={tableClassName}>
              {tableColumns.length > 0 && (
                <div
                  className={`${
                    props?.accType === "vendorlog" ? "hasTopBanners" : ""
                  } ${
                    props.accType === "VendorHistorical"
                      ? "vhChangeLogModalDiv"
                      : ""
                  } scrollableDiv`}
                  id="scrollableDiv"
                >
                  {/* <InfiniteScroll
                    dataLength={pageSize * currentPageNo}
                    next={() => onPaginationchange()}
                    scrollThreshold="0px"
                    pullDownToRefreshThreshold={50}
                    hasMore={totalDataLength > pageSize * currentPageNo}
                    loader={
                      isDataLoading ? (
                        <ReactPageLoader useas={"changeLogRows"} />
                      ) : (
                        ""
                      )
                    }
                    scrollableTarget="scrollableDiv"
                  > */}

                  {isDataLoading ? (
                    <ReactPageLoader useas={"changeLogRows"} />
                  ) : (
                    <div className={reactTableClassName || ""}>
                      <BootstrapTable
                        keyField={keyFieldName}
                        id={"changeLogData"}
                        columns={tableColumns}
                        data={filteredData}
                      />
                    </div>
                  )}

                  {/* </InfiniteScroll> */}
                  {totalDataLength > pageSize * currentPageNo &&
                    !isDataLoading && (
                      <div className="changelog-loader">
                        <Button
                          className="btn btn-ghost"
                          onClick={() => onPaginationchange()}
                        >
                          Load More...
                        </Button>
                      </div>
                    )}
                </div>
              )}
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};
