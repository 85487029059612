import React from "react";
import { Container, Dropdown, Modal, Button, Form, OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import Dropzone from 'react-dropzone';
import { Utils } from "../../../Common/Utilis";
import { ReconcileService as reconcileService } from "../../../Common/Services/ReconcileService";
import { Register as registersevice } from "../../../Common/Services/Register";
import { toast } from "react-toastify";
import { resolve, reject } from "q";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PendingReconcile } from "./PendingReconcile";
import _ from "lodash";
import { faFile, faFileImage, faFileExcel, faFilePdf, faFileCsv, faFileWord } from "@fortawesome/free-solid-svg-icons";
import "./reconcile.scss";
import { confirmAlert } from "react-confirm-alert";
import { SingleSearchDropdownList } from "../../../Common/Components/SingleSearchDropdownList";
import { ReconcileWithdrawals } from "./ReconcileWithdrawals";
import { ReconcileDeposits } from "./ReconcileDeposits";
import { ReportViewer } from "../../Reports/ReportViewer";
import scrollIntoView from "scroll-into-view";
import { ReportExport } from "../../../Common/Services/ReportExport";

const noOfApis = 10;


export class ReconcileSummaryPage extends React.Component<any, any> {
  private cardDivRef: any;
  private reportDivRef: any;
  private periodSelector: any;
  private depositDataList: any;
  private selectEhid: any;
  private linkRef: any;
  constructor(props: any) {
    super(props);
    this.linkRef = React.createRef();
    this.periodSelector = React.createRef();
    this.depositDataList = React.createRef();
    this.selectEhid = React.createRef();
    this.reportDivRef = React.createRef();
    this.state = {
      isScrollDepositData: false,
      parameterSet: false,
      PendingReconcileModal: false,
      dintinctAccTypeArr: [],
      dintinctEhidArr: [],
      depositDataArr: [],
      ehidColumnArr: [],
      depositData: [],
      withdrawalData: [],
      reconcileList: [],
      files: [],
      moreActionFileUpload: [
        { name: "Delete", eventKey: "delete" },
        { name: "Download", eventKey: "download" }
      ],
      pendingDepositBal: 0,
      pendingWithdrawalBal: 0,
      accountBal: 0,
      statementEndBal: 0,
      statementEndBalIsEdit: false,
      statementEndBalCopy: 0,
      status: "Unreconciled",
      accountNames: [],
      accountNamelist: [],
      accountName: "",
      selectedOprId: 0,
      accPerList: [],
      accPerDisplay: "",
      accPerReq: "",
      hotelId: 0,
      fileSize: 5242880,
      isSaveChanges: false,
      redirectFrom: "",
      activeAlertBox: "",
      PendingReconcileType: "",
      isReconcileWithdrawal: false,
      isReconcileDeposit: false,
      isExportReconcileReport: false,
      selectedAcctType: "",
      apisCalledCount: 0,
      stopLoader: false,
      mainPageHidletterCode: "",
      isInactiveAccount: false,
      futureReconciledBal: 0,
      reconcilePermissionArray: [],
      isModelReconcileAll: false,
      isModelGroupDepositMappings: false,
      isAddAnotherGroupModal: false,
      reconcileType: [],
      otherDepositDataArr: [],
      isFileUploading: false,
      selectedAccountPeriod: {},
      ehidList: [],
      groupHid: "",
      groupHidVal: "",
      isGroup: false,
      oprIDFromRegister: 0,
      letterCodeString: "",
      groupMappings: [],
      groupMappingAccountTypes: [],
      groupMappingAccountTypesCopy: [],
      groupMappingsEHID: [],
      isEditGroupMapping: false,
      selectedGroup: {auditId: 0, auditName: "", displayGroupName: "", displayName: "", groupID: 0, groupName: "", hid: -1, id: 0, lettercode: "", otherGroup: ""},
      checkedGroupList: [],
      isSaveorEditGroupClicked: false,
    };
    this.onDrop = this.onDrop.bind(this);
  }

  componentDidMount() {
    let registerRequest = { hotelID: -1, lettercode: '', oprID: -1, selectType: "", isDepositHide: "", startDate: "", endDate: "", acctType: "" };

    const location = this.props.location;
    if (location.state !== undefined) {
      const stateLength = Object.keys(location.state).length;
      if (stateLength > 0) {
        // pageLoadDate
        let { oprID, Accper, fromPage, activeAlertBox, letterCode } = location.state;
        let hotelId = -1;
        let accPerDisplay = Accper === undefined || Accper === '' ? "" : Accper;
        registerRequest.hotelID = hotelId;
        registerRequest.oprID = oprID;
        this.setState({ hotelId, selectedOprId: oprID, oprIDFromRegister: oprID, accPerDisplay, redirectFrom: fromPage, activeAlertBox, mainPageHidletterCode: letterCode }, () => {
          this.getReconcilePermission(registerRequest);
          this.getReconcileTypeList(registerRequest);
          this.getFileSize();
          this.getEhidList();

        })
      }
    }
  }

  GetReconcileExistingDepositGroup = () => {
    let request: any = {};
    request.OprID = this.state.selectedOprId;
    request.AcctType = this.state.selectedAcctType;
    reconcileService.GetReconcileExistingDepositGroup(request)
      .then(async (result: any | null) => {
        if (result.length !== null) {
          let groupMappingsEHID: any = [];
          groupMappingsEHID = result.map(data => data.lettercode)
            .filter((value, index, self) => self.indexOf(value) === index);
          this.setState({
            groupMappings: result,
            groupMappingsEHID
          })
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  }

  GetReconcileDepositGroup = () => {
    let request: any = {};
    request.OprID = this.state.selectedOprId;
    request.AcctType = this.state.selectedAcctType;
    reconcileService.GetReconcileDepositGroup(request)
      .then(async (result: any | null) => {
        if (result.length !== null) {
          this.setState({
            groupMappingAccountTypes: result,
            groupMappingAccountTypesCopy: result,
          })
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  }

  GetLettercode = () => {
    let request: any = {};
    request.hid = -1;
    request.OprId = this.state.selectedOprId;
    request.AcctType = this.state.selectedAcctType;
    reconcileService.GetSplitLettercode(request)
      .then(async (result: any | null) => {
        if (Object.keys(result).length > 0) {
          this.setState({
            letterCodeString: result.lettercode
          })
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  }

  getEhidList = () => {
    this.setState({ ehidList: [], groupHid: "", groupHidVal: "", isGroup: false })
    let request: any = {};
    request.Type = "Opr";
    request.OprId = this.state.selectedOprId;
    reconcileService.GetHotelsForAddAdjustment(request)
      .then(async (result: any | null) => {
        if (result.length > 0) {
          this.setState({
            ehidList: result
          })
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  }

  getDepositList = (hid) => {
    let dtArr = this.state.accPerDisplay.toString().trim().split("/");
    let accPer = dtArr[0] + "/01/" + dtArr[1];
    this.setState({ reconcileList: [] })
    let request: any = {};
    request.Type = "Opr";
    request.OprId = this.state.selectedOprId;
    request.Accper = accPer;
    request.hid = hid;
    reconcileService.GetGroupReconcileList(request)
      .then(async (result: any | null) => {
        if (result.length > 0) {
          result.map((items) => ((items["isChecked"] = false)));
          this.setState({ reconcileList: result, stopLoader: false })
        }
        resolve();
      })
      .catch((error) => {
        reject();
        this.setState({ stopLoader: false })
      });
  }

  onReconcileHid = (e, item) => {
    const ehidSelector = this.selectEhid.current;
    ehidSelector.click();
    this.setState({ groupHidVal: item.hotelId, groupHid: item.letterCode, stopLoader: true }, () => {
      this.getDepositList(item.hotelId);
    })
  }

  onChangeGroupReconcile = (item, e) => {
    let reconcileList = [...this.state.reconcileList];
    let index = reconcileList.indexOf(item);
    reconcileList[index].isChecked = e.target.checked;
    this.setState({ reconcileList })
  }

  resetData = () => {
    if (this.state.ehidList.length === 1) {
      this.setState({ groupHidVal: this.state.ehidList[0].hotelId, groupHid: this.state.ehidList[0].letterCode }, () => {
        this.getDepositList(this.state.ehidList[0].hotelId);
      })
    } else {
      this.setState({ groupHidVal: "", groupHid: "" })
    }
    this.setState({ reconcileList: [] })
  }

  reconcileGroup = () => {
    if (this.state.groupHid === "") {
      Utils.toastError("Please select ehid.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    }
    let selectedDeposits = this.state.reconcileList.filter(e => e.isChecked)
    if (selectedDeposits.length === 0) {
      Utils.toastError("Please check atleast one deposit.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    }
    let pageType = "";
    let AccountType = "";
    selectedDeposits.forEach((element: any) => {
      pageType += element.type1 + ", ";
      AccountType += element.type + ",";
    });
    pageType = pageType.replace(/,(\s+)?$/, "");
    AccountType = AccountType.replace(/,(\s+)?$/, "");
    this.setState({ pageType, ehid: this.state.groupHidVal, hotelName: this.state.groupHid, AccountType, isGroup: true }, () => {
      this.setState({ isReconcileDeposit: true })
    })
  }

  getReconcileTypeList = (registerRequest) => {
    reconcileService.getReconcileTypeList(registerRequest)
      .then(async (result: any | null) => {
        if (result != null) {
          this.setState({ reconcileType: result })
        }
        else {
          Utils.toastError("Something went wrong.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        resolve();
      })
      .catch((error) => {
        Utils.toastError(`Server Error: ${error}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  }

  getReconcilePermission = (registerRequest) => {
    reconcileService.GetReconcilePermission()
      .then(async (result: any | null) => {
        if (result != null) {
          this.setState({ reconcilePermissionArray: result }, () => {
            this.getRegisterNames(registerRequest);
          });
        }
        else {
          // Do this
        }
        resolve();
      })
      .catch((error) => {
        Utils.toastError(`Server Error: ${error}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  }

  getFileSize() {
    registersevice.GetFileSize()
      .then(async (result: any | null) => {
        if (result > 0) {
          this.setState({ fileSize: result })
        }
      });
  }

  onFileChange = (event: any, mode) => {
    let newfiles: any = [];
    if (event.target.files.length > 0) {
      for (let i = 0; i < event.target.files.length; i++) {
        let fileRow = event.target.files[i];
        newfiles.push(fileRow)
      }
      this.onDrop(newfiles);
      let fileList = document.getElementById('formControlsFile') as any
      fileList.value = "";
    }
  };

  onDrop = (filelist: any) => {
    let size = this.state.fileSize;//5242880//5MB
    let filesizeval = Number(Number(this.state.fileSize) / (1024 * 1024));
    let updatedFiles: any = this.state.files;
    let newfiles: any = [];
    let isReturn = true;
    let filestext = "";

    filelist.forEach(element => {
      if (element.size > size) {
        filestext = filestext + element.name + ","
        isReturn = false;
      }
    })

    if (!isReturn) {
      let msg = "One or more files are greater than " + filesizeval + "MB.";
      Utils.toastError(msg, {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "",
      });
    }
    let isInvalidFile = false;

    filelist.forEach(element => {
      if ((element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'pdf'
        || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'doc'
        || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'docx'
        || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'xls'
        || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'xlsx'
        || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'csv'
        || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'txt'
        || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'bmp'
        || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'tif'
        || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'ppt'
        || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'pptx'
        || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'rtf'
        || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'jpg'
        || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'jpeg'
        || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'png'
        || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'msg'
        || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'eml'
      ) && element.size <= size) {
        newfiles.push(element)
      } else {
        isInvalidFile = true;
      }
    });
    if (isInvalidFile && isReturn) {
      let msg = "";
      if (filelist.length === 1) {
        msg = "This file type is not supported."
      } else {
        msg = "Some files are not supported."
      }
      Utils.toastError(msg, {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "",
      });
    }
    newfiles = _.sortBy(newfiles, "name");
    updatedFiles.unshift(...newfiles);
    this.setState({ files: updatedFiles, }, () => {
      if (newfiles.length > 0) {
        this.SaveTempWithFile(newfiles)
      }
    })
  }

  SaveTempWithFile(files: any) {
    this.setState({ isFileUploading: true });
    let request: any = {};
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    let dtArr = this.state.accPerDisplay.toString().trim().split("/");
    let accPer = dtArr[0] + "/01/" + dtArr[1];

    request.TenantID = tenantID;
    request.actionType = "";
    request.iscopyAttachment = "No";
    request.TempUniqueNo = "0";
    request.UniqueNo = 0;
    request.oldUniqueNo = 0;
    request.TypeFrom = "reconcilefile";
    request.OldTypeFrom = "reconcilefile";
    request.AccPer = accPer;
    request.OprIdFrom = this.state.selectedOprId;
    request.AcctType = this.state.selectedAcctType;

    registersevice.SaveTempWithFile(request, files)
      .then(async (result: any | null) => {
        if (result !== null) {
          if (result.saveStatus === "Success") {
            this.setState({ isFileUploading: false });
            this.getUploadedFileList("update");
          }
          else {
            Utils.toastError("Failed to upload attachment.", { position: toast.POSITION.BOTTOM_RIGHT });
            this.setState({ isFileUploading: false });
            this.getUploadedFileList("update");
          }
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ isFileUploading: false });
        reject();
      });
  }

  getUploadedFileList = (mtype) => {

    let dtArr = this.state.accPerDisplay.toString().trim().split("/");
    let accPer = dtArr[0] + "/01/" + dtArr[1];

    let requestlist: any = {};
    requestlist.hotelID = "0"
    requestlist.etype = "ReconcileFile";
    requestlist.uniqueNo = "0";
    requestlist.checkNo = '';
    requestlist.series = accPer;
    requestlist.oprId = this.state.selectedOprId;
    requestlist.AcctType = this.state.selectedAcctType;

    registersevice.GetUploadFileWithFileCode(requestlist)
      .then(async (result: any | null) => {
        if (mtype === "refresh") {
          this.setState({ apisCalledCount: this.state.apisCalledCount + 1 });
        }
        if (result.length > 0) {
          let filesCopy = result;
          filesCopy.map((file) => file["isOptions"] = true);
          this.setState({ files: [] }, () => {
            this.setState({ files: filesCopy });
          })


        }
        else {
          this.setState({ files: [] });
        }
        resolve();
      })
      .catch((error) => {
        if (mtype === "refresh") {
          this.setState({ apisCalledCount: this.state.apisCalledCount + 1 });
        }
        reject();
      });
  }

  getRegisterNames = (registerRequest: any) => {
    let accountName = "";
    let selectedAcctType = "";
    let isInactiveAccount = false;
    registerRequest.hotelID = -1;
    reconcileService.ReconcileAccount(registerRequest)
      .then(async (result: any[] | null) => {
        this.setState({ apisCalledCount: this.state.apisCalledCount + 1 });
        if (result !== null) {
          let sharedHIDs: any[] = [];
          let accountNames: any[] = [];

          result.sort((a, b) => a.status !== b.status ? a.status < b.status ? -1 : 1 : 0);

          result.forEach(element => {
            let hidCode: any = [];
            let acctName: any = {}
            let oprID = element.oprID;
            let name = element.name;
            let acctType = element.acctType;
            acctName.id = oprID;
            acctName.sort = element.orderBy
            acctName.name = name;
            acctName.status = element.status;
            acctName.acctType = acctType;
            acctName.isHide = element.status.toLowerCase() === "inactive" ? true : false;
            accountNames.push(acctName);

            if (Number(this.state.selectedOprId) === Number(oprID)) {
              accountName = name;
              selectedAcctType = acctType;
              isInactiveAccount = element.status.toLowerCase() === "inactive" ? true : false;
            }
          });
          let reconcilePermissionArray = this.state.reconcilePermissionArray;
          let ccIndex = reconcilePermissionArray.findIndex(i => i.acctType === "CC");
          if (ccIndex >= 0) {
            reconcilePermissionArray[ccIndex].acctType = "C";
          }
          if (reconcilePermissionArray.length > 0) {
            let accountNamesWithPermission: any[] = [];
            for (let i = 0; i < reconcilePermissionArray.length; i++) {
              accountNamesWithPermission.push(...accountNames.filter(elem => elem.acctType === reconcilePermissionArray[i].acctType));
            }
            accountNames = accountNamesWithPermission;
          }
          accountNames = _.sortBy(accountNames, "sort");
          this.setState({ accountNamelist: result, accountNames: accountNames, accountName, selectedAcctType, isInactiveAccount }, () => {
            let request1: any = {};
            request1.hotelId = this.state.hotelId;
            request1.OprID = this.state.selectedOprId;
            this.getReconcilePeriod(request1);
            this.GetLettercode();
            this.GetReconcileExistingDepositGroup();
            this.GetReconcileDepositGroup();
          })
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ apisCalledCount: this.state.apisCalledCount + 1, stopLoader: true });
        Utils.toastError(`Server Error: ${error}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();

      });
  };

  getReconcilePeriod = (request) => {
    reconcileService.GetReconcilePeriod(request)
      .then(async (result: any | null) => {
        this.setState({ apisCalledCount: this.state.apisCalledCount + 1, stopLoader: false });
        if (result != null && result.length > 0) {
          this.setState({ accPerList: result }, () => {
            if (this.state.accPerDisplay === "" || this.state.accPerDisplay === undefined || this.state.accPerList.filter((accPer) => accPer.displayAccper == this.state.accPerDisplay).length === 0) {
              let accPerDisplay = this.state.accPerList[0].defaultAccper;
              let dtArr = accPerDisplay.toString().trim().split("/");
              let accPerReq = dtArr[0] + "/01/" + dtArr[1];
              this.setState({ accPerDisplay, accPerReq }, () => {
                this.onLoadData();
              });
            }
            else {
              let accPerReq = this.state.accPerList.filter((accPer) => accPer.displayAccper == this.state.accPerDisplay)[0]?.accper;
              this.setState({ accPerReq }, () => {
                this.onLoadData();
              });
            }
          });
          this.depositDataList.current?.scrollTo(0, 0);
        }
        else {
          this.setState({
            accPerDisplay: "Select",
            accPerReq: "",
            accPerList: ["Select"],
            stopLoader: true,
            pendingDepositBal: 0,
            pendingWithdrawalBal: 0,
            accountBal: 0,
            depositData: [],
            depositDataArr: [],
            withdrawalData: [],
            statementEndBal: Utils.currencyFormat(0),
            status: "Unreconciled",
            files: [],
            otherDepositDataArr: [],
            isFileUploading: false,
          });
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ apisCalledCount: this.state.apisCalledCount + 1, stopLoader: true });
        Utils.toastError(`Server Error: ${error}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  }

  onLoadData = () => {
    let request: any = {};
    request.hotelId = this.state.hotelId;
    request.OprID = this.state.selectedOprId;
    request.AccPeriod = this.state.accPerReq;
    request.AcctType = this.state.selectedAcctType;

    this.getReconcileSummary(request, "refresh");
    this.getPendingReconcileDeposit(request);
    this.getPendingReconcileWithdrawal(request);
    this.getReconcileAccountBalance(request);
    this.getDepositDetails(request);
    this.getWithdrawalDetails(request);
    this.getUploadedFileList("refresh");
    this.getReconcileGenDetails(request);
    this.getReconcileTypeList(request);
    this.getEhidList();
  }

  getPendingReconcileDeposit = (request) => {
    reconcileService.GetPendingReconcileDeposit(request)
      .then(async (result: any | null) => {
        this.setState({ apisCalledCount: this.state.apisCalledCount + 1 });
        if (result != null) {
          this.setState({ pendingDepositBal: result.depositPenndingTotal });
        }
        else {
          // Do this
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ apisCalledCount: this.state.apisCalledCount + 1 });
        Utils.toastError(`Server Error: ${error}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  }

  getPendingReconcileWithdrawal = (request) => {
    reconcileService.GetPendingReconcileWithdrawal(request)
      .then(async (result: any | null) => {
        this.setState({ apisCalledCount: this.state.apisCalledCount + 1 });
        if (result != null) {
          this.setState({ pendingWithdrawalBal: result.withdrawalTotal });
        }
        else {
          // Do this
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ apisCalledCount: this.state.apisCalledCount + 1 });
        Utils.toastError(`Server Error: ${error}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  }

  getReconcileAccountBalance = (request) => {
    reconcileService.GetReconcileAccountBalance(request)
      .then(async (result: any | null) => {
        this.setState({ apisCalledCount: this.state.apisCalledCount + 1 });
        if (result != null) {
          this.setState({ accountBal: result.accountBal });
        }
        else {
          // Do this
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ apisCalledCount: this.state.apisCalledCount + 1 });
        Utils.toastError(`Server Error: ${error}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  }

  getDepositDetails = (request) => {
    reconcileService.GetDepositDetails(request)
      .then(async (result: any | null) => {
        this.setState({ apisCalledCount: this.state.apisCalledCount + 1 });
        if (result != null) {
          let dintinctAccTypeArr = result.filter((data) => data.viewType === 1).map(data => data.type)
            .filter((value, index, self) => self.indexOf(value) === index);

          let dintinctAccTypeArr_OtherDeopsit = result.filter((data) => data.viewType === 2).map(data => data.type)
            .filter((value, index, self) => self.indexOf(value) === index);

          let dintinctEhidArr = result.filter(x => x.hType === 2 && x.orderNo === 1).map(data => data.lettercode)
            .filter((value, index, self) => self.indexOf(value) === index);

          let depositDataArr: any = [];
          let otherDepositDataArr: any = [];
          dintinctAccTypeArr.map((accType, index) => {
            let depositDataObj = {};
            depositDataObj["id"] = index;
            depositDataObj["accType"] = accType;
            depositDataObj["displayName"] = result.filter((data) => data.type === accType)[0].displayType;
            dintinctEhidArr.length > 0 ?
              accType === "Other Deposit" || accType === "Transfer" ?
                dintinctEhidArr.map((ehid, index) => {
                  if (index == 0) {
                    depositDataObj["value_" + ehid] = result.filter((data) => data.type === accType && data.lettercode === ehid)[0]?.typeStatus;
                  }
                })
                :
                dintinctEhidArr.map((ehid) => {
                  depositDataObj["value_" + ehid] = result.filter((data) => data.type === accType && data.lettercode === ehid)[0]?.typeStatus;
                })
              :
              depositDataObj["value_" + "status"] = result.filter((data) => data.type === accType)[0]?.typeStatus;

            depositDataObj["total"] = result.filter((data) => data.type === accType)[0].totalAmount;
            depositDataObj["overDue"] = result.filter((data) => data.type === accType)[0].overDue;
            depositDataObj["viewType"] = result.filter((data) => data.type === accType)[0].viewType;
            depositDataArr.push(depositDataObj);
          });

          dintinctAccTypeArr_OtherDeopsit.map((accType, index) => {
            let otherDepositDataObj = {};
            otherDepositDataObj["id"] = index;
            otherDepositDataObj["accType"] = accType;
            otherDepositDataObj["displayName"] = result.filter((data) => data.type === accType)[0].displayType;
            otherDepositDataObj["typeStatus"] = result.filter((data) => data.type === accType)[0].typeStatus;
            otherDepositDataObj["total"] = result.filter((data) => data.type === accType)[0].totalAmount;
            otherDepositDataObj["overDue"] = result.filter((data) => data.type === accType)[0].overDue;
            otherDepositDataObj["viewType"] = result.filter((data) => data.type === accType)[0].viewType;
            otherDepositDataObj["grandTotal"] = result.filter((data) => data.type === accType)[0].grandTotal;
            otherDepositDataArr.push(otherDepositDataObj);
          });

          let ehidColumnArr: any = [];
          let ehidColumnObj1: any = {};
          ehidColumnObj1["dataField"] = "value_" + "status";
          ehidColumnObj1["text"] = "";
          ehidColumnObj1["formatter"] = (cell: any, row: any, rowIndex: any) => {
            return (
              row["value_" + "status"] != undefined ?
                <Button variant="primary" type="button" className="btn-link" onClick={() => this.depositTableRowBtnClick(result[0].lettercode, rowIndex)} >{row["value_" + "status"]}</Button> : ""
            )
          };
          dintinctEhidArr.length > 0 ?
            dintinctEhidArr.map((ehid) => {
              let ehidColumnObj = {};
              ehidColumnObj["dataField"] = "value_" + ehid;
              ehidColumnObj["text"] = ehid;
              ehidColumnObj["formatter"] = (cell: any, row: any, rowIndex: any) => {
                return (
                  row["value_" + ehid] != undefined ?
                    <Button variant="primary" type="button" className="btn-link" onClick={() => this.depositTableRowBtnClick(ehid, rowIndex)} >{row["value_" + ehid]}</Button> : ""
                )
              }
              ehidColumnArr.push(ehidColumnObj);
            })
            :
            ehidColumnArr.push(ehidColumnObj1);

          this.setState({ depositDataArr: depositDataArr, ehidColumnArr, depositData: result, otherDepositDataArr }, () => {
            setTimeout(() => {
              let depositDataIsScroll = this.depositDataList.current
              if (depositDataIsScroll.firstChild.clientWidth == depositDataIsScroll.firstChild.scrollWidth) {
                this.setState({ isScrollDepositData: false });
              } else {
                this.setState({ isScrollDepositData: true });
              }
            }, 100);
          });

        }
        else {
          // Do this
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ apisCalledCount: this.state.apisCalledCount + 1 });
        Utils.toastError(`Server Error: ${error}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  }

  getWithdrawalDetails = (request) => {
    reconcileService.GetWithdrawalDetails(request)
      .then(async (result: any | null) => {
        this.setState({ apisCalledCount: this.state.apisCalledCount + 1 });
        if (result != null) {
          this.setState({ withdrawalData: result, });
        }
        else {
          // Do this
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ apisCalledCount: this.state.apisCalledCount + 1 });
        Utils.toastError(`Server Error: ${error}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  }

  getReconcileSummary = (request, type) => {
    reconcileService.GetReconcileSummary(request)
      .then(async (result: any | null) => {
        if (type === "refresh") {
          this.setState({ apisCalledCount: this.state.apisCalledCount + 1 });
        }
        if (result != null) {
          this.setState({ statementEndBal: Utils.currencyFormat(result.amount), status: result.status, statementEndBalCopy: Utils.currencyFormat(result.amount), });
        }
        else {
          this.setState({ statementEndBal: Utils.currencyFormat(0), status: "Unreconciled", statementEndBalCopy: Utils.currencyFormat(0), });
        }
        resolve();
      })
      .catch((error) => {
        if (type === "refresh") {
          this.setState({ apisCalledCount: this.state.apisCalledCount + 1 });
        }
        Utils.toastError(`Server Error: ${error}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  }

  saveReconcileSummary = (request, msgCode) => {
    let statusMsg = msgCode === 1 ? "Record saved successfully." : msgCode === 2 ? "Account successfully reconciled." : msgCode === 3 ? "Account successfully unreconciled." : "";
    reconcileService.SaveReconcileSummary(request)
      .then(async (result: any | null) => {
        if (result.result != null) {
          if (result.result.saveStatus === "Success") {
            this.setState({ isSaveChanges: false, statementEndBalCopy: Utils.currencyFormat(this.state.statementEndBal), }, () => {
              let request: any = {};
              request.hotelId = this.state.hotelId;
              request.OprID = this.state.selectedOprId;
              request.AccPeriod = this.state.accPerReq;
              request.AcctType = this.state.selectedAcctType;
              this.getReconcileSummary(request, "update");
              toast.success(`${statusMsg}`, {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            });
          }
        }
        else {
          // Do this
        }
        resolve();
      })
      .catch((error) => {
        Utils.toastError(`Server Error: ${error}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  }

  getReconcileGenDetails = (request) => {
    reconcileService.GetReconcileGenDetails(request)
      .then(async (result: any | null) => {
        this.setState({ apisCalledCount: this.state.apisCalledCount + 1 });
        if (result != null) {
          this.setState({ futureReconciledBal: result.trNextMonth });
        }
        else {
          // Do this
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ apisCalledCount: this.state.apisCalledCount + 1 });
        Utils.toastError(`Server Error: ${error}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  }

  getDropDownArrowIcon() {
    return (
      <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.28122e-05 1.19715C3.28122e-05 0.889454 0.253262 0.653898 0.543288 0.653898C0.688297 0.653898 0.833313 0.708365 0.923855 0.816593L4.0023 4.02162L7.08074 0.816593C7.2979 0.599435 7.6424 0.599435 7.84116 0.816593C8.04064 1.01608 8.05831 1.34145 7.85955 1.55933L4.40046 5.18103C4.20098 5.39819 3.85721 5.39819 3.64005 5.19943L3.62166 5.18103L0.145009 1.55933C0.054467 1.46879 0 1.34217 0 1.19716L3.28122e-05 1.19715Z" />
      </svg>
    );
  }

  handleAccountSelect = (eventKey: any) => {
    let accountName = this.state.accountNames.filter((account) => account.id == eventKey)[0].name;
    let selectedAcctType = this.state.accountNames.filter((account) => account.id == eventKey)[0].acctType;
    let isInactiveAccount = this.state.accountNames.filter((account) => account.id == eventKey)[0].status.toLowerCase() === "inactive" ? true : false;
    this.setState({ accountName, selectedAcctType, selectedOprId: eventKey, isSaveChanges: false, files: [], apisCalledCount: 1, isInactiveAccount, isFileUploading: false, accPerDisplay: "", }, () => {
      let request: any = {};
      request.hotelId = this.state.hotelId;
      request.OprID = this.state.selectedOprId;
      request.AcctType = this.state.selectedAcctType;
      this.getReconcilePeriod(request);
      this.GetLettercode();
      this.GetReconcileExistingDepositGroup();
      this.GetReconcileDepositGroup();
    });
    this.depositDataList.current?.firstChild.scrollTo(0, 0);
  }

  handlePeriodSelectToggle = (eventVal: any) => {
    setTimeout(() => {
      this.periodSelector.current.getElementsByClassName('active')[0]?.focus();
    }, 100);
  }

  handlePeriodSelect = (eventKey: any) => {
    if (eventKey !== null) {
      let accPerReq = this.state.accPerList.filter((accPer) => accPer.displayAccper == eventKey)[0].accper;
      this.setState({ accPerDisplay: eventKey, accPerReq, isSaveChanges: false, files: [], apisCalledCount: 2, isFileUploading: false, }, () => {
        this.onLoadData();
      });
    }
  }

  handleEndBalChange = (event: any) => {
    let statementEndBal = this.state.statementEndBal;
    let newInputVal = Utils.removeInvaildCharFromAmount(event.target.value, 10);
    event.target.value = newInputVal;

    const rx_live = /^[-]?\d*(?:[.]\d*)?$/;
    let lenbeforePoint = 10;
    if (event.target.value.includes("-")) {
      lenbeforePoint = 11;
    }
    if (rx_live.test(event.target.value)) {
      if (event.target.value.includes(".")) {
        const timeArray = (event.target.value)?.split(".");
        if (timeArray[0].length <= lenbeforePoint && timeArray[1].length <= 2) {
          statementEndBal = event.target.value;
        }
      } else {
        if (event.target.value.length <= lenbeforePoint) {
          statementEndBal = event.target.value;
        }
      }
    } else if (event.target.value.includes(".") && event.target.value.length === 1) {
      statementEndBal = event.target.value;
    }


    this.setState({ statementEndBal }, () => {
      this.setState({ isSaveChanges: true });
    });
  }

  handleEndBalBlur = (event) => {
    this.setState({ statementEndBalIsEdit: false });
    let statementEndBal = this.state.statementEndBal;
    if (!isNaN(event.target.value)) {
      let endBal = Utils.currencyFormat(statementEndBal);
      statementEndBal = endBal;
    } else {
      statementEndBal = Utils.currencyFormat(0);
    }
    if (statementEndBal === "-$0.00") {
      statementEndBal = "$0.00";
    }
    this.setState({ statementEndBal });
  }

  onEndBalFocus = (event: any) => {
    this.setState({ statementEndBalIsEdit: true });
    let newval = Utils.removecurrencyFormat(event.target.value);
    let statementEndBal = this.state.statementEndBal;
    if (newval === "0.00") {
      newval = "";
    }
    statementEndBal = newval;
    this.setState({ statementEndBal });
  }

  handleDiscard = () => {
    this.setState({ statementEndBal: this.state.statementEndBalCopy }, () => {
      this.setState({ isSaveChanges: false });
    })
  }

  handleSaveChanges = (type) => {

    let status = this.state.status;
    let request: any = {};
    request.hotelID = this.state.hotelId;
    request.OprID = this.state.selectedOprId;
    request.Accperiod = this.state.accPerReq;
    request.Amount = Number(Utils.removecurrencyFormat(this.state.statementEndBal));
    request.Status = status;
    request.AcctType = this.state.selectedAcctType;

    if (type === "save" && status === "Reconciled") {
      confirmAlert({
        title: "",
        message: "By updating this information, the reconciliation will be reopened. You must choose ‘Complete Reconciliation’ again to complete it.",
        buttons: [
          {
            label: "Yes",
            onClick: () => this.completeOrReopenReconciliation(3),
          },
          {
            label: "No",
            onClick: () => reject(),
          },
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    }
    else if (type === "save" && status === "Unreconciled") {
      this.saveReconcileSummary(request, 1);
    }
    else if (type === "complete") {
      this.completeOrReopenReconciliation(2);
    }
  }

  completeOrReopenReconciliation = (msgCode) => {

    let status = this.state.status === "Unreconciled" ? "Reconciled" : "Unreconciled";
    let request: any = {};
    request.hotelID = this.state.hotelId;
    request.OprID = this.state.selectedOprId;
    request.Accperiod = this.state.accPerReq;
    request.Amount = Number(Utils.removecurrencyFormat(this.state.statementEndBal));
    request.Status = status;
    request.AcctType = this.state.selectedAcctType;

    this.saveReconcileSummary(request, msgCode);
  }

  handleSelectFileUpload = (eventKey: any, file, index) => {

    if (eventKey === "delete") {
      confirmAlert({
        title: "Delete Attachment",
        message:
          "Are you sure you want to delete?",
        buttons: [
          {
            label: "Yes",
            onClick: () => this.DeleteTransactionFiles(file),
          },
          {
            label: "No",
            onClick: () => reject(),
          },
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    }
    else if (eventKey === "download") {
      this.downloadTranactionFile(file);
    }
  };

  DeleteTransactionFiles = (file: any) => {
    let deleteRequest: any = {};
    deleteRequest.uniqueno = file.uniqueNo;
    deleteRequest.fileUniqueNo = file.fileUniqueno;
    deleteRequest.type = file.etype;
    deleteRequest.isRepeat = "No";
    deleteRequest.AcctType = this.state.selectedAcctType;
    registersevice.DeleteTransactionFiles(deleteRequest)
      .then(async (result: any | null) => {

        if (result.saveStatus === "Success") {
          toast.success("Attachment deleted successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "TransSlideout",
          });
          this.getUploadedFileList("update");


        }
        else {
          Utils.toastError(result.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "TransSlideout",
          });
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  };

  downloadTranactionFile = (file: any) => {
    file.AcctType = this.state.selectedAcctType;
    registersevice.DownloadTranactionFile(file)
      .then(async (result: any | null) => {


        resolve();
      })
      .catch((error) => {
        reject();
      });
  };

  handlePendingModal = (event, rType) => {
    this.setState({ apisCalledCount: 2, isReconcileWithdrawal: false, }, () => {
      this.onLoadData();
    });
  }

  handleDepositModal = (event, rType) => {
    this.setState({ apisCalledCount: 2, isReconcileDeposit: false }, () => {
      this.onLoadData();
    });
  }

  viewPendingReconcile = (event, rType) => {
    this.setState({ PendingReconcileType: rType });
    this.setState({ PendingReconcileModal: event });
  }

  getAccountType = (accountType) => {

    if (accountType === "ACH") {
      return "Other Withdrawal";
    } else if (accountType === "Checks") {
      return "Check";
    } else if (accountType === "Deposits" || accountType === "VendorDeposit") {
      return "Other Deposit";
    } else {
      return accountType;
    }

  }

  handleNextButton = () => {
    let groupMappingAccountTypes = Object.assign([], JSON.parse(JSON.stringify(this.state.groupMappingAccountTypes)));
    let hotelName = "";
    if (this.state.hotelName === undefined || this.state.hotelName === "") {
      hotelName = this.state.depositData.length > 0 ? this.state.depositData[0].lettercode : "";
    } else {
      hotelName = this.state.hotelName;
    }
    let acctType = this.getAccountType(this.state.AccountType);
    let accountList: any = [];
    this.state.depositDataArr.forEach(element => {
      let objacc: any = {};
      objacc.accType = element.accType;
      objacc.type = element.accType;
      accountList.push(objacc);
    });
    this.state.otherDepositDataArr.forEach(element => {
      if (element.accType !== 'Transfer') {
        let objacc: any = {};
        objacc.accType = element.accType;
        objacc.type = element.accType;
        accountList.push(objacc);
      }
    });
    this.state.withdrawalData.forEach(element => {
      let objacc: any = {};
      objacc.accType = element.type;
      objacc.type = element.type;
      accountList.push(objacc);
    });

    let currentIndex = accountList.findIndex(e => e.accType === acctType);
    let lastGroupId = groupMappingAccountTypes.filter((item) => item.lettercode === hotelName && item.auditName === accountList[currentIndex].accType && item.groupID !==0).length > 0 ? this.state.groupMappingAccountTypes.filter((item) => item.lettercode === hotelName && item.auditName === accountList[currentIndex].accType)[0].groupID : -1;
    for (let i = 1; i <= accountList.length - currentIndex; i++) {
      if ((currentIndex + i) === accountList.length) {
        if (this.state.depositDataArr.length > 0) {
          this.depositTableRowBtnClick(hotelName, 0);
        } else if (this.state.otherDepositDataArr.length > 0) {
          this.withdrawalTableRowBtnClick(accountList[0]);
        } else if (this.state.withdrawalData.length > 0) {
          this.withdrawalTableRowBtnClick(accountList[currentIndex + i]);
        }
      } else {
        if (this.state.depositDataArr.some(e => e.accType === accountList[currentIndex + i].accType)) {
          let isSameGrpId = groupMappingAccountTypes.filter((item) => item.lettercode === hotelName && item.auditName === accountList[currentIndex + i].accType && item.groupID === lastGroupId).length > 0;
          if (isSameGrpId) {
            continue;
          }
          else{
            this.depositTableRowBtnClick(hotelName, currentIndex + i);
            break;
          }
        } else if (this.state.otherDepositDataArr.some(e => e.accType === accountList[currentIndex + i].accType)) {
          this.withdrawalTableRowBtnClick(accountList[currentIndex + i]);
          break;
        } else if (this.state.withdrawalData.some(e => e.type === accountList[currentIndex + i].accType)) {
          this.withdrawalTableRowBtnClick(accountList[currentIndex + i]);
          break;
        }
      }
    }
  }

  depositTableRowBtnClick = (ehid, index) => {
    let rowData = this.state.depositDataArr.filter(x => x.id === index)[0];
    let ehidCode = this.state.depositData.filter(x => x.lettercode === ehid)[0].hid;
    this.setState({ isReconcileDeposit: false, isReconcileWithdrawal: false }, () => {
        this.setState({ pageType: rowData.displayName, ehid: ehidCode, hotelName: ehid, AccountType: rowData.accType }, () => {
          this.setState({ isReconcileDeposit: true })
        })
      // }
    })
  }





  withdrawalTableRowBtnClick = (row) => {

    this.setState({ isReconcileWithdrawal: false, isReconcileDeposit: false }, () => {
      if (row.type === "Other Withdrawal") {
        this.setState({ pageType: "ACH Withdrawals", ehid: this.state.hotelId, AccountType: "ACH" }, () => {
          this.setState({ isReconcileWithdrawal: true })
        })
      } else if (row.type === "Check") {
        this.setState({ pageType: "Checks", ehid: this.state.hotelId, AccountType: "Checks" }, () => {
          this.setState({ isReconcileWithdrawal: true })
        })
      } else if (row.accType === "Other Deposit") {
        this.setState({ pageType: "Other Deposits", ehid: this.state.hotelId, AccountType: (this.state.selectedAcctType === "M" || this.state.selectedAcctType === "P") ? "Deposits" : "VendorDeposit" }, () => {
          this.setState({ isReconcileWithdrawal: true })
        })
      }
    })
  }

  handleReconcileAll = () => {
    this.setState({ pageType: "Reconcile All", ehid: this.state.hotelId, AccountType: "All", isModelReconcileAll: false }, () => {
      this.setState({ isReconcileWithdrawal: true })
    })
  }

  exitReconcile = () => {
    if (this.state.redirectFrom === "register") {
      this.props.history.push('/register', { oprid: this.state.oprIDFromRegister });
    }
    else if (this.state.redirectFrom === "reconcileStatus") {
      this.props.history.push('/accounting/reconcileStatus',);
    }
    else if (this.state.redirectFrom === "overview") {
      this.props.history.push('Account-management/Overview', { tabName: this.state.activeAlertBox });
    }
  }

  hideDepositModal = () => {
    this.setState({ isExportReconcileReport: false, parameterSet: false });
  }
  getExportReconciliationReport = () => {
    this.addReportParams();
    this.setState({ isExportReconcileReport: true });
    if (this.state?.parameterSet) {
      scrollIntoView(this.cardDivRef?.current, {
        align: {
          top: 0
        },
      });

      this.setState({ parameterSet: false });
      return;
    }

    this.setState({ parameterSet: false }, () => this.setState({ parameterSet: true }, () => {
      scrollIntoView(this.reportDivRef?.current, {
        align: {
          bottom: 0,
          left: 0,
          topOffset: 60,
          leftOffset: 0,
        },
      });
    }));
  }

  addReportParams = () => {
    let reportParam = [] as any;
    const storage = JSON.parse(localStorage.getItem("storage")!);
    let username = storage === null ? 0 : (storage.userName);
    let tenantId = storage === null ? 0 : (storage.tenantID);

    reportParam =
      reportParam = [
        { name: "Status", value: "1" }
        , { name: "coaType", value: this.state.accPerReq }
        , { name: "Report", value: "115" }
        , { name: "OwnershipID", value: this.state.selectedOprId }
        , { name: "isPosition", value: "No" }
        , { name: "IsSalaried", value: "No" }
        , { name: "IsExcludePayroll", value: "No" }
        , { name: "IsVariance", value: "No" }
        , { name: "IsDollars", value: "No" }
        , { name: "ActualCompared", value: "0" }
        , { name: "ReportName", value: 3 }
        , { name: "IsExclude", value: "No" }
        , { name: "ChkSummSec", value: "Yes" }
        , { name: "ChkIsCompSet", value: "No" }
        , { name: "Type", value: this.state.selectedAcctType }
        , { name: "HyperLink", value: true }
        , { name: "userName", value: username }
        , { name: "IsPageBreak", value: "0" }
        , { name: "ExportType", value: "View" }
        , { name: "IsOCC", value: "1" }
        , { name: "IsADR", value: "1" }
        , { name: "IsRMREV", value: "1" }
        , { name: "IsREVPAR", value: "1" }
        , { name: "IsFromOverview", value: "Yes" }
        , { name: "GroupName", value: "" }
        , { name: "HotelBrand", value: "" }
        , { name: "Hotelid", value: this.state?.hotelId }
        , { name: "Hid", value: this.state?.hotelId }
        , { name: "description", value: "" }
        , { name: "tenantId", value: tenantId }
        , { name: "reportId", value: 2078 }]

    localStorage.setItem("reportParameters", JSON.stringify(reportParam));
  }

  exportReport = (exportFormat = "PDF") => {
    this.addReportParams();
    let reportParameters = JSON.parse(
      localStorage.getItem("reportParameters")!
    );

    const nameIndex = reportParameters.map((p) => p.name).indexOf("writerFormat");
    let currentReportParameters = reportParameters;
    if (nameIndex > -1) {
      currentReportParameters.splice(nameIndex, 1);
    }
    currentReportParameters.push({ name: "writerFormat", value: exportFormat });


    let item = currentReportParameters.find(item => item.name === "writerFormat");

    if (item.value.toUpperCase() === "CSV") {
      ReportExport.reportDownloadCSV(JSON.stringify(reportParameters)).then(
        (result) => {
          const href = window.URL.createObjectURL(result);
          const a = this.linkRef.current;
          a.download = 'Export Reconciliation Report' + `.${exportFormat.toLowerCase()}`;
          a.href = href;
          a.click();
          a.href = "";
        }
      );
    }
    else if (item.value.toUpperCase() === "PDF") {
      ReportExport.reportDownload(JSON.stringify(reportParameters)).then(
        (result) => {
          const href = window.URL.createObjectURL(result);
          const a = this.linkRef.current;
          a.download = 'Export Reconciliation Report' + `.${exportFormat.toLowerCase()}`;
          a.href = href;
          a.click();
          a.href = "";
        }
      );
    }
    else {
      ReportExport.reportDownload(JSON.stringify(reportParameters)).then(
        (result) => {
          let extension = "";
          if (exportFormat === "Excel") {
            extension = "xlsx";
          }
          const href = window.URL.createObjectURL(result);
          const a = this.linkRef.current;
          a.download = 'Export Reconciliation Report' + `.${extension}`;
          a.href = href;
          a.click();
          a.href = "";
        }
      );
    }
  }
  editIcon() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" /></svg>
    );
  }

  addOrEditGroup = (item, type) => {
    let checkedGroupList = Object.assign([], JSON.parse(JSON.stringify(this.state.checkedGroupList)));
    let selectedGroup = Object.assign({}, JSON.parse(JSON.stringify(item)));
    if (type === "edit") {
      let list = this.state.groupMappingAccountTypesCopy.filter((accType) => accType.groupID === item.groupID);
      for (let i = 0; i < list.length; i++) {
        checkedGroupList.push({AuditID: list[i].auditId, AuditName: list[i].auditName});
      }
    }
    else {
      selectedGroup.groupID = 0;
    }
    this.setState({ isAddAnotherGroupModal: true, isEditGroupMapping: type === "edit" ? true : false, selectedGroup, checkedGroupList, isSaveorEditGroupClicked: false, })
  }

  handleGroupMappingCheckbox = (event, accType) => {
    let groupMappingAccountTypes = Object.assign([], JSON.parse(JSON.stringify(this.state.groupMappingAccountTypes)));
    let checkedGroupList = Object.assign([], JSON.parse(JSON.stringify(this.state.checkedGroupList)));
    let index = groupMappingAccountTypes.findIndex((item) => item.id === accType.id)
    if (event.target.checked) {
      groupMappingAccountTypes[index].ischeck = 2;
      if (checkedGroupList.filter((list) => list.AuditID === accType.auditId).length === 0) {
        checkedGroupList.push({AuditID: accType.auditId, AuditName: accType.auditName});
      }
    }
    else {
      groupMappingAccountTypes[index].ischeck = 0;
      if (checkedGroupList.filter((list) => list.AuditID === accType.auditId).length > 0) {
        const index = checkedGroupList.findIndex((list) => list.AuditID === accType.auditId)
        checkedGroupList.splice(index, 1);
      }
    }
    this.setState({ groupMappingAccountTypes, checkedGroupList });
  }

  cancelCreateGroup = () => {
    this.setState({
       isAddAnotherGroupModal: false, isEditGroupMapping: false, 
       selectedGroup: {auditId: 0, auditName: "", displayGroupName: "", displayName: "", groupID: 0, groupName: "", hid: -1, id: 0, lettercode: "", otherGroup: ""},
       groupMappingAccountTypes: this.state.groupMappingAccountTypesCopy,
       checkedGroupList:[],
    })
  }

  saveGroup = () => {
    this.setState({isSaveorEditGroupClicked: true});
    let request: any = {};
    request.GroupID = this.state.isEditGroupMapping ? this.state.selectedGroup.groupID : 0;
    request.HID = this.state.selectedGroup.hid;
    request.OprID = this.state.selectedOprId;
    request.SaveType = this.state.isEditGroupMapping ? "edit" : "add";
    request.GroupList = this.state.checkedGroupList;

    reconcileService.SaveReconcilDepositGroup(request)
      .then(async (result: any | null) => {
        if (result.result != null) {
          if (result.result.status.toLowerCase() === "success" || result.result.status.toLowerCase() === "fail") {
            if(result.result.status.toLowerCase() === "success")
            {
              toast.success(this.state.isEditGroupMapping ? "Group Deposit Mapping edited successfully." : "Group Deposit Mapping saved successfully.", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }
            else
            {
              toast.success(result.result.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }
           
            this.setState({ 
              isAddAnotherGroupModal: false, isEditGroupMapping: false, 
              selectedGroup: {auditId: 0, auditName: "", displayGroupName: "", displayName: "", groupID: 0, groupName: "", hid: -1, id: 0, lettercode: "", otherGroup: ""},
              groupMappings: [], groupMappingAccountTypes: [], groupMappingAccountTypesCopy: [], groupMappingsEHID: [],
              checkedGroupList:[], isSaveorEditGroupClicked: false,
            }, () => {
              this.GetReconcileExistingDepositGroup();
              this.GetReconcileDepositGroup();
            });
          }
        }
        resolve();
      })
      .catch((error) => {
        this.setState({isSaveorEditGroupClicked: false});
        Utils.toastError(`Server Error: ${error}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });

  }

  onExitGroupDepositMapping = () => {
    this.setState({ isModelGroupDepositMappings: false, isAddAnotherGroupModal: false, isEditGroupMapping: false,
      selectedGroup: {auditId: 0, auditName: "", displayGroupName: "", displayName: "", groupID: 0, groupName: "", hid: -1, id: 0, lettercode: "", otherGroup: ""},
      checkedGroupList:[], isSaveorEditGroupClicked: false, groupMappingAccountTypes: this.state.groupMappingAccountTypesCopy
    })
  }

  render() {
    let subTotal = 0;
    let total = 0;
    if (this.state.selectedAcctType === "C") {
      subTotal = Number(Utils.removecurrencyFormat(Utils.currencyFormatWithOutdoller(Number(Utils.removecurrencyFormat(this.state.statementEndBal)) - this.state.pendingDepositBal)));
      total = Number(Utils.removecurrencyFormat(Utils.currencyFormatWithOutdoller(subTotal + this.state.pendingWithdrawalBal)));
    }
    else {
      subTotal = Number(Utils.removecurrencyFormat(Utils.currencyFormatWithOutdoller(Number(Utils.removecurrencyFormat(this.state.statementEndBal)) + this.state.pendingDepositBal)));
      total = Number(Utils.removecurrencyFormat(Utils.currencyFormatWithOutdoller(subTotal - this.state.pendingWithdrawalBal)));
    }
    let variance = Number(Utils.removecurrencyFormat(Utils.currencyFormatWithOutdoller(total + this.state.futureReconciledBal - this.state.accountBal)));

    const editIcon = this.editIcon();
    const files = this.state.files.map((file, index) => (
      <li key={file.name}>
        <div className="d-flex align-items-center justify-content-start">
          <div className="icon">
            {(file?.type)?.split('/')[0] === 'image' && (
              <FontAwesomeIcon icon={faFileImage} />
            )}
            {(file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) === 'pdf' && (
              <FontAwesomeIcon icon={faFilePdf} />
            )}
            {(file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) === 'xlsx' && (
              <FontAwesomeIcon icon={faFileExcel} />
            )}
            {(file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) === 'csv' && (
              <FontAwesomeIcon icon={faFileCsv} />
            )}
            {(file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) === 'word' && (
              <FontAwesomeIcon icon={faFileWord} />
            )}
            {(((file?.type)?.split('/')[0] !== 'image') &&
              ((file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) !== 'pdf') &&
              ((file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) !== 'xlsx')
              && ((file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) !== 'csv')
              && ((file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) !== 'word'))
              && (
                <FontAwesomeIcon icon={faFile} />
              )}
          </div>
          <div className="file-info d-flex align-items-center">
            <div className="name-loader d-flex align-items-center">
              <div className="name">
                <EllipsisWithTooltip placement="bottom">
                  <div className="full-name" >{file.name}</div>
                </EllipsisWithTooltip>
                <div className="details">{file.createDate} - {file.pageno} Page</div>
              </div>
            </div>
            <div className="formate-percentage">
              <div className="formate d-flex justify-content-center align-items-center">
                {(file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length))}
              </div>
            </div>
          </div>
          {file?.isOptions &&
            <div className="action">
              <Dropdown className="more-action" alignRight onSelect={(event: any) => this.handleSelectFileUpload(event, file, index)}>
                <Dropdown.Toggle className="btn-outline-primary btn btn-primary more" id="dropdown-more">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {this.state.moreActionFileUpload.map(
                    (item: any, idx: any) => (
                      <Dropdown.Item
                        eventKey={this.state.moreActionFileUpload[idx].eventKey}
                        key={idx}
                      >
                        {this.state.moreActionFileUpload[idx].name}
                      </Dropdown.Item>
                    )
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          }
        </div>
      </li>
    ));
    const dropDownArrowIcon = this.getDropDownArrowIcon();
    const depositsColumns = [
      {
        dataField: "accType",
        text: "Account Type",
        headerClasses: 'acc-type',
        classes: 'acc-type',
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="d-flex">
              <div className="acc-name"><EllipsisWithTooltip placement="bottom">{row.displayName}</EllipsisWithTooltip></div>
              {row.overDue === "Yes" &&
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id={"deposit" + rowIndex}>
                      {"There are pending transactions older than 90 days"}
                    </Tooltip>
                  }>
                  <div className="alert-icn">
                    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.73655 1.11004C9.44822 0.565044 8.55155 0.565044 8.26322 1.11004L0.763217 15.2767C0.695704 15.4037 0.662233 15.546 0.666067 15.6897C0.669902 15.8335 0.710909 15.9738 0.785095 16.097C0.859281 16.2202 0.964115 16.3221 1.08938 16.3927C1.21465 16.4633 1.35608 16.5003 1.49988 16.5H16.4999C16.6436 16.5003 16.7849 16.4634 16.9101 16.3928C17.0353 16.3222 17.14 16.2204 17.2142 16.0973C17.2883 15.9741 17.3292 15.8339 17.333 15.6902C17.3368 15.5466 17.3033 15.4044 17.2357 15.2775L9.73655 1.11004ZM9.83322 14H8.16655V12.3334H9.83322V14ZM8.16655 10.6667V6.50004H9.83322L9.83405 10.6667H8.16655Z" fill="#FCA147" />
                    </svg>
                  </div>
                </OverlayTrigger>
              }
            </div>
          )
        }
      },
      ...this.state.ehidColumnArr,
      {
        dataField: "total",
        text: "Total",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">{Utils.currencyFormat(row.total)}</EllipsisWithTooltip>
          )
        }
      }
    ];

    const otherDepositsColumns = [
      {
        dataField: "accType",
        text: "Account Type",
        headerClasses: 'acc-type',
        classes: 'acc-type',
        footerClasses: 'acc-type',
        footer: 'Totals',
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="d-flex">
              <div className="acc-name"><EllipsisWithTooltip placement="bottom">{row.displayName}</EllipsisWithTooltip></div>
              {row.overDue === "Yes" &&
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id={"deposit" + rowIndex}>
                      {"There are pending transactions older than 90 days"}
                    </Tooltip>
                  }>
                  <div className="alert-icn">
                    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.73655 1.11004C9.44822 0.565044 8.55155 0.565044 8.26322 1.11004L0.763217 15.2767C0.695704 15.4037 0.662233 15.546 0.666067 15.6897C0.669902 15.8335 0.710909 15.9738 0.785095 16.097C0.859281 16.2202 0.964115 16.3221 1.08938 16.3927C1.21465 16.4633 1.35608 16.5003 1.49988 16.5H16.4999C16.6436 16.5003 16.7849 16.4634 16.9101 16.3928C17.0353 16.3222 17.14 16.2204 17.2142 16.0973C17.2883 15.9741 17.3292 15.8339 17.333 15.6902C17.3368 15.5466 17.3033 15.4044 17.2357 15.2775L9.73655 1.11004ZM9.83322 14H8.16655V12.3334H9.83322V14ZM8.16655 10.6667V6.50004H9.83322L9.83405 10.6667H8.16655Z" fill="#FCA147" />
                    </svg>
                  </div>
                </OverlayTrigger>
              }
            </div>
          )
        }
      },
      {
        dataField: "typeStatus",
        text: "",
        footer: '',
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <Button variant="primary" type="button" className="btn-link" onClick={() => this.withdrawalTableRowBtnClick(row)} >{row.typeStatus}</Button>
          )
        }
      },
      {
        dataField: "total",
        text: "Total",
        footer: (columnData) => {
          return (
            <EllipsisWithTooltip placement="bottom">{this.state.depositData.length > 0 ? Utils.currencyFormat(Number(Utils.removecurrencyFormat(Utils.currencyFormatWithOutdoller(this.state.otherDepositDataArr[0]?.grandTotal)))) : Utils.currencyFormat(0)}</EllipsisWithTooltip>
          )
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">{Utils.currencyFormat(row.total)}</EllipsisWithTooltip>
          )
        }
      }
    ];

    const withdrawalColumns = [
      {
        dataField: "type",
        text: "Account Type",
        headerClasses: 'acc-type',
        classes: 'acc-type',
        footer: 'Totals',
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="d-flex">
              <div className="acc-name"><EllipsisWithTooltip placement="bottom">{row.displayType}</EllipsisWithTooltip></div>
              {row.overDue === "Yes" &&
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id={"withdrawal" + rowIndex}>
                      {"There are pending transactions older than 90 days"}
                    </Tooltip>
                  }>
                  <div className="alert-icn">
                    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.73655 1.11004C9.44822 0.565044 8.55155 0.565044 8.26322 1.11004L0.763217 15.2767C0.695704 15.4037 0.662233 15.546 0.666067 15.6897C0.669902 15.8335 0.710909 15.9738 0.785095 16.097C0.859281 16.2202 0.964115 16.3221 1.08938 16.3927C1.21465 16.4633 1.35608 16.5003 1.49988 16.5H16.4999C16.6436 16.5003 16.7849 16.4634 16.9101 16.3928C17.0353 16.3222 17.14 16.2204 17.2142 16.0973C17.2883 15.9741 17.3292 15.8339 17.333 15.6902C17.3368 15.5466 17.3033 15.4044 17.2357 15.2775L9.73655 1.11004ZM9.83322 14H8.16655V12.3334H9.83322V14ZM8.16655 10.6667V6.50004H9.83322L9.83405 10.6667H8.16655Z" fill="#FCA147" />
                    </svg>
                  </div>
                </OverlayTrigger>
              }
            </div>
          )
        }
      },
      {
        dataField: "typeStatus",
        text: "",
        footer: '',
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <Button variant="primary" type="button" className="btn-link" onClick={() => this.withdrawalTableRowBtnClick(row)} >{row.typeStatus}</Button>
          )
        }
      },
      {
        dataField: "amount",
        text: "Total",
        footer: (columnData) => {
          let sum = columnData.reduce((acc, item) => acc + item, 0);
          return (
            <EllipsisWithTooltip placement="bottom">{Utils.currencyFormat(sum)}</EllipsisWithTooltip>
          )
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">{Utils.currencyFormat(row.amount)}</EllipsisWithTooltip>
          )
        }
      }
    ];
    const handleSelect = (eventKey: any) => {
      if (eventKey == "groupDepositMappings") {
        this.setState({ isModelGroupDepositMappings: true });
      }
    };

    return (
      <>
        <div className="reconciliation-page">
          <Container fluid className="body-sec reconcile">
            <div className="page-heading underline d-flex bg-white sticky-top">
              <div className="align-items-center width-calc">
                <div className="tag-name">Reconcile Account</div>
                {this.state.accountName}
                <div className="selected-letterCode">
                  <EllipsisWithTooltip placement="bottom">
                    {this.state.letterCodeString} 
                  </EllipsisWithTooltip>
                </div>
              </div>
              <div className="action-group d-flex ml-auto">
                {this.state.accountNames.filter((x) => !x.isHide).length > 0 &&
                  <SingleSearchDropdownList
                    id={"tableAccountName"}
                    itemList={this.state.accountNames.filter((x) => !x.isHide)}
                    handleSelectedItem={(event: any) => this.handleAccountSelect(event)}
                    defaultText={"Search..."}
                    defaultName={this.state.accountName}
                    calHomeIcon={true}
                    // currentChecked={this.state.accountName}
                    controlID="2"
                    // pageType={"Register"}
                    isStatusbadge={true}
                  />
                }
                {this.state.accPerList.length > 0 &&
                  <Dropdown className="more-action btn-dropdown period-selector" alignRight onSelect={(event: any) => this.handlePeriodSelect(event)} onToggle={(event: any) => this.handlePeriodSelectToggle(event)}>
                    <Dropdown.Toggle className="btn-outline-primary" id="dropdown-period">
                      <div className="d-flex align-items-center justify-content-start">
                        <div className="period-name mr-auto"><EllipsisWithTooltip placement="bottom">{this.state.accPerDisplay}</EllipsisWithTooltip></div>
                        <div className="arrow-icon">{dropDownArrowIcon}</div>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu ref={this.periodSelector}>
                      {this.state.accPerList.map(
                        (item: any, idx: any) => (
                          <Dropdown.Item className={this.state.accPerDisplay === item.displayAccper ? "active" : ""} eventKey={item.displayAccper} key={idx}>{item.displayAccper}</Dropdown.Item>
                        )
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                }
                <div className="divider"></div>
                <button type="button" className="btn-outline-primary btn btn-primary" onClick={this.exitReconcile}>Exit</button>
                <Dropdown className="more-action three-dot" alignRight onSelect={handleSelect}>
                  <Dropdown.Toggle className="btn-outline-primary btn btn-primary more" id="dropdown-more">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                      <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                      <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                    </svg>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="editaccount" key="editaccount"
                      onClick={() => this.getExportReconciliationReport()}
                    >Export Reconciliation Report</Dropdown.Item>
                    {(this.state.selectedAcctType === "M" || this.state.selectedAcctType === "O") && this.state.depositDataArr.length > 0 &&
                      <Dropdown.Item eventKey="groupDepositMappings" key="groupDepositMappings">Group Reconciliation Mappings</Dropdown.Item>
                    }
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="left-sec">
              <div className="section">
                <div className="header-title d-flex">
                  <div className="sec-ttl">Summary</div>
                  <div className={`header-status 
                      ${this.state.status == "Unreconciled" ? "blue" : ""}
                      ${this.state.status == "Locked" ? "grey" : ""}
                      ${this.state.status == "Reconciled" ? "green" : ""}
                    `}>{this.state.status}</div>
                </div>
                <div className="sec-body">
                  <ul>
                    <li>
                      <div className="d-flex align-items-center list">
                        <div className="mr-auto list-ttl">Statement Ending Balance</div>
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id={"statement-balance-tooltip"} className={this.state.statementEndBal.length <= 13 || this.state.statementEndBalIsEdit ? "statement-balance-tooltip-hide" : ""}>
                              {this.state.statementEndBal}
                            </Tooltip>
                          }>
                          <Form.Control type="text" min="0"
                            value={this.state.statementEndBal}
                            onChange={(e: any) => this.handleEndBalChange(e)}
                            onBlur={this.handleEndBalBlur}
                            onFocus={this.onEndBalFocus}
                            disabled={this.state.isInactiveAccount}
                            maxLength={20}
                            autoComplete='off'
                          />
                        </OverlayTrigger>
                      </div>
                      <div className="d-flex align-items-center list">
                        <div className="mr-auto list-ttl">
                          Pending Deposits
                          {this.state.selectedAcctType !== "C" && this.state.selectedAcctType !== undefined && this.state.selectedAcctType !== "" &&
                            <Button variant="primary" type="button" className="btn-link" onClick={(event: any) => this.viewPendingReconcile(true, "Pending Deposits")}> (View)</Button>
                          }
                        </div>
                        <div className="list-val">{Utils.currencyFormat(this.state.pendingDepositBal)}</div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex align-items-center list">
                        <div className="mr-auto list-ttl">Subtotal</div>
                        <div className="list-val">{Utils.currencyFormat(subTotal)}</div>
                      </div>
                      <div className="d-flex align-items-center list">
                        <div className="mr-auto list-ttl">
                          Pending Withdrawals
                          {this.state.selectedAcctType !== "C" && this.state.selectedAcctType !== undefined && this.state.selectedAcctType !== "" &&
                            <Button variant="primary" type="button" className="btn-link" onClick={(event: any) => this.viewPendingReconcile(true, "Pending Withdrawals")}> (View)</Button>
                          }
                        </div>
                        <div className="list-val">{Utils.currencyFormat(this.state.pendingWithdrawalBal)}</div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex align-items-center list">
                        <div className="mr-auto list-ttl">Total</div>
                        <div className="list-val bold">{Utils.currencyFormat(total)}</div>
                      </div>
                      {this.state.futureReconciledBal !== 0 && this.state.futureReconciledBal !== 0.00 &&
                        <div className="d-flex align-items-center list">
                          <div className="mr-auto list-ttl">Future Reconciled</div>
                          <div className="list-val bold">{Utils.currencyFormat(this.state.futureReconciledBal)}</div>
                        </div>
                      }
                      <div className="d-flex align-items-center list">
                        <div className="mr-auto list-ttl">Account Balance</div>
                        <div className="list-val bold">{Utils.currencyFormat(this.state.accountBal)}</div>
                      </div>
                      <div className="d-flex align-items-center list">
                        <div className="mr-auto list-ttl">Variance</div>
                        <div className={variance == 0 || variance == 0.00 ? "list-val bold green" : "list-val bold red"}>
                          {Number(variance)<0?
                          <>({Utils.currencyFormatNoMinus(variance)})</>://NW-31839
                          <>{Utils.currencyFormat(variance)}</>}
                          </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="section upload-section">
                <div className="header-title d-flex">
                  <div className="sec-ttl">Attachments</div>
                </div>
                <div className="sec-body">
                  <Dropzone onDrop={this.onDrop}>
                    {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                      <>

                        <section className={isDragActive ? "container upload-area default drag-active " : "container upload-area default"}>
                          <div {...getRootProps({
                            className: 'dropzone d-flex align-items-center',
                            onClick: (this.state.files.length > 0) ?
                              event => event.stopPropagation() : event => event
                          })}>
                            <input {...getInputProps()} />
                            {(this.state.files.length <= 0) && (
                              <p>Drag and drop Statements or <span>browse files</span> to upload.</p>
                            )}
                          </div>
                          {(this.state.files.length > 0) && (
                            <aside className="d-flex flex-column">
                              <ul>{files}</ul>
                            </aside>
                          )}
                        </section>
                        {(this.state.files.length > 0) && (
                          <div className="upload-link mrgn-top">
                            <button type="button" className="btn wht-bg link-btn mr-auto" disabled={this.state.isFileUploading} >Add Attachment</button>
                            <Form.Control disabled={this.state.isFileUploading} id="formControlsFile" type="file" multiple onChange={(event: any) => this.onFileChange(event, "1")}
                              accept="image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.txt,.bmp,.tif,.ppt,.pptx,.rtf,.jpg,.png,.msg,.eml" />
                          </div>
                        )}
                      </>
                    )}
                  </Dropzone>
                </div>
              </div>
            </div>
            <div className="right-sec">
              <div className="section">
                <div className="header-title d-flex">
                  <div className="sec-ttl mr-auto">Deposits</div>
                  {(this.state.selectedAcctType !== "C") && (
                    <>
                      <Button variant="primary" type="button" className="btn-link btn-height32" onClick={() => { this.setState({ isModelReconcileAll: true }) }}>Reconcile All</Button>
                      {this.state.depositDataArr.length > 0 && this.state.groupMappings[0]?.isGroupBtnShow.toLowerCase() === "yes" && (
                        <Dropdown className="margin-left-16 groupReconcile-selector" onToggle={() => this.resetData()}>
                          <Dropdown.Toggle id="dropdown-groupReconcile">{"Group Reconcile"}</Dropdown.Toggle>
                          <Dropdown.Menu>
                            <div className="header">Select Accounts to Reconcile</div>
                            <div className="body">
                              <div className="select-ehid">
                                <Form.Group controlId="EHID">
                                  <Form.Label>EHID</Form.Label>
                                  <Dropdown className="custom-selectbox" ref={this.selectEhid}>
                                    <Dropdown.Toggle id="dropdown-period-hid">
                                      <EllipsisWithTooltip placement="bottom">{this.state.groupHid.length === 0 ? "Select" : this.state.groupHid}</EllipsisWithTooltip>
                                      <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.29272 0.292969L5.00006 3.5863L1.70739 0.292969L0.292725 1.70764L5.00006 6.4143L9.70739 1.70764L8.29272 0.292969Z" />
                                      </svg>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="ddl-itemwidth">
                                      {this.state.ehidList.map(
                                        (item: any, idx: any) => (
                                          <div className="dropdown-item" key={idx} onClick={(e) => { this.onReconcileHid(e, item) }}>{item.letterCode}</div>
                                        )
                                      )}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </Form.Group>
                              </div>
                              <div className="account">
                                {this.state.stopLoader && (
                                  <div className="loader-spinner reconciliation-summary d-flex justify-content-center align-items-center">
                                    <Spinner animation="border" variant="success" />
                                  </div>
                                )}
                                {this.state.reconcileList.map(
                                  (item: any, idx: any) => (
                                    <div className="dropdown-item" key={idx}>
                                      <EllipsisWithTooltip placement="bottom">
                                        <Form.Check
                                          custom
                                          type="checkbox"
                                          key={"custom-" + idx}
                                          id={"custom-" + idx}
                                          label={item.type1}
                                          checked={item.isChecked ? true : false}
                                         // disabled={item.status === "Reconciled" ? true : false}
                                          onChange={(e: any) => this.onChangeGroupReconcile(item, e)}
                                        />
                                      </EllipsisWithTooltip>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                            <div className="footer d-flex justify-content-end">
                              <Button variant="primary" type="button" className="btn-outline-primary exit-button green-color" onClick={() => { this.reconcileGroup() }}>Reconcile</Button>
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      )}

                    </>
                  )}

                </div>
                <div className="sec-body deposit compact custom-scrollbar">
                  <div className="vertcle-line"></div>
                  <div className="deposit-data" ref={this.depositDataList}>
                    <BootstrapTable
                      keyField="id"
                      data={this.state.depositDataArr}
                      columns={depositsColumns}
                    />
                  </div>
                  <div className={this.state.isScrollDepositData ? "otherDeposit-data isScroll" : "otherDeposit-data"}>
                    <BootstrapTable
                      keyField="id"
                      data={this.state.otherDepositDataArr}
                      columns={otherDepositsColumns}
                      headerClasses={"d-none"}
                    />
                  </div>
                </div>
              </div>
              <div className="section">
                <div className="header-title d-flex">
                  <div className="sec-ttl mr-auto">Withdrawals</div>
                </div>
                <div className="sec-body withdrawal compact">
                  <div className="vertcle-line"></div>
                  <BootstrapTable
                    keyField="id"
                    data={this.state.withdrawalData}
                    columns={withdrawalColumns}
                  />
                </div>
              </div>
            </div>
            <div className="footer unsaved">
              <div className="d-flex justify-content-end">
                <Button variant="primary" hidden={!this.state.isSaveChanges} type="button" className="btn-discard" onClick={this.handleDiscard}>Discard</Button>
                <Button variant="primary" hidden={!this.state.isSaveChanges} type="button" onClick={() => this.handleSaveChanges("save")} >Save Changes</Button>
                {this.state.status === "Unreconciled" ?
                  <Button variant="primary" disabled={(variance != 0 && variance != 0.00) || this.state.isInactiveAccount ? true : false} type="button" className="btn-outline-primary"
                    onClick={() => this.handleSaveChanges("complete")} >Complete Reconciliation</Button>
                  :
                  <Button variant="primary" type="button" className="btn-outline-primary" disabled={this.state.isInactiveAccount}
                    onClick={() => this.completeOrReopenReconciliation(3)} >Reopen Reconciliation</Button>
                }
              </div>
            </div>
          </Container>
          {this.state.PendingReconcileModal && (
            <>
              {/* <div className="back-drop"></div> */}
              <PendingReconcile
                status={"Unreconciled"}
                pageTitle={this.state.PendingReconcileType}
                headerControlsDisabled={true}
                accountName={this.state.accountName}
                accPerDisplay={this.state.accPerDisplay}
                accPerRequest={this.state.accPerReq}
                selectedOprId={this.state.selectedOprId}
                handlePendingReconcileModal={this.viewPendingReconcile}>
              </PendingReconcile>
            </>
          )}
          {this.state.isExportReconcileReport && (
            <div className="reconciliation-modal modal">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="d-flex align-items-center export-modal-header">
                    <div className="modal-title page-title mr-auto">
                      {/* <div className="tag-name">Reconciliation Report</div> */}
                      <div className="ac-name">{this.state.accountName}</div>
                    </div>
                    <div className="action">
                      <div className="d-flex justify-content-end">
                        {<a ref={this.linkRef}></a>}
                        <Dropdown className="more-action">
                          <Dropdown.Toggle id="dropdown-export" className="rmv-brdr">Export</Dropdown.Toggle>
                          <Dropdown.Menu alignRight>
                            <Dropdown.Item onClick={() => this.exportReport("PDF")} >
                              PDF
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => this.exportReport("Excel")} >
                              Excel
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <div className="separator"></div>
                        <button type="button" className="close" onClick={() => this.hideDepositModal()}>
                          <span aria-hidden="true">×</span><span className="sr-only">Close</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="modal-body report-viewer">
                    {this.state.parameterSet &&
                      <ReportViewer onReportload={this.props?.onReportload} />
                    }
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.state.isReconcileWithdrawal && (
            <ReconcileWithdrawals
              acctStatus={this.state.status}
              headerControlsDisabled={true}
              accountName={this.state.accountName}
              periodValue={this.state.accPerDisplay}
              hidValue={this.state.ehid}
              hotelName={""}
              accper={this.state.accPerReq}
              OprID={this.state.selectedOprId}
              AccountType={this.state.AccountType}
              pageType={this.state.pageType}
              acctType={this.state.selectedAcctType}
              handleNextbutton={this.handleNextButton}
              handlePendingReconcileModal={this.handlePendingModal}>
            </ReconcileWithdrawals>
          )}
          {this.state.isReconcileDeposit && (
            <ReconcileDeposits
              acctStatus={this.state.status}
              headerControlsDisabled={true}
              accountName={this.state.accountName}
              periodValue={this.state.accPerDisplay}
              hidValue={this.state.ehid}
              hotelName={this.state.hotelName}
              accper={this.state.accPerReq}
              OprID={this.state.selectedOprId}
              AccountType={this.state.AccountType}
              pageType={this.state.pageType}
              acctType={this.state.selectedAcctType}
              handleNextbutton={this.handleNextButton}
              handleReconcileDepositModal={this.handleDepositModal}
              accountTypeList={this.state.depositDataArr}
              isGroup={this.state.isGroup}
            >
            </ReconcileDeposits>
          )}
        </div>
        {this.state.apisCalledCount !== noOfApis && !this.state.stopLoader && (
          <div className="loader-spinner reconciliation-summary d-flex justify-content-center align-items-center">
            <Spinner animation="border" variant="success" />
          </div>
        )}
        <Modal className="move-invoice-modal mark-invoice-as-paid" show={this.state.isModelReconcileAll} onHide={() => { this.setState({ isModelReconcileAll: false }) }}>
          <Modal.Header closeButton>
            <Modal.Title>Reconcile All</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <ul>{
                this.state.reconcileType.map(element =>
                  <li className="d-flex"><Form.Check checked disabled />{element.type1}</li>
                )
              }
              </ul>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-outline-primary" onClick={() => { this.setState({ isModelReconcileAll: false }) }}>Cancel</Button>
            <Button className="btn-primary" onClick={() => { this.handleReconcileAll() }}>Confirm</Button>
          </Modal.Footer>
        </Modal>
        <Modal className="move-invoice-modal ModelGroupDepositMappings" backdrop="static" show={this.state.isModelGroupDepositMappings} onHide={ () => {this.onExitGroupDepositMapping()}}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.isEditGroupMapping ? "Edit Group Reconciliation Mappings" : "Group Reconciliation Mappings"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <div className="section">
                <div className="info-txt">
                  {this.state.groupMappings.filter((e) => e.groupID > 0).length > 0 ? "You can group certain deposit types together to make reconciliation easier." : "Group certain deposit types together to make reconciliation easier."}
                </div>
                <div className="list-sec d-flex align-items-end">
                  <div className="sec">
                    {this.state.groupMappingsEHID.length > 0 &&
                      this.state.groupMappingsEHID.map(
                        (ehid: any, index: any) => (
                          <div className="list-item d-flex">
                            <div className="ehid">
                              {index == 0 && <div className="item ttl">EHID</div>}
                              <div className="item">{ehid}</div>
                            </div>
                            <div className="grouped-type">
                              {index == 0 && <div className="item ttl">Grouped Types</div>}
                              {this.state.groupMappings.filter((e) => e.lettercode === ehid).map(
                                (item: any, index: any) => (
                                  <div className="d-flex">
                                    {item.displayGroupName === "Add Deposit Grouping" ?
                                      <div className="item d-flex">
                                        <Button variant="primary" type="button" className="btn-link edit-link" onClick={() => { this.addOrEditGroup(item,"add") }}>{item.displayGroupName}</Button>
                                      </div>
                                      :
                                      <div className={index == this.state.groupMappings.filter((e) => e.lettercode === ehid).length -1 ? "item d-flex last-child" : "item d-flex"}>
                                        <EllipsisWithTooltip placement="bottom">
                                          <div className="display-name">{item.displayGroupName}</div>
                                        </EllipsisWithTooltip>
                                        <Button variant="primary" type="button" className="btn-link edit-link" onClick={() => { this.addOrEditGroup(item,"edit") }}>{editIcon}</Button>
                                      </div>
                                    }
                                    {item.otherGroup !== "" &&
                                      <div className="d-flex align-items-end action-link">
                                        <Button variant="primary" type="button" className="btn-link" onClick={() => { this.addOrEditGroup(item,"add") }}>{item.otherGroup}</Button>
                                      </div>
                                    }
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        )
                      )
                    }
                  </div>
                  {/* <Button variant="primary" type="button" className="btn-link" onClick={() => { this.setState({ isAddAnotherGroupModal: true }) }}>Add Another Group</Button> */}
                </div>
              </div>
              {this.state.isAddAnotherGroupModal && (
                <div className="add-another-group d-flex align-items-start flex-column">
                  {!this.state.isEditGroupMapping ? 
                    <div className="info-txt">
                      Select which account types you would like to group together.
                    </div> :
                    <>
                      <div className="info-txt">
                        Select which account types you would like to edit. Removing all will delete the group. Once delected,
                        you can create a new group with these types included on the next screen.
                      </div>
                    </>
                  }
                  <div className="listing">
                    {this.state.groupMappingAccountTypes.filter((item) => item.hid === this.state.selectedGroup.hid).length > 0 &&
                      this.state.groupMappingAccountTypes.filter((item) => item.hid === this.state.selectedGroup.hid).map(
                        (item: any, index: any) => (
                          <div className="list">
                            <Form.Check
                              custom
                              type="checkbox"
                              key={"custom-deposit-group-" + index}
                              id={"custom-deposit-group-" + index}
                              label={item.displayName}
                              checked={item.ischeck === 1 || item.ischeck === 2}
                              disabled={item.ischeck === 1 && item.groupID !== this.state.selectedGroup.groupID}
                              onChange={(e) => this.handleGroupMappingCheckbox(e, item)}
                            />
                          </div>
                        )
                      )
                    }
                  </div>
                  <div className="footer mt-auto d-flex justify-content-end">
                    <Button className="btn-outline-primary" onClick={() => { this.cancelCreateGroup() }}>Cancel</Button>
                    {/* <Button className="btn-primary" disabled={ (this.state.checkedGroupList.length <= 1 && !this.state.isEditGroupMapping) || (this.state.checkedGroupList.length === 1 && this.state.isEditGroupMapping) || this.state.isSaveorEditGroupClicked} */}
                    <Button className="btn-primary" disabled={(this.state.checkedGroupList.length === 0 && !this.state.isEditGroupMapping) || this.state.isSaveorEditGroupClicked}
                      onClick={() => { this.saveGroup() }}
                    >{this.state.isEditGroupMapping ? "Edit Group" : "Create Group"}</Button>
                  </div>
                </div>
              )}
            </>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-outline-primary" onClick={() => { this.onExitGroupDepositMapping() }}>Exit</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}