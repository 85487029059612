import React from "react";
import Joi from "joi-browser";
import {
  Button,
  Dropdown,
  Form,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import _ from "lodash";
import { DropDownList } from "../../Reports/common-components/dropdown-list";
import Calendar from "react-calendar";
import { Utils } from "../../../Common/Utilis";
import { StaticArrays } from "../../../Common/StaticArrays";
import { HotelWideSettings } from "./HotelWideSettings";
import { toast } from "react-toastify";
import moment from "moment";
import OutsideClickHandler from "react-outside-click-handler";
import BootstrapTable from "react-bootstrap-table-next";
import { AddPayrollAddress } from "./AddPayrollAddress";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import chevronRight from "../../../Common/Assets/Images/icons/bx-chevron-right.svg";
import { AddDepositAccount } from "./AddDepositAccount";
import { UserManagement } from "./../../../Common/Services/UserManagement";
import { IEnterPriseWisePayroll } from "../../../Common/Contracts/IEnterpriseWisePayroll";
import { AddDeduction } from "./AddDeduction";
import { AddGarnishment } from "./AddGarnishment";
import ReactTooltip from "react-tooltip";
import { IHotelWideSettingProps } from "../../../Common/Contracts/IHotelWideSettingProps";
import { InfoTooltipRed } from "../../../Common/Components/InfoTooltipRed";
import { AgGridReact } from "ag-grid-react";
import { GridOptions } from "ag-grid-community";
import NumericEditor from "./NumericEditor";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { confirmAlert } from "react-confirm-alert";
// import NumericEditor from "./NumericEditor"

export class Payroll extends React.Component<any, any> {
  private enterpriseWideSettingFormSchema = Joi.object({
    // ssn: Joi.string()
    //   .required()
    //   .min(11)
    //   .regex(/^(?!(000|9|666))\d{3}-(?!00)\d{2}-(?!0000)\d{4}$/i, "ssn")
    //   .error((errors) => {
    //     const _errors = errors.map((error) => {
    //       if (error?.message) {
    //         return;
    //       }
    //       switch (error.type) {
    //         case "any.empty":
    //           return { message: "Please enter SSN" };
    //         case "string.min":
    //           return {
    //             message:
    //               "Incorrect SSN format. SSN should not start with 9 format should include nine digits, for example 123-45-7689",
    //           };
    //         case "string.regex.name":
    //           return {
    //             message:
    //               "Incorrect SSN format. SSN should not start with 9 format should include nine digits, for example 123-45-7689",
    //           };
    //       }
    //     });

    //     return [_errors[0]];
    //   }),
    dob: Joi.string().allow(""),
    ssn: Joi.string().allow(""),
    i9SubmittedOn: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select I-9 Submitted On" };
        });
      }),
    w4Date: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select W-4 Submitted On" };
        });
      }),
    gender: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select Gender" };
        });
      }),
    address: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please enter Address" };
        });
      }),
    jobType: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select Job Type" };
        });
      }),

    country: Joi.string().regex(/^[A-Za-z\s]*$/,"country").allow("")
    .error((errors) => {
      return errors.map((error) => {
        if (error?.message) {
          return;
        }
        if(error?.type === "string.regex.name")
        {
          return { message: "Please enter Valid County/Municipality" };
        }
       
      });
    }),
    school: Joi.string().regex(/^[A-Za-z0-9\s]*$/,"school").allow("")
    .error((errors) => {
      return errors.map((error) => {
        if (error?.message) {
          return;
        }
        if(error?.type === "string.regex.name")
        {
          return { message: "Please enter Valid School District" };
        }
      });
    })
  });

  private excemptionsFormSchema = Joi.object({
    federalExemption: Joi.number()
      .min(0)
      .max(99)
      .required()
      .error((errors) => {
        const _errors = errors.map((error) => {
          if (error?.message) {
            return;
          }
          switch (error.type) {
            case "number.base":
              return {
                message: "Please enter Federal Exemptions in numeric only",
              };
            case "any.required":
              return {
                message:
                  "Federal Exemptions value should be greater than or equal to 0",
              };

            case "number.min":
              return {
                message:
                  "Federal Exemptions value should be greater than or equal to 0",
              };
            case "number.max":
              return {
                message:
                  "Federal Exemptions value should not be more than 2 digits",
              };
          }
        });
        return [_errors[0]];
      }),
    additionalFederal: Joi.number()
      .min(0)
      .error((errors) => {
        const _errors = errors.map((error) => {
          if (error?.message) {
            return;
          }
          switch (error.type) {
            case "number.base":
              return {
                message: "Please enter Additional Withholdings in numeric only",
              };
            case "any.required":
              return {
                message:
                  "Additional Withholdings value should be greater than or equal to 0",
              };
            case "number.min":
              return {
                message:
                  "Additional Withholdings value should be greater than or equal to 0",
              };
          }
        });
        return [_errors[0]];
      }),
    futa: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select FUTA" };
        });
      }),
    fica: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select FICA" };
        });
      }),
    stateExemption: Joi.number()
      .min(0)
      .max(99)
      .required()
      .error((errors) => {
        const _errors = errors.map((error) => {
          if (error?.message) {
            return;
          }
          switch (error.type) {
            case "number.base":
              return {
                message: "Please enter State Exemptions in numeric only",
              };
            case "any.required":
              return {
                message:
                  "State Exemptions value should be greater than or equal to 0",
              };
            case "number.min":
              return {
                message:
                  "State Exemptions value should be greater than or equal to 0",
              };
            case "number.max":
              return {
                message:
                  "State Exemptions value should not be more than 2 digits",
              };
          }
        });
        return [_errors[0]];
      }),
  });

  private payrollWithholdingSettingsFormSchema = Joi.object({
    use2020W4: Joi.boolean().optional(),
    federalFiling: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select Federal Filing Status" };
        });
      }),
    noOfFederalAllowances: Joi.number()
      .min(0)
      .error((errors) => {
        return errors.map((error) => {
          return {
            message: "Federal Allowances value should be greater or equal to 0",
          };
        });
      }),
    isJobs: Joi.boolean().optional(),
    otherIncome: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please enter Other Income" };
        });
      }),

    creditAmt: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please enter Dependents Amount" };
        });
      }),
    wksht: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please enter Deductions" };
        });
      }),
    extraAmt: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please enter Additional Federal W/H" };
        });
      }),
  });

  private payrollWithholdingSettingsFormSchemaOptional = Joi.object({
    use2020W4: Joi.boolean().optional(),
    federalFiling: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select Federal Filing Status" };
        });
      }),
    noOfFederalAllowances: Joi.number()
      .min(0)
      .error((errors) => {
        return errors.map((error) => {
          return {
            message: "Federal Allowances value should be greater or equal to 0",
          };
        });
      }),
    isJobs: Joi.boolean().optional(),
    otherIncome: Joi.number()
      .min(0)
      .allow(0)
      .error((errors) => {
        const _errors = errors.map((error) => {
          if (error?.message || !error?.type) {
            return;
          }
          switch (error.type) {
            case "number.base":
              return {
                message: "Please enter Other Income in numeric only",
              };
            case "number.min":
              return {
                message: "Please enter Other Income in numeric only",
              };
            case "number.max":
              return {
                message: "Other Income value should not be more than 8 digits",
              };
          }
        });
        return [_errors[0]];
      }),

    creditAmt: Joi.number()
      .min(0)
      .allow(0)
      .error((errors) => {
        const _errors = errors.map((error) => {
          if (error?.message || !error?.type) {
            return;
          }
          switch (error.type) {
            case "number.base":
              return {
                message: "Please enter Dependents Amount in numeric only",
              };
            case "number.min":
              return {
                message: "Please enter Dependents Amount in numeric only",
              };
            case "number.max":
              return {
                message: "Dependents Amount should not be more than 8 digits",
              };
          }
        });
        return [_errors[0]];
      }),
    wksht: Joi.number()
      .min(0)
      .allow(0)
      .error((errors) => {
        const _errors = errors.map((error) => {
          if (error?.message || !error?.type) {
            return;
          }
          switch (error.type) {
            case "number.base":
              return {
                message: "Please enter Deductions in numeric only",
              };
            case "number.min":
              return {
                message: "Deductions value should be greater or equal to 0",
              };
            case "number.max":
              return {
                message: "Deductions value should not be more than 8 digits",
              };
          }
        });
        return [_errors[0]];
      }),
    extraAmt: Joi.number()
      .min(0)
      .allow(0)
      .error((errors) => {
        const _errors = errors.map((error) => {
          if (error?.message || !error?.type) {
            return;
          }
          switch (error.type) {
            case "number.base":
              return {
                message: "Please enter Additional Federal W/H in numeric only",
              };
            case "number.min":
              return {
                message:
                  "Extra Additional Federal W/H should be greater or equal to 0",
              };
            case "number.max":
              return {
                message:
                  "Additional Federal W/H should not be more than 8 digits",
              };
          }
        });
        return [_errors[0]];
      }),
  });

  private sectionNames: any[] = [
    {
      id: 1,
      sectionName: "Direct Deposit",
      banner:
        "You must complete Direct Deposit settings in order to activate this user",
    },
    {
      id: 2,
      sectionName: "Exemptions",
      banner: "",
    },
    {
      id: 3,
      sectionName: "Federal Taxes",
      banner: "",
    },
  ];
  countTimer: any;
  gridApi: any;
  gridColumnApi: any;
  constructor(props: any) {
    super(props);
    this.state = {
      isFormValid: false,
      isEnterpriseWideDataLoaded: false,
      isDataSubmitting: false,
      showPayrollAddressModal: false,
      showDepositModal: false,
      showDeductionModal: false,
      deductionTableExpandend: [],
      tableExpended: [],
      isExpand: false,
      calenderShow: false,
      calenderShowI9: false,
      calenderShowW4: false,
      isEnterpriseWideSettingNotChanged: false,
      isAllowDeposit:
        +this.props?.userGeneralInfo?.allowDeposit === 1 ? true : false,
      isExemptionsNotChanged: false,
      inputPosition: 0,
      enterpriseWideSettingErrors: {},
      exemptionsErrors: {},
      enterPriseWisePayrollData: {},
      prevEnterPriseWisePayrollData: {},
      originalEnterpriseWisePayrollData: {},
      depositAccountsData: [],
      depositRowForAddEdit: 1,
      depositRowEdit: {},
      enterpriseWideSetting: {
        ssn: "",
        dob: "",
        gender: "",
        address: "",
        jobType: "",
        country: "",
        school: "",
        i9SubmittedOn: "",
        w4Date: "",
      },
      excemptions: {
        federalExemption: 0,
        additionalFederal: 0.0,
        futa: "No",
        fica: "No",
        stateExemption: 0,
      },
      payrollWithholdingSettings: {
        use2020W4: true,
        federalFiling: "",
        noOfFederalAllowances: "0",
        isJobs: false,
        otherIncome: "0",
        creditAmt: "0",
        wksht: "0",
        extraAmt: "0",
      },
      payrollWithholdingSettingsErrors: {},
      sectionNames: this.sectionNames,
      deductions: [],
      deductionRowEdit: {},
      garnishments: [],
      ganishmentTableExpandend: [],
      isGarnishmentExpand: false,
      showGarnishmentModal: false,
      isDeductionLoaded: false,
      showPayrollSettingBanner: false,
      garnishmentRowEdit: {},
      hotelWideTableData: [],
      isShowTaxFields: false,
      showHotelWiseAddEditModal: false,
      showSpecialRateAddEditModal: false,
      hotelWisePayrollRowEdit: {},
      specialRateRowEdit: {},
      hotelWideTableExpended: [],
      spacialRates: [],
      isHotelWisePayrollLoaded: false,
      isSpecialRateLoaded: false,
      isHotelWideExpand: false,
      hotelWideRow: {},
      duplicateSSNTableData: [],
      showDuplicateSSNModal: false,
      enterpriseWideSettingNotChangedForDate: true,
      localAndStateTaxesFormFields: [],
      isToggleSaved: true,
    };
  }
  componentDidMount() {
    this.getDeductionsData();
    this.getGarnishmentsData();
    this.getHotelWisePayrollData();
    this.getEnterPriseWisePayrollData();
    this.getUserSpecialRates();
  }
  getHotelWisePayrollData = (tabName = "") => {
    this.setState({ 
      // hotelWideTableData: [], 
      isHotelWisePayrollLoaded: false });
    UserManagement.GetHotelWisePayroll(
      +this.props?.userUniqueNo,
      this.props.isNewUserAdded
    )
      .then((hotelWideTableData) => {
        if (hotelWideTableData?.length > 0) {
          hotelWideTableData = hotelWideTableData.map((x) => {
            x.empType1 = this.props?.userGeneralInfo?.employeeTypeDisplay;
            return x;
          });
        }
        debugger;
        this.setState(
          {
            hotelWideTableData: hotelWideTableData?.filter(
              (x) => x?.isPayrollHid === "Yes"
            ),
            isHotelWisePayrollLoaded: true,
          },
          () => {
            this.updatePayrollTabCount(true, true);
            const hotelWideTableExpended = hotelWideTableData
              ?.filter((x) => x.status === "Active")
              ?.map((x) => x.hid);
            const isShowTaxFields =
              hotelWideTableData &&
              hotelWideTableData
                .map(
                  (item) =>
                    // item.status === "Active" &&
                    item.showLocalTaxFields === "YES"
                )
                .some((item) => item);
            this.setState({ isShowTaxFields });

            const withholdingState = hotelWideTableData?.find(
              (x) => x.hid === this.state?.hotelWisePayrollRowEdit?.hid
            )?.withholdingState;

            const empState =
              withholdingState ||
              this.state.enterPriseWisePayrollData?.state ||
              "";
            UserManagement.GetHotelStateAndLocalTax(
              (hotelWideTableData || [])?.map((r) => ({
                stateCode: r?.withholdingState,
                hid: r?.hid,
              })),
              this.props?.userUniqueNo
            )
              .then((localAndStateTaxesFormFields: any[]) => {
                this.setState({ localAndStateTaxesFormFields }, () => {
                  this.setState({
                    hotelWideTableExpended,
                    isHotelWideExpand: true,
                    isSpecialRateLoaded: true,
                  });
                });
              })
              .catch((err) => {
                this.setState({
                  hotelWideTableExpended,
                  isHotelWideExpand: true,
                  isSpecialRateLoaded: true,
                });
              });
          }
        );
      })
      .catch(() => this.setState({ isHotelWisePayrollLoaded: false }));
  };

  getEnterPriseWisePayrollData = (
    isDefaultLoad = false,
    setOnlydirectDeposit = false
  ) => {
    this.setState({ isEnterpriseWideDataLoaded: false });
    UserManagement.GetEnterPriseWisePayroll(
      this.props?.userUniqueNo,
      "",
      this.props?.isNewUserAdded
    ).then((_enterPriseWisePayrollData: IEnterPriseWisePayroll) => {
      _enterPriseWisePayrollData.additionalFederal =
        (+_enterPriseWisePayrollData.additionalFederal === 0
          ? 0.0
          : +_enterPriseWisePayrollData.additionalFederal) || 0.0;
      _enterPriseWisePayrollData.i9SubmittedOn =
        _enterPriseWisePayrollData?.i9SubmittedOn
          ? moment(_enterPriseWisePayrollData?.i9SubmittedOn)?.format(
              "MM/DD/YYYY"
            )
          : "";
      _enterPriseWisePayrollData.w4Date = _enterPriseWisePayrollData?.w4Date
        ? moment(_enterPriseWisePayrollData?.w4Date)?.format("MM/DD/YYYY")
        : "";
      _enterPriseWisePayrollData.noOfFederalAllowances =
        _enterPriseWisePayrollData?.noOfFederalAllowances != null
          ? Number(_enterPriseWisePayrollData?.noOfFederalAllowances)
          : null;

      this.setState(
        {
          originalEnterpriseWisePayrollData: { ..._enterPriseWisePayrollData },
          enterPriseWisePayrollData: { ..._enterPriseWisePayrollData },
          prevEnterPriseWisePayrollData: { ..._enterPriseWisePayrollData },
          isEnterpriseWideDataLoaded: true,
        },
        () => {
          this.setPayrollData(setOnlydirectDeposit);
          this.updatePayrollSettingBannerFlag(_enterPriseWisePayrollData);
          this.checkEnterpriseWideSettingFieldFormModified();
        }
      );
    });
  };

  updatePayrollSettingBannerFlag = (enterPriseWisePayrollData) => {
    this.setState({
      showPayrollSettingBanner: !enterPriseWisePayrollData?.ssn,
    });
  };

  updatePayrollTabCount = (
    isCalledFromMount = false,
    isBlur = false,
    reAttach = "all"
  ) => {
    const { isHotelWisePayrollLoaded, isEnterpriseWideDataLoaded } = this.state;
    if (!isHotelWisePayrollLoaded || !isEnterpriseWideDataLoaded) {
      return;
    }
    console.log({ isCalledFromMount });
    // debugger;
    if (!isCalledFromMount) {
      if (reAttach === "all") {
        this.reAttachEnteriseWideSettingValuesToMainObject(isBlur);
        this.reAttachExemptionsValues(isBlur);
        this.reAttachPayrollWithholdingSettingsValues(isBlur);
      } else if (reAttach === "EnterpriseWideSetting") {
        this.reAttachEnteriseWideSettingValuesToMainObject(isBlur);
      } else if (reAttach === "Exemptions") {
        this.reAttachExemptionsValues(isBlur);
      } else if (reAttach === "PayrollWithholdingSettings") {
        this.reAttachPayrollWithholdingSettingsValues(isBlur);
      }
    }

    const {
      payrollWithholdingSettingsErrors,
      exemptionsErrors,
      isAllowDeposit,
      depositAccountsData,
      hotelWideTableData,
      enterpriseWideSettingErrors,
    } = this.state;
    const { userGeneralInfo } = this.props;

    const sectionNamesData = _.cloneDeep(this.sectionNames).map((x) => {
      if (
        x.sectionName === "Direct Deposit" &&
        (!isAllowDeposit || (isAllowDeposit && depositAccountsData?.length > 0))
      ) {
        x.banner = "";
      }
      if (x.sectionName === "Exemptions") {
        x.banner = "";
      }
      if (
        x.sectionName === "Federal Taxes" &&
        userGeneralInfo.groupWidholdingSetting
      ) {
        x.banner = "";
      }
      return x;
    });

    const keys = [
      "ssn",
      "dob",
      "gender",
      "address",
      "jobType",
      "country",
      "school",
      "w4Date",
      "i9SubmittedOn",
    ];

    const tabCount =
      sectionNamesData?.filter((x) => x?.banner)?.length +
      (hotelWideTableData?.length === 0
        ? 0
        : hotelWideTableData.filter(
            (x) =>
              x.status === "Active" &&
              (!x?.withholdingState || !x?.unemploymentState)
          )?.length) +
      Object.keys(enterpriseWideSettingErrors).filter((x) => keys.includes(x))
        ?.length +
      (Object.keys(payrollWithholdingSettingsErrors)?.length > 0 ? 1 : 0) +
      (Object.keys(exemptionsErrors)?.length > 0 ? 1 : 0);
    if (this.countTimer) {
      clearTimeout(this.countTimer);
    }
    this.countTimer = setTimeout(() => {
      this.props?.updatePayrollCount(tabCount, isAllowDeposit ? 1 : 0);
    }, 1000);
  };

  getDeductionsData = (isDefaultLoad = false) => {
    this.setState({ isDeductionLoaded: false });
    UserManagement.GetUserDeduction(this.props?.userGeneralInfo?.user_UniqueId)
      .then((deductions: any) => {
        if (deductions?.length > 0) {
          deductions = deductions.map((x) => {
            x.createdDate = x.createdDate
              ? moment(x.createdDate)?.format("MM/DD/YYYY")
              : "";
            return x;
          });
        }
        this.setState(
          {
            deductions,
          },
          () => {
            this.setState({ isDeductionLoaded: true });
          }
        );
      })
      .catch(() => this.setState({ isDeductionLoaded: true }));
  };

  getGarnishmentsData = (isDefaultLoad = false) => {
    UserManagement.GetUserGarnishment(this.props?.userUniqueNo).then(
      (garnishments: any) => {
        if (garnishments?.length > 0) {
          garnishments = garnishments.map((x) => {
            x.date = x.date ? moment(x.date)?.format("MM/DD/YYYY") : "";
            return x;
          });
        }
        this.setState({
          garnishments,
        });
      }
    );
  };

  handleShowDuplicateSSNModal = () => {
    this.setState({ showDuplicateSSNModal: true });
  };

  hideDuplicateSSNModal = () => {
    const enterpriseWideSettingErrors = _.cloneDeep(
      this.state.enterpriseWideSettingErrors
    );
    enterpriseWideSettingErrors.ssn =
      "This SSN is already associated with another user";
    this.setState(
      { enterpriseWideSettingErrors, showDuplicateSSNModal: false },
      () => this.updatePayrollTabCount()
    );
  };

  setPayrollData = (setOnlydirectDeposit = false) => {
    if (setOnlydirectDeposit) {
      this.buildDepositTableData();
    } else {
      this.setEnteriseWideSettingValues();
      this.setExemptionsValues();
      this.setPayrollWithholdingSettingsValues();
      this.buildDepositTableData();
    }
  };

  setEnteriseWideSettingValues = () => {
    const data = this.state.enterPriseWisePayrollData as IEnterPriseWisePayroll;
    const enterpriseWideSetting: any = {
      ssn: data.ssn || "",
      dob: data.dob || "",
      gender: data.gender || "",
      address: this.buildAddress(data),
      jobType: data.jobType || "",
      country: data?.country || "",
      school: data?.school || "",
      i9SubmittedOn: data?.i9SubmittedOn || "",
      w4Date: data?.w4Date || "",
    };

    this.setState({ enterpriseWideSetting }, () =>
      this.validateEnterpriseWideSettingFormSchema(true, true)
    );
  };

  buildDepositTableData = () => {
    const data = this.state.enterPriseWisePayrollData as IEnterPriseWisePayroll;
    let depositAccountsData: any[] = [];

    const row1: any = {
      directDeposit:
        data.directDeposit ||
        (this.props?.userGeneralInfo?.allowDeposit === 1 ? "Yes" : ""),
      bankName: data.bankName,
      accountType: data.accountType,
      routingNumber: data.routingNumber,
      accountNumber: data.accountNumber,
      ddAmountType:
        data.ddAmountType === "Total Payroll"
          ? "Entire Paycheck"
          : data.ddAmountType === "$Amount of Paycheck"
          ? "$ Amount of Paycheck"
          : data.ddAmountType,
      ddAmount: data.ddAmount,
      rowNo: 1,
    };

    const row2: any = {
      directDeposit: data.directDeposit2,
      bankName: data.bankName2,
      accountType: data.accountType2,
      routingNumber: data.routingNumber2,
      accountNumber: data.accountNumber2,
      ddAmountType:
        data.ddAmountType2 === "Total Payroll"
          ? "Entire Paycheck"
          : data.ddAmountType2 === "$Amount of Paycheck"
          ? "$ Amount of Paycheck"
          : data.ddAmountType2,
      ddAmount: data.ddAmount2,
      rowNo: 2,
    };

    if (data?.directDeposit && data.accountType) {
      depositAccountsData.push(row1);
    }
    if (data?.directDeposit2 && data.accountType2) {
      depositAccountsData.push(row2);
    }

    depositAccountsData = depositAccountsData.map((row) => {
      row.isRowDataSubmitting = false;
      row.dataLength = depositAccountsData.length;
      return row;
    });

    this.setState({ depositAccountsData: [] }, () =>
      this.setState({ depositAccountsData }, () =>
        this.updatePayrollTabCount(true, true)
      )
    );
  };

  setExemptionsValues = () => {
    const data = this.state.enterPriseWisePayrollData as IEnterPriseWisePayroll;
    const excemptions: any = {
      federalExemption: data.federalExemption || 0,
      additionalFederal:
        (+data.additionalFederal === 0 ? 0.0 : data.additionalFederal) || 0.0,
      futa: data.futa || "No",
      fica: data.fica || "No",
      stateExemption: data.stateExemption || 0,
    };

    this.setState({ excemptions }, () => {
      this.validateExemptionsFormSchema(true, true);
    });
  };

  reAttachExemptionsValues = (isBlur = false) => {
    const { enterPriseWisePayrollData, excemptions, exemptionsErrors } =
      this.state;

    enterPriseWisePayrollData.federalExemption =
      excemptions?.federalExemption || 0;
    enterPriseWisePayrollData.additionalFederal =
      (+excemptions.additionalFederal === 0
        ? 0.0
        : excemptions.additionalFederal) || 0.0;
    enterPriseWisePayrollData.futa = excemptions.futa;
    enterPriseWisePayrollData.fica = excemptions.fica;
    enterPriseWisePayrollData.stateExemption = excemptions.stateExemption;
    this.setState({ enterPriseWisePayrollData }, () => {
      if (!isBlur) {
        this.checkEnterpriseWideSettingFieldFormModified();
      }
    });
  };

  setPayrollWithholdingSettingsValues = () => {
    const data = this.state.enterPriseWisePayrollData as IEnterPriseWisePayroll;
    console.log({ data });
    const payrollWithholdingSettings: any = {
      use2020W4: data.use2020W4 === 0 ? false : true,
      federalFiling: data.federalFiling || "",
      noOfFederalAllowances: data.noOfFederalAllowances || 0,
      isJobs: (data.isJobs === "true" ? true : false) || false,
      otherIncome: data.otherIncome
        ? Utils.currencyFormat(data.otherIncome)
        : "$0.00",
      creditAmt: data.creditAmt
        ? Utils.currencyFormat(data.creditAmt)
        : "$0.00",
      wksht: data.wksht ? Utils.currencyFormat(data.wksht) : "$0.00",
      extraAmt: data.extraAmt ? Utils.currencyFormat(data.extraAmt) : "$0.00",
    };
    this.setState({ payrollWithholdingSettings }, () => {
      this.validatePayrollWithholdingSettingsFormSchema(true, true);
    });
  };

  reAttachPayrollWithholdingSettingsValues = (isBlur) => {
    const {
      enterPriseWisePayrollData,
      enterpriseWideSetting,
      payrollWithholdingSettings,
      payrollWithholdingSettingsErrors,
      prevEnterPriseWisePayrollData,
    } = this.state;

    debugger;
    console.log({ enterPriseWisePayrollData, payrollWithholdingSettings });
    enterPriseWisePayrollData.isJobs =
      payrollWithholdingSettings.use2020W4 == false
        ? "false"
        : payrollWithholdingSettings.isJobs.toString() || "false";
    enterPriseWisePayrollData.use2020W4 =
      payrollWithholdingSettings.use2020W4 == false ? 0 : 1;
    enterPriseWisePayrollData.federalFiling =
      payrollWithholdingSettings.federalFiling || "";
    enterPriseWisePayrollData.noOfFederalAllowances =
      payrollWithholdingSettings.use2020W4 == false
        ? payrollWithholdingSettings.noOfFederalAllowances || 0
        : 0;
    enterPriseWisePayrollData.otherIncome =
      payrollWithholdingSettings.use2020W4 == false
        ? null
        : payrollWithholdingSettings.otherIncome
        ? Utils.removeInvaildCharFromAmount(
            payrollWithholdingSettings.otherIncome,
            10
          )
        : 0;
    enterPriseWisePayrollData.creditAmt =
      payrollWithholdingSettings.use2020W4 == false
        ? null
        : payrollWithholdingSettings.creditAmt
        ? Utils.removeInvaildCharFromAmount(
            payrollWithholdingSettings.creditAmt,
            10
          )
        : 0;
    enterPriseWisePayrollData.wksht =
      payrollWithholdingSettings.use2020W4 == false
        ? null
        : payrollWithholdingSettings.wksht
        ? Utils.removeInvaildCharFromAmount(
            payrollWithholdingSettings.wksht,
            10
          )
        : 0;
    enterPriseWisePayrollData.extraAmt = payrollWithholdingSettings.extraAmt
      ? Utils.removeInvaildCharFromAmount(
          payrollWithholdingSettings.extraAmt,
          10
        )
      : 0;

    this.setState({ enterPriseWisePayrollData }, () => {
      if (!isBlur) {
        this.checkEnterpriseWideSettingFieldFormModified();
      }
    });
  };

  reAttachEnteriseWideSettingValuesToMainObject = (isBlur = false) => {
    const {
      enterPriseWisePayrollData,
      enterpriseWideSetting,
      enterpriseWideSettingErrors,
    } = this.state;

    enterPriseWisePayrollData.ssn = enterpriseWideSetting.ssn;
    enterPriseWisePayrollData.dob = enterpriseWideSetting.dob;
    enterPriseWisePayrollData.gender = enterpriseWideSetting.gender;
    enterPriseWisePayrollData.jobType = enterpriseWideSetting.jobType;
    enterPriseWisePayrollData.country = enterpriseWideSetting.country;
    enterPriseWisePayrollData.school = enterpriseWideSetting.school;
    enterPriseWisePayrollData.i9SubmittedOn =
      enterpriseWideSetting.i9SubmittedOn;
    enterPriseWisePayrollData.w4Date = enterpriseWideSetting.w4Date;

    this.setState({ enterPriseWisePayrollData }, () => {
      if (!isBlur) {
        this.checkEnterpriseWideSettingFieldFormModified();
      }
    });
  };

  reAttachEnteriseWideSettingAddressToMainObject = (data, callback=()=>{}) => {
    const { enterPriseWisePayrollData, enterpriseWideSetting } = this.state;

    enterPriseWisePayrollData.street = data.street;
    enterPriseWisePayrollData.city = data.city;
    enterPriseWisePayrollData.state = data.state;
    enterPriseWisePayrollData.zip = data.zip;
    enterpriseWideSetting.address = this.buildAddress(data);
    this.setState({ enterPriseWisePayrollData, enterpriseWideSetting }, () => {
      this.validateEnterpriseWideSettingFormSchema();
      this.checkEnterpriseWideSettingFieldFormModified();
      callback()
    });
  };

  buildAddress = (data: any) => {
    return data.state
      ? `${data.street}, ${data.city}, ${data.state}, ${data.zip}`
      : "";
  };

  buildRverseAddressData = (data: any) => {
    const _data=data.split(",")
    let obj={
      street:"",
      city:"",
      state:"",
      zip:"",
    }
    if(_data.length > 0){
       obj={
        street:_data[0]?.trim(),
        city:_data[1]?.trim(),
        state:_data[2]?.trim(),
        zip:_data[3]?.trim()
      }
    }
    
    return obj
  };



  onEnterpriseWideSettingFieldChange = (
    event,
    inputPosition,
    isBlur = false
  ) => {
    let value = event.target?.value;
    const fieldName = event.target?.name;
    if (
      fieldName === "ssn" &&
      value?.toString()?.length >
        this.state.enterpriseWideSetting?.ssn?.toString()?.length
    ) {
      const pattern = /^[0-9\-]+$/;
      if (!pattern.test(value)) {
        return;
      }
    }
    debugger;

    const enterpriseWideSetting = _.cloneDeep(this.state.enterpriseWideSetting);

    if (
      fieldName === "dob" ||
      fieldName === "w4Date" ||
      fieldName === "i9SubmittedOn"
    ) {
      const _formattedDate = Utils.AddSlashINInputDateFormat(
        value,
        enterpriseWideSetting[fieldName],
        true,
        4,
        isBlur
      );
      if (_formattedDate === "invalid") {
        return;
      } else {
        value = _formattedDate;
      }
    }

    if (fieldName === "ssn") {
      const _formattedValue = Utils.AddHypenSSNFormat(
        value,
        enterpriseWideSetting?.ssn
      );
      if (_formattedValue === "invalid") {
        return;
      } else {
        value = _formattedValue;
      }
    }

    if (fieldName === "country") {
      if (!value?.trim()) {
        value = "";
      } else {
        value = value?.toString()?.trimLeft();
        // value = Utils.AllowOnlyAlphabetsWithRightSpace(value);
      }
    }
    if (fieldName === "school") {
      if (!value?.trim()) {
        value = "";
      } else {
        value = value?.toString()?.trimLeft();
        // value = Utils.AllowOnlyAlphaNumricWithRightSpace(value);
      }
    }

    enterpriseWideSetting[fieldName] = value;

    this.setState({ enterpriseWideSetting, inputPosition }, () => {
      this.validateEnterpriseWideSettingFormSchema(false, isBlur);
    });
  };

  checkEnterpriseWideSettingFieldFormModified = (isLoadedFromApi = false) => {
    const {
      enterPriseWisePayrollData,
      prevEnterPriseWisePayrollData,
      enterpriseWideSetting,
      isFormValid,
    } = this.state;

    enterPriseWisePayrollData.otherIncome =
      +enterPriseWisePayrollData?.otherIncome;
    enterPriseWisePayrollData.creditAmt = +enterPriseWisePayrollData?.creditAmt;
    enterPriseWisePayrollData.wksht = +enterPriseWisePayrollData?.wksht;
    enterPriseWisePayrollData.extraAmt = +enterPriseWisePayrollData?.extraAmt;

    prevEnterPriseWisePayrollData.otherIncome =
      +prevEnterPriseWisePayrollData?.otherIncome;
    prevEnterPriseWisePayrollData.creditAmt =
      +prevEnterPriseWisePayrollData?.creditAmt;
    prevEnterPriseWisePayrollData.wksht = +prevEnterPriseWisePayrollData?.wksht;
    prevEnterPriseWisePayrollData.extraAmt =
      +prevEnterPriseWisePayrollData?.extraAmt;

    let _isEnterpriseWideSettingNotChanged = _.isEqual(
      enterPriseWisePayrollData,
      prevEnterPriseWisePayrollData
    );

    if (enterPriseWisePayrollData?.iF_RecordId > 0) {
      const main = {
        federalFiling: enterPriseWisePayrollData?.federalFiling,
        isJobs: enterPriseWisePayrollData?.isJobs,
        otherIncome: +enterPriseWisePayrollData?.otherIncome,
        creditAmt: +enterPriseWisePayrollData?.creditAmt,
        wksht: +enterPriseWisePayrollData?.wksht,
        extraAmt: +enterPriseWisePayrollData?.extraAmt,
      };

      const comparison = {
        federalFiling: prevEnterPriseWisePayrollData?.federalFiling,
        isJobs: prevEnterPriseWisePayrollData?.isJobs,
        otherIncome: +prevEnterPriseWisePayrollData?.otherIncome,
        creditAmt: +prevEnterPriseWisePayrollData?.creditAmt,
        wksht: +prevEnterPriseWisePayrollData?.wksht,
        extraAmt: +prevEnterPriseWisePayrollData?.extraAmt,
      };

      let _isEnterpriseWideSettingNotChangedForDate = _.isEqual(
        main,
        comparison
      );
      if (_isEnterpriseWideSettingNotChangedForDate === false) {
        _isEnterpriseWideSettingNotChanged = _.isEqual(
          enterPriseWisePayrollData,
          prevEnterPriseWisePayrollData
        );
      }

      this.setState({
        enterpriseWideSettingNotChangedForDate:
          _isEnterpriseWideSettingNotChangedForDate,
        isEnterpriseWideSettingNotChanged: _isEnterpriseWideSettingNotChanged,
      });
      this.props.getPayrollTabModificationStatus(
        !_isEnterpriseWideSettingNotChanged
      );
    } else {
      this.setState({
        isEnterpriseWideSettingNotChanged: _isEnterpriseWideSettingNotChanged,
      });
      this.props.getPayrollTabModificationStatus(
        !_isEnterpriseWideSettingNotChanged
      );
    }
  };

  getEnterpriseWideSettingFieldFieldError = (
    fieldName: string,
    fieldPosition: number
  ) => {
    const { enterpriseWideSettingErrors } = this.state;

    return (
      <>
        {Object.keys(enterpriseWideSettingErrors).length > 0 &&
          fieldPosition <= this.state.inputPosition && (
            <span className="validation-message">
              {enterpriseWideSettingErrors[fieldName]}
            </span>
          )}
      </>
    );
  };

  validateEnterpriseWideSettingFormSchema = (
    isCalledFromMount = false,
    isBlur = false
  ) => {
    const { enterpriseWideSetting } = this.state;
    const valid = Joi.validate(
      this.state.enterpriseWideSetting,
      this.enterpriseWideSettingFormSchema,
      {
        abortEarly: false,
      }
    );

    let newErrorObject: any = {};
    if (valid.error) {
      valid.error.details.forEach((err) => {
        newErrorObject[err.path.join(".")] = err.message;
      });
    }

    if (
      newErrorObject?.ssn &&
      !this.props?.hasViewSensitiveDataPermission &&
      enterpriseWideSetting?.ssn?.includes("*******")
    ) {
      delete newErrorObject?.ssn;
    }

    if (enterpriseWideSetting.i9SubmittedOn) {
      let splittedDate = enterpriseWideSetting.i9SubmittedOn
        ?.toString()
        ?.split("/");
      if (
        moment(enterpriseWideSetting.i9SubmittedOn)?.format("MM/DD/YYYY") ===
          "Invalid date" ||
        enterpriseWideSetting.i9SubmittedOn?.toString()?.length !== 10 ||
        splittedDate[0]?.toString()?.length !== 2 ||
        splittedDate[1]?.toString()?.length !== 2 ||
        splittedDate[2]?.toString()?.length !== 4
      ) {
        newErrorObject["i9SubmittedOn"] =
          "I-9 Submitted On Date should be in MM/DD/YYYY format";
      } else {
        if (newErrorObject["i9SubmittedOn"]) {
          delete newErrorObject["i9SubmittedOn"];
        }
      }
    }

    if (enterpriseWideSetting.w4Date) {
      let splittedDate = enterpriseWideSetting.w4Date?.toString()?.split("/");
      if (
        moment(enterpriseWideSetting.w4Date)?.format("MM/DD/YYYY") ===
          "Invalid date" ||
        enterpriseWideSetting.w4Date?.toString()?.length !== 10 ||
        splittedDate[0]?.toString()?.length !== 2 ||
        splittedDate[1]?.toString()?.length !== 2 ||
        splittedDate[2]?.toString()?.length !== 4
      ) {
        newErrorObject["w4Date"] =
          "W-4 Submitted On Date should be in MM/DD/YYYY format";
      } else {
        if (newErrorObject["w4Date"]) {
          delete newErrorObject["w4Date"];
        }
      }
    }

    this.setState({ enterpriseWideSettingErrors: newErrorObject }, () =>
      this.updatePayrollTabCount(
        isCalledFromMount,
        isBlur,
        "EnterpriseWideSetting"
      )
    );

    if (Object.keys(newErrorObject).length === 0 && !isCalledFromMount) {
      this.setState({ isFormValid: true });
    } else {
      this.setState({ isFormValid: false });
    }
    return newErrorObject;
  };

  validationEnterpriseWideSettingOnBlur = (inputPosition) => {
    this.setState({ inputPosition });
  };

  onExcemptionsFieldChange = (event, inputPosition, isBlur = false) => {
    let value = event.target.value;
    const fieldName = event.target.name;
    if (
      (fieldName === "federalExemption" ||
        fieldName === "additionalFederal" ||
        fieldName === "stateExemption") &&
      value?.toString()?.split(".")[1]?.length > 2
    ) {
      return;
    }

    const excemptions = _.cloneDeep(this.state.excemptions);
    excemptions[fieldName] = value;
    if (inputPosition > this.state.inputPosition) {
      this.setState({ inputPosition });
    }
    this.setState({ excemptions }, () => {
      this.validateExemptionsFormSchema(false, isBlur);
    });
  };

  onExcemptionsFieldBlur = (event) => {
    let value = event.target.value;
    const fieldName = event.target.name;
    const excemptions = _.cloneDeep(this.state.excemptions);
    if (fieldName === "federalExemption" || fieldName === "stateExemption") {
      if (!value) {
        excemptions[fieldName] = 0;
        this.setState({ excemptions }, () => {
          this.validateExemptionsFormSchema(false, true);
        });
      }
    } else if (fieldName === "additionalFederal") {
      excemptions[fieldName] = Utils.convertNumberToTwoPlacesDecimal(value);
      this.setState({ excemptions }, () => {
        this.validateExemptionsFormSchema(false, true);
      });
    }
  };

  validateExemptionsFormSchema = (
    isCalledFromMount = false,
    isBlur = false
  ) => {
    const { excemptions } = this.state;
    const valid = Joi.validate(excemptions, this.excemptionsFormSchema, {
      abortEarly: false,
    });
    const newErrorObject = {};
    if (valid.error) {
      valid.error.details.forEach((err) => {
        newErrorObject[err.path.join(".")] = err.message;
      });
    }
    this.setState({ exemptionsErrors: newErrorObject }, () =>
      this.updatePayrollTabCount(isCalledFromMount, isBlur, "Exemptions")
    );
    if (Object.keys(newErrorObject).length === 0 && !isCalledFromMount) {
      this.setState({ isFormValid: true });
    } else {
      this.setState({ isFormValid: false });
    }
    return newErrorObject;
  };

  onCurrencyFocus = (event: any) => {
    let newval = !event.target.value
      ? ""
      : Utils.removecurrencyFormat(event.target.value);
    const { payrollWithholdingSettings } = this.state;
    const _payrollWithholdingSettings = _.cloneDeep(payrollWithholdingSettings);
    _payrollWithholdingSettings[event.target.name] = newval;
    this.setState({ payrollWithholdingSettings: _payrollWithholdingSettings });
  };
  handleCurrencyFormat = (event) => {
    const { payrollWithholdingSettings } = this.state;
    const _payrollWithholdingSettings = _.cloneDeep(payrollWithholdingSettings);
    if (!isNaN(event.target.value)) {
      let val = !event.target.value
        ? ""
        : Utils.currencyFormat(_payrollWithholdingSettings[event.target.name]);
      _payrollWithholdingSettings[event.target.name] = val;
    } else {
      _payrollWithholdingSettings[event.target.name] = "";
    }
    this.setState({ payrollWithholdingSettings: _payrollWithholdingSettings });
  };

  componentWillUnmount = () => {
    // setTimeout(()=> {
    //   toast.dismiss();
    // },1000)
  };
  validationExcemptionsOnBlur = (inputPosition) => {
    this.setState({ inputPosition });
    // this.validateExemptionsFormSchema(false,true);
  };

  getExcemptionsFieldError = (fieldName: string, fieldPosition: number) => {
    const { exemptionsErrors } = this.state;

    return (
      <>
        {Object.keys(exemptionsErrors).length > 0 &&
          fieldPosition <= this.state.inputPosition && (
            <span className="validation-message">
              {exemptionsErrors[fieldName]}
            </span>
          )}
      </>
    );
  };

  // onPayrollWithholdingSettingsFieldBlur = () => {
  //   const payrollWithholdingSettings = _.cloneDeep(
  //     this.state.payrollWithholdingSettings
  //   );

  //   if (+payrollWithholdingSettings?.creditAmt) {
  //     payrollWithholdingSettings.creditAmt = Utils.convertNumberToTwoPlacesDecimal(payrollWithholdingSettings.creditAmt);
  //   }

  //   if (+payrollWithholdingSettings?.otherIncome) {
  //     payrollWithholdingSettings.otherIncome = Utils.convertNumberToTwoPlacesDecimal(payrollWithholdingSettings.otherIncome);
  //   }
  //   if (+payrollWithholdingSettings?.wksht) {
  //     payrollWithholdingSettings.wksht = Utils.convertNumberToTwoPlacesDecimal(payrollWithholdingSettings.wksht);
  //   }
  //   if (+payrollWithholdingSettings?.extraAmt) {
  //     payrollWithholdingSettings.extraAmt = Utils.convertNumberToTwoPlacesDecimal(payrollWithholdingSettings.extraAmt);
  //   }
  //   if (+payrollWithholdingSettings?.extraAmt) {
  //     payrollWithholdingSettings.extraAmt = Utils.convertNumberToTwoPlacesDecimal(payrollWithholdingSettings.extraAmt);
  //   }
  //   this.setState({ payrollWithholdingSettings });
  // }

  onPayrollWithholdingSettingsFieldChange = (
    event,
    inputPosition,
    isBlur = false
  ) => {
    let value = event.target.value;
    if (value < 0 || value?.toString()?.includes("-")) {
      return;
    }
    const fieldName = event.target.name;

    // if (
    //   (

    //     fieldName === "otherIncome" ||
    //     fieldName === "wksht" ||
    //     fieldName === "extraAmt") &&
    //   value?.toString().split(".")[0]?.length > 8
    // ) {
    //   return;
    // }

    // if (
    //   (
    //     fieldName === "creditAmt" ||
    //     fieldName === "otherIncome" ||
    //     fieldName === "wksht" ||
    //     fieldName === "extraAmt" ||
    //     fieldName === "noOfFederalAllowances") &&
    //     (  value?.toString()?.split(".")[1]?.length > 2 ||       value?.toString()?.split(".")[0]?.length > 10)
    // ) {
    //   return;
    // }
    const _payrollWithholdingSettings = _.cloneDeep(
      this.state.payrollWithholdingSettings
    );
    const payrollWithholdingSettings = _.cloneDeep(
      this.state.payrollWithholdingSettings
    );
    if (
      fieldName === "creditAmt" ||
      fieldName === "otherIncome" ||
      fieldName === "wksht" ||
      fieldName === "extraAmt" ||
      fieldName === "noOfFederalAllowances"
    ) {
      value = Utils.removeInvaildCharFromAmount(value, 10);
    }
    payrollWithholdingSettings[fieldName] = value;
    if (inputPosition > this.state.inputPosition) {
      this.setState({ inputPosition });
    }

    if (fieldName === "use2020W4" && value) {
      payrollWithholdingSettings.noOfFederalAllowances = 0;
    }

    this.setState({ payrollWithholdingSettings }, () => {
      this.validatePayrollWithholdingSettingsFormSchema(false, isBlur);
    });

    const { enterpriseWideSetting, enterPriseWisePayrollData } = this.state;
    if (!_.isEqual(_payrollWithholdingSettings, payrollWithholdingSettings)) {
      enterPriseWisePayrollData.w4Date = moment().format("MM/DD/YYYY");
      enterpriseWideSetting.w4Date = moment().format("MM/DD/YYYY");
      this.setState(
        { enterPriseWisePayrollData, enterpriseWideSetting },
        () => {
          // this.validateEnterpriseWideSettingFormSchema(false,isBlur)
          const { enterpriseWideSettingErrors } = this.state;
          const newErrorObject = _.cloneDeep(enterpriseWideSettingErrors);

          delete newErrorObject["w4Date"];

          this.setState({ enterpriseWideSettingErrors: newErrorObject });
        }
      );
    }
  };

  validatePayrollWithholdingSettingsFormSchema = (
    isCalledFromMount = false,
    isBlur = false
  ) => {
    const { payrollWithholdingSettings } = this.state;
    const {
      excemptions,
      enterpriseWideSetting,
      prevEnterPriseWisePayrollData,
    } = this.state;

    let schema = this.payrollWithholdingSettingsFormSchemaOptional;
    if (
      !this.props?.isNewUserAdded &&
      (enterpriseWideSetting?.federalFiling !==
        prevEnterPriseWisePayrollData?.federalFiling ||
        excemptions?.federalExemption !==
          prevEnterPriseWisePayrollData?.federalExemption)
    ) {
      schema = this.payrollWithholdingSettingsFormSchema;
    }

    const valid = Joi.validate(payrollWithholdingSettings, schema, {
      abortEarly: false,
    });
    const newErrorObject = {};
    if (valid.error) {
      valid.error.details.forEach((err) => {
        newErrorObject[err.path.join(".")] = err.message;
      });
    }
    this.setState({ payrollWithholdingSettingsErrors: newErrorObject }, () =>
      this.updatePayrollTabCount(
        isCalledFromMount,
        isBlur,
        "PayrollWithholdingSettings"
      )
    );
    if (Object.keys(newErrorObject).length === 0 && !isCalledFromMount) {
      this.setState({ isFormValid: true });
    } else {
      this.setState({ isFormValid: false });
    }
    return newErrorObject;
  };

  validationPayrollWithholdingSettingsOnBlur = (inputPosition) => {
    this.setState({ inputPosition });
    // this.onPayrollWithholdingSettingsFieldBlur();
    //  this.validatePayrollWithholdingSettingsFormSchema(false,true);
  };

  getPayrollWithholdingSettingsFieldError = (
    fieldName: string,
    fieldPosition: number
  ) => {
    const { payrollWithholdingSettingsErrors } = this.state;

    return (
      <>
        {Object.keys(payrollWithholdingSettingsErrors).length > 0 &&
          fieldPosition <= this.state.inputPosition && (
            <span className="validation-message">
              {payrollWithholdingSettingsErrors[fieldName]}
            </span>
          )}
      </>
    );
  };

  calenderHide = () => {
    this.setState({ calenderShow: false });
  };

  calenderShow = () => {
    this.setState({ calenderShow: true });
  };

  calenderHideI9 = () => {
    this.setState({ calenderShowI9: false });
  };

  calenderShowI9 = () => {
    this.setState({ calenderShowI9: true });
  };

  calenderHideW4 = () => {
    this.setState({ calenderShowW4: false });
  };

  calenderShowW4 = () => {
    this.setState({ calenderShowW4: true });
  };

  openAddressEntryPanel = () => {
    this.setState({ inputPosition: 4 });
    this.setState({ showPayrollAddressModal: true });
  };
  hidePayrollAddressModal = (isReloadRequired: false) => {
    this.setState({ showPayrollAddressModal: false });
  };

  openDepositEntryPanel = (isEdit: boolean = false, row: any = {}) => {
    if (!(this.props.isEditLaborandPayrollInfo || this.props?.isNewUserAdded)) {
      return;
    }
    if (isEdit) {
      if (row?.rowNo === 1) {
        const data = this.state.enterPriseWisePayrollData;
        row.ddAmountType =
          data.ddAmountType === "Entire Paycheck"
            ? "Total Payroll"
            : data.ddAmountType == "$ Amount of Paycheck"
            ? "$Amount of Paycheck"
            : data.ddAmountType;
        row.ddAmountType2 = data?.ddAmountType2 || "";
        row.ddAmount2 = data?.ddAmount2;
      }

      this.setState(
        { depositRowEdit: row, depositRowForAddEdit: row?.rowNo },
        () => {
          this.setState({ showDepositModal: true });
        }
      );
    } else {
      this.setState(
        {
          depositRowEdit: {},
          depositRowForAddEdit: this.state.depositAccountsData?.length + 1,
        },
        () => {
          this.setState({ showDepositModal: true });
        }
      );
    }
  };
  hideDepositModal = (isReloadRequired: false) => {
    this.setState({ showDepositModal: false, deductionRowEdit: {} });
  };

  openDeductionEntryPanel = (isEdit: boolean = false, row: any = {}) => {
    if (isEdit) {
      this.setState({ deductionRowEdit: row }, () => {
        this.setState({ showDeductionModal: true });
      });
    } else {
      // if (
      //   this.props?.userGeneralInfo?.hids.filter(
      //     (x) =>
      //       !this.state?.deductions
      //         ?.map((l) => l?.letterCode)
      //         ?.includes(x?.lettercode)
      //   ).length === 0
      // ) {
      //   toast.success("Deductions added for all assigned EHID's", {
      //     position: toast.POSITION.BOTTOM_RIGHT,
      //     containerId: "laborTabUM",
      //   });
      //   return;
      // }

      this.setState(
        {
          deductionRowEdit: {},
        },
        () => {
          this.setState({ showDeductionModal: true });
        }
      );
    }
  };

  hideDeductionModal = (isReloadRequired: false) => {
    if (isReloadRequired) {
      //this.props?.getUserGeneralInfo(true);
      this.props.getUserGeneralInfoOnUpdate()
      this.props.getUserLaborInfo(true);
      this.savePayrollFormData();
      this.getDeductionsData();
    }
    this.setState({ showDeductionModal: false, deductionRowEdit: {} });
  };

  openGarnishmentEntryPanel = (isEdit: boolean = false, row: any = {}) => {
    if (isEdit) {
      debugger;
      if (
        (this.state?.hotelWideTableData || [])?.filter(
          (x) => x?.status === "Active" && x?.lettercode === row?.lettercode
        )?.length === 0
      ) {
        toast.success(
          "Please activate entity wide payroll setting to edit this row",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "userSlideOut",
          }
        );

        return;
      }

      this.setState({ garnishmentRowEdit: row }, () => {
        this.setState({ showGarnishmentModal: true });
      });
    } else {
      this.setState(
        {
          garnishmentRowEdit: {},
        },
        () => {
          this.setState({ showGarnishmentModal: true });
        }
      );
    }
  };

  hideGarnishmentModal = (isReloadRequired: false) => {
    if (isReloadRequired) {
      this.savePayrollFormData();
      this.getGarnishmentsData();
    }
    this.setState({ showGarnishmentModal: false, garnishmentRowEdit: {} });
  };

  buildFormObjectToSave = () => {
    const {
      enterpriseWideSettingErrors,
      exemptionsErrors,
      payrollWithholdingSettingsErrors,
      originalEnterpriseWisePayrollData,
      enterPriseWisePayrollData,
    } = this.state;

    if (Object.keys(enterpriseWideSettingErrors).length > 0) {
      //set to previous form
      enterPriseWisePayrollData.ssn = originalEnterpriseWisePayrollData.ssn;
      enterPriseWisePayrollData.dob = originalEnterpriseWisePayrollData.dob;
      enterPriseWisePayrollData.gender =
        originalEnterpriseWisePayrollData.gender;
      enterPriseWisePayrollData.jobType =
        originalEnterpriseWisePayrollData.jobType;
      enterPriseWisePayrollData.country =
        originalEnterpriseWisePayrollData.country;
      enterPriseWisePayrollData.school =
        originalEnterpriseWisePayrollData.school;
      enterPriseWisePayrollData.i9SubmittedOn =
        originalEnterpriseWisePayrollData.i9SubmittedOn;
      enterPriseWisePayrollData.w4Date =
        originalEnterpriseWisePayrollData.w4Date;

      enterPriseWisePayrollData.street =
        originalEnterpriseWisePayrollData.street;
      enterPriseWisePayrollData.city = originalEnterpriseWisePayrollData.city;
      enterPriseWisePayrollData.state = originalEnterpriseWisePayrollData.state;
      enterPriseWisePayrollData.zip = originalEnterpriseWisePayrollData.zip;
      enterPriseWisePayrollData.address =
        originalEnterpriseWisePayrollData.address;
    }

    if (Object.keys(exemptionsErrors).length > 0) {
      //set to previous form
      enterPriseWisePayrollData.federalExemption =
        originalEnterpriseWisePayrollData?.federalExemption;
      enterPriseWisePayrollData.additionalFederal =
        originalEnterpriseWisePayrollData.additionalFederal;
      enterPriseWisePayrollData.futa = originalEnterpriseWisePayrollData.futa;
      enterPriseWisePayrollData.fica = originalEnterpriseWisePayrollData.fica;
      enterPriseWisePayrollData.stateExemption =
        originalEnterpriseWisePayrollData.stateExemption;
    }

    if (Object.keys(payrollWithholdingSettingsErrors).length > 0) {
      //set to previous form

      enterPriseWisePayrollData.isJobs =
        originalEnterpriseWisePayrollData.isJobs;
      enterPriseWisePayrollData.use2020W4 =
        originalEnterpriseWisePayrollData.use2020W4;
      enterPriseWisePayrollData.federalFiling =
        originalEnterpriseWisePayrollData.federalFiling;
      enterPriseWisePayrollData.noOfFederalAllowances =
        originalEnterpriseWisePayrollData.noOfFederalAllowances;
      enterPriseWisePayrollData.otherIncome =
        originalEnterpriseWisePayrollData.otherIncome;
      enterPriseWisePayrollData.creditAmt =
        originalEnterpriseWisePayrollData.creditAmt;
      enterPriseWisePayrollData.wksht = originalEnterpriseWisePayrollData.wksht;
      enterPriseWisePayrollData.extraAmt =
        originalEnterpriseWisePayrollData.extraAmt;
    } else {
      if (
        enterPriseWisePayrollData.federalFiling !==
        originalEnterpriseWisePayrollData.federalFiling
      ) {
        enterPriseWisePayrollData.w4Date = moment().format("MM/DD/YYYY");
      }
    }

    return enterPriseWisePayrollData;
  };
  saveEnterpriseWiseSetting = (
    message = "User data saved successfully",
    isDepositRequest = false,
    handleDirectDepositError = () => {},
    PreviousEnterPriseWisePayrollData = null
  ) => {
    const request: IEnterPriseWisePayroll = {
      // ...this.state.enterPriseWisePayrollData,
      ...this.buildFormObjectToSave(),
    } as IEnterPriseWisePayroll;
    request.userUniqueNo = this.props?.userUniqueNo;
    request.uniqueNo = request.uniqueNo || 0;
    request.isNewUserAdded = this.props?.isNewUserAdded;
    request.isDepositRequest = isDepositRequest;

    delete request.ssn;

    this.setState({ isDataSubmitting: true });

    UserManagement.SaveEnterpriseWiseSetting(request)
      .then((result) => {
        this.setState({ isDataSubmitting: false });
        if (Object.keys(result?.result).length > 0) {
          if (result?.message === "Success") {
            if (result?.result.message === "InvalidAmount") {
              this.setState({
                enterPriseWisePayrollData: PreviousEnterPriseWisePayrollData,
              });
              handleDirectDepositError();
              // Utils.toastError(result?.result.result, {
              //       position: toast.POSITION.BOTTOM_RIGHT,
              //       containerId: "laborTabUM",
              //  });

              return;
            }

            if (result?.result.message === "Fail") {
              this.setState(
                {
                  isAllowDeposit: !this.state.isAllowDeposit,
                  duplicateSSNTableData: result?.result?.result || [],
                },
                () => {
                  this.handleShowDuplicateSSNModal();
                }
              );
              return;
            }
            // if (!isDepositRequest) {
            //   this.props?.getUserGeneralInfo(true);
            // }

            if (!isDepositRequest) {
              this.props.getUserLaborInfo(true);
            }
            const prevIsAllowDeposit =
              +this.props?.userGeneralInfo?.allowDeposit === 1 ? true : false;
            //after saving form, update toggle if there is any change in form
            if (prevIsAllowDeposit !== this.state?.isAllowDeposit) {
              this.changeDepositToggle();
            }

            const addressdata = this.buildRverseAddressData(this.state.enterpriseWideSetting?.address)
            if (isDepositRequest) {
              this.reAttachEnteriseWideSettingAddressToMainObject(addressdata,()=>{
                this.getEnterPriseWisePayrollData(false, true);
              })
              
            } else {
              this.reAttachEnteriseWideSettingAddressToMainObject(addressdata, ()=>{
                this.setState(
                  {
                    prevEnterPriseWisePayrollData: _.cloneDeep({
                      ...this.state.enterPriseWisePayrollData,
                    }),
                  },
                  () => {
                    this.validateEnterpriseWideSettingFormSchema(false, false);
                  }
                );
              })

             
             
            }
            this.hideDepositModal(false);
            this.props.getPayrollTabModificationStatus(false);
            toast.success(message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "userSlideOut",
            });
            this.setState({
              isExpand: true,
              ptoRow: { id: 1 },
              tableExpended: [1],
            });
          } else if (result?.message === "Fail" && result?.result?.length > 0) {
            this.setState(
              {
                isAllowDeposit: !this.state.isAllowDeposit,
                duplicateSSNTableData: result?.result?.result || [],
              },
              () => {
                this.handleShowDuplicateSSNModal();
              }
            );
          } else {
            toast.success(result?.result, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "userSlideOut",
            });
          }
        } else {
          Utils.toastError(result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "userSlideOut",
          });
        }
      })
      .catch((error) => {
        let _depositAccountsData = [...this.state.depositAccountsData];
        _depositAccountsData = _depositAccountsData.map((row) => {
          row.isRowDataSubmitting = false;
          return row;
        });

        this.setState({
          isDataSubmitting: false,
          depositAccountsData: [..._depositAccountsData],
        });

        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "userSlideOut",
        });
        this.getEnterPriseWisePayrollData();
      })
      .finally(() => this.setState({ isFormValid: false }));
  };

  getStatusClassName(status: any) {
    let className = "";
    switch (status) {
      case "Active":
        className = "color-green";
        break;
      case "Inactive":
        className = "color-grey";
        break;
      default:
        className = "color-orange";
        break;
    }
    return className;
  }
  addUpdateDeposit = (
    data,
    isEdit = false,
    allData = false,
    handleDirectDepositError = () => {}
  ) => {
    const {
      enterPriseWisePayrollData,
      depositRowForAddEdit,
      depositAccountsData,
    } = this.state;

    const PreviousEnterPriseWisePayrollData = _.cloneDeep(
      enterPriseWisePayrollData
    );
    if (allData) {
      const data1 = data[0];
      const data2 = data[1];

      //Row 1
      enterPriseWisePayrollData.directDeposit = data1.directDeposit;
      enterPriseWisePayrollData.bankName = data1.bankName;
      enterPriseWisePayrollData.accountType = data1.accountType;
      enterPriseWisePayrollData.routingNumber = data1.routingNumber;
      enterPriseWisePayrollData.accountNumber = data1.accountNumber;
      enterPriseWisePayrollData.ddAmountType =
        data1.ddAmountType === "Entire Paycheck"
          ? "Total Payroll"
          : data1.ddAmountType == "$ Amount of Paycheck"
          ? "$Amount of Paycheck"
          : data1.ddAmountType;
      enterPriseWisePayrollData.ddAmount =
        data1.ddAmountType === "Entire Paycheck" ||
        data1.ddAmountType === "Total Payroll"
          ? ""
          : data1.ddAmount;

      //Row 2
      enterPriseWisePayrollData.directDeposit2 = data2.directDeposit;
      enterPriseWisePayrollData.bankName2 = data2.bankName;
      enterPriseWisePayrollData.accountType2 = data2.accountType;
      enterPriseWisePayrollData.routingNumber2 = data2.routingNumber;
      enterPriseWisePayrollData.accountNumber2 = data2.accountNumber;
      enterPriseWisePayrollData.ddAmountType2 =
        data2.ddAmountType == "$ Amount of Paycheck"
          ? "$Amount of Paycheck"
          : data2.ddAmountType;
      enterPriseWisePayrollData.ddAmount2 =
        data1.ddAmountType === "Entire Paycheck" ||
        data1.ddAmountType === "Total Payroll"
          ? ""
          : data2.ddAmount;
    } else {
      if (depositRowForAddEdit === 1) {
        enterPriseWisePayrollData.directDeposit = data.directDeposit;
        enterPriseWisePayrollData.bankName = data.bankName;
        enterPriseWisePayrollData.accountType = data.accountType;
        enterPriseWisePayrollData.routingNumber = data.routingNumber;
        enterPriseWisePayrollData.accountNumber = data.accountNumber;
        enterPriseWisePayrollData.ddAmountType =
          data.ddAmountType === "Entire Paycheck"
            ? "Total Payroll"
            : data.ddAmountType == "$ Amount of Paycheck"
            ? "$Amount of Paycheck"
            : data.ddAmountType;
        enterPriseWisePayrollData.ddAmount =
          data.ddAmountType === "Entire Paycheck" ||
          data.ddAmountType === "Total Payroll"
            ? ""
            : data.ddAmount;
        enterPriseWisePayrollData.ddAmountType2 = data?.ddAmountType2
          ? data.ddAmountType2 == "$ Amount of Paycheck"
            ? "$Amount of Paycheck"
            : data?.ddAmountType2
          : depositAccountsData[1]?.ddAmountType == "$ Amount of Paycheck"
          ? "$Amount of Paycheck"
          : depositAccountsData[1]?.ddAmountType;
        enterPriseWisePayrollData.ddAmount2 =
          data.ddAmountType === "Entire Paycheck" ||
          data.ddAmountType === "Total Payroll"
            ? ""
            : data?.ddAmount2
            ? data?.ddAmount2
            : depositAccountsData[1]?.ddAmount;
      }
      if (depositRowForAddEdit === 2) {
        enterPriseWisePayrollData.directDeposit2 = data.directDeposit;
        enterPriseWisePayrollData.bankName2 = data.bankName;
        enterPriseWisePayrollData.accountType2 = data.accountType;
        enterPriseWisePayrollData.routingNumber2 = data.routingNumber;
        enterPriseWisePayrollData.accountNumber2 = data.accountNumber;
        enterPriseWisePayrollData.ddAmountType2 =
          data.ddAmountType == "$ Amount of Paycheck"
            ? "$Amount of Paycheck"
            : data.ddAmountType;
        enterPriseWisePayrollData.ddAmount2 =
          data.ddAmountType === "Entire Paycheck" ||
          data.ddAmountType === "Total Payroll"
            ? ""
            : data.ddAmount;
      }
    }

    let message = "Deposit data saved successfully";
    if (isEdit) {
      message = "Deposit data updated successfully";
    }
    // if ((enterPriseWisePayrollData?.ddAmountType === '% of Paycheck' || enterPriseWisePayrollData?.ddAmountType2 === '% of Paycheck') &&
    //   (+enterPriseWisePayrollData.ddAmount > 100 || +enterPriseWisePayrollData.ddAmount2 > 100 || (+enterPriseWisePayrollData.ddAmount + +enterPriseWisePayrollData.ddAmount2) > 100)) {

    //   //  this.setState({enterPriseWisePayrollData: this.state?.prevEnterPriseWisePayrollData}, ()=> {
    //   //   this.buildDepositTableData();
    //   //  });
    //   let _depositAccountsData = [...depositAccountsData]
    //   _depositAccountsData = _depositAccountsData.map((row) => {
    //     row.isRowDataSubmitting = false;
    //     return row;
    //   });

    //   this.setState({ depositAccountsData: [..._depositAccountsData] })

    //   Utils.toastError("Total percentage cannot be greater than 100%", {
    //     position: toast.POSITION.BOTTOM_RIGHT,
    //     containerId: "laborTabUM",
    //   });
    //   return false;

    // }

    //*** Use Above toast message for Table inline edit  */
    //*** Use condition is table inline edit  then toast message else joi error message  */

    this.setState({ enterPriseWisePayrollData }, () => {
      this.saveEnterpriseWiseSetting(
        message,
        true,
        handleDirectDepositError,
        PreviousEnterPriseWisePayrollData
      );
    });
  };

  isNoErrorInForm = () => {
    const {
      enterpriseWideSettingErrors,
      exemptionsErrors,
      payrollWithholdingSettingsErrors,
    } = this.state;

    return (
      Object.keys(enterpriseWideSettingErrors).length == 0 &&
      Object.keys(exemptionsErrors).length == 0 &&
      Object.keys(payrollWithholdingSettingsErrors).length == 0
    );
  };

  onIsAllowDepositChange = (event) => {
    const { hasAddPermission, hasEditPermission } = this.props;
    const {
      isFormValid,
      isAllowDeposit,
      isEnterpriseWideSettingNotChanged,
      enterpriseWideSettingErrors,
    } = this.state;

    const isChecked = event.target.checked;

    const isSaveBannerVisible =
      isFormValid &&
      !isEnterpriseWideSettingNotChanged &&
      (hasAddPermission || hasEditPermission || this.props?.isNewUserAdded);
    if (!isEnterpriseWideSettingNotChanged && !isSaveBannerVisible) {
      confirmAlert({
        title: "Unsaved Confirmation?",
        message:
          "You have unsaved changes on the page. Please populate all fields above before entering Direct Deposit information. Do you want to first save your changes?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              this.setState({ isAllowDeposit: isChecked }, () => {
                this.updateDepositToggle();
              });
            },
          },
          {
            label: "No",
            onClick: () => null,
          },
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    } else {
      this.setState({ isAllowDeposit: isChecked }, () => {
        this.updateDepositToggle();
      });
    }
  };

  changeDepositToggle = (showSuccessMessage = false) => {
    const { isAllowDeposit } = this.state;
    this.setState({ isToggleSaved: false });
    UserManagement.UpdateToggle(
      this.props?.userName,
      this.props?.userGeneralInfo?.isSalesAgent || 0,
      this.props?.userGeneralInfo?.allowPTO || 0,
      this.props?.userGeneralInfo?.allowACATracking || 0,
      this.props?.userGeneralInfo?.allowPerformance || 0,
      isAllowDeposit ? 1 : 0
    ).then((result) => {
      this.setState({ isToggleSaved: true });
      this.updatePayrollTabCount();
      //this.props?.getUserGeneralInfo(true);
      this.props.getUserLaborInfo(true);
      if (showSuccessMessage) {
        this.setState({
          tableExpended: [],
          depositAccountsData: [],
          isExpand: false,
        });
      }

      this.getEnterPriseWisePayrollData(false, true);
      // if (isAllowDeposit) {
      //   this.getEnterPriseWisePayrollData(false, true);
      // }
      if (showSuccessMessage) {
        toast.success(result.result?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "userSlideOut",
        });
      }
    });
  };

  updateDepositToggle = () => {
    const { hasAddPermission, hasEditPermission } = this.props;
    const {
      isFormValid,
      isAllowDeposit,
      isEnterpriseWideSettingNotChanged,
      enterpriseWideSettingErrors,
    } = this.state;

    //check if there is any change in form
    if (
      isFormValid &&
      !isEnterpriseWideSettingNotChanged &&
      (hasAddPermission || hasEditPermission || this.props?.isNewUserAdded)
    ) {
      this.saveEnterpriseWiseSetting("User data saved successfully", false);
    } else {
      this.changeDepositToggle(true);
    }
  };
  handleDeductionRowExpand = (
    row: any,
    isExpand: any,
    rowIndex: any,
    e: any
  ) => {
    if (this.state?.deductionTableExpandend?.length > 0) {
      this.setState({
        deductionRow: {},
        isExpand: false,
      });
      this.setState(() => ({ deductionTableExpandend: [] }));
    } else {
      this.setState(() => ({
        isExpand: true,
        deductionRow: row,
        deductionTableExpandend: [row.scheduleId],
      }));
      //  this.getPTOAdjustedDetails(row.ScheduleId);
    }
  };

  handleOnGarnishmentRowExpand = (
    row: any,
    isGarnishmentExpand: any,
    rowIndex: any,
    e: any
  ) => {
    if (this.state.ganishmentTableExpandend.length > 0) {
      
      if (isGarnishmentExpand) {
        this.setState({
          isGarnishmentExpand: true,
          garnishmentRow: row,
          ganishmentTableExpandend: [row.iF_RecordId],
        });
      }
      else{
        this.setState(
          {
            garnishmentRow: {},
            isGarnishmentExpand: false,
            ganishmentTableExpandend: [],
          } 
        );
      }
      
    } else {
      this.setState({
        isGarnishmentExpand: true,
        garnishmentRow: row,
        ganishmentTableExpandend: [row.iF_RecordId],
      });
    }
  };

  autoFormatSSNOnBlur = () => {
    const enterpriseWideSetting = _.cloneDeep(this.state.enterpriseWideSetting);
    let ssn = enterpriseWideSetting?.ssn?.toString();
    if (ssn?.length > 5 && !ssn?.includes("-")) {
      ssn =
        ssn.substr(0, 3) +
        "-" +
        ssn.substr(3, 2) +
        "-" +
        ssn.substr(5, ssn?.length - 4);
    }
    enterpriseWideSetting.ssn = ssn;
    this.setState({ enterpriseWideSetting }, () => {
      this.validateEnterpriseWideSettingFormSchema(false, true);
    });
  };

  handleOnExpand = (row: any, isExpand: any, rowIndex: any, e: any) => {
    if (this.state.tableExpended.length > 0) {
      this.setState(
        {
          laborTimeOffSubData: [],
          ptoRow: {},
          isExpand: false,
          tableExpended: [],
        },
        () => {
          if (isExpand) {
            this.setState({
              isExpand: true,
              ptoRow: row,
              tableExpended: [row.id],
            });
          }
        }
      );
    } else {
      this.setState({
        isExpand: true,
        ptoRow: row,
        tableExpended: [row.id],
      });
      //   this.getPTOAdjustedDetails(row.ptoUniqueNo);
    }
  };

  handleDiscard = () => {
    this.getEnterPriseWisePayrollData();
    this.props.getPayrollTabModificationStatus(false);
  };

  getUserSpecialRates = () => {
    this.setState({ isSpecialRateLoaded: false });
    UserManagement.GetUserSpecialRatesAllHid(this.props?.userUniqueNo)
      .then((spacialRates) => {
        const data = spacialRates.map((item: any) => {
          item.wefDate = moment(item.wefDate)?.format("MM/DD/YYYY");
          return item;
        });
        this.setState({ spacialRates: data }, () => {
          this.setState({ isSpecialRateLoaded: true });
        });
      })
      .catch(() => this.setState({ isSpecialRateLoaded: true }));
  };

  shouldDisableRow = (lettercode): boolean => {
    const result =
      !(this.props.isEditLaborandPayrollInfo || this.props?.isNewUserAdded) ||
      (this.state?.hotelWideTableData || [])?.filter(
        (x) => x?.status === "Active" && x?.lettercode === lettercode
      )?.length == 0 ||
      this.props?.userGeneralInfo?.hids.filter(
        (x) => x?.loginUserHasAccess && x.lettercode === lettercode
      )?.length === 0;

    return result;
  };

  handleOnHotelWideExpand = (
    row: any,
    isExpand: any,
    rowIndex: any,
    e: any
  ) => {
    const hotelWideTableExpended = [...this.state.hotelWideTableExpended];
    if (row?.status === "Active") {
      // this.setState({
      //   localAndStateTaxesFormFields:[]});
      if (hotelWideTableExpended.length > 0) {

        if (isExpand) {
          const empState =
            row?.withholdingState ||
            this.state.enterPriseWisePayrollData?.state ||
            "";
          UserManagement.GetHotelStateAndLocalTax(
            [{ stateCode: empState, hid: row?.hid }],
            this.props?.userUniqueNo
          ).then((localAndStateTaxesFormFields: any[]) => {
            this.setState(
              (prev) => ({
                localAndStateTaxesFormFields: _.uniqWith(
                  [
                    ...prev.localAndStateTaxesFormFields,
                    ...localAndStateTaxesFormFields,
                  ],
                  (a, b) => a.id === b.id && a.hid === b.hid
                ),
              }),
              () => {
                hotelWideTableExpended.push(row.hid);
                this.setState({
                  isHotelWideExpand: true,
                  hotelWideRow: row,
                  hotelWideTableExpended,
                });
              }
            );
          });
        }
        else{
           this.setState(
          {
            hotelWideRow: {},

            isHotelWideExpand: false,
            hotelWideTableExpended: hotelWideTableExpended.filter(
              (x) => x !== row.hid
            ),
          })
        }
      
      } else {
        const empState =
          row?.withholdingState ||
          this.state.enterPriseWisePayrollData?.state ||
          "";
        UserManagement.GetHotelStateAndLocalTax(
          [{ stateCode: empState, hid: row?.hid }],
          this.props?.userUniqueNo
        ).then((localAndStateTaxesFormFields: any[]) => {
          this.setState({ localAndStateTaxesFormFields }, () => {
            this.setState({
              isHotelWideExpand: true,
              hotelWideRow: row,
              hotelWideTableExpended: [row.hid],
            });
          });
        });
      }
    } else {
      this.setState({
        hotelWideTableExpended: hotelWideTableExpended.filter(
          (x) => x !== row.hid
        ),
      });
    }
  };

  savePayrollFormData = () => {
    const { hasAddPermission, hasEditPermission } = this.props;
    const {
      isFormValid,
      isAllowDeposit,
      isEnterpriseWideSettingNotChanged,
      enterpriseWideSettingErrors,
    } = this.state;

    if (
      isFormValid &&
      !isEnterpriseWideSettingNotChanged &&
      (hasAddPermission || hasEditPermission || this.props?.isNewUserAdded)
    ) {
      this.saveEnterpriseWiseSetting("User data saved successfully", false);
    }
  };

  hideHotelWiseAddEditModalForm = (isReload = false) => {
    this.setState({ showHotelWiseAddEditModal: false }, () => {
      if (isReload) {
        this.savePayrollFormData();
        this.getHotelWisePayrollData();
        if (typeof this.props?.shouldReloadMainPage === "function") {
          this?.props?.shouldReloadMainPage(true);
        }
        //  this.props?.getUserGeneralInfo(true);
        this.props.getUserGeneralInfoOnUpdate()
        this.props.getUserLaborInfo(true);
      }
    });
  };

  showHotelWiseAddEditModalForm = (isEdit: boolean = false, row: any = {}) => {
    if (isEdit) {
      if (
        this.props?.userGeneralInfo.hids?.filter(
          (item) => +item?.hotelID === +row?.hid
        )?.length === 0
      ) {
        toast.success(
          "You are unable to open and edit the information because you do not have access to the E/H/NID",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "userSlideOut",
          }
        );

        return;
      }

      this.getUserSpecialRates();
      this.setState({ hotelWisePayrollRowEdit: row }, () => {
        this.setState({ showHotelWiseAddEditModal: true });
      });
    } else {
      this.setState(
        {
          hotelWisePayrollRowEdit: {},
        },
        () => {
          this.setState({ showHotelWiseAddEditModal: true });
        }
      );
    }
  };

  hideSpecialRateAddEditModalForm = (isReload = false) => {
    this.setState({ showSpecialRateAddEditModal: false }, () => {
      if (isReload) {
        this.getUserSpecialRates();
      }
    });
  };
  showSpecialRateAddEditModalForm = (
    isEdit: boolean = false,
    row: any = {}
  ) => {
    if (isEdit) {
      this.setState({ specialRateRowEdit: row }, () => {
        this.setState({ showSpecialRateAddEditModal: true });
      });
    } else {
      this.setState(
        {
          specialRateRowEdit: {},
        },
        () => {
          this.setState({ showSpecialRateAddEditModal: true });
        }
      );
    }
  };

  updateDeductionStatus = (row: any, status) => {
    const request: any = {
      uniqueno: row?.uniqueno,
      status: status,
      deductUniqueNo: row?.deductUniqueNo,
      taxType: row?.taxType,
      exempt: row?.exempt,
      deductionType: row?.deductionType,
      deductionCriteria: row?.deductionCriteria,
      amount: row?.amount,
      payeeName: row?.payeeName,
      hid: +row?.hid,
    };
    request.username = this.props?.userName;
    this.setState({ isDataSaving: true });
    UserManagement.SaveUserDeductionCriteria(request)
      .then((result) => {
        this.setState({ isDataSaving: false });
        if (result?.message === "Success") {
          toast.success("Status updated successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "userSlideOut",
          });
          this.getDeductionsData();
        } else {
          Utils.toastError(result?.result?.message || result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "userSlideOut",
          });
        }
      })
      .catch((error) => {
        this.setState({ isDataSaving: false });
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "userSlideOut",
        });
      });
  };

  deletePayrollSpecialRate = (row) => {
    UserManagement.DeletePayrollSpecialRate(row?.uniqueno).then((result) => {
      this.getUserSpecialRates();
      toast.success("Special Rate deleted successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "userSlideOut",
      });
    });
  };

  deleteUserGranishment = (row) => {
    UserManagement.DeleteUserGranishment(
      row?.uniqueNo,
      this.props?.userUniqueNo
    ).then((result) => {
      this.savePayrollFormData();
      this.getGarnishmentsData();
      toast.success("Garnishment deleted successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "userSlideOut",
      });
    });
  };

  deleteDeduction = (row) => {
    UserManagement.DeleteDeduction(row?.iF_RecordId, this.props?.userName).then(
      (result) => {
        this.savePayrollFormData();
        this.props?.getUserGeneralInfo(true);
        this.getDeductionsData();
        toast.success("Deduction deleted successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "userSlideOut",
        });
      }
    );
  };

  deleteDirectDepositAcc = (row: any) => {
    if (this.state?.depositAccountsData?.length > 1 && row.rowNo === 1) {
      Utils.toastError(
        "You must delete second account in order to delete first account",
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "laborTabUM",
        }
      );
      return;
    }
    UserManagement.DeleteDirectDepositAcc(
      this.props?.userUniqueNo,
      row.rowNo
    ).then((result) => {
      this.getEnterPriseWisePayrollData(false, true);
      toast.success("Deposit Account deleted successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "laborTabUM",
      });
    });
  };

  downloadGranismentDocument = (row) => {
    UserManagement.DownloadGranismentDocument(
      row?.uniqueNo,
      row?.fileName
    ).then((result) => {});
  };

  render() {
    const {
      hasAddPermission,
      hasEditPermission,
      hasViewSensitiveDataPermission,
      isEditLaborandPayrollInfo,
    } = this.props;
    const {
      inputPosition,
      enterpriseWideSettingErrors,
      exemptionsErrors,
      isFormValid,
      isDataSubmitting,
      sectionNames,
      showDepositModal,
      depositAccountsData,
      isEnterpriseWideDataLoaded,
      isAllowDeposit,
      depositRowEdit,
      depositRowForAddEdit,
      isEnterpriseWideSettingNotChanged,
      enterpriseWideSetting: {
        ssn,
        dob,
        gender,
        address,
        jobType,
        country,
        school,
        i9SubmittedOn,
        w4Date,
      },
      excemptions: {
        federalExemption,
        additionalFederal,
        futa,
        fica,
        stateExemption,
      },
      payrollWithholdingSettings: {
        isJobs,
        otherIncome,
        creditAmt,
        wksht,
        extraAmt,
        use2020W4,
        federalFiling,
        noOfFederalAllowances,
      },
      showPayrollAddressModal,
      showDeductionModal,
      deductions,
      garnishments,
      showGarnishmentModal,
      isDeductionLoaded,
      showPayrollSettingBanner,
      payrollWithholdingSettingsErrors,
      hotelWideTableData,
      showHotelWiseAddEditModal,
      showSpecialRateAddEditModal,
      hotelWisePayrollRowEdit,
      specialRateRowEdit,
      hotelWideTableExpended,
      spacialRates,
      isHotelWisePayrollLoaded,
      isSpecialRateLoaded,
      isHotelWideExpand,
      duplicateSSNTableData,
      showDuplicateSSNModal,
      enterPriseWisePayrollData,
      enterpriseWideSettingNotChangedForDate,
    } = this.state;

    const { userGeneralInfo } = this.props;

    const hotelWideSettingProps: IHotelWideSettingProps = {
      hotelWideTableData,
      showSpecialRateAddEditModal,
      showSpecialRateAddEditModalForm: this.showSpecialRateAddEditModalForm,
      userGeneralInfo,
      deletePayrollSpecialRate: this.deletePayrollSpecialRate,
      spacialRates,
      isSpecialRateLoaded,
      hideSpecialRateAddEditModalForm: this.hideSpecialRateAddEditModalForm,
      hotelWideTableExpended,
      specialRateRowEdit,
      handleOnHotelWideExpand: this.handleOnHotelWideExpand,
      isHotelWideExpand,
      showHotelWiseAddEditModal,
      showHotelWiseAddEditModalForm: this.showHotelWiseAddEditModalForm,
      hideHotelWiseAddEditModalForm: this.hideHotelWiseAddEditModalForm,
      isHotelWisePayrollLoaded,
      hotelWisePayrollRowEdit,
      isPayrollTab: true,
      userUniqueNo: this.props?.userUniqueNo,
      clientSetupData: this.props?.clientSetupData,
      hasHWPSetting: userGeneralInfo?.groupHWPPayrollSetting,
      hasViewSensitiveDataPermission:
        this.props?.hasViewSensitiveDataPermission ||
        this.props?.isNewUserAdded,
      isFromPayroll: true,
      isEditLaborandPayrollInfo:
        this.props.isEditLaborandPayrollInfo || this.props?.isNewUserAdded,
      employeeState:
        hotelWideTableData?.find(
          (x) => x.hid === this.state?.hotelWisePayrollRowEdit?.hid
        )?.withholdingState ||
        this.state.enterPriseWisePayrollData?.state ||
        "",
      localAndStateTaxesFormFields: this.state.localAndStateTaxesFormFields,
    };

    const changeAmount = ({ value, rowIndex }) => {
      let _depositAccountsData = [...depositAccountsData];
      _depositAccountsData[rowIndex].ddAmount = value;
      _depositAccountsData = _depositAccountsData.map((row) => {
        row.isRowDataSubmitting = true;
        return row;
      });

      this.setState((current) => ({
        ...current,
        depositAccountsData: [..._depositAccountsData],
        depositRowForAddEdit: _depositAccountsData[rowIndex].rowNo,
      }));
    };

    const changeSerialNo = (newValue, oldValue, rowIndex) => {
      if (newValue == oldValue) {
        return;
      }
      if (newValue == 1 || newValue == 2) {
        let _depositAccountsData = [...depositAccountsData];

        // const tempValue=_depositAccountsData[0].rowNo
        // _depositAccountsData[0].rowNo=_depositAccountsData[1].rowNo
        // _depositAccountsData[1].rowNo=tempValue
        if (rowIndex == 0) {
          _depositAccountsData[0].rowNo = newValue;
          _depositAccountsData[1].rowNo = newValue == 2 ? 1 : 2;
        } else {
          _depositAccountsData[1].rowNo = newValue;
          _depositAccountsData[0].rowNo = newValue == 2 ? 1 : 2;
        }

        if (
          _depositAccountsData[0].ddAmountType == "Entire Paycheck" &&
          _depositAccountsData[1].ddAmountType == "None"
        ) {
          _depositAccountsData[1].ddAmountType = "Entire Paycheck";
          _depositAccountsData[0].ddAmountType = "None";
        }
        _depositAccountsData = [
          _depositAccountsData[1],
          _depositAccountsData[0],
        ];
        _depositAccountsData = _depositAccountsData.map((row) => {
          row.isRowDataSubmitting = true;
          return row;
        });

        this.setState({ depositAccountsData: [..._depositAccountsData] });
      }
    };

    const valueRender = (params) => {
      let field = params?.colDef?.field;
      let rowIndex = params?.rowIndex;
      let editable = true;
      if (
        params.data.dataLength == 2 &&
        (field == "ddAmount" || field == "rowNo")
      ) {
        editable = false;
      } else {
        if (params.data.dataLength == 1 && field == "ddAmount") {
          editable = false;
        }
      }

      return (
        <div
        // onClick={() =>
        //   hasEditPermission && !params.data.isRowDataSubmitting && editable
        //     ? this.openDepositEntryPanel(true, params.data)
        //     : {}
        // }
        >
          <EllipsisWithTooltip>{params?.data[field]}</EllipsisWithTooltip>
        </div>
      );
    };

    const tripleDotRender = (params) => {
      const row = params?.data;
      let rowIndex = params?.rowIndex;

      return (
        <Dropdown
          // onClick={(e) => {
          //   e.stopPropagation();
          // }}
          className={`more-action ${
            this.state.depositAccountsData.length - 1 == rowIndex
              ? "set-dropdown-inset"
              : ""
          }`}
          alignRight
        >
          <Dropdown.Toggle
            className="btn-outline-primary btn btn-primary btn-ghost more"
            id="dropdown-more"
            disabled={
              params.data.isRowDataSubmitting ||
              !(
                this.props.isEditLaborandPayrollInfo ||
                this.props?.isNewUserAdded
              )
            }
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
              <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
              <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
            </svg>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {hasEditPermission && (
              <Dropdown.Item
                key={row?.sectionName + "Edit"}
                onClick={() => this.openDepositEntryPanel(true, row)}
                eventKey={"status"}
              >
                Edit
              </Dropdown.Item>
            )}

            <Dropdown.Item
              key={row?.sectionName + "Delete"}
              onClick={() => this.deleteDirectDepositAcc(row)}
              eventKey={"status"}
            >
              Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      );
    };

    const depositTableColumns: any[] = [
      {
        field: "rowNo",
        headerName: "Sr. No.",
        cellRenderer: "valueRender",
        // cellEditor: "numericCellEditor",
        // editable: (params) =>
        //   params.data.dataLength == 1
        //     ? false
        //     : params.data.isRowDataSubmitting
        //     ? false
        //     : hasEditPermission,
        rowDrag: (params) => {
          return params.data.dataLength == 1
            ? false
            : params.data.isRowDataSubmitting
            ? false
            : hasEditPermission;
        },
        maxWidth: 80,
        minWidth: 80,
        cellStyle: { overflow: "visible" },
        cellClass: (params) => {
          const editable =
            params.data.dataLength == 1
              ? false
              : params.data.isRowDataSubmitting
              ? false
              : hasEditPermission;
          return editable
            ? "serial-number serial-number-edit"
            : "serial-number";
        },
        //cellClass: "serial-number",
        valueGetter: (params) => {
          return params.data.rowNo;
        },
        valueSetter: (params) => {
          const newValue = params.newValue;
          let data = params.data;

          if (newValue == 1 || newValue == 2) {
            data.rowNo = newValue;
            return true;
          }
          return false;
        },
      },
      {
        field: "bankName",
        headerName: "Name",
        cellRenderer: "valueRender",
        maxWidth: 140,
        minWidth: 140,
        //cellStyle: { overflow: "visible" },
        cellClass: "bank-name",
      },
      {
        field: "accountType",
        headerName: "Type",
        cellRenderer: "valueRender",
        maxWidth: 140,
        minWidth: 140,
        //cellStyle: { overflow: "visible" },
        cellClass: "account-type",
      },
      {
        field: "routingNumber",
        headerName: "Routing",
        cellRenderer: "valueRender",
        maxWidth: 140,
        minWidth: 140,
        //cellStyle: { overflow: "visible" },
        cellClass: "routing-number",
      },
      {
        field: "accountNumber",
        headerName: "Account",
        cellRenderer: "valueRender",
        maxWidth: 140,
        minWidth: 140,
        //cellStyle: { overflow: "visible" },
        cellClass: "account-number",
      },
      {
        field: "ddAmountType",
        headerName: "Amount Type",
        cellRenderer: "valueRender",
        maxWidth: 180,
        minWidth: 180,
        //cellStyle: { overflow: "visible" },
        cellClass: "dd-amount-type",
      },
      {
        field: "ddAmount",
        headerName: "Amount",
        cellRenderer: "valueRender",
        // cellEditor: "numericCellEditor",
        // editable: (params) =>
        //   params.data.isRowDataSubmitting ? false : hasEditPermission,
        // hide: params => params.data?.ddAmountType === "Entire Paycheck" || params.data?.ddAmountType === "None",
        maxWidth: 140,
        minWidth: 140,
        //cellStyle: { overflow: "visible" },
        cellClass: (params) => {
          const editable =
            params.data.dataLength == 1
              ? false
              : params.data.isRowDataSubmitting
              ? false
              : hasEditPermission;
          return "ehid-cells";
        },
        //cellClass: "ehid-cells",
        valueGetter: (params) => {
          return params.data.ddAmount;
        },
        valueSetter: (params) => {
          let newValue = params.newValue;
          let data = params.data;
          this.setState({ depositRowForAddEdit: data.rowNo });
          let rowIndex = data.rowNo - 1 == 1 ? 0 : 1;
          if (newValue) {
            if (data.ddAmountType === "% of Paycheck") {
              if (
                (depositAccountsData.length == 1 && +newValue < 100) ||
                (depositAccountsData.length == 2 &&
                  +depositAccountsData[rowIndex].ddAmount + +newValue <= 100)
              ) {
                data.ddAmount = newValue;
                return true;
              } else {
                return false;
              }
            } else {
              data.ddAmount = newValue;
            }

            return true;
          }

          data.ddAmount = 0;
          return true;
        },
      },
      {
        field: "",
        headerName: "",
        cellRenderer: "tripleDotRender",
        editable: false,
        lockPinned: true,
        pinned: "right",
        maxWidth: 60,
        minWidth: 60,
        cellStyle: { overflow: "visible", width: "auto" },
        cellClass: "triple-dot",
      },
    ];

    const duplicateSSNTableColumns: any[] = [
      {
        dataField: "employeeName",
        text: "Employee",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <EllipsisWithTooltip placement="bottom">
            <div className="employee-name">{cell}</div>
          </EllipsisWithTooltip>
        ),
      },
      {
        dataField: "username",
        text: "Username",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <EllipsisWithTooltip placement="bottom">
            <div className="user-name">{cell}</div>
          </EllipsisWithTooltip>
        ),
      },
      {
        dataField: "userId",
        text: "User ID",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <EllipsisWithTooltip placement="bottom">
            <div className="user-id">{cell}</div>
          </EllipsisWithTooltip>
        ),
      },
      {
        dataField: "rolePermission",
        text: "Role Permission",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <EllipsisWithTooltip placement="bottom">
            <div className="role-permission">{cell}</div>
          </EllipsisWithTooltip>
        ),
      },
      {
        dataField: "ehid",
        text: "EHID",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <EllipsisWithTooltip placement="bottom">
            <div className="ehid">{cell}</div>
          </EllipsisWithTooltip>
        ),
      },
      {
        dataField: "status",
        text: "Status",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="status">{cell}</div>
        ),
      },
    ];

    const onGridReady = (params: any) => {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;

      //condition to hide ddAmount column
      if (
        depositAccountsData &&
        depositAccountsData.length > 0 &&
        depositAccountsData[0]?.ddAmountType === "Entire Paycheck"
      ) {
        this.gridColumnApi.setColumnsVisible(["ddAmount"], false);
      }

      columnWidthSizeToFit();

      const headerSelector = ".ag-header";
      const scrollSelector = ".ag-body-horizontal-scroll";
      // const scrollViewportSelector = ".ag-body-horizontal-scroll-viewport";
      const scrollContainerSelector = ".ag-body-horizontal-scroll-container";

      // get scrollbar elements
      const scrollElement: any = document.querySelector(scrollSelector);
      // const scrollViewportElement: any = document.querySelector(
      //   scrollViewportSelector
      // );
      const scrollContainerElement: any = document.querySelector(
        scrollContainerSelector
      );
    };

    const columnWidthSizeToFit = () => {
      this.gridApi?.sizeColumnsToFit();
    };

    const onCellValueChanged = (params) => {
      const { value, newValue, oldValue, rowIndex, data, event, colDef } =
        params;
      if (colDef.field === "ddAmount") {
        changeAmount({ value, rowIndex });
      } else {
        changeSerialNo(newValue, oldValue, rowIndex);
      }
    };

    const onCellEditingStopped = (params) => {
      const { newValue, oldValue, rowIndex, colDef, data } = params;
      if (newValue == oldValue) {
        return;
      }

      const _rowIndex = rowIndex == 1 ? 0 : 1;
      if (colDef.field === "ddAmount") {
        if (data.ddAmountType === "% of Paycheck") {
          if (
            (depositAccountsData.length == 1 && +newValue < 100) ||
            (depositAccountsData.length == 2 &&
              +depositAccountsData[_rowIndex].ddAmount + +newValue <= 100)
          ) {
            this.addUpdateDeposit(depositAccountsData[rowIndex], true, false);
          } else {
            let _depositAccountsData = [...depositAccountsData];
            _depositAccountsData = _depositAccountsData.map((row) => {
              row.isRowDataSubmitting = false;
              return row;
            });

            this.setState({ depositAccountsData: [..._depositAccountsData] });

            Utils.toastError("Total percentage cannot be greater than 100%", {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "laborTabUM",
            });
          }
        } else {
          this.addUpdateDeposit(depositAccountsData[rowIndex], true, false);
        }
      } else {
        this.addUpdateDeposit(depositAccountsData, true, true);
      }
    };

    const onRowDragMove = (params) => {
      // const overIndex=params.overIndex //0
      // const rowData1=params.overNode.data
      // if(rowData1.rowNo == overIndex+1 )
      // {
      //   return
      // }
      // let _depositAccountsData=depositAccountsData
      // const rowData2=_depositAccountsData[overIndex== 0 ?1:0]
      // const value=rowData1.rowNo // 2
      // // Manage Row Number
      // if (overIndex == 0) {
      //   _depositAccountsData[0].rowNo = value
      //   _depositAccountsData[1].rowNo = value == 2 ? 1 : 2
      // }
      // else {
      //   _depositAccountsData[1].rowNo = value
      //   _depositAccountsData[0].rowNo = value == 2 ? 1 : 2
      // }
      // // Manage Entire Paycheck
      // if (_depositAccountsData[0].ddAmountType == "Entire Paycheck" && _depositAccountsData[1].ddAmountType == "None") {
      //   _depositAccountsData[1].ddAmountType = "Entire Paycheck"
      //   _depositAccountsData[0].ddAmountType = "None"
      // }
      // _depositAccountsData=[_depositAccountsData[1],_depositAccountsData[0]]
      // this.setState({ depositAccountsData: [..._depositAccountsData] })
    };

    const onRowDragEnd = (params) => {
      const overIndex = params.overIndex; //0
      const rowData1 = params.overNode.data;
      if (rowData1.rowNo == overIndex + 1) {
        return;
      }
      let _depositAccountsData = depositAccountsData;

      const rowData2 = _depositAccountsData[overIndex == 0 ? 1 : 0];
      const value = rowData1.rowNo; // 2
      // Manage Row Number
      if (overIndex == 0) {
        _depositAccountsData[0].rowNo = value;
        _depositAccountsData[1].rowNo = value == 2 ? 1 : 2;
      } else {
        _depositAccountsData[1].rowNo = value;
        _depositAccountsData[0].rowNo = value == 2 ? 1 : 2;
      }

      // Manage Entire Paycheck
      if (
        _depositAccountsData[0].ddAmountType == "Entire Paycheck" &&
        _depositAccountsData[1].ddAmountType == "None"
      ) {
        _depositAccountsData[1].ddAmountType = "Entire Paycheck";
        _depositAccountsData[0].ddAmountType = "None";
      }
      _depositAccountsData = [_depositAccountsData[1], _depositAccountsData[0]];

      _depositAccountsData = _depositAccountsData.map((row) => {
        row.isRowDataSubmitting = true;
        return row;
      });
      this.setState({ depositAccountsData: [..._depositAccountsData] }, () => {
        this.addUpdateDeposit(_depositAccountsData, true, true);
      });
    };
    const getAgGridOptions = () => {
      const gridOptions: GridOptions = {
        rowData: [],
        rowClass: "user-table-row",
        // singleClickEdit: true,
        getRowHeight: (params) => {
          return 48;
        },
        headerHeight: 48,
        frameworkComponents: {
          valueRender: valueRender,
          tripleDotRender: tripleDotRender,
          numericCellEditor: NumericEditor,

          // cellEditorSelector:cellEditorSelector,

          // rowNoRender:rowNoRender,
          // amountRender:amountRender,
        },

        rowSelection: "single",
      };
      return gridOptions;
    };

    const rowClasses = (row: any, rowIndex: any) => {
      if (this.state.isExpand === true && row.action === "Expended") {
        return "row-Expended";
      } else if (row.approveButtonSpinner) {
        return "p-none";
      } else if (this.state.isExpand === true) {
        return "row-Collapsed";
      } else {
        return "row-Action";
      }
    };
    const rowClassesGarnishment = (row: any, rowIndex: any) => {
      if (
        this.state.isGarnishmentExpand === true &&
        row.action === "Expended"
      ) {
        return "row-Expended";
      } else if (row.approveButtonSpinner) {
        return "p-none";
      } else if (this.state.isGarnishmentExpand === true) {
        return "row-Collapsed";
      } else {
        return "row-Action";
      }
    };

    const expandRow = {
      renderer: (row: any) => (
        <>
          {row.id === 1 && (
            <div className="rowExpended" id="laborDayDetails">
              <div className="heading">Deposit Accounts</div>
              {isAllowDeposit && (
                <>
                  <div className={`adjustments-enterprise-wise-payroll-table `}>
                    {/* {!hasViewSensitiveDataPermission && (
                      <span className="notification-banners orange-bg">
                        You do not have permission to view sensitive
                        information.
                      </span>
                    )} */}
                    {depositAccountsData?.length > 1 &&
                      depositAccountsData[1]?.accountNumber && (
                        <span className="notification-banners blue-bg">
                          Remaining Direct Deposit funds will deposit into
                          Account #
                          {+enterPriseWisePayrollData?.remainingBalanceAcc === 2
                            ? depositAccountsData[1]?.accountNumber
                            : depositAccountsData[0]?.accountNumber}{" "}
                        </span>
                      )}
                    {/* <BootstrapTable
                      keyField="rowNo"
                      data={depositAccountsData || []}
                      columns={depositTableColumns}
                      rowClasses={rowClasses}
                      hover
                      condensed
                    /> */}
                    {depositAccountsData?.length > 0 && (
                      <AgGridReact
                        onGridReady={onGridReady}
                        domLayout={"autoHeight"}
                        rowData={depositAccountsData || []}
                        columnDefs={depositTableColumns}
                        rowDragManaged={hasEditPermission}
                        animateRows={true}
                        // suppressMoveWhenRowDragging={true}
                        // suppressRowDrag={true}
                        // stopEditingWhenGridLosesFocus={true}
                        // onCellValueChanged={(params) =>
                        //   onCellValueChanged(params)
                        // }
                        // onCellEditingStopped={(params) =>
                        //   onCellEditingStopped(params)
                        // }
                        onRowDragEnd={(params) => onRowDragEnd(params)}
                        onRowDragMove={(params) => onRowDragMove(params)}
                        gridOptions={getAgGridOptions()}
                        frameworkComponents={{
                          valueRender: valueRender,
                          tripleDotRender: tripleDotRender,
                          numericCellEditor: NumericEditor,
                        }}
                        //  defaultColDef={{sortable: true,
                        //  filter: true}}
                        //  onDragStopped={this.columnDragStop}
                        //style ={{minHeight: 200}}
                        //  onCellKeyDown={(e) => {

                        //    if(e.colDef.field == "ddAmount") {
                        //     const handleKeyEvent=e?.data?.ddAmountType === "% of Paycheck"? Utils.AllowOnlyPositiveNumnberInputWithoutDot: Utils.AllowOnlyPositiveNumnberInput
                        //      handleKeyEvent(e.event)

                        //   }
                        //   }
                        // }
                        //  onCellEditingStarted = {({value})=>{ return [1,2,""].includes(value) && value   }}

                        //  suppressDragLeaveHidesColumns={true}
                        //  onCellClicked={(e: any) => {
                        //    if (
                        //      e.column &&
                        //      (e.column.colDef.cellClass === "triple-dot" ||
                        //        e.event.target.type === "checkbox")
                        //    ) {
                        //      e.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection =
                        //        true;
                        //    } else {
                        //      e.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection =
                        //        false;
                        //      this.handleShowSlideout(e);
                        //    }
                        //  }}
                      />
                    )}
                    {depositAccountsData?.length < 2 &&
                      (hasAddPermission || this.props?.isNewUserAdded) &&
                      (this.props.isEditLaborandPayrollInfo ||
                        this.props?.isNewUserAdded) && (
                        <Button
                          className="btn-ghost"
                          onClick={() => this.openDepositEntryPanel()}
                        >
                          Add Deposit Account
                        </Button>
                      )}
                  </div>

                  {showDepositModal && (
                    <AddDepositAccount
                      depositRowForAddEdit={depositRowForAddEdit}
                      depositRowEdit={depositRowEdit}
                      hideDepositModal={this.hideDepositModal}
                      showDepositModal={showDepositModal}
                      addUpdateDeposit={this.addUpdateDeposit}
                      enterPriseWisePayrollData={enterPriseWisePayrollData}
                      isDataSubmitting={this.state.isDataSubmitting}
                      hasViewSensitiveDataPermission={
                        hasViewSensitiveDataPermission
                      }
                      //   parentData={row}
                    />
                  )}
                </>
              )}
            </div>
          )}

          {row.id === 2 && (
            <div className="rowExpended" id="laborDayDetails">
              <Form.Group
                controlId="ssn-selection"
                onBlur={() => this.validationExcemptionsOnBlur(10)}
                className={
                  exemptionsErrors?.federalExemption && inputPosition >= 10
                    ? "validation-error d-flex flex-row"
                    : "d-flex flex-row"
                }
              >
                <Form.Label>Federal Exemptions</Form.Label>
                <div className="validate-input d-flex flex-column">
                  <input
                    type="text"
                    onKeyDown={Utils.AllowOnlyPositiveNumnberInputWithoutDot}
                    onBlur={(e) => this.onExcemptionsFieldBlur(e)}
                    className="form-control"
                    id="txtfederalExemptions"
                    name="federalExemption"
                    autoComplete="federalExemption"
                    disabled={
                      !hasAddPermission &&
                      !hasEditPermission &&
                      !this.props?.isNewUserAdded
                    }
                    value={federalExemption}
                    onChange={(e: any) => this.onExcemptionsFieldChange(e, 10)}
                  />

                  {this.getExcemptionsFieldError("federalExemption", 10)}
                </div>
              </Form.Group>
              {/* <Form.Group
                controlId="additionalFederal-selection"
                className={
                  exemptionsErrors?.additionalFederal && inputPosition >= 11
                    ? "validation-error d-flex flex-row"
                    : "d-flex flex-row"
                }
                onBlur={() => this.validationExcemptionsOnBlur(11)}
              >
                <Form.Label>Additional Withholdings</Form.Label>
                <div className="validate-input d-flex flex-column">
                  <input
                    type="text"
                    onKeyDown={Utils.AllowOnlyPositiveNumnberInput}

                    className="form-control"
                    id="txtadditionalWithholdings"
                    name="additionalFederal"
                    autoComplete="additionalFederal"
                    disabled={!hasAddPermission && !hasEditPermission && !this.props?.isNewUserAdded}
                    value={additionalFederal}
                    onChange={(e: any) => this.onExcemptionsFieldChange(e, 11)}
                  />

                  {this.getExcemptionsFieldError("additionalFederal", 11)}
                </div>
              </Form.Group> */}

              <Form.Group
                controlId="futa-selection"
                onBlur={() => this.validationExcemptionsOnBlur(12)}
                className={
                  exemptionsErrors?.futa && inputPosition >= 12
                    ? "validation-error d-flex flex-row"
                    : "d-flex flex-row"
                }
              >
                <Form.Label>FUTA Exempt</Form.Label>
                <div className="validate-input d-flex flex-column">
                  <DropDownList
                    placeHolder={"FUTA Exempt"}
                    data={StaticArrays.yesNoOptions}
                    defaultValue={futa}
                    isSearchRequired={false}
                    label={"label"}
                    value={"value"}
                    disabled={
                      !hasAddPermission &&
                      !hasEditPermission &&
                      !this.props?.isNewUserAdded
                    }
                    onDropDownChange={(item) => {
                      if (!item) {
                        return;
                      }
                      this.onExcemptionsFieldChange(
                        Utils.BuildCustomEventObject("futa", item.value),
                        12
                      );
                    }}
                    selectedItem={
                      [
                        ...[{ value: "", label: "Select" }],
                        ...StaticArrays.yesNoOptions,
                      ].find((r: any) => r?.value === futa) || {}
                    }
                  />

                  {this.getExcemptionsFieldError("futa", 12)}
                </div>
              </Form.Group>

              <Form.Group
                controlId="ssn-selection"
                className={
                  exemptionsErrors?.fica && inputPosition >= 13
                    ? "validation-error d-flex flex-row"
                    : "d-flex flex-row"
                }
                onBlur={() => this.validationExcemptionsOnBlur(13)}
              >
                <Form.Label>FICA Exempt</Form.Label>
                <div className="validate-input d-flex flex-column">
                  <DropDownList
                    placeHolder={"FICA Exempt"}
                    data={StaticArrays.yesNoOptions}
                    defaultValue={fica}
                    isSearchRequired={false}
                    label={"label"}
                    value={"value"}
                    disabled={
                      !hasAddPermission &&
                      !hasEditPermission &&
                      !this.props?.isNewUserAdded
                    }
                    onDropDownChange={(item) => {
                      if (!item) {
                        return;
                      }
                      this.onExcemptionsFieldChange(
                        Utils.BuildCustomEventObject("fica", item.value),
                        13
                      );
                    }}
                    selectedItem={
                      [
                        ...[{ value: "", label: "Select" }],
                        ...StaticArrays.yesNoOptions,
                      ].find((r: any) => r?.value === fica) || {}
                    }
                  />

                  {this.getExcemptionsFieldError("fica", 13)}
                </div>
              </Form.Group>

              <Form.Group
                controlId="stateExemption-selection"
                className={
                  exemptionsErrors?.stateExemption && inputPosition >= 14
                    ? "validation-error d-flex flex-row"
                    : "d-flex flex-row"
                }
                onBlur={() => this.validationExcemptionsOnBlur(14)}
              >
                <Form.Label>State Exemptions</Form.Label>
                <div className="validate-input d-flex flex-column">
                  <input
                    type="text"
                    onKeyDown={Utils.AllowOnlyPositiveNumnberInputWithoutDot}
                    onBlur={(e) => this.onExcemptionsFieldBlur(e)}
                    className="form-control"
                    id="txtstateExemptions"
                    name="stateExemption"
                    autoComplete="stateExemptions"
                    disabled={
                      !hasAddPermission &&
                      !hasEditPermission &&
                      !this.props?.isNewUserAdded
                    }
                    value={stateExemption}
                    onChange={(e: any) => this.onExcemptionsFieldChange(e, 14)}
                  />

                  {this.getExcemptionsFieldError("stateExemption", 14)}
                </div>
              </Form.Group>
            </div>
          )}

          {row.id === 3 && (
            <div className="rowExpended" id="laborDayDetails">
              <Form.Group
                controlId="stateExemption-selection"
                className={
                  payrollWithholdingSettingsErrors?.use2020W4 &&
                  inputPosition >= 22
                    ? "validation-error d-flex flex-row"
                    : "d-flex flex-row"
                }
                onBlur={() =>
                  this.validationPayrollWithholdingSettingsOnBlur(22)
                }
              >
                <Form.Label>Use 2020 W4</Form.Label>
                <div className="validate-input d-flex flex-column">
                  <Form.Check
                    type="checkbox"
                    id="use2020W4"
                    label=""
                    value={use2020W4}
                    checked={use2020W4}
                    disabled={
                      !hasAddPermission &&
                      !hasEditPermission &&
                      !this.props?.isNewUserAdded
                    }
                    onChange={(e) => {
                      this.onPayrollWithholdingSettingsFieldChange(
                        Utils.BuildCustomEventObject(
                          "use2020W4",
                          e.target.checked
                        ),
                        22
                      );
                    }}
                    name="chkIsJobs"
                  />

                  {this.getPayrollWithholdingSettingsFieldError(
                    "use2020W4",
                    22
                  )}
                </div>
              </Form.Group>
              <Form.Group
                onBlur={() =>
                  this.validationPayrollWithholdingSettingsOnBlur(23)
                }
                controlId="date-selection"
                className={
                  payrollWithholdingSettingsErrors?.federalFiling &&
                  inputPosition >= 23
                    ? "validation-error d-flex flex-row"
                    : "d-flex flex-row"
                }
              >
                <Form.Label>
                  <div className="d-flex align-items-center">
                    <div
                      className={
                        payrollWithholdingSettingsErrors?.federalFiling
                          ? "pips left-15"
                          : ""
                      }
                    >
                      <div className="pip light-red"></div>
                    </div>
                    <div>Federal Filing Status</div>
                  </div>
                </Form.Label>
                <div className="validate-input d-flex flex-column">
                  <DropDownList
                    onFocus={this.calenderHideI9}
                    placeHolder={"Select Federal Filing Status"}
                    data={StaticArrays.fillingStatusList}
                    defaultValue={federalFiling}
                    isSearchRequired={false}
                    label={"label"}
                    value={"value"}
                    disabled={
                      !hasAddPermission &&
                      !hasEditPermission &&
                      !this.props?.isNewUserAdded
                    }
                    onDropDownChange={(item) => {
                      if (!item) {
                        return;
                      }
                      this.onPayrollWithholdingSettingsFieldChange(
                        Utils.BuildCustomEventObject(
                          "federalFiling",
                          item.value
                        ),
                        23
                      );
                    }}
                    selectedItem={
                      [
                        ...[
                          {
                            value: "",
                            label: "Select",
                          },
                        ],
                        ...StaticArrays.fillingStatusList,
                      ].find((r: any) => r?.value === federalFiling) || {}
                    }
                  />
                  {this.getPayrollWithholdingSettingsFieldError(
                    "federalFiling",
                    23
                  )}
                </div>
              </Form.Group>
              {!use2020W4 && (
                <Form.Group
                  controlId="ssn-selection"
                  className={
                    payrollWithholdingSettingsErrors?.noOfFederalAllowances &&
                    inputPosition >= 24
                      ? "validation-error d-flex flex-row"
                      : "d-flex flex-row"
                  }
                  onBlur={() =>
                    this.validationPayrollWithholdingSettingsOnBlur(24)
                  }
                >
                  <Form.Label># of Federal Allowances</Form.Label>
                  <div className="validate-input d-flex flex-column">
                    <input
                      type="text"
                      onKeyDown={Utils.AllowOnlyPositiveNumnberInput}
                      min="0"
                      className="form-control"
                      id="txtnoOfFederalAllowances"
                      name="noOfFederalAllowances"
                      autoComplete="noOfFederalAllowances"
                      disabled={
                        !hasAddPermission &&
                        !hasEditPermission &&
                        !this.props?.isNewUserAdded
                      }
                      value={noOfFederalAllowances}
                      onChange={(e: any) =>
                        this.onPayrollWithholdingSettingsFieldChange(e, 24)
                      }
                    />

                    {this.getPayrollWithholdingSettingsFieldError(
                      "noOfFederalAllowances",
                      24
                    )}
                  </div>
                </Form.Group>
              )}
              {use2020W4 && (
                <>
                  <Form.Group
                    controlId="stateExemption-selection"
                    className={
                      payrollWithholdingSettingsErrors?.isJobs &&
                      inputPosition >= 25
                        ? "validation-error d-flex flex-row"
                        : "d-flex flex-row"
                    }
                    onBlur={() =>
                      this.validationPayrollWithholdingSettingsOnBlur(25)
                    }
                  >
                    <Form.Label>Step 2: Two Jobs</Form.Label>
                    <div className="validate-input d-flex flex-column">
                      <Form.Check
                        type="checkbox"
                        id="isJobs"
                        label=""
                        value={isJobs}
                        checked={isJobs}
                        disabled={
                          !hasAddPermission &&
                          !hasEditPermission &&
                          !this.props?.isNewUserAdded
                        }
                        onChange={(e) => {
                          this.onPayrollWithholdingSettingsFieldChange(
                            Utils.BuildCustomEventObject(
                              "isJobs",
                              e.target.checked
                            ),
                            25
                          );
                        }}
                        name="chkIsJobs"
                      />

                      {this.getPayrollWithholdingSettingsFieldError(
                        "isJobs",
                        25
                      )}
                    </div>
                  </Form.Group>
                  <Form.Group
                    controlId="ssn-selection"
                    className={
                      payrollWithholdingSettingsErrors?.creditAmt &&
                      inputPosition >= 26
                        ? "validation-error d-flex flex-row"
                        : "d-flex flex-row"
                    }
                    onBlur={() =>
                      this.validationPayrollWithholdingSettingsOnBlur(26)
                    }
                  >
                    <Form.Label>Step 3: Dependents Amount</Form.Label>
                    <div className="validate-input d-flex flex-column">
                      <input
                        type="text"
                        min="0"
                        className="form-control"
                        id="txtcreditAmt"
                        name="creditAmt"
                        autoComplete="creditAmt"
                        disabled={
                          !hasAddPermission &&
                          !hasEditPermission &&
                          !this.props?.isNewUserAdded
                        }
                        value={creditAmt}
                        placeholder="$0.00"
                        onBlur={(e) => this.handleCurrencyFormat(e)}
                        onFocus={(e) => this.onCurrencyFocus(e)}
                        onChange={(e: any) =>
                          this.onPayrollWithholdingSettingsFieldChange(e, 26)
                        }
                      />

                      {this.getPayrollWithholdingSettingsFieldError(
                        "creditAmt",
                        26
                      )}
                    </div>
                  </Form.Group>

                  <Form.Group
                    controlId="additionalFederal-selection"
                    className={
                      payrollWithholdingSettingsErrors?.otherIncome &&
                      inputPosition >= 27
                        ? "validation-error d-flex flex-row"
                        : "d-flex flex-row"
                    }
                    onBlur={() =>
                      this.validationPayrollWithholdingSettingsOnBlur(27)
                    }
                  >
                    <Form.Label>Step 4a: Other Income</Form.Label>
                    <div className="validate-input d-flex flex-column">
                      <input
                        type="text"
                        min="0"
                        className="form-control"
                        id="txtotherIncome"
                        name="otherIncome"
                        autoComplete="otherIncome"
                        disabled={
                          !hasAddPermission &&
                          !hasEditPermission &&
                          !this.props?.isNewUserAdded
                        }
                        value={otherIncome}
                        placeholder="$0.00"
                        onBlur={(e) => this.handleCurrencyFormat(e)}
                        onFocus={(e) => this.onCurrencyFocus(e)}
                        onChange={(e: any) =>
                          this.onPayrollWithholdingSettingsFieldChange(e, 27)
                        }
                      />

                      {this.getPayrollWithholdingSettingsFieldError(
                        "otherIncome",
                        27
                      )}
                    </div>
                  </Form.Group>

                  <Form.Group
                    controlId="stateExemption-selection"
                    className={
                      payrollWithholdingSettingsErrors?.wksht &&
                      inputPosition >= 28
                        ? "validation-error d-flex flex-row"
                        : "d-flex flex-row"
                    }
                    onBlur={() =>
                      this.validationPayrollWithholdingSettingsOnBlur(28)
                    }
                  >
                    <Form.Label>Step 4b: Deductions</Form.Label>
                    <div className="validate-input d-flex flex-column">
                      <input
                        type="text"
                        min="0"
                        className="form-control"
                        id="txtwksht"
                        name="wksht"
                        autoComplete="wksht"
                        disabled={
                          !hasAddPermission &&
                          !hasEditPermission &&
                          !this.props?.isNewUserAdded
                        }
                        value={wksht}
                        placeholder="$0.00"
                        onBlur={(e) => this.handleCurrencyFormat(e)}
                        onFocus={(e) => this.onCurrencyFocus(e)}
                        onChange={(e: any) =>
                          this.onPayrollWithholdingSettingsFieldChange(e, 28)
                        }
                      />

                      {this.getPayrollWithholdingSettingsFieldError(
                        "wksht",
                        28
                      )}
                    </div>
                  </Form.Group>
                </>
              )}
              <Form.Group
                controlId="stateExemption-selection"
                className={
                  payrollWithholdingSettingsErrors?.extraAmt &&
                  inputPosition >= 29
                    ? "validation-error d-flex flex-row"
                    : "d-flex flex-row"
                }
                onBlur={() =>
                  this.validationPayrollWithholdingSettingsOnBlur(29)
                }
              >
                <Form.Label>Additional Federal W/H</Form.Label>
                <div className="validate-input d-flex flex-column">
                  <input
                    type="text"
                    min="0"
                    className="form-control"
                    id="txtextraAmt"
                    name="extraAmt"
                    autoComplete="extraAmt"
                    disabled={
                      !hasAddPermission &&
                      !hasEditPermission &&
                      !this.props?.isNewUserAdded
                    }
                    value={extraAmt}
                    placeholder="$0.00"
                    onBlur={(e) => this.handleCurrencyFormat(e)}
                    onFocus={(e) => this.onCurrencyFocus(e)}
                    onChange={(e: any) =>
                      this.onPayrollWithholdingSettingsFieldChange(e, 29)
                    }
                  />
                  {this.getPayrollWithholdingSettingsFieldError("extraAmt", 29)}
                </div>
              </Form.Group>
            </div>
          )}
        </>
      ),
      expanded: this.state.tableExpended,
      onExpand: this.handleOnExpand,
      showExpandColumn: true,
      expandByColumnOnly: false,
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <span className="chevronExpended">
              <img src={chevronRight} alt="chevron Right" />
            </span>
          );
        }
        return (
          <span className="chevronCollpsed">
            <img src={chevronRight} alt="chevron Right" />
          </span>
        );
      },
    };

    const sectionTableColumns: any[] = [
      {
        dataField: "sectionName",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="d-flex align-items-center">
            <>
              {(row?.banner ||
                (Object.keys(payrollWithholdingSettingsErrors)?.length > 0 &&
                  cell === "Federal Taxes") ||
                (Object.keys(exemptionsErrors)?.length > 0 &&
                  cell === "Exemptions")) && (
                <div className="pips">
                  <div className="pip light-red"></div>
                </div>
              )}
            </>{" "}
            <div>{cell}</div>
          </div>
        ),
      },
      {
        dataField: "banner",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => <div></div>,
      },

      // {
      //   dataField: "",
      //   text: "",
      //   formatter: (cell: any, row: any, rowIndex: any) => (
      //     <Dropdown
      //       onClick={(e) => {
      //         e.stopPropagation();
      //       }}
      //       className="more-action"
      //       alignRight
      //     >
      //       <Dropdown.Toggle
      //         className="btn-outline-primary btn btn-primary btn-ghost more"
      //         id="dropdown-more"
      //       >
      //         <svg
      //           width="20"
      //           height="20"
      //           viewBox="0 0 20 20"
      //           fill="none"
      //           xmlns="http://www.w3.org/2000/svg"
      //         >
      //           <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
      //           <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
      //           <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
      //         </svg>
      //       </Dropdown.Toggle>
      //       <Dropdown.Menu>
      //         {/* <Dropdown.Item key={row?.sectionName} eventKey={"status"}>
      //           test
      //         </Dropdown.Item> */}
      //       </Dropdown.Menu>
      //     </Dropdown>
      //   ),
      // },
    ];

    const deductionsMainColumns = [
      {
        dataField: "letterCode",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="deduction-ehid-date-details d-flex flex-column">
            <span className="deduction-ehid">{row?.letterCode}</span>
            <span className="deduction-date">{row?.createdDate}</span>
          </div>
        ),
      },
      {
        dataField: "status",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className={`badge ${this.getStatusClassName(cell)}`}>{cell}</div>
        ),
      },
      {
        dataField: "amount",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="deduction-amount-details d-flex flex-column">
            <span className="deduction-amount">
              {row?.deductionCriteria?.toString()?.includes("%") ? "" : "$"}
              {row?.amount}
              {row?.deductionCriteria?.toString()?.includes("%") ? "%" : ""}
            </span>
            <span className="deduction-amount-label">Amount</span>
          </div>
        ),
      },
      {
        dataField: "deductionCriteria",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="deduction-criteria-details d-flex flex-column">
            <span className="deduction-criteria">{row?.deductionCriteria}</span>
            <span className="deduction-criteria-label">Criteria</span>
          </div>
        ),
      },
      {
        dataField: "deductionType",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="deduction-type-details d-flex flex-column">
            <span className="deduction-type">{row?.deductionType}</span>
            <span className="deduction-type-label">Type</span>
          </div>
        ),
      },

      {
        dataField: "",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <Dropdown
            // onClick={(e) => {
            //   e.stopPropagation();
            // }}
            className="more-action"
            alignRight
          >
            <Dropdown.Toggle
              disabled={this.shouldDisableRow(row.letterCode)}
              className="btn-outline-primary btn btn-primary btn-ghost more"
              id="dropdown-more"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <>
                {row?.status === "Active" && (
                  <Dropdown.Item
                    key={"Edit"}
                    onClick={() => this.openDeductionEntryPanel(true, row)}
                    eventKey={"Edit"}
                  >
                    Edit
                  </Dropdown.Item>
                )}
              </>
              {/* <Dropdown.Item
                key={"Delete"}
                onClick={() => this.deleteDeduction(row)}
                eventKey={"Delete"}
              >
                Delete
              </Dropdown.Item> */}
              <Dropdown.Item
                key={row?.status}
                onClick={() =>
                  this.updateDeductionStatus(
                    row,
                    row?.status === "Active" ? "Inactive" : "Active"
                  )
                }
                eventKey={"status"}
              >
                {row?.status === "Active" ? "Deactivate" : "Activate"}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ),
      },
    ];

    const expandRowDeductions = {
      renderer: (row: any) => (
        <div className="rowExpended" id="laborDayDetails">
          Some text
          <div className="laborDayApprovalTable cuslaborDayApprovalTable"></div>
        </div>
      ),
      expanded: this.state.tableExpended,
      onExpand: this.handleDeductionRowExpand,
      showExpandColumn: true,
      expandByColumnOnly: false,
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <span className="chevronExpended">
              <img src={chevronRight} alt="chevron Right" />
            </span>
          );
        }
        return (
          <span className="chevronCollpsed">
            <img src={chevronRight} alt="chevron Right" />
          </span>
        );
      },
    };

    const handleTableCollapse = (e,row,rowIndex, ExpandOnly=false)=>{
      const expandedItem=this.state.ganishmentTableExpandend?.find(x => x == row.hid)
      let isExpand = expandedItem ? false : this.state.isGarnishmentExpand
      console.log({isExpand})
      if(ExpandOnly )
      {
        isExpand=true
      }
      this.handleOnGarnishmentRowExpand(row,isExpand,rowIndex,e)
    
  }
  const tableColumnEvent = {
    onClick: (e, column, columnIndex, row, rowIndex) => handleTableCollapse(e,row,rowIndex),
  };

    const garnishmentsMainColumns = [
      {
        dataField: "letterCode",
        text: "",
        events: tableColumnEvent,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="garnishment-ehid-date-details d-flex flex-column">
            <span className="garnishment-ehid">{row?.lettercode}</span>
            <span className="garnishment-date">{row?.date}</span>
          </div>
        ),
      },
      {
        dataField: "status",
        text: "",
        events: tableColumnEvent,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className={`badge ${this.getStatusClassName(cell)}`}>{cell}</div>
        ),
      },
      {
        dataField: "amount",
        text: "",
        events: tableColumnEvent,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="garnishment-amount-details d-flex flex-column">
            <span className="garnishment-amount">${row?.totalAmount}</span>
            <span className="garnishment-amount-label">To be paid</span>
          </div>
        ),
      },
      {
        dataField: "",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <Dropdown
            // onClick={(e) => {
            //   e.stopPropagation();
            // }}
            className="more-action"
            alignRight
          >
            <Dropdown.Toggle
              className="btn-outline-primary btn btn-primary btn-ghost more"
              id="dropdown-more"
              disabled={this.shouldDisableRow(row.lettercode)}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {(hasEditPermission || this.props?.isNewUserAdded) && (
                <Dropdown.Item
                  key={row?.status + "edit"}
                  onClick={(e) => {
                    handleTableCollapse(e,row,rowIndex,true)
                    this.openGarnishmentEntryPanel(true, row)}}
                  eventKey={"edit"}
                >
                  Edit
                </Dropdown.Item>
              )}
              <Dropdown.Item
                key={row?.status}
                onClick={() => this.deleteUserGranishment(row)}
                eventKey={"status"}
              >
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ),
      },
    ];

    const expandRowGarnishments = {
      renderer: (row: any) => (
        <div className="rowExpended" id="laborDayDetails">
          <div className="section-heading">Garnishment Details</div>
          <div className="comments-section">
            <div className="heading">Comments</div>
            <div className="description">
              {row?.comments.replace(/\s{2,}/g, " ").trim()}
            </div>
          </div>
          <div className="attachments-section">
            {row?.fileName && (
              <div>
                <div className="heading">Attachments</div>
                <div className="attached-file d-flex align-items-center">
                  <div className="file-type">
                    {row?.fileName
                      ?.toString()
                      ?.substr(row?.fileName?.toString()?.lastIndexOf(".") + 1)
                      .toUpperCase()}
                  </div>
                  <div className="file-name mr-auto">{row?.fileName}</div>
                  <div>
                    {" "}
                    <Dropdown
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      className="more-action"
                      alignRight
                    >
                      <Dropdown.Toggle
                        className="btn-outline-primary btn btn-primary btn-ghost more"
                        id="dropdown-more"
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                          <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                          <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                        </svg>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => this.downloadGranismentDocument(row)}
                          eventKey={"status"}
                        >
                          Download
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ),
      expanded: this.state.ganishmentTableExpandend,
      onExpand: this.handleOnGarnishmentRowExpand,
      showExpandColumn: true,
      expandByColumnOnly: true,
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <span className="chevronExpended">
              <img src={chevronRight} alt="chevron Right" />
            </span>
          );
        }
        return (
          <span className="chevronCollpsed">
            <img src={chevronRight} alt="chevron Right" />
          </span>
        );
      },
    };

    const sectionNamesData = _.cloneDeep(this.sectionNames).map((x) => {
      if (
        x.sectionName === "Direct Deposit" &&
        (!isAllowDeposit ||
          !this.state.isToggleSaved ||
          (isAllowDeposit && depositAccountsData?.length > 0))
      ) {
        x.banner = "";
      }
      if (
        x.sectionName === "Exemptions" &&
        Object.keys(exemptionsErrors)?.length > 0
      ) {
        x.banner = "error";
      }
      if (
        x.sectionName === "Federal Taxes" &&
        Object.keys(payrollWithholdingSettingsErrors)?.length > 0
      ) {
        x.banner = "error";
      }
      return x;
    });

    return (
      <>
        <div className="payroll-tab">
          {showPayrollAddressModal && (
            <AddPayrollAddress
              userName={this.props?.userName}
              enterPriseWisePayrollData={enterPriseWisePayrollData}
              showPayrollAddressModal={showPayrollAddressModal}
              hidePayrollAddressModal={this.hidePayrollAddressModal}
              reAttachEnteriseWideSettingAddressToMainObject={
                this.reAttachEnteriseWideSettingAddressToMainObject
              }
            />
          )}

          <div className="body-section">
            {/* {(userGeneralInfo?.hasViewContactInfo || isNewUserAdded) && ( */}
            <>
              <div className="heading">User Payroll Information</div>
              <div className="form-section d-flex flex-column">
                {/* {showPayrollSettingBanner && (
                  <span className="notification-banners orange-bg">
                    Please add Enterprise wide setting to be able to schedule and run payroll for the User.
                  </span>
                )} */}
                <Form.Group
                  controlId="employee-type"
                  className={"d-flex flex-row"}
                >
                  <Form.Label>Employee Type</Form.Label>
                  <div className="validate-input d-flex flex-column">
                    <input
                      type="text"
                      className="form-control"
                      id="employeetype"
                      name="employeetype"
                      autoComplete="employeetype"
                      value={this.props?.userGeneralInfo?.employeeTypeDisplay}
                      disabled={true}
                    />
                  </div>
                </Form.Group>
                {/* <Form.Group
                  controlId="ssn-selection"
                  className={
                    enterpriseWideSettingErrors?.ssn && inputPosition >= 1
                      ? "validation-error d-flex flex-row"
                      : "d-flex flex-row"
                  }
                  onBlur={() => this.validationEnterpriseWideSettingOnBlur(1)}
                >
                  <Form.Label>
                    <div className="d-flex align-items-center">
                      <div
                        className={
                          enterpriseWideSettingErrors?.ssn ? "pips" : ""
                        }
                      >
                        <div className="pip light-red"></div>
                      </div>
                      <div>SSN</div>
                    </div>
                  </Form.Label>
                  <div className="validate-input d-flex flex-column">
                    <input
                      onBlur={() => this.autoFormatSSNOnBlur()}
                      type="text"
                      className="form-control"
                      id="txtSSN"
                      name="ssn"
                      onKeyDown={Utils.AllowOnlyPositiveNumnberInputWithDash}
                      autoComplete="ssn"
                      maxLength={11}
                      disabled={
                        ((!hasAddPermission && !hasEditPermission) ||
                          !hasViewSensitiveDataPermission) &&
                        !this.props?.isNewUserAdded
                      }
                      value={ssn}
                      onChange={(e: any) =>
                        this.onEnterpriseWideSettingFieldChange(e, 1)
                      }
                    />
                    {this.getEnterpriseWideSettingFieldFieldError("ssn", 1)}
                  </div>
                </Form.Group> */}

                <Form.Group
                  onBlur={() => this.validationEnterpriseWideSettingOnBlur(2)}
                  controlId="dob-selection"
                  className={
                    enterpriseWideSettingErrors?.dob && inputPosition >= 2
                      ? "validation-error d-flex flex-row date-picker"
                      : "d-flex flex-row date-picker"
                  }
                >
                  <Form.Label>
                    <div className="d-flex align-items-center">
                      <div
                        className={
                          enterpriseWideSettingErrors?.dob ? "pips" : ""
                        }
                      >
                        <div className="pip light-red"></div>
                      </div>
                      <div>DOB</div>
                    </div>
                  </Form.Label>

                  <div className="validate-input d-flex flex-column">
                    <div className="calender-container">
                      <div
                        className={
                          this.state.calenderShow
                            ? "calender-display open"
                            : "calender-display"
                        }
                      >
                        <input
                          type="text"
                          onClick={this.calenderShow}
                          placeholder="MM/DD/YYYY"
                          maxLength={10}
                          onInput={(e) => {
                            let value = e.currentTarget.value;

                            value = value
                              ?.toString()
                              ?.replace(/[^0-9\/]+/gi, "");
                            this.onEnterpriseWideSettingFieldChange(
                              Utils.BuildCustomEventObject("dob", value),
                              2
                            );
                          }}
                          disabled={true}
                          onBlur={(e) => {
                            let value = e.currentTarget.value;
                            value = value
                              ?.toString()
                              ?.replace(/[^0-9\/]+/gi, "");
                            this.onEnterpriseWideSettingFieldChange(
                              Utils.BuildCustomEventObject("dob", value),
                              2
                            );
                          }}
                          value={dob}
                          className="form-control single-date-pick"
                          name="dob"
                          autoComplete="dob"
                        />
                        <svg
                          // onClick={this.calenderShow}
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z" />
                          <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z" />
                        </svg>
                      </div>
                      {this.state.calenderShow && (
                        <div className="calender">
                          <OutsideClickHandler
                            onOutsideClick={this.calenderHide}
                          >
                            <Calendar
                              calendarType={"US"}
                              onChange={(date) => {
                                const value = moment(date).format("MM/DD/YYYY");
                                this.onEnterpriseWideSettingFieldChange(
                                  Utils.BuildCustomEventObject("dob", value),
                                  2
                                );
                                this.calenderHide();
                              }}
                              value={
                                dob
                                  ? moment(dob)?.format("MM/DD/YYYY") ===
                                    "Invalid date"
                                    ? new Date()
                                    : new Date(dob)
                                  : new Date()
                              }
                              minDate={
                                this.props?.hireDate
                                  ? new Date(
                                      moment(this.props?.hireDate).format(
                                        "MM/DD/YYYY"
                                      )
                                    )
                                  : undefined
                              }
                              maxDate={new Date()}
                            />
                          </OutsideClickHandler>
                        </div>
                      )}
                    </div>

                    {this.getEnterpriseWideSettingFieldFieldError("dob", 2)}
                  </div>
                </Form.Group>

                <Form.Group
                  onBlur={() => this.validationEnterpriseWideSettingOnBlur(3)}
                  controlId="date-selection"
                  className={
                    enterpriseWideSettingErrors?.gender && inputPosition >= 3
                      ? "validation-error d-flex flex-row"
                      : "d-flex flex-row"
                  }
                >
                  <Form.Label>
                    <div className="d-flex align-items-center">
                      <div
                        className={
                          enterpriseWideSettingErrors?.gender ? "pips" : ""
                        }
                      >
                        <div className="pip light-red"></div>
                      </div>
                      <div>Gender</div>
                    </div>
                  </Form.Label>
                  <div className="validate-input d-flex flex-column">
                    <DropDownList
                      placeHolder={"Select"}
                      data={StaticArrays.genderList}
                      defaultValue={gender}
                      isSearchRequired={false}
                      label={"label"}
                      value={"value"}
                      disabled={
                        !hasAddPermission &&
                        !hasEditPermission &&
                        !this.props?.isNewUserAdded
                      }
                      onDropDownChange={(item) => {
                        if (!item) {
                          return;
                        }
                        this.onEnterpriseWideSettingFieldChange(
                          Utils.BuildCustomEventObject("gender", item.value),
                          3
                        );
                      }}
                      selectedItem={
                        [
                          ...[{ value: "", label: "Select" }],
                          ...StaticArrays.genderList,
                        ].find((r: any) => r?.value === gender) || {}
                      }
                    />
                    {this.getEnterpriseWideSettingFieldFieldError("gender", 3)}
                  </div>
                </Form.Group>
                <Form.Group
                  controlId="ssn-selection"
                  className={
                    enterpriseWideSettingErrors?.address && inputPosition >= 4
                      ? "validation-error d-flex flex-row"
                      : "d-flex flex-row"
                  }
                >
                  <Form.Label
                    onBlur={() => this.validationEnterpriseWideSettingOnBlur(4)}
                  >
                    <div className="d-flex align-items-center">
                      <div
                        className={
                          enterpriseWideSettingErrors?.address ? "pips" : ""
                        }
                      >
                        <div className="pip light-red"></div>
                      </div>
                      <div>Address</div>
                    </div>
                  </Form.Label>
                  <div className="validate-input d-flex flex-column">
                    {address && (
                      <ReactTooltip
                        id="addressBar"
                        effect="solid"
                        place="bottom"
                      >
                        {address}
                      </ReactTooltip>
                    )}
                    <input
                      onClick={() => this.openAddressEntryPanel()}
                      onKeyPress={() => this.openAddressEntryPanel()}
                      type="text"
                      data-tip
                      data-for="addressBar"
                      className="form-control"
                      id="txtAddresssPayroll"
                      name="address"
                      autoComplete="txtAddresssPayroll"
                      disabled={
                        !hasAddPermission &&
                        !hasEditPermission &&
                        !this.props?.isNewUserAdded
                      }
                      value={address}
                    />
                    {this.getEnterpriseWideSettingFieldFieldError("address", 4)}
                  </div>
                </Form.Group>

                {this.state.isShowTaxFields && (
                  <>
                    <Form.Group
                      onBlur={() =>
                        this.validationEnterpriseWideSettingOnBlur(5)
                      }
                      className={
                        enterpriseWideSettingErrors?.country &&
                        inputPosition >= 5
                          ? "validation-error d-flex flex-row"
                          : "d-flex flex-row"
                      }
                      controlId="country"
                    >
                      <Form.Label>
                        <div className="d-flex align-items-center">
                          <div
                            className={
                              enterpriseWideSettingErrors?.country ? "pips" : ""
                            }
                          >
                            <div className="pip light-red"></div>
                          </div>
                          <div>County/Municipality</div>
                        </div>
                      </Form.Label>
                      <div className="validate-input d-flex flex-column">
                        {country && (
                          <ReactTooltip
                            id="country"
                            effect="solid"
                            place="bottom"
                          >
                            {country}
                          </ReactTooltip>
                        )}
                        <input
                          maxLength={100}
                          type="text"
                          className="form-control"
                          // placeholder="Please Enter County/Municipality"
                          data-tip
                          data-for="country"
                          id="txtCountry"
                          name="country"
                          autoComplete="txtCountry"
                          value={country}
                          disabled={
                            !hasAddPermission &&
                            !hasEditPermission &&
                            !this.props?.isNewUserAdded
                          }
                          onKeyDown={Utils.allowOnlyAplhabet}
                          onChange={(e) =>
                            this.onEnterpriseWideSettingFieldChange(e, 5)
                          }
                        />
                        {this.getEnterpriseWideSettingFieldFieldError(
                          "country",
                          5
                        )}
                      </div>
                    </Form.Group>

                    <Form.Group
                      onBlur={() =>
                        this.validationEnterpriseWideSettingOnBlur(6)
                      }
                      className={
                        enterpriseWideSettingErrors?.school &&
                        inputPosition >= 6
                          ? "validation-error d-flex flex-row"
                          : "d-flex flex-row"
                      }
                      controlId="school"
                    >
                      <Form.Label>
                        <div className="d-flex align-items-center">
                          <div
                            className={
                              enterpriseWideSettingErrors?.school ? "pips" : ""
                            }
                          >
                            <div className="pip light-red"></div>
                          </div>
                          <div>School District</div>
                        </div>
                      </Form.Label>
                      <div className="validate-input d-flex flex-column">
                        {school && (
                          <ReactTooltip
                            id="school"
                            effect="solid"
                            place="bottom"
                          >
                            {school}
                          </ReactTooltip>
                        )}
                        <input
                          maxLength={100}
                          type="text"
                          // placeholder="Please Enter School District"
                          data-tip
                          data-for="school"
                          className="form-control"
                          id="txtschool"
                          name="school"
                          autoComplete="txtschool"
                          value={school}
                          disabled={
                            !hasAddPermission &&
                            !hasEditPermission &&
                            !this.props?.isNewUserAdded
                          }
                          onKeyDown={Utils.allowOnlyAplhaNumeric}
                          onChange={(e) =>
                            this.onEnterpriseWideSettingFieldChange(e, 6)
                          }
                        />
                        {this.getEnterpriseWideSettingFieldFieldError(
                          "school",
                          6
                        )}
                      </div>
                    </Form.Group>
                  </>
                )}

                <Form.Group
                  onBlur={() => this.validationEnterpriseWideSettingOnBlur(7)}
                  controlId="date-selection"
                  className={
                    enterpriseWideSettingErrors?.jobType && inputPosition >= 7
                      ? "validation-error d-flex flex-row"
                      : "d-flex flex-row"
                  }
                >
                  <Form.Label>
                    <div className="d-flex align-items-center">
                      <div
                        className={
                          enterpriseWideSettingErrors?.jobType ? "pips" : ""
                        }
                      >
                        <div className="pip light-red"></div>
                      </div>
                      <div>Full/Part Time</div>
                    </div>
                  </Form.Label>
                  <div className="validate-input d-flex flex-column">
                    <DropDownList
                      placeHolder={"Select Full/Part Time"}
                      data={StaticArrays.jobTypes}
                      defaultValue={jobType}
                      isSearchRequired={false}
                      label={"label"}
                      value={"value"}
                      disabled={
                        !hasAddPermission &&
                        !hasEditPermission &&
                        !this.props?.isNewUserAdded
                      }
                      onDropDownChange={(item) => {
                        if (!item) {
                          return;
                        }
                        this.onEnterpriseWideSettingFieldChange(
                          Utils.BuildCustomEventObject("jobType", item.value),
                          7
                        );
                      }}
                      selectedItem={
                        [
                          ...[{ value: "", label: "Select" }],
                          ...StaticArrays.jobTypes,
                        ].find((r: any) => r?.value === jobType) || {}
                      }
                    />
                    {this.getEnterpriseWideSettingFieldFieldError("jobType", 7)}
                  </div>
                </Form.Group>
                <Form.Group
                  //  onBlur={() => this.validationEnterpriseWideSettingOnBlur(7)}
                  controlId="dob-selection"
                  className={
                    enterpriseWideSettingErrors?.i9SubmittedOn &&
                    inputPosition >= 7
                      ? "validation-error d-flex flex-row date-picker"
                      : "d-flex flex-row date-picker"
                  }
                >
                  <Form.Label>
                    <div className="d-flex align-items-center">
                      <div
                        className={
                          enterpriseWideSettingErrors?.i9SubmittedOn
                            ? "pips"
                            : ""
                        }
                      >
                        <div className="pip light-red"></div>
                      </div>
                      <div>I-9 Submitted On</div>
                    </div>
                  </Form.Label>

                  <div className="validate-input d-flex flex-column">
                    <div className="calender-container">
                      <div
                        className={
                          this.state.calenderShowI9
                            ? "calender-display open"
                            : "calender-display"
                        }
                      >
                        <input
                          type="text"
                          onClick={this.calenderShowI9}
                          onFocus={this.calenderShowI9}
                          placeholder="MM/DD/YYYY"
                          maxLength={10}
                          disabled={
                            !hasAddPermission &&
                            !hasEditPermission &&
                            !this.props?.isNewUserAdded
                          }
                          onInput={(e) => {
                            let value = e.currentTarget.value;
                            value = value
                              ?.toString()
                              ?.replace(/[^0-9\/]+/gi, "");
                            this.onEnterpriseWideSettingFieldChange(
                              Utils.BuildCustomEventObject(
                                "i9SubmittedOn",
                                value
                              ),
                              7
                            );
                          }}
                          onBlur={(e) => {
                            let value = e.currentTarget.value;
                            value = value
                              ?.toString()
                              ?.replace(/[^0-9\/]+/gi, "");
                            if (!value) {
                              return;
                            }
                            this.onEnterpriseWideSettingFieldChange(
                              Utils.BuildCustomEventObject(
                                "i9SubmittedOn",
                                value
                              ),
                              7
                            );
                          }}
                          value={i9SubmittedOn}
                          className="form-control single-date-pick"
                          name="i9SubmittedOn"
                          autoComplete="i9SubmittedOn"
                        />
                        <svg
                          onClick={this.calenderShowI9}
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z" />
                          <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z" />
                        </svg>
                      </div>
                      {this.state.calenderShowI9 && (
                        <div className="calender">
                          <OutsideClickHandler
                            onOutsideClick={this.calenderHideI9}
                          >
                            <Calendar
                              calendarType={"US"}
                              onChange={(date) => {
                                const value = moment(date).format("MM/DD/YYYY");
                                this.onEnterpriseWideSettingFieldChange(
                                  Utils.BuildCustomEventObject(
                                    "i9SubmittedOn",
                                    value
                                  ),
                                  7
                                );
                                this.calenderHideI9();
                              }}
                              value={
                                i9SubmittedOn
                                  ? moment(i9SubmittedOn)?.format(
                                      "MM/DD/YYYY"
                                    ) === "Invalid date"
                                    ? ""
                                    : new Date(i9SubmittedOn)
                                  : ""
                              }
                              // minDate={
                              //   this.props?.hireDate
                              //     ? new Date(
                              //       moment(this.props?.hireDate).format(
                              //         "MM/DD/YYYY"
                              //       )
                              //     )
                              //     : undefined
                              // }
                              maxDate={new Date()}
                            />
                          </OutsideClickHandler>
                        </div>
                      )}
                    </div>

                    {this.getEnterpriseWideSettingFieldFieldError(
                      "i9SubmittedOn",
                      7
                    )}
                  </div>
                </Form.Group>

                <Form.Group
                  // onBlur={() => this.validationEnterpriseWideSettingOnBlur(9)}
                  controlId="dob-selection"
                  className={
                    enterpriseWideSettingErrors?.w4Date && inputPosition >= 9
                      ? "validation-error d-flex flex-row date-picker"
                      : "d-flex flex-row date-picker"
                  }
                >
                  <Form.Label>
                    <div className="d-flex align-items-center">
                      <div
                        className={
                          enterpriseWideSettingErrors?.w4Date ? "pips" : ""
                        }
                      >
                        <div className="pip light-red"></div>
                      </div>
                      <div>W-4 Submitted On</div>
                    </div>
                  </Form.Label>

                  <div className="validate-input d-flex flex-column">
                    <div className="calender-container">
                      <div
                        className={
                          this.state.calenderShowW4
                            ? "calender-display open"
                            : "calender-display"
                        }
                      >
                        <input
                          type="text"
                          onClick={this.calenderShowW4}
                          onFocus={this.calenderShowW4}
                          placeholder="MM/DD/YYYY"
                          maxLength={10}
                          disabled={
                            (!hasAddPermission &&
                              !hasEditPermission &&
                              !this.props?.isNewUserAdded) ||
                            enterpriseWideSettingNotChangedForDate === false
                          }
                          onInput={(e) => {
                            debugger;
                            let value = e.currentTarget.value;

                            value = value
                              ?.toString()
                              ?.replace(/[^0-9\/]+/gi, "");
                            this.onEnterpriseWideSettingFieldChange(
                              Utils.BuildCustomEventObject("w4Date", value),
                              9
                            );
                          }}
                          onBlur={(e) => {
                            debugger;
                            let value = e.currentTarget.value;

                            value = value
                              ?.toString()
                              ?.replace(/[^0-9\/]+/gi, "");
                            if (!value) {
                              return;
                            }
                            this.onEnterpriseWideSettingFieldChange(
                              Utils.BuildCustomEventObject("w4Date", value),
                              9
                            );
                          }}
                          value={w4Date}
                          className="form-control single-date-pick"
                          name="w4Date"
                          autoComplete="w4Date"
                        />
                        <svg
                          onClick={this.calenderShowW4}
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z" />
                          <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z" />
                        </svg>
                      </div>
                      {this.state.calenderShowW4 && (
                        <div className="calender">
                          <OutsideClickHandler
                            onOutsideClick={this.calenderHideW4}
                          >
                            <Calendar
                              calendarType={"US"}
                              onChange={(date) => {
                                debugger;
                                const value = moment(date).format("MM/DD/YYYY");
                                this.onEnterpriseWideSettingFieldChange(
                                  Utils.BuildCustomEventObject("w4Date", value),
                                  9
                                );
                                this.calenderHideW4();
                              }}
                              value={
                                w4Date
                                  ? moment(w4Date)?.format("MM/DD/YYYY") ===
                                    "Invalid date"
                                    ? ""
                                    : new Date(w4Date)
                                  : ""
                              }
                              // minDate={
                              //   this.props?.hireDate
                              //     ? new Date(
                              //       moment(this.props?.hireDate).format(
                              //         "MM/DD/YYYY"
                              //       )
                              //     )
                              //     : undefined
                              // }
                              maxDate={new Date()}
                            />
                          </OutsideClickHandler>
                        </div>
                      )}
                    </div>

                    {this.getEnterpriseWideSettingFieldFieldError("w4Date", 9)}
                  </div>
                </Form.Group>
              </div>

              {/* <div className="separator-line"></div> */}

              <div className="enterprise-wise-payroll-table">
                <div className="switch-heading d-flex align-items-center">
                  <Form.Check
                    type="switch"
                    id="isAllowDeposit"
                    label=""
                    disabled={
                      !hasAddPermission &&
                      !hasEditPermission &&
                      !this.props?.isNewUserAdded
                    }
                    name="isAllowDeposit"
                    checked={isAllowDeposit}
                    onChange={(event: any) => {
                      this.onIsAllowDepositChange(event);
                    }}
                  />

                  <div className="heading">Deposit Accounts</div>
                </div>
                {isEnterpriseWideDataLoaded && (
                  <BootstrapTable
                    keyField="id"
                    data={
                      isAllowDeposit
                        ? sectionNamesData
                        : sectionNamesData.filter((x) => x.id !== 1)
                    }
                    columns={sectionTableColumns}
                    expandRow={expandRow}
                    rowClasses={rowClasses}
                    hover
                  />
                )}
              </div>

              {Object.keys(userGeneralInfo).length > 1 &&
                this.props?.userGeneralInfo?.hids?.filter(
                  (x) => x?.loginUserHasAccess
                )?.length > 0 && (
                  <HotelWideSettings {...hotelWideSettingProps} />
                )}

              <div className="separator-line"></div>

              <div className="enterprise-wise-deductions-settings">
                <div className="heading">Deductions</div>
                <div className="form-section d-flex flex-column">
                  <div className="enterprise-wise-deductions-table">
                    {isHotelWisePayrollLoaded && (
                      <BootstrapTable
                        keyField="rowNumber"
                        data={deductions}
                        columns={deductionsMainColumns}
                        rowClasses={rowClasses}
                        hover
                      />
                    )}
                  </div>
                  <div className="add-deduction-wrapper">
                    {(hasAddPermission || this.props?.isNewUserAdded) &&
                      this.props?.userGeneralInfo?.hids.filter(
                        (x) =>
                          x?.loginUserHasAccess &&
                          hotelWideTableData
                            ?.filter((x) => x?.status === "Active")
                            ?.map((l) => l?.lettercode)
                            .includes(x?.lettercode)
                      )?.length > 0 &&
                      this.props?.userGeneralInfo?.status === "Active" && (
                        <Button
                          className="btn-ghost"
                          onClick={() => this.openDeductionEntryPanel()}
                        >
                          Add Deduction
                        </Button>
                      )}
                  </div>
                  {showDeductionModal && isDeductionLoaded && (
                    <AddDeduction
                      // userHotels={
                      //   Object.keys(this.state.deductionRowEdit).length > 2
                      //     ? this.props?.userGeneralInfo?.hids.filter(
                      //         (x) =>
                      //           x?.lettercode ===
                      //           this.state.deductionRowEdit?.letterCode
                      //       )
                      //     : this.props?.userGeneralInfo?.hids

                      userHotels={
                        Object.keys(this.state.deductionRowEdit).length > 2
                          ? this.props?.userGeneralInfo?.hids.filter(
                              (x) =>
                                x?.loginUserHasAccess &&
                                x?.lettercode ===
                                  this.state.deductionRowEdit?.letterCode
                            )
                          : hotelWideTableData?.filter(
                              (x) => x?.status === "Active"
                            ).length > 0
                          ? this.props?.userGeneralInfo?.hids.filter(
                              (x) =>
                                x?.loginUserHasAccess &&
                                hotelWideTableData
                                  ?.filter((x) => x?.status === "Active")
                                  ?.map((l) => l?.lettercode)
                                  .includes(x?.lettercode)
                            )
                          : []
                      }
                      showDeductionModal={showDeductionModal}
                      hideDeductionModal={this.hideDeductionModal}
                      userUniqueId={this.props?.userGeneralInfo?.user_UniqueId}
                      deductionsData={deductions}
                      deductionRowEdit={this.state.deductionRowEdit}
                    />
                  )}
                </div>
              </div>

              <div className="separator-line"></div>

              <div className="enterprise-wise-garnishments-settings">
                <div className="heading">Garnishments</div>
                <div className="form-section d-flex flex-column">
                  <div className="enterprise-wise-garnishments-table">
                    {isHotelWisePayrollLoaded && (
                      <BootstrapTable
                        keyField="iF_RecordId"
                        data={garnishments}
                        columns={garnishmentsMainColumns}
                        expandRow={expandRowGarnishments}
                        rowClasses={rowClassesGarnishment}
                        hover
                      />
                    )}
                  </div>
                  <div className="add-garnishments-wrapper">
                    {(hasAddPermission || this.props?.isNewUserAdded) &&
                      this.props?.userGeneralInfo?.hids.filter(
                        (x) =>
                          x?.loginUserHasAccess &&
                          hotelWideTableData
                            ?.filter((l) => l?.status === "Active")
                            ?.map((l) => l?.lettercode)
                            .includes(x?.lettercode)
                      )?.length > 0 &&
                      this.props?.userGeneralInfo?.status === "Active" && (
                        <Button
                          className="btn-ghost"
                          onClick={() => this.openGarnishmentEntryPanel()}
                        >
                          Add Garnishment
                        </Button>
                      )}
                  </div>
                  {showGarnishmentModal && (
                    <AddGarnishment
                      userHotels={
                        hotelWideTableData?.filter(
                          (l) => l?.status === "Active"
                        )?.length > 0
                          ? this.props?.userGeneralInfo?.hids.filter(
                              (x) =>
                                x?.loginUserHasAccess &&
                                hotelWideTableData
                                  ?.filter((l) => l?.status === "Active")
                                  ?.map((l) => l?.lettercode)
                                  .includes(x?.lettercode)
                            )
                          : []
                      }
                      showGarnishmentModal={showGarnishmentModal}
                      hideGarnishmentModal={this.hideGarnishmentModal}
                      userUniqueNo={this.props?.userUniqueNo}
                      garnishmentRowEdit={this.state.garnishmentRowEdit}
                    />
                  )}
                </div>
              </div>
            </>
          </div>
        </div>
        {isFormValid &&
          !isEnterpriseWideSettingNotChanged &&
          (hasAddPermission ||
            hasEditPermission ||
            this.props?.isNewUserAdded) && (
            <div className="fixed-action ml-auto">
              <div className="d-flex align-content-center flex-wrap">
                <div className="mr-auto message">
                  <span>You have unsaved changes</span>
                </div>
                <button
                  disabled={isDataSubmitting}
                  type="button"
                  className="btn btn-primary btn-discard"
                  onClick={this.handleDiscard}
                >
                  Discard
                </button>

                <button
                  disabled={isDataSubmitting}
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.saveEnterpriseWiseSetting()}
                >
                  Save
                </button>
              </div>
            </div>
          )}
        <>
          {showDuplicateSSNModal && duplicateSSNTableData?.length > 0 && (
            <Modal
              className="duplicate-social-security-number-modal"
              show={showDuplicateSSNModal}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={() => this.hideDuplicateSSNModal()}
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  Duplicate Social Security Number Exists{" "}
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id={"tooltip-nickname"}>
                        The below user already has this SSN associated with
                        their user account.
                      </Tooltip>
                    }
                  >
                    <span
                      style={{
                        marginLeft: "4px",
                        display: "inline-block",
                      }}
                    >
                      <InfoTooltipRed />{" "}
                    </span>
                  </OverlayTrigger>{" "}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <BootstrapTable
                  keyField="rowNo"
                  data={duplicateSSNTableData || []}
                  columns={duplicateSSNTableColumns}
                  rowClasses={rowClasses}
                  hover
                />
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={() => this.hideDuplicateSSNModal()}>Ok</Button>
              </Modal.Footer>
            </Modal>
          )}
        </>
      </>
    );
  }
}
