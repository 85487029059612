import Instense from "./Axios-config";
import ReduxStore from "../../Redux/Store/IndexStore";

export class HeaderMenu {
  //     public static getMenuItem = async (

  //   ): Promise<any[] | null> => {
  //     // Need to put this and all future URLs into a config js file
  //     let storage = JSON.parse(localStorage.getItem("storage")!);
  //     // let tenantID = storage === null ? 0 : storage.tenantID as any;
  //     // let userUniqueID =storage === null ? 0 : storage.user_UniqueID as any;
  //     // 
  //     // let request = {} as any;
  //     // request.userUniqueID = userUniqueID;
  //     // request.tenantID = tenantID;

  //     const url = `/MenuMaster/GetMenuMasterlist`;
  //     return Instense.get(url, { params: request }).then((response) => {
  //       let result = response.data.result as [];
  //       return result;
  //     });
  //   };
  public static GetHeaderData = async (): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    
    let request = {} as any;
    request.userUniqueID = userUniqueID;
    request.tenantID = tenantID;
    const url = `/MenuMaster/GetMenuMasterlist`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };


  public static GetMenuList = async (): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    
    let request = {} as any;
    request.userUniqueID = userUniqueID;
    request.tenantID = tenantID;
    const url = `/MenuMaster/GetMenulist`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response?.data?.result as any;
      return result;
    });
  };



  public static GetMenuPermissionData = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let rolId = storage === null ? 0 : (storage.rolId as any);
    request.TenantID = tenantID;
    request.RoleID = rolId;
    const url = `/RolePermission/GetPageSubPermissionDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;

      // Sending Role Permissions Actions to Redux and setting in local and session for next time rerendring
      localStorage.setItem("headPermission", JSON.stringify(result));
      sessionStorage.setItem("headPermission", JSON.stringify(result));
      ReduxStore.dispatch({
        type: "UPDATE_ROLE_PERMISSION",
        payload: result,
      });

      return result;
    });
  };
  public static GetPunchDataPermission = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let rolId = storage === null ? 0 : (storage.rolId as any);
    request.TenantID = tenantID;
    request.RoleID = rolId;
    const url = `/RolePermission/GetPageSubPermissionDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static CheckPunchStatus = async (): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let userName = storage === null ? 0 : (storage.userName as any);
    
    let request = {} as any;
    request.username = userName;
    const url = `/LaborActual/CheckUserPunchStatus`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response?.data?.result as any;
      return result;
    });
  };



  public static AllCardDetails = async (): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    // let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    
    let request = {} as any;
    request.tenantID = tenantID;
    const url = `/MenuMaster/AllCardDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };


  public static CardDetails = async (): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    // let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    
    let request = {} as any;
    request.tenantID = tenantID;
    const url = `/MenuMaster/CardDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static CardDisMissDetails = async (): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    // let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    
    let request = {} as any;
    request.tenantID = tenantID;
    const url = `/MenuMaster/CardDisMissDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static DeleteDismissCard = async (id: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    // let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    
    let request = {} as any;
    request.ID = id;
    request.TenantID = tenantID;
    const url = `/MenuMaster/DeleteDismissCard`;
    return Instense.delete(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static saveDismissCard = async (request: any): Promise<any | null> => {
    const url = `/MenuMaster/SaveDismissCard`;
    return Instense.post(url, request).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };


  public static SaveCard = async (request: any, file: any, file1: any): Promise<any | null> => {
    
    ;
    const formData: FormData = new FormData();
    if (file != "" && file1 === "") {
      formData.append('file', file);
    }
    else if (file != "" && file1 != "") {
      formData.append('file', file);
      formData.append('file', file1);
    }
    else if (file === "" && file1 != "") {      
      formData.append('file', file1);
    }
    else 
    {
     
      formData.append('file', file);
    }
    //formData.append('file1', file1);
    formData.append('formField', JSON.stringify(request));

    if (file === "" && file1 === "")
    {
      const url = `/MenuMaster/SaveCard1`;
      return Instense.post(url, request).then((response) => {
        let result = response.data.result as any;
        return result;
      });
    }
    else
    {
      const url = `/MenuMaster/SaveCard`;
    return Instense.post(url, formData).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  }

    

  };


  public static CardMasterDetails = async (): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    // let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    
    let request = {} as any;
    request.tenantID = tenantID;
    const url = `/MenuMaster/CardMasterDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static DeleteCardDetails = async (id: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    // let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    
    let request = {} as any;
    request.ID = id;

    const url = `/MenuMaster/DeleteCardDetails`;
    return Instense.delete(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static ValidateHomeConfigarion = async (): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    // let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    
     let request = {} as any;
     request.tenantID = tenantID;
    const url = `/MenuMaster/ValidateHomeConfigarion`;//+tenantID;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };


  public static getModulePipCount = async (): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request:any ={};
    request.TenantID = tenantID;    
    const url = `/Notification/GetModulePipCount`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  
  public static SetAlertpip() {
    let pipItem: any = {};
    let pipData: any =[];
    pipItem.module = "Accounting";
    pipItem.count = 10;
    pipData.push(pipItem);

    pipItem = {};
    pipItem.module = "Labor";
    pipItem.count = 5;
    pipData.push(pipItem);


    localStorage.setItem("pip_data", JSON.stringify(pipData));
  };

  public static GetAlertpip(module: string,controlId:any) {
    let pipdata: any[] = JSON.parse(localStorage.getItem("pip_data")!);
    pipdata.map((item: any) => {
      if (item.module === module) {
        item.count = Number(item.count) - 1;
        //controlId.innerHTML =item.count;
      }
    })
    localStorage.setItem("pip_data", JSON.stringify(pipdata));
  };

  public static InnflowMaintenanceBanner = async (): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let userName = storage === null ? 0 : (storage.userName as any);
    let request:any ={};
    request.userName = userName;
    const url = `/Utility/InnflowMaintenanceBanner`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };
}
