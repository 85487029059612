import { API_ROOT } from "../Services/Api-config";
import { DwollaService } from "../Services/DwollaService";

export class Dwollajs {
  static IntegrateDwolla = (
    hotelId,
    doctypes = "document",
    statusCallBack = null
  ) => {
    window.dwolla.configure({
      environment: API_ROOT.dwollaConfigure,

      //styles: "https://inn-flow-test-v1-temp.azurewebsites.net/styles/Dwollaallcss.css",
      styles: `${statusCallBack ? "Dwollaallcss1.css" : "Dwollaallcss.css"}`,
      //tokenUrl: "../Administration/DwollaHandler.ashx",
      tokenUrl: API_ROOT.backendHost + "/DwollaWebhook/DwollaTokenURL",
      success: (res) => {
        res.hid = hotelId;
        if (res.response.status == "certified") {
          res.custUrl = res.response._links.self.href;
          res.doctype = doctypes;
          DwollaService.SaveDwollaCustomer(res)
            .then((data) => {
              if (statusCallBack !== null) statusCallBack(data, "certified");
            })
            .catch((err) => {
              if (statusCallBack !== null) statusCallBack(err, "error");
            });
        } else {
          if (res.response.location) {
            res.custUrl = res.response.location;
            res.doctype = doctypes;
            DwollaService.SaveDwollaCustomer(res)
              .then((data) => {
                if (statusCallBack !== null) statusCallBack(data, res.resource);
              })
              .catch((err) => {
                if (statusCallBack !== null) statusCallBack(err, "error");
              });
          }
        }
      },
      error: (err) => {
        if (statusCallBack !== null) statusCallBack(err, "error");
        alert(err);
      },
    });
  };
}
