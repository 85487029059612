import React from "react";
import {
  Form,
  Dropdown,
  Row,
  Col,
  Button,
  OverlayTrigger,
  Tooltip,
  Modal
} from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import OutsideClickHandler from "react-outside-click-handler";
import { RoleSubPermissionSetting } from "../../Common/Services/RoleSubPermissionSetting";
import _ from "lodash";
import "../../Modules/Accounting/vendor.scss";
import { FiChevronDown } from "react-icons/fi";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import { Register as registersevice } from "../../Common/Services/Register";
import { resolve, reject } from "q";
import { Utils } from "../../Common/Utilis";
import { InvoiceApprovalS } from "../../Common/Services/InvoiceApprovals";

let isValidInput: any = "";
let isShowGeneric: boolean = false;
let selectedRow: any = {};
let currentTabIndex: any = 0;
let MaxTabIndex: any = 0;
let MaxContractTabIndex: any = 0;
export class VendorSearch extends React.Component<any, any> {
  private inputSearch: any;
  constructor(props: any) {
    super(props);
    this.inputSearch = React.createRef();
    this.state = {
      hotelID: props.HID,
      loader: false,
      entryType: props.entryType,
      pageName: props.pageName,
      selectedVendor: "",
      oldselectedVendor: "",
      vendorForCreate: "",
      selectedContract: "",
      oldSelectedContract: "",
      vendorList: [],
      mainVendorList: [],
      vendorAddressList: [],
      isListOpen: false,
      isContractDetailsOpen: false,
      contractDetails: [],
      vendorforDetails: "",
      vendorType: "",
      vendorStatus: "app",
      isNewRequest: false,
      isNewVendor: false,
      isNewContract: false,
      isAddressSelect: false,
      isNewAddress: false,
      isGeneric: false,
      isFoundCurrent: false,
      isFoundOther: false,
      isAddVendorPerm: false,
      isAddVendorAddressPerm: false,
      isAddContractPerm: false,
      isCreateVendorWithApp: false,
      isGenericVendorPerm: false,
      btnName: "",
      // Inputs Control States
      newVendorName: "",
      vendorCheck: false,
      salesTaxCheck: false,
      paymentMethod: "Check",
      invoicesDuesAfter: "0",
      address: "Select Address",
      addressID: 0,
      city: "",
      states: "",
      zip: "",
      customerNo: "",
      taxIDPrefix: "TAX ID",
      taxIdSuffix: "",
      phone: "",
      email: "",
      lastVid: 0,
      // End Here
      lastVType: "",
      isAddVendorBtnVisible: false,
      isMatchingMsgVisible: false,
      addVendorFooterVisible: false,
      callFromPage: props.callFromPage,
      selectedContractRowID: 0,
      isGenericSelect: false,
      cursor: -1,
      cursorDetails: 1,
      tabEnableVendor: 1,
      postingRule: "",
      editedVid: 0,
      AddNewPostingRule: false,
      rules: "",
      newRuleError: false,
      LastUpdatedBy: "",
      LastUpdatedTime: "",
      selectedVID: 0,
      isEditInvoiceRules: false,
      disabled: props?.disabled,
      zipCodeError: false,
      isCustIDExist: false
    };
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  getVendorPermission = async () => {
    let requestObject = {} as any;
    requestObject.permissionID = 3; //For Invoice
    requestObject.Module = "Accounting";
    requestObject.Type = "page";

    let requestObject1 = {} as any;
    requestObject1.permissionID = 4; //For Other Pages
    requestObject1.Module = "Accounting";
    requestObject1.Type = "page";

    try {
      const response = await Promise.all([
        RoleSubPermissionSetting.GetPageSubPermissionDetails(requestObject),
        RoleSubPermissionSetting.GetPageSubPermissionDetails(requestObject1),
      ]);
      if (response !== null) {
        let finalResponse = response[0].concat(response[1]); //Merge Both response in one array
        if (finalResponse.length > 0) {
          for (let i = 0; i < finalResponse.length; i++) {
            if (
              (finalResponse[i].permissionName.toString().toLocaleLowerCase() === "Create Vendor".toLocaleLowerCase() ||
                finalResponse[i].permissionName.toString().toLocaleLowerCase() === "add invoice".toLocaleLowerCase()) &&
              this.state.pageName === "InvoiceEntry" && (finalResponse[i].permissionID === 2011 || finalResponse[i].permissionID === 2010)
            ) {
              this.setState({ isAddVendorPerm: true }); //This permission is for Create Vendor
            }
            if (
              finalResponse[i].permissionName.toString().toLocaleLowerCase() === "create vendor" && this.state.pageName !== "InvoiceEntry"
              && finalResponse[i].permissionID === 2027) {
              this.setState({ isAddVendorPerm: true }); //This permission is for Add Address
            }
            if (
              finalResponse[i].permissionName.toString().toLocaleLowerCase() === "edit vendor information") {
              this.setState({ isAddVendorAddressPerm: true }); //This permission is for Add Address
            }
            this.setState({ isAddContractPerm: true });
            // if (finalResponse[i].displayName === "Add Contract") {
            //   this.setState({ isAddContractPerm: true }); //This permission is for Add Contract
            // }
            // if (finalResponse[i].permissionName.toString().toLocaleLowerCase() === "create new vendor approval") {
            //   this.setState({ isCreateVendorWithApp: true }); //This permission is for Add Contract
            // }
            if (finalResponse[i].permissionName.toString().toLocaleLowerCase() === "access to generic vendor") {
              this.setState({ isGenericVendorPerm: true }); //This permission is for Add Contract
            }
            if (finalResponse[i].permissionName.toString().toLocaleLowerCase() === "edit invoice rules") {
              this.setState({ isEditInvoiceRules: true });
            }
          }
        }
      }
    } catch (error) {
      Utils.toastError((error as any)?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  componentDidMount() {
    this.getVendorPermission();
    this.CheckEpayEnabled();
    //document.addEventListener("keydown", this.escFunction, false); //Close dropdown on pressing esc and tab
    document.addEventListener("onKeyPress", this.taxIDCheck, false);
  }

  CheckEpayEnabled = () => {
    InvoiceApprovalS.CheckEpayEnabled()
      .then(async (result: any) => {
        if (result?.message === "Success") {
          const isCustIDExist = result?.result?.isCustIDExist;
          const isePayEnabled = result?.result.isePayEnabled;

          this.setState({ isCustIDExist: isCustIDExist && isePayEnabled ? true : false });
        }
        resolve();
      })
      .catch((error) => {
        Utils.toastError(error?.message, { position: toast.POSITION.BOTTOM_RIGHT });
        reject();
      });
  };

  componentWillUnmount() {
    //document.removeEventListener("keydown", this.escFunction, false);
    document.addEventListener("onKeyPress", this.taxIDCheck, false);

  }

  escFunction = (event) => {
    if (event.keyCode === 27) {
      //esc key press
      this.setState({
        isListOpen: false,
        addVendorFooterVisible: false,
        isNewVendor: false,
        isNewContract: false,
        isNewRequest: false,
      });
    }
  };

  importFromCCSI = (vendorname, vid, type) => {
    this.addNewContract(vendorname, vid, type, this.state.hotelID);
  };
  importFromJournal = (vendorname, vid, type) => {
    this.setState({ hotelID: this.props.HID });
    this.importFromCCSI(vendorname, vid, type);
  };

  GetVendorTranlist = (requestlist: any) => {
    this.setState({ loader: true });
    registersevice
      .GetVendorlist(requestlist) //Vendor list service method
      .then(async (result: any | null) => {
        if (result !== null) {
          result.sort((a, b) => a.vendorType.toString().toLowerCase() !== b.vendorType.toString().toLowerCase() ? a.vendorType.toString().toLowerCase() < b.vendorType.toString().toLowerCase() ? -1 : 1 : 0);//_.sortBy(result, "status");

          result.map(
            (item, index) => (
              (item["id"] = index),
              (item["postingRule"] = item.postingRule.replaceAll('<br/>', ''))
            )
          );
          this.updateParentState(result);
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  };

  handleSelectFromParent = (item: any) => {
    this.setState({
      selectedVendor: item[0].vendorName,
      vendorForCreate: item[0].vendorName?.trim(),
      oldSelectedContract: item[0].accountNo,
      selectedContract: item[0].accountNo === "" || item[0].accountNo === undefined || item[0].vendorName?.toLowerCase() === "generic vendor"
        ? ""
        : " (" + item[0]?.accountNo + ")",
      editedVid: item[0].id,
      selectedVID: item[0].id
    }, () => {
      this.getPostingRuleDetails(this.state.vendorList);
    });

  };

  updateParentState = (result: any) => {
    this.setState(
      {
        vendorList: [],
        mainVendorList: [],
      },
      () => {
        this.setState(
          {
            vendorList: result,
            mainVendorList: result,
           // loader: false,
          },
          () => {

            setTimeout(() => {
              this.setState({ loader: false});
          }, 1000);

           
            this.props.updateVendorList(result);
            this.getPostingRuleDetails(result);
          }
        );
      }
    );
  };

  getPostingRuleDetails = (result: any) => {
    let postingRule = result.filter((itemList) => itemList.vid === (this.state.editedVid === 0 || this.state.editedVid === undefined ? this.state.selectedVID : this.state.editedVid) /*&& itemList.vendorType === "Current"*/);
    this.setState({ postingRule: postingRule[0]?.postingRule, LastUpdatedBy: postingRule[0]?.postingRuleBy, LastUpdatedTime: postingRule[0]?.postingRuleDate })
  }

  handleClick = () => {
    selectedRow = {};
    MaxTabIndex = 0;
    if (this.state.selectedContract !== "" || this.state.isGenericSelect) {
      $("#inputSearch").select();
    }
    if (this.state.selectedVendor?.trim().length > 2) {
      this.setState(
        {
          cursor: -1,
          cursorDetails: 1,
          isListOpen: true,
          addVendorFooterVisible: true,
          isNewVendor: false,
          isNewRequest: false,
          isNewContract: false,
          isContractDetailsOpen: false,
        },
        () => {
          this.filterFromVendorList(this.state.selectedVendor?.trim(), "click"); //Calling this fun for filtering the Vendor List
        }
      );
    }
  };

  filterFromVendorList = (name: any, callFrom) => {
    selectedRow = {};
    MaxTabIndex = 0;
    this.setState({
      selectedVendor: name.toString(),
      vendorForCreate: name.toString()?.trim(),
      addVendorFooterVisible: true,
      isGenericSelect: name.toString().toLowerCase() === "generic vendor" ? true : false,
      cursor: -1,
      cursorDetails: 1,
    });
    let newResult: any = [];
    if (name?.trim().length > 2) {
      let vendorDataFilter: any[] = [];
      vendorDataFilter = _.cloneDeep(this.state.mainVendorList);

      //Check If Generic vendor is ceated or not for this tenant
      isShowGeneric = _.some(this.state.mainVendorList, function (el) {
        return el.vendorName.toLowerCase() === "generic vendor";
      });

      //This will remove generic vendor from Vendor List
      vendorDataFilter = _.reject(vendorDataFilter, (o) =>
        _.includes(o.vendorName.toString().toLowerCase(), "generic vendor")
      );

      //_.reject(vendorDataFilter, {vendorName:"Generic Vendor"});

      newResult = _.filter(vendorDataFilter, (o) =>
        _.includes(
          o.vendorName.toString().toLowerCase(),
          name?.trim().toString().toLowerCase()
        )
      );

      newResult.sort((a, b) => a.vendorType.toString().toLowerCase() !== b.vendorType.toString().toLowerCase() ? a.vendorType.toString().toLowerCase() < b.vendorType.toString().toLowerCase() ? -1 : 1 : 0);//_.sortBy(result, "status");

      newResult.map(
        (item, index) => (
          (item["id"] = index)
        )
      );

      //Check if any current EHID data present in result set
      const isCurrentFound = newResult.some(
        (item) => item.vendorType.toString().toLowerCase() === "current"
      );

      //Check if any Other EHID data present in result set
      const isOtherFound = newResult.some(
        (item) => item.vendorType.toString().toLowerCase() === "other"
      );

      if (callFrom === "dropdown") {
        const arrayResult: any = [];
        this.props.handleVendorChange(arrayResult);
      }
      MaxTabIndex = newResult.length === 0 ? 1 : 0;
      this.setState({
        vendorList: newResult,
        isFoundCurrent: isCurrentFound,
        isFoundOther: isOtherFound,
      });
      if (newResult.length > 0) {
        this.setState({
          isListOpen: true,
          isMatchingMsgVisible: false,
          isAddVendorBtnVisible: false,
        });
      } else {
        this.setState({
          isListOpen: false,
          isMatchingMsgVisible: true,
          isAddVendorBtnVisible: true,
        });
      }
    } else {
      this.setState({
        isListOpen: false,
        isMatchingMsgVisible: false,
        isAddVendorBtnVisible: false,
      });
    }
    if (name?.trim().length === 0) {
      if (callFrom === "dropdown") {
        const arrayResult: any = [];
        this.props.handleVendorChange(arrayResult);
      }
      this.setState({
        isListOpen: false,
        isMatchingMsgVisible: false,
      });
    }
    // if (name?.trim().length > 0 && newResult.length <= 0) {
    //   this.setState({ isAddVendorBtnVisible: true });
    // } else {
    //   this.setState({ isAddVendorBtnVisible: false });
    // }
  };

  //Handle change when entering the value in vendor search box
  handleChange = (event, eventFrom) => {
    this.setState({ selectedContract: "", oldSelectedContract: "" });
    if (!this.state.isNewRequest)
      this.filterFromVendorList(event.target.value, eventFrom); //Calling this fun to filter Vendor List
  };

  //Close vendor control on outside click
  handleOutsideClick = (e) => {
    if (!this.state.loader && !this.state.isNewRequest)
      this.clearFieldValuesFromOutsideClick();
    this.setState({ isListOpen: false, addVendorFooterVisible: false });
  };

  clearFieldValuesFromOutsideClick = () => {
    this.setState({
      vendorCheck: false,
      invoicesDuesAfter: "0",
      salesTaxCheck: false,
      address: "Select Address",
      addressID: 0,
      city: "",
      states: "",
      zip: "",
      customerNo: "",
      taxIDPrefix: "TAX ID",
      taxIdSuffix: "",
      phone: "",
      email: "",
      isContractDetailsOpen: false,
      isListOpen: true,
      addVendorFooterVisible: true,
      isNewVendor: false,
      isNewContract: false,
      isNewRequest: false,
      vendorForCreate: this.state.selectedVendor,
    });
  };

  //Fun to open a form for adding a New Vendor
  addNewVendor = () => {
    const vendorName = this.state.selectedVendor
      .toString()
      .toLowerCase()
      ?.trim();
    if (vendorName === "generic vendor") {
      Utils.toastError("You can't create a generic vendor.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }

    const found = _.some(this.state.mainVendorList, function (el) {
      return el.vendorName.toLowerCase() === vendorName;
    }); //found: true, IF the Vendor name already exist in DB

    if (!found) {
      this.clearFieldValues();
      this.setState({
        btnName: "Add Vendor",
        isNewRequest: true,
        isNewVendor: true,
        isContractDetailsOpen: false,
        isListOpen: false,
        newVendorName: this.state.selectedVendor,
        vendorForCreate:
          this.state.selectedVendor?.trim().length > 50
            ? this.state.selectedVendor.substring(0, 50)?.trim()
            : this.state.selectedVendor?.trim(),
        address: "",
        isNewContract: false,
        isAddressSelect: true,
        isNewAddress: true,
        addVendorFooterVisible: false,
        salesTaxCheck: true,
      }, () => {
        $('#vendorname').focus();
      });
    } else {
      Utils.toastError("Vendor already exists.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
  };

  //Fun to open a form for creating a New Contract for existing Vendor

  addNewContract = (vendorName, vid, vendorType, hotelID) => {
    let vendorAddressReq: any = {};
    vendorAddressReq.hotelID = hotelID;// this.state.hotelID;
    vendorAddressReq.vendorType = vendorType;
    vendorAddressReq.VendorId = vid;
    this.clearFieldValues();
    this.setState(
      {
        hotelID: hotelID,
        btnName: "Add Contract",
        loader: true,
        isNewContract: true,
        isNewVendor: false,
        isListOpen: false,
        addVendorFooterVisible: false,
        isNewRequest: true,
        isAddressSelect: false,
        address: "Select Address",
        isContractDetailsOpen: false,
        newVendorName: vendorName,
        vendorForCreate: vendorName?.trim(),
        isNewAddress: false,
        lastVid: vid,
        lastVType: vendorType,
        vendorType: vendorType,
        vendorAddressList: [],
        paymentMethod: "Check",
        vendorCheck: false,
        salesTaxCheck: false,
        invoicesDuesAfter: "0",
      },
      () => {
        registersevice //Service method for getting the address and some basic details of the selected vendor
          .GetVendorAddress(vendorAddressReq)
          .then(async (result: any | null) => {
            if (result !== null) {
              this.setState({
                loader: false,
                vendorAddressList: result,
                paymentMethod: result[0].paymentType.toString().toLowerCase() === "ACH" ? "Manual ACH" : result[0].paymentType,
                vendorCheck:
                  result[0].isVendor1099?.toLowerCase() === "yes"
                    ? true
                    : false,
                salesTaxCheck:
                  result[0].salesTax?.toLowerCase() === "yes" ? true : false,
                invoicesDuesAfter:
                  result[0].invoicesDuesAfter === ""
                    ? "0"
                    : result[0].invoicesDuesAfter,
                taxIDPrefix: result[0].isTaxOrSSN,
                taxIdSuffix: result[0].taxOrSsnId,
              });
            } else {
              this.setState({ loader: false });
            }
            resolve();
          })
          .catch((error) => {
            this.setState({ loader: false });
            reject();
          });
        $("#streetaddressDrop").focus();
      }
    );
  };

  contractDetails = async (vendorName, vid, vendorType, approval) => {
    this.setState({ loader: true });
    let vendorContractRequest: any = {};
    vendorContractRequest.hotelID = this.state.hotelID;
    vendorContractRequest.vendorType = vendorType;
    vendorContractRequest.VendorId = vid;
    let contractDetail: any[] = [];

    registersevice //Service method for getting the contract details of the selected vendor
      .GetVendorContract(vendorContractRequest)
      .then(async (result: any | null) => {
        if (result !== null) {
          for (let i = 0; i < result.length; i++) {
            let dta: any = {};
            dta.vendorName = vendorName;
            dta.rowID = result[i].rowID;
            dta.vid = vid;
            dta.accountNo = result[i].accountNo;
            dta.street = result[i].street;
            dta.city = result[i].city;
            dta.state = result[i].state;
            dta.pCode = result[i].pCode;
            dta.aid = result[i].aid;
            dta.salestax = result[i].salesTax;
            dta.paymentType = result[i].paymentType;
            dta.vendorDays = result[i].vendorDays;
            //dta.ctCount= "2";
            contractDetail.push(dta);
          }
          const selectedCustID = result.filter(
            (nis) =>
              nis.accountNo?.toString().toLowerCase() === this.state.selectedContract.replace('(', '').replace(')', '').trim()
          );
          MaxContractTabIndex = result.length;
          contractDetail.map(
            (item, index) => (
              (item["id"] = index)
            )
          );
          this.setState({
            selectedContractRowID: this.state.selectedContract === "" ? 0 : selectedCustID[0]?.rowID,
            vendorType: vendorType,
            contractDetails: contractDetail,
            isContractDetailsOpen: true,
            isListOpen: false,
            addVendorFooterVisible: false,
            vendorforDetails: vendorName,
            lastVid: vid,
            lastVType: vendorType,
            vendorStatus: approval,
            loader: false,
          }, () => {
            setTimeout(() => {
              $('[tabindex=' + 1 + ']').focus();
            }, 0);
          });
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ loader: false });
        reject();
      });
  };

  clearFieldValues = () => {
    this.setState({
      vendorCheck: false,
      invoicesDuesAfter: "0",
      salesTaxCheck: false,
      address: "Select Address",
      addressID: 0,
      city: "",
      states: "",
      zip: "",
      customerNo: "",
      taxIDPrefix: "TAX ID",
      taxIdSuffix: "",
      phone: "",
      email: "",
      isContractDetailsOpen: false,
      isListOpen: true,
      addVendorFooterVisible: true,
      isNewVendor: false,
      isNewContract: false,
      isNewRequest: false,
      vendorForCreate: this.state.selectedVendor,
      cursor: -1,
      cursorDetails: 1
    }, () => {
      $('#SlideBody').scrollTop(0);
      this.moveCursorToEnd();
      selectedRow = {};
    });
  };

  moveCursorToEnd = () => {
    let el: any = document.getElementById('inputSearch');
    if (el !== null && el !== undefined) {
      el.focus();
      setTimeout(() => {
        if (typeof el.selectionStart == "number") {
          el.selectionStart = el.selectionEnd = el.value.length;
        } else if (typeof el.createTextRange != "undefined") {
          var range = el.createTextRange();
          range.collapse(false);
          range.select();
        }

      }, 0);
    }
  }

  handleSelectVendor = (item: any) => {
    this.setState(
      {
        selectedVendor: item.vendorName,
        vendorForCreate: item.vendorName?.trim(),
        selectedContract:
          item.vendorName?.toLowerCase() === "generic vendor"
            ? ""
            : " (" + item.accountNo + ")",
        selectedContractRowID: item.rowID,
        oldSelectedContract: item.accountNo,
        isListOpen: false,
        addVendorFooterVisible: false,
        isContractDetailsOpen: false,
        isAddVendorBtnVisible: false,
        isMatchingMsgVisible: false,
        selectedVID: item.vid
      },
      () => {
        const arrayResult: any = [];
        arrayResult.push(item);
        this.props.handleVendorChange(arrayResult); //Calling the parent change method
      }
    );
  };

  handleNotApprove = () => {
    // Nothing to do in this fun
  };

  handleAddNewAddress = (event: any) => {
    this.setState({
      isAddressSelect: true,
      isNewAddress: true,
      address: "",
      phone: "",
      email: "",
      addressID: 0,
      customerNo: "",
      //taxIDPrefix: "TAX ID",
      //taxIdSuffix: "",
    }, () => {
      $("#streetaddressText").focus();
      $("#customerno").removeClass("alert-danger");
    });
  };

  //Old Method
  // onClickVendor = (items: any) => {
  //   this.setState({ postingRule: items.postingRule, selectedVID: items.vid,LastUpdatedBy: items.postingRuleBy,LastUpdatedTime:items.postingRuleDate, });
  //   if (this.state.pageName === "TransactionPage" || this.state.pageName === "CreditCardEntry") {
  //     if (items.ctCount > 1) {
  //       this.contractDetails(
  //         items.vendorName,
  //         items.vid,
  //         items.vendorType,
  //         items.approval
  //       );
  //     } else if (items.vendorType?.toString().toLowerCase() === "other") {
  //       this.addNewContract(
  //         items.vendorName,
  //         items.vid,
  //         items.vendorType,
  //         this.state.hotelID
  //       );
  //     } else {
  //       this.handleSelectVendor(items);
  //     }
  //   } else if (this.state.pageName === "InvoiceEntry") {
  //     if (items.ctCount > 1) {
  //       this.contractDetails(
  //         items.vendorName,
  //         items.vid,
  //         items.vendorType,
  //         items.approval
  //       );
  //     } else if (items.vendorType?.toString().toLowerCase() === "other") {
  //       if (items.approval?.toString().toLowerCase() === "app" && this.state.isAddContractPerm) {
  //         this.addNewContract(
  //           items.vendorName,
  //           items.vid,
  //           items.vendorType,
  //           this.state.hotelID
  //         );
  //       } else {
  //         this.handleNotApprove();
  //       }
  //     } else {
  //       if (items.approval?.toString().toLowerCase() === "app") {
  //         this.handleSelectVendor(items);
  //       }
  //     }
  //   }
  // }
  onClickVendor = (items: any) => {
    this.setState({ postingRule: items.postingRule, selectedVID: items.vid, LastUpdatedBy: items.postingRuleBy, LastUpdatedTime: items.postingRuleDate, });
    if (this.state.pageName === "InvoiceEntry") {
      if (items.ctCount > 1) {
        this.contractDetails(
          items.vendorName,
          items.vid,
          items.vendorType,
          items.approval
        );
      } else if (items.vendorType?.toString().toLowerCase() === "other") {
        this.addNewContract(
          items.vendorName,
          items.vid,
          items.vendorType,
          this.state.hotelID
        );
      } else {
        this.handleSelectVendor(items);
      }
    }
    //For Rest pages
    else {
      if (items.ctCount > 1 && items.approval?.toString().toLowerCase() === "app") {
        this.contractDetails(
          items.vendorName,
          items.vid,
          items.vendorType,
          items.approval
        );
      } else if (items.vendorType?.toString().toLowerCase() === "other" && items.approval?.toString().toLowerCase() === "app") {
        this.addNewContract(
          items.vendorName,
          items.vid,
          items.vendorType,
          this.state.hotelID
        );
      } else if (items.vendorType?.toString().toLowerCase() === "current" && items.approval?.toString().toLowerCase() === "app") {
        this.handleSelectVendor(items);
      }
    }
  }

  onClickContractDetailsList = (item: any) => {
    this.state.pageName === "TransactionPage" &&
      this.state.vendorStatus?.toLowerCase() ===
      "no"
      ? this.handleNotApprove()
      : this.state.vendorType?.toLowerCase() ===
        "other"
        ?
        this.state.isAddContractPerm ?
          this.addNewContract(
            item.vendorName,
            item.vid,
            this.state.vendorType,
            this.state.hotelID
          )
          : this.handleNotApprove()
        : this.handleSelectVendor(item);
  }

  handleKeyDownArrow = (e, items, action) => {
    const { cursor, vendorList } = this.state;
    if (e.keyCode === 38 && cursor > 0 && this.state.isListOpen && action === "vendorRow") {
      this.setState(prevState => ({
        cursor: prevState.cursor - 1
      }), () => {
        let selRow = vendorList.filter((item) => item.id === cursor - 1);
        selectedRow = {};
        selectedRow = (selRow[0]);
        setTimeout(() => {
          var tabindex: any = $('.vendorListFocusSet').attr('tabindex');
          console.log("tabindex", tabindex)
          if (Number(currentTabIndex) === 1) {
            $('.vendorListFocusSet').focus();
          } else {
            currentTabIndex = (Number(currentTabIndex) - 1)
            $('[tabindex=' + currentTabIndex + ']').focus();
          }

        }, 0);
      })

    } if (e.keyCode === 38 && cursor === 0 && this.state.isListOpen && action === "vendorRow") {
      this.setState(prevState => ({
        cursor: prevState.cursor - 1
      }), () => {
        selectedRow = {};
        MaxTabIndex = 0;
        setTimeout(() => {
          this.handleClick();
          $('#SlideBody').scrollTop(0);
          $('#inputSearch').focus();
        }, 0);
      })

    } else if (e.keyCode === 40 && cursor < vendorList.length - 1 && this.state.isListOpen && action === "vendorRow") {
      this.setState(prevState => ({
        cursor: prevState.cursor + 1
      }), () => {
        let selRow = vendorList.filter((item) => item.id === cursor + 1);
        selectedRow = {};
        selectedRow = (selRow[0]);
        setTimeout(() => {
          var tabindex: any = $('.vendorListFocusSet').attr('tabindex');
          currentTabIndex = tabindex;
          if (Number(MaxTabIndex) === this.state.cursor) {
            $('.vendorListFocusSet').focus();
          } else {
            $('[tabindex=' + tabindex + ']').focus();
          }
        }, 0);
      });

    } else if (e.keyCode === 40 && cursor === vendorList.length - 1 && this.state.isListOpen && action === "vendorRow") {
      this.setState(prevState => ({
        cursor: prevState.cursor + 1
      }), () => {
        selectedRow = {};
        setTimeout(() => {
          var tabindex: any = $('.vendorListFocusSet').attr('tabindex');
          currentTabIndex = tabindex;
          $('[tabindex=' + tabindex + ']').focus();
        }, 0);
      });

    } else if (e.keyCode === 40 && cursor === vendorList.length && this.state.isListOpen && action === "GenericSelect") {
      this.setState(prevState => ({
        cursor: prevState.cursor + 1
      }), () => {
        selectedRow = {};
        setTimeout(() => {
          var tabindex: any = $('.vendorListFocusSet').attr('tabindex');

          currentTabIndex = MaxTabIndex;
          if (Number(MaxTabIndex) === this.state.cursor) {
            $('.vendorListFocusSet').focus();
          } else {
            $('[tabindex=' + tabindex + ']').focus();
          }
        }, 0);
      });

    } else if (e.keyCode === 40 && this.state.isMatchingMsgVisible && action === "GenericSelect") {
      this.setState(prevState => ({
        cursor: prevState.cursor + 1
      }), () => {
        selectedRow = {};
        setTimeout(() => {
          var tabindex: any = $('.vendorListFocusSet').attr('tabindex');

          currentTabIndex = MaxTabIndex;
          if (Number(MaxTabIndex) === this.state.cursor) {
            $('.vendorListFocusSet').focus();
          } else {
            $('[tabindex=' + tabindex + ']').focus();
          }
        }, 0);
      });

    } else if (e.keyCode === 38 && cursor === vendorList.length && this.state.isListOpen && action === "GenericSelect") {
      this.setState(prevState => ({
        cursor: prevState.cursor - 1
      }), () => {
        let selRow = vendorList.filter((item) => item.id === cursor - 1);
        selectedRow = {};
        selectedRow = (selRow[0]);
        setTimeout(() => {
          var tabindex: any = $('.vendorListFocusSet').attr('tabindex');
          if (Number(currentTabIndex) === 1) {
            $('.vendorListFocusSet').focus();
          } else {
            currentTabIndex = (Number(currentTabIndex) - 1)
            $('[tabindex=' + currentTabIndex + ']').focus();
          }

        }, 0);
      })

    } else if (e.keyCode === 38 && (this.state.isListOpen || this.state.isMatchingMsgVisible) && action === "AddVendorBtn") {
      this.setState(prevState => ({
        cursor: prevState.cursor - 1
      }), () => {
        let selRow = vendorList.filter((item) => item.id === cursor - 1);
        selectedRow = {};
        selectedRow = (selRow[0]);
        setTimeout(() => {
          currentTabIndex = (Number(MaxTabIndex))
          $('[tabindex=' + currentTabIndex + ']').focus();
        }, 0);
      })

    } else if (e.keyCode === 38 && !this.state.isListOpen && this.state.isMatchingMsgVisible && action === "GenericSelect") {

      selectedRow = {};
      MaxTabIndex = 0;
      setTimeout(() => {
        this.handleClick();
        //this.setState({cursor:-1});
        $('#inputSearch').focus();
      }, 0);


    } else if (e.keyCode === 39 && cursor > -1 && this.state.isListOpen && action === "vendorRow") { // for right arrow
      if (this.state.pageName === "InvoiceEntry") {
        if (selectedRow?.vendorType.toLowerCase() === "current") {
          if (selectedRow?.ctCount > 1)
            this.onClickVendor(selectedRow)
          else
            this.addNewContract(
              selectedRow?.vendorName,
              selectedRow?.vid,
              selectedRow?.vendorType,
              this.state.hotelID
            );
        }
      } else {
        if (selectedRow?.vendorType.toLowerCase() === "current" && selectedRow.approval?.toString().toLowerCase() !== "no") {
          if (selectedRow?.ctCount > 1)
            this.onClickVendor(selectedRow)
          else
            this.addNewContract(
              selectedRow?.vendorName,
              selectedRow?.vid,
              selectedRow?.vendorType,
              this.state.hotelID
            );
        }
        else if (selectedRow.approval?.toString().toLowerCase() !== "no") {
          this.addNewContract(
            selectedRow?.vendorName,
            selectedRow?.vid,
            selectedRow?.vendorType,
            this.state.hotelID
          );
        }
      }
      //Old Code// if (selectedRow?.vendorType.toLowerCase() === "current" && selectedRow.approval?.toString().toLowerCase() !== "no") {
      //   if (selectedRow?.ctCount > 1)
      //     this.onClickVendor(selectedRow)
      //   else
      //     this.addNewContract(
      //       selectedRow?.vendorName,
      //       selectedRow?.vid,
      //       selectedRow?.vendorType,
      //       this.state.hotelID
      //     );
      // }
      // else if (selectedRow.approval?.toString().toLowerCase() !== "no") {
      //   this.addNewContract(
      //     selectedRow?.vendorName,
      //     selectedRow?.vid,
      //     selectedRow?.vendorType,
      //     this.state.hotelID
      //   );
      // }
    } else if (e.keyCode === 37 && cursor < vendorList.length - 1 && (this.state.isContractDetailsOpen || this.state.isNewRequest) && action === "vendorRow") {// for left arrow
      this.clearFieldValues();
    } else if (e.keyCode === 13 && this.state.isListOpen && action === "vendorRow") {// for Enter Key
      //if (selectedRow.approval?.toString().toLowerCase() !== "no")
      this.onClickVendor(selectedRow);
    } else if (e.keyCode === 13 && this.state.isListOpen && action === "GenericSelect") {// for Enter Key
      this.genericSelectClick();
    } else if (e.keyCode === 9) {// For disable tab
      e.preventDefault()
    } else if (e.keyCode === 13 && !this.state.isListOpen && action === "GenericSelect") {// for Enter Key
      this.genericSelectClick();
    } else if (e.keyCode === 13 && (this.state.isListOpen || this.state.isMatchingMsgVisible) && action === "AddVendorBtn") {// for Enter Key
      this.addNewVendor();
    } else if (e.keyCode === 13 && cursor < vendorList.length - 1 && this.state.isListOpen && action === "GenericSelect") {
      this.genericSelectClick();
    }
  }

  handleKeyDown = (e) => {
    const { cursor, vendorList } = this.state;
    if (e.keyCode === 40 && vendorList.length === 0 && this.state.isMatchingMsgVisible) {
      this.setState(prevState => ({
        cursor: prevState.cursor + 2
      }), () => {
        let selRow = vendorList.filter((item) => item.id === cursor + 1);
        selectedRow = {};
        selectedRow = (selRow[0]);
        setTimeout(() => {
          var tabindex: any = $('.GenericVendor').attr('tabindex');
          if (tabindex === undefined) {
            $('[tabindex=' + MaxTabIndex + ']').focus();
          } else {
            currentTabIndex = tabindex;
            $('[tabindex=' + tabindex + ']').focus();
          }
        }, 0);
      })

    } if (e.keyCode === 40 && cursor < vendorList.length - 1 && this.state.isListOpen) {
      this.setState(prevState => ({
        cursor: prevState.cursor + 1
      }), () => {
        let selRow = vendorList.filter((item) => item.id === cursor + 1);
        selectedRow = {};
        selectedRow = (selRow[0]);
        setTimeout(() => {
          $('.vendorListFocusSet').focus();
        }, 0);
      });
    }
    else if (e.keyCode === 9) {// for Disable Tab Key
      e.preventDefault();
    }
  }

  resetVendorSearchControl = () => {
    this.setState(() => ({ selectedVendor: "", selectedContract: "" }))
  }

  handleContractDetailBox = (e, action, item) => {
    const { cursorDetails, contractDetailList } = this.state;
    if (e.keyCode === 37 && this.state.isContractDetailsOpen) { // for left arrow click
      this.clearFieldValues();
    } if (e.keyCode === 13 && this.state.isContractDetailsOpen && action === "back") {// for Enter Key
      this.clearFieldValues();
    } if (e.keyCode === 38 && this.state.isContractDetailsOpen && action === "back") {
      this.setState(prevState => ({
        cursorDetails: prevState.cursorDetails
      }), () => {
        selectedRow = {};
        setTimeout(() => {
          var tabindex: any = $('.contractListFocusSet').attr('tabindex');
          $('[tabindex=' + 1 + ']').focus();
        }, 0);
      });
    } if (e.keyCode === 40 && this.state.isContractDetailsOpen && action === "back") {
      this.setState(prevState => ({
        cursorDetails: prevState.cursorDetails + 1
      }), () => {
        selectedRow = {};
        setTimeout(() => {
          var tabindex: any = $('.contractListFocusSet').attr('tabindex');
          $('[tabindex=' + tabindex + ']').focus();
        }, 0);
      });
    } if (e.keyCode === 40 && this.state.isContractDetailsOpen && action === "contractList") {
      this.setState(prevState => ({
        cursorDetails: prevState.cursorDetails + 1
      }), () => {
        selectedRow = {};
        selectedRow = item;
        setTimeout(() => {
          var tabindex: any = $('.contractListFocusSet').attr('tabindex');
          $('[tabindex=' + tabindex + ']').focus();
        }, 0);
      });
    } if (e.keyCode === 38 && this.state.isContractDetailsOpen && action === "contractList") {
      this.setState(prevState => ({
        cursorDetails: prevState.cursorDetails - 1
      }), () => {
        selectedRow = {};
        selectedRow = item;
        setTimeout(() => {
          var tabindex: any = $('.contractListFocusSet').attr('tabindex');
          if (tabindex === undefined) {
            $('[tabindex=' + 1 + ']').focus();
          } else {
            $('[tabindex=' + tabindex + ']').focus();
          }

        }, 0);
      });
    } if (e.keyCode === 38 && this.state.isContractDetailsOpen && action === "AddContract") {
      this.setState(prevState => ({
        cursorDetails: prevState.cursorDetails - 1
      }), () => {
        selectedRow = {};
        setTimeout(() => {
          var tabindex: any = $('.contractListFocusSet').attr('tabindex');
          if (tabindex === undefined) {
            $('[tabindex=' + 1 + ']').focus();
          } else {
            $('[tabindex=' + tabindex + ']').focus();
          }

        }, 0);
      });
    } if (e.keyCode === 13 && this.state.isContractDetailsOpen && action === "contractList") {// for Enter Key
      this.onClickContractDetailsList(item);
    } if (e.keyCode === 13 && this.state.isContractDetailsOpen && action === "AddContract") {// for Enter Key
      this.addNewContract(
        this.state.vendorforDetails,
        this.state.lastVid,
        this.state.vendorType,
        this.state.hotelID
      );
    } if (e.keyCode === 9) {// for Disable Tab Key
      e.preventDefault();
    }
  }

  handleKeyTabForm = (e, action) => {
    if (e.keyCode === 27 && this.state.isNewRequest) { // for Left Arrow
      this.clearFieldValues();
    }
    if (e.keyCode === 9 && this.state.isNewRequest) { // for Tab pressing
      setTimeout(() => {
        if (action === "vendorname")
          $('[tabindex=' + 2 + ']').focus();
        if (action === "vendorCheck")
          $('[tabindex=' + 3 + ']').focus();
        if (action === "paymentMethod")
          $('[tabindex=' + 4 + ']').focus();
        if (action === "invoices")
          $('[tabindex=' + 5 + ']').focus();
        if (action === "salesTaxCheck")
          $('[tabindex=' + 6 + ']').focus();
        if (action === "taxIDPrefix")
          $('[tabindex=' + 7 + ']').focus();
        if (action === "taxIdSuffix")
          $('[tabindex=' + 8 + ']').focus();
        if (action === "addressNew")
          $('[tabindex=' + 9 + ']').focus();
        if (action === "city")
          $('[tabindex=' + 10 + ']').focus();
        if (action === "states")
          $('[tabindex=' + 11 + ']').focus();
        if (action === "zip")
          $('[tabindex=' + 12 + ']').focus();
        if (action === "customerno")
          $('[tabindex=' + 13 + ']').focus();
        if (action === "phone")
          $('[tabindex=' + 14 + ']').focus();
        if (action === "email")
          $('[tabindex=' + 15 + ']').focus();
        if (action === "cancel")
          $('[tabindex=' + 16 + ']').focus();
        if (action === "saveBtn")
          if (this.state.isNewContract)
            if (this.state.isNewAddress)
              $('[tabindex=' + 8 + ']').focus();
            else
              $('[tabindex=' + 6 + ']').focus();
          // if (this.state.isNewAddress)
          //   setTimeout(() => {
          //     $('[tabindex=' + 6 + ']').focus();
          //   }, 0);
          // else
          //   setTimeout(() => {
          //     $('[tabindex=' + 7 + ']').focus();
          //   }, 0);
          else
            $('[tabindex=' + 1 + ']').focus();
      }, 0);
    }
  }

  //Fun for rendring the vendor list for current and Other Ehid's
  renderVendorList = (items: any, index: any, venDorType: any) => {
    //listRowIndex = listRowIndex + 1;
    let rNo = Number(items.id);
    MaxTabIndex = rNo + 1;
    if (
      items.vendorType?.toString().toLowerCase() ===
      venDorType?.toString().toLowerCase()
    ) {
      return (
        <div
          className={this.state.cursor === items.id ? 'activeVendor currentHIDRepeat vendorListItem vendorListFocusSet' : "vendorListItem currentHIDRepeat"}
          tabIndex={rNo}
          onKeyDown={(e) => { this.handleKeyDownArrow(e, items, 'vendorRow') }}
        >
          <Row
            className={
              this.state.pageName === "TransactionPage" &&
                items.approval?.toString().toLowerCase() === "no"
                ? "disabledRow VendorListRow"
                : "VendorListRow"
            }
          >
            <Col sm="11">
              <div
                className={items.approval?.toString().toLowerCase() === "no" && items.vendorName.length > 27 ? "selectableDiv showBadge" : "selectableDiv"}
                onClick={(e) => { this.onClickVendor(items) }}
              >
                <div className="d-flex">
                  {items.vendorName === this.state.selectedVendor &&
                    items.vendorType?.toString().toLowerCase() ===
                    "current" && (
                      <span className="VendorItemCheck">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
                            fill="#2CAF92"
                          />
                        </svg>
                      </span>
                    )}
                  <span className="vendorName">{items.vendorName}</span>
                  {items.approval?.toString().toLowerCase() === "no" ? (
                    <span className="red-badges"> Unapproved</span>
                  ) : null}
                </div>

                {items.vendorType?.toString().toLowerCase() === "current" ? (
                  <span className="contracts">
                    {items.ctCount > 1
                      ? items.ctCount + " Contracts"
                      : items.accountNo}
                  </span>
                ) : (
                  <span className="contracts">{items.contractdetails}</span>
                )}
              </div>
            </Col>
            <Col sm="1">{this.renderAddContractorDetailsBtn(items)}</Col>
          </Row>
        </div>
      );
    }
  };

  renderAddContractorDetailsBtn = (items: any) => {
    return this.state.pageName === "TransactionPage" ? (
      items.ctCount > 1 &&
        items.vendorType?.toString().toLowerCase() === "current" ? (
        <svg
          stroke="currentColor"
          fill="currentColor"
          stroke-width="0"
          viewBox="0 0 320 512"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
          className="rightIcon"
          onClick={(e) => {
            this.contractDetails(
              items.vendorName,
              items.vid,
              items.vendorType,
              items.approval
            );
          }}
        >
          <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
        </svg>
      ) : items.approval?.toString().toLowerCase() !== "no" ? (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="addContract">Add New Contract</Tooltip>}
        >
          <svg
            className="AddVendorrightIcon"
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 448 512"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
            onClick={(e) => {
              this.addNewContract(
                items.vendorName,
                items.vid,
                items.vendorType,
                this.state.hotelID
              );
            }}
          >
            <path d="M352 240v32c0 6.6-5.4 12-12 12h-88v88c0 6.6-5.4 12-12 12h-32c-6.6 0-12-5.4-12-12v-88h-88c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h88v-88c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v88h88c6.6 0 12 5.4 12 12zm96-160v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48zm-48 346V86c0-3.3-2.7-6-6-6H54c-3.3 0-6 2.7-6 6v340c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"></path>
          </svg>
        </OverlayTrigger>
      ) : (
        <svg
          className="AddVendorrightIcon"
          stroke="currentColor"
          fill="currentColor"
          stroke-width="0"
          viewBox="0 0 448 512"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
          onClick={(e) => {
            this.handleNotApprove();
          }}
        >
          <path d="M352 240v32c0 6.6-5.4 12-12 12h-88v88c0 6.6-5.4 12-12 12h-32c-6.6 0-12-5.4-12-12v-88h-88c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h88v-88c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v88h88c6.6 0 12 5.4 12 12zm96-160v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48zm-48 346V86c0-3.3-2.7-6-6-6H54c-3.3 0-6 2.7-6 6v340c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"></path>
        </svg>
      )
    ) : items.ctCount > 1 &&
      items.vendorType?.toString().toLowerCase() === "current" ? (
      <svg
        stroke="currentColor"
        fill="currentColor"
        stroke-width="0"
        viewBox="0 0 320 512"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
        className="rightIcon"
        onClick={(e) => {
          this.contractDetails(
            items.vendorName,
            items.vid,
            items.vendorType,
            items.approval
          );
        }}
      >
        <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
      </svg>
    ) : (
      this.state.isAddContractPerm && items.approval?.toString().toLowerCase() === "app" && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="addContract">Add New Contract</Tooltip>}
        >
          <svg
            className="AddVendorrightIcon"
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 448 512"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
            onClick={(e) => {
              this.addNewContract(items.vendorName, items.vid, items.vendorType, this.state.hotelID);
            }}
          >
            <path d="M352 240v32c0 6.6-5.4 12-12 12h-88v88c0 6.6-5.4 12-12 12h-32c-6.6 0-12-5.4-12-12v-88h-88c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h88v-88c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v88h88c6.6 0 12 5.4 12 12zm96-160v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48zm-48 346V86c0-3.3-2.7-6-6-6H54c-3.3 0-6 2.7-6 6v340c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"></path>
          </svg>
        </OverlayTrigger>
      )
    );
  };

  renderAdVendorBtn = () => {
    return this.state.pageName === "TransactionPage" ? (
      <>
        {this.state.isGenericVendorPerm && isShowGeneric && this.state.pageName !== "CreditCardEntry" && (
          <div className="d-flex">
            <div
              className={this.state.cursor === MaxTabIndex ? 'activeVendor GenericVendor vendorListFocusSet' : "GenericVendor"}
              onClick={(e) => {
                this.genericSelectClick();
              }}
              tabIndex={MaxTabIndex}
              onKeyDown={(e) => { this.handleKeyDownArrow(e, "", 'GenericSelect') }}
            >
              {this.state.isGenericSelect && (
                <span className="VendorItemCheck">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
                      fill="#2CAF92"
                    />
                  </svg>
                </span>
              )}{" "}
              USE GENERIC VENDOR
            </div>
          </div>
        )}
        {this.state.isAddVendorPerm && (
          <div className={this.state.cursor === MaxTabIndex + 1 ? 'activeVendor AddVendorFooter vendorListFocusSet' : "AddVendorFooter"}
            tabIndex={MaxTabIndex + 1}
            onKeyDown={(e) => { this.handleKeyDownArrow(e, "", 'AddVendorBtn') }}
          >
            <button
              id="addNewVendor"
              type="button"
              className="btn wht-bg txt-green addVendorButton"
              onClick={this.addNewVendor}
            >
              Add Vendor "{this.state.vendorForCreate?.trim()}"
            </button>
          </div>
        )}
      </>
    ) : (
      this.state.isAddVendorPerm && (
        <>
          <div className={this.state.cursor === MaxTabIndex + 1 ? 'activeVendor AddVendorFooter vendorListFocusSet' : "AddVendorFooter"}
            tabIndex={MaxTabIndex + 1}
            onKeyDown={(e) => { this.handleKeyDownArrow(e, "", 'AddVendorBtn') }}
          >
            <button
              id="addNewVendor"
              type="button"
              className="btn wht-bg txt-green addVendorButton"
              onClick={this.addNewVendor}
            >
              Add Vendor "{this.state.vendorForCreate?.trim()}"
            </button>
          </div>
        </>
      )
    );
  };

  genericSelectClick = () => {
    const genericVendorResult = this.state.mainVendorList.filter(
      (nis) =>
        nis.vendorName?.toString().toLowerCase() === "generic vendor" &&
        nis.vendorType?.toString().toLowerCase() === "current"
    );

    //if (genericVendorResult.length > 0)
    if (
      //genericVendorResult[0]?.accountNo !== "0" &&
      genericVendorResult[0]?.accountNo !== "" &&
      genericVendorResult[0] !== undefined
    ) {
      this.setState({ isGenericSelect: true }, () => {
        this.handleSelectVendor(genericVendorResult[0]);
      });
    } else {
      this.setState(
        {
          lastVid:
            genericVendorResult[0]?.vid === undefined
              ? 0
              : genericVendorResult[0]?.vid,
          addressID: 0,
          vendorForCreate:
            genericVendorResult[0]?.vendorName === undefined
              ? ""
              : genericVendorResult[0]?.vendorName,
          address: "",
          city: "",
          states: "",
          zip: "",
          phone: "",
          paymentMethod: "",
          salesTaxCheck: "",
          vendorCheck: "",
          taxIdSuffix: "",
          taxIDPrefix: "",
          invoicesDuesAfter: "",
          customerNo: "",
          email: "",
          isGeneric: true,
        },
        () => {
          this.handleSaveRequest();
        }
      );
    }
  };

  showModal = () => {
    if (this.state.isEditInvoiceRules)
      this.setState({ AddNewPostingRule: true, rules: this.state.postingRule, newRuleError: false });
  }
  hideModal = () => {
    this.setState({ AddNewPostingRule: false, });
  }
  handleRules = (event) => {
    let { value } = event.target;
    this.setState({ rules: value, newRuleError: false })
    this.setState({ rules: value, newRuleError: false })
  }
  saveInvoiceRule = () => {
    if (this.state.rules.trim() === "") {
      this.setState({ newRuleError: true });
      Utils.toastError("Please enter rules.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      this.setState({ loader: true });
      let formData: any = {};
      formData.Vid = this.state.selectedVID;
      formData.Description = this.state.rules.substring(0, 1000).trim();
      registersevice
        .SaveInvoiceRule(formData)
        .then(async (result: any | null) => {
          if (result !== null) {
            if (result.message?.toLowerCase() === "success") {
              this.setState({ postingRule: result.result.description, loader: false, LastUpdatedBy: result?.result.createdBy, LastUpdatedTime: result?.result.createdDateTime }, () => {
                this.hideModal();
                this.state.vendorList.find((o) => o.vid === this.state.selectedVID).postingRule = result.result.description;
                this.state.vendorList.find((o) => o.vid === this.state.selectedVID).postingRuleDate = result?.result.createdDateTime;
                this.state.vendorList.find((o) => o.vid === this.state.selectedVID).postingRuleBy = result.result.createdBy;
              });
              toast.success("Invoice rule saved Successfully.", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            } else {
              Utils.toastError(result.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }
          }
          resolve();
        })
        .catch((error) => {
          this.setState({ loader: false });
          reject();
        });
    }
  }

  componentWillReceiveProps(nextProps: { disabled: any }) {
    this.setState({ disabled: nextProps.disabled });
  }

  renderVendorSearchBox = () => {
    if ((this.state.selectedVendor.length + this.state.selectedContract.length) > 20 && !this.state.isListOpen && !this.state.addVendorFooterVisible)
      return (<OverlayTrigger
        placement="bottom"
        delay={{ show: 100, hide: 200 }}
        overlay={
          <Tooltip id={"ddd"}>
            {this.state.selectedVendor + this.state.selectedContract}
          </Tooltip>
        }>
        <input
          ref={this.inputSearch}
          id="inputSearch"
          key={"text-Filter"}
          type="text"
          className="form-control searchVendor VendorDisabled"
          onChange={(e) => {
            this.handleChange(e, "dropdown");
          }}
          onClick={this.handleClick}
          disabled={this.state.disabled ? true : this.state.isNewRequest}
          placeholder="Enter 3 characters to search."
          //title={this.state.selectedVendor.length > 20 ? this.state.selectedVendor + this.state.selectedContract : ""}
          autoComplete="Off"
          value={
            this.state.selectedVendor + this.state.selectedContract
          }
          onKeyDown={this.handleKeyDown}
        />
      </OverlayTrigger>)
    else
      return (<input
        ref={this.inputSearch}
        id="inputSearch"
        key={"text-Filter"}
        type="text"
        className="form-control searchVendor VendorDisabled"
        onChange={(e) => {
          this.handleChange(e, "dropdown");
        }}
        onClick={this.handleClick}
        disabled={this.state.disabled ? true : this.state.isNewRequest}
        placeholder="Enter 3 characters to search."
        autoComplete="Off"
        value={
          this.state.selectedVendor + this.state.selectedContract
        }
        onKeyDown={this.handleKeyDown}
      />)
  }

  render() {
    let isSpclCharMsg = "Fields containing only special characters is not allowed. Please update the field.";
    return (
      <React.Fragment>
        {this.state.loader && (
          <div className="matrixSpinnerCenter">
            <Spinner animation="border" variant="secondary" />
          </div>
        )}

        <OutsideClickHandler onOutsideClick={this.handleOutsideClick}>
          <div className="vendorSearch">
            {this.state.callFromPage === "CreditCardStatementImport" ? (
              ""
            ) : (
              <>
                <div className="SearchInput d-flex">
                  {this.renderVendorSearchBox()}
                  {this.state.selectedContract !== "" && this.state.pageName === "InvoiceEntry" && this.state.postingRule !== "" && this.state.postingRule !== undefined && (
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id="postingRule">{this.state.postingRule}</Tooltip>}
                    >
                      <svg
                        stroke="currentColor"
                        fill="#6a6e73"
                        stroke-width="0"
                        version="1.1"
                        viewBox="0 0 16 16"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                        className="postingToolTip"
                        onClick={this.showModal}
                      >
                        <path d="M7 4.75c0-0.412 0.338-0.75 0.75-0.75h0.5c0.412 0 0.75 0.338 0.75 0.75v0.5c0 0.412-0.338 0.75-0.75 0.75h-0.5c-0.412 0-0.75-0.338-0.75-0.75v-0.5z"></path>
                        <path d="M10 12h-4v-1h1v-3h-1v-1h3v4h1z"></path>
                        <path d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM8 14.5c-3.59 0-6.5-2.91-6.5-6.5s2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5-2.91 6.5-6.5 6.5z"></path>
                      </svg>
                    </OverlayTrigger>
                  )}{this.state.selectedContract !== "" && this.state.pageName === "InvoiceEntry" && (this.state.postingRule === "" || this.state.postingRule === undefined) && (
                    <svg
                      stroke="currentColor"
                      fill="#6a6e73"
                      stroke-width="0"
                      version="1.1"
                      viewBox="0 0 16 16"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                      className="postingToolTip"
                      onClick={this.showModal}
                    >
                      <path d="M7 4.75c0-0.412 0.338-0.75 0.75-0.75h0.5c0.412 0 0.75 0.338 0.75 0.75v0.5c0 0.412-0.338 0.75-0.75 0.75h-0.5c-0.412 0-0.75-0.338-0.75-0.75v-0.5z"></path>
                      <path d="M10 12h-4v-1h1v-3h-1v-1h3v4h1z"></path>
                      <path d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM8 14.5c-3.59 0-6.5-2.91-6.5-6.5s2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5-2.91 6.5-6.5 6.5z"></path>
                    </svg>
                  )}
                </div>

                {this.state.isListOpen && this.state.vendorList.length > 0 && (
                  <React.Fragment>
                    <div className="VendorList" id="VendorList">
                      <div className="scrollList VendorListScroll" id="VendorListScrol">
                        {this.state.isFoundCurrent && (
                          <div className="borderDiv" id="borderDiv">
                            <div className="CurrentEHID" id="CurrentEHID">
                              <p>EHID (Current)</p>

                              {this.state.vendorList.map((items, index) =>
                                this.renderVendorList(items, index, "Current")
                              )}
                            </div>
                          </div>
                        )}
                        {this.state.isFoundOther && (
                          <div className="borderDiv">
                            <div className="CurrentEHID">
                              <p>Other EHIDs</p>
                              {this.state.vendorList.map((items, index) =>
                                this.renderVendorList(items, index, "Other")
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                      {this.renderAdVendorBtn()}
                    </div>
                  </React.Fragment>
                )}

                {/* isMatchingMsgVisible */}

                {this.state.addVendorFooterVisible && (
                  <div className="VendorList">
                    {this.state.isMatchingMsgVisible && (
                      <div className="scrollList">
                        <div className="borderDiv">
                          <div className="CurrentEHID">
                            <div className="VendorListLoading">
                              No matching vendors exist.
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {this.state.isAddVendorBtnVisible &&
                      this.renderAdVendorBtn()}
                  </div>
                )}
              </>
            )}
            {this.state.callFromPage === "CreditCardStatementImport" ? (
              ""
            ) : (
              <>
                {this.state.isContractDetailsOpen && (
                  <div className="VendorList">
                    <div className="ContractDetailHeader">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 320 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={this.clearFieldValues}
                        className={this.state.cursorDetails === 1 ? 'activeVendor VendorDetailBackButton contractListFocusSet' : "VendorDetailBackButton"}
                        tabIndex={1}
                        onKeyDown={(e) => { this.handleContractDetailBox(e, 'back', '') }}
                      >
                        <path d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"></path>
                      </svg>{" "}
                      <span className="vendorDetailTitle">
                        {this.state.vendorforDetails}
                      </span>
                      {this.state.vendorStatus?.toLowerCase() === "no" ? (
                        <span className="red-badges"> Unapproved</span>
                      ) : null}
                    </div>
                    <div className="scrollList ContractScrollList">
                      <div className="borderDiv">
                        <div className="CurrentEHID">
                          {this.state.contractDetails.map((item, index) => (
                            <div
                              className={this.state.cursorDetails === (item.rowID) + 1 ? 'activeVendor currentHIDRepeat ListContract contractListFocusSet' : "currentHIDRepeat ListContract"}
                              tabIndex={Number(item.rowID) + 1}
                              onKeyDown={(e) => { this.handleContractDetailBox(e, 'contractList', item) }}
                            >
                              <div
                                className="selectableDiv"
                                onClick={(e) => { this.onClickContractDetailsList(item) }}
                              >
                                <div className="d-flex">
                                  {item.accountNo ===
                                    this.state.oldSelectedContract &&
                                    this.state.vendorType
                                      ?.toString()
                                      .toLowerCase() === "current" && this.state.selectedContractRowID === item.rowID && (
                                      <span className="VendorItemCheck">
                                        <svg
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
                                            fill="#2CAF92"
                                          />
                                        </svg>
                                      </span>
                                    )}
                                  <span className="vendorName">
                                    {item.accountNo}
                                  </span>
                                </div>
                                <span className="contractsDetails">
                                  {item.street}, {item.city}
                                </span>
                                <span className="contractsDetails">
                                  {item.state} {item.pCode}
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    {this.state.pageName === "InvoiceEntry" ? (
                      <div tabIndex={MaxContractTabIndex + 2}
                        onKeyDown={(e) => { this.handleContractDetailBox(e, 'AddContract', '') }}
                        className={this.state.cursorDetails === (MaxContractTabIndex + 2) ? 'activeVendor AddContractFooter contractListFocusSet' : "AddContractFooter"}
                      >
                        <button
                          id="addvendor"
                          type="button"
                          className="btn wht-bg txt-green addVendorButton"
                          onClick={(e) => {
                            this.addNewContract(
                              this.state.vendorforDetails,
                              this.state.lastVid,
                              this.state.vendorType,
                              this.state.hotelID
                            );
                          }}
                        >
                          Add New Contract
                        </button>
                      </div>
                    ) : (
                      this.state.isAddContractPerm && this.state.vendorStatus?.toLowerCase() !== "no" && (
                        <div tabIndex={MaxContractTabIndex + 2}
                          onKeyDown={(e) => { this.handleContractDetailBox(e, 'AddContract', '') }}
                          className={this.state.cursorDetails === (MaxContractTabIndex + 2) ? 'activeVendor AddContractFooter contractListFocusSet' : "AddContractFooter"}
                        >
                          <button
                            id="addvendor"
                            type="button"
                            className="btn wht-bg txt-green addVendorButton"
                            onClick={(e) => {
                              this.addNewContract(
                                this.state.vendorforDetails,
                                this.state.lastVid,
                                this.state.vendorType,
                                this.state.hotelID
                              );
                            }}
                          >
                            Add New Contract
                          </button>
                        </div>
                      )
                    )}
                  </div>
                )}
              </>
            )}
            {this.state.isNewRequest && (
              <div className={this.state.isNewRequest ? "isFromCCSI" : ""}>
                <div className="addVendorForm">
                  <div className="borderDiv">
                    <div className="NewContractHeader">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 320 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={this.clearFieldValues}
                        className="NewContractBackButton"
                        onKeyDown={(e) => { this.handleKeyTabForm(e, 'back') }}
                      >
                        <path d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"></path>
                      </svg>{" "}
                      Add New {this.state.isNewVendor ? "Vendor" : "Contract"}
                    </div>

                    <div className="CurrentEHID">
                      <div className="addVendorRow1">
                        <Form.Group>
                          <Form.Label>
                            Name <strong className="text-danger">*</strong>
                          </Form.Label>
                          <Form.Control
                            className="NewVendorName VendorDisabled"
                            id="vendorname"
                            value={this.state.vendorForCreate?.toUpperCase()}
                            onChange={(e: any) =>
                              this.onInputChange(e, "vendorname")
                            }
                            placeholder="Enter Name"
                            autoComplete="Off"
                            maxLength={Number(50)}
                            autoFocus={this.state.vendorForCreate?.toUpperCase()}
                            disabled={this.isDisabled("VendorNameControl")}
                            onBlur={(e) => {
                              this.validateInputsFormat("vendorname");
                            }}
                            tabIndex={1}
                            onKeyDown={(e) => { this.handleKeyTabForm(e, 'vendorname') }}
                          />
                        </Form.Group>

                        <Form.Check
                          id="vendorcheck"
                          type="checkbox"
                          className="customeCheck"
                          label="1099 Vendor"
                          value="1099 Vendor"
                          onChange={(e) => this.onInputChange(e, "vendorcheck")}
                          checked={this.state.vendorCheck}
                          disabled={this.isDisabled("vendorCheck")}
                          tabIndex={2}
                          onKeyDown={(e) => { this.handleKeyTabForm(e, 'vendorCheck') }}
                          custom
                        />
                      </div>
                      <div className="addVendorRow2">
                        <Row className="customRow2">
                          <Col sm="4">
                            <Form.Label>Payment Method </Form.Label>
                            <div className="singleSelect">
                              <Dropdown className="more-action bg-blue single-select-image">
                                <Dropdown.Toggle
                                  id="Modal-hid"
                                  className="filter-btn"
                                  disabled={this.isDisabled("PaymentMethod")}
                                  tabIndex={3}
                                  onKeyDown={(e) => { this.handleKeyTabForm(e, 'paymentMethod') }}
                                >
                                  <div className="d-flex align-items-center">
                                    <div className="txt-area">
                                      {this.state.paymentMethod.toString().toLowerCase() === "ACH".toLowerCase() ? "Manual ACH" : this.state.paymentMethod}
                                    </div>
                                    <div className="drop-arrow ml-0 d-flex">
                                      <FiChevronDown />
                                    </div>
                                  </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() =>
                                      this.onDropDownClick("Manual ACH", "payMethod")
                                    }
                                  >
                                    Manual ACH
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() =>
                                      this.onDropDownClick("Check", "payMethod")
                                    }
                                  >
                                    Check
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() =>
                                      this.onDropDownClick(
                                        "Credit Card",
                                        "payMethod"
                                      )
                                    }
                                  >
                                    Credit Card
                                  </Dropdown.Item>
                                  {this.state.isCustIDExist && (
                                    <Dropdown.Item
                                      onClick={() =>
                                        this.onDropDownClick(
                                          "ePay",
                                          "payMethod"
                                        )
                                      }
                                    >
                                      ePay
                                    </Dropdown.Item>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </Col>
                          <Col sm="5">
                            <Form.Group>
                              <Form.Label>
                                Invoices Due After{" "}
                                <strong className="text-danger">*</strong>
                              </Form.Label>
                              <Form.Control
                                className="VendorDisabled"
                                value={this.state.invoicesDuesAfter}
                                onChange={(e: any) =>
                                  this.onInputChange(e, "invoices")
                                }
                                autoComplete="Off"
                                placeholder="Enter Invoices"
                                id="invoices"
                                disabled={this.isDisabled("invoices")}
                                tabIndex={4}
                                onKeyDown={(e) => { this.handleKeyTabForm(e, 'invoices') }}
                                maxLength={Number(2)}
                              />
                            </Form.Group>
                          </Col>
                          <Form.Check
                            type="checkbox"
                            id="salescheck"
                            className="customeCheck salesCheck"
                            label="Sales Tax"
                            value="Sales Tax"
                            onChange={(e) =>
                              this.onInputChange(e, "salescheck")
                            }
                            disabled={this.isDisabled("salesTaxCheck")}
                            checked={this.state.salesTaxCheck}
                            tabIndex={5}
                            onKeyDown={(e) => { this.handleKeyTabForm(e, 'salesTaxCheck') }}
                            custom
                          />
                          <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip id="button-tooltip-helpText">
                              <span>Enable if sales tax is included on the invoice. Disable if sales tax has not been paid by the vendor, and if use tax is required to be paid on the items purchased.</span>
                            </Tooltip>}
                          >
                            <span className="help-icon VendorSalesHelpIcon">
                              <svg
                                stroke="currentColor"
                                fill="#6a6e73"
                                stroke-width="0"
                                version="1.1"
                                viewBox="0 0 16 16"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M7 4.75c0-0.412 0.338-0.75 0.75-0.75h0.5c0.412 0 0.75 0.338 0.75 0.75v0.5c0 0.412-0.338 0.75-0.75 0.75h-0.5c-0.412 0-0.75-0.338-0.75-0.75v-0.5z"></path>
                                <path d="M10 12h-4v-1h1v-3h-1v-1h3v4h1z"></path>
                                <path d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM8 14.5c-3.59 0-6.5-2.91-6.5-6.5s2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5-2.91 6.5-6.5 6.5z"></path>
                              </svg>{" "}
                            </span>
                          </OverlayTrigger>
                        </Row>
                      </div>
                      <div className="addVendorRowNew">
                        <div className="customRow2">
                          <Form.Group>
                            <Form.Label>
                              TAX ID/SSN{" "}
                            </Form.Label>
                            <div className="d-flex">
                              <div className="singleSelect">
                                <Dropdown className="more-action bg-blue single-select-image">
                                  <Dropdown.Toggle
                                    id="Modal-hid"
                                    className="filter-btn"
                                    disabled={this.isDisabled(
                                      "taxIDPrefix"
                                    )}
                                    tabIndex={6}
                                    onKeyDown={(e) => { this.handleKeyTabForm(e, 'taxIDPrefix') }}
                                  >
                                    <div className="d-flex align-items-center">
                                      <div className="txt-area">
                                        {this.state.taxIDPrefix}
                                      </div>
                                      <div className="drop-arrow ml-0 d-flex">
                                        <FiChevronDown />
                                      </div>
                                    </div>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={() =>
                                        this.onDropDownClick(
                                          "TAX ID",
                                          "taxIDPrefix"
                                        )
                                      }
                                    >
                                      TAX ID
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() =>
                                        this.onDropDownClick(
                                          "SSN",
                                          "taxIDPrefix"
                                        )
                                      }
                                    >
                                      SSN
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                              <input
                                type="text"
                                id="taxIdSuffix"
                                className="form-control VendorDisabled taxIDInput"
                                placeholder={`Enter ${this.state.taxIDPrefix}`}
                                disabled={this.isDisabled("taxIdSuffix")}
                                value={this.state.taxIdSuffix}
                                onChange={(e: any) =>
                                  this.onInputChange(e, "taxIdSuffix")
                                }
                                onKeyPress={this.taxIDCheck}
                                onBlur={(e) => {
                                  this.validateInputsFormat(
                                    "taxIdSuffix"
                                  );
                                }}
                                maxLength={Number(11)}
                                tabIndex={7}
                                onKeyDown={(e) => { this.handleKeyTabForm(e, 'taxIdSuffix') }}
                                onFocus={(e) => { this.onFocus(e, "taxIdSuffix") }}
                              />
                            </div>
                          </Form.Group>
                        </div>
                      </div>

                      {this.state.isNewAddress && (
                        <React.Fragment>
                          <div className="addVendorRow3">
                            <Row>
                              <Col sm="12">
                                <Form.Group>
                                  <Form.Label>
                                    Street Address{" "}
                                    <strong className="text-danger">*</strong>
                                  </Form.Label>
                                  <Form.Control
                                    value={this.state.address}
                                    onChange={(e: any) =>
                                      this.onInputChange(e, "address")
                                    }
                                    id="streetaddressText"
                                    placeholder="Enter Address"
                                    autoComplete="Off"
                                    maxLength={Number(100)}
                                    tabIndex={8}
                                    onKeyDown={(e) => { this.handleKeyTabForm(e, 'address') }}
                                  />
                                  {this.state.address.length > 0 && !this.validateAddress(this.state.address) && <div className="textError">{isSpclCharMsg}</div>}
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>
                          <div className="addVendorRow4">
                            <Row>
                              <Col sm="4" className="pr-0">
                                <Form.Group className="mr-0">
                                  <Form.Label>
                                    City{" "}
                                    <strong className="text-danger">*</strong>
                                  </Form.Label>
                                  <Form.Control
                                    id="city"
                                    value={this.state.city}
                                    onChange={(e: any) =>
                                      this.onInputChange(e, "city")
                                    }
                                    placeholder="Enter City"
                                    maxLength={50}
                                    tabIndex={9}
                                    onKeyDown={(e) => { this.handleKeyTabForm(e, 'city') }}
                                  />
                                  {this.state.city.length > 0 && !this.validateAddress(this.state.city) && <div className="textError">{isSpclCharMsg}</div>}
                                </Form.Group>
                              </Col>
                              <Col sm="4" className="pr-0">
                                <Form.Group className="mr-0">
                                  <Form.Label>
                                    State{" "}
                                    <strong className="text-danger">*</strong>
                                  </Form.Label>
                                  <Form.Control
                                    id="state"
                                    value={this.state.states}
                                    onChange={(e: any) =>
                                      this.onInputChange(e, "state")
                                    }
                                    placeholder="Enter State"
                                    maxLength={50}
                                    tabIndex={10}
                                    onKeyDown={(e) => { this.handleKeyTabForm(e, 'states') }}
                                  />
                                  {/* <DropDownList
                                    onKeyDown={(e) => { this.handleKeyTabForm(e, 'states') }}
                                    tabIndex={10}
                                    placeHolder={"Search..."}
                                    data={_.sortBy(StaticArrays.userStates, 'label')}
                                    defaultValue={this.state.states}
                                    isSearchRequired={true}
                                    label={"label"}
                                    value={"value"}
                                    onDropDownChange={(item) => {
                                      if (!item) {
                                        return;
                                      }
                                      this.onDropDownClick(
                                        Utils.BuildCustomEventObject(item.value, "states"),
                                        "states"
                                      );
                                    }}
                                    selectedItem={[
                                      ...[{ value: "", label: "" }],
                                      ...StaticArrays.userStates,
                                    ].find((r: any) => r?.value === this.state.states) || {}}
                                  /> */}
                                  {this.state.states.length > 0 && !this.validateAddress(this.state.states) && <div className="textError">{isSpclCharMsg}</div>}
                                </Form.Group>
                              </Col>
                              <Col sm="4">
                                <Form.Group>
                                  <Form.Label>
                                    Zip{" "}
                                    <strong className="text-danger">*</strong>
                                  </Form.Label>
                                  <Form.Control
                                    id="zip"
                                    value={this.state.zip}
                                    className={this.state.zipCodeError ? "alert-danger" : ""}
                                    onChange={(e: any) => this.onInputChange(e, "zip")}
                                    placeholder="Enter Zip"
                                    maxLength={10}
                                    tabIndex={11}
                                    onKeyDown={(e) => { this.handleKeyTabForm(e, 'zip') }}
                                    onBlur={(e) => { this.validateInputsFormat("zip"); }}
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>
                        </React.Fragment>
                      )}

                      {!this.state.isNewAddress && (
                        <div className="addVendorRow7">
                          <Form.Label>
                            Address <strong className="text-danger">*</strong>
                          </Form.Label>
                          <Dropdown className="more-action bg-blue single-select-image">
                            <Dropdown.Toggle
                              id="streetaddressDrop"
                              className="filter-btn"
                              tabIndex={6}
                              onKeyDown={(e) => { this.handleKeyTabForm(e, 'address') }}
                            >
                              <div className="d-flex align-items-center">
                                <div className="txt-area">
                                  {this.state.address}
                                </div>
                                <div className="drop-arrow ml-0 d-flex">
                                  <FiChevronDown />
                                </div>
                              </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {this.state.vendorAddressList.map(
                                (items, index) => (
                                  <>
                                    {items.adddressID !== -1 && (
                                      <Dropdown.Item
                                        onClick={(event: any) =>
                                          this.handleSelectAddress(event, items)
                                        }
                                        key={index}
                                      >
                                        <EllipsisWithTooltip placement="bottom">
                                          {items.address}
                                        </EllipsisWithTooltip>
                                      </Dropdown.Item>
                                    )}
                                  </>
                                )
                              )}
                              {this.state.isAddVendorAddressPerm && (
                                <Dropdown.Item
                                  className="addNewAddressButton"
                                  onClick={(event: any) =>
                                    this.handleAddNewAddress(event)
                                  }
                                >
                                  Add New Address
                                </Dropdown.Item>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      )}

                      {this.state.isAddressSelect && (
                        <React.Fragment>
                          <div className="addVendorRow5">
                            <Row>
                              <Col sm="12">
                                <Form.Group>
                                  <Form.Label>
                                    Account Number{" "}
                                    <strong className="text-danger">*</strong>
                                  </Form.Label>
                                  <Form.Control
                                    value={this.state.customerNo}
                                    onChange={(e: any) =>
                                      this.onInputChange(e, "customerno")
                                    }
                                    placeholder="Enter Account number"
                                    id="customerno"
                                    required
                                    maxLength={Number(40)}
                                    tabIndex={12}
                                    onKeyDown={(e) => { this.handleKeyTabForm(e, 'customerno') }}
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>
                          <div className="addVendorRow6"
                            style={{ display: this.state.isNewContract ? "none" : "block" }}>
                            <Row>
                              <Col sm="6">
                                <Form.Group>
                                  <Form.Label>Phone Number </Form.Label>
                                  <Form.Control
                                    className="VendorDisabled"
                                    value={this.state.phone}
                                    onChange={(e: any) =>
                                      this.onInputChange(e, "phone")
                                    }
                                    id="phone"
                                    onBlur={(e) => {
                                      this.validateInputsFormat("PhoneControl");
                                    }}
                                    placeholder="Enter Phone Number"
                                    disabled={this.isDisabled("PhoneControl")}
                                    maxLength={Number(20)}
                                    tabIndex={13}
                                    onKeyDown={(e) => { this.handleKeyTabForm(e, 'phone') }}
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm="6">
                                <Form.Group>
                                  <Form.Label>Email</Form.Label>
                                  <Form.Control
                                    className="VendorDisabled"
                                    value={this.state.email}
                                    onChange={(e: any) =>
                                      this.onInputChange(e, "email")
                                    }
                                    id="email"
                                    placeholder="Enter Email"
                                    disabled={this.isDisabled("EmailControl")}
                                    onBlur={(e) => {
                                      this.validateInputsFormat("EmailControl");
                                    }}
                                    tabIndex={14}
                                    onKeyDown={(e) => { this.handleKeyTabForm(e, 'email') }}
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                  <div className="footerNewVendor">
                    <Row>
                      <Col sm="8">
                        <button
                          className="btn wht-bg VendorCancelBtn"
                          onClick={this.clearFieldValues}
                          tabIndex={15}
                          onKeyDown={(e) => { this.handleKeyTabForm(e, 'cancel') }}
                        >
                          Cancel
                        </button>
                      </Col>
                      <Col sm="4">
                        <Button
                          disabled={this.state.loader}
                          className="addNewVendor"
                          id="saveBtn"
                          onClick={() => this.handleSaveRequest()}
                          tabIndex={16}
                          onKeyDown={(e) => { this.handleKeyTabForm(e, 'saveBtn') }}
                        >
                          {this.state.btnName}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            )}
          </div>
        </OutsideClickHandler>
        <Modal
          show={this.state.AddNewPostingRule}
          onHide={this.hideModal}
          backdrop="static"
          keyboard={false}
          centered
          className="ccsImportModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Invoice Posting Rule/Guide for this vendor</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Label><strong>Posting Rules</strong></Form.Label>
            <Form.Control
              className={this.state.newRuleError ? "border-danger" : ""}
              value={this.state.rules}
              as="textarea"
              onChange={this.handleRules}
              maxLength={1000}
              disabled={this.state.loader || this.state.disabled}
            />
            {this.state.LastUpdatedBy === "" || this.state.LastUpdatedBy === undefined ? "" :
              <label style={{ float: "right" }}>
                Last Updated By: ({this.state.LastUpdatedBy + " " + this.state.LastUpdatedTime})</label>}
          </Modal.Body>
          <Modal.Footer>
            <Button className="btnCancel btn-ghost"
              onClick={this.hideModal}
            >
              Cancel
            </Button>

            <Button variant="primary"
              onClick={this.saveInvoiceRule}
              disabled={this.state.loader || this.state.disabled}
            >
              Add
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }

  pCodeValidate = (val: any) => {
    return /^\d{5}(-\d{4})?$/.test(val);
  }

  validateAddress = (val: any) => {
    return /^(?=.*[a-zA-Z0-9])/.exec(val);
  }

  //Validate fields value format correct or not
  validateInputsFormat = (controlID: any) => {
    let { taxIdSuffix, zip } = this.state;
    if (
      controlID === "vendorname" &&
      this.state.vendorForCreate?.trim() !== ""
    ) {
      if (this.state.vendorForCreate?.trim().length < 3) {
        Utils.toastError("Vendor name should be atleast 3 characters.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        $("#vendorname").addClass("alert-danger");
      } else {
        $("#vendorname").removeClass("alert-danger");
      }
    }

    if (controlID === "EmailControl" && this.state.email?.trim() !== "") {
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!emailRegex.test(this.state.email?.toLowerCase()?.trim())) {
        $("#email").addClass("alert-danger");
        // Utils.toastError("E-Mail format is not correct.", {
        //   position: toast.POSITION.BOTTOM_RIGHT,
        // });
      } else {
        $("#email").removeClass("alert-danger");
      }
    }
    if (controlID === "PhoneControl" && this.state.phone?.trim() !== "") {
      if (
        this.state.phone?.trim().length < 7 ||
        this.state.phone?.trim().length > 20
      ) {
        // Utils.toastError("Mobile Number must be between 7 to 20 digits.", {
        //   position: toast.POSITION.BOTTOM_RIGHT,
        // });
        $("#phone").addClass("alert-danger");
      } else {
        $("#phone").removeClass("alert-danger");
      }
    }
    if (controlID === "zip" && zip?.trim() !== "") {
      let { zipCodeError, zip } = this.state;
      let pCode = zip.toString().replace(/-/g, '').trim();
      if ((pCode?.length > 5 && pCode?.length === 9) && !pCode?.includes("-")) {
        pCode = pCode.substr(0, 5) + "-" + pCode.substr(5, pCode?.length - 5);
      }
      zip = pCode;
      zipCodeError = !this.pCodeValidate(zip);
      if (zipCodeError && pCode.length > 5) {
        Utils.toastError("Please enter 9 digit zip.", { position: toast.POSITION.BOTTOM_RIGHT });
      } else if (zipCodeError) {
        Utils.toastError("Please enter 5 digit zip.", { position: toast.POSITION.BOTTOM_RIGHT });
      }
      this.setState({ zipCodeError, zip });
    }
    if (controlID === "taxIdSuffix" && this.state.taxIdSuffix?.trim() !== "") {
      if (taxIdSuffix?.trim().length < 9) {
        Utils.toastError(`${this.state.taxIDPrefix} should be 9 digit.`,
          { position: toast.POSITION.BOTTOM_RIGHT }
        );
        $("#taxIdSuffix").addClass("alert-danger");
        return;
      } else if (this.allCharactersSame(taxIdSuffix?.trim()?.replaceAll("-", "")?.substr(0, 9))) {
        Utils.toastError(this.state.taxIDPrefix + " cannot be the same digit repeating.",
          { position: toast.POSITION.BOTTOM_RIGHT }
        );
        $("#taxIdSuffix").addClass("alert-danger");
        return;
      } else if (taxIdSuffix?.trim()?.length > 8) {
        if (this.state.taxIDPrefix?.toLowerCase() === "tax id")
          taxIdSuffix = taxIdSuffix?.substr(0, 2) + "-" + taxIdSuffix?.substr(2, taxIdSuffix?.length - 2);
        else
          taxIdSuffix = taxIdSuffix?.substr(0, 3) + "-" + taxIdSuffix?.substr(3, 2) + "-" + taxIdSuffix?.substr(5, taxIdSuffix?.length - 4);
        this.setState({ taxIdSuffix });
      }
      //   if (
      //     this.state.taxIdSuffix?.trim().replace("-", "").length > 9 ||
      //     this.state.taxIdSuffix?.trim().replace("-", "").length < 9
      //     //this.state.taxIdSuffix?.trim().replace("-", "").length > 20
      //   ) {
      //     $("#taxIdSuffix").addClass("alert-danger");
      //     Utils.toastError(
      //       this.state.taxIDPrefix + " must be 9 digits.",
      //       {
      //         position: toast.POSITION.BOTTOM_RIGHT,
      //       }
      //     );
      //   } else if (this.allCharactersSame(this.state.taxIdSuffix?.trim())) {
      //     Utils.toastError(
      //       this.state.taxIDPrefix + " cannot be the same digit repeating.",
      //       {
      //         position: toast.POSITION.BOTTOM_RIGHT,
      //       }
      //     );
      //     $("#taxIdSuffix").addClass("alert-danger");
      //   } else {
      //     $("#taxIdSuffix").removeClass("alert-danger");
      //   }
    }
  };

  onFocus = (e, controlID) => {
    if (controlID === "taxIdSuffix") {
      this.setState({ taxIdSuffix: this.state.taxIdSuffix.trim().replaceAll('-', '') });
    }
  }

  //Check if all characters are not same in taxid/ssn
  allCharactersSame = (s): boolean => {
    let n = s.length;
    for (let i = 1; i < n; i++) if (s[i] != s[0]) return false;

    return true;
  };

  //Allow entering -(dash) in input field
  taxIDCheck = (event) => {
    var charCode = event.which ? event.which : event.keyCode;
    if (
      charCode != 46 &&
      charCode != 45 &&
      charCode > 31 &&
      (charCode < 48 || charCode > 57)
    )
      isValidInput = "No";
    else isValidInput = "Yes";
  };

  setZipError = (value) => {
    let zipActLen = (value.includes("-") ? 10 : 9);
    let zipLen = value.length;
    let pcodeError: boolean = false;
    if (zipLen < 5) {
      pcodeError = true;
    } else if (zipLen > 5 && (zipLen < zipActLen)) {
      pcodeError = true;
    } else {
      pcodeError = false;
    }
    return pcodeError;
  }

  //Set input values to the states when entering by the user
  onInputChange = (e: any, type: any) => {
    if (type === "vendorname") {
      $("#vendorname").removeClass("alert-danger");
      if (this.state.isNewAddress)
        this.setState({ vendorForCreate: e.currentTarget.value });
    } else if (type === "vendorcheck") {
      this.setState({ vendorCheck: e.currentTarget.checked });
    } else if (type === "invoices") {
      let val = e.currentTarget.value.replace(/[@\/\\^\/\\&\/\\#,+()$~.%'":*?<>{}-]/g, '').substring(0, 2).trim();
      if (!isNaN(val)) {
        this.setState({ invoicesDuesAfter: val });
      }
      $("#invoices").removeClass("alert-danger");
    } else if (type === "salescheck") {
      this.setState({ salesTaxCheck: e.currentTarget.checked });
    } else if (type === "address") {
      let isVal = e.currentTarget.value.trimStart();
      if (this.state.isNewAddress && !this.validateAddress(isVal)) {
        $("#streetaddressText").addClass("alert-danger");
      } else {
        $("#streetaddressText").removeClass("alert-danger");
      }
      this.setState({ address: e.currentTarget.value });
    } else if (type === "city") {
      let isVal = e.currentTarget.value.trimStart();
      if (!this.validateAddress(isVal)) {
        $("#city").addClass("alert-danger");
      } else {
        $("#city").removeClass("alert-danger");
      }
      this.setState({ city: isVal });
    } else if (type === "state") {
      let isVal = e.currentTarget.value.trimStart();
      if (!this.validateAddress(isVal)) {
        $("#state").addClass("alert-danger");
      } else {
        $("#state").removeClass("alert-danger");
      }
      this.setState({ states: isVal });
    } else if (type === "zip") {
      //this.setState({ zip: e.currentTarget.value });
      // let val = Utils.removeSpecialCharcterFromString(e.currentTarget.value)?.substring(0, 9);
      // if (!isNaN(val)) {
      //   $("#zip").removeClass("alert-danger");
      //   this.setState({ zip: val });
      // }
      let val = (e.currentTarget.value).trim();
      val = val.indexOf("-") === 5 ? val : val.replace(/[^0-9]/g, '');
      val = val.trim().replace(/[^0-9-]/g, '');
      val = val?.substring(0, val.indexOf("-") === 5 ? 10 : 9);
      let { zipCodeError, zip } = this.state;
      zipCodeError = this.setZipError(val);//!this.pCodeValidate(val)
      zip = val;
      this.setState({ zipCodeError, zip });
    } else if (type === "customerno") {
      $("#customerno").removeClass("alert-danger");
      this.setState({ customerNo: e.currentTarget.value });
    } else if (type === "taxIdSuffix") {
      if (isValidInput === "Yes") {
        $("#taxIdSuffix").removeClass("alert-danger");
        let inputValue = Utils.removeSpecialCharcterFromString(e.currentTarget.value)?.substring(0, 9);
        if (!isNaN(inputValue)) {
          this.setState({ taxIdSuffix: inputValue }, () => {
            $("#taxIdSuffix").removeClass("alert-danger");
          });
        }
        //this.setState({ taxIdSuffix: e.currentTarget.value });
      }
    } else if (type === "phone") {
      // let val = e.currentTarget.value.replace(/[@\/\\^\/\\&\/\\#,+()$~%.'":*?<>{}-]/g, '');
      let val = e.currentTarget.value.replace(/[^0-9]/g, '');
      //if (!isNaN(val)) {
      if (this.state.isNewAddress) {
        this.setState({ phone: val });
      }
      //}
    } else if (type === "email") {
      if (this.state.isNewAddress)
        this.setState({ email: e.currentTarget.value });
    }
  };

  //Filter the selected address from vendor all contract address list
  handleSelectAddress = (event: any, item: any) => {
    $("#streetaddressDrop").removeClass("alert-danger");
    let addressResult = [...this.state.vendorAddressList];
    let newResult = _.filter(addressResult, { adddressID: item.adddressID });

    this.setState({
      address: item.address,
      isAddressSelect: true,
      addressID: item.adddressID,
      phone: newResult[0].phoneNo,
      email: newResult[0].email,
      //taxIDPrefix: newResult[0].isTaxOrSSN,
      //taxIdSuffix: newResult[0].taxOrSsnId,
    }, () => {
      $("#streetaddressDrop").blur();
      setTimeout(() => {
        $("#customerno").focus();
      }, 0);
    });
  };

  // Set dropdown value on selecting
  onDropDownClick = (value: any, type: any) => {
    let { taxIdSuffix } = this.state;
    if (type === "payMethod") {
      this.setState({ paymentMethod: value });
    } else if (type === "taxIDPrefix") {
      if (taxIdSuffix?.replaceAll('-', '')?.trim()?.length > 8) {
        taxIdSuffix = taxIdSuffix.replaceAll('-', '');
        if (value === "TAX ID")
          taxIdSuffix = taxIdSuffix?.substr(0, 2) + "-" + taxIdSuffix?.substr(2, taxIdSuffix?.length - 2);
        else
          taxIdSuffix = taxIdSuffix?.substr(0, 3) + "-" + taxIdSuffix?.substr(3, 2) + "-" + taxIdSuffix?.substr(5, taxIdSuffix?.length - 4);
      }
      this.setState({ taxIDPrefix: value, taxIdSuffix });
    } else if (type === "states") {
      $("#Modal-ddl10").removeClass("alert-danger");
      $('#zip').focus();
      this.setState({ states: value?.target?.name });
    }
  };

  //Disabled the fields on creating a new contract
  isDisabled = (controlId: any): boolean => {
    if (this.state.isNewContract /*&& !this.state.isNewAddress*/) {
      if (
        controlId === "VendorNameControl" /*&&
        this.state.vendorForCreate?.trim() !== ""*/
      ) {
        return true;
      } else if (
        controlId === "PaymentMethod" /*&&
        this.state.paymentMethod?.trim() !== ""*/
      ) {
        return true;
      } else if (
        controlId === "PhoneControl" &&
        // !this.state.isNewAddress &&
        // this.state.phone?.trim() !== ""
        this.state.isNewContract &&
        !this.state.isNewAddress
        /*|| this.state.phone?.trim() !== ""*/
      ) {
        return true;
      } else if (
        controlId === "EmailControl" &&
        !this.state.isNewAddress &&
        this.state.isNewContract
        /*|| this.state.email?.trim() !== ""*/
      ) {
        return true;
      } else if (
        controlId === "invoices" /*&& this.state.invoicesDuesAfter !== ""*/
      ) {
        return true;
      } else if (
        controlId ===
        "taxIdSuffix" /*&& !this.state.isNewAddress this.state.taxIdSuffix !== ""*/
      ) {
        return true;
      } else if (
        controlId ===
        "taxIDPrefix" /*&& !this.state.isNewAddress this.state.taxIDPrefix !== ""*/
      ) {
        return true;
      } else if (controlId === "salesTaxCheck") {
        return true;
      } else if (controlId === "vendorCheck") {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  //Validate all input values before save
  checkValidation = () => {
    let isValid: any = [];
    let focusSet = false;
    if (this.state.vendorForCreate?.trim() === "") {
      $("#vendorname").focus();
      $("#vendorname").addClass("alert-danger");
      focusSet = true;
      isValid.push(false);
    } else {
      $("#vendorname").blur();
      isValid.push(true);
      focusSet = focusSet ? true : false;
    }
    if (this.state.invoicesDuesAfter?.trim() === "") {
      if (!focusSet) {
        $("#invoices").focus();
        focusSet = true;
      }
      $("#invoices").addClass("alert-danger");
      isValid.push(false);
    } else {
      focusSet = focusSet ? true : false;
      $("#invoices").blur();
      isValid.push(true);
    }
    // if (this.state.taxIdSuffix?.trim() === "") {
    //   $("#taxIdSuffix").addClass("alert-danger");
    //   isValid.push(false);
    //   if (!focusSet) {
    //     $("#taxIdSuffix").focus();
    //     focusSet = true;
    //   }
    // } else {
    //   $("#taxIdSuffix").blur();
    //   focusSet = focusSet ? true : false;
    //   isValid.push(true);
    // }
    if (this.state.address?.trim() === "" || /*!this.validateAddress(this.state.address?.trim()) ||*/ this.state.address?.trim() === "Select Address") {
      if (this.state.isNewAddress && !this.validateAddress(this.state.address?.trim())) {
        $("#streetaddressText").addClass("alert-danger");
        isValid.push(false);
        if (!focusSet) {
          $("#streetaddressText").focus();
          focusSet = true;
        }
      } else {
        $("#streetaddressDrop").addClass("alert-danger");
        isValid.push(false);
        if (!focusSet) {
          $("#streetaddressDrop").focus();
          focusSet = true;
        }
      }
    } else {
      if (this.state.isNewAddress) {
        $("#streetaddressText").blur();
        focusSet = focusSet ? true : false;
        isValid.push(true);
      } else {
        $("#streetaddressDrop").blur();
        focusSet = focusSet ? true : false;
        isValid.push(true);
      }
    }

    if ((this.state.city?.trim() === "" || !this.validateAddress(this.state.city?.trim())) && this.state.isNewAddress) {
      if (!focusSet) {
        $("#city").focus();
        focusSet = true;
      }
      $("#city").addClass("alert-danger");
      isValid.push(false);
    } else {
      $("#city").blur();
      isValid.push(true);
      focusSet = focusSet ? true : false;
    }
    if ((this.state.states?.trim() === "" || !this.validateAddress(this.state.states?.trim())) && this.state.isNewAddress) {
      // $("#Modal-ddl10").addClass("alert-danger");
      $("#state").addClass("alert-danger");
      isValid.push(false);
      if (!focusSet) {
        //$("#Modal-ddl10").focus();
        focusSet = true;
      }
    } else {
      //$("#Modal-ddl10").blur();
      focusSet = focusSet ? true : false;
      isValid.push(true);
    }
    if (this.state.zip?.trim() === "" && this.state.isNewAddress) {
      // $("#zip").addClass("alert-danger");
      let { zipCodeError } = this.state;
      zipCodeError = true;
      this.setState({ zipCodeError })
      isValid.push(false);
      if (!focusSet) {
        $("#zip").focus();
        focusSet = true;
      }
    } else {
      $("#zip").blur();
      focusSet = focusSet ? true : false;
      isValid.push(true);
    }
    if (this.state.customerNo?.trim() === "") {
      $("#customerno").addClass("alert-danger");
      isValid.push(false);
      if (!focusSet) {
        $("#customerno").focus();
        focusSet = true;
      }
    } else {
      $("#customerno").blur();
      focusSet = focusSet ? true : false;
      isValid.push(true);
    }

    const returnValue = isValid.some((item) => item === false);
    if (!returnValue) {
      if (
        $("#vendorname").hasClass("alert-danger") &&
        this.state.vendorForCreate !== ""
      ) {
        return "vendorName";
      } else {
        $("#vendorname").removeClass("alert-danger");
      }
      // if ($("#zip").hasClass("alert-danger") && (this.state.zip.trim().length < 5 || (this.state.zip.trim().length > 5 && this.state.zip.trim().length < 9)) && this.state.zip.trim() !== "") {
      if ($("#zip").hasClass("alert-danger") && this.state.zip.trim() !== "") {
        return "zip";
      } else {
        $("#zip").removeClass("alert-danger");
      }
      if (
        $("#taxIdSuffix").hasClass("alert-danger") &&
        this.state.taxIdSuffix !== ""
      ) {
        return "taxIdSuffix";
      } else {
        $("#taxIdSuffix").removeClass("alert-danger");
      }
      if ($("#phone").hasClass("alert-danger") && this.state.phone !== "") {
        return "phone";
      } else {
        $("#phone").removeClass("alert-danger");
      }
      if ($("#email").hasClass("alert-danger") && this.state.email !== "") {
        return "email";
      } else {
        $("#email").removeClass("alert-danger");
      }

    }
    return returnValue ? "Required" : "Succeed";
  };

  checkTotalLength = () => {
    let cityLength = this.state.city?.substring(0, 50).trim().length;
    let stateLength = this.state.states?.substring(0, 50).trim().length;
    let zipLength = this.state.zip?.substring(0, 50).trim().length;
    let totalLength = (Number(cityLength) + Number(stateLength) + Number(zipLength));
    if (totalLength > 50) {
      return 'Exceed';
    } else {
      return 'NotExceed';
    }
  }

  //Save all entered data into db and handle the created vendor as selected in dropdown
  handleSaveRequest = async () => {
    if (
      !this.state.vendorForCreate
        ?.trim()
        .toUpperCase()
        .includes("GENERIC VENDOR") ||
      this.state.isGeneric
    ) {
      this.setState({ loader: true });
      let isValid = ["Succeed"];
      if (!this.state.isGeneric)
        isValid = await Promise.all([this.checkValidation()]);

      if (isValid[0] === "Succeed") {
        let isLengthExceed = this.checkTotalLength();

        if (isLengthExceed === "NotExceed") {
          let formData: any = {};
          formData.Vid = this.state.isNewVendor ? 0 : this.state.lastVid;
          formData.AID = this.state.isNewVendor
            ? 0
            : this.state.isNewAddress
              ? 0
              : this.state.addressID;
          formData.Company = this.state.vendorForCreate?.substring(0, 50).trim().toUpperCase();
          formData.Address1 = this.state.address?.substring(0, 100).trim();
          formData.City = this.state.city?.substring(0, 50).trim();
          formData.State = this.state.states?.substring(0, 50).trim();
          formData.PCode = this.state.zip?.substring(0, 50).trim();
          formData.HotelId = this.state.hotelID;
          formData.Phone = this.state.phone?.substring(0, 20).trim();
          formData.PaymentType = this.state.paymentMethod.toString().toLowerCase() === "Manual ACH".toLowerCase() ? "ACH" : this.state.paymentMethod;
          formData.SalesTax = this.state.salesTaxCheck ? "Yes" : "No";
          formData.IsVendor1099 = this.state.vendorCheck ? "Yes" : "No";
          formData.TaxOrSsnId =
            this.state.taxIdSuffix === null ? "" : this.state.taxIdSuffix?.substring(0, 11).trim();
          formData.IsTaxOrSSN = this.state.taxIDPrefix.replace(" ", "");
          formData.InvoiceDueAfter = this.state.invoicesDuesAfter?.trim();
          formData.CustomerNo = this.state.customerNo?.substring(0, 40).trim();
          formData.Email = this.state.email?.trim();
          formData.CallingOnPage = this.state.pageName === "TransactionPage" ? "Other" : "Invoice";
          formData.CallType = this.state.isNewVendor
            ? "NewVendor"
            : this.state.isNewAddress
              ? "NewAddrNewContract"
              : this.state.isGeneric
                ? "GenericVendor"
                : "ExistsAddrNewContract";

          //console.log(formData);
          registersevice
            .SaveVendorData(formData)
            .then(async (result: any | null) => {
              if (result !== null) {
                if (result.message?.toLowerCase() === "success") {
                  let requestlist: any = {};
                  requestlist.hotelID = this.state.hotelID;
                  requestlist.oprID = 0;
                  requestlist.etype = "";
                  requestlist.vid = 0;
                  if (!this.state.isGeneric) {
                    toast.success(
                      this.state.isNewVendor
                        ? "Vendor created successfully."
                        : "Contract created successfully.",
                      {
                        position: toast.POSITION.BOTTOM_RIGHT,
                      }
                    );
                  }
                  if (this.state.isGeneric && this.state.vendorForCreate === "")
                    this.setState({ vendorForCreate: "Generic Vendor" });

                  try {
                    let vendorContractRequest: any = {};
                    vendorContractRequest.hotelID = this.state.hotelID;
                    vendorContractRequest.vendorType = "Current";
                    vendorContractRequest.VendorId = this.state.lastVid;

                    const response: any = await Promise.all([
                      this.state.isNewContract
                        ? registersevice.GetVendorContract(vendorContractRequest)
                        : null,
                      registersevice.GetVendorlist(requestlist),
                    ]);
                    if (response[1] != null) {
                      this.updateParentState(response[1]); //Update the vendor list to the parent page if new vendor created
                      this.setState(
                        {
                          isListOpen: true,
                          addVendorFooterVisible: true,
                          isNewRequest: false,
                          isContractDetailsOpen: false,
                          lastVType: "",
                          AID: 0,
                          selectedVendor: this.state.vendorForCreate,
                          selectedContract: " (" + this.state.customerNo?.trim() + ")",
                        },
                        () => {
                          if (this.state.isNewVendor || this.state.isGeneric) {
                            const createdVendor: any = response[1]?.filter(
                              (item) =>
                                item.vendorName?.toLowerCase() ===
                                this.state.vendorForCreate?.toLowerCase()
                            );
                            createdVendor[0].accountNo = this.state.customerNo?.trim();
                            this.setState(
                              {
                                isNewVendor: false,
                                isNewContract: false,
                                lastVid: 0,
                                isGeneric: false,
                                selectedVendor: this.state.vendorForCreate,
                                selectedContract: " (" + this.state.customerNo?.trim() + ")",
                              },
                              () => {
                                setTimeout(() => {
                                  this.handleSelectVendor(createdVendor[0]);
                                }, 500);
                              }
                            );
                          } else {
                            let contractDetail: any[] = [];
                            const createdContract: any = response[0]?.filter(
                              (item) =>
                                item.accountNo === this.state.customerNo?.trim()
                            );
                            if (createdContract.length > 0) {
                              let dta: any = {};
                              dta.vendorName = this.state.vendorForCreate?.trim();
                              dta.rowID = createdContract[0].rowID;
                              dta.vid = this.state.lastVid;
                              dta.accountNo = createdContract[0].accountNo;
                              dta.street = createdContract[0].street;
                              dta.city = createdContract[0].city;
                              dta.state = createdContract[0].state;
                              dta.pCode = createdContract[0].pCode;
                              dta.aid = createdContract[0].aid;
                              dta.salestax = createdContract[0].salesTax;
                              dta.paymentType = createdContract[0].paymentType;
                              dta.vendorDays = createdContract[0].vendorDays;
                              dta.postingRule = this.state.postingRule;
                              contractDetail.push(dta);
                              this.setState(
                                {
                                  isNewVendor: false,
                                  isNewContract: false,
                                  lastVid: 0,
                                },
                                () => {
                                  this.handleSelectVendor(contractDetail[0]);
                                  if (
                                    this.state.callFromPage ===
                                    "CreditCardStatementImport"
                                  ) {
                                    this.props.updateCustomerList(
                                      contractDetail[0]
                                    );
                                  }
                                }
                              );
                            }
                          }
                          this.clearFieldValues();
                          this.setState({
                            isNewVendor: false,
                            isNewContract: false,
                            lastVid: 0,
                          });
                        }
                      );
                    }
                  } catch (error) {
                    Utils.toastError((error as any).message, {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                  }
                } else if (result.message?.toLowerCase() === "vendorexists") {
                  this.setState({ loader: false });
                  Utils.toastError("Vendor name already exists.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  });
                } else if (result.message?.toLowerCase() === "samecontractexist") {
                  this.setState({ loader: false });
                  Utils.toastError("Same contract exists for this EHID.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  });
                } else {
                  Utils.toastError(result.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  });
                }
              }
              this.setState({ loader: false });
              resolve();
            })
            .catch((error) => {
              this.setState({ loader: false });
              reject();
            });
        } else {
          Utils.toastError("Total length of City, State and ZIP cannot be greater than 50.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          this.setState({ loader: false });
        }
      } else if (isValid[0] === "vendorName") {
        Utils.toastError("Vendor name should be atleast 3 characters.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        this.setState({ loader: false });
      } else if (isValid[0] === "Required") {
        Utils.toastError("Please fill the required fields.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        this.setState({ loader: false });
      } else if (isValid[0] === "taxIdSuffix") {
        Utils.toastError(
          "Please correct the " + this.state.taxIDPrefix + " format.",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
        this.setState({ loader: false });
      } else if (isValid[0] === "phone") {
        Utils.toastError("Phone number must be between 10 to 20 digits.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        this.setState({ loader: false });
      } else if (isValid[0] === "zip") {
        Utils.toastError("Please fill the required fields.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        this.setState({ loader: false });
      } else if (isValid[0] === "email") {
        Utils.toastError("Email format is not correct.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        this.setState({ loader: false });
      }
    } else {
      $("#vendorname").focus();
      $("#vendorname").addClass("alert-danger");
      Utils.toastError("You can't create a generic vendor.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
  };
}
