import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { Button, Dropdown, Form, Modal, Col, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { ReconcileService as reconcileService } from "../../../Common/Services/ReconcileService";
import { resolve, reject } from "q";
import "./reconcile.scss";
import { Register as registersevice } from "../../../Common/Services/Register";
import { ToastContainer, toast } from "react-toastify";
import { Utils } from "../../../Common/Utilis";
import { ReactPageLoader } from "../../../Common/Components/ReactPageLoader";
import { confirmAlert } from "react-confirm-alert";
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import _ from "lodash";
export class ReconcileDeposits extends React.Component<any, any> {
    private reconcileList: any;
    constructor(props: any) {
        super(props);
        this.reconcileList = React.createRef();
        this.state = {
            ishorizontalScroll: false,
            isGroupDepositsToggleEnabled: "no",
            groupDepositsToggleGroupId: 0,
            groupName: "",
            inlineDepositType: "",
            defaultCoa: [],
            tableData: [],
            selectedRows: [],
            periodValue: this.props.periodValue,
            accper: this.props.accper,
            hidValue: this.props.hidValue,
            acctType: this.props.acctType,
            hidName: this.props.hotelName,
            OprID: this.props.OprID,
            ClMonth: "",
            ClYear: "",
            EndDate: "",
            status: "",
            AccountType: this.props.AccountType,
            adjustmentAmount: "0.00",
            netAmount: "0.00",
            discountPercentage: "0.00",
            totalAmount: "0.00",
            verianceAmount: "0.00",
            transNetAmount: "0.00",
            sortingOrder: "asc",
            sortingField: "date1",
            pageTitle: this.props.pageType,
            isNextCheck: false,
            isInvoiceSlideOut: false,
            isTransactionSlideOut: false,
            isTransferSlideOut: false,
            addTransactionAmount: false,
            addCommissionAmount: false,
            isSummaryEnabled: false,
            isSaveClicked: false,
            isDeleteClicked: false,
            transAmount: "",
            ehidList: [],
            coaList: [],
            isLoader: false,
            isAddAdjustment: false,
            modalTitle: "",
            netDiscount: 0.00,
            sildeoutHid: "",
            sildeoutCode: "",
            maxDate: new Date(),
            adjustmentReq: {
                DepositUniqueno: "",
                UniqueNo: "",
                SplitHid: this.props.hidValue,
                BackHID: this.props.hidValue,
                hotelName: "",
                OprID: 0,
                Date: new Date(new Date(this.props.accper).getFullYear(), new Date(this.props.accper).getMonth() + 1, 0),
                AccPer: "",
                COA: "",
                COAName: "",
                Sign: "",
                Adjustment: "",
                Discount: 0,
                NetAmount: 0,
                Type: "",
                Description: "",
                CLMonth: "",
                CLYear: "",
                AmtType: "",
                CheckNo: "",
                EntryType: "",
                EntryStatus: ""
            },
            BtnStatus: "",
            chunkSize: 200,
            displayGroupName: "",
            isGroup: this.props.isGroup
        };
    }

    componentDidMount() {
        localStorage.setItem("scrollPos", "0");
        let newDate = new Date(this.state.accper);
        let strMonth = Number((newDate).getMonth()) + 1;
        let strYear = (newDate).getFullYear();
        let enddate = strMonth + "/01/" + strYear;
        this.setState({ EndDate: enddate, ClMonth: strMonth, ClYear: strYear }, () => {
            this.getAmexReconcilationData();
        })
    }

    getCOAList = (usedFor, row) => {
        let request: any = {};
        request.UsedFor = usedFor;
        reconcileService.GetSelectedCOAData(request)
            .then(async (result: any | null) => {
                if (result.length > 0) {
                    result.map((items) => ((items["label"] = items.name)));
                    let adjustmentReq = { ...this.state.adjustmentReq }
                    let selectedCOA = result.filter(e => e.isSelected === 1);
                    if (row !== null) {
                        if (usedFor === "Adjustment" && row.adjUniqueno !== null) {
                            selectedCOA = result.filter(e => e.id == row.adjCoa);
                        } else if (usedFor === "Discount" && row.commUniqueno !== null) {
                            selectedCOA = result.filter(e => e.id == row.commCoa);
                        } else {
                            selectedCOA = result.filter(e => e.isSelected === 1);
                        }
                    }
                    if (selectedCOA.length > 0) {
                        adjustmentReq.COA = selectedCOA[0].id;
                        adjustmentReq.COAName = selectedCOA[0].name;
                        this.setState({ defaultCoa: selectedCOA })
                    } else {
                        adjustmentReq.COA = "";
                        adjustmentReq.COAName = "";
                        this.setState({ defaultCoa: [] })
                    }
                    this.setState({
                        coaList: result,
                        adjustmentReq
                    })
                }
                resolve();
            })
            .catch((error) => {
                reject();
            });
    }

    getAccountStatus = () => {
        let request: any = {};
        request.Hid = this.state.hidValue;
        request.Type = this.state.AccountType;
        request.OprID = this.state.OprID;
        request.Accper = this.state.accper;
        reconcileService.GetReconcileStatusOtherWithdrawal(request)
            .then(async (result: any | null) => {
                if (result.length > 0) {
                    this.setState({ status: result[0].result })
                }
                resolve();
            })
            .catch((error) => {
                reject();
            });
    }

    GetGroupReconcileStatus = () => {
        let request: any = {};
        request.Hid = this.state.hidValue;
        request.Oprid = this.state.OprID;
        request.Accper = this.state.accper;
        request.Type = this.state.groupName;
        request.PageName = "NewGroup";
        reconcileService.GetGroupReconcileStatus(request)
            .then(async (result: any | null) => {
                if (result.length > 0) {
                    this.setState({ status: result[0]?.status })
                }
                resolve();
            })
            .catch((error) => {
                reject();
            });
    }

    getAmexReconcilationData = () => {
        if (this.state.isGroup) {
            this.getAccountStatus();
            this.getGroupDepositsData();
        } else {
            this.GetReconcileDepositGroupToggleDetail();
        }
    }

    getGroupDepositsData = () => {
        this.setState({ isLoader: true })
        let request: any = {};
        request.SplitHid = this.state.hidValue;
        request.Type = this.state.AccountType;
        request.OprID = this.state.OprID;
        request.CLmonth = this.state.ClMonth;
        request.CLyear = this.state.ClYear;
        reconcileService.GetGroupReconcileData(request)
            .then(async (result: any | null) => {
                if (result != null && result.length > 0) {
                    result.forEach(element => {
                        element.isReconcile = element.isReconcile === 1 ? true : false;
                        element.amount = Number(Number(element.amount).toFixed(2));
                        element.netAmount = Number(Number(element.netAmount).toFixed(2));
                        element.date1 = new Date(element.date);
                        element.type = "";
                        element.clMonth = element.clMonth === null ? "" : element.clMonth;
                    });
                    this.calculateHeaderData(result, "onload")
                }
                else {
                    this.setState({ tableData: [], isLoader: false });
                }
                resolve();
            })
            .catch((error) => {
                this.setState({ tableData: [], isLoader: false }, () => {
                    Utils.toastError(error, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        containerId: "Reconcile",
                    });
                });
                reject();
            });
    }

    getDepositsData = () => {
        this.setState({ isLoader: true })
        let request: any = {};
        request.SplitHid = this.state.hidValue;
        request.Type = this.state.AccountType;
        request.OprID = this.state.OprID;
        request.CLmonth = this.state.ClMonth;
        request.CLyear = this.state.ClYear;
        request.isGroupDeposit = this.state.isGroupDepositsToggleEnabled;
        reconcileService.GetAmexReconcileData(request)
            .then(async (result: any | null) => {
                if (result != null && result.length > 0) {
                    result.forEach(element => {
                        element.isReconcile = element.isReconcile === 1 ? true : false;
                        element.amount = Number(Number(element.amount).toFixed(2));
                        element.netAmount = Number(Number(element.netAmount).toFixed(2));
                        element.discount = Number(Number(element.discount).toFixed(2));
                        element.date1 = new Date(element.date);
                        element.adjustment = Number(Number(element.adjustment).toFixed(2));
                        element.description1 = element.description.toString().toLowerCase().replace("|", "!").replace("@", "!").replace("\\", "!").replace("[", "!").replace("]", "!").replace("?", "!").replace("`", "!").replace("^", "!").replace("_", "!").replace("~", "!").replace("{", "!").replace("}", "!").replace(">", "!").replace("<", "!").replace(";", "!").replace(":", "!").replace("=", "!");
                        element.clMonth = element.clMonth === null ? "" : element.clMonth;
                    });
                    this.calculateHeaderData(result, "onload")
                }
                else {
                    this.setState({ tableData: [], isLoader: false });
                }
                resolve();
            })
            .catch((error) => {
                this.setState({ tableData: [], isLoader: false }, () => {
                    Utils.toastError(error, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        containerId: "Reconcile",
                    });
                });
                reject();
            });
    }

    calculateHeaderData = (result, type) => {
        let data = result.filter(e => e.isReconcile);
        let netAmount = "0.00";
        let netDiscount = "0.00";
        let discountPercentage = "0.00";
        let adjustmentAmount = "0.00";
        let totalAmount = "0.00";
        if (this.state.isGroup) {
            data.forEach(element => {
                if (element.orderNo === "2") {
                    totalAmount = (Number(totalAmount) + Number(element.amount)).toFixed(2);
                }
            });
            this.setState({ totalAmount, isLoader: false });
        } else {
            data.forEach(element => {
                if (element.orderNo === "2") {
                    netAmount = (Number(netAmount) + Number(element.netAmount)).toFixed(2);
                    totalAmount = (Number(totalAmount) + Number(element.amount)).toFixed(2);
                    netDiscount = (Number(netDiscount) + Number(element.discount)).toFixed(2);
                    adjustmentAmount = (Number(adjustmentAmount) + Number(element.adjustment)).toFixed(2);
                }
                discountPercentage = ((Number(netDiscount) * 100) / Number(netAmount)).toFixed(2);
            });
            this.setState({ netAmount, discountPercentage, netDiscount, totalAmount, adjustmentAmount, transNetAmount: Number(result[0].transAmount), isLoader: false }, () => {
                this.setState({ verianceAmount: Number(this.state.transNetAmount) !== 0 ? (Number(this.state.netAmount) - Number(this.state.transNetAmount)).toFixed(2) : this.state.verianceAmount })
            });
        }

        if (type === "onload") {
            this.sortTableData(result, this.state.sortingField);
        }
        setTimeout(() => {
            let isHozontalScroll = this.reconcileList.current?.childNodes[0];
            if (isHozontalScroll.scrollWidth > isHozontalScroll.clientWidth) {
                this.setState({ ishorizontalScroll: true });
            } else {
                this.setState({ ishorizontalScroll: false });
            }
            this.reconcileList.current?.childNodes[0].scrollTo({ top: localStorage.getItem("scrollPos"), behavior: 'smooth', });
        }, 100);
    }

    hideDepositModal = () => {
        this.props.handleReconcileDepositModal(false)
    }

    onSingleReconcile = (event, rowIndex, rowInfo) => {

        let selectedRows: any = [];
        let data = [...this.state.tableData];
        this.setState({ BtnStatus: "Single" });
        if (event.shiftKey) {
            if (this.state.selectedRows.length > 0) {
                if (this.state.selectedRows[0].index !== rowIndex) {
                    if (Number(this.state.selectedRows[0].index) < Number(rowIndex)) {
                        for (let i = this.state.selectedRows[0].index; i <= rowIndex; i++) {
                            if (data[i].type.toLowerCase() !== "nothing") {
                                selectedRows.push(data[i]);
                            }
                        }
                    } else {
                        for (let i = rowIndex; i <= this.state.selectedRows[0].index; i++) {
                            if (data[i].type.toLowerCase() !== "nothing") {
                                selectedRows.push(data[i]);
                            }
                        }
                    }
                } else {
                    this.onSingleRowEvent(rowInfo);
                }
            } else {
                rowInfo.index = rowIndex;
                selectedRows.push(rowInfo);
            }
            this.setState({ selectedRows }, () => {
                if (this.state.selectedRows.length > 2) {
                    this.saveReconcileData(this.state.selectedRows);
                }
            })

        } else {
            this.onSingleRowEvent(rowInfo);
        }
    }

    onSingleRowEvent(rowInfo) {
        this.setState({ selectedRows: [rowInfo] }, () => {
            this.saveReconcileData(this.state.selectedRows);
        })
    }

    onReconcileAll = () => {
        let msg = "";
        let msg2 = "";
        let title = "";
        let BtnStatus = "";
        let selectedRows: any = [];
        let data = [...this.state.tableData];
        if (this.state.tableData.some(e => !e.isReconcile && e.orderNo === "2")) {
            selectedRows = data.filter(e => e.isReconcile === false && e.orderNo === "2" && e.islocked === 0);
        } else {
            selectedRows = data.filter(e => !e.isReconcile === false && e.orderNo === "2" && e.islocked === 0);
        }
        let bankSyncData = selectedRows.some(e => e.bankSyncID !== null && e.bankSyncID !== "" && e.bankSyncID !== 0);

        if (this.state.tableData.some(e => !e.isReconcile && e.orderNo === "2")) {
            title = "Reconcile All";
            BtnStatus = "ReconcileAll";
            msg = "Are you sure you want to reconcile all transactions?";
        } else {
            title = "Unreconcile All";
            BtnStatus = "UnreconcileAll";
            if (bankSyncData) {
                msg = "Are you sure you would like to unreconcile all transactions?";
                msg2 = "Some transactions are grouped with 1 or more transactions when they were reconciled. Unreconciling these transactions will unreconcile any associated transactions.";
            } else {
                msg = "Are you sure you would like to unreconcile all transactions?";
            }
        }
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-overlay">
                        <div className="react-confirm-alert">
                            <div className="react-confirm-alert-body">
                                <h1>{title}</h1>
                                <div className="msg">{msg}</div>
                                {msg2 !== "" && <div className="msg">{msg2}</div>}
                                <div className="react-confirm-alert-button-group">
                                    <button onClick={() => {
                                        this.onReconcileAllTransactions(selectedRows, BtnStatus);
                                        onClose();
                                    }}>Confirm</button>
                                    <button onClick={onClose}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        });
    };

    onReconcileAllTransactions = (selectedRows, BtnStatus) => {
        this.setState({ isSummaryEnabled: true, BtnStatus: BtnStatus }, () => {
            this.saveReconcileTransaction(selectedRows);
        });
    }

    isModalShowUp = (selectedRows) => {
        confirmAlert({
            title: "Reconcile confirmation",
            message:
                "This transaction was grouped with 1 or more transactions when this was reconciled. Unreconciling this transaction will unreconcile any associated transactions.",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => this.saveReconcileTransaction(selectedRows),
                },
                {
                    label: "No",
                    onClick: () => this.setState({ isSummaryEnabled: false }, () => { reject() }),
                },
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
        });
    };

    saveReconcileData(selectedRows) {
        if (selectedRows.length > 0) {
            let bankSyncData = selectedRows.some(e => e.bankSyncID !== null && e.bankSyncID !== 0);
            if (bankSyncData) {
                this.isModalShowUp(selectedRows);
            } else {
                this.saveReconcileTransaction(selectedRows);
            }
        }
    }

    saveReconcileTransaction(selectedRows) {
        localStorage.setItem("scrollPos", this.reconcileList.current?.childNodes[0].scrollTop);
        this.setState({ selectedRows: [] });
        let tableData = [...this.state.tableData];
        let requestArr: any = [];
        selectedRows.forEach(element => {
            let request: any = {};
            request.UniqueNo = element.uniqueno;
            request.CkNo = element.ckNo;
            request.Clmonth = element.isReconcile ? "" : this.state.ClMonth;
            request.ClYear = element.isReconcile ? "" : this.state.ClYear;
            request.acctType = this.props.acctType;
            request.Etype = "Deposit Audit";
            request.Type = this.state.AccountType;
            request.Date = element.date;
            request.Hid = element.backHid;
            request.Oprid = this.props.OprID;
            requestArr.push(request);
        });
        ///==== For update reconcile date in table state=====////
        selectedRows.forEach(element => {
            if (element.bankSyncID === null || element.bankSyncID === 0) {
                let index = tableData.indexOf(element);
                element.isReconcile = !element.isReconcile;
                element.clMonth = element.isReconcile ? this.state.periodValue.replace("/", "-") : "";
                tableData[index] = element;
            } else {
                let data = tableData.filter(e => e.bankSyncID === element.bankSyncID);
                data.forEach(ele => {
                    let index = tableData.indexOf(ele);
                    if (ele.ckNo === 'ADJ') {
                        tableData.splice(index, 1);
                    } else {
                        ele.isReconcile = !ele.isReconcile;
                        ele.clMonth = ele.isReconcile ? this.state.periodValue.replace("/", "-") : "";
                        tableData[index] = ele;
                    }
                });
            }
        });
        this.setState({ tableData: [] }, () => {
            this.calculateHeaderData(tableData, "reload");
            this.setState({ tableData: tableData }, () => {
                if (requestArr.length > 0) {
                    this.saveReconcileDataAPI(requestArr, "Reconcile");
                }
            })
        })
        ///==== For update reconcile date in table state=====/////

    }

    // sliceIntoChunks(arr, chunkSize) {
    //     for (let i = 0; i < arr.length; i += chunkSize) {
    //         let chunk = arr.slice(i, i + chunkSize);
    //         this.saveReconcileDataAPI(chunk, "Reconcile");
    //     }
    // }

    saveReconcileDataAPI(requestArr, BtnEvent) {

        let request: any = {};

        request.Hid = this.state.hidValue;
        request.Oprid = this.state.OprID;
        request.Accper = this.state.accper;
        request.Type = this.state.isGroup ? this.state.pageTitle : this.state.groupName;
        request.amount = Utils.removeInvaildCharFromAmount("0");
        request.PageName = this.state.isGroup ? "AllGroup" : "NewGroup";
        request.BtnStatus = this.state.BtnStatus;
        reconcileService.SaveReconcileStatus(request)
            .then(async (result: any | null) => {
                if (result.message === "Success") {
                    this.setState({ status: result.result[0].result });
                    if (BtnEvent == "Reconcile") {
                        this.SaveReconcileDetails(requestArr);
                    }
                    else {
                        if (this.state.isNextCheck) {
                            this.props.handleNextbutton();
                        } else {
                            let msg = this.state.status === "Unreconciled" ? "Account unreconciled successfully." : "Account reconciled successfully.";
                            toast.success(msg, {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                containerId: "Reconcile",
                            });
                        }
                    }
                } else {
                    Utils.toastError("Something went wrong.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        containerId: "Reconcile",
                    });
                }
                resolve();
            })
            .catch((error) => {
                reject();
            });

    }

    SaveReconcileDetails(requestArr) {
        reconcileService.SaveReconcileAmex(requestArr)
            .then(async (result: any | null) => {
                if (result !== null) {
                    if (result.saveStatus === "Success") {
                        if (this.state.isSummaryEnabled) {
                            let tabData = [...this.state.tableData];
                            this.setState({ isSummaryEnabled: false }, () => {
                                this.setState({ tableData: [] }, () => {
                                    this.setState({ tableData: tabData })
                                })
                            });
                        }
                    } else {
                        Utils.toastError("Something went wrong.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            containerId: "Reconcile",
                        });
                        this.setState({ isSummaryEnabled: false });
                    }
                } else {
                    Utils.toastError("Something went wrong.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        containerId: "Reconcile",
                    });
                    this.setState({ isSummaryEnabled: false });
                }
                resolve();
            })
            .catch((error) => {
                Utils.toastError("Something went wrong.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "Reconcile",
                });
                this.setState({ isSummaryEnabled: false });
                reject();
            });
    }
    getDropDownArrowIcon() {
        return (
            <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.28122e-05 1.19715C3.28122e-05 0.889454 0.253262 0.653898 0.543288 0.653898C0.688297 0.653898 0.833313 0.708365 0.923855 0.816593L4.0023 4.02162L7.08074 0.816593C7.2979 0.599435 7.6424 0.599435 7.84116 0.816593C8.04064 1.01608 8.05831 1.34145 7.85955 1.55933L4.40046 5.18103C4.20098 5.39819 3.85721 5.39819 3.64005 5.19943L3.62166 5.18103L0.145009 1.55933C0.054467 1.46879 0 1.34217 0 1.19716L3.28122e-05 1.19715Z" fill="#6A6E73" />
            </svg>
        );
    }

    getDropDownHomeIcon() {
        return (
            <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.466553 4.31579V7.19369H1.22661V11.5087H0.466553V13.6666H1.22661H3.50677H5.02688H7.30704H8.82715H11.1073H12.6274L14.9076 13.6674V13.6666H15.6676V11.5087H14.9076V7.19369H15.6676V4.31579L8.06709 1.90735e-05L0.466553 4.31579ZM3.50677 11.5087V7.19369H5.02688V11.5087H3.50677ZM7.30704 11.5087V7.19369H8.82715V11.5087H7.30704ZM12.6274 11.5087H11.1073V7.19369H12.6274V11.5087ZM9.5872 4.31579C9.5872 5.10989 8.90619 5.75438 8.06709 5.75438C7.228 5.75438 6.54699 5.10989 6.54699 4.31579C6.54699 3.52169 7.228 2.8772 8.06709 2.8772C8.90619 2.8772 9.5872 3.52169 9.5872 4.31579Z" fill="white" />
            </svg>
        );
    }

    handleSaveButton = (actionType, value) => {
        let status;
        if (actionType === "transaction") {
            status = this.state.status;
        } else {
            status = this.state.status === "Unreconciled" ? "Reconciled" : "Unreconciled";
        }
        let request: any = {};
        if (actionType === "transaction") {
            request.Hid = this.state.hidValue;
            request.Oprid = this.state.OprID;
            request.Accper = this.state.accper;
            request.Type = this.state.AccountType;
            request.Etype = "Other";
            request.TransType = "Operating"
            request.EntryConcile = "Yes";
            request.Status = status;
            request.actionType = actionType;
            request.amount = Utils.removeInvaildCharFromAmount(value);
            reconcileService.SaveCompleteOtherWithdrawal(request)
                .then(async (result: any | null) => {
                    if (result.saveStatus === "Success") {
                        this.setState({ status: request.Status }, () => {
                            if (actionType === "transaction") {
                                toast.success("Statement total added successfully.", {
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                    containerId: "Reconcile",
                                });
                                this.getAmexReconcilationData();
                            } else {
                                if (this.state.isNextCheck) {
                                    this.props.handleNextbutton();
                                } else {
                                    let msg = this.state.status === "Unreconciled" ? "Account unreconciled successfully." : "Account reconciled successfully.";
                                    toast.success(msg, {
                                        position: toast.POSITION.BOTTOM_RIGHT,
                                        containerId: "Reconcile",
                                    });
                                }
                            }
                        })
                    } else {
                        Utils.toastError("Something went wrong.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            containerId: "Reconcile",
                        });
                    }
                    resolve();
                })
                .catch((error) => {
                    reject();
                });
        }
        else {
            let BtnStatus = this.state.status === "Unreconciled" ? "Mark as Complete" : "Mark as Incomplete";
            this.setState({ BtnStatus: BtnStatus }, () => {
                this.saveReconcileDataAPI([], "MarkAsComplete")
            });
        }
    }

    sortTableData = (data: any, fieldName) => {
        localStorage.setItem("scrollPos", this.reconcileList.current?.childNodes[0].scrollTop);
        if (fieldName === "date1") {
            if (this.state.sortingOrder === "asc") {
                data = _.orderBy(data, ['orderNo', fieldName, 'amount'], ['asc', 'asc', 'asc'])
            } else if (this.state.sortingOrder === "desc") {
                data = _.orderBy(data, ['orderNo', fieldName, 'amount'], ['asc', 'desc', 'desc'])
            }
        } else {
            if (this.state.sortingOrder === "asc") {
                data = _.orderBy(data, ['orderNo', fieldName, 'date1'], ['asc', 'asc', 'asc'])
            } else if (this.state.sortingOrder === "desc") {
                data = _.orderBy(data, ['orderNo', fieldName, 'date1'], ['asc', 'desc', 'desc'])
            }
        }
        this.setState({ tableData: data }, () => {
            this.reconcileList.current?.childNodes[0].scrollTo({ top: localStorage.getItem("scrollPos"), behavior: 'smooth', });
        });
    }

    handleCheckNext = (e) => {
        this.setState({ isNextCheck: e.target.checked })
    }


    hideSlideOut(isSave) {
        this.setState({ isInvoiceSlideOut: false, isTransactionSlideOut: false, isTransferSlideOut: false, }, () => {
            if (isSave) {
                this.getAmexReconcilationData();
            }
        });
    }

    OpenInvoiceSlideOut = (e, newrow, rowIndex, rowType) => {
        if (!this.state.isBgBlue) {
            let row: any = {};
            row.hid = newrow.hotelid;
            row.etype = "Invoice";
            row.vendor = newrow.vendor;
            row.backHIDCode = newrow.lettercode;
            row.vid = newrow.vid;
            row.oprId = newrow.oprID;
            row.uniqueno = newrow.uniqueno;
            row.checkno = newrow.ckno;
            row.series = newrow.series;
            row.invPostFix = newrow.postFix;
            row.groupID = newrow.groupID;
            this.hideSlideOut(false);
            this.handlerowEvents(e, row, rowIndex, rowType);
        }
    }

    handlerowEvents = (e: any, row: any, rowIndex: any, rowType) => {
        let registerRequest1 = { ...this.state.registerRequest }
        let etype = '';
        etype = this.getEtype(row.etype, rowType);
        if (etype === "payment") {
            this.setState({
                trUniqueNo: row.uniqueno, pageType: etype, sildeoutHid: Number(row.hid),
                registerRequest: registerRequest1, selectedRow: row,
            }, () => {
                this.setState({ isTransactionSlideOut: true });
            });
        } else {
            this.setState({
                selectedRow: row, pageType: etype,
                trUniqueNo: row.uniqueno, registerRequest: registerRequest1, vid: row.vid, oprId: row.oprId,
                isInvoiceSlideOut: true, sildeoutHid: row.hid, sildeoutCode: row.backHIDCode
            })
        }
    }

    getEtype(mtype, rowType) {
        let etype = '';
        etype = registersevice.getEtype(mtype, rowType);
        return etype;
    }

    onCloseAdjModal = () => {
        this.setState({ isAddAdjustment: false, isSaveClicked: false, isDeleteClicked: false })
    }

    onAddAdjustment = (type, row) => {
        let uniqueNo = "", coa = "";
        if (row !== null && type === "Adjustment") {
            if (row.adjUniqueno === null) {
                uniqueNo = "";
                coa = "";
            } else {
                uniqueNo = row.adjUniqueno.split("-")[0];
                coa = row.adjUniqueno.split("-")[1];
            }
            if (row.ckNo.toLowerCase() === "adj") {
                coa = row.coa;
            }
            row["adjCoa"] = coa;

        } else if (row !== null && type === "Discount") {
            if (row.commUniqueno === null) {
                uniqueNo = "";
                coa = "";
            } else {
                uniqueNo = row.commUniqueno.split("-")[0];
                coa = row.commUniqueno.split("-")[1];
            }
            if (row.ckNo.toLowerCase() === "adj") {
                coa = row.coa;
            }
            row["commCoa"] = coa;

        }

        localStorage.setItem("scrollPos", this.reconcileList.current?.childNodes[0].scrollTop);
        this.setState({
            maxDate: new Date(new Date(this.state.accper).getFullYear(), new Date(this.state.accper).getMonth() + 1, 0),
            inlineDepositType: row !== null ? row.type : this.state.AccountType,
            adjustmentReq: {
                DepositUniqueno: row !== null ? row.uniqueno : "",
                UniqueNo: uniqueNo,
                SplitHid: row !== null ? row.backHid : Number(this.state.hidValue),
                BackHID: row !== null ? row.backHid : Number(this.state.hidValue),
                hotelName: "",
                OprID: 0,
                Date: row !== null ? new Date(row.date) : new Date(new Date(this.state.accper).getFullYear(), new Date(this.state.accper).getMonth() + 1, 0),
                AccPer: "",
                COA: "",
                COAName: "",
                Sign: "1",
                Adjustment: row !== null ? row.adjustment === null ? Utils.currencyFormat(0) : Utils.currencyFormat(row.adjustment) : Utils.currencyFormat(0),
                Discount: row !== null ? row.discount === null ? Utils.currencyFormat(0) : Utils.currencyFormat(row.discount) : Utils.currencyFormat(0),
                NetAmount: row !== null ? type === "Adjustment" ? row.adjustment : row.discount : 0,
                Type: "",
                Description: row !== null ? type === "Adjustment" ? row.adjDesc : row.commDesc : "",
                CLMonth: "",
                CLYear: "",
                AmtType: type,
                CheckNo: "",
                EntryType: "",
                EntryStatus: row !== null ? "Update" : "",
            }
        }, () => {
            if (type === "Adjustment") {
                this.setState({ modalTitle: "Add Adjustment" })
            } else {
                this.setState({ modalTitle: "Add Commission" })
            }
            this.getCOAList(type, row);
        })
        setTimeout(() => {
            this.setState({ isAddAdjustment: !this.state.isAddAdjustment, isSaveClicked: false, isDeleteClicked: false })
        }, 200);
    }

    toggleAddAmountPP = (toggle) => {
        this.setState({ transAmount: Utils.currencyFormat(Number(this.state.transNetAmount).toFixed(2)) }, () => {
            this.setState({ addTransactionAmount: toggle })
        })
    }

    toggleAddCommissionAmountPP = (toggle) => {
        this.setState({ addCommissionAmount: toggle })
    }

    onAddTransactionAmount = () => {
        if (this.state.transAmount.length > 0) {
            this.handleSaveButton("transaction", this.state.transAmount);
            this.toggleAddAmountPP(false)
        } else {
            Utils.toastError("Please enter transaction amount.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "Reconcile",
            });
        }
    }

    handleAmount = (e) => {
        let newInputVal = Utils.removeInvaildCharFromAmount(e.target.value, 10);
        e.target.value = newInputVal;
        const rx_live = /^[-]?\d*(?:[.]\d*)?$/;
        let amount = this.state.transAmount;
        if (rx_live.test(e.target.value)) {
            if (e.target.value.includes(".")) {
                const timeArray = (e.target.value)?.split(".");
                if (timeArray[0].length <= 8 && timeArray[1].length <= 2) {
                    amount = e.target.value;
                }
            } else {
                if (e.target.value.length <= 8) {
                    amount = e.target.value;
                }
            }
        } else if (e.target.value.includes(".") && e.target.value.length === 1) {
            amount = e.target.value;
        }
        this.setState({ transAmount: amount })
    }

    handleEndBalBlur = (event) => {
        let amount = "0.00";
        amount = this.state.transAmount;
        if (!isNaN(event.target.value)) {
            let endBal = Utils.currencyFormat(amount);
            amount = endBal;
        } else {
            amount = Utils.currencyFormat(0);
        }
        if (amount === "-$0.00") {
            amount = "$0.00";
        }
        this.setState({ transAmount: amount });
    }

    onEndBalFocus = (event: any) => {
        let newval = Utils.removecurrencyFormat(event.target.value);
        let amount = newval !== "0.00" ? newval : "";
        this.setState({ transAmount: amount });
    }

    dateFormat = (date) => {
        let newDate = new Date(date);
        let strday = Number((newDate).getDate());
        let strMonth = Number((newDate).getMonth()) + 1;
        let strYear = (newDate).getFullYear();
        return strMonth + "/" + strday + "/" + strYear;
    }

    getAccountPeriodStatus = (hid, mode, EntryStatus) => {
        this.setState({ isSaveClicked: true, isDeleteClicked: true });
        let request: any = {};
        request.hotelID = hid;
        request.Type = this.state.AccountType;
        request.OprID = this.state.OprID;
        request.Accperiod = this.state.accper;
        reconcileService.GetHotelStatusAccPer(request)
            .then(async (result: any | null) => {
                if (result.length > 0) {
                    if (result[0].status === "1") {
                        if (mode === "del") {
                            Utils.toastError("Record cannot be delete in inactive period.", {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                containerId: "Reconcile",
                            });
                            this.setState({ isSaveClicked: false, isDeleteClicked: false });
                            return false;
                        } else {
                            Utils.toastError("Record cannot be saved in inactive period.", {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                containerId: "Reconcile",
                            });
                            this.setState({ isSaveClicked: false, isDeleteClicked: false });
                            return false;
                        }
                    } else {
                        if (mode === "del") {
                            this.adjustmentAction("delete", EntryStatus);
                        } else {
                            this.adjustmentAction("save", EntryStatus);
                        }
                    }
                } else {
                    return false;
                }
                resolve();
            })
            .catch((error) => {
                Utils.toastError(error, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "Reconcile",
                });
                this.setState({ isSaveClicked: false, isDeleteClicked: false });
                reject();
            });
    }

    handleSelectedCoa(e) {
        this.setState({ defaultCoa: e }, () => {
            if (e.length > 0) {
                this.handleAddAdjustmentVal(e[0], "coa", "")
            } else {
                this.handleAddAdjustmentVal({ id: "", name: "" }, "coa", "")
            }
        })
    }

    handleAddAdjustmentVal = (item, type, EntryStatus) => {
        let adjustmentReq = { ...this.state.adjustmentReq }

        if (type === "save") {
            if (this.isValidAmount()) {
                this.getAccountPeriodStatus(adjustmentReq.BackHID, "adj", EntryStatus)
            }
        } else if (type === "delete") {
            this.getAccountPeriodStatus(adjustmentReq.BackHID, "del", EntryStatus)
        } else {
            if (type === "coa") {
                adjustmentReq.COA = item.id;
                adjustmentReq.COAName = item.name;
            }

            if (type === "date") {
                adjustmentReq.Date = new Date(item);
            }

            if (type === "amount") {
                let e = item;
                let newInputVal = Utils.removeInvaildCharFromAmount(e.target.value, 10);
                e.target.value = newInputVal;
                const rx_live = /^[-]?\d*(?:[.]\d*)?$/;
                let amount = this.state.modalTitle === "Add Adjustment" ? adjustmentReq.Adjustment : adjustmentReq.Discount;
                if (rx_live.test(e.target.value)) {
                    if (e.target.value.includes(".")) {
                        const timeArray = (e.target.value)?.split(".");
                        if (timeArray[0].length <= 8 && timeArray[1].length <= 2) {
                            amount = e.target.value;
                        }
                    } else {
                        if (e.target.value.length <= 8) {
                            amount = e.target.value;
                        }
                    }
                } else if (e.target.value.includes(".") && e.target.value.length === 1) {
                    amount = e.target.value;
                }
                if (this.state.modalTitle === "Add Adjustment") {
                    adjustmentReq.Adjustment = amount;
                    adjustmentReq.NetAmount = amount;
                } else {
                    adjustmentReq.Discount = amount;
                    adjustmentReq.NetAmount = amount;
                }

            }

            if (type === "amtblur") {
                let amount = "";
                let e = item;
                if (this.state.modalTitle === "Add Adjustment") {
                    amount = adjustmentReq.Adjustment;
                } else {
                    amount = adjustmentReq.Discount;
                }

                if (!isNaN(e.target.value)) {
                    let endBal = Utils.currencyFormat(amount);
                    amount = endBal;
                } else {
                    amount = Utils.currencyFormat(0);
                }
                if (amount === "-$0.00") {
                    amount = "$0.00";
                }

                if (this.state.modalTitle === "Add Adjustment") {
                    adjustmentReq.Adjustment = amount;
                    adjustmentReq.NetAmount = amount;
                } else {
                    adjustmentReq.Discount = amount;
                    adjustmentReq.NetAmount = amount;
                }
            }

            if (type === "amtfocus") {
                let amount = "";
                let e = item;
                let newval = Utils.removecurrencyFormat(e.target.value);
                amount = newval;

                if (this.state.modalTitle === "Add Adjustment") {
                    if (newval !== "0.00") {
                        adjustmentReq.Adjustment = amount;
                    } else {
                        adjustmentReq.Adjustment = "";
                    }
                    adjustmentReq.NetAmount = amount;
                } else {
                    if (newval !== "0.00") {
                        adjustmentReq.Discount = amount;
                    } else {
                        adjustmentReq.Discount = "";
                    }
                    adjustmentReq.NetAmount = amount;
                }
            }

            if (type === "description") {
                adjustmentReq.Description = item.target.value;
            }

            this.setState({ adjustmentReq });
        }

    }

    adjustmentAction(type, EntryStatus) {
        let adjustmentReq = { ...this.state.adjustmentReq }
        adjustmentReq.OprID = this.state.OprID;
        adjustmentReq.AccPer = this.state.accper;
        adjustmentReq.Type = this.state.inlineDepositType;
        adjustmentReq.CLMonth = this.state.ClMonth;
        adjustmentReq.CLYear = this.state.ClYear;
        adjustmentReq.EntryType = "Deposits Audit";
        adjustmentReq.Sign = "1";
        adjustmentReq.EntryStatus = type === "delete" ? "Delete" : adjustmentReq.EntryStatus === "" ? EntryStatus : adjustmentReq.EntryStatus;
        adjustmentReq.acctType = this.state.acctType;
        adjustmentReq.Date = this.dateFormat(new Date(adjustmentReq.Date));
        adjustmentReq.Adjustment = Utils.removecurrencyFormat(adjustmentReq.Adjustment);
        adjustmentReq.NetAmount = Utils.removecurrencyFormat(adjustmentReq.NetAmount);
        adjustmentReq.Discount = Utils.removecurrencyFormat(adjustmentReq.Discount);

        reconcileService.AddAdjustmentsWithdrawal(adjustmentReq)
            .then(async (result: any | null) => {
                if (result.saveStatus === "Success") {
                    let msg = "";
                    if (result.messageCode === "INSERT") {
                        msg = this.state.modalTitle === "Add Adjustment" ? "Adjustment added successfully." : "Commission added successfully.";
                        toast.success(msg, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            containerId: "Reconcile",
                        });
                    } else if (result.messageCode === "UPDATE") {
                        if (adjustmentReq.UniqueNo === "") {
                            msg = this.state.modalTitle === "Add Adjustment" ? "Adjustment added successfully." : "Commission added successfully.";
                        } else {
                            msg = this.state.modalTitle === "Add Adjustment" ? "Adjustment updated successfully." : "Commission updated successfully.";
                        }
                        toast.success(msg, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            containerId: "Reconcile",
                        });
                    } else if (result.messageCode === "DELETE") {
                        msg = this.state.modalTitle === "Add Adjustment" ? "Adjustment deleted successfully." : "Commission deleted successfully.";
                        toast.success(msg, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            containerId: "Reconcile",
                        });
                    }

                    this.onAddAdjustment("Adjustment", null);
                    this.getAmexReconcilationData();
                } else {
                    Utils.toastError("Something went wrong.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        containerId: "Reconcile",
                    });
                }
                resolve();
            })
            .catch((error) => {
                reject();
            });
    }

    isValidAmount = () => {
        let adjustmentReq = { ...this.state.adjustmentReq }
        if (adjustmentReq.SplitHid === 0) {
            Utils.toastError("Please select hotel.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "Reconcile",
            });
            return false;
        }

        if (adjustmentReq.COA === 0 || adjustmentReq.COA === "") {
            Utils.toastError("Please select COA.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "Reconcile",
            });
            return false;
        }

        if (adjustmentReq.Date === "") {
            Utils.toastError("Please select date.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "Reconcile",
            });
            return false;
        }

        if ((this.state.modalTitle === "Add Adjustment" ? adjustmentReq.Adjustment : adjustmentReq.Discount) === "") {
            Utils.toastError("Please enter amount.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "Reconcile",
            });
            return false;
        }

        if ((this.state.modalTitle === "Add Adjustment" ? Number(Utils.removecurrencyFormat(adjustmentReq.Adjustment)) : Number(Utils.removecurrencyFormat(adjustmentReq.Discount))) === Number("0.00")) {
            Utils.toastError("Please enter valid amount.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "Reconcile",
            });
            return false;
        }

        // if (adjustmentReq.Description.trim().length === 0) {
        //     Utils.toastError("Please enter description.", {
        //         position: toast.POSITION.BOTTOM_RIGHT,
        //         containerId: "Reconcile",
        //     });
        //     return false;
        // }
        return true;
    }

    inlineAddadjustment = (row) => {
        if (row.flag.toLowerCase() !== "Adjustment-V".toLowerCase()) {
            if (row.islocked === 0 && this.state.status === "Unreconciled" && !row.isReconcile) {
                if (row.adj === "Edit" ? row.flag === "Discount" ? false : true : true) {
                    this.onAddAdjustment("Adjustment", row)
                }
            }
        }
    }
    inlineDiscount = (row) => {
        if (row.flag.toLowerCase() !== "Adjustment-V".toLowerCase()) {
            if (row.islocked === 0 && this.state.status === "Unreconciled" && !row.isReconcile) {
                if (row.adj === "Edit" ? row.flag === "Adjustment" ? false : true : true) {
                    this.onAddAdjustment("Discount", row)
                }
            }
        }

    }

    exportData = () => {

        let exportData: any = {}
        exportData.tableData = this.state.tableData;
        exportData.type = this.state.AccountType;
        exportData.groupType = this.state.isGroup ? "SummaryGroup" : this.state.isGroupDepositsToggleEnabled.toLowerCase() === "yes" ? "GroupToggleOn" : this.state.groupDepositsToggleGroupId !== 0 && this.state.isGroupDepositsToggleEnabled.toLowerCase() === "no" ? "GroupToggleOff" : "none";
        reconcileService.depositReconcileExportData(exportData)
            .then(async (result: any | null) => {
                if (result.saveStatus === "Success") {

                } else {
                    Utils.toastError("Something went wrong.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        containerId: "Reconcile",
                    });
                }
                resolve();
            })
            .catch((error) => {
                reject();
            });
    }
    sortImage = (order) => {
        if (!order) return (
            <span className="ascending">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z" fill="#1D1E20" />
                </svg>
            </span>
        );
        else if (order === 'asc') return (
            <span className="ascending">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z" fill="#1D1E20" />
                </svg>
            </span>
        );
        else if (order === 'desc') return (
            <span className="descending">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z" fill="#1D1E20" />
                </svg>
            </span>
        );
        return null;
    }

    handleSortByColumn = (field) => {
        this.setState({
            sortingField: field,
            sortingOrder: this.state.sortingOrder === "asc" ? "desc" : "asc",
        }, () => {
            this.sortTableData(this.state.tableData, this.state.sortingField);
        });
    }

    GetReconcileDepositGroupToggleDetail = () => {
        let request: any = {};
        request.hotelID = this.state.hidValue;
        request.OprID = this.props.OprID;
        request.Accperiod = this.state.accper;
        request.AcctType = this.props.acctType;
        request.groupID = 0;
        request.IsCheck = "0";
        request.AuditName = this.props.AccountType;
        reconcileService.GetReconcileDepositGroupToggleDetail(request)
            .then(async (result: any | null) => {
                if (result.length !== null && result.length > 0) {
                    console.log(result);
                    this.setState({
                        isGroupDepositsToggleEnabled: result[0].isCheck === 1 ? "yes" : "no",
                        groupDepositsToggleGroupId: result[0].groupID,
                        groupName: result[0].groupName,
                        displayGroupName: result[0].displayGroupName,
                    }, () => {
                        this.GetGroupReconcileStatus();
                        this.getDepositsData();
                    })
                }
                resolve();
            })
            .catch((error) => {
                reject();
            });
    }

    SaveReconcileDepositGroupToggle = () => {
        let request: any = {};
        request.hotelID = this.state.hidValue;
        request.OprID = this.props.OprID;
        request.Accperiod = this.state.accper;
        request.AcctType = this.props.acctType;
        request.groupID = this.state.groupDepositsToggleGroupId;
        request.IsCheck = this.state.isGroupDepositsToggleEnabled.toLowerCase() === "yes" ? "1" : "0";
        // request.AuditName = this.props.pageType;
        reconcileService.SaveReconcileDepositGroupToggle(request)
            .then(async (result: any | null) => {
                if (result.length !== null && result.length > 0) {
                    // console.log(result);
                    // this.setState({
                    //     isGroupDepositsToggleEnabled: result[0].isCheck === 1 ? "yes" : "no",
                    //     groupDepositsToggleGroupId: result[0].groupID,
                    // }, () => {
                    //     this.getDepositsData()
                    // })
                }
                resolve();
            })
            .catch((error) => {
                reject();
            });
    }

    handleGroupDepositsToggle = (event) => {
        if (event.target.checked) {
            this.setState({ isGroupDepositsToggleEnabled: "yes" }, () => {
                this.SaveReconcileDepositGroupToggle();
                this.getDepositsData();
            });
        }
        else {
            this.setState({ isGroupDepositsToggleEnabled: "no" }, () => {
                this.SaveReconcileDepositGroupToggle();
                this.getDepositsData();
            });
        }
    }

    render() {
        const rowClasses = (row, rowIndex) => {
            let classes = '';

            if (row.isReconcile) {
                classes = classes + ' reconcile-entry';
            }

            if (row.orderNo === "4") {
                classes = classes + ' tblDivider';
            }

            return classes;
        };
        const columns = [
            {
                dataField: 'date1',
                text: 'Date',
                //headerClasses: {this.state.isGroupDepositsToggleEnabled == 'yes' ? "regColDate" : "regColDate"},
                headerClasses: (column, colIndex) => {
                    if (!this.state.isGroup) {
                        if (this.state.isGroupDepositsToggleEnabled == 'yes') {
                            return 'regColDate date-col group-toggle-enabled';
                        } else {
                            if (this.state.groupDepositsToggleGroupId === 0) {
                                return 'regColDate date-col';
                            } else {
                                return 'regColDate date-col group-toggle-disabled';
                            }

                        }
                    } else {
                        return 'regColDate date-col old-group';
                    }
                },
                headerFormatter: (column, colIndex) => {
                    return (
                        <div className="d-flex">
                            <div className={`sort-col ${this.state.sortingField === column.dataField ? 'active' : ''}`}
                                onClick={() => this.handleSortByColumn(column.dataField)}>
                                {column.text}{this.sortImage(this.state.sortingOrder)}
                            </div>
                        </div>
                    )
                },
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className={row.isReconcile ? "reconcile-sec" : ""}>
                            <EllipsisWithTooltip placement="bottom">
                                {row.type.toLowerCase() === "nothing" ? "" : row.date}
                            </EllipsisWithTooltip>
                        </div>
                    );
                },
            }, {
                dataField: 'type',
                text: 'Type',
                headerClasses: 'right-align rm-pos type-col',
                classes: 'right-align',
                hidden: this.state.isGroupDepositsToggleEnabled.toLowerCase() === "yes" || this.state.groupDepositsToggleGroupId === 0,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className={row.isReconcile ? "reconcile-sec" : ""}>
                            <EllipsisWithTooltip placement="bottom">
                                {row.displayType}
                            </EllipsisWithTooltip>
                        </div>
                    );
                },
                headerFormatter: (column: any, colIndex: any) => {
                    return (
                        <div className="d-flex justify-content-end">
                            <div className={`header-ttl sort-col ${this.state.sortingField === column.dataField ? 'active' : ''}`} onClick={() => this.handleSortByColumn(column.dataField)}>{column.text}{this.sortImage(this.state.sortingOrder)}</div>
                        </div>
                    )
                },
            }, {
                dataField: 'amount',
                text: 'Amount',
                headerClasses: 'right-align rm-pos amount-cell',
                classes: 'right-align',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className={row.isReconcile ? "reconcile-sec" : ""}>
                            <EllipsisWithTooltip placement="bottom">
                                {Utils.currencyFormatWithOutdoller(row.amount)}
                            </EllipsisWithTooltip>
                        </div>
                    );
                },
                headerFormatter: (column: any, colIndex: any) => {
                    return (
                        <div className="d-flex justify-content-end">
                            <div className={`header-ttl sort-col ${this.state.sortingField === column.dataField ? 'active' : ''}`} onClick={() => this.handleSortByColumn(column.dataField)}>{column.text}{this.sortImage(this.state.sortingOrder)}</div>
                            <div className="caption-val">{Utils.currencyFormat(this.state.totalAmount)}</div>
                        </div>
                    )
                },
            }, {
                dataField: 'discount',
                text: 'Commission',
                headerClasses: 'right-align rm-pos commission-cell',
                classes: 'right-align',
                hidden: this.state.isGroup ? true : false,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className={row.isReconcile ? "reconcile-sec cursor-pointer" : "cursor-pointer"} onClick={(e) => this.state.isGroupDepositsToggleEnabled === "yes" ? e.preventDefault() : this.inlineDiscount(row)}>
                            {(row.discount === "0.00" || row.discount === null) ? "-" : Utils.currencyFormatWithOutdoller(row.discount)}
                        </div>
                    );
                },
                headerFormatter: (column: any, colIndex: any) => {
                    return (
                        <div className="d-flex justify-content-end">
                            <div className={`header-ttl sort-col ${this.state.sortingField === column.dataField ? 'active' : ''}`} onClick={() => this.handleSortByColumn(column.dataField)}>{column.text}{this.sortImage(this.state.sortingOrder)}</div>
                            <div className="caption-val d-flex"
                                onClick={(e) => {
                                    (this.props.acctStatus === "Unreconciled" && this.state.status === "Unreconciled")
                                        ? this.onAddAdjustment("Discount", null) : e.preventDefault()
                                }}>
                                <div className="grey-color">{this.state.discountPercentage}% /</div>
                                <div>{Utils.currencyFormat(this.state.netDiscount)}</div>
                            </div>
                        </div>
                    )
                },
            }, {
                dataField: 'adjustment',
                text: 'Adjustment',
                headerClasses: 'right-align rm-pos adjustment-cell',
                classes: 'right-align',
                hidden: this.state.isGroup ? true : false,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className={row.isReconcile ? "reconcile-sec cursor-pointer" : "cursor-pointer"} onClick={(e) => this.state.isGroupDepositsToggleEnabled === "yes" ? e.preventDefault() : this.inlineAddadjustment(row)}>
                            {((row.adjustment === "0.00" || row.adjustment === null) && row.adj !== "Adj") ? "-" : Utils.currencyFormatWithOutdoller(row.adjustment)}
                        </div>
                    );
                },
                headerFormatter: (column: any, colIndex: any) => {
                    return (
                        <div className="d-flex justify-content-end">
                            <div className={`header-ttl sort-col ${this.state.sortingField === column.dataField ? 'active' : ''}`} onClick={() => this.handleSortByColumn(column.dataField)}>{column.text}{this.sortImage(this.state.sortingOrder)}</div>
                            <div className="caption-val"
                                onClick={(e) => {
                                    (this.props.acctStatus === "Unreconciled" && this.state.status === "Unreconciled") ?
                                        this.onAddAdjustment("Adjustment", null) : e.preventDefault()
                                }}>{Utils.currencyFormat(this.state.adjustmentAmount)}
                            </div>
                        </div>
                    )
                },
            }, {
                dataField: 'description1',
                text: 'Description',
                hidden: this.state.isGroup ? true : false,
                headerClasses: 'description-cell',
                headerFormatter: (column, colIndex) => {
                    return (
                        <div className="d-flex">
                            <div className={`sort-col ${this.state.sortingField === column.dataField ? 'active' : ''}`} onClick={() => this.handleSortByColumn(column.dataField)}>{column.text}{this.sortImage(this.state.sortingOrder)}</div>
                        </div>
                    )
                },
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className={row.isReconcile ? "reconcile-sec" : ""}>
                            <EllipsisWithTooltip placement="bottom">
                                {row.description}
                            </EllipsisWithTooltip>
                        </div>
                    );
                },
            }, {
                dataField: 'netAmount',
                text: 'Net',
                headerClasses: 'right-align rm-pos netAmount-cell',
                classes: 'right-align',
                hidden: this.state.isGroup ? true : false,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className={row.isReconcile ? "reconcile-sec" : ""}>
                            <EllipsisWithTooltip placement="bottom">
                                {Utils.currencyFormatWithOutdoller(row.netAmount)}
                            </EllipsisWithTooltip>
                        </div>
                    );
                },
                headerFormatter: (column: any, colIndex: any) => {
                    return (
                        <div className="d-flex justify-content-end">
                            <div className={`header-ttl sort-col ${this.state.sortingField === column.dataField ? 'active' : ''}`} onClick={() => this.handleSortByColumn(column.dataField)}>{column.text}{this.sortImage(this.state.sortingOrder)}</div>
                            <div className="caption-val">
                                <div
                                    onClick={(e) => {
                                        (this.props.acctStatus === "Unreconciled" && this.state.status === "Unreconciled") ?
                                            this.toggleAddAmountPP(true) : e.preventDefault()
                                    }}>{Number(this.state.transNetAmount) !== 0 ? Utils.currencyFormat(this.state.transNetAmount) : Utils.currencyFormat(this.state.netAmount)}
                                </div>
                                <div className="grey-color">{Utils.currencyFormat(this.state.verianceAmount)}</div>
                                {this.state.addTransactionAmount && (
                                    <div className="transaction-type-modal">
                                        <div className="header d-flex">
                                            <div className="title mr-auto">Transaction Type Total</div>
                                            <div className="close" onClick={() => { this.toggleAddAmountPP(false) }}>
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.4933 5.28667L9.95747 8.82167L6.42247 5.28667L5.24414 6.465L8.77914 10L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 10L14.6716 6.465L13.4933 5.28667Z" fill="#84888C" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="body">
                                            <Form.Group controlId="statement-ttl" className="text-left">
                                                <Form.Label>
                                                    <div className="d-flex">
                                                        <div>Statement Total</div>
                                                        <OverlayTrigger
                                                            placement="bottom"
                                                            delay={{ show: 250, hide: 400 }}
                                                            overlay={
                                                                <Tooltip id={"statement-ttl"}>
                                                                    {"Enter the total for this transaction type for the account statement. This will be used to show the variance."}
                                                                </Tooltip>
                                                            }>
                                                            <div className="info-icn">
                                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M8.66649 7.33327H7.33315V11.3333H8.66649V7.33327Z" fill="#6A6E73" />
                                                                    <path d="M8.66649 4.6666H7.33315V5.99994H8.66649V4.6666Z" fill="#6A6E73" />
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1998 8C15.1998 11.9765 11.9763 15.2 7.9998 15.2C4.02335 15.2 0.799805 11.9765 0.799805 8C0.799805 4.02355 4.02335 0.800003 7.9998 0.800003C11.9763 0.800003 15.1998 4.02355 15.1998 8ZM14.3998 8C14.3998 11.5346 11.5344 14.4 7.9998 14.4C4.46518 14.4 1.5998 11.5346 1.5998 8C1.5998 4.46538 4.46518 1.6 7.9998 1.6C11.5344 1.6 14.3998 4.46538 14.3998 8Z" fill="#6A6E73" />
                                                                </svg>
                                                            </div>
                                                        </OverlayTrigger>
                                                    </div>
                                                </Form.Label>
                                                <Form.Control placeholder="$0.00"
                                                    value={this.state.transAmount}
                                                    onChange={(e) => { this.handleAmount(e) }}
                                                    onBlur={this.handleEndBalBlur}
                                                    onFocus={this.onEndBalFocus}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="footer"><Button className="btn-primary" onClick={() => { this.onAddTransactionAmount() }}>Save</Button></div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )
                },
            }, {
                dataField: 'clMonth',
                text: 'CL',
                headerClasses: 'center-align width-200',
                classes: 'center-align width-200',
                headerFormatter: (column, colIndex) => {
                    return (
                        <div className="d-flex justify-content-center">
                            <div className={`sort-col ${this.state.sortingField === column.dataField ? 'active' : ''}`} onClick={() => this.handleSortByColumn(column.dataField)}>{column.text}{this.sortImage(this.state.sortingOrder)}</div>
                        </div>
                    )
                },
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className={row.isReconcile ? "reconcile-sec" : ""}>
                            <EllipsisWithTooltip placement="bottom">
                                {row.clMonth}
                            </EllipsisWithTooltip>
                        </div>
                    );
                },
            },
            {
                dataField: '',
                text: '',
                headerClasses: 'stick-column width-130',
                classes: 'stick-column',
                headerFormatter: (column, colIndex) => {
                    return (
                        <div className="border-line"></div>
                    );
                },
                formatter: (cell: any, row: any, rowIndex: any) => (
                    <div style={{ pointerEvents: this.state.isSummaryEnabled ? 'none' : 'auto', opacity: this.state.isSummaryEnabled ? 0.5 : 1 }}>
                        {(row.type.toLowerCase() !== "nothing" && row.orderNo !== "4") && (
                            <div className="cursor-pointer" onClick={(e) => {
                                e.stopPropagation()
                            }}>
                                {!row.isReconcile && (
                                    <div onClick={(event) => { row.islocked === 0 ? this.onSingleReconcile(event, rowIndex, row) : event.preventDefault() }}>
                                        {row.islocked === 0 ? (
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="24" height="24" rx="12" fill="#F4F6F8" />
                                                <path d="M10.711 13.294L8.412 10.999L7 12.415L10.713 16.12L17.419 9.414L16.005 8L10.711 13.294Z" fill="#84888C" />
                                            </svg>
                                        ) : (
                                            <OverlayTrigger
                                                placement="bottom"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={
                                                    <Tooltip id={"rc1"}>
                                                        {row.islocked === 1 ? "Please re-open reconciliation to make changes" : "Record cannot be saved in inactive period."}
                                                    </Tooltip>
                                                }>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="24" height="24" rx="12" fill="#F4F6F8" />
                                                    <path d="M10.711 13.294L8.412 10.999L7 12.415L10.713 16.12L17.419 9.414L16.005 8L10.711 13.294Z" fill="#84888C" />
                                                </svg>
                                            </OverlayTrigger>
                                        )}
                                    </div>
                                )}
                                {row.isReconcile && (
                                    <div onClick={(event) => { row.islocked === 0 ? this.onSingleReconcile(event, rowIndex, row) : event.preventDefault() }}>
                                        {row.islocked === 0 ? (
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="24" height="24" rx="12" fill="#2CAF92" />
                                                <path d="M10.711 13.294L8.412 10.999L7 12.415L10.713 16.12L17.419 9.414L16.005 8L10.711 13.294Z" fill="white" />
                                            </svg>
                                        ) : (
                                            <OverlayTrigger
                                                placement="bottom"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={
                                                    <Tooltip id={"rc1"}>
                                                        {row.islocked === 1 ? "Please re-open reconciliation to make changes" : "Record cannot be saved in inactive period."}
                                                    </Tooltip>
                                                }>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="24" height="24" rx="12" fill="#2CAF92" />
                                                    <path d="M10.711 13.294L8.412 10.999L7 12.415L10.713 16.12L17.419 9.414L16.005 8L10.711 13.294Z" fill="white" />
                                                </svg>
                                            </OverlayTrigger>
                                        )}
                                    </div>
                                )}

                            </div>
                        )}
                        <div className="border-line"></div>
                    </div>
                ),
            }];

        const dropDownHomeIcon = this.getDropDownHomeIcon();
        const dropDownArrowIcon = this.getDropDownArrowIcon();
        return (
            <>
                <ToastContainer
                    autoClose={3000}
                    containerId={"Reconcile"}
                    enableMultiContainer
                />
                <div className="reconciliation-modal modal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="d-flex align-items-center modal-header">
                                <div className="modal-title page-title mr-auto">
                                    <div className="tag-name">Reconcile Account</div>
                                    <div className="ac-name">{this.props.accountName}</div>
                                </div>
                                <div className="action">
                                    <div className="d-flex justify-content-end">
                                        <Dropdown className={this.props.headerControlsDisabled ? "ehid-selector disabled" : "ehid-selector"} alignRight>
                                            <Dropdown.Toggle id="dropdown-pending-account">
                                                <div className="d-flex align-items-center">
                                                    <div className="home-icon">{dropDownHomeIcon}</div>
                                                    <div className="ehid-name"><EllipsisWithTooltip placement="bottom">{this.props.accountName}</EllipsisWithTooltip></div>
                                                    <div className="arrow-icon">{dropDownArrowIcon}</div>
                                                </div>
                                            </Dropdown.Toggle>
                                        </Dropdown>
                                        <Dropdown className={this.props.headerControlsDisabled ? "margin-left-16 period-selector disabled" : "margin-left-16 period-selector"} alignRight>
                                            <Dropdown.Toggle id="dropdown-pending-period">
                                                <div className="d-flex align-items-center">
                                                    <div className="period-name mr-auto"><EllipsisWithTooltip placement="bottom">{this.state.periodValue}</EllipsisWithTooltip></div>
                                                    <div className="arrow-icon">{dropDownArrowIcon}</div>
                                                </div>
                                            </Dropdown.Toggle>
                                        </Dropdown>
                                        <div className="separator"></div>
                                        <Button variant="primary" type="button" className="btn-outline-primary exit-button" disabled={this.state.isSummaryEnabled} onClick={() => this.hideDepositModal()}>Exit</Button>
                                        {/* {(this.props.pageType === "ACH Withdrawals" || this.props.pageType === "Checks") && ( */}
                                        <Dropdown className="more-action three-dot margin-left-16" alignRight>
                                            <Dropdown.Toggle className="btn-outline-primary btn btn-primary more" id="dropdown-more">
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                                </svg>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item eventKey="editaccount" key="editaccount" onClick={() => this.exportData()}>Export</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        {/* )} */}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-body">
                                <div className="bdy-sec rm-padding">
                                    <div className="body-header d-flex align-items-center">
                                        <div className={`header-title width-cal d-flex mr-auto ${this.state.isGroup ? "is-group" : ""}`}>
                                            <div
                                                className={`sec-ttl ${this.state.isGroupDepositsToggleEnabled == 'yes' ? "group-toggle-enabled" : this.state.groupDepositsToggleGroupId === 0 ? "" : "group-toggle-disabled"} ${this.state.isGroup == true ? "old-group" : ""}`}
                                            // className={this.state.isGroupDepositsToggleEnabled == 'yes' ? "sec-ttl group-toggle-enabled" : "sec-ttl"}
                                            >
                                                <EllipsisWithTooltip placement="bottom">
                                                    {this.state.isGroup ? this.state.pageTitle : this.state.displayGroupName}
                                                </EllipsisWithTooltip>
                                            </div>
                                            {!this.state.isGroup && (
                                                <div className={`header-status 
                                                ${this.state.status === "Unreconciled" ? "blue" : ""}
                                                ${this.state.status === "Locked" ? "grey" : ""}
                                                ${this.state.status === "Reconciled" ? "green" : ""}
                                                `}>{this.state.status}</div>
                                            )}
                                        </div>
                                        {!this.state.isGroup && this.state.groupDepositsToggleGroupId !== 0 &&
                                            <Form.Check
                                                id="groupDepositToggle" key="groupDepositToggle"
                                                className="group-deposit-toggle"
                                                type="switch"
                                                label="Group Deposits"
                                                checked={this.state.isGroupDepositsToggleEnabled.toLowerCase() === "yes"}
                                                onChange={this.handleGroupDepositsToggle}
                                            />
                                        }
                                        {this.state.tableData.some(e => e.orderNo === "2") && (
                                            <Button variant="primary" type="button" className="btn-link"
                                                disabled={(this.state.isGroup ? this.props.acctStatus === "Unreconciled" : (this.props.acctStatus === "Unreconciled" && !this.state.isSummaryEnabled))
                                                    ? false : true} onClick={() => { this.onReconcileAll() }}>
                                                {this.state.tableData.some(e => !e.isReconcile && e.orderNo === "2") ? "Reconcile All" : "Unreconcile All"}
                                            </Button>
                                        )}
                                    </div>
                                    {this.state.isLoader ? (
                                        <ReactPageLoader useas={"reconcile"} />
                                    ) : (<div className={`body-list ${this.state.ishorizontalScroll ? "ishorizontalScroll" : ""}`} ref={this.reconcileList}>
                                        <BootstrapTable keyField='id' data={this.state.tableData} columns={columns} rowClasses={rowClasses} />
                                        <div className="headser-bg"></div>
                                        <div className="scroll-header-bg"></div>
                                    </div>)}
                                </div>
                            </div>
                            <div className="modal-footer unsaved justify-content-start">
                                <Button variant="primary" type="button" className="mr-auto btn-outline-primary" disabled={this.state.isSummaryEnabled} onClick={() => this.hideDepositModal()}>Return to Summary</Button>
                                {!this.state.isGroup && (
                                    <>
                                        <Form.Group controlId="chkNext">
                                            <Form.Check type="checkbox" id="chkNext" label="Proceed to Next" checked={this.state.isNextCheck} onChange={(e) => { this.handleCheckNext(e) }} custom />
                                        </Form.Group>
                                        <Button variant="primary" type="button" disabled={this.props.acctStatus === "Unreconciled" && !this.state.isSummaryEnabled ? false : true} onClick={() => this.handleSaveButton("reconcile", "0")} >{this.state.status === "Unreconciled" ? "Mark as Complete" : "Mark as Incomplete"}</Button>
                                    </>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
                <Modal className="move-invoice-modal mark-invoice-as-paid reconcile-modal" show={this.state.isAddAdjustment} onHide={() => this.onCloseAdjModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <Form.Group controlId="COA" className="more-action single-select-image dropdown coa-select">
                                    <Form.Label>COA</Form.Label>
                                    <Typeahead
                                        id="id"
                                        onChange={(event) => {
                                            this.handleSelectedCoa(event);
                                        }}
                                        options={this.state.coaList}
                                        placeholder="Enter COA"
                                        selected={this.state.defaultCoa}
                                        renderMenu={(results: any, menuProps) => (
                                            <Menu {...menuProps}>
                                                {results.map((result: any, index: any) => (
                                                    <>
                                                        {result.label !== "" && (
                                                            <MenuItem option={result} position={index}>
                                                                <EllipsisWithTooltip placement="bottom">
                                                                    {result.label}
                                                                </EllipsisWithTooltip>
                                                            </MenuItem>
                                                        )}
                                                    </>
                                                ))}
                                            </Menu>
                                        )}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="date">
                                    <Form.Label>Date</Form.Label>
                                    <DatePicker
                                        id="paymentdate"
                                        className="form-control"
                                        placeholderText={"MM/DD/YY"}
                                        dateFormat="MM/dd/yy"
                                        maxDate={new Date(this.state.maxDate)}
                                        selected={this.state.adjustmentReq.Date === "" ? new Date() : new Date(this.state.adjustmentReq.Date)}
                                        onChange={(event: any) => this.handleAddAdjustmentVal(event, 'date', "")}
                                        disabled={this.state.adjustmentReq.EntryStatus === "Update" ? true : false}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="amount">
                                    <Form.Label>Amount</Form.Label>
                                    <Form.Control placeholder="Amount"
                                        // pattern="[+-]?\d+(?:[.,]\d+)?"
                                        value={this.state.modalTitle === "Add Adjustment" ? this.state.adjustmentReq.Adjustment : this.state.adjustmentReq.Discount}
                                        onBlur={(e) => { this.handleAddAdjustmentVal(e, "amtblur", "") }}
                                        onChange={(e) => { this.handleAddAdjustmentVal(e, "amount", "") }}
                                        onFocus={(e) => { this.handleAddAdjustmentVal(e, "amtfocus", "") }} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group controlId="Description">
                            <Form.Label>Description</Form.Label>
                            <Form.Control id="txtDescription" as="textarea" placeholder="Add Description" wrap="hard" rows={3} value={this.state.adjustmentReq.Description} onChange={(e) => { this.handleAddAdjustmentVal(e, "description", "") }}
                                maxLength={99} />
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        {(this.state.adjustmentReq.UniqueNo !== "" && this.state.adjustmentReq.UniqueNo !== 0) && (
                            <Button className="btn-outline-primary" disabled={this.state.isDeleteClicked} onClick={() => { this.handleAddAdjustmentVal("", "delete", "Delete") }}>Delete</Button>
                        )}
                        <Button className="btn-primary" disabled={this.state.isSaveClicked} onClick={() => { this.handleAddAdjustmentVal("", "save", this.state.adjustmentReq.UniqueNo !== "" ? "Update" : "Insert") }}>Save</Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}