import * as React from "react";
import { Container, Dropdown, Form, Modal, OverlayTrigger, Button } from "react-bootstrap";
export class IframeSpike extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            reportURL: "",
            isReportShow: false,
        }
    }

    getUrl = (e) => {
        this.setState({reportURL: e.target.value, isReportShow: false});
    }

    runReport = () => {
        this.setState({isReportShow: true});
    }

    render() {
        return (
            <Container fluid className="body-sec">
                <input type="search" className="form-control" placeholder={"Report URL"} value={this.state.reportURL} onChange={(e) => this.getUrl(e)} />
                <Button variant="primary" onClick={(e) => { this.runReport() }}>Run Report</Button>
                {this.state.isReportShow &&
                    // <iframe src="https://tsv1.inn-flow.net/logIn.aspx?enc=5Coz/D0CgJ/VVWuJOumLUpeVPawyilCmwJK2TRJ5KrjNYIbv+Lxswwk9Y7jZbw818NQcL5A56fuotBA5HbZuvg0Me/HHo8QPxflRocP/H9PQZjjDxOTdhv2FNwW90RP5QzKE7vPMzOoZFo9qncYv9IGt+nQYpHPwfb7MYNguxITHv3L1Jr0aI26FkbIIDN4wvosYkkkLuvBhL/WRd0JjHKlqkt/l6E1GfWUT8rmDpoQ+xlsss1ohIYFKJli5sbfv/nlGT2p99vyCeQfsTA1v0s1bqkjJ0kbO2loKDVMB/wBJW+D5NukwfOzsbPqJ34JpNmVK4sc6VbMA4O/K4Gudu+KQY1SJUBAxAiiZyupf9PEDXdnX4XS78Y5rF4N8wVVlEn6GNU4XMBcXqUDp+An9Zl0RcoR7phM8nms8Gx6HGLgWjh4HBOqlxhQx6NDA7WgU8L6MFzIK7ctxElZD+vQAaVo9HhTBn44YTomRpWGcnui3x2musVPD3lBsfeHmEXlRXdZRiBWvqYRYzLzNJU/bIxdHcTEEXUZXKZpVPGPqCRM=" title="Iframe SPike" width="100%" height="100%">
                    // </iframe>
                    // <iframe src="https://test-rep1.azurewebsites.net/ReportGenerated.aspx?ID=1375979&ID2=101&TokID=&NewTokID=374295" title="Iframe SPike" width="100%" height="100%">
                    // </iframe>
                    // <iframe src="http://localhost:55558/ReportGenerated.aspx?ID=1375979&ID2=101&TokID=&NewTokID=374295" title="Iframe SPike" width="100%" height="100%">
                    // </iframe>
                    <iframe src={this.state.reportURL} title="Iframe SPike" width="100%" height="87%">
                    </iframe>
                }
            </Container>
        )
    }
}