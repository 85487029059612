import React from "react";
import { Prompt } from "react-router-dom";
import { createBrowserHistory } from "history";
import { confirmAlert } from "react-confirm-alert";
import { reject } from "q";

interface IState {
  modalVisible: any;
  lastLocation: any;
  confirmedNavigation: any;
  userFinalConfirmation: any;
}

interface IProps {
  when: any;
  routeLeavingGuardConfirm: any;
  shouldBlockNavigation: any;
}

export class RouteLeavingGuard extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      modalVisible: false,
      lastLocation: null,
      confirmedNavigation: false,
      userFinalConfirmation: false,
    };
  }

  showModal = (location: any) => {
    this.setState({
      modalVisible: true,
      lastLocation: location,
      userFinalConfirmation: false,
    });
  };

  closeModal = (callback: () => void) => {
    this.setState({ modalVisible: false }, callback);
  };

  handleConfirmNavigationClick = () => {
    this.closeModal(() => {
      const history = createBrowserHistory({
        forceRefresh: true,
      });
      const { lastLocation } = this.state;
      if (lastLocation) {
        this.setState({ confirmedNavigation: true }, () => {
          this.setState({ userFinalConfirmation: true });
          this.props.routeLeavingGuardConfirm(true);
          history.push(lastLocation.pathname);
        });
      }
    });
  };

  handleBlockedNavigation = (nextLocation: any) => {
    const { confirmedNavigation } = this.state;
    const { shouldBlockNavigation } = this.props;
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      this.showModal(nextLocation);
      this.isModalShowUp();
      return false;
    }
    return true;
  };

  isModalShowUp = () => {
    confirmAlert({
      title: "Unsaved confirmation",
      message:
        "You have unsaved changes on this page. Do you want to leave this page and discard your changes?",
      buttons: [
        {
          label: "Yes",
          onClick: this.handleConfirmNavigationClick,
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  render() {
    const { when } = this.props;
    return (
      <>
        <Prompt when={when} message={this.handleBlockedNavigation} />
      </>
    );
  }
}

export default RouteLeavingGuard;
