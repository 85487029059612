import * as React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ProtectedLayout } from "./Common/Components/ProtectedLayout";
import { RestoreSession } from "./Common/Components/RestoreSession";
import { Maintenance } from "../src/Login/Under-Maintenance";
import { Login } from "./Login/Login";
import { Logout } from "./Login/Logout";
import { Forgot } from "./ForgotPassword/Forgot";
import { TimeClockIndex } from "./WebTimeClock/TimeClockIndex";
import "./App.scss";
import "react-toastify/dist/ReactToastify.css";
import { API_ROOT } from "./Common/Services/Api-config";

const RelicMapper ={
  "test":"newrelictest.js",
  "po":"newrelicpo.js",
  "staging":"newrelicstaging.js",
  "production":"newreliclive.js",
}

const MicroSoftClarityMapper ={
  "test":"microsoftClarityTest.js",
  "po":"microsoftClarityPO.js",
  "staging":"microsoftClarityStaging.js",
  "production":"microsoftClarityLive.js",
}


const getScriptName =(scriptName)=>{
  let src:any=false
  scriptName=scriptName?.toLowerCase()
  const currentEnv=API_ROOT?.environment?.toLowerCase()
  
  if(scriptName === "newrelic"){
    src=RelicMapper[currentEnv] ? "/files/newrelic/"+RelicMapper[currentEnv] :false
  }
  else if(scriptName === "microsoftclarity"){
    src=MicroSoftClarityMapper[currentEnv] ? "/files/microsoftClarity/"+MicroSoftClarityMapper[currentEnv] :false
  }
  return src

}


const App: React.FC = () => {
  let appClassName: string = "App d-flex";
  if (window.innerWidth <= 1200 && window.innerWidth >= 1000) {
    appClassName = `${appClassName} small-width`;
  }
  React.useEffect(()=>{
    const newrelicsrc=getScriptName("newrelic")
    const microsoftclaritysrc=getScriptName("microsoftclarity")
    

    const newrelicsrcScript = document.createElement('script');
    const microsoftclaritysrcScript = document.createElement('script');
    
    if(newrelicsrc)
    {
      newrelicsrcScript.src = newrelicsrc; 
      document.head.appendChild(newrelicsrcScript);
    }

    if(microsoftclaritysrc)
    {
      microsoftclaritysrcScript.src = microsoftclaritysrc; 
      document.head.appendChild(microsoftclaritysrcScript);
    }
   

    // Clean up the script tag on component unmount
    return () => {
      document.head.removeChild(newrelicsrcScript);
      document.head.removeChild(microsoftclaritysrcScript);
    };
  },[])
  // Conditionally render the script tag
  // const renderScriptTag=getScript()
  return (
    <div className={appClassName}>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Router>
      <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/logout" component={Logout} />
          <Route
            exact
            path="/forgot-password/:userName/:passCode/:type?"
            component={Forgot}
          />
          <Route
            exact
            path="/forgot-password/:userName/:passCode/:type?"
            component={Forgot}
          />
          <Route exact path="/tc" component={TimeClockIndex} />
          <Route path="/Under-Maintenance" component={Maintenance} />
          <Route path="/RestoreSession" component={RestoreSession} />
          <Route path="/" component={ProtectedLayout} />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
