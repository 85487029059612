import * as React from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import { ModuleReports } from "./../../Common/Services/ModuleReports";
import { ToastContainer, toast } from "react-toastify";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { Utils } from "../../Common/Utilis";
export class COAClassificationReportType extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            selectedItem: [],
            reportTypes: [],
            reportTypesCopy: [],
            isbulkSelect: false,
        };
    }

    getCOAClassificationReportList = () => {
      // debugger;
      let request: any = {};
  
      ModuleReports
        .GetCOAClassificationReportList(request)
        .then(async (result: any | null) => {
          //debugger;
          if (result !== null) {
            this.setState({ reportTypes: result, reportTypesCopy: result }, () => {
              this.getDefaultSelectedTypes();
            });
          }
        })
        .catch((err: any) => {
          this.setState({ contractList: [], selectedItem: { rowNo: -1, label: "Select" }, });
          Utils.toastError(`Server Error, ${err}`, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        });
    }

    getDefaultSelectedTypes = () => {
        // debugger;
        const { defaultValue } = this.props;
        let selectedItem = Object.assign([], JSON.parse(JSON.stringify(this.state.selectedItem)));
        let reportTypes = Object.assign([], JSON.parse(JSON.stringify(this.state.reportTypes)));
        if (defaultValue !== "" && defaultValue !== undefined && defaultValue !== null) {
            let tempArray = defaultValue.split(",");
            for(let i = 0; i < tempArray.length; i++ ) {
                if (reportTypes.filter((x) => x.value === Number(tempArray[i])).length > 0) {
                    const index = reportTypes.findIndex((x) => x.value === Number(tempArray[i]));
                    reportTypes[index].isChecked = true;
                    selectedItem.push(reportTypes[index]);
                }
            }
            this.setState({selectedItem, reportTypes, isbulkSelect: selectedItem.length === this.state.reportTypesCopy.length });
        }
    }

    componentDidUpdate = (prevprops, prevState) => {
        // debugger;
        const { defaultValue } = this.props;
        if((prevprops.defaultValue !== defaultValue) && defaultValue === "") {
            this.setState({ reportTypes: this.state.reportTypesCopy, selectedItem: [], isbulkSelect: false})
        }
    }

    componentDidMount = () => {
        // debugger;
        this.getCOAClassificationReportList();
    };

    checkTotalLength = () => {
        let { reportTypes } = this.state;
        let totalLength: any[] = [];
        reportTypes.forEach((item: any) => {
            totalLength.push(item);
        });
        return totalLength;
    }

    handleCheckItem = () => {
        // debugger;
        const item = this.state.selectedItem;
        let selecteditem: any = [];
        item.forEach((o: any) => selecteditem.push(o.value));
        this.props?.handleMultiCoaClassificationType(selecteditem);
    }

    handleDropdownChange = (event: any, item: any) => {
        // debugger;
        let selectedItem = Object.assign([], JSON.parse(JSON.stringify(this.state.selectedItem)));
        let reportTypes = Object.assign([], JSON.parse(JSON.stringify(this.state.reportTypes)));
        let checked = event.target.checked;
        reportTypes.forEach((o: any) => {
            if (o.value === item.value) {
                o.isChecked = checked;
            }
        });
        selectedItem = reportTypes.filter((o: any) => o.isChecked);
        let isEqualLen = selectedItem.length === this.state.reportTypesCopy.length;
        this.setState({ reportTypes, isbulkSelect: isEqualLen, selectedItem }, () => {
            this.handleCheckItem();
        });
    };

    handleSelectAll = () => {
        let { isbulkSelect } = this.state;
        let selectedItem = Object.assign([], JSON.parse(JSON.stringify(this.state.selectedItem)));
        let reportTypes = Object.assign([], JSON.parse(JSON.stringify(this.state.reportTypes)));
        let totalLength: any[] = [];
        reportTypes.forEach((o: any) => {
            o.isChecked = !isbulkSelect;
            totalLength.push(o);
        })
        selectedItem = reportTypes.filter((o: any) => o.isChecked);
        let isEqualLen = selectedItem.length === this.state.reportTypesCopy.length;
        this.setState({ reportTypes, isbulkSelect: isEqualLen, selectedItem }, () => {
            this.handleCheckItem();
        });
    }

    render() {
        const { selectedItem, reportTypes, isbulkSelect } = this.state;
        return (
            <Dropdown className="single-select">
                <Dropdown.Toggle id="Modal-DocType" className="txtDocTypebtn">
                    {selectedItem.length > 0 ? (<>
                        {selectedItem.length === 1 ? (
                            <>
                                {selectedItem.map((item: any, index: any) => (
                                    <div className="selectedItems">
                                        <div className="itemLabel"><EllipsisWithTooltip placement="bottom">{item.label}</EllipsisWithTooltip></div>
                                    </div>
                                ))}
                            </>
                        ) : (<div className="txtDocTypeItemList">{selectedItem.length} Report Types Selected</div>)}
                    </>
                    ) : ("Select Report Type")}
                </Dropdown.Toggle>
                <Dropdown.Menu className="txtDocTypeMenu">
                    <div className="list-area label-width">
                        {reportTypes.map((item: any, idx: any) => (
                            <Form.Check
                                type="checkbox"
                                key={"docType" + idx}
                                id={"docType" + idx}
                                label={<EllipsisWithTooltip placement="bottom">{item.label}</EllipsisWithTooltip>}
                                checked={item.isChecked}
                                onChange={(e: any) => this.handleDropdownChange(e, item)}
                            />
                        ))}
                    </div>
                    <div className="footer">
                        <Button className="wht-bg txt-green" onClick={this.handleSelectAll}>{isbulkSelect ? "Clear All" : "Select All"}</Button>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}