const LayoutTypes = [
  { partitions: [0.5, 0.5], cols: 12 },
  { partitions: [0.33333, 0.33333, 0.33333], cols: 12 },
  { partitions: [0.66667, 0.33333], cols: 12 },
  { partitions: [0.33333, 0.66667], cols: 12 },
];

function calculateHeight(partitionWidth, cardHeight) {
  // Base height mapping for bar/progress charts
  const heightMapping = {
    0.66667: 3, // For partition width 0.666
    0.5: 2.3, // For partition width 0.5
    0.33333: 2.3, // For partition width 0.333
  };

  const baseHeight =
    heightMapping[partitionWidth] 

  // Adjust the height for the card type
  return cardHeight === 1 ? 1 : baseHeight; // Reduce height for number cards
}

function reverseLayout(layout, layoutTypeIndex = 3) {
  const layoutType = LayoutTypes[layoutTypeIndex - 1];
  if (!layoutType) {
    throw new Error(`Invalid layoutTypeIndex: ${layoutTypeIndex}`);
  }

  const { cols, partitions } = layoutType;

  let reversedLayout = layout.map((item) => {
    // Calculate the proportion of x within the total cols
    const proportionX = Math.round((item.x / cols) * 100) / 100;

    // Determine the columnNo by comparing proportionX against cumulative partitions
    let cumulativeWidth = 0;
    let columnNo = 0;

    for (let i = 0; i < partitions.length; i++) {
      cumulativeWidth += partitions[i];
      cumulativeWidth=Math.round((cumulativeWidth) * 100) / 100;
      if (proportionX < cumulativeWidth) {
        columnNo = i;
        break;
      }
    }

    // Recalculate the x position based on column index
    const normalizedX = columnNo;

    return {
      ...item,
      columnNo, // Standard column index (0-based)
      rowNo: Math.round(item.y), // Standard row number (keep y as-is)
      x: normalizedX, // Column index instead of scaled x
      y: Math.round(item.y), // Ensure y is rounded to an integer
    };
  });

  return reversedLayout;
}

function generateLayout(cards, layoutTypeIndex = 3) {
 

  const layoutType = LayoutTypes[layoutTypeIndex-1];
  if (!layoutType) {
    throw new Error(`Invalid layoutTypeIndex: ${layoutTypeIndex}`);
  }

  const { cols, partitions } = layoutType;
  let layouts:any = [];

  cards.forEach((card) => {
    // Clamp the columnNo to the valid range
    const maxColumnIndex = partitions.length - 1;
    const columnNo = Math.min(card.columnNo, maxColumnIndex);

    let columnWidth = partitions[columnNo]; // Width of the current column
    let previousColumnsWidth = partitions
      .slice(0, card.columnNo)
      .reduce((sum, width) => sum + width, 0); // Cumulative width before this column
    // Optimize cardHeight by scaling with partition size
    const optimizedHeight = calculateHeight(columnWidth, card.cardHeight);

    let layoutItem = {
      i: card.id.toString(), // Unique identifier for each layout item
      w: Math.round(columnWidth * cols), // Width scaled by the number of columns
      h: optimizedHeight, // Fixed height (can be adjusted as needed)
      x: Math.round((previousColumnsWidth * cols)), // Horizontal position
      y: card.rowNo, // Vertical position
      moved: false,
      static: false,
      id:card.id
    };

    layouts.push(layoutItem);
  });

  return layouts;
}

function findEmptyColumnAndAddConfig(layout, layoutTypeIndex) {
  const layoutType = LayoutTypes[layoutTypeIndex-1];
  const { partitions, cols } = layoutType;
  const columnWidths = partitions.map(partition => Math.round(partition * cols)); // Normalize widths
  const columnXPositions:any = [];

  // Calculate starting x positions for columns
  let currentX = 0;
  for (const width of columnWidths) {
      columnXPositions.push(currentX);
      currentX += width;
  }

  layout=layout.filter(item => !item?.i?.startsWith("empty"))
  // Group items by column x positions
  const columns:any = columnXPositions.map(() => []); // Create empty arrays for each column
  layout.forEach(item => {
      for (let i = 0; i < columnXPositions.length; i++) {
          const startX = columnXPositions[i];
          const endX = columnXPositions[i + 1] || cols; // Last column spans to max cols
          if (item.x >= startX && item.x < endX) {
              columns[i].push(item);
              break;
          }
      }
  });

  // Check for empty columns
  let emptyColumnIndex = -1;
  for (let i = 0; i < columns.length; i++) {
      if (columns[i].length === 0) {
          emptyColumnIndex = i;
          break;
      }
  }

   // Check for empty columns and add placeholders for all empty columns
   const placeholders:any = [];
   columns.forEach((columnItems, columnIndex) => {
       if (columnItems.length === 0) {
           const placeholderConfig = {
               i: `empty${Date.now()}_${columnIndex}`, // Unique ID for the placeholder
               w: columnWidths[columnIndex],
               h: 1,
               x: columnXPositions[columnIndex],
               y: 0, // Place at the top of the column
               moved: false,
               static: true,
               id: `empty${Date.now()}_${columnIndex}`,
           };
           placeholders.push(placeholderConfig);
       }
   });
   layout.push(...placeholders);

  return  layout;
}

export { generateLayout , LayoutTypes, calculateHeight,reverseLayout, findEmptyColumnAndAddConfig};
