import { faHourglassEnd } from "@fortawesome/free-solid-svg-icons";
import * as React from "react";
import { Dropdown } from "react-bootstrap";

export class ConfirmationModal extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      confirmModalContent: props.confirmModalContent,     
      loaderTrue:false,
    };
  }
  handleCancel() {
    this.props.ishandleDeletePosition(false);

  }

  handleDelete() {

    this.setState({loaderTrue:true});
    this.props.ishandleDeletePosition(true);
    // if(this.props.isFalse===false){
    // this.setState({loaderTrue:false});
    // }
  }

  render() {
   
    return (
      <div className="confirmModal">
        {this.state.confirmModalContent.map((item: any, idx: any) => (
          <div id={idx} key={idx}>
            <div className="title">{this.state.confirmModalContent[idx].title}</div>
            <div className="description">{this.state.confirmModalContent[idx].desc}</div>
            <div className="action-link">
              {this.state.confirmModalContent[idx].ActionTrue.trim().length === 0 && (
                <Dropdown.Item className="btn btn-danger" onClick={this.handleCancel.bind(this)}>{this.state.confirmModalContent[idx].cancleAction}</Dropdown.Item>
              )}

              {(this.state.confirmModalContent[idx].ActionTrue.trim().length > 0 ) && (
                <>
                  <Dropdown.Item className="btn wht-bg" onClick={this.handleCancel.bind(this)}>{this.state.confirmModalContent[idx].cancleAction}</Dropdown.Item>
                  <Dropdown.Item className="btn btn-danger"  onClick={this.handleDelete.bind(this)}>{this.state.confirmModalContent[idx].ActionTrue}</Dropdown.Item>
                </>
              )}

           

              {/* <button type="button" className="btn wht-bg" >Cancel</button>
              <button type="button" className="btn btn-danger">test1</button> */}
            </div>
          </div>
        ))}
      </div>
    );
  }
}
