import * as React from 'react';
// import { Form } from 'react-bootstrap'
// import { Hotel } from '../../Common/Services/Hotel';
// import { IHotelResponseDto } from '../../Common/Contracts/IHotel';
import {COAAccountSelector} from "../Reports/COAAccountSelector"
import { AccountingPeriodEnd } from './AccountingPeriodEnd';
import { AccountingPeriodStart } from './AccountingPeriodStart';


export class AccountingPeriodEndSector extends React.Component<any, any> {
    
  private SingleselectChild: any;
  private singleSerch: any;
  constructor(props: any) {
    super(props);
   
    this.SingleselectChild = React.createRef();
    this.singleSerch = React.createRef();
    this.state = {
      hidValue: "Select",
      hotelName: "Select",
      copyFromHid: 0,
      hotelList: [],
    };
    this.isHotelid = this.isHotelid.bind(this);
  }

  isHotelid = (hotelList: any) => {
    this.setState({ copyFromHid: hotelList });
    this.props.handleAccotingSelectorEnd(hotelList);
  };

  resetValue = () => {
    this.singleSerch.current.resetSerchBox();
  };

  componentDidUpdate(){
    this.singleSerch.current.hidSelectModal(this.props?.defaultValue);
  }

  
  componentDidMount() {
    
    // Hotel.getUserHotelAccess("Labor Management").then(async (result: IHotelResponseDto[] | null) => {
    //     if (result != null && result.length > 0) {
    //         let hotelList: any[] = [];
    //             result.forEach((element) => {
    //             let newItem = {} as any;
    //             newItem.hotelID = element.hotelID;
    //             newItem.value = element.lettercode;
    //             newItem.description = element.hotelName;
    //             newItem.isChecked = false;
    //             hotelList.push(newItem);
    //         });
    //         this.setState({ hotelList: hotelList });
    //     }
    // })
    //     .catch((error) => {
    //         // Need to handle this
    //         console.log(error);
    //     })
  }

  render() {
    return (
      <AccountingPeriodEnd
      ddlId= {this.props?.ddlId}
        isAll={this.props?.isAll}
        rptid= {this.props?.reportid}
        ddlValue={this.props?.startvalue}
        notIncludeEnterpriseAccounts={this.props?.notIncludeEnterpriseAccounts}
        defaultValue={this.props?.defaultValue}
        ref={this.singleSerch}
        isHotelid={this.isHotelid.bind(this)}
      />
    );
  }
}
