import * as React from "react";
// import { Form } from "react-bootstrap";
import { MultiSelectSearchAllHid } from "../../Common/Components/MultiSelectSearchAllHid";
import { Hotel } from "../../Common/Services/Hotel";
import { IHotelResponseDto } from "../../Common/Contracts/IHotel";

export class BusinessAccountSelector extends React.Component<any, any> {
  private multiselectChild: any;
  private SingleselectChild: any;
  constructor(props: any) {
    super(props);
    this.multiselectChild = React.createRef();
    this.state = {
      hotelList: [],
      selectedLetterCode: [],
      currentSelection: [],
    };
  }

  componentDidMount() {
    // debugger;
    let getUserHotelAccess: any;
    const { moduleReportId } = this.props;
    if (this.props?.shouldGetAllActiveHotelsOfTenant) {
      getUserHotelAccess = Hotel.getUserHotelAccessUM(
        this.props?.notIncludeEnterpriseAccounts,
        this.props?.payrollSubscriptionRequired
      );
    } else {
      if (this.props?.isAll) {
        if (this.props?.notIncludeEnterpriseAccounts) {
          getUserHotelAccess = Hotel.getUserHotelAccessAllByModuleName(
            true,
            this.props?.showOnlyHotelAccounts,
            this.props?.payrollSubscriptionRequired
          );
        } else {
          getUserHotelAccess = Hotel.getUserHotelAccessAllByModuleName(
            false,
            this.props?.showOnlyHotelAccounts,
            this.props?.payrollSubscriptionRequired
          );
        }
      } else {
        if (this.props?.notIncludeEnterpriseAccounts) {
          getUserHotelAccess = Hotel.getUserHotelAccessAllByModuleName(
            true,
            this.props?.showOnlyHotelAccounts,
            this.props?.payrollSubscriptionRequired
          );
        } else {
          if (moduleReportId === 2089 || moduleReportId === 2162) {
            getUserHotelAccess = Hotel.getUserHotelAccessReport(
              "",
              false,
              this.props?.showOnlyHotelAccounts,
              185,
              this.props?.payrollSubscriptionRequired
            );
          } else {
            getUserHotelAccess = Hotel.getUserHotelAccessAllByModuleName(
              false,
              this.props?.showOnlyHotelAccounts,
              this.props?.payrollSubscriptionRequired
            );
          }
        }
      }
    }
    getUserHotelAccess
      .then(async (result: IHotelResponseDto[] | null) => {
        if (result != null && result.length > 0) {
          // debugger;
          let hotelList: any[] = [];
          result.forEach((element) => {
            let newItem = {} as any;
            newItem.hotelID = element.hotelID;
            newItem.value = element.lettercode;
            newItem.description = element.hotelName;
            newItem.isChecked = false;
            newItem.hotelType = element.hotelType;
            hotelList.push(newItem);
          });
          if (typeof this.props["getLogiUserEnterpriseAccess"] === "function") {
            const hasAccess =
              hotelList.filter((item) => +item.hotelType === 1)?.length > 0
                ? true
                : false;
            this.props.getLogiUserEnterpriseAccess(hasAccess);
          }
          this.setState({ hotelList: hotelList });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  updateHotelList = (newHotelList, oldrows: any = [], isSelectAll: boolean = false) => {
    let isHid: any = "";
    let isDDLReportGroup: boolean = false;
    isDDLReportGroup = oldrows[0]?.type !== undefined && oldrows[0]?.type === 'ddlReportGroup';
    if (!isDDLReportGroup && oldrows.length > 0) {
      oldrows.forEach(o => {
        isHid += o.listhotelid + ",";
      })
      isHid = isHid.replace(/,(\s+)?$/, "");
    }
    this.setState({ currentSelection: newHotelList })
    let hotelList = [...this.state.hotelList];
    if (isSelectAll && newHotelList.length === 0) {
      hotelList = hotelList.map((hotel) => {
        hotel.isChecked = true;
        return hotel;
      });
    } else {
      const newHotelIds = newHotelList.map((r) => r.hotelID);
      hotelList = hotelList.map((hotel) => {
        if ((newHotelIds.includes(hotel.hotelID))) {
          hotel.isChecked = true;
        } else if (isDDLReportGroup || isHid?.includes(hotel.hotelID)) {
          hotel.isChecked = false;
        }
        return hotel;
      });
    }
    this.setState({ hotelList }, () => {
      this.props.handleBusinessAccountChange(
        hotelList?.filter((r) => r?.isChecked)
      );
    });
  };

  removalChips = (removalChips) => {
    let hotelList = [...this.state.hotelList];
    if (removalChips) {
      let parentId = removalChips.uniqueno;
      for (let i = this.state.hotelList.length - 1; i >= 0; i--) {
        if (Number(this.state.hotelList[i].rGroupID) === Number(parentId)) {
          hotelList.splice(i, 1);
        }
      }
      this.setState({ hotelList });
    } else {
      this.setState({ hotelList: [] });
    }
  };

  componentDidUpdate = (prevprops, prevState) => {
    let hotelList = [...this.state.hotelList];
    const { defaultValue } = this.props;
    if (
      (prevState?.hotelList?.length !== hotelList?.length ||
        prevprops.defaultValue !== defaultValue) &&
      hotelList?.length > 0 &&
      defaultValue?.length > 0
    ) {
      let hotelIDs = defaultValue.split(",")?.map((r) => +r);
      const selectedHotels = hotelList?.filter((r) =>
        hotelIDs?.includes(r.hotelID)
      );
      hotelIDs = hotelIDs.filter((r) =>
        selectedHotels?.map((r) => r?.hotelID)?.includes(r)
      );
      hotelList = hotelList?.map((item) => {
        if (hotelIDs?.includes(item?.hotelID)) {
          item.isChecked = true;
        } else {
          item.isChecked = false;
        }
        return item;
      });
      this.setState({ hotelList });
    }

    if (prevprops?.defaultValue !== defaultValue && !defaultValue) {
      let hotelList = [...this.state.hotelList];
      hotelList = hotelList?.map((item) => {
        item.isChecked = false;
        return item;
      });
      this.setState({ hotelList });
    }
  };

  updateMultiSelectItem = (selectedItem: any, hid: any, isClose = false) => {
    let subGroupID: any = "";
    let subGroupName: any = "";
    let isClearGroup = (this.props?.multigroupList || []).length === 0 ? true : isClose;
    if (hid !== "") {
      (this.props?.multigroupList || []).forEach((o: any) => {
        if (!o?.listhotelid?.toString().includes(hid?.toString())) {
          subGroupName += o.subGroupName + ","
        } else {
          subGroupID += `active_${o.subGroupID},`
        }
      });
      subGroupName = subGroupName.toString().replace(/,(\s+)?$/, "");
      subGroupID = subGroupID.toString().replace(/,(\s+)?$/, "");
    }
    this.setState({ selectedLetterCode: selectedItem }, () => {
      this.props.handleBusinessAccountChange(selectedItem, true, hid, subGroupName, subGroupID, isClearGroup);
    });
  };

  updateSingleSearchItem = (selectedItem: any) => {
    this.setState({ selectedLetterCode: selectedItem }, () => {
      this.props.handleBusinessAccountChange(selectedItem, true);
    });
  };

  dataChange = () => {
    this.setState({ isUnsavedChanges: true });
  };

  render() {
    return (
      <div
        id="businessAccountSelector"
        className={
          this.props.isDisabled
            ? "account-selector noPointer"
            : "account-selector"
        }
      >
        <MultiSelectSearchAllHid
          ref={this.SingleselectChild}
          itemList={this.state.hotelList}
          hidofgroup={this.state.currentSelection}
          updateMultiSelectItem={this.updateMultiSelectItem.bind(this)}
          dataChange={this.dataChange.bind(this)}
          isSelectAll={true}
          filtertitle={"Filter EHIDs"}
          moduleReportId={this.props?.moduleReportId}
        />
      </div>
    );
  }
}
