import * as React from "react";
import { Dropdown } from "react-bootstrap";
import { resolve, reject } from "q";
import { ReactComponentLoader } from "../../../../../Common/Components/ReactComponentLoader";
import { ILaborScheduleEmployeeRequestDto } from "../../../../../Common/Contracts/ILaborSchedule";
import { LaborSchedule } from "../../../../../Common/Services/LaborSchedule";
import profile from "../../../../../Common/Assets/Images/profile.png";

export class ReassignAllModal extends React.Component<any, any> {
  private inputElement: any;
  constructor(props: any) {
    super(props);
    this.inputElement = React.createRef();
    this.state = {
      loaderTrue: false,
      filterData: [],
      employeeList: [],
      inputValue: "",
    };
  }

  componentDidMount() {
    this.getUserList();
  }

  getUserList = () => {
    this.setState({ loaderTrue: true });
    let request = {} as ILaborScheduleEmployeeRequestDto;

    request.hotelID = this.props.hidValue;
    request.date = this.props.parentState.navigationCalenderDates[0];
    request.inTime = "0:00 AM";
    request.outTime = "0:00 AM";
    request.position = 0;
    request.type = this.props.requestType;
    request.shiftDate = this.props.parentState.navigationCalenderDates[1];
    request.uniqueno = 0;

    LaborSchedule.GetLaborScheduleEmployee(request)
      .then(async (result: any) => {
        let resultData: any = result.result;
        let remainingList = resultData.filter(
          (data: any) =>
            data.employee.toLowerCase() !==
            this.props.row.employeeName.toLowerCase()
        );

        this.setState({
          filterData: remainingList,
          employeeList: remainingList,
        });
        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error: any) => {
        this.setState({ loaderTrue: false });
        reject();
      });
  };

  handleChangeEmployee = (e: any) => {
    const updatedList = this.state.employeeList.filter((item: any) => {
      return (
        item.employee.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
      );
    });
    this.setState({
      filterData: updatedList,
      inputValue: e.target.value,
    });
  };

  handleDropdownChange = (item: any) => {
    this.setState({ inputValue: "" }, () => {
      this.props.handleSelectedItem(item);
    });
  };

  render() {
    return (
      <div className="reassignAllModal">
        {this.state.loaderTrue ? (
          <ReactComponentLoader useas={"scheduleAssignDDN"} />
        ) : (
          <>
            <div className="search-sec">
              <input
                type="text"
                className="form-control"
                onChange={this.handleChangeEmployee}
                placeholder="Search"
                value={this.state.inputValue}
                ref={this.inputElement}
              />
            </div>
            <div className="list-area sos">
              <div className="list-section">
                {this.state.filterData.map((item: any, idx: any) => (
                  <Dropdown.Item
                    className={
                      item.isconflict.toLowerCase() === "incomplete"
                        ? "disabled"
                        : ""
                    }
                    key={idx}
                    eventKey={item.employee}
                    onClick={() => this.handleDropdownChange(item)}
                  >
                    <div className="d-flex" key={idx}>
                      <img
                        src={
                          item.imagePath === null ||
                          item.imagePath.trim() === ""
                            ? profile
                            : item.imagePath
                        }
                        className="profileimg"
                        width="36"
                        height="36"
                        alt=""
                      />
                      <div>
                        <div className="name">{item.employee}</div>
                        <div className="pos-name">{item.positionName}</div>
                      </div>
                      {item.isconflict.toLowerCase() === "incomplete" && (
                        <div className="red-badges">Incomplete</div>
                      )}
                    </div>
                  </Dropdown.Item>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}
