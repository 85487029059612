import React from "react";
import { toast, ToastContainer } from "react-toastify";
import {
  Container,
  Dropdown,
  Form,
  Tab,
  Tabs,
  Row,
  Nav,
  Button,
  Modal,
  Col,
} from "react-bootstrap";
import moment from "moment";
// import { FundingSourceModal } from "./FundingSourceModal";
import { SingleSearchDropdownList } from "../../../Common/Components/SingleSearchDropdownList";
import { Utils } from "../../../Common/Utilis";
import { DropDownList } from "../../Reports/common-components/dropdown-list";
import { StaticArrays } from "../../../Common/StaticArrays";
import { accountService } from "../../../Common/Services/account";
import { PlaidSetup } from "../../../Common/ThirdPartyServices/PlaidSetup";
import { OtpModal } from "../../../Common/Components/OtpModal";

export class ConfirmBankAccount extends React.Component<any, any> {
  private submitBtnRef;
  constructor(props: any) {
    super(props);
    this.submitBtnRef = React.createRef();
    this.state = {
      showConfirmMsg: true,
      step: "1",
      formData: {
        bankName: "",
        accountType: "",
        routingNumber: "",
        accountNo: "",
        accountName: "",
        hotelID: "",
        bankRefID: "",
        amount1: "",
        amount2: "",
        fundingSource: "",
      },
      // bankName: this.props.bankName,
      // accountType: "",
      // routingNumber: "",
      // accountNo: this.props.accountNo,
      // accountName: this.props.accountName,
      // hotelID: this.props?.hotelID,
      // bankRefID: this.props?.bankRefID,
      errors: {
        bankName: "",
        accountType: "",
        routingNumber: "",
        accountNo: "",
        accountName: "",
        amount1: "",
        amount2: "",
      },
    };
  }

  componentDidMount() {}

  validateRoutingNumber = (num: string): boolean => {
    // Run through each digit and calculate the total.
    let n = 0;
    for (let i = 0; i < num.length; i += 3) {
      n +=
        parseInt(num.charAt(i), 10) * 3 +
        parseInt(num.charAt(i + 1), 10) * 7 +
        parseInt(num.charAt(i + 2), 10);
    }
    // If the resulting sum is an even multiple of ten (but not zero),
    // the aba routing number is good.
    if (n !== 0 && n % 10 === 0) {
      return true;
    } else {
      return false;
    }
  };

  isFormValid() {
    debugger;
    let errors = this.state.errors;
    let formData = { ...this.state.formData };
    let isForm = true;
    let microAmtLimit = parseFloat("0.10");
    if (formData.amount1.trim().length === 0) {
      errors.amount1 = "Amount 1 is required.";
      isForm = false;
    }
    // if (formData.amount1.trim().length > 4) {
    //   errors.amount1 = "Only upto 4 digits are required in Amount 1.";
    //   isForm = false;
    // }
    // if (formData.amount1.trim() > microAmtLimit) {
    //   errors.amount1 = "Amount 1 should be less than or equal to $0.10";
    //   isForm = false;
    // }
    if (formData.amount2.trim().length === 0) {
      errors.amount2 = "Amount 2 is required.";
      isForm = false;
    }
    // if (formData.amount2.trim().length > 4) {
    //   errors.amount2 = "Only upto 4 digits are required in Amount 2.";
    //   isForm = false;
    // }
    // if (formData.amount2.trim() > microAmtLimit) {
    //   errors.amount2 = "Amount 2 should be less than or equal to $0.10";
    //   isForm = false;
    // }
    this.setState({ errors });
    if (isForm === true) {
      return true;
    } else {
      return false;
    }
  }

  //   openPlaid = () => {
  //     this.props?.handleShowHideChoiceModal();
  //     this.props?.plaidFunction();
  //   };

  handleCancelSetupAccount = () => {
    this.props?.handleCancelSetupAccount();
  };

  handleContinueManualSetup = () => {
    this.setState({ step: "manualVerificationForm" });
  };
  handleContinue = (step) => {
    if (step === "1") {
      this.setState({ step: "2" });
    } else if (step === "2") {
      // this.setState({ step: "manualVerificationForm" });
    }
  };

  handleChange = (event) => {
    debugger;
    const { name, value } = event.target;
    let errors = this.state.errors;
    let formData = { ...this.state.formData };
    let inputValue = Utils.removeInvaildCharFromAmount(value);
    const rx_live = /^[0-9]*([.][0-9]?[0-9]?)?$/gm; ///^[-]?\d*(?:[.]\d*)?$/;
    let lenbeforePoint = 16;

    if (rx_live.test(inputValue)) {
      if (inputValue.includes(".")) {
        const timeArray = inputValue?.split(".");
        if (timeArray[0].length <= lenbeforePoint && timeArray[1].length <= 2) {
          inputValue = inputValue;
        }
      } else {
        if (inputValue.length <= lenbeforePoint) {
          inputValue = inputValue;
        }
      }
    } else if (inputValue.includes(".") && inputValue.length === 1) {
      inputValue = inputValue;
    } else {
      return false;
    }


    //return inputValue;
    switch (name) {
      case "amount1":
        // errors.amount1 =
        //   inputValue.trim().length === 0 ? "Please enter amount 1." : "";
        formData.amount1 = inputValue;
        break;
      case "amount2":
        // errors.amount2 =
        //   inputValue.trim().length === 0 ? "Please enter amount 2." : "";
        formData.amount2 = inputValue;
        break;
      default:
        break;
    }

    this.setState({ errors, formData });
  };

  validationOnClick = (inputPosition) => {
    this.setState({ inputPosition }, () => this.validateDepositFormSchema());
  };

  validateDepositFormSchema = () => {
    let errors = this.state.errors;
    let formData = { ...this.state.formData };
    let isForm = true;

    if (formData.accountType && errors.accountType) {
      errors.accountType = "";
    }
    this.setState({ errors });
  };

  onFieldChange = (event, inputPosition) => {
    const { formData } = this.state;
    let value = event.target.value;
    const fieldName = event.target.name;
    formData[fieldName] = value;

    this.setState({ formData, inputPosition }, () =>
      this.validateDepositFormSchema()
    );
  };

  addMicroDeposit = () => {
    if (this.isFormValid()) {
      this.setState({ isSpinner: true });
      const {
        accountName,
        accountNo,
        fundingSourceId,
        entityBankRefId,
        hotelId,
      } = this.props.item;
      const reqObj: any = {};
      reqObj.accountName = accountName;
      reqObj.accountNo = accountNo;
      reqObj.accountType = "";
      reqObj.bankName = "";
      reqObj.bankRefID = entityBankRefId;
      reqObj.fundingSource = fundingSourceId;
      reqObj.routingNumber = "";
      reqObj.hotelID = hotelId;
      reqObj.amount1 = this.state.formData.amount1;
      reqObj.amount2 = this.state.formData.amount2;
      this.submitBtnRef.current.disabled = true;
      accountService
        .SaveVerifyMicroDeposits(reqObj)
        .then(async (result: any | null) => {
          if (result !== null) {
            if (result.status === "Success") {
              toast.success(result.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "bankDetailsSlideout",
              });
              setTimeout(() => {
                this.props.buttonClickHandler();
                // this.closePopup();
                // this.refreshPage();
              }, 1000);
              this.submitBtnRef.current.disabled = false;
            } else {
              //   this.closePopup();
              this.setState({ isSpinner: false });
              Utils.toastError(result.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "bankDetailsSlideout",
              });
              this.submitBtnRef.current.disabled = false;
            }
          }
          //   resolve();
        })
        .catch((error) => {
          this.setState({ isSpinner: false });
          Utils.toastError(error.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "bankDetailsSlideout",
          });
          this.submitBtnRef.current.disabled = false;
        });
    }
  };

  //   refreshPage = () => {
  //     this.props?.PlaiddSetupStatus(this.props?.hotelID, this.props?.bankRefID);
  //     this.refreshBankSlidOut();
  //   };

  refreshBankSlidOut() {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let selectedExistAcc = { ...this.state.selectedExistAcc };
    selectedExistAcc.oprID = this.props.oprID;
    selectedExistAcc.hotelID = this.props?.hotelID;
    selectedExistAcc.tenantID = tenantID;
    selectedExistAcc.obaccno = this.props.accountNo;
    this.props?.bindFormData(selectedExistAcc);
  }

  cancelClickHandler = () => {
    this.setState({
      showConfirmMsg: true,
      confirmBankForm: false,
      errors: {
        bankName: "",
        accountType: "",
        routingNumber: "",
        accountNo: "",
        accountName: "",
        amount1: "",
        amount2: "",
      },
      formData: {
        bankName: "",
        accountType: "",
        routingNumber: "",
        accountNo: "",
        accountName: "",
        hotelID: "",
        bankRefID: "",
        amount1: "",
        amount2: "",
        fundingSource: "",
      },
    });
  };

  confirmDepositButtonClick = (isOTPVerified) => {
    if (isOTPVerified) {
      this.setState({
        confirmBankForm: true,
        showConfirmMsg: false,
      });
    } else {
      this.setState(
        {
          isOtpNeed: true,
          Purpose: "ConfirmDeposit",
        },
        () => {
          this.ReSendOTP();
        }
      );
    }
  };

  ReSendOTP = () => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    // debugger
    let userName = storage === null ? 0 : (storage.userName as any);
    let request: any = {};
    request.userName = userName;
    request.purpose = this.state.Purpose;
    request.otpCode = "";
    request.primaryContact = "Phone";
    accountService
      .OTPForAuthentication(request)
      .then(async (result: any | null) => {
        if (result !== null) {
          if (result.status === "Success") {
            toast.success(result.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "userSlideOut",
            });

            this.setState({ isOtpNeed: true });
          } else {
            Utils.toastError("Something went wrong", {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "userSlideOut",
            });
          }
        }
      });
  };

  closeOtpModal = () => {
    if (this.state.isOtpNeed) {
      this.setState({ isOtpNeed: false });
    }
  };

  ValidateOTP = (OTPCode) => {
    this.setState(
      {
        isOtpNeed: false,
        confirmBankForm: true,
        showConfirmMsg: false,
      },
      () => {
        this.props.verifiedOTP();
      }
    );
  };
  render() {
    const { step, errors, formData, showConfirmMsg } = this.state;
    const { isSpecialPermissionUser, certifiedUser, isOTPVerified } =
      this.props;
    const { dwolla_customersDetail } = this.props.hotelDetails;

    return (
      <div id="" className="confirm-bank-section">
        <ToastContainer containerId={"userSlideOut"} autoClose={3000} />
        <div
          className={`${
            showConfirmMsg ? "confirm-bank-setup" : "confirm-form-setup"
          }`}
        >
          {this.state.confirmBankForm && (
            <div className="setup-header">
              <div className="heading">Confirm Bank Account</div>
            </div>
          )}
          {this.state.showConfirmMsg && (
            <div className="setup-body">
              <div className="body-description mb-0">
                We sent 2 small deposits (less than $0.10) to your bank account
                and retrieved them in 1 withdrawal. This typically takes 1-3
                business days.
              </div>
            </div>
          )}
          {this.state.confirmBankForm && (
            <div className="setup-body">
              <div className="body-heading">
                Select an Inn-Flow Bank Account
              </div>
              <div className="body-description">
                Once configured, you will be able to make payments throught
                Digital ACH from this account for this property.
              </div>

              <div className="body-section">
                <div className="form-section d-flex flex-row justify-content-between">
                  <Form.Group controlId="amount1">
                    <div
                      className={
                        errors.amount1.length > 0 ? "validation-error" : ""
                      }
                    >
                      <Form.Label>Deposit 1 Amount</Form.Label>
                      <input
                        type="text"
                        className="form-control"
                        id="amount1"
                        name="amount1"
                        autoComplete="amount1"
                        onChange={this.handleChange}
                        placeholder="$0.00"
                        value={formData.amount1}
                      />
                      {errors.amount1.length > 0 && (
                        <span className="validation-message">
                          {errors.amount1}
                        </span>
                      )}
                    </div>
                  </Form.Group>
                  <Form.Group controlId="amount2">
                    <div
                      className={
                        errors.amount2.length > 0 ? "validation-error" : ""
                      }
                    >
                      <Form.Label>Deposit 2 Amount</Form.Label>
                      <input
                        type="text"
                        className="form-control"
                        id="amount2"
                        name="amount2"
                        autoComplete="amount2"
                        onChange={this.handleChange}
                        placeholder="$0.00"
                        value={formData.amount2}
                      />
                      {errors.amount2.length > 0 && (
                        <span className="validation-message">
                          {errors.amount2}
                        </span>
                      )}
                    </div>
                  </Form.Group>
                </div>
              </div>
            </div>
          )}

          <div className="setup-footer">
            <div>
              {this.state.confirmBankForm && (
                <>
                  <Button
                    className="btn-ghost"
                    onClick={() => {
                      this.cancelClickHandler();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn btn-primary"
                    onClick={this.addMicroDeposit}
                    ref={this.submitBtnRef}
                  >
                    Submit
                  </Button>
                </>
              )}

              {this.state.showConfirmMsg && (
                <Button
                  className="btn btn-primary"
                  disabled={
                    !isSpecialPermissionUser ||
                    !certifiedUser ||
                    dwolla_customersDetail?.dwollaCustStatus === "unverified"
                  }
                  onClick={() => {
                    this.confirmDepositButtonClick(isOTPVerified);
                    // this.setState({
                    //   confirmBankForm: true,
                    //   showConfirmMsg: false,
                    // });
                  }}
                >
                  Confirm Deposits
                </Button>
              )}
            </div>
          </div>
          {this.state.isOtpNeed && (
            <OtpModal
              closePopup={this.closeOtpModal.bind(this)}
              ValidateOTP={this.ValidateOTP.bind(this)}
              ReSendOTP={this.ReSendOTP.bind(this)}
              purpose={this.state.Purpose}
            ></OtpModal>
          )}
        </div>
      </div>
    );
  }
}
