import * as React from 'react';
import { Container, Dropdown, Form, Button, OverlayTrigger, Tooltip, Modal, Spinner } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import TableSearch from "../../Common/Components/TableSearch";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { CreateContact } from "./component/CreateContact";
import { ContactSlideout } from "./component/ContactSlideout";
import { SalesService } from "../../Common/Services/SalesService";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import "./sales.scss";
import { Utils } from '../../Common/Utilis';

export class Contacts extends React.Component<any, any> {
    private tableSearch: any;
    constructor(props: any) {
        super(props);
        this.tableSearch = React.createRef();
        this.state = {
            accountsPermissionArray: props.accountsPermissionArray,
            searchvalue: "Search...",
            createNewContact: false,
            selectedAccount: props.selectedAccount,
            contactData: [],
            isShowContactSlideout: false,
            selectedContact: {},
        };
    }

    componentDidMount() {
        this.GetSalesContactById();
    }

    rowEvents = {
        onClick: (e: any, row: any, rowIndex: any) => {
            debugger;
            var child = e.target;
            var parent = child.parentNode;
            // equivalent of parent.children.indexOf(child)
            // var index = Array.prototype.indexOf.call(parent.children, child);
            if (parent.children[0]?.id === "DeActive") {               
              
            }
            else {
                this.setState({ selectedContact: row, }, () => {
                    this.setState({ isShowContactSlideout: true });
                })
                
            }
        }
    }
    closeContactModal = (name, type) => {
        if (name === "contact") {
            this.setState({ createNewContact: false }, () => {
                if (type === "refresh") {
                    this.GetSalesContactById();  // API to refresh contact list
                }
            });
        };
    };
    showContactModal = () => {
        this.setState({ createNewContact: true });
    }

    GetSalesContactById = () => {
        debugger;
        let request: any = {};
        request.ParentID = this.state.selectedAccount.accountID;
        request.PageName = "AccountContactList";
        SalesService.GetSalesContactById(request)
            .then(async (result: any | null) => {
                // debugger;
                if (result != null) {
                    this.setState({ contactData: result.result })
                }
                resolve();
            })
            .catch((err: any) => {
                Utils.toastError("Something went wrong. Failed to fetch Market Segment.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "contacts-1a",
                });
                reject();
            });
    }

    CleanSearch = () => {
        if (this.tableSearch.current !== null) {
            this.tableSearch.current.CleanSearch();

        }
    };

    UpdateSalesContactStatus = (row: any) => {

        let ContactItem: any = {};
        ContactItem.ContactId = row.contactID;

        SalesService.UpdateSalesContactStatus(ContactItem)
            .then(async (result: any | null) => {
                // debugger;
                if (result.saveStatus.toLowerCase() === "success") {
                    toast.success(result.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        containerId: "SalesAcc1",
                    });

                    this.GetSalesContactById();
                }
                resolve();
            })
            .catch((error) => {
                Utils.toastError("Something went wrong.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "SalesAcc1",
                });
                reject();
            });

    }

    closeSlideout = (name, type) => {
        if (name === "contactSlideout") {
            this.setState({ isShowContactSlideout: false }, () => {
                if (type === "refresh") {
                    this.GetSalesContactById();  // API to refresh contact list
                }
            });
        };
    }


    render() {
        const { accountsPermissionArray } = this.state;
        let isViewOnlyAccount = this.props?.selectedAccount?.viewPermission.toLowerCase() === "view" ? true : false; // Is this a view only account from Accounts Page
        let isCreateAccountPerm = accountsPermissionArray[0]?.createSalesAccount.toLowerCase() === "yes" ? true : false; // same perm for edit/create contact
        const contactColumns = [
            {
                dataField: 'contactName',
                text: "Name",
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        this.setState({ selectedContact: row, }, () => {
                            this.setState({ isShowContactSlideout: true });
                        })
                    },
                  },
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <EllipsisWithTooltip placement="bottom">{row.contactName}</EllipsisWithTooltip>
                    );
                },
            }, {
                dataField: 'title',
                text: "Title",
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        this.setState({ selectedContact: row, }, () => {
                            this.setState({ isShowContactSlideout: true });
                        })
                    },
                  },
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <EllipsisWithTooltip placement="bottom">{row.title}</EllipsisWithTooltip>
                    );
                },
            }, {
                dataField: 'email',
                text: "Email",
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        this.setState({ selectedContact: row, }, () => {
                            this.setState({ isShowContactSlideout: true });
                        })
                    },
                  },
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <EllipsisWithTooltip placement="bottom">{row.email}</EllipsisWithTooltip>
                    );
                },
            }, {
                dataField: 'phone',
                text: "Phone",
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        this.setState({ selectedContact: row, }, () => {
                            this.setState({ isShowContactSlideout: true });
                        })
                    },
                  },
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <EllipsisWithTooltip placement="bottom">{row.phone}</EllipsisWithTooltip>
                    );
                },
            }, {
                dataField: 'status',
                text: "Status",
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        this.setState({ selectedContact: row, }, () => {
                            this.setState({ isShowContactSlideout: true });
                        })
                    },
                  },
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <>
                            {row.status != "" && (
                                <div className={row.status.toLowerCase() === "inactive" ? "status grey" : "status blue"}>
                                    {row.status}
                                </div>
                            )}
                        </>
                    );
                },
            }, {
                dataField: 'dateAdded',
                text: "Date Added",
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        this.setState({ selectedContact: row, }, () => {
                            this.setState({ isShowContactSlideout: true });
                        })
                    },
                  },
            }, {
                dataField: '',
                text: "",
                headerClasses: 'width-72',
                classes: 'width-72',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <Dropdown
                            id="more-action"
                            className="more-action"
                            alignRight
                        >
                            <Dropdown.Toggle
                                className="btn-outline-primary btn btn-primary btn-ghost more"
                                id="dropdown-more"
                            >
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                </svg>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item eventKey={"deactivate"} key={rowIndex} onClick={() => this.UpdateSalesContactStatus(row)}>
                                    {row.status.toLowerCase() === "inactive" ? "Activate" : "Deactivate"}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    );
                },
            }
        ]
        return (
            <div className='contacts list-table'>
                <ToolkitProvider
                    keyField="rNo"
                    data={this.state.contactData}
                    columns={contactColumns}
                    search
                    //rowEvents={this.rowEvents}
                >
                    {(props: {
                        searchProps: JSX.IntrinsicAttributes;
                        baseProps: JSX.IntrinsicAttributes;
                    }) => (
                        <div>
                            <div className="search-header d-flex">
                                <div className="salesSearch">
                                    <TableSearch
                                        id="tableSearch"
                                        key="tableSearch"
                                        ref={this.tableSearch}
                                        {...props.searchProps}
                                        CleanSearch={this.CleanSearch.bind(this)}
                                        placeholder={"Search"}
                                    />
                                </div>
                                {!isViewOnlyAccount && isCreateAccountPerm &&
                                    <Button variant="primary" type="button" className="ml-auto height-32" onClick={this.showContactModal}>
                                        <div className='d-flex align-items-center'>
                                            <div>Add Contact </div>
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.0002 0.700195C6.54654 0.700195 6.17846 1.06828 6.17846 1.52193V6.17846H1.52193C1.06828 6.17846 0.700195 6.54654 0.700195 7.0002C0.700195 7.45385 1.06828 7.82193 1.52193 7.82193H6.17846V12.4785C6.17846 12.9321 6.54654 13.3002 7.0002 13.3002C7.45385 13.3002 7.82193 12.9321 7.82193 12.4785V7.82193H12.4785C12.9321 7.82193 13.3002 7.45385 13.3002 7.0002C13.3002 6.54654 12.9321 6.17846 12.4785 6.17846H7.82193V1.52193C7.82193 1.06828 7.45385 0.700195 7.0002 0.700195Z" />
                                            </svg>
                                        </div>
                                    </Button>
                                }
                            </div>
                            <div className="tbl-sec">
                                <div className="scroll-area">
                                    <BootstrapTable
                                        {...props.baseProps}
                                        keyField="id"
                                        hover
                                        //rowEvents={this.rowEvents}
                                    //noDataIndication={'No activity for the selected time period'}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </ToolkitProvider>
                {this.state.createNewContact && (
                    <CreateContact
                        closeModal={this.closeContactModal.bind(this)}
                        accountId={this.state.selectedAccount.accountID}
                    >
                    </CreateContact>
                )}
                {this.state.isShowContactSlideout && (
                    <ContactSlideout
                        selectedAccount={this.props.selectedAccount}
                        closeSlideout={this.closeSlideout.bind(this)}
                        selectedContactId={this.state.selectedContact?.contactID}
                    >
                    </ContactSlideout>
                )}
            </div>
        );
    }
}