import React from "react";
import { Dropdown, Modal, Button, Form, Table } from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { SingleSelectActiveUserSearchWithImage } from "../../../../../Common/Components/SingleSelectActiveUserSearchWithImage";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LaborPTOCalculation as laborPTOCalculation } from "../../../../../Common/Services/LaborPTOCalculation";
import {
  ILaborScheduleEmployeeRequestDto,
  ISaveEditPTORequest,
} from "../../../../../Common/Contracts/ILaborSchedule";
import { LaborSchedule } from "../../../../../Common/Services/LaborSchedule";
import { HidSelector } from "../../../../../Common/Components/HidSelector";
//import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
//import OutsideClickHandler from "react-outside-click-handler";
import { LaborPerformance as laborPerformance } from "../../../../../Common/Services/LaborPerfomance";
import profile from "../../../../../Common/Assets/Images/profile.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import { faLaptopHouse, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { truncateSync } from "fs";
import { FiTrendingUp } from "react-icons/fi";
import { confirmAlert } from "react-confirm-alert";
import { resolve, reject } from "q";
import "./AddEditTimeOffShiftModal.scss";
import { ReactPageLoader } from "../../../../../Common/Components/ReactPageLoader";
import { Utils } from "../../../../../Common/Utilis";

//let isDuplicateEntry = false;
export class AddEditTimeOffShiftModal extends React.Component<any, any> {
  private textArea: any;
  private firstSingleSelect: any;
  private childHID: any;
  private noSchedule: any = { ptoTypeID: -1, timeOffType: "Do Not Schedule" };
  private uTO: any = { ptoTypeID: -2, timeOffType: "UTO" };
  private isSaveRequest:any;

  constructor(props: any) {
    super(props);
    this.isSaveRequest = React.createRef();
    this.textArea = React.createRef();
    this.firstSingleSelect = React.createRef();
    this.childHID = React.createRef();
    this.state = {
      hidValue: this.props.parentState.hidValue,
      hotelName: "Select",
      employeeList: [],
      selectedEmployee: {},
      timeOffReason: "",
      selectedTimeOffType: {},
      timeOffTypes: [],
      isBack: false,
      loaderTrue: false,
      tableData: [],
      dateList: [],
      calenderSelectDate: "",
      modalShiftOverlaps: false,
      modalShiftOverlapsData: "",
      modalShiftOverlapsType: "",
      IsRequestEntry: "no",
      IsForceApprove: "no",
      calenderShow: false,
      navigationCalenderDatesforMyPto: [],
      inputValue: "",
      selectedEmploye: "",
      filterData: [],
      shiftStartDate: "",
      shiftEndDate: "",
      isNewRow: false,
      formHasError: false,
      typeLoader: false,
      isPayrollExported:false,
      popup: false,
      isNetchexpopup : false,
      popupNetchex : false
     
    };
  }

  componentDidMount() {
    let fromPage = this.props.parentState.fromPage
    if (fromPage !== undefined) {
      if (fromPage.toString().toLowerCase() === "MyPTOTimeoff".toLowerCase()) {
        let e = new Date();
        let calenderYear = e.getFullYear();
        let calenderDate = e.getDate();
        let calenderMonth = e.getMonth() + 1;
        let tempDate = calenderMonth + "/" + calenderDate + "/" + calenderYear;
        let resultData: any = [];
        let tempData: any = {};
        tempData.employee = JSON.parse(localStorage.storage).userName;
        tempData.imagePath = "";
        tempData.rowno = 1;
        resultData.push(tempData);
        this.setState({
          employeeList: resultData,
          calenderSelectDate: tempDate,
          filterData: resultData,
          selectedEmployee: tempData,
        });
        this.handleChangeEmployee(tempData);
      }
    }
    this.getEmployeeData();
    this.prepareDateList();
  }

  handleChangeEmployee = (value) => {
    this.setState({ selectedEmploye: value.employee });
    if (this.state.hidValue !== "Select") {
      this.setState({ selectedEmployee: value, selectedTimeOffType: {} }, () => {
        this.onEmployeeChange(value);
      });
    }
  };

  selectHotelDropDown = (hotelDetails: any) => {
    this.setState(
      { hotelName: hotelDetails.lettercode, hidValue: hotelDetails.hotelID },
      () => {
        //this.getEmployeeData();
        this.getPTOBalance();
      }
    );
  };

  prepareDateList = () => {
    const { navigationCalenderDates } = this.props.parentState;
    const dateList: string[] = [];
    for (let i = 0; i < 7; i++) {
      let weekStartDate: any = new Date(navigationCalenderDates[0]);
      weekStartDate.setDate(weekStartDate.getDate() + i);
      let mnths: any = (new Date(weekStartDate).getMonth() + 1).toString().padStart(2, "0");
      let days: any = new Date(weekStartDate).getDate().toString().padStart(2, "0");
      let year: any = new Date(weekStartDate).getFullYear().toString().substring(2, 4);
      let formattedDate = `${mnths}/${days}/${year}`;
      dateList.push(formattedDate);
    }
    this.setState({ dateList });
  };

  addNewEntry = () => {
    const item = {
      startDate: "",
      startTime: "",
      endDate: "",
      endTime: "",
      duration: "",
      totalMinutes: 0,
      weekStartDate: "",
      weekEndDate: "",
      isDuplicateEntry: false,
      isDnsChecked: false,
    };
    const tableData = [...this.state.tableData, ...[item]];
    this.setState({ tableData, isNewRow: true });
  };

  getPTOBalance = async () => {
    const {
      selectedEmployee: { employee },
    } = this.state;

    let currentDate: any;
    currentDate =
      new Date().getMonth() +
      1 +
      "/" +
      new Date().getDate() +
      "/" +
      new Date().getFullYear();

    this.toggleLoader();
    try {
      let hid: any;
      if (this.props.parentState.fromPage !== undefined) {
        hid = this.state.hidValue;
      } else {
        hid = this.props.parentState.hidValue;
      }

      const response = await Promise.all([
        laborPTOCalculation.getPTOBalance(
          hid,
          this.props.parentState.fromPage !== undefined
            ? this.state.selectedEmploye
            : employee,
          currentDate
        ),
        LaborSchedule.getEmployeeTypeByUserName(
          this.props.parentState.fromPage !== undefined
            ? this.state.selectedEmploye
            : employee
        ),
      ]);
      if (response[0] != null) {
        const timeOffTypes = response[0]?.map(
          (x: {
            hours: string;
            availableHours: string | string[];
            ptoTypeID: string;
          }) => {
            if (!(x.ptoTypeID === "-1" || x.ptoTypeID === "-2")) {
              const hours = x?.hours.replace("hrs", "");
              x.availableHours = (hours.includes(",") || hours.includes(";")
                ? (hours?.split(",").length > 1
                  ? hours?.split(",")[0]
                  : hours?.split(";")[0]
                )?.trim()
                : hours
              )?.trim();
              x.availableHours = !x.availableHours.includes(":")
                ? x.availableHours + ":00"
                : x.availableHours;
            }
            return x;
          }
        );
        this.setState({
          timeOffTypes: [...timeOffTypes],
          typeLoader: false
        });
      }
    } catch (error: any) {
      Utils.toastError(error.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "scheduleMain",
      });
    } finally {
      this.toggleLoader();
    }
  };

  getEmployeeData = async () => {
    debugger;
    let request = {} as ILaborScheduleEmployeeRequestDto;
    if (this.props.parentState.fromPage !== undefined) {
      request.hotelID = this.state.hidValue;
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth() + 1;
      var yyyy = today.getFullYear();
      request.date = mm + "/" + dd + "/" + yyyy;
      request.shiftDate = mm + "/" + dd + "/" + yyyy;
    } else {
      request.hotelID = this.props.parentState.hidValue;
      request.date = this.props.parentState.navigationCalenderDates[0];
      request.shiftDate = this.props.parentState.navigationCalenderDates[1];
    }
    var _pageType=this.props.parentState.fromUrl;
    request.inTime = "0:00 AM";
    request.outTime = "0:00 AM";
    request.position = 0;
    if(_pageType==="LaborTimesheet")
    { 
    request.type = "addEditTimeofTimeSheet";
    }
    else
    {
    request.type = "addEditTimeof";
    }
    request.uniqueno = 0;
    this.toggleLoader();
    try {
      const response: any = await LaborSchedule.GetLaborScheduleEmployee(request);
      this.setState({
        employeeList: response?.result?.length > 0 ? response?.result?.sort((a: { employee: number }, b: { employee: number }) => a.employee > b.employee ? 1 : -1) : [],
      });
      // this.setState({filterData:response?.result?.length > 0
      //   ? response?.result?.sort(
      //       (a: { employee: number }, b: { employee: number }) =>
      //         a.employee > b.employee ? 1 : -1
      //     )
      //   : [],});
    } catch (error: any) {
      Utils.toastError(error.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "scheduleMain",
      });
    } finally {
      this.toggleLoader();
    }
  };

  addEditTimeOffShiftModalClose = (successMessage = ""): void => {
    this.setState({ IsRequestEntry: "no", IsForceApprove: "no" });
    this.props.addEditTimeOffShiftModalHide(successMessage);
  };

  onTextBlur = (e: any) => {
    let value = e.target.value.trim();
    this.setState({ timeOffReason: value });
  };

  onTextChange = (e: any) => {
    // let value = e.target.value;
    // this.setState({ timeOffReason: value });

    // let value = e.target.value;
    // if (/^[A-Za-z0-9\-,.:<>$@*+!#%&_() ]+$/.test(value) === false) {
    //   e.target.value = value.replace(/[^A-Za-z0-9\-,.:<>$@*+!#%&_() ]/g, "");
    // }
    // if (value.length > 50) {
    //   let a = value.replace(/[^A-Za-z0-9\-,.:<>$@*+!#%&_() ]/g, "");
    //   e.target.value = a.substring(0, value.length - 1);
    // }
    this.setState({ timeOffReason: e.target.value });
  };

  getDateList = (): string[] => {
    const { dateList, tableData } = this.state;
    return dateList.filter((f: any) => !tableData.map((r: { startDate: any }) => r.startDate).includes(f));
  };

  onTypeChange = (selectedTimeOffType: any) => {  

    this.setState({ selectedTimeOffType },()=>{
      this.setState({tableData:[]});
    });
  };

  onTypeClick = () => {
    if (
      this.state.hidValue === "Select" &&
      this.props.parentState.fromPage !== undefined
    ) {
      Utils.toastError("Please select hotel first.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "scheduleMain",
      });
      return;
    }
  };

  toggleLoader = () => {
    this.setState((preState)=>({ loaderTrue: !preState.loaderTrue }));
  };

  handleModalShiftClose = () => {
    this.setState({
      modalShiftOverlaps: false,
      loaderTrue: false,
      IsRequestEntry: "no",
      IsForceApprove: "no",
    });
  };

  getPayPeroid(dateSelected, index) {
    let hotelID = this.state.hidValue;
    laborPerformance
      .getPayPeriodDates(hotelID, dateSelected, "Weekly")
      .then(async (result: any[] | null) => {
        if (result != null) {
          const tableData = this.state.tableData;
          tableData[index].weekStartDate = result[0].startdate;
          tableData[index].weekEndDate = result[0].enddate;
          this.setState({ tableData });
        }
      });
  }


  saveTimeOffRequest=async(e: any)=>{
        e.preventDefault();
    
    if(this.state.selectedTimeOffType.timeOffType===undefined)
    {
      Utils.toastError("Please select pto type", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "scheduleMain",
      });
      return;
    }

    if (this.getPostEntryBalance()?.startsWith("-")) {     
        setTimeout(() => {         
          Utils.toastError("Post Entry Balance should not be less than 0.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "scheduleMain",
          });
          
        }, 400);       
        return;     
       }


    let isOverlap=true;
    const {
      tableData,
      selectedEmployee,
      selectedTimeOffType,
      timeOffReason,
    } = this.state;
    // for (let i = 0; i < tableData.length; i++) {
    //   let srartdateA: any = new Date(new Date(tableData[i].startDate).toLocaleDateString("en-US") + " " + tableData[i].startTime);
    //   let endDateA: any = new Date(new Date(tableData[i].endDate).toLocaleDateString("en-US") + " " + tableData[i].endTime);
    //    isOverlap=  this. cheackDuplicateEntry(srartdateA, endDateA);
    //   if(isOverlap===false){

    //     break;
    //   }
    // }
    isOverlap=this.cheackDuplicateEntry();
    if(!isOverlap){
     this.isCheckDuplicatePop();
    }else{
      let hour=this.getHoursFromMinutes(this.getTotalDurationMinutes());
      this.isConfirmSubmitPop(e,hour);
    }




  }


  addEditTimeOffShiftSave = async (e: any,type:any) => {
  //  this.setState({isPayrollExported : true})

    // if(this.props.parentState.exportedPayroll === "Yes" && type === "Yes")
    console.log("this.props.parentState",this.props.parentState);
    
    if(this.props.parentState.exportedPayroll === "Yes" && type === "Yes")
    {
      if(this.props.parentState.exportFormat === "NETCHEX" && this.props.parentState.isPayrollIntegration === "Yes")
      {
        this.setState({isNetchexpopup : true, popupNetchex:true});
      }
      else
      {
        this.setState({isPayrollExported : true, popup:true});
      }
      // this.ConfirmPayrollExport(e,type);
      
    }
    else{
      e.preventDefault();
      debugger;
      //this.setState({isSaveRequest:true});
      if(this.isSaveRequest.current!=null){
        this.isSaveRequest.current.disabled=true;
      }
      let btnid = e.target.id;
      const {
        tableData,
        selectedEmployee,
        selectedTimeOffType,
        timeOffReason,
      } = this.state;
      let fromPage = this.props.parentState.fromPage;
      let fromUrl = this.props.parentState.fromUrl;
      let isFromPage = fromPage !== null && fromPage !== undefined && fromPage.toString().toLowerCase() === "MyPTOTimeoff".toLowerCase()
      let hasSchedule = fromUrl !== null && fromUrl !== undefined && fromUrl.toString().toLowerCase() === "ScheduleMain".toLowerCase();
      let hasTimesheet = fromUrl !== null && fromUrl !== undefined && fromUrl.toString().toLowerCase() === "LaborTimesheet".toLowerCase();

      let Type = "";
      if (isFromPage) {
        if (selectedTimeOffType.ptoTypeID === "-1") {
          Type = "RequestedDNSMyTimeOff";
        } else if (selectedTimeOffType.ptoTypeID === "-2") {
          Type = "RequestedUTOMyTimeOff";
        } else {
          Type = "RequestedPTOMyTimeOff";
        }
      } else if (hasSchedule) {
        if (selectedTimeOffType.ptoTypeID === "-1") {
          Type = "ApprovedDNSSchedule";
        } else if (selectedTimeOffType.ptoTypeID === "-2") {
          Type = "ApprovedUTOSchedule";
        } else {
          Type = "ApprovedPTOSchedule";
        }
      } else if (hasTimesheet) {
        if (selectedTimeOffType.ptoTypeID === "-1") {
          Type = "ApproveDNSTimeSheet";
        } else if (selectedTimeOffType.ptoTypeID === "-2") {
          Type = "ApprovedUTOTimeSheet";
        } else {
          Type = "ApprovedPTOTimeSheet";
        }
      }

      if (tableData.length === 0) return;
      let request: ISaveEditPTORequest[];
      request = tableData.map((item: {
        startDate: any;
        startTime: any;
        endDate: any;
        endTime: any;
      }) => {
        const newItem: ISaveEditPTORequest = {
          HotelID: this.state.hidValue,
          UserName: selectedEmployee.employee,
          PTOUniqueno: +selectedTimeOffType?.ptoTypeID,
          StartDate: this.setCalcFormat(item.startDate),
          EndDate: this.setCalcFormat(item.endDate),
          ScheduleId: 0,
          WeekStartDate: this.props.parentState?.navigationCalenderDates[0],
          WeekEndDate: this.props.parentState?.navigationCalenderDates[1],
          Memo: timeOffReason,
          InTime: item.startTime,
          OutTime: item.endTime,
          IsRequestEntry: isFromPage ? "yes" : this.state.IsRequestEntry,
          IsForceApprove: this.state.IsForceApprove, //no
          Status: '',
          PageSource: Type,
        };
        return newItem;
      }
      );
      try {
        this.setState({ loaderTrue: true });
        const response: any = await LaborSchedule.RaiseTimeOff(request);
        let data = response.result;
        let hasTable;
        let hasString;
        let hasString2;
        if (response.success) {
          if(this.isSaveRequest.current!=null){
            this.isSaveRequest.current.disabled=false;
          }
        // this.setState({isSaveRequest:false});
          (isFromPage || hasTimesheet) && (toast.success(response.message, { position: toast.POSITION.BOTTOM_RIGHT, containerId: "scheduleMain", }))
          this.toggleLoader();
          hasSchedule && this.props.addShiftRefresh();
          hasTimesheet && this.props.timeSheetRefresh();
          this.addEditTimeOffShiftModalClose(response?.message);
          if (btnid === "yes" && hasSchedule) {
            this.props.isPublishOrNot(true);
          }
        } else {
          if (data !== null) {
            if (data.toString().toLowerCase() === "actual") {
              this.setState({ IsRequestEntry: "yes" });
              hasTable = response?.message.includes("<table>");
              hasString = response?.message.includes(
                "Time Off shift overlaps with this one"
              );
              hasString2 = response?.message.includes(
                "appropriate permissions and will need"
              );

              if (hasTable) {
                this.setState({
                  modalShiftOverlaps: true,
                  modalShiftOverlapsData: response?.message,
                  modalShiftOverlapsType: "actual",
                });
              } else if (hasString) {
                this.setState({
                  modalShiftOverlaps: true,
                  modalShiftOverlapsData: response?.message,
                  modalShiftOverlapsType: "actual",
                });
              } else if (hasString2) {
                this.setState({
                  modalShiftOverlaps: true,
                  modalShiftOverlapsData: response?.message,
                  modalShiftOverlapsType: "actual",
                });
              }
            } else if (data.toString().toLowerCase() === "schedule") {
              this.setState({ IsForceApprove: "yes" });
              hasTable = response?.message.includes("<table>");
              hasString = response?.message.includes(
                "approved as an already approved Time Off shift"
              );
              if (hasTable) {
                this.setState({
                  modalShiftOverlaps: true,
                  modalShiftOverlapsData: response?.message,
                  modalShiftOverlapsType: "schedule",
                });
              } else if (hasString) {
                this.setState({
                  modalShiftOverlaps: true,
                  modalShiftOverlapsData: response?.message,
                  modalShiftOverlapsType: "schedule",
                });
              }
            } else {
              Utils.toastError(response?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "scheduleMain",
              });
            }
            this.toggleLoader();
          } else {
            Utils.toastError(response?.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "scheduleMain",
            });
            this.toggleLoader();
          }
        }
      } catch (error: any) {
        Utils.toastError(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "scheduleMain",
        });
      } finally {
        this.setState({ loaderTrue: false });
        if(this.isSaveRequest.current!=null){
          this.isSaveRequest.current.disabled=false;
        }
        //this.toggleLoader();
      }
      this.setState({isNetchexpopup : false, popupNetchex:false});
   }
  };

  onEmployeeChange = (item: any) => {
    if (this.props.parentState.fromPage !== undefined) {
      if (this.state.hidValue !== "Select") {
        this.setState(
          { selectedEmployee: item, selectedTimeOffType: {}, },
          () => {
            this.getPTOBalance();
          }
        );
      } else {
        Utils.toastError("Please select hotel first.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "scheduleMain",
        });
        return;
      }
    } else {
      this.setState({ selectedEmployee: item, selectedTimeOffType: {}, typeLoader: true }, () => {
        this.getPTOBalance();
      });
    }
  };

  removeEntry = (index: any) => {
    

    this.isRequestDeleteConfirmPop(index);

  };

  DeleteRemoveRow= (index: any) => {
  debugger;
     let formHasError=false;

      const tableData = this.state.tableData;   
      tableData.splice(index, 1);
      this.setState({ tableData});
     if (this.getPostEntryBalance()?.startsWith("-")) { 

        


      if(this.isSaveRequest.current!=null){
        this.isSaveRequest.current.disabled=true;
      }
      formHasError=true;
      this.setState({ tableData, formHasError: formHasError });
     // setTimeout(() => {         
        Utils.toastError("Post Entry Balance should not be less than 0.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "scheduleMain",
        });
        
      //}, 400);  

      
      return;

    } 

    let isOverlap=  this.cheackOVerlapWithRedError();

    if (isOverlap === false) {
      Utils.toastError("Time Off request is overlapping.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "scheduleMain",
      });
        formHasError = true;
      // tableData[index].formHasError = formHasError;    
      this.setState({ tableData, formHasError: formHasError });
      if(this.isSaveRequest.current!=null){
        this.isSaveRequest.current.disabled=true;
      }
      return;
    }

    if (!this.getPostEntryBalance()?.startsWith("-") && isOverlap) {
      tableData.map((x,index) => {
        tableData[index].formHasError = formHasError;
      });
      this.setState({ tableData, formHasError: false});
      if(this.isSaveRequest.current!=null){
        this.isSaveRequest.current.disabled=false;
      }
     }
    
   
    
  }


  AddRowEntry = (index: any) => {
  
    
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableData))
    );
   // let dates = this.state.headerFullDates;
    let rowNo = data.length;
    let lastIndex = data.length - 1;

    let newTableData: any = [];
    //let Start_Date=slicedData[lastsliceIndex].startDate
    let End_date=this.prepareEndDate(index);    

    if (index !== lastIndex) {
      let slicedData = data.slice(0, index + 1);
      let restData = data.slice(index + 1);
      if (slicedData.length > 0) {
        slicedData.map((item: any) => newTableData.push(item));
      }
      let lastsliceIndex = slicedData.length - 1;

      let newRow: any = {};
      newRow.startDate= moment(slicedData[lastsliceIndex].startDate).add(1, "days");
      newRow.startTime=  slicedData[lastsliceIndex].startTime;
      newRow.endDate=End_date;//slicedData[lastsliceIndex].endDate;
      newRow.endTime=  slicedData[lastsliceIndex].endTime;
      newRow.duration=  slicedData[lastsliceIndex].duration;
      newRow.totalMinutes= slicedData[lastsliceIndex].totalMinutes;
      newRow.weekStartDate= slicedData[lastsliceIndex].weekStartDate;
      newRow.weekEndDate= slicedData[lastsliceIndex].weekEndDate;
      newRow.isDnsChecked= slicedData[lastsliceIndex].isDnsChecked;
      newRow.isDuplicateEntry= false;   

      //newRow.isDNSRows= ptotype === "-1" ? true : false;  

      newTableData.push(newRow);
      if (restData.length > 0) {
        restData.map((item: any) =>{
         // item.rowNo = item.rowNo +1;
         return newTableData.push(item)
        });
      }    
      this.setState({ tableData:newTableData },()=>{
        this.timeValidations(index+1);
      });


    }else{
      let slicedData = data.slice(0, index + 1);
      let restData = data.slice(index + 1);
      if (slicedData.length > 0) {
        slicedData.map((item: any) => newTableData.push(item));
      }
      let lastsliceIndex = slicedData.length - 1;

      let newRow: any = {};
      //newRow.startDate= slicedData[lastsliceIndex].startDate;
      newRow.startDate= moment(slicedData[lastsliceIndex].startDate).add(1, "days");
      newRow.startTime= slicedData[lastsliceIndex].startTime;
      newRow.endDate=End_date;//slicedData[lastsliceIndex].endDate;
      newRow.endTime= slicedData[lastsliceIndex].endTime;
      newRow.duration=  slicedData[lastsliceIndex].duration;
      newRow.totalMinutes=  slicedData[lastsliceIndex].totalMinutes;
      newRow.weekStartDate= slicedData[lastsliceIndex].weekStartDate;
      newRow.weekEndDate= slicedData[lastsliceIndex].weekEndDate;
      newRow.isDnsChecked= slicedData[lastsliceIndex].isDnsChecked;
      
      newRow.isDuplicateEntry= false;   
      //newRow.isDNSRows= ptotype === "-1" ? true : false;  
  

      newTableData.push(newRow);
      if (restData.length > 0) {
        restData.map((item: any) =>{
         // item.rowNo = item.rowNo +1;
         return newTableData.push(item)
        });
      }    
      this.setState({ tableData:newTableData },()=>{
        this.timeValidations(index+1);
      });

    }

  };

  prepareEndDate = (index:  number, ) => {

    //const { name: fieldName, value } = e.target;
    const tableData = this.state.tableData;
   // tableData[index][fieldName] = value;
    // if (fieldName !== "startTime") {
    let startdate1 = this.state.tableData[index].startDate;
    let startTime1 = this.state.tableData[index].startTime;
    let endTime1 = this.state.tableData[index].endTime;

    let startdate2 = moment(startdate1).add(1, "days").format("MM/DD/YYYY");
    let inMomentTime = moment(
      `${startdate2} ${startTime1}`
    );
    let outMomentTime = moment(
      `${startdate2} ${endTime1}`
    );

    let differenceInTime = outMomentTime.diff(inMomentTime);

    let punchOutDate = moment(startdate2).format("MM/DD/YYYY");
    if (differenceInTime <= 0) {
      punchOutDate = moment(startdate2)
        .add(1, "days")
        .format("MM/DD/YYYY");
    }

    return punchOutDate;

    // tableData[index]['endDate'] = punchOutDate;

    // }


    // this.setState({ tableData }
    //   , () => this.calculateDuration(index)
    // );
  };




  handleChangeDate = (index: string | number, value: any) => {  
   
    this.setState({ innerLoader: true });
    let calenderYear = value.getFullYear();
    let calenderDate = value.getDate();
    let calenderMonth = value.getMonth() + 1;
    let tempDate = calenderMonth + "/" + calenderDate + "/" + calenderYear;
    let isSelected = false;
    for (let index = 0; index < this.state.tableData.length; index++) {
      if (this.state.tableData[index].startDate === tempDate) {
        isSelected = true;
        break;
      }
    }
    if (isSelected) {
      Utils.toastError("This Date already selected. Plesae choose another one.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "scheduleMain",
      });
      return;
    } else {
      const tableData = this.state.tableData;
      tableData[index].startDate = tempDate;

      this.setState({ tableData, calenderSelectDate: tempDate }, () => {
        this.calenderHide();
        //this.getPayPeroid(tempDate, index);
      });
    }
   
  };

  editTime = (index: string | number, e: { target: { name: any; value: any } }) => {
   
    const { name: fieldName, value } = e.target;
    const tableData = this.state.tableData;
    tableData[index][fieldName] = value;
    // if (fieldName !== "startTime") {
    let startdate1 = this.state.tableData[index].startDate;
    let startTime1 = this.state.tableData[index].startTime;
    let endTime1 = this.state.tableData[index].endTime;

    let startdate2 = moment(startdate1).format("MM/DD/YYYY");
    let inMomentTime = moment(
      `${startdate2} ${startTime1}`
    );
    let outMomentTime = moment(
      `${startdate2} ${endTime1}`
    );

    let differenceInTime = outMomentTime.diff(inMomentTime);

    let punchOutDate = moment(startdate2).format("MM/DD/YYYY");
    if (differenceInTime <= 0) {
      punchOutDate = moment(startdate2)
        .add(1, "days")
        .format("MM/DD/YYYY");
    }

    tableData[index]['endDate'] = punchOutDate;

    // }


    this.setState({ tableData }
      , () => this.calculateDuration(index)
    );
  };

  disableButton = (): boolean => {
    const { tableData, timeOffReason, selectedTimeOffType } = this.state;
    return (
      +selectedTimeOffType?.ptoTypeID === -1 ? (
        tableData.filter((x: any) => !x?.totalMinutes
          || x?.startDate === "Select"
          || !x?.startDate).length > 0
      ) : (tableData.filter((x: { totalMinutes: number; startDate: string }) => x?.totalMinutes > 480
        || !x?.totalMinutes || x?.startDate === "Select" || !x?.startDate).length > 0)
       || this.props.parentState.fromPage==="MyPTOTimeoff" ? 0 :this.getPostEntryBalance()?.startsWith("-")
      //|| (this.getTotalDurationMinutes() > 2400 && +selectedTimeOffType?.ptoTypeID !== -1)
      || !+selectedTimeOffType?.ptoTypeID
      )
      ? true
      : false;
  };

  getTotalDurationMinutes = () => {
   
    const minutesArray = this.state.tableData
      .filter(
        (x: any) => x.startDate && x.startSate !== "Select" && x.startTime && x.endTime
      )
      ?.map((x: any) => {
        const data = x.duration.split(":");
        return +data[0] * 60 + +data[1];
      })
      .filter((r: any) => r);
    return minutesArray.length > 0
      ? minutesArray.reduce((a: any, b: any) => a + b)
      : 0;
  };

  calculateDuration = (index: string | number) => {
    debugger;
    const tableData = this.state.tableData;
    const { startDate, endDate, startTime, endTime } = this.state.tableData[index];
    if (startDate && startDate !== "Select" && startTime && endTime) {
      // const _startDate = new Date(0, 0, 0, startTime.split(":")[0], startTime.split(":")[1]);
      // let _endDate = new Date(0, 0, 0, endTime.split(":")[0], endTime.split(":")[1]);
      // const totalMinutes = _endDate.getHours() * 60 + _endDate.getMinutes() - (_startDate.getHours() * 60 + _startDate.getMinutes());
      let startdateTime = new Date(new Date(startDate).toLocaleDateString("en-US") + " " + startTime);//new Date(startDate + " " + startTime);
      let endDateTime = new Date(new Date(endDate).toLocaleDateString("en-US") + " " + endTime);//new Date(endDate + " " + endTime)
      const totalMinutes = this.timeDiffCalc(startdateTime, endDateTime);
      if (totalMinutes > 0) {
        const totalHours = this.getHoursFromMinutes(totalMinutes);
        tableData[index].totalMinutes = totalMinutes;
        tableData[index].duration = totalHours;
        this.setState({ isNewRow: false });
      } else {
        tableData[index].totalMinutes = 0;
        tableData[index].duration = "";
      }
    } else {
      tableData[index].totalMinutes = 0;
      tableData[index].duration = "";
    }
    this.setState({ tableData });
  };

  setCalcFormat = date => {
    let CDate = new Date(date).toLocaleDateString("en-US");
    let calenderMonth = parseInt(CDate.split('/')[0]) < 10 ? '0' + CDate.split('/')[0] : CDate.split('/')[0];
    let calenderDate = parseInt(CDate.split('/')[1]) < 10 ? '0' + CDate.split('/')[1] : CDate.split('/')[1];
    let calenderYear = CDate.split('/')[2];
    let calenderDateString = `${calenderMonth}/${calenderDate}/${calenderYear}`;
    return calenderDateString;
  };

  timeDiffCalc = (startDateTime, endDateTime) => {
    let dateTime = endDateTime - startDateTime;
    let min = Math.floor(dateTime / 1000 / 60);
    return min;
  }

  cheackOverlap = (startDateTime, endDateTime,index) => {
    
    //let { tableData } = this.state;
    let isValidate = true;
    let tableData = [...this.state.tableData];
    tableData.splice(index, 1);

    for (let i = 0; i < tableData.length; i++) {


      let srartdateA: any = new Date(new Date(tableData[i].startDate).toLocaleDateString("en-US") + " " + tableData[i].startTime);
      let endDateA: any = new Date(new Date(tableData[i].endDate).toLocaleDateString("en-US") + " " + tableData[i].endTime);


      let startDateTimeB: any = new Date(startDateTime);
      let endDateTimeB: any = new Date(endDateTime);
      if (srartdateA < endDateTimeB && endDateA > startDateTimeB) {
        isValidate = false;
        break;
      } 
    }


    return isValidate;


  }

 // cheackDuplicateEntry = (startDateTime, endDateTime) => {
  cheackDuplicateEntry = () => {
 
    //let { tableData } = this.state;
    let isValidate = true;
    let tableData = [...this.state.tableData];
   

    for (let i = 0; i < tableData.length; i++) {


      let srartdateA: any = new Date(new Date(tableData[i].startDate).toLocaleDateString("en-US") + " " + tableData[i].startTime);
      let endDateA: any = new Date(new Date(tableData[i].endDate).toLocaleDateString("en-US") + " " + tableData[i].endTime);

      for (let x = i+1; x < tableData.length; x++) {
        let startDateTime: any = new Date(new Date(tableData[x].startDate).toLocaleDateString("en-US") + " " + tableData[x].startTime);
        let endDateTime: any = new Date(new Date(tableData[x].endDate).toLocaleDateString("en-US") + " " + tableData[x].endTime);

      let startDateTimeB: any = new Date(startDateTime);
      let endDateTimeB: any = new Date(endDateTime);
      if (srartdateA < endDateTimeB && endDateA > startDateTimeB) {
        isValidate = false;
        break;
      } 
    }

   if (!isValidate){
    break;
   }


    }


    return isValidate;


  }



  cheackOVerlapWithRedError = () => {
 debugger;
    //let { tableData } = this.state;
    let isValidate = true;
    let tableData = [...this.state.tableData];
   

    for (let i = 0; i < tableData.length; i++) {
      let srartdateA: any = new Date(new Date(tableData[i].startDate).toLocaleDateString("en-US") + " " + tableData[i].startTime);
      let endDateA: any = new Date(new Date(tableData[i].endDate).toLocaleDateString("en-US") + " " + tableData[i].endTime);

      for (let x = i+1; x < tableData.length; x++) {
        let startDateTime: any = new Date(new Date(tableData[x].startDate).toLocaleDateString("en-US") + " " + tableData[x].startTime);
        let endDateTime: any = new Date(new Date(tableData[x].endDate).toLocaleDateString("en-US") + " " + tableData[x].endTime);

      let startDateTimeB: any = new Date(startDateTime);
      let endDateTimeB: any = new Date(endDateTime);
      if (srartdateA < endDateTimeB && endDateA > startDateTimeB) {
        tableData[x].formHasError = true;  
        isValidate = false;
      
      } 
    }
  }
  if(isValidate===false){
    this.setState({ tableData});
    if(this.isSaveRequest.current!=null){
      this.isSaveRequest.current.disabled=true;
    }
  }


    return isValidate;


  }






  timeValidations = (index: any): void => {
    debugger
    const { selectedTimeOffType } = this.state;
    let { tableData } = this.state;
    const { startDate, endDate, startTime, endTime } = tableData[index];
    let formHasError = true;
    let startdateTime = new Date(new Date(startDate).toLocaleDateString("en-US") + " " + startTime);
    let endDateTime = new Date(new Date(endDate).toLocaleDateString("en-US") + " " + endTime);
    if (startDate && startDate !== "" && endDate && endDate !== "" && startTime && endTime) {

   // let isOverlap=  this.cheackOverlap(startdateTime, endDateTime,index);
   let isOverlap=  this.cheackOVerlapWithRedError();
    if (isOverlap === false) {
      Utils.toastError("Time Off request is overlapping.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "scheduleMain",
      });
        formHasError = true;
      // tableData[index].formHasError = formHasError;    
      this.setState({ tableData, formHasError: formHasError });
      if(this.isSaveRequest.current!=null){
        this.isSaveRequest.current.disabled=true;
      }
      return;
    }else {
      
      formHasError = false;
      if(this.isSaveRequest.current!=null){
        this.isSaveRequest.current.disabled=false;
      }
    }


      const totalMinutes = this.timeDiffCalc(startdateTime, endDateTime);
      if (totalMinutes < 0) {
        Utils.toastError("Start Time can't be greater than End Time.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "scheduleMain",
        });
        formHasError = true;
        tableData[index].formHasError = formHasError;
        this.setState({ tableData, formHasError: formHasError });
        if(this.isSaveRequest.current!=null){
          this.isSaveRequest.current.disabled=true;
        }
        return;
      } else {
        formHasError = false;
      }
      if (totalMinutes === 0) {
        Utils.toastError("Time Off Shift Start Time and End Time cannot be same.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "scheduleMain",
        });
        formHasError = true;
        tableData[index].formHasError = formHasError;
        this.setState({ tableData, formHasError: formHasError });
        if(this.isSaveRequest.current!=null){
          this.isSaveRequest.current.disabled=true;
        }
        return;
      } else {
        formHasError = false;
      }
     
      if (+selectedTimeOffType?.ptoTypeID !== -1) {
        if (totalMinutes > 480) {
          Utils.toastError("PTO/UTO duration can not be more than 8 hours for a day.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "scheduleMain",
          });
          formHasError = true;
          tableData[index].formHasError = formHasError;
          this.setState({ tableData, formHasError: formHasError });
          if(this.isSaveRequest.current!=null){
            this.isSaveRequest.current.disabled=true;
          }
          return;
        } else {
          formHasError = false;
        }
       
        if (this.getPostEntryBalance()?.startsWith("-")) {
        //   if(this.props.parentState.fromPage!=="MyPTOTimeoff"){
        //   setTimeout(() => {
           
        //     Utils.toastError("Post Entry Balance should not be less than 0.", {
        //       position: toast.POSITION.BOTTOM_RIGHT,
        //       containerId: "scheduleMain",
        //     });
            
        //   }, 400);
        //   formHasError = true;
        //   tableData[index].formHasError = formHasError;
        //   this.setState({ tableData, formHasError: formHasError });
        //   return;
        // }else{
        //   formHasError = false;
        //   tableData[index].formHasError = formHasError;
        //   this.setState({ tableData, formHasError: formHasError });
        //   return;
        // }

        setTimeout(() => {
           
          Utils.toastError("Post Entry Balance should not be less than 0.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "scheduleMain",
          });
          
        }, 400);
        formHasError = true;
        tableData[index].formHasError = formHasError;
        this.setState({ tableData, formHasError: formHasError });
        if(this.isSaveRequest.current!=null){
          this.isSaveRequest.current.disabled=true;
        }
        return;
          
        } else {
          formHasError = false;
          tableData[index].formHasError = formHasError;           
          if(!formHasError)
          {
            tableData.map((x,index) => {
              tableData[index].formHasError = formHasError;
            });
          }
          this.setState({ tableData, formHasError: formHasError });
          return;
        }
      } else {
        // if (totalMinutes > 480) {
        //   Utils.toastError("PTO/UTO duration can not be more than 8 hours for a day.", {
        //     position: toast.POSITION.BOTTOM_RIGHT,
        //     containerId: "scheduleMain",
        //   });
        //   formHasError = true;
        //   tableData[index].formHasError = formHasError;
        //   this.setState({ tableData, formHasError: formHasError });
        //   return;
        // } else {
        //   formHasError = false;
        // }
        if (this.getPostEntryBalance()?.startsWith("-")) {
          setTimeout(() => {
            Utils.toastError("Post Entry Balance should not be less than 0.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "scheduleMain",
            });
          }, 400);
          formHasError = true;
          tableData[index].formHasError = formHasError;
          this.setState({ tableData, formHasError: formHasError });
          return;
        } else {
          formHasError = false;
        }
      }

      if(isOverlap){
        tableData[index].isDuplicateEntry = false;
      }
    } else {
      formHasError = true;
    }
    tableData[index].formHasError = formHasError;
    
    if(!formHasError)
    {
      tableData.map((x,index) => {
        tableData[index].formHasError = formHasError;
      });
    }
    
    
    this.setState({ tableData, formHasError: formHasError });
    // this.setState({ tableData, formHasError: formHasError },()=>{
    //   this.calculateEndDate(index)
    // });
  };

  getHoursFromMinutes = (totalMinutes: number) => {
    const isNagetive = totalMinutes.toString().includes("-") ? true : false;
    totalMinutes = +totalMinutes.toString().replace("-", "");
    var hours: number = Math.floor(totalMinutes / 60);
    var minutes: number = totalMinutes % 60;
    const hoursWithMinutes =
      hours + ":" + Math.round(minutes).toString().padStart(2, "0");
    return isNagetive
      ? "-" + hoursWithMinutes?.toString().replace("-", "")
      : hoursWithMinutes;
  };

  calenderHide = () => {
    this.setState({ calenderShow: false });
  };

  calenderShow = () => {
    this.setState({ calenderShow: true });
  };

  showCalender = () => {
    this.calenderShow();
  };
  handleDateValidation = (index) => {
    let { tableData } = this.state;
    const { startDate, endDate, startTime, endTime } = tableData[index];
    let formHasError = false;
    if (startDate && startDate !== "" && endDate && endDate !== "" && startTime && endTime) {      
      formHasError = false;
    } else {
      formHasError = true;
    }
    if(!formHasError){
    this.timeValidations(index);
    }else{
    tableData[index].formHasError = formHasError;
    //this.setState({ tableData, formHasError: formHasError },()=>{this.calculateEndDate(index)});
    this.setState({ tableData, formHasError: formHasError });
    }
  }

  handleSelectDate = (index, event, dateType,eventType) => {
   debugger;
    let { tableData } = this.state;
    const { startDate, endDate, startTime, endTime } = tableData[index];
    let isDuplicateDate = false;
    if (dateType === "startDate") {
      //tableData[index]["endDate"] = '';
      //tableData[index]["endTime"] = '';
     // tableData[index]["duration"] = "";
     let startdateTime = new Date(new Date(startDate).toLocaleDateString("en-US") + " " + startTime);
     let endDateTime = new Date(new Date(endDate).toLocaleDateString("en-US") + " " + endTime);
     if (startDate && startDate !== "" && endDate && endDate !== "" && startTime && endTime) {
    
      //const { name: fieldName, value } = e.target;
    const tableData = this.state.tableData;
    tableData[index]["startDate"] = event;
    // if (fieldName !== "startTime") {
    let startdate1 = this.state.tableData[index].startDate;
    let startTime1 = this.state.tableData[index].startTime;
    let endTime1 = this.state.tableData[index].endTime;

    let startdate2 = moment(startdate1).format("MM/DD/YYYY");
    let inMomentTime = moment(
      `${startdate2} ${startTime1}`
    );
    let outMomentTime = moment(
      `${startdate2} ${endTime1}`
    );

    let differenceInTime = outMomentTime.diff(inMomentTime);

    let punchOutDate = moment(startdate2).format("MM/DD/YYYY");
    if (differenceInTime <= 0) {
      punchOutDate = moment(startdate2)
        .add(1, "days")
        .format("MM/DD/YYYY");
    }

    tableData[index]['endDate'] = punchOutDate;

    }


    this.setState({ tableData }
      , () => this.calculateDuration(index)
    );





     }

      // for (let i = 0; i < tableData.length - 1; i++) {
      //   const startdateArr = tableData[i].startDate.toLocaleDateString("en-US")();
      //   if (startdateArr === event.toLocaleDateString("en-US")()) {
      //     isDuplicateDate = true;
      //     break;
      //   }
      // }
   // }
    // if (dateType === "endDate") {
    //   tableData[index]["endTime"] = '';
    //   tableData[index]["duration"] = "";
    // }
    // if (isDuplicateDate) {
    //   Utils.toastError("Duplicate Date Entered.", {
    //     position: toast.POSITION.BOTTOM_RIGHT,
    //     containerId: "scheduleMain",
    //   });
    // }
    if (tableData[index]["endTime"] === '') {
      this.handleDateValidation(index);
    }
    tableData[index][dateType] = event
    tableData[index].isDuplicateEntry = isDuplicateDate;
    this.setState({ tableData },()=>{
      //if (tableData[index]["endDate"] === '') {
      this.calculateEndDate(index);
      if(eventType==="onChange"){
      this.timeValidations(index);
      }
     // }
    });
  }

  calculateEndDate = (index: number) => {

    let newTableData = [...this.state.tableData];
    const { startDate, startTime, endTime } = newTableData[index];

    if (
      !startDate || startDate === "" ||
      !startTime || startTime === "" ||
      !endTime || endTime === ""
    ) {
      return false;
    } else {

      let inMomentTime = moment(
        `${moment(startDate).format("MM/DD/YYYY")} ${startTime}`
      );
      let outMomentTime = moment(
        `${moment(startDate).format("MM/DD/YYYY")} ${endTime}`
      );

      let differenceInTime = outMomentTime.diff(inMomentTime);

      newTableData[index].endDate = moment(`${moment(startDate).format("MM/DD/YYYY")} ${endTime}`).format("MM/DD/YYYY");
      if (differenceInTime <= 0) {
        newTableData[index].endDate = moment(`${moment(startDate).format("MM/DD/YYYY")} ${endTime}`)
          .add(1, "days")
          .format("MM/DD/YYYY");
      }
    }
    // alert( newTableData[index].endDate );

    this.setState({ tableData: newTableData });
  }


  dnsCheckBox=(index:any,e:any)=>{  
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableData))
    );

    data[index].isDnsChecked=e.currentTarget.checked;
    data[index].startTime='00:00';
    data[index].endTime='23:59';
    //data[index].isDNSRows=e.currentTarget.checked;
    data[index].duration= '23:59';
    data[index].totalMinutes=  1439;
  
   this.setState({tableData:data},()=>{
    if (this.state.tableData[index]["startDate"] !== '') {

      this.calculateEndDate(index);
      this.timeValidations(index);
      }
   }); 
  }

  getTableRowsWithData = () => {
   
    
    const { tableData, selectedTimeOffType ,formHasError} = this.state;
    let ptoTypeID = selectedTimeOffType.ptoTypeID;
    let fromPage = this.props.parentState.fromPage;
    let hasMyTimeoff = fromPage !== null && fromPage !== undefined && fromPage.toString().toLowerCase() === "MyPTOTimeoff".toLowerCase();
    return (
      <tbody>
        {tableData.map((row: any, i: any) => {
          let _minDate = hasMyTimeoff ? new Date(new Date().getTime() - 360 * 60 * 60 * 1000) : new Date(this.getDateList()[0]);
          let _maxDate = hasMyTimeoff ? "" : new Date(this.getDateList()[this.getDateList().length - 1])
          return (
            <>

              <tr key={i}>
                <td>
                  <div className="date-picker tofDatePicker">
                    <DatePicker
                      value={row?.startDate? new Date(row.startDate) : null}
                      name="startDate"
                      className={`form-control scheduleInput ${row.isDuplicateEntry ? "alert-danger" : ''}`}
                      id="shiftStartDate"
                      minDate={_minDate ?_minDate: null}
                      maxDate={_maxDate?_maxDate: null}
                      selected={row?.startDate? new Date(row.startDate) : null}
                      onSelect={(e: any) => this.handleSelectDate(i, e, "startDate","")}
                      onChange={(e: any) => this.handleSelectDate(i, e, "startDate","onChange")}
                      onBlur={(e: any) => this.handleDateValidation(i)}
                      placeholderText="mm/dd/yyyy"
                      required
                      autoComplete="off"                      
                      
                    />

                  </div>
                  {this.state. selectedTimeOffType.ptoTypeID ==="-1" &&(
                 <Form.Check className="checkbox-ui"  onChange={(e) => this.dnsCheckBox(i,e)}  type={"checkbox"} checked={row.isDnsChecked} label="Request DNS for Full Day(s)" />
                   )}

                </td>
                <td>
                  <Form.Control
                    type="time"
                    name="startTime"
                    value={row.startTime}
                    className={`scheduleInput ${row.formHasError ? "alert-danger" : ''}`}
                    //${+row.totalMinutes <= 0 && row.startTime && row.endTime ? "alert-danger" : ""}
                    onChange={(e) => this.editTime(i, e)}
                    onBlur={() => this.timeValidations(i)}
                    disabled={
                      (!row.startDate || row.startDate === "")
                    || (this.state. selectedTimeOffType.ptoTypeID ==="-1" ? row.isDnsChecked :"")
                       }
                    required
                  />
                </td>
                {/* <td>
                  <div className="date-picker tofDatePicker">
                    <DatePicker
                      value={new Date(row.endDate)}
                      name="endDate"
                      className={`form-control scheduleInput ${row.formHasError ? "alert-danger" : ''}`}
                      id="shiftEndDate"
                      minDate={new Date(row.startDate)}
                      maxDate={new Date(new Date(row.startDate).getTime() + 24 * 60 * 60 * 1000)}
                      selected={row.endDate}
                      openToDate={new Date(row.startDate)}
                      onSelect={(e: any) => this.handleSelectDate(i, e, "endDate")}
                      onChange={(e: any) => this.handleSelectDate(i, e, "endDate")}
                      onBlur={(e: any) => this.handleDateValidation(i)}
                      placeholderText="mm/dd/yyyy"
                      required
                      disabled={(!row.startTime || row.startTime === "") || (!row.startDate || row.startDate === "")}
                      autoComplete="off"
                    />
                  </div>
                </td> */}
                <td>
                  <Form.Control
                    type="time"
                    name="endTime"
                    value={row.endTime}
                    onChange={(e) => this.editTime(i, e)}
                    onBlur={() => this.timeValidations(i)}
                    className={`scheduleInput ${row.formHasError ? "alert-danger" :''}`}
                    //${+row.totalMinutes <= 0 && row.startTime && row.endTime ? "alert-danger" : ""}
                    disabled={(!row.startTime || row.startTime === "")
                      || (!row.startDate || row.startDate === "")
                      || (this.state. selectedTimeOffType.ptoTypeID ==="-1" ? row.isDnsChecked :"")

                    }
                    required
                  />
                </td>
                <td className="duration-col">
                  <span className={(row.totalMinutes < 1 || (row.totalMinutes > 480)) && selectedTimeOffType.ptoTypeID !== "-1" ? "over-duration text-danger" : ""}>
                    {row.duration?.includes("-") || row.totalMinutes < 1 ? "" : row.duration}
                  </span>
                </td>
                <td className="add-remove-entry-buttons">
                  {/* <Dropdown
                    onClick={(e: { stopPropagation: () => void }) => {
                      e.stopPropagation();
                    }}
                    className="more-action"
                    alignRight
                  >
                    <Dropdown.Toggle
                      className="btn-outline-primary btn btn-primary more"
                      id="dropdown-more"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        eventKey={"Delete"}
                        onClick={() => this.removeEntry(i)}
                      >
                        Delete
                      </Dropdown.Item>

                      <Dropdown.Item
                        //eventKey={"Delete"}
                        onClick={() => this.AddRowEntry(i)}
                      >
                        Add Entry
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}

                <Button className="btn-outline-primary" 
                 disabled={(!row.startTime || row.startTime === "")
                 || (!row.startDate || row.startDate === "")
                  || (!row.endDate || row.endDate === "")
                  || (! row.endTime ||  row.endTime === "")
                  ||(formHasError)
                  
                 
               }
                onClick={() => this.AddRowEntry(i)}>
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
                <Button className="btn-outline-primary" 
                 disabled={(!row.startTime || row.startTime === "")
                 || (!row.startDate || row.startDate === "")
                  || (!row.endDate || row.endDate === "")
                  || (! row.endTime ||  row.endTime === "")
                  //||(formHasError)
               }
                
                onClick={() => this.removeEntry(i)}>
                  <FontAwesomeIcon icon={faMinus} />
                </Button>

                </td>
              </tr>
            </>
          )
        })}
        {(tableData.length > 0 && this.getTotalDurationMinutes() > 0)  && (
          <tr key={tableData.length + 1}>
            <td colSpan={4}>
              <span className="totals-title">Duration</span>
              :  <span className={`totals-value ${+selectedTimeOffType?.ptoTypeID !== -1 && this.getTotalDurationMinutes() > 2400 ? "text-danger" : ""}`}>
                {this.getHoursFromMinutes(this.getTotalDurationMinutes())}
              </span>
            </td>
            <td>
              {/* <span className={`totals-value ${+selectedTimeOffType?.ptoTypeID !== -1 && this.getTotalDurationMinutes() > 2400 ? "text-danger" : ""}`}>
                {this.getHoursFromMinutes(this.getTotalDurationMinutes())}
              </span> */}
            </td>
            <td className="text-right"> </td>
          </tr>
        )}
        {/* <tr className="checkbox-row">
            <td colSpan={5}>
              {this.state. selectedTimeOffType.ptoTypeID ==="-1" &&(
              <Form.Check className="checkbox-ui"  onChange={(e) => this.dnsCheckBox(e)}  type={"checkbox"} label="Request DNS for Full Day(s)" />
              )}
            </td>
        </tr> */}
      </tbody>
    );
  };

  getPostEntryBalance = (): string => {
    const {
      selectedTimeOffType: { availableHours, ptoTypeID },
    } = this.state;
    const availableMinutes = availableHours
      ? +availableHours.split(":")[0] * 60 + +availableHours.split(":")[1]
      : 0;
    const totalDurationMinutes = this.getTotalDurationMinutes();
    return +ptoTypeID > 0
      ? this.getHoursFromMinutes(availableMinutes - totalDurationMinutes)
      : "0";
  };


  isCheckDuplicatePop() {
    confirmAlert({
     // title: "Unsaved confirmation",
      message:
        "You have made multiple requests with the same date or time. Please update and submit again.",
      buttons: [
        // {
        //   label: "Yes",
        //   onClick: () => this.isChangeBudgetTab(),
        // },
        {
          label: "OK",
          onClick: () => reject(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
      overlayClassName: "duplicate-entry-alert"
    });
  }

  
  isConfirmSubmitPop(e:any, hour:any) {
    confirmAlert({
     // title: "Unsaved confirmation",
      message:
        "You just requested a total of "+ hour +" hours in "+this.state.selectedTimeOffType.timeOffType+". Would you like to continue?",
      buttons: [
        {
          label: "Yes",
          onClick:() => this.addEditTimeOffShiftSave(e,"Yes"),
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }


  isRequestDeleteConfirmPop(index:any) {
    confirmAlert({
     // title: "Unsaved confirmation",
      message:
        "Are you sure that you want to delete this entry?",
      buttons: [
        {
          label: "Yes",
          onClick:() => this.DeleteRemoveRow(index),
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }

  ConfirmPayrollExport(e:any,type) {
    confirmAlert({
     // title: "Unsaved confirmation",
      message:
        "Payroll for this period has already been exported. Continuing will require payroll to be re-exported to prevent any disruptions. Would you like to continue?",
      buttons: [
        {
          label: "Yes",
          onClick:() => this.addEditTimeOffShiftSave(e,"No"),
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }

  NoClick() {
    this.setState({popup : false} , () => {
      this.props.addEditTimeOffShiftModalShow()
    })
    
  }

  ClickCancel() {
    this.setState({popupNetchex : false} , () => {
      this.props.addEditTimeOffShiftModalShow()
    })
    
  }

  render() {
    const {
      employeeList,
      positionName,
      selectedEmployee,
      selectedTimeOffType,
      timeOffTypes,
      timeOffReason,
      loaderTrue,
      tableData,
      dateList,
      formHasError
    } = this.state;
    
    let isBothEqual = false;
    let hasRowError = false;
    let isRowBlank = false;
    let isAddEntryFalse = false;
   
    if ((typeof tableData !== "undefined" && tableData.length > 0) || (typeof dateList !== "undefined" && dateList.length > 0)) {
      tableData.length === dateList.length ? (isBothEqual = true) : (isBothEqual = false)
    }
    if (typeof tableData !== "undefined" && tableData.length > 0) {
      hasRowError = tableData.some(item => item.isDuplicateEntry === true)
      isRowBlank = tableData.some(item => item.endDate === '')
      isAddEntryFalse=true;
    }
  
    if(tableData.length === 0){
      if(this.isSaveRequest.current!=null){
        this.isSaveRequest.current.disabled=true;
      }
    }
    
    //this.state.tableData.length === this.state.dateList.length
    let fromPage = this.props.parentState.fromPage;
    return (
      <div className="container-fluid">
        <Modal
          className="add-edit-time-off-shift-modal AddTimeOffRequestModal"
          show={this.props.parentState.addEditTimeOffShiftModal}
          onHide={this.addEditTimeOffShiftModalClose}
          //aria-labelledby="contained-modal-title-vcenter"
          centered
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Time Off Request</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="user-type d-flex">
              {fromPage !== null && fromPage !== undefined && fromPage.toString().toLowerCase() === "MyPTOTimeoff".toLowerCase() && (
                <Form.Group className="hasEhidSelector">
                  <Form.Label><strong>Select HID{" "}<span className="text-danger">*</span></strong></Form.Label>
                  <HidSelector
                    onClickEvent={this.selectHotelDropDown}
                    ref={this.childHID}
                    modulename={"Mypto"}
                  />
                </Form.Group>
              )}
              <Form.Group controlId="select-user" className={`${fromPage !== null && fromPage !== undefined && fromPage.toString().toLowerCase() === "MyPTOTimeoff".toLowerCase() ? "NoEhidSelector" : ""}`}>
                <Form.Label>
                  User <b>*</b>
                </Form.Label>
                {this.props.parentState.fromPage === undefined && (
                  <>
                    {employeeList.length === 0 && (
                      <SingleSelectActiveUserSearchWithImage
                        ref={this.firstSingleSelect}
                        employeeList={employeeList}
                        mode="primary"
                        pageFrom={this.props.parentState.fromPage}
                      />
                    )}
                    {employeeList.length > 0 && (
                      <SingleSelectActiveUserSearchWithImage
                        ref={this.firstSingleSelect}
                        employeeList={employeeList}
                        handleSelectedItem={this.onEmployeeChange}
                        mode="primary"
                        defaultItem={positionName}
                        pageFrom={this.props.parentState.fromPage}
                      //handleDropdownChange={}
                      />
                    )}
                  </>
                )}
                {this.props.parentState.fromPage !== undefined && (
                  <Dropdown className="single-select-image">
                    <Dropdown.Toggle id="dropdown-importFrom" disabled={true}>
                      {this.state.selectedEmploye === "" ? (
                        <span className="placeholder">Select user</span>
                      ) : (
                        <span className="selected">
                          {this.state.selectedEmploye}
                        </span>
                      )}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div className="search-sec">
                        <input
                          type="text"
                          className="form-control"
                          onChange={this.handleChangeEmployee}
                          placeholder="Search"
                          value={this.state.inputValue}
                        //ref={this.inputElement}
                        />
                      </div>
                      <div className="list-area">
                        <div className="list-section">
                          {this.state.filterData.map((item: any, idx: any) => (
                            <Dropdown.Item eventKey={item.employee} key={idx}>
                              <div className="d-flex">
                                <img
                                  src={
                                    item.imagePath === null ||
                                      item.imagePath.trim() === ""
                                      ? profile
                                      : item.imagePath
                                  }
                                  className="profileimg"
                                  width="36"
                                  height="36"
                                  alt=""
                                />
                                <div>
                                  <EllipsisWithTooltip placement="bottom">
                                    <div className="name">
                                      <div className="all-user">
                                        {item.employee}
                                      </div>
                                    </div>
                                  </EllipsisWithTooltip>
                                  <EllipsisWithTooltip placement="bottom">
                                    <div className="pos-name">
                                      {item.positionName}
                                    </div>
                                  </EllipsisWithTooltip>
                                </div>
                              </div>
                            </Dropdown.Item>
                          ))}
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </Form.Group>

              <Form.Group controlId="select-type" className={`${fromPage !== null && fromPage !== undefined && fromPage.toString().toLowerCase() === "MyPTOTimeoff".toLowerCase() ? "NoEhidSelector" : ""}`}>
                <Form.Label>
                  Type <b>*</b>
                </Form.Label>
                <Dropdown
                  className={
                    !selectedEmployee?.employee
                      ? "import-from-dropdown disabled "
                      : !+selectedTimeOffType?.ptoTypeID
                        ? "import-from-dropdown select"
                        : "import-from-dropdown"
                  }
                  onClick={() => this.onTypeClick()}
                >
                  <Dropdown.Toggle id="dropdown-importFrom">
                    <EllipsisWithTooltip placement="top">
                      {!+selectedTimeOffType?.ptoTypeID ? (
                        <span className="placeholder">
                          Select type of Time Off
                        </span>
                      ) : (
                        <span className="selected">
                          {selectedTimeOffType.timeOffType}
                          {+selectedTimeOffType.ptoTypeID > 0 &&
                            `, ${selectedTimeOffType.availableHours} Available`}
                        </span>
                      )}
                    </EllipsisWithTooltip>
                  </Dropdown.Toggle>
                  {
                    this.state.typeLoader && <ReactPageLoader useas={"field-loader"} />
                  }
                  
                  <Dropdown.Menu>
                    <div>
                      {timeOffTypes.map((item: any, idx: any) => {
                        if (Number(item.ptoTypeID) === -1) {
                          if (this.props.parentState.permissionManageSchedule) {
                            return (
                              <EllipsisWithTooltip placement="bottom">
                                <Dropdown.Item
                                  key={idx}
                                  eventKey={item.ptoTypeID}
                                  onClick={() => this.onTypeChange(item)}
                                >
                                  <div className="dropdwn-timeoff-type">
                                    {item.timeOffType}
                                  </div>
                                  <div className="dropdwn-timeoff-value">
                                    {item.availableHours}{" "}
                                    {+item.ptoTypeID > 0 && "Balance"}{" "}
                                  </div>
                                </Dropdown.Item>
                              </EllipsisWithTooltip>
                            );
                          } else {
                            return false;
                          }
                        } else {
                          if (this.props.parentState.permissionManageTimeOff) {
                            return (
                              <EllipsisWithTooltip placement="bottom">
                                <Dropdown.Item
                                  key={idx}
                                  eventKey={item.ptoTypeID}
                                  onClick={() => this.onTypeChange(item)}
                                >
                                  <div className="dropdwn-timeoff-type">
                                    {item.timeOffType}
                                  </div>
                                  <div className="dropdwn-timeoff-value">
                                    {item.availableHours}{" "}
                                    {+item.ptoTypeID > 0 && "Balance"}{" "}
                                  </div>
                                </Dropdown.Item>
                              </EllipsisWithTooltip>
                            );
                          } else {
                            return false;
                          }
                        }
                      })}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
            </div>

            <Form.Group controlId="addtimeoff-reason">
              <Form.Label>
                Reason 
              </Form.Label>
              <Form.Control
                className="form-control"
                as="textarea"
                onChange={(e: any) => this.onTextChange(e)}
                onBlur={(e: any) => this.onTextBlur(e)}
                ref={this.textArea}
                value={timeOffReason}
                placeholder="Enter Reason for Time Off (Optional)"
                maxLength={200}
                onFocus={(event: { target: { select: () => void } }) =>
                  event.target.select()
                }
                required
              />
            </Form.Group>

            <div className="bottomSepetatorLine"></div>

            {
              <>
                {this.state.tableData.length > 0 ? (
                  <>
                    <Form.Label>Request Entries</Form.Label>
                    <div className="react-bootstrap-table">
                      <Table className={`addTimeoffShiftTable react-bootstrap-table table-hover table-condensed ${this.state.selectedTimeOffType.ptoTypeID === "-1" ? "DNS-view" : '' }`} id="add-timeoff-shift-table">
                        <thead>
                          <tr className="bg-light">
                            <th>Start Date</th>
                            <th>Start Time</th>
                            {/* <th>End Date</th> */}
                            <th>End Time</th>
                            <th>Duration</th>
                            <th></th>
                          </tr>
                        </thead>
                        {this.getTableRowsWithData()}
                      </Table>
                    </div>
                  </>
                ) : (
                  <>
                    <Form.Label>Entries</Form.Label>
                    <br></br>
                  </>
                )}
              </>
            }
            <Button
              className="btn-outline-primary"
              onClick={this.addNewEntry}
             // disabled={this.disableButton() || isBothEqual || hasRowError || isRowBlank || formHasError}
              disabled={isAddEntryFalse || this.state.selectedTimeOffType.timeOffType===undefined ?true : false}
            >
              Add Entry
            </Button>
          </Modal.Body>
          <Modal.Footer>
            {+selectedTimeOffType?.ptoTypeID > 0 && (
              <div className="post-entry-balance">
                Post Entry Balance:{" "}
                <span
                  className={
                    this.getPostEntryBalance()?.startsWith("-")
                      ? "badge color-orange"
                      : "badge color-green"
                  }
                >
                  {this.getPostEntryBalance()}
                </span>
              </div>
            )}


            <Button
            ref={this.isSaveRequest}
              disabled={
                
                loaderTrue 
                ||
                this.props.parentState.fromPage==="MyPTOTimeoff" ? false : this.disableButton() ||
                this.getTotalDurationMinutes() === 0 ||
                //isBothEqual ||
                hasRowError ||
                isRowBlank ||
                formHasError ||
                this.state.selectedTimeOffType.timeOffType===undefined?true:false
               
              }
              className="import-forecast-button"
              //onClick={(e: any) => this.addEditTimeOffShiftSave(e)}
              onClick={(e: any) => this. saveTimeOffRequest(e)}
             
            >
              Request Time Off
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalShiftOverlaps}
          onHide={this.handleModalShiftClose}
          backdrop="static"
          keyboard={false}
          centered
          className="modaloverlapOverlay"
        >
          <Modal.Header>
            <Modal.Title>
              {this.state.modalShiftOverlapsType.toLowerCase() === "actual" &&
                this.state.modalShiftOverlapsData.includes(
                  "appropriate permissions and will need"
                ) ? (
                "Insufficient Permission"
              ) : (
                <>Shift overlap</>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="shiftTimeModal">
              {this.state.modalShiftOverlapsType.toLowerCase() ===
                "schedule" ? (
                <>
                  {this.state.modalShiftOverlapsData.includes("<table>") ? (
                    <>
                      <p>
                        <strong>
                          This time off request overlaps with the following
                          shifts:
                        </strong>
                      </p>
                      <div className="table-responsive">
                        <div className="shiftTimeModalTable">
                          <table>
                            <tr>
                              <th>Position</th>
                              <th>Date</th>
                              <th>In Time</th>
                              <th>Out Time</th>
                            </tr>
                          </table>
                        </div>
                        <div
                          className="shiftTimeModalTable shiftTimebodyTable"
                          dangerouslySetInnerHTML={{
                            __html: this.state.modalShiftOverlapsData,
                          }}
                        ></div>
                      </div>
                      <p>
                        Continuing will move these shifts to unassigned. Do you
                        want to continue?
                      </p>
                    </>
                  ) : (
                    <p>{this.state.modalShiftOverlapsData}</p>
                  )}
                </>
              ) : (
                /* Actual */ <>
                  {this.state.modalShiftOverlapsData.includes("<table>") && (
                    <>
                      <p>
                        <strong>
                          This time off request overlaps with the following
                          shifts:
                        </strong>
                      </p>
                      <div className="table-responsive">
                        <div className="shiftTimeModalTable">
                          <table>
                            <tr>
                              <th>Position</th>
                              <th>Date</th>
                              <th>In Time</th>
                              <th>Out Time</th>
                            </tr>
                          </table>
                        </div>
                        <div
                          className="shiftTimeModalTable shiftTimebodyTable"
                          dangerouslySetInnerHTML={{
                            __html: this.state.modalShiftOverlapsData,
                          }}
                        ></div>
                      </div>
                      <p>
                        The time off cannot be added as it overlaps with an
                        existing shift.
                      </p>
                    </>
                  )}
                  {this.state.modalShiftOverlapsData.includes(
                    "Time Off shift overlaps with this one"
                  ) && (
                      <>
                        <p>{this.state.modalShiftOverlapsData}</p>
                      </>
                    )}
                  {this.state.modalShiftOverlapsData.includes(
                    "appropriate permissions and will need"
                  ) && (
                      <>
                        <p>{this.state.modalShiftOverlapsData}</p>
                      </>
                    )}
                </>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer className="text-center">
            {this.state.modalShiftOverlapsType.toLowerCase() === "schedule" ? (
              <>
                {this.state.modalShiftOverlapsData.includes("<table>") ? (
                  <>
                    <Button
                      id="yes"
                      className="btn-success"
                      onClick={(e: any) => this.addEditTimeOffShiftSave(e,"Yes")}
                    >
                      Yes
                    </Button>
                    <Button
                      variant="danger"
                      onClick={this.handleModalShiftClose}
                    >
                      No
                    </Button>
                  </>
                ) : (
                  <Button onClick={this.handleModalShiftClose}>Ok</Button>
                )}
              </>
            ) : (
              <>
                {this.state.modalShiftOverlapsData.includes("<table>") && (
                  <Button onClick={this.handleModalShiftClose}>Ok</Button>
                )}
                {this.state.modalShiftOverlapsData.includes(
                  "Time Off shift overlaps with this one"
                ) && (
                    <>
                      <Button onClick={this.handleModalShiftClose}>Ok</Button>
                    </>
                  )}
                {this.state.modalShiftOverlapsData.includes(
                  "appropriate permissions and will need"
                ) && (
                    <>
                      <Button
                        className="btn-success"
                        onClick={(e: any) => this.addEditTimeOffShiftSave(e,"Yes")}
                      >
                        Yes
                      </Button>
                      <Button
                        variant="danger"
                        onClick={this.handleModalShiftClose}
                      >
                        No
                      </Button>
                    </>
                  )}
              </>
            )}
          </Modal.Footer>
        </Modal>

                  {/* -------Confirm Export Payroll Modal-------- */}
          <Modal
            className="confirm-export-payroll-modal"
            show={this.state.isPayrollExported && this.state.popup}
            // onHide={this.addEditTimeOffShiftModalClose}
            //aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false} 
          >
          {/* <Modal.Header closeButton>
            <Modal.Title>Add Time Off Request</Modal.Title>
          </Modal.Header> */}
          <Modal.Body>
            <div>Payroll for this period has already been exported. Continuing will require payroll to be re-exported to prevent any disruptions. Would you like to continue?
              <hr />
            </div>
          </Modal.Body>
          <Modal.Footer>

           
            <Button
              onClick={() => this.NoClick()}
             
            >
              No
            </Button>
            <Button
              onClick={(e: any) => this. addEditTimeOffShiftSave(e,"No")}
             
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

             {/* -------Netchex Warning Popup-------- */}
        <Modal
            className="confirm-export-payroll-modal warning-export-modal-UI"
            show={this.state.isNetchexpopup && this.state.popupNetchex}
            centered
            keyboard={false} 
          >
          <Modal.Header>
            <Modal.Title>Your payroll data has already been exported</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>Time for these roles has already been exported to Netchex.</p>
              <p>If you edit any timesheets, you must 1) reapprove the timesheet and then 2) export payroll again to update Netchex to ensure your hours and payroll are in sync.</p>
            </div>
          </Modal.Body>
          <Modal.Footer>

           
            <Button
              onClick={() => this.ClickCancel()}
             
            >
              Cancel
            </Button>
            <Button
              onClick={(e: any) => this. addEditTimeOffShiftSave(e,"No")}
             
            >
              Save & Continue
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
