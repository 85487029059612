import * as React from "react";
import { DropDownMultiList } from "./common-components/dropdown-multi-list";
import { ModuleReports } from "./../../Common/Services/ModuleReports";
export class MultiBankAccountSelector extends React.Component<any, any> {
    private checkBoxRef: any;
    constructor(props: any) {
        super(props);
        this.checkBoxRef = React.createRef();
        this.state = {
            dataSourceList: [],
            defaultValue: "",
            placeHolder: "Select",
            inputValue: "",
        }
    }

    getBankAccounts = async (hotelIds: string = "") => {
        try {
            const result: any[] = await ModuleReports.GetBankAccounts(hotelIds);
            if (result?.length > 0) {
                let hotelList: any[] = [];
                result.forEach((element) => {
                    let newItem = {} as any;
                    newItem.label = element.name;
                    newItem.value = element.oprID;
                    newItem.isChecked = false;
                    newItem.acctType = element.acctType;
                    hotelList.push(newItem);
                });
                this.setState({ dataSourceList: [] }, () => {
                    const { defaultValue } = this.props;
                    if (defaultValue === undefined) {
                        this.props?.handlebankACCChange(hotelList);
                    } else {
                        let groupNames = defaultValue.split(",")?.map(r => parseInt(r));
                        const selectedGroups = hotelList?.filter(r => groupNames?.includes(r.value));
                        groupNames = groupNames.filter(r => selectedGroups?.map(r => r?.value)?.includes(r));
                        hotelList = hotelList?.map(item => {
                            if (groupNames?.includes(item?.value)) {
                                item.isChecked = true;
                            } else {
                                item.isChecked = false;
                            }
                            return item;
                        })
                        this.setState({ dataSourceList: hotelList, defaultValue });
                    }
                });
            }
        } catch (error) {
            // Need to handle this
            console.log(error);
        }
    };

    updateEmployeeDDL = (hotelIds, status) => {
        this.getBankAccounts(hotelIds);
    };

    componentDidUpdate(prevProps, prevState) {
        const { defaultValue } = this.props;
        let dataSourceList = [...this.state.dataSourceList];
        if (prevProps.defaultValue !== defaultValue) {
            let groupNames = defaultValue.split(",")?.map(r => r);
            const selectedGroups = dataSourceList?.filter(r => groupNames?.includes(r.label))
            groupNames = groupNames.filter(r => selectedGroups?.map(r => r?.label)?.includes(r));
            dataSourceList = dataSourceList?.map(item => {
                if (groupNames?.includes(item?.label)) {
                    item.isChecked = true;
                } else {
                    item.isChecked = false;
                }
                return item;
            })
            this.setState({ dataSourceList, defaultValue });
        }
    }

    componentDidMount = () => {
        this.getBankAccounts(this.props.hotelIds);
    };

    updateMultiSelectItem = (selectedItem: any) => {
        this.props?.handlebankACCChange(selectedItem);
    };

    render() {
        return (
            <>
                {this.state.dataSourceList.length > 0 && (
                    <DropDownMultiList
                        defaultValue={this.state?.defaultValue}
                        label={"label"}
                        value={"value"}
                        data={[...this.state.dataSourceList]}
                        updateMultiSelectItem={this.updateMultiSelectItem}
                        isSelectAll={true}
                        placeHolder={"Select Bank Accounts"}
                        filtertitle={"Filter Bank Accounts"}
                    />
                )}
            </>
        );
    }
}