import React from "react";
import {
  Col,
  Container,
  Dropdown,
  Form,
  Row,
  Spinner,
  Button,
  OverlayTrigger,
  Tooltip,
  Modal,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import AutosizeInput from "react-input-autosize";
import { confirmAlert } from "react-confirm-alert";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { ToastContainer, toast } from "react-toastify";
import { resolve, reject } from "q";
import "react-toastify/dist/ReactToastify.css";
import "react-input-range/lib/css/index.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { MultiSelectSearch } from "../../Common/Components/MultiSelectSearch";
import { ReactPageLoader } from "../../Common/Components/ReactPageLoader";
import { Hotel } from "../../Common/Services/Hotel";
import { Facility } from "../../Common/Services/Facility";
import _ from "lodash";
import { IFacilityProcedureWithTasksSaveRequest } from "../../Common/Contracts/IProcedures";
import { AgGridReact } from "ag-grid-react";
import { GridOptions } from "ag-grid-community";
import { FacilityProcedure } from "../../Common/Services/FacilityProcedure";
import { IFacilityTaskDeleteBulkRequest } from "../../Common/Contracts/IFacilities";
import "./Procedures.scss";
import { FiChevronDown } from "react-icons/fi";
import Duplicate from "../../Common/Assets/Images/icons/duplicate.svg";
import Delete from "../../Common/Assets/Images/icons/delete.svg";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import { Utils } from "../../Common/Utilis";

const { SearchBar } = Search;
let validaterRowClickId: number = -1;
const TaskNamemaxlength = 200;
export class Procedures extends React.Component<any, any> {
  private input: any;
  private duplicateInput: any;
  private multiselectChild: any;
  private checkAllRef: any;
  private taskColumns = [
    {
      headerName: "taskId",
      field: "taskId",
      cellRenderer: "checkBoxCellRenderer",
      lockPosition: true,
      // checkboxSelection: true,
      maxWidth: 50,
      minWidth: 50,
      flex: 1,
      resizable: true,
      pinned: true,
      cellClass: "pl-3 checkbox",
    },
    {
      field: "name",
      headerName: "",
      cellRenderer: "nameCellRenderer",
      editable: true,
      cellClass: "task-name",
      flex: 2,
      valueGetter: (params) => {
        return params.data.name;
      },
      valueSetter: (params) => {
        let value = params.newValue?.toString()?.trimLeft();
        var isDuplicate =
          params?.api
            ?.getRenderedNodes()
            ?.map((x) => x.data)
            .filter(
              (x) =>
                x.taskId != params.data.taskId &&
                x.name?.toLowerCase() === value?.toLowerCase()
            )?.length > 0;
        if (isDuplicate) {
          value = params.oldValue?.toString()?.trimLeft();
        }
        if (value?.toString()?.length <= 200) {
          params.data.name = value;
          const procedure = _.cloneDeep(this.state.procedure);
          let facilityTaskList = procedure.facilityTaskList;
          facilityTaskList = facilityTaskList.map((x) => {
            if (x.taskId === params.data.taskId) {
              x.name = value;
            }
            if (value !== params.oldValue) {
              x.checked = false;
            }
            x.isEditingStarted = false;
            return x;
          });
          procedure.facilityTaskList = _.cloneDeep(facilityTaskList);
          if (value !== params.oldValue) {
            this.setState({ selectedTaskIds: [] });
          }
          this.setState({ procedure });

          return true;
        } else {
          return false;
        }
      },
    },
    {
      headerName: "",
      field: "",
      lockPinned: true,
      pinned: "right",
      cellRenderer: "deleteCellRenderer",
      width: 50,
      flex: 0.1,
      cellStyle: { overflow: "visible", position: "reset" },
      cellClass: "triple-dot pr-3",
    },
  ];
  gridApi: any;
  gridColumnApi: any;

  constructor(props: any) {
    super(props);
    this.input = React.createRef();
    this.duplicateInput = React.createRef();
    this.checkAllRef = React.createRef();
    this.multiselectChild = React.createRef();

    this.state = {
      leftPanelLoader: false,
      rightPanelLoader: false,
      saveButtonSpinner: false,
      showDuplicateProcedureModal: false,
      isFromMainSave: false,
      isDuplicate: false,
      newProcedureName: "",
      isSelected: false,
      selected: [],
      isNewProcedure: false,
      hasSharedAddEditPermission: true,
      hasPropetySpecificAddEditPermission: true,
      isRPanel: false,
      allHotelList: [],
      procedureTypeList: [
        { id: "Location", name: "Location" },
        { id: "Asset", name: "Asset" },
      ],
      bdrValue: false,
      btnEventName: "",
      procedureList: [],
      assetTypes: [],
      originalProcedure: {
        procedureId: 0,
        name: "",
        type: "Select",
        hids: [],
        isGlobal: false,
        assetTypeId: 0,
        facilityTaskList: [],
      },

      selectedTaskIds: [],
      isBulkAction: false,
      procedure: {
        procedureId: 0,
        name: "",
        type: "Select",
        hids: [],
        isGlobal: false,
        assetTypeId: 0,
        facilityTaskList: [],
      },
      taskColumns: [],
    };
  }

  componentDidMount() {
    this.getAllhotelList();
    this.getProcedureList(true);
    this.getAssetTypes();
    this.GetAddEditPermissions();
  }

  GetAddEditPermissions = () => {
    FacilityProcedure.GetAddEditPermissions().then((res) => {
      this.setState(
        {
          hasSharedAddEditPermission: res?.result?.hasSharedAddEditPermission,
          hasPropetySpecificAddEditPermission:
            res?.result?.hasPropetySpecificAddEditPermission,
        },
        () => {
          const taskColumns = this.taskColumns.map((x) => {
            if (x.field == "name") {
              x.editable = true;
            }
            return x;
          });
          this.setState({ taskColumns });
        }
      );
    });
  };

  getAssetTypes = () => {
    Facility.GetDistinctAssetTypeList().then((assetTypes: any) => {
      if (assetTypes?.length > 0) {
        assetTypes = assetTypes.map((x) => {
          return { id: x?.assetTypeId, name: x?.assetTypeName };
        });
      }
      this.setState({ assetTypes });
    });
  };

  getProcedureList = (isDefaultLoad = false) => {
    FacilityProcedure.GetFacilityProcedure("").then((tableData: any) => {
      this.setState(
        {
          procedureList: _.orderBy(
            tableData || [],
            [(x) => x?.name?.toString()?.toLowerCase()],
            ["asc"]
          ),
        },
        () => {
          if (isDefaultLoad) {
            if (tableData?.length > 0) {
              const data =
                this.state.procedureList.filter((x) => x?.isGlobal)?.length > 0
                  ? this.state.procedureList.filter((x) => x?.isGlobal)[0]
                  : this.state.procedureList[0];
              const procedure = _.cloneDeep(data);
              procedure.hids =
                procedure?.facilityProcedureHids?.map((x) => x?.hid) || [];
              this.setState(
                { procedure, originalProcedure: _.cloneDeep(procedure) },
                () => {
                  this.getFacilityTaskList(procedure.procedureId);
                }
              );
            }
          }
        }
      );
    });
  };

  getFacilityTaskList = (procedureId) => {
    this.setState({
      rightPanelLoader: false,
    });
    FacilityProcedure.GetFacilityTaskList(procedureId).then((tableData) => {
      let _tableData = tableData?.map((x: any) => {
        x.checked = false;
        x.isEditingStarted = false;
        return x;
      });
      const { procedure } = this.state;
      procedure.facilityTaskList = _.cloneDeep(_tableData || []);
      let selectedRow: Array<any> = [];
      selectedRow.push(procedureId);
      this.setState(
        {
          procedure,
          originalProcedure: _.cloneDeep(procedure),
          isSelected: true,
          selected: selectedRow,
          isRPanel: true,
          isNewProcedure: false,
          rightPanelLoader: false,
        },
        () => {
          this.updateSelectedHids();
          setTimeout(() => {
            this.updateColumnEditBasedOnPermission();
          });
        }
      );
    });
  };

  getAllhotelList = () => {
    Hotel.getUserHotelAccessAll("Labor Management", true)
      .then(async (result: any[] | null) => {
        if (result != null) {
          let allHotelList: Array<any> = [];
          result.forEach((element) => {
            let newItem = {} as any;
            newItem.hotelID = element.hotelID;
            newItem.value = element.lettercode;
            newItem.description = element.hotelName;
            newItem.isChecked = false;
            allHotelList.push(newItem);
          });
          this.setState({ allHotelList });
        }
        resolve();
      })
      .catch((err) => {
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  };

  showHideDuplicateModal = (
    showDuplicateProcedureModal,
    newProcedureName = "",
    isDuplicate = false,
    isFromMainSave = false
  ) => {
    this.setState({
      newProcedureName: showDuplicateProcedureModal ? newProcedureName : "",
      isDuplicate,
      isFromMainSave,
      showDuplicateProcedureModal,
    });
  };

  addNewProcedure = (isGlobal: boolean | null = null) => {
    const { hasSharedAddEditPermission, hasPropetySpecificAddEditPermission } =
      this.state;
    this.setState(
      {
        procedure: {
          procedureId: 0,
          name: "",
          type: "Select",
          hids: [],
          isGlobal: isGlobal
            ? isGlobal
            : hasSharedAddEditPermission && !hasPropetySpecificAddEditPermission
            ? true
            : false,
          assetTypeId: 0,
          facilityTaskList: [],
        },
        originalProcedure: {
          procedureId: 0,
          name: "",
          type: "Select",
          hids: [],
          isGlobal: isGlobal
            ? isGlobal
            : hasSharedAddEditPermission && !hasPropetySpecificAddEditPermission
            ? true
            : false,
          assetTypeId: 0,
          facilityTaskList: [],
        },
        isNewProcedure: true,
        isRPanel: true,
        procedureName: "",
        isSelected: false,
        selected: [],
        taskList: [],
      },
      () => this.updateSelectedHids()
    );
    setTimeout(() => {
      this.input.current?.focus();
    }, 100);
  };

  updateColumnEditBasedOnPermission = () => {
    let { taskColumns, procedure } = this.state;
    if (procedure?.name) {
      taskColumns = taskColumns.map((x) => {
        if (x.field == "name") {
          x.editable = !this.shouldBeDisableControlBasedOnPermission();
        }
        return x;
      });
      this.setState({ taskColumns }, () => {
        this.gridApi?.setColumnDefs(taskColumns);
      });
    }
  };

  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      //debugger;
      const rowClickEvent = () => {
        const procedure = _.cloneDeep(
          this.state.procedureList.find(
            (x) => x.procedureId === row.procedureId
          )
        );
        procedure.hids =
          procedure?.facilityProcedureHids?.map((x) => x?.hid) || [];

        this.setState(
          { procedure, originalProcedure: _.cloneDeep(procedure) },
          () => {
            this.getFacilityTaskList(row.procedureId);
          }
        );
      };

      this.rightPanelConfirmation(rowClickEvent);
    },
  };

  cellEdit = cellEditFactory({
    mode: "click",
    blurToSave: true,
    beforeSaveCell: (
      oldValue: any,
      newValue: any,
      row: any,
      column: any,
      done: any
    ) => {
      if (oldValue !== newValue) {
        const { procedure } = this.state;
        let facilityTaskList = procedure.facilityTaskList;

        if (
          facilityTaskList.filter(
            (x) =>
              (x?.name || x?.taskId?.toString()?.startsWith("new")) &&
              x?.name?.toString()?.toLowerCase() ===
                newValue?.toString()?.toLowerCase()
          )?.length === 0 &&
          newValue.trim().length <= 50
        ) {
          let dataField = column.dataField;
          facilityTaskList.map((items) => {
            if (items.taskId === row.taskId) {
              items.name = newValue.trimLeft();
            }

            return items;
          });
          procedure.facilityTaskList = facilityTaskList;
          this.setState(() => ({
            procedure: _.cloneDeep(procedure),
          }));

          done(true);
        } else {
          done(false);
        }
      } else {
        done(false);
      }
      return { async: true };
    },
  });

  ishandleAction = () => {
    if (this.checkIfDataChange()) {
      this.setState({ btnEventName: "policystatus" });
      //   this.isValidateModelPoupTab();
    } else {
    }
  };

  handleClick = () => {
    this.setState({ bdrValue: true });
  };

  handleBlurAutoSize = () => {
    this.setState({ bdrValue: false });
  };

  onFieldValueChange = (key, value, isFromAlertBox = false) => {
    const { procedure } = this.state;
    value = key === "name" ? value?.toString()?.trimLeft() : value;
    procedure[key] = value;
    if (isFromAlertBox) {
      this.duplicateInput.current.value = value;
    }
    if (key === "type" && value === "Location") {
      procedure.assetTypeId = 0;
    }
    this.setState({ procedure }, () => {
      if (key === "isGlobal") {
        this.updateSelectedHids(value);
      }
    });
  };

  hidSelectionChange = () => {};

  updateSelectedHids = (isGlobal = false) => {
    const selectedHotels = this.state.allHotelList.filter((x) =>
      this.state.procedure?.hids?.includes(x.hotelID)
    );

    if (isGlobal) {
      const { procedure } = this.state;
      procedure.hids = [];
      this.setState({ procedure });
    }
    setTimeout(() => {
      this.multiselectChild.current?.updateSavedItem(
        isGlobal ? [] : selectedHotels
      );
    });
  };

  handleDiscard = () => {
    if (this.state.isNewProcedure === true) {
      // this.loadState();
      this.setState({ isNewProcedure: true, isRPanel: true });
    } else {
      this.setState({
        isRPanel: true,
        isNewProcedure: false,
      });
    }

    this.setState(
      {
        procedure: _.cloneDeep(this.state.originalProcedure),
      },
      () => this.updateSelectedHids()
    );
  };

  validateForm = () => {
    const {
      procedure: {
        procedureId,
        name,
        type,
        hids,
        assetTypeId,
        isGlobal,
        facilityTaskList,
      },
    } = this.state;
    let errorMessage = "";
    if (!name) {
      errorMessage = "Please enter Procedure Name";
    } else if (name.trim().length > 50) {
      errorMessage = "Procedure Name length should be <= 50 characters";
    } else if (type === "Select" || !type) {
      errorMessage = "Select Procedure Type";
    } else if (!assetTypeId && type === "Asset") {
      errorMessage = "Select Asset Type";
    } else if (!isGlobal && hids.length === 0) {
      errorMessage = "Please select at least one EHID";
    } else if (facilityTaskList.filter((x) => x?.name).length === 0) {
      errorMessage = "Please add at least one Task";
    }

    return errorMessage;
  };

  deleteProcedureTaskBulk = (
    taskIds: number[],
    showMessage = true,
    isPartialDelete = false
  ) => {
    const { procedure, originalProcedure } = this.state;
    const partialTaskIDs = taskIds.filter((x) =>
      x?.toString()?.includes("new")
    );
    const serverTaskIDs = taskIds.filter((x) => Number(x) > 0);
    if (isPartialDelete) {
      let facilityTaskList = procedure.facilityTaskList;
      facilityTaskList = facilityTaskList?.filter(
        (x) => !taskIds.includes(x.taskId)
      );
      let _procedure = _.cloneDeep(procedure);
      _procedure.facilityTaskList = facilityTaskList;
      this.setState({
        procedure: _.cloneDeep(_procedure),
        selectedTaskIds: [],
      });

      return;
    } else if (partialTaskIDs?.length > 0 && serverTaskIDs?.length === 0) {
      let facilityTaskList = procedure.facilityTaskList;
      facilityTaskList = facilityTaskList?.filter(
        (x) => !partialTaskIDs.includes(x.taskId)
      );
      let _procedure = _.cloneDeep(procedure);
      _procedure.facilityTaskList = facilityTaskList;
      let _originalProcedure = _.cloneDeep(originalProcedure);
      _originalProcedure.facilityTaskList = facilityTaskList;
      this.setState({
        procedure: _.cloneDeep(_procedure),
        originalProcedure: _.cloneDeep(_originalProcedure),
        selectedTaskIds: [],
      });
      let message =
        partialTaskIDs?.length > 1
          ? "Procedure Tasks deleted successfully"
          : "Procedure Task deleted successfully";
      if (showMessage) {
        toast.success(message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
      return;
    } else if (serverTaskIDs?.length === 0) {
      return;
    }

    if (!isPartialDelete) {
      const request: IFacilityTaskDeleteBulkRequest = {
        procedureId: this.state.procedure.procedureId,
        taskIds: serverTaskIDs,
      };
      FacilityProcedure.DeleteProcedureTaskBulk(request)
        .then((response) => {
          if (showMessage) {
            toast.success(response.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }

          const { procedure, originalProcedure } = this.state;
          let facilityTaskList = procedure.facilityTaskList;
          facilityTaskList = facilityTaskList?.filter(
            (x) => !taskIds.includes(x.taskId)
          );
          procedure.facilityTaskList = facilityTaskList;
          let _originalProcedure = _.cloneDeep(originalProcedure);
          _originalProcedure.facilityTaskList =
            _originalProcedure.facilityTaskList?.filter(
              (x) => !taskIds.includes(x.taskId)
            );

          this.setState({
            procedure: _.cloneDeep(procedure),
            originalProcedure: _.cloneDeep(_originalProcedure),
            selectedTaskIds: [],
          });
        })
        .catch((error) => {
          Utils.toastError(error.response.data, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        });
    }
  };

  partialDuplicateProcedure = (isFromMainSave = false) => {
    const { procedure, newProcedureName } = this.state;
    if (!newProcedureName?.toString()?.trim()) {
      toast.success("Please Enter New Procedure Name", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    const _procedure = _.cloneDeep(procedure);
    if (!isFromMainSave) {
      _procedure.procedureId = 0;
    }
    FacilityProcedure.CheckProcudureDuplicacy(
      _procedure.procedureId,
      newProcedureName
    ).then((response) => {
      if (response.isDuplicate) {
        this.showHideDuplicateModal(true, newProcedureName, true);

        return;
      }

      if (this.state.showDuplicateProcedureModal) {
        this.showHideDuplicateModal(false);
      }
      _procedure.name = newProcedureName;

      if (!isFromMainSave) {
        _procedure.facilityTaskList = _.cloneDeep(
          _procedure.facilityTaskList?.map((x, i) => {
            x.taskId = "new" + (i + 1);
            x.procedureId = 0;
            return x;
          })
        );
        this.setState(
          {
            procedure: _.cloneDeep(_procedure),
            isNewProcedure: true,
            isRPanel: true,
            procedureName: "",
            isSelected: false,
            selected: [],
            taskList: [],
          },
          () => {
            this.updateSelectedHids();
            _procedure.name = "";
            this.setState({ originalProcedure: _.cloneDeep(_procedure) });
          }
        );
      } else {
        this.setState({
          procedure: _.cloneDeep(_procedure),
        });
      }
    });
  };

  handleSave = () => {
    const errorMessage = this.validateForm();
    if (errorMessage) {
      Utils.toastError(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }

    this.setState({ saveButtonSpinner: true });

    const { procedure } = this.state;

    var request: IFacilityProcedureWithTasksSaveRequest = {
      ...procedure,
    } as IFacilityProcedureWithTasksSaveRequest;

    request.facilityTaskList = procedure.facilityTaskList?.map((x) => {
      if (x?.taskId?.toString()?.includes("new")) {
        x.taskId = 0;
      }
      return x;
    });
    request.hids = procedure.hids;
    FacilityProcedure.SaveFacilityProcedureWithTask(request)
      .then((response) => {
        if (response.isDuplicate) {
          this.showHideDuplicateModal(true, request.name, true, true);
          return;
        }
        toast.success(response.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        if (this.state.showDuplicateProcedureModal) {
          this.showHideDuplicateModal(false);
        }

        this.getProcedureList();
        const data = response.data;
        this.setState(
          {
            procedure: {
              procedureId: data.procedureId,
              name: data.name,
              type: data.type,
              hids: data.facilityProcedureHids?.map((x) => x?.hid) || [],
              isGlobal: data.isGlobal,
              assetTypeId: data.assetTypeId,
              facilityTaskList: data.facilityTaskList,
            },
            originalProcedure: _.cloneDeep({
              procedureId: data.procedureId,
              name: data.name,
              type: data.type,
              hids: data.facilityProcedureHids?.map((x) => x?.hid) || [],
              isGlobal: data.isGlobal,
              assetTypeId: data.assetTypeId,
              facilityTaskList: data.facilityTaskList,
            }),
            isSelected: true,
            selected: [data?.procedureId],
            isRPanel: true,
            isNewProcedure: false,
            rightPanelLoader: false,
          },
          () => {
            this.updateSelectedHids();
          }
        );
      })
      .catch((error) => {
        Utils.toastError(error.response.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      })
      .finally(() => {
        this.setState({ saveButtonSpinner: false });
      });
  };

  rightPanelConfirmation = (func: any) => {
    if (this.checkIfDataChange()) {
      confirmAlert({
        title: "Unsaved confirmation",
        message:
          "You have unsaved changes on this page. Do you want to leave this page and discard your changes?",
        buttons: [
          {
            label: "Yes",
            onClick: () =>
              setTimeout(() => {
                func();
              }),
          },
          {
            label: "No",
            onClick: () => reject(),
          },
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    } else {
      func();
    }
  };

  bulkTasksDeleteConfirmation = () => {
    confirmAlert({
      title: "",
      message:
        "Deleting these tasks may effect multiple Preventative Maintenance tasks. Are you sure you want to continue?",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            this.deleteProcedureTaskBulk(this.state.selectedTaskIds),
        },
        {
          label: "No",
          onClick: () => {
            this.closeBulkAction();
            reject();
          },
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  deleteProcedure = () => {
    FacilityProcedure.DeleteFacilityProcedure(this.state.procedure.procedureId)
      .then((response) => {
        toast.success(response.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        this.getProcedureList();
        this.setState({
          procedure: {
            procedureId: 0,
            name: "",
            type: "Select",
            hids: [],
            isGlobal: false,
            assetTypeId: 0,
            facilityTaskList: [],
          },
          originalProcedure: {
            procedureId: 0,
            name: "",
            type: "Select",
            hids: [],
            isGlobal: false,
            assetTypeId: 0,
            facilityTaskList: [],
          },
          isNewProcedure: false,
          isRPanel: false,
          isSelected: false,
          selected: [],
          taskList: [],
        });
      })
      .catch((error) => {
        toast.success(error.response.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };
  confirmAlertDelete = () => {
    confirmAlert({
      //  title: "Delete confirmation",
      message:
        "Deleting this procedure may remove it for multiple properties. Are you sure you want to continue?",
      buttons: [
        {
          label: "No",
          onClick: () => reject(),
        },
        {
          label: "Yes",
          onClick: () => this.deleteProcedure(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };
  resetProcedureName = () => {
    const { procedure, originalProcedure } = this.state;
    procedure.name = _.cloneDeep(originalProcedure).name;
    this.setState({ procedure });
  };

  duplicacyAlertBox(name) {
    setTimeout(() => {
      this.duplicateInput.current.value = name;
    });
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-overlay">
            <div className="react-confirm-alert">
              <div className="react-confirm-alert-body">
                <span className="msg">
                  A procedure with this name already exists. Please save this
                  procedure with a different name.
                </span>
                <input
                  maxLength={50}
                  ref={this.duplicateInput}
                  onChange={(e) =>
                    this.onFieldValueChange("name", e.currentTarget.value, true)
                  }
                ></input>
                <div className="react-confirm-alert-button-group">
                  <button
                    onClick={() => {
                      onClose();
                      this.resetProcedureName();
                    }}
                  >
                    No
                  </button>
                  <button
                    onClick={() => {
                      this.handleSave();
                      onClose();
                    }}
                  >
                    Yes, change it!
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      },
      overlayClassName: "task-rename-alert",
    });
  }

  addTask = () => {
    const { procedure } = this.state;
    let facilityTaskList = procedure.facilityTaskList;
    if (facilityTaskList?.filter((x) => !x?.name)?.length === 0) {
      const newTempTaskId =
        (_.max(facilityTaskList?.map((x) => x?.taskId)) || 0) +
        facilityTaskList?.length;
      procedure.facilityTaskList = [
        ...facilityTaskList,
        {
          taskId: "new" + newTempTaskId,
          name: "",
          checked: false,
          isEditingStarted: true,
        },
      ];
    }

    this.setState({ procedure: _.cloneDeep(procedure) }, () => {
      if (procedure.facilityTaskList?.length === 1) {
        setTimeout(() => {
          this?.gridApi?.startEditingCell({
            rowIndex: 0,
            colKey: "name",
          });
        });
      } else {
        this.gridApi.forEachNode((node) => {
          if (node.lastChild) {
            this?.gridApi?.startEditingCell({
              rowIndex: node.rowIndex,
              colKey: "name",
            });
          }
        });
      }
    });
  };

  bulkSelectTaskList = (e) => {
    const { procedure } = this.state;
    let facilityTaskList = procedure?.facilityTaskList;
    let ischecked = e.target.checked;
    let selectedTaskIds = [];
    if (ischecked) {
      selectedTaskIds = facilityTaskList?.map((x) => x?.taskId);
    }
    facilityTaskList = facilityTaskList.map((row) => {
      return {
        ...row,
        checked: ischecked,
      };
    });
    procedure.facilityTaskList = facilityTaskList;

    this.setState({ selectedTaskIds, procedure: _.cloneDeep(procedure) });

    this.setState({ selectedTaskIds });
  };
  closeBulkAction = () => {
    let { taskColumns } = this.state;
    taskColumns = taskColumns.map((x) => {
      if (x.field == "name") {
        x.editable = !this.shouldBeDisableControlBasedOnPermission();
      } else {
        x.editable = false;
      }
      return x;
    });
    this.gridApi.setColumnDefs(taskColumns);

    const procedure = _.cloneDeep(this.state.procedure);
    let facilityTaskList = procedure.facilityTaskList;
    facilityTaskList = facilityTaskList.map((x) => {
      if (x?.checked) {
        x.checked = false;
      }
      return x;
    });
    procedure.facilityTaskList = _.cloneDeep(facilityTaskList);

    this.setState(
      { selectedTaskIds: [], procedure, taskColumns: _.cloneDeep(taskColumns) },
      () => {
        this.columnWidthSizeToFit();
      }
    );
  };

  renderSharedTooltip = (e) => (
    <Tooltip id="button-tooltipPwd" {...e}>
      Shared Procedures can be used across all properties
    </Tooltip>
  );

  shouldBeDisableControlBasedOnPermission = () => {
    const {
      hasSharedAddEditPermission,
      hasPropetySpecificAddEditPermission,
      procedure,
    } = this.state;
    let result = false;
    if (!hasSharedAddEditPermission && !hasPropetySpecificAddEditPermission) {
      result = true;
    } else if (
      (procedure?.isGlobal && !hasSharedAddEditPermission) ||
      (!procedure?.isGlobal && !hasPropetySpecificAddEditPermission)
    ) {
      result = true;
    } else {
      result = false;
    }
    return result;
  };

  handleRowSelection = (e, rowData) => {
    let checked = e.target.checked;
    let { selectedTaskIds, procedure } = this.state;
    if (!checked) {
      selectedTaskIds = selectedTaskIds.filter(
        (taskId) => taskId != rowData?.data.taskId
      );
    } else {
      selectedTaskIds.push(rowData?.data.taskId);
    }
    let facilityTaskList = procedure?.facilityTaskList;

    facilityTaskList = facilityTaskList.map((row) => {
      if (row.taskId === rowData?.data.taskId) {
        return {
          ...row,
          checked: checked,
        };
      } else {
        return row;
      }
    });
    procedure.facilityTaskList = facilityTaskList;

    this.setState({ selectedTaskIds, procedure: _.cloneDeep(procedure) });
  };

  renderInfoIcon = () => {
    return (
      <svg
        stroke="currentColor"
        fill="currentColor"
        stroke-width="0"
        version="1.1"
        viewBox="0 0 16 16"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7 4.75c0-0.412 0.338-0.75 0.75-0.75h0.5c0.412 0 0.75 0.338 0.75 0.75v0.5c0 0.412-0.338 0.75-0.75 0.75h-0.5c-0.412 0-0.75-0.338-0.75-0.75v-0.5z"></path>
        <path d="M10 12h-4v-1h1v-3h-1v-1h3v4h1z"></path>
        <path d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM8 14.5c-3.59 0-6.5-2.91-6.5-6.5s2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5-2.91 6.5-6.5 6.5z"></path>
      </svg>
    );
  };
  onGridReady = (params: any) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.columnWidthSizeToFit();
  };

  getAgGridOptions = () => {
    const gridOptions: GridOptions = {
      rowData: [],
      overlayNoRowsTemplate: "",
      rowSelection: "single",
      singleClickEdit: true,
      getRowHeight: (params) => {
        return 40;
      },
      frameworkComponents: {
        checkBoxCellRenderer: this.checkBoxCellRenderer,
        deleteCellRenderer: this.deleteCellRenderer,
        nameCellRenderer: this.nameCellRenderer,
      },
    };
    return gridOptions;
  };

  nameCellRenderer(params: any) {
    return (
      <div className="task-ttl">
        <EllipsisWithTooltip placement="bottom">
          {params?.data?.name}
        </EllipsisWithTooltip>
      </div>
    );
  }
  checkBoxCellRenderer = (params) => {
    return (
      <div className="employee-name-wrapper d-flex align-items-center">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            disabled={
              params?.data?.isEditingStarted ||
              this.shouldBeDisableControlBasedOnPermission()
            }
            checked={params.data.checked}
            onChange={(e) => this.handleRowSelection(e, params)}
          />
        </div>
      </div>
    );
  };

  deleteCellRenderer = (params: any): any => {
    const list = this.state.procedure?.facilityTaskList;

    return params?.data?.isEditingStarted ? (
      ""
    ) : (
      <svg
        className={
          this.shouldBeDisableControlBasedOnPermission() || list?.length === 1
            ? "task-delete-disabled"
            : ""
        }
        onClick={() =>
          !this.shouldBeDisableControlBasedOnPermission() && list?.length > 1
            ? this.deleteProcedureTaskBulk([params.data.taskId])
            : {}
        }
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M5 5.83366C4.53917 5.83366 4.16667 5.83366 4.16667 5.83366V16.667C4.16667 17.587 4.91333 18.3337 5.83333 18.3337H14.1667C15.0867 18.3337 15.8333 17.587 15.8333 16.667V5.83366C15.8333 5.83366 15.4608 5.83366 15 5.83366H5ZM8.33333 15.8337H6.66667V8.33366H8.33333V15.8337ZM13.3333 15.8337H11.6667V8.33366H13.3333V15.8337ZM13.8483 3.33366L12.5 1.66699H7.5L6.15167 3.33366H2.5V5.00033H6.66667H13.3333H17.5V3.33366H13.8483Z"
          fill="#2CAF92"
        />
      </svg>
    );
  };

  columnWidthSizeToFit = () => {
    this.gridApi?.sizeColumnsToFit();
  };

  checkIfDataChange = (): boolean => {
    let result = false;
    const formdataresult = !_.isEqual(
      _.omit(this.state.procedure, ["facilityTaskList"]),
      _.omit(this.state.originalProcedure, ["facilityTaskList"])
    );
    const taskListresult = !_.isEqual(
      _.map(this.state.procedure?.facilityTaskList, (row) => {
        return _.omit(row, ["isEditingStarted"]);
      }),
      _.map(this.state.originalProcedure?.facilityTaskList, (row) => {
        return _.omit(row, ["isEditingStarted"]);
      })
    );

    result = formdataresult || taskListresult;
    if (
      this.state.procedure?.facilityTaskList?.filter((x) => !x?.name)?.length >
      0
    ) {
      result = false;
    }

    return result;
  };

  changeNewProcedureName = (value) => {
    if (value?.toString()?.trim()?.length > 50) {
      return;
    }
    const { procedure, isFromMainSave } = this.state;
    if (isFromMainSave) {
      procedure.name = value?.trimLeft();
      this.setState({ procedure });
    }
    this.setState({ newProcedureName: value?.trimLeft(), isDuplicate: false });
  };

  onTabKeyDown = (e) => {
    e.preventDefault();
    if (
      e?.currentTarget?.childNodes[0]?.ariaExpanded === "false" &&
      e?.keyCode === 9
    ) {
      e?.currentTarget?.childNodes[0]?.click();
    }
  };

  render() {
    const {
      procedureList,
      hasSharedAddEditPermission,
      hasPropetySpecificAddEditPermission,
      assetTypes,
      procedureTypeList,
      taskColumns,
      selectedTaskIds,
      procedure: {
        procedureId,
        name,
        type,
        hids,
        isGlobal,
        assetTypeId,
        facilityTaskList,
      },
    } = this.state;
    const hoverOutEllipsis = () => {
      $(".tooltip").removeClass("show");
    };

    const columns = [
      {
        dataField: "name",
        text: "",
        classes: "w-100",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div>
            <div className="title" onWheel={() => hoverOutEllipsis()}>
              <EllipsisWithTooltip placement="bottom">
                {row.name}
              </EllipsisWithTooltip>
            </div>
          </div>
        ),
      },
    ];

    const selectRow = {
      mode: "radio",
      hideSelectColumn: true,
      classes: "active-row",
      selected: this.state.isSelected ? this.state.selected : "",
    };

    const hideRow = {
      mode: "radio",
      hideSelectColumn: true,
      classes: "",
    };

    return (
      <React.Fragment>
        <Container fluid className="body-sec">
          <ToastContainer autoClose={3000} />
          <div className="page-heading d-flex">
            <div className="mr-auto">Procedures</div>
            <div className="action-group d-flex flex-row">
              <>
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={this.shouldBeDisableControlBasedOnPermission()}
                  onClick={() =>
                    this.rightPanelConfirmation(this.addNewProcedure.bind(this))
                  }
                >
                  New Procedure
                </button>
                <Dropdown className="more-action ml-3">
                  <DropdownToggle
                    className="btn-outline-primary btn btn-primary more"
                    id="dropdown-more"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                      <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                      <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                    </svg>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem>Not Found</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </>
            </div>
          </div>
          <div className="section" style={{ display: "flex" }}>
            <div className="budget-settings time-off-settings procedure-page-wrapper d-flex justify-content-start ptoSettings">
              <div className="left-panel">
                <ToolkitProvider
                  keyField="name"
                  columns={columns}
                  data={procedureList || []}
                  search
                >
                  {(props: {
                    searchProps: JSX.IntrinsicAttributes;
                    baseProps: JSX.IntrinsicAttributes;
                  }) => (
                    <div>
                      <div className="search-header">
                        <SearchBar
                          {...props.searchProps}
                          placeholder={"Search..."}
                        />
                      </div>
                      {this.state.leftPanelLoader ? (
                        <ReactPageLoader useas="timeOffSettingsLeftPanel" />
                      ) : (
                        <div>
                          <div className="left-body-wrapper">
                            <div className="left-body">
                              <h6 className="sub-heading">
                                Shared{" "}
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={this.renderSharedTooltip}
                                >
                                  {this.renderInfoIcon()}
                                </OverlayTrigger>
                              </h6>
                              <BootstrapTable
                                {...props.baseProps}
                                keyField="procedureId"
                                rowEvents={this.rowEvents}
                                hover
                                data={_.orderBy(
                                  procedureList?.filter((x) => x?.isGlobal),
                                  [(x) => x?.name?.toString()?.toLowerCase()],
                                  ["asc"]
                                )}
                                condensed
                                selectRow={
                                  this.state.isSelected ? selectRow : hideRow
                                }
                              />

                              <button
                                type="button"
                                className={`add-Budget ttl ${
                                  !hasSharedAddEditPermission
                                    ? "disabled-link"
                                    : ""
                                }`}
                                disabled={!hasSharedAddEditPermission}
                                onClick={() =>
                                  this.rightPanelConfirmation(
                                    this.addNewProcedure.bind(this, true)
                                  )
                                }
                              >
                                New Shared Procedure
                              </button>
                            </div>
                            <div className="left-body">
                              <h6 className="sub-heading">Property Specific</h6>
                              <BootstrapTable
                                {...props.baseProps}
                                keyField="procedureId"
                                rowEvents={this.rowEvents}
                                hover
                                data={_.orderBy(
                                  procedureList?.filter((x) => !x?.isGlobal),
                                  [(x) => x?.name?.toString()?.toLowerCase()],
                                  ["asc"]
                                )}
                                condensed
                                selectRow={
                                  this.state.isSelected ? selectRow : hideRow
                                }
                              />

                              <button
                                type="button"
                                className={`add-Budget ttl ${
                                  !hasPropetySpecificAddEditPermission
                                    ? "disabled-link"
                                    : ""
                                }`}
                                disabled={!hasPropetySpecificAddEditPermission}
                                onClick={() =>
                                  this.rightPanelConfirmation(
                                    this.addNewProcedure.bind(this, false)
                                  )
                                }
                              >
                                New Procedure
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </ToolkitProvider>
              </div>

              {this.state.isRPanel && (
                <div className="right-panel">
                  {this.state.rightPanelLoader ? (
                    <ReactPageLoader useas="timeOffSettingsRightPanel" />
                  ) : (
                    <>
                      <div className="pos-header d-flex align-content-center flex-wrap">
                        <div className="pos-ttl mr-auto">
                          <OverlayTrigger
                            placement="bottom"
                            defaultShow={false}
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip
                                id={"Procedure-Name"}
                                className={
                                  name.length <= 44 ? "tootltip-hide" : ""
                                }
                              >
                                {name}
                              </Tooltip>
                            }
                          >
                            <AutosizeInput
                              ref={this.input}
                              className={
                                this.state.bdrValue
                                  ? "form-control removeBorder input-component bdr-green"
                                  : "form-control removeBorder input-component"
                              }
                              style={{ fontSize: 24 }}
                              injectStyles={false}
                              type="input"
                              value={name}
                              tabIndex={1}
                              disabled={
                                selectedTaskIds?.length > 0 ||
                                this.shouldBeDisableControlBasedOnPermission()
                              }
                              onClick={() => this.handleClick()}
                              onChange={(e: any) =>
                                this.onFieldValueChange("name", e.target.value)
                              }
                              onBlur={() => this.handleBlurAutoSize()}
                              placeholder={"Procedure Name"}
                              maxLength={50}
                            />
                          </OverlayTrigger>
                          <div className="badge">
                            {isGlobal ? "Shared" : "Property Specific"}
                          </div>
                        </div>

                        <Dropdown className="more-action" alignRight>
                          <Dropdown.Toggle
                            className="btn-outline-primary btn btn-primary more"
                            id="dropdown-more"
                            disabled={
                              selectedTaskIds?.length > 0 ||
                              !procedureId ||
                              this.shouldBeDisableControlBasedOnPermission()
                            }
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                              <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                              <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                            </svg>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() =>
                                this.rightPanelConfirmation(
                                  this.confirmAlertDelete.bind(this)
                                )
                              }
                            >
                              <img src={Delete} alt="Delete" /> Delete
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() =>
                                this.rightPanelConfirmation(
                                  this.showHideDuplicateModal.bind(
                                    this,
                                    true,
                                    "Copy of " + name
                                  )
                                )
                              }
                            >
                              <img src={Duplicate} alt="Duplicate" /> Duplicate
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div
                        className={
                          this.checkIfDataChange()
                            ? "pos-body dataChange"
                            : "pos-body"
                        }
                      >
                        <Form>
                          <Form.Group as={Row} controlId="carryover">
                            <Form.Label column sm="6" lg="4">
                              Procedure Type
                            </Form.Label>
                            <Col sm="3" lg="2" className="pr-0">
                              <Dropdown
                                onKeyUp={(e) => this.onTabKeyDown(e)}
                                className="procedure-dropdown"
                                onSelect={(value) => {
                                  this.onFieldValueChange("type", value);
                                }}
                              >
                                <Dropdown.Toggle
                                  tabIndex={2}
                                  id="dropdown-ptoType"
                                  disabled={
                                    selectedTaskIds?.length > 0 ||
                                    this.shouldBeDisableControlBasedOnPermission()
                                  }
                                  className="d-flex align-items-center"
                                >
                                  <div className="d-flex">
                                    <div className="txt-area">{type}</div>
                                    <div className="drop-arrow">
                                      <svg
                                        stroke="currentColor"
                                        fill="none"
                                        stroke-width="2"
                                        viewBox="0 0 24 24"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <polyline points="6 9 12 15 18 9"></polyline>
                                      </svg>
                                    </div>
                                  </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  {procedureTypeList.map(
                                    (item: any, idx: any) => (
                                      <Dropdown.Item
                                        key={idx}
                                        eventKey={item.name}
                                      >
                                        {item.name}
                                      </Dropdown.Item>
                                    )
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </Col>
                          </Form.Group>
                          <>
                            {type === "Asset" && (
                              <Form.Group as={Row} controlId="carryover">
                                <Form.Label column sm="6" lg="4">
                                  Asset Type
                                </Form.Label>
                                <Col sm="6" lg="8" className="pr-0">
                                  <Dropdown
                                    onKeyUp={(e) => this.onTabKeyDown(e)}
                                    className="procedure-dropdown asset-dropdown"
                                    onSelect={(value) => {
                                      this.onFieldValueChange(
                                        "assetTypeId",
                                        Number(value)
                                      );
                                    }}
                                  >
                                    <Dropdown.Toggle
                                      id="dropdown-assetType"
                                      disabled={
                                        selectedTaskIds?.length > 0 ||
                                        this.shouldBeDisableControlBasedOnPermission()
                                      }
                                      className="d-flex align-items-center"
                                      tabIndex={3}
                                    >
                                      <div className="d-flex">
                                        <div className="txt-area">
                                          <EllipsisWithTooltip placement="bottom">
                                            {!assetTypeId
                                              ? "Select"
                                              : assetTypes.find(
                                                  (x) => x.id === assetTypeId
                                                )?.name}
                                          </EllipsisWithTooltip>
                                        </div>
                                        <div className="drop-arrow">
                                          <svg
                                            stroke="currentColor"
                                            fill="none"
                                            stroke-width="2"
                                            viewBox="0 0 24 24"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <polyline points="6 9 12 15 18 9"></polyline>
                                          </svg>
                                        </div>
                                      </div>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      {assetTypes.map((item: any, idx: any) => (
                                        <Dropdown.Item
                                          key={idx}
                                          eventKey={item.id}
                                        >
                                          <EllipsisWithTooltip placement="bottom">
                                            {item.name}
                                          </EllipsisWithTooltip>
                                        </Dropdown.Item>
                                      ))}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </Col>
                              </Form.Group>
                            )}
                          </>
                          <Form.Group as={Row} controlId="earningmethod">
                            <Form.Label column sm="6" lg="4">
                              Shared
                            </Form.Label>
                            <Col sm="6">
                              <Form.Check
                                type="switch"
                                id="sw_autopunchout"
                                label=""
                                tabIndex={4}
                                checked={isGlobal}
                                disabled={
                                  selectedTaskIds?.length > 0 ||
                                  this.shouldBeDisableControlBasedOnPermission() ||
                                  (isGlobal &&
                                    !hasPropetySpecificAddEditPermission) ||
                                  (!isGlobal && !hasSharedAddEditPermission)
                                }
                                onChange={(e: any) => {
                                  this.onFieldValueChange(
                                    "isGlobal",
                                    e.target.checked
                                  );
                                }}
                              />
                            </Col>
                          </Form.Group>

                          {!isGlobal && (
                            <Form.Group as={Row} controlId="earningmethod">
                              <Form.Label column sm="6" lg="4">
                                Applies To
                              </Form.Label>
                              <Col sm="6">
                                <MultiSelectSearch
                                  tabIndex={5}
                                  pageName="Procedures"
                                  ref={this.multiselectChild}
                                  itemList={this.state.allHotelList}
                                  updateMultiSelectItem={(selectedHids) => {
                                    this.onFieldValueChange(
                                      "hids",
                                      selectedHids?.map((x) => x?.hotelID)
                                    );
                                  }}
                                  dataChange={this.hidSelectionChange.bind(
                                    this
                                  )}
                                  isSelectAll={true}
                                  isDisable={
                                    selectedTaskIds?.length > 0 ||
                                    this.shouldBeDisableControlBasedOnPermission()
                                  }
                                  filtertitle={"Filter EHIDs"}
                                />
                              </Col>
                            </Form.Group>
                          )}
                          {/* Apply to All Ehids Row Ends*/}

                          <div className="add-task-table-wrapper">
                            <div className="add-task-table">
                              <h3 className="task-heading">Tasks</h3>

                              {facilityTaskList?.length > 0 && (
                                <AgGridReact
                                  onGridReady={this.onGridReady}
                                  domLayout={"autoHeight"}
                                  //    onDragStopped={this.columnDragStop}
                                  // style ={{minHeight: 200}}
                                  rowData={facilityTaskList}
                                  columnDefs={taskColumns}
                                  onCellEditingStarted={(params: any) => {
                                    params.data.isEditingStarted = true;
                                    params.node.updateData(params.data);
                                    params.api.refreshCells({
                                      force: true,
                                      suppressFlash: true,
                                      rowNodes: [params.node],
                                    });
                                  }}
                                  onCellEditingStopped={(params) => {
                                    const val = params?.newValue
                                      ?.toString()
                                      ?.trim();
                                    if (val?.length > TaskNamemaxlength) {
                                      Utils.toastError(
                                        "Task Name should be maximum 200 characters.",
                                        {
                                          position: toast.POSITION.BOTTOM_RIGHT,
                                        }
                                      );

                                      params.data.isEditingStarted = false;
                                      params.data.name = params?.newValue
                                        ?.toString()
                                        ?.trim();

                                      params.data.name =
                                        params.data.name.substring(
                                          0,
                                          TaskNamemaxlength
                                        );
                                      const value = params.data.name;
                                      const procedure = _.cloneDeep(
                                        this.state.procedure
                                      );
                                      let facilityTaskList =
                                        procedure.facilityTaskList;
                                      facilityTaskList = facilityTaskList.map(
                                        (x) => {
                                          if (x.taskId === params.data.taskId) {
                                            x.name = value;
                                          }
                                          if (value !== params.oldValue) {
                                            x.checked = false;
                                          }
                                          x.isEditingStarted = false;
                                          return x;
                                        }
                                      );
                                      procedure.facilityTaskList =
                                        _.cloneDeep(facilityTaskList);
                                      if (value !== params.oldValue) {
                                        this.setState({ selectedTaskIds: [] });
                                      }
                                      this.setState({ procedure });

                                      return;
                                    }

                                    params.data.isEditingStarted = false;
                                    params.data.name = params?.newValue
                                      ?.toString()
                                      ?.trim();

                                    params.node.updateData(params.data);

                                    params.api.refreshCells({
                                      force: true,
                                      suppressFlash: true,
                                      rowNodes: [params.node],
                                    });
                                    if (
                                      !params?.newValue?.toString()?.trim() &&
                                      (params?.node as any)?.eventService
                                        ?.firedEvents?.mouseLeave
                                    ) {
                                      this.deleteProcedureTaskBulk(
                                        [params?.data?.taskId],
                                        false,
                                        true
                                      );
                                    }
                                  }}
                                  onCellKeyPress={(params: any) => {
                                    if (params?.event?.key === "Enter") {
                                      if (
                                        params.api.getRenderedNodes().length ===
                                          params.rowIndex + 1 &&
                                        params.data?.name?.trim()
                                      ) {
                                        this.addTask();
                                      } else {
                                        if (
                                          params.api.getRenderedNodes()
                                            .length ===
                                          params.rowIndex + 1
                                        ) {
                                          this?.gridApi?.startEditingCell({
                                            rowIndex: params.rowIndex,
                                            colKey: "name",
                                          });
                                        } else {
                                          this?.gridApi?.startEditingCell({
                                            rowIndex: params.rowIndex + 1,
                                            colKey: "name",
                                          });
                                        }
                                      }
                                    } else {
                                      const val = params.event.target.value;
                                      if (val.length > TaskNamemaxlength) {
                                        this?.gridApi.stopEditing();
                                      }
                                    }
                                  }}
                                  suppressRowDrag={true}
                                  stopEditingWhenGridLosesFocus={true}
                                  gridOptions={this.getAgGridOptions()}
                                />
                              )}
                              <button
                                type="button"
                                tabIndex={7}
                                className={`add-Budget ttl ${
                                  this.shouldBeDisableControlBasedOnPermission()
                                    ? "disabled"
                                    : ""
                                }`}
                                disabled={this.shouldBeDisableControlBasedOnPermission()}
                                onClick={(e) => this.addTask()}
                              >
                                Add Task
                              </button>
                            </div>
                          </div>
                        </Form>
                        {this.state.selectedTaskIds?.length > 0 &&
                          !this.shouldBeDisableControlBasedOnPermission() && (
                            <div className="tableFooter">
                              <div className="bulkSelectFooter">
                                <Form.Check
                                  type="checkbox"
                                  label=""
                                  tabIndex={8}
                                  id="footerBulkCheckBox"
                                  ref={(chkRef) => {
                                    if (chkRef) {
                                      this.checkAllRef = chkRef;
                                      if (
                                        this.state.selectedTaskIds?.length ===
                                        facilityTaskList.length
                                      ) {
                                        chkRef.checked = true;
                                      } else {
                                        chkRef.checked = false;
                                      }

                                      if (
                                        this.state.selectedTaskIds?.length >
                                          0 &&
                                        !chkRef?.checked
                                      ) {
                                        chkRef.indeterminate = true;
                                      } else {
                                        chkRef.indeterminate = false;
                                      }
                                    }
                                  }}
                                  onClick={(e: any) => {
                                    e.stopPropagation();
                                    this.bulkSelectTaskList(e);
                                  }}
                                />
                                <span className="shift-selected-count">
                                  {this.state.selectedTaskIds?.length}{" "}
                                  {this.state.selectedTaskIds?.length <= 1 &&
                                    "task"}
                                  {this.state.selectedTaskIds?.length > 1 &&
                                    "tasks"}{" "}
                                  selected
                                </span>
                                <div className="action-group d-flex flex-row mr-auto">
                                  <Button
                                    tabIndex={9}
                                    disabled={
                                      this.state.selectedTaskIds?.length <= 0
                                    }
                                    className="unassign btn-outline-primary"
                                    onClick={() =>
                                      this.bulkTasksDeleteConfirmation()
                                    }
                                  >
                                    Delete Tasks
                                  </Button>
                                </div>
                                <Button
                                  tabIndex={10}
                                  className="closeButton btn-ghost-light"
                                  onClick={() => this.closeBulkAction()}
                                >
                                  &times;
                                </Button>
                              </div>
                            </div>
                          )}

                        {this.checkIfDataChange() &&
                          !this.shouldBeDisableControlBasedOnPermission() &&
                          this.state.selectedTaskIds?.length === 0 && (
                            <div className="fixed-action">
                              <div className="d-flex align-content-center flex-wrap">
                                <div className="mr-auto message">
                                  <span>You have unsaved changes</span>
                                </div>
                                <button
                                  type="button"
                                  tabIndex={11}
                                  className="btn btn-primary btn-discard"
                                  onClick={this.handleDiscard}
                                >
                                  Discard
                                </button>

                                <button
                                  type="button"
                                  tabIndex={12}
                                  className={
                                    this.state.saveButtonSpinner
                                      ? "btn btn-primary p-none"
                                      : "btn btn-primary"
                                  }
                                  onClick={() => this.handleSave()}
                                >
                                  {this.state.saveButtonSpinner && (
                                    <Spinner
                                      className="mr-2"
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                  )}
                                  Save
                                </button>
                              </div>
                            </div>
                          )}
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>

            <Modal
              className="rename-report-modal new-procedure-modal"
              show={this.state.showDuplicateProcedureModal}
              onHide={() => this.showHideDuplicateModal(false)}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Enter new procedure name</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group
                  className={this.state?.isDuplicate ? "validation-error" : ""}
                >
                  <input
                    type="text"
                    value={this.state.newProcedureName}
                    maxLength={50}
                    onChange={(e) =>
                      this.changeNewProcedureName(e.target.value)
                    }
                    className="form-control"
                  ></input>
                  <span className="validation-message">
                    {this.state?.isDuplicate
                      ? "A procedure with this name already exists. Please save this procedure with a different name."
                      : ""}
                  </span>
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                {/* <Button
                className="cancel-btn btn-ghost ml-auto"
                onClick={() => this.showHideDuplicateModal()}
              >
                Cancel
              </Button> */}
                <Button
                  disabled={this.state.disableSave}
                  className="reset-password-btn"
                  onClick={() => {
                    this.partialDuplicateProcedure(this.state.isFromMainSave);
                  }}
                >
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}
