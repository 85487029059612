import * as React from "react";
import { Form, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import DatePicker from "react-datepicker";
import { ToastContainer, toast } from "react-toastify";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { resolve, reject } from "q";
import _ from "lodash";
import { PmsMonitoringService as pmsMonitoringService } from "../../../Common/Services/PmsMonitoringService";
import { ReactPageLoader } from "../../../Common/Components/ReactPageLoader";
import { DropDownList } from "../../Reports/common-components/dropdown-list";
import { Utils } from "../../../Common/Utilis";
import "./pmsImport.scss";

export class FilePmsMonitoring extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            searchText: "",
            isPermission: "No",
            pmsMonitoringData: [],
            filteredpmsMonitoringData: [],
            isLoader: false,
            tenantsList: [],
            tenantData: {
                userUniqueno: this.props?.userUniqueNo,
                tenant: 0
            },
            hotelList: [],
            hotelData: {
                hotelid: 0,
                lettercode: ""
            },
            pmsList: [],
            pmsData: {
                pmsid: 0,
                daAutoImport: ""
            },
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            maxDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            minDate: new Date(new Date().setDate(new Date().getDate() - 7))
        };
    }

    componentDidMount() {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : storage.tenantID;
        if (tenantID !== 230) {
            window.location.href = "/unauthorize-access";
        } else {
            this.setState({ isPermission: "Yes" }, () => {
                this.getFilePmsMonitoringData();
            })
        }
    }

    getFilePmsMonitoringData = () => {
        const { startDate } = this.state;

        let request: any = {};
        request.Date = startDate;
        this.setState({ isLoader: true })

        pmsMonitoringService.GetFileBasedMonitoringData(request)
            .then(async (result: any | null) => {
                if (result.length > 0) {
                    this.setState({ pmsMonitoringData: result, filteredpmsMonitoringData: result, isLoader: false }, () => this.getTenantsList(result))
                }
                else {
                    this.setState({ pmsMonitoringData: [], filteredpmsMonitoringData: [], isLoader: false });
                }
                resolve();
            })
            .catch((error) => {
                this.setState({ pmsMonitoringData: [], filteredpmsMonitoringData: [], isLoader: false }, () => {
                    Utils.toastError(error.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        containerId: "PmsMonitoring",
                    });
                });
                reject();
            });
    }

    getTenantsList = (result) => {
        let uniqueTenantList = _.uniqBy(result, "tenantId");
        uniqueTenantList.map((items, index) => ((items["id"] = index, items["tenantid"] = items.tenantId, items["tenantname"] = items.tenantId)));

        const tenantsList = [
            { id: -1, tenantname: "All", tenantid: "All" },
            ...uniqueTenantList
        ];
        this.setState({ tenantsList }, () => this.getPMSList(result))
    }

    handleDateChange = (date) => {
        const { tenantData, hotelData, pmsData } = this.state;
        tenantData["tenant"] = 0;
        hotelData["hotelid"] = 0;
        hotelData["lettercode"] = "";
        pmsData.pmsid = 0;
        pmsData.daAutoImport = "";

        if (date === null) date = new Date(new Date().setDate(new Date().getDate() - 1));
        this.setState({ startDate: date, tenantData, hotelData, pmsData, hotelList: [], searchText: "" }, () => this.getFilePmsMonitoringData());
    }

    onTenantChange = (event, inputPosition, isBlur = false) => {
        const { tenantData, hotelData, pmsData: { daAutoImport } } = this.state;
        let value = event.target.value;
        const fieldName = event.target.name;
        tenantData[fieldName] = value;
        hotelData["lettercode"] = "";
        hotelData["hotelid"] = 0;

        this.setState({ tenantData, searchText: "", hotelData, hotelList: [] }, () => {
            this.getHotelsListFromTenantId(value);
        });
        this.performFilter(value, "", daAutoImport, "");
    }

    getHotelsListFromTenantId = (selectedTenantId) => {
        const { pmsMonitoringData } = this.state;
        let hotels = _.uniqBy(pmsMonitoringData, "hotel");
        let uniqueHotelsFromTenant = hotels.filter(x => x.tenantId === selectedTenantId);
        uniqueHotelsFromTenant.map((items, index) => ((items["id"] = index, items["lettercode"] = items.hotel, items["hotelid"] = items.hotel)));

        const hotelList = [
            { id: -1, lettercode: "All", hotelid: "All" },
            ...uniqueHotelsFromTenant
        ];
        this.setState({ hotelList });
    }

    onEhidChange = (event, inputPosition, isBlur = false) => {
        const { hotelData, tenantData: { tenant }, pmsData: { daAutoImport } } = this.state;
        let value = event.target.value;
        let name = event.target.name;

        hotelData["lettercode"] = value;
        hotelData["hotelid"] = name;

        this.setState({ hotelData, searchText: "" });
        this.performFilter(tenant, value, daAutoImport, "")
    }

    getPMSList = (result) => {
        let uniquePmsList = _.uniqBy(result, "pmsType");
        uniquePmsList.map((items, index) => ((items["id"] = index, items["daAutoImport"] = items.pmsType, items["hotelid"] = items.pmsType)));

        const pmsList = [
            { id: -1, daAutoImport: "All", hotelid: "All" },
            ...uniquePmsList
        ];
        this.setState({ pmsList })
    }

    onPmsChange = (event, inputPosition, isBlur = false) => {
        const { pmsData, tenantData: { tenant }, hotelData: { lettercode } } = this.state;
        let value = event.target.value;
        const fieldName = event.target.name;
        pmsData[fieldName] = value;
        pmsData.pmsid = value;

        this.setState({ pmsData, searchText: "" });
        this.performFilter(tenant, lettercode, value, "")
    }

    onSearchChange = (searchText: string) => {
        const { tenantsList, pmsList } = this.state;
        let tenant = tenantsList;
        let pms = pmsList;
        this.setState({
            searchText, tenantData: { tenant: 0 }, hotelData: { hotelid: 0, lettercode: "" }, pmsData: { pmsid: 0, daAutoImport: "" },
            tenantsList: [], hotelList: [], pmsList: []
        }, () => this.setState({ tenantsList: tenant, pmsList: pms }));

        this.performFilter(0, "", "", searchText);
    }

    performFilter = (tenant, hotel, pms, search) => {
        const { pmsMonitoringData } = this.state;

        let filteredData = pmsMonitoringData;
        let filtered: any = [];

        if (search === "") {
            filtered = filteredData.filter(item => {
                if ((tenant === 0 || tenant === 'All' || item.tenantId === tenant) &&
                    (hotel === '' || hotel === 'All' || item.hotel === hotel) &&
                    (pms === '' || pms === 'All' || item.pmsType === pms)) {
                    return true;
                }
                return false;
            });
        } else {
            filtered = filteredData.filter(item => {
                if ((item.pmsType.toLowerCase().includes(search.toLowerCase())) ||
                    (item.tenantId.toString().includes(search)) ||
                    (item.hotel.toLowerCase().includes(search.toLowerCase()))) {
                    return true;
                }
                return false;
            });
        }

        const updatedFiltered: any = [];
        const addedItems = new Set(); // To keep track of added items

        for (const item of filtered) {
            if (item.isChild === 1) {
                const matchingParent = filteredData.find(parent => parent.hotel === item.hotel &&
                    parent.tenantId === item.tenantId && parent.isChild === 0);
                if (matchingParent && !addedItems.has(matchingParent.rno)) {
                    updatedFiltered.push(matchingParent);
                    addedItems.add(matchingParent.rno);
                }
            }
            if (!addedItems.has(item.rno)) {
                updatedFiltered.push(item);
                addedItems.add(item.rno);
            }
        }

        this.setState({ filteredpmsMonitoringData: [] }, () => this.setState({ filteredpmsMonitoringData: updatedFiltered }));
    }

    tenantIdFormatter(cell: any, row: any, rowIndex: any) {
        return (
            <div>{row.isChild ? "" : row.tenantId}</div>
        );
    }

    ehidFormatter(cell: any, row: any, rowIndex: any) {
        return (
            <div>{row.isChild ? "" : row.hotel}</div>
        );
    }

    startedFormatter(cell: any, row: any, rowIndex: any) {
        return (
            row.rstatus === "RUNNING" && row.sstatus !== "ERROR" ?
                <div>{row.runningTime}</div> :
                row.sstatus === "ERROR" ?
                    <div className="dot-icon red"></div>
                    : <div className={row.rstatus === "IMPORTED" ? "dot-icon green" : "dot-icon red"}></div>
        );
    }

    completedFormatter(cell: any, row: any, rowIndex: any) {
        return (
            <div className={row.sstatus === "PROCESSED" ? "dot-icon green" : row.sstatus === "NOTRECEIVED" ? "dot-icon orange" : "dot-icon red"}></div>
        );
    }

    errorFormatter(cell: any, row: any, rowIndex: any) {
        return (
            <EllipsisWithTooltip placement="bottom">
                <div className="error-msg">{row.sstatus !== "" && row.error}</div>
            </EllipsisWithTooltip>
        );
    }

    rowClasses(row, rowIndex) {
        let classes = 'hide-bottom-bdr';

        if (!row.isChild) {
            classes = classes + ' show-top-bdr';
        }

        return classes;
    };

    render() {
        const { filteredpmsMonitoringData, tenantsList, hotelList, startDate, maxDate, minDate, isPermission, pmsList, searchText,
            tenantData: {
                tenant
            },
            hotelData: {
                hotelid
            },
            pmsData: {
                pmsid
            }
        } = this.state;

        const tooltipmsg = (recievedFiles) => {
            let arr = recievedFiles.split("*");
            return arr[0];
        }

        const tooltipfiles = (recievedFiles) => {
            let blanckarr = [];
            let arr = recievedFiles.split("*");
            if (arr[1].length === 0) {
                return blanckarr;
            } else {
                return arr[1].split(";");
            }
        }

        const columns = [
            {
                dataField: "tenantId",
                text: "Tenant ID",
                headerClasses: "width8",
                formatter: this.tenantIdFormatter
            }, {
                dataField: "hotel",
                text: "EHID",
                formatter: this.ehidFormatter
            }, {
                dataField: "pmsType",
                text: "PMS Type",
            }, {
                dataField: "pstatus",
                text: "Received",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        row.pstatus === "NOTRECEIVED" ?
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                    <Tooltip id={"letf-nav1"}>
                                        {tooltipmsg(row.recievedFiles)}
                                        {tooltipfiles(row.recievedFiles).map((item) => (
                                            <div key={item}>{item}</div>
                                        ))}
                                    </Tooltip>
                                }

                            >
                                <div className="dot-icon red"></div>
                            </OverlayTrigger> : <div className="dot-icon green"></div>
                    );
                },
            }, {
                dataField: "rstatus",
                text: "Started",
                formatter: this.startedFormatter
            }, {
                dataField: "sstatus",
                text: "Completed",
                formatter: this.completedFormatter
            }, {
                dataField: "error",
                text: "Error",
                headerClasses: "width25",
                formatter: this.errorFormatter
            },
        ];
        return (
            <Container fluid className="body-sec pms-import">
                <ToastContainer
                    autoClose={3000}
                    containerId={"PmsMonitoring"}
                    enableMultiContainer
                />
                {isPermission === "Yes" && (
                    <>
                        <div className="page-heading underline d-flex">
                            <div className="mr-auto d-flex align-items-center">PMS Import Monitoring (File Based)</div>
                        </div>
                        <div className="main-Page">
                            <div className="bdy-sec">
                                {!this.state.isLoader && (
                                    <div className="search-header">
                                        <div className="filter-section d-flex">
                                            <Form.Group controlId="date">
                                                <Form.Label>Date</Form.Label>
                                                <div className="date-picker calender-picker">
                                                    <div className="drop-arrow arrow-right30">
                                                        <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M3.28122e-05 1.19724C3.28122e-05 0.889538 0.253262 0.653982 0.543288 0.653982C0.688297 0.653982 0.833313 0.708449 0.923855 0.816677L4.0023 4.0217L7.08074 0.816677C7.2979 0.599519 7.6424 0.599519 7.84116 0.816677C8.04064 1.01616 8.05831 1.34153 7.85955 1.55941L4.40046 5.18112C4.20098 5.39827 3.85721 5.39828 3.64005 5.19951L3.62166 5.18112L0.145009 1.55941C0.0544669 1.46887 0 1.34226 0 1.19724L3.28122e-05 1.19724Z" fill="#3F4347" />
                                                        </svg>
                                                    </div>
                                                    <DatePicker
                                                        className="form-control"
                                                        placeholderText={"Select Date"}
                                                        dateFormat="MM/dd/yyyy"
                                                        autoComplete="off"
                                                        selected={startDate}
                                                        minDate={minDate}
                                                        maxDate={maxDate}
                                                        onChange={(date) => this.handleDateChange(date)}
                                                    />
                                                </div>
                                            </Form.Group>
                                        </div>

                                        <div className="filter-section d-flex">
                                            <Form.Group controlId="search" >
                                                <Form.Label>Search</Form.Label>
                                                <div className="registerSearch search-inpute">
                                                    <input
                                                        type="text"
                                                        value={searchText}
                                                        onChange={(e) => this.onSearchChange(e.target.value)}
                                                        className="search-box form-control"
                                                        placeholder="Search..."
                                                    />
                                                </div>
                                            </Form.Group>
                                            {(tenantsList && tenantsList.length > 1 &&
                                                <Form.Group controlId="type" >
                                                    <Form.Label>Tenant</Form.Label>
                                                    <DropDownList
                                                        defaultTextColor={true}
                                                        placeHolder={"Search Tenant"}
                                                        data={tenantsList}
                                                        isSearchRequired={true}
                                                        label={"tenantname"}
                                                        value={"tenantid"}
                                                        onDropDownChange={(item) => {
                                                            if (!item) {
                                                                return;
                                                            }
                                                            this.onTenantChange(
                                                                Utils.BuildCustomEventObject(
                                                                    "tenant",
                                                                    item.tenantid
                                                                ),
                                                                1
                                                            );
                                                        }}
                                                        selectedItem={[
                                                            ...[{ tenantid: 0, tenantname: "Select Tenant" }],
                                                            ...tenantsList,
                                                        ].find((r) => +r.tenantid === tenant)}
                                                    />
                                                </Form.Group>
                                            )}
                                            {(hotelList && hotelList.length > 1 &&
                                                <Form.Group controlId="type" >
                                                    <Form.Label>EHID</Form.Label>
                                                    {(hotelList && hotelList.length > 1 && <DropDownList
                                                        defaultTextColor={true}
                                                        placeHolder={"Search EHID"}
                                                        data={hotelList}
                                                        isSearchRequired={true}
                                                        label={"lettercode"}
                                                        value={"hotelid"}
                                                        onDropDownChange={(item) => {
                                                            if (!item) {
                                                                return;
                                                            }
                                                            this.onEhidChange(
                                                                Utils.BuildCustomEventObject(
                                                                    item.hotelid,
                                                                    item.lettercode
                                                                ),
                                                                1
                                                            );
                                                        }}
                                                        selectedItem={[
                                                            ...[{ hotelid: 0, lettercode: "Select EHID" }],
                                                            ...hotelList,
                                                        ].find((r) => +r.hotelid === hotelid)}
                                                    />
                                                    )}
                                                </Form.Group>
                                            )}
                                            {(pmsList && pmsList.length > 1 &&
                                                <Form.Group controlId="type" >
                                                    <Form.Label>PMS Type</Form.Label>
                                                    <DropDownList
                                                        defaultTextColor={true}
                                                        placeHolder={"Search PMS"}
                                                        data={pmsList}
                                                        isSearchRequired={true}
                                                        label={"daAutoImport"}
                                                        value={"hotelid"}
                                                        onDropDownChange={(item) => {
                                                            if (!item) {
                                                                return;
                                                            }
                                                            this.onPmsChange(
                                                                Utils.BuildCustomEventObject(
                                                                    "daAutoImport",
                                                                    item.daAutoImport
                                                                ),
                                                                1
                                                            );
                                                        }}
                                                        selectedItem={[
                                                            ...[{ hotelid: 0, daAutoImport: "Select PMS" }],
                                                            ...pmsList,
                                                        ].find((r) => +r.hotelid === pmsid)}
                                                    />
                                                </Form.Group>
                                            )}
                                        </div>
                                    </div>
                                )}
                                {this.state.isLoader ? (
                                    <ReactPageLoader useas={"pmsMonitoring"} />
                                ) : (
                                    <div>
                                        <BootstrapTable
                                            rowClasses={this.rowClasses}
                                            keyField='id'
                                            data={filteredpmsMonitoringData}
                                            columns={columns}
                                            noDataIndication="No matching record found"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </Container>
        )
    }
}