import React, { useState, useRef, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import {BsThreeDots } from "react-icons/bs"
interface DropdownItem {
  label: string;
  description: string;
  disabled?: boolean;
}

interface DropDownListProps {
  data: DropdownItem[]; // List of items to display
  placeholder?: string; // Placeholder text for the dropdown
  onItemSelected: (item: DropdownItem) => void; // Callback when an item is selected
  onCreateDashboard : () => void
}

export const DashboardsListDropdown: React.FC<DropDownListProps> = ({
  data,
  placeholder = "Select an option",
  onItemSelected,
  onCreateDashboard
}) => {
  const [selectedItem, setSelectedItem] = useState<DropdownItem | null>(null);
  const [filterData, setFilterData] = useState(data);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  // Handle dropdown search/filter
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    const filteredItems = data.filter((item) =>
      item.label.toLowerCase().includes(value)
    );
    setFilterData(filteredItems);
    setInputValue(e.target.value);
  };

  // Handle selection of an item
  const handleItemSelect = (item: DropdownItem) => {
    setSelectedItem(item);
    onItemSelected(item);
    setInputValue(""); // Reset search box
    setFilterData(data); // Reset filter
  };

  return (
    <Dropdown>
      <Dropdown.Toggle
        id="dropdown-custom-components"
        className="dropdown-toggle"
      >
        <EllipsisWithTooltip placement="top">
          {selectedItem ? selectedItem.label : placeholder}
        </EllipsisWithTooltip>
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu">
      

        {/* Dropdown items */}
        {filterData.length > 0 ? (
          filterData.map((item, index) => (
            <Dropdown.Item
              key={index}
              onClick={() => handleItemSelect(item)}
              className={item.disabled ? "disabled" : ""}
              disabled={item.disabled}
            >
              <div className="d-flex align-items-center">
                <div>
                  <EllipsisWithTooltip placement="top">
                    {item.label}
                  </EllipsisWithTooltip>
                  <div className="item-description">
                    <EllipsisWithTooltip placement="top">
                        {item.description}
                    </EllipsisWithTooltip>
                  </div>
                </div>
                <span className="ml-auto"><BsThreeDots/></span>
              </div>
              
            </Dropdown.Item>
          ))
        ) : (
          <div className="no-results">No results found</div>
        )}

        {/* "Create Dashboard" option */}
        <Dropdown.Item className="create-dashboard" onClick={onCreateDashboard}>
          <span>Create Dashboard</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M13.5 9.75H9.75V13.5C9.75 13.9125 9.4125 14.25 9 14.25C8.5875 14.25 8.25 13.9125 8.25 13.5V9.75H4.5C4.0875 9.75 3.75 9.4125 3.75 9C3.75 8.5875 4.0875 8.25 4.5 8.25H8.25V4.5C8.25 4.0875 8.5875 3.75 9 3.75C9.4125 3.75 9.75 4.0875 9.75 4.5V8.25H13.5C13.9125 8.25 14.25 8.5875 14.25 9C14.25 9.4125 13.9125 9.75 13.5 9.75Z" fill="#2CAF92"/>
          </svg>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DashboardsListDropdown;
