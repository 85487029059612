import * as React from "react";
import { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import Calendar from "react-calendar";
import moment from "moment";
import { toast } from "react-toastify";
import OutsideClickHandler from "react-outside-click-handler";
import { Utils } from "../../../Common/Utilis";
import { StaticArrays } from "../../../Common/StaticArrays";
import { DropDownList } from "../../Reports/common-components/dropdown-list";
import { AddGlobalSettingsAddress } from "./AddGlobalSettingsAddress";
import { IGlobalSettingsRequest } from "../../../Common/Contracts/IGlobalSettings";
import _ from "lodash";
import { EntityManagementService } from "./../../../Common/Services/EntityManagementService";
import Joi from "joi-browser";
import ReactTooltip from "react-tooltip";
import { DwollaSetupModal } from "./DwollaSetupModal";
import { CustomDwolla } from "./Dwolla";
import { DwollaService } from "../../../Common/Services/DwollaService";
import { OtpModal } from "../../../Common/Components/OtpModal";
import { accountService } from "../../../Common/Services/account";
import { debug } from "console";
import { isNumber } from 'util';

const tooltipMessageAdd = "Enabling Digital ACH for a property requires a Special Permission. Please contact your administrator to have this Special Permission assigned to you.";
const DEFAULT_TAXLABELMAPPER={
  "Occupancy Tax": "Occupancy Tax",
  "Food & Beverage Tax": "Food & Beverage Tax",
  "Tax 4": "Tax 4",
  "Tax 5": "Tax 5",
}
export class GlobalSettings extends React.Component<any, any> {
  private globalSettingsFormSchema = Joi.object({
    startingDate: Joi.string().allow(""),
    type: Joi.number().min(0),
    pname: Joi.string().trim().min(1).max(70)
      .required()
      .error((errors) => {
        const _errors = errors.map((error) => {
          if (error?.message) {
            return;
          }
          switch (error.type) {
            case "string.base":
              return {
                message: "Entity Name cannot be blank.",
              };
            case "any.empty":
              return {
                message: "Entity Name cannot be blank.",
              };
            case "string.max":
              return {
                message: "Entity Name can not exceed 70 characters.",
              };
          }
        });
        return [_errors[0]];
      }),
      hotelNameAbbr: Joi.string().trim()
      .required()
      .error((errors) => {
        const _errors = errors.map((error) => {
          if (error?.message) {
            return;
          }
          switch (error.type) {
            case "string.base":
              return {
                message: "Abbreviated Hotel Name cannot be blank.",
              };
            case "any.empty":
              return {
                message: "Abbreviated Hotel Name cannot be blank.",
              };
          }
        });
        return [_errors[0]];
      }),
    lettercode: Joi.string()
      .required().trim().min(1)
      .max(5)
      .error((errors) => {
        const _errors = errors.map((error) => {
          if (error?.message) {
            return;
          }
          switch (error.type) {
            case "string.base":
              return {
                message: "Entity ID cannot be blank.",
              };
            case "any.empty":
              return {
                message: "Entity ID cannot be blank.",
              };
            case "string.max":
              return {
                message: "Entity ID can not exceed 5 characters.",
              };
          }
        });
        return [_errors[0]];
      }),
    taxID: Joi.string()
      .required()
      .min(10)
      .max(20)
      .regex(/^\(?\d{2}-\)?\d{7}$/i, "taxID")
      .error((errors) => {
        const _errors = errors.map((error) => {
          if (error?.message) {
            return;
          }
          switch (error.type) {
            case "string.base":
              return { message: "Tax ID cannot be blank." };
            case "any.empty":
              return { message: "Tax ID cannot be blank." };
            case "string.min":
              return {
                message:
                  "Incorrect Tax ID format. Format should include nine numbers and one hyphen , for example 12-3457689.",
              };
              case "string.max":
                return {
                  message:
                    "Incorrect Tax ID format. Format should include nine numbers and one hyphen , for example 12-3457689.",
                };

            case "string.regex.name":
              return {
                message:
                  "Incorrect Tax ID format. Format should include nine numbers and one hyphen , for example 12-3457689.",
              };
          }
        });

        return [_errors[0]];
      }),
    strCode: Joi.string()
      .required().max(15).trim()
      .error((errors) => {
        const _errors = errors.map((error) => {
          if (error?.message) {
            return;
          }
          switch (error.type) {
            case "string.base":
              return {
                message: "Chain ID cannot be blank.",
              };
            case "any.empty":
              return {
                message: "Chain ID cannot be blank.",
              };
            case "string.max":
              return {
                message: "Chain ID can not exceed 15 characters.",
              };
          }
        });
        return [_errors[0]];
      }),
    legalName: Joi.string()
      .max(70)
      .required()
      .error((errors) => {
        const _errors = errors.map((error) => {
          if (error?.message) {
            return;
          }
          switch (error.type) {
            case "string.base":
              return {
                message: "Legal Name cannot be blank.",
              };
            case "any.empty":
              return {
                message: "Legal Name cannot be blank.",
              };
            case "string.max":
              return {
                message: "Legal Name can not exceed 70 characters.",
              };
          }
        });
        return [_errors[0]];
      }),
    phone: Joi.string()
      .optional()
      .allow("")
      .regex(
        /^(\+\d{1,3}\s?)?1?\-?\.?\s?\(?\d{2,3}\)?[\s.-]?\d{3}[\s.-]?\d{4,5}$/,
        "phone1"
      )
      .error((errors) => {
        const _errors = errors.map((error) => {
          return { message: "Phone number is not valid" };
        });
        return [_errors[0]];
      }),
    address1: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please Enter Address." };
        });
      }),
    tripAdvisorLink: Joi.string()
      .allow("")
      .max(5000)
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Trip Advisor Link can not exceed 70 characters." };
        });
      }),
    reservesType: Joi.number()
      .min(1)
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select Reserve Type." };
        });
      }),
    reserves: Joi.number().allow(0).allow("").allow(null),
    // .error((errors) => {
    //   return errors.map((error) => {
    //     return { message: "Reserve Amount should be greater than 0." };
    //   });
    // }),
    overTime: Joi.number().allow(0),
    startMonth: Joi.string().allow(""),
    doubleOverTime: Joi.number().allow(0),
    holidayRate: Joi.number().allow(0),
    salesTaxRate: Joi.number().allow(0).allow("").allow(null),
    OccTax: Joi.number().allow(0).allow("").allow(null),
    FBTax: Joi.number().allow(0).allow("").allow(null),
    Tax4: Joi.number().allow(0).allow("").allow(null),
    Tax5: Joi.number().allow(0).allow("").allow(null),
    // .required()
    // .error((errors) => {
    //   return errors.map((error) => {
    //     return { message: "Sales Tax should be greater than 0." };
    //   });
    // }),
    taxWithholding: Joi.string().allow(""),
    fax: Joi.string().allow("").max(20)
    .error((errors) => {
      return errors.map((error) => {
        return { message: "Fax no. can not exceed 20 characters." };
      });
    }),
    nonUS: Joi.boolean().default(false),
    country: Joi.string().allow(""),
    state: Joi.string().allow(""),
    postalCode: Joi.string().allow(""),
    province: Joi.string().allow(""),
    city: Joi.string().allow(""),
    astreet: Joi.string().allow(""),
    stateDepartmentLabourId: Joi.string().allow("").max(100).allow(null).max(100).error((errors) => {
      return errors.map((error) => {
        return { message: "State - Department of labor ID can not exceed 100 characters." };
      });
    }),
    stateReveuneId: Joi.string().allow("").allow(null).max(100).error((errors) => {
      return errors.map((error) => {
        return { message: "State - Revenue ID can not exceed 100 characters." };
      });
    }),
    secetaryStateId: Joi.string().allow("").allow(null).max(100).error((errors) => {
      return errors.map((error) => {
        return { message: "Secretary of State ID can not exceed 100 characters." };
      });
    }),
    finCenId: Joi.string().allow("").allow(null).max(100).error((errors) => {
      return errors.map((error) => {
        return { message: "FinCEN ID can not exceed 100 characters." };
      });
    }),
    payrollCompanyCode: Joi.string().allow("").allow(null).max(100).error((errors) => {
      return errors.map((error) => {
        return { message: "Payroll Company Code can not exceed 100 characters." };
      });
    }),
  });


  constructor(props: any) {
    super(props);
    this.state = {
      prevGlobalSettings: {
        startingDate:
          (this.props?.globalSettings?.startingDate
            ? moment(this.props?.globalSettings?.startingDate).format(
              "MM/DD/YY"
            )
            : this.props?.globalSettings?.startingDate) || "",
        type: this.props?.globalSettings?.type || "",
        pname: this.props?.globalSettings?.pname || "",
        hotelNameAbbr:this.props?.globalSettings?.hotelNameAbbr?.trim() || "",
        lettercode: this.props?.globalSettings?.lettercode || "",
        taxID: this.props?.globalSettings?.taxID || "",
        strCode: this.props?.globalSettings?.strCode || "",
        legalName: this.props?.globalSettings?.legalName || "",
        phone: this.props?.globalSettings?.phone || "",
        address1: this.props?.globalSettings?.address1 || "",
        tripAdvisorLink: this.props?.globalSettings?.tripAdvisorLink || "",
        reservesType: this.props?.globalSettings?.reservesType || 1,
        reserves: isNumber(this.props?.globalSettings?.reserves) ?  Number(this.props?.globalSettings?.reserves).toFixed(2) : "",
        overTime: +this.props?.globalSettings?.overTime || 0,
        startMonth: this.props?.globalSettings?.startMonth,
        doubleOverTime: +this.props?.globalSettings?.doubleOverTime || 0,
        holidayRate: +this.props?.globalSettings?.holidayRate || 0,
        salesTaxRate: isNumber(this.props?.globalSettings?.salesTaxRate) ? Number(this.props?.globalSettings?.salesTaxRate).toFixed(2) : "",
        OccTax: isNumber(this.props?.globalSettings?.occTax) ? Number(this.props?.globalSettings?.occTax).toFixed(2) : null,
        FBTax: isNumber(this.props?.globalSettings?.fbTax) ? Number(this.props?.globalSettings?.fbTax).toFixed(2) : null,
        Tax4: isNumber(this.props?.globalSettings?.tax4) ? Number(this.props?.globalSettings?.tax4).toFixed(2) : null,
        Tax5: isNumber(this.props?.globalSettings?.tax5) ? Number(this.props?.globalSettings?.tax5).toFixed(2) : null,
        taxWithholding: this.props?.globalSettings?.taxWithholding || "",
        fax: this.props?.globalSettings?.fax || "",
        nonUS: this.props?.globalSettings?.nonUS === "Yes" ? true : false,
        country: this.props?.globalSettings?.country || "",
        state: this.props?.globalSettings?.state || "",
        postalCode: this.props?.globalSettings?.postalCode || "",
        province: this.props?.globalSettings?.province || "",
        city: this.props?.globalSettings?.city || "",
        astreet: this.props?.globalSettings?.astreet || "",
        stateDepartmentLabourId: this.props?.globalSettings?.stateDepartmentLabourId || "",
        stateReveuneId:  this.props?.globalSettings?.stateReveuneId || "",
        secetaryStateId:  this.props?.globalSettings?.secetaryStateId || "",
        finCenId: this.props?.globalSettings?.finCenId || "",
        payrollCompanyCode: this.props?.globalSettings?.payrollCompanyCode || "",
      },
      globalSettings: {
        startingDate:
          (this.props?.globalSettings?.startingDate
            ? moment(this.props?.globalSettings?.startingDate).format(
              "MM/DD/YY"
            )
            : this.props?.globalSettings?.startingDate) || "",
        type: this.props?.globalSettings?.type || "",
        pname: this.props?.globalSettings?.pname || "",
        hotelNameAbbr:this.props?.globalSettings?.hotelNameAbbr?.trim() || "",
        lettercode: this.props?.globalSettings?.lettercode || "",
        taxID: this.props?.globalSettings?.taxID || "",
        strCode: this.props?.globalSettings?.strCode || "",
        legalName: this.props?.globalSettings?.legalName || "",
        phone: this.props?.globalSettings?.phone || "",
        address1: this.props?.globalSettings?.address1 || "",
        tripAdvisorLink: this.props?.globalSettings?.tripAdvisorLink || "",
        reservesType: this.props?.globalSettings?.reservesType || 1,
         reserves: isNumber(this.props?.globalSettings?.reserves) ?  Number(this.props?.globalSettings?.reserves).toFixed(2) : "",
        overTime: +this.props?.globalSettings?.overTime || 0,
        startMonth: this.props?.globalSettings?.startMonth,
        doubleOverTime: +this.props?.globalSettings?.doubleOverTime || 0,
        holidayRate: +this.props?.globalSettings?.holidayRate || 0,
        salesTaxRate: isNumber(this.props?.globalSettings?.salesTaxRate) ? Number(this.props?.globalSettings?.salesTaxRate).toFixed(2) : "",
        OccTax: isNumber(this.props?.globalSettings?.occTax) ? Number(this.props?.globalSettings?.occTax).toFixed(2) : null,
        FBTax: isNumber(this.props?.globalSettings?.fbTax) ? Number(this.props?.globalSettings?.fbTax).toFixed(2) : null,
        Tax4: isNumber(this.props?.globalSettings?.tax4) ? Number(this.props?.globalSettings?.tax4).toFixed(2) : null,
        Tax5: isNumber(this.props?.globalSettings?.tax5) ? Number(this.props?.globalSettings?.tax5).toFixed(2) : null,
        taxWithholding: this.props?.globalSettings?.taxWithholding || "",
        fax: this.props?.globalSettings?.fax || "",
        nonUS: this.props?.globalSettings?.nonUS === "Yes" ? true : false,
        country: this.props?.globalSettings?.country || "",
        state: this.props?.globalSettings?.state || "",
        postalCode: this.props?.globalSettings?.postalCode || "",
        province: this.props?.globalSettings?.province || "",
        city: this.props?.globalSettings?.city || "",
        astreet: this.props?.globalSettings?.astreet || "",
        stateDepartmentLabourId: this.props?.globalSettings?.stateDepartmentLabourId || "",
        stateReveuneId:  this.props?.globalSettings?.stateReveuneId || "",
        secetaryStateId:  this.props?.globalSettings?.secetaryStateId || "",
        finCenId: this.props?.globalSettings?.finCenId || "",
        payrollCompanyCode: this.props?.globalSettings?.payrollCompanyCode || "",
      },
      calenderStartDateShow: false,
      showPayrollAddressModal: false,
      isFormValid: false,
      isDataSubmitting: false,
      isGlobalSettingNotChanged: true,
      isGroupMappingModified: false,
      prevEntityGroupsData: [],
      entityGroupsData: [],
      globalSettingErrors: {},
      inputPosition: 1000,
      showDwollSetUpModal: false,
      isOpenDwolla: false,
      isselectedheckAgree: false,
      showselectedheckAgree: false,
      ModalName: "",
      documentType: "document",
      isOtpNeed: false,
      isDisableDCH: false,
      taxLabelMapper:DEFAULT_TAXLABELMAPPER
    };
  }

  openOtpModal = (isDisableDCH, ModalName, Purpose) => {
    this.setState({ isOtpNeed: true, isDisableDCH, ModalName, Purpose }, () => {
      this.ReSendOTP();
    });
  }

  setupAgainopenOtpModal = (isDisableDCH, ModalName, Purpose) => {
      this.setState({ isOtpNeed: true,callResetupApi:true, isDisableDCH, ModalName, Purpose }, () => {
        this.ReSendOTP();
      });
  }
  
  closeOtpModal = () => {
    if (this.state.isOtpNeed) {
      this.setState({ isOtpNeed: false });
    }
  }

  ValidateOTP = (OTPCode) => {
    this.setState({ otpSuccess: true, isOtpNeed: false }, () => {
      if (this.state.isDisableDCH) {
        this.disableACH();
      } else {
        if(this.state.callResetupApi){
          let request = {} as any;
          request.hid = this.props.hotelId;
          request.DwollaCustId = this.props.dwollaCustomersDetail.dwollaCustId;
          DwollaService.DwollaResetup(request).then((newresult)=>{
          });
        }
        this.openDwollSetUpEntryPanel();
      }
    })
  }

  ReSendOTP = () => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    // debugger
    let userName = storage === null ? 0 : (storage.userName as any);
    let request: any = {};
    request.userName = userName;
    request.purpose = this.state.Purpose;
    request.otpCode = "";
    request.primaryContact = "Phone";
    accountService.OTPForAuthentication(request)
      .then(async (result: any | null) => {
        if (result !== null) {
          if (result.status === "Success") {
            
            toast.success(result.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "userSlideOut",
            });

            this.setState({ isOtpNeed: true });
          }
          else {
            toast.success('Something went wrong', {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "userSlideOut",
            });
          }

        }
      });
  }

  getTaxLabelsMappping = ()=>{
    const {taxLabels}= this.props
    if(taxLabels && taxLabels.length > 0){

     let newLabels=Object.assign({},...taxLabels.filter(item => Object.keys(DEFAULT_TAXLABELMAPPER).includes(item.auditName)
    ).map(item => { return {[item?.auditName]:item?.displayName || item?.auditName}}))

   
    this.setState({taxLabelMapper:{...DEFAULT_TAXLABELMAPPER,...newLabels}})  
    }
    else{
    this.setState({taxLabelMapper:{...DEFAULT_TAXLABELMAPPER}})  
    }
  }
  componentDidMount() {
    this.getHotelGroupMapping();
    this.getTaxLabelsMappping()
    this.validationOnGlobalSettingsClick(1000)
  }
  hideDwollasetupModal = (isOpenDwolla: boolean = false) => {
    this.setState({ showDwollSetUpModal: false, isOpenDwolla });

  };


  hideCustomDwollaModal = () => {
    this.setState({ isOpenDwolla: false }, () => {
      this.props.getHotelModules();
    });
  };


  isCheckAgreebox = (isselectedheckAgree: false) => {
    this.setState({ showselectedheckAgree: false, isselectedheckAgree });
  };

  openDwollSetUpEntryPanel = () => {
    let documentType = "document";
    this.setState({ showunverifedconfirmation: false });
    if (this.state.ModalName == "dwolla-beneficial-owners") {
      documentType = "boddocument";
    }
    this.setState({ documentType });
    if (this.props?.dwollaCustomersDetail?.dwollaCustId) {
      this.hideDwollasetupModal(true);
    } else {
      this.setState({ showDwollSetUpModal: true });
    }
  };

  getHotelGroupMapping = () => {
    EntityManagementService.GetHotelGroupMapping().then(
      (_entityGroupsData: any) => {
        let entityGroupsData: any[] = _entityGroupsData?.result;
        entityGroupsData = (entityGroupsData || [])?.map((item: any) => {
          let groupId = 0;
          if ((item?.groupWithHotels || [])?.length > 0) {
            (item?.groupWithHotels || [])?.forEach((groupItem) => {
              if (
                (groupItem?.hotels || [])
                  ?.map((x) => +x?.hotelID)
                  ?.includes(this.props?.hotelId)
              ) {
                groupId = groupItem?.rGoupId;
              }
            });
          }
          item.selectedGroupId = groupId;
          return item;
        });
        // debugger;
        this.setState({ entityGroupsData, prevEntityGroupsData: _.cloneDeep(entityGroupsData) });
        // if (+this.state.selected[0]) {
        //   const rightTableData:any[]= entityGroupsData?.result?.find(x => +x?.categoryId === +this.state.selected[0])?.groupWithHotels || [] ;
        //   this.setState({ rightTableData });
        //   if (+this.state.entityGroupsTableExpanded[0]) {
        //     this.setState({ rightTableChildData: rightTableData?.find(x => +x?.rGoupId === +this.state.entityGroupsTableExpanded[0])?.hotels || [] });
        //   }
        // }
      }
    );
  };


  updateEntityGroupsDataState = (index, groupId) => {
    const entityGroupsData = [...this.state?.entityGroupsData];
    entityGroupsData[index].selectedGroupId = groupId;
    if (entityGroupsData?.filter(x => +x?.selectedGroupId > 0)?.length > 0) {
      this.setState({ isGroupMappingModified: true },()=>this.updateFormModificationStatus());
    }
    this.setState({ entityGroupsData });
  }

  validationOnGlobalSettingsClick = (inputPosition) => {
    this.setState({ inputPosition:1000 });
    this.validateGlobalSettingsFormSchema();
  };

  getHotelTypeName = (typeValue) => {
    switch (typeValue) {
      case 1:
        return "Enterprise";
        break;
      case 2:
        return "Hotel";
        break;
      case 3:
        return "Non Hotel";
        break;

      default:
        return "";
        break;
    }
  };

  checkFormModified(isLoadedFromApi = false) {
    const { globalSettings, prevGlobalSettings } = this.state;
    let _isGlobalSettingNotChanged = _.isEqual(
      globalSettings,
      prevGlobalSettings
    );
    this.setState({ isGlobalSettingNotChanged: _isGlobalSettingNotChanged },()=>
    this.updateFormModificationStatus());
  }

  handleDiscard = () => {
    this.setState({ entityGroupsData: [] }, () =>
      this.setState({ entityGroupsData: _.cloneDeep(this.state?.prevEntityGroupsData) })
    );
    this.setState({
      globalSettings: { ...this.state.prevGlobalSettings },
      isGlobalSettingNotChanged: true,
      isFormValid: false,
      isGroupMappingModified: false,
    },()=>this.updateFormModificationStatus());
  };

  validateGlobalSettingsFormSchema = () => {
    const valid = Joi.validate(
      this.state.globalSettings,
      this.globalSettingsFormSchema,
      {
        abortEarly: false,
      }
    );
    const newErrorObject: any = {};
    if (valid.error) {
      valid.error.details.forEach((err) => {
        newErrorObject[err.path.join(".")] = err.message;
      });
    }
    if (newErrorObject?.nonUS) {
      delete newErrorObject?.nonUS;
    }
    this.setState({ globalSettingErrors: newErrorObject });
    if (Object.keys(newErrorObject).length === 0) {
      this.setState({ isFormValid: true },()=>this.updateFormModificationStatus());
    } else {
      this.setState({ isFormValid: false },()=>this.updateFormModificationStatus());
    }
    return newErrorObject;
  };

  calenderTerminationDateHide = () => {
    this.setState({ calenderStartDateShow: false });
  };

  calenderTerminationDateDisplay = () => {
    this.setState({ calenderStartDateShow: true });
  };

  openAddressEntryPanel = () => {
    this.setState({ inputPosition: 1000 });
    this.setState({ showPayrollAddressModal: true });
  };
  hidePayrollAddressModal = (isReloadRequired: false) => {
    this.setState({ showPayrollAddressModal: false });
  };

  getFieldError = (fieldName: string, fieldPosition: number) => {
    const { globalSettingErrors, inputPosition } = this.state;
    return (
      <>
        {Object.keys(globalSettingErrors).length > 0 && (
            <span className="validation-message">
              {globalSettingErrors[fieldName]}
            </span>
          )}
      </>
    );
  };


  onConvertToDemial =(event)=>{
    const { globalSettings ,prevGlobalSettings} = this.state;

    let value = event.target.value;
    const fieldName = event.target.name;
    globalSettings[fieldName] = value ? Utils.convertNumberToTwoPlacesDecimal(value || 0) :null;


  }

  handleTrimEnd =(event,inputPosition)=>{
    const { globalSettings ,prevGlobalSettings} = this.state;
    const _globalSettings={...globalSettings}
    let value = event.target.value;
    const fieldName = event.target.name;

    if(fieldName === "hotelNameAbbr")
    {
      value=value?.toString()?.trim()
    }
    _globalSettings[fieldName] = value == "" ? null : value;

    this.setState({ globalSettings:{..._globalSettings}})
  }
  onGlobalSettingFieldChange = (event, inputPosition, isBlur = false) => {
    const { globalSettings ,prevGlobalSettings} = this.state;
    const _globalSettings={...globalSettings}
    let value = event.target.value;
    const fieldName = event.target.name;
    // if (fieldName === "reserves") {
    //   globalSettings[fieldName] =value>=0?  Number(value).toFixed(2) : value;
    // } else {

    // globalSettings[fieldName] = value;
  //  }
    if (value < 0) {
      return;
    }
  


    if (
      (fieldName === "salesTaxRate" || fieldName === "reserves") &&
     ( value?.toString()?.split(".")[0]?.length > 10  || value?.toString()?.split(".")[1]?.length > 2)
    ) {
      return;
    }

    const taxes=["OccTax","FBTax","Tax4","Tax5"];
    if (
      taxes.includes(fieldName) &&
      ( value?.toString()?.split(".")[0]?.length > 10  || value?.toString()?.split(".")[1]?.length > 2)
    ) {
      return;
    }

    if (fieldName === "reservesType") {
      if(prevGlobalSettings.reservesType === _globalSettings?.reservesType) {
        _globalSettings.reserves = _globalSettings?.reserves ? _globalSettings?.reserves :   ("0.00" || prevGlobalSettings?.reserves);
      } else {
        _globalSettings.reserves ="0.00"; 
      }
    }


    if(fieldName === "hotelNameAbbr")
    {
      value=value?.toString()?.trimLeft()
    }



    if (fieldName === "phone") {
      let validValue = value?.toString()?.replace(/[^0-9\-\+]+/gi, "");
      if (validValue) {
        if (validValue.indexOf("-") === 0) {
          validValue = validValue?.slice(0, 0);
        }
      }
      _globalSettings[fieldName] = validValue;
    }else{
      _globalSettings[fieldName] = value == "" ? null : value;
    }

   
    if (this.state?.isGroupMappingModified) {
      this.setState({ isGroupMappingModified: false },()=>this.updateFormModificationStatus());
    }
    this.setState({ globalSettings:{..._globalSettings}, inputPosition:1000 }, () => {
      this.validateGlobalSettingsFormSchema();
      this.checkFormModified();
    });
  };

  buildAddress = (data: any) => {
    return data.astreet
      ? `${data.astreet} ${data.city} ${data.state || data.province} ${data.postalCode} ${data.country}`
      : "";
  };


  reAttachHotelAddressToMainObject = (data) => {
    const { globalSettings } = this.state;
    globalSettings.astreet = data.astreet;
    globalSettings.city = data.city;
    globalSettings.state = data.state;
    globalSettings.province = data.province;
    globalSettings.postalCode = data.postalCode;
    globalSettings.country = data.country;
    globalSettings.nonUS = data?.nonUS;
    globalSettings.address1 = this.buildAddress(data);

    this.setState({ globalSettings }, () => {
      this.validateGlobalSettingsFormSchema();
      this.checkFormModified();
    });
  };
  updateHotelGlobalSetting = () => {

    if (this.state?.isGroupMappingModified) {
      this.saveGroupMappingData();
      return;
    }

    const request: IGlobalSettingsRequest = { ...this.state.globalSettings };
    request.hotelID = this.props?.hotelId;
    request.nonUS = this.state.globalSettings?.nonUS ? "Yes" : "No";
    request.StartMonth = this.state.globalSettings?.startMonth;
    this.setState({ isDataSubmitting: true });
    EntityManagementService.UpdateHotelGlobalSetting(request)
      .then((result) => {
        if (result?.message === "Success") {
          this.props?.isReloadRequired(true);
          this.setState({
            prevGlobalSettings: { ...this.state.globalSettings },
          });
          this.setState({ isGlobalSettingNotChanged: true,isGroupMappingModified:false },()=> {
            this.updateFormModificationStatus();
          });
          this.props?.getHotelModules();
          toast.success("Global Settings updated successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "userSlideOut",
          });
       
        } else {
          Utils.toastError(result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "userSlideOut",
          });
        }
      })
      .catch((error) => {
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      })
      .finally(() => {
        this.setState({ isDataSubmitting: false });
      });
  };

  saveGroupMappingData = () => {
    const { entityGroupsData } = this.state;
    const postData = (entityGroupsData || []).map(x => {
      const item: any = {};
      item.categoryId = x?.categoryId;
      item.groupWithHotels = (x?.groupWithHotels || [])?.map(dataItem =>
      ({
        rGoupId: dataItem?.rGoupId || 0,
        groupName: dataItem.groupName,
        hids: (dataItem?.rGoupId === x?.selectedGroupId ? _.uniq([this.props?.hotelId, ...(dataItem?.hotels || []).map(x => x.hotelID)]) : (dataItem?.hotels || []).map(x => x.hotelID)?.filter(x=> +x !== +this.props?.hotelId) || [])?.filter(x => +x > 0)
      }));
      return item;
    });

    this.setState({isDataSubmitting: true});
    EntityManagementService.SaveCategoriesGroupWithHotel(postData)
      .then((result) => {
        this.getHotelGroupMapping();
        if (result?.message === "Success") {
          this.props?.isReloadRequired(true);
          toast.success("Data saved successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addGarnishment",
          });
          this.setState({ isGroupMappingModified: false },()=>this.updateFormModificationStatus());

        } else {
          Utils.toastError(result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addGarnishment",
          });
        }
      })
      .catch((error) => {
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "addGarnishment",
        });
      }).finally(()=> {
        this.setState({isDataSubmitting: false});
      });
  }

  onSuspendedClick = () => {
    alert("Account suspended by Dwolla. Please contact your Inn-Flow administrator for more information.");
  }

  disableACH = () => {
    const { dwollaCustomersDetail } = this.props;
    let request = {} as any;
    request.DwollaCustId = dwollaCustomersDetail.dwollaCustId;
    request.DwollaCustStatus = dwollaCustomersDetail.customerstatus;
    request.HID = this.props.hotelId;

    DwollaService.DisableACH(request).then((result) => {
      if (result.message == "FundingSourceexists") {
        toast.success("Account cannot be disabled,Please deactivate associated bank account.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

      } else if (result.message == "NoFundingSource") {
        toast.success("Account has been successfully disabled", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
      this.hideDwollasetupModal();
      this.props.getHotelModules();
    }).catch((error) => {
      Utils.toastError(error?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    })
      .finally(() => {
        this.setState({ isDataSubmitting: false });
      });;

  }

   updateFormModificationStatus = ()=> {
     const {isFormValid ,isGlobalSettingNotChanged,isGroupMappingModified} =this.state;

    if((  isFormValid && isGlobalSettingNotChanged === false) || isGroupMappingModified) {
this.props?.getGeneralTabModificationStatus(true);
    } else {
      this.props?.getGeneralTabModificationStatus(false);

    }
   }


  render() {
    const {
      globalSettings: {
        startingDate,
        type,
        pname,
        hotelNameAbbr,
        lettercode,
        taxID,
        strCode,
        legalName,
        phone,
        nonUS,
        address1,
        tripAdvisorLink,
        reservesType,
        reserves,
        overTime,
        startMonth,
        doubleOverTime,
        holidayRate,
        salesTaxRate,
        OccTax,
        FBTax,
        Tax4,
        Tax5,
        taxWithholding,
        fax,
        stateDepartmentLabourId,
        stateReveuneId,
        secetaryStateId,
        finCenId,
        payrollCompanyCode
      },
      globalSettingErrors,
      showPayrollAddressModal,
      inputPosition,
      calenderStartDateShow,
      isFormValid,
      isGlobalSettingNotChanged,
      isDataSubmitting,
      entityGroupsData,
      isGroupMappingModified,
      showDwollSetUpModal,
      isOpenDwolla,
      isselectedheckAgree,
      showselectedheckAgree,
      taxLabelMapper
    } = this.state;
    const { dwollaCustomersDetail, dwollaCustomerDocumentDetail, docstatus, boverificationStatus, digitalACHEnabled, hasAddDigitalACH } = this.props;
    //let hasAddDigitalACH = false;
    return (
      <div className="general-tab general-tab-content">
        <div className="body-section">
          <div className="heading">General</div>
          <div className="form-section d-flex flex-column">
            <div>
              <Form.Group
                onBlur={() => this.validationOnGlobalSettingsClick(1)}
                controlId="date-selection"
                className={
                  globalSettingErrors?.startingDate && inputPosition >= 1
                    ? "validation-error d-flex flex-row date-picker"
                    : "d-flex flex-row date-picker"
                }
              >
                <Form.Label>Start Date</Form.Label>

                <div className="validate-input d-flex flex-column">
                  {/* <div className="calender-container">
                    <div
                      className={
                        calenderStartDateShow
                          ? "calender-display open"
                          : "calender-display"
                      }
                      //   onClick={this.calenderTerminationDateDisplay}
                    > */}
                  <input
                  tabIndex={1}
                    type="text"
                    placeholder="MM/DD/YY"
                    name="startingDate"
                    autoComplete="off"
                    value={startingDate}
                    disabled={true}
                    className="form-control sos1"
                  />
                  {/* <svg
                               
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z" />
                                <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z" />
                              </svg> */}
                  {/* </div> */}
                  {/* {calenderStartDateShow && (
                      <div className="calender">
                        <OutsideClickHandler
                          onOutsideClick={this.calenderTerminationDateHide}
                        >
                          <Calendar
                            calendarType={"US"}
                            maxDate={new Date()}
                            onChange={(date) => {
                              const value = moment(date).format("MM/DD/YY");
                              this.onGlobalSettingFieldChange(
                                Utils.BuildCustomEventObject(
                                  "startingDate",
                                  value
                                ),
                                8
                              );
                              this.calenderTerminationDateHide();
                            }}
                            value={
                              startingDate ? new Date(startingDate) : new Date()
                            }
                          />
                        </OutsideClickHandler>
                      </div>
                    )}
                  </div> */}

                  {this.getFieldError("startingDate", 1)}
                </div>
              </Form.Group>

              <Form.Group
                onBlur={() => this.validationOnGlobalSettingsClick(2)}
                controlId="date-selection"
                className={
                  globalSettingErrors?.type && inputPosition >= 2
                    ? "validation-error d-flex flex-row"
                    : "d-flex flex-row"
                }
              >
                <Form.Label>Type</Form.Label>
                <div className="validate-input d-flex flex-column">
                  <input
                  tabIndex={2}
                    type="text"
                    className="form-control"
                    id="txtType"
                    name="type"
                    autoComplete="off"
                    value={this.getHotelTypeName(+type)}
                    disabled={true}
                  />
                  {this.getFieldError("type", 2)}
                </div>
              </Form.Group>
              <Form.Group
                onBlur={() => this.validationOnGlobalSettingsClick(3)}
                controlId="date-selection"
                className={
                  globalSettingErrors?.pname && inputPosition >= 3
                    ? "validation-error d-flex flex-row"
                    : "d-flex flex-row"
                }
              >
                <Form.Label>Entity Name</Form.Label>
                <div className="validate-input d-flex flex-column show-ellipsis">
                  {/* <ReactTooltip id="entityName" effect="solid" place="bottom">
                    {pname}
                  </ReactTooltip> */}
                  <OverlayTrigger 
                      placement="bottom"
                      defaultShow={false}
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                          <Tooltip id={"Entity-Name"} className={((pname?.length || 0) <= 24 ) ? "tootltip-hide d-none": ""}>
                              {pname}
                          </Tooltip> 
                      }
                    >
                  <input
                  tabIndex={3}
                    type="text"
                    placeholder="Please Enter Entity Name"
                    // data-tip
                    // data-for="entityName"
                    className="form-control"
                    id="txtAccountName"
                    name="pname"
                    autoComplete="off"
                    value={pname}
                    disabled={!this.props?.hasEditPermission}
                    onChange={(e: any) => this.onGlobalSettingFieldChange(e, 3)}
                  />
                  </OverlayTrigger>
                  {this.getFieldError("pname", 3)}
                </div>
              </Form.Group>
              <Form.Group
                onBlur={() => this.validationOnGlobalSettingsClick(4)}
                controlId="date-selection"
                className={
                  globalSettingErrors?.lettercode && inputPosition >= 4
                    ? "validation-error d-flex flex-row"
                    : "d-flex flex-row"
                }
              >
                <Form.Label>Entity ID</Form.Label>
                <div className="validate-input d-flex flex-column">
                  <input
                  tabIndex={4}
                    type="text"
                    className="form-control"
                    placeholder="Please Enter Entity ID"
                    id="txtlettercode"
                    name="lettercode"
                    autoComplete="off"
                    value={lettercode}
                    disabled={!this.props?.hasEditPermission}
                    onChange={(e: any) => this.onGlobalSettingFieldChange(e, 4)}
                  />
                  {this.getFieldError("lettercode", 4)}
                </div>
              </Form.Group>

              <Form.Group
                onBlur={() => this.validationOnGlobalSettingsClick(5)}
                controlId="date-selection"
                className={
                  globalSettingErrors?.hotelNameAbbr && inputPosition >= 5
                    ? "validation-error d-flex flex-row"
                    : "d-flex flex-row"
                }
              >
                <Form.Label>Abbreviated Hotel Name

                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id={"tooltip-displayName"}>
                        This is the hotel name that displays on all dashboards. Limit to 20 characters.
                      </Tooltip>
                    }
                  >
                    <span style={{ marginLeft: 5, marginTop: 10 }}>
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        version="1.1"
                        viewBox="0 0 16 16"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M7 4.75c0-0.412 0.338-0.75 0.75-0.75h0.5c0.412 0 0.75 0.338 0.75 0.75v0.5c0 0.412-0.338 0.75-0.75 0.75h-0.5c-0.412 0-0.75-0.338-0.75-0.75v-0.5z"></path>
                        <path d="M10 12h-4v-1h1v-3h-1v-1h3v4h1z"></path>
                        <path d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM8 14.5c-3.59 0-6.5-2.91-6.5-6.5s2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5-2.91 6.5-6.5 6.5z"></path>
                      </svg>
                    </span>
                  </OverlayTrigger>
                </Form.Label>
                <div className="validate-input d-flex flex-column show-ellipsis">
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id={"tooltip-txtHotelNameAbbr"} className={((hotelNameAbbr?.length || 0) <= 24) ? "tootltip-hide": ""}>
                      {hotelNameAbbr}
                      </Tooltip>
                    }
                  >
                  <input
                  tabIndex={5}
                    type="text"
                    className="form-control"
                    placeholder="Please Enter Abbr. Hotel Name"
                    id="txtHotelNameAbbr"
                    name="hotelNameAbbr"
                    autoComplete="off"
                    maxLength={20}
                    value={hotelNameAbbr}
                    disabled={!this.props?.hasEditPermission}
                    onBlur={(e)=>this.handleTrimEnd(e, 5)}
                    onChange={(e: any) => this.onGlobalSettingFieldChange(e, 5)}
                  />
                  </OverlayTrigger>
                  {this.getFieldError("hotelNameAbbr", 5)}
                </div>
              </Form.Group>

              <Form.Group
                controlId="date-selection"
                className={
                  globalSettingErrors?.taxID && inputPosition >= 6
                    ? "validation-error d-flex flex-row"
                    : "d-flex flex-row"
                }
              >
                <Form.Label
                  onBlur={() => this.validationOnGlobalSettingsClick(6)}
                >
                  Tax ID
                </Form.Label>
                <div className="validate-input d-flex flex-column">
                  <input
                     tabIndex={6}
                    type="text"
                    placeholder="Please Enter Tax ID"
                    className="form-control"
                    id="txtTaxID"
                    name="taxID"
                    autoComplete="off"
                    value={taxID}
                    disabled={!this.props?.hasEditPermission}
                    onChange={(e: any) => this.onGlobalSettingFieldChange(e, 6)}
                  />
                  {this.getFieldError("taxID", 6)}
                </div>
              </Form.Group>
              <Form.Group
                controlId="date-selection"
                className={
                  globalSettingErrors?.strCode && inputPosition >= 7
                    ? "validation-error d-flex flex-row"
                    : "d-flex flex-row"
                }
              >
                <Form.Label
                  onBlur={() => this.validationOnGlobalSettingsClick(7)}
                >
                  Chain ID
                </Form.Label>
                <div className="validate-input d-flex flex-column">
                  <input
                          tabIndex={7}
                    placeholder="Please Enter Chain ID"
                    type="text"
                    className="form-control"
                    id="txtStrCode"
                    name="strCode"
                    autoComplete="off"
                    value={strCode}
                    disabled={!this.props?.hasEditPermission}
                    onChange={(e: any) => this.onGlobalSettingFieldChange(e, 7)}
                  />
                  {this.getFieldError("strCode", 7)}
                </div>
              </Form.Group>
              <Form.Group
                onBlur={() => this.validationOnGlobalSettingsClick(8)}
                controlId="date-selection"
                className={
                  globalSettingErrors?.legalName && inputPosition >= 8
                    ? "validation-error d-flex flex-row"
                    : "d-flex flex-row"
                }
              >
                <Form.Label>Legal Name</Form.Label>
                <div className="validate-input d-flex flex-column">
                  {/* <ReactTooltip id="legalNameBar" effect="solid" place="bottom">
                    {legalName}
                  </ReactTooltip> */}
                  <input
                          tabIndex={8}
                    type="text"
                    placeholder="Please Enter Legal Name"
                    data-tip
                    data-for="legalNameBar"
                    className="form-control"
                    id="txtLegalName"
                    name="legalName"
                    autoComplete="off"
                    value={legalName}
                    disabled={!this.props?.hasEditPermission}
                    onChange={(e: any) => this.onGlobalSettingFieldChange(e, 8)}
                  />
                  {this.getFieldError("legalName", 8)}
                </div>
              </Form.Group>
              <Form.Group
                onBlur={() => this.validationOnGlobalSettingsClick(9)}
                controlId="date-selection"
                className={
                  globalSettingErrors?.phone && inputPosition >= 9
                    ? "validation-error d-flex flex-row"
                    : "d-flex flex-row"
                }
              >
                <Form.Label>Phone</Form.Label>
                <div className="validate-input d-flex flex-column">
                  <input
                          tabIndex={9}
                    type="text"
                    placeholder="Please Enter Phone"
                    className="form-control"
                    id="txtFirstName"
                    name="phone"
                    autoComplete="off"
                    value={phone}
                    disabled={!this.props?.hasEditPermission}
                    onChange={(e: any) => this.onGlobalSettingFieldChange(e, 9)}
                  />
                  {this.getFieldError("phone", 9)}
                </div>
              </Form.Group>
              <Form.Group
                onBlur={() => this.validationOnGlobalSettingsClick(10)}
                controlId="date-selection"
                className={
                  globalSettingErrors?.address1 && inputPosition >= 10
                    ? "validation-error d-flex flex-row"
                    : "d-flex flex-row"
                }
              >
                <Form.Label>Address</Form.Label>
                <div className="validate-input d-flex flex-column show-ellipsis">
                  {/* <ReactTooltip id="addressBar" effect="solid" place="bottom">
                    {address1}
                  </ReactTooltip> */}
                  <OverlayTrigger 
                      placement="bottom"
                      defaultShow={false}
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                          <Tooltip id={"entity-Name"} className={(address1.length <= 24) ? "tootltip-hide": ""}>
                              {address1}
                          </Tooltip> 
                      }
                    >
                  <input
                          tabIndex={10}
                    type="text"
                    placeholder="Please Enter Address"
                    data-tip
                    data-for="addressBar"
                    className="form-control"
                    id="txtFirstName"
                    name="address1"
                    autoComplete="off"
                    value={address1}
                    disabled={!this.props?.hasEditPermission}
                    onClick={() => this.openAddressEntryPanel()}
                    onKeyPress={() => this.openAddressEntryPanel()}
                  />
                  </OverlayTrigger>
                  {this.getFieldError("address1", 10)}
                </div>
              </Form.Group>
              <Form.Group
                onBlur={() => this.validationOnGlobalSettingsClick(11)}
                controlId="date-selection"
                className={
                  globalSettingErrors?.tripAdvisorLink && inputPosition >= 11
                    ? "validation-error d-flex flex-row"
                    : "d-flex flex-row"
                }
              >
                <Form.Label>Trip Advisor Link</Form.Label>
                <div className="validate-input d-flex flex-column">
                  <textarea
                    rows={3}
                    tabIndex={11}
                    placeholder="Please Enter Trip Advisor Link"
                    className="form-control"
                    id="txttripAdvisorLink"
                    name="tripAdvisorLink"
                    autoComplete="off"
                    value={tripAdvisorLink}
                    disabled={!this.props?.hasEditPermission}
                    onChange={(e: any) =>
                      this.onGlobalSettingFieldChange(e, 11)
                    }
                  />
                  {this.getFieldError("tripAdvisorLink", 11)}
                </div>
              </Form.Group>
              <Form.Group
                controlId="date-selection"
                className={
                  globalSettingErrors?.stateDepartmentLabourId && inputPosition >= 24
                    ? "validation-error d-flex flex-row"
                    : "d-flex flex-row"
                }
              >
                <Form.Label
                  onBlur={() => this.validationOnGlobalSettingsClick(24)}
                >
                  State - Department of labor ID
                </Form.Label>
                <div className="validate-input d-flex flex-column">
                  <input
                    tabIndex={12}
                    type="text"
                    placeholder="Please Enter State-Department of labor ID"
                    className="form-control"
                    id="txtstateDepartmentLabourId"
                    name="stateDepartmentLabourId"
                    autoComplete="off"
                    value={stateDepartmentLabourId}
                    disabled={!this.props?.hasEditPermission}
                    onChange={(e: any) => this.onGlobalSettingFieldChange(e, 24)}
                  />
                  {this.getFieldError("stateDepartmentLabourId", 24)}
                </div>
              </Form.Group>
              <Form.Group
                controlId="date-selection"
                className={
                  globalSettingErrors?.stateReveuneId && inputPosition >= 25
                    ? "validation-error d-flex flex-row"
                    : "d-flex flex-row"
                }
              >
                <Form.Label
                  onBlur={() => this.validationOnGlobalSettingsClick(25)}
                >
                  State - Revenue ID
                </Form.Label>
                <div className="validate-input d-flex flex-column">
                  <input
                     tabIndex={13}
                    type="text"
                    placeholder="Please Enter State - Revenue ID"
                    className="form-control"
                    id="txtSReveuneId"
                    name="stateReveuneId"
                    autoComplete="off"
                    value={stateReveuneId}
                    disabled={!this.props?.hasEditPermission}
                    onChange={(e: any) => this.onGlobalSettingFieldChange(e, 25)}
                  />
                  {this.getFieldError("stateReveuneId", 25)}
                </div>
              </Form.Group>
              <Form.Group
                controlId="date-selection"
                className={
                  globalSettingErrors?.secetaryStateId && inputPosition >= 26
                    ? "validation-error d-flex flex-row"
                    : "d-flex flex-row"
                }
              >
                <Form.Label
                  onBlur={() => this.validationOnGlobalSettingsClick(26)}
                >
                  Secretary of State ID
                </Form.Label>
                <div className="validate-input d-flex flex-column">
                  <input
                     tabIndex={14}
                    type="text"
                    placeholder="Please Enter Secretary of State ID"
                    className="form-control"
                    id="txtSecetaryStateId"
                    name="secetaryStateId"
                    autoComplete="off"
                    value={secetaryStateId}
                    disabled={!this.props?.hasEditPermission}
                    onChange={(e: any) => this.onGlobalSettingFieldChange(e, 26)}
                  />
                  {this.getFieldError("secetaryStateId", 26)}
                </div>
              </Form.Group>
              <Form.Group
                controlId="date-selection"
                className={
                  globalSettingErrors?.finCenId && inputPosition >= 27
                    ? "validation-error d-flex flex-row"
                    : "d-flex flex-row"
                }
              >
                <Form.Label
                  onBlur={() => this.validationOnGlobalSettingsClick(27)}
                >
                  FinCEN ID
                </Form.Label>
                <div className="validate-input d-flex flex-column">
                  <input
                    tabIndex={15}
                    type="text"
                    placeholder="Please Enter FinCEN ID"
                    className="form-control"
                    id="txtFinCenId"
                    name="finCenId"
                    autoComplete="off"
                    value={finCenId}
                    disabled={!this.props?.hasEditPermission}
                    onChange={(e: any) => this.onGlobalSettingFieldChange(e, 27)}
                  />
                  {this.getFieldError("finCenId", 27)}
                </div>
              </Form.Group>
              <Form.Group
                controlId="date-selection"
                className={
                  globalSettingErrors?.payrollCompanyCode && inputPosition >= 27
                    ? "validation-error d-flex flex-row"
                    : "d-flex flex-row"
                }
              >
                <Form.Label
                  onBlur={() => this.validationOnGlobalSettingsClick(27)}
                >
                  Payroll Company Code
                </Form.Label>
                <div className="validate-input d-flex flex-column">
                  <input
                    tabIndex={15}
                    type="text"
                    placeholder="Please Enter Payroll Company Code"
                    className="form-control"
                    id="txtPayrollCompanyCode"
                    name="payrollCompanyCode"
                    autoComplete="off"
                    value={payrollCompanyCode}
                    disabled={!this.props?.hasEditPermission}
                    onChange={(e: any) => this.onGlobalSettingFieldChange(e, 27)}
                  />
                  {this.getFieldError("payrollCompanyCode", 27)}
                </div>
              </Form.Group>
              <Form.Group
                onBlur={() => this.validationOnGlobalSettingsClick(12)}
                controlId="date-selection"
                className={
                  globalSettingErrors?.reservesType && inputPosition >= 12
                    ? "validation-error d-flex flex-row"
                    : "d-flex flex-row"
                }
              >
                <Form.Label>
                  Replacement Reserves {+reservesType === 1 ? "(%)" : ""}
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id={"tooltip-displayName"}>
                        Replacement Reserves are shown as an expense on the Operating Statement (For Operators) Report. It does not represent a transaction.
                        <br />
                        <b>Percentage</b> : Replacement Reserves represent a percentage of total revenue.
                        <br />
                        <b>Fixed Amount</b> : Replacement Reserves represent a fixed amount per month.
                      </Tooltip>
                    }
                  >
                    <span style={{ marginLeft: 5, marginTop: 10 }}>
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        version="1.1"
                        viewBox="0 0 16 16"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M7 4.75c0-0.412 0.338-0.75 0.75-0.75h0.5c0.412 0 0.75 0.338 0.75 0.75v0.5c0 0.412-0.338 0.75-0.75 0.75h-0.5c-0.412 0-0.75-0.338-0.75-0.75v-0.5z"></path>
                        <path d="M10 12h-4v-1h1v-3h-1v-1h3v4h1z"></path>
                        <path d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM8 14.5c-3.59 0-6.5-2.91-6.5-6.5s2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5-2.91 6.5-6.5 6.5z"></path>
                      </svg>
                    </span>
                  </OverlayTrigger>
                </Form.Label>
                <div className="validate-input d-flex flex-column">
                  <DropDownList
                  tabIndex = {16}
                  
                    placeHolder={"Select Status"}
                    data={StaticArrays.replacementReservesMethod}
                    defaultValue={+reservesType}
                    isSearchRequired={false}
                    label={"label"}
                    value={"value"}
                    disabled={!this.props?.hasEditPermission}
                    onDropDownChange={(item) => {
                      if (!item) {
                        return;
                      }
                      this.onGlobalSettingFieldChange(
                        Utils.BuildCustomEventObject(
                          "reservesType",
                          item.value
                        ),
                        12
                      );
                    }}
                    selectedItem={[
                      ...[
                        {
                          label: "Select Reserve Type",
                          value: 0,
                        },
                      ],
                      ...StaticArrays.replacementReservesMethod,
                    ].find((r: any) => +r?.value === +reservesType)}
                  />
                  {this.getFieldError("reservesType", 12)}
                </div>
              </Form.Group>

              <Form.Group
                onBlur={() => this.validationOnGlobalSettingsClick(13)}
                controlId="date-selection"
                className={
                  globalSettingErrors?.reserves && inputPosition >= 13
                    ? "validation-error d-flex flex-row"
                    : "d-flex flex-row"
                }
              >
                <Form.Label></Form.Label>
                <div className="validate-input d-flex flex-column">
                  <input
                          tabIndex={17}
                    placeholder={
                      +reservesType === 1
                        ? "Enter Reserve Percentage"
                        : "Enter Reserve Amount"
                    }
                    type="number"
                    onKeyDown={Utils.AllowOnlyPositiveNumnberInput}
                    min="0"
                    className="form-control"
                    id="txtreserves"
                    name="reserves"
                    autoComplete="off"
                    value={reserves}
                    disabled={!this.props?.hasEditPermission}
                    onChange={(e: any) =>
                      this.onGlobalSettingFieldChange(e, 13)
                    }
                    
                  />
                  {this.getFieldError("reserves", 13)}
                </div>
              </Form.Group>
        
              <Form.Group
                onBlur={() => this.validationOnGlobalSettingsClick(14)}
                controlId="date-selection"
                className={
                  globalSettingErrors?.startMonth && inputPosition >= 14
                    ? "validation-error d-flex flex-row"
                    : "d-flex flex-row"
                }
              >
                <Form.Label>Accounting YTD Start Month
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id={"tooltip-displayName"}>
                        This setting affects the Income Statement Report. For YTD values, the selected month will be considered the starting month. If the entity is not on a fiscal calendar, leave as January.
                      </Tooltip>
                    }
                  >
                    <span style={{ marginLeft: 5, marginTop: 10 }}>
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        version="1.1"
                        viewBox="0 0 16 16"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M7 4.75c0-0.412 0.338-0.75 0.75-0.75h0.5c0.412 0 0.75 0.338 0.75 0.75v0.5c0 0.412-0.338 0.75-0.75 0.75h-0.5c-0.412 0-0.75-0.338-0.75-0.75v-0.5z"></path>
                        <path d="M10 12h-4v-1h1v-3h-1v-1h3v4h1z"></path>
                        <path d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM8 14.5c-3.59 0-6.5-2.91-6.5-6.5s2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5-2.91 6.5-6.5 6.5z"></path>
                      </svg>
                    </span>
                  </OverlayTrigger>
                </Form.Label>
                <div className="validate-input d-flex flex-column">
                  <DropDownList
                    data={StaticArrays.monthsList}
                    label={"label"}
                    value={"value"}
                    isSearchRequired={false}
                    disabled={!this.props?.hasEditPermission}
                    defaultValue={startMonth}
                    onDropDownChange={(item) => {
                      if (!item) {
                        return;
                      }
                      this.onGlobalSettingFieldChange(
                        Utils.BuildCustomEventObject("startMonth", item.value),
                        14
                      );
                    }}
                    selectedItem={
                      [
                        ...[
                          {
                            label: "Select Month",
                            value: 0,
                          },
                        ],
                        ...StaticArrays.monthsList,
                      ].find((r: any) => +r?.value === startMonth) || {
                        label: "Select Month",
                        value: 0,
                      }
                    }
                  />
                  {this.getFieldError("startMonth", 14)}
                </div>
              </Form.Group>
          
              <Form.Group
                onBlur={() => this.validationOnGlobalSettingsClick(14)}
                controlId="date-selection"
                className={
                  globalSettingErrors?.overTime && inputPosition >= 14
                    ? "validation-error d-flex flex-row"
                    : "d-flex flex-row"
                }
              >
                <Form.Label>OverTime Rate</Form.Label>
                <div className="validate-input d-flex flex-column">
                  <DropDownList
                       tabIndex = {18}
                    placeHolder={"Select Over Time"}
                    data={StaticArrays.overtimeRates}
                    defaultValue={+overTime}
                    isSearchRequired={false}
                    label={"label"}
                    value={"value"}
                    disabled={!this.props?.hasEditPermission}
                    onDropDownChange={(item) => {
                      if (!item) {
                        return;
                      }
                      this.onGlobalSettingFieldChange(
                        Utils.BuildCustomEventObject("overTime", item.value),
                        14
                      );
                    }}
                    selectedItem={
                      [
                        ...[
                          {
                            label: "Select Over Time",
                            value: 0,
                          },
                        ],
                        ...StaticArrays.overtimeRates,
                      ].find((r: any) => +r?.value === +overTime) || {
                        label: "Select Over Time",
                        value: 0,
                      }
                    }
                  />
                  {this.getFieldError("overTime", 14)}
                </div>
              </Form.Group>
              <Form.Group
                onBlur={() => this.validationOnGlobalSettingsClick(15)}
                controlId="date-selection"
                className={
                  globalSettingErrors?.doubleOverTime && inputPosition >= 15
                    ? "validation-error d-flex flex-row"
                    : "d-flex flex-row"
                }
              >
                <Form.Label>Double OverTime Rate</Form.Label>
                <div className="validate-input d-flex flex-column">
                  <DropDownList
                       tabIndex = {19}
                    placeHolder={"Select Double Over Time"}
                    data={StaticArrays.doubleOvertimeRates}
                    defaultValue={+doubleOverTime}
                    isSearchRequired={false}
                    label={"label"}
                    value={"value"}
                    disabled={!this.props?.hasEditPermission}
                    onDropDownChange={(item) => {
                      if (!item) {
                        return;
                      }
                      this.onGlobalSettingFieldChange(
                        Utils.BuildCustomEventObject(
                          "doubleOverTime",
                          item.value
                        ),
                        15
                      );
                    }}
                    selectedItem={
                      [
                        ...[
                          {
                            label: "Select Double Over Time",
                            value: 0,
                          },
                        ],
                        ...StaticArrays.doubleOvertimeRates,
                      ].find((r: any) => +r?.value === +doubleOverTime) || {
                        label: "Select Double Over Time",
                        value: 0,
                      }
                    }
                  />
                  {this.getFieldError("doubleOverTime", 15)}
                </div>
              </Form.Group>

              <Form.Group
                onBlur={() => this.validationOnGlobalSettingsClick(16)}
                controlId="date-selection"
                className={
                  globalSettingErrors?.holidayRate && inputPosition >= 16
                    ? "validation-error d-flex flex-row"
                    : "d-flex flex-row"
                }
              >
                <Form.Label>Holiday Rate</Form.Label>
                <div className="validate-input d-flex flex-column">
                   <DropDownList
                        tabIndex = {20}
                        disabled={!this.props?.hasEditPermission}
                    placeHolder={"Select Holiday Rate"}
                    data={StaticArrays.overtimeRates}
                    defaultValue={+holidayRate}
                    isSearchRequired={false}
                    label={"label"}
                    value={"value"}
                    onDropDownChange={(item) => {
                      if (!item) {
                        return;
                      }
                      this.onGlobalSettingFieldChange(
                        Utils.BuildCustomEventObject("holidayRate", item.value),
                        16
                      );
                    }}
                    selectedItem={
                      [
                        ...[
                          {
                            label: "Select Holiday Rate",
                            value: 0,
                          },
                        ],
                        ...StaticArrays.overtimeRates,
                      ].find((r: any) => +r?.value === +holidayRate) || {
                        label: "Select Holiday Rate",
                        value: 0,
                      }
                    }
                  /> 
                  {this.getFieldError("holidayRate", 16)}
                </div>
              </Form.Group>

              <Form.Group
                onBlur={() => this.validationOnGlobalSettingsClick(17)}
                controlId="date-selection"
                className={
                  globalSettingErrors?.salesTaxRate && inputPosition >= 17
                    ? "validation-error d-flex flex-row"
                    : "d-flex flex-row"
                }
              >
                <Form.Label>Sales Tax(%)</Form.Label>
                <div className="validate-input d-flex flex-column">
                  <input
                          tabIndex={21}
                    type="number"
                    title=""
                    placeholder="Please Enter Sales Tax"
                    onKeyDown={Utils.AllowOnlyPositiveNumnberInput}
                    min="0"
                    className="form-control"
                    id="txtSalesTaxRate"
                    name="salesTaxRate"
                    autoComplete="off"
                    value={salesTaxRate}
                    disabled={!this.props?.hasEditPermission}
                    onChange={(e: any) =>
                      this.onGlobalSettingFieldChange(e, 17)
                    }
                    onBlur={(e:any) => this.onConvertToDemial(e)}

                  />
                  {this.getFieldError("salesTaxRate", 17)}
                </div>
              </Form.Group>


            {type == 2 &&
            <>
                <Form.Group
                  onBlur={() => this.validationOnGlobalSettingsClick(18)}
                  controlId="date-selection"
                  className={
                    globalSettingErrors?.OccTax && inputPosition >= 18
                      ? "validation-error d-flex flex-row"
                      : "d-flex flex-row"
                  }
                >
                  <Form.Label>{`${taxLabelMapper["Occupancy Tax"]}(%)`}</Form.Label>
                  <div className="validate-input d-flex flex-column">
                    <input
                            tabIndex={22}
                      type="number"
                      title=""
                      placeholder={`Please Enter ${taxLabelMapper["Occupancy Tax"]}`}
                      onKeyDown={Utils.AllowOnlyPositiveNumnberInput}
                      min="0"
                      className="form-control"
                      id="txtOccTax"
                      name="OccTax"
                      autoComplete="off"
                      value={OccTax}
                      // disabled={!this.props?.hasEditPermission}
                      onChange={(e: any) =>
                        this.onGlobalSettingFieldChange(e, 18)
                      }
                      onBlur={(e:any) => this.onConvertToDemial(e)}
                    />
                    {this.getFieldError("OccTax", 18)}
                  </div>
                </Form.Group>

                <Form.Group
                  onBlur={() => this.validationOnGlobalSettingsClick(19)}
                  controlId="date-selection"
                  className={
                    globalSettingErrors?.FBTax && inputPosition >= 19
                      ? "validation-error d-flex flex-row"
                      : "d-flex flex-row"
                  }
                >
                  <Form.Label>{`${taxLabelMapper["Food & Beverage Tax"]}(%)`}</Form.Label>
                  <div className="validate-input d-flex flex-column">
                    <input
                      tabIndex={23}
                      type="number"
                      title=""
                      placeholder={`Please Enter ${taxLabelMapper["Food & Beverage Tax"]}`}
                      onKeyDown={Utils.AllowOnlyPositiveNumnberInput}
                      min="0"
                      className="form-control"
                      id="txtFBTax"
                      name="FBTax"
                      autoComplete="off"
                      value={FBTax}
                      // disabled={!this.props?.hasEditPermission}
                      onChange={(e: any) =>
                        this.onGlobalSettingFieldChange(e, 19)
                      }
                      onBlur={(e:any) => this.onConvertToDemial(e)}

                    />
                    {this.getFieldError("FBTax", 19)}
                  </div>
                </Form.Group>

                <Form.Group
                  onBlur={() => this.validationOnGlobalSettingsClick(20)}
                  controlId="date-selection"
                  className={
                    globalSettingErrors?.Tax4 && inputPosition >= 20
                      ? "validation-error d-flex flex-row"
                      : "d-flex flex-row"
                  }
                >
                  <Form.Label>{`${taxLabelMapper["Tax 4"]}(%)`}</Form.Label>
                  <div className="validate-input d-flex flex-column">
                    <input
                            tabIndex={24}
                      type="number"
                      title=""
                      placeholder={`Please Enter ${taxLabelMapper["Tax 4"]}`}
                      onKeyDown={Utils.AllowOnlyPositiveNumnberInput}
                      min="0"
                      className="form-control"
                      id="txtTax4"
                      name="Tax4"
                      autoComplete="off"
                      value={Tax4}
                      // disabled={!this.props?.hasEditPermission}
                      onChange={(e: any) =>
                        this.onGlobalSettingFieldChange(e, 20)
                      }
                      onBlur={(e:any) => this.onConvertToDemial(e)}

                    />
                    {this.getFieldError("Tax4", 20)}
                  </div>
                </Form.Group>

                <Form.Group
                  onBlur={() => this.validationOnGlobalSettingsClick(21)}
                  controlId="date-selection"
                  className={
                    globalSettingErrors?.Tax5 && inputPosition >= 21
                      ? "validation-error d-flex flex-row"
                      : "d-flex flex-row"
                  }
                >
                  <Form.Label>{`${taxLabelMapper["Tax 5"]}(%)`}</Form.Label>
                  <div className="validate-input d-flex flex-column">
                    <input
                            tabIndex={25}
                      type="number"
                      title=""
                      placeholder={`Please Enter ${taxLabelMapper["Tax 5"]}`}
                      onKeyDown={Utils.AllowOnlyPositiveNumnberInput}
                      min="0"
                      className="form-control"
                      id="txtTax5"
                      name="Tax5"
                      autoComplete="off"
                      value={Tax5}
                      // disabled={!this.props?.hasEditPermission}
                      onChange={(e: any) =>
                        this.onGlobalSettingFieldChange(e, 21)
                      }
                      onBlur={(e:any) => this.onConvertToDemial(e)}

                    />
                    {this.getFieldError("Tax5", 21)}
                  </div>
                </Form.Group> 
              </>
              }


              {nonUS === false && (
                <Form.Group
                  onBlur={() => this.validationOnGlobalSettingsClick(22)}
                  controlId="date-selection"
                  className={
                    globalSettingErrors?.taxWithholding && inputPosition >= 22
                      ? "validation-error d-flex flex-row"
                      : "d-flex flex-row"
                  }
                >
                  <Form.Label>Tax Withholding State</Form.Label>
                  <div className="validate-input d-flex flex-column">
                    <DropDownList
                         tabIndex = {26}
                      placeHolder={"Select Status"}
                      data={_.sortBy(StaticArrays.withHoldingUnemploymentStates,'label')}
                      defaultValue={taxWithholding}
                      isSearchRequired={false}
                      label={"label"}
                      value={"label"}
                      disabled={!this.props?.hasEditPermission}
                      onDropDownChange={(item) => {
                        if (!item) {
                          return;
                        }
                        this.onGlobalSettingFieldChange(
                          Utils.BuildCustomEventObject(
                            "taxWithholding",
                            item.value
                          ),
                          22
                        );
                      }}
                      selectedItem={
                        [
                          ...[
                            {
                              label: "Select Withholding State",
                              value: "",
                            },
                          ],
                          ...StaticArrays.withHoldingUnemploymentStates,
                        ].find((r: any) => r?.value === taxWithholding) || {
                          label: "Select Withholding State",
                          value: "",
                        }
                      }
                    />
                    {this.getFieldError("taxWithholding", 22)}
                  </div>
                </Form.Group>
              )}

              <Form.Group
                onBlur={() => this.validationOnGlobalSettingsClick(23)}
                controlId="date-selection"
                className={
                  globalSettingErrors?.fax && inputPosition >= 23
                    ? "validation-error d-flex flex-row"
                    : "d-flex flex-row"
                }
              >
                <Form.Label>Fax No.</Form.Label>
                <div className="validate-input d-flex flex-column">
                  <input
                          tabIndex={27}
                    type="text"
                    placeholder="Please Enter Fax No."
                    className="form-control"
                    id="txtfax"
                    name="fax"
                    autoComplete="off"
                    value={fax}
                    disabled={!this.props?.hasEditPermission}
                    onChange={(e: any) =>
                      this.onGlobalSettingFieldChange(e, 23)
                    }
                  />
                  {this.getFieldError("fax", 23)}
                </div>
              </Form.Group>
              {/* {digitalACHEnabled === "Enabled" &&
              <Form.Group
                controlId="date-selection"
                className="d-flex flex-row align-items-center"
              >
                <Form.Label>Setup as Verified User</Form.Label>
                
                  <div className="action-group d-flex flex-row align-items-center">
                    {dwollaCustomersDetail?.dwollaCustStatus === "verified" &&
                      <div>
                        <label     tabIndex={19} >Verified </label>
                        {dwollaCustomersDetail?.beneficialOwnerStatus == "certified" &&
                          <label tabIndex={20} >Certified</label>
                        }
                        <span data-tip={hasAddDigitalACH === false ? tooltipMessageAdd : ""}>
                          <ReactTooltip
                            effect="solid"
                            event="mouseover mouseenter"
                            eventOff="mouseleave mouseout scroll mousewheel blur"
                            place="bottom"
                            multiline={true}
                          />
                          <button
                          tabIndex={21}
                           disabled={hasAddDigitalACH === false}
                            type="button"
                            className="report-gallery btn btn-primary"
                            onClick={() => this.openOtpModal(true, "dwolla-business-vcr", "Disable ACH")}
                          >
                            Disable ACH
                          </button>
                        </span>
                        
                      </div>
                    }

                    {dwollaCustomersDetail?.dwollaCustStatus === "unverified" &&  
                     <span data-tip={hasAddDigitalACH === false ? tooltipMessageAdd : ""}>
                     <ReactTooltip
                       effect="solid"
                       event="mouseover mouseenter"
                       eventOff="mouseleave mouseout scroll mousewheel blur"
                       place="bottom"
                       multiline={true}
                     />
                    <button
                    tabIndex={22}
                    disabled={hasAddDigitalACH === false}
                      type="button"
                      className="report-gallery btn btn-primary"
                      onClick={() => this.setState({ showunverifedconfirmation: true })}
                    >
                      Setup
                    </button>
                    </span>
                    }
                    {this.state.showunverifedconfirmation &&
                      <div className="deleteReport confirmModal">
                        <OutsideClickHandler
                          onOutsideClick={() =>
                            this.setState({ showunverifedconfirmation: false })
                          }
                        >
                          <div className="deleteReportDropdown">
                            <div className="deleteReportBody">
                              <div className="title">Dwolla Setup Confirmation</div>
                              <div className="description deleteReportTxt">
                                <p>
                                  "DACH was already setup . Enabling DACH again will fetch all the previous entered details". Are you sure you want to continue ?
                                </p>
                              </div>
                            </div>
                            <div className="deleteReportFooter action-link">
                              <button
                              tabIndex={23}
                                className="deleteReportCancel btn wht-bg"
                                onClick={() =>
                                  this.setState({
                                    showunverifedconfirmation: false,
                                  })
                                }
                              >
                                Cancel
                              </button>
                              <button
                              tabIndex={24}
                                className="deleteReportDelete btn btn-primary btn-danger"
                                onClick={(e) => this.setupAgainopenOtpModal(false, "dwolla-business-vcr", "Setup")}
                              >
                                Yes
                              </button>
                            </div>
                          </div>
                        </OutsideClickHandler>
                      </div>
                    }
                    {dwollaCustomersDetail?.customerstatus === "suspended" && 
                    
                    <span data-tip={hasAddDigitalACH === false ? tooltipMessageAdd : ""}>
                      <ReactTooltip
                        effect="solid"
                        event="mouseover mouseenter"
                        eventOff="mouseleave mouseout scroll mousewheel blur"
                        place="bottom"
                        multiline={true}
                      />
                    <button
                    tabIndex={25}
                        type="button"
                        className="report-gallery btn btn-primary"
                        onClick={() => this.onSuspendedClick()}
                      >
                        Suspended
                      </button>
                    </span>
                    }

                    {dwollaCustomersDetail?.customerstatus === "retry" && 
                    
                    <span data-tip={hasAddDigitalACH === false ? tooltipMessageAdd : ""}>
                             <ReactTooltip
                               effect="solid"
                               event="mouseover mouseenter"
                               eventOff="mouseleave mouseout scroll mousewheel blur"
                               place="bottom"
                               multiline={true}
                             />
                             <button
                             tabIndex={26}
                                disabled={hasAddDigitalACH}
                                type="button"
                                className="report-gallery btn btn-primary"
                                onClick={() => this.openOtpModal(false, "dwolla-business-vcr", "Retry")}
                              >
                                Retry
                              </button>
                        </span>
                   
                    }
                    {dwollaCustomersDetail?.customerstatus === "document" &&
                      <span data-tip={hasAddDigitalACH === false ? tooltipMessageAdd : ""}>
                             <ReactTooltip
                               effect="solid"
                               event="mouseover mouseenter"
                               eventOff="mouseleave mouseout scroll mousewheel blur"
                               place="bottom"
                               multiline={true}
                             />
                            <button
                               tabIndex={27}
                                disabled={hasAddDigitalACH === false}
                                type="button"
                                className="report-gallery btn btn-primary"
                                onClick={() => this.openOtpModal(false, "dwolla-document-upload", docstatus)}
                              >
                                {docstatus}
                              </button>
                        </span>
                    }
                    {dwollaCustomersDetail?.dwollaCustStatus !== "verified" && dwollaCustomersDetail?.customerstatus === "verified" && <label >Customer verification completed</label>}
                    {
                    dwollaCustomersDetail?.dwollaCustStatus !== "unverified" &&
                    <>
                      {
                        dwollaCustomersDetail?.beneficialOwnerRequired === "Yes" && dwollaCustomersDetail?.beneficialOwnerStatus !== "certified" &&
                        
                        <span data-tip={hasAddDigitalACH === false ? tooltipMessageAdd : ""}>
                             <ReactTooltip
                               effect="solid"
                               event="mouseover mouseenter"
                               eventOff="mouseleave mouseout scroll mousewheel blur"
                               place="bottom"
                               multiline={true}
                             />
                            <button
                               tabIndex={28}
                            disabled={hasAddDigitalACH === false}
                            type="button"
                            className="report-gallery btn btn-primary"
                            onClick={() => this.openOtpModal(false, "dwolla-beneficial-owners", boverificationStatus)}
                          >
                            {boverificationStatus}
                          </button>
                        </span>
                      }
                    </>
                    }
                    {
                    
                        <>
                          {(!dwollaCustomersDetail?.dwollaCustStatus && !dwollaCustomersDetail?.customerstatus) && hasAddDigitalACH === true && 
                         
                             <button
                             tabIndex={29}
                                disabled={isDataSubmitting}
                                type="button"
                                className="report-gallery btn btn-primary"
                                onClick={() => this.openOtpModal(false, "dwolla-business-vcr", "Setup")}
                              >
                                Setup
                             </button>
                          }
                        </>
                    }
                  </div>
                
                {
                  hasAddDigitalACH === false  && dwollaCustomersDetail == null &&
                  <>
                    <span data-tip={tooltipMessageAdd}>
                      <ReactTooltip
                        effect="solid"
                        event="mouseover mouseenter"
                        eventOff="mouseleave mouseout scroll mousewheel blur"
                        place="bottom"
                        multiline={true}
                      />
                      <button
                         tabIndex={30}
                        disabled={true}
                        type="button"
                        className="report-gallery btn btn-primary"
                      >
                      Setup
                      </button>
                    </span>
                  </>
                }
                {this.state.isOtpNeed && (
                  <OtpModal
                    closePopup={this.closeOtpModal.bind(this)}
                    ValidateOTP={this.ValidateOTP.bind(this)}
                    ReSendOTP={this.ReSendOTP.bind(this)}
                    purpose={this.state.Purpose}
                  ></OtpModal>
                )}
              </Form.Group>
              } */}


              {showDwollSetUpModal && (
                <DwollaSetupModal
                  globalSettings={this.state.globalSettings}
                  showDwollSetUpModal={showDwollSetUpModal}
                  hideDwollasetupModal={this.hideDwollasetupModal}
                  isCheckAgreebox={this.isCheckAgreebox}
                  hotelId={this.props?.hotelId}
                />
              )}
              {
                isOpenDwolla && (
                  <CustomDwolla documentType={this.state.documentType} ModalName={this.state.ModalName} custId={dwollaCustomersDetail?.dwollaCustId || ''} hideModal={this.hideCustomDwollaModal} hotelId={this.props?.hotelId}></CustomDwolla>
                )
              }
              {showPayrollAddressModal && (
                <AddGlobalSettingsAddress
                  globalSettings={this.state.globalSettings}
                  showPayrollAddressModal={showPayrollAddressModal}
                  hidePayrollAddressModal={this.hidePayrollAddressModal}
                  reAttachHotelAddressToMainObject={
                    this.reAttachHotelAddressToMainObject
                  }
                />
              )}
            </div>
          </div>
          {(entityGroupsData || [])?.length > 0 && <> <div className="separator-line"></div>
            <div className="heading">Groups Memberships</div>
            <div className="form-section d-flex flex-column">
              <div>
                {(entityGroupsData || [])?.map(
                  (item: any, i) =>
                    (item?.groupWithHotels || [])?.length > 0 && (
                      <Form.Group
                        key={i}
                        className={
                          // globalSettingErrors?.fax && inputPosition >= 18
                          //   ? "validation-error d-flex flex-row"
                          "d-flex flex-row"
                        }
                      >
                        <Form.Label>{item?.categroyName}</Form.Label>
                        <div className="validate-input d-flex flex-column">
                          <DropDownList
                               tabIndex = {31 + i}
                            placeHolder={"Select"}
                            data={item?.groupWithHotels || []}
                            defaultValue={+item?.selectedGroupId}
                            isSearchRequired={false}
                            label={"groupName"}
                            value={"rGoupId"}
                            disabled={!this.props?.hasEditPermission}
                            onDropDownChange={(item) => {
                              if (!item) {
                                return;
                              }
                              this.updateEntityGroupsDataState(i, item.rGoupId);
                            }}
                            selectedItem={
                              [
                                ...[
                                  {
                                    groupName: "Select Group",
                                    rGoupId: 0,
                                  },
                                ],
                                ...(item?.groupWithHotels || []),
                              ].find((r: any) => +r?.rGoupId === +item?.selectedGroupId) || {
                                groupName: "Select Group",
                                rGoupId: 0,
                              }
                            }
                            autoComplete={`new-dropdown-${i}`}
                          />
                          {/* {this.getFieldError("overTime", 13)} */}
                        </div>
                      </Form.Group>
                    )
                )}
              </div>
            </div>
          </>
          }
        </div>

        {(((isFormValid && isGlobalSettingNotChanged === false) || isGroupMappingModified) &&
          this.props?.hasEditPermission ) && (
          <div className="fixed-action ml-auto">
            <div className="d-flex align-content-center flex-wrap">
              <div className="mr-auto message">
                <span>You have unsaved changes</span>
              </div>
              <button
                disabled={isDataSubmitting}
                type="button"
                className="btn btn-primary btn-discard"
                onClick={()=> this.handleDiscard()}
              >
                Discard
              </button>

              <button
                disabled={isDataSubmitting}
                type="button"
                className="btn btn-primary"
                onClick={() => this.updateHotelGlobalSetting()}
              >
                Save
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}
