import React from "react";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import OutsideClickHandler from "react-outside-click-handler";
import { resolve, reject } from "q";
import { ILaborScheduleEmployeeRequestDto } from "../../../../../Common/Contracts/ILaborSchedule";
import { LaborSchedule } from "../../../../../Common/Services/LaborSchedule";
// import { LaborTimeSheet } from "../../../../../Common/Services/LaborTimeSheet";
import profile from "../../../../../Common/Assets/Images/profile.png";

export class DepartmentSearchView extends React.Component<any, any> {
  private inputSearch: any;
  static defaultProps: { component: string };
  constructor(props: any) {
    super(props);
    this.inputSearch = React.createRef();
    this.state = {
      itemLists: [],
      filterData: [],
      allEmployeeList: [],
      selectedItems: [],
      inputValue: "",
    };
  }

  componentDidMount() {
    this.getSearchUsersList();
    this.getSelectedUser();
  }

  componentWillReceiveProps(nextProps: any) {
    let newState = nextProps.parentState.searchSelectionList;
    let newStateComparison = JSON.stringify(newState);

    let currentState = this.state.selectedItems;

    let currentStateComparison = JSON.stringify(currentState);

    if (newStateComparison !== currentStateComparison) {
      this.getSearchUsersList();
    }
  }

  getSelectedUser = () => {
    let searchSelectionList = this.props.parentState.searchSelectionList;
    this.setState({ selectedItems: searchSelectionList });
    // let idx=1
    // let searchSelectionList=this.props.parentState.searchSelectionList

    // this.setState({selectedItems:searchSelectionList})

    // if(searchSelectionList.length>0){}

    // const itemLists = [...this.state.itemLists];

    // const index = itemLists.findIndex((xvalue) => xvalue.rowno === idx);

    // const item = {
    //   value: itemLists[index].rowno,
    //   employee: itemLists[index].employee,
    // };
    // this.setState(
    //   {
    //     selectedItems: [item],
    //   }
    // ,
    // () => {
    //   this.props.selectFromSearchBar(itemLists[index]);
    //   this.setState({ opened: false, inputValue: "" });
    // }
    // );
  };

  getSearchUsersList = () => {
    let request = {} as ILaborScheduleEmployeeRequestDto;

    request.hotelID = this.props.hidValue;
    request.date =
      // this.props.component === "timesheet"
      //   ? this.props.parentState.calenderDates[0]
      // :
      this.props.parentState.navigationCalenderDates[0];
    request.inTime = "0:00 AM";
    request.outTime = "0:00 AM";
    request.position = 0;
    request.type = "scheduleDepartmentViewSearch";
    request.shiftDate =
      // this.props.component === "timesheet"
      //   ? this.props.parentState.calenderDates[1]
      // :
      this.props.parentState.navigationCalenderDates[1];
    request.uniqueno = 0;

    // if (this.props.component === "timesheet") {
    //   this.laborTimeSheetEmployee(
    //     this.props.hidValue,
    //     this.props.parentState.calenderDates[0]
    //   );
    // } else {
    LaborSchedule.GetLaborScheduleEmployee(request)
      .then(async (result: any) => {
        this.setState(
          {
            filterData: result.result,
            allEmployeeList: result.result,
            itemLists: result.result,
          }
          // ,
          // () => {
          // this.getSelectedUser();
          // }
        );
        resolve();
      })
      .catch((error: any) => {
        reject();
      });
    // }
  };

  // laborTimeSheetEmployee(hotelID: number, date: any) {
  //   LaborTimeSheet.GetTimeSheetEmployee(hotelID, date)
  //     .then(async (result: any) => {
  //       this.setState({
  //         filterData: result,
  //         allEmployeeList: result,
  //         itemLists: result,
  //       });
  //       resolve();
  //     })
  //     .catch((error: any) => {
  //       reject();
  //     });
  // }

  handleOutsideClick = () => {
    this.setState({ opened: false, inputValue: "" });
  };

  openDropdown = () => {
    this.setState(
      {
        opened: true,
        filterData: [],
        inputValue: "",
      },
      () => {
        this.getSearchUsersList();
        this.inputSearch.current?.focus();
      }
    );
  };

  handleRemoveSpecificChip = (idx: number) => () => {
    const itemLists = [...this.state.itemLists];
    const selectedItems = [...this.state.selectedItems];
    if (selectedItems[0].employee !== undefined) {
      selectedItems.splice(idx, 1);
      this.setState({ selectedItems, itemLists }, () => {
        this.props.selectFromSearchBar([]);
        this.setState({ opened: false, inputValue: "" });
      });
    }
  };

  handleChange = (e: any) => {
    const updatedList = this.state.itemLists.filter((item: any) => {
      return (
        item.employee.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
      );
    });

    this.setState({
      filterData: updatedList,
      allEmployeeList: updatedList,
      inputValue: e.target.value,
    });
  };

  userListClick = (idx: any) => {
    const itemLists = [...this.state.itemLists];

    const index = itemLists.findIndex((xvalue) => xvalue.rowno === idx);
    idx = index;
    this.setState({ itemLists });
    const item = {
      value: itemLists[idx].rowno,
      employee: itemLists[idx].employee,
    };
    this.setState(
      {
        selectedItems: [item],
      },
      () => {
        this.props.selectFromSearchBar(itemLists[index]);
        this.setState({ opened: false, inputValue: "" });
      }
    );
  };

  clearSearch = () => {
    let blankSelectedItems: any = [];
    this.setState({ selectedItems: blankSelectedItems });
  };

  // userListScrollHeader = (idx: any) => {
  //   const itemLists = [...this.state.itemLists];
  //   const index = itemLists.findIndex((xvalue) => xvalue.rowno === idx);
  //   // idx = index;
  //   // this.setState({ itemLists });
  //   const item = {
  //     value: itemLists[index].rowno,
  //     employee: itemLists[index].employee,
  //   };
  //   let selectedItems: any = [];
  //   selectedItems.push(item);
  //   this.setState(
  //     {
  //       selectedItems,
  //     },
  //     () => {
  //       this.props.selectFromSearchBar(itemLists[index]);
  //       this.setState({ opened: false, inputValue: "" });
  //     }
  //   );
  // };

  render() {
    return (
      <OutsideClickHandler onOutsideClick={this.handleOutsideClick}>
        <div
          className={
            this.state.opened ? "multiSelectSearch opened" : "multiSelectSearch"
          }
          id={this.props.id}
        >
          <div className="fieldArea" onClick={this.openDropdown}>
            {this.state.selectedItems.length === 0 ? (
              <span className="areaPlaceholder">Search </span>
            ) : (
              <div className="chip">
                {this.state.selectedItems.map((item: any, idx: any) => (
                  <div className="chipSelect" id={idx} key={idx}>
                    <div className="chipVal">
                      <EllipsisWithTooltip placement="bottom">
                        {item.employee}
                      </EllipsisWithTooltip>
                    </div>
                    <button
                      id="btnremove"
                      type="button"
                      className="cross"
                      onClick={this.handleRemoveSpecificChip(idx)}
                    >
                      <svg
                        width="8"
                        height="8"
                        viewBox="0 0 8 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.79465 0.229492L3.96598 3.05749L1.13798 0.229492L0.195312 1.17216L3.02331 4.00016L0.195312 6.82816L1.13798 7.77083L3.96598 4.94282L6.79465 7.77083L7.73731 6.82816L4.90931 4.00016L7.73731 1.17216L6.79465 0.229492Z"
                          fill="#6A6E73"
                        />
                      </svg>
                    </button>
                  </div>
                ))}
              </div>
            )}
            {this.state.opened && (
              <div className="coaSearch">
                <div className="dropDownPanel">
                  <div className="SearchInput">
                    <input
                      ref={this.inputSearch}
                      key={"text-Filter"}
                      type="text"
                      className="form-control"
                      onChange={this.handleChange}
                      placeholder="Search"
                      value={this.state.inputValue}
                    />
                  </div>
                  <ul>
                    {this.state.allEmployeeList.length > 0 &&
                      this.state.allEmployeeList.map((item: any, idx: any) => {
                        if (item.isconflict.toLowerCase() !== "incomplete")
                          return (
                            <li
                              id={idx}
                              key={idx}
                              className={
                                item.isconflict === "Yes" ? "disabled" : ""
                              }
                              onClick={() => this.userListClick(item.rowno)}
                            >
                              <div className="user-details">
                                <img
                                  src={
                                    item.imagePath === null ||
                                    item.imagePath.trim() === ""
                                      ? profile
                                      : item.imagePath
                                  }
                                  className="profileimg"
                                  width="36"
                                  height="36"
                                  alt=""
                                />
                                <div className="name-position mr-auto">
                                  <EllipsisWithTooltip placement="bottom">
                                    <div className="name">{item.employee}</div>
                                  </EllipsisWithTooltip>
                                  <EllipsisWithTooltip placement="bottom">
                                    <div className="pos-name">
                                      {item.positionName}
                                    </div>
                                  </EllipsisWithTooltip>
                                </div>
                              </div>
                            </li>
                          );


                        if (item.isconflict.toLowerCase() === "incomplete")
                          return (
                            <li
                              id={idx}
                              key={idx}
                              className={
                                "disabled"
                              }
                              // onClick={() => this.userListClick(item.rowno)}
                            >
                              <div className="user-details d-flex">
                                <img
                                  src={
                                    item.imagePath === null ||
                                    item.imagePath.trim() === ""
                                      ? profile
                                      : item.imagePath
                                  }
                                  className="profileimg"
                                  width="36"
                                  height="36"
                                  alt=""
                                />
                                <div className="name-position mr-auto">
                                  <>
                                  <EllipsisWithTooltip placement="bottom">
                                    <div className="name">{item.employee}</div>
                                  </EllipsisWithTooltip>
                                  <EllipsisWithTooltip placement="bottom">
                                    <div className="pos-name">
                                      {item.positionName}
                                     
                                    </div>
                                   
                                  </EllipsisWithTooltip>
                                 
                                  </>
                                </div>
                                <div className="red-badges">Incomplete</div>
                              </div>
                            </li>
                          );
                      })}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </OutsideClickHandler>
    );
  }
}

// DepartmentSearchView.defaultProps = {
//   component: "schedule",
// };
