import React from "react";
import {
  Form,
  Button,
  Dropdown,
  Container,
  ButtonGroup,
  Table,
  Modal
} from "react-bootstrap";
import {
  LaborTimeOffDetails,/*LabourTimeOffApprovePTORequest, LabourTimeOffUpdatePTORequestEntries,*/
} from "../../../../../Common/Services/LabourTimeOff";

// import {
//   ILabourTimeOffApprovePTORequest,
//   ILabourTimeOffUpdatePTORequestEntries,
// } from "../../../../../Common/Contracts/ILabourTimeOff";
import { reject } from "q";
import ForecastLock from "../../../../../Common/Assets/Images/icons/forecast-lock.svg";
import $ from "jquery";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-calendar/dist/Calendar.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { ToastContainer } from "react-toastify";
import OutsideClickHandler from "react-outside-click-handler";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { LaborSchedule } from "../../../../../Common/Services/LaborSchedule";
import moment from "moment-timezone";
import { Utils } from "../../../../../Common/Utilis";


let isOverlapCheck: any = "";
export class SchedulePTOSlideOut extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      hotelName: this.props.parentState.hotelName,
      permissionManageTimeOff: this.props.parentState.permissionManageTimeOff,
      permissionManageSchedule: this.props.parentState.permissionManageSchedule,
      slideoutPTOTitle: "Time Off Request",
      employeeName: this.props.parentState.openSlideOutdata.row.employeeName,
      employeeImage: this.props.parentState.openSlideOutdata.row.employeeImage,
      rightPanelRowData: { rightPanelRowTop: [], rightPanelRowBottom: [] },
      PTORequestID: this.props.parentState.openSlideOutdata.schedule.uniqueno,
      calenderDates: this.props.parentState.navigationCalenderDates[0],
      edtPTOstartTime: "00:00",
      edtPTOendTime: "00:00",
      showNewEntry: false,
      dsValue: "",
      dsError: "",
      asValue: "",
      asError: "",
      PTOSummery: "Add Comments",
      reEditPto: { reCalcStartDate: "", reCalcEndDate: "", inTime: "", outTime: "" },
      reEditPtoErr: { reCalcStartDate: false, reCalcEndDate: false, inTime: false, outTime: false },
      IsPTOID: "0",
      modalShiftOverlaps: false,
      modalShiftOverlapsData: "",
      hidValue: this.props.parentState.hidValue,
      navigationCalenderDates: this.props.parentState.navigationCalenderDates,
      formHasError: false,
      inOutPtoDuration: "",
      timeDuration: "",
      
    };
  }
  componentDidMount() {
    console.warn = () => { };
    this.getslideOut();
  }

  getslideOut = () => {
    let { IsPTOID, PTORequestID } = this.state;
    let scheduleUniqueNo = PTORequestID;
    let calenderDates = this.props.parentState.navigationCalenderDates[0];
    let userName = this.props.parentState.openSlideOutdata.row.employeeName;
    LaborTimeOffDetails.GetTimeOffRequestDetails(
      scheduleUniqueNo,
      calenderDates,
      IsPTOID
    ).then(async (result: any | null) => {
      let data = result.result;
      if (data !== null) {
        this.setState({ PTORequestID: data.ptoRequestID, IsPTOID: "1" });
        let rightPanelTop = [
          {
            username: userName,
            empTitle: data.empTitle,
            ptoRequestID: data.ptoRequestID,
            postApprovalBalance: data.postBalance,
            ptoStatus: data.ptoStatus,
            scheduleType: data.scheduleType,
          },
        ];
        let rightPanelBottom = [
          {
            ptoType: data.ptoType,
            ptoResion: data.ptoResion,
            postApprovalBalance: data.postBalance,
            ptoRequestDate: data.ptoRequestDate,
            ptoBalance: data.ptoBalance,
            managerComment: data.managerComment,
            duration: data.duration,
            timeOffRequestDateTime: data.timeOffRequestDateTime,
            imageURL: data.imageURL,
            ptoSubmitted: data.ptoRequestDate,
            ptoReason: data.ptoResion,
            ptoPostApprovalBalance: data.postBalance,
            overlapData: data.overlapData,
            overlapType: data.overlapType,
          },
        ];
        this.setState({
          rightPanelRowData: {
            rightPanelRowTop: rightPanelTop,
            rightPanelRowBottom: rightPanelBottom,
          },
        });
      }
    });
  };

  asChange = (event: { target: { value: any } }) => {
    let { value } = event.target;
    this.setState({ asValue: value.trimStart() });
  };

  dsChange = (event: { target: { value: any } }) => {
    let { value } = event.target;
    this.setState({ dsValue: value.trimStart() });
  };
  dsValidate = (value: string | any[]) => {
    if (value === "") {
      this.setState({ dsError: "Please provide an explanation." });
    } else if (value.length > 200) {
      this.setState({ dsError: "Maximum 200 characters allowed." });
    } else {
      return value;
    }
  };

  asValidate = (value: string | any[]) => {
    if (value === "") {
      this.setState({ asError: "Please provide an explanation." });
    } else if (value.length > 200) {
      this.setState({ asError: "Maximum 200 characters allowed." });
    } else {
      return value;
    }
  };
  clearTextarea = () => {
    this.setState({ dsValue: "", asValue: "" });
  };

  declineSubmit = (event: any) => {
    let { dsValue, calenderDates, PTORequestID, IsPTOID } = this.state;
    let ptoStatusDeclined = "Declined";
    event.preventDefault();
    this.setState({ showNewEntry: false });
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let ptoRqstid = PTORequestID;
    let ptoUsrStatus = ptoStatusDeclined;
    let selectDate = calenderDates;
    let currentuserName = storage.userName;
    let managerComment = this.dsValidate(dsValue);
    if (this.dsValidate(dsValue)) {
      this.setState({ dsError: "" });
      this.setState({ panelDecline: false });
      this.setState({ PTOSummery: this.dsValidate(dsValue) });
      let request: any[] = [];
      let newItem: any = {
        HotelID: this.state.hidValue,
        ptoRequestID: ptoRqstid,
        Status: ptoUsrStatus,
        selectDate: selectDate,
        loginUser: currentuserName,
        managerComment: managerComment,
        IsPTOID: IsPTOID,
        pageSource: '',
      }
      request.push(newItem);
      //LabourTimeOffApprovePTORequest.ApprovePTORequest(request)
      LaborSchedule.RaiseTimeOff(request)
        .then(async (result: any | null) => {
          if (result.success) {
            let data = result.result;
            if (data.toLowerCase() === "dayapprove") {
              Utils.toastError("Day is already approved, you cannot decline the request.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "schedulePTOSlideOut",
              });
            } else if (data.toLowerCase() === "deptapprove") {
              Utils.toastError("Department is already approved, you cannot decline the request.              ", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "schedulePTOSlideOut",
              });
            } else if (data.toLowerCase() === "scheduleoverlap") {
              Utils.toastError(
                "The requested PTO overlaps with user schedule, you cannot decline the request.",
                { position: toast.POSITION.BOTTOM_RIGHT, containerId: "schedulePTOSlideOut" }
              );
            } else if (data.toLowerCase() === "actualoverlap") {
              Utils.toastError(
                "The requested PTO overlaps with actual Punch-in, you cannot decline the request.",
                { position: toast.POSITION.BOTTOM_RIGHT, containerId: "schedulePTOSlideOut" }
              );
            } else if (data.toLowerCase() === "fail") {
              Utils.toastError("Request Failed.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "schedulePTOSlideOut",
              });
            } else if (data.toLowerCase() === "success") {
              this.setState({
                showNewEntry: false,
                inOutPtoDuration: "",
                timeDuration: "",
                dsValue: "",
              });
              this.setState({
                reEditPto: { reCalcStartDate: "", reCalcEndDate: "", inTime: "", outTime: "" },
              });
              toast.success("PTO Request Declined.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "schedulePTOSlideOut",
              });
              this.getslideOut();
              setTimeout(() => {
                this.closeSlideOut();
              }, 3500);
            } else {
              Utils.toastError(data, {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "schedulePTOSlideOut",
              });
            }
          } else {
            Utils.toastError("Some error occurred, Please try letter.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "schedulePTOSlideOut",
            });
          }
        })
        .catch((error) => {
          Utils.toastError(error.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "schedulePTOSlideOut",
          });
        });
    }
  };

  declinedPanel = () => {
    let { dsValue, dsError } = this.state;
    const { rightPanelRowTop, } = this.state.rightPanelRowData;
    let ptoType = rightPanelRowTop[0].scheduleType;
    let isPermission = false;
    if (ptoType === "3" && this.state.permissionManageTimeOff === true) {
      isPermission = true;
    }
    if (ptoType === "4" && this.state.permissionManageTimeOff === true) {
      isPermission = true;
    }
    if (ptoType === "5" && this.state.permissionManageTimeOff === true) {
      isPermission = true;
    }
    if (ptoType === "6" && this.state.permissionManageTimeOff === true) {
      isPermission = true;
    }
    else if (ptoType === "10" && this.state.permissionManageSchedule === true) {
      isPermission = true;
    }

    return (
      <Dropdown className="btnDeclined hasForm mr-2" alignRight>
        {isPermission === true ?
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            <div onClick={this.closeNewEntry}>Decline</div>
          </Dropdown.Toggle>
          : null}

        <Dropdown.Menu>
          <Form noValidate onSubmit={this.declineSubmit}>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <div className="clearfix">
                <Form.Label>Reason to Decline</Form.Label>
              </div>
              <Form.Control
                as="textarea"
                placeholder="Explanation required..."
                name="decline"
                value={dsValue}
                onChange={this.dsChange}
                rows={3}
                required
              />
              <span className="small text-danger">{dsError}</span>
            </Form.Group>
            <Form.Group className="text-right seprator">
              <Dropdown.Item onClick={this.clearTextarea}>Cancel</Dropdown.Item>
              <Button variant="danger" type="submit">
                Decline
              </Button>
            </Form.Group>
          </Form>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  handleModalShiftClose = () => {
    this.setState({ modalShiftOverlaps: false });
  }

  approveSubmit = (event, type) => {
    let { asValue, calenderDates, PTORequestID, IsPTOID } = this.state;
    let ptoStatusApproved = "Approved";
    event.preventDefault();
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let ptoRqstid = PTORequestID;
    let ptoUsrStatus = ptoStatusApproved;
    let selectDate = calenderDates;
    let currentuserName = storage.userName;
    let managerComment;
    if (type === "Yes") {
      managerComment = this.dsValidate(type);
    } else {
      managerComment = this.dsValidate(asValue);
    }
    if (type === "No" || this.asValidate(managerComment)) {
      this.setState({ asError: "" });
      this.setState({ panelApprove: false });
      this.setState({ PTOSummery: this.asValidate(asValue) });
      let request: any[] = [];
      let newItem: any = {
        HotelID: this.state.hidValue,
        ptoRequestID: ptoRqstid,
        Status: ptoUsrStatus,
        selectDate: selectDate,
        loginUser: currentuserName,
        managerComment: type === "No" ? "": managerComment,
        OverlapeUpdate: type,
        IsPTOID: IsPTOID,
        PageSource:''
      }
      request.push(newItem);
      //LabourTimeOffApprovePTORequest.ApprovePTORequest(request)
      LaborSchedule.RaiseTimeOff(request)
        .then(async (result: any | null) => {
          this.setState({ modalShiftOverlaps: false, modalShiftOverlapsData: '', asError: "" });
          if (result.success) {
            let data = result.result;
            let hasTable = data.includes("<table>");
            let hasString = data.includes("already approved Time Off shift overlaps with this one");
            if (data.toLowerCase() === "dayapprove") {
              Utils.toastError("Day is already approved, Request can't approve.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "schedulePTOSlideOut",
              });
            } else if (hasTable) {
              this.setState({ modalShiftOverlaps: true, modalShiftOverlapsData: data });
            } else if (hasString) {
              this.setState({ modalShiftOverlaps: true, modalShiftOverlapsData: data });
            } else if (data.toLowerCase() === "exceed") {
              Utils.toastError(
                "Maximum PTO Hours for a week can not exceed 40 hours.",
                {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  containerId: "schedulePTOSlideOut",
                }
              );
            } else if (data.toLowerCase() === "deptapprove") {
              Utils.toastError("Department is approved, Request can't approve.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "schedulePTOSlideOut",
              });
            } else if (data.toLowerCase() === "scheduleoverlap") {
              Utils.toastError(
                "PTO overlap with user schedule, Request can't approve.",
                { position: toast.POSITION.BOTTOM_RIGHT, containerId: "schedulePTOSlideOut" }
              );
            } else if (data.toLowerCase() === "actualoverlap") {
              Utils.toastError(
                "PTO overlap with Actual Punch-in, Request can't approve.",
                { position: toast.POSITION.BOTTOM_RIGHT, containerId: "schedulePTOSlideOut" }
              );
            } else if (data.toLowerCase() === "fail") {
              Utils.toastError("Request Failed.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "schedulePTOSlideOut",
              });
            } else if (data.toLowerCase() === "success") {
              this.setState({
                showNewEntry: false,
                inOutPtoDuration: "",
                timeDuration: "",
                asValue: "",
                reEditPto: { reCalcStartDate: "", reCalcEndDate: "", inTime: "", outTime: "" },
              });
              toast.success("PTO Request Approved.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "schedulePTOSlideOut",
              });
              this.getslideOut();
              setTimeout(() => {
                this.closeSlideOut();
              }, 3500);
            } else {
              Utils.toastError(data, {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "schedulePTOSlideOut",
              });
            }
          } else {
            Utils.toastError("Some error occurred, Please try letter.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "schedulePTOSlideOut",
            });
          }
        }
        );
    }
  };

  closeNewEntry = () => {
    this.setState({ showNewEntry: false });
  };

  approvedPanel = () => {
    let { asValue, asError } = this.state;
    const { rightPanelRowTop, } = this.state.rightPanelRowData;
    let ptoType = rightPanelRowTop[0].scheduleType;
    let isPermission = false;
    if (ptoType === "3" && this.state.permissionManageTimeOff === true) {
      isPermission = true;
    }
    if (ptoType === "4" && this.state.permissionManageTimeOff === true) {
      isPermission = true;
    }
    if (ptoType === "5" && this.state.permissionManageTimeOff === true) {
      isPermission = true;
    }
    if (ptoType === "6" && this.state.permissionManageTimeOff === true) {
      isPermission = true;
    }
    else if (ptoType === "10" && this.state.permissionManageSchedule === true) {
      isPermission = true;
    }
    return (
      <Dropdown className="btnApprove hasForm mr-2" alignRight>
        {isPermission && (
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            <div onClick={(e)=>{this.closeNewEntry();this.approveSubmit( e,"No")}}>Approve</div>
          </Dropdown.Toggle>
        )}

        {/* <Dropdown.Menu>
          <Form noValidate onSubmit={(e) => this.approveSubmit(e, "No")}>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <div className="clearfix">
                <Form.Label>Reason to Approve</Form.Label>
              </div>
              <Form.Control
                as="textarea"
                placeholder="Explanation required..."
                name="decline"
                value={asValue}
                onChange={this.asChange}
                rows={3}
                required
              />
              <span className="small text-danger">{asError}</span>
            </Form.Group>
            <Form.Group className="text-right seprator">
              <Dropdown.Item onClick={this.clearTextarea}>Cancel</Dropdown.Item>
              <Button variant="success" type="submit">
                Approve
              </Button>
            </Form.Group>
          </Form>
        </Dropdown.Menu> */}
      </Dropdown>

    );
  };

  dotsSVG = () => {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
      </svg>
    );
  };

  croseSVG = () => {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.4933 5.28662L9.95747 8.82162L6.42247 5.28662L5.24414 6.46495L8.77914 9.99995L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 9.99995L14.6716 6.46495L13.4933 5.28662Z"
          fill="#84888C"
        />
      </svg>
    );
  };

  singleDotSVG = () => {
    return (
      <svg
        stroke="currentColor"
        fill="currentColor"
        stroke-width="0"
        viewBox="0 0 16 16"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M8 9.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
          clip-rule="evenodd"
        ></path>
      </svg>
    );
  };

  resetPopupData = (time: {
    requestID: any;
    startDate: any;
    inTime24Hrs: any;
    outTime24Hrs: any;
  }) => {
    let { PTORequestID } = this.state;
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let PTORequestEntriesedit = {
      ptoReqID: PTORequestID,
      reqID: time.requestID,
      loginUser: storage.userName,
      ptoDate: time.startDate,
    };
    this.setState({
      PTORequestEntriesedit: PTORequestEntriesedit,
      edtPTOstartTime: time.inTime24Hrs,
      edtPTOendTime: time.outTime24Hrs,
      showNewEntry: false,
      inOutPtoDuration: "",
      timeDuration: "",
    });
  };

  editPTOStarttime = (e: { target: { value: any } }) => {
    this.setState({ edtPTOstartTime: e.target.value });
  };
  editPTOEndtime = (e: { target: { value: any } }) => {
    this.setState({ edtPTOendTime: e.target.value });
  };

  formatDate(time: string) {
    const dataArray = time.split(":");
    let hh = Number(dataArray[0]);
    let m = Number(dataArray[1]);
    let dd = "AM";
    let h = hh;
    if (h >= 12) {
      h = hh - 12;
      dd = "PM";
    }
    if (h === 0) {
      h = 12;
    }
    let min = m < 10 ? "0" + m : m;
    let replacement = h + ":" + min;
    replacement += " " + dd;
    return replacement;
  }

  editPTORequestSubmit = (e: any, row: any) => {
    
    let {
      PTORequestEntriesedit,
      edtPTOstartTime,
      navigationCalenderDates,
      edtPTOendTime,
      IsPTOID,
    } = this.state;
    e.preventDefault();

    /// Change end date when time is selected next date

    let startdate1 = row.startDate;

    let startTime1 = this.state.edtPTOstartTime;
    let endTime1 = this.state.edtPTOendTime;

    let startdate2 = moment(startdate1).format("MM/DD/YYYY");
    let inMomentTime = moment(
      `${startdate2} ${startTime1}`
    );
    let outMomentTime = moment(
      `${startdate2} ${endTime1}`
    );

    let differenceInTime = outMomentTime.diff(inMomentTime);

    let punchOutDate = moment(startdate2).format("MM/DD/YYYY");
    if (differenceInTime <= 0) {
      punchOutDate = moment(startdate2)
        .add(1, "days")
        .format("MM/DD/YYYY");
    }

    ///==============

    let rightPanelScheduleType="";
    const { rightPanelRowTop  } = this.state.rightPanelRowData;
    if (
      typeof rightPanelRowTop !== "undefined" &&
      rightPanelRowTop.length > 0
    ) {     
      rightPanelScheduleType = rightPanelRowTop[0].scheduleType;
    }

    let startdateTime = new Date(new Date(row.startDate).toLocaleDateString("en-US") + " " + edtPTOstartTime);
    //let endDateTime = new Date(new Date(row.endDate).toLocaleDateString("en-US") + " " + edtPTOendTime);
    let endDateTime = new Date(new Date(punchOutDate).toLocaleDateString("en-US") + " " + edtPTOendTime);

    let isOverlap=  this.cheackOverlapEdit(startdateTime, endDateTime,row);

    const totalMinutes = this.timeDiffCalc(startdateTime, endDateTime);
    if (totalMinutes < 0) {
      Utils.toastError("Start Time can't be greater than End Time.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "schedulePTOSlideOut",
      });
    } else if (totalMinutes === 0) {
      Utils.toastError("Start Time can't be same as End Time.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "schedulePTOSlideOut",
      });
    } else if (totalMinutes > 480 && rightPanelScheduleType!=="10" )  {
      Utils.toastError("PTO for a day can't be greater than 8:00 hours.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "schedulePTOSlideOut",
      });
    } else if (isOverlap ===false) {
      Utils.toastError("Time of request is overlapping.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "timeoffRequest",
      });
    }    
    else {
      this.formatDate(this.state.edtPTOstartTime);
      let request: any[] = [];
      let newItem: any = {
        HotelID: this.state.hidValue,
        PTORequestID: parseInt(PTORequestEntriesedit.ptoReqID),
        RequestID: parseInt(PTORequestEntriesedit.reqID),
        InTime: this.formatDate(this.state.edtPTOstartTime),
        OutTime: this.formatDate(this.state.edtPTOendTime),
        StartDate: row.startDate,
       // EndDate: row.endDate,
       EndDate: punchOutDate, 
        LoginUser: PTORequestEntriesedit.loginUser,
        Type: "Edit",
        PTODate: PTORequestEntriesedit.ptoDate,
        IsPTOID: IsPTOID,
        WeekStartDate: navigationCalenderDates[0],
        Status: '',
        pageSource: "UpdatedSchedulePTOSlideOut"
      }
      request.push(newItem);
      //LabourTimeOffUpdatePTORequestEntries.ApprovePTORequestEntries(request)
      LaborSchedule.RaiseTimeOff(request)
        .then(async (result: any | null) => {
          let data = result.result;
          if (result.success) {
            if (data.toLowerCase() === "success") {
              this.getslideOut();
              toast.success("PTO time has been saved successfully.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "schedulePTOSlideOut",
              });
              $(".tableHasDropdown.show").trigger("click");
            } else if (data.toLowerCase() === "approved") {
              Utils.toastError("PTO has already been approved.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "schedulePTOSlideOut",
              });
            } else if (data.toLowerCase() === "declined") {
              Utils.toastError("PTO has already been declined.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "schedulePTOSlideOut",
              });
            } else {
              Utils.toastError(data, {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "schedulePTOSlideOut",
              });
            }
          }
        })
        .catch((error) => {
          Utils.toastError(error.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "schedulePTOSlideOut",
          });
        });
    }
  };

  RemovePTOEntry = (time: any) => {
    
    let { PTORequestID, IsPTOID } = this.state;
    this.setState({ showNewEntry: false });
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let PTORequestEntriesedit = {
      ptoReqID: PTORequestID,
      reqID: time.requestID,
      loginUser: storage.userName,
      ptoDate: time.startDate,
    };
    let request: any[] = [];
    let newItem: any = {
      HotelID: this.state.hidValue,
      PTORequestID: PTORequestEntriesedit.ptoReqID,
      RequestID: PTORequestEntriesedit.reqID,
      InTime: this.formatDate(this.state.edtPTOstartTime),
      OutTime: this.formatDate(this.state.edtPTOendTime),
      StartDate: time.startDate,
      EndDate: time.endDate,
      LoginUser: PTORequestEntriesedit.loginUser,
      Type: "Delete",
      PTODate: PTORequestEntriesedit.ptoDate,
      IsPTOID: IsPTOID,
      WeekStartDate: time.startDate,
      Status: '',
      PageSource:''
    }
    request.push(newItem);

    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete this Entry?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            LaborSchedule.RaiseTimeOff(request)
              .then(async (result: any | null) => {
                let data = result.result;
                
                if (result.success) {
              //     if (data.toLowerCase() === "success") {
              //       toast.success("Requested entry delete successfully.", {
              //         position: toast.POSITION.BOTTOM_RIGHT,
              //         containerId: "schedulePTOSlideOut",
              //       });
              //       this.getslideOut();
              //     } else if (data.toLowerCase() === "approved") {
              //       Utils.toastError("PTO has already been approved.", {
              //         position: toast.POSITION.BOTTOM_RIGHT,
              //         containerId: "schedulePTOSlideOut",
              //       });
              //     } else if (data.toLowerCase() === "declined") {
              //       Utils.toastError("PTO has already been declined.", {
              //         position: toast.POSITION.BOTTOM_RIGHT,
              //         containerId: "schedulePTOSlideOut",
              //       });
              //     } else {
              //       Utils.toastError(data, {
              //         position: toast.POSITION.BOTTOM_RIGHT,
              //         containerId: "schedulePTOSlideOut",
              //       });
              //     }
              //   }
              // })

              if (data.toLowerCase() === "success") {
                toast.success("Requested entry delete successfully.", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  containerId: "schedulePTOSlideOut",
                });
                this.getslideOut();
              } else if (data.toLowerCase() === "approved") {
                Utils.toastError("PTO has already been approved.", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  containerId: "schedulePTOSlideOut",
                });
              } else if (data.toLowerCase() === "declined") {
                Utils.toastError("PTO has already been declined.", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  containerId: "schedulePTOSlideOut",
                });
              } else {
                Utils.toastError(data, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  containerId: "schedulePTOSlideOut",
                });
              }
            }
          })             
              .catch((error) => {
                Utils.toastError(error.message, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  containerId: "schedulePTOSlideOut",
                });
              });
          },
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
    });
  };

  addNewEntry = () => {
    this.setState({
      showNewEntry: !this.state.showNewEntry, outSaveHandler: false,
      reEditPto: { reCalcStartDate: "", reCalcEndDate: "", inTime: "", outTime: "" },
      reEditPtoErr: { reCalcStartDate: false, reCalcEndDate: false, inTime: false, outTime: false },
      inOutPtoDuration: "",
      timeDuration: "",
    });
  };

  timeConvert = (n: any) => {
    var num: any = n;
    var hours: any = num / 60;
    var rhours: any = Math.floor(hours);
    var minutes: any = (hours - rhours) * 60;
    var rminutes: any =
      Math.round(minutes) < 10
        ? "0" + Math.round(minutes)
        : Math.round(minutes);
    let TotalHourTime: any = rhours + ":" + rminutes;
    return TotalHourTime;
  };

  reEditTime = (e: any) => {
    let { name, value } = e.target;
    let { reEditPto } = this.state;
    name === "reCalcStartDate"
      ? (reEditPto[name] = value
        .replace(/^(\d\d)(\d)$/g, "$1/$2")
        .replace(/^(\d\d\/\d\d)(\d+)$/g, "$1/$2")
        .replace(/[^\d/]/g, ""))
      : (reEditPto[name] = value);

     // if (name === "outTime") {
        let startdate1 = reEditPto.reCalcStartDate;
  
        let startTime1 = reEditPto.inTime;
        let endTime1 = reEditPto.outTime;
  
        let startdate2 = moment(startdate1).format("MM/DD/YYYY");
        let inMomentTime = moment(
          `${startdate2} ${startTime1}`
        );
        let outMomentTime = moment(
          `${startdate2} ${endTime1}`
        );
  
        let differenceInTime = outMomentTime.diff(inMomentTime);
  
        let punchOutDate = moment(startdate2).format("MM/DD/YYYY");
        if (differenceInTime <= 0) {
          punchOutDate = moment(startdate2)
            .add(1, "days")
            .format("MM/DD/YYYY");
        }
  
        reEditPto["reCalcEndDate"] = punchOutDate;
  
     // }


    this.setState({ reEditPto });
    this.setState({ outSaveHandler: false });
  };

  handleSelectDate = (event: any, dateType: any) => {
    let { reEditPto } = this.state;
    reEditPto[dateType] = event;
    if (dateType === "reCalcStartDate") {
      reEditPto.reCalcEndDate = ''
      this.claculateEndDate();
      this.handleFormValidation();
    }
    if (dateType === "reCalcEndDate") {
      reEditPto.outTime = ''
    }
    this.setState({ inOutPtoDuration: '', timeDuration: '', reEditPto, outSaveHandler: false });
    $(".schduleInput").attr("maxlength", "10");
  };

  claculateEndDate=()=>{
    let { reEditPto } = this.state;
    let startdate1 = reEditPto.reCalcStartDate;

    let startTime1 = reEditPto.inTime;
    let endTime1 = reEditPto.outTime;

    let startdate2 = moment(startdate1).format("MM/DD/YYYY");
    let inMomentTime = moment(
      `${startdate2} ${startTime1}`
    );
    let outMomentTime = moment(
      `${startdate2} ${endTime1}`
    );

    let differenceInTime = outMomentTime.diff(inMomentTime);

    let punchOutDate = moment(startdate2).format("MM/DD/YYYY");
    if (differenceInTime <= 0) {
      punchOutDate = moment(startdate2)
        .add(1, "days")
        .format("MM/DD/YYYY");
    }

    reEditPto["reCalcEndDate"] = punchOutDate;
  this.setState({ reEditPto });
  }


  timeDiffCalc = (startDateTime, endDateTime) => {
    let dateTime = endDateTime - startDateTime;
    let min = Math.floor(dateTime / 1000 / 60);
    return min;
  }

  mintoHour = (mins) => {
    let h = Math.floor(mins / 60);
    let m = mins % 60;
    let hour = h;// < 10 ? '0' + h : h
    let minute = m < 10 ? '0' + m : m;
    return `${hour}:${minute}`;
  }


  cheackOverlap = (startDateTime, endDateTime) => {
   
    //let { tableData } = this.state;
    let isValidate = true;
    let tableData = [...this.state.rightPanelRowData.rightPanelRowBottom[0].timeOffRequestDateTime];
    //tableData.splice(index, 1);

    for (let i = 0; i < tableData.length; i++) {


      let srartdateA: any = new Date(new Date(tableData[i].startDate).toLocaleDateString("en-US") + " " + tableData[i].inTime);
      let endDateA: any = new Date(new Date(tableData[i].endDate).toLocaleDateString("en-US") + " " + tableData[i].outTime);


      let startDateTimeB: any = new Date(startDateTime);
      let endDateTimeB: any = new Date(endDateTime);
      if (srartdateA < endDateTimeB && endDateA > startDateTimeB) {
        isValidate = false;
        break;
      } 
    }


    return isValidate;


  }

  cheackOverlapEdit = (startDateTime, endDateTime,row) => {
    debugger;
    //let { tableData } = this.state;
    let isValidate = true;
    let tableData = [...this.state.rightPanelRowData.rightPanelRowBottom[0].timeOffRequestDateTime];
   let rowIndex = tableData.findIndex(
      (xvalue) => xvalue.requestID === row.requestID
    );
   tableData.splice(rowIndex,1);
    for (let i = 0; i < tableData.length; i++) {


      let srartdateA: any = new Date(new Date(tableData[i].startDate).toLocaleDateString("en-US") + " " + tableData[i].inTime);
      let endDateA: any = new Date(new Date(tableData[i].endDate).toLocaleDateString("en-US") + " " + tableData[i].outTime);


      let startDateTimeB: any = new Date(startDateTime);
      let endDateTimeB: any = new Date(endDateTime);
      if (srartdateA < endDateTimeB && endDateA > startDateTimeB) {
        isValidate = false;
        break;
      } 
    }


    return isValidate;


  }

  handleFormValidation = () => {

    let rightPanelScheduleType="";
    const { rightPanelRowTop  } = this.state.rightPanelRowData;
    if (
      typeof rightPanelRowTop !== "undefined" &&
      rightPanelRowTop.length > 0
    ) {     
      rightPanelScheduleType = rightPanelRowTop[0].scheduleType;
    }

    let { reEditPto, reEditPtoErr, rightPanelRowData } = this.state;
    let { reCalcStartDate, reCalcEndDate, inTime, outTime } = reEditPto;
    var date_regex1 = /^([1-9]|1[0-2])\/([1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
    var date_regex2 = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}/;
    if (
      reCalcStartDate !== "Invalid Date" &&
      reCalcStartDate !== "" &&
      reCalcStartDate !== "1/1/1970" &&
      inTime !== "" &&
      reCalcEndDate !== "Invalid Date" &&
      reCalcEndDate !== "" &&
      reCalcEndDate !== "1/1/1970" &&
      outTime !== ""
    ) {
      reEditPtoErr.reCalcStartDate = false;
      reEditPtoErr.reCalcEndDate = false;
      reEditPtoErr.inTime = false;
      reEditPtoErr.outTime = false;
      if (
        !date_regex1.test(reCalcStartDate.toLocaleDateString("en-US")) &&
        !date_regex2.test(reCalcStartDate.toLocaleDateString("en-US")) &&
        !date_regex1.test(reCalcEndDate.toLocaleDateString("en-US")) &&
        !date_regex2.test(reCalcEndDate.toLocaleDateString("en-US"))) {
        Utils.toastError("Please provide date in mm/dd/yyyy format", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "timeoffRequest",
        });
      } else {
        let startdateTime = new Date(new Date(reCalcStartDate).toLocaleDateString("en-US") + " " + inTime);
        let endDateTime = new Date(new Date(reCalcEndDate).toLocaleDateString("en-US") + " " + outTime);


       
        const totalMinutes = this.timeDiffCalc(startdateTime, endDateTime);
        if (totalMinutes < 0) {
          Utils.toastError("Start Time can't be greater than End Time.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "timeoffRequest",
          });
          reEditPtoErr.inTime = true;
          reEditPtoErr.outTime = true;
          // return;
        }
        if (totalMinutes === 0) {
          Utils.toastError("Start Time can't be same as End Time.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "timeoffRequest",
          });
          reEditPtoErr.inTime = true;
          reEditPtoErr.outTime = true;
          //return;
        }
        if (totalMinutes > 480 &&  rightPanelScheduleType!=="10") {
          Utils.toastError("Request for a day can't be greater than 8:00 hours.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "scheduleMain",
          });
          reEditPtoErr.inTime = true;
          reEditPtoErr.outTime = true;
          //return;
        }

        // let isOverlap=  this.cheackOverlap(startdateTime, endDateTime);
        // if (isOverlap ===false) {
        //   Utils.toastError("Time of request is overlapping.", {
        //     position: toast.POSITION.BOTTOM_RIGHT,
        //     containerId: "scheduleMain",
        //   });
        //   reEditPtoErr.inTime = true;
        //   reEditPtoErr.outTime = true;
        //   // return;
        //   isOverlapCheck="false";
        // }
        
        this.setState({ inOutPtoDuration: totalMinutes, timeDuration: this.mintoHour(totalMinutes)})
      }
    } else {
      if (reCalcStartDate !== "" && reCalcStartDate !== "Invalid Date" && reCalcStartDate !== "1/1/1970") {
        let ptoStartDate = reCalcStartDate.toLocaleDateString("en-US");
        let day = ptoStartDate.split("/")[0] < 10 ? "0" + ptoStartDate.split("/")[0] : ptoStartDate.split("/")[0];
        let month = ptoStartDate.split("/")[1] < 10 ? "0" + ptoStartDate.split("/")[1] : ptoStartDate.split("/")[1];
        let year = ptoStartDate.split("/")[2];
        let newCalcDate = day + "/" + month + "/" + year;
        let x = rightPanelRowData.rightPanelRowBottom[0].timeOffRequestDateTime.filter((items: { startDate: string }) => items.startDate === newCalcDate);
        // if (x.length > 0) {
        //   Utils.toastError("Date already exist.", {
        //     position: toast.POSITION.BOTTOM_RIGHT,
        //     containerId: "timeoffRequest",
        //   });
        //   reEditPtoErr.reCalcStartDate = true;
        //   return;
        // }
        reEditPtoErr.reCalcStartDate = false;
      } else {
        reEditPtoErr.reCalcStartDate = true;
        return
      }
      if (inTime === "") {
        reEditPtoErr.inTime = true;
        return;
      } else {
        reEditPtoErr.inTime = false;
      }
      if (reCalcEndDate !== "" && reCalcEndDate !== "Invalid Date" && reCalcEndDate !== "1/1/1970") {
        reEditPtoErr.reCalcEndDate = false;
      } else {
        reEditPtoErr.reCalcEndDate = true;
        return;
      }
      if (outTime === "") {
        reEditPtoErr.outTime = true;
        return;
      } else {
        reEditPtoErr.outTime = false;
      }
    }
    this.setState({ reEditPtoErr })
  }

  submitNewPTOEntry = (e: any) => {
    
    e.preventDefault();
   
    

    let { reEditPto, PTORequestID, reEditPtoErr, IsPTOID, navigationCalenderDates, hidValue, inOutPtoDuration } = this.state;

    let startdateTime = new Date(new Date(reEditPto.reCalcStartDate).toLocaleDateString("en-US") + " " + reEditPto.inTime);
    let endDateTime = new Date(new Date(reEditPto.reCalcEndDate).toLocaleDateString("en-US") + " " + reEditPto.outTime);

    let isOverlap=  this.cheackOverlap(startdateTime, endDateTime);
    if (isOverlap ===false) {
      Utils.toastError("Time of request is overlapping.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "scheduleMain",
      });
      reEditPtoErr.inTime = true;
      reEditPtoErr.outTime = true;
       return;
      
    }


    let storage = JSON.parse(localStorage.getItem("storage")!);
    reEditPto.reCalcStartDate === "" && (reEditPtoErr.reCalcStartDate = true);
    this.setState({ reEditPtoErr })
    let calcStartDate = new Date(reEditPto.reCalcStartDate).toLocaleDateString("en-US");
    let calcEndDate = new Date(reEditPto.reCalcEndDate).toLocaleDateString("en-US");
    let inTime = reEditPto.inTime;
    let outTime = reEditPto.outTime;
    let ptoReqId = parseInt(PTORequestID);

    let rightPanelScheduleType="";
    const { rightPanelRowTop  } = this.state.rightPanelRowData;
    if (
      typeof rightPanelRowTop !== "undefined" &&
      rightPanelRowTop.length > 0
    ) {     
      rightPanelScheduleType = rightPanelRowTop[0].scheduleType;
    }



    if (rightPanelScheduleType==="10" || (inOutPtoDuration !== "" && (inOutPtoDuration > 0 && inOutPtoDuration < 481))) {
      let request: any[] = [];
      let newItem: any = {
        HotelID: hidValue,
        InTime: this.formatDate(inTime),
        OutTime: this.formatDate(outTime),
        IsPTOID: IsPTOID,
        LoginUser: storage.userName,
        PTODate: calcStartDate,
        StartDate: calcStartDate,
        EndDate: calcEndDate,
        PTORequestID: ptoReqId,
        RequestID: 0,
        TenantID: storage.tenantID,
        Type: "Add",
        WeekStartDate: navigationCalenderDates[0],
        WeekEndDate: navigationCalenderDates[1],
        Status: '',
        pageSource: "SchedulePTOSlideOut"
      }
      debugger;
      request.push(newItem);
      this.setState({ outSaveHandler: true });
      //LabourTimeOffUpdatePTORequestEntries.ApprovePTORequestEntries(request)
      LaborSchedule.RaiseTimeOff(request)
        .then(async (result: any | null) => {
          let data = result.result;
          if (result.success) {
            if (data.toLowerCase() === "success") {
              toast.success("Requested entry added successfully.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "schedulePTOSlideOut",
              });
              isOverlapCheck="";
              this.setState({ reCalcStartDate: "", reCalcEndDate: "", inTime: "", outTime: "" });
              this.setState({
                showNewEntry: false,
                inOutPtoDuration: "",
                timeDuration: "",
              });
              this.getslideOut();
            } else if (data.toLowerCase() === "approved") {
              Utils.toastError("PTO has already been approved.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "schedulePTOSlideOut",
              });
            } else if (data.toLowerCase() === "declined") {
              Utils.toastError("PTO has already been declined.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "schedulePTOSlideOut",
              });
            } else {
              this.setState({ outSaveHandler: false });
              Utils.toastError(data, {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "schedulePTOSlideOut",
              });
            }
          } else {
          }
        })
        .catch((error) => {
          Utils.toastError(error.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "schedulePTOSlideOut",
          });
        });
    }
  };

  clearDeclinedPanel = () => {
    this.setState({ dsValue: "", dsError: "" });
  };
  clearApprovedPanel = () => {
    this.setState({ asValue: "", asError: "" });
  };

  closeSlideOut = () => {
    this.props.closeSlideOut();
    this.setState({
      IsPTOID: "0",
      PTORequestID: this.props.parentState.openSlideOutdata.schedule.uniqueno,
    });
  };

  setRequestName = (scheduleType) => {
    let label = "";
    if (scheduleType === "3") {
      label = "PTO Request";
    } else if (scheduleType === "4") {
      label = "PTO Approved";
    } else if (scheduleType === "5") {
      label = "UTO Request";
    } else if (scheduleType === "6") {
      label = "PTO";
    } else if (scheduleType === "8") {
      label = "OFF";
    } else if (scheduleType === "10") {
      label = "DNS Request";
    }
    return <div>{label}</div>;
  };

  render() {
    const { hotelName, slideoutPTOTitle, employeeName, employeeImage, reEditPtoErr, reEditPto, inOutPtoDuration, timeDuration } = this.state;
    const { rightPanelRowTop, rightPanelRowBottom, } = this.state.rightPanelRowData;
    let empTitle: any;
    let ptoType: any;
    let ptoRequestDate: any;
    let rightPanelScheduleType;
    let rightPanelPTPStatus: any;
    if (typeof rightPanelRowBottom !== "undefined" && rightPanelRowBottom.length > 0) {
      ptoType = rightPanelRowBottom[0].ptoType;
      ptoRequestDate = rightPanelRowBottom[0].ptoRequestDate;
    }
    if (
      typeof rightPanelRowTop !== "undefined" &&
      rightPanelRowTop.length > 0
    ) {
      rightPanelPTPStatus = rightPanelRowTop[0].ptoStatus;
      empTitle = rightPanelRowTop[0].empTitle;
      rightPanelScheduleType = rightPanelRowTop[0].scheduleType;
    }
    let isPermissionforEdit = false;
    if (rightPanelScheduleType === "3" || rightPanelScheduleType === "4" || rightPanelScheduleType === "5" || rightPanelScheduleType === "6") {
      if (this.state.permissionManageTimeOff === true) {
        isPermissionforEdit = true;
      }
      else {
        isPermissionforEdit = false;
      }

    }
    else if (rightPanelScheduleType === "10") {
      if (this.state.permissionManageSchedule === true) {
        isPermissionforEdit = true;
      }
      else {
        isPermissionforEdit = false;
      }
    }

    return (
      <>
        <div className="pos-fxd index-1010">
          <ToastContainer autoClose={3000} containerId="schedulePTOSlideOut" />
          <div className="d-flex">
            <div
              className="backDrop back-drop"
              onClick={this.closeSlideOut}
            ></div>
            <Container
              fluid
              className="body-sec performance-position timesheet-details justify-content-end rm-padding"
            >
              <div className="stickey">
                <div className="page-heading underline d-flex">
                  <div className="hid-set d-flex align-items-center">
                    {hotelName}
                  </div>
                  <div className="mr-auto d-flex align-items-center">
                    {slideoutPTOTitle}
                  </div>
                  <div className="action-group d-flex flex-row">
                    <button
                      type="button"
                      className="btn wht-bg cross"
                      onClick={this.closeSlideOut}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                          fill="#84888C"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>

              <div className="add-padding overFlow-hide">
                <div className="PTOp28 userPTOprofile">
                  <div className="d-flex">
                    <div className="profileImg">
                      <img src={employeeImage} alt="user profile" />
                    </div>
                    <div className="details">
                      <div className="name">{employeeName}</div>
                      {rightPanelRowTop.length === 0
                        ? ""
                        : rightPanelRowTop.map(
                          (
                            items: any,
                            index: string | number | null | undefined
                          ) => (
                            <div key={index} className="designation">
                              {items.empTitle}
                            </div>
                          )
                        )}
                    </div>
                  </div>
                </div>

                <div className="border rounded">
                  <div className="ptoHasPadding">
                    <div className="userPTOprofile">
                      <div className="d-flex">
                        <React.Fragment>
                          {rightPanelPTPStatus === "Approved" && (
                            <div className="userStatus statusApproved">
                              <svg
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth="0"
                                viewBox="0 0 16 16"
                                height="24"
                                width="24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M13.854 3.646a.5.5 0 010 .708l-7 7a.5.5 0 01-.708 0l-3.5-3.5a.5.5 0 11.708-.708L6.5 10.293l6.646-6.647a.5.5 0 01.708 0z"
                                  clipRule="evenodd"
                                  fill="#2caf92"
                                ></path>
                              </svg>
                            </div>
                          )}
                        </React.Fragment>
                        <div className="details">
                          {/* {if(rightPanelScheduleType === "3"){} } */}
                          <div className="header">
                            {this.setRequestName(rightPanelScheduleType)}
                          </div>
                          <div className="designation">
                            {rightPanelRowBottom.length === 0 ? (
                              <strong>Loading...</strong>
                            ) : (
                              <React.Fragment>
                                {ptoType} {this.singleDotSVG()} Submitted{" "}
                                {ptoRequestDate}
                              </React.Fragment>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="PTObtnGroup">
                        <ButtonGroup aria-label="userapprove">
                          {rightPanelRowBottom.length === 0 ? (
                            ""
                          ) : (
                            <React.Fragment>
                              {rightPanelPTPStatus === "Approved" || rightPanelPTPStatus === "Declined" ? ("") : rightPanelRowBottom[0].postApprovalBalance
                                .split(" ")[0]
                                .split(":")[0]
                                .includes("-") ? (
                                <React.Fragment>
                                  <OutsideClickHandler onOutsideClick={this.clearDeclinedPanel}>{this.declinedPanel()}</OutsideClickHandler>
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <OutsideClickHandler onOutsideClick={this.clearDeclinedPanel}>{this.declinedPanel()}</OutsideClickHandler>
                                  {(rightPanelRowBottom[0].overlapData === "" || rightPanelRowBottom[0].overlapData.toLowerCase() === "schedule") &&
                                    (rightPanelRowBottom[0].overlapType === "" || rightPanelRowBottom[0].overlapType.toLowerCase() === "schedule")
                                    ? (
                                      <OutsideClickHandler onOutsideClick={this.clearApprovedPanel}>{this.approvedPanel()}</OutsideClickHandler>
                                    ) : ("")}

                                </React.Fragment>
                              )}
                            </React.Fragment>
                          )}
                        </ButtonGroup>
                      </div>
                    </div>
                  </div>

                  <div className="ptoDetails">
                    <div className="ptoHasPadding">

                      <>{rightPanelRowBottom.length === 0
                        ? ("")
                        : rightPanelRowBottom.map(
                          (
                            items: any,
                            index: string | number | null | undefined
                          ) => (
                            <React.Fragment key={index}>
                              <div className="ptoFormGroup mb-0 row">
                                <label className="col-sm-3 timeoffLabel">
                                  Reason for {ptoType}
                                </label>
                                <div className="col-sm-9">
                                  <div className="form-control-plaintext fortwoline">
                                    {/* <span className="firstLine">{items.ptoSubmitted}</span> */}
                                    <span className="secondLine">
                                      {items.ptoReason}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              {rightPanelScheduleType === "3" || rightPanelScheduleType === "4" ? (
                                <div>
                                  {rightPanelPTPStatus === "Approved" ||
                                    rightPanelPTPStatus ===
                                    "Declined" ? null : (
                                    <React.Fragment>
                                      <div className="ptoFormGroup mb-0 row">
                                        <label className="col-sm-3 timeoffLabel">
                                          PTO Balance
                                        </label>
                                        <div className="col-sm-9">
                                          <div className="form-control-plaintext fortwoline">
                                            <strong className="secondLine">
                                              <div className="cusptoflexPanel">
                                                <div className="cusflexgrid">
                                                  <div className="col2 forTextBtn">
                                                    <span className="forbtn">
                                                      {items.ptoBalance.replace(
                                                        /:/g,
                                                        "."
                                                      )}
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </strong>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="ptoFormGroup mb-0 row">
                                        <label className="col-sm-3 timeoffLabel">
                                          Post Approval Balance
                                        </label>
                                        <div className="col-sm-9">
                                          <div className="form-control-plaintext fortwoline">
                                            <strong className="secondLine">
                                              <div className="cusptoflexPanel">
                                                <div className="cusflexgrid">
                                                  <div className="col2 forTextBtn">
                                                    {items.postApprovalBalance
                                                      .split(" ")[0]
                                                      .split(":")[0]
                                                      .includes("-") ? (
                                                      <span className="cusDangerBtn">
                                                        {items.postApprovalBalance.replace(
                                                          /:/g,
                                                          "."
                                                        )}
                                                      </span>
                                                    ) : (
                                                      <span className="cusSuccessBtn">
                                                        {items.postApprovalBalance.replace(
                                                          /:/g,
                                                          "."
                                                        )}
                                                      </span>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </strong>
                                          </div>
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  )}
                                </div>
                              ) : null}
                            </React.Fragment>
                          )
                        )}</>

                    </div>
                  </div>

                  {rightPanelRowBottom.length === 0 ? (
                    ""
                  ) : (
                    <div className="ptoDetails">
                      <div className="ptoHasPadding">
                        <div className="ptoFormGroup row mb-2">
                          <label className="timeoffLabel labelheader">
                            Request Entries
                          </label>
                        </div>
                        <div className="cusPTOGridTable">
                          <Table responsive className="tbl1">
                            {rightPanelRowBottom.length === 0 ? (
                              ""
                            ) : (
                              <thead>
                                <tr className="bg-light">
                                  <th style={{ width: isPermissionforEdit === true ? "15%" : "30%" }}>Start Date</th>
                                  <th style={{ width: isPermissionforEdit === true ? "15%" : "30%" }} className={
                                    rightPanelPTPStatus === "Approved" ||
                                      rightPanelPTPStatus === "Declined"
                                      ? ""
                                      : "pl-4"
                                  }
                                  >
                                    Start Time
                                  </th>
                                  {/* <th style={{ width: "15%" }}>End Date</th> */}
                                  <th
                                    style={{ width: isPermissionforEdit === true ? "15%" : "30%" }}
                                    className={
                                      rightPanelPTPStatus === "Approved" ||
                                        rightPanelPTPStatus === "Declined"
                                        ? ""
                                        : "pl-4"
                                    }
                                  >
                                    End Time
                                  </th>
                                  <th style={{ width: isPermissionforEdit === true ? "35%" : "30%" }}>Duration</th>
                                  <th style={{ width: "20%" }}></th>
                                </tr>
                              </thead>
                            )}
                            {rightPanelRowBottom.length === 0
                              ? ""
                              : rightPanelRowBottom.map(
                                (
                                  items: { timeOffRequestDateTime: any[] },
                                  index: string | number | null | undefined
                                ) => (
                                  <React.Fragment key={index}>
                                    {items.timeOffRequestDateTime.map(
                                      (
                                        tOffRDT: any,
                                        index:
                                          | string
                                          | number
                                          | null
                                          | undefined
                                      ) => (
                                        <tbody key={index}>
                                          <tr>
                                            <td className="cusScheduleGridTable">
                                              <div className="tableLockSymbol">
                                                <span>
                                                  {tOffRDT.startDate}
                                                </span>
                                                {tOffRDT.isDeptApprove.toLowerCase() ===
                                                  "yes" ||
                                                  tOffRDT.isDayApprove.toLowerCase() ===
                                                  "yes" ? (
                                                  <img
                                                    src={ForecastLock}
                                                    alt="isDayApprove"
                                                  />
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            </td>
                                            <td
                                              className={
                                                rightPanelPTPStatus ===
                                                  "Approved" ||
                                                  rightPanelPTPStatus ===
                                                  "Declined"
                                                  ? ""
                                                  : "ptoTdNoPadd"
                                              }
                                              style={{ paddingLeft: isPermissionforEdit === true ? "" : "2.20%" }}
                                            >
                                              {rightPanelPTPStatus === "Approved" || rightPanelPTPStatus === "Declined" ? (tOffRDT.inTime) : (
                                                <div>{isPermissionforEdit === true ?
                                                  <Dropdown
                                                    alignRight
                                                    className="tableHasDropdown"
                                                  >
                                                    <Dropdown.Toggle
                                                      className="btnHasNoPadd cusScheduleGridTable"
                                                      id={
                                                        "inTime_" +
                                                        tOffRDT.requestID
                                                      }
                                                    >
                                                      <div
                                                        className="btnHandler"
                                                        onClick={() =>
                                                          this.resetPopupData(
                                                            tOffRDT
                                                          )
                                                        }
                                                      >
                                                        {tOffRDT.inTime}
                                                      </div>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                      <form
                                                        onSubmit={(e) =>
                                                          this.editPTORequestSubmit(
                                                            e,
                                                            tOffRDT
                                                          )
                                                        }
                                                      >
                                                        <div className="dropDownHasForm">
                                                          <div className="dropDownHeader">
                                                            <label>
                                                              Edit Time
                                                            </label>
                                                            <Dropdown.Item className="xClose">
                                                              {this.croseSVG()}
                                                            </Dropdown.Item>
                                                          </div>
                                                          <div className="dropDownBody">
                                                            <strong className="schduleInput">
                                                              Start Time
                                                            </strong>
                                                            <input
                                                              type="time"
                                                              name="startTime"
                                                              value={
                                                                this.state
                                                                  .edtPTOstartTime
                                                              }
                                                              onChange={
                                                                this
                                                                  .editPTOStarttime
                                                              }
                                                              className="form-control schduleInput"
                                                              required
                                                            />
                                                          </div>
                                                          <div className="dropDownFooter">
                                                            <Button type="submit">
                                                              Save
                                                            </Button>
                                                          </div>
                                                        </div>
                                                      </form>
                                                    </Dropdown.Menu>
                                                  </Dropdown>
                                                  : <div style={{ paddingTop: 12 }}>{tOffRDT.inTime}</div>}</div>
                                              )}
                                            </td>
                                            {/* <td className="cusScheduleGridTable">
                                              <div className="tableLockSymbol">
                                                <span>
                                                  {tOffRDT.endDate}
                                                </span>
                                              </div>
                                            </td> */}
                                            <td
                                              className={
                                                rightPanelPTPStatus === "Approved" || rightPanelPTPStatus === "Declined" ? "" : "ptoTdNoPadd"}
                                              style={{ paddingLeft: isPermissionforEdit === true ? "" : "2.20%" }}>
                                              {rightPanelPTPStatus === "Approved" || rightPanelPTPStatus === "Declined" ? (tOffRDT.outTime) : (
                                                <div>{isPermissionforEdit === true ?
                                                  <Dropdown
                                                    alignRight
                                                    className="tableHasDropdown"
                                                  >
                                                    <Dropdown.Toggle
                                                      className="btnHasNoPadd cusScheduleGridTable"
                                                      id={
                                                        "endTime_" +
                                                        tOffRDT.requestID
                                                      }
                                                    >
                                                      <div
                                                        className="btnHandler"
                                                        onClick={() =>
                                                          this.resetPopupData(
                                                            tOffRDT
                                                          )
                                                        }
                                                      >
                                                        {tOffRDT.outTime}
                                                      </div>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                      <form
                                                        onSubmit={(e) =>
                                                          this.editPTORequestSubmit(
                                                            e,
                                                            tOffRDT
                                                          )
                                                        }
                                                      >
                                                        <div className="dropDownHasForm">
                                                          <div className="dropDownHeader">
                                                            <label>
                                                              Edit Time
                                                            </label>
                                                            <Dropdown.Item className="xClose">
                                                              {this.croseSVG()}
                                                            </Dropdown.Item>
                                                          </div>
                                                          <div className="dropDownBody">
                                                            <strong className="schduleInput">
                                                              End Time
                                                            </strong>
                                                            <input
                                                              type="time"
                                                              name="endTime"
                                                              value={
                                                                this.state
                                                                  .edtPTOendTime
                                                              }
                                                              onChange={
                                                                this
                                                                  .editPTOEndtime
                                                              }
                                                              className="form-control schduleInput"
                                                              required
                                                            />
                                                          </div>
                                                          <div className="dropDownFooter">
                                                            <Button type="submit">
                                                              Save
                                                            </Button>
                                                          </div>
                                                        </div>
                                                      </form>
                                                    </Dropdown.Menu>
                                                  </Dropdown>
                                                  : <div style={{ paddingTop: 12 }}>{tOffRDT.outTime}</div>}</div>
                                              )}
                                            </td>
                                            <td className="cusScheduleGridTable" style={{ paddingLeft: isPermissionforEdit === true ? "" : "1%" }}>
                                              {tOffRDT.dayDuration}
                                            </td>
                                            {isPermissionforEdit && (
                                              <td className="ptoTdNoPadd text-right">
                                                {rightPanelPTPStatus ===
                                                  "Approved" ||
                                                  rightPanelPTPStatus ===
                                                  "Declined" ? null : (
                                                  <React.Fragment>
                                                    {items
                                                      .timeOffRequestDateTime
                                                      .length < 2 ? null : (
                                                      <Dropdown
                                                        alignRight
                                                        className="tableHasDropdown tableRightDropdown"
                                                      >
                                                        <Dropdown.Toggle
                                                          className="btn btn-primary"
                                                          id="dropdown-more"
                                                        >
                                                          {this.dotsSVG()}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                          <Dropdown.Item
                                                            onClick={() =>
                                                              this.RemovePTOEntry(
                                                                tOffRDT
                                                              )
                                                            }
                                                          >
                                                            Remove Entry
                                                          </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                      </Dropdown>
                                                    )}
                                                  </React.Fragment>
                                                )}
                                              </td>
                                            )}
                                          </tr>
                                        </tbody>
                                      )
                                    )}
                                  </React.Fragment>
                                )
                              )}
                          </Table>

                          {this.state.showNewEntry ? (
                            <OutsideClickHandler
                              disabled={this.state.outSaveHandler}
                              onOutsideClick={this.submitNewPTOEntry}
                            >
                              <Form
                                autoComplete="Off"
                                onSubmit={this.submitNewPTOEntry}
                              >
                                <Table>
                                  <tbody>
                                    <tr>
                                      <td style={{ width: "15%" }} className="ptoTdHasPadd">
                                        <div className="date-picker scheduleptoDatePicker">
                                          <DatePicker
                                            name="reCalcStartDate"
                                            value={reEditPto.reCalcStartDate}
                                            className={`form-control schduleInput ${reEditPtoErr.reCalcStartDate ? "ptoFormHasError" : ""}`}
                                            id="reCalcStartDate"
                                            selected={reEditPto.reCalcStartDate}
                                            onSelect={(e: any) => this.handleSelectDate(e, "reCalcStartDate")}
                                            //onChange={(e: any) => this.handleSelectDate(e, "reCalcStartDate")}
                                            placeholderText="mm/dd/yyyy"
                                            required
                                            autoComplete="off"
                                          />
                                        </div>
                                      </td>
                                      <td style={{ width: "15%" }} className="ptoTdHasPadd">
                                        <Form.Control
                                          type="time"
                                          name="inTime"
                                          value={reEditPto.inTime}
                                          className={`schduleInput ${reEditPtoErr.inTime ? "ptoFormHasError" : ""}`}
                                          onChange={this.reEditTime}
                                          onBlur={this.handleFormValidation}
                                          required
                                          disabled={
                                            this.state.reEditPto.reCalcStartDate === "" ||
                                            reEditPtoErr.reCalcStartDate
                                          }
                                        />
                                      </td>
                                      {/* <td style={{ width: "15%" }} className="ptoTdHasPadd">
                                        <div className="date-picker scheduleptoDatePicker">
                                          <DatePicker
                                            value={reEditPto.reCalcEndDate}
                                            name="reCalcEndDate"
                                            className={`form-control schduleInput ${reEditPtoErr.reCalcEndDate ? "ptoFormHasError" : ""}`}
                                            id="reCalcEndDate"
                                            minDate={reEditPto.reCalcStartDate}
                                            maxDate={new Date(new Date(this.state.reEditPto.reCalcStartDate).getTime() + 24 * 60 * 60 * 1000)}
                                            selected={this.state.reEditPto.reCalcEndDate}
                                            onSelect={(e: any) => this.handleSelectDate(e, "reCalcEndDate")}
                                            //onChange={(e: any) => this.handleSelectDate(e, "reCalcEndDate")}
                                            placeholderText="mm/dd/yyyy"
                                            required
                                            autoComplete="off"
                                            disabled={
                                              this.state.reEditPto.reCalcStartDate === "" ||
                                              this.state.reEditPto.inTime === "" ||
                                              reEditPtoErr.reCalcStartDate
                                            }
                                          />
                                        </div>
                                      </td> */}
                                      <td style={{ width: "15%" }} className="ptoTdHasPadd">
                                        <Form.Control
                                          type="time"
                                          name="outTime"
                                          value={reEditPto.outTime}
                                          onChange={this.reEditTime}
                                          onBlur={this.handleFormValidation}
                                          className={`schduleInput ${reEditPtoErr.outTime ? "ptoFormHasError" : ""}`}
                                          required
                                          disabled={
                                            this.state.reEditPto.reCalcStartDate === "" ||
                                            this.state.reEditPto.inTime === "" ||
                                            this.state.reEditPto.reCalcEndDate === "" ||
                                            reEditPtoErr.reCalcStartDate
                                          }
                                        />
                                      </td>
                                      <td style={{ width: "35%" }}>
                                        <span className={(rightPanelScheduleType !=="10" && (inOutPtoDuration < 1 || (inOutPtoDuration > 480))) ? "text-danger" : ""}>
                                          {timeDuration}
                                        </span>
                                      </td>
                                      <td
                                        style={{ width: "20%" }}
                                        className="text-right ptoTdHasPadd"
                                      >
                                        <div
                                          id="calcHide"
                                          className="tableHasDropdown tableRightDropdown dropdown"
                                        >
                                          &nbsp;
                                          <Button
                                            onClick={this.addNewEntry}
                                            title="Cancel"
                                          >
                                            <svg
                                              width="20"
                                              height="20"
                                              viewBox="0 0 20 20"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M13.4933 5.28662L9.95747 8.82162L6.42247 5.28662L5.24414 6.46495L8.77914 9.99995L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 9.99995L14.6716 6.46495L13.4933 5.28662Z"
                                                fill="#2caf92"
                                              ></path>
                                            </svg>
                                          </Button>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </Form>
                            </OutsideClickHandler>
                          ) : null}

                          {rightPanelRowBottom.length === 0 ? (
                            ""
                          ) : (
                            <Table>
                              {rightPanelRowBottom.map(
                                (
                                  items: { duration: string },
                                  index: string | number | null | undefined
                                ) => (
                                  <tfoot key={index}>
                                    <tr>
                                    <th style={{ width: "15%" }}>Totals</th>
                                    <th style={{ width: "15%" }}></th>
                                    <th style={{ width: "15%" }}></th>
                                      <th style={{ width: "35%" }}>
                                        {items.duration.split(" ")[0]}
                                      </th>
                                      <td style={{ width: "20%" }} className="text-right ptoTdNoPadd">
                                        {rightPanelPTPStatus === "Approved" ||
                                          rightPanelPTPStatus === "Declined" ? (
                                          ""
                                        ) : this.state.showNewEntry ? null : (
                                          isPermissionforEdit === true ?
                                            <Button
                                              onClick={this.addNewEntry}
                                              className="btnAddEntry"
                                            >
                                              Add Entry
                                            </Button> : null
                                        )}
                                      </td>
                                    </tr>
                                  </tfoot>
                                )
                              )}
                            </Table>
                          )}
                        </div>
                        {rightPanelPTPStatus === "Pending" && (<>
                          {typeof rightPanelRowBottom !== "undefined" && rightPanelRowBottom.length > 0 && (<>
                            <br />
                            {rightPanelRowBottom[0].overlapData.toLowerCase() === "actual" && rightPanelRowBottom[0].overlapType.toLowerCase() === "timeoff" ?
                              (<p className="hasoverlapMessage"><strong>*</strong> This request cannot be approved as entries for one or more days overlap with an approved time off.</p>) :
                              ("")}

                            {rightPanelRowBottom[0].overlapData.toLowerCase() === "actual" && rightPanelRowBottom[0].overlapType.toLowerCase() === "schedule" ?
                              (<p className="hasoverlapMessage"><strong>*</strong> This request cannot be approved as entries for one or more days overlap with actual hours worked.</p>) :
                              ("")}

                            {rightPanelRowBottom[0].overlapData.toLowerCase() === "schedule" && rightPanelRowBottom[0].overlapType.toLowerCase() === "timeoff" ?
                              (<p className="hasoverlapMessage"><strong>*</strong> This request cannot be approved as entries for one or more days overlap with an approved time off.</p>) :
                              ("")}

                          </>)}
                        </>)}
                      </div>
                      {rightPanelRowBottom.length === 0
                        ? ""
                        : rightPanelRowBottom.map(
                          (
                            items: {
                              imageURL: string | undefined;
                              managerComment: any;
                            },
                            index: string | number | null | undefined
                          ) => (
                            <div key={index}>
                              {rightPanelPTPStatus === "Pending" ||
                                rightPanelPTPStatus === "All" ? (
                                ""
                              ) : (
                                <div className="ptoDetails">
                                  <div className="ptoHasPadding">
                                    <div className="row">
                                      <div className="col-sm-12">
                                        <div className="border-line"></div>
                                      </div>
                                    </div>
                                    <div className="ptoFormGroup row mb-2">
                                      <label className="timeoffLabel labelheader">
                                        Comments
                                      </label>
                                    </div>
                                    <div className="d-flex ptoManagerComment">
                                      <div className="profile-img">
                                        {items.imageURL === "" ? (
                                          <img
                                            src="https://innflowfilestore1.blob.core.windows.net/userprofile/51/1843.jpg"
                                            alt="profile img"
                                          />
                                        ) : (
                                          <img
                                            src={items.imageURL}
                                            alt="profile img"
                                          />
                                        )}
                                      </div>
                                      <div className="details hasComments">
                                        <span className="comments">
                                          {items.managerComment === ""
                                            ? "Add Comments"
                                            : items.managerComment}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          )
                        )}
                    </div>
                  )}
                </div>
              </div>
            </Container>
          </div>

          <Modal
            show={this.state.modalShiftOverlaps}
            onHide={this.handleModalShiftClose}
            backdrop="static"
            keyboard={false}
            centered
          >
            <Modal.Header>
              <Modal.Title>Shift overlap</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="shiftTimeModal">
                {this.state.modalShiftOverlapsData.includes("<table>") ? (<>
                  <p><strong>This time off request overlaps with the following shifts:</strong></p>
                  <div className="table-responsive">
                    <div className="shiftTimeModalTable">
                      <table>
                        <tr>
                          <th>Position</th>
                          <th>Date</th>
                          <th>In Time</th>
                          <th>Out Time</th>
                        </tr>
                      </table>
                    </div>
                    <div className="shiftTimeModalTable shiftTimebodyTable" dangerouslySetInnerHTML={{ __html: this.state.modalShiftOverlapsData }}></div>
                  </div>
                  <p>Continuing will move these shifts to unassigned. Do you want to continue?</p>
                </>) : (
                  <p>{this.state.modalShiftOverlapsData}</p>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer className="text-center">
              {this.state.modalShiftOverlapsData.includes("<table>") ? (
                <>
                  <form onSubmit={(e) => this.approveSubmit(e, "Yes")}><Button className="btn-success" type="submit">Yes</Button></form>
                  <Button variant="danger" onClick={this.handleModalShiftClose}>No</Button>
                </>
              ) : (
                <Button onClick={this.handleModalShiftClose}>Ok</Button>
              )}
            </Modal.Footer>
          </Modal>


        </div>
      </>
    );
  }
}
