import Instense from "./Axios-config";
import {
  ILaborSmileIdRequestDto,
  ILaborSmileIdResponseDto,
  ILaborSmileIdDetailsResponseDto,
  ILaborSmileIdActionRequestDto,
  ILaborDeleteSmileIdPicRequestDto,
} from "../Contracts/ILaborDayApprove";

export class LaborSmileID {
  public static GetLaborSmileIdDetails = async (
    request: ILaborSmileIdRequestDto
  ): Promise<ILaborSmileIdResponseDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let userUniqueNo = storage === null ? 0 : (storage.user_UniqueID as any);
    request.tenantID = tenantID;
    request.userUniqueNo = userUniqueNo;
    const url = `/LaborDayApprove/GetLaborSmilIDAlretBoxDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as ILaborSmileIdResponseDto;
      return result;
    });
  };

  public static GetLaborSmileHidFailureDetails = async (
    request: ILaborSmileIdRequestDto
  ): Promise<ILaborSmileIdDetailsResponseDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let userUniqueNo = storage === null ? 0 : (storage.user_UniqueID as any);
    let rolId = storage === null ? 0 : (storage.rolId as any);
    request.tenantID = tenantID;
    request.userUniqueNo = userUniqueNo;
    request.LoginUserRoleID = rolId;
    const url = `/LaborDayApprove/GetLaborSmilIDHidDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as ILaborSmileIdDetailsResponseDto;
     
      return result;
    });
  };

  public static SimeIdAction = async (
    request: ILaborSmileIdActionRequestDto
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let userUniqueNo = storage === null ? 0 : (storage.user_UniqueID as any);
    request.tenantID = tenantID;
    request.userUniqueNo = userUniqueNo;
    const url = `/LaborDayApprove/SaveLaborSmileIDStatus`;
    const response = await Instense.post(url, request);
    let result = response.data.result as any;
    return result;
  };


  public static GetSimleIDProfile = async (request: any): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.TenantID = tenantID;
    const url = `/LaborTimeSheet/SimleIDProfile`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as any;
      return result;
    });
  };

  public static DeleteSmileIdProfilePic = async (
    request: ILaborDeleteSmileIdPicRequestDto
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
   // let userUniqueNo = storage === null ? 0 : (storage.user_UniqueID as any);
    request.tenantID = tenantID;
  
    const url = `/LaborTimeSheet/DeleteSmileIdProfilePic`;
    const response = await Instense.post(url, request);
    let result = response.data.result as any;
    return result;
  };



}
