import React from "react";
import { Dropdown, Modal, Button, Form, Alert } from "react-bootstrap";
import { IForecastImportTemplateRequestDto } from "../../../../../Common/Contracts/ILaborForecast";
import { LaborForecast } from "../../../../../Common/Services/LaborForecast";
import { resolve, reject } from "q";
import { MultiSelectDepartment } from "../components/MultiSelectDepartment";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import "react-toastify/dist/ReactToastify.css";
import {
  IScheduleForecastTempalteRequestDto,
  ILaborImportScheduleRequestDto,
  ILaborImportScheduleTemplateRequestDto,
} from "../../../../../Common/Contracts/ILaborForecast";
import { LaborBudgetSetting } from "../../../../../Common/Services/LaborBudgetSetting";
import { GetLaborScheduleTemplateRequest } from "../../../../../Common/Contracts/ILaborSchedule";
import { LaborSchedule } from "../../../../../Common/Services/LaborSchedule";
import { Utils } from "../../../../../Common/Utilis";

const lastWeekVarName: string = "Last Week's Schedule";
const lastTwoWeekVarName: string = "Last Two Week's Schedule";
export class SetForecastModal extends React.Component<any, any> {
  private isSetForcastScroll: any;
  constructor(props: any) {
    super(props);
    this.isSetForcastScroll = React.createRef();
    this.state = {
      selectedDepartmentID: [],
      loaderTrue: true,
      importForecastSelected: "Select",
      copyShcedule: false,
      importFromListActual: [],
      importFromListTemplate: [],
      copyDepartmentList: this.props.parentState.departmentList,
      copyUsers: true,
      copyUsersSelected: "Select",
      copyUsersList: [],
      copyShifts: true,
      copyShiftsSelected: "Select",
      copyShiftsList: [],
      copyShiftsAssignments: false,
      shiftsSelectionType: "",
      importFromListSelected: {},
      status: false,
      usersSelectionType: "",
    };
  }

  componentDidMount() {
    // 
    this.getForcastImportTemplateData();
    this.getTemplateList();
  }

  getTemplateList = () => {
    let requestObject = {} as GetLaborScheduleTemplateRequest;

    requestObject.hotelID = this.props.parentState.hidValue;

    LaborSchedule.GetLaborScheduleTemplates(requestObject)
      .then(async (result: any) => {
        if (result.message === "Success") {
          let apiResult = result.result;
          // 
          apiResult.forEach((data: any) => (data.listType = "Template"));

          let resultObject: any = {};
          resultObject.hid = this.props.parentState.hidValue;
          resultObject.id = -1;
          resultObject.listType = "Schedule";
          resultObject.message = null;
          resultObject.success = null;
          resultObject.templateName = "";
          resultObject.tenantID = 101;
          resultObject.type = null;

          let object1 = { ...resultObject };
          object1.templateName = lastWeekVarName;
          apiResult.push(object1);
          let object2 = { ...resultObject };
          object2.templateName = lastTwoWeekVarName;
          apiResult.push(object2);
          this.setState({
            copyUsersList: apiResult,
            copyShiftsList: apiResult,
          });
        } else {
          Utils.toastError(`${result.message}`, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "scheduleMain",
          });
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "scheduleMain",
        });
        reject();
      });
  };

  getForcastImportTemplateData = () => {
    let request = {} as IForecastImportTemplateRequestDto;
    request.hotelID = this.props.parentState.hidValue;
    LaborForecast.GetLaborForecastImportTemplateData(request)
      .then(async (result: any) => {
        if (result != null && result.length > 0) {
          let importFromListActual: any = [];
          let importFromListTemplate: any = [];
          for (let i = 0; i < result.length; i++) {
            if (result[i].type === "Actual") {
              importFromListActual.push(result[i]);
            } else {
              importFromListTemplate.push(result[i]);
            }
          }
          let storage = JSON.parse(localStorage.getItem("storage")!);
          let tenantID = storage === null ? 0 : (storage.tenantID as any);
         
          let newItem = { hid: this.props.parentState.hidValue,id:-1, name: "+ Create New Template",tenantID:Number(tenantID),type: "Template"};       
          importFromListTemplate.push(newItem)

          this.setState({
            importFromListActual: importFromListActual,
            importFromListTemplate: importFromListTemplate,
          });
        } else{
          let importFromListTemplate: any = [];
          let storage = JSON.parse(localStorage.getItem("storage")!);
          let tenantID = storage === null ? 0 : (storage.tenantID as any);
         
          let newItem = { hid: this.props.parentState.hidValue,id:-1, name: "+ Create New Template",tenantID:Number(tenantID),type: "Template"};       
          importFromListTemplate.push(newItem)
          this.setState({           
            importFromListTemplate: importFromListTemplate,
          });
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "scheduleMain",
        });
        reject();
      });
  };

  importForecastModalClose = () => {
    this.props.setForecastModalHide();
  };

  importFromListSelect = (item: any) => {
    let tamplateName = `${item.type}s, ${item.name}`;
    this.setState(
      {
        importForecastSelected: tamplateName,
        importFromListSelected: item,
      },
      () => {
        this.Isvalidate();
      }
    );
  };

  copyShifts = (item: any) => {
    this.setState(
      {
        copyShiftsSelected: item.templateName,
        shiftsSelectionType: item.listType,
      },
      () => {
        if (this.state.copyUsersSelected === this.state.copyShiftsSelected) {
          this.setState({ copyShiftsAssignments: true });
        }
        this.Isvalidate();
      }
    );
  };

  copyUsers = (item: any) => {
    this.setState(
      {
        copyUsersSelected: item.templateName,
        usersSelectionType: item.listType,
      },
      () => {
        if (this.state.copyUsersSelected === this.state.copyShiftsSelected) {
          this.setState({ copyShiftsAssignments: true });
        }
        this.Isvalidate();
      }
    );
  };

  copyScheduleToggle = () => {
    this.setState(
      {
        copyShcedule: !this.state.copyShcedule,
        copyUsers: true,
        copyShifts: true,
        copyUsersSelected: "Select",
        copyShiftsSelected: "Select",
      },
      () => {
        this.Isvalidate();
      }
    );
  };

  copyUsersToggle = () => {
    this.setState(
      {
        copyUsers: !this.state.copyUsers,
        copyUsersSelected: "Select",
      },
      () => {
        if (this.state.copyUsers && this.state.copyShifts) {
          this.setState({ copyShiftsAssignments: true });
        } else {
          this.setState({ copyShiftsAssignments: false });
        }
        this.Isvalidate();
      }
    );
  };

  copyShiftsToggle = () => {
    this.setState(
      {
        copyShifts: !this.state.copyShifts,
        copyShiftsSelected: "Select",
      },
      () => {
        if (this.state.copyUsers && this.state.copyShifts) {
          this.setState({ copyShiftsAssignments: true });
        } else {
          this.setState({ copyShiftsAssignments: false });
        }
        this.Isvalidate();
      }
    );
  };

  copyShiftsAssignmentsToggle = () => {
    this.setState({
      copyShiftsAssignments: !this.state.copyShiftsAssignments,
    });
  };

  Isvalidate() {
    // 
    if (
      this.state.importForecastSelected === "Select" ||
      this.state.importForecastSelected === ""
    ) {
      this.setState({ loaderTrue: true });
      return;
    }
    if (
      this.state.copyShcedule &&
      this.state.copyUsers === true &&
      this.state.copyUsersSelected === "Select"
    ) {
      this.setState({ loaderTrue: true });
      return;
    }
    if (
      this.state.copyShcedule &&
      this.state.copyShifts === true &&
      this.state.copyShiftsSelected === "Select"
    ) {
      this.setState({ loaderTrue: true });
      return;
    }
    // Checking if template is selected and if both of them are not same
    if (
      (this.state.usersSelectionType === "Template" ||
        this.state.shiftsSelectionType === "Template") &&
      this.state.usersSelectionType !== this.state.shiftsSelectionType &&
      this.state.copyShifts === true
    ) {
      this.setState({ loaderTrue: true });
      return;
    }
    this.setState({ loaderTrue: false });
  }

  setForecast = () => {
    // 
    if (
      this.state.importForecastSelected === "Select" ||
      this.state.importForecastSelected === ""
    ) {
      Utils.toastError("Please select actuals / templates to import a forecast", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "scheduleMain",
      });

      return;
    } else {
      this.setState({ loaderTrue: true });
      let request = {} as IScheduleForecastTempalteRequestDto;
      request.hotelID = this.props.parentState.hidValue;
      request.ImportType = this.state.importFromListSelected.type;
      request.PayperiodDate = this.props.parentState.navigationCalenderDates[0];
      if (this.state.importFromListSelected.type === "Actual") {
        let name = this.state.importFromListSelected.name;
        name = name.split("-");
        let tempImportTypeValue = name[0].substring(
          name[0].length - 9,
          name[0].length - 1
        );
        request.ImportTypeValue = tempImportTypeValue;
      } else {
        request.ImportTypeValue = this.state.importFromListSelected.name;
      }

      LaborForecast.ImportSchudleForecastTemplate(request)
        .then(async (result: any) => {
          // debugger
          if (result.message === "Success") {
            if (result.result.length > 0) {
              const newResult = result.result[0];
              if (newResult.message === "Incomplete") {
                this.setState({ status: true }, () => {
                  this.isSetForcastScroll.current.scrollIntoView();
                });
              } else {
                if (this.state.copyShcedule === true) {
                  if (
                    this.state.copyShcedule === true &&
                    this.state.selectedDepartmentID.length === 0
                  ) {
                    Utils.toastError("Please select department", {
                      // // position: toast.POSITION.BOTTOM_RIGHT,
                      // // containerId: "scheduleMain",
                    });
                    this.setState({ loaderTrue: false });
                    return;
                  }
                  if (
                    this.state.copyUsers === false &&
                    this.state.copyShifts === false
                  ) {
                    Utils.toastError("Please select copy users or copy shifts", {
                      // // position: toast.POSITION.BOTTOM_RIGHT,
                      // // containerId: "scheduleMain",
                    });
                    this.setState({ loaderTrue: false });
                    return;
                  }

                  let laborImportRequest = {} as ILaborImportScheduleRequestDto;
                  laborImportRequest.date =
                    this.props.parentState.navigationCalenderDates[0];
                  laborImportRequest.hotelID = this.props.parentState.hidValue;
                  let day: number = 0;
                  if (this.state.copyUsersSelected === "Last Week's Schedule") {
                    day = 7;
                  }
                  if (
                    this.state.copyUsersSelected === "Last Two Week's Schedule"
                  ) {
                    day = 14;
                  }
                  laborImportRequest.day = day;

                  if (
                    this.state.copyShiftsSelected === "Last Week's Schedule"
                  ) {
                    laborImportRequest.day2 = 7;
                  }
                  if (
                    this.state.copyShiftsSelected === "Last Two Week's Schedule"
                  ) {
                    laborImportRequest.day2 = 14;
                  }

                  if (this.state.copyUsers === true) {
                    laborImportRequest.isBlankShift = "Yes";
                  } else {
                    laborImportRequest.isBlankShift = "No";
                  }

                  laborImportRequest.ImportType = "Schedule";
                  let departMentArray = [...this.state.selectedDepartmentID];
                  let departMentID = "";
                  departMentArray.forEach((element) => {
                    departMentID += element.id + ",";
                  });
                  laborImportRequest.selectedDept = departMentID.replace(
                    /,(?=\s*$)/,
                    ""
                  );
                  if (
                    this.state.copyShiftsAssignments === true &&
                    this.state.copyUsersSelected ===
                      this.state.copyShiftsSelected
                  ) {
                    laborImportRequest.isAssignShift = "Yes";
                  } else {
                    laborImportRequest.isAssignShift = "No";
                  }

                  if (this.state.copyShifts === false) {
                    laborImportRequest.copyShift = "No";
                  } else {
                    laborImportRequest.copyShift = "Yes";
                  }
                  // if(request.ImportType==="Template")
                  if (
                    this.state.usersSelectionType === "Template" ||
                    this.state.shiftsSelectionType === "Template"
                  ) {
                    let laborImportRequest =
                      {} as ILaborImportScheduleTemplateRequestDto;
                    laborImportRequest.date =
                      this.props.parentState.navigationCalenderDates[0];
                    laborImportRequest.hotelID =
                      this.props.parentState.hidValue;
                    laborImportRequest.day = 7;
                    laborImportRequest.day2 = 7;
                    laborImportRequest.isBlankShift = this.state.copyUsers
                      ? "Yes"
                      : "No";
                    laborImportRequest.copyShift = this.state.copyShifts
                      ? "Yes"
                      : "No";
                    laborImportRequest.importType = "Template";
                    laborImportRequest.TemplateName =
                      this.state.copyUsersSelected;
                    if (
                      this.state.copyShifts === true &&
                      this.state.copyUsers === false
                    ) {
                      laborImportRequest.TemplateName =
                        this.state.copyShiftsSelected;
                    }
                    let departMentArray = [...this.state.selectedDepartmentID];
                    let departMentID = "";
                    departMentArray.forEach((element) => {
                      departMentID += element.id + ",";
                    });
                    laborImportRequest.selectedDept = departMentID.replace(
                      /,(?=\s*$)/,
                      ""
                    );
                    laborImportRequest.isAssignShift =
                      this.state.copyShiftsAssignments &&
                      this.state.copyUsersSelected ===
                        this.state.copyShiftsSelected
                        ? "Yes"
                        : "No";

                    LaborForecast.LaborImportScheduleTemplate(
                      laborImportRequest
                    )
                      .then(async (result: any) => {
                        if (result.message === "Success") {
                          if (result.result.length > 0) {
                            const newResult1 = result.result[0];
                            if (newResult1.message === "Success") {
                              this.setState({ loaderTrue: false });
                              // Budget Recalculation on successful forecast import
                              this.recalculateBudget();
                              this.props.successForecast();
                              toast.success("Schedule imported successfully", {
                                // // position: toast.POSITION.BOTTOM_RIGHT,
                                // // containerId: "scheduleMain",
                              });
                            } else {
                              let errorMessage: any = "";
                              if (newResult1.message === "blank") {
                                errorMessage =
                                  "Imported week with selected department have no data, schedule cannot be imported.";
                              } else if (newResult1.message === "blankshift") {
                                errorMessage =
                                  "Imported week with selected department have no data, schedule cannot be imported.";
                              } else if (newResult1.message === "blankuser") {
                                errorMessage =
                                  "Imported week with selected department have no data, schedule cannot be imported.";
                              }
                              // this.props.successForecast();
                              Utils.toastError(errorMessage, {
                                // // position: toast.POSITION.BOTTOM_RIGHT,
                                // // containerId: "scheduleMain",
                              });
                            }
                          }
                        } else {
                          this.setState({ loaderTrue: false });
                          Utils.toastError(result.message, {
                            // // position: toast.POSITION.BOTTOM_RIGHT,
                            // // containerId: "scheduleMain",
                          });
                        }
                        resolve();
                      })
                      .catch((err: any) => {
                        this.setState({ loaderTrue: false });
                        Utils.toastError(`Server Error, ${err}`, {
                          // // position: toast.POSITION.BOTTOM_RIGHT,
                          // // containerId: "scheduleMain",
                        });
                        reject();
                      });
                  } else {
                    LaborForecast.LaborImportSchedule(laborImportRequest)
                      .then(async (result: any) => {
                        if (result.message === "Success") {
                          if (result.result.length > 0) {
                            const newResult1 = result.result[0];
                            if (newResult1.message === "Success") {
                              this.setState({ loaderTrue: false });
                              // Budget Recalculation on successful forecast import
                              this.recalculateBudget();
                              this.props.successForecast();
                              toast.success("Schedule imported successfully", {
                                // // position: toast.POSITION.BOTTOM_RIGHT,
                                // // containerId: "scheduleMain",
                              });
                            } else {
                              let errorMessage: any = "";
                              if (newResult1.message === "blank") {
                                errorMessage =
                                  "Imported week with selected department have no data, schedule cannot be imported.";
                              } else if (newResult1.message === "blankshift") {
                                errorMessage =
                                  "Imported week with selected department have no data, schedule cannot be imported.";
                              } else if (newResult1.message === "blankuser") {
                                errorMessage =
                                  "Imported week with selected department have no data, schedule cannot be imported.";
                              }
                              // this.props.successForecast();
                              Utils.toastError(errorMessage, {
                                // // position: toast.POSITION.BOTTOM_RIGHT,
                                // // containerId: "scheduleMain",
                              });
                            }
                          }
                        } else {
                          this.setState({ loaderTrue: false });
                          Utils.toastError(result.message, {
                            // // position: toast.POSITION.BOTTOM_RIGHT,
                            // // containerId: "scheduleMain",
                          });
                        }
                        resolve();
                      })
                      .catch((err: any) => {
                        this.setState({ loaderTrue: false });
                        Utils.toastError(`Server Error, ${err}`, {
                          // // position: toast.POSITION.BOTTOM_RIGHT,
                          // // containerId: "scheduleMain",
                        });
                        reject();
                      });
                  }
                } else {
                  this.setState({ loaderTrue: false });
                  // Budget Recalculation on successful forecast import
                  this.recalculateBudget();
                  this.props.successForecast();
                  toast.success("Forecast imported successfully", {
                    // // position: toast.POSITION.BOTTOM_RIGHT,
                    // // containerId: "scheduleMain",
                  });
                }
              }
            } else {
              this.setState({ loaderTrue: false });
              // this.props.successForecast();
              Utils.toastError("Blank Data Imported", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "forecastPage",
              });
            }
          } else {
            this.setState({ loaderTrue: false });
            Utils.toastError(result.message, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "forecastPage",
            });
          }
          this.setState({
            importFromListSelectDisplay: "Select",
          });
          resolve();
        })
        .catch((err: any) => {
          this.setState({ loaderTrue: false });
          Utils.toastError(`Server Error, ${err}`, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "scheduleMain",
          });
          reject();
        });
    }
  };

  recalculateBudget = () => {
    
    // forecast budget calculation starts
    let request: any = {};
    request.hotelID = this.props.parentState.hidValue;
    request.layout = "7";
    request.date = this.props.parentState.navigationCalenderDates[0];
    request.type = "ForeCast";
    LaborBudgetSetting.SaveBudgetCalculation(request)
      .then(async (result: any | null) => {
        
      })
      .catch((err: any) => {});
    // forecast budget calculation ends
  };

  onCancle = () => {
    this.setState({ status: false });
  };

  employeeSelection = (childState: any) => {
    this.setState({ selectedDepartmentID: childState.selectedItems });
  };

  render() {
    return (
      <div>
        <Modal
          className="set-forecast-modal"
          show={this.props.parentState.setForecastModal}
          onHide={this.importForecastModalClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Set Forecast</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="importFrom">
              <Form.Group controlId="set-Forecast">
                <Form.Label>Import From</Form.Label>
                <Dropdown className="import-from-dropdown">
                  <Dropdown.Toggle id="dropdown-importFrom">
                    <span
                      className={
                        this.state.importForecastSelected === "Select"
                          ? "placeholder"
                          : "selected"
                      }
                    >
                      {this.state.importForecastSelected}
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {this.state.importFromListActual.length !== 0 && (
                      <div>
                        <Dropdown.Header>ACTUALS</Dropdown.Header>
                        {this.state.importFromListActual.map(
                          (item: any, idx: any) => (
                            <Dropdown.Item
                              key={idx}
                              eventKey={item.id}
                              onClick={() => this.importFromListSelect(item)}
                            >
                              {item.name}
                            </Dropdown.Item>
                          )
                        )}
                      </div>
                    )}
                    {this.state.importFromListTemplate.length !== 0 && (
                      <div className="templates-body">
                        <Dropdown.Divider />
                        <Dropdown.Header>TEMPLATES</Dropdown.Header>
                        {this.state.importFromListTemplate.map(
                          (item: any, idx: any) => (
                            item.id !== -1 ? 
                            <Dropdown.Item
                              key={idx}
                              eventKey={item.id}
                              onClick={() => this.importFromListSelect(item)}
                            >
                              <EllipsisWithTooltip placement="bottom">
                                <span>{item.name}</span>
                              </EllipsisWithTooltip>
                              {/* <span> <a href="#">View/Edit</a> </span> */}
                              <Link
                      to={{
                        pathname: "/labor-management/forecast",
                        state: {
                          scheduleError: true,
                          pageLoadDate: this.props.parentState.todayDate,
                          viewType: this.props.parentState.viewType,
                          redierectType:"ViewEdit",
                          ViewTempleteName:item.name,
                        },
                      }}
                    >
                      <button className="btn btn-green">View/Edit</button>
                              </Link> 
                            </Dropdown.Item>
                             : 
                               
                             <div className="new-template-btn">
                                 <Link
                      to={{
                        pathname: "/labor-management/forecast",
                        state: {
                          scheduleError: true,
                          pageLoadDate: this.props.parentState.todayDate,
                          viewType: this.props.parentState.viewType,
                          redierectType:"ViewEdit",
                          ViewTempleteName:item.name,
                        },
                      }}
                    >
                      <Button>{item.name}</Button>
                      {/* <button className="btn btn-green">{item.name}</button> */}
                              </Link> 
                             </div>
                          )
                        )}
                      </div>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
            </div>

            <div className="copySchedule">
              <div className="controlls">
                <Form>
                  <Form.Check
                    type="switch"
                    id="sw_copy-schedule"
                    label="Copy Schedule"
                    checked={this.state.copyShcedule}
                    onChange={() => this.copyScheduleToggle()}
                  />
                </Form>
              </div>
              {this.state.copyShcedule && (
                <div className="copySchedule details">
                  <div className="importFrom">
                    <Form.Group controlId="set-Forecast">
                      <Form.Label>Department to Copy</Form.Label>
                      <MultiSelectDepartment
                        parentState={this.state}
                        employeeSelection={this.employeeSelection}
                      />
                    </Form.Group>
                  </div>

                  <div className="bottomSepetatorLine"></div>

                  <div className="copyUsers">
                    <Form>
                      <Form.Check
                        type="switch"
                        id="sw_copy-users"
                        label="Copy Users"
                        checked={this.state.copyUsers}
                        onChange={() => this.copyUsersToggle()}
                      />
                    </Form>
                    {this.state.copyUsers && (
                      <Dropdown className="import-from-dropdown">
                        <Dropdown.Toggle id="dropdown-importFrom">
                          <span
                            className={
                              this.state.copyUsersSelected === "Select"
                                ? "placeholder"
                                : "selected"
                            }
                          >
                            {this.state.copyUsersSelected}
                          </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <div>
                            {this.state.copyUsersList.map(
                              (item: any, idx: any) => (
                                <Dropdown.Item
                                  key={idx}
                                  eventKey={item.id}
                                  onClick={() => this.copyUsers(item)}
                                >
                                  {item?.templateName}
                                </Dropdown.Item>
                              )
                            )}
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </div>

                  <div className="copyShifts">
                    <Form>
                      <Form.Check
                        type="switch"
                        id="sw_copy-shifts"
                        label="Copy Shifts"
                        checked={this.state.copyShifts}
                        onChange={() => this.copyShiftsToggle()}
                      />
                    </Form>
                    {this.state.copyShifts && (
                      <Dropdown className="import-from-dropdown">
                        <Dropdown.Toggle id="dropdown-importFrom">
                          <span
                            className={
                              this.state.copyShiftsSelected === "Select"
                                ? "placeholder"
                                : "selected"
                            }
                          >
                            {this.state.copyShiftsSelected}
                          </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <div>
                            {this.state.copyShiftsList.map(
                              (item: any, idx: any) => (
                                <Dropdown.Item
                                  key={idx}
                                  eventKey={item.id}
                                  onClick={() => this.copyShifts(item)}
                                >
                                  {item?.templateName}
                                </Dropdown.Item>
                              )
                            )}
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </div>
                  {!(
                    this.state.copyShiftsSelected === "Select" &&
                    this.state.copyUsersSelected === "Select"
                  ) && (
                    <>
                      {this.state.copyShifts &&
                        this.state.copyUsers &&
                        this.state.copyShiftsSelected ===
                          this.state.copyUsersSelected && (
                          <div className="copyShiftAssignment">
                            <Form>
                              <Form.Check
                                type="switch"
                                id="sw_copy-shifts-assignments"
                                label="Copy Shift Assignments"
                                checked={this.state.copyShiftsAssignments}
                                onChange={() =>
                                  this.copyShiftsAssignmentsToggle()
                                }
                              />
                            </Form>
                          </div>
                        )}
                    </>
                  )}
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="footer-wrapper">
              <Button
                disabled={this.state.loaderTrue}
                className={
                  this.state.status
                    ? "importForecastAlertButton"
                    : "import-forecast-button"
                }
                onClick={() => this.setForecast()}
              >
                {this.state.copyShcedule
                  ? "Set Forecast and Copy Schedule"
                  : "Set Forecast"}
              </Button>

              {this.state.status && (
                <div
                  className="confirmModalNonTable"
                  ref={this.isSetForcastScroll}
                >
                  <div className="title">Error Setting Forecast</div>
                  <div className="description">
                    An error occured when attempting to set the forecast.
                  </div>

                  <div className="action-link">
                    <button onClick={this.onCancle} className="btn wht-bg">
                      Cancel
                    </button>
                    <Link
                      to={{
                        pathname: "/labor-management/forecast",
                        state: {
                          scheduleError: true,
                          pageLoadDate: this.props.parentState.todayDate,
                          viewType: this.props.parentState.viewType,
                        },
                      }}
                    >
                      <button className="btn btn-danger">Fix Forecast</button>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
