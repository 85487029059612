import * as React from "react";

export class ConfirmationModalNonTable extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      confirmModalContent: props.confirmModalContent,
      disableActionButton: false,
    };
  }
  handleCancel() {
    this.props.warningCloseModel();
  }

  handleAction() {
    this.props.saveCopyBudgets();
    if( this.props.onceClickable == true ){
      this.setState( { disableActionButton: true })
    }
  }
  

  

  render() {
    return (
      <div className="confirmModalNonTable">
        <div>
          <div className="title">{this.props.title}</div>
          <div className="description">{this.props.description}</div>
          <div className="action-link">
            <button
              type="button"
              className="btn wht-bg"
              onClick={this.handleCancel.bind(this)}
            >
              {this.props.cancelButtonName}
            </button>
            <button
              id="action-btn"
              type="button"
              className="btn btn-danger"
              onClick={this.handleAction.bind(this)}
              disabled={this.state.disableActionButton}
            >
              {this.props.actionButtonName}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
