import React from "react";
import "./LaborPromotion.scss";
import { Button, Col, Container, Modal, Nav, Row, Tab, Tabs, Fade } from "react-bootstrap";
import LaborIcon from "../../../Common/Assets/Images/icons/inn-flow-labor-logo.svg";
import { LaborSchedule } from "../../../Common/Services/LaborSchedule";
import { resolve, reject } from "q";
import { Player } from '@lottiefiles/react-lottie-player';
export class LaborPromotion extends React.Component<any, any>{
    constructor(props: any){
        super(props);
        this.state = {
            requestDemoModal: false,
            activeTab: "schedulingForecasting",
            showExpertModalText: false,
            tabText: "Scheduling & Forecasting",
        }
    }

    componentDidMount() {
        this.SaveUserForPermotion();        
      }

      SaveUserForPermotion=()=>{
        let request = {} as any;
        LaborSchedule.SaveUserForPermotion(request)
        .then(async (result: any) => {
      
            // if (result.message === "Success") {

            // }
  
          resolve();
         
        })
        .catch((err: any) => {      
          reject();
        });
    }
    sendEmail=()=>{
        let request = {} as any;
        LaborSchedule.SendRequestDemoEmail(request)
        .then(async (result: any) => {
      
            // if (result.message === "Success") {

            // }
  
          resolve();
         
        })
        .catch((err: any) => {      
          reject();
        });
    }


    RequestDemo = (e)=>{
      this.setState({requestDemoModal:true},()=>{
      this.sendEmail();
      });
      let buttonText = e.target.textContent;
      console.log(e.target.textContent);
      if( buttonText == "Talk to an Expert"){
        this.setState({ showExpertModalText: true });
      }else{
        this.setState({ showExpertModalText: false });
      }
    };

    checkModal = () => {
        this.setState({ showExpertModalText: true });
    }

    setActiveTab = (selectedTab) => {
        this.setState({ activeTab: selectedTab})
    }

    hideModal = () => {
        this.setState({ requestDemoModal : false })
    }

    getText = (e) => {
        let text = e.target.textContent; 
        this.setState({tabText: text});
    }

    render(){
        return (
            <Container fluid className="labor-promotion-page px-0 pt-0">
            <div>
                <div className="page-banner">
                    <div>
                        <h6 className="d-flex"><img src={LaborIcon} alt="Labor" /> <span className="ml-1">labor</span></h6>
                        
                    </div>
                    <div className="d-flex justify-content-between btn-row">
                        <div className="banner-text"> 
                            <h6>Subscribe today — <span>Add Inn-Flow Labor Management and find out how you could save by recouping time, streamlining forecasting and scheduling, and optimizing housekeeping and payroll operations.</span></h6>
                        </div>
                        <Button  type="button" className="border-0"  onClick={this.RequestDemo}>Request a Demo</Button>
                    </div>
                </div>
                
                    <Tab.Container
                        activeKey={this.state.activeTab}
                        onSelect={(selectedtab) => this.setActiveTab(selectedtab)}
                        id="labor-promotions-tabs"
                        transition={Fade}
                    >
                        <div className="nav-container container">
                            <Nav className="nav-tabs flex-row">
                                <Nav.Item>
                                    <Nav.Link eventKey="schedulingForecasting">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none">
<path d="M10.75 0.5C10.3375 0.5 10 0.8375 10 1.25V2H4V1.25C4 0.8375 3.6625 0.5 3.25 0.5C2.8375 0.5 2.5 0.8375 2.5 1.25V2H1.75C0.9175 2 0.2575 2.675 0.2575 3.5L0.25 14C0.25 14.825 0.9175 15.5 1.75 15.5H12.25C13.075 15.5 13.75 14.825 13.75 14V3.5C13.75 2.675 13.075 2 12.25 2H11.5V1.25C11.5 0.8375 11.1625 0.5 10.75 0.5ZM12.25 14H1.75V6.5H12.25V14ZM6.25 8.75C6.25 8.3375 6.5875 8 7 8C7.4125 8 7.75 8.3375 7.75 8.75C7.75 9.1625 7.4125 9.5 7 9.5C6.5875 9.5 6.25 9.1625 6.25 8.75ZM3.25 8.75C3.25 8.3375 3.5875 8 4 8C4.4125 8 4.75 8.3375 4.75 8.75C4.75 9.1625 4.4125 9.5 4 9.5C3.5875 9.5 3.25 9.1625 3.25 8.75ZM9.25 8.75C9.25 8.3375 9.5875 8 10 8C10.4125 8 10.75 8.3375 10.75 8.75C10.75 9.1625 10.4125 9.5 10 9.5C9.5875 9.5 9.25 9.1625 9.25 8.75ZM6.25 11.75C6.25 11.3375 6.5875 11 7 11C7.4125 11 7.75 11.3375 7.75 11.75C7.75 12.1625 7.4125 12.5 7 12.5C6.5875 12.5 6.25 12.1625 6.25 11.75ZM3.25 11.75C3.25 11.3375 3.5875 11 4 11C4.4125 11 4.75 11.3375 4.75 11.75C4.75 12.1625 4.4125 12.5 4 12.5C3.5875 12.5 3.25 12.1625 3.25 11.75ZM9.25 11.75C9.25 11.3375 9.5875 11 10 11C10.4125 11 10.75 11.3375 10.75 11.75C10.75 12.1625 10.4125 12.5 10 12.5C9.5875 12.5 9.25 12.1625 9.25 11.75Z" fill="#775BFA"/>
</svg>
                                        <span onClick={this.getText}>Scheduling & Forecasting</span>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="housekeepingOptimization">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                        <path d="M12 8.25H11.25V3C11.25 1.755 10.245 0.75 9 0.75C7.755 0.75 6.75 1.755 6.75 3V8.25H6C3.93 8.25 2.25 9.93 2.25 12V15.75C2.25 16.575 2.925 17.25 3.75 17.25H14.25C15.075 17.25 15.75 16.575 15.75 15.75V12C15.75 9.93 14.07 8.25 12 8.25ZM14.25 15.75H12.75V13.5C12.75 13.0875 12.4125 12.75 12 12.75C11.5875 12.75 11.25 13.0875 11.25 13.5V15.75H9.75V13.5C9.75 13.0875 9.4125 12.75 9 12.75C8.5875 12.75 8.25 13.0875 8.25 13.5V15.75H6.75V13.5C6.75 13.0875 6.4125 12.75 6 12.75C5.5875 12.75 5.25 13.0875 5.25 13.5V15.75H3.75V12C3.75 10.7625 4.7625 9.75 6 9.75H12C13.2375 9.75 14.25 10.7625 14.25 12V15.75Z" fill="#775BFA"/>
                                    </svg>
                                        <span onClick={this.getText}>Housekeeping Optimization</span>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="automatedPayrollExport">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                        <path d="M9 1.5C4.86 1.5 1.5 4.86 1.5 9C1.5 13.14 4.86 16.5 9 16.5C13.14 16.5 16.5 13.14 16.5 9C16.5 4.86 13.14 1.5 9 1.5ZM9 15C5.6925 15 3 12.3075 3 9C3 5.6925 5.6925 3 9 3C12.3075 3 15 5.6925 15 9C15 12.3075 12.3075 15 9 15ZM9.2325 8.355C7.905 8.0175 7.4775 7.65 7.4775 7.1025C7.4775 6.4725 8.07 6.03 9.0525 6.03C10.0875 6.03 10.4775 6.525 10.5075 7.26H11.79C11.7525 6.255 11.1375 5.3325 9.9225 5.0325V3.75H8.175V5.0175C7.0425 5.2575 6.135 5.9925 6.135 7.125C6.135 8.4675 7.2525 9.1425 8.88 9.5325C10.3425 9.8775 10.635 10.395 10.635 10.935C10.635 11.3325 10.3425 11.9775 9.06 11.9775C7.86 11.9775 7.3875 11.4375 7.32 10.7475H6.03C6.105 12.0225 7.05 12.7425 8.175 12.975V14.25H9.93V12.9975C11.07 12.78 11.97 12.1275 11.9775 10.92C11.97 9.27 10.5525 8.7 9.2325 8.355Z" fill="#775BFA"/>
                                    </svg>
                                        <span onClick={this.getText}>Automated Payroll Export</span>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="smartTimeClock">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                        <path d="M8.9925 1.5C4.8525 1.5 1.5 4.86 1.5 9C1.5 13.14 4.8525 16.5 8.9925 16.5C13.14 16.5 16.5 13.14 16.5 9C16.5 4.86 13.14 1.5 8.9925 1.5ZM9 15C5.685 15 3 12.315 3 9C3 5.685 5.685 3 9 3C12.315 3 15 5.685 15 9C15 12.315 12.315 15 9 15ZM8.835 5.25H8.79C8.49 5.25 8.25 5.49 8.25 5.79V9.33C8.25 9.5925 8.385 9.84 8.6175 9.975L11.73 11.8425C11.985 11.9925 12.315 11.9175 12.465 11.6625C12.6225 11.4075 12.54 11.07 12.2775 10.92L9.375 9.195V5.79C9.375 5.49 9.135 5.25 8.835 5.25Z" fill="#775BFA"/>
                                    </svg>
                                        <span onClick={this.getText}>Smart Time Clock & SmileID</span>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="ActionableReport">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                        <path d="M14.25 3.75V14.25H3.75V3.75H14.25ZM14.25 2.25H3.75C2.925 2.25 2.25 2.925 2.25 3.75V14.25C2.25 15.075 2.925 15.75 3.75 15.75H14.25C15.075 15.75 15.75 15.075 15.75 14.25V3.75C15.75 2.925 15.075 2.25 14.25 2.25Z" fill="#775BFA"/>
                                        <path d="M10.5 12.75H5.25V11.25H10.5V12.75ZM12.75 9.75H5.25V8.25H12.75V9.75ZM12.75 6.75H5.25V5.25H12.75V6.75Z" fill="#775BFA"/>
                                        </svg>
                                        <span onClick={this.getText}>Actionable Reports</span>
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>  
                        <div className="col-lg-11 mx-auto">
                            <Tab.Content> 
                                <Tab.Pane eventKey="schedulingForecasting">
                                    <Row>
                                        <Col lg="6" className="content-col">
                                            <div className="tab-text">
                                                <h4>Spend less time managing schedules</h4>
                                                <p>Ditch the spreadsheets, print-outs, and whiteboards. With Inn-Flow Labor, everything you need is easy and in one place. Build schedules intelligently from forecasts, giving you back time to focus on guests and run your hotel like a pro.</p>
                                                <ul>
                                                    <li>
                                                        Forecast Models
                                                        <span>Turn occupancy data into staffing plans without manual calculations so you stay on target.</span>
                                                    </li>
                                                    <li>
                                                        Drag-&-Drop Schedule Builder
                                                        <span>Save time and provide crucial info on PTO and overtime, so you stay on budget.</span>
                                                    </li>
                                                    <li>
                                                        Automated Alerts
                                                        <span>Notify staff of schedule changes, eliminating the need for follow-ups.</span>
                                                    </li>
                                                </ul>
                                                <div className="d-flex justify-content-between btn-row">
                                                    <Button href='https://www.inn-flow.com/labor/' target="_blank" className="read-more-btn">Read More</Button>
                                                    <Button onClick={ this.RequestDemo }>Talk to an Expert</Button>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg="4" className="mx-auto">
                                        {
                                             this.state.tabText == "Scheduling & Forecasting" ? 
                                             <Player
                                                autoplay
                                                loop
                                                src="https://innflowfilestore1.blob.core.windows.net/animation/LaborPromotion/Schedule-forecasting.json" 
                                            > 
                                            </Player> : <></>
                                        }
                                            
                                        </Col>
                                    </Row>
                                </Tab.Pane> 
                                <Tab.Pane eventKey="housekeepingOptimization">
                                    <Row>
                                        <Col lg="6" className="content-col">
                                            <div className="tab-text">
                                                <h4>Streamline housekeeping operations & boost efficiency</h4>
                                                <p>Your housekeepers have dynamic schedules and it's essential you keep them only for the hours you need them. Ensure they work efficiently and effectively with Inn-Flow Labor. Simple tools help you optimize housekeeping operations, ensuring rooms are clean without going over budget.</p>
                                                <ul>
                                                    <li>
                                                        Optimize Minutes per Cleaned Room
                                                        <span>Automatically calculate time needed for housekeeping tasks based on smart forecasts, saving money and ensuring rooms are always cleaned efficiently.</span>
                                                    </li>
                                                    <li>
                                                        Daily Monitoring
                                                        <span>Automated alerts and dashboards give you all the info you need to stay on top of room cleaning and assignments.</span>
                                                    </li>
                                                    <li>
                                                        Performance Tracking
                                                        <span>Monitor housekeeping performance with detailed reports to improve productivity.</span>
                                                    </li>
                                                </ul>
                                                <div className="d-flex justify-content-between btn-row">
                                                    <Button href='https://www.inn-flow.com/labor/' target="_blank" className="read-more-btn">Read More</Button>
                                                    <Button onClick={this.RequestDemo}>Talk to an Expert</Button>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg="4" className="mx-auto">
                                        {
                                            this.state.tabText == "Housekeeping Optimization" ? 
                                            <Player
                                                autoplay
                                                loop
                                                src="https://innflowfilestore1.blob.core.windows.net/animation/LaborPromotion/Housekeeping-optimisation.json" 
                                            > 
                                            </Player> : <></>
                                        }
                                            
                                        </Col>
                                    </Row>
                                </Tab.Pane> 
                                <Tab.Pane eventKey="automatedPayrollExport">
                                    <Row>
                                        <Col lg="6" className="content-col">
                                            <div className="tab-text">
                                                <h4>Simplify Payroll Management</h4>
                                                <p>Streamline processes and eliminate manual entry by sending time and attendance data directly to your payroll system, allowing you to focus on what truly matters—running your hotel efficiently. Inn-Flow's automated tools ensure your payroll is accurate, compliant, and hassle-free.</p>
                                                <ul>
                                                    <li>
                                                        One-Click Export
                                                        <span>Seamlessly send time and attendance to payroll (including PTO and overtime), saving you hours of time by eliminating the need for manual input.</span>
                                                    </li>
                                                    <li>
                                                        Error Reduction
                                                        <span>Ensure you are compliant and accurate every pay period.</span>
                                                    </li>
                                                    <li>
                                                        Accelerated Payroll Processing
                                                        <span>Speed up payroll, giving you more time to focus on guests and operations.</span>
                                                    </li>
                                                </ul>
                                                <div className="d-flex justify-content-between btn-row">
                                                    <Button href='https://www.inn-flow.com/labor/' target="_blank" className="read-more-btn">Read More</Button>
                                                    <Button onClick={this.RequestDemo}>Talk to an Expert</Button>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg="4" className="mx-auto">
                                        {
                                            this.state.tabText == "Automated Payroll Export" ? 
                                            <Player
                                                autoplay
                                                loop
                                                src="https://innflowfilestore1.blob.core.windows.net/animation/LaborPromotion/Payroll-Export.json" 
                                            > 
                                            </Player> : <></>
                                        }
                                            
                                        </Col> 
                                    </Row>
                                </Tab.Pane> 
                                <Tab.Pane eventKey="smartTimeClock">
                                    <Row>
                                        <Col lg="6" className="content-col">
                                            <div className="tab-text">
                                                <h4>Prevent time theft by eliminating buddy punching & early punch-in</h4>
                                                <p>Ensure accurate time tracking and stay on budget with Inn-Flow’s Smart Time Clock and SmileID. Plus, you get PTO management and scheduling all in one simple tool.</p>
                                                <ul>
                                                    <li>
                                                        SmileID Facial Recognition
                                                        <span>Prevents buddy punching while improving payroll accuracy and physical security.</span>
                                                    </li>
                                                    <li>
                                                    Time & Attendance Alerts
                                                        <span>Automatically known when staff fails to clock in or out — no more chasing people down or inaccurate timesheets.</span>
                                                    </li>
                                                    <li>
                                                        Early Punch-In Controls
                                                        <span>Own the power to decide when staff can clock in early so you're not paying unnecessary wages.</span>
                                                    </li>
                                                </ul>
                                                <div className="d-flex justify-content-between btn-row">
                                                    <Button href='https://www.inn-flow.com/labor/' target="_blank" className="read-more-btn">Read More</Button>
                                                    <Button onClick={this.RequestDemo}>Talk to an Expert</Button>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg="4" className="mx-auto">
                                        {
                                            this.state.tabText == "Smart Time Clock & SmileID" ? 
                                            <Player
                                                autoplay
                                                loop
                                                src="https://innflowfilestore1.blob.core.windows.net/animation/LaborPromotion/Smart-Timeclock.json" 
                                            > </Player>: <></>
                                        }
                                        </Col> 
                                    </Row>
                                </Tab.Pane> 
                                <Tab.Pane eventKey="ActionableReport">
                                    <Row>
                                        <Col lg="6" className="content-col">
                                            <div className="tab-text">
                                                <h4>Unlock Insights with Actionable Reports</h4>
                                                <p>You need accurate information so you can make fast decisions, but creating reports takes time you don’t have.With actionable insights at your fingertips, Inn-Flow Labor helps you make smarter decisions that drive better results for your hotel.</p>
                                                <ul>
                                                    <li>
                                                    Automated Report Scheduling
                                                        <span>Maximize revenue per room by keeping labor costs low with insights delivered right to your email inbox when you need them.</span>
                                                    </li>
                                                    <li>
                                                    PTO, Wages, & Overtime Analysis
                                                        <span>Ensure payroll is accurate and on budget.</span>
                                                    </li>
                                                    <li>
                                                    Staffing Trends
                                                        <span>Monitor early/late punches, no-shows, and more.</span>
                                                    </li>
                                                </ul>
                                                <div className="d-flex justify-content-between btn-row">
                                                    <Button href='https://www.inn-flow.com/labor/' target="_blank" className="read-more-btn">Read More</Button>
                                                    <Button onClick={this.RequestDemo}>Talk to an Expert</Button>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg="4" className="mx-auto">
                                            {
                                                this.state.tabText == "Actionable Report" ?
                                            <Player
                                                autoplay
                                                loop
                                                src="https://innflowfilestore1.blob.core.windows.net/animation/LaborPromotion/Actionable-Reports.json"> 
                                            </Player> : <></>
                                            }
                                            
                                        </Col> 
                                    </Row>
                                </Tab.Pane> 
                            </Tab.Content>
                        </div>
                    </Tab.Container> 
                

                <Modal
                    className="warning-export-modal-UI request-demo-modal"
                    show={this.state.requestDemoModal}      
                    aria-labelledby="contained-modal-title-vcenter"
                    animation={true}
                    centered      
                    >
                    <Modal.Header>
                        <Modal.Title>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                <rect x="1.5" y="1.5" width="27" height="27" rx="13.5" fill="#DEFFF8"/>
                                <rect x="1.5" y="1.5" width="27" height="27" rx="13.5" stroke="#0E9074" stroke-width="3"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.0964 10.3897L12.9364 17.2997L11.0364 15.2697C10.6864 14.9397 10.1364 14.9197 9.73638 15.1997C9.34638 15.4897 9.23638 15.9997 9.47638 16.4097L11.7264 20.0697C11.9464 20.4097 12.3264 20.6197 12.7564 20.6197C13.1664 20.6197 13.5564 20.4097 13.7764 20.0697C14.1364 19.5997 21.0064 11.4097 21.0064 11.4097C21.9064 10.4897 20.8164 9.67967 20.0964 10.3797V10.3897Z" fill="#0E9074"/>
                            </svg>
                            We’ve got you covered!
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            this.state.showExpertModalText ? <p>Your request to speak with one of our Inn-Flow Experts has been sent. Someone will reach out to you via email or with a phone call shortly.</p>
                            :
<p>Your request to see a demo of Inn-Flow Labor is with our team of experts. Someone will reach out to you via email or with a phone call shortly.</p> 
                        }
                    </Modal.Body>
                    <Modal.Footer> 
                        <Button
                        className="close-btn px-3"
                        onClick={(e: any) => this.hideModal()}
                        >
                        Close
                        </Button>
                    </Modal.Footer>
                    </Modal>
            </div>
            </Container>
        )
    }
}