import * as React from "react";

export class InfoTooltipRed extends React.Component<any, any> {
  render() {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="#b12a18"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M8.66669 7.33332H7.33335V11.3333H8.66669V7.33332Z" />
        <path d="M8.66669 4.66665H7.33335V5.99998H8.66669V4.66665Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.2 8.00005C15.2 11.9765 11.9765 15.2 8 15.2C4.02355 15.2 0.800003 11.9765 0.800003 8.00005C0.800003 4.0236 4.02355 0.800049 8 0.800049C11.9765 0.800049 15.2 4.0236 15.2 8.00005ZM14.4 8.00005C14.4 11.5347 11.5346 14.4 8 14.4C4.46538 14.4 1.6 11.5347 1.6 8.00005C1.6 4.46543 4.46538 1.60005 8 1.60005C11.5346 1.60005 14.4 4.46543 14.4 8.00005Z"
        />
      </svg>
    );
  }
}
