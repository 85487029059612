import React, { Component } from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import _ from "lodash";
import { Facility } from '../../Common/Services/Facility';
import { IAssetByLocation } from '../../Common/Contracts/IFacilities';

export class LocationAsset extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
          tableData:[],
          tableDataLength : 0,
          sortingOrder: "asc",
          sortingField: "location",
    };

  }

  componentDidMount() {
    this.getAsset()
  }

  getAsset = () => {
    let request = {} as IAssetByLocation;
    request.RoomId=this.props.parentData.locationId;
    Facility.GetAssetByLocation(
    request
    ).then((result: any) => {
      this.setState({tableData : result,tableDataLength : result.length});
    }
    );
  };

  handleSortByColumn = (field) => {
    this.setState(
      {
        sortingField: field,
        sortingOrder: this.state.sortingOrder === "asc" ? "desc" : "asc",
      },
      () => {
        this.sortTableData(this.state.tableData, this.state.sortingField);
      }
    );
  };

  sortTableData = (data: any, fieldName) => {
    if (this.state.sortingOrder === "asc") {
      data = _.orderBy(data, [fieldName], ["asc", "asc"]);
    } else if (this.state.sortingOrder === "desc") {
      data = _.orderBy(data, [fieldName], ["desc", "asc"]);
    }
    this.setState({ tableData: data });
  };

  sortImage = (order) => {

    if (!order)
      return (
        <span className="ascending">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
              fill="#1D1E20"
            />
          </svg>
        </span>
      );
    else if (order === "asc")
      return (
        <span className="ascending">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
              fill="#1D1E20"
            />
          </svg>
        </span>
      );
    else if (order === "desc")
      return (
        <span className="descending">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
              fill="#1D1E20"
            />
          </svg>
        </span>
      );
    return null;
  };

  render() {

    const columns = [
        {
          dataField: "assetName",
          text: "Name",
          headerFormatter: (column, colIndex) => {
            return (
              <div
                className={`d-inline-block ${this.state.sortingField === "assetName" ? "active" : ""
                  }`}
                onClick={() => this.handleSortByColumn("assetName")}
              >
                {column.text}
                {this.sortImage(this.state.sortingOrder)}
              </div>
            );
          },
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <>
                <EllipsisWithTooltip placement="bottom">
                  <div className="work-name"> {row.assetName}</div>
                </EllipsisWithTooltip>
              </>
            );
          },
  
        },
        {
          dataField: "assetTypeName",
          searchable: false,
          text: "Type",
          headerFormatter: (column, colIndex) => {
            return (
              <div
                className={`d-inline-block ${this.state.sortingField === "assetTypeName" ? "active" : ""
                  }`}
                onClick={() => this.handleSortByColumn("assetTypeName")}
              >
                {column.text}
                {this.sortImage(this.state.sortingOrder)}
              </div>
            );
          },
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <>
                <EllipsisWithTooltip>
                  <div className={`assetTypeName ${row.assetTypeName.toLowerCase()}`}>{row.assetTypeName}</div>
                </EllipsisWithTooltip>
              </>
            )
          },
  
  
        },
  
      ];

    return (
      <div>
        <BootstrapTable
          keyField="workOrderID"
          data={this.state.tableData}
          columns={columns}
          search
          hover
        />
      </div>
    );
  }
}
