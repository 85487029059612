import React from "react";
import { AddEditTimeOffShiftModal } from "../LaborManagement/Labor/Schedule/Modals & SlideOut/AddEditTimeOffShiftModal";
import { Button, Row, Spinner } from "react-bootstrap";
import {
  // Button,
  Container,
} from "react-bootstrap";
// import { ToastContainer } from "react-toastify";
import scrollIntoView from "scroll-into-view";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReportExport } from "../../Common/Services/ReportExport";
import { ReportViewer } from "../Reports/ReportViewer";
import { ToastContainer } from "react-toastify";
import { LaborPerformance as laborPerformance } from "../../Common/Services/LaborPerfomance";
import chevronRight from "../../Common/Assets/Images/icons/bx-chevron-right.svg";
import "./MyPTO.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import {RequestPendingApproval} from "./RequestPendingApproval"
;import { DeniedRequest } from "./DeniedRequest";
import { ApprovedFutureTimeOff } from "./ApprovedFutureTimeOff";
import { TimeOffHistory } from "./TimeOffHistory";
import { LaborSchedule } from "../../Common/Services/LaborSchedule";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { Utils } from "../../Common/Utilis";
export class MyPTO extends React.Component<any, any> {
  private linkRef: any;
  private scheduleUserViewRef: any;
  constructor(props: any) {
    super(props);
    this.scheduleUserViewRef = React.createRef();
    this.linkRef = React.createRef();
    this.state = {
      permissionManageSchedule: true,
      permissionManageTimeOff: true,
      parameterset: false,
      addEditTimeOffShiftModal: false,
      navigationCalenderDates: [],
      hidValue: 0,
      fromPage: "MyPTOTimeoff",
      requestPendingApprovalData:[],
      requestPendingApproval:true,

      approvedFutureTimeOffData:[],
      approvedFutureTimeOff:true,

      deniedRequestData:[],
      deniedRequest:true,
      
      timeOffHistoryData:[],
      timeOffHistory:true,

      pendingApprovalTableExpended: [],
      approvedFutureTimeOffTableExpended: [],
      deniedRequestTableExpended: [],
      timeOffHistoryTableExpended: [],
      summaryData: [],
      anniversayDate: "",
      requestpendingexpanded: false,
      approvefuturetimeexpanded : false,
      deniedexpanded: false,
      timeoffhistoryexpanded: false,
      timeOffReportLoader: false,
    };
  }
  componentDidMount() {
  debugger;



    let deepParam = localStorage.getItem("deepParam");
    let param = "";
    if (deepParam !== "") {
     
      try {
        param = atob(String(window.location.href.split("?")[1]));
      } catch {
        this.setState({ isLoader: false, mobileLoader: false });
        window.location.href = "/unauthorize-access";
      }
      // const username =param.split('=')[1];
      // let currentURL = window.location.href.split("?")[0] + "?" + param; 
      // if (currentURL.includes("?")) {
      //   const strogSusername =  JSON.parse(localStorage.storage).user_UniqueID.toString();
      //   if(strogSusername===username){
      //     this.getPTOReport();
      //     this.getHidPayPeriod();
      //     this.GetLaborTimeoffSummaryCount();
      //     this.GetLaborTimeoffSummary();
      //   }else{
      //     window.location.href = "/unauthorize-access?Error=Page";
      //   }
        
       
      // }
    }

    this.getPTOReport();
    this.getHidPayPeriod();
    this.GetLaborTimeoffSummaryCount();
    this.GetLaborTimeoffSummary();

    // let timeOffHistoryArray: any = [];
    // let request = {} as any;    

    // request.timeofHistory = "Time Off History (Last 90 Days)";
    // request.action=""; 
    // timeOffHistoryArray.push(request);
    // this.setState({timeOffHistoryData:timeOffHistoryArray});



  }

  getParamValueByParamName(paramName: string): any {	
    const valuesArray = decodeURIComponent(this.props.history.location.search)?.replace('?', '')?.split('&');	
    const item = valuesArray?.find(r => r?.includes(paramName));	
    return     item ? atob(item?.split('=')[1])?.split('=')[1] as any : "";	
  }



  getFormattedDate(date) {
    var year = date.getFullYear();
    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : "0" + month;
    var day = date.getDate().toString();
    day = day.length > 1 ? day : "0" + day;
    return month + "/" + day + "/" + year.toString().slice(-2);
  }
  addEditTimeOffShiftModalShow = () => {
    this.setState({
      addEditTimeOffShiftModal: true,
    });
  };
  addEditTimeOffShiftModalHide = (successMessage = "") => {
    this.setState({
      addEditTimeOffShiftModal: false,
      requestpendingexpanded: false,
      approvefuturetimeexpanded: false,
      deniedexpanded: false,
      timeoffhistoryexpanded: false
    });
    this.GetLaborTimeoffSummaryCount("");
  };

  GetLaborTimeoffSummaryCount(type:string=""){
    debugger

      let request = {} as any;
      request.hid = 0;
      request.Useruniqueno = JSON.parse(localStorage.storage).user_UniqueID;    
      LaborSchedule.GetLaborTimeoffSummaryCount(request)
        .then(async (result: any) => {
          debugger
          console.log("resulttt",result);
          if (result.message === "Success") {
            let requestPendingApprovalArray: any = [];
            let approvedFutureTimeOfflArray: any = [];
            let deniedRequestArray: any = [];
            let timeOffHistoryArray: any = [];
           
            for(let i=0; i < result.result.length;i++ ){
              
             if (result.result[i].status==="Pending"){
               let request = {} as any;  
              request.entryType = "Requests Pending Approval";
              request.Count = result.result[i].requestCount; 
              request.action= type==="requestPendingApproval" ? "PendingApprovalExpand" : "";  
              requestPendingApprovalArray.push(request);
              this.setState({requestPendingApprovalData:requestPendingApprovalArray});
             }
             if (result.result[i].status==="Approved"){
              let request = {} as any;  
              request.entryType = "Approved Future Time Off";
              request.Count = result.result[i].requestCount; 
              request.action="";  
              approvedFutureTimeOfflArray.push(request);
              this.setState({approvedFutureTimeOffData:approvedFutureTimeOfflArray})
            }
            if (result.result[i].status==="Declined"){
              let request = {} as any;  
              request.entryType = "Denied Requests (Last 30 Days)";
              request.Count = result.result[i].requestCount; 
              request.action="";  
              deniedRequestArray.push(request);
              this.setState({deniedRequestData:deniedRequestArray});
            }
          }

          let request = {} as any;  
          request.entryType = "Time Off History (Last 90 Days)";
          request.action="";  
          timeOffHistoryArray.push(request);
          this.setState({timeOffHistoryData:timeOffHistoryArray});


          }
        }).catch((err) => {
                  Utils.toastError(`Server Error, ${err}`, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  });
                 
                  //reject();
                });



  };

  GetLaborTimeoffSummary(){
    debugger

      let request = {} as any;
      request.hid = 0;
      request.Useruniqueno = JSON.parse(localStorage.storage).user_UniqueID;    
      LaborSchedule.GetLaborTimeoffSummary(request)
        .then(async (result: any) => {
          debugger
          console.log("timeoffresulttt",result);
          if (result.message === "Success") {
            let summaryArray: any =[];
           
            for(let i=0; i < result.result.length;i++ ){
               let request = {} as any;  
              request.ptoName = result.result[i].ptoName;
              request.balance = result.result[i].balance;
              request.anniversaryDate = result.result[i].anniversarydate;
              request.displayType = result.result[i].displayType;
              summaryArray.push(request);
              this.setState({summaryData:summaryArray , anniversaryDate: summaryArray[0].anniversaryDate})
          }

           


          }
        }).catch((err) => {
                  Utils.toastError(`Server Error, ${err}`, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  });
                 
                  //reject();
                });



  };

  getPTOReport() {
    let reportParam = [] as any;
    const storage = JSON.parse(localStorage.getItem("storage")!);
    let username = storage === null ? 0 : (storage.userName as any);
    let tenantId = storage === null ? 0 : (storage.tenantID as any);
    reportParam.push({
      name: "Status",
      value: "1",
    });
    reportParam.push({
      name: "Status",
      value: "1",
    });
    reportParam.push({
      name: "Report",
      value: "115",
    });
    reportParam.push({
      name: "ReportName",
      value: "PTO History Report",
    });
    reportParam.push({
      name: "userName",
      value: username,
    });
    reportParam.push({
      name: "ReportType",
      value: "ByEmployee",
    });
    reportParam.push({
      name: "StartDate",
      value: this.getFormattedDate(new Date()),
    });
    reportParam.push({
      name: "Employee",
      value: username,
    });
    reportParam.push({
      name: "tenantId",
      value: tenantId,
    });
    reportParam.push({
      name: "reportId",
      value: 10,
    });
    localStorage.setItem("reportParameters", JSON.stringify(reportParam));
    this.setState({ parameterset: true });
  }

  getHidPayPeriod() {
    let hotelSelected = JSON.parse(localStorage.getItem("hotelSelected")!);
    let hotelID = hotelSelected !== null ? hotelSelected.hotelID : "Select";
    this.setState({ hidValue: hotelID });
    let currentDay: any = new Date();
    if (JSON.parse(localStorage.storage).currentUtcTime !== "") {
      currentDay = new Date(JSON.parse(localStorage.storage).currentUtcTime);
    }
    laborPerformance.getPayPeriodDates(hotelID, currentDay, "Weekly").then(async (result: any[] | null) => {
      if (result != null) {
        this.setState({ navigationCalenderDates: [result[0].startdate, result[0].enddate] });
      }
    });
  }

  // exportToPDF = () => {
  //   let reportParameters = JSON.parse(
  //     localStorage.getItem("reportParameters")!
  //   );
  //   reportParameters.push({ name: "writerFormat", value: "Excel" });
  //   ReportExport.reportDownload(JSON.stringify(reportParameters)).then(
  //     (result) => {
  //       const href = window.URL.createObjectURL(result);
  //       const a = this.linkRef.current;
  //       a.download = "MyPTOHistory.xls";
  //       a.href = href;
  //       a.click();
  //       a.href = "";
  //     }
  //   );
  // };

  exportToPDF = () => {
    this.setState({ timeOffReportLoader: true })
    let reportParameters = JSON.parse(localStorage.getItem("reportParameters")!);
    reportParameters.push({ name: "writerFormat", value: "PDF" });
    ReportExport.reportDownload(JSON.stringify(reportParameters)).then((result) => {
      const blob = new Blob([result], { type: "application/pdf" });
      const href = window.URL.createObjectURL(blob);
      const a = this.linkRef.current;
      a.download = "MyPTOHistory.pdf"; 
      a.href = href; 
      a.click();
      this.setState({ timeOffReportLoader: false }) 
      a.href = "";
    });
  };

  addShiftRefresh = () => {
    if (this.state.viewType === "Users") {
      this.scheduleUserViewRef.current.getScheduleData("addShiftRefresh");
    } else {
      return false;
    }
  };

  
  handleOnPendingApprovalExpand = (row: any, isExpand: any, rowIndex: any, e: any) => {
    if (this.state.pendingApprovalTableExpended.length > 0) {
      this.setState(
        { expendedRowIndex: rowIndex, isExpand: false, loaderOverBudget: true },
        () => {
          this.setState(() => ({
            pendingApprovalTableExpended: [],
          }));
        }
      );
      row.action ="";
    } else {
    

      row.action = "PendingApprovalExpand";
      this.setState(() => ({
        pendingApprovalTableExpended: [...this.state.pendingApprovalTableExpended, row.rowNo],
      }));

      this.setState(
        { expendedRowIndex: rowIndex, isExpand: true, loaderOverBudget: false,requestpendingexpanded : true },
        () => {
        }
      );
     
      
    }
  };

  handleOnApprovedFutureTimeOffExpand = (row: any, isExpand: any, rowIndex: any, e: any) => {
    if (this.state.approvedFutureTimeOffTableExpended.length > 0) {
      this.setState(
        { expendedRowIndex: rowIndex, isExpand: false, loaderOverBudget: true },
        () => {
          this.setState(() => ({
            approvedFutureTimeOffTableExpended: [],
          }));
        }
      );
      row.action ="";
      
    } else {
    

      row.action = "ApprovedFutureTimeOffExpand";
      this.setState(() => ({
        approvedFutureTimeOffTableExpended: [...this.state.approvedFutureTimeOffTableExpended, row.rowNo],
      }));

      this.setState(
        { expendedRowIndex: rowIndex, isExpand: true, loaderOverBudget: false ,approvefuturetimeexpanded : true },
        () => {
        }
      );
    }
  };

  handleOnDeniedRequestExpand = (row: any, isExpand: any, rowIndex: any, e: any) => {
    if (this.state.deniedRequestTableExpended.length > 0) {
      this.setState(
        { expendedRowIndex: rowIndex, isExpand: false, loaderOverBudget: true },
        () => {
          this.setState(() => ({
            deniedRequestTableExpended: [],
          }));
        }
      );
      row.action ="";
      
    } else {
    

      row.action = "DeniedRequestExpand";
      this.setState(() => ({
        deniedRequestTableExpended: [...this.state.deniedRequestTableExpended, row.rowNo],
      }));

      this.setState(
        { expendedRowIndex: rowIndex, isExpand: true, loaderOverBudget: false,deniedexpanded : true  },
        () => {
        }
      );
    }
  };

  handleOnTimeOffHistoryExpand = (row: any, isExpand: any, rowIndex: any, e: any) => {
    if (this.state.timeOffHistoryTableExpended.length > 0) {
      this.setState(
        { expendedRowIndex: rowIndex, isExpand: false, loaderOverBudget: true },
        () => {
          this.setState(() => ({
            timeOffHistoryTableExpended: [],
          }));
        }
      );
      row.action ="";
      // this.setState({timeoffhistoryexpanded : false})
      
    } else {
    

      row.action = "TimeOffHistoryExpand";
      this.setState(() => ({
        timeOffHistoryTableExpended: [...this.state.timeOffHistoryTableExpended, row.rowNo],
      }));

      this.setState(
        { expendedRowIndex: rowIndex, isExpand: true, loaderOverBudget: false ,timeoffhistoryexpanded : true},
        () => {
        }
      );
    }
  };

  getCount = () => {
    this.GetLaborTimeoffSummaryCount("requestPendingApproval");
  }

  render() {
  console.log(this.state.requestPendingApprovalData);
    const PendingApprovalColumns = [
      {
        dataField: "action",
        formatter: (cell: any, row: any, rowIndex: any) => {
          
          if (row.action === "PendingApprovalExpand") {
            return (
              <span className="chevronExpended">
                <FontAwesomeIcon icon={faChevronDown} />
              </span>
            );
          } else {
            return (
              <span className="chevronCollpsed">
                <FontAwesomeIcon icon={faChevronRight} />
              </span>
            );
          }
        },
      },
      {
        dataField: "entryType",
       classes: "pl-2",
        editable: false,
        formatter: (cell: any, row: any, rowIndex: any) => (
         
            <div className="d-flex align-items-center">
             <span className="sub-heading-2">{this.state.requestPendingApprovalData[0].entryType}  </span> 
             {this.state.requestPendingApprovalData[0].Count !== 0 ? 
             <div className="count-badge badge"><span>{this.state.requestPendingApprovalData[0].Count}</span></div> : <></>}
            </div>
        ),
      },
    


    ];

    const ApprovedFutureTimeOffColumns = [
      {
        dataField: "action",
        formatter: (cell: any, row: any, rowIndex: any) => {
          if (row.action === "ApprovedFutureTimeOffExpand") {
            return (
              <span className="chevronExpended">
                <FontAwesomeIcon icon={faChevronDown} />
              </span>
            );
          } else {
            return (
              <span className="chevronCollpsed">
                <FontAwesomeIcon icon={faChevronRight} />
              </span>
            );
          }
        },
      },
      {
        dataField: "entryType",
       classes: "pl-2",
        editable: false,
        formatter: (cell: any, row: any, rowIndex: any) => (
         
            <div className="d-flex align-items-center">
             <span className="sub-heading-2">{this.state.approvedFutureTimeOffData[0].entryType}  </span> 
             {this.state.approvedFutureTimeOffData[0].Count !== 0 ? 
             <div className="count-badge badge"><span>{this.state.approvedFutureTimeOffData[0].Count}</span></div> : <></>}
            </div>
        ),
      },
    


    ];

    const DeniedRequestColumns = [
      {
        dataField: "action",
        formatter: (cell: any, row: any, rowIndex: any) => {
          if (row.action === "DeniedRequestExpand") {
            return (
              <span className="chevronExpended">
                <FontAwesomeIcon icon={faChevronDown} />
              </span>
            );
          } else {
            return (
              <span className="chevronCollpsed">
                <FontAwesomeIcon icon={faChevronRight} />
              </span>
            );
          }
        },
      },
      {
        dataField: "entryType",
       classes: "pl-2",
        editable: false,
        formatter: (cell: any, row: any, rowIndex: any) => (
         
            <div className="d-flex align-items-center">
            <span className="sub-heading-2">{this.state.deniedRequestData[0].entryType}  </span> 
            {this.state.deniedRequestData[0].Count !== 0 ? 
             <div className="count-badge badge"><span>{this.state.deniedRequestData[0].Count}</span></div> : <></>}
            </div>
        ),
      },
    


    ];

    const TimeOffHistoryColumns = [
      {
        dataField: "action",
        formatter: (cell: any, row: any, rowIndex: any) => {
          
          if (row.action === "TimeOffHistoryExpand" ) {
            return (
              <span className="chevronExpended">
                <FontAwesomeIcon icon={faChevronDown} />
              </span>
            );
          } else {
            return (
              <span className="chevronCollpsed">
                <FontAwesomeIcon icon={faChevronRight} />
              </span>
            );
          }
        },
      },
      {
        dataField: "entryType",
       classes: "pl-2",
        editable: false,
        formatter: (cell: any, row: any, rowIndex: any) => (
         
            <span className="d-flex align-items-center">
             <span className="sub-heading-2">{this.state.timeOffHistoryData[0].entryType}  </span> 
            </span>
        ),
      },
    ];


    const pendingApprovalExpandRow = {
      onlyOneExpanding: true,
      renderer: (row: any) => {      
        const rowColumns = [
          {
            dataField: "",
            headerClasses: "selection-cell-header",
            classes: "selection-cell",
            formatter: (cell: any, row: any, rowIndex: any) => (
              <span className="rowBlank"></span>
            ),
          },
          {
            dataField: "",
            headerClasses: "expand-collpse",
            classes: "expand-collpse",
            formatter: (cell: any, row: any, rowIndex: any) => (
              <span className="rowBlank"></span>
            ),
          },
 
    ];
        return (
          <>
          {this.state.requestpendingexpanded === true ? <RequestPendingApproval parentState={this.state} GetLaborTimeoffSummaryCount={this.getCount}/> : <></>}
             
          </>
        );
      },
      expanded: this.state.pendingApprovalTableExpended,
      onExpand: this.handleOnPendingApprovalExpand,
      nonExpandable: [1],
      
    };

    const approvedFutureTimeOffExpandRow = {
      onlyOneExpanding: true,
      renderer: (row: any) => {      
        const rowColumns = [
          {
            dataField: "",
            headerClasses: "selection-cell-header",
            classes: "selection-cell",
            formatter: (cell: any, row: any, rowIndex: any) => (
              <span className="rowBlank"></span>
            ),
          },
          {
            dataField: "",
            headerClasses: "expand-collpse",
            classes: "expand-collpse",
            formatter: (cell: any, row: any, rowIndex: any) => (
              <span className="rowBlank"></span>
            ),
          },
 
    ];
        return (
          <>
             {this.state.approvefuturetimeexpanded === true ? <ApprovedFutureTimeOff/> : <></>}
          </>
        );
      },
      expanded: this.state.approvedFutureTimeOffTableExpended,
      onExpand: this.handleOnApprovedFutureTimeOffExpand,
      nonExpandable: [1],
      
    };

    const deniedRequestExpandRow = {
      onlyOneExpanding: true,
      renderer: (row: any) => {      
        const rowColumns = [
          {
            dataField: "",
            headerClasses: "selection-cell-header",
            classes: "selection-cell",
            formatter: (cell: any, row: any, rowIndex: any) => (
              <span className="rowBlank"></span>
            ),
          },
          {
            dataField: "",
            headerClasses: "expand-collpse",
            classes: "expand-collpse",
            formatter: (cell: any, row: any, rowIndex: any) => (
              <span className="rowBlank"></span>
            ),
          },
 
    ];
        return (
          <>
             {this.state.deniedexpanded === true ? <DeniedRequest/> : <></> }
          </>
        );
      },
      expanded: this.state.deniedRequestTableExpended,
      onExpand: this.handleOnDeniedRequestExpand,
      nonExpandable: [1],
      
    };

    const timeOffHistoryExpandRow = {
      onlyOneExpanding: true,
      renderer: (row: any) => {      
        const rowColumns = [
          {
            dataField: "",
            headerClasses: "selection-cell-header",
            classes: "selection-cell",
            formatter: (cell: any, row: any, rowIndex: any) => (
              <span className="rowBlank"></span>
            ),
          },
          {
            dataField: "",
            headerClasses: "expand-collpse",
            classes: "expand-collpse",
            formatter: (cell: any, row: any, rowIndex: any) => (
              <span className="rowBlank"></span>
            ),
          },
 
    ];
        return (
          <>
          {this.state.timeoffhistoryexpanded === true ? <TimeOffHistory/> : <></>}
          </>
        );
      },
      expanded: this.state.timeOffHistoryTableExpended,
      onExpand: this.handleOnTimeOffHistoryExpand,
      nonExpandable: [1],
      
    };

    const columns = [
      {
        dataField: "ptoName",
        text: "",
        editable: false,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="item-name summary-sub-title">
            <EllipsisWithTooltip placement="bottom">
              <span>{row.ptoName }</span><span> : </span><span className="value">{ row.balance}</span>
            </EllipsisWithTooltip>
          </div>
        ),
      },
    ]

    const currentData = this.state.summaryData.filter(item => item.displayType.includes("Current"));
    const pendingData = this.state.summaryData.filter(item => item.displayType.includes("Future"));
    return (
      <div className="report-viewer-container">
        {this.state.parameterset === true && (
          <div className="my-pto-main">
            <Container fluid className="body-sec profile-pto">
              <div className="page-heading mrgn-btn-24 underline d-flex">
                <div className="mr-auto">My Time Off</div>
                <div className="action-group">
                  {<a ref={this.linkRef}></a>}
                  <Button
                    className="btn btn-outline-primary"
                    onClick={this.exportToPDF}
                  >
                    {/* PTO Report */}
                    Time Off Report
                    
                    {this.state.timeOffReportLoader && <Spinner className="ml-1" size="sm" animation={"border"} />}
                  </Button>

                  <Button
                    className="add-time-off-shift btn-primary"
                    onClick={() => this.addEditTimeOffShiftModalShow()}
                  >
                    Request Time Off
                  </Button>
                </div>
              </div>
              <div className="pto-report PTO-summary-content">
              <h3 className="summary-title">Time Off Summary</h3>
              <h4 className="summary-sub-title" style={{paddingBottom: "4px"}}>Anniversary Date : {this.state.anniversaryDate}</h4>
              {/* {this.state.summaryData.length > 0 &&  */}
              
                {/* {currentData.length > 0 && (
                  <BootstrapTable
                    keyField="id"
                    data={currentData}
                    columns={columns}
                    headerClasses="d-none"
                  />
                )} */}
              <div className="summary-container">
                  {/* For current */}
                  <div className="summary-wrapper">
                    {
                      currentData.map((item) => {
                        return (
                          <div className="PTO-item">
                            <h4 className="summary-sub-title">{item.ptoName} : <span>{item.balance}</span></h4>
                        </div>
                        )
                      })
                    }
                  </div>
                  {/* For Pending */}
                  <div className="summary-wrapper">
                    {
                      pendingData.map((item) => {
                        return (
                          <div className="PTO-item">
                            <h4 className="summary-sub-title">{item.ptoName} : <span>{item.balance}</span></h4>
                        </div>
                        )
                      })
                    }
                  </div>
              </div>
        {/* {pendingData.length > 0 && (
          <BootstrapTable
            keyField="id"
            data={pendingData}
            columns={columns}
            headerClasses="d-none"
          />
        )} */}
              {/* <BootstrapTable
                keyField="id"
                data={this.state.summaryData}
                columns={columns}
                headerClasses="d-none"
                /> */}
              
              {/* } */}
              {/* <div className="d-flex align-items-center">
                  <h4 className="summary-sub-title">Current PTO Balance : <span>22:22</span></h4>
                  <h4 className="summary-sub-title ml-5" style={{paddingLeft: "3px"}}>Current Personal leave Balance : <span>22:22</span></h4>
              </div>
              <div className="d-flex align-items-center">
                  <h4 className="summary-sub-title">Pending PTO Balance : <span>22:22</span></h4>
                  <h4 className="summary-sub-title ml-5">Pending Personal leave Balance : <span>22:22</span></h4>
              </div> */}
                {/* <ReportViewer onReportload={this.props?.onReportload} /> */}
              </div>


               {this.state.requestPendingApproval && (
                <div className="my-PTO-wrapper pendingapproval">
                <BootstrapTable
                keyField="rowNo"
                data={this.state.requestPendingApprovalData}
                columns={PendingApprovalColumns}
                hover
                expandRow={pendingApprovalExpandRow}
                headerClasses="d-none"
                />
                </div>

                )}

                {this.state.approvedFutureTimeOff && (
                <div className="my-PTO-wrapper approvedfuturetimeoff">
                <BootstrapTable
                keyField="rowNo"
                data={this.state.approvedFutureTimeOffData}
                columns={ApprovedFutureTimeOffColumns}
                hover
                expandRow={approvedFutureTimeOffExpandRow}
                headerClasses="d-none"
                />
                </div>

              )}

                {this.state.deniedRequest && (
                <div className="my-PTO-wrapper deniedrequest">
                <BootstrapTable
                keyField="rowNo"
                data={this.state.deniedRequestData}
                columns={DeniedRequestColumns}
                hover
                expandRow={deniedRequestExpandRow}
                headerClasses="d-none"
                />
                </div>

              )}

              {this.state.timeOffHistory && (
                <div className="my-PTO-wrapper timeoffhistory">
                <BootstrapTable
                keyField="rowNo"
                data={this.state.timeOffHistoryData}
                columns={TimeOffHistoryColumns}
                hover
                expandRow={timeOffHistoryExpandRow}
                headerClasses="d-none"
                />
                </div>

              )}




              {this.state.addEditTimeOffShiftModal && (
                <AddEditTimeOffShiftModal
                  parentState={this.state}
                  addEditTimeOffShiftModalHide={
                    this.addEditTimeOffShiftModalHide
                  }
                  //redirectState={}
                  addShiftRefresh={this.addShiftRefresh}
                />
              )}
            </Container>
          </div>
        )}
      </div>
    );
  }
}
