import Instense from "./Axios-config";
import { IGlobalSettingsRequest } from './../Contracts/IGlobalSettings';
import { IRoomTypeNew } from './../Contracts/IRoomType';
import { IConferenceRoomRequest } from "../Contracts/IConferenceRoomRequest";
import { IRoom, ISaveRoom } from "../Contracts/IRoom";
import { ISaveHotelKeySetupRequest } from "../Contracts/ISaveHotelKeySetupRequest";

export class EntityManagementService {

    public static GetHotelModules = async (
      HotelId: number
    ): Promise< any[] | null> => {
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : (storage.tenantID as any);
      let request = {} as  any;
      request.TenantId = tenantID;
      request.HotelId = HotelId;
      const url = `/EntityManagement/GetHotelModules`;
      return Instense.get(url, { params: request }).then((response) => {
        // console.log(response);
        // debugger
        let result = response.data?.result as  any;
        return result;
      });
    };

    public static GetAllModules = async (): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        const url = `/EntityManagement/GetAllModules`;
        return Instense.get(url, { params: null }).then((response) => {
          // console.log(response);
          let result = response.data.result as  any;
          return result;
        });
      };

      public static UpdateHotelDefaultSetting = async (
        AllowPerformanceReview:number, 
        AllowTimeOffReview:number,
        AllowDDReview:number,
        AllowBreakAttestation:number,
        showLocalTaxFields:number,
        HotelId:number,
        ClientID:string,
        APIKey:string,
        APISecret:string
      ): Promise< any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        let request = {} as  any;
        request.TenantId = tenantID;
        request.HotelId = HotelId;
        request.AllowPerformanceReview = AllowPerformanceReview;
        request.AllowTimeOffReview = AllowTimeOffReview;
        request.AllowBreakAttestation = AllowBreakAttestation;
        request.AllowDDReview = AllowDDReview;
        request.showLocalTaxFields = showLocalTaxFields;
        request.AllowBreakAttestation = AllowBreakAttestation;
        request.ClientId = ClientID;
        request.APIKey = APIKey;
        request.APISecret = APISecret;
        const url = `/EntityManagement/UpdateHotelDefaultSetting`;
        return Instense.get(url, { params: request }).then((response) => {
         
          let result = response.data as  any;
          return result;
        });
      };

      public static UpdateHotelGlobalSetting = async (request:IGlobalSettingsRequest
      ): Promise< any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.tenantID = tenantID;
        const url = `/EntityManagement/UpdateHotelGlobalSetting`;
        return Instense.post(url, request).then((response) => {
          // console.log(response);
          let result = response.data as  any;
          return result;
        });
      };

      public static GetAllRooms = async (
        HotelId: number
      ): Promise< any[] | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        let request = {} as  any;
        request.TenantId = tenantID;
        request.Hid = HotelId;
        const url = `/EntityManagement/GetAllRooms`;
        return Instense.get(url, { params: request }).then((response) => {
          // console.log(response);
          let result = response.data?.result as  any;
          return result;
        });
      };

      public static GetConferenceRooms = async (
        hotelId: number
      ): Promise< any[] | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        let request = {} as  any;
        request.TenantId = tenantID;
        request.Hid = hotelId;
        const url = `/EntityManagement/GetConferenceRooms`;
        return Instense.post(url, null, { params: request }).then((response) => {
          // console.log(response);
          let result = response.data?.result as  any;
          return result;
        });
      };

      public static SaveConferenceRoom = async (
        request: IConferenceRoomRequest
      ): Promise< any[] | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.tenantID = tenantID;
        const url = `/EntityManagement/SaveConferenceRoom`;
        return Instense.post(url, request).then((response) => {
          // console.log(response);
          let result = response.data as  any;
          return result;
        });
      };

      public static ValidateRoomType = async (
        request:any[],DeleteRoomIds, Hid
      ): Promise< any[] | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request= request.map((item: IRoomTypeNew)=> {
          item.tenantId = tenantID;
          return item;
        });
        const url = `/EntityManagement/ValidateRoomType`;

        const data={
          RoomTypeNew:request,
          DeleteRoomIds:DeleteRoomIds,
          TenantID:tenantID,
          Hid:Hid
        }
        return Instense.post(url, data).then((response) => {
          // console.log(response);
          let result = response.data as  any;
          return result;
        });
      };

      public static ValidateRoom = async (
        request:ISaveRoom
      ): Promise< any[] | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.TenantID = tenantID;
        const url = `/EntityManagement/ValidateRoomName`;

       
        return Instense.post(url, request).then((response) => {
          // console.log(response);
          let result = response.data as  any;
          return result;
        });
      };

      

      public static GetPayrollImportAccount = async (
        HotelId: number
      ): Promise< any[] | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        let request = {} as  any;
        request.TenantID = tenantID;
        request.Hid = HotelId;
        const url = `/EntityManagement/GetPayrollImportAccount`;
        return Instense.post(url, null, { params: request }).then((response) => {
          let result = response.data as any
          return result;
        });
      };

      public static SavePayrollImport = async (
        data: any, hid 
      ): Promise< any[] | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        let request = {} as  any;
        request.TenantID = tenantID;
        request.Hid = hid;
        request.OprID = data.OperatingID;
        request.PayOprID = data.PayOprId;
        request.AutoTransfer = data.AutoTransfer;
        const url = `/EntityManagement/SavePayrollImport`;
        return Instense.post(url, null, { params: request }).then((response) => {
          let result = response.data as any
          return result;
        });
      };

      
      public static GetRoomType = async (
        HotelId: number
      ): Promise< any[] | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        let request = {} as  any;
        request.tenantId = tenantID;
        request.hotelId = HotelId;
        const url = `/EntityManagement/GetRoomType`;
        return Instense.get(url, { params: request }).then((response) => {
          // console.log(response);
          let result = response.data?.result as  any;
          return result;
        });
      };
      public static SaveRoomTypeOld = async (request:IRoomTypeNew[]
        ): Promise< any | null> => {
          let storage = JSON.parse(localStorage.getItem("storage")!);
          let tenantID = storage === null ? 0 : (storage.tenantID as any);
          request= request.map((item: IRoomTypeNew)=> {
            item.tenantId = tenantID;
            return item;
          });
          const url = `/EntityManagement/SaveRoomType`;
          return Instense.post(url, request).then((response) => {
            // console.log(response);
            let result = response.data as  any;
            return result;
          });
        };

      public static SaveRoomType = async (request:IRoomTypeNew[],DeleteRoomIds, Hid,AccountingDate,PrevAccountingDate, roomList
        ): Promise< any | null> => {
          let storage = JSON.parse(localStorage.getItem("storage")!);
          let tenantID = storage === null ? 0 : (storage.tenantID as any);
          request= request.map((item: IRoomTypeNew)=> {
            item.tenantId = tenantID;
            item.accountingDate = AccountingDate ? AccountingDate : PrevAccountingDate;
            item.roomList = roomList.filter(x => x.RoomID === 0 || (typeof x.RoomID === 'number' && x.RoomID !== 0)).filter( x => x.RoomTypeId == item.PrevRoomTypeId).map(x => ({...x, TenantID:tenantID}))
            return item;
          });
          const url = `/EntityManagement/SaveRoomType`;

          const data={
            RoomTypeNew:request,
            DeleteRoomIds:DeleteRoomIds,
            TenantID:tenantID,
            Hid:Hid,
            AccountingDate:AccountingDate ? AccountingDate : PrevAccountingDate,
            PrevAccountingDate:PrevAccountingDate ? PrevAccountingDate : AccountingDate,
      
          }

          
          return Instense.post(url, data).then((response) => {
            // console.log(response);
            let result = response.data as  any;
            return result;
          });
        };

        public static SaveBulkRoom = async (request:ISaveRoom[]): Promise< any | null> => {
            let storage = JSON.parse(localStorage.getItem("storage")!);
            let tenantID = storage === null ? 0 : (storage.tenantID as any);
            request= request.map((item: ISaveRoom)=> {
              item.TenantID = tenantID;
              return item;
            });
            const url = `/EntityManagement/SaveBulkRoom`;
  
            
            return Instense.post(url, request).then((response) => {
              // console.log(response);
              let result = response.data as  any;
              return result;
            });
          };

        public static DeleteRoomType = async (uniqueno: any): Promise<any | null> => {
          let storage = JSON.parse(localStorage.getItem("storage")!);
          let tenantID = storage === null ? 0 : storage.tenantID as any;
          var request: any = {};
          request.uniqueno = uniqueno;
          request.tenantID = tenantID;
          const url = `/EntityManagement/DeleteRoomType`;
          return Instense.get(url, { params: request }).then((response) => {
            let result = response.data as any;
            return result;
          });
        };

        public static DeleteConferenceRooms = async (roomID: any): Promise<any | null> => {
          let storage = JSON.parse(localStorage.getItem("storage")!);
          let tenantID = storage === null ? 0 : storage.tenantID as any;
          var request: any = {};
          request.roomID = roomID;
          request.tenantID = tenantID;
          const url = `/EntityManagement/DeleteConferenceRooms`;
          return Instense.get(url, { params: request }).then((response) => {
            let result = response.data as any;
            return result;
          });
        };
        
        public static GetHotelGroupMapping = async (): Promise<any | null> => {
          let storage = JSON.parse(localStorage.getItem("storage")!);
          let tenantID = storage === null ? 0 : storage.tenantID as any;
          var request: any = {};
          request.tenantId = tenantID;
          const url = `/EntityManagement/GetHotelGroupMapping`;
          return Instense.get(url, { params: request }).then((response) => {
            let result = response.data?.result as any;
            return result;
          });
        };


        public static AddHotelCategory = async (category: string,uniqueno: number = 0): Promise<any | null> => {
          let storage = JSON.parse(localStorage.getItem("storage")!);
          let tenantID = storage === null ? 0 : storage.tenantID as any;
          var request: any = {};
          request.category =category;
          request.uniqueno =uniqueno;
          request.tenantId = tenantID;
          const url = `/EntityManagement/AddHotelCategory`;
          return Instense.post(url,null, { params: request }).then((response) => {
            let result = response.data as any;
            return result;
          });
        };

        public static SaveGroupWithHotel = async (request: any): Promise<any | null> => {
          let storage = JSON.parse(localStorage.getItem("storage")!);
          let tenantID = storage === null ? 0 : storage.tenantID as any;
          request.TenantId = tenantID;
          const url = `/EntityManagement/SaveGroupWithHotel`;
          return Instense.post(url, request).then((response) => {
            let result = response.data as any;
            return result;
          });
          
        };

        public static SaveCategoriesGroupWithHotel = async (requests: any[]): Promise<any | null> => {
          let storage = JSON.parse(localStorage.getItem("storage")!);
          let tenantID = storage === null ? 0 : storage.tenantID as any;
          requests = requests?.map(request=> {
            request.TenantId = tenantID;
            return request;
          });
      
          const url = `/EntityManagement/SaveCategoriesGroupWithHotel`;
          return Instense.post(url, requests).then((response) => {
            let result = response.data as any;
            return result;
          });
          
        };

        

        public static DeleteHotelCategory = async (uniqueno: number =0): Promise<any | null> => {
          let storage = JSON.parse(localStorage.getItem("storage")!);
          let tenantID = storage === null ? 0 : storage.tenantID as any;
          const request: any = {};
          request.Uniqueno = uniqueno;
          request.tenantId = tenantID;
          const url = `/EntityManagement/DeleteHotelCategory`;
          return Instense.get(url, {params: request}).then((response) => {
            let result = response.data as any;
            return result;
          });
        };

        public static DeleteHotelCategoryGroup = async (id: number =0): Promise<any | null> => {
          let storage = JSON.parse(localStorage.getItem("storage")!);
          let tenantID = storage === null ? 0 : storage.tenantID as any;
          const request: any = {};
          request.id =id;
          request.tenantId = tenantID;
          const url = `/EntityManagement/DeleteHotelCategoryGroup`;
          return Instense.get(url, {params: request}).then((response) => {
            let result = response.data as any;
            return result;
          });
        };
        public static DeleteGroupHotel = async (id: number =0, hid:number =0): Promise<any | null> => {
          let storage = JSON.parse(localStorage.getItem("storage")!);
          let tenantID = storage === null ? 0 : storage.tenantID as any;
          const request: any = {};
          request.id =id;
          request.hid =hid;
          request.tenantId = tenantID;
          const url = `/EntityManagement/DeleteGroupHotel`;
          return Instense.get(url, {params: request}).then((response) => {
            let result = response.data as any;
            return result;
          });
        };

        
  


  public static GetHotelkeyAPISetup = async (
    HotelId: number
  ): Promise< any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request = {} as  any;
    request.Tenantid = tenantID;
    request.Hid = HotelId;
    const url = `/EntityManagement/GetHotelkeyAPISetup`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data?.result as  any;
      return result;
    });
  };

  public static GetHotelAPI = async (
    HotelId: number
  ): Promise< any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request = {} as  any;
    request.Tenantid = tenantID;
    request.Hid = HotelId;
    const url = `/EntityManagement/GetHotelAPI`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data?.result as  any;
      return result;
    });
  };

  public static SaveHotelkeyAPISetup = async (request: ISaveHotelKeySetupRequest): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID as any;   
    request.tenantid = tenantID;
    const url = `/EntityManagement/SaveHotelkeyAPISetup`;
    return Instense.post(url, request).then((response) => {
      let result = response.data.result as  any;
      return result;
    });
  };

  public static GetCloudbedsApiKey = async (
    cloudbedsApiKey: string
  ): Promise< any[] | null> => {
    let request = {} as  any;
    request.APIKey = cloudbedsApiKey;
    const url = `/EntityManagement/GetCloudbedsApiKey`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data?.result as  any;
      return result;
    });
  };
}