import * as React from "react";
import { Container, Button, Dropdown, Form } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-calendar/dist/Calendar.css";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { HidSelector } from "../../Common/Components/HidSelector";
import { SingleSearchDropdownList } from "../../Common/Components/SingleSearchDropdownList";
import { Facility } from "../../Common/Services/Facility";
import "./Location.scss";
import "./facilities.scss";
import _ from "lodash";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { IUserRole } from "../../Common/Contracts/IUserRole";
import { UserManagement } from "../../Common/Services/UserManagement";
import taskIcon from "../../Common/Assets/Images/icons/daily-tasks.svg";
import "./DailyTask.scss";
import DailyTaskSlideOut from "./DailyTaskSlideOut";
import { ReactPageLoader } from "../../Common/Components/ReactPageLoader";
import { IDailyTaskCreationRequest } from "../../Common/Contracts/IDailyTask";
import { FacilityDailyTask } from "../../Common/Services/FacilityDailyTask";
import { AddDailyTask } from "./AddDailyTask";
import { Utils } from "../../Common/Utilis";

export class DailyTaskCreation extends React.Component<any, any> {
  private child: any;
  private childHID: any;
  private departmentSearch: any;
  private empTypeSearch: any;
  private tableTypeSearch: any;
  private scheduleUserViewRef: any;
  private checkAllRef: any;
  constructor(props: any) {
    super(props);
    this.scheduleUserViewRef = React.createRef();
    this.child = React.createRef();
    this.childHID = React.createRef();
    this.departmentSearch = React.createRef();
    this.empTypeSearch = React.createRef();
    this.tableTypeSearch = React.createRef();
    this.checkAllRef = React.createRef();
    this.state = {
      pageLoader: false,
      showAddDailyTaskModal: false,
      hidValue: "Select",
      hotelName: "Select",
      tableData: [],
      isDataExist: false,
      userRoles: [],
      createEditPermission: true,
      locationList: [],
      searchText: "",
      role: -1,
      sortingOrder: "asc",
      sortingField: "taskName",
      showSlideOut: false,
      rowData: {},
      selectedRows: [],
      isBulkSelect: false,
    };
  }

  componentDidMount() {
    this.getUserRoleList();
    this.CheckAddEditPermission();
  }

  CheckAddEditPermission = () => {
    FacilityDailyTask.DailyTaskCheckAddEditPermission().then((res) => {
      this.setState({ createEditPermission: res?.result });
    });
  };

  handleShowSlideout = (row: any) => {
    this.setState({
      showSlideout: true,
      rowData: { ...row, dailyTaskPermission: "Yes" },
    });
  };

  handleHideSlideout = (isReload) => {
    this.setState({
      showSlideout: false,
    });

    if (isReload) {
      this.getLocationList();
      this.getFacilityDailyTaskTemplate();
    }
  };

  selectHotelDropDown = (hotelDetails: any) => {
    this.setState({ pageLoader: true });

    this.setState(
      {
        hotelName: hotelDetails.lettercode,
        hidValue: hotelDetails.hotelID,
        selectedRows: [],
        isBulkSelect: false,
        searchText: "",
        role: -1,
      },
      () => {
        this.getLocationList();
        this.getFacilityDailyTaskTemplate(true);
      }
    );
  };

  getFacilityDailyTaskTemplate = (isHotelChanged = false) => {
    const { searchText, hidValue, role, userRoles } = this.state;
    const request: IDailyTaskCreationRequest = {
      SearchText: searchText,
      Hid: hidValue,
      Role:
        role == -1 ? "" : userRoles.find((item) => +item?.id === +role)?.name,
    };

    const filterApplied = searchText.length > 0 || role != -1;

    this.setState({ pageLoader: true });
    FacilityDailyTask.GetFacilityDailyTaskTemplate(request)
      .then((tableData) => {
        this.setState({
          isDataExist: isHotelChanged
            ? (tableData?.length || 0) > 0 || filterApplied
              ? true
              : false
            : filterApplied
            ? true
            : (tableData?.length || 0) > 0
            ? true
            : false,
          tableData: _.sortBy(tableData, "taskName"),
          pageLoader: false,
          isBulkSelect: false,
          selectedRows: [],
        });
      })
      .catch((err) => {
        if (err.message != "Cancel") {
          this.setState({ pageLoader: false });
        }
      });
  };

  DeactivateDailyTasks = (ids) => {
    FacilityDailyTask.DeactivateDailyTasks(ids)
      .then((result) => {
        if (result.success) {
          toast.success(result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          Utils.toastError(result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }

        this.getFacilityDailyTaskTemplate();
      })
      .catch((err) => {
        this.setState({ pageLoader: false });
      });
  };

  getUserRoleList = () => {
    UserManagement.GetUserRoleList().then((response: IUserRole[]) => {
      // response = _.orderBy(response,  [item => item.roleName.toLowerCase()], ["asc"]);
      const userRoles = [
        { id: -1, name: "All" },
        ...response.map((item) => ({
          id: item.roleID,
          name: item.roleName,
        })),
      ];

      this.setState({ userRoles });
    });
  };

  onSearchChange = (searchText: string) => {
    this.setState(
      { searchText: searchText?.toString()?.trimStart(), pageLoader: true },
      () => {
        // if (searchText?.length > 1 || !searchText) {
        let debounce = _.debounce(this.getFacilityDailyTaskTemplate, 1000);
        debounce();
        // }
      }
    );
  };

  onDDlFiltersChange = (controlID: any, id: any) => {
    let { role } = this.state;
    switch (controlID) {
      case "ddlRole":
        role = id;
        break;
    }

    this.setState({ role }, () => {
      this.getFacilityDailyTaskTemplate();
    });
  };

  handleSortByColumn = (field) => {
    this.setState(
      {
        sortingField: field,
        sortingOrder: this.state.sortingOrder === "asc" ? "desc" : "asc",
      },
      () => {
        this.sortTableData(this.state.tableData, this.state.sortingField);
      }
    );
  };

  sortTableData = (data: any, fieldName) => {
    if (this.state.sortingOrder === "asc") {
      data = _.orderBy(data, [fieldName], ["asc", "asc"]);
    } else if (this.state.sortingOrder === "desc") {
      data = _.orderBy(data, [fieldName], ["desc", "asc"]);
    }
    this.setState({ tableData: data });
  };
  addNewDailyTask = () => {
    this.setState({ showAddDailyTaskModal: true });
  };

  hideAddDailyTask = (isReload = false, row = null) => {
    this.setState({ showAddDailyTaskModal: false }, () => {
      //Call Get Data
      if (isReload) {
        this.getFacilityDailyTaskTemplate();

        if (row) {
          this.handleShowSlideout(row);
        }
      }
    });
  };

  sortImage = (order) => {
    if (!order)
      return (
        <span className="ascending">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
              fill="#1D1E20"
            />
          </svg>
        </span>
      );
    else if (order === "asc")
      return (
        <span className="ascending">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
              fill="#1D1E20"
            />
          </svg>
        </span>
      );
    else if (order === "desc")
      return (
        <span className="descending">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
              fill="#1D1E20"
            />
          </svg>
        </span>
      );

    return null;
  };

  getLocationList = () => {
    Facility.GetDistinctRoomsList(this.state.hidValue)
      .then((res) => {
        const _res = res.map((x) => ({ ...x, id: x.roomId, name: x.roomName }));
        this.setState({ locationList: _res });
      })
      .catch((res) => {
        this.setState({ locationList: [] });
      });
  };

  refreshTable = (data) => {
    this.setState(
      {
        tableData: [],
      },
      () => this.setState({ tableData: _.cloneDeep(data) })
    );
  };

  handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      this.setState(
        () => ({
          selectedRows: [...this.state.selectedRows, row.taskTemplateId],
        }),
        () => this.refreshTable(this.state.tableData)
      );
    } else {
      this.setState(
        () => ({
          selectedRows: this.state.selectedRows.filter(
            (x) => x !== row.taskTemplateId
          ),
        }),
        () => this.refreshTable(this.state.tableData)
      );
    }
  };

  handleOnSelectAll = (isSelect, rows) => {
    const ids = this.state.tableData.map((r) => r.taskTemplateId);
    if (
      isSelect ||
      (this.state?.selectedRows?.length > 0 &&
        this.state.tableData?.length != this.state?.selectedRows?.length)
    ) {
      this.setState({ selectedRows: [] }, () =>
        this.setState(
          {
            selectedRows: ids,
          },
          () => this.refreshTable(this.state.tableData)
        )
      );
    } else {
      this.setState(
        {
          selectedRows: [],
        },
        () => this.refreshTable(this.state.tableData)
      );
    }
  };

  handleBulkSelect = () => {
    this.setState({ isBulkSelect: true });
  };

  handleDeactivate = (row) => {
    //handle
    this.DeactivateDailyTasks([row.taskTemplateId]);
  };

  handleBulkDeactivate = (ids) => {
    this.DeactivateDailyTasks(ids);
  };

  footerBulkSelectAll = (e) => {
  
    let ischecked = e.target.checked;
    const ids = this.state.tableData.map((r) => r.taskTemplateId);
    let selectedRows: any[] = [];
    if (ischecked) {
      this.setState({ selectedRows: [] }, () =>
        this.setState(
          {
            selectedRows: ids,
          },
          () => this.refreshTable(this.state.tableData)
        )
      );
    } else {
      this.setState(
        {
          selectedRows: [],
        },
        () => this.refreshTable(this.state.tableData)
      );
    }

    this.setState({ selectedRows });
  };

  closeBulkAction = () => {
    this.setState(
      {
        selectedRows: [],
        isBulkSelect: false,
      },
      () => this.refreshTable(this.state.tableData)
    );
  };

  render() {
    const {
      userRoles,
      role,
      isDataExist,
      pageLoader,
      tableData,
      selectedRows,
      isBulkSelect,
    } = this.state;

    // const tableRowEvents = {
    //   onClick: (e, row, rowIndex) => {
    //     console.log({ e });
    //     this.handleShowSlideout(row);
    //   },
    // };

    const tableColumnEvent = {
      onClick: (e, column, columnIndex, row, rowIndex) => {
        this.handleShowSlideout(row);
      },
    };

    const columns = [
      {
        dataField: "taskName",
        text: "Task",
        events: tableColumnEvent,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="task-name">
              <EllipsisWithTooltip placement="bottom">
                {row.taskName}
              </EllipsisWithTooltip>
            </div>
          );
        },
      },
      {
        dataField: "role",
        text: "Role",
        events: tableColumnEvent,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <>
              <EllipsisWithTooltip placement="bottom">
                {row.role}
              </EllipsisWithTooltip>
            </>
          );
        },
      },
      {
        dataField: "location",
        text: "Location",
        events: tableColumnEvent,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <>
              <EllipsisWithTooltip>{row.location}</EllipsisWithTooltip>
            </>
          );
        },
      },
      {
        dataField: "",
        text: "",
        formatExtraData: { isBulkSelect },
        formatter: (cell: any, row: any, rowIndex: any, extraData: any) => {
          return (
            <>
              <Dropdown
                onClick={(e) => {
                  // e.stopPropagation();
                }}
                className={`more-action text-right position-static ${
                  !this.state?.createEditPermission || extraData.isBulkSelect
                    ? "disabled"
                    : ""
                }`}
              >
                <DropdownToggle
                  className={`btn-outline-primary btn btn-primary more ${
                    !this.state?.createEditPermission ? "disabled" : ""
                  }`}
                  id="dropdown-more"
                  disabled={
                    !this.state?.createEditPermission || extraData.isBulkSelect
                  }
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                  </svg>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={this.handleBulkSelect}>
                    Bulk Select
                  </DropdownItem>
                  <DropdownItem onClick={() => this.handleDeactivate(row)}>
                    Deactivate
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </>
          );
        },
      },
    ];

    const selectRow = {
      mode: "checkbox",
      nonSelectable: !this.state?.createEditPermission
        ? tableData?.map((r) => r?.id)
        : [],
      selected: selectedRows,
      hideSelectColumn: !isBulkSelect,
      onSelect: this.handleOnSelect,
      onSelectAll: (isSelect, rows) =>
        !this.state?.createEditPermission
          ? {}
          : this.handleOnSelectAll(isSelect, rows),
      selectionHeaderRenderer: ({ mode, ...rest }) => (
        <Form.Check
          type={mode}
          {...rest}
          label=""
          custom
          disabled={!this.state?.createEditPermission}
          ref={(chkRef) => {
            if (chkRef) {
              this.checkAllRef = chkRef;
              if (
                selectedRows?.length === tableData.length &&
                tableData?.length > 0
              ) {
                chkRef.checked = true;
              } else {
                chkRef.checked = false;
              }

              if (selectedRows?.length > 0 && !chkRef?.checked) {
                chkRef.indeterminate = true;
              } else {
                chkRef.indeterminate = false;
              }
            }
          }}
        />
      ),
      selectionRenderer: ({ mode, ...rest }) => (
        <>
          {/* <input type={ mode } { ...rest } /> */}
          <Form.Check
            type={mode}
            {...rest}
            label=""
            custom
            disabled={!this.state?.createEditPermission}
          />
        </>
      ),
    };

    return (
      <>
        {this.state.showAddDailyTaskModal && (
          <AddDailyTask
            hid={this.state.hidValue}
            showModal={this.state.showAddDailyTaskModal}
            hideModal={this.hideAddDailyTask}
            locationList={this.state.locationList}
          />
        )}

        <div className="performance-position performance timesheet location-page-wrapper daily-task-wrapper">
          <Container fluid className="body-sec">
            <div className="page-heading underline d-flex">
              <HidSelector
                onClickEvent={this.selectHotelDropDown}
                ref={this.childHID}
                modulename={"Facility Maintenance"}
                disabled={isBulkSelect}
              />
              <div className="mr-auto d-flex align-items-center">
                Daily Tasks Settings
              </div>

              {this.state.hidValue !== "Select" &&
                this.state.createEditPermission && (
                  <Button
                    disabled={isBulkSelect}
                    onClick={this.addNewDailyTask}
                  >
                    Add Task
                  </Button>
                )}

              <Dropdown className="more-action ml-3">
                <DropdownToggle
                  className="btn-outline-primary btn btn-primary more"
                  disabled={!this.state.createEditPermission || isBulkSelect}
                  id="dropdown-more"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                  </svg>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem></DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>

            {this.state.hidValue === "Select" ? (
              <></>
            ) : isDataExist == false && pageLoader == false ? (
              <div className="task-wrapper">
                <div>
                  <img src={taskIcon} alt="Daily task set up" />
                </div>
                <h5>You don't have any daily tasks set up</h5>
                <p>Get started by adding your first task</p>
                {this.state.hidValue !== "Select" &&
                  this.state.createEditPermission && (
                    <div>
                      <Button
                        disabled={isBulkSelect}
                        onClick={this.addNewDailyTask}
                        className="add-tsk-link"
                      >
                        Add Task
                      </Button>
                    </div>
                  )}
              </div>
            ) : (
              this.state.hidValue !== "Select" && (
                <div className="main-Page">
                  <div className="bdy-sec">
                    <ToolkitProvider
                      keyField="userUniqueno"
                      data={this.state.tableData}
                      columns={columns}
                      hover
                    >
                      {(props: {
                        searchProps: JSX.IntrinsicAttributes;
                        baseProps: JSX.IntrinsicAttributes;
                      }) => (
                        <div>
                          <div className="search-header d-flex">
                            <div className="schedule-main">
                              <div className="schedule-department-view-table">
                                <div className="departmentViewNavigation">
                                  <div className="navigation-menus d-flex">
                                    <div className="schedule-table-search">
                                      <input
                                        type="text"
                                        disabled={isBulkSelect}
                                        onChange={(e) =>
                                          this.onSearchChange(e.target.value)
                                        }
                                        value={this.state.searchText}
                                        className="search-box form-control"
                                        placeholder="Search..."
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="role-dropdown">
                              {userRoles.length > 0 && (
                                <SingleSearchDropdownList
                                  id={"tabletypeddl"}
                                  isRefresh={this.state.isRefresh}
                                  isdisable={isBulkSelect}
                                  itemList={userRoles}
                                  handleSelectedItem={this.onDDlFiltersChange.bind(
                                    this,
                                    "ddlRole"
                                  )}
                                  defaultItem={role}
                                  defaultText={"Search Role"}
                                  defaultName={
                                    +role < 1
                                      ? "Role"
                                      : userRoles.find(
                                          (item) => +item?.id === +role
                                        )?.name
                                  }
                                  isStatusbadge={false}
                                  controlID="ddlRole"
                                />
                              )}
                            </div>
                          </div>

                          <div
                            className={`hasCursorPointer position-relative ${
                              isBulkSelect ? "set-checkbox-col-width" : ""
                            }`}
                          >
                            <div className="block-bar"></div>
                            {this.state.pageLoader ? (
                              <ReactPageLoader useas={"dashboardTable"} />
                            ) : (
                              <BootstrapTable
                                id={"taskTemplateId"}
                                {...props.baseProps}
                                keyField="taskTemplateId"
                                hover
                                selectRow={selectRow}
                                noDataIndication="No matching record found"

                                // rowEvents={tableRowEvents}
                              />
                            )}
                          </div>
                          {isBulkSelect && (
                            <div className="tableFooter TabFooter-Pay">
                              <div className="bulkSelectFooter">
                                {/* this.footerchk.current.indeterminate = false; */}
                                <Form.Check
                                  type="checkbox"
                                  label=""
                                  id="footerBulkCheckBox"
                                  ref={(chkRef) => {
                                    if (chkRef) {
                                      this.checkAllRef = chkRef;
                                      if (
                                        this.state.selectedRows?.length ===
                                        tableData.length
                                      ) {
                                        chkRef.checked = true;
                                      } else {
                                        chkRef.checked = false;
                                      }

                                      if (
                                        this.state.selectedRows?.length > 0 &&
                                        !chkRef?.checked
                                      ) {
                                        chkRef.indeterminate = true;
                                      } else {
                                        chkRef.indeterminate = false;
                                      }
                                    }
                                  }}
                                  onClick={(e: any) => {
                                    e.stopPropagation();
                                    this.footerBulkSelectAll(e);
                                  }}
                                />
                                <span className="shift-selected-count">
                                  {selectedRows?.length}{" "}
                                  {selectedRows?.length <= 1 && "Task"}
                                  {selectedRows?.length > 1 && "Tasks"} selected
                                </span>
                                <div className="action-group d-flex flex-row mr-auto">
                                  <Button
                                    disabled={selectedRows?.length <= 0}
                                    className="deactivate-btn btn-outline-primary"
                                    onClick={() =>
                                      this.handleBulkDeactivate(selectedRows)
                                    }
                                  >
                                    Deactivate
                                  </Button>
                                </div>
                                <Button
                                  className="closeButton btn-ghost-light"
                                  onClick={() => this.closeBulkAction()}
                                >
                                  &times;
                                </Button>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </ToolkitProvider>

                    {this.state.hidValue !== "Select" &&
                      this.state.createEditPermission && (
                        <Button
                          className="add-tsk-link"
                          disabled={isBulkSelect}
                          onClick={this.addNewDailyTask}
                        >
                          Add Task
                        </Button>
                      )}
                  </div>
                </div>
              )
            )}

            {this.state.showSlideout && (
              <DailyTaskSlideOut
                hid={this.state.hidValue}
                handleHideSlideout={this.handleHideSlideout}
                parentRowData={this.state.rowData}
                hotelName={this.state.hotelName}
                userRoles={userRoles.filter((x) => x.id > 0)}
                locationList={this.state.locationList}
                createEditPermission={this.state.createEditPermission}
              />
            )}
          </Container>
        </div>
      </>
    );
  }
}
