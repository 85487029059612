import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Container, Dropdown, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import chevronRight from "../../Common/Assets/Images/icons/bx-chevron-right.svg";
import { resolve, reject } from "q";
import { InfoTooltip } from "../../Common/Components/InfoTooltip";
import { NotificationService } from "../../Common/Services/NotificationService";
import "./alertPreferences.scss";
import { toast } from "react-toastify";
import { ISaveNotificationPreferencesRequest } from "../../Common/Contracts/ISaveNotificationPreferencesRequest";
import { Utils } from "../../Common/Utilis";
const { SearchBar } = Search;
export class AlertPreferences extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      expandedList: [],
      notificationData: [],
      actualnotificationData: [],
      searchtext: "",
      // notificationData: [
      //     {id: 1, notificationModule: "Labor", totalNoOfRow: 5, totalNoOfActivePip: 3, totalNoOfActiveSubModule: 4},
      //     {id: 2,  notificationModule: "Accounting", totalNoOfRow: 5, totalNoOfActivePip: 3, totalNoOfActiveSubModule: 4 },
      //     {id: 3, notificationModule: "Facility Maintenance", totalNoOfRow: 5, totalNoOfActivePip: 3, totalNoOfActiveSubModule: 4},
      //     {id: 4, notificationModule: "System", totalNoOfRow: 5, totalNoOfActivePip: 3, totalNoOfActiveSubModule: 4}
      // ], 

    };
  }
  componentDidMount() {
    this.getNotificationModule();

  }

  getNotificationModule() {
    NotificationService.getNotificationModule()
      .then(async (result: any | null) => {
        if (result != null) {
          this.setState({ actualnotificationData: result.result, notificationData: result.result });

        }
        resolve();
      })
      .catch((err: any) => {
        reject();
      });
  }

  toggleChange = (e, row: any, type: string) => {
    //debugger;
    let { checked } = e.target;
    let PipChecked = "";
    let ModuleChecked = "";
    let notificationData = Object.assign([], JSON.parse(JSON.stringify(this.state.notificationData)));
    if (checked) {
      if (type === "pip") {
        if (notificationData.filter((x) => x.name === row.name && x.module === row.module).length > 0) {
          const index = notificationData.findIndex((x) => x.name === row.name && x.module === row.module);
          notificationData[index].pipNotification = "Yes";
          PipChecked = "Yes";
          ModuleChecked = notificationData[index].moduleNotification;
        }
      }
      else {
        if (notificationData.filter((x) => x.name === row.name && x.module === row.module).length > 0) {
          const index = notificationData.findIndex((x) => x.name === row.name && x.module === row.module);
          notificationData[index].pipNotification = "Yes";
          notificationData[index].moduleNotification = "Yes";
          PipChecked = "Yes";
          ModuleChecked = "Yes";
        }
      }
    }
    else {
      if (type === "pip") {
        if (notificationData.filter((x) => x.name === row.name && x.module === row.module).length > 0) {
          const index = notificationData.findIndex((x) => x.name === row.name && x.module === row.module);
          notificationData[index].pipNotification = "No";
          notificationData[index].moduleNotification = "No";
          PipChecked = "No";
          ModuleChecked = "No";
        }
      }
      else {
        if (notificationData.filter((x) => x.name === row.name && x.module === row.module).length > 0) {
          const index = notificationData.findIndex((x) => x.name === row.name && x.module === row.module);
          notificationData[index].moduleNotification = "No";
          PipChecked = notificationData[index].pipNotification;
          ModuleChecked = "No";
        }
      }
    }
    this.setState({ notificationData, searchtext: "" });

    const storage = JSON.parse(localStorage.getItem("storage")!);
    const tenantID = storage === null ? 0 : (storage.tenantID as any);

    let requestObject = {} as ISaveNotificationPreferencesRequest;
    requestObject.Id = 0;
    requestObject.Module = row.module;
    requestObject.Name = row.name;
    requestObject.TenantID = tenantID;
    requestObject.TypeId = 0;
    requestObject.PipChecked = PipChecked;
    requestObject.ModuleChecked = ModuleChecked;

    NotificationService.saveNotificationPreferencesData(requestObject)
      .then(async (result: any | null) => {
        if (result.saveStatus === "Success") {
          toast.success("Preferences saved successfully.", {
          });
          this.getNotificationModule();
        } else {
          Utils.toastError("Error in saving, please try again", {
          });
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
        });
        reject();
      });
  }

  handleExpandCollapse = (type) => {
    //  let { expList } = this.state;
    let expList: any = [];
    this.state.actualnotificationData.forEach(element => {

      if (element.viewType === 1) {
        expList.push(element.rno);
      }

    });
    if (type === "Collapse_All") {
      this.setState({expandedList: []},() => {
        this.setState({expandedList: []});
      });
    } else {
      this.setState({expandedList: []},() => {
        this.setState({expandedList: expList});
      });
    }
  }


  filetrPreferences = (e: any) => {
    
    let searchtext = e.target.value;
    this.setState({ searchtext });

    let actualnotificationData = [...this.state.actualnotificationData];

    /*
    let finalArray: any = [];
    let mainArray = actualnotificationData.filter(x => x.viewType === 1);
    let subArray = actualnotificationData.filter(x => x.viewType === 2);
    if (searchtext.length > 0) {
      let filterData = subArray.filter(ele => {
        return (ele.name.toString().toLowerCase().includes(searchtext.toString().toLowerCase())
        );
      });

      mainArray.map((item)=>{
        finalArray.push(item);
      })
      filterData.map((item)=>{
        finalArray.push(item);
      })

      finalArray.push(mainArray);
      finalArray.push(filterData);
      this.setState({ notificationData: finalArray });
    }
    else
    {
      this.setState({ notificationData: actualnotificationData });
    }
    */
    if (searchtext.length > 0) {
      let filterData = actualnotificationData.filter(ele => {
        return (ele.searchText.toString().toLowerCase().includes(searchtext.toString().toLowerCase())
        );
      });
      this.handleExpandCollapse("Expend_All");
      this.setState({ notificationData: filterData });
  }
  else
  {
    this.handleExpandCollapse("Collapse_All");
    this.setState({ notificationData: actualnotificationData });
  }
}



  render() {
    const rowClasses = (row, rowIndex) => {
      if (this.state.expandedList.filter((rno) => row.rno === rno).length > 0)
        return 'expand-row';
      else
        return ""
    };
    
    const handleOnExpand = (row, isExpand, rowIndex, e) => {
      // this.setState({ expandedList: [] }, () => {
        if (isExpand) {
          this.setState(() => ({
            expandedList: [...this.state.expandedList, row.rno]
          }));
        } else {
          this.setState(() => ({
            expandedList: this.state.expandedList.filter(x => x !== row.rno)
          }));
        }
      // });
    }


    const expandRow = {
      renderer: (row: any, rowIndex: any) => renderChild(row),
      expanded: this.state.expandedList,
      showExpandColumn: true,
      //onlyOneExpanding: true,
      expandByColumnOnly: true,
      onExpand: handleOnExpand,
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return <div></div>;
        }
        return <div></div>;
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <span className="chevronExpended Right">
              <img src={chevronRight} alt="chevron Right" />
            </span>
          );
        }
        return (
          <span className="chevronCollpsed">
            <img src={chevronRight} alt="chevron Right" />
          </span>
        );
      },
    };


    const renderChild = (row) => {
      let childData = this.state.notificationData.filter(x => x.viewType === 2 && x.module === row.module)
      return (
        <>
          {/* <ToolkitProvider keyField="rno" data={childData} columns={columnChild} search>
                    {props => (
                        <>
                            <div className="forRegChildTable">
                                <BootstrapTable
                                    {...props.baseProps}
                                    keyField="id"
                                    hover
                                />
                            </div>
                        </>
                    )}
                </ToolkitProvider> */}

          <BootstrapTable
            keyField='rno'
            data={childData}
            columns={columnChild}
            // rowEvents={this.rowEvents}
            noDataIndication={'No record found.'}
          />
        </>
      );
    };
    const columns = [
      {
        dataField: 'module',
        text: 'Alert',
        headerClasses: 'mobile-header',
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              <div className="module">{row.module}</div>
            </EllipsisWithTooltip>
          );
        },
      }, {
        dataField: 'pipActiveNotification',
        text: 'Navigation Pip',
        hidden: true,
        headerClasses: 'width-170',
        classes: 'width-170',
        headerFormatter: (column, colIndex) => {
          let tooltipText = "This controls the notification count that is shown on the navigation icons. Disable a particular notification to prevent it from showing in the navigation.";
          return (
            <div className="d-flex align-items-center justify-content-center">
              <div className="col-text">{column.text}</div>
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip id={`tooltip-${colIndex}`}>{tooltipText}</Tooltip>
                }
              >
                <div className="info-tooltip">
                  <InfoTooltip />{" "}
                </div>
              </OverlayTrigger>
            </div>
          );
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="module-info">
              <div className="count">{row.pipActiveNotification} / {row.totalNotification}</div>
              <div className="count-tag">{"Enabled"}</div>
            </div>
          );
        },
      }, {
        dataField: 'moduleActiveNotification',
        text: 'Overview Alert',
        headerClasses: 'width-170',
        classes: 'width-170',
        headerFormatter: (column, colIndex) => {
          let tooltipText = "Disabling these will prevent alert boxes from being shown on each product’s Overview page.";
          return (
            <div className="d-flex align-items-center justify-content-center">
              <div className="col-text">{column.text}</div>
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip id={`tooltip-${colIndex}`}>{tooltipText}</Tooltip>
                }
              >
                <div className="info-tooltip">
                  <InfoTooltip />{" "}
                </div>
              </OverlayTrigger>
            </div>
          );
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="module-info">
              <div className="count">{row.moduleActiveNotification} / {row.totalNotification}</div>
              <div className="count-tag">{"Enabled"}</div>
            </div>
          );
        },
      }
    ]
    const columnChild = [
      {
        dataField: 'displayName',
        text: '',
        headerClasses: 'mobile-header',
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="d-flex align-items-center">
              <div className="col-text sub-module">{row.displayName}</div>
              {row.toolTip.length > 0 ?
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id={`tooltip-${rowIndex}`}>{row.toolTip}</Tooltip>
                  }
                >
                  <div className="info-tooltip">
                    <InfoTooltip />{" "}
                  </div>
                </OverlayTrigger>
                : ""
              }
            </div>
          );
        }
      },
      {
        dataField: "",
        text: "",
        headerClasses: 'width-170',
        hidden: true,
        classes: 'width-170',
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="bdr-left height-48 d-flex align-items-center justify-content-center">
              <Form.Check
                custom
                id={"chk" + row.module + rowIndex}
                key={"chk" + row.module + rowIndex}
                type="switch"
                label={""}
                checked={row.pipNotification === "Yes" ? true : false}
                onChange={(e) => this.toggleChange(e, row, "pip")}
                className={row.alwaysDisable === "Yes" ? "disable" : ""}
              //disabled={row.alwaysDisable === "Yes" ? true : false}
              />
            </div>
          )
        },
      },
      {
        dataField: "",
        text: "",
        headerClasses: 'width-170',
        classes: 'width-170',
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="bdr-left height-48 d-flex align-items-center justify-content-center">
              <Form.Check
                id={"chk1" + row.module + rowIndex}
                key={"chk1" + row.module + rowIndex}
                type="switch"
                label={""}
                checked={row.moduleNotification === "Yes" ? true : false}
                onChange={(e) => this.toggleChange(e, row, "module")}
                className={row.alwaysDisable === "Yes" ? "disable" : ""}
              //disabled={row.alwaysDisable === "Yes" ? true : false}
              />
            </div>
          )
        },
      }
    ]
    return (
      <div className="alert-preferences">
        <Container fluid className="body-sec">
          <div className="page-heading underline d-flex">
            <div className="mr-auto d-flex align-items-center">Alert Preferences</div>
            {/* <div className="action-group d-flex">
              <Dropdown className="more-action three-dot" alignRight>
                <Dropdown.Toggle className="btn-outline-primary btn btn-primary more" id="dropdown-more">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="link" key="link">Link</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div> */}

          </div>
          <ToolkitProvider
            keyField="rno"
            data={this.state.notificationData.filter(x => x.viewType === 1)}
            columns={columns}
            search
          //rowEvents={this.rowEvents}
          >{(props: {
            searchProps: JSX.IntrinsicAttributes;
            baseProps: JSX.IntrinsicAttributes;
          }) => (
            <div>
              <div className="searc-sec d-flex">
                <div className="mr-auto search-label">
                  {/* <SearchBar {...props.searchProps} placeholder={"Filter Preferences"} /> */}
                  <input type="search" className="form-control" placeholder={"Filter Preferences"} value={this.state.searchtext} onChange={this.filetrPreferences}
                  />

                </div>
                <button type="button"
                  className="btn btn-primary btn-link expand-all"
                  onClick={(e: any) => this.handleExpandCollapse(this.state.expandedList.length > 0 ? "Collapse_All" : "Expend_All")}>
                  <div className="d-flex align-items-center">
                    {this.state.expandedList.length > 0 ?
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.8334 9.1665H10.8334L9.16675 9.1665L4.16675 9.1665V10.8332H9.16675L10.8334 10.8332L15.8334 10.8332V9.1665Z" fill="#2CAF92" />
                      </svg> :
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.8334 9.1665H10.8334V4.1665H9.16675V9.1665H4.16675V10.8332H9.16675V15.8332H10.8334V10.8332H15.8334V9.1665Z" fill="#2CAF92" />
                      </svg>
                    }
                    <div>{this.state.expandedList.length > 0 ? "Collapse All" : "Expand All"}</div>
                  </div>
                </button>
              </div>
              <div className="table-responsive">
                <BootstrapTable
                  {...props.baseProps}
                  keyField="rno"
                  //hover
                  //rowEvents={this.rowEvents}
                  rowClasses={rowClasses}
                  expandRow={expandRow}
                />
              </div>
              
            </div>
          )}
          </ToolkitProvider>
        </Container>
      </div>
    );
  }
}
