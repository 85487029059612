import React from "react";
import { Container, Row, Col, Form, Button, Modal, Spinner } from "react-bootstrap";
import "../../Modules/Register/slideout.scss";
import { supportServices } from "../Services/Support";
import { ToastContainer, toast } from "react-toastify";
import { resolve, reject } from "q";
import { Utils } from "../Utilis";
export class Support extends React.Component<any, any> {
    private childHID: any;
    constructor(props: any) {
        super(props);
        this.state = {
            support: true,
            isSpinner: false,
            formData: {
                hotelID: this.props.hidValue, email: "", phoneNo: "", summary: this.props.summery, entryType: "", eName: "",
                hids: "", supportMail: this.props.supportEmail, tenantID: "", userName: "", supportDepartment: this.props.supportDepartment,
                comment: ""
            },
            errors: {
                supportDepartment: '', email: '', userName: '', tenantID: '', hids: '',
                phoneNo: '', summary: '', comment: '',
            },
            selectedFile: []
        };
    }

    isFormValid() {
        let errors = this.state.errors;
        let formData = { ...this.state.formData };
        let isForm = true;
        if (formData.supportDepartment === "") {
            errors.supportDepartment = 'Support department is required.';
            isForm = false;
        }
        if (formData.email.trim() === "") {
            errors.email = 'Email is required.';
            isForm = false;
        }
        if (formData.userName.trim().length === 0) {
            errors.userName = 'User Name is required.';
            isForm = false;
        }
        if (formData.tenantID === 0) {
            errors.tenantID = 'Enterprise is required.';
            isForm = false;
        }
        if (formData.hids.trim().length === 0) {
            errors.hids = 'Hotels is required.';
            isForm = false;
        }
        if (formData.phoneNo.trim().length === 0) {
            errors.phoneNo = 'Please enter phone number.';
            isForm = false;
        } else if (formData.phoneNo.trim().length > 20) {
            errors.phoneNo = 'Phone number must be less than or equal to 20 digits.';
            isForm = false;
        }
        if (formData.summary.trim().length === 0) {
            errors.summary = 'Please enter summary.';
            isForm = false;
        } else if (formData.summary.trim().length > 200) {
            errors.phoneNo = 'Summary must be less than or equal to 200 characters.';
            isForm = false;
        }
        if (formData.comment.trim().length === 0) {
            errors.comment = 'Please enter description.';
            isForm = false;
        }
        this.setState({ errors });
        return isForm;
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        let errors = this.state.errors;
        let formData = { ...this.state.formData };
        switch (name) {
            case 'email':
                errors.email = value.trim().length === 0 ? 'Please enter Email Address.' : '';
                formData.email = value;
                break;
            case 'phoneNo':
                errors.phoneNo = value.trim().length === 0 ? 'Please enter Phone Number.' : '';
                if (value.trim().length <= 20) {
                    formData.phoneNo = value;
                }
                break;
            case 'summary':
                errors.summary = value.trim().length === 0 ? 'Please enter summary.' : '';
                if (value.trim().length <= 200) {
                    formData.summary = value;
                }
                break;
            case 'comment':
                errors.comment = value.trim().length === 0 ? 'Please enter Description.' : '';
                formData.comment = value;
                break;
            default:
                break;
        }

        this.setState({ errors, formData });
    }

    componentDidMount() {
        this.getSupportData();
    }

    getSupportData() {
        this.setState({ isSpinner: true })
        supportServices.SupportCustomerdetails()
            .then(async (result: any | null) => {
                if (result !== null) {
                    this.setState({ isSpinner: false })
                    let formData = { ...this.state.formData };
                    formData.email = result[0].email;
                    formData.phoneNo = result[0].phoneNo;
                    formData.hids = result[0].hids;
                    formData.tenantID = result[0].tenantID;
                    formData.userName = result[0].userName;
                    this.setState({ formData });
                } else {
                    this.setState({ isSpinner: false })
                }
            });
    }

    sendSupportMail() {
        if (this.isFormValid()) {
            this.setState({ isSpinner: true })
            supportServices.sendSupportMail(this.state.formData, this.state.selectedFile)
                .then(async (result: any | null) => {
                    if (result !== null) {
                        if (result.saveStatus === "Success") {
                            toast.success(result.message, {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                containerId: "supportPopup",
                            });

                            setTimeout(() => {
                                this.closePopup();
                            }, 1000);

                        }
                        else {
                            this.setState({ isSpinner: false })
                            Utils.toastError(result.message, {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                containerId: "supportPopup",
                            });
                        }
                    }
                    resolve();
                })
                .catch((error) => {
                    this.setState({ isSpinner: false })
                    Utils.toastError(error.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        containerId: "supportPopup",
                    });
                });
        }
    }

    closePopup() {
        this.props.closePopup();
    }

    onFileChange = event => {
        this.setState({ selectedFile: event.target.files });
    };

    render() {
        const { errors, formData } = this.state;
        return (
            <>
                <ToastContainer
                    autoClose={3000}
                    containerId={"supportPopup"}
                    enableMultiContainer
                />
                <Modal show={this.state.support} className="bank-account-setting" size="lg">
                    <Modal.Header closeButton onClick={() => { this.closePopup() }}>
                        <Modal.Title>Support</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="transaction-slideout bankAccount-setting modal-form">
                            <Container fluid className="body-sec">
                                <div className="bank-account-setting">
                                    <div className="form-section">
                                        <Form.Group as={Row} className="mb-3" controlId="supportDepartment">
                                            <Form.Label column sm="4">Support Department</Form.Label>
                                            <Col sm="8">
                                                <Form.Control placeholder="Enter Support Department"
                                                    type="text" id="supportDepartment"
                                                    name="supportDepartment"
                                                    value={formData.supportDepartment}
                                                    disabled={true}
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className={errors.email.length > 0 ? "mb-3 error" : "mb-3"} controlId="email">
                                            <Form.Label column sm="4">Your Email Address</Form.Label>
                                            <Col sm="8">
                                                <Form.Control placeholder="Enter Email Address"
                                                    type="text" id="email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={this.handleChange}
                                                />
                                                {errors.email.length > 0 &&
                                                    <span className='error'>{errors.email}</span>
                                                }
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="userName">
                                            <Form.Label column sm="4">Username</Form.Label>
                                            <Col sm="8">
                                                <Form.Control placeholder="Enter Username"
                                                    type="text" id="userName"
                                                    name="userName"
                                                    value={formData.userName}
                                                    disabled={true}
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="tenantID">
                                            <Form.Label column sm="4">Enterprise</Form.Label>
                                            <Col sm="8">
                                                <Form.Control placeholder="Enter Enterprise"
                                                    type="text" id="tenantID"
                                                    name="tenantID"
                                                    value={formData.tenantID}
                                                    disabled={true}
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="hids">
                                            <Form.Label column sm="4">Hotel</Form.Label>
                                            <Col sm="8">
                                                <Form.Control placeholder="Enter Hotel"
                                                    type="text" id="hids"
                                                    name="hids"
                                                    value={formData.hids}
                                                    disabled={true}
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className={errors.phoneNo.length > 0 ? "mb-3 error" : "mb-3"} controlId="phoneNo">
                                            <Form.Label column sm="4">Phone Number</Form.Label>
                                            <Col sm="8">
                                                <Form.Control placeholder="Enter Phone Number"
                                                    type="text" id="phoneNo"
                                                    name="phoneNo"
                                                    value={formData.phoneNo}
                                                    onChange={this.handleChange}
                                                />
                                                {errors.phoneNo.length > 0 &&
                                                    <span className='error'>{errors.phoneNo}</span>
                                                }
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className={errors.summary.length > 0 ? "mb-3 error" : "mb-3"} controlId="summary">
                                            <Form.Label column sm="4">Summarize your issue into one sentence</Form.Label>
                                            <Col sm="8">
                                                <Form.Control placeholder="Enter Summarize your issue"
                                                    type="text" id="summary"
                                                    name="summary"
                                                    value={formData.summary}
                                                    onChange={this.handleChange}
                                                />
                                                {errors.summary.length > 0 &&
                                                    <span className='error'>{errors.summary}</span>
                                                }
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className={errors.comment.length > 0 ? "mb-3 error" : "mb-3"} controlId="description">
                                            <Form.Label column sm="4">Describe your issue in detail</Form.Label>
                                            <Col sm="8">
                                                <Form.Control placeholder="Describe your issue in detail"
                                                    type="text" id="comment"
                                                    name="comment"
                                                    as="textarea" rows={3}
                                                    value={formData.description}
                                                    onChange={this.handleChange}
                                                />
                                                {errors.comment.length > 0 &&
                                                    <span className='error'>{errors.comment}</span>
                                                }
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="fileAttachment">
                                            <Form.Label column sm="4">File Attachment</Form.Label>
                                            <Col sm="8">
                                                <Form.Control type="file"
                                                    className=""
                                                    id="fileAttachment"
                                                    placeholder="Upload File"
                                                    custom onChange={this.onFileChange} />
                                            </Col>
                                        </Form.Group>
                                    </div>
                                </div>
                            </Container>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => { this.closePopup() }}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={() => { this.sendSupportMail() }}>
                            Send Mail
                        </Button>
                    </Modal.Footer>
                    {this.state.isSpinner && (
                        <div className="loader-spinner d-flex justify-content-center align-items-center">
                            <Spinner animation="border" variant="success" />
                        </div>
                    )}
                </Modal>

            </>

        );
    }
}
