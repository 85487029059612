import Instense from "./Axios-config";
import { ITimesheetChangeLogRequest, ITimesheetChangeLogResponse } from './../Contracts/ITimesheetChangeLog';


export class LaborTimeSheet {

    public static getDisputeTotal = async (hotelID: Number, payStartDate: any, payEndDate: any): Promise<any | null> => {
        // Need to put this and all future URLs into a config js file
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : storage.tenantID as any;
        let request = {} as any;
        request.tenantID = tenantID;
        request.hotelID = hotelID;
        request.payStartDate = payStartDate;
        request.payEndDate = payEndDate;
        const url = `/LaborTimeSheet/GetDisputeTotal`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data.result as any;
            return result;
        });
    };



    public static timeSheetOverViewDetails = async (hotelID: Number, selectedDate: any): Promise<any | null> => {
        // Need to put this and all future URLs into a config js file
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : storage.tenantID as any;
        let request = {} as any;
        request.tenantID = tenantID;
        request.hotelID = hotelID;
        request.date = selectedDate;
        const url = `/LaborTimeSheet/TimeSheetOverViewDetails`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data.result as any;
            return result;
        });
    };

    public static getEmployeeType = async (): Promise<any | null> => {
        // Need to put this and all future URLs into a config js file
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : storage.tenantID as any;
        let request = {} as any;
        request.tenantID = tenantID;
        const url = `/LaborTimeSheet/GetEmployeeType`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data.result as any;
            return result;
        });
    };


    public static getLaborTimesheetDetails = async (hotelID: Number, selectedDate: any, userName: any, type: any): Promise<any | null> => {
        // Need to put this and all future URLs into a config js file
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : storage.tenantID as any;
        let request = {} as any;
        request.tenantID = tenantID;
        request.hotelID = hotelID;
        request.date = selectedDate;
        request.userName = userName;
        request.type = type;
        const url = `/LaborTimeSheet/TimeSheetOverViewEmployeesWise`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data.result as any;
            return result;
        });
    };

    public static getLaborTimesheetApprovalStatus = async (hotelID: Number, fromDate: any, toDate: any, userUniqueno: any): Promise<any | null> => {
        // Need to put this and all future URLs into a config js file
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : storage.tenantID as any;
        let request = {} as any;
        request.TenantID = tenantID;
        request.HotelId = hotelID;
        request.UserUniqueNo = userUniqueno;
        request.Date = fromDate;
        const url = `/LaborTimeSheet/GetTimeSheetApprovalStatus`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data.result as any;
            return result;
        });
    };

    public static downloadLaborTimesheetDetails = async (hotelID: Number, selectedDate: any, userName: any): Promise<any | null> => {
        // Need to put this and all future URLs into a config js file
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : storage.tenantID as any;
        let request = {} as any;
        request.tenantID = tenantID;
        request.hotelID = hotelID;
        request.date = selectedDate;
        request.userName = userName;
        const url = `/LaborTimeSheet/DownloadEmployeeTimeSheet`;
        return Instense.get(url, { params: request, responseType: 'blob' }).then((response) => {
            let result = response.data as any;
            return result;
        });
    };

    public static dayWiseTimeSheetDetails = async (hotelID: Number, selectedDate: any, userName: any, type: any): Promise<any | null> => {
        // Need to put this and all future URLs into a config js file
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : storage.tenantID as any;
        let request = {} as any;
        request.tenantID = tenantID;
        request.hotelID = hotelID;
        request.date = selectedDate;
        request.userName = userName;
        request.type = type;
        const url = `/LaborTimeSheet/DayWiseTimeSheetDetails`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data.result as any;
            return result;
        });
    };

    public static LaborDisputePunchDetails = async (hotelID: Number, selectedDate: any, userName: any, type: any): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : storage.tenantID as any;
        let logInUser = storage === null ? 0 : storage.userName as any;
        let request = {} as any;
        request.tenantID = tenantID;
        request.hotelID = hotelID;
        request.date = selectedDate;
        request.userName = userName;
        request.logInUser = logInUser;
        request.type = type;
        const url = `/LaborTimeSheet/LaborDisputePunchDetails`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data.result as any;
            return result;
        });
    };

    public static LaborDisputeCount = async (hotelID: Number, selectedDate: any, userName: any, type: any): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : storage.tenantID as any;
        let request = {} as any;
        request.tenantID = tenantID;
        request.hotelID = hotelID;
        request.date = selectedDate;
        request.userName = userName;
        request.type = type;
        const url = `/LaborTimeSheet/LaborDisputeCount`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data.result as any;
            return result;
        });
    };

    public static UpdateLaborDisputePunch = async (request: any): Promise<any | null> => {
        const url = `/LaborTimeSheet/UpdateLaborDisputePunch`;
        const response = await Instense.post(url, request);
        let result = response.data as any;
        return result;
    };

    public static CommentHistory = async (request: any): Promise<any | null> => {
        const url = `/LaborTimeSheet/CommentHistory`;
        const response = await Instense.post(url, request);
        let result = response.data as any;
        return result;
    };

    public static LaborResolveRejectAdjustment = async (request: any): Promise<any | null> => {
        const url = `/LaborTimeSheet/LaborResolveRejectAdjustment`;
        const response = await Instense.post(url, request);
        let result = response.data as any;
        return result;
    };

    public static GetTimeSheetEmployee = async (hotelID: Number, selectedDate: any): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : storage.tenantID as any;
        let request = {} as any;
        request.tenantID = tenantID;
        request.hotelID = hotelID;
        request.date = selectedDate;
        const url = `/LaborTimeSheet/GetTimeSheetEmployee`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data.result as any;
            return result;
        });
    };

    public static TimeSheetDatewithPermission = async (hotelID: Number, selectedDate: any): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : storage.tenantID as any;
        let request = {} as any;
        request.tenantID = tenantID;
        request.hotelID = hotelID;
        request.date = selectedDate;
        const url = `/LaborTimeSheet/TimeSheetDatewithPermission`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data.result as any;
            return result;
        });
    };

    public static getCurrentDateByTenantAndHotelId = async (hotelID: Number): Promise<any | null> => {
        // Need to put this and all future URLs into a config js file
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : storage.tenantID as any;
        let request = {} as any;
        request.tenantId = tenantID;
        request.hotelId = hotelID;
        const url = `/LaborSchedule/GetCurrentDateByTenantAndHotelId`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data.result as any;
            return result;
        });
    };


    public static GetTimesheetChangeLogData = async (
        request: ITimesheetChangeLogRequest
    ): Promise<ITimesheetChangeLogResponse[] | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.tenantId = tenantID;
        const url = `/LaborTimeSheet/GetTimeSheetChangeLog`;
        return Instense.post(url, request).then((response) => {
            return response.data?.result as ITimesheetChangeLogResponse[];
        });
    };

    public static DownloadChangeLog = async (
        request: ITimesheetChangeLogRequest, fileName: string
    ): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.tenantId = tenantID;


        const url = `/LaborTimeSheet/DownloadChangeLog`;
        return Instense.post(url, request, { responseType: "blob" }).then(

            (response: any) => {
                
                let csvURL = window.URL.createObjectURL(response.data);
                let tempLink = document.createElement("a");
                tempLink.href = csvURL;
                tempLink.setAttribute("download", fileName + ".xlsx");
                tempLink.click();
            }
        );
    };
}