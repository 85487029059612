import React from "react";
import { Dropdown, Modal, Button, Form, Table, Spinner } from "react-bootstrap";
import OutsideClickHandler from "react-outside-click-handler";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { resolve, reject } from "q";
import { schConversions } from "../Schedule/commonFunctions/conversions";
import { PayrollExportService } from "../../../../Common/Services/PayrollExport";
import {
  ISavePtoPayout,
  IGetPtoBalance,
  IGetLaborPtoPayout,
  IDeleteLaborPtoPayout,
} from "../../../../Common/Contracts/IPayrollExport";
import { ReactPageLoader } from "../../../../Common/Components/ReactPageLoader";
import { ConfirmationModal } from "../../../../Common/Components/ConfirmationModal";
import { Utils } from "../../../../Common/Utilis";

interface tableRow {
  ptoType: object;
  ptoBalance: string;
  ptoHours: string;
  ptoMinutes: string;
  postBalance: string;
  showAction: boolean;
  alert: boolean;
  ptoPayoutid: number;
  userUniqueno: number;
}
export class PtoPayoutModal extends React.Component<any, any> {
  private reasonArea: any;
  private warningDialogScroll: any;
  constructor(props: any) {
    super(props);
    this.reasonArea = React.createRef();
    this.warningDialogScroll = React.createRef();
    this.state = {
      pageLoader: true,
      buttonSpinner: false,
      selectedUserName: "",
      ptoReason: "",
      ptoTypeList: [],
      tableData: [],
      warnModalStatus: false,
    };
  }

  componentDidMount() {
    this.getPtoList();

    let rowData = this.props.parentState.payoutModalRow;
    let empVar = "employee!!0";

    this.setState({ selectedUserName: rowData[empVar] });
  }

  getPtoList = () => {
    let rowData = this.props.parentState.payoutModalRow;
    let empVar = "userName";
    let rowUserName = rowData[empVar];

    let requestObject = {} as IGetPtoBalance;

    requestObject.hotelID = this.props.parentState.hidValue;
    requestObject.userName = rowUserName;
    requestObject.startDate = `${
      new Date().getMonth() + 1
    }/${new Date().getDate()}/${new Date().getFullYear()}`;

    PayrollExportService.getPTOBalance(requestObject)
      .then(async (result: any | null) => {
        let apiResult = result.result;

        if (apiResult != null && apiResult.length > 0) {
          this.getPtoPayout();
          let ptoTypeList: any = [];
          for (let i = 0; i < apiResult.length; i++) {
            if (
              apiResult[i].ptoTypeID === "-1" ||
              apiResult[i].ptoTypeID === "-2"
            ) {
            } else {
              ptoTypeList.push(apiResult[i]);
            }
          }
          this.setState({ ptoTypeList });
        } else {
          this.setState({ pageLoader: false });
          Utils.toastError(result.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "payrollExportIndex",
          });
        }
        resolve();
      })
      .catch((err: any) => {
        this.setState({ pageLoader: false });
        Utils.toastError(`Server Error, ${err}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "payrollExportIndex",
        });
        reject();
      });
  };

  getPtoPayout = () => {
    let requestObject = {} as IGetLaborPtoPayout;

    let rowData = this.props.parentState.payoutModalRow;
    let empVar = "userName";
    let rowUserName = rowData[empVar];

    requestObject.employeename = rowUserName;
    requestObject.hid = this.props.parentState.hidValue;
    requestObject.PayperiodStartDate =
      this.props.parentState.navigationCalenderDates[0];

    PayrollExportService.GetLaborPtoPayout(requestObject)
      .then(async (result: any | null) => {
        let apiResult: any = result.result;
        if (apiResult != null && apiResult.length > 0) {
          
          let tableData = [...this.state.tableData];
          let ptoMasList = [...this.state.ptoTypeList];

          for (let i = 0; i < apiResult.length; i++) {
            let userPtoData: any = {};

            for (let j = 0; j < ptoMasList.length; j++) {
              if (
                Number(apiResult[i].pTypeUniqueno) ===
                Number(ptoMasList[j].ptoTypeID)
              ) {
                userPtoData = ptoMasList[j];
                break;
              }
            }
            if (Object.keys(userPtoData).length > 0) {
              let rowObject = {} as tableRow;
              let totalHours = userPtoData.hours.split(" ");
              let ptoTotalHours = `${apiResult[i].hours}`.split(".");

              let splitPtoBalance = totalHours[0].split(":");
              let splitPtoBalaceHour =
                Number(
                  splitPtoBalance[0] === null ||
                    splitPtoBalance[0] === undefined
                    ? "0"
                    : `${splitPtoBalance[0]}`
                ) +
                Number(
                  ptoTotalHours[0] === null || ptoTotalHours[0] === undefined
                    ? "0"
                    : `${ptoTotalHours[0]}`
                );
              let splitPtoBalaceMinute =
                Number(
                  splitPtoBalance[1] === null ||
                    splitPtoBalance[1] === undefined
                    ? "0"
                    : `${splitPtoBalance[1]}`
                ) +
                Number(
                  ptoTotalHours[1] === null || ptoTotalHours[1] === undefined
                    ? "0"
                    : `${ptoTotalHours[1]}`
                );

              let joinPtoBalance = `${splitPtoBalaceHour}:${splitPtoBalaceMinute}`;

              rowObject.ptoType = userPtoData;
              rowObject.ptoBalance = joinPtoBalance;
              rowObject.ptoHours =
                ptoTotalHours[0] === null || ptoTotalHours[0] === undefined
                  ? "0"
                  : `${ptoTotalHours[0]}`;
              rowObject.ptoMinutes =
                ptoTotalHours[1] === null || ptoTotalHours[1] === undefined
                  ? "00"
                  : `${ptoTotalHours[1]}`;
              rowObject.postBalance = totalHours[0];
              rowObject.showAction = false;
              rowObject.alert = false;
              rowObject.ptoPayoutid = apiResult[i].ptoPayoutid;
              rowObject.userUniqueno = apiResult[i].userUniqueno;

              tableData.push(rowObject);
            }
          }

          // Getting first possible pto resaon to be common
          let explanation = apiResult[0].explanation;
          this.setState({ tableData, ptoReason: explanation });
        }
        this.setState({ pageLoader: false }, () => {
          this.reasonArea.current?.focus();
        });
        resolve();
      })
      .catch((err: any) => {
        this.setState({ pageLoader: false });
        Utils.toastError(`Server Error, ${err}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "payrollExportIndex",
        });
        reject();
      });
  };

  reasonChange = (e: any) => {
    let value = e.target.value;
    this.setState({ ptoReason: value });
  };

  addEntryClick = () => {
    let tableData = [...this.state.tableData];

    let blankRow = {} as tableRow;

    blankRow.ptoType = {};
    blankRow.ptoBalance = "";
    blankRow.ptoHours = "0";
    blankRow.ptoMinutes = "00";
    blankRow.postBalance = "";
    blankRow.showAction = true;
    blankRow.alert = false;
    blankRow.ptoPayoutid = 0;

    tableData.push(blankRow);
    this.setState({
      tableData,
    });
  };

  ptoTypeClick = (item: any, idx: number) => {
    let tableData = [...this.state.tableData];

    let rowData = tableData[idx];
    let isNotFirstSelection =
      JSON.stringify(rowData.ptoType) === JSON.stringify(item);

    tableData[idx].ptoType = item;

    if (
      isNotFirstSelection &&
      (Number(tableData[idx].ptoHours > 0) ||
        Number(tableData[idx].ptoMinutes > 0))
    ) {
      // don't perform any action
    } else {
      let totalHours = item.hours.split(" ");
      let postEntry = totalHours[0];

      tableData[idx].ptoBalance = postEntry;
      tableData[idx].postBalance = postEntry;
    }

    this.setState({ tableData }, () => {
      this.changePtoBalance(idx);
    });
  };

  changePtoHours = (e: any, idx: number) => {
    let tableData = [...this.state.tableData];
    let value = e.target.value;

    if (/^\d+$/.test(value) === false) {
      value = value.replace(/[^\d]/g, "");
    }

    if (value.length > 4) {
      let a = value.replace(/[^\d]/g, "");
      value = a.substring(0, value.length - 1);
    }

    tableData[idx].ptoHours = value;
    this.setState({ tableData });
  };

  blurPtoHours = (e: any, idx: number) => {
    let tableData = [...this.state.tableData];
    let value: any = e.target.value;
    if (value === "") {
      value = "0";
    }

    tableData[idx].ptoHours = value;

    this.setState({ tableData }, () => {
      this.changePtoBalance(idx);
    });
  };

  changePtoMinutes = (e: any, idx: number) => {
    let tableData = [...this.state.tableData];
    let value = e.target.value;

    if (/^\d+$/.test(value) === false) {
      value = value.replace(/[^\d]/g, "");
    }

    if (value.length > 2) {
      let a = value.replace(/[^\d]/g, "");
      value = a.substring(0, value.length - 1);
    }

    if (Number(value) > 59) {
      let a = value.replace(/[^\d]/g, "");
      value = a.substring(0, value.length - 1);
    }

    tableData[idx].ptoMinutes = value;
    this.setState({ tableData });
  };

  blurPtoMinutes = (e: any, idx: number) => {
    let tableData = [...this.state.tableData];
    let value: any = e.target.value;

    if (value === "") {
      value = "00";
    } else {
      if (value.length === 1) {
        value = `0${value}`;
      }
    }

    tableData[idx].ptoMinutes = value;

    this.setState({ tableData }, () => {
      this.changePtoBalance(idx);
    });
  };

  changePtoBalance = (idx: number) => {
    let tableData = [...this.state.tableData];
    let rowValue = tableData[idx];

    let ptoBalanceArray = rowValue.ptoBalance.split(":");
    let ptoBalanceHoursInMinutes = Number(ptoBalanceArray[0]) * 60;
    let ptoBalanceInMinutes =
      ptoBalanceHoursInMinutes +
      Number(
        ptoBalanceArray[1] === null || ptoBalanceArray[1] === undefined
          ? "0"
          : ptoBalanceArray[1]
      );

    let userHourHoursInMinutes = Number(rowValue.ptoHours) * 60;
    let userHourInMinutes =
      userHourHoursInMinutes + Number(rowValue.ptoMinutes);

    let remainingPostBalance = ptoBalanceInMinutes - userHourInMinutes;

    tableData[idx].postBalance = schConversions.convertMinuteToHour(
      Math.abs(remainingPostBalance)
    );

    if (remainingPostBalance < 0) {
      tableData[idx].alert = true;
      tableData[idx].postBalance = `-${tableData[idx].postBalance}`;
    } else {
      tableData[idx].alert = false;
    }

    this.setState({ tableData });
  };

  removeTableRow = (action: any, idx: number, obj: any) => {
    
    let tableData = [...this.state.tableData];

    if(tableData[idx].ptoPayoutid===0)
    {
      tableData.splice(idx, 1);
      this.setState({ tableData });
    }
    else
    {
  
    if (action) {
     
      let requestObject = {} as IDeleteLaborPtoPayout;

      let rowData = this.props.parentState.payoutModalRow;
      let empVar = "userName";
      let rowUserName = rowData[empVar];

      requestObject.PtoPayoutid = tableData[idx].ptoPayoutid;
      requestObject.date = this.props.parentState.navigationCalenderDates[0];
      requestObject.PayperiodStartDate =
        this.props.parentState.navigationCalenderDates[0];
      requestObject.hid = this.props.parentState.hidValue;
      requestObject.UserUniqueno = tableData[idx].userUniqueno;

      requestObject.employeename = tableData[idx].ptoType.userName;
      tableData.splice(idx, 1);
      this.setState({ tableData });

      PayrollExportService.DeleteLaborPtoPayout(requestObject)
        .then(async (result: any | null) => {
          if (result != null && result.saveStatus === "Success") {
            this.props.savePtoPayoutModal();
            toast.success("PTO Payout Deleted Successfully", {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "payrollExportIndex",
            });
          } else {
            Utils.toastError(result.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "payrollExportIndex",
            });
          }
          this.setState({ buttonSpinner: false });
          resolve();
        })
        .catch((err: any) => {
          Utils.toastError(`Server Error, ${err}`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "payrollExportIndex",
          });
          this.setState({ buttonSpinner: false });
          reject();
        });
    }
  }
  };

  checkSaveValidations = () => {
    let tableData: any = [...this.state.tableData];
    let noPtoSelection: boolean = false;
    let rowErrorData: boolean = false;
    let isZeroHours: boolean = false;
    let IsNewEntry: boolean = false;

    for (let i = 0; i < tableData.length; i++) {
      
      if (tableData[i].ptoPayoutid > 0) {
        IsNewEntry = true;
      }

      if (Object.keys(tableData[i].ptoType).length === 0) {
        noPtoSelection = true;
        break;
      }
      if (
        Number(tableData[i].ptoHours) === 0 &&
        Number(tableData[i].ptoMinutes) === 0
      ) {
        isZeroHours = true;
        break;
      }
    }

    let allTablePtoTypes: any = [];

    for (let i = 0; i < tableData.length; i++) {
      allTablePtoTypes.push(JSON.stringify(tableData[i].ptoType));
    }

    let duplicatesInTable = allTablePtoTypes.filter(
      (item: any, idx: number) => allTablePtoTypes.indexOf(item) !== idx
    );

    if (duplicatesInTable.length > 0) {
      rowErrorData = true;
    }

    if (this.state.ptoReason === "") {
      Utils.toastError("Please enter reason for PTO entry", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "payrollExportIndex",
      });
      this.reasonArea.current?.focus();
      return false;
    } else if (noPtoSelection) {
      Utils.toastError("Error in payout table entry, please check all input", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "payrollExportIndex",
      });
      return false;
    } else if (isZeroHours && !IsNewEntry) {
      Utils.toastError("PTO hours should be greater than zero", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "payrollExportIndex",
      });
      return false;
    } else if (rowErrorData) {
      Utils.toastError("Can not create multiple entries of same PTO type", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "payrollExportIndex",
      });
      return false;
    } else {
      this.setState({ warnModalStatus: true }, () => {
        this.warningDialogScroll.current?.scrollIntoView();
      });
    }
  };

  handleSavePtoPayout = () => {
    this.setState({ warnModalStatus: false, buttonSpinner: true });

    let rowData = this.props.parentState.payoutModalRow;
    let empVar = "userName";
    let empCodeVar = "empCode!!-1";
    let rowUserName = rowData[empVar];
    let requestEmpCode = rowData[empCodeVar];

    let tableData: any = [...this.state.tableData];
    let requestArray: any = [];

    for (let i = 0; i < tableData.length; i++) {
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : (storage.tenantID as any);
      let user_UniqueID = storage === null ? 0 : (storage.user_UniqueID as any);

      let requestObject = {} as ISavePtoPayout;

      requestObject.tenantid = tenantID;
      requestObject.LoginUser = user_UniqueID;
      requestObject.PtoPayoutid = tableData[i].ptoPayoutid;
      requestObject.date = `${
        new Date().getMonth() + 1
      }/${new Date().getDate()}/${new Date().getFullYear()}`;
      requestObject.PayperiodStartDate =
        this.props.parentState.navigationCalenderDates[0];
      requestObject.hours = Number(
        `${tableData[i].ptoHours}.${tableData[i].ptoMinutes}`
      );
      requestObject.hid = this.props.parentState.hidValue;
      requestObject.PTypeUniqueno = tableData[i].ptoType.ptoTypeID;
      requestObject.UserUniqueno = Number(requestEmpCode);
      requestObject.employeename = rowUserName;
      requestObject.Explanation = this.state.ptoReason;

      requestArray.push(requestObject);
    }

    PayrollExportService.saveLaborPtoPayout(requestArray)
      .then(async (result: any | null) => {
        if (result != null && result.saveStatus === "Success") {
          this.props.savePtoPayoutModal();
          toast.success("PTO Payout Saved Successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "payrollExportIndex",
          });
        } else {
          Utils.toastError(result.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "payrollExportIndex",
          });
        }
        this.setState({ buttonSpinner: false });
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "payrollExportIndex",
        });
        this.setState({ buttonSpinner: false });
        reject();
      });
  };

  hidePtoPayoutModal = () => {
    this.props.hidePtoPayoutModal();
  };

  addEntryButtonStatus = () => {
    let tableData: any = [...this.state.tableData];
    let ptoTypeList: any = [...this.state.ptoTypeList];

    // check for non selection of pto type
    let disableButton: boolean = false;

    for (let i = 0; i < tableData.length; i++) {
      if (Object.keys(tableData[i].ptoType).length === 0) {
        disableButton = true;
        break;
      }
    }

    // Check if all pto types has been added
    let isAllPtoAdded: boolean = false;
    if (
      tableData.length > 0 &&
      ptoTypeList.length > 0 &&
      Object.keys(tableData[tableData.length - 1].ptoType).length !== 0
    ) {
      let dupAllTablePtoTypes: any = [];
      let allListPtoType: any = [];
      for (let i = 0; i < tableData.length; i++) {
        dupAllTablePtoTypes.push(JSON.stringify(tableData[i].ptoType));
      }
      let allTblPtoType: any = Array.from(new Set(dupAllTablePtoTypes));
      for (let i = 0; i < ptoTypeList.length; i++) {
        allListPtoType.push(JSON.stringify(ptoTypeList[i]));
      }
      let checkConditionArray: any = [];
      for (let i = 0; i < allListPtoType.length; i++) {
        if (allTblPtoType.includes(allListPtoType[i])) {
        } else {
          checkConditionArray.push(allListPtoType[i]);
        }
      }
      if (checkConditionArray.length === 0) {
        isAllPtoAdded = true;
      }
    }

    if (disableButton) {
      return true;
    } else if (isAllPtoAdded) {
      return true;
    } else {
      return false;
    }
  };

  getButtonStatus = () => {
    let tableData: any = [...this.state.tableData];
    let disableButton: boolean = false;

    for (let i = 0; i < tableData.length; i++) {
      if (
        Object.keys(tableData[i].ptoType).length === 0 ||
        tableData[i].postBalance.startsWith("-")
      ) {
        disableButton = true;
        break;
      }
    }

    if (disableButton) {
      return true;
    } else if (this.state.tableData.length === 0) {
      return true;
    } else if (this.state.pageLoader) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const confirmModalContent = [
      {
        title: "Delete PTO Payout",
        desc: "Are you sure you want to delete PTO Payout?",
        cancleAction: "Cancel",
        ActionTrue: "Delete",
      },
    ];
    return (
      <div className="pto-payout-modal">
        <Modal
          className="pto-payout-modal"
          show={this.props.parentState.payoutModalShow}
          onHide={this.hidePtoPayoutModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add PTO Payout</Modal.Title>
          </Modal.Header>
          {this.state.pageLoader ? (
            <ReactPageLoader useas={"ptoPayoutModal"} />
          ) : (
            <Modal.Body>
              <div className="user-type d-flex flex-column">
                <Form.Group
                  controlId="select-user"
                  className="user d-flex flex-column"
                >
                  <Form.Label>User</Form.Label>
                  <div className="selected-user">
                    {this.state.selectedUserName}
                  </div>
                </Form.Group>
              </div>

              <Form.Group controlId="addtimeoff-reason" className="reason">
                <Form.Label>
                  Reason <b>*</b>
                </Form.Label>
                <Form.Control
                  ref={this.reasonArea}
                  className="form-control"
                  as="textarea"
                  onChange={(e: any) => this.reasonChange(e)}
                  value={this.state.ptoReason}
                  placeholder="Enter Reason for PTO Payout"
                  maxLength={200}
                  onFocus={(e: any) => e.target.select()}
                />
              </Form.Group>

              <div className="bottomSepetatorLine"></div>

              <Table className="react-bootstrap-table table-hover table-condensed">
                <thead>
                  <tr className="bg-light">
                    <th>Type</th>
                    <th>PTO Hours</th>
                    <th>Balance</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  {this.state.tableData.map((data: any, idx: number) => (
                    <tr className="bg-light" key={idx}>
                      <th>
                        <Form.Group controlId="select-type" className="type">
                          <Dropdown
                            className={
                              Object.keys(data.ptoType).length === 0
                                ? "import-from-dropdown select"
                                : "import-from-dropdown"
                            }
                          >
                            <Dropdown.Toggle
                              id="dropdown-importFrom"
                              className={data.alert ? "alert-danger" : ""}
                            >
                              <EllipsisWithTooltip placement="top">
                                {Object.keys(data.ptoType).length === 0 ? (
                                  <span className="placeholder">Select</span>
                                ) : (
                                  <span className="selected">
                                    {data.ptoType.timeOffType}
                                  </span>
                                )}
                              </EllipsisWithTooltip>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {this.state.ptoTypeList.length > 0 &&
                                this.state.ptoTypeList.map(
                                  (item: any, stx: any) => (
                                    <Dropdown.Item
                                      key={stx}
                                      onClick={() =>
                                        this.ptoTypeClick(item, idx)
                                      }
                                    >
                                      <EllipsisWithTooltip placement="bottom">
                                        <span className="list-type">
                                          <div className="dropdwn-timeoff-type">
                                            {item.timeOffType}
                                          </div>
                                          <div className="dropdwn-timeoff-value">
                                            {item.hours}
                                          </div>
                                        </span>
                                      </EllipsisWithTooltip>
                                    </Dropdown.Item>
                                  )
                                )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Form.Group>
                      </th>

                      <th>
                        <div
                          className={
                            data.alert
                              ? "time-input alert-danger d-flex align-items-center"
                              : "time-input d-flex align-items-center"
                          }
                        >
                          <Form.Control
                            disabled={Object.keys(data.ptoType).length === 0}
                            value={data.ptoHours}
                            onChange={(e: any) => this.changePtoHours(e, idx)}
                            onBlur={(e: any) => this.blurPtoHours(e, idx)}
                            onFocus={(e: any) => e.target.select()}
                          />
                          <span>:</span>

                          <Form.Control
                            disabled={Object.keys(data.ptoType).length === 0}
                            value={data.ptoMinutes}
                            onChange={(e: any) => this.changePtoMinutes(e, idx)}
                            onBlur={(e: any) => this.blurPtoMinutes(e, idx)}
                            onFocus={(e: any) => e.target.select()}
                          />
                        </div>
                      </th>
                      <th>
                        <span
                          className={
                            data.alert
                              ? "post-entry badge color-orange"
                              : "post-entry badge color-green"
                          }
                        >
                          {data.postBalance}
                        </span>
                      </th>
                      <th>
                        {/* { data.showAction  &&( */}
                        {
                          // <span
                          //   className="delete-row"
                          //   onClick={() => this.removeTableRow(idx)}
                          // >
                          //   &#10005;
                          // </span>

                          <div>
                            {/* <button
                              aria-haspopup="true"
                              aria-expanded="false"
                              id="dropdown-more"
                              type="button"
                             className="btn-outline-primary btn btn-primary more fa-trash dropdown-toggle btn btn-primary"

                             onClick={(e: any) => this.removeTableRow(e,idx)}
                            >
                              <svg
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M3.75226 15C3.75226 15.8273 4.42501 16.5 5.25226 16.5H12.7523C13.5795 16.5 14.2523 15.8273 14.2523 15V6H15.7523V4.5H13.5023H12.7523V3C12.7523 2.17275 12.0795 1.5 11.2523 1.5H6.75226C5.92501 1.5 5.25226 2.17275 5.25226 3V4.5H4.50226H2.25226V6H3.75226V15ZM6.75226 3H11.2523V4.5H6.75226V3ZM6.00226 6H12.0023H12.7523L12.753 15H5.25226V6H6.00226Z"></path>
                                <path d="M6.75226 7.5H8.25226V13.5H6.75226V7.5ZM9.75226 7.5H11.2523V13.5H9.75226V7.5Z"></path>
                              </svg>
                            </button> */}

                            <Dropdown className="more-action" alignRight>
                              <Dropdown.Toggle
                                className="btn-outline-primary btn btn-primary more fa-trash"
                                id="dropdown-more"
                              >
                                <svg
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M3.75226 15C3.75226 15.8273 4.42501 16.5 5.25226 16.5H12.7523C13.5795 16.5 14.2523 15.8273 14.2523 15V6H15.7523V4.5H13.5023H12.7523V3C12.7523 2.17275 12.0795 1.5 11.2523 1.5H6.75226C5.92501 1.5 5.25226 2.17275 5.25226 3V4.5H4.50226H2.25226V6H3.75226V15ZM6.75226 3H11.2523V4.5H6.75226V3ZM6.00226 6H12.0023H12.7523L12.753 15H5.25226V6H6.00226Z" />
                                  <path d="M6.75226 7.5H8.25226V13.5H6.75226V7.5ZM9.75226 7.5H11.2523V13.5H9.75226V7.5Z" />
                                </svg>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <ConfirmationModal
                                  confirmModalContent={confirmModalContent}
                                  ishandleDeletePosition={(action: any) =>
                                    this.removeTableRow(action, idx, this)
                                  }
                                />
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        }
                      </th>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <Button
                className="add-entry-button btn-outline-primary"
                disabled={this.addEntryButtonStatus()}
                onClick={() => this.addEntryClick()}
              >
                Add Entry
              </Button>
            </Modal.Body>
          )}
          <Modal.Footer>
            <div className="footer-wrapper">
              <Button
                disabled={this.getButtonStatus()}
                className={
                  this.state.buttonSpinner
                    ? "save-pto-payout p-none"
                    : "save-pto-payout"
                }
                onClick={() => {
                  this.checkSaveValidations();
                }}
              >
                {this.state.buttonSpinner && (
                  <Spinner
                    className="mr-2"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                Add PTO
              </Button>

              {this.state.warnModalStatus && (
                <OutsideClickHandler
                  onOutsideClick={() =>
                    this.setState({ warnModalStatus: false })
                  }
                >
                  <div className="confirmModal" ref={this.warningDialogScroll}>
                    <div className="title">Alert</div>
                    <div className="description">
                      PTO Payouts are not included in future PTO Threshold
                      calculations.
                    </div>

                    <div className="action-link">
                      <button
                        onClick={() =>
                          this.setState({ warnModalStatus: false })
                        }
                        className="btn wht-bg"
                      >
                        Cancel
                      </button>

                      <button
                        className="btn btn-danger"
                        onClick={() => this.handleSavePtoPayout()}
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </OutsideClickHandler>
              )}
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
