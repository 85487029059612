import React from "react";
import { Dropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const HorizontalDropdownLayout = ({ value, onSelectItem }) => {
  const handleSelect = (item) => {
    if(item == value)
    {
      return 
    }
    onSelectItem(item);
  };

  return (
    <Dropdown id="layout-btn">
      <Dropdown.Toggle  variant="primary" id="dropdown-basic">
        Change Layout{" "}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
        >
          <path
            d="M1 7.75H5.5C5.9125 7.75 6.25 7.4125 6.25 7V1C6.25 0.5875 5.9125 0.25 5.5 0.25H1C0.5875 0.25 0.25 0.5875 0.25 1V7C0.25 7.4125 0.5875 7.75 1 7.75ZM1 13.75H5.5C5.9125 13.75 6.25 13.4125 6.25 13V10C6.25 9.5875 5.9125 9.25 5.5 9.25H1C0.5875 9.25 0.25 9.5875 0.25 10V13C0.25 13.4125 0.5875 13.75 1 13.75ZM8.5 13.75H13C13.4125 13.75 13.75 13.4125 13.75 13V7C13.75 6.5875 13.4125 6.25 13 6.25H8.5C8.0875 6.25 7.75 6.5875 7.75 7V13C7.75 13.4125 8.0875 13.75 8.5 13.75ZM7.75 1V4C7.75 4.4125 8.0875 4.75 8.5 4.75H13C13.4125 4.75 13.75 4.4125 13.75 4V1C13.75 0.5875 13.4125 0.25 13 0.25H8.5C8.0875 0.25 7.75 0.5875 7.75 1Z"
            fill="#2CAF92"
          />
        </svg>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <div className="d-flex align-items-center">
          <Dropdown.Item active={value == 1} onClick={() => handleSelect(1)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="24"
              viewBox="0 0 32 24"
              fill="none"
            >
              <rect x="5" y="3" width="10" height="18" rx="1" fill="#0E9074" />
              <rect x="17" y="3" width="10" height="18" rx="1" fill="#0E9074" />
            </svg>
          </Dropdown.Item>

          <Dropdown.Item active={value == 2} onClick={() => handleSelect(2)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="33"
              height="24"
              viewBox="0 0 33 24"
              fill="none"
            >
              <rect
                x="5.33203"
                y="3"
                width="6"
                height="18"
                rx="1"
                fill="#0E9074"
              />
              <rect
                x="13.332"
                y="3"
                width="6"
                height="18"
                rx="1"
                fill="#0E9074"
              />
              <rect
                x="21.332"
                y="3"
                width="6"
                height="18"
                rx="1"
                fill="#0E9074"
              />
            </svg>
          </Dropdown.Item>

          <Dropdown.Item active={value == 3} onClick={() => handleSelect(3)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="33"
              height="24"
              viewBox="0 0 33 24"
              fill="none"
            >
              <rect
                x="6.66797"
                y="3"
                width="12"
                height="18"
                rx="1"
                fill="#0E9074"
              />
              <rect
                x="20.668"
                y="3"
                width="6"
                height="18"
                rx="1"
                fill="#0E9074"
              />
            </svg>
          </Dropdown.Item>

          <Dropdown.Item active={value == 4} onClick={() => handleSelect(4)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="24"
              viewBox="0 0 32 24"
              fill="none"
            >
              <rect x="6" y="3" width="6" height="18" rx="1" fill="#0E9074" />
              <rect x="14" y="3" width="12" height="18" rx="1" fill="#0E9074" />
            </svg>
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default HorizontalDropdownLayout;
