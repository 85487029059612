import * as React from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ModuleReports } from "../../Common/Services/ModuleReports";

export class DDLReportType extends React.Component<any, any> {

  private summarySubTypes = [
    { label: "Accrual Basis", value: "Accrual" },
    { label: "Cash Basis", value: "Cash" },
  ];

  constructor(props: any) {
    super(props);

    this.state = {
      selectedItem: { label: "Accrual Basis", value: "Accrual" },
      summarySubTypes: this.summarySubTypes,
    };
  }

  componentDidMount = () => {
    
    const { defaultValue } = this.props;
    if (defaultValue === undefined || defaultValue === "Accrual") {
      this.handleDropdownChange(this.state.selectedItem);
    } else {
      this.handleDropdownChange({ label: "Cash Basis", value: defaultValue, });
    }
  };


  handleDropdownChange = (item: any) => {

    this.setState({ selectedItem: item });
    //this.props?.handleCOAType(item.value);
    this.props?.handleddlType(item.value);
  };

  componentDidUpdate = (prevprops, prevState) => {
    const { defaultValue } = this.props;
    if (((prevprops.defaultValue !== defaultValue)) && defaultValue?.length > 0) {
      let arr = this.state.summarySubTypes.filter(e => e.value === defaultValue);
      this.setState({ selectedItem: arr.length > 0 ? arr[0] : { label: "Accrual Basis", value: "Accrual" } });
    }
    if (!defaultValue && prevprops?.defaultValue !== defaultValue) {
      this.setState({ selectedItem: { label: "Accrual Basis", value: "Accrual" } });
    }
  }

  render() {

    return (
      <Dropdown className="single-select">
        <Dropdown.Toggle id="Modal-empType">
          <span
            style={{
              width: "95%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "block",
            }}
          >
            {this.state.selectedItem.label}
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div className="list-area">
            {this.state.summarySubTypes.map((item: any, idx: any) => (

              <Dropdown.Item onClick={() => this.handleDropdownChange(item)}>
                {item.label}
              </Dropdown.Item>
            ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

