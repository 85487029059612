import * as React from 'react';


export class SplitIcon extends React.Component<any, any> {
    render() {
        return (
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.0607 3C11.0607 2.44772 10.6129 2 10.0607 2C9.50838 2 9.06066 2.44772 9.06066 3V17C9.06066 17.5523 9.50838 18 10.0607 18C10.6129 18 11.0607 17.5523 11.0607 17V3Z"/>
                <path d="M5.53033 6.46967L6.59099 7.53033L4.12132 10L6.59099 12.4697L5.53033 13.5303L2 10L5.53033 6.46967Z"/>
                <path d="M14.591 6.46967L13.5303 7.53033L16 10L13.5303 12.4697L14.591 13.5303L18.1213 10L14.591 6.46967Z"/>
            </svg>
        );
    }
}