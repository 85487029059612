import * as React from "react";
import { Dropdown } from "react-bootstrap";
import { ModuleReports } from "./../../Common/Services/ModuleReports";
import { ToastContainer, toast } from "react-toastify";
import { Utils } from "../../Common/Utilis";

export class ContractSelector extends React.Component<any, any> {

  private inputElement: any;

  constructor(props: any) {
    super(props);
    this.inputElement = React.createRef();
    this.state = {
      selectedItem: {},
      contractList: [],
    };
  }


  componentDidUpdate(prevProps, prevState) {
    // debugger;
    const { selectedContract } = this.props;
    if (selectedContract.toLowerCase() === "select" && (prevProps.hotelIds !== this.props.hotelIds || prevProps.selectedVendorId !== this.props.selectedVendorId || prevProps.projectBudgetId !== this.props.projectBudgetId)) {
      this.getContractList();
    }
  }

  componentDidMount = () => {
    // debugger;
    this.getContractList();

  };

  getContractList = () => {
    // debugger;
    let request: any = {};
    request.HotelID = this.props.hotelIds;
    request.VendorId = this.props.selectedVendorId;
    request.PID = this.props.projectBudgetId;

    ModuleReports
      .GetVendorContractList(request)
      .then(async (result: any | null) => {
        //debugger;
        if (result !== null) {
          this.setState({ contractList: result }, () => {
            if (this.props.selectedContract === undefined || this.props.selectedContract.toLowerCase() === "select") {
              this.setState({ selectedItem: { rowNo: -1, label: "Select" } }, () => {
                //   this.handleDropdownChange(this.state.selectedItem);
              })
            } else {
              let selectedItem = this.state.contractList?.filter(r => r.label.toLowerCase() === this.props.selectedContract.toLowerCase());
              this.setState({ selectedItem: selectedItem[0], }, () => {
                //   this.handleDropdownChange(this.state.selectedItem[0]);
              });
            }
          });
        }
      })
      .catch((err: any) => {
        this.setState({ contractList: [], selectedItem: { rowNo: -1, label: "Select" }, });
        Utils.toastError(`Server Error, ${err}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  }


  handleDropdownChange = (item: any) => {
    // debugger;
    this.setState({ selectedItem: item });
    this.props.onContractChange(item);
  }

  render() {

    return (
      <Dropdown
        className="single-select custom-list" style={{ marginBottom: "10px" }}>
        <Dropdown.Toggle id="Modal-reportTypes">
          <span >
            {this.state.selectedItem?.label}
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div className="list-area">
            {this.state.contractList.map((item: any, idx: any) => (

              <Dropdown.Item
                onClick={() => this.handleDropdownChange(item)}
              >
                <div>
                  <div className="title">{item.label}</div>
                  <div className="varience">Original Amount: <span>{item.originalAmount}</span>, Change Amount: <span>{item.changeAmount}</span></div>
                  <div className="varience">Total Amount: <span>{item.totalAmount}</span></div>
                </div>
              </Dropdown.Item>
            ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}
