import * as React from 'react';
import { PlaidSetup } from "../../Common/ThirdPartyServices/PlaidSetup";
import { Container, Modal, ButtonToolbar, InputGroup, Form, FormControl, Dropdown, Button, ButtonGroup, OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import { accountService } from "../../Common/Services/account";
import { toast } from "react-toastify";

export class PlaidTransactionLog extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        // this.IntegratePlaidForTransactionlog();
    }

    
    IntegratePlaidForTransactionlog = () => {
        PlaidSetup.IntegratePlaidForTransactionlog();
    }

    getPlaidTransactionLog = () => {
    accountService.getPlaidTransactionLog()
    .then(async (result: any | null) => {
        if (result !== null) {
            // debugger;
            if (result.messageCode === "Success" && result.message === "The requested product is not yet ready") {
                toast.success(result.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
            else if (result.message === "Success" && result.success) {
                // console.log("result " + result.result );
                toast.success("Transactions downloaded successfully", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });

                const json = JSON.stringify(result, null, 2);
                const blob = new Blob([json], { type: "application/docx" });
                const href = URL.createObjectURL(blob);
                let link = document.createElement('a');
                link.href = href;
                link.download = "TransactionLogs" + ".json";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);

            }
            else {
                toast.success(result.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }

        }
    });
    }


    render() {
        return (
            <div>
                <div style={{marginTop: "13px" }}>
                    <Button className="margin-right-25" variant="primary" style={{marginRight: "13px", marginLeft: "13px"}} onClick={this.IntegratePlaidForTransactionlog}>Setup Plaid</Button>
                    <Button className="btn-outline-primary" variant="primary" onClick={this.getPlaidTransactionLog}>Get Transaction</Button>
                </div>
            </div>
        );
    }
}