import Instense from "./Axios-config";

export class PmsMonitoringService {

    public static GetPmsMonitoringData = async (request: any): Promise<any | null> => {
        const url = `/PMSMonitoring/GetPmsMonitoringData`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data.result as any;
            return result;
        });
    };

    public static GetHidListFromTenantId = async (request: any): Promise<any> => {

        const url = `/PMSMonitoring/GetHIDListFromTenantId`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data.result as any;
            return result;
        });
    };

    public static GetFileBasedMonitoringData = async (request: any): Promise<any | null> => {
        const url = `/PMSMonitoring/GetFileBasedMonitoringData`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data.result as any;
            return result;
        });
    };

    public static GetPMSListFromTenantIdHID = async (request: any): Promise<any> => {

        const url = `/PMSMonitoring/GetPMSListFromTenantIdHID`;
        return Instense.get(url, { params: request }).then((response) => {
            //debugger
            let result = response.data.result as any;
            return result;
        });
    };

    public static GetHotelkeyAPI = () => {
        const url = `/PMSMonitoring/GetHotelkeyAPI`;
        return Instense.get(url).then((response) => {
            let result = response.data.result as any;
            return result;
        });
    };

    public static GetHotelKeyAPIQueue = async (request: any): Promise<any> => {
        const url = `/PMSMonitoring/GetHotelKeyAPIQueue`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data.result as any;
            return result;
        });
    };


    public static cloudBedsCheckAccPeriod = async (request: any): Promise<any> => {
        const url = `/PMSCloudBeds/cloudBedsCheckAccPeriod`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data as any;
            return result;
        });
    };
}