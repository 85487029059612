import React from "react";
import { Redirect } from "react-router-dom";
import {
  Container,
  Button,
  OverlayTrigger,
  Tooltip,
  Dropdown,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import OutsideClickHandler from "react-outside-click-handler";
import filterFactory from "react-bootstrap-table2-filter";
import { FiChevronDown } from "react-icons/fi";
import $ from "jquery";
import { HidSelector } from "../../Common/Components/HidSelector";
import { SingleSearchDropdownList } from "../../Common/Components/SingleSearchDropdownList";
import { ReactPageLoader } from "../../Common/Components/ReactPageLoader";
import { TableCustomSearch } from "../../Common/Components/TableCustomSearch";
import { Facility } from "../../Common/Services/Facility";
import { IGetfacilitiesListRequest } from "../../Common/Contracts/IFacilities";
import { EntityManagementService } from "../../Common/Services/EntityManagementService";
import "./Location.scss";
import "./facilities.scss";
import _ from "lodash";
import { Utils } from "../../Common/Utilis";
import { LocationSlideOut } from "./LocationSlideOut";
import WorkOrderSlideOut from "../Workorder/WorkOrderSlideOut";
import { WorkOrder } from "../../Common/Services/WorkOrder";
import { resolve, reject } from "q";
import { IWorkOrderRequestDto } from "../../Common/Contracts/IWorkOrder";

export class Locations extends React.Component<any, any> {
  private wororderSlideOut: any;
  private child: any;
  private childHID: any;
  private departmentSearch: any;
  private empTypeSearch: any;
  private tableTypeSearch: any;
  private scheduleUserViewRef: any;
  constructor(props: any) {
    super(props);
    this.scheduleUserViewRef = React.createRef();
    this.wororderSlideOut = React.createRef();
    this.child = React.createRef();
    this.childHID = React.createRef();
    this.departmentSearch = React.createRef();
    this.empTypeSearch = React.createRef();
    this.tableTypeSearch = React.createRef();
    this.state = {
      pageLoader: false,
      hidValue: "Select",
      hotelName: "Select",
      tableData: [],
      roomTypeData: [],
      roomCategories: [],
      statusList: [
         { id: "", name: "All" },
        { id: "Online", name: "Online" },
        { id: "Offline", name: "Offline" },
      ],


      searchText: "",
      status: "",
      roomType: "",
      roomCategory: "",
      sortingOrder:"asc",
      sortingField: "roomName",
      showSlideOut: false,
      // isSlideOut: false,
      // locationTypesList: [],
      // assetTypesList: [],
      // assignToListRightPanel: [],
      // parentRowData: [],
      // assignToList: [],
    };
  }

  componentDidMount() {

    let storage = JSON.parse(localStorage.getItem("hotelSelected")!);
    let storagehotelid = storage === null ? -1 : (storage.hotelID as any);
    this.getRoomCategories();
  }

  selectHotelDropDown = (hotelDetails: any) => {
    this.setState({ pageLoader: true });

    this.setState(
      {
        hotelName: hotelDetails.lettercode,
        hidValue: hotelDetails.hotelID,

      },
      () => {
        this.getFacilities();
        this.getRoomTypes();
      }
    );
  };

//   getrooms = () => {
//     let hotelID = Number(this.state.hidValue);
//    WorkOrder.GetFacilityRooms(hotelID)
//        .then(async (result: any | null) => {
//            let DayApproveData: any = [];
           
//            if (result != null && result.length > 0) {
//                let Data = result.map(x => {
//                    return { id: x?.roomID, name: x?.roomName };
//                })

//                this.setState({
//                  locationTypesList: Data
//                });
//                this.setState({locationSearchTypesList:[]},()=>{
//                  this.setState({ locationSearchTypesList: [{ id: -1, name: "All" },...Data] });
//                })
              
//            } else {
//                this.setState({
//                  locationTypesList: []
//                });
//            }
//            resolve();
//        })
//        .catch((err) => {
//            Utils.toastError(`Server Error, ${err}`, {
//            });

//            reject();
//        });
// }

// GetFacilityAsset = () => {
//   let hotelID = Number(this.state.hidValue);
//     WorkOrder.GetFacilityAsset(hotelID)
//         .then(async (result: any | null) => {

//             if (result != null && result.length > 0) {
//                 let Data = result.map(x => {
//                     return { id: x?.roomID, name: x?.roomName };
//                 })
//                 this.setState({ assetTypesList: Data });
//               // this.setState({ assetTypesList: [{ id: 0, name: "Select an asset" }, ...Data] });
//             } else {
//                 //this.setState({ assetTypesList: [{ id: 0, name: "Select an asset" }] });
//                 this.setState({ assetTypesList: [] });
//             }
//             resolve();
//         })
//         .catch((err) => {
//             Utils.toastError(`Server Error, ${err}`, {
//             });

//             reject();
//         });
// }

// getAssignUserList() {

//   let request = {} as IWorkOrderRequestDto;
//   request.hotelID = Number(this.state.hidValue);
//   request.type = "AssignUsers";
//   WorkOrder.GetFcUserDetailsList(request)
//     .then(async (result: any | null) => {
     
//       if (result != null && result.length > 0) {
//         let Data = result.map(x => {
//           return { id: x?.user_UniqueID, name: x?.employee };
//         })

//         this.setState({ assignToList: [] }, () => {
//           this.setState({ assignToList: [{ id: -1, name: "All" }, ...Data] });
//           this.setState({assignToListRightPanel:Data});
//           // this.setState({ assignToListToChild: [{ id: -1, name: "Select a technician" }, ...Data] });
//         })

//       } else {
//         this.setState({ assignToList: [{ id: -1, name: "All" }],assignToListRightPanel:[]});
        
//       }
//       resolve();
//     })
//     .catch((err) => {
//       Utils.toastError(`Server Error, ${err}`, {
//       });

//       reject();
//     });
// }


  getFacilities = () => {

    const { searchText,
      status,
      roomType,
      roomCategory, hidValue } = this.state;
    const request: IGetfacilitiesListRequest = {
      searchText,
      status,
      hid: hidValue,
      roomType,
      roomCategory
    };

    Facility.GetFacilities(request).then(tableData => {
      
      this.setState({ tableData: _.sortBy(tableData,'roomName')});
    });
  }




  getRoomTypes = () => {
    // Facility.GetDistinctRoomTypes(
    //   this.state.hidValue
    // ).then((roomTypeData: any) => {

    //   if (roomTypeData?.length > 0) {
    //     roomTypeData = roomTypeData.map(x => {
    //       return { id: x, name: x };
    //     })
    //   }
    //   this.setState({ roomTypeData: [ { id: "", name: "All" },...roomTypeData] });
    // }
    // );
  };

  getRoomCategories = () => {
    Facility.GetDistinctRoomCategories().then((roomCategories: any) => {

      if (roomCategories?.length > 0) {
        roomCategories = roomCategories.map(x => {
          return { id: x, name: x };
        })
      }
      this.setState({ roomCategories :[ { id: "", name: "All" },...roomCategories]  });
    }
    );
  };

  onSearchChange = (searchText: string) => {
    this.setState({ searchText: searchText?.toString()?.trimStart() }, () => {
      // if (searchText?.length > 1 || !searchText) {
        this.getFacilities();
      // }
    });
  };

  onDDlFiltersChange = (controlID: any, id: any) => {
    let {
      status,
      roomType,
      roomCategory

    } = this.state;
    switch (controlID) {
      case "ddlRoomType":
        roomType = id;
        break;
      case "ddlRoomCategory":
        roomCategory = id;
        break;
      case "ddlStatus":
        status = id;
        break;
      default:
        break;
    }

    this.setState({
      status,
      roomType,
      roomCategory
    }, () => {
      this.getFacilities();
    });
  };

  handleSortByColumn = (field) => {
    this.setState(
      {
        sortingField: field,
        sortingOrder: this.state.sortingOrder === "asc" ? "desc" : "asc",
      },
      () => {
          this.sortTableData(this.state.tableData, this.state.sortingField);
        }
      
    );
  };



  sortTableData = (data: any, fieldName) => {
    if (this.state.sortingOrder === "asc") {
      data = _.orderBy(data, [fieldName], ["asc", "asc"]);
    } else if (this.state.sortingOrder === "desc") {
      data = _.orderBy(data, [fieldName], ["desc", "asc"]);
    }
    this.setState({ tableData: data });
  };
  
  sortImage = (order) => {
    if (!order)
      return (
        <span className="ascending">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
              fill="#1D1E20"
            />
          </svg>
        </span>
      );
    else if (order === "asc")
 
    return (
      <span className="ascending">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
            fill="#1D1E20"
          />
        </svg>
      </span>
    );
    else if (order === "desc")
    return (
      <span className="descending">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
            fill="#1D1E20"
          />
        </svg>
      </span>
    );
 
    return null;
  };



  handleShowSlideout = (entityRowData: any) => {
    this.setState({ entityRowData }, () => {
      this.setState({
        showSlideout: true
      });
    });
  };

  handleHideSlideout = (isReload) => {
    this.setState({
      showSlideout: false, 
    });
  };

  render() {
    
    
    const columns = [
      {
        dataField: "roomName",
        text: "Room Name",
        headerFormatter: (column) => {
          return (
            <div
              className={`d-inline-block ${this.state.sortingField === "roomName" ? "active" : ""
                }`}
                onClick={() => this.handleSortByColumn("roomName")}>
              {column.text}
              {this.sortImage(this.state.sortingOrder)} 
            </div>
          );
        },
   formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <div className="d-flex flex-wrap thours align-items-center">
                      <div className="main-ttl">{row.roomName}</div>

                {row.status == "Offline" && (
                  <span className="badge">{row?.status}</span>
                )}
              
              </div>
            );
          },
       
      },
      {
        dataField: "openWorkOrder",
        text: "Open Work Orders",
        headerFormatter: (column) => {
          return (
            <div
              className={`d-inline-block ${this.state.sortingField === "openWorkOrder" ? "active" : ""
                }`}
                onClick={() => this.handleSortByColumn("openWorkOrder")}>
              {column.text}
              {this.sortImage(this.state.sortingOrder)} 
            </div>
          );
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="d-flex flex-wrap thours align-items-center">
                    <div className="main-ttl">{row.openWorkOrder>0 ? row.openWorkOrder: "-"}</div>
            
            </div>
          );
        },
      
     
      },
      {
        dataField: "roomType",
        text: "Room Type",
        headerFormatter: (column) => {
          return (
            <div
              className={`d-inline-block ${this.state.sortingField === "roomType" ? "active" : ""
                }`}
                onClick={() => this.handleSortByColumn("roomType")}>
              {column.text}
              {this.sortImage(this.state.sortingOrder)} 
            </div>
          );
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div>
              {row.roomType}
            </div>
          )
        }
     
      },
      {
        dataField: "category",
        text: "Category",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="main-ttl">
              {Utils.CapitalizeFirstLetter(row?.category)}
            </div>
          );
        },
        
        headerFormatter: (column) => {
          return (
            <div
              className={`d-inline-block ${this.state.sortingField === "category" ? "active" : ""
                }`}
                onClick={() => this.handleSortByColumn("category")}>
              {column?.text}
              {this.sortImage(this.state.sortingOrder)} 
            </div>
          );
        },
     
      }
   
    ];

    const tableRowEvents = {
      onClick: (e, row, rowIndex) => {
        this.handleShowSlideout(row)
      },
  
    }
    return (
      <>


        <div className="performance-position performance timesheet location-page-wrapper">
          <Container fluid className="body-sec">
            <div className="underline page-heading d-flex pl-0">
              <HidSelector
                onClickEvent={this.selectHotelDropDown}
                ref={this.childHID}
                modulename={"Facility Maintenance"}
              />
              <div className="mr-auto d-flex align-items-center">
                Locations
              </div>
              
              {/* {this.state.hidValue !== "Select" && ()}*/}
                <div className="action-group d-flex flex-row">


                  <Dropdown className="more-action" alignRight>
                    <Dropdown.Toggle
                      className="btn-outline-primary btn btn-primary more"
                      id="dropdown-more"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                      >
                        Not Found
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div> 
              
            </div>
            {/* {this.state.pageLoader ? (
              <ReactPageLoader useas={"timesheetMain"} />
            ) : ( */}
            {this.state.hidValue !== "Select" && (
              <div className="main-Page">
                <div className="bdy-sec">
                  <ToolkitProvider
                    keyField="userUniqueno"
                    data={this.state.tableData}
                    columns={columns}
                    hover
                  >
                    {(props: {
                      searchProps: JSX.IntrinsicAttributes;
                      baseProps: JSX.IntrinsicAttributes;
                    }) => (
                      <div>
                        <div className="search-header d-flex">
                          <div className="schedule-main">
                            <div className="schedule-department-view-table">
                              <div className="departmentViewNavigation">
                                <div className="navigation-menus d-flex">
                                  <div className="schedule-table-search">

                                    <input
                                      type="text"
                                      onChange={(e) => this.onSearchChange(e.target.value)}
                                      value ={this.state.searchText}
                                      className="search-box form-control"
                                      placeholder="Search..."
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>



                          <div className="room-type-dropdown">
                            {this.state.roomTypeData.length > 0 && (
                              <SingleSearchDropdownList
                                id={"tabletypeddl"}
                                isRefresh={this.state.isRefresh}
                                itemList={this.state.roomTypeData}
                                handleSelectedItem={this.onDDlFiltersChange.bind(
                                  this,
                                  "ddlRoomType"
                                )}
                                defaultItem={this.state.roomType}
                                defaultText={"Search Type"}
                                defaultName={"Room Type"}
                                controlID="1"
                              />
                            )}
                          </div>
                          <div className="room-category-dropdown">
                            {this.state.roomCategories.length > 0 && (
                              <SingleSearchDropdownList
                                id={"tabletypeddl"}
                                isRefresh={this.state.isRefresh}
                                itemList={this.state.roomCategories}
                                handleSelectedItem={this.onDDlFiltersChange.bind(
                                  this,
                                  "ddlRoomCategory"
                                )}
                                allowSearch={false}
                                defaultItem={this.state.roomCategory}
                                defaultText={"Search Room Category"}
                                defaultName={"Room Category"}
                                controlID="1"
                              />
                            )}
                          </div>
                          <div className="room-status-dropdown">
                            {this.state.statusList.length > 0 && (
                              <SingleSearchDropdownList
                                id={"tabletypeddl"}
                                isRefresh={this.state.isRefresh}
                                itemList={this.state.statusList}
                                handleSelectedItem={this.onDDlFiltersChange.bind(
                                  this,
                                  "ddlStatus"
                                )}
                                allowSearch={false}
                                defaultItem={this.state.status}
                                defaultText={"Search Status"}
                                defaultName={"Status"}
                                controlID="1"
                              />
                            )}
                          </div>
                        </div>


                        <div className="hasCursorPointer">
                          <BootstrapTable
                            id={"location-table"}
                            // id={"timesheet"}
                            {...props.baseProps}
                            keyField="userUniqueno"
                            hover
                            rowEvents={tableRowEvents}
                          />
                        </div>

                        
                      </div>
                    )}
                  </ToolkitProvider>
                </div>
              </div>

            )
            }
            {/* )} */}


          </Container>
          {
            this.state.showSlideout && <LocationSlideOut hid={this.state.hidValue} handleHideSlideout={this.handleHideSlideout} {...this.props} parentstate={this.state.entityRowData} hotelName={this.state.hotelName}/>
          }

          {/* {this.state.isSlideOut && (
            <div>
              <WorkOrderSlideOut
                ref={this.wororderSlideOut}
                closeWorkSlideOut={this.closeWorkSlideOut}
                parentState={this.state}
                parentRowData={this.state.parentRowData}
                workOrderStatusUpdate={this.workOrderStatusUpdate}
              // changesOfSlideOut={this.changesOfSlideOut}
              // refeshTable={this.refeshTable}                               
              />
            </div>

          )} */}
        </div>
      </>
    );
  }
}
