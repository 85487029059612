import * as React from "react";
import { Dropdown } from "react-bootstrap";
import PageLoader from "./PageLoader";
// import profile from "../Assets/Images/profile1.png"
import OutsideClickHandler from "react-outside-click-handler";
import profile from "../Assets/Images/profile.png";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
export class SingleSelectActiveUserSearchWithImage extends React.Component<
  any,
  any
> {
  private inputElement: any;
  constructor(props: any) {
    super(props);
    this.inputElement = React.createRef();
    this.state = {
      filterData: this.props.employeeList,
      employeeList: this.props.employeeList,
      inputValue: "",
      selectedEmploye: "",
      loaderTrue: false,
    };
  }

  handleChangeEmployee = (e: { target: { value: string } }) => {
    this.setState({ loaderTrue: true });
    const updatedList = this.state.employeeList.filter(
      (item: { employee: string }) => {
        return (
          item.employee.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
        );
      }
    );

    this.setState({
      filterData: updatedList,
      inputValue: e.target.value,
      loaderTrue: false,
    });
  };

  resetSerchBox = () => {
    this.setState({ inputValue: "" });
    this.setState({ filterData: this.state.employeeList });
  };

  handleDropdownChange = (event: any, item: any) => {
    this.setState({ inputValue: "", selectedEmploye: item.employee });
    this.props.handleSelectedItem(item);
    this.resetSerchBox();
  };

  updateEmpData = (data: any) => {
    this.setState({ filterData: data, employeeList: data });
  };

  render() {
    var loadShow: any;
    if (this.state.loaderTrue) {
      loadShow = <PageLoader />;
    }

    return (
      <>
        <OutsideClickHandler
          key="outsideclick"
          onOutsideClick={this.resetSerchBox}
        >
          <Dropdown className="single-select-image">
            {loadShow}
            <Dropdown.Toggle id="dropdown-importFrom">
              {this.state.selectedEmploye === "" ? (
                <span className="placeholder">Select user</span>
              ) : (
                <span className="selected">{this.state.selectedEmploye}</span>
              )}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <div className="search-sec">
                <input
                  type="text"
                  className="form-control"
                  onChange={this.handleChangeEmployee}
                  placeholder="Search"
                  value={this.state.inputValue}
                  ref={this.inputElement}
                />
              </div>
              <div className="list-area">
                <div className="list-section">
                  {this.state.filterData.map((item: any, idx: any) => {
                    if (item.isconflict.toLowerCase() !== "incomplete")
                    return (
                    <Dropdown.Item
                    className={
                      item.isconflict.toLowerCase() === "incomplete"
                        ? "disabled"
                        : ""
                    }
                      eventKey={item.employee}
                      onClick={(event: any) =>
                        this.handleDropdownChange(event, item)
                      }
                      key={idx}
                    >
                      <div className="d-flex">
                        <img
                          src={
                            item.imagePath === null ||
                            item.imagePath.trim() === ""
                              ? profile
                              : item.imagePath
                          }
                          className="profileimg"
                          width="36"
                          height="36"
                          alt=""
                        />
                        <div>
                          <EllipsisWithTooltip placement="bottom">
                            <div className="name">
                              <div className="all-user">{item.employee}</div>
                            </div>
                          </EllipsisWithTooltip>
                          <EllipsisWithTooltip placement="bottom">
                            <div className="pos-name">{item.positionName}</div>
                          </EllipsisWithTooltip>
                        </div>
                        {item.isconflict.toLowerCase() === "incomplete" && (
                        <div className="red-badges">Incomplete</div>
                      )}
                      </div>
                    </Dropdown.Item>
                  )})}

<div className="dropdown-header divider">
                      INCOMPLETE USERS
                    </div>
{this.state.filterData.map((item: any, idx: any) => {
                    if (item.isconflict.toLowerCase() === "incomplete")
                    return (
                      <>
                      

                    <Dropdown.Item
                    className={
                      item.isconflict.toLowerCase() === "incomplete"
                        ? "disabled"
                        : ""
                    }
                      eventKey={item.employee}
                      onClick={(event: any) =>
                        this.handleDropdownChange(event, item)
                      }
                      key={idx}
                    >
                      <div className="d-flex">
                        <img
                          src={
                            item.imagePath === null ||
                            item.imagePath.trim() === ""
                              ? profile
                              : item.imagePath
                          }
                          className="profileimg"
                          width="36"
                          height="36"
                          alt=""
                        />
                        <div>
                          <EllipsisWithTooltip placement="bottom">
                            <div className="name">
                              <div className="all-user">{item.employee}</div>
                            </div>
                          </EllipsisWithTooltip>
                          <EllipsisWithTooltip placement="bottom">
                            <div className="pos-name">{item.positionName}</div>
                          </EllipsisWithTooltip>
                        </div>
                        {item.isconflict.toLowerCase() === "incomplete" && (
                        <div className="red-badges">Incomplete</div>
                      )}
                      </div>
                    </Dropdown.Item>
                    </>
                  )})}

                </div>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </OutsideClickHandler>
      </>
    );
  }
}
