import React, { useState } from 'react';
// import default react-pdf entry
import { Document, Page, pdfjs } from "react-pdf";
import { Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faFileArchive } from "@fortawesome/free-solid-svg-icons";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function PDFSlider(props) {
  const [file, setFile] = useState(props);
  const [numPages, setNumPages] = useState(null);
  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }
  function onDocumentLoadProgress({ loaded, total }){
    console.log('Loading a document: ' + (loaded / total) * 100 + '%');
  }
  function renderLoader(){
    return (
      <div className="spiner d-flex align-items-center justify-content-center"><Spinner animation="border" variant="success" /></div>
    )    
  }
  function renderError(){
    return (
      <div className={props.activeSaction === true ? "custom-error-msg active" : "custom-error-msg"}
        onClick={(e:any) => handlePreview(e, props.fileName, props.filePreview, numPages, props.currentIndex)}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">          
          <path d="M0 64C0 28.65 28.65 0 64 0H224V128C224 145.7 238.3 160 256 160H384V198.6C310.1 219.5 256 287.4 256 368C256 427.1 285.1 479.3 329.7 511.3C326.6 511.7 323.3 512 320 512H64C28.65 512 0 483.3 0 448V64zM256 128V0L384 128H256zM288 368C288 288.5 352.5 224 432 224C511.5 224 576 288.5 576 368C576 447.5 511.5 512 432 512C352.5 512 288 447.5 288 368zM491.3 331.3C497.6 325.1 497.6 314.9 491.3 308.7C485.1 302.4 474.9 302.4 468.7 308.7L432 345.4L395.3 308.7C389.1 302.4 378.9 302.4 372.7 308.7C366.4 314.9 366.4 325.1 372.7 331.3L409.4 368L372.7 404.7C366.4 410.9 366.4 421.1 372.7 427.3C378.9 433.6 389.1 433.6 395.3 427.3L432 390.6L468.7 427.3C474.9 433.6 485.1 433.6 491.3 427.3C497.6 421.1 497.6 410.9 491.3 404.7L454.6 368L491.3 331.3z"/></svg>
      </div>
    )    
  }
  function renderNoData(){
    // return (
    //   <div>
    //     {/* <Spinner animation="border" variant="success" /> */}
    //   </div>
    // )    
  }
  function handlePreview(eventKey: any, currentfileName: any, currentfileNo: any, currentPage: any, currentIndex: any) {
    props.handlePreview(eventKey, currentfileName, currentfileNo, currentPage, currentIndex);
  }

  return (
    <Document file={file.filePreview} onLoadSuccess={onDocumentLoadSuccess} loading={renderLoader} noData={renderNoData} error={renderError}>
      <Page
          key={1}
          pageNumber={1}
          className={props.activeSaction === true ? "active" : ""}
          //onLoadProgress={() => alert('progress')}
					//onLoadSuccess={() => alert('success')}
          renderAnnotationLayer={false}
          renderTextLayer={false}
          onClick={(e:any) => handlePreview(e, props.fileName, props.filePreview, numPages, props.currentIndex)}
        />        
    </Document>
    
  );
}

export default PDFSlider;