import * as React from 'react';
import { Container, Dropdown, Form, Button, OverlayTrigger, Tooltip, Modal, Spinner } from "react-bootstrap";
import "./sales.scss";

export class Documents extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
        };
    }
    render() {
        return (
            <>Documents Tab</>
        );
    }
}