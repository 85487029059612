import * as React from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ModuleReports } from "../../Common/Services/ModuleReports";

export class AccountingCOAType extends React.Component<any, any> {


  private summarySubTypes = [
    { label: "Include all COA's", value: "1" },
    { label: "Include COA range", value: "3" },
    { label: "Single COA", value: "2" },
  ];


  constructor(props: any) {
    super(props);
    
    this.state = {
      selectedItem: { label: "Include all COA's", value: "1" },
      summarySubTypes: this.summarySubTypes,
    };
  }

  componentDidMount = () => {
    
    const { defaultValue } = this.props;
    if (defaultValue === "Include all COA's") {
      this.handleDropdownChange({
       label: "Include all COA's",
        value: "1",
      });
    } else {
      this.handleDropdownChange(this.state.selectedItem);
    }
  };


  handleDropdownChange = (item: any) => {
      
   this.setState({ selectedItem: item });
    this.props?.handleCOAType(item.value);
   
  };
   


  render() {

    return (
      <Dropdown className="single-select">
        <Dropdown.Toggle id="Modal-empType">
          <span
              style={{
                width: "95%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "block",
              }}
            >
              {this.state.selectedItem.label}
            </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div className="list-area">
            {this.state.summarySubTypes.map((item: any, idx: any) => (
             
                <Dropdown.Item onClick={() => this.handleDropdownChange(item)}>
                  {item.label}
                </Dropdown.Item>
            ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

