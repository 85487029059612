import React,{useRef} from "react";
import { FiChevronDown } from "react-icons/fi";
import OutsideClickHandler from "react-outside-click-handler";
import { Calendar } from "react-calendar";

import moment from "moment";
import { ICalenderFilterProps } from "../../../Contracts/ICalenderFilter";
import DateRangePicker from "react-bootstrap-daterangepicker";
const CalenderFilter = ({
  startDate,
  endDate,
  handleDatesChange,
}: ICalenderFilterProps) => {

  const startDateRef:any = useRef();
  const endDateRef:any = useRef();
  const tabindexDivs = document?.querySelectorAll('[role="dialog"]');
  
    if (tabindexDivs?.length > 1) {
      document
        .querySelectorAll('[role="dialog"]')[1]
        .removeAttribute("tabIndex");
    } else {
      document
        ?.querySelectorAll('[role="dialog"]')[0]
        ?.removeAttribute("tabIndex");
    }

  let startDateInitialSettings: any = {};
  
  startDateInitialSettings = {
    showDropdowns: true,
    singleDatePicker: true,
    autoUpdateInput: false,
    autoApply: true,
   // maxDate:endDate,
    locale: {
      daysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    }
  };


  let endDateInitialSettings: any = {};

  endDateInitialSettings = {
    showDropdowns: true,
            singleDatePicker: true,
            autoUpdateInput: false,
            autoApply: true,
          //  minDate: startDate,
            locale: {
              daysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            }
  };

  return (
    <div className="calender-container">
      <div className="dateRangeContainer d-flex align-items-center">
        <DateRangePicker
          ref={startDateRef}
          initialSettings={startDateInitialSettings}
          onApply={(e: any, picker: any) => {
            const value = picker.startDate.format("MM/DD/YY");
            handleDatesChange(value, "StartDate",endDateRef);
          }}
        >
          {!startDate ? (
            <input type="text" placeholder="MM/DD/YY" size={8} maxLength={8} value={""} className="" />
          ) : (
            <input type="text" value={startDate} className="" maxLength={8} size={8}/>
          )}
        </DateRangePicker>
        <span>-</span>

        <DateRangePicker
          ref={endDateRef}
          initialSettings={endDateInitialSettings}
          onApply={(e: any, picker: any) => {
            const value = picker.startDate.format("MM/DD/YY");
            handleDatesChange(value, "EndDate",startDateRef);
          }}
        >
          {!endDate ? (
            <input type="text" placeholder="MM/DD/YY" maxLength={8} size={10} value={""} className="" />
          ) : (
            <input type="text" value={endDate} className="" maxLength={8} size={10}/>
          )}
        </DateRangePicker>
      </div>

      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z" />
        <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z" />
      </svg>
    </div>
  );
};

export default CalenderFilter;
