import React from "react";
import { Modal, Button, Form, Dropdown } from "react-bootstrap";
import { UserManagement } from "../../../Common/Services/UserManagement";
import { toast } from "react-toastify";
import { IACATrackingFileUploadRequest } from "../../../Common/Contracts/IACATrackingFileUploadRequest";
import BootstrapTable from "react-bootstrap-table-next";
import { Utils } from "../../../Common/Utilis";

export class ACAFileEditUpload extends React.Component<any, any> {

  private fileRef: any = React.createRef();
  state = {
    fileUniqueNo: 0,
    comments: [],
    uploadedFilesData: [],
    notes: "",
    files: [],
    selectedFileRow: {},
    actions: ["Download", "Delete"],
  };

  componentDidMount() {
    this.getACATrackingUpload();
  }
  componentWillUnmount = () => {
    // setTimeout(() => {
    //   toast.dismiss();
    // }, 1000)
  }
  insertACAFileComment = () => {
    const { selectedFileRow, notes } = this.state;
    UserManagement.InsertACAFileComment((selectedFileRow as any)?.fileCode, notes)
      .then((result) => {
        if (result?.message === "Success") {
          this.getACAFileComment(selectedFileRow);
          toast.success(result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          Utils.toastError(result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((error) => {
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  getACATrackingUpload = () => {
    const { parentData } = this.props;
    UserManagement.GetACATrackingUpload(parentData.uniqueno, this.props?.userUniqueNo).then(
      (uploadedFilesData) => {
        this.setState({ uploadedFilesData });
      }
    );
  };

  onComment = (event: any) => {
    this.setState({ notes: event.target.value })
  }

  getACAFileComment = (row) => {
    UserManagement.GetACAFileComment(row.fileCode).then((comments) => {
      this.setState({ comments });
    });
  };

  selectFiles = (event: any) => {

    let files: File[] = event.target.files;
    this.setState({ files });
  };

  saveACATrackingFile = () => {
    const { notes, files } = this.state;
    const { username, parentData } = this.props;

    const request: IACATrackingFileUploadRequest = {
      Uniqueno: parentData?.uniqueno,
      Notes: notes,
      CheckFileSize: "2",
      Username: username,
      UserUniqueno: this.props?.userUniqueNo
    } as IACATrackingFileUploadRequest;




    UserManagement.SaveACATrackingFile(request, files)
      .then((result) => {
        if (result?.message === "Success") {
          toast.success(result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          this.getACATrackingUpload();
          this.fileRef.current.value = null;
          this.setState({files: []});

        } else {
          Utils.toastError(result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((error) => {
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  deleteACAFile = (row) => {
    UserManagement.DeleteACAFile(row.fileCode)
      .then((result) => {

        if (result?.message === "Success") {
          toast.success(result?.result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          this.getACATrackingUpload();

        } else {
          Utils.toastError(result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((error) => {
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  handleRowActions = (action, row) => {
    this.setState({ selectedFileRow: row })
    switch (action) {
  
      case "Delete":
        this.deleteACAFile(row)
        break;
      case "Download":
        this.downloadACAFile(row)
        break;

      default:
        break;
    }
  };

  downloadACAFile = (row) => {
    UserManagement.DownloadACAFile(row.fileCode, row?.filename).then(() => {

    });
  }

  render() {
    const { showACAUpload, handleCloseACAUpload } = this.props;
    const { uploadedFilesData, actions } = this.state;
    const columns = [
      {
        dataField: "filename",
        text: "Filename",
      },

      {
        dataField: "",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <Dropdown
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="more-action"
            alignRight
          >
            <Dropdown.Toggle
              className="btn-outline-primary btn btn-primary btn-ghost more"
              id="dropdown-more"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {actions.map((item) => (
                <Dropdown.Item
                  key={item}
                  eventKey={item}
                  onClick={() => this.handleRowActions(item, row)}
                >
                  {item}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        ),
      },
    ];

    return (
      <>
     
        <Modal
          className="aca-file-upload-modal"
          show={showACAUpload}
          onHide={handleCloseACAUpload}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>ACA Tracking Documents</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="doc-preview"></div>
              <div className="doc-upload">
                <div className="form-section">
                  <div className="body-section">
                    <Form.Group controlId="taxType" className="d-flex flex-column">
                      <label>Add Doc</label>
                      <div className="d-flex">
                        <input
                          ref={this.fileRef}
                          multiple={true}
                          type="file"
                        //  multiple={true}
                          onChange={(event) => this.selectFiles(event)}
                          id="myFile"
                          name="filename"
                          className="form-control"
                        />
                        <Button disabled={this.state?.files?.length ===0}  onClick={this.saveACATrackingFile}>Upload</Button>
                      </div>
                    </Form.Group>
                  </div>

                  <div className="body-section">
                    <BootstrapTable
                      keyField="rowNo"
                      data={uploadedFilesData}
                      columns={columns}
                      hover
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          {/* <Modal.Footer></Modal.Footer> */}
        </Modal>
      </>
    );
  }
}
