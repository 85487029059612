import * as React from "react";
import { Button, Card, Form } from "react-bootstrap";
import { ForgotPassword as PwdService } from "../Common/Services/ForgotPasswordService";
import { resolve, reject } from "q";
import { Redirect } from "react-router-dom";
import PageLoader from "../Common/Components/PageLoader";
//const ResetMethod: React.FC = () => {
export class ResetMethod extends React.Component<any, any> {
  private continueBtnRef:any;
  constructor(props: any) {
    super(props);
    this.continueBtnRef = React.createRef();
    this.state = {
      mode: 2,
      userName: this.props.changeModule[1],
      passCode: "",
      resetMode: "mail",
      navigate: false,
      loaderTrue: false,
      errorMessage: "",
    };
    this.handleContinue = this.handleContinue.bind(this);
  }
  handleContinue(event: any) {
    event.preventDefault();
    this.continueBtnRef.current.disabled = true;
    this.setState({ loaderTrue: true });
    const form = event.target;
    var OTPMethod = "sms";
    if (form.elements.SMS.checked) {
      OTPMethod = "sms";
    } else if (form.elements.Email.checked) {
      OTPMethod = "mail";
    }
    let errorMessage = "";
    PwdService.generatePassCode(this.state.userName, OTPMethod,0,this.props.platform)
      .then(async (response: any | null) => {
        if (response.status === "Success") {
            this.setState({
            passCode: response.passcode,
            resetMode: OTPMethod,
          });
          this.props.changeModule[0](this.state);
          this.continueBtnRef.current.disabled = false;
          this.setState({ loaderTrue: false });
        } else {
          this.setState({ passCode: 0, resetMode: OTPMethod, logAuditId:response.logAuditId });
          this.props.changeModule[0](this.state);
          this.continueBtnRef.current.disabled = false;
          this.setState({ loaderTrue: false });
        }
        this.setState({ loaderTrue: false, errorMessage: errorMessage });
        resolve();
      })
      .catch((error) => {
        this.setState({ loaderTrue: false });
//        this.continueBtnRef.current.disabled = false;
        reject();
      });
  }
  cancel = () => {
    this.setState({ navigate: true });
    if (this.props.page === "timeclock") {
      return this.props.cancelResetPassword();
    }
  };

  render() {
    const { navigate } = this.state;

    if (navigate && this.props.page !== "timeclock") {
      return <Redirect to={this.state.redirect} />;
    }

    var loaderTrue = this.state.loaderTrue;
    var loadShow;
    if (loaderTrue) {
      loadShow = <PageLoader />;
    }
    return (
      <div className="reset-method">
        {loadShow}
        <div className="header-sec under-line">
          <div className="sec-ttl">Choose Reset Method</div>
          <div className="sec-desc">If the username you entered is valid, you can reset your password by either receiving an email or a text message. Select below which method you prefer.</div>
        </div>
        <Form
          className="frm-section"
          name="forgotForm"
          onSubmit={this.handleContinue}
        >
          <Form.Group controlId="formBasicEmail" className="resetMethod">
            <Form.Check
              custom
              type="radio"
              label="Text Message"
              name="resetMethodRadios"
              id="SMS"
              onChange={() =>
                this.setState({ resetMode: "sms", errorMessage: "" })
              }
              checked={this.state.resetMode === "sms" ? true : false}
            />
            <Form.Check
              custom
              type="radio"
              label="Email"
              name="resetMethodRadios"
              id="Email"
              onChange={() =>
                this.setState({ resetMode: "mail", errorMessage: "" })
              }
              checked={this.state.resetMode === "mail" ? true : false}
            />
            <div className="was-validated">
              <span className="invalid-feedback">
                {this.state.errorMessage}
              </span>
            </div>
          </Form.Group>
          <div className="frm-action">
            <Button variant="primary" type="submit" ref={this.continueBtnRef}>Continue</Button>
            <Button variant="primary" type="button"className="white-bg" onClick={() => this.cancel()}>Cancel</Button>
          </div>
        </Form>
      </div>
    );
  }
}
