import React from "react";
import { Form } from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import OutsideClickHandler from "react-outside-click-handler";

export class MultiSelectDepartment extends React.Component<any, any> {
  private inputSearch: any;
  constructor(props: any) {
    super(props);
    this.inputSearch = React.createRef();
    this.state = {
      selectedItems: [],
      itemLists: [],
      departmentList: [],
      allEmployeeList: [],
      filterData: [],
      opened: false,
      isSelect: true,
      inputValue: "",
      positionID: 0,
      positionName: "",
      isRemove: true,
      isEdit: "",
      isSelectAll: true,
    };
  }

  componentDidMount() {
    let copyDepartmentList = [...this.props.parentState.copyDepartmentList];
    let removeIndex = copyDepartmentList.findIndex(
      (xvalue: any) =>
        xvalue.name.toLowerCase().trim() === "All".toLowerCase().trim()
    );

    if (removeIndex > -1) {
      copyDepartmentList.splice(removeIndex, 1);
    }
    this.setState(
      {
        itemLists: copyDepartmentList,
        departmentList: copyDepartmentList,
        selectedItems: [],
      },
      () => {
        let itemLists = [...this.state.itemLists];
        for (let i = 0; i < itemLists.length; i++) {
          itemLists[i].isChecked = false;
        }
      }
    );
  }

  updateLists = () => {
    let { filterData, departmentList } = this.state;

    if (filterData.length === 0 && this.state.inputValue === "") {
      this.setState({ itemLists: departmentList });
    }
  };

  openDropdown = () => {
    this.setState(
      {
        opened: true,
        filterData: [],
        inputValue: "",
      },
      () => {
        this.inputSearch.current.focus();
        this.updateLists();
      }
    );
  };

  handleOutsideClick = (e: any) => {
    this.setState({ opened: false, inputValue: "" });
  };

  handleChange = (e: any) => {
    const updatedList = this.state.itemLists.filter((item: any) => {
      return item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1;
    });

    this.setState(
      { itemLists: updatedList, inputValue: e.target.value },

      () => {
        this.updateLists();
      }
    );
  };

  handleRemoveSpecificChip = (idx: number) => () => {
    const itemLists = [...this.state.itemLists];
    const selectedItems = [...this.state.selectedItems];
    if (selectedItems[0].name !== undefined) {
      const rowIndex = itemLists.findIndex(
        (obj) =>
          obj.name.toLowerCase().trim() ===
          selectedItems[idx].name.toLowerCase().trim()
      );
      itemLists[rowIndex].isChecked = false;
      selectedItems.splice(idx, 1);

      this.setState({ selectedItems, itemLists }, () => {
        this.props.employeeSelection(this.state);
      });
    }
  };

  handleCheckCheckbox = (idx: any, e: any) => {
    const itemLists = [...this.state.itemLists];
    const selectedItems = [...this.state.selectedItems];

    const index = itemLists.findIndex((xvalue) => xvalue.id === idx);

    itemLists[index].isChecked = e.target.checked;

    idx = index;
    this.setState({ itemLists });

    if (e.target.checked === true) {
      const item = {
        id: itemLists[idx].id,
        name: itemLists[idx].name,
        isChecked: e.target.checked,
      };
      this.setState(
        {
          selectedItems: [...this.state.selectedItems, item],
        },
        () => {
          this.props.employeeSelection(this.state);
        }
      );
    } else {
      const removeIndex = selectedItems.findIndex(
        (xvalue) =>
          xvalue.name.toLowerCase().trim() ===
          itemLists[index].name.toLowerCase().trim()
      );
      selectedItems.splice(removeIndex, 1);

      this.setState({ selectedItems, isRemove: false }, () => {
        this.props.employeeSelection(this.state);
      });
    }
  };

  getIndex = (val: any, type: string) => {
    if (type === "removeChip") {
      let itemLists = [...this.state.itemLists];
      return itemLists.findIndex((obj) => obj.id === val);
    } else {
      let selectedItems = [...this.state.selectedItems];
      return selectedItems.findIndex((obj) => obj.id === val);
    }
  };

  handleSelectAll = () => {
    let allItemList = [...this.state.itemLists];
    allItemList.forEach((elem) => (elem.isChecked = true));
    this.setState(
      {
        itemLists: allItemList,
        isSelect: false,
        selectedItems: allItemList,
      },
      () => {
        this.props.employeeSelection(this.state);
        this.setState({ opened: false });
      }
    );
  };

  handleClearAll = () => {
    let allItemList = [...this.state.itemLists];
    allItemList.forEach((elem) => (elem.isChecked = false));
    this.setState(
      { itemLists: allItemList, isSelect: true, selectedItems: [] },
      () => {
        this.props.employeeSelection(this.state);
      }
    );
  };

  render() {
    return (
      <OutsideClickHandler onOutsideClick={this.handleOutsideClick}>
        <div
          className={
            this.state.opened ? "multiSelectSearch opened" : "multiSelectSearch"
          }
        >
          <div className="fieldArea" onClick={this.openDropdown}>
            {this.state.selectedItems.length === 0 ? (
              <span className="areaPlaceholder placeholder">
                Select one or more department
              </span>
            ) : (
              <div className="chip">
                {this.state.selectedItems.map((item: any, idx: any) => (
                  <div className="chipSelect" id={idx} key={idx}>
                    <div className="chipVal">
                      <EllipsisWithTooltip placement="bottom">
                        {item.name}
                      </EllipsisWithTooltip>
                    </div>
                    <button
                      id="btnremove"
                      type="button"
                      className="cross"
                      onClick={this.handleRemoveSpecificChip(idx)}
                    >
                      <svg
                        width="8"
                        height="8"
                        viewBox="0 0 8 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.79465 0.229492L3.96598 3.05749L1.13798 0.229492L0.195312 1.17216L3.02331 4.00016L0.195312 6.82816L1.13798 7.77083L3.96598 4.94282L6.79465 7.77083L7.73731 6.82816L4.90931 4.00016L7.73731 1.17216L6.79465 0.229492Z"
                          fill="#6A6E73"
                        />
                      </svg>
                    </button>
                  </div>
                ))}
              </div>
            )}

            <div className="coaSearch">
              <div className="dropDownPanel inner-label">
                <div className="SearchInput">
                  <input
                    ref={this.inputSearch}
                    key={"text-Filter"}
                    type="text"
                    className="form-control"
                    onChange={this.handleChange}
                    placeholder="Search"
                    value={this.state.inputValue}
                  />
                </div>
                <ul>
                  {this.state.itemLists.length > 0 && (
                    <div>
                      <div className="dropdown-header divider">
                        ALL Departments
                      </div>
                      {this.state.itemLists.map((item: any, idx: any) => (
                        <li
                          id={idx}
                          key={idx}
                          className={
                            item.isconflict === "Yes" ? "disabled" : ""
                          }
                        >
                          <Form.Check
                            custom
                            type="checkbox"
                            key={"custom-" + idx}
                            id={"custom-" + idx}
                            checked={item.isChecked ? true : false}
                            onChange={(e: any) =>
                              this.handleCheckCheckbox(item.id, e)
                            }
                          />

                          <div className="name-position mr-auto">
                            <EllipsisWithTooltip placement="bottom">
                              <div className="name">{item.name}</div>
                            </EllipsisWithTooltip>
                          </div>
                        </li>
                      ))}
                    </div>
                  )}
                </ul>
                {this.state.isSelectAll && this.state.itemLists.length > 1 && (
                  <div className="footer">
                    {this.state.isSelect && (
                      <button
                        id="selectAll"
                        type="button"
                        className="btn wht-bg txt-green"
                        onClick={this.handleSelectAll}
                      >
                        Select All
                      </button>
                    )}
                    {!this.state.isSelect && (
                      <button
                        id="ClearAll"
                        type="button"
                        className="btn wht-bg txt-green"
                        onClick={this.handleClearAll}
                      >
                        Clear All
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    );
  }
}
