import React from 'react'
import { Col, Form, Modal, Row, Dropdown, Spinner } from 'react-bootstrap'
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { FiChevronDown, FiArrowRight } from "react-icons/fi";
import _ from "lodash";
import { toast } from "react-toastify";
import { VendorService } from "../../../../Common/Services/vendor";
import { resolve, reject } from "q";
import { confirmAlert } from "react-confirm-alert";
import { Utils } from '../../../../Common/Utilis';
export default class MergeProcessModal extends React.Component<any, any> {
    private ListItemsFrom: any
    private ListItemsToo: any;
    constructor(props: any) {
        super(props);
        this.ListItemsFrom = React.createRef();
        this.ListItemsToo = React.createRef();
        this.state = {
            itemsList: props?.itemsList,
            fromList: [],
            fromFilter: [],
            toList: [],
            toFilter: [],
            searchItem: '',
            listMergeFrom: { count: 0, label: "" },
            listMergeTooo: { id: "", label: "", vendorAprroval: "", hid: -1 },
            submitLoader: false,
            itemSelectAll: false,
        };
    }

    checkSelecall = (fromList: any) => {
        let getCheckList: boolean = false;
        let parseFilter = fromList.filter(item => !item.isDiffrentEhid);
        getCheckList = parseFilter.some(item => item.isChecked === false) ? false : true;
        this.setState(() => ({ itemSelectAll: getCheckList }));
    }

    componentDidMount(): void {
        document.addEventListener("keydown", this.escFunction, false);
        let isVendor = this.props?.callType === "vendor";
        let isContract = this.props?.callType === "contracts";
        let isEtypeBlank = this.props?.eventType === "blank";
        let isEtypeRow = this.props?.eventType === "row";
        let { itemsList, fromList, fromFilter, listMergeFrom } = this.state;
        if (isVendor && isEtypeRow) {
            itemsList.forEach((item: any, index: any) => {
                fromList.push(item);
                fromFilter.push(item);
            });
            // this.checkSelecall(fromList);
        } else if (isContract && isEtypeRow) {
            if (Number(this.props?.isHid) !== -1) {
                itemsList.forEach((item: any) => {
                    if (Number(item.hid) === Number(this.props?.isHid)) {
                        fromList.push(item);
                        fromFilter.push(item);
                    }
                });
            } else {
                itemsList.forEach((item: any) => {
                    fromList.push(item);
                    fromFilter.push(item);
                });
            }
            // this.checkSelecall(fromList);
        } else if (isContract && isEtypeBlank) {
            itemsList.forEach((item: any) => {
                fromList.push(item);
                fromFilter.push(item);
            });
        }
        fromList.forEach((item: any) => {
            if (item.id === this.props?.isId) {
                item.isChecked = true;
                item.sortIndex = "-1" + (item.label).substring(0, 1).toLocaleLowerCase();
            } else {
                item.isChecked = false;
                item.sortIndex = null;
            }
        });
        fromFilter.forEach((item: any) => {
            if (item.id === this.props?.isId) {
                item.isChecked = true;
                item.sortIndex = "-1" + (item.label).substring(0, 1).toLocaleLowerCase();
            } else {
                item.isChecked = false;
                item.sortIndex = null;
            }
        });

        fromList = fromList.filter((o: any) =>
            !o.isDiffrentEhid &&
            o.label.toString().toLocaleLowerCase() !== "generic vendor" &&
            o.cardNoExists.toString().toLocaleLowerCase() === "notexists" &&
            o.isContractAgency.toString().toLocaleLowerCase() === "0" &&
            o.vendorAprroval.toString().toLocaleLowerCase() === "app"
        )
        fromFilter = fromFilter.filter((o: any) =>
            !o.isDiffrentEhid &&
            o.label.toString().toLocaleLowerCase() !== "generic vendor" &&
            o.cardNoExists.toString().toLocaleLowerCase() === "notexists" &&
            o.isContractAgency.toString().toLocaleLowerCase() === "0" &&
            o.vendorAprroval.toString().toLocaleLowerCase() === "app"
        )
        fromList = _.sortBy(fromList, 'sortIndex');
        fromFilter = _.sortBy(fromFilter, 'sortIndex');
        let currentVendor = fromList.filter((item: any) => item.id === this.props?.isId);
        if (currentVendor.length > 0) {
            listMergeFrom.count = 0;
            listMergeFrom.label = currentVendor[0].label;
        }
        this.checkSelecall(fromList);
        this.setState(() => ({ fromList, fromFilter, listMergeFrom }));
    }

    hoverOutEllipsis = () => {
        $(".tooltip").removeClass("show");
    };

    escFunction = (event) => {
        if (event.key === "Escape") {
            this.closeMergeProcessModal();
        }
    }

    clearSearch = (e) => {
        let isVendor = this.props?.callType === "vendor";
        let { fromFilter, toFilter, listMergeFrom } = this.state;
        fromFilter = _.sortBy(fromFilter, 'sortIndex');
        let selectedItemList = fromFilter.filter(item => item.isChecked);
        if (selectedItemList.length === 1) {
            listMergeFrom.count = 0;
            listMergeFrom.label = selectedItemList[0].label;
        } else if (selectedItemList.length > 1) {
            listMergeFrom.count = selectedItemList.length;
            listMergeFrom.label = isVendor ? "Vendors" : "Contracts";
        } else {
            listMergeFrom.count = 0;
            listMergeFrom.label = '';
        }
        setTimeout(() => {
            this.ListItemsFrom.current?.scrollTo({ top: 0 });
            this.ListItemsToo.current?.scrollTo({ top: 0 });
        }, 50)
        this.setState(() => ({
            searchItem: "",
            toList: toFilter,
            fromList: fromFilter,
            listMergeFrom
        }));
    }

    handleSearchItem = (event: any) => {
        let { fromList, fromFilter, toList, toFilter, searchItem } = this.state;
        let isContractBlank = this.props?.callType === "contracts" && this.props?.eventType === "blank";
        let filter = event.target.value;
        fromFilter = _.sortBy(fromFilter, 'sortIndex');
        searchItem = filter;
        if (filter !== "") {
            fromList = fromFilter.filter((items) => items.labelSearch.toString().toLowerCase().includes(filter.toLowerCase().trim()));
            if (isContractBlank) {
                toList = toFilter.filter((items) => items.labelSearch.toString().toLowerCase().includes(filter.toLowerCase().trim()));
            }
        } else {
            fromList = fromFilter
            if (isContractBlank) {
                toList = toFilter
            }
        }
        this.setState(() => ({ fromList, searchItem, toList, toFilter }));
    }

    itemCheckChange = (event: any, value: any) => {
        let isVendor = this.props?.callType === "vendor";
        let isContract = this.props?.callType === "contracts";
        let isEtypeBlank = this.props?.eventType === "blank";
        let isEtypeRow = this.props?.eventType === "row";
        let { itemsList, fromList, fromFilter, toList, toFilter, listMergeFrom, listMergeTooo } = this.state;
        let isCheck = event.target.checked;
        if (isContract && isEtypeBlank) {
            toList = []; toFilter = [];
            let hasEhidBasedList = itemsList.filter((item: any) => item.hid === value.hid);
            if (hasEhidBasedList.length > 0) {
                hasEhidBasedList.forEach((item: any) => {
                    toList.push(item);
                    toFilter.push(item);
                })
            }

            let ehidList = fromList.filter((item: any) => (Number(item.hid) === Number(value.hid)));
            fromList.map((item: any, index: any) => {
                if (Number(item.hid) === Number(value.hid)) {
                    if (item.id === value.id) {
                        item.isChecked = isCheck;
                        item.sortIndex = "-1" + (item.label).substring(0, 1).toLocaleLowerCase();;
                    }
                    item.isDiffrentEhid = false;
                } else {
                    item.isDiffrentEhid = ehidList.some((o: any) => o.isChecked)
                    item.isChecked = false;
                }
            });

            fromFilter.map((item: any, index: any) => {
                if (Number(item.hid) === Number(value.hid)) {
                    if (item.id === value.id) {
                        item.isChecked = isCheck;
                        item.sortIndex = "-1" + (item.label).substring(0, 1).toLocaleLowerCase();;
                    }
                    item.isDiffrentEhid = false;
                } else {
                    item.isDiffrentEhid = ehidList.some((o: any) => o.isChecked)
                    item.isChecked = false;
                }
            });

            if (fromList.some(item => item.isChecked && (item.id === listMergeTooo.id || item.hid !== listMergeTooo.hid))) {
                listMergeTooo = { id: "", label: "", vendorAprroval: "", hid: -1 };
            }
        } else if ((isContract || isVendor) && isEtypeRow) {
            fromList.forEach((item: any, index: any) => {
                if (item.id === value.id) {
                    item.isChecked = isCheck;
                }
                if (fromList.some(item => item.id === listMergeTooo.id && item.isChecked)) {
                    listMergeTooo = { id: "", label: "", vendorAprroval: "", hid: -1 };
                }
            });
            fromFilter.forEach((item: any, index: any) => {
                if (item.id === value.id) {
                    item.isChecked = isCheck;
                    item.sortIndex = isCheck ? "-1" + (item.label).substring(0, 1).toLocaleLowerCase() : null;
                }
            });
        }
        let selectedItemList: any = [];
        selectedItemList = fromList.filter(item => item.isChecked);
        if (selectedItemList.length === 1) {
            listMergeFrom.count = 0;
            listMergeFrom.label = selectedItemList[0].label;
        } else if (selectedItemList.length > 1) {
            listMergeFrom.count = selectedItemList.length;
            listMergeFrom.label = isVendor ? "Vendors" : "Contracts";
        } else {
            listMergeFrom.count = 0;
            listMergeFrom.label = '';
        }

        let parseFilter = fromList.filter(item => !item.isDiffrentEhid);
        let getCheckList: boolean = false;
        getCheckList = parseFilter.some(item => item.isChecked === false) ? false : true;
        this.setState(() => ({ fromList, fromFilter, toList, toFilter, listMergeFrom, listMergeTooo, itemSelectAll: getCheckList }))
    }


    handleItemSelectAll = (event: any) => {
        let isVendor = this.props?.callType === "vendor";
        let { fromList, listMergeFrom } = this.state;
        let isChecked = event.target.checked;
        let selectedItemList: any = [];
        let parseFilter = fromList.filter(item => !item.isDiffrentEhid);
        parseFilter.map(item => (item.isChecked = isChecked));
        selectedItemList = fromList.filter(item => item.isChecked);
        if (selectedItemList.length === 1) {
            listMergeFrom.count = 0;
            listMergeFrom.label = selectedItemList[0].label;
        } else if (selectedItemList.length > 1) {
            listMergeFrom.count = selectedItemList.length;
            listMergeFrom.label = isVendor ? "Vendors" : "Contracts";
        } else {
            listMergeFrom.count = 0;
            listMergeFrom.label = '';
        }
        this.setState({ itemSelectAll: isChecked, fromList: parseFilter, listMergeFrom })
    }

    handleSelectSingleItem = (event: any) => {
        let isVendor = this.props?.callType === "vendor";
        let { listMergeTooo, fromList } = this.state;
        let selectedItemList: any = [];
        selectedItemList = fromList.filter(item => isVendor ? Number(item.id) === Number(event) : item.id === event);
        if (selectedItemList.length > 0) {
            listMergeTooo.id = selectedItemList[0].id;
            listMergeTooo.label = selectedItemList[0].label;
            listMergeTooo.hid = selectedItemList[0]?.hid
            listMergeTooo.vendorAprroval = selectedItemList[0].vendorAprroval;
        }
        this.setState({ listMergeTooo })
    }

    closeMergeProcessModal = () => {
        let { fromList } = this.state;
        fromList.map(item => { item.isChecked = false; item.sortIndex = null; item.isDiffrentEhid = false });
        this.setState(() => ({
            listMergeFrom: { count: 0, label: '' },
            listMergeTooo: { id: "", label: "", vendorAprroval: "", hid: -1 },
            fromList
        }));
        this.props.hideModal()
    }

    checkBeforeMerge = () => {
        let isVendor = this.props?.callType === "vendor";
        let alertMessage: any;
        if (isVendor) {
            alertMessage = "This process is irreversible. It will change all previous transactions and checks associated with both of these vendor accounts. Are you sure you want to proceed?"
        } else {
            alertMessage = "This process is irreversible. It will change all previous transactions and checks. Are you sure you want to proceed?";
        }
        confirmAlert({
            title: "Alert",
            message: alertMessage,
            buttons: [{ label: "Yes", onClick: () => this.saveMergeForm() }, { label: "No", onClick: () => reject() }],
            closeOnEscape: false,
            closeOnClickOutside: false,
        });
    }

    saveMergeForm = () => {
        let isVendor = this.props?.callType === "vendor";
        $(".disabled-area").find("input form-control button").prop("disabled", true);
        let { fromList, listMergeFrom, listMergeTooo } = this.state;
        if (isVendor) {
            if (listMergeFrom.label === "") {
                Utils.toastError("Please select from vendor.", { position: toast.POSITION.BOTTOM_RIGHT, });
            } else if (listMergeTooo.label === "") {
                Utils.toastError("Please select to vendor.", { position: toast.POSITION.BOTTOM_RIGHT, });
            } else {
                this.setState({ submitLoader: true })
                let checkItems = fromList.filter(item => item.isChecked);
                let request: any = {}
                let vidList: any = [];
                checkItems.length > 0 && checkItems.forEach(item => vidList.push(item.vid));
                request.FromVID = vidList.toString();
                request.ToVID = listMergeTooo.id;
                request.ToVendorName = listMergeTooo.label;
                VendorService.MergeVendor(request)
                    .then(async (result: any | null) => {
                        if (result.message.toString().toLocaleLowerCase() === "success") {
                            toast.success("Vendor merged successfully.", { position: toast.POSITION.BOTTOM_RIGHT });
                            this.props.isReload(true);
                            this.setState({ submitLoader: false }, () => {
                                this.closeMergeProcessModal();
                                this.props.isReloadMain(listMergeTooo.id, listMergeTooo.label, listMergeTooo.vendorAprroval);
                            });
                        } else if (result.message.toString().toLocaleLowerCase() === "vendornotexists") {
                            Utils.toastError("This Vendor is already merged into another vendor.", { position: toast.POSITION.BOTTOM_RIGHT });
                            this.setState({ submitLoader: false });
                        } else if (result.message.toString().toLocaleLowerCase() === "vendornotactive") {
                            Utils.toastError("Destination Vendor is inactive.", { position: toast.POSITION.BOTTOM_RIGHT });
                            this.setState({ submitLoader: false });
                        } else {
                            Utils.toastError(result.message, { position: toast.POSITION.BOTTOM_RIGHT });
                            this.setState({ submitLoader: false });
                        }
                        resolve();
                    })
                    .catch((error: any) => {
                        Utils.toastError(error, { position: toast.POSITION.BOTTOM_RIGHT });
                        this.setState({ submitLoader: false });
                        reject();
                    });
            }
        } else {
            if (listMergeFrom.label === "") {
                Utils.toastError("Please select from contract.", { position: toast.POSITION.BOTTOM_RIGHT, });
            } else if (listMergeTooo.label === "") {
                Utils.toastError("Please select to contract.", { position: toast.POSITION.BOTTOM_RIGHT, });
            } else {
                this.setState({ submitLoader: true })
                let checkItems = fromList.filter(item => item.isChecked);
                let request: any = {}
                let idList: any = [];
                checkItems.length > 0 && checkItems.forEach(item => idList.push(item.label));
                request.VID = fromList[0].vid;
                request.HID = fromList[0].hid;
                request.FromAccountNo = idList.toString();
                request.ToAccount = listMergeTooo.label;
                VendorService.MergeContract(request)
                    .then(async (result: any | null) => {
                        if (result.message.toString().toLocaleLowerCase() === "success") {
                            toast.success("Contract merged successfully.", { position: toast.POSITION.BOTTOM_RIGHT });
                            this.props.isReload(true);
                            this.props.isReloadMain(true);
                            this.setState({ submitLoader: false });
                            this.closeMergeProcessModal();
                        } else if (result.message.toString().toLocaleLowerCase() === "vendornotexists") {
                            Utils.toastError("This Vendor is already merged into another vendor.", { position: toast.POSITION.BOTTOM_RIGHT });
                            this.setState({ submitLoader: false });
                        } else {
                            Utils.toastError(result.message, { position: toast.POSITION.BOTTOM_RIGHT });
                            this.setState({ submitLoader: false });
                        }
                        resolve();
                    })
                    .catch((error: any) => {
                        Utils.toastError(error, { position: toast.POSITION.BOTTOM_RIGHT });
                        this.setState({ submitLoader: false });
                        reject();
                    });
            }
        }
    }

    render() {
        let isVendor = this.props?.callType === "vendor";
        let isContractBlank = this.props?.callType === "contracts" && this.props?.eventType === "blank";
        let iscallTypeContract = this.props?.callType === "contracts";
        let isContractRow = this.props?.callType === "contracts" && this.props?.eventType === "row";
        let { listMergeFrom, listMergeTooo, fromList, toList } = this.state;
        const formCheckLabel = (item: any) => (<EllipsisWithTooltip placement="bottom">{iscallTypeContract && (<>{item.letterCode} - </>)}{item.label}</EllipsisWithTooltip>);
        let saveButtonDisable = (listMergeFrom.label === "" || listMergeTooo.label === "");
        return (
            <div className={isContractRow ? "cusMergeProcessNonModal" : "cusMergeProcessModal"}>
                <div className="inBox">
                    <div className={this.state.submitLoader ? "mergeProcessContainer disabled-area" : "mergeProcessContainer"}>
                        <div className="modalHeader">
                            <h4 className={isContractRow ? "modalTitle titleRow" : "modalTitle titleLarge"}>Merge <span className="text-capitalize">{isVendor ? "Vendors" : "Contracts"}</span></h4>
                            <button className="closeModal" onClick={this.closeMergeProcessModal}></button>
                        </div>
                        <Modal.Body>
                            <div className="mergeProcess">
                                <div className="cusNotificationBanners orange-bg mb-3">
                                    Any conflicting data from the {isVendor ? "vendor" : "contract"} being merged will be lost. Data on the remaining {isVendor ? "vendor" : "contract"} record will be unchanged.
                                </div>
                                <div className="formGroup">
                                    <Row>
                                        <Col xs={6}>
                                            <div className="hasCenterArrow">
                                                <span className="arrowCenter"><FiArrowRight /></span>
                                                <Form.Group controlId="mergeThisControl">
                                                    <Form.Label>{isVendor ? "Merge This Vendor" : "Source"}</Form.Label>
                                                    <div className="cusProcessDropdownWithSearch">
                                                        <Dropdown className="itemDropdown" onToggle={this.clearSearch}>
                                                            <Dropdown.Toggle id="eType">
                                                                <div className="pos-rel">
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="txt-area gTextArea mr-auto">
                                                                            <EllipsisWithTooltip placement="bottom">
                                                                                {listMergeFrom.label === "" ? isVendor ? "Select a vendor..." : "Select a contract..." : listMergeFrom.label}
                                                                            </EllipsisWithTooltip>
                                                                            {listMergeFrom.count > 0 && (<strong className="ListCountBadge">{listMergeFrom.count}</strong>)}
                                                                        </div>
                                                                        <div className="drop-arrow ml-0 d-flex">
                                                                            <FiChevronDown />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <div className="SearchControl">
                                                                    <Form.Control type="text"
                                                                        name="searchItems"
                                                                        id="searchItemsFrom"
                                                                        value={this.state.searchItem}
                                                                        autoComplete="off"
                                                                        onChange={this.handleSearchItem}
                                                                        placeholder="Search..."
                                                                    />
                                                                </div>
                                                                <div className="ListItems ListWithMultiSelect"
                                                                    ref={this.ListItemsFrom}
                                                                    onWheel={() => this.hoverOutEllipsis()}>
                                                                    {fromList.map((item: any, index: any) => {
                                                                        if (item.label.toString().toLocaleLowerCase() !== "generic vendor") {
                                                                            if (item.cardNoExists.toString().toLocaleLowerCase() === "notexists") {
                                                                                if (item.isContractAgency.toString().toLocaleLowerCase() === "0") {
                                                                                    if (item.vendorAprroval.toString().toLocaleLowerCase() === "app") {
                                                                                        return (
                                                                                            <div className={item.isDiffrentEhid ? "dropdownItems disabledCheckArea" : "dropdownItems"} key={index}>
                                                                                                <Form.Check
                                                                                                    type="checkbox"
                                                                                                    id={`checkId_${item.id}`}
                                                                                                    disabled={item.isDiffrentEhid}
                                                                                                    name={item.labelSearch}
                                                                                                    label={formCheckLabel(item)}
                                                                                                    value={item.id}
                                                                                                    onChange={(event) => this.itemCheckChange(event, item)}
                                                                                                    checked={item.isChecked}
                                                                                                />
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    })}
                                                                </div>
                                                                {!isContractBlank && (
                                                                    <div className="selectClearAll dropdownItems">
                                                                        <Form.Check
                                                                            type="checkbox"
                                                                            id={`itemSelectAll`}
                                                                            label={this.state.itemSelectAll ? "Clear All" : "Select All"}
                                                                            value={this.state.itemSelectAll ? "clearAll" : "selectAll"}
                                                                            onChange={(event) => this.handleItemSelectAll(event)}
                                                                            checked={this.state.itemSelectAll}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </Dropdown.Menu>
                                                        </Dropdown>

                                                    </div>
                                                </Form.Group>
                                            </div>
                                        </Col>
                                        <Col xs={6}>
                                            <Form.Group controlId="intoThisControl">
                                                <Form.Label>{isVendor ? "Into This Vendor" : "Destination"}</Form.Label>
                                                <div className="cusProcessDropdownWithSearch">
                                                    <Dropdown className="itemDropdown"
                                                        onToggle={this.clearSearch}
                                                        onSelect={(e: any) => this.handleSelectSingleItem(e)}>
                                                        <Dropdown.Toggle id="eType">
                                                            <div className="pos-rel">
                                                                <div className="d-flex align-items-center">
                                                                    <div className="txt-area gTextArea mr-auto">
                                                                        <EllipsisWithTooltip placement="bottom">
                                                                            {listMergeTooo.label === "" ? isVendor ? "Select a vendor..." : "Select a contract..." : listMergeTooo.label}
                                                                        </EllipsisWithTooltip>
                                                                    </div>
                                                                    <div className="drop-arrow ml-0 d-flex">
                                                                        <FiChevronDown />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <div className="SearchControl" >
                                                                <Form.Control type="text"
                                                                    name="searchItems"
                                                                    id="searchItemsToo"
                                                                    value={this.state.searchItem}
                                                                    autoComplete="off"
                                                                    onChange={this.handleSearchItem}
                                                                    placeholder="Search..."
                                                                />
                                                            </div>
                                                            <div className="ListItems"
                                                                ref={this.ListItemsToo}
                                                                onWheel={() => this.hoverOutEllipsis()}>
                                                                {isContractBlank ? (
                                                                    <>
                                                                        {toList.map((item: any, index: any) => {
                                                                            if (item.status.toString().toLocaleLowerCase() !== "inactive") {
                                                                                if (!item.isChecked) {
                                                                                    return (
                                                                                        <Dropdown.Item key={index} eventKey={item.id}>
                                                                                            <EllipsisWithTooltip placement="bottom">
                                                                                                {/* {iscallTypeContract && (<>{item.letterCode} - </>)}{item.label} */}
                                                                                                {item.labelSearch}
                                                                                            </EllipsisWithTooltip>
                                                                                        </Dropdown.Item>
                                                                                    )
                                                                                }
                                                                            }
                                                                        })}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {fromList.map((item: any, index: any) => {
                                                                            if (item.status.toString().toLocaleLowerCase() !== "inactive") {
                                                                                if (item.label.toString().toLocaleLowerCase() !== "generic vendor") {
                                                                                    if (item.vendorAprroval.toString().toLocaleLowerCase() === "app") {
                                                                                        if (!item.isChecked) {
                                                                                            return (
                                                                                                <Dropdown.Item
                                                                                                    key={index} eventKey={item.id}>
                                                                                                    <EllipsisWithTooltip placement="bottom">{iscallTypeContract && (<>{item.letterCode} - </>)}{item.label}</EllipsisWithTooltip>
                                                                                                </Dropdown.Item>
                                                                                            )
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            {!isVendor && (
                                <button type="button" className="btn wht-bg btnColorSuccess" onClick={this.closeMergeProcessModal}>Cancel</button>
                            )}
                            <button
                                type="button" className="btn btn-primary"
                                disabled={this.state.submitLoader || saveButtonDisable}
                                onClick={this.checkBeforeMerge}>
                                {this.state.submitLoader && (<Spinner size="sm" variant="light" animation="border" />)}{" "}
                                Merge <span className="text-capitalize">{isVendor ? "Vendors" : "Contracts"}</span>
                            </button>
                        </Modal.Footer>

                    </div>
                </div>
            </div>
        )
    }
}