import React from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Utils } from "../../Common/Utilis";
import { toast, ToastContainer } from "react-toastify";
import Joi from "joi-browser";
import _ from "lodash";
import { IDailyTaskSaveRequest } from "../../Common/Contracts/IDailyTask";
import { FacilityDailyTask } from "../../Common/Services/FacilityDailyTask";
import { DropDownList } from "../Reports/common-components/dropdown-list";


export class AddDailyTask extends React.Component<any, any> {
  private FormSchema = Joi.object({
    taskName: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Task Name is required" };
        });
      }),
    location: Joi.number().optional().allow(""),
  });

  state: any = {
    FormData: {
      taskName: "",
      location: -1,
    },
    errors: {},
    isDataSaving: false,
    isFormNotValid: true,
    inputPosition: 0,
    isEditButton: false,
  };

  handleHideModal = (isReload = false, row = null) => {
    const FormData = {
      taskName: "",
      location: -1,
    };
    this.props?.hideModal(isReload, row);

    this.setState({ FormData, isFormNotValid: true, isDataSaving: false });
  };

  onFieldChange = (event, inputPosition) => {
    const { FormData } = this.state;
    let value = event.target.value;
    const fieldName = event.target.name;
    if (fieldName === "taskName") {
      value = value?.toString()?.trimLeft();
    }

    FormData[fieldName] = value;
    this.setState({ FormData }, () => {
      this.validationOnClick(inputPosition);
    });
  };

  SaveData = () => {
    let {
      FormData: { taskName, location },
    } = this.state;

    const data: IDailyTaskSaveRequest = {
      RoomId: location == -1 ? 0:location,
      TaskName: taskName,
      Hid: this.props.hid,
    };

    this.setState({ isDataSaving: true });

    FacilityDailyTask.SaveDailyTask(data)
      .then((result: any) => {
        this.setState({ isDataSaving: false });
        this.handleHideModal(true, result.result);

        toast.success(`Daily Task Added successfully.`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "addGarnishment",
        });
      })
      .catch((error) => {
        this.setState({ isDataSaving: false });

        if (
          error.response.data ==
          "Task Name already in use. Please provide a different name."
        ) {
          let newErrorObject = _.cloneDeep(this.state.error);
          newErrorObject = {
            ...newErrorObject,
            taskName:
              "Task Name already in use. Please provide a different name.",
          };
          this.setState(
            {
              isDataSaving: false,
              isFormNotValid: false,
              errors: newErrorObject,
            },
            () => {
              this.validateConfigurationFormSchema(true);
            }
          );
        } else {
          Utils.toastError(error.response.data, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addGarnishment",
          });

          this.handleHideModal(true);
        }
      });
  };

  validateConfigurationFormSchema = (isTaskNameChanged = false) => {
    const valid = Joi.validate(this.state.FormData, this.FormSchema, {
      abortEarly: false,
    });

    const newErrorObject: any = {};
    if (valid.error) {
      valid.error.details.forEach((err) => {
        newErrorObject[err.path.join(".")] = err.message;
      });
    }

    if (
      this.state.errors?.taskName ===
        "Task Name already in use. Please provide a different name." &&
      isTaskNameChanged
    ) {
      newErrorObject["taskName"] =
        "Task Name already in use. Please provide a different name.";
    }

    if (Object.keys(newErrorObject).length === 0) {
      this.setState({ isFormNotValid: false });
    } else {
      this.setState({ isFormNotValid: true });
    }
    this.setState({ errors: newErrorObject });
    return newErrorObject;
  };

  getFieldError = (fieldName: string, fieldPosition: number) => {
    const { errors, inputPosition } = this.state;

    return (
      <>
        {Object.keys(errors).length > 0 && fieldPosition <= inputPosition && (
          <span className="validation-message">{errors[fieldName]}</span>
        )}
      </>
    );
  };
  calenderHide = () => {
    this.setState({ calenderShow: false });
  };

  calenderShow = () => {
    this.setState({ calenderShow: true });
  };

  validationOnClick = (inputPosition) => {
    this.setState({ inputPosition }, () =>
      this.validateConfigurationFormSchema()
    );
  };

  render() {
    const {
      FormData: { taskName, location },
      errors,
      isDataSaving,
      isFormNotValid,
      inputPosition,
    } = this.state;

    const { showModal } = this.props;
    return (
      <>
        <div className="">
          <ToastContainer containerId={"addDeduction"} autoClose={3000} />

          <Modal
            className="add-room-type-modal add-daily-task"
            show={showModal}
            onHide={this.handleHideModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            // keyboard={this.props?.showDeductionModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>Add Daily Task</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-section">
                <div className="body-section">
                  <Form.Group
                    onBlur={() => this.validationOnClick(1)}
                    controlId="name"
                    className="d-flex"
                  >
                    <Col
                      sm={"12"}
                      className={`mb-1 ${
                        errors?.taskName && inputPosition >= 1
                          ? "validation-error"
                          : ""
                      }`}
                      // onClick={() => this.validationOnClick(1)}
                    >
                      <Form.Label>
                        Name<b>*</b>
                      </Form.Label>
                      <OverlayTrigger
                        placement="bottom"
                        defaultShow={false}
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip
                            id={"taskName"}
                            className={
                              taskName.length <= 47 ? "tootltip-hide" : ""
                            }
                          >
                            {taskName}
                          </Tooltip>
                        }
                      >
                        <Form.Control
                          type="text"
                          className="form-control"
                          // tabIndex={1}
                          maxLength={100}
                          id="txtTaskName"
                          name="taskName"
                          autoComplete="taskName"
                          placeholder="Enter task name"
                          value={taskName}
                          onChange={(e) => this.onFieldChange(e, 1)}
                          onBlur={() => {
                            this.setState({
                              FormData: {
                                ...this.state.FormData,
                                taskName: taskName.trimRight(),
                              },
                            });
                          }}
                        />
                      </OverlayTrigger>
                      {this.getFieldError("taskName", 1)}
                    </Col>
                  </Form.Group>

                  {this.props.locationList.length > 0 && <Form.Group
                    onBlur={() => this.validationOnClick(2)}
                    controlId="name"
                    className="d-flex location-dropdown"
                  >
                    <Col
                      sm={"12"}
                      className={`mb-0 ${
                        errors?.location && inputPosition >= 2
                          ? "validation-error"
                          : ""
                      }`}
                      //   onBlur={() => this.validationOnClick(3)}
                    >
                      <Form.Label>Location</Form.Label>

                      {this.props.locationList.length > 0 && <DropDownList
                            // tabIndex={2}
                            placeHolder={"Select a location"}
                            data={this.props.locationList}
                            defaultValue={location}
                            isSearchRequired={true}
                            label={"name"}
                            value={"id"}
                            placeHolderItem={{ id: -1, name: "Select a location" }}
                            onDropDownChange={(item) => {
                            if (!item) {
                                return;
                            }
                            this.onFieldChange(
                              Utils.BuildCustomEventObject("location", item.id),
                              2
                            )
                           
                            }}
                            selectedItem={[
                            ...[{ id: -1, name: "Select a location" }],
                            ...this.props.locationList,
                            ].find((r: any) => r?.id === location)}
                        />}
                     

                      {this.getFieldError("location", 2)}
                    </Col>
                  </Form.Group>}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
             
                className="btn-ghost"
                onClick={() => this.handleHideModal(false)}
              >
                Cancel
              </Button>
              <Button
              
                disabled={isFormNotValid || isDataSaving}
                className="btn btn-primary"
                onClick={this.SaveData}
              >
                Add Task
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}
