import React from "react";
import { Button, Spinner } from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip"; 
import PageLoader from "../../../../Common/Components/PageLoader";

export class SmileIdRightPanel extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      loaderTrue: false,
      tableData: [],
      rightPanelData: {},
    };
  }

  static getDerivedStateFromProps(props: any, state: any) {

    if (Object.keys(props.rightPanelRow).length === 0) {
      return {
        rightPanelData: props.parentState.tableData[0],
        tableData: props.parentState.tableData,
      };
    } else {
      return {
        rightPanelData: props.rightPanelRow,
        tableData: props.parentState.tableData,
      };
    }
  }

  actionButton = (e: any, type: string, row: any) => {
    e.stopPropagation();
    this.props.rightPanelAction(type, row);
  };


  render() {
    let loadShow: any;

    if (this.state.loaderTrue) {
      loadShow = <PageLoader />;
    }

    return (
      <>
        {loadShow}
        <div>
          {this.state.tableData.length > 0 &&
            this.state.rightPanelData.name !== "" && (
              <div>
                <div className="commit-header d-flex w-100 justifyBetween align-items-center">
                  <span className="smileIdQuestion d-flex align-items-center">
                    Is this
                    <EllipsisWithTooltip placement="bottom">
                      <span>{this.state.rightPanelData.employee}</span>
                    </EllipsisWithTooltip>
                    ?
                  </span>
                  <span className="action-group d-flex flex-row">
                    <Button
                      // disabled={this.state.rightPanelData.noButtonSpinner}
                      // className="btn-danger"
                      className={
                        this.state.rightPanelData.noButtonSpinner
                          ? "btn-danger p-none"
                          : "btn-danger"
                      }
                      onClick={(e: any) =>
                        this.actionButton(e, "No", this.state.rightPanelData)
                      }
                    >
                      {this.state.rightPanelData.noButtonSpinner && (
                        <Spinner
                          className="mr-2"
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      No
                    </Button>
                    <Button
                      className={
                        this.state.rightPanelData.noButtonSpinner
                          ? "p-none"
                          : ""
                      }
                      // disabled={this.state.rightPanelData.yesButtonSpinner}
                      onClick={(e: any) =>
                        this.actionButton(e, "Yes", this.state.rightPanelData)
                      }
                    >
                      {this.state.rightPanelData.yesButtonSpinner && (
                        <Spinner
                          className="mr-2"
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      Yes
                    </Button>
                  </span>
                </div>
                <div className="right-body">
                  <div className="smileIdEmpImg smileIdEmpImgLoader">
                    {this.props.userImg.imgPath === "" ? (
                      <div className="matrixSpinnerCenter">
                        <Spinner animation="border" variant="secondary" />
                      </div>
                    ) : (
                        <img src={this.props.userImg.imgPath} className={`${this.state.imgalt === "imgProfile" ? "img-fluid imgProfile" : "img-fluid"}`} alt={this.props.userImg.imgalt} />
                      )}
                    {/* <MyImage src={this.state.rightPanelRow[0].imageUrl} fallbackSrc={profile} alt="User SmileId" className="img-fluid d-none"></MyImage> */}

                  </div>

                  <div className="smileIdEmpImgCaption">
                    Punched <span>{this.state.rightPanelData.punchType}</span>{" "}
                    at{" "}
                    <span>
                      {this.state.rightPanelData.punchType === "In"
                        ? this.state.rightPanelData.inTime
                        : this.state.rightPanelData.outTime}
                    </span>{" "}
                    on <span>{this.state.rightPanelData.date}</span>
                  </div>
                </div>
              </div>
            )}
        </div>
      </>
    );
  }
}
