import * as React from 'react';
import { Dropdown } from "react-bootstrap";
import "./component.scss";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";


export class DropDownSelect extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            placeholder: props?.placeholder === undefined || props?.placeholder === null ? '' : props?.placeholder,
            isDisabled: props?.isDisabled === undefined || props?.isDisabled === null ? false : props?.isDisabled,
            setSelectValue: props?.selectedItem === undefined || props?.selectedItem === null ? '' : props?.selectedItem,
            isSearchable: props?.isSearchable === undefined || props?.isSearchable === null ? false : props?.isSearchable,
            listOption: props.itemList,
            listOptionCopy: props.itemList,
            searchInputValue: "",
        };
    }

    componentDidUpdate(prevProps, prevState) {
        // debugger;
        if (prevProps.itemList !== this.props.itemList) {
            this.setState({ listOption: this.props.itemList, listOptionCopy: this.props.itemList });
        }
        if (prevProps?.selectedItem !== this.props?.selectedItem) {
            let selectedItem = this.props?.selectedItem === undefined || this.props?.selectedItem === null ? '' : this.props?.selectedItem;
            this.setState({ setSelectValue: selectedItem });
        }
        if (prevProps?.isDisabled !== this.props?.isDisabled) {
            let isDisabled = this.props?.isDisabled === undefined || this.props?.isDisabled === null ? false : this.props?.selectedItem;
            this.setState({ isDisabled });
        }
    }

    onDropdownOpen = (event) => {
        // added to clean search box and update list 
        let listOptionCopy = Object.assign([], JSON.parse(JSON.stringify(this.state.listOptionCopy)));
        if (event && this.state.isSearchable) {
            this.setState({ listOption: listOptionCopy, searchInputValue: "" });
        }
    }

    handleSearch = (event) => {
        let value = event.target.value;
        let filteredList = this.state.listOptionCopy.filter((item: any) => {
            return item.name.toLowerCase().indexOf(value.toLowerCase()) > -1;
        });
        this.setState({ listOption: filteredList, searchInputValue: value });
    }

    render() {
        const selectValue = (item: any) => {
            // debugger;
            if(item.id === -1) {
                let obj = {id: -1, eventKey: "Select", name: "", label: ""}
                this.setState({ setSelectValue: "" }, () => {
                    this.props.onDropdownChange(obj, this.props.type);
                });
            }
            else {
                this.setState({ setSelectValue: item.name }, () => {
                    this.props.onDropdownChange(item, this.props.type);
                });
            }
        };
        return (
            <Dropdown className="drop-down-select" alignRight onToggle={(event: any) => this.onDropdownOpen(event)}>
                <Dropdown.Toggle id="dropdown-more" disabled={this.state.isDisabled}>
                    <div className='d-flex'>
                        {this.state.setSelectValue === "" &&
                            <div className='selected-val placeholder'><EllipsisWithTooltip placement="bottom">{this.state.placeholder}</EllipsisWithTooltip></div>
                        }
                        {this.state.setSelectValue !== "" &&
                            <div className='selected-val'><EllipsisWithTooltip placement="bottom">{this.state.setSelectValue}</EllipsisWithTooltip></div>
                        }
                        <div className='arrow ml-auto'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M10 12.8124L5 7.81242L6.16667 6.64575L10 10.4791L13.8333 6.64575L15 7.81242L10 12.8124Z" fill="#1D1E20" />
                            </svg>
                        </div>
                    </div>

                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {this.state.isSearchable && this.state.listOptionCopy?.length > 0 &&
                        <div className="search-sec">
                            <input
                                type="text"
                                className="form-control"
                                onChange={(e) => this.handleSearch(e)}
                                placeholder={'Search...'}
                                value={this.state.searchInputValue}
                            //ref={this.inputElement}
                            />
                        </div>
                    }
                    <div className='list-area'>
                        {/* {this.state.listOption?.length === 0 && (
                            <span>No data found</span>
                        )} */}
                        {
                        // this.state.listOption?.length > 0 &&
                            this.state.listOption.map(
                                (item: any, idx: any) => (
                                    <Dropdown.Item
                                        eventKey={item.eventKey}
                                        key={idx}
                                        onClick={() => selectValue(item)}
                                    >
                                        <EllipsisWithTooltip placement="bottom">{item.name}</EllipsisWithTooltip>
                                    </Dropdown.Item>
                                )
                            )}
                    </div>

                </Dropdown.Menu>
            </Dropdown>
        );
    }
}