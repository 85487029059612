import React from "react";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import Dropzone from "react-dropzone";
import { Utils } from "../../../Common/Utilis";
import moment from "moment";
import { UserManagement } from "../../../Common/Services/UserManagement";
import { toast, ToastContainer } from "react-toastify";
import Joi from "joi-browser";
import { EntityManagementService } from "../../../Common/Services/EntityManagementService";
import { Hotel } from "../../../Common/Services/Hotel";
import { DropDownList } from "../../Reports/common-components/dropdown-list";
import { IRoomTypeNew } from "../../../Common/Contracts/IRoomType";
import OutsideClickHandler from "react-outside-click-handler";
import Calendar from "react-calendar";

export class TakeRoomOffline extends React.Component<any, any> {
  private formSchema = Joi.object({
    startDate: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Start Date is required" };
        });
      }),
      endDateCheckBox: Joi.optional().allow(""),
      endDate:  Joi.optional().allow("")
  });

  state: any = {
    formData: {
      startDate: this.props?.roomRowEdit?.startDate || "",
      endDateCheckBox: this.props?.roomRowEdit?.endDate ? true : false,
      endDate: this.props?.roomRowEdit?.endDate || "",
    },
    errors: {},
    isDataSaving: false,
    isFormNotValid: true,
    inputPosition: 0,
    isEditButton: false,
    calenderShow: false,
    endcalenderShow: false,
  };

  hideModal = (isReload = false) => {
    const formData = {
      startDate: this.props?.roomRowEdit?.startDate || "",
      endDateCheckBox: this.props?.roomRowEdit?.endDate ? true : false,
      endDate: this.props?.roomRowEdit?.endDate || "",
    };
    this.props?.handleHideRoomModal(isReload);

    this.setState({ formData, isFormNotValid: true, isDataSaving: false });
  };

  onFieldChange = (event, inputPosition) => {
    debugger;
    const { formData } = this.state;
    let value = event.target.value;
    const fieldName = event.target.name;

    if ( fieldName ==="startDate" ||  fieldName ==="endDate") {
      const _formattedDate = Utils.AddSlashINInputDateFormat(
        value,
        formData[fieldName],
        false,
        4,  
        false
      );
      if (_formattedDate === "invalid") 
       {
        return;
      } else {
        value = _formattedDate;
      }
    }

    formData[fieldName] = value;
    this.setState({ formData }, () => {
      this.validationOnClick(inputPosition);
    });
  };

  onSave = () => {
    //Save
    this.hideModal(true);
  };

  validateConfigurationFormSchema = () => {
    const valid = Joi.validate(this.state.formData, this.formSchema, {
      abortEarly: false,
    });

    const newErrorObject: any = {};
    if (valid.error) {
      valid.error.details.forEach((err) => {
        newErrorObject[err.path.join(".")] = err.message;
      });
    }
    

    if(this.state.formData.endDateCheckBox && this.state.formData.endDate === "")
    {
        newErrorObject["endDate"] = "End Date is required";
    }
    else{
        if(newErrorObject["endDate"])
        {
            delete newErrorObject["endDate"];
        }  
    }

    if (this.props?.roomRowEdit?.uniqueNo > 0) {
      if (newErrorObject?.file) {
        delete newErrorObject.file;
      }
      if (newErrorObject?.fileName) {
        delete newErrorObject.fileName;
      }
    }
    if (Object.keys(newErrorObject).length === 0) {
      this.setState({ isFormNotValid: false });
    } else {
      this.setState({ isFormNotValid: true });
    }
    this.setState({ errors: newErrorObject });
    return newErrorObject;
  };

  getFieldError = (fieldName: string, fieldPosition: number) => {
    const { errors, inputPosition } = this.state;

    return (
      <>
        {Object.keys(errors).length > 0 && fieldPosition <= inputPosition && (
          <span className="validation-message">{errors[fieldName]}</span>
        )}
      </>
    );
  };
  calenderHide = () => {
    this.setState({ calenderShow: false });
  };

  calenderShow = () => {
    this.setState({ calenderShow: true });
  };

  endcalenderHide = () => {
    this.setState({ endcalenderShow: false });
  };

  endcalenderShow = () => {
    this.setState({ endcalenderShow: true });
  };

  validationOnClick = (inputPosition) => {
    this.setState({ inputPosition }, () =>
      this.validateConfigurationFormSchema()
    );
  };

  render() {
    const {
      formData: { startDate, endDateCheckBox, endDate },
      errors,
      isDataSaving,
      isFormNotValid,
      inputPosition,
      calenderShow,
      endcalenderShow
    } = this.state;

    const { showAddRoomModal } = this.props;
    return (
      <>
        <div className="">
          <ToastContainer containerId={"addDeduction"} autoClose={3000} />

          <Modal
            className="add-room-type-modal"
            show={showAddRoomModal}
            onHide={this.hideModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            // keyboard={this.props?.showDeductionModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>Take Room Offline</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-section">
                <div className="body-section room-offline-form">
                  <Form.Group
                    onBlur={() => this.validationOnClick(1)}
                    controlId="name"
                    className="d-flex"
                  >
                    <Col
                      sm={"12"}
                      className={`mb-0 ${
                        errors?.startDate && inputPosition >= 1
                          ? "validation-error"
                          : ""
                      }`}
                      // onClick={() => this.validationOnClick(1)}
                    >
                      <Form.Label>Start Date</Form.Label>
                      <div className="validate-input">
                      <div className="calender w-100">
                        <div
                          className={
                            startDate
                              ? "calender-display open"
                              : "calender-display"
                          }
                        >
                          <svg
                            onClick={this.calenderShow}
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z" />
                            <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z" />
                          </svg>
                          <input
                            // onFocus={this.calenderDateDisplay}
                            onClick={this.calenderShow}
                            onInput={(e) => {
                              let value = e.currentTarget.value;

                              value = value
                                ?.toString()
                                ?.replace(/[^0-9\/]+/gi, "");

                              this.onFieldChange(
                                Utils.BuildCustomEventObject(
                                  "startDate",
                                  value
                                ),
                                1
                              );
                            }}
                            type="text"
                            placeholder="MM/DD/YYYY"
                            maxLength={10}
                            name="startDate"
                            autoComplete="off"
                            value={startDate}
                            className="form-control single-date-pick"
                          />
                        </div>
                        </div>

                        {calenderShow && (
                          <div className="calender">
                            <OutsideClickHandler
                              onOutsideClick={this.calenderHide}
                            >
                              <Calendar
                                calendarType={"US"}
                                onChange={(date) => {
                                  const value = moment(date).format("MM/DD/YYYY");
                                  this.onFieldChange(
                                    Utils.BuildCustomEventObject(
                                      "startDate",
                                      value
                                    ),
                                    1
                                  );

                                  this.calenderHide()
                                }}
                                value={
                                  startDate
                                    ? moment(startDate)?.format("MM/DD/YYYY") ===
                                      "Invalid date"
                                      ? new Date()
                                      : new Date(startDate)
                                    : new Date()
                                }
                              />
                            </OutsideClickHandler>
                          </div>
                        )}
                      </div>

                      {this.getFieldError("startDate", 1)}
                    </Col>
                  </Form.Group>

                  <Form.Group
                    onBlur={() => this.validationOnClick(2)}
                    controlId="endDate"
                    className="d-flex mb-2"
                  >
                    
                     <Col
                      sm={"12"}
                      className={`mb-0 d-flex align-items-center mt-1 ${
                        errors?.endDate && inputPosition >= 3
                          ? "validation-error"
                          : ""
                      }`}
                    >
                      <Form.Check
                        type="checkbox"
                        checked={endDateCheckBox}
                        label=""
                        custom
                        onChange={(e: any) =>  this.onFieldChange(
                            Utils.BuildCustomEventObject(
                              "endDateCheckBox",
                              e.target.checked
                            ),
                            2
                          )}
                        />
                         <Form.Label>End Date</Form.Label>
                    </Col>
                     
                  </Form.Group>

                  <Form.Group
                    onBlur={() => this.validationOnClick(3)}
                    controlId="endDate"
                    className="d-flex"
                  >
                    <Col
                      sm={"12"}
                      className={`mb-0 ${
                        errors?.endDate && inputPosition >= 3
                          ? "validation-error"
                          : ""
                      }`}
                    >
                       

                      {endDateCheckBox && <>
                      <div className="validate-input d-flex flex-column pl-4">
                      <div className="calender w-100">
                        <div
                          className={
                            endDate
                              ? "calender-display open"
                              : "calender-display"
                          }
                        >
                          <svg
                            onClick={this.endcalenderShow}
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z" />
                            <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z" />
                          </svg>
                          <input
                            // onFocus={this.calenderDateDisplay}
                            onClick={this.endcalenderShow}
                            onInput={(e) => {
                              let value = e.currentTarget.value;

                              value = value
                                ?.toString()
                                ?.replace(/[^0-9\/]+/gi, "");

                              this.onFieldChange(
                                Utils.BuildCustomEventObject(
                                  "endDate",
                                  value
                                ),
                                3
                              );
                            }}
                            type="text"
                            placeholder="MM/DD/YYYY"
                            maxLength={10}
                            name="endDate"
                            autoComplete="off"
                            value={endDate}
                            className="form-control single-date-pick"
                          />
                        </div>
                        </div>

                        {endcalenderShow && (
                          <div className="calender">
                            <OutsideClickHandler
                              onOutsideClick={this.endcalenderHide}
                            >
                              <Calendar
                                calendarType={"US"}
                                onChange={(date) => {
                                  const value = moment(date).format("MM/DD/YYYY");
                                  this.onFieldChange(
                                    Utils.BuildCustomEventObject(
                                      "endDate",
                                      value
                                    ),
                                    3
                                  );

                                  this.endcalenderHide()
                                }}
                                value={
                                    endDate
                                    ? moment(endDate)?.format("MM/DD/YYYY") ===
                                      "Invalid date"
                                      ? new Date()
                                      : new Date(endDate)
                                    : new Date()
                                }
                              />
                            </OutsideClickHandler>
                          </div>
                        )}
                      </div>

                      <div className="pl-4">{this.getFieldError("endDate", 3)}</div>
                      </>}
                     
                    
                    </Col>
                  </Form.Group>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {/* <Button
                //   tabIndex={8}
                className="btn-ghost"
                onClick={() => this.hideModal(false)}
              >
                Cancel
              </Button> */}
              <Button
                //    tabIndex={9}
                disabled={isFormNotValid || isDataSaving}
                className="btn btn-primary"
                onClick={this.onSave}
              >
                Save
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}
