import Instense from "./Axios-config";
export class cashMgmtService {
    public static getPayrollImportLog = async (request: any): Promise<any[] | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.tenantID = tenantID;
        const url = `/CashManagement/GetPayrollImportLog`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data as any;
            return result;
        });
    };

    public static getPayrollImportRecord = async (request: any): Promise<any[] | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.tenantID = tenantID;
        const url = `/CashManagement/GetPayrollImportRecord`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data as any;
            return result;
        });
    };

    public static ImportPayrollSave = async (request: any, files: any[], onUploadProgress): Promise<any[] | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : storage.tenantID as any;
        request.tenantID = tenantID;
        const url = `/CashManagement/ImportPayrollSave`;
        const formData: FormData = new FormData();
        files.forEach(element => {
            formData.append('file', element);
        });

        formData.append('formField', JSON.stringify(request));
        return Instense.post(url, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
              },
              onUploadProgress, 
        }).then((response) => {
            let result = response.data.result as any;
            return result;
        });
    };

    public static deletePayrollData = async (request: any): Promise<any[] | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : storage.tenantID as any;
        request.tenantID = tenantID;
        const url = `/CashManagement/DeletePayrollData`;
        return Instense.post(url, request).then((response) => {
            let result = response.data.result as any;
            return result;
        });
    };
    public static payrollTenant = async (): Promise<any[] | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        let request : any ={};
        request.TenantID = tenantID;
        const url = `/CashManagement/AdditionalPayrollTenant`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data as any;
            return result;
        });
    };

    public static payrollHotel = async (request: any): Promise<any[] | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.TenantID = tenantID;
        const url = `/CashManagement/AdditionalHotelTenant`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data as any;
            return result;
        });
    };

    public static importAdditionalPayroll = async (request: any): Promise<any[] | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : storage.tenantID as any;
        request.TenantID = tenantID;
        const url = `/CashManagement/ImportAdditionalPayroll`;
        return Instense.post(url, request).then((response) => {
            let result = response.data.result as any;
            return result;
        });
    };

    // public static payrollTenant = async (): Promise<any[] | null> => {
    //     let storage = JSON.parse(localStorage.getItem("storage")!);
    //     let tenantID = storage === null ? 0 : (storage.tenantID as any);
    //     let request : any ={};
    //     request.TenantID = tenantID;
    //     const url = `/CashManagement/AdditionalPayrollTenant`;
    //     return Instense.get(url, { params: request }).then((response) => {
    //         let result = response.data as any;
    //         return result;
    //     });
    // };

    // public static payrollHotel = async (request: any): Promise<any[] | null> => {
    //     let storage = JSON.parse(localStorage.getItem("storage")!);
    //     let tenantID = storage === null ? 0 : (storage.tenantID as any);
    //     request.TenantID = tenantID;
    //     const url = `/CashManagement/AdditionalHotelTenant`;
    //     return Instense.get(url, { params: request }).then((response) => {
    //         let result = response.data as any;
    //         return result;
    //     });
    // };

    // public static importAdditionalPayroll = async (request: any): Promise<any[] | null> => {
    //     let storage = JSON.parse(localStorage.getItem("storage")!);
    //     let tenantID = storage === null ? 0 : storage.tenantID as any;
    //     request.TenantID = tenantID;
    //     const url = `/CashManagement/ImportAdditionalPayroll`;
    //     return Instense.post(url, request).then((response) => {
    //         let result = response.data.result as any;
    //         return result;
    //     });
    // };

}