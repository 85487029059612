import * as React from "react";
import { Dropdown } from "react-bootstrap";

export class PaymentRegisterSelector extends React.Component<any, any> {

  private inputElement: any;

  private reportTypes = [
    { label: "Payment Register #1", value: "1", description: "[Check total by vendor]" },
    { label: "Payment Register #2", value: "2", description: "[Check total by vendor, Invoice no.]" },
    { label: "Payment Register #3", value: "3", description: "[Check total by vendor, Invoice no. and COA ]" },
  ];

  constructor(props: any) {
    super(props);
    this.inputElement = React.createRef();
    this.state = {
      selectedItem: {},
      filterData: this.reportTypes,
      reportTypes: this.reportTypes
    };
  }

  componentDidUpdate(prevProps, prevState) {
    debugger;
    const { defaultValue } = this.props;
    const { filterData } = this.state;
    if (prevProps.defaultValue !== defaultValue) {
      let selectedItem = filterData?.filter(r => r.value === defaultValue);
      this.setState({ selectedItem: selectedItem[0], defaultValue }, () => {
        this.handleDropdownChange(selectedItem[0]);
      });
    }
  }

  componentDidMount = () => {
    debugger;
    const { defaultValue } = this.props;
    const { filterData } = this.state;
    if (defaultValue === undefined) {
      let selectedItem = { label: "Payment Register #1", value: "1", description: "[Check total by vendor]" };
      this.setState({ selectedItem }, () => {
        this.handleDropdownChange(selectedItem);
      })

    } else {
      let selectedItem = filterData?.filter(r => r.value === defaultValue);
      this.setState({ selectedItem: selectedItem[0], defaultValue }, () => {
        this.handleDropdownChange(selectedItem[0]);
      });
    }
  };


  handleDropdownChange = (item: any) => {
    this.setState({ selectedItem: item });
    this.props.onReportTypeChange(item);
    this.resetSerchBox();
  }

  resetSerchBox = () => {
    this.setState({ filterData: this.state.reportTypes });
  };

  render() {

    return (
      <Dropdown
        className="single-select" style={{ marginBottom: "10px" }}>
        <Dropdown.Toggle id="Modal-reportTypes">
          <span >
            {this.state.selectedItem.label}
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div className="list-area">
            {this.state.filterData.map((item: any, idx: any) => (

              <Dropdown.Item
                onClick={() => this.handleDropdownChange(item)}
              >
                {item.label}
              </Dropdown.Item>
            ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}
