import React from 'react'

const CustomLegends = ({datasets, isLine=false }) => {
  return (
    <div className="d-flex flex-row justify-content-end chart-indicators">
    {datasets.map((item) => (
      <div key={item.label} className='item'>
        <div
          style={{
            backgroundColor: item.backgroundColor,
            borderColor: item.backgroundColor,
            width: "8px",
            height: "8px",
            borderRadius: "4px",
          }}
          className={`${isLine ? "show-line" : "show-dot"} ${isLine && item.label.toLowerCase() !== "actual" ? "show-dotted":""}`}
           
        ></div>

        <span style={{ color: "#6A6E73", fontSize: 12 }}>{item.label}</span>
      </div>
    ))}
  </div>
  )
}

export default CustomLegends
