import * as React from "react";
import { Dropdown} from "react-bootstrap";
// import { read } from "fs";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";

export const DropDownList: React.FC<any> = (props: any) => {
  const inputElement: any = React.createRef();
  const toggleRef: any = React.createRef();

  const [placeHolder, setPlaceholder] = React.useState(props?.placeHolder);
  const [selectedItem, setSelectedItem] = React.useState(props?.selectedItem || {});
  const [data, setData] = React.useState(props?.data || []);
  const [filterData, setFilterData] = React.useState(props?.data || []);
  const [disabled, setdisabled] = React.useState(props?.disabled);
  const [inputValue, setInputValue] = React.useState("");

  //When dropdown data values changed depending on another dropdown
  React.useEffect(() => {
    if(props.dependent)
    {
      setData(props?.data || [])
      setFilterData(props?.data || [])
      setSelectedItem(props?.selectedItem ||{})
    }

  },[props.data, props.dependent])

  React.useEffect(() => {

    const { defaultValue, value, label, shouldToggle } = props;
    if(!selectedItem && props?.selectedItem) {
      handleDropdownChange(props?.selectedItem);
    }
    else if(selectedItem) {
      if (
        defaultValue &&
        selectedItem[value] !== defaultValue &&
        data.length > 0
      ) {
        const selectedItem = data?.find((r) => r[value] === defaultValue);
        handleDropdownChange(selectedItem);
      }
    } else {
      if(data?.length> 0 && defaultValue) {
        const selectedItem = data?.find((r) => r[value] === defaultValue);
        handleDropdownChange(selectedItem);
      }
    }


  });


  const handleDropdownSearch = (e: { target: { value: string } }) => {
    const { label } = props;
    const updatedList = data.filter(
      (item) =>
        item[label]
          ?.toString()
          .toLowerCase()
          .indexOf(e.target.value.toLowerCase()) > -1
    );
    setFilterData(updatedList);
    setInputValue(e.target.value);
  };

  const   hoverOutEllipsis = () => {
    $(".tooltip").removeClass("show");
  };
  const handleDropdownChange = (item: any) => {
    if(props?.placeHolderItem) {
      if(item) {
        if(props?.placeHolderItem[props?.value] ===item[props?.value]) {
          setSelectedItem(props?.placeHolderItem);
        } else {
          setSelectedItem(item);
        }
      } else {
        setSelectedItem(props?.placeHolderItem);
      }

    }else {
      setSelectedItem(item);
    }

    if (item&& typeof props?.onDropDownChange === "function" && item[props?.value] !== props?.defaultValue) {
      props?.onDropDownChange(item);
    }
    resetSerchBox();
  };

  const resetSerchBox = () => {
    setFilterData(data);
    setInputValue("");
  };

  const ddlSelectModal = (eventKey: any) => {
    setPlaceholder(eventKey);
  };

  const onTabKeyDown = (e) => {
    e.preventDefault();
    if (toggleRef.current.ariaExpanded === "false" && e?.keyCode === 9) {
      toggleRef.current.click();
      setTimeout(() => {
        $(`#ddlSpecailSearchText`)?.focus();
      }, 100);
    }
  };

  const onFocusInput = (eventKey: any) => {
    const {onFocus=()=>{}}= props
    onFocus()
    resetSerchBox();
    setTimeout(() => {
      inputElement?.current?.focus();
    }, 100);
  };

  const { label, value ,showBlueBg , tabIndex, style, isIcon,onBlur=(e:any)=>{} } = props;

  return (
    <Dropdown
      style={style}
      tabIndex = {tabIndex}
      className="single-select"
      onSelect={ddlSelectModal}
      onKeyUp={(e)=>onTabKeyDown(e)}
      onBlur={onBlur}
      onClick={onFocusInput} >

      <Dropdown.Toggle ref={toggleRef} id={`Modal-ddl${tabIndex}`} className={`${showBlueBg? "selected-blue-bg" :""} ${isIcon && selectedItem[value] <= 0 && "icon-add"}`} disabled={props.disabled}>
        
          {
              isIcon && selectedItem[value] <= 0 && <span className="search-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
                  <path d="M5.33333 11.4308C6.51665 11.4306 7.66587 11.0344 8.598 10.3055L11.5287 13.2362L12.4713 12.2935L9.54067 9.36283C10.27 8.4306 10.6664 7.28112 10.6667 6.09749C10.6667 3.15683 8.274 0.76416 5.33333 0.76416C2.39267 0.76416 0 3.15683 0 6.09749C0 9.03816 2.39267 11.4308 5.33333 11.4308ZM5.33333 2.09749C7.53933 2.09749 9.33333 3.89149 9.33333 6.09749C9.33333 8.30349 7.53933 10.0975 5.33333 10.0975C3.12733 10.0975 1.33333 8.30349 1.33333 6.09749C1.33333 3.89149 3.12733 2.09749 5.33333 2.09749Z" fill="#84888C"/>
                  </svg>
              </span>
          }
        <EllipsisWithTooltip placement="top">
          {selectedItem &&
            <span
              className={selectedItem[value] <= 0 ? "placeholder-grey" : "placeholder-black"}
              style={{
                width: "95%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "block",
                color: props.defaultTextColor && selectedItem[value] === 0 ? "gray" : "black"
              }}
            >
              {selectedItem[label]}
            </span>
          }
        </EllipsisWithTooltip>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {props?.isSearchRequired &&
          <div className="search-sec">
            <input
              id="ddlSpecailSearchText"
              type="text"
              className="form-control"
              onChange={handleDropdownSearch}
              placeholder={placeHolder}
              // autoComplete ={"ddlSpecailSearchText" + placeHolder}
              autoComplete="off"
              value={inputValue}
              ref={inputElement}
            />
          </div>
        }
        {filterData?.length > 0 && (
          <div className="list-area">
            {filterData.map((item: any, idx: any) => (
              <>
                <div className="list-item">

                  <Dropdown.Item onClick={() => handleDropdownChange(item)}
                    className={`${item.disabled ? "d-flex align-items-center disabled" : "d-flex align-items-center"}`}
                    disabled={item.disabled} onWheel={() => hoverOutEllipsis()}>
                    <EllipsisWithTooltip placement="top">{item[label]}</EllipsisWithTooltip>
                    {item.isLowSecurity == 1 && <div className={"ml-auto badge color-blue"}>Low Security</div>}
                  </Dropdown.Item>


                </div>
              </>
            ))}
          </div>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};
DropDownList.defaultProps = {
  isSearchRequired: true,
  showBlueBg: false,
  shouldToggle: false
};