import Instense from "./Axios-config";
import { IUserRequest } from "../Contracts/IUserRequest";
import { IUserList } from "../Contracts/IUserList";
import { IAddUserRequest } from "../Contracts/IAddUserRequest";
import { IUserRole } from "../Contracts/IUserRole";
import { IGeneralInfo } from "../Contracts/IGeneralInfo";
import { IUserExcelExportRequest } from "../Contracts/IUserExcelExportRequest";
import { ILaborSettings } from "../Contracts/ILaborSettings";
import { ILaborPTORequest } from "../Contracts/ILaborPTOCalculation";
import { ISavePTOUserDetailRequest } from "../Contracts/ISavePTOUserDetailRequest";
import { IPTOUserDetails } from "../Contracts/IPTOUserDetails";
import { ILaborPTOAdjBalance } from "../Contracts/ILaborPTOAdjBalance";
import { IUserACATrackingSaveRequest } from "../Contracts/IUserACATrackingSaveRequest";
import { IACATrackingFileUploadRequest } from "../Contracts/IACATrackingFileUploadRequest";
import {
  IUserChangeLogRequest,
  IUserChangeLogResponse,
} from "../Contracts/IUserChangeLog";
import { IUserDocTypeResponse } from "./../Contracts/IUserDocTypeResponse";
import { IUserDocumentRequest } from "./../Contracts/IUserDocumentRequest";
import { IUserDocumentUpload } from "./../Contracts/IUserDocumentUpload";
import { IGetTaskTypeGoalData } from "../Contracts/IGetTaskTypeGoalData";
import { ISalesActivityTypeMaster } from "./../Contracts/ISalesActivityTypeMaster";
import { IGetUserReviewSchedule } from "../Contracts/IGetUserReviewSchedule";
import { IUserReviewSchedule } from "../Contracts/IUserReviewSchedule";
import { IPermissionModal } from "./../Contracts/IPermissionModal";
import { IEnterPriseWisePayroll } from "./../Contracts/IEnterpriseWisePayroll";
import { IUserDeductionCriteria } from "../Contracts/IDeductionCriteria";
import { IUserGarnishment } from "../Contracts/IUserGarnishment";
import { IHotelWisePayRollRequest } from "../Contracts/IHotelWisePayRoll";
import { IUserPositionRegularRate } from "../Contracts/IUserPositionRegularRate";
import { IHotelWisePayrollExludeFPayrollRequest } from "../Contracts/IHotelWisePayrollExludeFPayroll";
import {
  IIncompleteUserRequest,
  IValidateUserEmailAndPhone,
} from "../Contracts/IIncompleteUserRequest";
import axios from "axios";
import { ISaveUserReviewRequest } from "../Contracts/ISaveUserReviewRequest";
import { IChangeEHIDAccessRequest } from "../Contracts/IChangeEHIDAccessRequest";
import { IPayrollAllowance } from "../Contracts/IPayrollAllowance";

export class UserManagement {
  private static ajaxRequestUserList: any = null;
  public static GetUserList = async (
    request: IUserRequest
  ): Promise<IUserList[]> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantId = tenantID;
    const url = `/UserManagement/GetUserList`;
    if (UserManagement.ajaxRequestUserList) {
      UserManagement.ajaxRequestUserList.cancel("Cancel");
    }
    UserManagement.ajaxRequestUserList = axios.CancelToken.source();
    return Instense.post(url, request, {
      cancelToken: UserManagement.ajaxRequestUserList.token,
    }).then((response) => {
      let result = response?.data.result as IUserList[];
      return result;
    });
  };

  public static SaveUserDetails = async (
    request: IAddUserRequest
  ): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    request.tenantID = storage === null ? 0 : storage.tenantID;
    request.createdBy = storage === null ? 0 : storage.user_UniqueID;
    const url = `/UserManagement/SaveUserDetails`;
    return Instense.post(url, request).then((response) => {
      let result = response?.data ;
      return result;
    });
  };

  public static GetUsername = (firstName, lastName): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let request: any = {};
    request.tenantId = storage === null ? 0 : storage.tenantID;
    request.firstName = firstName ? firstName : "";
    request.lastName = lastName ? lastName : "";
    const url = `/UserManagement/GetUsername`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response?.data?.result?.result;
      return result;
    });
  };

  public static GetUserStatusList = async (): Promise<string[]> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;

    const url = `/UserManagement/GetUserStatusList`;
    return Instense.get(url, { params: { tenantId: tenantID } }).then(
      (response) => {
        let result = response?.data?.result?.result as string[] || [];
        return result;
      }
    );
  };

  public static GetUserMissingStatusList = async (): Promise<string[]> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;

    const url = `/UserManagement/GetUserMissingStatusList`;
    return Instense.get(url, { params: { tenantId: tenantID } }).then(
      (response) => {
        let result = response?.data?.result?.result as string[] || [];
        return result;
      }
    );
  };

  public static GetPayTypeList = async (): Promise<any[]> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;

    const url = `/UserManagement/GetPayTypeList`;
    return Instense.get(url, { params: { tenantId: tenantID } }).then(
      (response) => {
        let result = response?.data?.result?.result as any[] || [];
        return result;
      }
    );
  };

  public static GetUserRoleList = async (): Promise<IUserRole[]> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;

    const url = `/UserManagement/GetUserRoleList`;
    return Instense.get(url, { params: { tenantId: tenantID } }).then(
      (response) => {
        let result = response?.data?.result?.result as IUserRole[] || [];
        return result;
      }
    );
  };

  public static GetUserGeneralInfo = async (
    userUniqueID: number,
    isNewUserAdded: boolean = false
  ): Promise<IGeneralInfo> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    let request = {} as any;
    request.tenantId = tenantID;
    request.user_UniqueId = userUniqueID;
    request.isNewUserAdded = isNewUserAdded;
    const url = `/UserManagement/GetUserGeneralInfo`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response?.data?.result?.result as IGeneralInfo;
      return result;
    });
  };

  public static GetUserLaborInfo = async (
    userUniqueID: number,
    isNewUserAdded: boolean = false
  ): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID);
    let request = {} as any;
    request.tenantId = tenantID;
    request.user_UniqueId = userUniqueID;
    request.isNewUserAdded = isNewUserAdded;
    const url = `/UserManagement/GetUserLaborInfo`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response?.data?.result?.result;
      return result;
    });
  };

  public static updateGeneralInfo = async (
    request: IGeneralInfo
  ): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantID;
    const url = `/UserManagement/UpdateGeneralInfo`;
    return Instense.post(url, request).then((response) => {
      let result = response?.data;
      return result;
    });
  };

  public static SendOTP = async (
    Uniqueno: any,
    mode: any,
    email: any,
    phone: any
  ) => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    let request = {} as any;
    request.tenantId = tenantID;
    request.Uniqueno = Uniqueno;
    request.mode = mode;
    request.email = email;
    request.phone = phone;
    const url = `/UserManagement/SendOTP`;
    return Instense.post(url, request).then((response) => {
      let result = response?.data;
      return result;
    });
  };

  public static ValidateOTP = async (
    Uniqueno: any,
    otp: any,
    email: any,
    phone: any,
    shouldupdate: any = true
  ) => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    let request = {} as any;
    request.tenantId = tenantID;
    request.otp = otp;
    request.Uniqueno = Uniqueno;
    request.email = email;
    request.phone = phone;
    request.shouldupdate = shouldupdate;
    const url = `/UserManagement/ValidateOTP`;
    return Instense.post(url, request).then((response) => {
      let result = response?.data;
      return result;
    });
  };

  public static downloadUsersSheet = async (
    request: IUserExcelExportRequest,
    fileName: string
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantId = tenantID;

    const url = `/UserManagement/DownloadUserList`;
    return Instense.post(url, request, { responseType: "blob" }).then(
      (response: any) => {
        let csvURL = window.URL.createObjectURL(response?.data);
        let tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", fileName + ".xlsx");
        tempLink.click();
      }
    );
  };

  public static DownloadUserDocument = async (
    uniqueno: number,
    filename: string,
    typeID: number
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {};
    request.tenantId = tenantID;
    request.uniqueno = uniqueno;
    request.filename = filename;
    request.typeID = typeID;

    const url = `/UserManagement/DownloadUserDocument`;
    return Instense.post(url, null, {
      params: request,
      responseType: "blob",
    }).then((response: any) => {
      let csvURL = window.URL.createObjectURL(response?.data);
      let tempLink = document.createElement("a");
      tempLink.href = csvURL;
      tempLink.setAttribute("download", filename.replace(/,/g, ""));
      tempLink.click();
    });
  };

  public static SaveLaborSettingAndPayHours = async (
    request: ILaborSettings
  ): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    request.tenantId = storage === null ? 0 : storage.tenantID;
    request.loginUser = storage === null ? 0 : storage.userName;

    const url = `/UserManagement/SaveLaborSettingAndPayHours`;
    return Instense.post(url, request).then((response) => {
      let result = response?.data;
      return result;
    });
  };

  public static SavePTOAdjustment = async (
    request: ILaborPTORequest
  ): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    request.tenantId = storage === null ? 0 : storage.tenantID;
    request.loginUser = storage === null ? 0 : storage.userName;

    const requestArray: ILaborPTORequest[] = [];
    requestArray.push(request);

    const url = `/UserManagement/SavePTOAdjustment`;
    return Instense.post(url, requestArray).then((response) => {
      let result = response?.data;
      return result;
    });
  };

  public static SavePTOUserDetails = async (
    request: ISavePTOUserDetailRequest
  ): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    request.tenantId = storage === null ? 0 : storage.tenantID;
    request.logInUser = storage === null ? 0 : storage.userName;

    const url = `/UserManagement/SavePTOUserDetails`;
    return Instense.post(url, null, { params: request }).then((response) => {
      let result = response?.data;
      return result;
    });
  };

  public static UpdateBulkPTOPoliciesStatus = async (
    userName,
    isNewUserAdded,
    ptoStatus
  ): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    const request: any = {};
    request.tenantId = storage === null ? 0 : storage.tenantID;
    request.userName = userName;
    request.ptoStatus = ptoStatus;
    request.isNewUserAdded = isNewUserAdded;
    const url = `/UserManagement/UpdateBulkPTOPoliciesStatus`;
    return Instense.post(url, null, { params: request }).then((response) => {
      let result = response?.data;
      return result;
    });
  };

  public static GetPTOUserList = async (
    username: string
  ): Promise<IPTOUserDetails[]> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {} as any;
    request.tenantId = tenantID;
    request.username = username;
    const url = `/UserManagement/GetPTOUserList`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response?.data?.result?.result as IPTOUserDetails[];
      return result;
    });
  };

  public static GetPTOAdjustedDetails = async (
    ptoType: string,
    username: string
  ): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {};
    request.tenantId = tenantID;
    request.username = username;
    request.ptoType = ptoType;

    const url = `/UserManagement/GetPTOAdjustedDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response?.data?.result?.result as ILaborPTOAdjBalance[];
      return result;
    });
  };

  public static GetPTOName = async (
    status: string,
    username: string
  ): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {};
    request.tenantId = tenantID;
    request.status = status;
    request.username = username;
    const url = `/UserManagement/GetPTOName`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response?.data?.result?.result;
      return result;
    });
  };

  public static GetACATracking = async (userUniqueno: number): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {};
    request.tenantID = tenantID;
    request.userUniqueno = userUniqueno;
    const url = `/UserManagement/GetACATracking`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response?.data?.result?.result;
      return result;
    });
  };

  public static GetACATrackingUpload = async (
    uniqueno: number,
    userUniqueNo: number
  ): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    const request: any = {};
    request.tenantId = storage === null ? 0 : storage.tenantID;
    request.uniqueno = uniqueno;
    request.useruniqueno = userUniqueNo;
    const url = `/UserManagement/GetACATrackingUpload`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response?.data?.result?.result;
      return result;
    });
  };

  public static SaveACATracking = async (
    request: IUserACATrackingSaveRequest
  ): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    request.tenantId = storage === null ? 0 : storage.tenantID;
    request.loginUser = storage === null ? 0 : storage.userName;
    const url = `/UserManagement/SaveACATracking`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response?.data;
      return result;
    });
  };
  public static InsertACAFileComment = async (
    fileUniqueNo: number,
    notes: string
  ): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    const request: any = {};
    request.tenantId = storage === null ? 0 : storage.tenantID;
    request.loginUser = storage === null ? 0 : storage.userName;
    request.fileUniqueNo = fileUniqueNo;
    request.notes = notes;
    const url = `/UserManagement/InsertACAFileComment`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response?.data;
      return result;
    });
  };

  public static SaveACATrackingFile = async (
    formField: IACATrackingFileUploadRequest,
    files: any
  ): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    formField.TenantId = storage === null ? 0 : storage.tenantID;
    let formData = new FormData();
    formData.append("formField", JSON.stringify(formField));
    for (let i = 0; i < files.length; i++) {
      formData.append(`file[${i}]`, files[i], files[i].name);
    }

    const url = `/UserManagement/SaveACATrackingFile`;
    return Instense.post(url, formData).then((response) => {
      let result = response?.data;
      return result;
    });
  };

  public static GetACAFileComment = async (
    fileUniqueNo: number
  ): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {};
    request.tenantId = tenantID;
    request.fileUniqueNo = fileUniqueNo;
    const url = `/UserManagement/GetACAFileComment`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response?.data?.result?.result;
      return result;
    });
  };

  public static DeleteACAFile = async (fileCode: number): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {};
    request.tenantId = tenantID;
    request.fileCode = fileCode;
    const url = `/UserManagement/DeleteACAFile`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response?.data;
      return result;
    });
  };

  public static GetLaborPayHours = async (uniqueno: number): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {};
    request.tenantId = tenantID;
    request.uniqueNo = uniqueno;
    const url = `/UserManagement/GetLaborPayHours`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response?.data?.result?.result;
      return result;
    });
  };

  public static GetPayrollIntegrationHidsByTenant = async (): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {};
    request.tenantId = tenantID;
    const url = `/UserManagement/GetPayrollIntegrationHidsByTenant`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response?.data?.result;
      return result;
    });
  };
  public static UpdateUserRole = async (request: any): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantID;
    const url = `/UserManagement/UpdateUserRole`;
    return Instense.post(url, request).then((response) => {
      let result = response?.data;
      return result;
    });
  };

  public static UpdateUserStatus = async (request: any): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantID;
    const url = `/UserManagement/UpdateUserStatus`;
    return Instense.post(url, request).then((response) => {
      let result = response?.data;
      return result;
    });
  };

  public static ResetPasswordNotEmailed = async (
    request: any
  ): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantId = tenantID;
    const url = `/UserManagement/ResetPasswordNotEmailed`;
    return Instense.post(url, request).then((response) => {
      let result = response?.data;
      return result;
    });
  };

  public static sendWelcomeEmail = async (request: any): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantId = tenantID;
    const url = `/UserManagement/sendWelcomeEmail`;
    return Instense.post(url, request).then((response) => {
      let result = response?.data;
      return result;
    });
  };

  public static GetUsersChangeLogData = async (
    request: IUserChangeLogRequest
  ): Promise<IUserChangeLogResponse[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    let userUniqueID = storage === null ? 0 : storage.user_UniqueID;
    request.tenantId = tenantID;
    request.parentUserUniqueId = request.isViewAll ? userUniqueID : 0;
    const url = `/UserManagement/GetUsersChangeLogData`;
    return Instense.post(url, request).then((response) => {
      return response?.data?.result?.result as IUserChangeLogResponse[];
    });
  };

  public static DownloadChangeLog = async (
    request: IUserChangeLogRequest,
    fileName: string
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    let userUniqueID = storage === null ? 0 : storage.user_UniqueID;
    request.tenantId = tenantID;
    request.parentUserUniqueId = request.isViewAll ? userUniqueID : 0;
    const url = `/UserManagement/DownloadChangeLog`;
    return Instense.post(url, request, { responseType: "blob" }).then(
      (response: any) => {
        let csvURL = window.URL.createObjectURL(response?.data);
        let tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", fileName + ".xlsx");
        tempLink.click();
      }
    );
  };

  public static GetUserDocumentType = async (): Promise<
    IUserDocTypeResponse[] | null
  > => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    let rolId = storage === null ? 0 : storage.rolId;
    const request: any = {};
    request.tenantId = tenantID;
    request.role = rolId;
    const url = `/UserManagement/GetUserDocumentType`;
    return Instense.get(url, { params: request }).then((response) => {
      return response?.data?.result?.result as IUserDocTypeResponse[];
    });
  };

  public static GetUserDocumentDetails = async (
    userUniqueno
  ): Promise<IUserDocumentUpload[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;

    const request: any = {};
    request.tenantId = tenantID;
    request.userUniqueno = userUniqueno;
    const url = `/UserManagement/GetUserDocumentDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      return response?.data?.result?.result as IUserDocumentUpload[];
    });
  };

  public static SaveUserUploadDoc = async (
    formField: IUserDocumentRequest,
    file: any
  ): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    formField.TenantID = storage === null ? 0 : storage.tenantID;
    formField.LoginUser = storage === null ? "" : storage.userName;
    let formData = new FormData();
    formData.append("formField", JSON.stringify(formField));
    formData.append(`file`, file, file?.name);

    const url = `/UserManagement/SaveUserUploadDoc`;
    return Instense.post(url, formData).then((response) => {
      let result = response?.data;
      return result;
    });
  };

  public static AddEditDocumentType = async (
    typeName,
    typeId,
    docEntryType
  ): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;

    const request: any = {};
    request.tenantID = tenantID;
    request.typeName = typeName;
    request.typeId = typeId;
    request.docEntryType = docEntryType;
    const url = `/UserManagement/SaveUserDocumentType`;
    return Instense.post(url, null, { params: request }).then((response) => {
      return response?.data;
    });
  };

  public static DeleteUserDocument = async (
    ifRecord,
    delType
  ): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;

    const request: any = {};
    request.tenantID = tenantID;
    request.ifRecord = ifRecord;
    request.delType = delType;
    const url = `/UserManagement/DeleteUserDocument`;
    return Instense.get(url, { params: request }).then((response) => {
      return response?.data;
    });
  };

  public static GetTaskTypeGoalData = async (
    userName
  ): Promise<IGetTaskTypeGoalData[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {};
    request.tenantID = tenantID;
    request.userName = userName;
    const url = `/UserManagement/GetTaskTypeGoalData`;
    return Instense.get(url, { params: request }).then((response) => {
      return response?.data?.result?.result as IGetTaskTypeGoalData[];
    });
  };

  public static GetSalesActivityTypeMaster = async (): Promise<
    ISalesActivityTypeMaster[] | null
  > => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {};
    request.tenantID = tenantID;
    const url = `/UserManagement/GetSalesActivityTypeMaster`;
    return Instense.get(url, { params: request }).then((response) => {
      return response?.data?.result?.result as ISalesActivityTypeMaster[];
    });
  };

  public static SaveUserGoal = async (
    userName,
    taskTypeId,
    dayNo,
    weekNo,
    rowNumber
  ): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {};
    request.tenantID = tenantID;
    request.userName = userName;
    request.taskTypeId = taskTypeId;
    request.dayNo = dayNo;
    request.weekNo = weekNo;
    request.rowNumber = rowNumber;
    const url = `/UserManagement/SaveUserGoal`;
    return Instense.post(url, null, { params: request }).then((response) => {
      return response?.data;
    });
  };

  public static UpdateToggle = async (
    userName,
    isSalesAgent,
    allowPTO,
    allowACATracking,
    allowPerformance,
    isAllowDeposit: number | null = null
  ): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {};
    request.tenantId = tenantID;
    request.userName = userName;
    request.isSalesAgent = isSalesAgent;
    request.allowPTO = allowPTO;
    request.allowACATracking = allowACATracking;
    request.allowPerformance = allowPerformance;
    request.allowDeposit = isAllowDeposit;
    //debugger;
    const url = `/UserManagement/UpdateToggle`;
    return Instense.get(url, { params: request }).then((response) => {
      return response?.data;
    });
  };

  public static GetUserReviewSchedule = async (
    userUniqueno,
    status
  ): Promise<IGetUserReviewSchedule[]> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    const request: any = {};
    request.tenantId = storage === null ? 0 : storage.tenantID;
    request.userUniqueno = userUniqueno;
    const url = `/UserManagement/GetUserReviewSchedule`;
    return Instense.get(url, { params: request }).then((response) => {
      return response?.data?.result?.result as IGetUserReviewSchedule[];
    });
  };

  public static GetTenantList = async (): Promise<any> => {
    const url = `/UserManagement/Get_AllActiveTenantDetailsList`;
    return Instense.get(url).then((response) => {
      return response?.data;
    });
  };

  public static ImportNewUsers = async (
    formField: IUserReviewSchedule,
    file: any,
    tenantID
  ): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    // formField.TenantId =  storage === null ? 0 : storage.tenantID;
    formField.Createdby = storage === null ? "" : storage.userName;
    let formData = new FormData();
    formData.append("formField", JSON.stringify(formField));
    if (file && file?.name) formData.append(`file`, file, file?.name);

    const url = `/UserManagement/ImportNewUsers?TenantID=${tenantID}`;
    return Instense.post(url, formData, {
      headers: {
        ImportUser: 1,
      },
    }).then((response) => {
      let result = response?.data;
      return result;
    });
  };

  public static SaveUserReviewSchedule = async (
    formField: IUserReviewSchedule,
    file: any
  ): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    formField.TenantId = storage === null ? 0 : storage.tenantID;
    formField.Createdby = storage === null ? "" : storage.userName;
    let formData = new FormData();
    formData.append("formField", JSON.stringify(formField));
    if (file && file?.name) formData.append(`file`, file, file?.name);

    const url = `/UserManagement/SaveUserReviewSchedule`;
    return Instense.post(url, formData).then((response) => {
      let result = response?.data;
      return result;
    });
  };

  public static GetUMPermissions = async (): Promise<IPermissionModal[]> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    const request: any = {};
    request.tenantID = storage === null ? 0 : storage.tenantID;
    request.userName = storage === null ? 0 : storage.userName;
    const url = `/UserManagement/GetUMPermissions`;
    return Instense.get(url, { params: request }).then((response) => {
      return response?.data?.result?.result as IPermissionModal[];
    });
  };

  public static DeleteUserGoal = async (username, taskTypeID): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    const request: any = {};
    request.tenantID = storage === null ? 0 : storage.tenantID;
    request.username = username;
    request.taskTypeID = taskTypeID;
    const url = `/UserManagement/DeleteUserGoal`;
    return Instense.get(url, { params: request }).then((response) => {
      return response?.data;
    });
  };

  public static DeletePayrollSpecialRate = async (uniqueno): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    const request: any = {};
    request.tenantid = storage === null ? 0 : storage.tenantID;
    request.createdBy = storage === null ? 0 : storage.userName;
    request.uniqueno = uniqueno;
    const url = `/UserManagement/DeletePayrollSpecialRate`;
    return Instense.get(url, { params: request }).then((response) => {
      return response?.data;
    });
  };

  public static GetReviewStartDate = async (username): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    const request: any = {};
    request.tenantid = storage === null ? 0 : storage.tenantID;
    request.username = username;
    const url = `/UserManagement/GetReviewStartDate`;
    return Instense.get(url, { params: request }).then((response) => {
      return response?.data;
    });
  };

  public static GetEnterPriseWisePayroll = async (
    userUniqueno = 0,
    page = "",
    isNewUserAdded: boolean = false
  ): Promise<IEnterPriseWisePayroll> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    const request: any = {};
    request.tenantId = storage === null ? 0 : storage.tenantID;
    request.userUniqueno = userUniqueno;
    request.page = page;
    request.isNewUserAdded = isNewUserAdded;
    const url = `/UserManagement/GetEnterPriseWisePayroll`;
    return Instense.get(url, { params: request }).then((response) => {
      return (response?.data?.result || {}) as IEnterPriseWisePayroll;
    });
  };

  public static SaveEnterpriseWiseSetting = async (
    request: IEnterPriseWisePayroll
  ): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    request.tenantId = storage === null ? 0 : storage.tenantID;
    const url = `/UserManagement/SaveEnterpriseWiseSetting`;
    return Instense.post(url, request).then((response) => {
      return response?.data;
    });
  };

  public static GetAllDeductionCriteria = async (): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    const request: any = {};
    request.tenantID = storage === null ? 0 : storage.tenantID;
    request.uniqueno = 0;
    const url = `/UserManagement/GetAllDeductionCriteria`;
    return Instense.get(url, { params: request }).then((response) => {
      return (response?.data?.result || {}) ;
    });
  };

  public static GetUserDeduction = async (
    userUniqueId: string
  ): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    const request: any = {};
    request.tenantID = storage === null ? 0 : storage.tenantID;
    request.userUniqueId = userUniqueId;
    const url = `/UserManagement/GetUserDeduction`;
    return Instense.get(url, { params: request }).then((response) => {
      return (response?.data?.result || {});
    });
  };

  public static GetUserGarnishment = async (
    userUniqueNo: number
  ): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    const request: any = {};
    request.tenantId = storage === null ? 0 : storage.tenantID;
    request.userUniqueNo = userUniqueNo;
    const url = `/UserManagement/GetUserGarnishment`;
    return Instense.get(url, { params: request }).then((response) => {
      return (response?.data?.result || {});
    });
  };

  public static DownloadGranismentDocument = async (
    uniqueno: number,
    filename: string
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {};
    request.tenantId = tenantID;
    request.uniqueno = uniqueno;
    request.filename = filename;
    const url = `/UserManagement/DownloadGranismentDocument`;
    return Instense.post(url, null, {
      params: request,
      responseType: "blob",
    }).then((response: any) => {
      let csvURL = window.URL.createObjectURL(response?.data);
      let tempLink = document.createElement("a");
      tempLink.href = csvURL;
      tempLink.setAttribute("download", filename.replace(/,/g, ""));
      tempLink.click();
    });
  };

  public static DeleteUserGranishment = async (
    uniqueno: number,
    userUniqueNo: number
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {};
    request.tenantId = tenantID;
    request.uniqueno = uniqueno;
    request.userUniqueNo = userUniqueNo;
    const url = `/UserManagement/DeleteUserGranishment`;
    return Instense.get(url, { params: request }).then((response) => {
      return response?.data;
    });
  };
  public static DeleteDeduction = async (
    iF_RecordId: number,
    username: number
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {};
    request.tenantId = tenantID;
    request.username = username;
    request.iF_RecordId = iF_RecordId;
    const url = `/UserManagement/DeleteDeduction`;
    return Instense.get(url, { params: request }).then((response) => {
      return response?.data;
    });
  };

  public static DeleteDirectDepositAcc = async (
    userUniqueNo: number,
    srNo: number
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {};
    request.tenantId = tenantID;
    request.userUniqueNo = userUniqueNo;
    request.srNo = srNo;
    const url = `/UserManagement/DeleteDirectDepositAcc`;
    return Instense.get(url, { params: request }).then((response) => {
      return response?.data;
    });
  };

  public static SaveUserDeductionCriteria = async (
    request: IUserDeductionCriteria
  ): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    request.tenantID = storage === null ? 0 : storage.tenantID;
    request.createdBy = storage === null ? 0 : storage.userName;
    const url = `/UserManagement/SaveUserDeductionCriteria`;
    return Instense.post(url, request).then((response) => {
      return response?.data;
    });
  };

  public static SaveGarnishment = async (
    formField: IUserGarnishment,
    file: any = {}
  ): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    formField.tenantID = storage === null ? 0 : storage.tenantID;
    formField.createdBy = storage === null ? "" : storage.userName;
    let formData = new FormData();
    formData.append("formField", JSON.stringify(formField));
    if (Object.keys(file).length > 0) {
      formData.append(`file`, file, file?.name);
    }

    const url = `/UserManagement/SaveGarnishment`;
    return Instense.post(url, formData).then((response) => {
      let result = response?.data;
      return result;
    });
  };

  public static GetActiveHotelWisePayroll = async (
    userUniqueNo: number
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {};
    request.tenantId = tenantID;
    request.userUniqueNo = userUniqueNo;
    const url = `/UserManagement/GetActiveHotelWisePayroll`;
    return Instense.get(url, { params: request }).then((response) => {
      return response?.data;
    });
  };

  public static GetHotelWisePayroll = async (
    userUniqueNo: number,
    isNewUserAdded: boolean = false
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {};
    request.tenantid = tenantID;
    request.useruniqueno = userUniqueNo;
    request.isNewUserAdded = isNewUserAdded;
    const url = `/UserManagement/GetHotelWisePayroll`;
    return Instense.get(url, { params: request }).then((response) => {
      return response?.data?.result;
    });
  };

  public static SaveHotelPayrollDetails = async (
    request: IHotelWisePayRollRequest[]
  ): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    request.forEach((x) => {
      x.tenantId = storage === null ? 0 : storage.tenantID;
      x.changedBy = storage === null ? 0 : storage.userName;
    });
    

    const url = `/UserManagement/SaveHotelPayrollDetails`;
    return Instense.post(url, request).then((response) => {
      return response?.data;
    });
  };

  public static SaveSpecialRates = async (
    request: IUserPositionRegularRate
  ): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    request.tenantID = storage === null ? 0 : storage.tenantID;
    request.createdBy = storage === null ? 0 : storage.userName;
    const url = `/UserManagement/SaveSpecialRates`;
    return Instense.post(url, request).then((response) => {
      return response?.data;
    });
  };

  public static GetUserSpecialRates = async (
    userUniqueNo: number,
    hid: number
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {};
    request.tenantid = tenantID;
    request.useruniqueno = userUniqueNo;
    request.hid = hid;
    request.isNewUserAdded = false;
    const url = `/UserManagement/GetUserSpecialRates`;
    return Instense.get(url, { params: request }).then((response) => {
      return response?.data?.result;
    });
  };

  public static GetUserSpecialRatesAllHid = async (
    userUniqueNo: number
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {};
    request.tenantid = tenantID;
    request.useruniqueno = userUniqueNo;
    request.isNewUserAdded = false;
    const url = `/UserManagement/GetUserSpecialRatesAllHid`;
    return Instense.post(url, null, { params: request }).then((response) => {
      return response?.data?.result;
    });
  };

  public static GetHotelWisePayrollForExcludeFromPayroll = async (
    userUniqueNo: number,
    isNewUserAdded: boolean = false
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {};
    request.tenantid = tenantID;
    request.useruniqueno = userUniqueNo;
    request.isNewUserAdded = isNewUserAdded;
    const url = `/UserManagement/GetHotelWisePayrollForExcludeFromPayroll`;
    return Instense.get(url, { params: request }).then((response) => {
      return response?.data?.result;
    });
  };

  public static SaveHotelWisePayrollForExludeFromPayroll = async (
    request: IHotelWisePayrollExludeFPayrollRequest
  ): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    request.tenantID = storage === null ? 0 : storage.tenantID;
    request.createdBy = storage === null ? 0 : storage.userName;
    const url = `/UserManagement/SaveHotelWisePayrollForExludeFromPayroll`;
    return Instense.post(url, request).then((response) => {
      return response?.data;
    });
  };

  public static CheckIfPositionIsAssignable = async (
    positionId: number,
    hid: number
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {};
    request.positionId = positionId;
    request.tenantid = tenantID;
    request.hid = hid;
    const url = `/UserManagement/CheckIfPositionIsAssignable`;
    return Instense.get(url, { params: request }).then((response) => {
      return (response?.data || {});
    });
  };
  public static GetHotelWithAllowances = async (
    useruniqueno
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {};
    request.tenantid = tenantID;
    request.useruniqueno = useruniqueno;
    const url = `/UserManagement/GetHotelWithAllowances`;
    return Instense.get(url, { params: request }).then((response) => {
      return response?.data?.result;
    });
  };

  public static GetAllPositionlist = async (): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {};
    request.tenantid = tenantID;
    const url = `/Position/GetAllPositionlist`;
    return Instense.get(url, { params: request }).then((response) => {
      return response?.data?.result;
    });
  };

  public static GetAllPositionlistbyHid = async (): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {};
    request.tenantid = tenantID;
    const url = `/Position/GetAllActivePositionlistbyHid`;
    return Instense.get(url, { params: request }).then((response) => {
      return response?.data?.result;
    });
  };

  public static GetUserStateFillStatus = async (
    hid,
    state
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {};
    request.hid = hid;
    request.state = state;
    request.tenantID = tenantID;
    const url = `/UserManagement/GetUserStateFillStatus`;
    return Instense.get(url, { params: request }).then((response) => {
      return response?.data?.result;
    });
  };

  public static InactivateHotelWisePayroll = async (
    request: any[]
  ): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    request = request.map((x) => {
      x.tenantid = storage === null ? 0 : storage.tenantID;
      return x;
    });

    const url = `/UserManagement/InactivateHotelWisePayroll`;
    return Instense.post(url, request).then((response) => {
      return response?.data;
    });
  };

  public static GetAllUsernameByTenantId = async (): Promise<any[]> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    const request: any = {};
    request.tenantId = storage === null ? 0 : storage.tenantID;
    const url = `/UserManagement/GetAllUsernameByTenantId`;
    return Instense.get(url, { params: request }).then((response) => {
      return response?.data;
    });
  };

  public static GetClientSetup = async (): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {};
    request.tenantID = tenantID;
    const url = `/UserManagement/GetClientSetup`;
    return Instense.get(url, { params: request }).then((response) => {
      return (response?.data || {});
    });
  };

  public static SaveIncompleteUser = async (
    request: IIncompleteUserRequest
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantId = tenantID;
    const url = `/UserManagement/SaveIncompleteUser`;
    const response = await Instense.post(url, request);
    let result = response?.data;
    return result;
  };

  public static ValidateIncompleteUser = async (
    request: IValidateUserEmailAndPhone
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantId = tenantID;
    const url = `/UserManagement/ValidateIncompleteUser`;
    const response = await Instense.post(url, request);
    let result = response?.data;
    return result;
  };

  public static GetModuleWithHids = async (): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantId = storage === null ? 0 : storage.tenantID;
    const request: any = {};
    request.tenantId = tenantId;
    const url = `/UserManagement/GetModuleWithHids`;
    return Instense.get(url, { params: request }).then((response) => {
      return (response?.data || {});
    });
  };

  public static RemoveOptOut = async (phone): Promise<any | null> => {
    const request: any = {};
    request.phone = phone;
    const url = `/UserManagement/RemoveOptOut`;
    return Instense.get(url, { params: request }).then((response) => {
      return (response?.data || {});
    });
  };

  public static DownloadACAFile = async (
    fileUniqueNo: number,
    fileName: string
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {};
    request.tenantId = tenantID;
    request.fileUniqueNo = fileUniqueNo;
    request.fileName = fileName;
    const url = `/UserManagement/DownloadACAFile`;
    return Instense.post(url, null, {
      params: request,
      responseType: "blob",
    }).then((response: any) => {
      //debugger;
      let csvURL = window.URL.createObjectURL(response?.data);
      let tempLink = document.createElement("a");
      tempLink.href = csvURL;
      tempLink.setAttribute(
        "download",
        fileName?.toString()?.replace(/,/g, "")
      );
      tempLink.click();
    });
  };

  public static SaveUserPerformance = async (
    request: ISaveUserReviewRequest
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    request.tenantID = storage === null ? 0 : storage.tenantID;
    request.logInUser = storage === null ? 0 : storage.userName;
    const url = `/UserManagement/SaveUserPerformance`;
    const response = await Instense.post(url, request);
    let result = response?.data;
    return result;
  };

  public static GetUserReviewDetails = async (
    scheduleId: number,
    userUniqueno: number
  ): Promise<any | null> => {
    const request: any = {};
    request.scheduleId = scheduleId;
    request.userUniqueno = userUniqueno;
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantId = tenantID;
    const url = `/UserManagement/GetUserReviewDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      return (response?.data || []);
    });
  };

  public static UpdatePerformanceReviewSetting = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantId = tenantID;
    const url = `/UserManagement/UpdatePerformanceReviewSetting`;
    return Instense.get(url, { params: request }).then((response) => {
      return (response?.data || []);
    });
  };

  public static ChangeEHIDAccess = async (
    request: IChangeEHIDAccessRequest
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    request.tenantId = storage === null ? 0 : storage.tenantID;

    const url = `/UserManagement/ChangeEHIDAccess`;
    const response = await Instense.post(url, request);
    let result = response?.data;
    return result;
  };

  public static UpdateUserAuthenticationDetails = async (
    userName,
    uniqueno,
    isTextMessage,
    isGoogleFA,
    primaryMethod
  ): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {};
    request.tenantId = tenantID;
    request.userName = userName;
    request.uniqueno = uniqueno;
    request.isTextMessage = isTextMessage;
    request.isGoogleFA = isGoogleFA;
    request.primaryMethod = primaryMethod;

    const url = `/UserManagement/UpdateUserAuthentication`;
    return Instense.get(url, { params: request }).then((response) => {
      return response?.data;
    });
  };

  public static GetUserPerformanceReviewIsDeleted = async (
    scheduleId: number,
    userUnique: number
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {};

    request.userUnique = userUnique;
    request.scheduleId = scheduleId;
    request.tenantId = tenantID;
    request.createdby = storage === null ? 0 : storage.user_UniqueID;
    const url = `/UserManagement/GetUserPerformanceReviewIsDeleted`;
    return Instense.get(url, { params: request }).then((response) => {
      return response?.data;
    });
  };

  public static GetPayrollAllowancesType = async (
    hid: number
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {};

    request.hid = hid;
    request.tenantId = tenantID;
    const url = `/UserManagement/GetPayrollallowances`;
    return Instense.get(url, { params: request }).then((response) => {
      return response?.data.result;
    });
  };

  public static GetPayrollAllowancesList = async (
    userUnique: number
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {};

    request.UserUniqueno = userUnique;
    request.tenantid = tenantID;
    const url = `/UserManagement/GetUserPayrollallowances`;
    return Instense.get(url, { params: request }).then((response) => {
      return response?.data?.result ;
    });
  };

  public static SaveUserAllowance = async (
    request: IPayrollAllowance
  ): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);

    request.TenantId = storage === null ? 0 : storage.tenantID;
    request.createdBy = storage === null ? 0 : storage.user_UniqueID;

    const url = `/UserManagement/SaveUserAllowance`;
    return Instense.post(url, request).then((response) => {
      return response?.data;
    });
  };

  public static DeleteUserPayrollallowances = async (
    uniqueno,
    useruniqueno
  ): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    const request: any = {};
    request.tenantID = storage === null ? 0 : storage.tenantID;
    request.useruniqueno = useruniqueno;
    request.uniqueno = uniqueno;
    const url = `/UserManagement/DeleteUserPayrollallowances`;
    return Instense.get(url, { params: request }).then((response) => {
      return response?.data;
    });
  };

  public static SendPasswordToUserandAssitance = async (
    request: any
  ): Promise<any | null> => {
    const url = `/UserManagement/SendPasswordToUserandAssitance`;
    const response = await Instense.post(url, request);
    let result = response?.data;
    return result;
  };

  public static CheckIfLoginUserIsPasswordAssitant = async (): Promise<
    any | null
  > => {
    const request: any = {};
    let storage = JSON.parse(localStorage.getItem("storage")!);
    request.tenantID = storage === null ? 0 : storage.tenantID;
    const url = `/UserManagement/GetPasswordAssistanceRole`;
    return Instense.get(url, { params: request }).then((response) => {
      return response?.data;
    });
  };

  public static DownloadPRDocDocument = async (
    uniqueno: number,
    filename: string,
    typeID: string
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {};
    request.tenantId = tenantID;
    request.uniqueno = uniqueno;
    request.filename = filename;
    request.typeID = typeID;
    const url = `/UserManagement/DownloadPRDocDocument`;
    return Instense.post(url, null, {
      params: request,
      responseType: "blob",
    }).then((response: any) => {
      let csvURL = window.URL.createObjectURL(response?.data);
      let tempLink = document.createElement("a");
      tempLink.href = csvURL;
      tempLink.setAttribute("download", filename.replace(/,/g, ""));
      tempLink.click();
    });
  };

  public static GetPayrollStateAndLocalTaxesMaster = async (): Promise<
    any[]
  > => {
    const url = `/UserManagement/GetPayrollStateAndLocalTaxesMaster`;
    return Instense.get(url).then((response) => {
      return response?.data?.result as any[];
    });
  };
  public static GetHotelStateAndLocalTax = async (
    stateHids: any[],
    useruniqueno
  ): Promise<any[]> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {};
    request.stateHids = stateHids;
    request.tenantId = tenantID;
    request.useruniqueno = useruniqueno;

    const url = `/UserManagement/GetHotelStateAndLocalTax`;
    return Instense.post(url, request).then((response) => {
      return response?.data?.result as any[];
    });
  };

  public static DeclineUserPTORequest = async (
    UserUniqueno
  ): Promise<any[]> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {};
    request.TenantId = tenantID;
    request.UserUniqueno = UserUniqueno;
    const url = `/TimeClock/DeclineUserPTORequest`;
    return Instense.get(url, { params: request }).then((response) => {
      return response?.data as any[];
    });
  };

  public static GetFeatureFlagForHousekeepingApprovalThreshold = async (): Promise<any[] | null> => {
    let request: any={};
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID);
    request.tenantID = tenantID;
    const url = `/LaborDayApprove/GetFeatureFlagForHousekeepingApprovalThreshold`;
    return Instense.get(url, { params: request }).then((response) => {
      console.log(response)
      let result = response.data;
      return result;
    });
  };

  public static CheckLaborPermission = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.TenantID = tenantID;
    const url = `/LaborSchedule/CheckLaborSubscription`;

    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as any;
      //debugger
      return result;
    });
  };

}
