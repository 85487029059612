import * as React from 'react';
import {Form, Button, Modal, Spinner, Row, Col,} from "react-bootstrap";
import { DropDownSelect } from "./DropDownSelect";
import { User } from "../../../Common/Services/User";
import { SalesService } from "../../../Common/Services/SalesService";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import { Utils } from '../../../Common/Utilis';

export class CreateContact extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            isModalshow: true,
            preferredContactList: [
                { name: "Email", eventKey: "email", id: 1 },
                { name: "Phone", eventKey: "phone", id: 2 },
                { name: "Text", eventKey: "text", id: 3 },
            ],
            accountContactDetails: {
                contactId: -1, accountId: this.props?.accountId, name: "", email: "", phone: "", title: "", preferredMethod: "", notes: "", isPrimary: "no", isEdit: "no"
            },
            errors: {
                name: "", contact: "", email: "", phone: "",
            },
            isSaveClicked: false,
        };
    }

    closeModal = (type) => {
        this.setState({isModalshow: false});
        this.props.closeModal("contact", type);
    }

    onInputFieldChange = (event: any, type: any) => {
        //debugger;
        let errors = Object.assign({}, JSON.parse(JSON.stringify(this.state.errors)));
        let accountContactDetails = Object.assign({}, JSON.parse(JSON.stringify(this.state.accountContactDetails)));
        let value = event.target.value;
        if (type === "contactName") {
            accountContactDetails.name = value?.toString()?.replace(/[^a-zA-Z0-9 ]+/ig, '');;
            accountContactDetails.isEdit = "yes";
            errors.name = value.trim() == '' ? 'Name is required.' : '';
        }
        if (type === "contactTitle") {
            accountContactDetails.title = value;
            accountContactDetails.isEdit = "yes";
        }
        if (type === "contactEmail") {
            accountContactDetails.email = value;
            accountContactDetails.isEdit = "yes";
            errors.email = value == '' ? 'Email is required.' : '';
        }
        if (type === "contactPhone") {
            let phone = value?.toString()?.replace(/[^0-9\+]+/gi, "");
            if (phone) {
                if (phone.indexOf("-") >= 0) {
                  phone = phone?.replaceAll("-", "");
                }
        
                if (phone?.toString()?.length > 1) {
                  phone = phone?.toString().substr(0, 1) + phone?.toString()?.substr(1)?.replaceAll("+", "");
                }
            }
            accountContactDetails.phone = phone;
            accountContactDetails.isEdit = "yes";
            errors.phone = (phone == '' && (accountContactDetails.preferredMethod === "Text" || accountContactDetails.preferredMethod === "Phone")) ? 'Phone Number is required when Preferred Contact Method is Phone or Text.' : '';
        }
        if (type === "contactnotes") {
            accountContactDetails.notes = value;
            accountContactDetails.isEdit = "yes";
        }
        this.setState({ accountContactDetails, errors });
    }

    
    onDropdownChange = (item, type) => {
        let errors = Object.assign({}, JSON.parse(JSON.stringify(this.state.errors)));
        let accountContactDetails = Object.assign({}, JSON.parse(JSON.stringify(this.state.accountContactDetails)));
        if (type === "preferredContact") {
            accountContactDetails.preferredMethod = item.name;
            accountContactDetails.isEdit = "yes";
            if (accountContactDetails.phone == '' && (item.name === "Text" || item.name === "Phone")) {
                errors.phone = 'Phone Number is required when Preferred Contact Method is Phone or Text.';
            }
            else if (accountContactDetails.phone == '' && (item.name === "Email")) {
                errors.phone = '';
            }
        }
        this.setState({ accountContactDetails, errors });
    }

    isPrimaryContact = (event: any) => {
        let accountContactDetails = Object.assign({}, JSON.parse(JSON.stringify(this.state.accountContactDetails)));
        if (event.target.checked) {
            accountContactDetails.isPrimary = "yes";
        }
        else {
            accountContactDetails.isPrimary = "no";
        }
        this.setState({ accountContactDetails });
    }

    onBlurName = (event) => {
        let errors = Object.assign({}, JSON.parse(JSON.stringify(this.state.errors)));
        if (event.target.value?.trim().length === 0) {
            errors.name = 'Name is required.';
        }
        this.setState({ errors });
    }

    validateEmail = (event) => {
        let errors = Object.assign({}, JSON.parse(JSON.stringify(this.state.errors)));
        let email = event.target.value;
        if (email?.toString()?.length === 0) {
            errors.email = "Email is required.";
            this.setState({ errors });
            return;
        }
        const emailRegex =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if(!emailRegex.test(email?.toLowerCase()?.trim())) {
            errors.email = 'Please enter a valid email address.';
        }
        else {
            errors.email = ""
        }
        this.setState({ errors });
    }

    validatePhone = (event) => {
        let errors = Object.assign({}, JSON.parse(JSON.stringify(this.state.errors)));
        let accountContactDetails = Object.assign({}, JSON.parse(JSON.stringify(this.state.accountContactDetails)));
        let phone = event.target.value;
        if (phone?.toString()?.length === 0 && (accountContactDetails.preferredMethod !== "Text" && accountContactDetails.preferredMethod !== "Phone")) {
            errors.phone = "";
            this.setState({ errors });
            return;
        }
        else if (phone?.toString()?.length === 0 && (accountContactDetails.preferredMethod === "Text" || accountContactDetails.preferredMethod === "Phone")) {
            errors.phone = "Phone Number is required when Preferred Contact Method is Phone or Text.";
            this.setState({ errors });
            return;
        }
        if (phone?.toString()?.includes("+") && phone?.toString()?.substr(1, 1) !== "1") {
            if (phone?.toString()?.length < 9) {
                errors.phone = "Mobile Phone Number is not valid";
            } else {
                errors.phone = "";
            }
            this.setState({ errors });
            return;
        }
        User.ValidatePhoneNumber(phone).then((response: any) => {
            // debugger;
            if (response?.fakeNumber === "YES") {
                errors.phone = "Mobile Phone Number is not valid";
            } else if (response?.lineType !== "CELL PHONE") {
                errors.phone = response?.lineType && response?.lineType !== "FAKE" ? `${this.CapitalizeFirstLetter(response?.lineType)} Phone Numbers are not supported` : `Mobile Phone Number is not valid`;
            } else {
                errors.phone = "";
            }
            this.setState({ errors });
        });
    }

    isFormValid = () => {
        let errors = Object.assign({}, JSON.parse(JSON.stringify(this.state.errors)));
        let accountContactDetails = Object.assign({}, JSON.parse(JSON.stringify(this.state.accountContactDetails)));

        if (accountContactDetails.name.trim() === "") {
            errors.name = 'Name is required.';
        }
        if (accountContactDetails.email === "") {
            errors.email = 'Email is required.';
        }
        if(accountContactDetails.email?.trim() !== "") {
            const emailRegex =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if(!emailRegex.test(accountContactDetails.email?.toLowerCase()?.trim())) {
                errors.email = 'Please enter a valid email address.';
            }
            else {
                errors.email = ""
            }
        }

        let isValidatePhoneAPI = true;
        let phone = accountContactDetails.phone;
        if (phone?.toString()?.length === 0 && (accountContactDetails.preferredMethod === "Text" || accountContactDetails.preferredMethod === "Phone")) {
            errors.phone = "Phone Number is required when Preferred Contact Method is Phone or Text.";
            isValidatePhoneAPI = false;
        }
        else if (phone?.toString()?.length === 0 && (accountContactDetails.preferredMethod !== "Text" && accountContactDetails.preferredMethod !== "Phone")) {
            errors.phone = "";
            isValidatePhoneAPI = false;
        }
        else if (phone?.toString()?.includes("+") && phone?.toString()?.substr(1, 1) !== "1") {
            if (phone?.toString()?.length < 9) {
                errors.phone = "Mobile Phone Number is not valid";
            } else {
                errors.phone = "";
            }
            isValidatePhoneAPI = false;
        }
        this.setState({ errors });
        // debugger;
        let fields = Object.keys(errors);
        if (fields.length > 0) {
            for (let i = 0; i < fields.length; i++) {
                if (errors[fields[i]] !== "") {
                    Utils.toastError("Please check and correct the highlighted fields.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        containerId: "create-cont-val-1",
                    });
                    return {isValid: false, isValidatePhoneAPI: isValidatePhoneAPI};
                }
            }
            return {isValid: true, isValidatePhoneAPI: isValidatePhoneAPI};
        }
        return {isValid: false, isValidatePhoneAPI: isValidatePhoneAPI};
    }

    CapitalizeFirstLetter = (string) => {
        if (string) {
            return string?.charAt(0).toUpperCase() + string?.slice(1).toLowerCase();
        } else {
            return string;
        }
    }

    saveNewContact = () => {
        this.setState({ isSaveClicked: true});
        let formValid = this.isFormValid();  // formValid is an object {isValid: false, isValidatePhoneAPI: false}
        if (formValid?.isValid) {
            if(formValid?.isValidatePhoneAPI) {
                let errors = Object.assign({}, JSON.parse(JSON.stringify(this.state.errors)));
                User.ValidatePhoneNumber(this.state.accountContactDetails.phone).then((response: any) => {
                    // debugger;
                    if (response?.fakeNumber === "YES") {
                        errors.phone = "Mobile Phone Number is not valid";
                        Utils.toastError("Please check and correct the highlighted fields.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            containerId: "create-cont-val-2",
                        });
                        this.setState({ isSaveClicked: false, errors});
                    } else if (response?.lineType !== "CELL PHONE") {
                        errors.phone = response?.lineType && response?.lineType !== "FAKE" ? `${this.CapitalizeFirstLetter(response?.lineType)} Phone Numbers are not supported` : `Mobile Phone Number is not valid`;
                        Utils.toastError("Please check and correct the highlighted fields.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            containerId: "create-cont-val-3",
                        });
                        this.setState({ isSaveClicked: false, errors});
                    } else {
                        errors.phone = "";
                        this.setState({ errors }, () => {
                            this.saveNewContactAPI();
                        });
                    }
                });
            }
            else {
                this.saveNewContactAPI();
            }
        }
        else {
            this.setState({ isSaveClicked: false});
        }
    }

    saveNewContactAPI = () => {
        let request: any = Object.assign({}, JSON.parse(JSON.stringify(this.state.accountContactDetails)));
        SalesService.SaveSalesAccountContact(request)
            .then(async (result: any | null) => {
                // debugger;
                if (result.saveStatus.toLowerCase() === "success") {
                    toast.success(result.message,
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            containerId: "SalesAcc1a",
                        });
                    this.closeModal("refresh");
                } else {
                    this.setState({ isSaveClicked: false});
                    Utils.toastError(result.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        containerId: "SalesAcc1b",
                    });
                }
                resolve();
            })
            .catch((error) => {
                Utils.toastError("Something went wrong.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "SalesAcc1",
                });
                reject();
            });
    }

    isSaveDisabled = () => {
        let errors = Object.assign({}, JSON.parse(JSON.stringify(this.state.errors)));
        let accountContactDetails = Object.assign({}, JSON.parse(JSON.stringify(this.state.accountContactDetails)));
        if (this.state.isSaveClicked) {
            return true;
        }
        if (accountContactDetails.name?.trim().length === 0 || accountContactDetails.email?.trim().length === 0) {
            return true;
        }
        else if (errors.name !== "" || errors.email !== "") {
            return true;
        }
        else if (errors.name === "" || errors.email === "") {
            return false;
        }
        return true;
    }

    render() {
        const { errors } = this.state;
        return (
            <Modal className="create-modal" show={this.state.isModalshow} onHide={() => this.closeModal("close")} backdrop="static" centered>
                <Modal.Header closeButton>
                    <Modal.Title>New Contact</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-section">
                        <div className="section">
                            <Row>
                                <Col>
                                    <Form.Group className={errors.name.length > 0 ? "error" : ""}>
                                        <Form.Label>Name <span className='star'>*</span></Form.Label>
                                        <Form.Control
                                            id="name"
                                            type="text"
                                            name="name"
                                            value={this.state.accountContactDetails.name}
                                            autoComplete="off"
                                            onChange={(event: any) => this.onInputFieldChange(event, "contactName")}
                                            onBlur={(e) => this.onBlurName(e)}
                                            placeholder="Name"
                                            maxLength={50}
                                        />
                                        {errors.name.length > 0 &&
                                            <span className='error'>{errors.name}</span>
                                        }
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={6}>
                                    <Form.Group>
                                        <Form.Label>Title</Form.Label>
                                        <Form.Control
                                            id="title"
                                            type="text"
                                            name="title"
                                            value={this.state.accountContactDetails.title}
                                            autoComplete="off"
                                            onChange={(event: any) => this.onInputFieldChange(event, "contactTitle")}
                                            placeholder="Title"
                                            maxLength={50}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={6}>
                                    <Form.Group>
                                        <Form.Label>Preferred Contact Method</Form.Label>
                                        <DropDownSelect
                                            itemList={this.state.preferredContactList}
                                            onDropdownChange={this.onDropdownChange}
                                            type="preferredContact"
                                            placeholder="Select Preferred Contact"
                                        >
                                        </DropDownSelect>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={6}>
                                    <Form.Group className={errors.email.length > 0 ? "error" : ""}>
                                        <Form.Label>Email <span className='star'>*</span></Form.Label>
                                        <Form.Control
                                            id="email"
                                            type="text"
                                            name="email"
                                            value={this.state.accountContactDetails.email}
                                            autoComplete="off"
                                            onChange={(event: any) => this.onInputFieldChange(event, "contactEmail")}
                                            onBlur={(e) => this.validateEmail(e)}
                                            placeholder="Email"
                                        />
                                        {errors.email.length > 0 &&
                                            <span className='error'>{errors.email}</span>
                                        }
                                    </Form.Group>
                                </Col>
                                <Col xs={6}>
                                    <Form.Group className={errors.phone.length > 0 ? "error" : ""}>
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control
                                            id="phoneNumber"
                                            type="text"
                                            name="phoneNumber"
                                            value={this.state.accountContactDetails.phone}
                                            autoComplete="off"
                                            maxLength={16}
                                            onChange={(event: any) => this.onInputFieldChange(event, "contactPhone")}
                                            onBlur={(e) => this.validatePhone(e)}
                                            placeholder="Phone Number"
                                        />
                                        {errors.phone.length > 0 &&
                                            <span className='error'>{errors.phone}</span>
                                        }
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Notes</Form.Label>
                                        <Form.Control 
                                            as="textarea" 
                                            rows={3}
                                            id="notes"
                                            name="notes"
                                            value={this.state.accountContactDetails.notes}
                                            autoComplete="off"
                                            onChange={(event: any) => this.onInputFieldChange(event, "contactnotes")}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Primary Contact</Form.Label>
                                        <Form.Check
                                            type="switch"
                                            id="primaryContact"
                                            label=""
                                            checked={this.state.accountContactDetails.isPrimary === "yes"}
                                            onChange={(event: any) => { this.isPrimaryContact(event); }}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" disabled={this.isSaveDisabled()} onClick={this.saveNewContact}>Save</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}