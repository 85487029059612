import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Row,
  Spinner,
  Modal,
} from "react-bootstrap";
import { UserManagement } from "./../../../Common/Services/UserManagement";
import { AddHotelWiseSetting } from "./AddHotelWiseSetting";
import Joi from "joi-browser";
import { AddSpecialRate } from "./AddSpecialRate";
import moment from "moment";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { Utils } from "../../../Common/Utilis";
import BootstrapTable from "react-bootstrap-table-next";
import chevronRight from "../../../Common/Assets/Images/icons/bx-chevron-right.svg";
import { IHotelWideSettingProps } from "./../../../Common/Contracts/IHotelWideSettingProps";
import { toast } from "react-toastify";
import { AddAllowances } from "./AddAllowances";
import { IPayrollAllowancesProps } from "../../../Common/Contracts/IPayrollAllowancesProps";
import _ from "lodash";

export const PayrollAllowances = ({
  allowanceTableData,
  userGeneralInfo,
  allowanceTableExpanded,
  handleOnAllowanceExpand,
  isAllowanceExpand,
  showAllowanceAddEditModal,
  showAllowanceAddEditModalForm,
  isAllowancePayrollLoaded,
  hideAllowanceAddEditModalForm,
  AllowancePayrollRowEdit,
  isPayrollTab,
  userUniqueNo,
  clientSetupData,
  hasHWPSetting,
  hasViewSensitiveDataPermission,
  isFromPayroll,
  isEditLaborandPayrollInfo,
  employeeState,
  hotelWideTableData
}: IPayrollAllowancesProps) => {

  const [positionList, setPositionList] = useState<any[]>([]);
  const [mainPositionList, setMainPositionList] = useState<any[]>([]);
  const [moduleWithHids, setModuleWithHids] = useState<any[]>([]);
  const [allowanceHotels, setAllowanceHotel] = useState<any[]>([]);



  useEffect(() => {
    if (positionList?.length === 0) {
      getAllPositionlist();
    }
    if (moduleWithHids?.length === 0) {
      getModuleWithHids();
    }
    getHotelWithAllowances()
  }, []);
  
  const getHotelWithAllowances = () => {
    UserManagement.GetHotelWithAllowances(userUniqueNo).then((res: any[]) => {
       setAllowanceHotel([...res])
    });
  };


  const getAllPositionlist = () => {
    UserManagement.GetAllPositionlistbyHid().then((_positionList: any[]) => {
      setPositionList(_positionList);
      setMainPositionList(_positionList);
    });
  };

  const deletePayrollAllowance = (row) => {
    UserManagement.DeleteUserPayrollallowances(row?.uniqueNo, userUniqueNo).then(result => {
      console.log({result})
      if (result?.message === "success") {
        toast.success(
            "Payroll Allowance Deleted Successfully",
            {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "addDeduction",
            }
        )
    } else {
        Utils.toastError(result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addDeduction",
        });
    }
      hideAllowanceAddEditModalForm(true);

    }).catch(error => {
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "addDeduction",
      });
    })



  }


  const getStatusString = (status) => {
    return status === "Active" ? (
      <span className="badge color-green">Active</span>
    ) : (
      <span className="badge color-grey">Inactive</span>
    );
  };

  const tableColumnEvent = {
    onClick: (e, column, columnIndex, row, rowIndex) => {
      showAllowanceAddEditModalForm(true, row)
    },
  };

  const hotelWideMainColumns = [
    {
      dataField: "lettercode",
      text: "",
      events: tableColumnEvent,
      formatter: (cell: any, row: any, rowIndex: any) => (
        <div className="hotel-wide-details d-flex flex-column">
          <span className="lettercode">{cell}</span>
          {/* <span className="effective-date">Effective {row?.ptoStartDate}</span> */}
        </div>
      ),
    },
    {
      dataField: "allowanceName",
      text: "",
      events: tableColumnEvent,
      formatter: (cell: any, row: any, rowIndex: any) => (
        <div className="position-details d-flex flex-column">
          <EllipsisWithTooltip>
            <span className="position">{cell}</span>
            <span className="position-label">Allowance Name</span>   
          </EllipsisWithTooltip>
        </div>
      ),
    },
    {
      dataField: "allowanceType",
      text: "",
      events: tableColumnEvent,
      formatter: (cell: any, row: any, rowIndex: any) => (
        <div className="position-details d-flex flex-column">
          <EllipsisWithTooltip>
            <span className="position">{cell}</span>
          </EllipsisWithTooltip>
          <span className="position-label">Allowance Type</span>
        </div>
      ),
    },
    {
      dataField: "recurrence",
      text: "",
      events: tableColumnEvent,
      formatter: (cell: any, row: any, rowIndex: any) => (
        <div className="position-details d-flex flex-column">
          <EllipsisWithTooltip>
            <span className="position">{cell}</span>
          </EllipsisWithTooltip>
          <span className="position-label">Recurrence Period</span>
        </div>
      ),
    },
    {
      dataField: "amount",
      text: "",
      events: tableColumnEvent,
      formatter: (cell: any, row: any, rowIndex: any) => (
        <div className="position-details d-flex flex-column">
          <EllipsisWithTooltip>
            <span className="position">{Utils.currencyFormat(cell)}</span>
            <span className="position-label">Amount</span>   
          </EllipsisWithTooltip>
        </div>
      ),
    },
    {
      dataField: "",
      text: "",
      formatter: (cell: any, row: any, rowIndex: any) => (
        <Dropdown
          // onClick={(e) => {
          //   e.stopPropagation();
          // }}
          className="more-action"
          alignRight
        >
          <Dropdown.Toggle
            disabled={!hasViewSensitiveDataPermission || !isEditLaborandPayrollInfo
              || userGeneralInfo.hids?.filter(x => x.lettercode === row?.lettercode && x?.loginUserHasAccess)?.length === 0}
            className="btn-outline-primary btn btn-primary btn-ghost more"
            id="dropdown-more"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
              <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
              <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
            </svg>
          </Dropdown.Toggle>
          <Dropdown.Menu>

            <Dropdown.Item
              key={"Delete"}
              onClick={() => deletePayrollAllowance(row)}
              eventKey={"Delete"}
            >
              Delete
            </Dropdown.Item>



          </Dropdown.Menu>
        </Dropdown>
      ),
    },
  ];

  const hotelWideChildColumns = [
    {
      dataField: "regPosition",
      text: "Position",
      formatter: (cell: any, row: any, rowIndex: any) => (
        <EllipsisWithTooltip>
          <div className="position-name">{cell}</div>
        </EllipsisWithTooltip>
      ),
    },
    {
      dataField: "regularRate",
      text: "Regular",
      formatter: (cell: any, row: any, rowIndex: any) => (
        <div>
          {!hasViewSensitiveDataPermission ? cell : Utils.amountFormatted(cell)}
        </div>
      ),
    },
    {
      dataField: "overTime",
      text: "OT",
      hidden: userGeneralInfo?.employeeType == "ExcludefromPayroll" ? true : false,
      formatter: (cell: any, row: any, rowIndex: any) => (
        <div>
          {!hasViewSensitiveDataPermission ? cell : Utils.amountFormatted(cell)}
        </div>
      ),
    },
    {
      dataField: "doubleOverTime",
      text: "DOT",
      hidden: userGeneralInfo?.employeeType == "ExcludefromPayroll" ? true : false,
      formatter: (cell: any, row: any, rowIndex: any) => (
        <div>
          {!hasViewSensitiveDataPermission ? cell : Utils.amountFormatted(cell)}

        </div>
      ),
    },
    {
      dataField: "holidayRate",
      text: "Holiday",
      hidden: userGeneralInfo?.employeeType == "ExcludefromPayroll" ? true : false,
      formatter: (cell: any, row: any, rowIndex: any) => (
        <div>
          {!hasViewSensitiveDataPermission ? cell : Utils.amountFormatted(cell)}

        </div>
      ),
    }
  ];




  const expandRowHotelWide = {
    renderer: (row: any) => (
      <div className="rowExpended" id="laborDayDetails">

        <div>

          <div className="section-heading">Details</div>
          <div className="adjustments-labor-hotel-wide-regular-table">
            <BootstrapTable
              keyField="rowNo"
              data={[row]}
              columns={
                (userGeneralInfo?.employeeType === "Contractor" &&
                  !userGeneralInfo?.allowContactorOverTime) ?
                  hotelWideChildColumns?.filter(x => !["overTime", "doubleOverTime", "holidayRate"]?.includes(x.dataField))
                  : hotelWideChildColumns}
              hover
            />
          </div>
        </div>
      </div>
    ),
    expanded: allowanceTableExpanded,
    onExpand: handleOnAllowanceExpand,
    showExpandColumn: false,
    expandByColumnOnly: false,
    // expandColumnRenderer: ({ expanded }) => {
    //   if (expanded) {
    //     return (
    //       <span className="chevronExpended">
    //         <img src={chevronRight} alt="chevron Right" />
    //       </span>
    //     );
    //   }
    //   return (
    //     <span className="chevronCollpsed">
    //       <img src={chevronRight} alt="chevron Right" />
    //     </span>
    //   );
    // },
  };

  const rowHotelWideClasses = (row: any, rowIndex: any) => {
    if (isAllowanceExpand === true && row?.action === "Expended") {
      return "row-Expended";
    } else if (row?.approveButtonSpinner) {
      return "p-none";
    } else if (isAllowanceExpand === true) {
      return "row-Collapsed";
    } else {
      return "row-Action";
    }
  };

  const getModuleWithHids = () => {
    UserManagement.GetModuleWithHids().then((result: any) => {
      setModuleWithHids(result?.result);
    });
  }
  const isAllowAddAllowance=()=>{
    const userHotels=getOnlyAllowedHotelList();
    if(allowanceTableData.length > 0)
      { 
        const currentTableHID=_.uniq(allowanceTableData.map(item => item?.hid))
        if(currentTableHID.length >0)
        {
          const isAllow=allowanceTableData.map(item => item?.allowAllownace)?.some(item =>item)
         if(isAllow)
         {
          return isAllow
         }
         else{
          const allowanceNotAdded= userHotels.map(item => !currentTableHID.includes(item?.hotelID)).some(item =>item)
          return allowanceNotAdded
         }
       
        }
       
       
      }
     else
     {

       return userHotels.length > 0 ? true : false
     }
  }

  const getHotelsForButton = (): any[] => {

    let assignableHotels = (allowanceTableData.length > 0
      ? userGeneralInfo?.hids.filter(
        (x) => x?.loginUserHasAccess
        //  &&
        //   !allowanceTableData
        //     ?.map((l) => l?.lettercode)
        //     .includes(x?.lettercode)
      )
      : userGeneralInfo?.hids?.filter(
        (x) => x?.loginUserHasAccess) || []);
    const _assignableHotels: any[] = [];



    assignableHotels.forEach((hotel: any) => {
      moduleWithHids?.forEach((item: any) => {

        if (item?.moduleName === "Labor Management" && item?.hotelID === hotel?.hotelID) {
          _assignableHotels.push(hotel);
        }
      });
    });

    return _assignableHotels;
  }


  const getHotelsforPip = () => {
    let assignableHotels = userGeneralInfo?.hids?.filter(
      (x) => x?.loginUserHasAccess) || [];
    const _assignableHotels: any[] = [];
    assignableHotels.forEach((hotel: any) => {
      moduleWithHids?.forEach((item: any) => {
        if (item?.moduleName === "Labor Management" && item?.hotelID === hotel?.hotelID) {
          _assignableHotels.push(hotel);
        }
      });
    });
    return _assignableHotels;
  }

   const getOnlyAllowedHotelList =()=>{
    if(AllowancePayrollRowEdit?.uniqueNo > 0)
    {
      return getHotelsForForm()
    }
    else{
      if(allowanceHotels && allowanceHotels.length > 0)
      {
        const allowanceHotelsTrueOnly=allowanceHotels.filter(item => item.allowAllowance).map(item => item.hotelId)
        const hotels=getHotelsForForm()
  
        const onlyAllowedHotelList=hotels.filter(item =>allowanceHotelsTrueOnly.includes(item.hotelID) )
        return onlyAllowedHotelList
      }
      else{
        return []
      }
    }
   }
  const getHotelsForForm = (): any[] => {
    let assignableHotels = (AllowancePayrollRowEdit?.uniqueNo > 0
      ? userGeneralInfo?.hids.filter(
        (x) => x?.loginUserHasAccess && +x.hotelID === AllowancePayrollRowEdit.hid
      )
      : userGeneralInfo?.hids?.filter(x => x?.loginUserHasAccess) || []);
    //    if(!AllowancePayrollRowEdit?.uniqueNo && !isFromPayroll) {
    //   if(userGeneralInfo?.employeeType === "Salaried" ||userGeneralInfo?.employeeType === "SalariedNonExempt") {

    //    assignableHotels= assignableHotels.;
    //   }
    // }
    const _assignableHotels: any[] = assignableHotels.filter(hotel => hotelWideTableData.find(hotelwide => hotelwide.status === "Active" && hotelwide.lettercode == hotel.lettercode))



    return _assignableHotels;
  }



  const handleAddHotelWideSettings = () => {

    if (getHotelsForForm()?.length === 0) {


      showAllowanceAddEditModalForm(false, {}, true);

    } else {
      showAllowanceAddEditModalForm();
    }


  }

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      console.log(row)
      showAllowanceAddEditModalForm(true, row)
    }
  };

 




  return (

    <>
      {
        ((isPayrollTab &&
          allowanceTableData?.length > 0)
          || !isPayrollTab) &&
        getHotelsforPip()?.length > 0  && 
        <>
          <div className="separator-line"></div>
          <div className="body-section">
            <div className="heading mod-for-pips-icon">
              <>
              {
                (
                  userGeneralInfo?.groupHWPPayrollSetting &&
                  !isPayrollTab &&
                  userGeneralInfo?.employeeType !== "ExcludefromPayroll") &&

                <div className="pips"><div className="pip light-red"></div></div>
              }
            </> 
            Allowances</div>
           {allowanceTableData.length == 0 && !isAllowAddAllowance() && <span className="notification-banners blue-bg">
              Allowance Type is not Mapped for Entities.
            </span>}
            <div className="labor-payroll-allowances-table">
              <BootstrapTable
                keyField="hid"
                data={allowanceTableData}
                columns={hotelWideMainColumns}
                // rowEvents={rowEvents}
              // expandRow={expandRowHotelWide}
              // rowClasses={rowHotelWideClasses}
              // hover
              />
            </div>
            <div className="add-allowances-wrapper">
              {getHotelsForButton()?.length > 0 && isAllowAddAllowance()  && !isPayrollTab && hasViewSensitiveDataPermission && isEditLaborandPayrollInfo &&
                userGeneralInfo?.employeeType && (
                  <Button
                    className="btn-ghost"
                    onClick={() => handleAddHotelWideSettings()}
                  >
                    Add Allowance
                  </Button>
                )}{" "}
            </div>
            {showAllowanceAddEditModal &&
              userGeneralInfo?.employeeType &&
              // isAllowancePayrollLoaded &&
              moduleWithHids?.length > 0 && (
                <AddAllowances
                  isPayrollTab={isPayrollTab}
                  userHotels={getOnlyAllowedHotelList()}
                  hireDate={userGeneralInfo?.hireDate}
                  showAllowanceAddEditModal={showAllowanceAddEditModal}
                  hideAllowanceAddEditModalForm={hideAllowanceAddEditModalForm}
                  userName={userGeneralInfo?.userName}
                  userUniqueNo={userUniqueNo}
                  isEmpCodeRequired={clientSetupData?.empCodeADP === 'Yes'}
                  employeeType={userGeneralInfo?.employeeType}
                  AllowancePayrollRowEdit={AllowancePayrollRowEdit}
                  positionList={mainPositionList}
                  allowanceTableData={allowanceTableData}
                  employeeState={employeeState}
                />
              )}
          </div>
        </>
      }
    </>
  );
};
