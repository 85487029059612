import * as React from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { ModuleReports } from "../../Common/Services/ModuleReports";
import { resolve, reject } from "q";
import { toast } from "react-toastify";
import { Utils } from "../../Common/Utilis";
export class MultiSelectVendorControl extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            selectedItem: [],
            vendorList: [],
            vendorListCopy: [],
            isbulkSelect: false,
            searchItem: "",
            tableLoader: false,
            disableVendorControl: true
        };
    }

    componentDidMount = () => {
        const { hotelIds } = this.props;
        if (hotelIds !== undefined && hotelIds !== "") {
            this.GetePayVendorList();
        }
    };

    GetePayVendorList = () => {
        this.setState({ tableLoader: true })
        let request: any = {};
        request.Hid = this.props?.hotelIds;
        ModuleReports.GetePayVendorList(request).then((result: any[]) => {
            if (result != null && result.length > 0) {
                result.forEach((o: any) => {
                    o["isChecked"] = false;
                    o["value"] = o.vid;
                    o["label"] = o.vendorname
                });
                this.setState({ vendorList: result, vendorListCopy: result, selectedItem: [], tableLoader: false, disableVendorControl: false }, () => {
                    this.getDefaultSelectedItems();
                })
            } else {

                this.setState({ vendorList: [], vendorListCopy: [], selectedItem: [], tableLoader: false, disableVendorControl: true })
            }
            resolve();
        }).catch(err => {
            Utils.toastError(`Server Error, ${err}`);
            this.setState({ vendorList: [], vendorListCopy: [], selectedItem: [], tableLoader: false, disableVendorControl: true })
            reject();
        });
    };

    handleSearch(e: any) {
        const updatedList = this.state.vendorListCopy.filter((item: any) => {
            return (item.label.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1);
        });
        this.setState({ vendorList: updatedList, searchItem: e.target.value });
    }

    handleToggle = (e: any) => {
        if (!e) {
            let event: any = { target: { value: "" } }
            this.handleSearch(event)
        }
    }

    getDefaultSelectedItems = () => {
        const { defaultValue } = this.props;
        let selectedItem = Object.assign([], JSON.parse(JSON.stringify(this.state.selectedItem)));
        let vendorList = Object.assign([], JSON.parse(JSON.stringify(this.state.vendorList)));
        if (defaultValue !== "" && defaultValue !== undefined && defaultValue !== null) {
            let tempArray = defaultValue.split(",");
            for (let i = 0; i < tempArray.length; i++) {
                if (vendorList.filter((x) => x.value.toString() === tempArray[i]).length > 0) {
                    const index = vendorList.findIndex((x) => x.value.toString() === tempArray[i]);
                    vendorList[index].isChecked = true;
                    selectedItem.push(vendorList[index]);
                }
            }
            this.setState({ selectedItem, vendorList, isbulkSelect: selectedItem.length === this.state.vendorListCopy.length });
        } else {
            let event: any = { target: { checked: true } }
            this.handleDropdownChange(event, "All");
        }
    }

    componentDidUpdate = (prevprops, prevState) => {
        const { hotelIds } = this.props;
        if ((prevprops.hotelIds !== hotelIds)) {
            this.GetePayVendorList();
        }
    }

    resetState = () => {
        let { selectedItem, vendorList, vendorListCopy } = this.state;
        vendorList.forEach((o: any) => o.isChecked = true);
        vendorListCopy.forEach((o: any) => o.isChecked = true);
        selectedItem = vendorListCopy.filter((o: any) => o.isChecked);
        let isEqualLen = selectedItem.length === this.state.vendorListCopy.length;
        this.setState({ vendorList, vendorListCopy, isbulkSelect: isEqualLen, selectedItem }, () => {
            this.handleCheckItem();
        });
    }

    handleCheckItem = () => {
        const item = this.state.selectedItem;
        let selecteditem: any = [];
        item.forEach((o: any) => selecteditem.push(o.value));
        this.props?.multiSelectVendor(selecteditem);
    }

    handleDropdownChange = (event: any, item: any) => {
        let { selectedItem, vendorList, vendorListCopy } = this.state;
        let checked = event.target.checked;
        if (item === "All") {
            vendorList.forEach((o: any) => { o.isChecked = checked });
            vendorListCopy.forEach((o: any) => { o.isChecked = checked });
        } else {
            vendorList.forEach((o: any) => { if (o.value === item.value) { o.isChecked = checked } });
            vendorListCopy.forEach((o: any) => { if (o.value === item.value) { o.isChecked = checked } });
        }
        selectedItem = vendorListCopy.filter((o: any) => o.isChecked);
        let isEqualLen = selectedItem.length === this.state.vendorListCopy.length;
        this.setState({ vendorList, vendorListCopy, isbulkSelect: isEqualLen, selectedItem }, () => {
            this.handleCheckItem();
        });
    };

    handleSelectAll = (checked) => {
        let { selectedItem, vendorList, vendorListCopy, searchItem } = this.state;
        let totalLength: any[] = [];
        vendorList.forEach((o: any) => { o.isChecked = checked })
        vendorListCopy.forEach((o: any) => {
            if (o.label.toLowerCase().includes(searchItem.toLowerCase().trim())) {
                o.isChecked = checked;
            }
        })
        vendorList.forEach((o: any) => { totalLength.push(o) });
        selectedItem = vendorListCopy.filter((o: any) => o.isChecked);
        let isEqualLen = selectedItem.length === this.state.vendorListCopy.length;
        this.setState({ vendorList, isbulkSelect: isEqualLen, selectedItem }, () => {
            this.handleCheckItem();
        });
    }

    render() {
        const { selectedItem, vendorList, vendorListCopy, isbulkSelect, searchItem, tableLoader, disableVendorControl } = this.state;
        const isAll = selectedItem.length > 0 && selectedItem.length === vendorListCopy.length;
        return (
            <>
                <Dropdown className="single-select" onToggle={this.handleToggle}>
                    <Dropdown.Toggle disabled={tableLoader || disableVendorControl} id="Modal-DocType" className="txtDocTypebtn">
                        {isAll ? "All" : (
                            <div className={`${tableLoader || disableVendorControl ? "disabled-area" : ""}`}>
                                {selectedItem.length > 0 ? (<>
                                    {selectedItem.length === 1 ? (
                                        <div className="txtDocTypeItemList">
                                            {selectedItem.map((item: any, index: any) => (
                                                <div className="selectedItems">
                                                    <div className="itemLabel"><EllipsisWithTooltip placement="bottom">{item.label}</EllipsisWithTooltip></div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (<div className="txtDocTypeItemList">{selectedItem.length} Vendor(<span className="text-lowercase">s</span>) Selected</div>)}
                                </>
                                ) : ("Select Vendor")}
                            </div>
                        )}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="txtDocTypeMenu">
                        <div className="SearchInput">
                            <input
                                key={"text-Filter"}
                                type="text"
                                className="form-control"
                                onChange={(e: any) => this.handleSearch(e)}
                                placeholder="Search..."
                                value={searchItem}
                            />
                        </div>
                        {vendorList.length > 0 && (<div className="m-0 dropdown-divider"></div>)}
                        {/* 
                    <div className="list-area">
                        <Form.Check type="checkbox" label="All" disabled={tableLoader} checked={isbulkSelect} onChange={(e: any) => this.handleDropdownChange(e, "All")} />
                    </div>
                    <div className="m-0 dropdown-divider"></div> 
                    */}
                        <div className="list-area">
                            {tableLoader ? (<>
                                <div className="single-loader vendorListloader mb-2 loadingAnimation"></div>
                                <div className="single-loader vendorListloader mb-2 loadingAnimation"></div>
                                <div className="single-loader vendorListloader mb-2 loadingAnimation"></div>
                                <div className="single-loader vendorListloader mb-2 loadingAnimation"></div>
                                <div className="single-loader vendorListloader mb-2 loadingAnimation"></div>
                                <div className="single-loader vendorListloader mb-2 loadingAnimation"></div>
                            </>) : (
                                <>
                                    {vendorList.map((item: any, idx: any) => (
                                        <Form.Check
                                            type="checkbox"
                                            key={"docType" + idx}
                                            id={"docType" + idx}
                                            label={<EllipsisWithTooltip placement="bottom">{item.label}</EllipsisWithTooltip>}
                                            checked={item.isChecked}
                                            onChange={(e: any) => this.handleDropdownChange(e, item)}
                                        />
                                    ))}
                                </>
                            )}
                        </div>
                        {vendorList.length > 0 && (
                            <div className="footer">
                                <Button className="wht-bg txt-green" disabled={tableLoader} onClick={(e) => { this.handleSelectAll(!isbulkSelect) }}>{isbulkSelect ? "Clear All" : "Select All"}</Button>
                            </div>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            </>
        );
    }
}