import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Dropdown, Form, Modal, Button } from "react-bootstrap";
import { LaborDayApprove } from "../../../../../Common/Services/LaborDayApprove";
import { HousekeepingApprovalsDetails } from "./HousekeepingApprovalsDetails";
import chevronRight from "../../../../../Common/Assets/Images/icons/bx-chevron-right.svg";
import { resolve, reject } from "q";
import { toast } from "react-toastify";
import $ from "jquery";
import OutsideClickHandler from "react-outside-click-handler";
import { ReactPageLoader } from "../../../../../Common/Components/ReactPageLoader";
import { LaborBudgetSetting } from "../../../../../Common/Services/LaborBudgetSetting";
import { Utils } from "../../../../../Common/Utilis";

export class HousekeepingApprovalsList extends React.Component<any, any> {
  private approveDepartment: any;
  constructor(props: any) {
    super(props);
    this.approveDepartment = React.createRef();
    this.state = {
      hidValue: props.parentState.hidValue,
      hotelLetterCode: props.parentState.hotelName,
      isLetterCode: props.parentState.isLetterCode,
      isLetterId: props.parentState.isLetterId,
      date:
        JSON.parse(localStorage.storage).currentUtcTime !== ""
          ? new Date(JSON.parse(localStorage.storage).currentUtcTime)
          : new Date(),
      tableData: [],
      tableDataDup: [],
      loaderTrue: false,
      expendedRow: {},
      tableExpended: [],
      columnDetails: [],
      isExpand: false,
      isBulkSelect: false,
      show: false,
      checkCount: 0,
      checkAll: false,
      skipNotes: "",
    };
    this.bulkSelectClick = this.bulkSelectClick.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleFooterClose = this.handleFooterClose.bind(this);
    this.handleMultiSkip = this.handleMultiSkip.bind(this);
  }

  bulkSelectClick() {
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableData))
    );
    // eslint-disable-next-line array-callback-return
    data.map((item: any) => {
      item.isCheck = false;
    });
    this.setState({ tableDataDup: data });
    this.setState(
      { isBulkSelect: !this.state.isBulkSelect, tableData: [] },
      () => {
        this.setState({
          tableData: this.state.tableDataDup,
          checkCount: 0,
          checkAll: false,
        });
      }
    );
  }

  getHousekeepingApprovals(request: any) {
    this.setState({ loaderTrue: true });
    LaborDayApprove.LaborApproveServiceList(request)
      .then(async (result: any[] | null) => {
        if (result != null) {
          if (result.length > 0) {
            this.setState({ columnDetails: result });
          }
          this.setState({ loaderTrue: false });
        } else {
          this.setState({ loaderTrue: false });
        }

        resolve();
      })
      .catch((error) => {
        reject();
        this.setState({ loaderTrue: false });
      });

    LaborDayApprove.GetLaborRoomApprovalDays(request)
      .then(async (result: any[] | null) => {
        let data = [];
        if (result != null) {
          if (result.length > 0) {
            data = Object.assign([], JSON.parse(JSON.stringify(result)));
            // eslint-disable-next-line array-callback-return
            data.map((item: any) => {
              item.isCheck = false;
              item.isBtnSkip = true;
              item.skipNotes = "";
              item.action = "";
              item.occupiedRoom = item.roomoccupied;
              item.roomOccupiedChange = false;
              item.isActualEntry = "No";
              if (item.type.toLowerCase() === "actual") {
                item.isActualEntry = "Yes";
              } else {
                item.occupiedRoom =
                  item.roomoccupied === 0 ? "" : item.roomoccupied;
              }
            });
          }
        }
        this.setState({ tableData: [] }, () => {
          this.setState({ tableData: data, allData: data });
        });
        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error) => {
        reject();
        this.setState({ loaderTrue: false });
      });
  }

  handleOnExpand = (row: any, isExpand: any, rowIndex: any, e: any) => {
    this.setState({ date: row.actualdate });
    if (isExpand) {
      row.action = "Expended";
      this.setState({ isExpand: true });
      this.setState(() => ({
        tableExpended: [...this.state.tableExpended, row.id],
      }));
      this.setState({ expendedRow: row });
    } else {
      row.action = "";
      this.setState({ isExpand: false });
      this.setState(() => ({
        tableExpended: this.state.tableExpended.filter(
          (x: any) => x !== row.id
        ),
      }));
    }
  };

  handleClose() {
    this.setState({ show: false, skipNotes: "" });
  }

  handleShow() {
    this.setState({ show: true });
  }

  checkBoxEvent(e: any, row: any, rowIndex: any) {
    let array = [];
    let arrayTotal = [];
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableData))
    );
    data[rowIndex].isCheck = e.target.checked;
    data[rowIndex].action = "";
    array = data.filter((row) => row.isCheck === true);
    arrayTotal = data.filter((row) => row.totalassign === 0);
    if (array.length > 0 && array.length !== arrayTotal.length) {
      $("#checkAll").prop("checked", false).prop("indeterminate", true);
    } else {
      $("#checkAll").prop("checked", false).prop("indeterminate", false);
    }
    this.setState({
      tableData: data,
      checkCount: array.length,
      checkAll: array.length === arrayTotal.length ? true : false,
    }); 
  }

  handleFooterClose() {
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableData))
    );
    // eslint-disable-next-line array-callback-return
    data.map((item: any) => {
      item.isCheck = false;
    });
    this.setState(
      { tableData: [], checkCount: 0, checkAll: false, isBulkSelect: false },
      () => {
        this.setState({ tableData: data });
      }
    );
  }

  skipNotesChange(e: any) {
    this.setState({ skipNotes: e.target.value });
  }

  skipNotesChangeRow(e: any, row: any, rowIndex: any) {
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableData))
    );
    data[rowIndex].skipNotes = e.target.value;
    this.setState({ tableData: data });
  }

  onOutsideClick = (e, row, rowIndex) => {
    let data = this.state.tableData;
    data[rowIndex].isBtnSkip = true;
    data[rowIndex].skipNotes = "";
    this.setState({ tableData: [] }, () => {
      this.setState({ tableData: data, tableExpended: [], isExpand: false });
    });
  };

  skipClick(e: any, row: any, rowIndex: any) {
    let data = this.state.tableData;
    data[rowIndex].isBtnSkip = false;
    this.setState({ tableData: [] }, () => {
      this.setState({ tableData: data });
      this.ApproveSetFocus(rowIndex);
    });
  }

  ApproveSetFocus = (rowIndex: any) => {
    $("#dropdown-more" + rowIndex).click();
  };

  onSkipSubmit(e: any, row: any, rowIndex: any) {
    if (row.skipNotes.trim().length > 0) {
      let skipDetail: any[] = [];
      let newItem: any = {};
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : (storage.tenantID as any);
      newItem.hid = row.hotelid;
      newItem.date = row.actualdate;
      newItem.tenantid = tenantID;
      newItem.notes = row.skipNotes;
      skipDetail.push(newItem);
      this.updateSkipDetails(skipDetail);
      let data = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.tableData))
      );
      data[rowIndex].skipNotes = "";
      this.setState({ tableData: data });
    } else {
      Utils.toastError("Please enter skip notes.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }

  handleMultiSkip() {
    if (this.state.skipNotes.trim().length > 0) {
      let skipDetail: any[] = [];
      let array = this.state.tableData.filter(
        (row: { isCheck: boolean }) => row.isCheck === true
      );
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : (storage.tenantID as any);
      // eslint-disable-next-line array-callback-return
      array.map((item: any) => {
        let newItem: any = {};
        newItem.hid = item.hotelid;
        newItem.date = item.actualdate;
        newItem.tenantid = tenantID;
        newItem.notes = this.state.skipNotes;
        skipDetail.push(newItem);
      });
      this.updateSkipDetails(skipDetail);
    } else {
      Utils.toastError("Please enter skip notes.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }

  updateSkipDetails(skipDetail: any[]) {
    this.setState({ loaderTrue: true });
    LaborDayApprove.HousekeepingApprovalsSkip(skipDetail)
      .then(async (result: any | null) => {
        if (result != null) {
          if (result.message === "Success") {
            toast.success("Housekeeping approvals successfully skip.", {
              // // position: toast.POSITION.BOTTOM_RIGHT,
            });
            this.setState({ tableExpended: [], isExpand: false });
            this.props.refreshData();
          } else {
            Utils.toastError(result.message, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error: any) => {
        this.setState({ loaderTrue: false });
        reject();
      });
  }

  checkAllEvent(e: any) {
    let array = [];
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableData))
    );
    // eslint-disable-next-line array-callback-return
    data.map((item: any) => {
      if (item.totalassign === 0) {
        item.isCheck = e.target.checked;
      }
    });
    array = data.filter((row) => row.isCheck === true);
    this.setState(
      { tableData: [], checkCount: array.length, checkAll: e.target.checked },
      () => {
        this.setState({ tableData: data });
      }
    );
  }

  refreshData() {
    this.setState({ tableExpended: [], isExpand: false });
    this.props.refreshData();
  }

  allHotelBreadCrumb = (e: any) => {
    this.props.housekeepingApprovalHIDBreadCrumbs("allHotels");
  };

  changeOccupiedRoows(e: any, row: any, rowIndex: any) {
    let val = e.target.value;
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableData))
    );

    if (/^\d+$/.test(val) === false) {
      val = val.replace(/[^\d]/g, "");
    }
    if (val.length > 3) {
      let a = val.replace(/[^\d]/g, "");
      val = a.substring(0, val.length - 1);
    }
    data[rowIndex].occupiedRoom = val;
    data[rowIndex].roomOccupiedChange = true;
    this.setState(
      {
        tableData: data,
      },
      () => {
         
      }
    );
  }
  saveBudgetCalculation = (date: any, hidValue: any) => {
    let request: any = {};
    let currentDate: any = null;
    currentDate = date;
    request.hotelID = hidValue;
    request.layout = "2";
    request.date = currentDate;
    request.type = "Housekeeping";
    LaborBudgetSetting.SaveBudgetCalculation(request)
      .then(async (result: any | null) => {})
      .catch((err: any) => {});
  };

  saveHousekeepingManualOccupiedRooms = (e: any, row: any, rowIndex: any) => {
    let oldData = this.state.allData;
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableData))
    );
    if (!row.roomOccupiedChange) {
      return;
    }
    if (row.occupiedRoom === "") {
      Utils.toastError(
        "Please enter rooms occupied. Rooms Occupied can't be blank."
      );
      data[rowIndex].occupiedRoom = oldData[rowIndex].occupiedRoom;
      data[rowIndex].roomOccupiedChange = false;
      this.setState({
        tableData: data,
      });
      return;
    }
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID);
    let requestArray: any = [];
    let request: any = {};
    request.Hid = this.state.isLetterId === ""? this.state.hidValue: this.state.isLetterId;
    request.Tenantid = tenantID;
    request.Date = row.actualdate;
    request.RoomTotal = row.occupiedRoom;
    request.type = "";
    requestArray.push(request);

    LaborDayApprove.saveHousekeepingManualOccupiedRooms(requestArray)
      .then(async (result: any | null) => {
        if (result != null) {
          if (result.message === "Success") {
            toast.success("Total occupied rooms saved successfully.", {
              // // position: toast.POSITION.BOTTOM_RIGHT,
            });
            this.saveBudgetCalculation(row.actualdate, this.state.isLetterId === ""? this.state.hidValue: this.state.isLetterId);
          }
          data[rowIndex].roomOccupiedChange = false;
          data[rowIndex].action = "";
          this.setState({
            // noDailyActivity:showBanner,
            tableData: data,
            allData: data,
            tableExpended: [],
            isExpand: false,
          });
          // this.selectHotelDropDown({hotelID:this.state.hidValue,lettercode:this.state.hotelName})
        }
      })
      .catch((error) => {
        Utils.toastError(`Server Error, ${error}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "scheduleMain",
        });
        reject(); 
      });
  };

  render() {
    const rowClasses = (row: { action: string }, rowIndex: any) => {
      if (this.state.isExpand === true && row.action === "Expended") {
        return "row-Expended";
      } else if (this.state.isExpand === true) {
        return "row-Collapsed";
      } else {
        return "row-Action";
      }
    };

 

    const expandRow = {
      onlyOneExpanding: true,
      renderer: (row: any) => (
        <div className="rowExpended" id="laborDayDetails">
          <HousekeepingApprovalsDetails
            hidValue={row.hotelid}
            columnDetails={this.state.columnDetails}
            date={row.actualdate}
            assigned={row.totalassign}
            refreshData={this.refreshData.bind(this)}
            row={row}
          />
        </div>
      ),
      expanded: this.state.tableExpended,
      onExpand: this.handleOnExpand,
      showExpandColumn: true,
      expandByColumnOnly: true,
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <span className="chevronExpended">
              <img src={chevronRight} alt="chevron Right" />
            </span>
          );
        }
        return (
          <span className="chevronCollpsed">
            <img src={chevronRight} alt="chevron Right" />
          </span>
        );
      },
    };

    const columns = [
      {
        dataField: "",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => {
          const stopPropagation = (e: { stopPropagation: () => any }) =>
            e.stopPropagation();
          if (this.state.isBulkSelect && row.totalassign === 0) {
            return (
              <div className="overBudgetDeptsData" onClick={stopPropagation}>
                <Form.Check
                  custom
                  type="checkbox"
                  key={"custom" + rowIndex}
                  id={"custom" + rowIndex}
                  label=""
                  checked={row.isCheck}
                  onChange={(e: any) => this.checkBoxEvent(e, row, rowIndex)}
                />
              </div>
            );
          }
        },
      },

      {
        dataField: "",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="overBudgetDeptsData">
            <span className="overBudgetDeptsCount">{row.day}</span>
            <span className="overBudgetDeptsLabel">{row.date}</span>
          </div>
        ),
      },
      {
        dataField: "",
        text: "",
        formatExtraData: { otherRowExpanede: this.state.tableExpended },
        formatter: (cell: any, row: any, rowIndex: any, extraData) => {
          let readOnly = false;
          if (
            extraData.otherRowExpanede.length > 0 &&
            extraData.otherRowExpanede[0] !== row.id
          ) {
            readOnly = true;
          }
          const stopPropagation = (e: { stopPropagation: () => any }) =>
            e.stopPropagation();
          if (row.isActualEntry !== "Yes") {
            return (
              <div className="main-ttl" onClick={stopPropagation}>
                <input
                  id={`occupiedRooms-${rowIndex}`}
                  type="text"
                  readOnly={readOnly}
                  className="form-control"
                  style={{ fontFamily: "OpenSans" }}
                  value={row.occupiedRoom}
                  onChange={(e) => this.changeOccupiedRoows(e, row, rowIndex)}
                  onBlur={(e) => {
                    this.saveHousekeepingManualOccupiedRooms(e, row, rowIndex);
                  }}
                  autoComplete="off"
                />
                <span
                  className="overBudgetDeptsLabel"
                  style={{
                    fontFamily: "opensans",
                    color: "#6a6e73",
                    fontSize: "12px",
                  }}
                >
                  Rooms Occupied
                </span>
              </div>
            );
          } else {
            return (
              <div className="overBudgetDeptsData">
                <span className="overBudgetDeptsCount">{row.occupiedRoom}</span>
                <span className="overBudgetDeptsLabel">Rooms Occupied</span>
              </div>
            );
          }
        },
      },
      {
        dataField: "",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="overBudgetDeptsData">
            <span className="overBudgetDeptsCount">{row.totalassign}</span>
            <span className="overBudgetDeptsLabel">Assigned</span>
          </div>
        ),
      },
      {
        dataField: "",
        text: "",
        editable: false,
        formatter: (cell: any, row: any, rowIndex: any) => {
          const stopPropagation = (e: { stopPropagation: () => any }) =>
            e.stopPropagation();
          if (row.isBtnSkip) {
            return (
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn-outline-primary btn btn-primary"
                  onClick={(e)=>this.handleOnExpand(row, row.action==="",rowIndex,e)}
                >
                  Approve
                </button>
                <Dropdown
                  className="more-action"
                  alignRight
                  // onClick={stopPropagation}
                >
                  <Dropdown.Toggle
                    className="btn-outline-primary btn btn-primary more"
                    id="dropdown-more"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                      <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                      <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                    </svg>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {row.totalassign === 0 && (
                      <Dropdown.Item
                        eventKey={"Skip"}
                        onClick={(e: any) => this.skipClick(e, row, rowIndex)}
                      >
                        Skip
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item
                      eventKey={"Skip"}
                      onClick={this.bulkSelectClick}
                    >
                      Bulk Select
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            );
          } else {
            return (
              <OutsideClickHandler
                onOutsideClick={(e) => {
                  this.onOutsideClick(e, row, rowIndex);
                }}
              >
                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn-outline-primary btn btn-primary"
                    onClick={(e)=>this.handleOnExpand(row, row.action==="",rowIndex,e)}
                  >
                    Approve
                  </button>
                  <Dropdown
                    className="more-action"
                    alignRight
                    // onClick={stopPropagation}
                  >
                    <Dropdown.Toggle
                      className="btn-outline-primary btn btn-primary more"
                      id={"dropdown-more" + rowIndex}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu rootCloseEvent="click" show={true}>
                      <div
                        className="flayoutApproveMenu"
                        ref={this.approveDepartment}
                      >
                        <div className="approveDepartmentDetails">
                          <div className="entry-body">
                            <Form.Group controlId="exampleForm.ControlTextarea1">
                              <Form.Label>
                                Skip Housekeeping Approval
                              </Form.Label>
                              <Form.Control
                                as="textarea"
                                value={row.skipNotes}
                                onChange={(e: any) =>
                                  this.skipNotesChangeRow(e, row, rowIndex)
                                }
                                placeholder="Explain why you are skipping this approval"
                                rows={3}
                                maxLength={200}
                              />
                            </Form.Group>
                          </div>
                          <div className="action-link">
                            <Dropdown.Item
                              className="btn wht-bg dropdown-item cancel"
                              onClick={(e) => {
                                this.onOutsideClick(e, row, rowIndex);
                              }}
                            >
                              Cancel
                            </Dropdown.Item>
                            <Button
                              className="btn btn-primary dropdown-item approve"
                              onClick={(e) => {
                                this.onSkipSubmit(e, row, rowIndex);
                              }}
                            >
                              Submit
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </OutsideClickHandler>
            );
          }
        },
      },
    ];
    return (
      <>
        {!this.state.loaderTrue ? (
          <>
            {/* <ToastContainer autoClose={3000} /> */}

            <div className="smileIdOverview">
              <div className="smileIDOverviewActionHeader">
                {this.state.hidValue === "All" && (
                  <span className="smileIDBreadCrumbs">
                    <span onClick={this.allHotelBreadCrumb}>
                      {" "}
                      &laquo; All Properties{" "}
                    </span>
                  </span>
                )}
              </div>
            </div>
            <div className="laborDayApproval roomassignments housekeepingList">
              <div className="laborDayApprovalActionHeader">
                <span className="laborHeader">
                  {this.state.hidValue === "All" ? this.state.isLetterCode : ""}{" "}
                  Housekeeping Days to Approve
                </span>
              </div>
              <div className="laborDayApprovalTable disable-expand-cell">
                <BootstrapTable
                  keyField="id"
                  data={this.state.tableData}
                  columns={columns}
                  expandRow={expandRow}
                  rowClasses={rowClasses}
                  hover
                />
              </div>
              <div>
                <Modal
                  className="flayoutApproveMenu skipModal"
                  show={this.state.show}
                  onHide={this.handleClose}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Skip Housekeeping Approvals</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="approveDepartmentDetails">
                    <div className="entry-body">
                      <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Skip Housekeeping Approval</Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Explain why you are skipping these approvals"
                          maxLength={200}
                          id="skipNotes"
                          rows={3}
                          value={this.state.skipNotes}
                          onChange={(e: any) => this.skipNotesChange(e)}
                        />
                      </Form.Group>
                    </div>
                  </Modal.Body>
                  <Modal.Footer className="approveDepartmentDetails">
                    <div className="action-link">
                      <Button
                        className="btn btn-primary dropdown-item approve"
                        onClick={this.handleMultiSkip}
                      >
                        Skip Approvals
                      </Button>
                    </div>
                  </Modal.Footer>
                </Modal>
              </div>
              {this.state.isBulkSelect && (
                <div className="footer-sec">
                  <div className="d-flex align-items-center height-60">
                    <div>
                      <div className="d-flex">
                        <Form.Check
                          custom
                          type="checkbox"
                          key={"checkAll"}
                          id={"checkAll"}
                          label=""
                          checked={this.state.checkAll}
                          onChange={(e: any) => this.checkAllEvent(e)}
                        />
                        <div className="selected-count">
                          {this.state.checkCount}{" "}
                          {this.state.checkCount > 1 ? "Days" : "Day"} Selected
                        </div>
                      </div>
                    </div>
                    <div className="mr-auto approve-btn">
                      {this.state.checkCount > 0 && (
                        <button
                          type="button"
                          className="btn-outline-primary btn btn-primary"
                          onClick={this.handleShow}
                        >
                          Skip Approvals
                        </button>
                      )}
                    </div>
                    <div>
                      <button
                        type="button"
                        className="cross-btn"
                        onClick={this.handleFooterClose}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                            fill="white"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <ReactPageLoader useas={"HousekeepingDays"} />
        )}
      </>
    );
  }
}
