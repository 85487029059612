
import React from 'react';
import { Container, Row, Col, Button, Form, ProgressBar } from 'react-bootstrap';
import PageLoader from '../../Common/Components/PageLoader';
import { ForgotPassword as PwdService } from "../../Common/Services/ForgotPasswordService";
import { resolve, reject } from "q";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { Redirect } from "react-router-dom";

const strongRegex = new RegExp("^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&?*.<>:_{}])(?=.{8,})");
const mediumRegex = new RegExp("^(((?=.*[A-Z]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

export class ResetPassword extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      errorMessage: "",
      OlderrorMessage: "",
      loaderTrue: false,
      confirmPwdlen: 0,
      filledColor: 'default',
      filledArea: 0,
      passwordStrength: '',
      user_UniqueID: 0,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    }
    this.handleContinue = this.handleContinue.bind(this);
    this.analyze = this.analyze.bind(this);
  }


  handleChangeOldPassword = (event: any) => {
    let OldPassword = event.target.value;
    this.setState({ oldPassword: event.target.value.length })
    let OlderrorMessage;
    event.preventDefault();
    if (OldPassword === "") {
      OlderrorMessage = "Old Password can't be blank";
      this.setState({ loaderTrue: false, OlderrorMessage: OlderrorMessage });
    }
    else {
      OlderrorMessage = "";
      this.setState({ loaderTrue: false, OlderrorMessage: OlderrorMessage });
    }
  }

  handleChange = (event: any) => {
    let errorMessage, OlderrorMessage;
    event.preventDefault();
    let newPassword = this.state.newPassword;
    let oldPassword = this.state.oldPassword;
    let ConfirmPassword = event.target.value;
    this.setState({ confirmPassword: ConfirmPassword })
    if (oldPassword === "") {
      OlderrorMessage = "Old Password can't be blank";
      this.setState({ loaderTrue: false, OlderrorMessage: OlderrorMessage });
    }

    if (ConfirmPassword === "") {
      errorMessage = "Confirm Password can't be blank";
      this.setState({ loaderTrue: false, errorMessage: errorMessage });
    }
    else if (newPassword !== ConfirmPassword) {
      errorMessage = "Passwords do not match";
      this.setState({ loaderTrue: false, errorMessage: errorMessage });
    }
    else if (newPassword === ConfirmPassword) {
      errorMessage = "";
      this.setState({ loaderTrue: false, errorMessage: errorMessage });
    }
  }

  analyze = (event: any) => {
    this.setState({ newPassword: event.target.value })
    let errorMessage, OlderrorMessage;
    let filledColor
    let filledArea
    let passwordStrength
    let newPassword = event.target.value
    let ConfirmPassword = this.state.confirmPassword;
    let OldPassword = this.state.oldPassword;

    // this is for Filled area
    if (newPassword.length < 3) {
      filledArea = 0
    }
    else if (newPassword.length >= 3 && newPassword.length < 6) {
      filledArea = 33
    }
    else if (newPassword.length >= 6 && newPassword.length < 8) {
      filledArea = 66
    }
    else {
      filledArea = 100
    }
    if (OldPassword === "") {
      OlderrorMessage = "Old Password can't be blank";
      this.setState({ loaderTrue: false, OlderrorMessage: OlderrorMessage });

    }
    else if (newPassword !== ConfirmPassword && ConfirmPassword !== "") {
      errorMessage = "Passwords do not match";
      this.setState({ loaderTrue: false, errorMessage: errorMessage });
    }
    else if (newPassword === ConfirmPassword) {
      errorMessage = "";
      this.setState({ loaderTrue: false, errorMessage: errorMessage });
    }


    // this is for Regex 
    if (strongRegex.test(event.target.value)) {
      filledColor = 'success'
      passwordStrength = 'Strong password'
    } else if (mediumRegex.test(event.target.value)) {
      filledColor = 'info'
      passwordStrength = 'Could be stronger'
    } else {
      filledColor = 'warning'
      passwordStrength = 'Too weak'
    }
    this.setState({
      filledColor,
      passwordStrength,
      filledArea
    })
  }

  handleContinue(event: any) {
    event.preventDefault();
    const form = event.target;
    const OldPassword = form.elements.oldPassword.value;
    const newPassword = form.elements.newPassword.value;
    const confirmPassword = form.elements.confirmPassword.value;
    let errorMessage, OlderrorMessage;
    if (OldPassword === "" || newPassword === "" || confirmPassword === "") {
      if (OldPassword === "") {
        OlderrorMessage = "Old Password can't be blank";
      }

      else if (confirmPassword === "") {
        errorMessage = "Confirm Password can't be blank";
      }

    } else if (newPassword !== confirmPassword) {
      errorMessage = "Passwords do not match";
    } else {
      this.setState({ loaderTrue: true });

      let User_UniqueID = this.state.user_UniqueID;

      PwdService.ResetPassword(User_UniqueID, OldPassword, newPassword)
        .then((response: any | null) => {

          if (response.message === "InvalidUser") {
            errorMessage = "Invalid User.";
            this.setState({ loaderTrue: false, errorMessage: errorMessage });
          } else if (response.message === "InvalidOldPassword") {
            OlderrorMessage = "Old password is not correct.";
            this.setState({ loaderTrue: false, OlderrorMessage: OlderrorMessage });
          }
          else if (response.message === "AlreadyUsedPassword") {
            errorMessage = "You cannot re-use passwords. Please choose a unique password.";
            this.setState({ loaderTrue: false, errorMessage: errorMessage });
          }
          else if (response.message === "Success") {
            errorMessage = "Password changed successfully.";
            this.setState({ loaderTrue: false, errorMessage: '' });
            toast.success(errorMessage, {
              // // position: toast.POSITION.BOTTOM_RIGHT
            });
          }
          else if (response.message === "Locked") {
            alert("Your account has been locked due to 5 invalid attempts. Please try again after 15 minutes or contact Support/Administrator.");
            localStorage.clear();
            this.props.history.push("/login");
          }

          resolve();
        })
        .catch(error => {
          this.setState({ loaderTrue: false });
          reject();
        });
    }
    this.setState({ loaderTrue: false, errorMessage: errorMessage, OlderrorMessage: OlderrorMessage });
  }

  componentDidMount() {
    let storageKey = JSON.parse(localStorage.getItem('storage')!);
    this.setState({ user_UniqueID: storageKey.user_UniqueID })


  }


  render() {
    const { passwordStrength, confirmPassword, newPassword, oldPassword } = this.state;
    let loaderTrue = this.state.loaderTrue;

    let loadShow;
    if (loaderTrue) {
      loadShow = <PageLoader />;
    }
    const isEnabled = passwordStrength === "Strong password" && newPassword === confirmPassword && oldPassword !== "";
    return (
      <Container fluid className="body-sec">
        {/* <ToastContainer autoClose={3000} /> */}
        {loadShow}
        <div className="page-heading">
          Change Password
          <div className="sub-page-section">
            <p>Strong passwords are important! Please be sure to choose a password that meets the following criteria:</p>
            <p>
              <ul>
                <li>Is at least eight (8) characters long</li>
                <li>Has at least one uppercase letter</li>
                <li>Has at least one number and/or special character (such as punctuation marks, mathematical and other conventional symbols)</li>
                <li>Is unique (you have not used this password before)</li>
              </ul>
            </p>
          </div>
        </div>
        <Row>
          <Col md={{ span: 6 }}>
            <Form
              className="frm-section was-validated"
              name="forgotForm"
              onSubmit={this.handleContinue}
            >
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Old Password</Form.Label>
                <Form.Control
                  type="password"
                  id="oldPassword"
                  name="oldPassword"
                  placeholder="Enter old password"
                  onChange={this.handleChangeOldPassword}
                />
                <span className="invalid-feedback">{this.state.OlderrorMessage}</span>
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  type="password"
                  value={this.state.password}
                  onChange={this.analyze}
                  id="newPassword"
                  name="newPassword"
                  placeholder="Enter new password"
                />

                <Row>
                  <Col>
                    <Row>
                      <Col md={{ span: 8 }}><ProgressBar variant={this.state.filledColor} now={this.state.filledArea} /></Col>
                      <Col md={{ span: 4 }}><div className="progress-lbl">{this.state.passwordStrength}</div></Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group controlId="formBasicEmail">
                <Form.Label>Enter Password Again</Form.Label>
                <Form.Control
                  type="password"
                  onChange={this.handleChange}
                  name="confirmPassword"
                  id="confirmPassword"
                  placeholder="Enter confirm password"
                />
                <span className="invalid-feedback">{this.state.errorMessage}</span>
              </Form.Group>

              <div className="Forgot-action">
                {/* <Button
                  variant="primary"
                  type="button"
                  className="btn-outline-primary"
                >
                  Cancel
      </Button> */}
                <Button variant="primary" type="submit" disabled={!isEnabled}>
                  Change Password
                </Button>


              </div>
            </Form>
          </Col>
        </Row>

      </Container>
    );
  }
}
