import React from "react";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import BootstrapTable from "react-bootstrap-table-next";

export class ChieldLocationSlideOut extends React.Component<any, any> {

    constructor(props: any) {
        super(props);       
        this.state = {
            // tableProceuresData:[{name:'abc',asset:'open',userUniqueno:1,rowNo:1},
            // {name:'abc',asset:'open',userUniqueno:2,rowNo:2}],

            chiledRoomTypeDetails:this.props.parentState.locationChieldData,
           
        };
      }

      componentDidMount() {
       //debugger;
        let data=[...this.state.chiledRoomTypeDetails];
        let row=this.props.row;
        let filterData=data.filter(
          (x: any) => x.selectID == row.selectID && x.type.toLowerCase()!=="roomtype"
        );

        this.setState({chiledRoomTypeDetails:filterData});
      }
    

      render() {  
        const columns = [
            {
              dataField: "name",
              text: "Name",
              formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                  <>
                    <EllipsisWithTooltip placement="bottom">
                      {row.name}
                    </EllipsisWithTooltip>
                  </>
                );
              },
      
            },
            // {
            //     dataField: "asset",
            //     text: "Asset",
            //     formatter: (cell: any, row: any, rowIndex: any) => {
            //       return (
            //         <>
            //           <EllipsisWithTooltip placement="bottom">
            //             {row.asset}
            //           </EllipsisWithTooltip>
            //         </>
            //       );
            //     },
        
            //   },
      
           
            // {
            //   dataField: "",
            //   text: "",
            //   formatter: (cell: any, row: any, rowIndex: any) => (
            //     <Dropdown
            //       // onClick={(e) => {
            //       //   e.stopPropagation();
            //       // }}
            //       className="more-action position-static"
            //       alignRight
            //     >
            //       <Dropdown.Toggle
            //         className="btn-outline-primary btn btn-primary btn-ghost more"
            //         id="dropdown-more"
            //         disabled={!this.state?.createEditPermission || selectedRows?.length > 0}
            //       >
            //         <svg
            //           width="20"
            //           height="20"
            //           viewBox="0 0 20 20"
            //           fill="none"
            //           xmlns="http://www.w3.org/2000/svg"
            //         >
            //           <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
            //           <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
            //           <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
            //         </svg>
            //       </Dropdown.Toggle>
            //       <Dropdown.Menu>
            //         {(Number(row.inUse) !== 1) &&
            //           <Dropdown.Item
            //             onClick={() => this.hanldeDeleteConfirmation(row.id)} >
            //             Delete
            //           </Dropdown.Item>
            //         }
            //         <Dropdown.Item
            //           onClick={() => this.hanldeArchiveConfirmation([row.id])} >
            //           Archive
            //         </Dropdown.Item>
            //       </Dropdown.Menu>
            //     </Dropdown>
            //   ),
            // },
          ];
       
        return (
          <>
    
    <div>
           
           
              <div 
              // id="laborDayApprovalOuter"
              className="table-container"
              >
                <BootstrapTable
                  keyField="rowNo"
                  data={this.state.chiledRoomTypeDetails}
                  columns={columns}
                 // expandRow={expandRow}
                 // rowClasses={rowClasses}
                 // hover
                />
              </div>
          
          </div>
          
          </>
          );
        }

}