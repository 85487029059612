import * as React from "react";
import {
  Button,
  Form,
  // OverlayTrigger, Tooltip
} from "react-bootstrap";
import OutsideClickHandler from "react-outside-click-handler";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";

export class DropDownMultiListPopUp extends React.Component<any, any> {
  private btnSection: any;
  private searchSection: any;
  constructor(props: any) {
    super(props);
    this.btnSection = React.createRef();
    this.searchSection = React.createRef();
    this.state = {
      selectedItems: [],
      filterData: [],
      opened: false,
      itemList: props.data,
      isSelectAll: props.isSelectAll,
      filterTitle: props.filtertitle,
      isSelect: true,
      inputValue: "",
      childClick: false,
    };
  }

  componentDidMount = () => {
    const activeChip = this.state.itemList.filter(
      (itemList: { isChecked: boolean }) => itemList.isChecked === true
    );
    this.setState({
      selectedItems: activeChip,
      filterData: this.state.itemList,
    });
    // this.props.updateMultiSelectItem(activeChip);
  };

  componentDidUpdate = (prevProps) => {
    const { defaultValue } = this.props;
    if (!defaultValue && prevProps?.defaultValue !== defaultValue) {
      let items = [...this.state.itemList];
      items = items.map((r) => {
        r.isChecked = false;
        return r;
      });

      this.setState({
        isSelect: true,
        selectedItems: [],
        filterData: items,
      });
    }
  };

  onChipMouseOver = (): void => {
    this.setState({ childClick: true });
  };
  onChipMouseOut = (): void => {
    this.setState({ childClick: false });
  };

  /**For remove COA(s) list **/
  handleRemoveSpecificChip = (idx: number) => () => {
    this.setState({ childClick: true });
    const { value } = this.props;
    this.props.dataChange();
    const itemList = [...this.state.itemList];
    const selectedItems = [...this.state.selectedItems];
    const removeSelect = this.getIndex(selectedItems[idx][value], "removeChip");
    itemList[removeSelect].isChecked = false;
    selectedItems.splice(idx, 1);
    this.setState({ selectedItems, itemList });

    const coaSelectedItem = this.state.itemList.filter(
      (itemList: { isChecked: boolean }) => itemList.isChecked === true
    );
    this.props.updateMultiSelectItem(coaSelectedItem);
    this.setState({ childClick: false });
  };

  /************checkbox check unchek********/
  handleCheckCheckbox = (idx: any) => (event: any) => {
    const { value } = this.props;
    this.props.dataChange();
    const itemList = [...this.state.itemList];
    const index = itemList.findIndex((xvalue) => xvalue[value] === idx);
    itemList[index].isChecked = event.target.checked;
    const selectedItems = itemList.filter((r) => r.isChecked);
    this.setState({ selectedItems, itemList });
    this.props.updateMultiSelectItem(selectedItems);
  };
  //Get array Index
  getIndex = (val: any, type: string) => {
    const { value } = this.props;
    if (type === "removeChip") {
      let itemList = [...this.state.itemList];
      return itemList.findIndex((obj) => obj[value] === val);
    } else {
      let selectedItems = [...this.state.selectedItems];
      return selectedItems.findIndex((obj) => obj[value] === val);
    }
  };
  handleChange = (e: { target: { value: string } }) => {
    const {
      // value,
      label,
    } = this.props;
    const updatedList = this.state.itemList.filter((item) => {
      return (
        item[label]
          ?.toString()
          ?.toLowerCase()
          ?.indexOf(e.target.value?.toLowerCase()) > -1
      );
    });

    this.setState({ filterData: updatedList, inputValue: e.target.value });
  };

  updateSavedItem = (data: any) => {
    const { value } = this.props;
    let allCoaList = [...this.state.itemList];
    allCoaList.forEach((element) => {
      element.isChecked = false;
    });
    this.setState({ itemList: allCoaList });
    data.forEach((element: { value: any }) => {
      this.state.itemList.filter(
        (itemList: { value: any }) => itemList[value] === element[value]
      )[0].isChecked = true;
    });
    this.setState({ selectedItems: data });
  };
  handleClick = () => {
    if (this.state?.childClick === true) {
      return false;
    }

    this.setState((prevState) => ({
      opened: !prevState.opened,
    }));

    this.setState({
      filterData: this.state.itemList,
    });
    this.setState({ inputValue: "" });
  };

  handleOutsideClick = (e) => {
    this.setState((prevState) => ({
      opened: false,
    }));
    this.setState({ inputValue: "" });
  };

  handleSelectAll = (e: any) => {
    this.props.dataChange();
    let allItemList = [...this.state.itemList];
    allItemList.forEach((element) => {
      element.isChecked = true;
    });
    this.setState({ itemList: allItemList, isSelect: false, opened: false });
    this.setState({ selectedItems: allItemList });
    this.props.updateMultiSelectItem(allItemList);
  };

  handleClearAll = (e: any) => {
    this.props.dataChange();
    let allItemList = [...this.state.itemList];
    allItemList.forEach((element) => {
      element.isChecked = false;
    });
    this.setState({ itemList: allItemList, isSelect: true });
    this.setState({ selectedItems: [] });
    this.props.updateMultiSelectItem([]);
  };

  render() {
    const { label, value } = this.props;
    return (
      <OutsideClickHandler onOutsideClick={this.handleOutsideClick}>
        <div
          className={
            this.state.opened
              ? "multiSelectSearch add-entity opened"
              : "multiSelectSearch add-entity"
          }
        >
          <div
            className="fieldArea"
            onMouseOut={this.onChipMouseOut}
            onClick={this.handleClick}
          >
            <Button className="btn-ghost add-entity-btn">
              {this.props?.placeHolder}
            </Button>

            <div
              className="coaSearch"
              onMouseOver={this.onChipMouseOver}
              ref={(searchSection) => {
                this.searchSection = searchSection;
              }}
            >
              <div className="dropDownPanel">
                <div className="SearchInput">
                  <input
                    key={"text-Filter"}
                    type="text"
                    className="form-control"
                    onChange={this.handleChange}
                    placeholder={this.props?.filtertitle}
                    value={this.state.inputValue}
                  />
                </div>
                <ul>
                  {this.state.filterData.map((item: any, idx: any) => (
                    // <OverlayTrigger
                    //   key={"overlay-" + idx}
                    //   placement="bottom"
                    //   delay={{ show: 250, hide: 400 }}
                    //   overlay={
                    //     <Tooltip id={"coa" + idx}>
                    //       {
                    //         this.state.itemList.filter(
                    //           (row) => row[value] === item[value]
                    //         )[0][label]
                    //       }
                    //     </Tooltip>
                    //   }
                    // >
                    <li id={item[value]} key={item[value]}>
                      <Form.Check
                        custom
                        type="checkbox"
                        key={"custom-s" + item[label]}
                        id={"custom-s" + item[label]}
                        //label={item[label]}
                        label={
                          <div className="d-flex align-items-center" style={{ width: "100%"}}>
                              <EllipsisWithTooltip placement="bottom">
                                {item[label]}
                              </EllipsisWithTooltip>
                              {item?.status != "Active" ? (
                                <span className="badge">{item?.status} </span>
                              ) : (
                                ""
                              )}
                          </div>
                        }
                        checked={item.isChecked}
                        onChange={this.handleCheckCheckbox(item[value])}
                      />
                    </li>
                    // </OverlayTrigger>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    );
  }
}
