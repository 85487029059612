import * as React from "react";
import "react-input-range/lib/css/index.css";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { ModuleReports } from "../../../Common/Services/ModuleReports";
import { resolve, reject } from "q";
// import { OverlayTrigger, Tooltip } from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { ReactPageLoader } from "../../../Common/Components/ReactPageLoader";
import TableSearch from "../../../Common/Components/TableSearch";
const { SearchBar } = Search;

export class ScheduleReportLeftPanel extends React.Component<any, any> {
  private isNewBudgetScroll: any;
  constructor(props: any) {
    super(props);
    this.isNewBudgetScroll = React.createRef();
    this.state = {
      loaderTrue: false,
      scheduleReports: [] as any,
      newScheduleReport: null,
      currentRow: {},
      isreset: false,
      selectedRow: true,
    };
  }

  getFormattedDate(date) {
    let year = date.getFullYear();

    let month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : "0" + month;

    let day = date.getDate().toString();
    day = day.length > 1 ? day : "0" + day;

    return month + "/" + day + "/" + year;
  }


  getUserScheduleReport = (newScheduleReport) => {
    this.setState({ loaderTrue: true });
    ModuleReports.getUserScheduleReport()
      .then(async (result: any | null) => {
        this.setState({ loaderTrue: false });
        if (result != null) {
          let scheduleReports = result;
          let allowedReports = [] as any;
          scheduleReports.forEach((report: any) => {
            if (report.parentReportId > 0) {
              let parentReport = this.props.allmoduleReports.filter(
                (x) => x.moduleReportId === report.parentReportId
              );
              if (parentReport.length > 0) {
                let hasPermission = this.props.reportPermissions.filter(
                  (x) =>
                    x.permissionName.toLowerCase() ===
                    parentReport[0].reportName.toLowerCase()
                );
                if (hasPermission && hasPermission.length > 0) {
                  allowedReports.push(report);
                }
              }
            } else {
              let hasPermission = this.props.reportPermissions.filter(
                (x) =>
                  x.permissionName.toLowerCase() ===
                  report.reportName.toLowerCase()
              );
              if (hasPermission && hasPermission.length > 0) {
                allowedReports.push(report);
              }
            }
          });

          if (newScheduleReport != null) {
            let newItem = {} as any;
            newItem.uniqueNo = 0;
            newItem.description = newScheduleReport.description;
            let date = new Date();
            if (JSON.parse(localStorage.storage).currentUtcTime !== "" && JSON.parse(localStorage.storage).currentUtcTime !== null && JSON.parse(localStorage.storage).currentUtcTime !== undefined) {
              date = new Date(
                JSON.parse(localStorage.storage).currentUtcTime
              );
            }
            newItem.createdDate = this.getFormattedDate(date); //"01/18/20";
            newItem.noOfReceipents = 0;
            newItem.parameters = newScheduleReport.parameters;
            allowedReports.push(newItem);
          }
          if (this.state.isreset) {

            let rowtoselect = scheduleReports.find(x => x.uniqueNo == this.state.currentRow.uniqueNo);
            if (rowtoselect) {
              this.props.showRightPanelData(rowtoselect);
            }
          }
          this.setState({ scheduleReports: allowedReports });
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ loaderTrue: false });
        reject();
      });
  };



  rowEvents = {

    onClick: (e: any, row: any, rowIndex: any) => {
      this.setState({
        currentRow: row,
      });
      //isChecked
      this.props.showRightPanelData(row);
    },
  };

  duplicateReport = (uniqueNo) => {
    let reportToCopy = this.state.scheduleReports.find(
      (x) => x.uniqueNo === uniqueNo
    );
    if (reportToCopy) {
      let scheduleReports = [...this.state.scheduleReports];
      let newItem = {} as any;
      newItem = { ...reportToCopy };
      newItem.uniqueNo = 0;
      newItem.description = "Copy of " + reportToCopy.description;
      scheduleReports.push(newItem);
      this.props.showRightPanelData(newItem);
      this.setState({ scheduleReports, currentRow: newItem });
    }
  };

  resetSelection = (currentSelection) => {
    this.setState({ isreset: true, currentRow: { uniqueNo: currentSelection } });
  };

  render() {
    const rowClasses = (row: any, rowIndex: number) => {
      if (this.state.currentRow !== null) {
        if (row.uniqueNo === this.state.currentRow.uniqueNo) {
          return "active-row";
        } else if (row.action) {
          return "faded-row";
        } else {
          return;
        }
      }
    };

    const columns = [
      {
        dataField: "description",
        text: "",
        formatter: (cell, row) => (
          <div className="">
            <EllipsisWithTooltip placement="bottom">
              <div className="title">{row.description}</div>
            </EllipsisWithTooltip>
            <div className="desc">Created {row.createdDate}</div>
          </div>
        ),
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => { },
        },
      },
      {
        dataField: "noOfRecipients",
        text: "",
        formatter: (cell, row) => (
          <div className="">
            <div className="title1">{row.noOfReceipents}</div>
            <div className="desc grey">Recipients</div>
          </div>
        ),
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => { },
        },
      },
    ];
    return (
      <>
        {this.state.loaderTrue ? (
          <ReactPageLoader useas={"reportsScheduleLeftPanel"} />
        ) : (
          <>
            <>
              {this.state.scheduleReports &&
                this.state.scheduleReports.length > 0 && (
                  <div className="left-panel">
                    <ToolkitProvider
                      keyField={`toolkitProvider_${this.state.scheduleReports.map(
                        (items) => items.uniqueNo
                      )}`}
                      data={this.state.scheduleReports}
                      columns={columns}
                      rowEvents={this.rowEvents}
                      search={{
                        afterSearch: (newResult: any) => {
                          //this.searchedData(newResult);
                        },
                      }}
                    >
                      {(props: {
                        searchProps: JSX.IntrinsicAttributes;
                        baseProps: JSX.IntrinsicAttributes;
                      }) => (
                        <div>
                          <div className="search-header">
                            {/* <SearchBar
                              {...props.searchProps}
                              placeholder={"Filterrrrr"}
                            /> */}
                            <TableSearch id="tableSearch" key="tableSearch"
                              {...props.searchProps}
                              placeholder={"Filter..."}
                            />
                          </div>
                          <div className="left-body">
                            <BootstrapTable
                              {...props.baseProps}
                              id={`tableid_${this.state.scheduleReports.map(
                                (items) => items.uniqueNo
                              )}`}
                              keyField={`bootstrapTable_${this.state.scheduleReports.map(
                                (items) => items.uniqueNo
                              )}`}
                              rowEvents={this.rowEvents}
                              hover
                              condensed
                              rowClasses={rowClasses}
                            />
                          </div>
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                )}
            </>
          </>
        )}
      </>
    );
  }
}
