import Instense from "./Axios-config";
import { ISaveNotificationPreferencesRequest } from "../Contracts/ISaveNotificationPreferencesRequest";
export class NotificationService {

    public static getNotificationModule = async (): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        let request :any ={};
        request.TenantID = tenantID;
        const url = `/Notification/GetNotificationModule`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data as any;
            return result;
        });
    };

    public static saveNotificationPreferencesData = async (
        request: ISaveNotificationPreferencesRequest
      ): Promise<any | null> => {
        const url = `/Notification/SaveNotificationUserMapping`;
        const response = await Instense.post(url, request);
        // alert(JSON.stringify(response));
        let result = response.data as any;
        return result.result;
      };

   

}