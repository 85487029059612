import * as React from "react";
import { Dropdown } from "react-bootstrap";
// import { read } from "fs";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import ReactTooltip from "react-tooltip";
import { InfoTooltip } from "../../../Common/Components/InfoTooltip";

export const DropDownListWithToolTip: React.FC<any> = (props: any) => {
  const inputElement: any = React.createRef();
  const toggleRef: any = React.createRef();

  const [placeHolder, setPlaceholder] = React.useState(props?.placeHolder);
  const [selectedItem, setSelectedItem] = React.useState(props?.selectedItem || {});
  const [data, setData] = React.useState(props?.data || []);
  const [filterData, setFilterData] = React.useState(props?.data || []);
  const [disabled, setdisabled] = React.useState(props?.disabled);
  const [inputValue, setInputValue] = React.useState("");

  React.useEffect(() => {

    const { defaultValue, value, label, shouldToggle } = props;
    if (!selectedItem && props?.selectedItem) {
      handleDropdownChange(props?.selectedItem);
    }
    else if (selectedItem) {
      if (
        defaultValue &&
        selectedItem[value] !== defaultValue &&
        data.length > 0
      ) {
        const selectedItem = data?.find((r) => r[value] === defaultValue);
        handleDropdownChange(selectedItem);
      }
    } else {
      if (data?.length > 0 && defaultValue) {
        const selectedItem = data?.find((r) => r[value] === defaultValue);
        handleDropdownChange(selectedItem);
      }
    }


  });


  const handleDropdownSearch = (e: { target: { value: string } }) => {
    const { label } = props;
    const updatedList = data.filter(
      (item) =>
        item[label]
          ?.toString()
          .toLowerCase()
          .indexOf(e.target.value.toLowerCase()) > -1
    );
    setFilterData(updatedList);
    setInputValue(e.target.value);
  };

  const hoverOutEllipsis = () => {
    $(".tooltip").removeClass("show");
  };
  const handleDropdownChange = (item: any) => {
    if (item.disabled) {
      return;
    }
    setSelectedItem(item);

    if (typeof props?.onDropDownChange === "function") {
      props?.onDropDownChange(item);
    }
    resetSerchBox();
  };

  const resetSerchBox = () => {
    setFilterData(data);
    setInputValue("");
  };

  const ddlSelectModal = (eventKey: any) => {
    setPlaceholder(eventKey);
  };

  const onTabKeyDown = (e) => {
    e.preventDefault();
    if (toggleRef.current.ariaExpanded === "false" && e?.keyCode === 9) {
      toggleRef.current.click();
      setTimeout(() => {
        $(`#ddlSpecailSearchText`)?.focus();
      }, 100);
    }
  };

  const onFocusInput = (eventKey: any) => {
    resetSerchBox();
    setTimeout(() => {
      inputElement?.current?.focus();
    }, 100);
  };

  const { label, value, showBlueBg, tabIndex } = props;

  return (
    <Dropdown
      tabIndex={tabIndex}
      className="single-select"
      onSelect={ddlSelectModal}
      onKeyUp={(e) => onTabKeyDown(e)}
      onClick={onFocusInput} >

      <Dropdown.Toggle ref={toggleRef} id={`Modal-ddl${tabIndex}`} className={showBlueBg ? "selected-blue-bg" : ""} disabled={disabled}>
        <EllipsisWithTooltip placement="top">
          {selectedItem && <div className="d-flex">
            <span
              style={{
                width: "auto",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "block",
              }}
            >
              {selectedItem[label]}

            </span>
            {selectedItem?.toolTip &&

              <span data-tip={selectedItem?.toolTip} style={{ marginLeft: 5 }}>
                <ReactTooltip
                  effect="solid"
                  event="mouseover mouseenter"
                  eventOff="mouseleave mouseout scroll mousewheel blur"
                  place="bottom"
                  multiline={true}
                />
                <InfoTooltip />

              </span>
            }
          </div>
          }
        </EllipsisWithTooltip>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {props?.isSearchRequired &&
          <div className="search-sec">
            <input
              id="ddlSpecailSearchText"
              type="text"
              className="form-control"
              onChange={handleDropdownSearch}
              placeholder={placeHolder}
              autoComplete={"ddlSpecailSearchText" + placeHolder}
              value={inputValue}
              ref={inputElement}
            />
          </div>
        }
        {filterData?.length > 0 && (
          <div className="list-area">
            {filterData.map((item: any, idx: any) => (
              // <EllipsisWithTooltip  placement="top">
              <Dropdown.Item onClick={() => handleDropdownChange(item)}
                className={`${item.disabled ? "account-status-disabled-list" : ""}`}
                onWheel={() => hoverOutEllipsis()}>
                {item[label]}
                {item?.toolTip &&
                  <span data-tip={item?.toolTip} style={{ marginLeft: 5 }}>
                    <ReactTooltip
                      effect="solid"
                      event="mouseover mouseenter"
                      eventOff="mouseleave mouseout scroll mousewheel blur"
                      place="bottom"
                      multiline={true}
                    />
                    <InfoTooltip />

                  </span>
                }
              </Dropdown.Item>
              // </EllipsisWithTooltip>
            ))}
          </div>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};
DropDownListWithToolTip.defaultProps = {
  isSearchRequired: true,
  showBlueBg: false,
  shouldToggle: false
};