import * as React from "react";
import { Container, Dropdown, Form, Modal, OverlayTrigger, Button, Spinner } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faFileImage, faFileExcel, faFilePdf, faFileCsv, faFileWord, } from "@fortawesome/free-solid-svg-icons";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import "../Accounting/Reconcile/reconcile.scss";
import { Register as registersevice } from "../../Common/Services/Register";
import { resolve, reject } from "q";
import { Utils } from "../../Common/Utilis";
import { toast } from "react-toastify";
import BootstrapTable from "react-bootstrap-table-next";

export class AzureExtraction extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      uploadFiles: [],
      headerData: [],
      splitData: [],
      isLoading: false,
    }
  }



  onDrop = (filelist: any, mode: any) => {
    this.setState({ uploadFiles: filelist }, () => {
      console.log(filelist);
    });
  };

  clearFiles = () => {
    this.setState({ uploadFiles: [], headerData: [], splitData: [], })
  }

  extractData = () => {
    this.setState({isLoading: true});
    registersevice
      .ExtractFileByAzure(this.state.uploadFiles)
      .then(async (result: any | null) => {
        // debugger;
        if (result !== null) {
          if (result[0].saveStatus.toLowerCase() === "sucess") {
            let headerData = result[0].invoiceJsonDetails;
            headerData.forEach((data: any, index: any) => {
              data.id = index + 1;
            });
            let splitData = result[0].invoiceSpiltDetails;
            splitData.forEach((data: any, index: any) => {
              data.id = index + 1;
            });
            this.setState({ headerData, splitData });
          } else {
            Utils.toastError("File is corrupted or something went wrong.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "InvoiceSlideout",
            });
          }
        } else {
          Utils.toastError("File is corrupted or something went wrong.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "InvoiceSlideout",
          });
        }
        this.setState({isLoading: false});
        resolve();
      })
      .catch((error) => {
        this.setState({isLoading: false});
        reject();
      });
  }

  render() {
    const columns = [
      {
        dataField: "id",
        text: "Sl No.",
      },
      {
        dataField: "coloumnName",
        text: "Key",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {row.coloumnName}
            </EllipsisWithTooltip>
          );
        },
      },
      {
        dataField: "coloumnValues",
        text: "Value",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {row.coloumnValues}
            </EllipsisWithTooltip>
          );
        },
      }
    ];

    const files = this.state.uploadFiles.map((file, index) => (
      <>
        <li key={file.name}>
          <div className="d-flex align-items-center justify-content-start">
            <div className="icon">
              {file?.type?.split("/")[0] === "image" && (
                <FontAwesomeIcon icon={faFileImage} />
              )}
              {file?.name.substring(
                file?.name.lastIndexOf(".") + 1,
                file?.name.length
              ) === "pdf" && <FontAwesomeIcon icon={faFilePdf} />}
              {file?.name.substring(
                file?.name.lastIndexOf(".") + 1,
                file?.name.length
              ) === "xlsx" && <FontAwesomeIcon icon={faFileExcel} />}
              {file?.name.substring(
                file?.name.lastIndexOf(".") + 1,
                file?.name.length
              ) === "csv" && <FontAwesomeIcon icon={faFileCsv} />}
              {file?.name.substring(
                file?.name.lastIndexOf(".") + 1,
                file?.name.length
              ) === "word" && <FontAwesomeIcon icon={faFileWord} />}
              {file?.type?.split("/")[0] !== "image" &&
                file?.name.substring(
                  file?.name.lastIndexOf(".") + 1,
                  file?.name.length
                ) !== "pdf" &&
                file?.name.substring(
                  file?.name.lastIndexOf(".") + 1,
                  file?.name.length
                ) !== "xlsx" &&
                file?.name.substring(
                  file?.name.lastIndexOf(".") + 1,
                  file?.name.length
                ) !== "csv" &&
                file?.name.substring(
                  file?.name.lastIndexOf(".") + 1,
                  file?.name.length
                ) !== "word" && <FontAwesomeIcon icon={faFile} />}
            </div>
            <div className="file-info d-flex align-items-center">
              <div className="name-loader d-flex align-items-center">
                <div className="name">
                  <EllipsisWithTooltip placement="bottom">
                    <div
                      className="full-name"
                    // onClick={() => {
                    //   this.handlePreview(file);
                    // }}
                    >
                      {file.name}
                    </div>
                  </EllipsisWithTooltip>
                </div>
              </div>
              <div className="formate-percentage">
                <div className="formate d-flex justify-content-center align-items-center">
                  {file?.name.substring(
                    file?.name.lastIndexOf(".") + 1,
                    file?.name.length
                  )}
                </div>
              </div>
            </div>
          </div>
        </li>
      </>
    ));
    return (
      <div className="reconciliation-page">
        <Container fluid className="body-sec reconcile">
          <div className="section upload-section">
            <div className="left-sec" style={{ maxHeight: 'auto', width: '100%', overflow: 'visible', position: 'relative', top: '0' }}>
              <div className="section upload-section">
                <div className="header-title d-flex">
                  <div className="sec-ttl">Azure Extraction Key-Value</div>
                </div>
                <div className="sec-body">
                  <Dropzone onDrop={this.onDrop}>
                    {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                      <>

                        <section className={isDragActive ? "container upload-area default drag-active " : "container upload-area default"}>
                          <div {...getRootProps({
                            className: 'dropzone d-flex align-items-center',
                            onClick: (this.state.uploadFiles.length > 0) ?
                              event => event.stopPropagation() : event => event
                          })} style={{ width: '45%' }}>
                            <input {...getInputProps()} />
                            {(this.state.uploadFiles.length <= 0) && (
                              <p>Drag and drop files or{" "} <span>browse files</span> to upload and test Azure Extraction.</p>
                            )}
                          </div>
                          {(this.state.uploadFiles.length > 0) && (
                            <aside className="d-flex flex-column">
                              <ul>{files}</ul>
                            </aside>
                          )}
                        </section>
                        {(this.state.uploadFiles.length > 0) && (
                          <div className="upload-link mrgn-top">
                            <button type="button" className="btn wht-bg link-btn" style={{ marginRight: '20px' }} onClick={this.extractData}>Extract</button>
                            <button type="button" className="btn wht-bg link-btn" onClick={this.clearFiles}>Clear</button>
                          </div>
                        )}
                        {this.state.headerData.length > 0 &&
                          <div style={{ marginTop: '1rem' }}>
                            <div className="header-title d-flex">
                              <div className="sec-ttl">Header Extracted Data</div>
                            </div>
                            <div>
                              <BootstrapTable
                                keyField="id"
                                data={this.state.headerData}
                                columns={columns}
                              />
                            </div>
                          </div>
                        }
                        {this.state.splitData.length > 0 &&
                          <div style={{ marginTop: '1rem' }}>
                            <div className="header-title d-flex">
                              <div className="sec-ttl">Split Extracted Data</div>
                            </div>
                            <div>
                              <BootstrapTable
                                keyField="id"
                                data={this.state.splitData}
                                columns={columns}
                              />
                            </div>
                          </div>
                        }
                      </>
                    )}
                  </Dropzone>
                </div>
              </div>
            </div>
          </div>
        </Container>
        {(this.state.isLoading) && (
          <div className="loader-spinner d-flex justify-content-center align-items-center">
            <Spinner animation="border" variant="success" />
          </div>
        )}
      </div>
    )
  }
}