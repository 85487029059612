import React from "react";
import { Container, Dropdown, Modal, Button, Form } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import {InvoiceEntrySlideout} from "../../Register/InvoiceEntrySlideout";
import "./reconcile.scss";
import { ReconcileService as reconcileService} from "../../../Common/Services/ReconcileService";

export class PendingReconcile extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
        isSlideOut : false,
        ColumnsData : [],
        tableColumns: [],
        pageSize: 50,
        currentPageNo: 1,
        totalDataLength:0
    };   
     
  }
  
    componentDidMount() {
        //debugger;
        this.bindPendingReconcile(true);
    }
    exportPendingReconcileSheet = () => {
        const {pageSize, currentPageNo } = this.state;
        let request: any = {};
        request.OprID = this.props.selectedOprId;
        request.Accperiod = this.props.accPerRequest;

        var rType=this.props.pageTitle;
        if(rType.toLowerCase()=== "pending deposits"){
            request.Type = "deposit";
        }
        else if(rType.toLowerCase()=== "pending withdrawals"){
        request.Type = "withdrawal";
        }
        request.pageNo = currentPageNo;
        request.pageSize = pageSize;

        reconcileService.exportUnconcileDetail(request).then(
            (result: any[] | null) => {
                
            }
        );
    };
    onPaginationchange = () => {
        const currentPageNo = this.state.currentPageNo + 1;
        this.setState({ currentPageNo }, () => {
          this.bindPendingReconcile(true);
        });
      };

    bindPendingReconcile(isPagination = false) {
     //   let tableColumns = this.tableColumns;

    if (!isPagination) {
      this.setState({
        ColumnsData: [],
      //  tableColumns,
      });
    } 

    const {pageSize, currentPageNo } = this.state;
    
        let request: any = {};
        request.OprID = this.props.selectedOprId;
        request.Accperiod = this.props.accPerRequest;

        var rType=this.props.pageTitle;
        if(rType.toLowerCase()=== "pending deposits"){
            request.Type = "deposit";
        }
        else if(rType.toLowerCase()=== "pending withdrawals"){
        request.Type = "withdrawal";
        }
        request.pageNo = currentPageNo;
        request.pageSize = pageSize;
        reconcileService.GetUnconcileDetail(request)
            .then(async (result: any | null) => {
                //debugger;
                if (result != null) {
                    //debugger;
                    if (isPagination) {
                        result = [
                          ...this.state.ColumnsData,
                          ...(result as any[]),
                        ];
                      }
              
                      const totalDataLength = result?.length
                        ? result[0]?.totalCount
                        : 0;

                    this.setState({ ColumnsData: result || [],totalDataLength });
                }
            });
    }
    getDropDownHomeIcon() {
        return (
            <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.466553 4.31579V7.19369H1.22661V11.5087H0.466553V13.6666H1.22661H3.50677H5.02688H7.30704H8.82715H11.1073H12.6274L14.9076 13.6674V13.6666H15.6676V11.5087H14.9076V7.19369H15.6676V4.31579L8.06709 1.90735e-05L0.466553 4.31579ZM3.50677 11.5087V7.19369H5.02688V11.5087H3.50677ZM7.30704 11.5087V7.19369H8.82715V11.5087H7.30704ZM12.6274 11.5087H11.1073V7.19369H12.6274V11.5087ZM9.5872 4.31579C9.5872 5.10989 8.90619 5.75438 8.06709 5.75438C7.228 5.75438 6.54699 5.10989 6.54699 4.31579C6.54699 3.52169 7.228 2.8772 8.06709 2.8772C8.90619 2.8772 9.5872 3.52169 9.5872 4.31579Z" fill="white"/>
        </svg>
        );
    }
    getDropDownArrowIcon() {
        return (
            <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.28122e-05 1.19715C3.28122e-05 0.889454 0.253262 0.653898 0.543288 0.653898C0.688297 0.653898 0.833313 0.708365 0.923855 0.816593L4.0023 4.02162L7.08074 0.816593C7.2979 0.599435 7.6424 0.599435 7.84116 0.816593C8.04064 1.01608 8.05831 1.34145 7.85955 1.55933L4.40046 5.18103C4.20098 5.39819 3.85721 5.39819 3.64005 5.19943L3.62166 5.18103L0.145009 1.55933C0.054467 1.46879 0 1.34217 0 1.19716L3.28122e-05 1.19715Z" fill="#6A6E73"/>
            </svg>
        );
    }
    OpenInvoiceSlideOut = (e, newrow, rowIndex, rowType) => {} 
    hideSlideOut(isSave) {
        this.setState({isSlideOut: false})
    }
    
    handleTransactionModal = (event) => {
        this.setState({ isSlideOut: event});
      } 

      
  cellTooltipFormatter = (cell: any, row: any, rowIndex: any) => {
    return (
      <> 
        <EllipsisWithTooltip placement="bottom">{cell}</EllipsisWithTooltip>
      </>
    );
  };
  render() {
    const dropDownHomeIcon = this.getDropDownHomeIcon();
    const dropDownArrowIcon = this.getDropDownArrowIcon();
    const dummyData = [
        {id: "1", Date: "7/1/22", Description: "Dummy text", Amount: "778.35 ", Commission: "-", Adjustment: "-", Net: "778.35", CL: ""},
        {id: "2", Date: "10/1/22", Description: "Dummy text", Amount: "778.35 ", Commission: "-", Adjustment: "-", Net: "778.35", CL: ""},
        {id: "3", Date: "12/1/22", Description: "Dummy text", Amount: "778.35 ", Commission: "-", Adjustment: "-", Net: "778.35", CL: ""},
        {id: "4", Date: "14/1/22", Description: "Dummy text", Amount: "778.35 ", Commission: "-", Adjustment: "-", Net: "778.35", CL: ""},
        {id: "5", Date: "16/1/22", Description: "Dummy text", Amount: "778.35 ", Commission: "-", Adjustment: "-", Net: "778.35", CL: ""},
    ]
    const {pageSize,currentPageNo,totalDataLength} = this.state;
    let tableColumns = [
        {
            dataField: "date",
            text: "Date",
        },{
            dataField: "accPer",
            text: "Act. Per",
        },{
            dataField: "vendor",
            text: "Vendor",
            formatter: this.cellTooltipFormatter,
        },{
            dataField: "invoiceNo",
            text: "	Invoice No.",
        },{
            dataField: "internalnotes",
            text: "	Internal Notes",
            formatter: this.cellTooltipFormatter,
        },
        // {
        //     dataField: "description",
        //     text: "Description",
        //     formatter: this.cellTooltipFormatter,
        // },
        {
            dataField: "lettercode",
            text: "E/HID",
        },{
            dataField: "coa",
            text: "COA",
        },{
            dataField: "ckno",
            text: "Ck No.",
        },{
            dataField: "amount",
            text: "Payment",
           
        },
        {
            dataField: "deposit",
            text: "Deposit",
           
        },{
            dataField: "transTotal",
            text: "Trans. Total",
           
        },{
            dataField: "cl",
            text: "CL",
        }
    ];

    return (
        <>
            <div className="reconciliation-modal modal">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="d-flex align-items-center modal-header">
                            <div className="modal-title page-title mr-auto">
                                <div className="tag-name">Reconcile Account</div>
                                <div className="ac-name">{this.props.accountName}</div>
                            </div>
                            <div className="action">
                                <div className="d-flex justify-content-end">
                                    <Dropdown className={this.props.headerControlsDisabled ? "ehid-selector disabled" : "ehid-selector"} alignRight>
                                        <Dropdown.Toggle id="dropdown-pending-account">
                                            <div className="d-flex align-items-center">
                                                <div className="home-icon">{dropDownHomeIcon}</div>
                                                <div className="ehid-name"><EllipsisWithTooltip placement="bottom">{this.props.accountName}</EllipsisWithTooltip></div>
                                                <div className="arrow-icon">{dropDownArrowIcon}</div>
                                            </div>
                                        </Dropdown.Toggle>
                                        {/* <Dropdown.Menu>
                                            {this.state.accountNames.map(
                                                (item: any, idx: any) => (
                                                <Dropdown.Item eventKey={this.state.accountNames[idx].id} key={idx}>{this.state.accountNames.name}</Dropdown.Item>
                                                // <Dropdown.Item eventKey={`${this.state.ehidList[idx].eventKey} - ${this.state.ehidList[idx].name}`} key={idx}>{this.state.ehidList[idx].eventKey} - {this.state.ehidList[idx].name}</Dropdown.Item>
                                                )
                                            )}                                
                                        </Dropdown.Menu> */}
                                    </Dropdown>
                                    
                                    <Dropdown className={this.props.headerControlsDisabled ? "margin-left-16 period-selector disabled" : "margin-left-16 period-selector"} alignRight>
                                        <Dropdown.Toggle id="dropdown-pending-period">
                                            <div className="d-flex align-items-center">
                                                <div className="period-name mr-auto"><EllipsisWithTooltip placement="bottom">{this.props.accPerDisplay}</EllipsisWithTooltip></div>
                                                <div className="arrow-icon">{dropDownArrowIcon}</div>
                                            </div>
                                        </Dropdown.Toggle>
                                        {/* <Dropdown.Menu>
                                            {this.state.periodList.map(
                                                (item: any, idx: any) => (
                                                    <Dropdown.Item eventKey={this.state.periodList[idx].eventKey} key={idx}>{this.state.periodList[idx].value}</Dropdown.Item>
                                                )
                                            )}                                
                                        </Dropdown.Menu> */}
                                    </Dropdown>
                                    
                                    <div className="separator"></div>
                                    <Button variant="primary" type="button" className="btn-outline-primary exit-button" onClick={() => this.props.handlePendingReconcileModal(false)}>Exit</Button>
                                </div>                            
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="bdy-sec rm-padding">
                                <div className="body-header d-flex align-items-center">
                                    <div className="header-title d-flex mr-auto">
                                        <div className="sec-ttl">{this.props.pageTitle}</div>
                                        <div className="header-status">{this.props.status}</div>
                                    </div>
                                    <Button variant="primary" type="button" className="btn-link" onClick={(event:any) => this.exportPendingReconcileSheet()}>Export</Button>
                                    {/* <Button variant="primary" type="button" className="btn-link">Reconcile All</Button>
                                    <Button variant="primary" type="button" className="btn-link" onClick={(event:any) => this.handleTransactionModal(true)}>Add Transaction</Button> */}
                                </div>
                                <div className="body-list">
                                    <BootstrapTable
                                        keyField="id"
                                        data={ this.state.ColumnsData }
                                        columns={tableColumns}
                                    />                            
                                </div>                                        
                            </div>
                        </div>
                        {totalDataLength > pageSize * currentPageNo &&(
                            <div className="modal-footer Load-more-list">
                                <Button
                                className="btn btn-ghost"
                                onClick={() => this.onPaginationchange()}
                                >
                                Load More...
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {/* <Modal className="reconciliation-modal" show={true}>      
                <Modal.Header className="d-flex align-items-center">
                    <Modal.Title className="page-title mr-auto">
                        <div className="tag-name">Reconcile Account</div>
                        <div className="ac-name">{this.props.accountName}</div>                    
                    </Modal.Title>
                    <div className="action">
                        <div className="d-flex justify-content-end">
                            <Dropdown className={this.props.headerControlsDisabled ? "ehid-selector disabled" : "ehid-selector"} alignRight>
                                <Dropdown.Toggle id="dropdown-pending-account">
                                    <div className="d-flex align-items-center">
                                        <div className="home-icon">{dropDownHomeIcon}</div>
                                        <div className="ehid-name"><EllipsisWithTooltip placement="bottom">{this.props.accountName}</EllipsisWithTooltip></div>
                                        <div className="arrow-icon">{dropDownArrowIcon}</div>
                                    </div>
                                </Dropdown.Toggle>                                
                            </Dropdown>
                            
                            <Dropdown className={this.props.headerControlsDisabled ? "margin-left-16 period-selector disabled" : "margin-left-16 period-selector"} alignRight>
                                <Dropdown.Toggle id="dropdown-pending-period">
                                    <div className="d-flex align-items-center">
                                        <div className="period-name mr-auto"><EllipsisWithTooltip placement="bottom">{this.props.accPerDisplay}</EllipsisWithTooltip></div>
                                        <div className="arrow-icon">{dropDownArrowIcon}</div>
                                    </div>
                                </Dropdown.Toggle>                                
                            </Dropdown>
                            
                            <div className="separator"></div>
                            <Button variant="primary" type="button" className="btn-outline-primary exit-button" onClick={() => this.props.handlePendingReconcileModal(false)}>Exit</Button>
                        </div>                            
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="bdy-sec rm-padding">
                        <div className="body-header d-flex align-items-center">
                            <div className="header-title d-flex mr-auto">
                                <div className="sec-ttl">{this.props.pageTitle}</div>
                                <div className="header-status">{this.props.status}</div>
                            </div>
                            <Button variant="primary" type="button" className="btn-link" onClick={(event:any) => this.exportPendingReconcileSheet()}>Export</Button>
                            
                        </div>
                        <div className="body-list">
                            <BootstrapTable
                                keyField="id"
                                data={ this.state.ColumnsData }
                                columns={tableColumns}
                            />                            
                        </div>                                        
                    </div>
                </Modal.Body>
                {totalDataLength > pageSize * currentPageNo &&(
                    <div className="modal-footer Load-more-list">
                        <Button
                        className="btn btn-ghost"
                        onClick={() => this.onPaginationchange()}
                        >
                        Load More...
                        </Button>
                    </div>
                )}                 
            </Modal> */}
            {this.state.isSlideOut &&(
                <InvoiceEntrySlideout
                    hidValue={this.state.sildeoutHid}
                    hotelName={this.state.sildeoutCode}
                    uniqueNo={this.state.trUniqueNo}
                    pageType={this.state.pageType}
                    oprId={this.state.oprId}
                    vid={this.state.vid}
                    hideSlideOut={this.hideSlideOut.bind(this)}
                    OpenInvoiceSlideOut={this.OpenInvoiceSlideOut.bind(this)}
                    selectedRow={this.state.selectedRow}
                    isFromRepeating={false}
                />
            )}            
        </>
    );
  }
}