import React from "react";
import { toast, ToastContainer } from "react-toastify";
import { resolve, reject } from "q";
import { Container, Form, Button, Modal, Col, Spinner } from "react-bootstrap";
import moment from "moment";
import { DropDownList } from "../Reports/common-components/dropdown-list";
import { StaticArrays } from "../../Common/StaticArrays";
import { Utils } from "../../Common/Utilis";
import { accountService } from "../../Common/Services/account";

export class VerifyMicroDepositModal extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      isSpinner: false,
      formData: {
        bankName: this.props.bankName,
        accountType: "",
        routingNumber: "",
        accountNo: this.props.accountNo,
        accountName: this.props.accountName,
        hotelID: this.props?.hotelID,
        bankRefID: this.props?.bankRefID,
        fundingSource: this.props?.fundingSource,
        amount1: "",
        amount2: "",
      },
      errors: {
        bankName: "",
        accountType: "",
        routingNumber: "",
        accountNo: "",
        accountName: "",
        amount1: "",
        amount2: "",
      },
    };
  }

  componentDidMount() {
    //debugger;
  }

  isFormValid() {
    //debugger;
    let errors = this.state.errors;
    let formData = { ...this.state.formData };
    let isForm = true;
    let microAmtLimit = parseFloat("0.10");
    if (formData.amount1.trim().length === 0) {
      errors.amount1 = "Amount 1 is required.";
      isForm = false;
    }
    if (formData.amount1.trim().length > 4) {
      errors.amount1 = "Only upto 4 digits are required in Amount 1.";
      isForm = false;
    }
    if (formData.amount1.trim() > microAmtLimit) {
      errors.amount1 = "Amount 1 should be less than or equal to $0.10";
      isForm = false;
    }
    if (formData.amount2.trim().length === 0) {
      errors.amount2 = "Amount 2 is required.";
      isForm = false;
    }
    if (formData.amount2.trim().length > 4) {
      errors.amount2 = "Only upto 4 digits are required in Amount 2.";
      isForm = false;
    }
    if (formData.amount2.trim() > microAmtLimit) {
      errors.amount2 = "Amount 2 should be less than or equal to $0.10";
      isForm = false;
    }
    this.setState({ errors });
    if (isForm === true) {
      return true;
    } else {
      return false;
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    let errors = this.state.errors;
    let formData = { ...this.state.formData };
    switch (name) {
      case "amount1":
        errors.amount1 =
          value.trim().length === 0 ? "Please enter amount 1." : "";
        formData.amount1 = value;
        break;
      case "amount2":
        errors.amount2 =
          value.trim().length === 0 ? "Please enter amount 2." : "";
        formData.amount2 = value;
        break;
      default:
        break;
    }

    this.setState({ errors, formData });
  };

  validationOnClick = (inputPosition) => {
    this.setState({ inputPosition }, () => this.validateDepositFormSchema());
  };

  validateDepositFormSchema = () => {};

  addMicroDeposit = () => {
    if (this.isFormValid()) {
      this.setState({ isSpinner: true });
      accountService
        .SaveVerifyMicroDeposits(this.state.formData)
        .then(async (result: any | null) => {
          if (result !== null) {
            if (result.status === "Success") {
              toast.success(result.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "bankDetailsSlideout",
              });
              setTimeout(() => {
                this.closePopup();
                this.refreshPage();
              }, 1000);
            } else {
              this.closePopup();
              this.setState({ isSpinner: false });
              Utils.toastError(result.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "bankDetailsSlideout",
              });
            }
          }
          resolve();
        })
        .catch((error) => {
          this.setState({ isSpinner: false });
          Utils.toastError(error.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "bankDetailsSlideout",
          });
        });
    }
  };

  refreshPage = () => {
    this.props?.PlaiddSetupStatus(this.props?.hotelID, this.props?.bankRefID);
  };

  closePopup() {
    this.props?.handleVerifyModal();
  }

  render() {
    const { errors, formData } = this.state;
    return (
      <div id="" className="entity-management">
        <ToastContainer containerId={"bankDetailsSlideout"} autoClose={3000} />
        <Container fluid className="">
          <Modal
            className="add-deposit-account-modal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={this.props.showVerifyModal}
            onHide={() => this.props?.handleVerifyModal()}
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Verify Account</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-section">
              <div className="description">
                  To verify your account, enter the two micro deposits below. if
                  you don't see them, they should arrive in 1-3 business days
                </div>
                <div className="body-section">
                  <Form.Group controlId="amount1">
                    <Col
                      sm={"12"}
                      className={
                        errors.amount1.length > 0 ? "mb-3 validation-error" : "mb-3"
                      }
                      onBlur={() => this.validationOnClick(1)}
                    >
                      <Form.Label>Amount 1</Form.Label>
                      <input
                        type="text"
                        className="form-control"
                        id="amount1"
                        name="amount1"
                        autoComplete="amount1"
                        onChange={this.handleChange}
                        placeholder="$0.00"
                        maxLength={4}
                        onKeyDown={Utils.AllowOnlyPositiveNumnberInput}
                      />
                      {errors.amount1.length > 0 && (
                        <span className="validation-message">{errors.amount1}</span>
                      )}
                    </Col>
                  </Form.Group>
                  <Form.Group controlId="amount2">
                    <Col className={
                        errors.amount2.length > 0 ? "mb-3 validation-error" : "mb-3"
                      } sm={"12"} onBlur={() => this.validationOnClick(2)}>
                      <Form.Label>Amount 2</Form.Label>
                      <input
                        type="text"
                        className="form-control"
                        id="amount2"
                        name="amount2"
                        autoComplete="amount2"
                        onChange={this.handleChange}
                        placeholder="$0.00"
                        maxLength={4}
                        onKeyDown={Utils.AllowOnlyPositiveNumnberInput}
                      />
                      {errors.amount2.length > 0 && (
                        <span className="validation-message">{errors.amount2}</span>
                      )}
                    </Col>
                  </Form.Group>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn-ghost"
                onClick={() => {
                  this.closePopup();
                }}
              >
                Cancel
              </Button>
              <Button
                className="btn btn-primary"
                onClick={this.addMicroDeposit}
              >
                Send
              </Button>
            </Modal.Footer>
            {this.state.isSpinner && (
              <div className="loader-spinner d-flex justify-content-center align-items-center">
                <Spinner animation="border" variant="success" />
              </div>
            )}
          </Modal>
        </Container>
      </div>
    );
  }
}
