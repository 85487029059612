import * as React from 'react';
import { Dropdown, OverlayTrigger, Tooltip, Form } from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import "./reorderList.scss";
import { toast, ToastContainer } from "react-toastify";
import { ModuleReports } from "../../../Common/Services/ModuleReports";
import { getDataDetail } from '@microsoft/signalr/dist/esm/Utils';
import { setsEqual } from 'chart.js/dist/helpers/helpers.core';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import _ from "lodash";
import { Utils } from '../../../Common/Utilis';

export class CashFlowGlobalSetting extends React.Component<any, any> {
    private selectCategory: any;
    private categoryDropdownRef: any;
    private saveButtonRef: any;
    private cancelButtonRef: any;
    private menuRef: any;
    constructor(props: any) {
        super(props);
        this.selectCategory = React.createRef();
        this.categoryDropdownRef = React.createRef();
        this.saveButtonRef = React.createRef();
        this.cancelButtonRef = React.createRef();
        this.menuRef = React.createRef();
        this.state = {
            isload: false,
            catList: [
                { catId: "Operating", catName: "Operating Activities" },
                { catId: "Investing", catName: "Investing Activities" },
                { catId: "Financing", catName: "Financing Activities" }
            ],
            itemData: {
                title: "",
                groupId: "",
                uniqueNo: ""
            },
            selectedCategory: "Operating Activities",
            // Index of the currently selected item
            selectedIndexMap: {
                operating: -1,
                investing: -1,
                financing: -1
            },
            // Dropdown open state
            isoperatingDropdownOpen: false,
            isinvestingDropdownOpen: false,
            isfinancingDropdownOpen: false,
        };
        this.handleGlobalKeyDown = this.handleGlobalKeyDown.bind(this);
        this.handleDropdownToggle = this.handleDropdownToggle.bind(this);
    }

    componentDidMount() {
        this.getCashFloWData();
        document.addEventListener('keydown', this.handleGlobalKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleGlobalKeyDown);
    }

    handleGlobalKeyDown = (event) => {
        // Call the specific handleKeyDown function based on the currently open dropdown
        if (this.state.isoperatingDropdownOpen) {
            this.handleKeyDown(event, "operating");
        } else if (this.state.isinvestingDropdownOpen) {
            this.handleKeyDown(event, "investing");
        } else if (this.state.isfinancingDropdownOpen) {
            this.handleKeyDown(event, "financing");
        }
    };

    getCashFloWData() {
        ModuleReports.getCashFloWData().then(async (result: any[] | null) => {
            if (result != null && result.length > 0) {
                result.map((items) => ((items["title"] = items.rowTitle_Line)));
                this.props.updateState(result);
                this.setState({
                    allData: result,
                    operating: result.filter(e => e.groupID === 1),
                    investing: result.filter(e => e.groupID === 2),
                    financing: result.filter(e => e.groupID === 3),
                    isload: false
                }, () => {
                    this.setState({ isload: true })
                })
            }
        })
            .catch((error) => {
                console.log(error);
            });
    }

    handleDropOperating = (droppedItem) => {
        let operating = [...this.state.operating];
        if (droppedItem.destination === null) {
            let msg = operating[droppedItem.source.index].rowTitle_Line + " cannot move from " + "'" + operating[droppedItem.source.index].rowTitle + "'"; //Purchases of Investments CANNOT MOVE UP TO 'Cash Flows from Investing Activities'
            Utils.toastError(msg, {});
            return false;
        } else {
            let currentPos = droppedItem.source.index;
            let newPos = droppedItem.destination.index;
            if (currentPos === 1 || currentPos === 2 || newPos === 2 || newPos === 0 || (currentPos !== 0 && newPos === 1) || (currentPos === 0 && newPos !== 1)) {
                let newState = _.orderBy(operating, ['orderSrno'], ['asc']);
                this.setState({ operating: [], isload: false }, () => {
                    this.setState({ operating: newState, isload: true })
                })
            } else {
                let currIndexObj = operating[currentPos];
                let NewIndexObj = operating[newPos];
                this.DraggingFunctionality(currIndexObj, NewIndexObj);
            }
        }
    }

    handleDropInvesting = (droppedItem) => {
        let investing = [...this.state.investing];
        if (droppedItem.destination === null) {
            let msg = investing[droppedItem.source.index].rowTitle_Line + " cannot move from " + "'" + investing[droppedItem.source.index].rowTitle + "'"; //Purchases of Investments CANNOT MOVE UP TO Cash Flows from Investing Activities
            Utils.toastError(msg, {});
            return false;
        } else {
            let currentPos = droppedItem.source.index;
            let newPos = droppedItem.destination.index;
            let currIndexObj = investing[currentPos];
            let NewIndexObj = investing[newPos];
            this.DraggingFunctionality(currIndexObj, NewIndexObj);
        }
    }

    handleDropFinancing = (droppedItem) => {
        let financing = [...this.state.financing];
        if (droppedItem.destination === null) {
            let msg = financing[droppedItem.source.index].rowTitle_Line + " cannot move from " + "'" + financing[droppedItem.source.index].rowTitle + "'"; //Purchases of Investments CANNOT MOVE UP TO Cash Flows from Investing Activities
            Utils.toastError(msg, {});
            return false;
        } else {
            let currentPos = droppedItem.source.index;
            let newPos = droppedItem.destination.index;
            let currIndexObj = financing[currentPos];
            let NewIndexObj = financing[newPos];
            this.DraggingFunctionality(currIndexObj, NewIndexObj);
        }
    }

    DraggingFunctionality = (currIndexObj, NewIndexObj) => {
        let allData = [...this.state.allData];
        let currentPos = currIndexObj.orderSrno;
        let newPos = NewIndexObj.orderSrno;
        let currRow = allData.indexOf(currIndexObj);
        if (newPos > currentPos) {
            for (let index = 0; index < allData.length; index++) {
                if (allData[index].orderSrno > currentPos && allData[index].orderSrno <= newPos) {
                    allData[index].orderSrno = allData[index].orderSrno - 1;
                }
            }

            let diff = newPos - currentPos;
            currIndexObj.orderSrno = currIndexObj.orderSrno + diff;
            allData.push(currIndexObj);
            allData.splice(currRow, 1);
            this.setState({ allData: allData }, () => {
                this.setTableOrders(allData);
            })

        } else if (newPos < currentPos) {
            for (let index = 0; index < allData.length; index++) {
                if (allData[index].orderSrno < currentPos && allData[index].orderSrno >= newPos) {
                    allData[index].orderSrno = allData[index].orderSrno + 1;
                }
            }
            let diff = currentPos - newPos;
            currIndexObj.orderSrno = currIndexObj.orderSrno - diff;
            allData.push(currIndexObj);
            allData.splice(currRow, 1);
            this.setState({ allData: allData }, () => {
                this.setTableOrders(allData);
            })
        }

    }

    setTableOrders = (allData) => {
        this.props.updateState(allData);
        let operating = allData.filter(e => e.groupID === 1);
        let investing = allData.filter(e => e.groupID === 2);
        let financing = allData.filter(e => e.groupID === 3);
        let newoperating = _.orderBy(operating, ['orderSrno'], ['asc']);
        let newinvesting = _.orderBy(investing, ['orderSrno'], ['asc']);
        let newfinancing = _.orderBy(financing, ['orderSrno'], ['asc']);
        this.setState({
            operating: newoperating,
            investing: newinvesting,
            financing: newfinancing,
            isload: false
        }, () => {
            this.setState({ isload: true })
        })
    }

    handleAddRowEvents = (item, type) => {
        const { itemData } = this.state;

        if (type === "txtOpName") {
            itemData.title = item.target.value;
        } else if (type === "save") {
            itemData.uniqueNo = "";
            this.saveAndUpdateCashFlowItem();
        } else if (type === "category") {
            const categorySelector = this.selectCategory.current;
            categorySelector.click();
            switch (item.catName) {
                case "Operating Activities":
                    itemData.groupId = 1;
                    break;
                case "Investing Activities":
                    itemData.groupId = 2;
                    break;
                default:
                    itemData.groupId = 3;
            }
            this.setState({ selectedCategory: item.catName })
        }

        this.setState({ itemData });
    }

    saveAndUpdateCashFlowItem = () => {
        const { itemData } = this.state;
        if (this.isValidName()) {
            let request: any = {};
            request.title = itemData.title;
            request.uniqueNo = itemData.uniqueNo;
            request.groupId = itemData.groupId;

            ModuleReports.PostSaveCashFlowItem(request)
                .then(async (result: any | null) => {
                    if (result?.message === "Success") {
                        toast.success("Record Saved Successfully", {});
                        this.getCashFloWData();
                    } else {
                        Utils.toastError(result?.result, {});
                    }
                })
                .catch((error) => {
                    Utils.toastError(error?.message, {});
                });
        }
    }

    handleKeyDown(event, activity) {
        const { catList } = this.state;
        // Use the appropriate selectedIndex for this dropdown type
        const selectedIndex = this.state.selectedIndexMap[activity];

        if (event.key === 'ArrowDown') {
            event.preventDefault();
            this.setState(prevState => ({
                selectedIndexMap: {
                    ...prevState.selectedIndexMap,
                    [activity]: (selectedIndex + 1) % catList.length
                }
            }));
        } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            this.setState(prevState => ({
                selectedIndexMap: {
                    ...prevState.selectedIndexMap,
                    [activity]: selectedIndex === -1 ? catList.length - 1 : (selectedIndex - 1 + catList.length) % catList.length
                }
            }));
        } else if (event.key === 'Enter') {
            event.preventDefault();
            if (selectedIndex !== -1) {
                this.handleAddRowEvents(catList[selectedIndex], "category");
            }
        }
        console.log(selectedIndex);
    }

    handleDropdownToggle(isOpen, activity) {
        if (isOpen) {
            this.setState(
                {
                    [`is${activity}DropdownOpen`]: true
                },
                () => {
                    this.menuRef.current.focus();
                }
            );
        } else {
            // Reset the selectedIndex for the specific dropdown when it is closed
            this.setState(prevState => ({
                [`is${activity}DropdownOpen`]: false,
                selectedIndexMap: {
                    ...prevState.selectedIndexMap,
                    [activity]: -1  // Reset selectedIndex for this dropdown
                }
            }));
        }
    }

    addItemPopup = (activity) => {
        const { itemData } = this.state;

        // Retrieve the appropriate selectedIndex for this dropdown type
        const selectedIndex = this.state.selectedIndexMap[activity];

        return (
            <Dropdown className="add-edit-modal" alignRight onToggle={(event) => this.handleToggle(event, activity, "")}>
                <Dropdown.Toggle className="btn btn-primary more fa-trash blue-btn" id="dropdown-addEdit">
                    Add Row
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <div className="header d-flex align-items-center">
                        <div className='ttl mr-auto'>Custom Item Settings</div>
                        <Dropdown.Item className='cross-btn'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M13.4933 5.28662L9.95751 8.82162L6.4225 5.28662L5.24417 6.46495L8.77917 9.99995L5.24417 13.535L6.4225 14.7133L9.95751 11.1783L13.4933 14.7133L14.6717 13.535L11.1367 9.99995L14.6717 6.46495L13.4933 5.28662Z" fill="#84888C" />
                            </svg>
                        </Dropdown.Item>
                    </div>
                    <div className='body'>
                        <Form.Group controlId="Category">
                            <Form.Label>Category</Form.Label>
                            <Dropdown className="custom-selectbox category-selecter" ref={this.selectCategory} show={this.state[`is${activity}DropdownOpen`]} onToggle={(isOpen) => this.handleDropdownToggle(isOpen, activity)}>
                                <Dropdown.Toggle id="dropdown-Category" ref={this.categoryDropdownRef}>
                                    <EllipsisWithTooltip placement="bottom">{this.state.selectedCategory === "" ? "Select" : this.state.selectedCategory}</EllipsisWithTooltip>
                                    <svg
                                        width="10"
                                        height="7"
                                        viewBox="0 0 10 7"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M8.29272 0.292969L5.00006 3.5863L1.70739 0.292969L0.292725 1.70764L5.00006 6.4143L9.70739 1.70764L8.29272 0.292969Z" />
                                    </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                    tabIndex={0}
                                    ref={this.menuRef}
                                    onKeyDown={(event) => this.handleGlobalKeyDown(event)} // Pass the activity here
                                >
                                    {this.state.catList.map((item: any, idx: any) => (
                                        <div
                                            className={`dropdown-item ${selectedIndex === idx ? 'active' : ''}`}
                                            key={idx}
                                            onClick={() => this.handleAddRowEvents(item, "category")}
                                        >{item.catName}</div>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Form.Group>
                        <Form.Group controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                className="form-control"
                                id="txtOpName"
                                name="txtOpName"
                                value={itemData.title}
                                autoComplete="off"
                                onChange={(e) => this.handleAddRowEvents(e, "txtOpName")}
                            />
                        </Form.Group>
                    </div>
                    <div className='footer d-flex align-items-center'>
                        <Dropdown.Item
                            ref={this.cancelButtonRef}
                            onKeyDown={this.handleCancelButtonKeyDown}
                            className="btn btn-primary btn-link d-flex justify-content-center align-items-center">Cancel</Dropdown.Item>
                        <Dropdown.Item
                            ref={this.saveButtonRef}
                            onKeyDown={this.handleSaveButtonKeyDown}
                            disabled={itemData.title.trim() !== "" ? false : true}
                            className="btn btn-primary d-flex justify-content-center align-items-center"
                            onClick={() => this.handleAddRowEvents("", "save")}>Save</Dropdown.Item>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    handleCancelButtonKeyDown = (e) => {
        if (e.key === 'Tab') {
            e.preventDefault();
            if (this.state.itemData.title === "") {
                this.categoryDropdownRef.current.focus();
            } else {
                this.saveButtonRef.current.focus();
            }
        }
    };

    handleSaveButtonKeyDown = (e) => {
        if (e.key === 'Tab') {
            e.preventDefault();
            this.categoryDropdownRef.current.focus();
        }
    };

    editItemPopup = (type, item, index) => {
        // Retrieve the appropriate selectedIndex for this dropdown type
        const selectedIndex = this.state.selectedIndexMap[type];
        return (
            <Dropdown className="add-edit-modal" alignRight onToggle={(event) => this.handleToggle(event, type, item)}>
                <Dropdown.Toggle className="btn btn-primary more fa-trash blue-btn" id="dropdown-addEdit">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M7.25583 16.4223L15 8.67818L11.3217 4.99984L3.5775 12.744C3.47083 12.8507 3.395 12.9848 3.35833 13.1307L2.5 17.4998L6.86833 16.6415C7.015 16.6048 7.14917 16.529 7.25583 16.4223ZM17.5 6.17818C18.1508 5.52734 18.1508 4.47234 17.5 3.82151L16.1783 2.49984C15.5275 1.84901 14.4725 1.84901 13.8217 2.49984L12.5 3.82151L16.1783 7.49984L17.5 6.17818Z" />
                    </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <div className="header d-flex align-items-center">
                        <div className='ttl mr-auto'>Custom Item Settings</div>
                        <Dropdown.Item className='cross-btn'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M13.4933 5.28662L9.95751 8.82162L6.4225 5.28662L5.24417 6.46495L8.77917 9.99995L5.24417 13.535L6.4225 14.7133L9.95751 11.1783L13.4933 14.7133L14.6717 13.535L11.1367 9.99995L14.6717 6.46495L13.4933 5.28662Z" fill="#84888C" />
                            </svg>
                        </Dropdown.Item>
                    </div>
                    <div className='body'>
                        <Form.Group controlId="Category">
                            <Form.Label>Category</Form.Label>
                            <Dropdown className="custom-selectbox category-selecter" ref={this.selectCategory} show={this.state[`is${type}DropdownOpen`]} onToggle={(isOpen) => this.handleDropdownToggle(isOpen, type)}>
                                <Dropdown.Toggle id="dropdown-Category" ref={this.categoryDropdownRef}>
                                    <EllipsisWithTooltip placement="bottom">{this.state.selectedCategory === "" ? "Select" : this.state.selectedCategory}</EllipsisWithTooltip>
                                    <svg
                                        width="10"
                                        height="7"
                                        viewBox="0 0 10 7"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M8.29272 0.292969L5.00006 3.5863L1.70739 0.292969L0.292725 1.70764L5.00006 6.4143L9.70739 1.70764L8.29272 0.292969Z" />
                                    </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                    tabIndex={0}
                                    ref={this.menuRef}
                                    onKeyDown={(event) => this.handleGlobalKeyDown(event)} // Pass the activity here
                                >
                                    {this.state.catList.map((item: any, idx: any) => (
                                        <div
                                            className={`dropdown-item ${selectedIndex === idx ? 'active' : ''}`}
                                            key={idx}
                                            onClick={() => this.handleAddRowEvents(item, "category")}
                                        >{item.catName}</div>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Form.Group>
                        <Form.Group controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                className="form-control"
                                key={"txtOpName" + index}
                                id={"txtOpName" + index}
                                name="txtOpName"
                                value={this.state.itemData.title}
                                autoComplete="off"
                                onChange={(e) => this.handleAddRowEvents(e, "txtOpName")}
                            />
                        </Form.Group>
                    </div>
                    <div className='footer d-flex align-items-center'>
                        <Dropdown.Item className="btn btn-danger d-flex justify-content-center align-items-center mr-auto" onClick={() => this.handleDeleteItem(item)}>Delete</Dropdown.Item>
                        <Dropdown.Item
                            ref={this.cancelButtonRef}
                            onKeyDown={this.handleCancelButtonKeyDown}
                            className="btn btn-primary btn-link d-flex justify-content-center align-items-center">Cancel</Dropdown.Item>
                        <Dropdown.Item
                            ref={this.saveButtonRef}
                            onKeyDown={this.handleSaveButtonKeyDown}
                            disabled={this.state.itemData.title.trim() !== "" ? false : true}
                            className="btn btn-primary d-flex justify-content-center align-items-center"
                            onClick={() => this.handleUpdateItem(type, item, index)}>Update</Dropdown.Item>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    handleUpdateItem = (type, item, i) => {
        const { itemData } = this.state;

        itemData.uniqueNo = item.uniqueno_2;
        this.setState({ itemData }, () => {
            this.saveAndUpdateCashFlowItem();
        })
    }

    resetData = () => {
        const { itemData } = this.state;
        itemData.title = "";
        itemData.groupId = "";
        itemData.uniqueNo = "";
        this.setState({
            itemData
        });
    }

    handleDeleteItem = (item) => {
        let request: any = {};
        request.uniqueNo = item.uniqueno_2;

        ModuleReports.DeleteCashFlowItem(request)
            .then(async (result: any | null) => {
                if (result?.message === "Success") {
                    toast.success("Record Deleted Successfully.", {});
                    this.getCashFloWData();
                } else {
                    Utils.toastError(result?.result, {});
                }
            })
            .catch((error) => {
                Utils.toastError(error?.message, {});
            });
    }

    isValidName = () => {
        const { itemData, allData } = this.state;
        let isNotUnique = false;

        if (itemData.title.trim().length === 0) {
            Utils.toastError("Please enter the item name", {});
            return false;
        }

        isNotUnique = allData.some(x => x.rowTitle_Line.toLowerCase() === itemData.title.toLowerCase().trim() && x.uniqueno_2 !== itemData.uniqueNo);

        if (isNotUnique) {
            Utils.toastError("Group item already exists", {});
            return false;
        }

        return true;
    }

    handleToggle = (e, activity, item) => {
        if (e) {
            this.resetData();
            let { selectedCategory, itemData } = this.state;

            if (activity === "operating") {
                itemData.groupId = 1;
                selectedCategory = "Operating Activities";
            }
            else if (activity === "investing") {
                itemData.groupId = 2;
                selectedCategory = "Investing Activities";
            }
            else {
                itemData.groupId = 3;
                selectedCategory = "Financing Activities";
            }

            if (item !== "") {
                itemData.uniqueNo = item.uniqueno_2;
                itemData.title = item.rowTitle_Line;
            }

            this.setState({ selectedCategory, itemData });
        }
    }

    render() {
        return (
            <>
                {this.state.isload && (
                    <div className="sec-group cash-flow">
                        <div className="group-title">Cash Flow Groups</div>
                        <div className="section">
                            {/* Inner list */}
                            <div className='list-section'>
                                <div className='section-ttl'>From Operating Activities</div>
                                <div className="drag-list">
                                    <DragDropContext onDragEnd={this.handleDropOperating}>
                                        <Droppable droppableId="list-container">
                                            {(provided) => (
                                                <div
                                                    className="list-container"
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                >
                                                    {this.state.operating.map((item, index) => (
                                                        <Draggable key={item} draggableId={item.rowTitle_Line} index={index}>
                                                            {(provided) => (
                                                                <div
                                                                    className="list d-flex align-items-center"
                                                                    ref={provided.innerRef}
                                                                    {...provided.dragHandleProps}
                                                                    {...provided.draggableProps}
                                                                >
                                                                    <div className="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="20" viewBox="0 0 23 20" fill="none">
                                                                            <path d="M3.68116 5H18.4058V6.66667H3.68116V5ZM3.68116 9.16667H18.4058V10.8333H3.68116V9.16667ZM3.68116 13.3333H18.4058V15H3.68116V13.3333Z" fill="#CACED2" />
                                                                        </svg>
                                                                    </div>
                                                                    <div className="display-name mr-auto"><EllipsisWithTooltip placement="bottom">{item.rowTitle_Line}</EllipsisWithTooltip></div>
                                                                    {item?.addedType.toLowerCase() === "user" && (
                                                                        this.editItemPopup("operating", item, index)
                                                                    )}
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>
                            </div>
                            <div className="add-row">
                                {this.addItemPopup("operating")}
                            </div>
                        </div>
                        <div className="section">
                            {/* Inner list */}
                            <div className='list-section'>
                                <div className='section-ttl'>From Investing Activities</div>
                                <div className="drag-list">

                                    <DragDropContext onDragEnd={this.handleDropInvesting}>
                                        <Droppable droppableId="list-container">
                                            {(provided) => (
                                                <div
                                                    className="list-container"
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                >
                                                    {this.state.investing.map((item, index) => (
                                                        <Draggable key={item} draggableId={item.rowTitle_Line} index={index}>
                                                            {(provided) => (
                                                                <div
                                                                    className="list d-flex align-items-center"
                                                                    ref={provided.innerRef}
                                                                    {...provided.dragHandleProps}
                                                                    {...provided.draggableProps}
                                                                >
                                                                    <div className="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="20" viewBox="0 0 23 20" fill="none">
                                                                            <path d="M3.68116 5H18.4058V6.66667H3.68116V5ZM3.68116 9.16667H18.4058V10.8333H3.68116V9.16667ZM3.68116 13.3333H18.4058V15H3.68116V13.3333Z" fill="#CACED2" />
                                                                        </svg>
                                                                    </div>
                                                                    <div className="display-name mr-auto"><EllipsisWithTooltip placement="bottom">{item.rowTitle_Line}</EllipsisWithTooltip></div>
                                                                    {item?.addedType.toLowerCase() === "user" && (
                                                                        this.editItemPopup("investing", item, index)
                                                                    )}
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>
                            </div>
                            <div className="add-row">
                                {this.addItemPopup("investing")}
                            </div>
                        </div>
                        <div className="section">
                            {/* Inner list */}
                            <div className='list-section'>
                                <div className='section-ttl'>From Financing Activities</div>
                                <div className="drag-list">
                                    <DragDropContext onDragEnd={this.handleDropFinancing}>
                                        <Droppable droppableId="list-container">
                                            {(provided) => (
                                                <div
                                                    className="list-container"
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                >
                                                    {this.state.financing.map((item, index) => (
                                                        <Draggable key={item} draggableId={item.rowTitle_Line} index={index}>
                                                            {(provided) => (
                                                                <div
                                                                    className="list d-flex align-items-center"
                                                                    ref={provided.innerRef}
                                                                    {...provided.dragHandleProps}
                                                                    {...provided.draggableProps}
                                                                >
                                                                    <div className="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="20" viewBox="0 0 23 20" fill="none">
                                                                            <path d="M3.68116 5H18.4058V6.66667H3.68116V5ZM3.68116 9.16667H18.4058V10.8333H3.68116V9.16667ZM3.68116 13.3333H18.4058V15H3.68116V13.3333Z" fill="#CACED2" />
                                                                        </svg>
                                                                    </div>
                                                                    <div className="display-name mr-auto"><EllipsisWithTooltip placement="bottom">{item.rowTitle_Line}</EllipsisWithTooltip></div>
                                                                    {item?.addedType.toLowerCase() === "user" && (
                                                                        this.editItemPopup("financing", item, index)
                                                                    )}
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>
                            </div>
                            <div className="add-row">
                                {this.addItemPopup("financing")}
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }
}