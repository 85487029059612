import React from "react";
import { Container, Dropdown, Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { reject, resolve } from "q";
import { ILabourScheduleRequestDto } from "../../../../../Common/Contracts/ILaborSchedule";
import { LaborSchedule } from "../../../../../Common/Services/LaborSchedule";
import BootstrapTable from "react-bootstrap-table-next";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { schConversions } from "../../../../LaborManagement/Labor/Schedule/commonFunctions/conversions";
import ForecastLock from "../../../../../Common/Assets/Images/icons/forecast-lock.svg";
import { ReactPageLoader } from "../../../../../Common/Components/ReactPageLoader";
import { Utils } from "../../../../../Common/Utilis";
//let dragDropArray:any=[];
export class OtrScheduleSlideOut extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      currentDate: JSON.parse(localStorage.storage).currentUtcTime !== "" ? new Date(
        JSON.parse(localStorage.storage).currentUtcTime
      ):new Date(),
      LaborSchedule: [],
      loaderTrue: false,
      tableData: [],
      headerDates: "",
      lockDays: [],
    };
  }
  componentDidMount() {
    console.warn = () => { };
    this.getScheduleData();
  }
  closeSlideOut = () => {
    this.props.closeSlideOut();
  };
  filterDataByDepartment(departmentId: any, array: []) {
    let dataArr: any[] = [];
    let mainArray: any[] = [];
    dataArr = Object.assign([], JSON.parse(JSON.stringify(array)));
    for (let i = 0; i < dataArr.length; i++) {
      let isValue = false;
      let day1: any[] = [];
      let obj: any = {};
      let day2: any[] = [];
      let day3: any[] = [];
      let day4: any[] = [];
      let day5: any[] = [];
      let day6: any[] = [];
      let day7: any[] = [];
      obj.actualHour = dataArr[i].actualHour;
      obj.bulkSelect = dataArr[i].bulkSelect;
      obj.employeeImage = dataArr[i].employeeImage;
      obj.employeeName = dataArr[i].employeeName;
      obj.multipleShiftVisibility = dataArr[i].multipleShiftVisibility;
      obj.rowNo = dataArr[i].rowNo;
      obj.shiftType = dataArr[i].shiftType;
      obj.showUserList = dataArr[i].showUserList;
      obj.timeDetails = dataArr[i].timeDetails;
      obj.timeDetailsSummary = dataArr[i].timeDetailsSummary;
      for (let j = 0; j < dataArr[i].day1.length; j++) {
        let newItem: any = {};
        if (Number(dataArr[i].day1[j].deptId) === departmentId) {
          newItem = dataArr[i].day1[j];
          isValue = true;
        } else {
          newItem.actualPunches = "No";
          newItem.deptId = "";
          newItem.displayTime = "";
          newItem.indate = "";
          newItem.optionsItems = "showOptions";
          newItem.outdate = "";
          newItem.positionId = "";
          newItem.shiftAction = "";
          newItem.shiftDate = "";
          newItem.shiftDay = "";
          newItem.shiftInTime = "";
          newItem.shiftIsMultiple = "";
          newItem.shiftOutTime = "";
          newItem.shiftPosition = "";
          newItem.shiftType = "blankShift";
          newItem.shiftbulkSelect = false;
          newItem.typeMaster = "1";
          newItem.uniqueno = "";
        }
        day1.push(newItem);
      }
      for (let j = 0; j < dataArr[i].day2.length; j++) {
        let newItem: any = {};
        if (Number(dataArr[i].day2[j].deptId) === departmentId) {
          newItem = dataArr[i].day2[j];
          isValue = true;
        } else {
          newItem.actualPunches = "No";
          newItem.deptId = "";
          newItem.displayTime = "";
          newItem.indate = "";
          newItem.optionsItems = "showOptions";
          newItem.outdate = "";
          newItem.positionId = "";
          newItem.shiftAction = "";
          newItem.shiftDate = "";
          newItem.shiftDay = "";
          newItem.shiftInTime = "";
          newItem.shiftIsMultiple = "";
          newItem.shiftOutTime = "";
          newItem.shiftPosition = "";
          newItem.shiftType = "blankShift";
          newItem.shiftbulkSelect = false;
          newItem.typeMaster = "1";
          newItem.uniqueno = "";
        }
        day2.push(newItem);
      }
      for (let j = 0; j < dataArr[i].day3.length; j++) {
        let newItem: any = {};
        if (Number(dataArr[i].day3[j].deptId) === departmentId) {
          newItem = dataArr[i].day3[j];
          isValue = true;
        } else {
          newItem.actualPunches = "No";
          newItem.deptId = "";
          newItem.displayTime = "";
          newItem.indate = "";
          newItem.optionsItems = "showOptions";
          newItem.outdate = "";
          newItem.positionId = "";
          newItem.shiftAction = "";
          newItem.shiftDate = "";
          newItem.shiftDay = "";
          newItem.shiftInTime = "";
          newItem.shiftIsMultiple = "";
          newItem.shiftOutTime = "";
          newItem.shiftPosition = "";
          newItem.shiftType = "blankShift";
          newItem.shiftbulkSelect = false;
          newItem.typeMaster = "1";
          newItem.uniqueno = "";
        }
        day3.push(newItem);
      }
      for (let j = 0; j < dataArr[i].day4.length; j++) {
        let newItem: any = {};
        if (Number(dataArr[i].day4[j].deptId) === departmentId) {
          newItem = dataArr[i].day4[j];
          isValue = true;
        } else {
          newItem.actualPunches = "No";
          newItem.deptId = "";
          newItem.displayTime = "";
          newItem.indate = "";
          newItem.optionsItems = "showOptions";
          newItem.outdate = "";
          newItem.positionId = "";
          newItem.shiftAction = "";
          newItem.shiftDate = "";
          newItem.shiftDay = "";
          newItem.shiftInTime = "";
          newItem.shiftIsMultiple = "";
          newItem.shiftOutTime = "";
          newItem.shiftPosition = "";
          newItem.shiftType = "blankShift";
          newItem.shiftbulkSelect = false;
          newItem.typeMaster = "1";
          newItem.uniqueno = "";
        }
        day4.push(newItem);
      }
      for (let j = 0; j < dataArr[i].day5.length; j++) {
        let newItem: any = {};
        if (Number(dataArr[i].day5[j].deptId) === departmentId) {
          newItem = dataArr[i].day5[j];
          isValue = true;
        } else {
          newItem.actualPunches = "No";
          newItem.deptId = "";
          newItem.displayTime = "";
          newItem.indate = "";
          newItem.optionsItems = "showOptions";
          newItem.outdate = "";
          newItem.positionId = "";
          newItem.shiftAction = "";
          newItem.shiftDate = "";
          newItem.shiftDay = "";
          newItem.shiftInTime = "";
          newItem.shiftIsMultiple = "";
          newItem.shiftOutTime = "";
          newItem.shiftPosition = "";
          newItem.shiftType = "blankShift";
          newItem.shiftbulkSelect = false;
          newItem.typeMaster = "1";
          newItem.uniqueno = "";
        }
        day5.push(newItem);
      }
      for (let j = 0; j < dataArr[i].day6.length; j++) {
        let newItem: any = {};
        if (Number(dataArr[i].day6[j].deptId) === departmentId) {
          newItem = dataArr[i].day6[j];
          isValue = true;
        } else {
          newItem.actualPunches = "No";
          newItem.deptId = "";
          newItem.displayTime = "";
          newItem.indate = "";
          newItem.optionsItems = "showOptions";
          newItem.outdate = "";
          newItem.positionId = "";
          newItem.shiftAction = "";
          newItem.shiftDate = "";
          newItem.shiftDay = "";
          newItem.shiftInTime = "";
          newItem.shiftIsMultiple = "";
          newItem.shiftOutTime = "";
          newItem.shiftPosition = "";
          newItem.shiftType = "blankShift";
          newItem.shiftbulkSelect = false;
          newItem.typeMaster = "1";
          newItem.uniqueno = "";
        }
        day6.push(newItem);
      }
      for (let j = 0; j < dataArr[i].day7.length; j++) {
        let newItem: any = {};
        if (Number(dataArr[i].day7[j].deptId) === departmentId) {
          newItem = dataArr[i].day7[j];
          isValue = true;
        } else {
          newItem.actualPunches = "No";
          newItem.deptId = "";
          newItem.displayTime = "";
          newItem.indate = "";
          newItem.optionsItems = "showOptions";
          newItem.outdate = "";
          newItem.positionId = "";
          newItem.shiftAction = "";
          newItem.shiftDate = "";
          newItem.shiftDay = "";
          newItem.shiftInTime = "";
          newItem.shiftIsMultiple = "";
          newItem.shiftOutTime = "";
          newItem.shiftPosition = "";
          newItem.shiftType = "blankShift";
          newItem.shiftbulkSelect = false;
          newItem.typeMaster = "1";
          newItem.uniqueno = "";
        }
        day7.push(newItem);
      }
      obj.day1 = day1;
      obj.day2 = day2;
      obj.day3 = day3;
      obj.day4 = day4;
      obj.day5 = day5;
      obj.day6 = day6;
      obj.day7 = day7;
      if (isValue) {
        mainArray.push(obj);
      }
    }
    this.setState({ tableData: mainArray });
  }
  getScheduleData = () => {
    let hotelid = this.props.rightPanel!==undefined && (this.props.rightPanel[0].hid);
    let departmentID = this.props.rowEventData!==undefined && (parseInt(this.props.rowEventData.deptID));
    let request = {} as ILabourScheduleRequestDto;
    request.HotelID = hotelid;
    request.ViewType = "Departments";
    request.DayLayOut = 7;
    request.filter = "Alphabetical";
    request.Date = this.props.date[0];
    this.setState({ loaderTrue: true });
    LaborSchedule.GetLaborScheduleData(request)
      .then(async (result: any) => {
        if (result != null && result.length > 0) {
          this.setState({ loaderTrue: false });
          let tempScheduleData: any = [];
          let calnderDatesDispaly: any = [];
          let isPageLock: any = false;
          let lockDays: any = [];
          let headerDates: any = [];
          let headerFullDates: any = [];
          let headerDays: any = [];
          let isBannerShow = false;
          let bannerType: any = "";
          let footerTotal: any = [];
          let footerData: any = [];
          let currentDate: any = {};
          let shiftType: any = "";
          for (let i = 0; i < result.length; i++) {
            if (result[i].employee !== "Total") {
              currentDate = new Date(result[i].currentDate);
              shiftType = result[i].shiftType;
              let displayDates: any = result[i].payPeriodDate.split("_");
              calnderDatesDispaly = displayDates;
              let tempRowObject: any = {};
              tempRowObject.rowNo = i;
              tempRowObject.showUserList = "showOptions";
              tempRowObject.multipleShiftVisibility = "hideDropdown";
              tempRowObject.bulkSelect = false;
              tempRowObject.shiftType = result[i].shiftType;
              if (result[i].shiftType === "UnAssigned") {
                tempRowObject.employeeImage = "";
                tempRowObject.employeeName = "Unassigned Shifts";
                tempRowObject.timeDetails = "";
                tempRowObject.timeDetailsSummary = "";
                tempRowObject.actualHour = 0;
              } else {
                tempRowObject.employeeImage = result[i].imagePath;
                tempRowObject.employeeName = result[i].employee;
                tempRowObject.timeDetails = result[i].ot;
                let time = result[i].ot.split("/");
                tempRowObject.actualHour = Number(
                  time[0].trim().replace(":", ".")
                );
                if (
                  Number(time[0].trim().replace(":", ".")) >
                  Number(time[1].trim().replace(":", "."))
                ) {
                  tempRowObject.timeDetailsSummary = "overtime";
                } else {
                  tempRowObject.timeDetailsSummary = "undertime";
                }
              }
              let day1: any = []; //0 :date, //1:dateday, //2:intime,
              let day2: any = []; //3:outtime,  //4:deptid,
              let day3: any = []; //5:positionid,  //6:position,
              let day4: any = []; //7:IsPublishShift, //8:IsMultiShift,
              let day5: any = []; //9:uniqueno, //10:indate,
              let day6: any = []; //11:outdate
              let day7: any = []; //12:scheduleTypeId === 3 = PTO Request or === 4 = PTO Approved or 5=UTO Request, 6=UTO Approved, 8=DNS Approved, 10 = DNS Request
              for (let j = 1; j <= 7; j++) {
                let pushFlag = "";
                let multiShiftPushArray: any = [];
                let dayValues: any = [];
                let day = `day${j}`;
                let shiftObject: any = {};
                if (result[i][day] === null) {
                  shiftObject.shiftDate = "";
                  shiftObject.shiftDay = "";
                  shiftObject.shiftInTime = "";
                  shiftObject.shiftOutTime = "";
                  shiftObject.displayTime = "";
                  shiftObject.deptId = "";
                  shiftObject.positionId = "";
                  if (shiftType === "UnAssigned") {
                    shiftObject.shiftPosition = "";
                  } else {
                    shiftObject.shiftPosition = "+";
                  }
                  shiftObject.optionsItems = "showOptions";
                  shiftObject.shiftType = "blankShift";
                  shiftObject.shiftIsMultiple = "";
                  shiftObject.shiftAction = "";
                  shiftObject.shiftbulkSelect = false;
                  shiftObject.uniqueno = "";
                  shiftObject.indate = "";
                  shiftObject.outdate = "";
                  shiftObject.typeMaster = "1";
                  shiftObject.actualPunches = "No";
                } else {
                  if (result[i][day].includes("!!")) {
                    pushFlag = "multipleShift";
                    let multiShift = result[i][day].split("!!");
                    for (let k = 0; k < multiShift.length; k++) {
                      dayValues = multiShift[k].split("||");
                      let pushObject: any = {};
                      pushObject.shiftDate = dayValues[0];
                      pushObject.shiftDay = dayValues[1];
                      if (dayValues[2] === "") {
                        pushObject.shiftInTime = "";
                        pushObject.shiftOutTime = "";
                        pushObject.displayTime = "";
                      } else {
                        pushObject.shiftInTime = dayValues[2];
                        pushObject.shiftOutTime = dayValues[3];
                        pushObject.displayTime = `${dayValues[2]} - ${dayValues[3]}`;
                      }
                      pushObject.deptId = dayValues[4];
                      pushObject.positionId = dayValues[5];
                      pushObject.optionsItems = "showOptions";
                      if (dayValues[2] === "") {
                        pushObject.shiftPosition = "+";
                      } else {
                        pushObject.shiftPosition = schConversions.replaceInvalidChar(
                          dayValues[6]
                        );
                      }
                      if (shiftType === "UnAssigned") {
                        pushObject.shiftType = "unassigned";
                      } else if (dayValues[7] === "Yes") {
                        pushObject.shiftType = "published";
                      } else {
                        pushObject.shiftType = "unpublished";
                      }
                      if (dayValues[2] === "") {
                        pushObject.shiftType = "blankShift";
                      }
                      pushObject.shiftIsMultiple = dayValues[8];
                      pushObject.shiftAction = "";
                      pushObject.shiftbulkSelect = false;
                      pushObject.uniqueno = dayValues[9];
                      pushObject.indate = dayValues[10];
                      pushObject.outdate = dayValues[11];
                      pushObject.typeMaster = dayValues[12];
                      pushObject.actualPunches = dayValues[13];

                      if (dayValues[12] === "3") {
                        pushObject.shiftPosition = "PTO";
                        pushObject.shiftType = "ptoRequest";
                      }
                      if (dayValues[12] === "4") {
                        pushObject.shiftPosition = "PTO";
                        pushObject.shiftType = "ptoApproved";
                      }
                      if (dayValues[12] === "5") {
                        pushObject.shiftPosition = "UTO ";
                        pushObject.shiftType = "ptoRequest";
                      }
                      if (dayValues[12] === "6") {
                        pushObject.shiftPosition = "UTO";
                        pushObject.shiftType = "ptoApproved";
                      }
                      if (dayValues[12] === "8") {
                        pushObject.shiftPosition = "Do not schedule";
                        pushObject.shiftType = "ptoApproved";
                      }
                      multiShiftPushArray.push(pushObject);
                    }
                  } else {
                    pushFlag = "singleShift";
                    dayValues = result[i][day].split("||");
                    shiftObject.shiftDate = dayValues[0];
                    shiftObject.shiftDay = dayValues[1];
                    if (dayValues[2] === "") {
                      shiftObject.shiftInTime = "";
                      shiftObject.shiftOutTime = "";
                      shiftObject.displayTime = "";
                    } else {
                      shiftObject.shiftInTime = dayValues[2];
                      shiftObject.shiftOutTime = dayValues[3];
                      shiftObject.displayTime = `${dayValues[2]} - ${dayValues[3]}`;
                    }
                    shiftObject.deptId = dayValues[4];
                    shiftObject.positionId = dayValues[5];
                    shiftObject.optionsItems = "showOptions";
                    if (dayValues[2] === "") {
                      if (calnderDatesDispaly[2] === "No") {
                        shiftObject.shiftPosition = " ";
                      } else {
                        shiftObject.shiftPosition = "+";
                      }
                    } else {
                      shiftObject.shiftPosition = schConversions.replaceInvalidChar(
                        dayValues[6]
                      );
                    }
                    if (shiftType === "UnAssigned") {
                      shiftObject.shiftType = "unassigned";
                    } else if (dayValues[7] === "Yes") {
                      shiftObject.shiftType = "published";
                    } else {
                      shiftObject.shiftType = "unpublished";
                    }
                    if (dayValues[2] === "") {
                      shiftObject.shiftType = "blankShift";
                    }
                    shiftObject.shiftIsMultiple = dayValues[8];
                    shiftObject.shiftAction = "";
                    shiftObject.shiftbulkSelect = false;
                    shiftObject.uniqueno = dayValues[9];
                    shiftObject.indate = dayValues[10];
                    shiftObject.outdate = dayValues[11];
                    shiftObject.typeMaster = dayValues[12];
                    shiftObject.actualPunches = dayValues[13];
                    if (dayValues[12] === "3") {
                      shiftObject.shiftPosition = "PTO";
                      shiftObject.shiftType = "ptoRequest";
                    }
                    if (dayValues[12] === "4") {
                      shiftObject.shiftPosition = "PTO";
                      shiftObject.shiftType = "ptoApproved";
                    }
                    if (dayValues[12] === "5") {
                      shiftObject.shiftPosition = "UTO ";
                      shiftObject.shiftType = "ptoRequest";
                    }
                    if (dayValues[12] === "6") {
                      shiftObject.shiftPosition = "UTO";
                      shiftObject.shiftType = "ptoApproved";
                    }
                    if (dayValues[12] === "8") {
                      shiftObject.shiftPosition = "Do not schedule";
                      shiftObject.shiftType = "ptoApproved";
                    }
                  }
                }

                if (pushFlag === "multipleShift") {
                  let multiShiftAddObject: any = {};
                  multiShiftAddObject.shiftDate = "";
                  multiShiftAddObject.shiftDay = "";
                  multiShiftAddObject.shiftInTime = "";
                  multiShiftAddObject.shiftOutTime = "";
                  multiShiftAddObject.displayTime = "";
                  multiShiftAddObject.deptId = "";
                  multiShiftAddObject.positionId = "";
                  multiShiftAddObject.shiftPosition = "+";
                  multiShiftAddObject.shiftType = "blankShift";
                  multiShiftAddObject.shiftIsMultiple = "";
                  multiShiftAddObject.shiftAction = "";
                  multiShiftAddObject.shiftbulkSelect = false;
                  multiShiftAddObject.uniqueno = "";
                  multiShiftAddObject.indate = "";
                  multiShiftAddObject.outdate = "";
                  multiShiftAddObject.typeMaster = "1";
                  multiShiftAddObject.actualPunches = "No";
                  if (multiShiftPushArray.length > 0) {
                    const firtstElemMultiShift = multiShiftPushArray[0];
                    const shiftDateFitstElement =
                      firtstElemMultiShift.shiftDate;
                    const dateShiftDateFirstElement = new Date(
                      shiftDateFitstElement
                    );
                    const currentDate = result[i].currentDate;
                    const dateCurrentDate = new Date(currentDate);
                    if (dateShiftDateFirstElement >= dateCurrentDate) {
                      multiShiftPushArray.push(multiShiftAddObject);
                    }
                  }
                  if (day === "day1") {
                    day1 = multiShiftPushArray;
                  } else if (day === "day2") {
                    day2 = multiShiftPushArray;
                  } else if (day === "day3") {
                    day3 = multiShiftPushArray;
                  } else if (day === "day4") {
                    day4 = multiShiftPushArray;
                  } else if (day === "day5") {
                    day5 = multiShiftPushArray;
                  } else if (day === "day6") {
                    day6 = multiShiftPushArray;
                  } else if (day === "day7") {
                    day7 = multiShiftPushArray;
                  }
                } else {
                  if (day === "day1") {
                    day1.push(shiftObject);
                  } else if (day === "day2") {
                    day2.push(shiftObject);
                  } else if (day === "day3") {
                    day3.push(shiftObject);
                  } else if (day === "day4") {
                    day4.push(shiftObject);
                  } else if (day === "day5") {
                    day5.push(shiftObject);
                  } else if (day === "day6") {
                    day6.push(shiftObject);
                  } else if (day === "day7") {
                    day7.push(shiftObject);
                  }
                }
                if (dayValues.length > 0 && result[i].employee === "header") {
                  let testDate = new Date(dayValues[0]);
                  if (testDate < currentDate) {
                    lockDays.push(day);
                  }
                  let tempHeaderDate = dayValues[0].substring(0, 5);
                  headerDates.push(tempHeaderDate);
                  headerFullDates.push(dayValues[0]);
                  headerDays.push(dayValues[1]);
                }
                tempRowObject.day1 = day1;
                tempRowObject.day2 = day2;
                tempRowObject.day3 = day3;
                tempRowObject.day4 = day4;
                tempRowObject.day5 = day5;
                tempRowObject.day6 = day6;
                tempRowObject.day7 = day7;
              }
              tempScheduleData.push(tempRowObject);
            } else {
              let footerTotalData: any = [];
              footerTotalData = result[i].ot.split("/");
              for (let j = 1; j <= 7; j++) {
                let footerObject: any = {};
                let dayValues: any = [];
                let day = `day${j}`;
                dayValues = result[i][day].split("||");
                footerObject.day = day;
                footerObject.total = dayValues[0];
                footerObject.actual = dayValues[1];
                footerData.push(footerObject);
              }
              footerTotal = footerTotalData;
            }
          }
          const uniqueLockDays: any = new Set(lockDays);
          const arrayLockDays = [...uniqueLockDays];
          const uniqueHeaderDates: any = new Set(headerDates);
          const arrayHeaderDates = [...uniqueHeaderDates];
          const uniqueHeaderFullDates: any = new Set(headerFullDates);
          const arrayHeaderFullDates = [...uniqueHeaderFullDates];
          const uniqueHeaderDays: any = new Set(headerDays);
          const arrayHeaderDays = [...uniqueHeaderDays];
          if (arrayLockDays.length >= 7) {
            isPageLock = true;
          }
          if (calnderDatesDispaly[2] === "Yes") {
            isBannerShow = false;
            bannerType = "";
          } else {
            isBannerShow = true;
            if (calnderDatesDispaly[2] === "No") {
              bannerType = "noForecast";
            } else {
              bannerType = "errorForecast";
            }
          }
          let offSetHour = 0;
          if (calnderDatesDispaly.length > 2) {
            offSetHour = calnderDatesDispaly[3];
          }
          if (departmentID > 0) {
            this.filterDataByDepartment(departmentID, tempScheduleData.filter((row) => row.shiftType !== "UnAssigned")
            );
          }
          this.setState(
            {
              isPageLock: isPageLock,
              lockDays: arrayLockDays,
              headerDates: arrayHeaderDates,
              headerFullDates: arrayHeaderFullDates,
              headerDays: arrayHeaderDays,
              navigationCalenderDates: calnderDatesDispaly,
              offSetHour: offSetHour,
              currentDate: currentDate,
              footerDayData: footerData,
              footerDayTotal: footerTotal,
              importForecastBanner: isBannerShow,
              importForecastBannerType: bannerType,
              dragStartSchedule: {},
              bulkSelect: false,
            });
        }
        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "scheduleMain",
        });
        this.setState({ loaderTrue: false });
        reject();
      });
  };

  employeeNameForematter = (cell: any, row: any, rowIndex: any) => {
    return (
      <div className="employee-set d-flex" draggable={false}>
        <div className="employee-image">
          <img src={row.employeeImage} alt="" />
        </div>
        <div className="employee-details mr-auto">
          <EllipsisWithTooltip placement="bottom">
            <span
              className={
                row.timeDetailsSummary === "undertime"
                  ? " employeeName"
                  : "employeeName red"
              }
            >
              {row.employeeName}
            </span>
          </EllipsisWithTooltip>
          <span
            className={
              row.timeDetailsSummary === "undertime"
                ? " employeeTimeDetails"
                : "employeeTimeDetails red"
            }
          >
            {row.timeDetails}
          </span>
        </div>
      </div>
    );
  }
  headerFormatter = (column: any, colIndex: any, row: any) => {
    let scheduleLockDisplay = false;
    let lockDays = this.state.lockDays;
    let headerDates = this.state.headerDates;
    let headerDays = this.state.headerDays;

    if (lockDays.includes(column.dataField)) {
      scheduleLockDisplay = true;
    }

    return (
      <div className="column-header">
        <div className="header-data">
          {scheduleLockDisplay && (
            <span className="schedule-lock">
              <img src={ForecastLock} alt="ScheduleLock" />
              <span className="customToolTip">
                The schedule cannot be changed because a shift from this day has
                already begun.
              </span>
            </span>
          )}

          <span className="header-day">{headerDays[colIndex - 1]}</span>
        </div>
        <p className="header-date">{headerDates[colIndex - 1]}</p>
      </div>
    );
  };

  dayFormatter = (cell: any, row: any, rowIndex: any, dayType: any) => {
    let currentRow = row[dayType];
    let schedule: any = {};
    if ((currentRow.filter(a => a.deptId !== "")).length <= 1) {
      if ((currentRow.filter(a => a.deptId !== "")).length === 1) {
        schedule = row[dayType].filter(a => a.deptId !== "")[0];
      } else {
        schedule = row[dayType][0];
      }

      let showPosition = true;

      let scheduleClassName: any = `schedule-type ${schedule.shiftType}`;
      if (row.shiftType === "UnAssigned") {
        if (
          this.state.lockDays.includes(dayType) ||
          this.state.importForecastBanner
        ) {
          if (schedule.shiftPosition.trim() === "") {
            scheduleClassName = `schedule-type ${schedule.shiftType} dayLockedUnassignedBlankShifts`;
          } else {
            scheduleClassName = `schedule-type ${schedule.shiftType} dayLockedUnassignedNonBlankShifts`;
          }
        } else {
          if (schedule.shiftPosition.trim() === "") {
            scheduleClassName = `schedule-type ${schedule.shiftType} unassignedBlankShifts`;
          }
        }
      } else {
        if (schedule.shiftPosition.trim() === "PTO") {
          if (this.state.lockDays.includes(dayType)) {
            scheduleClassName = `schedule-type ${schedule.shiftType} dayLocked`;
          }
        } else {
          if (
            this.state.lockDays.includes(dayType) ||
            this.state.importForecastBanner
          ) {
            if (schedule.shiftPosition.trim() === "") {
              scheduleClassName = `schedule-type ${schedule.shiftType} dayLockedBlankShift`;
            } else {
              scheduleClassName = `schedule-type ${schedule.shiftType} dayLocked`;
            }
          }
        }
      }

      return (
        <div className="singleShift">
          {schedule.optionsItems === "showOptions" && (
            <div
              className={
                schedule.shiftbulkSelect &&
                  schedule.shiftAction === "bulkSelect"
                  ? `${scheduleClassName} ${schedule.shiftAction} bulkTrue`
                  : `${scheduleClassName} ${schedule.shiftAction}`
              }>
              <Dropdown className="more-action">
                <Dropdown.Toggle
                  className="btn-outline-primary btn btn-primary more"
                  id="dropdown-more"
                >
                  <div className="d-flex">
                    <div className="details">
                      <span className="schedule-time">
                        {schedule.displayTime}
                      </span>
                      {showPosition && (
                        <EllipsisWithTooltip placement="bottom">
                          <span className="schedule-details">
                            {schedule.shiftPosition === "+" ? "" : schedule.shiftPosition}
                          </span>
                        </EllipsisWithTooltip>
                      )}
                    </div>
                  </div>
                </Dropdown.Toggle>
              </Dropdown>
            </div>
          )}
        </div>
      );
    } else {
      let shiftBulkSelectArray: any = [];
      let shiftPositionArray: any = [];
      let shiftClassArray: any = [];
      let shiftActionArray: any = [];
      let dropDownClassName: any = "";
      let shiftCount: any = 0;
      let bulkSelectParent: any = "fullCheck";

      currentRow.forEach((data: any) => {
        if (data.shiftPosition.trim() === "" || data.shiftPosition.trim() === "+") {
        } else {
          shiftPositionArray.push(data.shiftPosition);
          shiftClassArray.push(data.shiftType);
          shiftActionArray.push(data.shiftAction);
          shiftBulkSelectArray.push(data.shiftbulkSelect);
          shiftCount = shiftCount + 1;
        }
      });

      if (shiftPositionArray.includes("+")) {
        let indexOfPlus = shiftPositionArray.indexOf("+");
        if (indexOfPlus > -1) {
          shiftPositionArray.splice(indexOfPlus, 1);
        }
      }
      if (shiftClassArray.includes("blankShift")) {
        let indexOfBlank = shiftClassArray.indexOf("blankShift");
        if (indexOfBlank > -1) {
          shiftClassArray.splice(indexOfBlank, 1);
        }
      }

      const uniqueShiftBulkSelect: any = new Set(shiftBulkSelectArray);
      const shiftBulkSelect = [...uniqueShiftBulkSelect];
      const uniqueShiftPoition: any = new Set(shiftPositionArray);
      const shiftPositions = [...uniqueShiftPoition];
      const uniqueShiftType: any = new Set(shiftClassArray);
      const shiftTypes = [...uniqueShiftType];
      const uniqueShiftAction: any = new Set(shiftActionArray);
      const shiftActions = [...uniqueShiftAction];

      if (shiftBulkSelect.length === 1) {
        if (shiftBulkSelect[0] === false) {
          bulkSelectParent = "noCheck";
        }
      } else {
        if (shiftBulkSelect.includes(true)) {
          bulkSelectParent = "halfCheck";
        }
      }

      if (
        shiftTypes.includes("unpublished") ||
        shiftTypes.includes("ptoRequest")
      ) {
        dropDownClassName =
          "btn-outline-primary btn btn-primary more unpublished";
      } else {
        dropDownClassName =
          "btn-outline-primary btn btn-primary more published";
      }

      if (shiftActions.includes("wfres")) {
        dropDownClassName = "btn-outline-primary btn btn-primary more wfres";
      }

      let isDayLock = false;
      let bulkSelectShow = true;
      if (this.state.lockDays.includes(dayType)) {
        isDayLock = true;
        bulkSelectShow = false;
      }

      if (shiftPositions.length === 1 && shiftPositions[0] === "PTO") {
        bulkSelectShow = false;
      }

      return (
        <div className="multipleShift">
          {row.multipleShiftVisibility === "hideDropdown" && (
            <div
              className={
                isDayLock ? "mulitpleShift dayLockedMultiple" : "mulitpleShift"
              }
            >
              <Dropdown className="multiple-shift-dropdown" draggable={false}>
                <Dropdown.Toggle
                  className={
                    row.bulkSelect && bulkSelectShow
                      ? `${dropDownClassName} bulkSelect ${bulkSelectParent}`
                      : dropDownClassName
                  }
                  id="dropdown-more"
                  draggable={false}
                >
                  {row.bulkSelect && bulkSelectShow && (
                    <div>
                      <Form.Check
                        className={bulkSelectParent}
                        id="multiShiftBulkSelect"
                        type="checkbox"
                        label=""
                      />
                    </div>
                  )}
                  <div className="details">
                    <EllipsisWithTooltip placement="bottom">
                      <span className="schedule-time">
                        {shiftCount <= 1
                          ? `${shiftCount} Shift`
                          : `${shiftCount} Shifts`}
                      </span>
                    </EllipsisWithTooltip>
                    <EllipsisWithTooltip placement="bottom">
                      <span className="schedule-details">
                        {shiftPositions.length > 1
                          ? `${shiftPositions.length} Positions`
                          : shiftPositions[0]}
                      </span>
                    </EllipsisWithTooltip>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu draggable={false}>
                  <Dropdown.Item draggable={false} className="ifCusOver">
                    {currentRow.map((schdeule: any, index: any) => {
                      let className = `schedule-type ${schdeule.shiftType} ${schdeule.shiftAction}`;

                      if (isDayLock) {
                        className = `schedule-type ${schdeule.shiftType} dayLocked`;
                      }

                      return (
                        <div key={index} className={schdeule.shiftbulkSelect ? `${className} bulkTrue`
                          : className} draggable={false} >
                          <Dropdown
                            className="more-action dropdownItemDefaultPadding"
                            onClick={(e: any) => {
                              e.stopPropagation();
                            }}
                          >
                            <Dropdown.Toggle
                              className="btn-outline-primary btn btn-primary more"
                              id="dropdown-more"
                            >
                              <div className="details">
                                <span className="schedule-time">
                                  {schdeule.displayTime}
                                </span>
                                <EllipsisWithTooltip placement="bottom">
                                  <span className="schedule-details sos">
                                    {schdeule.shiftPosition}
                                  </span>
                                </EllipsisWithTooltip>
                              </div>
                            </Dropdown.Toggle>
                          </Dropdown>
                        </div>
                      );
                    })}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </div>
      );
    }
  };

  render() {
    const columns = [
      {
        dataField: "employeeName",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) =>
          this.employeeNameForematter(cell, row, rowIndex),
      },
      {
        dataField: "day1",
        text: "",
        headerFormatter: this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) => this.dayFormatter(cell, row, rowIndex, "day1")
      },
      {
        dataField: "day2",
        text: "",
        headerFormatter: this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) => this.dayFormatter(cell, row, rowIndex, "day2"),
        searchable: false,
      },
      {
        dataField: "day3",
        text: "",
        headerFormatter: this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) => this.dayFormatter(cell, row, rowIndex, "day3"),
        searchable: false,
      },
      {
        dataField: "day4",
        text: "",
        headerFormatter: this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) => this.dayFormatter(cell, row, rowIndex, "day4"),
        searchable: false,
      },
      {
        dataField: "day5",
        text: "",
        headerFormatter: this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) => this.dayFormatter(cell, row, rowIndex, "day5"),
        searchable: false,
      },
      {
        dataField: "day6",
        text: "",
        headerFormatter: this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) => this.dayFormatter(cell, row, rowIndex, "day6"),
        searchable: false,
      },
      {
        dataField: "day7",
        text: "",
        headerFormatter: this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) => this.dayFormatter(cell, row, rowIndex, "day7"),
        searchable: false,
      },
    ];
    return (
      <div className="pos-fxd index-1010">
        {/* <ToastContainer autoClose={3000} /> */}
        <div className="schedule-main d-flex">
          <div className="backDrop back-drop" onClick={this.closeSlideOut}></div>
          <Container fluid className="body-sec performance-position timesheet-details justify-content-end rm-padding">
            <div className="stickey">
              <div className="page-heading underline d-flex">
                <div className="hid-set d-flex align-items-center">
                  {this.props.hotel!==undefined && (this.props.hotel.lettercode)}
                </div>
                <div className="mr-auto d-flex align-items-center">
                  <span className="text-capitalize pr-2">{this.props.rowEventData!==undefined && (this.props.rowEventData.deptName.toLowerCase())}</span> Schedule
                </div>
                <div className="action-group d-flex flex-row">
                  <button type="button" className="btn wht-bg cross" onClick={this.closeSlideOut}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z" fill="#84888C" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="add-padding userViewTable noDeepLink" id="deepToTop">
              <br />
              <div>
                {this.state.loaderTrue && (<ReactPageLoader useas={"scheduleUserView"} />)}
              </div>
              <div className="schedule-main">
                <div className="userViewTable">
                  <div className="schedule-user-view-table read-only">
                    {this.state.headerDates.length > 0 && (
                      <div className="table otarSlideOutTable">
                        <BootstrapTable
                          keyField="rowNo"
                          data={this.state.tableData}
                          columns={columns}
                          hover
                        //rowClasses={rowClasses}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}
