import React from "react";
import {
  Dropdown,
  Modal,
  Button,
  Form,
  Spinner
} from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import moment from "moment";

let localStorageUser: any;

export class RegisterExport extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      loaderTrue: false,
      dateRange: [
        { name: "Transaction Date", eventKey: "Transaction Date" },
        { name: "Accounting Period", eventKey: "Accounting Period" },
      ],
      dateRangeSelected: "Transaction Date",
      startDate: `01/01/${new Date().getFullYear()}`,
      endDate: `12/31/${new Date().getFullYear()}`,
    };
  }
  registerExoprtModalClose = () => {
    this.props.registerExoprtModalClose();
  };

  render() {
    return (
      <div>
        <Modal
          className="add-edit-shift-modal SOS"
          show={this.props.parentState.exportModal}
          onHide={this.registerExoprtModalClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Export</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="dept-pos d-flex">
              <Form.Group controlId="select-department">
                <Form.Label>Date Range</Form.Label>
                <Dropdown className="import-from-dropdown">
                  <Dropdown.Toggle id="dropdown-importFrom">
                    <span className="selected">
                      {this.state.dateRangeSelected}
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div>
                      {this.state.dateRange.map((item: any, idx: any) => (
                        <Dropdown.Item
                          key={idx}
                          eventKey={item.id}
                          onClick={() => this.handleDateRangeChange(item)}
                        >
                          {item.name}
                        </Dropdown.Item>
                      ))}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
            </div>
            <div className="dept-pos d-flex date-picker">
              <Form.Group controlId="select-department">
                <Form.Label>Starting </Form.Label>
                <DatePicker
                  //value={this.state.startDate}
                  selected={new Date(this.state.startDate)}
                  name="startDate"
                  className={`form-control registerExportInput`}
                  id="shiftStartDate"
                  placeholderText="mm/dd/yyyy"
                  required
                  autoComplete="off"
                  onSelect={(e: any) => this.handleSelectDate(e, "startDate")}
                  onChange={(e: any) => this.handleSelectDate(e, "startDate")}
                />
              </Form.Group>

              <Form.Group className="form-group-register-export" controlId="select-position">
                <Form.Label>Thru</Form.Label>
                <DatePicker
                  //value={this.state.endDate}
                  selected={new Date(this.state.endDate)}
                  name="endDate"
                  className={`form-control registerExportInput`}
                  id="shiftStartDate"
                  placeholderText="mm/dd/yyyy"
                  required
                  autoComplete="off"
                  onSelect={(e: any) => this.handleSelectDate(e, "endDate")}
                  onChange={(e: any) => this.handleSelectDate(e, "endDate")}
                />
              </Form.Group>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={this.state.loaderTrue}
              className="import-forecast-button"
              onClick={() => this.handleExportClick()}
            >
              {this.state.loaderTrue ? <><Spinner size="sm" animation="border" />{" "}Processing</> : "Export"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  handleDateRangeChange = (item: any) => {
    this.setState({ dateRangeSelected: item.name });
  };
  handleSelectDate = (e: any, type: any) => {
    if (type === "startDate")
      this.setState({
        startDate: moment(e).format("DD-MMM-YYYY"), //e.toLocaleDateString(),
      });
    else
      this.setState({
        endDate: moment(e).format("DD-MMM-YYYY"), //e.toLocaleDateString(),
      });
  };
  handleExportClick=()=>{
    this.setState({loaderTrue:true});
    this.props.exportToExcel(this.state.dateRangeSelected.split(' ')[0],this.state.startDate,this.state.endDate);
  }
  stopLoader=()=>{
    this.setState({loaderTrue:false});
  }
}
