import { accountService } from "../Services/account";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { Utils } from "../Utilis";
export class PlaidSetup {

    static IntegratePlaid1 = () => {
        console.log(document.getElementById("lblSetupStatus"));
    }

    static IntegratePlaid = (hid, bankRefID, accountName, plaidStatusCallbank) => {
        accountService.GetLinkToken()
            .then(async (result) => {
                if (result !== null) {
                    let token = result;
                    let linkHandler = window.Plaid.create({
                        // Make a request to your server to fetch a new link_token.
                        token: token,
                        onLoad: function () {
                            // The Link module finished loading.
                        },
                        onSuccess: function (public_token, metadata) {
                            var AccountId = metadata.account_id;
                            PlaidSetup.sendLinkTokenData(public_token, AccountId, hid, bankRefID, accountName, plaidStatusCallbank);
                        },
                        onExit: function (err, metadata) {
                            // console.log('plaid-exit',metadata,err);
                            plaidStatusCallbank(err, "exit")
                            if (err != null) {
                                console.log(err);
                                plaidStatusCallbank(err, "error")
                            }
                        },
                    });
                    linkHandler.open();
                }
            });
    };

    static sendLinkTokenData(public_token, AccountId, hid, bankRefID, accountName, plaidStatusCallbank) {
        accountService.GetAccessToken(public_token)
            .then(async (result) => {
                if (result !== null) {
                    var Access_token = result;
                    PlaidSetup.sendAcessTokenData(Access_token, AccountId, hid, bankRefID, accountName, plaidStatusCallbank)
                }
            });
    }

    static sendAcessTokenData(Access_token, AccountId, hid, bankRefID, accountName, plaidStatusCallbank) {
        accountService.GetProcessorTokenForHotel(Access_token, bankRefID, AccountId, hid)
            .then(async (result) => {
                if (result !== null) {
                    let Processor_token = result[0].processor_token;
                    PlaidSetup.sendProcessorTokenToDwolla(Processor_token, accountName, hid, bankRefID, plaidStatusCallbank)
                }
            });
    }

    static sendProcessorTokenToDwolla(Processor_token, accountName, hid, bankRefID, plaidStatusCallbank) {
        accountService.GetDwollaFundingToken(Processor_token, accountName, hid)
            .then(async (result) => {
                if (result !== null) {
                    if (result[0].status === "Success") {
                        let FundingSourceId = result[0].code;
                        PlaidSetup.saveFundingSourceHotel(hid, FundingSourceId, bankRefID)
                    } else {
                        Utils.toastError(result[0].message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        if (plaidStatusCallbank !== null) {
                            plaidStatusCallbank(result[0], result[0].message)
                        }
                    }

                }
            });
    }

    static saveFundingSourceHotel(hid, FundingSourceId, bankRefID) {
        accountService.saveFundingSourceHotel(hid, FundingSourceId, bankRefID)
            .then(async (result) => {
                if (result !== null) {
                    if (result.saveStatus === 'Success') {
                        let lblStatus = document.getElementById("lblSetupStatus");
                        lblStatus.innerHTML = result.saveStatus;
                    }
                    else {
                        Utils.toastError("Something went wrong, Please try again later!", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            containerId: "bankDetailsSlideout",
                        });
                    }
                }
            });
    }

    //Reconnect bank account with plaid
    static ReconectHotelLinkToken(hid, bankRefID) {
        accountService.ReconectHotelLinkToken(bankRefID)
            .then(async (result) => {
                if (result !== null) {
                    let linkHandler = window.Plaid.create({
                        // Make a request to your server to fetch a new link_token.
                        token: result.code,
                        onLoad: function () {
                            // The Link module finished loading.
                        },
                        onSuccess: function (public_token, metadata) {
                            PlaidSetup.sendLinkTokenDataReconnect(public_token, bankRefID, hid);
                        },
                        onExit: function (err, metadata) {
                            if (err != null) {
                                console.log(err);
                            }
                        },
                    });
                    linkHandler.open();
                }
            });
    }

    static sendLinkTokenDataReconnect(public_token, bankRefID, hid) {
        accountService.GetAccessToken(public_token)
            .then(async (result) => {
                if (result !== null) {
                    var Access_token = result;
                    PlaidSetup.UpdateAccessToken(bankRefID, hid, Access_token);
                }
            })
    }

    static UpdateAccessToken(bankRefID, hid, Access_token) {
        accountService.UpdateNewAccessToken(bankRefID, hid, Access_token)
            .then(async (result) => {
                if (result !== null) {
                    if (result.status === 'Success' || result.Status === 'Success') {
                        let resultStatus = "";
                        if (!result.status) {
                            resultStatus = result.Status;
                        } else {
                            resultStatus = result.status;
                        }
                        let lblStatus = document.getElementById("lblSetupStatus");
                        lblStatus.innerHTML = resultStatus;
                        // toast.success('You account has been reconnected successfully.', {
                        //     position: toast.POSITION.BOTTOM_RIGHT,
                        //     containerId: "bankDetailsSlideout",
                        // });
                    } else {
                        Utils.toastError("Something went wrong, Please try again later!", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            containerId: "bankDetailsSlideout",
                        });
                    }
                }
            })
    }

    ///Disabled ACH
    static disabledACH(hid, bankRefID) {
        let status = "Disable";
        accountService.CheckUnpaidDigitalACH(bankRefID, hid)
            .then(async (result) => {
                if (result !== null) {
                    if (result.Status === 'YesIsUnpaid') {
                        confirmAlert({
                            title: "Confirmation",
                            message:
                                "Any unpaid invoices previously set to pay by Digital ACH from this bank account have been set to pay by check.",
                            buttons: [
                                {
                                    label: "Yes",
                                    onClick: () => PlaidSetup.DisableACHIFYES(bankRefID, hid, status),
                                },
                                {
                                    label: "No",
                                    onClick: () => PlaidSetup.reject(),
                                },
                            ],
                            closeOnEscape: false,
                            closeOnClickOutside: false,
                        });
                    }
                    else {
                        PlaidSetup.DisableACHIFYES(bankRefID, hid, status);
                    }
                }
            })
    }

    static reject() {
        return false;
    }

    static DisableACHIFYES(bankRefID, hid, status) {
        accountService.CustomerFundingSourceUpdate(bankRefID, hid, status)
            .then(async (result) => {
                if (result !== null) {
                    if (result.status === 'Success') {
                        // toast.success('Bank account has been disabled for Digital ACH successfully.', {
                        //     position: toast.POSITION.BOTTOM_RIGHT,
                        //     containerId: "bankDetailsSlideout",
                        // });
                        let lblStatus = document.getElementById("lblSetupStatus");
                        lblStatus.innerHTML = result.status;
                    }
                    else {
                        Utils.toastError("Something went wrong, Please try again later!", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            containerId: "bankDetailsSlideout",
                        });
                    }
                } else {
                    Utils.toastError("Something went wrong, Please try again later!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        containerId: "bankDetailsSlideout",
                    });
                }
            })
    }

    static IntegratePlaidForTransactionlog = () => {
        accountService.GetLinkToken()
            .then(async (result) => {
                if (result !== null) {
                    let token = result;
                    let linkHandler = window.Plaid.create({
                        // Make a request to your server to fetch a new link_token.
                        token: token,
                        onLoad: function () {
                            // The Link module finished loading.
                        },
                        onSuccess: function (public_token, metadata) {
                            var AccountId = metadata.account_id;
                            console.log("Token " + public_token);
                            accountService.sendPublicTokenData(public_token, AccountId)
                                .then(async (result) => {
                                    //debugger;
                                    if (result !== null) {
                                        toast.success(result.message, {
                                            position: toast.POSITION.BOTTOM_RIGHT,
                                        });
                                    }
                                });
                        },
                        onExit: function (err, metadata) {
                            // console.log('plaid-exit',metadata,err);
                            // plaidStatusCallbank(err,"exit")
                            // if (err != null) {
                            //     console.log(err);
                            //     plaidStatusCallbank(err,"error")
                            // }
                        },
                    });
                    linkHandler.open();
                }
            });
    };
    static LinkBankAccount = (OprID, pullTranDate, plaidStatusCallbank) => {
        accountService.GetLinkToken()
            .then(async (result) => {
                if (result !== null) {
                    let token = result;
                    let linkHandler = window.Plaid.create({
                        // Make a request to your server to fetch a new link_token.
                        token: token,
                        onLoad: function () {
                            // The Link module finished loading.
                        },
                        onSuccess: function (public_token, metadata) {
                            var AccountId = metadata.account_id;
                            accountService.sendPublicTokenOprData(public_token, AccountId, OprID, pullTranDate)
                                .then(async (result) => {
                                    if (result?.saveStatus === "Fail") {
                                        Utils.toastError(result.message, {
                                            position: toast.POSITION.BOTTOM_RIGHT,
                                        });
                                        plaidStatusCallbank("Fail");
                                    }
                                    else if (result?.saveStatus === "Success") {
                                        toast.success(result.message, {
                                            position: toast.POSITION.BOTTOM_RIGHT,
                                        });
                                        plaidStatusCallbank("success");
                                    }
                                });
                        },
                        onExit: function (err, metadata) {
                            plaidStatusCallbank("Exit");
                        },
                    });
                    linkHandler.open();
                }
            });
    };

    //Reconnect bank account with plaid
    static ReconectBank(hid, oprID, reloadBackPage) {
        accountService.ReconectBank(hid, oprID)
            .then(async (result) => {
                if (result !== null) {
                    if (result.status === "Success") {
                        let linkHandler = window.Plaid.create({
                            token: result.code,
                            onLoad: function () {
                            },
                            onSuccess: function (token, metadata) {
                                //debugger
                                //PlaidSetup.sendLinkTokenBankReconnect(token, oprID, hid);
                                reloadBackPage('success', token);
                            },
                            onExit: function (err, metadata) {
                                reloadBackPage('Exit', '');
                            },
                        });
                        linkHandler.open();
                    } else if (result.status === "AccessTokenNotFound") {
                        Utils.toastError("Access token not found for this account.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            containerId: "bankDetailsSlideout",
                        });
                        reloadBackPage('tokenNotFound', '');
                    } else {
                        Utils.toastError("Something went wrong, Please try again later!", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            containerId: "bankDetailsSlideout",
                        });
                    }
                }
            });
    }

    static sendLinkTokenBankReconnect(public_token, OPRID, hid, updateToken) {
        accountService.GetAccessToken(public_token)
            .then(async (result) => {
                if (result !== null) {
                    var Access_token = result;
                    updateToken(Access_token);
                    //PlaidSetup.UpdateBankAccessToken(OPRID, hid, Access_token);
                }
            })
    }

    static UpdateBankAccessToken(OPRID, hid, Access_token, reloadBackPage) {
        accountService.UpdateNewBankAccessToken(OPRID, hid, Access_token)
            .then(async (result) => {
                if (result !== null) {
                    if (result?.status === 'Success' || result?.Status === 'Success') {
                        toast.success('You account has been reconnected successfully.', {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            containerId: "bankDetailsSlideout",
                        });
                        reloadBackPage('success');
                    } else {
                        Utils.toastError("Something went wrong, Please try again later.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            containerId: "bankDetailsSlideout",
                        });
                        reloadBackPage('fail');
                    }
                }
            })
    }

    static LinkBankAccountPlaid = (connectionName, bankName, selectedOprIDs, plaidStatusCallbank) => {
        accountService.GetLinkToken()
            .then(async (result) => {
                if (result !== null) {
                    let token = result;
                    let linkHandler = window.Plaid.create({
                        // Make a request to your server to fetch a new link_token.
                        token: token,
                        onLoad: function () {
                            // The Link module finished loading.
                        },
                        onSuccess: function (public_token, metadata) {
                            var AccountId = metadata.account_id;
                            plaidStatusCallbank("startLoader", "");
                            accountService.InitializePlaidSetup(public_token, AccountId, connectionName, bankName, selectedOprIDs)
                                .then(async (result) => {
                                    if (result?.message === "Fail") {
                                        Utils.toastError(result?.result, {
                                            position: toast.POSITION.BOTTOM_RIGHT,
                                        });
                                        plaidStatusCallbank("Fail", result?.result);
                                    } else if (result?.message === "DuplicateMapping") {
                                        Utils.toastError("This has been already mapped or duplicate connection name", {
                                            position: toast.POSITION.BOTTOM_RIGHT,
                                        });
                                        plaidStatusCallbank("DuplicateMapping", result?.result);
                                    }
                                    else if (result?.message === "Success") {
                                        plaidStatusCallbank("success", result?.result);
                                    }
                                }).catch((error) => {
                                    accountService.LogConnectionProcess(selectedOprIDs, "LinkBankAccountPlaid-InitializePlaidSetup", JSON.stringify(result), error.toString());
                                    plaidStatusCallbank("Fail", result?.result);
                                });
                        },
                        onExit: function (err, metadata) {
                            plaidStatusCallbank("Exit", "");
                        },
                        onEvent: (eventName, metadata) => {
                            accountService.LogConnectionProcess(selectedOprIDs, eventName, JSON.stringify(metadata), "");
                        },
                    });
                    linkHandler.open();
                } else {
                    plaidStatusCallbank("InvalidClient", "");
                }
            });
    };

    static InitializePlaidReconnect(ConnectionID, ConnectionType, callBackPage) {
        accountService.InitializePlaidReconnect(ConnectionID, ConnectionType)
            .then(async (result) => {
                if (result !== null) {
                    if (result?.message === "Success") {
                        let token = "";
                        if (result?.result[0]?.accounts === undefined)
                            token = result?.result[0];
                        else
                            token = result?.result[0]?.accounts[0]?.accessToken;

                        let linkHandler = window.Plaid.create({
                            token: token,
                            onLoad: function () {
                            },
                            onSuccess: function (public_token, metadata) {
                                callBackPage('success', public_token, result?.result);
                            },
                            onExit: function (err, metadata) {
                                callBackPage('Exit', '', '');
                            },
                            onEvent: (eventName, metadata) => {
                                accountService.LogConnectionProcess(ConnectionID, eventName, JSON.stringify(metadata), "");
                            },
                        });
                        linkHandler.open();
                    } else if (result?.result === "AccessTokenNotFound") {
                        Utils.toastError("Access token not found for this account.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        callBackPage('tokenNotFound', '', '');
                    } else if (result?.result?.toUpperCase() == "ITEM_LOGIN_REQUIRED") {
                        Utils.toastError("The login details of this item have changed (credentials, MFA, or required user action).", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        callBackPage('LoginExpired', '', '');
                    } else {
                        Utils.toastError(result?.result?.charAt(0).toUpperCase() + result?.result?.slice(1), {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        callBackPage('SomeError', '', '');
                    }
                }
            });
    }

    static InitializePlaidUpdateMode(ConnectionID, ConnectionType, callBackPage) {
        accountService.InitializePlaidUpdateMode(ConnectionID, ConnectionType)
            .then(async (result) => {
                if (result !== null) {
                    //debugger
                    if (result?.message === "Success") {
                        let token = result?.result;
                        let linkHandler = window.Plaid.create({
                            token: token,
                            onLoad: function () {
                            },
                            onSuccess: function (public_token, metadata) {
                                callBackPage("startLoader", "", "");
                                accountService.GetBankUpdatedDetails(ConnectionID, ConnectionType)
                                    .then(async (result) => {
                                        //debugger
                                        if (result !== null) {
                                            if (result?.result?.message?.toUpperCase() === "SUCCESS") {
                                                callBackPage('success', public_token, result?.result?.result);
                                            } else if (result?.result?.message?.toUpperCase() == "ITEM_LOGIN_REQUIRED") {
                                                Utils.toastError("The login details of this item have changed (credentials, MFA, or required user action).", {
                                                    position: toast.POSITION.BOTTOM_RIGHT,
                                                });
                                                callBackPage('LoginExpired', '', '');
                                            } else {
                                                Utils.toastError("Something went wrong, Please try again later!", {
                                                    position: toast.POSITION.BOTTOM_RIGHT,
                                                });
                                                callBackPage('SomeError', '', '');
                                            }
                                        }
                                    }).catch((error) => {
                                        accountService.LogConnectionProcess(ConnectionID, "InitializePlaidUpdateMode-GetBankUpdatedDetails", JSON.stringify(result), error.toString());
                                        callBackPage('Exit', '', '');
                                    });
                            },
                            onExit: function (err, metadata) {
                                callBackPage('Exit', '', '');
                            },
                            onEvent: (eventName, metadata) => {
                                accountService.LogConnectionProcess(ConnectionID, eventName, JSON.stringify(metadata), "");
                            },
                        });
                        linkHandler.open();
                    } else if (result?.result === "AccessTokenNotFound") {
                        Utils.toastError("Access token not found for this account.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        callBackPage('tokenNotFound', '', '');
                    } else if (result?.message === "ITEM_NOT_FOUND") {
                        Utils.toastError(result?.result, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        callBackPage('ITEM_NOT_FOUND', '', '');
                    } else {
                        Utils.toastError(result?.result?.charAt(0).toUpperCase() + result?.result?.slice(1), {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        callBackPage('ITEM_NOT_FOUND', '', '');
                    }
                }
            });
    }

    static UpdateBankConnectionStatus(Access_token, OPRID, reloadBackPage) {
        accountService.UpdateBankConnectionStatus(OPRID, Access_token)
            .then(async (result) => {
                if (result !== null) {
                    if (result?.status === 'Success' || result?.Status === 'Success') {
                        toast.success('You account has been reconnected successfully.', {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        reloadBackPage('success');
                    } else {
                        Utils.toastError("Something went wrong, Please try again later.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        reloadBackPage('fail');
                    }
                }
            })
    }
}

