import React, { useState, useEffect } from "react";
import {
  Form,
  Container,
  Card,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import OutsideClickHandler from "react-outside-click-handler";
import { UserManagement } from "../../../Common/Services/UserManagement";
import { IGeneralInfo } from "../../../Common/Contracts/IGeneralInfo";
import { DropDownList } from "../../Reports/common-components/dropdown-list";
import { BusinessAccountSelectorGeneralTab } from "../common-components/BusinessAccountSelectorGeneralTab";
import { reject } from "q";
import Joi from "joi-browser";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import _ from "lodash";
import { toast } from "react-toastify";
import Calendar from "react-calendar";
import { Utils } from "../../../Common/Utilis";
import { OTPCodeModal } from "../components/OTPCodeModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { AddTerminationDateForPayrollHotel } from "./AddTerminationDateForPayrollHotel";
import ReactTooltip from "react-tooltip";
import { User } from "../../../Common/Services/User";
import { DropDownListWithToolTip } from "../common-components/DropDownListWithToolTip";
export interface IGeneralProps {
  userData: any;
  userGeneralInfo: any;
  getGeneralTabModificationStatus: Function;
  getUserGeneralInfo: Function;
  user: any;
  enterpriseAccess: any;
  enableEnterpriseAccess: any;
  statusList: any[];
  uniqueno: string;
  isNewUserAdded: boolean;
  isEditGeneralInfo: boolean;
  getGeneralTabCount?: Function;
  userRoles: any[];
  payrollIntegrationHids: any[];
  activeTab: string;
}
// isDuplicateEmail
// isDuplicatePhone

const primaryContacts: any[] = [
  {
    label: "Email",
    value: "Email",
  },
  { label: "Phone", value: "Phone" },
];

let accountStatusList: any[] = [
  {
    label: "Unlocked",
    value: 1,
    toolTip: "User is able to log in.",
  },
  {
    label: "Auto lock",
    value: 2,
    disabled: true,
    toolTip:
      "User is not able to log in due to too many incorrect password attempts.",
  },
  {
    label: "Manager Locked",
    value: 3,
    toolTip: "This option will prevent a user from logging in.",
  },
];

const contactFormSchema = Joi.object({
  user_UniqueId: Joi.number(),
  tenantID: Joi.number(),
  createdBy: Joi.number(),
  primaryContact: Joi.string().allow(""),
  terminationDate: Joi.string().allow(""),
  terminationReason: Joi.string().allow(""),
  userUniqueID: Joi.number(),
  accountStatus: Joi.number(),
  firstName: Joi.string()
    .required()
    .regex(/^[A-Za-z\' ][A-Za-z \-\_\'']*$/, "firstName")
    .max(48)
    .error((errors) => {
      const _errors = errors.map((error) => {
        if (error?.message) {
          return;
        }
        switch (error?.type) {
          case "string.base":
            return { message: "First Name is required" };
          case "string.regex.name":
            return { message: "First Name is not valid" };
          case "any.empty":
            return { message: "First Name is required" };
          case "string.max":
            return {
              message: "First Name should not be more than 48 characters",
            };
        }
      });
      return [_errors[0]];
    }),
  lastName: Joi.string()
    .required()
    .regex(/^[A-Za-z\' ][A-Za-z \-\_\'']*$/, "lastName")
    .max(48)
    .error((errors) => {
      const _errors = errors.map((error) => {
        if (error?.message) {
          return;
        }
        switch (error?.type) {
          case "string.base":
            return { message: "Last Name is required" };
          case "string.regex.name":
            return { message: "Last Name is not valid" };
          case "any.empty":
            return { message: "Last Name is required" };
          case "string.max":
            return {
              message: "Last Name should not be more than 48 characters",
            };
        }
      });
      return [_errors[0]];
    }),
  email: Joi.string()
    .regex(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "email"
    )
    .required()
    .max(200)
    .error((errors) => {
      const _errors = errors.map((error) => {
        if (error?.message) {
          return;
        }
        switch (error.type) {
          case "string.base":
            return { message: "Email Address is required" };
          case "any.empty":
            return { message: "Email Address is required" };
          case "string.regex.name":
            return { message: "Email Address is not valid" };
          case "string.max":
            return { message: "Email Address is not valid" };
        }
      });
      return [_errors[0]];
    }),

  phone1: Joi.string()
    .optional()
    .allow("")
    .regex(
      /^(\+\d{1,3}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
      "phone1"
    )
    .error((errors) => {
      const _errors = errors.map((error) => {
        return { message: "Mobile Phone Number is not valid" };
      });
      return [_errors[0]];
    }),
  hireDate: Joi.string()
    .required()
    .error((errors) => {
      return errors.map((error) => {
        return { message: "Hire Date is required" };
      });
    }),
  status: Joi.string(),
  rehireEligibility: Joi.string().allow(""),
});

const accessFormSchema = Joi.object({
  role: Joi.number()
    .required()
    .min(1)
    .error((errors) => {
      return errors.map((error) => {
        return { message: "Please select Role" };
      });
    }),
  hids: Joi.array()
    .items(Joi.object().keys().min(1))
    .error((errors) => {
      return errors.map((error) => {
        return { message: "Please select atleast 1 Entity" };
      });
    }),
});

export const General = (props: IGeneralProps) => {
  const { userGeneralInfo, statusList, getGeneralTabCount } = props;

  let buisnessAccountRef: any = React.useRef();
  const [contactInfo, setContactInfo] = useState({
    firstName: userGeneralInfo.firstName,
    lastName: userGeneralInfo.lastName,
    email: userGeneralInfo.email,
    userUniqueID: userGeneralInfo.user_UniqueId,
    phone1:
      userGeneralInfo.phoneStatus === "blocked" ? "" : userGeneralInfo.phone1,
    status: userGeneralInfo?.status || "Incomplete",
    hireDate:
      userGeneralInfo.hireDate &&
      userGeneralInfo.hireDate != "0001-01-01T00:00:00"
        ? moment(userGeneralInfo.hireDate).format("MM/DD/YY")
        : "",
    terminationDate:
      userGeneralInfo.terminationDate &&
      userGeneralInfo.terminationDate != "0001-01-01T00:00:00"
        ? moment(userGeneralInfo.terminationDate).format("MM/DD/YY")
        : "",
    rehireEligibility: userGeneralInfo.rehireEligibility || "",
    terminationReason: userGeneralInfo.terminationReason || "",
    primaryContact: userGeneralInfo?.primaryContact || "Email",
    accountStatus: userGeneralInfo?.accountStatus || 0,
  });
  const [rehireEligibilityList, setrehireEligibilityList] = useState([
    { id: "-1", name: "Select Rehire Eligibility" },
    { id: "Eligible for Rehire", name: "Eligible for Rehire" },
    { id: "Questionable for Rehire", name: "Questionable for Rehire" },
    { id: "Ineligible for Rehire", name: "Ineligible for Rehire" },
  ]);
  const [prevContactInfo, setPrevContactInfo] = useState({
    firstName: userGeneralInfo.firstName,
    lastName: userGeneralInfo.lastName,
    email: userGeneralInfo.email,
    userUniqueID: userGeneralInfo.user_UniqueId,
    phone1:
      userGeneralInfo.phoneStatus === "blocked" ? "" : userGeneralInfo.phone1,
    status: userGeneralInfo?.status || "Incomplete",
    hireDate:
      userGeneralInfo.hireDate &&
      userGeneralInfo.hireDate != "0001-01-01T00:00:00"
        ? moment(userGeneralInfo.hireDate).format("MM/DD/YY")
        : "",
    terminationDate:
      userGeneralInfo.terminationDate &&
      userGeneralInfo.terminationDate != "0001-01-01T00:00:00"
        ? moment(userGeneralInfo.terminationDate).format("MM/DD/YY")
        : "",
    rehireEligibility: userGeneralInfo?.rehireEligibility || "",
    terminationReason: userGeneralInfo.terminationReason || "",
    primaryContact: userGeneralInfo?.primaryContact || "Email",
    accountStatus: userGeneralInfo?.accountStatus || 0,
  });
  const [accessControlInfo, setAccessControlInfo] = useState({
    role: userGeneralInfo.role || 0,
    hids: userGeneralInfo.hids,
  });
  const [prevAccessControlInfo, setPrevAccessControlInfo] = useState({
    role: userGeneralInfo.role || 0,
    hids: userGeneralInfo.hids,
  });
  const [contactFormError, setContactFormError] = useState<any>({});
  const [accessFormError, setAccessFormError] = useState<any>({});
  const [inputPosition, setInputPosition] = useState(4);
  const [isContactFormValid, setIsContactFormValid] = useState(false);
  const [isAcessFormValid, setIsAcessFormValid] = useState(false);
  const [isContactFormUpdated, setIsContactFormUpdated] = useState(false);
  const [isAccessFormUpdated, setIsAccessFormUpdated] = useState(false);
  const [userName, setUserName] = useState(userGeneralInfo?.userName);
  const [calenderShow, setCalenderShow] = useState(false);
  const [calenderTerminationDateShow, setCalenderTerminationDateShow] =
    useState(false);
  const [
    calenderTerminationDateModalShow,
    setCalenderTerminationDateModalShow,
  ] = useState(false);

  const [isDataSubmitting, setIsDataSubmitting] = useState(false);
  const [isPayrollDataLoaded, setIsPayrollDataLoaded] = useState(true);
  const [hotelWiseSetPayHours, setHotelWiseSetPayHours] = useState<any[]>([]);

  const [hasLogiUserEnterpriseAccess, setHasLogiUserEnterpriseAccess] =
    useState(false);

  const [userRoleList, setUserRoleList] = useState<any[]>(props?.userRoles);
  const [OpenOTPModal, setOpenOTPModal] = useState(false);
  const [mode, setMode] = useState("");
  const [hotelWideTableData, setHotelWideTableData] = useState([]);
  //const [payrollIntegrationHids, setPayrollIntegrationHids] = useState<any[]>([]);
  const [ispayrollIntegrated, setIspayrollIntegrated] = useState<any>(false);
  const [isUserstatuscheck, setIsUserstatuscheck] = useState<any>(false);

  //const [ispayrollIntegrationloading, setPayrollIntegrationloading] = useState<any>(true);
  const [settedOldHids, setOldHids] = useState([]);
  const [newHids, setNewHids] = useState([]);
  const [hotelWideHid, setHotelWideHid] = useState([]);
  const [hotelsForDeactivation, setHotelsForDeactivation] = useState<any[]>([]);
  const [timeOutFunc, setTimeOutFunc] = useState<any>(null);
  const [contactFieldName, setContactFieldName] = useState<any>("");
  const [maxTerminationDate, setMaxTerminationDate] = useState(() => {
    return JSON.parse(localStorage?.storage)?.currentUtcTime !== ""
      ? new Date(JSON.parse(localStorage?.storage)?.currentUtcTime)
      : new Date();
  });

  useEffect(() => {
    setPrevContactInfo((prev) => ({
      ...prev,
      status: userGeneralInfo?.status || "Incomplete",
    }));

    setContactInfo((prev) => ({
      ...prev,
      status: userGeneralInfo?.status || "Incomplete",
    }));

    getHotelWisePayrollData();
  }, [props.activeTab, userGeneralInfo?.status]);

  useEffect(() => {
    if (
      (contactFieldName === "phone1" && !contactInfo?.phone1) ||
      contactFieldName !== "phone1" ||
      (contactFieldName === "phone1" &&
        contactInfo?.phone1?.toString()?.includes("+") &&
        contactInfo?.phone1?.toString()?.substr(1, 1) !== "1")
    ) {
      validateContactForm();
      const _isContactFormUpdated = !_.isEqual(contactInfo, prevContactInfo);
      setIsContactFormUpdated(_isContactFormUpdated);
    }

    validateAccessForm();
    if (prevContactInfo || prevAccessControlInfo) {
      const _isAccessFormUpdated = !_.isEqual(
        prevAccessControlInfo,
        accessControlInfo
      );
      setIsAccessFormUpdated(_isAccessFormUpdated);

      const _isContactFormUpdated = !_.isEqual(contactInfo, prevContactInfo);
      setIsContactFormUpdated(_isContactFormUpdated);
      if (_isAccessFormUpdated || _isContactFormUpdated) {
        props.getGeneralTabModificationStatus(
          _isAccessFormUpdated || _isContactFormUpdated
        );
      } else {
        props.getGeneralTabModificationStatus(false);
      }
    }
  }, [contactInfo, accessControlInfo, inputPosition, contactFieldName]);
  useEffect(() => {
    const userhids = userGeneralInfo?.hids?.map((x) => x.hotelID);
    const IspayrollIntegration = allHidsExist(
      userhids,
      props.payrollIntegrationHids
    );
    const IsUserstatuscheck = isUserHidInAll(
      userhids,
      props.payrollIntegrationHids
    );
    setIspayrollIntegrated(IspayrollIntegration);
    setIsUserstatuscheck(IsUserstatuscheck);
    //GetPayrollIntegrationHidsByTenant();
    getLaborPayHours();
    getHotelWisePayrollData();

    accountStatusList = isNewUserAdded
      ? accountStatusList.map((item) => {
          if (item.value === 3) {
            item.disabled = true;
          }
          return item;
        })
      : accountStatusList.map((item) => {
          if (item.value === 3) {
            item.disabled = false;
          }
          return item;
        });
  }, []);

  useEffect(() => {
    if (getGeneralTabCount)
      getGeneralTabCount(
        Object.keys(accessFormError).length +
          Object.keys(contactFormError).length
      );
  }, [accessFormError, contactFormError]);

  const getHotelWisePayrollData = () => {
    setIsPayrollDataLoaded(false);
    (userGeneralInfo?.employeeType !== "ExcludefromPayroll"
      ? UserManagement.GetHotelWisePayroll(
          +props?.uniqueno,
          props?.isNewUserAdded
        )
      : UserManagement.GetHotelWisePayrollForExcludeFromPayroll(
          +props?.uniqueno,
          props?.isNewUserAdded
        )
    ).then((hotelWideTableData) => {
      setHotelWideTableData(
        hotelWideTableData?.filter((x) => x.status === "Active")
      );
      setIsPayrollDataLoaded(true);
    });
  };

  function CapitalizeFirstLetter(string) {
    if (string) {
      return string?.charAt(0).toUpperCase() + string?.slice(1).toLowerCase();
    } else {
      return string;
    }
  }

  const validatePhoneNo = (phone, _inputPosition): any => {
    setIsContactFormValid(false);
    const errorObj: any = contactFormError;
    contactInfo.phone1 = phone;
    if (!phone) {
      if (
        errorObj?.phone1 === "Mobile Phone Number is not valid" &&
        contactInfo.primaryContact === "Email"
      ) {
        delete errorObj.phone1;
        setContactFormError({
          ...errorObj,
        });
      }
      return;
    }

    if (
      phone?.toString()?.includes("+") &&
      phone?.toString()?.substr(1, 1) !== "1"
    ) {
      if (_inputPosition > inputPosition) {
        setInputPosition(_inputPosition);
      }
      if (errorObj.phone1) {
        if (phone?.toString()?.length < 9) {
          setContactFormError({
            ...errorObj,
            phone1: "Mobile Phone Number is not valid",
          });
        } else {
          delete errorObj.phone1;
          setContactFormError({
            ...errorObj,
          });
        }

        validateContactForm(true);
        const _isContactFormUpdated = !_.isEqual(contactInfo, prevContactInfo);
        setIsContactFormUpdated(_isContactFormUpdated);
      } else {
        validateContactForm();
        const _isContactFormUpdated = !_.isEqual(contactInfo, prevContactInfo);
        setIsContactFormUpdated(_isContactFormUpdated);
      }

      return;
    }

    setIsDataSubmitting(true);
    User.ValidatePhoneNumber(phone)
      .then((response: any) => {
        if (!contactInfo.phone1) {
          validateContactForm(true);
          const _isContactFormUpdated = !_.isEqual(
            contactInfo,
            prevContactInfo
          );
          setIsContactFormUpdated(_isContactFormUpdated);
          return;
        }
        if (
          response?.fakeNumber === "YES"
          // &&
          // response?.lineType === "CELL PHONE"
        ) {
          if (_inputPosition > inputPosition) {
            setInputPosition(_inputPosition);
          }
          errorObj["phone1"] = "Mobile Phone Number is not valid";
          setContactFormError({
            ...errorObj,
            phone1: "Mobile Phone Number is not valid",
          });
        } else if (response?.lineType !== "CELL PHONE") {
          if (_inputPosition > inputPosition) {
            setInputPosition(_inputPosition);
          }
          errorObj["phone1"] = response?.lineType
            ? `${CapitalizeFirstLetter(
                response?.lineType
              )} Phone Numbers are not supported`
            : `Mobile Phone Number is not valid`;
          setContactFormError({
            ...errorObj,
            phone1:
              response?.lineType && response?.lineType !== "FAKE"
                ? `${CapitalizeFirstLetter(
                    response?.lineType
                  )} Phone Numbers are not supported`
                : `Mobile Phone Number is not valid`,
          });
        } else {
          validateContactForm(true);
          const _isContactFormUpdated = !_.isEqual(
            contactInfo,
            prevContactInfo
          );
          setIsContactFormUpdated(_isContactFormUpdated);
          if (errorObj?.phone1) {
            delete errorObj.phone1;
            setContactFormError({
              ...errorObj,
            });
          }
        }

        if (Object.keys(errorObj).length === 0) {
          setIsContactFormValid(true);
        } else {
          setIsContactFormValid(false);
        }
      })
      .finally(() => setIsDataSubmitting(false));
  };

  const validateContactForm = (isFromPhoneValidator = false) => {
    const valid = Joi.validate(contactInfo, contactFormSchema, {
      abortEarly: false,
    });

    let newErrorObject: any =
      contactFormError?.phone1 && contactInfo.phone1 && !isFromPhoneValidator
        ? { phone1: contactFormError?.phone1 }
        : {};
    if (valid.error) {
      valid.error.details.forEach((err) => {
        newErrorObject[err.path.join(".")] = err.message;
        if (err.message) {
          setContactFormError({
            ...newErrorObject,
            [err.path.join(".")]: err.message,
          });
        }
      });
    }

    if (
      newErrorObject["phone1"] == "Mobile Phone Number is not valid" &&
      contactInfo?.phone1?.toString()?.length >= 9 &&
      (contactInfo?.phone1?.toString()?.startsWith("+1242") ||
        contactInfo?.phone1?.toString()?.startsWith("1242") ||
        contactInfo?.phone1?.toString()?.startsWith("242"))
    ) {
      delete newErrorObject["phone1"];
    }

    if (contactInfo.hireDate) {
      let splittedDate = contactInfo.hireDate?.toString()?.split("/");
      const dob =
        props?.userGeneralInfo.dob &&
        props?.userGeneralInfo.dob != "0001-01-01T00:00:00"
          ? moment(props?.userGeneralInfo.dob).format("MM/DD/YY")
          : "";
      if (
        moment(contactInfo.hireDate)?.format("MM/DD/YY") === "Invalid date" ||
        contactInfo.hireDate?.toString()?.length !== 8 ||
        splittedDate[0]?.toString()?.length !== 2 ||
        splittedDate[1]?.toString()?.length !== 2 ||
        splittedDate[2]?.toString()?.length !== 2
      ) {
        newErrorObject["hireDate"] = "Hire Date should be in MM/DD/YY format";
        setContactFormError({
          ...newErrorObject,
          hireDate: "Hire Date should be in MM/DD/YY format",
        });
      } else if (
        dob &&
        moment(dob).isAfter(
          moment(moment(contactInfo.hireDate)?.format("MM/DD/YY"))
        )
      ) {
        setContactFormError({
          ...newErrorObject,
          hireDate: "Hire Date should be greater than Date Of Birth",
        });
      } else {
        if (newErrorObject["hireDate"]) {
          delete newErrorObject["hireDate"];
        }
      }
    }

    if (contactInfo.status === "Inactive" && !contactInfo.terminationDate) {
      newErrorObject["terminationDate"] =
        "Please select valid Termination Date";
      setContactFormError({
        ...newErrorObject,
        ["terminationDate"]: "Please select valid Termination Date",
      });
    } else {
      if (newErrorObject["terminationDate"]) {
        delete newErrorObject["terminationDate"];
      }
    }
    if (contactInfo.status === "Inactive" && !contactInfo.terminationReason) {
      newErrorObject["terminationReason"] = "Please enter Termination Reason";
      setContactFormError({
        ...newErrorObject,
        ["terminationDate"]: "Please enter Termination Reason",
      });
    } else {
      if (newErrorObject["terminationReason"]) {
        delete newErrorObject["terminationReason"];
      }
    }

    let _isDuplicateEmail = false;
    let _isDuplicatePhone = false;
    if (
      userGeneralInfo?.isDuplicateEmail &&
      prevContactInfo.email === contactInfo.email
    ) {
      _isDuplicateEmail = true;
    }

    if (
      userGeneralInfo?.isDuplicatePhone &&
      prevContactInfo.phone1 === contactInfo.phone1
    ) {
      _isDuplicatePhone = true;
    }

    if (
      (contactInfo?.primaryContact === "Phone" ||
        userGeneralInfo?.hasSpecialPermissionUsers) &&
      !contactInfo.phone1
    ) {
      newErrorObject["phone1"] = "Please enter Mobile Phone Number";

      setContactFormError({
        ...newErrorObject,
      });
    }

    // else if(contactInfo?.primaryContact === "Email" && !contactInfo.phone1){
    //   if (newErrorObject?.phone1) {
    //     delete newErrorObject.phone1;
    //     setContactFormError({
    //       ...newErrorObject,
    //     });
    //   }
    // }
    else {
      if (
        ((newErrorObject?.phone1 && contactInfo.phone1?.toString()?.length) >
          8 ||
          (contactInfo?.primaryContact === "Email" && !contactInfo.phone1)) &&
        contactInfo.phone1?.toString()?.includes("+") &&
        contactInfo.phone1?.toString()?.substr(1, 1) !== "1"
      ) {
        delete newErrorObject.phone1;
        console.log({ inner: newErrorObject });

        setContactFormError({
          ...newErrorObject,
        });
      }
    }

    if (userGeneralInfo?.isLowSecurityUser) {
      if (newErrorObject.phone1 != "Mobile Phone Number is not valid") {
        delete newErrorObject.phone1;
      }
      if (newErrorObject.email != "Email Address is not valid") {
        delete newErrorObject.email;
      }
      setContactFormError({
        ...newErrorObject,
      });
    }

    if (_isDuplicateEmail) {
      newErrorObject["email"] = "User with same Email exists";

      setContactFormError({
        ...newErrorObject,
      });
    }

    if (_isDuplicatePhone) {
      newErrorObject["phone1"] = "User with same Phone Number exists";

      setContactFormError({
        ...newErrorObject,
      });
    }

    if (Object.keys(newErrorObject).length === 0) {
      setIsContactFormValid(true);
    } else {
      setIsContactFormValid(false);
    }
    setContactFormError({ ...newErrorObject });
    return newErrorObject;
  };

  const getLaborPayHours = () => {
    UserManagement.GetLaborPayHours(+props?.uniqueno).then((data: any[]) => {
      setHotelWiseSetPayHours(data);
    });
  };
  function allHidsExist(userHids, allHids) {
    for (let i = 0; i < userHids?.length; i++) {
      if (!(allHids || [])?.includes(userHids[i])) {
        return false;
      }
    }
    return true;
  }
  function isUserHidInAll(userHids, allHids) {
    for (let i = 0; i < userHids.length; i++) {
      if ((allHids || [])?.includes(userHids[i])) {
        return true;
      }
    }
    return false;
  }

  // const GetPayrollIntegrationHidsByTenant = () => {
  //   //setPayrollIntegrationloading(true);
  //   UserManagement.GetPayrollIntegrationHidsByTenant().then((data: any[]) => {
  //     setPayrollIntegrationHids(data);
  //     const hids= userGeneralInfo?.hids?.map(x=>x.hotelID)
  //     const IspayrollIntegration=allHidsExist(hids,data);
  //     setPayrollIntegrationloading(false);

  //     setIspayrollIntegrated(IspayrollIntegration);
  //   }).catch(()=>{
  //     setPayrollIntegrationHids([]);
  //     setPayrollIntegrationloading(false);
  //   });
  // };

  const getUsername = () => {
    if (
      contactInfo?.firstName === userGeneralInfo?.firstName &&
      contactInfo?.lastName === userGeneralInfo?.lastName
    ) {
      setUserName(userGeneralInfo?.userName);
      return;
    }
    if (!contactInfo.firstName || !contactInfo.lastName) {
      return;
    }
    UserManagement.GetUsername(contactInfo.firstName, contactInfo.lastName)
      .then((userName) => {
        setUserName(userName);
      })
      .catch(() => setUserName(""));
  };
  const validateAccessForm = () => {
    const valid = Joi.validate(accessControlInfo, accessFormSchema, {
      abortEarly: false,
    });

    let newErrorObject: any = {};
    if (valid.error) {
      valid.error.details.forEach((err) => {
        newErrorObject[err.path.join(".")] = err.message;
        if (err.message) {
          setAccessFormError({
            ...newErrorObject,
            [err.path.join(".")]: err.message,
          });
        }
      });
      // } else {
      //   newErrorObject = {};
    }
    if (
      accessControlInfo?.hids?.length === 0 ||
      (userGeneralInfo.status === "Active" &&
        accessControlInfo?.hids?.filter(
          (x) => x.status?.toString()?.toLowerCase() === "active"
        )?.length === 0)
    ) {
      if (
        userGeneralInfo.status === "Active" &&
        accessControlInfo?.hids?.filter(
          (x) => x.status?.toString()?.toLowerCase() === "active"
        )?.length === 0
      ) {
        newErrorObject["hids"] = "Please Select Atleast 1 Active Entity";
      } else {
        newErrorObject["hids"] = "Please Select Atleast 1 Entity";
      }

      setAccessFormError({
        ...newErrorObject,
        hids: "Please Select Atleast 1 Entity",
      });
    } else {
      if (newErrorObject?.hids) {
        delete newErrorObject.hids;
        setAccessFormError({
          ...newErrorObject,
        });
      }
    }

    if (Object.keys(newErrorObject).length === 0) {
      setIsAcessFormValid(true);
    } else {
      setIsAcessFormValid(false);
    }
    setAccessFormError({ ...newErrorObject });

    return newErrorObject;
  };

  const onContactFieldChange = (event, _inputPosition, isBlur = false) => {
    let value = event.target.value;
    const fieldName = event.target.name;
    setContactFieldName(fieldName);
    if (fieldName === "terminationReason") {
      value = typeof value === "string" ? value?.toString()?.trimLeft() : value;
    }
    if (fieldName == "email") {
      value = value?.toString()?.trim();
    }

    if (fieldName === "firstName" || fieldName === "lastName") {
      // value = value?.toString()?.replace(/[^A-Z \-\_\']+/gi, "")?.trimLeft();
      value = value?.toString()?.trimLeft();
      if (value) {
        if (
          value.indexOf("_") === 0 ||
          value.indexOf("-") === 0 ||
          value.indexOf("'") === 0
        ) {
          // value = value?.slice(0, 0);
          value = value?.substring(1);
        }
      }
    }

    if (fieldName === "phone1") {
      value = value?.toString()?.replace(/[^0-9\-\+]+/gi, "");
      if (value) {
        if (value.indexOf("-") >= 0) {
          value = value?.replaceAll("-", "");
        }
        if (value?.toString()?.length > 1) {
          value =
            value?.toString().substr(0, 1) +
            value?.toString()?.substr(1)?.replaceAll("+", "");
        }
      }
    }
    if (fieldName === "phone1" && value) {
      setIsContactFormValid(false);
      if (timeOutFunc) {
        clearTimeout(timeOutFunc);
      }
      setIsContactFormValid(false);
      const timeoutId = setTimeout(() => validatePhoneNo(value, 4), 500);
      setTimeOutFunc(timeoutId);
    }

    if (fieldName === "terminationDate") {
      const _formattedDate = Utils.AddSlashINInputDateFormat(
        value,
        contactInfo[fieldName],
        true,
        2,
        isBlur
      );
      if (_formattedDate === "invalid") {
        return;
      } else {
        value = _formattedDate;
      }

      if (contactInfo.hireDate.length === 8 && value.length === 8) {
        if (!moment(contactInfo.hireDate)?.isSameOrBefore(value)) {
          return;
        }
      }
    }

    if (fieldName === "hireDate") {
      const _formattedDate = Utils.AddSlashINInputDateFormat(
        value,
        contactInfo[fieldName],
        false,
        2,
        isBlur
      );
      if (_formattedDate === "invalid") {
        return;
      } else {
        value = _formattedDate;
      }
      if (
        contactInfo.status === "Inactive" &&
        contactInfo.terminationDate?.toString()?.length === 8 &&
        value?.toString()?.length === 8
      ) {
        if (moment(contactInfo.terminationDate)?.isBefore(value)) {
          return;
        }
      }
    }

    if (_inputPosition > inputPosition) {
      setInputPosition(_inputPosition);
    }
    if (fieldName === "status" && value === "Active") {
      setContactInfo({
        ...contactInfo,
        terminationDate: "",
        terminationReason: "",
        rehireEligibility: "",
        [fieldName]: value,
      });
    } else {
      setContactInfo({ ...contactInfo, [fieldName]: value });
    }

    if (fieldName === "rehireEligibility") {
      setContactInfo({ ...contactInfo, [fieldName]: value });
    }
  };
  const onAccessControlFieldChange = (event, _inputPosition) => {
    let value = event.target.value;

    // if (event.target.name === "hasEnterpriseAccess" && !event.target.checked) {
    //   value = event.target.checked;
    //   const hids = hotelWideTableData.map((x: any) => x.hid);

    //   const filterableHid = accessControlInfo?.hids
    //     ?.filter((x) => +x?.type === 1)
    //     ?.map((x) => x.hotelID);
    //   const hid = hids.filter((hid) => filterableHid?.includes(hid))[0];
    //   if (+hid > 0) {
    //     setHotelWideHid(hid);
    //     setOldHids( _.cloneDeep(accessControlInfo?.hids));
    //     setNewHids( _.cloneDeep(accessControlInfo?.hids));
    //     showConfirmationOnSingleHIDRemove(
    //       filterableHid,
    //       _.cloneDeep(accessControlInfo?.hids),
    //       _.cloneDeep(accessControlInfo?.hids),
    //       true
    //     );
    //   }
    // }

    const fieldName = event.target.name;
    const hids = hotelWideTableData.map((x: any) => x.hid);
    if (fieldName === "hids") {
      if (!_.isEqual(accessControlInfo?.hids, value)) {
        const filterableHid = accessControlInfo?.hids
          ?.map((x) => x.hotelID)
          .filter((hid) => !value?.map((x) => x.hotelID)?.includes(hid));
        const hid = hids.filter((hid) => filterableHid?.includes(hid))[0];

        if (+hid > 0) {
          setHotelWideHid(hid);
          setOldHids(_.cloneDeep(accessControlInfo?.hids));
          setNewHids(value);
          showConfirmationOnSingleHIDRemove(
            filterableHid,
            _.cloneDeep(accessControlInfo?.hids),
            [...value]
          );
        }
      }
    }

    setAccessControlInfo({ ...accessControlInfo, [fieldName]: value });
    if (_inputPosition > inputPosition) {
      setInputPosition(_inputPosition);
    }
  };

  const getContactFieldError = (fieldName: string, fieldPosition: number) => {
    return (
      <>
        {Object.keys(contactFormError).length > 0 &&
          fieldPosition <= inputPosition && (
            <span className="validation-message">
              {contactFormError[fieldName]}
            </span>
          )}
      </>
    );
  };

  const handlerPaste = (fieldName, e, inputPosition) => {
    e.preventDefault();
    let value = e.clipboardData.getData("text").trim();
    value = Utils.CapitalizeFirstLetter(value);
    setContactInfo({ ...contactInfo, [fieldName]: value });
  };

  const getLogiUserEnterpriseAccess = (hasLogiUserEnterpriseAccess) => {
    setHasLogiUserEnterpriseAccess(hasLogiUserEnterpriseAccess);
  };
  const getAccessFieldError = (fieldName: string, fieldPosition: number) => {
    return (
      <>
        {Object.keys(accessFormError).length > 0 &&
          fieldPosition <= inputPosition && (
            <span className="validation-message">
              {accessFormError[fieldName]}
            </span>
          )}
      </>
    );
  };

  const handleDiscard = () => {
    props.getGeneralTabModificationStatus(false);
    props.getUserGeneralInfo(true);
  };
  const saveConfirmationOnHIDChange = () => {
    confirmAlert({
      title: "Are you ready to make changes?",
      message:
        "Removing this property will prevent assigning time-off policies mapped to this property for the employee in the future. Currently assigned policies will be retained and continue to accrue until they are individually removed.",
      buttons: [
        {
          label: "Yes",
          onClick: () => updateGeneralInfo(""),
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  const showConfirmationOnSingleHIDRemove = (
    hid,
    oldHids,
    newHids,
    isEnterprise = false
  ) => {
    setOldHids(oldHids);
    setHotelWideHid(hid);
    confirmAlert({
      title: "Are you ready to make changes?",
      message:
        "Removing this property will de-activate entity wide setting for that property",
      buttons: [
        {
          label: "Yes",
          onClick: () => showTerminationDateModal(hid, isEnterprise),
        },
        {
          label: "No",
          onClick: () =>
            cancelConfirmationOnSingleHIDRemove(isEnterprise, true),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  const removeOptOutWithConfirmation = (phone) => {
    confirmAlert({
      title: "Remove Opt-Out",
      message: "Are you sure you would like to opt-in this user?",
      buttons: [
        {
          label: "Yes, Proceed",
          onClick: () => removeOptOut(phone),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  const removeOptOut = (phone) => {
    UserManagement.RemoveOptOut(phone).then((result: any) => {
      if (result?.message === "success") {
        props.getUserGeneralInfo(true);
        toast.success("User Opted-In Successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "labortabcontainer",
        });
      }
    });
  };

  const cancelConfirmationOnSingleHIDRemove = (
    isEnterprise = false,
    isAddHid = false
  ) => {
    const hids = accessControlInfo.hids;
    if (isAddHid) {
      hids.push(
        userGeneralInfo.hids?.filter((x) => +x?.hotelID === +hotelWideHid)[0]
      );
      setAccessControlInfo({
        ...accessControlInfo,
        hids: _.uniqBy(
          hids?.filter((x) => x),
          "hotelID"
        ),
      });
    } else {
      setAccessControlInfo({ ...accessControlInfo, hids: newHids });
    }
  };

  const updateWithConfirmation = () => {
    const hotels = userGeneralInfo.hids;
    const hotelIds = hotels.map((item) => item?.hotelID?.toString());

    if (
      hotels?.length >
      accessControlInfo.hids.filter((item) =>
        hotelIds.includes(item?.hotelID?.toString())
      ).length
    ) {
      saveConfirmationOnHIDChange();
      return;
    }

    updateGeneralInfo("");
  };

  const validationOnContactClick = (_inputPosition, fieldName = "") => {
    if (_inputPosition > inputPosition) {
      setInputPosition(_inputPosition);
    }
    if (
      (fieldName === "phone1" && !contactInfo?.phone1) ||
      fieldName !== "phone1" ||
      (fieldName === "phone1" &&
        contactInfo?.phone1?.toString()?.includes("+") &&
        contactInfo?.phone1?.toString()?.substr(1, 1) !== "1")
    ) {
      validateContactForm();
      const _isContactFormUpdated = !_.isEqual(contactInfo, prevContactInfo);
      setIsContactFormUpdated(_isContactFormUpdated);
    }
  };

  const validationOnAccessClick = (_inputPosition) => {
    if (_inputPosition > inputPosition) {
      setInputPosition(_inputPosition);
    }
    validateAccessForm();
  };
  const calenderHide = () => {
    setCalenderShow(false);
  };

  const calenderDisplay = () => {
    setCalenderShow(true);
  };
  const calenderTerminationDateHide = () => {
    setCalenderTerminationDateShow(false);
  };

  const calenderTerminationDateDisplay = () => {
    setCalenderTerminationDateShow(true);
  };

  const showTerminationDateModal = (hid, isEnterprise = false) => {
    //if (!isEnterprise) {
    setCalenderTerminationDateModalShow(true);
    //}
  };

  const hideTerminationDateModal = (isSave = false, data: any = {}) => {
    if (!isSave) {
      cancelConfirmationOnSingleHIDRemove(false, true);
    } else {
      data.hid = hotelWideHid[0];
      setAccessControlInfo({ ...accessControlInfo, hids: newHids });
      const _hotelsForDeactivation = [...hotelsForDeactivation];
      _hotelsForDeactivation.push(data);
      setHotelsForDeactivation(_hotelsForDeactivation);
    }
    setCalenderTerminationDateModalShow(false);
  };

  const updateGeneralInfo = (otp) => {
    const request: IGeneralInfo = {} as IGeneralInfo;

    // group1
    if (isContactFormValid && isAcessFormValid) {
      request.firstName = contactInfo.firstName;
      request.lastName = contactInfo.lastName;
      request.email = contactInfo.email;
      request.user_UniqueId = contactInfo.userUniqueID;
      request.phone1 = contactInfo.phone1;
      request.status = contactInfo.status ? contactInfo.status : "";
      request.hireDate = contactInfo.hireDate;
      request.terminationDate = contactInfo.terminationDate;
      request.rehireEligibility = contactInfo.rehireEligibility;
      request.terminationReason = contactInfo.terminationReason;
      request.primaryContact = contactInfo.primaryContact;
      request.accountStatus = contactInfo.accountStatus;
      // group 2
      request.role = accessControlInfo.role;
      request.hids = accessControlInfo.hids;
      request.modifyAccessInfo = true;
      request.modifyContactInfo = true;
    } else if (isContactFormValid) {
      request.firstName = contactInfo.firstName;
      request.lastName = contactInfo.lastName;
      request.email = contactInfo.email;
      request.user_UniqueId = contactInfo.userUniqueID;
      request.phone1 = contactInfo.phone1;
      request.status = contactInfo.status;
      request.hireDate = contactInfo.hireDate;
      request.terminationDate = contactInfo.terminationDate;
      request.rehireEligibility = contactInfo.rehireEligibility;
      request.terminationReason = contactInfo.terminationReason;
      request.primaryContact = contactInfo.primaryContact;
      request.modifyContactInfo = true;
      request.modifyAccessInfo = false;
      request.accountStatus = contactInfo.accountStatus;
    } else {
      // group 2
      request.role = accessControlInfo.role;
      request.hids = accessControlInfo.hids;
      request.modifyAccessInfo = true;
      request.modifyContactInfo = false;
      request.user_UniqueId = contactInfo.userUniqueID;
      request.email = contactInfo.email;
      request.status = contactInfo.status ? contactInfo.status : "";
    }

    request.isNewUserAdded = props?.isNewUserAdded;

    setIsDataSubmitting(true);

    if (hotelsForDeactivation?.length > 0) {
      UserManagement.InactivateHotelWisePayroll(hotelsForDeactivation).then(
        (result) => {
          if (result?.result?.success) {
            setHotelsForDeactivation([]);
            UserManagement.updateGeneralInfo(request)
              .then((result) => {
                setIsDataSubmitting(false);
                if (result?.result?.success) {
                  props.getGeneralTabModificationStatus(false);
                  toast.success(result?.result?.result, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "generaltab",
                  });
                  setIsContactFormUpdated(false);
                  setIsAccessFormUpdated(false);
                  //updateContact();
                  props.getUserGeneralInfo(true);
                } else {
                  Utils.toastError(result?.result?.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "generaltab",
                  });
                }
              })
              .catch((error) => {
                setIsDataSubmitting(false);

                Utils.toastError(error?.message, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  containerId: "generaltab",
                });
              });
          } else {
            setIsContactFormUpdated(false);
            setIsAccessFormUpdated(false);
            //updateContact();
            props.getUserGeneralInfo(true);
            toast.success(result?.result?.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "generaltab",
            });
          }
        }
      );
    } else {
      UserManagement.updateGeneralInfo(request)
        .then((result) => {
          setIsDataSubmitting(false);
          if (result?.result?.success) {
            props.getGeneralTabModificationStatus(false);
            toast.success(result?.result?.result, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "generaltab",
            });
            setIsContactFormUpdated(false);
            setIsAccessFormUpdated(false);
            //updateContact();
            props.getUserGeneralInfo(true);
          } else {
            Utils.toastError(result?.result?.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "generaltab",
            });
          }
        })
        .catch((error) => {
          setIsDataSubmitting(false);
          setIsContactFormUpdated(false);
          setIsAccessFormUpdated(false);

          Utils.toastError(error?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "generaltab",
          });
        });
    }
  };
  // const updateContact = () => {
  //   const loggedInUser: any = {
  //     userName,
  //     firstName: contactInfo.firstName,
  //     lastName: contactInfo.lastName,
  //     email: contactInfo.email,
  //   };
  //   if (userGeneralInfo?.freshDeskId) {
  //     FreshDeskAccounts.UpdateContact(
  //       loggedInUser,
  //       userGeneralInfo?.freshDeskId
  //     );
  //   }
  // };
  const closeOTPModal = () => {
    setOpenOTPModal(false);
    handleDiscard();
  };

  const showHideSavebutton = (): boolean => {
    if (props.isEditGeneralInfo || props?.isNewUserAdded) {
      if (isContactFormValid || isAcessFormValid) {
        if (
          (isContactFormUpdated && isContactFormValid) ||
          (isAccessFormUpdated && isAcessFormValid)
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const getOTP = (mode) => {
    // UserManagement.SendOTP(props?.uniqueno, mode)
    //   .then((result) => {
    //     if (result?.result?.success) {
    //     } else {
    //       Utils.toastError(result?.result?.message, {
    //         position: toast.POSITION.BOTTOM_RIGHT,
    //         containerId: "userSlideOut",
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     Utils.toastError(error?.message, {
    //       position: toast.POSITION.BOTTOM_RIGHT,
    //       containerId: "userSlideOut",
    //     });
    //   });

    if (
      contactInfo.primaryContact === "Phone" &&
      !contactInfo?.email &&
      mode === "phone"
    ) {
      Utils.toastError(
        "Please update Email Address in order to update Mobile Phone Number",
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "userSlideOut",
        }
      );
      return;
    }

    if (!contactInfo?.phone1 && mode === "email") {
      Utils.toastError(
        "Please update Mobile Phone Number in order to update Email Address",
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "userSlideOut",
        }
      );
      return;
    }
    setOpenOTPModal(true);
    setMode(mode);
  };

  const getFormattedDate = (date) => {
    let DateValue;

    if (date) {
      const formattedDate = moment(date)?.format("MM/DD/YY");
      if (formattedDate === "Invalid date") {
        DateValue = new Date();
      } else {
        DateValue = new Date(date);
      }
    } else {
      DateValue = new Date();
    }
    return DateValue;
  };

  const { isNewUserAdded } = props;
  return (
    <>
      <>
        <div className="general-tab">
          {(userGeneralInfo?.hasViewContactInfo || isNewUserAdded) && (
            <>
              <div className="body-section" id="user-info">
                <div className="heading">User Information</div>
                <div className="form-section d-flex flex-column">
                  <Form.Group
                    controlId="date-selection"
                    className={
                      contactFormError?.firstName && inputPosition >= 1
                        ? "validation-error d-flex flex-row"
                        : "d-flex flex-row"
                    }
                  >
                    <Form.Label>
                      <div className="d-flex align-items-center">
                        <div
                          className={contactFormError?.firstName ? "pips" : ""}
                        >
                          <div className="pip light-red"></div>
                        </div>
                        <div>First Name</div>
                      </div>
                    </Form.Label>
                    <div className="validate-input d-flex flex-column">
                      <OverlayTrigger
                        placement="bottom"
                        defaultShow={false}
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip
                            id={"First-Name"}
                            className={
                              Utils.CapitalizeFirstLetter(
                                contactInfo?.firstName
                              ).length <= 26
                                ? "tootltip-hide"
                                : ""
                            }
                          >
                            {Utils.CapitalizeFirstLetter(
                              contactInfo?.firstName
                            )}
                          </Tooltip>
                        }
                      >
                        <input
                          type="text"
                          className="form-control"
                          id="txtFirstName"
                          name="firstName"
                          autoComplete="firstName"
                          disabled={
                            (!userGeneralInfo?.hasEditContactInfo &&
                              !isNewUserAdded) ||
                            ispayrollIntegrated
                          }
                          value={Utils.CapitalizeFirstLetter(
                            contactInfo?.firstName
                          )}
                          onKeyDown={
                            Utils.allowOnlyAplhabetAndHypenUnderscoreApostrophe
                          }
                          onPaste={(e) => handlerPaste("firstName", e, 1)}
                          onChange={(e: any) => onContactFieldChange(e, 1)}
                          onBlur={(e) => {
                            getUsername();
                            validationOnContactClick(1);
                          }}
                        />
                      </OverlayTrigger>
                      {getContactFieldError("firstName", 1)}
                    </div>
                  </Form.Group>
                  <Form.Group
                    controlId="date-selection"
                    className={
                      contactFormError?.lastName && inputPosition >= 2
                        ? "validation-error d-flex flex-row"
                        : "d-flex flex-row"
                    }
                  >
                    <Form.Label>
                      <div className="d-flex align-items-center">
                        <div
                          className={contactFormError?.lastName ? "pips" : ""}
                        >
                          <div className="pip light-red"></div>
                        </div>
                        <div>Last Name</div>
                      </div>
                    </Form.Label>
                    <div className="validate-input d-flex flex-column">
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip
                            id={"Last-Name"}
                            className={
                              Utils.CapitalizeFirstLetter(contactInfo?.lastName)
                                .length <= 26
                                ? "tootltip-hide"
                                : ""
                            }
                          >
                            {Utils.CapitalizeFirstLetter(contactInfo?.lastName)}
                          </Tooltip>
                        }
                      >
                        <input
                          type="text"
                          className="form-control"
                          id="txtLastName"
                          name="lastName"
                          autoComplete="lastName"
                          disabled={
                            (!userGeneralInfo?.hasEditContactInfo &&
                              !isNewUserAdded) ||
                            ispayrollIntegrated
                          }
                          value={Utils.CapitalizeFirstLetter(
                            contactInfo?.lastName
                          )}
                          onKeyDown={
                            Utils.allowOnlyAplhabetAndHypenUnderscoreApostrophe
                          }
                          onChange={(e: any) => onContactFieldChange(e, 2)}
                          onPaste={(e) => handlerPaste("lastName", e, 2)}
                          onBlur={(e) => {
                            getUsername();
                            validationOnContactClick(2);
                          }}
                        />
                      </OverlayTrigger>

                      {getContactFieldError("lastName", 2)}
                    </div>
                  </Form.Group>
                  <Form.Group
                    className="d-flex flex-row"
                    controlId="date-selection"
                  >
                    <Form.Label>
                      <div className="d-flex align-items-center">
                        <div
                          className={contactFormError?.userName ? "pips" : ""}
                        >
                          <div className="pip light-red"></div>
                        </div>
                        <div>Username</div>
                      </div>
                    </Form.Label>
                    <div className="validate-input">
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip
                            id={"User-Name"}
                            className={
                              Utils.CapitalizeFirstLetter(userName).length <= 26
                                ? "tootltip-hide"
                                : ""
                            }
                          >
                            {Utils.CapitalizeFirstLetter(userName)}
                          </Tooltip>
                        }
                      >
                        <input
                          type="text"
                          className="form-control"
                          id="txtUsername"
                          name="txtUsername"
                          autoComplete="txtUsername"
                          value={userName}
                          disabled={true}
                        />
                      </OverlayTrigger>
                    </div>
                  </Form.Group>
                  <Form.Group
                    controlId="date-selection"
                    className={
                      contactFormError?.email && inputPosition >= 3
                        ? "validation-error d-flex flex-row"
                        : "d-flex flex-row"
                    }
                  >
                    <Form.Label>
                      <div className="d-flex align-items-center">
                        <div className={contactFormError?.email ? "pips" : ""}>
                          <div className="pip light-red"></div>
                        </div>
                        <div>Email</div>
                      </div>
                    </Form.Label>
                    <div className="validate-input d-flex flex-column">
                      {contactInfo?.email != "" && (
                        <ReactTooltip
                          id="txtEmail"
                          effect="solid"
                          place="bottom"
                        >
                          {contactInfo?.email}
                        </ReactTooltip>
                      )}

                      <input
                        type="text"
                        className="form-control"
                        id="txtEmail"
                        data-tip
                        data-for="txtEmail"
                        placeholder="Add Email Address"
                        disabled={
                          (!userGeneralInfo?.hasEditContactInfo &&
                            !isNewUserAdded) ||
                          userGeneralInfo?.hasSpecialPermissionUsers
                        }
                        name="email"
                        autoComplete="email"
                        value={contactInfo?.email}
                        onChange={(e: any) => onContactFieldChange(e, 3)}
                        onBlur={() => validationOnContactClick(3)}
                      />

                      {userGeneralInfo?.hasSpecialPermissionUsers &&
                        props.isEditGeneralInfo && (
                          <FontAwesomeIcon
                            onClick={() => getOTP("email")}
                            icon={faEdit}
                          />
                        )}

                      {getContactFieldError("email", 3)}
                    </div>
                  </Form.Group>
                  <Form.Group
                    controlId="date-selection"
                    className="d-flex flex-row"
                  >
                    <Form.Label>User ID</Form.Label>
                    <div className="user-id-input">
                      <input
                        type="text"
                        className="form-control w-100"
                        id="txtUserId"
                        name="userUniqueID"
                        autoComplete="userUniqueID"
                        value={+props?.uniqueno}
                        disabled={true}
                      />
                    </div>
                  </Form.Group>
                  <Form.Group
                    controlId="date-selection"
                    className={
                      contactFormError?.phone1 && inputPosition >= 4
                        ? "validation-error d-flex flex-row"
                        : "d-flex flex-row"
                    }
                  >
                    <Form.Label>
                      <div className="d-flex align-items-center">
                        <div className={contactFormError?.phone1 ? "pips" : ""}>
                          <div className="pip light-red"></div>
                        </div>
                        <div>Mobile Phone</div>
                      </div>
                    </Form.Label>
                    <div className="validate-input d-flex flex-column">
                      <input
                        type="text"
                        className="form-control"
                        id="txtPhoneNo"
                        name="phone1"
                        maxLength={16}
                        autoComplete="phone1"
                        placeholder="Add Mobile Phone Number"
                        disabled={
                          (!userGeneralInfo?.hasEditContactInfo &&
                            !isNewUserAdded) ||
                          userGeneralInfo?.hasSpecialPermissionUsers
                        }
                        value={contactInfo?.phone1}
                        onChange={(e: any) => {
                          onContactFieldChange(e, 4);
                        }}
                        onBlur={() => validationOnContactClick(4, "phone1")}
                      />

                      {userGeneralInfo?.hasSpecialPermissionUsers &&
                        props.isEditGeneralInfo && (
                          <FontAwesomeIcon
                            onClick={() => getOTP("phone")}
                            icon={faEdit}
                          />
                        )}
                      {getContactFieldError("phone1", 4)}
                    </div>
                    <div
                      className="badge-sec d-flex align-items-center"
                      style={{ height: 36 }}
                    >
                      {userGeneralInfo.phoneStatus === "optout" && (
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id={"tooltip-nickname"}>
                              This user has opted-out and will no longer receive
                              text messages
                            </Tooltip>
                          }
                        >
                          <span
                            onClick={() =>
                              removeOptOutWithConfirmation(contactInfo?.phone1)
                            }
                            style={{ cursor: "pointer" }}
                            className="optout-badge badge color-orange"
                          >
                            Opted-Out
                          </span>
                        </OverlayTrigger>
                      )}
                      {userGeneralInfo.phoneStatus === "blocked" && (
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id={"tooltip-nickname"}>
                              {userGeneralInfo?.blockedPhone} has been blocked
                              due to too many send failures
                            </Tooltip>
                          }
                        >
                          <span
                            style={{ cursor: "default" }}
                            className="blocked-badge badge color-red"
                          >
                            Blocked
                          </span>
                        </OverlayTrigger>
                      )}
                    </div>
                  </Form.Group>
                  <Form.Group
                    controlId="date-selection"
                    className={`d-flex flex-row ${
                      !userGeneralInfo?.hasEditContactInfo && !isNewUserAdded
                        ? "disabled"
                        : ""
                    }`}
                  >
                    <Form.Label>Primary Contact</Form.Label>
                    {primaryContacts?.length > 0 && (
                      <DropDownList
                        placeHolder={"Select Primary Contact"}
                        data={primaryContacts}
                        defaultValue={contactInfo.primaryContact}
                        isSearchRequired={false}
                        label={"label"}
                        value={"value"}
                        disabled={
                          !userGeneralInfo?.hasEditContactInfo &&
                          !isNewUserAdded
                        }
                        onDropDownChange={(item) => {
                          if (!item) {
                            return;
                          }
                          onContactFieldChange(
                            Utils.BuildCustomEventObject(
                              "primaryContact",
                              item.value
                            ),
                            5
                          );
                        }}
                        selectedItem={[
                          ...[{ value: "", label: "Select Primary Contact" }],
                          ...primaryContacts,
                        ].find(
                          (r: any) => r?.value === contactInfo.primaryContact
                        )}
                      />
                    )}
                  </Form.Group>
                  <Form.Group
                    controlId="date-selection"
                    className={
                      contactFormError?.hireDate && inputPosition >= 6
                        ? "validation-error d-flex flex-row date-picker"
                        : "d-flex flex-row date-picker"
                    }
                  >
                    <Form.Label>
                      <div className="d-flex align-items-center">
                        <div
                          className={contactFormError?.hireDate ? "pips" : ""}
                        >
                          <div className="pip light-red"></div>
                        </div>
                        <div>Hire Date</div>
                      </div>
                    </Form.Label>
                    <div className="validate-input d-flex flex-column">
                      <div className="calender-container">
                        <div
                          className={
                            calenderShow
                              ? "calender-display open"
                              : "calender-display"
                          }
                        >
                          <input
                            onFocus={calenderDisplay}
                            onClick={calenderDisplay}
                            type="text"
                            disabled={
                              (!userGeneralInfo?.hasEditContactInfo &&
                                !isNewUserAdded) ||
                              ispayrollIntegrated
                            }
                            placeholder="MM/DD/YY"
                            maxLength={8}
                            name="hireDate"
                            onInput={(e) => {
                              let value = e.currentTarget.value;

                              value = value
                                ?.toString()
                                ?.replace(/[^0-9\/]+/gi, "");

                              onContactFieldChange(
                                Utils.BuildCustomEventObject("hireDate", value),
                                6
                              );
                            }}
                            onBlur={(e) => {
                              let value = e.currentTarget.value;

                              value = value
                                ?.toString()
                                ?.replace(/[^0-9\/]+/gi, "");

                              onContactFieldChange(
                                Utils.BuildCustomEventObject("hireDate", value),
                                6,
                                true
                              );

                              validationOnContactClick(6);
                            }}
                            autoComplete="hireDate"
                            value={contactInfo.hireDate}
                            className="form-control single-date-pick"
                          />
                          <svg
                            onClick={calenderDisplay}
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z" />
                            <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z" />
                          </svg>
                        </div>
                        {calenderShow && (
                          <div className="calender">
                            <OutsideClickHandler onOutsideClick={calenderHide}>
                              <Calendar
                                calendarType={"US"}
                                onChange={(date) => {
                                  const value = moment(date).format("MM/DD/YY");
                                  onContactFieldChange(
                                    Utils.BuildCustomEventObject(
                                      "hireDate",
                                      value
                                    ),
                                    6
                                  );
                                  calenderHide();
                                }}
                                value={getFormattedDate(contactInfo.hireDate)}
                                maxDate={
                                  contactInfo.status === "Inactive" &&
                                  contactInfo?.terminationDate?.toString()
                                    ?.length === 8
                                    ? new Date(contactInfo.terminationDate)
                                    : null
                                }
                              />
                            </OutsideClickHandler>
                          </div>
                        )}
                      </div>

                      {getContactFieldError("hireDate", 6)}
                    </div>
                  </Form.Group>{" "}
                  <Form.Group
                    controlId="date-selection-sos"
                    className="d-flex flex-row"
                  >
                    <Form.Label>
                      <div className="d-flex align-items-center">
                        <div className={contactFormError?.status ? "pips" : ""}>
                          <div className="pip light-red"></div>
                        </div>
                        <div>Status</div>
                      </div>
                    </Form.Label>
                    {statusList?.length > 0 && (
                      <DropDownList
                        onFocus={calenderHide}
                        placeHolder={"Select Status"}
                        data={statusList.filter((item) => item?.id)}
                        isSearchRequired={false}
                        label={"name"}
                        value={"id"}
                        disabled={
                          !userGeneralInfo?.hasEditContactInfo &&
                          !isNewUserAdded
                          // || ispayrollIntegrated
                        }
                        onDropDownChange={(item) => {
                          onContactFieldChange(
                            Utils.BuildCustomEventObject("status", item.id),
                            7
                          );
                        }}
                        dependent={true}
                        selectedItem={[
                          ...[{ id: "", name: "Select Status" }],
                          ...statusList.filter((item) => item?.id),
                        ].find((r) => r.id === contactInfo.status)}
                      />
                    )}
                  </Form.Group>
                  <>
                    {contactInfo.status === "Inactive" && (
                      <>
                        <Form.Group
                          controlId="date-selection"
                          className={
                            contactFormError?.terminationDate &&
                            inputPosition >= 8
                              ? "validation-error d-flex flex-row date-picker"
                              : "d-flex flex-row date-picker"
                          }
                        >
                          <Form.Label>
                            <div className="d-flex align-items-center">
                              <div
                                className={
                                  contactFormError?.terminationDate
                                    ? "pips"
                                    : ""
                                }
                              >
                                <div className="pip light-red"></div>
                              </div>
                              <div>Termination Date</div>
                            </div>
                          </Form.Label>
                          <div className="validate-input d-flex flex-column">
                            <div className="calender-container">
                              <div
                                className={
                                  calenderShow
                                    ? "calender-display open"
                                    : "calender-display"
                                }
                              >
                                <input
                                  onClick={calenderTerminationDateDisplay}
                                  type="text"
                                  disabled={
                                    !userGeneralInfo?.hasEditContactInfo &&
                                    !isNewUserAdded
                                    // || ispayrollIntegrated
                                  }
                                  placeholder="MM/DD/YY"
                                  maxLength={8}
                                  name="hireDate"
                                  onInput={(e) => {
                                    let value = e.currentTarget.value;

                                    value = value
                                      ?.toString()
                                      ?.replace(/[^0-9\/]+/gi, "");

                                    onContactFieldChange(
                                      Utils.BuildCustomEventObject(
                                        "terminationDate",
                                        value
                                      ),
                                      8
                                    );
                                  }}
                                  onBlur={(e) => {
                                    let value = e.currentTarget.value;

                                    value = value
                                      ?.toString()
                                      ?.replace(/[^0-9\/]+/gi, "");

                                    onContactFieldChange(
                                      Utils.BuildCustomEventObject(
                                        "terminationDate",
                                        value
                                      ),
                                      8,
                                      true
                                    );

                                    validationOnContactClick(8);
                                  }}
                                  autoComplete="terminationDate"
                                  value={contactInfo.terminationDate}
                                  className="form-control single-date-pick"
                                />
                                <svg
                                  onClick={calenderTerminationDateDisplay}
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z" />
                                  <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z" />
                                </svg>
                              </div>
                              {calenderTerminationDateShow && (
                                <div className="calender">
                                  <OutsideClickHandler
                                    onOutsideClick={calenderTerminationDateHide}
                                  >
                                    <Calendar
                                      calendarType={"US"}
                                      onChange={(date) => {
                                        const value =
                                          moment(date).format("MM/DD/YY");
                                        onContactFieldChange(
                                          Utils.BuildCustomEventObject(
                                            "terminationDate",
                                            value
                                          ),
                                          8
                                        );
                                        calenderTerminationDateHide();
                                      }}
                                      value={getFormattedDate(
                                        contactInfo.terminationDate
                                      )}
                                      minDate={
                                        contactInfo?.hireDate?.toString()
                                          ?.length === 8
                                          ? new Date(contactInfo.hireDate)
                                          : null
                                      }
                                      maxDate={maxTerminationDate}
                                    />
                                  </OutsideClickHandler>
                                </div>
                              )}
                            </div>

                            {getContactFieldError("terminationDate", 8)}
                          </div>
                        </Form.Group>

                        <Form.Group
                          controlId="date-selection-sos"
                          className="d-flex flex-row"
                        >
                          <Form.Label>
                            <div className="d-flex align-items-center">
                              <div
                                className={
                                  contactFormError?.status ? "pips" : ""
                                }
                              >
                                <div className="pip light-red"></div>
                              </div>
                              <div>Rehire Eligibility</div>
                            </div>
                          </Form.Label>
                          {rehireEligibilityList?.length > 0 && (
                            <DropDownList
                              defaultTextColor={true}
                              onFocus={calenderHide}
                              placeHolder={"Select Rehire Eligibility"}
                              data={rehireEligibilityList.filter(
                                (item) => item?.id
                              )}
                              isSearchRequired={false}
                              label={"name"}
                              value={"id"}
                              disabled={
                                !userGeneralInfo?.hasEditContactInfo &&
                                !isNewUserAdded
                              }
                              onDropDownChange={(item) => {
                                onContactFieldChange(
                                  Utils.BuildCustomEventObject(
                                    "rehireEligibility",
                                    item.id
                                  ),
                                  14
                                );
                              }}
                              selectedItem={[
                                ...[
                                  { id: "", name: "Select Rehire Eligibility" },
                                ],
                                ...rehireEligibilityList.filter(
                                  (item) => item?.id
                                ),
                              ].find(
                                (r) => r.id === contactInfo.rehireEligibility
                              )}
                            />
                          )}
                        </Form.Group>

                        <Form.Group
                          controlId="date-selection"
                          className={
                            contactFormError?.terminationReason &&
                            inputPosition >= 9
                              ? "validation-error d-flex flex-row date-picker"
                              : "d-flex flex-row date-picker"
                          }
                        >
                          <Form.Label>
                            <div className="d-flex align-items-center">
                              <div
                                className={
                                  contactFormError?.terminationReason
                                    ? "pips"
                                    : ""
                                }
                              >
                                <div className="pip light-red"></div>
                              </div>
                              <div>Termination Reason</div>
                            </div>
                          </Form.Label>
                          <div className="validate-input d-flex flex-column termination-reason">
                            <textarea
                              className="form-control"
                              id="txtEmail"
                              placeholder="Comments..."
                              disabled={
                                !userGeneralInfo?.hasEditContactInfo &&
                                !isNewUserAdded
                                // || ispayrollIntegrated
                              }
                              name="terminationReason"
                              autoComplete="terminationReason"
                              value={contactInfo?.terminationReason}
                              onChange={(e: any) => onContactFieldChange(e, 9)}
                              onBlur={() => validationOnContactClick(9)}
                            />
                            {getContactFieldError("terminationReason", 9)}
                          </div>
                        </Form.Group>
                      </>
                    )}
                  </>
                  <Form.Group
                    controlId="date-selection-sos"
                    className="d-flex flex-row"
                  >
                    <Form.Label>Account Status</Form.Label>
                    {accountStatusList.length > 0 && (
                      <DropDownListWithToolTip
                        placeHolder={"Select Account Status"}
                        data={accountStatusList}
                        isSearchRequired={false}
                        label={"label"}
                        value={"value"}
                        disabled={
                          !userGeneralInfo?.hasEditContactInfo &&
                          !isNewUserAdded
                        }
                        onDropDownChange={(item) => {
                          onContactFieldChange(
                            Utils.BuildCustomEventObject(
                              "accountStatus",
                              item.value
                            ),
                            10
                          );
                        }}
                        selectedItem={[
                          ...[{ value: 0, label: "Select Account Status" }],
                          ...accountStatusList.filter((item) => item?.value),
                        ].find((r) => r.value === contactInfo.accountStatus)}
                      />
                    )}
                  </Form.Group>
                </div>
              </div>

              <div className="separator-line"></div>
            </>
          )}
          {(userGeneralInfo?.hasViewAccessControl || isNewUserAdded) &&
            isPayrollDataLoaded && (
              <div className="body-section">
                <div className="heading">Access Control</div>
                <div className="form-section d-flex flex-column">
                  <Form.Group
                    controlId="date-selection"
                    className={`d-flex flex-row ${
                      !userGeneralInfo?.hasEditAccessControl && !isNewUserAdded
                        ? " disabled"
                        : ""
                    }  
                  ${
                    accessFormError?.role && inputPosition >= 11
                      ? " validation-error"
                      : ""
                  } 
                  `}
                  >
                    <Form.Label>
                      <div className="d-flex align-items-center">
                        <div className={accessFormError?.role ? "pips" : ""}>
                          <div className="pip light-red"></div>
                        </div>
                        <div> Role Permission</div>
                      </div>
                    </Form.Label>
                    <div
                      onClick={() =>
                        !userGeneralInfo?.hasEditAccessControl &&
                        !isNewUserAdded
                          ? {}
                          : validationOnAccessClick(11)
                      }
                      className="validate-input d-flex flex-column"
                    >
                      {userRoleList.length > 0 && (
                        <DropDownList
                          placeHolder={"Search Role"}
                          data={userRoleList}
                          isSearchRequired={true}
                          disabled={
                            !userGeneralInfo?.hasEditAccessControl &&
                            !isNewUserAdded
                          }
                          label={"name"}
                          value={"id"}
                          onDropDownChange={(item) => {
                            if (!item) {
                              return;
                            }
                            onAccessControlFieldChange(
                              Utils.BuildCustomEventObject("role", item?.id),
                              11
                            );
                          }}
                          selectedItem={[
                            ...[{ id: 0, name: "Select Role" }],
                            ...userRoleList,
                          ].find((r) => +r.id === +accessControlInfo.role)}
                          autoComplete={`new-access-role`}
                        />
                      )}
                      {getAccessFieldError("role", 11)}
                    </div>
                  </Form.Group>

                  <Form.Group
                    // className="d-flex flex-row"
                    controlId="multiSelectHid"
                    className={`d-flex flex-row ${
                      !userGeneralInfo?.hasEditAccessControl &&
                      !isNewUserAdded &&
                      userRoleList
                        .find((r) => +r.id === +accessControlInfo.role)
                        ?.name?.toString()
                        ?.toLowerCase() !== "administrator"
                        ? "disabled"
                        : ""
                    } ${
                      accessFormError?.hids && inputPosition >= 13
                        ? "validation-error"
                        : ""
                    } `}
                  >
                    <Form.Label className="d-flex flex-row align-items-center">
                      <div className="d-flex align-items-center">
                        <div className={accessFormError?.hids ? "pips" : ""}>
                          <div className="pip light-red"></div>
                        </div>
                        <div>Select Entities</div>
                      </div>
                    </Form.Label>
                    <div className="validate-input-ehid-picker">
                      <div className="ehid-picker-wrapper">
                        <div>
                          <div
                            // className={`ehid-picker`}
                            onClick={() =>
                              accessControlInfo.role !== 0 &&
                              !userGeneralInfo?.hasEditAccessControl &&
                              !isNewUserAdded
                                ? {}
                                : validationOnAccessClick(13)
                            }
                            className={`ehid-picker ${
                              !userGeneralInfo?.hasEditAccessControl &&
                              !isNewUserAdded &&
                              userRoleList
                                .find((r) => +r.id === +accessControlInfo.role)
                                ?.name?.toString()
                                ?.toLowerCase() !== "administrator"
                                ? "disabled"
                                : ""
                            }`}
                          >
                            <BusinessAccountSelectorGeneralTab
                              role={accessControlInfo.role}
                              userGeneralInfoHids={userGeneralInfo?.hids
                                ?.map((item) => item?.hotelID)
                                ?.join(",")}
                              disabled={
                                accessControlInfo.role === 0 ||
                                (!userGeneralInfo?.hasEditAccessControl &&
                                  !isNewUserAdded &&
                                  userRoleList
                                    .find(
                                      (r) => +r.id === +accessControlInfo.role
                                    )
                                    ?.name?.toString()
                                    ?.toLowerCase() !== "administrator")
                              }
                              defaultValue={accessControlInfo?.hids
                                ?.map((item) => item?.hotelID)
                                ?.join(",")}
                              notIncludeEnterpriseAccounts={false}
                              shouldGetAllActiveHotelsOfTenant={false}
                              shouldGetAllActiveHotels={true}
                              getLogiUserEnterpriseAccess={
                                getLogiUserEnterpriseAccess
                              }
                              custom
                              ref={buisnessAccountRef}
                              handleBusinessAccountChange={(item) => {
                                onAccessControlFieldChange(
                                  Utils.BuildCustomEventObject("hids", item),
                                  13
                                );
                              }}
                              payrollIntegrationHids={
                                props.payrollIntegrationHids
                              }
                            />
                          </div>
                        </div>
                      </div>
                      {getAccessFieldError("hids", 13)}
                    </div>
                    <AddTerminationDateForPayrollHotel
                      hid={hotelWideHid}
                      userUniqueno={props?.uniqueno}
                      showTerminationDateModal={
                        calenderTerminationDateModalShow
                      }
                      hideTerminationDateModal={hideTerminationDateModal}
                    />
                  </Form.Group>
                </div>
              </div>
            )}
        </div>
        <>
          {
            <Modal
              className="enter-code-modal email-phone-modal"
              show={OpenOTPModal}
              //onHide={(e) => this.hideRenameModal(e)}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={closeOTPModal}
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  {mode == "email"
                    ? "Updated Email"
                    : "Updated Mobile Phone Number"}{" "}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container fluid className="login">
                  <div className="d-flex justify-content-center login-container flex-column forgot-password">
                    <Card className="align-self-center">
                      <OTPCodeModal
                        platform="web"
                        userNameToEdit={userName}
                        uniqueNo={props?.uniqueno}
                        otpMethod={mode}
                        updateGeneralInfo={updateGeneralInfo}
                        closeOTPModal={closeOTPModal}
                      />
                    </Card>
                  </div>
                </Container>
              </Modal.Body>
            </Modal>
          }
        </>
        <>
          {showHideSavebutton() && (
            <div className="fixed-action ml-auto">
              <div className="d-flex align-content-center flex-wrap">
                <div className="mr-auto message">
                  <span>You have unsaved changes</span>
                </div>
                <button
                  disabled={isDataSubmitting}
                  type="button"
                  className="btn btn-primary btn-discard"
                  onClick={handleDiscard}
                >
                  Discard
                </button>

                <button
                  disabled={isDataSubmitting}
                  type="button"
                  className="btn btn-primary"
                  onClick={() => updateWithConfirmation()}
                  // disabled={this.state.isSaveDisaled}
                >
                  Save
                </button>
              </div>
            </div>
          )}
        </>
      </>
    </>
  );
};
