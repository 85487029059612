import React, { useEffect, useState } from "react";
import { Facility } from "../../Common/Services/Facility";
import { DropDownList } from "../Reports/common-components/dropdown-list";

function LocationSingleSelector({
  hid,
  isSearchRequired = true,
  defaultValue,
  onDropDownChange,
}) {
  const [locationList, setLocationList] = useState<any[]>([]);
  const [locationListLoading, setLocationListLoading] = useState<any>(false);

  useEffect(() => {
    getLocationList();
  }, [hid]);

  const getLocationList = () => {
    setLocationListLoading(true);
    Facility.GetDistinctRoomsAllList(hid)
      .then((res) => {
        setLocationList([...res]);
        setLocationListLoading(false);
      })
      .catch((res) => {
        setLocationList([]);
        setLocationListLoading(false);
      });
  };

  return (
    <div>
      {!locationListLoading && (
        <DropDownList
          //  tabIndex={3}
          placeHolder={"Select Location"}
          data={locationList}
          defaultValue={defaultValue}
          isSearchRequired={isSearchRequired}
          label={"roomName"}
          value={"roomId"}
          placeHolderItem={{ id: -1, name: "Select Location" }}
          onDropDownChange={(item) => {
            if (!item) {
              return;
            }
            onDropDownChange(item);
          }}
          selectedItem={[
            ...[{ roomId: -1, roomName: "Select Location" }],
            ...locationList,
          ].find((r: any) => r?.roomId === defaultValue)}
        />
      )}
    </div>
  );
}

export default LocationSingleSelector;
