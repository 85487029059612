import React from "react";
import { Button, Card, Form } from "react-bootstrap";

import OtpInput from "react-optinput";
import "react-optinput/bundle.css";
import PageLoader from "../../../Common/Components/PageLoader";
import { toast } from "react-toastify";
import { UserManagement } from "../../../Common/Services/UserManagement";
import { User } from "../../../Common/Services/User";
import { Utils } from "../../../Common/Utilis";
export class OTPCodeModal extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      errorMessage: "",
      timeOutFunc: null,
      mode: 3,
      otp: 0,
      navigate: false,
      loaderTrue: false,
      reSendOtp: true,
      email:"",
      phone:"",
      isValid:null,
      enterOTP:false,
      invalidMessage:"",
      otpCount : 0,
      otpMethod:  this.props.otpMethod
    };
    this.handleContinue = this.handleContinue.bind(this);
    this.resendPasscode = this.resendPasscode.bind(this);
  }

  handleContinue(mode ="") {
   if(!mode) {
    if(this.state.email == ""){
      mode = "phone";
    }
    if(this.state.phone == ""){
      mode = "email";
    }
  }
  debugger
    UserManagement.SendOTP(this.props?.uniqueNo, mode,this.state.email,this.state.phone)
    .then((result) => {
      if (result?.result?.success) {
          this.setState({enterOTP:true});
          toast.success(result?.result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "userSlideOut",
          });
          
      } else {
        this.setState({enterOTP:false})
        Utils.toastError(result?.result?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "userSlideOut",
        });
      }
    })
    .catch((error) => {
      this.setState({enterOTP:false})
      Utils.toastError(error?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "userSlideOut",
      });
    });
  }

  resendPasscode(event: any) {
   
    debugger
    UserManagement.SendOTP(this.props.uniqueNo,this.state.otpMethod,this.state.email,this.state.phone)
      .then((result) => {
        if (result?.result?.success) {
          toast.success(result?.result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "userSlideOut",
          });
        } else {
          Utils.toastError(result?.result?.message, {
            // position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((error) => {
        Utils.toastError(error?.message, {
          // position: toast.POSITION.BOTTOM_RIGHT,
        });
      });

      setTimeout(() => {
        this.setState({ reSendOtp: true });
      }, 60000);
  }

  onInputChange = (value: number) => {
    if (isNaN(value)) {
      let errorMessage = "Invalid input, accept only numeric value";
      this.setState({ errorMessage: errorMessage });
    } else {
      this.setState({ otp: value });
      this.setState({ errorMessage: "" });
    }
  };

  cancel = () => {
    this.props.closeOTPModal();
  };

  validateOTP = () =>{
    this.setState({isEnabled: false});
    UserManagement.ValidateOTP(this.props.uniqueNo,this.state.otp,this.state.email,this.state.phone, this.state.otpCount>0)
      .then((result) => {
        this.setState({isEnabled: true});
        if (result?.result?.success) {
         
          if(this.state.otpCount ===0) {
           let mode ="";
            if(this.state.email == ""){
              mode = "email";
            }
            if(this.state.phone == ""){
              mode = "phone";
            }
           

            this.setState({otpCount:this.state.otpCount+1, otpMethod:mode},()=> {
              this.setState({enterOTP:false}, ()=> {
                this.setState({enterOTP:true});
              })  
              this.handleContinue(mode);
            });
            return;
          }
          if(this.state.otpCount>0) {
            toast.success(result?.result?.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
      
            this.props.closeOTPModal(true, this.state.email,this.state.phone);
            //this.props?.updateGeneralInfo();
          }
         
        } else {
          Utils.toastError(result?.result?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((error) => {
        Utils.toastError(error?.message, {
         position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  }

  onContactFieldChange = (event,field) => {
    let value = event.target.value;
    if(field == "email"){
      this.setState({email:value});
    }else{

      let validValue = value?.toString()?.replace(/[^0-9\-\+]+/ig, '')?.replace(/^.{1}[+]/ig, '')
      if(validValue) {
        if (validValue.indexOf("-") >= 0) {
          validValue = validValue?.replaceAll("-", "");
        }

        if(validValue?.toString()?.length >1) {
          validValue =  validValue?.toString().substr(0,1) + validValue?.toString()?.substr(1)?.replaceAll("+",'')
        }

      const timeOutFunc = this.state?.timeOutFunc;
      if(timeOutFunc) {
        clearTimeout(timeOutFunc)
      }
      this.setState({isValid: false});
      const timeoutId = setTimeout(() =>  this.validatePhoneNo(validValue,6), 500);
       this.setState({timeOutFunc:timeoutId});
    }
    this.setState({phone:validValue});
    }
  }
  
  CapitalizeFirstLetter =(string) =>{
    if(string) {
    return string?.charAt(0).toUpperCase() + string?.slice(1).toLowerCase();
    } else {
     return string; 
    }
  }



  validatePhoneNo = (phone, inputPosition): any => {
   let invalidMessage = "";
       if(!phone ) {
         return;
       }
       this.setState({invalidMessage:"",isValid: false});

   if(phone?.toString()?.includes("+")  &&  phone?.toString()?.substr(1,1) !=='1'
 
   ) {
  
      if(!(/^(\+\d{1,3}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(phone))){
        this.setState({invalidMessage : "Mobile Phone Number is not valid",isValid:false});
        
      }else{
        this.setState({invalidMessage : "",isValid:true});
       
      }
      return;
   }
     
       User.ValidatePhoneNumber(phone).then((response: any)=> {
       if(response?.fakeNumber === 'YES' 
       //&& response?.lineType === 'CELL PHONE'
       )
       {
        invalidMessage= "Mobile Phone Number is not valid";
        this.setState({invalidMessage,isValid: false});
       } 
       else  if(response?.lineType !== 'CELL PHONE') {
        invalidMessage = response?.lineType && response?.lineType !== "FAKE"    ? `${this.CapitalizeFirstLetter(response?.lineType)} Phone Numbers are not supported` : `Mobile Phone Number is not valid`;
        this.setState({invalidMessage,isValid: false});
   
       }  else {
       
        this.setState({invalidMessage:"",isValid: true});
     }
        });
     }
   

  validate = (event,field) => {
    let value = event.target.value;
 
    if(field == "email"){
      if(!(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value))){
        this.setState({invalidMessage : "Invalid Email",email:"",isValid:false});
        return;
      }else{
        this.setState({invalidMessage : "",isValid:true});
      }
    }
  };

  render() {
    const { otp } = this.state;
    // let selectOtpMethod = "";
    const isEnabled = otp.length === 6;
    // if (this.state.resetMode === "sms") {
    //   selectOtpMethod = "mobile phone";
    // } else {
    //   selectOtpMethod = "email";
    // }
    
    let loaderTrue = this.state.loaderTrue;
    let loadShow;
    if (loaderTrue) {
      loadShow = <PageLoader />;
    }
    
    return (
      <div className="enter-code">
        {loadShow}
        <Card.Body>
          {
             this.state.enterOTP == true &&
             <div className="card-title h4">Enter code</div>
          }
          {
            this.state.enterOTP && <>
            <div className="card-description">
              {/* {JSON.stringify(this.state)} */}
              <p>
                We've sent a code to your {this.state.otpMethod == "email" ? "Phone" : "Email"}. Please enter it below
                to continue. The code will expire in <b>20 minutes</b>.
              </p>
              <p>
                If you didn't receive the code within a few minutes, you can have
                us{" "}
                <span
                  className={this.state.reSendOtp ? "enableOtp" : "disabledOtp"}
                  onClick={this.resendPasscode}
                >
                  resend the code
                </span>
                , or you may contact your Inn-flow administrator for further help.
              </p>
            </div>
            </>
          }
          

          <Form
            name="forgotForm"
            className={
              this.state.isValid == false
                ? "validation-error frm-section was-validated"
                : "frm-section was-validated"
            }
          >
            {
              this.state.otpMethod == "email" && this.state.enterOTP == false &&
              <>
                <div className="validate-input d-flex flex-column">
                  <input
                    type="text"
                    className={"form-control " + (this.state.invalidMessage == "" ? (this.state.email ? "valid" : "") :"invalid")} 
                    id="txtEmail"
                    name="email"
                    autoComplete ="email"
                    value={this.state.email}
                    onBlur={(e: any) => this.validate(e, "email")}
                    onChange={(e: any) => this.onContactFieldChange(e,"email")}
                  />
                  <span className="validation-message text-danger">
                    {this.state.invalidMessage}
                  </span>
                </div>
              </>
            }
            {
              this.state.otpMethod === "phone" && this.state.enterOTP === false &&
              <>
                <div className="validate-input d-flex flex-column">
                  <input
                    type="text"
                    className={"form-control " + (this.state.invalidMessage === "" ? (this.state.phone ? "valid" : "") :"invalid")} 
                    id="txtPhoneNo"
                    name="phone1"
                    autoComplete ="phone1"
                    placeholder="Add Mobile Phone Number"
                    value={this.state.phone}
                  //  onBlur={(e: any) => this.validate(e, "phone")}
                    onChange={(e: any) => this.onContactFieldChange(e, "phone")}
                  />
                <span className="validation-message">
                {this.state.invalidMessage}
                </span>
                </div>
              </>
            }
            {
              this.state.enterOTP && <>
              <div className="form-lbl">Code</div>
              <Form.Group controlId="formBasicEmail">
                <OtpInput
                  codeLength={6}
                  onInputChange={(value: any) => this.onInputChange(value)}
                />
                <span className="invalid-feedback">
                  {this.state.errorMessage}
                </span>
              </Form.Group></>
            }
            
            <div className="Forgot-action">
              <Button
                variant="primary"
                type="button"
                className="btn-outline-primary"
                onClick={() => this.cancel()}
              >
                Cancel
              </Button>
              {
                this.state.enterOTP == false &&
                <Button onClick={() => this.handleContinue()} variant="primary" type="button" disabled={!this.state.isValid}>
                  Continue
                </Button>
              }
              {
                this.state.enterOTP == true &&
                <Button onClick={() => this.validateOTP()} variant="primary" type="button" disabled={!isEnabled}>
                  Continue
                </Button>
              }
              
            </div>
          </Form>
        </Card.Body>
      </div>
    );
  }
}
