import * as React from "react";
import { Container, Dropdown, Tab, Tabs, Form } from "react-bootstrap";
import { FiChevronDown } from "react-icons/fi";
import { ToastContainer, toast } from "react-toastify";
import { resolve, reject } from "q";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";

import { ReactPageLoader } from "../../../Common/Components/ReactPageLoader";
import AllReports from "./AllReports";
import AllReportsGallery from "./AllReportsGallery";
import { ReportGalleryNew } from "./ReportGalleryNew";
import { ReportRunnerNew } from "./ReportRunnerNew";
import "../../../Common/Assets/Styles/Modules/reports.scss";
import moment from "moment-timezone";
import { Utils } from "../../../Common/Utilis";
import { ModuleReports } from "../../../Common/Services/ModuleReports";
import { ReportExport } from "../../../Common/Services/ReportExport";
import QueuedReports from "./QueuedReports";
import Spinner from 'react-bootstrap/Spinner';

export class Reports extends React.Component<any, any> {
    private timerId: any;
    private linkRef: any;
    constructor(props: any) {
        super(props);
        this.linkRef = React.createRef();
        this.state = {
            activeTab: "allreports",
            loaderTrue: false,
            search: "",
            productTypes: ["All", "Accounting", "Labor"] as any,
            productType: "Products",
            allReportsData: [],
            filteredReports: [],
            nonExpandRows: [],
            densityView: [
                { id: 1, densityType: "densityView_1", name: "WIDE" },
                { id: 2, densityType: "densityView_3", name: "NARROW" },
            ],
            defaultDensityView: "densityView_3",
            selectedView: 'listview',
            viewType: [
                { name: "Card View", eventKey: "cardview" },
                { name: "List View", eventKey: "listview" }
            ],
            expandAll: false,

            lastSelectedMenu: localStorage.getItem("lastMenu"),
            showReport: false,
            runFrom: "myreports",
            reportData: {},
            startDateTime: {},
            endTime: {},
            startTime: {},
            rptStartDate: {},
            rptEnDate: {},
            clickType: "",
            lastAPD: [],
            throughAPD: [],
            queuedReports: [],
            filteredQueuedReports: [],
            searchQueue: "",
            count: 0,
            processedCount: 0
        };
    }

    componentDidMount() {
        this.GetLastActivePeriod();
        this.GetServerDate();
        this.getQueuedReports();
    }

    GetLastActivePeriod = () => {
        ModuleReports.GetlastActivePeriods(0).then(async (result: any | null) => {
            if (result != null && result.length > 0) {
                this.setState({ lastAPD: result });
            }
        }).catch((err: any) => {
            Utils.toastError(`Server Error, ${err}`, {});
        });
    }

    GetServerDate = () => {
        ModuleReports.GetSeverDateTime(0).then(async (result: any | null) => {
            if (result != null && result.length > 0) {
                this.setState({ throughAPD: result });
            }
        }).catch((err: any) => {
            Utils.toastError(`Server Error, ${err}`, {});
        });
    }

    getQueuedReports = () => {
        let request: any = {};
        this.setState({ loaderTrue: true })

        ModuleReports.GetReportQueue(request)
            .then(async (result: any | null) => {
                if (result.length > 0) {
                    const readyToViewCount = result.filter(report => report.reportStatus === "Ready to View").length;
                    const processedCount = result.filter(report => report.reportStatus === "In Progress").length;
                    this.setState({ queuedReports: result, filteredQueuedReports: result, loaderTrue: false, count: readyToViewCount, processedCount });
                } else {
                    this.setState({ queuedReports: [], filteredQueuedReports: [], loaderTrue: false });
                }
                resolve();
            })
            .catch((error) => {
                this.setState({ queuedReports: [], filteredQueuedReports: [], loaderTrue: false }, () => {
                    Utils.toastError(error.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        containerId: "consolidatedReports",
                    });
                });
                reject();
            });
    }

    setActiveTab = (selectedtab) => {
        this.setState({ activeTab: selectedtab });
    };

    onShowReport = (e, row, runfrom) => {
        if (!row.reportParameter) {
            row.reportParameter = row.parameters;
        }
        this.setState({ reportData: row, showReport: true, runFrom: runfrom });
        if (row.reportName == "Department Permission Report" || row.reportName == "Role Permission") {
            //   this.logReportLoadTiming('onstart', '')
        }
    };

    logReportLoadTiming = (callFrom, reportRunParams) => {
        if (callFrom === 'onstart') {
            let startTime = new Date()
            let reportdate = new Date()
            const format1 = "MM/D/YYYY HH:mm:ss"
            let rptStartDate = moment.utc(reportdate).format(format1);
            this.setState({ rptStartDate: rptStartDate, startDateTime: startTime, clickType: "runreport" });
        };

        if (callFrom === 'onload') {
            this.timerId = setInterval(() => {
                this.calculateRenderTime(reportRunParams)
            }, 1000)
        }
    }
    calculateRenderTime = async (reportRunParams) => {
        let loader = document.getElementById("reportviewer-container_loadingIndicator")?.style.display;
        if (loader === 'none' && this.state.clickType === "runreport") {
            let startTim = this.state.startDateTime;
            let endTime: any = new Date();
            let timeinMS: any = endTime - this.state.startDateTime
            let reportdate = new Date()
            const format1 = "MM/D/YYYY HH:mm:ss"
            let rptEnDate = moment.utc(reportdate).format(format1);
            this.setState({ rptEnDate })
            const reportRenderMinutSecond = this.millisToMinutesAndSeconds(timeinMS);
            const reportName = reportRunParams.find(({ name }) => name === "reportName")?.value.toString() ?? ''
            let runReportDateTime = this.state.rptStartDate;
            let runReportEndDateTime = rptEnDate;
            let reportRenderInMS = timeinMS;
            const reportParam = JSON.stringify(reportRunParams);
            let type = "fromReportRender"

            clearInterval(this.timerId);
            const result: any[] = await ModuleReports.InsertRunReportDetails(reportName, runReportDateTime.toString(), runReportEndDateTime, reportRenderInMS, reportRenderMinutSecond, reportParam, type);
            this.setState({ clickType: "" });
        } else {
            clearInterval(this.timerId);
        }
    }
    millisToMinutesAndSeconds(millis) {
        const a = '00'
        let minutes = Math.floor(millis / 60000);
        let seconds = ((millis % 60000) / 1000).toFixed(Number(0));
        const parts = [
            minutes, (Number(seconds) < 10 ? '0' : '') + seconds
        ];
        return a + ":" + parts.map(s => String(s).padStart(2, '0')).join(':');
    }

    hideModal = (runFrom) => {
        this.setState({ showReport: false }, () => {
            // this.getUserReport();
        });
    };

    exportReport = (userReportName, exportFormat = "PDF") => {
        let reportParameters = JSON.parse(
            localStorage.getItem("reportParameters")!
        );
        const nameIndex = reportParameters.map((p) => p.name).indexOf("writerFormat");
        let currentReportParameters = reportParameters;
        if (nameIndex > -1) {
            currentReportParameters.splice(nameIndex, 1);
        }
        currentReportParameters.push({ name: "writerFormat", value: exportFormat });


        let item = currentReportParameters.find(item => item.name === "writerFormat");

        if (item.value.toUpperCase() === "CSV") {
            ReportExport.reportDownloadCSV(JSON.stringify(reportParameters)).then(
                (result) => {
                    const href = window.URL.createObjectURL(result);
                    const a = this.linkRef.current;
                    a.download = userReportName + `.${exportFormat.toLowerCase()}`;
                    a.href = href;
                    a.click();
                    a.href = "";
                }
            );
        }
        else {
            ReportExport.reportDownload(JSON.stringify(reportParameters)).then(
                (result) => {
                    const href = window.URL.createObjectURL(result);
                    const a = this.linkRef.current;
                    a.download = userReportName + `.${exportFormat.toLowerCase()}`;
                    a.href = href;
                    a.click();
                    a.href = "";
                }
            );
        };
    };

    openScheduleTab = (reportData, showrightpanel) => {
        // copied from reportSelection.tsx, requires changes (commented as it appears it is not being used)

        // this.setState({ showReport: false, showReportGallery: false }, () => {
        //   this.setState(
        //     {
        //       activeTab: "scheduled",
        //       showRightPanel: showrightpanel,
        //     },
        //     () => {
        //       if (showrightpanel === false) {
        //         this.childLeftPanel.current.resetSelection(0);
        //       } else {
        //         if (this.childLeftPanel && this.childLeftPanel.current) {
        //           this.childLeftPanel.current.resetSelection(0);
        //           this.childLeftPanel.current.getUserScheduleReport(reportData);
        //         }
        //         this.showRightPanelData(reportData);
        //       }
        //     }
        //   );
        // });
    };


    searchQueuedReport = (e) => {
        this.setState({ searchQueue: e.target.value }, () => this.filterQueueReportData());
    };

    filterQueueReportData = () => {
        const { queuedReports, searchQueue } = this.state;
        let filteredReports = [...queuedReports];

        if (searchQueue) {
            // Filter queuedReports based on searchQueue
            filteredReports = filteredReports.filter(report => {
                const searchLowerCase = searchQueue.toLowerCase();
                const formattedRunDate = new Date(report.runDate).toLocaleDateString('en-US', {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric'
                });
                const ehidArray = report.ehid.split(',').map(ehid => ehid.trim().toLowerCase());
                const moduleArray = report.module.split(',').map(module => module.trim().toLowerCase());

                return (
                    report.reportName.toLowerCase().includes(searchLowerCase) ||
                    report.versionName?.toLowerCase().includes(searchLowerCase) ||
                    ehidArray.some(ehid => ehid.includes(searchLowerCase)) || // Check if any part of ehid matches
                    moduleArray.some(module => module.includes(searchLowerCase)) || // Check if any part of module matches
                    report.reportStatus.toLowerCase().includes(searchLowerCase) ||
                    report.expireIn.toLowerCase().includes(searchLowerCase) ||
                    formattedRunDate.includes(searchLowerCase)
                );
            });
        }

        this.setState({ filteredQueuedReports: filteredReports });
    };

    render() {
        const { activeTab, loaderTrue, defaultDensityView, expandAll, count, processedCount } = this.state;
        return (
            <div className="reports-main">
                <Container fluid className="body-sec">
                    <ToastContainer
                        enableMultiContainer
                        autoClose={3000}
                        containerId={"consolidatedReports"}
                    />
                    <div className="page-heading underline d-flex">
                        <div className="mr-auto">Reports</div>
                        {<a ref={this.linkRef}></a>}
                        <div className="action-group d-flex">
                            <Dropdown className="more-action" alignRight>
                                <Dropdown.Toggle className="btn-outline-primary btn btn-primary more" id="dropdown-more">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                    </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item>Link 1</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={activeTab}
                        onSelect={(selectedtab) => this.setActiveTab(selectedtab)}
                    >
                        <Tab eventKey="myreports" title="My Reports"></Tab>
                        <Tab eventKey="allreports" title="All Reports">
                            {loaderTrue ? (
                                <ReactPageLoader useas={"reportsMain"} />
                            ) : (
                                <div className="all-report">
                                    <div className="section" style={{ display: "flex" }}>
                                        <div className="main-Page">
                                            <div className={`${defaultDensityView}`}>
                                                <ReportGalleryNew
                                                    showModal={true}
                                                    onShowReport={this.onShowReport}
                                                />
                                            </div>
                                            {this.state.showReport && (
                                                <ReportRunnerNew
                                                    reportData={this.state.reportData}
                                                    showModal={this.state.showReport}
                                                    lastAPD={this.state.lastAPD}
                                                    throughAPD={this.state.throughAPD}
                                                    hideModal={this.hideModal}
                                                    exportReport={this.exportReport}
                                                    runFrom={this.state.runFrom}
                                                    openScheduleTab={this.openScheduleTab}
                                                    logReportLoadTiming={this.logReportLoadTiming}
                                                ></ReportRunnerNew>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Tab>
                        <Tab eventKey="scheduled" title="Scheduled"></Tab>
                        <Tab eventKey="queue"
                            title={
                                <React.Fragment>
                                    <div className="d-flex">
                                        <div>Queue</div>
                                        {activeTab === "queue" && count > 0 && processedCount === 0 && <div className="count">{count}</div>}
                                        {activeTab === "queue" && processedCount > 0 && <Spinner animation="border" />}
                                    </div>
                                </React.Fragment>
                            }>
                            {loaderTrue ? (
                                <ReactPageLoader useas={"reportsMain"} />
                            ) : (
                                <div className="all-report">
                                    <div className="section" style={{ display: "flex" }}>
                                        <div className="main-Page">
                                            <div className="banner d-flex blue">
                                                <div className="info-icn">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9.16663 5.83317H10.8333V7.49984H9.16663V5.83317ZM9.16663 9.1665H10.8333V14.1665H9.16663V9.1665ZM9.99996 1.6665C5.39996 1.6665 1.66663 5.39984 1.66663 9.99984C1.66663 14.5998 5.39996 18.3332 9.99996 18.3332C14.6 18.3332 18.3333 14.5998 18.3333 9.99984C18.3333 5.39984 14.6 1.6665 9.99996 1.6665ZM9.99996 16.6665C6.32496 16.6665 3.33329 13.6748 3.33329 9.99984C3.33329 6.32484 6.32496 3.33317 9.99996 3.33317C13.675 3.33317 16.6666 6.32484 16.6666 9.99984C16.6666 13.6748 13.675 16.6665 9.99996 16.6665Z" fill="#065A8C" />
                                                    </svg>
                                                </div>
                                                <div className="bannertxt">
                                                    <EllipsisWithTooltip placement="bottom">
                                                        Large reports for multiple hotels can take additional time to complete. We will notify you when your queued reports are finished.
                                                    </EllipsisWithTooltip>
                                                </div>
                                                <div className="ml-auto">

                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M15.8334 5.3415L14.6584 4.1665L10.0001 8.82484L5.34175 4.1665L4.16675 5.3415L8.82508 9.99984L4.16675 14.6582L5.34175 15.8332L10.0001 11.1748L14.6584 15.8332L15.8334 14.6582L11.1751 9.99984L15.8334 5.3415Z" fill="#065A8C" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="search-header d-flex">
                                                <div className="search-feild">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search"
                                                        value={this.state.searchQueue}
                                                        onChange={(e) => this.searchQueuedReport(e)}
                                                    />
                                                </div>
                                                <div className="filter-section toggle-view ml-auto">
                                                    <Form.Check id="hideViewed" key="hideViewed" type="switch" label="Hide Viewed"
                                                        checked={expandAll}
                                                    // onChange={this.handleExpandAllToggle}
                                                    />
                                                </div>
                                            </div>
                                            <div className={`${defaultDensityView}`}>
                                                <QueuedReports allReportsData={this.state.filteredQueuedReports} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Tab>
                    </Tabs>
                </Container>
            </div>
        );
    }
}