import React from "react";
import { Button, Col, Dropdown, Form, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { Utils } from "../../../Common/Utilis";
import moment from "moment";
import { UserManagement } from "../../../Common/Services/UserManagement";
import { toast, ToastContainer } from "react-toastify";
import Joi from "joi-browser";
import { EntityManagementService } from "../../../Common/Services/EntityManagementService";
import { Hotel } from "../../../Common/Services/Hotel";
import { DropDownList } from "../../Reports/common-components/dropdown-list";
import { IRoomTypeNew } from "../../../Common/Contracts/IRoomType";

export class AddRoomType extends React.Component<any, any> {
  private roomTypeFormSchema = Joi.object({
    roomType: Joi.string()
    .required()
    .error((errors) => {
      return errors.map((error) => {
        return { message: "Enter Room Type Name" };
      });
    }),
      capacity: Joi.number().required().min(0).max(9999)
      .error((errors) => {
      const _errors = errors.map((error)=> {
        if (error?.message) {
          return;
        }
        switch (error.type) {
          case "number.base":
            return {
              message: "Please enter Count",
            };
            case "number.min":
              return {
                message: "Count should be greater than equal to 0",
              };
          case "number.max":
            return {
              message: "Count should not be more than 4 digits",
            };
        }
      });
      return [_errors[0]];
    }),
    accountingDate: Joi.string().optional().allow(''),
    notes: Joi.string().max(500)
    .error((errors) => {
      return errors.map((error) => {
        return { message: "Maximun 500 characters are allowed" };
      });
    })
    .optional().allow('')
  });  

  state: any = {
    roomTypeFormData: {
      roomType: this.props?.roomTypeRowEdit?.roomType || '',
      capacity: this.props?.roomTypeRowEdit?.capacity || 0,
      accountingDate: this.props?.roomTypeRowEdit?.accountingDate ? moment(this.props?.roomTypeRowEdit?.accountingDate).format("MM/DD/YYYY") : '' || '',
      notes: this.props?.roomTypeRowEdit?.notes || ''
    },
    errors: {},
    isDataSaving: false,
    isFormNotValid: true,
    inputPosition: 0,
    dateList: [{label: "11/11/21", value: "11/11/21"}, {label: "11/11/21", value: "11/11/21"}],
        isEditButton: false,
  };

  componentDidMount = () => {
    this.getHotels();
  };
  getHotels = () => {
    this.setState({ hotelList: this.props?.userHotels });
  };

  hideModal = (isReload = false) => {
    const    roomTypeFormData = {
      roomType: this.props?.roomTypeRowEdit?.roomType || '',
    capacity: this.props?.roomTypeRowEdit?.capacity || 0,
    accountingDate: this.props?.roomTypeRowEdit?.accountingDate || '',
    notes: this.props?.roomTypeRowEdit?.notes || ''
    };
    this.props?.handleHideRoomTypeModal(isReload);

    this.setState({ roomTypeFormData, isFormNotValid: true,isDataSaving:false });
  };

  onFieldChange = (event, inputPosition) => {
    
    const { roomTypeFormData } = this.state;
    let value = event.target.value;
    const fieldName = event.target.name;
    if(fieldName === "roomType" || fieldName === "notes"){
      value = value?.toString()?.trimLeft();
    }
   else if(fieldName === "capacity" ){
      value = value?.toString()?.trim()?.replace(/[^0-9\+]+/gi, "");
      value=value.slice(0,4)
    }
    roomTypeFormData[fieldName] = value;
    this.setState({roomTypeFormData},()=> {
      this.validationOnClick(inputPosition);
    });
  };


  saveRoomType = () => {
    const request: IRoomTypeNew = {...this.state?.roomTypeFormData } as IRoomTypeNew;
    request.hid =this.props?.hotelId;
    request.uniqueno = this.props?.roomTypeRowEdit?.uniqueno || 0;
    request.accountingDate = this.props?.date ? this.props?.date :  moment().format("MM/DD/YYYY");
    request.PrevRoomTypeId =  request.uniqueno

    this.setState({isDataSaving:true})



    //Check if Room Name Already Exist
    EntityManagementService.ValidateRoomType([request],null,request.hid)
    .then((result: any) => {
      
      if(result?.result)
      {
        this.setState({isDataSaving:false})
        Utils.toastError(result?.result, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "addGarnishment",
        });
      }
      else{
        this.props?.updateRoomTypesList(request);
        this.hideModal(true);
      }
     
    }).catch((error)=>{
      
      this.props?.updateRoomTypesList(request);
      this.hideModal(true);
    })

   
  
  };
  validateRoomConfigurationFormSchema = () => {
    const valid = Joi.validate(
      this.state.roomTypeFormData,
      this.roomTypeFormSchema,
      {
        abortEarly: false,
      }
    );

    const newErrorObject: any = {};
    if (valid.error) {
      valid.error.details.forEach((err) => {
        newErrorObject[err.path.join(".")] = err.message;
      });
    }

    if (this.props?.roomTypeRowEdit?.uniqueNo > 0) {
      if (newErrorObject?.file) {
        delete newErrorObject.file;
      }
      if (newErrorObject?.fileName) {
        delete newErrorObject.fileName;
      }
    }
    if (Object.keys(newErrorObject).length === 0) {
      this.setState({ isFormNotValid: false });
    } else {
      this.setState({ isFormNotValid: true });
    }
    this.setState({ errors: newErrorObject });
    return newErrorObject;
  };

  getFieldError = (fieldName: string, fieldPosition: number) => {
    const { errors, inputPosition } = this.state;

    return (
      <>
        {Object.keys(errors).length > 0 && fieldPosition <= inputPosition && (
          <span className="validation-message">{errors[fieldName]}</span>
        )}
      </>
    );
  };
  calenderHide = () => {
    this.setState({ calenderShow: false });
  };

  calenderShow = () => {
    this.setState({ calenderShow: true });
  };

  validationOnClick = (inputPosition) => {
    this.setState({ inputPosition }, () =>
      this.validateRoomConfigurationFormSchema()
    );
  };

  render() {
    const {
      roomTypeFormData: {
          roomType,
          capacity,
          accountingDate,
          notes
      },
      errors,
      isDataSaving,
      isFormNotValid,
      inputPosition,
      dateList
    } = this.state;

    const { showAddRoomTypeModal } = this.props;
    return (
      <>
        <div className="">
          <ToastContainer containerId={"addDeduction"} autoClose={3000} />

          <Modal
            className="add-room-type-modal"
            show={showAddRoomTypeModal}
            onHide={this.hideModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
           // keyboard={this.props?.showDeductionModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {this.props?.roomTypeRowEdit?.uniqueno > 0
                  ? "Edit Room Type"
                  : "Add Room Type"}{" "}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-section">
                <div className="body-section">
                <Form.Group 
                      onBlur={() => this.validationOnClick(1)}
                      controlId="name" className="d-flex">
                    <Col
                      sm={"12"}
                      className={`mb-0 ${
                        errors?.roomType && inputPosition >= 1
                          ? "validation-error"
                          : ""
                      }`}
                      // onClick={() => this.validationOnClick(1)}
                    >
                      <Form.Label>Name</Form.Label>
                      <OverlayTrigger
                        placement="bottom"
                        defaultShow={false}
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <Tooltip id={"roomType"} className={(roomType.length <= 47) ? "tootltip-hide": ""}>
                                {roomType}
                            </Tooltip> 
                        }
                        >
                      <Form.Control
                        type="text"
                        className="form-control"
                        tabIndex={1}
                        maxLength={100}
                        id="txtRoomType"
                        name="roomType"
                        autoComplete="roomType"
                        value={roomType}
                        onChange={(e) => this.onFieldChange(e, 1)}
                        onBlur={()=>{this.setState({roomTypeFormData:{...this.state.roomTypeFormData,roomType:this.state.roomTypeFormData.roomType.trimRight()}})}}
                      />
                        </OverlayTrigger>
                      {this.getFieldError("roomType", 1)}
                    </Col>
                  </Form.Group>
                 {( this.props?.facilityFeatureFlag?.toString()?.toLowerCase() !== "yes") &&  <Form.Group  onBlur={() => this.validationOnClick(2)} controlId="capacity" className="d-flex">
                    <Col
                      sm={ +this.props?.roomTypeRowEdit?.uniqueno>0 ? "6" : "12"}
                      className={`mb-0 ${
                        errors?.capacity && inputPosition >= 2
                          ? "validation-error"
                          : ""
                      }`}
                      onBlur={() => this.validationOnClick(2)}
                    >
                      <Form.Label>Count</Form.Label>
                      <Form.Control
                        type="text"
                        // min="0"
                        tabIndex={2}
                        onKeyDown={Utils.AllowOnlyPositiveNumnberInputWithoutDot}
                        className="form-control"
                        id="txtCapacity"
                        name="capacity"
                        autoComplete="txtCapacity"
                        value={capacity}
                        // max={9999}
                        // maxLength={4}
                        onChange={(e) => this.onFieldChange(e, 2)}
                      />
                      {this.getFieldError("capacity", 2)}
                    </Col>
                    
                    {+this.props?.roomTypeRowEdit?.uniqueno>0 &&
                    <Col
                      sm={"6"}
                      className={`mb-0 ${
                        errors?.accountingDate && inputPosition >= 3
                          ? "validation-error"
                          : ""
                      }`}
                      onBlur={() => this.validationOnClick(3)}
                    >
                      <Form.Label>Effective Date</Form.Label>
                       <Form.Control
                        type="text"
                        min="0"
                        tabIndex={3}

                        className="form-control"
                        id="txtefdate"
                        name="txtefdate"
                        autoComplete="txtefdate"
                        disabled={true}
                        value={accountingDate}
                        onChange={(e) => this.onFieldChange(e, 3)}
                      />
                      {this.getFieldError("accountingDate", 3)}
                    </Col>
  }
                  </Form.Group>} 
                  <Form.Group  onBlur={() => this.validationOnClick(4)} controlId="notes" className="d-flex">
                    <Col
                      sm={"12"}
                      className={`mb-0 ${
                        errors?.notes && inputPosition >= 4
                          ? "validation-error"
                          : ""
                      }`}
                      // onClick={() => this.validationOnClick(4)}
                    >
                      <Form.Label>Notes</Form.Label>
                      <Form.Control as="textarea"
                      
                        className="form-control"
                        id="txtnotes"
                        rows={1}
                        tabIndex={4}
                        name="notes"
                        autoComplete="notes"
                        value={notes}
                        maxLength={500}
                        onChange={(e) => this.onFieldChange(e, 4)}
                        onBlur={()=>{this.setState({roomTypeFormData:{...this.state.roomTypeFormData,notes:this.state.roomTypeFormData.notes.trimRight()}})}}

                      />
                      {this.getFieldError("notes", 4)}
                    </Col>
                  </Form.Group>
                 
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
              tabIndex={8}
                className="btn-ghost"
                onClick={() => this.hideModal(false)}
              >
                Cancel
              </Button>
              <Button
               tabIndex={9}
                disabled={isFormNotValid || isDataSaving}
                className="btn btn-primary"
                onClick={this.saveRoomType}
              >
             Save
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}
