import * as React from "react";
import { Table, Form, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import InputRange from "react-input-range";
import { toast } from "react-toastify";
import "react-input-range/lib/css/index.css";
import { DeleteIcon } from "./delete";
import { SplitIcon } from "./split";
import { Utils } from "../Utilis";

export class Slider extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      header: props.header,
      rows: props.rows,
      budgetType: props.budgetType,
      oldMinValue: Number,
      oldMaxValue: Number,
      defaultMinVal: 0,
      defaultMaxVal: 0,
      isNew: false,
      validationClassName: "",
    };

    this.changeHH = this.changeHH.bind(this);
    this.changeMM = this.changeMM.bind(this);
  }

  /**Function to recive updated Props from Parent**/
  componentWillReceiveProps(nextProps: { rows: any }) {
    this.setState({ rows: nextProps.rows }, () => {
      this.updateRows();
    });
  }

  /**Function to update rows from Parent**/
  updateRows() {
    const rows = [...this.state.rows];
    const rowsLength = [...this.state.rows].length;
    this.setState({
      defaultMinVal: rows[0].value.min,
      defaultMaxVal: rows[rowsLength - 1].value.max,
    });
  }

  /**For add row of Range of Statistics **/
  handleAddRow = (event: any) => {
    event.preventDefault();
    const rows = [...this.state.rows];
    const rowsLength = rows.length;
    const maxValue = rows[rowsLength - 1].value.max;
    const minValue = rows[rowsLength - 1].value.min;
    if (maxValue !== minValue && maxValue !== "" && maxValue > 0) {
      const item = {
        value: { min: maxValue + 1, max: maxValue + 1 },
        time: "0:00",
        hours: "0",
        minutes: "00",
      };
      rows.push(item);
      this.setState(
        {
          defaultMaxVal: maxValue + 1,
          rows,
          isNew: true,
        },
        () => {
          this.props.updateRangeStatisticData(rows);
        }
      );
    } else {
      Utils.toastError("Max value cannot be blank, zero or same", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "BudgetSettingRightPanel",
      });
    }
  };

  /**For set input data in slider **/
  onBlurText = (idx: any, Val: any) => (event: any) => {
    const rows = [...this.state.rows];
    const { oldMinValue, oldMaxValue } = this.state;
    const rowsLength = [...this.state.rows].length;
    var currMinValue: any, currMaxValue: any;
    if (rows[idx].value.min === rows[idx].value.max && idx !== 0) {
    } else if (event.target.value === "") {
      event.target.value = "";
    } else if (event.target.value === "NaN") {
      event.target.value = "";
    } else if (event.target.value >= "0") {
      let i = 0,
        j = 0;
      var flag = false;
      if (Val === "min") {
        currMaxValue = rows[idx].value.max;
        if (
          rows[rows.length - 1].value.min === rows[rows.length - 1].value.max
        ) {
          rows[rows.length - 1].value.max = rows[rows.length - 1].value.max + 1;
        }
        if (Number(event.target.value) <= rows[idx].value.max - 1) {
          currMinValue = Number(event.target.value);
        } else {
          currMinValue = rows[idx].value.max - 1;
          rows[idx] = {
            value: { min: currMinValue, max: rows[idx].value.max },
            time: rows[idx].time,
          };
        }
      } else {
        currMinValue = rows[idx].value.min;
        if (event.target.value === "") {
          currMaxValue = null;
        } else if (Number(event.target.value) >= rows[idx].value.min + 1) {
          currMaxValue = Number(event.target.value);
        } else {
          currMaxValue = rows[idx].value.min + 1;
          rows[idx] = {
            value: { min: rows[idx].value.min, max: currMaxValue },
            time: rows[idx].time,
          };
        }
      }
      if (oldMinValue !== currMinValue) {
        for (i = idx - 1; i >= 0; i--) {
          const previousRowMinValue = rows[i].value.min;
          if (previousRowMinValue <= currMinValue - 2) {
            rows[i] = {
              value: { min: rows[i].value.min, max: Number(currMinValue) - 1 },
              time: rows[i].time,
            };
          } else {
            if (currMinValue <= i + i + 2 && flag === false) {
              rows[idx] = {
                value: { min: idx + idx, max: currMaxValue },
                time: rows[idx].time,
              };
              currMinValue = idx + idx;
              rows[i] = {
                value: { min: currMinValue - 2, max: currMinValue - 1 },
                time: rows[i].time,
              };
              flag = true;
            } else {
              rows[i] = {
                value: { min: currMinValue - 2, max: currMinValue - 1 },
                time: rows[i].time,
              };
            }
          }
          currMinValue = rows[i].value.min;
        }
      } else if (oldMaxValue !== currMaxValue) {
        for (j = idx + 1; j < rowsLength; j++) {
          const MaxValue = rows[rowsLength - 1].value.max;
          const nextRowMaxValue = rows[j].value.max;
          if (currMaxValue + 1 < nextRowMaxValue) {
            rows[j] = {
              value: { min: Number(currMaxValue) + 1, max: rows[j].value.max },
              time: rows[j].time,
            };
          } else {
            if (
              rows[rows.length - 1].value.min ===
              rows[rows.length - 1].value.max &&
              Number(event.target.value) < rows[rows.length - 1].value.max
            ) {
              rows[j] = {
                value: {
                  min: Number(currMaxValue) + 1,
                  max: rows[j].value.max + 1,
                },
                time: rows[j].time,
              };
            } else if (
              rows[rows.length - 1].value.min ===
              rows[rows.length - 1].value.max &&
              Number(event.target.value) >= rows[rows.length - 1].value.max
            ) {
              let minimumrows = MaxValue - 2 * (rowsLength - j);
              if (currMaxValue > minimumrows && flag === false) {
                rows[idx] = {
                  value: {
                    min: Number(currMinValue),
                    max: Number(minimumrows),
                  },
                  time: rows[idx].time,
                };

                currMaxValue = minimumrows;
                rows[j] = {
                  value: {
                    min: Number(currMaxValue) + 1,
                    max: Number(currMaxValue) + 2,
                  },
                  time: rows[j].time,
                };
                flag = true;
              } else {
                rows[j] = {
                  value: {
                    min: Number(currMaxValue) + 1,
                    max: Number(currMaxValue) + 2,
                  },
                  time: rows[j].time,
                };
              }
            } else {
              let minimumrows = MaxValue - 2 * (rowsLength - j);
              if (currMaxValue > minimumrows && flag === false) {
                rows[idx] = {
                  value: {
                    min: Number(currMinValue),
                    max: Number(minimumrows),
                  },
                  time: rows[idx].time,
                };

                currMaxValue = minimumrows;
                rows[j] = {
                  value: {
                    min: Number(currMaxValue) + 1,
                    max: Number(currMaxValue) + 2,
                  },
                  time: rows[j].time,
                };
                flag = true;
              } else {
                rows[j] = {
                  value: {
                    min: Number(currMaxValue) + 1,
                    max: Number(currMaxValue) + 2,
                  },
                  time: rows[j].time,
                };
              }
            }
          }
          currMaxValue = rows[j].value.max;
        }
      }
      if (idx === rowsLength - 1) {
        this.setState({ defaultMaxVal: rows[rowsLength - 1].value.max });
      }
      this.setState({ rows, isNew: false });
      this.props.updateRangeStatisticData(rows);
    } else {
      Utils.toastError("Please check the values and try again", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "BudgetSettingRightPanel",
      });
    }
  };

  /**To genetate Header based on recieved Props **/
  generateHeader = () => {
    let res: any[] = [];
    for (var i = 0; i < this.state.header.length; i++) {
      res.push(<th key={this.state.header[i]}>{this.state.header[i]}</th>);
    }
    return res;
  };

  /**For change input value **/
  textChange = (idx: any, value: any) => (event: any) => {
    const rows = [...this.state.rows];
    let inputValue = event.target.value;
    let isnum: any = true;
    if (inputValue.includes("/^[A-Za-z]+$/")) {
      isnum = false;
    } else if (isNaN(inputValue) === true) {
      isnum = false;
    } else if (isNaN(inputValue) === false && inputValue < 0) {
      isnum = false;
    } else {
      isnum = true;
    }
    if (isnum === true) {
      this.setState({
        oldMinValue: rows[idx].value.min,
        oldMaxValue: rows[idx].value.max,
      });
      var currentVal = Number(event.target.value);
      if (value === "min") {
        rows[idx].value.min = currentVal;
        event.target.value = currentVal;
      } else if (value === "max") {
        rows[idx].value.max = currentVal;
        event.target.value = currentVal;
      }
      this.setState({ rows: rows });
    } else {
      event.target.value = "";
    }
  };

  /**For remove row of Range of Statistics **/
  handleRemoveRow = (idx: any, Val: any) => (event: any) => {
    event.preventDefault();
    const rows = [...this.state.rows];
    var currMinValue: any;
    var currMaxValue: any;
    let validationFlag: any;
    for (let i = 0; i <= rows.length - 1; i++) {
      if (
        rows[i].value.min === rows[i].value.max ||
        rows[i].value.max === 0 ||
        rows[i].value.max === ""
      ) {
        validationFlag = true;
      } else {
        validationFlag = false;
      }
    }
    if (Val.min === Val.max) {
      currMinValue = Val.min;
      currMaxValue = rows[idx - 1].value.max;
    } else {
      currMinValue = Val.min;
      currMaxValue = Val.max;
    }

    if (idx === rows.length - 1 && idx !== 0) {
      rows[idx - 1] = {
        value: { min: rows[idx - 1].value.min, max: rows[idx - 1].value.max },
        time: rows[idx - 1].time,
      };
      this.setState(rows);
      rows.splice(idx, 1);
      this.setState({ rows }, () => {
        this.props.updateRangeStatisticData(rows);
      });
    } else if (validationFlag === true) {
      Utils.toastError("Please enter Max range value", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "BudgetSettingRightPanel",
      });
    } else if (rows.length === 1) {
      Utils.toastError("Single row can not be deleted", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "BudgetSettingRightPanel",
      });
    } else {
      if (rows.length === idx + 1) {
        rows[idx - 1] = {
          value: { min: rows[idx - 1].value.min, max: rows[idx - 1].value.max },
          time: rows[idx - 1].time,
        };
        this.setState(rows);
      } else {
        rows[idx + 1] = {
          value: { min: currMinValue, max: rows[idx + 1].value.max },
          time: rows[idx + 1].time,
        };
        this.setState(rows);
      }
      rows.splice(idx, 1);
      this.setState({ rows }, () => {
        this.props.updateRangeStatisticData(rows);
      });
    }
  };

  /**For Split row of Range of Statistics **/
  handleSplitRow = (idx: any, Val: any) => (event: any) => {
    event.preventDefault();
    const rows = [...this.state.rows];
    var currMinValue = parseInt(Val.min);
    var currMaxValue = Val.max;
    var newCurrMaxValue: number;
    var nextCurrMinValue: any;
    let validationFlag: any;
    for (let i = 0; i <= rows.length - 1; i++) {
      if (
        rows[i].value.min === rows[i].value.max ||
        rows[i].value.max === 0 ||
        rows[i].value.max === ""
      ) {
        validationFlag = true;
      } else {
        validationFlag = false;
      }
    }
    if (validationFlag === true) {
      Utils.toastError(
        "Row can not be split when max value is blank, zero or same",
        {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "BudgetSettingRightPanel",
        }
      );
    } else if (currMaxValue - currMinValue >= 2) {
      const item = {
        value: { min: currMinValue, max: currMaxValue },
        time: "00:00",
        hours: "00",
        minutes: "00"
      };
      rows.splice(idx + 1, 0, item);
      this.setState({ rows }, () => {
        this.props.updateRangeStatisticData(rows);
      });
      var currRange = currMaxValue - currMinValue;
      if (currRange % 2 === 0) {
        newCurrMaxValue = currMinValue + currRange / 2;
        nextCurrMinValue = newCurrMaxValue + 1;
      } else {
        newCurrMaxValue = currMinValue + (currRange - 1) / 2;
        nextCurrMinValue = newCurrMaxValue + 1;
      }
      rows[idx] = {
        value: { min: currMinValue, max: newCurrMaxValue },
        time: rows[idx].time,
        hours: rows[idx].hours,
        minutes: rows[idx].minutes
      };
      rows[idx + 1] = {
        value: { min: nextCurrMinValue, max: currMaxValue },
        time: "00:00",
        hours: "00",
        minutes: "00"
      };
      this.setState({ rows }, () => {
        this.props.updateRangeStatisticData(rows);
      });
    } else {
      Utils.toastError("The range is not large enough to split", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "BudgetSettingRightPanel",
      });
    }
  };

  /**For complete change on slider **/
  handleChangeComplete = (idx: any) => (event: any) => {
    const rows = [...this.state.rows];
    const { oldMinValue, oldMaxValue } = this.state;
    const rowsLength = [...this.state.rows].length;
    var currMinValue = event.min;
    var currMaxValue = event.max;
    var flag = false;
    let i = 0,
      j = 0;
    if (oldMinValue !== currMinValue) {
      for (i = idx - 1; i >= 0; i--) {
        const previousRowMinValue = rows[i].value.min;
        if (previousRowMinValue <= currMinValue - 2) {
          rows[i] = {
            value: { min: previousRowMinValue, max: currMinValue - 1 },
            time: rows[i].time,
          };
        } else {
          if (currMinValue <= i + i + 2 && flag === false) {
            rows[idx] = {
              value: { min: idx + idx, max: currMaxValue },
              time: rows[idx].time,
            };
            currMinValue = idx + idx;
            rows[i] = {
              value: { min: currMinValue - 2, max: currMinValue - 1 },
              time: rows[i].time,
            };
            flag = true;
          } else {
            rows[i] = {
              value: { min: currMinValue - 2, max: currMinValue - 1 },
              time: rows[i].time,
            };
          }
        }
        currMinValue = rows[i].value.min;
      }
    } else if (oldMaxValue !== currMaxValue) {
      flag = false;
      for (j = idx + 1; j < rowsLength; j++) {
        const MaxValue = rows[rowsLength - 1].value.max;
        const nextRowMaxValue = rows[j].value.max;
        if (currMaxValue + 1 < nextRowMaxValue) {
          rows[j] = {
            value: { min: currMaxValue + 1, max: nextRowMaxValue },
            time: rows[j].time,
          };
        } else {
          let minimumrows = MaxValue - 2 * (rowsLength - j);
          if (currMaxValue > minimumrows && flag === false) {
            rows[idx] = {
              value: { min: currMinValue, max: minimumrows },
              time: rows[idx].time,
            };

            currMaxValue = minimumrows;
            rows[j] = {
              value: { min: currMaxValue + 1, max: currMaxValue + 2 },
              time: rows[j].time,
            };
            flag = true;
          } else {
            rows[j] = {
              value: { min: currMaxValue + 1, max: currMaxValue + 2 },
              time: rows[j].time,
            };
          }
        }
        currMaxValue = rows[j].value.max;
      }
    }
    this.setState({ rows }, () => {
      this.props.updateRangeStatisticData(rows);
    });
  };

  /**For change slider value of Range of Statistics **/
  handleChange = (idx: any, value: any) => (event: any) => {
    const rows = [...this.state.rows];
    let validationFlag: any;
    for (let i = 0; i <= rows.length - 1; i++) {
      if (
        rows[i].value.min === rows[i].value.max ||
        rows[i].value.max === 0 ||
        rows[i].value.max === ""
      ) {
        validationFlag = true;
      } else {
        validationFlag = false;
      }
    }
    if (validationFlag === true) {
      // this.handleChangeComplete(idx);
      // Utils.toastError("User can't adjust rows when max value is zero or blank", {
      //   // // position: toast.POSITION.BOTTOM_RIGHT,// // containerId: 'BudgetSettingRightPanel'
      // });
      return;
    } else {
      this.setState({
        oldMinValue: rows[idx].value.min,
        oldMaxValue: rows[idx].value.max,
      });
      rows[idx] = { value: event, time: rows[idx].time };
      this.setState({ rows }, () => {
        this.props.updateRangeStatisticData(rows);
      });
    }
  };

  /**For Time Clock Change added in Range of Statistics **/
  onTimeChangeHandler = (idx: any, value: any) => (event: any) => {
    let time = event.target.value;
    const rows = [...this.state.rows];
    let isnum: any;
    if (time.includes("/^[A-Za-z]+$/")) {
      isnum = false;
    } else if (time.includes(":") || time.includes(".") || time === "") {
      isnum = true;
    } else if (/^\d+$/.test(time)) {
      isnum = true;
    } else {
      isnum = false;
    }
    if (isnum === true) {
      rows[idx].time = time;
      this.setState({ rows: rows });
    } else {
      rows[idx].time = "00:00";
      this.setState({ rows: rows });
    }
  };

  /**For Time Clock Blur in Range of Statistics **/
  onTimeBlurHandler = (idx: any, Val: any) => (event: any) => {
    let time = event.target.value;
    let hour: any;
    let minute: any;
    if (time.includes(":") || time.includes(".")) {
      let count = 0;
      for (let i = 0; i < time.length; i++) {
        if (time[i] === ":" || time[i] === ".") {
          count = count + 1;
        } else {
        }
      }
      if (count >= 2) {
        const rows = [...this.state.rows];
        rows[idx].time = "00:00";
        this.setState({ rows: rows });
        Utils.toastError("Time should be in HH:MM format", {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "BudgetSettingRightPanel",
        });
      } else if (time.includes(":")) {
        let split = time.split(":");
        hour = split[0];
        hour = Number(hour).toString();
        minute = split[1];
        minute = minute.substring(0, 2);
        if (minute < 60) {
          if (minute.length === 0) {
            if (hour === "") {
              const rows = [...this.state.rows];
              rows[idx].time = "00:00";
              this.setState({ rows: rows });
            } else {
              const rows = [...this.state.rows];
              rows[idx].time = hour + ":00";
              this.setState({ rows: rows });
            }
          } else if (minute.length === 1) {
            if (hour === "") {
              const rows = [...this.state.rows];
              rows[idx].time = "00:0" + minute;
              this.setState({ rows: rows });
            } else {
              const rows = [...this.state.rows];
              rows[idx].time = hour + ":0" + minute;
              this.setState({ rows: rows });
            }
          } else {
            if (hour === "") {
              const rows = [...this.state.rows];
              rows[idx].time = "00:" + minute;
              this.setState({ rows: rows });
            } else {
              const rows = [...this.state.rows];
              rows[idx].time = hour + ":" + minute;
              this.setState({ rows: rows });
            }
          }
        } else {
          const rows = [...this.state.rows];
          rows[idx].time = "00:00";
          this.setState({ rows: rows });
          Utils.toastError("Minutes can not exceed 59", {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "BudgetSettingRightPanel",
          });
        }
      } else if (time.includes(".")) {
        let split = time.split(".");
        hour = split[0];
        hour = Number(hour).toString();
        minute = split[1];
        minute = minute.substring(0, 2);
        if (minute < 60) {
          if (minute.length === 0) {
            if (hour === "") {
              const rows = [...this.state.rows];
              rows[idx].time = "00:00";
              this.setState({ rows: rows });
            } else {
              const rows = [...this.state.rows];
              rows[idx].time = hour + ":00";
              this.setState({ rows: rows });
            }
          } else if (minute.length === 1) {
            if (hour === "") {
              const rows = [...this.state.rows];
              rows[idx].time = "00:0" + minute;
              this.setState({ rows: rows });
            } else {
              const rows = [...this.state.rows];
              rows[idx].time = hour + ":0" + minute;
              this.setState({ rows: rows });
            }
          } else {
            if (hour === "") {
              const rows = [...this.state.rows];
              rows[idx].time = "00:" + minute;
              this.setState({ rows: rows });
            } else {
              const rows = [...this.state.rows];
              rows[idx].time = hour + ":" + minute;
              this.setState({ rows: rows });
            }
          }
        } else {
          const rows = [...this.state.rows];
          rows[idx].time = "00:00";
          this.setState({ rows: rows });
          Utils.toastError("Minutes can not exceed 59", {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "BudgetSettingRightPanel",
          });
        }
      }
    } else {
      time = Number(time).toString();
      if (time === 0 || time === "" || time <= 0 || time === null) {
        time = "00:00";
        const rows = [...this.state.rows];
        rows[idx].time = time;
        this.setState({ rows: rows });
        // Utils.toastError("Time can't be left zero or blank", {
        //   // // position: toast.POSITION.BOTTOM_RIGHT,// // containerId: 'BudgetSettingRightPanel'
        // });
      } else {
        time = time + ":00";
        const rows = [...this.state.rows];
        rows[idx].time = time;
        this.setState({ rows: rows });
      }
    }
    this.props.updateRangeStatisticData(this.state.rows);
  };
  formatHourValue = (value) => {
    if (!value?.trim()) {
      return value;
    }
    const timeArray = value?.split(":");
    if (timeArray?.length < 2) {
      return value;
    }
    return (
      timeArray[0] +
      ":" +
      (timeArray[1]?.length === 1 ? "0" + timeArray[1] : timeArray[1])
    );
  };

  changeHH = (e: any, row: any, rowIndex: any) => {
    if (Number(e.target.value) >= 0) {
      const rows = [...this.state.rows];
      rows[rowIndex].hours = e.target.value;
      rows[rowIndex].time = this.formatHourValue(
        Number(e.target.value) + ":" + rows[rowIndex].minutes
      );
      this.setState({ rows: rows });
      this.props.updateRangeStatisticData(this.state.rows);
    }
  };

  changeMM = (e: any, row: any, rowIndex: any) => {
    if (Number(e.target.value) < 60) {
      const rows = [...this.state.rows];
      rows[rowIndex].minutes = e.target.value.length === 1 ? Number(e.target.value) > 5 ? "0" + e.target.value : e.target.value : e.target.value;//Number(e.target.value) < 10 ? "0" + Number(e.target.value) : Number(e.target.value);
      rows[rowIndex].time = this.formatHourValue(
        rows[rowIndex].hours + ":" + (Number(e.target.value) < 10 ? "0" + Number(e.target.value) : Number(e.target.value))
      );
      this.setState({ rows: rows });
      this.props.updateRangeStatisticData(this.state.rows);
    }
  };
  handleFocus = (event) => event.target.select();
  render() {
    return (
      <div className="RangeSlider">
        <div className="slider-table">
          <Form>
            <Table hover>
              <thead>
                <tr>{this.generateHeader()}</tr>
              </thead>
              <tbody>
                {this.state.rows.map((item: any, idx: any) => (
                  <tr id={idx} key={idx}>
                    <td>
                      <Form.Group>
                        {this.props.budgetType === "RangeRevenue" && (
                          <span className="dollarVal">$</span>
                        )}
                        <Form.Control
                          className={
                            this.props.budgetType === "RangeRevenue"
                              ? "dollar"
                              : ""
                          }
                          type="text"
                          id="min"
                          disabled={
                            this.props.manageBudgets === false || idx === 0
                              ? true
                              : false
                          }
                          //disabled={idx === 0 ? true : false}
                          value={this.state.rows[idx].value.min}
                          onChange={this.textChange(idx, "min")}
                          onBlur={this.onBlurText(idx, "min")}
                        />
                      </Form.Group>
                    </td>
                    <td
                      className={this.state.isNew ? "hideSlider" : "showSlider"}
                    >
                      <InputRange
                        maxValue={this.state.defaultMaxVal}
                        minValue={this.state.defaultMinVal}
                        value={this.state.rows[idx].value}
                        onChange={this.handleChange(
                          idx,
                          this.state.rows[idx].value
                        )}
                        disabled={!this.props.manageBudgets}
                        onChangeComplete={this.handleChangeComplete(idx)}
                      />
                    </td>
                    <td>
                      <Form.Group>
                        {this.props.budgetType === "RangeRevenue" && (
                          <span className="dollarVal">$</span>
                        )}

                        <Form.Control
                          className={
                            this.props.budgetType === "RangeRevenue"
                              ? "dollar"
                              : ""
                          }
                          disabled={!this.props.manageBudgets}
                          type="text"
                          id="max"
                          value={
                            this.state.isNew
                              ? null
                              : this.state.rows[idx].value.max
                          }
                          onChange={this.textChange(idx, "max")}
                          onBlur={this.onBlurText(idx, "max")}
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <div className="d-flex justify-content-center">
                        <div className="d-flex time-picker modf-input align-items-center">
                          <Form.Control
                            type="text"
                            pattern="\d*"
                            maxLength={5}
                            placeholder="00"
                            id="HH"
                            value={this.state.rows[idx].hours}
                            onFocus={this.handleFocus}
                            onChange={(event: any) =>
                              this.changeHH(event, item, idx)
                            }
                            onBlur={(event: any) =>
                              this.changeHH(event, item, idx)
                            }
                            disabled={!this.props.manageBudgets}
                          />
                          <span>:</span>
                          <Form.Control
                            type="text"
                            pattern="\d*"
                            maxLength={2}
                            placeholder="00"
                            id="MM"
                            value={this.state.rows[idx].minutes}
                            onFocus={this.handleFocus}
                            onChange={(event: any) =>
                              this.changeMM(event, item, idx)
                            }
                            onBlur={(event: any) =>
                              this.changeMM(event, item, idx)
                            }
                            disabled={!this.props.manageBudgets}
                          />
                        </div>
                      </div>
                    </td>
                    {this.props.manageBudgets && (
                      <td>
                        <div
                          className={
                            this.state.rows[idx].value.max -
                              this.state.rows[idx].value.min >
                              2
                              ? "valid action-btn"
                              : "invalid action-btn"
                          }
                        >
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip id={"tooltip-rangeSlider"}>
                                Split the existing range into two ranges.
                              </Tooltip>
                            }
                          >
                            <span
                              className="fa-divide"
                              onClick={this.handleSplitRow(
                                idx,
                                this.state.rows[idx].value
                              )}
                            >
                              <SplitIcon />
                            </span>
                          </OverlayTrigger>
                          <span
                            className="fa-trash"
                            onClick={this.handleRemoveRow(
                              idx,
                              this.state.rows[idx].value
                            )}
                          >
                            <DeleteIcon />
                          </span>
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
            {this.props.manageBudgets && (
              <Form.Group>
                {/* <Form.Control
                  type="button"
                  onClick={this.handleAddRow}
                  className="addRange"
                  value="Add Range"
                /> */}
                  <Button
                  className="addRange btn-ghost"
                  onClick={this.handleAddRow}
                >
                  Add Range
                </Button>
                <span className="infoText">
                  Any rooms beyond the maximum defined will use the same rate
                </span>
              </Form.Group>
            )}
          </Form>
        </div>
      </div>
    );
  }
}
