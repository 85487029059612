import * as React from "react";
import { Dropdown } from "react-bootstrap";
import PageLoader from "../Components/PageLoader";
import profile from "../Assets/Images/profile1.png";
import OutsideClickHandler from "react-outside-click-handler";
import _ from "lodash";
export class SingleSelectSearchWithImage extends React.Component<any, any> {
  private inputElement: any;
  constructor(props: any) {
    super(props);
    this.inputElement = React.createRef();
    this.state = {
      employeeNameModal: "Select Employee",
      filterData: this.props.employeeList,
      employeeList: this.props.employeeList,
      inputValue: "",
      defaultItem: this.props.defaultItem,
      loaderTrue: false,
      dateApprove: this.props.dateApprove,
    };

    // this.handleChangeEmployee = this.handleChangeEmployee.bind(this);
    // this.resetSerchBox = this.resetSerchBox.bind(this);
  }

  handleChangeEmployee = (e: { target: { value: string } }) => {
    this.setState({ loaderTrue: true });
    const updatedList = this.state.employeeList.filter(
      (item: { employee: string }) => {
        return (
          item.employee.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
        );
      }
    );
    
    this.setState({
      filterData: updatedList,
      inputValue: e.target.value,
      loaderTrue: false,
    });
  };

  resetSerchBox = () => {
    
    this.setState({ inputValue: "" });
    this.setState({ filterData: this.state.employeeList });
  };

  handleDropdownChange = (event: any, item: any) => {
    this.resetSerchBox();
    this.props.handleSelectedItem(item);
  };

  updateEmpData = (data: any) => {
    this.setState({ filterData: data, employeeList: data });
  };

  componentDidUpdate = (prevProps) => {
    if(prevProps.employeeList.length > 0 && !_.isEqual(prevProps.employeeList.map(r=>r?.userUniqueno).sort((a,b)=> a - b), this.props.employeeList.map(r=>r?.userUniqueno).sort((a,b)=> a - b))) {
         this.setState({
    filterData: this.props.employeeList,
    employeeList: this.props.employeeList,
    });
  }
  }

  render() {
    var loadShow: any;
    if (this.state.loaderTrue) {
      loadShow = <PageLoader />;
    }
    
  
  
    return (
      <>
        <OutsideClickHandler
          key="outsideclick"
          onOutsideClick={this.resetSerchBox}
        >
          <Dropdown className="single-select-image">
            {loadShow}
            <Dropdown.Toggle
              id="searchWithImage"
              className={
                this.props.mode === "primary" ? "btn-primary" : "blue-bg"
              }
            >
              {
                this.props.addUser &&
                ("Add User")
              }
              {
               ( this.props.addUser === false || this.props.addUser == null) &&
               ( "Add Entry")
              }
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <div className="search-sec">
                <input
                  type="text"
                  className="form-control"
                  onChange={this.handleChangeEmployee}
                  placeholder="Search"
                  value={this.state.inputValue}
                  ref={this.inputElement}
                />
              </div>
              <div className="list-area">
                <div className="list-section">
                  {
                    this.state.filterData.filter(x=>x.order === 1).length > 0 &&
                    <div className="sec-ttl">{this.state.defaultItem}</div>
                  }
                  {this.state.filterData.map((item: any, idx: any) => (
                    <Dropdown.Item
                      key={item.employee}
                      eventKey={item.employee}
                      onClick={(event: any) =>
                        this.handleDropdownChange(event, item)
                      }
                    >
                      {item.order === 1 && item.imagePath === null && (
                        <div className="d-flex">
                          <img
                            src={profile}
                            className="profileimg"
                            width="36"
                            height="36"
                            alt=""
                          />
                          <div>
                            <div className="name">{item.employee}</div>
                            <div className="pos-name">{item.positionName}</div>
                          </div>
                        </div>
                      )}

                      {item.order === 1 && item.imagePath !== null && (
                        <div className="d-flex">
                          {item.imagePath.length === 0 && (
                            <img
                              src={profile}
                              className="profileimg"
                              width="36"
                              height="36"
                              alt=""
                            />
                          )}
                          {item.imagePath.length > 0 && (
                            <img
                              src={item.imagePath}
                              className="profileimg"
                              width="36"
                              height="36"
                              alt=""
                            />
                          )}
                          <div>
                            <div className="name">
                              <div className="person-name">{item.employee}</div>
                              {item.isPTO === "Yes" && (
                                <div className="red-badges blue-bg">Time Off</div>
                              )}
                            </div>
                            <div className="pos-name">{item.positionName}</div>
                          </div>
                        </div>
                      )}

                      {item.order === 2 && item.suborder === 0 && (
                        <div className="sec-ttl">All Users</div>
                      )}

                      {item.order === 2 && item.imagePath === null && (
                        <div className="d-flex">
                          <img
                            src={profile}
                            className="profileimg"
                            width="36"
                            height="36"
                            alt=""
                          />
                          <div>
                            <div className="name">
                              <div className="all-user">{item.employee}</div>
                            </div>
                            <div className="pos-name">{item.positionName}</div>
                          </div>
                        </div>
                      )}

                      {item.order === 2 && item.imagePath !== null && (
                        <div className="d-flex">
                          {item.imagePath.length === 0 && (
                            <img
                              src={profile}
                              className="profileimg"
                              width="36"
                              height="36"
                              alt=""
                            />
                          )}
                          {item.imagePath.length > 0 && (
                            <img
                              src={item.imagePath}
                              className="profileimg"
                              width="36"
                              height="36"
                              alt=""
                            />
                          )}
                          <div>
                            <div className="name">{item.employee}</div>
                            <div className="pos-name">{item.positionName}</div>
                          </div>
                        </div>
                      )}
                    </Dropdown.Item>
                  ))}
                </div>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </OutsideClickHandler>
      </>
    );
  }
}
