import * as React from "react";
import "react-input-range/lib/css/index.css";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

const { SearchBar } = Search;
export class SpecialPermissionLeftPanel extends React.Component<any, any> {
  private isNewRoleScroll: any;

  constructor(props) {
    super(props);
    this.isNewRoleScroll = React.createRef();
    this.state = {
      specialPermissionList: [],
      columns: props.columns,
      changeValue: props.changeValue,
      selectedRow: true,
      fieldData: {
        permissionID: 0,
        permissionName: "",
      },
    };
  }

  componentWillReceiveProps(nextProps: any) {
    this.setState({
      specialPermissionList: nextProps.SpecialPermissionList,
      changeValue: nextProps.changeValue,
    });
  }

  rowEvents = {
    onClick: (
      e: any,
      row: {
        result: any;
        id:any;
      }
    ) => {
      if (this.state.changeValue) {
        this.props.childHandleChange("rowClick");
        this.props.rowChildValue(row);
        this.setState({ selectedRow: false });
        this.props.isValidateModelPoupTab();
      } else {
        const fieldData = {
          permissionName: row.result,
          permissionID:row.id
        };
        this.props.updateSpecialState(fieldData);
        this.setState({ selectedRow: true });
        this.setState({ isselected: false });
      }
    },
  };

  render() {
    const selectRow = {
      mode: "radio",
      clickToSelect: true,
      hideSelectColumn: true,
      classes: "active-row",
      selected: this.state.isselected ? this.state.selected : "",
    };
    const hideRow = {
      mode: "radio",
      clickToSelect: true,
      hideSelectColumn: true,
      classes: "",
    };

    return (
      <div className="left-panel">
        <ToolkitProvider
          keyField="permissionName"
          data={this.props.SpecialPermissionList}
          columns={this.state.columns}
          rowEvents={this.rowEvents}
          search
        >
          {(props: {
            searchProps: JSX.IntrinsicAttributes;
            baseProps: JSX.IntrinsicAttributes;
          }) => (
            <div>
              <div className="search-header">
                <div className="leftFilterDiv">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.33317 15.0001C9.8715 15.0001 11.2857 14.4709 12.414 13.5934L16.0773 17.2567L17.2557 16.0784L13.5923 12.4151C14.4707 11.2859 14.9998 9.87175 14.9998 8.33342C14.9998 4.65758 12.009 1.66675 8.33317 1.66675C4.65734 1.66675 1.6665 4.65758 1.6665 8.33342C1.6665 12.0092 4.65734 15.0001 8.33317 15.0001ZM8.33317 3.33341C11.0907 3.33341 13.3332 5.57591 13.3332 8.33342C13.3332 11.0909 11.0907 13.3334 8.33317 13.3334C5.57567 13.3334 3.33317 11.0909 3.33317 8.33342C3.33317 5.57591 5.57567 3.33341 8.33317 3.33341Z"
                      fill="#84888C"
                    />
                  </svg>

                  <SearchBar
                    {...props.searchProps}
                    placeholder={"Filter Permission by Name"}
                  />
                </div>
              </div>
              <div className="left-body-special">
                <BootstrapTable
                  {...props.baseProps}
                  keyField="id"
                  rowEvents={this.rowEvents}
                  selectRow={this.state.selectedRow ? selectRow : hideRow}
                  hover
                  condensed
                />
              </div>
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  }
}
