import React from "react";
import { Button, Col, Container, Dropdown, Form, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Utils } from "../../Common/Utilis";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import Joi from "joi-browser";
import { DropDownList } from "../Reports/common-components/dropdown-list";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import { StaticArrays } from "../../Common/StaticArrays";
import _ from "lodash";
import OutsideClickHandler from "react-outside-click-handler";
import { FiChevronDown } from "react-icons/fi";
import Calendar from 'react-calendar';
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { AddProcedure } from "./AddProcedure";
import { LocationAdded } from "./LocationAdded";
import { MaintenanceLocationSlideOut } from "./MaintenanceLocationSlideOut";
import { ProcedureMaintenanceSlideOut } from "./ProcedureMaintenanceSlideOut";
import { IFacilityMaintenanceListRequest, IGetProceureDetailsRequestDto } from "../../Common/Contracts/IMaintenanceSchedule";
import { FacilityMaintenance } from "../../Common/Services/FacilityMaintenance";
import { AnyARecord } from "dns";
import { confirmAlert } from "react-confirm-alert";
import { resolve, reject } from "q";
import { SingleSearchDropdownList } from "../../Common/Components/SingleSearchDropdownList";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
export class MaintenanceSchudleSlieOut extends React.Component<any, any> {
  private refMaintenanceLocationSlideOut: any; 
  private refAddProcedure: any; 
  
  constructor(props: any) {
    super(props);
    this.refMaintenanceLocationSlideOut = React.createRef();
    this.refAddProcedure = React.createRef();
    this.state = {


      tableProceuresData: [],     
      proceduresList: [],
      scheduleData: this.props.parentState.parentRowData,
      // locationData:this.props.parentState.locationData,
      hidValue: this.props.parentState.hidValue,
      hotelName: this.props.parentState.hotelName,
      isCountLocation: 0,
      tableLocationData: [],
      isProcedureMaintenanceSlideout: false,
      isChanges: true,
      temptableProceuresData: [],     
      tempTableproceduresList: [],
      isProcedureRow: [],
      priorities: this.props.parentState.prioritiList,
      priority: "",
      isProcedureChanges:false,
      isRefresh:false,
      isNew:true,
    };
  }

  componentDidMount() {
    this.setState({isChanges:this.state.scheduleData[0]?.isChanges});
    this.setState({isNew:this.state.scheduleData[0]?.isNew,priority:this.state.scheduleData[0]?.priority});
    //this.GetMSProceureDetails();
    this.GetMSAllProceureDetails();

  }

  GetMSProceureDetails() {
    this.setState({ isworkLoader: true });
    let request = {} as IGetProceureDetailsRequestDto;
    request.hotelid = Number(this.props.parentState.hidValue);
    request.gblId = this.state.scheduleData[0]?.id;
    let storage = JSON.parse(localStorage.getItem("storage")!);   
    let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);   
   request.loginUser = Number(userUniqueID);


    FacilityMaintenance.GetMSProceureDetails(request)
      .then(async (result: any | null) => {

        if (result != null && result.length > 0) {
          debugger;
          this.setState({ tableProceuresData: result,temptableProceuresData:result },()=>{
            if (this.refAddProcedure.current !== null){            
              this.refAddProcedure.current.upatedProcedureList(this.state.tableProceuresData);
            }
          });
        } else {
          this.setState({ tableProceuresData: [],temptableProceuresData:[] },()=>{
            if (this.refAddProcedure.current !== null){           
              this.refAddProcedure.current.upatedProcedureList(this.state.tableProceuresData);
            }
          });
        }


        resolve();
      })
      .catch((err) => {

        Utils.toastError(`Server Error, ${err}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "MaintenceShudleSldieOut",
        });

         reject();
      });
  }

  GetMSAllProceureDetails() {
    this.setState({ isworkLoader: true });
    let request = {} as IGetProceureDetailsRequestDto;
    request.hotelid = Number(this.props.parentState.hidValue);
    request.type = "AllProceure";
    request.gblId = this.state.scheduleData[0]?.id;
    let storage = JSON.parse(localStorage.getItem("storage")!);   
      let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);   
     request.loginUser = Number(userUniqueID);

    FacilityMaintenance.GetMSAllProceure(request)
      .then(async (result: any | null) => {

        if (result != null && result.length > 0) {
         
          this.setState({ proceduresList: result,tempTableproceduresList:result });
        } else {
          this.setState({ proceduresList: [],tempTableproceduresList: [] });
        }

        this.GetMSProceureDetails();
        resolve();
      })
      .catch((err) => {
        this.GetMSProceureDetails();
        Utils.toastError(`Server Error, ${err}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "MaintenceShudleSldieOut",
        });

         reject();
      });
  }



  closeWorkSlideOut = () => {
    if(this.state.isChanges){
      this.hideApprovalModal("sideClick");
    }else{
      this.props?.closeWorkSlideOut();
    }
    
   // this.props?.closeWorkSlideOut();
  }

  procedureSelection = (item: any, Type: any) => {
        debugger;
        //let tblPDate= _.cloneDeep([...this.state.tableProceuresData]); 
    let astname="";
    if (Type === "add") {
       let data = item;
      let tableProceuresdata= _.cloneDeep([...this.state.tableProceuresData]); 
      let tableProceuresTempdata: any = [];
      data.forEach(element => {

        if (element.isChecked == "yes") {
          let tempData: any = {};
          let name=element.assetName.split(':')
        if(name.length>0){
          astname=name[1];
        }
          tempData.assetName =  astname;
          tempData.assetTypeId = element.assetTypeId;
          tempData.countTask = element.countTask;
          tempData.hid = element.hid;
          tempData.id = element.id;
          tempData.isChecked = element.isChecked;
          tempData.isGlobal = element.isGlobal;
          tempData.procedureId = element.procedureId;
          tempData.procedureName = element.procedureName;
          tableProceuresTempdata.push(tempData);
        }
      });


      // let tempData: any = {};
      // let element = item;

      //   if (element.isChecked == "yes") {
         
      //     let name=element.assetName.split(':')
      //   if(name.length>0){
      //     astname=name[1];
      //   }
      //     tempData.assetName =  astname;
      //     tempData.assetTypeId = element.assetTypeId;
      //     tempData.countTask = element.countTask;
      //     tempData.hid = element.hid;
      //     tempData.id = element.id;
      //     tempData.isChecked = element.isChecked;
      //     tempData.isGlobal = element.isGlobal;
      //     tempData.procedureId = element.procedureId;
      //     tempData.procedureName = element.procedureName;
      //     //tableProceuresTempdata.push(tempData);
      //   }
      







      // const index = data.findIndex((xvalue) => xvalue.id === item);
     // tblPDate.push(tempData);
      // this.setState({ tableProceuresData: tblPDate, isChanges: true},()=>{
      //   if (this.refAddProcedure.current !== null){
      //     this.refAddProcedure.current.upatedProcedureList(this.state.tableProceuresData);
      //   }
        
      // });
      this.setState({tableProceuresData:[]},()=>{
        this.setState({ tableProceuresData: tableProceuresTempdata, isChanges: true,isNew:false},()=>{
             if (this.refAddProcedure.current !== null){
          this.refAddProcedure.current.upatedProcedureList(this.state.tableProceuresData);
        }
        });
      })
      
    }
    if (Type === "remove") {
      debugger;
      let data = _.cloneDeep([...this.state.tableProceuresData]);
      const index = data.findIndex((xvalue) => xvalue.procedureId === item);
      if(index!==-1){
      data.splice(index, 1);
      this.setState({ tableProceuresData: data, isChanges: true,isNew:false },()=>{
        if (this.refAddProcedure.current !== null){
          this.refAddProcedure.current.upatedProcedureList(this.state.tableProceuresData);
        }
        
      });
      }
    }
  }

  countUpdate = (state: any,stype:any) => {
  
    let totalRoom = state.filter(
      (x: any) => x.type.toLowerCase() !== "roomtype"
    );
     if(stype!=="notechange"){
      this.setState({ isChanges: true,isNew:false });
     }
    this.setState({ isCountLocation: totalRoom.length, tableLocationData: totalRoom });
  }

  onSaveMaintenance = () => {
   
    let tableLocationData = [...this.state.tableLocationData];
    let tempProceuresData = [...this.state.tableProceuresData];
    if(tableLocationData.length===0 || tempProceuresData.length===0){
      toast.success("Please add atleast one procedure and one location", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "MaintenceShudleSldieOut",
      });
      return;
    }


    let mainData: any = [];
    let mainDataObject: any = {};
    const storage = JSON.parse(localStorage.getItem("storage")!);
    let User_UniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    let TenantId = storage === null ? 0 : (storage.tenantID as any);
    mainDataObject.ProjectName = this.state.scheduleData[0]?.name;
    mainDataObject.StartDate = this.state.scheduleData[0]?.startDate;
    mainDataObject.NextStartDate = this.state.scheduleData[0]?.nextOccurrenceDate;    
    mainDataObject.EndDate = this.state.scheduleData[0]?.dueDate;
    mainDataObject.Frequency = this.state.scheduleData[0]?.frequenId == "1" ? "Day" : this.state.scheduleData[0]?.frequenId == "2" ? "Month" : this.state.scheduleData[0]?.frequenId == "3" ? "Year" : "";
    mainDataObject.FrequencyValue = this.state.scheduleData[0]?.frequency;
    mainDataObject.Hid = Number(this.props.parentState.hidValue);
    mainDataObject.TenantId = TenantId;
    mainDataObject.Pririty = this.state.priority;
    //mainData.push(mainDataObject);

    let procedureData: any = [];
    //let tempProceuresData = [...this.state.tableProceuresData];
    tempProceuresData.forEach(element => {
      let procedureDataObject: any = {};
      procedureDataObject.ProcedureId = element.procedureId;
      procedureData.push(procedureDataObject);
    });


    let locationData: any = [];

   
    tableLocationData.forEach(element => {
      let locationDataObject: any = {};
      locationDataObject.LocationId = element.roomID;
      locationData.push(locationDataObject);
    });


    let request: any = {};
    request.scheduleMaintenanceRequest = _.cloneDeep((mainDataObject));
    request.procedureIds = _.cloneDeep(procedureData);
    request.locationIds = _.cloneDeep(locationData);

    FacilityMaintenance.SaveMaintenance(request)
      .then(async (result: any | null) => {
        if (result !== null) {
         

          if (result.saveStatus === "Success") {
            toast.success(" Pending changes saved successfully", {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "MaintenceShudleSldieOut",
            });
            this.props.saveCloseWorkSlideOut();

          }
          else {


          }

        }
        resolve();
      })
      .catch((error) => {
        reject();
      });

  }

  handleProcedureSlideout = (row:any) => {
   
    let procedureData:any={};
    procedureData.procedureId=row.procedureId;
    procedureData.procedureName=row.procedureName;   
    let Data:any=[];
    
   Data.push(procedureData);

    this.setState({ isSlideOut: false,isProcedureRow:Data},()=>{
      this.setState({ isProcedureMaintenanceSlideout: true });
      });
    
  }

  closeProcedureSlideout = (isReload) => {
    this.setState({ isProcedureMaintenanceSlideout: false })
  }

  onDiscardChanges = (Type:any) => {
    if(Type==="Discard"){
    let tableProceuresData=_.cloneDeep([...this.state.temptableProceuresData]);
    let tempTableproceduresList=_.cloneDeep([...this.state.tempTableproceduresList]);
   let temppri= this.state.scheduleData[0]?.priority;
      this.setState({ isChanges: false,tableProceuresData:tableProceuresData,proceduresList:tempTableproceduresList,priority: temppri,isRefresh:true},()=>{
        if (this.refMaintenanceLocationSlideOut.current !== null){        
          this.refMaintenanceLocationSlideOut.current.GetMLocationDetails("onDiscardChanges");
          this.refMaintenanceLocationSlideOut.current.GetAllMLocationDetails("onDiscardChanges");       
        }
        if (this.refAddProcedure.current !== null){
          this.refAddProcedure.current.discardChanges();
          this.refAddProcedure.current.upatedProcedureList(this.state.tableProceuresData);
        }
        this.setState({isRefresh:false});
      });
     
      toast.success("Pending changes discarded successfully.", {
        position: toast.POSITION.BOTTOM_RIGHT,
         containerId: "MaintenceShudleSldieOut",
      });
    }else{
      this.closeWorkSlideOut();
    }
    
  }
  
  hideApprovalModal = (value) => {
  
    this.isValidateModelPoupTab(value);
  }


  isValidateModelPoupTab(value) {
    confirmAlert({
      title: "Unsaved confirmation",
      message:
        "You have unsaved changes on this page. Do you want to leave this page and discard your changes?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.isChangeUnsaved(value),
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }

  isChangeUnsaved = (value) => {
    this.props?.closeWorkSlideOut();

  }

  onDDlFiltersChange = (controlID: any, id: any) => {
   
    let { frequency, status, priority } = this.state;
    switch (controlID) {    
      case "ddlPriority":
        priority = id || "";
        break;
      default:
        break;
    }

    this.setState({ priority,isChanges:true,isNew:false}, () => {
      
    });
  };

//  upatedProcedureTable=()=>{
//   if (this.refAddProcedure.current !== null){
//     this.refAddProcedure.current.upatedProcedureList();
//   }
//  }

  render() {

    const {      
      priority,     
    } = this.state;

    const {createEditPermission}=this.props.parentState;

    const columns = [
      {
        dataField: "procedureName",
        text: "Name",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <>
              <EllipsisWithTooltip placement="bottom">
                {row.procedureName}
              </EllipsisWithTooltip>
            </>
          );
        },

      },
      {
        dataField: "assetName",
        text: "Asset",
        headerFormatter: (column) => {
          return (
            <>
              <span>Asset</span>
              <OverlayTrigger 
              overlay={<Tooltip id="asset-info">Procedure applies to assets of a matching type for each location</Tooltip>}
              placement="bottom" 
              delay={{ show: 250, hide: 400 }} 
              >
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <g clip-path="url(#clip0_361_12954)"><path d="M8.66649 7.33307H7.33315V11.3331H8.66649V7.33307Z" fill="#6A6E73"/><path d="M8.66649 4.66641H7.33315V5.99974H8.66649V4.66641Z" fill="#6A6E73"/><path fill-rule="evenodd" clip-rule="evenodd" d="M15.1998 7.9998C15.1998 11.9763 11.9763 15.1998 7.9998 15.1998C4.02335 15.1998 0.799805 11.9763 0.799805 7.9998C0.799805 4.02335 4.02335 0.799805 7.9998 0.799805C11.9763 0.799805 15.1998 4.02335 15.1998 7.9998ZM14.3998 7.9998C14.3998 11.5344 11.5344 14.3998 7.9998 14.3998C4.46518 14.3998 1.5998 11.5344 1.5998 7.9998C1.5998 4.46518 4.46518 1.5998 7.9998 1.5998C11.5344 1.5998 14.3998 4.46518 14.3998 7.9998Z" fill="#6A6E73"/>
                  </g><defs><clipPath id="clip0_361_12954"><rect width="16" height="16" fill="white"/></clipPath></defs>
                  </svg>
                {column.assetName}
              </div>
            </OverlayTrigger>
            </>
          )
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            
            row.assetName?.trim().length >= 1 ? 
              <div className={`${(row.assetName?.length >= 1 && row.assetName!==" " ) ? "badge asset-badge" : ""}`}>
                <EllipsisWithTooltip placement="bottom">
                  {row.assetName}
                </EllipsisWithTooltip>
              </div> 
              :
              <></>
          );
        },

      },


      {
        dataField: "",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <Dropdown
            // onClick={(e) => {
            //   e.stopPropagation();
            // }}
            className="more-action position-static text-right"
            alignRight
          >
            {/* <Dropdown.Toggle
              className="btn-outline-primary btn btn-primary btn-ghost more"
              id="dropdown-more"
            // disabled={!this.state?.createEditPermission || selectedRows?.length > 0}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
              </svg>
            </Dropdown.Toggle> */}
            {/* <Dropdown.Menu>
              {(Number(row.inUse) !== 1) &&
                <Dropdown.Item
                // onClick={() => this.hanldeDeleteConfirmation(row.id)} 
                >
                  Delete
                </Dropdown.Item>
              }
              <Dropdown.Item
                onClick={() => this.handleProcedureSlideout()}
              >
                Archive
              </Dropdown.Item>
            </Dropdown.Menu> */}
          </Dropdown>
        ),
      },
    ];

    const tableRowEvents = {
      onClick: (e, row, rowIndex) => {
        this.handleProcedureSlideout(row)
      },
    }
    return (
      <>

        {/* <OutsideClickHandler onOutsideClick={(e: any) => this.hideApprovalModal("sideClick")}
                > */}

        <div className="pos-fxd index-1000" id="maintenance-slideout">
          <div className={`slideOut-wrapper ${this.state.isChanges ? "set-bulk-banner" : ""}`}>
            <div className="d-flex">
              <div className="back-drop" onClick={this.closeWorkSlideOut}></div>
              <Container
                fluid
                className="body-sec"
                onScroll={(e) => {this.refAddProcedure?.current?.removeTooltip()}}
              >
                <div className="page-heading d-flex">

                  <div className="user-details align-items-center mr-auto">
                    <div className="maintenance-title">
                      <EllipsisWithTooltip placement="bottom">
                      {this.state.scheduleData[0]?.name}
                      </EllipsisWithTooltip>
                    </div>
                    <div className="position-name">Maintenance Schedule</div>
                  </div>

                  <div className="d-flex">
                    <Dropdown className="more-action" alignRight>
                      <Dropdown.Toggle
                        className="btn-outline-primary btn btn-primary more border-0"
                        id="dropdown-more"
                        disabled={!createEditPermission}
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                          <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                          <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                        </svg>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item></Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <button
                      type="button"
                      className="btn wht-bg cross pr-0 pl-2"
                      onClick={this.closeWorkSlideOut}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                          fill="#84888C"
                        />
                      </svg>
                    </button>
                  </div>

                </div>
                <div className="info">
                  <h2 className="title mb-4">Schedule</h2>
                  <div className="form-item">
                    <div className="item-label">
                      <h6>Start Date</h6>
                    </div>
                    <div className="item-value">{this.state.scheduleData[0]?.startDate}</div>
                  </div>
                  <div className="form-item">
                    <div className="item-label">
                      <h6>Next Occurrence Date</h6>
                    </div>
                    <div className="item-value">{this.state.scheduleData[0]?.nextOccurrenceDate}</div>
                  </div>
                  <div className="form-item">
                    <div className="item-label">
                      <h6>Next Due Date</h6>
                    </div>
                    <div className="item-value">{this.state.scheduleData[0]?.dueDate}</div>
                  </div>
                  <div className="form-item">
                    <div className="item-label">
                      <h6>Frequency</h6>
                    </div>
                    <div className="item-value"> {this.state.scheduleData[0]?.frequency} {this.state.scheduleData[0]?.id == 0 ? this.state.scheduleData[0]?.frequenId == "1" ? "Days" : this.state.scheduleData[0]?.frequenId == "2" ? "Months" : this.state.scheduleData[0]?.frequenId == "3" ? "Years" : "" : ""}</div>
                  </div>
                  <div className="form-item priority-row">
                    <div className="item-label">
                      <h6>Priority</h6>
                    </div>
                    <div className="item-value dropdown-interface">
                      {this.state.priorities.length > 0 && (
                              <SingleSearchDropdownList
                                id={"ddlPriority"}
                                isRefresh={this.state.isRefresh}
                                allowSearch={false}
                                itemList={this.state.priorities}
                                handleSelectedItem={this.onDDlFiltersChange.bind(
                                  this,
                                  "ddlPriority"
                                )}
                                defaultItem={this.state.priorities}
                                defaultText={"Search Priority"}
                                defaultName={this.state.scheduleData[0]?.priority=="" ? "Select":this.state.scheduleData[0]?.priority}
                                controlID="1"
                                isdisable={!createEditPermission}
                              />
                            )}
                            </div>
                  </div>

                </div>
                <h4 className="title mb-3">Procedures</h4>
                <div>
                  <div>
                    <div className="maintenance-procedure-tbl">
                      <div className="block-bar"></div>

                      <BootstrapTable
                        //id={"procuers"}
                        // {...props.baseProps}
                        keyField="ID"
                        data={this.state.tableProceuresData}
                        columns={columns}
                        rowEvents={tableRowEvents}
                      // hover

                      // selectRow={selectRow}

                      />

                    </div>

                    <div>

                      {this.state.proceduresList.length > 0 && (
                        <div className="select-users">
                          <Form.Group controlId="set-Forecast" className="mb-0">

                            <AddProcedure
                              ref={this.refAddProcedure}
                              conflictCheck="Actual"
                              parentState={_.cloneDeep(this.state)}
                              procedureSelection={this.procedureSelection}
                              disabled={!createEditPermission}
                            // multiSelectErrorValidation={this.multiSelectErrorValidation}
                            />
                          </Form.Group>
                        </div>
                       )}

                     

                      <div className="pb-2">
                        <hr className="my-4" />
                      </div>

                      {/* {this.state.locationData.length  > 0 &&( */}
                      <div className="select-users">
                        <Form.Group controlId="set-Forecast">
                          <h4 className="title mb-3">Locations <span>({this.state.isCountLocation})</span></h4>
                          <MaintenanceLocationSlideOut
                            ref={this.refMaintenanceLocationSlideOut}
                            // conflictCheck="Actual"
                            parentState={this.state}
                            countUpdate={this.countUpdate}
                            disabled={!createEditPermission}
                            //discardChanges={}
                          // procedureSelection={this.procedureSelection}
                          // multiSelectErrorValidation={this.multiSelectErrorValidation}
                          />
                        </Form.Group>
                      </div>
                      {/* )} */}


                      {this.state.isChanges && (
                        <div className="strip-wrapper">
                          <div className="fixed-action pl-xl-5 pl-4">
                            <div className="d-flex align-items-center flex-wrap forecast-action-strip">
                              <h4 className="message mr-auto p-0 m-0 mr-auto h-auto">You have unsaved changes. Would you like to save and continue?</h4>
                              <Button
                                onClick={() => { this.onDiscardChanges(this.state.isNew ? "Cancel":"Discard") }}
                                className="btn-discard"
                              > {this.state.isNew ? "Cancel":"Discard" }</Button>
                              <Button className="skip-btn"
                                onClick={this.onSaveMaintenance}
                              >Save Changes</Button>
                            </div>
                          </div>
                        </div>

                      )}
                    </div>



                  </div>








                </div>
                {
                  this.state.isProcedureMaintenanceSlideout &&
                   <ProcedureMaintenanceSlideOut 
                   parentState={this.state}
                   closeProcedureSlideout={this.closeProcedureSlideout} />
                }

              </Container>
            </div>
          </div>
        </div>

        {/* </OutsideClickHandler> */}

      </>
    );
  }

}