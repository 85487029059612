import * as React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Button, Container, Dropdown, Form, Spinner } from "react-bootstrap";
import { FiChevronDown } from "react-icons/fi";
import { ToastContainer, toast } from "react-toastify";
import "../../../Common/Assets/Styles/Modules/epay-onboarding.scss";
import { resolve, reject } from "q";
import _ from "lodash";
import { Utils } from "../../../Common/Utilis";
import { PayrollIntegration } from "../../../Common/Services/PayrollIntegration";
import { ReactPageLoader } from "../../../Common/Components/ReactPageLoader";

export class PayrollIntegrationSetup extends React.Component<any, any> {
    private tenantSelector: any;
    constructor(props: any) {
        super(props);
        this.tenantSelector = React.createRef();
        this.state = {
            selectedTenant: { id: -1, name: "", status: "" },
            tenantSearch: "",
            isTenantLoading: false,
            tenantsList: [],
            filterTenantList: [],
            isTableLoading: false,
            isSaveEnabled: false,
            isSubmitLoading: false,
            headerData: [],
            payrollIntegrationHotelData: [],
            payrollIntegrationHotelDataCopy: [],
            tableData: [],
            tableDataCopy: [],
            dynamicColumnArray: [],
            disabledCheckboxes: {},
        };
    }

    componentDidMount() {
        this.getTenantsList();
    }
    getTenantsList = () => {
        this.setState({ isTenantLoading: true })
        PayrollIntegration.GetLaborTenantList('labor')
            .then(async (result: any) => {
                const data = result;
                let filterItem: any[] = [];
                filterItem = data.filter((item: any) => item.status.toString().toLocaleLowerCase() === "active")
                this.setState({ tenantsList: filterItem, filterTenantList: filterItem, isTenantLoading: false });
            })
    }


    searchItems = (e: any) => {
        const dataList = Object.assign([], JSON.parse(JSON.stringify(this.state.tenantsList)));
        let { filterTenantList } = this.state;
        let result = e.target.value;
        this.setState({ tenantSearch: result });
        if (result !== "") {
            filterTenantList = dataList.filter((o: any) => o.tenantName.toLowerCase().includes(result.toLowerCase().trim()));
        } else {
            filterTenantList = dataList;
        }
        this.setState({ filterTenantList });
    };

    tenantDDToggle = (e: any) => {
        const dataList = Object.assign([], JSON.parse(JSON.stringify(this.state.tenantsList)));
        this.setState({ tenantSearch: "", filterTenantList: dataList });
        (e === true) && setTimeout(() => { $("#idTenantSearch").focus(); }, 50);
        setTimeout(() => {
            this.tenantSelector.current.getElementsByClassName('active')[0]?.focus();
        }, 100);
    }

    tenantChange = (item: any) => {
        let { selectedTenant } = this.state;
        selectedTenant.id = item.tenantID;
        selectedTenant.name = item.tenantName;
        selectedTenant.status = item.status;
        this.setState({
            selectedTenant,
            isTableLoading: true,
            isSaveEnabled: false,
            payrollIntegrationHotelData: [],
            payrollIntegrationHotelDataCopy: [],
            tableData: [],
            tableDataCopy: [],
            headerData : []

        }, () => {
            this.GetPayrollIntegrationHeader(item.tenantID);
            // this.GetPayrollIntegrationHotelDetails(item.tenantID);
        });
    }

    GetPayrollIntegrationHeader = (tenantid: any) => {
        this.setState({ isTableLoading: true });
        let request: any = {};
        request.selectedTenantId = tenantid;
        PayrollIntegration.GetPayrollIntegrationHeader(request)
            .then(async (result: any | null) => {
                if (result !== null && result.length > 0) {

                    this.setState({ headerData: result, }, () => {
                        let dynamicColumnArray: any = [];
                        this.state.headerData.length > 0 &&
                            this.state.headerData.map((header) => {
                                let dynamicColumnObj: any = {};
                                dynamicColumnObj.dataField = header.name;
                                dynamicColumnObj.text = header.displayName;
                                dynamicColumnObj.headerFormatter = (column, colIndex) => {
                                    return (
                                        <div className={`d-flex ${this.headerShowCheck(header.name) ? "show-half-check" : ""}`}>
                                            <Form.Check
                                                custom
                                                id={"chkheader1_" + header.name}
                                                key={"chkheader1_" + header.name}
                                                type="checkbox"
                                                label={""}
                                                checked={this.isColumnHeaderChecked(header.name, 1)}
                                                onChange={(e) => this.handleHeaderCheckbox(e, column.dataField, 1)}
                                            />
                                            <div>{column.text}</div>
                                        </div>
                                    );
                                };
                                dynamicColumnObj.formatter = (cell: any, row: any, rowIndex: any) => {
                                    return (
                                        <div className="bdr-left height-48 d-flex align-items-center">
                                            <Form.Check
                                                custom
                                                id={"chk1_" + header.name + row.id}
                                                key={"chk1_" + header.name + row.id}
                                                type="checkbox"
                                                label={""}
                                                checked={row[header.name] === "Yes" ? true : false}
                                                onChange={(e) => this.handleCheckbox(e, row, header.name)}
                                                disabled={this.state.disabledCheckboxes[`${row["ehid"]}_${row["id"]}`]}
                                            />
                                        </div>
                                    )
                                };
                                dynamicColumnArray.push(dynamicColumnObj);
                            });
                            this.setState({ dynamicColumnArray,isTableLoading:false});
                            this.GetPayrollIntegrationHotelDetails(tenantid);
                        this.setState({ dynamicColumnArray, isTableLoading: false });
                    });
                }
                else{
                    this.setState({ dynamicColumnArray : [],isTableLoading:false});
                    this.GetPayrollIntegrationHotelDetails(tenantid);
                }
                resolve();
            })
            .catch((error) => {
                reject();
            });
    }

    GetPayrollIntegrationHotelDetails = (tenantid: any) => {
        this.setState({ isTableLoading: true });
        let request: any = {};
        request.selectedTenantId = tenantid;
        PayrollIntegration.GetPayrollIntegrationHotelDetails(request)
            .then(async (result: any | null) => {
                if (result !== null && result.length > 0) {
                    this.setState({ payrollIntegrationHotelData: result, payrollIntegrationHotelDataCopy: result, isTableLoading: false }, () => {
                        let tableData: any = [];
                        let dintinctEhidArr = this.state.payrollIntegrationHotelData.map(data => data.ehid)
                            .filter((value, index, self) => self.indexOf(value) === index);

                        dintinctEhidArr.map((ehid, index) => {
                            let tableDataObj = {};
                            tableDataObj["id"] = index;
                            tableDataObj["ehid"] = ehid;
                            tableDataObj["hotelid"] = this.state.payrollIntegrationHotelData.filter((data) => data.ehid === ehid)[0].hotelid;
                            tableDataObj["isCheckAll"] = "No";
                            this.state.headerData.map((header, index) => {
                                tableDataObj[header.name] = this.state.payrollIntegrationHotelData.filter((data) => data.ehid === ehid && data.displayName === header.name)[0].checkValue;;
                            });
                            tableDataObj["viewType"] = this.state.payrollIntegrationHotelData.filter((data) => data.ehid === ehid)[0].viewType;
                            tableData.push(tableDataObj);
                            // this.setState({tableData : tableDataObj})
                        })

                    
                        debugger;
                        this.setState({ tableData, tableDataCopy: tableData }, () => {
                            let tableData = Object.assign([], JSON.parse(JSON.stringify(this.state.tableData)));
                            for (const element of tableData) {
                                for (let j = 0; j < this.state.headerData.length; j++) {
                                    if (element[this.state.headerData[j]["name"]] === "Yes") {

                                    }
                                    else {
                                        element["isCheckAll"] = "No";
                                        break;
                                    }
                                    element["isCheckAll"] = "Yes";
                                }
                            }
                            this.setState({ tableData, tableDataCopy: tableData });
                        });
                    });
                }
                resolve();
            })
            .catch((error) => {
                this.setState({ isTableLoading: false });
                Utils.toastError(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
                reject();
            });
    }



    handleSave = () => {
        this.setState({ isSubmitLoading: true, isTableLoading: true });
        let request = Object.assign([], JSON.parse(JSON.stringify(this.state.payrollIntegrationHotelData)));
        request.map((items) => ((items["selectedTenantId"] = this.state.selectedTenant.id)))

        PayrollIntegration.DeleteWebHookPayrollIntegrationHid(request).then(async (result) => {
            if (result.saveStatus.toLowerCase() === "success") {
                await PayrollIntegration.SavePayrollIntegrationHid(request)
                    .then(async (result: any | null) => {
                        if (result.saveStatus.toLowerCase() === "success") {
                            toast.success(result.message,
                                {
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                });
                            this.setState({
                                isSubmitLoading: false,
                                isSaveEnabled: false,
                                isTableLoading: false
                            }, () => {
                                this.GetPayrollIntegrationHotelDetails(this.state.selectedTenant.id);
                            });
                        } else {
                            this.setState({ isSubmitLoading: false });
                            Utils.toastError("Something went wrong.", {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            });
                        }
                        resolve();
                    })
                    .catch((error) => {
                        this.setState({ isSubmitLoading: false });
                        Utils.toastError("Something went wrong.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        reject();
                    });
            }
        }).catch(err => { })

        // PayrollIntegration.SavePayrollIntegrationHid(request)                                                                                                  
        //     .then(async (result: any | null) => {
        //         if (result.saveStatus.toLowerCase() === "success") {
        //             toast.success(result.message,                                                                
        //                 { position: toast.POSITION.BOTTOM_RIGHT ,                                                                                                    
        //                 });                     
        //             this.setState({
        //                 isSubmitLoading: false,
        //                 isSaveEnabled: false,
        //                 isTableLoading: false
        //             }, () => {
        //                 this.GetPayrollIntegrationHotelDetails(this.state.selectedTenant.id);
        //             });
        //         } else {
        //             this.setState({ isSubmitLoading: false });
        //             Utils.toastError("Something went wrong.", { 
        //                 position: toast.POSITION.BOTTOM_RIGHT,
        //              });
        //         }
        //         resolve();
        //     })
        //     .catch((error) => {
        //         this.setState({ isSubmitLoading: false });
        //         Utils.toastError("Something went wrong.", { 
        //             position: toast.POSITION.BOTTOM_RIGHT ,y
        //         });
        //         reject();
        //     });
    }

    handleCheckbox = (event, row, type) => {
        if (!this.state.isSubmitLoading) {
            let tableData = Object.assign([], JSON.parse(JSON.stringify(this.state.tableData)));
            let payrollIntegrationHotelData = Object.assign([], JSON.parse(JSON.stringify(this.state.payrollIntegrationHotelData)));
            for (const element of payrollIntegrationHotelData) {
                if (element["ehid"] === row.ehid && element["displayName"] === type) {
                    element.checkValue = event.target.checked ? "Yes" : "No";
                    element.isUpdated = "Yes";
                }
                if (element["ehid"] === row.ehid && element["displayName"] != type) {
                    element.isUpdated = "No"
                }
                if (element.isUpdated === "No") {
                    element.checkValue = "No";
                }


            }

            for (const element of tableData) {

                if (element["ehid"] === row.ehid && element["id"] === row.id) {
                    for (let key in element) {
                        if (key !== type && typeof element[key] === 'string') {
                            if (key !== "ehid") {
                                element[key] = "No";
                            }

                        }
                    }
                    element[type] = event.target.checked ? "Yes" : "No";
                }
            }
            this.setState({ tableData, payrollIntegrationHotelData, isSaveEnabled: true, }, () => {
                let tableData = Object.assign([], JSON.parse(JSON.stringify(this.state.tableData)));
                for (const element of tableData) {
                    if (element["ehid"] === row.ehid && element["id"] === row.id) {
                        for (let j = 0; j < this.state.headerData.length; j++) {
                            if (element[this.state.headerData[j]["name"]] === "Yes") {

                            }
                            else {
                                element["isCheckAll"] = "No";
                                this.setState({ tableData });
                                return;
                            }
                        }
                        element["isCheckAll"] = "Yes";
                        this.setState({ tableData });
                    }
                }
            });
        }
    }

    handleHeaderCheckbox = (event, type, viewType) => {
        if (!this.state.isSubmitLoading) {
            let tableData = Object.assign([], JSON.parse(JSON.stringify(this.state.tableData)));
            let payrollIntegrationHotelData = Object.assign([], JSON.parse(JSON.stringify(this.state.payrollIntegrationHotelData)));

            if (type === "isCheckAll") {
                for (let i = 0; i < payrollIntegrationHotelData.length; i++) {
                    if (payrollIntegrationHotelData[i]["viewType"] === viewType) {
                        payrollIntegrationHotelData[i].checkValue = event.target.checked ? "Yes" : "No";
                        payrollIntegrationHotelData[i].isUpdated = "Yes";

                    }

                }
                for (let i = 0; i < tableData.length; i++) {
                    if (tableData[i]["viewType"] === viewType) {
                        for (let j = 0; j < this.state.headerData.length; j++) {
                            tableData[i][this.state.headerData[j]["displayName"]] = event.target.checked ? "Yes" : "No";
                        }
                        tableData[i]["isCheckAll"] = event.target.checked ? "Yes" : "No";
                    }
                }
            }
            else {
                for (let i = 0; i < payrollIntegrationHotelData.length; i++) {
                    if (payrollIntegrationHotelData[i]["displayName"] === type) {
                        payrollIntegrationHotelData[i].checkValue = event.target.checked ? "Yes" : "No";
                        payrollIntegrationHotelData[i].isUpdated = "Yes";
                    }
                    if (payrollIntegrationHotelData[i]["displayName"] != type) {
                        payrollIntegrationHotelData[i].isUpdated = "No"
                    }
                    if (payrollIntegrationHotelData[i].isUpdated === "No") {
                        payrollIntegrationHotelData[i].checkValue = "No";
                    }
                }
                for (let i = 0; i < tableData.length; i++) {
                    for (let key in tableData[i]) {
                        if (key !== type && typeof tableData[i][key] === 'string') {
                            if (key !== "ehid") {
                                tableData[i][key] = "No";
                            }

                        }
                    }
                    tableData[i][type] = event.target.checked ? "Yes" : "No";
                }
            }
            this.setState({ tableData, payrollIntegrationHotelData, isSaveEnabled: true }, () => {
                let tableData = Object.assign([], JSON.parse(JSON.stringify(this.state.tableData)));

                for (const element of tableData) {
                    for (let j = 0; j < this.state.headerData.length; j++) {
                        if (element[this.state.headerData[j]["name"]] === "Yes") {
                            element["isCheckAll"] = "Yes";
                        }
                        else {
                            element["isCheckAll"] = "No";
                            break;
                        }
                        element["isCheckAll"] = "Yes";
                    }
                }
                this.setState({ tableData });
            });
        }
    }

    isColumnHeaderChecked = (type, viewType) => {
        if (this.state.tableData.length === 0) {
            return false;
        }
        for (const element of this.state.tableData) {
            if (element[type] === "Yes") {
            }
            else {
                return false;
            }
        }
        return true;
    }

    headerShowCheck = (type) => {
        if (this.state.tableData.length === 0) {
            return false;
        }
        const checkedEhid = this.state.tableData.filter((item) => item[type] === "Yes");
        if( checkedEhid.length != 0 && checkedEhid.length != this.state.tableData.length ){
            return true;
        } 
        return false;
    }

    render() {
        const columns = [
            {
                dataField: 'ehid',
                text: "EHID",
                headerClasses: '',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="d-flex align-items-center">
                            <div className="col-text sub-module">{row.ehid}</div>
                        </div>
                    );
                }
            },

            ...this.state.dynamicColumnArray,
        ];

        const { isTenantLoading, filterTenantList, selectedTenant, isTableLoading, isSubmitLoading, isSaveEnabled } = this.state;
        const checkItem = () => {
            return (
                <span className="itemcheck">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z" fill="#2CAF92" />
                    </svg>
                </span>
            )
        }
        return (
            <div className="ePayOnboardingControlMain payroll-integration-setup-wrapper">
                <Container fluid className="body-sec pos-rel">
                    <ToastContainer
                        autoClose={3000}
                        enableMultiContainer
                    />

                    <div className="page-heading underline d-flex">
                        <div className="mr-auto">Payroll Integration</div>
                        <div className="action-group d-flex">{/**/}</div>
                    </div>
                    <div className="d-flex headerDownControl">
                        <div className="ePayTenantSelector">
                            <label>Tenant</label>
                            <div className="d-flex">
                                <Dropdown className={`${isTenantLoading ? "disabled-area" : ""} ePayTenantSelectorDropdown`} onToggle={this.tenantDDToggle}>
                                    <Dropdown.Toggle className="ePayButton" id="dropdown-Tenant">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <div className="">
                                                {(selectedTenant.id !== -1 ? selectedTenant.name : "Select Tenant")}
                                            </div>
                                            <div className="ml-auto drop-arrow d-flex">
                                                <FiChevronDown />
                                            </div>
                                        </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="ePayTenantSelectorMenu" ref={this.tenantSelector}>
                                        <div className="ePayTenantSearch">
                                            <Form.Group controlId="ePayTenantSearch">
                                                <Form.Control
                                                    id="idTenantSearch"
                                                    type="text"
                                                    name="search"
                                                    value={this.state.tenantSearch}
                                                    autoComplete="off"
                                                    onChange={this.searchItems}
                                                    placeholder="Search Tenant"
                                                    autoFocus={true}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="dropdownItems">
                                            {filterTenantList.length > 0 ? (<>
                                                {filterTenantList.map((item: any, idx: any) => (
                                                    <Dropdown.Item className={selectedTenant.name === item.tenantName ? "active" : ""} key={idx} eventKey={item.tenantID} onClick={() => this.tenantChange(item)}>
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <div className=""> {item.tenantName}</div>
                                                            <div className="ml-auto">
                                                                {(item.tenantID === selectedTenant.id) && (
                                                                    checkItem()
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Dropdown.Item>
                                                ))}
                                            </>) : (
                                                <Dropdown.Item>No Item Found</Dropdown.Item>
                                            )}
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>

                    {selectedTenant.id !== -1 && (
                        <>
                            {isTableLoading ? (
                                <ReactPageLoader useas={"payroll-position-mapping"} />
                            ) : (
                                <>
                                    <div className="inner-hide payroll-integration-setup">
                                        <div className="payroll-integration-setup-wrapper">
                                            <div>
                                                <div>
                                                    <div>
                                                        <BootstrapTable
                                                            keyField='rno'
                                                            data={this.state.tableData}
                                                            columns={columns}
                                                        // noDataIndication={'No record found.'}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ePaySubmitPanel">
                                        <Button disabled={!isSaveEnabled || isSubmitLoading} onClick={this.handleSave}
                                        >{isSubmitLoading && (<Spinner size="sm" className="disabled-area" animation="border" />)} Save</Button>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </Container>
            </div>
        );
    }
}