import * as React from "react";
import "react-input-range/lib/css/index.css";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import TableSearch from "../../../Common/Components/TableSearch";

const { SearchBar } = Search;

export class BudgetSettingsLeftPanel extends React.Component<any, any> {
  private isNewBudgetScroll: any;
  constructor(props: any) {
    super(props);
    this.isNewBudgetScroll = React.createRef();
    this.state = {
      Budgets: props.Budgets,
      columns: props.columns,
      fieldData: {
        uniqueno: 0,
        departmentID: 0,
        departmentName: "",
        positionID: 0,
        positionName: "",
        notes: "",
        budgetType: "",
        budgetTypeID: "",
        revenueType: "",
        subType: "",
        tenantID: 0,
        hotelID: 0,
        statisticsNo: "",
        itemName: "",
        isRoomAssign: false,
        ishide: false,
        selected: [],
        isselected: false,
        budgetSettingList: [
          {
            text: "",
            value: "",
            srNo: "",
          },
        ],
      },
      selectedRow: true,
    };
    this.handleLeftBudget = this.handleLeftBudget.bind(this);
    this.removeSelectedRow = this.removeSelectedRow.bind(this);
    this.removeNewBlankRow = this.removeNewBlankRow.bind(this);
    this.defaultSelectedRow = this.defaultSelectedRow.bind(this);
  }

  componentWillReceiveProps(nextProps: any) {
    this.setState({ Budgets: nextProps.Budgets });
  }

  handleLeftBudget = (event: any) => {
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    this.props.updateStateNewBudget(fieldData);
    this.setState({ selectedRow: false });
  };
  
  removeSelectedRow = (data: any) => {
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    fieldData.ishide = true;
    this.setState({ selectedRow: false });
  };

  removeNewBlankRow = (data: any) => {
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    fieldData.ishide = false;
    this.setState({ selectedRow: true });
  };

  defaultSelectedRow = (data: any) => {
    if (data !== "") {
      localStorage.setItem("dataUniqueID", JSON.stringify(data));
    }

    let uniqueValueFeild: any;
    for (let i = 0; i <= this.props.Budgets.length - 1; i++) {
      if (Number(this.props.Budgets[i].uniqueno) === Number(data)) {
        uniqueValueFeild = i;
        this.setState({
          selected: [this.props.Budgets[uniqueValueFeild].uniqueno],
          isselected: true,
        });
        localStorage.setItem("dataUniqueID", JSON.stringify(""));
      }  
    }

    this.setState({ selectedRow: true });
  };
  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      
      let new_state = Object.assign({}, this.state);
      let fieldData1 = new_state.fieldData;
      fieldData1.ishide = false;
      const fieldData = {
        hotelID: row.hotelID,
        tenantID: row.tenantID,
        uniqueno: row.uniqueno,
        departmentID: row.departmentID,
        departmentName: row.departmentName,
        positionID: row.positionID,
        positionName: row.positionName,
        budgetType: row.budgetType === "MPCR" ? "MPOR": row.budgetType,
        budgetTypeID: row.budgetTypeID === "MPCR" ? "MPOR": row.budgetTypeID,
        itemName: row.itemName,
        statisticsNo: row.statisticsNo,
        ishide: false,
        notesBorder: "removeBorder",
      };
      this.props.updateState(fieldData);
      this.setState({ selectedRow: true, isselected: false });
     
    },
  };
  render() {

    const selectRow = {
      mode: "radio",
      clickToSelect: true,
      hideSelectColumn: true,
      classes: "active-row",
      selected: this.state.isselected ? this.state.selected : "",
    };
    const hideRow = {
      mode: "radio",
      clickToSelect: true,
      hideSelectColumn: true,
      classes: "",
    };
    return (
     
      <div className="left-panel">


        <ToolkitProvider
          keyField="id"
          data={this.props.Budgets}
          columns={this.state.columns}
          rowEvents={this.rowEvents}
          search
        >
          {(props: {
            searchProps: JSX.IntrinsicAttributes;
            baseProps: JSX.IntrinsicAttributes;
          }) => (
            <div>
              <div className="search-header">
                {/* <SearchBar autoComplete="off" {...props.searchProps} placeholder={"Filterrrrrr"} /> */}
                <TableSearch id="tableSearch" key="tableSearch"
                  {...props.searchProps}
                  placeholder={"Filter..."}
                />
              </div>
              <div className="left-body">
                <BootstrapTable
                  {...props.baseProps}
                  // keyField="uniqueno"
                  keyField="rowNo"
                  rowEvents={this.rowEvents}
                  selectRow={this.state.selectedRow ? selectRow : hideRow}
                  hover
                  condensed
                />
                {/* {this.state.fieldData.ishide && (
                  <div
                    style={{
                      background: "#FFFFFF",
                      border: "1px solid #E0E3E6",
                    }}
                    ref={this.isNewBudgetScroll}
                  >
                    <div className="add-Budget"></div>
                  </div>
                )} */}
                {this.props.manageBudgets && (
                  <div className="add-Budget" onClick={this.handleLeftBudget}>
                    New Model
                  </div>
                )}
              </div>
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  }
}
