import Instense from "./Axios-config";
import { HouseKeepingDetailRequest, IHouseKeepingDetail, IHouseKeepingDetailSummary } from "../Contracts/IHousekeeping";

export class Housekeeping {
 
  public static getHouseKeepingDetail = async (
    hotelId: number, date:string,username:string
  ): Promise<IHouseKeepingDetail[] | null> => {
const request: HouseKeepingDetailRequest = {} as HouseKeepingDetailRequest;
request.hotelID = hotelId;
request.date = date;
    let storage = JSON.parse(localStorage.getItem("storage")!);
    request.tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.username = username;
    const url = `/LaborDayApprove/HouseKeepingDetail`;
    return Instense.get(url, {params: request} ).then((response) => {
      let result = response?.data?.result as IHouseKeepingDetail[];
      return result;
    });
  };

  public static houseKeepingDetailSummary = async ( hotelId: number, date:string,username:string): Promise<any | null> => {
    const request: HouseKeepingDetailRequest = {} as HouseKeepingDetailRequest;
    request.hotelID = hotelId;
    request.date = date;
        let storage = JSON.parse(localStorage.getItem("storage")!);
        request.tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.username = username;
    const url = `/LaborDayApprove/HouseKeepingDetailSummary`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IHouseKeepingDetailSummary[];
      return result;
    });
  };

  
}
