import * as React from "react";

import {
  Col,
  Button,
  OverlayTrigger,
  Tooltip,
  Dropdown,
  Form,
  Spinner,
} from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-calendar/dist/Calendar.css";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { SingleSearchDropdownList } from "../../Common/Components/SingleSearchDropdownList";
import { resolve, reject } from "q";
import Dropzone from "react-dropzone";
import {
  faFile,
  faFileImage,
  faFileExcel,
  faFilePdf,
  faFileCsv,
  faFileWord,
} from "@fortawesome/free-solid-svg-icons";
import { confirmAlert } from "react-confirm-alert";
import OutsideClickHandler from "react-outside-click-handler";
import { Utils } from "../../Common/Utilis";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { schConversions } from "../LaborManagement/Labor/Schedule/commonFunctions/conversions";
import AutosizeInput from "react-input-autosize";
import _ from "lodash";
import {
  ISaveDailyTaskFileRequest,
  IDailyTaskSaveRequest,
} from "../../Common/Contracts/IDailyTask";
import { FacilityDailyTask } from "../../Common/Services/FacilityDailyTask";

const RepeatList = [{ id: "Daily", name: "Daily" }];
let routeLeavingGuard: any = null;

export default class DailyTaskSlideOut extends React.Component<any, any> {
  private slideoutRef: any;
  private parentScroll: any;
  private btnSave: any;
  private input: any;

  constructor(props: any) {
    super(props);

    this.slideoutRef = React.createRef();
    this.parentScroll = React.createRef();
    this.btnSave = React.createRef();
    this.input = React.createRef();

    this.state = {
      isSpinner: false,
      pageLoader: false,
      showAddAssetModal: false,
      hidValue: "Select",
      hotelName: "Select",
      createEditPermission: this.props.createEditPermission,
      searchText: "",
      sortingOrder: "asc",
      sortingField: "",
      parentRowData: this.props.parentRowData,
      savedfiles: [],
      newSelectfiles: [],
      files: [],
      fileSize: 5242880,
      moreActionFileUpload1: [
        { name: "Delete", eventKey: "delete" },
        { name: "Download", eventKey: "download" },
      ],
      isStatusloder: false,
      changeValue: false,
      pathname: "",
      isRefresh: false,
      bdrValue: false,
      locationId: 0,
      inTime: this.props.parentRowData.time
        ? schConversions.tweleveToTwentyFourConvert(
            this.props.parentRowData.time
          ) || ""
        : "",
      assetId: 0,
      priorityId: "",
      name: "",
      assignedId: 0,
      description: "",
      errors: false,
      inputPosition: 0,
      htmlcomment: [],
      descritionBox: "",
      tempLocationSlideOutStatus: this.props.parentRowData?.status || "",
      deleteFile: "No",
    };

    this.onDrop = this.onDrop.bind(this);
  }

  static defaultProps = {
    locationDisable: false,
    assetDisable: false,
  };

  componentDidMount() {
    this.setState({
      createEditPermission: this.props.createEditPermission,
      description: this.state.parentRowData?.description,
      name: this.props.parentRowData?.taskName,
      locationId: this.props.parentRowData.roomId,
      assignedId: this.props.parentRowData.roleId,
      inTime: this.props.parentRowData.time
        ? schConversions.tweleveToTwentyFourConvert(
            this.props.parentRowData.time
          ) || ""
        : "",
    });
    this.GetUploadFileList();
    this.disccardChanges();
    this.slideoutRef.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.beforeunload.bind(this));
  }

  beforeunload(e: { preventDefault: () => void; returnValue: boolean }) {
    if (
      this.state.changeValue &&
      routeLeavingGuard !== "routeLeavingGuardTrue"
    ) {
      e.preventDefault();
      e.returnValue = true;
    }
  }

  closeSlideOut = () => {
    this.props.handleHideSlideout(true);
  };

  onFieldChange = (event, inputPosition) => {
    let value = event.target.value;
    const fieldName = event.target.name;

    if (fieldName === "name") {
      value = value?.toString()?.trimLeft();
    }

    this.setState({ [fieldName]: value, changeValue: true }, () =>
      this.isChangedData()
    );
  };

  onDrop = (filelist: any, mode: any) => {
    let size = this.state.fileSize; //5242880//5MB
    let newfiles: any = [];
    let files: any = [];
    let isReturn = true;
    let filestext = "";
    let filesizeval = Number(Number(this.state.fileSize) / (1024 * 1024));
    filelist.forEach((element) => {
      if (element.size > size) {
        filestext = filestext + element.name + ",";
        isReturn = false;
      }
    });

    if (!isReturn) {
      let msg = "One or more files are greater than " + filesizeval + "MB.";
      Utils.toastError(msg, {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "InvoiceSlideout",
      });
    }
    let isInvalidFile = false;
    filelist.forEach((element) => {
      if (
        (element.name
          .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
          .toLowerCase() === "pdf" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "doc" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "docx" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "xls" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "xlsx" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "csv" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "txt" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "bmp" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "tif" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "ppt" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "pptx" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "rtf" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "jpg" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "jpeg" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "png" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "msg" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "eml") &&
        element.size <= size
      ) {
        newfiles.push(element);
        files.push(element);
      } else {
        isInvalidFile = true;
      }
    });
    if (isInvalidFile && isReturn) {
      let msg = "";
      if (filelist.length === 1) {
        msg = "This file type is not supported.";
      } else {
        msg = "Some files are not supported.";
      }
      Utils.toastError(msg, {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "InvoiceSlideout",
      });
    }

    let newfileData = [...this.state.newSelectfiles];
    for (let i = 0; i < newfiles.length; i++) {
      newfiles[i].etype = "newfile";
      newfileData.push(newfiles[i]);
    }

    this.setState({ files, newSelectfiles: newfileData }, () => {
      //debugger;

      if (mode === 2) {
        //this.isHandleAction();
      } else {
        if (files.length > 0) {
          this.handleSaveFiles();
        }
      }
    });
  };

  handleSaveFiles = () => {
    this.setState({ isSpinner: true });
    let request: ISaveDailyTaskFileRequest = {
      hotelID: Number(this.state.parentRowData?.hid),
      TaskTemplateId: this.state.parentRowData?.taskTemplateId,
      Time: this.state.inTime
        ? schConversions.twentyFourToTwelveConvert(this.state.inTime)
        : "",
      RoomId:
        this.state.locationId == 0
          ? Number(this.state.parentRowData.roomId)
          : Number(this.state.locationId) || 0,

      RoleId:
        this.state.assignedId == 0
          ? Number(this.state.parentRowData.roleId)
          : Number(this.state.assignedId) || 0,

      Description: this.state.description?.trim() || "",
    };

    let newfileData = [...this.state.newSelectfiles];

    FacilityDailyTask.SaveDailyTaskFile(request, newfileData)
      .then(async (result: any | null) => {
        console.log({ result });
        if (result !== null) {
          if (result.success) {
            this.GetUploadFileList();
            this.setState({ isSpinner: false });
            toast.success("Files saved  successfully.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "InvoiceSlideout",
            });
          } else {
            this.setState({ isSpinner: false });
            Utils.toastError(result.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "InvoiceSlideout",
            });
          }
        }

        resolve();
      })
      .catch((error) => {
        this.setState({ changeValue: false, isSpinner: false });
        reject();
      });
  };

  handleSave = () => {
    let request: IDailyTaskSaveRequest = {
      Hid: Number(this.state.parentRowData?.hid),
      TaskName: this.state.name,
      TaskTemplateId: this.state.parentRowData?.taskTemplateId,
      Time: this.state.inTime
        ? schConversions.twentyFourToTwelveConvert(this.state.inTime)
        : "",
      RoomId:
        this.state.locationId == 0
          ? Number(this.state.parentRowData.roomId)
          : Number(this.state.locationId) || 0,

      RoleId:
        this.state.assignedId == 0
          ? Number(this.state.parentRowData.roleId)
          : Number(this.state.assignedId) || 0,

      Description: this.state.description?.trim() || "",
    };

    if (!this.state.name) {
      let errorMessage = "Please enter Daily Task Name";
      Utils.toastError(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "InvoiceSlideout",
      });

      return;
    }
    this.btnSave.current.disabled = true;
    // let newfileData = [...this.state.newSelectfiles];
    let newfileData: any = [];

    FacilityDailyTask.SaveDailyTask(request)
      .then(async (result: any | null) => {
        if (result !== null) {
          if (result.success) {
            toast.success("Daily Task Added successfully.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "InvoiceSlideout",
            });

            this.btnSave.current.disabled = false;
            this.setState({ changeValue: false });

            // this.props.handleHideSlideout(true);
            // this.GetUploadFileList();
          } else {
            Utils.toastError(result.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "InvoiceSlideout",
            });
            this.setState({ isDisabled: false });
            this.btnSave.current.disabled = false;
          }
        }

        resolve();
      })
      .catch((error) => {
        Utils.toastError(error?.response?.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "InvoiceSlideout",
        });
        this.btnSave.current.disabled = false;
        this.setState({ bdrValue: true });

        reject();
      });
  };

  handleClick = () => {
    this.setState({ bdrValue: true });
  };

  handleBlurAutoSize = () => {
    this.setState(
      { bdrValue: false, name: this.state.name.trimRight() },
      () => {
        this.isChangedData();
      }
    );
  };

  handleSelectFileUpload = (eventKey: any, file, index) => {
    if (eventKey === "delete") {
      this.setState({ deleteFile: "Yes" });
      let request: any = {};
      // request = Object.assign({}, JSON.parse(JSON.stringify(this.state.formData)));
      let masg = "";
      masg = "Are you sure you want to delete this Attachment?";
      confirmAlert({
        title: "Delete Attachment",
        message: masg,
        buttons: [
          {
            label: "Yes",
            onClick: () => this.isDeleteFile(file),
          },
          {
            label: "No",
            onClick: () => reject(),
          },
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    } else if (eventKey === "download") {
      this.DownloadDailyTaskUploadFile(file);
    } else if (eventKey === "TempDelete") {
      let masg = "";
      masg = "Are you sure you want to delete this Attachment?";
      confirmAlert({
        title: "Delete Attachment",
        message: masg,
        buttons: [
          {
            label: "Yes",
            onClick: () => this.isTempDeleteDailyTaskFile(file),
          },
          {
            label: "No",
            onClick: () => reject(),
          },
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    }
  };

  DownloadDailyTaskUploadFile = (file: any) => {
    FacilityDailyTask.DownloadDailyTaskUploadFile(file)
      .then(async (result: any | null) => {
        // toast.success(`${file.filename} downloaded successfully.`, {
        //   position: toast.POSITION.BOTTOM_RIGHT,
        // });
        resolve();
      })
      .catch((error) => {
        reject();
      });
  };

  isDeleteFile = (file: any) => {
    let deleteRequest: any = {};
    deleteRequest.hotelID = Number(this.state.parentRowData?.hid);

    deleteRequest.filenameUniqno = file.fileUniqueNo;

    FacilityDailyTask.DeleteDailyTaskFiles(deleteRequest)
      .then(async (result: any | null) => {
        if (result.saveStatus === "Success") {
          toast.success(result.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        this.GetUploadFileList();
        resolve();
      })
      .catch((error) => {
        reject();
      });
  };
  isTempDeleteDailyTaskFile = (file: any) => {
    let data = [...this.state.newSelectfiles];
    let index = data.findIndex((item) => item.etype === "newfile");
    data.splice(index, 1);
    this.setState({ newSelectfiles: data });
  };

  onFileChange = (event: any, mode) => {
    let newfiles: any = [];
    if (event.target.files.length > 0) {
      for (let i = 0; i < event.target.files.length; i++) {
        let fileRow = event.target.files[i];

        newfiles.push(fileRow);
      }
      this.onDrop(newfiles, mode);
      let fileList = document.getElementById("formControlsFile") as any;
      fileList.value = "";
    }
  };

  GetUploadFileList = () => {
    let request: ISaveDailyTaskFileRequest = {
      hotelID: Number(this.state.parentRowData?.hid),
      TaskTemplateId: this.state.parentRowData?.taskTemplateId,
      Time: this.state.inTime
        ? schConversions.twentyFourToTwelveConvert(this.state.inTime)
        : "",
      RoomId:
        this.state.locationId == 0
          ? Number(this.state.parentRowData.roomId)
          : Number(this.state.locationId) || 0,

      RoleId:
        this.state.assignedId == 0
          ? Number(this.state.parentRowData.roleId)
          : Number(this.state.assignedId) || 0,

      Description: this.state.description?.trim() || "",
    };

    FacilityDailyTask.GetDailyTaskUploadfile(request)
      .then(async (result: any | null) => {
        if (result !== null) {
          for (let i = 0; i < result.length; i++) {
            this["myRef" + i] = React.createRef();
          }
          this.setState({ savedfiles: [] }, () => {
           //debugger;
            this.setState({
              savedfiles: result,
              files: [],
              newSelectfiles: [],
              isSpinner: false,
            });
          });
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  };

  hideApprovalModal = (value = "click") => {
    if (value == "outSideClick") {
      return;
    }

    if (this.state.changeValue) {
      this.isValidateModelPoupTab();
    } else {
      this.closeSlideOut();
    }
  };

  routeLeavingGuardConfirm = (childState: any) => {
    if (childState === true) {
      routeLeavingGuard = "routeLeavingGuardTrue";
    } else {
      routeLeavingGuard = null;
    }
  };

  isValidateModelPoupTab() {
    confirmAlert({
      title: "Unsaved confirmation",
      message:
        "You have unsaved changes on this page. Do you want to leave this page and discard your changes?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.isChangeUnsaved(),
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }

  isChangeUnsaved = () => {
    this.setState({ changeValue: false }, () => {
      this.disccardChanges(true);
    });
  };

  disccardChanges = (isFromModal = false) => {
    this.setState(
      {
        isRefresh: true,
        changeValue: false,
        description: this.state.parentRowData?.description,
        name: this.props.parentRowData?.taskName,
        inTime: this.props.parentRowData.time
          ? schConversions.tweleveToTwentyFourConvert(
              this.props.parentRowData.time
            ) || ""
          : "",
      },
      () => {
        this.setState({ isRefresh: false });
        // let data = [...this.state.newSelectfiles]
        // let filterData = data.filter((item) => item.etype === "newfile");
        // for (let i = 0; i < filterData.length; i++) {

        //     let index = data.indexOf(filterData[i]);
        //     data.splice(index, 1);
        // }

        // this.setState({ newSelectfiles: data });
      }
    );

    if (isFromModal) {
      this.closeSlideOut();
    }
    // this.props.changesOfSlideOut(false);
    // this.setState({ changeValue: false },()=>{
    // this.props.changesOfSlideOut(false);
    //  });
  };

  descriptionChange = (e: any) => {
    let value = e.target.value;

    let inputValue =
      typeof value === "string" ? value?.toString()?.trimLeft() : value;

    this.setState({ changeValue: true, description: inputValue }, () =>
      this.isChangedData()
    );

    // e.target.style.height = 'auto';
    // e.target.style.height = e.target.scrollHeight + 'px';
  };

  inTimeChange = (e: any) => {
    this.setState(
      {
        inTime: e.target.value,
        changeValue: true,
      },
      () => this.isChangedData()
    );
  };

  inTimeBlur = (e: any) => {
    this.setState({
      inTime: e.target.value,
    });
  };

  isChangedData = () => {
    const previousData = {
      TaskName: this.props.parentRowData.taskName,
      Time: this.props.parentRowData.time
        ? schConversions.tweleveToTwentyFourConvert(
            this.props.parentRowData.time
          ) || ""
        : "",
      RoomId: Number(this.props.parentRowData.roomId),
      RoleId: Number(this.props.parentRowData.roleId),
      Description: this.props.parentRowData.description?.trim() || "",
    };

    const newData = {
      TaskName: this.state.name,
      Time: this.state.inTime,
      RoomId: Number(this.state.locationId),
      RoleId: Number(this.state.assignedId),
      Description: this.state.description?.trim() || "",
    };

    const isChanged = !_.isEqual(previousData, newData);

    this.setState({ changeValue: isChanged });
  };

  updatedCommentItem = () => {
    let updatedItem: any = {};
    this.setState({ isSave: true });
    updatedItem.transcationDate = new Date();
    updatedItem.vendorName = "";
    updatedItem.amountTotal = 0;
    return updatedItem;
  };

  descriptionBox = () => {};

  render() {
    const files1 = this.state.savedfiles.map((file, index) => (
      <>
        <li key={file.filename}>
          <div className="d-flex align-items-center justify-content-start">
            <div className="icon">
              {file?.type?.split("/")[0] === "image" && (
                <FontAwesomeIcon icon={faFileImage} />
              )}
              {file?.filename.substring(
                file?.filename.lastIndexOf(".") + 1,
                file?.filename.length
              ) === "pdf" && <FontAwesomeIcon icon={faFilePdf} />}
              {file?.filename.substring(
                file?.filename.lastIndexOf(".") + 1,
                file?.filename.length
              ) === "xlsx" && <FontAwesomeIcon icon={faFileExcel} />}
              {file?.filename.substring(
                file?.filename.lastIndexOf(".") + 1,
                file?.filename.length
              ) === "csv" && <FontAwesomeIcon icon={faFileCsv} />}
              {file?.filename.substring(
                file?.filename.lastIndexOf(".") + 1,
                file?.filename.length
              ) === "word" && <FontAwesomeIcon icon={faFileWord} />}
              {file?.type?.split("/")[0] !== "image" &&
                file?.filename.substring(
                  file?.filename.lastIndexOf(".") + 1,
                  file?.filename.length
                ) !== "pdf" &&
                file?.filename.substring(
                  file?.filename.lastIndexOf(".") + 1,
                  file?.filename.length
                ) !== "xlsx" &&
                file?.filename.substring(
                  file?.filename.lastIndexOf(".") + 1,
                  file?.filename.length
                ) !== "csv" &&
                file?.filename.substring(
                  file?.filename.lastIndexOf(".") + 1,
                  file?.filename.length
                ) !== "word" && <FontAwesomeIcon icon={faFile} />}
            </div>
            <div className="file-info d-flex align-items-center">
              <div className="name-loader d-flex align-items-center">
                <div className="name">
                  <EllipsisWithTooltip placement="bottom">
                    <div className="full-name">{file.filename}</div>
                  </EllipsisWithTooltip>
                  {/* <div className="details">{file.createDate} - {file.pageno} Page</div> */}
                </div>
              </div>
              <div className="formate-percentage">
                <span className="formate d-flex justify-content-center align-items-center">
                  {file?.filename.substring(
                    file?.filename.lastIndexOf(".") + 1,
                    file?.filename.length
                  )}
                </span>
              </div>
            </div>
            <div className="action">
              {/* {!this.state.bulkSelect && ( */}
              <Dropdown
                className="more-action"
                alignRight
                onSelect={(event: any) =>
                  this.handleSelectFileUpload(event, file, index)
                }
              >
                <Dropdown.Toggle
                  className="btn-outline-primary btn btn-primary more"
                  id="dropdown-more"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {this.state.moreActionFileUpload1.map(
                    (item: any, idx: any) => (
                      <>
                        <Dropdown.Item
                          // className={}

                          eventKey={
                            this.state.moreActionFileUpload1[idx].eventKey
                          }
                          key={idx}
                        >
                          {this.state.moreActionFileUpload1[idx].name}
                        </Dropdown.Item>
                      </>
                    )
                  )}
                </Dropdown.Menu>
              </Dropdown>
              {/* )} */}
            </div>
          </div>
        </li>
      </>
    ));

    const files = this.state.newSelectfiles.map((file, index) => (
      <>
        <li key={file.name}>
          <div className="d-flex align-items-center justify-content-start">
            <div className="icon">
              {file?.type?.split("/")[0] === "image" && (
                <FontAwesomeIcon icon={faFileImage} />
              )}
              {file?.name.substring(
                file?.name.lastIndexOf(".") + 1,
                file?.name.length
              ) === "pdf" && <FontAwesomeIcon icon={faFilePdf} />}
              {file?.name.substring(
                file?.name.lastIndexOf(".") + 1,
                file?.name.length
              ) === "xlsx" && <FontAwesomeIcon icon={faFileExcel} />}
              {file?.name.substring(
                file?.name.lastIndexOf(".") + 1,
                file?.name.length
              ) === "csv" && <FontAwesomeIcon icon={faFileCsv} />}
              {file?.name.substring(
                file?.name.lastIndexOf(".") + 1,
                file?.name.length
              ) === "word" && <FontAwesomeIcon icon={faFileWord} />}
              {file?.type?.split("/")[0] !== "image" &&
                file?.name.substring(
                  file?.name.lastIndexOf(".") + 1,
                  file?.name.length
                ) !== "pdf" &&
                file?.name.substring(
                  file?.name.lastIndexOf(".") + 1,
                  file?.name.length
                ) !== "xlsx" &&
                file?.name.substring(
                  file?.name.lastIndexOf(".") + 1,
                  file?.name.length
                ) !== "csv" &&
                file?.name.substring(
                  file?.name.lastIndexOf(".") + 1,
                  file?.name.length
                ) !== "word" && <FontAwesomeIcon icon={faFile} />}
            </div>
            <div className="file-info d-flex align-items-center">
              <div className="name-loader d-flex align-items-center">
                <div className="name">
                  <EllipsisWithTooltip placement="bottom">
                    {/* <div className="full-name" onClick={() => { this.handlePreview(file) }}>{file.name}</div> */}
                    <div className="full-name">{file.name}</div>
                  </EllipsisWithTooltip>
                </div>
              </div>
              <div className="formate-percentage">
                <span className="formate d-flex justify-content-center align-items-center">
                  {file?.name.substring(
                    file?.name.lastIndexOf(".") + 1,
                    file?.name.length
                  )}
                </span>
              </div>
            </div>
            <div className="action">
              {/* <button className="btn-outline-primary remove-pdf more btn btn-primary" onClick={() => { this.handleSelectFileUpload("TempDelete", file, 0) }} >
                                <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"></path></svg>
                               
                            </button> */}

              {this.state.isSpinner && (
                <button className="btn-outline-primary remove-pdf more btn btn-primary">
                  <Spinner
                    className="mr-2"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </button>
              )}
            </div>
          </div>
        </li>
      </>
    ));

    const attachmentheader = (column: any, colIndex: any) => {
      return (
        <div className="paperClipBtn">
          <svg
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 448 512"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M43.246 466.142c-58.43-60.289-57.341-157.511 1.386-217.581L254.392 34c44.316-45.332 116.351-45.336 160.671 0 43.89 44.894 43.943 117.329 0 162.276L232.214 383.128c-29.855 30.537-78.633 30.111-107.982-.998-28.275-29.97-27.368-77.473 1.452-106.953l143.743-146.835c6.182-6.314 16.312-6.422 22.626-.241l22.861 22.379c6.315 6.182 6.422 16.312.241 22.626L171.427 319.927c-4.932 5.045-5.236 13.428-.648 18.292 4.372 4.634 11.245 4.711 15.688.165l182.849-186.851c19.613-20.062 19.613-52.725-.011-72.798-19.189-19.627-49.957-19.637-69.154 0L90.39 293.295c-34.763 35.56-35.299 93.12-1.191 128.313 34.01 35.093 88.985 35.137 123.058.286l172.06-175.999c6.177-6.319 16.307-6.433 22.626-.256l22.877 22.364c6.319 6.177 6.434 16.307.256 22.626l-172.06 175.998c-59.576 60.938-155.943 60.216-214.77-.485z"></path>
          </svg>
        </div>
      );
    };

    const { name, createEditPermission } = this.state;

    return (
      <>
        <OutsideClickHandler
          onOutsideClick={(e: any) => this.hideApprovalModal("outSideClick")}
        >
          <div className="daily-task-slideout pos-fxd index-1000">
            <div className="d-flex">
              <div
                className="back-drop"
                onClick={() => this.hideApprovalModal()}
              ></div>
              <div className="slideout-UI">
                <div className="wrapper">
                  <div className="slideout-header" ref={this.parentScroll}>
                    {createEditPermission ? (
                      <div className="pos-ttl mr-auto">
                        <OverlayTrigger
                          placement="bottom"
                          defaultShow={false}
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip
                              id={"Daily-Task-Name"}
                              className={
                                name?.length <= 75 ? "tootltip-hide" : ""
                              }
                            >
                              {name}
                            </Tooltip>
                          }
                        >
                          <AutosizeInput
                            ref={this.input}
                            className={`form-control removeBorder input-component ${
                              this.state.bdrValue ? "bdr-green" : ""
                            }`}
                            style={{ fontSize: 24 }}
                            injectStyles={false}
                            type="input"
                            value={name}
                            tabIndex={1}
                            onClick={() => this.handleClick()}
                            onChange={(e: any) => {
                              this.onFieldChange(
                                Utils.BuildCustomEventObject(
                                  "name",
                                  e.target.value
                                ),
                                1
                              );
                            }}
                            onBlur={() => this.handleBlurAutoSize()}
                            placeholder={"Enter task name"}
                            maxLength={100}
                          />
                        </OverlayTrigger>
                      </div>
                    ) : (
                      <h3 className="heading mr-auto">
                        <EllipsisWithTooltip placement="bottom">
                          {name}
                        </EllipsisWithTooltip>
                      </h3>
                    )}

                    <Dropdown className="more-action ml-3">
                      <DropdownToggle
                        className="btn-outline-primary btn btn-primary more"
                        id="dropdown-more"
                        disabled={!createEditPermission}
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                          <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                          <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                        </svg>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem></DropdownItem>
                      </DropdownMenu>
                    </Dropdown>

                    <Button onClick={() => this.hideApprovalModal()}>
                      <FontAwesomeIcon icon={faClose} />
                    </Button>
                  </div>

                  <div
                    className={`slideout-body ${
                      this.state.changeValue && "bottom-spacer"
                    } ${
                      this.state.parentRowData?.dailyTaskPermission === "No"
                        ? "controls-disabled"
                        : ""
                    }`}
                    ref={this.slideoutRef}
                  >
                    <div className="order-info">
                      <Form.Group
                        controlId="Repeats"
                        className="Repeats-dropdown"
                      >
                        <Form.Label>Repeats</Form.Label>

                        <SingleSearchDropdownList
                          isdisable={true}
                          itemList={RepeatList}
                          handleSelectedItem={(item) =>
                            this.onFieldChange(
                              Utils.BuildCustomEventObject(
                                "Repeats",
                                item.name
                              ),
                              1
                            )
                          }
                          defaultItem={RepeatList}
                          defaultText={"Daily"}
                          defaultName={RepeatList[0].name}
                          controlID="1"
                        />
                      </Form.Group>

                      <Form.Group
                        controlId="Repeats"
                        className="Repeats-dropdown"
                      >
                        <Form.Label>Time</Form.Label>

                        <input
                          type="time"
                          id="inTime"
                          name="inTime"
                          className={
                            "html-time-picker" +
                            (this.state.dateSelected === "Select"
                              ? " disabled"
                              : "")
                          }
                          value={this.state.inTime}
                          onChange={(e: any) => this.inTimeChange(e)}
                          onBlur={(e: any) => this.inTimeBlur(e)}
                          disabled={!createEditPermission}
                        />
                      </Form.Group>

                      <Form.Group controlId="assignedTo">
                        <Form.Label>Assigned To</Form.Label>

                        {this.props.userRoles.length > 0 && (
                          <SingleSearchDropdownList
                            isdisable={!createEditPermission}
                            isRefresh={this.state.isRefresh}
                            itemList={this.props.userRoles}
                            handleSelectedItem={(item) =>
                              this.onFieldChange(
                                Utils.BuildCustomEventObject(
                                  "assignedId",
                                  item
                                ),
                                3
                              )
                            }
                            defaultItem={this.props.userRoles}
                            defaultText={"Search"}
                            // defaultName={this.state.assignToList.filter((item) => item.id === this.state.parentRowData.user_UniqueID)[0]?.name}
                            defaultName={
                              this.props.userRoles.filter(
                                (item) =>
                                  item.id === this.state.parentRowData?.roleId
                              )[0]?.name || "Select a Role"
                            }
                            controlID="1"
                          />
                        )}
                      </Form.Group>

                      <Form.Group
                        controlId="location"
                        className="location-dropdown"
                      >
                        <Form.Label>Location</Form.Label>
                      
                          <SingleSearchDropdownList
                            isdisable={!createEditPermission}
                            isRefresh={this.state.isRefresh}
                            itemList={this.props.locationList}
                            handleSelectedItem={(item) =>
                              this.onFieldChange(
                                Utils.BuildCustomEventObject(
                                  "locationId",
                                  item
                                ),
                                3
                              )
                            }
                            defaultItem={this.props.locationList}
                            defaultText={"Select a Location"}
                            defaultName={
                              this.props.locationList.filter(
                                (item) =>
                                  item.id === this.state.parentRowData?.roomId
                              )[0]?.name || "Select a Location"
                            }
                            controlID="1"
                          />
                      
                      </Form.Group>
                    </div>
                    <div className="py-4">
                      <div className="description-box">
                        <h5 className="label">Description</h5>
                        <Form.Group controlId="description" className="d-flex">
                          <Col sm={"12"} className={`mb-0 p-0`}>
                            <Form.Control
                              disabled={!createEditPermission}
                              as="textarea"
                              type="textarea"
                              className="form-control"
                              maxLength={200}
                              id="description"
                              name="description"
                              autoComplete="description"
                              value={this.state.description}
                              placeholder="Give as much detail as possible about the Daily Task, Location and anything else relevant"
                              onChange={(e) => this.descriptionChange(e)}
                              onBlur={() => {
                                this.setState({
                                  description:
                                    this.state.description?.trimRight(),
                                });
                              }}
                              // onBlur={(e) => this.descriptionChange(e)}
                            />
                          </Col>
                        </Form.Group>
                      </div>
                    </div>

                    <div
                      className={`filter-sec upload-section ${
                        createEditPermission == false ? "disabled" : ""
                      }`}
                    >
                      <Form.Group controlId="exampleForm.ControlTextarea1">
                        <div className="d-flex">
                          <Form.Label className="mr-auto label">
                            Attachments
                          </Form.Label>
                          {this.state.savedfiles.length > 0 && (
                            <div className="upload-link">
                              {/* <button type="button" className="btn wht-bg link-btn mr-auto" onClick={this.OpenViewer}>
                                                                    {this.state.isOpenViewer ? 'Close Viewer' : 'Open Viewer'}
                                                                </button>  */}
                            </div>
                          )}
                        </div>
                        <div
                          className={files1.length >= 1 ? "remove-border" : ""}
                        >
                          <Dropzone
                            disabled={!createEditPermission}
                            onDrop={this.onDrop}
                            // accept="image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.txt,.bmp,.tif,.ppt,.pptx,.rtf,.jpg,.png,.msg,.eml"
                          >
                            {({
                              getRootProps,
                              getInputProps,
                              isDragActive,
                              isDragReject,
                            }) => (
                              <>
                                <section
                                  className={
                                    isDragActive
                                      ? "container upload-area default drag-active "
                                      : "container upload-area default"
                                  }
                                >
                                  {!this.state.bulkSelect && (
                                    <div
                                      {...getRootProps({
                                        className:
                                          "dropzone d-flex align-items-center",
                                        onClick:
                                          this.state.newSelectfiles.length >
                                            0 ||
                                          this.state.savedfiles.length > 0
                                            ? (event) => event.stopPropagation()
                                            : (event) => event,
                                      })}
                                    >
                                      <input {...getInputProps()} />
                                      {this.state.newSelectfiles.length < 1 &&
                                        this.state.savedfiles.length < 1 && (
                                          <p>
                                            Drag and drop files or{" "}
                                            <span>browse files</span> to upload.
                                          </p>
                                        )}
                                      {(this.state.newSelectfiles.length > 0 ||
                                        this.state.savedfiles.length > 0) &&
                                        isDragActive && (
                                          <p>
                                            Drag and drop files here to upload.
                                          </p>
                                        )}
                                      {/* {!isDragActive && (<p>Drag and drop or <span>browse files</span> to upload</p>)} */}
                                    </div>
                                  )}

                                  {/* {this.state.bulkSelect && ( 
                                                                            <div className="dropzone d-flex align-items-center">
                                                                                <p>Drag and drop or <span>browse files</span> to upload</p>
                                                                            </div>
                                                                      )}   */}
                                  {(this.state.newSelectfiles.length > 0 ||
                                    this.state.savedfiles.length > 0) && (
                                    <aside className="d-flex flex-column">
                                      <ul className="p-0">{files1}</ul>
                                      <ul className="p-0">{files}</ul>
                                    </aside>
                                  )}
                                </section>
                                {!this.state.bulkSelect &&
                                  (this.state.newSelectfiles.length > 0 ||
                                    this.state.savedfiles.length > 0) &&
                                  this.state.parentRowData
                                    ?.dailyTaskPermission === "Yes" && (
                                    <div className="upload-link mrgn-top">
                                      <button
                                        type="button"
                                        className="btn p-0 wht-bg link-btn mr-auto"
                                      >
                                        Add Attachment
                                      </button>
                                      <Form.Control
                                        id="formControlsFile"
                                        type="file"
                                        multiple
                                        onChange={(event: any) =>
                                          this.onFileChange(event, "1")
                                        }
                                        accept="image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.txt,.bmp,.tif,.ppt,.pptx,.rtf,.jpg,.png,.msg,.eml"
                                      />
                                    </div>
                                  )}
                              </>
                            )}
                          </Dropzone>
                        </div>
                      </Form.Group>
                    </div>
                  </div>

                  {this.state.parentRowData?.dailyTaskPermission === "Yes" && (
                    <div>
                      {this.state.changeValue && (
                        <div className="strip-wrapper">
                          <div className="fixed-action px-3 pb-2 h-auto">
                            <div className="d-flex align-items-center flex-wrap forecast-action-strip">
                              <div className="mr-auto message">
                                <span>
                                  You have made changes to this daily task. Do
                                  you want to save and continue ?{" "}
                                </span>
                              </div>

                              <button
                                type="button"
                                className="btn btn-primary border-0 btn-discard btn-changes-discard"
                                onClick={() => this.disccardChanges()}
                              >
                                Discard
                              </button>

                              <button
                                ref={this.btnSave}
                                type="button"
                                className="save-btn btn btn-primary mr-0"
                                onClick={this.handleSave}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      </>
    );
  }
}
