import React from "react";
import { Button, Form, Modal, Dropdown, Spinner } from "react-bootstrap";
import { User } from "../Services/User";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faSave } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { Utils } from "../Utilis";

export class ValidateUserModal extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      notPrimaryError: "",
      primarySelectedOption:
        this.props.userData.primaryContact === "Email"
          ? "Email address"
          : "Mobile number",
      primaryOption: ["Email address", "Mobile number"],
      primaryValue:
        this.props.userData.primaryContact === "Email" ? "email" : "number",
      email: this.props.userData.displayEmail,
      number: this.props.userData.displayPhone,
      hasSpecialPermission: this.props.userData.isSplPermission === "Yes",
      isPhoneBlocked: this.props.userData.isPhoneBlock === "Yes",
      isEmailBlocked: this.props.userData.isEmailBlock === "Yes",
      primaryContact: this.props.userData.primaryContact,
      emailDangerWall: false,
      numberDangerWall: false,
      emailError: "",
      numberError: "",
      noPhoneSelected: false,
      checkedRadioBtn: 0,
      phoneStatus: "",
      saveBtnSpinner: false,
      modalOpen : this.props.userData.modalOpen,
    };
  }

  componentDidMount(){
    const { isPhoneBlocked } = this.state;
    let phoneStatus = this.props.userData.phoneStatus;
    if(!isPhoneBlocked){
        this.setState({phoneStatus})
    }
  }

  modalClose = () => {
    let validateCloseFrom = '';
    let data:any = {};
    const {modalOpen} = this.state;
    const { userData } = this.props;
    if(modalOpen === 'managerLoginModal'){
      data.userName = userData.userName;
      data.user_UniqueID = userData.user_UniqueID;
      validateCloseFrom = "managerLoginModal";
    }else if( modalOpen === 'userPunchModal'){
      validateCloseFrom = "userPunchModal";
    }
    this.props.validateUserModalHide(validateCloseFrom,data);
  };

  setPrimaryContact = (e: any, item: any) => {
    e.preventDefault();
    this.setState({ primarySelectedOption: item });
    if (item === "Mobile number") {
      this.setState({ primaryValue: "number" });
    } else {
      this.setState({ primaryValue: "email" });
    }
    this.setState({
      emailError: "",
      emailDangerWall: false,
      numberError: "",
      numberDangerWall: false,
    });
  };

  onChange = (e: any, type: any) => {
    let value = e.target.value;
    if (type === "email") {
      this.setState({ email: value, emailError: "",  numberError: "", emailDangerWall: false, numberDangerWall: false });
    } else {
      if (/^\+/.test(value) === false) {
        value = value.replace(/^\+/, "%2B");
      }
      this.setState({
        number: value,
        numberError: "",
        emailError: "",
        numberDangerWall: false,
        emailDangerWall: false,
        notPrimaryError: "",
        phoneStatus: "",
        checkedRadioBtn: 0,
      });
    }
  };
  formValidation = () => {
    let {
      email,
      number,
      emailDangerWall,
      numberDangerWall,
      emailError,
      numberError,
      checkedRadioBtn,
      isPhoneBlocked,
      isEmailBlocked
    } = this.state;
    let isValidate: boolean = true;
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // const numberRegex = /^\d+$/;
    const numberRegex =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

    if (isEmailBlocked) {
      if (!emailRegex.test(email.toLowerCase())) {
        emailDangerWall = true;
        emailError = "Please enter valid Email Address";
        isValidate = false;
      }
      if (email === "") {
        emailDangerWall = true;
        emailError = "Please enter Email Address";
        isValidate = false;
      }
    } 
      if (isPhoneBlocked) {
        if (checkedRadioBtn === 0) {
          if (!numberRegex.test(number)) {
            numberDangerWall = true;
            numberError = "Please enter valid Mobile Number";
            isValidate = false;
          }
          if (number.length < 7) {
            numberDangerWall = true;
            numberError = "Please enter valid Mobile Number";
            isValidate = false;
          }
          if (number === "") {
            numberDangerWall = true;
            numberError = "Please enter Mobile Number";
            isValidate = false;
          }
        }
      }

      this.setState({
      emailDangerWall,
      numberDangerWall,
      emailError,
      numberError,
    });
    return isValidate;
  };
  submitForm = (e: any) => {
    this.setState({ saveBtnSpinner: true });
    let { number } = this.state;
    e.preventDefault();
    let isValidate = this.formValidation();
    if (!isValidate) {
      this.setState({ saveBtnSpinner: false });
      return false;
    } else {
      if (/^\+/.test(number) === true) {
        number = number.replace(/^\+/, "%2B");
      }
      let timeClockTemp = JSON.parse(localStorage.getItem("timeClockTemp")!);
      let tenantID =
        timeClockTemp === null ? 0 : (timeClockTemp.tenantID as any);
      User.UpdateUserValidateStatus(
        this.props.userData.userName,
        this.props.userData.password,
        tenantID,
        this.state.email,
        number,
        this.state.primaryValue === "email" ? "1" : "0",
        this.state.phoneStatus,
        this.props.parnetComponent
      )
        .then(async (userResTo: any | null) => {
          let apiResult = userResTo.result;
          if (apiResult.rVal === "0") {
            if(apiResult.saveStatus.includes("phone")){
              this.setState({
                numberDangerWall:true,
                notPrimaryError: `${apiResult.saveStatus}`,
                saveBtnSpinner: false,
              });
            } else if (apiResult.saveStatus.includes("email")){
              this.setState({
                emailDangerWall:true,
                notPrimaryError: `${apiResult.saveStatus}`,
                saveBtnSpinner: false,
              });
            } else {
              this.setState({
                notPrimaryError: `${apiResult.saveStatus}`,
                saveBtnSpinner: false,
              });
            }
          } else {
            this.modalClose();
          }
        })
        .catch((err) => {
          Utils.toastError(`Server Error: ${err}`);
          this.setState({ saveBtnSpinner: false });
        });
    }
  };

  render() {
    return (
      <div className="validate-user-modal">
        <Modal
          show={this.props.modalShow}
          onHide={this.modalClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="ifcusModal"
          keyboard={false}
          backdrop="static"
        >
          {/* <Modal.Header >
            <Modal.Title >
              Uh oh, it looks like there was a problem with your contact information.
              {/* Please provide the primary contact details to continue 
            </Modal.Title>
          </Modal.Header> */}
          <Modal.Body style={{ minHeight: "225px", height: "auto" }}>
            {this.state.notPrimaryError !== "" ? (
              <p className="text-danger text-center my-2">
                <strong>{this.state.notPrimaryError}</strong>
              </p>
            ) : null}
            <div className="hasPadding">
              {/* <div className="frm-section was-validated">
                {/* <Form.Group >
                  <Form.Label>Primary Contact</Form.Label>

                  <Form.Control
                  readOnly
                    // className={
                    //   this.state.numberDangerWall
                    //     ? `form-control alert-danger ${this.state.primaryValue}`
                    //     : `form-control valid ${this.state.primaryValue}`
                    // }
                    // required={this.state.primaryValue === "number"}
                    type="text"
                    name="primaryContact"
                    value={this.state.primarySelectedOption}
                    // onChange={(e) => this.onChange(e, "number")}
                    // placeholder="Enter Mobile Number"
                    // maxLength={10}
                  />
                  {/* <Dropdown >
                    <Dropdown.Toggle id="dropdown-importFrom">
                      {this.state.primarySelectedOption}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div>
                        {this.state.primaryOption.map((item: any, idx: any) => (
                          <Dropdown.Item
                            key={idx}
                            eventKey={item.id}
                            onClick={(e: any) =>
                              this.setPrimaryContact(e, item)
                            }
                          >
                            {item}
                          </Dropdown.Item>
                        ))}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown> 
                </Form.Group> 
              </div> */}
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  marginBottom: "1em",
                }}
              >
                Uh oh, it looks like there was a problem with your contact
                information.
              </div>
              <Form className="frm-section was-validated">
                {this.state.isEmailBlocked && (
                  <Form.Group>
                    <Form.Label>
                      {this.state.isEmailBlocked ? "Invalid " : "Enter "} Email{" "}
                      <em className="text-danger">Required</em>
                      {/* <em
                      className={
                        this.state.primaryValue === "email" ? "text-danger" : ""
                      }
                    >
                      {this.state.primaryValue === "email"
                        ? "Required"
                        : "optional"}
                    </em> */}
                    </Form.Label>
                    <Form.Control
                      className={
                        this.state.emailDangerWall
                          ? `form-control alert-danger ${this.state.primaryValue}`
                          : `form-control valid ${this.state.primaryValue}`
                      }
                      // required //={this.state.primaryValue === "email"}
                      type="text"
                      name="email"
                      value={this.state.email}
                      onChange={(e) => this.onChange(e, "email")}
                      placeholder="Enter Email Address"
                    />
                    {/* {this.state.emailError !== "" && ()} */}
                    <span className="invalid-feedback">
                      {this.state.emailError}
                    </span>
                  </Form.Group>
                )}
                {this.state.isPhoneBlocked && (
                  <Form.Group>
                    <Form.Label>
                      {this.state.isPhoneBlocked ? "Invalid " : "Enter "}Mobile Phone Number{" "}
                      <em
                        className={
                          this.state.primaryContact === "Phone" || this.state.hasSpecialPermission
                            ? "text-danger"
                            : ""
                        }
                      >
                        {this.state.primaryContact === "Phone" || this.state.hasSpecialPermission
                          ? "Required"
                          : ""}
                      </em>
                    </Form.Label>
                    <Form.Control
                      className={
                        this.state.numberDangerWall
                          ? `form-control alert-danger ${this.state.primaryValue}`
                          : `form-control valid ${this.state.primaryValue}`
                      }
                      // required={this.state.primaryValue === "number"}
                      type="text"
                      name="mobile"
                      value={this.state.number}
                      onChange={(e) => this.onChange(e, "number")}
                      placeholder="Enter Mobile Number"
                      // maxLength={10}
                    />

                    {this.state.numberError !== "" ? (
                      <span className="invalid-feedback">
                        {this.state.numberError}
                      </span>
                    ) : (
                      <span
                        className="invalid-feedback"
                        style={{ color: "black" }}
                      >
                        {"Number must support text messages"}
                      </span>
                    )}
                  </Form.Group>
                )}
                {this.state.primaryValue !== "number" &&
                  this.state.isPhoneBlocked &&
                  !this.state.hasSpecialPermission && (
                    <Form.Group style={{ display: "flex" }}>
                      <Form.Check
                        type="radio"
                        as="input"
                        id="phoneNo"
                        // label="No Phone"
                        // onChange={e=>}
                        checked={this.state.checkedRadioBtn === 1}
                        onChange={(e) =>
                          this.setState({
                            checkedRadioBtn: 1,
                            phoneStatus: "NoPhone",
                            numberError: "",
                            number: "",
                            numberDangerWall: false,
                            notPrimaryError: "",
                          })
                        }
                        // className={
                        //   this.state.errorMessage ? "alert-danger" : "valid"
                        // }
                      />
                      <Form.Label
                        htmlFor="phoneNo"
                        style={{ marginRight: "14px", marginTop: "1px" }}
                      >
                        No Phone
                      </Form.Label>

                      <Form.Check
                        type="radio"
                        id="updateLater"
                        // label="Update Later"
                        checked={this.state.checkedRadioBtn === 2}
                        onChange={(e) =>
                          this.setState({
                            checkedRadioBtn: 2,
                            phoneStatus: "UpdateLater",
                            numberError: "",
                            number: "",
                            numberDangerWall: false,
                            notPrimaryError: "",
                          })
                        }
                        // className={
                        //   this.state.errorMessage ? "alert-danger" : "valid"
                        // }
                      />
                      <Form.Label
                        htmlFor="updateLater"
                        style={{ marginRight: "14px", marginTop: "1px" }}
                      >
                        Update Later
                      </Form.Label>
                    </Form.Group>
                  )}
                <Form.Group
                  className="text-center"
                  style={{ marginBottom: "0px" }}
                >
                  <Button
                    type="submit"
                    title="Update"
                    onClick={(e: any) => this.submitForm(e)}
                    variant="primary"
                  >
                    {this.state.saveBtnSpinner && (
                      <Spinner
                        className="mr-2"
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    {/* <FontAwesomeIcon icon={faSave} />  */}
                    Save
                  </Button>
                  &nbsp;
                  {/* <Button
                    type="reset"
                    onClick={() => this.modalClose()}
                    variant="danger"
                    title="Close"
                  >
                    <FontAwesomeIcon icon={faTimes} /> Close
                  </Button> */}
                </Form.Group>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
