import React from "react";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ChangeLogs } from "../../../Common/Components/ChangeLogs/ChangeLogs";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import {
    ICurrentFilter,
    ILogHistoryProps,
    ILogHistoryState,
} from "../../../Common/Contracts/IScheduleLogHistory";

import { LaborSetting } from "../../../Common/Services/LaborSetting";
//import { DeductionCriteria } from "../../../Common/Services/DeductionCriteria";
import { IPayrollProcessorLogRequest,IPayrollProcessorLogChangeLog } from "../../../Common/Contracts/IPayrollProcessorLog";
import { LaborSchedule } from "../../../Common/Services/LaborSchedule";
import { Utils } from "../../../Common/Utilis";
export class PayrollProcessorLogHistory extends React.Component<
    ILogHistoryProps,
    ILogHistoryState
> {
    private pageName: string = "TransactionLogHistory";

    private tranTypes: any[] = [{
        label: 'Deposit', value: 'Deposit'
    },
    { label: 'Transfer', value: 'Transfer' },
    {
        label: 'Withdrawal', value: 'Withdrawal'
    }]

    state = {
        tableData: [],
        filteredData: [],
        tableColumns: [],
        title:  "Payroll Processor History",
        keyFieldName: "rowNumber",
        isDataLoading: false,
        scheduleId: 0,
        isDrillDownView: false,
        drillDownLevel: +this.props.shiftId > 0 ? 1 : 0,
        filterColumns: [],
        filterPopUps: [],
        filterPopUpsCopy: [],
        showFilterPopUp: false,
        popClassName: "",
        currentFilter: {
            filterName: "",
            filterValue: "",
            filterKeyName: "",
            filterInputType: "",
            dropDownProps: {},
        },
        tableClassName: "payroll-deduction-log-table",
        startDate: this.props.payPeriodStartDate,
        endDate: this.props.payPeriodEndDate,
        pageSize: 20,
        currentPageNo: 1,
        totalDataLength: 0,
        drillDownShiftDate: "",
        reactTableClassName: "react-payroll-deduction-log-table",
        tableRow: {},
        childTableRow: {}
    };

    hoverOutEllipsis = () => {
        $(".tooltip")?.removeClass("show");
        $(".tooltip")?.removeClass("fade");
    };

    cellTooltipFormatter = (cell: any, row: any, rowIndex: any) => {
        console.log(cell)
        const text = cell?.toString();
        let substr = "";
        if (text?.length >= 15) {
            substr = `${text.substr(0, 10)}...`;
        }
        if (row.main === 999 && cell == row.transactionId) {
            return (
                <><svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1V5.94118H13M13 5.94118L8.05882 1M13 5.94118L8.05882 10.8824" stroke="#84888C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                </>
            )
        }
        return (
            <>
                <EllipsisWithTooltip placement="bottom">{cell}</EllipsisWithTooltip>
            </>
        );
    };




    cellTransactionTypeFormatter = (cell: any, row: any, rowIndex: any) => {

        const text = cell?.toString();
        let substr = "";
        if (text?.length >= 15) {
            substr = `${text.substr(0, 10)}...`;
        }
        if (row.main === 999 && cell == row.transactionId) {
            return (
                <><svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1V5.94118H13M13 5.94118L8.05882 1M13 5.94118L8.05882 10.8824" stroke="#84888C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                </>
            )
        }
        return (
            <>
                {" "}
                <div>
                    {
                        row.transactionType === "From" &&
                        <EllipsisWithTooltip placement="bottom">{"Debit"}</EllipsisWithTooltip>
                    }
                    {
                        row.transactionType === "To" &&
                        <EllipsisWithTooltip placement="bottom">{"Credit"}</EllipsisWithTooltip>
                    }
                    {
                        !(row.transactionType === "From" || row.transactionType === "To") &&
                        <EllipsisWithTooltip placement="bottom">{cell}</EllipsisWithTooltip>
                    }
                </div>
            </>
        );
    };

    cellTooltipPositionFormatter = (cell: any, row: any, rowIndex: any) => {
        const text = cell?.toString();
        let substr = "";
        if (text?.length > 11) {
            substr = `${text.substr(0, 11)}...`;
        }
        return (
            <>
                {" "}
                {text?.length > 11 ? (
                    <div onWheel={() => this.hoverOutEllipsis()}>
                        <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id={"PositionName"}>{cell}</Tooltip>}
                        >
                            <span>{substr}</span>
                        </OverlayTrigger>
                    </div>
                ) : (
                    <div>{cell}</div>
                )}
            </>
        );
    };

    tableColumns = [
        {
            dataField: "rowNumber",
            text: "",
            hidden: true,
        },




        {
            dataField: "editor",
            text: "User Name",
            filterInputType: "text",
            headerStyle: () => {
                return { width: "15%" };
            },
            formatter: this.cellTooltipFormatter,
        },

        {
            dataField: "ehid",
            text: "EHID",
            filterInputType: "text",
            headerStyle: () => {
                return { width: "15%" };
            },
            formatter: this.cellTooltipFormatter,
        },
        {
            dataField: "editDateTime",
            text: "Date Time",
            filterInputType: "date",

            headerStyle: () => {
                return { width: "20%" };
            },
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <>
                        <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id={"PositionName"}>{cell}</Tooltip>}
                        >
                            <div className="edit-date-time">{cell}</div>
                        </OverlayTrigger>

                    </>
                );
            },
        },


        {
            dataField: "fieldname",
            text: "Field Name",
            filterInputType: "text",
            headerStyle: () => {
                return { width: "25%" };
            },
            formatter: this.cellTooltipFormatter,
        },
        {
            dataField: "oldValue",
            text: "Old Value",
            filterInputType: "text",
            headerStyle: () => {
                return { width: "25%" };
            },
            formatter: this.cellTooltipFormatter,
        },
        {
            dataField: "newValue",
            text: "New Value",
            filterInputType: "text",
            headerStyle: () => {
                return { width: "25%" };
            },
            formatter: this.cellTooltipFormatter,
        },
      
    ];

    componentDidMount = () => {
        this.buildDefaultData();
    };
  
    buildDefaultData = () => {
        // let tableColumns = (+this.props?.shiftId > 0 ? this.tableColumns.filter(
        //   (column: any) => column?.dataField
        // ) : this.tableColumns) as any[];

        let tableColumns = this.tableColumns.map((x: any) => {
            x.drillDownLevel = 0;
            return x;
        });
        //let  tableClassName = +this.props?.shiftId > 0 ? "transaction-log-table-drilldown" : "transaction-log-table";
        let tableClassName = "transaction-log-table";
        const filterColumns = tableColumns
            .filter((item) => item.filterInputType)
            .map((item) => ({
                text: item.text,
                key: item.dataField,
                isFilterApplied: false,
                filterInputType: item?.filterInputType,
                dropDownProps: item?.dropDownProps,
            }));

        this.setState({
            tableClassName,
            filterPopUps: [],
            filterColumns,
            tableColumns: tableColumns,
        });

        this.getChangeLogData(true);
    };

    validateDates = () => {
        const currentFilter: any = { ...this.state.currentFilter };
        if (currentFilter.filterKeyName === "editDateTime") {
            const { startDate, endDate } = this.state;

            if (!endDate) {
                Utils.toastError("Please enter End Date.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });

                return false;
            } else if (!startDate) {
                Utils.toastError("Please enter Start Date.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });

                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    };

   

    updateFilters = () => {
        const currentFilter: any = { ...this.state.currentFilter };
        let filterPopUps: any[] = [...this.state.filterPopUps];
        const filterIndex = filterPopUps.findIndex(
            (filter) => filter?.filterName === currentFilter?.filterName
        );
        if (
            currentFilter.filterKeyName === "editDateTime" &&
            !currentFilter.filterValue
        ) {
            currentFilter.filterValue = `${this.props.payPeriodStartDate} - ${this.props.payPeriodEndDate}`;
        }
        if (filterIndex < 0) {
            filterPopUps.push(currentFilter);
        } else {
            filterPopUps[filterIndex] = currentFilter;
        }
        filterPopUps = filterPopUps?.filter((item) => item?.filterValue);
        filterPopUps = filterPopUps.map((item, index) => {
            item.popClassName = "filter-edit-popup-" + index;
            return item;
        });
        this.setState({ filterPopUps, currentPageNo: 1 }, () => {
            this.getChangeLogData();
            this.updateFilterColumns();
        });
    };

    updateFilterColumns = () => {
        const { filterColumns: columns, filterPopUps } = this.state;
        const filteredKeys = filterPopUps?.map(
            (filter: ICurrentFilter) => filter?.filterKeyName
        );
        const filterColumns = columns.map((item: any) => {
            if (filteredKeys.find((key) => key === item?.key)) {
                item.isFilterApplied = true;
            } else {
                item.isFilterApplied = false;
            }
            return item;
        });
        this.setState({ filterColumns });
    };

    onFilterTextChange = (event: any) => {
        const currentFilter: any = { ...this.state.currentFilter };
        currentFilter.filterValue = event.currentTarget?.value;
        this.setState({ currentFilter });
    };

    onFilterDropDownChange = (item: any, valueFieldName) => {
        const currentFilter: ICurrentFilter = { ...this.state.currentFilter };
        currentFilter.filterValue = item[valueFieldName];
        if (!currentFilter.dropDownProps) {
            currentFilter.dropDownProps = {};
        }
        currentFilter.dropDownProps.dropDownSelectedItem = item;
        this.setState({ currentFilter });
    };

    onFilterChange = (filterItem: any, popClassName): void => {
        const filterPopUps: any[] = [...this.state.filterPopUps];
        let currentFilter: ICurrentFilter = this.state.currentFilter;

        if (filterPopUps.length >= 5) {
            Utils.toastError("You can't apply more than 5 filters!", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
        if (
            filterPopUps.length >= 5 ||
            filterPopUps.filter((filter) => filter?.filterName === filterItem.text)
                ?.length > 0
        ) {
            return;
        }
        currentFilter = {
            filterName: filterItem?.text,
            filterValue: "",
            filterKeyName: filterItem.key,
            filterInputType: filterItem?.filterInputType,
            popClassName: popClassName,
            dropDownProps: filterItem?.dropDownProps,
        };
        this.setState({
            currentFilter,
            currentPageNo: 1,
            showFilterPopUp: true,
            popClassName,
            startDate: this.props.payPeriodStartDate,
            endDate: this.props.payPeriodEndDate,
        });
    };

    removeFilter = (filterName: string): void => {

       


        let filterPopUps: any[] = [...this.state.filterPopUps];
        if (filterPopUps.length === 0) {
            return;
        }
        filterPopUps = filterPopUps.filter(
            (filter) => filter?.filterName !== filterName
        );
        if (filterPopUps.length > 0) {
            filterPopUps = filterPopUps.map((item, index) => {
                item.popClassName = "filter-edit-popup-" + index;
                return item;
            });
        }

        if (+this.state.scheduleId > 0) {
            this.setState({ tableClassName: "transaction-log-table" });
            this.returnBackFromDrillDown();
        } else {
            this.setState({ filterPopUps, currentPageNo: 1 }, () => {
                this.getChangeLogData();
                this.updateFilterColumns();
            });
        }
    };

    hideFilterPopUp = () => {
        if (this.state.showFilterPopUp) {
            this.setState({
                showFilterPopUp: false,
                popClassName: "",
            });
        }
    };

    editFilter = (currentFilter: ICurrentFilter, popClassName) => {
        if (currentFilter.filterInputType === "date") {
            const datesArray = currentFilter.filterValue?.split("-");
            this.setState({
                currentFilter,
                popClassName,
                showFilterPopUp: true,
                startDate: datesArray[0]?.trim(),
                endDate: datesArray[1]?.trim(),
            });
        } else {
            this.setState({ currentFilter, popClassName, showFilterPopUp: true });
        }
    };



    returnBackFromDrillDown = () => {
        this.setState(
            {
                scheduleId: 0,
                currentPageNo: 1,
                isDrillDownView: false,
                filterPopUps: this.state.filterPopUpsCopy,
            },
            () => {
                this.getChangeLogData();
                this.updateFilterColumns();
            }
        );
    };

    onPaginationchange = () => {
        const currentPageNo = this.state.currentPageNo + 1;
        this.setState({ currentPageNo }, () => {
            this.getChangeLogData(false, true);
        });
    };

    getChangeLogData = (isDefaultLoad = false, isPagination = false, transactionType = "") => {

        // let tableColumns = (+this.props?.shiftId > 0 ? this.tableColumns.filter(
        //   (column: any) => column?.dataField
        // ) : this.tableColumns) as any[];

        let tableColumns = this.tableColumns

        if (!isPagination) {
            this.setState({
                tableData: [],
                filteredData: [],
                tableColumns,
            });
        } else {
            this.setState({
                tableColumns,
            });
        }



        const { currentFilter, filterPopUps, pageSize, currentPageNo } = this.state;

        const request: IPayrollProcessorLogRequest = {
            fromDate: "",
            toDate: "",
            tenantId: 0,
            fieldName: "",
            oldValue: "",
            newValue: "",
            editor: "",
            pageNumber: currentPageNo,
            pageSize: pageSize
        } as IPayrollProcessorLogRequest;

        filterPopUps.forEach((item: ICurrentFilter) => {
            if (item.filterKeyName === "editDateTime") {
                const datesArray = item.filterValue
                    ?.split("-")
                    .map((item) => item?.trim());
                request.fromDate = datesArray[0];
                request.toDate = datesArray[1];
            }

            else if (item.filterKeyName === "editor") {
                request.editor = item.filterValue;
            }
         
            else if (item.filterKeyName === "letterCode") {
                request.letterCode = item.filterValue;
            }
            else if (item.filterKeyName === "fieldname") {
                request.fieldName = item.filterValue;
            } else if (item.filterKeyName === "oldValue") {
                request.oldValue = item.filterValue;
            } else if (item.filterKeyName === "newValue") {
                request.newValue = item.filterValue;
            }
        });

        this.toggleDataLoading();
        LaborSchedule.GetLaborAccountMailLogData(request)
                .then((tableData: IPayrollProcessorLogChangeLog[] | null) => {
                tableData = tableData?.map((item) => {
                    item.editDateTime = moment(item.editDateTime).format(
                        "MM/DD/YYYY hh:mm"
                    );

                    return item;
                }) as IPayrollProcessorLogChangeLog[] | null;

                if (isPagination) {
                    tableData = [
                        ...this.state.tableData,
                        ...(tableData as IPayrollProcessorLogChangeLog[]),
                    ];
                }

                const totalDataLength = tableData?.length
                    ? tableData[0]?.totalCount
                    : 0;

                this.setState({
                    tableData: tableData,
                    filteredData: tableData,
                    totalDataLength,
                });
            })
            .catch((error) => {
                Utils.toastError(error.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            })
            .finally(() => {
                setTimeout(() => this.toggleDataLoading());
            });
    };



    getFilteredData = () => {
        this.onFiltersChange();
    };

    downloadChangeLogData = () => {
        const { filterPopUps, childTableRow, drillDownLevel } = this.state;
        const request: IPayrollProcessorLogRequest = {
            fromDate: "",
            toDate: "",
            tenantId: 0,
            fieldName: "",
            oldValue: "",
            newValue: "",
            editor: "",
            isDownload:1
        } as IPayrollProcessorLogRequest;

        filterPopUps.forEach((item: ICurrentFilter) => {
            if (item.filterKeyName === "editDateTime") {
                const datesArray = item.filterValue
                    ?.split("-")
                    .map((item) => item?.trim());
                request.fromDate = datesArray[0];
                request.toDate = datesArray[1];
            }
          

            else if (item.filterKeyName === "editor") {
                request.editor = item.filterValue;
            }

        
            else if (item.filterKeyName === "letterCode") {
                request.letterCode = item.filterValue;
            }
            else if (item.filterKeyName === "fieldname") {
                request.fieldName = item.filterValue;
            } else if (item.filterKeyName === "oldValue") {
                request.oldValue = item.filterValue;
            } else if (item.filterKeyName === "newValue") {
                request.newValue = item.filterValue;
            }
        });
      LaborSchedule.DownloadLaborAccountMailLogData(
                    request,
                    `PayrollProcessorChangeLog_${moment().format("DD/MM/YY")}`
                ).then(() => { })
            .catch((error) => { });
   
    };
    toggleDataLoading = () => {
        this.setState({ isDataLoading: !this.state.isDataLoading });
    };

    handleDatesChange = (date, type) => {
        const { startDate, endDate } = this.state;
        const currentFilter: any = { ...this.state.currentFilter };
        if (type === "StartDate") {
            if (new Date(date) > new Date(endDate)) {
                this.setState({ startDate: "" });
                Utils.toastError(
                    "Please enter the Start Date less than or equal to End Date.",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );

                return false;
            }

            currentFilter.filterValue = `${date} - ${endDate}`;
            this.setState({ startDate: date });
        }

        if (type === "EndDate") {
            if (new Date(startDate) > new Date(date)) {
                this.setState({ endDate: "" });
                Utils.toastError(
                    "Please enter the End Date greater than or equal to Start Date.",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );

                return false;
            }

            currentFilter.filterValue = `${startDate} - ${date}`;
            this.setState({ endDate: date });
        }

        this.setState({ currentFilter });
    };

    onFiltersChange = () => {
        const currentFilter = this.state.currentFilter;
        this.setState({ currentPageNo: 1 }, () => {
            this.getChangeLogData();
        });

        if (currentFilter?.filterKeyName) {
            this.setState({
                currentFilter: {
                    filterName: "",
                    filterValue: "",
                    filterKeyName: "",
                    popClassName: "",
                    filterInputType: "",
                    dropDownProps: {},
                },
            });
        }
    };

    clearFilters = () => {
     
            this.setState({ filterPopUps: [], currentPageNo: 1 }, () => {
                this.getChangeLogData();
                this.updateFilterColumns();
            });
        
    };

    render() {
        const { tableColumns } = this.state;


        const functions = {
            downloadChangeLogData: this.downloadChangeLogData,
            updateFilters: this.updateFilters,
            editFilter: this.editFilter,
            onFilterTextChange: this.onFilterTextChange,
            onFilterChange: this.onFilterChange,
            removeFilter: this.removeFilter,
            hideFilterPopUp: this.hideFilterPopUp,
            clearFilters: this.clearFilters,
            onFiltersChange: this.onFiltersChange,
            handleDatesChange: this.handleDatesChange,
            validateDates: this.validateDates,
            onPaginationchange: this.onPaginationchange,
            onFilterDropDownChange: this.onFilterDropDownChange,
        };
        const forwardedProps = { ...this.state, ...this.props, ...functions };
        return (
            <>
                <ToastContainer autoClose={3000} />
                {tableColumns?.length > 0 && <ChangeLogs {...forwardedProps} />}
            </>
        );
    }
}
