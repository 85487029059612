import * as React from "react";
import {
  Button,
  Container,
  Dropdown,
  Form,
  // Form,
  Modal,
  Tab,
  Tabs,
} from "react-bootstrap";
import { SingleSearchDropdownList } from "../../Common/Components/SingleSearchDropdownList";
import { UserManagement } from "./../../Common/Services/UserManagement";
import { IUserRole } from "../../Common/Contracts/IUserRole";
import _ from "lodash";
import { toast } from "react-toastify";
export class MissingInfoModal extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      showModal: this.props.showModal,
      missingPhoneEmailUsers : this.props.missingPhoneEmailUsers
    };
  }
  componentDidMount() {
   
  }

  
  hideMissingInfoModal = () => {
    this.props.hideMissingInfoModal();
  };

  renderUserName = ()=>{
    if(this.state.missingPhoneEmailUsers?.length > 0){
      return <ul>
      {
        this.state.missingPhoneEmailUsers?.map(item=>
          <li> {item.username}</li>
        )
      }
      </ul>
    }else{
      return <></>
    }
    
  }
  render() {
    const { userRoles, role } = this.state;

    return (
      <div>
        <Modal
          className="missing-info-modal"
          show={this.state.showModal}
          onHide={() => this.hideMissingInfoModal()}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Missing Info</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="description">
            {this.state.missingPhoneEmailUsers?.length > 0 &&
            <>The below user(s) have missing email and phone. Please update the details before you can proceed with resetting their passwords.
            </>
            }
            {this.state.missingPhoneEmailUsers?.length == 0 &&
            <>The user has missing email and phone. Please update the details before you can proceed with resetting their passwords.</>
            }
            {this.renderUserName()}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
