import React from "react";
import { toast } from "react-toastify";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-toastify/dist/ReactToastify.css";
import { Container, Dropdown, Form, Row, Col, Button } from "react-bootstrap";
import { Redirect , Link} from "react-router-dom";
import { FiChevronDown } from "react-icons/fi";
import { resolve, reject } from "q";
import { IHotelResponseDto } from "../../../../Common/Contracts/IHotel";
import { Hotel as hotel } from "../../../../Common/Services/Hotel";
import { LaborDayApprove } from "../../../../Common/Services/LaborDayApprove";
import { ReactPageLoader } from "../../../../Common/Components/ReactPageLoader";
import { PayrollExportOverview } from "../Payroll Export/PayrollExportComponent";
import { TimeoffRequestComponent } from "../Payroll Export/TimeoffRequestComponent";
import LaborDayOverviewDetails from "./DayApproveOverview/LaborDayOverviewDetails";
import BreakException from "./BreakExceptionViews/BreakException";
import LaborDayApproval from "./DayApproveOverview/LaborDayApproval";
import BreakExceptionDetails from "./BreakExceptionViews/BreakExceptionDetails";
import SmileIdOverviewDetails from "../SmileId/SmileIdOverviewDetails";
import SmileIdOverview from "../SmileId/SmileIdOverview";
import HousekeepingApprovalHID from "./HouseKeepingOverview/HousekeepingApprovalHID";
import RoomAssignmentsHID from "./RoomAssignmnetOverview/RoomAssignmentsHID";
import { SingleSearchDropdownList } from "../../../../Common/Components/SingleSearchDropdownList";
import { RoomAssignments } from "./RoomAssignmnetOverview/RoomAssignments";
import { HousekeepingApprovalsList } from "./HouseKeepingOverview/HousekeepingApprovalsList";
import { OvertimeRisk as OverTimeAtRiskComponent } from "../../../../Modules/LaborManagement/Labor/Schedule/OverTimeRisk";
import BootstrapTable from "react-bootstrap-table-next";
import NoAlerts from "../../../../Common/Assets/Images/icons/noAlertOverview.svg";
import { User } from "../../../../Common/Services/User";
import {
  ILaborDayApproveRequestDto,
  ILaborSmileIdRequestDto,
} from "../../../../Common/Contracts/ILaborDayApprove";
import OutsideClickHandler from "react-outside-click-handler";
import profile from "../../../../Common/Assets/Images/profile.png";
import caught from "../../../../Common/Assets/Images/icons/all-caught.png";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { WebTimeClockService } from "../../../../Common/Services/WebTimeClock";
import { TimeClockCommonFunc } from "../../../../WebTimeClock/TimeClockCommonFunc";
import moment from "moment-timezone";
import { ILaborScheduleDeptPositionRequestDto } from "../../../../Common/Contracts/ILaborSchedule";
import {
  RoleSubPermissionSetting,
  IPageSubPermissionRole,
} from "../../../../Common/Services/RoleSubPermissionSetting";
import { PerformanceSlideout } from "../PerformanceViews/PerformanceSlideout";
import { LaborTimesheetDetail } from "../../TimeSheet/LaborTimesheetDetail";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Utils } from "../../../../Common/Utilis";
import { AddEditShiftModal } from "../../../../Modules/LaborManagement/Labor/Schedule//Modals & SlideOut/AddEditShiftModal";
import { LaborPerformance as laborPerformance } from "../../../../Common/Services/LaborPerfomance";
import { LaborTimeSheet } from "../../../../Common/Services/LaborTimeSheet";
import LaborDayOverviewDetailsNew from "./DayApproveOverview/LaborDayOverviewDetailsNew";
import LaborDayApprovalNew from "./DayApproveOverview/LaborDayApprovalNew";
import TableSearch from "../../../../Common/Components/TableSearch";

const { SearchBar } = Search;
let localStorageUser: any;
const noOfApis = 9;
function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className + " d-flex justify-content-center align-items-center"} onClick={onClick}>
      <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.03029 9.28026L6.31054 5.00001L2.03029 0.719757L0.969788 1.78026L4.18954 5.00001L0.969788 8.21976L2.03029 9.28026Z" fill="#2CAF92" />
      </svg>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className={className + " d-flex justify-content-center align-items-center"} onClick={onClick}>
      <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.03029 9.28026L6.31054 5.00001L2.03029 0.719757L0.969788 1.78026L4.18954 5.00001L0.969788 8.21976L2.03029 9.28026Z" fill="#2CAF92" />
      </svg>
    </div>
  );
}

let slickSliderCount: any = 0;
export class LaborDayOverviews extends React.Component<any, any> {
  private hasPageWidth: any;
  private laborDayOverviewDetailsChild: any;
  private breakExceptionsDetails: any;
  private laborDayHotelApprovalDetailsChild: any;
  private breakExceptionsChildDetails: any;
  private simeIdOverviewChild: any;
  private simeIdOverviewDetailsChild: any;
  private refRoomAssignments: any;
  private refRoomAssignmentsHID: any;
  private refHousekeepingApprovals: any;
  private refHousekeepingApprovalHID: any;
  private refotarPanel: any;
  private payrollExportPanel: any;
  private TimeOffRequestPanel: any;
  private saveBtnRef: any;
  constructor(props: any) {
    super(props);
    this.hasPageWidth = React.createRef();
    this.laborDayOverviewDetailsChild = React.createRef();
    this.breakExceptionsDetails = React.createRef();
    this.laborDayHotelApprovalDetailsChild = React.createRef();
    this.breakExceptionsChildDetails = React.createRef();
    this.simeIdOverviewChild = React.createRef();
    this.simeIdOverviewDetailsChild = React.createRef();
    this.refRoomAssignments = React.createRef();
    this.refRoomAssignmentsHID = React.createRef();
    this.refHousekeepingApprovals = React.createRef();
    this.refHousekeepingApprovalHID = React.createRef();
    this.refotarPanel = React.createRef();
    this.payrollExportPanel = React.createRef();
    this.TimeOffRequestPanel = React.createRef();
    this.saveBtnRef = React.createRef();
    this.state = {
      scrollWidth: "auto",
      scrollItemList: 1,
      navbtnClicked: false,
      hidList: [],
      hidFilterList: [],
      hidValue: "Select",
      hotelName: "Select",
      tableData: [],
      itemTableData: [],
      showDaysAlertBox: false,
      loaderTrue: false,
      isCheckComponent: "",
      activeComparision: "",
      isLetterCode: "",
      isLetterId: "",
      isSlideOutShow: false,
      positionDetailsRow: {},
      search: "",
      overTimeRiskCount: {},
      payrollExportCount: {},
      overtimeRiskPanel: false,
      overtimeRiskPanelData: [],
      overtimeRiskPanellr: true,
      tabName: "",
      payrollExportHolelList: [],
      isPayrollRedicet: false,
      redirectState: {},
      isRecordExist: false,
      tableDataWebClock: [],
      tableDataFilter: [],
      tableStatusFilter: [],
      positionName: "Position",
      departmentName: "Department",
      positionList: [],
      departmentList: [],
      calenderShow: false,
      addEdiShiftValues: {},
      addEditShiftModal: false,
      selectedDepartment: 0,
      selectedPosition: 0,
      displayCurrentDay: "",
      weekStartDate: "",
      weekEndDate: "",

      calenderSelectDate: {},
      calenderDisplayDate: "",
      isWebClockPermission: false,
      webClockLoaderTrue: false,
      TOffRHotelList: [],
      ToffRHotelCount: {},
      isToffRRedicet: false,
      ToffRredirectState: {},
      defaultSelectedStatus: "Shift status",
      shiftStatus: ["All", "Punched In", "Punched In Late", "Late", "Scheduled", "Punched Out", "No Show"],
      permissionManageSchedule: false,
      isSchedulingPermission: false,
      navigationCalenderDates: [],
      currentDate: {},
      showForcePunch: false,
      fullTimeClockData: "No",
      isTodayactive: false,
      hasPageWidth: 0,
      isPageSource: "ScheduleLaborOverview",
      currentHotelTime: '',
      hotelTimeZoneCurrentDateTime: '',
      isHolidayRedirect: false,
      hiddenBoxes: "",
      apisCalledCount: 0,
      smileIdIndex1: -1,
      smileIdIndex2: -1,
      featureFlag: false,
      isGMDayPermission: ""
    };
  }

  getHidPayPeriod() {
    let hotelID = this.state.hidValue === '' ? 0 : this.state.hidValue;
    let currentDay: any = new Date();
    laborPerformance.getPayPeriodDates(hotelID, currentDay, "Weekly").then(async (result: any[] | null) => {
      if (result != null) {
        this.setState({ navigationCalenderDates: [result[0].startdate, result[0].enddate] });
      }
    });
  }

  getSchudlePermission = () => {
    let requestObject = {} as IPageSubPermissionRole;
    requestObject.permissionID = 10006;
    requestObject.Module = "LaborManagement";
    requestObject.Type = "page";
    RoleSubPermissionSetting.GetPageSubPermissionDetails(requestObject)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          result.forEach((element: any) => {
            switch (element.permissionID) {
              case 10021:
                this.setState({ permissionManageSchedule: true });
                break;
            }
          });
        } else {
          Utils.toastError(result.message, {
          });
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
        });
        reject();
      });
  };

  getWebTimeClockManagerPermission = () => {
    let localStorageUser = JSON.parse(localStorage.getItem("storage")!);
    let requestObject = {} as IPageSubPermissionRole;
    requestObject.permissionID = 10048;
    requestObject.Module = "LaborManagement";
    requestObject.Type = "page";
    requestObject.RoleID = localStorageUser.rolId;
    requestObject.TenantID = localStorageUser.tenantID;
    RoleSubPermissionSetting.GetPageSubPermissionWebClockDetails(requestObject)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          for (const element of result) {
            if (element.permissionID === 10049) {
              this.setState({ isSchedulingPermission: true });
            }
            if (element.displayName.toString().toLowerCase() === "allow forced punches" && element.moduleName === "LaborManagement") {
              this.setState({ showForcePunch: true });
            }
          }
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
        });
        reject();
      });
  };

  changeOTARCount = (countValue: any) => {
    let { overTimeRiskCount } = this.state;
    overTimeRiskCount["dayCount"] = countValue;
    this.setState({ overTimeRiskCount });
    sessionStorage.removeItem("otarCurrentRow");
    this.cusToastMessage(overTimeRiskCount.dayCount);
  };

  cusToastMessage = (countValue: any) => {
    if (countValue === 0) {
      setTimeout(() => {
        this.setState({ overtimeRiskPanellr: false });
      }, 1500);
    }
  };

  componentDidMount() {
    localStorageUser = JSON.parse(localStorage.getItem("storage")!);
    const hotelid = JSON.parse(localStorage.getItem("hotelid")!);
    const hotelName = JSON.parse(localStorage.getItem("hotelName")!);
    this.preDefineArray();
    this.getSchudlePermission();
    this.getWebTimeClockManagerPermission()
    this.getWebClockViewPermission();
    hotel.getUserHotelAccess("Labor Management").then(async (result: IHotelResponseDto[] | null) => {
      if (result != null && result.length > 0) {
        let hidList = [...result];
        const allAppendedHotel = {
          ehidName: "All",
          hotelID: "All",
          hotelName: "All",
          hotelType: "1",
          lettercode: "All",
          tenantID: localStorageUser.tenantID,
        };
        if (result.length > 1) {
          hidList.unshift(allAppendedHotel);
        }
        this.setState({ hidList: hidList, hidFilterList: hidList })
        if (hotelName === null) {
          if (result.length > 1) {
            this.setState({
              hotelName: hidList[0].lettercode,
              hidValue: hidList[0].hotelID,
            }, () => {
              this.getHidPayPeriod();
              this.getHotelTime();
            });
          }
        } else {
          this.setState({ hotelName: hotelName });
        }
        if (hotelid === null) {
          if (result.length === 1) {
            this.setState(prevState => ({
            hidValue: prevState.hidList[0].hotelID,
            hotelName: prevState.hidList[0].lettercode,
          }), () => {
            this.getHidPayPeriod();
            this.getHotelTime();
          });
          }
        } else {
          this.setState({ hidValue: hotelid }, () => {
            this.getHidPayPeriod();
            this.getHotelTime();
          });
        }
        
        if (this.state.hidValue !== "Select") {
          this.refreshBindApproveData();
          if (this.state.hidList.length < 2) {
            let timeZoneValue = TimeClockCommonFunc.getCurrentTimeZone(
              this.state.hidList[0].hidTimeZone
            );
            let zoneDate: any = moment.tz(timeZoneValue).format("DD");
            let zoneMonth: any = moment.tz(timeZoneValue).format("MM");
            let zoneYear: any = moment.tz(timeZoneValue).format("YYYY");
            let curretnzoneDate = `${zoneMonth}/${zoneDate}/${zoneYear}`;
            this.setState({ displayCurrentDay: curretnzoneDate });
            const todayDate = new Date(curretnzoneDate);

            this.setState({
              calenderDisplayDate: curretnzoneDate,
              calenderSelectDate: todayDate,
              currentDate: todayDate,
              isTodayactive: true,
            });
            this.getLaborManagerTimeClockData("d");
            this.state.hidValue !== "All" && this.getPostionDepartment("d");
          }
        }
      }
      resolve();
    })
      .catch((error) => {
        Utils.toastError(`Server Error: ${error}`, {
        });
        reject();
      });
    window.addEventListener('resize', this.setslickSliderCount);
    let navToggleBtn: any = document.getElementById('nav-toggle-btn');
    navToggleBtn.addEventListener('click', this.navbuttonClicked);
  }
  navbuttonClicked = () => {
    this.setState({ navbtnClicked: true });
    this.setslickSliderCount();
  }

  preDefineArray = () => {
    let SortArayForAlertCard: any = {};
    let alertItem: any = {};
    alertItem.index = 0;
    alertItem.dayType = "GMApproveDay";
    alertItem.index = 1;
    alertItem.dayType = "ApproveDay";
  }

  getWebClockViewPermission = () => {
    let requestObject = {} as IPageSubPermissionRole;
    requestObject.permissionID = 10035;
    requestObject.Module = "LaborManagement";
    requestObject.Type = "page";
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID );
    let rolId = storage === null ? 0 : (storage.rolId );
    requestObject.RoleID = parseInt(rolId);
    requestObject.TenantID = tenantID;
    RoleSubPermissionSetting.GetPageSubPermissionWebClockDetails(requestObject)
      .then(async (resultPermission: any | null) => {
        if (resultPermission != null && resultPermission.length > 0) {
          for (const element of resultPermission) {
            if (element.permissionID === 10048) {
              this.setState({ isWebClockPermission: true });
              break;
            }
          }
        }
      })
      .catch((err: any) => {
        this.setState({ buttonSpinner: false });
        Utils.toastError(`Server Error, ${err}`, {
        });
        reject();
      });
  };

  getBudget = (hotelDetails: any) => {
    if (hotelDetails.hotelID !== "All") {
      const location = this.props.location;
      if (location.state !== undefined) {
        location.state = undefined;
      }
    }
    let timeZoneValue = TimeClockCommonFunc.getCurrentTimeZone(hotelDetails.hidTimeZone);
    let zoneDate: any = moment.tz(timeZoneValue).format("DD");
    let zoneMonth: any = moment.tz(timeZoneValue).format("MM");
    let zoneYear: any = moment.tz(timeZoneValue).format("YYYY");
    let curretnzoneDate = `${zoneMonth}/${zoneDate}/${zoneYear}`;
    let isActive = false;
    hotelDetails.hotelID !== "All" ? (isActive = true) : (isActive = false)
    this.setState({ isTodayactive: isActive })
    this.setState({ displayCurrentDay: curretnzoneDate });
    const todayDate = new Date(curretnzoneDate);
    this.setState({
      isRecordExist: false,
      calenderDisplayDate: curretnzoneDate,
      calenderSelectDate: todayDate,
      currentDate: todayDate,
      defaultSelectedStatus: "Shift status",
      hotelName: hotelDetails.lettercode,
      hidValue: hotelDetails.hotelID,
      isCheckComponent: "",
      isLetterId: hotelDetails.hotelID,
      overtimeRiskPanel: false,
      overtimeRiskPanelData: [],
      fullTimeClockData: "No",
    },
      () => {
        this.getHotelTime();
        this.refreshBindApproveData();
        this.getLaborManagerTimeClockData("d");
        hotelDetails.hotelID !== "All" && this.getPostionDepartment("d");
        this.getHidPayPeriod();
      }
    );
  };

  refreshBindApproveData = () => {
    this.setState({
      tableData: [],
      itemTableData: [],
      activeComparision: "",
      apisCalledCount: 0,
      smileIdIndex1: -1,
      smileIdIndex2: -1,
    })
    let request = {} as ILaborDayApproveRequestDto;
    request.hotelID = this.state.hidValue;
    request.userUniqueNo = localStorageUser.user_UniqueID;
    this.GetLaborApproveDayAlertBoxCount(request);
    this.GetLaborSmileIDAlertBoxCount(request);
    this.GetLaborRoomAssignmentAlertBoxCount(request);
    this.GetLaborHousekeepingApprovalAlertBoxCount(request);
    this.GetLaborBreakAttestationAlertBoxCount(request);
    this.GetLaborHolidayCalendarAlertBoxCount(request);
    this.OverTimeRiskFirstApi();
    this.TimeOffRequestFirstApi();
    setTimeout(() => {
      this.payrollExportFirstApi();
    }, 2000);

  };

  refreshRoomAssignmentsAlertData = () => {
    let request = {} as ILaborDayApproveRequestDto;
    request.hotelID = this.state.hidValue;
    request.userUniqueNo = localStorageUser.user_UniqueID;
    LaborDayApprove.GetLaborRoomAssignmentAlertBoxCount(request)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          let itemTableData = [...this.state.itemTableData];
          let tableData = [...this.state.tableData];

          if (result[0].dayCount > 0) {
            if (tableData.filter((x) => x.dayType === "RoomAssignments").length > 0) {
              const index = tableData.findIndex((x) => x.dayType === "RoomAssignments");
              tableData[index].dayCount = result[0].dayCount;
            }
            if (itemTableData.filter((x) => x.dayType === "RoomAssignments").length > 0) {
              const index = itemTableData.findIndex((x) => x.dayType === "RoomAssignments");
              itemTableData[index].dayCount = result[0].dayCount;
            }
            this.setState({ itemTableData, tableData, showDaysAlertBox: true, }, () => {
              this.setslickSliderCount();
            });
          }
          else {
            if (tableData.filter((x) => x.dayType === "RoomAssignments").length > 0) {
              const index = tableData.findIndex((x) => x.dayType === "RoomAssignments");
              tableData.splice(index, 1);
            }
            if (itemTableData.filter((x) => x.dayType === "RoomAssignments").length > 0) {
              const index = itemTableData.findIndex((x) => x.dayType === "RoomAssignments");
              itemTableData.splice(index, 1);
            }
            this.setState({ itemTableData, tableData, isCheckComponent: "", }, () => {
              this.setslickSliderCount();
            });
          }
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ loaderTrue: false });
        Utils.toastError(`Server Error: ${error}`, {
        });
        reject();
      });
  }

  GetLaborApproveDayAlertBoxCount = (request: ILaborDayApproveRequestDto) => {
    LaborDayApprove.GetLaborApproveDayAlertBoxCount(request)
      .then(async (result: any | null) => {
        this.setState({ apisCalledCount: this.state.apisCalledCount + 1 });
        if (result != null && result.length > 0) {
          let itemTableData = [...this.state.itemTableData];
          let tableData = [...this.state.tableData];        
          if (result[0].dayCount > 0) {
            itemTableData.push(result[0]);
            if (result[0].isHide === "No") {
              tableData.push(result[0]);             
              if (tableData.filter((x) => x.dayType === "TotalHideBox").length > 0) {
                const index = tableData.findIndex((x) => x.dayType === "TotalHideBox");
                let hiddenObjArr = tableData.splice(index, 1);
                tableData.push(hiddenObjArr[0]);
              }


              tableData= this.AlertBoxAscdesc(tableData);
              this.setState({ itemTableData, tableData, showDaysAlertBox: true, }, () => {
                this.setslickSliderCount();
              });
            }
            else {
              this.setState({ itemTableData, });
              this.getHiddenCount();
            }
            this.setState({ isRecordExist: false });
          }

          if (result.length > 1) {
            if (result[1].dayCount > 0) {
              itemTableData.push(result[1]);
              if (result[1].isHide === "No") {
                tableData.push(result[1]);
                if (tableData.filter((x) => x.dayType === "TotalHideBox").length > 0) {
                  const index = tableData.findIndex((x) => x.dayType === "TotalHideBox");
                  let hiddenObjArr = tableData.splice(index, 1);
                  tableData.push(hiddenObjArr[0]);
                }
                tableData= this.AlertBoxAscdesc(tableData);
                this.setState({ itemTableData, tableData, showDaysAlertBox: true, }, () => {
                  this.setslickSliderCount();
                  if (result[0].dayCount > 0) {
                    if (result[0].isHide === "Yes") {
                      this.getHiddenCount();
                    }
                  }
                });
              }
              else {
                this.setState({ itemTableData, });
                this.getHiddenCount();
              }
              this.setState({ isRecordExist: false });
            }
          }


        }
        resolve();
      })
      .catch((error) => {
        this.setState({ apisCalledCount: this.state.apisCalledCount + 1 });
        this.setState({ loaderTrue: false });
        Utils.toastError(`Server Error: ${error}`, {
        });
        reject();
      });
  }

  GetLaborSmileIDAlertBoxCount = (request: ILaborDayApproveRequestDto) => {
    LaborDayApprove.GetLaborSmileIDAlertBoxCount(request)
      .then(async (result: any | null) => {
        this.setState({ apisCalledCount: this.state.apisCalledCount + 1 });
        if (result != null && result.length > 0) {
          let itemTableData = [...this.state.itemTableData];
          let tableData = [...this.state.tableData];        
          if (result[0].dayCount > 0) {
            itemTableData.push(result[0]);
            if (result[0].isHide === "No") {
              tableData.push(result[0]);
              if (tableData.filter((x) => x.dayType === "TotalHideBox").length > 0) {
                const index = tableData.findIndex((x) => x.dayType === "TotalHideBox");
                let hiddenObjArr = tableData.splice(index, 1);
                tableData.push(hiddenObjArr[0]);
              }
              tableData= this.AlertBoxAscdesc(tableData);
             
              this.setState({ itemTableData, tableData, showDaysAlertBox: true, }, () => {
                this.setslickSliderCount();
              });
            }
            else {
              this.setState({ itemTableData, });
              this.getHiddenCount();
            }
          }
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ apisCalledCount: this.state.apisCalledCount + 1 });
        this.setState({ loaderTrue: false });
        Utils.toastError(`Server Error: ${error}`, {
        });
        reject();
      });
  }

  GetLaborRoomAssignmentAlertBoxCount = (request: ILaborDayApproveRequestDto) => {
    LaborDayApprove.GetLaborRoomAssignmentAlertBoxCount(request)
      .then(async (result: any | null) => {
        this.setState({ apisCalledCount: this.state.apisCalledCount + 1 });
        if (result != null && result.length > 0) {         
          let itemTableData = [...this.state.itemTableData];
          let tableData = [...this.state.tableData];
          if (result[0].dayCount > 0) {
            itemTableData.push(result[0]);
            if (result[0].isHide === "No") {
              tableData.push(result[0]);
              if (tableData.filter((x) => x.dayType === "TotalHideBox").length > 0) {
                const index = tableData.findIndex((x) => x.dayType === "TotalHideBox");
                let hiddenObjArr = tableData.splice(index, 1);
                tableData.push(hiddenObjArr[0]);
              }
              tableData= this.AlertBoxAscdesc(tableData);
              this.setState({ itemTableData, tableData, showDaysAlertBox: true, }, () => {
                this.setslickSliderCount();
              });
            }
            else {
              this.setState({ itemTableData, });
              this.getHiddenCount();
            }
          }
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ apisCalledCount: this.state.apisCalledCount + 1 });
        this.setState({ loaderTrue: false });
        Utils.toastError(`Server Error: ${error}`, {
        });
        reject();
      });
  }

  GetLaborHousekeepingApprovalAlertBoxCount = (request: ILaborDayApproveRequestDto) => {
    LaborDayApprove.GetLaborHousekeepingApprovalAlertBoxCount(request)
      .then(async (result: any | null) => {
        this.setState({ apisCalledCount: this.state.apisCalledCount + 1 });
        if (result != null && result.length > 0) {
         
          let itemTableData = [...this.state.itemTableData];
          let tableData = [...this.state.tableData];
          if (result[0].dayCount > 0) {
            itemTableData.push(result[0]);
            if (result[0].isHide === "No") {
              tableData.push(result[0]);
              if (tableData.filter((x) => x.dayType === "TotalHideBox").length > 0) {
                const index = tableData.findIndex((x) => x.dayType === "TotalHideBox");
                let hiddenObjArr = tableData.splice(index, 1);
                tableData.push(hiddenObjArr[0]);
              }
             
              tableData= this.AlertBoxAscdesc(tableData);
              this.setState({ itemTableData, tableData, showDaysAlertBox: true, }, () => {
                this.setslickSliderCount();
              });
            }
            else {
              this.setState({ itemTableData, });
              this.getHiddenCount();
            }
          }
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ apisCalledCount: this.state.apisCalledCount + 1 });
        this.setState({ loaderTrue: false });
        Utils.toastError(`Server Error: ${error}`, {
        });
        reject();
      });
  }

  GetLaborBreakAttestationAlertBoxCount = (request: ILaborDayApproveRequestDto) => {
    LaborDayApprove.GetLaborBreakAttestationAlertBoxCount(request)
      .then(async (result: any | null) => {
        this.setState({ apisCalledCount: this.state.apisCalledCount + 1 });
        if (result != null && result.length > 0) {
          let itemTableData = [...this.state.itemTableData];
          let tableData = [...this.state.tableData];
          if (result[0].dayCount > 0) {
            itemTableData.push(result[0]);
            if (result[0].isHide === "No") {
              tableData.push(result[0]);
              if (tableData.filter((x) => x.dayType === "TotalHideBox").length > 0) {
                const index = tableData.findIndex((x) => x.dayType === "TotalHideBox");
                let hiddenObjArr = tableData.splice(index, 1);
                tableData.push(hiddenObjArr[0]);
              }
              tableData= this.AlertBoxAscdesc(tableData);
              this.setState({ itemTableData, tableData, showDaysAlertBox: true, }, () => {
                this.setslickSliderCount();
              });
            }
            else {
              this.setState({ itemTableData, });
              this.getHiddenCount();
            }
          }
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ apisCalledCount: this.state.apisCalledCount + 1 });
        this.setState({ loaderTrue: false });
        Utils.toastError(`Server Error: ${error}`, {
        });
        reject();
      });
  }

  GetLaborHolidayCalendarAlertBoxCount = (request: ILaborDayApproveRequestDto) => {
    LaborDayApprove.GetLaborHolidayCalendarAlertBoxCount(request)
      .then(async (result: any | null) => {
        this.setState({ apisCalledCount: this.state.apisCalledCount + 1 });
        if (result != null && result.length > 0) {
          let itemTableData = [...this.state.itemTableData];
          let tableData = [...this.state.tableData];
          if (result[0].dayCount > 0) {
            itemTableData.push(result[0]);
            if (result[0].isHide === "No") {
              tableData.push(result[0]);
              if (tableData.filter((x) => x.dayType === "TotalHideBox").length > 0) {
                const index = tableData.findIndex((x) => x.dayType === "TotalHideBox");
                let hiddenObjArr = tableData.splice(index, 1);
                tableData.push(hiddenObjArr[0]);
              }
              tableData= this.AlertBoxAscdesc(tableData);
              this.setState({ itemTableData, tableData, showDaysAlertBox: true, }, () => {
                this.setslickSliderCount();
              });
            }
            else {
              this.setState({ itemTableData, });
              this.getHiddenCount();
            }
          }
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ apisCalledCount: this.state.apisCalledCount + 1 });
        this.setState({ loaderTrue: false });
        Utils.toastError(`Server Error: ${error}`, {
        });
        reject();
      });
  }

  binDayApproveData = (request: ILaborDayApproveRequestDto) => {
    this.setState({
      loaderTrue: true,
      //webClockLoaderTrue:true,
      showDaysAlertBox: false,
      isRecordExist: false,
      itemTableData: [],
      tableData: [],
    });
    LaborDayApprove.GetLaborDayApprovalDetails(request)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          for (const element of result) {
            if (element.dayCount > 0) {
              this.setState({ showDaysAlertBox: true });
              break;
            }
          }
          this.setState({ itemTableData: result, activeComparision: "", hiddenBoxes: result[0].hideCol, });
          this.OverTimeRiskFirstApi();
          this.TimeOffRequestFirstApi();
        } else {
          this.setState({
            loaderTrue: true,
            showDaysAlertBox: false,
            isRecordExist: false,
            itemTableData: [],
            tableData: [],
          });
          this.OverTimeRiskFirstApi();
          this.TimeOffRequestFirstApi();
        }
        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error) => {
        this.setState({ loaderTrue: false });
        Utils.toastError(`Server Error: ${error}`, {
        });
        reject();
      });
  };

  OverTimeRiskFirstApi = () => {
    let response: any = {};
    response.tenantID = localStorageUser.tenantID;
    response.hotelID = this.state.hidValue === "All" ? 0 : this.state.hidValue;
    response.LoginUserName = localStorageUser.userName;
    response.callType = "AllHotelCount";
    let cusOtarArray: any = {};
    hotel
      .GetOverTimeRiskData(response)
      .then(async (result) => {
        this.setState({ apisCalledCount: this.state.apisCalledCount + 1 });
        if (result != null) {
          let apiResult = result.result;
          let itemTableData = [...this.state.itemTableData];
          let tableData = [...this.state.tableData];
          for (let i in apiResult) {
            cusOtarArray.dayCount = parseInt(apiResult[i].result);
          }

          cusOtarArray.rowno = this.state.itemTableData.length + 1;
          cusOtarArray.dayTypeName = "Overtime at Risk";
          cusOtarArray.dayType = "otar";
          cusOtarArray.isHide = apiResult[0].isHide;
          cusOtarArray.ordertype = apiResult[0].ordertype;

          let isValid = true;
          for (const element of itemTableData) {
            if (element.dayType === "otar") {
              isValid = false;
              break;
            }
          }
          //
          if (isValid) {
            if (cusOtarArray.dayCount > 0) {
              itemTableData.push(cusOtarArray);
              if (apiResult[0].isHide === "No") {
                tableData.push(cusOtarArray);
                if (tableData.filter((x) => x.dayType === "TotalHideBox").length > 0) {
                  const index = tableData.findIndex((x) => x.dayType === "TotalHideBox");
                  let hiddenObjArr = tableData.splice(index, 1);
                  tableData.push(hiddenObjArr[0]);
                }
                tableData= this.AlertBoxAscdesc(tableData);

                this.setState({ itemTableData, tableData, showDaysAlertBox: true, }, () => {
                  this.setslickSliderCount();
                });
              }
              else {
                this.setState({ itemTableData, });
                this.getHiddenCount();
              }
            }
          }


          this.setState({ overTimeRiskCount: cusOtarArray });
        } else {
        }
        const location = this.props.location;
        if (location.state !== undefined) {
          const stateLength = Object.keys(location.state).length;
          if (stateLength > 0) {
            let { tabName } = location.state;
            this.optionBoxClick(tabName);
          }
        }
        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error) => {
        this.setState({ apisCalledCount: this.state.apisCalledCount + 1 });
        this.setState({ loaderTrue: false });
        Utils.toastError(`Server Error: ${error}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "laborDayOverviewContainer",
        });
        reject();
      });
  };

  payrollExportFirstApi = () => {
    let request: any = {};
    request.tenantID = localStorageUser.tenantID;
    request.hotelID = this.state.hidValue === "All" ? 0 : this.state.hidValue;
    request.LoginUserName = localStorageUser.userName;
    request.callType = "HotelWiseCount";
    hotel
      .GetPayrollExportData(request)
      .then(async (result) => {
        this.setState({ apisCalledCount: this.state.apisCalledCount + 1 });
        if (result.success) {
          let apiResult = result.result;
          let payrollExportHolelList: any = [];
          let cusExportArray: any = {};
          let itemTableData = [...this.state.itemTableData];
          let tableData = [...this.state.tableData];
          for (let i = 0; i < apiResult.length; i++) {
            let rowPushObject: any = apiResult[i];
            rowPushObject.keyIndex = i;
            payrollExportHolelList.push(rowPushObject);
          }
          this.setState({ payrollExportHolelList });
          let dayCount = 0;
          for (const element of apiResult) {
            dayCount = dayCount + element.payPeriodCount;
          }

          cusExportArray.dayCount = dayCount;
          cusExportArray.rowno = itemTableData.length + 1;
          cusExportArray.dayTypeName = "Payroll Export";
          cusExportArray.dayType = "PE";
          cusExportArray.isHide = apiResult[0].isHide;
          cusExportArray.ordertype = apiResult[0].ordertype;
          let isValid = true;
          for (const element of itemTableData) {
            if (element.dayType === "PE") {
              isValid = false;
              break;
            }
          }
          //
          if (isValid) {
            if (cusExportArray.dayCount > 0) {
              itemTableData.push(cusExportArray);
              if (apiResult[0].isHide === "No") {
                tableData.push(cusExportArray);
                if (tableData.filter((x) => x.dayType === "TotalHideBox").length > 0) {
                  const index = tableData.findIndex((x) => x.dayType === "TotalHideBox");
                  let hiddenObjArr = tableData.splice(index, 1);
                  tableData.push(hiddenObjArr[0]);
                }
                tableData= this.AlertBoxAscdesc(tableData);

                this.setState({ itemTableData, tableData, }, () => {
                  this.setslickSliderCount();
                });
              }
              else {
                this.setState({ itemTableData, });
                this.getHiddenCount();
              }
            }
          }

          this.setState({
            payrollExportCount: cusExportArray,
          }, () => {
            this.setslickSliderCount();
          });

          if (this.state.tableData.length > 0) {
            const location = this.props.location;
            if (location.state !== undefined) {
              const stateLength = Object.keys(location.state).length;
              if (stateLength > 0) {
                let { tabName } = location.state;
                this.optionBoxClick(tabName);
              }
            }
          }
          //
          let flag = false;
          {
            this.state.itemTableData.map((data: any, key: any) => {
              if (data.dayCount !== 0) {
                if (flag === false) {
                  flag = true;
                }
              }
            });
          }
          if (flag === false) {
            this.setState({ isRecordExist: true });
          }
        } else {
        }

        this.setState({ loaderTrue: false, showDaysAlertBox: true });
        resolve();
      })
      .catch((error) => {
        this.setState({ apisCalledCount: this.state.apisCalledCount + 1 });
        this.setState({ loaderTrue: false });
        Utils.toastError(`Server Error: ${error}`, {
        });
        reject();
      });
  };

  setslickSliderCount = () => {
    if (this.hasPageWidth.current !== null) {
      let navbtnClicked = this.state.navbtnClicked;
      let setTimeVal = 0;
      if (navbtnClicked) {
        setTimeVal = 500
      }
      else {
        setTimeVal = 0
      }
      setTimeout(() => {
        if (this.hasPageWidth.current !== null) {
          let hasWidth = this.hasPageWidth.current.clientWidth;

          if (hasWidth < 850 && hasWidth > 600) {
            slickSliderCount = 3
          } else {
            slickSliderCount = 2
          }
          //this is for slidesToShow
          if (hasWidth < 440) {
            this.setState({ scrollItemList: 1 });
          } else if (hasWidth > 440 && hasWidth < 660) {
            this.setState({ scrollItemList: 2 });
          } else if (hasWidth > 660 && hasWidth < 880) {
            this.setState({ scrollItemList: 3 });
          } else if (hasWidth > 880 && hasWidth < 1100) {
            this.setState({ scrollItemList: 4 });
          } else if (hasWidth > 1100 && hasWidth < 1320) {
            this.setState({ scrollItemList: 5 });
          } else {
            this.setState({ scrollItemList: 6 });
          }
          this.setState({ scrollWidth: hasWidth });
        }
      }, setTimeVal);
    }
  }

  TimeOffRequestFirstApi = () => {
    let request: any = {};
    request.tenantID = localStorageUser.tenantID;
    request.hotelID = this.state.hidValue === "All" ? 0 : this.state.hidValue;
    request.LoginUserName = localStorageUser.userName;
    request.callType = "HotelWiseCount";
    hotel
      .GetPendingTimeoffCount(request)
      .then(async (result) => {
        this.setState({ apisCalledCount: this.state.apisCalledCount + 1 });
        if (result.success) {
          let apiResult = result.result;
          let TOffRHotelList: any = [];
          let cusToffRArray: any = {};
          let itemTableData = [...this.state.itemTableData];
          let tableData = [...this.state.tableData];
          for (let i = 0; i < apiResult.length; i++) {
            let rowPushObject: any = apiResult[i];
            rowPushObject.keyIndex = i;
            TOffRHotelList.push(rowPushObject);
          }
          this.setState({ TOffRHotelList: TOffRHotelList });
          let dayCount = 0;
          for (const element of apiResult) {
            dayCount = dayCount + element.countData;
          }
          
          cusToffRArray.dayCount = dayCount;
          cusToffRArray.rowno = itemTableData.length + 1;
          cusToffRArray.dayTypeName = "Time Off Requests";
          cusToffRArray.dayType = "ToffR";
          cusToffRArray.isHide = apiResult[0].isHide;
          cusToffRArray.ordertype = apiResult[0].ordertype;
          let isValid = true;
          for (const element of itemTableData) {
            if (element.dayType === "ToffR") {
              isValid = false;
              break;
            }
          }
          if (isValid) {
            if (cusToffRArray.dayCount > 0) {
              itemTableData.push(cusToffRArray);
              if (apiResult[0].isHide === "No") {
                tableData.push(cusToffRArray);
                if (tableData.filter((x) => x.dayType === "TotalHideBox").length > 0) {
                  const index = tableData.findIndex((x) => x.dayType === "TotalHideBox");
                  let hiddenObjArr = tableData.splice(index, 1);
                  tableData.push(hiddenObjArr[0]);
                }
                tableData= this.AlertBoxAscdesc(tableData);
                this.setState({ itemTableData, tableData, }, () => {
                  this.setslickSliderCount();
                });
                
              }
              else {
                this.setState({ itemTableData, });
                this.getHiddenCount();
              }
            }
          }
          this.setState({ ToffRHotelCount: cusToffRArray, });
          if (this.state.itemTableData.length > 0) {
            const location = this.props.location;
            if (location.state !== undefined) {
              const stateLength = Object.keys(location.state).length;
              if (stateLength > 0) {
                let { tabName } = location.state;
                this.optionBoxClick(tabName);
              }
            }
          }
        }
        this.setState({ loaderTrue: false, showDaysAlertBox: true });
        resolve();
      })
      .catch((error) => {
        this.setState({ apisCalledCount: this.state.apisCalledCount + 1 });
        this.setState({ loaderTrue: false });
        Utils.toastError(`Server Error: ${error}`, {
        });
        reject();
      });
  };

  getHiddenCount = () => {
    let tableData = Object.assign([], JSON.parse(JSON.stringify(this.state.tableData)));
    if (tableData.filter((x) => x.dayType === "TotalHideBox").length > 0) {
      const index = tableData.findIndex((x) => x.dayType === "TotalHideBox");
      tableData[index].dayCount = tableData[index].dayCount + 1;
    }
    else {
      let hiddenCard: any = {};
      hiddenCard.dayCount = 1;
      hiddenCard.rowno = 10;
      hiddenCard.dayTypeName = "TotalHideBox";
      hiddenCard.dayType = "TotalHideBox";
      hiddenCard.pipCount = 0;
      hiddenCard.isHide = "No"
      tableData.push(hiddenCard);
    }
    this.setState({ tableData }, () => {
      this.setslickSliderCount();
    });
  }

  filterCard = () => {
    let itemTableData = Object.assign([], JSON.parse(JSON.stringify(this.state.itemTableData)));
    if (itemTableData.filter((x) => x.dayType === "TotalHideBox").length > 0) {
      const index = itemTableData.findIndex((x) => x.dayType === "TotalHideBox");
      let hiddenObjArr = itemTableData.splice(index, 1);
      itemTableData.push(hiddenObjArr[0]);
    }
    this.setState({ itemTableData, tableData: itemTableData }, () => {
      let hiddenCount = 0;
      let tableData = Object.assign([], JSON.parse(JSON.stringify(this.state.tableData)));
      let hiddenBoxesArray = this.state.hiddenBoxes.split(",");
      for (const element of hiddenBoxesArray) {
        if (tableData.filter((x) => x.dayType === element.trim()).length > 0) {
          let index = tableData.findIndex((x) => x.dayType === element.trim());
          if (tableData[index].dayCount > 0) {
            tableData.splice(index, 1);
            hiddenCount++;
          }
        }
      }
      tableData[tableData.length - 1].dayCount = hiddenCount;
      this.setState({ tableData });
    });
  }

  refreshData() {
    let request = {} as ILaborDayApproveRequestDto;
    request.hotelID = this.state.hidValue;
    request.userUniqueNo = localStorageUser.user_UniqueID;
    this.refreshtableData(request);
  }

  refreshtableData(request: ILaborDayApproveRequestDto) {
    this.setState({
      showDaysAlertBox: true,
      isCheckComponent: "",
    });
    LaborDayApprove.GetLaborHousekeepingApprovalAlertBoxCount(request)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          let itemTableData = [...this.state.itemTableData];
          let tableData = [...this.state.tableData];
            
          if (result[0].dayCount > 0) {
            if (tableData.filter((x) => x.dayType === "HousekeepingApprovals").length > 0) {
              const index = tableData.findIndex((x) => x.dayType === "HousekeepingApprovals");
              tableData[index].dayCount = result[0].dayCount;
            }
            if (itemTableData.filter((x) => x.dayType === "HousekeepingApprovals").length > 0) {
              const index = itemTableData.findIndex((x) => x.dayType === "HousekeepingApprovals");
              itemTableData[index].dayCount = result[0].dayCount;
            }
            this.setState({ itemTableData, tableData, activeComparision: "", showDaysAlertBox: true, }, () => {
              this.setslickSliderCount();
              this.optionBoxClick("HousekeepingApprovals");
            });
          }
          else {
            if (tableData.filter((x) => x.dayType === "HousekeepingApprovals").length > 0) {
              const index = tableData.findIndex((x) => x.dayType === "HousekeepingApprovals");
              tableData.splice(index, 1);
            }
            if (itemTableData.filter((x) => x.dayType === "HousekeepingApprovals").length > 0) {
              const index = itemTableData.findIndex((x) => x.dayType === "HousekeepingApprovals");
              itemTableData.splice(index, 1);
            }
            this.setState({ itemTableData, tableData }, () => {
            });
          }
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ loaderTrue: false });
        Utils.toastError(`Server Error: ${error}`, {
        });
        reject();
      });
  }

  optionBoxClick = (type: string, dayTypeName?: string) => {   
    sessionStorage.removeItem("otarCurrentRow");
    this.setState({ activeComparision: type, isTodayactive: false });
    if (type !== "otar") {
      this.setState({ overtimeRiskPanel: false, overtimeRiskPanelData: [] });
    }
    if (type === "ApproveDay" || type === "GMApproveDay" || type === "DailyApproveDay") {
      let request = {} as ILaborDayApproveRequestDto;
      request.hotelID = this.state.hidValue;
      request.userUniqueNo = localStorageUser.user_UniqueID;
      if (type === "GMApproveDay" || type === "DailyApproveDay") {
        this.setState({ featureFlag: true, isGMDayPermission: type })
      }

      if (this.state.hidValue === "All") {
        this.setState({ isCheckComponent: "laborDayOverviewDetails" }, () => {
          this.laborDayOverviewDetailsChild.current.dayToApproveAccounts(
            request
          );
        });
      } else {
        this.setState({ isCheckComponent: "laborDayApproval" }, () => {
          this.laborDayHotelApprovalDetailsChild.current.dayToApproveHotel(
            request
          );
        });
      }
    } else if (type === "SmileID") {
      let request = {} as ILaborSmileIdRequestDto;
      request.hotelID = this.state.hidValue;
      if (this.state.hidValue === "All") {
        this.setState({ isCheckComponent: "smileIdOverview" }, () => {
          this.simeIdOverviewChild.current.smileIdOverviewAccounts(request);
        });
      } else {
        this.setState({ isCheckComponent: "smileIdOverviewDetails" }, () => {
          this.simeIdOverviewDetailsChild.current.smileIdOverviewAccountsDetails(
            request,
            "match%",
            "parentBoxClicked"
          );
        });
      }
    } else if (type === "RoomAssignments") {
      let request = {} as any;
      request.hotelID = this.state.hidValue;
      if (this.state.hidValue === "All") {
        request.hotelID = "-1";
        this.setState({ isCheckComponent: "RoomAssignmentsHID" }, () => {
          this.refRoomAssignmentsHID.current.getHouseKeepingRoomOccupied(
            request
          );
        });
      } else {
        this.setState({ isCheckComponent: "RoomAssignments" }, () => {
          this.refRoomAssignments.current.getHouseKeepingRoomOccupied(request);
        });
      }
    } else if (type === "HousekeepingApprovals") {
      let request = {} as any;
      request.hotelID = this.state.hidValue;
      if (this.state.hidValue === "All") {
        request.hotelID = "-1";
        this.setState({ isCheckComponent: "HousekeepingApprovalHID" }, () => {
          setTimeout(() => {
            this.refHousekeepingApprovalHID.current.getHousekeepingApprovals(
              request
            );
          }, 500);
        });
      } else {
        this.setState({ isCheckComponent: "HousekeepingApprovals" }, () => {
          setTimeout(() => {
            this.refHousekeepingApprovals.current.getHousekeepingApprovals(
              request
            );
          }, 500);
        });
      }
    } else if (type === "otar") {
      let request = {} as any;
      request.hotelID = this.state.hidValue;
      if (this.state.hidValue === "All") {
        request.hotelID = "-1";
        this.setState({ isCheckComponent: "OtarPanelList" }, () => {
          if (this.refotarPanel.current !== null) {
            this.refotarPanel.current.refotarPanel();
          }
        });
      } else {
        this.setState({ isCheckComponent: "OtarPanelList" }, () => {
          if (this.refotarPanel.current !== null) {
            this.refotarPanel.current.refotarPanel();
          }
        });
      }
    } else if (type === "PE") {
      let request = {} as any;
      request.hotelID = this.state.hidValue;
      if (this.state.tableData.length > 0) {
        if (this.state.hidValue === "All") {
          request.hotelID = "-1";
          this.setState({ isCheckComponent: "PExportPanelList" });
        } else {
          localStorage.setItem("mainMenu", "Labor");
          localStorage.setItem("subMenu", "Payroll");

          this.setState({
            isPayrollRedicet: true,
            redirectState: this.state.payrollExportHolelList[0],
          });
        }
      }
    } else if (type === "ToffR") {
      let request = {} as any;
      request.hotelID = this.state.hidValue;
      if (this.state.itemTableData.length > 0) {
        if (this.state.hidValue === "All") {
          request.hotelID = "-1";
          this.setState({ isCheckComponent: "TimeOffRequestPanel" });
        } else {
          localStorage.setItem("mainMenu", "Labor");
          localStorage.setItem("subMenu", "Time Off Requests");

          this.setState({
            isToffRRedicet: true,
            redirectState: this.state.TOffRHotelList[0],
          });
        }
      }
    } else if (type === "BreakException") {
      let request = {} as ILaborDayApproveRequestDto;
      request.hotelID = this.state.hidValue;
      request.userUniqueNo = localStorageUser.user_UniqueID;
      if (this.state.hidValue === "All") {
        this.setState({ isCheckComponent: "breakExceptionsAll_Details" }, () => {
          this.breakExceptionsDetails.current.dayToApproveAccounts(
            request
          );
        });
      } else {
        this.setState({ isCheckComponent: "breakExceptionHID_Details" }, () => {
          this.breakExceptionsChildDetails.current.dayToApproveHotel(
            request
          );
        });
      }
    } else if (type === 'HolidayCalendar') {
      localStorage.setItem("mainMenu", "Labor");
      localStorage.setItem("subMenu", "Holidays");
      this.setState({
        isHolidayRedirect: true,
        redirectState: type,
      });
    }
  };

  showHidden = () => {   
    let tableData = Object.assign([], JSON.parse(JSON.stringify(this.state.itemTableData)));
    tableData.sort((a, b) => {
      if (a.isHide < b.isHide) { return -1 };
      if (a.isHide > b.isHide) { return 1 };
      if (a.ordertype < b.ordertype) { return -1 };
      if (a.ordertype > b.ordertype) { return 1 };
      return 0;
    })

    this.setState({
      tableData: tableData,
    });
  }

  laborOverviewChecked = (hotelLetterCode: any, hotelID: any, callFrom: string) => {
    let request = {} as ILaborDayApproveRequestDto;
    request.hotelID = hotelID;
    request.userUniqueNo = localStorageUser.user_UniqueID;

    if (callFrom === "breakException") {
      this.setState(
        {
          isCheckComponent: "breakExceptionHID_Details",
          isLetterCode: hotelLetterCode,
        },
        () => {
          this.breakExceptionsChildDetails.current.dayToApproveHotel(
            request
          );
        }
      );
    } else {
      this.setState(
        {
          isCheckComponent: "laborDayApproval",
          isLetterCode: hotelLetterCode,
        },
        () => {
          this.laborDayHotelApprovalDetailsChild.current.dayToApproveHotel(
            request
          );
        }
      );
    }
  };

  laborDayApprovalBreadCrumbs = (event: string) => {
    if (event === "allHotels") {
      let request = {} as ILaborDayApproveRequestDto;
      request.hotelID = this.state.hidValue;
      request.userUniqueNo = localStorageUser.user_UniqueID;
      this.setState({ isCheckComponent: "laborDayOverviewDetails" }, () => {
        this.laborDayOverviewDetailsChild.current.dayToApproveAccounts(request);
      });
    }
  };

  breakExceptionsBreadCrumbs = (event: string) => {
    if (event === "allHotels") {
      let request = {} as ILaborDayApproveRequestDto;
      request.hotelID = this.state.hidValue;
      request.userUniqueNo = localStorageUser.user_UniqueID;
      this.setState({ isCheckComponent: "breakExceptionsAll_Details" }, () => {
        this.breakExceptionsDetails.current.dayToApproveAccounts(request);
      });
    }
  };

  smileIdBreadCrumbs = (event: string) => {
    if (event === "allHotels") {
      let request = {} as ILaborSmileIdRequestDto;
      request.hotelID = this.state.hidValue;
      this.setState({ isCheckComponent: "smileIdOverview" }, () => {
        this.simeIdOverviewChild.current.smileIdOverviewAccounts(request);
      });
    }
  };

  roomAssignmentBreadCrumbs = (event: string) => {
    if (event === "allHotels") {
      let request = {} as any;
      request.hotelID =
        this.state.hidValue === "All" ? -1 : this.state.hidValue;
      this.setState({ isCheckComponent: "RoomAssignmentsHID" }, () => {
        this.refRoomAssignmentsHID.current.getHouseKeepingRoomOccupied(request);
      });
    }
  };

  housekeepingApprovalHIDBreadCrumbs = (event: string) => {
    if (event === "allHotels") {
      let request = {} as any;
      request.hotelID =
        this.state.hidValue === "All" ? -1 : this.state.hidValue;
      this.setState({ isCheckComponent: "HousekeepingApprovalHID" }, () => {
        this.refHousekeepingApprovalHID.current.getHousekeepingApprovals(
          request
        );
      });
    }
  };

  OtarPanelListDetails = (eventData: any) => {
    this.setState({
      overtimeRiskPanelData: eventData,
      overtimeRiskPanel: true,
    });
  };

  laborDayApproval = (event: string, from?: string) => {
  
    if (event === "Approved") {
      if (from === "breakExceptionApproved") {
        let request = {} as ILaborDayApproveRequestDto;
        request.hotelID = this.state.hidValue;
        request.userUniqueNo = localStorageUser.user_UniqueID;
        LaborDayApprove.GetLaborBreakAttestationAlertBoxCount(request)
          .then(async (result: any | null) => {
            if (result != null && result.length > 0) {
              let itemTableData = [...this.state.itemTableData];
              let tableData = [...this.state.tableData];

              if (result[0].dayCount > 0) {
                if (tableData.filter((x) => x.dayType === "BreakException").length > 0) {
                  const index = tableData.findIndex((x) => x.dayType === "BreakException");
                  tableData[index].dayCount = result[0].dayCount;
                }
                if (itemTableData.filter((x) => x.dayType === "BreakException").length > 0) {
                  const index = itemTableData.findIndex((x) => x.dayType === "BreakException");
                  itemTableData[index].dayCount = result[0].dayCount;
                }
                this.setState({ itemTableData, tableData, showDaysAlertBox: true, }, () => {
                  this.setslickSliderCount();
                });
              }
              else {
                if (tableData.filter((x) => x.dayType === "BreakException").length > 0) {
                  const index = tableData.findIndex((x) => x.dayType === "BreakException");
                  tableData.splice(index, 1);
                }
                if (itemTableData.filter((x) => x.dayType === "BreakException").length > 0) {
                  const index = itemTableData.findIndex((x) => x.dayType === "BreakException");
                  itemTableData.splice(index, 1);
                }
                this.setState({ itemTableData, tableData, isCheckComponent: "", }, () => {
                  this.setslickSliderCount();
                });
              }
            }
            resolve();
          })
          .catch((error) => {
            this.setState({ loaderTrue: false });
            Utils.toastError(`Server Error: ${error}`, {
            });
            reject();
          });
      } else {
        let request = {} as ILaborDayApproveRequestDto;
        request.hotelID = this.state.hidValue;
        request.userUniqueNo = localStorageUser.user_UniqueID;
        LaborDayApprove.GetLaborApproveDayAlertBoxCount(request)
          .then(async (result: any | null) => {
            if (result != null && result.length > 0) {             
              let itemTableData = [...this.state.itemTableData];
              let tableData = [...this.state.tableData];

              if (result[0].dayCount > 0) {
                if (tableData.filter((x) => x.dayType === "ApproveDay").length > 0) {
                  const index = tableData.findIndex((x) => x.dayType === "ApproveDay");
                  tableData[index].dayCount = result[0].dayCount;
                }
                if (itemTableData.filter((x) => x.dayType === "ApproveDay").length > 0) {
                  const index = itemTableData.findIndex((x) => x.dayType === "ApproveDay");
                  itemTableData[index].dayCount = result[0].dayCount;
                }

                if (tableData.filter((x) => x.dayType === "DailyApproveDay").length > 0) {
                  const index = tableData.findIndex((x) => x.dayType === "DailyApproveDay");
                  tableData[index].dayCount = result[0].dayCount;
                }
                if (itemTableData.filter((x) => x.dayType === "DailyApproveDay").length > 0) {
                  const index = itemTableData.findIndex((x) => x.dayType === "DailyApproveDay");
                  itemTableData[index].dayCount = result[0].dayCount;
                }


                this.setState({ itemTableData, tableData, showDaysAlertBox: true, }, () => {
                  this.setslickSliderCount();
                });
              } else {
                if (tableData.filter((x) => x.dayType === "ApproveDay").length > 0) {
                  const index = tableData.findIndex((x) => x.dayType === "ApproveDay");
                  tableData.splice(index, 1);
                }
                if (itemTableData.filter((x) => x.dayType === "ApproveDay").length > 0) {
                  const index = itemTableData.findIndex((x) => x.dayType === "ApproveDay");
                  itemTableData.splice(index, 1);
                }

                if (tableData.filter((x) => x.dayType === "DailyApproveDay").length > 0) {
                  const index = tableData.findIndex((x) => x.dayType === "DailyApproveDay");
                  tableData.splice(index, 1);
                }
                if (itemTableData.filter((x) => x.dayType === "DailyApproveDay").length > 0) {
                  const index = itemTableData.findIndex((x) => x.dayType === "DailyApproveDay");
                  itemTableData.splice(index, 1);
                }

                this.setState({ itemTableData, tableData, showDaysAlertBox: true, });
              }



              if (result.length > 1) {
                if (result[1].dayCount > 0) {

                  if (tableData.filter((x) => x.dayType === "GMApproveDay").length > 0) {
                    const index = tableData.findIndex((x) => x.dayType === "GMApproveDay");
                    tableData[index].dayCount = result[1].dayCount;
                  }
                  if (itemTableData.filter((x) => x.dayType === "GMApproveDay").length > 0) {
                    const index = itemTableData.findIndex((x) => x.dayType === "GMApproveDay");
                    itemTableData[index].dayCount = result[1].dayCount;
                  }

                  this.setState({ itemTableData, tableData, showDaysAlertBox: true, }, () => {
                    this.setslickSliderCount();
                  });
                } else {
                  if (tableData.filter((x) => x.dayType === "GMApproveDay").length > 0) {
                    const index = tableData.findIndex((x) => x.dayType === "GMApproveDay");
                    tableData.splice(index, 1);
                  }
                  if (itemTableData.filter((x) => x.dayType === "GMApproveDay").length > 0) {
                    const index = itemTableData.findIndex((x) => x.dayType === "GMApproveDay");
                    itemTableData.splice(index, 1);
                  }
                  this.setState({ itemTableData, tableData, showDaysAlertBox: true, });
                }
              } else {
                if (tableData.filter((x) => x.dayType === "ApproveDay").length > 0) {
                  const index = tableData.findIndex((x) => x.dayType === "ApproveDay");
                  tableData.splice(index, 1);
                }
                if (itemTableData.filter((x) => x.dayType === "ApproveDay").length > 0) {
                  const index = itemTableData.findIndex((x) => x.dayType === "ApproveDay");
                  itemTableData.splice(index, 1);
                }
                this.setState({ itemTableData, tableData, isCheckComponent: "", }, () => {
                  this.setslickSliderCount();
                });
              }

            }
            resolve();
          })
          .catch((error) => {
            this.setState({ loaderTrue: false });
            Utils.toastError(`Server Error: ${error}`, {
            });
            reject();
          });
      }
    }
  };

  SmileIdAction = (event: string) => {

    let request = {} as ILaborDayApproveRequestDto;
    request.hotelID = this.state.hidValue;
    request.userUniqueNo = localStorageUser.user_UniqueID;
    LaborDayApprove.GetLaborSmileIDAlertBoxCount(request)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          let itemTableData = [...this.state.itemTableData];
          let tableData = [...this.state.tableData];

          if (result[0].dayCount > 0) {
            if (tableData.filter((x) => x.dayType === "SmileID").length > 0) {
              const index = tableData.findIndex((x) => x.dayType === "SmileID");
              tableData[index].dayCount = result[0].dayCount;
            }
            else {
              let newTableData: any = [];
              for (let i = 0; i <= tableData.length; i++) {
                if (i < this.state.smileIdIndex1) {
                  newTableData.push(tableData[i]);
                }
                else if (i == this.state.smileIdIndex1) {
                  newTableData.push(result[0]);
                }
                else if (i > this.state.smileIdIndex1) {
                  newTableData.push(tableData[i - 1]);
                }
              }
              tableData = newTableData;
            }
            if (itemTableData.filter((x) => x.dayType === "SmileID").length > 0) {
              const index = itemTableData.findIndex((x) => x.dayType === "SmileID");
              itemTableData[index].dayCount = result[0].dayCount;
            }
            else {
              let newitemTableData: any = [];
              for (let i = 0; i <= itemTableData.length; i++) {
                if (i < this.state.smileIdIndex2) {
                  newitemTableData.push(itemTableData[i]);
                }
                else if (i == this.state.smileIdIndex2) {
                  newitemTableData.push(result[0]);
                }
                else if (i > this.state.smileIdIndex2) {
                  newitemTableData.push(itemTableData[i - 1]);
                }
              }
              itemTableData = newitemTableData;
            }
            this.setState({ itemTableData, tableData, showDaysAlertBox: true, }, () => {
              this.setslickSliderCount();
            });
          }
          else {
            if (tableData.filter((x) => x.dayType === "SmileID").length > 0) {
              const index = tableData.findIndex((x) => x.dayType === "SmileID");
              this.setState({ smileIdIndex1: index });
              tableData.splice(index, 1);
            }
            if (itemTableData.filter((x) => x.dayType === "SmileID").length > 0) {
              const index = itemTableData.findIndex((x) => x.dayType === "SmileID");

              this.setState({ smileIdIndex2: index });
              itemTableData.splice(index, 1);
            }
            this.setState({ itemTableData, tableData, }, () => {
              this.setslickSliderCount();
            });
          }
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ loaderTrue: false });
        Utils.toastError(`Server Error: ${error}`, {
        });
        reject();
      });
  };

  smileIdChecked = (hotelLetterCode: any, hotelID: any) => {
    let request = {} as ILaborSmileIdRequestDto;
    request.hotelID = hotelID;
    this.setState(
      {
        isCheckComponent: "smileIdOverviewDetails",
        isLetterCode: hotelLetterCode,
        isLetterId: hotelID,
      },
      () => {
        this.simeIdOverviewDetailsChild.current.smileIdOverviewAccountsDetails(
          request,
          "match%",
          "rowClicked"
        );
      }
    );
  };

  roomAssignmentsHID = (hotelLetterCode: any, hotelID: any) => {
    let request = {} as any;
    request.hotelID = hotelID;
    this.setState(
      {
        isCheckComponent: "RoomAssignments",
        isLetterCode: hotelLetterCode,
        isLetterId: hotelID,
      },
      () => {
        this.refRoomAssignments.current.getHouseKeepingRoomOccupied(request);
      }
    );
  };

  housekeepingApprovalHID = (hotelLetterCode: any, hotelID: any) => {
    let request = {} as any;
    request.hotelID = hotelID;
    this.setState(
      {
        isCheckComponent: "HousekeepingApprovals",
        isLetterCode: hotelLetterCode,
        isLetterId: hotelID,
      },
      () => {
        this.refHousekeepingApprovals.current.getHousekeepingApprovals(request);
      }
    );
  };

  hideSlideOut() {
   
    this.setState({ isSlideOutShow: false }, () => {
      
      if (this.laborDayHotelApprovalDetailsChild.current !== null) {
        this.laborDayHotelApprovalDetailsChild.current.refreshChild();
      }
    });
  }
  hideTimesheetSlideOut() {
    this.setState({ timesheetSlideOutShow: false }, () => {
    });
  }
  hideExportedDate = () => {
    this.setState({ exportedDate: "" });
  };

  slideOutRefresh = () => {
    this.getTimeSheetOverViewDetails(
      this.state.slideOutData.hotelID,
      this.state.slideOutData.date,
      "slideOutRefresh"
    );
  };

  getTimeSheetOverViewDetails = (
    hotelID: number,
    selectedDate: any,
    callFrom: string
  ) => {
    if (
      callFrom === "addEntryRefresh" ||
      callFrom === "timeSheetRefresh" ||
      callFrom === "slideOutRefresh"
    ) {
    } else {
      this.setState({ pageLoader: true });
    }

    LaborTimeSheet.timeSheetOverViewDetails(hotelID, selectedDate)
      .then(async (result: any[] | null) => {
        if (result != null) {
          this.setState({
            filteredTimesheetDetails: result,
            timesheetDetails: result,
          });
          if (result.length > 0) {
            this.setState({
              totalHours: result[0].grandTotal,
              totalRegular: result[0].grandRegularTotal,
              totalOverTime: result[0].grandOverTotal,
              totalDoubleOverTime: result[0].grandDoubleOverTotal,
              totalTimeOff: result[0].grandPTOTotal,
              totalHoliday: result[0].grandHolidayTotal,
              totalUTO: result[0].grandUTOTotal,
              isHolidayShow:
                result[0].grandHolidayTotal === "0:00" ? false : true,
              isUTOShow: result[0].grandUTOTotal === "0:00" ? false : true,
            });
          } else {
            this.setState({
              totalHours: "0:00",
              totalRegular: "0:00",
              totalOverTime: "0:00",
              totalDoubleOverTime: "0:00",
              totalTimeOff: "0:00",
              totalHoliday: "0:00",
              totalUTO: "0:00",
              isHolidayShow: false,
              isUTOShow: false,
            });
          }
        }
        this.setState({ pageLoader: false });
        resolve();
      })
      .catch((err) => {
        this.setState({ pageLoader: false });
        Utils.toastError(`Server Error: ${err}`);
        reject();
      });
  };

  handlePositionDetail(row: any) {    
    let ScheduleNo = 0;
    let Employee = ""
    let PositionId = 0;
    let DeptId = 0
    if (row?.scheduleNo) {
      ScheduleNo = row?.scheduleNo;
      Employee = row?.userName;
    } else {
      if (row?.parentName !== "overBudgetDepartment") {
        PositionId = row?.positionID;
        if (row?.budgetType === "UnderBudgetTabNew") {
          PositionId = row?.position;
        }

      }
      DeptId = row?.deptID;
      if (row?.budgetType === "UnderBudgetTabNew") {
        DeptId = row?.deptid;
      }


    }
    let budgetedHours = 0;
    let showBudgetedHours = false;
    let isCalenderLocked = false;
    let deptName = "";
    let positionName = "";
    let breadDepartments: any[] = [];
    let breadPositions: any[] = [];
    if (row?.budgetType === "UnderBudget" || row?.budgetType === "OverBudget") {
      budgetedHours = Utils.getMinutesFromHHMM((row?.bgtDeptTotal || row?.budgetTotal));
      showBudgetedHours = true;
      if (row?.hasOwnProperty("positionExpended")) {
        deptName = row?.departMentName
      } else {
        positionName = row?.positionName;
        breadDepartments.push({ id: DeptId, name: row?.departMentName });
      }
    }
    if (row?.budgetType === "UnderBudgetTabNew" || row?.budgetType === "OverBudgetTabNew") {
      budgetedHours = Utils.getMinutesFromHHMM((row?.realBudget || row?.realBudget));
      showBudgetedHours = true;
      if (row?.hasOwnProperty("positionExpended")) {
        deptName = row?.departmentName
      } else {
        positionName = row?.positionName;
        breadDepartments.push({ id: DeptId, name: row?.departmentName });
      }
    }


    let userName = "";
    if (row?.parentName === "autoPunchOut") {
      userName = row?.userName
      breadDepartments.push({ id: row?.deptID, name: row?.departMentName });
      breadPositions.push({ id: row?.positionID, name: row?.positionName });
      PositionId = row?.positionID;
      DeptId = row?.deptID;
    }
    isCalenderLocked = true;
    if (row?.hasOwnProperty("positionExpended")) {
      PositionId = 0;
    } else {
      PositionId = row?.positionID;
      if (row?.budgetType === "UnderBudgetTabNew") {
        PositionId = row?.position;
      }
    }
    let dataparameter = {
      ScheduleNo, Employee, DeptId,
      PositionId, Date: row?.date, HotelId: row?.hotelID, HotelName: row?.letterCode,
      budgetedHours, showBudgetedHours, userName, breadPositions, breadDepartments, deptName, positionName, isCalenderLocked
    };
    this.setState({ isSlideOutShow: true, positionDetailsRow: dataparameter });
  }

  hanldleTimesheetDetail(data: any) {
    let newItem: any = {};
    newItem.disputeStatus = "";
    newItem.hotelID = data.hotelID;
    newItem.hotelName = data.letterCode;
    newItem.userUniqueno = data.userUniqueno;
    newItem.userName = data.employeeName;
    newItem.payPeriod = "";
    newItem.date = data.date;
    newItem.ruleType = "Standard Rule"
    newItem.imagePath = "";
    newItem.position = "";
    newItem.fullDate = ["", ""];
    this.setState({ slideOutData: newItem, timesheetSlideOutShow: true });
  }

  searchClear = () => {
    this.setState({ search: "" });
    this.setState({ hidList: this.state.hidFilterList });
  };

  searchItems = (e: any) => {
    const { hidFilterList } = this.state;
    let result = e.target.value;
    this.setState({ search: result });
    if (result !== "") {
      this.setState({
        hidList: hidFilterList.filter((hName: any) =>
          hName.hotelName.toLowerCase().includes(result.toLowerCase().trim())
        ),
      });
    } else {
      this.setState({ hidList: this.state.hidFilterList });
    }
  };

  handleSelectedItem = (controlID: any, id: any) => {
    if (controlID === 1) {
      this.setState({ selectedPosition: id });
      this.FilterByDropDownList(id, this.state.selectedDepartment);
    } else {
      this.setState({ selectedDepartment: id });
      this.FilterByDropDownList(this.state.selectedPosition, id);
    }
  };

  FilterByDropDownList(positionId: number, departmentId: number) {
    this.setState({ webClockLoaderTrue: true });
    let tableDataSummery: any[] = [];
    tableDataSummery = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableDataFilter))
    );
    if (positionId > 0) {
      tableDataSummery = tableDataSummery.filter(
        (item) => item.position === positionId.toString()
      );
      this.setState({ tableDataWebClock: tableDataSummery });
    }
    if (departmentId > 0) {
      tableDataSummery = tableDataSummery.filter(
        (item) => item.deptID === departmentId
      );
      this.setState({ tableDataWebClock: tableDataSummery });
    }
    if (positionId <= 0 && departmentId <= 0) {
      this.setState({ tableDataWebClock: this.state.tableDataFilter });
    }
    this.setState({ webClockLoaderTrue: false });
  }

  getLaborManagerTimeClockData = (type: any) => {    
    if (this.state.hidValue === "" || this.state.calenderDisplayDate === "") {
      return false;
    } else {
      this.setState({ webClockLoaderTrue: true });
      let requestTimeClock = {} as any;
      requestTimeClock.Hid = this.state.hidValue;
      requestTimeClock.WeekStartDate = this.state.displayCurrentDay;
      requestTimeClock.WeekEndDate = this.state.displayCurrentDay;
      requestTimeClock.Date = this.state.calenderDisplayDate;
      requestTimeClock.FullTimeClock = this.state.fullTimeClockData;
      RoleSubPermissionSetting.GetLaborManagerTimeClockOverView(requestTimeClock)
        .then(async (result: any) => {
          if (result.message === "Success") {
            let apiResult = result.result;
            apiResult.map((items) => ((items["modalShow"] = false, items["punchTimeValue"] = "", items["punchCommentValue"] = "")))
            let weekStartDateList = apiResult.filter(
              (items: any) => items.employee === "Header"
            );
            let weekStartDate = null;
            let weekEndDate = null;
            if (weekStartDateList !== null) {
              weekStartDate = weekStartDateList[0].weekStartDate;
              weekEndDate = weekStartDateList[0].weekEndDate;
            }
            let apiResultList = apiResult.filter(
              (items: any) => items.employee !== "Header"
            );

            this.setState(
              {
                tableDataWebClock: apiResultList,
                tableDataFilter: apiResultList,
                tableStatusFilter: apiResultList,
                weekStartDate: weekStartDate,
                weekEndDate: weekEndDate,
                webClockLoaderTrue: false,
                fullTimeClockData: "No"
              },
              () => {
                if (
                  type === "scheduleEmployee" ||
                  type === "calenderDropDown"
                ) {
                  this.FilterByDropDownList(
                    this.state.selectedPosition,
                    this.state.selectedDepartment
                  );
                }
              }
            );
          }
          resolve();
        })
        .catch((err) => {
          this.setState({ webClockLoaderTrue: false });
          Utils.toastError(`Server Error, ${err}`, {
          });
          reject();
        });
    }
  };

  getPostionDepartment = (type: string) => {
    if (this.state.hidValue === "") {
      return false;
    } else {
      let requestPosDept = {} as ILaborScheduleDeptPositionRequestDto;
      requestPosDept.hotelID = this.state.hidValue;
      this.setState({ webClockLoaderTrue: true });
      WebTimeClockService.GetLaborPositionDeptOverView(requestPosDept).then(async (result: any) => {
        if (result.message === "Success") {
          let departMentDetails = [{ id: -1, name: "All" }];
          let positonDetails: any = [{ id: -1, name: "All" }];
          if (result.result.length > 0) {
            result.result[0].laborSchedulePosition.forEach((res: any) => {
              let positonObject: any = {};
              positonObject.id = res.positionID;
              positonObject.name = res.positionName;
              positonObject.deptid = res.deptID;
              positonDetails.push(positonObject);
            });
          }
          if (result.result.length > 0) {
            result.result[0].laborScheduleDepartment.forEach((res: any) => {
              let departMentObject: any = {};
              departMentObject.id = res.deptID;
              departMentObject.name = res.departmentName;
              departMentDetails.push(departMentObject);
            });
          }
          this.setState({
            positionList: positonDetails,
            departmentList: departMentDetails,
            webClockLoaderTrue: false,
          });
        }
        resolve();
      })
        .catch((err) => {
          this.setState({ webClockLoaderTrue: true });
          Utils.toastError(`Server Error, ${err}`, {
          });
          reject();
        });
    }
  };


  getHotelTime = () => {
    let request: any = {};
    request.HID = this.state.hidValue;
    User.GetHotelCurrentDateTime(request).then(async (response: any | null) => {
      if (response !== null) {
        let currentDate = new Date(response.result);
        let dateDay: any = currentDate.getDate();
        let mnth: any = currentDate.getMonth() + 1;
        let nyear: any = currentDate.getFullYear();
        let ndate: any =
          mnth + "/" + dateDay + "/" + nyear

        this.setState({ currentHotelTime: ndate, calenderDisplayDate: ndate, displayCurrentDay: ndate });
      } else {
        this.setState({ currentHotelTime: new Date().toISOString() });
      }
    })
  }

  getHotelTimeZoneCurrentDate = (type, row) => {
    let request: any = {};
    request.HID = this.state.hidValue;
    User.GetTimeZoneCurrentDateTime(request)
      .then(async (response: any | null) => {
        if (response !== null) {
          let data = response.result;
          let HDate = data.split("T")[0];

          let calcdate = HDate.replace(/-/g, "/");
          this.setState(
            { hotelTimeZoneCurrentDate: calcdate, }, () => {

              let requestObject: any = {};
              requestObject.Hotelid = this.state.hidValue;
              requestObject.UserName = row.employee;
              requestObject.DateTime24Hrs =
                this.state.hotelTimeZoneCurrentDate + " " + row.punchTimeValue;
              requestObject.CallingPage = "force";
              requestObject.ForceRemarks =
                "Forced " +
                this.setDefaultPunch(row) +
                ": " +
                row.punchCommentValue;
              if (type === "Punch In") {
                requestObject.IsPunchCall = "Yes";
                requestObject.ScheduleNo = row.uniqueno;
                requestObject.PageSource = "OverViewForcePunchIn";
                this.SaveEmployeeForcePunchIn(requestObject, row);
              } else {
                requestObject.IsPunchCall = "No";
                requestObject.ScheduleNo = row.mainUniqueno;
                requestObject.PageSource = "OverViewForcePunchOut";
                this.SaveEmployeeForcePunchOut(requestObject, row);
              }
            }
          );
        }
      })
      .catch((err: any) => {
        this.saveBtnRef.current.disabled = false;
        this.setState({ buttonSpinner: false });
        Utils.toastError(`Server Error, ${err}`, {
        });
        reject();
      });
  };
  isPunchOutTimeExceedCurrentTime = (outDate, outTime) => {
    let { currentHotelTime } = this.state;
    let currentTime;
    let formattedOutTime;
    let formattedCurrentTime;
    if (currentHotelTime !== '') {
      currentTime = new Date(currentHotelTime).toLocaleTimeString();
      formattedOutTime = moment(moment(outDate).format("DD/MM/YYYY") + " " + outTime, 'DD/MM/YYYY h:mma');
      formattedCurrentTime = moment(moment(currentHotelTime).format("DD/MM/YYYY") + " " + currentTime, 'DD/MM/YYYY h:mma');
      let isShow = formattedOutTime.isAfter(formattedCurrentTime);
      return isShow
    } else {
      return 0
    }
  }

  showUserPunch(e: any, row: any) {
    let { tableDataWebClock } = this.state;
    tableDataWebClock = tableDataWebClock.map((items) => {
      if (items.id === row.id) {
        return { ...items, modalShow: true };
      } else {
        return items;
      }
    });
    this.setState((curr: any) => ({ ...curr, tableDataWebClock }))
  }

  hideUserPunch(row: any) {
    if (document?.getElementById("performanceSlideOut")) {
      return;
    }
    let { tableDataWebClock } = this.state;
    tableDataWebClock = tableDataWebClock.map((items) => {
      if (items.id === row.id) {
        return {
          ...items,
          modalShow: false,
          punchTimeValue: "",
          punchCommentValue: "",
        };
      } else {
        return items;
      }
    });
    this.setState((curr: any) => ({ ...curr, tableDataWebClock }))
  }

  punchTimeChange(e: any, row: any, rowIndex: any) {
    let { value } = e.target;
    let { tableDataWebClock } = this.state;
    tableDataWebClock = tableDataWebClock.map((items) => {
      if (items.id === row.id) {
        return { ...items, punchTimeValue: value };
      } else {
        return items;
      }
    });
    this.setState((curr: any) => ({ ...curr, tableDataWebClock }))
  }

  commentChange(e: any, row: any, rowIndex: any) {
    let { value } = e.target;
    let { tableDataWebClock } = this.state;
    tableDataWebClock = tableDataWebClock.map((items) => {
      if (items.id === row.id) {
        return { ...items, punchCommentValue: value.trimStart() };
      } else {
        return items;
      }
    });
    this.setState((curr: any) => ({ ...curr, tableDataWebClock }))
  }

  commentValidate(row) {
    if (row.punchCommentValue === "" || row.punchCommentValue === undefined) {
      Utils.toastError("Please enter explanation.", {
      });
    } else {
      return row.punchCommentValue;
    }
  };

  submitPunch(event, row, type) {
    event.preventDefault();
    if (this.commentValidate(row)) {
      this.saveBtnRef.current.disabled = true;

      this.getHotelTimeZoneCurrentDate(type, row)
    }
  }

  SaveEmployeeForcePunchIn(requestObject, row) {
    let { tableDataWebClock } = this.state;
    RoleSubPermissionSetting.SaveEmployeeForcePunchIn(requestObject)
      .then(async (resultPermission: any | null) => {
        if (resultPermission.message.toLowerCase() === "success") {
          if (resultPermission.result !== null) {
            let statusResponse = resultPermission.result.result.status;
            if (statusResponse.toLowerCase() === "success") {
              toast.success("Punch in successfully", {
              });
              this.getLaborManagerTimeClockData("d");
            } else if (statusResponse.toLowerCase() === "invalidintime") {
              Utils.toastError("Punch-in time can not be less than actual time", {
              });
            } else if (statusResponse.toLowerCase() === "noschedule") {
              Utils.toastError(statusResponse, {
              });
            } else if (statusResponse.toLowerCase() === "already punchin") {
              Utils.toastError(statusResponse, {
              });
            } else if (statusResponse.toLowerCase() === "invalidhid") {
              Utils.toastError(statusResponse, {
              });
            } else if (statusResponse.toLowerCase() === "invalidemployee") {
              Utils.toastError(statusResponse, {
              });
            } else if (statusResponse.toLowerCase() === "overlapped") {
              Utils.toastError(statusResponse, {
              });
            } else {
              Utils.toastError(statusResponse, {
              });
            }
            tableDataWebClock = tableDataWebClock.map((items) => {
              if (items.id === row.id) {
                return { ...items, punchTimeValue: "", punchCommentValue: "", modalShow: false };
              } else {
                return items;
              }
            });
            this.setState((curr: any) => ({ ...curr, tableDataWebClock }));
            $(".dropdown.show").trigger("click");
          }
        } else {
          this.saveBtnRef.current.disabled = false;
          Utils.toastError(resultPermission.message, {
          });
        }
      })
      .catch((err: any) => {
        this.saveBtnRef.current.disabled = false;
        this.setState({ buttonSpinner: false });
        Utils.toastError(`Server Error, ${err}`, {
        });
        reject();
      });
  }

  SaveEmployeeForcePunchOut(requestObject, row) {
    let { tableDataWebClock } = this.state;
    RoleSubPermissionSetting.SaveEmployeeForcePunchOut(requestObject)
      .then(async (resultPermission: any | null) => {
        if (resultPermission.message.toLowerCase() === "success") {
          if (resultPermission.result !== null) {
            let statusResponse = resultPermission.result.result.status;
            if (statusResponse.toLowerCase() === "success") {
              toast.success("Punch Out successfully", {
              });
              this.getLaborManagerTimeClockData("d");
            } else if (statusResponse.toLowerCase() === "invalidouttime") {
              Utils.toastError("Punch-Out time can not be greater than actual time", {
              });
            } else if (statusResponse.toLowerCase() === "lessthanintime") {
              Utils.toastError("Punch-Out time can not be less than punch-in time", {
              });
            } else if (statusResponse.toLowerCase() === "noschedule") {
              Utils.toastError(statusResponse, {
              });
            } else if (statusResponse.toLowerCase() === "already punchin") {
              Utils.toastError(statusResponse, {
              });
            } else if (statusResponse.toLowerCase() === "invalidhid") {
              Utils.toastError(statusResponse, {
              });
            } else if (statusResponse.toLowerCase() === "invalidemployee") {
              Utils.toastError(statusResponse, {
              });
            } else if (statusResponse.toLowerCase() === "overlapped") {
              Utils.toastError(statusResponse, {
              });
            } else {
              Utils.toastError(statusResponse, {
              });
            }
            tableDataWebClock = tableDataWebClock.map((items) => {
              if (items.id === row.id) {
                return { ...items, punchTimeValue: "", punchCommentValue: "", modalShow: false };
              } else {
                return items;
              }
            });
            this.setState((curr: any) => ({ ...curr, tableDataWebClock }));
            $(".dropdown.show").trigger("click");
          }
        } else {
          this.saveBtnRef.current.disabled = false;
          Utils.toastError(resultPermission.message, {
          });
        }
      })
      .catch((err: any) => {
        this.saveBtnRef.current.disabled = false;
        this.setState({ buttonSpinner: false });
        Utils.toastError(`Server Error, ${err}`, {
        });
        reject();
      });
  }

  setDefaultPunch(items) {
    if (items.actIntime === null && items.actOutTime === null) {
      return "Punch In"
    } else if (items.actIntime !== null && items.actOutTime === null) {
      return "Punch Out"
    } else if (items.actIntime !== null && items.actOutTime !== null) {
      return "Punch In"
    }
  }

  /* New TimeClock */
  updateTimeClock = () => {
    this.setState({ activeComparision: '', isTodayactive: true, isCheckComponent: "" }, () => {
      this.getLaborManagerTimeClockData("d");
      if (this.state.hidList.length > 0 && this.state.hidList.length < 2) { this.getHotelTime(); }
    })
  }

  quickAddShift = () => {
    let currentDay: any = new Date();
    let currentdate = currentDay.getDate();
    let currentMonth = currentDay.getMonth() + 1;
    let currentYear = currentDay.getFullYear();
    let tempDate = currentMonth + "/" + currentdate + "/" + currentYear;
    let requestObject: any = {};
    requestObject.hotelId = Number(this.state.hidValue === '' ? 0 : this.state.hidValue);
    requestObject.date = tempDate;

    RoleSubPermissionSetting.CheckForecast(requestObject)
      .then(async (result: any | null) => {
        let apiResult = result.result[0];
        if (apiResult !== null) {
          if (result.success) {
            let apiMessage = apiResult.result.toString()
            if (apiMessage.toLowerCase() === "No".toLocaleLowerCase()) {
              Utils.toastError(`No forecast set for this week. You must first create a forecast before you can create a schedule.`, {
              });
            } else if (apiMessage.toLowerCase() === "Incomplete".toLocaleLowerCase()) {
              Utils.toastError(`No forecast set for this week. An error occurred during a previous forecast import attempt.`, {
              });
            } else if (apiMessage.toLowerCase() === "Yes".toLocaleLowerCase()) {
              this.addEditShiftModalShow();
            }
          } else {
            Utils.toastError(`Error.`, {
            });
          }
        }
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
        });
        reject();
      });
  }

  addEditShiftModalHide = () => {
    this.setState({
      addEditShiftModal: false,
      addEdiShiftValues: {},
    });
  };

  addEditShiftModalShow = () => {
    let addEditObject: any = {};
    addEditObject.formType = "add";
    addEditObject.selectedSchedule = "addSchedule";
    addEditObject.scheduleAssignedType = "Assigned";
    addEditObject.selectedRow = "";
    addEditObject.selectedDay = "";
    this.setState({
      addEditShiftModal: true,
      addEdiShiftValues: addEditObject,
    });
  };

  changeStatusDropdown = (item) => {
    let { tableStatusFilter } = this.state;
    if (item === "All") {
      this.setState({ defaultSelectedStatus: item, tableDataWebClock: tableStatusFilter })
    } else {
      this.setState({
        defaultSelectedStatus: item,
        tableDataWebClock: tableStatusFilter.filter(items => items.status === item)
      });
    }
  }

  showFullTimeClock = () => {
    this.setState({ fullTimeClockData: "Yes", defaultSelectedStatus: "Shift status", }, () => {
      this.getLaborManagerTimeClockData("d");
      if (this.state.hidList.length > 0 && this.state.hidList.length < 2) { this.getHotelTime(); }
    })
  }

  setTimeClockShift = (items) => {
    let className: any = "";
    switch (items) {
      case "Scheduled":
        className = "badge color-grey";
        break;
      case "All":
        className = "pl-2";
        break;
      case "Punched In":
        className = "badge color-green";
        break;
      case "No Show":
        className = "badge color-orange";
        break;
      case "Punched In Late":
        className = "badge color-orange";
        break;
      case "Punched Out":
        className = "badge color-blue";
        break;
      case "Late":
        className = "badge color-orange";
        break;
      default:
        className = "badge color-grey";
        break;
    }
    return (
      <>
        <Dropdown.Item
          eventKey={items}
          onClick={() => this.changeStatusDropdown(items)}
          key={items.id}><span className={className}>{items}</span></Dropdown.Item>
        {items === "All" && (
          <Dropdown.Divider className="m-0" />
        )}
      </>
    )
  }

  handleAlertPreference = () => {
    localStorage.setItem("mainMenu", "Profile");
    localStorage.setItem("subMenu", "Alert Preferences");
  }

  /* New TimeClock */


  AlertBoxAscdesc = (tableData: any) => {
    tableData.sort((a, b) => {
      return a.ordertype - b.ordertype;
    });
    return tableData;
  }

  render() {
    let daycountlen = this.state.tableData.filter(e => e.dayCount > 0).length
    const settings = {
      className: "slider variable-width",
      dots: false,
      infinite: false,
      arrows: true,
      speed: 500,
      slidesToShow: this.state.scrollItemList,
      slidesToScroll: this.state.scrollItemList,
      initialSlide: 0,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };
    let { isTodayactive } = this.state
    const columns = [
      {
        dataField: "employee",
        editable: false,
        searchable: true,
        formatter: (cell: any, row: any, rowIndex: any) => {
          let className: any = "";
          switch (row.status) {
            case "Punched In":
              className = "hasLiveGreenCircle";
              break;
            case "Punched In Late":
              className = "hasLiveGreenCircle";
              break;
            default:
              className = "";
              break;
          }
          return (
            <div className="employee-card d-flex">
              <div className={`${className} employee-img`}>
                <img
                  src={
                    row.imagePath === null || row.imagePath.trim() === ""
                      ? profile
                      : row.imagePath
                  }
                  className={
                    row.imagePath === null || row.imagePath.trim() === ""
                      ? "default"
                      : ""
                  }
                  alt={row.employee}
                />
                {className && (
                  <span className="hasLiveIcon">
                    <svg width="25" height="25" viewBox="0 0 20 20" fill="#ffffff" xmlns="http://www.w3.org/2000/svg"><path d="M10.8332 5.83331H9.1665V10.345L11.9107 13.0891L13.089 11.9108L10.8332 9.65498V5.83331Z"></path></svg>
                  </span>
                )}
              </div>
              <div className="employee-details d-flex">
                <EllipsisWithTooltip placement="bottom">
                  <span className="employee-name">{row.employee}</span>
                </EllipsisWithTooltip>
                <EllipsisWithTooltip placement="bottom">
                  <span className="employee-position">{row.positionName}</span>
                </EllipsisWithTooltip>
              </div>
            </div>
          )
        },
      },
      {
        dataField: "status",
        editable: false,
        searchable: false,
        formatter: (cell: any, row: any, rowIndex: any) => {
          let className: any = "";
          switch (row.status) {
            case "Scheduled":
              className = "badge color-grey";
              break;
            case "Punched In":
              className = "badge color-green";
              break;
            case "No Show":
              className = "badge color-orange";
              break;
            case "Punched In Late":
              className = "badge color-orange";
              break;
            case "Late":
              className = "badge color-orange";
              break;
            case "Punched Out":
              className = "badge color-blue";
              break;
            default:
              className = "badge color-grey";
              break;
          }
          return <div className="TChasBorderRight"><span className={className}>{row.status}</span></div>;
        },
      },
      {
        dataField: "displaySchedule",
        editable: false,
        searchable: false,
        formatter: (cell: any, row: any, rowIndex: any) => {
          let className: any = "";
          switch (row.status) {
            case "Late":
              className = "colorB12A18";
              break;
            default:
              className = "";
              break;
          }
          return (
            <div className="shift-info d-flex">
              <span className={`${className} shift-info-time`}>{row.displaySchedule}</span>
              <span className="shift-info-subtitle">Schedule</span>
            </div>
          );
        },
      },
      {
        dataField: "displayActual",
        text: "",
        editable: false,
        searchable: false,
        formatter: (cell: any, row: any, rowIndex: any) => {
          let className: any = "";
          switch (row.status) {
            case "Punched In Late":
              className = "colorB12A18";
              break;
            default:
              className = "";
              break;
          }
          return (
            <div className="shift-info-actual d-flex">
              <span className={`${className} shift-info-actual-total`}>
                {row.displayActual}
              </span>
              {row.displayActual !== "-" && (
                <span className="shift-info-actual-subtitle">Actual</span>
              )}
            </div>
          );
        },
      },
      {
        dataField: "action",
        editable: false,
        searchable: false,
        hidden: !this.state.showForcePunch,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (<>{
            ((this.isPunchOutTimeExceedCurrentTime(row?.outDate, row?.displaySchedule?.split("-")[1]?.trim()) || (row.status === "Punched In")) ||
              (this.isPunchOutTimeExceedCurrentTime(row?.outDate, row?.displaySchedule?.split("-")[1]?.trim()) || (row.status === "Punched In Late"))) && (
              <div className="OverviewUserPunchedDetails">
                {row.status !== "No Show" && (
                  <Dropdown
                    // onClick={(e) => {
                    //   e.stopPropagation();
                    // }}
                    className="more-action"
                    alignRight
                  >
                    <Dropdown.Toggle className="btn-outline-primary btn btn-primary btn-ghost more" id="dropdown-more">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {!row.modalShow ? (<span onClick={(e: any) => this.showUserPunch(e, row)}>{this.setDefaultPunch(row)}</span>) : (
                        <div className="OverviewUserPunched">
                          <OutsideClickHandler onOutsideClick={(e: any) => this.hideUserPunch(row)}>
                            <form noValidate onSubmit={(e) => this.submitPunch(e, row, this.setDefaultPunch(row))}>
                              <div className="list list-header">
                                <div className="ttl">
                                  Enter explanation for forced {this.setDefaultPunch(row)?.toLocaleLowerCase()}
                                </div>
                              </div>
                              <div className="pading">
                                <div className="list">
                                  <div className="ttl">{this.setDefaultPunch(row)} Time</div>
                                  <div className="control">
                                    <input
                                      type="time"
                                      id="inTimedupappt"
                                      name="inTimedupappt"
                                      className="html-time-picker"
                                      value={row.punchTimeValue}
                                      onChange={(e: any) =>
                                        this.punchTimeChange(e, row, rowIndex)
                                      }
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="list">
                                  <div className="ttl">Notes</div>
                                  <div className="control">
                                    <Form.Control
                                      className="input-notes"
                                      as="textarea"
                                      placeholder="Explanation required..."
                                      value={row.punchCommentValue}
                                      onChange={(e: any) =>
                                        this.commentChange(e, row, rowIndex)
                                      }
                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="footer d-flex justify-content-end">
                                <Dropdown.Item className="btn btnClose" >Close</Dropdown.Item>
                                &nbsp;
                                <button ref={this.saveBtnRef} type="submit" className="btn btn-primary">Save</button>
                              </div>
                            </form>
                          </OutsideClickHandler>
                        </div>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            )}
          </>
          )
        },
      },
    ];

    if (this.state.isPayrollRedicet) {
      return (
        <Redirect
          to={{
            pathname: "/labor-management/payroll-export",
            state: this.state.redirectState,
          }}
        />
      );
    } else if (this.state.isToffRRedicet) {
      return (
        <Redirect
          to={{
            pathname: "/labor-management/timeoffrequest",
            state: this.state.redirectState,
          }}
        />
      );
    } else if (this.state.isHolidayRedirect) {
      return (
        <Redirect
          to={{
            pathname: "/labor-settings/Holidays",
            state: this.state.redirectState,
          }}
        />
      );
    }

    return (
      <>
        {this.state.isSlideOutShow && (
        <div className="performance-slideout-wrapper">
          <PerformanceSlideout
            hotelName={this.state.positionDetailsRow?.HotelName}
            DataParameter={this.state.positionDetailsRow}
            hideSlideOut={this.hideSlideOut.bind(this)}
          />
          </div>
        )}
        {this.state.timesheetSlideOutShow && (
          <LaborTimesheetDetail
            data={this.state.slideOutData}
            hideSlideOut={this.hideTimesheetSlideOut.bind(this)}
            hideExportedDate={this.hideExportedDate.bind(this)}
            slideOutRefresh={this.slideOutRefresh}
          />
        )}

        <div className="laborMgmgtApproval" onClick={this.searchClear}>
          <Container fluid className="body-sec">
            <div className="page-heading underline d-flex">
              <Dropdown className="hid-select">
                <Dropdown.Toggle
                  id="dropdown-hid"
                  disabled={this.state.loaderTrue || this.state.apisCalledCount != noOfApis}
                >
                  <div className="d-flex align-items-center justify-content-center">
                    {this.state.hotelName}
                    <div className="drop-arrow d-flex">
                      <FiChevronDown />
                    </div>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu className="cusEhidSelector">
                  <div className="EhidSearch">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Control
                        type="text"
                        name="search"
                        value={this.state.search}
                        autoComplete="off"
                        onChange={this.searchItems}
                        placeholder="Filter EHIDs"
                      />
                    </Form.Group>
                  </div>
                  <div className="dropdownItems">
                    {this.state.hidList.length > 0 ? (
                      this.state.hidList.map(
                        (item: any, idx: string | number | undefined) => (
                          <React.Fragment key={idx}>
                            <Dropdown.Item
                              eventKey={item.lettercode}
                              onClick={() => this.getBudget(item)}
                            >
                              <div className={item.lettercode === this.state.hotelName ? "dropDownListTitle hasCheck" : "dropDownListTitle"}>
                                <span className="name">
                                  {item.hotelName.split(" - ")[0]}
                                </span>
                                <span className="title">
                                  {item.hotelName.replace(" - ", ".").split(".")[1]}
                                </span>
                                {item.lettercode === this.state.hotelName && (
                                  <span className="itemcheck">
                                    <svg
                                      width="20"
                                      height="20"
                                      viewBox="0 0 20 20"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
                                        fill="#2CAF92"
                                      />
                                    </svg>
                                  </span>
                                )}
                              </div>
                            </Dropdown.Item>
                            {item.lettercode === "All" && <Dropdown.Divider />}
                          </React.Fragment>
                        )
                      )
                    ) : (
                      <Dropdown.Item>
                        <b>No Item Found</b>
                      </Dropdown.Item>
                    )}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
              <div className="mr-auto">Labor Overview</div>
              <div className="action-group d-flex">
                <Dropdown className="more-action three-dot" alignRight>
                  <Dropdown.Toggle className="btn-outline-primary btn btn-primary more" id="dropdown-more">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                      <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                      <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                    </svg>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="alertPreferences"
                      as={Link}
                      to="/alertPreferences"
                      onClick={() => this.handleAlertPreference()}>
                      Alert Preferences

                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            {this.state.loaderTrue ? (
              <ReactPageLoader useas={"overviewBox"} />
            ) : (
              <>
                {this.state.isRecordExist && (
                  <div className="no-alert-box d-flex justify-content-center align-items-center">
                    <img src={NoAlerts} alt="No Alert" />
                  </div>
                )}

                <div className="slider-option">
                  <div
                    className={
                      this.state.isCheckComponent !== ""
                        ? "optionBoxCont d-flex"
                        : "optionBoxCont d-flex underline"
                    }
                  >
                    <div className="stickey-area">
                      <div className={`${isTodayactive ? "optionBox d-flex align-items-center active" : "optionBox d-flex align-items-center"}`}
                        onClick={() => this.updateTimeClock()}>
                        <div className="circle d-flex align-items-center justify-content-center">
                          <svg width="30" height="30" viewBox="0 0 20 20" fill="" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.99984 1.66669C5.40484 1.66669 1.6665 5.40502 1.6665 10C1.6665 14.595 5.40484 18.3334 9.99984 18.3334C14.5948 18.3334 18.3332 14.595 18.3332 10C18.3332 5.40502 14.5948 1.66669 9.99984 1.66669ZM9.99984 16.6667C6.32401 16.6667 3.33317 13.6759 3.33317 10C3.33317 6.32419 6.32401 3.33335 9.99984 3.33335C13.6757 3.33335 16.6665 6.32419 16.6665 10C16.6665 13.6759 13.6757 16.6667 9.99984 16.6667Z" fill="#065A8C"></path>
                            <path d="M10.8332 5.83331H9.1665V10.345L11.9107 13.0891L13.089 11.9108L10.8332 9.65498V5.83331Z" fill="#065A8C"></path>
                          </svg>
                        </div>
                        <div className="label-sec">Today</div>
                      </div>
                    </div>

                    <div className="separator"></div>
                    {(this.state.tableData.length <= 0 && this.state.apisCalledCount !== noOfApis) && (
                      <div className="box-loader loader-responsive d-flex">
                        <div className="box loadingAnimation"></div>
                        <div className="box loadingAnimation"></div>
                      </div>
                    )}

                    {this.state.tableData.length > 0 &&
                      this.state.showDaysAlertBox &&
                      !this.state.isRecordExist && (
                        <>
                          {daycountlen > 0 && (
                            <div className="scroll-area" ref={this.hasPageWidth} style={{ width: `${this.state.scrollWidth}px` }}>
                              <div className="slick-arrow slick-prev slick-disabled d-flex justify-content-center align-items-center shadow-remove">
                                <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M2.03029 9.28026L6.31054 5.00001L2.03029 0.719757L0.969788 1.78026L4.18954 5.00001L0.969788 8.21976L2.03029 9.28026Z" fill="#2CAF92"></path>
                                </svg>
                              </div>
                              <div className="slick-arrow slick-next slick-disabled d-flex justify-content-center align-items-center shadow-remove">
                                <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M2.03029 9.28026L6.31054 5.00001L2.03029 0.719757L0.969788 1.78026L4.18954 5.00001L0.969788 8.21976L2.03029 9.28026Z" fill="#2CAF92"></path>
                                </svg>
                              </div>
                              <Slider {...settings}>
                                {this.state.tableData.map((data: any, key: any) => data.dayCount !== 0 && (
                                  <div style={{ width: 250 }}
                                    className={`optionBox d-flex align-items-center ${this.state.activeComparision === data.dayType ? "active" : ""} ${data.dayType === "TotalHideBox" ? "hiddenCountBoxes" : ""}`}

                                   

                                    onClick={(e) => data.dayType != "TotalHideBox" ? this.optionBoxClick(data.dayType, data.dayTypeName) : e.stopPropagation()}
                                    key={key}
                                 
                                  >
                                    {data.dayType != "TotalHideBox" && (
                                      <div className="circle d-flex align-items-center justify-content-center">{data.dayCount}</div>
                                    )}

                                    <div className="label-sec" onClick={(e) => data.dayType === "TotalHideBox" ? this.showHidden() : ""}>
                                      {data.dayType === "TotalHideBox" ? `Show ${data.dayCount} Hidden` : data.dayTypeName}
                                     
                                    </div>
                                  </div>
                                )
                                )}
                              </Slider>
                            </div>
                          )}
                        </>
                      )}
                    {daycountlen <= 0 && this.state.apisCalledCount === noOfApis && (
                      <div className="caught d-flex align-items-center">
                        <img src={caught} alt="You’re all caught up!" />
                        <div className="label-sec"> You’re all caught up!</div>
                      </div>
                    )}


                  </div>
                </div>


                {this.state.isCheckComponent === "laborDayOverviewDetails" && !isTodayactive && !this.state.featureFlag && (
                  <LaborDayOverviewDetails
                    ref={this.laborDayOverviewDetailsChild}
                    laborOverviewChecked={this.laborOverviewChecked}
                  />
                )}
                {this.state.isCheckComponent === "laborDayOverviewDetails" && !isTodayactive && this.state.featureFlag && (
                  <LaborDayOverviewDetailsNew
                    ref={this.laborDayOverviewDetailsChild}
                    parentState={this.state}
                    laborOverviewChecked={this.laborOverviewChecked}
                  />
                )}

                {this.state.isCheckComponent === "breakExceptionsAll_Details" && !isTodayactive && (
                  <BreakException
                    ref={this.breakExceptionsDetails}
                    laborOverviewChecked={this.laborOverviewChecked}
                  />
                )}

                {this.state.isCheckComponent === "laborDayApproval" && !isTodayactive && !this.state.featureFlag && (
                  <LaborDayApproval
                    ref={this.laborDayHotelApprovalDetailsChild}
                    parentState={this.state}
                    laborDayApprovalBreadCrumbs={
                      this.laborDayApprovalBreadCrumbs
                    }
                    laborDayApproval={this.laborDayApproval}
                    positionDetail={this.handlePositionDetail.bind(this)}
                  />
                )}

                {this.state.isCheckComponent === "laborDayApproval" && !isTodayactive && this.state.featureFlag && (
                  <LaborDayApprovalNew
                    ref={this.laborDayHotelApprovalDetailsChild}
                    parentState={this.state}
                    laborDayApprovalBreadCrumbs={
                      this.laborDayApprovalBreadCrumbs
                    }
                    laborDayApproval={this.laborDayApproval}
                    positionDetail={this.handlePositionDetail.bind(this)}
                  />
                )}

                {this.state.isCheckComponent === "breakExceptionHID_Details" && !isTodayactive && (
                  <BreakExceptionDetails
                    ref={this.breakExceptionsChildDetails}
                    parentState={this.state}
                    laborDayApprovalBreadCrumbs={
                      this.breakExceptionsBreadCrumbs
                    }
                    laborDayApproval={this.laborDayApproval}
                    positionDetail={this.hanldleTimesheetDetail.bind(this)}
                    laborOverviewChecked={this.laborOverviewChecked}
                  />
                )}

                {this.state.isCheckComponent === "smileIdOverview" && (
                  <SmileIdOverview
                    ref={this.simeIdOverviewChild}
                    smileIdChecked={this.smileIdChecked}
                    parentState={this.state}
                  />
                )}

                {this.state.isCheckComponent === "smileIdOverviewDetails" && !isTodayactive && (
                  <SmileIdOverviewDetails
                    ref={this.simeIdOverviewDetailsChild}
                    smileIdBreadCrumbs={this.smileIdBreadCrumbs}
                    parentState={this.state}
                    SmileIdAction={this.SmileIdAction}
                  />
                )}

                {this.state.isCheckComponent === "RoomAssignmentsHID" && !isTodayactive && (
                  <RoomAssignmentsHID
                    ref={this.refRoomAssignmentsHID}
                    roomAssignmentsHID={this.roomAssignmentsHID}
                    parentState={this.state}
                  />
                )}

                {this.state.isCheckComponent === "RoomAssignments" && !isTodayactive && (
                  <RoomAssignments
                    ref={this.refRoomAssignments}
                    parentState={this.state}
                    roomAssignmentBreadCrumbs={this.roomAssignmentBreadCrumbs}
                    refreshBindApproveData={this.refreshRoomAssignmentsAlertData.bind(
                      this
                    )}
                  />
                )}
                {this.state.isCheckComponent === "HousekeepingApprovalHID" && !isTodayactive && (
                  <HousekeepingApprovalHID
                    ref={this.refHousekeepingApprovalHID}
                    housekeepingApprovalHID={this.housekeepingApprovalHID}
                    parentState={this.state}
                  />
                )}
                {this.state.isCheckComponent === "HousekeepingApprovals" && !isTodayactive && (
                  <HousekeepingApprovalsList
                    ref={this.refHousekeepingApprovals}
                    parentState={this.state}
                    refreshData={this.refreshData.bind(this)}
                    housekeepingApprovalHIDBreadCrumbs={
                      this.housekeepingApprovalHIDBreadCrumbs
                    }
                  />
                )}
                {this.state.isCheckComponent === "OtarPanelList" && !isTodayactive &&
                  this.state.overtimeRiskPanellr && (
                    <OverTimeAtRiskComponent
                      ref={this.refotarPanel}
                      parentState={this.state}
                      overtimeRiskPanelData={this.state.overtimeRiskPanelData}
                      changeOTARCount={this.changeOTARCount.bind(this)}
                      cusToastMessage={this.cusToastMessage.bind(this)}
                    />
                  )}
                {this.state.tableData.length > 0 &&
                  this.state.showDaysAlertBox &&
                  !this.state.isRecordExist && (
                    <>
                      {this.state.isCheckComponent === "PExportPanelList" && !isTodayactive && (
                        <PayrollExportOverview
                          ref={this.payrollExportPanel}
                          parentState={this.state}
                        />
                      )}
                      {this.state.isCheckComponent === "TimeOffRequestPanel" && !isTodayactive && (
                        <TimeoffRequestComponent
                          ref={this.TimeOffRequestPanel}
                          parentState={this.state}
                        />
                      )}
                    </>
                  )}
              </>
            )}

            {this.state.hidValue !== "All" &&
              this.state.hidValue !== "Select" &&
              this.state.isWebClockPermission && isTodayactive && (
                <>
                  {(this.state.loaderTrue || this.state.webClockLoaderTrue) && (this.state.isRecordExist || !this.state.isRecordExist) ? (
                    <>
                      <br></br>
                      <ReactPageLoader useas={"overViewWebClock"} />
                    </>
                  ) : (
                    <>
                      <br></br>
                      <Row>
                        <Col lg={9}>
                          <div className="time-clock-main">
                            <div
                              className="time-clock-container tccOvertimeContainer container-fluid2 p-0 m-0">
                              <div className="manager-view">
                                <div className="manager-table-view tdHasDropdown timeClockTable tctHasNoHeader">
                                  <ToolkitProvider
                                    keyField="rowNo"
                                    data={this.state.tableDataWebClock}
                                    columns={columns}
                                    search
                                  >
                                    {(props: {
                                      searchProps: JSX.IntrinsicAttributes;
                                      baseProps: JSX.IntrinsicAttributes;
                                    }) => (
                                      <>
                                        <div className="manager-navigation">
                                          <Row>
                                            <Col sm="3">
                                              <div className="timeClickLabel">
                                                <div className="hasLabel">
                                                  Time Clock</div>
                                              </div>
                                            </Col>
                                            <Col sm="9">
                                              <div className="LDOrowHasMultipleItems">
                                                <div className="department-filter">

                                                  <Dropdown className="more-action bg-blue single-select-image autoWidth">
                                                    <Dropdown.Toggle id="Modal-hid" className="filter-btn">
                                                      <div className="d-flex align-items-center">
                                                        <div className="txt-area">
                                                          <EllipsisWithTooltip placement="bottom">
                                                            {this.state.defaultSelectedStatus}
                                                          </EllipsisWithTooltip>
                                                        </div>
                                                        <div className="drop-arrow ml-0 d-flex">
                                                          <FiChevronDown />
                                                        </div>
                                                      </div>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                      {this.state.shiftStatus.map(items => (
                                                        <>{this.setTimeClockShift(items)}</>
                                                      ))}
                                                    </Dropdown.Menu>
                                                  </Dropdown>
                                                </div>
                                                <div className="department-filter">
                                                  {this.state.departmentList.length > 0 && (
                                                    <SingleSearchDropdownList
                                                      itemList={this.state.departmentList}
                                                      handleSelectedItem={this.handleSelectedItem.bind(
                                                        this,
                                                        2
                                                      )}
                                                      defaultItem={
                                                        this.state.departmentName
                                                      }
                                                      defaultText={"Search Department"}
                                                      defaultName={"Department"}
                                                      controlID="2"
                                                    />
                                                  )}
                                                </div>
                                                <div className="position-filter">
                                                  {this.state.positionList.length > 0 && (
                                                    <SingleSearchDropdownList
                                                      itemList={this.state.positionList}
                                                      handleSelectedItem={this.handleSelectedItem.bind(
                                                        this,
                                                        1
                                                      )}
                                                      defaultItem={this.state.positionName}
                                                      defaultText={"Search Position"}
                                                      defaultName={"Position"}
                                                      controlID="1"
                                                    />
                                                  )}
                                                </div>
                                                <div className="manager-table-search">
                                                  {/* <SearchBar
                                                    autocomplete="off"
                                                    {...props.searchProps}
                                                    placeholder="Search..."
                                                  /> */}
                                                  <TableSearch id="tableSearch" key="tableSearch"
                                                    {...props.searchProps}
                                                    placeholder={"Search..."}
                                                  />
                                                </div>
                                                {(this.state.isSchedulingPermission || this.state.permissionManageSchedule) && (
                                                  <div className="QuickAddBtn">
                                                    <Button
                                                      className="add-time-off-shift btn-outline-primary"
                                                      onClick={() => this.quickAddShift()}>Quick Add Shift</Button>
                                                  </div>
                                                )}
                                              </div>
                                            </Col>
                                          </Row>
                                        </div>
                                        {this.state.tableDataWebClock.length > 0 && (
                                          <div className="table tccOvertimeTable">
                                            <BootstrapTable
                                              {...props.baseProps}
                                              keyField="id"
                                              hover
                                              condensed
                                            />
                                          </div>
                                        )}
                                        <div className={`${this.state.tableDataWebClock.length === 0 ? "topMinMargin" : ''} manager-navigation-footer`}>
                                          <strong onClick={() => this.showFullTimeClock()}>Full Time Clock</strong>
                                        </div>
                                      </>
                                    )}
                                  </ToolkitProvider>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="managerPanel d-none">
                            <div className="managerHeader">
                              <div className="timeClickLabel">
                                <div className="hasLabel">
                                  Quick Action
                                </div>
                              </div>
                            </div>
                            <div className="managerBody"></div>
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              )}

          </Container>
          {this.state.hidValue !== "Select" &&
            this.state.addEditShiftModal && (
              <AddEditShiftModal
                parentState={this.state}
                addEditShiftModalHide={this.addEditShiftModalHide}
                getLaborManagerTimeClockData={this.getLaborManagerTimeClockData}
                component={this.state.permissionManageSchedule ? "laborDayOverviewSchedule" : "TimeClockOverviewOTF"}
              />
            )}
        </div>
      </>
    );
  }
}