import * as React from "react";
import {
  Container,
  Tabs,
  Tab,
  Dropdown,
  Tooltip,
  Form,
  OverlayTrigger,
} from "react-bootstrap";
import "react-input-range/lib/css/index.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { RolePermissionLeftPanel } from "../RolePermission/RolePermissionLeftPanel";
import { RolePermissionRightPanel } from "../RolePermission/RolePermissionRightPanel";
import { SpecialPermissionLeftPanel } from "../RolePermission/SpecialPermissionLeft";
import { SpecialPermissionRightPanel } from "../RolePermission/SpecialPermissionRight";
import RoleMatrix from "../RolePermission/RoleMatrix";
import {
  IRolePermissionResponseDto,
  IRoleTagResponseDto,
} from "../../../Common/Contracts/IRolePermission";
import { Roles, RolesforTreeView } from "../../../Common/Services/RolePermissionSetting";
import { confirmAlert } from "react-confirm-alert";
import { resolve, reject } from "q";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-toastify/dist/ReactToastify.css";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import UserManagementHierarchy from "../RolePermission/UserManagementHierarchy";
import RouteLeavingGuard from "../../../Common/Components/RouteLeavingGuard";
import MasterRoleLock from "../../../Common/Assets/Images/icons/forecast-lock.svg";
import { RoleSubPermissionSetting, IPageSubPermissionRole } from "../../../Common/Services/RoleSubPermissionSetting";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import _ from "lodash";
import { ForgotPassword as PwdService } from "../../../Common/Services/ForgotPasswordService";
import { SpecialPermissionEnterCode } from "../RolePermission/EnterCode";
import { UserManagement } from "../../../Common/Services/UserManagement";
import { IGeneralInfo } from "../../../Common/Contracts/IGeneralInfo";
import { User } from "../../../Common/Services/User";
import { IUser } from "../../../Common/Contracts/IUser";
import { LowSecurityRolesModal } from "./LowSecurityRolesModal";
import { OtpValidator } from "../../../Common/Components/OtpValidator"
import { Utils } from "../../../Common/Utilis";

let routeLeavingGuard: any = null;
let childRowValue: any; // var used to manage row clock
export class RolePermission extends React.Component<any, any> {
  private child: any;
  private childleft: any;
  private childSpecial: any;
  private childSpecialleft: any;
  private mypositionRef: any;
  private itemNameRef: any;
  public scrollRef;
  private ChildOTP: any;


  constructor(props: any) {
    super(props);
    this.child = React.createRef();
    this.childleft = React.createRef();
    this.childSpecial = React.createRef();
    this.childSpecialleft = React.createRef();
    this.mypositionRef = React.createRef();
    this.itemNameRef = React.createRef();
    this.scrollRef = React.createRef();
    this.ChildOTP= React.createRef();

    this.state = {
      isSaved: false,
      changeValue: false,
      Roles: [],
      SpecialPermissionList: [],
      key: "rolesPermission",
      //key: "matrix",
      scrollPageClass: "body-sec",
      isLeftPanelrefresh: true,
      roleListforDelete: [],
      LowSecurityList: [],
      handleChangeValue: "",
      scrollTop: 0,
      scrollToFix: false,
      roleTagName: [],
      isReportViewPermission: false,
      userGeneralInfo: {},
      fieldData: {
        roleID: 0,
        roleName: "",
        type: "",
        orderNo: 0,
        tenantID: 0,
        hotelID: 0,
        loaderMainTrue: false,
        isBtnDisable: true,
        search: "",
        isMasterRole: false,
        roleTag: "",
      },
      specialFieldData: {
        permissionID: 0,
        permissionName: "",
      },
      lowSecurityCheck: false,
      passCode: "",
      resetMode: "sms",
      enterCodeShow: false,
      userName: "",
      roleDataList: [],
      isLowSecurityRolesModal: false,
      isLowSecurityRole: false,
      featureStatus_LowSecurity: false,
      leftRowSelected: false
    };

    this.handleNewRole = this.handleNewRole.bind(this);
    this.refreshleftpanel = this.refreshleftpanel.bind(this);
    this.refreshleftpanelforNewRole =
      this.refreshleftpanelforNewRole.bind(this);

    this.removeBlankRow = this.removeBlankRow.bind(this);
    this.handleTabSelect = this.handleTabSelect.bind(this);
    this.isBydefaultSelectedRow = this.isBydefaultSelectedRow.bind(this);

    this.isValidateTab = this.isValidateTab.bind(this);
    this.getRoleData = this.getRoleData.bind(this);


  }


  componentDidMount() {
    // console.log(this.props);
    const { location } = this.props;
    // debugger;
    this.getVandorContractMergePermission();
    this.getReportPermission();
    this.getRoleData();
    this.getTagName();
    this.getSpecialPermissionData();
    this.getUserGeneralInfo();
    localStorage.setItem("eventType", JSON.stringify(""));
    localStorage.setItem("tabType", JSON.stringify(""));
    window.addEventListener("beforeunload", this.beforeunload.bind(this));
    if (location.state === "SpecialPermission") {
      window.history.replaceState(null, '');
      this.handleSelect("SpecialPermission")
    }
  }

  getUserGeneralInfo = (reDirectFromPayroll = false) => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let user_UniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    User.GetUserDetails(user_UniqueID).then(
      (userGeneralInfo: IUser) => {
        // debugger;
        this.setState({ userGeneralInfo });
      }
    );



  };

  getReportPermission = () => {
    let requestObject = {} as any;
    requestObject.permissionID = 28;
    requestObject.Module = "Accounting";
    requestObject.Type = "page";
    RoleSubPermissionSetting.GetPageSubPermissionDetails(requestObject)

      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          result.forEach((element: any) => {
            switch (element.permissionID) {
              case 2164:
                this.setState({ isReportViewPermission: true });
                break;
            }
          });
        }
        resolve();
      })
      .catch((err: any) => {
        reject();
      });
  };

  getTagName = () => {
    this.setState({ loaderMainTrue: true });

    Roles.GetRoleTags()
      .then(async (result: IRoleTagResponseDto[] | null) => {
        if (result != null) {
          let rawData = {
            id: 0,
            result: "NN;None",
          };
          this.setState({ roleTagName: result });
          this.state.roleTagName.splice(0, 0, rawData);
        }
        this.setState({ loaderMainTrue: false });
        resolve();
      })
      .catch((error) => {
        this.setState({ loaderMainTrue: false });
        reject();
      });
  };

  getVandorContractMergePermission = () => {
    let localStorageUser = JSON.parse(localStorage.getItem("storage")!);
    let requestObject = {} as IPageSubPermissionRole;
    requestObject.permissionID = 1003;
    requestObject.Module = "Accounting";
    requestObject.Type = "page";
    requestObject.RoleID = localStorageUser.rolId;
    requestObject.TenantID = localStorageUser.tenantID;
    let { featureStatus_LowSecurity } = this.state;
    featureStatus_LowSecurity = false
    this.setState({ featureStatus_LowSecurity });
    RoleSubPermissionSetting.GetPageSubPermissionWebClockDetails(requestObject)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          for (let index = 0; index < result.length; index++) {
            if (result[index].displayName.toString().toLowerCase() === "manage low security user roles") {
              featureStatus_LowSecurity = true;
            }
          }
        }
        this.setState({ featureStatus_LowSecurity });
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, { position: toast.POSITION.BOTTOM_RIGHT });
        reject();
      });
  };

  getRoleData = () => {
    this.GetPasswordAssistanceRole();
    this.setState({ loaderMainTrue: true });
    let RoleData: any[] = [];
    Roles.GetUserRoleList()
      .then(async (result: IRolePermissionResponseDto[] | null) => {
        if (result != null) {
          let roleData = result as [];
          roleData.map((res, index) => RoleData.push(res));

          const isLowSecurityFound = _.some(result, function (el) {
            return (el.lowSecurityStatus || el.isLowSecurityRole === "Yes");
          });

          // const isLowSecurityFound = result[0]?.lowSecurityStatus;


          //this.sortRolesName(RoleData);
          this.sortByKey(RoleData, "orderNo");
          this.setState({ Roles: RoleData, lowSecurityCheck: isLowSecurityFound });
          let new_state = Object.assign({}, this.state);
          let fieldData = new_state.fieldData;
          if (roleData.length > 0) {
            fieldData.hotelID = result[0].roleID;
          }
          fieldData.ishide = false;
          let isLowSecurityRole = RoleData.some(item => item?.isLowSecurityRole === "Yes")
          let LowSecurityList: any = RoleData;
          LowSecurityList.map((item) => ((item["label"] = item.roleName)));

          // let filterResult = LowSecurityList[0].lowSecurityStatus;
          // let filterResult = LowSecurityList.filter((item) => item.isLowSecurityRole === "Yes");

          this.setState({ roleListforDelete: RoleData, LowSecurityList, isLowSecurityRole });
          this.setState({ fieldData });
          if (this.state.isLeftPanelrefresh === true) {
            this.child.current.HideRightPanel();
          }

          let dataUniqueRoleID = JSON.parse(
            localStorage.getItem("dataUniqueRoleID")!
          );
          if (dataUniqueRoleID !== "") {
            this.childleft.current.defaultSelectedRow(dataUniqueRoleID);
          }
        }
        this.setState({ loaderMainTrue: false });
        resolve();
      })
      .catch((error) => {
        this.setState({ loaderMainTrue: false });
        reject();
      });

  };

  GetPasswordAssistanceRole = () => {
    RolesforTreeView.GetPasswordAssistanceRole()
      .then(async (result: any | null) => {
        if (result != null) {
          let roleData = result.result[0]?.result?.split(',');
          if (roleData[0] !== "0") {
            this.setState({ roleDataList: roleData })
          }
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  }

  getSpecialPermissionData = () => {
    this.setState({ loaderMainTrue: true });
    Roles.GetSpecialPermissionRoleList()
      .then(async (result: any[] | null) => {
        if (result != null) {
          this.setState({ SpecialPermissionList: result });
        }
        this.setState({ loaderMainTrue: false });
        resolve();
      })
      .catch((error) => {
        this.setState({ loaderMainTrue: false });
        reject();
      });
  };

  sortByKey = (array: any, key: any) => {
    return array.sort((a, b) => {
      let x = parseInt(a[key]);
      let y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  };

  sortRolesName = (result) => {
    result.sort(function (a, b) {
      if (a.roleName.toLowerCase() < b.roleName.toLowerCase()) {
        return -1;
      }
      if (a.roleName.toLowerCase() > b.roleName.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  };

  updateStateNewRole(data: any) {
    let hotelid = this.state.fieldData.hotelID;
    data.hotelID = hotelid;
    this.setState({
      fieldData: data,
    });
    this.child.current.handleRolesLeft(data);
  }

  beforeunload(e: { preventDefault: () => void; returnValue: boolean }) {
    if (
      this.state.changeValue &&
      routeLeavingGuard !== "routeLeavingGuardTrue"
    ) {
      e.preventDefault();
      e.returnValue = true;
    }
  }

  isValidateModelPoupTab() {
    confirmAlert({
      title: "Unsaved confirmation",
      message:
        "You have unsaved changes on this page. Do you want to leave this page and discard your changes?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.isChangeUnsaved(),
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }

  childHandleChange = (value: any) => {
    this.setState({ handleChangeValue: value });
  };

  getLeftRowClick = () => {
    this.setState({ leftRowSelected: true })
  }

  rowChildValue = (rowValue: any) => {
    childRowValue = rowValue;
  };

  isChangeUnsaved = () => {
    this.setState({ changeValue: false }, () => {
      let eventType = JSON.parse(localStorage.getItem("eventType")!);
      if (this.state.handleChangeValue === "matrix") {
        this.handleSelect("matrix");
      } else if (this.state.handleChangeValue === "rolesPermission") {
        this.handleSelect("rolesPermission");
      } else if (this.state.handleChangeValue === "managementHierarchy") {
        this.handleSelect("managementHierarchy");
      } else if (this.state.handleChangeValue === "SpecialPermission") {
        this.child.current.handleDiscard();
        this.handleSelect("SpecialPermission");
      } else if (this.state.handleChangeValue === "handleNewRole") {
        this.handleSelect("rolesPermission");
      } else if (this.state.handleChangeValue === "rowClick") {
        if (this.childleft.current !== null) {
          this.childleft.current.rowEvents.onClick(
            "e",
            childRowValue,
            "rowIndex"
          );
        }
      } else if (
        this.state.handleChangeValue === "NewRolePermission" &&
        eventType !== "rightPanelTab"
      ) {
        if (this.childleft.current !== null) {
          this.childleft.current.rowEvents.onClick(
            "e",
            childRowValue,
            "rowIndex"
          );
        }
      }

      if (eventType === "rightPanelTab") {
        this.child.current.handleDiscard();
      }
    });
  };
  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.beforeunload.bind(this));
  }

  updateState(data: any) {
    let hotelid = this.state.fieldData.hotelID;
    data.hotelID = hotelid;
    this.setState({
      fieldData: data,
    });
    this.child.current.handleRowClick(data);
  }

  updateSpecialState = (data: any) => {
    // let hotelid = this.state.specialFieldData.hotelID;
    // data.hotelID = hotelid;
    this.setState({
      specialFieldData: data,
    });
    this.childSpecial?.current.handleRowClick(data);
  }

  updateStateforDiscard(data: any) {
    let hotelid = this.state.fieldData.hotelID;
    data.hotelID = hotelid;
    this.setState({
      fieldData: data,
    });
    this.child.current.handleRowClick(data);
  }

  handleNewRole = (event: any) => {
    this.setState({ handleChangeValue: "handleNewRole" });
    if (this.state.changeValue) {
      this.isValidateModelPoupTab();
    } else {
      if (this.state.isChangesTab) {
        ///this.isValidateModelPoupTab();
      } else {
        this.setState({ key: "rolesPermission" });
      }
      this.setState({ isHandleNewRole: "newrole" });
      let new_state = Object.assign({}, this.state);
      let fieldData = new_state.fieldData;
      fieldData.isLowSecurityRole = "No";
      this.child.current.handleRolesRight(fieldData);
      this.childleft.current.removeSelectedRow(fieldData);
    }
  };

  changeValueFuntion = (type) => {
    this.setState({ changeValue: type });
  };
  routeLeavingGuardConfirm = (childState: any) => {
    if (childState === true) {
      routeLeavingGuard = "routeLeavingGuardTrue";
    } else {
      routeLeavingGuard = null;
    }
  };

  refreshleftpanelforNewRole() {
    this.setState({
      isLeftPanelrefresh: false,
      isBtnDisable: false,
      isSaved: true,
    });
    this.getRoleData();
  }

  refreshleftpanel() {
    this.setState({
      isLeftPanelrefresh: true,
      isBtnDisable: false,
      isSaved: true,
    });

    this.getRoleData();
  }

  removeBlankRow() {
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    this.childleft.current.removeNewBlankRow(fieldData);
    //this.childleft.current.rowEvents.onClick(fieldData);
  }
  handleTabSelect() {
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    localStorage.setItem("hotelid", JSON.stringify(fieldData.hotelID));
    localStorage.setItem("hotelName", JSON.stringify(fieldData.lettercode));
  }

  isBydefaultSelectedRow = (childState: any) => {
    //
    if (childState !== "") {
      localStorage.setItem("dataUniqueRoleID", JSON.stringify(childState));
    }
    ///this.getRoleData();
    this.childleft.current.defaultSelectedRow(childState);
  };

  // isCopyBudgetId = (childhotelid: any) => {
  //   this.setState({ copyFromHid: childhotelid });
  // };

  isValidateTab = (childState: any) => {
    this.setState({ isChangesTab: childState });
  };
  handleSelect = (eventKey: any) => {
    this.setState({ handleChangeValue: eventKey });
    if (eventKey === "matrix")
      this.setState({ scrollPageClass: "body-sec RollMatrixScroll" });
    else this.setState({ scrollPageClass: "body-sec" });

    if (this.state.changeValue) {
      this.isValidateModelPoupTab();
    } else {
      let new_state = Object.assign({}, this.state);
      let fieldData = new_state.fieldData;
      if (this.state.isChangesTab) {
        this.setState({ isHandleNewRole: "tabbudget" });
        this.child.current.HideRightPanel();
        this.childleft.current.removeNewBlankRow(fieldData);
      } else {
        // debugger;
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let userName = storage === null ? 0 : (storage.userName as any);
        // if (eventKey === "SpecialPermission" && this.state.key !== "SpecialPermission" && this.state.userGeneralInfo?.primaryMethod === "GoogleFA") {
        //   this.setState({
        //     userName: userName,
        //     passCode: 0,
        //     resetMode: "google",
        //     enterCodeShow: true,
        //     loaderMain: false
        //   });
        // }
        //else {
          if (eventKey === "SpecialPermission" && this.state.key !== "SpecialPermission")
           // this.sendOTPText();
           this.setState({ enterCodeShow: true });

          else
            this.setState({ key: eventKey });
       // }
      }
      localStorage.setItem("hotelid", JSON.stringify(fieldData.hotelID));
    }
  };

  sendOTPText = () => {
    this.setState({ loaderMain: true });
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let userName = storage === null ? 0 : (storage.userName as any);
    // this.setState({
    //   userName: "",
    //   passCode: "054554",
    //   resetMode: "sms",
    //   enterCodeShow: true,
    //   loaderMain: false
    // });
    PwdService.generatePassCode(userName, "sms", 0, "SpecialOTP")
      .then(async (response: any | null) => {
        if (response.passcode !== "") {
          this.setState({
            userName: userName,
            passCode: response.passcode,
            resetMode: "sms",
            enterCodeShow: true,
            loaderMain: false
          });
        } else {
          this.setState({ passCode: 0, resetMode: "sms", logAuditId: response.logAuditId, enterCodeShow: false, loaderMain: false });
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ loaderMain: false, enterCodeShow: false, });
        reject();
      });
  }

  hideOTPModal = (state) => {

    this.setState({ enterCodeShow: false, key: this.state.key, passCode: 0 }, () => {
      $('#extraClick').focus();
    });
  }

  setSpecialTabTrue = () => {
    this.setState({ enterCodeShow: false, key: "SpecialPermission", passCode: 0 });
  }
  ValidateOTP = () => {
    this.setState({ enterCodeShow: false, key: "SpecialPermission", passCode: 0 });
  }

  handleScroll = (e) => {
    const scrollTop = this.scrollRef.current.scrollTop;
    let scrollToFix;
    scrollTop > 200 ? (scrollToFix = true) : (scrollToFix = false);
    this.setState({ scrollTop: scrollTop, scrollToFix });
  };

  runReportClicked = (event) => {
    window.open(window.location.origin + "/labor-settings/viewer", "_blank");
  };

  renderToolTipSystemRole = (e) => (
    <Tooltip id="button-tooltip" {...e}>
      <span>System Role</span>
    </Tooltip>
  );

  addLowSecurityRole = (e) => {
    if (!this.state.lowSecurityCheck) {
      let request = {} as any;
      request.RoleName = "Low Security";
      request.RoleTag = "";
      request.ResetPwd = "No";
      request.AddPermissionIDList = ""
      request.RemovePermissionIDList = "";
      request.LowSecurity = this.state.isLowSecurityRole;
      this.setState({ loaderMain: false });
      RolesforTreeView.saveNewRolePermissioin(request)
        .then(async (result: any | null) => {
          if (result != null) {
            if (result.message === "Success") {
              toast.success("Low Security Role created successfullly.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "RolePermissionRightPanel",
              });
              this.getRoleData();
            }
          }
          resolve();
        })
        .catch((error) => {
          this.setState({ loaderMain: false });
          reject();
        });
    } else {
      Utils.toastError("There is already created a Low Security Role.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "RolePermissionRightPanel",
      });
      //this.setState({})
    }
  }

  hideLowSecurityRoles = () => {
    this.setState({ isLowSecurityRolesModal: false })
  }

  showLowSecurityRoles = () => {
    this.setState({ isLowSecurityRolesModal: true })
  }

  returnInactiveBadge = (cell: any, row: any, rowIndex: any) => {
    // if (row.roleName?.toString().toLocaleLowerCase() === "low security" && !row.lowSecurityStatus) {
    //   return (
    //     <div className="desc grey lowBadge badgeNoRap">
    //       <span className="incompleteBadge"> Incomplete</span>
    //     </div>
    //   );
    // } else 
    if (row.isLowSecurityRole === "Yes") {
      return (
        <div className="desc grey badgeNoRap">
          <span className="red-badges blue-bg">Low Security</span>
        </div>
      );
    } else {
      return (
        <></>
      )
    }
  }

  render() {


    const columns = [
      {
        dataField: "roleName",
        text: "",
        hidden: true,
      },
      {
        dataField: "type",
        text: "",
        searchable: false,
        formatter: (
          cell: any,
          row: any,
          rowIndex: any
        ) => {
          return (
            <div>
              <div className="title" ref={this.mypositionRef}>
                <div className="d-flex">
                  <EllipsisWithTooltip placement="bottom">{row.roleName}</EllipsisWithTooltip> {this.returnInactiveBadge(cell, row, rowIndex)}
                </div>
              </div>
            </div>
          )
        },
      },
      {
        dataField: "assignedUsers",
        text: "",
        searchable: false,
        formatter: (
          cell: any,
          row: any,
          rowIndex: any
        ) => {
          return (<div>
            <div className="title1">
              <EllipsisWithTooltip placement="bottom">
                {row.assignedUsers}
              </EllipsisWithTooltip>
            </div>
            <div className="desc grey" ref={this.itemNameRef}>
              <EllipsisWithTooltip placement="bottom">
                Assigned Users
              </EllipsisWithTooltip>
            </div>
          </div>)
        }
      },
      {
        dataField: "masterRoleLock",
        text: "",
        formatter: (
          cell: any,
          row: any,
          rowIndex: any
        ) => (
          <div className="MasterRoleLock">
            {row.isMasterRole === true/* || row.roleName?.toString().toLocaleLowerCase() === "low security"*/ ? (
              <div>
                <OverlayTrigger
                  placement="bottom"
                  overlay={this.renderToolTipSystemRole}
                >
                  <img src={MasterRoleLock} alt="ScheduleLock" />
                </OverlayTrigger>
              </div>
            ) : null}
          </div>
        ),
      },
    ];

    const SpecialPermColumns = [
      {
        dataField: "id",
        text: "permissionID",
        hidden: true,
      },
      {
        dataField: "result",
        text: "permissionName",
        hidden: true,
      },
      {
        dataField: "result",
        text: "permissionName",
        searchable: true,
        formatter: (
          cell: any,
          row: {
            result: React.ReactNode;
          },
          rowIndex: any
        ) => (
          <div className="title">
            <EllipsisWithTooltip placement="bottom">
              {row.result}
            </EllipsisWithTooltip>
          </div>
        ),
      },
    ];

    let storage = JSON.parse(localStorage.getItem("storage")!);
    let loginRole = storage === null ? 0 : (storage.roleName as any);

    return (
      <>
        <RouteLeavingGuard
          when={this.state.changeValue}
          routeLeavingGuardConfirm={this.routeLeavingGuardConfirm}
          shouldBlockNavigation={(location: { pathname: any }) => {
            let clickEventType = JSON.parse(
              localStorage.getItem("ClickEventType")!
            );
            if (this.state.changeValue) {
              if (clickEventType !== "SelectALL") {
                if (location.pathname) {
                  return true;
                }
              } else {
                localStorage.setItem("ClickEventType", JSON.stringify(""));
                return false;
              }
            }
            return false;
          }}
        />

        <Container
          fluid
          className={[
            this.state.scrollPageClass,
            this.state.pageWidthClass,
          ].join(" ")}
          onScroll={(e) => this.handleScroll(e)}
          ref={this.scrollRef}
          id="container"
        >
          {this.state.loaderMain && (
            <div className="matrixSpinnerCenter">
              <Spinner animation="border" variant="secondary" />
            </div>
          )}

          {this.state.enterCodeShow && (
            // <SpecialPermissionEnterCode platform="SpecialOTP" hideOTPModal={this.hideOTPModal} setSpecialTabTrue={this.setSpecialTabTrue} parentState={this.state} />
            <OtpValidator
            ref={this.ChildOTP}
            closePopup={this.hideOTPModal.bind(this)}
            ValidateOTP={this.ValidateOTP.bind(this)}
            PageName={"SpecialPermission"}
            Source={1}
        ></OtpValidator>
          )}
          <div className="page-heading d-flex">
            <div className="mr-auto">Roles & Permissions</div>
            <input type="text" id="extraClick" style={{ opacity: 0, width: 0 }} ></input>

            {this.state.fieldData.hotelID > 0 && (
              <div className="action-group d-flex flex-row">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.handleNewRole}
                >
                  New Role
                </button>

                <Dropdown className="more-action" id="dropdownMenuClickableInside" alignRight>
                  <Dropdown.Toggle
                    className="btn-outline-primary btn btn-primary more"
                    id="dropdown-more"
                    data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                      <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                      <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                    </svg>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {this.state.isReportViewPermission && (
                      <Dropdown.Item
                        eventKey="runreport"
                        onClick={this.runReportClicked}
                        className="runReportItem"
                      >
                        {/* {this.state.lowSecurityCheck && (
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="runReportIcon"
                        >
                          <path
                            d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
                            fill="#2CAF92"
                          />
                        </svg>)} */}
                        Run Report
                      </Dropdown.Item>
                    )}
                    {this.state.featureStatus_LowSecurity && (
                      <Dropdown.Item
                        onClick={this.showLowSecurityRoles}>
                        {this.state.lowSecurityCheck ? "Edit Low Security Users" : "Enable Low Security Roles"}
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>

                <span className="divider"></span>
              </div>
            )}
          </div>
          {this.state.fieldData.hotelID > 0 && (
            <Tabs
              id="controlled-tab-example"
              activeKey={this.state.key}
              onSelect={this.handleSelect}
            >
              <Tab eventKey="rolesPermission" title="Roles">
                <div className="section">
                  <div className="Role-Permission-Left d-flex justify-content-start">
                    <RolePermissionLeftPanel
                      ref={this.childleft}
                      Roles={this.state.Roles}
                      changeValue={this.state.changeValue}
                      columns={columns}
                      updateState={this.updateState.bind(this)}
                      updateStateforDiscard={this.updateStateforDiscard.bind(
                        this
                      )}
                      updateStateNewRole={this.updateStateNewRole.bind(this)}
                      changeValueFuntion={this.changeValueFuntion}
                      isValidateModelPoupTab={this.isValidateModelPoupTab}
                      isChangeUnsaved={this.isChangeUnsaved}
                      childHandleChange={this.childHandleChange}
                      rowChildValue={this.rowChildValue}
                      getLeftRowClick={this.getLeftRowClick}
                    />
                    <RolePermissionRightPanel
                      ref={this.child}
                      updateState={[this.updateState.bind(this), this.state]}
                      roleListforDelete={this.state.roleListforDelete}
                      refreshleftpanel={this.refreshleftpanel}
                      refreshleftpanelforNewRole={
                        this.refreshleftpanelforNewRole
                      }
                      removeBlankRow={this.removeBlankRow}
                      isValidateModelPoupTab={this.isValidateModelPoupTab}
                      isBydefaultSelectedRow={this.isBydefaultSelectedRow}
                      changeValueFuntion={this.changeValueFuntion}
                      changeValue={this.state.changeValue}
                      getRoleData={this.getRoleData}
                      isChangeUnsaved={this.isChangeUnsaved}
                      childHandleChange={this.childHandleChange}
                      roleTagName={this.state.roleTagName}
                      handleSelect={this.handleSelect}
                      isLowSecurityPer={this.state.featureStatus_LowSecurity}
                      roleDataList={this.state.roleDataList}
                      lowSecurityCheck={this.state.lowSecurityCheck}
                    />
                  </div>
                </div>
              </Tab>

              {loginRole?.toLowerCase() === "administrator" && (
                <Tab eventKey="SpecialPermission" title="Special Permissions">
                  <div className="section">
                    <div className="Role-Permission-Left d-flex justify-content-start">
                      <SpecialPermissionLeftPanel
                        ref={this.childSpecialleft}
                        SpecialPermissionList={this.state.SpecialPermissionList}
                        changeValue={this.state.changeValue}
                        columns={SpecialPermColumns}
                        updateSpecialState={this.updateSpecialState}
                      ></SpecialPermissionLeftPanel>
                     <SpecialPermissionRightPanel
                        ref={this.childSpecial}
                        getRoleData={this.getRoleData}
                        getVCMPer={this.getVandorContractMergePermission}
                      ></SpecialPermissionRightPanel>
                    </div>
                  </div>
                </Tab>
              )}

              <Tab eventKey="matrix" title="Matrix">
                <RoleMatrix {...this.state}></RoleMatrix>
              </Tab>
              <Tab
                eventKey="managementHierarchy"
                title="Hierarchy"
              >
                <UserManagementHierarchy
                  {...this.state}
                  getRoleData={this.getRoleData}
                  changeValueFuntion={this.changeValueFuntion}
                />
              </Tab>
            </Tabs>
          )}
          {this.state.isLowSecurityRolesModal && (
            <LowSecurityRolesModal
              show={this.state.isLowSecurityRolesModal}
              leftRowSelected={this.state.leftRowSelected}
              isLowSecurityRole={this.state.isLowSecurityRole}
              hide={this.hideLowSecurityRoles}
              callType={this.state.lowSecurityCheck ? "Edit" : "Enable"}
              lowRoleOptions={this.state.LowSecurityList}
              changeValueFuntion={this.changeValueFuntion}
              getRoleData={this.getRoleData}
              removeBlankRow={this.removeBlankRow}
              fieldData={this.state.fieldData}
              updateState={this.updateState.bind(this)}
              refreshleftpanelforNewRole={this.refreshleftpanelforNewRole}
            />
          )}
        </Container>
      </>
    );
  }

}
