import * as React from "react";
import { Dropdown, 
  // OverlayTrigger, Tooltip
 } from "react-bootstrap";

export class ReportTypeSelector extends React.Component<any, any> {
  
  private inputElement: any;

  private reportTypes = [
    {label: 'For a Single User', value:'ByEmployee' },
    {label: 'For all Users by EHID', value:'ByEHID' },
  ];

  constructor(props: any) {
    super(props);
    this.inputElement = React.createRef();
    this.state = {
      selectedItem:    {label: 'For a Single User', value:'ByEmployee' },
      filterData: this.reportTypes,
      reportTypes: this.reportTypes
    };
  }


  componentDidMount = () => {
    const { defaultValue} = this.props;
    if(defaultValue === "ByEHID") {
      this.handleDropdownChange({label: 'For all Users by EHID', value:'ByEHID' });
    } else {
      this.handleDropdownChange( {label: 'For a Single User', value:'ByEmployee' });
    }
  };

componentDidUpdate =(prevProps) => {
  const { defaultValue} = this.props;
  if(!defaultValue && this.props.defaultValue !== "ByEmployee") {
    this.handleDropdownChange( {label: 'For a Single User', value:'ByEmployee' }); 
  }
}

    handleDropdownChange = (item: any) =>  {
      this.setState({ selectedItem: item });
     this.props.onReportTypeChange(item.value);
     this.resetSerchBox();
  }

   resetSerchBox = () => {
    this.setState({ filterData: this.state.reportTypes });
  };

  render() {

    return (
      <Dropdown
        className="single-select"
      >
        <Dropdown.Toggle id="Modal-reportTypes">
            
              <span
                // style={{
                //   width: " 95%",
                //   overflow: "hidden",
                //   textOverflow: "ellipsis",
                //   display: "inline-block",
                // }}
              >
                {this.state.selectedItem.label}
              </span>
          
        </Dropdown.Toggle>
        <Dropdown.Menu>
         
          <div className="list-area">
            {this.state.filterData.map((item: any, idx: any) => (
            
                <Dropdown.Item
                   onClick={() => this.handleDropdownChange(item)}
                >
                  {item.label}
                </Dropdown.Item>
            ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}
