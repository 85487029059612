import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { ToastContainer } from "react-toastify";
import { Col, Container, Row, Dropdown} from "react-bootstrap";
import { Housekeeping } from "./../../../../Common/Services/Housekeeping";
import {
  HouseKeepingSlideOutProps,
  HouseKeepingSlideOutState,
} from "../../../../Common/Contracts/IHousekeeping";
import moment from "moment";
import { Utils } from "../../../../Common/Utilis";
import { ReactPageLoader } from "../../../../Common/Components/ReactPageLoader";
import profile from "../../../../Common/Assets/Images/profile.png";
class HouseKeepingSlideOut extends React.Component<
  HouseKeepingSlideOutProps,
  HouseKeepingSlideOutState
> {
  state: HouseKeepingSlideOutState = {
    isVarianceLoading: false,
    isRoomTypeDataLoading: false,
    detailGridData: [],
    topHeaderDetail: {
      totalTime: "",
      cleanedRoom: 0,
      mpor: "",
    },
    mporAvg: "00:00:00",
    budgetAvg: "00:00",
    totalVariance: "00:00",
    mporVariance: "00:00:00",
  };

  detailByRoomTypeColumns = [
    {
      dataField: "id",
      text: "",
      hidden: true,
    },
    {
      dataField: "roomType",
      text: "Room Type",
      headerStyle: () => {
        return { width: "25%" };
      },
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <>
            <div>{cell}</div>
          </>
        );
      },
    },
    {
      dataField: "qunatity",
      text: "Quantity",
      headerStyle: () => {
        return { width: "25%" };
      },
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <>
            <div>{cell}</div>
          </>
        );
      },
    },
    {
      dataField: "mporBudget",
      text: "MPCR Budget",
      headerStyle: () => {
        return { width: "25%" };
      },
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <>
            <div>{cell}</div>
          </>
        );
      },
    },
    {
      dataField: "totalBudget",
      text: "Total Budget",
      headerStyle: () => {
        return { width: "25%" };
      },
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <>
            <div>{cell}</div>
          </>
        );
      },
    },
  ];

  componentWillMount = () => {
    this.getHousekeepingDetail();
    this.houseKeepingDetailSummary();
  };

  getHousekeepingDetail = async () => {
    try {
      this.setState({ isRoomTypeDataLoading: true });

      const date = moment(new Date(this.props.data?.date)).format("MM/DD/YY");

      let detailGridData: any = await Housekeeping.getHouseKeepingDetail(
        this.props.data?.hotelid,
        date,
        this.props.data?.userName
      );

      let budgetAvg = detailGridData
        ?.map((r) => +r.totalBudget)
        .reduce((a, b) => a + b);

      let mporAvg = (budgetAvg / +this.props.data.roomClean) as any;

      const totalVariance = this.getMinutesInHHMMFormat(
        this.getVariance(
          this.getMinutesInHHMMFormat(budgetAvg),
          this.props.data.total
        )
      );
      const mporVariance = this.getSecondsInHHMMSSFormat(
        +this.getMporVariance(
          +mporAvg * 60,
          Utils.getSecondsFromHHMMSS(this.props.data.mpor)
        )
      );

      detailGridData = detailGridData?.map((item) => {
        item.mporBudget = this.getSecondsInHHMMSSFormat(item.mporBudget * 60);
        item.totalBudget = this.getMinutesInHHMMFormat(item.totalBudget);
        return item;
      });
      this.setState({
        detailGridData,
        mporAvg,
        budgetAvg,
        totalVariance,
        mporVariance
      });
    } catch (error) {
    } finally {
      this.setState({ isRoomTypeDataLoading: false });
    }
  };

  houseKeepingDetailSummary = async () => {
    try {
      this.setState({ isVarianceLoading: true });

      const date = moment(new Date(this.props.data?.date)).format("MM/DD/YY");
      let topHeaderDetails: any = await Housekeeping.houseKeepingDetailSummary(
        this.props.data?.hotelid,
        date,
        this.props.data?.userName
      );
    

      topHeaderDetails = topHeaderDetails?.map((item) => {
       
        item.mpor = this.getSecondsInHHMMSSFormat(+item.mpor * 60);
        item.totalTime = this.getMinutesInHHMMFormat(item.totalTime);
        return item;
      });
      this.setState({ topHeaderDetail: topHeaderDetails[0] });
    } catch (error) {
    } finally {
      this.setState({ isVarianceLoading: false });
    }
  };

  getVariance = (budget: any, actual: any): number => {
    const budgetMinutes = Utils.getMinutesFromHHMM(budget);

    const actualMinutes = Utils.getMinutesFromHHMM(actual);

    return budgetMinutes - actualMinutes;
  };

  getMporVariance = (budget: any, actual: any): number => {
    return budget - actual;
  };

  getMinutesinHHMMSSFormat = (minutes): string => {
   
    const seconds = +minutes * 60;
    const hhMMFormat = this.getMinutesInHHMMFormat(seconds);
    return hhMMFormat?.includes("-")
      ? `- 00:${hhMMFormat?.replace("-", "")?.trim()}`
      : `00:${hhMMFormat}`;
  };
  getSecondsInHHMMSSFormat = (seconds): string => {
    
    const hhMMFormat = this.getMinutesInHHMMFormat(+seconds);
    return hhMMFormat?.includes("-")
      ? `- 00:${hhMMFormat?.replace("-", "")?.trim()}`
      : `00:${hhMMFormat}`;
  };

  getMinutesInHHMMFormat = (value): string => {
    let formatttedHours: string = "00:00";
    if (value) {
      const totalHours = Math.floor(+value?.toString()?.replace("-", "") / 60);
      const remainingMinutes = Math.round(value % 60);

      formatttedHours = `${
        totalHours?.toString()?.replace("-", "")?.length === 1
          ? totalHours?.toString()?.padStart(2, "0")
          : totalHours.toLocaleString("en-US", { maximumFractionDigits: 2 })
      }:${
        remainingMinutes.toString()?.replace("-", "")?.length === 1
          ? remainingMinutes
              ?.toString()
              ?.replace("-", "")
              ?.padStart(2, "0")
              ?.toString()
          : remainingMinutes?.toString()?.replace("-", "")
      }`;

      formatttedHours = value?.toString()?.includes("-")
        ? "- " + formatttedHours
        : formatttedHours;
    }

    return formatttedHours;
  };

  render() {
    const {
      detailGridData,
      topHeaderDetail,
      mporAvg,
      isVarianceLoading,
      isRoomTypeDataLoading,
      budgetAvg,
      totalVariance,
      mporVariance
    } = this.state;
    const { data: propsData } = this.props;

    return (
      <div className="housekeeper-details-slideout pos-fxd index-1000">
        {/* <ToastContainer autoClose={3000} /> */}
        <div className="d-flex">
          <div
            className="back-drop"
            onClick={() => this.props.hideSlideOut()}
          ></div>
          <Container
            fluid
            className="body-sec performance-position timesheet-details justify-content-end rm-padding"
          >
            <div className="page-heading underline">
              <Row>
                <Col sm="10">
                  <div className="header-name">Housekeeper Details</div>
                </Col>
                <Col sm="2" className="text-right">
                  <button
                    type="button"
                    className="btn wht-bg cross"
                    onClick={() => this.props.hideSlideOut()}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                        fill="#84888C"
                      ></path>
                    </svg>
                  </button>
                </Col>
              </Row>
            </div>

            <div className="section-body" id="houseKeeperUserInfo">
              <div className="date">
                {moment(new Date(propsData?.date)).format("MMMM Do, YYYY")}
              </div>
              <div className="employee d-flex">
                <div className="employee-details d-flex">
                  <div className="profile-img">
             
                  <img
                                src={profile}
                                className="default"
                                width="40"
                                height="40"
                                alt={propsData?.userName}
                                />
                  </div>
                  <div className="name-position d-flex">
                    <div className="name">{propsData?.userName}</div>
                    <div className="position">Housekeeper</div>
                  </div>
                </div>
                <div className="work-detail-slots d-flex">
                  <div className="total-time">
                    <div className= {(propsData.totalActualinMin) > (propsData.totalBudgetinMin)
                                            ? "data red"
                                            : "data"
                                        }>{propsData.total}</div>
                    <div className="label">Total Time</div>
                  </div>
                  <div className="mpor">
                    <div  className="data">{propsData.mpor}</div>
                    <div className="label">MPCR</div>
                  </div>
                  <div className="cleaned">
                    <div className="data">{propsData.roomClean}</div>
                    <div className="label">Cleaned</div>
                  </div>
                  <div className="notes-sec">
                  {(propsData.notes.length>0 &&
                    <Dropdown className="more-action notes-btn" alignRight>
                        <Dropdown.Toggle
                            className="btn-outline-primary  more"
                            id="dropdown-more"
                            >
                            <div className="d-flex">
                                <svg 
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                >
                                <path d="M13.5556 2.4H11.3889V1H9.94444V2.4H7.05556V1H5.61111V2.4H3.44444C2.64783 2.4 2 3.0279 2 3.8V13.6C2 14.3721 2.64783 15 3.44444 15H13.5556C14.3522 15 15 14.3721 15 13.6V3.8C15 3.0279 14.3522 2.4 13.5556 2.4ZM3.44444 13.6V4.5H5.61111H7.05556H9.94444H11.3889H13.5556V3.8L13.557 13.6H3.44444Z" />
                                <path d="M4.6665 6H11.3332V7.33333H4.6665V6ZM4.6665 8.66667H7.99984V10H4.6665V8.66667Z" />
                                </svg>
                                {/* <span>5</span> */}
                            </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>                  
                          <div className="notes-sec">                    
                              <div className="notes-list">                                   
                                  <div className="comment-list">
                                  <div className="d-flex profile-details">
                                      <div className="profile-img">
                                          <img
                                              src={propsData.noteimageUrl ==="" ?  profile : propsData.noteimageUrl}
                                              className="default"
                                              width="40"
                                              height="40"                                                                                            
                                          />
                                      </div>
                                      <div>
                                          <div className="main-ttl">{propsData.noteUser}</div>
                                          <div className="sub-ttl">{Utils.getLocalDateTimeFromUtc(propsData.noteDate)}</div>
                                      </div>
                                  </div>
                                      <ul>
                                          <li>
                                              <div className="d-flex">
                                                  <div className="detail-sec">
                                                      <div className="comments">
                                                      {propsData.notes}
                                                      </div>
                                                  </div>
                                              </div>
                                          </li>
                                      </ul>
                                  </div>                                  
                              </div>
                          </div>
                        </Dropdown.Menu>
                    </Dropdown>
                 )}
                  </div>
                </div>
              </div>
            </div>

            <div className="section-body">
              {isVarianceLoading ? (
                <ReactPageLoader useas={"houseKeepingSlideVariance"} />
              ) : (
                <div className="variance-table">
                  <div className="theader d-flex">
                    <div></div>
                    <div>Totals</div>
                    <div>Variance</div>
                  </div>
                  <div className="tbody">
                    <div className="row-time d-flex">
                      <div>Time</div>
                      <div className="time-totals d-flex">
                        <span className={
                            totalVariance?.includes('-')
                              ? "red-text"
                              : ""
                          }>{propsData.total}</span>
                        <span>{this.getMinutesInHHMMFormat(budgetAvg)}</span>
                      </div>
                      <div className="time-variance d-flex">
                        <span className={
                            totalVariance?.includes('-')
                              ? "red-text"
                              : ""
                          }>
                          {totalVariance}
                        </span>
                        
                        <span>vs. Budget</span>
                      </div>
                      <div className="time-variance d-flex">
                        <span className={
                            topHeaderDetail.totalTime?.toString()?.includes('-')
                              ? "red-text"
                              : ""
                          }>{topHeaderDetail.totalTime}</span>
                        <span>vs. 30 Days Average</span>
                      </div>
                    </div>
                    <div className="row-mpor d-flex">
                      <div>MPCR</div>
                      <div className="mpor-totals d-flex">
                        <span
                           className={
                            mporVariance?.includes('-')
                              ? "red-text"
                              : ""
                          }
                        >{propsData.mpor}</span>
                        <span>{this.getMinutesinHHMMSSFormat(mporAvg)}</span>
                      </div>
                      <div className="mpor-variance d-flex">
                        <span
                          className={
                            mporVariance?.includes('-')
                              ? "red-text"
                              : ""
                          }
                        >
                          {" "}
                          {mporVariance}
                        </span>
                        <span>vs. Budget</span>
                      </div>
                      <div className="mpor-variance d-flex">
                        <span
                        className={
                          topHeaderDetail.mpor?.toString()?.includes('-')
                            ? "red-text"
                            : ""
                        }
                        >{topHeaderDetail.mpor}</span>
                        <span>vs. 30 Days Average</span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isRoomTypeDataLoading ? (
                <ReactPageLoader useas={"houseKeepingSlideRoomTypeData"} />
              ) : (
                <div className="roomtype-table">
                  <div className="heading">Details by Room Type</div>
                  <div className="table-area">
                    <BootstrapTable
                      keyField="id"
                      id={"detailsByRoomType"}
                      columns={this.detailByRoomTypeColumns}
                      data={detailGridData}
                    />
                  </div>
                </div>
              )}
            </div>
          </Container>
        </div>
      </div>
    );
  }
}

export default HouseKeepingSlideOut;
