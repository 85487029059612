import Instense from "./Axios-config";
import {
  ILaborDayApproveRequestDto,
  ILaborDayApproveResponseDto,
  ILaborDayDaystoApproveDto,
  ILaborDayDaystoApproveHotelDto,
  IApproveSaveRequestDto,
  IAutoPunchOutDaysDto,
  IAutoPunchOutDaysResponseDto,
  IDeptLaborApproveRequestDto,
  IOverUnderDeptsDaysDto,
  IOverUnderDeptsDaysResponseDto,
  IGetHousekeepingBannerDto,
  IApproveSaveBreakExceptionCommentDto,
  ILaborDayDaystoApproveHotelGMDto,
  IReviewTabDto,
  IReviewResponseDto,
  ISaveReviewRequestDto,
  IDepartmentPostionDaysDto
} from "../Contracts/ILaborDayApprove";

export class LaborDayApprove {
  public static GetLaborDayApprovalDetails = async (
    request: ILaborDayApproveRequestDto
  ): Promise<ILaborDayApproveResponseDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDayApprove/GetLaborDayApprovalDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as ILaborDayApproveResponseDto;
      return result;
    });
  };

  public static GetLaborApproveDayAlertBoxCount = async (
    request: ILaborDayApproveRequestDto
  ): Promise<ILaborDayApproveResponseDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDayApprove/GetLaborApproveDayAlertBoxCount`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as ILaborDayApproveResponseDto;
      // alert(JSON.stringify(result));
      return result;
    });
  };

  public static GetLaborSmileIDAlertBoxCount = async (
    request: ILaborDayApproveRequestDto
  ): Promise<ILaborDayApproveResponseDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDayApprove/GetLaborSmileIDAlertBoxCount`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as ILaborDayApproveResponseDto;
      // alert(JSON.stringify(result));
      return result;
    });
  };

  public static GetLaborRoomAssignmentAlertBoxCount = async (
    request: ILaborDayApproveRequestDto
  ): Promise<ILaborDayApproveResponseDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDayApprove/GetLaborRoomAssignmentAlertBoxCount`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as ILaborDayApproveResponseDto;
      // alert(JSON.stringify(result));
      return result;
    });
  };

  public static GetLaborHousekeepingApprovalAlertBoxCount = async (
    request: ILaborDayApproveRequestDto
  ): Promise<ILaborDayApproveResponseDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDayApprove/GetLaborHousekeepingApprovalAlertBoxCount`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as ILaborDayApproveResponseDto;
      // alert(JSON.stringify(result));
      return result;
    });
  };

  public static GetLaborBreakAttestationAlertBoxCount = async (
    request: ILaborDayApproveRequestDto
  ): Promise<ILaborDayApproveResponseDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDayApprove/GetLaborBreakAttestationAlertBoxCount`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as ILaborDayApproveResponseDto;
      // alert(JSON.stringify(result));
      return result;
    });
  };

  public static GetLaborHolidayCalendarAlertBoxCount = async (
    request: ILaborDayApproveRequestDto
  ): Promise<ILaborDayApproveResponseDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDayApprove/GetLaborHolidayCalendarAlertBoxCount`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as ILaborDayApproveResponseDto;
      // alert(JSON.stringify(result));
      return result;
    });
  };

  public static GetLaborDayOverViewetails = async (
    request: ILaborDayApproveRequestDto
  ): Promise<ILaborDayDaystoApproveDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDayApprove/GetLaborDayOverViewetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as ILaborDayDaystoApproveDto;
      return result;
    });
  };

  public static GetLaborDayOverViewetailsByGM = async (
    request: ILaborDayApproveRequestDto
  ): Promise<ILaborDayDaystoApproveDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDayApprove/GetLaborDayOverViewetailsByGM`;
    //const url = `/LaborDayApprove/GetLaborDayOverViewetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as ILaborDayDaystoApproveDto;
      return result;
    });
  };

  public static GetLaborBreakAttestationHidView = async (
    request: ILaborDayApproveRequestDto
  ): Promise<ILaborDayDaystoApproveDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDayApprove/GetLaborBreakAttestationHidView`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as ILaborDayDaystoApproveDto;
      return result;
    });
  };
  public static optimizeMPORShift = async (
    request: any[]
  ): Promise<any | null> => {
    const storage = JSON.parse(localStorage.getItem("storage")!);
    const tenantID = storage?.tenantID;
    request = request.map((item) => {
      item.TenantID = tenantID;
      return item;
    });
    const url = `/LaborDayApprove/AdjustMPORSchedule`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result;
  };

  public static GetLaborDayHotelApprovalDetails = async (
    request: ILaborDayApproveRequestDto
  ): Promise<ILaborDayDaystoApproveHotelDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDayApprove/LaborDayOverViewApproveDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as ILaborDayDaystoApproveHotelDto;
      return result;
    });
  };

  public static GetLaborOverviewDailyHidDetailsByGM = async (
    request: ILaborDayApproveRequestDto
  ): Promise<ILaborDayDaystoApproveHotelGMDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDayApprove/GetLaborOverviewDailyHidDetailsByGM`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as ILaborDayDaystoApproveHotelGMDto;
      return result;
    });
  };


  public static GetLaborBreakAttestationHidDetails = async (
    request: ILaborDayApproveRequestDto
  ): Promise<ILaborDayDaystoApproveHotelDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDayApprove/GetLaborBreakAttestationHidDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as ILaborDayDaystoApproveHotelDto;
      return result;
    });
  };
  
  public static GetLaborBreakAttestationHidSubDetails = async (
    request: ILaborDayApproveRequestDto
  ): Promise<ILaborDayDaystoApproveHotelDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDayApprove/GetLaborBreakAttestationHidSubDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as ILaborDayDaystoApproveHotelDto;
      return result;
    });
  };

  public static SaveLaborOverViewBreakAttestationResolve = async (
    request: IApproveSaveBreakExceptionCommentDto
  ): Promise<any | null> => {
    const url = `/LaborDayApprove/SaveLaborOverViewBreakAttestationResolve`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result;
  };

  public static ApproveLaborBudgetSetting = async (
    request: IApproveSaveRequestDto
  ): Promise<any | null> => {
    const url = `/LaborDayApprove/LaborSaveDayApprove`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result;
  };

  public static GetAutoPunchOutApprovalDays = async (
    request: IAutoPunchOutDaysDto
  ): Promise<IAutoPunchOutDaysResponseDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDayApprove/GetLaborDayOverViewApproveSubDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IAutoPunchOutDaysResponseDto;
      return result;
    });
  };

  public static GetLaborOverViewDayApproveAutoPunchDetails = async (
    request: IAutoPunchOutDaysDto
  ): Promise<IAutoPunchOutDaysResponseDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDayApprove/GetLaborOverViewDayApproveAutoPunchDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IAutoPunchOutDaysResponseDto;
      return result;
    });
  };

  public static GetLaborOverViewReviewDetails = async (
    request: IReviewTabDto
  ): Promise<IReviewResponseDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDayApprove/GetApproveLaborDayOverReviewDetailsTab`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IReviewResponseDto;
      return result;
    });
  };



  public static GetLaborReviewComment = async (
    request: IReviewTabDto
  ): Promise<IReviewResponseDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDayApprove/GetLaborDayOverViewReviewComments`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IReviewResponseDto;
      return result;
    });
  };

  public static GetReviewMonthlySecussesRate = async (
    request: IReviewTabDto
  ): Promise<IReviewResponseDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDayApprove/GetLaborDayOverViewReviewMonthlySucessRate`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IReviewResponseDto;
      return result;
    });
  };



  public static GetReviewMontlyOtRisk = async (
    request: IReviewTabDto
  ): Promise<IReviewResponseDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDayApprove/GetReviewMontlyOtRisk`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IReviewResponseDto;
      return result;
    });
  };

  public static GetReviewDotWages = async (
    request: IReviewTabDto
  ): Promise<IReviewResponseDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDayApprove/GetReviewDotHours`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IReviewResponseDto;
      return result;
    });
  };

  


  public static GetOverUnderDeptApprovalDays = async (
    request: IOverUnderDeptsDaysDto
  ): Promise<IOverUnderDeptsDaysResponseDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDayApprove/GetLaborDayOverViewBudgetDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IAutoPunchOutDaysResponseDto;
      return result;
    });
  };


  public static GetLaborOverViewDayApproveDeptPunchDetails = async (
    request: IAutoPunchOutDaysDto
  ): Promise<IAutoPunchOutDaysResponseDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDayApprove/GetLaborDayOverViewBudgetDeptDetailsTab`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IAutoPunchOutDaysResponseDto;
      return result;
    });
  };

  public static GetLaborDayOverViewBudgetDeptPositionDetails = async (
    request: IDepartmentPostionDaysDto
  ): Promise<IAutoPunchOutDaysResponseDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDayApprove/GetLaborDayOverViewBudgetDeptPositionDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IAutoPunchOutDaysResponseDto;
      return result;
    });
  };

  public static GetLaborDepartmentComments = async (
    request: IAutoPunchOutDaysDto
  ): Promise<IAutoPunchOutDaysResponseDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDayApprove/GetLaborDepartmentComments`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IAutoPunchOutDaysResponseDto;
      return result;
    });
  };





  public static ApproveLaborWithDept = async (
    request: IDeptLaborApproveRequestDto
  ): Promise<any | null> => {
    const url = `/LaborDayApprove/LaborSaveDayApproveWithDept`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result;
  };

  public static GetHouseKeepingRoomOccupied = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDayApprove/GetHouseKeepingRoomOccupied`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static LaborRoomAssignment = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDayApprove/LaborRoomAssignment`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static LaborServiceList = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDayApprove/LaborServiceList`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static LaborApproveServiceList = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDayApprove/LaborApproveServiceList`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static LaborRoomAssignmentSave = async (
    request: any[]
  ): Promise<any | null> => {
    const url = `/LaborDayApprove/LaborRoomAssignmentSave`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result;
  };

  public static saveHousekeepingManualOccupiedRooms = async (
    request: any[]
  ): Promise<any | null> => {
    const url = `/LaborDayApprove/SaveHouseKeepingManualRoomOccupied`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result;
  };

  public static GetLaborRoomApprovalDays = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDayApprove/LaborRoomApprovalDays`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static HousekeepingApprovalCount = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDayApprove/HousekeepingApprovalCount`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static LaborRoomApproval = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDayApprove/LaborRoomApproval`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static LaborRoomDiscrepency = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDayApprove/LaborRoomDiscrepency`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static LaborEmployeeOverBudget = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDayApprove/LaborEmployeeOverBudget`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static LaborRoomAssignmentSchedule = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDayApprove/LaborRoomAssignmentSchedule`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };
  public static HousekeepingApprovalsSkip = async (
    request: any[]
  ): Promise<any | null> => {
    const url = `/LaborDayApprove/HousekeepingApprovalsSkip`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result;
  };

  public static LaborRoomApprovedSave = async (
    request: any[]
  ): Promise<any | null> => {
    const url = `/LaborDayApprove/LaborRoomApprovedSave`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result;
  };

  public static LaborRooomTotalSave = async (
    request: any[]
  ): Promise<any | null> => {
    const url = `/LaborDayApprove/LaborRooomTotalSave`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result;
  };

  public static LaborDiscrepancyWithSave = async (
    request: any[]
  ): Promise<any | null> => {
    const url = `/LaborDayApprove/LaborDiscrepancyWithSave`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result;
  };

  public static housekeepingSummary = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDayApprove/HouseKeepingSummary`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static houseKeepingApprovedUser = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDayApprove/HouseKeepingApprovedUser`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static LaborUnApprovedDay = async (
    request: any
  ): Promise<any | null> => {
    const url = `/LaborDayApprove/LaborUnApprovedDay`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result;
  };

  public static houseKeepingDaySummary = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDayApprove/HouseKeepingDaySummary`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static GetDaliyPunchReport = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let rolId = storage === null ? 0 : (storage.rolId as any);

    request.RoleID = Number(rolId);
    request.TenantID = tenantID;

    const url = `/RolePermission/GetPageSubPermissionDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static GetHousekeepingBanner = async (
    request: IGetHousekeepingBannerDto
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDailyActivityEntry/GetHouseKeepingBanner`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };


  public static bindHouseKeepingComments = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDayApprove/HouseKeepingApprovedComments`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static LaborSaveDayDepartmentApproval = async (
    request: IApproveSaveRequestDto
  ): Promise<any | null> => {
    const url = `/LaborDayApprove/LaborSaveDayDepartmentApproval`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result;
  };

  public static LaborSaveDayDepartmentNotes = async (
    request: IApproveSaveRequestDto
  ): Promise<any | null> => {
    const url = `/LaborDayApprove/LaborSaveDayDepartmentNotes`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result;
  };

  public static LaborSaveDayReviewApprovalComment = async (
    request: ISaveReviewRequestDto
  ): Promise<any | null> => {
    const url = `/LaborDayApprove/LaborSaveDayReviewApprovalComment`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result;
  };

  public static GetHousekeepungApprovalDetails = async (
    request: IOverUnderDeptsDaysDto
  ): Promise<IOverUnderDeptsDaysResponseDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDayApprove/GetApproveLaborDayOverViewHousekepingDetailsTab`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IAutoPunchOutDaysResponseDto;
      return result;
    });
  };

}
