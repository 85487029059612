import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";

export class UnauthorizeAccess extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      isError: false,
    };
  }

  componentDidMount() {
    
    let valuesArray = decodeURIComponent(this.props.history.location.search)?.replace('?', '')?.split('&');
    const page = valuesArray ? valuesArray[0]?.split('=')[1] as any : "";
    if (page === "NotFound") {
      this.setState({ isError: true })
    }

    const { location, history } = this.props;
    history.replace();

  }

  render() {
   
    //const error = valuesArray ? window.atob(valuesArray[0]?.split('=')[1] as any) : "";

    return (
      <Container fluid>
        <Row className="screen-action">
          <Col>

            {this.state.isError && (
              <div className="screen-heading text-center">
                Transaction unavailable. Please contact your administrator for more information.
              </div>
            )}

            {!this.state.isError && (
              <div className="screen-heading text-center">
                You don't have permission to view the requested page.
              </div>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}



