import * as React from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import OutsideClickHandler from "react-outside-click-handler";

export class MultiSelectSearch extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      selectedItems: [],
      filterData: [],
      filterDataCopy: [],
      opened: false,
      itemList: props.itemList,
      isSelectAll: props.isSelectAll,
      filtertitle: props.filtertitle,
      isSelect: true,
      inputValue: "",
      isTooltipVisible: this.props?.pageName === "InvoiceApproval" ? "hidden" : "visible",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleClearAll = this.handleClearAll.bind(this);
  }
  componentDidMount() {
    const activeChip = this.state.itemList.filter(
      (itemList: { isChecked: boolean }) => itemList.isChecked === true
    );
    this.setState({
      selectedItems: activeChip,
      filterData: this.state.itemList,
    });
  }

  componentWillReceiveProps(nextProps: { itemList: any }) {
    this.setState({ itemList: nextProps.itemList });
  }

  showSelected = () => {
    const activeChip = this.props.itemList.filter(
      (itemList: { isChecked: boolean }) => itemList.isChecked === true
    );
    this.setState({
      selectedItems: activeChip,
      filterData: this.state.itemList,
    });
  };

  /**For remove COA(s) list **/
  handleRemoveSpecificChip = (idx: number) => () => {
    const itemList = [...this.state.itemList];
    const selectedItems = [...this.state.selectedItems];
    const removeSelect = this.getIndex(selectedItems[idx].value, "removeChip");
    this.props.dataChange();
    if (removeSelect !== -1) { itemList[removeSelect].isChecked = false; }
    selectedItems.splice(idx, 1);
    this.setState({ selectedItems });
    this.setState({ itemList });
    const coaSelectedItem = this.state.itemList.filter(
      (itemList: { isChecked: boolean }) => itemList.isChecked === true
    );
    this.props.updateMultiSelectItem(coaSelectedItem);
  };

  /************checkbox check unchek********/
  handleCheckCheckbox = (idx: any) => (event: any) => {
    this.props.dataChange();
    const itemList = [...this.state.itemList];
    const selectedItems = [...this.state.selectedItems];

    this.setState({
      filterDataCopy: this.state.itemList,
    });
    const index = itemList.findIndex((xvalue) => xvalue.value === idx);
    itemList[index].isChecked = event.target.checked;

    idx = index;
    this.setState({ itemList });

    //Item push and remove
    let item;
    if (this.props?.pageName === "InvoiceApproval") {
      item = {
        id: itemList[idx].id,
        value: itemList[idx].value,
        isChecked: event.target.checked,
        status: itemList[idx].status,
        isconfidentialcoa: itemList[idx].isconfidentialcoa,
      };
    } else {
      item = {
        value: itemList[idx].value,
        isChecked: event.target.checked,
      };
    }

    if (event.target.checked === true) {
      this.setState({
        selectedItems: [...this.state.selectedItems, item],
      });
    } else {
      const removeIndex = this.getIndex(itemList[idx].value, "selectbox");
      selectedItems.splice(removeIndex, 1);
      this.setState({ selectedItems });
    }
    const coaSelectedItem = this.state.itemList.filter(
      (itemList: { isChecked: boolean }) => itemList.isChecked === true
    );
    this.props.updateMultiSelectItem(coaSelectedItem);
  };
  //Get array Index
  getIndex(val: any, type: string) {
    if (type === "removeChip") {
      let itemList = [...this.state.itemList];
      return itemList.findIndex((obj) => obj.value === val);
    } else {
      let selectedItems = [...this.state.selectedItems];
      return selectedItems.findIndex((obj) => obj.value === val);
    }
  }
  handleChange(e: { target: { value: string } }) {
    const updatedList = this.state.itemList.filter(
      (item: { description: string }) => {
        return (
          item.description.toLowerCase().indexOf(e.target.value.toLowerCase()) >
          -1
        );
      }
    );

    this.setState({ filterData: updatedList, inputValue: e.target.value });
  }

  updateSavedItem(data: any) {
    let allCoaList = [...this.state.itemList];
    allCoaList.forEach((element) => {
      element.isChecked = false;
    });
    this.setState({ itemList: allCoaList });
    if (this.props?.pageName === "InvoiceApproval") {
      data.forEach((element: { id: any }) => {
        this.state.itemList.filter(
          (itemList: { id: any }) => itemList.id === element.id
        )[0].isChecked = true;
      });
    } else if (this.props?.pageName === "VendorGeneral") {
      data.forEach((element: { value: any }) => {
        this.state.itemList.filter(
          (itemList: { value: any }) => itemList.value === element.value
        )[0] !== undefined &&
          (this.state.itemList.filter(
            (itemList: { value: any }) => itemList.value === element.value
          )[0].isChecked = true)
      });
    } else {
      // data.forEach((element: { value: any }) => {
      //   this.state.itemList.filter(
      //     (itemList: { value: any }) => itemList.value === element.value
      //   )[0].isChecked = true;
      // });
      data.forEach((element/*: { value: any }*/) => {
        let hasIschecked = this.state.itemList.filter((itemList: { value: any }) => itemList.value === element.value);
        console.log(hasIschecked);
        if (hasIschecked.length > 0) {
          hasIschecked[0].isChecked = true
        }
      });
    }
    this.setState({ selectedItems: data });
  }

  handleClick() {
    if (this.props.multiSelectOpen) {
      this.props.multiSelectOpen();
    }

    this.setState((prevState: any) => ({
      opened: !prevState.opened,
    }));
    this.setState({ inputValue: "", filterData: this.state.itemList });
  }

  handleOutsideClick(e: any) {
    this.setState({ inputValue: "", opened: false });
  }

  handleSelectAll(e: any) {
    this.props.dataChange();
    let allItemList = [...this.state.itemList];
    allItemList.forEach((element) => {
      element.isChecked = true;
    });
    this.setState({ itemList: allItemList, isSelect: false, opened: false });
    this.setState({ selectedItems: allItemList });
    this.props.updateMultiSelectItem(allItemList);
  }

  handleClearAll(e: any) {
    this.props.dataChange();
    let allItemList = [...this.state.itemList];
    allItemList.forEach((element) => {
      element.isChecked = false;
    });
    this.setState({ itemList: allItemList, isSelect: true });
    this.setState({ selectedItems: [] });
    this.props.updateMultiSelectItem([]);
  }

  render() {
    const hoverOutEllipsis = () => {
      $(".tooltip").removeClass("show");
    };
    let btnDisabled = (this.props.isDisable !== undefined && this.props.isDisable);
    return (
      <OutsideClickHandler  onOutsideClick={this.handleOutsideClick}>
        <div
          className={
            this.state.opened ? "multiSelectSearch opened" : "multiSelectSearch"
          }
        >
          <div className="fieldArea budgetCoa">
            <div className="chip">
              {this.state.selectedItems.map((item: any, idx: any) => {
                if (this.props?.pageName === "VendorGeneral") {
                  if (idx <= 2) {
                    return (
                      <div className="chipSelect" id={idx} key={idx}>
                        <div className="chipVal">{item.value}</div>

                        {btnDisabled ? (<></>) : (<>
                          {this.props?.isConfiCOAPerm ? (
                            <button id="btnremove" type="button" className="cross"
                              onClick={this.handleRemoveSpecificChip(idx)}
                              disabled={this.props.managePermission === undefined ? false : !this.props.managePermission}                          >
                              <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                <path d="M6.79465 0.229492L3.96598 3.05749L1.13798 0.229492L0.195312 1.17216L3.02331 4.00016L0.195312 6.82816L1.13798 7.77083L3.96598 4.94282L6.79465 7.77083L7.73731 6.82816L4.90931 4.00016L7.73731 1.17216L6.79465 0.229492Z" fill="#6A6E73" />
                              </svg>
                            </button>
                          ) : <>{item.isconfidentialcoa === "" && (
                            <button id="btnremove" type="button" className="cross" onClick={this.handleRemoveSpecificChip(idx)}
                              disabled={this.props.managePermission === undefined ? false : !this.props.managePermission}
                            >
                              <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.79465 0.229492L3.96598 3.05749L1.13798 0.229492L0.195312 1.17216L3.02331 4.00016L0.195312 6.82816L1.13798 7.77083L3.96598 4.94282L6.79465 7.77083L7.73731 6.82816L4.90931 4.00016L7.73731 1.17216L6.79465 0.229492Z" fill="#6A6E73" />
                              </svg>
                            </button>
                          )}</>
                          }
                        </>)}
                      </div>
                    )
                  }
                } 
               else if(this.props?.pageName === "Procedures") {
                  if (idx <= 4) {
                    return (
                      <div className="chipSelect" id={idx} key={idx}>
                        <div className="chipVal">{item.value}</div>

                        {btnDisabled ? (<></>) : (<>
                          {this.props?.isConfiCOAPerm ? (
                            <button id="btnremove" type="button" className="cross"
                              onClick={this.handleRemoveSpecificChip(idx)}
                              disabled={btnDisabled}                          >
                              <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                <path d="M6.79465 0.229492L3.96598 3.05749L1.13798 0.229492L0.195312 1.17216L3.02331 4.00016L0.195312 6.82816L1.13798 7.77083L3.96598 4.94282L6.79465 7.77083L7.73731 6.82816L4.90931 4.00016L7.73731 1.17216L6.79465 0.229492Z" fill="#6A6E73" />
                              </svg>
                            </button>
                          ) : <>{item.isconfidentialcoa === "" && (
                            <button id="btnremove" type="button" className="cross" onClick={this.handleRemoveSpecificChip(idx)}
                              disabled={  btnDisabled}
                            >
                              <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.79465 0.229492L3.96598 3.05749L1.13798 0.229492L0.195312 1.17216L3.02331 4.00016L0.195312 6.82816L1.13798 7.77083L3.96598 4.94282L6.79465 7.77083L7.73731 6.82816L4.90931 4.00016L7.73731 1.17216L6.79465 0.229492Z" fill="#6A6E73" />
                              </svg>
                            </button>
                          )}</>
                          }
                        </>)}
                      </div>
                    )
                  }
                }
                
                else {
                  return (
                    <OverlayTrigger
                      key={"overlay1-" + idx}
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip id={"coa" + idx} style={{ visibility: this.state.isTooltipVisible }}>
                          {
                            this.props?.pageName === "InvoiceApproval" ?
                              this.state.itemList.filter(
                                (row: any) => row.id === item.id
                              )[0]?.description
                              : this.state.itemList.filter(
                                (row: any) => row.value === item.value
                              )[0]?.description
                          }
                        </Tooltip>
                      }
                    >
                      <div className="chipSelect" id={idx} key={idx}>
                        <div className="chipVal">{item.value}</div>
                        {this.props?.pageName === "InvoiceApproval" ? <>
                          {this.props?.isConfiCOAPerm ? (
                            <button
                              id="btnremove"
                              type="button"
                              className="cross"
                              onClick={this.handleRemoveSpecificChip(idx)}
                              disabled={
                                this.props.managePermission === undefined
                                  ? false
                                  : !this.props.managePermission
                              }
                            >
                              <svg
                                width="8"
                                height="8"
                                viewBox="0 0 8 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.79465 0.229492L3.96598 3.05749L1.13798 0.229492L0.195312 1.17216L3.02331 4.00016L0.195312 6.82816L1.13798 7.77083L3.96598 4.94282L6.79465 7.77083L7.73731 6.82816L4.90931 4.00016L7.73731 1.17216L6.79465 0.229492Z"
                                  fill="#6A6E73"
                                />
                              </svg>
                            </button>
                          ) : <>{item.isconfidentialcoa === "" && (
                            <button
                              id="btnremove"
                              type="button"
                              className="cross"
                              onClick={this.handleRemoveSpecificChip(idx)}
                              disabled={
                                this.props.managePermission === undefined
                                  ? false
                                  : !this.props.managePermission
                              }
                            >
                              <svg
                                width="8"
                                height="8"
                                viewBox="0 0 8 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.79465 0.229492L3.96598 3.05749L1.13798 0.229492L0.195312 1.17216L3.02331 4.00016L0.195312 6.82816L1.13798 7.77083L3.96598 4.94282L6.79465 7.77083L7.73731 6.82816L4.90931 4.00016L7.73731 1.17216L6.79465 0.229492Z"
                                  fill="#6A6E73"
                                />
                              </svg>
                            </button>
                          )}</>
                          }
                        </> :
                          <button
                            id="btnremove"
                            type="button"
                            className="cross"
                            onClick={this.handleRemoveSpecificChip(idx)}
                            disabled={(
                              this.props.managePermission === undefined
                                ? false
                                : !this.props.managePermission
                            )
                            }
                          >
                            <svg
                              width="8"
                              height="8"
                              viewBox="0 0 8 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.79465 0.229492L3.96598 3.05749L1.13798 0.229492L0.195312 1.17216L3.02331 4.00016L0.195312 6.82816L1.13798 7.77083L3.96598 4.94282L6.79465 7.77083L7.73731 6.82816L4.90931 4.00016L7.73731 1.17216L6.79465 0.229492Z"
                                fill="#6A6E73"
                              />
                            </svg>
                          </button>
                        }

                      </div>
                    </OverlayTrigger>
                  )
                }
              })}

              {this.props?.pageName === "VendorGeneral" && (
                <>
                  {this.state.selectedItems?.length > 3 && (
                    <div className="chipSelect" onWheel={() => hoverOutEllipsis()}>
                      <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip id="button-tooltip">
                            <>{this.state.selectedItems?.map((items, idx) => (<div key={idx}>{idx > 2 && items.value}</div>))}</>
                          </Tooltip>
                        }
                      >
                        <div style={{ "cursor": "pointer" }} className="chipVal">{this.state.selectedItems?.length - 3} More</div>
                      </OverlayTrigger>
                    </div>
                  )}
                </>
              )}

{this.props?.pageName === "Procedures" && (
                <>
                  {this.state.selectedItems?.length > 5 && (
                    <div className="chipSelect" onWheel={() => hoverOutEllipsis()}>
                      <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip id="button-tooltip">
                            <div>{this.state.selectedItems?.filter((x,i)=> i>4 )?.map(x=>x?.value).join(", ")}</div>
                          </Tooltip>
                        }
                      >
                        <div style={{ "cursor": "pointer" }} className="chipVal">{this.state.selectedItems?.length - 5} More</div>
                      </OverlayTrigger>
                    </div>
                  )}
                </>
              )}

              <button
                id="btnselection"
                type="button"
                tabIndex={  this.props?.tabIndex || 0}
                className={`${btnDisabled ? "btn disabled-area" : "addCoa"}`}
                onClick={this.handleClick}
                // this event enabble tabbing on this control
                onKeyUp={(e)=> (this.props?.tabIndex && e?.keyCode === 9 )? (this.state.opened === false? this.handleClick() : this.handleOutsideClick(e)):{}}
                disabled={btnDisabled ? true :
                  this.props.managePermission === undefined
                    ? false
                    : !this.props.managePermission
                }
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  tabIndex={   this.props?.tabIndex ? (this.props?.tabIndex+1) : 0}
                  onKeyUp={(e)=> (this.props?.tabIndex && e?.keyCode === 9 )? (this.state.opened === false? this.handleClick() : this.handleOutsideClick(e)):{}}

                >
                  <path d="M7.9999 2.3999C7.59665 2.3999 7.26947 2.72709 7.26947 3.13034V7.26947H3.13034C2.72709 7.26947 2.3999 7.59665 2.3999 7.9999C2.3999 8.40315 2.72709 8.73034 3.13034 8.73034H7.26947V12.8695C7.26947 13.2727 7.59665 13.5999 7.9999 13.5999C8.40315 13.5999 8.73034 13.2727 8.73034 12.8695V8.73034H12.8695C13.2727 8.73034 13.5999 8.40315 13.5999 7.9999C13.5999 7.59665 13.2727 7.26947 12.8695 7.26947H8.73034V3.13034C8.73034 2.72709 8.40315 2.3999 7.9999 2.3999Z" />
                </svg>
              </button>
            </div>
            <div className="coaSearch">
              <div className="dropDownPanel">
                <div className="SearchInput">
                  <input
                    key={"text-Filter"}
                    type="text"
                    className="form-control"
                    onChange={this.handleChange}
                    placeholder={this.state.filtertitle}
                    value={this.state.inputValue}
                  />
                </div>
                <ul>
                  {this.state.filterData.map((item: any, idx: any) => (
                    <li id={idx} key={idx} onWheel={() => hoverOutEllipsis()}
                      style={this.props?.pageName === "InvoiceApproval" ? !this.props?.isConfiCOAPerm && item.isconfidentialcoa !== "" ? { pointerEvents: "none", opacity: 0.2 } : item.status?.toLowerCase() === "inactive" && item.isChecked ? {} : item.status?.toLowerCase() === "inactive" ? { pointerEvents: "none", opacity: 0.2 } : {} : {}}
                    >
                      <EllipsisWithTooltip placement="bottom">
                        <Form.Check
                          custom
                          type="checkbox"
                          key={"custom-" + idx}
                          id={"custom-" + idx}
                          label={item.description}
                          checked={item.isChecked}
                          onChange={this.handleCheckCheckbox(item.value)}
                        />
                      </EllipsisWithTooltip>
                    </li>
                  ))}
                </ul>
                {this.state.isSelectAll && (
                  <div className="footer">
                    {this.state.isSelect && (
                      <button
                        id="selectAll"
                        type="button"
                        className="btn wht-bg txt-green"
                        onClick={this.handleSelectAll}
                      >
                        Select All
                      </button>
                    )}
                    {!this.state.isSelect && (
                      <button
                        id="ClearAll"
                        type="button"
                        className="btn wht-bg txt-green"
                        onClick={this.handleClearAll}
                      >
                        Clear All
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    );
  }
}
