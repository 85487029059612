import * as React from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ModuleReports } from "../../Common/Services/ModuleReports";

export class EmployeeWageHoursExtendedReportSortSelector extends React.Component<any, any> {


  private sortParameters = [
    { label: "Position", value: "Position" },
    { label: "Department", value: "Department" },
  
  ];


  constructor(props: any) {
    super(props);
    
    this.state = {
      selectedItem: { label: "Position", value: "Position" },
      sortParameters: this.sortParameters,
    };
  }

  componentDidMount = () => {
    
    const { defaultValue } = this.props;
    if (defaultValue === "Position") {
      this.handleDropdownChange(    { label: "Position", value: "Position" });
    } else {
      this.handleDropdownChange(this.state.selectedItem);
    }
  };


  handleDropdownChange = (item: any) => {
      
   this.setState({ selectedItem: item });
    //this.props?.handleCOAType(item.value);
    this.props?.handleEmployeeWageExtendedReportSortSelectorChange(item.value);
  };
   
  componentDidUpdate = (prevprops, prevState) => {
    const { defaultValue } = this.props;
    if (((prevprops.defaultValue !== defaultValue)) && defaultValue?.length > 0) {
      let arr=this.state.sortParameters.filter(e=>e.value===defaultValue);
      this.setState({ selectedItem:arr.length>0?arr[0]:  { label: "Position", value: "Position" }});
    }
    if (!defaultValue && prevprops?.defaultValue !== defaultValue) {
      this.setState({ selectedItem: { label: "Position", value: "Position" }});
    } 
  }

  render() {

    return (
      <Dropdown className="single-select">
        <Dropdown.Toggle id="Modal-empType">
          <span
              style={{
                width: "95%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "block",
              }}
            >
              {this.state.selectedItem.label}
            </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div className="list-area">
            {this.state.sortParameters.map((item: any, idx: any) => (
             
                <Dropdown.Item onClick={() => this.handleDropdownChange(item)}>
                  {item.label}
                </Dropdown.Item>
            ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

