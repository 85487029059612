import * as React from "react";
import { Form, Container, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import DatePicker from "react-datepicker";
import _ from "lodash";
import { resolve, reject } from "q";
import moment from "moment";

import { PmsMonitoringService as pmsMonitoringService } from "../../../Common/Services/PmsMonitoringService";
import { ReactPageLoader } from "../../../Common/Components/ReactPageLoader";
import { ToastContainer, toast } from "react-toastify";
import { DropDownList } from "../../Reports/common-components/dropdown-list";
import { Utils } from "../../../Common/Utilis";

import "./pmsImport.scss";

export class HotelKeyHistorical extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            isPermission: "No",
            hotelkeyData: [],
            hotelKeyHistoricalData: [],
            isLoader: false,
            tenantsList: [],
            tenantData: {
                userUniqueno: this.props?.userUniqueNo,
                tenant: 0
            },
            hotelList: [],
            hotelData: {
                hotelid: 0,
                lettercode: ""
            },
            minStartDate: null,
            maxStartDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            startDate: null,
            toDate: null,
            isUserNameNull: true,
            isRetrieveDisabled: true,
            showTable: false,
            pmsList: [
                { pmsid: 1, pms: "HOTELKEYAPI" },
                { pmsid: 2, pms: "CLOUDBEDS_API" }
            ],
            selectedpms: ""

        };
    }

    componentDidMount() {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : storage.tenantID;
        if (tenantID !== 230) {
            window.location.href = "/unauthorize-access";
        } else {
            this.setState({ isPermission: "Yes" },
                () => this.getHotelkeyAPI()
            )
        }
    }

    getHotelkeyAPI = () => {
        pmsMonitoringService.GetHotelkeyAPI()
            .then(async (result: any | null) => {
                if (result.length > 0) {
                    this.setState({ hotelkeyData: result }, () => this.getTenantsList(result))
                }
                else {
                    this.setState({ hotelkeyData: [] });
                }
                resolve();
            })
            .catch((error) => {
                this.setState({ hotelkeyData: [] }, () => {
                    Utils.toastError(error.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        containerId: "PmsMonitoring",
                    });
                });
                reject();
            })
    }

    getTenantsList = (result) => {
        let uniqueTenantList = _.uniqBy(result, "tenantid");
        uniqueTenantList.map((items, index) => ((items["id"] = index, items["tenantname"] = items.tenantid)));

        this.setState({ tenantsList: uniqueTenantList })
    }

    handleTenantChange = (event, inputPosition, isBlur = false) => {
        const { tenantData, hotelData } = this.state;
        let value = event.target.value;
        const fieldName = event.target.name;
        tenantData[fieldName] = value;
        hotelData.hotelid = 0;
        hotelData.lettercode = "";

        this.setState({
            tenantData, hotelList: [], hotelData, showTable: false, isUserNameNull: true, isRetrieveDisabled: true, selectedpms: ""
        }, () => {
            this.getHotelsListFromTenantId(value);
        });
    }

    getHotelsListFromTenantId = (selectedTenantId) => {
        const { hotelkeyData } = this.state;
        let hotels = _.uniqBy(hotelkeyData, "hotelid");
        let uniqueHotelsFromTenant = hotels.filter(x => x.tenantid === selectedTenantId);
        uniqueHotelsFromTenant.map((items, index) => (items["id"] = index));

        this.setState({ hotelList: uniqueHotelsFromTenant });
    }

    onEhidChange = (event, inputPosition, isBlur = false) => {
        const { tenantData, hotelData, hotelkeyData } = this.state;
        let value = event.target.value;
        let name = event.target.name;
        let isUserNameNull = false;

        hotelData["lettercode"] = value;
        hotelData["hotelid"] = name;

        isUserNameNull = hotelkeyData.some(x => {
            return x.tenantid === tenantData.tenant &&
                x.hotelid === name &&
                x.username == null && x.pmsType.toUpperCase() == "HOTELKEYAPI";
        });
        const pmsSetupDate = hotelkeyData.find(item => item.tenantid === tenantData.tenant && item.lettercode === value)?.startDate;
        const pms = hotelkeyData.find(item => item.tenantid === tenantData.tenant && item.lettercode === value)?.pmsType;

        this.setState({
            hotelData, isUserNameNull, showTable: false,
            toDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)), minStartDate: new Date(pmsSetupDate), selectedpms: ""
        }, () => {
            this.setState({ selectedpms: pms })
        });
        if (isUserNameNull) {
            this.setState({ isRetrieveDisabled: true })
        } else {
            this.setState({ isRetrieveDisabled: false })
        }
    }

    handleFromDateChange = (date) => {
        this.setState({ startDate: date, toDate: date }); // Set toDate to the same value as startDate
    };

    handleToDateChange = (date) => {
        const { startDate } = this.state;
        const maxDate = this.getMaxDate(startDate);

        if (date >= startDate && date <= maxDate) {
            this.setState({ toDate: date });
        }
    };

    // Function to calculate the maximum date for the "To Date"
    getMaxDate = (startDate) => {
        const today = new Date(new Date().setDate(new Date().getDate() - 1));
        const maxDate = new Date(startDate);
        maxDate.setDate(startDate.getDate() + 89);

        // Ensure toDate is not in the future
        return maxDate > today ? today : maxDate;
    };

    retrieveData = () => {
        const { startDate, hotelData, selectedpms } = this.state;
        if (selectedpms.toUpperCase() == "CLOUDBEDS_API") {
            let request: any = {};
            request.BackHid = hotelData.hotelid;
            request.ReqDate = moment(startDate).format('YYYY-MM-DD');
            debugger;
            pmsMonitoringService.cloudBedsCheckAccPeriod(request)
                .then(async (result: any | null) => {
                    if (result.message === "Success") {
                        if (result.result.length > 0) {
                            if (result.result[0].result == "1") {
                                this.getHotelKeyAPIQueue();
                            } else {
                                Utils.toastError("Unable to retrieve the data as Accounting period is closed.", {
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                    containerId: "PmsMonitoring",
                                });
                            }
                        } else {
                            Utils.toastError("Unable to retrieve the data as Accounting period is closed.", {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                containerId: "PmsMonitoring",
                            });
                        }
                    }
                })
        } else {
            this.getHotelKeyAPIQueue();
        }
    }

    getHotelKeyAPIQueue = () => {
        const { startDate, toDate, hotelData, tenantData, selectedpms } = this.state;

        let request: any = {};
        request.Hid = hotelData.hotelid;
        request.Datefrom = selectedpms.toUpperCase() == "CLOUDBEDS_API" ? moment(startDate).format('YYYY-MM-DD') : moment(startDate).format('MM/DD/YYYY');
        request.Dateto = selectedpms.toUpperCase() == "CLOUDBEDS_API" ? moment(toDate).format('YYYY-MM-DD') : moment(toDate).format('MM/DD/YYYY');
        request.HTenantId = tenantData.tenant;
        request.pms = selectedpms;

        this.setState({ isLoader: true })

        pmsMonitoringService.GetHotelKeyAPIQueue(request)
            .then(async (result: any | null) => {
                if (result.message === "Success") {
                    this.setState({ hotelKeyHistoricalData: result.result, showTable: true, isLoader: false })
                }
                else {
                    this.setState({ hotelKeyHistoricalData: [], showTable: false, isLoader: false }, () => {
                        Utils.toastError(result.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            containerId: "PmsMonitoring",
                        });
                    });
                }
                resolve();
            })
            .catch((error) => {
                this.setState({ hotelKeyHistoricalData: [], showTable: false, isLoader: false }, () => {
                    Utils.toastError(error.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        containerId: "PmsMonitoring",
                    });
                });
                reject();
            });
    }

    dateFormatter(cell: any, row: any, rowIndex: any) {
        return (
            <div>{moment(row.date).format('MM/DD/YYYY')}</div>
        );
    }

    propertyCodeFormatter(cell: any, row: any, rowIndex: any) {
        return (
            <div>{row.propertyCode}</div>
        );
    }

    pullStatusFormatter(cell: any, row: any, rowIndex: any) {
        return (
            <div className={row.pullStatus === "Yes" ? "dot-icon green" : "dot-icon red"}></div>
        );
    }

    addedtoQueueFormatter(cell: any, row: any, rowIndex: any) {
        return (
            <div className={row.addedtoQueue === "Yes" ? "dot-icon green" : "dot-icon red"}></div>
        );
    }

    isEntriesFormatter(cell: any, row: any, rowIndex: any) {
        return (
            <div>{row.isEntries === 1 ? "Manual entry found" : ""}</div>
        );
    }
    render() {
        const { hotelKeyHistoricalData, tenantsList, hotelList, minStartDate, maxStartDate, startDate, isLoader, toDate, showTable, isPermission, isUserNameNull, isRetrieveDisabled,
            tenantData: {
                tenant
            },
            hotelData: {
                hotelid
            }
            , pmsList, selectedpms } = this.state;

        const columns = [
            {
                dataField: "date",
                text: "Date",
                formatter: this.dateFormatter
            }, {
                dataField: "propertyCode",
                text: "Property Code",
                formatter: this.propertyCodeFormatter
            }, {
                dataField: "pullStatus",
                text: "Pull Status",
                formatter: this.pullStatusFormatter
            }, {
                dataField: "addedtoQueue",
                text: "Added to Queue",
                formatter: this.addedtoQueueFormatter
            },
            {
                dataField: "isEntries",
                text: "",
                formatter: this.isEntriesFormatter
            }
        ];

        return (
            <Container fluid className="body-sec pms-import">
                <ToastContainer
                    autoClose={3000}
                    containerId={"PmsMonitoring"}
                    enableMultiContainer
                />
                {isPermission === "Yes" && (
                    <>
                        <div className="page-heading underline d-flex">
                            <div className="mr-auto d-flex align-items-center">PMS API Historical</div>
                        </div>
                        <div className="main-Page">
                            <div className="bdy-sec">
                                <div className="search-header">
                                    <div className="filter-section d-flex align-items-end">
                                        <Form.Group controlId="tenant" >
                                            <Form.Label>Tenant</Form.Label>
                                            {(tenantsList && tenantsList.length > 0 && <DropDownList
                                                defaultTextColor={true}
                                                placeHolder={"Search Tenant"}
                                                data={tenantsList}
                                                isSearchRequired={true}
                                                label={"tenantname"}
                                                value={"tenantid"}
                                                onDropDownChange={(item) => {
                                                    if (!item) {
                                                        return;
                                                    }
                                                    this.handleTenantChange(
                                                        Utils.BuildCustomEventObject(
                                                            "tenant",
                                                            item.tenantid
                                                        ),
                                                        1
                                                    );
                                                }}
                                                selectedItem={[
                                                    ...[{ tenantid: 0, tenantname: "Select Tenant" }],
                                                    ...tenantsList,
                                                ].find((r) => +r.tenantid === tenant)}
                                            />
                                            )}
                                        </Form.Group>
                                        {(hotelList && hotelList.length > 0 &&
                                            <Form.Group controlId="ehid" >
                                                <Form.Label>EHID</Form.Label>
                                                {(hotelList && hotelList.length > 0 && <DropDownList
                                                    defaultTextColor={true}
                                                    placeHolder={"Search EHID"}
                                                    data={hotelList}
                                                    isSearchRequired={true}
                                                    label={"lettercode"}
                                                    value={"hotelid"}
                                                    onDropDownChange={(item) => {
                                                        if (!item) {
                                                            return;
                                                        }
                                                        this.onEhidChange(
                                                            Utils.BuildCustomEventObject(
                                                                item.hotelid,
                                                                item.lettercode
                                                            ),
                                                            1
                                                        );
                                                    }}
                                                    selectedItem={[
                                                        ...[{ hotelid: 0, lettercode: "Select EHID" }],
                                                        ...hotelList,
                                                    ].find((r) => +r.hotelid === hotelid)}
                                                />
                                                )}
                                            </Form.Group>
                                        )}
                                        {!isUserNameNull &&
                                            <>
                                                <Form.Group controlId="date">
                                                    <Form.Label>From Date</Form.Label>
                                                    <div className="date-picker calender-picker d-block">
                                                        <div className="drop-arrow">
                                                            <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M3.28122e-05 1.19724C3.28122e-05 0.889538 0.253262 0.653982 0.543288 0.653982C0.688297 0.653982 0.833313 0.708449 0.923855 0.816677L4.0023 4.0217L7.08074 0.816677C7.2979 0.599519 7.6424 0.599519 7.84116 0.816677C8.04064 1.01616 8.05831 1.34153 7.85955 1.55941L4.40046 5.18112C4.20098 5.39827 3.85721 5.39828 3.64005 5.19951L3.62166 5.18112L0.145009 1.55941C0.0544669 1.46887 0 1.34226 0 1.19724L3.28122e-05 1.19724Z" fill="#3F4347" />
                                                            </svg>
                                                        </div>
                                                        <DatePicker
                                                            className="form-control"
                                                            placeholderText={"Select Date"}
                                                            dateFormat="MM/dd/yyyy"
                                                            autoComplete="off"
                                                            selected={startDate}
                                                            minDate={selectedpms.toUpperCase() == "CLOUDBEDS_API" ? new Date(new Date().setDate(new Date().getDate() - 90)) : minStartDate}
                                                            //minDate={minStartDate}
                                                            onChange={(date) => this.handleFromDateChange(date)}
                                                            maxDate={maxStartDate}
                                                        />
                                                    </div>
                                                </Form.Group>
                                                <Form.Group controlId="date">
                                                    <Form.Label>To Date</Form.Label>
                                                    <div className="date-picker calender-picker d-block">
                                                        <div className="drop-arrow">
                                                            <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M3.28122e-05 1.19724C3.28122e-05 0.889538 0.253262 0.653982 0.543288 0.653982C0.688297 0.653982 0.833313 0.708449 0.923855 0.816677L4.0023 4.0217L7.08074 0.816677C7.2979 0.599519 7.6424 0.599519 7.84116 0.816677C8.04064 1.01616 8.05831 1.34153 7.85955 1.55941L4.40046 5.18112C4.20098 5.39827 3.85721 5.39828 3.64005 5.19951L3.62166 5.18112L0.145009 1.55941C0.0544669 1.46887 0 1.34226 0 1.19724L3.28122e-05 1.19724Z" fill="#3F4347" />
                                                            </svg>
                                                        </div>
                                                        <DatePicker
                                                            className="form-control"
                                                            placeholderText="Select Date"
                                                            dateFormat="MM/dd/yyyy"
                                                            autoComplete="off"
                                                            selected={toDate}
                                                            onChange={(date) => this.handleToDateChange(date)}
                                                            minDate={selectedpms.toUpperCase() == "CLOUDBEDS_API" ? toDate : startDate} // Restrict past dates
                                                            maxDate={selectedpms.toUpperCase() == "CLOUDBEDS_API" ? toDate : this.getMaxDate(startDate)} // 90-day restriction
                                                        // minDate={startDate} // Restrict past dates
                                                        // maxDate={this.getMaxDate(startDate)} // 90-day restriction
                                                        />
                                                    </div>
                                                </Form.Group>
                                            </>
                                        }
                                        {(pmsList && pmsList.length > 1 && selectedpms != "" &&
                                            <Form.Group controlId="type" >
                                                <Form.Label>PMS Type</Form.Label>
                                                <DropDownList
                                                    defaultTextColor={true}
                                                    placeHolder={"Search PMS"}
                                                    data={pmsList}
                                                    isSearchRequired={true}
                                                    label={"pms"}
                                                    value={"pmsid"}
                                                    disabled
                                                    onDropDownChange={(item) => {
                                                        if (!item) {
                                                            return;
                                                        }
                                                    }}

                                                    selectedItem={pmsList.filter(r => r.pms === selectedpms.toUpperCase())[0]}
                                                />
                                            </Form.Group>
                                        )}
                                        <Form.Group controlId="button">
                                            <Button variant="primary"
                                                onClick={this.retrieveData}
                                                disabled={isRetrieveDisabled}
                                            >Retrieve</Button>
                                        </Form.Group>
                                    </div>
                                </div>
                                {isLoader ? (
                                    <ReactPageLoader useas={"pmsMonitoring"} />
                                ) : (
                                    showTable && <div>
                                        <BootstrapTable
                                            keyField='id'
                                            data={hotelKeyHistoricalData}
                                            columns={columns}
                                            noDataIndication="No matching record found"
                                        />
                                    </div>
                                )}
                                {hotelid !== 0 && isUserNameNull && <div className="no-record msg">Username & Password are not available for selected EHID</div>}
                            </div>
                        </div>
                    </>
                )}
            </Container>
        )
    }
}