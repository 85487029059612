import Instense from "./Axios-config";
import {
  IGetPayrollExportReciepentData,
  ISavePayrollExportReciepentData,
  ISaveCodeMappingData,
} from "../Contracts/IPayrollProcessor";

export class PayrollProcessor {
  public static getExportTypeFormat = async (): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request: any = {};
    request.tenantid = tenantID;
    const url = `/LaborSchedule/GetLaborExportTypeFormat`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static getExportReciepentData = async (
    request: IGetPayrollExportReciepentData
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let user_UniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    request.TenantID = `${tenantID}`;
    request.userUniqueNo = `${user_UniqueID}`;
    const url = `/LaborSchedule/GetLaborExportAccountMail`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static getCodeMappingData = async (
    request: IGetPayrollExportReciepentData
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let user_UniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    request.TenantID = `${tenantID}`;
    request.userUniqueNo = `${user_UniqueID}`;
    const url = `/LaborSchedule/Get_LaborExportCodeMapping`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static isAllowanceExistForUser = async (
    srNo: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    const request={
      TenantID: `${tenantID}`,
      uniqueno: srNo,
    } 
  
    const url = `/payroll/AllowAllowanceToInactivate`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static saveExportReciepentData = async (
    request: ISavePayrollExportReciepentData
  ): Promise<any | null> => {
    const url = `/LaborSchedule/SaveLaborExportMailAccount`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result.result;
  };

  public static saveCodeMappingData = async (
    request: ISaveCodeMappingData
  ): Promise<any | null> => {
    const url = `/LaborSchedule/SaveLaborLaborExportCode`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result.result;
  };
}
