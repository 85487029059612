import React from "react";
import {
  // Button,
  Container,
} from "react-bootstrap";
// import { ToastContainer } from "react-toastify";
import { ReportExport } from "../../Common/Services/ReportExport";
import { ReportViewer } from "../Reports/ReportViewer";
import { ToastContainer } from "react-toastify";
import { LaborPerformance as laborPerformance } from "../../Common/Services/LaborPerfomance";

export class DetailsReportViwer extends React.Component<any, any> {
  private linkRef: any;
  private scheduleUserViewRef: any;
  constructor(props: any) {
    //
    super(props);
    this.scheduleUserViewRef = React.createRef();
    this.linkRef = React.createRef();
    this.state = {
      permissionManageSchedule: true,
      permissionManageTimeOff: true,
      parameterset: false,
      addEditTimeOffShiftModal: false,
      navigationCalenderDates: [],
      hidValue: 0,
      fromPage: "DetailsReport",
    };
  }
  componentDidMount() {
    this.loadReport();
    this.getPayPeriod();
  }

  getFormattedDate(date) {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : "0" + month;
    let day = date.getDate().toString();
    day = day.length > 1 ? day : "0" + day;
    return month + "/" + day + "/" + year.toString().slice(-2);
  }
  addEditTimeOffShiftModalShow = () => {
    this.setState({
      addEditTimeOffShiftModal: true,
    });
  };
  addEditTimeOffShiftModalHide = (successMessage = "") => {
    this.setState({
      addEditTimeOffShiftModal: false,
    });
  };

  getParamValueByParamName(paramName: string): any {
    const valuesArray = decodeURIComponent(this.props.history.location.search)?.replace('?', '')?.split('&');
    const item = valuesArray?.find(r => r?.includes(paramName));
    return item?.split('=')[1] as any; //? window.atob(item?.split('=')[1] as any) : "";
  }

  loadReport() {
    let reportParam = [] as any;
    const storage = JSON.parse(localStorage.getItem("storage")!);
    let username = storage === null ? 0 : (storage.userName as any);
    let tenantId = storage === null ? 0 : (storage.tenantID as any);

    reportParam.push({
      name: "ReportName",
      value: "Report Details",
    });
    reportParam.push({
      name: "userName",
      value: username,
    });
    reportParam.push({
      name: "UniqueNo",
      value: this.getParamValueByParamName("UniqueNo")
    });

    reportParam.push({
      name: "SplitHID",
      value: this.getParamValueByParamName("SplitHID")
    });
    reportParam.push({
      name: "Ctype",
      value: this.getParamValueByParamName("Ctype")
    });
    reportParam.push({
      name: "StartDate",
      value: this.getParamValueByParamName("StartDate"), //
    });
    reportParam.push({
      name: "AccountingPeriodFrom",
      value: this.getParamValueByParamName("StartDate"), //AccountingPeriodFrom
    });
    reportParam.push({
      name: "EndDate",
      value: this.getParamValueByParamName("EndDate"),// 
    });
    reportParam.push({
      name: "AccountingPeriodThrough",
      value: this.getParamValueByParamName("EndDate"),// AccountingPeriodThrough
    });
    reportParam.push({
      name: "ReportType",
      value: this.getParamValueByParamName("ReportType"),
    });
    reportParam.push({
      name: "OprID",
      value: this.getParamValueByParamName("OprID"),
    });
    reportParam.push({
      name: "OprID",
      value: this.getParamValueByParamName("OprID"),
    });
    reportParam.push({
      name: "reportId",
      value: this.getParamValueByParamName("reportId")
    });
    reportParam.push({
      name: "SelectPL",
      value: this.getParamValueByParamName("SelectPL")
    });
    reportParam.push({
      name: "COA",
      value: this.getParamValueByParamName("COA")
    });
    reportParam.push({
      name: "Type",
      value: this.getParamValueByParamName("Type") // 
    });
    reportParam.push({
      name: "DDLType",
      value: this.getParamValueByParamName("Type") // DDLType
    });
    reportParam.push({
      name: "AccountType",
      value: this.getParamValueByParamName("AccountType")
    });
    reportParam.push({
      name: "GroupID",
      value: this.getParamValueByParamName("GroupID")
    });
    reportParam.push({
      name: "SubGroupID",
      value: this.getParamValueByParamName("SubGroupID")
    });
    reportParam.push({
      name: "SubGroupID2",
      value: this.getParamValueByParamName("SubGroupID2")
    });
    reportParam.push({
      name: "RowType",
      value: this.getParamValueByParamName("RowType")
    });
    reportParam.push({
      name: "IsTotal",
      value: this.getParamValueByParamName("IsTotal")
    });
    reportParam.push({
      name: "IsVariable",
      value: this.getParamValueByParamName("IsVariable")
    });
    reportParam.push({
      name: "tenantId",
      value: tenantId,
    });
    reportParam.push({
      name: "Hid",
      value: this.getParamValueByParamName("Hid")  // Hotelid
    });
    reportParam.push({
      name: "Hotelid",
      value: this.getParamValueByParamName("SplitHID")  // Hotelid
    });
    reportParam.push({
      name: "ItemName",
      value: this.getParamValueByParamName("ItemName")
    });
    reportParam.push({
      name: "SubGroupId",
      value: this.getParamValueByParamName("SubGroupId")
    });
    reportParam.push({
      name: "SubGroup",
      value: this.getParamValueByParamName("SubGroup")
    });
    reportParam.push({
      name: "LineGroup",
      value: this.getParamValueByParamName("LineGroup")
    });
    reportParam.push({
      name: "IsCOS",
      value: this.getParamValueByParamName("IsCOS")
    });
    reportParam.push({
      name: "IsMOD",
      value: this.getParamValueByParamName("IsMOD")
    });
    reportParam.push({
      name: "IsFB",
      value: this.getParamValueByParamName("LineGroup")
    });
    reportParam.push({
      name: "DDLReportType",
      value: this.getParamValueByParamName("DDLReportType")
    });
    reportParam.push({
      name: "IsUnApprove",
      value: this.getParamValueByParamName("IsUnApprove")
    });
    reportParam.push({
      name: "Vendor",
      value: this.getParamValueByParamName("Vendor")
    });
    reportParam.push({
      name: "Section",
      value: this.getParamValueByParamName("Section")
    });
    reportParam.push({
      name: "Section",
      value: this.getParamValueByParamName("Section")
    });
    reportParam.push({
      name: "lettercode",
      value: this.getParamValueByParamName("lettercode") //
    });
    reportParam.push({
      name: "chkBankBalance",
      value: "No" //chkBankBalance
    });
    reportParam.push({
      name: "IsHyperlink",
      value: "" //chkBankBalance
    });
    localStorage.setItem("reportParameters", JSON.stringify(reportParam));
    this.setState({ parameterset: true });
  }

  getPayPeriod() {
    let hotelSelected = JSON.parse(localStorage.getItem("hotelSelected")!);
    let hotelID = hotelSelected !== null ? hotelSelected.hotelID : "Select";
    this.setState({ hidValue: hotelID });
    let currentDay: any = new Date();
    if (JSON.parse(localStorage.storage).currentUtcTime !== "") {
      currentDay = new Date(JSON.parse(localStorage.storage).currentUtcTime);
    }
    laborPerformance.getPayPeriodDates(hotelID, currentDay, "Weekly").then(async (result: any[] | null) => {
      if (result != null) {
        this.setState({ navigationCalenderDates: [result[0].startdate, result[0].enddate] });
      }
    });
  }

  exportToPDF = () => {
    let reportParameters = JSON.parse(
      localStorage.getItem("reportParameters")!
    );
    reportParameters.push({ name: "writerFormat", value: "PDF" });
    ReportExport.reportDownload(JSON.stringify(reportParameters)).then(
      (result) => {
        const href = window.URL.createObjectURL(result);
        const a = this.linkRef.current;
        a.download = "Unbudgeted Hours.pdf";
        a.href = href;
        a.click();
        a.href = "";
      }
    );
  };
  addShiftRefresh = () => {
    if (this.state.viewType === "Users") {
      this.scheduleUserViewRef.current.getScheduleData("addShiftRefresh");
    } else {
      return false;
    }
  };

  render() {
    return (
      <div className="report-viewer-container">
        <ToastContainer autoClose={3000} containerId="scheduleMain" />
        {this.state.parameterset === true && (
          <div className="schedule-main">
            <Container fluid className="body-sec profile-pto">
              <div className="page-heading mrgn-btn-24 underline d-flex">
                <div className="mr-auto"></div>
                <div className="action-group">

                  {/*  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.exportToPDF}
                  >
                    Export PDF
                  </button>
                  {<a ref={this.linkRef}></a>} */}
                </div>
              </div>
              <div className="pto-report">
                <ReportViewer onReportload={this.props?.onReportload} />
              </div>

            </Container>
          </div>
        )}
      </div>
    );
  }
}
