import React from "react";
import { toast, ToastContainer } from "react-toastify";
import {
  Container,
  Dropdown,
  Form,
  Tab,
  Tabs,
  Row,
  Nav,
} from "react-bootstrap";
//import profile from "../../../Common/Assets/Images/profile.png";
//import profile from "../../Common/Assets/Images/profile.png";
import { confirmAlert } from "react-confirm-alert";
import profile from "../../../../Common/Assets/Images/user-no-image.svg";
import OutsideClickHandler from "react-outside-click-handler";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { LockInGeneral, IGeneralProps } from "./LockInGeneral";
import { LockInPayroll } from "./LockInPayroll";
import { UserManagement } from "../../../../Common/Services/UserManagement";
import { IGeneralInfo } from "../../../../Common/Contracts/IGeneralInfo";
import { UsersLogHistory } from "../UsersLogHistory";
import { IEnterPriseWisePayroll } from "../../../../Common/Contracts/IEnterpriseWisePayroll";
import { Utils } from "../../../../Common/Utilis";
let selectedRolesItem: any = [];
export class LockInUserDetailSlideOut extends React.Component<any, any> {
  private laborRef: any;
  private payrollRef: any;

  constructor(props: any) {
    super(props);
    this.laborRef = React.createRef();
    this.payrollRef = React.createRef();
    this.state = {
      activeTab: this.props?.selectedTab || "general"   ,
      userGeneralInfo: {},
      salesTabCount: 0,
      isGeneralTabChangesNotSaved: false,
      isLaborTabChangesNotSaved: false,
      isPayrollTabChangesNotSaved: false,
      hasPerformanceAccess: false,
      hasSalesAccess: false,
      generalTabCount: 0,
      laborTabCount: 0,
      payRollTabCount: 0,
      controlPosition: 10,
      phoneUpdateStatus:"",
      user: {
        FirstName: "",
        LastName: "",
        Email: "",
        PhoneNo: "",
        UserId: 0,
      },
      loader: false,
      showSalesTabCount: false,
      enterpriseAccess: false,
      enableEnterpriseAccess: true,
      tabs: [
        { tab: "general", order: 1 },
        { tab: "Labor", order: 2 },
        { tab: "Payroll", order: 3 },
        { tab: "Documents", order: 4 },
      ],
      showChangeLogModal: false,
      tabPermissions: [],
      isTabPermissionsLoaded: false,
      enterPriseWisePayrollData: {},
      isEnterpriseWideDataLoaded: false,
      clientSetupData: {},
      isClientSetupDataLoaded: false,
      sectionNames: []
    };
  }

  componentDidMount() {
    this.getUserGeneralInfo();
    this.getClientSetup();
  }

updatePhoneStatus = (phoneUpdateStatus)=>
{
  this.setState({phoneUpdateStatus});
}


  exitOnboarding = (isRedirectToHome = false)=> {
    debugger;
    localStorage?.setItem("showIncompleteSlideOut", "false");
    localStorage?.setItem("PopUpState","");
    const { userGeneralInfo,generalTabCount,payRollTabCount,phoneUpdateStatus} = this.state;
    isRedirectToHome = (userGeneralInfo?.isLowSecurityUser === 1 && payRollTabCount===0 )? true : false;
    if(!((((userGeneralInfo?.hasSpecialPermissionUsers && userGeneralInfo?.isTextMessage != 1 && userGeneralInfo?.isGoogleFA != 1) ||
      userGeneralInfo.primaryContact == "Phone")) && !userGeneralInfo.phone1
      && !userGeneralInfo?.isLowSecurityUser))
    {
      if((((userGeneralInfo?.phoneUpdateStatus || phoneUpdateStatus) ==="UpdateLater" ||
       (userGeneralInfo?.phoneUpdateStatus ||phoneUpdateStatus) === "NoPhone") || generalTabCount ===0)){
        if(isRedirectToHome || (generalTabCount ===0 && payRollTabCount>0)) {
          window.location.reload();
        } else {
          if(userGeneralInfo?.hasSpecialPermissionUsers && userGeneralInfo?.isTextMessage != 1 && userGeneralInfo?.isGoogleFA != 1){
            window.location.replace("/login");
          }
          else if(generalTabCount ===0 && payRollTabCount===0){
           window.location.reload();
          }else{
           window.location.replace("/login");
          }
        }
      }else{
        window.location.replace("/login");
      }
    }else {
      window.location.replace("/login");
    }
  }

  getClientSetup = (isDefaultLoad = false) => {
    this.setState({ isClientSetupDataLoaded: false });
    UserManagement.GetClientSetup()
      .then((data: any) => {
          this.setState({ clientSetupData: data?.result[0] },
             ()=> {
          this.setState({ isClientSetupDataLoaded: true });
        });
      })
      .catch(() => 
       this.setState({ isClientSetupDataLoaded: true 
       }));
  };


  getEnterPriseWisePayrollData = (isDefaultLoad = false) => {
    this.setState({ isEnterpriseWideDataLoaded: false });
    UserManagement.GetEnterPriseWisePayroll(this.state.userGeneralInfo?.uniqueno,'lockslideout')
      .then((enterPriseWisePayrollData: IEnterPriseWisePayroll) => {
        if (isDefaultLoad) {
          this.setState({
            prevEnterPriseWisePayrollData: enterPriseWisePayrollData,
          });
        }
        // if(!enterPriseWisePayrollData?.ssn) {
        //  this.setState({payRollTabCount: 1}); 
        // } 
        this.setState({ enterPriseWisePayrollData });
      })
      .finally(() => this.setState({ isEnterpriseWideDataLoaded: true }));
  };


  getGeneralTabModificationStatus = (isGeneralTabChangesNotSaved = false) => {
    this.setState({ isGeneralTabChangesNotSaved });
  };

  getLaborTabModificationStatus = (isLaborTabChangesNotSaved = false) => {
    this.setState({ isLaborTabChangesNotSaved });
  };

  getPayrollTabModificationStatus = (isPayrollTabChangesNotSaved = false) => {
    this.setState({ isPayrollTabChangesNotSaved });
  };

  checkIfOnlyAccountingAccess = (userGeneralInfo): boolean => {
    let hasAccess = false;
    if (userGeneralInfo?.subscriptionModule?.length > 0) {
      hasAccess =
        userGeneralInfo?.subscriptionModule?.filter(
          (item) => item.result?.toString() !== "Accounting" && item.result?.toString() !== "Accounting Services"
        )?.length === 0;
    }
    return hasAccess;
  };
  

  closeSlideOut = () => {
    this.props.closeSlideOut();
  };

  closeSlideOutWithConfirmation = () => {
    const { isGeneralTabChangesNotSaved, isLaborTabChangesNotSaved,isPayrollTabChangesNotSaved } =
      this.state;

    if (isGeneralTabChangesNotSaved || isLaborTabChangesNotSaved|| isPayrollTabChangesNotSaved) {
      this.confirmationOnSlideoutClose();
      return;
    }
    this.props.closeSlideOut();
  };

  setTabSelection = (selectedTab) => {
    this.getUserGeneralInfo();
    if(selectedTab === "payroll") {
    this.getEnterPriseWisePayrollData();
    }

    this.setState({
      activeTab: selectedTab,
      isGeneralTabChangesNotSaved: false,
      isLaborTabChangesNotSaved: false,
      isPayrollTabChangesNotSaved: false
    });
  };

  setTabSelectionFromGuide = (selectedTab) => {
    if (this.state.generalTabCount > 0) {
      if (selectedTab === "general") {
        Utils.toastError("You are on the same tab", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "userSlideOut",
        });
        return false;
      }
      
    }

    if (
      this.state.userGeneralInfo.status !== "Active" &&
      selectedTab === "sales"
    ) {
      return false;
    }


    if ( this.getLaborTabPTOCount()>0 &&
      selectedTab === "payroll"
    ) {
      return false;
    }


    this.setState({
      activeTab: selectedTab,
      isGeneralTabChangesNotSaved: false,
      isLaborTabChangesNotSaved: false,
      isPayrollTabChangesNotSaved: false,
      
    });
  };

  stayOnSameTab = () => {
    const { activeTab } = this.state;
  };

  handleSalesTabCount = (showSalesTabCount) => {
    this.setState({ showSalesTabCount });
  };

  confirmationOnTabChange = (selectedTab) => {
    if (this.state.activeTab === "labor") {
      this.setState({ controlPosition: 20 });
    }
    if (this.state.activeTab === "payroll") {
      this.setState({ controlPosition: 30 });
      
    }


    confirmAlert({
      title: "Unsaved confirmation?",
      message:
        "You have unsaved changes on this page. Do you want to leave this page and discard your changes?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.setTabSelection(selectedTab),
        },
        {
          label: "No",
          onClick: () => this.stayOnSameTab(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  confirmationOnSlideoutClose = () => {
    confirmAlert({
      title: "Unsaved confirmation?",
      message:
        "You have unsaved changes on this page. Do you want to leave this page and discard your changes?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.closeSlideOut(),
        },
        {
          label: "No",
          onClick: () => this.stayOnSameTab(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  setActiveTab = (selectedTab) => {
    const {
      isGeneralTabChangesNotSaved,
      isLaborTabChangesNotSaved,
      isPayrollTabChangesNotSaved,
      activeTab,
    } = this.state;

    if (activeTab === selectedTab) {
      return;
    }
    if(selectedTab === "payroll") {
      this.getEnterPriseWisePayrollData();
      this.payrollRef?.current?.getHotelWisePayrollData();
    }

    if(selectedTab === "labor") {
      this.laborRef?.current?.getHotelWisePayrollData();
    }

   


    if(selectedTab === "documents"  &&  this.state?.userGeneralInfo?.status === "Incomplete" ) {
      Utils.toastError("Please complete user to access Documents Tab", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "userSlideOut",
      });
      return;
    }

    if(selectedTab === "labor"  &&  this.state?.userGeneralInfo?.status === "Incomplete" && this.state?.generalTabCount>0) {
      Utils.toastError("Please complete General Tab to access Labor Tab", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "userSlideOut",
      });
      return;
    }




    switch (activeTab) {
      case "general":
        if (isGeneralTabChangesNotSaved) {
          this.confirmationOnTabChange(selectedTab);
          return;
        }
        break;

      case "labor":
        if (isLaborTabChangesNotSaved) {
          this.setState({ controlPosition: 20 }, () =>
            this.setState({ controlPosition: 0 })
          );

          this.confirmationOnTabChange(selectedTab);

          return;
        }
        break;

        case "payroll":
          if (isPayrollTabChangesNotSaved) {
            this.setState({ controlPosition: 30 }, () =>
              this.setState({ controlPosition: 0 })
            );
  
            
            this.confirmationOnTabChange(selectedTab);
  
            return;
          }
    
          break;

      default:
        break;
    }

    this.setState({ activeTab: selectedTab });
  };

  removeHighlightedColor = (obj, textToSearch) => {
    if (obj.children.length > 0) {
      for (let i = 0; i < obj.children.length; i++) {
        this.removeHighlightedColor(obj.children[i], textToSearch);
        obj.backColorClass = "";
      }
    } else {
      obj.backColorClass = "";
    }
  };



  getUserGeneralInfo = (reDirectFromPayroll =false ) => {
    
    UserManagement.GetUserGeneralInfo(this.props.userData?.user_UniqueID).then(
      (userGeneralInfo: IGeneralInfo) => {
        if(!reDirectFromPayroll) {
          if(!userGeneralInfo.showIncompleteSlideOut ) {
            if( (!userGeneralInfo?.isMissingEmail && !userGeneralInfo?.isMissingPhone) && (!this.checkHasPayrollUNModuleAccess(userGeneralInfo)  || reDirectFromPayroll ) || (this.checkHasPayrollUNModuleAccess(userGeneralInfo) && this.state?.payRollTabCount === 0)) {
              localStorage?.setItem("showIncompleteSlideOut", "false");
              localStorage?.setItem("PopUpState","");
               window.location.reload();
             return ;
              }
          
           }
        }
        




        let generalTabCount = 0;
        let sectionNames: any[] = [];
        console.log("out")
        if ((!userGeneralInfo.email || userGeneralInfo.isDuplicateEmail) || ((!userGeneralInfo.phone1 || userGeneralInfo.isDuplicatePhone) && (userGeneralInfo.primaryContact === "Phone" || userGeneralInfo?.hasSpecialPermissionUsers))
        ) {
          
          if(!userGeneralInfo?.isLowSecurityUser )
          {
            generalTabCount = generalTabCount + 1;
            if(!userGeneralInfo.email) {

              sectionNames.push("Add an Email Address");
            } 
            if((!userGeneralInfo.phone1 && userGeneralInfo.primaryContact === "Phone")) {
              sectionNames.push("Add a Mobile Phone Number");
            }
          }
        

        }
        else if(userGeneralInfo.hasSpecialPermissionUsers && userGeneralInfo.isTextMessage !== 1 && userGeneralInfo.isGoogleFA !== 1){
          generalTabCount = generalTabCount + 1;
        } else {
          sectionNames = [];
        }
        // if(this.state.sectionNames.filter(x=> x ==="Configure Payroll") && this.state.sectionNames.length ===1) {
        //   sectionNames.push("Configure Payroll");
        //  }
        let salesTabCount = 0;
        const hasPerformanceAccess =
          this.checkHasLaborAccess(userGeneralInfo);
          const hasSalesAccess =  this.checkHasSalesAccess(userGeneralInfo);
        this.setState(
          { userGeneralInfo: {}, hasPerformanceAccess,hasSalesAccess },
          () => {
            this.setState({
              userGeneralInfo,
              generalTabCount,
              salesTabCount,
            }, ()=> {
              this.getEnterPriseWisePayrollData();
            });
          }
        );
      }
    );
    this.getUMPermissions();
  };

  getUMPermissions = () => {
    this.setState({ tabPermissions: [], isTabPermissionsLoaded: false });
    UserManagement.GetUMPermissions()
      .then((tabPermissions) => {
        this.setState({ tabPermissions }, () =>
          this.setState({ isTabPermissionsLoaded: true })
        );
      })
      .catch(() => {
        this.setState({ isTabPermissionsLoaded: true });
      });
  };

  getLaborTabPTOCount = () => {
    const { laborTabCount } = this.state;

    return laborTabCount;
  };

 
  
  updatePayrollCount = (payrollSectionValue=0,depositSectionValue=0) => {
    let count=0;
    count+=depositSectionValue;

   const userGeneralInfo:IGeneralInfo =   this.state.userGeneralInfo  as IGeneralInfo;    

 

    let {sectionNames} =this.state;
    if(count> 0 
  &&  this.state.userGeneralInfo?.employeeType!== "ExcludefromPayroll" 
    ) {
    sectionNames.push("Add Direct Deposit Information");
    } else {
      sectionNames=  sectionNames.filter(x=> x!=="Add Direct Deposit Information");
    }
  
    this.setState({payRollTabCount:count, sectionNames});
  };

  getStatusBadgeclass = (status) => {
    let className = "";
    switch (status) {
      case "Active":
        className = "color-green";
        break;
      case "Inactive":
        className = "color-grey";
        break;
      default:
        className = "color-orange";
        break;
    }
    return className;
  };

  onEnterpriseAccessChange(e) {}
  checkHasTabAccess = (tabName) => {
    const { userGeneralInfo } = this.state;
    let hasAccess = false;
    if (userGeneralInfo?.subscriptionModule?.length > 0) {
      hasAccess =
        userGeneralInfo?.subscriptionModule
          .filter((item) => item.result?.toString()?.toLowerCase())
          .includes(tabName?.toString()?.toLowerCase())?.length > 0;
    }
    return hasAccess;
  };

  checkHasLaborAccess = (userGeneralInfo): boolean => {
    let hasAccess = false;
    if (userGeneralInfo?.subscriptionModule?.length > 0) {
      hasAccess =
        userGeneralInfo?.subscriptionModule?.filter(
          (item) => item.result?.toString() === "Labor Management"
        )?.length > 0;
    }
    return hasAccess;
  };


  checkHasSalesAccess = (userGeneralInfo): boolean => {
    let hasAccess = false;
    if (userGeneralInfo?.subscriptionModule?.length > 0) {
      hasAccess =
        userGeneralInfo?.subscriptionModule?.filter(
          (item) => item.result?.toString() === "Sales"
        )?.length > 0;
    }
    return hasAccess;
  };
  checkHasPayrollModuleAccess = (): boolean => {
    let hasAccess = false;
    const { userGeneralInfo } = this.state;
    if (userGeneralInfo?.subscriptionModule?.length > 0) {
      hasAccess =
        userGeneralInfo?.subscriptionModule?.filter(
          (item) => (item.result?.toString() === "Payroll"
        ||  item.result?.toString() === "Payroll Service"
        ||  item.result?.toString() === "ShowPayroll")
        )?.length > 0;
    }
    return hasAccess;
  };

  checkHasPayrollUNModuleAccess = (userGeneralInfo): boolean => {
    let hasAccess = false;
    if (userGeneralInfo?.subscriptionModule?.length > 0) {
      hasAccess =
        userGeneralInfo?.subscriptionModule?.filter(
          (item) => (item.result?.toString() === "Payroll"
        ||  item.result?.toString() === "Payroll Service"
        ||  item.result?.toString() === "ShowPayroll")
        )?.length > 0;
    }
    return hasAccess;
  };


  checkHasTabPermissionAccess = (tabName) => {
    const { tabPermissions } = this.state;
    let hasAccess = false;
    if (tabPermissions?.length > 0) {
      hasAccess =
        tabPermissions.filter(
          (item) =>
            item.displayPermissionName?.toString()?.toLowerCase() ===
            tabName?.toString()?.toLowerCase()
        )?.length > 0;
    }
    return hasAccess;
  };

  showHideChangeLogModal = () => {
    this.setState({
      showChangeLogModal: !this.state.showChangeLogModal,
    });
  };


  redirectToLogin = () => {
    if( this.state?.generalTabCount === 0 && this.state?.payRollTabCount === 0) {
   window.location.replace("/login");
    }
  }

  render() {
    let {
      generalTabCount,
      user,
      enterpriseAccess,
      enableEnterpriseAccess,
      userGeneralInfo,
      showChangeLogModal,
      controlPosition,
      salesTabCount,
      isTabPermissionsLoaded,
      hasPerformanceAccess,
      enterPriseWisePayrollData,
      isEnterpriseWideDataLoaded,
      payRollTabCount,
      isClientSetupDataLoaded,
      clientSetupData,
      hasSalesAccess
    } = this.state;

    const {
      userData: { username, imagePath, status, role, position },
      statusList,
    } = this.props;
    const generalTabFunctionsProps: IGeneralProps = {
      onEnterpriseAccessChange: this.onEnterpriseAccessChange,
      getUserGeneralInfo: this.getUserGeneralInfo,
      getGeneralTabModificationStatus: this.getGeneralTabModificationStatus,
      userGeneralInfo: this.state.userGeneralInfo,
      user,
      enterpriseAccess,
      enableEnterpriseAccess,
      userData: this.props.userData,
      statusList,
      uniqueno: userGeneralInfo?.uniqueno,
      isNewUserAdded: this.props.isNewUserAdded,
      isDuplicatePhone: this.props?.isDuplicatePhone,
      isDuplicateEmail: this.props?.isDuplicateEmail,
      isMissingPhone:this.props?.isMissingPhone,
      isMissingEmail:this.props?.isMissingEmail,
      updatePhoneStatus:this.updatePhoneStatus
    };




    return (
      <div id="performanceSlideOut" className="pos-fxd index-1000">
        <ToastContainer containerId={"userSlideOut"} autoClose={3000} />
       { isClientSetupDataLoaded && <div className="d-flex">
          <div
            className="back-drop"
            onClick={this.closeSlideOutWithConfirmation}
          ></div>

          <Container
            fluid
            className="body-sec performance-position user-management-detail-slideout justify-content-end"
          >
            <div className="page-heading d-flex">
              <div className="profile-img">
                <img
                  src={imagePath ? imagePath : profile}
                  className={"default"}
                  width="48"
                  height="48"
                  onError={(e) => (e.currentTarget.src = profile)}
                />
              </div>

              <div className="user-details align-items-center">
                <div className="user-name d-flex align-items-center">
                  <EllipsisWithTooltip placement="bottom">
                    {userGeneralInfo?.userName}
                  </EllipsisWithTooltip>                  
                  {/* <span
                    className={`badge ${this.getStatusBadgeclass(
                      userGeneralInfo?.status
                    )}`}
                  >
                    {userGeneralInfo?.status}
                  </span> */}
                </div>
                <div className="position-name">{userGeneralInfo?.roleName} {userGeneralInfo?.isLowSecurityUser == 1 && <div className={"badge color-blue"}>Low Security</div> }</div>
              </div>

              <div className="header-popout ml-auto">
                <button
                  type="button"
                  className="btn btn-primary btn-outline-primary"
                  onClick={() => this.exitOnboarding()}
                  // disabled={this.state.isSaveDisaled}
                >
                  Exit Onboarding
                </button>
                
              </div>

            </div>

            <Tab.Container
              activeKey={this.state.activeTab}
              onSelect={(selectedtab) => this.setActiveTab(selectedtab)}
              id="left-tabs-example"
              defaultActiveKey="general"
            >
              <div>
                <Nav className="nav-tabs flex-row">
                  <Nav.Item>
                    <Nav.Link eventKey="general">
                      General{" "}
                      {generalTabCount > 0 && (
                        <span className="tab-badge color-orange">
                          {generalTabCount}
                        </span>
                      )}
                    </Nav.Link>
                  </Nav.Item>
                  {Object.keys(userGeneralInfo)?.length > 0 &&+userGeneralInfo?.allowDeposit > 0 &&
                  !this.checkIfOnlyAccountingAccess(userGeneralInfo) &&
                    isTabPermissionsLoaded && userGeneralInfo?.employeeType &&
                    this.checkHasPayrollModuleAccess() && userGeneralInfo?.employeeType!== "ExcludefromPayroll" && 
                    (
                      <Nav.Item>
                        <Nav.Link eventKey="payroll"
                          //  disabled={generalTabCount>0 }
                        >Payroll
                        {payRollTabCount > 0  && (
                          <span className="tab-badge color-red">
                            {payRollTabCount}
                          </span>
                        )} </Nav.Link>
                      </Nav.Item>
                    )}
                </Nav>
              </div>
              <div>
                <Tab.Content>
                  <Tab.Pane eventKey="general">
                     {Object.keys(userGeneralInfo)?.length > 0 && (
                       <LockInGeneral {...generalTabFunctionsProps} 
                       
                       />
                    )} 
                  </Tab.Pane>{" "}
                
                  {/* {Object.keys(userGeneralInfo)?.length > 0 &&
                    isTabPermissionsLoaded &&
                    this.checkHasTabPermissionAccess("Payroll Section") &&
                      this.state.userGeneralInfo?.employeeType!== "ExcludefromPayroll" && 
                    this.checkHasPayrollModuleAccess() && isEnterpriseWideDataLoaded &&
                    && this.checkHasLaborAccess(userGeneralInfo)
                    ( */}
                     {userGeneralInfo?.uniqueno > 0 && isEnterpriseWideDataLoaded &&
                     userGeneralInfo?.employeeType &&
                      !this.checkIfOnlyAccountingAccess(userGeneralInfo) &&
                      <Tab.Pane eventKey="payroll">
                        <LockInPayroll
                         ref={(payrollRef) => {
                          this.payrollRef = payrollRef;
                        }}
                         hasAddPermission=   {this.checkHasTabPermissionAccess("Edit Labor and Payroll Data")}
                         hasEditPermission=   {this.checkHasTabPermissionAccess("Edit Labor and Payroll Data")}
                         hasViewSensitiveDataPermission=   {this.checkHasTabPermissionAccess("View Sensitive data")}
                         userUniqueNo={userGeneralInfo?.uniqueno}
                         userName={username}
                         userGeneralInfo={userGeneralInfo}
                         clientSetupData= {clientSetupData}
                         hireDate={userGeneralInfo?.hireDate}
                         employeeType={userGeneralInfo?.employeeType}
                         enterPriseWisePayrollData = {enterPriseWisePayrollData}
                         getEnterPriseWisePayrollData= {this.getEnterPriseWisePayrollData}
                         getPayrollTabModificationStatus={
                          this.getPayrollTabModificationStatus
                         }
                        isNewUserAdded= {this.props?.isNewUserAdded}
                        getUserGeneralInfo={this.getUserGeneralInfo}
                        updatePayrollCount={this.updatePayrollCount}
                        />
                      </Tab.Pane>
  }
                    {/* )} */}
                </Tab.Content>
              </div>
            </Tab.Container>
          </Container>
        </div>
  }
      </div>
                            
    );
  }
}
