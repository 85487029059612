import * as React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment-timezone";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { InfoTooltip } from "../../../../Common/Components/InfoTooltip";

export class ContractorNameRender extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

//   rowSeclectionCheck = (e: any, rowValues: any) => {
//     this.props.context.componentParent.methodFromParent(e, rowValues);
//   };

  render() {
    const renderTooltip = (props) => {
      return (
        <Tooltip id="tooltip-bottom" {...props}>
         You do not have permission to update this user’s Payroll Details.
        </Tooltip>
      );
    };
    const renderhideTooltip = (props) => {
      return <div id="tooltip-bottom" {...props}></div>;
    };

    // const parentState = this.props.context.componentParent.state;
    const rowData = this.props.data;
    // let showInfoBanner: boolean =
    //   rowData.showInfoBanner === "Yes" ? true : false;

    let positionIdentifier = "positioN!!0";
    // const hasEditPermission = rowData.hasEditPermission;
    // let missingPayrollInfo = false;
    // if(rowData["isPayrollSetting"] === "No"){
    //   missingPayrollInfo = true;
    // }
    const positionName = rowData[positionIdentifier].replace(
      /\w\S*/g,
      function (txt: any) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );

    // let employeeStatus = rowData.empStatus;

    // let employeeStatusClass = "badge color-green";
    // if (employeeStatus.toLowerCase() === "terminated") {
    //   employeeStatusClass = "badge color-orange";
    // }

    // Checking for partial export
    // let isPartialExport: boolean = false;
    // let displayDates: any = rowData.payPeriod_Date.split("-");
    // if (
    //   moment().isSameOrBefore(displayDates[1], "day") &&
    //   moment().isSameOrAfter(displayDates[0], "day")
    // ) {
    //   isPartialExport = true;
    // }

    // checkbox disable condition
    // let checkboxDisable: boolean = false;
    // if (
    //   employeeStatus === "Active" &&
    //   !isPartialExport &&
    //   rowData.isPartialExport === "No"
    // ) {
    //   checkboxDisable = true;
    // }
    // if (rowData.isUserPunch === "Yes") {
    //   checkboxDisable = true;
    // }
    // if (parentState.exportBanner) {
    //   checkboxDisable = true;
    // }
    // if(rowData.isPayrollSetting === "No"){
    //   checkboxDisable = true;
    // }

    return (
      <div
        className="employee-name-wrapper d-flex align-items-center"
        key={this.props.rowIndex}
      >
        {/* <div className="row-selection-check form-check">
          <input
            id={this.props.rowIndex}
            className="form-check-input"
            type="checkbox"
            key={this.props.rowIndex}
            checked={rowData.isChecked}
            disabled={checkboxDisable}
            onChange={(e: any) => this.rowSeclectionCheck(e, rowData)}
          />
        </div> */}
        <div className="emplyee-details mr-auto">
          <EllipsisWithTooltip placement="bottom">
            <div className="employee-name">
              {this.props.value}
            </div>
          </EllipsisWithTooltip>
          <EllipsisWithTooltip placement="bottom">
            <div className="position-name">{positionName}</div>
          </EllipsisWithTooltip>
        </div>
        {/* <div className="emplyee-pip-icon">
          {(missingPayrollInfo && !parentState.exportBanner) && (
                <OverlayTrigger
                  key={"bottom"}
                  placement={"bottom"}
                  overlay={!hasEditPermission ? renderTooltip : renderhideTooltip}
                >
                  <span className="pip-icon red"></span>
                </OverlayTrigger>
              )}
        </div> */}
        {/* {showInfoBanner && (
          <div className="info-tooltip">
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip id={"tooltip-Assign"}>
                  User having issues in payroll export, please fix positons
                </Tooltip>
              }
            >
              <span>
                <InfoTooltip />
              </span>
            </OverlayTrigger>
          </div>
        )}   */}
      </div>
    );
  }
}
