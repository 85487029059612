import React from "react";
import GreyTickMark from "../../../../../Common/Assets/Images/icons/grey_tick_mark.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import AutoPunchOut from "./AutoPunchOut";
import AutoPunchOutTab from "./AutoPunchOutTab";

import OverBudgetsDept from "./OverBudgetsDept";
import DepartmentTab from "./DepartmentTab";  

import UnderBudgetsDept from "./UnderBudgetsDept";
import { Dropdown,Button } from "react-bootstrap";
import HousekeepingActualVsBudget from "./HousekeepingActualVsBudget";
import { ApproveDepartmentDetailsNew } from "../../../../../Common/Components/ApproveDepartmentDetailsNew";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./AutoPunchOutTab.scss";
import "./LaborDayApprovalDetailsNew.scss";
import { connect, useSelector } from "react-redux";
import ReviewTab from "./ReviewTab";
import { truncate } from "fs/promises";
import { Utils } from "../../../../../Common/Utilis";
const approveDepartmentDetailsContent = [
  {
    // title: "Add department note",
    title: "Add Department Approval Reason",
    cancelButtonName: "Cancel",
    actionButtonName: "Approve",
    table: "autoPunchOut",
  },
];

const approveAutoPuncDetailsContent = [
  {
    title: "Continuing will approve the Auto Punch Outs for all selected users. Are you sure you would like to continue?",
    cancelButtonName: "Cancel",
    actionButtonName: "Approve",
    table: "autoPunchOut",
  },
];


 class LaborDayApprovalDetailsNew extends React.Component<any, any> {
  private laborDayDetails: any;
  private laborAPOChild: any;
  private laborUnderBudgetChild: any;
  private laborOverBudgetChild: any;
  private laborReviewTab: any;
  private submitBtnRef: any;
  private submitCheckBtnRef: any;

  constructor(props: any) {
    super(props);
    this.laborDayDetails = React.createRef();
    this.laborAPOChild = React.createRef();
    this.laborUnderBudgetChild = React.createRef();
    this.laborOverBudgetChild = React.createRef();
    this.laborReviewTab = React.createRef();
    this.submitBtnRef = React.createRef();
    this.submitCheckBtnRef = React.createRef();
    this.state = {
      renderTable: "",
      row: props.row,
      isBackVisible: true,
      isContinueVisible: true,
      isAllApprovedButton:"",
      selectedDataTabRow:[],
      type:"",
      isHouskeepingPermission:true,
      tableExpended:false,
      Approvedstatus:"",
      departmentDataTab:[],
      isOverBudjet:false,
      isCheckenButtonStatus:true,
    };
  }

  componentDidMount() { 
    if (Number(this.state.row.showAutoPunchTab)===0) {      

      if (Number(this.state.row.overBudgetDept) === 0) {
          this.setState({
            renderTable: "HousekeppingTab",
            isBackVisible: false,
          });
        } else {

          
          this.setState({
                renderTable: "DepartmentTab",
                isBackVisible: false,
                isContinueVisible: false,
              });
              // if(this.state.row.isHouseKeeping.toLowerCase()==="no"){
              //   this.setState({ isBackVisible: true,isContinueVisible: false });
              // }
              // if(this.state.row.isHouseKeeping.toLowerCase()==="no" 
              //  && this.state.row.isGM.toLowerCase()==="gmapproveday"
              //  &&this.state.row.pendingDeptID?.toLowerCase() === "approved"){
              //   this.setState({ isBackVisible: true ,isContinueVisible: true });
              // }

              if(this.state.row.isHouseKeeping.toLowerCase()==="no" 
              && this.state.row.isGM.toLowerCase()==="gmapproveday" ){
               this.setState({ isContinueVisible: true });
             }



              // if(this.state.row.isHouseKeeping.toLowerCase()==="yes" 
              //  && this.state.row.isGM.toLowerCase()==="gmapproveday"
              //  &&this.state.row.pendingDeptID?.toLowerCase() === "approved"){
              //   this.setState({ isBackVisible: true ,isContinueVisible: true });
              // }
              // }if(this.state.row.isHouseKeeping.toLowerCase()==="yes"){
              //   this.setState({ isContinueVisible: false });
              // }

        }


    } else {
      
      this.setState({ renderTable: "autoPunchOut" });
    }
    
    // if(this.props.persmission.permissionHousekeeping)
    // {
    //   this.setState({isHouskeepingPermission:true});
    // }
    

  }
  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
    if((prevProps?.reloadChildData !== this.props?.reloadChildData) && this.props?.reloadChildData==true) {
      this.refreshDayAppChild();
    }
  }

  changeExpandedState = (state)=>{
    this.setState({tableExpended:state})
  }

  labirDayNavigation = (event: string) => {
  
    if (event === "continue") {
      if (this.state.renderTable === "autoPunchOut") {       
     
        this.setState({ renderTable: "DepartmentTab" });
        if(this.state.row.isHouseKeeping.toLowerCase()==="no"){
          this.setState({ isBackVisible: true ,isContinueVisible: false });
        }
        if(this.state.row.isHouseKeeping.toLowerCase()==="no" && this.state.row.isGM.toLowerCase()==="gmapproveday"){
          this.setState({ isBackVisible: true ,isContinueVisible: true });
        }

        this.setState({isAllApprovedButton:""});
      } else if (this.state.renderTable === "DepartmentTab") {
        if(this.state.row.isHouseKeeping.toLowerCase()==="yes" && this.state.row.isGM.toLowerCase()!=="gmapproveday")
        {
        // this.setState({ renderTable: "HousekeppingTab",isContinueVisible: false});
          this.setState({ renderTable: "HousekeppingTab",isContinueVisible: false ,isBackVisible : true});
        }if(this.state.row.isHouseKeeping.toLowerCase()==="yes" && this.state.row.isGM.toLowerCase()==="gmapproveday")
        {
          // this.setState({ renderTable: "HousekeppingTab",isContinueVisible: true});
          this.setState({ renderTable: "HousekeppingTab",isContinueVisible: true ,isBackVisible : true});
        }        
        else{
          if(this.state.row.isGM.toLowerCase()==="gmapproveday"){
          this.setState({ renderTable: "review"});
          this.setState({isBackVisible: true}) // Added for Back Button
          }
        }

       
        // if (
        //   Number(this.state.row.autoPunchOut) !== 0 ||
        //   Number(this.state.row.overBudgetDept) !== 0
        // ) {
        //   this.setState({ isBackVisible: true });
        // } else {
        //   this.setState({ isBackVisible: false });
        // }
      } else if (this.state.renderTable === "HousekeppingTab") {
   
        if(this.state.row.isGM.toLowerCase()==="gmapproveday"){
        this.setState({ renderTable: "review",isBackVisible: true,isContinueVisible: true,isAllApprovedButton: "" });
         }
         if(this.state.row.isGM.toLowerCase()!=="gmapproveday"){
          this.setState({ isContinueVisible: false});
         }
        //this.setState({ renderTable: "review" });
       // this.setState({ renderTable: "review",isBackVisible: true,isContinueVisible: true,isAllApprovedButton: "" });
        // if (
        //   Number(this.state.row.autoPunchOut) !== 0 ||
        //   Number(this.state.row.overBudgetDept) !== 0
        // ) {
        //   this.setState({ isBackVisible: true });
        // } else {
        //   this.setState({ isBackVisible: false });
        // }
      } else {

      }
    } else {
      if (this.state.renderTable === "DepartmentTab") {
        this.setState({ renderTable: "autoPunchOut" });      
       
        if (          
          Number(this.state.row.overBudgetDept) !== 0 ||
          Number(this.state.row.underBudgetDept) !== 0
        ) {
          this.setState({ isContinueVisible: true });
        } else {
          this.setState({ isContinueVisible: false });
        }
       // this.setState({isAllApprovedButton:""});
      } else if (this.state.renderTable === "HousekeppingTab") {
        if (Number(this.state.row.overBudgetDept) !== 0) {
        //  debugger;
          this.setState({ renderTable: "DepartmentTab" });
          if (Number(this.state.row.underBudgetDept) !== 0) {
            this.setState({ isContinueVisible: true });
          } else {
            this.setState({ isContinueVisible: false });
          }
          if (Number(this.state.row.showAutoPunchTab)!==0) {
            this.setState({ isBackVisible: true });
          } else {
            this.setState({ isBackVisible: false });
          }
        }
       } else if (this.state.renderTable === "review") {
        if(this.state.row.isHouseKeeping.toLowerCase()==="yes")
        {
          this.setState({ renderTable: "HousekeppingTab",isContinueVisible: true,isBackVisible: true });
        }else{
        
          if (Number(this.state.row.showAutoPunchTab)!==0) {  
          this.setState({ renderTable: "DepartmentTab",isContinueVisible: true,isBackVisible: true });
          }else{
            this.setState({ renderTable: "DepartmentTab",isContinueVisible: true,isBackVisible: false });
          }
        }
          // this.setState({ isContinueVisible: true });
          // this.setState({ isBackVisible: true });
          // if (Number(this.state.row.overBudgetDept) !== 0) {
          //   this.setState({ renderTable: "HousekeppingTab" });
          //   if (Number(this.state.row.underBudgetDept) !== 0) {
          //     this.setState({ isContinueVisible: true });
          //   } else {
          //     this.setState({ isContinueVisible: false });
          //   }
          //   if (Number(this.state.row.autoPunchOut) !== 0) {
          //     this.setState({ isBackVisible: true });
          //   } else {
          //     this.setState({ isBackVisible: false });
          //   }
          // }
        
        }
        
        
        else {
          
          this.setState({ renderTable: "autoPunchOut" });
          if (
            Number(this.state.row.overBudgetDept) !== 0 ||
            Number(this.state.row.underBudgetDept) !== 0
          ) {
            this.setState({ isContinueVisible: true });
          } else {
            this.setState({ isContinueVisible: false });
          }
        
      } 
      this.setState({ isAllApprovedButton: "" });
      
    }

    


  };

  buttonStatus =(status: string, chkbutton: string)=>{
  
    if( this.submitCheckBtnRef.current !==null){
    this.submitCheckBtnRef.current.disabled = chkbutton;
    if(status==="Approved"){    
   
         let row = this.state.row;
         this.props.removeRow(row.rowNo);           
      
    }
    if(status==="Pending"){ 
      let row = this.state.row;
      row.action = "";
         
            let panding = this.state.departmentDataTab.filter((item: any) => {
              return (item.departmentName.toLowerCase()!=="total")
            });
            let ispandingstatus = panding.filter((item: any) => {
              return (item.isGMApproved.toLowerCase()==="unapprove")
            });            

            if(ispandingstatus.length===this.state.selectedDataTabRow.length)
            {
              this.props.UpdateBagesStatus(row.rowNo); 
            }
        this.props.IsRowEpande(row.rowNo);      
     }
  


    }
    
  }

  dataApprove = (status: string, renderTable: string) => {
  //  debugger;
    let row = this.state.row;
    if (renderTable === "autoPunchOut" && status === "Approved") {
      row.autoPunchOut = row.autoPunchOut - 1;
      if (Number(row.autoPunchOut) === 0 && Number(row.overBudgetDept) === 0) {
        row.status = "Ready to Approve";
      }
      this.props.childApprove(row, renderTable);
      this.setState({ row: row });
      if(this.state.isAllApprovedButton==="Approve all"){
      this.setState({
        renderTable: "DepartmentTab",
      });
    }


      // if (Number(row.autoPunchOut) === 0) {
      //   if (Number(row.overBudgetDept) !== 0) {
      //     this.setState({
      //       renderTable: "DepartmentTab",
      //       isBackVisible: false,
      //     });
      //   } else if (Number(row.underBudgetDept) !== 0) {
      //     if (Number(row.autoPunchOut) !== 0) {
      //       this.setState({
      //         isBackVisible: true,
      //       });
      //     } else {
      //       this.setState({
      //         isBackVisible: false,
      //       });
      //     }
      //     this.setState({ renderTable: "HousekeppingTab" });
      //   } else {
      //     this.props.removeRow(row.rowNo);
      //   }
      // }
    } else if (renderTable === "overBudget" && status === "Approved") {
      row.overBudgetDept = row.overBudgetDept - 1;
      if (Number(row.autoPunchOut) === 0 && Number(row.overBudgetDept) === 0) {
        row.status = "Ready to Approve";
      }
      this.props.childApprove(row, renderTable);
      this.setState({ row: row });
      
      if(this.state.row.isGM.toLowerCase()==="gmapproveday" &&  this.state.row.isHouseKeeping.toLowerCase()!=="no")
      {
        this.setState({      
        isContinueVisible: true,
        isBackVisible: true,
        renderTable: "HousekeppingTab",
      });
      }else{
        if(this.state.row.isHouseKeeping.toLowerCase()!=="no"){
        this.setState({
          renderTable: "HousekeppingTab",
          isBackVisible: this.state.Approvedstatus!=="Approved" ? true :false,
        });
      }else{
        if(this.state.row.isGM.toLowerCase()==="gmapproveday"){
          this.setState({
            renderTable: "review",
           
          });
        }
        else{
          
        this.setState({
          renderTable: "DepartmentTab",
        });}
      }
      // if(this.state.Approvedstatus === "Approved" && this.state.row.isGM.toLowerCase()!=="gmapproveday"){
      //   this.setState({
      //     renderTable: "HousekeppingTab",
      //     isBackVisible : false
      //   })
      // }
      // else{
      //   this.setState({
      //     isBackVisible : true
      //   })
      // }
      }

      // if (Number(row.overBudgetDept) === 0) {
      //   if (Number(row.underBudgetDept) !== 0) {
      //     if (Number(row.autoPunchOut) !== 0) {
      //       this.setState({
      //         isBackVisible: true,
      //       });
      //     } else {
      //       this.setState({
      //         isBackVisible: false,
      //       });
      //     }
      //     this.setState({
      //       renderTable: "HousekeppingTab",
      //     });
      //   } else if (Number(row.autoPunchOut) !== 0) {
      //     this.setState({
      //       renderTable: "autoPunchOut",
      //       isContinueVisible: false,
      //     });
      //   } else {
      //     this.props.removeRow(row.rowNo);
      //   }
      // }
    } else if (renderTable === "underBudget" && status === "Approved") {
      row.underBudgetDept = row.underBudgetDept - 1;
      if (Number(row.autoPunchOut) === 0 && Number(row.overBudgetDept) === 0) {
        row.status = "Ready to Approve";
      }
      this.props.childApprove(row, renderTable);
      this.setState({ row: row });
      // if (Number(row.underBudgetDept) === 0) {
      //   if (Number(row.overBudgetDept) !== 0) {
      //     if (Number(row.autoPunchOut) !== 0) {
      //       this.setState({
      //         isBackVisible: true,
      //       });
      //     } else {
      //       this.setState({
      //         isBackVisible: false,
      //       });
      //     }
      //     this.setState({
      //       renderTable: "DepartmentTab",
      //       isContinueVisible: false,
      //     });
      //   } else if (Number(row.autoPunchOut) !== 0) {
      //     this.setState({
      //       renderTable: "autoPunchOut",
      //       isContinueVisible: false,
      //     });
      //   } else {
      //     this.props.removeRow(row.rowNo);
      //   }
      // }


      // this.setState({
      //   renderTable: "DepartmentTab",
      //   isContinueVisible: false,
      // });

    }
    this.setState({isAllApprovedButton:""});
  };

  refreshDayAppChild=()=> {
    if (this.laborAPOChild.current !== null) {
    //  debugger
      this.laborAPOChild.current.refreshAPOChild();
    }

    if (this.laborUnderBudgetChild.current !== null) {
      this.laborUnderBudgetChild.current.refreshUnderBudgetChild();
    }

    if (this.laborOverBudgetChild.current !== null) {
      this.laborOverBudgetChild.current.refreshDptoChild();
    }
  }

  handlePositionDetail(row: any) {
  
    let newrow = this.state.row;
    row.date = newrow.date;
    row.hotelID = newrow.hotelID;
    row.letterCode = newrow.letterCode;
    this.props.positionDetail(row);
  }

  AproveAll=(isApproval:any, chieldState:any,Type:any)=>{
debugger;
    if(chieldState!==undefined){
    if(chieldState.length>0){

      if(Type==="departmenttab"){
         // For Back Button Invisiblity
        //  if(Number(this.state.row.autoPunchOut) === 0 && this.state.renderTable === "DepartmentTab")
        //  {
        //    this.setState({isBackVisible : false})
        //  }
        if(this.state.row.isHouseKeeping.toLowerCase()==="yes")
        {
          if (this.state.renderTable==="DepartmentTab"){
          isApproval="Approve All and Continue";
          this.setState({ isContinueVisible: false });
          }
         else if (this.state.renderTable==="autoPunchOut"){
            isApproval="";
            this.setState({ isContinueVisible: true });
          }
         
        }else{
         if (this.state.renderTable==="review"){
          isApproval="";
          this.setState({ isContinueVisible: false });
          }else if (this.state.renderTable==="autoPunchOut"){
            isApproval="";
            this.setState({ isContinueVisible: true });
          }
          else{
            if(this.state.row.isGM.toLowerCase()==="gmapproveday")
            {
              isApproval="Approve All and Continue";
            }else{
              isApproval="Approve All & Submit";
            }
            this.setState({ isContinueVisible: false });
          }
         
        }
       
      }

      let isCheackoverbudjet=false;     
      // for (let i = 0; i < chieldState.length; i++) {       
      //   if(Number(chieldState[i].acualBudget)===0 && Number(chieldState[i].actualTotal) > 0) {
      //     isCheackoverbudjet=true;
      //     break;
      //   }
      //   if(Number(chieldState[i].acualBudget) > 0 ){
      //     isCheackoverbudjet=true;
      //     break;
      //   }       
      // }

      for (let i = 0; i < chieldState.length; i++) {       
        if(Number(chieldState[i].isOverBudget)===1)  {
          isCheackoverbudjet=true;
          break;
        }           
      }

      
     this.setState({isAllApprovedButton:isApproval,selectedDataTabRow:chieldState,type:Type,
      isOverBudjet:isCheackoverbudjet});
     
     
  }else{
    if(chieldState.length===0){
      if(Type==="departmenttab"){
        if(this.state.row.isHouseKeeping.toLowerCase()==="yes"){
         // if(this.state.row.isGM.toLowerCase()==="gmapproveday"){
          this.setState({ isContinueVisible: true });
           // }
            if(this.state.row.isGM.toLowerCase()!=="gmapproveday" && this.state.renderTable==="HousekeppingTab"){
             
              this.setState({ isContinueVisible: false });
            }
          
        }  if(this.state.row.isHouseKeeping.toLowerCase()==="no" && this.state.row.isGM.toLowerCase()==="gmapproveday"){
          this.setState({ isContinueVisible: true });
        }         
         
      }
      this.setState({isAllApprovedButton:"",selectedDataTabRow:chieldState,type:Type});
      
    }else{
      this.setState({isAllApprovedButton:isApproval,selectedDataTabRow:chieldState,type:Type});
    }
    
  }
}
}




approveModalAction = (
  row: any,
  actionItem: any,
  flag: any,
  childState: { approvalReason: string }
) => {
  
  if (flag) {
    if (childState.approvalReason === "" && this.state.type==="autopunchtab") {
      Utils.toastError(`Please enter Approval Reason`, {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "laborDayOverviewContainer",
      });
    } else {
      if (childState.approvalReason.length > 200) {
       Utils.toastError(`Explanation should not exceed 200 characters`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "laborDayOverviewContainer",
        });
      } else {
        if(this.state.type==="autopunchtab"){
        if(this.laborAPOChild.current!==null)
       this.laborAPOChild.current.rowApprove(this.state.selectedDataTabRow, actionItem, childState);
        }
        if(this.state.type==="departmenttab"){
          this.laborOverBudgetChild.current.rowApprove(this.state.selectedDataTabRow, actionItem, childState);
        }
       //this.props.rowApprove(row, actionItem, childState);
      }
    }
  } else {
    if (actionItem === "singleApprove") {
      // let data = this.state.autoPunchOutData;
      // let index = data.indexOf(row);
      // if (index >= 0) {
      //   data[index].flyOutAction = "";
      //   this.setState({ autoPunchOutData: data });
      // }
    }
  }
};

SubmitCheckIn=()=>{  
 // debugger;
  if(this.laborReviewTab.current!=null){
  this.laborReviewTab.current.SubmitCheckIn(this.state.selectedDataTabRow);
  }
}
FinishClick=()=>{
  
  if(this.state.Approvedstatus==="Approved"){
   
    let row = this.state.row;
    this.props.removeRow(row.rowNo);
   
  }
}
AllApprovedStatus=(AllApproved:any)=>{ 
  if(this.state.row.isHouseKeeping.toLowerCase()==="no" && this.state.row.isGM.toLowerCase()!=="gmapproveday"){
    if(AllApproved==="Approved"){
   
      let row = this.state.row;
      this.props.removeRow(row.rowNo);     
    }
  }
  if(this.state.row.isGM.toLowerCase()==="gmapproveday") {
    if(AllApproved==="Approved"){
   
      let row = this.state.row;
      this.props.UpdateApprovedStatus(row.rowNo);     
    }
  }
this.setState({Approvedstatus:AllApproved})
}
TotalDeptdata=(DeptData:any)=>{
  this.setState({departmentDataTab:DeptData});
}

OnApprovalCall=()=>{
  if(this.state.type==="departmenttab" && !this.state.isOverBudjet){
    this.laborOverBudgetChild.current.rowApprove(this.state.selectedDataTabRow, "bulkApprove", "");
  }
}
isCheckinButtonTrue=(status:any)=>{
this.setState({isCheckenButtonStatus:status});
// this.submitCheckBtnRef.current.disabled = chkbutton;
}
  render() {
    return (
      <>
        <div className="laborDayApprovalDetails" ref={this.laborDayDetails}>
          <div className="laborDayNavigation">
            <div className="labordayNavigationlabels">
              <span
                className={
                  this.state.renderTable === "autoPunchOut"
                    ? "labordayNavigationSpan active"
                    : Number(this.state.row.autoPunchOut) === 0
                    ? "labordayNavigationSpan disable"
                    : "labordayNavigationSpan"
                }
              >
                <span className="labordayNavigationNumber">
                  {Number(this.state.row.autoPunchOut) === 0 ? (
                    <span className="tickMark">
                      <FontAwesomeIcon icon={faCheck} />
                    </span>
                  ) : (
                    "1"
                  )}
                </span>
                <span className="labordayNavigationLabel">APOs</span>
              </span>
              <span className="laborDayApprovalDesign"></span>
              <span
                className={
                  this.state.renderTable === "DepartmentTab"
                    ? "labordayNavigationSpan active"
                    : Number(this.state.row.overBudgetDept) === 0
                    ? "labordayNavigationSpan disable"
                    : "labordayNavigationSpan"
                }
              >
                <span className="labordayNavigationNumber">
                  {this.state.row.pendingDeptID?.toLowerCase() === "approved" ? (
                    <span className="tickMark">
                      <FontAwesomeIcon icon={faCheck} />
                    </span>
                  ) : (
                    "2"
                  )}
                </span>
                <span className="labordayNavigationLabel">
                 Departments
                </span>
              </span>
              
              {this.state.isHouskeepingPermission && this.state.row.isHouseKeeping.toLowerCase()==="yes"  && (
                <>
                <span className="laborDayApprovalDesign"></span>
              <span
                className={
                  this.state.renderTable === "HousekeppingTab"
                    ? "labordayNavigationSpan active"
                    : Number(this.state.row.underBudgetDept) === 0
                    ? "labordayNavigationSpan disable"
                    : "labordayNavigationSpan"
                }
              >
                <span className="labordayNavigationNumber">
                  {this.state.row.ApproveStatus?.toLowerCase() === "approved" ?(
                    <span className="tickMark">
                      <FontAwesomeIcon icon={faCheck} />
                    </span>
                  ) : (
                    "3"
                  )}
                </span>
                <span className="labordayNavigationLabel">
                Housekeeping
                </span>
              </span>
              </>
              )}
           
          
             
               {this.state.row.isGM==="GMApproveDay" && (
                  <>
                 <span className="laborDayApprovalDesign"></span>
                <span
                className={
                  this.state.renderTable === "review"
                    ? "labordayNavigationSpan active"
                    : Number(this.state.row.autoPunchOut) === 0
                    ? "labordayNavigationSpan disable"
                    : "labordayNavigationSpan"
                }
              >
                <span className="labordayNavigationNumber">                 
                  {this.state.row.isHouseKeeping.toLowerCase()==="yes" ? (4):(3)}                  
                </span>
                <span className="labordayNavigationLabel">Review</span>
              </span>
              </>
               )}
             </div>
              <div className="labordayNavigationbuttons">
              <div className="d-flex justify-content-end" id="approveAllDropdown">

              {this.state.renderTable !== "HousekeppingTab" &&              
                this.state.isAllApprovedButton !== "" && (                  

                  <Dropdown
                  //className="more-action ml-3 approveAllBtn"
                  className={`more-action ml-3 approveAllBtn ${this.state.renderTable === "review" ? "d-none" : ""}`}
                  alignRight
               
                  onClick={() => this.OnApprovalCall()}
                 
                >
                  <Dropdown.Toggle
                    className="btn-outline-primary autoPunchOutApprove more"
                    id="dropdown-more"
                  >
                    {this.state.isAllApprovedButton}
                  </Dropdown.Toggle>
                  {this.state.type==="autopunchtab" && (
                    <Dropdown.Menu>
                    <ApproveDepartmentDetailsNew
                      approveDepartmentDetailsContent={
                        approveAutoPuncDetailsContent
                      }
                      outTime={"00:00"}
                      showOutTime={true}
                      table="autoPunchOut"
                      // showPlaceHolder={"Explain the numbers..."}
                      showPlaceHolder={"Please provide explanation here."}
                      // ref={this.approveDepartment}
                      approveModalAction={this.approveModalAction.bind(
                        this,
                        "row",
                        "bulkApprove"
                        )}
                    />
                    </Dropdown.Menu>
                  )}
                  {this.state.type==="departmenttab" && this.state.isOverBudjet &&(
                    <Dropdown.Menu>
                    <ApproveDepartmentDetailsNew
                      approveDepartmentDetailsContent={
                        approveDepartmentDetailsContent
                      }
                      outTime={"00:00"}
                      showOutTime={true}
                      // showPlaceHolder={"Explain the numbers..."}
                      showPlaceHolder={"Please provide explanation here."}
                      table="departmentTab"
                      // ref={this.approveDepartment}
                      approveModalAction={this.approveModalAction.bind(
                        this,
                        "row",
                        "bulkApprove"
                        )}
                    />
                    </Dropdown.Menu>
                  )}

                </Dropdown>

                )}

              {this.state.renderTable !== "autoPunchOut" && 
                this.state.isBackVisible === true &&           
                  (
                  <Button
                    className="btn-back laborDayApprovalBack"
                    onClick={() => this.labirDayNavigation("back")}
                  >
                    Back
                  </Button>
              )}

              
              {this.state.renderTable !== "review" &&              
                this.state.isContinueVisible === true && (
                  <Button
                    className="btn-primary laborDayApprovalContinue ml-3"
                    onClick={() => this.labirDayNavigation("continue")}
                  >
                    Continue
                  </Button>
                )}
                 {this.state.renderTable === "review" && this.state.selectedDataTabRow.length > 0 &&              
                  <Button
                    className="btn-primary laborDayApprovalContinue ml-3"
                    onClick={() => this.SubmitCheckIn()}
                    ref={this.submitCheckBtnRef}
                    //disabled={this.state.isCheckenButtonStatus}
                    disabled={this.state.isCheckenButtonStatus}
                    
                  >
                    Submit Check In
                  </Button>
                }

               {this.state.renderTable === "HousekeppingTab" && this.state.row.isGM!=="GMApproveDay"
               && this.state.Approvedstatus==="Approved" &&              
                  <Button
                    className="btn-primary laborDayApprovalContinue"
                    onClick={() => this.FinishClick()}
                    ref={this.submitBtnRef}
                  >
                    Finish
                  </Button>
                }


            </div>
            </div>
          </div>
          {this.state.renderTable === "autoPunchOut" && (
            <AutoPunchOutTab
              ref={this.laborAPOChild}
              autoPunchoutApprove={this.dataApprove}
              row={this.state.row}
              changeExpandedState={this.changeExpandedState}
              positionDetail={this.handlePositionDetail.bind(this)}
              AproveAll={this.AproveAll}
              {...this.state}
            />

          )}
          {this.state.renderTable === "DepartmentTab" && (
            <DepartmentTab
              ref={this.laborOverBudgetChild}
              overBudgetApprove={this.dataApprove}
              row={this.state.row}
              changeExpandedState={this.changeExpandedState}
              positionDetail={this.handlePositionDetail.bind(this)}
              AproveAll={this.AproveAll}
              AllApprovedStatus={this.AllApprovedStatus}
              TotalDeptdata={this.TotalDeptdata}
              {...this.state}
            />
          )}
          {this.state.renderTable === "HousekeppingTab" && (
            
                <HousekeepingActualVsBudget
            ref={this.laborUnderBudgetChild}
            underBudgetApprove={this.dataApprove}
            row={this.state.row}
            positionDetail={this.handlePositionDetail.bind(this)}
          />


           
        
          )}

          {this.state.renderTable === "review" && (
           
                <ReviewTab
            ref={this.laborReviewTab}
            underBudgetApprove={this.dataApprove}
            row={this.state.row}
            buttonStatus={this.buttonStatus}
            positionDetail={this.handlePositionDetail.bind(this)}
            isCheckinButtonTrue={this.isCheckinButtonTrue}
          />


            // <UnderBudgetsDept
            //   ref={this.laborUnderBudgetChild}
            //   underBudgetApprove={this.dataApprove}
            //   row={this.state.row}
            //   positionDetail={this.handlePositionDetail.bind(this)}
            // />
        
          )}



        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
    const persmission = state.RolePermissionReducer;
    return {
        persmission
    };
  }

export default connect(mapStateToProps)(LaborDayApprovalDetailsNew);
