import React from "react";
import { Modal, Button, Form, Table } from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OutsideClickHandler from "react-outside-click-handler";
import { resolve, reject } from "q";
import { schConversions } from "../commonFunctions/conversions";
import { ConfirmationModalNonTable } from "../../../../../Common/Components/ConfirmationModalNonTable";
import { ReactComponentLoader } from "../../../../../Common/Components/ReactComponentLoader";
import { LaborSchedule } from "../../../../../Common/Services/LaborSchedule";
import { LaborTimeOffDetails } from "../../../../../Common/Services/LabourTimeOff";
import {
  //ILaborEditPTOUTO,
  ILaborPTOUTODeleteReq,
} from "../../../../../Common/Contracts/ILaborSchedule";
import { Utils } from "../../../../../Common/Utilis";

export class EditApprovedPTOModal extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      loaderTrue: true,
      buttonDisabled: false,
      employeeName: "",
      shiftDate: "",
      shiftStartDate: "",
      shiftEndDate: "",
      timeClass: "schduleInput",
      shiftInTime: "",
      shiftOutTime: "",
      scheduleType: "",
      ptoType: "",
      ptoDisplayString: "",
      ptoReason: "",
      postBalance: "",
      oldPostBalance: "",
      ptoDuration: "",
      oldPtoDuration: "",
      IsPTOID: "0",
      ptoRequestId: "",
      isValidate: false,
      deleteConfiramtionToggle: false,
      payrollExported:false,
      popup: false
    };
  }

  componentDidMount() {
    let modalRow = this.props.parentState.editApprovedPTOModalData.row;
    let modalSchedule = this.props.parentState.editApprovedPTOModalData.schedule;
    let employeeName = modalRow.employeeName;
    let shiftDate = modalSchedule.shiftDate;
    let shiftStartDate = moment(new Date(modalSchedule.indate).toLocaleDateString("en-US"), "MM-DD-YYYY").format("MM/DD/YY");
    let shiftEndDate = moment(new Date(modalSchedule.outdate).toLocaleDateString("en-US"), "MM-DD-YYYY").format("MM/DD/YY");
    let shiftInTime = schConversions.tweleveToTwentyFourConvert(modalSchedule.shiftInTime);
    let shiftOutTime = schConversions.tweleveToTwentyFourConvert(modalSchedule.shiftOutTime);
    this.setState(
      { employeeName, shiftDate, shiftStartDate, shiftEndDate, shiftInTime, shiftOutTime },
      () => {
        this.getPTOModalData();
      }
    );
  }

  getPTOModalData = () => {
    this.setState({ loaderTrue: true });
    LaborTimeOffDetails.GetTimeOffRequestDetails(
      this.props.parentState.editApprovedPTOModalData.schedule.uniqueno,
      this.props.parentState.navigationCalenderDates[0],
      this.state.IsPTOID
    )
      .then(async (result: any | null) => {
        let apiResult = result.result;
        if (result.message === "Success" && apiResult !== null) {
          let scheduleType = apiResult.scheduleType;
          let ptoType = apiResult.ptoType;
          let ptoReason = apiResult.managerComment;
          let ptoDuration = apiResult.duration;
          let postBalance = apiResult.ptoBalance;
          let shiftDateMoment = moment(this.state.shiftDate, "MM-DD-YY").format("MM/DD/YYYY");
          let currentTimeOFfDetails: any;
          for (let i = 0; i < apiResult.timeOffRequestDateTime.length; i++) {
            if (
              apiResult.timeOffRequestDateTime[i].startDate === shiftDateMoment
            ) {
              currentTimeOFfDetails = apiResult.timeOffRequestDateTime[i];
              break;
            }
          }
          let ptoRequestId = currentTimeOFfDetails.requestID;
          ptoDuration = currentTimeOFfDetails.dayDuration;
          if (postBalance.includes(" ")) {
            let postBalanceTemp = postBalance.split(" ");
            postBalance = postBalanceTemp[0];
          }
          let ptoDisplayString = `${ptoType}, ${postBalance} Available`;
          if (scheduleType === "6") {
            ptoDisplayString = "UTO";
          } else if (scheduleType === "8") {
            ptoDisplayString = "Do Not Schedule";
          }
          this.setState({
            scheduleType,
            ptoType,
            ptoDisplayString,
            ptoReason,
            ptoDuration,
            oldPtoDuration: ptoDuration,
            postBalance,
            oldPostBalance: postBalance,
            ptoRequestId,
          });
        } else {
          Utils.toastError(apiResult.message, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "scheduleMain",
          });
        }
        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "scheduleMain",
        });
        this.setState({ loaderTrue: false });
        reject();
      });
  };

  editTime = (e: any, state: any) => {
    let value = e.target.value;
    this.setState({ timeClass: "schduleInput", isValidate: false });
    if (state === "inTime") {
      this.setState({ shiftInTime: value },()=>{
        this.calcEndDate();
      });
    } else {
      this.setState({ shiftOutTime: value },()=>{
        this.calcEndDate();
      });
    }
  };

  calcEndDate=()=>{
    
    let modalSchedule = this.props.parentState.editApprovedPTOModalData.schedule;
        let startdate1=modalSchedule.indate;
       
        let startTime1=this.state.shiftInTime;
        let endTime1=this.state.shiftOutTime;
        
       let startdate2=moment(startdate1).format("MM/DD/YYYY");
       let inMomentTime = moment(
         `${startdate2} ${startTime1}`
       );
       let outMomentTime = moment(
         `${startdate2} ${endTime1}`
       );
    
       let differenceInTime = outMomentTime.diff(inMomentTime);
    
       let punchOutDate = moment(startdate2).format("MM/DD/YYYY");
       if (differenceInTime <= 0) {
         punchOutDate = moment(startdate2)
           .add(1, "days")
           .format("MM/DD/YYYY");
       }
    
       //reEditPto["reCalcEndDate"] = punchOutDate;
  
       this.setState({ shiftEndDate: punchOutDate});
        
      }

  timeDiffCalc = (startDateTime, endDateTime) => {
    let dateTime = endDateTime - startDateTime;
    let min = Math.floor(dateTime / 1000 / 60);
    return min;
  }

  mintoHour = (mins) => {
    let h = Math.floor(mins / 60);
    let m = mins % 60;
    let hour = h;// < 10 ? '0' + h : h
    let minute = m < 10 ? '0' + m : m;
    return `${hour}:${minute}`;
  }

  blurTime = (e: any, state: any) => {
    let { shiftStartDate, shiftEndDate } = this.state;
    let value = e.target.value;
    let inTime;
    let outTime;
    if (state === "inTime") {
      inTime = value;
    } else {
      outTime = value;
    }
    let stx = inTime !== undefined ? inTime : this.state.shiftInTime
    let oty = outTime !== undefined ? outTime : this.state.shiftOutTime;
    let startdateTime = new Date(new Date(shiftStartDate).toLocaleDateString("en-US") + " " + stx);
    let endDateTime = new Date(new Date(shiftEndDate).toLocaleDateString("en-US") + " " + oty);
    const totalMinutes = this.timeDiffCalc(startdateTime, endDateTime);
    let diffInHours = this.mintoHour(totalMinutes);
    if (totalMinutes <= 0 ) {
      this.setState({
        timeClass: "schduleInput alert-danger",
        isValidate: true,
      });
      Utils.toastError("Out Time must be greater than In Time", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "scheduleMain",
      });
    }
    if (totalMinutes > 480 && this.state.scheduleType!=="8") {
      this.setState({
        timeClass: "schduleInput alert-danger",
        isValidate: true,
      });
      Utils.toastError("PTO/UTO duration can not be more than 8 hours for a day.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "scheduleMain",
      });
    }
    let ptoDuration = diffInHours;
    this.setState({ ptoDuration });
    // Changing Post Entry Balance only on PTO Schedule
    if (this.state.scheduleType === "4") {
      let postEntryHours: any = Number(
        // schConversions.hhmmToNumber(this.state.postBalance)
        schConversions.hhmmToNumber(this.state.oldPostBalance)
      );
      let _totalMinutes = Number(schConversions.hhmmToNumber(this.mintoHour(totalMinutes)));
      let _oldPtoDuration = Number(schConversions.hhmmToNumber(this.state.oldPtoDuration));
      let realDuration: any = _totalMinutes - _oldPtoDuration;
      let diffrenceInPostEntry = postEntryHours - realDuration;
      if (diffrenceInPostEntry < 0) {
        this.setState({
          timeClass: "schduleInput alert-danger",
          isValidate: true,
        });
        Utils.toastError("Post Entry Balance should not be less than 0", {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "scheduleMain",
        });
      }
      let newPostEntry = schConversions.numberToHHMM(diffrenceInPostEntry);
      this.setState({ postBalance: newPostEntry });
    }
  };

  handleDeletePTO = () => {
   
   this.deleteConfiramtionHide();   
    this.setState({ buttonDisabled: true });
    //let request = {} as ILaborPTOUTODeleteReq;
    let request: any[] = [];
    let newItem: any = {
      HotelID: this.props.parentState.hidValue,
      scheduleID: Number(this.props.parentState.editApprovedPTOModalData.schedule.uniqueno),
      date: this.state.shiftDate,
      Status: '',
      requestID: Number(this.state.ptoRequestId),
      ActionType: "DeletePTO"
    }
    request.push(newItem);
    //LaborSchedule.DeletechedulePTOUTOFF(request)
    LaborSchedule.RaiseTimeOff(request)
      .then(async (result: any | null) => {
        let apiResult = result;
        if (apiResult.success && apiResult !== null) {
          toast.success("Time Off deleted successfully", {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "scheduleMain",
          });
          this.editApprovedPTOModalHide();
          this.props.addShiftRefresh();
        } else {
          Utils.toastError(result.message, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "scheduleMain",
          });
        }
        this.setState({ buttonDisabled: false });
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "scheduleMain",
        });
        this.setState({ buttonDisabled: false });
        reject();
      });
    
  };

  handleEditPTO = () => {
    
    this.setState({ buttonDisabled: true });
    let PTOModalData = this.props.parentState.editApprovedPTOModalData;
    let request: any[] = [];
    let Type="";
    if(PTOModalData.schedule.shiftPosition=== "Do not schedule"){
      Type="UpdateApproveDNSSchedule";
    }else if(PTOModalData.schedule.shiftPosition=== "UTO"){
      Type="UpdateApproveUTOSchedule";
    }else{
      Type="UpdateApprovePTOSchedule";
    }
    let newItem: any = {
      scheduleID: Number(PTOModalData.schedule.uniqueno),
      hotelID: this.props.parentState.hidValue,
      employeeName: this.state.employeeName,
      date: this.state.shiftDate,
      inTime: schConversions.twentyFourToTwelveConvert(this.state.shiftInTime),
      outTime: schConversions.twentyFourToTwelveConvert(this.state.shiftOutTime),
      ScheduleTYpe: PTOModalData.schedule.typeMaster,
      inDate: new Date(PTOModalData.schedule.indate).toLocaleDateString("en-US"),
      outDate: new Date(PTOModalData.schedule.outdate).toLocaleDateString("en-US"),
      weekStartDate: this.props.parentState.navigationCalenderDates[0],
      weekEndDate: this.props.parentState.navigationCalenderDates[1],
      requestID: Number(this.state.ptoRequestId),
      Status: '',
      ActionType: "EditPTO",

      pageSource : Type,
      
      
      //Type: "Edit",
    }
    request.push(newItem);
    //LaborSchedule.EditSchedulePTOUTO(request)
    LaborSchedule.RaiseTimeOff(request)
      .then(async (result: any | null) => {
        let apiResult = result;
        if (apiResult.success && apiResult !== null) {
          toast.success("Time Off saved successfully", {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "scheduleMain",
          });
          this.editApprovedPTOModalHide();
          this.props.addShiftRefresh();
        } else {
          Utils.toastError(apiResult.message, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "scheduleMain",
          });
        }
        this.setState({ buttonDisabled: false });
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "scheduleMain",
        });
        this.setState({ buttonDisabled: false });
        reject();
      });
  };

  editApprovedPTOModalHide = () => {
    this.props.editApprovedPTOModalHide();
  };

  deleteConfiramtionHide = () => {
    this.setState({ deleteConfiramtionToggle: false });
  };

 

  render() {
    return (
      <div>
        <Modal
          className="edit-pto-modal"
          show={this.props.parentState.editApprovedPTOModal}
          onHide={this.editApprovedPTOModalHide}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Time Off</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <div className="container">
              <code>
                {JSON.stringify(this.state)}
              </code>
            </div> */}
            {this.state.loaderTrue ? (
              <ReactComponentLoader useas={"scheduleEditApprovedPTO"} />
            ) : (
              <>
                <div className="user-type d-flex">
                  <Form.Group controlId="select-user">
                    <Form.Label>User</Form.Label>
                    <div className="employeeName">
                      {this.state.employeeName}
                    </div>
                  </Form.Group>

                  <Form.Group controlId="select-type">
                    <Form.Label>Type</Form.Label>
                    <EllipsisWithTooltip placement="bottom">
                      <div className="pto-type">
                        {this.state.ptoDisplayString}
                      </div>
                    </EllipsisWithTooltip>
                  </Form.Group>
                </div>

                <Form.Group controlId="addtimeoff-reason">
                  <Form.Label>Reason</Form.Label>
                  <Form.Control
                    readOnly
                    className="form-control"
                    as="textarea"
                    value={this.state.ptoReason}
                  />
                </Form.Group>

                <div className="bottomSepetatorLine"></div>

                <Form.Label>Time Off Entry</Form.Label>
                <div className="react-bootstrap-table">
                  <Table className="react-bootstrap-table table-hover table-condensed">
                    <thead>
                      <tr className="bg-light">
                        <th style={{ width: "20%" }}>Start Date</th>
                        <th style={{ width: "20%" }} className="pl-4">Start Time</th>
                        {/* <th style={{ width: "20%" }} className="pl-4">End Date</th> */}
                        <th style={{ width: "20%" }} className="pl-4">End Time</th>
                        <th style={{ width: "20%" }}>Duration</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="pt-2"></div>
                          <Form.Label>{this.state.shiftStartDate}</Form.Label>
                        </td>
                        <td>
                          <Form.Control
                            className={this.state.timeClass}
                            required
                            type="time"
                            name="inTime"
                            value={this.state.shiftInTime}
                            onChange={(e: any) => this.editTime(e, "inTime")}
                            onBlur={(e: any) => this.blurTime(e, "inTime")}
                          />
                        </td>
                        {/* <td>
                          <div className="pt-2"></div>
                          <Form.Label>{this.state.shiftEndDate}</Form.Label>
                        </td> */}
                        <td>
                          <Form.Control
                            className={this.state.timeClass}
                            required
                            type="time"
                            name="outTime"
                            value={this.state.shiftOutTime}
                            onChange={(e: any) => this.editTime(e, "outTime")}
                            onBlur={(e: any) => this.blurTime(e, "outTime")}
                          />
                        </td>
                        <td>
                          {/* <span className={this.state.ptoDuration > 480 && this.state.ptoDuration < 0 && "schduleInput alert-danger" ? "text-danger" : ""}>{this.state.ptoDuration}</span> */}
                          {!this.state.ptoDuration.includes("-") && (
                            <span
                              className={
                                this.state.timeClass ===
                                  "schduleInput alert-danger"
                                  ? "text-danger"
                                  : ""
                              }
                            >
                              {this.state.ptoDuration}

                            </span>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            {this.state.postBalance !== "" && this.state.scheduleType === "4" && (
              <div className="post-entry-balance">
                Post Entry Balance:{" "}
                <span
                  className={
                    this.state.postBalance?.includes("-")
                      ? "badge color-orange"
                      : "badge color-green"
                  }
                >
                  {this.state.postBalance}
                </span>
              </div>
            )}
            <div className="deleteButtonWrapper ml-auto">
              <>
                <Button
                  disabled={this.state.loaderTrue || this.state.buttonDisabled}
                  className={
                    this.state.deleteConfiramtionToggle
                      ? "delete-pto-btn deleteConfirmModalOpen"
                      : "delete-pto-btn btn-outline-primary"
                  }
                  onClick={() => {
                    this.setState({ deleteConfiramtionToggle: true });
                  }}
                >
                  Delete Time Off
                </Button>
                {this.state.deleteConfiramtionToggle && (
                  <OutsideClickHandler
                    onOutsideClick={this.deleteConfiramtionHide}
                  >
                    <ConfirmationModalNonTable
                      title={"Delete Time Off"}
                      description={"Are you sure to delete Time Off ?"}
                      cancelButtonName={"No"}
                      actionButtonName={"Yes"}
                      warningCloseModel={this.deleteConfiramtionHide.bind(this)}
                      saveCopyBudgets={this.handleDeletePTO.bind(this)}
                    />
                  </OutsideClickHandler>
                )}
              </>
            </div>

            <Button
              disabled={
                this.state.loaderTrue ||
                this.state.buttonDisabled ||
                this.state.isValidate
              }
              className="save-pto-btn"
              onClick={this.handleEditPTO}
            >
              Save Time Off
            </Button>
          </Modal.Footer>
        </Modal>






      </div>
    );
  }
}
