import moment from "moment";


export const showOnlySections = [
  {
    header: "TOP PERFORMING",
    items: [
      { item: 5, label: "Top 5", value: "top5" },
      { item: 10, label: "Top 10", value: "top10" },
      { item: 20, label: "Top 20", value: "top20" },
    ],
  },
  {
    header: "BOTTOM PERFORMING",
    items: [
      { item: 5, label: "Bottom 5", value: "bottom5" },
      { item: 10, label: "Bottom 10", value: "bottom10" },
      { item: 20, label: "Bottom 20", value: "bottom20" },
    ],
  },
];



export const getPeriods = (date: any) => {
  return [
    { label: `Yesterday (${moment(date).format("MM/DD")})`, value: 1 },
    {
      label: `Current Period (${moment(date)
        .startOf("month")
        .format("MM/DD")} - ${moment(date).format("MM/DD")})`,
      value: 2,
    },
    {
      label: `Last Period (${moment(date)
        .subtract(1, "months")
        .startOf("month")
        .format("MM/DD")} - ${moment(date)
        .subtract(1, "months")
        .endOf("month")
        .format("MM/DD")})`,
      value: 3,
    },
 
    {
      label: `YTD (${moment(date)
        .startOf("year")
        .format("MM/DD")} - ${moment(date).format("MM/DD")})`,
      value: 5,
    },
    { label: `Trailing 12 (${moment(date)
      .subtract(1, "years").add(1, "days")
      .format("MM/DD/YY")} - ${moment(date).format("MM/DD/YY")})`, value: 4 },
  ];
};

 
export const DashboardsDataList = [
  {
    label: "Portfolio Health",
    description: "A high-level view of your portfolio",
    id:1,
  },
  { label: "Labor Focus", description: "Dashboard for labor analysis",   id:2, },
  { label: "NE Region", description: "Health of the NE Region" ,    id:3,},
];