export class TimeClockCommonFunc {
  public static getCurrentTimeZone = (timeZone: string) => {
    let timeZoneValue = "";

    switch (timeZone) {
      case "EST":
        timeZoneValue = "America/New_York";
        break;
      case "PST":
        timeZoneValue = "America/Los_Angeles";
        break;
      case "MST":
        timeZoneValue = "America/Phoenix";
        break;
      case "CST":
        timeZoneValue = "America/Chicago";
        break;
      case "HST":
        timeZoneValue = "US/Hawaii";
        break;
      default:
        timeZoneValue = "America/New_York";
        break;
    }

    return timeZoneValue;
  };
}
