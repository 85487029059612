import { HubConnectionBuilder } from "@microsoft/signalr";
import { API_ROOT } from "../../Services/Api-config";

export class Hub {
  static connect = (hubName) => {
    const hubConnection = new HubConnectionBuilder()
      .withUrl(`${API_ROOT.notificationHubURL}` + hubName)
      .withAutomaticReconnect()
      .build();
    return hubConnection;
  };
}
