import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ReactTooltip from "react-tooltip"; 
import "react-toastify/dist/ReactToastify.css";
import { LaborDayApprove } from "../../../../../Common/Services/LaborDayApprove";
import {
  ILaborDayApproveRequestDto,
} from "../../../../../Common/Contracts/ILaborDayApprove";
import { Button, } from "react-bootstrap";
import { resolve, reject } from "q";
import chevronRight from "../../../../../Common/Assets/Images/icons/bx-chevron-right.svg";
import OutsideClickHandler from "react-outside-click-handler";
import { ReactPageLoader } from "../../../../../Common/Components/ReactPageLoader";
import LaborDayApprovalDetailsNew from "./LaborDayApprovalDetailsNew";
import "./LaborDayApprovalNew.scss";
import { Utils } from "../../../../../Common/Utilis";


let localStorageUser: any;
export default class LaborDayApprovalNew extends React.Component<any, any> {
  private laborDayAppChild: any;
  constructor(props: any) {
    super(props);

    this.laborDayAppChild = React.createRef();

    this.state = {
      loaderTrue: false,
      showEhidLabel: true,
      hidValue: props.parentState.hidValue,
      hotelLetterCode: props.parentState.hotelName,
      tableExpended: [],
      laborDayApprovalHotelData: [],
      isLetterCode: props.parentState.isLetterCode,
      isExpand: false,
      expendedRow: {},
      lblType:"",    
      reloadChildData:false
    };
  }

  dayToApproveHotel(request: ILaborDayApproveRequestDto) {
   
    this.setState({ loaderTrue: true,selectedhid:request.hotelID});
    request.type=this.props.parentState.isGMDayPermission;  

    LaborDayApprove.GetLaborOverviewDailyHidDetailsByGM(request)
      .then(async (result: any | null) => {
      
        let laborDayApproveData: any = [];
        if (result !== null && result.length > 0) {
          for (let i = 0; i < result.length; i++) {
            let pushObject: any = result[i];

            pushObject.rowNo = i + 1;
            let status: string = "Needs Review";
           // if()
            pushObject.isGM=this.props.parentState.isGMDayPermission;
            // if(this.props.parentState.isGMDayPermission==="GMApproveDay")
            // {
            //   pushObject.isGM="GMApproveDay";
            // }
            // if (
            //   Number(pushObject.autoPunchOut) === 0 &&
            //   Number(pushObject.overBudgetDept) === 0 &&
            //   Number(pushObject.underBudgetDept) > 0
            // ) {
            //   status = "Ready to Approve";
            // }
            pushObject.status = status;
            let tempDate = pushObject.dayToApprove.split(",");
            pushObject.dayToApprove = `${tempDate[0]} ${Number(tempDate[1])}, ${
              tempDate[2]
            }`;
            pushObject.action = "";
            pushObject.approveButtonSpinner = false;

            laborDayApproveData.push(pushObject);
          }
          this.setState({ laborDayApprovalHotelData: laborDayApproveData });
        } else {
          Utils.toastError(result.message, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "laborDayOverviewContainer",
          });
        }

        let msgType="";
        if(this.props.parentState.isGMDayPermission==="DailyApproveDay")
        {
          msgType="Daily Labor Approval"
        }else{
          msgType="Daily GM Approvals"
        }
        
        this.setState({ loaderTrue: false,lblType:msgType });
        resolve();
      })
      .catch((error) => {
        this.setState({ loaderTrue: false });
        Utils.toastError(`Server Error: ${error}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "laborDayOverviewContainer",
        });
        reject();
      });
  }

  allHotelBreadCrumb = (e: any) => {
    e.stopPropagation();
    this.props.laborDayApprovalBreadCrumbs("allHotels");
  };

  navigatetoPerformancePage= (e: any, row: any, rowIndex: any) => {
   
    e.stopPropagation();
    let date = window.btoa(row.date);
    let hotelID = window.btoa(row.hotelID);
    let url =window.location.origin+`/labor-management/Performance?date=${date}&hotelId=${hotelID}`
   
    url = encodeURI(url);


      window.open(url,
      "_blank")

    
  };
  laborDayApproveForReport= (e: any, row: any, rowIndex: any) => {
    ;
    e.stopPropagation();

    let url =window.location.origin+`/labor-management/viewer?date=${ row.date}&hotelId=${row.hotelID}`
   
    url = encodeURI(url);


      window.open(url,
      "_blank")

    
  };

  laborDayReview = (e: any, row: any, rowIndex: any) => {
    e.stopPropagation();

    if (!this.state.tableExpended.includes(row.rowNo)) {
      row.action = "Expended";
      this.setState({ isExpand: true });
      this.setState(() => ({
        tableExpended: [...this.state.tableExpended, row.rowNo],
      }));
    } else {
      row.action = "";
      this.setState({ isExpand: false });
      this.setState(() => ({
        tableExpended: this.state.tableExpended.filter(
          (x: any) => x !== row.rowNo
        ),
      }));
    }
  };

  handleOnExpand = (row: any, isExpand: any, rowIndex: any, e: any) => {
   
    ;
    if(row.underBudgetDept===0 && row.overBudgetDept===0 && row.autoPunchOut===0){
      this.setState({ isExpand: false });
      return;
    }
    if (isExpand) {
      ;
      row.action = "Expended";
      this.setState({ isExpand: true });
      this.setState(() => ({
        tableExpended: [...this.state.tableExpended, row.rowNo],
      }));
      this.setState({ expendedRow: row });
      
    } else {
      ;
      row.action = "";
      this.setState({ isExpand: false });
      this.setState(() => ({
        tableExpended: this.state.tableExpended.filter(
          (x: any) => x !== row.rowNo
        ),
      }));
    }
  };

  childApprove = (updatedRow: any, renderTable: string) => {
    let data = this.state.laborDayApprovalHotelData;
    if (renderTable === "autoPunchOut") {
      data[updatedRow.rowNo - 1].autoPunchOut = updatedRow.autoPunchOut;
    } else if (renderTable === "overBudget") {
      data[updatedRow.rowNo - 1].overBudgetDept = updatedRow.overBudgetDept;
    } else if (renderTable === "underBudget") {
      data[updatedRow.rowNo - 1].underBudgetDept = updatedRow.underBudgetDept;
    }
    data[updatedRow.rowNo - 1].status = updatedRow.status;
    this.setState({
      laborDayApprovalHotelData: data,
    });
  };

  removeRow = (rowNo: any) => {    
    let data = [...this.state.laborDayApprovalHotelData];

    let rowIndex: number = -1;

    for (let i = 0; i < data.length; i++) {
      if (data[i].rowNo === rowNo) {
        rowIndex = i;
        break;
      }
    }
    if (rowIndex >= 0) {
      data.splice(rowIndex, 1);
    }
    data.forEach((ele,index)=>{
      if(index >= rowIndex){
          ele.rowNo = ele.rowNo -1
      }
    })
    this.props.laborDayApproval("Approved");

    this.setState({
      laborDayApprovalHotelData: data,
      isExpand: false,
      tableExpended:[]
    });
  };

  IsRowEpande = (rowNo: any) => {    
    let data = [...this.state.laborDayApprovalHotelData];

    let rowIndex: number = -1;

    // for (let i = 0; i < data.length; i++) {
    //   if (data[i].rowNo === rowNo) {
    //     rowIndex = i;
    //     break;
    //   }
    // }
    // if (rowIndex >= 0) {
    //   data.splice(rowIndex, 1);
    // }
    // data.forEach((ele,index)=>{
    //   if(index >= rowIndex){
    //       ele.rowNo = ele.rowNo -1
    //   }
    // })
    // this.props.laborDayApproval("Approved");

    this.setState({
      laborDayApprovalHotelData: data,
      isExpand: false,
      tableExpended:[]
    });
  };

  refreshChild=()=> {
    this.setState({reloadChildData:true});  
    setTimeout(()=>{
      this.setState({reloadChildData:false}); 
    }) 
  }

  handlePositionDetail(row: any) {
    this.props.positionDetail(row);
  }

  onOutsideClick = (e: any) => {
    if (document?.getElementById("performanceSlideOut")) {
      return;
  }
    let isChildClick = false;

    e.path?.map((x: any) => {
      if (
        x.className ===
        "body-sec performance-position justify-content-end container-fluid"
      ) {
        isChildClick = true;
             } 
     
      return false;
    });
    if (isChildClick) {
      return;
    }
    if (this.state.isExpand) {
      this.handleOnExpand(this.state.expendedRow, false, "", "");
    }
  };

  UpdateApprovedStatus=(rowNo: any)=>{ 
    let data = [...this.state.laborDayApprovalHotelData];

    let rowIndex: number = -1;

    for (let i = 0; i < data.length; i++) {
      if (data[i].rowNo === rowNo) {
        rowIndex = i;       
        break;
      }
    }
   
    if (rowIndex >= 0) {
      data[rowIndex].pendingDeptID="Approved";
    }

    this.setState({
      laborDayApprovalHotelData: data,
   
    });
  }



  UpdateBagesStatus=(rowNo: any)=>{ 
    let data = [...this.state.laborDayApprovalHotelData];

    let rowIndex: number = -1;

    for (let i = 0; i < data.length; i++) {
      if (data[i].rowNo === rowNo) {
        rowIndex = i;       
        break;
      }
    }
   
    if (rowIndex >= 0) {
      data[rowIndex].pendingDeptID="Approved";
      data[rowIndex].waitingForMe="Waiting on Others";
      
    }

    this.setState({
      laborDayApprovalHotelData: data,
   
    });
  }
 

  render() {
    const rowClasses = (row: any, rowIndex: any) => {
      if (this.state.isExpand === true && row.action === "Expended") {
        return "row-Expended";
      } else if (row.approveButtonSpinner) {
        return "p-none";
      } else if (this.state.isExpand === true) {
        return "row-Collapsed";
      } else {
        return "row-Action";
      }
    };

    const expandRow = {
      renderer: (row: any) => (
        <div className="rowExpended" id="laborDayDetails">
           <LaborDayApprovalDetailsNew
            ref={this.laborDayAppChild}
            row={row}
            childApprove={this.childApprove}
            removeRow={this.removeRow}
            IsRowEpande={this.IsRowEpande}
            
            positionDetail={this.handlePositionDetail.bind(this)}
            reloadChildData= {this.state.reloadChildData}
            UpdateApprovedStatus={this.UpdateApprovedStatus}
            UpdateBagesStatus={this.UpdateBagesStatus}
          />
        </div>
      ),
      expanded: this.state.tableExpended,
      onExpand: this.handleOnExpand,
      showExpandColumn: true,
      expandByColumnOnly: true,
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <span className="chevronExpended">
              <img src={chevronRight} alt="chevron Right" />
            </span>
          );
        }
        return (
          <span className="chevronCollpsed">
            <img src={chevronRight} alt="chevron Right" />
          </span>
        );
      },
    };

    const columns = [
      {
        dataField: "status",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => {
          if (row.status === "Ready to Approve") {
            return (
              <div className="laborDayDetails">
                <div className="laborDayStatus" data-tip={row.status}>
                  <span className="DayStatusApprove">
                    <ReactTooltip
                      effect="solid"
                      event="mouseover mouseenter"
                      eventOff="mouseleave mouseout scroll mousewheel blur"
                      place="bottom"
                    />
                  </span>
                </div>
                <div className="laborDetailsdayDate">
                  <span className="day">{row.weekDayName}</span>
                  <span className="date">{row.dayToApprove}</span>
                </div>
              </div>
            );
          } else {
            return (
              <div className="laborDayDetails">
                <div className="laborDayStatus" data-tip={row.status}>
                  <span className="DayStatusPending">
                    <ReactTooltip
                      effect="solid"
                      event="mouseover mouseenter"
                      eventOff="mouseleave mouseout scroll mousewheel blur"
                      place="bottom"
                    />
                  </span>
                </div>
                <div className="laborDetailsdayDate">
                  <span className="day">{row.weekDayName}</span>
                  <span className="date">{row.dayToApprove}</span>
                </div>
              </div>
            );
          }
        },
      },
      {
        dataField: "autoPunchOut",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="autoPunchOutsData">
            <span className="autoPunchOutsCount">{Number(row.autoPunchOut) > 0 ? row.autoPunchOut : 0}</span>
            <span className="autoPunchOutsLabel">Auto Punch Outs</span>
          </div>
        ),
      },
      {
        dataField: "pendingDeptID",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => {
             if(row.isGM === "GMApproveDay"){
                 return (
          <div className="overBudgetDeptsData">
            <span className="overBudgetDeptsCount">{row.pendingDeptID}</span>
            <span className="overBudgetDeptsLabel">Department Approvals</span>
          </div>
                 )
             }
        }
      },

      {
        dataField: "waitingForMe",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => {
             if(row.isGM === "GMApproveDay"){
              if(row.waitingForMe === "Waiting on Me")
              {
                 return (
          <div className="overBudgetDeptsData">
            <span className="overBudgetDeptsCount waitingForMeBadge txt-red">{row.waitingForMe}</span>
            <span className="overBudgetDeptsLabel d-block mt-1">Approver</span>
          </div>
                 )
              }
              else{
                return (
                  <div className="overBudgetDeptsData">
                    <span className="overBudgetDeptsCount waitingForOtherBadge txt-black">{row.waitingForMe}</span>
                    <span className="overBudgetDeptsLabel d-block mt-1">Approver</span>
                  </div>
                )
              }
             }
        }
      },


      {
        dataField: "action",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <Button
              className="btn-outline-primary laborDayReadyToReview"
              onClick={(e: any) => this.laborDayReview(e, row, rowIndex)}
            >
              {row.action === "Expended" ? "Close" : "Review"}
            </Button>
          );


        },
      }, 
    ];
    return (
      <>
        {this.state.loaderTrue ? (
          <ReactPageLoader useas={"daysToApproveDetailList"} />
        ) : (
          <div className="laborDayApproval">
            <div className="laborDayApprovalActionHeader">
              {this.state.hidValue === "All" && (
                <span className="laborBreadCrumbs">
                  <span onClick={this.allHotelBreadCrumb}>
                    &laquo; All Properties
                  </span>
                </span>
              )}
              {this.state.showEhidLabel && (
                <span className="laborHeader">
                  {this.state.hotelLetterCode === "All"
                    ? this.state.isLetterCode
                    : this.state.hotelLetterCode}{" "}
                  {/* Daily Labor Approval */}
                  {this.state.lblType}
                </span>
              )}
            </div>
            <OutsideClickHandler
              onOutsideClick={this.onOutsideClick}
              // onOutsideClick={(e) => {
              //   this.onOutsideClick(e);
              // }}
            >
              <div className="laborDayApprovalTable cuslaborDayApprovalTable" id="laborDayApprovalOuter">
                <BootstrapTable
                  keyField="rowNo"
                  data={this.state.laborDayApprovalHotelData}
                  columns={columns}
                  expandRow={expandRow}
                  rowClasses={rowClasses}
                  hover
                />
              </div>
            </OutsideClickHandler>
          </div>
        )}
      </>
    );
  }
}
