import "./globals";
import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
// import { createStore } from "redux";
import { Provider } from "react-redux";
// import reducer from "./Redux/reducer";
// import IndexReducer from "./Redux/Reducers/IndexReducer";
import ReduxStore from "./Redux/Store/IndexStore";

// const store = createStore(reducer);
// const store = createStore(IndexReducer);

ReactDOM.render(
  // <Provider store={store}>
  <Provider store={ReduxStore}>
    <App />
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
