import * as React from "react";
import { AgGridReact } from "ag-grid-react";
import { SingleSearchDropdownList } from "../../../Common/Components/SingleSearchDropdownList";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import {
  Button,
  Container,
  Dropdown,
  Form,
  FormGroup,
  Modal,
} from "react-bootstrap";
import { FiChevronDown, FiTarget } from "react-icons/fi";
import { Hotel } from "../../../Common/Services/Hotel";
import { ToastContainer, toast } from "react-toastify";
import { ReactPageLoader } from "../../../Common/Components/ReactPageLoader";
import OutsideClickHandler from "react-outside-click-handler";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import { EntityManagementSlideOut } from "./EntityManagementSlideOut";
import { userUniqueID } from "../../../Common/Services/LocalStorage";
import { LaborPerformance as laborPerformance } from "../../../Common/Services/LaborPerfomance";
import { ILaborSaveAsDefaultDto } from "../../../Common/Contracts/ILaborSaveAsDefaultDto";
import { accountService } from "../../../Common/Services/account";
import { OtpModal } from "../../../Common/Components/OtpModal";
import { DwollaService } from "../../../Common/Services/DwollaService";
import { DwollaSetupModal } from "./DwollaSetupModal";
import { DigitalACHCustomDwolla } from "./DigitalACHCustomDwolla";
import { EntityManagementService } from "../../../Common/Services/EntityManagementService";
import moment from "moment";
import { AddBankAccountSetup } from "./AddBankAccountSetup";
import { ConfirmBankAccount } from "./ConfirmBankAccount";
import { PlaidSetup } from "../../../Common/ThirdPartyServices/PlaidSetup";
import { ConfirmationModal } from "../../../Common/Components/ConfirmationModal";
import { Register } from "../../../Common/Services/Register";
import { Utils } from "../../../Common/Utilis";

const tooltipMessageAdd =
  "Enabling Digital ACH for a property requires a Special Permission. Please contact your administrator to have this Special Permission assigned to you.";
export class DigitalAchTab extends React.Component<any, any> {
  private pageName = "EnableDigitalAch";
  private DACHtoggleRef: any;
  constructor(props: any) {
    super(props);
    this.DACHtoggleRef = React.createRef();
    this.state = {
      isDataLoaded: false,
      digitalAChPaymentsEnabled: false,
      hotelDetails: {},
      verfiedUser: false,
      certifiedUser: false,
      showDACHCOnfiguration: false,
      disablingFromToggleButton: false,
      showAddBankSetup: false,
      enableDACH: "No",
      isPlaidSetupButton: true,
      showConfirmBankAccountModal: false,
      isManualBankAccountAdded: false,
      bankName: "",
      mode: "0",
      otpSuccess: false,
      fundingSources: [],
      registerRequest: {
        hotelID: -1,
        oprID: -1,
        selectType: "30",
        isDepositHide: "Yes",
        startDate: "",
        endDate: "",
        acctType: "M",
      },
      accountNames: [],
      isOTPVerified: false,
      isOtpNeed: false,
    };
  }

  componentDidMount() {
    // this.getDefaultView();
    this.getHotelModules();
    this.GetFundingSourceStatusByHid();
    let registerRequest1 = { ...this.state.registerRequest };
    const { hotelDetails, hotelId } = this.props;
    registerRequest1.hotelID = hotelId;
    registerRequest1.lettercode = hotelDetails?.hotelDetail?.lettercode;
    //this.getRegisterlist(registerRequest);
    this.getRegisterNames(registerRequest1);
  }

  getRegisterNames = (registerRequest: any) => {
    let regularlist: any = [];
    let splitlist: any = [];
    let selectedaccount = "";
    let selectedStatus = "";
    Register.RegisterAccount(registerRequest)
      .then(async (result: any[] | null) => {
        if (result !== null) {
          let sharedHIDs: any[] = [];
          let accountNames: any[] = [];

          result.sort((a, b) =>
            a.status !== b.status ? (a.status < b.status ? -1 : 1) : 0
          ); //_.sortBy(result, "status");

          result.forEach((element) => {
            let hidCode: any = [];
            if (element.status === "Active") {
              let acctName: any = {};
              let oprID = element.oprID;
              let name = element.name;
              acctName.id = oprID;
              acctName.sort = element.orderBy;
              acctName.name = name;
              acctName.status = element.status;
              accountNames.push(acctName);

              if (Number(this.state.selectedOprID) === Number(oprID)) {
                selectedaccount = name;
                selectedStatus = element.status;
              }
            }
          });

          this.setState({ accountNames: accountNames });
        }
        // resolve();
      })
      .catch((error) => {
        // reject();
      });
  };

  getHotelModules = () => {
    this.setState({ isHotelDetailsLoaded: false });
    EntityManagementService.GetHotelModules(this.props?.hotelId)
      .then((hotelDetails: any) => {
        let verfiedUser = false;
        let certifiedUser = false;
        let digitalAChPaymentsEnabled = false;
        const hotelModules = [...hotelDetails?.hotelModules];
        const digitalACHEnabled = hotelDetails.digitalACHEnabled;
        if (hotelModules?.length > 0) {
          hotelDetails.hotelModules = hotelModules.map((item) => {
            item.startDate = item.startDate
              ? moment(item.startDate).format("MM/DD/YY")
              : item.startDate;
            item.terminationDate = item.terminationDate
              ? moment(item.terminationDate).format("MM/DD/YY")
              : item.terminationDate;
            return item;
          });
        }
        if (digitalACHEnabled === "Enabled") {
          digitalAChPaymentsEnabled = true;
        }
        if (hotelDetails.dwolla_customersDetail !== null) {
          verfiedUser = true;
          if (
            hotelDetails.dwolla_customersDetail.beneficialOwnerRequired === "No"
          ) {
            certifiedUser = true;
          } else if (
            hotelDetails.dwolla_customersDetail.beneficialOwnerStatus ===
            "certified"
          ) {
            certifiedUser = true;
          }
        }
        this.setState(
          {
            hotelDetails,
            verfiedUser,
            certifiedUser,
            digitalAChPaymentsEnabled,
            showDACHCOnfiguration: digitalAChPaymentsEnabled,
          },
          () => this.setState({ isHotelDetailsLoaded: true })
        );
      })
      .catch((error) => {
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "bankDetailsSlideout",
        });
        this.setState({ isHotelDetailsLoaded: true });
      });
  };

  GetFundingSourceStatusByHid = () => {
    this.setState({ isDataLoaded: false });
    let reqObj: any = {};
    reqObj.HotelId = this.props?.hotelId;
    accountService
      .GetFundingSourceStatusByHid(reqObj)
      .then(async (result: any | null) => {
        if (result !== null) {
          console.log(result);
          this.setState({
            fundingSources: result,
            isDataLoaded: true,
          });
        }
      });
  };

  PlaiddSetupButtonPermission(hid) {
    accountService
      .GetRolePermission_Ach(hid)
      .then(async (result: any | null) => {
        if (result !== null) {
          this.setState({
            isPlaidSetupButton: result[0].isDACHReady === "Yes" ? true : false,
            enableDACH: result[0].isDACHEnable,
          });
        }
      });
  }

  openOtpModal = (isDisableDCH, ModalName, Purpose, event = false) => {
    const OTPVerified = this.state.isOTPVerified;
    this.setState(
      {
        isOtpNeed: !OTPVerified,
        isDisableDCH,
        ModalName,
        Purpose,
        event,
      },
      () => {
        if (OTPVerified) {
          if (isDisableDCH) {
            this.disableACH();
          } else {
            this.openDwollSetUpEntryPanel();
          }
        } else {
          this.ReSendOTP();
        }
      }
    );
    // this.ValidateOTP(true);
  };
  openOtpModalForBankSetup = (Purpose) => {
    const OTPVerified = this.state.isOTPVerified;

    this.setState(
      { isOtpNeed: !OTPVerified, Purpose, isDisableDCH: false },
      () => {
        if (OTPVerified) {
          this.setState({ showAddBankSetup: true, mode: 1 });
        } else {
          this.ReSendOTP();
        }
      }
    );
  };

  setupAgainopenOtpModal = (isDisableDCH, ModalName, Purpose) => {
    const OTPVerified = this.state.isOTPVerified;

    this.setState(
      {
        isOtpNeed: !OTPVerified,
        callResetupApi: true,
        isDisableDCH,
        ModalName,
        Purpose,
      },
      () => {
        if (OTPVerified) {
          let request = {} as any;
          request.hid = this.props.hotelId;
          request.DwollaCustId =
            this.state.hotelDetails?.dwolla_customersDetail.dwollaCustId;
          DwollaService.DwollaResetup(request).then((newresult) => {
            this.setState({ isOpenDwolla: false }, () => {
              this.setState({ showunverifedconfirmation: false });
              this.getHotelModules();
            });
          });
        } else {
          this.ReSendOTP();
        }
      }
    );
    // this.ValidateOTP(true);
  };

  ReSendOTP = () => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    // debugger
    let userName = storage === null ? 0 : (storage.userName as any);
    let request: any = {};
    request.userName = userName;
    request.purpose = this.state.Purpose;
    request.otpCode = "";
    request.primaryContact = "Phone";
    accountService
      .OTPForAuthentication(request)
      .then(async (result: any | null) => {
        if (result !== null) {
          if (result.status === "Success") {
            toast.success(result.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "bankDetailsSlideout",
            });

            this.setState({ isOtpNeed: true });
          } else {
            Utils.toastError("Something went wrong", {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "bankDetailsSlideout",
            });
          }
        }
      });
  };

  closeOtpModal = () => {
    if (this.state.isOtpNeed) {
      const toggleState = this.state.digitalAChPaymentsEnabled;
      this.setState({
        isOtpNeed: false,
        digitalAChPaymentsEnabled: toggleState,
      });
      this.DACHtoggleRef.current.disabled = false;
    }
    if (this.state.disablingFromToggleButton) {
      this.setState({ disablingFromToggleButton: false });
    }
  };

  ValidateOTP = (OTPCode) => {
    this.setState(
      { otpSuccess: true, isOtpNeed: false, isOTPVerified: true },
      () => {
        if (this.state.isDisableDCH) {
          this.disableACH();
        } else {
          if (this.state.callResetupApi) {
            let request = {} as any;
            request.hid = this.props.hotelId;
            request.DwollaCustId =
              this.state.hotelDetails?.dwolla_customersDetail.dwollaCustId;
            DwollaService.DwollaResetup(request).then((newresult) => {
              this.setState({ isOpenDwolla: false }, () => {
                this.getHotelModules();
              });
            });
          }
          const { Purpose } = this.state;
          if (Purpose === "Bank Validation") {
            this.setState({ showAddBankSetup: true, mode: 1 });
            return;
          } else if (Purpose === "DACHtoggleChange") {
            this.saveDigitalAChPayments(this.state.event);
            return;
          } else if (Purpose === "Reconnect Bank") {
            this.reconnectBank(
              this.state.reconnectBankDetail,
              this.state.showPlaidReconnectBtn
            );
            return;
          } else if (Purpose === "Remove Bank") {
            this.confirmRemoveBank(this.state.removeBankDetails, true);
            return;
          }
          this.openDwollSetUpEntryPanel();
        }
      }
    );
  };

  verifiedOTP = () => {
    this.setState({ otpSuccess: true, isOtpNeed: false, isOTPVerified: true });
  };
  isCheckAgreebox = (isselectedheckAgree: false) => {
    this.setState({ showselectedheckAgree: false, isselectedheckAgree });
  };

  openDwollSetUpEntryPanel = () => {
    let documentType = "document";
    this.setState({ showunverifedconfirmation: false });
    if (this.state.ModalName == "dwolla-beneficial-owners") {
      documentType = "boddocument";
    }
    this.setState({ documentType });
    if (this.state.hotelDetails?.dwolla_customersDetail?.dwollaCustId) {
      this.hideDwollasetupModal(true);
    } else {
      this.setState({ showDwollSetUpModal: true });
    }
  };

  hideDwollasetupModal = (isOpenDwolla: boolean = false) => {
    this.setState({ showDwollSetUpModal: false, isOpenDwolla });
  };

  onSuspendedClick = () => {
    alert(
      "Account suspended by Dwolla. Please contact your Inn-Flow administrator for more information."
    );
  };

  disableACH = () => {
    const { dwolla_customersDetail: dwollaCustomersDetail } =
      this.state?.hotelDetails;
    let request = {} as any;
    request.DwollaCustId = dwollaCustomersDetail.dwollaCustId;
    request.DwollaCustStatus = dwollaCustomersDetail.customerstatus;
    request.HID = this.props.hotelId;

    DwollaService.DisableACH(request)
      .then((result) => {
        if (result.message == "FundingSourceexists") {
          Utils.toastError(
            "DACH cannot be disabled,Please deactivate associated bank account.",
            {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "bankDetailsSlideout",
            }
          );
          this.setState({
            digitalAChPaymentsEnabled: true,
            disablingFromToggleButton: false,
          });
        } else  if (result.message == "InvoiceSourceexists") {
          Utils.toastError(
            "DACH cannot be disabled, Some invoices are pending for payment.",
            {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "bankDetailsSlideout",
            }
          );
          this.setState({
            digitalAChPaymentsEnabled: true,
            disablingFromToggleButton: false,
          }); 
        } else if (result.message == "NoFundingSource") {
          toast.success("DACH has been successfully disabled", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "bankDetailsSlideout",
          });
          if (this.state.disablingFromToggleButton) {
            this.saveDACHTogglePosition("Disabled");
          }
          // this.setState({
          //   digitalAChPaymentsEnabled: false,
          // });
        }
        this.hideDwollasetupModal();
        this.getHotelModules();
        this.DACHtoggleRef.current.disabled = false;
      })
      .catch((error) => {
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "bankDetailsSlideout",
        });
      })
      .finally(() => {
        this.setState({ isDataSubmitting: false });
      });
  };
  hideCustomDwollaModal = () => {
    this.setState({ isOpenDwolla: false }, () => {
      this.getHotelModules();
    });
  };

  statusCallBack = (data, msg) => {
    if (msg === "certified") {
      toast.success("Cerified user successfully configured", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "bankDetailsSlideout",
      });
      this.setState({ isOpenDwolla: false });
    } else if (msg === "customers") {
      toast.success("Verified user successfully configured", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "bankDetailsSlideout",
      });
      this.setState({ isOpenDwolla: false });
    } else if (msg.includes("documents")) {
      toast.success("Document uploaded successfully.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "bankDetailsSlideout",
      });
      this.setState({ isOpenDwolla: false });
    } else if (msg === "error") {
      Utils.toastError(data, {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "bankDetailsSlideout",
      });
    }
    this.props.isReloadRequired(true);
    this.getHotelModules();
  };

  plaidStatusCallback = (data, msg) => {
    // console.log(data,msg);

    this.setState({ showAddBankSetup: false });
  };

  saveDigitalAChPayments = (event) => {
    debugger;
    if (this.state.isOTPVerified) {
      this.DACHtoggleRef.current.disabled = true;
      const { dwolla_customersDetail } = this.state.hotelDetails;
      this.setState({
        digitalAChPaymentsEnabled: event,
      });
      if (event) {
        this.saveDACHTogglePosition("Enabled");
      } else {
        if (dwolla_customersDetail?.dwollaCustStatus === "verified") {
          // this.openOtpModal(true, "dwolla-business-vcr", "Disable ACH");
          this.setState({ disablingFromToggleButton: true });
          this.disableACH();
        } else {
          this.saveDACHTogglePosition("Disabled");
          this.setState({ isOpenDwolla: false });
        }
      }
    } else {
      this.openOtpModal(false, "", "DACHtoggleChange", event);
    }
  };

  saveDACHTogglePosition = (status) => {
    let request: any = {};
    request.Hotelid = this.props?.hotelId;
    request.Status = status;

    Hotel.EnableDACHPaymentForHID(request)
      .then((res: any) => {
        if (res.message === "Fail") {
          Utils.toastError("Save Digital ACH failed.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "bankDetailsSlideout",
          });
          if (status === "Disabled") {
            this.setState({
              showDACHCOnfiguration: true,
              digitalAChPaymentsEnabled: true,
              disablingFromToggleButton: false,
            });
          } else {
            this.setState({
              showDACHCOnfiguration: false,
              digitalAChPaymentsEnabled: false,
            });
          }
        } else if (res.message === "Success") {
          toast.success("Digital ACH successfully saved.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "bankDetailsSlideout",
          });
          if (status === "Disabled") {
            this.setState({
              showDACHCOnfiguration: false,
              digitalAChPaymentsEnabled: false,
              disablingFromToggleButton: false,
            });
          } else {
            this.setState({
              showDACHCOnfiguration: true,
              digitalAChPaymentsEnabled: true,
            });
          }
          // this.setState({ showDACHCOnfiguration: status === "Enabled" });
          this.props.isReloadRequired(true);
        }
        this.DACHtoggleRef.current.disabled = false;
        // console.log(res);
      })
      .catch((error) => {
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "bankDetailsSlideout",
        });
        this.DACHtoggleRef.current.disabled = false;
        if (status === "Disabled") {
          this.setState({
            showDACHCOnfiguration: true,
            digitalAChPaymentsEnabled: true,
            disablingFromToggleButton: false,
          });
        } else {
          this.setState({
            showDACHCOnfiguration: false,
            digitalAChPaymentsEnabled: false,
          });
        }
      });
  };

  handleCancelSetupAccount = (state) => {
    if (state) {
    } else {
    }
    this.setState({ showAddBankSetup: false });
  };

  buttonClickHandler = (state) => {
    this.GetFundingSourceStatusByHid();
    if (state) {
    } else {
    }
  };

  handleSubmitBankSetup = (childState, callFrom) => {
    const { accountName } = childState;
    // if (callFrom === "manual") {
    //   this.GetFundingSourceStatusByHid()
    //   // this.setState({
    //   //   showAddBankSetup: false,
    //   //   showConfirmBankAccountModal: true,
    //   //   isManualBankAccountAdded: true,
    //   //   bankName: accountName,
    //   //   childState,
    //   // });
    // } else if (callFrom === "plaid") {
    //   this.setState({
    //     showAddBankSetup: false,
    //     showConfirmBankAccountModal: false,
    //     isManualBankAccountAdded: false,
    //     bankName: accountName,
    //     childState,
    //     isBankDetailsConfirmed: true,
    //   });
    // }
    this.setState({ showAddBankSetup: false }, () => {
      this.GetFundingSourceStatusByHid();
    });
  };

  plaidFunction = (childState) => {
    debugger;
    const { mode } = this.state;
    if (true) {
      let msg = "";
      if (mode === 1) {
        if (this.state.plaidStatus === "inactive") {
          PlaidSetup.saveFundingSourceHotel(
            this.props.hotelId,
            "inactive",
            this.state.formData.id
          );
          msg = "Funding source has been added successfully.";
        } else {
          PlaidSetup.IntegratePlaid(
            this.props.hotelId,
            childState?.formData.id,
            childState?.formData.accountName,
            this.plaidStatusCallback
          );
          msg = "Funding source has been added successfully.";
        }
      } else if (mode === 2) {
        PlaidSetup.ReconectHotelLinkToken(
          this.props.hotelId,
          childState.entityBankRefId
        );
        this.props.isReloadRequired(true);
        msg = "You account has been reconnected successfully.";
      } else if (mode === 3) {
        PlaidSetup.disabledACH(this.props.hotelId, childState.entityBankRefId);
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        this.props.isReloadRequired(true);

        // let selectedExistAcc = { ...this.state.selectedExistAcc };
        // selectedExistAcc.oprID = this.props.oprID;
        // selectedExistAcc.hotelID = this.state.hotelID;
        // selectedExistAcc.tenantID = tenantID;
        // selectedExistAcc.obaccno = "";
        // this.bindFormData(selectedExistAcc);
        msg = "Bank account has been disabled for Digital ACH successfully.";
      }
      const intervalId = setInterval(() => {
        let lblStatus = document.getElementById("lblSetupStatus");
        if (lblStatus?.innerHTML === "Success") {
          lblStatus.innerHTML = "";
          clearInterval(intervalId);
          toast.success(msg, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "bankDetailsSlideout",
          });
          // this.handleCancelSetupAccount(true);
          this.handleSubmitBankSetup(childState, "plaid");

          // this.PlaiddSetupStatus(this.props.hidValue, this.state.formData.id);
        }
      }, 1000);
    } else {
      this.setState({ isOtpNeed: true });
    }
  };

  PlaiddSetupStatus(hid, id) {
    accountService
      .PlaiddSetupStatus(id, hid)
      .then(async (result: any | null) => {
        if (result !== null) {
          this.setState({ plaidbtnStatus: "", plaidStatus: "" }, () => {
            this.setState({
              plaidbtnStatus: result.status,
              plaidStatus: result.isActive,
            });
          });
        }
      });
  }

  removeBankACH = (item) => {
    this.setState({ mode: 3 }, () => {
      this.plaidFunction(item);
    });
  };
  reconnectBank = (item, showPlaidReconnectBtn = false) => {
    const OTPVerified = this.state.isOTPVerified;
    this.setState(
      {
        isOtpNeed: !OTPVerified,
        Purpose: "Reconnect Bank",
        isDisableDCH: false,
      },
      () => {
        if (OTPVerified) {
          if (showPlaidReconnectBtn) {
            this.setState({ mode: 2 }, () => {
              this.plaidFunction(item);
            });
          } else {
            this.enableFundingSource(item);
          }
        } else {
          this.setState(
            {
              reconnectBankDetail: item,
              showPlaidReconnectBtn: showPlaidReconnectBtn,
            },
            () => {
              this.ReSendOTP();
            }
          );
        }
      }
    );

    // this.setState({ mode: 2 }, () => {
    //   this.plaidFunction(item);
    // });
  };

  enableFundingSource = (item, purpose = "") => {
    let request: any = {};
    if (purpose === "reconnect") {
      request.bankRefID = item.id;
    } else {
      request.bankRefID = item.entityBankRefId;
    }
    request.purpose = purpose;
    request.hotelID = this.props.hotelId;
    accountService
      .EnableFundingSource(request)
      .then(async (result: any | null) => {
        if (result !== null) {
          if (result.status === "Success") {
            this.GetFundingSourceStatusByHid();
            this.setState({ showAddBankSetup: false });
            this.props.isReloadRequired(true);
            // this.PlaiddSetupStatus(this.props.hidValue, this.state.formData.id);
          }
        }
        // resolve();
      })
      .catch((error) => {
        // reject();
      });
  };

  confirmRemoveBank = (item, mode) => {
    debugger;
    if (mode) {
      const OTPVerified = this.state.isOTPVerified;
      this.setState(
        {
          isOtpNeed: !OTPVerified,
          Purpose: "Remove Bank",
          isDisableDCH: false,
        },
        () => {
          if (OTPVerified) {
            if (
              item.badgeText === "Unconfirmed" ||
              item.badgeText === "Processing"
            ) {
              accountService
                .deleteFundingSource(item.hotelId, item.entityBankRefId)
                .then((result: any) => {
                  if (result !== null) {
                    if (result?.success === "Success") {
                      toast.success("Bank has been removed successfully", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        containerId: "bankDetailsSlideout",
                      });
                      this.GetFundingSourceStatusByHid();
                    }
                  }
                })
                .catch((error) => {
                  Utils.toastError("Remove bank failed", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "bankDetailsSlideout",
                  });
                });
            } else {
              this.removeBankACH(item);
            }
          } else {
            this.setState({ removeBankDetails: item }, () => {
              this.ReSendOTP();
            });
          }
        }
      );
    } else {
    }
  };

  render() {
    const {
      isDataLoaded,
      digitalAChPaymentsEnabled,
      showDwollSetUpModal,
      isOpenDwolla,
      ModalName,
      isOtpNeed,
      hasDwolla_customersDetail,
      verfiedUser,
      certifiedUser,
      showDACHCOnfiguration,
      fundingSources,
      accountNames,
    } = this.state;
    const { isSpecialPermissionUser } = this.props;
    const {
      dwolla_customersDetail: dwollaCustomersDetail,
      dwollaCustomer_documentDetail: dwollaCustomerDocumentDetail,
      docstatus,
      boverificationStatus,
      digitalACHEnabled,
      hasAddDigitalACH,
    } = this.state?.hotelDetails;

    const confirmModalContent = [
      {
        title: "Remove Bank",
        desc: "Are you sure you want to remove this bank?",
        cancleAction: "Cancel",
        ActionTrue: "Remove",
      },
    ];

    let addBankButtonDisable = false;
    if (fundingSources.length === accountNames.length) {
      addBankButtonDisable = true;
    }

    return (
      <div className="DigitalAchTab">
        <ToastContainer enableMultiContainer containerId={"bankDetailsSlideout"} autoClose={2000} />
        {!isDataLoaded ? (
          <ReactPageLoader useas={"timesheetSlideout"} />
        ) : (
          <div className="body-section">
            <div className="form-section d-flex flex-column">
              <Form.Group className="d-flex flex-row">
                <Form.Check
                  disabled={!isSpecialPermissionUser}
                  type="switch"
                  id="dachEnaablePayments"
                  label="Digital ACH Payments"
                  ref={this.DACHtoggleRef}
                  // disabled={digitalACHEnabled === "Disabled"}
                  checked={digitalAChPaymentsEnabled}
                  onChange={(event: any) => {
                    this.saveDigitalAChPayments(event.target.checked);
                  }}
                />
              </Form.Group>
            </div>

            <div className="separator-line"></div>

            {showDACHCOnfiguration && isDataLoaded && (
              <>
                <div className="form-section d-flex flex-column">
                  <div className="heading">Configuration</div>
                  <FormGroup className="d-flex flex-row align-items-center">
                    <Form.Label>Verified User</Form.Label>
                    <div>
                      {(dwollaCustomersDetail?.dwollaCustStatus ===
                        "verified" ||
                        dwollaCustomersDetail?.customerstatus ===
                          "verified") && (
                        <div className="readonly-value">Verified</div>
                      )}
                    </div>
                    <div className="action-group d-flex flex-row align-items-center">
                      {dwollaCustomersDetail?.dwollaCustStatus ===
                        "verified" && (
                        <div>
                          <span
                            data-tip={
                              hasAddDigitalACH === false
                                ? tooltipMessageAdd
                                : ""
                            }
                          >
                            <ReactTooltip
                              effect="solid"
                              event="mouseover mouseenter"
                              eventOff="mouseleave mouseout scroll mousewheel blur"
                              place="bottom"
                              multiline={true}
                            />
                            <button
                              tabIndex={21}
                              disabled={
                                !isSpecialPermissionUser ||
                                hasAddDigitalACH === false ||
                                isOpenDwolla
                              }
                              type="button"
                              className="report-gallery btn-ghost btn btn-primary"
                              onClick={() =>
                                this.openOtpModal(
                                  true,
                                  "dwolla-business-vcr",
                                  "Disable ACH"
                                )
                              }
                              // style={{ cursor: "pointer" }}
                            >
                              Disable D-ACH
                            </button>
                          </span>
                        </div>
                      )}

                      {dwollaCustomersDetail?.dwollaCustStatus ===
                        "unverified" && (
                        <span
                          data-tip={
                            hasAddDigitalACH === false ? tooltipMessageAdd : ""
                          }
                        >
                          <ReactTooltip
                            effect="solid"
                            event="mouseover mouseenter"
                            eventOff="mouseleave mouseout scroll mousewheel blur"
                            place="bottom"
                            multiline={true}
                          />
                          <button
                            tabIndex={22}
                            disabled={
                              !isSpecialPermissionUser ||
                              hasAddDigitalACH === false ||
                              isOpenDwolla
                            }
                            type="button"
                            // style={{ cursor: "pointer" }}
                            className="report-gallery btn btn-primary"
                            onClick={() =>
                              this.setState({ showunverifedconfirmation: true })
                            }
                          >
                            Setup
                          </button>
                        </span>
                      )}
                      {this.state.showunverifedconfirmation && (
                        <div className="deleteReport confirmModal">
                          <OutsideClickHandler
                            onOutsideClick={() =>
                              this.setState({
                                showunverifedconfirmation: false,
                              })
                            }
                          >
                            <div className="deleteReportDropdown">
                              <div className="deleteReportBody">
                                <div className="title">
                                  Dwolla Setup Confirmation
                                </div>
                                <div className="description deleteReportTxt">
                                  <p>
                                    "DACH was already setup . Enabling DACH
                                    again will fetch all the previous entered
                                    details". Are you sure you want to continue
                                    ?
                                  </p>
                                </div>
                              </div>
                              <div className="deleteReportFooter action-link">
                                <button
                                  tabIndex={23}
                                  className="deleteReportCancel btn wht-bg"
                                  onClick={() =>
                                    this.setState({
                                      showunverifedconfirmation: false,
                                    })
                                  }
                                >
                                  Cancel
                                </button>
                                <button
                                  tabIndex={24}
                                  className="deleteReportDelete btn btn-primary btn-danger"
                                  onClick={(e) =>
                                    this.setupAgainopenOtpModal(
                                      false,
                                      "dwolla-business-vcr",
                                      "Setup"
                                    )
                                  }
                                >
                                  Yes
                                </button>
                              </div>
                            </div>
                          </OutsideClickHandler>
                        </div>
                      )}
                      {dwollaCustomersDetail?.customerstatus ===
                        "suspended" && (
                        <span
                          data-tip={
                            hasAddDigitalACH === false ? tooltipMessageAdd : ""
                          }
                        >
                          <ReactTooltip
                            effect="solid"
                            event="mouseover mouseenter"
                            eventOff="mouseleave mouseout scroll mousewheel blur"
                            place="bottom"
                            multiline={true}
                          />
                          <button
                            disabled={!isSpecialPermissionUser}
                            tabIndex={25}
                            type="button"
                            className="report-gallery btn btn-primary"
                            onClick={() => this.onSuspendedClick()}
                            // style={{ cursor: "pointer" }}
                          >
                            Suspended
                          </button>
                        </span>
                      )}

                      {dwollaCustomersDetail?.customerstatus === "retry" && (
                        <span
                          data-tip={
                            hasAddDigitalACH === false ? tooltipMessageAdd : ""
                          }
                        >
                          <ReactTooltip
                            effect="solid"
                            event="mouseover mouseenter"
                            eventOff="mouseleave mouseout scroll mousewheel blur"
                            place="bottom"
                            multiline={true}
                          />
                          <button
                            tabIndex={26}
                            disabled={
                              !isSpecialPermissionUser ||
                              hasAddDigitalACH === false ||
                              isOpenDwolla
                            }
                            type="button"
                            className="report-gallery btn btn-primary"
                            onClick={() =>
                              this.openOtpModal(
                                false,
                                "dwolla-business-vcr",
                                "Retry"
                              )
                            }
                            // style={{ cursor: "pointer" }}
                          >
                            Edit
                          </button>
                          <span className="badge color-red">
                            Information Required
                          </span>
                        </span>
                      )}
                      {dwollaCustomersDetail?.customerstatus === "document" && (
                        <span
                          data-tip={
                            hasAddDigitalACH === false ? tooltipMessageAdd : ""
                          }
                        >
                          <ReactTooltip
                            effect="solid"
                            event="mouseover mouseenter"
                            eventOff="mouseleave mouseout scroll mousewheel blur"
                            place="bottom"
                            multiline={true}
                          />
                          <button
                            tabIndex={27}
                            disabled={
                              !isSpecialPermissionUser ||
                              hasAddDigitalACH === false ||
                              isOpenDwolla
                            }
                            type="button"
                           // style={{ cursor: "pointer" }}
                            className="report-gallery btn btn-primary"
                            onClick={() =>
                              this.openOtpModal(
                                false,
                                "dwolla-document-upload",
                                docstatus
                              )
                            }
                          >
                            {/* {docstatus} */}
                            Add Documents
                          </button>
                          <span className="badge color-red">
                            Documentation Needed
                          </span>
                        </span>
                      )}
                      {
                        <>
                          {!dwollaCustomersDetail?.dwollaCustStatus &&
                            !dwollaCustomersDetail?.customerstatus &&
                            hasAddDigitalACH === true && (
                              <button
                                tabIndex={29}
                                disabled={
                                  !isSpecialPermissionUser || isOpenDwolla
                                }
                                type="button"
                               // style={{ cursor: "pointer" }}
                                className="report-gallery btn btn-primary"
                                onClick={() =>
                                  this.openOtpModal(
                                    false,
                                    "dwolla-business-vcr",
                                    "Setup"
                                  )
                                }
                              >
                                Setup
                              </button>
                            )}
                        </>
                      }
                      {hasAddDigitalACH === false &&
                        dwollaCustomersDetail == null && (
                          <>
                            <span data-tip={tooltipMessageAdd}>
                              <ReactTooltip
                                effect="solid"
                                event="mouseover mouseenter"
                                eventOff="mouseleave mouseout scroll mousewheel blur"
                                place="bottom"
                                multiline={true}
                              />
                              <button
                                tabIndex={30}
                                disabled={true}
                                type="button"
                                className="report-gallery btn btn-primary"
                              >
                                Setup
                              </button>
                            </span>
                          </>
                        )}
                    </div>
                  </FormGroup>
                  {/* <FormGroup className="d-flex flex-row align-items-center"> */}
                  {isOpenDwolla && ModalName !== "dwolla-beneficial-owners" && (
                    <DigitalACHCustomDwolla
                      documentType={this.state.documentType}
                      ModalName={this.state.ModalName}
                      custId={dwollaCustomersDetail?.dwollaCustId || ""}
                      hideModal={this.hideCustomDwollaModal}
                      hotelId={this.props?.hotelId}
                      statusCallBack={this.statusCallBack}
                    ></DigitalACHCustomDwolla>
                  )}

                  <Form.Group className="d-flex flex-row align-items-center">
                    <Form.Label className={`${!verfiedUser && "grey-text"}`}>
                      Certified User
                    </Form.Label>

                    {(dwollaCustomersDetail === null ||
                      dwollaCustomersDetail?.dwollaCustStatus ===
                        "unverified") && (
                      <div
                        className={`readonly-value ${
                          !verfiedUser && "grey-text"
                        }`}
                      >
                        Not Configured
                      </div>
                    )}

                    {dwollaCustomersDetail?.beneficialOwnerRequired === "No" &&
                      dwollaCustomersDetail.dwollaCustStatus !==
                        "unverified" && (
                        <div className="readonly-value grey-text">
                          Not Required
                        </div>
                      )}

                    {dwollaCustomersDetail?.beneficialOwnerRequired === "Yes" &&
                      dwollaCustomersDetail?.beneficialOwnerStatus ===
                        "certified" && (
                        <div className="readonly-value">Certified</div>
                      )}

                    {dwollaCustomersDetail?.dwollaCustStatus !==
                      "unverified" && (
                      <>
                        {dwollaCustomersDetail?.beneficialOwnerRequired ===
                          "Yes" &&
                          dwollaCustomersDetail?.beneficialOwnerStatus !==
                            "certified" && (
                            <div className="action-group d-flex flex-row align-items-center">
                              <span
                                data-tip={
                                  hasAddDigitalACH === false
                                    ? tooltipMessageAdd
                                    : ""
                                }
                              >
                                <ReactTooltip
                                  effect="solid"
                                  event="mouseover mouseenter"
                                  eventOff="mouseleave mouseout scroll mousewheel blur"
                                  place="bottom"
                                  multiline={true}
                                />
                                <button
                                  tabIndex={28}
                                  disabled={
                                    !isSpecialPermissionUser ||
                                    hasAddDigitalACH === false ||
                                    isOpenDwolla
                                  }
                                  type="button"
                                 // style={{ cursor: "pointer" }}
                                  className="report-gallery btn btn-primary"
                                  onClick={() =>
                                    this.openOtpModal(
                                      false,
                                      "dwolla-beneficial-owners",
                                      boverificationStatus
                                    )
                                  }
                                >
                                  Setup
                                </button>
                              </span>
                            </div>
                          )}
                      </>
                    )}
                  </Form.Group>
                  {/* <FormGroup className="d-flex flex-row align-items-center"> */}
                  {isOpenDwolla && ModalName === "dwolla-beneficial-owners" && (
                    <DigitalACHCustomDwolla
                      documentType={this.state.documentType}
                      ModalName={this.state.ModalName}
                      custId={dwollaCustomersDetail?.dwollaCustId || ""}
                      hideModal={this.hideCustomDwollaModal}
                      hotelId={this.props?.hotelId}
                      statusCallBack={this.statusCallBack}
                    ></DigitalACHCustomDwolla>
                  )}
                  {/* </FormGroup> */}

                  <Form.Group>
                    {showDwollSetUpModal && (
                      <DwollaSetupModal
                        //   globalSettings={this.state.globalSettings}
                        showDwollSetUpModal={showDwollSetUpModal}
                        hideDwollasetupModal={this.hideDwollasetupModal}
                        isCheckAgreebox={this.isCheckAgreebox}
                        hotelId={this.props?.hotelId}
                      />
                    )}
                  </Form.Group>
                </div>

                <div className="separator-line"></div>

                {this.state.isPlaidSetupButton && (
                  <>
                    <label id="lblSetupStatus" style={{ display: "none" }}>
                      Setup Status
                    </label>
                    <div className="form-section d-flex flex-column">
                      <div className="add-bank-account-section">
                        <Form.Group className="d-flex flex-row align-items-center">
                          <Form.Label
                            style={{ fontSize: "16px" }}
                            // className={`${!certifiedUser && "grey-text"}`}
                          >
                            Connected Banks
                          </Form.Label>
                        </Form.Group>
                        {this.state.fundingSources.length > 0 &&
                          this.state.fundingSources.map((item, index) => {
                            // let statusPending = false;
                            // const verified =
                            //   item.fundingSourceStatus.toLowerCase() ===
                            //   "verified";
                            // let badgeText = "Unconfirmed";
                            const verified = item.badgeText === "Confirmed";
                            const statusPending = item.badgeText === "Processing";

                            let showPlaidReconnectBtn = false;
                            if (verified) {
                              // badgeText = "Confirmed";
                              if (
                                item.isAccountId === "Yes" &&
                                item.isAccessToken === "Yes" &&
                                item.isPlaidEnabled === "0"
                              ) {
                                showPlaidReconnectBtn = true;
                              }
                            }
                            // if (
                            //   item.fundingSourceStatus.toLowerCase() ===
                            //   "inactive"
                            // ) {
                            //   badgeText = "Inactive";
                            // } else if (
                            //   item.fundingSourceStatus ===
                            //     "customer_funding_source_verified" ||
                            //   item.fundingSourceStatus.toLowerCase() ===
                            //     "processing"
                            // ) {
                            //   badgeText = "Processing";
                            //   statusPending = true;
                            // }
                            // let showConfirmModal = false;
                            // if (
                            //   item.fundingSourceStatus ===
                            //     "customer_funding_source_added" ||
                            //   item.fundingSourceStatus.toLowerCase() ===
                            //     "unverified"
                            // ) {
                            //   showConfirmModal = true;
                            // }
                            let accountName = item.bName;
                            if (item.bName.length > 6) {
                              accountName = item.bName.slice(
                                item.bName.length - 6
                              );
                            }
                            let encryptedBankName = item.encryptedBankName;
                            if (item.encryptedBankName.length > 6) {
                              encryptedBankName = item.encryptedBankName.slice(
                                item.encryptedBankName.length - 6
                              );
                            }
                            // item.badgeText = badgeText;

                            return (
                              <>
                                <Form.Group className="d-flex flex-row align-items-center">
                                  <Form.Label
                                  // className={`${!certifiedUser && "grey-text"}`}
                                  >
                                    Bank Account
                                  </Form.Label>

                                  <div className="readonly-value d-flex align-items-center">
                                    <div className={`mr-3 account-type`}>
                                      {item.ifbanckAccountName}
                                    </div>

                                    <div className={`mr-3 account-number`}>
                                      {/* {item.displayNo} */}
                                      {verified &&
                                        accountName + " " + encryptedBankName}
                                    </div>

                                    <span
                                      className={`confirmed-unconfirmed badge color-${
                                        verified
                                          ? "green"
                                          : statusPending
                                          ? "blue"
                                          : "orange"
                                      }`}
                                    >
                                      {item.badgeText}
                                    </span>

                                    {item.buttonText === "Remove Bank" &&
                                      !showPlaidReconnectBtn && (
                                        <Dropdown className="more-action">
                                          <Dropdown.Toggle
                                            className="btn-ghost ml-3"
                                            id="dropdown-more"
                                            disabled={
                                              !isSpecialPermissionUser ||
                                              !certifiedUser ||
                                              dwollaCustomersDetail?.dwollaCustStatus ===
                                                "unverified"
                                            }
                                           // style={{ cursor: "pointer" }}
                                          >
                                            Remove Bank
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            <div className="showReassignShiftList">
                                              {/* <OutsideClickHandler
                                             onOutsideClick={() =>this.outSideClickHandler()}
                                           > */}
                                              <ConfirmationModal
                                                confirmModalContent={
                                                  confirmModalContent
                                                }
                                                ishandleDeletePosition={this.confirmRemoveBank.bind(
                                                  this,
                                                  item
                                                )}
                                              />
                                              {/* </OutsideClickHandler> */}
                                            </div>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                        // <Button
                                        //   disabled={!isSpecialPermissionUser}
                                        //   onClick={() => this.removeBankACH(item)}
                                        //   className="btn-ghost ml-3"
                                        // >
                                        //   Remove Bank
                                        // </Button>
                                      )}

                                    {(showPlaidReconnectBtn ||
                                      item.buttonText === "Reconnect Bank") && (
                                      <Button
                                        onClick={() =>
                                          this.reconnectBank(
                                            item,
                                            showPlaidReconnectBtn
                                          )
                                        }
                                       // style={{ cursor: "pointer" }}
                                        className="reconnect-bank btn-ghost ml-3"
                                        disabled={
                                          !isSpecialPermissionUser ||
                                          !certifiedUser ||
                                          dwollaCustomersDetail?.dwollaCustStatus ===
                                            "unverified"
                                        }
                                      >
                                        Reconnect Bank
                                      </Button>
                                    )}
                                  </div>
                                </Form.Group>
                                {item.badgeText === "Unconfirmed" && (
                                  <ConfirmBankAccount
                                    {...this.state}
                                    {...this.props}
                                    buttonClickHandler={this.buttonClickHandler}
                                    item={item}
                                    verifiedOTP={this.verifiedOTP}
                                    hotelDetails={this.state.hotelDetails}
                                  />
                                )}
                              </>
                            );
                          })}
                        {/* {this.state.fundingSources.length > 0 && (
                    )} */}

                        {!this.state.showAddBankSetup &&
                          !this.state.isManualBankAccountAdded && (
                            <button
                              tabIndex={30}
                              disabled={
                                !isSpecialPermissionUser ||
                                addBankButtonDisable ||
                                !certifiedUser ||
                                dwollaCustomersDetail?.dwollaCustStatus ===
                                  "unverified"
                              }
                              type="button"
                             // style={{ cursor: "pointer" }}
                              className="add-bank-account-btn btn-outline-primary btn btn-primary"
                              onClick={() => {
                                // this.setState({ showAddBankSetup: true, mode: 1 });
                                this.openOtpModalForBankSetup(
                                  "Bank Validation"
                                );
                              }}
                            >
                              Add Bank Account
                            </button>
                          )}

                        {this.state.showAddBankSetup && (
                          <AddBankAccountSetup
                            handleCancelSetupAccount={
                              this.handleCancelSetupAccount
                            }
                            handleSubmitBankSetup={this.handleSubmitBankSetup}
                            {...this.props}
                            fundingSources={this.state.fundingSources}
                            plaidFunction={this.plaidFunction}
                            enableFundingSource={this.enableFundingSource}
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
            {this.state.isOtpNeed && (
              <OtpModal
                closePopup={this.closeOtpModal.bind(this)}
                ValidateOTP={this.ValidateOTP.bind(this)}
                ReSendOTP={this.ReSendOTP.bind(this)}
                purpose={this.state.Purpose}
              ></OtpModal>
            )}
          </div>
        )}
      </div>
    );
  }
}
