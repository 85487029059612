import * as React from "react";

class TableSearch extends React.Component<any, any> {
  private input:any;
  constructor(props: any) {

    super(props);
    this.input = React.createRef();
    
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.CleanSearch = this.CleanSearch.bind(this);
  }
 
  CleanSearch = (event:any) => {  
       
    this.props.onClear("");   
    this.props.onSearch("");   
    this.input.current.value ="";     
    
  };
  resetSearch = (event:any) => {   
    
    this.input.current.value ="";
    this.props.onSearch("");    

    
  };

  handleClick = (event:any) => {
    this.input.value ="";
    event.preventDefault();
  };
  handleChange = (event:any) => {
      this.props.onSearch(this.input.current.value);
      event.preventDefault();
    };

  componentDidMount()
  {
   // this.input.current.focus();
  }
 
  render() {
    
    return [
      
        <div>
        <input
        id="serachInput"
        key="serachInput"
          className="form-control"
          style={ { backgroundColor: 'white' } }
          ref={this.input}
          type="text"
          onChange={this.handleChange}
          placeholder={this.props.placeholder}
          autoComplete="off"
          data-lpignore="true"
          disabled={this.props.disabled}
        />
      </div>

    ];
  }
}

export default TableSearch;
