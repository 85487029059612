
import { IGetfacilitiesListRequest, IFacilities, IGetAssetsListRequest, IAssetDto, IFacilityTaskDeleteBulkRequest } from "../Contracts/IFacilities";
import { IFacilityProcedureListRequest, IFacilityProcedureWithTasksSaveRequest, IFacilityProcedures, IFacilityTasks } from "../Contracts/IProcedures";
import Instense from "./Axios-config";

export class FacilityProcedure {

  public static GetFacilityProcedure = async (searchText:string =""): Promise<IFacilityProcedures[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request:IFacilityProcedureListRequest = {
     searchText: searchText,
     tenantID : tenantID
    };
    const url = `/FacilityProcedure/GetFacilityProcedure`;
    return Instense.post(url,  request ).then((response) => {
      let result = response?.data?.result as IFacilityProcedures[];
      return result;
    });
  };
  

  public static GetFacilityTaskList = async (procedureId): Promise<IFacilityTasks[] | null> => {
    const url = `/FacilityProcedure/GetFacilityTaskList`;
    return Instense.get(url,  {params: {procedureId}}  ).then((response) => {
      let result = response?.data?.result as IFacilityTasks[];
      return result;
    });
  };
  
  
  public static SaveFacilityProcedureWithTask = async (request:IFacilityProcedureWithTasksSaveRequest): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID =tenantID;
    request.createdBy = storage === null ? 0 : (storage.user_UniqueID as any);
    const url = `/FacilityProcedure/SaveFacilityProcedureWithTask`;
    return Instense.post(url, request).then((response) => {
      let result = response?.data?.result as any;
      return result;
    });
  };

  public static DeleteFacilityProcedure = async (procedureId): Promise<any | null> => {
    const url = `/FacilityProcedure/DeleteFacilityProcedure`;
    return Instense.delete(url,{ params: {procedureId} }  ).then((response) => {
      let result = response?.data?.result as any;
      return result;
    });
  };
  
  public static DeleteProcedureTaskBulk = async (data:IFacilityTaskDeleteBulkRequest): Promise<any | null> => {
    const url = `/FacilityProcedure/DeleteProcedureTaskBulk`;
    return Instense.delete(url,{ data }  ).then((response) => {
      let result = response?.data?.result as any;
      return result;
    });
  };
  
  
  public static CheckProcudureDuplicacy = async (procedureId:number,newProcedureName): Promise<any | null> => {;
    const url = `/FacilityProcedure/CheckProcudureDuplicacy`;
    return Instense.get(url,  {params: {procedureId,newProcedureName}}  ).then((response) => {
      let result = response?.data?.result as any;
      return result;
    });
  };
  public static GetAddEditPermissions = async ()=> {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
   
    const userName = storage === null ? 0 : (storage.userName as any);
    const TenantID = tenantID
  
    const url = `/FacilityProcedure/GetAddEditPermissions`;
    return Instense.get(url,{params:{userName:userName,TenantID:TenantID}}).then((response) => {
      let result = response?.data?.result ;
      return result;
    });
  };

}
