import { LaborSchedule } from "../../../../../Common/Services/LaborSchedule";

export class Schedule {
  public static createDuplicateShift = async (
    schdeule: any,
    row: any,
    rowIndex: any,
    dayType: any,
    type: any,
    navigationCalenderDates: any,
    props: any
  ) => {
    // 
    let storage:any = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID);
    let requestItem = {} as any;

    requestItem.scheduleId = 0;
    requestItem.hotelID = props.hidValue;
    requestItem.tenantID = tenantID;
    requestItem.departmentID = Number(schdeule.deptId);
    requestItem.positionID = Number(schdeule.positionId);
    requestItem.date = schdeule.shiftDate;
    requestItem.inTime = schdeule.shiftInTime;
    requestItem.outTime = schdeule.shiftOutTime;
    requestItem.notes = "";
    requestItem.entryType = "Schedule";
    requestItem.inDate = schdeule.shiftDate;
    requestItem.outDate = schdeule.shiftDate;
    requestItem.isAssignedShift = "No";
    requestItem.isPublishShift = "No";
    requestItem.employeeName = "";
    requestItem.offRepeat = "No";
    requestItem.ptoType = -1;
    requestItem.weekStartDate = navigationCalenderDates[0];
    requestItem.weekEndDate = navigationCalenderDates[1];
    requestItem.editDate = schdeule.shiftDate;
    requestItem.editEmployee = "";
    requestItem.EarlyPunch = "";
    requestItem.PageSource = "Schedule";
    let requestArray: any = [];
    requestArray.push(requestItem);

    
    let result = await LaborSchedule.SaveLaborScheduleDetails(
      requestArray
    ).then(async (result: any | null) => {
         return result;
    });
    return result;
  };
}
