import React from "react";
import { Container, Dropdown, Button, Form, Tabs, Tab, Table, Modal, Spinner } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { DataImportRules } from "../../../Common/Services/DataImportRule";
import TableSearch from "../../../Common/Components/TableSearch";
import { ConfirmationModal } from "../../../Common/Components/ConfirmationModal";
import cellEditFactory from "react-bootstrap-table2-editor";
import { FiChevronDown } from "react-icons/fi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import "react-calendar/dist/Calendar.css";
import OutsideClickHandler from "react-outside-click-handler";
import { resolve, reject } from "q";
import _ from "lodash";
import "../ConditionalRuleSetting/conditionalRule.scss";
import { ConditionalDataRule } from "../../../Common/Components/ConditionalDataRule";
import { ReactPageLoader } from "../../../Common/Components/ReactPageLoader";
import TableAutocomplete from "../../../Common/Components/TableAutocomplete";
import { Register as registersevice } from "../../../Common/Services/Register";
import { ColumnMapingControl } from './columnMaping';
import { confirmAlert } from "react-confirm-alert";
import { Utils } from "../../../Common/Utilis";
export class DataImportRule extends React.Component<any, any> {
  private tableSearch: any;
  private containsValue: any;
  private ConditionalDataRuleModal: any;
  private ColumnMapingCtrl: any;
  constructor(props: any) {
    super(props);
    this.tableSearch = React.createRef();
    this.containsValue = React.createRef();
    this.ConditionalDataRuleModal = React.createRef();
    this.ColumnMapingCtrl = React.createRef()
    this.state = {
      actionType: "Add",
      loaderTrue: false,
      tableData: [],
      tableDataCopy: [],
      MappingRuleTypeList: [],
      defaultSelectOption: "Filter by Mapping Rule",
      tableRowDates: [],
      currentIndex: 0,
      mappingRuleField: [],
      COAList: [],
      defaultCOA: [],
      vendorList: [],
      defaultVendor: [],
      mappingRuleFieldMain: [],
      COAListMain: [],
      vendorListMain: [],
      isDisabled: false,
      oldData: {
        oldValue: "",
        OldType: "",
        UniqueNo: "",
      },
      dataRuleFrom: "",
      ruleIDFromImport: "0",
      totalRowCount: "0",
      isScrollVisiblePage: false,
      isScrollVisibleTab: false,
      tabkey: "dataImportRule",
      ruleFound: false,
      isBannerShow: false,
      mappingRuleArray: [],
      isAddNewRoleMain: false,
      newRuleNameMain: "",
      newRuleErrorMain: "",
      newRuleErrorNameMain: "",
      newRuleErrorSetMainMain: false,
      selectedType: [],
      isNewRequest: false,
    };
  }

  componentDidMount = () => {
    if (this.props.parentState?.dataRuleFrom !== "Import") {
      this.GetDataImportRule("0");
    } else if (this.props.parentState?.dataRuleFrom === "Import") {
      let selectedRule = { ...this.props.parentState.selectedRule };
      this.setState({
        ruleIDFromImport: selectedRule.ruleID,
        dataRuleFrom: this.props.parentState?.dataRuleFrom,
      });
    }
    this.setState({
      loaderTrue: true,
    });
    this.GetMappingRule();
    this.GetMappingRuleField();
    this.GetHotelCOAlist();
    this.GetVendorList();
  };

  GetMappingRule = () => {
    this.setState({
      loaderTrue: true,
    });
    let request: any = {};
    request.Type = "CashImportStatement";
    registersevice.GetMappingRule(request)
      .then(async (result: any) => {
        let data = result.result;
        data.map(item => (item["ruleName"] = item.ruleName.trim(), item["ruleNameNew"] = item.ruleName.toLowerCase().trim()))
        data = _.sortBy(data, "ruleNameNew");
        if (result.success) {
          this.setState({ mappingRuleArray: data }, () => {
            this.updateState(data.length);
            if (this.state.tabkey !== "dataImportRule")
              this.ColumnMapingCtrl?.current.updatechildList(data)
          })
        } else {
          Utils.toastError("Something went wrong.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        resolve();
      })
      .catch((error) => {
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  }

  changeNodeKey = (result: any, Type: any) => {
    if (Type === "1") {
      let resultNew = result.filter(
        (x) =>
          x.displayFieldName.toLowerCase() === "category" ||
          x.displayFieldName.toLowerCase() === "company" ||
          x.displayFieldName.toLowerCase() === "description"
      );
      this.setState({ mappingRuleFieldMain: resultNew });
      result = resultNew.map((item: any, id: any) => {
        return {
          id: id,
          label: item.displayFieldName,
          value: item.displayFieldName,
        };
      });
      this.setState({ mappingRuleField: result });
    } else if (Type === "2") {
      result = result.map((item: any, id: any) => {
        return {
          id: item.id,
          label: item.label,
          value: item.label,
        };
      });
      this.setState({ COAList: result });
    } else if (Type === "3") {
      result = result.map((item: any, id: any) => {
        return {
          id: item.vid,
          label: item.vendor,
          value: item.vid,
        };
      });
      this.setState({ vendorList: result, isBannerShow: true });
    }
  };

  GetMappingRuleField = () => {
    this.setState({
      loaderTrue: true,
    });
    let request: any = {};
    DataImportRules.GetMappingRuleField(request)
      .then(async (result: any) => {
        let data = result.result;
        if (result.success) {
          this.changeNodeKey(data, "1");
        } else {
          Utils.toastError(result?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        resolve();
      })
      .catch((error) => {
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
        this.setState({
          loaderTrue: false,
        });
      });
  };

  GetHotelCOAlist = () => {
    this.setState({
      loaderTrue: true,
    });
    let request: any = {};
    request.hotelID = this.state.hidValue;
    registersevice
      .GetHotelCOAlist(request)
      .then(async (result: any | null) => {
        if (result !== null) {
          this.setState({ COAListMain: result.transactionChartList }, () => {
            this.changeNodeKey(result.transactionChartList, "2");
          });
        } else {
          Utils.toastError("Something went wrong", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        resolve();
      })
      .catch((error) => {
        this.setState({
          loaderTrue: false,
        });
        reject();
      });
  };

  GetVendorList = () => {
    this.setState({
      loaderTrue: true,
    });
    let request: any = {};
    request.HID = this.state.hidValue;
    DataImportRules.GetVendorList(request)
      .then(async (result: any) => {
        if (result.success) {
          let vendorData = result.result;
          vendorData.map(items => (items["label"] = items.vendor, items["id"] = items.vid));
          this.setState({ vendorListMain: vendorData, loaderTrue: false, }, () => {
            this.changeNodeKey(result.result, "3");
          });
        } else {
          Utils.toastError(result?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        resolve();
      })
      .catch((error) => {
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
        this.setState({
          loaderTrue: false,
        });
      });
  };

  GetDataImportRule = (RuleID: string) => {
    DataImportRules.GetDataImportRule(RuleID)
      .then(async (result: any) => {
        if (result.result.length > 0) {
          this.setState({ MappingRuleTypeList: [] }, () => {
            let allType: any = { id: -1, name: "All" };
            this.state.MappingRuleTypeList.push(allType);
            let newdata: any = _.uniqBy(result.result, "ruleName"); // Filter data for bind the Rule type dropdown
            newdata = newdata.map((item: any, id: any) => {
              let data = { id: id, name: item.ruleName };
              this.state.MappingRuleTypeList.push(data);
            });
          });
          this.setState(
            {
              loaderTrue: false,
              tableData: result.result,
              tableDataCopy: result.result,
              totalRowCount: result.result.length,
            },
            () => {
              if (this.props.parentState?.dataRuleFrom === "Import")
                this.props.updateCount(result.result.length);
            }
          );
          this.updateClass();
        }

        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error: any) => {
        this.setState({ loaderTrue: false });
        Utils.toastError(`Server Error: ${error}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  };

  updateClass = () => {
    if (this.props.parentState?.dataRuleFrom === "Import") {
      // height for data rule tab
      if (this.state.tableData.length > 6) {
        this.setState({
          isScrollVisiblePage: false,
          isScrollVisibleTab: true,
        });
      } else {
        this.setState({
          isScrollVisiblePage: false,
          isScrollVisibleTab: false,
        });
      }
    } else {
      if (this.state.tableData.length > 12) {
        this.setState({
          isScrollVisiblePage: true,
          isScrollVisibleTab: false,
        });
      } else {
        this.setState({
          isScrollVisiblePage: false,
          isScrollVisibleTab: false,
        });
      }
    }
  };

  handleFilter = () => {
    this.handleSelectedItem({ id: -1, name: "All" })
  }

  handleSelectedItem = (selected) => {
    let acntDataFilter: any[] = [];
    this.setState({
      selectedAcType: selected.name,
      defaultSelectOption: selected.name,
      //selectedType:selected
    });


    if (selected.id >= 0) {
      acntDataFilter = _.cloneDeep(this.state.tableDataCopy);

      acntDataFilter = acntDataFilter.filter(
        (item) => item.ruleName === selected.name
      );
      this.setState({ tableData: acntDataFilter }, () => {
        if (this.props.parentState?.dataRuleFrom === "Import") {
          if (this.state.tableData.length > 8)
            this.setState({
              isScrollVisiblePage: true,
              isScrollVisibleTab: false,
            });
          else
            this.setState({
              isScrollVisiblePage: false,
              isScrollVisibleTab: false,
            });
        } else {
          if (this.state.tableData.length > 12)
            this.setState({
              isScrollVisiblePage: true,
              isScrollVisibleTab: false,
            });
          else
            this.setState({
              isScrollVisiblePage: false,
              isScrollVisibleTab: false,
            });
        }
      });
    }
    if (selected.id < 0) {
      this.setState({ tableData: this.state.tableDataCopy }, () => {
        this.updateClass();
      });
    }
    let divHeight: any = $(".DataRuleTable").height();
  };

  ishandleDeletePosition = (row: any, Action: any, mode: any) => {
    if (mode) {
      this.UpdateDeleteRule(row, Action);
    }
  };

  UpdateDeleteRule = (row: any, Action: any) => {
    let request = {} as any;
    request.UniqueNo = row.uniqueNo;
    request.RuleID = Number(row.ruleID);
    request.ActionType = Action;
    if (row.if === "Description")
      request.contains =
        row.contains?.trim().length > 200
          ? row.contains.substring(0, 200)?.trim()
          : row.contains?.trim();
    else
      request.contains =
        row.contains?.trim().length > 100
          ? row.contains.substring(0, 100)?.trim()
          : row.contains?.trim();

    request.If = row.if;
    request.Vendor = row?.vendor === undefined || row?.vendor === null ? "" : row.vendor;
    request.COA = row.coa === undefined || row.coa === null ? "" : row.coa?.split(" ")[0];
    request.UpdatedField =
      row?.updatedField === undefined ? "" : row?.updatedField;

    DataImportRules.DeleteUpdateDataImportRule(request)
      .then(async (result: any) => {
        let copiedState = _.cloneDeep(this.state);
        let data = copiedState.tableData;
        let dataCopy = copiedState.tableDataCopy;
        if (result.result.message.toLowerCase() === "success") {
          if (Action === "Delete") {
            var find = [
              {
                uniqueNo: row.uniqueNo,
              },
            ];

            _.remove(data, function (obj) {
              return _.some(find, {
                uniqueNo: obj.uniqueNo,
              });
            });

            _.remove(dataCopy, function (obj) {
              return _.some(find, {
                uniqueNo: obj.uniqueNo,
              });
            });

            this.setState(
              {
                tableData: data,
                tableDataCopy: dataCopy,
                MappingRuleTypeList: [],
              },
              () => {
                this.updateClass();
              }
            );

            let allType: any = { id: -1, name: "All" };
            this.state.MappingRuleTypeList.push(allType);
            let newdata: any = _.uniqBy(dataCopy, "ruleName"); // Filter data for bind the Rule type dropdown
            newdata = newdata.map((item: any, id: any) => {
              let dataFilter = { id: id, name: item.ruleName };
              this.state.MappingRuleTypeList.push(dataFilter);
            });
            this.setState({
              MappingRuleTypeList: this.state.MappingRuleTypeList,
            });
            if (this.props.parentState?.dataRuleFrom === "Import") {
              let totalRowCount = Number(this.state.totalRowCount) - 1;
              this.props.updateCount(totalRowCount);
              this.setState({ totalRowCount: totalRowCount });
            }
          } else {
            let newDataCopy = copiedState.tableDataCopy;
            newDataCopy.find((o) => o.uniqueNo === request.UniqueNo).contains = request.contains;
            newDataCopy.find((o) => o.uniqueNo === request.UniqueNo).coa = row.coa;
            newDataCopy.find((o) => o.uniqueNo === request.UniqueNo).vendor = request.Vendor;
            newDataCopy.find((o) => o.uniqueNo === request.UniqueNo).if = request.If;
            this.setState({
              tableData: data,
              tableDataCopy: newDataCopy,
            });
          }

          let msg = "";
          msg = Action === "Delete" ? "deleted" : "updated";

          toast.success("Mapping Rule " + msg + " successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          if (this.props.parentState?.dataRuleFrom === "Import")
            this.showReprocess();
          this.setState({ isDisabled: false });
        } else if (result.result?.message.toLowerCase() === "exists") {
          let oldData = { ...this.state.oldData };
          if (oldData.OldType === "contains")
            data.find((o) => o.uniqueNo === oldData.UniqueNo).contains =
              oldData.oldValue;
          if (oldData.OldType === "if")
            data.find((o) => o.uniqueNo === oldData.UniqueNo).if =
              oldData.oldValue;

          this.setState({
            isDisabled: false,
            tableData: data,
            //tableDataCopy: data,
            oldData: [],
          });
          Utils.toastError("The mapping with this data rule already exists.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        resolve();
      })
      .catch((error: any) => {
        this.setState({ isDisabled: false });
        Utils.toastError(`Server Error: ${error}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  };

  conditionalDataRuleModalShow = () => {
    this.ConditionalDataRuleModal.current.showConditionalModal();
  };

  onOutsideNewContains = (e: any, row: any) => {
    let newtableData: any = [];
    newtableData = Object.assign([], this.state.tableData);
    let tableIndex = newtableData.length as number;
    newtableData[tableIndex - 1].contains = this.containsValue.current.value;
    this.setState({ tableData: newtableData });
  };

  isContainsValue = (cell, row, rowIndex) => {
    if (row.contains === "") {
      return (
        <OutsideClickHandler
          onOutsideClick={(e: any) => {
            this.onOutsideNewContains(e, row);
          }}
        >
          <Form.Group>
            <Form.Control
              ref={this.containsValue}
              maxLength={100}
              type="text"
              key="121"
            />
          </Form.Group>
        </OutsideClickHandler>
      );
    } else {
      return (
        <div>
          <EllipsisWithTooltip placement="bottom">
            {row.contains}
          </EllipsisWithTooltip>
        </div>
      );
    }
  };

  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      this.setState({ currentIndex: rowIndex });
    },
    onBlur: (e: any, row: any, rowIndex: any) => {
      row = {};
    },
  };

  cellEdit = cellEditFactory({
    mode: "click",
    blurToSave: true,
    afterSaveCell: (oldValue: any, newValue: any, row: any, column: any) => {
      let newVal = "";
      if (row.if === "Description")
        newVal = newValue?.trim().length > 200
          ? newValue.substring(0, 200)?.trim()
          : newValue?.trim();
      else
        newVal = newValue?.trim().length > 100
          ? newValue.substring(0, 100)?.trim()
          : newValue?.trim();
      let oldData = { ...this.state.oldData };
      oldData.OldType = column.dataField;
      oldData.oldValue = oldValue;
      oldData.UniqueNo = row.uniqueNo;
      this.setState({ oldData });

      row.coa = column.dataField === "coa" ? newVal : row.coa;
      row.contains =
        column.dataField === "contains" ? newVal.trim() : row.contains.trim();
      row.if = column.dataField === "if" ? newVal : row.if;
      row.vendor = column.dataField === "vendor" ? newVal : row.vendor;
      row.updatedField = column.dataField;

      if (oldValue !== newVal) {
        if (row.contains.trim().length === 0) {
          Utils.toastError("Please enter the Contains Value.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          this.setState({ isDisabled: true }, () => {
            this.UpdateDeleteRule(row, "Update");
          });
        }
      }
    },
  });

  isIFBlank = (cell, row, rowIndex) => {
    if (row.if === "") {
      return <div style={{ color: "gray" }}>IF</div>;
    } else if (row.if === "") {
      return (
        <div className="required-cell-text">
          IF <div>Required</div>
        </div>
      );
    } else {
      return (
        <div>
          <EllipsisWithTooltip placement="bottom">{row.if}</EllipsisWithTooltip>
        </div>
      );
    }
  };

  isVendorBlank = (cell, row, rowIndex) => {
    if (row.vendor === "") {
      return <div style={{ color: "gray" }}></div>;
    } else if (row.vendor === "") {
      return (
        <div className="required-cell-text">
          Vendor <div>Required</div>
        </div>
      );
    } else {
      return (
        <div>
          <EllipsisWithTooltip placement="bottom">
            {row.vendor}
          </EllipsisWithTooltip>
        </div>
      );
    }
  };

  isCOABlank = (cell, row, rowIndex) => {
    if (row.coa === "") {
      return <div style={{ color: "gray" }}>COA</div>;
    } else if (row.coa === "") {
      return (
        <div className="required-cell-text">
          COA <div>Required</div>
        </div>
      );
    } else {
      return (
        <div>
          <EllipsisWithTooltip placement="bottom">
            {row.coa}
          </EllipsisWithTooltip>
        </div>
      );
    }
  };

  showReprocess = () => {
    this.props.reProcessRulesBtnShow();
  };

  handleTabSelect = (action, eventKey: any) => {
    if (this.state.isNewRequest && action !== "discard") {
      this.isValidateModelPoupTab();
    } else {
      this.setState({ tabkey: eventKey });
      if (eventKey === "dataImportRule") {
        this.ColumnMapingCtrl.current.resetColumnMapping();
        this.updateNewReqState(false);
      } else {
        this.ColumnMapingCtrl.current.GetMappingRule();
      }
    }
  };

  isValidateModelPoupTab() {
    confirmAlert({
      title: "Unsaved confirmation",
      message:
        "You have unsaved changes on this page. Do you want to leave this page and discard your changes?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.handleTabSelect('discard', "dataImportRule"),
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }

  updateNewReqState = (state) => {
    this.setState({ isNewRequest: state });
  }

  updateState = (listLength) => {
    if (listLength === 0) {
      this.setState({ ruleFound: false });
    } else {
      this.setState({ ruleFound: true });
    }
  }

  showAddNewRuleModal = () => {
    this.setState({ isAddNewRoleMain: true })
  }

  addNewRoleClose = () => {
    this.setState({ isAddNewRoleMain: false, newRuleNameMain: "", newRuleErrorMain: false, newRuleErrorNameMain: "" })
  }

  handleNewRuleName = (event) => {
    let { value } = event.target;
    this.setState({ newRuleNameMain: value })
  }

  NewRuleNameValidation = () => {
    let { newRuleNameMain, mappingRuleArray, newRuleErrorMain, newRuleErrorNameMain } = this.state;
    if (newRuleNameMain === "") {
      newRuleErrorMain = true;
      newRuleErrorNameMain = "Please enter rule name.";
      Utils.toastError(newRuleErrorNameMain, { position: toast.POSITION.BOTTOM_RIGHT });
      this.setState({ newRuleErrorMain, newRuleErrorNameMain });
      return newRuleErrorMain;
    } else if (newRuleNameMain.length > 50) {
      newRuleErrorMain = true;
      newRuleErrorNameMain = "Maximum 50 characters allowed.";
      Utils.toastError(newRuleErrorNameMain, { position: toast.POSITION.BOTTOM_RIGHT });
      this.setState({ newRuleErrorMain, newRuleErrorNameMain });
      return newRuleErrorMain;
    } else {
      let hasVal = mappingRuleArray.filter(item => item?.ruleName.toString().toLocaleLowerCase() === newRuleNameMain.toLocaleLowerCase())
      if (hasVal.length > 0) {
        newRuleErrorNameMain = "Rule name already exists";
        newRuleErrorMain = true;
        Utils.toastError(newRuleErrorNameMain, { position: toast.POSITION.BOTTOM_RIGHT });
        this.setState({ newRuleErrorMain, newRuleErrorNameMain });
        return newRuleErrorMain;
      } else {
        newRuleErrorMain = false;
        this.setState({ newRuleErrorMain });
        return newRuleErrorMain;
      }
    }
  }

  addNewRoleName = () => {
    let { newRuleNameMain, mappingRuleArray } = this.state;
    if (!this.NewRuleNameValidation()) {
      this.setState({ newRuleErrorSetMain: true })
      let request: any = {};
      request.RuleName = newRuleNameMain;
      request.Type = "CashImportStatement";
      let newRule = {};
      registersevice.SaveCreditCardRuleName(request)
        .then(async (result: any) => {
          if (result.success) {
            toast.success("New rule added successfully", { position: toast.POSITION.BOTTOM_RIGHT });
            this.setState({ selectedRule: newRule, mappingRuleArray }, () => {
              setTimeout(() => {
                this.addNewRoleClose();
                this.setState({ newRuleErrorSetMain: false }, () => {
                  this.setState({
                    loaderTrue: false,
                  }, () => {
                    this.GetMappingRule();
                    this.GetMappingRuleField();
                    this.GetHotelCOAlist();
                    this.GetVendorList();
                  });
                });
              }, 1000)
            })
          } else {
            this.setState({ newRuleErrorSetMain: false });
            Utils.toastError(result?.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          resolve();
        })
        .catch((error) => {
          this.setState({ newRuleErrorSetMain: false })
          Utils.toastError(error?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          reject();
        });
    }
  }

  render() {
    const columns = [
      {
        dataField: "if",
        text: "If",
        editable: true,
        formatter: this.isIFBlank,
        editorRenderer: (
          editorProps,
          value,
          row,
          column,
          rowIndex,
          columnIndex
        ) => (
          <div className="auto-complete">
            <TableAutocomplete
              key={"if-1" + rowIndex}
              {...editorProps}
              value={value}
              items={this.state.mappingRuleField}
              inputProps={{ placeholder: "IF" }}
            />
          </div>
        ),
      },
      {
        dataField: "contains",
        text: "Contains",
        editable: true,
        formatter: this.isContainsValue,
      },
      {
        dataField: "",
        text: "Then",
        editable: false,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 16 16"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
              ></path>
            </svg>
          );
        },
      },
      {
        dataField: "vendor",
        text: "Vendor",
        editable: true,
        formatter: this.isVendorBlank,
        editorRenderer: (
          editorProps,
          value,
          row,
          column,
          rowIndex,
          columnIndex
        ) => (
          <div className="auto-complete">
            <TableAutocomplete
              key={"Vendor-1" + rowIndex}
              {...editorProps}
              value={value}
              items={this.state.vendorList}
              inputProps={{ placeholder: "Vendor" }}
            />
          </div>
        ),
      },
      {
        dataField: "coa",
        text: "COA",
        editable: true,
        formatter: this.isCOABlank,
        editorRenderer: (
          editorProps,
          value,
          row,
          column,
          rowIndex,
          columnIndex
        ) => (
          <div className="auto-complete">
            <TableAutocomplete
              key={"coa-1" + rowIndex}
              {...editorProps}
              value={value}
              items={this.state.COAList}
              inputProps={{ placeholder: "COA" }}
            />
          </div>
        ),
      },
      {
        dataField: "ruleName",
        text: "Mapping Rule",
        editable: false,
      },
      {
        dataField: "ruleID",
        text: "",
        hidden: true,
      },
      {
        dataField: "uniqueno",
        text: "",
        hidden: true,
      },
      {
        dataField: "ation",
        text: "",
        editable: false,
        searchable: false,
        formatter: (cell: any, row: any, rowIndex: any) => {
          const confirmModalContent = [
            {
              title: "Delete Mapping Rule",
              desc: "Are you sure you want to delete this mapping rule?",
              cancleAction: "Cancel",
              ActionTrue: "Delete",
            },
          ];
          return (
            <Dropdown className="DataRuleImportTab more-action" alignRight>
              <Dropdown.Toggle
                className="btn-outline-primary btn btn-primary more fa-trash DataRuleTabList"
                id="dropdown-more"
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M3.75226 15C3.75226 15.8273 4.42501 16.5 5.25226 16.5H12.7523C13.5795 16.5 14.2523 15.8273 14.2523 15V6H15.7523V4.5H13.5023H12.7523V3C12.7523 2.17275 12.0795 1.5 11.2523 1.5H6.75226C5.92501 1.5 5.25226 2.17275 5.25226 3V4.5H4.50226H2.25226V6H3.75226V15ZM6.75226 3H11.2523V4.5H6.75226V3ZM6.00226 6H12.0023H12.7523L12.753 15H5.25226V6H6.00226Z" />
                  <path d="M6.75226 7.5H8.25226V13.5H6.75226V7.5ZM9.75226 7.5H11.2523V13.5H9.75226V7.5Z" />
                </svg>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <ConfirmationModal
                  confirmModalContent={confirmModalContent}
                  ishandleDeletePosition={this.ishandleDeletePosition.bind(
                    this,
                    row,
                    "Delete"
                  )}
                />
              </Dropdown.Menu>
            </Dropdown>
          );
        },
      },
    ];
    return (
      <>
        <ToastContainer enableMultiContainer autoClose={3000} />
        <div className={`Data-Imoprt-Rule ${this.state.isDisabled ? "disabled-area" : ""}`}>
          <Container fluid
            className={`body-sec ${this.props.parentState?.dataRuleFrom === "Import d-flex" ? "ImportProcess" : ""}`}>
            {this.props.parentState?.dataRuleFrom !== "Import" && (
              <div className="page-heading d-flex">
                <div className="mr-auto">{this.state.tabkey === "dataImportRule" ? "Data Import Rules" : "Column Mapping"}</div>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.showAddNewRuleModal()}
                >
                  Add New Rule
                </button>
              </div>
            )}{this.state.ruleFound && (
              <Tabs activeKey={this.state.tabkey} id="dataMapingrule" onSelect={(e) => { this.handleTabSelect('', e) }}>
                <Tab eventKey="dataImportRule"
                  title={this.props.parentState?.dataRuleFrom !== "Import" ? "Data Mapping Rule" : ""}
                  disabled={this.props.parentState?.dataRuleFrom === "Import"}>
                  {this.state.loaderTrue ? (
                    <ReactPageLoader useas={"forecastMain"} />
                  ) : (
                    <>
                      <div className="main-page">
                        <ToolkitProvider
                          keyField="uniqueNo"
                          data={this.state.tableData}
                          columns={columns}
                          search
                          rowEvents={this.rowEvents}
                        >
                          {(props: {
                            searchProps: JSX.IntrinsicAttributes;
                            baseProps: JSX.IntrinsicAttributes;
                          }) => (
                            <div>
                              <div
                                className={
                                  this.props.parentState?.dataRuleFrom !== "Import"
                                    ? "navigation-menus d-flex"
                                    : "navigation-menus-Import d-flex"
                                }
                              >
                                <div
                                  className={
                                    this.props.parentState?.dataRuleFrom === "Import"
                                      ? "SearchDiv"
                                      : ""
                                  }
                                >
                                  <TableSearch
                                    id="tableSearch"
                                    key="tableSearch"
                                    ref={this.tableSearch}
                                    {...props.searchProps}
                                    placeholder={"Search..."}
                                  />
                                </div>
                                {this.props.parentState?.dataRuleFrom !==
                                  "Import" && (
                                    <div className="navigationSort">
                                      <Dropdown className="more-action bg-blue">
                                        <Dropdown.Toggle
                                          className=""
                                          id="dropdown-more"
                                        >
                                          <div className="d-flex align-items-center">
                                            {this.state.defaultSelectOption}
                                            <div className="drop-arrow d-flex">
                                              <FiChevronDown />
                                            </div>
                                          </div>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <Dropdown.Header>
                                            Mapping Rule
                                          </Dropdown.Header>
                                          <div className="scrollableArea">
                                            {this.state.MappingRuleTypeList.map(
                                              (item: any, idx: any) => (
                                                <Dropdown.Item
                                                  key={idx}
                                                  className={
                                                    this.state.selectedAcType ===
                                                      item.name
                                                      ? "active"
                                                      : ""
                                                  }
                                                  eventKey={item}
                                                  onClick={() =>
                                                    this.handleSelectedItem(item)
                                                  }
                                                >
                                                  <div className="d-flex">
                                                    <div className="mr-auto">
                                                      {" "}
                                                      {item.name}
                                                    </div>
                                                    <span className="itemcheck">
                                                      <svg
                                                        width="20"
                                                        height="20"
                                                        viewBox="0 0 20 20"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                        <path
                                                          d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
                                                          fill="#2CAF92"
                                                        />
                                                      </svg>
                                                    </span>
                                                  </div>
                                                </Dropdown.Item>
                                              )
                                            )}
                                          </div>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  )}

                                <div className="action-group pos-rel d-flex ml-auto">
                                  {this.props.parentState?.dataRuleFrom !== "Import" && (
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      onClick={() => this.conditionalDataRuleModalShow()}
                                    >
                                      Add Data Rule
                                    </button>
                                  )}
                                  <div
                                    className={
                                      this.props.parentState?.dataRuleFrom === "Import"
                                        ? "ConditionalModal DataRuleTab"
                                        : "ConditionalModal"
                                    }
                                  >
                                    <ConditionalDataRule
                                      ref={this.ConditionalDataRuleModal}
                                      parentState={this.state}
                                      GetDataImportRule={() =>
                                        this.GetDataImportRule(this.state.ruleIDFromImport)
                                      }
                                      showReprocess={() => {
                                        this.showReprocess();
                                      }}
                                      updateState={this.updateState}
                                      handleFilter={this.handleFilter}
                                    />
                                  </div>
                                  {/* {this.props.parentState?.dataRuleFrom !== "Import" && (
                                  <Dropdown className="more-action" alignRight>
                                    <Dropdown.Toggle
                                      className="btn-outline-primary btn btn-primary more"
                                      id="dropdown-more"
                                    >
                                      <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                      </svg>
                                    </Dropdown.Toggle>
                                  </Dropdown>
                                )} */}
                                </div>



                              </div>

                              <div
                                className={`scrolling-height ${this.props.parentState?.dataRuleFrom === "Import"
                                  ? "mt-ImportPage"
                                  : ""
                                  }`}
                              >
                                <div
                                  className={
                                    this.state.tableData.length > 10
                                      ? this.props.parentState?.dataRuleFrom ===
                                        "Import"
                                        ? "ImportRule-table scroll ImportScroll"
                                        : "ImportRule-table scroll"
                                      : this.props.parentState?.dataRuleFrom ===
                                        "Import"
                                        ? "ImportRule-table ImportScroll"
                                        : "ImportRule-table"
                                  }
                                >
                                  <div
                                    className={`forecast-table DataRuleTable ${this.state.isScrollVisiblePage
                                      ? "scrollVisibleSA"
                                      : this.state.isScrollVisibleTab
                                        ? "scrollVisibleTab"
                                        : ""
                                      }`}
                                  >
                                    <BootstrapTable
                                      {...props.baseProps}
                                      keyField="uniqueNo"
                                      cellEdit={this.cellEdit}
                                      hover
                                      condensed
                                      rowEvents={this.rowEvents}
                                    />
                                  </div>
                                </div>
                                <Button
                                  className="add-rule-btn btn-ghost"
                                  onClick={() => this.conditionalDataRuleModalShow()}
                                >
                                  {/* {this.props.parentState?.dataRuleFrom === "Import"
                                  ? "Add Conditional Data Rule"
                                  : "Add Data Rule"} */}
                                  Add Data Rule
                                </Button>
                              </div>
                            </div>
                          )}
                        </ToolkitProvider>
                      </div>
                    </>
                  )}
                </Tab>
                <Tab eventKey="columnMapping" title={this.props.parentState?.dataRuleFrom !== "Import" ? "Column Mapping" : ""}
                  disabled={this.props.parentState?.dataRuleFrom === "Import"}>
                  <ColumnMapingControl ref={this.ColumnMapingCtrl}
                    updateState={this.updateState}
                    mappingRuleArray={this.state.mappingRuleArray}
                    updateNewReqState={this.updateNewReqState}
                  /></Tab>
              </Tabs>
            )}
            {!this.state.ruleFound && !this.state.loaderTrue && this.state.isBannerShow && (
              <div className="notification-banners orange-bg">
                <div className="mr-auto">
                  <span className="bold">
                    No rule found.</span>
                </div>
              </div>
            )}
          </Container>
        </div>
        <Modal
          show={this.state.isAddNewRoleMain}
          onHide={this.addNewRoleClose}
          backdrop="static"
          keyboard={false}
          centered
          className="ccsImportModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Rule Name</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Label>Enter rule name</Form.Label>
            <Form.Control
              className={this.state.newRuleErrorMain ? "border-danger" : ""}
              value={this.state.newRuleNameMain}
              type="text"
              onChange={this.handleNewRuleName}
              //onBlur={this.NewRuleNameValidation}
              placeholder="Enter rule name" />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary"
              disabled={this.state.newRuleErrorSetMain}
              onClick={this.addNewRoleName}
            >
              Add
            </Button>
            <Button variant="secondary" className="btnCancel"
              onClick={this.addNewRoleClose}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
