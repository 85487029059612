import React from "react";
import { Dropdown, Row, Col, Form, Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";
import "../../Modules/Accounting/ConditionalRuleSetting/conditionalRule.scss";
import { DataImportRules } from "../../Common/Services/DataImportRule";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import "react-toastify/dist/ReactToastify.css";
import OutsideClickHandler from "react-outside-click-handler";
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import { Utils } from "../Utilis";

export class ConditionalDataRule extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      mappingRuleSelected: "Select",
      ruleList: [],
      mappingRuleField: [],
      mappingRuleFieldSelected: "",
      COAList: [],
      cOASelected: "",
      defaultCOA: [],
      vendorList: [],
      defaultVendor: [],
      vendorSelected: "",
      containsValue: "",
      formPostData: {
        RuleID: "",
        FieldName: "",
        ContainValue: "",
        COA: "",
        VID: 0,
        VendorName: "",
      },
      formOldData: {
        FieldName: "",
        ContainValue: "",
        COA: "",
        VID: "",
        VendorName: "",
      },
      isProcessing: false,
      conditionalDataRuleModal: false,
      uniqueNo: "",
      isLength: 100,
    };
  }
  componentDidMount() {
    this.GetMappingRule();
    this.setState({
      mappingRuleField: this.props.parentState.mappingRuleFieldMain,
      COAList: this.props.parentState.COAListMain,
      defaultCOA: this.props.parentState.defaultCOA,
      vendorList: this.props.parentState.vendorListMain,
    });
  }

  updateListFromParent = () => {
    if (
      this.props.parentState.dataRuleFrom === "Import" &&
      this.props.parentState.actionType !== "Edit"
    ) {
      let formPostData = { ...this.state.formPostData };
      formPostData.RuleID = this.props.parentState.ruleIDFromImport;
      this.setState({ formPostData: formPostData });
    }
    this.setState({
      mappingRuleField: this.props.parentState.mappingRuleFieldMain,
      COAList: this.props.parentState.COAListMain,
      vendorList: this.props.parentState.vendorListMain,
    });
  };

  GetMappingRule = () => {
    let request: any = {};
    request.Type = "CashImportStatement";
    DataImportRules.GetMappingRule(request)
      .then(async (result: any) => {
        let data = result.result;
        if (result.success) {
          this.setState({ ruleList: data });
        } else {
          Utils.toastError("Something went wrong.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        resolve();
      })
      .catch((error: any) => {
        Utils.toastError(`Server Error: ${error}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  };

  GetMappingRuleField = () => {
    let request: any = {};
    DataImportRules.GetMappingRuleField(request)
      .then(async (result: any) => {
        let data = result.result;
        if (result.success) {
          this.setState({ mappingRuleField: data });
        } else {
          Utils.toastError(result?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        resolve();
      })
      .catch((error) => {
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  };

  GetCOAList = () => {
    let request: any = {};
    request.VID = 0;
    DataImportRules.GetCOAList(request)
      .then(async (result: any) => {
        let data = result.result;
        if (result.success) {
          this.setState({ COAList: data });
        } else {
          Utils.toastError(result?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        resolve();
      })
      .catch((error) => {
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  };

  GetVendorList = () => {
    let request: any = {};
    request.HID = 0;
    DataImportRules.GetVendorList(request)
      .then(async (result: any) => {
        let data = result.result;
        if (result.success) {
          this.setState({ vendorList: data });
        } else {
          Utils.toastError(result?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        resolve();
      })
      .catch((error) => {
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  };

  handleClose = () => {
    this.hideConditionalModal();
  };

  hideConditionalModal = () => {
    this.setState({ conditionalDataRuleModal: false, isLength: 100 }, () => {
      this.clearState();
    });
  };

  clearState = () => {
    let formData = { ...this.state.formPostData };

    formData.RuleID = "";
    formData.FieldName = "";
    formData.ContainValue = "";
    formData.COA = "";
    formData.VID = 0;
    formData.VendorName = "";

    this.setState({
      mappingRuleSelected: "Select",
      mappingRuleFieldSelected: "",
      cOASelected: "",
      vendorSelected: "",
      containsValue: "",
      formPostData: formData
    });
  };

  showConditionalModal = () => {
    this.setState(
      { conditionalDataRuleModal: true, defaultCOA: [], defaultVendor: [] },
      () => {
        this.updateListFromParent();
      }
    );
  };

  updateParentRowData = (
    subVendor,
    coa,
    ifColumnName,
    ifColumnVal,
    ruleID,
    uniqueNo,
    defaultVendor,
    defaultCOA
  ) => {
    if (this.props.parentState.actionType === "Edit") {
      let PostData = { ...this.state.formPostData };
      PostData.RuleID = ruleID;
      PostData.FieldName = ifColumnName;
      PostData.ContainValue = ifColumnVal;
      PostData.COA = coa.split(" ")[0];
      PostData.VendorName = subVendor;

      let isLength: number;
      if (ifColumnName?.toLowerCase() === "description")
        isLength = 200;
      else
        isLength = 100;

      this.setState({
        mappingRuleFieldSelected: ifColumnName,
        cOASelected: coa,
        vendorSelected: subVendor,
        containsValue: ifColumnVal,
        formPostData: PostData,
        //formOldData: OldData,
        uniqueNo: uniqueNo,
        defaultVendor: defaultVendor,
        defaultCOA: defaultCOA,
        isLength
      });
    }
  };

  render() {
    const renderItemChildren = (option, props, index) => {
      return (
        <>
          <div key={option.id}>
            <div className="vendor-name">
              <EllipsisWithTooltip placement="bottom">
                {option.label}
              </EllipsisWithTooltip>
            </div>
          </div>
        </>
      );
    };
    return (
      <OutsideClickHandler onOutsideClick={() => this.hideConditionalModal()}>
        {this.state.conditionalDataRuleModal && (
          <div className="ConditionalDropdown">
            <div className="headerDiv">
              <div className="publishHeading mr-auto">
                {this.props.parentState.actionType} Conditional Data Rule
              </div>
              <button
                type="button"
                className="close"
                onClick={(e) => this.handleClose()}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="publishBody">
              {this.props.parentState.actionType === "Add" &&
                this.props.parentState.dataRuleFrom === "" && (
                  <div className="MappingRule">
                    <Form.Group controlId="select-MappingRule">
                      <Form.Label>Mapping Rule</Form.Label>
                      <Dropdown
                        className={
                          this.state.mappingRuleSelected === "Select"
                            ? "import-from-dropdown select" + ""
                            : "import-from-dropdown"
                        }
                      >
                        <Dropdown.Toggle id="mappingRuleSelected">
                          {this.state.mappingRuleSelected === "Select" ? (
                            <span className="placeholder">
                              Select mapping rule
                            </span>
                          ) : (
                            <span className="selected">
                              <EllipsisWithTooltip placement="bottom">
                                {this.state.mappingRuleSelected}
                              </EllipsisWithTooltip>
                            </span>
                          )}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <div>
                            {this.state.ruleList.map((item: any, idx: any) => (
                              <Dropdown.Item
                                key={idx}
                                eventKey={item.ruleID}
                                onClick={() => this.handleDropChange(item, "1")}
                              >
                                <EllipsisWithTooltip placement="bottom">
                                  {item.ruleName}
                                </EllipsisWithTooltip>
                              </Dropdown.Item>
                            ))}
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Form.Group>
                  </div>
                )}

              <div className="MappingFields">
                <Row className="mappingFieldRow">
                  <Col sm="1">
                    <Form.Label>If</Form.Label>
                  </Col>
                  <Col sm="3">
                    <Dropdown
                      className={[
                        this.props.parentState.actionType === "Edit"
                          ? "EditEntry"
                          : "AddEntry",
                        this.state.mappingRuleFieldSelected === "Select"
                          ? "import-from-dropdown select"
                          : "import-from-dropdown",
                      ].join(" ")}
                    >
                      <Dropdown.Toggle id="mappingRuleFieldSelected">
                        <span className="selected">
                          {this.state.mappingRuleFieldSelected}
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div>
                          {this.state.mappingRuleField.map(
                            (item: any, idx: any) => (
                              <Dropdown.Item
                                key={idx}
                                eventKey={item.fieldID}
                                onClick={() => this.handleDropChange(item, "2")}
                              >
                                {item.displayFieldName}
                              </Dropdown.Item>
                            )
                          )}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                  <Col sm="3">
                    <input
                      className="form-control ConditionalLabel"
                      type="text"
                      value="Contains"
                      readOnly

                    />
                  </Col>
                  <Col sm="5">
                    <Form.Control
                      className="ConditionalInput"
                      value={this.state.containsValue}
                      onChange={(e: any) =>
                        this.onInputChange(e, "containsValue")
                      }
                      autoComplete="Off"
                      id="containsValue"
                      type="text"
                      maxLength={this.state.isLength}
                    />
                  </Col>
                </Row>
              </div>
              <div className="MappingFields">
                <Row className="mappingFieldRow Row1">
                  <Col sm="1">
                    <Form.Label>Then</Form.Label>
                  </Col>
                  <Col sm="3">
                    <input
                      className="form-control"
                      type="text"
                      value="COA"
                      readOnly
                    />
                  </Col>
                  <Col sm="3">
                    <input
                      className="form-control ConditionalLabel"
                      type="text"
                      value="Equals"
                      readOnly
                    />
                  </Col>
                  <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={this.renderTooltip(this.state.defaultCOA[0]?.label === undefined || this.state.defaultCOA[0]?.label === "" ? "COA" : this.state.defaultCOA[0]?.label)}>
                  <Col
                    sm="5"
                    className="more-action single-select-image dropdown coa-select jecoaSelect ConditionalTypeheaddropdown"
                  >
                    <Typeahead

                      inputProps={{
                        className: "ConditionalInput",
                        id: "cOASelected"
                      }}
                      options={this.state.COAList}
                      renderMenu={(results: any, menuProps) => (
                        <Menu {...menuProps}>
                          {results.map((result: any, index: any) => (
                            <>
                              <MenuItem option={result} position={index}>
                                <EllipsisWithTooltip placement="bottom">
                                  {result.label}
                                </EllipsisWithTooltip>
                              </MenuItem>
                              {result.label == "" && (
                                <MenuItem
                                  className="load-more"
                                  option={result}
                                  position={index}
                                >
                                  Load More
                                </MenuItem>
                              )}
                              {this.state.COAList.length > 0 &&
                                results[index - 1]?.orderno === 1 &&
                                result?.orderno === 2 && <Dropdown.Divider />}
                            </>
                          ))}
                        </Menu>
                      )}
                      renderMenuItemChildren={renderItemChildren}
                      onChange={(e) => this.handleSelectedItem(e, "coa")}
                      selected={this.state.defaultCOA}
                    />
                  </Col>
                  </OverlayTrigger>
                </Row>
                <Row className="mappingFieldRow">
                  <Col sm="1">
                    <Form.Label></Form.Label>
                  </Col>
                  <Col sm="3">
                    <input
                      className="form-control"
                      type="text"
                      value="Vendor"
                      readOnly
                    />
                  </Col>
                  <Col sm="3">
                    <input
                      className="form-control ConditionalLabel"
                      type="text"
                      value="Equals"
                      readOnly
                    />
                  </Col>
                  <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={this.renderTooltip(this.state.defaultVendor[0]?.label === undefined || this.state.defaultVendor[0]?.label === "" ? "Vendor" : this.state.defaultVendor[0]?.label)}>
                  <Col
                    sm="5"
                    className="more-action single-select-image dropdown coa-select jecoaSelect ConditionalTypeheaddropdown"
                  >
                    <Typeahead
                      inputProps={{
                        className: "ConditionalInput",
                        id: "vendorSelected"
                      }}
                      options={this.state.vendorList}
                      renderMenu={(results: any, menuProps) => (
                        <Menu {...menuProps}>
                          {results.map((result: any, index: any) => (
                            <>
                              <MenuItem option={result} position={index}>
                                <EllipsisWithTooltip placement="bottom">
                                  {result.label}
                                </EllipsisWithTooltip>
                              </MenuItem>
                              {result.label == "" && (
                                <MenuItem
                                  className="load-more"
                                  option={result}
                                  position={index}
                                >
                                  Load More
                                </MenuItem>
                              )}
                              {this.state.vendorList.length > 0 &&
                                results[index - 1]?.orderno === 1 &&
                                result?.orderno === 2 && <Dropdown.Divider />}
                            </>
                          ))}
                        </Menu>
                      )}
                      renderMenuItemChildren={renderItemChildren}
                      onChange={(e) => this.handleSelectedItem(e, "Vendor")}
                      selected={this.state.defaultVendor}
                    />
                  </Col>
                  </OverlayTrigger>
                </Row>
              </div>
            </div>
            <div className="publishFooter action-link">
              <button
                className="publishCancel btn wht-bg"
                onClick={(e) => this.handleClose()}
              >
                Cancel
              </button>
              <button
                className="publishSchedule btn btn-primary"
                onClick={() => this.handleSaveRule()}
                disabled={this.state.isProcessing}
              >
                {this.state.isProcessing ? (
                  <>
                    <Spinner size="sm" animation="border" /> Processing
                  </>
                ) : this.props.parentState.actionType === "Add" ? (
                  "Add Rule"
                ) : (
                  "Save Rule"
                )}
              </button>
            </div>
          </div>
        )}
      </OutsideClickHandler>
    );
  }

  renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props}
    </Tooltip>
  );

  handleSelectedItem = (event: any, type: any) => {
    let PostData = { ...this.state.formPostData };
    if (type === "coa") {
      if (event.length > 0) {
        let defaultCOA: any = [];
        let objCOA: any = {};
        objCOA.id = event[0].id;
        objCOA.label = event[0].label;
        objCOA.coAname = event[0].coAname;
        PostData.COA = event[0].id;
        defaultCOA.push(objCOA);
        this.setState(
          {
            cOASelected: event[0].id,
            formPostData: PostData,
            defaultCOA: defaultCOA,
          },
          () => {
            $("#cOASelected").removeClass("alert-danger");
          }
        );
      } else {
        PostData.COA = 0;
        PostData.COA = '';
        setTimeout(() => {
          this.setState({
            defaultCOA: [], cOASelected: '',
            formPostData: PostData
          });
        }, 100);
      }
    } else {
      if (event.length > 0) {
        let defaultVen: any = [];
        let objveN: any = {};
        objveN.id = event[0].id;
        objveN.label = event[0].label;
        objveN.coAname = event[0].coAname;
        PostData.VID = event[0].vid;
        PostData.VendorName = event[0].vendor;
        defaultVen.push(objveN);
        this.setState(
          {
            vendorSelected: event[0].label,
            formPostData: PostData,
            defaultVendor: defaultVen,
          },
          () => {
            $("#cOASelected").removeClass("alert-danger");
          }
        );
      } else {
        PostData.VID = 0;
        PostData.VendorName = '';
        setTimeout(() => {
          this.setState({
            defaultVendor: [], vendorSelected: '',
            formPostData: PostData
          });
        }, 100);
      }
    }
  };

  handleDropChange = (item: any, ControlID: any) => {
    let PostData = { ...this.state.formPostData };
    if (ControlID === "1") {
      PostData.RuleID = item.ruleID;
      this.setState(
        { mappingRuleSelected: item.ruleName, formPostData: PostData },
        () => {
          $("#mappingRuleSelected").removeClass("alert-danger");
        }
      );
    } else if (ControlID === "2") {
      let { containsValue } = this.state;
      let isLength: number;
      PostData.FieldName = item.fieldName === "Description1" ? item.displayFieldName : item.fieldName;
      if (item.fieldName.toString().toLocaleLowerCase().includes("descrip")) {
        isLength = 200;
      } else {
        isLength = 100;
        containsValue = containsValue.substring(0, 100);
      }
      this.setState(
        {
          mappingRuleFieldSelected: item.displayFieldName,
          formPostData: PostData,
          isLength,
          containsValue
        },
        () => {
          $("#mappingRuleFieldSelected").removeClass("alert-danger");
        }
      );
    } else if (ControlID === "3") {
      PostData.COA = item.id;
      this.setState(
        { cOASelected: item.coaName, formPostData: PostData },
        () => {
          $("#cOASelected").removeClass("alert-danger");
        }
      );
    } else if (ControlID === "4") {
      PostData.VID = item.vid;
      PostData.VendorName = item.vendor;
      this.setState(
        { vendorSelected: item.vendor, formPostData: PostData },
        () => {
          $("#vendorSelected").removeClass("alert-danger");
        }
      );
    }
  };

  onInputChange = (e: any, type: any) => {
    if (type === "containsValue") {
      let PostData = { ...this.state.formPostData };
      let containValue = e.currentTarget.value;
      // let containValue =
      //   e.currentTarget.value?.length > 50
      //     ? e.currentTarget.value.substring(0, 50)
      //     : e.currentTarget.value;
      PostData.ContainValue = containValue;
      this.setState(
        { containsValue: containValue, formPostData: PostData },
        () => {
          $("#containsValue").removeClass("alert-danger");
        }
      );
    }
  };

  checkValidation = () => {
    let isValid: any = [];
    let focusSet = false;
    if (
      this.props.parentState.actionType === "Add" &&
      this.props.parentState.dataRuleFrom !== "Import"
    ) {
      if (this.state.mappingRuleSelected?.trim() === "Select") {
        $("#mappingRuleSelected").focus();
        $("#mappingRuleSelected").addClass("alert-danger");
        focusSet = true;
        isValid.push(false);
      } else {
        $("#mappingRuleSelected").blur();
        isValid.push(true);
        focusSet = focusSet ? true : false;
      }
    }

    if (this.state.mappingRuleFieldSelected?.trim() === "") {
      if (!focusSet) {
        $("#mappingRuleFieldSelected").focus();
        focusSet = true;
      }
      $("#mappingRuleFieldSelected").addClass("alert-danger");
      isValid.push(false);
    } else {
      $("#mappingRuleFieldSelected").blur();
      isValid.push(true);
      focusSet = focusSet ? true : false;
    }
    if (this.state.containsValue?.trim() === "") {
      if (!focusSet) {
        $("#containsValue").focus();
        focusSet = true;
      }
      $("#containsValue").addClass("alert-danger");
      isValid.push(false);
    } else {
      $("#containsValue").blur();
      isValid.push(true);
      focusSet = focusSet ? true : false;
    }
    if (this.state.cOASelected?.trim() === "" && $('#cOASelected').val() === "" && this.state.vendorSelected?.trim() === "" && $('#vendorSelected').val() === "") {
      if (!focusSet) {
        focusSet = true;
      }
      $("#cOASelected").addClass("alert-danger");
      isValid.push(false);
    } else {
      $("#cOASelected").blur();
      isValid.push(true);
      focusSet = focusSet ? true : false;
    }
    if (this.state.vendorSelected?.trim() === "" && $('#vendorSelected').val() === "" && this.state.cOASelected?.trim() === "" && $('#cOASelected').val() === "") {
      if (!focusSet) {
        focusSet = true;
      }
      $("#vendorSelected").addClass("alert-danger");
      isValid.push(false);
    } else {
      $("#vendorSelected").blur();
      isValid.push(true);
      focusSet = focusSet ? true : false;
    }

    const returnValue = isValid.some((item) => item === false);
    return returnValue ? "Required" : "Succeed";
  };

  handleSaveRule = () => {
    this.setState({ isProcessing: true });
    let isValid = this.checkValidation();
    console.log(this.state.formPostData);
    if (isValid === "Succeed") {
      if (this.state.cOASelected?.trim() === "" && this.state.vendorSelected?.trim() === "") {
        Utils.toastError("Please Select the correct COA from list.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        this.setState({ isProcessing: false });
        $("#cOASelected").addClass("alert-danger");
        return false;
      }
      if (this.state.vendorSelected?.trim() === "" && this.state.cOASelected?.trim() === "") {
        $("#vendorSelected").addClass("alert-danger");
        Utils.toastError("Please Select the correct Vendor from list.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        this.setState({ isProcessing: false });
        return false;
      }
      if (this.props.parentState.actionType === "Add") {
        DataImportRules.SaveDataImportRule(this.state.formPostData)
          .then(async (result: any | null) => {
            if (result !== null) {
              if (result.result?.message.toLowerCase() === "success") {
                toast.success("Data Rule added successfully.", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                });
                this.props?.GetDataImportRule(
                  this.props.parentState.ruleIDFromImport
                );
                if (this.props?.parentState.dataRuleFrom === "Import")
                  this.props.showReprocess();
                setTimeout(() => {
                  this.handleClose();
                  if (this.props?.parentState.dataRuleFrom !== "Import")
                    this.props?.handleFilter();
                }, 1000);
                this.setState({ isProcessing: false });
              } else if (result.result?.message.toLowerCase() === "exists") {
                this.setState({ isProcessing: false });
                Utils.toastError("The mapping with this data rule already exists.", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                });
              } else {
                this.setState({ isProcessing: false });
                Utils.toastError(result.message, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                });
              }
            }
            resolve();
          })
          .catch((error) => {
            this.setState({ isProcessing: false });
            reject();
          });
      } else {
        let formData = { ...this.state.formPostData };
        let updatePostData: any = {};
        updatePostData.RuleID = formData.RuleID;
        updatePostData.FieldName = formData.FieldName;
        if (formData.FieldName?.toLowerCase() === "description")
          updatePostData.ContainValue =
            formData.ContainValue?.trim().length > 200
              ? formData.ContainValue?.substring(0, 200)?.trim()
              : formData.ContainValue?.trim();
        else
          updatePostData.ContainValue =
            formData.ContainValue?.trim().length > 100
              ? formData.ContainValue?.substring(0, 100)?.trim()
              : formData.ContainValue?.trim();
        updatePostData.COA = formData.COA;
        updatePostData.VID = formData.VID;
        updatePostData.VendorName = formData.VendorName;
        updatePostData.uniqueNo = this.state.uniqueNo;
        this.UpdateDeleteRule(updatePostData, "Update");
      }
    } else {
      Utils.toastError("Please fill the required fields.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      this.setState({ isProcessing: false });
    }
  };

  UpdateDeleteRule = (row: any, Action: any) => {
    let request = {} as any;
    request.UniqueNo = row.uniqueNo;
    request.RuleID = Number(row.RuleID);
    request.ActionType = Action;
    if (row.FieldName.toLowerCase() === "description")
      request.contains =
        row.ContainValue?.trim().length > 200
          ? row.ContainValue.substring(0, 200)?.trim()
          : row.ContainValue?.trim(); //row.contains;
    else
      request.contains =
        row.ContainValue?.trim().length > 100
          ? row.ContainValue.substring(0, 100)?.trim()
          : row.ContainValue?.trim(); //row.contains;
    request.If = row.FieldName;
    request.Vendor = row.VendorName;
    request.COA = row.COA === 0 ? '' : row.COA;
    request.UpdatedField =
      row?.updatedField === undefined ? "" : row?.updatedField;

    DataImportRules.DeleteUpdateDataImportRule(request)
      .then(async (result: any) => {
        if (result.result.message.toLowerCase() === "success") {
          toast.success("Mapping Rule updated successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          if (this.props.parentState.dataRuleFrom === "Import")
            this.props.reProcessRulesBtnShow();
          setTimeout(() => {
            this.handleClose();
          }, 2000);
          this.setState({ isProcessing: false });
        } else if (result.result?.message.toLowerCase() === "exists") {
          Utils.toastError("The mapping with this data rule already exists.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          this.setState({ isProcessing: false });
        }
        resolve();
      })
      .catch((error: any) => {
        this.setState({ isProcessing: false });
        Utils.toastError(`Server Error: ${error}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  };
}
