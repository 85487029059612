import React from "react";
import { Container, Dropdown, Spinner } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "../Settings/EntityManagement/pmsImport.scss"
import { ModuleReports } from "../../Common/Services/ModuleReports";
import { ToastContainer, toast } from "react-toastify";
import { resolve, reject } from "q";
import "../../Common/Assets/Styles/Modules/DetailsReportDrilldown.scss";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { Utils } from "../../Common/Utilis";

export class DetailsReportDrilldownMultiCOA extends React.Component<any, any> {
    private linkRef: any;
    private scheduleUserViewRef: any;
    constructor(props: any) {
        super(props);
        this.scheduleUserViewRef = React.createRef();
        this.linkRef = React.createRef();
        this.state = {
            tableData: [],
            densityName: "compact",
            reportName: "",
            coaName: "",
            queryData: []
        };
    }
    componentDidMount() {
        this.getDataFromQueryString();
    }

    getDataFromQueryString() {
        const location = this.props.location;
        if (location.state !== undefined) {
            const stateLength = Object.keys(location.state).length;
            if (stateLength > 0) {
                this.setState({ queryData: location.state }, () => {
                    this.loadReport(1);
                    this.loadData();
                })
            }
        }
    }

    getParamValueByParamName(paramName: string): any {
        let pramValue = new URLSearchParams(window.location.search).get(paramName)
        return window.atob(pramValue === null ? "" : pramValue);
    }

    loadData() {
        let { ReportType, reportId, OprID, COA, IsPending } = this.state.queryData;
        let reportParam = {} as any;
        const storage = JSON.parse(localStorage.getItem("storage")!);
        reportParam.tenantid = storage === null ? 0 : (storage.tenantID as any);
        reportParam.Report = ReportType;
        reportParam.reportid = reportId;
        reportParam.Oprid = OprID;
        reportParam.coa = COA;
        reportParam.IsPending = IsPending;
        try {
            ModuleReports.GetHeaderDetails(reportParam)
                .then(async (result: any | null) => {
                    if (result != null) {
                        this.setState({
                            reportName: result.reportname,
                            //coaName: result.coaname,
                        })
                    } else {

                    }
                    resolve();
                })
        } catch (error) {
            Utils.toastError(`Server Error: ${error}`, {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            reject();
        }
    }

    loadReport(pageNumber) {
        let { StartDate, EndDate, SplitHID, COA, UniqueNo, Ctype, Type, GroupID, SubGroupID, SubGroupID2, AccountType, IsTotal, OprID, reportId, Report, ReportType, RowType, SelectPL, IsCOS, IsMOD, IsFB, LineGroup, IsPending } = this.state.queryData;
        let reportParam = {} as any;
        const storage = JSON.parse(localStorage.getItem("storage")!);
        reportParam.ReportName = "Report Details";
        reportParam.username = storage === null ? 0 : (storage.userName as any);
        reportParam.tenantId = storage === null ? 0 : (storage.tenantID as any);


        reportParam.StartDate = StartDate;
        reportParam.EndDate = EndDate;
        reportParam.SplitHID = SplitHID;
        reportParam.COA = COA;
        reportParam.UniqueNo = UniqueNo;
        reportParam.Type = Type;
        reportParam.Ctype = Ctype;
        reportParam.GroupID = GroupID;
        reportParam.SubGroupID = SubGroupID;
        reportParam.SubGroupID2 = SubGroupID2;
        reportParam.AccountType = AccountType;
        reportParam.IsTotal = IsTotal;
        reportParam.Oprid = OprID;
        reportParam.Reportid = reportId;
        reportParam.PageNumber = pageNumber;
        reportParam.ExportType = "";
        reportParam.Report = Report;
        reportParam.ReportType = ReportType;
        reportParam.RowType = RowType;
        reportParam.SelectPL = SelectPL;
        reportParam.IsCOS = IsCOS;
        reportParam.IsMOD = IsMOD;
        reportParam.IsFB = IsFB;
        reportParam.Userid = "";
        reportParam.LineGroup = LineGroup;
        reportParam.IsPending = IsPending;

        this.setState({ loader: true })
        try {
            ModuleReports.GetDrillDownData(reportParam)
                .then(async (result: any | null) => {
                    if (result != null && result.length > 0) {
                        this.setState({ tableData: result }, () => {
                            this.setState({ loader: false });
                        });
                    } else {
                        this.setState({ loader: false })
                    }
                    resolve();
                })
        } catch (error) {
            Utils.toastError(`Server Error: ${error}`, {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            reject();
        }

    }

    Encript_btoa = (str) => {
        return window.btoa(str);
    }

    rowEvents = {
        onClick: (e: any, newRow: any, rowIndex: any) => {
            let { tokenID, StartDate, EndDate, Ctype, Type, GroupID, SubGroupID, SubGroupID2, AccountType, IsTotal, OprID, reportId, Report, ReportType, RowType, SelectPL, IsCOS, IsMOD, IsFB, LineGroup, IsPending } = this.state.queryData;
            let UniqueNo = -2;
            let SplitHID = newRow.splithid;
            let COA = newRow.coa;
            let url = "/Report/ReportDrilldown?";
            url = url + "UniqueNo=" + this.Encript_btoa(UniqueNo) + "&SplitHID=" + this.Encript_btoa(SplitHID) + "&OprID=" + this.Encript_btoa(OprID) + "&Ctype=" + this.Encript_btoa(Ctype) + "&StartDate=" + this.Encript_btoa(StartDate) + "&EndDate=" + this.Encript_btoa(EndDate) + "&ReportType=" + this.Encript_btoa(ReportType) + "&reportId=" + this.Encript_btoa(reportId) + "&SelectPL=" + this.Encript_btoa(SelectPL) + "&COA=" + this.Encript_btoa(COA) + "&Type=" + this.Encript_btoa(Type) + "&AccountType=" + this.Encript_btoa(AccountType) + "&GroupID=" + this.Encript_btoa(GroupID) + "&SubGroupID=" + this.Encript_btoa(SubGroupID) + "&SubGroupID2=" + this.Encript_btoa(SubGroupID2) + "&RowType=" + this.Encript_btoa(RowType) + "&IsTotal=" + this.Encript_btoa(IsTotal) + "&IsCOS=" + this.Encript_btoa(IsCOS) + "&IsMOD=" + this.Encript_btoa(IsMOD) + "&IsFB=" + this.Encript_btoa(IsFB) + "&LineGroup=" + this.Encript_btoa(LineGroup) + "&Report=" + this.Encript_btoa(Report) + "&tokenID=" + this.Encript_btoa(tokenID) + "&IsPending=" + this.Encript_btoa(IsPending);
            window.open(url, "_blank")
        }
    }

    handleTripleDotsMenu = (eventKey: any) => {
        let { StartDate, EndDate, SplitHID, COA, UniqueNo, Ctype, Type, GroupID, SubGroupID, SubGroupID2, AccountType, IsTotal, OprID, reportId, Report, ReportType, RowType, SelectPL, IsCOS, IsMOD, IsFB, LineGroup } = this.state.queryData;
        let reportParam = {} as any;
        const storage = JSON.parse(localStorage.getItem("storage")!);
        reportParam.username = storage === null ? 0 : (storage.userName as any);
        reportParam.tenantId = storage === null ? 0 : (storage.tenantID as any);
        reportParam.StartDate = StartDate;
        reportParam.EndDate = EndDate;
        reportParam.SplitHID = SplitHID;
        reportParam.COA = COA;
        reportParam.UniqueNo = UniqueNo;
        reportParam.Type = Type;
        reportParam.Ctype = Ctype;
        reportParam.GroupID = GroupID;
        reportParam.SubGroupID = SubGroupID;
        reportParam.SubGroupID2 = SubGroupID2;
        reportParam.AccountType = AccountType;
        reportParam.IsTotal = IsTotal;
        reportParam.Oprid = OprID;
        reportParam.Reportid = reportId;
        reportParam.ExportType = "";
        reportParam.Report = Report;
        reportParam.ReportType = ReportType;
        reportParam.RowType = RowType;
        reportParam.SelectPL = SelectPL;
        reportParam.IsCOS = IsCOS;
        reportParam.IsMOD = IsMOD;
        reportParam.IsFB = IsFB;
        reportParam.Userid = "";
        reportParam.LineGroup = LineGroup;
        reportParam.PageNumber = 0;
        reportParam.ExportType = eventKey;
        let currDate = new Date()
        let fileName = "Details-" + (currDate.getMonth() + 1) + "_" + currDate.getDate() + "_" + currDate.getFullYear() + "_" + currDate.getHours() + "_" + currDate.getMinutes() + "_" + currDate.getSeconds();
        reportParam.fileName = eventKey === "pdf" ? fileName + ".pdf" : fileName + ".xlsx";
        try {
            ModuleReports.Get_DrilldownDataAttachmentMultiple(reportParam)
                .then(async (result: any | null) => {
                    if (result != null && result.length > 0) {

                    } else {

                    }
                    resolve();
                })
        } catch (error) {
            Utils.toastError(`Server Error: ${error}`, {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            reject();
        }
    }

    render() {
        let { tableData, loader, reportName, coaName } = this.state;
        let sum_value = tableData.reduce((sum, current) => {
            return sum + current.amount
        }, 0);

        const columns = [
            {
                dataField: "lettercode",
                text: "EHID",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <EllipsisWithTooltip placement="bottom">{row.lettercode}</EllipsisWithTooltip>
                    )
                }
            }, {
                dataField: "coa",
                text: "COA",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <EllipsisWithTooltip placement="bottom">{row.coa}</EllipsisWithTooltip>
                    )
                }
            }, {
                dataField: "coaname",
                text: "COA Name",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <EllipsisWithTooltip placement="bottom">{row.coaname}</EllipsisWithTooltip>
                    )
                }
            }, {
                dataField: "amount",
                text: "Amount",
                headerClasses: 'amount',
                classes: 'amount',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <EllipsisWithTooltip placement="bottom">{Utils.currencyFormatWithOutdoller(Number(row.amount))}</EllipsisWithTooltip>
                    )
                }
            }
        ];

        return (
            <div className="drilldown">
                <Container fluid className="body-sec pms-import">
                    <ToastContainer
                        autoClose={3000}
                        containerId={"ddldata"}
                        enableMultiContainer
                    />
                    <div className="page-heading underline d-flex">
                        <div className="mr-auto align-items-center">
                            {reportName}
                            <div className="tag-line">{coaName}</div>
                        </div>
                        <div className="action-group d-flex">
                            <div className="Accbalance">
                                <span className="balTitle">Grand Total Amount</span>
                                <strong className="balMain">{Utils.currencyFormat(Number(sum_value))}</strong>
                            </div>
                            <Dropdown className="more-action three-dot" alignRight onSelect={this.handleTripleDotsMenu}>
                                <Dropdown.Toggle className="btn-outline-primary btn btn-primary more" id="dropdown-more">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                    </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item eventKey={"pdf"}>Export PDF</Dropdown.Item>
                                    <Dropdown.Item eventKey={"excel"}>Export Excel</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <div className={loader ? "bdy-sec loader-active pos-relative" : "bdy-sec pos-relative"}>
                        <div className="main-Page">
                            <div className="result-area">
                                <div className={`scroll-area ${this.state.densityName}`}>
                                    {!loader && (
                                        <BootstrapTable keyField="rowNo" data={tableData} columns={columns} rowEvents={this.rowEvents} />
                                    )}
                                    {loader && (
                                        <div className="loader-spinner d-flex justify-content-center align-items-center">
                                            <Spinner animation="border" variant="success" />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}
