import React from "react";
import { Row, Col, Button, Card, Form, ProgressBar } from "react-bootstrap";
import { ForgotPassword as PwdService } from "../Common/Services/ForgotPasswordService";
import { resolve, reject } from "q";
import PageLoader from "../Common/Components/PageLoader";
import { Redirect } from "react-router-dom";

const strongRegex = new RegExp(
  "^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&?*.<>:_{}])(?=.{8,})"
);
//const strongRegex = new RegExp("^(?=.*[A-Z])(?=.*[0-9])(?=.*~`!@#$%^&*()_-+={[}]|:;'<,>.?)(?=.{8,})");
const mediumRegex = new RegExp(
  "^(((?=.*[A-Z]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
);
export class ChangePassword extends React.Component<any, any> {
  private continueBtnRef:any;
  constructor(props: any) {
    super(props);
    this.continueBtnRef = React.createRef();
    this.state = {
      mode: 4,
      userName: this.props.changeModule[1].userName,
      passCode: this.props.changeModule[1].passCode,
      resetMode: this.props.changeModule[1].resetMode,
      errorMessage: "",
      loaderTrue: false,
      navigate: false,
      filledColor: "default",
      filledArea: 0,
      passwordStrength: "",
      password: "",
      confirmPassword: "",
    };
    this.handleContinue = this.handleContinue.bind(this);
    this.analyze = this.analyze.bind(this);
  }
  handleChange = (event: any) => {
    let errorMessage;
    event.preventDefault();
    let password = this.state.password;
    let ConfirmPassword = event.target.value;
    this.setState({ confirmPassword: ConfirmPassword });
    if (ConfirmPassword === "") {
      errorMessage = "Password can't be blank";
      this.setState({ loaderTrue: false, errorMessage: errorMessage });
    } else if (password !== ConfirmPassword) {
      errorMessage = "Passwords do not match";
      this.setState({ loaderTrue: false, errorMessage: errorMessage });
    } else if (password === ConfirmPassword) {
      errorMessage = "";
      this.setState({ loaderTrue: false, errorMessage: errorMessage });
    }
  };

  analyze = (event: any) => {
    this.setState({ password: event.target.value });
    let errorMessage;
    let filledColor;
    let filledArea;
    let passwordStrength;
    let password = event.target.value;
    let ConfirmPassword = this.state.confirmPassword;

    // this is for Filled area
    if (password.length < 3) {
      filledArea = 0;
    } else if (password.length >= 3 && password.length < 6) {
      filledArea = 33;
    } else if (password.length >= 6 && password.length < 8) {
      filledArea = 66;
    } else {
      filledArea = 100;
    }

    if (password !== ConfirmPassword && ConfirmPassword !== "") {
      errorMessage = "Passwords do not match";
      this.setState({ loaderTrue: false, errorMessage: errorMessage });
    } else if (password === ConfirmPassword) {
      errorMessage = "";
      this.setState({ loaderTrue: false, errorMessage: errorMessage });
    }

    // this is for Regex
    if (strongRegex.test(event.target.value)) {
      filledColor = "success";
      passwordStrength = "Strong password";
    } else if (mediumRegex.test(event.target.value)) {
      filledColor = "info";
      passwordStrength = "Could be stronger";
    } else {
      filledColor = "warning";
      passwordStrength = "Too weak";
    }
    this.setState({
      filledColor,
      passwordStrength,
      filledArea,
    });
  };

  async handleContinue(event: any) {
    event.preventDefault();
    const form = event.target;
    const userName = this.state.userName;
    const password = form.elements.confirmPassword.value;

    const newPassword = form.elements.newPassword.value;
    const confirmPassword = form.elements.confirmPassword.value;
    let errorMessage = "";

    if (newPassword === "" || confirmPassword === "") {
      errorMessage = "Password can't be blank";
      this.setState({ loaderTrue: false, errorMessage: errorMessage });
    } else if (newPassword !== confirmPassword) {
      errorMessage = "Passwords do not match";
      this.setState({ loaderTrue: false, errorMessage: errorMessage });
    } else {
      this.continueBtnRef.current.disabled = true;
      this.setState({ loaderTrue: true });
      const passCode = this.state.passCode;

      PwdService.ResetPasswordWithPassCode(userName, password, passCode, "Yes")
        .then(async (resturnMessage: string | null) => {
          if (resturnMessage === "ValidPassCode") {
            this.props.changeModule[0](this.state);
            this.setState({ loaderTrue: false });
          } else if (resturnMessage === "AlreadyUsedPassword") {
            errorMessage =
              "You cannot re-use passwords. Please choose a unique password.";
            // this.setState({loaderTrue: false, errorMessage: errorMessage });
          } else if (resturnMessage === "InvalidUser") {
            errorMessage = "Please enter valid username.";
            //this.setState({loaderTrue: false, errorMessage: errorMessage });
          } else if (resturnMessage === "InvalidPassCode") {
            errorMessage = "Incorrect passcode entered. Please try again.";
            // this.setState({loaderTrue: false, errorMessage: errorMessage });
          } else if (resturnMessage === "PasscodeExpired") {
            errorMessage =
              "Passcode reset link has been expired. Please try again.";
            // this.setState({loaderTrue: false, errorMessage: errorMessage });
          }
          this.continueBtnRef.current.disabled = false;
          this.setState({ loaderTrue: false, errorMessage: errorMessage });
          resolve();
        })
        .catch((error) => {
          this.setState({ loaderTrue: false });
          reject();
        });
    }
  }
  cancel = () => {
    this.setState({ navigate: true });
    if (this.props.page === "timeclock") {
      return this.props.cancelResetPassword();
    }
  };
  render() {
    const {
      passwordStrength,
      navigate,
      confirmPassword,
      password,
    } = this.state;
    var loaderTrue = this.state.loaderTrue;

    var loadShow;
    if (loaderTrue) {
      loadShow = <PageLoader />;
    }
    if (navigate && this.props.page !== "timeclock") {
      ;
      return <Redirect to={this.state.redirect} />;
    }

    const isEnabled =
      passwordStrength === "Strong password" && password === confirmPassword;
    return (
      <div className="create-new-password">
        {loadShow}
        {
          this.props.errorMessage != "" &&
          <div className="card-title h4">{this.props.errorMessage}</div>
        }
        {this.props.errorMessage == "" &&
          <>
            <div className="header-sec">
              <div className="sec-ttl">Create New Password</div>
              <div className="sec-desc fnt-14">
                <p>Passwords must:</p>
                <ul>
                    <li>Be at least eight characters long</li>
                    <li>Have at least one uppercase letter</li>
                    <li>
                    Have at least one number and/or special character (such as punctuation marks, mathematical and other conventional symbols)
                    </li>
                    <li>Be unique (a password you haven’t used before)</li>
                  </ul>
              </div>
            </div>
            <Form
              className="frm-section was-validated"
              name="forgotForm"
              onSubmit={this.handleContinue}
            >
              <Form.Group controlId="formBasicEmail" className="mrgn-btm-24">
                <Form.Label>Enter Password</Form.Label>
                <Form.Control
                  type="password"
                  value={this.state.password}
                  onChange={this.analyze}
                  name="newPassword"
                  //placeholder="*******"
                />
                <Row>
                  <Col>
                    <Row>
                      <Col md={{ span: 8 }}>
                        <ProgressBar
                          variant={this.state.filledColor}
                          now={this.state.filledArea}
                        />
                      </Col>
                      <Col md={{ span: 4 }}>
                        <div className="progress-lbl">
                          {this.state.passwordStrength}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Enter Password Again</Form.Label>
                <Form.Control
                  type="password"
                  onChange={this.handleChange}
                  name="confirmPassword"
                  
                  
                  //placeholder="*******"
                />
                <span className="invalid-feedback">
                  {this.state.errorMessage}
                </span>
              </Form.Group>

            <div className="frm-action">
              <Button variant="primary" type="submit" disabled={!isEnabled} ref={this.continueBtnRef}>
                Continue
              </Button>
              <Button variant="primary" type="button" className="white-bg" onClick={() => this.cancel()}>
                Cancel
              </Button>
            </div>
            </Form>
          </>
        }
      </div>
    );
  }
}
