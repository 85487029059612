import React from "react";
import { toast } from "react-toastify";
import {
  Container,
  Dropdown,
  Tab,
  Nav,
  OverlayTrigger,
  Tooltip,
  Button,
  Spinner
} from "react-bootstrap";  
import {IWorkOrderPermissionRequestDto, IWorkOrderRequestDto } from "../../Common/Contracts/IWorkOrder";
import { WorkOrder } from "../../Common/Services/WorkOrder";
import { resolve, reject } from "q";
import WorkOrderSlideOut from "../Workorder/WorkOrderSlideOut";
import { AssetMaintenance } from "./AssetMaintenance";
import { AssetWorkOrder } from "./AssetWorkOrder";
import AutosizeInput from "react-input-autosize";
import { Utils } from "../../Common/Utilis";
import { confirmAlert } from "react-confirm-alert";
import { Facility } from "../../Common/Services/Facility";


export class AssetSlideOut extends React.Component<any, any> {
  private wororderSlideOut: any;
  private refLocationWorkOrder: any;
  private refLocationAsset: any;
  private refLocationMaintenance: any;
  private slideOutRef: any;
  private input: any;

  constructor(props: any) {
    super(props);
    this.wororderSlideOut = React.createRef();
    this.refLocationWorkOrder = React.createRef();
    this.refLocationAsset = React.createRef();
    this.refLocationMaintenance = React.createRef();
    this.slideOutRef = React.createRef();
    this.input = React.createRef();
    
    this.state = {
        isReload: false,  
        locationName : this.props.parentstate.roomName,
        locationType : this.props.parentstate.roomType,
        tableData: [],
        sortingOrder: "asc",
        sortingField: "location",
        locationTypesList: [],
        assetTypesList: [],
        assignToListRightPanel: [],
        parentRowData: [],
        assignToList: [],
        isDataSaving:false,
        isSlideOut: false,
        bdrValue:false,
        PageType: "locationWorkOrder",
        hidValue : this.props.hid,
        locationId : this.props.parentstate.roomID,
        name: this.props?.parentstate?.assetName,
        workorderPermission: "",
        activeTab: "work-orders",
        // workordertbl: 0
        tbl : 0,
        lastWorkId : "",
        type: "",
        countLoader: false,
        count:0
    };
  }

  componentDidMount() {
    this.getrooms();
    this.GetFacilityAsset();
    this.getAssignUserList();
    this.GetWorkOrderPermission();
    this.refLocationWorkOrder.current.getWorkOrder();
  }

  closeSlideOut = () => {
    this.props?.handleHideSlideout(this.state.isReload);
  };

  closeLocationSlideOut = (row: any) => {
    this.setState({isSlideOut : true,parentRowData:row});
    setTimeout(() => {
      this.slideOutRef.current.scrollTo({
        top: 0,
        behavior: "instant",
      });
    });
    // this.props?.handleHideSlideoutforworkorder(this.state.isReload,row);
  }

  handleClick = () => {
    this.setState({ bdrValue: true });
  };

  handleBlurAutoSize = () => {
    this.setState({ bdrValue: false ,   name: this.state.name.trimRight(),}, ()=>{
      this.setState({changeValue: this.state.name  === this.props?.parentstate?.assetName ? false:true})
    });
  };

  changesAfterSave = (row: any) =>{

     if(this.state.isSlideOut){
      this.setState({isSlideOut : false},()=>{
        this.setState({isSlideOut : true,parentRowData:row});
      });     
     }else{
      this.setState({isSlideOut : true,parentRowData:row});
     }
  }

  closeworkorderSlideout = (value) => {
  
    if(this.wororderSlideOut.current.state.changeValue === true){
      this.setState({isSlideOut: value,activeTab: "work-orders",type: "table"} , () => {
        this.wororderSlideOut.current.isValidateModelPoupTab("sideClick");
      });
    }
    else{
    this.setState({isSlideOut: value, activeTab: "work-orders" , type: "table"}, () => {
      if (this.refLocationWorkOrder.current !== null) {
        this.refLocationWorkOrder.current.getWorkOrder();
      }
    });
  }
  };

  closeSlideOutWithConfirmation = () => {
    if (this.state.changeValue) {
      this.isValidateModelPoupTab();
    }
    else if(this.state.isSlideOut === false){
      this.closeSlideOut();
    }
    
  };

  
  isValidateModelPoupTab() {
    confirmAlert({
      title: "Unsaved confirmation",
      message:
        "You have unsaved changes on this page. Do you want to leave this page and discard your changes?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.isChangeUnsaved(),
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }

  isChangeUnsaved = () => {
    this.setState({ changeValue: false }, () => {
      this.disccardChanges(true);
    });
  };

  slideOutClosewithDiscardChanges = (value) => {
    this.setState({isSlideOut : value});
  
  }

  getrooms = () => {
    let hotelID = Number(this.state.hidValue);
   WorkOrder.GetFacilityRooms(hotelID)
       .then(async (result: any | null) => {
           let DayApproveData: any = [];
           
           if (result != null && result.length > 0) {
               let Data = result.map(x => {
                   return { id: x?.roomID, name: x?.roomName };
               })

               this.setState({
                 locationTypesList: Data
               });
               this.setState({locationSearchTypesList:[]},()=>{
                 this.setState({ locationSearchTypesList: [{ id: -1, name: "All" },...Data] });
               })
              
           } else {
               this.setState({
                 locationTypesList: []
               });
           }
           resolve();
       })
       .catch((err) => {
           Utils.toastError(`Server Error, ${err}`, {
           });

           reject();
       });
}

GetFacilityAsset = () => {
  let hotelID = Number(this.state.hidValue);
    WorkOrder.GetFacilityAsset(hotelID)
        .then(async (result: any | null) => {

            if (result != null && result.length > 0) {
                let Data = result.map(x => {
                    return { id: x?.roomID, name: x?.roomName, locationID: x?.locationID };
                })
                this.setState({ assetTypesList: Data });
              // this.setState({ assetTypesList: [{ id: 0, name: "Select an asset" }, ...Data] });
            } else {
                //this.setState({ assetTypesList: [{ id: 0, name: "Select an asset" }] });
                this.setState({ assetTypesList: [] });
            }
            resolve();
        })
        .catch((err) => {
            Utils.toastError(`Server Error, ${err}`, {
            });

            reject();
        });
}

getAssignUserList() {

  let request = {} as IWorkOrderRequestDto;
  request.hotelID = Number(this.state.hidValue);
  request.type = "AssignUsers";
  WorkOrder.GetFcUserDetailsList(request)
    .then(async (result: any | null) => {
     
      if (result != null && result.length > 0) {
        let Data = result.map(x => {
          return { id: x?.user_UniqueID, name: x?.employee };
        })

        this.setState({ assignToList: [] }, () => {
          this.setState({ assignToList: [{ id: -1, name: "All" }, ...Data] });
          this.setState({assignToListRightPanel:Data});
          // this.setState({ assignToListToChild: [{ id: -1, name: "Select a technician" }, ...Data] });
        })

      } else {
        this.setState({ assignToList: [{ id: -1, name: "All" }],assignToListRightPanel:[]});
        
      }
      resolve();
    })
    .catch((err) => {
      Utils.toastError(`Server Error, ${err}`, {
      });

      reject();
    });
}

GetWorkOrderPermission = () =>  {
  let request = {} as IWorkOrderPermissionRequestDto;
  WorkOrder.GetWorkOrderPermission(request)
    .then(async (result: any | null) => {
      let DayApproveData: any = [];
      if (result != null && result.length > 0) {  
        console.log("result",result[0].permission);
        // console.log();
        
        if(result[0].permission === "Yes")
        {
          this.setState({workorderPermission: "Yes"});
        }
        else{
          this.setState({workorderPermission: "No"});
        }
      }
      resolve();
    })
    .catch((err) => {
      Utils.toastError(`Server Error, ${err}`, {         
      });
      
      reject();
    });
}

handleTabSelect= (selectedTab) => {

  console.log("selectedTab",selectedTab);
  this.setState({activeTab: selectedTab});
}

refeshTable=(workOrderID :any,type:any)=>{

  this.setState({lastWorkId:workOrderID,type:type});
  this.refLocationWorkOrder.current.getWorkOrder();
  // if (this.state.isDayDropDown) {
  //         this.getWorkOrderSummaryDetails("completed","");
  //       } else {
  //         this.getWorkOrderSummaryDetails("notcompleted","");
  //       }
  //     });
}

countUpdate = (count,countState) => {
    this.setState({count:count,countLoader:countState})
}

onFieldChange = (event, inputPosition) => {
  let value = event.target.value;
  const fieldName = event.target.name;

  if (fieldName === "name") {
    value = value?.toString()?.trimLeft();
  }

  this.setState({ [fieldName]: value, changeValue: this.props?.parentstate?.assetName  === value ? false:true });
};



disccardChanges = (isFromModal = false) => {
  this.setState(
    {
      isRefresh: true,
      changeValue: false,
      name: this.props?.parentstate?.assetName,
    },
    () => {
      this.setState({ isRefresh: false });
    }
  );

  if (isFromModal) {
    this.closeSlideOut();
  }
 
};

handleSave =()=>{

  const data={
    AssetId:this.props.parentstate?.assetId,
    RoomId: this.props.parentstate?.roomId,
    AssetName: this.state.name,
    AssetTypeId:  this.props.parentstate.assetTypeId,
    AssetDescription:  this.props.parentstate?.description,
    }

    if (!this.state.name) {
      let errorMessage = "Please enter Asset Name";
      Utils.toastError(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "InvoiceSlideout",
      });
      
      return
    }

    this.setState({isDataSaving:true})

  Facility.SaveAssets(data)
    .then((result: any) => {
      
         this.setState({isDataSaving:false})
         this.props?.handleHideSlideout(true);

         toast.success(`Asset Updated successfully.`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addGarnishment",
          });
      
     
    }).catch((error)=>{
        this.setState({isDataSaving:false})

        if(error.response.data == 'Asset Name already in use. Please provide a different name.')
        {

            Utils.toastError(error?.response?.data, {
              position: toast.POSITION.BOTTOM_RIGHT,
              // containerId: "addGarnishment",
            });
      
           
        }
        else{
         Utils.toastError(error.response.data, {
            position: toast.POSITION.BOTTOM_RIGHT,
            // containerId: "addGarnishment",
          });

          this.props?.handleHideSlideout(true);
        }
   
    })
}

  render() { 

    const {name,isDataSaving}=this.state
    return (
      <div id="asset-slideout" className="pos-fxd index-1000">
        <div className="slideOut-wrapper">
          <div className="d-flex">
            <div className="back-drop" onClick={this.closeSlideOutWithConfirmation}></div>
            {/* <div className="back-drop" onClick={this.closeSlideout}></div> */}

            <Container
              fluid
              className="body-sec"
              ref={this.slideOutRef}
            >
              <div className="page-heading d-flex">
                
                  <div className="user-details align-items-center mr-auto">
                      {/* <div className="location-title">{this.props?.parentstate?.assetName}</div> */}
                      
                      <div className="pos-ttl mr-auto">
                          <OverlayTrigger
                            placement="bottom"
                            defaultShow={false}
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip id={"Asset-Name"} className={name?.length <= 75 ? "tootltip-hide" : ""}>
                                {name}
                              </Tooltip>
                            }
                          >
                            <AutosizeInput
                              ref={this.input}
                              className={`form-control removeBorder input-component ${this.state.bdrValue ? this.state.nameError ?  "bdr-green" :"bdr-green":""}`}
                              style={{ fontSize: 24 }}
                              injectStyles={false}
                              type="input"
                              value={name}
                              tabIndex={1}
                              onClick={() => this.handleClick()}
                              onChange={(e: any) =>{
                                  this.onFieldChange(
                                    Utils.BuildCustomEventObject(
                                      "name",
                                      e.target.value
                                    ),
                                    1
                                  )
                                }
                              }
                              onBlur={() => this.handleBlurAutoSize()}
                              placeholder={"Enter Asset Name"}
                              maxLength={100}
                            />
                          </OverlayTrigger>
                         

                      </div>
                      <div className="position-name">Asset</div>
                  </div> 

                <div className="d-flex">
                    <Dropdown className="more-action" alignRight>
                      <Dropdown.Toggle
                        className="btn-outline-primary btn btn-primary more border-0"
                        id="dropdown-more"
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                          <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                          <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                        </svg>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item></Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <button
                    type="button"
                    className="btn wht-bg cross pr-0 pl-2"
                    onClick={this.closeSlideOutWithConfirmation}
                  >
                  <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                        fill="#84888C"
                      />
                    </svg>
                  </button>
                </div>
                  
              </div> 
        
              <div className="info">
                <div className="form-item">
                  <div className="item-label">
                    <h6>EHID</h6>
                  </div>
                  <div className="item-value">{this.props.hotelName}</div>
                </div>
                <div className="form-item">
                  <div className="item-label">
                    <h6>Location</h6>
                  </div>
                  <div className="item-value">{this.props?.parentstate?.roomName}</div>
                </div>
                <div className="form-item">
                  <div className="item-label">
                    <h6>Asset Type</h6>
                  </div>
                  <div className="item-value">{this.props?.parentstate?.assetTypeName}</div>
                </div>
                  
              </div>


              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="work-orders"
                activeKey={this.state.activeTab}
                onSelect={this.handleTabSelect}
              >
                <div>
                  <Nav className="nav-tabs flex-row">
                      <Nav.Item>
                          <Nav.Link eventKey="work-orders" className="d-flex"><span>Work Orders</span> 
                          {this.state.countLoader ? <Spinner animation="border" size="sm" variant="success" className="ml-2" /> :
                          <div className="count"><span>{this.state.count}</span></div> 
                          }   
                      </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                          <Nav.Link eventKey="maintenance"><span>Maintenance </span>
                          <div className="count"><span>{this.refLocationMaintenance.current !== null ? this.refLocationMaintenance.current.state.tableDataLength : 0}</span></div></Nav.Link>
                      </Nav.Item>
                   
                  </Nav>
                </div>
                <div>
                  <Tab.Content>
                      <Tab.Pane eventKey="work-orders">
                      {/* <BootstrapTable
                      keyField="workOrderID"
                      data={this.state.tableData}
                      columns={columns}
                      search
                      hover
                    /> */}
                    <AssetWorkOrder 
                    assetId={this.props.parentstate.assetId}
                    ref={this.refLocationWorkOrder} countUpdate={this.countUpdate}
                    closeLocationSlideOut={this.closeLocationSlideOut} parentData={this.state} changesAfterSave={this.changesAfterSave}></AssetWorkOrder>
                  
                      </Tab.Pane>
                  
                      <Tab.Pane eventKey="maintenance">
                      <AssetMaintenance roomId={this.props.parentstate.roomId} ref={this.refLocationMaintenance} parentData={this.state}></AssetMaintenance>
                      </Tab.Pane>
                    
                  </Tab.Content>
                </div>
                {this.state.isSlideOut && (
                  <div className="asset-workorder-slideOut">
                    <div className="breadcrumb-bar">
                      <Button onClick={this.closeworkorderSlideout}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="22" viewBox="0 0 26 22" fill="none">
                        <path d="M25.116 3.1328L22.4208 0.4375L11.6396 11.2187L22.4208 22L25.116 19.3045L17.0302 11.2187L25.116 3.1328Z" fill="#2CAF92"/>
                        <path d="M11.6396 0.4375L14.3349 3.1328L6.24901 11.2187L14.3349 19.3045L11.6396 21.9998L0.858398 11.2187L11.6396 0.4375Z" fill="#2CAF92"/>
                      </svg>
                        <span>Back to {this.props?.parentstate?.assetName}</span>
                        
                      </Button>
                    </div>
                    <WorkOrderSlideOut
                      assetDisable={true}
                      locationDisable={true}
                      ref={this.wororderSlideOut}
                      // closeWorkSlideOut={this.closeWorkSlideOut}
                      parentState={this.state}
                      parentRowData={this.state.parentRowData}
                      // workOrderStatusUpdate={this.workOrderStatusUpdate}
                      closeLocationSlideOut={this.closeSlideOut}
                    // changesOfSlideOut={this.changesOfSlideOut}
                    refeshTable={this.refeshTable}     
                    slideOutClosewithDiscardChanges={this.slideOutClosewithDiscardChanges}     
                    // slideOutClosewithoutChanges={this.slideOutClosewithoutChanges} 
                    closeworkorderSlideout={this.closeworkorderSlideout}                    
                    />
                  </div>

                )}
              </Tab.Container> 

              {this.state.changeValue && (
                        <div className="strip-wrapper">
                          <div className="fixed-action px-3 pb-2 h-auto">
                            <div className="d-flex align-items-center flex-wrap forecast-action-strip">
                              <div className="mr-auto message">
                                <span>
                                  You have made changes to this Asset. Do
                                  you want to save and continue ?{" "}
                                </span>
                              </div>

                              <button
                                type="button"
                                className="btn btn-primary border-0 btn-discard btn-changes-discard"
                                onClick={() => this.disccardChanges()}
                              >
                                Discard
                              </button>

                              <button
                                disabled={isDataSaving}
                                type="button"
                                className="save-btn btn btn-primary mr-0"
                                onClick={this.handleSave}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
            </Container>

          </div>
           
        </div>
      </div>
    );
  }
}
