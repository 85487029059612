
import Instense from "./Axios-config";
import { IGetLaborDailyActivity,IGetLaborDailyActivityPermission,IGetExitsLaborHouseKeeping } from "../Contracts/ILaborDailyActivity";

export class LaborDailyActivity {
  public static GetDailyActivity = async (
    request: IGetLaborDailyActivity
  ): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDailyActivityEntry/GetDailyActivityEntrySummary`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static SaveDailyActivity = async (
    request: any
  ): Promise<any | null> => {
    const url = `/LaborDailyActivityEntry/SaveDailyActivityEntry`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    
    return result;
  };

  public static GetDailyActivityPermission = async (
    request: IGetLaborDailyActivityPermission
  ): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDailyActivityEntry/GetDailyActivityPermission`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static getLabourHousekeppingExits = async (
    request: IGetExitsLaborHouseKeeping
  ): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborDailyActivityEntry/GetExitsLaborHouseKeeping`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };


  
}


