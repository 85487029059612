import React from "react";
import { Button, Col, Form, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Utils } from "../../../Common/Utilis";
import { toast, ToastContainer } from "react-toastify";
import Joi from "joi-browser";
import { DropDownList } from "../../Reports/common-components/dropdown-list";
import { StaticArrays } from "../../../Common/StaticArrays";
import _ from "lodash";
import { DeductionCriteria } from "../../../Common/Services/DeductionCriteria";
import { confirmAlert } from "react-confirm-alert";
import { MultiSelectDropdown } from "./MultiSelectDropdown";
import { ExemptTypeDropdown } from "./ExemptTypeDropdown";

const GetAmountType=(ddCriteria=null)=>{
  const val=ddCriteria 
  if(!val){
    return false
  }
  
  const isDollarValues=['Fixed Amount','Amount ($) Per Hour','Amount ($) per Month','Direct Entry']
  if(isDollarValues.includes(val)){
     return "dollar"
  }else{
    return "percentage"
  }
}

const DEDUCTIONTYPES=_.sortBy(StaticArrays.deductionTypeList,[function(o) { return o.label; }])
const TAXTYPES=_.sortBy(StaticArrays.taxTypesList,[function(o) { return o.label; }])
const DEDEDUCTIONCRITERIAS=_.sortBy(StaticArrays.deductionCriteriaList,[function(o) { return o.label; }])

export class PayrollDeductionForm extends React.Component<any, any> {
  private deductionFormSchema = Joi.object({
    uniqueno: Joi.number().min(0),
    status: this.props?.deductionRowEdit?.status || "Active",
    deductionName:  Joi.string().required().error((errors) => {
      return errors.map((error) => {
        return { message: "Please enter Deduction Name" };
      });
    }),
    taxType: Joi.string().required().error((errors) => {
      return errors.map((error) => {
        return { message: "Please select Tax Type" };
      });
    }),
    exempt: Joi.allow(""),
    deductionType: Joi.string().required().error((errors) => {
      return errors.map((error) => {
        return { message: "Please select Deduction Type" };
      });
    }),
    deductionCriteria:  Joi.string().required().error((errors) => {
      return errors.map((error) => {
        return { message: "Please select Deduction Criteria" };
      });
    }),
    amount: Joi.string().required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please enter Amount" };
        });
      }),
 
    showFields:Joi.boolean(),
    payeeName: Joi.allow(""),
    street: Joi.allow(""),
        //  Joi.string().when("showFields",{is : true, then : Joi.required()
        //   .error((errors) => {
        //     return errors.map((error) => {
        //       return { message: "Please enter street" };
        //     });
        //   })}),

          // country: Joi.string().when("showFields",{is : true, then : Joi.required()
          //   .error((errors) => {
          //     return errors.map((error) => {
          //       return { message: "Please enter country" };
          //     });
          //   })}),
    city:  Joi.allow(""),
    // Joi.string().when("showFields",{is : true, then : Joi.required()
    //   .error((errors) => {
    //     return errors.map((error) => {
    //       return { message: "Please enter City" };
    //     });
    //   })}),
    state:  Joi.allow(""),
    // Joi.string().when("showFields",{is : true, then :Joi.required()
    //   .error((errors) => {
    //     return errors.map((error) => {
    //       return { message: "Please select State" };
    //     });
    //   })})
    
    zip:  Joi.allow(""),
    // Joi.string().max(15).when("showFields",{is : true, then :Joi.required()
    //   .error((errors) => {
    //     const _errors = errors.map((error) => {
    //       if (error?.message) {
    //         return;
    //       }
    //       switch (error.type) {
    //         case "any.empty":
    //           return {
    //             message:
    //               "Please enter Zip Code",
    //           };

    //           case "string.max":
    //           return {
    //             message:
    //               "Zip Code should be 15 digit",
    //           };
    //       }
    //     });
    //     return [_errors[0]];
    //   })})
   
  
  });

 
  constructor(props){
    super(props);  
    this.state = {
      deductionFormData: {
        uniqueno: this.props?.deductionRowEdit?.uniqueno || 0,
        status: this.props?.deductionRowEdit?.status || "Active",
        deductionName: this.props?.deductionRowEdit?.deductionName || "",
        taxType: this.props?.deductionRowEdit?.taxType || "",
        exempt: this.props?.deductionRowEdit?.exempt?.replaceAll(" ","")?.split(",")?.map(x=>({label:x, value:x})) || "",
        deductionType: this.props?.deductionRowEdit?.deductionType.trim() || "",
        deductionCriteria: this.props?.deductionRowEdit?.deductionCri|| "",
        amount: this.props?.deductionRowEdit != null && (this.props?.deductionRowEdit?.amount != null || this.props?.deductionRowEdit?.amount !== "") ? GetAmountType(this.props?.deductionRowEdit?.deductionCri|| "") === "percentage" ? Utils.currencyFormatWithOutdoller(this.props?.deductionRowEdit?.amount || "") +"%" : Utils.currencyFormat(this.props?.deductionRowEdit?.amount || "") : "" ,
        payeeName: this.props?.deductionRowEdit?.payName || "",
        street: this.props?.deductionRowEdit?.street || "",
        // country: this.props?.deductionRowEdit?.country || "",
        state: this.props?.deductionRowEdit?.state || "",
        city: this.props?.deductionRowEdit?.city || "",
        zip: this.props?.deductionRowEdit?.zip || "",
        showFields:this.props?.deductionRowEdit?.payName || this.props?.deductionRowEdit?.street  || this.props?.deductionRowEdit?.state || this.props?.deductionRowEdit?.city  || this.props?.deductionRowEdit?.zip  ? true : false,
       
       
      },
      deductions: [],
      deductionFilters: [],
      exemptList:[],
      deductionName: "",
      errors: {},
      isDataSaving: false,
      isFormNotValid: true,
      inputPosition: 0,
      hotelsLoaded: false,
      isChange:false
    
    };
  }



  componentDidMount = () => {
    let _exemptList:any[]=StaticArrays.exemptList
    const _deductionFormData=this.state.deductionFormData
    _deductionFormData.exempt= _deductionFormData.exempt ? _deductionFormData.exempt?.filter(x => x.value !== "").map(x =>({...x,isChecked:true}) ) :[]
    
    const _exempt=this.props?.deductionRowEdit?.exempt?.replaceAll(" ","")?.split(",")
    _exemptList=_exemptList.map(x=>({description:x.label, value:x.label,isChecked:_exempt ? _exempt?.includes(x.value): false}))
   
    _exemptList=_.sortBy(_exemptList,[function(o) { return o.description; }])
    this.setState({exemptList:_exemptList,deductionFormData:_deductionFormData},()=>{
   
   
    if(this.props?.deductionRowEdit)
    {
      this.validateDeductionFormSchema()
    }
   })


   
   
  };


  saveDeductionPopUp=()=>{
    const oldData=this.props?.deductionRowEdit
    const data=_.cloneDeep(this.state.deductionFormData)
    const amountType=this.getAmountType(data.deductionCriteria)
    if(data.amount)
    {
      if(amountType === "dollar")
      {
        data.amount=Utils.removecurrencyFormat(data.amount)
      }
      else{
        data.amount= data.amount.toString().replaceAll(",", '').replace('%', '');
      }
    }

    
    
    if (
      (oldData?.taxType?.trim() !== "" && data?.taxType.trim() !== oldData?.taxType?.trim()) ||
      (oldData?.exempt?.trim() !== "" && data?.exempt.map(x => x.value).join(",").trim() !== oldData?.exempt?.replaceAll(" ","")?.trim()) ||
      (oldData?.deductionName?.trim() !== "" && data?.deductionName.trim() !== oldData?.deductionName?.trim()) ||
      (oldData?.deductionCri.trim() !== "" && data.deductionCriteria.trim() !== oldData?.deductionCri.trim()) ||
      (oldData?.amount !== "" && data.amount != oldData?.amount) ||
      (oldData?.payName?.trim() !== "" && data.payeeName.trim() !== oldData?.payName?.trim())
    ) {
      confirmAlert({
        // title: "",
        message:
        'Would you like to apply this change to all existing employees that are using this deduction? This change will be irreversible. Select OK to change the amount for all users, otherwise select Cancel',
        buttons: [
            {
                label: "Ok",
                onClick: () => this.saveDeduction(),
            },
            {
                label: "Cancel",
                onClick: () => {},
            },
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
    });
    }
    else{
      this.saveDeduction()
    }
 
  
}

  saveDeduction = () => {

   


    const data=this.state.deductionFormData
    const amountType=this.getAmountType(data.deductionCriteria)
    if(data.amount)
    {
      if(amountType === "dollar")
      {
        data.amount=Utils.removecurrencyFormat(data.amount)
      }
      else{
        data.amount= data.amount.toString().replaceAll(",", '').replace('%', '');
      }
    }
    
    const request: any = {
    
      DeductionName:data.deductionName,
      TaxType: data.taxType,
      Exempt: data.exempt.map(x => x.value).join(", "),
      DeductionType: data.deductionType,
      DeductionCri: data.deductionCriteria,
      Amount: data.amount,
      AmountType: "dollar",
      PayName: data.payeeName,
      City: data.city,
      State: data.state,
      Zip: data.zip,
      Street: data.street,
    
    } as any;

    
    if(this.props?.deductionRowEdit?.uniqueno > 0){
      request.uniqueno=this.props?.deductionRowEdit.uniqueno;
      request.isDeleted=this.props?.deductionRowEdit.isDeleted
    }
    this.setState({ isDataSaving: true });
    DeductionCriteria.SaveDeductionCriteria(request).then(result => {
      this.setState({ isDataSaving: false });
      if (result?.message === "Success") {
        toast.success(
          this.props?.deductionRowEdit > 0
            ? "Deduction Updated Successfully"
            : "Deduction Saved Successfully",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addDeduction",
          }
        );
        this.hideModal(true);
      } else {
        Utils.toastError(result?.result, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "addDeduction",
        });
      }
    }).catch(error => {
      this.setState({ isDataSaving: false });
      Utils.toastError(error?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "addDeduction",
      });
    })

 
  };

  onSearchChange = (value) => {
    const deductionFilters = this.state?.deductions?.filter((x) =>
      x?.deductionName?.toString()?.toLowerCase()?.includes(value)
    );
    this.setState({ deductionFilters });
  };

  filterBy = () => true;

  onFieldChange = (event, inputPosition) => {
    const { deductionFormData } = this.state;
    let value = event.target.value;
    const fieldName = event.target.name;
    if((fieldName==="amount" ) &&  (value?.toString()?.split('.')[0]?.length >10 ||  value?.toString()?.split('.')[1]?.length >2)) {
      return;
    
    }

    if (fieldName === "deductionName" || fieldName === "payeeName" ||  fieldName === "city" || fieldName ==="state" || fieldName ==="street") {
      value = value?.toString()?.trimLeft();
    
    }
    else if(fieldName === "amount"){
  
      value = value?.toString()?.trim()?.replace(/[^0-9\.\+]+/gi, "");
       
  
       //maxlength for Amount 
    if ( !value?.toString()?.includes(".") && value?.toString()?.length > 10) {
      return;
    }

    if ( value?.toString()?.split(".")[0]?.length > 10) {
      return;
    }
    if ( value?.toString()?.split(".")[1]?.length > 2) {
      return;
    }
    if (value?.toString()?.split(".")[1]?.length > 2 && value) {
         value= +value >= 0 ? Number(value)?.toFixed(2) : value;

    }

    
    }
    else if(fieldName === "zip"){
      // value = value?.toString()?.trim()?.replace(/[^0-9\+]+/gi, "");
    }
    else if(fieldName === "deductionCriteria"){
     const selectedAmountType= this.getAmountType(value)
     const previousAmountType= this.getAmountType()

     if(previousAmountType){
      if(selectedAmountType !== previousAmountType)
      {
        deductionFormData.amount=""
      }
     }
     else{
       deductionFormData.amount=""
     }
    }
    


    if(fieldName === "showFields" && !value){
      deductionFormData.city ="";
      deductionFormData.payeeName ="";
      deductionFormData.state ="";
      deductionFormData.street ="";
      deductionFormData.zip ="";
    }
    


    deductionFormData[fieldName] = value;
    if(inputPosition> this.state.inputPosition) 
    {
      this.setState({inputPosition});
    }
    this.setState({ deductionFormData }, () =>
      this.validateDeductionFormSchema()
    );
  };
  componentWillUnmount=()=> {
    // setTimeout(()=> {
    //   toast.dismiss();
    // },1000)
  }
 
  validateDeductionFormSchema = () => {
    const valid = Joi.validate(
      this.state.deductionFormData,
      this.deductionFormSchema,
      {
        abortEarly: false,
      }
    );

    let newErrorObject = {};
    if (valid.error) {
      valid.error.details.forEach((err) => {
        newErrorObject[err.path.join(".")] = err.message;
      });
    }

    // console.log(newErrorObject)
    // const errorsKeys=Object.keys(newErrorObject)
    // const conditionalErrorKeys=["city","state","street","zip"]

    // if(!this.state.deductionFormData.showFields){
    //  if( _.isEqual(_.sortBy(conditionalErrorKeys), _.sortBy(errorsKeys)))
    //   {
    //     newErrorObject={}
    //   }
    // }
    // console.log({conditionalErrorKeys,errorsKeys})
    


    if (Object.keys(newErrorObject).length === 0) {
      this.setState({ isFormNotValid: false });
    } else {
      this.setState({ isFormNotValid: true });
    }

  let _isChange=this.state.isChange
    if(this.props?.deductionRowEdit){
          //compare oldData and new Data
          const oldData=this.props?.deductionRowEdit
          const data=_.cloneDeep(this.state.deductionFormData) 
          const amountType=this.getAmountType(data.deductionCriteria)
          if(data.amount)
          {
            if(amountType === "dollar")
            {
              data.amount=Utils.removecurrencyFormat(data.amount)
            }
            else{
              data.amount= data.amount.toString().replaceAll(",", '').replace('%', '');
            }
          }
          if(data?.exempt){
            data.exempt =  data?.exempt.length  > 0 ?  data?.exempt?.map(x => x.value).join(",").trim() :""
          }
          if(oldData?.payName === null){
            oldData.payName =""
          }
          if(oldData?.city === null){
            oldData.city =""
          }
          if(oldData?.state === null){
            oldData.state =""
          }
          if(oldData?.street === null){
            oldData.street =""
          }
          if(oldData?.zip === null){
            oldData.zip =""
          }
        

          if(
          (oldData?.deductionName?.trim() !== "" && data?.deductionName.trim() !== oldData?.deductionName?.trim()) ||
          (oldData?.taxType?.trim() !== "" && data?.taxType.trim() !== oldData?.taxType?.trim()) ||
          (oldData?.exempt?.trim() !== "" && data?.exempt  !== oldData?.exempt?.replaceAll(" ","")?.trim()) ||
          (oldData?.deductionType.trim() !== "" && data.deductionType.trim() !== oldData?.deductionType.trim()) ||
          (oldData?.deductionCri.trim() !== "" && data.deductionCriteria.trim() !== oldData?.deductionCri.trim()) ||
          (oldData?.amount != "" && data.amount != oldData?.amount) ||
          (oldData?.payName?.trim() !== "" && data.payeeName.trim() !== oldData?.payName?.trim()) ||
          (oldData?.city?.trim() !== "" && data.city.trim() !== oldData?.city?.trim()) ||
          (oldData?.state?.trim() !== "" && data.state.trim() !== oldData?.state?.trim()) ||
          (oldData?.street?.trim() !== "" && data.street.trim() !== oldData?.street?.trim()) ||
          (oldData?.zip?.trim() !== "" && data.zip.trim() !== oldData?.zip?.trim()))
          {
            _isChange=true
          }
          else{
            _isChange=false
          }
    }
    
    this.setState({ errors: newErrorObject, isChange:_isChange });

    
    return newErrorObject;
  };

  handleUnsavedChangesModal=()=>{
    if(this.state.isChange)
    {
      confirmAlert({
        title: "Unsaved confirmation?",
        message:
          "You have unsaved changes on this modal. Do you want to leave this modal and discard your changes?",
        buttons: [
          {
            label: "Yes",
            onClick: () => this.hideModal(),
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    }
    else{
      this.hideModal()
    }
   
  }

  validationOnClick = (inputPosition) => {
    if(inputPosition> this.state.inputPosition) 
    {
      this.setState({ inputPosition }, () => this.validateDeductionFormSchema());
    }
  };

  handlePercentageOnFocus = (event, index) => {
 
    let newval = (!event.target.value) ?
    "" : event.target.value.toString().replaceAll(",", '').replace('%', '');
  const { deductionFormData } = this.state;
  const _deductionFormData = _.cloneDeep(deductionFormData);
  _deductionFormData[index] = newval
  this.setState({ deductionFormData: _deductionFormData });
  }

  handlePercentageOnBlur  = (event, index) => {
    const { deductionFormData } = this.state;
    const _deductionFormData = _.cloneDeep(deductionFormData);
    if (!isNaN(event.target.value)) {
      let fieldValue = !event.target.value ? "" : Utils.currencyFormatWithOutdoller(_deductionFormData[index]) +"%";
      _deductionFormData[index] = fieldValue;
    }
    this.setState({ deductionFormData: _deductionFormData });
  }



  handleCurrencyOnFocus = (event, index) => {
    let newval = (!event.target.value) ?
      "" : Utils.removecurrencyFormat(event.target.value);
    const { deductionFormData } = this.state;
    const _deductionFormData = _.cloneDeep(deductionFormData);
    _deductionFormData[index] = newval
    this.setState({ deductionFormData: _deductionFormData });
  }




  handleCurrencyOnBlur = (event, index) => {
    const { deductionFormData } = this.state;
    const _deductionFormData = _.cloneDeep(deductionFormData);
    if (!isNaN(event.target.value)) {
      let fieldValue = !event.target.value ? "" : Utils.currencyFormat(_deductionFormData[index]);
      _deductionFormData[index] = fieldValue;
    }
    this.setState({ deductionFormData: _deductionFormData });
  }

  getFieldError = (fieldName: string, fieldPosition: number) => {
    const { errors, inputPosition } = this.state;

    return (
      <>
        {Object.keys(errors).length > 0 && fieldPosition <= inputPosition && (
          <span className="validation-message">{errors[fieldName]}</span>
        )}
      </>
    );
  };
 
  hideModal = (isReload = false) => {
    const deductionFormData = {
      uniqueno: this.props?.deductionRowEdit?.uniqueno || 0,
      status: this.props?.deductionRowEdit?.status || "Active",
      deductionName: this.props?.deductionRowEdit?.deductionName || "",
      taxType: this.props?.deductionRowEdit?.taxType || "",
      exempt:[],
      deductionType: this.props?.deductionRowEdit?.deductionType || "",
      deductionCriteria: this.props?.deductionRowEdit?.deductionCriteria || "",
      amount: this.props?.deductionRowEdit?.amount || 0,
      // amount2: this.props?.deductionRowEdit?.amount || 0,
      payeeName: this.props?.deductionRowEdit?.payeeName || "",
      street: this.props?.deductionRowEdit?.street || "",
     
    };
    this.props?.hideDeductionModal(isReload);
    this.setState({ deductionFormData, isFormNotValid: true });
  };



  //    filterBy = () => {
  //     if(!props?.defaultValue && selectedValue?.length > 0) {
  //     typeahead?.current?.clear()
  //  }
  handleStatusChange=()=>{
    if(this.props.deductionRowEdit.isDeleted !== 1)
    {
      confirmAlert({
        title: "Deactivate Deduction",
        message:
            "Are you sure you would like to Deactivate this Deduction? Deactivating will affect all users with this deduction. ",
        buttons: [
            {
                label: "Yes",
                onClick: () => this.deactivateDeduction(),
            },
            {
                label: "No",
                onClick: () => {},
            },
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
    });
    }
    else{
      confirmAlert({
        title: "Reactivate Deduction",
        message:
            "Are you sure you would like to Reactivate this Deduction? Reactivating will affect all users with this deduction. ",
        buttons: [
            {
                label: "Yes",
                onClick: () => this.reactivateDeduction(),
            },
            {
                label: "No",
                onClick: () => {},
            },
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
    });
    }
   
  }

  reactivateDeduction=()=>{

    const request: any = {
     ...this.props.deductionRowEdit,
     isDeleted: 0
     
    } as any;
    this.setState({ isDataSaving: true });
    DeductionCriteria.SaveDeductionCriteria(request).then(result => {
      this.setState({ isDataSaving: false });
      if (result?.message === "Success") {
        toast.success(
         "Deduction Reactivated Successfully",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addDeduction",
          }
        );
        this.hideModal(true);
      } else {
        Utils.toastError(result?.result, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "addDeduction",
        });
      }
    }).catch(error => {
      this.setState({ isDataSaving: false });
      Utils.toastError(error?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "addDeduction",
      });
    })

  }

  deactivateDeduction =()=>{
 
    const request: any = {
      ...this.props.deductionRowEdit,    
    } as any;

    this.setState({ isDataSaving: true });
    DeductionCriteria.DeleteDeductionCriteria(request.uniqueno).then(result => {
      this.setState({ isDataSaving: false });
      if (result?.message === "Success") {
        toast.success(
       "Deduction Deactivated Successfully",
         
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addDeduction",
          }
        );
        this.hideModal(true);
      } else {
        Utils.toastError(result?.result, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "addDeduction",
        });
      }
    }).catch(error => {
      this.setState({ isDataSaving: false });
      Utils.toastError(error?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "addDeduction",
      });
    })
  }

  getAmountType=(ddCriteria=null)=>{
    const val=ddCriteria ? ddCriteria : this.state.deductionFormData.deductionCriteria
    if(!val){
      return false
    }
    
    const isDollarValues=['Fixed Amount','Amount ($) Per Hour','Amount ($) per Month','Direct Entry']
    if(isDollarValues.includes(val)){
       return "dollar"
    }else{
      return "percentage"
    }
  }

  

  render() {
    const {
      deductionFormData: {
        deductionName,
        taxType,
        exempt,
        deductionType,
        deductionCriteria,
        amount,
     
        payeeName,
        street,
        // country,
        state,
        city,
        zip,
        showFields
       
      },
    
      deductions,
      deductionFilters,
      errors,
      isDataSaving,
      isFormNotValid,
      inputPosition,
    } = this.state;
    const { showDeductionModal } = this.props;
    console.log(this.state.exemptList)
    return (
      <>
        <div className="">
        
          <Modal
            className="add-deduction-modal payroll-deduction-setting-modal"
            show={showDeductionModal}
            onHide={this.handleUnsavedChangesModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={this.props?.showDeductionModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {this.props?.deductionRowEdit
                  ? "Edit Deduction"
                  : "Add Deduction"}{" "}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-section">
                <div className="body-section">
                  <Form.Group controlId="deductionName" className="d-flex">
                    <Col
                      sm={"6"}
                      className={`${
                        errors?.deductionName && inputPosition >= 1
                          ? "validation-error"
                          : ""
                      }`}
                      onBlur={() => this.validationOnClick(1)}
                    >
                      <Form.Label>Deduction Name</Form.Label>

                      <OverlayTrigger
                        placement="bottom"
                        defaultShow={false}
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <Tooltip id={"Deduction-Name"} className={(deductionName.length <= 26) ? "tootltip-hide": ""}>
                                {deductionName}
                            </Tooltip> 
                        }
                        >
                        <input
                        maxLength={100}
                        type="text"
                        className="form-control"
                        id="txtAmount"
                        name="deductionName"
                        autoComplete="deductionName"
                        value={deductionName}
                        
                        onChange={(e) => this.onFieldChange(e, 1)}
                      />

                      </OverlayTrigger>
                    
                      
                      {this.getFieldError("deductionName", 1)}
                    </Col>

                    <Col
                      sm={"6"}
                      className={`mb-0 ${
                        errors?.taxType && inputPosition >= 2
                          ? "validation-error"
                          : ""
                      }`}
                      onBlur={() => this.validationOnClick(2)}
                    >
                      <Form.Label>Tax Type</Form.Label>
                      <DropDownList
                          placeHolder={"Select Tax Type"}
                          data={TAXTYPES}
                          defaultValue={taxType}
                          isSearchRequired={false}
                          label={"label"}
                          value={"value"}
                          onDropDownChange={(item) => {
                            if (!item) {
                              return;
                            }

                            this.onFieldChange(
                              Utils.BuildCustomEventObject("taxType", item.value),
                              2
                            );

                            let _exempt=this.state.exemptList.map(x =>({...x, isChecked:item.value  === "Pre Tax"}))
                           
                
                            let _data=this.state.deductionFormData
                            _data.exempt=_exempt
                            this.setState({deductionFormData:_data, exemptList:_exempt})
                           
                            // else{
                            //   let _exempt=this.state.exemptList.map(x =>({...x, isChecked:false}))
                            //   this.setState({exempt:_exempt, exemptList:_exempt})
                            // }
                          }}
                          selectedItem={[
                            ...[{ value: "", label: "Select Tax Type" }],
                            ...TAXTYPES,
                          ].find((r: any) => r?.value === taxType)}
                        />
                      
                      {this.getFieldError("taxType", 2)}
                    </Col>
                  </Form.Group>

                  <Form.Group controlId="deductionType" className="d-flex">
                    
                    <Col
                      sm={"12"}
                      className={`mb-0 ${
                        errors?.exempt && inputPosition >= 3
                          ? "validation-error"
                          : ""
                      }`}
                      onBlur={() => this.validationOnClick(3)}
                    >
                      <Form.Label>Exempt Type</Form.Label>
                      <div className="ExemptTypeDropdown">

               
                      {this.state.exemptList.length > 0 && 
                      <div key="exemptList" id="exemptList" className="exemptList">
                        <ExemptTypeDropdown
                          key="exemptList"
                          showLength={StaticArrays.exemptList.length}
                          dataChange={()=>{}}
                          itemList={this.state.exemptList?.length > 0 ? this.state.exemptList : []}
                          updateMultiSelectItem={(item) => {
                            if (!item) {
                              return;
                            }

                            this.onFieldChange(
                              Utils.BuildCustomEventObject("exempt", item),
                              3
                            );
                             
                          }}
                          isSelectAll={true}
                          placeholder={"Select Exempt Type"}
                          filtertitle={"Select Exempt Type"}

                      />
                      </div> }

                      
                      </div>
                    
                     {/* {this.state.exemptList.length > 0 && <MultiSelectSearch
                            // ref={this.multiselectChild}
                            itemList={this.state.exemptList}
                            dataChange={()=>{}}
                            updateMultiSelectItem={(item) => {
                              if (!item) {
                                return;
                              }
                              
                              this.onFieldChange(
                                Utils.BuildCustomEventObject("exempt", item),
                                4
                              );
                            }}
                          
                            isSelectAll={true}
                            placeHolder={"Select Exempt Type"}
                            filtertitle={"Filter Exempt Type"}
                            pageName="payrollSettings"
                        
                          />} */}
                      {/* <input
                        type="text"
                        className="form-control"
                        id="txtdeductionAmount"
                        name="exempt"
                        autoComplete="txtdeductionAmount"
                        value={exempt}
                        
                        onChange={(e) => this.onFieldChange(e, 4)}
                      /> */}
                      {this.getFieldError("exempt", 3)}
                    </Col>
                  </Form.Group>

                  <Form.Group controlId="ExemptType" className="d-flex">
                    
                  <Col
                      sm={"6"}
                      className={`mb-0 ${
                        errors?.deductionType && inputPosition >= 4
                          ? "validation-error"
                          : ""
                      }`}
                      onBlur={() => this.validationOnClick(4)}
                    >
                      <Form.Label>Deduction Type</Form.Label>

                      <DropDownList
                          placeHolder={"Select Deduction Type"}
                          data={DEDUCTIONTYPES}
                          defaultValue={deductionType}
                          isSearchRequired={true}
                          label={"label"}
                          value={"value"}
                          onDropDownChange={(item) => {
                            if (!item) {
                              return;
                            }
                            this.onFieldChange(
                              Utils.BuildCustomEventObject("deductionType", item.value),
                              4
                            );
                          }}
                          selectedItem={[
                            ...[{ value: "", label: "Select Deduction Type" }],
                            ...DEDUCTIONTYPES,
                          ].find((r: any) => r?.value === deductionType)}
                        />
                     
                      {this.getFieldError("deductionType", 4)}
                    </Col>
                    <Col
                      sm={"6"}
                      className={`mb-0 deductionCriteriaInput ${
                        errors?.deductionCriteria && inputPosition >= 5
                          ? "validation-error"
                          : ""
                      }`}
                      onBlur={() => this.validationOnClick(5)}
                    >
                      <Form.Label>Deduction Criteria</Form.Label>
                      <DropDownList
                          placeHolder={"Select Deduction Criteria"}
                          data={DEDEDUCTIONCRITERIAS}
                          defaultValue={deductionCriteria}
                          isSearchRequired={true}
                          label={"label"}
                          value={"value"}
                          onDropDownChange={(item) => {
                            if (!item) {
                              return;
                            }
                            this.onFieldChange(
                              Utils.BuildCustomEventObject("deductionCriteria", item.value),
                              5
                            );
                          }}
                          selectedItem={[
                            ...[{ value: "", label: "Select Deduction Criteria" }],
                            ...DEDEDUCTIONCRITERIAS,
                          ].find((r: any) => r?.value === deductionCriteria)}
                        />
                     
                      {this.getFieldError("deductionCriteria", 5)}
                    </Col>
                  </Form.Group>

                  {/* <Form.Group controlId="deductionType" className="d-flex">
                    <Col
                      sm={"6"}
                      className={`mb-0 ${
                        errors?.deductionType && inputPosition >= 4
                          ? "validation-error"
                          : ""
                      }`}
                      onBlur={() => this.validationOnClick(4)}
                    >
                      <Form.Label>Deduction Type</Form.Label>
                      <input
                        type="text"
                        className="form-control"
                        id="txtAmount"
                        name="deductionType"
                        autoComplete="deductionType"
                        value={deductionType}
                        
                        onChange={(e) => this.onFieldChange(e, 4)}
                      />
                      {this.getFieldError("deductionType", 4)}
                    </Col>
                    
                  </Form.Group> */}

                  <Form.Group controlId="amount" className="d-flex">

                  <Col
                      sm={"6"}
                      className={`mb-0 ${
                        this.getAmountType() === "dollar" &&  errors?.amount && inputPosition >= 6
                          ? "validation-error"
                          : ""
                      }`}
                      onBlur={() => this.validationOnClick(6)}
                    >
                      <Form.Label>Amount ($) </Form.Label>
                      <input
                        type="text"
                        onKeyDown= {Utils.AllowOnlyPositiveNumnberInput}
                        className="form-control"
                        id="txtAmount"
                        name="amount"
                        disabled={ this.getAmountType() !== "dollar"}
                        autoComplete="txtAmount"
                        value={this.getAmountType() !== "dollar" ? "" :amount}
                        onChange={(e) => this.onFieldChange(e, 6)}
                        onBlur={(e)=>this.handleCurrencyOnBlur(e,"amount")}
                        onFocus={(e)=>this.handleCurrencyOnFocus(e,"amount")}
                      />
                      { this.getAmountType() === "dollar" &&this.getFieldError("amount", 6)}
                    </Col>


                    <Col
                      sm={"6"}
                      className={`mb-0 ${
                        this.getAmountType() === "percentage" && errors?.amount && inputPosition >= 7
                          ? "validation-error"
                          : ""
                      }`}
                      onBlur={() => this.validationOnClick(7)}
                    >
                      <Form.Label>Amount (%) </Form.Label>
                      <input
                        type="text"
                        onKeyDown= {Utils.AllowOnlyPositiveNumnberInput}
                        // onKeyDown={Utils.AllowOnlyPositiveNumnberInputWithoutDot}
                        className="form-control"
                        id="txtAmount"
                        name="amount"
                        autoComplete="txtAmount"
                        disabled={this.getAmountType() !== "percentage"}
                        value={this.getAmountType() !== "percentage" ? "": amount}
                        onChange={(e) => this.onFieldChange(e, 7)}
                        onBlur={(e)=>this.handlePercentageOnBlur(e,"amount")}
                        onFocus={(e)=>this.handlePercentageOnFocus(e,"amount")}
                      />
                      {  this.getAmountType() === "percentage" && this.getFieldError("amount", 7)}
                    </Col>

                    
                  </Form.Group>
                  <Form.Group controlId="payeeCheckbox">
                    <Col
                      sm={"12"}
                    >
                     
                     <Form.Check 
                        className="payeeCheckbox"
                        onChange={(e) =>  this.onFieldChange(
                          Utils.BuildCustomEventObject("showFields", e.target.checked),
                          7
                        )} 
                        type={"checkbox"}
                        id={`default`}
                        checked={showFields}
                        label={`Add Payee Name and Address`} />
                      
                     
                    </Col>
                  </Form.Group>
               {showFields &&  
                  <>
                  <Form.Group controlId="payeeName">
                    <Col
                      sm={"12"}
                      // onBlur={() => this.validationOnClick(8)}
                      // className={
                      //   errors?.payeeName && inputPosition >= 8
                      //     ? "validation-error"
                      //     : ""
                      // }
                    >
                      <Form.Label>Payee Name</Form.Label>
                      <OverlayTrigger
                        placement="bottom"
                        defaultShow={false}
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <Tooltip id="txtBankName" className={(payeeName.length <= 65) ? "tootltip-hide": ""}>
                                {payeeName}
                            </Tooltip> 
                        }
                        >
                      <input
                        type="text"
                        className="form-control"
                        id="txtBankName"
                        name="payeeName"
                        maxLength={100}
                        autoComplete="txtBankName"
                        value={payeeName}
                        onChange={(e) => this.onFieldChange(e, 8)}
                      />
                      </OverlayTrigger>
                      {/* {this.getFieldError("payeeName", 8)} */}
                    </Col>
                  </Form.Group>
                 
                  <Form.Group controlId="street">
                  
                     
                    <Col
                      sm={"12"}
                      onBlur={() => this.validationOnClick(9)}
                      className={
                        errors?.street && inputPosition >= 9
                          ? "validation-error"
                          : ""
                      }
                    >
                      <Form.Label>Address Line</Form.Label>
                      <OverlayTrigger
                        placement="bottom"
                        defaultShow={false}
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <Tooltip id="street" className={(street.length <= 65) ? "tootltip-hide": ""}>
                                {street}
                            </Tooltip> 
                        }
                        >
                        <input
                          maxLength={100}
                          type="text"
                          className="form-control"
                          id="street"
                          name="street"
                          autoComplete="street"
                          value={street}
                          onChange={(e) => this.onFieldChange(e, 9)}
                        />
                        </OverlayTrigger>
                      {this.getFieldError("street", 9)}
                    </Col>
                  </Form.Group>

                  <Form.Group controlId="countrystate" className="d-flex">
                    {/* <Col
                      sm={"6"}
                      onBlur={() => this.validationOnClick(9)}
                      className={
                        errors?.country && inputPosition >= 9
                          ? "validation-error"
                          : ""
                      }
                    >
                      <Form.Label>Country</Form.Label>
                      <input
                        type="text"
                        className="form-control"
                        id="txtcountry"
                        name="country"
                        
                        autoComplete="txtcountry"
                        value={country}
                        onChange={(e) => this.onFieldChange(e, 9)}
                      />
                      {this.getFieldError("country", 9)}
                    </Col> */}

                    <Col
                      sm={"6"}
                      onBlur={() => this.validationOnClick(10)}
                      className={
                        errors?.state && inputPosition >= 10
                          ? "validation-error"
                          : ""
                      }
                    >
                      <Form.Label>State</Form.Label>

                      <DropDownList
                        placeHolder={"Select State"}
                        data={_.sortBy(StaticArrays.userStates,'label')}
                        defaultValue={state}
                        isSearchRequired={true}
                        label={"label"}
                        value={"value"}
                        // disabled={
                        //   !userGeneralInfo?.hasEditContactInfo &&
                        //   !isNewUserAdded
                        // }
                        onDropDownChange={(item) => {
                          if (!item) {
                            return;
                          }
                          this.onFieldChange(
                            Utils.BuildCustomEventObject("state", item.value),
                            10
                          );
                        }}
                        selectedItem={[
                          ...[{ value: "", label: "Select State" }],
                          ...StaticArrays.userStates,
                        ].find((r: any) => r?.value === state) || {}}
                      /> 
                     
                      {this.getFieldError("state", 10)}
                    </Col>

                    <Col
                      sm={"6"}
                      onBlur={() => this.validationOnClick(11)}
                      className={
                        errors?.city && inputPosition >= 11
                          ? "validation-error"
                          : ""
                      }
                    >
                      <Form.Label>City</Form.Label>

                      <OverlayTrigger
                        placement="bottom"
                        defaultShow={false}
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <Tooltip id={`txtCity`} className={((city).length <= 28) ? "tootltip-hide": ""}>
                                {(city)}
                            </Tooltip> 
                        }
                        >
                      <input
                        maxLength={100}
                        type="text"
                        className="form-control"
                        id="txtCity"
                        name="city"
                        // autoComplete="txtCity"
                        autoComplete="off"
                        value={city}
                        onChange={(e) => this.onFieldChange(e, 11)}
                        
                      />
                      </OverlayTrigger>
                     
                      {this.getFieldError("city", 11)}
                    </Col>
                  </Form.Group>

                  <Form.Group controlId="cityzipcode" className="d-flex">
                    

                    <Col
                      sm={"12"}
                      onBlur={() => this.validationOnClick(12)}
                      className={
                        errors?.zip && inputPosition >= 12
                          ? "validation-error"
                          : ""
                      }
                    >
                      <Form.Label>Zip Code</Form.Label>
                      <input
                        maxLength={15}
                        type="text"
                        // onKeyDown={Utils.AllowOnlyPositiveNumnberInputWithoutDot}
                        className="form-control"
                        id="txtzipcode"
                        name="zip"
                        autoComplete="txtzipcode"
                        value={zip}
                        onChange={(e) => this.onFieldChange(e, 12)}
                      />
                      {this.getFieldError("zip", 12)}
                    </Col>
                  </Form.Group>
                  </>} 
                  

                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {this.props.deductionRowEdit && <Button
                className="btn-ghost deactivate-btn"
                onClick={() => this.handleStatusChange()}
              >
               {this.props.deductionRowEdit.isDeleted === 1 ? "Reactivate Deduction": "Deactivate Deduction" }
              </Button>}
              <Button
                disabled={isFormNotValid || isDataSaving}
                className="btn btn-primary"
                onClick={this.props?.deductionRowEdit?.uniqueno > 0 ? this.saveDeductionPopUp : this.saveDeduction}
              >
                {this.props.deductionRowEdit ? "Save Deduction" : "Add Deduction"}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}
