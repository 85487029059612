import React, { useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";

import { IFilterModalProps } from "../../../Contracts/IChangeLogFilterModal";
import OutsideClickHandler from "react-outside-click-handler";
import CalenderFilter from "./CalenderFilter";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";

const FilterModal = ({
  filterName,
  filterValue,
  popClassName,
  onFilterTextChange,
  removeFilter,
  hideFilterPopUp,
  onApplyFilter,
  filterInputType,
  startDate,
  endDate,
  handleDatesChange,
  validateDates,
  onFilterDropDownChange,
  dropDownProps,
  hideCrossAndClearFilters: isStrictChildView,
}: IFilterModalProps) => {
  const hidePopUp = () => {
    hideFilterPopUp();
  };

  const handleRemoveFilter = () => {
    if (removeFilter) {
      hideFilterPopUp();
      removeFilter();
    }
  };

  const handleApplyFilter = () => {
    if (validateDates()) {
      onApplyFilter();
      hidePopUp();
    }
  };
  return (
    <OutsideClickHandler
      onOutsideClick={() =>
        filterInputType === "date" ? () => {} : hidePopUp()
      }
    >
      <div className={popClassName}>
        <div className="header justify-content-between">
          <span className="title d-inline">Filter by {filterName}</span>{" "}
          <button className="close" onClick={() => hidePopUp()}>
            &times;
          </button>
        </div>

        <div className="body">
          {filterInputType === "date" && (
            <CalenderFilter
              key={popClassName}
              handleDatesChange={handleDatesChange}
              startDate={startDate}
              endDate={endDate}
            />
          )}

          {filterInputType === "text" && (
            <input
              className="form-control"
              type="text"
              value={filterValue}
              placeholder={`Enter ${filterName?.toString()?.toLowerCase()}`}
              onChange={(e) => onFilterTextChange(e)}
            />
          )}

          {filterInputType === "ddl" && (
            <Dropdown className="single-select">
              <Dropdown.Toggle id="Modal-ddl">
                <EllipsisWithTooltip placement="bottom">
                  {dropDownProps?.dropDownSelectedItem && (
                    <span
                      style={{
                        width: "95%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "block",
                      }}
                    >
                      {
                        dropDownProps?.dropDownSelectedItem[
                          (dropDownProps as any)?.dropDownItemLabelFieldName
                        ]
                      }
                    </span>
                  )}
                </EllipsisWithTooltip>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {/* {props?.isSearchRequired &&
             <div className="search-sec">
               <input
                 type="text"
                 className="form-control"
                 onChange={handleDropdownSearch}
                 placeholder={placeHolder}
                 value={inputValue}
                 ref={inputElement}
               />
             </div>
     } */}

                {(dropDownProps as any)?.ddlData?.length > 0 && (
                  <div className="list-area">
                    {(dropDownProps as any)?.ddlData.map(
                      (item: any, idx: any) => (
                        <EllipsisWithTooltip placement="bottom">
                          <Dropdown.Item
                            onClick={() =>
                              onFilterDropDownChange(
                                item,
                                (dropDownProps as any)
                                  .dropDownItemValueFieldName
                              )
                            }
                            disabled={item?.disabled}
                          >
                            {
                              item[
                                (dropDownProps as any)
                                  ?.dropDownItemLabelFieldName
                              ]
                            }
                          </Dropdown.Item>
                        </EllipsisWithTooltip>
                      )
                    )}
                  </div>
                )}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
        <div className="footer d-flex">
          {!isStrictChildView && removeFilter && (
            <button
              className="btn btn-primary btn-outline-primary mr-auto"
              onClick={() => handleRemoveFilter()}
            >
              Remove
            </button>
          )}
          <button className="btn btn-ghost ml-auto" onClick={() => hidePopUp()}>
            Cancel
          </button>
          <button className="btn btn-primary" onClick={handleApplyFilter}>
            Filter
          </button>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default FilterModal;
