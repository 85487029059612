import * as React from 'react';
import { Container, Dropdown, Form, Button, OverlayTrigger, Tooltip, Modal, Spinner } from "react-bootstrap";
import { SalesService } from "../../Common/Services/SalesService";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
  import { AgGridReact } from 'ag-grid-react';
  import { GridOptions } from "ag-grid-community";
  import 'ag-grid-community/dist/styles/ag-grid.css';
  import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
  import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
  import { DropDownSelect } from "./component/DropDownSelect";
import { FiChevronDown } from "react-icons/fi";
import "./sales.scss";
import { Utils } from '../../Common/Utilis';

export class Tasks extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            filterPriorityList: [
                { name: "Demo 1", eventKey: "demo1" },
                { name: "Demo 2", eventKey: "demo2" },
                { name: "Demo 3", eventKey: "demo3" },
            ],
            selectedAccount: props.selectedAccount,
            upcomingTasksData: [],
        };
    }

    componentDidMount() {
        this.GetSalesTaskById(); 
    }

    GetSalesTaskById = () => {
        // debugger;
        let request: any = {};
        request.ParentID = this.state.selectedAccount.accountID;
        request.PageName = "AccountTaskContainer";
        request.isCompleted = "No";
        SalesService.GetSalesTaskById(request)
            .then(async (result: any | null) => {
                // debugger;
                if (result != null) {
                    //  console.log(result);                    
                    this.setState({ upcomingTasksData: result.result })
                }
                resolve();
            })
            .catch((err: any) => {
                Utils.toastError("Something went wrong. Failed to fetch Market Segment.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "overview-11b",
                });
                reject();
            });
    }

    
    // onGridReady = (params: any) => {
    //     let rowData = [{taskName: "Send Documents to Emily", dueDate: "09/05/23", type: "Email", assignedTo : "Karen James", priority : "Medium"}]
    //     this.setState({ addressToggle: !this.state.addressToggle });
    // }
    getAgGridOptions = () => {
        const gridOptions: GridOptions = {
            //headerHeight: 48,
            frameworkComponents: {
                RenderPriority: this.RenderPriority,
            },
        };
        return gridOptions;
    };
    RenderPriority(params: any) {
        debugger;
        return (
            <div className='d-flex'>
                {params?.data?.priority == "Medium" && (
                    <div className="circle orange">
                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                            <circle cx="4" cy="4" r="4" />
                        </svg>
                    </div>
                )}
                {params?.data?.priority == "Low" && (
                    <div className="circle blue">
                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                            <circle cx="4" cy="4" r="4" />
                        </svg>
                    </div>
                )}
                <div>{params?.data?.priority}</div>
            </div>
        );
    }
    onFilterDropdownOpen = (event, field) => {
       
    }
    handleFilterInputChange = (event, field) => {
        
    }
    handleSelectOrClearAll = (type, field) => {

    }
    render() {
        
        const columnDefs = [
            {
                headerName: "Task Name",
                field: "taskName",
            },{
                headerName: "Due Date",
                field: "dueDate",
            },{
                headerName: "Type",
                field: "type",
            },{
                headerName: "Assigned To",
                field: "assignedUser",
            },{
                headerName: "Priority",
                field: "priority",
                cellRenderer: "RenderPriority",
            },
        ]
        return (
            <div className='tasks list-table ag-grid-tbl'>
                <div className="search-header d-flex">
                    <div className="salesSearch mrgn-right-8">
                        <input
                            id="serachInput"
                            key="serachInput"
                            className="form-control"
                            style={{ backgroundColor: 'white' }}
                            type="text"
                            //onChange={this.handleSearch}
                            placeholder={"Search..."}
                            value={this.state.searchvalue}
                            autoComplete="off"
                        />
                    </div>
                    <div className='priority mrgn-right-8'>
                        <Dropdown className="dropDownSelector priority" onToggle={(event: any) => this.onFilterDropdownOpen(event, "priority")}>
                            <Dropdown.Toggle className='d-flex align-items-center'>
                                <div className='selected-val'><EllipsisWithTooltip placement="bottom">{"Priority"}</EllipsisWithTooltip></div>
                                <div className="drop-arrow"><FiChevronDown /></div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <div className="search-sec">
                                    <input type="text" className="form-control"
                                        onChange={(e: any) => this.handleFilterInputChange(e, "priority")}
                                        placeholder={"Search..."}
                                        value={this.state.filterSearchInput}
                                    />
                                </div>
                                <div className="list-area">
                                    {this.state.filterPriorityList.map((items, index) => (
                                        <Dropdown.Item
                                            eventKey={items.eventKey}
                                            key={index}
                                        //onClick={() => this.filterAccountsList(items, "priority", "")}
                                        >
                                            {items.name}
                                        </Dropdown.Item>
                                    ))}
                                </div>
                                <div className="footer d-flex">
                                    <button id="clearAll" type="button" className="btn wht-bg txt-green"
                                        onClick={() => this.handleSelectOrClearAll("clearAll", "priority")}
                                        //disabled={this.state.filterSelectedOwner.toLowerCase() === "priority"}
                                    >
                                        Clear Filter
                                    </button>
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className='type mr-auto'>
                        <Dropdown className="dropDownSelector type" onToggle={(event: any) => this.onFilterDropdownOpen(event, "type")}>
                            <Dropdown.Toggle className='d-flex align-items-center'>
                                <div className='selected-val'><EllipsisWithTooltip placement="bottom">{"Type"}</EllipsisWithTooltip></div>
                                <div className="drop-arrow"><FiChevronDown /></div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <div className="search-sec">
                                    <input type="text" className="form-control"
                                        onChange={(e: any) => this.handleFilterInputChange(e, "type")}
                                        placeholder={"Search..."}
                                        value={this.state.filterSearchInput}
                                    />
                                </div>
                                <div className="list-area">
                                    {this.state.filterPriorityList.map((items, index) => (
                                        <Dropdown.Item
                                            eventKey={items.eventKey}
                                            key={index}
                                        //onClick={() => this.filterAccountsList(items, "priority", "")}
                                        >
                                            {items.name}
                                        </Dropdown.Item>
                                    ))}
                                </div>
                                <div className="footer d-flex">
                                    <button id="clearAll" type="button" className="btn wht-bg txt-green"
                                        onClick={() => this.handleSelectOrClearAll("clearAll", "type")}
                                        //disabled={this.state.filterSelectedOwner.toLowerCase() === "priority"}
                                    >
                                        Clear Filter
                                    </button>
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <Button variant="primary" type="button" className="ml-auto height-32">
                        <div className='d-flex align-items-center'>
                            <div>Add Task </div>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.0002 0.700195C6.54654 0.700195 6.17846 1.06828 6.17846 1.52193V6.17846H1.52193C1.06828 6.17846 0.700195 6.54654 0.700195 7.0002C0.700195 7.45385 1.06828 7.82193 1.52193 7.82193H6.17846V12.4785C6.17846 12.9321 6.54654 13.3002 7.0002 13.3002C7.45385 13.3002 7.82193 12.9321 7.82193 12.4785V7.82193H12.4785C12.9321 7.82193 13.3002 7.45385 13.3002 7.0002C13.3002 6.54654 12.9321 6.17846 12.4785 6.17846H7.82193V1.52193C7.82193 1.06828 7.45385 0.700195 7.0002 0.700195Z" />
                            </svg>
                        </div>
                    </Button>
                </div>

                <div className="scroll-area ag-theme-alpine">
                        <AgGridReact
                            rowData={this.state.upcomingTasksData}
                            columnDefs={columnDefs}
                            gridOptions={this.getAgGridOptions()}
                        //suppressDragLeaveHidesColumns={true}
                        //onGridReady={this.onGridReady}
                        />
                </div>
            </div>
        );
    }
}