import React from "react";
import { Button, ButtonGroup, Dropdown, Form, Row } from "react-bootstrap";
import moment from "moment";
import OutsideClickHandler from "react-outside-click-handler";
import Calendar from "react-calendar";
import { Utils } from "../../../Common/Utilis";

const formatDate = (value: any = false, selectedDate) => {
  value = value?.toString()?.replace(/[^0-9\/]+/gi, "");
  const formmatedDate = Utils.AddSlashINInputDateFormat(
    value,
    selectedDate,
    true,
    2,
    false
  );

  if (formmatedDate === "invalid") {
    return;
  } else {
    return formmatedDate;
  }
};

const oneYearBack = () => {
  let d = new Date();
  d.setFullYear(d.getFullYear() - 1);
  return d;
};
const minDateDefault = oneYearBack();
export class CalendarSelector extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      currentIndex: -1,
      calenderShow: false,
      selectedDate: "",
      minimumDate: "",
    };
  }

  calenderHide = () => {
    if (this.state.selectedDate.length !== 8) {
      const { defaultValue, onChange } = this.props;
      const value = defaultValue
        ? moment(defaultValue).format("MM/DD/YY")
        : moment().subtract(1, "days").format("MM/DD/YY");
      this.setState({ calenderShow: false, selectedDate: value }, () => {
        onChange(value);
      });
    } else {
      this.setState({ calenderShow: false });
    }
  };

  calenderDisplay = () => {
    this.setState({ calenderShow: true });
  };

  componentDidMount() {
    const { defaultValue, minDate } = this.props;

    this.setState({ minimumDate: minDate ? minDate : minDateDefault }, () => {
      const value = defaultValue
        ? moment(defaultValue).format("MM/DD/YY")
        : moment().subtract(1, "days").format("MM/DD/YY");
      this.setSelectedDate(value);
    });
  }

  // componentDidUpdate = (prevProps) => {
  //   if(prevProps.defaultValue != this.props.defaultValue){
  //     const {defaultValue, onChange}=this.props
  //     const value=defaultValue ? moment(defaultValue).format("MM/DD/YY") : moment().subtract(1, 'days').format("MM/DD/YY")
  //     this.setSelectedDate(value);
  //   }
  // };

  setSelectedDate = (value) => {
    const { onChange } = this.props;

    const formmattedDate = formatDate(value, this.state.selectedDate);
    const minDateFormatted = moment(this.state.minimumDate).format("MM/DD/YY");
    const maxDateFormatted = this.props?.maxDate
      ? this.props?.maxDate
      : moment().subtract(1, "days").format("MM/DD/YYYYY");
    if (!formmattedDate) return;

    if (formmattedDate?.length === 8) {
      if (
        !moment(minDateFormatted)?.isSameOrBefore(formmattedDate) ||
        !moment(maxDateFormatted)?.isSameOrAfter(formmattedDate)
      ) {
        return;
      }
    }
    this.setState(
      {
        selectedDate: formmattedDate,
      },
      () => {
        onChange(formmattedDate);
      }
    );
  };

  handleDateChange = (event, _inputPosition, isBlur = false) => {
    let value = event.target.value;

    const _formattedDate = Utils.AddSlashINInputDateFormat(
      value,
      this.state.selectedDate,
      true,
      2,
      isBlur
    );
    if (_formattedDate === "invalid") {
      return;
    } else {
      value = _formattedDate;
    }
    const { onChange } = this.props;
    const { selectedDate } = this.state;
    const minDateFormatted = moment(this.state.minimumDate).format("MM/DD/YY");

    if (value.length === 8) {
      if (
        !moment(minDateFormatted)?.isSameOrBefore(value) ||
        !moment(new Date())?.isSameOrAfter(value)
      ) {
        return;
      }
    }

    this.setState(
      {
        selectedDate: value,
      },
      () => {
        onChange(value);
      }
    );
  };

  handleNextDate = () => {
    const { selectedDate } = this.state;
    const value = moment(selectedDate).add(1, "days").format("MM/DD/YY");
    this.setSelectedDate(value);
  };

  handlePreviousDate = () => {
    const { selectedDate } = this.state;
    const value = moment(selectedDate).subtract(1, "days").format("MM/DD/YY");
    this.setSelectedDate(value);
  };

  render() {
    const { currentIndex, calenderShow, selectedDate } = this.state;

    return (
      <div className="day-year-picker-container">
        {/* <label>Date</label> */}
        <ButtonGroup aria-label="Basic example">
          <Button onClick={this.handlePreviousDate}>
            <svg
              width="6"
              height="9"
              viewBox="0 0 6 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.98314 8.414L0.879001 4.207L4.98314 0L6 1.04235L2.91273 4.207L6 7.37165L4.98314 8.414Z"
                fill="#1D1E20"
              />
            </svg>
          </Button>

          <Button className="calendar-cont-btn">
            <div className="calender-container" onClick={this.calenderDisplay}>
              <div
                style={{ display: "flex" }}
                className={
                  calenderShow ? "calender-display open" : "calender-display"
                }
              >
                <svg
                  onClick={this.calenderDisplay}
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z" />
                  <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z" />
                </svg>
                <input
                  onFocus={this.calenderDisplay}
                  onClick={this.calenderDisplay}
                  type="text"
                  placeholder="MM/DD/YY"
                  maxLength={8}
                  name="selectedDate"
                  onInput={(e) => {
                    let value = e.currentTarget.value;
                    value = value?.toString()?.replace(/[^0-9\/]+/gi, "");

                    this.handleDateChange(
                      Utils.BuildCustomEventObject("selectedDate", value),
                      6
                    );
                  }}
                  onBlur={(e) => {
                    let value = e.currentTarget.value;
                    value = value?.toString()?.replace(/[^0-9\/]+/gi, "");

                    this.handleDateChange(
                      Utils.BuildCustomEventObject("selectedDate", value),
                      6,
                      true
                    );
                  }}
                  autoComplete="selectedDate"
                  value={selectedDate}
                  className="form-control single-date-pick"
                />
              </div>
              {calenderShow && (
                <div className="calender">
                  <OutsideClickHandler onOutsideClick={this.calenderHide}>
                    <Calendar
                      calendarType={"US"}
                      onChange={(date) => {
                        const value = moment(date).format("MM/DD/YY");

                        this.handleDateChange(
                          Utils.BuildCustomEventObject("selectedDate", value),
                          6
                        );
                        this.calenderHide();
                      }}
                      value={
                        selectedDate
                          ? moment(selectedDate)?.format("MM/DD/YY") ===
                            "Invalid date"
                            ? new Date()
                            : new Date(selectedDate)
                          : new Date()
                      }
                      maxDate={
                        this.props?.maxDate
                          ? this.props?.maxDate
                          : new Date(
                            moment().subtract(1, "days").format("MM/DD/YYYYY")
                          )
                      }
                      minDate={
                        !this.props?.allowAllBackDates
                          ? this.state.minimumDate
                          : null
                      }
                    />
                  </OutsideClickHandler>
                </div>
              )}
            </div>

            {/* <Dropdown>
              <Dropdown.Toggle id="ddlPeriod" className={this.props?.showBlueBg ? "selected-blue-bg" : ""}>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M4.66663 7.33325H5.99996V8.66659H4.66663V7.33325ZM4.66663 9.99992H5.99996V11.3333H4.66663V9.99992ZM7.33329 7.33325H8.66663V8.66659H7.33329V7.33325ZM7.33329 9.99992H8.66663V11.3333H7.33329V9.99992ZM9.99996 7.33325H11.3333V8.66659H9.99996V7.33325ZM9.99996 9.99992H11.3333V11.3333H9.99996V9.99992Z" />
                  <path d="M3.33333 14.6666H12.6667C13.402 14.6666 14 14.0686 14 13.3333V5.33325V3.99992C14 3.26459 13.402 2.66659 12.6667 2.66659H11.3333V1.33325H10V2.66659H6V1.33325H4.66667V2.66659H3.33333C2.598 2.66659 2 3.26459 2 3.99992V5.33325V13.3333C2 14.0686 2.598 14.6666 3.33333 14.6666ZM12.6667 5.33325L12.6673 13.3333H3.33333V5.33325H12.6667Z" />
                </svg>

                <div className="d-inline-block">
                  {this.state.selectedPeriod.label}
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {this.periods.map((item, index) => {
                  return (
                    <Dropdown.Item
                      key={item.value}
                      onClick={(e: any) => this.onPeriodChange(item, index)}
                    >
                      {item.label}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown> */}
          </Button>

          <Button onClick={this.handleNextDate}>
            <svg
              width="6"
              height="9"
              viewBox="0 0 6 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.01693 8.414L5.12106 4.207L1.01693 0L6.10352e-05 1.04235L3.08733 4.207L6.10352e-05 7.37165L1.01693 8.414Z"
                fill="#1D1E20"
              />
            </svg>
          </Button>
        </ButtonGroup>
      </div>
    );
  }
}
