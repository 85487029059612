import * as React from "react";
import {
  Dropdown,
  Form,
  FormLabel,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-toastify/dist/ReactToastify.css";
import "react-calendar/dist/Calendar.css";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import OutsideClickHandler from "react-outside-click-handler";
import { SingleSearchDropdownList } from "../../Common/Components/SingleSearchDropdownList";
import { ReactPageLoader } from "../../Common/Components/ReactPageLoader";
import "./Location.scss";
import "./facilities.scss";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import moment from "moment";
import { CalendarSelector } from "../Analytics/Components/CalendarSelector";
import profile from "../../Common/Assets/Images/profile.png";
import { Utils } from "../../Common/Utilis";

export const DailyTaskManagementDataTable = (props) => {
 

  const {
    onSearchChange,
    searchText,
    userRoles,
    isRefresh,
    onDDlFiltersChange,
    roleId,
    locationList,
    locationId,
    selectedDate,
    showCompleted,
    onCompletedChange,
    onDateSelect,
    tableData,
    isDataLoading,
    handleOnSelect,
    selectedRowIDs,
    tableColumnEvent,
  
    isAssignedToMe,
    timezone
  } = props;


  const hideDropdown = (ref) => {
    if(ref?.current && ref?.current?.ariaExpanded === "true")
    {
      ref.current.click()
    }
    // $(".dropdown").removeClass("show");
    // $(".dropdown-menu").hide();
  };

  const columns = [
    {
      dataField: "name",
      text: "",

      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <div className="task-title"
          onClick={() => props?.handleShowSlideout(row)}
          >
            <div
              className="task-name"
            >
              <EllipsisWithTooltip placement="bottom">
                {row.name}
              </EllipsisWithTooltip>
            </div>
            {row?.dailyTaskComment?.length > 0 && (
              <OutsideClickHandler onOutsideClick={() => hideDropdown(row.ref)}>
                <Dropdown className="more-action comment-dropdown position-static ml-1"
                  onClick={(e) => e.stopPropagation()}
                >
                  <DropdownToggle
                    className="btn-outline-primary btn btn-primary more"
                    id="dropdown-more"
                    ref={row.ref}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M3.3335 12V14.5107L4.3435 13.9047L7.51816 12H10.6668C11.4022 12 12.0002 11.402 12.0002 10.6667V5.33333C12.0002 4.598 11.4022 4 10.6668 4H2.66683C1.9315 4 1.3335 4.598 1.3335 5.33333V10.6667C1.3335 11.402 1.9315 12 2.66683 12H3.3335ZM2.66683 5.33333H10.6668V10.6667H7.14883L4.66683 12.156V10.6667H2.66683V5.33333Z"
                        fill="#6A6E73"
                      />
                      <path
                        d="M13.3333 1.33301H5.33333C4.598 1.33301 4 1.93101 4 2.66634H12C12.7353 2.66634 13.3333 3.26434 13.3333 3.99967V9.33301C14.0687 9.33301 14.6667 8.73501 14.6667 7.99967V2.66634C14.6667 1.93101 14.0687 1.33301 13.3333 1.33301Z"
                        fill="#6A6E73"
                      />
                    </svg>
                  </DropdownToggle>
                  <DropdownMenu>
                    {row?.dailyTaskComment?.map((x) => (
                      <div className="comment">
                        <div className="d-flex comment-header">
                          {x?.simleIdUrl ? (
                            <div className="actual-profile">
                              <img
                                src={x?.simleIdUrl}
                                className="profileimg"
                                alt="Profile"
                              />
                            </div>
                          ) : (
                            <div className="actual-profile border">
                              <img src={profile} alt="user profile" />
                            </div>
                          )}
                          <div className="ml-3">
                            <h5>{x.commentBy} </h5>
                            <span>{Utils.convertUtcToTimezoneFormat(x?.createdOn,timezone)}</span>
                          </div>
                        </div>

                        <h6 dangerouslySetInnerHTML={{
                            __html: x?.htmlComment,
                          }}
                          ></h6>
                      </div>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </OutsideClickHandler>
            )}
          </div>
        );
      },
    },
    {
      dataField: "roomName",
      text: "",
      events: tableColumnEvent,

      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <div className="role-col">
            <EllipsisWithTooltip placement="bottom">
              {row.roomName}
            </EllipsisWithTooltip>
          </div>
        );
      },
    },
    {
      dataField: "simleIdUrl",
      text: "",
      events: tableColumnEvent,
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <div className="user-col">
            { row.username ? (row?.simleIdUrl ? (
              <div className="actual-profile">
                <img
                  src={row?.simleIdUrl}
                  className="profileimg"
                  alt="Profile"
                />
              </div>
            ) : (
              <div className="actual-profile border">
                <img src={profile} alt="user" />
              </div>
            )) : <div className="actual-profile border"></div>}
          </div>
        );
      },
    },
  ];

  const selectRow = {
    mode: "checkbox",
    onSelect: handleOnSelect,
    selected: [
      ...selectedRowIDs,
      ...tableData
        ?.filter((r) => r?.status === "Completed")
        ?.map((r) => r?.taskId),
    ],
    nonSelectable: props?.tableData
      ?.filter((r) => r?.disabled)
      ?.map((r) => r?.taskId),
    selectionRenderer: ({ mode, ...rest }) => (
      <>
        {/* <input type={ mode } { ...rest } /> */}
        {/* {props?.showCompleted ? (
          <Form.Check
            type={mode}
            {...rest}
            label=""
            checked={props?.showCompleted}
          />
        ) : ( */}
        <Form.Check
          type={mode}
          {...rest}
          //   disabled={rest?.disabled}
          label=""
          checked={true}
        />
        {/* )} */}
      </>
    ),
  };

  const rowClasses = (row, rowIndex) => {
    let classes = "";

    if (props?.showCompleted && row?.status === "Completed") {
      classes = classes + " row-grey";
      classes = classes + " checkbox-disabled task-completed";
    } else {
      if (props?.selectedRowIDs[0] === row?.taskId) {
        classes = classes + " row-grey";
      }
    }

    return classes;
  };

  return (
    <>
      <div className="search-header">
        <div className="schedule-main">
          <div className="navigation-menus d-flex mt-0">
            <div className="schedule-table-search">
              <input
                type="text"
                onChange={(e) => onSearchChange(e.target.value)}
                value={searchText}
                className="search-box form-control"
                placeholder="Search..."
              />
            </div>
            <div className="room-type-dropdown">
              {userRoles?.length > 0 && !isAssignedToMe && (
                <SingleSearchDropdownList
                  id={"roleddl"}
                  isRefresh={isRefresh}
                  itemList={userRoles}
                  handleSelectedItem={onDDlFiltersChange.bind(this, "ddlRole")}
                  defaultItem={roleId}
                  defaultText={"Search Role"}
                  defaultName={"Role"}
                  controlID="1"
                />
              )}
            </div>
            <div className="room-type-dropdown">
              {locationList?.length > 0 && (
                <SingleSearchDropdownList
                  id={"locationddl"}
                  isRefresh={isRefresh}
                  itemList={locationList}
                  handleSelectedItem={onDDlFiltersChange.bind(
                    this,
                    "ddlLocation"
                  )}
                  defaultItem={locationId}
                  defaultText={"Search Location"}
                  defaultName={"Location"}
                  controlID="1"
                />
              )}
            </div>

            <div>
              <CalendarSelector
                id={"chartPeriodSelector"}
                defaultValue={
                  selectedDate
                    ? selectedDate
                    : new Date(moment().format("MM/DD/YYYYY"))
                }
                onChange={onDateSelect}
                ai
                allowAllBackDates={true}
                // minDate={new Date(moment().format("MM/DD/YY"))}
                maxDate={new Date(moment().format("MM/DD/YYYYY"))}
              ></CalendarSelector>
            </div>
            <div className="nav-column-right ml-auto">
              <div className="d-flex align-items-center">
                <Form.Check
                  checked={showCompleted}
                  type="switch"
                  onChange={(e) => onCompletedChange(e?.target?.checked)}
                  className="sm-controll toggleButtonRP"
                  label=""
                  id="daily-flash-switch"
                />
                <FormLabel className="mb-0">Show Completed</FormLabel>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {" "}
        {tableData?.filter((x) => x?.status !== "Completed")?.length > 0 ? (
          <span>
            <h6 className="table-heading">Due Today</h6>
          </span>
        ) : (
          ""
        )}
        <div className="hasCursorPointer position-relative table-wrapper">
          <div className="block-bar"></div>
          {isDataLoading ? (
            <ReactPageLoader useas={"daily-task-loader"} />
          ) : (
            <>
              {" "}
              {props.tableData?.length > 0 ? (
                <BootstrapTable
                  id={"timesheet"}
                  {...props.baseProps}
                  keyField="taskId"
                  data={props.tableData}
                  columns={columns}
                  selectRow={selectRow}
                  hover
                  rowClasses={rowClasses}
                  //  rowEvents={tableRowEvents}
                />
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
