import React from "react";
// import { Button, Container } from "react-bootstrap";
// import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ReportParameterSelection } from "./ReportParameterSelection";

export class ReportRunnerLeftPanel extends React.Component<any, any> {
 private paramSelectionRef
  constructor(props: any) {
    super(props);
    this.state = {disableRunReport: false };
  }

  showReportData = (loadReport: boolean = true) => {
    debugger;
    if(loadReport) {
    this.setState({disableRunReport: true });
    } else {
    this.setState({disableRunReport: false });
    }
  this.props.showReport(loadReport);
  };
   enableReportRunner =() => {
  
   this.setState({disableRunReport: false });
  }


  render() {
    return (
      <>
        {Object.keys(this.props.reportData).length > 0 && (
          <ReportParameterSelection
            disableRunReport= {this.state.disableRunReport} 
            userReportName={this.props.userReportName}
            enableReportRunner = {this.enableReportRunner}
            reportData={this.props.reportData}
            openScheduleTab={this.props.openScheduleTab}
            showReportData={this.showReportData}
            runFrom={this.props.runFrom}
            reloadMyReports={() => {
              this.props.reloadMyReports();
            }}
            {...this.props}
          ></ReportParameterSelection>
        )}
        {/* <ToastContainer
          enableMultiContainer
          autoClose={3000}
          containerId={"reportParameterSelection"}
        /> */}
      </>
    );
  }
}
