import * as React from "react";
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { ModuleReports } from "../../Common/Services/ModuleReports";
import { ToastContainer, toast } from "react-toastify";
import { Utils } from "../../Common/Utilis";

export class VendorSelector extends React.Component<any, any> {

  private inputElement: any;

  constructor(props: any) {
    super(props);
    this.inputElement = React.createRef();
    this.state = {
      selectedItem: [{ id: -1, label: "" }],
      vendorList: [],
    };
  }


  componentDidUpdate(prevProps, prevState) {
    // debugger;
    const { selectedVendorId } = this.props;
    if (selectedVendorId === -1 && prevProps.hotelIds !== this.props.hotelIds) {
      this.getVendorList();
    }
  }

  componentDidMount = () => {
    // debugger;
    this.getVendorList();

  };

  getVendorList = () => {
    // debugger;
    let request: any = {};
    request.HotelID = this.props.hotelIds;

    ModuleReports
      .GetVendorList(request)
      .then(async (result: any | null) => {
        //debugger;
        if (result !== null) {
          this.setState({ vendorList: result }, () => {
            if (this.props.selectedVendorId === undefined || this.props.selectedVendorId === -1) {
              this.setState({ selectedItem: [{ id: -1, label: "" }] }, () => {
                // this.handleVendorChange(this.state.selectedItem);
              })
            } else {
              let selectedItem = this.state.vendorList?.filter(r => r.id === this.props.selectedVendorId);
              this.setState({ selectedItem: selectedItem, }, () => {
                // this.handleVendorChange(this.state.selectedItem);
              });
            }
          });
        }
      })
      .catch((err: any) => {
        this.setState({ vendorList: [], selectedItem: [{ id: -1, label: "" }], });
        Utils.toastError(`Server Error, ${err}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  }

  handleVendorChange = (selected: any) => {
    // debugger;
    if (selected.length > 0) {
      if (selected[0].id !== this.state.selectedItem[0]?.id) {
        this.setState({ selectedItem: selected.length > 0 ? selected : [] });
        this.props.onVendorChange(selected);
      }
    }
  }

  handleTypeaheadInput = (input) => {
    //debugger;
    let selectedItem: any = [];
    if (input.length > 0) {
      if (this.state.vendorList.filter((x) => x.label.toLowerCase() === input.toLowerCase()).length > 0) {
        const index = this.state.vendorList.findIndex((x) => x.label.toLowerCase() === input.toLowerCase());
        selectedItem.push(this.state.vendorList[index]);
      }
      else {
        selectedItem.push({ id: -1, label: input });
      }
    }
    else {
      selectedItem.push({ id: -1, label: input });
    }
    this.setState({ selectedItem });
  };

  handleTypeaheadBlur = () => {
    //debugger;
    let selectedItem = Object.assign([], JSON.parse(JSON.stringify(this.state.selectedItem)));
    if (this.state.selectedItem.length > 0) {
      if (this.state.selectedItem[0].id === -1) {
        if (this.state.vendorList.filter((x) => x.label.toLowerCase() === this.state.selectedItem[0].label.toLowerCase()).length > 0) {
          const index = this.state.vendorList.findIndex((x) => x.label.toLowerCase() === this.state.selectedItem[0].label.toLowerCase());
          selectedItem = [];
          selectedItem.push(this.state.vendorList[index]);
        }
        else {
          selectedItem = [{ id: -1, label: "" }];
        }
        this.setState({ selectedItem, }, () => {
          this.props.onVendorChange(this.state.selectedItem);
        });
      }
    }
  }

  render() {

    return (
      <div className="single-select dropdown custom-typeahead">
        <Typeahead
          id="reportVendorSelector"
          onChange={(selected) => {
            this.handleVendorChange(selected);
          }}
          onInputChange={input => this.handleTypeaheadInput(input)}
          onBlur={this.handleTypeaheadBlur}
          options={this.state.vendorList}
          placeholder="Select Vendor"
          selected={this.state.selectedItem}
          renderMenu={(results: any, menuProps) => (
            <Menu {...menuProps}>
              {results.map((result: any, index: any) => (
                <>
                  {result.label !== "" && (
                    <MenuItem option={result} position={index}>
                      <EllipsisWithTooltip placement="bottom">
                        {result.label}
                      </EllipsisWithTooltip>
                    </MenuItem>
                  )}
                  {result.label === "" && (
                    <MenuItem className="load-more" option={result} position={index}>
                      Load More
                    </MenuItem>
                  )}
                </>
              ))}
            </Menu>
          )}
        />
      </div>
    );
  }
}
