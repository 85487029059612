import Instense from "./Axios-config";

import axios from "axios";
import { IScheduleChangeLogResponse, IScheduleChangeLogRequest } from './../Contracts/ISchedule';

export class Schedule {

    public static GetScheduleChangeLog = async (
     request : IScheduleChangeLogRequest 
    ): Promise<IScheduleChangeLogResponse[] | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.tenantId = tenantID;
        
       

        const url = `/Schedule/GetScheduleChangeLog`;
        return Instense.post(url,request).then((response) => {
            return response.data?.result as IScheduleChangeLogResponse[];
        });
    };

    public static GetScheduleChangeLogByScheduleId = async (
        scheduleId
    ): Promise<IScheduleChangeLogResponse[] | null> => {
        const request: any = {};
        let storage = JSON.parse(localStorage.getItem("storage")!);
        request.scheduleId = scheduleId;
        const url = `/Schedule/GetScheduleChangeLogByScheduleId`;
        return Instense.get(url, { params: request }).then((response) => {
            return response.data?.result as IScheduleChangeLogResponse[];
        });
    };

    public static DownloadChangeLog = async (
        request : IScheduleChangeLogRequest , fileName: string
    ): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.tenantId = tenantID;
      

        const url = `/Schedule/DownloadChangeLog`;
        return Instense.post(url, request,  { responseType: "blob" }).then(
           
            (response: any) => {
                
              let csvURL = window.URL.createObjectURL(response.data);
              let tempLink = document.createElement("a");
              tempLink.href = csvURL;
              tempLink.setAttribute("download", fileName + ".xlsx");
              tempLink.click();
            }
          );
    };

  
    public static   GetPayPeriodWeekByDateAndHotel = ( hotelId,  date): Promise<any| null> => {
        const request: any = {};
        request.hotelId = hotelId;
        request.date = date;
        const url = `/Schedule/GetPayPeriodWeekByDateAndHotel`;
        return Instense.get(url, { params: request }).then((response) => {

            
            return response.data?.result as any;
        });
    };


}
