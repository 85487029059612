import Instense from "./Axios-config";
export class AdvancedAccountingService {

    public static GetAdvancedAccountingHeaderData = async (request: any): Promise<any[] | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.TenantId = tenantID;
        const url = `/AdvancedAccounting/GetAdvancedAccountingHeaderData`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data.result as any;
            return result;
        });
    };

    public static GetAdvancedAccountingHotelData = async (request: any): Promise<any[] | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.TenantId = tenantID;
        const url = `/AdvancedAccounting/GetAdvancedAccountingHotelData`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data.result as any;
            return result;
        });
    };

    public static SaveAdvancedAccountingHotelMapping = async (request: any[]): Promise<any | null> => {
        // debugger;
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.map((items) => ((items["TenantId"] = tenantID)));
        const url = `/AdvancedAccounting/SaveAdvancedAccountingHotelMapping`;
        return Instense.post(url, request).then(
          (response: any) => {
            let result = response.data.result as any;
            return result;
          }
        );
      }

}