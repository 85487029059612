import React from "react";
import { Container, Dropdown, Button, Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PageLoader from "../../../../Common/Components/PageLoader";
import { Hotel } from "../../../../Common/Services/Hotel";
import { IHotelResponseDto } from "../../../../Common/Contracts/IHotel";
import { LaborSmileID } from "../../../../Common/Services/LaborSmileId";
import { resolve, reject } from "q";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import BootstrapTable from "react-bootstrap-table-next";
import { FiChevronDown } from "react-icons/fi";
import OutsideClickHandler from "react-outside-click-handler";
// import calenderGrey from "../../../../Common/Assets/Images/icons/calendar_grey.svg";
//import { ReactPageLoader } from "../../../../Common/Components/ReactPageLoader";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import {
  ILaborSmileIdRequestDto,
  ILaborSmileIdActionRequestDto,
  ILaborDeleteSmileIdPicRequestDto,
} from "../../../../Common/Contracts/ILaborDayApprove";
import {
  RoleSubPermissionSetting,
  IPageSubPermissionRole,
} from "../../../../Common/Services/RoleSubPermissionSetting";
import { LaborPerformance } from "../../../../Common/Services/LaborPerfomance";
import paginationFactory from "react-bootstrap-table2-paginator";
import { HidSelector } from "../../../../Common/Components/HidSelector";
import TableSearch from "../../../../Common/Components/TableSearch";
//import { MyImage } from "../../../../Common/Components/MyImage";
import profile from "../../../../Common/Assets/Images/profile.png";
import { ReactPageLoader } from "../../../../Common/Components/ReactPageLoader";
import { confirmAlert } from "react-confirm-alert";
import { Utils } from "../../../../Common/Utilis";
let newSearchResult: any = [];
let firstItem: any = 0;
export class SmileIDPunchEvaluates extends React.Component<any, any> {
  private smileIdChildLeft: any;
  private child: any;
  private childHID: any;
  private pageBtn: any;

  constructor(props: any) {
    super(props);
    this.smileIdChildLeft = React.createRef();
    this.child = React.createRef();
    this.childHID = React.createRef();
    this.pageBtn = React.createRef();
    this.state = {
      hidValue: "Select",
      hotelName: "Select",
      isLastRecord: false,
      hidList: [],
      tableData: [],
      allPendingData: [],
      allData: [],
      rightPanelRow: [],
      sortBy: "Employees",
      calenderShow: false,
      calenderDates: "All Dates",
      calenderSelectDate: "",
      filterStatus: "Pending",
      startDate: "",
      endDate: "",
      selectedFilter: "Pending",
      currentPageIndex: 0,
      isPermission: false,
      imgPath: "",
      imgalt: '',
      filterList: [
        { id: "Pass", value: "Pass", status: false },
        { id: "Fail", value: "Fail", status: false },
        { id: "Pending", value: "Pending", status: true },
        { id: "All", value: "All", status: false },
      ],
      isManageSmileIDPermission: false,
      resetUserSmileId: false,
    };
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.actionButton = this.actionButton.bind(this);
  }

  handleFilterChange = (event: any, value: any) => {
    this.setState({ filterStatus: value.value });
    if (value.value === "Pending") {
      this.setState({ calenderDates: "All Dates" });
    }
    
    this.CleanSearch();
    let data = this.state.filterList;
    for (let i = 0; i < data.length; i++) {
      if (data[i].id === value.id) {
        data[i].status = true;
      } else {
        data[i].status = false;
      }
    }
    this.setState({
      filterList: data,
      selectedFilter: value.value,
      currentPageIndex: 0,
      tableData: [],
    });
    let request = {} as any;
    request.hotelID = this.state.hidValue;
    if (this.state.calenderDates !== "All Dates" && value.value !== "Pending") {
      request.startDate = this.state.startDate;
      request.endDate = this.state.endDate;
      request.pageType = "SMILEID";
    } else {
      request.pageType = request.pageType = value.value === "Pending" ? "ALLPENDING" : value.value === "Pass" ? "ALLPASS" : value.value === "Fail" ? "ALLFAIL" : "ALL";
    }
    this.smileIdOverviewAccountsDetails(request, this.state.sortBy);
  };

  componentDidMount() {
    
    this.getSmileIDPermission();
    this.loadHotelsDetails();
    //this.getMenuPermission();
  }

  getSmileIDPermission = () => {
    let requestObject = {} as IPageSubPermissionRole;
    requestObject.permissionID = 10004;
    requestObject.Module = "LaborManagement";
    requestObject.Type = "page";
    RoleSubPermissionSetting.GetPageSubPermissionDetails(requestObject)

      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          result.forEach((element: any) => {
            switch (element.permissionID) {
              case 10018:
                this.setState({ isManageSmileIDPermission: true });
                break;
              case 10077:
                this.setState({ resetUserSmileId: true });
                break;
            }
          });
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "payrollExportIndex",
        });
        reject();
      });
  };




  smileIdOverviewAccountsDetails(
    request: ILaborSmileIdRequestDto,
    sortType: string
  ) {
    request.sortType = sortType;
    this.setState({ loaderTrue: true });
    LaborSmileID.GetLaborSmileHidFailureDetails(request)
      .then(async (result: any | null) => {
        if (result.message === "Success") {
          let data = result.result;
          
          if (
            this.state.selectedFilter.toLowerCase() === "pass" ||
            this.state.selectedFilter.toLowerCase() === "fail" ||
            this.state.selectedFilter.toLowerCase() === "pending"
          ) {
            data = data.filter(
              (item: { status: any, isFalsePunch: any }) =>
                item.status === this.state.selectedFilter
            );
          }

          let datefilterList = this.state.filterList;
          for (let i = 0; i < datefilterList.length; i++) {
            if (datefilterList[i].id === this.state.selectedFilter) {
              datefilterList[i].status = true;
            } else {
              datefilterList[i].status = false;
            }
          }
          // this.setState({
          //   filterList: datefilterList,
          // });

          //  this.setState({ tableData: [] }, () =>
          this.setState({ tableData: data, filterList: datefilterList })
          // );
          this.eventRowData(data[0]);
          this.CleanSearch();
        } else {
          Utils.toastError(result.message, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        this.setState({ loaderTrue: false });
      })
      .catch((error) => {
        reject();
        this.setState({ loaderTrue: false });
      });
  }

  loadHotelsDetails() {
    Hotel.getUserHotelAccess("Labor Management")
      .then(async (result: IHotelResponseDto[] | null) => {
        if (result != null) {
          ;
          this.setState({ hidList: result }, () => { });
        }
        resolve();
        this.setState({ loaderTrue: false });
      })
      .catch((error) => {
        this.setState({ loaderTrue: false });
        reject();
      });
  }

  selectHotelDropDown = (hotelDetails: any) => {
    this.setState({ activeClassRow: "", rightPanelRow: {}, tableData: [], currentPageIndex: 0, });
    this.CleanSearch();
    this.BindSmilidOnHidChange(hotelDetails.lettercode, hotelDetails.hotelID);
  };

  CleanSearch = () => {
    if (this.child.current != null) {
      this.child.current.CleanSearch();
    }
  };

  AllDates = () => {
    this.setState({ calenderShow: false, calenderDates: "All Dates" });
    let request = {} as any;
    request.hotelID = this.state.hidValue;
    request.pageType = request.pageType =
      this.state.selectedFilter === "Pending"
        ? "ALLPENDING"
        : this.state.selectedFilter === "Pass"
          ? "ALLPASS"
          : this.state.selectedFilter === "Fail"
            ? "ALLFAIL"
            : "ALL";
    this.smileIdOverviewAccountsDetails(request, this.state.sortBy);
  };

  firstaLoadData(lettercode: any, hotelID: any) {
    
    let currentDate: any = new Date();
    if (JSON.parse(localStorage.storage).currentUtcTime !== "" && JSON.parse(localStorage.storage).currentUtcTime !== null && JSON.parse(localStorage.storage).currentUtcTime !== undefined) {
      currentDate = new Date(
        JSON.parse(localStorage.storage).currentUtcTime
      );
    }
    let currentdate = currentDate.getDate();
    let currentMonth = currentDate.getMonth() + 1;
    let currentYear = currentDate.getFullYear();
    let tempDate = currentMonth + "/" + currentdate + "/" + currentYear;
    this.setState({
      hotelName: lettercode,
      hidValue: hotelID,
      calenderShow: false,
      calenderDates: "All Dates",
      calenderSelectDate: tempDate,
      // selectedFilter: "Pending",
      tableData: [],
      rightPanelRow: [],
      startDate: "",
      endDate: "",
    });
    let request = {} as any;
    request.hotelID = hotelID;
    request.pageType = "ALLPENDING";
    this.smileIdOverviewAccountsDetails(request, this.state.sortBy);
  }


  BindSmilidOnHidChange(lettercode: any, hotelID: any) {
    
    let currentDate: any = new Date();
    if (JSON.parse(localStorage.storage).currentUtcTime !== "" && JSON.parse(localStorage.storage).currentUtcTime !== null && JSON.parse(localStorage.storage).currentUtcTime !== undefined) {
      currentDate = new Date(
        JSON.parse(localStorage.storage).currentUtcTime
      );
    }
    let currentdate = currentDate.getDate();
    let currentMonth = currentDate.getMonth() + 1;
    let currentYear = currentDate.getFullYear();
    let tempDate = currentMonth + "/" + currentdate + "/" + currentYear;
    this.setState({
      hotelName: lettercode,
      hidValue: hotelID,
      calenderShow: false,
      calenderDates: "All Dates",
      calenderSelectDate: tempDate,
      // selectedFilter: "Pending",
      tableData: [],
      rightPanelRow: [],
      startDate: "",
      endDate: "",
    });
    let request = {} as any;
    request.hotelID = hotelID;

    request.pageType = request.pageType =
      this.state.selectedFilter === "Pending"
        ? "ALLPENDING"
        : this.state.selectedFilter === "Pass"
          ? "ALLPASS"
          : this.state.selectedFilter === "Fail"
            ? "ALLFAIL"
            : "ALL";
    this.smileIdOverviewAccountsDetails(request, this.state.sortBy);
  }

  getHidPayPeriod(selectedDate: any, hotelID: any) {
    LaborPerformance.getPayPeriodDates(hotelID, selectedDate, "Weekly").then(
      async (result: any[] | null) => {
        if (result != null) {
          this.setState({
            calenderDates: result[0].startdate + " - " + result[0].enddate,
            startDate: result[0].startdate,
            endDate: result[0].enddate,
          });
          let request = {} as any;
          request.hotelID = hotelID;
          request.startDate = result[0].startdate;
          request.endDate = result[0].enddate;
          request.pageType = "SMILEID";
          this.smileIdOverviewAccountsDetails(request, this.state.sortBy);
        }
      }
    );
  }

  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      let value = (rowIndex + 1) % 10;
      if (value === 0) this.setState({ isLastRecord: true });
      else this.setState({ isLastRecord: false });
      this.setState({ activeClassRow: row.uniqueno }, () => {
        this.eventRowData(row);
      })
    },
  };

  eventRowData(row: any) {
    if (row !== undefined) {
      if (!row.action && row.action !== "") {
        let array: any[] = [];
        let newItem: any = {};
        newItem.action = row.action;
        newItem.actualPunchID = row.actualPunchID;
        newItem.confidencelevel = row.confidencelevel;
        newItem.date = row.date;
        newItem.employee = row.employee;
        newItem.imageUrl = row.imageUrl;
        newItem.inTime = row.inTime;
        newItem.isFalsePunch = row.isFalsePunch;
        newItem.outTime = row.outTime;
        newItem.position = row.position;
        newItem.punchType = row.punchType;
        newItem.rowSelected = row.rowSelected;
        newItem.status = row.status;
        newItem.uniqueno = row.uniqueno;
        newItem.hotelId = row.hotelId;
        newItem.entryType = row.entryType;
        array.push(newItem);

        this.setState({ activeClassRow: row.uniqueno, rightPanelRow: array }, () => {
          this.setState({ imgPath: '', imgalt: '' }, () => {
            this.getSimleIDProfileImage(row);
          })
        });
      }
    }
  }


  getSimleIDProfileImage = (row) => {
    if (row !== undefined) {
      let request = {
        hotelID: row.hotelId,
        uniqueID: row.uniqueno,
      };
      LaborSmileID.GetSimleIDProfile(request).then(async (result: any | null) => {
        let staticPath = ""
        let basePath;
        let title;
        if (result.success) {
          if (result.result !== '') {
            staticPath = "data:image/jpeg;base64,"
            basePath = result.result;
            title = "baseProfile";
          } else {
            staticPath = ""
            basePath = profile;
            title = "imgProfile";
          }
        } else {
          staticPath = ""
          basePath = profile;
          title = " imgProfile";
        }
        this.setState({ imgPath: staticPath + basePath, imgalt: title })
      })
    }
  }

  calenderHide = () => {
    this.setState({ calenderShow: false });
  };

  calenderShow = () => {
    this.setState({ calenderShow: true });
  };

  calenderChange = (e: any) => {
    let calenderYear = e.getFullYear();
    let calenderDate = e.getDate();
    let calenderMonth = e.getMonth() + 1;
    let tempDate = calenderMonth + "/" + calenderDate + "/" + calenderYear;
    this.setState({ calenderSelectDate: tempDate, calenderShow: false });
    this.getHidPayPeriod(tempDate, this.state.hidValue);
  };

  actionButton = (e: any, type: string, row: any) => {
    ;
    let data = this.state.tableData;
    this.setState({ loaderTrue: true });
    let request = {} as ILaborSmileIdActionRequestDto;
    request.hotelID = row.hotelId;
    request.Uniqueno = row.uniqueno;
    request.isFalsePunches = type;
    LaborSmileID.SimeIdAction(request)
      .then(async (result: any | null) => {

        if (result.saveStatus === "Success") {
          for (let i = 0; i < data.length; i++) {
            if (data[i].uniqueno === row.uniqueno) {
              data[i].action = false;
              if (this.state.selectedFilter.toLowerCase() === "pending") {
                data[i].action = true;
              }
              if (this.state.selectedFilter.toLowerCase() === "all") {
                ;
                data[i].status = type === "Yes" ? "Pass" : "Fail";

                // this.setState({ tableData: data })

              }
              break;
            }
          }

          if (this.state.selectedFilter.toLowerCase() === "pending") {
            this.setState({ tableData: data });
            this.nextSelectedRow(row);
          } else {
            let dataRight = this.state.rightPanelRow;
            for (let i = 0; i < dataRight.length; i++) {
              if (dataRight[i].uniqueno === row.uniqueno) {
                dataRight[i].action = false;
                dataRight[i].isFalsePunch = type;
                dataRight[i].status = type === "Yes" ? "Pass" : "Fail";
                // this.setState({tableData:data, rightPanelRow: dataRight }, () =>
                //     this.setState({ tableData: data })
                // );
                // this.nextSelectedRow(row);
                break;
              }
            }
            this.setState({ tableData: [], rightPanelRow: dataRight }, () =>
              this.setState({ tableData: data })
            );

          }

          if (this.state.isLastRecord) {
            this.pageBtn.click();
          }
        } else {
          Utils.toastError(result.message, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "smileIdOverview",
          });
        }
        resolve();
        this.setState({ loaderTrue: false });
      })
      .catch((error) => {
        reject();
        this.setState({ loaderTrue: false });
      });
  };

  undoButtonClick = (e: any, row: any) => {
    this.setState({ activeClassRow: "" });
    this.leftUndoButton(row);
  };

  searchedData = (newResult: any) => {
    if (newResult.length > 0) {
      let newRowIndex = 0;
      this.setState({ isFirstRowSelected: true });
      for (let i = 0; i < newResult.length; i++) {
        if (newResult[i].action === false) {
          newRowIndex = i;
          break;
        }
      }
      this.setState({ activeClassRow: newResult[newRowIndex].uniqueno });
      if (newResult.length > 0) {
        this.leftSelectedRow(newResult[newRowIndex]);
      }
    } else {
      let blankRow: any = {};
      blankRow.employee = "";
      blankRow.action = "";
      this.setState({ activeClassRow: "" });
      this.leftSelectedRow(blankRow);
    }
    newSearchResult = newResult;
  };

  leftUndoButton = (row: any) => {
    let data = [...this.state.tableData];
    this.setState({ loaderTrue: true });
    let request = {} as ILaborSmileIdActionRequestDto;
    request.hotelID = row.hotelId;
    request.Uniqueno = row.uniqueno;
    request.isFalsePunches = null;
    LaborSmileID.SimeIdAction(request)
      .then(async (result: any | null) => {
        if (result.saveStatus === "Success") {
          for (let i = 0; i < data.length; i++) {
            if (data[i].uniqueno === row.uniqueno) {
              data[i].action = false;
              data[i].isFalsePunch = null;
              data[i].rowSelected = false;
              data[i].status = "Pending";
              break;
            }
          }
          // this.setState({ tableData: data, currentPageIndex: 0 });
          if (this.state.selectedFilter.toLowerCase() === "pending") {
            row.isFalsePunch = null;
            row.status = "Pending";
            this.undoButtonClickRerender(row);
          }
          if (this.state.selectedFilter.toLowerCase() === "all") {
            let dataRight = this.state.rightPanelRow;
            for (let i = 0; i < dataRight.length; i++) {
              if (dataRight[i].uniqueno === row.uniqueno) {
                dataRight[i].action = true;
                dataRight[i].isFalsePunch = null;
                dataRight[i].status = "Pending";
                break;
              }
            }
            this.undoButtonClickRerender(dataRight[0]);
            // this.setState({ tableData: [], rightPanelRow: dataRight }, () =>
            //   this.setState({ tableData: data })
            // );
          } else {
            // let tableData: any[] = [];
            // tableData = Object.assign(
            //   [],
            //   JSON.parse(JSON.stringify(this.state.tableData))
            // );
            // tableData = tableData.filter(
            //   (item) =>
            //     item.status.toLowerCase() ===
            //     this.state.selectedFilter.toLowerCase()
            // );
            // this.setState({ tableData: [] }, () =>
            //    this.setState({ tableData: tableData })
            // );
          }
        } else {
          Utils.toastError(result.message, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "smileIdOverview",
          });
        }
        resolve();
        this.setState({ loaderTrue: false });
      })
      .catch((error) => {
        reject();
        this.setState({ loaderTrue: false });
      });
  };

  undoButtonClickRerender = (row: any) => {
    let data = this.state.tableData;
    for (let i = 0; i < data.length; i++) {
      if (data[i].uniqueno === row.uniqueno) {
        this.setState({ activeClassRow: row.uniqueno, }, () => {
          this.leftSelectedRow(this.state.tableData[i]);
        });
        break;
      }
    }
  };

  nextSelectedRow = (row: any) => {
    ;
    let data: any;
    if (newSearchResult.length > 0) {
      data = newSearchResult;
    } else {
      data = this.state.tableData;
    }

    for (let i = 0; i < data.length; i++) {
      let rowIndex: number;
      if (data[i].uniqueno === row.uniqueno) {
        if (data.length - 1 > i) {
          rowIndex = i + 1;
          if (data[rowIndex].action === false) {
            this.setState(
              {
                activeClassRow: data[rowIndex].uniqueno,
              },
              () => {
                this.leftSelectedRow(data[rowIndex]);
              }
            );
          } else {
            for (let y = 0; y < data.length; y++) {
              if (data[y].action === false) {
                this.setState(
                  {
                    activeClassRow: data[y].uniqueno,
                  },
                  () => {
                    this.leftSelectedRow(data[y]);
                  }
                );
                break;
              } else {
                let blankRow: any = {};
                blankRow.employee = "";
                this.setState({ activeClassRow: "" }, () => {
                  this.leftSelectedRow(blankRow);
                });
              }
            }
          }
        } else {
          for (let x = 0; x < data.length; x++) {
            if (data[x].action === false) {
              this.setState(
                {
                  activeClassRow: data[x].uniqueno,
                },
                () => {
                  this.leftSelectedRow(data[x]);
                }
              );
              break;
            } else {
              let blankRow: any = {};
              blankRow.employee = "";
              this.setState({ activeClassRow: "" }, () => {
                this.leftSelectedRow(blankRow);
              });
            }
          }
        }
      }
    }
  };

  leftSelectedRow = (row: any) => {

    this.eventRowData(row);
  };

  handleTableChange = (type, { page, sizePerPage }) => {
    // const currentIndex = (page - 1) * sizePerPage;
    // setTimeout(() => {
    //   this.setState(() => ({
    //     page,
    //     data: products.slice(currentIndex, currentIndex + sizePerPage),
    //     sizePerPage
    //   }));
    // }, 2000);
  };
  resetUserSmileId = (uniqueno: any, employee: any) => {
    this.isSmileId(uniqueno, employee);
  }
  isSmileId = (uniqueno: any, employee: any) => {
    let bodyCss: string = "recalculate-budget-desc";
    let boldFont: string = "bold";
    let Message: any = "";
    Message = (
      <div>
        <div>
        Stop! Resetting a user's Smile iD will require the facial recognition system to
         re-learn this user's facial features. It will require the user to enter their 
         password until the Smile iD re-learning is complete.
        </div>
        {/* <div> <a href="">Learn more about resetting a user's Smile iD.</a> </div> */}
      </div>
    );
    confirmAlert({
      title: "Reset Smile iD confirmation",
      message: Message,
      buttons: [
        {
          //className: "leave-without-recalc",
          label: "Reset",
          onClick: () => this.reSetSmileIdPic(uniqueno, employee),
        },
        {
          // className: "return-to-settings",
          label: "Cancel",
          onClick: () => reject(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
      //overlayClassName: "recalculate-modal",
    });
  };

  reSetSmileIdPic = (uniqueno: any, employee: any) => {

    let requestObject = {} as ILaborDeleteSmileIdPicRequestDto;
    requestObject.hotelID = this.state.hidValue;
    requestObject.uniqueno = uniqueno;
    requestObject.employee = employee;

    LaborSmileID.DeleteSmileIdProfilePic(requestObject)
      .then(async (result: any | null) => {
        
        if (result === "deleted") {
          
          toast.success("Image reset successfully", {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "tipEntry",
          });
        } else {
          toast.success("image does not reset successfully", {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "tipEntry",
          });
        }

      }).catch((error) => {
        reject();
        this.setState({ loaderTrue: false });
      });
    //}
  }

  render() {
    const rowClasses = (row: any, rowIndex: number) => {
      if (row.uniqueno === Number(this.state.activeClassRow)) {
        return "active-row";
      } else if (row.action) {
        return "faded-row";
      } else {
        return;
      }
    };

    let loadShow: any;

    if (this.state.loaderTrue) {
      loadShow = <PageLoader />;
    }

    const columns = [
      {
        dataField: "employee",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="smileIdEmpDetails">
            <EllipsisWithTooltip placement="bottom">
              <span className="smileIdEmpName">{row.employee}</span>
            </EllipsisWithTooltip>
            <EllipsisWithTooltip placement="bottom">
              <span className="smileIdEmpDesignation">{row.position}</span>
            </EllipsisWithTooltip>
          </div>
        ),
      },
      {
        dataField: "",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="smileIdPunchDetails">
            <span className="smileIdPunchDate">{row.date}</span>
            <span className="smileIdPunchLabel">Punch Date</span>
          </div>
        ),
      },

      {
        dataField: "",
        text: "",
        hidden:
          this.state.selectedFilter.toLowerCase() === "all" ? false : true,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="d-flex flex-wrap">
              {row.status === "Pending" && (
                <>
                  <div className="red-badges orange-bg">{row.status}</div>
                </>
              )}
              {row.status === "Pass" && (
                <>
                  <div className="red-badges green-bg">{row.status}</div>
                </>
              )}
              {row.status === "Fail" && (
                <>
                  <div className="red-badges">{row.status}</div>
                </>
              )}
            </div>
          );
        },
      },
      {
        dataField: "action",
        text: "",
        hidden:
          this.state.selectedFilter.toLowerCase() === "pending" ? false : true,
        formatter: (cell: any, row: any, rowIndex: any) => {
          if (row.action) {
            return (
              <div className="smile-page-undo-button">
                <Button
                  className="row-UndoButton btn-outline-primary float-right"
                  onClick={(e: any) => this.undoButtonClick(e, row)}
                >
                  {" "}
                  Undo{" "}
                </Button>
              </div>
            );
          } else {
            return <div className="smile-page-undo-button"></div>;
          }
        },
      },
    ];
    const customTotal = (from, to, size) => {
      return (
        <span className="react-bootstrap-table-pagination-total">
          Showing <span>{from}</span> - <span>{to}</span> of <span>{size}</span>{" "}
          Items
        </span>
      );
    };
    const pageButtonRenderer = ({
      page,
      active,
      disable,
      title,
      onPageChange,
    }) => {
      const handleClick = (e) => {
        let currentPageIndex = this.state.currentPageIndex;
        if (page === "Next") {
          currentPageIndex = this.state.currentPageIndex + 1;
          this.setState({ currentPageIndex: currentPageIndex });
        } else if (page == "Back") {
          currentPageIndex = this.state.currentPageIndex - 1;
          this.setState({ currentPageIndex: currentPageIndex });
        }


        firstItem = currentPageIndex * 10 + 1;
        e.preventDefault();
        onPageChange(page);
        this.eventRowData(this.state.tableData[firstItem - 1]);
        let tblSmile = document.getElementById("tblSmile") as any;
        tblSmile.scrollIntoView();
      };
      const activeStyle = {} as any;
      if (active) {
        activeStyle.opacity = 0;
        activeStyle.cursor = "default";
      }
      if (page === 0) {
        activeStyle.backgroundColor = "white";
        activeStyle.color = "black";
      }
      return (
        <li className="page-item">
          {this.state.tableData.length > 10 && (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              href="#"
              ref={(a) => {
                this.pageBtn = a;
              }}
              onClick={handleClick}
              style={activeStyle}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="12"
                  cy="12"
                  r="11.5"
                  transform="rotate(180 12 12)"
                  stroke="#2CAF92"
                />
                <path
                  d="M13.2518 8.00028C13.5595 8.00028 13.7951 8.25351 13.7951 8.54353C13.7951 8.68854 13.7406 8.83356 13.6324 8.9241L10.4274 12.0025L13.6324 15.081C13.8495 15.2981 13.8495 15.6426 13.6324 15.8414C13.4329 16.0409 13.1075 16.0586 12.8897 15.8598L9.26795 12.4007C9.05079 12.2012 9.05079 11.8575 9.24956 11.6403L9.26795 11.6219L12.8897 8.14525C12.9802 8.05471 13.1068 8.00024 13.2518 8.00024L13.2518 8.00028Z"
                  fill="#2CAF92"
                />
              </svg>
            </a>
          )}
        </li>
      );
    };
    const options = {
      pageButtonRenderer,
      paginationSize: 10,
      pageStartIndex: 0,
      alwaysShowAllBtns: false, // Always show next and previous button
      buttonsAlwaysShown: ["Last", "Next"],
      withFirstAndLast: false, // Hide the going to First and Last page button
      hideSizePerPage: true, // Hide the sizePerPage dropdown always
      hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      firstPageText: "First",
      prePageText: "Back",
      nextPageText: "Next",
      lastPageText: "Last",
      nextPageTitle: "First page",
      prePageTitle: "Pre page",
      firstPageTitle: "Next page",
      lastPageTitle: "Last page",
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [
        {
          text: "10",
          value: 10,
        },
      ], // A numeric array is also available. the purpose of above example is custom the text
    };
    return (
      <div className="">
        {/* <ToastContainer autoClose={3000} /> */}
        {loadShow}
        <Container fluid className="body-sec">
          <div className="page-heading underline d-flex mrgn-btm">
            <HidSelector
              onClickEvent={this.selectHotelDropDown}
              ref={this.childHID}
            />
            <div className="mr-auto d-flex align-items-center">Smile iD</div>
          </div>
          {this.state.hidValue !== "Select" && (
            <div>
              <div className="performance-position performance ">
                <div className="body-sec">
                  <div className="main-Page">
                    <div className=" search-header d-flex">
                      <div className="calender-container ml-0 smileIDCalender">
                        <div
                          className={
                            this.state.calenderShow
                              ? "calender-display open"
                              : "calender-display"
                          }
                          onClick={this.calenderShow}
                        >
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z" />
                            <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z" />
                          </svg>
                          <span className="prev-Date">
                            {this.state.calenderDates}
                          </span>
                          <span className="chevron-down">
                            <FiChevronDown />
                          </span>
                        </div>
                        {this.state.calenderShow && (
                          <div className="calender">
                            <OutsideClickHandler
                              onOutsideClick={this.calenderHide}
                            >
                              <Calendar
                                calendarType={"US"}
                                onChange={this.calenderChange}
                                value={
                                  new Date(this.state.calenderSelectDate)
                                }
                              />
                              {this.state.calenderDates !== "All Dates" && (
                                <div
                                  className="all-dates"
                                  onClick={() => this.AllDates()}
                                >
                                  {" "}
                                  All Dates
                                </div>
                              )}
                            </OutsideClickHandler>
                          </div>
                        )}
                      </div>

                      <div className="position-filter select">
                        <Dropdown className="more-action bg-blue" alignRight>
                          <Dropdown.Toggle
                            id="dropdown-ptoType"
                            className="d-flex align-items-center"
                          >
                            <svg
                              width="14"
                              height="10"
                              viewBox="0 0 14 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M1 2C0.447715 2 0 1.55228 0 0.999999C0 0.447715 0.447715 0 0.999999 0H12.3328C12.8851 0 13.3328 0.447715 13.3328 0.999999C13.3328 1.55228 12.8851 2 12.3328 2H1Z" />
                              <path d="M3 6C2.44771 6 2 5.55228 2 5C2 4.44771 2.44771 4 3 4H10.3328C10.8851 4 11.3328 4.44771 11.3328 5C11.3328 5.55228 10.8851 6 10.3328 6H3Z" />
                              <path d="M5.66601 10C5.11373 10 4.66602 9.55229 4.66602 9C4.66602 8.44772 5.11373 8 5.66602 8H7.66601C8.2183 8 8.66601 8.44772 8.66601 9C8.66601 9.55229 8.2183 10 7.66601 10H5.66601Z" />
                            </svg>
                            <div
                              className={`${this.state.filterStatus} badges`}
                            >
                              {this.state.selectedFilter}
                            </div>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <div className="ttl">STATUS</div>
                            {this.state.filterList.map(
                              (item: any, idx: any) => (
                                <Dropdown.Item
                                  className={item.status ? "active" : ""}
                                  key={item.id}
                                  eventKey={item.value}
                                  onClick={(e: any) =>
                                    this.handleFilterChange(e, item)
                                  }
                                >
                                  <div className="d-flex">
                                    <div className="mr-auto">
                                      {" "}
                                      {item.value}
                                    </div>
                                    <svg
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
                                        fill="#2CAF92"
                                      />
                                    </svg>
                                  </div>
                                </Dropdown.Item>
                              )
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*this.state.loaderTrue ? (<ReactPageLoader useas={"SmileIDPAnel50"} />) : ('')*/}

              <div className="budget-settings smileIdOverview">
                <div className="panel-container d-flex justify-content-start">
                  <div className="left-panel">
                    <div className="smileid-left-panel">
                      <div className="">
                        <ToolkitProvider
                          keyField="uniqueno"
                          data={this.state.tableData}
                          columns={columns}
                          rowEvents={this.rowEvents}
                          search={{
                            afterSearch: (newResult: any) => {
                              this.searchedData(newResult);
                            },
                          }}
                        >
                          {(props: {
                            searchProps: JSX.IntrinsicAttributes;
                            baseProps: JSX.IntrinsicAttributes;
                          }) => (
                            <div>
                              <div className="search-header d-flex">
                                <TableSearch
                                  id="tableSearch"
                                  key="tableSearch"
                                  ref={this.child}
                                  {...props.searchProps}
                                  CleanSearch={this.CleanSearch.bind(this)}
                                  placeholder={"Search..."}
                                />
                              </div>
                              <div className="left-body">
                                {
                                  this.state.loaderTrue &&
                                  <ReactPageLoader useas={"smileIDLeftPanelLoader"} />
                                }
                                <div className="list">
                                  <BootstrapTable
                                    id="tblSmile"
                                    {...props.baseProps}
                                    keyField="uniqueno"
                                    rowEvents={this.rowEvents}
                                    hover
                                    condensed
                                    rowClasses={rowClasses}
                                    pagination={paginationFactory(options)}
                                  //onTableChange={ this.handleTableChange }
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </ToolkitProvider>
                      </div>
                    </div>
                  </div>
                  <div className="right-panel">
                    {this.state.rightPanelRow.length > 0 &&
                      this.state.tableData.length > 0 &&
                      this.state.rightPanelRow[0].employee.length > 0 && (
                        <div>
                          <div className="commit-header d-flex align-items-lg-center align-items-end justify-content-between smileIdQuestionOuter pb-2 pb-lg-0">
                            {(this.state.rightPanelRow[0].status ===
                              "Pending" ||
                              this.state.rightPanelRow[0].status ===
                              null) && (
                                <div className="d-lg-flex w-100 justifyBetween">

                                  <span className="smileIdQuestion d-flex align-items-center">
                                    Is this
                                    <EllipsisWithTooltip placement="bottom">
                                      <span>
                                        {this.state.rightPanelRow[0].employee}
                                      </span>
                                    </EllipsisWithTooltip>
                                    ?
                                  </span>

                                  {this.state.isManageSmileIDPermission ? (
                                    <span className="action-group d-flex">
                                      <button
                                        type="button"
                                        className="btn-danger btn btn-primary ml-lg-3 ml-0"
                                        onClick={(e: any) =>
                                          this.actionButton(
                                            e,
                                            "No",
                                            this.state.rightPanelRow[0]
                                          )
                                        }
                                      >
                                        No
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={(e: any) =>
                                          this.actionButton(
                                            e,
                                            "Yes",
                                            this.state.rightPanelRow[0]
                                          )
                                        }
                                      >
                                        Yes
                                      </button>
                                    </span>
                                  ) : null}
                                </div>
                              )}
                            {this.state.rightPanelRow[0].status !==
                              "Pending" && (
                                <>
                                  <span className="smileIdQuestion d-flex align-items-center">
                                    <span>
                                      <EllipsisWithTooltip placement="bottom">
                                        <span>
                                          {this.state.rightPanelRow[0].employee}
                                        </span>
                                      </EllipsisWithTooltip>
                                    </span>
                                    {this.state.rightPanelRow[0].status ===
                                      "Pass" && (
                                        <>
                                          <div className="red-badges green-bg">
                                            {this.state.rightPanelRow[0].status}
                                          </div>
                                        </>
                                      )}
                                    {this.state.rightPanelRow[0].status ===
                                      "Fail" && (
                                        <>
                                          <div className="red-badges">
                                            {this.state.rightPanelRow[0].status}
                                          </div>
                                        </>
                                      )}
                                  </span>
                                  {this.state.rightPanelRow[0].entryType !==
                                    "Auto" && (
                                      <span className="action-group d-flex flex-row">
                                        {this.state.isManageSmileIDPermission && (
                                          <Button
                                            className="row-UndoButton btn-outline-primary float-right"
                                            onClick={(e: any) =>
                                              this.undoButtonClick(
                                                e,
                                                this.state.rightPanelRow[0]
                                              )
                                            }
                                          >
                                            {" "}
                                            Undo{" "}
                                          </Button>
                                        )}
                                      </span>
                                    )}
                                </>
                              )}

                            <div className="action-group d-flex flex-row align-items-lg-center align-items-end">
                              {this.state.resetUserSmileId && (
                                <Dropdown className="more-action" alignRight>
                                  <Dropdown.Toggle
                                    className="btn-outline-primary btn btn-primary more"
                                    id="dropdown-more"
                                  >
                                    <svg
                                      width="20"
                                      height="20"
                                      viewBox="0 0 20 20"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                      <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                      <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                    </svg>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      eventKey="resetUserSmileId"
                                      onClick={(e: any) =>
                                        this.resetUserSmileId(
                                          this.state.rightPanelRow[0].uniqueno,
                                          this.state.rightPanelRow[0].employee,
                                        )
                                      }
                                    >
                                      Reset User's Smile iD
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              )}

                            </div>

                          </div>
                          <div className="right-body">
                            <div className="smileIdEmpImg smileIdEmpImgLoader">
                              {this.state.imgPath === "" ? (
                                <ReactPageLoader useas={"smileIDRightPanelLoader"} />
                              ) : (
                                <img src={this.state.imgPath} className={`${this.state.imgalt === "imgProfile" ? "img-fluid imgProfile" : "img-fluid"}`} alt={this.state.imgalt} />
                              )}
                              {/* <MyImage src={this.state.rightPanelRow[0].imageUrl} fallbackSrc={profile} alt="User SmileId" className="img-fluid d-none"></MyImage> */}

                            </div>
                            <div className="smileIdEmpImgCaption">
                              Punched{" "}
                              <span>
                                {this.state.rightPanelRow[0].punchType}
                              </span>{" "}
                              at{" "}
                              <span>
                                {this.state.rightPanelRow[0].punchType ===
                                  "Out"
                                  ? this.state.rightPanelRow[0].outTime
                                  : this.state.rightPanelRow[0].inTime}
                              </span>{" "}
                              on
                              <span>
                                {" "}
                                {this.state.rightPanelRow[0].date}
                              </span>{" "}
                              with a{" "}
                              <span>
                                {this.state.rightPanelRow[0].confidencelevel}%
                                Match
                              </span>{" "}
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>

            </div>
          )}
        </Container>
      </div>
    );
  }
}
