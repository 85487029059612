import React from "react";
import {
  Button,
  Col,
  Form,
  Modal,
} from "react-bootstrap";
import { Utils } from "../../../Common/Utilis";
import { UserManagement } from "../../../Common/Services/UserManagement";
import { toast } from "react-toastify";
import { IUserACATrackingSaveRequest } from "../../../Common/Contracts/IUserACATrackingSaveRequest";
import { ModuleReports } from "../../../Common/Services/ModuleReports";
import { DropDownList } from "../../Reports/common-components/dropdown-list";

export class ACACodeEdit extends React.Component<any, any> {
  state = {
    months: [
      { id: 1, name: "JAN" },
      { id: 2, name: "FEB" },
      { id: 3, name: "MAR" },
      { id: 4, name: "APR" },
      { id: 5, name: "MAY" },
      { id: 6, name: "JUN" },
      { id: 7, name: "JUL" },
      { id: 8, name: "AUG" },
      { id: 9, name: "SEP" },
      { id: 10, name: "OCT" },
      { id: 11, name: "NOV" },
      { id: 12, name: "DEC" },
    ],
    years: [],
    acaCodes: [],
    acaCodeEdit: {
      userUniqueNo: this.props.userUniqueNo, 
      acaType: this.props.parentData.uniqueno,
      statusCode: this.props.parentData?.statusCodeID || 0, 
      acaDate: "", mm: this.props.parentData?.mm || "", 
      yy: this.props.parentData?.yy || ""
    },
  };

  componentDidMount() {
    this.getACACodes();
    this.setState({ years: this.getACAYearsList() })
  }
  getACAYearsList = () => {
    const currentYear = new Date().getFullYear();
    const years: object[] = [];
    for (let i = 0; i <= 20; i++) {
      years.push({id:(currentYear - i),name:(currentYear - i)});

    }
    return years;

  }

  getACACodes = () => {
    ModuleReports.GetACACodes(+this.props.parentData.uniqueno).then(acaCodes => {
      this.setState({ acaCodes });
    });
  }
  componentWillUnmount = () => {
    // setTimeout(() => {
    //   toast.dismiss();
    // }, 1000)
  }

  saveACATracking = () => {
    const { acaCodeEdit } = this.state;
    acaCodeEdit.acaDate = `${acaCodeEdit.mm}/01/${acaCodeEdit.yy}`
    const request: IUserACATrackingSaveRequest = {
      ...(this.state.acaCodeEdit as any),
    } as IUserACATrackingSaveRequest;
    UserManagement.SaveACATracking(request)
      .then((result) => {
        if (result?.message === "Success") {
          toast.success(result?.result?.result, {
             position: toast.POSITION.BOTTOM_RIGHT,
          });
          this.props.handleCloseACACodeEdit(true);
        } else {
          Utils.toastError(result?.result?.message, {
             position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((error) => {
        Utils.toastError(error?.message, {
           position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  onFieldChange = (event, inputPosition) => {
    const { acaCodeEdit } = this.state;
    let value = event.target.value;
    const fieldName = event.target.name;
    acaCodeEdit[fieldName] = value;
    this.setState({ acaCodeEdit });
  };

  render() {
    const { acaCodeEdit, acaCodes, months, years } = this.state;
    const { showACACodeEdit, parentData, handleCloseACACodeEdit } = this.props;
    return (
      <form autoComplete="false">
     
        <Modal
          className="aca-code-edit-modal"
          show={showACACodeEdit}
          onHide={handleCloseACACodeEdit}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>{parentData.uniqueno === 1 ? "ACA Coverage Code" : "ACA Safe Harbor Code"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-section d-flex flex-column">
              <div className="body-section">
                <Form.Group
                  controlId="date-selection"
                  className={"d-flex"}
                >
                  <Col sm="6" className="pl-0">
                    <Form.Label>Coverage Code</Form.Label>
                    {acaCodes.length > 0 && (
                        <DropDownList
                          placeHolder={"Search Code"}
                          data={acaCodes}
                          isSearchRequired={false}
                          label={"code"}
                          value={"uniqueno"}
                          onDropDownChange={(item) => {
                            if (!item) {
                              return;
                            }
                            this.onFieldChange(
                              Utils.BuildCustomEventObject(
                                "statusCode",
                                item.uniqueno
                              ),
                              3
                            )
                          
                          }}
                         
                          selectedItem={[
                            ...[{ uniqueno: 0, code: "Select Code" }],
                            ...acaCodes,
                          ].find((r) => +r.uniqueno === +acaCodeEdit?.statusCode)}
                        />
                      )}
                    {/* <Dropdown className="import-from-dropdown">
                      <Dropdown.Toggle id="">
                        {!acaCodeEdit?.statusCode ? (
                          <span className="placeholder">Select Code</span>
                        ) : (
                          <span>{(acaCodes as any).find(item => +item?.uniqueno === +acaCodeEdit?.statusCode)?.code}</span>
                        )}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>

                        {(acaCodes as any).map((item) => {
                          return (
                            <Dropdown.Item
                              onClick={() =>
                                this.onFieldChange(
                                  Utils.BuildCustomEventObject(
                                    "statusCode",
                                    item.uniqueno
                                  ),
                                  3
                                )
                              }
                            >
                              {item?.code}
                            </Dropdown.Item>
                          );
                        })}

                      </Dropdown.Menu>
                    </Dropdown> */}
                    {/* {this.getFieldError("firstName",1)} */}
                  </Col>

                  <Col sm="6" className="pr-0">
                    <Form.Label>Month &amp; Year</Form.Label>
                    <div className="d-flex justify-content-between">
                    {months.length > 0 && (
                        <DropDownList
                         style={{ width: "45%" }}
                          placeHolder={"Month"}
                          data={months}
                          isSearchRequired={false}
                          label={"name"}
                          value={"id"}
                          onDropDownChange={(item) => {
                            if (!item) {
                              return;
                            }
                            this.onFieldChange(
                              Utils.BuildCustomEventObject(
                                "mm",
                                item?.id
                              ),
                              3
                            )
                          
                          }}
                         
                          selectedItem={[
                            ...[{ id: 0, name: "Month" }],
                            ...months,
                          ].find((r) => +r.id === +acaCodeEdit?.mm)}
                        />
                      )}
                      {/* <Dropdown className="import-from-dropdown" style={{ width: "45%" }}>
                        <Dropdown.Toggle id="">
                          {!acaCodeEdit?.mm ? (
                            <span className="placeholder">Month</span>
                          ) : (
                            <span>{(months as any).find(item => +item?.id === +acaCodeEdit?.mm).name}</span>
                          )}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>

                          {months.map((item) => {
                            return (
                              <Dropdown.Item
                                onClick={() =>
                                  this.onFieldChange(
                                    Utils.BuildCustomEventObject(
                                      "mm",
                                      item?.id
                                    ),
                                    3
                                  )
                                }
                              >
                                {item.name}
                              </Dropdown.Item>
                            );
                          })}

                        </Dropdown.Menu>
                      </Dropdown> */}

                    {years.length > 0 && (
                        <DropDownList
                         style={{ width: "45%" }}
                          placeHolder={"Year"}
                          data={years}
                          isSearchRequired={false}
                          label={"name"}
                          value={"id"}
                          onDropDownChange={(item) => {
                            if (!item) {
                              return;
                            }
                            this.onFieldChange(
                              Utils.BuildCustomEventObject(
                                "yy",
                                item?.id
                              ),
                              3
                            )
                          
                          }}
                         
                          selectedItem={[
                            ...[{ id: 0, name: "Year" }],
                            ...years,
                          ].find((r) => +r.id === +acaCodeEdit?.yy)}
                        />
                      )}
                      {/* <Dropdown className="import-from-dropdown" style={{ width: "45%" }}>
                        <Dropdown.Toggle id="">
                          {!acaCodeEdit?.yy ? (
                            <span className="placeholder">Year</span>
                          ) : (
                            <span>{acaCodeEdit?.yy}</span>
                          )}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>

                          {years.map((item) => {
                            return (
                              <Dropdown.Item
                                onClick={() =>
                                  this.onFieldChange(
                                    Utils.BuildCustomEventObject(
                                      "yy",
                                      item
                                    ),
                                    3
                                  )
                                }
                              >
                                {item}
                              </Dropdown.Item>
                            );
                          })}

                        </Dropdown.Menu>
                      </Dropdown> */}


                    </div>
                    {/* {this.getFieldError("lastName", 2)} */}
                  </Col>
                </Form.Group>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              className="import-forecast-button btn-ghost"
            onClick={() => handleCloseACACodeEdit()}
          
            >
              Cancel
            </Button>
            <Button
              disabled={!acaCodeEdit?.statusCode || !acaCodeEdit?.mm || !acaCodeEdit?.yy}
              type="submit"
              className="import-forecast-button"
              onClick={(e: any) => this.saveACATracking()}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </form>
    );
  }
}
