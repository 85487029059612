import React from "react";
import { Button, Col, Dropdown, Form, Spinner, Tab, Tabs, } from "react-bootstrap";
import moment from "moment";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import OutsideClickHandler from "react-outside-click-handler";
import { SkipRoom } from "./SkipRoom";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import { SingleSearchDropdownList } from "../../Common/Components/SingleSearchDropdownList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropzone from "react-dropzone";
import AddPMCommentBox from "./AddPMCommentBox";
import { toast, ToastContainer } from "react-toastify";
import { FacilityPreventativeMaintenance } from "../../Common/Services/FacilityPreventativeMaintenance";
import {
  faClose,
  faPlay,
  faPause,
  faCheck,
  faFile,
  faFileCsv,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFileWord,
} from "@fortawesome/free-solid-svg-icons";
import profile from "../../Common/Assets/Images/profile.png";
import { resolve, reject } from "q";
import {
  IPMAssignToChangeRequestDto,
  IPMCalculationRequestDto,
  IPMTaskListRequestDto,
} from "../../Common/Contracts/IPreventativeMaintenance";
import { IGetProceureDetailsRequestDto } from "../../Common/Contracts/IMaintenanceSchedule";
import { FacilityMaintenance } from "../../Common/Services/FacilityMaintenance";
import { confirmAlert } from "react-confirm-alert";
import _ from "lodash";
import { WorkOrder } from "../../Common/Services/WorkOrder";
import { IWorkOrderRequestDto } from "../../Common/Contracts/IWorkOrder";
import { Facility } from "../../Common/Services/Facility";
import { Utils } from "../../Common/Utilis";


export class PreventativeMaintenanceRightPanel extends React.Component<any, any> {

  private parentScroll: any;

  private btnOpen: any;
  private btnInprogress: any;
  private btnOnhold: any;
  private btnComplet: any;
  private ChildAddPMCommentBox: any;
  private rightPanelRef: any;
  private skipDropdownRef: any;
  private completeProjectRef: any;
  private skipRef: any;
  private tableSearchnew: any;

  constructor(props) {
    super(props);
    this.tableSearchnew = React.createRef();
    this.parentScroll = React.createRef();
    this.btnOpen = React.createRef();
    this.btnInprogress = React.createRef();
    this.btnOnhold = React.createRef();
    this.btnComplet = React.createRef();
    this.ChildAddPMCommentBox = React.createRef();
    this.rightPanelRef = React.createRef();
    this.skipDropdownRef = React.createRef();
    this.completeProjectRef = React.createRef();
    this.skipRef = React.createRef();
    this.state = {
      parentRowData: this.props.parentRowData,
      parentState: this.props.parentState,
      // showSlideout: this.props.parentState.showSlideout,
      leftPanelRoomList: [],
      tableDataFilter: [],
      status: [],
      rightPanelData: [],
      tempData: [
        { roomName: "abcd", status: "On Hold" },
        { roomName: "efgh", status: "In Progress" },
      ],
      rightPanelOpen: false,
      locationList: [],
      assetList: [],
      assignToList: [],
      files: [],
      fileSize: 5242880,
      savedfiles: [],
      newSelectfiles: [],
      moreActionFileUpload1: [
        { name: "Delete", eventKey: "delete" },
        { name: "Download", eventKey: "download" },
      ],
      isSelected: false,
      isShowDropdown: false,
      isShowCompleteProjectPopup: false,
      stdRoomAnnualData: [
        {
          taskName: "Room Maintenance",
        },
        {
          taskName: "Kitchen Maintenance",
        },
      ],
      calculation: [],
      getSkippedStatus: false,
      Status: [
        { id: "All", name: "All" },
        { id: "Open", name: "Open" },
        { id: "In Progress", name: "In Progress" },
        { id: "On Hold", name: "On Hold" },
        { id: "Completed", name: "Completed" },
      ],
      isReload: false,
      sortingOrder: "asc",
      sortingField: "completionDate",
      procedureData: [],
      procedureId: 0,
      taskData: [],
      isChecked: false,
      doneTask: false,
      isRefresh: false,
      assignTiId: -1,
      commentUserType: "PreventiveCommentType",
      createEditPermission: this.props.createEditPermission,
    };
  }

  componentDidMount() {
    //this.CheckAddEditPermission();
    this.getRoomByMaintenance();
    this.getAssignUserList();
    this.GetPMAllProceureList();
  }
  scrollToBottom = () => {
    // this.parentScroll.current.scrollTo({
    //     //top: this.parentScroll.current.clientHeight,
    //     top: this.parentScroll.current.scrollHeight,
    //     behavior: 'smooth',
    // });
  };
  CheckAddEditPermission = () => {
    Facility.FacilityMaintenanceCheckEditStatusPermission().then((res) => {
      this.setState({ createEditPermission: res?.result });
    });
  };
  updatedCommentItem = () => {
    let updatedItem: any = {};
    this.setState({ isSave: true });
    updatedItem.transcationDate = new Date();
    updatedItem.vendorName = "";
    updatedItem.amountTotal = 0;
    return updatedItem;
  };

  hideSkipDropdown = () => {
    this.setState({ isShowDropdown: false });
    this.hideDropdown();
  };

  closeSlideout = () => {
    this.props.closeMaintenanceSlideout();
  }

  handleCompleteStatus = () => {
    let masg = "";
    masg = "Please complete all check list items before changing status to Complete.";
    confirmAlert({
      // title: "Delete Attachment",
      message: masg,
      buttons: [
        
        {
          label: "Ok",
          onClick: () => reject(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });

};
  updateWorkOrderStatus = (type: any, row: any) => {
    // this.setState({isRefresh : true})
    //debugger;
    row = this.state.rightPanelData;
    if (this.state.rightPanelData.status === type) {
      toast.success("Selected status already saved", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        pauseOnHover: false,
        closeOnClick: false,
      });
      return;
    }

    if (type === "Open") {
      if (this.btnOpen.current != null) {
        this.btnOpen.current.disabled = true;
      }
    } else if (type === "In Progress") {
      if (this.btnInprogress.current != null) {
        this.btnInprogress.current.disabled = true;
      }
    } else if (type === "On Hold") {
      if (this.btnOnhold.current != null) {
        this.btnOnhold.current.disabled = true;
      }
    } else if (type === "Completed") {
      if (this.btnComplet.current != null && this.state.doneTask === true) {
        this.btnComplet.current.disabled = true;
      }
    }

    let data = this.state.selectedRows;
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let deleteWorkOrder: any[] = [];
    // for (let i = 0; i < data.length; i++) {
    let newItem: any = {};
     newItem.hotelID = Number(this.state.parentState.hidValue);
     newItem.tenantID = tenantID;
    // newItem.workOrderID = Number(this.state.parentRowData.workOrderID);
    newItem.roomId = Number(this.state.parentState.locationId);
    newItem.cycleID = Number(this.state.parentState.cycleId);
    newItem.statusType = type;
    newItem.reason = this.state.rightPanelData.reason;
    deleteWorkOrder.push(newItem);
    // }
    if (type === "Completed" && this.state.doneTask === false) {
      this.handleCompleteStatus();
      // Utils.toastError("Complete the task first.", {
      //   position: toast.POSITION.BOTTOM_RIGHT,
      //   autoClose: 5000,
      //   pauseOnHover: false,
      //   closeOnClick: false,
      // });
    } else {
      FacilityPreventativeMaintenance.UpdateMaintenanceStatus(deleteWorkOrder)
        .then(async (result: any | null) => {
          if (result != null && result.result != null) {
           // debugger;
            if (result.result.messageCode === "Success") {
              this.enableutton();
              row.status = type;
              if (row.status !== "Skipped") {
                // this.setState(
                //   { rightPanelOpen: false, isRefresh: true },
                //   () => {
                //     this.setState({ isRefresh: false });
                //     if (row.status === "Completed") {
                //       row.reason = null;
                //       row.completionDate = new Date();
                //     }
                //     //this.handleRowClick(row);
                //   }
                // );
                //this.handleSkipClick("", row);

                this.setState({ rightPanelData: row });

              } else {
                this.setState(
                  {
                    getSkippedStatus: true,
                    // rightPanelOpen: false,
                    isRefresh: true,
                  },
                  () => {
                    this.setState({ isRefresh: false });
                    this.handleSkipClick("", row);
                  }
                );
              }

              toast.success("Status changed successfully.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 5000,
                pauseOnHover: false,
                closeOnClick: false,
              });
            } else {

              if (result.result.messageCode === "Error_cycle") {
                Utils.toastError(" This cycle has been completed so status cannot be changed.", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 5000,
                  pauseOnHover: false,
                  closeOnClick: false,
                });
              }else{
                Utils.toastError("Status not changed", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 5000,
                  pauseOnHover: false,
                  closeOnClick: false,
                });

              }

             
            }


            this.enableutton();
          }
          resolve();
        })
        .catch((err) => {
          Utils.toastError(`Server Error, ${err}`, {});
          if (type === "Open") {
            if (this.btnOpen.current != null) {
              this.btnOpen.current.disabled = false;
            }
          } else if (type === "In Progress") {
            if (this.btnInprogress.current != null) {
              this.btnInprogress.current.disabled = false;
            }
          } else if (type === "On Hold") {
            if (this.btnOnhold.current != null) {
              this.btnOnhold.current.disabled = false;
            }
          } else if (type === "Completed") {
            if (this.btnComplet.current != null) {
              this.btnComplet.current.disabled = false;
            }
          }

          reject();
        });
    }
  };
  handleRowClick = (row) => {
   // debugger;
    this.setState(
      { rightPanelData: row[0], rightPanelOpen: true, activeRow: row.id },
      () => {
        this.GetPreventiveMaintenanceUploadFile();
        this.GetPMAllProceureList();
      }
    );
    setTimeout(() => {
      this.rightPanelRef?.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 0);
  };

  GetPreventiveMaintenanceUploadFile = () => {
    // this.ChildAddPMCommentBox.current.getCommentPM();
    let requestlist: any = {};
    // requestlist.roomId = this.state.rightPanelData.roomId;
    // requestlist.cycleID = Number(this.state.rightPanelData.cycleId);

    requestlist.roomId = Number(this.state.parentState.locationId);
    requestlist.cycleID = Number(this.state.parentState.cycleId);
    FacilityPreventativeMaintenance.GetPreventiveMaintenanceUploadFile(
      requestlist
    )
      .then(async (result: any | null) => {
        if (result !== null) {
          for (let i = 0; i < result.length; i++) {
            this["myRef" + i] = React.createRef();
          }
          this.setState({ savedfiles: [] }, () => {
            this.setState({
              savedfiles: result,
              files: [],
              newSelectfiles: [],
              isSpinner: false,
            });
          });
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  };

  GetPMAllProceureList() {
   // debugger;
    let request = {} as IGetProceureDetailsRequestDto;
    request.hotelid = Number(this.state.parentState.hidValue);
    request.roomId = Number(this.state.parentState.locationId);
    request.maintenanceId = Number(
      this.state.parentState.maintenanceScheduleId
    );
    request.gblId = Number(this.state.parentState.cycleId);
    FacilityMaintenance.GetPMAllProceureList(request)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          this.setState({ procedureData: result });
          let procedureArray = this.state.procedureData.map(
            (t) => t.getProceureTaskList
          );
          let taskArray = procedureArray.map((y) => y.length);
          let total = taskArray.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          );

          let flatArray = procedureArray.flatMap((innerArray) => innerArray);
          let doneArray = flatArray.filter((obj) => obj.taskStatus === "done");
          let lengthOfDoneArray = doneArray.length;
          if (total === lengthOfDoneArray) {
            this.setState({ doneTask: true });
          } else {
            this.setState({ doneTask: false });
          }
        } else {
          this.setState({ procedureData: [] });
        }
        resolve();
      })
      .catch((err) => {
        Utils.toastError(`Server Error, ${err}`, {});
        reject();
      });
  }



  enableutton = () => {
    this.btnOpen.current.disabled = false;
    this.btnInprogress.current.disabled = false;
    this.btnOnhold.current.disabled = false;
    this.btnComplet.current.disabled = false;
  };



  getRoomByMaintenance = () => {

    let cycleId = Number(this.state.parentState.cycleId);
    let maintenanceid = Number(
      this.state.parentState.maintenanceScheduleId
    );
    let hid = Number(this.state.parentState.hidValue);
    let type = "Single";
    let locationId = Number(this.state.parentState.locationId);
    FacilityPreventativeMaintenance.GetRoomByMaintenanceRightPanle(
      cycleId,
      hid,
      maintenanceid,
      type,
      locationId
    )
      .then(async (result: any | null) => {
        let DayApproveData: any = [];

        if (result != null && result.length > 0) {
          this.handleRowClick(result);
          //   this.setState(
          //     { leftPanelRoomList: result, tableDataFilter: result },
          //     () => {
          //       // this.handleRowClick(result[0]);
          //     }
          //   );
        } else {
          this.setState({
            leftPanelRoomList: [],
          });
        }
        resolve();
      })
      .catch((err) => {
        Utils.toastError(`Server Error, ${err}`, {});

        reject();
      });
  };



  handleSkipClick = (rowIndex, row) => {
   // debugger;
    // const nextRowIndex = rowIndex + 1;
    // const nextRowData = this.state.leftPanelRoomList[nextRowIndex];
    // this.setState(
    //   {
    //     rightPanelData: nextRowData,
    //    // rightPanelOpen: true,
    //     getSkippedStatus: false,
    //     activeRow: nextRowData.id,
    //   },
    //   () => {
    //     this.GetPreventiveMaintenanceUploadFile();
    //     this.getRoomByMaintenance();
    //     this.GetPMAllProceureList();
    //   }
    // );
    this.GetPreventiveMaintenanceUploadFile();
    this.getRoomByMaintenance();
    this.GetPMAllProceureList();
  };




  hideDropdown = () => {
    if (
      this.skipDropdownRef?.current &&
      this.skipDropdownRef?.current?.ariaExpanded === "true"
    ) {
      this.skipDropdownRef.current.click();
    }
  };

  handleSelectStatus = (controlID: any, id: any) => {
    // this.setState({ rightPanelOpen: false }, () => {
    //   this.FilterByDropDownList(id);
    // });

    this.FilterByDropDownList(id);

  };
  FilterByDropDownList(status: string) {
    let data: any[] = [];
    data = _.cloneDeep(this.state.tableDataFilter);

    if (status && status !== "All") {
      data = data.filter((item) => item.status === status);
    }

    this.setState({ leftPanelRoomList: data, activeRow: 0 });
  }

  hideSkip = () => {
    if (
      this.skipRef?.current &&
      this.skipRef?.current?.ariaExpanded === "true"
    ) {
      this.skipRef.current.click();
    }
  };

  onFileChange = (event: any, mode) => {
    let newfiles: any = [];
    if (event.target.files.length > 0) {
      for (let i = 0; i < event.target.files.length; i++) {
        let fileRow = event.target.files[i];

        newfiles.push(fileRow);
      }
      this.onDrop(newfiles, mode);
      let fileList = document.getElementById("formControlsFile") as any;
      fileList.value = "";
    }
  };
  onDrop = (filelist: any, mode: any) => {
    let size = this.state.fileSize; //5242880//5MB
    let newfiles: any = [];
    let files: any = [];
    let isReturn = true;
    let filestext = "";
    let filesizeval = Number(Number(this.state.fileSize) / (1024 * 1024));
    filelist.forEach((element) => {
      if (element.size > size) {
        filestext = filestext + element.name + ",";
        isReturn = false;
      }
    });

    if (!isReturn) {
      let msg = "One or more files are greater than " + filesizeval + "MB.";
      Utils.toastError(msg, {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "InvoiceSlideout",
      });
    }
    let isInvalidFile = false;
    filelist.forEach((element) => {
      if (
        (element.name
          .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
          .toLowerCase() === "pdf" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "doc" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "docx" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "xls" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "xlsx" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "csv" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "txt" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "bmp" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "tif" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "ppt" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "pptx" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "rtf" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "jpg" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "jpeg" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "png" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "msg" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "eml") &&
        element.size <= size
      ) {
        newfiles.push(element);
        files.push(element);
      } else {
        isInvalidFile = true;
      }
    });
    if (isInvalidFile && isReturn) {
      let msg = "";
      if (filelist.length === 1) {
        msg = "This file type is not supported.";
      } else {
        msg = "Some files are not supported.";
      }
      Utils.toastError(msg, {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "InvoiceSlideout",
      });
    }

    let newfileData = [...this.state.newSelectfiles];
    for (let i = 0; i < newfiles.length; i++) {
      newfiles[i].etype = "newfile";
      newfileData.push(newfiles[i]);
    }

    this.setState({ files, newSelectfiles: newfileData }, () => {
      if (this.props.parentState.PageType === "workOrderDetails") {
        // this.props.changesOfSlideOut(true);
      }
      if (mode === 2) {
        //this.isHandleAction();
      } else {
        if (files.length > 0) {
          this.handleSaveFiles();
        }
      }
    });
  };

  handleSaveFiles = () => {
    this.setState({ isSpinner: true });
    let request: any = {};
    // request.hotelID = Number(this.state.parentState.hidValue);
    request.cycleID = Number(this.state.rightPanelData?.cycleId);
    request.roomId = Number(this.state.rightPanelData?.roomId);
    request.assignedId = Number(this.state.rightPanelData?.assignedTo);
    // request.completionDate = this.state.rightPanelData?.completionDate
    request.status = this.state.rightPanelData?.status;
    let newfileData = [...this.state.newSelectfiles];

    FacilityPreventativeMaintenance.SaveMaintenanceFile(request, newfileData)
      .then(async (result: any | null) => {
        if (result !== null) {
          if (result.saveStatus === "Success") {
            this.GetPreventiveMaintenanceUploadFile();
            this.setState({ isSpinner: false });
            toast.success("File saved successfully.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "InvoiceSlideout",
              autoClose: 5000,
              pauseOnHover: false,
              closeOnClick: false,
            });
          } else {
            this.setState({ isSpinner: false });
            Utils.toastError(result.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "InvoiceSlideout",
            });
          }
        }

        resolve();
      })
      .catch((error) => {
        this.setState({ changeValue: false, isSpinner: false });
        reject();
      });
  };

  handleSelectedItem = (controlID: any, id: any) => {
    if (controlID === 1) {
      this.setState({ assignedId: id });
      let value = this.state.assignToList.filter((item) => item.id === id);
      this.setState({ assignToName: value[0].name, assignTiId: id }, () => {
        this.changesAssignTo(id);
      });
    }
    if (controlID === 2) {
      this.setState({ priorityId: id });
    }
    if (controlID === 3) {
      this.setState({ assetId: id });
    }
    if (controlID === 4) {
      this.setState({ locationId: id });
    }
  };

  handleTaskCheckboxChange = (e, task,procedureName) => {
    //debugger;
    if (this.state.rightPanelData.status !== "Completed") {
      let request: any = {};
      //   request.roomId = this.state.rightPanelData.roomId;
      //   request.cycleId = this.state.rightPanelData.cycleId;
      request.roomId = Number(this.state.parentState.locationId);
      request.cycleId = Number(this.state.parentState.cycleId);
      request.procedureId = task.procedureId;
      request.taskId = task.taskID;
      request.status = e.target.checked === true ? "done" : "pending";
      request.taskName = task.procedureName;
      request.taskGroupName = procedureName;
      FacilityPreventativeMaintenance.AddCycleRoomTaskStatus(request)
        .then(async (result: any | null) => {
          if (result.result.saveStatus === "Success") {
            this.GetPMAllProceureList();
          }
          resolve();
        })
        .catch((err) => {
          Utils.toastError(`Server Error, ${err}`, {});

          reject();
        });
    }
  };


  handleSelectFileUpload = (eventKey: any, file, index) => {
    if (eventKey === "delete") {
      this.setState({ deleteFile: "Yes" });
      let request: any = {};
      // request = Object.assign({}, JSON.parse(JSON.stringify(this.state.formData)));
      let masg = "";
      masg = "Are you sure you want to delete this Attachment?";
      confirmAlert({
        title: "Delete Attachment",
        message: masg,
        buttons: [
          {
            label: "Yes",
            onClick: () => this.isDeletePMFile(file),
          },
          {
            label: "No",
            onClick: () => reject(),
          },
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    } else if (eventKey === "download") {
      this.DownloadPMUploadFile(file);
    } else if (eventKey === "TempDelete") {
      let masg = "";
      masg = "Are you sure you want to delete this Attachment?";
      confirmAlert({
        title: "Delete Attachment",
        message: masg,
        buttons: [
          {
            label: "Yes",
            onClick: () => this.isTempDeleteWorkOrder(file),
          },
          {
            label: "No",
            onClick: () => reject(),
          },
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    }
  };

  isDeletePMFile = (file: any) => {
    let deleteRequest: any = {};

    // deleteRequest.cycleId = Number(this.state.rightPanelData.cycleId);
    // deleteRequest.roomId = Number(this.state.rightPanelData.roomId);
    deleteRequest.cycleId = Number(this.state.parentState.cycleId);
    deleteRequest.roomId = Number(this.state.parentState.locationId);
    deleteRequest.filenameUniqno = file.fileUniqueNo;

    FacilityPreventativeMaintenance.DeletePMFiles(deleteRequest)
      .then(async (result: any | null) => {
        if (result.saveStatus === "Success") {
          toast.success(result.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 5000,
            pauseOnHover: false,
            closeOnClick: false,
          });
        }
        this.GetPreventiveMaintenanceUploadFile();
        resolve();
      })
      .catch((error) => {
        reject();
      });
  };

  DownloadPMUploadFile = (file: any) => {
    file.hid = this.state.parentState.hidValue;
    FacilityPreventativeMaintenance.DownloadPMUploadFile(file)
      .then(async (result: any | null) => {
        resolve();
      })
      .catch((error) => {
        reject();
      });
  };

  isTempDeleteWorkOrder = (file: any) => {
    let data = [...this.state.newSelectfiles];
    let index = data.findIndex((item) => item.etype === "newfile");
    data.splice(index, 1);
    this.setState({ newSelectfiles: data });
  };


  changesAssignTo = (id) => {
    let request = {} as IPMAssignToChangeRequestDto;
    request.AssignedTo = id;
    request.CycleId = this.state.rightPanelData.cycleId;
    request.RoomId = this.state.rightPanelData.roomId;
    FacilityPreventativeMaintenance.UpdateMaintenanceAssignedTo(request)
      .then(async (result: any | null) => {
        if (result !== null) {
          if (result.result.saveStatus === "Success") {
            // this.GetPreventiveMaintenanceUploadFile();
            // this.setState({ isSpinner:false });
            if (this.state.assignToName !== "Select a technician") {
              toast.success(
                "Assigned to " + this.state.assignToName + " successfully.",
                {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  containerId: "InvoiceSlideout",
                  autoClose: 5000,
                  pauseOnHover: false,
                  closeOnClick: false,
                }
              );
            }
            this.getRoomByMaintenance();
          } else {
            this.setState({ isSpinner: false });
            Utils.toastError(result.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "InvoiceSlideout",
            });
          }
        }

        resolve();
      })
      .catch((error) => {
        this.setState({ changeValue: false, isSpinner: false });
        reject();
      });
  };


  getAssignUserList() {
    let request = {} as IWorkOrderRequestDto;
    request.hotelID = Number(this.state.parentState.hidValue);
    request.type = "PreventativeAssignedTo";
    WorkOrder.GetFcUserDetailsList(request)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          let Data = result.map((x) => {
            return { id: x?.user_UniqueID, name: x?.employee };
          });

          this.setState({ assignToList: [] }, () => {
            this.setState({
              assignToList: [{ id: -1, name: "Select a technician" }, ...Data],
            });
            // this.setState({assignToListRightPanel:Data});
            this.setState({
              assignToListToChild: [
                { id: -1, name: "Select a technician" },
                ...Data,
              ],
            });
          });
        } else {
          this.setState({
            assignToList: [{ id: -1, name: "All" }],
            assignToListToChild: [
              {
                id: -1,
                name: "Select a technician",
                assignToListRightPanel: [],
              },
            ],
          });
        }
        resolve();
      })
      .catch((err) => {
        Utils.toastError(`Server Error, ${err}`, {});

        reject();
      });
  }


  render() {
    const { createEditPermission } = this.state;
    let dateFormat = "MM/DD/YY";

    const files1 = this.state.savedfiles.map((file, index) => (
      <>
        <li key={file.filename}>
          <div className="d-flex align-items-center justify-content-start">
            <div className="icon">
              {file?.type?.split("/")[0] === "image" && (
                <FontAwesomeIcon icon={faFileImage} />
              )}
              {file?.filename.substring(
                file?.filename.lastIndexOf(".") + 1,
                file?.filename.length
              ) === "pdf" && <FontAwesomeIcon icon={faFilePdf} />}
              {file?.filename.substring(
                file?.filename.lastIndexOf(".") + 1,
                file?.filename.length
              ) === "xlsx" && <FontAwesomeIcon icon={faFileExcel} />}
              {file?.filename.substring(
                file?.filename.lastIndexOf(".") + 1,
                file?.filename.length
              ) === "csv" && <FontAwesomeIcon icon={faFileCsv} />}
              {file?.filename.substring(
                file?.filename.lastIndexOf(".") + 1,
                file?.filename.length
              ) === "word" && <FontAwesomeIcon icon={faFileWord} />}
              {file?.type?.split("/")[0] !== "image" &&
                file?.filename.substring(
                  file?.filename.lastIndexOf(".") + 1,
                  file?.filename.length
                ) !== "pdf" &&
                file?.filename.substring(
                  file?.filename.lastIndexOf(".") + 1,
                  file?.filename.length
                ) !== "xlsx" &&
                file?.filename.substring(
                  file?.filename.lastIndexOf(".") + 1,
                  file?.filename.length
                ) !== "csv" &&
                file?.filename.substring(
                  file?.filename.lastIndexOf(".") + 1,
                  file?.filename.length
                ) !== "word" && <FontAwesomeIcon icon={faFile} />}
            </div>
            <div className="file-info d-flex align-items-center">
              <div className="name-loader d-flex align-items-center">
                <div className="name">
                  <EllipsisWithTooltip placement="bottom">
                    <div className="full-name">{file.filename}</div>
                  </EllipsisWithTooltip>
                  {/* <div className="details">{file.createDate} - {file.pageno} Page</div> */}
                </div>
              </div>
              <div className="formate-percentage">
                <span className="formate d-flex justify-content-center align-items-center">
                  {file?.filename.substring(
                    file?.filename.lastIndexOf(".") + 1,
                    file?.filename.length
                  )}
                </span>
              </div>
            </div>
            <div className="action">
              {/* {!this.state.bulkSelect && ( */}
              <Dropdown
                className="more-action"
                alignRight
                onSelect={(event: any) =>
                  this.handleSelectFileUpload(event, file, index)
                }
              >
                <Dropdown.Toggle
                  className="btn-outline-primary btn btn-primary more"
                  id="dropdown-more"
                // disabled={!createEditPermission}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {this.state.moreActionFileUpload1.map(
                    (item: any, idx: any) => (
                      <>
                        {!createEditPermission ? (

                          <div>
                            {this.state.moreActionFileUpload1[idx].eventKey !== "delete" && (
                              <Dropdown.Item

                                eventKey={
                                  this.state.moreActionFileUpload1[idx].eventKey
                                }
                                key={idx}
                              >
                                {this.state.moreActionFileUpload1[idx].name}

                              </Dropdown.Item>
                            )}

                          </div>

                        ) : (
                          <Dropdown.Item

                            eventKey={
                              this.state.moreActionFileUpload1[idx].eventKey
                            }
                            key={idx}
                          >
                            {this.state.moreActionFileUpload1[idx].name}
                          </Dropdown.Item>
                        )}
                      </>
                    )
                  )}
                </Dropdown.Menu>
              </Dropdown>
              {/* )} */}
            </div>
          </div>
        </li>
      </>
    ));

    const files = this.state.newSelectfiles.map((file, index) => (
      <>
        <li key={file.name}>
          <div className="d-flex align-items-center justify-content-start">
            <div className="icon">
              {file?.type?.split("/")[0] === "image" && (
                <FontAwesomeIcon icon={faFileImage} />
              )}
              {file?.name.substring(
                file?.name.lastIndexOf(".") + 1,
                file?.name.length
              ) === "pdf" && <FontAwesomeIcon icon={faFilePdf} />}
              {file?.name.substring(
                file?.name.lastIndexOf(".") + 1,
                file?.name.length
              ) === "xlsx" && <FontAwesomeIcon icon={faFileExcel} />}
              {file?.name.substring(
                file?.name.lastIndexOf(".") + 1,
                file?.name.length
              ) === "csv" && <FontAwesomeIcon icon={faFileCsv} />}
              {file?.name.substring(
                file?.name.lastIndexOf(".") + 1,
                file?.name.length
              ) === "word" && <FontAwesomeIcon icon={faFileWord} />}
              {file?.type?.split("/")[0] !== "image" &&
                file?.name.substring(
                  file?.name.lastIndexOf(".") + 1,
                  file?.name.length
                ) !== "pdf" &&
                file?.name.substring(
                  file?.name.lastIndexOf(".") + 1,
                  file?.name.length
                ) !== "xlsx" &&
                file?.name.substring(
                  file?.name.lastIndexOf(".") + 1,
                  file?.name.length
                ) !== "csv" &&
                file?.name.substring(
                  file?.name.lastIndexOf(".") + 1,
                  file?.name.length
                ) !== "word" && <FontAwesomeIcon icon={faFile} />}
            </div>
            <div className="file-info d-flex align-items-center">
              <div className="name-loader d-flex align-items-center">
                <div className="name">
                  <EllipsisWithTooltip placement="bottom">
                    {/* <div className="full-name" onClick={() => { this.handlePreview(file) }}>{file.name}</div> */}
                    <div className="full-name">{file.name}</div>
                  </EllipsisWithTooltip>
                </div>
              </div>
              <div className="formate-percentage">
                <span className="formate d-flex justify-content-center align-items-center">
                  {file?.name.substring(
                    file?.name.lastIndexOf(".") + 1,
                    file?.name.length
                  )}
                </span>
              </div>
            </div>
            <div className="action">
              {/* <button className="btn-outline-primary remove-pdf more btn btn-primary" onClick={() => { this.handleSelectFileUpload("TempDelete", file, 0) }} >
                                    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"></path></svg>
                                   
                                </button> */}

              {this.state.isSpinner && (
                <button className="btn-outline-primary remove-pdf more btn btn-primary">
                  <Spinner
                    className="mr-2"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </button>
              )}
            </div>
          </div>
        </li>
      </>
    ));


    //const { createEditPermission } = this.props;

    return (
      <div>

        {this.state.rightPanelOpen && (
          <div className="right-panel">
            <div className="wrapper">
              <div className="panel-header" ref={this.parentScroll}>
                <div className="slideout-title mr-auto">
                  <EllipsisWithTooltip placement="bottom">
                    {this.state.rightPanelData.roomName}
                  </EllipsisWithTooltip>
                  <div>
                    <span
                      className={`badge ${this.state.rightPanelData.status.toLowerCase() ==
                        "in progress"
                        ? "progress"
                        : this.state.rightPanelData.status.toLowerCase() ==
                          "skipped" ||
                          this.state.rightPanelData.status.toLowerCase() ==
                          "on hold"
                          ? "red-badge"
                          : this.state.rightPanelData.status.toLowerCase() ==
                            "completed"
                            ? "complete"
                            : ""
                        }`}
                    >
                      {this.state.rightPanelData.status}
                    </span>
                  </div>
                </div>
                <div className="d-flex">
                  {
                    this.state.rightPanelData.status.toLowerCase() !==
                    "skipped" && this.state.rightPanelData.status.toLowerCase() !==
                    "completed"
                    ?
                    <Dropdown className="more-action" alignRight>
                      <Dropdown.Toggle
                        className="btn-outline-primary btn btn-primary more border-0"
                        id="dropdown-more"
                        ref={this.skipDropdownRef}
                        disabled={!createEditPermission}
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                          <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                          <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                        </svg>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {this.state.rightPanelData.status.toLowerCase() !== "completed"
                          && this.state.rightPanelData.status.toLowerCase() !== "skipped"
                          && (
                            <div
                              className="dropdown-item"
                              onClick={() =>
                                this.setState({ isShowDropdown: true })
                              }
                            >
                              Skip Room
                            </div>
                          )}


                        {this.state.isShowDropdown && (
                          <SkipRoom
                            handleHideDropdown={this.hideSkipDropdown}
                            updateWorkOrderStatus={
                              this.updateWorkOrderStatus
                            }
                            parentState={this.state}
                          />
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                    :
                    <></>
                  }
                  {/* <button 
                    onClick={this.closeSlideout}
                    type="button" className="btn wht-bg cross pr-0 pl-2">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z" fill="#84888C"></path></svg>
                  </button> */}
                </div>
              </div>
              <div
                className={`panel-body ${this.state.changeValue && "bottom-spacer"
                  } ${this.props.parentState.workorderPermission === "No"
                    ? "controls-disabled"
                    : ""
                  }`}
                ref={this.rightPanelRef}
              >
                <h3 className="label">Status</h3>
                <div className="buttons-wrapper">
                  <div className="status-list">
                    <button
                      disabled={!createEditPermission}
                      ref={this.btnOpen}
                      onClick={() =>
                        this.updateWorkOrderStatus(
                          "Open",
                          this.state.rightPanelData
                        )
                      }
                      className={
                        this.state.rightPanelData.status === "Open"
                          ? "active"
                          : ""
                      }
                    >
                      <div className="circle"></div>
                      <span>Open</span>
                    </button>
                    <button
                      disabled={!createEditPermission}
                      ref={this.btnInprogress}
                      onClick={() =>
                        this.updateWorkOrderStatus(
                          "In Progress",
                          this.state.rightPanelData
                        )
                      }
                      className={
                        this.state.rightPanelData.status ===
                          "In Progress"
                          ? "active"
                          : ""
                      }
                    >
                      <FontAwesomeIcon icon={faPlay} />
                      <span>In Progress</span>
                    </button>
                    <button
                      disabled={!createEditPermission}
                      ref={this.btnOnhold}
                      onClick={() =>
                        this.updateWorkOrderStatus(
                          "On Hold",
                          this.state.rightPanelData
                        )
                      }
                      className={
                        this.state.rightPanelData.status === "On Hold"
                          ? "active"
                          : ""
                      } 
                    >
                      <FontAwesomeIcon icon={faPause} />
                      <span>On Hold</span>
                    </button>
                    <button
                      disabled={!createEditPermission}
                      ref={this.btnComplet}
                      onClick={() =>
                        this.updateWorkOrderStatus(
                          "Completed",
                          this.state.rightPanelData
                        )
                      }
                      className={
                        this.state.rightPanelData.status ===
                          "Completed"
                          ? "active"
                          : ""
                      }
                    >
                      <FontAwesomeIcon icon={faCheck} />
                      <span>Complete</span>
                    </button>
                  </div>
                </div>
                <div className="order-info">
                  <Form.Group controlId="location">
                    <Form.Label>Location</Form.Label>
                    <div className="value">
                      {this.state.rightPanelData.roomName}
                    </div>
                  </Form.Group>

                  {/* <Form.Group controlId="location">
                                <Form.Label>Asset </Form.Label>
                                <div className="value">{this.state.rightPanelData.roomName}</div>
                            </Form.Group> */}

                  <Form.Group
                    controlId="assignedTo"
                    className="assignedto-dropdown"
                  >
                    <Form.Label>Assigned To</Form.Label>
                    {this.state.assignToList.length > 0 && (
                      <div className={`dropdown-interface ${createEditPermission == false ? "disabled" : ""}`}>
                        <SingleSearchDropdownList
                          isdisable={!createEditPermission}
                          // showIcon={
                          //   this.state.assignTiId === -1 ||
                          //     this.state.assignTiId === null ||
                          //     this.state.assignTiId === undefined
                          //     ? true
                          //     : false
                          // }
                          showIcon={
                            this.state.rightPanelData.assignedTo === -1 ||
                              this.state.rightPanelData.assignedTo === null ||
                              this.state.rightPanelData.assignedTo === undefined ||
                              this.state.rightPanelData.assignedTo === 0
                              ? true
                              : false
                          }



                          isRefresh={this.state.isRefresh}
                          itemList={this.state.assignToList}
                          handleSelectedItem={this.handleSelectedItem.bind(
                            this,
                            1
                          )}
                          defaultItem={this.state.assignToList}
                          defaultText={"Assigned To"}
                          // defaultName={this.state.assignToList.filter((item) => item.id === this.state.parentRowData.user_UniqueID)[0]?.name}
                          defaultName={
                            this.state.assignToList.filter(
                              (item) =>
                                item.id ===
                                this.state.rightPanelData.assignedTo
                            )[0]?.name === undefined
                              ? "Select a technician"
                              : this.state.assignToList.filter(
                                (item) =>
                                  item.id ===
                                  this.state.rightPanelData
                                    .assignedTo
                              )[0]?.name
                          }
                          controlID="1"
                        />
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group
                    controlId="maintenance"
                  //   className={`last-maintenance ${
                  //     this.state.rightPanelData.status.toLowerCase() ==
                  //     "skipped"
                  //       ? "skipped"
                  //       : ""
                  //   }`}
                  >
                    <Form.Label>Last Maintenance </Form.Label>
                    <div className="value">
                      {this.state.rightPanelData?.completionDate?.length > 1 ?
                      <div className={`date ${this.state.rightPanelData.status.toLowerCase() ==
                          "skipped" ? "red-color" : "" }`}>
                        {this.state.rightPanelData?.completionDate !==
                          null
                          ? moment(
                            this.state.rightPanelData
                              ?.completionDate
                          ).format("MM/DD/YY")
                          : ""}
                      </div>
                      :
                      <></>
                      }
                      {this.state.rightPanelData.previousSkipped.toLowerCase() ==
                        "skipped" ? (
                        <OutsideClickHandler
                          onOutsideClick={this.hideSkip}
                        >
                          <Dropdown className="more-action comment-dropdown">
                            <DropdownToggle
                              disabled={!createEditPermission}
                              className="alert-icon"
                              ref={this.skipRef}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="13"
                                viewBox="0 0 14 13"
                                fill="none"
                              >
                                <path
                                  d="M7.58963 0.68784C7.35896 0.25184 6.64163 0.25184 6.41096 0.68784L0.410965 12.0212C0.356954 12.1228 0.330177 12.2366 0.333245 12.3516C0.336312 12.4666 0.369118 12.5789 0.428467 12.6774C0.487816 12.776 0.571683 12.8575 0.671896 12.914C0.77211 12.9705 0.885252 13.0001 1.0003 12.9998H13.0003C13.1153 13.0001 13.2283 12.9705 13.3285 12.9141C13.4286 12.8576 13.5124 12.7761 13.5717 12.6776C13.631 12.5791 13.6638 12.4669 13.6668 12.352C13.6698 12.2371 13.643 12.1233 13.589 12.0218L7.58963 0.68784ZM7.66697 10.9998H6.33363V9.66651H7.66697V10.9998ZM6.33363 8.33317V4.99984H7.66697L7.66763 8.33317H6.33363Z"
                                  fill="#863902"
                                />
                              </svg>
                              Previously Skipped
                            </DropdownToggle>
                            <DropdownMenu renderOnMount>
                              <div className="user-info">
                                <div className="d-flex">
                                  <div className="user">
                                    <img src={profile} alt="User" />
                                  </div>
                                  <div className="user-details">
                                    <h4>
                                      {
                                        this.state.rightPanelData
                                          .username
                                      }
                                    </h4>
                                    <h6>
                                      <span>
                                        {moment(
                                          this.state.rightPanelData
                                            ?.completionDate
                                        ).format("MM/DD/YY")}
                                      </span>{" "}
                                      at{" "}
                                      <span>
                                        {moment(
                                          this.state.rightPanelData
                                            .completionDate
                                        ).format("hh:mm A")}
                                      </span>
                                    </h6>
                                  </div>
                                </div>
                                <div className="body">
                                  <h5>
                                    {this.state.rightPanelData.reason}
                                  </h5>
                                </div>
                              </div>
                            </DropdownMenu>
                          </Dropdown>
                        </OutsideClickHandler>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Form.Group>
                </div>
                {this.state.procedureData.map((procedure, index) => (
                  <div className="mb-4 maintenanace-table-wrapper" id="maintenanace-procedure-tables">
                    <h4 className="label">
                      {procedure.procedureName}
                    </h4>
                    <div className="react-bootstrap-table table-outer-border">
                      <table className="table">
                        <tr>
                          <th></th>
                          <th></th>
                        </tr>
                        <tbody>
                          {procedure.getProceureTaskList.map(
                            (task, taskIndex) => (
                              <tr key={taskIndex}>
                                <td>
                                  <input
                                    disabled={!createEditPermission}
                                    checked={
                                      task.taskStatus === "done"
                                        ? true
                                        : false
                                    }
                                    type="checkbox"
                                    onChange={(e) =>
                                      this.handleTaskCheckboxChange(
                                        e,
                                        task,procedure.procedureName
                                      )
                                    }
                                  />
                                </td>
                                <td><EllipsisWithTooltip placement="bottom">{task.procedureName}</EllipsisWithTooltip></td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>

                    {/* <BootstrapTable
                                id="std-room-annual"
                                data={this.state.taskData}
                                columns={stdRoomColumns}
                                keyField="std-rooms"
                                selectRow={stdRoomSelectRow}
                            /> */}
                  </div>
                ))}
                <div className={`filter-sec upload-section ${createEditPermission == false ? "disabled" : ""}`}>
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <div className="d-flex">
                      <Form.Label className="mr-auto label">
                        Files
                      </Form.Label>
                      {this.state.savedfiles.length > 0 && (
                        <div className="upload-link">
                          {/* <button type="button" className="btn wht-bg link-btn mr-auto" onClick={this.OpenViewer}>
                                                        {this.state.isOpenViewer ? 'Close Viewer' : 'Open Viewer'}
                                                    </button>  */}
                        </div>
                      )}
                    </div>
                    <div>
                      <Dropzone
                        disabled={!createEditPermission}
                        // disabled={isBedgeProcessing}
                        onDrop={this.onDrop}
                      // accept="image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.txt,.bmp,.tif,.ppt,.pptx,.rtf,.jpg,.png,.msg,.eml"
                      >
                        {({
                          getRootProps,
                          getInputProps,
                          isDragActive,
                          isDragReject,
                        }) => (
                          <div
                            className={`upload-wrapper ${files1.length >= 1
                              ? "remove-border"
                              : ""
                              }`}
                          >
                            <section
                              className={
                                isDragActive
                                  ? "container upload-area default drag-active "
                                  : "container upload-area default"
                              }
                            >
                              {!this.state.bulkSelect && (
                                <div
                                  {...getRootProps({
                                    className:
                                      "dropzone d-flex align-items-center",
                                    onClick:
                                      this.state.newSelectfiles
                                        .length > 0 ||
                                        this.state.savedfiles.length > 0
                                        ? (event) =>
                                          event.stopPropagation()
                                        : (event) => event,
                                  })}
                                >
                                  <input {...getInputProps()} />
                                  {this.state.newSelectfiles.length <
                                    1 &&
                                    this.state.savedfiles.length <
                                    1 && (
                                      <p>
                                        Drag and drop files or{" "}
                                        <span>browse files</span> to
                                        upload.
                                      </p>
                                    )}
                                  {(this.state.newSelectfiles.length >
                                    0 ||
                                    this.state.savedfiles.length >
                                    0) &&
                                    isDragActive && (
                                      <p>
                                        Drag and drop files here to
                                        upload.
                                      </p>
                                    )}
                                  {/* {!isDragActive && (<p>Drag and drop or <span>browse files</span> to upload</p>)} */}
                                </div>
                              )}

                              {/* {this.state.bulkSelect && ( 
                                                                <div className="dropzone d-flex align-items-center">
                                                                    <p>Drag and drop or <span>browse files</span> to upload</p>
                                                                </div>
                                                            )}   */}
                              {(this.state.newSelectfiles.length >
                                0 ||
                                this.state.savedfiles.length > 0) && (
                                  <aside className="d-flex flex-column">
                                    <ul className="p-0">{files1}</ul>
                                    <ul className="p-0">{files}</ul>
                                  </aside>
                                )}
                            </section>
                            {!this.state.bulkSelect &&
                              (this.state.newSelectfiles.length > 0 ||
                                this.state.savedfiles.length > 0) && (
                                <div className="upload-link mrgn-top">
                                  <button
                                    type="button"
                                    className="btn p-0 wht-bg link-btn mr-auto"
                                  >
                                    Add Attachment
                                  </button>
                                  <Form.Control
                                    disabled={!createEditPermission}
                                    id="formControlsFile"
                                    type="file"
                                    multiple
                                    onChange={(event: any) =>
                                      this.onFileChange(event, "1")
                                    }
                                    accept="image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.txt,.bmp,.tif,.ppt,.pptx,.rtf,.jpg,.png,.msg,.eml"
                                  />
                                </div>
                              )}
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  </Form.Group>
                </div>
                <hr className="mt-0 mb-4" />
                <div className="comments">
                  <Tabs defaultActiveKey="Comments">
                    <Tab eventKey="Comments" title="Comments">
                      {/* <div className="add-comment">
                                        <img src={profile} className="user" alt="user" />
                                        <Form.Control type="text" placeholder="Write a comment..." />
                                    </div> */}
                      <div
                        className={
                          this.props.parentState
                            .workorderPermission === "No"
                            ? "comment-disabled"
                            : ""
                        }
                      >
                        <AddPMCommentBox
                          ref={this.ChildAddPMCommentBox}
                          hotelid={this.state.parentState?.hidValue}
                          cycleId={Number(
                            this.state.rightPanelData.cycleId
                          )}
                          roomId={this.state.rightPanelData.roomId}
                          entryType={this.props.pageType}
                          //  uniqueNo={this.state.formData.actionType === "copy" ? 0 : this.props.pageType === "Invoice" ? this.state.formData.uniqueno : this.state.formData.incompleteUniqueno}
                          precommentlist={this.state.htmlcomment}
                          parentScrollMethod={this.scrollToBottom}
                          updatedCommentItem={this.updatedCommentItem}
                          parentState={this.state}
                          addCommentDisabled={!createEditPermission}
                        />
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        )}

      </div>
    );

  }

}
