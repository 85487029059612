import axios from 'axios';
import Instense from './Axios-config';
import { API_ROOT } from "./Api-config";


export class ReportExport {
  public static reportDownload = async (reportParameters: string): Promise<any | null> => {
    let bodyFormData = new FormData();
    bodyFormData.append('jsonReportParameters', reportParameters);
    return axios.post(`${API_ROOT.reportingHost}/ReportWriter/Export`, bodyFormData, {
      responseType: 'blob',
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    }).then((response) => {
      return response.data;
    }).catch((error) => {
      console.log("Error", error);
    });
    // return axios.post(`${API_ROOT.reportingHost}/ReportWriter/Export`, bodyFormData, 
    // { responseType: 'blob','Access-Control-Allow-Origin':'*' }).then((response) => {
    //     return response.data;
    // }).catch((error) => {
    //     console.log(error);
    // });
  }

  public static reportDownloadCSV = async (reportParameters: string): Promise<any | null> => {
    let bodyFormData = new FormData();
    bodyFormData.append('jsonReportParameters', reportParameters);
    return axios.post(`${API_ROOT.reportingHost}/ReportWriter/ExportCSV`, bodyFormData, {
      responseType: 'blob',
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    }).then((response) => {
      return response.data;
    }).catch((error) => {
      console.log("Error", error);
    });
    // return axios.post(`${API_ROOT.reportingHost}/ReportWriter/Export`, bodyFormData, 
    // { responseType: 'blob','Access-Control-Allow-Origin':'*' }).then((response) => {
    //     return response.data;
    // }).catch((error) => {
    //     console.log(error);
    // });
  }
}