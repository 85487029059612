/* eslint-disable array-callback-return */
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {
  Container,
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { SingleDropdownListWithoutSearch } from "../../../Common/Components/SingleDropdownListWithoutSearch";
import { HidSelector } from "../../../Common/Components/HidSelector";
import { FiChevronDown } from "react-icons/fi";
import { resolve, reject } from "q";
import { Journal as Journalsevice } from "../../../Common/Services/Journal";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import "../../../Modules/Register/register.scss";
import { ToastContainer, toast } from "react-toastify";
import chevronRight from "../../../Common/Assets/Images/icons/bx-chevron-right.svg";
import TableSearch from "../../../Common/Components/TableSearch";
import $ from "jquery";
import { TransactionSlideout } from "./transactionSlideout";
import {
  RoleSubPermissionSetting,
  IPageSubPermissionRole,
} from "../../../Common/Services/RoleSubPermissionSetting";
import Spinner from "react-bootstrap/Spinner";
import { JournalImport } from "../JournalEntry/JournalImport";
import { JournalLogHistory } from "./JournalLogHistory";
import _ from "lodash";
import { Utils } from "../../../Common/Utilis";
import { LaborPerformance as laborPerformance } from "../../../Common/Services/LaborPerfomance";
const { SearchBar } = Search;
let openRow: any = {};

export class Journal extends React.Component<any, any> {
  private tableSearch: any;
  private childHID: any;
  private dayCount: any;
  constructor(props: any) {
    super(props);
    this.childHID = React.createRef();
    this.dayCount = React.createRef();
    this.tableSearch = React.createRef();
    this.state = {
      hidValue: "Select",
      hotelName: "Select",
      isBgBlue: false,
      customDate: false,
      hotelList: [],
      istableloader: false,
      regularlist: [],
      splitlist: [],
      tableData: [],
      tableData2: [],
      hasAddPermission: false,
      viewType: "regular",
      journalRequest: {
        hotelID: -1,
        selectType: "30",
        startDate: "",
        endDate: "",
      },
      journalExportReq: {
        hotelID: -1,
        selectType: "30",
        startDate: "",
        endDate: "",
      },
      dayFilter: [
        { id: 1, name: "Last 30 Days" },
        { id: 2, name: "Last 60 Days" },
        { id: 3, name: "Last 90 Days" },
        { id: 4, name: "Custom" },
      ],
      dayText: "Last 30 Days",
      dayValue: 1,
      dateRange: "",
      start: Utils.getHotalCurrentDate(),
      end: Utils.getHotalCurrentDate(),
      isSelect: true,
      isFilter: false,
      isloader: false,
      vid: 0,
      isExpand: false,
      densityView: [
        { id: 1, densityType: "densityView_1", name: "WIDE" },
        { id: 2, densityType: "densityView_3", name: "NARROW" },
      ],
      defaultDensityView: "densityView_3",
      expandedList: [],
      expList: [],
      calenderVisibleClass: "",
      calenderHideClass: "mr-auto",
      JERequest: {
        UniqueNo: 0,
      },
      isTRSideOut: false,
      isExportRun: false,
      JournalStatementModal: false,
      showChangeLogModal: false,
      hasImportPermission: false,
      SortBy: "Date",
      Status: "Status",
    };
    this.handleDateCallback = this.handleDateCallback.bind(this);
  }

  getParamValueByParamName(paramName: string): any {
    let valuesArray = decodeURIComponent(this.props.history.location.search)
      ?.replace("?", "")
      ?.split("&");
    window.history.replaceState(null, "");
    return valuesArray;
  }

  componentDidMount() {
    this.getDefaultView();
    this.getWebTimeClockManagerPermission();
    this.getJournalImportPermission();
    const valuesArray = this.getParamValueByParamName("");
    if (valuesArray.length > 1) {
      const { history } = this.props;
      history.replace();
      const uniqueNo = valuesArray ? window.atob(valuesArray[0]?.split("=")[1]) : "";
      const backHid = valuesArray ? window.atob(valuesArray[2]?.split("=")[1]) : "";

      let JERequest = { ...this.state.JERequest };
      JERequest.UniqueNo = uniqueNo;
      this.setState({
        JERequest,
        hidValue: backHid,
        hotelName: "",
        isTRSideOut: true,
        isBgBlue: true,
      });
    }
  }

  showHideChangeLogModal = () => {
    this.setState({
      showChangeLogModal: !this.state.showChangeLogModal,
    });
  };

  selectHotelDropDown = (hotelDetails: any) => {
    const valuesArray = this.getParamValueByParamName("");
    if (valuesArray.length > 1) {
      return;
    }

    this.setState({
      hotelName: hotelDetails.lettercode,
      hidValue: hotelDetails.hotelID,
    });
    let journalRequest: any = {
      hotelID: hotelDetails.hotelID,
      selectType: "30",
      startDate: "",
      endDate: "",
    };
    let journalExportReq: any = {
      hotelID: hotelDetails.hotelID,
      selectType: "30",
      startDate: "",
      endDate: "",
    };
    this.setState(
      {
        accountNameValue: 1,
        dayText: "Last 30 Days",
        journalRequest: journalRequest,
        journalExportReq: journalExportReq,
        tableData: [],
        dateRange: "",
        start: Utils.getHotalCurrentDate(),
        end: Utils.getHotalCurrentDate(),
        isSelect: true,
        isFilter: false,
        viewType: "regular",
      },
      () => {
        let journalRequest = { ...this.state.journalRequest };
        journalRequest.hotelID = hotelDetails.hotelID;
        this.getJournallist(journalRequest);
      }
    );
    if (this.childHID.current !== null) {
      let hidList = this.childHID.current.handleHIDList();
      this.setState({ hotelList: hidList, SortBy: "Date", Status: "Status" });
    }
  };

  getWebTimeClockManagerPermission = () => {
    let localStorageUser = JSON.parse(localStorage.getItem("storage")!);
    let requestObject = {} as IPageSubPermissionRole;
    requestObject.permissionID = 148;
    requestObject.Module = "Accounting";
    requestObject.Type = "page";
    requestObject.RoleID = localStorageUser.rolId;
    requestObject.TenantID = localStorageUser.tenantID;
    RoleSubPermissionSetting.GetPageSubPermissionWebClockDetails(requestObject)
      .then(async (result: any | null) => {
        let { hasAddPermission } = this.state;
        if (result != null && result.length > 0) {
          for (let index of result) {
            if (index?.displayName.toString().toLowerCase() === "Add".toLocaleLowerCase() && index?.moduleName === "Accounting") {
              hasAddPermission = true;
            }
          }
          this.setState({ hasAddPermission });
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  };

  getJournalImportPermission = () => {
    let requestObject = {} as any;
    requestObject.permissionID = 148;
    requestObject.Module = "Accounting";
    requestObject.Type = "page";
    RoleSubPermissionSetting.GetPageSubPermissionDetails(requestObject)
      .then(async (result: any | null) => {
        let { hasImportPermission } = this.state;
        if (result != null && result.length > 0) {
          for (let index of result) {
            if (index?.displayName.toString().toLowerCase() === "import journals" && index?.moduleName === "Accounting") {
              hasImportPermission = true;
            }
          }
          this.setState({ hasImportPermission });
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  };

  getJournallist = (registerRequest: any) => {
    this.setState({ istableloader: true /*isloader: true*/ });
    let regularlist: any = [];
    let exList: any = [];
    Journalsevice.GetJournalList(registerRequest)
      .then(async (result: any[] | null) => {
        if (result !== null) {
          if (result.length > 0) {
            let regex = /[,\s]/g;
            result.forEach((items) => {
              items["transTotal1"] = parseFloat(items.transTotal.replace(regex, ""));
              items["dr1"] = parseFloat(items.dr.replace(regex, ""));
              items["cr1"] = parseFloat(items.cr.replace(regex, ""));
              items["coaName1"] = items.coaName.replaceAll("&amp;", "&");
            }
            );
            regularlist = _.sortBy(result, ["date1", "date1"]).reverse();
            let expandList = result.filter((item) => item.viewType === 1);
            expandList.forEach((item, i) => {
              item.id = i + 1;
            });
            for (let i = 1; i <= expandList.length; i++) {
              exList.push(i);
            }
            this.setState(
              {
                expandedList: [],
                expList: exList,
                registerList: result,
                tableData2: regularlist,
                tableData: regularlist,
                istableloader: false,
                //isloader: false
              },
              () => {
                this.CleanSearch();
              }
            );
          } else {
            this.setState({ istableloader: false /*isloader: false*/ }, () => {
              this.CleanSearch();
            });
          }
        } else {
          this.setState({ istableloader: false /*isloader: false*/ }, () => {
            this.CleanSearch();
          });
        }
        resolve();
      })
      .catch((error) => {
        Utils.toastError(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        this.setState({ istableloader: false }, () => {
          this.CleanSearch();
        });
        reject();
      });
  };

  handleSelectedItem = (controlID: any, id: any) => {
    let selectType;
    let dayText: any;
    this.setState({
      predayValue: this.state.dayValue,
      calenderVisibleClass: "",
      calenderHideClass: "mr-auto",
    });
    if (id === 1) {
      selectType = "30";
      dayText = "Last 30 Days";
    } else if (id === 2) {
      selectType = "60";
      dayText = "Last 60 Days";
    } else if (id === 3) {
      selectType = "90";
      dayText = "Last 90 Days";
    } else if (id === 4) {
      selectType = "DateRange";
      dayText = "Custom";
      this.setState({ customDate: true });
      let now = Utils.getHotalCurrentDate();
      let pre30days;
      if (this.state.dayValue === 1) {
        pre30days = new Date(now.setDate(now.getDate() - 30));
      } else if (this.state.dayValue === 2) {
        pre30days = new Date(now.setDate(now.getDate() - 60));
      } else if (this.state.dayValue === 3) {
        pre30days = new Date(now.setDate(now.getDate() - 90));
      }

      let calenderYear = pre30days.getFullYear();
      let calenderDate =
        pre30days.getDate() > 9
          ? pre30days.getDate()
          : 0 + "" + pre30days.getDate();
      let calenderMonth =
        pre30days.getMonth() + 1 > 9
          ? pre30days.getMonth() + 1
          : 0 + "" + (pre30days.getMonth() + 1);
      let tempDate = calenderMonth + "/" + calenderDate + "/" + calenderYear;

      let datee = Utils.getHotalCurrentDate();
      let calenderYeare = datee.getFullYear();
      let calenderDatee =
        datee.getDate() > 9 ? datee.getDate() : 0 + "" + datee.getDate();
      let calenderMonthe =
        datee.getMonth() + 1 > 9
          ? datee.getMonth() + 1
          : 0 + "" + (datee.getMonth() + 1);
      let tempDatee =
        calenderMonthe + "/" + calenderDatee + "/" + calenderYeare;

      this.setState({
        dateRange: tempDate + " - " + tempDatee,
        start: tempDate,
        end: tempDatee,
        dayText,
        selectType,
        calenderVisibleClass: "mr-auto",
        calenderHideClass: "",
      });
      setTimeout(() => {
        $("#dtrange").trigger("click");
      }, 100);
    }
    if (id !== 4) {
      this.setState({ istableloader: true, tableData: [] }, () => {
        let journalRequest = { ...this.state.journalRequest };
        let journalExportReq = { ...this.state.journalExportReq };
        journalRequest.selectType = selectType;
        journalExportReq.selectType = selectType;
        this.getJournallist(journalRequest);
        this.setState({
          journalRequest,
          journalExportReq,
          dateRange: "",
          customDate: false,
          dayValue: id,
          dayText,
        });
      });
    }
    this.setState({ isFilter: false, SortBy: "Date", Status: "Status" });
  };

  handleDateCallback(start, end, label) {
    let date = new Date(start._d);
    let calenderYear = date.getFullYear();
    let calenderDate =
      date.getDate() > 9 ? date.getDate() : 0 + "" + date.getDate();
    let calenderMonth =
      date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : 0 + "" + (date.getMonth() + 1);
    let tempDate = calenderMonth + "/" + calenderDate + "/" + calenderYear;

    let datee = new Date(end._d);
    let calenderYeare = datee.getFullYear();
    let calenderDatee =
      datee.getDate() > 9 ? datee.getDate() : 0 + "" + datee.getDate();
    let calenderMonthe =
      datee.getMonth() + 1 > 9
        ? datee.getMonth() + 1
        : 0 + "" + (datee.getMonth() + 1);
    let tempDatee = calenderMonthe + "/" + calenderDatee + "/" + calenderYeare;

    let journalRequest = { ...this.state.journalRequest };
    let journalExportReq = { ...this.state.journalExportReq };

    journalExportReq.selectType = "DateRange";
    journalRequest.selectType = "DateRange";
    journalExportReq.startDate = tempDate;
    journalExportReq.endDate = tempDatee;
    journalRequest.startDate = tempDate;
    journalRequest.endDate = tempDatee;
    this.getJournallist(journalRequest);
    this.setState({
      journalRequest,
      journalExportReq,
      dateRange: tempDate + " - " + tempDatee,
      SortBy: "Date",
      Status: "Status",
    });
  }

  notesIcon() {
    return (
      <svg
        stroke="currentColor"
        fill="#1d1e20"
        strokeWidth="0"
        viewBox="0 0 24 24"
        height="1.2em"
        width="1.2em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M19,4h-3V2h-2v2h-4V2H8v2H5C3.897,4,3,4.897,3,6v14c0,1.103,0.897,2,2,2h14c1.103,0,2-0.897,2-2V6C21,4.897,20.103,4,19,4z M5,20V7h3h2h4h2h3V6l0.002,14H5z"></path>
        <path d="M7 9H17V11H7zM7 13H12V15H7z"></path>
      </svg>
    );
  }

  handleDensityView = (viewName) => {
    this.setState({ defaultDensityView: viewName });
  };

  getJournalEntry = (row) => {
    if (
      row.vendor.toString().toLocaleLowerCase() ===
      "Payroll".toLocaleLowerCase() &&
      row.vid === 0
    ) {
      Utils.toastError(`Payroll entries cannot be updated.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      let JERequest = { ...this.state.JERequest };
      JERequest.UniqueNo = row.uniqueno;
      this.setState({ JERequest, isTRSideOut: true });
    }
  };

  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      this.getJournalEntry(row);
    },
  };

  rowChildEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      this.getJournalEntry(row);
    },
  };

  handleBtnClick = (type) => {
    let { expList } = this.state;
    if (type === "Collapse_All") {
      this.setState(() => ({
        expandedList: [],
      }));
    } else {
      this.setState(() => ({
        expandedList: expList,
      }));
    }
  };

  CleanSearch = () => {
    if (this.tableSearch.current !== null) {
      this.tableSearch.current.CleanSearch();
    }
  };

  resetJEState = () => {
    this.setState({ JERequest: { UniqueNo: 0 } });
  };

  hideSlideOut = (hasEvent) => {
    if (this.state.isBgBlue) {
      let win = window.open("about:blank", "_self");
      win?.close();
    } else {
      if (hasEvent) {
        this.setState({ isTRSideOut: false, tableData: [] }, () => {
          this.getJournallist(this.state.journalRequest);
          this.resetJEState();
        });
      } else {
        this.setState({ isTRSideOut: false }, () => {
          this.resetJEState();
        });
      }
    }
  };
  showSlideOut = () => {
    this.setState({ isTRSideOut: true });
  };

  ExportToExcel = () => {
    this.setState({ isExportRun: true });
    let journalExportReq = { ...this.state.journalExportReq };
    journalExportReq.hotelID = this.state.hidValue;
    journalExportReq.Condition = "All";
    journalExportReq.VID = 0;
    Journalsevice.ExportJournal(journalExportReq)
      .then(async (result: any) => {
        this.setState({ isExportRun: false });
        resolve();
      })
      .catch((error) => {
        this.setState({ isExportRun: false });
        Utils.toastError(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  };

  sortData = (sortBy) => {
    let tableData = _.sortBy(this.state.tableData, [sortBy, "date1"]);
    let tableData2 = _.sortBy(this.state.tableData, [
      sortBy,
      "date1",
    ]).reverse();
    if (sortBy === "date1") {
      this.setState({ SortBy: "Date", tableData: tableData2 });
    } else if (sortBy === "vendor") {
      this.setState({ SortBy: "Vendor", tableData: tableData });
    }
  };

  saveDefaultView = (): void => {
    const laborSaveAsDefaultdtos: any[] = [];
    laborSaveAsDefaultdtos.push({
      fieldName: "sort",
      fieldValue: this.state.defaultDensityView,
      pageName: "JournalEntry",
    });
    laborPerformance
      .saveDefaultViewProperties(laborSaveAsDefaultdtos)
      .then(async (result: any | null) => {
        if (result != null) {
          if (result.message === "Success") {
            toast.success(result?.result?.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
      })
      .catch((error) => {
        Utils.toastError(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
        this.setState({ showLoader: false });
      });
  };

  getDefaultView() {
    laborPerformance
      .getDefaultViewProperties("JournalEntry")
      .then(async (response: any | null) => {
        if (response?.length > 0) {
          this.setState({
            defaultDensityView: response[0].fieldValue,
          });
        } else {
          this.setState({
            defaultDensityView: this.state.densityView[1].densityType,
          });
        }
      })
      .catch((error) => {
        this.setState({
          defaultDensityView: this.state.densityView[1].densityType,
        });
        Utils.toastError(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
      });
  }

  descriptionValues = (data) => {
    return (
      <div className="contenxt-box">
          <div className="item">{data}</div>
          {
              data.length > 38 ? <div className="tooltip-content"><div className="text-wrapper"><div className="text">{data}</div></div></div> : ""
          }
      </div>
    )
  }

  render() {
    const renderEllipsisWithTooltip = (value: any) => {
      return (
        <EllipsisWithTooltip placement="bottom">
          {value}
        </EllipsisWithTooltip> 
      )
    }

    const renderRightText = (value: any) => {
      return (
        <div className="text-right">{value}</div>
      )
    }

    const renderlettercode = (isTrue: boolean, value: any) => {
      if (isTrue) {
        return (<OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id={"ehid-list"}>{value}</Tooltip>}>
          <span>Multiple</span>
        </OverlayTrigger>)
      } else {
        return (<div>{value}</div>)
      }
    }

    const columns = [
      {
        dataField: "date1",
        text: "Date",
        formatter: (cell: any, row: any, rowIndex: any) => renderEllipsisWithTooltip(row.date)
      },
      {
        dataField: "lettercode",
        text: "EHID",
        formatter: (cell: any, row: any, rowIndex: any) => renderlettercode(row.codeCount > 1, row.lettercode)
      },
      {
        dataField: "vendor",
        text: "Vendor",
      },
      {
        dataField: "internalnotes",
        text: "Notes",
        searchable: true,
        hidden: true,
        formatter: (cell: any, row: any, rowIndex: any) => renderEllipsisWithTooltip(row.internalnotes !== "" ? this.notesIcon() : row.internalnotes)
      },
      {
        dataField: "transTotal",
        text: "Debit",
        headerAlign: "right",
        formatter: (cell: any, row: any, rowIndex: any) => renderRightText(row.transTotal)
      },
      {
        dataField: "transTotal",
        text: "Credit",
        headerAlign: "right",
        formatter: (cell: any, row: any, rowIndex: any) => renderRightText(row.transTotal)
      },
      {
        dataField: "coa",
        text: "",
        hidden: true,
      },
      {
        dataField: "coaName",
        text: "",
        hidden: true,
      },
      {
        dataField: "coaName1",
        text: "",
        hidden: true,
      },
      {
        dataField: "date",
        text: "",
        hidden: true,
      },
      {
        dataField: "transTotal1",
        text: "",
        hidden: true,
      },
      {
        dataField: "dr",
        text: "",
        hidden: true,
      },
      {
        dataField: "cr",
        text: "",
        hidden: true,
      },
      {
        dataField: "dr1",
        text: "",
        hidden: true,
      },
      {
        dataField: "cr1",
        text: "",
        hidden: true,
      },
      {
        dataField: "description",
        text: "",
        hidden: true,
      },
    ];

    const handleOnExpand = (row, isExpand, rowIndex, e) => {
      if (isExpand) {
        this.setState(() => ({
          expandedList: [...this.state.expandedList, row.id],
        }));
      } else {
        this.setState(() => ({
          expandedList: this.state.expandedList.filter((x) => x !== row.id),
        }));
      }
    };

    const renderExpends = (isTrue: boolean) => {
      if (isTrue) {
        return <div></div>;
      }
      return <div></div>;
    }

    const renderexpandColumn = (isTrue: boolean) => {
      if (isTrue) {
        return (
          <span className="chevronExpended">
            <img src={chevronRight} alt="chevron Right" />
          </span>
        );
      }
      return (
        <span className="chevronCollpsed">
          <img src={chevronRight} alt="chevron Right" />
        </span>
      );
    }

    const expandRow = {
      parentClassName: "parent-expand-foo",
      renderer: (row: any, rowIndex: any) => renderChild(row),
      expanded: this.state.expandedList,
      showExpandColumn: true,
      expandByColumnOnly: true,
      onExpand: handleOnExpand,
      expandHeaderColumnRenderer: ({ isAnyExpands }) => renderExpends(isAnyExpands),
      expandColumnRenderer: ({ expanded }) => renderexpandColumn(expanded),
    };

    const getRenderChild = (registerList, row) => {
      return registerList.filter(
        (splist) =>
          splist.viewType === 2 &&
          ((splist.uniqueno === row.uniqueno &&
            splist.payment === row.payment) ||
            (row.uniqueno === "-1" &&
              splist.vid === row.vid &&
              splist.date1 === row.date1 &&
              splist.ckno === row.ckno))
      );
    };

    const renderChild = (row) => {
      let { registerList } = this.state;
      let splitlist = [];
      splitlist = getRenderChild(registerList, row);
      openRow = row;
      return (
        <>
          <ToolkitProvider
            keyField="Child_rNo"
            data={splitlist}
            columns={columnChild}
            search
          >
            {(props) => (
              <>
                <div className="forJournalChildTable table-UI">
                  <BootstrapTable
                    {...props.baseProps}
                    keyField="id"
                    rowEvents={this.rowChildEvents}
                    hover
                    noDataIndication={
                      "No activity for the selected time period"
                    }
                  />
                </div>
              </>
            )}
          </ToolkitProvider>
        </>
      );
    };

    const columnChild = [
      {
        dataField: "coa",
        text: "COA #",
      },
      {
        dataField: "coaName",
        text: "COA NAME",
        formatter: (cell: any, row: any, rowIndex: any) => renderEllipsisWithTooltip(row.coaName)
      },
      {
        dataField: "description",
        text: "DESCRIPTION",
        // formatter: (cell: any, row: any, rowIndex: any) => renderEllipsisWithTooltip(row.description)
        formatter: (cell: any, row: any, rowIndex: any) => this.descriptionValues(row.description)
      },
      {
        dataField: "accper",
        text: "A/C PERIOD",
        formatter: (cell: any, row: any, rowIndex: any) => renderEllipsisWithTooltip(row.accper)
      },
      {
        dataField: "dr",
        text: "DEBIT",
        headerAlign: "right",
        formatter: (cell: any, row: any, rowIndex: any) => renderRightText(row.dr)
      },
      {
        dataField: "cr",
        text: "CREDIT",
        headerAlign: "right",
        formatter: (cell: any, row: any, rowIndex: any) => renderRightText(row.cr)
      },
      {
        dataField: "",
        text: "",
        hidden: true,
      },
    ];

    return (
      <>
        {this.state.isExportRun && (
          <div className="matrixSpinnerCenter">
            <Spinner animation="border" variant="secondary" />
          </div>
        )}
        <div className={this.state.isBgBlue ? "bg-blue-static" : ""}>
          {this.state.isTRSideOut && (
            <TransactionSlideout
              JERequest={this.state.JERequest}
              hidValue={this.state.hidValue}
              hotelName={this.state.hotelName}
              hideSlideOut={this.hideSlideOut}
              newRequest={true}
              hasAddPermission={this.state.hasAddPermission}
              isFromRepeating={false}
              showCustomTooltip={true}
            />
          )}
          <ToastContainer autoClose={3000} enableMultiContainer />
          {this.state.showChangeLogModal && (
            <JournalLogHistory
              payPeriodStartDate={""}
              payPeriodEndDate={""}
              accType={"All-J"}
              showChangeLogModal={this.state.showChangeLogModal}
              showHideChangeLogModal={this.showHideChangeLogModal}
              hotelName={""}
              hotelId={this.state.journalRequest.hotelID}
              hideCrossAndClearFilters={false}
              shiftId={0}
              shiftDate={""}
              noOfDays={this.state.journalRequest.selectType}
            />
          )}

          <div className="register">
            <Container fluid className="body-sec">
              <div className="page-heading underline d-flex">
                <HidSelector
                  onClickEvent={this.selectHotelDropDown}
                  ref={this.childHID}
                  modulename={"Accounting"}
                />
                {this.state.hidValue !== "Select" && (
                  <>
                    <div className="mr-auto d-flex align-items-center">
                      Journal Entries
                    </div>
                    {this.state.hasImportPermission && (
                      <button
                        type="button"
                        disabled={this.state.hotelName === "Select"}
                        className="btn-outline-primary btn btn-primary"
                        onClick={this.showJournalImportModal}
                      >
                        Import
                      </button>
                    )}
                    <div className="action-group d-flex">
                      {this.state.hasAddPermission && (
                        <Button
                          disabled={this.state.hotelName === "Select"}
                          onClick={this.showSlideOut}
                        >
                          Add Journal Entry
                        </Button>
                      )}
                      {this.state.tableData.length > 0 && (
                        <Dropdown className="more-action" alignRight>
                          <Dropdown.Toggle
                            className="btn-outline-primary btn btn-primary more"
                            id="dropdown-more"
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                              <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                              <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                            </svg>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              eventKey="export"
                              key="export"
                              onClick={() => this.ExportToExcel()}
                            >
                              Export
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="changeLog"
                              key="changeLog"
                              onClick={() => this.showHideChangeLogModal()}
                            >
                              Change Log
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </div>
                  </>
                )}
              </div>
              {this.state.hidValue !== "Select" && (
                <div className="main-Page">
                  <div className="bdy-sec">
                    <ToolkitProvider
                      keyField="Modal_rNo"
                      data={this.state.tableData.filter((x) => x.viewType === 1)}
                      columns={columns}
                      search
                      rowEvents={this.rowEvents}
                    >
                      {(props: {
                        searchProps: JSX.IntrinsicAttributes;
                        baseProps: JSX.IntrinsicAttributes;
                      }) => (
                        <div>
                          <div className="search-header registerSearchHeader d-flex">
                            {this.state.accountNameValue !== -1 && (
                              <>
                                <>
                                  {!this.state.isloader ? (
                                    <div className="registerSearch">
                                      <TableSearch
                                        id="tableSearch"
                                        key="tableSearch"
                                        ref={this.tableSearch}
                                        {...props.searchProps}
                                        CleanSearch={this.CleanSearch.bind(this)}
                                        placeholder={"Filter Transactions"}
                                      />
                                    </div>
                                  ) : (
                                    <div className="single-loader loadingAnimation"></div>
                                  )}
                                </>
                                <div
                                  className={[
                                    this.state.calenderHideClass,
                                    "filter-section cusDateMargin d-flex align-items-center",
                                  ].join(" ")}
                                >
                                  {!this.state.isloader ? (
                                    <SingleDropdownListWithoutSearch
                                      id={"tabledayCount"}
                                      key={Math.floor(Math.random() * 90 + 10)}
                                      ref={this.dayCount}
                                      itemList={this.state.dayFilter}
                                      handleSelectedItem={this.handleSelectedItem.bind(
                                        this,
                                        1
                                      )}
                                      defaultText={"Search day Status"}
                                      defaultName={this.state.dayText}
                                      controlID="1"
                                      calIcon={true}
                                    />
                                  ) : (
                                    <div className="single-loader loadingAnimation"></div>
                                  )}
                                </div>
                                {!this.state.isloader ? (
                                  <>
                                    {this.state.customDate && (
                                      <DateRangePicker
                                        onCallback={this.handleDateCallback}
                                        initialSettings={{
                                          startDate: new Date(this.state.start),
                                          endDate: new Date(this.state.end),
                                        }}
                                      >
                                        <div
                                          className={[
                                            this.state.calenderVisibleClass,
                                            "d-flex align-items-center info",
                                          ].join(" ")}
                                        >
                                          <div
                                            className="shared-list"
                                            id="dtrange"
                                          >
                                            {this.state.dateRange}
                                          </div>
                                        </div>
                                      </DateRangePicker>
                                    )}
                                  </>
                                ) : (
                                  <div className="single-loader loadingAnimation"></div>
                                )}

                                {!this.state.isloader ? (
                                  <div className="hasShortingBtn ml-1">
                                    <Dropdown className="more-action bg-blue single-select-image dropdown">
                                      <Dropdown.Toggle id="dropdown-ptoType">
                                        <div className="d-flex align-items-center">
                                          <svg
                                            width="18"
                                            height="18"
                                            viewBox="0 0 18 18"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path d="M4.5 15L7.5 12H5.25V3H3.75V12H1.5L4.5 15ZM8.25 6H15V7.5H8.25V6ZM8.25 9H13.5V10.5H8.25V9ZM8.25 3H16.5V4.5H8.25V3ZM8.25 12H12V13.5H8.25V12Z" />
                                          </svg>
                                          {this.state.SortBy}
                                          <div className="drop-arrow d-flex">
                                            <FiChevronDown />
                                          </div>
                                        </div>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          key={"Date"}
                                          eventKey={"Date"}
                                          className={
                                            this.state.SortBy === "Date"
                                              ? "active"
                                              : ""
                                          }
                                          onClick={(e: any) =>
                                            this.sortData("date1")
                                          }
                                        >
                                          <div className="d-flex">
                                            <div className="mr-auto">Date</div>
                                            <svg
                                              width="18"
                                              height="18"
                                              viewBox="0 0 18 18"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
                                                fill="#2CAF92"
                                              />
                                            </svg>
                                          </div>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          key={"Vendor"}
                                          eventKey={"Vendor"}
                                          className={
                                            this.state.SortBy === "Vendor"
                                              ? "active"
                                              : ""
                                          }
                                          onClick={(e: any) =>
                                            this.sortData("vendor")
                                          }
                                        >
                                          <div className="d-flex">
                                            <div className="mr-auto">
                                              Vendor
                                            </div>
                                            <svg
                                              width="18"
                                              height="18"
                                              viewBox="0 0 18 18"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
                                                fill="#2CAF92"
                                              />
                                            </svg>
                                          </div>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                ) : (
                                  <div className="single-loader loadingAnimation"></div>
                                )}

                                <div className="regCollapseButton">
                                  {!this.state.isloader ? (
                                    <button
                                      type="button"
                                      onClick={(e: any) =>
                                        this.handleBtnClick(
                                          this.state.expandedList.length > 0
                                            ? "Collapse_All"
                                            : "Expend_All"
                                        )
                                      }
                                      className="btn-outline-primary btn btn-primary"
                                      disabled={this.state.istableloader}
                                    >
                                      {this.state.expandedList.length > 0
                                        ? "Collapse All"
                                        : "Expand All"}
                                    </button>
                                  ) : (
                                    <div className="single-loader loadingAnimation"></div>
                                  )}
                                </div>
                                <div className="filter-section ml-1 hasGearIcon">
                                  {!this.state.isloader ? (
                                    <Dropdown className="more-action bg-blue dropdown single-select-image">
                                      <Dropdown.Toggle id="rolesList">
                                        <div className="d-flex align-items-center">
                                          <svg
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path d="M0.562614 9.18059L1.89595 11.4873C2.07995 11.8059 2.48795 11.9153 2.80661 11.7306L3.73728 11.1933C4.12395 11.4979 4.55128 11.7479 5.00061 11.9346V12.9999C5.00061 13.3679 5.29861 13.6666 5.66728 13.6666H8.33395C8.70261 13.6666 9.00062 13.3679 9.00062 12.9999V11.9346C9.44995 11.7479 9.87661 11.4979 10.2639 11.1933L11.1946 11.7306C11.5126 11.9139 11.9219 11.8039 12.1053 11.4873L13.4386 9.18059C13.6219 8.86325 13.5119 8.45325 13.1953 8.26992L12.2806 7.74125C12.3153 7.49525 12.3333 7.24725 12.3333 6.99992C12.3333 6.75259 12.3153 6.50459 12.2793 6.25858L13.1939 5.72992C13.5113 5.54659 13.6213 5.13659 13.4373 4.81925L12.1039 2.51259C11.9199 2.19392 11.5119 2.08459 11.1933 2.26925L10.2626 2.80659C9.87595 2.50192 9.44928 2.25125 8.99995 2.06525V0.999919C8.99995 0.631919 8.70195 0.333252 8.33328 0.333252H5.66661C5.29795 0.333252 4.99995 0.631919 4.99995 0.999919V2.06525C4.55061 2.25192 4.12395 2.50192 3.73661 2.80659L2.80661 2.26925C2.48728 2.08459 2.07995 2.19392 1.89595 2.51259L0.562614 4.81925C0.379281 5.13659 0.489281 5.54659 0.805948 5.72992L1.72061 6.25858C1.68461 6.50459 1.66661 6.75259 1.66661 6.99992C1.66661 7.24725 1.68461 7.49525 1.72061 7.74125L0.805948 8.26992C0.488614 8.45325 0.378614 8.86325 0.562614 9.18059ZM6.99995 4.33325C8.47061 4.33325 9.66661 5.52925 9.66661 6.99992C9.66661 8.47059 8.47061 9.66659 6.99995 9.66659C5.52928 9.66659 4.33328 8.47059 4.33328 6.99992C4.33328 5.52925 5.52928 4.33325 6.99995 4.33325Z" />
                                          </svg>
                                          <div className="drop-arrow ml-1 d-flex">
                                            <FiChevronDown />
                                          </div>
                                        </div>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        <div className="gearIconHeader headerTop">
                                          <strong>Density View Control</strong>
                                        </div>
                                        {this.state.densityView.map(
                                          (items, index) => (
                                            <div
                                              className={
                                                this.state
                                                  .defaultDensityView ===
                                                  items.densityType
                                                  ? "dropdownItem active"
                                                  : "dropdownItem"
                                              }
                                              key={items.id}
                                              onClick={(e: any) =>
                                                this.handleDensityView(
                                                  items.densityType
                                                )
                                              }
                                            >
                                              {items.name}
                                              {this.state.defaultDensityView ===
                                                items.densityType && (
                                                  <svg
                                                    width="18"
                                                    height="18"
                                                    viewBox="0 0 18 18"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                    <path
                                                      d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
                                                      fill="#2CAF92"
                                                    ></path>
                                                  </svg>
                                                )}
                                            </div>
                                          )
                                        )}
                                        <div className="m-0 dropdown-divider"></div>
                                        <Dropdown.Item
                                          className="saveDefaultBtn"
                                          onClick={() => this.saveDefaultView()}
                                        >
                                          Save as Default View
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  ) : (
                                    <div className="single-loader loadingAnimation"></div>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                          {this.state.accountNameValue !== -1 && (
                            <>
                              {!this.state.istableloader && (
                                <div
                                  className={`${this.state.defaultDensityView} fixHeader100 forJournalParentTable tableToggleRow regular JorScrollArea SOS`}
                                >
                                  <BootstrapTable
                                    {...props.baseProps}
                                    keyField="id"
                                    hover
                                    rowEvents={this.rowEvents}
                                    expandRow={expandRow}
                                  />
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </ToolkitProvider>

                    {this.state.istableloader && (
                      <div className="loader-responsive register">
                        <div className="performanceList">
                          <div className="tableList">
                            <ul>
                              <li className="d-flex">
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Container>
            {this.state.JournalStatementModal && (
              <JournalImport
                pageName="Register"
                hidValue={this.state.hidValue}
                hotelName={this.state.hotelName}
                oprId={this.state.CardUniqueNo}
                hideJournalModal={this.hideJournalModal.bind(this)}
              />
            )}
          </div>
        </div>
      </>
    );
  }
  showJournalImportModal = () => {
    this.setState({ JournalStatementModal: true });
  };
  hideJournalModal = (isTrue) => {
    if (isTrue) {
      this.setState({ JournalStatementModal: false }, () => {
        this.setState(
          {
            splitlist: [],
            tableData: [],
            tableData2: [],
          },
          () => {
            this.getJournallist(this.state.journalRequest);
          }
        );
      });
    } else {
      this.setState({ JournalStatementModal: false });
    }
  };
}
