import * as React from "react";
import "react-input-range/lib/css/index.css";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

const { SearchBar } = Search;
export class InvoiceApprovalLeftPanel extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            ApprovalList: props.ApprovalList,
            columns: props.columns,
            changeValue: props.changeValue,
            fieldData: {
                ruleID: 0,
                ruleStatus: "",
                approvalName: "",
                approvalType: "Select rule type",
                threshold: "",
                mainGroup: "Select Main Group",
                mainGroupID: "0",
                subGroupOne: "Select Sub Group 1",
                subGroupOneID: "-1",
                subGroupTwo: "Select Sub Group 2",
                subGroupTwoID: "-1",
                vendor: "",
                coa: "",
                internalNotes: "",
                primaryApprover: "",
                secondaryApprover: "",
            },
            selectedRow: true,
        };
        this.handleLeftApproval = this.handleLeftApproval.bind(this);
        this.removeSelectedRow = this.removeSelectedRow.bind(this);
        this.removeNewBlankRow = this.removeNewBlankRow.bind(this);
    }

    componentWillReceiveProps(nextProps: any) {
        this.setState({ ApprovalList: nextProps.ApprovalList, changeValue: nextProps.changeValue });
    }

    componentDidMount() {
        $('#search-bar-0').prop("autocomplete", "off");

    }

    handleLeftApproval = (event: any) => {
        let new_state = Object.assign({}, this.state);
        let fieldData = new_state.fieldData;
        this.props.updateStateNewApproval(fieldData);
        this.setState({ selectedRow: false });
    };

    removeSelectedRow = (data: any) => {
        let new_state = Object.assign({}, this.state);
        let fieldData = new_state.fieldData;
        fieldData.ishide = true;
        this.setState({ selectedRow: false });
    };

    removeNewBlankRow = (data: any) => {
        let new_state = Object.assign({}, this.state);
        let fieldData = new_state.fieldData;
        fieldData.ishide = false;
        this.setState({ selectedRow: true });
    };

    rowEvents = {
        onClick: (e: any, row: any, rowIndex: any) => {
            if (this.state.changeValue) {
                this.props.childHandleChange("rowClick");
                this.props.rowChildValue(row);
                this.setState({ selectedRow: false });
                this.props.isValidateModelPoupTab();
            } else {
                if (row.appType === "Standard" || this.props.AAPermission === "Yes") {
                    const fieldData = {
                        approvalName: row.ruleName,
                        approvalType: row.appType,
                        ruleID: row.ruleID,
                        ruleStatus: row.status
                    };
                    this.props.updateState(fieldData);
                    this.setState({ selectedRow: true, isselected: false });
                }
            }
        },
    };

    render() {
        const isStandardComplete = this.state.ApprovalList.some(
            (item) => item.paCompleted.toString().toLowerCase() === "yes"
        );

        const selectRow = {
            mode: "radio",
            clickToSelect: true,
            hideSelectColumn: true,
            classes: "active-row",
            selected: this.state.isselected ? this.state.selected : "",
        };
        const hideRow = {
            mode: "radio",
            clickToSelect: true,
            hideSelectColumn: true,
            classes: "",
        };
        return (

            <div className="left-panel-IA">
                <ToolkitProvider
                    keyField="id"
                    data={this.props.ApprovalList}
                    columns={this.state.columns}
                    rowEvents={this.rowEvents}
                    search
                >
                    {(props: {
                        searchProps: JSX.IntrinsicAttributes;
                        baseProps: JSX.IntrinsicAttributes;
                    }) => (
                        <div>
                            <div className="search-header">
                                <div className="leftFilter-IA">
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M8.33317 15.0001C9.8715 15.0001 11.2857 14.4709 12.414 13.5934L16.0773 17.2567L17.2557 16.0784L13.5923 12.4151C14.4707 11.2859 14.9998 9.87175 14.9998 8.33342C14.9998 4.65758 12.009 1.66675 8.33317 1.66675C4.65734 1.66675 1.6665 4.65758 1.6665 8.33342C1.6665 12.0092 4.65734 15.0001 8.33317 15.0001ZM8.33317 3.33341C11.0907 3.33341 13.3332 5.57591 13.3332 8.33342C13.3332 11.0909 11.0907 13.3334 8.33317 13.3334C5.57567 13.3334 3.33317 11.0909 3.33317 8.33342C3.33317 5.57591 5.57567 3.33341 8.33317 3.33341Z"
                                            fill="#84888C"
                                        />
                                    </svg>
                                    <SearchBar {...props.searchProps} placeholder={"Filter by category"} />
                                </div>
                            </div>
                            <div className="left-body-IA">
                                <BootstrapTable
                                    {...props.baseProps}
                                    keyField="rowNo"
                                    rowEvents={this.rowEvents}
                                    selectRow={this.state.selectedRow ? selectRow : hideRow}
                                    hover
                                    condensed
                                />
                                {this.props.AAPermission === "Yes" && isStandardComplete && this.state.ApprovalList.length > 0 && (
                                    <div className="add-Approval" onClick={this.handleLeftApproval}>
                                        Add Approval
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </ToolkitProvider>
            </div>
        );
    }
}
