import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Container } from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip, faFile, faFileImage, faFileExcel, faFilePdf, faFileCsv, faFileWord } from "@fortawesome/free-solid-svg-icons";
import { HidSelector } from "../../Common/Components/HidSelector";
import { InvoiceEntrySlideout } from "./InvoiceEntrySlideout";
import { resolve, reject } from "q";
import { Register as registersevice } from "../../Common/Services/Register";

export class InvoiceEntry extends React.Component<any, any> {
    private child: any;
    private childHID: any;
    constructor(props: any) {
        super(props);
        this.child = React.createRef();
        this.childHID = React.createRef();
        this.state = {
            hidValue: "Select",
            hotelName: "Select",
            isSlideOut: false,
            trUniqueNo: 0,
            pageType: "",
            previousTransactionsData: [],
            registerRequest: { hotelID: -1, lettercode: '', oprID: -1, selectType: "30", isDepositHide: "Yes", startDate: "", endDate: "", acctType: "" },
        }
    }

    addInvoice = () => {
        this.setState({
            isSlideOut: true, trUniqueNo: 0,
            sildeoutHid: this.state.hidValue, sildeoutCode: this.state.hotelName
        })
    }

    hideSlideOut() {
        this.setState({ isSlideOut: false });
        this.GetIncompleteTransactionList(this.state.hidValue);
    }

    selectHotelDropDown = (hotelDetails: any) => {
        this.setState({
            hotelName: hotelDetails.lettercode,
            hidValue: hotelDetails.hotelID,
        });
        this.GetIncompleteTransactionList(hotelDetails.hotelID);
    };

    GetIncompleteTransactionList = (hotelID) => {
        let request: any = {};
        request.hotelID = hotelID;
        request.etype = "IncompleteInvoice";
        registersevice.GetIncompleteTransactionList(request)
            .then(async (result: any | null) => {
                if (result !== null) {
                    
                    this.setState({ previousTransactionsData: result })
                }
                resolve();
            })
            .catch((error) => {
                reject();
            });
    };

    rowEvents = {
        onClick: (e: any, row: any, rowIndex: any) => {
            
            this.setState({
                pageType: "IncompleteInvoice",
                isSlideOut: true, trUniqueNo: Number(row.uniqueNo),
                sildeoutHid: this.state.hidValue, sildeoutCode: this.state.hotelName
            })
        },
    };

    render() {
        const iconReceiptFormatter = (column, colIndex) => {
            return (
                <div><FontAwesomeIcon icon={faPaperclip}  /></div>
            )
        }

        const previousTransactionsColumns = [{
            dataField: 'date',
            text: 'Date'
        }, {
            dataField: 'accper',
            text: 'Act.Per'
        },
        {
            dataField: 'vendor',
            text: 'Vendor'
        }

            , {
            dataField: 'internalnotes',
            text: 'Notes',
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <EllipsisWithTooltip placement="bottom">{row.internalnotes}</EllipsisWithTooltip>
                );
            },
        },
        {
            dataField: 'description',
            text: 'Description',
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <EllipsisWithTooltip placement="bottom">{row.description}</EllipsisWithTooltip>
                );
            },
        }, {
            dataField: 'lettercode',
            text: 'EHID'
        }, {
            dataField: 'coa',
            text: 'COA'
        }, {
            dataField: 'amount',
            text: this.props.pageType === 'Deposit' ? 'Deposit' : 'Payment'
        }, {
            dataField: 'transTotal',
            text: 'Total'
        }, {
            dataField: 'receipt',
            text: 'Receipt',
            headerFormatter: iconReceiptFormatter,
            formatter: (cell: any, row: any, rowIndex: any) => {
                if (row.isFile === "Yes") {
                    return (
                        <div><FontAwesomeIcon icon={faPaperclip}  /></div>
                    )
                }
                else {
                    return (
                        <div></div>
                    )
                }
            },
        }];

        return (
            <>
                {this.state.isSlideOut && (
                    <InvoiceEntrySlideout
                        hidValue={this.state.sildeoutHid}
                        hotelName={this.state.sildeoutCode}
                        uniqueNo={this.state.trUniqueNo}
                        pageType={this.state.pageType}
                        hideSlideOut={this.hideSlideOut.bind(this)}
                    />
                )}
                <div className="">
                    <Container fluid className="body-sec">
                        <div className="page-heading underline d-flex">
                            <HidSelector
                                onClickEvent={this.selectHotelDropDown}
                                ref={this.childHID}
                                modulename={'Accounting'}
                            />
                            <>
                                <div className="mr-auto d-flex align-items-center">Invoice entry</div>
                                <div className="action-group d-flex">
                                    {this.state.hidValue !== "Select" && (
                                        <button type="button" className="btn btn-primary" onClick={this.addInvoice}>Add Invoice</button>
                                    )}
                                </div>
                            </>
                        </div>
                        <div className="main-Page">
                            <div className="bdy-sec">
                                <BootstrapTable keyField='id'
                                    data={this.state.previousTransactionsData}
                                    columns={previousTransactionsColumns}
                                    rowEvents={this.rowEvents} />
                            </div>
                        </div>
                    </Container>
                </div >
            </>)
    }
}