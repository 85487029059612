import React,{ useState} from 'react'
import { Button } from 'react-bootstrap'
import MetricModal from './modal/MetricModal'
import "./PortfolioDashboard.scss";

function PortfolioDashboardPage() {
  const [showModal,setShowModal] =useState<boolean>(false)


  const hideModal =()=>{
    setShowModal(false)
  }

  const handleOpen =()=>{
    setShowModal(true)
  }
    
  return (
    <div>
      <h1>Portfolio Dashboard</h1>

      <Button onClick={handleOpen}>Show Modal</Button>


      <MetricModal show={showModal} handleClose={hideModal} />
    </div>
  )
}

export default PortfolioDashboardPage
