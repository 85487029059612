import React from "react";
import ContentLoader from "react-content-loader";

interface IProps {
  useas: string;
}

export const ReactComponentLoader = (props: IProps) => {
  const { useas } = props;
  if (useas === "scheduleAssignDDN" || useas === "shiftReassignDDN") {
    return (
      <ContentLoader
        speed={2}
        width={300}
        height={360}
        viewBox="0 0 300 360"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
      >
        <rect x="64" y="77" rx="5" ry="5" width="100" height="10" />
        <circle cx="34" cy="92" r="18" />
        <rect x="64" y="93" rx="5" ry="5" width="150" height="10" />
        <rect x="16" y="12" rx="0" ry="0" width="268" height="36" />
        <rect x="64" y="133" rx="5" ry="5" width="100" height="10" />
        <circle cx="34" cy="148" r="18" />
        <rect x="64" y="149" rx="5" ry="5" width="150" height="10" />
        <rect x="64" y="195" rx="5" ry="5" width="100" height="10" />
        <circle cx="34" cy="210" r="18" />
        <rect x="64" y="211" rx="5" ry="5" width="150" height="10" />
        <rect x="64" y="251" rx="5" ry="5" width="100" height="10" />
        <circle cx="34" cy="266" r="18" />
        <rect x="64" y="267" rx="5" ry="5" width="150" height="10" />
      </ContentLoader>
    );
  } else if (useas === "schedleAddShiftDDN") {
    return (
      <ContentLoader
        speed={2}
        width={1920}
        height={768}
        viewBox="0 0 1920 768"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
      >
        <rect x="0" y="24" rx="5" ry="5" width="170" height="40" />
        <rect x="0" y="358" rx="5" ry="5" width="700" height="40" />
        <rect x="0" y="90" rx="5" ry="5" width="560" height="185" />
        <rect x="0" y="539" rx="5" ry="5" width="415" height="40" />
        <rect x="0" y="598" rx="5" ry="5" width="200" height="40" />
        <rect x="0" y="328" rx="5" ry="5" width="100" height="10" />
        <rect x="0" y="419" rx="5" ry="5" width="500" height="40" />
        <rect x="0" y="479" rx="5" ry="5" width="380" height="40" />
      </ContentLoader>
    );
  }
  //  else if (useas === "scheduleDepartmentSearch") {
  //   return (
  //     <ContentLoader
  //       speed={2}
  //       width={300}
  //       height={360}
  //       viewBox="0 0 300 360"
  //       backgroundColor="#f3f3f3"
  //       foregroundColor="#ecebeb"
  //       {...props}
  //     >
  //       <rect x="16" y="12" rx="0" ry="0" width="200" height="36" />
  //       <circle cx="34" cy="92" r="18" />
  //       <rect x="64" y="77" rx="5" ry="5" width="100" height="10" />
  //       <rect x="64" y="93" rx="5" ry="5" width="150" height="10" />
  //       <circle cx="34" cy="148" r="18" />
  //       <rect x="64" y="133" rx="5" ry="5" width="100" height="10" />
  //       <rect x="64" y="149" rx="5" ry="5" width="150" height="10" />
  //       <circle cx="34" cy="210" r="18" />
  //       <rect x="64" y="195" rx="5" ry="5" width="100" height="10" />
  //       <rect x="64" y="211" rx="5" ry="5" width="150" height="10" />
  //       <rect x="64" y="251" rx="5" ry="5" width="100" height="10" />
  //     </ContentLoader>
  //   );
  // }
   else if (useas === "userPunchModalShifts") {
    return (
      <ContentLoader
        speed={2}
        width={500}
        height={66}
        viewBox="0 0 500 66"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
      >
        <rect x="0" y="0" rx="0" ry="0" width="436" height="66" />
      </ContentLoader>
    );
  } else if (useas === "scheduleEditApprovedPTO") {
    return (
      <div className="loader-responsive">
        <div className="scheduleEditApprovedPTO">
          <div className="modal-body-section d-flex justify-content-between">
            <div className="label-input-grp col2">
              <div className="loadingAnimation labelTxt"></div>
              <div className="loadingAnimation inputBox"></div>
            </div>
            <div className="label-input-grp col2">
              <div className="loadingAnimation labelTxt"></div>
              <div className="loadingAnimation inputBox"></div>
            </div>
          </div>
          <div className="modal-body-section d-flex justify-content-between">
            <div className="label-input-grp col1">
              <div className="loadingAnimation labelTxt"></div>
              <div
                className="loadingAnimation inputBox"
                style={{ height: 72 }}
              ></div>
            </div>
          </div>
          <div className="separatorLine"></div>
          <div className="modal-body-section-table">
            <div className="loadingAnimation labelTxt"></div>
            <div className="loadingAnimation tableHeader"></div>
            <div className="tableList">
            <ul>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};
