import React from "react";
import {
  Container,
  Dropdown,
  Modal,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import { FiChevronDown } from "react-icons/fi";
import ReactTooltip from "react-tooltip";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";
import Autocomplete from "react-autocomplete";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import OutsideClickHandler from "react-outside-click-handler";
import RouteLeavingGuard from "../../Common/Components/RouteLeavingGuard";
import { ConfirmationModalNonTable } from "../../Common/Components/ConfirmationModalNonTable";
import ForecastLock from "../../Common/Assets/Images/icons/forecast-lock.svg";
import {
  IForecastRequestDto,
  IForecastSaveDto,
  IForecastTemplateRequestDto,
  IForecastImportTemplateRequestDto,
  IForecastTempalteRequestDto,
} from "../../Common/Contracts/ILaborForecast";
import { LaborForecast } from "../../Common/Services/LaborForecast";
import { HidSelector } from "../../Common/Components/HidSelector";
import { resolve, reject } from "q";
import { Redirect } from "react-router-dom";
import { LaborBudgetSetting } from "../../Common/Services/LaborBudgetSetting";
import $ from "jquery";
import {
  RoleSubPermissionSetting,
  IPageSubPermissionRole,
} from "../../Common/Services/RoleSubPermissionSetting";
import { ReactPageLoader } from "../../Common/Components/ReactPageLoader";
import "./Forecast.scss";
import { TimeoffRequestRightPanel } from "./timeoffrequestrightpanel";
import { Utils } from "../../Common/Utilis";

// Defined Varaibles
let roomOccupiedID: number = 1;
let arrivalID: number = 25;
let departureID: number = 26;
let stayOverID: number = 27;
let occupiedIndex: number;
let arrivalIndex: number;
let departureIndex: number;
let stayOverIndex: number;
let routeLeavingGuard: any = null;
let hotelDetailsData: any;

export class Forecast extends React.Component<any, any> {
  private childHID: any;
  private inputRow: any;
  constructor(props: any) {
    super(props);
    this.childHID = React.createRef();
    this.inputRow = React.createRef();
    this.state = {
      loaderTrue: true,
      hidValue: "Select",
      currentDate: "",
      calenderDates: [],
      calenderSelectDate: {},
      laborMgmtMinDate: {},
      forecastData: [],
      headerDates: [],
      headerDays: [],
      lockDays: [],
      importFromListActual: [],
      importFromListTemplate: [],
      testing: [{id : 123, name : 123},{id : 456, name : 456}],
      importFromListSelectDisplay: "Select",
      importFromListSelect: {},
      templateData: [],
      templateName: "",
      isHandleForecast: "",
      rowEditNo: null,
      changeValue: false,
      importDataBanner: false,
      calenderShow: false,
      importForecastModal: false,
      saveTemplateModal: false,
      templateSaveConfirmation: false,
      scheduleError: false,
      redirect: false,
      scheduleViewType: "",
      permissionManageForecast: false,
      permissionExportForecast: false,
      saveForecastButtonSpinner: false,
      saveTemplateButtonSpinner: false,
      saveAsNewTemplateButtonSpinner: false,
      tempSelectedDate: "",
      checkValidationOnSaveAsTemapleButon: false,
      ModeCheck : "foreCastMode",
      redierectType:"",
      ViewTempleteName:"",
      gblimportFromListTemplate: [],
      templateListDisplay: "Select Template",
      createNewTemplate : true,
      normalSave : false,
      changeTemplate : false,
      tempData: [],
      buttonName : "save"
    };
  }

  componentDidMount() {
   // debugger;
    const location = this.props.location;
    if (location.state !== undefined) {
      const stateLength = Object.keys(location.state).length;
      if (stateLength > 0 && location.state.scheduleError) {
        let { pageLoadDate, viewType,redierectType,ViewTempleteName } = location.state;
        let scheduleDate: any = new Date(pageLoadDate);

        this.setState({
          calenderSelectDate: scheduleDate,
          currentDate: pageLoadDate,
          scheduleError: true,
          scheduleViewType: viewType,
          tempSelectedDate: pageLoadDate,
          ModeCheck:redierectType==="ViewEdit"  ? "templateMode" : "foreCastMode",
          redierectType:redierectType,
          ViewTempleteName:ViewTempleteName
        });
      }
    } else {
      let todayDate: any = new Date();
      if (
        JSON.parse(localStorage.storage).currentUtcTime !== "" &&
        JSON.parse(localStorage.storage).currentUtcTime !== null &&
        JSON.parse(localStorage.storage).currentUtcTime !== undefined
      ) {
        todayDate = new Date(JSON.parse(localStorage.storage).currentUtcTime);
      }
      let currentdate = todayDate.getDate();
      let currentMonth = todayDate.getMonth() + 1;
      let currentYear = todayDate.getFullYear();
      let tempDate = currentMonth + "/" + currentdate + "/" + currentYear;

      this.setState({
        currentDate: tempDate,
        calenderSelectDate: todayDate,
        tempSelectedDate: tempDate,
      });
    }

    window.addEventListener("beforeunload", this.beforeunload.bind(this));

    this.getForcastPermission();
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.beforeunload.bind(this));
  }

  beforeunload(e: { preventDefault: () => void; returnValue: boolean }) {
    if (
      this.state.changeValue &&
      routeLeavingGuard !== "routeLeavingGuardTrue"
    ) {
      e.preventDefault();
      e.returnValue = true;
    }
  }

  getForcastPermission = () => {
    let requestObject = {} as IPageSubPermissionRole;
    requestObject.permissionID = 10005;
    requestObject.Module = "LaborManagement";

    requestObject.Type = "page";
    RoleSubPermissionSetting.GetPageSubPermissionDetails(requestObject)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          result.forEach((element: any) => {
            switch (element.permissionName) {
              case "Manage Forecast":
                this.setState({ permissionManageForecast: true });
                break;
              case "Manage Templates":
                this.setState({ permissionExportForecast: true });
                break;
            }
          });
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "forecastPage",
        });
        reject();
      });
  };

  routeLeavingGuardConfirm = (childState: any) => {
    if (childState === true) {
      routeLeavingGuard = "routeLeavingGuardTrue";
    } else {
      routeLeavingGuard = null;
    }
  };

  selectHotelDropDown = (hotelDetails: any) => {
   // debugger
    if (this.state.changeValue) {
      this.setState({ isHandleForecast: "selectHotelDropDown" });
      
      hotelDetailsData = hotelDetails;
      this.isValidateModelPoupTab();
      
    } else {
      if(this.state.ModeCheck === "templateMode"){
      this.setState({ModeCheck: "foreCastMode" , templateListDisplay: "Select Template"})
      }
      this.childHID?.current?.updateChildState(hotelDetails);
      setTimeout(() => {
        // if (!this.state.scheduleError) {
        //   let todayDate: any = new Date();
        //   this.setState({ calenderSelectDate: todayDate });
        // }
       
        hotelDetailsData = null;

        this.setState(
          {
            hidValue: hotelDetails.hotelID,
          },
          () => {
          
       
            let request = {} as IForecastRequestDto;
            request.hotelID = this.state.hidValue;
            request.date = this.state.tempSelectedDate;
            this.getForcastData(request, "ehidDropdown");

            //Template data loading
            let request2 = {} as IForecastImportTemplateRequestDto;
            request2.hotelID = this.state.hidValue;
            this.getForcastImportTemplateData(request2);
      
          
          }
        );
      }, 0);
    }
  };

  getForcastData(request: IForecastRequestDto, type: string) {
    if (type === "ehidDropdown" || type === "calenderChange") {
      this.setState({ loaderTrue: true });
    }

    LaborForecast.GetLaborForecastSummary(request)
      .then(async (result: any) => {
        let tempForecastData: any = [];
        let calnderDatesDispaly: any = [];
        let lockDays: any = [];
        let headerDates: any = [];
        let headerDays: any = [];
        let isExistForecast = false;
        let laborMgmtMinDate = {};
        if (result != null && result.length > 0) {
          for (let i = 0; i < result.length; i++) {
            // Getting Navigation Dates
            let displayDates: any = result[i].payPeriodDate.split("_");
            calnderDatesDispaly = displayDates;

            // Checking Existing Foreacst
            if (result[i].isExistForcast === "No") {
              isExistForecast = true;
            }

            // Destructing the row to get roomOccupied, arrival, departure and stayOver ID's
            // Arriving Order -- LaborManagementStartDate || RoomOccupliedId * ArrivalId * DepartureId * StayOverId
            let idVaraiblesDate: any = result[i].periodStartDate.split("||");
            // Getting LaborMgmtMinDate
            let periodStartDate = idVaraiblesDate[0];
            laborMgmtMinDate = new Date(periodStartDate);
            // Assigning roomOccupied, arrival, departure and stayOver ID's
            let idsArray: any = idVaraiblesDate[1].split("*");
            roomOccupiedID = Number(idsArray[0]);
            arrivalID = Number(idsArray[1]);
            departureID = Number(idsArray[2]);
            stayOverID = Number(idsArray[3]);

            // Checking Index of roomOccupied, arrival, departure and stayOver accoring to value in row

            if (Number(result[i].typeid) === roomOccupiedID) {
              occupiedIndex = i;
            }
            if (Number(result[i].typeid) === arrivalID) {
              arrivalIndex = i;
            }
            if (Number(result[i].typeid) === departureID) {
              departureIndex = i;
            }
            if (Number(result[i].typeid) === stayOverID) {
              stayOverIndex = i;
            }

            // Generating Table Row Object

            let tempRowObject: any = {};
            tempRowObject.id = i;
            tempRowObject.itemName = result[i].itemName;
            tempRowObject.typeid = Number(result[i].typeid);

            for (let j = 0; j <= 7; j++) {
              let dayValue = `day${j}`;
              let day_forecastData = `day${j}_forecastData`;
              let day_uniqueNo = `day${j}_uniqueNo`;
              let day_Date = `day${j}_Date`;
              let day_Validation = `day${j}_Validation`;

              let day = result[i][dayValue].split("||");
              tempRowObject[day_Validation] = "";
              tempRowObject[day_forecastData] = day[3];
              tempRowObject[day_uniqueNo] = day[5];
              tempRowObject[day_Date] = day[0];
              if (day[2] === "Yes") {
                if (day[4] === "") {
                  if (day[3] === "") {
                    tempRowObject[dayValue] = "0";
                  } else {
                    tempRowObject[dayValue] = day[3];
                  }
                } else {
                  tempRowObject[dayValue] = day[4];
                }
              } else {
                tempRowObject[dayValue] = day[3];
              }

              if (tempRowObject[dayValue].includes(".")) {
                let value = tempRowObject[dayValue].split(".");
                tempRowObject[dayValue] = value[0];
              }

              if (j > 0) {
                day[2] === "Yes" ? lockDays.push(dayValue) : lockDays.push("");
                headerDates.push(day[0].substring(0, day[0].length - 3));
                headerDays.push(day[1]);
              }
            }
            // Pushing Object to an array for table Data
            tempForecastData.push(tempRowObject);
          }

          // Removing multiple Entries and Blank Indexes from Arrays using Set
          const uniqueLockDays: any = new Set(lockDays);
          const arrayLockDays = [...uniqueLockDays];
          let blankIndex = arrayLockDays.indexOf("");
          if (blankIndex > -1) {
            arrayLockDays.splice(blankIndex, 1);
          }
          const uniqueHeaderDates: any = new Set(headerDates);
          const arrayHeaderDates = [...uniqueHeaderDates];
          const uniqueHeaderDays: any = new Set(headerDays);
          const arrayHeaderDays = [...uniqueHeaderDays];

          this.setState(
            {
              lockDays: arrayLockDays,
              headerDates: arrayHeaderDates,
              headerDays: arrayHeaderDays,
              calenderDates: calnderDatesDispaly,
              laborMgmtMinDate: laborMgmtMinDate,
              forecastData: tempForecastData,
              // tempData: tempForecastData,
              importDataBanner: isExistForecast,
              changeValue: false,
              templateName: "",
            },
            () => {
             if(this.state.redierectType==="ViewEdit"){
              //debugger
                let storage = JSON.parse(localStorage.getItem("storage")!);
               let tenantID = storage === null ? 0 : (storage.tenantID as any);
               let item:any={};
               item.hid=this.state.hidValue;
               item.id=0;
               item.name=this.state.ViewTempleteName;
               item.tenantID=tenantID;
               item.type="Template"
              this.gblViewEditTemplete(item);
              }
              else  if (this.state.scheduleError) {
                this.checkSchedulealidation();
              } else {
              //  debugger
                let data = tempForecastData;

                for (let i = 0; i < data.length; i++) {
                  for (let j = 1; j <= 7; j++) {
                    let txtID = i.toString() + j.toString() + "day";
                    $("#tblForeCast")
                      .find("input[id^='" + txtID + "']")
                      .removeClass("red-border");
                  }
                }
              }
            }
          );
        }

        let isblanckcheck=false;
        let data = tempForecastData;
        for (let i = 0; i < data.length; i++) {
          for (let j = 1; j <= 7; j++) {
            let day = `day${j}`;
            if (data[i][day] === "") {   
              isblanckcheck=true;      
              break;
          }
        }
      }
        if(isblanckcheck){
          this.setState({checkValidationOnSaveAsTemapleButon:true});
        }else{
          this.setState({checkValidationOnSaveAsTemapleButon:false});
        }


        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error: ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "forecastPage",
        });
        this.setState({ loaderTrue: false });
        reject();
      });
  }

  checkSchedulealidation = () => {
    let data = this.state.forecastData;
    let isFocus = false;
    for (let i = 0; i < data.length; i++) {
      for (let j = 1; j <= 7; j++) {
        let day = `day${j}`;
        if (data[i][day] === "") {
          let txtID = i.toString() + j.toString() + "day";
          $("#tblForeCast")
            .find("input[id^='" + txtID + "']")
            .addClass("red-border");

          if (!isFocus) {
            $("#tblForeCast")
              .find("input[id^='" + txtID + "']")
              .focus();
            isFocus = true;
          }
        }
      }
    }
  };

  getForcastTemplateData(request: IForecastTemplateRequestDto) {
    LaborForecast.GetLaborForecastTemplateData(request)
      .then(async (result: any) => {
        if (result != null && result.length > 0) {
          
          
          this.setState({ templateData: result });
        }
        else{
          this.setState({ templateData: [] });
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error: ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "forecastPage",
        });
        reject();
      });
  }

  getForcastImportTemplateData(request: IForecastImportTemplateRequestDto) {
    LaborForecast.GetLaborForecastImportTemplateData(request)
      .then(async (result: any) => {
       // debugger;
        let importFromListTemplate: any = [];
        let gblimportFromListTemplate: any = [];
       
        if (result != null && result.length > 0) {
          let importFromListActual: any = [];
         
         
          for (let i = 0; i < result.length; i++) {
            if (result[i].type === "Actual") {
              importFromListActual.push(result[i]);
            } else {
              importFromListTemplate.push(result[i]);
              gblimportFromListTemplate.push(result[i]);
            }
          }
          
          this.setState({
            importFromListActual: importFromListActual,
            importFromListTemplate: importFromListTemplate,
            gblimportFromListTemplate: gblimportFromListTemplate,
          });       
        }
        let newItem = { id: -1, name: "+ Create New Template" };
        importFromListTemplate.push(newItem)
        gblimportFromListTemplate.push(newItem);
        this.setState({
          importFromListTemplate: importFromListTemplate,
          gblimportFromListTemplate: gblimportFromListTemplate
        });
        console.log("importFromListTemplate",importFromListTemplate);
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error: ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "forecastPage",
        });
        reject();
      });
  }

  handleDiscard = () => {
   // debugger;
    this.setState({changeTemplate : false})
   // debugger;
    this.setState({redierectType:""});
    let date = this.state.calenderSelectDate;
    let calenderYear = date.getFullYear();
    let calenderDate = date.getDate();
    let calenderMonth = date.getMonth() + 1;
    let tempDate = calenderMonth + "/" + calenderDate + "/" + calenderYear;
    let request = {} as IForecastRequestDto;
    request.hotelID = this.state.hidValue;
    request.date = tempDate;
    this.getForcastData(request, "discard");
   // this.setState({ changeValue: false, templateName: ""});
     this.setState({ changeValue: false, templateName: ""},()=>{

      // this.setState({templateListDisplay: "Select Template"});
      if(this.state.ModeCheck === "templateMode"){      
       this.editableTextFieldRemove();
      }
    });
  };

  handleTemplateDiscard = () => {
   // debugger;
    this.setState({changeTemplate : false,redierectType:""})    
    if(this.state.templateListDisplay !== "+ Create New Template"){
    this.importTemplateForecast("");
    }
    else{
      this.createNewBlankTemplate();
    }
  
     this.setState({ changeValue: true, templateName: ""},()=>{

      // this.setState({templateListDisplay: "Select Template"});
      // if(this.state.ModeCheck === "templateMode"){      
      //  this.editableTextFieldRemove();
      // }
    });
  };


  editableTextFieldRemove=()=>{
   let txtID = "";
    //txtID ="07day";
   

     let data = [...this.state.forecastData];
    for (let i = 0; i < data.length; i++) {
      for (let j = 1; j <= 7; j++) {
        //let day = `day${j}`;
      //   if (data[i][day] === "") {   
      //     isblanckcheck=true;      
      //     break;
      // }
   //   debugger;
       txtID = i.toString() + j.toString() + "day";
      // $("#tblForeCast")
      // .find("input[id^='" + txtID + "']")
      // .addClass("focus");
    $("#tblForeCast")
      .find("input[id^='" + txtID + "']")
      .closest("tr").removeClass("focus-row")
      .removeClass("red-border");
      

    }
  }



  }



  // handleSaveTemplate = (type: string) => {
  //   let validationFlag = false;
  //   let templateName = this.state.templateName;
  //   let validationMessage = "";
  //   let templateData = this.state.templateData;
  //   if(templateName === "") { //added for added for validation msg if -- else condition
  //     // Show an error message or prevent the save operation
  //     validationFlag = true;
  //     validationMessage = "Template name cannot be blank";
  //     if (validationFlag === true) {
  //       this.saveTemplateModalClose();
  //       Utils.toastError(validationMessage, { 
  //       });}
  //   }
  //   else{
  //   for (let i = 0; i < templateData.length; i++) {
  //     if (
  //       templateName.toLowerCase() ===
  //       templateData[i].templateName.toLowerCase()
  //     ) {
  //       validationFlag = true;
  //     }
  //   }
  //   if (validationFlag === true) {
  //     this.setState({ templateSaveConfirmation: true });
  //   } else {
  //     this.handleSave(type);
  //      this.setState({normalSave : true})
  //   }
  // }
  
  // };

  handleSaveTemplate = (type: string) => {
   // debugger;
    let validationFlag = false;
    let validationMessage = "";
    let templateName = this.state.templateName;
    let templateData = this.state.templateData;

    
    // if(this.state.templateListDisplay === "+ Create New Template"){
    //   this.setState({buttonName: "saveTemplate"});
    // }
    // else{
      // this.setState({buttonName: "saveNewTemplate"});
    // }
    
    if(templateName.trim() === "") { //added for added for validation msg if -- else condition
          // Show an error message or prevent the save operation
          validationFlag = true;
          validationMessage = "Template name cannot be blank";
          if (validationFlag === true) {
            this.saveTemplateModalClose();
            Utils.toastError(validationMessage, { 
            });}
        }
        else{
    for (let i = 0; i < templateData.length; i++) {
      if (
        templateName.toLowerCase() ===
        templateData[i].templateName.toLowerCase()
      ) {
        validationFlag = true;
      }
    }
    if (validationFlag === true) {
      this.setState({ templateSaveConfirmation: true });
    } else {
      this.setState({ buttonName: "saveNewTemplate" }, () => {
        console.log("buttonname", this.state.buttonName);
        this.handleSave(type);
        this.setState({ normalSave: true });
      });
    //   this.setState({ buttonName: "saveNewTemplate" });
    //   console.log("buttonname",this.state.buttonName)
    //   this.handleSave(type);
    //   this.setState({normalSave : true})
    // }
    }
  }
  };

  handleSave = (type: string) => {
  //  debugger;
    
    // this.setState({
    //   templateSaveConfirmation: false,
    // });
    let templateName = this.state.templateName;
    let data = this.state.forecastData;
    if(type === "Template"){
      this.setState({lockDays : []})
    }
    let lockDays = this.state.lockDays;
    let validationFlag = false;
    let validationMessage = "";

    for (let i = 0; i < data.length; i++) {
      for (let j = 1; j <= 7; j++) {
        let day = `day${j}`;
        if (data[i][day] === "") {
          validationFlag = true;
          validationMessage = `A ${type} with blank values cannot be saved`;
          let txtID = i.toString() + j.toString() + "day";
          $("#tblForeCast")
            .find("input[id^='" + txtID + "']")
            .addClass("red-border");
        }
        if (
          Number(data[occupiedIndex][day]) < Number(data[arrivalIndex][day])
        ) {
          validationFlag = true;
          validationMessage = "Rooms Occupied cannot be less then Arrivals";
        }
        if (
          Number(data[arrivalIndex][day]) > Number(data[occupiedIndex][day])
        ) {
          validationFlag = true;
          validationMessage = "Arrivals cannot be greater than Rooms Occupied";
        }
      }
    }

    // console.log("this.state.templateListDisplay",this.state.templateListDisplay);
    
    // if(this.state.ModeCheck === "foreCastMode"){
    //   templateName = ""
    // }
    // else if(this.state.ModeCheck === "templateMode"){
    //   templateName = this.state.templateListDisplay
    //   // this.setState({templateListDisplay : templateName})
    // }
   
   // debugger;
    // if(templateName === "") { //added for added for validation msg if -- else condition
    //     // Show an error message or prevent the save operation
    //     validationFlag = true;
    //     validationMessage = "Template name cannot be blank";
    //     if (validationFlag === true) {
    //       this.saveTemplateModalClose();
    //       Utils.toastError(validationMessage, { 
    //       });}
    //   }
   // removed this to avoid conflict while saving template
    //   if (type === "Template" && this.state.templateName === "") {
    //   validationFlag = true;
    //   validationMessage = "Template name cannot be blank";
    // }

    if (validationFlag === true) {
      Utils.toastError(validationMessage, {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "forecastPage",
      });
      this.setState({
        saveTemplateModal: false,
        // templateName: "",
      });
      return;
    } else {
      // this.setState({
      //   saveForecastButtonSpinner: true,
      //   saveTemplateButtonSpinner: true,
      //   // saveAsNewTemplateButtonSpinner: true
      // });
     // debugger;
      if(this.state.buttonName === "saveNewTemplate" && this.state.ModeCheck === "templateMode"){
        this.setState({saveAsNewTemplateButtonSpinner: true, saveTemplateButtonSpinner: true,})
      }
      else if(this.state.buttonName === "save" && this.state.ModeCheck === "templateMode"){
        this.setState({saveForecastButtonSpinner: true})
      }
      else{
        this.setState({
          saveForecastButtonSpinner: true,
          saveTemplateButtonSpinner: true,
        });
      }

      let templateSaveData: any = [];
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : (storage.tenantID as any);
      let loginUser = storage === null ? 0 : (storage.userName as any);
      for (let i = 0; i < data.length; i++) {
        for (let j = 1; j <= 7; j++) {
          let day = `day${j}`;
          let day_uniqueNo = `day${j}_uniqueNo`;
          let day_Date = `day${j}_Date`;
          let day_forecastData = `day${j}_forecastData`;

          let tempRowObject: any = {} as IForecastSaveDto;
          tempRowObject.Hid = this.state.hidValue;
          tempRowObject.date = data[i][day_Date];
          tempRowObject.typeid = data[i].typeid;
          tempRowObject.TypeName = data[i].itemName;

          if (type === "Template") {
            tempRowObject.ForecastValue = data[i][day];
          } else {
            if (lockDays.includes(day)) {
              if (data[i][day_forecastData] === "") {
                tempRowObject.ForecastValue = "0";
              } else {
                tempRowObject.ForecastValue = data[i][day_forecastData];
              }
            } else {
              tempRowObject.ForecastValue = data[i][day];
            }
          }
          tempRowObject.TenantID = tenantID;
          if(this.state.buttonName === "save"){
            tempRowObject.TemplateName =this.state.templateListDisplay;
          }
          else if(this.state.buttonName === "saveNewTemplate"){
          tempRowObject.TemplateName = this.state.templateName;
          this.setState({buttonName : "save"});
        }
          tempRowObject.saveType = type;
          tempRowObject.Uniqueno = data[i][day_uniqueNo];
          tempRowObject.LoginUser = loginUser;
          templateSaveData.push(tempRowObject);
        }
      }
  //    debugger
      LaborForecast.ForecastSave(templateSaveData)
        .then(async (result: any) => {
          if (result != null) {
 //           debugger
            let resultData = result.result as any;
            
            if (resultData.saveStatus === "Success") {
              let successMessage = "Forecast saved successfully";
              this.setState({changeTemplate : false});
              if (type === "Template") {
                successMessage = "Template saved successfully";
                // Redirect to Schedule in schedule error cases
                this.setState({ changeValue: true , changeTemplate: false , createNewTemplate : false}, () => {
                                  
                  if (this.state.scheduleError && this.state.redierectType==="ViewEdit") {
                    this.setState({
                      redirect: true,
                      changeValue:false
                    });
                  }
                }); // added to hide the banner on save new template

                let request = {} as IForecastImportTemplateRequestDto;
                request.hotelID = this.state.hidValue;
                if(this.state.ModeCheck === "foreCastMode"){
                  this.setState({ModeCheck : "templateMode"})
                }
                this.getForcastImportTemplateData(request);

                if(this.state.ModeCheck === "templateMode"){                 
                  if(this.state.normalSave === true){
                  this.setState({templateListDisplay : templateSaveData[0].TemplateName})
                  this.setState({normalSave : false})
                  }

                }
              }

              this.setState({
                saveTemplateModal: false,
                templateName: "",
              });

              toast.success(successMessage, {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "forecastPage",
              });

              if (type !== "Template") {
                let date = this.state.calenderSelectDate;
                let calenderYear = date.getFullYear();
                let calenderDate = date.getDate();
                let calenderMonth = date.getMonth() + 1;
                let tempDate =
                  calenderMonth + "/" + calenderDate + "/" + calenderYear;
                let request = {} as IForecastRequestDto;
                request.hotelID = this.state.hidValue;
                request.date = tempDate;
                this.getForcastData(request, "saveForecast");
                // Redirect to Schedule in schedule error cases
                this.setState({ changeValue: false }, () => {
                  if (this.state.scheduleError) {
                    this.setState({
                      redirect: true,
                    });
                  }
                });
              }

              // forecast budget calculation starts
              let request: any = {};
              let currentDate: any = null;
              currentDate = templateSaveData[0].date;
              request.hotelID = this.state.hidValue;
              request.layout = "7";
              request.date = currentDate;
              request.type = "ForeCast";
              LaborBudgetSetting.SaveBudgetCalculation(request)
                .then(async (result: any | null) => {})
                .catch((err: any) => {});
              // forecast budget calculation ends
            }
          }
          this.setState({
            saveForecastButtonSpinner: false,
            saveTemplateButtonSpinner: false,
            saveAsNewTemplateButtonSpinner: false
          });
          resolve();
        })
        .catch((err: any) => {
          this.setState({
            saveForecastButtonSpinner: false,
            saveTemplateButtonSpinner: false,
            saveAsNewTemplateButtonSpinner: false
          });
          toast.success(`Server Error: ${err}`, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "forecastPage",
          });
          reject();
        });
    }
  };

  isValidateModelPoupTab() {
    confirmAlert({
      title: "Unsaved confirmation",
      message:
        "You have unsaved changes on this page. Do you want to leave this page and discard your changes?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.isChangeUnsaved(),
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }

  isChangeUnsaved = () => {
    if (this.state.isHandleForecast === "selectHotelDropDown") {
      this.setState({ changeValue: false , ModeCheck: "foreCastMode" , templateListDisplay: "Select Template",changeTemplate:false}, () => {
        this.selectHotelDropDown(hotelDetailsData);
      });
    } else if (this.state.isHandleForecast === "calenderShow") {
      this.setState({ changeValue: false ,changeTemplate:false}, () => {
        this.handleDiscard();
        this.calenderShow();
      });
    } else if (this.state.isHandleForecast === "forecastModalShow") {
      this.setState({ changeValue: false,changeTemplate:false }, () => {
        this.handleDiscard();
        this.importForecastModalShow();
      });
    }
    // elseif(this.state.ModeCheck === "templateMode"){

    // }
  };

  calenderChange = (e: any) => {
    this.setState({changeTemplate : false})
    let calenderYear = e.getFullYear();
    let calenderDate = e.getDate();
    let calenderMonth = e.getMonth() + 1;
    let tempDate = calenderMonth + "/" + calenderDate + "/" + calenderYear;
    let request = {} as IForecastRequestDto;
    request.hotelID = this.state.hidValue;
    request.date = tempDate;
    this.getForcastData(request, "calenderChange");
    this.setState({
      calenderSelectDate: e,
      calenderShow: false,
      tempSelectedDate: tempDate,
    });
  };

  calenderShow = () => {
    if (this.state.changeValue) {
      this.setState({ isHandleForecast: "calenderShow" });
      this.isValidateModelPoupTab();
    } else {
      this.setState({ calenderShow: true });
    }
  };

  calenderHide = () => {
    this.setState({ calenderShow: false });
  };

  importForecastModalShow = () => {
    if (this.state.changeValue) {
      this.setState({ isHandleForecast: "forecastModalShow" });
      this.isValidateModelPoupTab();
    } else {
      let request = {} as IForecastImportTemplateRequestDto;
      request.hotelID = this.state.hidValue;
      this.getForcastImportTemplateData(request);
      this.setState({ importForecastModal: true });
    }
  };

  importFromListSelect = (item: any) => {
   // debugger;
    let tamplateName = `${item.type}s, ${item.name}`;
    let tamplateNames = `${item.name}`;
    this.setState({
      importFromListSelectDisplay: tamplateName,
      importFromListSelect: item,
    });
    if(this.state.ModeCheck === "templateMode"){
      this.setState({templateListDisplay : tamplateNames,
        importFromListSelectDisplay: tamplateNames})
    }
  };

  importForecastModalClose = () => {
    this.setState({
      importForecastModal: false,
      importFromListSelect: {},
      importFromListSelectDisplay: "Select",
      templateListDisplay : "Select Template",
      importFromListActual: [],
      // importFromListTemplate: [], // commented to not empty list
    });
  };

  handleCreateTemplate = (item : any) => {
   // debugger;
    this.importForecastModalClose();
    this.setState({ModeCheck: "templateMode"})
    let request = {} as IForecastImportTemplateRequestDto;
                request.hotelID = this.state.hidValue;
                this.getForcastImportTemplateData(request);
                this.gblViewEditTemplete(item);
    // this.createNewBlankTemplate();
    // this.setState({templateListDisplay : item.name});

  }

  importForecast = (e: any) => {
   // debugger;
    let lockDays = this.state.lockDays;

    if (
      this.state.importFromListSelectDisplay === "Select" ||
      this.state.importFromListSelectDisplay === ""
    ) {
      Utils.toastError("Please select actuals / templates to import a forecast", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "forecastPage",
      });
      return;
    }
    if (lockDays.length === 7) {
      Utils.toastError(
        `The day is Locked, ${this.state.importFromListSelect.type}'s cannot be Imported`,
        {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "forecastPage",
        }
      );
      return;
    } else {
      let request = {} as IForecastTempalteRequestDto;
      request.hotelID = this.state.hidValue;
      request.ImportType = this.state.importFromListSelect.type;
      if (this.state.importFromListSelect.type === "Actual") {
        let name = this.state.importFromListSelect.name;
        name = name.split("-");
        let tempImportTypeValue = name[0].substring(
          name[0].length - 9,
          name[0].length - 1
        );
        request.ImportTypeValue = tempImportTypeValue;
      } else {
        request.ImportTypeValue = this.state.importFromListSelect.name;
      }
      request.PayperiodDate = this.state.calenderDates[0];

      LaborForecast.ImportForecastTemplate(request)
        .then(async (result: any) => {
          if (result.message === "Success") {
        //    debugger;
            if (result.result.length > 0) {
              this.changeImportData(result.result);
              // this.changeTempletNewImportData(result.result)
            } else {
              Utils.toastError("Blank Data Imported", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "forecastPage",
              });
            }
          } else {
            Utils.toastError(result.message, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "forecastPage",
            });
          }
          this.setState({
            importForecastModal: false,
            importFromListSelect: {},
            importFromListSelectDisplay: "Select",
          });
          resolve();
        })
        .catch((err: any) => {
          Utils.toastError(`Server Error: ${err}`, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "forecastPage",
          });
          reject();
        });
    }
  };



  importTemplateForecast = (e: any) => {
    //debugger;
    let lockDays = this.state.lockDays;

    // if (
    //   this.state.importFromListSelectDisplay === "Select" ||
    //   this.state.importFromListSelectDisplay === ""
    // ) {
    //   Utils.toastError("Please select actuals / templates to import a forecast", {
    //     // // position: toast.POSITION.BOTTOM_RIGHT,
    //     // // containerId: "forecastPage",
    //   });
    //   return;
    // }
    // if (lockDays.length === 7) {
    //   Utils.toastError(
    //     `The day is Locked, ${this.state.importFromListSelect.type}'s cannot be Imported`,
    //     {
    //       // // position: toast.POSITION.BOTTOM_RIGHT,
    //       // // containerId: "forecastPage",
    //     }
    //   );
    //   return;
    // } else {
      let request = {} as IForecastTempalteRequestDto;
      request.hotelID = this.state.hidValue;
      request.ImportType = "Template";
      // if (this.state.importFromListSelect.type === "Actual") {
      //   let name = this.state.importFromListSelect.name;
      //   name = name.split("-");
      //   let tempImportTypeValue = name[0].substring(
      //     name[0].length - 9,
      //     name[0].length - 1
      //   );
      //   request.ImportTypeValue = tempImportTypeValue;
      // } else {
      //   request.ImportTypeValue = this.state.importFromListSelect.name;
      // }
      request.ImportTypeValue = this.state.templateListDisplay;
      request.PayperiodDate = this.state.calenderDates[0];
      LaborForecast.ImportNewForecastTemplate(request)
    //  LaborForecast.ImportForecastTemplate(request)
        .then(async (result: any) => {
          if (result.message === "Success") {
        //    debugger;
            if (result.result.length > 0) {
              this.changeImportData(result.result);
              // this.changeTempletNewImportData(result.result)
            } else {
              Utils.toastError("Blank Data Imported", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "forecastPage",
              });
            }
          } else {
            Utils.toastError(result.message, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "forecastPage",
            });
          }
          this.setState({
            importForecastModal: false,
            importFromListSelect: {},
            importFromListSelectDisplay: "Select",
          });
          resolve();
        })
        .catch((err: any) => {
          Utils.toastError(`Server Error: ${err}`, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "forecastPage",
          });
          reject();
        });
   // }
  };







  changeImportData = (incomingData: any) => {
   
    let tableData = this.state.forecastData;

    for (let i = 0; i < tableData.length; i++) {
      for (let j = 0; j < incomingData.length; j++) {
        if (tableData[i].typeid === Number(incomingData[j].typeid)) {
          let day0 = incomingData[i].day0.split("||");
          let day1 = incomingData[i].day1.split("||");
          let day2 = incomingData[i].day2.split("||");
          let day3 = incomingData[i].day3.split("||");
          let day4 = incomingData[i].day4.split("||");
          let day5 = incomingData[i].day5.split("||");
          let day6 = incomingData[i].day6.split("||");
          let day7 = incomingData[i].day7.split("||");

          if (this.state.lockDays.includes("day0") === false) {
            let value = day0[3].split(".");
            tableData[i].day0 = value[0];
          }
          if (this.state.lockDays.includes("day1") === false) {
            let value = day1[3].split(".");
            tableData[i].day1 = value[0];
          }
          if (this.state.lockDays.includes("day2") === false) {
            let value = day2[3].split(".");
            tableData[i].day2 = value[0];
          }
          if (this.state.lockDays.includes("day3") === false) {
            let value = day3[3].split(".");
            tableData[i].day3 = value[0];
          }
          if (this.state.lockDays.includes("day4") === false) {
            let value = day4[3].split(".");
            tableData[i].day4 = value[0];
          }
          if (this.state.lockDays.includes("day5") === false) {
            let value = day5[3].split(".");
            tableData[i].day5 = value[0];
          }
          if (this.state.lockDays.includes("day6") === false) {
            let value = day6[3].split(".");
            tableData[i].day6 = value[0];
          }
          if (this.state.lockDays.includes("day7") === false) {
            let value = day7[3].split(".");
            tableData[i].day7 = value[0];
          }
        }
      }
    }

    this.setState({
      changeValue: true,
      forecastData: tableData,
      importDataBanner: false,
      templateName: "",
      loaderTrue: false
    },()=>{
      if(this.state.ModeCheck === "templateMode"){      
        this.editableTextFieldRemove();
       }
      
    });

    let isblanckcheck=false;
    let data = tableData;
    for (let i = 0; i < data.length; i++) {
      for (let j = 1; j <= 7; j++) {
        let day = `day${j}`;
        if (data[i][day] === "") {   
          isblanckcheck=true;      
          break;
      }
    }
  }
    if(isblanckcheck){
      this.setState({checkValidationOnSaveAsTemapleButon:true});
    }else{
      this.setState({checkValidationOnSaveAsTemapleButon:false});
    }



  };

  saveTemplateModalShow = () => {
    let request = {} as IForecastTemplateRequestDto;
    
    request.hotelID = this.state.hidValue;
    this.getForcastTemplateData(request);

    this.setState({ saveTemplateModal: true, templateSaveConfirmation: false });
    this.setState({ saveAsNewTemplateButtonSpinner: true })
    setTimeout(() => {
      this.setState({ saveAsNewTemplateButtonSpinner: false })
    },1000)
  };

  saveTemplateModalClose = () => {
    this.setState({
      saveTemplateModal: false,
      templateName: "",
      templateSaveConfirmation: false,
    });
  };

  saveTemplateName = (e: any) => {
    let value = e.target.value;
    this.setState({ templateName: value });
  };

  warningCloseModel = () => {
    this.setState({ templateSaveConfirmation: false });
  };

  saveCopyBudgets = () => {
   // debugger;
    for(let i=0;i<this.state.importFromListTemplate.length;i++) {
      if(this.state.importFromListTemplate[i].name.toLowerCase() === this.state.templateName.toLowerCase()){
        console.log("this.state.importFromListTemplate[i].name",this.state.importFromListTemplate[i].name);
        
        this.setState({templateListDisplay: this.state.importFromListTemplate[i].name}) // added for overwrite
      }
    }
    this.setState(
      {
        templateSaveConfirmation: false,
        
      },
      () => {
        this.handleSave("Template");
      }
    );
  };

  cellEdit = cellEditFactory({
    mode: "click",
    autoSelectText: true,
    blurToSave: true,
    onStartEdit: (row: any, column: any, rowIndex: any, columnIndex: any) => {
      this.setState({ rowEditNo: rowIndex });
    },
    beforeSaveCell: (
      oldValue: any,
      newValue: any,
      row: any,
      column: any,
      done: (arg0: boolean | undefined) => void
    ) => {
      let data = this.state.forecastData;
      let dayValue = column.dataField;
      let saveValidationMessageFlag = false;
      let validationFlag = false;
      let validationMessage = "";
      if (oldValue === newValue) {
        validationFlag = true;
      } else if (row.typeid === roomOccupiedID || row.typeid === arrivalID) {
        if (row.typeid === roomOccupiedID) {
          if (
            data[arrivalIndex][dayValue] !== "" &&
            Number(newValue) < Number(data[arrivalIndex][dayValue])
          ) {
            validationMessage = "Rooms Occupied cannot be less then Arrivals";
            saveValidationMessageFlag = true;
            let validationString = `${dayValue}_Validation`;
            data[occupiedIndex][validationString] = "Validated";
          } else {
            validationFlag = false;
            saveValidationMessageFlag = false;
            let newTableData = [...this.state.forecastData];
            newTableData = newTableData.map((d) => {
              if (d.typeid === roomOccupiedID) {
                if (column.dataField === "day1") {
                  return {
                    ...d,
                    day1_Validation: "",
                  };
                } else if (column.dataField === "day2") {
                  return {
                    ...d,
                    day2_Validation: "",
                  };
                } else if (column.dataField === "day3") {
                  return {
                    ...d,
                    day3_Validation: "",
                  };
                } else if (column.dataField === "day4") {
                  return {
                    ...d,
                    day4_Validation: "",
                  };
                } else if (column.dataField === "day5") {
                  return {
                    ...d,
                    day5_Validation: "",
                  };
                } else if (column.dataField === "day6") {
                  return {
                    ...d,
                    day6_Validation: "",
                  };
                } else if (column.dataField === "day7") {
                  return {
                    ...d,
                    day7_Validation: "",
                  };
                }
              } else if (d.typeid === arrivalID) {
                if (column.dataField === "day1" && d.day1 !== "") {
                  return {
                    ...d,
                    day1_Validation: "",
                  };
                } else if (column.dataField === "day2" && d.day2 !== "") {
                  return {
                    ...d,
                    day2_Validation: "",
                  };
                } else if (column.dataField === "day3" && d.day3 !== "") {
                  return {
                    ...d,
                    day3_Validation: "",
                  };
                } else if (column.dataField === "day4" && d.day4 !== "") {
                  return {
                    ...d,
                    day4_Validation: "",
                  };
                } else if (column.dataField === "day5" && d.day5 !== "") {
                  return {
                    ...d,
                    day5_Validation: "",
                  };
                } else if (column.dataField === "day6" && d.day6 !== "") {
                  return {
                    ...d,
                    day6_Validation: "",
                  };
                } else if (column.dataField === "day7" && d.day7 !== "") {
                  return {
                    ...d,
                    day7_Validation: "",
                  };
                }
              } else if (d.typeid === departureID || d.typeid === stayOverID) {
                if (column.dataField === "day1") {
                  return {
                    ...d,
                    day1_Validation: "",
                    day2_Validation: "",
                  };
                } else if (column.dataField === "day2") {
                  return {
                    ...d,
                    day2_Validation: "",
                    day3_Validation: "",
                  };
                } else if (column.dataField === "day3") {
                  return {
                    ...d,
                    day3_Validation: "",
                    day4_Validation: "",
                  };
                } else if (column.dataField === "day4") {
                  return {
                    ...d,
                    day4_Validation: "",
                    day5_Validation: "",
                  };
                } else if (column.dataField === "day5") {
                  return {
                    ...d,
                    day5_Validation: "",
                    day6_Validation: "",
                  };
                } else if (column.dataField === "day6") {
                  return {
                    ...d,
                    day6_Validation: "",
                    day7_Validation: "",
                  };
                } else if (column.dataField === "day7") {
                  return {
                    ...d,
                    day7_Validation: "",
                  };
                }
              } else {
              }
              return d;
            });
            this.setState((curr: any) => ({
              ...curr,
              forecastData: newTableData,
            }));
          }
        } else {
          if (
            data[occupiedIndex][dayValue] !== "" &&
            Number(newValue) > Number(data[occupiedIndex][dayValue])
          ) {
            validationMessage =
              "Arrivals cannot be greater than Rooms Occupied";
            saveValidationMessageFlag = true;
            let validationString = `${dayValue}_Validation`;
            data[arrivalIndex][validationString] = "Validated";
          } else {
            validationFlag = false;
            saveValidationMessageFlag = false;
            let newTableData = [...this.state.forecastData];
            newTableData = newTableData.map((d) => {
              if (d.typeid === roomOccupiedID) {
                if (column.dataField === "day1") {
                  return {
                    ...d,
                    day1_Validation: "",
                  };
                } else if (column.dataField === "day2") {
                  return {
                    ...d,
                    day2_Validation: "",
                  };
                } else if (column.dataField === "day3") {
                  return {
                    ...d,
                    day3_Validation: "",
                  };
                } else if (column.dataField === "day4") {
                  return {
                    ...d,
                    day4_Validation: "",
                  };
                } else if (column.dataField === "day5") {
                  return {
                    ...d,
                    day5_Validation: "",
                  };
                } else if (column.dataField === "day6") {
                  return {
                    ...d,
                    day6_Validation: "",
                  };
                } else if (column.dataField === "day7") {
                  return {
                    ...d,
                    day7_Validation: "",
                  };
                }
              } else if (d.typeid === arrivalID) {
                if (column.dataField === "day1" && d.day1 !== "") {
                  return {
                    ...d,
                    day1_Validation: "",
                  };
                } else if (column.dataField === "day2" && d.day2 !== "") {
                  return {
                    ...d,
                    day2_Validation: "",
                  };
                } else if (column.dataField === "day3" && d.day3 !== "") {
                  return {
                    ...d,
                    day3_Validation: "",
                  };
                } else if (column.dataField === "day4" && d.day4 !== "") {
                  return {
                    ...d,
                    day4_Validation: "",
                  };
                } else if (column.dataField === "day5" && d.day5 !== "") {
                  return {
                    ...d,
                    day5_Validation: "",
                  };
                } else if (column.dataField === "day6" && d.day6 !== "") {
                  return {
                    ...d,
                    day6_Validation: "",
                  };
                } else if (column.dataField === "day7" && d.day7 !== "") {
                  return {
                    ...d,
                    day7_Validation: "",
                  };
                }
              } else if (d.typeid === departureID || d.typeid === stayOverID) {
                if (column.dataField === "day1") {
                  return {
                    ...d,
                    day1_Validation: "",
                    day2_Validation: "",
                  };
                } else if (column.dataField === "day2") {
                  return {
                    ...d,
                    day2_Validation: "",
                    day3_Validation: "",
                  };
                } else if (column.dataField === "day3") {
                  return {
                    ...d,
                    day3_Validation: "",
                    day4_Validation: "",
                  };
                } else if (column.dataField === "day4") {
                  return {
                    ...d,
                    day4_Validation: "",
                    day5_Validation: "",
                  };
                } else if (column.dataField === "day5") {
                  return {
                    ...d,
                    day5_Validation: "",
                    day6_Validation: "",
                  };
                } else if (column.dataField === "day6") {
                  return {
                    ...d,
                    day6_Validation: "",
                    day7_Validation: "",
                  };
                } else if (column.dataField === "day7") {
                  return {
                    ...d,
                    day7_Validation: "",
                  };
                }
              } else {
              }
              return d;
            });
            this.setState((curr: any) => ({
              ...curr,
              forecastData: newTableData,
            }));
          }
        }
      } else {
        validationFlag = false;
        saveValidationMessageFlag = false;
        let newTableData = [...this.state.forecastData];
        newTableData = newTableData.map((d) => {
          if (d.typeid === row.typeid) {
            if (column.dataField === "day1") {
              return {
                ...d,
                day1_Validation: "",
              };
            } else if (column.dataField === "day2") {
              return {
                ...d,
                day2_Validation: "",
              };
            } else if (column.dataField === "day3") {
              return {
                ...d,
                day3_Validation: "",
              };
            } else if (column.dataField === "day4") {
              return {
                ...d,
                day4_Validation: "",
              };
            } else if (column.dataField === "day5") {
              return {
                ...d,
                day5_Validation: "",
              };
            } else if (column.dataField === "day6") {
              return {
                ...d,
                day6_Validation: "",
              };
            } else if (column.dataField === "day7") {
              return {
                ...d,
                day7_Validation: "",
              };
            }
          } else {
          }
          return d;
        });
        this.setState((curr: any) => ({
          ...curr,
          forecastData: newTableData,
        }));
      }

      setTimeout(() => {
        if (validationFlag === true) {
          done(false);
        } else {
          if (saveValidationMessageFlag === true) {
            done(true);
            Utils.toastError(validationMessage, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "forecastPage",
            });
          } else {
            done(true);
          }
        }
      }, 0);
      this.setState({ rowEditNo: null });
      return { async: true };
    },
    afterSaveCell: (oldValue: any, newValue: any, row: any, column: any) => {
      let data = this.state.forecastData;
      let dayValue = column.dataField;
      let dayIndex = Number(
        dayValue.substring(dayValue.length - 1, dayValue.length)
      );
      let earlierDayValue = `day${dayIndex - 1}`;
      let nextDayValue = `day${dayIndex + 1}`;

      if (row.typeid === roomOccupiedID || row.typeid === arrivalID) {
        data[departureIndex][dayValue] = (
          Number(data[occupiedIndex][earlierDayValue]) +
          Number(data[arrivalIndex][dayValue]) -
          Number(data[occupiedIndex][dayValue])
        ).toString();
        data[stayOverIndex][dayValue] = (
          Number(data[occupiedIndex][earlierDayValue]) -
          Number(data[departureIndex][dayValue])
        ).toString();
        if (dayIndex < 7) {
          data[departureIndex][nextDayValue] = (
            Number(data[occupiedIndex][dayValue]) +
            Number(data[arrivalIndex][nextDayValue]) -
            Number(data[occupiedIndex][nextDayValue])
          ).toString();
          data[stayOverIndex][nextDayValue] = (
            Number(data[occupiedIndex][dayValue]) -
            Number(data[departureIndex][nextDayValue])
          ).toString();
        }
      }

      setTimeout(() => {
        this.setState({
          changeValue: true,
          forecastData: data,
          rowEditNo: null,
          importDataBanner: false,
        });
      }, 0);
    },
  });

  handleInputValue(e: any, row: any, rowIndex: any, dayNo: any, typeID: any) {
    this.setState({changeTemplate : true})
    let val = e.target.value;
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.forecastData))
    );
    // let datas =  Object.assign(
    //   [],
    //   JSON.parse(JSON.stringify(this.state.tempData))
    // );
    let txtID = "";

    if (/^\d+$/.test(val) === false) {
      val = val.replace(/[^\d]/g, "");
    }
    if (val.length > 8) {
      let a = val.replace(/[^\d]/g, "");
      val = a.substring(0, val.length - 1);
    }
    let day = "day" + dayNo;
    data[rowIndex][day] = val;
    // datas[rowIndex][day] = val;

    txtID = rowIndex.toString() + dayNo.toString() + "day";

    this.setState(
      {
        forecastData: data,
        // tempData : data,
        changeValue: true,
        rowEditNo: null,
        importDataBanner: false,
      },
      () => {
        $("#tblForeCast")
          .find("input[id^='" + txtID + "']")
          .focus();
      }
    );
  }

  onBlurInputValue(e: any, row: any, rowIndex: any, dayNo: any, typeID: any) {   
    let val = e.target.value;
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.forecastData))
    );
    // let datas = Object.assign(
    //   [],
    //   JSON.parse(JSON.stringify(this.state.forecastData))
    // );
    let txtID = "";

    txtID = rowIndex.toString() + dayNo.toString() + "day";

    let validation = false;
    if (val === "") {
      validation = true;      
    } else if (typeID === roomOccupiedID) {
      let arrivalRow = data.filter((e) => e.typeid === arrivalID);
      let dayname = "day" + dayNo;
      let arrivalval = arrivalRow[0][dayname];
      let txtID1 = arrivalIndex.toString() + dayNo.toString() + "day";
      $("#tblForeCast")
        .find("input[id^='" + txtID1 + "']")
        .removeClass("red-border");

      if (Number(val) < Number(arrivalval)) {
        validation = true;
        Utils.toastError("Arrivals cannot be greater than Rooms Occupied", {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "forecastPage",
        });
      }
    } else if (typeID === arrivalID) {
      let roomRow = data.filter((e: any) => e.typeid === roomOccupiedID);
      let dayname = "day" + dayNo;
      let roomval = roomRow[0][dayname];

      let txtID1 = occupiedIndex.toString() + dayNo.toString() + "day";

      $("#tblForeCast")
        .find("input[id^='" + txtID1 + "']")
        .removeClass("red-border");

      if (Number(roomval) < Number(val)) {
        validation = true;
        Utils.toastError("Arrivals cannot be greater than Rooms Occupied", {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "forecastPage",
        });
      }
    } else {
      validation = false;
      //this.setState({checkValidationOnSaveAsTemapleButon:false});
    }
    if (validation) {
      $("#tblForeCast")
        .find("input[id^='" + txtID + "']")
        .addClass("red-border");
        this.setState({checkValidationOnSaveAsTemapleButon:true});
    } else {
     
      let dayValue = "day" + dayNo;
      let dayIndex = Number(dayNo);
      let earlierDayValue = `day${dayIndex - 1}`;
      let nextDayValue = `day${dayIndex + 1}`;

      if (row.typeid === roomOccupiedID || row.typeid === arrivalID) {
      //  debugger;
        data[departureIndex][dayValue] = (
          Number(data[occupiedIndex][earlierDayValue]) +
          Number(data[arrivalIndex][dayValue]) -
          Number(data[occupiedIndex][dayValue])
        ).toString();
        // Added to remove negative departure
        if(data[departureIndex][dayValue] < 0){
          data[departureIndex][dayValue] = 0
        }
        
        data[stayOverIndex][dayValue] = (
          Number(data[occupiedIndex][earlierDayValue]) -
          Number(data[departureIndex][dayValue])
        ).toString();
        
        if (dayIndex < 7) {
          data[departureIndex][nextDayValue] = (
            Number(data[occupiedIndex][dayValue]) +
            Number(data[arrivalIndex][nextDayValue]) -
            Number(data[occupiedIndex][nextDayValue])
          ).toString();

          // Added to remove negative departure
          if(data[departureIndex][nextDayValue] < 0){
            data[departureIndex][nextDayValue] = 0
          }
         
          data[stayOverIndex][nextDayValue] = (
            Number(data[occupiedIndex][dayValue]) -
            Number(data[departureIndex][nextDayValue])
          ).toString();
          
        }
      }


     

      this.setState({ forecastData: data });
      // this.setState({ tempData: data });
      $("#tblForeCast")
        .find("input[id^='" + txtID + "']")
        .closest("tr")
        .removeClass("focus-row");
       // this.setState({checkValidationOnSaveAsTemapleButon:false});


   let isblanckcheck=false;
        let checkdata = data;
        for (let i = 0; i < checkdata.length; i++) {
          for (let j = 1; j <= 7; j++) {
            let day = `day${j}`;
            if (checkdata[i][day] === "") {   
              isblanckcheck=true;      
              break;
          }
        }
      }
        if(isblanckcheck){
          this.setState({checkValidationOnSaveAsTemapleButon:true});
        }else{
          this.setState({checkValidationOnSaveAsTemapleButon:false});
        }






    } 

  }

  onFocusInputValue(e: any, row: any, rowIndex: any, dayNo: any, typeID: any) {
   
  //  debugger;
    let txtID = "";
    txtID = rowIndex.toString() + dayNo.toString() + "day";
    $("#tblForeCast")
      .find("input[id^='" + txtID + "']")
      .addClass("focus");
    $("#tblForeCast")
      .find("input[id^='" + txtID + "']")
      .closest("tr")
      .addClass("focus-row");
  }

  inputformatter = (cell: any, row: any, rowIndex: any, dayNo: any) => {
    const day = `day${dayNo}`;
    const txtID = `${rowIndex}${dayNo}day`;

    const conditonFormatter = `day${dayNo}_Validation`;
    const valueFormatter = `day${dayNo}`;

    // if(this.state.CheckMode ==="foreCastMode"){
    if (
      row.typeid === stayOverID ||
      row.typeid === departureID ||
      this.state.lockDays.includes(day)
    ) {
      return (
        <div
          className={
            row[conditonFormatter] === "Validated"
              ? "forecast-name tab-key validated"
              : "forecast-name tab-key"
          }
        >
          {row[valueFormatter]}
        </div>
      );
    // } 
    // else {
    //   return (
    //     <div
    //       className={
    //         row[conditonFormatter] === "Validated"
    //           ? "forecast-name tab-key validated"
    //           : "forecast-name tab-key"
    //       }
    //     >
    //       <Form.Group>
    //         <Form.Control
    //           className={this.state.permissionManageForecast ? "" : "p-none"}
    //           ref={this.inputRow}
    //           maxLength={8}
    //           type="text"
    //           key={txtID}
    //           id={txtID}
    //           onChange={(e: any) => {
    //             // this.setState({permissionManageForecast : true})
    //             if (this.state.permissionManageForecast) {
    //               this.handleInputValue(e, row, rowIndex, dayNo, row.typeid);
    //             } else {
    //               return false;
    //             }
    //           }}
    //           onBlur={(e: any) => {
    //             if (this.state.permissionManageForecast) {
    //               this.onBlurInputValue(e, row, rowIndex, dayNo, row.typeid);
    //             } else {
    //               return false;
    //             }
    //           }}
    //           onSelect={(e: any) => {
    //             if (this.state.permissionManageForecast) {
    //               this.onFocusInputValue(e, row, rowIndex, dayNo, row.typeid);
    //             } else {
    //               return false;
    //             }
    //           }}
    //           value={row[valueFormatter]}
    //         />
    //       </Form.Group>
    //     </div>
    //   );
    // }
  }
  //added
  else{
    return (
      <div
        className={
          row[conditonFormatter] === "Validated"
            ? "forecast-name tab-key validated"
            : "forecast-name tab-key"
        }
      >
        <Form.Group>
          <Form.Control
            className={this.state.permissionManageForecast ? "" : "p-none"}
            ref={this.inputRow}
            maxLength={8}
            type="text"
            key={txtID}
            id={txtID}
            onChange={(e: any) => {
              this.setState({permissionManageForecast : true})
              if (this.state.permissionManageForecast) {
                this.handleInputValue(e, row, rowIndex, dayNo, row.typeid);
              } else {
                return false;
              }
            }}
            onBlur={(e: any) => {
              if (this.state.permissionManageForecast) {
                this.onBlurInputValue(e, row, rowIndex, dayNo, row.typeid);
              } else {
                return false;
              }
            }}
            onSelect={(e: any) => {
              if (this.state.permissionManageForecast) {
                this.onFocusInputValue(e, row, rowIndex, dayNo, row.typeid);
              } else {
                return false;
              }
            }}
            value={row[valueFormatter]}
            autoComplete="off"
          />
        </Form.Group>
      </div>
    );
  }
  };

  rowEvents = {
    onChange: (e: any, cell: any) => {
      let value = e.target.value;
      if (/^\d+$/.test(value) === false) {
        e.target.value = value.replace(/[^\d]/g, "");
      }
      if (value.length > 8) {
        let a = value.replace(/[^\d]/g, "");
        e.target.value = a.substring(0, value.length - 1);
      }
    },
  };

  headerFormatter = (column: any, colIndex: any, row: any) => {
    let forecastLockDisplay = false;
    let lockDays = this.state.lockDays;
    let headerDates = this.state.headerDates;
    let headerDays = this.state.headerDays;
    if (lockDays.includes(column.dataField)) {
      forecastLockDisplay = true;
    }
    const tooltipMessage =
      "The forecast cannot be changed because a shift from this day has already begun.";

    return (
      <div className="column-header">
        <div className="header-data">
          {forecastLockDisplay && (
            <span className="forecast-lock" data-tip={tooltipMessage}>
              <ReactTooltip
                effect="solid"
                event="mouseover mouseenter"
                eventOff="mouseleave mouseout scroll mousewheel blur"
                place="bottom"
                multiline={true}
              />
              {this.state.ModeCheck === "foreCastMode" ? 
              <img src={ForecastLock} alt="ForecastLock" />: <></>}
            </span>
          )}
 
            <span className="header-day">{headerDays[colIndex - 1]}</span>
          
        </div>
        {this.state.ModeCheck === "foreCastMode" ? 
        <p className="header-date">{headerDates[colIndex - 1]}</p>: <></>}
      </div>
    );
  };

  headerFormatter2 = (column: any, colIndex: any, row: any) => {
    let forecastLockDisplay = false;
    let lockDays = this.state.lockDays;
    let headerDates = this.state.headerDates;
    let headerDays = this.state.headerDays;
    if (lockDays.includes(column.dataField)) {
      forecastLockDisplay = true;
    }
    const tooltipMessage =
      "The forecast cannot be changed because a shift from this day has already begun.";

    return (
      <div className="column-header">
        <div className="header-data">
          <span className="header-day">{headerDays[colIndex - 1]}</span>
        </div>
      </div>
    );
  };

  renderItem(item: any, isHighlighted: any) {
    return (
      <div style={{ background: isHighlighted ? "lightgray" : "white" }}>
        {item.templateName}
      </div>
    );
  }
  getItemValue(item: { ID: any; templateName: any }) {
    return `${item.ID}@@@${item.templateName}`;
  }

  onAutocompleteSelect = (selectItem: any) => {
    let TemplateNameArray = selectItem.split("@@@");
    let templatName = TemplateNameArray[1]
      .toString()
      .replace(/(^\s+|\s+$)/g, "");
    this.setState({ templateName: templatName });
  };

  onAutocompleteChange = (e: any) => {
    let value = e.target.value;
    if (/^[A-Za-z0-9\-'&_() ]+$/.test(value) === false) {
      e.target.value = value.replace(/[^A-Za-z0-9\-'&_() ]/g, "");
    }
    if (value.length > 50) {
      let a = value.replace(/[^A-Za-z0-9\-'&_() ]/g, "");
      e.target.value = a.substring(0, value.length - 1);
    }
    this.setState({ templateName: e.target.value });
  };

  onChangeValue= (event) => {
   // debugger;
    if(this.state.changeTemplate === true){
      this.isValidateChangeMode(event)
    }
    else{
      this.setState({changeValue: false})
      this.onChangeMode(event,"Yes")
      // this.onChangeMode(event,"")
    }
    // if(event.target.id === "dateSelector")
    // {
    //   this.setState({ModeCheck : "foreCastMode"});
    // }
    // else if (event.target.id === "templateSelector") {
    //   this.setState({ModeCheck : "templateMode"});
    // }
  }

  onChangeMode = (event,type:any) => {
   // debugger;
    this.setState({changeValue: false,templateListDisplay: "Select Template",changeTemplate:false},()=>{

   
    
      if(event.target.id === "dateSelector")
    {
      this.setState({ModeCheck : "foreCastMode"},()=>{
      //  this.setState({tempData : []})
        let date = this.state.calenderSelectDate;
        let calenderYear = date.getFullYear();
        let calenderDate = date.getDate();
        let calenderMonth = date.getMonth() + 1;
        let tempDate =
          calenderMonth + "/" + calenderDate + "/" + calenderYear;
        let request = {} as IForecastRequestDto;
        request.hotelID = this.state.hidValue;
        request.date = tempDate;
        if(type==="Yes"){
          this.editableTextFieldRemove();
        this.getForcastData(request, "calenderChange");
        }
      });
    }
    else if (event.target.id === "templateSelector") {
      this.setState({ModeCheck : "templateMode" , createNewTemplate : false});
      // this.createNewBlankTemplate();
      if(type==="Yes"){
     // this.editableTextFieldRemove();
    }
      //this.setState
    }

  });
  }

  checkValue = () => {
    const data = this.state.forecastData;
    for(let i=0; i<data.length; i++){
      const item = data[i];
      if( item.day0 || item.day1 || item.day2 || item.day3 || item.day4 || item.day5 || item.day6 || item.day7){
        return true;
      }
    }
    return false;
  }


  renderRedirect = () => {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: "/labor-management/schedule",
            state: {
              pageLoadDate: this.state.currentDate,
              isForcast: true,
              scheduleViewType: this.state.scheduleViewType,
            },
          }}
        />
      );
    }
  };

check = (item: any) => {
  if(this.state.changeTemplate === true){
    this.isValidateTemplateChange(item);
    
  }
  else{
    this.gblViewEditTemplete(item);
  }
}
  gblViewEditTemplete = (item: any) => {
   // debugger;
    this.setState({createNewTemplate : false , changeTemplate:false, lockDays: [] , loaderTrue: true})
    if(this.state.ModeCheck === "foreCastMode"){
      this.setState({ModeCheck : "templateMode"});
      // this.setState({ changeValue: false });
      // this.setState({ permissionManageForecast: false });
    }
    if(item.name==="+ Create New Template"){
      item.id=-1;
    }

    if(item.id !== -1)
    {
    let tamplateName =item.name; 
    this.setState({
      importFromListSelectDisplay: tamplateName,
      importFromListSelect: item,
      templateListDisplay: tamplateName,
      createNewTemplate : false,
      templateName: tamplateName
    
    },()=>{
      this.importTemplateForecast("");
    });
  }
  else{
    let tamplateName =item.name; 
    this.setState({
      templateListDisplay: tamplateName,
      createNewTemplate : true
    
    });
    this.createNewBlankTemplate();
  }
  };


  createNewBlankTemplate = () => {
    
    
    let request = {} as IForecastRequestDto;
    request.hotelID = this.state.hidValue;
    request.date = this.state.tempSelectedDate;
    this.GetForecastNewTemplete(request);
    
 // debugger
    // this.setState({ saveTemplateModal: true, templateSaveConfirmation: false });
    // this.setState({ saveAsNewTemplateButtonSpinner: true })
    // setTimeout(() => {
    //   this.setState({ saveAsNewTemplateButtonSpinner: false })
    // },1000)
  };

  GetForecastNewTemplete(request: IForecastRequestDto) {
  //  debugger;
    LaborForecast.GetForecastNewTemplete(request)
      .then(async (result: any) => {
        if (result != null && result.length > 0) {
        //  debugger
          this.changeTempletNewImportData(result);
          this.setState({createNewTemplate : true})
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error: ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "forecastPage",
        });
        reject();
      });
  }

  changeTempletNewImportData = (incomingData: any) => {
  // debugger;
    // let tableData = this.state.tempData;
    let tableData = this.state.forecastData;

    for (let i = 0; i < tableData.length; i++) {
      for (let j = 0; j < incomingData.length; j++) {
        if (tableData[i].typeid === Number(incomingData[j].typeid)) {
          let day0 = incomingData[i].day0.split("||");
          let day1 = incomingData[i].day1.split("||");
          let day2 = incomingData[i].day2.split("||");
          let day3 = incomingData[i].day3.split("||");
          let day4 = incomingData[i].day4.split("||");
          let day5 = incomingData[i].day5.split("||");
          let day6 = incomingData[i].day6.split("||");
          let day7 = incomingData[i].day7.split("||");

          // if (this.state.lockDays.includes("day0") === true) {
          //   let value = day0[3].split(".");
          //   tableData[i].day0 = "0";
          //   tableData[i].day0_forecastData = "0";
          // }
          // else{
            let value0 = day0[3].split(".");
            tableData[i].day0 = value0[0];         
          // }
          // if (this.state.lockDays.includes("day1") === true) {
          //   let value = day1[3].split(".");
          //   tableData[i].day1 = "0";
          //   tableData[i].day1_forecastData = "0";
          // }
          // else{
            let value1 = day1[3].split(".");
            tableData[i].day1 = value1[0];         
          // }
          // if (this.state.lockDays.includes("day2") === true) {
          //   let value = day2[3].split(".");
          //   tableData[i].day2 = "0";
          //   tableData[i].day2_forecastData = "0";
          // }
          // else{
            let value2 = day2[3].split(".");
            tableData[i].day2 = value2[0];         
          // }
          // if (this.state.lockDays.includes("day3") === true) {
          //   let value = day3[3].split(".");
          //   tableData[i].day3 = "0";
          //   tableData[i].day3_forecastData = "0";
          // }
          // else{
            let value3 = day3[3].split(".");
            tableData[i].day3 = value3[0];         
          // }
          // if (this.state.lockDays.includes("day4") === true) {
          //   let value = day4[3].split(".");
          //   tableData[i].day4 = "0";
          //   tableData[i].day4_forecastData = "0";
          // }
          // else{
            let value4 = day4[3].split(".");
            tableData[i].day4 = value4[0];         
          // }
          // if (this.state.lockDays.includes("day5") === true) {
          //   let value = day5[3].split(".");
          //   tableData[i].day5 = "0";
          //   tableData[i].day5_forecastData = "0";
          // }
          // else{
            let value5 = day5[3].split(".");
            tableData[i].day5 = value5[0];         
          // }
          // if (this.state.lockDays.includes("day6") === true) {
          //   let value = day6[3].split(".");
          //   tableData[i].day6 = "0";
          //   tableData[i].day6_forecastData = "0";
          // }
          // else{
            let value6 = day6[3].split(".");
            tableData[i].day6 = value6[0];         
          // }
          // if (this.state.lockDays.includes("day7") === true) {
          //   let value = day7[3].split(".");
          //   tableData[i].day7 = "0";
          //   tableData[i].day7_forecastData = "0";
          // }
          // else{
            let value7 = day7[3].split(".");
            tableData[i].day7 = value7[0];         
          // }
        }
      }
    }
//debugger;

console.log("lockday",this.state.lockDays);

    this.setState({
      changeValue: true,
      // tempData: tableData,
      forecastData : tableData,
      importDataBanner: false,
      templateName: "",
      loaderTrue: false
    },()=>{     
      //debugger;

      //debugger;
   
      let txtID = "";
      //txtID ="07day"; 
       let data = tableData;
      for (let i = 0; i < data.length; i++) {
        for (let j = 1; j <= 7; j++) {
          
         txtID = i.toString() + j.toString() + "day";      
  
        $("#tblForeCast")
        .find("input[id^='" + txtID + "']")
        .closest("tr").find("input[id^='" + txtID + "']").removeClass("red-border");
       
        
      }
    }
      });
  




    
    
  
    //});

    if(this.state.templateListDisplay === "Select Template"){
      this.setState({lockDays : ['day1', 'day2', 'day3', 'day4', 'day5', 'day6', 'day7']})
    }
    // else{
    //   this.setState({lockDays : []})
    // }

    let isblanckcheck=false;
    let data = tableData;
    for (let i = 0; i < data.length; i++) {
      for (let j = 1; j <= 7; j++) {
        let day = `day${j}`;
        if (data[i][day] === "") {   
          isblanckcheck=true;      
          break;
      }
    }
  }
    if(isblanckcheck){
      this.setState({checkValidationOnSaveAsTemapleButon:true});
    }else{
      this.setState({checkValidationOnSaveAsTemapleButon:false});
    }



  };



  DeleteTemplete = () => {
    // if (this.state.changeValue) {
      // this.setState({changeTemplate : true})
      this.isValidateDelete();
    // } 
  //this.DeleteTempletSave("Template");
  };





  DeleteTempletSave = (type: string) => {
  // debugger;  
    let templateSaveData: any = [];
    let tempRowObject: any = {} as IForecastSaveDto;
    tempRowObject.Hid = this.state.hidValue;
    tempRowObject.date =this.state.tempSelectedDate;
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);   
    tempRowObject.TenantID = tenantID;
    tempRowObject.TemplateName = this.state.templateListDisplay;
    tempRowObject.saveType = type;    
    let loginUser = storage === null ? 0 : (storage.userName as any);
    tempRowObject.LoginUser = loginUser;
    templateSaveData.push(tempRowObject);


      LaborForecast.DeleteNewTemplete(templateSaveData)
        .then(async (result: any) => {
          if (result != null) {
            let resultData = result.result as any;
            if (resultData.saveStatus === "Success") {
              let successMessage = "Template deleted successfully";     
              this.setState({changeValue : false,templateListDisplay: "+ Create New Template",changeTemplate : false,loaderTrue: true});    
              this.createNewBlankTemplate();    
              
              //  //Template data loading
             let request = {} as IForecastImportTemplateRequestDto;
             request.hotelID = this.state.hidValue;
             this.getForcastImportTemplateData(request);

            

              toast.success(successMessage, {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "forecastPage",
              });      
              
            

          }
         
          resolve();
        }}).catch((err: any) => {         
          toast.success(`Server Error: ${err}`, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "forecastPage",
          });
          reject();
        });
      
  };

  isValidateDelete() {
    confirmAlert({
      title: "Delete Template",
      message:
        "Are you sure you want to delete the template ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.DeleteTempletSave("Template"),
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }

  isValidateChangeMode(event) {
    confirmAlert({
      title: "Unsaved confirmation",
      message:
        "You have unsaved changes on this page. Do you want to leave this page and discard your changes?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.onChangeMode(event,"Yes"),
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }

  isValidateTemplateChange(item) {
    confirmAlert({
      title: "Unsaved confirmation",
      message:
        "You have unsaved changes on this template. Do you want to leave this template and discard your changes?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.gblViewEditTemplete(item),
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }

  DiscardTemplate = () => {
    //this.setState({changeTemplate : false})
      this.isValidateDiscard();
  };

  isValidateDiscard() {
    confirmAlert({
      title: "Discard Changes",
      message:
        "Are you sure you want to discard the changes ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.handleTemplateDiscard(),
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }

  render() {   



    const rowClasses = (row: any, rowIndex: any) => {
      if (rowIndex === this.state.rowEditNo) {
        return "row-is-editing";
      } else {
        return "";
      }
    };

    const columns = [
      {
        dataField: "itemName",
        text: "",
        editable: false,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="item-name">
            <EllipsisWithTooltip placement="bottom">
              <span>{row.itemName}</span>
            </EllipsisWithTooltip>
          </div>
        ),
      },
      {
        dataField: "day1",
        text: "",
        headerFormatter: this.headerFormatter,
        editable: (content: any, row: any) => {
          let result =
            this.state.lockDays.includes("day1") ||
            row.typeid === stayOverID ||
            row.typeid === departureID;
          return !result;
        },
        classes: (cell: string, row: any, rowIndex: any, colIndex: any) => {
          if (this.state.lockDays.includes("day1")) {
            return "cells-with-lock";
          } else if (
            (row.typeid === stayOverID || row.typeid === departureID) &&
            cell !== ""
          ) {
            return "cells-with-box";
          } else {
            return "";
          }
        },
        formatter: this.inputformatter,
        formatExtraData: "1",
      },
      {
        dataField: "day2",
        text: "",
        headerFormatter: this.headerFormatter,
        editable: (content: any, row: any) => {
          let result =
            this.state.lockDays.includes("day2") ||
            row.typeid === stayOverID ||
            row.typeid === departureID;
          return !result;
        },
        classes: (cell: string, row: any, rowIndex: any, colIndex: any) => {
          if (this.state.lockDays.includes("day2")) {
            return "cells-with-lock";
          } else if (
            (row.typeid === stayOverID || row.typeid === departureID) &&
            cell !== ""
          ) {
            return "cells-with-box";
          } else {
            return "";
          }
        },
        formatter: this.inputformatter,
        formatExtraData: "2",
      },
      {
        dataField: "day3",
        text: "",
        editable: (content: any, row: any) => {
          let result =
            this.state.lockDays.includes("day3") ||
            row.typeid === stayOverID ||
            row.typeid === departureID;
          return !result;
        },
        classes: (cell: string, row: any, rowIndex: any, colIndex: any) => {
          if (this.state.lockDays.includes("day3")) {
            return "cells-with-lock";
          } else if (
            (row.typeid === stayOverID || row.typeid === departureID) &&
            cell !== ""
          ) {
            return "cells-with-box";
          } else {
            return "";
          }
        },
        headerFormatter: this.headerFormatter,
        formatter: this.inputformatter,
        formatExtraData: "3",
      },
      {
        dataField: "day4",
        text: "",
        headerFormatter: this.headerFormatter,
        editable: (content: any, row: any) => {
          let result =
            this.state.lockDays.includes("day4") ||
            row.typeid === stayOverID ||
            row.typeid === departureID;
          return !result;
        },
        classes: (cell: string, row: any, rowIndex: any, colIndex: any) => {
          if (this.state.lockDays.includes("day4")) {
            return "cells-with-lock";
          } else if (
            (row.typeid === stayOverID || row.typeid === departureID) &&
            cell !== ""
          ) {
            return "cells-with-box";
          } else {
            return "";
          }
        },
        formatter: this.inputformatter,
        formatExtraData: "4",
      },
      {
        dataField: "day5",
        text: "",
        headerFormatter: this.headerFormatter,
        editable: (content: any, row: any) => {
          let result =
            this.state.lockDays.includes("day5") ||
            row.typeid === stayOverID ||
            row.typeid === departureID;
          return !result;
        },
        classes: (cell: string, row: any, rowIndex: any, colIndex: any) => {
          if (this.state.lockDays.includes("day5")) {
            return "cells-with-lock";
          } else if (
            (row.typeid === stayOverID || row.typeid === departureID) &&
            cell !== ""
          ) {
            return "cells-with-box";
          } else {
            return "";
          }
        },
        formatter: this.inputformatter,
        formatExtraData: "5",
      },
      {
        dataField: "day6",
        text: "",
        headerFormatter: this.headerFormatter,
        editable: (content: any, row: any) => {
          let result =
            this.state.lockDays.includes("day6") ||
            row.typeid === stayOverID ||
            row.typeid === departureID;
          return !result;
        },
        classes: (cell: string, row: any, rowIndex: any, colIndex: any) => {
          if (this.state.lockDays.includes("day6")) {
            return "cells-with-lock";
          } else if (
            (row.typeid === stayOverID || row.typeid === departureID) &&
            cell !== ""
          ) {
            return "cells-with-box";
          } else {
            return "";
          }
        },
        formatter: this.inputformatter,
        formatExtraData: "6",
      },
      {
        dataField: "day7",
        text: "",
        headerFormatter: this.headerFormatter,
        editable: (content: any, row: any) => {
          let result =
            this.state.lockDays.includes("day7") ||
            row.typeid === stayOverID ||
            row.typeid === departureID;
          return !result;
        },
        classes: (cell: string, row: any, rowIndex: any, colIndex: any) => {
          if (this.state.lockDays.includes("day7")) {
            return "cells-with-lock";
          } else if (
            (row.typeid === stayOverID || row.typeid === departureID) &&
            cell !== ""
          ) {
            return "cells-with-box";
          } else {
            return "";
          }
        },
        formatter: this.inputformatter,
        formatExtraData: "7",
      },
    ];

    const columns2 = [
      {
        dataField: "itemName",
        text: "",
        editable: false,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="item-name">
            <EllipsisWithTooltip placement="bottom">
              <span>{row.itemName}</span>
            </EllipsisWithTooltip>
          </div>
        ),
      },
      {
        dataField: "day1",
        text: "",
        headerFormatter: this.headerFormatter2,
        editable: (content: any, row: any) => {
          let result =
            this.state.lockDays.includes("day1") ||
            row.typeid === stayOverID ||
            row.typeid === departureID;
          return !result;
        },
        classes: (cell: string, row: any, rowIndex: any, colIndex: any) => {
          if (this.state.lockDays.includes("day1")) {
            return "cells-with-lock";
          } else if (
            (row.typeid === stayOverID || row.typeid === departureID) &&
            cell !== ""
          ) {
            return "cells-with-box";
          } else {
            return "";
          }
        },
        formatter: this.inputformatter,
        formatExtraData: "1",
      },
      {
        dataField: "day2",
        text: "",
        headerFormatter: this.headerFormatter2,
        editable: (content: any, row: any) => {
          let result =
            this.state.lockDays.includes("day2") ||
            row.typeid === stayOverID ||
            row.typeid === departureID;
          return !result;
        },
        classes: (cell: string, row: any, rowIndex: any, colIndex: any) => {
          if (this.state.lockDays.includes("day2")) {
            return "cells-with-lock";
          } else if (
            (row.typeid === stayOverID || row.typeid === departureID) &&
            cell !== ""
          ) {
            return "cells-with-box";
          } else {
            return "";
          }
        },
        formatter: this.inputformatter,
        formatExtraData: "2",
      },
      {
        dataField: "day3",
        text: "",
        editable: (content: any, row: any) => {
          let result =
            this.state.lockDays.includes("day3") ||
            row.typeid === stayOverID ||
            row.typeid === departureID;
          return !result;
        },
        classes: (cell: string, row: any, rowIndex: any, colIndex: any) => {
          if (this.state.lockDays.includes("day3")) {
            return "cells-with-lock";
          } else if (
            (row.typeid === stayOverID || row.typeid === departureID) &&
            cell !== ""
          ) {
            return "cells-with-box";
          } else {
            return "";
          }
        },
        headerFormatter: this.headerFormatter2,
        formatter: this.inputformatter,
        formatExtraData: "3",
      },
      {
        dataField: "day4",
        text: "",
        headerFormatter: this.headerFormatter2,
        editable: (content: any, row: any) => {
          let result =
            this.state.lockDays.includes("day4") ||
            row.typeid === stayOverID ||
            row.typeid === departureID;
          return !result;
        },
        classes: (cell: string, row: any, rowIndex: any, colIndex: any) => {
          if (this.state.lockDays.includes("day4")) {
            return "cells-with-lock";
          } else if (
            (row.typeid === stayOverID || row.typeid === departureID) &&
            cell !== ""
          ) {
            return "cells-with-box";
          } else {
            return "";
          }
        },
        formatter: this.inputformatter,
        formatExtraData: "4",
      },
      {
        dataField: "day5",
        text: "",
        headerFormatter: this.headerFormatter2,
        editable: (content: any, row: any) => {
          let result =
            this.state.lockDays.includes("day5") ||
            row.typeid === stayOverID ||
            row.typeid === departureID;
          return !result;
        },
        classes: (cell: string, row: any, rowIndex: any, colIndex: any) => {
          if (this.state.lockDays.includes("day5")) {
            return "cells-with-lock";
          } else if (
            (row.typeid === stayOverID || row.typeid === departureID) &&
            cell !== ""
          ) {
            return "cells-with-box";
          } else {
            return "";
          }
        },
        formatter: this.inputformatter,
        formatExtraData: "5",
      },
      {
        dataField: "day6",
        text: "",
        headerFormatter: this.headerFormatter2,
        editable: (content: any, row: any) => {
          let result =
            this.state.lockDays.includes("day6") ||
            row.typeid === stayOverID ||
            row.typeid === departureID;
          return !result;
        },
        classes: (cell: string, row: any, rowIndex: any, colIndex: any) => {
          if (this.state.lockDays.includes("day6")) {
            return "cells-with-lock";
          } else if (
            (row.typeid === stayOverID || row.typeid === departureID) &&
            cell !== ""
          ) {
            return "cells-with-box";
          } else {
            return "";
          }
        },
        formatter: this.inputformatter,
        formatExtraData: "6",
      },
      {
        dataField: "day7",
        text: "",
        headerFormatter: this.headerFormatter2,
        editable: (content: any, row: any) => {
          let result =
            this.state.lockDays.includes("day7") ||
            row.typeid === stayOverID ||
            row.typeid === departureID;
          return !result;
        },
        classes: (cell: string, row: any, rowIndex: any, colIndex: any) => {
          if (this.state.lockDays.includes("day7")) {
            return "cells-with-lock";
          } else if (
            (row.typeid === stayOverID || row.typeid === departureID) &&
            cell !== ""
          ) {
            return "cells-with-box";
          } else {
            return "";
          }
        },
        formatter: this.inputformatter,
        formatExtraData: "7",
      },
    ];

    
    return (
      <>
        {this.renderRedirect()}
        <RouteLeavingGuard
          when={this.state.changeValue}
          routeLeavingGuardConfirm={this.routeLeavingGuardConfirm}
          shouldBlockNavigation={(location: { pathname: any }) => {
           // debugger;
            if (this.state.changeValue) {
              if (location.pathname) {
                return true;
              }
            }
            return false;
          }}
        />
        {/* <ToastContainer
          enableMultiContainer
          autoClose={3000}
          containerId={"forecastPage"}
        /> */}
        <div className="forecastModule">
          <Container fluid className="body-sec">
            <div className="page-heading underline d-flex">
              <HidSelector
                onClickEvent={this.selectHotelDropDown}
                ref={this.childHID}
                alertComponent={this.state.changeValue}
              />

              <div className="mr-auto">Forecast</div>
              {this.state.hidValue !== "Select" && this.state.permissionExportForecast && (
                <div className="mr-4">
                  <Button
                    onClick={this.saveTemplateModalShow}
                    // eventKey="saveTemplate"
                    className='btn-outline-primary'
                    id="forecast-template-btn"
                    //disabled={ this.state.importDataBanner}
                    disabled={this.state.importDataBanner === true ? this.state.importDataBanner : 
                       this.state.checkValidationOnSaveAsTemapleButon===true ? true 
                       : this.state.ModeCheck === "templateMode"
                        ? true:false }
                  >
                    Save Forecast as Template
                  </Button>
                </div>
              )}
              {this.state.hidValue !== "Select" &&
                (this.state.permissionManageForecast ||
                  this.state.permissionExportForecast) && (
                  <div className="header-popout">
                    <Dropdown className="more-action" alignRight>
                      <Dropdown.Toggle
                        className={`${!(
                          this.state.lockDays.length < 7 &&
                          this.state.permissionManageForecast ) ? 'btn-outline-primary btn btn-primary more btn-disabled' : 'btn-outline-primary btn btn-primary more'}`}
                        id="dropdown-more"
                        disabled={
                          !(
                            this.state.lockDays.length < 7 &&
                            this.state.permissionManageForecast 
                            // &&
                            // this.state.ModeCheck === "foreCastMode" // added to hide importforecast
                          ) || (this.state.ModeCheck === "templateMode")
                        }
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                          <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                          <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                        </svg>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {this.state.lockDays.length < 7 &&
                          this.state.permissionManageForecast && (
                            <Dropdown.Item
                              eventKey="importForecast"
                              onClick={this.importForecastModalShow}
                            >
                              Import Forecast
                            </Dropdown.Item>
                          )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}
            </div>
            {this.state.hidValue !== "Select" && (
              <>
                {this.state.loaderTrue ? (
                  <ReactPageLoader useas={"forecastMain"} />
                ) : (
                  <div className="main-Page forecast-page-UI">
                    <div className="navigation-menus frm-section" onChange={this.onChangeValue}>
                      <div className="calender-container ml-0 row align-items-center">
                        <div className="col-lg-4 date-selector-column pl-0">
                        <div className="d-flex align-items-center">
                          <Form.Check type="radio" custom id="dateSelector" name="radioButton" checked={this.state.ModeCheck === "foreCastMode" ? true : false}/>
                         <div  className={
                             this.state.ModeCheck !== "foreCastMode"
                                ? "calender-not-vesible"
                                : ""
                            }>
                          <div                            
                            className={
                              this.state.calenderShow
                                ? "calender-display open"
                                : "calender-display"
                            }
                            onClick={this.calenderShow}
                          >
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z" />
                              <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z" />
                            </svg>
                            <span className="prev-Date">
                              {this.state.calenderDates[0]}
                            </span>
                            <span className="seperator"> - </span>
                            <span className="next-Date">
                              {this.state.calenderDates[1]}
                            </span>
                          
                            <span className="chevron-down">
                              <FiChevronDown />
                            </span>
                          </div>
                          </div>
                          {this.state.calenderShow && (
                            <div className="calender">
                              <OutsideClickHandler
                                onOutsideClick={this.calenderHide}
                              >
                                <Calendar
                                  calendarType={"US"}
                                  onChange={this.calenderChange}
                                  value={this.state.calenderSelectDate}
                                  minDate={this.state.laborMgmtMinDate}
                                />
                              </OutsideClickHandler>
                            </div>
                          )}
                      </div>
                    </div>
                   
                    {/* DropDown for Template selection */}
                    <div className="col-lg-3 forecast-dropdown-UI">
                        <div>
                          <Form.Check type="radio" custom id="templateSelector" name="radioButton" checked={this.state.ModeCheck === "templateMode" ? true : false}/>
                            <Dropdown  className={`"import-from-dropdown" ${this.state.ModeCheck === "foreCastMode" ? "disabled" : ""}`} >
                              <Dropdown.Toggle id="dropdown-importFrom" disabled={this.state.ModeCheck === "foreCastMode" ? true : false}>
                                <EllipsisWithTooltip placement="bottom">{this.state.templateListDisplay}</EllipsisWithTooltip>
                              <span><svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><polyline points="6 9 12 15 18 9"></polyline></svg></span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>                             
                              {this.state.importFromListTemplate.map(
                                (item: any, idx: any) => (
                                  <Dropdown.Item
                                    key={idx}
                                    eventKey={item.id}
                                    onClick={() =>
                                      this.check(item)
                                    }
                                  >
                                    <EllipsisWithTooltip placement="bottom">
                                      {item.name}
                                    </EllipsisWithTooltip>
                                  </Dropdown.Item>
                                )
                              )}
                              </Dropdown.Menu>
                              </Dropdown>
                        
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* End */}
                        
                    {this.state.importDataBanner && this.state.ModeCheck === "foreCastMode" && (
                      <div className="notification-banners orange-bg">
                        <div className="mr-auto">
                          <span className="bold">
                            No forecast set for this week.
                          </span>{" "}
                          Get started by importing your forecast from a previous
                          week’s actuals or a template.
                        </div>
                        {this.state.permissionExportForecast && (
                          <button
                            type="button"
                            className="btn btn-primary btn-ghost"
                            onClick={this.importForecastModalShow}
                          >
                            Import Forecast
                          </button>
                        )}
                      </div>
                    )}
                    <div className={`forecast-table ${this.state.ModeCheck === "templateMode" && "template-mode-view"}`} id="forecast-table-data">
                      {/* {this.state.ModeCheck === "foreCastMode" ?  */}
                      {(this.state.templateListDisplay === "Select Template" && this.state.ModeCheck === "foreCastMode") || (this.state.templateListDisplay !== "Select Template" && this.state.ModeCheck === "templateMode") ? 
                      <BootstrapTable
                        id="tblForeCast"
                        keyField="id"
                        data={this.state.forecastData}
                        columns={columns}
                        rowClasses={rowClasses}
                        hover
                        wrapperClasses={
                          this.state.importDataBanner
                            ? "table-with-banner"
                            : "table-no-banner"
                        }
                      /> : <></> }
                       {/* : 
                      <BootstrapTable
                        id="tblForeCast"
                        keyField="id"
                        data={this.state.tempData}
                        columns={columns2}
                        rowClasses={rowClasses}
                        hover
                        wrapperClasses={
                          this.state.importDataBanner
                            ? "table-with-banner"
                            : "table-no-banner"
                        }
                      /> */}
                    {/* }  */}
                    </div>
                    {this.state.changeValue &&
                      this.state.permissionManageForecast && ((this.state.templateListDisplay === "Select Template" && this.state.ModeCheck === "foreCastMode") || (this.state.templateListDisplay !== "Select Template" && this.state.ModeCheck === "templateMode")) && (
                        <div className="fixed-action relative">
                          <div className="d-flex align-content-center flex-wrap forecast-action-strip">
                           
                            <div className="mr-auto message">
                            {this.state.ModeCheck === "foreCastMode" ? 
                            <span>You have unsaved changes</span> : <></>
                            }
                              
                            </div>
                            {this.state.ModeCheck === "templateMode" && this.state.createNewTemplate === false ? 
                            <button
                            type="button"
                            className="btn btn-primary btn-discard delete-btn"
                            onClick={this.DeleteTemplete}
                          >
                            Delete Template
                          </button> 
                          : <></>}
                          
                            {this.state.ModeCheck === "foreCastMode" ?
                             <button
                             type="button"
                             className="btn btn-primary btn-discard btn-changes-discard"
                             onClick={this.handleDiscard}
                           >
                             Discard Changes
                           </button> 
                           :
                            <button
                            type="button"
                            className="btn btn-primary btn-discard btn-changes-discard"
                            onClick={this.DiscardTemplate}
                            disabled={this.state.changeTemplate === true ? false : true}
                          >
                            Discard Changes
                          </button>
                            }
                           

                          {this.state.ModeCheck === "foreCastMode" || (this.state.ModeCheck === "templateMode" && this.state.createNewTemplate === false)
                          ? 
                          <button
                          type="button"
                          className={
                            `save-btn ${this.state.saveForecastButtonSpinner ? "btn btn-primary p-none" : "btn btn-primary"}`
                          }
                          onClick={() => this.handleSave(this.state.ModeCheck === "foreCastMode" ? "Forecast" : "Template")}
                          disabled={this.state.ModeCheck === "foreCastMode" || this.state.changeTemplate === true ? false : true}
                        >
                          {this.state.saveForecastButtonSpinner && (
                            <Spinner
                              className="mr-2"
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                          {/* Save Changes */}
                          Save
                        </button> : <></>}
                           

                            {this.state.ModeCheck === "templateMode" ? 
                          <button
                          type="button"
                          className={
                            `save-btn ${this.state.saveAsNewTemplateButtonSpinner ? "btn btn-primary p-none" : "btn btn-primary"}`
                          }
                          onClick={() => this.saveTemplateModalShow()}
                          disabled={this.state.changeTemplate === true && this.state.checkValidationOnSaveAsTemapleButon===false? false : true}
                        >
                          {this.state.saveAsNewTemplateButtonSpinner && (
                            <Spinner
                              className="mr-2"
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                          {this.state.createNewTemplate === false ? "Save As New" : "Save Template"}
                        </button>
                           : <></>}
                           
                          </div>
                        </div>
                      )}
                  </div>
                )}
              </>
            )}

            {/*=========================== Import Forcast model =================================*/}
            <Modal
            className="import-forecast-modal importForecastModalUI"
            show={this.state.importForecastModal}
            onHide={this.importForecastModalClose}
            aria-labelledby="contained-modal-title-vcenter"
            animation={true}
            centered
            backdrop="static"
            keyboard={false}
          >
            <OutsideClickHandler
              onOutsideClick={this.importForecastModalClose}
            >
              <Modal.Header closeButton>
                <Modal.Title>Import Forecast</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group controlId="copyFrom">
                  <Form.Label>Import From</Form.Label>
                  {this.state.importForecastModal && (
                    <Dropdown className="import-from-dropdown">
                      <Dropdown.Toggle id="dropdown-importFrom">
                        {this.state.importFromListSelectDisplay}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                      {this.state.importFromListActual.length !== 0 && (
                          <div>
                            <Dropdown.Header>ACTUALS</Dropdown.Header>
                            {this.state.importFromListActual.map(
                              (item: any, idx: any) => (
                                <Dropdown.Item
                                  key={idx}
                                  eventKey={item.id}
                                  onClick={() =>
                                    this.importFromListSelect(item)
                                  }
                                >
                                  {item.name}
                                </Dropdown.Item>
                              )
                            )}
                          </div>
                        )}
                        {this.state.importFromListTemplate.length !== 0 && (
                          <div className="templates-body">
                            <Dropdown.Divider />
                            <Dropdown.Header>TEMPLATES</Dropdown.Header>
                            {/* {this.state.ModeCheck === "foreCastMode" ?  */}
                             {this.state.importFromListTemplate.map(
                              (item: any, idx: any) => (
                                item.id !== -1 ? 
                                <Dropdown.Item
                                  key={idx}
                                  eventKey={item.id}
                                  onClick={() =>
                                    this.importFromListSelect(item)
                                  }
                                >

                                <EllipsisWithTooltip placement="bottom">
                                    <span>{item.name}</span> 
                                  </EllipsisWithTooltip>
                                  <button onClick={(e: any) => this.gblViewEditTemplete(item)}>View/Edit</button>
                                  {/* <EllipsisWithTooltip placement="bottom">
                                    <span>{item.name}</span> 
                                  </EllipsisWithTooltip>                              */}
                                </Dropdown.Item>
                                 : 
                               
                                 <div className="new-template-btn">
                                     <Button onClick={() => this.handleCreateTemplate(item)}>{item.name}</Button>
                                 </div>
                                
                              )
                            ) }
                            {/* :
                            this.state.gblimportFromListTemplate.map(
                              (item: any, idx: any) => (
                                item.id !== -1 ? 
                                <Dropdown.Item
                                  key={idx}
                                  eventKey={item.id}
                                  onClick={() =>
                                    this.importFromListSelect(item)
                                  }
                                > 
                                <EllipsisWithTooltip placement="bottom">
                                    <span>{item.name}</span> 
                                  </EllipsisWithTooltip>
                                  <button onClick={(e: any) => this.gblViewEditTemplete(item)}>View/Edit</button>
                                  
                                </Dropdown.Item> 
                                : 
                               
                        <div className="new-template-btn">
                            <Button onClick={() => this.handleCreateTemplate(item)}>{item.name}</Button>
                        </div>
                                // <Dropdown.Item
                                //   key={idx}
                                //   eventKey={item.id}
                                //   onClick={() =>
                                //     this.handleCreateTemplate(item)
                                //   }
                                // >
                                //     <EllipsisWithTooltip placement="bottom">
                                //     <span>{item.name}</span> 
                                //   </EllipsisWithTooltip>                       
                                // </Dropdown.Item>
                                  
                              )
                            ) */}
                            {/* } */}
                           
                          </div>
                        )}
                         {/* {this.state.ModeCheck === "templateMode" ? 
                        <div className="new-template-btn">
                            <Button onClick={this.handleCreateTemplate}>+ Create New Template</Button>
                        </div>
                        : <></>} */}
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="import-forecast-button"
                  onClick={(e: any) => this.importForecast(e)}
                >
                  Import Forecast
                </Button>
              </Modal.Footer>
            </OutsideClickHandler>
          </Modal> 
        
            {/*=========================== Save as Template model =================================*/}
            <Modal
              className="save-template-modal"
              show={this.state.saveTemplateModal}
              onHide={this.saveTemplateModalClose}
              aria-labelledby="contained-modal-title-vcenter"
              animation={true}
              centered
              backdrop="static"
              keyboard={false}
            >
              <OutsideClickHandler onOutsideClick={this.saveTemplateModalClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Save as Template</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form.Group controlId="template-name">
                    <Form.Label>Template Name</Form.Label>
                    <div className="search-header mr-auto auto-complete">
                      <Autocomplete
                        getItemValue={this.getItemValue}
                        shouldItemRender={(
                          item: { templateName: string },
                          value: string
                        ) =>
                          item.templateName
                            .toLowerCase()
                            .indexOf(value.toLowerCase()) > -1
                        }
                        items={this.state.templateData}
                        renderItem={this.renderItem}
                        value={this.state.templateName}
                        onSelect={this.onAutocompleteSelect}
                        onChange={this.onAutocompleteChange}
                        inputProps={{
                          placeholder: "Enter new or existing template name",
                          maxlength: 50 //added for maxlength of charater
                        }}
                      />
                    </div>
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    className={
                      this.state.saveTemplateButtonSpinner
                        ? "saveTemplate-button p-none"
                        : this.state.templateSaveConfirmation
                        ? "saveTemplateAlertButton"
                        : "saveTemplate-button"
                    }
                    onClick={() => this.handleSaveTemplate("Template")}
                  >
                    {this.state.saveTemplateButtonSpinner && (
                      <Spinner
                        className="mr-2"
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    Save Template
                  </Button>
                </Modal.Footer>
                {this.state.templateSaveConfirmation && (
                  <ConfirmationModalNonTable
                    title={"Save as Template"}
                    description={`${this.state.templateName} already exists, continuing to save will overwrite the existing values. Are you sure you want to continue?`}
                    cancelButtonName={"No"}
                    actionButtonName={"Yes"}
                    warningCloseModel={this.warningCloseModel.bind(this)}
                    saveCopyBudgets={this.saveCopyBudgets.bind(this)}
                  />
                )}
                
              </OutsideClickHandler>
            </Modal>
          </Container>
        </div>
      </>
    );
  }
}
