import * as React from "react";
import { Profiler } from "react";
import "@boldreports/javascript-reporting-controls/Scripts/bold.report-viewer.min";
import "@boldreports/javascript-reporting-controls/Content/material/bold.reports.all.min.css";
import "@boldreports/react-reporting-components/Scripts/bold.reports.react.min";
import { IReportParameter } from "../../Common/Contracts/IReportParameter";
import { API_ROOT } from "../../Common/Services/Api-config";
import ReactContentLoaderListStyle from "react-content-loader/dist/web/presets/ListStyle";
import { argv0 } from "process";
import moment from "moment";
import { ModuleReports } from "../../Common/Services/ModuleReports";

declare let ej: any;
declare let BoldReportViewerComponent: any;

export const ReportViewer: React.FC<any> = (props: any) => {
  const [reportParameters, setReportParameters] = React.useState<IReportParameter[]>([]);

  let parameterSettings = {
    hideParameterBlock: true,
  };



  function onReportPrint(event) {
    event.isStyleLoad = false;

  }

  let exportSettings = {
    exportOptions:
      ej.ReportViewer.ExportOptions.All &
      ~ej.ReportViewer.ExportOptions.CSV &
      ~ej.ReportViewer.ExportOptions.Word &
      ~ej.ReportViewer.ExportOptions.XML &
      ~ej.ReportViewer.ExportOptions.Html &
      ~ej.ReportViewer.ExportOptions.PPT
  };

  function onExportProgressChanged(event) {
    if (event.stage === "preparation") {
      // console.log(event.stage);
      localStorage.getItem(event.format);

      console.log(event.format);
      // console.log(event.preparationStage);
    }
    event.handled = true;
  }



  function onDrillThrough(event) {
    // debugger;
    console.log(event);


    let asa = event.model.reportPath;
    let myObject = JSON.parse(asa);
    const arr1 = event.actionInfo.Parameters;
    const arr2 = myObject
    const arr3 = [...arr1, ...arr2];
    console.log(arr3);
    event.actionInfo.ReportName = event.actionInfo.ReportName;
    event.actionInfo.Parameters = arr3.filter((a, i) => arr3.findIndex((s) => a.value === s.value) === i);


    // let mainArr: any = [];
    // let reportParam = [] as any;
    // event.actionInfo.Parameters.forEach(element => {
    //   let objarr: any = {};
    //   objarr.name = element.Name;
    //   objarr.value = element.Value !== null ? element.Value[0] : null;
    //   mainArr.push(objarr)
    // });
    // let jsonString = JSON.stringify(mainArr);
    // event.actionInfo.Parameters =jsonString;

    // event.actionInfo.Parameters =JSON.stringify(mainArr);


  }

  // const ReportRenderTime = async (id, phase, actualDuration, baseDuration, startTime, commitTime) => {

  //   console.table({id, phase, actualDuration, baseDuration, startTime, commitTime})
  //   // debugger;
  //   const timestamp = Date.now(); // This would be the timestamp you want to format
  //   const rundatetime=[new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(timestamp)];
  //   let  runReportDateTime=rundatetime;
  //   const reportName=id;
  //   const reportRenderInMS=actualDuration;

  //  // convertMS(actualDuration);
  //   const reportRenderMinutSecond= millisToMinutesAndSeconds(actualDuration);
  //   const reportParam=JSON.stringify(reportParameters);
  //   let type="reportRender"
  //   //  const result: any[] = await ModuleReports.InsertRunReportDetails(reportName,runReportDateTime.toString(),phase,reportRenderInMS,reportRenderMinutSecond,reportParam,type);
  //   // // debugger;
  //   // if (result?.length > 0) {
  //   // }

  // } 

  // function millisToMinutesAndSeconds(millis) {
  //  // debugger;
  //   let minutes = Math.floor(millis / 60000);
  //   let seconds = ((millis % 60000) / 1000).toFixed(Number(0));
  //   const parts = [
  //     minutes, (Number(seconds) < 10 ? '0' : '') + seconds
  //   ];
  //   return  parts.map(s => String(s).padStart(2, '0')).join(':');
  //   //console.table(formatted);

  // }

  // function convertMS( milliseconds ) {
  //   let day, hour, minute, seconds, miliSecnds;


  //   seconds = Math.floor(Number(milliseconds) / 1000);
  //   miliSecnds = (Number(milliseconds) % 1000)
  //   minute = Math.floor(Number(seconds) / 60);
  //   seconds = Number(seconds) % 60;
  //   hour = Math.floor(Number(minute) / 60);
  //   minute = Number(minute) % 60;
  //   day = Math.floor(Number(hour) / 24);
  //   hour = Number(hour) % 24;
  //   const parts= [
  //        hour,minute,seconds
  //   ];

  // const formatted = parts.map(s => String(s).padStart(2,'0')).join(':');
  // alert(parts);
  // alert(formatted); 
  // }



  function onexportItemClick(event) {
    if (event.stage === "preparation") {
      localStorage.getItem(event.format);
    }
    event.handled = true;
  }
  let toolbarSettings = {
    showToolbar: true,
    items:
      ~ej.ReportViewer.ToolbarItems.FittoPage &
      ~ej.ReportViewer.ToolbarItems.Parameters &
      ~ej.ReportViewer.ToolbarItems.Refresh &
      ~ej.ReportViewer.ToolbarItems.PageSetup
  };
  const [showComponent, setShowComponent] = React.useState(false);

  React.useEffect(() => {
    if (!reportParameters || reportParameters.length === 0)
      setReportParameters(
        JSON.parse(localStorage.getItem("reportParameters")!)
      );

    return () => {
    };
  }, [reportParameters]);

  const onAjaxSuccess = (event) => {
    //debugger;
    if (event?.cancel) {
      props?.onReportload();
      return;
    }
    if (event?.inProgress === "completed" && !event?.hasOwnProperty('dataSets')) {
      if (typeof props?.onReportload === 'function') {
        props?.onReportload();
        props.logReportLoadTiming('onload', reportParameters)
      }
    }
  }

  const onAjaxFailed = (event) => {
    debugger
    if (typeof props?.onReportload === 'function') {
      props?.onReportload();
    }
  }

  function ontoolBarItemClick(args) {
    debugger
    let requrl = `${API_ROOT.reportingHost}/reportviewer/PostFormReportAction`;
    $.ajax({
      type: "POST",
      contentType: "application/json; charset=utf-8",
      url: requrl,
      //data: JSON.stringify(_json),
      dataType: "json",
      crossDomain: true
    })
  }


  if (reportParameters && reportParameters.length > 0) {
    return (
      <div id="reportviewer">
        <BoldReportViewerComponent
          id="reportviewer-container"
          reportServiceUrl={
            `${API_ROOT.reportingHost}/reportviewer`
          }
          reportPath={JSON.stringify(reportParameters)}
          isResponsive={true}
          parameterSettings={parameterSettings}
          reportPrint={onReportPrint}
          exportSettings={exportSettings}
          toolbarSettings={toolbarSettings}
          exportProgressChanged={onExportProgressChanged}
          exportItemClick={onexportItemClick}
          ajaxSuccess={onAjaxSuccess}
          ajaxError={onAjaxFailed}
          reportError={onAjaxFailed}
          toolBarItemClick={ontoolBarItemClick}
          drillThrough={onDrillThrough}
        ></BoldReportViewerComponent>
      </div>
    );
  } else {
    return <div id="reportviewer"></div>;
  }
};
