import React from "react";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  ICurrentFilter,
  ILogHistoryProps,
  ILogHistoryState,
} from "../../../../Common/Contracts/IScheduleLogHistory";
import {
  IApprovalChangeLogRequest,
  IApprovalChangeLogResponse,
} from "../../../../Common/Contracts/IApprovalChangeLog";
import { LaborPerformance } from "../../../../Common/Services/LaborPerfomance";
import { ChangeLogs } from "../../../../Common/Components/ChangeLogs/ChangeLogs";
import { Utils } from "../../../../Common/Utilis";

export class ApprovalLogHistory extends React.Component<
  ILogHistoryProps,
  ILogHistoryState
> {

  private approvalStatusData:any[] = [{label:"All", value:"All"}, {label: "Approve",value:"Approve"},
  {label: "Unapprove",value:"Unapprove"}];
  private pageName: string = "UserLogHistory";
  state = {
    tableData: [],
    filteredData: [],
    tableColumns: [],
    title: "Approval History",
    keyFieldName: "rowNumber",
    isDataLoading: false,
    scheduleId: 0,
    isDrillDownView: false,
    filterColumns: [],
    filterPopUps: [],
    filterPopUpsCopy: [],
    showFilterPopUp: false,
    popClassName: "",
    currentFilter: {
      filterName: "",
      filterValue: "",
      filterKeyName: "",
      filterInputType: "",
      dropDownProps: {}
    },
    tableClassName: "schedule-log-table",
    startDate: "",
    endDate: "",
    pageSize: 20,
    currentPageNo: 1,
    totalDataLength: 0,
    drillDownShiftDate: "",
    reactTableClassName: "react-approval-log-table",
  };

  hoverOutEllipsis = () => {
    $(".tooltip")?.removeClass("show");
    $(".tooltip")?.removeClass("fade");
  };

  cellTooltipFormatter = (cell: any, row: any, rowIndex: any) => {
    const text = cell?.toString();
    let substr = "";
    if (text?.length > 15) {
      substr = `${text.substr(0, 15)}...`;
    }

    return (
      <>
        {" "}
        {text?.length >15 ? (
          <div onWheel={() => this.hoverOutEllipsis()}>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip id={"PositionName"}>{cell}</Tooltip>}
            >
              <span>{substr}</span>
            </OverlayTrigger>
          </div>
        ) : (
          <div>{cell}</div>
        )}
      </>
    );
  };

  cellTooltipPositionFormatter = (cell: any, row: any, rowIndex: any) => {
    const text = cell?.toString();
    let substr = "";
    if (text?.length > 11) {
      substr = `${text.substr(0, 11)}...`;
    }
    return (
      <>
        {" "}
        {text?.length > 11 ? (
          <div onWheel={() => this.hoverOutEllipsis()}>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip id={"PositionName"}>{cell}</Tooltip>}
            >
              <span>{substr}</span>
            </OverlayTrigger>
          </div>
        ) : (
          <div>{cell}</div>
        )}
      </>
    );
  };

  tableColumns = [
    {
      dataField: "rowNumber",
      text: "",
      hidden: true,
    },

    {
      dataField: "deptName",
      text: "Department Name",
      filterInputType: "text",
    
      headerStyle: () => {
        return { width: "25%" };
      },
      formatter: this.cellTooltipFormatter,
    },

    {
      dataField: "dateApproved",
      text: "Date Approved",
      filterInputType: "date",
      headerStyle: () => {
        return { width: "25%" };
      },
      //formatter: this.cellTooltipFormatter,
    },

    {
      dataField: "editedOn",
      text: "Edited On",
      filterInputType: "date",
      headerStyle: () => {
        return { width: "25%" };
      },
     // formatter: this.cellTooltipFormatter,
    },
    {
      dataField: "editedBy",
      text: "Edited By",
      filterInputType: "text",
      headerStyle: () => {
        return { width: "25%" };
      },
      formatter: this.cellTooltipFormatter,
    },

    {
      dataField: "fieldName",
      text: "Field Name",
      filterInputType: "",
      headerStyle: () => {
        return { width: "25%" };
      },
      formatter: this.cellTooltipFormatter,
    },
   

    {
      dataField: "oldValue",
      text: "Old Value",
      filterInputType:  "ddl",

      headerStyle: () => {
        return { width: "25%" };
      },
      formatter: this.cellTooltipFormatter,
      dropDownProps: {
        ddlData: this.approvalStatusData,
        dropDownItemLabelFieldName: "label",
        dropDownItemValueFieldName: "value",
        dropDownSelectedItem: {},
      },
    },
    {
      dataField: "newValue",
      text: "New Value",
      filterInputType:  "ddl",

      headerStyle: () => {
        return { width: "25%" };
      },
      formatter: this.cellTooltipFormatter,
      dropDownProps: {
        ddlData: this.approvalStatusData,
        dropDownItemLabelFieldName: "label",
        dropDownItemValueFieldName: "value",
        dropDownSelectedItem: {},
      },
    },

    
    // {
    //   dataField: "triggerName",
    //   text: "Trigger",
    //   filterInputType: "text",
    //   headerStyle: () => {
    //     return { width: "25%" };
    //   },
    //   formatter: this.cellTooltipFormatter,
    // },
  ];

  componentDidMount = () => {
    this.buildDefaultData();
  };

  buildDefaultData = () => {
    let filterColumns = this.tableColumns
      .filter((item) => item.filterInputType)
      .map((item) => ({
        text: item.text,
        key: item.dataField,
        isFilterApplied: false,
        filterInputType: item?.filterInputType,
        dropDownProps: item?.dropDownProps,
      }));

    const filterPopUps: any = [];
    const {payPeriodStartDate, payPeriodEndDate} = this.props;
    if(payPeriodStartDate && payPeriodEndDate) {
      const filter: ICurrentFilter = {
        filterName: "Date Approved",
        filterValue: `${payPeriodStartDate} - ${payPeriodEndDate}`,
        filterKeyName: "dateApproved",
        filterInputType: "date",
        popClassName: "filter-edit-popup-0",
      }
      filterPopUps.push(filter);
      filterColumns = filterColumns.map(item => {
        if(item.key  === "dateApproved") {
          item.isFilterApplied =true;
        }
       return item;
      });

    } 
    this.setState(
      {
        filterPopUps,
        filterColumns,
        tableColumns: this.tableColumns,
      },
      () => {
        this.getChangeLogData(true);
      }
    );
  };

  validateDates = () => {
    const currentFilter: any = { ...this.state.currentFilter };
    if (currentFilter.filterKeyName === "editDateTime") {
      const { startDate, endDate } = this.state;

      if (!endDate) {
        Utils.toastError("Please enter End Date.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        return false;
      } else if (!startDate) {
        Utils.toastError("Please enter Start Date.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  updateFilters = () => {
    const currentFilter: any = { ...this.state.currentFilter };
    let filterPopUps: any[] = [...this.state.filterPopUps];
    const filterIndex = filterPopUps.findIndex(
      (filter) => filter?.filterName === currentFilter?.filterName
    );
    if (
      currentFilter.filterKeyName === "editDateTime" &&
      !currentFilter.filterValue
    ) {
      //  currentFilter.filterValue = `${this.props.payPeriodStartDate} - ${this.props.payPeriodEndDate}`;
    }
    if (filterIndex < 0) {
      filterPopUps.push(currentFilter);
    } else {
      filterPopUps[filterIndex] = currentFilter;
    }
    filterPopUps = filterPopUps?.filter((item) => item?.filterValue);
    filterPopUps = filterPopUps.map((item, index) => {
      item.popClassName = "filter-edit-popup-" + index;
      return item;
    });
    this.setState({ filterPopUps, currentPageNo: 1 }, () => {
      this.getChangeLogData();
      this.updateFilterColumns();
    });
  };

  updateFilterColumns = () => {
    const { filterColumns: columns, filterPopUps } = this.state;
    const filteredKeys = filterPopUps?.map(
      (filter: ICurrentFilter) => filter?.filterKeyName
    );
    const filterColumns = columns.map((item: any) => {
      if (filteredKeys.find((key) => key === item?.key)) {
        item.isFilterApplied = true;
      } else {
        item.isFilterApplied = false;
      }
      return item;
    });
    this.setState({ filterColumns });
  };

  onFilterTextChange = (event: any) => {
    const currentFilter: any = { ...this.state.currentFilter };
    currentFilter.filterValue = event.currentTarget?.value;
    this.setState({ currentFilter });
  };
  onFilterChange = (filterItem: any, popClassName): void => {
    if(filterItem?.dropDownProps) {
      filterItem.dropDownProps.dropDownSelectedItem={}
    }
    const filterPopUps: any[] = [...this.state.filterPopUps];
    let currentFilter: ICurrentFilter = this.state.currentFilter;

    if (filterPopUps.length >= 5) {
      Utils.toastError("You can't apply more than 5 filters!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
    if (
      filterPopUps.length >= 5 ||
      filterPopUps.filter((filter) => filter?.filterName === filterItem.text)
        ?.length > 0
    ) {
      return;
    }
    currentFilter = {
      filterName: filterItem?.text,
      filterValue: "",
      filterKeyName: filterItem.key,
      filterInputType: filterItem?.filterInputType,
      popClassName: popClassName,
      dropDownProps: filterItem?.dropDownProps,
    };
    this.setState({
      currentFilter,
      currentPageNo: 1,
      showFilterPopUp: true,
      popClassName,
      startDate: "",
      endDate: "",
    });
  };

  onFilterDropDownChange = (item: any, valueFieldName) => {
    const currentFilter: ICurrentFilter = { ...this.state.currentFilter };
    currentFilter.filterValue = item[valueFieldName];
    if (!currentFilter.dropDownProps) {
      currentFilter.dropDownProps = {};
    }
    currentFilter.dropDownProps.dropDownSelectedItem = item;
    this.setState({ currentFilter });
  };

  removeFilter = (filterName: string): void => {
    let filterPopUps: any[] = [...this.state.filterPopUps];
    if (filterPopUps.length === 0) {
      return;
    }
    filterPopUps = filterPopUps.filter(
      (filter) => filter?.filterName !== filterName
    );
    if (filterPopUps.length > 0) {
      filterPopUps = filterPopUps.map((item, index) => {
        item.popClassName = "filter-edit-popup-" + index;
        return item;
      });
    }
    if (+this.state.scheduleId > 0) {
      this.setState({ tableClassName: "schedule-log-table" });
      this.returnBackFromDrillDown();
    } else {
      this.setState({ filterPopUps, currentPageNo: 1 }, () => {
        this.getChangeLogData();
        this.updateFilterColumns();
      });
    }
  };

  hideFilterPopUp = () => {
    if (this.state.showFilterPopUp) {
      this.setState({
        showFilterPopUp: false,
        popClassName: "",
      });
    }
  };

  editFilter = (currentFilter: ICurrentFilter, popClassName) => {
    if (currentFilter.filterInputType === "date") {
      const datesArray = currentFilter.filterValue?.split("-");
      this.setState({
        currentFilter,
        popClassName,
        showFilterPopUp: true,
        startDate: datesArray[0]?.trim(),
        endDate: datesArray[1]?.trim(),
      });
    } else {
      this.setState({ currentFilter, popClassName, showFilterPopUp: true });
    }
  };

  returnBackFromDrillDown = () => {
    this.setState(
      {
        scheduleId: 0,
        currentPageNo: 1,
        isDrillDownView: false,
        filterPopUps: this.state.filterPopUpsCopy,
      },
      () => {
        this.getChangeLogData();
        this.updateFilterColumns();
      }
    );
  };

  onPaginationchange = () => {
    const currentPageNo = this.state.currentPageNo + 1;
    this.setState({ currentPageNo }, () => {
      this.getChangeLogData(false, true);
    });
  };

  getChangeLogData = (isDefaultLoad = false, isPagination = false) => {
    if (!isPagination) {
      this.setState({
        tableData: [],
        filteredData: [],
      });
    }

    const { filterPopUps, pageSize, currentPageNo } = this.state;

    const request: IApprovalChangeLogRequest = {
      fromDate: "",
      toDate: "",
      approvalFromDate: "",
      approvalToDate: "",
      hid: this.props.hotelId,
      tenantId: 0,
      editor: "",
      fieldName: "",
      oldValue: "",
      newValue: "",
      triggerName: "",
      pageNumber: currentPageNo,
      pageSize: pageSize,
    } as IApprovalChangeLogRequest;

    filterPopUps.forEach((item: ICurrentFilter) => {
      if (item.filterKeyName === "editedOn") {
        const datesArray = item.filterValue
          ?.split("-")
          .map((item) => item?.trim());
        request.fromDate = datesArray[0];
        request.toDate = datesArray[1];
      } else if (item.filterKeyName === "dateApproved") {
        const datesArray = item.filterValue
          ?.split("-")
          .map((item) => item?.trim());
        request.approvalFromDate = datesArray[0];
        request.approvalToDate = datesArray[1];
      } else if (item.filterKeyName === "deptName") {
        request.deptName = item.filterValue;
      } else if (item.filterKeyName === "editedBy") {
        request.editor = item.filterValue;
      } else if (item.filterKeyName === "fieldName") {
        request.fieldName = item.filterValue;
      } else if (item.filterKeyName === "oldValue") {
        request.oldValue = item.filterValue;
      } else if (item.filterKeyName === "newValue") {
        request.newValue = item.filterValue;
      } else if (item.filterKeyName === "triggerName") {
        request.triggerName = item.filterValue;
      }
    });
    this.toggleDataLoading();
    LaborPerformance.GetApprovalChangeLogData(request)
      .then((tableData: IApprovalChangeLogResponse[] | null) => {
        tableData = tableData?.map((item) => {
          item.dateApproved = moment(item.dateApproved).format(
            "MM/DD/YYYY"
          );
          item.editedOn = item.editedOn? moment(item.editedOn).format("MM/DD/YYYY hh:mm A") : "";

          return item;
        }) as IApprovalChangeLogResponse[] | null;
        if (isPagination) {
          tableData = [
            ...this.state.tableData,
            ...(tableData as IApprovalChangeLogResponse[]),
          ];
        }

        const totalDataLength = tableData?.length
          ? tableData[0]?.totalCount
          : 0;
        this.setState({
          tableData: tableData,
          filteredData: tableData,
          totalDataLength,
        });
      })
      .catch((error) => {
        Utils.toastError(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      })
      .finally(() => {
        setTimeout(() => this.toggleDataLoading());
      });
  };

  getFilteredData = () => {
    this.onFiltersChange();
  };

  downloadChangeLogData = () => {
    const { filterPopUps } = this.state;

    const request: IApprovalChangeLogRequest = {
      fromDate: "",
      toDate: "",
      approvalFromDate: "",
      approvalToDate: "",
      hid: this.props.hotelId,
      tenantId: 0,
      editor: "",
      fieldName: "",
      oldValue: "",
      newValue: "",
      triggerName: "",
    } as IApprovalChangeLogRequest;

    filterPopUps.forEach((item: ICurrentFilter) => {
      if (item.filterKeyName === "editedOn") {
        const datesArray = item.filterValue
          ?.split("-")
          .map((item) => item?.trim());
        request.fromDate = datesArray[0];
        request.toDate = datesArray[1];
      } else if (item.filterKeyName === "dateApproved") {
        const datesArray = item.filterValue
          ?.split("-")
          .map((item) => item?.trim());
        request.approvalFromDate = datesArray[0];
        request.approvalToDate = datesArray[1];
      } else if (item.filterKeyName === "deptName") {
        request.deptName = item.filterValue;
      } else if (item.filterKeyName === "editedBy") {
        request.editor = item.filterValue;
      } else if (item.filterKeyName === "fieldName") {
        request.fieldName = item.filterValue;
      } else if (item.filterKeyName === "oldValue") {
        request.oldValue = item.filterValue;
      } else if (item.filterKeyName === "newValue") {
        request.newValue = item.filterValue;
      } else if (item.filterKeyName === "triggerName") {
        request.triggerName = item.filterValue;
      }
    });

    LaborPerformance.DownloadChangeLog(
      request,
      `ApprovalChangeLogs_${moment().format("DD/MM/YY")}`
    )
      .then(() => {})
      .catch((error) => {});
  };
  toggleDataLoading = () => {
    this.setState({ isDataLoading: !this.state.isDataLoading });
  };

  handleDatesChange = (date, type) => {
    const { startDate, endDate } = this.state;
    const currentFilter: any = { ...this.state.currentFilter };
    if (type === "StartDate") {
      if (new Date(date) > new Date(endDate)) {
        this.setState({ startDate: "" });
        Utils.toastError(
          "Please enter the Start Date less than or equal to End Date.",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );

        return false;
      }

      currentFilter.filterValue = `${date} - ${endDate}`;
      this.setState({ startDate: date });
    }

    if (type === "EndDate") {
      if (new Date(startDate) > new Date(date)) {
        this.setState({ endDate: "" });
        Utils.toastError(
          "Please enter the End Date greater than or equal to Start Date.",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );

        return false;
      }

      currentFilter.filterValue = `${startDate} - ${date}`;
      this.setState({ endDate: date });
    }

    this.setState({ currentFilter });
  };

  onFiltersChange = () => {
    const currentFilter = this.state.currentFilter;
    this.setState({ currentPageNo: 1 }, () => {
      this.getChangeLogData();
    });

    if (currentFilter?.filterKeyName) {
      this.setState({
        currentFilter: {
          filterName: "",
          filterValue: "",
          filterKeyName: "",
          popClassName: "",
          filterInputType: "",
        },
      });
    }
  };

  clearFilters = () => {
    if (+this.state.scheduleId > 0) {
      this.setState({ tableClassName: "schedule-log-table" });
      this.returnBackFromDrillDown();
    } else {
      this.setState({ filterPopUps: [], currentPageNo: 1 }, () => {
        this.getChangeLogData();
        this.updateFilterColumns();
      });
    }
  };

  render() {
    const { tableColumns } = this.state;
    const functions = {
      downloadChangeLogData: this.downloadChangeLogData,
      updateFilters: this.updateFilters,
      editFilter: this.editFilter,
      onFilterTextChange: this.onFilterTextChange,
      onFilterChange: this.onFilterChange,
      removeFilter: this.removeFilter,
      hideFilterPopUp: this.hideFilterPopUp,
      clearFilters: this.clearFilters,
      onFiltersChange: this.onFiltersChange,
      handleDatesChange: this.handleDatesChange,
      validateDates: this.validateDates,
      onPaginationchange: this.onPaginationchange,
      onFilterDropDownChange: this.onFilterDropDownChange,
    };
    const forwardedProps = {
      ...this.state,
      ...this.props,
      ...functions,
      ...{ hotelName: "" },
    };
    return (
      <>
        <ToastContainer autoClose={3000} />
        {tableColumns?.length > 0 && <ChangeLogs {...forwardedProps} />}
      </>
    );
  }
}
