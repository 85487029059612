import axios from "axios";
// import ReduxStore from "../../Redux/Store/IndexStore";
import { IUser, IUserUpdateFreshDeskStatus } from "../Contracts/IUser";
import { API_ROOT } from "./Api-config";
import Instense from "./Axios-config";
import { Utils } from "../Utilis";

export class User {
  public static isAuthenticated = localStorage.getItem("token") !== null;
  public static mergeToken = "";
  public static apiLoginResponse: any = null;

  public static getAllUsers = async (): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    let request = {} as any;
    request.tenantID = tenantID;
    const url = `/User/GetAllActiveUsers`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static GetAllActiveUsersByHotelIds = async (
    hotelIds: string
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    let request = {} as any;
    request.tenantID = tenantID;
    request.hotelIds = hotelIds;
    const url = `/User/GetAllActiveUsersByHotelIds`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static login = async (
    userName: string,
    password: string,
    LoginHID: string
  ): Promise<IUser | null> => {
    const url = `${API_ROOT.backendHost}/User/Login`;

    const response = await axios.post(url, { userName, password, LoginHID });
    let loginResponse = response.data;
    User.apiLoginResponse = loginResponse;
    return loginResponse.user;
  };

  public static loginNew = async (
    userName: string,
    password: string,
    LoginHID: string,
    Nonce:string,
    isFreshDeskRequest:boolean =false
  ): Promise<IUser | null> => {
    const url = `${API_ROOT.backendHost}/User/Login`;

    const response = await axios.post(url, { userName, password, LoginHID, Nonce,isFreshDeskRequest });
    let loginResponse = response.data;
    User.apiLoginResponse = loginResponse;
    return loginResponse.user;
  };

  
  public static AutoLogin = async (
    userName: string,
    token: string,
    Nonce:string
  ): Promise<IUser | null> => {
    const url = `${API_ROOT.backendHost}/User/AutoLogin`;
    return Instense.post(url, { userName, token, Nonce }).then((response) => {
      let loginResponse = response.data;
      User.apiLoginResponse = loginResponse;
      return loginResponse;
    });
    
  };

  public static ValidateUserStatus = async (
    userName: string,
    tenantID: number,
    parentComponent: string
  ): Promise<any | null> => {
    // const url = `${API_ROOT.backendHost}/User/ValidateUserStatus`;
    const url = `${API_ROOT.backendHost}/User/ValidateUserStatusNew`;
    const response = await axios.get(
      url + "?userName=" + userName + "&TenantID=" + tenantID
    );
    if (parentComponent === "loginModal") {
      // let result = response.data.result;
      // if (
      //   response.data.success &&
      //   result.rVal === "1" &&
      //   User.apiLoginResponse !== null
      // ) {
      User.settingUserToken(User.apiLoginResponse);
      //}
    }
    return response.data;
  };

  public static UpdateUserValidateStatus = async (
    userName: string,
    password: string,
    tenantID: number,
    email: string,
    phone: string,
    IsEmail: string,
    phoneStatus: string,
    parentComponent: string
  ): Promise<any | null> => {
    // const url = `${API_ROOT.backendHost}/User/UpdateUserValidateStatus`;
    const url = `${API_ROOT.backendHost}/User/UpdateUserValidateStatusNew`;
    const response = await axios.get(
      url +
        "?userName=" +
        userName +
        "&TenantID=" +
        tenantID +
        "&Email=" +
        email +
        "&Phone=" +
        phone +
        "&IsEmail=" +
        IsEmail +
        "&phoneStatus=" +
        phoneStatus +
        "&pagesource=" +
        parentComponent +
        "&password=" +
        password
    );

    if (parentComponent === "loginModal") {
      let result = response.data.result;

      if (result.primaryContact === "Email") {
        if (User.apiLoginResponse !== null) {
          User.apiLoginResponse.user.email = result.email;
        }
      }
      if (
        response.data.success &&
        result.rVal === "1" &&
        User.apiLoginResponse !== null
      ) {
        User.settingUserToken(User.apiLoginResponse);
      }
    }
    return response.data;
  };

  public static settingUserToken = (loginResponse: any) => {
    // Before setting User lets clean all pervious user history
    User.isAuthenticated = false;
    User.apiLoginResponse = null;
    localStorage.clear();
    sessionStorage.clear();

    // Set User Token to local and session
    let storageKey: any = {};
    storageKey.user_UniqueID = loginResponse.user.user_UniqueID;
    storageKey.userName = loginResponse.user.userName;
    storageKey.tenantID = loginResponse.user.tenantID;
    storageKey.rolId = loginResponse.user.rolId;
    storageKey.roleName = loginResponse.user.roleName;
    storageKey.firstName = loginResponse.user.firstName;
    storageKey.lastName = loginResponse.user.lastName;
    storageKey.email = loginResponse.user.email;
    storageKey.phone1 = loginResponse.user.phone1;
    storageKey.restoreID = loginResponse.user.restoreID;
    storageKey.unmergedURL = loginResponse.user.unmergeURL;
    storageKey.currentUtcTime = loginResponse.user.currentUtcTime;
    storageKey.freshdeskLoginUrl = loginResponse.user.freshdeskLoginUrl;
    storageKey.freshdeskSupportUrl = loginResponse.user.freshdeskSupportUrl;
    storageKey.freshdeskUsername = loginResponse.user.freshdeskUsername;
    storageKey.freshdeskPassword= loginResponse.user.freshdeskPassword;
    storageKey.theme = loginResponse.user.theme;
    storageKey.showDefaultThemeToggle = loginResponse.user
      .showDefaultThemeToggle;
      storageKey.isPasswordAssitanceRole = loginResponse.user
      .isPasswordAssitanceRole;
      storageKey.primaryContact = loginResponse.user.primaryContact;
      storageKey.phone1 = loginResponse.user.phone1;
    if (loginResponse.user.isMerge === "Yes") {
      User.SetAuthToken(loginResponse.token, loginResponse.expirationTime, loginResponse?.logoutPopupTime);
      localStorage.setItem("token_merge", loginResponse.token_merge);
      localStorage.setItem("storage", JSON.stringify(storageKey));
      Utils.setCookie("userName",loginResponse.user.userName,1);
      Utils.setCookie("token",loginResponse.token,1);
    }
    User.isAuthenticated = true;

    // Adding FreshDeskApi only to users where status is no in db
    // if (loginResponse.user.isUpdateFresdesk === "No") {
    //   FreshDeskAccounts.CreateContact(loginResponse.user);
    // }

    // // Sending Role Permissions Actions to Redux
    // ReduxStore.dispatch({
    //   type: "UPDATE_ROLE_PERMISSION",
    //   payload: {
    //     permissionViewSchedule: true,
    //   },
    // });
  };

  public static ValidateUserName = async (
    userName: string
  ): Promise<boolean | null> => {
    const url = `${API_ROOT.backendHost}/User/ValidateUserName`;
    const response = await axios.get(url + "?userName=" + userName);

    return response.data.success;
  };

  public static GetCurrentTime = async (): Promise<boolean | null> => {
    const url = `${API_ROOT.backendHost}/User/GetCurrentTime`;
    const response = await axios.get(url);
    return response.data;
  };

  public static GenerateToken = async (): Promise<boolean | null> => {
    // const url = `/User/GenerateToken`;
    // return Instense.get(url).then((response) => {
    //   let result = response.data as any;
    //   return result;
    // });

    let request: any = {};
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let token = localStorage.getItem("token")!;
    let tenantID = storage.tenantID;

    request.id = 0;
    request.status = "Active";
    request.token = token;
    request.tenantID = tenantID;
    request.storage = btoa(
      storage.user_UniqueID +
        "_" +
        storage.userName +
        "_" +
        storage.rolId +
        "_" +
        storage.roleName +
        "_" +
        storage.firstName +
        "_" +
        storage.lastName +
        "_" +
        storage.email +
        "_" +
        storage.restoreID +
        "_" +
        storage.unmergedURL +
        "_" +
        storage.currentUtcTime +
        "_" +
        storage.theme +
        "_" +
        storage.showDefaultThemeToggle +
        "_" +
        storage.expirationTime +
        "_" +
        storage.token_merge
    );

    const url = `/User/SaveGenerateToken`;
    const response = await Instense.post(url, request);
    let result = response.data;
    return result;
  };

  public static removeToken = async (): Promise<boolean | null> => {
    // const url = `/User/GenerateToken`;
    // return Instense.get(url).then((response) => {
    //   let result = response.data as any;
    //   return result;
    // });

    let request: any = {};
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let token = localStorage.getItem("token")!;
    let tenantID = storage.tenantID;

    request.id = 0;
    request.status = "Inactive";
    request.token = token;
    request.tenantID = tenantID;
    request.storage = "";

    const url = `/User/UserTokenUpdate`;
    const response = await Instense.post(url, request);
    let result = response.data;
    return result;
  };



  public static restoreTokenDetails = async (tokenID: string, code: string): Promise<any | null> => {

    const url = `/User/TokenDetails`;
    let request: any = {};
    request.id = tokenID;
    request.code = code;
    request.utctime = "";
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      if (result.length > 0) {
        let storagedet = window.atob(result[0].storage);
        let token = result[0].token.toString();
      let storagelist = storagedet.split("_");

        if (storagelist.length > 0) {
          let user_UniqueID = storagelist[0].toString();
          let userName = storagelist[1].toString();
          let tenantID = result[0].tenantID.toString();
          let rolId = storagelist[2].toString();
          let roleName = storagelist[3].toString();
          let firstName = storagelist[4].toString();
          let lastName = storagelist[5].toString();
          let email = storagelist[6].toString();
          let restoreID = storagelist[7].toString();
          let unmergedURL = storagelist[8].toString();
          let currentUtcTime = storagelist[9].toString();
          let theme = storagelist[10].toString();
          let showDefaultThemeToggle = storagelist[11].toString();

          let storageKey: any = {};

        storageKey.user_UniqueID = user_UniqueID;
          storageKey.userName = userName;
          storageKey.tenantID = tenantID;
          storageKey.rolId = rolId;
          storageKey.roleName = roleName;
          storageKey.firstName = firstName;
          storageKey.lastName = lastName;
          storageKey.email = email;
          storageKey.restoreID = restoreID;
          storageKey.unmergedURL = unmergedURL;
          storageKey.currentUtcTime = currentUtcTime;
          storageKey.theme = theme;
          storageKey.showDefaultThemeToggle = showDefaultThemeToggle;
          
          localStorage.setItem("storage", JSON.stringify(storageKey));
          Utils.setCookie("userName",userName,1);
          localStorage.setItem("token", token);
          // localStorage.setItem("expirationTime", expirationTime);
          localStorage.setItem("token_merge", token);
        localStorage.setItem("showIncompleteSlideOut", "false");
          User.isAuthenticated = true;
        }
      }
      // if (loginResponse.user.isMerge === "Yes") {
      //   User.SetAuthToken(loginResponse.token, loginResponse.expirationTime);
      //   localStorage.setItem("token_merge", loginResponse.token_merge);
      //   localStorage.setItem("storage", JSON.stringify(storageKey));
      // }

      return result;
    });
  };

  public static GetSchedulePunchInUser = async (request: any): Promise<any> => {
    const url = `/LaborSchedule/GetSchedulePunchInUser`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data;
      return result;
    });
  };
  public static GetHotelCurrentDateTime = async (
    request: any
  ): Promise<any> => {
    const url = `/Hotel/GetHotelCurrentDateTime`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data;
      return result;
    });
  };

  public static GetTimeZoneCurrentDateTime = async (
    request: any
  ): Promise<any> => {
    const url = `/Hotel/GetTimeZoneCurrentDateTime`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data;
      return result;
    });
  };
  public static EmployeePunchOut = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage.tenantID;
    // request.TenantID = tenantID;
    request.EnterpriseID = tenantID;
    // const url = `/LaborSchedule/SaveEmployeePunchOut`;
    // const url = `/LaborSchedule/SavePunchOutData`;
    //  const url = `/LaborSchedule/SavePunchDataWithPassword`;
    const url = `/LaborSchedule/SavePunchDataWithPasswordNew`;
    const response = await Instense.post(url, request);
    let result = response.data;
    return result;
  };
  public static EmployeePunchIn = async (request: any): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage.tenantID;
    // request.TenantID = tenantID;
    request.EnterpriseID = tenantID;
    // const url = `/LaborSchedule/SaveEmployeePunchIn`;
    //const url = `/LaborSchedule/SavePunchInData`;//
    // const url = `/LaborSchedule/SavePunchDataWithPassword`;
    const url = `/LaborSchedule/SavePunchDataWithPasswordNew`;
    const response = await Instense.post(url, request);
    let result = response.data;
    return result;
  };
  public static SaveUserLaborScheduleDetails = async (
    request: any
  ): Promise<any | null> => {
    const url = `/LaborSchedule/SaveLaborScheduleDetails`;
    const response = await Instense.post(url, request);
    let result = response.data;
    return result.result;
  };

  public static RefreshToken = async (): Promise<boolean | null> => {
    const url = `/User/RefreshToken`;
    return Instense.get(url).then((response) => {
      let result = response?.data;
      return result;
    });
  };

  public static SetAuthToken = (token, expirationTime, logoutPopupTime) => {
    localStorage.setItem("token", token);
    localStorage.setItem("expirationTime", expirationTime);
    localStorage.setItem("logoutPopupTime", logoutPopupTime);
    localStorage.setItem("secondsTime", JSON.stringify(300));
  };

  public static RemoveAuthToken = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("expirationTime");
    localStorage.removeItem("logoutPopupTime");
  };

  public static WarnUserExpiringPassword = async (
    userUniqeID: string
  ): Promise<string | null> => {
    const url = `${API_ROOT.backendHost}/User/WarnUserExpiringPassword`;
    const response = await axios.get(url + "?userUniqeID=" + userUniqeID);
    return response.data;
  };

  public static PasswordExpireAlert = async (
    userUniqeID: string
  ): Promise<string | null> => {
    const url = `${API_ROOT.backendHost}/User/PasswordExpireAlert`;
    const response = await axios.get(url + "?userUniqeID=" + userUniqeID);
    return response.data.message;
  };

  public static UpdateFreshDeskStatus = async (
    request: IUserUpdateFreshDeskStatus
  ): Promise<string | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let user_UniqueID = storage.user_UniqueID;
    let userName = storage.userName;
    request.User_UniqueID = user_UniqueID;
    request.UserName = userName;
    const url = `/User/UpdateFreshDeskStatus`;
    const response = await Instense.post(url, request);
    let result = response.data;
    return result;
  };

  public static UpdateRestoreID = async (
    request: any
  ): Promise<string | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let user_UniqueID = storage.user_UniqueID;
    let tenantID = storage.tenantID;
    request.User_UniqueID = user_UniqueID;
    request.tenantID = tenantID;
    const url = `/User/UpdateRestoreID`;
    const response = await Instense.post(url, request);
    let result = response.data;
    return result;
  };

  public static UnderMaintenance = async (): Promise<any | null> => {
    const url = `/User/UnderMaintenance`;
    return Instense.get(url).then((response) => {
      let result = response.data;
      return result;
    });
  };

  public static ValidatePhoneNumber = async (phoneNo): Promise<any | null> => {
    const request: any = {};
    request.phone = phoneNo;
    return Instense.get("User/ValidatePhoneNew", { params: request }).then(
      (response) => {
        return (response.data?.result || {});
      }
    );
  };

  public logout = (): void => {
    localStorage.clear();
  };

  public static GetUserDetails = async (userUniqueID:  number): Promise<IUser> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    let request = {} as  any;
    request.tenantId = tenantID;
    request.user_UniqueId = userUniqueID;
   
    const url = `/User/GetUserDetail`;
    return Instense.get(url, { params: request} ).then((response) => {
      // 
      let result = response.data?.result as IUser;
      return result;
    });
  };


  public static UpdateLastClickedFreshDeskUrl = async (
    freshDeskUrl: string
  ): Promise<string | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let user_UniqueID = storage.user_UniqueID;
    let tenantID = storage.tenantID;
   const request:any = {}
    request.user_UniqueID = user_UniqueID;
    request.tenantID = tenantID;
    request.url= freshDeskUrl;
    const url = `/User/UpdateLastClickedFreshDeskUrl`;
    const response = await Instense.post(url, request);
    let result = response?.data;
    return result;
  };

  public static IsFreshDeskTokenExpired = async (): Promise<string | null> => {
    const url = `/User/IsFreshDeskTokenExpired`;
    const response = await Instense.get(url);
    let result = response.data;
    return result;
  };

  public static GetServerTime = async (): Promise<any | null> => {
    const url = `/UserManagement/GetServerTime`;
    return Instense.get(url).then((response) => {
      let result = response.data.result;
    
      let date = new Date(result);
      result = date.toISOString().replace(/\.\d{3}Z$/, match => match.slice(0, 4) + 'Z');
      
      return result;
    });
  };

}

interface ILoginResponse {
  success: boolean;
  token: string;
  token_merge: string;
  user: IUser;
}
