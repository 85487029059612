import axios, {AxiosError } from "axios";
import axiosRetry from 'axios-retry';
import { API_ROOT } from "./Api-config";

//const Instense = axios.create({timeout : 2000, baseURL : "" , withCredentials : true});
const Instense = axios.create();
/*
if (window.location.href.indexOf("login") <= 0) {
  if (localStorage.getItem("token") != null) {
   
    Instense.defaults.headers.common = {
      Authorization: `bearer ${localStorage.getItem("token")}`
    };
    Instense.defaults.baseURL = `${API_ROOT.backendHost}`;
  } else {
    localStorage.clear();
    window.location.href = "/login";
  }
}*/

const controller=new AbortController()
//if (window.location.href.indexOf("login") <= 0) {
Instense.defaults.baseURL = `${API_ROOT.backendHost}`;
Instense.interceptors.request.use((config) => {
 

  if (localStorage.getItem("token") != null && localStorage.getItem("storage") != null) {
    config.headers.Authorization = `bearer ${localStorage.getItem("token")}`
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let userName = storage === null ? "" : (storage.userName as any);
    let tenantID = storage === null ? "" : (storage.tenantID as any);
    config.headers.Username = userName;
    config.headers.tenantId = tenantID;
  }
  return {...config,signal:controller.signal}
}, (err) => {
  console.log(err)
  return Promise.reject(err)
})
// }
// else {
//   localStorage.clear();
//   window.location.href = "/login";
// }

const cancelAllOngoingRequest =()=>{
  controller.abort()
}

Instense.interceptors.response.use((response) => {
  
  return response;
}, (error) => {
 
  if (error?.response?.data?.message === "under maintenance") {
    window.location.href = "/Under-Maintenance";
  }
  else if (error.response && error?.response?.data && error?.response?.data?.error &&
    (error.response.data.session === false || error.response.data.session === "false") || (localStorage.getItem("token") === null)) {
    // localStorage.removeItem("userId"); // <-- add your var
    window.location.href = "/login";   // <-- add your path
    return Promise.reject("Unauthorized");
  }
  else if (error?.response && error?.response?.data && error?.response?.data?.error && error?.response?.data?.error?.message) {
    alert(error.response.data.error.message);
  }
  else
    if ((error?.response && error?.response?.status === 401) || (localStorage.getItem("token") === null)) {
      // localStorage.removeItem("userId"); // <-- add your var
      window.location.href = "/login";  // <-- add your path
      return Promise.reject("Unauthorized");
      // cancelAllOngoingRequest()
    } else
      return Promise.reject(error);
});


axiosRetry(Instense, {
  retries: 3, // Number of retries
  retryDelay: (retryCount) => {
    console.log(`Retry attempt: ${retryCount}`);
    return 1000; // 1 second delay between retries
  },
  retryCondition: (error: AxiosError<unknown, any>): boolean => {
    // Retry only if the error status is 503
    return error?.response?.status === 503;
  },
});

export default Instense;
//export const AxiosRoot = `${Instense}`;

