import React from "react"
import { ReportViewer } from "./ReportViewer"
export class ReportRunnerRightPanel extends React.Component<any, any> {
    render(){
        return(
            <>
                <div className="report-viewer-container">
                    <ReportViewer onReportload ={this.props?.onReportload} {...this.props}/>
                </div>
            </>
        )
    }
}