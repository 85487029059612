import Instense from "./Axios-config";
import { IModuleReport } from "../Contracts/IModuleReport";

export class ModuleReports {

  public static GetPagePermissionData = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let roleid = storage === null ? 0 : (storage.rolId as any);
    request.TenantID = tenantID;
    request.RoleID = roleid;
    const url = `/RolePermission/GetPageSubPermissionDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static GetReportPermissionData = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let roleid = storage === null ? 0 : (storage.rolId as any);
    request.TenantID = tenantID;
    request.RoleID = roleid;
    const url = `/RolePermission/GetReportPermissionDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };


  public static getReportList = (moduleId: number): Promise<IModuleReport[] | null> => {

    const url = `/Reports/GetReportList`;
    const request = {} as any;
    request.moduleId = moduleId;

    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as [];
      return result;
    }).catch((error) => {
      return error;
    });
  }



  public static GetAllAccountingYears = async (): Promise<string[]> => {
    const storage = JSON.parse(localStorage.getItem("storage")!);
    const request: any = {};
    request.tenantID = storage === null ? 0 : (storage.tenantID as any);
    const url = `/Reports/GetAllAccountingYears`;
    const response = await Instense.get(url, { params: request });
    let result = response.data as any;
    return result.result;
  };

  public static GetLaborEmployees = async (hotelIds: string = "", status: number): Promise<string[]> => {
    const storage = JSON.parse(localStorage.getItem("storage")!);
    const request: any = {};
    request.tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.hotelIds = hotelIds;
    request.status = status ? status : 1;
    const url = `/Reports/GetLaborEmployees`;
    const response = await Instense.get(url, { params: request });
    let result = response.data as any;
    return result.result;
  };

  public static GetBankAccounts = async (hotelIds: string = ""): Promise<string[]> => {
    const storage = JSON.parse(localStorage.getItem("storage")!);
    const request: any = {};
    request.userName = storage === null ? 0 : (storage.userName as any);
    request.TenantID = storage === null ? 0 : (storage.tenantID as any);
    request.Hid = hotelIds;
    const url = `/Reports/GetBankAccountFromHid`;
    const response = await Instense.get(url, { params: request });
    let result = response.data as any;
    return result.result;
  };

  public static GetACACodes = async (accTypeID: number): Promise<any[]> => {
    const storage = JSON.parse(localStorage.getItem("storage")!);
    const request: any = {};
    request.tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.accTypeID = accTypeID;
    const url = `/Reports/GetACACodes`;
    const response = await Instense.get(url, { params: request });
    let result = response.data as any;
    return result.result;
  };

  public static GetAutoCompleteEmployeeData = async (prefix: string): Promise<any[]> => {
    const storage = JSON.parse(localStorage.getItem("storage")!);
    const request: any = {};
    request.tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.prefix = prefix;
    const url = `/Reports/GetAutoCompleteEmployeeData`;
    const response = await Instense.get(url, { params: request });
    let result = response.data as any;
    return result.result;
  };

  public static SaveUserReport = async (request: any): Promise<any[]> => {
    const storage = JSON.parse(localStorage.getItem("storage")!);
    request.User_UniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    request.TenantId = storage === null ? 0 : (storage.tenantID as any);
    const url = `/Reports/SaveUserReport`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result.result;
  };

  public static GetUserReport = async (): Promise<any[]> => {
    const storage = JSON.parse(localStorage.getItem("storage")!);
    const request: any = {};
    request.User_UniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    request.TenantId = storage === null ? 0 : (storage.tenantID as any);
    let Module_Id = ModuleReports.getModuleId(localStorage.mainMenu as any);
    request.ModuleId = Module_Id;
    const url = `/Reports/GetUserSavedReport`;
    const response = await Instense.get(url, { params: request });
    let result = response.data.result as any;
    return result.result;
  };

  public static getModuleId = (muduleName: any) => {

    let str = window.location.href;
    if (str.includes("Account-management")) {
      return 8
    }
    else if (str.includes("labor-management")) {
      return 4
    }
    else {
      return 4
    }

    // switch (muduleName) {
    //   case 'Accounting':
    //     return 1
    //   case 'Labor':
    //     return 4
    //   default:
    //     return 4
    // }
  }

  public static getReportParameters = (UserReportId: number, ModuleReportId: number) => {
    const url = `/Reports/GetReportParameters`;
    const request = {} as any;
    request.userReportId = UserReportId;
    request.moduleReportId = ModuleReportId;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any[] = response.data.result.result as [];
      const keys = Object.keys(result[0]);
      for (let key in keys) {
        const _key = keys[key];
        if (!isNaN(result[0][_key]) && !_key?.includes('userReportId') && !_key?.includes('reportId') && !_key?.includes('moduleReportId') && !_key?.includes('reportParameter')) {
          result[0][_key] = +result[0][_key] > 0 ? true : false;
        }
      }
      return result;
    }).catch((error) => {
      return error;
    });
  }

  public static GetReportInputParamters = (moduleReportId: number) => {
    const url = `/Reports/GetReportInputParamters`;
    const request = {} as any;
    request.moduleReportId = moduleReportId;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any[] = response.data.result.result as [];
      // const keys = Object.keys(result[0]);
      // for(let key in keys ) {
      //   const _key =keys[key];
      //    if(!isNaN(result[0][_key]) && !_key?.includes('userReportId') && !_key?.includes('reportId') && !_key?.includes('moduleReportId') && !_key?.includes('reportParameter')) {
      //     result[0][_key] = +result[0][_key]> 0 ? true: false;
      //   }
      // }
      return result;
    }).catch((error) => {
      return error;
    });
  }



  public static getModuleReports = (ModuleId: number) => {
    const url = `/Reports/GetModuleReports`;
    const request = {} as any;

    let Module_Id = ModuleReports.getModuleId(localStorage.mainMenu as any);
    request.ModuleId = Module_Id;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result as any;
      return result;
    }).catch((error) => {
      return error;
    });
  }

  public static getAllModuleReports = () => {
    const url = `/Reports/GetAllModuleReports`;
    const request = {} as any;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result as any;
      return result;
    }).catch((error) => {
      return error;
    });
  }

  // This is New Implemation 
  public static getIsMOde = () => {

    const storage = JSON.parse(localStorage.getItem("storage")!);
    const url = `/Reports/GetIsMode`;
    const request = {} as any;
    request.TenantID = storage === null ? 0 : (storage.tenantID as any);
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result as any;
      return result;
    }).catch((error) => {
      return error;
    });
  }

  public static deleteUserReport = (UserReportId: number) => {
    const url = `/Reports/DeleteUserReport`;
    const request = {} as any;
    request.UserReportId = UserReportId;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result as any;
      return result;
    }).catch((error) => {
      return error;
    });
  }

  public static duplicateUserReport = (UserReportId: number, newReportName: string) => {
    const url = `/Reports/DuplicateUserReport`;
    const request = {} as any;
    request.UserReportId = UserReportId;
    request.newReportName = newReportName;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result as any;
      return result;
    }).catch((error) => {
      return error;
    });
  }

  public static renameUserReport = (UserReportId: number, newReportName: string) => {
    const url = `/Reports/RenameUserReport`;
    const request = {} as any;
    request.UserReportId = UserReportId;
    request.newReportName = newReportName;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result as any;
      return result;
    }).catch((error) => {
      return error;
    });
  }

  public static SaveAutomationReport = async (request: any): Promise<any[]> => {
    const storage = JSON.parse(localStorage.getItem("storage")!);
    request.CreatedBy = storage === null ? 0 : (storage.userName as any);
    request.TenantID = storage === null ? 0 : (storage.tenantID as any);
    request.NoOfReceipents = 0;
    const url = `/AutomationReport/SaveAutomationReport`;
    const response = await Instense.post(url, request);
    let result = response.data.result as any;
    return result;
  };

  public static UpdateAutomationReportParam = async (request: any): Promise<any[]> => {
    const storage = JSON.parse(localStorage.getItem("storage")!);
    request.CreatedBy = storage === null ? 0 : (storage.userName as any);
    request.TenantID = storage === null ? 0 : (storage.tenantID as any);
    request.NoOfReceipents = 0;
    const url = `/AutomationReport/UpdateAutomationReportParam`;
    const response = await Instense.post(url, request);
    let result = response.data.result as any;
    return result;
  };

  public static getAutomationReportByName = (customreportName: string) => {
    const url = `/AutomationReport/GetAutomationReportByName`;
    const request = {} as any;
    const storage = JSON.parse(localStorage.getItem("storage")!);
    request.customreportName = customreportName;
    request.username = storage === null ? 0 : (storage.userName as any);
    request.tenantId = storage === null ? 0 : (storage.tenantID as any);
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result.result as any;
      return result;
    }).catch((error) => {
      return error;
    });
  }

  public static getAutomationReportByReportId = (reportId: number) => {
    const url = `/AutomationReport/GetAutomationReportByReportId`;
    const request = {} as any;
    const storage = JSON.parse(localStorage.getItem("storage")!);
    request.reportId = reportId;
    request.username = storage === null ? 0 : (storage.userName as any);
    request.tenantId = storage === null ? 0 : (storage.tenantID as any);
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result.result as any;
      return result;
    }).catch((error) => {
      return error;
    });
  }

  public static getUserScheduleReport = () => {
    const url = `/AutomationReport/GetUserScheduleReport`;
    const request = {} as any;
    const storage = JSON.parse(localStorage.getItem("storage")!);
    request.username = storage === null ? 0 : (storage.userName as any);
    request.tenantId = storage === null ? 0 : (storage.tenantID as any);
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result as any;
      return result;
    }).catch((error) => {
      return error;
    });
  }

  public static getScheduleReportByUniqueNo = (uniqueNo) => {
    const url = `/AutomationReport/GetScheduleReportByUniqueNo`;
    const request = {} as any;
    const storage = JSON.parse(localStorage.getItem("storage")!);
    request.uniqueNo = uniqueNo;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result as any;
      return result;
    }).catch((error) => {
      return error;
    });
  }

  public static deleteScheduleReport = (uniqueNo: number) => {
    const url = `/AutomationReport/DeleteScheduleReport`;
    const request = {} as any;
    request.uniqueNo = uniqueNo;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result as any;
      return result;
    }).catch((error) => {
      return error;
    });
  }

  public static duplicateScheduleReport = (uniqueNo: number, reportName: string) => {
    const url = `/AutomationReport/DuplicateScheduleReport`;
    const request = {} as any;
    request.uniqueNo = uniqueNo;
    request.reportName = reportName;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result as any;
      return result;
    }).catch((error) => {
      return error;
    });
  }

  public static GetLaborMultiUsers = async (hotelIds: number): Promise<any[]> => {

    const storage = JSON.parse(localStorage.getItem("storage")!);
    const request: any = {};
    request.tenantId = storage === null ? 0 : (storage.tenantID as any);
    request.hotelIds = hotelIds;
    const url = `/Reports/GetLaborMultiUsers`;
    const response = await Instense.get(url, { params: request });
    let result = response.data as any;

    return result.result;
  };




  public static GetGlobalSetting = async (reportParanetid: number): Promise<any[]> => {


    const storage = JSON.parse(localStorage.getItem("storage")!);
    const request: any = {};
    request.tenantId = storage === null ? 0 : (storage.tenantID as any);
    request.reportParanetid = reportParanetid;
    //request.percentage= percentage;
    const url = `/Reports/GetIsGlobalSetting`;
    const response = await Instense.get(url, { params: request });
    let result = response.data as any;

    return result.result;
  };


  public static GetClosingPeriod = async (reportParanetid: number): Promise<any[]> => {


    const storage = JSON.parse(localStorage.getItem("storage")!);
    const request: any = {};
    request.tenantId = storage === null ? 0 : (storage.tenantID as any);
    // request.reportParanetid= reportParanetid;
    //request.percentage= percentage;
    const url = `/Reports/LastClosedPeriod`;
    const response = await Instense.get(url, { params: request });
    let result = response.data as any;

    return result.result;
  };


  public static GetlastActivePeriods = async (reportParanetid: number): Promise<any[]> => {


    const storage = JSON.parse(localStorage.getItem("storage")!);
    const request: any = {};
    request.tenantId = storage === null ? 0 : (storage.tenantID as any);
    // request.reportParanetid= reportParanetid;
    //request.percentage= percentage;
    const url = `/Reports/GetLastAcountugDate`;
    const response = await Instense.get(url, { params: request });
    let result = response.data as any;

    return result.result;
  };

  public static GetSeverDateTime = async (reportParanetid: number): Promise<any[]> => {


    const storage = JSON.parse(localStorage.getItem("storage")!);
    const request: any = {};
    request.tenantId = storage === null ? 0 : (storage.tenantID as any);
    // request.reportParanetid= reportParanetid;
    //request.percentage= percentage;
    const url = `/Reports/GetServerDateTime`;
    const response = await Instense.get(url, { params: request });
    let result = response.data as any;

    return result.result;
  };


  public static InsertRunReportDetails = async (reportName: string, runReportDateTime: string, runReportEndDateTime: string, reportRenderInMS: string, reportRenderMinutSecond: string, reportParam: string, type: string): Promise<any[]> => {
    const storage = JSON.parse(localStorage?.getItem("storage")!);
    const request: any = {};
    //debugger;
    request.reportName = reportName;
    request.rptRunDateTime = runReportDateTime;
    request.rptloadPhase = runReportEndDateTime;
    request.rptRenderInMilliseconds = reportRenderInMS;
    request.rptCommitTime = reportRenderMinutSecond;
    request.tenantId = storage === null ? 0 : (storage.tenantID as any);
    request.reportParam = reportParam;
    request.type = type;
    const url = `/Reports/SaveReportRunTimeDetails`;
    return Instense.post(url, request).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static GetProjectBudget = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/Reports/GetProjectBudget`;
    return Instense.get(url, { params: request }).then((response) => {
      //debugger;
      let result = response.data.result as any;
      return result;
    });
  };

  public static GetVendorList = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    request.UserName = storage === null ? 0 : (storage.userName as any);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.TenantID = tenantID;
    const url = `/Reports/GetVendorList`;
    return Instense.get(url, { params: request }).then((response) => {
      //debugger;
      let result = response.data.result as any;
      return result;
    });
  };

  public static GetVendorContractList = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    request.UserName = storage === null ? 0 : (storage.userName as any);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.TenantID = tenantID;
    const url = `/Reports/GetVendorContractList`;
    return Instense.get(url, { params: request }).then((response) => {
      //debugger;
      let result = response.data.result as any;
      return result;
    });
  };

  public static GetCOAClassificationReportList = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.TenantID = tenantID;
    const url = `/Reports/Get_COAClassificationReportList`;
    return Instense.get(url, { params: request }).then((response) => {
      //debugger;
      let result = response.data.result as any;
      return result;
    });
  }




  //========================================== Global Setting ===================================\\

  public static getGlobalSettingParams = (moduleReportId: number) => {
    const url = `/Reports/getGlobalSettingParams`;
    const request = {} as any;
    request.moduleReportId = moduleReportId;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any[] = response.data.result.result as [];
      return result;
    }).catch((error) => {
      return error;
    });
  }


  public static GETreprotPageSetup = async (parentreportid: number): Promise<any[]> => {
    const storage = JSON.parse(localStorage.getItem("storage")!);
    const request: any = {};
    request.tenantId = storage === null ? 0 : (storage.tenantID as any);
    request.parentreportid = parentreportid;
    const url = `/Reports/GetReportSetup`;
    const response = await Instense.get(url, { params: request });
    let result = response.data.result as any;
    return result;
  };

  public static InsertreprotPageSetup = async (ModuleReportId: number,
    percentage: string, underPercentage: string, variance: string, underVariance: string, reportName: string, bottomMessage: string): Promise<any[]> => {
    const storage = JSON.parse(localStorage?.getItem("storage")!);
    const request: any = {};
    request.tenantId = storage === null ? 0 : (storage.tenantID as any);
    request.percentage = percentage;
    request.underPercentage = underPercentage;
    request.variance = variance;
    request.underVariance = underVariance;
    request.ReportHeader = reportName;
    request.ModuleReportId = ModuleReportId;
    request.bottomMessage = bottomMessage;
    const url = `/Reports/SaveGlobalSettings`;
    return Instense.post(url, request).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static getCashFloWData = () => {
    const url = `/Reports/GetCashFlowData`;
    const storage = JSON.parse(localStorage?.getItem("storage")!);
    const request: any = {};
    request.tenantId = storage === null ? 0 : (storage.tenantID as any);
    return Instense.get(url, { params: request }).then((response) => {
      let result: any[] = response.data.result as [];
      return result;
    }).catch((error) => {
      return error;
    });
  }

  public static updateCashFlowData = async (arr: any): Promise<any[]> => {
    const storage = JSON.parse(localStorage?.getItem("storage")!);
    const tenantId = storage === null ? 0 : (storage.tenantID as any);
    arr.map((items) => (items["tenantId"] = tenantId));
    const url = `/Reports/updateCashFlowData`;
    return Instense.post(url, arr).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };


  public static getRevenueData = (globalid) => {
    const url = `/Reports/GetReportMasterById`;
    const storage = JSON.parse(localStorage?.getItem("storage")!);
    const request: any = {};
    request.tenantId = storage === null ? 0 : (storage.tenantID as any);
    request.reportId = globalid;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any[] = response.data.result as [];
      return result;
    }).catch((error) => {
      return error;
    });
  }


  public static getStatisticsData = () => {
    const url = `/Reports/GetStatisticRevenueData`;
    const storage = JSON.parse(localStorage?.getItem("storage")!);
    const request: any = {};
    request.tenantId = storage === null ? 0 : (storage.tenantID as any);
    return Instense.get(url, { params: request }).then((response) => {
      let result: any[] = response.data.result as [];
      return result;
    }).catch((error) => {
      return error;
    });
  }

  public static updateStatisticRevenueData = async (arr: any): Promise<any[]> => {
    const storage = JSON.parse(localStorage?.getItem("storage")!);
    const tenantId = storage === null ? 0 : (storage.tenantID as any);
    const request: any = {};
    arr.objRevenue.tenentId = tenantId;
    request.objRevenue = arr.objRevenue;
    arr.StatisticData.map((items) => (items["tenentId"] = tenantId));
    arr.StatisticData.map((items) => (items["reportId"] = arr.objRevenue.reportId));
    request.Statistics = arr.StatisticData;
    //debugger;
    const url = `/Reports/SaveStatisticRevenue`;
    return Instense.post(url, request).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static PostSaveCashFlowItem = async (request: any): Promise<any[]> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantId = tenantID;
    const url = `/Reports/saveCashFlowItem`;
    return Instense.post(url, request).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static DeleteCashFlowItem = async (request: any): Promise<any[]> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantId = tenantID;
    const url = `/Reports/DeleteCashFlowItem`;
    return Instense.post(url, request).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static updateCashFlowOrder = async (request: any): Promise<any[]> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.map((items) => (items["tenantId"] = tenantID));
    request.map((items) => (items["orderNo"] = items.orderSrno));
    request.map((items) => (items["uniqueNo"] = items.uniqueno_2));
    const requestData: any = {};
    requestData.CashFlowRequest = request;
    const url = `/Reports/UpdateCashFlowItemOrders`;
    return Instense.post(url, requestData).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static GetePayVendorList = async (request): Promise<any[]> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.TenantId = tenantID
    const url = `/Vendor/Get_ePayVendorPaymentsReport`;
    const response = await Instense.post(url, request);
    let result = response.data.result as any;
    return result;
  };

  public static GetDrillDownData = async (request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/Reports/Get_DrilldownData`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static GetDrillDownDataExport = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/Reports/Get_DrilldownDataAttachment`;
    return Instense.post(url, request, { responseType: "blob" }).then(
      (response: any) => {
        let csvURL = window.URL.createObjectURL(response.data);
        let tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", request.fileName);
        tempLink.click();
      }
    );
  };

  public static Get_DrilldownDataAttachmentMultiple = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/Reports/Get_DrilldownDataAttachmentMultiple`;
    return Instense.post(url, request, { responseType: "blob" }).then(
      (response: any) => {
        let csvURL = window.URL.createObjectURL(response.data);
        let tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", request.fileName);
        tempLink.click();
      }
    );
  };

  public static GetHeaderDetails = async (request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/Reports/GetHeaderDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static GetReportQueue = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    let user_UniqueId = storage === null ? 0 : storage.user_UniqueID;
    request.tenantID = tenantID;
    request.user_UniqueId = user_UniqueId;
    const url = `/Reports/GetReportQueue?User_UniqueID=${request.user_UniqueId}&TenantId=${request.tenantID}`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

}
