import axios from "axios";
import { API_ROOT } from "./Api-config";
import {
  ILaborLaborManagerTimeClockRequestDto,
  IsendEmailInAddShiftInTimeClockRequestDto,
} from "../Contracts/IWebTimeClock";
import Instense from "./Axios-config";

export interface ISaveEmployeeForcePunch {
  Hotelid: number;
  EnterpriseID: number;
  ScheduleNo: number;
  UserName: string;
  Time24Hrs: string;
  ForceRemarks: string;
}

export interface ISaveEmployeeForcePunchNew {
  Hotelid: number;
  EnterpriseID: number;
  ScheduleNo: number;
  UserName: string;
  Time24Hrs: string;
  ForceRemarks: string;
}

export class WebTimeClockService {
  public static GetHotelCurrentDateTime = async (): Promise<any | null> => {
    let request = {} as any;
    request.HID = JSON.parse(localStorage.WebClockDetail).defaultHid;
    // const url = `${API_ROOT.backendHost}/Hotel/GetHotelCurrentDateTime`;
    const url = `${API_ROOT.backendHost}/Hotel/GetTimeZoneCurrentDateTime`;
    return axios.get(url, { params: request }).then((response) => {
      let result = response.data as any;
      return result;
    });
  };

  public static GetEmployeeTimeClockData = async (
    request: any
  ): Promise<any> => {
    let timeClockTemp = JSON.parse(localStorage.getItem("timeClockTemp")!);
    let tenantID = timeClockTemp === null ? 0 : (timeClockTemp.tenantID as any);
    if (tenantID !== 0) {
      request.tenantID = tenantID;
    }
    const url = `${API_ROOT.backendHost}/LaborSchedule/GetSchedulePunchInUser`;
    return axios.get(url, { params: request }).then((response) => {
      let result = response.data as any;
      return result;
    });
  };

  public static GetEmployeeTimeClockSchedules = async (
    request: any
  ): Promise<any> => {
    const url = `${API_ROOT.backendHost}/LaborSchedule/GetSchedulePunchUserDetails`;
    return axios.get(url, { params: request }).then((response) => {
      let result = response.data as any;
      return result;
    });
  };

  public static GetLaborManagerTimeClock = async (
    request: ILaborLaborManagerTimeClockRequestDto
  ): Promise<any | null> => {
    let timeClockTemp = JSON.parse(localStorage.getItem("timeClockTemp")!);
    let timeClock = JSON.parse(localStorage.getItem("timeClock")!);
    let tenantID = timeClockTemp === null ? 0 : timeClockTemp.tenantID;
    let user_UniqueID = timeClock === null ? 0 : timeClock.managerId;
    request.tenantID = tenantID;
    request.LoginUser = user_UniqueID;
    const url = `${API_ROOT.backendHost}/LaborSchedule/GetLaborManagerTimeClock`;
    return axios.get(url, { params: request }).then((response) => {
      let result = response.data as any;
      return result;
    });
  };

  public static GetLaborPositionDept = async (
    request: any
  ): Promise<any | null> => {
    let timeClockTemp = JSON.parse(localStorage.getItem("timeClockTemp")!);
    let tenantID = timeClockTemp === null ? 0 : (timeClockTemp.tenantID as any);
    let user_UniqueID =
      timeClockTemp === null ? 0 : (timeClockTemp.user_UniqueID as any);
    request.tenantID = tenantID;
    request.userUniqueno = user_UniqueID;
    const url = `${API_ROOT.backendHost}/LaborSchedule/GetLaborScheduleDeptPosition`;
    return axios.get(url, { params: request }).then((response) => {
      let result = response.data as any;
      return result;
    });
  };

  public static GetLaborScheduleEmployee = async (
    request: any
  ): Promise<any | null> => {
    let timeClockTemp = JSON.parse(localStorage.getItem("timeClockTemp")!);
    let tenantID = timeClockTemp === null ? 0 : (timeClockTemp.tenantID as any);
    request.tenantID = tenantID;
    const url = `${API_ROOT.backendHost}/LaborSchedule/Get_LaborScheduleEmployeeList`;
    return axios.get(url, { params: request }).then((response) => {
      let result = response.data as any;
      return result;
    });
  };

  public static SaveLaborScheduleDetails = async (
    request: any
  ): Promise<any | null> => {
    const url = `${API_ROOT.backendHost}/LaborSchedule/SaveLaborScheduleDetails`;
    const response = await axios.post(url, request);
    let result = response.data as any;
    return result.result;
  };

  public static SendEmailInAddShiftInTimeClock = async (
    request: IsendEmailInAddShiftInTimeClockRequestDto
  ): Promise<any | null> => {

    let timeClockTemp = JSON.parse(localStorage.getItem("timeClockTemp")!);    
    let tenantIDnew = timeClockTemp === null ? 0 : timeClockTemp.tenantID;
    
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    let WebClockDetail: any = JSON.parse(
      localStorage.getItem("WebClockDetail")!
    );
    if (tenantID === undefined) {
      request.tenantID = WebClockDetail.tenantID;
    }
    if(tenantID===undefined || tenantID===0 || tenantID===""){
      request.tenantID= tenantIDnew;
    }
    
    const url = `${API_ROOT.backendHost}/LaborSchedule/SendEmailInAddShiftInTimeClock`;
    return axios.get(url, { params: request }).then((response) => {
      let result = response.data as any;
      return result;
    });
  };

  public static EmployeePunchIn = async (request: any): Promise<any | null> => {
    let timeClockTemp = JSON.parse(localStorage.getItem("timeClockTemp")!);
    let tenantID = timeClockTemp === null ? 0 : (timeClockTemp.tenantID as any);
    // request.Tenantid = tenantID;
    request.EnterpriseID = tenantID;
    // const url = `${API_ROOT.backendHost}/LaborSchedule/SavePunchInData`;
    // const url = `${API_ROOT.backendHost}/LaborSchedule/SavePunchDataWithPassword`;
    const url = `${API_ROOT.backendHost}/LaborSchedule/SavePunchDataWithPasswordNew`;
    const response = await axios.post(url, request);
    let result = response.data as any;

    return result;
  };

  public static EmployeePunchOut = async (
    request: any
  ): Promise<any | null> => {
    let timeClockTemp = JSON.parse(localStorage.getItem("timeClockTemp")!);
    let tenantID = timeClockTemp === null ? 0 : (timeClockTemp.tenantID as any);
    // request.Tenantid = tenantID;
    request.EnterpriseID = tenantID;
   // const url = `${API_ROOT.backendHost}/LaborSchedule/SaveEmployeePunchOut`;
    // const url = `${API_ROOT.backendHost}/LaborSchedule/SavePunchOutData`;
    // const url = `${API_ROOT.backendHost}/LaborSchedule/SavePunchDataWithPassword`;
    const url = `${API_ROOT.backendHost}/LaborSchedule/SavePunchDataWithPasswordNew`;

    const response = await axios.post(url, request);
    let result = response.data as any;

    return result;
  };

  public static getUserHotelAccess = async (
    moduleName: string
  ): Promise<any> => {
    let timeClockTemp = JSON.parse(localStorage.getItem("timeClockTemp")!);
    let tenantID = timeClockTemp === null ? 0 : (timeClockTemp.tenantID as any);
    let userUniqueID =
      timeClockTemp === null ? 0 : (timeClockTemp.user_UniqueID as any);
    let request: any = {};
    request.userUniqueID = userUniqueID;
    request.tenantID = tenantID;
    request.moduleName = moduleName;
    const url = `${API_ROOT.backendHost}/Hotel/UserHotelAccess`;
    console.log(url);
    return axios.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };
  public static validateTimeClockLoginWithGUID = async (
    GUID: string
  ): Promise<any | null> => {
    const url = `${API_ROOT.backendHost}/User/Login`;
    const response = await axios.post(url, { GUID });
    let loginResponse = response.data as any;
    return loginResponse;
  };

  public static GetLaborPositionDeptOverView = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    request.userUniqueno = storage.user_UniqueID;
    const url = `${API_ROOT.backendHost}/LaborSchedule/GetLaborScheduleDeptPosition`;
    return axios.get(url, { params: request }).then((response) => {
      let result = response.data as any;
      return result;
    });
  };

 
  public static SaveEmployeeForcePunchIn = async (
    request: ISaveEmployeeForcePunch
  ): Promise<any | null> => {
    let timeClockTemp = JSON.parse(localStorage.getItem("timeClockTemp")!);
    request.EnterpriseID = timeClockTemp === null ? 0 : timeClockTemp.tenantID;
     // const url = `${API_ROOT.backendHost}/LaborSchedule/SaveEmployeeForcePunchIn`;
    // const url = `${API_ROOT.backendHost}/LaborSchedule/SavePunchInData`;
    // const url = `${API_ROOT.backendHost}/LaborSchedule/SavePunchDataWithPassword`;
    const url = `${API_ROOT.backendHost}/LaborSchedule/SavePunchDataWithPasswordNew`;
    return Instense.post(url, request).then((response) => {
      let result = response.data as any;
      return result;
    });
  };

  public static SaveEmployeeForcePunchOut = async (
    request: ISaveEmployeeForcePunch
  ): Promise<any | null> => {
    let timeClockTemp = JSON.parse(localStorage.getItem("timeClockTemp")!);
    request.EnterpriseID = timeClockTemp === null ? 0 : timeClockTemp.tenantID;
   // const url = `${API_ROOT.backendHost}/LaborSchedule/SaveEmployeeForcePunchOut`; 
    // const url = `${API_ROOT.backendHost}/LaborSchedule/SavePunchOutData`;
    // const url = `${API_ROOT.backendHost}/LaborSchedule/SavePunchDataWithPassword`;
    const url = `${API_ROOT.backendHost}/LaborSchedule/SavePunchDataWithPasswordNew`;
    return Instense.post(url, request).then((response) => {
      let result = response.data as any;
      return result;
    });
  };

}