import Instense from "./Axios-config";
import {
  ITipDetailsTempalteRequestDto,
  ITipDetailsTempalteResponseDto,
  ITipDetailsDeptPositionRequestDto,
  ITipDetailsDeptPositionResponseDto,
  ITipsEmployeRequestDto,
  ITipsEmployeResponseDto,
  ILabourDeleteTipsRequestDto,
  // ILabourSaveTipsRequestDto,
} from "../Contracts/ILaborForecast";

export class LaborTips {
  public static GetLaborTipsData = async (
    request: ITipDetailsTempalteRequestDto
  ): Promise<ITipDetailsTempalteResponseDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let rolId = storage === null ? 0 : (storage.rolId as any);
    request.tenantID = tenantID;
    request.LoginUserRoleID = rolId;
    const url = `/LaborTipDetails/GetLaborTipsDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as ITipDetailsTempalteResponseDto;
      
      return result;
    });
  };

  public static GetLaborPositionDept = async (
    request: ITipDetailsDeptPositionRequestDto
  ): Promise<ITipDetailsDeptPositionResponseDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let user_UniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    let rolId = storage === null ? 0 : (storage.rolId as any);
    request.tenantID = tenantID;
    request.userUniqueno = user_UniqueID;
    request.LoginUserRoleID = rolId;
    
    const url = `/LaborTipDetails/GetLaborTipsFilterPosition`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as ITipDetailsDeptPositionResponseDto;
      return result;
    });
  };

  public static GetLaborEmployeeTipsData = async (
    request: ITipsEmployeRequestDto
  ): Promise<ITipsEmployeResponseDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let user_UniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    let rolId = storage === null ? 0 : (storage.rolId as any);
    request.tenantID = tenantID;
    request.userUniqueno = user_UniqueID;
    request.LoginUserRoleID = rolId;
    const url = `/LaborTipDetails/Get_LabortHidUsers`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as ITipsEmployeResponseDto;
      return result;
    });
  };

  public static deleteLaborTips = async (
    request: ILabourDeleteTipsRequestDto
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let userName = storage === null ? 0 : (storage.userName as any);
    request.Tenantid = tenantID;
    request.loginUser = userName;
    const url = `/LaborTipDetails/DeleteEmployeeTipDetails`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result;
  };

  public static saveLaborTips = async (request: any): Promise<any | null> => {
    const url = `/LaborTipDetails/SaveEmployeeTipDetails`;
    
    const response = await Instense.post(url, request);
    let result = response.data as any;
   
    return result;
  };
}
