import React from "react";
// import PropTypes from "prop-types";

interface IMoonProps {
  parentComponent: String;
}

export const MoonIcon: React.FC<IMoonProps> = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="10"
        cy="10"
        r="9"
        fill="white"
        stroke="white"
        stroke-width="2"
      />
      <path
        d="M10.1714 9.5814C8.933 8.3425 8.62982 6.5234 9.26001 5C8.3841 5.17285 7.54742 5.59843 6.86818 6.27767C5.04394 8.10192 5.04394 11.0599 6.86818 12.8842C8.69288 14.7089 11.6504 14.7084 13.4751 12.8842C14.1544 12.2049 14.5795 11.3687 14.7528 10.4928C13.229 11.1225 11.4099 10.8198 10.1714 9.5814Z"
        fill="#065A8C"
      />
    </svg>
  );
};

// MoonIcon.propTypes = {
//   parentComponent: PropTypes.string.isRequired,
// };
