import React from "react";
import { Dropdown, Form } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { resolve, reject } from "q";
import $ from "jquery";
import { ReactPageLoader } from "../../../Common/Components/ReactPageLoader";
import { PayrollProcessor } from "../../../Common/Services/PayrollProcessor";
import {
  IGetPayrollExportReciepentData,
  ISaveCodeMappingData,
} from "../../../Common/Contracts/IPayrollProcessor";
import { confirmAlert } from "react-confirm-alert";
import { Utils } from "../../../Common/Utilis";

export class PayrollExportCodeMappng extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      loaderTrue: false,
      oldTableData: [],
      tableData: [],
      activeExportName: this.props.parentState.activeExportName,
      activeExportType: this.props.parentState.activeExportType,
      exportTypeFormat: this.props.parentState.exportTypeFormat,
    };
  }

  componentDidMount() {
    this.getExportReciepentData("componentMount");
  }

  getExportReciepentData = (type: string) => {
    if (type === "componentMount" || type === "payrollExportDropdown") {
      this.setState({ loaderTrue: true });
    }

    let requestObject = {} as IGetPayrollExportReciepentData;

    requestObject.Hotelid = -1;
    requestObject.Type = this.state.activeExportType;

    PayrollProcessor.getCodeMappingData(requestObject)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          let tableData: any = [];

          result.forEach((data: any, idx: any) => {
            let tableDataObject: any = {};

            tableDataObject.id = idx;
            tableDataObject.srNo = data.srNo;
            tableDataObject.columnName =
              data.columnName === null ? "" : data.columnName;
            tableDataObject.description =
              data.description === null ? "" : data.description;
            tableDataObject.exportCode =
              data.displayCode === null ? "" : data.displayCode;
            tableDataObject.innFlowCode =
              data.innFlowCode === null ? "" : data.innFlowCode;
            tableDataObject.showAllownceCheckBox =
              data.showAllownceCheckBox === null ? "" : data.showAllownceCheckBox;
            tableDataObject.addExport = data.status === "Active" ? true : false;
            tableDataObject.addExportDisable =
              data.selectType === "Yes" ? false : true;
            tableDataObject.descValidation = false;
            tableDataObject.expCodeValidation = false;
            tableDataObject.AllowanceStatus = data.allowanceStatus === "Active" ? true : false;

            tableData.push(tableDataObject);
          });

          this.setState({ tableData, oldTableData: tableData });
        } else {
          Utils.toastError("No Data found", {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "payrollProcessorIndex",
          });
        }
        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "payrollProcessorIndex",
        });
        this.setState({ loaderTrue: false });
        reject();
      });
  };

  payrollExportSelection = (item: any) => {
    let activeExportName = item.exportName;
    let activeExportType = item.exportType;

    this.props.exportFormatChange(item);

    this.setState({ activeExportType, activeExportName }, () => {
      this.getExportReciepentData("payrollExportDropdown");
    });
  };

  handleSave = (row: any, rowIndex: any) => {
    let oldRowData = this.state.oldTableData[rowIndex];
    let newRowData = this.state.tableData[rowIndex];

    if (JSON.stringify(oldRowData) === JSON.stringify(newRowData)) {
      return false;
    } else {
      let validateResult: boolean = false;
      let validateMessage: string = "";
      let validatior: string = "";

      if (row.description === "") {
        validateResult = true;
        validateMessage = "Description can not be blank";
        validatior = "description";
      }

      if (row.exportCode === "") {
        validateResult = true;
        validateMessage = "Export Code can not be blank";
        validatior = "exportCode";
      }

      if (validateResult) {
        let newTableData = [...this.state.tableData];
        newTableData = newTableData.map((d) => {
          if (d.id === row.id) {
            if (validatior === "description") {
              return {
                ...d,
                descValidation: true,
              };
            } else if (validatior === "exportCode") {
              return {
                ...d,
                expCodeValidation: true,
              };
            } else {
              return d;
            }
          } else {
            return d;
          }
        });
        this.setState((curr: any) => ({
          ...curr,
          tableData: newTableData,
        }));

        Utils.toastError(validateMessage, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "payrollProcessorIndex",
        });
      } else {
        this.setState({ oldTableData: this.state.tableData });

        const storage = JSON.parse(localStorage.getItem("storage")!);
        const tenantID = storage === null ? 0 : (storage.tenantID as any);
        let userName = storage === null ? 0 : (storage.userName as any);

        let requestObject = {} as ISaveCodeMappingData;

        requestObject.Hid = -1;
        requestObject.TenantID = tenantID;
        requestObject.Description = row.description;
        requestObject.ExportCode = row.exportCode;
        requestObject.SrNo = row.srNo;
        requestObject.ExportFormat = this.state.activeExportType;
        requestObject.Status = row.addExport ? "Active" : "Inactive";
        requestObject.allowanceStatus = row.AllowanceStatus ? "Active" : "Inactive";
        requestObject.loginUser = userName;

        PayrollProcessor.saveCodeMappingData(requestObject)
          .then(async (result: any | null) => {
            if (result.saveStatus === "Success") {
              toast.success("Settings Saved Successfully", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "payrollProcessorIndex",
              });
            } else {
              Utils.toastError("Save Error, please try again", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "payrollProcessorIndex",
              });
            }
            resolve();
          })
          .catch((err: any) => {
            Utils.toastError(`Server Error, ${err}`, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "payrollProcessorIndex",
            });
            reject();
          });
      }
    }
  };

  onDescriptionChange = (e: any, row: any, rowIndex: any) => {
    let inputId = `${rowIndex}description`;

    let value = e.target.value;
    let cursorIndex = e.target.selectionStart;

    let descriptionRegex = /^[A-Za-z0-9\-_() ]+$/;

    if (descriptionRegex.test(value) === false) {
      value = value.replace(/[^A-Za-z0-9\-_() ]/g, "");
    }
    if (value.length > 100) {
      let a = value.replace(/[^A-Za-z0-9\-_() ]/g, "");
      value = a.substring(0, value.length - 1);
    }

    let newTableData = [...this.state.tableData];
    newTableData = newTableData.map((d) => {
      if (d.id === row.id) {
        return {
          ...d,
          description: value,
          descValidation: false,
        };
      } else {
        return d;
      }
    });
    this.setState(
      (curr: any) => ({
        ...curr,
        tableData: newTableData,
      }),
      () => {
        $("#" + inputId)
          .focus()
          .prop("selectionEnd", cursorIndex);
      }
    );
  };

  onDescriptionBlur = (e: any, row: any, rowIndex: any) => {
    let value = e.target.value;
    value = value.trim();
    value = value.replace(/\s\s+/g, " ");

    let newTableData = [...this.state.tableData];
    newTableData = newTableData.map((d) => {
      if (d.id === row.id) {
        return {
          ...d,
          description: value,
        };
      } else {
        return d;
      }
    });
    this.setState(
      (curr: any) => ({
        ...curr,
        tableData: newTableData,
      }),
      () => {
        this.handleSave(newTableData[rowIndex], rowIndex);
      }
    );
  };

  onExportCodeChange = (e: any, row: any, rowIndex: any) => {
    let inputId = `${rowIndex}exportCode`;
    let value = e.target.value;
    let cursorIndex = e.target.selectionStart;

    let exportCodeRegex = /^[A-Za-z0-9\-_() ]+$/;

    if (exportCodeRegex.test(value) === false) {
      value = value.replace(/[^A-Za-z0-9\-_() ]/g, "");
    }
    if (value.length > 20) {
      let a = value.replace(/[^A-Za-z0-9\-_() ]/g, "");
      value = a.substring(0, value.length - 1);
    }

    let newTableData = [...this.state.tableData];
    newTableData = newTableData.map((d) => {
      if (d.id === row.id) {
        return {
          ...d,
          exportCode: value,
          expCodeValidation: false,
        };
      } else {
        return d;
      }
    });
    this.setState(
      (curr: any) => ({
        ...curr,
        tableData: newTableData,
      }),
      () => {
        $("#" + inputId)
          .focus()
          .prop("selectionEnd", cursorIndex);
      }
    );
  };

  onExportCodeBlur = (e: any, row: any, rowIndex: any) => {
    let value = e.target.value;
    value = value.trim();
    value = value.replace(/\s\s+/g, " ");

    let newTableData = [...this.state.tableData];
    newTableData = newTableData.map((d) => {
      if (d.id === row.id) {
        return {
          ...d,
          exportCode: value,
        };
      } else {
        return d;
      }
    });
    this.setState(
      (curr: any) => ({
        ...curr,
        tableData: newTableData,
      }),
      () => {
        this.handleSave(newTableData[rowIndex], rowIndex);
      }
    );
  };

  addExportChange = (e: any, row: any, rowIndex: any) => {
    let value = e.target.checked;

    let newTableData = [...this.state.tableData];
    newTableData = newTableData.map((d) => {
      if (d.id === row.id) {
        return {
          ...d,
          addExport: value,
        };
      } else {
        return d;
      }
    });
    this.setState(
      (curr: any) => ({
        ...curr,
        tableData: newTableData,
      }),
      () => {
        this.handleSave(newTableData[rowIndex], rowIndex);
      }
    );
  };

  confirmationAllowanceDeSelect=(e: any, row: any, rowIndex: any)=>{
 
    PayrollProcessor.isAllowanceExistForUser(row?.srNo).then(res=>{
    
      if(res)
      {
        //Add API to check if allowance is exist in any user
        confirmAlert({
          title: "Unsaved confirmation?",
          message:
            "You have one or more employees who are currently using this allowance. Continuing will deselect the allowance for all employees. Are you sure you want to move forward? Allowances can also be edited on the specific user’s page.",
          buttons: [
            {
              label: "Yes",
              onClick: () => this.addAllowanceChange({target:{checked:false}},row,rowIndex),
            },
            {
              label: "No",
              onClick: () => null
            },
          ],
          closeOnEscape: false,
          closeOnClickOutside: false,
        });
      }
      else{
        this.addAllowanceChange({target:{checked:false}},row,rowIndex)
      }
       
    }).catch(err =>{

    })

    
  }


  addAllowanceChange = (e: any, row: any, rowIndex: any) => {
    debugger;
    let value = e.target.checked;

    let newTableData = [...this.state.tableData];
    newTableData = newTableData.map((d) => {
      if (d.id === row.id) {
        return {
          ...d,
          AllowanceStatus: value,
        };
      } else {
        return d;
      }
    });
    this.setState(
      (curr: any) => ({
        ...curr,
        tableData: newTableData,
      }),
      () => {
        this.handleSave(newTableData[rowIndex], rowIndex);
      }
    );
  };

  ehidFormatter = (cell: any, row: any, rowIndex: any) => {
    return <div className="ehid-formatter">{row.innFlowCode}</div>;
  };

  descriptionFormatter = (cell: any, row: any, rowIndex: any) => {
    return (
      <div className="description-formatter">
        <Form.Group>
          <Form.Control
            disabled={
              !this.props.parentState.permissionManageSettings ||
              row.addExportDisable ||
              this.state.activeExportType === "INN-FLOW"
            }
            className={row.descValidation ? "alert-danger" : ""}
            id={`${rowIndex}description`}
            placeholder="-"
            value={row.description}
            onChange={(e: any) => this.onDescriptionChange(e, row, rowIndex)}
            onBlur={(e: any) => this.onDescriptionBlur(e, row, rowIndex)}
          />
        </Form.Group>
      </div>
    );
  };

  exportCode = (cell: any, row: any, rowIndex: any) => {
    return (
      <div className="exportCode-formatter">
        <Form.Group>
          <Form.Control
            disabled={
              !this.props.parentState.permissionManageSettings ||
              this.state.activeExportType === "INN-FLOW"
            }
            className={row.expCodeValidation ? "alert-danger" : ""}
            id={`${rowIndex}exportCode`}
            placeholder="-"
            value={row.exportCode}
            onChange={(e: any) => this.onExportCodeChange(e, row, rowIndex)}
            onBlur={(e: any) => this.onExportCodeBlur(e, row, rowIndex)}
          />
        </Form.Group>
      </div>
    );
  };

  addExportFormatter = (cell: any, row: any, rowIndex: any) => {
    return (
      <div className="companyCode-formatter">
        <Form.Check
          disabled={
            !this.props.parentState.permissionManageSettings ||
            row.addExportDisable
          }
          type="checkbox"
          label=""
          checked={row.addExport}
          onChange={(e: any) => {
            this.addExportChange(e, row, rowIndex);
          }}
        />
      </div>
    );
  };

  addAllowanceFormatter = (cell: any, row: any, rowIndex: any) => {
    return (
      <>
    {row.showAllownceCheckBox !=="No" && (
      <div className="companyCode-formatter">
      <Form.Check
        // disabled={
        //   !this.props.parentState.permissionManageSettings
        // }
        type="checkbox"
        label=""
       checked={row.AllowanceStatus}
        onChange={(e: any) => {
          if(row?.AllowanceStatus)
          {
            this.confirmationAllowanceDeSelect(e, row, rowIndex)
          }
          else{
            this.addAllowanceChange(e, row, rowIndex);
          }
         
        }}
      />
    </div>
    )}
      </>
    )
  };

  render() {
    const columns = [
      {
        dataField: "innFlowCode",
        text: "Inn-Flow Code",
        editable: false,
        formatter: this.ehidFormatter,
      },
      {
        dataField: "description",
        text: "Description",
        editable: false,
        formatter: this.descriptionFormatter,
      },
      {
        dataField: "exportCode",
        text: "Export Code",
        editable: false,
        formatter: this.exportCode,
      },
      {
        dataField: "addExport",
        text: "Add to Export",
        editable: false,
        formatter: this.addExportFormatter,
      },
      {
        dataField: "AllowanceStatus",
        text: "Allowance",
        editable: false,
         formatter: this.addAllowanceFormatter,
       
      },
    ];

    return (
      <>
        {this.state.loaderTrue ? (
          <ReactPageLoader useas={"payrollProcessorCodeMapping"} />
        ) : (
          <div className="export-code-mapping">
            <div className="formatSelector d-flex">
              <span className="format-label">Select Export Format</span>
              <Dropdown className="format-select">
                <Dropdown.Toggle id="dropdown-hid">
                  {this.state.activeExportName}
                </Dropdown.Toggle>
                <Dropdown.Menu className="format-selector-menu">
                  {this.state.exportTypeFormat.map((item: any, idx: any) => (
                    <Dropdown.Item
                      key={idx}
                      onClick={() => {
                        this.payrollExportSelection(item);
                      }}
                    >
                      {item.exportName}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div className="export-code-table">
              <BootstrapTable
                id="tablePayrollProcessorExportCode"
                keyField="id"
                data={this.state.tableData}
                columns={columns}
                hover
              />
            </div>
          </div>
        )}
      </>
    );
  }
}
