import * as React from "react";
import { Row, Col, Button, Card, Spinner, Form, Modal } from "react-bootstrap";
import { User } from "../Common/Services/User";
import { Link, Redirect } from "react-router-dom";
import "./login.scss";
import logo from "../Common/Assets/Images/logo-brand.png";
import marketingSlider from "../Common/Assets/Images/marketing-slider.png";
import logoMaintenance from "../Common/Assets/Images/logo_maintenance.png";
import underMaintenance from "../Common/Assets/Images/underMaintenance.png";
import { IUser } from "../Common/Contracts/IUser";
import { resolve, reject } from "q";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faTimes, faSave } from "@fortawesome/free-solid-svg-icons";
import { Cookies } from "react-cookie";
import { WebTimeClockService } from "../Common/Services/WebTimeClock";
import { ToastContainer, toast } from "react-toastify";
import { FreshDeskChat } from "../Common/ThirdPartyServices/FreshDeskChat";
import { Utils } from "../Common/Utilis";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";
import { HeaderMenu } from "../Common/Services/HeaderMenu";
import maintenance from "../Common/Assets/Images/maintenance.png";
import createDOMPurify from "dompurify";
import KnowledgeBaseLoader from "./KnowledgeBaseLoader";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { API_ROOT } from "../Common/Services/Api-config";
import { GeneralHub } from "../Common/SignalR/Hubs/GeneralHub";
import EventEmitter from "reactjs-eventemitter";
import { Actions } from "../Common/SignalR/Actions/Actions";


const DOMPurify = createDOMPurify(window);
const cookies = new Cookies();
class LoginComponent extends React.Component<any, any> {
  formDefaults = {
    userNameVal: { value: "", isValid: true, message: "" },
    userPwd: { value: "", isValid: true, message: "" },
  };
  constructor(props: any) {
    super(props);
    this.state = {
      needhelp: true,
      isDeeplink: false,
      deeplink: "/home",
      isLoading: false,
      inError: false,
      user_UniqueID: "0",
      userName: "",
      submitted: false,
      errorMessage: "",
      isCaptcha: false,
      captchaLoginButton: false,
      isBlocked: false,
      isValidateLogin: false,
      loginButtonSpinner: false,
      isShowinvalidCaptcha: 1,
      isMarketing: true,
      isMerge: "",
      isLaborModule: "",
      currentUtcTime: "",
      mergeURL: "",
      unmergeURL: "",
      mergeToken: "",
      ...this.formDefaults,
      //Modal
      modalShow: false,
      modalHelpShow: false,
      primaryInput: { email: "", mobile: "" },
      mobileReq: { required: false, message: "optional", style: "" },
      emailReq: { required: true, message: "Required", style: "text-danger" },
      primaryInputError: { mobileError: "", emailError: "" },
      modalValue: {
        username: "",
        tenantid: "",
        email: "",
        phone: "",
        IsEmail: "",
      },
      usId: { username: "", tenantid: "", uniqueid: "" },
      modalValueusId: {
        username: "",
        tenantid: "",
        email: "",
        phone: "",
        IsEmail: "",
      },
      primarymode: [
        { isPrimary: false, value: "email", label: "Email address" },
        { isPrimary: true, value: "mobile", label: "Mobile number" },
      ],
      notPrimaryError: "",
      isEmail: 1,
      isCookiePresent: false,
      currentDomain: window.location.hostname,
      isMaintenance: 1,
      showIncompleteSlideOut: false,
      client_id: "",
      idleLogOutMessage: "",
      showAlert: false,
      maintainenceTime: "",
      serverCurrentTime: "",
      isLogOutFlag: 0,
      releaseProtocolBannerMsg: "",
      intervalId: -1,
      webclock: false,
    };
  }
  showAlertMessage = () => {
    confirmAlert({
      // title: "title",
      message: `To enjoy our website's full features, please disable your pop-up blocker. To do this, click on the Settings menu of your web browser and find the pop-up blocker option. Then, select 'Always allow pop-ups from this site' or add our website to the allowed list. Thank you for visiting our website!`,
      buttons: [
        {
          label: "OK",
          onClick: () => {
            window.close();
          },
        },
        // {
        //   label: "No",
        //   onClick: () => { },
        // },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  componentDidMount() {
    const cookies = document.cookie
      .split(";")
      .filter((c) => c.trim().split("=")[0]);
    // const containsCookie = cookies.some((cookie) => cookie.toLowerCase().includes("_fw_crm_v"));
    const containsCookie = cookies.some((cookie) =>
      cookie.includes("WebTimeClock")
    );
    if (containsCookie === true) {
      this.setState({ webclock: true });
    }
    //window.history.replaceState(null, '');
    GeneralHub.connectAndOperateHub();
    this.recieveNotifyUsersAboutSiteDownData();
    // let intervalId = setInterval(() => {
    this.getInnflowMaintenanceBanner();
    // }, 300000);
    // this.setState({intervalId: intervalId});
    FreshDeskChat.hideFreshDesk();
    //FreshDeskChat.showFreshDesk();
    this.isUnderMaintenance();
    let deepParam = String(localStorage.getItem("deepParam"));
    let deepURL = String(localStorage.getItem("deepURL"));
    /////////////Stop redirection For DrillDown////////
    if (
      deepURL === "/Report/ReportDrilldown" ||
      deepURL === "/Report/ReportDrilldownmultiCOA"
    ) {
      deepParam = "";
      deepURL = "/";
    }
    /////////////Stop redirection For DrillDown////////
    let lastFreshdeskUrl = Utils.getCookie("lastFreshdeskUrl");
    this.setState({ lastFreshdeskUrl });
    if (deepParam.includes("?")) {
      this.setState({ isDeeplink: true, deeplink: deepURL + deepParam });
    } else {
      this.setState({ isDeeplink: false, deeplink: "/home" });
    }
    //localStorage.removeItem("WebClockDetail");
    const paramObject = this.getURLParamsValues();
    if (
      this.state.isMaintenance === 1 &&
      this.state.client_id == "" &&
      paramObject?.isRedirectFromFreshDesk != 1
    ) {
      this.freshDeskOperation();

      if (paramObject?.client_id) {
        this.setState({ client_id: paramObject.client_id });
      }

      var pop = window.open(
        "https://www.google.com",
        "windowname",
        "toolbar=no,location=no,status=no,menubar=no,scrollbars=no,width=1,height=1,left=5,top=3"
      );
      if (pop == null) {
        this.setState({ showAlert: true });
        // alert("Popups must be enabled.");
      } else {
        this.setState({ showAlert: false });
        // alert("Popups is disabled.");
        pop && pop.close();
        if (
          paramObject?.client_id &&
          paramObject?.isRedirectFromFreshDesk != 1
        ) {
          const userdetail = JSON.parse(localStorage.getItem("storage") || "");
          const token = localStorage.getItem("token");
          this.autoLogin(
            Utils.getCookie("userName") || userdetail.userName,
            Utils.getCookie("token") || token,
            paramObject
          );

          return;
        }
      }
    }

    if (localStorage.getItem("logOutFromIdlePopUp")) {
      localStorage.removeItem("logOutFromIdlePopUp");

      this.setState({
        idleLogOutMessage: "You have been logged out due to inactivity",
      });
    }
    // localStorage.clear();
    // sessionStorage.clear();
  }

  componentWillUnmount() {
    // console.log("Login counter stopped with id " + this.state.intervalId);
    // clearInterval(this.state.intervalId);
  }

  recieveNotifyUsersAboutSiteDownData = () => {
    EventEmitter.subscribe(
      Actions.GeneralHubActions.SITE_DOWN_NOTIFICATION,
      (response) => {
        const data = response?.data;
        if (data) {
          this.setMaintenanceBannerData(data);
        }
      }
    );
  };
  setMaintenanceBannerData = (result) => {
    if (result !== null && result.length > 0) {
      // debugger;
      this.setState({
        releaseProtocolBannerMsg: result[0].message,
        maintainenceTime: result[0].startDateTime, //"2023-08-19T10:16:03.94",
        serverCurrentTime: result[0].currentDateTime, //"2023-08-19T10:15:02.93",
        isLogOutFlag: result[0].logOutFlag, //1
      });
    } else {
      this.setState({
        isLogOutFlag: 0,
      });
    }
  };

  getInnflowMaintenanceBanner = () => {
    HeaderMenu.InnflowMaintenanceBanner()
      .then(async (result: any | null) => {
        if (result !== null && result?.length > 0) {
          // debugger;
          this.setState({
            releaseProtocolBannerMsg: result[0].message,
            maintainenceTime: result[0].startDateTime, //"2023-08-19T10:16:03.94",
            serverCurrentTime: result[0].currentDateTime, //"2023-08-19T10:15:02.93",
            isLogOutFlag: result[0].logOutFlag, //1
          });
        } else {
          this.setState({
            isLogOutFlag: 0,
          });
        }
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`);
      });
  };

  getURLParamsValues = (): any => {
    const paramObject: any = {
      response_type: this.getParamValueByParamName("response_type"),
      client_id: this.getParamValueByParamName("client_id"),
      scope: this.getParamValueByParamName("scope"),
      state: this.getParamValueByParamName("state"),
      redirect_uri: this.getParamValueByParamName("redirect_uri"),
      nonce: this.getParamValueByParamName("nonce"),
      isRedirectFromFreshDesk:
        this.getParamValueByParamName("isRedirectFromFreshDesk") ||
        (this.getParamValueByParamName("client_id") &&
        !Utils.getCookie("userName")
          ? 1
          : ""),
    };
    return paramObject;
  };

  freshDeskOperation = (): any => {
    const paramObject: any = this.getURLParamsValues();
    if (paramObject.client_id) {
      this.setState({ client_id: paramObject.client_id });
      const userdetail = JSON.parse(localStorage.getItem("storage") || "");
      if (Utils.getCookie("userName") || userdetail?.userName) {
        const token = localStorage.getItem("token");
        this.autoLogin(
          Utils.getCookie("userName") || userdetail.userName,
          Utils.getCookie("token") || token,
          paramObject
        );
      } else {
        const queryString = new URLSearchParams(paramObject).toString();

        this.props?.history.push(
          "/login?isRedirectFromFreshDesk=1&" + queryString
        );
      }
    }
  };

  autoLogin(userName, token, paramObject) {
    User.AutoLogin(userName, token, paramObject.nonce)
      .then(async (response: any) => {
        let result = response.user;
        if (result) {
          this.setState({
            errorMessage: "",
            isBlocked: false,
            isCaptcha: false,
            captchaLoginButton: false,
            usId: {
              username: result.userName,
              tenantid: result.tenantID,
              uniqueid: result.user_UniqueID,
            },
          });

          let errorMessage = this.state.errorMessage;
          this.letModalVal();
          if (result.user_UniqueID === 0) {
            errorMessage = "Please enter the valid username/password.";
            if (this.state.isShowinvalidCaptcha === 2) {
              this.setState({ isBlocked: false, isCaptcha: true });
            } else {
              this.setState({
                isShowinvalidCaptcha: this.state.isShowinvalidCaptcha + 1,
              });
            }
            this.setState({
              errorMessage: errorMessage,
              loginButtonSpinner: false,
            });
          } else {
            localStorage.setItem(
              "showIncompleteSlideOut",
              result?.showIncompleteSlideOut?.toString()
            );
            localStorage?.setItem("PopUpState", "");

            this.setState({
              userName: result.userName,
              isMerge: result.isMerge,
              isLaborModule: result.isLaborModule,
              currentUtcTime: result.currentUtcTime,
              mergeURL: result.mergeURL,
              unmergeURL: result.unmergeURL,
              mergeToken: result.mergeToken,
              user_UniqueID: result.user_UniqueID,
              isValidateLogin: true,
              loginButtonSpinner: false,
              showIncompleteSlideOut: result.showIncompleteSlideOut,
              isDuplicatePhone: result.isDuplicatePhone,
              isDuplicateEmail: result.isDuplicateEmail,
              primaryMethod: result.primaryMethod,
            });

            if (paramObject?.client_id) {
              localStorage.setItem(
                "expirationTimeForFreshDesk",
                response?.expirationTime
              );
              Utils.setCookie(
                "expirationTimeForFreshDesk",
                response?.expirationTime,
                1
              );
              // Utils.setCookie("tokenForFreshDesk", response.token ,1);
              let url =
                paramObject.redirect_uri +
                "?state=" +
                paramObject.state +
                "&id_token=" +
                response.token; //Utils.getCookie("token");
              var win = window.open(
                url,
                "windowname",
                "toolbar=no,location=no,status=no,menubar=no,scrollbars=no,width=1,height=1,left=5,top=3"
              );

              Utils.setCookie("userName", "", 0);
              Utils.setCookie("token", "", 0);
              Utils.setCookie("lastFreshdeskUrl", "", 0);
              User.UpdateLastClickedFreshDeskUrl("").then((result) => {
                console.log(result);
              });
              setTimeout(() => {
                let faqUrl =
                  this.state.lastFreshdeskUrl ||
                  (result as any)?.lastClickedFreshdeskUrl;
                if (!this.state.showAlert && faqUrl != null) {
                  window.location.replace(faqUrl);
                  win?.close();
                }
              }, 10000);

              return;
            }
            resolve();
          }

          this.setState({
            isShowinvalidCaptcha: 1,
            loginButtonSpinner: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ loginButtonSpinner: false });
        Utils.toastError(`Server Error: ${error}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "Login",
        });
        reject();
      });
  }

  getParamValueByParamName(paramName: string): any {
    const valuesArray = decodeURIComponent(this.props.history.location.search)
      ?.replace("?", "")
      ?.split("&");
    const item = valuesArray?.find((r) => r?.includes(paramName));
    return item ? (item?.split("=")[1] as any) : "";
  }

  isUnderMaintenance() {
    User.UnderMaintenance().then(async (result: any | null) => {
      if (result.message === "success") {
        this.setState({ isMaintenance: result.result });
        if (result.result === 1) {
          window.location.href = window.location.origin + "/Under-Maintenance";
          return false;
        }
      }
    });
  }

  handleClose = () => {
    this.setState({ modalShow: false, notPrimaryError: "" });
  };
  handleHelpShow = () => {
    this.setState({ modalHelpShow: true });
  };
  handleCloseHelp = () => {
    this.setState({ modalHelpShow: false });
  };

  isPrimaryEmail = () => {
    this.setState({
      isEmail: 1,
      primarymode: [
        { isPrimary: true, value: "email", label: "Email address" },
        { isPrimary: false, value: "mobile", label: "Mobile number" },
      ],
      emailReq: { required: true, message: "Required", style: "text-danger" },
      mobileReq: { required: false, message: "optional", style: "" },
    });
  };

  isPrimaryMobile = () => {
    this.setState({
      isEmail: 0,
      primarymode: [
        { isPrimary: false, value: "email", label: "Email address" },
        { isPrimary: true, value: "mobile", label: "Mobile number" },
      ],
      mobileReq: { required: true, message: "Required", style: "text-danger" },
      emailReq: { required: false, message: "optional", style: "" },
    });
  };

  setPrimaryContact = (e: any) => {
    this.setState({ notPrimaryError: "" });
    if (e.target.value === "email") {
      this.isPrimaryEmail();
    } else {
      this.isPrimaryMobile();
    }
  };

  letModalVal = () => {
    let { primaryInput, emailReq, mobileReq, usId, isEmail } = this.state;
    if (emailReq.required) {
      this.setState({ isEmail: 1 });
    }
    if (mobileReq.required) {
      this.setState({ isEmail: 0 });
    }
    this.setState({
      modalValue: {
        username: usId.username,
        tenantid: usId.tenantid,
        user_UniqueID: usId.uniqueid,
        email: primaryInput.email || "",
        phone: primaryInput.mobile || "",
        IsEmail: isEmail,
      },
      primaryInput,
    });
  };

  piChange = (e: any) => {
    let { name, value } = e.target;
    let { primaryInput } = this.state;
    name === "mobile"
      ? (primaryInput[name] = value.replace(/\D/, ""))
      : (primaryInput[name] = value);
    this.letModalVal();
  };

  submitForm = (e: any) => {
    e.preventDefault();
    this.setState({ notPrimaryError: "Please Wait" });
    let { modalValue, primaryInput, isEmail } = this.state;
    User.UpdateUserValidateStatus(
      modalValue.username,
      "",
      modalValue.tenantid,
      primaryInput.email,
      primaryInput.mobile,
      isEmail,
      "",
      "loginModal"
    ).then(async (userResTo: any | null) => {
      this.setState({ notPrimaryError: "Please Wait" });
      if (userResTo.result.rVal === "1") {
        this.setState({ notPrimaryError: "" });
        this.setState({ modalShow: false });
        this.setState({
          user_UniqueID: modalValue.uniqueid,
          isValidateLogin: true,
        });
      } else {
        this.setState({
          primaryInput: {
            email: userResTo.result.email,
            mobile: userResTo.result.phone,
          },
        });
        this.setState({ modalShow: true });
        this.setState({
          notPrimaryError: `Please provide unique 
          ${!userResTo.result.email ? "Email Address" : ""} 
          ${!userResTo.result.email && !userResTo.result.phone ? " and " : ""}
          ${!userResTo.result.phone ? "Mobile Number" : ""}`,
        });
      }
    });
  };

  handleSubmit = (e: any) => {
    // debugger;
    e.preventDefault();
    this.setState({ submitted: true, errorMessage: "" });
    const form = e.target;

    const userName = form.elements.userNameVal.value;
    const password = form.elements.userPwd.value;
    let new_state = Object.assign({}, this.state);
    e.target.className += " was-validated";
    let userNameVal = new_state.userNameVal;
    let userPwd = new_state.userPwd;
    if (userName === "" || password === "") {
      if (userName === "") {
        userNameVal.isValid = false;
        userNameVal.message = "Username is required";
      }
      if (password === "") {
        userPwd.isValid = false;
        userPwd.message = "Password is required";
      }
    } else {
      // debugger;
      this.setState({ loginButtonSpinner: true });
      let ssoparam = this.getURLParamsValues();
      User.loginNew(
        userName,
        password,
        "LoginPage",
        ssoparam?.nonce,
        ssoparam?.client_id ? true : false
      )
        .then(async (result: IUser | null) => {
          //IUser

          if (result) {
            // debugger;
            this.setState({
              errorMessage: "",
              isBlocked: false,
              isCaptcha: false,
              captchaLoginButton: false,
              usId: {
                username: result.userName,
                tenantid: result.tenantID,
                uniqueid: result.user_UniqueID,
              },
            });

            let errorMessage = this.state.errorMessage;
            this.letModalVal();
            if (result.user_UniqueID === 0) {
              errorMessage = "Please enter the valid username/password.";
              if (this.state.isShowinvalidCaptcha === 2) {
                this.setState({ isBlocked: false, isCaptcha: true });
              } else {
                this.setState({
                  isShowinvalidCaptcha: this.state.isShowinvalidCaptcha + 1,
                });
              }
              this.setState({
                errorMessage: errorMessage,
                loginButtonSpinner: false,
              });
            } else {
              if (result.message === "success") {
                User.ValidateUserStatus(
                  result.userName,
                  result.tenantID,
                  "loginModal"
                )
                  .then((userRes: any | null) => {
                    // if (
                    //   result.user_UniqueID > 0 &&
                    //   (userRes.result.rVal === null ||
                    //     userRes.result.rVal === "0")
                    // ) {
                    //   userRes.result.primaryContact === "Email"
                    //     ? this.isPrimaryEmail()
                    //     : this.isPrimaryMobile();
                    //   this.setState({
                    //     modalShow: true,
                    //     primaryInput: {
                    //       email: userRes.result.email,
                    //       mobile: userRes.result.phone,
                    //     },
                    //   });
                    // } else {
                    localStorage.setItem(
                      "showIncompleteSlideOut",
                      result?.showIncompleteSlideOut?.toString()
                    );
                    localStorage?.setItem("PopUpState", "");

                    if (
                      ssoparam?.client_id &&
                      ssoparam?.isRedirectFromFreshDesk == 1
                    ) {
                      localStorage.setItem(
                        "expirationTimeForFreshDesk",
                        localStorage.getItem("expirationTime") || ""
                      );

                      Utils.setCookie(
                        "expirationTimeForFreshDesk",
                        localStorage.getItem("expirationTime"),
                        1
                      );
                      // Utils.setCookie("tokenForFreshDesk", response.token ,1);
                      let url =
                        ssoparam.redirect_uri +
                        "?state=" +
                        ssoparam.state +
                        "&id_token=" +
                        localStorage.getItem("token"); //Utils.getCookie("token");
                      let faqUrl = (result as any)?.lastClickedFreshdeskUrl;
                      User.UpdateLastClickedFreshDeskUrl("").then((result) => {
                        console.log(result);
                      });
                      Utils.setCookie("userName", "", 0);
                      Utils.setCookie("token", "", 0);
                      Utils.setCookie("lastFreshdeskUrl", "", 0);
                      if (!faqUrl) {
                        window.location.replace(url);
                      } else {
                        var win = window.open(
                          url,
                          "windowname",
                          "toolbar=no,location=no,status=no,menubar=no,scrollbars=no,width=1,height=1,left=5,top=3"
                        );

                        setTimeout(() => {
                          if (!this.state.showAlert && faqUrl != null) {
                            window.location.replace(faqUrl);
                            win?.close();
                          }
                        }, 10000);
                      }

                      return;
                      resolve();
                    }

                    this.setState({
                      userName: result.userName,
                      isMerge: result.isMerge,
                      isLaborModule: result.isLaborModule,
                      currentUtcTime: result.currentUtcTime,
                      mergeURL: result.mergeURL,
                      unmergeURL: result.unmergeURL,
                      mergeToken: result.mergeToken,
                      user_UniqueID: result.user_UniqueID,
                      isValidateLogin: true,
                      loginButtonSpinner: false,
                      showIncompleteSlideOut: result.showIncompleteSlideOut,
                      isDuplicatePhone: result.isDuplicatePhone,
                      isDuplicateEmail: result.isDuplicateEmail,
                      primaryMethod: result.primaryMethod,
                    });
                    //}
                    resolve();
                  })
                  .catch((error) => {
                    this.setState({ loginButtonSpinner: false });
                    Utils.toastError(`Server Error: ${error}`, {
                      position: toast.POSITION.BOTTOM_RIGHT,
                      containerId: "Login",
                    });
                    reject();
                  });
              } else if (
                result.message ===
                "You are not allowed to log in. Please contact your system administrator for more information."
              ) {
                this.setState({
                  user_UniqueID: result.user_UniqueID,
                  loginButtonSpinner: false,
                  errorMessage:
                    "You do not have access to any hotels. Please contact your administrator for assistance.",
                });
              } else if (result.message === "Password expired") {
                this.setState({
                  user_UniqueID: result.user_UniqueID,
                  loginButtonSpinner: false,
                  errorMessage:
                    "Password expired. Please click Forgot your password link to reset it.",
                });
              } else {
                //let wrongPwdMsg ="Wrong password. Please try again or click Forgot your password link to reset it.";

                let wrongPwdMsg = "Please enter the valid username/password";
                if (result.lockDay > 1) {
                  this.setState({ isCaptcha: true });
                }
                this.setState({ errorMessage: wrongPwdMsg });
                let isLockedStatus = "No";
                if (result.isManualLocked === "True") {
                  isLockedStatus = "Yes";
                  this.setState({
                    isBlocked: true,
                    errorMessage:
                      "Your account has been locked please contact Support/Administrator.",
                  });
                }
                if (isLockedStatus === "No") {
                  if (result.isLocked === "Yes") {
                    this.setState({
                      isBlocked: true,
                      errorMessage:
                        "Your account has been locked due to 5 invalid attempts. Please try again after 15 minutes or contact Support/Administrator.",
                    });
                  }
                }
              }
              this.setState({
                isShowinvalidCaptcha: 1,
                loginButtonSpinner: false,
              });
            }
          } else {
            let errorMessage = this.state.errorMessage;
            errorMessage = "Please enter the valid username/password.";
            if (this.state.isShowinvalidCaptcha === 2) {
              this.setState({ isBlocked: false, isCaptcha: true });
            } else {
              this.setState({
                isShowinvalidCaptcha: this.state.isShowinvalidCaptcha + 1,
              });
            }
            this.setState({
              errorMessage: errorMessage,
              loginButtonSpinner: false,
            });
          }
          resolve();
        })
        .catch((error) => {
          this.setState({ loginButtonSpinner: false });
          Utils.toastError(`Server Error: ${error}`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "Login",
          });
          reject();
        });
    }
  };

  handleChange = (e: any) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    if (name === "userNameVal") {
      this.setState({
        errorMessage: "",
        isBlocked: false,
      });
    } else {
      if (value === "") {
        this.setState({ errorMessage: "" });
      }
    }
    this.setState({
      [e.target.name]: { value: e.target.value, isValid: !!e.target.value },
    });
  };

  requestADemo = () => {
    window.open("https://www.inn-flow.com/request-a-demo/", "_blank");
  };

  verifyWithGUID = () => {
    let found = document.cookie
      .split(";")
      .filter((c) => c.trim().split("=")[0] === "WebTimeClock");
    let cookieValue = found.length > 0 ? found[0].split("=")[1] : "";

    if (cookieValue === undefined) cookieValue = "";
    let currentURL = window.location.origin;
    if (cookieValue !== "") {
      WebTimeClockService.validateTimeClockLoginWithGUID(cookieValue).then(
        async (result: any | null) => {
          if (result.success === true) {
            if (result.user.isMerge.toLowerCase() === "yes") {
              if (result.user.cookieStatus.toLowerCase() === "active") {
                localStorage.setItem(
                  "WebClockDetail",
                  JSON.stringify(result.user)
                );
                window.location.href = currentURL + "/tc";
              } else {
                Utils.toastError("Web time clock inactive.", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  containerId: "TimeClockSetting",
                });
                return;
              }
            } else {
              window.location.href =
                result.user.unmergeURL.split(/\/(?=[^\/]+$)/)[0] +
                "/tc/TimeClockT1.aspx?company=" +
                ///"https://v0.inn-flow.net/tc/TimeClockT1.aspx?company=" +
                result.tenantID +
                "&UserName=" +
                this.state.userNameVal.value +
                "";
            }
          } else {
            cookies.remove("WebTimeClock", {
              path: "/",
              domain: this.state.currentDomain,
            });
            window.location.href = currentURL + "/login";
          }
        }
      );
    } else {
      if (this.state.userNameVal.value === "") {
        Utils.toastError("Please enter username.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "TimeClockSetting",
        });
        return;
      } else {
        User.login(this.state.userNameVal.value.trim(), "", "").then(
          async (result: any | null) => {
            if (result != null) {
              if (result.isMerge.toLowerCase() === "no") {
                window.location.href =
                  result.unmergeURL.split(/\/(?=[^\/]+$)/)[0] +
                  "/tc/TimeClockT1.aspx?company=" +
                  result.tenantID +
                  "&UserName=" +
                  this.state.userNameVal.value +
                  "";
              } else {
                Utils.toastError("Please create a web time clock.", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  containerId: "TimeClockSetting",
                });
                return;
              }
            }
          }
        );
      }
    }
  };

  closeSlideOut = () => {};

  render() {
    const {
      userNameVal,
      userPwd,
      isMarketing,
      mobileReq,
      primaryInputError,
      modalShow,
      modalHelpShow,
      primaryInput,
      primarymode,
    } = this.state;
    let ssoparam = this.getURLParamsValues();
    let Marketcss: any, loginMD: any, loginSpan: any, loginOffset: any;

    if (isMarketing) {
      loginMD = 7;
      loginSpan = 8;
      loginOffset = 2;
      Marketcss = "base-blue";
    } else {
      loginMD = 12;
      loginSpan = 4;
      loginOffset = 4;
      Marketcss = "base-blue d-none";
    }

    if (this.state.isValidateLogin) {
      let userName = this.state.userName;
      let mergeURL = this.state.mergeURL;
      let unmergeURL = this.state.unmergeURL;
      let mergeToken = this.state.mergeToken;
      let userRowData = {
        user_UniqueID: this.state.user_UniqueID,
        userName: this.state.userName,
        tenantid: this.state.tenantid,
        email: this.state.email,
        phone: this.state.phone,
        IsEmail: this.state.IsEmail,
      };
      

    
      if (this.state.isMerge === "No") {
         const redirectV1 = async ()=>{
          const serverTimeResponse = await User.GetServerTime();
          let currentTimeUTC = serverTimeResponse ? serverTimeResponse : null;
          if (!currentTimeUTC) {
            throw new Error("Failed to retrieve server time from API.");
          }

          window.location.href =
          unmergeURL +
          "?logInUser=" +
          Utils.encryptString(`${userName}|${currentTimeUTC}`) +
          "&token=" +
          mergeToken +
          "&logInType=1";

         return "";
        }

        redirectV1()
      
      } else if (
        this.state.isMerge === "Yes" &&
        this.state.isLaborModule === "No"
      ) {
        const redirectV1Fn = async ()=>{
          const serverTimeResponse = await User.GetServerTime();
          let currentTimeUTC = serverTimeResponse ? serverTimeResponse : null;
          if (!currentTimeUTC) {
            throw new Error("Failed to retrieve server time from API.");
          }

          window.location.href =
          mergeURL +
          "?logInUser=" +
          Utils.encryptString(`${userName}|${currentTimeUTC}`) +
          "&token=" +
          mergeToken +
          "&logInType=1";

         return "";
        }
        redirectV1Fn()
      } else if (this.state.isDeeplink) {
        // return <Redirect
        //   to={{
        //     pathname: this.state.deeplink,
        //     state: {}
        //   }}
        // />;
        window.location.replace(this.state.deeplink);
      } else if (this.state.client_id != "" && this.state.showAlert) {
        return <></>;
      } else if (this.state.client_id != "") {
        return <KnowledgeBaseLoader />;
      } else if (ssoparam?.isRedirectFromFreshDesk == 1) {
        return <></>;
      } else {
        let _userRowData = {
          user_UniqueID: this.state.user_UniqueID,
          userName: this.state.userName,
          tenantid: this.state.tenantid,
          email: this.state.email,
          phone: this.state.phone,
          IsEmail: this.state.IsEmail,
          primaryMethod: this.state.primaryMethod,
        };

        const PopUpState = {
          showIncompleteSlideOut: this.state.showIncompleteSlideOut,
          isChangeEHIDAccess: this.state.isChangeEHIDAccess,
          isChangeUserRole: this.state.isChangeUserRole,
          isPageCreateNewUser: this.state.isPageCreateNewUser,
          userData: _userRowData,
          isNewUserAdded: this.state?.isNewUserAdded,
          isDuplicatePhone: this.state?.isDuplicatePhone,
          isDuplicateEmail: this.state?.isDuplicateEmail,
          isMissingPhone: this.state?.isMissingPhone,
          isMissingEmail: this.state?.isMissingEmail,
          primaryMethod: this.state?.primaryMethod,
        };

        // debugger;
        localStorage.setItem("PopUpState", JSON.stringify(PopUpState));
        return (
          <Redirect
            to={{
              pathname: "/Home",
              state: {},
            }}
          />
        );
      }
    } else {
      const Recaptcha = require("react-recaptcha");
      const callback = () => {};
      const verifyCallback = (response: any) => {
        this.setState({ captchaLoginButton: true });
      };

      const verifyCallback1 = (response: any) => {
        this.handleCloseHelp();
        FreshDeskChat.showFreshDesk();
        FreshDeskChat.openFreshDesk();
        //FreshDeskChat.IntegrateFreshDeskChat('');
        this.setState({ needhelp: false });
      };

      const { isCaptcha, captchaLoginButton, isBlocked, modalHelpShow } =
        this.state;
      const isEnabled = isCaptcha && !captchaLoginButton;
      const isVisable = !isBlocked && isCaptcha && !modalHelpShow;

      let recaptchaInput;
      if (isVisable) {
        recaptchaInput = (
          <div>
            <Recaptcha
              id="captcha1"
              sitekey="6LfiUNoUAAAAAD_g22g7mvTdyrs2gW9XxQ8e14gH"
              render="explicit"
              verifyCallback={verifyCallback}
              onloadCallback={callback}
            />
          </div>
        );
      } else {
        recaptchaInput = <div></div>;
      }

      if (this.state.client_id != "" && this.state.showAlert) {
        this.showAlertMessage();
      }

      return (
        <>
          {this.state.client_id != "" && this.state.showAlert ? (
            <></>
          ) : (
            this.state.client_id != "" &&
            ssoparam.isRedirectFromFreshDesk != 1 && <KnowledgeBaseLoader />
          )}
          {this.state.isLogOutFlag !== 0 && (
            <div className="maintenance">
              <div className="header d-flex justify-content-center align-items-center">
                <div className="logo">
                  <img src={logoMaintenance} width="400" alt="Inn-Flow" />
                </div>
                {/* <div className="logo-text">Inn-Flow</div> */}
              </div>
              <div className="body d-flex flex-column justify-content-center align-items-center">
                <div className="lable">UNDER MAINTENANCE</div>
                <img
                  src={underMaintenance}
                  className="maintenance-img"
                  alt="Inn-Flow"
                />
                {/* <img src={maintenance} className="maintenance-img" alt="Inn-Flow" /> */}
                <div
                  className="desc"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      this.state.releaseProtocolBannerMsg
                    ),
                  }}
                ></div>
              </div>
            </div>
          )}
          {this.state.isLogOutFlag === 0 &&
            this.state.isMaintenance !== 1 &&
            (this.state.client_id == "" ||
              ssoparam.isRedirectFromFreshDesk == 1) && (
              <div
                className={this.state.isMarketing ? "login marketing" : "login"}
              >
                <ToastContainer autoClose={3000} containerId={"Login"} />
                <div className="lgn-form d-flex flex-column">
                  <div className="header d-flex align-items-center">
                    <div className="logo">
                      <a href="https://www.inn-flow.com" target="_blank">
                        <svg
                          width="121"
                          height="32"
                          viewBox="0 0 121 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M24.0732 4.37285H13.4629V0.840933C13.4629 0.509232 13.5738 0.270967 13.7941 0.130812C13.9285 0.0436039 14.1019 0 14.2847 0C14.4503 0 14.6238 0.0373748 14.7816 0.10901L24.0732 4.37285Z"
                            fill="#101828"
                          />
                          <path
                            d="M23.8873 4.99585L14.4051 9.34846C14.0364 9.51664 13.7176 9.70196 13.463 9.94178C13.3442 10.0508 13.2395 10.1723 13.1505 10.3077C13.0083 10.5226 12.9036 10.7734 12.838 11.077C12.7864 11.3122 12.7599 11.5785 12.7599 11.8837V27.0735H8.39609C7.48052 27.0735 6.73682 26.3306 6.73682 25.4196V6.64968C6.73682 5.73712 7.48052 4.99585 8.39609 4.99585H23.8873Z"
                            fill="#065986"
                          />
                          <path
                            d="M25.1074 5.29321V27.1357L25.0293 27.1715L24.6558 27.3428L24.5652 27.3848L23.8825 27.6978L15.033 31.7592C14.4909 32.0131 14.0409 32.0691 13.7956 31.9134C13.5753 31.7748 13.5425 31.4696 13.5425 31.3014V11.8836C13.5425 10.7857 13.9096 10.4322 14.733 10.0554L25.0839 5.30411L25.1089 5.29321H25.1074Z"
                            fill="#2ED3B7"
                          />
                          <path
                            d="M75.5064 11.547V14.2244H66.9909V11.547H75.5064ZM69.0919 25.8264V10.2641C69.0919 9.28489 69.2902 8.473 69.6868 7.82844C70.0897 7.18389 70.632 6.70047 71.3137 6.37819C71.9954 6.05591 72.7547 5.89478 73.5913 5.89478C74.1801 5.89478 74.7038 5.94126 75.1624 6.03422C75.6273 6.12719 75.9743 6.21086 76.2036 6.28523L75.5157 8.96261C75.3732 8.91923 75.1934 8.87584 74.9765 8.83246C74.7596 8.78288 74.521 8.75809 74.2607 8.75809C73.6409 8.75809 73.204 8.90683 72.9499 9.20432C72.702 9.49561 72.578 9.91395 72.578 10.4593V25.8264H69.0919Z"
                            fill="#293056"
                          />
                          <path
                            d="M81.5956 6.78724V25.8264H78.1094V6.78724H81.5956Z"
                            fill="#293056"
                          />
                          <path
                            d="M91.2616 26.1053C89.8547 26.1053 88.6369 25.7985 87.6081 25.1849C86.5793 24.5714 85.7829 23.713 85.2189 22.6098C84.6611 21.5004 84.3822 20.2113 84.3822 18.7425C84.3822 17.2675 84.6611 15.9752 85.2189 14.8659C85.7829 13.7565 86.5793 12.895 87.6081 12.2815C88.6369 11.6679 89.8547 11.3611 91.2616 11.3611C92.6622 11.3611 93.877 11.6679 94.9058 12.2815C95.9346 12.895 96.731 13.7565 97.295 14.8659C97.859 15.9752 98.141 17.2675 98.141 18.7425C98.141 20.2113 97.859 21.5004 97.295 22.6098C96.731 23.713 95.9346 24.5714 94.9058 25.1849C93.877 25.7985 92.6622 26.1053 91.2616 26.1053ZM91.2709 23.335C92.0146 23.335 92.6313 23.1304 93.1209 22.7214C93.6167 22.3123 93.9854 21.7607 94.2271 21.0666C94.475 20.3725 94.599 19.5916 94.599 18.7239C94.599 17.8624 94.475 17.0846 94.2271 16.3905C93.9854 15.6902 93.6167 15.1355 93.1209 14.7264C92.6313 14.3112 92.0146 14.1036 91.2709 14.1036C90.521 14.1036 89.895 14.3112 89.393 14.7264C88.8972 15.1355 88.5253 15.6902 88.2774 16.3905C88.0357 17.0846 87.9148 17.8624 87.9148 18.7239C87.9148 19.5916 88.0357 20.3725 88.2774 21.0666C88.5253 21.7607 88.8972 22.3123 89.393 22.7214C89.895 23.1304 90.521 23.335 91.2709 23.335Z"
                            fill="#293056"
                          />
                          <path
                            d="M103.475 25.8264L99.468 11.547H103.019L105.464 21.4943H105.594L108.104 11.547H111.609L114.138 21.4478H114.268L116.685 11.547H120.227L116.22 25.8264H112.576L109.945 16.3161H109.75L107.119 25.8264H103.475Z"
                            fill="#293056"
                          />
                          <path
                            d="M32.659 25.8264V11.547H34.4254V25.8264H32.659ZM33.5608 9.11136C33.2013 9.11136 32.8915 8.9905 32.6312 8.74879C32.3771 8.50089 32.25 8.2034 32.25 7.85633C32.25 7.51546 32.3771 7.22417 32.6312 6.98246C32.8915 6.73456 33.2013 6.6106 33.5608 6.6106C33.9141 6.6106 34.2177 6.73456 34.4719 6.98246C34.7322 7.22417 34.8623 7.51546 34.8623 7.85633C34.8623 8.2034 34.7322 8.50089 34.4719 8.74879C34.2177 8.9905 33.9141 9.11136 33.5608 9.11136Z"
                            fill="#293056"
                          />
                          <path
                            d="M40.2551 16.9669V25.8264H38.4888V11.547H40.1993V13.7875H40.3573C40.692 13.0562 41.2095 12.4705 41.9099 12.0304C42.6164 11.5842 43.4964 11.3611 44.55 11.3611C45.5169 11.3611 46.3629 11.5625 47.088 11.9654C47.8131 12.362 48.3771 12.9508 48.7799 13.7317C49.189 14.5064 49.3935 15.467 49.3935 16.6136V25.8264H47.6272V16.7252C47.6272 15.5662 47.308 14.6521 46.6696 13.9827C46.0313 13.3072 45.1698 12.9694 44.0852 12.9694C43.3477 12.9694 42.6908 13.1305 42.1144 13.4528C41.538 13.7689 41.0825 14.2275 40.7478 14.8287C40.4193 15.4237 40.2551 16.1364 40.2551 16.9669Z"
                            fill="#293056"
                          />
                          <path
                            d="M55.2046 16.9669V25.8264H53.4383V11.547H55.1488V13.7875H55.3069C55.6416 13.0562 56.1591 12.4705 56.8594 12.0304C57.5659 11.5842 58.446 11.3611 59.4996 11.3611C60.4664 11.3611 61.3124 11.5625 62.0375 11.9654C62.7626 12.362 63.3266 12.9508 63.7295 13.7317C64.1385 14.5064 64.343 15.467 64.343 16.6136V25.8264H62.5767V16.7252C62.5767 15.5662 62.2575 14.6521 61.6192 13.9827C60.9808 13.3072 60.1193 12.9694 59.0348 12.9694C58.2972 12.9694 57.6403 13.1305 57.0639 13.4528C56.4875 13.7689 56.032 14.2275 55.6973 14.8287C55.3689 15.4237 55.2046 16.1364 55.2046 16.9669Z"
                            fill="#293056"
                          />
                        </svg>
                      </a>
                    </div>
                    <div className="help-button ml-auto">
                      <Button
                        variant="primary"
                        onClick={this.handleHelpShow.bind(this)}
                      >
                        Need Help? Contact Us
                      </Button>
                    </div>
                  </div>
                  <div className="body d-flex align-items-center justify-content-center">
                    <div className="frm-sec">
                      <div className="frm-header-sec">
                        <div className="logo">
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M24.0732 4.37285H13.4629V0.840933C13.4629 0.509232 13.5738 0.270967 13.7941 0.130812C13.9285 0.0436039 14.1019 0 14.2847 0C14.4503 0 14.6238 0.0373748 14.7816 0.10901L24.0732 4.37285Z"
                              fill="#101828"
                            />
                            <path
                              d="M23.8873 4.99585L14.4051 9.34846C14.0364 9.51664 13.7176 9.70196 13.463 9.94178C13.3442 10.0508 13.2395 10.1723 13.1505 10.3077C13.0083 10.5226 12.9036 10.7734 12.838 11.077C12.7864 11.3122 12.7599 11.5785 12.7599 11.8837V27.0735H8.39609C7.48052 27.0735 6.73682 26.3306 6.73682 25.4196V6.64968C6.73682 5.73712 7.48052 4.99585 8.39609 4.99585H23.8873V4.99585Z"
                              fill="#065986"
                            />
                            <path
                              d="M25.1074 5.29321V27.1357L25.0293 27.1715L24.6558 27.3428L24.5652 27.3848L23.8825 27.6978L15.033 31.7592C14.4909 32.0131 14.0409 32.0691 13.7956 31.9134C13.5753 31.7748 13.5425 31.4696 13.5425 31.3014V11.8836C13.5425 10.7857 13.9096 10.4322 14.733 10.0554L25.0839 5.30411L25.1089 5.29321H25.1074Z"
                              fill="#2ED3B7"
                            />
                          </svg>
                        </div>
                        <div className="heading">Sign into your account</div>
                        <div className="sub-heading">
                          Welcome! Please enter your details.
                        </div>
                      </div>
                      {this.state.errorMessage !== "" && (
                        <div className="error-sec">
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.16699 11.5001H9.83366V13.1667H8.16699V11.5001ZM8.16699 4.83341H9.83366V9.83342H8.16699V4.83341ZM8.99199 0.666748C4.39199 0.666748 0.666992 4.40008 0.666992 9.00008C0.666992 13.6001 4.39199 17.3334 8.99199 17.3334C13.6003 17.3334 17.3337 13.6001 17.3337 9.00008C17.3337 4.40008 13.6003 0.666748 8.99199 0.666748ZM9.00033 15.6667C5.31699 15.6667 2.33366 12.6834 2.33366 9.00008C2.33366 5.31675 5.31699 2.33341 9.00033 2.33341C12.6837 2.33341 15.667 5.31675 15.667 9.00008C15.667 12.6834 12.6837 15.6667 9.00033 15.6667Z"
                              fill="#B12A18"
                            />
                          </svg>
                          Password or Username is incorrect. Please try again.
                        </div>
                      )}
                      <Form
                        className="frm-section"
                        name="loginForm"
                        onSubmit={(e: any) => this.handleSubmit(e)}
                      >
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>Inn-Flow Username</Form.Label>
                          <Form.Control
                            type="text"
                            name="userNameVal"
                            value={userNameVal.value}
                            //placeholder="Username"
                            onChange={(e: any) => this.handleChange(e)}
                            className={
                              this.state.userNameVal.isValid ? "" : "invalid"
                            }
                          />
                          <span className="invalid-feedback">
                            {userNameVal.message}
                          </span>
                        </Form.Group>
                        <Form.Group controlId="formBasicPassword">
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            type="password"
                            name="userPwd"
                            value={userPwd.value}
                            //placeholder="••••••••"
                            onChange={(e: any) => this.handleChange(e)}
                            className={
                              this.state.userPwd.isValid ? "" : "invalid"
                            }
                          />
                          <span className="invalid-feedback">
                            {userPwd.message}
                          </span>
                          {/* <span className="invalid-feedback">
                            {" "}
                            {this.state.errorMessage}
                          </span> */}
                        </Form.Group>
                        <div className="frgt-pwd d-flex justify-content-end">
                          <Link to="/forgot-password/0/0">
                            Forgot password/username?
                          </Link>
                        </div>
                        <div>{recaptchaInput}</div>
                        <div className="frm-action">
                          <Button
                            variant="primary"
                            type="submit"
                            disabled={
                              isEnabled || this.state.loginButtonSpinner
                            }
                          >
                            {this.state.loginButtonSpinner && (
                              <Spinner
                                className="mr-2"
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            )}
                            Sign in
                          </Button>
                          {this.state.webclock === true && (
                            <Button
                              variant="primary white-bg"
                              onClick={this.verifyWithGUID}
                            >
                              <svg
                                width="25"
                                height="24"
                                viewBox="0 0 25 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12.5 6V12L16.5 14M22.5 12C22.5 17.5228 18.0228 22 12.5 22C6.97715 22 2.5 17.5228 2.5 12C2.5 6.47715 6.97715 2 12.5 2C18.0228 2 22.5 6.47715 22.5 12Z"
                                  stroke="#309E84"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              Web time clock
                            </Button>
                          )}
                        </div>

                        {this.state?.idleLogOutMessage && (
                          <div className="pt-2 action txt-center">
                            You have been logged out due to inactivity
                          </div>
                        )}
                      </Form>
                    </div>
                  </div>
                  <div className="footer">
                    © 2020 Inn-Flow. All Rights Reserved |{" "}
                    <a
                      href="https://www.inn-flow.com"
                      target="_blank"
                      className="action-link"
                    >
                      Learn more
                    </a>
                  </div>
                </div>
                {this.state.isMarketing && (
                  <div className="marketing-bg d-flex flex-column">
                    <div className="slider-sec d-flex align-items-center">
                      <div className="section">
                        <div className="img-sec">
                          <img src={marketingSlider} />
                        </div>
                        <div className="content-sec">
                          <div className="ttl">
                            <div className="sub-ttl">Celebrating Inn-Flow</div>
                            2024 HotelTechAwards Finalist
                          </div>
                          <div className="desc">
                            We are excited to announce Inn-Flow's exciting
                            achievement as a finalist in both the Accounting and
                            Labor categories in the 2024 HotelTechAwards.
                          </div>
                          <div className="action">
                            <a
                              href="https://www.inn-flow.com/inn-flow-shines-as-a-double-finalist-in-2024-hoteltechawards/"
                              target="_blank"
                              className="btn btn-primary learnMore"
                            >
                              Learn more
                              <svg
                                width="14"
                                height="12"
                                viewBox="0 0 14 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1 11L6 6L1 1M8 11L13 6L8 1"
                                  stroke="#99F6E0"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="footer-action"><Button className="schedule">Schedule a Demo</Button></div> */}
                  </div>
                )}

                {/* <Row>
                  <Col
                    md={{ span: loginMD }}
                    className={
                      this.state.isMarketing
                        ? "base-white login marketing"
                        : "base-white login"
                    }
                  >
                    <Row className="d-flex align-items-center login-container login">
                      <Col md={{ span: loginSpan, offset: loginOffset }}>
                        <div className="brand-img">
                          <a href="https://www.inn-flow.com" target="_blank">
                            <img
                              src={logo}
                              width="180"
                              height=""
                              className=""
                              alt="Inn-Flow"
                            />
                          </a>
                        </div>
                        <Card>
                          <Card.Body>
                            <div className="card-heading txt-center">
                              Sign In
                              <div className="sub-card-heading">
                                using your Inn-Flow account
                              </div>
                            </div>
                            <Form
                              className="frm-section"
                              name="loginForm"
                              onSubmit={(e: any) => this.handleSubmit(e)}
                            >
                              <Form.Group controlId="formBasicEmail">
                                <Form.Label>Username</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="userNameVal"
                                  value={userNameVal.value}
                                  placeholder="Enter username"
                                  onChange={(e: any) => this.handleChange(e)}
                                  className={
                                    this.state.userNameVal.isValid
                                      ? "valid"
                                      : "invalid"
                                  }
                                />
                                <span className="invalid-feedback">
                                  {userNameVal.message}
                                </span>
                              </Form.Group>
                              <Form.Group controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                  type="password"
                                  name="userPwd"
                                  value={userPwd.value}
                                  placeholder="Enter Password"
                                  onChange={(e: any) => this.handleChange(e)}
                                  className={
                                    this.state.userPwd.isValid
                                      ? "valid"
                                      : "invalid"
                                  }
                                />
                                <span className="invalid-feedback">
                                  {userPwd.message}
                                </span>
                                <span className="invalid-feedback">
                                  {" "}
                                  {this.state.errorMessage}
                                </span>
                              </Form.Group>
                              <div style={{ marginTop: "30px" }}>
                                <Form.Group>{recaptchaInput}</Form.Group>
                                <Button
                                  variant="primary"
                                  className={
                                    this.state.loginButtonSpinner
                                      ? "lg-btn action p-none"
                                      : "lg-btn action"
                                  }
                                  type="submit"
                                  disabled={
                                    isEnabled || this.state.loginButtonSpinner
                                  }
                                >
                                  {this.state.loginButtonSpinner && (
                                    <Spinner
                                      className="mr-2"
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                  )}
                                  <FontAwesomeIcon icon={faLock} />
                                  Login
                                </Button>
                              </div>
                              <div className="action txt-center">
                                <Link
                                  to="/forgot-password/0/0"
                                  className="action-link"
                                >
                                  Forgot your password?
                                </Link>
                              </div>

                              <div className="pt-2 action txt-center">
                                <a
                                  href="#"
                                  className="action-link"
                                  onClick={this.verifyWithGUID}
                                >
                                  Web Time Clock
                                </a>
                              </div>
                              {this.state?.idleLogOutMessage && (
                                <div className="pt-2 action txt-center">
                                  You have been logged out due to inactivity
                                </div>
                              )}
                            </Form>
                          </Card.Body>
                        </Card>
                        {this.state.needhelp && (
                          <div
                            className="not-customer-txt text-center help"
                            style={{ paddingTop: 12 }}
                          >
                            Need help?{" "}
                            <Button
                              variant="primary"
                              onClick={this.handleHelpShow.bind(this)}
                            >
                              Contact support
                            </Button>
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={{ span: loginSpan, offset: loginOffset }}>
                        <div className="help">
                          © 2020 Inn-Flow. All Rights Reserved
                          <span>|</span>
                          <a
                            href="https://www.inn-flow.com"
                            target="_blank"
                            className="action txt-center action-link btn-primary"
                          >
                            Learn more
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={{ span: 5 }} className={Marketcss}>
                    <div className="bg-opacity"></div>
                    <Row>
                      <Col>
                        <div className="login-body d-flex flex-column">
                          <div className="title">Marketing Message</div>
                          <div className="desc">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Phasellus eu arcu vel urna tincidunt blandit
                            quis ac erat. Praesent dignissim, justo semper
                            sagittis suscipit, arcu justo varius elit, vel
                            laoreet metus orci fringilla lacus. Aliquam augue
                            neque, fringilla sit amet enim sed, tempor mattis
                            nibh.{" "}
                          </div>
                          <div className="demo-link">
                            <Button
                              variant="primary"
                              type="submit"
                              className="btn-outline-primary"
                              onClick={this.requestADemo}
                            >
                              Learn More
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row> */}

                {/*---Modal---*/}
                <Modal
                  show={modalShow}
                  onHide={this.handleClose.bind(this)}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  className="ifcusModal"
                  keyboard={false}
                  backdrop="static"
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      Please provide primary contact information
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {this.state.notPrimaryError ? (
                      <p className="text-danger text-center my-2">
                        <strong>{this.state.notPrimaryError}</strong>
                      </p>
                    ) : null}
                    <div className="hasPadding">
                      <div className="frm-section">
                        <Form.Group>
                          <Form.Label>Primary Contact</Form.Label>
                          <Form.Control
                            className="noReadOnly"
                            as="select"
                            onChange={this.setPrimaryContact}
                            title="Select Primary Contact"
                          >
                            {primarymode.map((items: any, index: any) => (
                              <option
                                selected={items.isPrimary}
                                key={index}
                                value={items.value}
                              >
                                {items.label}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </div>

                      <Form
                        onSubmit={this.submitForm.bind(this)}
                        className="frm-section was-validated"
                      >
                        <Form.Group>
                          <Form.Label>
                            Enter Email Address{" "}
                            <em className="text-danger">Required</em>
                          </Form.Label>
                          <Form.Control
                            title="Enter email address"
                            type="email"
                            name="email"
                            pattern="[a-Z0-9._%+-]+@[a-Z0-9.-]+\.[a-Z]{2,}$"
                            className="form-control"
                            value={primaryInput.email}
                            onChange={this.piChange}
                            autoComplete="off"
                            required
                            placeholder="Enter email address"
                          />
                          <span className="invalid-feedback">
                            {primaryInputError.emailError}
                          </span>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>
                            Enter Mobile Number{" "}
                            <em className={mobileReq.style}>
                              {mobileReq.message}
                            </em>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            title="Enter mobile number"
                            className="form-control"
                            name="mobile"
                            value={primaryInput.mobile}
                            pattern="^(0|[1-9][0-9]*)$"
                            onChange={this.piChange}
                            maxLength={10}
                            minLength={10}
                            autoComplete="off"
                            required={mobileReq.required}
                            placeholder="Enter mobile number"
                          />
                          <span className="invalid-feedback">
                            {primaryInputError.mobileError}
                          </span>
                        </Form.Group>
                        <Form.Group className="text-center">
                          <Button
                            type="submit"
                            title="Update"
                            disabled={this.state.submitDisable}
                            variant="primary"
                          >
                            <FontAwesomeIcon icon={faSave} /> Update
                          </Button>
                          &nbsp;
                          <Button
                            type="reset"
                            onClick={this.handleClose.bind(this)}
                            variant="danger"
                            title="Close"
                          >
                            <FontAwesomeIcon icon={faTimes} /> Close
                          </Button>
                        </Form.Group>
                      </Form>
                    </div>
                  </Modal.Body>
                </Modal>
                {/*---Modal---*/}
                <Modal
                  className="help-captcha"
                  show={modalHelpShow}
                  onHide={this.handleCloseHelp.bind(this)}
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Select Captcha</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Recaptcha
                      id="captcha2"
                      sitekey="6LfiUNoUAAAAAD_g22g7mvTdyrs2gW9XxQ8e14gH"
                      render="explicit"
                      verifyCallback={verifyCallback1}
                      onloadCallback={callback}
                    />
                  </Modal.Body>
                  {/* <Modal.Footer>
                  <Button variant="secondary" onClick={this.handleCloseHelp.bind(this)}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={this.handleCloseHelp.bind(this)}>
                    Save Changes
                  </Button>
                </Modal.Footer> */}
                </Modal>
              </div>
            )}
        </>
      );
    }
  }
}
export const Login = LoginComponent;
