import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Container, Dropdown, Form} from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import chevronRight from "../../../Common/Assets/Images/icons/bx-chevron-right.svg";
import Lottie from "lottie-react";
import reportLoaderAnimation from "../../../Common/Assets/Lottie/reportloader.json";
import "./interactiveReports.scss";
import { FiChevronDown } from "react-icons/fi";
export class PnLReport extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      loader: true,
      expandedList: [],
      reportData: [
        { rno: 1, title: "Summary", actual: "", budget: "", variance: "", priorActuals: "", viewType: 1, module: 1 },
        { rno: 2, title: "Departmental Revenue", actual: "", budget: "", variance: "", priorActuals: "", viewType: 1, module: 2 },
        { rno: 3, title: "Departmental Expenses", actual: "", budget: "", variance: "", priorActuals: "", viewType: 1, module: 3 },
        { rno: 4, title: "Departmental  Profit", actual: "", budget: "", variance: "", priorActuals: "", viewType: 1, module: 4 },
        { rno: 5, title: "Undistributed Expenses", actual: "", budget: "", variance: "", priorActuals: "", viewType: 1, module: 5 },
        { rno: 6, title: "Gross Operating Profit", actual: "", budget: "", variance: "", priorActuals: "", viewType: 1, module: 6 },
        { rno: 7, title: "Management Fees", actual: "", budget: "", variance: "", priorActuals: "", viewType: 1, module: 7 },
        { rno: 8, title: "Income Before Non Operating", actual: "", budget: "", variance: "", priorActuals: "", viewType: 1, module: 8 },
        { rno: 9, title: "EBITDA", actual: "", budget: "", variance: "", priorActuals: "", viewType: 1, module: 9 },
        { rno: 10, title: "Replacement Reserves", actual: "", budget: "", variance: "", priorActuals: "", viewType: 1, module: 10 },
        { rno: 11, title: "EBITDA less Replacement Reserves", actual: "", budget: "", variance: "", priorActuals: "", viewType: 1, module: 11 },

        { rno: 12, title: "Available Rooms", actual: "1935", budget: "1935", variance: "0", priorActuals: "1935", viewType: 2, module: 1 },
        { rno: 13, title: "Rooms Sold", actual: "1935", budget: "1935", variance: "-17%", priorActuals: "1935", viewType: 2, module: 1 },
        { rno: 14, title: "Occupied Rooms", actual: "1935", budget: "1935", variance: "-17%", priorActuals: "1935", viewType: 2, module: 1 },
        { rno: 15, title: "Occupancy %", actual: "1935", budget: "1935", variance: "-17%", priorActuals: "1935", viewType: 2, module: 1 },
        { rno: 16, title: "ADR", actual: "1935", budget: "1935", variance: "-$9.00", priorActuals: "1935", viewType: 2, module: 1 },
        { rno: 17, title: "RevPAR", actual: "1935", budget: "1935", variance: "-$11.33", priorActuals: "1935", viewType: 2, module: 1 },
        { rno: 18, title: "# Days in Period", actual: "1935", budget: "1935", variance: "0", priorActuals: "1935", viewType: 2, module: 1 },
        { rno: 19, title: "Rooms", actual: "$71237", budget: "$93170", variance: "-$21933", priorActuals: "$203443", viewType: 2, module: 2 },
        { rno: 20, title: "Food & Beverage", actual: "$0", budget: "$0", variance: "$0", priorActuals: "$0", viewType: 2, module: 2 },
        { rno: 21, title: "Conference", actual: "$0", budget: "$0", variance: "$0", priorActuals: "$0", viewType: 2, module: 2 },
        { rno: 22, title: "Minor Opearted Department", actual: "$0", budget: "$0", variance: "$0", priorActuals: "$0", viewType: 2, module: 2 },
        { rno: 23, title: "Totals", actual: "$71237", budget: "$93170", variance: "-$21933", priorActuals: "$203443", viewType: 2, module: 2 },

        { rno: 24, title: "Rooms", actual: "$71237", budget: "$93170", variance: "-$21933", priorActuals: "$203443", viewType: 2, module: 3 },
        { rno: 25, title: "Food & Beverage", actual: "$0", budget: "$0", variance: "$0", priorActuals: "$0", viewType: 2, module: 3 },
        { rno: 26, title: "Minor Opearted Department", actual: "$0", budget: "$0", variance: "$0", priorActuals: "$0", viewType: 2, module: 3 },
        { rno: 27, title: "Totals", actual: "$71237", budget: "$93170", variance: "-$21933", priorActuals: "$203443", viewType: 2, module: 3 },

        { rno: 28, title: "Rooms", actual: "$71237", budget: "$93170", variance: "-$21933", priorActuals: "$203443", viewType: 2, module: 4 },
        { rno: 29, title: "Food & Beverage", actual: "$0", budget: "$0", variance: "$0", priorActuals: "$0", viewType: 2, module: 4 },
        { rno: 30, title: "Minor Opearted Department", actual: "$0", budget: "$0", variance: "$0", priorActuals: "$0", viewType: 2, module: 4 },
        { rno: 31, title: "Totals", actual: "$71237", budget: "$93170", variance: "-$21933", priorActuals: "$203443", viewType: 2, module: 4 },

        { rno: 32, title: "Administrative & General", actual: "0", budget: "0", variance: "0", priorActuals: "0", viewType: 2, module: 5 },
        { rno: 33, title: "Information & TeleComm", actual: "0", budget: "0", variance: "0", priorActuals: "0", viewType: 2, module: 5 },
        { rno: 34, title: "Sales & Marketing", actual: "0", budget: "0", variance: "0", priorActuals: "0", viewType: 2, module: 5 },
        { rno: 35, title: "Repairs & Maintenance", actual: "0", budget: "0", variance: "0", priorActuals: "0", viewType: 2, module: 5 },
        { rno: 36, title: "Utilities", actual: "0", budget: "0", variance: "0", priorActuals: "0", viewType: 2, module: 5 },
        { rno: 37, title: "Franchies & Related", actual: "0", budget: "0", variance: "0", priorActuals: "0", viewType: 2, module: 5 },
        { rno: 38, title: "Non Operating Income", actual: "0", budget: "0", variance: "0", priorActuals: "0", viewType: 2, module: 5 },
        { rno: 39, title: "Transportaion", actual: "0", budget: "0", variance: "0", priorActuals: "0", viewType: 2, module: 5 },
        { rno: 40, title: "Totals", actual: "0", budget: "0", variance: "0", priorActuals: "0", viewType: 2, module: 5 },

        { rno: 41, title: "Gross Operating Profit", actual: "3000000", budget: "3000000", variance: "-3000000", priorActuals: "3000000", viewType: 2, module: 6 },

        { rno: 42, title: "Management Fees", actual: "3000000", budget: "3000000", variance: "-3000000", priorActuals: "3000000", viewType: 2, module: 7 },

        { rno: 43, title: "Rent", actual: "3000000", budget: "3000000", variance: "-3000000", priorActuals: "3000000", viewType: 2, module: 8 },
        { rno: 44, title: "Income", actual: "3000000", budget: "3000000", variance: "-3000000", priorActuals: "3000000", viewType: 2, module: 8 },
        { rno: 45, title: "Insurance", actual: "3000000", budget: "3000000", variance: "-3000000", priorActuals: "3000000", viewType: 2, module: 8 },
        { rno: 46, title: "Other", actual: "3000000", budget: "3000000", variance: "-3000000", priorActuals: "3000000", viewType: 2, module: 8 },
        { rno: 47, title: "Income Before Non Operating Income & Expenses", actual: "3000000", budget: "3000000", variance: "-3000000", priorActuals: "3000000", viewType: 2, module: 8 },

        { rno: 48, title: "EBITDA", actual: "3000000", budget: "3000000", variance: "-3000000", priorActuals: "3000000", viewType: 2, module: 9 },

        { rno: 49, title: "Replacement Reserves", actual: "0", budget: "0", variance: "0", priorActuals: "0", viewType: 2, module: 10 },

        { rno: 50, title: "EBITDA less Replacement Reserves", actual: "3000000", budget: "3000000", variance: "-3000000", priorActuals: "3000000", viewType: 2, module: 11 },

      ],

      exportOptions: [{id: 1, name: "Export to PDF"}, {id: 2, name: "Export to Excel"}],
      selectedAccPeriod: "03/01/24",
      accPeriodList: [
        {id: 1, accPer: "01/01/24"},
        {id: 1, accPer: "02/01/24"},
        {id: 1, accPer: "03/01/24"},
        {id: 1, accPer: "04/01/24"},
      ],
      selectedType: "MTD",
      typeList: [
        {id: 1, name: "MTD"},
        {id: 1, name: "YTD"},
      ],
      selectedBudget: "Original Budget",
      budgetList: [
        {id: 1, name: "Original Budget"},
        {id: 1, name: "Adjusted Budget"},
        {id: 1, name: "Forecast"},
      ],
    };
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({loader: false});
    }, 10000);
  }

  handleDropdoanChange = (type, item) => {
    if (type === "accPer") {
      this.setState({selectedAccPeriod: item.accPer});
    }
    else if (type === "type") {
      this.setState({selectedType: item.name});
    }
    else if (type === "budget") {
      this.setState({selectedBudget: item.name});
    }
  }

  render() {
    const rowClasses = (row, rowIndex) => {
      if (this.state.expandedList.filter((rno) => row.rno === rno).length > 0)
        return 'expand-row';
      else
        return ""
    };

    const handleOnExpand = (row, isExpand, rowIndex, e) => {
      debugger;
      // this.setState({ expandedList: [] }, () => {
      if (isExpand) {
        this.setState(() => ({
          expandedList: [...this.state.expandedList, row.rno]
        }));
      } else {
        this.setState(() => ({
          expandedList: this.state.expandedList.filter(x => x !== row.rno)
        }));
      }
      // });
    }


    const expandRow = {
      renderer: (row: any, rowIndex: any) => renderChild(row),
      expanded: this.state.expandedList,
      showExpandColumn: true,
      //onlyOneExpanding: true,
      expandByColumnOnly: true,
      onExpand: handleOnExpand,
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return <div></div>;
        }
        return <div></div>;
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <span className="chevronExpended Right">
              <img src={chevronRight} alt="chevron Right" />
            </span>
          );
        }
        return (
          <span className="chevronCollpsed">
            <img src={chevronRight} alt="chevron Right" />
          </span>
        );
      },
    };


    const renderChild = (row) => {
      let childData = this.state.reportData.filter(x => x.viewType === 2 && x.module === row.module)
      return (
        <>
          <BootstrapTable
            keyField='rno'
            data={childData}
            columns={columnChild}
          // rowEvents={this.rowEvents}
          // noDataIndication={'No record found.'}
          />
        </>
      );
    };
    const columns = [
      {
        dataField: 'title',
        text: 'Title',
        headerClasses: 'mobile-header',
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              <div className="module">{row.title}</div>
            </EllipsisWithTooltip>
          );
        },
      }, {
        dataField: 'actual',
        text: 'Actual',
        headerClasses: 'mobile-header',
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              <div className="module">{row.actual}</div>
            </EllipsisWithTooltip>
          );
        },
      }, {
        dataField: 'budget',
        text: 'Budget',
        headerClasses: 'mobile-header',
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              <div className="module">{row.budget}</div>
            </EllipsisWithTooltip>
          );
        },
      }, {
        dataField: 'variance',
        text: 'Variance',
        headerClasses: 'mobile-header',
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              <div className="module">{row.variance}</div>
            </EllipsisWithTooltip>
          );
        },
      }, {
        dataField: 'priorActuals',
        text: 'Prior Actuals',
        headerClasses: 'mobile-header',
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              <div className="module">{row.priorActuals}</div>
            </EllipsisWithTooltip>
          );
        },
      }
    ]
    const columnChild = [
      {
        dataField: 'title',
        text: '',
        headerClasses: 'mobile-header',
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              <div className="module">{row.title}</div>
            </EllipsisWithTooltip>
          );
        },
      }, {
        dataField: 'actual',
        text: '',
        headerClasses: 'mobile-header',
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              <div className="module">{row.actual}</div>
            </EllipsisWithTooltip>
          );
        },
      }, {
        dataField: 'budget',
        text: '',
        headerClasses: 'mobile-header',
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              <div className="module">{row.budget}</div>
            </EllipsisWithTooltip>
          );
        },
      }, {
        dataField: 'variance',
        text: '',
        headerClasses: 'mobile-header',
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              <div className="module">{row.variance}</div>
            </EllipsisWithTooltip>
          );
        },
      }, {
        dataField: 'priorActuals',
        text: '',
        headerClasses: 'mobile-header',
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              <div className="module">{row.priorActuals}</div>
            </EllipsisWithTooltip>
          );
        },
      }
    ]
    return (
      <div className="interactive-reports">
        <Container fluid className="body-sec">
          <div className="page-heading underline d-flex">
            <div className="mr-auto d-flex align-items-center">Profit & Loss Report</div>
            <div className="action-group d-flex">
              <Dropdown
                className="more-action btn-dropdown"
                alignRight
                // onSelect={}
              >
                <Dropdown.Toggle
                  className="btn btn-primary"
                  id="dropdown-more"
                >
                  Export
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {this.state.exportOptions.map(
                    (item: any, idx: any) =>
                      <Dropdown.Item eventKey="link" key="link">{item.name}</Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="more-action three-dot" alignRight>
                <Dropdown.Toggle className="btn-outline-primary btn btn-primary more" id="dropdown-more">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="link" key="link">Go to P&L Report (Operators)</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>

          </div>
          <ToolkitProvider
          >
            {(props: {
              searchProps: JSX.IntrinsicAttributes;
              baseProps: JSX.IntrinsicAttributes;
            }) => (
              <div>
                <div className="search-header d-flex align-items-center">
                  <div className="filter-section">
                    <Dropdown className="">
                      <Dropdown.Toggle id="" className="d-flex align-items-center">
                        <div className="period-name mr-auto"><EllipsisWithTooltip placement="bottom">{this.state.selectedAccPeriod}</EllipsisWithTooltip></div>
                        <div className="icn">
                          <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.28122e-05 1.19715C3.28122e-05 0.889454 0.253262 0.653898 0.543288 0.653898C0.688297 0.653898 0.833313 0.708365 0.923855 0.816593L4.0023 4.02162L7.08074 0.816593C7.2979 0.599435 7.6424 0.599435 7.84116 0.816593C8.04064 1.01608 8.05831 1.34145 7.85955 1.55933L4.40046 5.18103C4.20098 5.39819 3.85721 5.39819 3.64005 5.19943L3.62166 5.18103L0.145009 1.55933C0.054467 1.46879 0 1.34217 0 1.19716L3.28122e-05 1.19715Z" />
                          </svg>
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {this.state.accPeriodList.map(
                          (item: any, idx: any) =>
                            <Dropdown.Item id={"accPer" + idx} onClick={() => this.handleDropdoanChange("accPer", item)}>{item.accPer}</Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="filter-section">
                    <Dropdown className="">
                      <Dropdown.Toggle id="" className="d-flex align-items-center">
                        <div className="period-name mr-auto"><EllipsisWithTooltip placement="bottom">{this.state.selectedType}</EllipsisWithTooltip></div>
                        <div className="icn">
                          <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.28122e-05 1.19715C3.28122e-05 0.889454 0.253262 0.653898 0.543288 0.653898C0.688297 0.653898 0.833313 0.708365 0.923855 0.816593L4.0023 4.02162L7.08074 0.816593C7.2979 0.599435 7.6424 0.599435 7.84116 0.816593C8.04064 1.01608 8.05831 1.34145 7.85955 1.55933L4.40046 5.18103C4.20098 5.39819 3.85721 5.39819 3.64005 5.19943L3.62166 5.18103L0.145009 1.55933C0.054467 1.46879 0 1.34217 0 1.19716L3.28122e-05 1.19715Z" />
                          </svg>
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {this.state.typeList.map(
                          (item: any, idx: any) =>
                            <Dropdown.Item id={"name" + idx} onClick={() => this.handleDropdoanChange("type", item)}>{item.name}</Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="filter-section">
                    <Dropdown className="">
                      <Dropdown.Toggle id="" className="d-flex align-items-center">
                        <div className="period-name mr-auto"><EllipsisWithTooltip placement="bottom">{this.state.selectedBudget}</EllipsisWithTooltip></div>
                        <div className="icn">
                          <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.28122e-05 1.19715C3.28122e-05 0.889454 0.253262 0.653898 0.543288 0.653898C0.688297 0.653898 0.833313 0.708365 0.923855 0.816593L4.0023 4.02162L7.08074 0.816593C7.2979 0.599435 7.6424 0.599435 7.84116 0.816593C8.04064 1.01608 8.05831 1.34145 7.85955 1.55933L4.40046 5.18103C4.20098 5.39819 3.85721 5.39819 3.64005 5.19943L3.62166 5.18103L0.145009 1.55933C0.054467 1.46879 0 1.34217 0 1.19716L3.28122e-05 1.19715Z" />
                          </svg>
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {this.state.budgetList.map(
                          (item: any, idx: any) =>
                            <Dropdown.Item id={"budgetType" + idx} onClick={() => this.handleDropdoanChange("budget", item)}>{item.name}</Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <div className="filter-section ml-auto">
                    <Form.Check
                      type="switch"
                      id="show-pos-neg-budget"
                      label="Show +/- Budget Only"
                      // checked={this.state.isDayDropDown}
                      onChange={(event: any) => {
                        // this.completedOnChange(event, "Completed");
                      }}
                    />
                  </div>
                  <div className="filter-section hasGearIcon">
                    <Dropdown>
                      <Dropdown.Toggle id="" className="d-flex align-items-center">
                        <svg width="14" height="14" viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M0.562614 9.18059L1.89595 11.4873C2.07995 11.8059 2.48795 11.9153 2.80661 11.7306L3.73728 11.1933C4.12395 11.4979 4.55128 11.7479 5.00061 11.9346V12.9999C5.00061 13.3679 5.29861 13.6666 5.66728 13.6666H8.33395C8.70261 13.6666 9.00062 13.3679 9.00062 12.9999V11.9346C9.44995 11.7479 9.87661 11.4979 10.2639 11.1933L11.1946 11.7306C11.5126 11.9139 11.9219 11.8039 12.1053 11.4873L13.4386 9.18059C13.6219 8.86325 13.5119 8.45325 13.1953 8.26992L12.2806 7.74125C12.3153 7.49525 12.3333 7.24725 12.3333 6.99992C12.3333 6.75259 12.3153 6.50459 12.2793 6.25858L13.1939 5.72992C13.5113 5.54659 13.6213 5.13659 13.4373 4.81925L12.1039 2.51259C11.9199 2.19392 11.5119 2.08459 11.1933 2.26925L10.2626 2.80659C9.87595 2.50192 9.44928 2.25125 8.99995 2.06525V0.999919C8.99995 0.631919 8.70195 0.333252 8.33328 0.333252H5.66661C5.29795 0.333252 4.99995 0.631919 4.99995 0.999919V2.06525C4.55061 2.25192 4.12395 2.50192 3.73661 2.80659L2.80661 2.26925C2.48728 2.08459 2.07995 2.19392 1.89595 2.51259L0.562614 4.81925C0.379281 5.13659 0.489281 5.54659 0.805948 5.72992L1.72061 6.25858C1.68461 6.50459 1.66661 6.75259 1.66661 6.99992C1.66661 7.24725 1.68461 7.49525 1.72061 7.74125L0.805948 8.26992C0.488614 8.45325 0.378614 8.86325 0.562614 9.18059ZM6.99995 4.33325C8.47061 4.33325 9.66661 5.52925 9.66661 6.99992C9.66661 8.47059 8.47061 9.66659 6.99995 9.66659C5.52928 9.66659 4.33328 8.47059 4.33328 6.99992C4.33328 5.52925 5.52928 4.33325 6.99995 4.33325Z" />
                        </svg>
                        <div className="drop-arrow ml-1 d-flex">
                          <FiChevronDown />
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {/* <Form.Check
                          type="switch"
                          id="hide-zero-values"
                          label="Hide Zero Values"
                          // checked={this.state.isDayDropDown}
                          onChange={(event: any) => {
                            // this.completedOnChange(event, "Completed");
                          }}
                        /> */}
                        <Dropdown.Item className="saveDefaultBtn"
                        // onClick={() => this.saveDefaultView()}
                        >Save as Default View
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className="table-responsive list-area">
                  {!this.state.loader && (
                    <BootstrapTable
                      data={this.state.reportData.filter(x => x.viewType === 1)}
                      columns={columns}
                      keyField="rno"
                      rowClasses={rowClasses}
                      expandRow={expandRow}
                    />
                  )}
                  {this.state.loader && (
                    <div className="d-flex loader-area align-items-center justify-content-center">
                      <div>
                        <Lottie animationData={reportLoaderAnimation} style={{ height: 150 }} loop={true} />
                        <div className="caption">Generating your Interactive Report</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </ToolkitProvider>
        </Container>
      </div>
    );
  }
}
