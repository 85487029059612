import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClose } from "@fortawesome/free-solid-svg-icons";
import "./ReviewTab.scss";
import { LaborDayApprove } from "../../../../../Common/Services/LaborDayApprove";
import {
  IReviewTabDto,
  ISaveReviewRequestDto,
} from "../../../../../Common/Contracts/ILaborDayApprove";
import { resolve, reject } from "q";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Utils } from "../../../../../Common/Utilis";
import profile from "../../../../../Common/Assets/Images/profile.png";
import stars from "../../../../../Common/Assets/Images/icons/review-star-icon.svg"; 
import rebbonBadge from "../../../../../Common/Assets/Images/rebbon-badge.svg";
import { ReactPageLoader } from "../../../../../Common/Components/ReactPageLoader";
import { Spinner } from "react-bootstrap";

let localStorageUser: any;
export default class ReviewTab extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      parentRow: props.row,
      AcualBudget: "",
      ActualTotal: "",
      TotalWages: "",
      WagesBudget: "",
      WeekActualTotal: "",
      WeekAcualBudget: "",
      WeekTotalWages: "",
      WeekWagesBudget: "",
      OTRisk: "",
      ExplainInRedComment: "",
      ActionItemComment: "", 
      AdditionalNotes:"",    

      dayBudgetStreak: 0,
      todayBudgetStatus: "",
      daysBtn: [],
      weekSuccessRate: "",
      monthSuccessRate: "",
      loader: false,
      Comments: [],
      isSpinner:true,
      isSpinnerOtRisk:true,
      isHideHeadNotesLink:false,
      overTime:"",
      DotOverTime:"",
      isSpinnerDOtHours:false,
    };
  }

  componentDidMount() {
    this.getAutoPunchApprovalData();
    this.GetReviewComment();
  }

  getAutoPunchApprovalData() {
    this.props.isCheckinButtonTrue(true);
    localStorageUser = JSON.parse(localStorage.getItem("storage")!);
    this.setState({ loader: true });
    let parentRow = this.state.parentRow;
    let request = {} as IReviewTabDto;
    request.hotelID = Number(parentRow.hotelID);
    request.user_uniqueno = localStorageUser.user_UniqueID;
    request.date = parentRow.date;
    request.entryType = "Department";
    request.type = "GMApprove";
    // GetLaborOverViewDayApproveAutoPunchDetails
    LaborDayApprove.GetLaborOverViewReviewDetails(request)
      .then(async (result: any | null) => {
        let DayBtnArray: any = [];
        let DayBtn: any = {};
        if (result != null && result.length > 0) {
          //let Data = result as [];
          let AcualBudget = result[0].acualBudget;
          let ActualTotal = result[0].actualTotal;
          let TotalWages = result[0].totalWages;
          let WagesBudget = result[0].wagesBudget;
          let WeekActualTotal = result[0].weekActualTotal;
          let WeekAcualBudget = result[0].weekAcualBudget;
          let WeekTotalWages = result[0].weekTotalWages;
          let WeekWagesBudget = result[0].weekWagesBudget;
          let OTRisk = result[0].otRisk;
          let dayBudgetStreak = Number(result[0].dayBudgetStreak);
          let todayBudgetStatus = result[0].todayBudgetStatus;

           
          let count = 1;
          for (let key in result[0]) {
            let keyName = `day${count}`;
            if (key === keyName) {
              DayBtnArray.push({
                dayName: result[0][keyName].split("_")[0],
                budgetStatus: result[0][keyName].split("_")[1],
              });
              count++;
            }
          }

          let daysBtn = [...this.state.daysBtn];
          let newDaysData = DayBtnArray.map((item) => {
            let className = "";
            let isCheck = false;
            let isCross = false;
            if (item.budgetStatus === "Under") {
              className = "green";
              isCheck = true;
            } else if (item.budgetStatus === "Over") {
              className = "black";
              isCross = true;
            }
            item.class = className;
            item.isCheck = isCheck;
            item.isCross = isCross;

            return { ...item };
          });
          this.setState({ daysBtn: newDaysData });

          this.setState({
            ActualTotal: ActualTotal,
            AcualBudget: AcualBudget,
            TotalWages: TotalWages,
            WagesBudget: WagesBudget,
            WeekActualTotal: WeekActualTotal,
            WeekAcualBudget: WeekAcualBudget,
            WeekTotalWages: WeekTotalWages,
            WeekWagesBudget: WeekWagesBudget,
           // OTRisk: OTRisk,
            dayBudgetStreak: dayBudgetStreak,
            todayBudgetStatus: todayBudgetStatus,
            //loader: false,
            //daysBtn:DayBtnArray
          });
        } else {
        }
        this.props.isCheckinButtonTrue(false);
        this.GetReviewMonthlySecussesRate();
        this.GetReviewMontlyOtRisk();
        this.setState({ loader: false });

        resolve();
      })
      .catch((err) => {
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "laborDayOverviewContainer",
        });
        this.setState({ loader: false });
        reject();
      });
  }

  SubmitCheckIn = (row :any) => {
    let rowStatus="";
    if(this.state.todayBudgetStatus !== "Under")
    {
    if (this.state.ExplainInRedComment.length === 0) {
      Utils.toastError(`Please fill out both text boxes before submitting `, {
      // Utils.toastError(`Please enter the explanation red `, {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "laborDayOverviewContainer",
      });
      return;
    }
  
    if (this.state.ExplainInRedComment.length >0) {
    if (this.state.ActionItemComment.length === 0) {
      Utils.toastError(`Please fill out both text boxes before submitting `, {
      // Utils.toastError(`Please enter the explanation Action Items`, {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "laborDayOverviewContainer",
      });
      return;
    }
  }
}


    if (this.state.AdditionalNotes.length > 200) {
      Utils.toastError(`Additional notes should not exceed 200 characters`, {
         // // position: toast.POSITION.BOTTOM_RIGHT,
         // // containerId: "laborDayOverviewContainer",
       });
       return;
     }
    if (this.state.ExplainInRedComment.length > 200) {
      Utils.toastError(`Explain the red should not exceed 200 characters`, {
         // // position: toast.POSITION.BOTTOM_RIGHT,
         // // containerId: "laborDayOverviewContainer",
       });
       return;
     }
     if (this.state.ActionItemComment.length > 200) {
      Utils.toastError(`Action items explanation should not exceed 200 characters`, {
         // // position: toast.POSITION.BOTTOM_RIGHT,
         // // containerId: "laborDayOverviewContainer",
       });
       return;
     }
    this.props.buttonStatus("",true);
    let requestArray: any = [];
    let deptid="";
    let request = {} as ISaveReviewRequestDto;
    let parentRow = this.state.parentRow;
    request.hotelID = Number(parentRow.hotelID);
    request.tenantID = localStorageUser.tenantID;
    row.forEach(element => {
      deptid+=element.deptid+",";
    });

    request.deptid = deptid; 
    request.ExplainInRedComment = this.state.ExplainInRedComment;
    request.ActionItemComment = this.state.ActionItemComment;
    request.AdditionalNotes = this.state.AdditionalNotes;
    request.Date = parentRow.date;
    request.CommentBy = localStorageUser.userName;
    request.DayUnderOverStatus=this.state.todayBudgetStatus;

    requestArray.push(request);

    LaborDayApprove.LaborSaveDayReviewApprovalComment(requestArray)
      .then(async (result: any | null) => {
        if (result != null) { 
          let resultData = result.result as any;
          if (resultData.saveStatus === "Success") { 
            this.setState({ActionItemComment:"",ExplainInRedComment:"",AdditionalNotes:""});
            toast.success(`Review Saved successfully`, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "laborDayOverviewContainer",
            });
            this.GetReviewComment();
          } else {
            Utils.toastError(resultData.message, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "laborDayOverviewContainer",
            });
          }
          rowStatus=resultData.message;
        }
        this.props.buttonStatus(rowStatus,false);
      })
      .catch((error) => {
        Utils.toastError(`Server Error: ${error}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "laborDayOverviewContainer",
        });
        this.props.buttonStatus(rowStatus,false);
        
        this.setState({ loaderOverBudget: false });
        reject();
      });
  };

  GetReviewComment = () => { 
    localStorageUser = JSON.parse(localStorage.getItem("storage")!); 
    let parentRow = this.state.parentRow;
    let request = {} as IReviewTabDto;
    request.hotelID = Number(parentRow.hotelID);
    request.user_uniqueno = localStorageUser.user_UniqueID;
    request.date = parentRow.date; 
    LaborDayApprove.GetLaborReviewComment(request)
      .then(async (result: any | null) => {
        let DayBtnArray: any = [];
        let DayBtn: any = {};
        if (result != null && result.length > 0) {
          this.setState({ Comments: result,isHideHeadNotesLink:true });
        } else {
          this.setState({ Comments: [] });
        }
      
        resolve();
      })
      .catch((err) => {
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "laborDayOverviewContainer",
        });
        this.setState({ loader: false });
        reject();
      });
  };

  GetReviewMonthlySecussesRate = () => {   
    localStorageUser = JSON.parse(localStorage.getItem("storage")!);
    this.setState({ loader: true });
    let parentRow = this.state.parentRow;
    let request = {} as IReviewTabDto;
    request.hotelID = Number(parentRow.hotelID);
    request.user_uniqueno = localStorageUser.user_UniqueID;
    request.date = parentRow.date;
    request.entryType = "ReviewTab";
     request.type = "GMApprove"; 
    LaborDayApprove.GetReviewMonthlySecussesRate(request)
      .then(async (result: any | null) => {       
        if (result != null && result.length > 0) { 
          let  weekSuccessRate = result[0]. weeklySucessRate+"%";
          let monthSuccessRate = result[0].monthlySucessRate+"%";
          this.setState({ weekSuccessRate: weekSuccessRate,monthSuccessRate:monthSuccessRate,isSpinner:false });
        } else {
          this.setState({ loader: false,isSpinnerOtRisk:false });
        }

        resolve();
      })
      .catch((err) => {
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "laborDayOverviewContainer",
        });
        this.setState({ loader: false,isSpinner:false });
        reject();
      });
  };


GetReviewMontlyOtRisk=()=>{ 
    localStorageUser = JSON.parse(localStorage.getItem("storage")!);
    this.setState({ loader: true });
    let parentRow = this.state.parentRow;
    let request = {} as IReviewTabDto;
    request.hotelID = Number(parentRow.hotelID);
    request.user_uniqueno = localStorageUser.user_UniqueID;
    request.date = parentRow.date;
    request.entryType = "ReviewTab";
     request.type = "GMApprove";
    // GetLaborOverViewDayApproveAutoPunchDetails
    LaborDayApprove.GetReviewMontlyOtRisk(request)
      .then(async (result: any | null) => {      
        if (result != null) { 
          let OTRisk = result.otRisk;
          let overTime = result.overTime;
          this.setState({ OTRisk: OTRisk,overTime:overTime,isSpinnerOtRisk:false });
        } else {
          this.setState({ loader: false,isSpinnerOtRisk:false });
        }

        this.GetReviewDotWages();
        resolve();
      })
      .catch((err) => {
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "laborDayOverviewContainer",
        });
        this.setState({ loader: false,isSpinnerOtRisk:false });
        reject();
      });
}

  OnChange = (e: any,type:any) => {
    let ExplainInRedComment = e.target.value;
    if(type==="AdditionalNotes")
    {    
    this.setState({
      AdditionalNotes: ExplainInRedComment,
    });
   }else if(type==="ExplaintheRed"){
    this.setState({
      ExplainInRedComment: ExplainInRedComment,
    });
   }else if(type==="ActionItems"){
    this.setState({
      ActionItemComment: ExplainInRedComment,
    });
   }
  };
  ShowImage = () => {
    let dayBudgetStreak = this.state.dayBudgetStreak;
    let starArr = [] as any;
    if (dayBudgetStreak <= 3) {
      for (let i = 0; i < dayBudgetStreak; i++) {
        starArr.push(i);
      }
    } else {
      return (
        <div className="rebbon-badge pt-3">
          <img src={rebbonBadge} alt='You haven’t missed this week!' />
          {/* <h3 className="title">For You haven’t missed this week!</h3> */}
          <h3 className="title d-block">You haven’t missed this week!</h3>
        </div>
      )
    }
    return (
      <>
        <div className="col-lg-10 mx-auto">
          <div className="stars mx-auto">
            {starArr.map((item) => {
              return <img key={item} src={stars} alt="star icon" />;
            })}
          </div>
          {dayBudgetStreak > 1 ? 
          <h3 className="title">{dayBudgetStreak} days in a row under budget!</h3> :
          <h3 className="title">You were under budget today!</h3>}
          
        </div>
      </>
    );
  };


  GetReviewDotWages=()=>{ 
        localStorageUser = JSON.parse(localStorage.getItem("storage")!); 
        let parentRow = this.state.parentRow;
        let request = {} as IReviewTabDto;
        request.hotelID = Number(parentRow.hotelID);
        request.user_uniqueno = localStorageUser.user_UniqueID;
        request.date = parentRow.date;
        request.entryType = "ReviewTab";
         request.type = "GMApprove";
        // GetLaborOverViewDayApproveAutoPunchDetails
        LaborDayApprove.GetReviewDotWages(request)
          .then(async (result: any | null) => {      
            if (result != null) {
             debugger; 
              let overTime = result.doubleOverTime;
              this.setState({ DotOverTime: overTime,isSpinnerDOtHours:false });
            } else {
              this.setState({ loader: false,DotOverTime:"",isSpinnerDOtHours:false });
            }
    
            resolve();
          })
          .catch((err) => {
            Utils.toastError(`Server Error, ${err}`, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "laborDayOverviewContainer",
            });
            this.setState({ loader: false,isSpinnerDOtHours:false });
            reject();
          });
    }




  render() {
    return (
      <>
        {this.state.loader ? (
          <ReactPageLoader useas={"daysToApproveExpand"} />
        ) : (
          <div className="container reviewTabContainer">
            <div className="row">
              <div className="col-lg-6 col-xl-4 pl-lg-0 starCol">
                <div className={`${this.state.dayBudgetStreak >= 4 ? 'reviewSidePanel bg-rebon' : 'reviewSidePanel' }`}>
                  <div>
                    {/* For You’re on fire! */}
                    {/* <div className="">
                      <img src={fire} alt="You’re on fire!" />
                      <h3 className="title">You’re on fire!</h3>
                    </div>
                    <h3 className="title">For You haven’t missed this week!</h3> */}
                    {/* <div>
                      <div className="pt-4 mt-2">
                        <img
                          src={rebbonBadge}
                          alt="You haven’t missed this week!"
                        />
                      </div>
                      <h3 className="title">You haven’t missed this week!</h3>
                    </div> */}
                    {this.state.todayBudgetStatus === "Over" ? (
                      <div className="col-lg-10 mx-auto">
                        <h3 className="title">
                          {/* You were over your budget today */}
                          You were over budget today
                        </h3>
                        <h6 className="subTitle">
                          Think about what you could do differently tomorrow.
                        </h6>
                      </div>
                    ) : (
                      <div>
                        <div>
                          {this.ShowImage()}
                        </div>
                      </div>
                    )}
                    <div>
                      <ul className="daysBtn">
                        {this.state.daysBtn.map((item) => {
                          return (
                            <li key={item.dayName}>
                              <h6>{item.dayName}</h6>
                              <button className={item.class}>
                                {item.isCheck && (
                                  <FontAwesomeIcon icon={faCheck} />
                                )}
                                {item.isCross && (
                                  <FontAwesomeIcon icon={faClose} />
                                )}
                              </button>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="rateSection d-flex justify-content-between px-3">
                      <div>
                        {this.state.isSpinner ? (

                          <Spinner animation="border" variant="success" />
                        ):(
                          <h5>{this.state.weekSuccessRate}</h5>

                        )}

                        <h6>Weekly Success Rate</h6>
                      </div>
                      <div>
                      {this.state.isSpinner ? (

<Spinner animation="border" variant="success" />
):(
  <h5>{this.state.monthSuccessRate}</h5>

)}

                       
                        <h6>Monthly Success Rate</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-8 mt-4 mt-xl-0 pl-xl-4 reviewDetailsCol">
                <div className="row reviewPanel">
                  <div className="col-lg-5">
                    <h5 className="title">Today’s Totals</h5>
                    <div className="d-flex">
                      <div>
                        <div className="heading">
                          <h4 className="d-inline">{this.state.ActualTotal}</h4>
                          <span
                            className={`${Number(this.state.AcualBudget) > 0
                                ? "text-red"
                                : "text-green"
                            }`}
                          >
                            {" "}
                            {Number(this.state.AcualBudget) > 0
                              ? "+" +
                              Utils.amountFormatted(
                                Number(this.state.AcualBudget).toFixed(2).replace('.',':')
                              )
                              : Utils.amountFormatted(
                                Number(this.state.AcualBudget).toFixed(2).replace('.',':')
                              )}
                          </span>
                          
                        </div>
                        <h6>Total Hours</h6>
                      </div>
                      <div className="ml-26">
                        <div className="heading">
                          <h4 className="d-inline">
                            {" "}
                            {"$"}
                            {Utils.amountFormatted(
                              Number(this.state.TotalWages).toFixed(2)
                            )}
                          </h4>
                          <span
                            className={`${
                              this.state.WagesBudget >= 0
                                ? "text-red"
                                : "text-green"
                            }`}
                          >
                            {this.state.WagesBudget >= 0
                              ? "+" +
                                Utils.amountFormatted(
                                  Number(this.state.WagesBudget).toFixed(2)
                                )
                              : Utils.amountFormatted(
                                  Number(this.state.WagesBudget).toFixed(2)
                                )}
                          </span>
                        </div>
                        <h6>Total Wages</h6>
                      </div>
                    </div>

                    {this.state.isSpinnerOtRisk ? (<Spinner animation="border" variant="success" />)
                    :
                    (this.state.OTRisk === "" ? "" : <div className="red-badges ml-0 mt-1">{this.state.OTRisk}</div>)
                    }

                  
                    
                  </div>
                  <div className="col-lg-1 text-center">
                    <div className="devider"></div>
                  </div>
                  <div className="col-lg-5">
                    <h5 className="title">Week to Date</h5>
                    <div className="d-flex">
                      <div>
                        <div className="heading">
                          <h4 className="d-inline">
                            {this.state.WeekActualTotal}
                          </h4>
                          <span
                            className={`${Number(this.state.WeekAcualBudget) > 0
                                ? "text-red"
                                : "text-green"
                            }`}
                          >
                            {" "}
                            {Number(this.state.WeekAcualBudget) > 0
                              ? "+" +
                              Utils.amountFormatted(
                                Number(this.state.WeekAcualBudget).toFixed(2)
                              ).replace('.',':')
                              : Utils.amountFormatted(
                                Number(this.state.WeekAcualBudget).toFixed(2).replace('.',':')
                              )}{" "}
                          </span>
                        </div>
                        <h6>Total Hours</h6>
                      </div>
                      <div className="ml-26">
                        <div className="heading">
                          <h4 className="d-inline">
                            {"$"}
                            {Utils.amountFormatted(
                              Number(this.state.WeekTotalWages).toFixed(2)
                            )}
                          </h4>
                          <span
                            className={`${
                              this.state.WeekWagesBudget >= 0
                                ? "text-red"
                                : "text-green"
                            }`}
                          >
                            {this.state.WeekWagesBudget >= 0
                              ? "+" +
                                Utils.amountFormatted(
                                  Number(this.state.WeekWagesBudget).toFixed(2)
                                )
                              : Utils.amountFormatted(
                                  Number(this.state.WeekWagesBudget).toFixed(2)
                                )}
                          </span>
                        </div>
                        <h6>Total Wages</h6>
                      </div>
                    </div>
                    <div>
                    {this.state.isSpinnerOtRisk ? (

<Spinner animation="border" variant="success" />
):(
  this.state.overTime === "" ? "" : <div className="red-badges ml-0 mt-1">{this.state.overTime}</div>

)}
                     
                      
                    </div>
                    <div>
                    {this.state.isSpinnerDOtHours ? (

<Spinner animation="border" variant="success" />
):(
  this.state.DotOverTime === "" ? "" : <div className="red-badges ml-0 mt-1">{this.state.DotOverTime}</div>

)}
                     
                      
                    </div>



                  </div>
                </div>
                {/* <form action="" className="row mt-4"> */}
                <div className="row mt-4">
                  {this.state.todayBudgetStatus === "Under" ? (
                    <div className="col-lg-12 px-0">
                      <div>
                        <label>Additional Notes</label>
                        <textarea
                          
                          name=""
                          id=""
                          onChange={(e: any) => this.OnChange(e,"AdditionalNotes")}
                          value={this.state.AdditionalNotes}
                        ></textarea>
                      </div>

                      <div className="deptHeadNotes mt-4">
                      {this.state.isHideHeadNotesLink &&
                        <h4
                          onClick={() =>
                            this.setState((prevState) => {
                              return {
                                ...prevState,
                                ViewComment: !prevState.ViewComment,
                              };
                            })
                          }
                        >
                          {this.state.ViewComment ? "Hide" : "View"} Department
                          Head Notes
                        </h4>
                          }
                      <div className="deptHeadNotesComments">
                        {this.state.ViewComment &&
                          this.state.Comments.map((item: any, idx: any) => {
                            return (
                              <div key={"reivew comment"}>
                                <ul className="userLists">
                                  <li>
                                    <div className="d-flex align-items-center">
                                      <div className="profile-img">
                                        <img
                                          src={
                                            item.imagePath === null
                                              ? profile
                                              : item.imagePath
                                          }
                                          className="img-fluid"
                                          width={32}
                                          height={32}
                                          alt="user profile"
                                        />
                                      </div>
                                      <div>
                                        <h4>{item.username}</h4>
                                        <h5>
                                          {item.positionName === null
                                            ? ""
                                            : item.positionName +" •"}{" "}
                                          {item.formatedDate}
                                        </h5>
                                      </div>
                                    </div>
                                    <h6>{item.explainInRedComment}</h6>
                                  </li>
                                  
                                </ul>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="col-lg-6 pl-0">
                      {/* <label>Explain the Red</label> */}
                        <label>Please explain why today was Over budget.</label>
                        <textarea  name="" id=""
                          onChange={(e: any) => this.OnChange(e,"ExplaintheRed")} 
                          value={this.state.ExplainInRedComment}></textarea>
                      </div>
                      <div className="col-lg-6 pr-0">
                      {/* <label>Action Items</label> */}
                        <label>What do you think will improve performance?</label>
                        <textarea  name="" id=""
                        onChange={(e: any) => this.OnChange(e,"ActionItems")}
                        value={this.state.ActionItemComment}></textarea>
                      </div>
                    </>
                  )}
                </div>
                {/* </form> */}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
