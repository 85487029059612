import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SmileIdLeftPanel } from "./SmileIdLeftPanel";
import { SmileIdRightPanel } from "./SmileIdRightPanel"; 
import { LaborSmileID } from "../../../../Common/Services/LaborSmileId";
import {
  ILaborSmileIdRequestDto,
  ILaborSmileIdActionRequestDto,
} from "../../../../Common/Contracts/ILaborDayApprove";
import { resolve, reject } from "q";
import { ReactPageLoader } from "../../../../Common/Components/ReactPageLoader";
import profile from "../../../../Common/Assets/Images/profile.png";
import { LaborPerformance as laborPerformance } from "../../../../Common/Services/LaborPerfomance";
import { Utils } from "../../../../Common/Utilis";

export default class SmileIdOverviewDetails extends React.Component<any, any> {
  private smileIdChildLeft: any;
  constructor(props: any) {
    super(props);
    this.smileIdChildLeft = React.createRef();
    this.state = {
      hidValue: props.parentState.hidValue,
      hotelLetterCode: props.parentState.hotelName,
      isLetterCode: props.parentState.isLetterCode,
      isLetterId: props.parentState.isLetterId,
      tableData: [],
      rightPanelRow: {},
      loaderTrue: false,
      currentCol: {},
      navigationCalenderDates: [],
      currentDate: JSON.parse(localStorage.storage).currentUtcTime !== "" ? new Date(
        JSON.parse(localStorage.storage).currentUtcTime
      ):new Date(),
      userImg: {
        imgPath: "",
        imgalt: '',
      }
    };
  }

  getHidPayPeriod(row) {
    this.setState({ currentCol: {} })
    if (row !== undefined) {
      this.setState({ currentCol: row })
      let hotelID = row.hotelId;
      laborPerformance.getPayPeriodDates(hotelID, this.state.currentDate, "Weekly").then(async (result: any[] | null) => {
        if (result != null) {
          this.setState({ navigationCalenderDates: [result[0].startdate, result[0].enddate] });
        }
      });
    }
  }

  smileIdOverviewAccountsDetails = (
    request: ILaborSmileIdRequestDto,
    sortType: string,
    type: string
  ) => {
    if (type === "rowClicked" || type === "parentBoxClicked") {
      this.setState({ loaderTrue: true });
    }
    request.sortType = sortType;
    request.startDate = this.state.navigationCalenderDates[0];
    request.endDate = this.state.navigationCalenderDates[1];
    request.pageType = type;

    LaborSmileID.GetLaborSmileHidFailureDetails(request)
      .then(async (result: any | null) => {
        if (result.message === "Success") {
          let data = result.result;

          let tableData: any = [];
          for (let i = 0; i < data.length; i++) {
            let pushObject: any = data[i];
            pushObject.undoButtonSpinner = false;
            pushObject.noButtonSpinner = false;
            pushObject.yesButtonSpinner = false;

            tableData.push(pushObject);
          }

          this.setState({ tableData }, () => {
            this.setState({ userImg: { imgPath: '', imgalt: '' } }, () => {
              this.getSimleIDProfileImage(tableData[0]);
              this.getHidPayPeriod(tableData[0]);
            })
          });
        } else {
          Utils.toastError(result.message, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "smileIdOverview",
          });
        }
        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error) => {
        Utils.toastError(`Server Error: ${error}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "smileIdOverview",
        });
        this.setState({ loaderTrue: false });
        reject();
      });
  };

  sortLeftPanel = (sortType: any) => {
    let { currentCol } = this.state;
    let request = {} as ILaborSmileIdRequestDto;
    if (currentCol !== undefined) {
      request.hotelID = this.state.currentCol.hotelId;
      this.smileIdOverviewAccountsDetails(request, sortType, "leftSort");
    }
  };

  allHotelBreadCrumb = (e: any) => {
    this.props.smileIdBreadCrumbs("allHotels");
  };

  leftSelectedRow = (row: any) => {
    this.setState({ rightPanelRow: row }, () => {
      this.setState({ userImg: { imgPath: '', imgalt: '' } }, () => {
        this.getSimleIDProfileImage(row);
        this.getHidPayPeriod(row);
      })
    });
  };

  getSimleIDProfileImage = (row) => {
    if (row !== undefined) {
      let request = {
        hotelID: row.hotelId,
        uniqueID: row.uniqueno,
      };
      LaborSmileID.GetSimleIDProfile(request).then(async (result: any | null) => {
        let staticPath = ""
        let basePath;
        let title;
        if (result.success) {
          if (result.result !== '') {
            staticPath = "data:image/jpeg;base64,"
            basePath = result.result;
            title = "baseProfile";
          } else {
            staticPath = ""
            basePath = profile;
            title = "imgProfile";
          }
        } else {
          staticPath = ""
          basePath = profile;
          title = " imgProfile";
        }
        this.setState({ userImg: { imgPath: staticPath + basePath, imgalt: title } })
      })
    }

  }

  rightPanelAction = (type: string, row: any) => {
    let data = this.state.tableData;
    this.buttonSpinnerChanges(type, true, row);
    let request = {} as ILaborSmileIdActionRequestDto;
    request.hotelID = row.hotelId;
    request.Uniqueno = row.uniqueno;
    request.isFalsePunches = type;
    ;
    LaborSmileID.SimeIdAction(request)
      .then(async (result: any | null) => {
        if (result.saveStatus === "Success") {
          for (let i = 0; i < data.length; i++) {
            if (data[i].uniqueno === row.uniqueno) {
              data[i].action = true;
              break;
            }
          }
          this.setState({ tableData: data });
          this.smileIdChildLeft.current.nextSelectedRow(row);
          
          this.props.SmileIdAction(type);
        } else {
          Utils.toastError(result.message, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "smileIdOverview",
          });
        }
        this.buttonSpinnerChanges(type, false, row);
        resolve();
        
      })
      .catch((error) => {
        this.buttonSpinnerChanges(type, false, row);
        Utils.toastError(`Server Error: ${error}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "smileIdOverview",
        });
        reject();
        
      });
  };

  buttonSpinnerChanges = (type: string, value: boolean, row: any) => {
    let newTableData = [...this.state.tableData];

    

    newTableData = newTableData.map((d) => {
      if (d.uniqueno === row.uniqueno) {
        if (type === "undo") {
          return {
            ...d,
            undoButtonSpinner: value,
          };
        } else if (type === "No") {
          return {
            ...d,
            noButtonSpinner: value,
          };
        } else if (type === "Yes") {
          return {
            ...d,
            yesButtonSpinner: value,
          };
        } else {
          return d;
        }
      } else {
        return d;
      }
    });
    this.setState((curr: any) => ({
      ...curr,
      tableData: newTableData,
    }));
  };

  leftUndoButton = (row: any) => {
    
    this.buttonSpinnerChanges("undo", true, row);


    let request = {} as ILaborSmileIdActionRequestDto;
    request.hotelID = row.hotelId;
    request.Uniqueno = row.uniqueno;
    request.isFalsePunches = null;

    LaborSmileID.SimeIdAction(request)
      .then(async (result: any | null) => {
        if (result.saveStatus === "Success") {
          let newTableData = [...this.state.tableData];
          for (let i = 0; i < newTableData.length; i++) {
            if (newTableData[i].uniqueno === row.uniqueno) {
              newTableData[i].action = false;
              newTableData[i].rowSelected = false;
              break;
            }
          }
          this.setState({ tableData: newTableData });
          this.smileIdChildLeft.current.undoButtonClickRerender(row);
          this.props.SmileIdAction("Undo");
        } else {
          Utils.toastError(result.message, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "smileIdOverview",
          });
        }
        this.buttonSpinnerChanges("undo", false, row);
        resolve();
         
      })
      .catch((error) => {
        this.buttonSpinnerChanges("undo", false, row);
        Utils.toastError(`Server Error: ${error}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "smileIdOverview",
        });
        reject();
         
      });
  };

  render() {
     

    return (
      <>
        {this.state.loaderTrue ? (
          <ReactPageLoader useas={"SmileIDPAnel"} />
        ) : (
            <div className="smileIdOverview">
              <div className="smileIDOverviewActionHeader">
                {this.state.hidValue === "All" && (
                  <span className="smileIDBreadCrumbs">
                    <span onClick={this.allHotelBreadCrumb}>
                      &laquo; All Properties
                  </span>
                  </span>
                )}
                <span className="smileIDHeader">
                  {this.state.hotelLetterCode === "All"
                    ? this.state.isLetterCode
                    : this.state.hotelLetterCode}{" "}
                Smile iD Failures
              </span>
              </div>
              <div className="panel-container d-flex justify-content-start">
                <div className="left-panel">
                  <SmileIdLeftPanel
                    ref={this.smileIdChildLeft}
                    parentState={this.state}
                    leftSelectedRow={this.leftSelectedRow}
                    leftUndoButton={this.leftUndoButton}
                    sortLeftPanel={this.sortLeftPanel}
                  />
                </div>
                <div className="Toastify"></div>
                <div className="right-panel">
                  <SmileIdRightPanel
                    parentState={this.state}
                    rightPanelRow={this.state.rightPanelRow}
                    rightPanelAction={this.rightPanelAction}
                    userImg={this.state.userImg}
                  />
                </div>
              </div>
            </div>
          )}
      </>
    );
  }
}
