import React from "react";
import { Modal, ProgressBar, Button, Dropdown, Spinner, Tabs, Tab, Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import Dropzone from 'react-dropzone';
import { ToastContainer, toast } from "react-toastify";
import "./bulkImportSlideout.scss";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import chevronRight from "../../../Common/Assets/Images/icons/bx-chevron-right.svg";
import { FiChevronDown, FiTrash, FiPaperclip } from "react-icons/fi";
import { resolve, reject } from "q";
import { confirmAlert } from "react-confirm-alert";
import TableAutocomplete from "../../../Common/Components/TableAutocomplete";
import cellEditFactory from 'react-bootstrap-table2-editor';
import DatePicker from "react-datepicker";
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import { Register as registersevice } from "../../../Common/Services/Register";
import { ddlSearchWithTab as SingleSearchDropdownList } from "../../../Common/Components/ddlSearchWithTab";
import { SingleDropdownListWithoutSearch } from "../../../Common/Components/SingleDropdownListWithoutSearch";
import { VendorSearch } from "../../../Common/Components/VendorSearch";
import { CommonService } from "../../../Common/Services/CommonService";
import _ from "lodash";
import { Utils } from "../../../Common/Utilis";
const { SearchBar } = Search;
let expandedRows: any = [];
export class BulkImportSlideout extends React.Component<any, any> {
    private vendorSearch: any;
    constructor(props: any) {
        super(props);
        this.vendorSearch = React.createRef();
        this.state = {
            BlobbaseUrl: "",
            hidValue: props.hidValue,
            hotelName: props.hotelName,
            oprId: props.oprId,
            pageName: props.pageName,
            acctType: props.acctType,
            modalWidth: "modal-60w",
            isProgress: Math.floor(Math.random() * 30),
            uploadFiles: [],
            coaList: [],
            vendorListMain: [],
            subVendorListMain: [],
            defaultAccList: [],
            accPeriodList: [],
            isSpinner: false,
            isImportData: false,
            ehidList: [],
            ehidListMain: [],
            isFileImported: false,
            isCloseAlert: false,
            tableData: [],
            isSlideUpdated: false,
            isSlideSave: false,
            isExpand: [],
            isBulkUpdated: false,
            isAddContractPerm: true,
            fileSize: 5242880
        };
        this.onDrop = this.onDrop.bind(this);
    }

    componentDidMount() {
        this.getBlobbaseUrl();
    }

    getBlobbaseUrl = () => {

        let keyName = "BlobbaseUrl";
        let BlobbaseUrl = "";
        CommonService.GetInnflowConfigKeyValue(keyName)
            .then(async (result: any | null) => {
                if (result !== null) {
                    BlobbaseUrl = result;
                    this.setState({ BlobbaseUrl: BlobbaseUrl });
                }

            });
    }


    getFileSize() {
        registersevice.GetFileSize()
            .then(async (result: any | null) => {
                if (result > 0) {
                    this.setState({ fileSize: result })
                }

            });
    }

    onDrop = (uploadFiles: any) => {
        let size = 5242880
        if (uploadFiles.length === 1) {
            if (uploadFiles[0].size > size) {
                Utils.toastError("File is greater than 5 MB.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            } else {
                this.setState({ uploadFiles }, () => {
                    this.BulkExcelImport()
                    this.getFileSize();
                });
            }
        } else if (uploadFiles.length > 1) {
            Utils.toastError("Multiple files are not allowed.", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        } else {
            Utils.toastError("Only .xlsx, and .csv file is acceptable.", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
    }

    BulkExcelImport = () => {
        let request: any = {};
        request.TransType = this.getBulkPagetype("transType");
        request.Hid = this.state.hidValue;
        request.OprId = this.state.oprId;
        request.BackLetterCode = this.state.hotelName;
        registersevice.BulkImportExcelFile("importDW", request, this.state.uploadFiles)
            .then(async (result: any) => {
                this.setState({ isProgress: 100 })
                let resResult = result?.message.toString().toLowerCase();
                let resMessage = "";
                if (result.success) {
                    if (resResult === "Success".toLocaleLowerCase()) {
                        let data = result?.result;
                        data.map(element => {
                            let isChild = element.rowNo !== 1;
                            element["defaultCOA"] = [];
                            element["defaultVendor"] = [];
                            element["defaultSubVendor"] = [];
                            element["attachmentMain"] = [];
                            element["date"] = element.date === "01/01/00" ? new Date() : element.date;
                            element['isActivePeriod'] = element.accper === "01/01/00" ? "Yes" : element.isActivePeriod;
                            element["accper"] = element.accper === "01/01/00" ? "" : element.accper;
                            element["isException"] = false;
                            element["VendorList"] = [];
                            element["subVendorList"] = [];
                            element["customerList"] = [];
                            element["achType"] = (element.achType !== undefined && element.achType !== null) ? element.achType : "";
                            element["isSubVendor"] = (element.isSubVendor !== undefined && element.isSubVendor !== null) ? element.isSubVendor : "";
                            element["isSplitPeriod"] = (element.isSplitPeriod !== undefined && element.isSplitPeriod !== null) ? element.isSplitPeriod : "";
                            element["amount"] = Utils.removeInvaildCharFromAmountNonMinus(element.amount);
                            element["accPeriodForEHID"] = [];
                            element["isDuplicate"] = (element.isDuplicate !== undefined && element.isDuplicate !== null) ? element.isDuplicate : "";
                            element["vid"] = -1;
                            element["backHID"] = (element.backHID !== undefined && element.backHID !== null) ? element.backHID : "";
                            element["mainLettercode"] = (element.mainLettercode !== undefined && element.mainLettercode !== null) ? element.mainLettercode : "";
                            if (isChild) {
                                element["isVendor"] = "Right";
                                element["isInactiveVendor"] = "Active";
                                element["isActivePeriod"] = "Yes";
                                //element["isVendorToHId"] = "Right";
                                element["isSplitPeriod"] = "Active";
                            } else {
                                element["AccountList"] = [];
                                element["accountName"] = "";
                            }
                            element["splitHidList"] = [];
                            if (this.getBulkPagetype("isWMisc")) {
                                //checkNo
                                element["paymentType"] = [{ id: 'ACH', name: 'ACH' }, { id: 'CHECK', name: 'CHECK' }];
                                element["checkNo"] = element.achType.toString().toLowerCase() === "ACH".toLocaleLowerCase() ? "" : element.checkNo;
                            }
                        })
                        setTimeout(() => {
                            this.setState({ isFileImported: true, isSpinner: true, isCloseAlert: true }, () => {
                                this.GetAccountingPeriod(data);
                                if (this.getBulkPagetype("hasSubVendorAndEhid")) {
                                    this.GetAccountNoList();
                                }

                            });
                        }, 1000);
                    } else {
                        if (resResult === "ColumnNameNotMatch".toLocaleLowerCase() || resResult === "InvalidCallName".toLocaleLowerCase()) {
                            resMessage = "The columns in the selected file do not match the template.";
                        } else if (resResult === "LengthExceed".toLocaleLowerCase()) {
                            resMessage = "Import sheet should not have more than 100 characters in a cell.";
                        } else if (resResult === "EHIDShouldbeSame".toLocaleLowerCase()) {
                            resMessage = "EHID Should be Same.";
                        } else if (resResult === "fail".toLocaleLowerCase()) {
                            resMessage = "System keyword does not allowed please rename the column name.";
                        } else if (resResult === "Worng".toLocaleLowerCase()) {
                            resMessage = "Please enter valid column value.";
                        } else if (resResult === "NoData".toLocaleLowerCase()) {
                            resMessage = "No data found.";
                        } else {
                            resMessage = resResult;
                        }
                        setTimeout(() => { Utils.toastError(resMessage, { position: toast.POSITION.BOTTOM_RIGHT, }); }, 1000);
                        setTimeout(() => { this.setState({ isSpinner: false, uploadFiles: [], isProgress: Math.floor(Math.random() * 30) }); }, 1500);
                    }
                } else {
                    resMessage = resResult === "fail" ? "System keyword does not allowed please rename the column name." : resResult;
                    setTimeout(() => { Utils.toastError(resMessage, { position: toast.POSITION.BOTTOM_RIGHT, }); }, 1000);
                    setTimeout(() => { this.setState({ isSpinner: false, uploadFiles: [], isProgress: Math.floor(Math.random() * 30) }); }, 1500);
                }
                resolve();
            })
            .catch((error) => {
                let resMessage = "No Records are available to import.";
                setTimeout(() => { Utils.toastError(resMessage, { position: toast.POSITION.BOTTOM_RIGHT, }); }, 1000);
                setTimeout(() => { this.setState({ isSpinner: false, uploadFiles: [], isProgress: Math.floor(Math.random() * 30) }); }, 1500);
                reject();
            });
    }

    getBulkPagetype(type) {
        let pageTitle = '';
        let hideSubVendorAndEhid = false;
        let transactionType = "";
        let hidePaymentType = true;
        let isWMisc = false;
        let isOperatingDW = true;
        let hasSubVendorAndEhid = true;
        if (this.props.acctType.toLowerCase() === 'O'.toLowerCase()
            && this.props.pageName.toLowerCase() === 'deposit'.toLowerCase()) {
            pageTitle = 'Import Operating Deposit';
            transactionType = "ImportDeposit".toLocaleLowerCase();
            hideSubVendorAndEhid = false;
            hasSubVendorAndEhid = true;
            isOperatingDW = false;
        } else if (this.props.acctType.toLowerCase() === 'O'.toLowerCase()
            && this.props.pageName.toLowerCase() === 'Withdrawal'.toLowerCase()) {
            pageTitle = 'Import Operating Withdrawal';
            transactionType = "ImportWithdrawal".toLocaleLowerCase();
            hideSubVendorAndEhid = false;
            hasSubVendorAndEhid = true;
            isOperatingDW = false;
        } else if (this.props.acctType.toLowerCase() === 'M'.toLowerCase()
            && this.props.pageName.toLowerCase() === 'Withdrawal'.toLowerCase()) {
            pageTitle = 'Import Misc Withdrawal';
            transactionType = "importmisc".toLocaleLowerCase();
            hidePaymentType = false;
            isWMisc = true;
            hideSubVendorAndEhid = true;
            hasSubVendorAndEhid = false;
            isOperatingDW = true;
        } else if (this.props.acctType.toLowerCase() === 'M'.toLowerCase()
            && this.props.pageName.toLowerCase() === 'deposit'.toLowerCase()) {
            pageTitle = 'Import Misc Deposit';
            transactionType = "ImportMiscDeposit".toLocaleLowerCase();
            hideSubVendorAndEhid = true;
            hasSubVendorAndEhid = false;
            isOperatingDW = true;
        } else if (this.props.acctType.toLowerCase() === 'P'.toLowerCase()
            && this.props.pageName.toLowerCase() === 'Withdrawal'.toLowerCase()) {
            pageTitle = 'Import Payroll Withdrawal';
            transactionType = "importpayrollwithdrawal".toLocaleLowerCase();
            hideSubVendorAndEhid = true;
            hasSubVendorAndEhid = false;
            isOperatingDW = true;
        } else if (this.props.acctType.toLowerCase() === 'P'.toLowerCase()
            && this.props.pageName.toLowerCase() === 'deposit'.toLowerCase()) {
            pageTitle = 'Import Payroll Deposit';
            transactionType = "ImportPayrollDeposit".toLocaleLowerCase();
            hideSubVendorAndEhid = true;
            hasSubVendorAndEhid = false;
            isOperatingDW = true;
        }
        if (type === "isHideSubVendorAndEhid") {
            return hideSubVendorAndEhid;
        } else if (type === "transType") {
            return transactionType;
        } else if (type === "isHidePaymentType") {
            return hidePaymentType;
        } else if (type === "isWMisc") {
            return isWMisc;
        } else if (type === "hasSubVendorAndEhid") {
            return hasSubVendorAndEhid;
        } else if (type === "pageTitle") {
            return pageTitle;
        } else if (type === "isOperatingDW") {
            return isOperatingDW;
        }
    }

    GetAccountNoList = () => {
        let request: any = {};
        registersevice.GetAccountNoList(request)
            .then(async (result: any | null) => {
                if (result !== null) {
                    let isResult = result.filter(item => item.acctType.toString().toLocaleLowerCase() !== "c" && item.status === "Active" && item.acctType.toLowerCase() === this.props.acctType.toLowerCase());
                    // result.map(item => item["id"] = item.oprId)
                    this.setState({ defaultAccList: isResult })
                }
                resolve();
            })
            .catch((error) => {
                reject();
            });
    };

    GetAccountingPeriod = (data) => {
        let accPeriod: any = [];
        accPeriod = Object.assign([], JSON.parse(JSON.stringify(this.state.accPeriodList)));
        let request: any = {};
        registersevice.GetAccountingPeriodAllEHID(request)
            .then(async (result: any | null) => {
                if (result !== null) {
                    result.forEach(element => {
                        element.name = element.accperiod;
                        accPeriod.push(element);
                    });
                    this.setState({ accPeriodList: accPeriod }, () => {
                        this.GetHotelCOAlist(data);
                    })
                }
                resolve();
            })
            .catch((error) => {
                reject();
            });
    };

    GetHotelCOAlist = (data) => {
        let request: any = {};
        let tableData: any = [];
        tableData = Object.assign([], JSON.parse(JSON.stringify(data)));
        let isExpanded: any = [];
        let hasSubVendorAndEhid = this.getBulkPagetype("hasSubVendorAndEhid");
        let accPeriodList: any = [];
        registersevice.GetImportList(request)
            .then(async (result: any | null) => {
                if (result !== null) {
                    let coaData = result.transactionChartList;
                    let vendorList: any = result.approvedVendorList;
                    let subVendorList: any = result.vendorBYHIDList;

                    let defaultAccList = this.state.defaultAccList;
                    // let userHotelAccess: any = result.userHotelAccess;
                    // let selectedHotelList: any = result.selectedHotelList;

                    vendorList = vendorList.filter(item => item.name.toString().toLowerCase() !== "SELECT SUB VENDOR".toLocaleLowerCase());
                    vendorList.map((element: any) => (element["label"] = element?.name, element["vendor"] = element?.name));

                    subVendorList = subVendorList.filter(item => item.name.toString().toLowerCase() !== "SELECT SUB VENDOR".toLocaleLowerCase());
                    subVendorList.map((element: any) => (element["label"] = element?.name, element["vendor"] = element?.name));

                    let vendorData = vendorList
                    let subVendorData = subVendorList

                    tableData.map((element: any, idx: any) => {
                        let parentNode = element.rowNo === 1;
                        let childNode = element.rowNo !== 1;
                        let isbackHID = element.backHID === "" ? this.state.hidValue : element.backHID;
                        accPeriodList = this.state.accPeriodList.filter(x => x.hid === isbackHID && x.apClosed.toString().toLowerCase() === "Active".toLocaleLowerCase());
                        if (accPeriodList.length > 0) {
                            element.accPeriodForEHID = accPeriodList;
                            // element.accper = accPeriodList.length > 0 ? accPeriodList[0].defaultPeriod : "";
                            // element.isActivePeriod = "Yes";
                            // element.isSplitPeriod = "Active";
                        }

                        coaData.forEach((coa: any) => {
                            if (element.coa === coa.id) {
                                let defaultCOA: any = [];
                                let objCOA: any = {};
                                objCOA.id = coa?.id;
                                objCOA.label = coa?.label;
                                objCOA.coAname = coa?.coAname;
                                defaultCOA.push(objCOA);
                                element.defaultCOA = defaultCOA;
                                element.coa = coa?.label;
                            }
                        })
                        if (element.defaultCOA.length > 0) {
                            element.coa = element.defaultCOA[0].id;
                        } else {
                            element.coa = "";
                        }
                        if (parentNode) {
                            let contractList = element.contractList !== null && element.contractList.split(",");
                            let cusList: any = [];
                            if (contractList.length > 0) {
                                contractList.forEach((item, index) => {
                                    let cRow: any = {};
                                    cRow.accountNo = item;
                                    cRow.rowID = index;
                                    element.customerList.push(cRow);
                                    if (element.customerNo.toString().toLocaleLowerCase() === item.toLocaleLowerCase()) {
                                        cusList.push(item);
                                    }
                                })
                            }
                            element.customerNo = cusList.length > 0 ? cusList[0] : '';

                            if (result.selectedHotelList.filter(x => x.id === this.state.hidValue).length > 0) {
                                element.isSplitPeriod = "Active";
                            }
                            element.VendorList = vendorData; //.filter(x => x.hid === isbackHID);
                            element.VendorList.forEach((vendor: any) => {
                                if (element.vendor.toString().toLowerCase() === vendor.name.toString().toLowerCase()) {
                                    let defaultVendor: any = [];
                                    let objVEN: any = {};
                                    objVEN.label = vendor?.name;
                                    objVEN.vendor = vendor?.name;
                                    defaultVendor.push(objVEN);
                                    element.defaultVendor = defaultVendor;
                                    element.vid = vendor?.id;
                                }
                            })
                            if (element.defaultVendor.length > 0) {
                                element.vendor = element.defaultVendor[0].vendor;
                            } else {
                                element.vendor = "";
                                //element.isVendorToHId = "Right";
                                element.isInactiveVendor = "Active";
                            }
                        }

                        // let isSplitHid = result.selectedHotelList.filter(x => x.id === (element.backHID === "" ? this.state.hidValue : element.backHID));
                        let isSplitHid = result.userHotelAccess !== null && result.userHotelAccess.filter(x => x.id === (element.backHID === "" ? this.state.hidValue : element.backHID));
                        if (isSplitHid.length > 0) {
                            if (isSplitHid[0].type === 1) {
                                element.splitHidList = result.selectedHotelList//_.sortBy(result.selectedHotelList, "name");;
                                if (hasSubVendorAndEhid) {
                                    element.subVendorList = subVendorData.filter(x => x.hid === element.splitID);
                                    let hasEHID = result.selectedHotelList.filter(x => x.name === element.splitHid);
                                    if (hasEHID.length > 0) {
                                        element.splitHid = hasEHID[0].name;
                                        element.isSplitPeriod = "Active";
                                    } else {
                                        element.splitHid = "";
                                        element.isException = true;
                                    }
                                    if (element.subVendorList.length > 0) {
                                        element.subVendorList.forEach((vendor: any) => {
                                            if (element.subvendor.toString().toLowerCase() === vendor.name.toString().toLowerCase()) {
                                                let defaultSubVendor: any = [];
                                                let objVEN: any = {};
                                                objVEN.label = vendor?.name;
                                                objVEN.vendor = vendor?.name;
                                                defaultSubVendor.push(objVEN);
                                                element.defaultSubVendor = defaultSubVendor;
                                                element.subvendor = vendor?.name;
                                            }
                                        })
                                    }
                                    if (element.defaultSubVendor.length > 0) {
                                        element.subvendor = element.defaultSubVendor[0].vendor;
                                    } else {
                                        element.subvendor = "";
                                    }
                                    element.isEHID = "Right";
                                }
                            } else {
                                if (hasSubVendorAndEhid) {
                                    element.subVendorList = subVendorData.filter(x => x.hid === isSplitHid[0].id);
                                    let hasEHID = result.selectedHotelList.filter(x => x.name === element.splitHid);
                                    if (hasEHID.length > 0) {
                                        element.splitHid = hasEHID[0].name;
                                        element.isSplitPeriod = "Active";
                                    } else {
                                        element.splitHid = "";
                                        element.isException = true;
                                    }
                                    if (element.subVendorList.length > 0) {
                                        element.subVendorList.forEach((vendor: any) => {
                                            if (element.subvendor.toString().toLowerCase() === vendor.name.toString().toLowerCase()) {
                                                let defaultSubVendor: any = [];
                                                let objVEN: any = {};
                                                objVEN.label = vendor?.name;
                                                objVEN.vendor = vendor?.name;
                                                defaultSubVendor.push(objVEN);
                                                element.defaultSubVendor = defaultSubVendor;
                                                element.subvendor = vendor?.name;
                                            }
                                        })
                                    }
                                    if (element.defaultSubVendor.length > 0) {
                                        element.subvendor = element.defaultSubVendor[0].vendor;
                                    } else {
                                        element.subvendor = "";
                                    }
                                }

                                element.splitHidList = isSplitHid;
                                element.splitHid = isSplitHid[0].name;
                                element.splitID = isSplitHid[0].id;
                                element.checkEhid = "Right";
                                element.isEHID = "Right";
                            }
                        } else {
                            if (hasSubVendorAndEhid) {
                                element.subVendorList = subVendorData.filter(x => x.hid === element.splitID);
                                let hasEHID = result.selectedHotelList.filter(x => x.name === element.splitHid);
                                if (hasEHID.length > 0) {
                                    element.splitHid = hasEHID[0].name;
                                    element.isSplitPeriod = "Active";
                                } else {
                                    element.splitHid = "";
                                    element.isException = true;
                                }
                                if (element.subVendorList.length > 0) {
                                    element.subVendorList.forEach((vendor: any) => {
                                        if (element.subvendor.toString().toLowerCase() === vendor.name.toString().toLowerCase()) {
                                            let defaultSubVendor: any = [];
                                            let objVEN: any = {};
                                            objVEN.label = vendor?.name;
                                            objVEN.vendor = vendor?.name;
                                            defaultSubVendor.push(objVEN);
                                            element.defaultSubVendor = defaultSubVendor;
                                            element.subvendor = vendor?.name;
                                        }
                                    })
                                }
                                if (element.defaultSubVendor.length > 0) {
                                    element.subvendor = element.defaultSubVendor[0].vendor;
                                } else {
                                    element.subvendor = "";
                                }
                            }
                        }
                        if (hasSubVendorAndEhid) {
                            let headerEhid = result.userHotelAccess !== null && result.userHotelAccess.filter(x => x.id === element.backHID);
                            if (headerEhid.length > 0) {
                                element.checkEhid = "Right";
                                if (defaultAccList.length > 0) {
                                    element.AccountList = defaultAccList.filter(x => x.hid === isbackHID);
                                    let isAccFilterList = defaultAccList.filter(x => x.acctType.toLowerCase() === this.props.acctType.toLowerCase())
                                    if (isAccFilterList.length > 0) {
                                        if (element.oprID === -1 && element.isPermission === "Yes") {
                                            let accN = isAccFilterList.filter(item => item.hid === isbackHID && item.setDefault === "Yes");
                                            if (accN.length > 0) {
                                                element.accountName = accN[0].name;
                                                element.oprID = accN[0].oprId;
                                            } else {
                                                element.accountName = "";
                                                element.oprID = -1;
                                            }
                                        } else if (element.oprID === -1 && element.isPermission === "No") {
                                            element.accountName = "";
                                            element.oprID = -1;
                                        } else if (element.oprID !== -1 && element.isPermission === "No") {
                                            let accN = defaultAccList.filter(x => x.oprId === element.oprID);
                                            if (accN.length > 0) {
                                                element.accountName = accN[0].name;
                                                element.oprID = accN[0].oprId;
                                            } else {
                                                element.accountName = "";
                                                element.oprID = -1;
                                            }
                                        } else {
                                            let accN = defaultAccList.filter(x => x.oprId === element.oprID);
                                            if (accN.length > 0) {
                                                element.accountName = accN[0].name;
                                                element.oprID = accN[0].oprId;
                                            } else {
                                                element.accountName = "";
                                                element.oprID = -1;
                                            }
                                        }
                                    } else {
                                        element.accountName = "";
                                        element.oprID = -1;
                                    }
                                } else {
                                    element.accountName = "";
                                    element.oprID = -1;
                                }
                            } else {
                                element.checkEhid = "NotRight";
                                element.accountName = "";
                            }
                        }
                        element.amount = !isNaN(element.amount) ? element.amount : "";
                        let isActiveCOA = element.isActiveCOA.toString().toLowerCase() !== "Right".toLocaleLowerCase();
                        //let isSubVendor = element.isSubVendor.toString().toLowerCase() !== "Right".toLocaleLowerCase();
                        let isInactiveVendor = element.isInactiveVendor.toString().toLowerCase() !== "Active".toLocaleLowerCase();
                        let isActivePeriod = element.isActivePeriod.toString().toLowerCase() !== "Yes".toLocaleLowerCase();
                        //let isVendorToHId = element.isVendorToHId.toString().toLowerCase() !== "Right".toLocaleLowerCase();
                        let isSplitPeriod = element.isSplitPeriod.toString().toLowerCase() !== "Active".toLocaleLowerCase();

                        if (isActiveCOA ||
                            (parentNode && (hasSubVendorAndEhid && (element.splitHid === "")) || element.coa === "") ||
                            (childNode && (hasSubVendorAndEhid && (element.splitHid === "")) || element.coa === "" || element.amount === "")
                        ) {
                            let parentNode = tableData.filter(item => (item.uniqueno === element.uniqueno && item.rowNo === 1));
                            isExpanded.push(parentNode[0].id);
                        }
                        if (
                            isActiveCOA || isInactiveVendor || isActivePeriod /*|| isVendorToHId*/ ||
                            (hasSubVendorAndEhid && isSplitPeriod) || isActiveCOA ||
                            (parentNode && element.vendor === "" || element.coa === "") ||
                            (childNode || element.coa === "")
                        ) {
                            element.isException = true;
                        }

                    });
                    this.setState({
                        coaList: result.transactionChartList,
                        ehidList: result.userHotelAccess !== null ? result.userHotelAccess : [],
                        ehidListMain: result.selectedHotelList,
                        vendorListMain: subVendorData,
                        tableData,
                        //isSpinner: false,
                        isExpand: isExpanded
                    }, () => {
                        this.isDuplicateCheckNo();
                        this.checkAccountOpeningDate("", "", "");
                    });
                }
                resolve();
            })
            .catch((error) => {
                Utils.toastError(error.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                this.setState({ isSpinner: false })
                reject();
            });
    };

    checkAccountOpeningDate = (oprid, hid, date) => {
        const { tableData } = this.state;
        tableData.forEach(e => {
            const accOpeningDate = e?.AccountList?.filter((n) => n?.oprId === (oprid === "" ? e?.oprID : oprid) && n?.hid === (hid === "" ? e?.backHID : hid))[0]?.odate;
            if (new Date(date === "" ? e?.date : date) < new Date(accOpeningDate)) {
                e.isOpeningDate = "Wrong";
            } else {
                e.isOpeningDate = "Right";
            }
        });
        this.setState((curr: any) => ({ ...curr, tableData, isSpinner: false, isSlideUpdated: true }));
    }

    downloadTemplate = () => {
        let BlobbaseUrl = this.state.BlobbaseUrl;
        //'https://innflowfilestoretest.blob.core.windows.net/innflowtemplate/TransactionTemplate.xlsx'
        fetch(BlobbaseUrl + 'innflowtemplate/TransactionTemplate.xlsx', {
            method: 'GET', headers: { 'Content-Type': 'application/vnd.ms-excel' }
        })
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `TransactionTemplate.xlsx`);
                document.body.appendChild(link);
                link.click();
            });
    }

    bulkImportSlideoutClose = () => {
        if (this.state.isCloseAlert) {
            confirmAlert({
                title: "Exit",
                message: "You will lose all the data in setup during the import process. Are you sure you want to exit?",
                buttons: [
                    { label: "Yes", onClick: () => { this.props.hideBulkImportSlideout(this.state.isBulkUpdated); }, },
                    { label: "No", onClick: () => reject() }
                ],
            });
        } else {
            this.props.hideBulkImportSlideout(this.state.isBulkUpdated);
        }
    }

    deleteCurrentRow = (row) => {
        let { tableData } = this.state;
        if (tableData.filter(item => item.rowNo === 1).length > 1) {
            confirmAlert({
                title: "Delete entry",
                message: "Are you sure you want to delete this Entry?",
                buttons: [{
                    label: "Yes", onClick: () => {
                        tableData = tableData.filter(
                            (item) => item.uniqueno !== row.uniqueno
                        );
                        this.setState({ tableData, isSlideUpdated: true }, () => this.isDuplicateCheckNo());
                    },
                }, {
                    label: "No", onClick: () => reject(),
                }],
            });
        }
    }

    onAttachmentDrop = (uploadAttachments: any, uniqueid: any) => {
        let size = this.state.fileSize//5242880//5MB
        let filesizeval = Number(Number(this.state.fileSize) / (1024 * 1024))
        let { tableData } = this.state;
        if (uploadAttachments.length > 0) {
            if (uploadAttachments[0].size > size) {
                Utils.toastError("File is greater than " + filesizeval + "MB.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            } else {
                let hasAttachmentMain: any = [];
                uploadAttachments.forEach((items, index) => {
                    hasAttachmentMain.push(items);
                });
                tableData = tableData.map((items) => {
                    if (items.uniqueno === uniqueid) {
                        return {
                            ...items,
                            attachmentMain: items.attachmentMain.concat(hasAttachmentMain)
                        };
                    } else {
                        return items;
                    }
                });
                $("#addAttachment").val("");
                this.setState((curr: any) => ({ ...curr, tableData: tableData, uploadAttachments, isSlideUpdated: true }));
            }
        } else {
            Utils.toastError("Please select a valid file.", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
    }

    deleteCurrentAttachment = (rowIndex, row) => {
        let { tableData } = this.state;
        confirmAlert({
            title: "Delete entry",
            message: "Are you sure you want to delete this Entry?",
            buttons: [{
                label: "Yes", onClick: () => {
                    tableData = tableData.map((items) => {
                        if (items.uniqueno === row.uniqueno) {
                            return {
                                ...items,
                                attachmentMain: items.attachmentMain.filter((item, index) => rowIndex !== index)
                            };
                        } else {
                            return items;
                        }
                    });

                    setTimeout(() => {
                        this.setState((curr: any) => ({ ...curr, tableData: tableData }));
                        tableData.forEach((items) => {
                            if (items.uniqueno === row.uniqueno) {
                                if (items.attachmentMain.length === 0) {
                                    this.setState({ uploadAttachments: [] });
                                };
                            }
                        });
                    }, 1000)
                },
            }, {
                label: "No", onClick: () => reject(),
            }],
        });
    }

    handleSelectedItem = (event: any, row: any, type: any) => {
        let { tableData } = this.state;
        let AccList;
        if (type === "coa") {
            if (event.length > 0) {
                let defaultCOA: any = [];
                let objCOA: any = {};
                objCOA.id = event[0].id;
                objCOA.label = event[0].label;
                objCOA.coAname = event[0].coAname;
                defaultCOA.push(objCOA);
                tableData = tableData.map((items) => {
                    if (items.id === row.id) {
                        return { ...items, defaultCOA: defaultCOA, coa: event[0].id, isActiveCOA: "Right" };
                    } else {
                        return items;
                    }
                });
            } else {
                tableData = tableData.map((items) => {
                    if (items.id === row.id) {
                        return { ...items, defaultCOA: [], coa: '', isException: true };
                    } else {
                        return items;
                    }
                });
            }
        } else if (type === "vendor") {
            if (event.length > 0) {
                let defaultVendor: any = [];
                let objVEN: any = {};
                objVEN.id = event[0].id;
                objVEN.label = event[0].vendor;
                objVEN.vendor = event[0].vendor
                defaultVendor.push(objVEN);
                tableData = tableData.map((items) => {
                    let parentVendor = items.rowNo == 1 && items.vendor;
                    tableData.forEach((items2) => {
                        if (items2.uniqueno === items.uniqueno) {
                            if (items2.subvendor !== parentVendor) {
                                tableData.map(it => it.uniqueno === row.uniqueno && (it.isSubVendor = "Right", it.isException = false));
                            }
                        }
                    })
                    if (items.id === row.id) {
                        row.customerList = [];
                        row.customerNo = '';
                        return {
                            ...items,
                            defaultVendor: defaultVendor,
                            vendor: event[0].vendor,
                            isVendor: "Right",
                            vid: event[0].id,
                            customerList: [],
                            customerNo: '',
                            //isVendorToHId: "Right",
                            isInactiveVendor: "Active"
                        }
                    } else {
                        return items;
                    }
                });
            } else {
                tableData = tableData.map((items) => {
                    if (items.id === row.id) {
                        return { ...items, defaultVendor: [], vendor: '' };
                    } else {
                        return items;
                    }
                });
            }
        } else if (type === "subVendor") {
            if (event.length > 0) {
                let defaultSubVendor: any = [];
                let objVEN: any = {};
                objVEN.id = event[0].id;
                objVEN.label = event[0].vendor;
                objVEN.vendor = event[0].vendor
                defaultSubVendor.push(objVEN);
                tableData = tableData.map((items) => {

                    let parentVendor = items.rowNo == 1 && items.vendor;
                    tableData.forEach((items2) => {
                        if (items2.uniqueno === items.uniqueno) {
                            if (items2.subvendor !== parentVendor) {
                                tableData.map(it => it.uniqueno === row.uniqueno && (it.isSubVendor = "Right", it.isException = false));
                            }
                        }
                    })

                    if (items.id === row.id) {
                        if (items.rowNo === row.rowNo) {
                            return {
                                ...items,
                                defaultSubVendor: defaultSubVendor,
                                subvendor: event[0].vendor,
                                //isSubVendor: "Right",
                            }
                        } else {
                            return items;
                        }
                    } else {
                        return items;
                    }
                });
            } else {
                tableData = tableData.map((items) => {
                    if (items.id === row.id) {
                        return { ...items, defaultSubVendor: [], subvendor: '' };
                    } else {
                        return items;
                    }
                });
            }
        } else if (type === "ehidHeaderSelector") {
            // row.VendorList = [];
            // row.defaultVendor = [];
            // row.AccountList = [];
            // row.vendor = "";
            row.customerList = [];
            row.customerNo = '';
            let { ehidListMain } = this.state;
            let currentEhidName = ehidListMain.filter(item => item.id === event);
            let subVendorList = this.state.vendorListMain.filter(x => x.hid === currentEhidName[0].id);
            if (currentEhidName.length > 0) {
                // let VendorList = this.state.vendorListMain.filter(x => x.hid === currentEhidName[0].id);
                let accPeriodList = this.state.accPeriodList.filter(x => x.hid === currentEhidName[0].id && x.apClosed.toString().toLowerCase() === "Active".toLocaleLowerCase());
                let AccList = this.state.defaultAccList.filter(x => x.hid === currentEhidName[0].id);

                if (currentEhidName[0].type === 1) {
                    tableData = tableData.map((items) => {
                        if (items.uniqueno === row.uniqueno) {
                            return {
                                ...items,
                                mainLettercode: currentEhidName[0].name,
                                backHID: currentEhidName[0].id,
                                splitHidList: ehidListMain,
                                //oprID: currentEhidName[0].splitOprID,
                                AccountList: AccList,
                                //VendorList: VendorList,
                                accountName: "",
                                customerList: [],
                                accPeriodForEHID: accPeriodList,
                                accper: accPeriodList.length > 0 ? accPeriodList[0].defaultPeriod : "",
                                isActivePeriod: "Yes",
                                isSplitPeriod: "Active",
                                checkEhid: "Right",
                                isEHID: "Right",
                            };
                        } else {
                            return items;
                        }
                    });
                } else {
                    let idSplitEhidSame = tableData.filter((items) => items.uniqueno === row.uniqueno && items.splitID);
                    if (idSplitEhidSame.length > 0) {
                        idSplitEhidSame.forEach(items => {
                            if (items.splitID === event) {
                                tableData = tableData.map((items) => {
                                    if (items.uniqueno === row.uniqueno) {
                                        return {
                                            ...items,
                                            mainLettercode: currentEhidName[0].name,
                                            backHID: currentEhidName[0].id,
                                            splitHidList: currentEhidName,
                                            splitHid: currentEhidName[0].name,
                                            splitID: currentEhidName[0].id,
                                            //oprID: currentEhidName[0].splitOprID,
                                            AccountList: AccList,
                                            //VendorList: VendorList,
                                            accountName: "",
                                            customerList: [],
                                            accPeriodForEHID: accPeriodList,
                                            accper: accPeriodList.length > 0 ? accPeriodList[0].defaultPeriod : "",
                                            isActivePeriod: "Yes",
                                            isSplitPeriod: "Active",
                                            checkEhid: "Right",
                                            isEHID: "Right",
                                            subVendorList: subVendorList,
                                        };
                                    } else {
                                        return items;
                                    }
                                });
                            } else {
                                tableData = tableData.map((items) => {
                                    if (items.uniqueno === row.uniqueno) {
                                        return {
                                            ...items,
                                            mainLettercode: currentEhidName[0].name,
                                            backHID: currentEhidName[0].id,
                                            splitHidList: currentEhidName,
                                            splitHid: currentEhidName[0].name,
                                            splitID: currentEhidName[0].id,
                                            AccountList: AccList,
                                            //VendorList: VendorList,
                                            accountName: "",
                                            customerList: [],
                                            accPeriodForEHID: accPeriodList,
                                            accper: accPeriodList.length > 0 ? accPeriodList[0].defaultPeriod : "",
                                            isActivePeriod: "Yes",
                                            isSplitPeriod: "Active",
                                            checkEhid: "Right",
                                            isEHID: "Right",
                                            subVendorList: subVendorList,
                                            defaultSubVendor: [],
                                            subvendor: ""
                                        };
                                    } else {
                                        return items;
                                    }
                                });
                            }
                        });
                    } else {
                        tableData = tableData.map((items) => {
                            if (items.uniqueno === row.uniqueno) {
                                return {
                                    ...items,
                                    mainLettercode: currentEhidName[0].name,
                                    backHID: currentEhidName[0].id,
                                    splitHidList: currentEhidName,
                                    splitHid: currentEhidName[0].name,
                                    splitID: currentEhidName[0].id,
                                    AccountList: AccList,
                                    //VendorList: VendorList,
                                    accountName: "",
                                    customerList: [],
                                    accPeriodForEHID: accPeriodList,
                                    accper: accPeriodList.length > 0 ? accPeriodList[0].defaultPeriod : "",
                                    isActivePeriod: "Yes",
                                    isSplitPeriod: "Active",
                                    checkEhid: "Right",
                                    isEHID: "Right",
                                    subVendorList: subVendorList,
                                    defaultSubVendor: [],
                                    subvendor: ""
                                };
                            } else {
                                return items;
                            }
                        });
                    }

                }
            } else {
                tableData = tableData.map((items) => {
                    if (items.id === row.id) {
                        return { ...items, mainLettercode: '', backHID: -1, VendorList: [], vendor: "", accountName: "", customerList: [] };
                    } else {
                        return items;
                    }
                });
            }

        } else if (type === "ehidSplitSelector") {
            let { ehidListMain } = this.state;
            let currentEhidName = ehidListMain.filter(item => item.id === event);
            if (currentEhidName.length > 0) {
                let subVendorList = this.state.vendorListMain.filter(x => x.hid === currentEhidName[0].id);
                tableData = tableData.map((items) => {
                    if (items.uniqueno === row.uniqueno) {
                        return { ...items, isSplitPeriod: "Active" };
                    } else {
                        return items;
                    }
                });
                tableData = tableData.map((items) => {
                    if (items.id === row.id) {
                        if (items.splitID === event) {
                            return {
                                ...items,
                                splitHid: currentEhidName[0].name,
                                splitID: currentEhidName[0].id
                            };
                        } else {
                            return {
                                ...items,
                                splitHid: currentEhidName[0].name,
                                splitID: currentEhidName[0].id, subvendor: "",
                                defaultSubVendor: [],
                                subVendorList: subVendorList
                            };
                        }
                    } else {
                        return items;
                    }
                });
            } else {
                tableData = tableData.map((items) => {
                    if (items.id === row.id) {
                        return {
                            ...items,
                            splitHid: "",
                            splitID: -1,
                            //  subVendorList: [], 
                            //  subvendor: "", 
                            isException: true
                        };
                    } else {
                        return items;
                    }
                });
            }
        } else if (type === "paymentType") {
            tableData = tableData.map((items) => {
                if (items.id === row.id) {
                    if (event.toString().toLowerCase() === "ACH".toLocaleLowerCase()) {
                        return { ...items, achType: event, checkNo: "" };
                    } else {
                        return { ...items, achType: event };
                    }
                } else {
                    return items;
                }
            });
        } else if (type === "accountListSelector") {
            AccList = this.state.defaultAccList.filter(x => x.id === Number(event));
            this.checkAccountOpeningDate(AccList[0].oprId ?? "", row.backHID ?? "", row.date ?? "");
            tableData = tableData.map((items) => {
                if (items.id === row.id) {
                    if (AccList.length > 0) {
                        return { ...items, accountName: AccList[0].name, oprID: AccList[0].oprId };
                    } else {
                        return { ...items, accountName: "" };
                    }

                } else {
                    return items;
                }
            });
        }
        this.setState((curr: any) => ({ ...curr, tableData, isSlideUpdated: true }), () => {
            if (type === "vendor" && event.length > 0) { this.GetVendorContract(event[0].id, row, "vendor"); }
            if (this.getBulkPagetype("hasSubVendorAndEhid")) {
                this.isSubVendorMatch(row);
            }
            this.isValidForm();
        });
    }

    GetVendorContract = (vid, row, type) => {
        if (row.customerList.length === 0) {
            let { tableData } = this.state;
            tableData = tableData.map((items) => {
                if (items.uniqueno === row.uniqueno) {
                    return { ...items, isVandorSpinner: true, };
                } else {
                    return items;
                }
            });
            this.setState((curr: any) => ({ ...curr, tableData, isVandorSpinnerPanel: true }));
            let request: any = {};
            request.hotelID = row.backHID; //this.state.hidValue;
            request.vendorType = "Current";
            request.VendorId = vid;
            registersevice.GetVendorContract(request)
                .then(async (result: any) => {
                    if (result.length > 0) {
                        if (result.length === 1) {
                            tableData = tableData.map((items) => {
                                if (items.id === row.id) {
                                    return { ...items, customerList: result, customerNo: result[0].accountNo, isVandorSpinner: false };
                                } else {
                                    return items;
                                }
                            });
                        } else {
                            tableData = tableData.map((items) => {
                                if (items.id === row.id) {
                                    return { ...items, customerList: result, customerNo: '', isVandorSpinner: false };
                                } else {
                                    return items;
                                }
                            });
                        }
                    } else {
                        tableData = tableData.map((items) => {
                            if (items.id === row.id) {
                                return { ...items, customerNo: "", customerList: [], isVandorSpinner: false };
                            } else {
                                return items;
                            }
                        });
                    }
                    this.setState((curr: any) => ({ ...curr, tableData, isVandorSpinnerPanel: false }), () => {
                        if (type === "custNo" && result.length === 0) {
                            this.addNewCustomerNo(row);
                        } else {
                            this.isValidForm();
                        }
                    });
                    resolve();
                })
                .catch((error) => {
                    Utils.toastError(error?.message, { position: toast.POSITION.BOTTOM_RIGHT });
                    reject();
                });
        }
    }

    addNewCustomerNo = (row: any) => {
        this.setState({ editedRow: row }, () => {
            if (this.vendorSearch?.current !== null) {
                this.setState(
                    prevState => ({ currentRowHid: row.backHID === "" ? this.state.hidValue : row.backHID }),
                    () => {
                        setTimeout(() => { this.vendorSearch?.current.importFromJournal(row.vendor, row.vid, "current"); }, 500);
                    }
                );
            }
        })
    }

    updateVendorList = (result: any) => { }
    handleVendorChange = (vendor: any) => { }
    getCustomerNo = (result: any) => { }
    updateCustomerList = (newCustList) => {
        this.updateNewCustomerList(newCustList);
    }

    updateNewCustomerList = (newCustList) => {
        let { tableData, editedRow } = this.state;
        let request: any = {};
        request.hotelID = editedRow.backHID === "" ? this.state.hidValue : editedRow.backHID;
        request.vendorType = "Current";
        request.VendorId = editedRow.vid;
        registersevice.GetVendorContract(request)
            .then(async (result: any) => {
                if (result.length > 0) {
                    tableData = tableData.map((items) => {
                        if (items.id === editedRow.id) {
                            return { ...items, customerList: result, customerNo: newCustList.accountNo };
                        } else {
                            return items;
                        }
                    });
                    this.setState((curr: any) => ({ ...curr, tableData }), () => this.isValidForm());
                }
                resolve();
            })
            .catch((error) => {
                reject();
            });
    }


    isSubVendorMatch = (row) => {
        let tableData = [...this.state.tableData];
        let parentNode = tableData.filter(item => (item.uniqueno === row.uniqueno && item.rowNo === 1));
        let parentVendor = parentNode.length > 0 && parentNode[0]?.vendor;
        let currentid: any = [];
        let hasUniqueRows: any = [];
        let isMatch = false;
        tableData.filter(item => {
            if (item.uniqueno === row.uniqueno) {
                hasUniqueRows.push(item);
            }
        })
        if (hasUniqueRows.length > 0) {
            hasUniqueRows.filter((item: any) => {
                if ((item.subvendor !== "" && item.subvendor !== null) && item.subvendor === parentVendor) {
                    currentid.push(item.id);
                } else {

                }
            })
        }
        if (currentid.length > 0) {
            currentid.filter(item => {
                if (item === row.id) {
                    isMatch = true;
                }
            })
        }
        return isMatch;
    }

    isSubVendorVelidate = () => {
        let tableData = [...this.state.tableData];
        let hasError = false;
        tableData.forEach(items => {
            let header: any = [];
            if (items.rowNo === 1) {
                let headerRow: any = {};
                headerRow.uniqueno = items.uniqueno;
                headerRow.vendor = items.vendor;
                headerRow.splitData = [];
                header.push(headerRow);
            }
            tableData.forEach(items2 => {
                if (items2.uniqueno === items.uniqueno) {
                    if (header.length > 0) {
                        let HeaderData = header.find(a => a.uniqueno === items2.uniqueno)
                        let splitRow: any = {};
                        splitRow.subvendor = items2.subvendor !== undefined && items2.subvendor !== null ? items2.subvendor : "";
                        HeaderData.splitData.push(splitRow)
                    }
                }
            });
            if (header.length > 0) {
                if (hasError !== true) {
                    header.forEach(header => {
                        hasError = header.splitData.filter(item => item.subvendor === header.vendor).length > 0;
                    })
                }
            }
        });
        return hasError;
    }

    isDuplicateCheckNo = () => {
        let tableData = [...this.state.tableData];
        let match = 0;
        let i = 0;
        let k = 0;
        for (i = 0; i < tableData.length; i++) {
            match = 0;
            for (k = 0; k < tableData.length; k++) {
                if (tableData[k].checkNo !== "" && (tableData[k].checkNo === tableData[i].checkNo)) {
                    match++;
                    if (match > 1) {
                        tableData = tableData.map((items) => {
                            if (items.id === tableData[k].id) {
                                return { ...items, isDuplicate: "Duplicate check No." };
                            } else {
                                return items;
                            }
                        });
                    } else {
                        tableData = tableData.map((items) => {
                            if (items.id === tableData[k].id) {
                                return { ...items, isDuplicate: "Right" };
                            } else {
                                return items;
                            }
                        });
                    }
                }
            }
        }
        this.setState((curr: any) => ({ ...curr, tableData }), () => {
            this.isValidForm()
        });
    }

    isValidForm = () => {
        let { tableData } = this.state;
        let hasSubVendorAndEhid = this.getBulkPagetype("hasSubVendorAndEhid");
        let isSubVendorVelidate = this.isSubVendorVelidate();
        let getBulkPagetype = this.getBulkPagetype("isWMisc");
        let isCheckType = this.getBulkPagetype("isHideSubVendorAndEhid");
        tableData.map((element) => {
            //if (element.id === row.id) {
            let parentNode = element.rowNo === 1;
            let childNode = element.rowNo !== 1;
            let isActiveCOA = element.isActiveCOA.toString().toLowerCase() !== "Right".toLocaleLowerCase();
            let isSubVendor = element.isSubVendor.toString().toLowerCase() !== "Right".toLocaleLowerCase();
            let isInactiveVendor = element.isInactiveVendor.toString().toLowerCase() !== "Active".toLocaleLowerCase();
            let isActivePeriod = element.isActivePeriod.toString().toLowerCase() !== "Yes".toLocaleLowerCase();
            //let isVendorToHId = element.isVendorToHId.toString().toLowerCase() !== "Right".toLocaleLowerCase();
            let isSplitPeriod = element.isSplitPeriod.toString().toLowerCase() !== "Active".toLocaleLowerCase();
            let isDuplicate = element.isDuplicate.toString().toLowerCase() !== "Right".toLocaleLowerCase();
            let isGenericVendor = parentNode && element.vendor.toString().toLowerCase() !== "GENERIC VENDOR".toLocaleLowerCase();
            if (
                isActiveCOA || isInactiveVendor || isActivePeriod || /*isVendorToHId ||*/
                (hasSubVendorAndEhid && isSubVendor && isSplitPeriod) || isActiveCOA || isSubVendorVelidate ||
                (parentNode && (element.vendor === "" || (getBulkPagetype && isDuplicate) ||
                    (hasSubVendorAndEhid && (element.splitHid === "" || element.accountName === "")) ||
                    element.coa === "" || element.accper === "" || element.amount === "" || (isGenericVendor && element.customerNo === "") ||
                    (getBulkPagetype && (element.achType === "" ? element.achType === "" : element.achType.toString().toLowerCase() === "CHECK".toLocaleLowerCase() && element.checkNo === "" || isNaN(Number(element.checkNo)))))) ||
                (childNode && (hasSubVendorAndEhid && (element.splitHid === "")) || element.coa === "" || element.amount === "")
            ) {
                element.isException = true;
            } else {
                element.isException = false;
            }
            //}
        });
        this.setState({ tableData });
    }

    handleChange = (event: any, row: any, FieldType: string) => {
        let { tableData } = this.state;
        if (FieldType === "trDate") {
            let isDate = event === null ? new Date() : event;
            let today = new Date(isDate);
            let month = (today.getMonth() + 1) <= 9 ? "0" + (today.getMonth() + 1) : (today.getMonth() + 1);
            let day = today.getDate() <= 9 ? "0" + today.getDate() : today.getDate();
            let year = today.getFullYear().toString().substr(-2);
            let dateNew = `${month}/${day}/${year}`;
            tableData = tableData.map((items) => {
                if (items.id === row.id) {
                    return { ...items, date: dateNew };
                } else {
                    return items;
                }
            });
            this.checkAccountOpeningDate(row.oprID ?? "", row.backHID ?? "", dateNew ?? "");
        }
        this.setState((curr: any) => ({ ...curr, tableData, isSlideUpdated: true }), () => this.isValidForm());
    }

    cellParentEdit = cellEditFactory({
        mode: "click",
        blurToSave: true,
        afterSaveCell: (oldValue: any, newValue: any, row: any, column: any) => {
            let { tableData } = this.state;
            let index = tableData.indexOf(row);
            let dataField = column.dataField;
            if (oldValue !== newValue) {
                if (dataField === "internalnotes") {
                    if (newValue.length > 100) {
                        row.internalnotes = newValue.substring(0, 100);
                        tableData[index] = row;
                        this.setState({ tableData }, () => this.isValidForm());
                    } else {
                        row.internalnotes = newValue;
                        tableData[index] = row;
                        this.setState({ tableData }, () => this.isValidForm());
                    }
                } else if (dataField === "checkNo") {
                    if (isNaN(Number(row.checkNo))) {
                        row.checkNo = "";
                        tableData[index] = row;
                        this.setState({ tableData }, () => this.isDuplicateCheckNo());
                    } else {
                        row.checkNo = newValue.substring(0, 100);
                        tableData[index] = row;
                        this.setState({ tableData }, () => this.isDuplicateCheckNo());
                    }
                }
            }
        }
    })

    formatDollar(num) {
        return (Number(num).toFixed(2)).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    cellChildEdit = cellEditFactory({
        mode: "click",
        blurToSave: true,
        afterSaveCell: (oldValue: any, newValue: any, row: any, column: any) => {
            let { tableData } = this.state;
            let dataField = column.dataField;
            let index = tableData.indexOf(row);
            if (oldValue !== newValue) {
                if (dataField === "description") {
                    if (newValue.length > 100) {
                        row.description = newValue.substring(0, 100);
                        tableData[index] = row;
                        this.setState({ tableData }, () => this.isValidForm());
                    } else {
                        row.description = newValue;
                        tableData[index] = row;
                        this.setState({ tableData }, () => this.isValidForm());
                    }
                } else if (dataField === 'amount') {
                    newValue = Utils.removeInvaildCharFromAmount(newValue);
                    if (!isNaN(newValue)) {
                        if (newValue === "") {
                            row.amount = ''
                            tableData[index] = row;
                            this.setState({ tableData }, () => this.isValidForm());
                        } else {
                            row.amount = newValue !== "" ? newValue : newValue
                            tableData[index] = row;
                            this.setState({ tableData }, () => this.isValidForm());
                        }
                    } else {
                        row.amount = ""
                        tableData[index] = row;
                        this.setState({ tableData }, () => this.isValidForm());
                    }
                }
            }
        }
    })

    handleRowEvent = (event: any, row: any, type: any) => {
        let { tableData } = this.state;
        if (type === "cusNo") {
            tableData = tableData.map((items) => {
                if (items.id === row.id) {
                    return { ...items, customerNo: JSON.parse(event).accountNo };
                } else {
                    return items;
                }
            });
        } else if (type === "actPer") {
            tableData = tableData.map((items) => {
                if (items.id === row.id) {
                    return { ...items, accper: event, isActivePeriod: "Yes", isSplitPeriod: "Active" };
                } else {
                    return items;
                }
            });
        } else {

        }
        this.setState((curr: any) => ({ ...curr, tableData: tableData, isSlideUpdated: true }), () => this.isValidForm());
    };

    handleBackScreen = () => {
        confirmAlert({
            title: "Alert!",
            message: "All data will remove which you set to current step, Are you sure you want to redirect previous step?",
            buttons: [{
                label: "Yes", onClick: () => {
                    this.setState({
                        uploadFiles: [],
                        coaList: [],
                        vendorListMain: [],
                        accPeriodList: [],
                        ehidList: [],
                        tableData: [],
                        isExpand: [],
                        isFileImported: false,
                        isSlideUpdated: false,
                        isCloseAlert: false,
                        isProgress: Math.floor(Math.random() * 30),
                    })
                },
            }, {
                label: "No", onClick: () => reject(),
            }],
        });
    }

    importData = () => {
        $("#btnImportData").prop('disabled', true);
        this.setState({ isImportData: true })
        let tableData = [...this.state.tableData];
        const storage = JSON.parse(localStorage.getItem("storage")!);
        const username = storage === null ? 0 : (storage.userName as any);
        const tenantId = storage === null ? 0 : (storage.tenantID as any);
        let request: any = {}
        request.FileNo = tableData[0].fileNo;
        let importInfo: any = {};
        importInfo.TransType = this.getBulkPagetype("transType");
        importInfo.TenantID = tenantId;
        importInfo.Hid = this.state.hidValue
        importInfo.OprId = tableData[0].oprID !== undefined && tableData[0].oprID !== null ? tableData[0].oprID : this.state.oprId;
        importInfo.LoginUserName = username;
        request.ImportInfo = importInfo;
        let headerData: any = [];
        tableData.forEach(items => {
            let header: any = [];
            if (items.rowNo === 1) {
                let headerRow: any = {};
                headerRow.FileUniqueNo = items.uniqueno;
                headerRow.Hid = items.backHID === "" ? this.state.hidValue : items.backHID;
                //headerRow.BackLetterCode = items.mainLettercode === "" ? this.state.hotelName : items.mainLettercode;
                headerRow.OprId = items.oprID !== undefined && items.oprID !== null ? items.oprID : this.state.oprId;
                headerRow.VendorName = items.vendor;
                headerRow.Date = items.date;
                headerRow.CustomerNo = items.customerNo !== null && items.customerNo !== undefined && items.customerNo !== "" ? items.customerNo : items.backHID === "" ? this.state.hidValue : items.backHID;
                headerRow.AccPer = items.accper;
                headerRow.InternalNotes = items.internalnotes;
                headerRow.PaymentMethod = items.achType !== undefined && items.achType !== null ? items.achType : "";
                headerRow.CheckNo = items.checkNo !== undefined && items.checkNo !== null ? items.checkNo : "";
                headerRow.splitData = [];
                header.push(headerRow);
            }
            tableData.forEach(items2 => {
                if (items2.uniqueno === items.uniqueno) {
                    if (header.length > 0) {
                        let HeaderData = header.find(a => a.FileUniqueNo === items2.uniqueno)
                        let splitRow: any = {};
                        splitRow.EHID = items2.splitHid !== undefined && items2.splitHid !== null ? items2.splitHid : "";
                        splitRow.COA = items2.coa !== undefined && items2.coa !== null ? items2.coa : "";
                        splitRow.SubVendor = items2.subvendor !== undefined && items2.subvendor !== null ? items2.subvendor : "";
                        splitRow.Description = items2.description !== undefined && items2.description !== null ? items2.description : "";
                        splitRow.Amount = items2.amount;
                        HeaderData.splitData.push(splitRow)
                    }
                }
            });
            if (header.length > 0) {
                header.forEach(header => {
                    let totalAmount = 0;
                    header.splitData.forEach(item => totalAmount += Number(item.Amount))
                    header.TotalAmt = totalAmount.toString();
                    headerData.push(header);
                })
            }
        });
        request.HeaderData = headerData;
        registersevice.SaveBulkTemplateData("importDW", request, tableData)
            .then(async (result: any) => {
                //messageCode
                if (result?.saveStatus.toString().toLowerCase() === "success".toLocaleLowerCase()) {
                    toast.success("Data imported successfully.", { position: toast.POSITION.BOTTOM_RIGHT });
                    this.setState({ isBulkUpdated: true, isImportData: false, isCloseAlert: false });
                    setTimeout(() => {
                        this.bulkImportSlideoutClose();
                        $("#btnImportData").prop('disabled', false);
                    }, 1000);
                } else if (result?.saveStatus.toString().toLowerCase() === "Fail".toLocaleLowerCase()) {
                    let failResponse = result?.message.toString().toLowerCase();
                    if (failResponse === "PeriodNotActive".toLocaleLowerCase() || failResponse === "NotActive".toLocaleLowerCase()) {
                        $("#btnImportData").prop('disabled', false);
                        this.setState({ isImportData: false })
                        Utils.toastError("Please set active accounting period.", { position: toast.POSITION.BOTTOM_RIGHT });
                    } else if (failResponse === "Vendor does not exist.".toLocaleLowerCase()) {
                        $("#btnImportData").prop('disabled', false);
                        this.setState({ isImportData: false })
                        Utils.toastError("Please set active ", { position: toast.POSITION.BOTTOM_RIGHT });
                    } else if (failResponse === "EHIDShouldbeSame".toLocaleLowerCase()) {
                        $("#btnImportData").prop('disabled', false);
                        this.setState({ isImportData: false })
                        Utils.toastError("EHID should be the same.", { position: toast.POSITION.BOTTOM_RIGHT });
                    } else if (failResponse === "SubVendorNotExist".toLocaleLowerCase()) {
                        $("#btnImportData").prop('disabled', false);
                        this.setState({ isImportData: false })
                        Utils.toastError("Sub Vendor does not exist..", { position: toast.POSITION.BOTTOM_RIGHT });
                    } else if (failResponse === "FixedCOA".toLocaleLowerCase()) {
                        $("#btnImportData").prop('disabled', false);
                        this.setState({ isImportData: false })
                        Utils.toastError("Entry can not be made in Fixed COA.", { position: toast.POSITION.BOTTOM_RIGHT });
                    } else if (failResponse === "ConfidentialCOA".toLocaleLowerCase() || failResponse === "Confidential COA".toLocaleLowerCase()) {
                        $("#btnImportData").prop('disabled', false);
                        this.setState({ isImportData: false })
                        Utils.toastError("Transactions with Confidential COAs are not permitted.", { position: toast.POSITION.BOTTOM_RIGHT });
                    } else if (failResponse === "COANotExist".toLocaleLowerCase()) {
                        $("#btnImportData").prop('disabled', false);
                        this.setState({ isImportData: false })
                        Utils.toastError("COA does not exists.", { position: toast.POSITION.BOTTOM_RIGHT });
                    } else if (failResponse === "EHIDNotExist".toLocaleLowerCase()) {
                        $("#btnImportData").prop('disabled', false);
                        this.setState({ isImportData: false })
                        Utils.toastError("EHID does not exists.", { position: toast.POSITION.BOTTOM_RIGHT });
                    } else if (failResponse === "DuplicateInvoice".toLocaleLowerCase()) {
                        $("#btnImportData").prop('disabled', false);
                        this.setState({ isImportData: false })
                        Utils.toastError("Duplicate Invoice.", { position: toast.POSITION.BOTTOM_RIGHT });
                    } else if (failResponse === "customerNoNotexist".toLocaleLowerCase()) {
                        $("#btnImportData").prop('disabled', false);
                        this.setState({ isImportData: false })
                        Utils.toastError("Account Number not exist.", { position: toast.POSITION.BOTTOM_RIGHT });
                    } else {
                        $("#btnImportData").prop('disabled', false);
                        Utils.toastError(result?.message, { position: toast.POSITION.BOTTOM_RIGHT });
                    }
                }
                $("#btnImportData").prop('disabled', false);
                this.setState({ isImportData: false })
                resolve();
            })
            .catch((error) => {
                $("#btnImportData").prop('disabled', false);
                this.setState({ isImportData: false })
                Utils.toastError(error?.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                reject();
            });
    }

    onTAB = (control, event) => { }

    render() {
        let tableData = [...this.state.tableData];
        let isException = tableData.filter(item => item.isException || item?.isOpeningDate === "Wrong").length > 0;
        let isisSubVendor = tableData.filter(item => item.isException).length > 0;
        const files = this.state.uploadFiles.map(file => (
            <li key={file.name}>
                <div className="d-flex align-items-center">
                    <div className="file-name">{file.name}</div>
                    <div className="file-loader mr-auto"><ProgressBar variant="success" now={this.state.isProgress} /></div>
                    <div className="file-percentage">{this.state.isProgress} %</div>
                </div>
            </li>
        ));

        const notesIcon = (file) => {
            return (
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="3em" width="3em" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z"></path>
                    <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"></path>
                </svg>
            )
        }

        const hoverOutEllipsis = () => {
            $(".tooltip").removeClass("show");
        };

        const renderMenuItemChildren = (option, props, index) => {
            return (
                <>
                    <div key={option.id}>
                        <div className="vendor-name">
                            <EllipsisWithTooltip placement="bottom">
                                {option.label}
                            </EllipsisWithTooltip>
                        </div>
                    </div>
                </>
            );
        }

        const hasCOAControl = (row: any, rowIndex: any) => {
            return (
                <div onWheel={hoverOutEllipsis.bind(this)}>
                    {this.state.coaList.length > 0 && (
                        <div className="more-action single-select-image dropdown coa-select jecoaSelect BulkTypeheaddropdown">
                            <Typeahead
                                flip={true}
                                inputProps={{ className: "coa" + rowIndex.toString() }}
                                id={"coa-" + rowIndex.toString()}
                                options={this.state.coaList}
                                placeholder={"Enter COA"}
                                renderMenu={(results: any, menuProps) => (
                                    <Menu {...menuProps}>
                                        {results.map((result: any, index: any) => (
                                            <>
                                                <MenuItem option={result} position={index}>
                                                    <EllipsisWithTooltip placement="bottom">{result.label}</EllipsisWithTooltip>
                                                </MenuItem>
                                                {result.label == "" && (
                                                    <MenuItem className="load-more" option={result} position={index}>
                                                        Load More
                                                    </MenuItem>
                                                )}
                                                {(this.state.coaList.length > 0
                                                    && results[index - 1]?.orderno === 1 && result?.orderno === 2) && (
                                                        <Dropdown.Divider />
                                                    )}
                                            </>
                                        ))}
                                    </Menu>
                                )}
                                renderMenuItemChildren={renderMenuItemChildren}
                                onChange={(e) => this.handleSelectedItem(e, row, "coa")}
                                selected={row.defaultCOA}
                            />
                        </div>
                    )}
                </div>
            )
        }

        const hasVendorControl = (row: any, rowIndex: any, isMessage: any) => {
            return (
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip id={"VendorName"}>
                            {row.vendor === null || row.vendor === undefined || row.vendor === "" ? isMessage : row.vendor}
                        </Tooltip>
                    }>
                    <div onWheel={hoverOutEllipsis.bind(this)}>
                        <div className="">
                            {row.VendorList.length > 0 ? (
                                <div className="more-action single-select-image dropdown coa-select jecoaSelect BulkTypeheaddropdown">
                                    <Typeahead
                                        flip={true}
                                        inputProps={{ className: "vendor" + rowIndex.toString() }}
                                        id={"vendor-" + rowIndex.toString()}
                                        options={row.VendorList}
                                        placeholder={"Enter Vendor"}
                                        renderMenu={(results: any, menuProps) => (
                                            <Menu {...menuProps}>
                                                {results.map((result: any, index: any) => (
                                                    <>
                                                        <MenuItem option={result} position={index}>
                                                            <EllipsisWithTooltip placement="bottom">{result.vendor}</EllipsisWithTooltip>
                                                        </MenuItem>
                                                        {result.label == "" && (
                                                            <MenuItem className="load-more" option={result} position={index}>
                                                                Load More
                                                            </MenuItem>
                                                        )}
                                                        {(row.VendorList.length > 0
                                                            && results[index - 1]?.orderno === 1 && result?.orderno === 2) && (
                                                                <Dropdown.Divider />
                                                            )}
                                                    </>
                                                ))}
                                            </Menu>
                                        )}
                                        renderMenuItemChildren={renderMenuItemChildren}
                                        onChange={(e) => this.handleSelectedItem(e, row, "vendor")}
                                        selected={row.defaultVendor}
                                    />
                                </div>
                            ) : (
                                <Dropdown className="more-action disabled-area bg-blue single-select-image">
                                    <Dropdown.Toggle id="Modal-hid" className="filter-btn border-danger">
                                        <div className="d-flex align-items-center">
                                            <div className="txt-area">
                                                No vendor exist.
                                            </div>
                                        </div>
                                    </Dropdown.Toggle>
                                </Dropdown>
                            )}
                        </div>
                    </div>
                </OverlayTrigger>
            )
        }

        const hasSubVendorControl = (row: any, rowIndex: any) => {
            return (
                <div onWheel={hoverOutEllipsis.bind(this)}>
                    <div className="">
                        {row.subVendorList.length > 0 && row.splitHid !== "" ? (
                            <div className="more-action single-select-image dropdown coa-select jecoaSelect BulkTypeheaddropdown">
                                <Typeahead
                                    flip={true}
                                    inputProps={{ className: "subvendor" + rowIndex.toString() }}
                                    id={"vendor-" + rowIndex.toString()}
                                    options={row.subVendorList}
                                    placeholder={"Enter Sub Vendor"}
                                    renderMenu={(results: any, menuProps) => (
                                        <Menu {...menuProps}>
                                            {results.map((result: any, index: any) => (
                                                <>
                                                    <MenuItem option={result} position={index}>
                                                        <EllipsisWithTooltip placement="bottom">{result.vendor}</EllipsisWithTooltip>
                                                    </MenuItem>
                                                    {result.label == "" && (
                                                        <MenuItem className="load-more" option={result} position={index}>
                                                            Load More
                                                        </MenuItem>
                                                    )}
                                                    {(row.subVendorList.length > 0
                                                        && results[index - 1]?.orderno === 1 && result?.orderno === 2) && (
                                                            <Dropdown.Divider />
                                                        )}
                                                </>
                                            ))}
                                        </Menu>
                                    )}
                                    renderMenuItemChildren={renderMenuItemChildren}
                                    onChange={(e) => this.handleSelectedItem(e, row, "subVendor")}
                                    selected={row.defaultSubVendor}
                                />
                            </div>
                        ) : (
                            <Dropdown className="more-action disabled-area bg-blue single-select-image">
                                <Dropdown.Toggle id="Modal-hid" className="filter-btn">
                                    <div className="d-flex align-items-center">
                                        <div className="txt-area">
                                            No sub vendor exist.
                                        </div>
                                    </div>
                                </Dropdown.Toggle>
                            </Dropdown>
                        )}
                    </div>
                </div>
            )
        }

        const hasAccPerControl = (row: any, rowIndex: any) => {
            return (
                <div className="singleSelect bulkimportDropdown">
                    {row.accPeriodForEHID.length > 0 ? (
                        <Dropdown onSelect={(e) => this.handleRowEvent(e, row, "actPer")} className="more-action single-select-image">
                            <Dropdown.Toggle id="Modal-hid" className="filter-btn">
                                <div className="d-flex align-items-center">
                                    <div className="txt-area">
                                        <EllipsisWithTooltip placement="bottom">{row.accper === "" ? "Select" : row.accper}</EllipsisWithTooltip>
                                    </div>
                                    <div className="drop-arrow ml-0 d-flex">
                                        <FiChevronDown />
                                    </div>
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {row.accPeriodForEHID.map(
                                    (item: any, idx: any) => (
                                        <Dropdown.Item eventKey={item.name} key={idx}>
                                            <EllipsisWithTooltip placement="bottom">{item.name}</EllipsisWithTooltip>
                                        </Dropdown.Item>
                                    )
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    ) : (
                        <Dropdown className="more-action disabled-area bg-blue single-select-image">
                            <Dropdown.Toggle id="Modal-hid" className="filter-btn border-danger">
                                <div className="d-flex align-items-center">
                                    <div className="txt-area">
                                        No Accounting Period exist.
                                    </div>
                                </div>
                            </Dropdown.Toggle>
                        </Dropdown>
                    )}
                </div>
            )
        }

        const hasDateControl = (row: any, rowIndex: any, isNotValidDate: any) => {
            return (
                <div className="date-picker calender-picker bulkDropdownSmall">
                    <div className="posRelative">
                        <label htmlFor={"trdate_" + rowIndex} className="calanderOverlay"></label>
                        <label htmlFor={"trdate_" + rowIndex} className="drop-arrow">
                            <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.28122e-05 1.19724C3.28122e-05 0.889538 0.253262 0.653982 0.543288 0.653982C0.688297 0.653982 0.833313 0.708449 0.923855 0.816677L4.0023 4.0217L7.08074 0.816677C7.2979 0.599519 7.6424 0.599519 7.84116 0.816677C8.04064 1.01616 8.05831 1.34153 7.85955 1.55941L4.40046 5.18112C4.20098 5.39827 3.85721 5.39828 3.64005 5.19951L3.62166 5.18112L0.145009 1.55941C0.0544669 1.46887 0 1.34226 0 1.19724L3.28122e-05 1.19724Z" fill="#3F4347" />
                            </svg>
                        </label>
                    </div>
                    <DatePicker
                        id={"trdate_" + rowIndex}
                        className={row.date === "" || isNotValidDate ? "border-danger form-control" : "form-control"}
                        placeholderText={"MM/DD/YY"}
                        dateFormat="MM/dd/yy"
                        autoComplete="off"
                        selected={row.date === "" ? "" : new Date(row.date)}
                        onSelect={(event: any) => this.handleChange(event, row, 'trDate')}
                        onChange={(event: any) => this.handleChange(event, row, 'trDate')}
                    />
                </div>
            )
        }

        const hasAccountControl = (row: any, rowIndex: any, type: any) => {
            return (
                <div className="bulkimportDropdown" onClick={hoverOutEllipsis.bind(this)}>
                    {row.AccountList.length > 0 ? (
                        <Dropdown onSelect={(e) => this.handleSelectedItem(e, row, "accountListSelector")} className="more-action single-select-image">
                            <Dropdown.Toggle id="Modal-hid" className="filter-btn">
                                <div className="d-flex align-items-center">
                                    <div className="txt-area">
                                        <EllipsisWithTooltip placement="bottom">{row.accountName === "" ? "Search Account" : row.accountName}</EllipsisWithTooltip>
                                    </div>
                                    <div className="drop-arrow ml-0 d-flex">
                                        <FiChevronDown />
                                    </div>
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {row.AccountList.map(
                                    (item: any, idx: any) => (
                                        <Dropdown.Item eventKey={item.id} key={idx}>
                                            <EllipsisWithTooltip placement="bottom">{item.name}</EllipsisWithTooltip>
                                        </Dropdown.Item>
                                    )
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                        // <SingleSearchDropdownList
                        //     itemList={row.AccountList}
                        //     handleSelectedItem={(e) => this.handleSelectedItem(e, row, "accountListSelector")}
                        //     defaultItem={row.accountName === "" ? "Search Account" : row.accountName}
                        //     defaultText={"Search Account"}
                        //     defaultName={row.accountName === "" ? "Search Account" : row.accountName}
                        //     controlID="1"
                        //     id={"ddlEHID-" + rowIndex.toString()}
                        //     onTAB={this.onTAB.bind(this, "ddlACC-" + rowIndex.toString())}
                        // />
                    ) : (
                        <Dropdown className="more-action disabled-area bg-blue single-select-image">
                            <Dropdown.Toggle id="Modal-hid" className="filter-btn border-danger">
                                <div className="d-flex align-items-center">
                                    <div className="txt-area">
                                        No Account exist.
                                    </div>
                                </div>
                            </Dropdown.Toggle>
                        </Dropdown>
                    )}
                </div>
            )
        }

        const hasEHIDControl = (row: any, rowIndex: any, type: any) => {
            if (type === "header") {
                return (
                    <div className="bulkimportDropdown" onClick={hoverOutEllipsis.bind(this)}>
                        <SingleSearchDropdownList
                            itemList={this.state.ehidList}
                            handleSelectedItem={(e) => this.handleSelectedItem(e, row, "ehidHeaderSelector")}
                            defaultItem={row.mainLettercode === "" ? "EHID" : row.mainLettercode}
                            defaultText={"Search EHID"}
                            defaultName={row.mainLettercode === "" ? "EHID" : row.mainLettercode}
                            controlID="1"
                            id={"ddlEHID-" + rowIndex.toString()}
                            onTAB={this.onTAB.bind(this, "ddlEHID-" + rowIndex.toString())}
                        />
                    </div>
                )
            } {
                return (
                    <div className="bulkimportDropdown">
                        <SingleSearchDropdownList
                            itemList={row.splitHidList}
                            handleSelectedItem={(e) => this.handleSelectedItem(e, row, "ehidSplitSelector")}
                            defaultItem={row.splitHid === "" ? "EHID" : row.splitHid}
                            defaultText={"Search EHID"}
                            defaultName={row.splitHid === "" ? "EHID" : row.splitHid}
                            controlID="1"
                            id={"ddlEHID-" + rowIndex.toString()}
                            onTAB={this.onTAB.bind(this, "ddlEHID-" + rowIndex.toString())}
                        />
                    </div>
                )
            }
        }

        const importParentColumns = [
            {
                dataField: 'mainLettercode', text: 'EHID', headerClasses: "parentCol1", editable: false, hidden: this.getBulkPagetype("isHideSubVendorAndEhid"),
                formatter: (cell: any, row: any, rowIndex: any) => {
                    let isMessage = "";
                    let isInvalidControl = false;
                    if (row.mainLettercode === "" || row.mainLettercode === null) {
                        isMessage = "Please select a valid EHID.";
                        isInvalidControl = true;
                    } else if (row.checkEhid !== undefined && row.checkEhid.toString().toLowerCase() !== "Right".toLocaleLowerCase()) {
                        isMessage = "EHID does not exist!";
                        isInvalidControl = true;
                    } else {
                        isMessage = "";
                        isInvalidControl = false;
                    }
                    if (isInvalidControl) {
                        return (
                            <div className="customTooltipControl"><div className="toolTipIn">{isMessage}</div>
                                <div className="hasErrorInControl">{hasEHIDControl(row, rowIndex, "header")}</div>
                            </div>
                        )
                    } else {
                        return (<div className="noErrorInControl">{hasEHIDControl(row, rowIndex, "header")}</div>)
                    }
                }
            }, {
                dataField: 'oprID', text: 'Account', headerClasses: "parentCol2", editable: false, hidden: this.getBulkPagetype("isHideSubVendorAndEhid"),
                formatter: (cell: any, row: any, rowIndex: any) => {
                    let isMessage = "";
                    let isInvalidControl = false;
                    if (row.accountName === "") {
                        isMessage = "Please select a valid Account.";
                        isInvalidControl = true;
                    } else {
                        isMessage = "";
                        isInvalidControl = false;
                    }
                    if (isInvalidControl) {
                        return (
                            <div className="customTooltipControl"><div className="toolTipIn">{isMessage}</div>
                                <div className="hasErrorInControl">{hasAccountControl(row, rowIndex, "account")}</div>
                            </div>
                        )
                    } else {
                        return (<div className="noErrorInControl">{hasAccountControl(row, rowIndex, "account")}</div>)
                    }
                }
            }, {
                dataField: 'vendor', text: 'Vendor', headerClasses: "parentCol3", editable: false,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    let isMessage = "";
                    let isInvalidControl = false;

                    // if (row.isVendorToHId !== undefined && row.isVendorToHId.toString().toLowerCase() !== "Right".toLocaleLowerCase()) {
                    //     isMessage = row.isVendorToHId.split("Please")[0].trim();
                    //     isInvalidControl = true;
                    // } else 

                    if (row.isInactiveVendor !== undefined && row.isInactiveVendor.toString().toLowerCase() !== "Active".toLocaleLowerCase()) {
                        isMessage = row.isInactiveVendor.split("Please")[0].trim();
                        isInvalidControl = true;
                    } else if (row.defaultVendor.length === 0 || row.vendor === "" || row.vendor === null) {
                        isMessage = "Please enter a valid Vendor.";
                        isInvalidControl = true;
                    } else {
                        isMessage = "";
                        isInvalidControl = false;
                    }
                    if (isInvalidControl) {
                        return (
                            <div className="customTooltipControl">
                                {/* <div className="toolTipIn">{isMessage}</div> */}
                                <div className="hasErrorInControl">{hasVendorControl(row, rowIndex, isMessage)}</div>
                            </div>
                        )
                    } else {
                        return (
                            <div className="noErrorInControl">{hasVendorControl(row, rowIndex, isMessage)}</div>
                        )
                    }
                }
            }, {
                dataField: 'customerNo', text: 'Customer No.', headerClasses: "parentColCusNo", editable: false,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    let isGenericVendor = row.vendor.toString().toLowerCase() !== "GENERIC VENDOR".toLocaleLowerCase();
                    let hasValidVendor = row.VendorList.some((item: any) => item.vendor === row.vendor);
                    let isMessage = "Account No. does not exist!";
                    if (hasValidVendor && row.ehid !== "" && row.vendor.toString().toLowerCase() !== "GENERIC VENDOR".toLocaleLowerCase()) {
                        return (
                            <div className="isCusNoField hasVendorSearch posStatic">
                                {row.customerNo === "" && row.customerList.length == 0 && (
                                    <div className="customTooltipControl"><div className="toolTipIn">{isMessage}</div>
                                        <div onClick={(e) => this.GetVendorContract(row.vid, row, "custNo")}
                                            className={this.state.isAddContractPerm ?
                                                "ddBorderDanger singleSelect importDropdown importDropdownSmall hasCustomerList" :
                                                "disabled-area ddBorderDanger singleSelect importDropdown importDropdownSmall hasCustomerList"}>
                                            <Dropdown className="more-action single-select-image posUnset">
                                                <Dropdown.Toggle id="Modal-hid" className="filter-btn posRelative">
                                                    <div className="d-flex align-items-center">
                                                        <div className="txt-area">
                                                            <EllipsisWithTooltip placement="bottom">
                                                                {row.customerNo === ""
                                                                    ? "Add" : row.customerNo}
                                                            </EllipsisWithTooltip>
                                                        </div>
                                                        <div className="add-icon ml-0 d-flex">
                                                            {row.isVandorSpinner ? (
                                                                <Spinner size="sm" variant="success" animation="border" />) : (
                                                                <svg stroke="currentColor" fill="#2caf92" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M352 240v32c0 6.6-5.4 12-12 12h-88v88c0 6.6-5.4 12-12 12h-32c-6.6 0-12-5.4-12-12v-88h-88c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h88v-88c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v88h88c6.6 0 12 5.4 12 12zm96-160v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48zm-48 346V86c0-3.3-2.7-6-6-6H54c-3.3 0-6 2.7-6 6v340c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"></path>
                                                                </svg>
                                                            )}
                                                        </div>
                                                    </div>
                                                </Dropdown.Toggle>
                                            </Dropdown>
                                        </div>
                                    </div>
                                )}
                                {(row.customerNo !== "" || row.customerList.length > 0) &&
                                    (<>
                                        <div className={row.customerNo === "" ? "customTooltipControl" : ""}><div className="toolTipIn">{row.customerNo === "" && "Please Select a valid account No."}</div>
                                            <div className={row.customerNo === "" ?
                                                "ddBorderDanger singleSelect importDropdown importDropdownSmall hasCustomerList hasFixButton" :
                                                "singleSelect importDropdown importDropdownSmall hasCustomerList hasFixButton"}
                                            >

                                                <Dropdown
                                                    onClick={(e) => this.GetVendorContract(row.vid, row, "custNo")}
                                                    className="more-action single-select-image posUnset">
                                                    <Dropdown.Toggle id="Modal-hid" className="filter-btn posRelative">
                                                        <div className="d-flex align-items-center">
                                                            <div className="txt-area">
                                                                <EllipsisWithTooltip placement="bottom">
                                                                    {row.customerNo === "" ? "Select" : row.customerNo}</EllipsisWithTooltip>
                                                            </div>
                                                            <div className="drop-arrow ml-0 d-flex">
                                                                <FiChevronDown />
                                                            </div>
                                                        </div>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <div className="scrollableArea">
                                                            {row.customerList !== undefined && row.customerList.map((item: any, idx: any) => (
                                                                <Dropdown.Item eventKey={JSON.stringify(item)} key={idx} onSelect={(e) => this.handleRowEvent(e, row, "cusNo")}>
                                                                    <EllipsisWithTooltip placement="bottom">{item.accountNo}</EllipsisWithTooltip>
                                                                </Dropdown.Item>
                                                            ))}
                                                        </div>
                                                        {this.state.isAddContractPerm && (
                                                            <Dropdown.Item onClick={(e) => this.addNewCustomerNo(row)} style={{ borderTop: "1px solid #e0e3e6" }}>
                                                                <EllipsisWithTooltip placement="bottom"
                                                                ><strong style={{ color: "#2caf92" }}>Add New Contract</strong> </EllipsisWithTooltip>
                                                            </Dropdown.Item>
                                                        )}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </>
                                    )}
                            </div>
                        )
                    }
                    else {
                        return (
                            <Form.Control disabled={true} className={isGenericVendor ? "border-danger" : ""} type="text" placeholder={isGenericVendor ? "Cust. No" : ""} />
                        )
                    }
                }
            }, {
                dataField: 'date', text: 'Date', headerClasses: "parentCol4", editable: false,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    let isNotValidDate = row?.isOpeningDate.toLowerCase() === "wrong";
                    if (isNotValidDate) {
                        return (
                            <div className="customTooltipControl"><div className="toolTipIn">Date should be grater than account opening date.</div>
                                <div className="hasErrorInControl">{hasDateControl(row, rowIndex, isNotValidDate)}</div></div>
                        )
                    } else {
                        return (<div className="noErrorInControl" > {hasDateControl(row, rowIndex, isNotValidDate)}</div>)
                    }
                }
            }, {
                dataField: 'accper', text: 'A/C PERIOD', headerClasses: "parentCol5", editable: false,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    let hasSubVendorAndEhid = this.getBulkPagetype("hasSubVendorAndEhid");
                    let isMessage = "";
                    let isInvalidControl = false;
                    if (row.isActivePeriod !== undefined && row.isActivePeriod.toString().toLowerCase() !== "Yes".toLocaleLowerCase()) {
                        isMessage = "Accounting period is not active.";
                        isInvalidControl = true;
                    } else if (hasSubVendorAndEhid && row.isSplitPeriod !== undefined && row.isSplitPeriod.toString().toLowerCase() !== "Active".toLocaleLowerCase()) {
                        isMessage = row.isSplitPeriod.split("Please")[0].trim();
                        isInvalidControl = true;
                    } else if (row.accper === "") {
                        isMessage = "Please select accounting period.";
                        isInvalidControl = true;
                    } else {
                        isMessage = "";
                        isInvalidControl = false;
                    }
                    if (isInvalidControl) {
                        return (
                            <div className="customTooltipControl"><div className="toolTipIn">{isMessage}</div>
                                <div className="hasErrorInControl">{hasAccPerControl(row, rowIndex)}</div></div>
                        )
                    } else {
                        return (<div className="noErrorInControl" > {hasAccPerControl(row, rowIndex)}</div>)
                    }
                }
            }, {
                dataField: 'achType', text: 'Payment Type', headerClasses: "parentCol6", hidden: this.getBulkPagetype("isHidePaymentType"), editable: false,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    let isMessage = "";
                    let isInvalidControl = false;
                    if (row.achType !== null && row.achType === "" || row.achType === null) {
                        isMessage = "Please select payment Type";
                        isInvalidControl = true;
                    } else {
                        isMessage = "";
                        isInvalidControl = false;
                    }
                    if (isInvalidControl) {
                        return (
                            <div className="customTooltipControl"><div className="toolTipIn">{isMessage}</div>
                                <div className="bulkDropdownSmall isInValid">
                                    <SingleDropdownListWithoutSearch
                                        id={"paymentType"}
                                        itemList={row.paymentType}
                                        handleSelectedItem={(e) => this.handleSelectedItem(e, row, "paymentType")}
                                        defaultItem={row.achType === "" ? "Select Type" : row.achType.toUpperCase()}
                                        defaultText={"Payment Type"}
                                        defaultName={row.achType === "" ? "Select Type" : row.achType.toUpperCase()}
                                        controlID="5"
                                    />
                                </div>
                            </div>
                        )
                    } else {
                        return (
                            <div className="bulkDropdownSmall isValid">
                                <SingleDropdownListWithoutSearch
                                    id={"paymentType"}
                                    itemList={row.paymentType}
                                    handleSelectedItem={(e) => this.handleSelectedItem(e, row, "paymentType")}
                                    defaultItem={row.achType === "" ? "Select Type" : row.achType.toUpperCase()}
                                    defaultText={"Payment Type"}
                                    defaultName={row.achType === "" ? "Select Type" : row.achType.toUpperCase()}
                                    controlID="5"
                                />
                            </div>
                        )
                    }
                }
            }, {
                dataField: 'checkNo', text: 'Check No.', hidden: this.getBulkPagetype("isHidePaymentType"), headerClasses: "parentCol7",
                editable: (cell: any, row: any, rowIndex: any) => row.achType.toString().toLowerCase() !== "ACH".toLocaleLowerCase(),
                formatter: (cell: any, row: any, rowIndex: any) => {
                    let isMessage = "";
                    let isInvalidControl = false;
                    if (row.achType.toString().toLowerCase() === "CHECK".toLocaleLowerCase() && row.isDuplicate.toString().toLowerCase() !== "Right".toLocaleLowerCase()) {
                        isMessage = "Duplicate Check No.";
                        isInvalidControl = true;
                    } else if (row.achType.toString().toLowerCase() === "CHECK".toLocaleLowerCase() && (row.checkNo === "" || isNaN(Number(row.checkNo)))) {
                        isMessage = "Please enter a valid check No.";
                        isInvalidControl = true;
                    } else {
                        isMessage = "";
                        isInvalidControl = false;
                    }
                    if (isInvalidControl) {
                        return (
                            <div className="customTooltipControl"><div className="toolTipIn">{isMessage}</div>
                                <div className="hasRowError">
                                    <EllipsisWithTooltip placement="bottom">{row.checkNo}</EllipsisWithTooltip>
                                </div>
                            </div>
                        )
                    } else {
                        if (row.achType.toString().toLowerCase() === "ACH".toLocaleLowerCase() || row.achType === "") {
                            return (<Form.Control disabled={true} type="text" />)
                        } else {
                            return (
                                <div className="validEntry">
                                    <EllipsisWithTooltip placement="bottom">{row.checkNo}</EllipsisWithTooltip>
                                </div>
                            )
                        }
                    }

                }
            }, {
                dataField: 'internalnotes', text: 'Notes', headerClasses: "parentCol8",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <>
                            <div className="validEntry">
                                <EllipsisWithTooltip placement="bottom">{row.internalnotes}</EllipsisWithTooltip>
                            </div>
                        </>
                    )
                }
            },
            { dataField: 'ehid', text: '', hidden: true },
            { dataField: 'coa', text: '', hidden: true },
            { dataField: 'subvendor', text: '', hidden: true },
            { dataField: 'description', text: '', hidden: true },
            { dataField: 'amount', text: '', hidden: true },
            {
                dataField: "upload", text: <span className="IconPaperclip"><FiPaperclip /></span>, headerClasses: "iconCol", classes: "iconCol", editable: false,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <button type="button" className="btn btn-primary fa-trash bulkbtnDropzone">
                            <div className="bulkbtnDropzone">
                                <Dropzone key={row.uniqueno} onDrop={(e) => this.onAttachmentDrop(e, row.uniqueno)}
                                    accept="image/*,application/pdf,
                                            application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                                            application/vnd.ms-excel,
                                            application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                                            text/plain,
                                            .doc,.docx,.xls,.xlsx,.csv,.txt,.bmp,.tif,.ppt,.pptx,.rtf,.jpg,.png,.msg,.eml"
                                >
                                    {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                                        <input id="addAttachment" {...getInputProps()} title={row.attachmentMain.length > 99 ? (row.attachmentMain.length) : ""} />
                                    )}
                                </Dropzone>
                                <div className="bulkbtnDropzoneIn">
                                    <span className="IconPaperclip"><FiPaperclip /></span>
                                    {row.attachmentMain.length > 0 && (
                                        <span className="fileCounter">
                                            {row.attachmentMain.length > 99 ? 99 + "+" : row.attachmentMain.length}
                                        </span>)}
                                </div>
                            </div>
                        </button>
                    )
                }
            }, {
                dataField: "action", text: "", headerClasses: "iconCol", classes: "iconCol", editable: false,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <OverlayTrigger placement="top" overlay={<Tooltip id="addContract">Delete Row</Tooltip>}>
                            <button type="button" disabled={tableData.filter(item => item.rowNo === 1).length < 2} onClick={(e) => { this.deleteCurrentRow(row) }} className="btn btn-primary fa-trash">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="trashSVGIcon">
                                    <path d="M3.75226 15C3.75226 15.8273 4.42501 16.5 5.25226 16.5H12.7523C13.5795 16.5 14.2523 15.8273 14.2523 15V6H15.7523V4.5H13.5023H12.7523V3C12.7523 2.17275 12.0795 1.5 11.2523 1.5H6.75226C5.92501 1.5 5.25226 2.17275 5.25226 3V4.5H4.50226H2.25226V6H3.75226V15ZM6.75226 3H11.2523V4.5H6.75226V3ZM6.00226 6H12.0023H12.7523L12.753 15H5.25226V6H6.00226Z"></path><path d="M6.75226 7.5H8.25226V13.5H6.75226V7.5ZM9.75226 7.5H11.2523V13.5H9.75226V7.5Z"></path>
                                </svg>
                            </button>
                        </OverlayTrigger>
                    )
                }
            }];
        const importChildColumns = [{
            dataField: 'splitHid', text: 'Split Hid', headerClasses: "childCol1", editable: false, hidden: this.getBulkPagetype("isHideSubVendorAndEhid"),
            formatter: (cell: any, row: any, rowIndex: any) => {
                let hasValidEhid = row.splitHidList.filter(item => item.id === row.splitID).length > 0;
                let isMessage = "";
                let isInvalidControl = false;
                if (row.splitHid === "" || row.splitHid === null || !hasValidEhid) {
                    isMessage = "Please enter a valid Split EHID.";
                    isInvalidControl = true;
                } else if (row.isEHID !== undefined && row.isEHID.toString().toLowerCase() !== "Right".toLocaleLowerCase()) {
                    isMessage = "Split EHID does not exist!";
                    isInvalidControl = true;
                } else {
                    isMessage = "";
                    isInvalidControl = false;
                }
                if (isInvalidControl) {
                    return (
                        <div className="customTooltipControl"><div className="toolTipIn">{isMessage}</div>
                            <div className="hasErrorInControl">{hasEHIDControl(row, rowIndex, "split")}</div>
                        </div>
                    )
                } else {
                    return (<div className="noErrorInControl">{hasEHIDControl(row, rowIndex, "split")}</div>)
                }
            }
        }, {
            dataField: 'coa', text: 'COA', headerClasses: "childCol2", editable: false,
            formatter: (cell: any, row: any, rowIndex: any) => {
                let isMessage = "";
                let isInvalidControl = false;
                if (row.defaultCOA.length === 0) {
                    isMessage = "Please enter a valid COA";
                    isInvalidControl = true;
                } else {
                    isMessage = "";
                    isInvalidControl = false;
                }
                if (isInvalidControl) {
                    return (
                        <div className="customTooltipControl"><div className="toolTipIn">{isMessage}</div>
                            <div className="hasErrorInControl">{hasCOAControl(row, rowIndex)}</div>
                        </div>
                    )
                } else {
                    return (<div className="noErrorInControl">{hasCOAControl(row, rowIndex)}</div>)
                }
            }
        }, {
            dataField: 'subvendor', text: 'Sub Vendor', headerClasses: "childCol3", editable: false, hidden: this.getBulkPagetype("isHideSubVendorAndEhid"),
            formatter: (cell: any, row: any, rowIndex: any) => {
                let isMessage = "";
                let isInvalidControl = false;

                // if (row.defaultSubVendor.length === 0 || row.subvendor === "" || row.subvendor === null) {
                //     isMessage = "Please enter a valid Sub Vendor.";
                //     isInvalidControl = true;
                // } else 

                if (this.isSubVendorMatch(row)) {
                    isMessage = "Vendor and sub vendor can not be same.";
                    isInvalidControl = true;
                } else if (row.subvendor !== null && row.subvendor !== "") {
                    isMessage = row.subvendor;
                    isInvalidControl = false;
                } else {
                    isMessage = "";
                    isInvalidControl = false;
                }
                if (isInvalidControl) {
                    return (
                        <div className="customTooltipControl"><div className="toolTipIn">{isMessage}</div>
                            <div className="hasErrorInControl">{hasSubVendorControl(row, rowIndex)}</div>
                        </div>
                    )
                } else {
                    return (<div className="noErrorInControl customTooltipControl">
                        {(row.subvendor !== null && row.subvendor !== "") && (<div className="toolTipIn">{isMessage}</div>)}
                        {hasSubVendorControl(row, rowIndex)}</div>)
                }
            }
        }, {
            dataField: 'description', text: 'Description', headerClasses: "childCol4",
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <div className="validEntry">
                        <EllipsisWithTooltip placement="bottom">{row.description}</EllipsisWithTooltip>
                    </div>
                )
            }
        }, {
            dataField: 'amount', text: 'Amount', headerClasses: "amountCol", classes: "amountCol",
            formatter: (cell: any, row: any, rowIndex: any) => {
                let isInvalidControl = false;
                let isMessage = "";
                if (row.amount === "" || row.amount === null) { //Number(row.amount) === 0  || isNaN(Number(row.amount))
                    isMessage = "Invalid Amount.";
                    isInvalidControl = true;
                } else {
                    isMessage = "";
                    isInvalidControl = false;
                }
                if (isInvalidControl) {
                    return (
                        <div className="customTooltipControl"><div className="toolTipIn">{isMessage}</div>
                            <div className="hasRowError"><EllipsisWithTooltip placement="bottom">{row.amount}</EllipsisWithTooltip></div>
                        </div>
                    )
                } else {
                    return (<div className="validEntry"><EllipsisWithTooltip placement="bottom">{Utils.currencyFormatWithOutdoller(row.amount)}</EllipsisWithTooltip></div>)
                }
            }
        }]

        const handleOnExpand = (row, isExpand, rowIndex, e) => {
            if (isExpand) {
                this.setState(() => ({
                    isExpand: [...this.state.isExpand, row.id]
                }));
            } else {
                this.setState(() => ({
                    isExpand: this.state.isExpand.filter(x => x !== row.id)
                }));
            }
        }

        const expandBulkImportRow = {
            parentClassName: "parent-expand-foo",
            renderer: (row: any, rowIndex: any) => renderChild(rowIndex, row),
            showExpandColumn: true,
            expandByColumnOnly: true,
            expanded: this.state.isExpand,
            onExpand: handleOnExpand,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                if (isAnyExpands) {
                    return <div></div>;
                }
                return <div></div>;
            },
            expandColumnRenderer: ({ expanded }) => {
                if (expanded) {
                    return (
                        <span className="chevronExpended">
                            <img src={chevronRight} alt="chevron Right" />
                        </span>
                    );
                }
                return (
                    <span className="chevronCollpsed">
                        <img src={chevronRight} alt="chevron Right" />
                    </span>
                );
            },
        };

        const columnAttachments = (mainRow) => [{
            dataField: 'attachment',
            headerClasses: "attachmentCol",
            classes: "attachmentCol",
            text: '',
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <>
                        {notesIcon(row)}
                    </>
                )
            }
        }, {
            dataField: 'name',
            text: 'Name',
            headerClasses: "fileNameCol",
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <>
                        <EllipsisWithTooltip placement="bottom">{row.name.split(/\.(?=[^\.]+$)/)[0]}</EllipsisWithTooltip>
                        <div className="dateSection"><EllipsisWithTooltip placement="bottom">{new Date(row.lastModified).toLocaleDateString()}</EllipsisWithTooltip></div>
                    </>
                )
            }
        }, {
            dataField: 'type',
            headerClasses: "fileTypeCol",
            text: 'File Type',
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <EllipsisWithTooltip placement="bottom"><span className="badge badgeOrange">{row.path.split(/\.(?=[^\.]+$)/)[1]}</span></EllipsisWithTooltip>
                )
            }
        }, {
            dataField: 'action',
            text: '',
            headerClasses: "iconCol",
            classes: "iconCol",
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <OverlayTrigger placement="top" overlay={<Tooltip id="addContract">Delete Row</Tooltip>}>
                        <button type="button"
                            onClick={(e) => { this.deleteCurrentAttachment(rowIndex, mainRow) }}
                            className="btn btn-primary fa-trash">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="trashSVGIcon">
                                <path d="M3.75226 15C3.75226 15.8273 4.42501 16.5 5.25226 16.5H12.7523C13.5795 16.5 14.2523 15.8273 14.2523 15V6H15.7523V4.5H13.5023H12.7523V3C12.7523 2.17275 12.0795 1.5 11.2523 1.5H6.75226C5.92501 1.5 5.25226 2.17275 5.25226 3V4.5H4.50226H2.25226V6H3.75226V15ZM6.75226 3H11.2523V4.5H6.75226V3ZM6.00226 6H12.0023H12.7523L12.753 15H5.25226V6H6.00226Z"></path><path d="M6.75226 7.5H8.25226V13.5H6.75226V7.5ZM9.75226 7.5H11.2523V13.5H9.75226V7.5Z"></path>
                            </svg>
                        </button>
                    </OverlayTrigger>
                )
            }
        }]

        const renderChild = (rowIndex, row) => {
            let { tableData } = this.state;
            let uniqueID = Math.floor(Math.random() * 90 + 10);
            let uniqueID123 = Math.floor(Math.random() * 90 + 10);
            return (
                <div className="BulkImportChildTable">
                    <div className="childHeader"><strong>Splits</strong></div>
                    <BootstrapTable
                        key={uniqueID}
                        id={uniqueID}
                        keyField='id'
                        data={tableData.filter(item => item.uniqueno === row.uniqueno)}
                        columns={importChildColumns}
                        cellEdit={this.cellChildEdit}
                    />
                    {row.attachmentMain.length > 0 ? (
                        <>
                            <div className="mt-3 mb-3 childHeader"><strong>Attachments</strong></div>
                            <div className="bulkattachmentTable">
                                <BootstrapTable
                                    key={uniqueID123}
                                    id={uniqueID123}
                                    keyField='id'
                                    data={row.attachmentMain}
                                    columns={columnAttachments(row)}
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="mt-3 mb-3 childHeader"><strong>Attachments</strong></div>
                            <div className="BulkUploadSection">
                                <Dropzone key={row.uniqueno} onDrop={(e) => this.onAttachmentDrop(e, row.uniqueno)}
                                    //accept=".xlsx,.xls,.csv"
                                    accept="image/*,application/pdf,
                                                application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                                                application/vnd.ms-excel,
                                                application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                                                text/plain,
                                                .doc,.docx,.xls,.xlsx,.csv,.txt,.bmp,.tif,.ppt,.pptx,.rtf,.jpg,.png,.msg,.eml"
                                >
                                    {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                                        <>
                                            <section className={isDragActive ? "upload-area default drag-active " : "upload-area default"}>
                                                <div {...getRootProps({ className: 'dropzone d-flex align-items-center' })}>
                                                    <input {...getInputProps()} />
                                                    <p>Drag and drop or <span>browse files</span> to upload</p>
                                                </div>
                                            </section>
                                        </>
                                    )}
                                </Dropzone>
                            </div>
                        </>
                    )}
                </div>
            );
        }

        const bulkResponsiveList = () => {
            return (
                <div className="loader-responsive register">
                    <div className="performanceList">
                        {/* <div className="tableHeader height-40 loadingAnimation"></div> */}
                        <div className="tableList">
                            <ul>
                                <li className="d-flex">
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <>
                <ToastContainer autoClose={3000} enableMultiContainer />
                <Modal className={this.state.isFileImported ? "import-credit-card-result-modal importCreditCardModal" : "import-credit-card-modal"}
                    centered
                    show={true}
                    onHide={this.bulkImportSlideoutClose}
                    backdrop="static"
                    dialogClassName={this.state.isFileImported ? "modal-100w" : "modal-60w"}
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.state.isFileImported ?
                                (<>{this.getBulkPagetype("pageTitle")} <div className="sub-heading">{this.state.uploadFiles[0]?.path}</div></>) :
                                (<>{this.getBulkPagetype("pageTitle")}</>
                                )}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={this.state.key === "import-data" ? "" : "NoScroll"}>
                        {!this.state.isFileImported ? (
                            <>
                                <Dropzone onDrop={this.onDrop} accept=".xlsx,.csv" multiple={false}>
                                    {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                                        <>
                                            {this.state.uploadFiles.length == 0 && (
                                                <section className={isDragActive ? "container upload-area default drag-active " : "container upload-area default"}>
                                                    <div {...getRootProps({ className: 'dropzone d-flex align-items-center' })}>
                                                        <input {...getInputProps()} />
                                                        <p>Drag and drop here to start <br /><strong className="acceptedFiles">.xlsx or .csv</strong></p>
                                                    </div>
                                                </section>
                                            )}
                                            {this.state.uploadFiles.length > 0 && (
                                                <aside className="d-flex flex-column file-list">
                                                    <ul>{files}</ul>
                                                </aside>
                                            )}
                                        </>
                                    )}
                                </Dropzone>
                                {this.state.uploadFiles.length === 0 && (
                                    <div className="bulkImportPanel">
                                        <Link to="#" className="templateLink" onClick={this.downloadTemplate}>Download Template</Link>
                                    </div>
                                )}
                            </>
                        ) : (<>
                            <ToolkitProvider
                                keyField="rNo"
                                data={this.state.tableData}
                                columns={importParentColumns}
                                search
                            >
                                {(props: {
                                    searchProps: JSX.IntrinsicAttributes;
                                    baseProps: JSX.IntrinsicAttributes;
                                }) => (
                                    <>
                                        <div className="BulkImportSearchPanel">
                                            <SearchBar {...props.searchProps} placeholder={"Search..."} />
                                        </div>
                                        {(this.state.isSpinner) ? (
                                            <div className="bulkImportList">
                                                <div className="bulkImportList1 d-flex">
                                                    <div className="single-loader loadingAnimation"></div>
                                                </div>
                                                <>{bulkResponsiveList()}</>
                                                <>{bulkResponsiveList()}</>
                                                <>{bulkResponsiveList()}</>
                                            </div>
                                        ) : (
                                            <div className="grid-sec cusGridSec">
                                                <div className="bulkImportedNavTabs">
                                                    <div className="scroll-area bulkImportedTable">
                                                        <BootstrapTable
                                                            {...props.baseProps}
                                                            keyField="id"
                                                            data={this.state.tableData.filter(item => item.rowNo === 1)}
                                                            hover
                                                            noDataIndication={'No record found.'}
                                                            expandRow={expandBulkImportRow}
                                                            cellEdit={this.cellParentEdit}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </>)}
                            </ToolkitProvider>
                        </>)}
                    </Modal.Body>
                    {this.state.isFileImported && (
                        <Modal.Footer>
                            <button type="button"
                                onClick={this.handleBackScreen}
                                className="btn-outline-primary btn btn-primary mr-auto">Back</button>
                            <Button
                                id="btnImportData"
                                onClick={this.importData}
                                disabled={this.state.isSpinner || this.state.isImportData || isException}
                                variant="primary">
                                {this.state.isSpinner || this.state.isImportData && <><Spinner size="sm" animation="border" />{" "}</>} Import Data
                            </Button>
                        </Modal.Footer>
                    )}

                    <div className="isCusNoField hasVendorSearch">
                        <VendorSearch
                            ref={this.vendorSearch}
                            updateVendorList={this.updateVendorList}
                            handleVendorChange={this.handleVendorChange}
                            getCustomerNo={this.getCustomerNo}
                            HID={this.state.currentRowHid}
                            callFromPage="CreditCardStatementImport"
                            updateCustomerList={this.updateCustomerList}
                        />
                    </div>

                </Modal>
            </>
        );
    }
}