import React from "react";
import { Button, Modal, Dropdown, ProgressBar, Spinner, Tabs, Tab, Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import Dropzone from 'react-dropzone';
import { ToastContainer, toast } from "react-toastify";
import { Register as registersevice } from "../../../Common/Services/Register";
import { resolve, reject } from "q";
import "./creditCardStatement.scss";
import { FiChevronDown, FiTrash, FiPaperclip } from "react-icons/fi";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import chevronRight from "../../../Common/Assets/Images/icons/bx-chevron-right.svg";
import { DataImportRules } from "../../../Common/Services/DataImportRule";
import { confirmAlert } from "react-confirm-alert";
import OutsideClickHandler from "react-outside-click-handler";
import { ConditionalDataRule } from "../../../Common/Components/ConditionalDataRule";
import { DataImportRule } from "../../Accounting/ConditionalRuleSetting/DataImportRule"
import "../../Accounting/ConditionalRuleSetting/conditionalRule.scss";
import TableAutocomplete from "../../../Common/Components/TableAutocomplete";
import cellEditFactory from 'react-bootstrap-table2-editor';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "../../../Modules/Accounting/vendor.scss";
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import { VendorSearch } from "../../../Common/Components/VendorSearch";
import { RoleSubPermissionSetting } from "../../../Common/Services/RoleSubPermissionSetting";
import _ from "lodash";
import $, { event } from "jquery";
import { Utils } from "../../../Common/Utilis";
//import { isDate } from "moment";

const { SearchBar } = Search;
let currentImpRow: any = {};
let columnCounter = 5;
let changeAccCounter = 0;
let openRow: any = {}
let qualityFilter;
export class CreditCardStatementImport extends React.Component<any, any> {
    private DataImportRuleList: any;
    private ConditionalRule: any;
    private vendorSearch: any;
    private trdate: any
    private pageName = "TransactionPage";
    constructor(props: any) {
        super(props);
        this.DataImportRuleList = React.createRef();
        this.ConditionalRule = React.createRef();
        this.vendorSearch = React.createRef();
        this.trdate = React.createRef();
        this.state = {
            hidValue: props.hidValue,
            hotelName: props.hotelName,
            oprId: props.oprId,
            modalWidth: "modal-60w",
            isProgress: Math.floor(Math.random() * 30),
            uploadFiles: [],
            importCreditCardModal: true,
            formData: [],
            newArray: [],
            formDataHeaderItems: [],
            isImportScreen: false,

            isUploadedCCS: false,
            isSetingUpRules: true,

            coaList: [],
            COAListMain: [],
            accPeriodList: [],
            selectdefaultperiod: "Accounting Period",

            mappingRuleArray: [],
            selectedRule: { "ruleID": 0, "ruleName": "Select mapping rule" },
            mappingRuleField: [],
            mappingRuleFieldMain: [],
            selectedMapingRule: { "fieldID": 0, "fieldName": "Select" },
            defaultFilterException: "Filter by Exception",
            filterException: [{ id: 0, name: "All", eventKey: "all" }],
            filterName: "All",
            fieldMappedWithRule: [],
            columnCount: [],
            dynamicMappingFields: [],
            isSelectedRoleHasValue: false,
            isDynamicArrayHasValue: false,
            isProcessing: false,
            isVandorSpinnerPanel: false,

            creditCardsData: [],
            defaultCCD: [],
            importDataState: {},
            isImportData: false,
            isUpdated: false,
            isAddNewRole: false,
            newRuleName: "",
            newRuleError: false,
            newRuleErrorSet: false,
            newRuleErrorName: "",
            expandedCreditCardData: [],

            vendorListMain: [],
            conditionalDataRuleModal: false,
            actionType: "Edit",
            editVendor: "",
            editCOA: "",
            editIF: "",
            editContains: "",
            uniqueNo: "",
            isReprocessBtnShow: false,
            isProcessStart: false,
            dataRuleFrom: "Import",
            dataRuleCount: "0",
            key: "import-data",
            ccdUpdated: false,
            isSpinner: false,
            editedRow: [],
            isAddContractPerm: true,
            uploadAttachments: [],
            fileSize: 5242880,
            tabEnable: 0,
            tabDisable: -1,
        };
        this.onDrop = this.onDrop.bind(this);
        this.addNewCustomerNo = this.addNewCustomerNo.bind(this);
    }

    creditCardModalClose = () => {
        this.setState({ importCreditCardModal: false, newArray: [], isProgress: 0, isUploadedCCS: false }, () => {
            this.props.hideCreditCardModal(this.state.isUpdated);
            columnCounter = 5;
        });
    };

    getFileSize() {
        registersevice.GetFileSize()
            .then(async (result: any | null) => {
                if (result > 0) {
                    this.setState({ fileSize: result })
                }
            });
    }


    onDrop = (uploadFiles: any) => {
        let size = 5242880
        if (uploadFiles.length === 1) {
            if (uploadFiles[0].size > size) {
                Utils.toastError("File is greater than 5 MB.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            } else {
                this.setState({ uploadFiles }, () => {
                    this.CreditCardExcelImport();
                    this.getFileSize();
                });
            }
        } else if (uploadFiles.length > 1) {
            Utils.toastError("Multiple files are not allowed.", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        } else {
            Utils.toastError("Only .xlsx,.xls, and .csv file is acceptable.", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
    }

    onAttachmentDrop = (uploadAttachments: any, uniqueid: any) => {
        let size = this.state.fileSize//5242880//5MB
        let filesizeval = Number(Number(this.state.fileSize) / (1024 * 1024))
        let { creditCardsData } = this.state;
        if (uploadAttachments.length > 0) {
            if (uploadAttachments[0].size > size) {
                Utils.toastError("File is greater than " + filesizeval + "MB.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            } else {
                let hasAttachmentMain: any = [];
                uploadAttachments.forEach((items, index) => {
                    hasAttachmentMain.push(items);
                });
                creditCardsData = creditCardsData.map((items) => {
                    if (items.impt_Uniqueno123 === uniqueid) {
                        return {
                            ...items,
                            attachmentMain: items.attachmentMain.concat(hasAttachmentMain)
                        };
                    } else {
                        return items;
                    }
                });
                $("#addAttachment").val("");
                this.setState((curr: any) => ({ ...curr, creditCardsData, uploadAttachments, ccdUpdated: true }));
            }
        } else {
            Utils.toastError("Only .xlsx,.xls, and .csv file is acceptable.", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
    }

    CreditCardExcelImport = () => {
        let { newArray } = this.state;
        let formDataHeaderItems: any = []
        let columnCount: any = [];
        registersevice.CreditCardExcelImport(this.state.uploadFiles)
            .then(async (result: any) => {
                this.setState({ isProgress: 100 })
                if (result.success) {
                    let data = result?.result;
                    data.forEach((item: any) => formDataHeaderItems = Object.keys(item))
                    formDataHeaderItems.forEach((i: any) => columnCount.push(i))
                    data.forEach((items: any, i: any) => {
                        if (i < columnCounter) {
                            newArray.push(data[i]);
                        }
                    });
                    setTimeout(() => {
                        this.setState({ formData: data, isUploadedCCS: true, newArray, formDataHeaderItems, columnCount }, () => {
                            this.GetMappingRule();
                            this.GetMappingRuleField();
                            this.GetAccountingPeriod();
                        });
                    }, 1000)
                } else if (result?.message.toString().toLowerCase() === "LengthExceed".toLocaleLowerCase()) {
                    setTimeout(() => {
                        Utils.toastError("Import sheet should not have more than 100 characters in a cell.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }, 1000)
                    setTimeout(() => {
                        this.setState({ uploadFiles: [], isProgress: Math.floor(Math.random() * 30) });
                    }, 1500)
                } else if (result?.message.toString().toLocaleLowerCase() === "InvalidCallName".toLocaleLowerCase()) {
                    setTimeout(() => {
                        Utils.toastError("System keyword does not allowed please rename the column name.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }, 1000)
                    setTimeout(() => {
                        this.setState({ uploadFiles: [], isProgress: Math.floor(Math.random() * 30) });
                    }, 1500)
                } else {
                    setTimeout(() => {
                        Utils.toastError(result?.result, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }, 1000)
                    setTimeout(() => {
                        this.setState({ uploadFiles: [], isProgress: Math.floor(Math.random() * 30) });
                    }, 1500)
                }
                resolve();
            })
            .catch((error) => {
                setTimeout(() => {
                    Utils.toastError("No records are available to import.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }, 1000);
                setTimeout(() => {
                    this.setState({ uploadFiles: [], isProgress: Math.floor(Math.random() * 30) });
                }, 1500)
                reject();
            });
    }

    GetAccountingPeriod = () => {
        let accPeriod: any = [];
        accPeriod = Object.assign([], JSON.parse(JSON.stringify(this.state.accPeriodList)));
        let request: any = {};
        request.hotelID = this.props.hidValue;
        request.OprIdFrom = this.props.oprId;
        request.etype = "Operating";
        let { selectdefaultperiod } = this.state;
        registersevice.GetAccountingPeriod(request)
            .then(async (result: any | null) => {
                if (result !== null) {
                    result.forEach(element => {
                        let item: any = {};
                        item.id = element.accperiod;
                        item.name = element.accperiod;
                        accPeriod.push(item);
                    });
                    let defaultper = result[0].defaultPeriod;
                    if (accPeriod.length > 0) {
                        selectdefaultperiod = defaultper === "" ? "Accounting Period" : defaultper;
                    }
                    this.setState({ accPeriodList: accPeriod, selectdefaultperiod })
                }
                resolve();
            })
            .catch((error) => {
                reject();
            });
    };

    handleAccPeriod = (event: any) => {
        let { creditCardsData } = this.state;
        let hasDiffrentAccPer = creditCardsData.some((items: any) => items.actPer !== event);
        if (this.state.isImportScreen) {
            changeAccCounter++;
        }
        let pageName = this.props.pageName;
        if (pageName !== "InvoiceEntrySlideout") {
            if (this.state.isImportScreen && hasDiffrentAccPer) {
                confirmAlert({
                    title: "Alert!",
                    message: "Making this change will update the Accounting Periods for all rows. Would you like to proceed?",
                    buttons: [{
                        label: "Yes", onClick: () => {
                            this.setState({ selectdefaultperiod: event });
                            if (creditCardsData.length > 0) {
                                creditCardsData = creditCardsData.map((items) => {
                                    if (items.impt_Uniqueno123) {
                                        return { ...items, actPer: event };
                                    } else {
                                        return items;
                                    }
                                });
                                this.setState((curr: any) => ({ ...curr, creditCardsData, ccdUpdated: true }));
                            }
                        },
                    }, {
                        label: "No", onClick: () => {
                            changeAccCounter = 0;
                            reject()
                        },
                    }],
                    onClickOutside: () => {
                        changeAccCounter = 0;
                    },
                });
            }
            else {
                this.setState({ selectdefaultperiod: event });
                if (creditCardsData.length > 0) {
                    creditCardsData = creditCardsData.map((items) => {
                        if (items.impt_Uniqueno123) {
                            return { ...items, actPer: event };
                        } else {
                            return items;
                        }
                    });
                    this.setState((curr: any) => ({ ...curr, creditCardsData, ccdUpdated: true }));
                }
            }
        } else {
            this.setState({ selectdefaultperiod: event });
        }
    };

    loadNextRows = () => {
        let { formData, newArray } = this.state;
        columnCounter += 5;
        if (columnCounter - 5 < formData.length) {
            for (var i = columnCounter - 5; i < formData.length; i++) {
                if (i < columnCounter) {
                    newArray.push(formData[i]);
                }
            }
            this.setState({ newArray });
        } else {
            Utils.toastError("No more records found.", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
    };

    GetMappingRule = () => {
        let request: any = {};
        request.Type = "CashImportStatement";
        registersevice.GetMappingRule(request)
            .then(async (result: any) => {
                let data = result.result;
                data.map(item => (item["ruleName"] = item.ruleName.trim(), item["ruleNameNew"] = item.ruleName.toLowerCase().trim()))
                data = _.sortBy(data, "ruleNameNew");
                if (result.success) {
                    this.setState({ mappingRuleArray: data })
                } else {
                    Utils.toastError("Something went wrong.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
                resolve();
            })
            .catch((error) => {
                Utils.toastError(error?.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                reject();
            });
    }

    GetMappingRuleField = () => {
        let request: any = {};
        registersevice.GetMappingRuleField(request)
            .then(async (result: any) => {
                let data = result.result;
                if (result.success) {
                    let resultNew = data.filter(
                        (x) =>
                            x.displayFieldName.toLowerCase() === "category" ||
                            x.displayFieldName.toLowerCase() === "company" ||
                            x.displayFieldName.toLowerCase() === "description"
                    );
                    data.map(items => items["isFieldName"] = "");
                    this.setState({ mappingRuleField: data, mappingRuleFieldMain: resultNew })
                } else {
                    Utils.toastError(result?.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
                resolve();
            })
            .catch((error) => {
                Utils.toastError(error?.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                reject();
            });
    }

    handleSelectRule = (eventKey: any) => {
        if (eventKey === "addNewRule") {
            this.setState({ isAddNewRole: true });
        } else {
            this.setState({
                fieldMappedWithRule: [],
                isSelectedRoleHasValue: false,
                dynamicMappingFields: [],
                selectedRule: JSON.parse(eventKey)
            }, () => {
                this.GetFieldMappedWithRule(JSON.parse(eventKey))
            });
        }
    };

    addNewRoleClose = () => {
        this.setState({ isAddNewRole: false, newRuleName: "", newRuleError: false, newRuleErrorName: "" })
    };

    handleNewRuleName = (event) => {
        let { value } = event.target;
        this.setState({ newRuleName: value })
    }

    NewRuleNameValidation = () => {
        let { newRuleName, mappingRuleArray, newRuleError, newRuleErrorName } = this.state;
        if (newRuleName === "") {
            newRuleError = true;
            newRuleErrorName = "Please enter rule name.";
            Utils.toastError(newRuleErrorName, { position: toast.POSITION.BOTTOM_RIGHT });
            this.setState({ newRuleError, newRuleErrorName });
            return newRuleError;
        } else if (newRuleName.length > 50) {
            newRuleError = true;
            newRuleErrorName = "Maximum 50 characters allowed.";
            Utils.toastError(newRuleErrorName, { position: toast.POSITION.BOTTOM_RIGHT });
            this.setState({ newRuleError, newRuleErrorName });
            return newRuleError;
        } else {
            let hasVal = mappingRuleArray.filter(item => item?.ruleName.toString().toLocaleLowerCase() === newRuleName.toLocaleLowerCase())
            if (hasVal.length > 0) {
                newRuleErrorName = "Rule name already exists";
                newRuleError = true;
                Utils.toastError(newRuleErrorName, { position: toast.POSITION.BOTTOM_RIGHT });
                this.setState({ newRuleError, newRuleErrorName });
                return newRuleError;
            } else {
                newRuleError = false;
                this.setState({ newRuleError });
                return newRuleError;
            }
        }
    }

    addNewRoleName = () => {
        let { newRuleName, mappingRuleArray } = this.state;
        if (!this.NewRuleNameValidation()) {
            this.setState({ newRuleErrorSet: true })
            let request: any = {};
            request.RuleName = newRuleName;
            request.Type = "CashImportStatement";
            let newRule = {};
            registersevice.SaveCreditCardRuleName(request)
                .then(async (result: any) => {
                    let data = result.result;
                    if (result.success) {
                        toast.success("New rule added successfully", { position: toast.POSITION.BOTTOM_RIGHT });
                        let data = result?.result;
                        newRule = { "ruleID": data.id, "ruleName": data.result }
                        mappingRuleArray.push(newRule);
                        this.setState({ selectedRule: newRule, mappingRuleArray }, () => {
                            setTimeout(() => {
                                this.handleSelectRule(JSON.stringify(newRule));
                                this.addNewRoleClose();
                                this.setState({ newRuleErrorSet: false });
                            }, 1000)
                        })
                    } else {
                        this.setState({ newRuleErrorSet: false });
                        Utils.toastError(result?.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }
                    resolve();
                })
                .catch((error) => {
                    this.setState({ newRuleErrorSet: false })
                    Utils.toastError(error?.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    reject();
                });
        }

    }

    selectMapingRuleDrop = (event: any, index: any) => {
        let dynamicMappingFields = [...this.state.dynamicMappingFields];
        let isDynamicArrayHasValue;
        if (event === "SelectRole") {
            dynamicMappingFields[index].selectedName = "";
            dynamicMappingFields[index].showName = "";
        } else {
            let parseValue = JSON.parse(event);
            let hasEventVal = dynamicMappingFields.filter(item => item?.selectedName === parseValue.fieldName.toLowerCase())
            if (hasEventVal.length > 0) {
                hasEventVal[0].selectedName = "";
                hasEventVal[0].showName = "";
            }
            //Deposit / Payment / Payment_Deposit
            if (parseValue.fieldName.toString().toLocaleLowerCase() === "Deposit".toLocaleLowerCase()) {
                let hasAmount1 = dynamicMappingFields.filter(item => item?.selectedName.toString().toLowerCase().includes("amount1".toLowerCase()));
                let hasAmount2 = dynamicMappingFields.filter(item => item?.selectedName.toString().toLowerCase().includes("amount2".toLowerCase()));
                let Payment_Deposit = dynamicMappingFields.filter(item => item?.selectedName.toString().toLowerCase() === "Payment_Deposit".toLowerCase())
                if (hasAmount1.length > 0) { hasAmount1[0].selectedName = ""; hasAmount1[0].showName = "" };
                if (hasAmount2.length > 0) {
                    hasAmount2[0].selectedName = "";
                    hasAmount2[0].showName = "";
                }
                if (Payment_Deposit.length > 0) {
                    Payment_Deposit[0].selectedName = "";
                    Payment_Deposit[0].showName = "";
                }
            } else if (parseValue.fieldName.toString().toLocaleLowerCase() === "Payment".toLocaleLowerCase()) {
                let hasAmount1 = dynamicMappingFields.filter(item => item?.selectedName.toString().toLowerCase().includes("amount1".toLowerCase()));
                let hasAmount2 = dynamicMappingFields.filter(item => item?.selectedName.toString().toLowerCase().includes("amount2".toLowerCase()));
                let Payment_Deposit = dynamicMappingFields.filter(item => item?.selectedName.toString().toLowerCase() === "Payment_Deposit".toLowerCase())
                if (hasAmount1.length > 0) {
                    hasAmount1[0].selectedName = "";
                    hasAmount1[0].showName = "";
                }
                if (hasAmount2.length > 0) {
                    hasAmount2[0].selectedName = "";
                    hasAmount2[0].showName = "";
                }
                if (Payment_Deposit.length > 0) {
                    Payment_Deposit[0].selectedName = "";
                    Payment_Deposit[0].showName = "";
                }
            } else if (parseValue.fieldName.toString().toLocaleLowerCase() === "Payment_Deposit".toLocaleLowerCase()) {
                let hasAmount1 = dynamicMappingFields.filter(item => item?.selectedName.toString().toLowerCase().includes("amount1".toLowerCase()));
                let hasAmount2 = dynamicMappingFields.filter(item => item?.selectedName.toString().toLowerCase().includes("amount2".toLowerCase()));
                let hasDeposit = dynamicMappingFields.filter(item => item?.selectedName.toString().toLowerCase() === "Deposit".toLowerCase())
                let hasPayment = dynamicMappingFields.filter(item => item?.selectedName.toString().toLowerCase() === "Payment".toLowerCase())
                if (hasAmount1.length > 0) {
                    hasAmount1[0].selectedName = "";
                    hasAmount1[0].showName = "";
                }
                if (hasAmount2.length > 0) {
                    hasAmount2[0].selectedName = "";
                    hasAmount2[0].showName = "";
                }
                if (hasDeposit.length > 0) {
                    hasDeposit[0].selectedName = "";
                    hasDeposit[0].showName = "";
                }
                if (hasPayment.length > 0) {
                    hasPayment[0].selectedName = "";
                    hasPayment[0].showName = "";
                }
            }
            dynamicMappingFields[index].selectedName = parseValue?.fieldName.toLowerCase();
            dynamicMappingFields[index].showName = parseValue?.displayFieldName
        }
        isDynamicArrayHasValue = dynamicMappingFields.filter((items: any) => items?.selectedName !== "").length > 0;
        this.setState({ dynamicMappingFields, isDynamicArrayHasValue });
    };

    maptableHandle = (eventKey: any, columnName: any) => {
        this.setState({ selectedMapingRule: JSON.parse(eventKey) });
    };

    GetFieldMappedWithRule = (data) => {
        let request: any = {};
        request.RuleID = data.ruleID;
        let newDataArray: any = [];
        let dynamicMappingFields = [...this.state.dynamicMappingFields]
        let mappingRuleField = [...this.state.mappingRuleField];
        let isDynamicArrayHasValue;
        registersevice.GetFieldMappedWithRule(request)
            .then(async (result: any) => {
                let data = result.result;
                if (result.success) {
                    let formDataKeys = Object.keys(this.state.formData[0])
                    formDataKeys = formDataKeys.map(items => items.toString().replace(/ /g, ''));
                    formDataKeys.map((items, idx) => {
                        dynamicMappingFields.push({
                            id: idx + 1,
                            fieldName: items,
                            selectedName: "",
                            showName: ""
                        });
                    });
                    this.setState({ dynamicMappingFields, isSelectedRoleHasValue: true });
                    if (data.length > 0) {
                        newDataArray = Object.entries(data[0]).map(([key, value]) => ({ key, value }));
                        newDataArray.forEach((items, idx) => {
                            let itmVal = items.value;
                            let itmKey = items.key;
                            let hasvalidVal = itmVal !== "" && itmVal !== null && itmVal !== undefined ? itmVal : "";
                            hasvalidVal = hasvalidVal !== "" ? hasvalidVal.toString().toLocaleLowerCase() : hasvalidVal;
                            let hasvalidKey = itmKey !== "" && itmKey !== null && itmKey !== undefined ? itmKey : "";
                            hasvalidKey = hasvalidKey !== "" ? hasvalidKey.toString().toLocaleLowerCase() : hasvalidKey;
                            dynamicMappingFields.find((item, index) => {
                                if (item.fieldName === hasvalidVal) {
                                    dynamicMappingFields[index].selectedName =
                                        items.key.toString().toLocaleLowerCase() === "Credit".toLocaleLowerCase() ? "deposit" :
                                            items.key.toString().toLocaleLowerCase() === "Debit".toLocaleLowerCase() ? "payment" :
                                                items.key.replace(/ /g, '');
                                    dynamicMappingFields[index].showName =
                                        items.key.toString().toLocaleLowerCase() === "payment_Deposit".toLocaleLowerCase() ?
                                            "Debit/Credit" : items.key.replace(/ /g, '');
                                }
                            });
                            dynamicMappingFields.forEach((items, index) => {
                                if (items.selectedName.toString().toLocaleLowerCase() === "payment_deposit".toLocaleLowerCase()) {
                                    dynamicMappingFields.filter((item, idex) => {
                                        if (
                                            item.selectedName.toString().toLocaleLowerCase() === "payment".toLocaleLowerCase() ||
                                            item.selectedName.toString().toLocaleLowerCase() === "deposit".toLocaleLowerCase()) {
                                            dynamicMappingFields[idex].selectedName = "";
                                            dynamicMappingFields[idex].showName = "";
                                        }
                                    })
                                } else if (
                                    items.selectedName.toString().toLocaleLowerCase().includes("amount1".toLocaleLowerCase()) ||
                                    items.selectedName.toString().toLocaleLowerCase().includes("amount2".toLocaleLowerCase())
                                ) {
                                    dynamicMappingFields.filter((item, idex) => {
                                        if (
                                            item.selectedName.toString().toLocaleLowerCase() === "payment".toLocaleLowerCase() ||
                                            item.selectedName.toString().toLocaleLowerCase() === "deposit".toLocaleLowerCase() ||
                                            item.selectedName.toString().toLocaleLowerCase() === "payment_deposit".toLocaleLowerCase()
                                        ) {
                                            dynamicMappingFields[idex].selectedName = "";
                                            dynamicMappingFields[idex].showName = "";
                                        }
                                    })
                                }
                            });

                            let addlessAmt1 = data[0].addlessAmt1 !== null && data[0].addlessAmt1 !== "" && data[0].addlessAmt1 !== undefined;
                            let addlessAmt2 = data[0].addlessAmt2 !== null && data[0].addlessAmt2 !== "" && data[0].addlessAmt2 !== undefined;
                            if (hasvalidKey === "amount1") {
                                dynamicMappingFields.forEach((item, index) => {
                                    if (item.selectedName.includes(hasvalidKey)) {
                                        if (addlessAmt1) {
                                            dynamicMappingFields[index].selectedName = "Amount1 (" + data[0].addlessAmt1 + ")";
                                            dynamicMappingFields[index].showName = "Amount1 (" + data[0].addlessAmt1 + ")";
                                        } else {
                                            dynamicMappingFields[index].selectedName = "";
                                            dynamicMappingFields[index].showName = "";
                                        }
                                    }
                                });
                            }
                            if (hasvalidKey === "amount2") {
                                dynamicMappingFields.forEach((item, index) => {
                                    if (item.selectedName.includes(hasvalidKey)) {
                                        if (addlessAmt2) {
                                            dynamicMappingFields[index].selectedName = "Amount2 (" + data[0].addlessAmt2 + ")";
                                            dynamicMappingFields[index].showName = "Amount2 (" + data[0].addlessAmt2 + ")";
                                        } else {
                                            dynamicMappingFields[index].selectedName = "";
                                            dynamicMappingFields[index].showName = "";
                                        }
                                    }
                                });
                            }
                            mappingRuleField = mappingRuleField.filter((item) => (
                                item?.fieldName.toString().toLocaleLowerCase() !== "AddlessAmt1".toLocaleLowerCase() &&
                                item?.fieldName.toString().toLocaleLowerCase() !== "AddlessAmt2".toLocaleLowerCase() &&
                                item?.fieldName.toString().toLocaleLowerCase() !== "Amount1".toLocaleLowerCase() &&
                                item?.fieldName.toString().toLocaleLowerCase() !== "Amount2".toLocaleLowerCase()
                            ));
                            mappingRuleField = mappingRuleField.filter((item) => item?.fieldName !== "AddlessAmt1" && item?.fieldName !== "AddlessAmt2");
                            isDynamicArrayHasValue = this.state.dynamicMappingFields.filter((items: any) => items.selectedName !== "").length > 0;
                            this.setState({ dynamicMappingFields, isDynamicArrayHasValue, mappingRuleField })
                        })
                        this.setState({ fieldMappedWithRule: data })
                    } else {
                        mappingRuleField = mappingRuleField.filter((item) => (
                            item?.fieldName.toString().toLocaleLowerCase() !== "AddlessAmt1".toLocaleLowerCase() &&
                            item?.fieldName.toString().toLocaleLowerCase() !== "AddlessAmt2".toLocaleLowerCase() &&
                            item?.fieldName.toString().toLocaleLowerCase() !== "Amount1".toLocaleLowerCase() &&
                            item?.fieldName.toString().toLocaleLowerCase() !== "Amount2".toLocaleLowerCase()
                        ));
                        this.setState({ mappingRuleField })
                    }
                } else {
                    Utils.toastError(result?.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
                resolve();
            })
            .catch((error) => {
                Utils.toastError(error?.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                reject();
            });
    }

    handleNext = () => {
        this.setState({ isProcessing: true, isImportScreen: true });
        let { selectedRule, dynamicMappingFields, fieldMappedWithRule } = this.state;
        let request: any = {};
        let isDate = dynamicMappingFields.filter((mapitem: any) => mapitem?.selectedName?.toString().toLowerCase() === "date".toLocaleLowerCase());
        let isCompany = dynamicMappingFields.filter((mapitem: any) => mapitem?.selectedName?.toString().toLowerCase() === "company".toLocaleLowerCase());
        let isCategory = dynamicMappingFields.filter((mapitem: any) => mapitem?.selectedName?.toString().toLowerCase() === "category".toLocaleLowerCase());
        let isDscrptn1 = dynamicMappingFields.filter((mapitem: any) => mapitem?.selectedName?.toString().toLowerCase() === "description1".toLocaleLowerCase());
        let isDscrptn2 = dynamicMappingFields.filter((mapitem: any) => mapitem?.selectedName?.toString().toLowerCase() === "description2".toLocaleLowerCase());
        let isDscrptn3 = dynamicMappingFields.filter((mapitem: any) => mapitem?.selectedName?.toString().toLowerCase() === "description3".toLocaleLowerCase());
        let isAmount1 = dynamicMappingFields.filter((mapitem: any) => mapitem?.selectedName.toString().toLowerCase().includes("amount1".toLowerCase()))
        let isAmount2 = dynamicMappingFields.filter((mapitem: any) => mapitem?.selectedName.toString().toLowerCase().includes("amount2".toLowerCase()))
        let isPayment = dynamicMappingFields.filter((mapitem: any) => mapitem?.selectedName?.toString().toLowerCase() === "payment".toLowerCase())
        let isDeposit = dynamicMappingFields.filter((mapitem: any) => mapitem?.selectedName?.toString().toLowerCase() === "deposit".toLowerCase())
        let isPDAmunt = dynamicMappingFields.filter((mapitem: any) => mapitem?.selectedName?.toString().toLowerCase() === "payment_Deposit".toLowerCase())
        let isStatus = dynamicMappingFields.filter((mapitem: any) => mapitem?.selectedName?.toString().toLowerCase() === "status".toLocaleLowerCase());
        let isInvoiceNo = dynamicMappingFields.filter((mapitem: any) => mapitem?.selectedName?.toString().toLowerCase() === "invoiceno".toLocaleLowerCase());

        request.Date = isDate.length > 0 ? isDate[0]?.fieldName !== null ? isDate[0]?.fieldName : "" : "";
        request.Company = isCompany.length > 0 ? isCompany[0]?.fieldName !== null ? isCompany[0]?.fieldName : "" : "";
        request.Category = isCategory.length > 0 ? isCategory[0]?.fieldName !== null ? isCategory[0]?.fieldName : "" : "";
        request.Description1 = isDscrptn1.length > 0 ? isDscrptn1[0]?.fieldName !== null ? isDscrptn1[0]?.fieldName : "" : "";
        request.Description2 = isDscrptn2.length > 0 ? isDscrptn2[0]?.fieldName !== null ? isDscrptn2[0]?.fieldName : "" : "";
        request.Description3 = isDscrptn3.length > 0 ? isDscrptn3[0]?.fieldName !== null ? isDscrptn3[0]?.fieldName : "" : "";
        request.Amount1 = isAmount1.length > 0 ? isAmount1[0]?.fieldName !== null ? isAmount1[0]?.fieldName : "" : "";  // Payment
        request.Amount2 = isAmount2.length > 0 ? isAmount2[0]?.fieldName !== null ? isAmount2[0]?.fieldName : "" : "";  // Deposit
        request.AddlessAmt1 = isAmount1.length > 0 ? fieldMappedWithRule.length > 0 ? fieldMappedWithRule[0]?.addlessAmt1 !== null ? fieldMappedWithRule[0]?.addlessAmt1 : "" : "" : "";
        request.AddlessAmt2 = isAmount2.length > 0 ? fieldMappedWithRule.length > 0 ? fieldMappedWithRule[0]?.addlessAmt2 !== null ? fieldMappedWithRule[0]?.addlessAmt2 : "" : "" : "";
        request.Status = isStatus.length > 0 ? isStatus[0]?.fieldName !== null ? isStatus[0]?.fieldName : "" : "";
        request.Payment = isPayment.length > 0 ? isPayment[0]?.fieldName !== null ? isPayment[0]?.fieldName : "" : "";
        request.Deposit = isDeposit.length > 0 ? isDeposit[0]?.fieldName !== null ? isDeposit[0]?.fieldName : "" : "";
        request.Payment_Deposit = isPDAmunt.length > 0 ? isPDAmunt[0]?.fieldName !== null ? isPDAmunt[0]?.fieldName : "" : "";

        request.AcPeriod = "";
        request.InvoiceNo = isInvoiceNo.length > 0 ? isInvoiceNo[0]?.fieldName : "";
        request.Type = "CashImportStatement";
        request.OprId = this.state.oprId;
        request.RuleID = selectedRule.ruleID;
        request.Flag = "CreditCardAcct";

        if (this.state.selectdefaultperiod === "Accounting Period") {
            Utils.toastError("Please select Accounting Period", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            this.setState({ isProcessing: false });
        } else {
            registersevice.SaveImportStatementDataMapping(request)
                .then(async (result: any) => {
                    if (result.success) {
                        this.InsertCashImportStatementTableData();
                        //this.getVendorPermission();
                    } else {
                        Utils.toastError(result?.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        this.setState({ isProcessing: false });
                    }
                    resolve();
                })
                .catch((error) => {
                    this.setState({ isProcessing: false, isProcessStart: false });
                    Utils.toastError(error?.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    reject();
                });
        }
    }

    InsertCashImportStatementTableData() {
        let { formDataHeaderItems, hidValue, formData, selectedRule, dynamicMappingFields } = this.state;
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let userName = storage.userName;
        let request: any = {};
        let hasMapItems: any = [];
        dynamicMappingFields.filter((itmDy) => {
            hasMapItems.push(itmDy.selectedName);
            hasMapItems = hasMapItems.map((itm: any) => itm.toString().toLowerCase().replace(/ /g, ''));
        });
        let formDataArray: any = [];
        formData.forEach((items: any, idx: any) => {
            let hasKey: any = Object.keys(items);
            for (let i = 0; i < hasMapItems.length; i++) {
                if (hasMapItems[i]) {
                    let frmCurVal: any = formData[idx][hasKey[i]];
                    frmCurVal = frmCurVal !== null ? frmCurVal.toString().includes(",") ? frmCurVal.replace(/,/g, 'Ø') : frmCurVal : frmCurVal;
                    let hasMapValue: any = frmCurVal === null || frmCurVal === "" ? "NullColVal123" : frmCurVal;
                    formDataArray.push(hasMapValue);
                } else {
                    formDataArray.push("NullColVal123");
                }
            }
            formDataArray.push("<break>");
        });
        let requestRows = formDataArray.toString();
        requestRows = requestRows.replaceAll(",", "[stop]");
        requestRows = requestRows.split("<break>[stop]");
        requestRows = requestRows.map((item) => item.replaceAll("<break>", ""));
        request.UserName = userName;
        request.ColNames = formDataHeaderItems.toString().replace(/ /g, '');
        request.RowsValues = requestRows;
        request.HID = this.state.hidValue;
        request.RuleID = selectedRule.ruleID;
        request.OprId = this.state.oprId;
        registersevice.InsertCashImportStatementTableData(request)
            .then(async (result: any) => {
                debugger
                let data = result.result;
                if (data.length > 0) {
                    if (data[0]?.result.toString().toLocaleLowerCase() === "Success".toLocaleLowerCase()) {
                        let request: any = {}
                        request.FileNo = data[0].id;
                        request.HID = hidValue;
                        request.RuleID = selectedRule.ruleID;
                        request.CardUniqueNo = this.state.oprId;
                        this.setState({ importDataState: request })
                        this.GetCashCCImportRuleMapping(request);
                    } else {
                        this.setState({ isProcessing: false })
                        Utils.toastError("Something went wrong.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }
                } else {
                    this.setState({ isProcessing: false, isProcessStart: false })
                    Utils.toastError("Something went wrong.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
                resolve();
            })
            .catch((error) => {
                this.setState({ isProcessing: false, isProcessStart: false })
                Utils.toastError(error?.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                reject();
            });
    }

    GetCashCCImportRuleMapping = (request) => {
        let { selectdefaultperiod } = this.state;
        this.setState({ isSpinner: true })
        let pageName = this.props.pageName;
        registersevice.GetCashCCImportRuleMapping(request)
            .then(async (result: any) => {
                let data = result?.result;
                if (result.success) {
                    data.map((item, index) => (
                        item["rowId"] = "rowId_" + index,
                        item["exception"] = "",
                        item["actPer"] = selectdefaultperiod,
                        item["defaultCOA"] = [],
                        item["defaultVendor"] = [],
                        item["isVandorSpinner"] = false,
                        item["attachmentMain"] = [],
                        item["exEr"] = ""
                    ));

                    if (pageName !== "InvoiceEntrySlideout") {
                        data.map((item) => (item["customerList"] = []));
                    }
                    this.setState({
                        isProcessStart: false,
                        isReprocessBtnShow: false
                    }, () => {
                        this.GetHotelCOAlist(data);
                        //this.GetCOAListConditionalRule();
                    })
                } else {
                    this.setState({ isProcessing: false, isProcessStart: false, isSpinner: false })
                    Utils.toastError(result?.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
                resolve();
            })
            .catch((error) => {
                this.setState({ isProcessing: false, isProcessStart: false, isSpinner: false })
                Utils.toastError(error?.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                reject();
            });
    }

    GetHotelCOAlist = (creditCardsData) => {
        let request: any = {};
        request.hotelID = this.state.hidValue;
        registersevice.GetHotelCOAlist(request)
            .then(async (result: any | null) => {
                if (result !== null) {
                    let coaData = result.transactionChartList;
                    creditCardsData.forEach((ccd: any, idx: any) => {
                        coaData.forEach((coa: any) => {
                            if (ccd.coa === coa.id) {
                                let defaultCOA: any = [];
                                let objCOA: any = {};
                                objCOA.id = coa?.id;
                                objCOA.label = coa?.label;
                                objCOA.coAname = coa?.coAname;
                                defaultCOA.push(objCOA);
                                creditCardsData[idx].defaultCOA = defaultCOA;
                            }
                        })
                    })
                    this.setState({
                        coaList: coaData,
                        COAListMain: coaData
                    }, () => {
                        this.GetVendorList(creditCardsData);
                    })
                } else {
                    Utils.toastError("Something went wrong", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    this.setState({ isProcessing: false, isProcessStart: false, isSpinner: false })
                }
                resolve();
            })
            .catch((error) => {
                reject();
                this.setState({ isProcessing: false, isProcessStart: false, isSpinner: false })
            });
    };

    GetCOAListConditionalRule = () => {
        let request: any = {};
        request.VID = 0;
        DataImportRules.GetCOAList(request)
            .then(async (result: any) => {
                let data = result.result;
                if (result.success) {
                    this.setState({ COAListMain: data });
                } else {
                    Utils.toastError(result?.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
                resolve();
            })
            .catch((error) => {
                Utils.toastError(error?.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                reject();
            });
    };

    GetVendorList = (creditCardsData) => {
        let request: any = {};
        //request.HID = this.state.hidValue;
        registersevice.GetHotelCOAlist(request)
            .then(async (result: any) => {
                if (result !== null) {
                    let vendorList: any = result.vendorBYHIDList;
                    vendorList = vendorList.filter(item => item.name.toString().toLowerCase() !== "SELECT SUB VENDOR".toLocaleLowerCase());
                    vendorList.map((element: any) => (element["label"] = element?.name, element["vendor"] = element?.name));
                    let vendorData: any = [];
                    if (this.props.pageName === "InvoiceEntrySlideout") {
                        vendorData = vendorList.filter(x => x.hid === this.state.hidValue);
                    } else {
                        let map = new Map();
                        vendorList.forEach((item: any) => {
                            if (!map.has(item.name)) {
                                map.set(item.name, true);
                                vendorData.push(item);
                            }
                        });
                    }
                    creditCardsData.map((ccd: any, idx: any) => {
                        let defaultVendor: any = [];
                        vendorData.forEach((vendor: any) => {
                            if (ccd.subVendor.toString().toLowerCase() === vendor.vendor.toString().toLowerCase()) {
                                let objVEN: any = {};
                                objVEN.id = vendor.id;
                                objVEN.label = vendor?.name;
                                objVEN.vendor = vendor?.name;
                                defaultVendor.push(objVEN);
                            }
                        })
                        if (defaultVendor.length > 0) {
                            defaultVendor.forEach(items => {
                                ccd.defaultVendor.push(items);
                                ccd.subVendor = items?.vendor;
                            })
                        } else {
                            ccd.defaultVendor = [];
                            ccd.subVendor = '';
                        }
                    })

                    // creditCardsData[idx].defaultVendor = defaultVendor;
                    // creditCardsData[idx].vendor=vendor?.name;
                    this.setState({
                        vendorListMain: vendorData,
                    }, () => {
                        setTimeout(() => {
                            this.setState({
                                creditCardsData,
                                isProcessStart: false,
                                isReprocessBtnShow: false,
                                isSpinner: false,
                                expandedCreditCardData: creditCardsData,
                            }, () => {
                                setTimeout(() => {
                                    this.setException();
                                    this.isValidPayment();
                                }, 500);

                            })
                        }, 100);
                    });
                } else {
                    Utils.toastError(result?.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    this.setState({ isProcessing: false, isProcessStart: false, isSpinner: false })
                }
                resolve();
            })
            .catch((error) => {
                this.setState({ isProcessing: false, isProcessStart: false, isSpinner: false })
                Utils.toastError(error?.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                reject();
            });
    };

    deleteCurrentRow = (row) => {
        let { creditCardsData, filterException } = this.state;
        confirmAlert({
            title: "Delete entry",
            message: "Are you sure you want to delete this Entry?",
            buttons: [{
                label: "Yes", onClick: () => {
                    creditCardsData = creditCardsData.filter((item) => item.impt_Uniqueno123 !== row.impt_Uniqueno123);
                    setTimeout(() => {
                        this.setState({ filterException, creditCardsData, ccdUpdated: true }, () => {
                            setTimeout(() => { this.finalExSet(row) }, 500);
                        });
                    }, 1000)
                },
            }, {
                label: "No", onClick: () => reject(),
            }],
        });
    }

    finalExSet = (row: any) => {
        let { creditCardsData } = this.state;
        let pageName = this.props.pageName;
        let isMisinrow = false;
        let isPayment = this.hasValidPayment(row);
        creditCardsData.map((items) => {
            if (items.impt_Uniqueno123 === row.impt_Uniqueno123) {
                if (pageName !== "InvoiceEntrySlideout") {
                    if (row.payment_Deposit.toString().toLowerCase() === "Payment_Deposit".toLowerCase()) {
                        isMisinrow = (
                            items.date === "" ||
                            items.coa === "" ||
                            Number(items.payment) === 0 && Number(items.deposit) === 0 ||
                            items.subVendor === "" ||
                            items.customerNo === "More" || items.customerNo === ""
                        );
                    } else if (row.payment_Deposit.toString().toLowerCase() === "AmountCol".toLowerCase()) {
                        isMisinrow = (
                            items.date === "" ||
                            items.coa === "" ||
                            Number(items.totalAmount) === 0 ||
                            items.subVendor === "" ||
                            items.customerNo === "More" ||
                            items.customerNo === ""
                        );
                    }
                } else {
                    if (row.payment_Deposit.toString().toLowerCase() === "Payment_Deposit".toLowerCase()) {
                        isMisinrow = (
                            //items.date === "" ||
                            items.coa === "" ||
                            Number(items.payment) === 0 && Number(items.deposit) === 0 /*|| items.subVendor === ""*/);
                    } else if (row.payment_Deposit.toString().toLowerCase() === "AmountCol".toLowerCase()) {
                        isMisinrow = (
                            //items.date === "" ||
                            items.coa === "" ||
                            Number(items.totalAmount) === 0 /*|| items.subVendor === ""*/
                        );
                    }
                }
                if (isMisinrow) {
                    (items["exception"] = "Missing Data");
                    (items["exEr"] = 0);
                } else {
                    (items["exception"] = "");
                    (items["exEr"] = '');
                    if (pageName !== "InvoiceEntrySlideout") {
                        this.CheckDuplicateCCImportData(items);
                    } else {
                        if (row.payment_Deposit.toString().toLowerCase() === "Payment_Deposit".toLocaleLowerCase()) {
                            if (isPayment && Number(row.payment) === 0) {
                                (items["exception"] = "Payment");
                                (items["exEr"] = 1);
                            } else {
                                (items["exception"] = "");
                                (items["exEr"] = "");
                            }
                        } else if (row.payment_Deposit.toString().toLowerCase() === "AmountCol".toLocaleLowerCase()) {
                            (items["exception"] = "");
                            // if (Number(row.totalAmount) !== 0) {
                            //     (items["exception"] = "Payment");
                            // } else {
                            //     (items["exception"] = "");
                            // }
                        }
                    }
                }
            }
        });
        if (creditCardsData.filter((item) => item.impt_Uniqueno123 === row.impt_Uniqueno123).length > 0) {
            this.setState({ creditCardsData }, () => {
                setTimeout(() => {
                    this.setFilterExcepton();
                    this.isValidPayment();
                }, 400)
            });
        } else {
            this.setState({ creditCardsData }, () => {
                setTimeout(() => {
                    this.setFilterExcepton();
                    this.isValidPayment();
                }, 400)
            });
        }
    }

    CheckDuplicateCCImportData = async (row) => {
        let { creditCardsData } = this.state;
        let isDeposit = Number(row.deposit) === 0 ? "" : (Number(row.deposit));
        let isAmount = row.payment_Deposit.toString().toLocaleLowerCase() === 'AmountCol'.toLocaleLowerCase();
        let isPayment = row.payment_Deposit.toString().toLocaleLowerCase() === 'Payment_Deposit'.toLocaleLowerCase();
        let hasPayment = isAmount ? row.totalAmount : isPayment ? (Number(row.payment) + Number(isDeposit)).toString() : 0;
        let request: any = {};
        request.HID = this.state.hidValue
        request.CardUniqueNo = this.state.oprId
        request.VID = row.vid;
        request.COA = row.coa;
        request.PayDate = row.date;
        request.CustomerNo = row.customerNo;
        request.Amount = hasPayment;
        try {
            const response = await Promise.all([registersevice.CheckDuplicateCCImportData(request)]);
            if (response.length > 0) {
                let data: any = response[0];
                if (data.result.result.toString().toLowerCase() === "Dup".toLocaleLowerCase()) {
                    row.exception = "Duplicate";
                    row.exEr = 2;
                } else {
                    row.exception === "Duplicate" && (row.exception = "" && (row.exEr = ""));
                    if (row.payment_Deposit.toString().toLowerCase() === "Payment_Deposit".toLocaleLowerCase()) {
                        if (isPayment && Number(row.payment) === 0) {
                            creditCardsData.forEach((items: any, idx: any) => {
                                if (items.impt_Uniqueno123 === row.impt_Uniqueno123) {
                                    creditCardsData[idx].exception = "Payment";
                                    creditCardsData[idx].exEr = 1;
                                }
                            })
                        } else {
                            creditCardsData.forEach((items: any, idx: any) => {
                                if (items.impt_Uniqueno123 === row.impt_Uniqueno123) {
                                    creditCardsData[idx].exception = "";
                                    creditCardsData[idx].exEr = "";
                                }
                            })
                        }
                    } else if (row.payment_Deposit.toString().toLowerCase() === "AmountCol".toLocaleLowerCase()) {
                        creditCardsData.forEach((items: any, idx: any) => {
                            if (items.impt_Uniqueno123 === row.impt_Uniqueno123) {
                                creditCardsData[idx].exception = "";
                                creditCardsData[idx].exEr = "";
                            }
                        })
                    }
                }
                this.setState({ creditCardsData })
            }
        } catch (error) {
            Utils.toastError("Something went wrong in duplicate checking.", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
    }

    hasValidPayment = (row) => {
        let isVal = true;
        if (Number(row.payment) === 0 && Number(row.deposit) === 0) {
            isVal = false;
        }
        else if (Number(row.deposit) !== 0 && Number(row.payment) !== 0) {
            isVal = false;
        }
        return isVal;
    };

    isValidPayment = () => {
        let ccd = [...this.state.creditCardsData];
        let isVal = true;
        if (ccd.length > 0) {
            ccd.forEach((itm1) => {
                if (itm1?.payment_Deposit.toString().toLowerCase() === "Payment_Deposit".toLocaleLowerCase()) {
                    if (Number(itm1.payment) === 0 && Number(itm1.deposit) === 0) {
                        isVal = false;
                    } else if (Number(itm1.deposit) !== 0 && Number(itm1.payment) !== 0) {
                        isVal = false;
                    }
                } else if (itm1.payment_Deposit.toString().toLowerCase() === "AmountCol".toLocaleLowerCase()) {
                    if (Number(itm1.totalAmount) === 0) {
                        isVal = false;
                    }
                }
            });
        }
        return isVal;
    };

    setFilterExcepton = () => {
        let ccd = [...this.state.creditCardsData];
        let filterException = [...this.state.filterException];
        let hasException: any = {};
        ccd.forEach((items, index) => {
            if (items !== null || items !== "") {
                if (items?.exception !== "") {
                    hasException = {
                        id: index + 1,
                        name: items?.exception,
                        eventKey: items?.exception.toString().replace(/ /g, "")
                    }
                    filterException.push(hasException)
                }
            }
        })
        let newArray2: any = [];
        let map = new Map();
        filterException.forEach((item: any) => {
            if (!map.has(item.name)) {
                map.set(item.name, true);
                newArray2.push(item);
            }
        });
        this.setState({
            filterException: newArray2
        })
    }

    setException = () => {
        let ccd = [...this.state.creditCardsData];
        let pageName = this.props.pageName;
        let i = 0;
        let k = 0;
        let match = 0;
        if (ccd.length > 0) {
            if (pageName !== "InvoiceEntrySlideout") {
                for (i = 0; i < ccd.length; i++) {
                    match = 0;
                    if (ccd[i].payment_Deposit.toString().toLowerCase() === "Payment_Deposit".toLowerCase()) {
                        if (
                            ccd[i].date === "" || ccd[i].coa === "" || ccd[i].subVendor === "" ||
                            (Number(ccd[i].payment) === 0 && Number(ccd[i].deposit) === 0) ||
                            ccd[i].customerNo.toString().toLowerCase() === "More".toLocaleLowerCase() || ccd[i].customerNo === ""
                        ) {
                            ccd[i].exception = "Missing Data";
                            ccd[i].exEr = 0;
                        } else {
                            if (ccd[i].exceptionRemark !== "") {
                                ccd[i].exception = "Duplicate";
                                ccd[i].exEr = 2;
                            } else {
                                if ((ccd[i].exception === "") && (Number(ccd[i].deposit) !== 0 && Number(ccd[i].payment) === 0)) {
                                    ccd[i].exception = "Payment";
                                    ccd[i].exEr = 1;
                                } else {
                                    ccd[i].exception = "";
                                    ccd[i].exEr = "";
                                }
                            }
                        }

                    } else if (ccd[i].payment_Deposit.toString().toLowerCase() === "AmountCol".toLowerCase()) {
                        if (
                            //ccd[i].date === "" || 
                            ccd[i].coa === "" || ccd[i].subVendor === "" ||
                            (Number(ccd[i].totalAmount) === 0) ||
                            ccd[i].customerNo.toString().toLowerCase() === "More".toLocaleLowerCase() || ccd[i].customerNo === ""
                        ) {
                            ccd[i].exception = "Missing Data";
                            ccd[i].exEr = 0;
                        } else {
                            if (ccd[i].exceptionRemark !== "") {
                                ccd[i].exception = "Duplicate";
                                ccd[i].exEr = 2;
                            }
                        }
                    }
                }

            } else {
                for (i = 0; i < ccd.length; i++) {
                    match = 0;
                    if (ccd[i].payment_Deposit.toString().toLowerCase() === "Payment_Deposit".toLowerCase()) {
                        if (
                            //ccd[i].date === "" || 
                            ccd[i].coa === "" /*|| ccd[i].subVendor === ""*/ ||
                            (Number(ccd[i].payment) === 0 && Number(ccd[i].deposit) === 0)
                        ) {
                            ccd[i].exception = "Missing Data";
                            ccd[i].exEr = 0;
                        } else {
                            // if (ccd[i].exceptionRemark !== "") {
                            //     ccd[i].exception = "Duplicate";
                            // } else {
                            if ((ccd[i].exception === "") && (Number(ccd[i].deposit) !== 0 && Number(ccd[i].payment) === 0)) {
                                ccd[i].exception = "Payment";
                                ccd[i].exEr = 1;
                            } else {
                                ccd[i].exception = "";
                                ccd[i].exEr = '';
                            }
                            // }
                        }
                    } else if (ccd[i].payment_Deposit.toString().toLowerCase() === "AmountCol".toLowerCase()) {
                        if (
                            //ccd[i].date === "" || 
                            ccd[i].coa === "" /*|| ccd[i].subVendor === ""*/ || (Number(ccd[i].totalAmount) === 0)
                        ) {
                            ccd[i].exception = "Missing Data";
                            ccd[i].exEr = 0;
                        } else {
                            // if (ccd[i].exceptionRemark !== "") {
                            //     ccd[i].exception = "Duplicate";
                            // }
                        }

                    }
                }
            }
            this.setState({
                creditCardsData: ccd,
                isSetingUpRules: false,
                isProcessing: false,
            }, () => {
                this.setFilterExcepton();
                let selectedRule = { ...this.state.selectedRule }
                this.DataImportRuleList.current.GetDataImportRule(selectedRule.ruleID);
            });
        }
    }

    handleBackScreen = () => {
        confirmAlert({
            title: "Alert!",
            message: "All data will remove which you set to current step, Are you sure you want to redirect previous step?",
            buttons: [{
                label: "Yes", onClick: () => {
                    this.setState({ isSetingUpRules: true, isImportScreen: false, /*isAddContractPerm: false*/ });
                    changeAccCounter = 0;
                },
            }, {
                label: "No", onClick: () => reject(),
            }],
        });
    }

    handlefilterException = (event) => {
        let { creditCardsData, filterException, } = this.state;
        let setCCD: any = [...this.state.creditCardsData];
        let resetCCD: any = [...this.state.creditCardsData];


        this.setState({
            filterName: event.name,
            creditCardsData
        });
    }

    headerFormatterVendor = (column: any, colIndex: any) => {
        let pageName = this.props.pageName;
        if (pageName === "Register") {
            return (<>{column.text}{" "}<span className="text-danger">*</span></>);
        } else {
            return (<>{column.text}</>);
        }
    }

    headerFormatterDef = (column: any, colIndex: any) => {
        return (<>{column.text}{" "}<span className="text-danger">*</span></>);
    }

    handleImportData = () => {
        let { isImportData } = this.state;
        isImportData = true;
        this.setState({ isImportData })
        let { creditCardsData, importDataState, selectedRule } = this.state;
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let userName = storage.userName;
        let pageName = this.props.pageName;
        if (pageName === "Register") {
            let requestArray: any = [];
            creditCardsData.forEach((items) => {
                let request: any = {};
                //Credit deposit// 
                let isDeposit = Number(items.deposit) === 0 ? "" : (Number(items.deposit)); //"-" + 
                request.isCCDepositEntry = isDeposit !== "" ? "Yes" : "No";
                let isAmount = items.payment_Deposit.toString().toLocaleLowerCase() === 'AmountCol'.toLocaleLowerCase();
                let isPayment = items.payment_Deposit.toString().toLocaleLowerCase() === 'Payment_Deposit'.toLocaleLowerCase();
                let hasPayment = isAmount ? items.totalAmount : isPayment ? (Number(items.payment) + Number(isDeposit)).toString() : 0;
                request.Uniqueno = items.impt_Uniqueno123;
                request.Hid = this.state.hidValue//items.ehid//latterCode - 1430
                request.OprId = this.state.oprId;
                request.Vendor = items.subVendor;
                request.Date = items.date;
                request.InvoiceNo = items.invoiceNo;
                request.Duedate = "";
                request.Accper = items.actPer;
                request.CustomerNo = items.customerNo;
                request.EHID = this.state.hidValue;//items.ehid;
                request.SubVendor = items.subVendor;
                request.COA = items.coa;
                request.Amount = hasPayment;
                request.SrNo = "";
                request.Description = items.description;
                request.Internalnotes = "";
                request.SplitHid = items.ehid;
                request.Value1 = "";
                request.Value2 = "";
                request.Value3 = "";
                request.Value4 = "";
                request.FileNo = importDataState.FileNo;
                request.Type = "";
                request.Type2 = "";
                request.Type3 = "";
                request.Type4 = "";
                request.Message = "";
                request.Flag = "";
                request.TenantID = storage.tenantID;
                request.RuleID = selectedRule.ruleID;
                request.PayMethod = "";
                request.CheckNo = "";
                request.UserName = userName;
                request.TotalAmt = hasPayment;
                request.CardUniqueno = this.state.oprId;
                request.ImportType = "";
                request.Gdate = items.date;
                request.isDuplicateProceed = false;
                request.PaymentType = items.paymentType;
                request.CCAccountType = this.state.oprId;
                request.attachmentMain = items.attachmentMain;
                requestArray.push(request);
            });

            let hasException = creditCardsData.filter(item => item.exception === "Duplicate");
            if (hasException.length > 0) {
                confirmAlert({
                    title: "Alert!",
                    message: "Duplicate data exist do you want to proceed.",
                    buttons: [{
                        label: "Yes", onClick: () => {
                            requestArray.forEach((items, index) => {
                                requestArray[index].isDuplicateProceed = true
                            });
                            this.importDataForRegister(requestArray);
                        },
                    }, {
                        label: "No", onClick: () => {
                            this.setState({ isImportData: false })
                            changeAccCounter = 0;
                            reject()
                        },
                    }],
                });

            } else {
                this.importDataForRegister(requestArray);
            }


        } else if (pageName === "InvoiceEntrySlideout") {
            let requestArray: any = [];
            creditCardsData.forEach((items) => {
                let request: any = {};
                let coaFullName = items.coa + " " + items.coaname;
                let coaName = Array.from(new Set(coaFullName.split(" "))).join(" ").toString();

                // let isDeposit = Number(items.deposit) === 0 ? "" : items.deposit.includes("-") ? Number(items.deposit) : "-" + (Number(items.deposit)); //"-" + //Credit deposit// 
                let isDeposit = Number(items.deposit) === 0 ? "" : (Number(items.deposit) * -1);
                let isAmount = items.payment_Deposit.toString().toLocaleLowerCase() === 'AmountCol'.toLocaleLowerCase();
                let isPayment = items.payment_Deposit.toString().toLocaleLowerCase() === 'Payment_Deposit'.toLocaleLowerCase();
                let hasPayment = isAmount ? items.totalAmount : isPayment ? (Number(items.payment) + Number(isDeposit)).toString() : 0;

                request.Uniqueno = items.impt_Uniqueno123;
                request.ehid = items.ehid;
                request.OprId = this.state.oprId;
                request.Date = items.date;
                request.InvoiceNo = items.invoiceNo;
                request.Accper = this.state.selectdefaultperiod;
                request.SubVendor = items.subVendor;
                request.coa = items.coa;
                request.coaName = coaName;
                request.Amount = hasPayment;
                request.Description = items.description;
                request.Internalnotes = "";
                request.FileNo = importDataState.FileNo;
                request.TenantID = storage.tenantID;
                request.RuleID = selectedRule.ruleID;
                request.UserName = userName;
                request.PaymentType = items.paymentType;
                request.vid = items.vid;
                requestArray.push(request);
            });
            this.props.importedStatementData(requestArray);
            toast.success("Data imported successfully.", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            setTimeout(() => {
                this.setState({ isImportData: false }, () => {
                    this.creditCardModalClose();
                });
            }, 2000)
        }
    }

    importDataForRegister = (requestArray) => {
        registersevice.SaveCashImportInvoice(requestArray)
            .then(async (result: any) => {
                if (result?.messageCode.toString().toLowerCase() === "success".toLocaleLowerCase()) {
                    let failResponse = result?.message.toString().toLowerCase();
                    if (failResponse === "PeriodNotActive".toLocaleLowerCase()) {
                        this.setState({ isImportData: false });
                        Utils.toastError("Please set active accounting period.", { position: toast.POSITION.BOTTOM_RIGHT });
                    } else if (failResponse === "DuplicateInvoice".toLocaleLowerCase()) {
                        this.setState({ isImportData: false });
                        Utils.toastError("Duplicate invoice no.", { position: toast.POSITION.BOTTOM_RIGHT });
                    } else if (failResponse === "CustomerNoNotExist".toLocaleLowerCase()) {
                        this.setState({ isImportData: false });
                        Utils.toastError("Account no does not exist.", { position: toast.POSITION.BOTTOM_RIGHT });
                    } else if (failResponse === "VendorNotExist".toLocaleLowerCase() || failResponse === "SubVendorNotExist".toLocaleLowerCase()) {
                        this.setState({ isImportData: false });
                        Utils.toastError("Vendor does not exist.", { position: toast.POSITION.BOTTOM_RIGHT });
                    } else if (failResponse === "EHIDShouldbeSame".toLocaleLowerCase()) {
                        this.setState({ isImportData: false });
                        Utils.toastError("EHID should be the same.", { position: toast.POSITION.BOTTOM_RIGHT });
                    } else if (failResponse === "FixedCOA".toLocaleLowerCase()) {
                        this.setState({ isImportData: false });
                        Utils.toastError("Entry can not be made in Fixed COA.", { position: toast.POSITION.BOTTOM_RIGHT });
                    } else if (failResponse === "ConfidentialCOA".toLocaleLowerCase() || failResponse === "Confidential COA".toLocaleLowerCase()) {
                        this.setState({ isImportData: false });
                        Utils.toastError("Transactions with Confidential COAs are not permitted.", { position: toast.POSITION.BOTTOM_RIGHT });
                    } else if (failResponse === "COANotExist".toLocaleLowerCase()) {
                        this.setState({ isImportData: false });
                        Utils.toastError("COA does not exists.", { position: toast.POSITION.BOTTOM_RIGHT });
                    } else if (result?.message.toString().toLowerCase() === "success".toLocaleLowerCase()) {
                        toast.success("Data imported successfully.", { position: toast.POSITION.BOTTOM_RIGHT });
                        this.setState({ isImportData: false, isUpdated: true })
                        setTimeout(() => {
                            this.creditCardModalClose();
                        }, 1500)
                    } else if (result?.message.toString().toLowerCase() === "DuplicateEntry".toLocaleLowerCase()) {
                        let { creditCardsData } = this.state;
                        confirmAlert({
                            title: "Alert!",
                            message: "Duplicate data exist do you want to proceed.",
                            buttons: [{
                                label: "Yes", onClick: () => {
                                    requestArray.forEach((items, index) => {
                                        requestArray[index].isDuplicateProceed = true
                                    });
                                    this.importDataForRegister(requestArray);
                                },
                            }, {
                                label: "No", onClick: () => {
                                    let dupArray = result.duplicateData;
                                    creditCardsData.forEach((item: any, idx: any) => {
                                        dupArray.forEach((dup, idex) => {
                                            if (item.impt_Uniqueno123 === dup?.id) {
                                                creditCardsData[idx].exception = "Duplicate";
                                                creditCardsData[idx].exEr = 2;
                                            }
                                        })

                                    })
                                    this.setState({ creditCardsData, isImportData: false })
                                    reject()
                                },
                            }],
                        });
                    } else {
                        this.setState({ isImportData: false })
                        Utils.toastError(result?.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }
                } else {
                    this.setState({ isImportData: false })
                    Utils.toastError("Something went wrong.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
                resolve();
            })
            .catch((error) => {
                this.setState({ isImportData: false })
                Utils.toastError(error?.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                reject();
            });
    }


    editDataRule = (row: any, rowIndex: any) => {
        let { selectedRule } = this.state;
        this.setState({
            conditionalDataRuleModal: true,
            editVendor: row.subVendor,
            editCOA: row.coa + " " + row.coaname,
            editIF: row.ifColumnName,
            editContains: row.ifColumnVal,
            RuleID: selectedRule.ruleID,
            uniqueNo: row.uniqueNo,
        }, () => {
            this.ConditionalRule.current.showConditionalModal();
            this.ConditionalRule.current.updateParentRowData(row.subVendor, row.coa + " " + row.coaname, row.ifColumnName
                , row.ifColumnVal, selectedRule.ruleID, row.uniqueNo, row.defaultVendor, row.defaultCOA);
        });
    }

    reprocessStart = () => {
        if (this.state.ccdUpdated) {
            confirmAlert({
                title: "Alert!",
                message: "All data will remove which you set to current step, Are you sure you want to reprocess start?",
                buttons: [{
                    label: "Yes", onClick: () => {
                        this.setState({ isProcessStart: true, ccdUpdated: false }, () => {
                            this.handleNext();
                            this.handleTabSelect("import-data");
                        });
                    },
                }, {
                    label: "No", onClick: () => reject(),
                }],
            });
        } else {
            this.setState({ isProcessStart: true }, () => {
                this.handleNext();
                this.handleTabSelect("import-data");
            });
        }
    }

    reProcessRulesBtnShow = () => {
        this.setState({ isReprocessBtnShow: true });
    }

    updateCount = (count: any) => {
        this.setState({ dataRuleCount: count.toString() })
    }

    handleTabSelect = (eventKey: any) => {
        this.setState({ key: eventKey });
    };

    showRuleModal = () => {
        this.setState({ key: "data-rules" }, () => {
            this.DataImportRuleList.current.conditionalDataRuleModalShow();
        });
    }

    renderTooltip2 = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {props}
        </Tooltip>
    );

    formatDollar(num) {
        return (Number(num).toFixed(2)).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    importTablecellEdit = cellEditFactory({
        mode: "click",
        blurToSave: true,
        afterSaveCell: (oldValue: any, newValue: any, row: any, column: any) => {
            let { creditCardsData } = this.state;
            let dataField = column.dataField;
            let index = creditCardsData.indexOf(row);

            if (oldValue !== newValue) {
                newValue = Utils.removeInvaildCharFromAmount(newValue);
                if (!isNaN(newValue)) {
                    if (dataField === "payment") {
                        if (row.deposit === "") {
                            row.payment = newValue !== "" ? newValue : newValue
                            creditCardsData[index] = row;
                            this.setState({ creditCardsData }, () => this.finalExSet(row));
                        } else {
                            row.deposit = "";
                            row.payment = newValue !== "" ? newValue : newValue
                            creditCardsData[index] = row;
                            this.setState({ creditCardsData }, () => this.finalExSet(row));
                        }
                    } else if (dataField === "deposit") {
                        if (row.payment === "") {
                            row.deposit = newValue !== "" ? newValue : newValue
                            creditCardsData[index] = row;
                            this.setState({ creditCardsData }, () => this.finalExSet(row));
                        } else {
                            row.payment = "";
                            row.deposit = newValue !== "" ? newValue : newValue
                            creditCardsData[index] = row;
                            this.setState({ creditCardsData }, () => this.finalExSet(row));
                        }
                    } else {
                        if (newValue === "") {
                            row.totalAmount = ''
                            creditCardsData[index] = row;
                            this.setState({ creditCardsData }, () => this.finalExSet(row));
                        } else {
                            row.totalAmount = newValue !== "" ? newValue : newValue
                            creditCardsData[index] = row;
                            this.setState({ creditCardsData }, () => this.finalExSet(row));
                        }
                        setTimeout(() => { this.finalExSet(row); }, 300)
                    }
                } else {
                    row[dataField] = "";
                    creditCardsData[index] = row;
                    this.setState({ creditCardsData }, () => this.finalExSet(row));
                }
                this.setState({ creditCardsData }, () => { this.finalExSet(row); })
            }
            this.setState({ creditCardsData }, () => { this.finalExSet(row); })
        }
    })

    handleRowEvent = (event: any, row: any, type: any) => {
        let { creditCardsData } = this.state;
        if (type === "cusNo") {
            creditCardsData = creditCardsData.map((items) => {
                if (items.impt_Uniqueno123 === row.impt_Uniqueno123) {
                    return { ...items, customerNo: JSON.parse(event).accountNo };
                } else {
                    return items;
                }
            });
        } else if (type === "actPer") {
            creditCardsData = creditCardsData.map((items) => {
                if (items.impt_Uniqueno123 === row.impt_Uniqueno123) {
                    return { ...items, actPer: event };
                } else {
                    return items;
                }
            });
        } else {

        }
        this.setState((curr: any) => ({ ...curr, creditCardsData, ccdUpdated: true }), () => {
            setTimeout(() => { this.finalExSet(row) }, 500);
        });
    }

    // addNewCustomerNo = (row: any) => {
    //     this.setState({ editedRow: row }, () => {
    //         if (this.vendorSearch?.current !== null) {
    //             this.vendorSearch?.current.importFromCCSI(row.subVendor, row.vid, "current");
    //         }
    //     })
    // }

    addNewCustomerNo = (row: any) => {
        this.setState({ editedRow: row }, () => {
            if (this.vendorSearch?.current !== null) {
                this.setState(
                    prevState => ({ currentRowHid: this.state.hidValue }),
                    () => {
                        setTimeout(() => { this.vendorSearch?.current.importFromJournal(row.subVendor, row.vid, "current"); }, 500);
                    }
                );
            }
        })
    }

    updateVendorList = (result: any) => { }
    handleVendorChange = (vendor: any) => { }
    // getVendorPermission = async () => {
    //     let requestObject = {} as any;
    //     requestObject.permissionID = 3;
    //     requestObject.Module = "Accounting";
    //     requestObject.Type = "page";
    //     try {
    //         const response = await Promise.all([RoleSubPermissionSetting.GetPageSubPermissionDetails(requestObject)]);
    //         if (response !== null) {
    //             let finalResponse = response[0]; //Merge Both response in one array
    //             if (finalResponse.length > 0) {
    //                 for (let i = 0; i < finalResponse.length; i++) {
    //                     if (finalResponse[i].displayName === "Add Contract") {
    //                         this.setState({ isAddContractPerm: true });
    //                     }
    //                 }
    //             }
    //         }
    //     } catch (error) {
    //         Utils.toastError("Something went wrong in roll permission.", {
    //             position: toast.POSITION.BOTTOM_RIGHT,
    //         });
    //     }
    // };

    getCustomerNo = (result: any) => { }

    updateCustomerList = (newCustList) => {
        this.updateNewCustomerList(newCustList);
    }

    updateNewCustomerList = (newCustList) => {
        let { creditCardsData, editedRow } = this.state;
        let request: any = {};
        request.hotelID = this.state.hidValue;
        request.vendorType = "Current";
        request.VendorId = editedRow.vid;
        registersevice.GetVendorContract(request)
            .then(async (result: any) => {
                if (result.length > 0) {
                    //if (result.length > 1) {
                    creditCardsData = creditCardsData.map((items) => {
                        if (items.impt_Uniqueno123 === editedRow.impt_Uniqueno123) {
                            return { ...items, customerList: result, customerNo: newCustList.accountNo };
                        } else {
                            return items;
                        }
                    });
                    // }
                    this.setState((curr: any) => ({ ...curr, creditCardsData }), () => {
                        setTimeout(() => { this.finalExSet(editedRow) }, 500);
                    });
                }
                resolve();
            })
            .catch((error) => {
                reject();
            });
    }

    GetVendorContract = (vid, row, type) => {
        if (row.customerList.length === 0) {
            currentImpRow = row;
            let { creditCardsData } = this.state;
            creditCardsData = creditCardsData.map((items) => {
                if (items.impt_Uniqueno123 === row.impt_Uniqueno123) {
                    return { ...items, isVandorSpinner: true, };
                } else {
                    return items;
                }
            });
            this.setState((curr: any) => ({ ...curr, creditCardsData, ccdUpdated: true, isVandorSpinnerPanel: true }));
            let request: any = {};
            request.hotelID = this.state.hidValue;
            request.vendorType = "Current";
            request.VendorId = vid;
            registersevice.GetVendorContract(request)
                .then(async (result: any) => {
                    if (result.length > 0) {
                        if (result.length === 1) {
                            creditCardsData = creditCardsData.map((items) => {
                                if (items.impt_Uniqueno123 === row.impt_Uniqueno123) {
                                    return { ...items, customerList: result, customerNo: result[0].accountNo, isVandorSpinner: false };
                                } else {
                                    return items;
                                }
                            });
                        } else if (result.length > 1) {
                            creditCardsData = creditCardsData.map((items) => {
                                if (items.impt_Uniqueno123 === row.impt_Uniqueno123) {
                                    return { ...items, customerList: result, customerNo: 'More', isVandorSpinner: false };
                                } else {
                                    return items;
                                }
                            });
                        } else {
                            creditCardsData = creditCardsData.map((items) => {
                                if (items.impt_Uniqueno123 === row.impt_Uniqueno123) {
                                    return { ...items, customerList: result, customerNo: '', isVandorSpinner: false };
                                } else {
                                    return items;
                                }
                            });
                        }
                        this.setState((curr: any) => ({ ...curr, creditCardsData, ccdUpdated: true, isVandorSpinnerPanel: false }), () => {
                            setTimeout(() => { this.finalExSet(row) }, 500);
                        });
                    } else {
                        creditCardsData = creditCardsData.map((items) => {
                            if (items.impt_Uniqueno123 === row.impt_Uniqueno123) {
                                return { ...items, customerNo: "", customerList: [], isVandorSpinner: false };
                            } else {
                                return items;
                            }
                        });
                        this.setState((curr: any) => ({ ...curr, creditCardsData, ccdUpdated: true, isVandorSpinnerPanel: false }), () => {
                            if (type === "custNo") {
                                // && this.state.isAddContractPerm
                                this.addNewCustomerNo(row);
                            } else {
                                setTimeout(() => { this.finalExSet(row) }, 500);
                            }
                            //else {Utils.toastError("Customer number not found.", { position: toast.POSITION.BOTTOM_RIGHT });}
                        });
                    }
                    resolve();
                })
                .catch((error) => {
                    creditCardsData = creditCardsData.map((items) => {
                        if (items.impt_Uniqueno123 === row.impt_Uniqueno123) {
                            return { ...items, isVandorSpinner: false, };
                        } else {
                            return items;
                        }
                    });
                    this.setState((curr: any) => ({ ...curr, creditCardsData, isVandorSpinnerPanel: false }));
                    Utils.toastError(error?.message, { position: toast.POSITION.BOTTOM_RIGHT });
                    reject();
                });
        }
    }

    handleSelectedItem = (event: any, row: any, type: any) => {
        let { creditCardsData } = this.state;
        let pageName = this.props.pageName;
        if (type === "coa") {
            if (event.length > 0) {
                let defaultCOA: any = [];
                let objCOA: any = {};
                objCOA.id = event[0].id;
                objCOA.label = event[0].label;
                objCOA.coAname = event[0].coAname;
                defaultCOA.push(objCOA);
                creditCardsData = creditCardsData.map((items) => {
                    if (items.impt_Uniqueno123 === row.impt_Uniqueno123) {
                        return { ...items, defaultCOA: defaultCOA, coa: event[0].id, coaname: event[0].label, exceptionRemark: "" };
                    } else {
                        return items;
                    }
                });
            } else {
                creditCardsData = creditCardsData.map((items) => {
                    if (items.impt_Uniqueno123 === row.impt_Uniqueno123) {
                        return { ...items, defaultCOA: [], coa: '', coaname: "", exception: "Missing Data", exEr: 0 };
                    } else {
                        return items;
                    }
                });
            }
        } else {
            if (event.length > 0) {
                let defaultVendor: any = [];
                let objVEN: any = {};
                objVEN.id = event[0].id;
                objVEN.label = event[0].vendor;
                objVEN.vendor = event[0].vendor
                defaultVendor.push(objVEN);
                creditCardsData = creditCardsData.map((items) => {
                    if (items.impt_Uniqueno123 === row.impt_Uniqueno123) {
                        return {
                            ...items,
                            defaultVendor: defaultVendor,
                            vid: event[0].id,
                            isVandorSpinner: true,
                            isVandorSpinnerPanel: false,
                            subVendor: event[0].vendor,
                            customerList: [],
                            isInactiveVendor: "Active",
                            exceptionRemark: "",
                        }
                    } else {
                        return items;
                    }
                });
                row.customerList = [];
                row.customerNo = '';
                row.isInactiveVendor = "Active";
            } else {
                creditCardsData = creditCardsData.map((items) => {
                    if (items.impt_Uniqueno123 === row.impt_Uniqueno123) {
                        return { ...items, defaultVendor: [], vid: "", subVendor: '', exception: "Missing Data", exEr: 0, customerList: [], customerNo: '' };
                    } else {
                        return items;
                    }
                });
                row.customerList = [];
                row.customerNo = '';
            }
        }
        this.setState((curr: any) => ({ ...curr, creditCardsData, ccdUpdated: true }), () => {
            if (type === "vendor" && event.length > 0) {
                if (pageName !== "InvoiceEntrySlideout") {
                    this.GetVendorContract(event[0].id, row, "vendor");
                } else {
                    setTimeout(() => { this.finalExSet(row) }, 500);
                }
            } else {
                setTimeout(() => { this.finalExSet(row) }, 500);
            }
        });
    }

    handleChange = (event: any, row: any, FieldType: string) => {
        let { creditCardsData } = this.state;
        if (FieldType === "trDate") {
            let isDate = event === null ? new Date() : event;
            let today = new Date(isDate);
            let month = (today.getMonth() + 1) <= 9 ? "0" + (today.getMonth() + 1) : (today.getMonth() + 1);
            let day = today.getDate() <= 9 ? "0" + today.getDate() : today.getDate();
            let year = today.getFullYear().toString().substr(-2);
            let dateNew = `${month}/${day}/${year}`;
            creditCardsData = creditCardsData.map((items) => {
                if (items.impt_Uniqueno123 === row.impt_Uniqueno123) {
                    return { ...items, date: dateNew, exceptionRemark: "" };
                } else {
                    return items;
                }
            });
        }

        this.setState((curr: any) => ({ ...curr, creditCardsData, ccdUpdated: true }), () => {
            setTimeout(() => { this.finalExSet(row) }, 500);
        });
    }

    notesIcon() {
        return (
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="3em" width="3em" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z"></path>
                <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"></path>
            </svg>
        )
    }

    deleteCurrentAttachment = (rowIndex, row) => {
        let { creditCardsData } = this.state;
        confirmAlert({
            title: "Delete entry",
            message: "Are you sure you want to delete this Entry?",
            buttons: [{
                label: "Yes", onClick: () => {
                    creditCardsData = creditCardsData.map((items) => {
                        if (items.impt_Uniqueno123 === row.impt_Uniqueno123) {
                            return {
                                ...items,
                                attachmentMain: items.attachmentMain.filter((item, index) => rowIndex !== index)
                            };
                        } else {
                            return items;
                        }
                    });

                    setTimeout(() => {
                        this.setState((curr: any) => ({ ...curr, creditCardsData }));
                        creditCardsData.forEach((items) => {
                            if (items.impt_Uniqueno123 === row.impt_Uniqueno123) {
                                if (items.attachmentMain.length === 0) {
                                    this.setState({ uploadAttachments: [] });
                                };
                            }
                        });
                    }, 1000)
                },
            }, {
                label: "No", onClick: () => reject(),
            }],
        });
    }


    onTAB = (control, event) => {
        let { columnCount } = this.state
        if (event.key.toLowerCase() === "tab") {
            let controlID = control.split('_')[0];
            let rowIndex: any = Number(control.split('_')[1]);
            if (control === "ruleName" && !event.shiftKey) {
                setTimeout(() => { $("#accPeriod").trigger("click"); }, 50);
            } else if (control === "AccPeriod" && !event.shiftKey) {
                setTimeout(() => { $("#settingRule_0").trigger('click') }, 50);
            } else if (controlID === "settingRule" && !event.shiftKey) {
                let len = rowIndex + 1
                if (len < columnCount.length) {
                    setTimeout(() => { $("#settingRule_" + len).trigger('click') }, 50);
                }
            }
        }
    }

    render() {
        let { creditCardsData } = this.state;
        let pageName = this.props.pageName;
        let isAmountCol = false;
        let isPDCol = false;
        let isNotValidCCImport = false;
        let isValidPayment = this.isValidPayment() ? false : true;
        if (creditCardsData.length > 0) {
            isAmountCol = (creditCardsData[0].payment_Deposit === "AmountCol" ? true : false);
            isPDCol = (creditCardsData[0].payment_Deposit === "Payment_Deposit" ? true : false);
            let hasException = creditCardsData.filter((items: any) => items.exception === "Missing Data");/* || items.exception === "Duplicate"*/
            if (hasException.length > 0) {
                isNotValidCCImport = true;
            }
        }

        const files = this.state.uploadFiles.map(file => (
            <li key={file.name}>
                <div className="d-flex align-items-center">
                    <div className="file-name">{file.name}</div>
                    <div className="file-loader mr-auto"><ProgressBar variant="success" now={this.state.isProgress} /></div>
                    <div className="file-percentage">{this.state.isProgress} %</div>
                </div>
            </li>
        ));

        /*------------------------ */
        const getRenderChild = (registerList, row) => {
            return registerList.filter(
                (splist) => row.impt_Uniqueno123 === splist.impt_Uniqueno123
            );
        }

        const renderChild = (rowIndex, row) => {
            let { expandedCreditCardData } = this.state;
            let splitlist = [];
            let uniqueID = Math.floor(Math.random() * 90 + 10);
            let uniqueID123 = Math.floor(Math.random() * 90 + 10);
            splitlist = getRenderChild(expandedCreditCardData, row)
            openRow = row
            if (row.ifColumnName !== "" && row.ifColumnVal !== "") {
                return (
                    <div className="nestedChildTable">
                        <div className="childHeader"><strong>Applied Rules</strong></div>
                        <BootstrapTable
                            key={uniqueID}
                            id={uniqueID}
                            keyField='impt_Uniqueno123'
                            data={splitlist}
                            columns={columnChild}
                        />
                        {pageName !== "InvoiceEntrySlideout" && (
                            <>
                                <div className="mt-3 mb-3 childHeader"><strong>Attachments</strong></div>
                                {row.attachmentMain.length > 0 ? (
                                    <>
                                        <div className="attachmentTable">
                                            <BootstrapTable
                                                key={uniqueID123}
                                                id={uniqueID123}
                                                keyField='impt_Uniqueno123'
                                                data={row.attachmentMain}
                                                columns={columnAttachments(row)}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="ccdUploadSection">
                                            <Dropzone key={row.impt_Uniqueno123} onDrop={(e) => this.onAttachmentDrop(e, row.impt_Uniqueno123)}
                                                //accept=".xlsx,.xls,.csv"
                                                accept="image/*,application/pdf,
                                                application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                                                application/vnd.ms-excel,
                                                application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                                                text/plain,
                                                .doc,.docx,.xls,.xlsx,.csv,.txt,.bmp,.tif,.ppt,.pptx,.rtf,.jpg,.png,.msg,.eml"
                                            >
                                                {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                                                    <>
                                                        <section className={isDragActive ? "container upload-area default drag-active " : "container upload-area default"}>
                                                            <div {...getRootProps({ className: 'dropzone d-flex align-items-center' })}>
                                                                <input {...getInputProps()} />
                                                                <p>Drag and drop files or <span>browse files</span> to upload</p>
                                                            </div>
                                                        </section>
                                                    </>
                                                )}
                                            </Dropzone>
                                        </div>
                                    </>

                                )}
                            </>
                        )}

                    </div>


                );
            } else {
                return (
                    <div className="nestedChildTable">
                        <div className="childHeader">No Rule applied. Do you want to create a new?
                            <strong><Link to="#" style={{ color: "#2caf92", backgroundColor: "transparent", textDecoration: "none" }}
                                //onClick={(e)=>{this.showNewDataRuleModal()}}
                                onClick={(e) => { this.showRuleModal() }}
                            > Click here </Link></strong>.

                        </div>
                        {pageName !== "InvoiceEntrySlideout" && (
                            <>
                                <div className="mt-3 mb-3 childHeader"><strong>Attachments</strong></div>
                                {row.attachmentMain.length > 0 ? (
                                    <>
                                        <div className="attachmentTable">
                                            <BootstrapTable
                                                key={uniqueID123}
                                                id={uniqueID123}
                                                keyField='impt_Uniqueno123'
                                                data={row.attachmentMain}
                                                columns={columnAttachments(row)}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="ccdUploadSection">
                                            <Dropzone key={row.impt_Uniqueno123} onDrop={(e) => this.onAttachmentDrop(e, row.impt_Uniqueno123)}
                                                //accept=".xlsx,.xls,.csv"
                                                accept="image/*,application/pdf,
                                                application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                                                application/vnd.ms-excel,
                                                application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                                                text/plain,
                                                .doc,.docx,.xls,.xlsx,.csv,.txt,.bmp,.tif,.ppt,.pptx,.rtf,.jpg,.png,.msg,.eml"
                                            >
                                                {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                                                    <>
                                                        <section className={isDragActive ? "container upload-area default drag-active " : "container upload-area default"}>
                                                            <div {...getRootProps({ className: 'dropzone d-flex align-items-center' })}>
                                                                <input {...getInputProps()} />
                                                                <p>Drag and drop files or <span>browse files</span> to upload</p>
                                                            </div>
                                                        </section>
                                                    </>
                                                )}
                                            </Dropzone>
                                        </div>
                                    </>

                                )}
                            </>
                        )}
                    </div>
                )
            }
        };

        const columnChild = [{
            dataField: 'ifColumnName',
            text: 'If',
            headerClasses: "ifColumnNameCol",
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <EllipsisWithTooltip placement="bottom">{row.ifColumnName}</EllipsisWithTooltip>
                )
            }
        }, {
            dataField: 'ifColumnVal',
            text: 'Contains',
            headerClasses: "ContainsCol",
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <EllipsisWithTooltip placement="bottom">{row.ifColumnVal}</EllipsisWithTooltip>
                )
            }
        }, {
            dataField: '',
            text: 'Then',
            headerClasses: "ThenCol",
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <svg stroke="currentColor" fill="none" strokeWidth="0" viewBox="0 0 24 24" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.0378 6.34317L13.6269 7.76069L16.8972 11.0157L3.29211 11.0293L3.29413 13.0293L16.8619 13.0157L13.6467 16.2459L15.0643 17.6568L20.7079 11.9868L15.0378 6.34317Z" fill="#acacac"></path>
                    </svg>
                )
            }
        }, {
            dataField: '',
            text: 'COA',
            headerClasses: "COACol",
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <EllipsisWithTooltip placement="bottom">{row.coa + " " + row.coaname}</EllipsisWithTooltip>
                )
            }
        }, {
            dataField: 'subVendor',
            text: 'Vendor',
            headerClasses: "VendorCol",
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <EllipsisWithTooltip placement="bottom">{row.subVendor}</EllipsisWithTooltip>
                )
            }
        }, {
            dataField: '',
            text: '',
            headerClasses: "IconCol",
            formatter: (cell: any, row: any, rowIndex: any) => {
                let rowNo = row.impt_Uniqueno123;
                return (
                    <div className="posRelative">
                        <svg onClick={(e) => { this.editDataRule(row, rowIndex) }} stroke="currentColor" fill="#2caf92" strokeWidth="0" viewBox="0 0 24 24" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                            <path fill="none" d="M0 0h24v24H0z"></path>
                            <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04a.996.996 0 000-1.41l-2.34-2.34a.996.996 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path>
                        </svg>

                        <div className="ConditionalModal EditExtra">
                            <ConditionalDataRule
                                id={row.impt_Uniqueno123}
                                key={`cond_ ${rowNo}`}
                                ref={this.ConditionalRule}
                                parentState={this.state}
                                reProcessRulesBtnShow={() => this.reProcessRulesBtnShow()}
                            />
                        </div>
                    </div>
                )
            }
        }];

        const columnAttachments = (mainRow) => [{
            dataField: 'attachment',
            headerClasses: "attachmentCol",
            text: '',
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <>
                        {this.notesIcon()}
                    </>
                )
            }
        }, {
            dataField: 'name',
            text: 'Name',
            headerClasses: "fileNameCol",
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <>
                        <EllipsisWithTooltip placement="bottom">{row.name.split(/\.(?=[^\.]+$)/)[0]}</EllipsisWithTooltip>
                        <div className="dateSection"><EllipsisWithTooltip placement="bottom">{new Date(row.lastModified).toLocaleDateString()}</EllipsisWithTooltip></div>
                    </>
                )
            }
        }, {
            dataField: 'type',
            headerClasses: "fileTypeCol",
            text: 'File Type',
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <EllipsisWithTooltip placement="bottom"><span className="badge badgeOrange">{row.path.split(/\.(?=[^\.]+$)/)[1]}</span></EllipsisWithTooltip>
                )
            }
        }, {
            dataField: 'action',
            text: '',
            headerClasses: "iconCol",
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <button type="button"
                        onClick={(e) => { this.deleteCurrentAttachment(rowIndex, mainRow) }}
                        className="btn btn-primary fa-trash">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="trashSVGIcon">
                            <path d="M3.75226 15C3.75226 15.8273 4.42501 16.5 5.25226 16.5H12.7523C13.5795 16.5 14.2523 15.8273 14.2523 15V6H15.7523V4.5H13.5023H12.7523V3C12.7523 2.17275 12.0795 1.5 11.2523 1.5H6.75226C5.92501 1.5 5.25226 2.17275 5.25226 3V4.5H4.50226H2.25226V6H3.75226V15ZM6.75226 3H11.2523V4.5H6.75226V3ZM6.00226 6H12.0023H12.7523L12.753 15H5.25226V6H6.00226Z"></path><path d="M6.75226 7.5H8.25226V13.5H6.75226V7.5ZM9.75226 7.5H11.2523V13.5H9.75226V7.5Z"></path>
                        </svg>
                    </button>
                )
            }
        }]

        const expandCreditCardRow = {
            onlyOneExpanding: true,
            parentClassName: "parent-expand-foo",
            renderer: (row: any, rowIndex: any) => renderChild(rowIndex, row),
            showExpandColumn: true,
            expandByColumnOnly: true,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                if (isAnyExpands) {
                    return <div></div>;
                }
                return <div></div>;
            },
            expandColumnRenderer: ({ expanded }) => {
                if (expanded) {
                    return (
                        <span className="chevronExpended">
                            <img src={chevronRight} alt="chevron Right" />
                        </span>
                    );
                }
                return (
                    <span className="chevronCollpsed">
                        <img src={chevronRight} alt="chevron Right" />
                    </span>
                );
            },
        };

        const renderCOAItemChildren = (option, props, index) => {
            return (
                <>
                    <div key={option.id}>
                        <div className="vendor-name">
                            <EllipsisWithTooltip placement="bottom">
                                {option.label}
                            </EllipsisWithTooltip>
                        </div>
                    </div>
                </>
            );
        }

        const renderVendorItemChildren = (option, props, index) => {
            return (
                <>
                    <div key={option.id}>
                        <div className="vendor-name">
                            <EllipsisWithTooltip placement="bottom">
                                {option.vendor}
                            </EllipsisWithTooltip>
                        </div>
                    </div>
                </>
            );
        }

        const hoverOutEllipsis = () => {
            $(".tooltip").removeClass("show");
        };

        const renderTooltip = (props) => (
            <Tooltip id="button-tooltip" {...props}>
                {props}
            </Tooltip>
        );

        let hasMissingData = creditCardsData.filter(item => item.exception === "Missing Data").length > 0;
        let hasPayment = creditCardsData.filter(item => item.exception === "Payment").length > 0;
        let hasDuplicate = creditCardsData.filter(item => item.exception === "Duplicate").length > 0;
        let selectOptions = {};
        selectOptions = hasMissingData && { 0: 'Missing Data' }
        selectOptions = hasPayment && { 1: 'Payment' }
        selectOptions = hasDuplicate && { 2: 'Duplicate' }

        const handleClick = (event) => {
            let { filterName } = this.state;
            filterName = event === "" ? "All" : event === "0" ? "Missing Data" : event === "1" ? "Payment" : event === "2" ? "Duplicate" : "";
            this.setState({ filterName });
            qualityFilter(event);
        };

        const creditCardsColumns = [
            {
                dataField: 'company',
                text: 'Company',
                editable: false,
                headerClasses: "sameWidthCol",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <EllipsisWithTooltip placement="bottom">{row.company.toString().replace(/Ø/g, ',')}</EllipsisWithTooltip>
                    )
                }
            }, {
                dataField: 'exEr',
                text: '',
                headerClasses: 'd-none',
                classes: 'd-none',
                formatter: (cell) => selectOptions[cell],
                filter: selectFilter({
                    options: selectOptions,
                    getFilter: (filter) => {
                        qualityFilter = filter;
                    },
                }),
            }, {
                dataField: 'category',
                text: 'Category',
                editable: false,
                headerClasses: "sameWidthCol",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <EllipsisWithTooltip placement="bottom">{row.category.toString().replace(/Ø/g, ',')}</EllipsisWithTooltip>
                    )
                }
            }, {
                dataField: 'date',
                text: 'Date',
                editable: false,
                hidden: pageName === "InvoiceEntrySlideout", // hide Date for Invoice - https://app.asana.com/0/942352992481363/1201959773428331/f
                headerClasses: "defaultColWidth3",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="date-picker calender-picker importDropdownSmall">
                            <div className="posRelative">
                                <label htmlFor={"trdate_" + rowIndex} className="drop-arrow">
                                    <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.28122e-05 1.19724C3.28122e-05 0.889538 0.253262 0.653982 0.543288 0.653982C0.688297 0.653982 0.833313 0.708449 0.923855 0.816677L4.0023 4.0217L7.08074 0.816677C7.2979 0.599519 7.6424 0.599519 7.84116 0.816677C8.04064 1.01616 8.05831 1.34153 7.85955 1.55941L4.40046 5.18112C4.20098 5.39827 3.85721 5.39828 3.64005 5.19951L3.62166 5.18112L0.145009 1.55941C0.0544669 1.46887 0 1.34226 0 1.19724L3.28122e-05 1.19724Z" fill="#3F4347" />
                                    </svg>
                                </label>
                            </div>
                            <DatePicker
                                id={"trdate_" + rowIndex}
                                ref={this.trdate}
                                className={row.date === "" ? "border-danger form-control" : "form-control"}
                                placeholderText={"MM/DD/YY"}
                                dateFormat="MM/dd/yy"
                                autoComplete="off"
                                selected={row.date === "" ? "" : new Date(row.date)}
                                onSelect={(event: any) => this.handleChange(event, row, 'trDate')}
                                onChange={(event: any) => this.handleChange(event, row, 'trDate')}
                            />
                        </div>
                    )
                }
            }, {
                dataField: 'description',
                text: 'Description',
                headerClasses: "descriptionColw",
                editable: false,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <EllipsisWithTooltip placement="bottom">{row.description.toString().replace(/Ø/g, ',')}</EllipsisWithTooltip>
                    )
                }
            }, {
                dataField: 'payment',
                text: 'Debit',
                headerClasses: "text-right sameWidthCol",
                hidden: !isPDCol,
                classes: (cell, row, rowIndex, colIndex) => (
                    (row.payment === "" || row.payment === null || isNaN(Number(row.payment))) && (row.deposit === "" || row.deposit === null)) ? "text-right hasColumnError" : "text-right",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    if ((row.payment === "" || row.payment === null || isNaN(Number(row.payment))) && (row.deposit === "" || row.deposit === null)) {
                        return (
                            <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("Invalid Payment")}>
                                <div className="hasPaymentError"><EllipsisWithTooltip placement="bottom">{row.payment}</EllipsisWithTooltip></div>
                            </OverlayTrigger>
                        )
                    } else {
                        return (
                            <EllipsisWithTooltip placement="bottom">{Utils.currencyFormatWithOutdoller(row.payment)}</EllipsisWithTooltip>
                        )
                    }
                }
            }, {
                dataField: 'deposit',
                text: 'Credit',
                headerClasses: "text-right sameWidthCol",
                hidden: !isPDCol,
                classes: (cell, row, rowIndex, colIndex) =>
                    ((row.deposit === "" || row.deposit === null) && (row.payment === "" || row.payment === null || isNaN(Number(row.payment)))) ? "text-right hasColumnError" : "text-right",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    if (((row.deposit === "" || row.deposit === null) && (row.payment === "" || row.payment === null || isNaN(Number(row.payment))))) {
                        return (
                            <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("Invalid Deposit")}>
                                <div className="hasPaymentError"><EllipsisWithTooltip placement="bottom">{row.deposit}</EllipsisWithTooltip></div>
                            </OverlayTrigger>
                        )
                    } else {
                        return (
                            <EllipsisWithTooltip placement="bottom">{Utils.currencyFormatWithOutdoller(row.deposit)}</EllipsisWithTooltip>
                        )
                    }
                }
            }, {
                dataField: 'totalAmount',
                text: 'Amount',
                headerClasses: "text-right totalAmountCol",
                hidden: !isAmountCol,
                classes: (cell, row, rowIndex, colIndex) => (
                    row.totalAmount === "" || row.totalAmount === null || isNaN(Number(row.totalAmount))) ? "text-right hasColumnError" : "text-right",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    if (row.totalAmount === "" || row.totalAmount === null || isNaN(Number(row.totalAmount))) {
                        return (
                            <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("Invalid Amount")}>
                                <div className="hasPaymentError"><EllipsisWithTooltip placement="bottom">{row.totalAmount}</EllipsisWithTooltip></div>
                            </OverlayTrigger>
                        )
                    } else {
                        return (
                            <EllipsisWithTooltip placement="bottom">{Utils.currencyFormatWithOutdoller(row.totalAmount)}</EllipsisWithTooltip>
                        )
                    }
                }
            }, {
                dataField: 'exception',
                text: 'Exception',
                editable: false,
                headerClasses: "sameWidthCol",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <span className="badge badgeOrange">{row.exception}</span>
                    )
                },

            }, {
                dataField: '',
                text: '',
                editable: false,
                headerClasses: 'headerSeparator',
                classes: 'columnSeparator'
            }, {
                dataField: "coa",
                text: "COA",
                editable: false,
                headerClasses: pageName === "InvoiceEntrySlideout" ? "defaultColWidth4" : "defaultColWidth",
                classes: pageName === "InvoiceEntrySlideout" ? "defaultColWidth4" : "defaultColWidth",
                headerFormatter: this.headerFormatterDef,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    let hasValidVendor = this.state.coaList.some((item: any) => item.id === row.coa);
                    return (
                        <div onWheel={hoverOutEllipsis.bind(this)}>
                            <div className={row.coa === "" || !hasValidVendor ? "ddBorderDanger" : ''}>
                                {this.state.coaList.length > 0 ? (
                                    <div className="more-action single-select-image dropdown coa-select jecoaSelect ccdTypeheaddropdown">
                                        <div className={row.defaultCOA.length > 0 ? "" : "typeaheadBorderDanger"}>
                                            <Typeahead
                                                flip={true}
                                                inputProps={{ className: "coa" + rowIndex.toString() }}
                                                id={"coa-" + rowIndex.toString()}
                                                options={this.state.coaList}
                                                placeholder={"Enter COA"}
                                                renderMenu={(results: any, menuProps) => (
                                                    <Menu {...menuProps}>
                                                        {results.map((result: any, index: any) => (
                                                            <>
                                                                <MenuItem option={result} position={index}>
                                                                    <EllipsisWithTooltip placement="bottom">{result.label}</EllipsisWithTooltip>
                                                                </MenuItem>
                                                                {result.label == "" && (
                                                                    <MenuItem className="load-more" option={result} position={index}>
                                                                        Load More
                                                                    </MenuItem>
                                                                )}
                                                                {(this.state.coaList.length > 0
                                                                    && results[index - 1]?.orderno === 1 && result?.orderno === 2) && (
                                                                        <Dropdown.Divider />
                                                                    )}
                                                            </>
                                                        ))}
                                                    </Menu>
                                                )}
                                                renderMenuItemChildren={renderCOAItemChildren}
                                                onChange={(e) => this.handleSelectedItem(e, row, "coa")}
                                                selected={row.defaultCOA}
                                            />
                                        </div>
                                    </div>

                                ) : (
                                    <Dropdown className="more-action disabled-area bg-blue single-select-image">
                                        <Dropdown.Toggle id="Modal-hid" className="filter-btn">
                                            <div className="d-flex align-items-center">
                                                <div className="txt-area">
                                                    Select COA
                                                </div>
                                                <div className="drop-arrow ml-0 d-flex">
                                                    <FiChevronDown />
                                                </div>
                                            </div>
                                        </Dropdown.Toggle>
                                    </Dropdown>
                                )}
                            </div>
                        </div>
                    )
                }
            }, {
                dataField: "subVendor",
                text: pageName === "InvoiceEntrySlideout" ? "Sub Vendor" : "Vendor",
                editable: false,
                headerFormatter: this.headerFormatterVendor,
                headerClasses: pageName === "InvoiceEntrySlideout" ? "defaultColWidth4" : "defaultColWidth2",
                classes: pageName === "InvoiceEntrySlideout" ? "defaultColWidth4" : "defaultColWidth2",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    let hasValidVendor = this.state.vendorListMain.some((item: any) => item.vendor === row.subVendor);
                    if (row.isInactiveVendor.toString().toLowerCase() !== "Active".toLocaleLowerCase()) {
                        return (
                            <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(row.isInactiveVendor)}>
                                <div onWheel={hoverOutEllipsis.bind(this)}>
                                    <div className={pageName !== "InvoiceEntrySlideout" ? hasValidVendor ? "" : "ddBorderDanger" : ""}>
                                        {this.state.vendorListMain.length > 0 ? (
                                            <div className="more-action single-select-image dropdown coa-select jecoaSelect ccdTypeheaddropdown">
                                                <div className={pageName !== "InvoiceEntrySlideout" ? row.defaultVendor.length > 0 ? "" : "typeaheadBorderDanger" : ""}>
                                                    <Typeahead
                                                        flip={true}
                                                        inputProps={{ className: "vendor" + rowIndex.toString() }}
                                                        id={"vendor-" + rowIndex.toString()}
                                                        options={this.state.vendorListMain}
                                                        placeholder={pageName === "InvoiceEntrySlideout" ? "Enter Sub Vendor" : "Enter Vendor"}
                                                        renderMenu={(results: any, menuProps) => (
                                                            <Menu {...menuProps}>
                                                                {results.map((result: any, index: any) => (
                                                                    <>
                                                                        <MenuItem option={result} position={index}>
                                                                            <EllipsisWithTooltip placement="bottom">{result.vendor}</EllipsisWithTooltip>
                                                                        </MenuItem>
                                                                        {result.label == "" && (
                                                                            <MenuItem className="load-more" option={result} position={index}>
                                                                                Load More
                                                                            </MenuItem>
                                                                        )}
                                                                        {(this.state.vendorListMain.length > 0
                                                                            && results[index - 1]?.orderno === 1 && result?.orderno === 2) && (
                                                                                <Dropdown.Divider />
                                                                            )}
                                                                    </>
                                                                ))}
                                                            </Menu>
                                                        )}
                                                        renderMenuItemChildren={renderVendorItemChildren}
                                                        onChange={(e) => this.handleSelectedItem(e, row, "vendor")}
                                                        selected={row.defaultVendor}
                                                    />
                                                </div>
                                            </div>

                                        ) : (
                                            <Dropdown className="more-action disabled-area bg-blue single-select-image">
                                                <Dropdown.Toggle id="Modal-hid" className="filter-btn">
                                                    <div className="d-flex align-items-center">
                                                        <div className="txt-area">
                                                            Select COA
                                                        </div>
                                                        <div className="drop-arrow ml-0 d-flex">
                                                            <FiChevronDown />
                                                        </div>
                                                    </div>
                                                </Dropdown.Toggle>
                                            </Dropdown>
                                        )}
                                    </div>
                                </div>
                            </OverlayTrigger>
                        )

                    } else {
                        return (
                            <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(row.defaultVendor[0]?.vendor === undefined ? "Please select a vendor." : row.defaultVendor[0]?.vendor)}>
                                <div onWheel={hoverOutEllipsis.bind(this)}>
                                    <div className={pageName !== "InvoiceEntrySlideout" ? hasValidVendor ? "" : "ddBorderDanger" : ""}>
                                        {this.state.vendorListMain.length > 0 ? (
                                            <div className="more-action single-select-image dropdown coa-select jecoaSelect ccdTypeheaddropdown">
                                                <div className={pageName !== "InvoiceEntrySlideout" ? row.defaultVendor.length > 0 ? "" : "typeaheadBorderDanger" : ""}>
                                                    <Typeahead
                                                        flip={true}
                                                        inputProps={{ className: "vendor" + rowIndex.toString() }}
                                                        id={"vendor-" + rowIndex.toString()}
                                                        options={this.state.vendorListMain}
                                                        placeholder={pageName === "InvoiceEntrySlideout" ? "Enter Sub Vendor" : "Enter Vendor"}
                                                        renderMenu={(results: any, menuProps) => (
                                                            <Menu {...menuProps}>
                                                                {results.map((result: any, index: any) => (
                                                                    <>

                                                                        <MenuItem option={result} position={index}>
                                                                            <EllipsisWithTooltip placement="bottom">{result.vendor}</EllipsisWithTooltip>
                                                                        </MenuItem>
                                                                        {result.label == "" && (
                                                                            <MenuItem className="load-more" option={result} position={index}>
                                                                                Load More
                                                                            </MenuItem>
                                                                        )}
                                                                        {(this.state.vendorListMain.length > 0
                                                                            && results[index - 1]?.orderno === 1 && result?.orderno === 2) && (
                                                                                <Dropdown.Divider />
                                                                            )}
                                                                    </>
                                                                ))}
                                                            </Menu>
                                                        )}
                                                        renderMenuItemChildren={renderVendorItemChildren}
                                                        onChange={(e) => this.handleSelectedItem(e, row, "vendor")}
                                                        selected={row.defaultVendor}
                                                    />
                                                </div>
                                            </div>

                                        ) : (
                                            <Dropdown className="more-action disabled-area bg-blue single-select-image">
                                                <Dropdown.Toggle id="Modal-hid" className="filter-btn">
                                                    <div className="d-flex align-items-center">
                                                        <div className="txt-area">
                                                            Select COA
                                                        </div>
                                                        <div className="drop-arrow ml-0 d-flex">
                                                            <FiChevronDown />
                                                        </div>
                                                    </div>
                                                </Dropdown.Toggle>
                                            </Dropdown>
                                        )}
                                    </div>
                                </div>
                            </OverlayTrigger>
                        )
                    }

                }
            }, {
                dataField: "cust-no",
                text: "Cust. No",
                editable: false,
                headerClasses: "defaultColWidth",
                classes: "defaultColWidth",
                hidden: pageName === "InvoiceEntrySlideout",
                headerFormatter: this.headerFormatterDef,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    let isGenericVendor = row.subVendor.toString().toLowerCase() !== "GENERIC VENDOR".toLocaleLowerCase();
                    let hasValidVendor = this.state.vendorListMain.some((item: any) => item.vendor === row.subVendor);
                    if (hasValidVendor && row.ehid !== "" && row.subVendor.toString().toLowerCase() !== "GENERIC VENDOR".toLocaleLowerCase()) {
                        return (
                            <div className="isCusNoField hasVendorSearch posStatic">
                                {row.customerNo === "" && row.customerList.length == 0 && (
                                    <div onClick={(e) => this.GetVendorContract(row.vid, row, "custNo")}
                                        className={this.state.isAddContractPerm ?
                                            "ddBorderDanger ccimportdd singleSelect importDropdown importDropdownSmall hasCustomerList" :
                                            "disabled-area ddBorderDanger ccimportdd singleSelect importDropdown importDropdownSmall hasCustomerList"}>
                                        <Dropdown className="more-action single-select-image posUnset">
                                            <Dropdown.Toggle id="Modal-hid" className="filter-btn posRelative">
                                                <div className="d-flex align-items-center">
                                                    <div className="txt-area">
                                                        <EllipsisWithTooltip placement="bottom">
                                                            {row.customerNo === ""
                                                                ? "Add" : row.customerNo}
                                                        </EllipsisWithTooltip>
                                                    </div>
                                                    <div className="add-icon ml-0 d-flex">
                                                        {row.isVandorSpinner ? (
                                                            <Spinner size="sm" variant="success" animation="border" />) : (
                                                            <svg stroke="currentColor" fill="#2caf92" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M352 240v32c0 6.6-5.4 12-12 12h-88v88c0 6.6-5.4 12-12 12h-32c-6.6 0-12-5.4-12-12v-88h-88c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h88v-88c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v88h88c6.6 0 12 5.4 12 12zm96-160v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48zm-48 346V86c0-3.3-2.7-6-6-6H54c-3.3 0-6 2.7-6 6v340c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"></path>
                                                            </svg>
                                                        )}
                                                    </div>
                                                </div>
                                            </Dropdown.Toggle>
                                        </Dropdown>
                                    </div>
                                )}
                                {(row.customerNo !== "" || row.customerList.length > 0) &&
                                    (<>
                                        <div className={row.customerNo === "" ?
                                            "ddBorderDanger ccimportdd singleSelect importDropdown importDropdownSmall hasCustomerList hasFixButton" :
                                            "ccimportdd singleSelect importDropdown importDropdownSmall hasCustomerList hasFixButton"}
                                        >
                                            <Dropdown
                                                onClick={(e) => this.GetVendorContract(row.vid, row, "custNo")}
                                                className="more-action single-select-image posUnset">
                                                <Dropdown.Toggle id="Modal-hid" className="filter-btn posRelative">
                                                    <div className="d-flex align-items-center">
                                                        <div className="txt-area">
                                                            <EllipsisWithTooltip placement="bottom">
                                                                {(row.customerNo === "" || row.customerNo.toString().toLowerCase() === "More".toLocaleLowerCase()) ? "Select" : row.customerNo}</EllipsisWithTooltip>
                                                        </div>
                                                        <div className="drop-arrow ml-0 d-flex">
                                                            <FiChevronDown />
                                                        </div>
                                                    </div>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <div className="scrollableArea">
                                                        {row.customerList !== undefined && row.customerList.map((item: any, idx: any) => (
                                                            <Dropdown.Item eventKey={JSON.stringify(item)} key={idx} onSelect={(e) => this.handleRowEvent(e, row, "cusNo")}>
                                                                <EllipsisWithTooltip placement="bottom">{item.accountNo}</EllipsisWithTooltip>
                                                            </Dropdown.Item>
                                                        ))}
                                                    </div>
                                                    {this.state.isAddContractPerm && (
                                                        <Dropdown.Item onClick={(e) => this.addNewCustomerNo(row)} style={{ borderTop: "1px solid #e0e3e6" }}>
                                                            <EllipsisWithTooltip placement="bottom"
                                                            ><strong style={{ color: "#2caf92" }}>Add New Contract</strong> </EllipsisWithTooltip>
                                                        </Dropdown.Item>
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </>
                                    )}
                            </div>
                        )
                    }
                    else {
                        return (
                            <Form.Control disabled={true} className={isGenericVendor ? "border-danger" : ""} type="text" placeholder={isGenericVendor ? "Cust. No" : ""} />
                        )
                    }
                }

            }, {
                dataField: "actPer",
                text: "A/C PERIOD",
                editable: false,
                headerClasses: "defaultColWidth",
                classes: "defaultColWidth",
                hidden: pageName === "InvoiceEntrySlideout",
                headerFormatter: this.headerFormatterDef,
                formatter: (cell: any, row: any, rowIndex: any) => {//CCIAccPer
                    return (<div className="ccimportdd singleSelect importDropdown importDropdownSmall">
                        {this.state.accPeriodList.length > 0 && (
                            <Dropdown
                                className={row.actPer !== "" || row.actPer !== null ? "more-action posStatic single-select-image" : "ddBorderDanger more-action posStatic single-select-image"}
                                onSelect={(e) => this.handleRowEvent(e, row, "actPer")}>
                                <Dropdown.Toggle id="Modal-hid" className="filter-btn posRelative">
                                    <div className="d-flex align-items-center">
                                        <div className="txt-area">
                                            <EllipsisWithTooltip placement="bottom">{row.actPer}</EllipsisWithTooltip>
                                        </div>
                                        <div className="drop-arrow apda ml-0 d-flex">
                                            <FiChevronDown />
                                        </div>
                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {this.state.accPeriodList.map(
                                        (item: any, idx: any) => (
                                            <Dropdown.Item eventKey={item.name} key={idx}>
                                                <EllipsisWithTooltip placement="bottom">{item.name}</EllipsisWithTooltip>
                                            </Dropdown.Item>
                                        )
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                        )}
                    </div>)
                }
            }, {
                dataField: "upload",
                text: "",
                hidden: pageName === "InvoiceEntrySlideout",
                editable: false,
                headerClasses: "lastChildColWidth",
                classes: "lastChildColWidth",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <button type="button" className="btn btn-primary fa-trash btnDropzone">
                            <div className="btnDropzone">
                                <Dropzone key={row.impt_Uniqueno123} onDrop={(e) => this.onAttachmentDrop(e, row.impt_Uniqueno123)}
                                    //accept=".xlsx,.xls,.csv"
                                    accept="image/*,application/pdf,
                                                application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                                                application/vnd.ms-excel,
                                                application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                                                text/plain,
                                                .doc,.docx,.xls,.xlsx,.csv,.txt,.bmp,.tif,.ppt,.pptx,.rtf,.jpg,.png,.msg,.eml"
                                >
                                    {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                                        <input id="addAttachment" {...getInputProps()} title={row.attachmentMain.length > 99 ? (row.attachmentMain.length) : ""} />
                                    )}
                                </Dropzone>
                                <div className="btnDropzoneIn">
                                    <FiPaperclip />
                                    {row.attachmentMain.length > 0 && (
                                        <span className="fileCounter">
                                            {row.attachmentMain.length > 99 ? 99 + "+" : row.attachmentMain.length}
                                        </span>)}
                                </div>
                            </div>


                        </button>

                    )
                }
            }, {
                dataField: "action",
                text: "",
                editable: false,
                headerClasses: "lastChildColWidth",
                classes: "lastChildColWidth",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <OverlayTrigger placement="top" overlay={<Tooltip id="addContract">Delete Row</Tooltip>}>
                            <button type="button"
                                disabled={this.state.creditCardsData.length < 2}
                                onClick={(e) => { this.deleteCurrentRow(row) }}
                                className="btn btn-primary fa-trash">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="trashSVGIcon">
                                    <path d="M3.75226 15C3.75226 15.8273 4.42501 16.5 5.25226 16.5H12.7523C13.5795 16.5 14.2523 15.8273 14.2523 15V6H15.7523V4.5H13.5023H12.7523V3C12.7523 2.17275 12.0795 1.5 11.2523 1.5H6.75226C5.92501 1.5 5.25226 2.17275 5.25226 3V4.5H4.50226H2.25226V6H3.75226V15ZM6.75226 3H11.2523V4.5H6.75226V3ZM6.00226 6H12.0023H12.7523L12.753 15H5.25226V6H6.00226Z"></path><path d="M6.75226 7.5H8.25226V13.5H6.75226V7.5ZM9.75226 7.5H11.2523V13.5H9.75226V7.5Z"></path>
                                </svg>
                            </button>
                        </OverlayTrigger>
                    )
                }
            }];
        /*------------------------ */
        return (
            <>
                <ToastContainer autoClose={3000} enableMultiContainer />
                <Modal className={this.state.isUploadedCCS ? "import-credit-card-result-modal importCreditCardModal CCSI" : "import-credit-card-modal"}
                    centered
                    show={this.state.importCreditCardModal}
                    onHide={this.creditCardModalClose}
                    backdrop="static"
                    dialogClassName={this.state.isUploadedCCS ? "modal-100w" : "modal-60w"}
                    //aria-labelledby="credit-card-Result-modal"
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.state.isUploadedCCS ? (
                                <>
                                    Credit Card Statement Import
                                    <div className="sub-heading">
                                        {this.state.uploadFiles[0]?.path}</div>
                                </>
                            ) : (
                                <>Import Credit Card Statement</>
                            )}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={this.state.key === "import-data" ? "" : "NoScroll"}>
                        <>
                            {!this.state.isUploadedCCS ? (
                                <Dropzone onDrop={this.onDrop} accept=".xlsx,.xls,.csv" multiple={false}>
                                    {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                                        <>
                                            {this.state.uploadFiles.length == 0 && (
                                                <section className={isDragActive ? "container upload-area default drag-active " : "container upload-area default"}>
                                                    <div {...getRootProps({ className: 'dropzone d-flex align-items-center' })}>
                                                        <input {...getInputProps()} />
                                                        <p>{/*Drag and drop invoices or <span>browse files</span> to upload*/}
                                                            Drag and drop file here to upload
                                                            <br /><strong className="acceptedFiles">.xls, .xlxs or .csv</strong></p>

                                                    </div>
                                                </section>
                                            )}
                                            {this.state.uploadFiles.length > 0 && (
                                                <aside className="d-flex flex-column file-list">
                                                    <ul>{files}</ul>
                                                </aside>
                                            )}
                                        </>
                                    )}
                                </Dropzone>
                            ) : (<>
                                <ToolkitProvider
                                    keyField="rNo"
                                    data={this.state.creditCardsData}
                                    columns={creditCardsColumns}
                                    search
                                >
                                    {(props: {
                                        searchProps: JSX.IntrinsicAttributes;
                                        baseProps: JSX.IntrinsicAttributes;
                                    }) => (
                                        <div className={this.state.isProcessing ? "disabled-area" : ""}>
                                            <div className="steps">
                                                <div className="filter-sec">
                                                    <div className="d-flex">
                                                        <div className="selectContainer">
                                                            <div className="selectHead">
                                                                <strong>Mapping Rule</strong>
                                                            </div>
                                                            <div className="ccimportdd singleSelect importDropdown hasFixButton importRuleDropdown">
                                                                {!this.state.isSetingUpRules ? (
                                                                    <Dropdown className="disabled-area w-210 more-action single-select-image">
                                                                        <Dropdown.Toggle id="Modal-hid" className="filter-btn">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="txt-area">
                                                                                    <EllipsisWithTooltip placement="bottom">
                                                                                        {this.state.selectedRule.ruleName}
                                                                                    </EllipsisWithTooltip>
                                                                                </div>
                                                                                <div className="drop-arrow ml-0 d-flex">
                                                                                    <FiChevronDown />
                                                                                </div>
                                                                            </div>
                                                                        </Dropdown.Toggle>
                                                                    </Dropdown>
                                                                ) : (
                                                                    <Dropdown

                                                                        tabIndex={this.state.tabEnable}
                                                                        onSelect={(e) => this.handleSelectRule(e,)}
                                                                        className="w-210 more-action single-select-image">

                                                                        <Dropdown.Toggle id="Modal-hid" onKeyDown={(e: any) => this.onTAB('ruleName', e)} className="filter-btn">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="txt-area">
                                                                                    {this.state.selectedRule.ruleName === "Select" ?
                                                                                        (<span className="dropdownPlaceholder">Select mapping rule</span>) :
                                                                                        (<EllipsisWithTooltip placement="bottom">{this.state.selectedRule.ruleName}</EllipsisWithTooltip>)}
                                                                                </div>
                                                                                <div className="drop-arrow ml-0 d-flex">
                                                                                    <FiChevronDown />
                                                                                </div>
                                                                            </div>
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu>
                                                                            <div className="scrollableArea">
                                                                                {this.state.mappingRuleArray.map(
                                                                                    (item: any, idx: any) => (
                                                                                        <Dropdown.Item eventKey={JSON.stringify(item)} key={idx}>
                                                                                            <EllipsisWithTooltip placement="bottom">{item.ruleName}</EllipsisWithTooltip>
                                                                                        </Dropdown.Item>
                                                                                    )
                                                                                )}
                                                                            </div>
                                                                            <Dropdown.Divider className="m-0" />
                                                                            <Dropdown.Item className="filterException" eventKey={"addNewRule"}>
                                                                                <strong>Add New</strong>
                                                                            </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className={this.state.key === "import-data" ? "ml-3 selectContainer" : "selectContainer"}>
                                                            <div className="selectHead">
                                                                <strong>{this.state.key === "import-data" && "Accounting Period"}</strong>
                                                            </div>
                                                            <div className="ccimportdd singleSelect importDropdown">
                                                                {this.state.accPeriodList.length > 0 ? (
                                                                    <>
                                                                        {this.state.key === "import-data" && (
                                                                            <Dropdown onSelect={this.handleAccPeriod} className="w-210 more-action single-select-image">
                                                                                <Dropdown.Toggle id="accPeriod" className="filter-btn"
                                                                                    onKeyDown={(e: any) => this.onTAB('AccPeriod', e)}>
                                                                                    <div className="d-flex align-items-center">
                                                                                        <div className="txt-area">
                                                                                            <EllipsisWithTooltip placement="bottom">{this.state.selectdefaultperiod}</EllipsisWithTooltip>
                                                                                        </div>
                                                                                        <div className="drop-arrow ml-0 d-flex">
                                                                                            <FiChevronDown />
                                                                                        </div>
                                                                                    </div>
                                                                                </Dropdown.Toggle>

                                                                                <Dropdown.Menu>
                                                                                    {this.state.accPeriodList.map(
                                                                                        (item: any, idx: any) => (
                                                                                            <Dropdown.Item eventKey={item.name} key={idx}>
                                                                                                <EllipsisWithTooltip placement="bottom">{item.name}</EllipsisWithTooltip>
                                                                                            </Dropdown.Item>
                                                                                        )
                                                                                    )}
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <Dropdown className="disabled-area w-210 more-action single-select-image">
                                                                        <Dropdown.Toggle id="Modal-hid" className="filter-btn">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="txt-area">
                                                                                    <EllipsisWithTooltip placement="bottom">{this.state.selectdefaultperiod}</EllipsisWithTooltip>
                                                                                </div>
                                                                                <div className="drop-arrow ml-0 d-flex">
                                                                                    <FiChevronDown />
                                                                                </div>
                                                                            </div>
                                                                        </Dropdown.Toggle>
                                                                    </Dropdown>
                                                                )}
                                                            </div>
                                                        </div>
                                                        {!this.state.isSetingUpRules && (<>

                                                            <div className="isVerticalSeparator"></div>

                                                            {this.state.key === "import-data" && (
                                                                <>
                                                                    <div className="selectContainer">
                                                                        <div className="selectHead">
                                                                            <strong>&nbsp;</strong>
                                                                        </div>
                                                                        <div className="search-header">
                                                                            <SearchBar {...props.searchProps} placeholder={"Search..."} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="ml-3 selectContainer">
                                                                        <div className="selectHead">
                                                                            <strong>&nbsp;</strong>
                                                                        </div>
                                                                        <div className="ml-3 ccimportdd singleSelect importDropdown cusDropdownNoBorder">
                                                                            <Dropdown onSelect={(e) => handleClick(e)} className="more-action single-select-image">
                                                                                <Dropdown.Toggle id="Modal-hid" className="filter-btn">
                                                                                    <div className="d-flex align-items-center">
                                                                                        <div className="txt-area">
                                                                                            {this.state.filterName}
                                                                                        </div>
                                                                                        <div className="drop-arrow ml-0 d-flex">
                                                                                            <FiChevronDown />
                                                                                        </div>
                                                                                    </div>
                                                                                </Dropdown.Toggle>
                                                                                <Dropdown.Menu>
                                                                                    <Dropdown.Item className="filterException" eventKey="">All</Dropdown.Item>
                                                                                    {hasMissingData && (<Dropdown.Item className="filterException" eventKey="0">Missing Data</Dropdown.Item>)}
                                                                                    {hasPayment && (<Dropdown.Item className="filterException" eventKey="1">Payment</Dropdown.Item>)}
                                                                                    {hasDuplicate && (<Dropdown.Item className="filterException" eventKey="2">Duplicate</Dropdown.Item>)}
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                            <div className="ml-auto">
                                                                {this.state.isReprocessBtnShow && (
                                                                    <div className="selectContainer">
                                                                        <div className="selectHead">
                                                                            <strong>&nbsp;</strong>
                                                                        </div>
                                                                        <span className="publish-changes-wrapper">
                                                                            <span className="pip-icon red"></span>
                                                                            <Button type="button" className="publish-button btn btn-primary"
                                                                                onClick={() => this.reprocessStart()}
                                                                            >
                                                                                {this.state.isProcessStart && (
                                                                                    <Spinner
                                                                                        className="mr-2"
                                                                                        as="span"
                                                                                        animation="border"
                                                                                        size="sm"
                                                                                        role="status"
                                                                                        aria-hidden="true"
                                                                                    />
                                                                                )}
                                                                                Reprocess Rules
                                                                            </Button>
                                                                        </span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div>
                                                                <div className="selectContainer">
                                                                    <div className="selectHead">
                                                                        <strong>&nbsp;</strong>
                                                                    </div>
                                                                    <button type="button" className="btn-outline-primary btn btn-primary" onClick={(e) => { this.showRuleModal() }}>Add Data Rule</button>
                                                                </div>
                                                            </div>
                                                        </>)}
                                                    </div>
                                                </div>
                                            </div>

                                            {this.state.isSetingUpRules ? (
                                                <div className="grid-sec cciCusGridSec">
                                                    <div className={this.state.isSelectedRoleHasValue ? "" : "disabled-area"}>
                                                        <div className="repeativeDropdownSec">
                                                            <div className="table-row isTable">
                                                                {this.state.columnCount.map((index, idx: any) => (
                                                                    <div className="table-cell">
                                                                        <div className="ccimportdd singleSelect importDropdown">
                                                                            <Dropdown key={idx} className="w-190 more-action single-select-image"
                                                                                onSelect={(e: any) => this.selectMapingRuleDrop(e, idx)}>
                                                                                <Dropdown.Toggle id={`settingRule_${idx}`}
                                                                                    onKeyDown={this.onTAB.bind(this, "settingRule_" + idx.toString())}
                                                                                    className="filter-btn">
                                                                                    <div className="d-flex align-items-center">
                                                                                        <div className="txt-area text-capitalize">
                                                                                            {this.state.dynamicMappingFields[idx]?.showName === "" ?
                                                                                                (<span className="dropdownPlaceholder">Select</span>) :
                                                                                                <EllipsisWithTooltip placement="bottom">{this.state.dynamicMappingFields[idx]?.showName}</EllipsisWithTooltip>}
                                                                                        </div>
                                                                                        <div className="drop-arrow ml-0 d-flex">
                                                                                            <FiChevronDown />
                                                                                        </div>
                                                                                    </div>
                                                                                </Dropdown.Toggle>
                                                                                <Dropdown.Menu>
                                                                                    <Dropdown.Item eventKey={"SelectRole"}>
                                                                                        Select
                                                                                    </Dropdown.Item>
                                                                                    {this.state.mappingRuleField.map((items: any, idx: any) => (
                                                                                        <>
                                                                                            <Dropdown.Item
                                                                                                eventKey={JSON.stringify(items)}
                                                                                                key={idx}>
                                                                                                <EllipsisWithTooltip placement="bottom">{items.displayFieldName}</EllipsisWithTooltip>
                                                                                            </Dropdown.Item>
                                                                                        </>
                                                                                    ))}
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                        {/* Table header start */}
                                                        <div className="table-row header-row isTable isTableHeader">
                                                            {this.state.formDataHeaderItems.map((item: any, idx: any) => (
                                                                <div className="table-cell">
                                                                    <div className="data text-capitalize"><strong><EllipsisWithTooltip placement="bottom">{this.state.formDataHeaderItems[idx]}</EllipsisWithTooltip></strong></div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        {/* Table Body start */}
                                                        <React.Fragment>
                                                            {this.state.newArray.map((rowName: any, idx: any) => (
                                                                <div className="table-row isTable isTableRow">
                                                                    {this.state.formDataHeaderItems.map((item: any, idx: any) => (
                                                                        <div className="table-cell"><div className="data"><EllipsisWithTooltip placement="bottom">{rowName[item]}</EllipsisWithTooltip> </div></div>
                                                                    ))}
                                                                </div>
                                                            ))}
                                                        </React.Fragment>
                                                        {/* Table Body end */}
                                                        {this.state.formData.length > 5 && (
                                                            <div className="transactionsLinks">
                                                                <button className="btn wht-bg link-btn" onClick={this.loadNextRows}>Show 5 More Rows</button>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>) : (<>
                                                    <div className="CreditCardNavTabs">
                                                        <Tabs activeKey={this.state.key} className="CCTabsMain" id="import-data-creditCard" onSelect={this.handleTabSelect}>
                                                            <Tab eventKey="import-data" title="Import Data" className="CCTabsSplit">
                                                                <div className="grid-sec cciCusGridSec2">
                                                                    <div className={
                                                                        this.props.pageName !== "InvoiceEntrySlideout" ?
                                                                            this.state.isVandorSpinnerPanel ?
                                                                                "disabled-area scroll-area importedTable" :
                                                                                "scroll-area importedTable" :
                                                                            "scroll-area importedTable importedInvoiceTable"
                                                                    }>
                                                                        <BootstrapTable
                                                                            {...props.baseProps}
                                                                            keyField="rowId"
                                                                            hover
                                                                            noDataIndication={'No record found.'}
                                                                            expandRow={expandCreditCardRow}
                                                                            cellEdit={this.importTablecellEdit}
                                                                            filter={filterFactory()}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Tab>
                                                            <Tab eventKey="data-rules" title={`Data rules (${this.state.dataRuleCount})`} className="CCTabsSplit">
                                                                <DataImportRule
                                                                    ref={this.DataImportRuleList}
                                                                    parentState={this.state}
                                                                    updateCount={this.updateCount}
                                                                    reProcessRulesBtnShow={this.reProcessRulesBtnShow}
                                                                />
                                                            </Tab>
                                                        </Tabs>
                                                    </div>
                                                </>)}
                                        </div>
                                    )}
                                </ToolkitProvider>
                            </>)}
                        </>
                    </Modal.Body>
                    {this.state.isUploadedCCS && (
                        <Modal.Footer>
                            {!this.state.isSetingUpRules ? (
                                <>
                                    {this.state.key === "import-data" && (
                                        <button type="button" onClick={this.handleBackScreen} className="btn-outline-primary btn btn-primary mr-auto">Back</button>
                                    )}
                                    <Button
                                        variant="primary"
                                        disabled={this.state.isImportData || this.state.isReprocessBtnShow || isNotValidCCImport || isValidPayment}
                                        onClick={this.handleImportData}>
                                        {this.state.isImportData && <><Spinner size="sm" animation="border" />{" "}</>} Import Data
                                    </Button>
                                </>
                            ) : (
                                <Button
                                    className="ccimportBtn"
                                    onClick={this.handleNext}
                                    disabled={!this.state.isSelectedRoleHasValue || !this.state.isDynamicArrayHasValue || this.state.isProcessing}
                                    variant="primary">
                                    {this.state.isProcessing ? <><Spinner size="sm" animation="border" />{" "}Processing</> : "Next"}
                                </Button>
                            )}

                        </Modal.Footer>
                    )}

                    <div className="isCusNoField hasVendorSearch">
                        <VendorSearch
                            ref={this.vendorSearch}
                            updateVendorList={this.updateVendorList}
                            handleVendorChange={this.handleVendorChange}
                            getCustomerNo={this.getCustomerNo}
                            HID={this.state.hidValue}
                            callFromPage="CreditCardStatementImport"
                            updateCustomerList={this.updateCustomerList}
                        />
                    </div>

                </Modal>

                {/* {(this.state.isSpinner) && (
                    <div className="spinnerOverModal loader-spinner d-flex justify-content-center align-items-center">
                        <Spinner animation="border" variant="success" />
                    </div>
                )} */}

                <Modal
                    show={this.state.isAddNewRole}
                    onHide={this.addNewRoleClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                    className="ccsImportModal"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Add Rule Name</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Label>Enter rule name</Form.Label>
                        <Form.Control
                            className={this.state.newRuleError ? "border-danger" : ""}
                            value={this.state.newRuleName}
                            type="text"
                            onChange={this.handleNewRuleName}
                            //onBlur={this.NewRuleNameValidation}
                            placeholder="Enter rule name" />
                    </Modal.Body>
                    <Modal.Footer>

                        <Button variant="primary"
                            disabled={this.state.newRuleErrorSet}
                            onClick={this.addNewRoleName}>
                            Add
                        </Button>
                        <Button variant="secondary" className="btnCancel" onClick={this.addNewRoleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>


            </>
        );
    }
}