import React from "react";
import { Button, Col, Dropdown, Form, Modal, Row } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { Utils } from "../../../Common/Utilis";
import { UserManagement } from "../../../Common/Services/UserManagement";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import Calendar from "react-calendar";
import moment from "moment";
import OutsideClickHandler from "react-outside-click-handler";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { IUserDocTypeResponse } from "../../../Common/Contracts/IUserDocTypeResponse";
import { IUserDocumentRequest } from "../../../Common/Contracts/IUserDocumentRequest";
import { AdDocumentType } from "./AdDocumentType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
export class AddDocument extends React.Component<any, any> {
  private documentFormSchema = Joi.object({
    fileUniqueNo: Joi.number().optional().allow(0),
    typeID: Joi.number()
      .required()
      .min(1)
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select Document Type" };
        });
      }),
    fileName: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select File" };
        });
      }),
    description: Joi.string().optional().allow(""),
    date: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select Date" };
        });
      }),
    notes: Joi.string().optional().allow(""),
    file: Joi.object(),
  });

  toggleRef: any  = React.createRef();
  state: any = {
    document: {
      fileUniqueNo: 0,
      typeID: 0,
      fileName: "",
      description: "",
      date: "",
      notes: "",
      file: {},
    },
    calenderShow: false,
    errors: {},
    isDataSaving: false,
    isFormNotValid: true,
    inputPosition: 0,
    docTypes: [],
    showDocTypeModal: false,
    typeId: "",
    typeName: "",
    docEntryType: "New",
    isEditButton: false,
  };

  componentDidMount = () => {
    this.getUserDocumentTypes(false);
  };

  onFieldChange = (event, inputPosition,isBlur = false) => {
    const { document } = this.state;
    let value = event.target.value;
    const fieldName = event.target.name;
    if(fieldName === "description"){
      value =typeof value === 'string' ? value?.toString()?.trimLeft() :value;
    }
    if (fieldName === "typeID" && +value < 0) {
      this.addEditNewType();
    }
    if (fieldName === "date") {
      const _formattedDate = Utils.AddSlashINInputDateFormat(
        value,
        document.date,
        false,
        2,  
        isBlur
      );
      if (_formattedDate === "invalid") 
      {
       return;
     } else {
       value = _formattedDate;
     }
    }

    if (fieldName === "date") {
      const _formattedDate = Utils.AddSlashINInputDateFormat(
        value,
        document.date,
        false,
        2,  
        isBlur
      );
      if (_formattedDate === "invalid") 
      {
       return;
     } else {
       value = _formattedDate;
     }
    }

    document[fieldName] = value;
    if(inputPosition> this.state.inputPosition) 
    {
      this.setState({inputPosition});
    }
    
    this.setState({ document }, () =>
      this.validateDocumentFormSchema()
    );
    if (fieldName === "typeID") {
      $("#ddlDocType")?.click();
    }

    
  };

  onFileDrop = (files) => {
    this.onFieldChange(
      Utils.BuildCustomEventObject("file", files[0]),

      3
    );
    this.onFieldChange(
      Utils.BuildCustomEventObject("fileName", files[0]?.name),

      3
    );
  };

  getUserDocumentTypes = (isReloadRequired: boolean = false, typeName = '') => {
    this.setState({ docTypes: [], isEditButton: false });
    UserManagement.GetUserDocumentType().then(
      (data: IUserDocTypeResponse[] | null) => {
        if(typeName && isReloadRequired) {
          const  document = {...this.state.document};
          document.typeID =  data?.find(x => x?.typeName === typeName)?.uniqueno || 0;
           this.setState({document}, ()=> this.validateDocumentFormSchema());
        }

        let isEditButton =
          (data as any)?.filter((x) => +x.uniqueno < 0)?.length > 0;
        this.setState({ docTypes: [], isEditButton }, () => this.setState({ docTypes: (data?.filter(x => x?.typeName) || []) }));
      }
    );
  };

  reset = () => {
    this.setState({
      document: {
        fileUniqueNo: 0,
        typeID: 0,
        fileName: "",
        description: "",
        date: "",
        notes: "",
        file: {},
      },
      calenderShow: false,
      errors: {},
      isDataSaving: false,
      isFormNotValid: true,
      inputPosition: 0,
      showDocTypeModal: false,
      typeId: "",
      typeName: "",
      docEntryType: "New",
    });
  }
  componentWillUnmount = () => {
    // setTimeout(() => {
    //   toast.dismiss();
    // }, 1000)
  }
  saveUserUploadDoc = () => {
    const {
      document: { typeID, fileName, description, date },
    } = this.state;

    const request: IUserDocumentRequest = {
      FileUniqueNo: "-1",
      UniqueNo: this.props?.uniqueno,
      TypeID: typeID,
      FileName: fileName,
      Description: description?.trim(),
      Date: date,
      Notes: "",
      LoginUser: "",
      TenantID: 0,
    } as IUserDocumentRequest;

    this.setState({ isDataSaving: true });
    UserManagement.SaveUserUploadDoc(request, this.state.document.file)
      .then((result) => {
        this.setState({ isDataSaving: false });
        if (result?.message === "Success") {
          toast.success("Document Uploaded Successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addDocument",
          });
          this.props.getUserDocumentDetails();
          this.props?.handleShowHideDocumentForm()

        } else {
          Utils.toastError(result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addDocument",
          });
        }
      })
      .catch((error) => {
        this.setState({ isDataSaving: false });
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "addDocument",
        });
      }).finally(() => this.reset());
  };
  validateDocumentFormSchema = () => {
    const valid = Joi.validate(this.state.document, this.documentFormSchema, {
      abortEarly: false,
    });

    const newErrorObject = {};
    if (valid.error) {
      valid.error.details.forEach((err) => {
        newErrorObject[err.path.join(".")] = err.message;
      });
    }
    if (Object.keys(newErrorObject).length === 0) {
      this.setState({ isFormNotValid: false });
    } else {
      this.setState({ isFormNotValid: true });
    }
    this.setState({ errors: newErrorObject });
    return newErrorObject;
  };

  getFieldError = (fieldName: string, fieldPosition: number) => {
    const { errors, inputPosition } = this.state;

    return (
      <>
        {Object.keys(errors).length > 0 && fieldPosition <= inputPosition && (
          <span className="validation-message">{errors[fieldName]}</span>
        )}
      </>
    );
  };
  calenderHide = () => {
    this.setState({ calenderShow: false });
  };

  calenderShow = () => {
    this.setState({ calenderShow: true });
  };

  validationOnClick = (inputPosition) => {
    if(inputPosition> this.state.inputPosition) 
    {
      this.setState({ inputPosition }, () => this.validateDocumentFormSchema());
    }
   
  };

  addEditNewType = (isEdit = false) => {
    let typeId = this.state.document.typeID || "";
    if (isEdit && typeId <= 0) {
      this.validationOnClick(1);
      return;
    }

    let typeName =
      (
        this.state.docTypes.find(
          (item: any) => +item?.uniqueno === +typeId
        ) 
      )?.typeName || "";
    if (this.state.showDocTypeModal) {
      this.setState({
        showDocTypeModal: false,
        typeId,
        typeName,
        docEntryType: "New",
      });
    }
    setTimeout(() => {
      if (isEdit) {
        this.setState({ typeId, typeName }, () => {
          this.setState({ showDocTypeModal: true, docEntryType: "Edit" });
        });
      } else {
        this.setState({
          showDocTypeModal: true,
          typeId,
          typeName,
          docEntryType: "New",
        });
      }
    });
  };

  hideDocTypeModal = (isReloadRequired = false, typeName = '') => {
    if (isReloadRequired) {
   
      this.getUserDocumentTypes(isReloadRequired,typeName);
    } else {
      if(!typeName ) {
        const  document = {...this.state.document};
        document.typeID =  0;
         this.setState({document}, ()=> this.validateDocumentFormSchema());
      }
    }
    this.setState(
      {
        showDocTypeModal: false,
        typeId: "",
        typeName: "",
        docEntryType: "New",
      });
  };

  openModal = () => {
    this.reset();
  };

   onTabKeyDown = (e) => {
    e.preventDefault();
    if (this.toggleRef.current.ariaExpanded === "false" && e?.keyCode === 9) {
      this.toggleRef.current.click();
     
    }
  };
  render() {
    const {
      document: { typeID, fileName, description, date },
      errors,
      docTypes,
      inputPosition,
      isFormNotValid,
      isDataSaving,
      showDocTypeModal,
      docEntryType,
      typeId,
      typeName,
      isEditButton,
    } = this.state;

    return (
      <>
        {/* <div className="add-documents-wrapper"> */}
        
          {docEntryType === "Edit" ? (
            <>
              {typeId && typeName && showDocTypeModal && (
                <AdDocumentType
                  typeId={typeId}
                  typeName={typeName}
                  docEntryType={docEntryType}
                  showDocTypeModal={showDocTypeModal}
                  hideDocTypeModal={this.hideDocTypeModal}
                />
              )}
            </>
          ) : (showDocTypeModal &&
            
            <AdDocumentType
              typeId={0}
              typeName={""}
              docEntryType={docEntryType}
              showDocTypeModal={showDocTypeModal}
              hideDocTypeModal={this.hideDocTypeModal}
            />
          )}

          <Modal
            className="add-document-modal"
            show={this.props?.showDocumentForm}
            onHide={this.props?.handleShowHideDocumentForm}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={this.props?.showDeductionModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Add Document
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-section">
                <div className="body-section">
                  <Form.Group as={Row} controlId="description">
                    <Col sm="12">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        onChange={(e) => this.onFieldChange(e, 0)}
                        value={description}
                        maxLength={100}
                        id="txtDescription"
                        name="description"
                      ></Form.Control>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="time-picker">
                    {docTypes?.length > 0 &&
                      <Col
                        sm={"12"}
                        className={`mb-3 ${errors?.typeID && inputPosition >= 1
                          ? "validation-error"
                          : ""}`
                        }
                        onBlur={() => this.validationOnClick(1)}
                      >
                        <Form.Label>Type</Form.Label>
                        <div className="d-flex flex-row justify-content-between align-items-center">
                          <Dropdown
                           onKeyUp={(e)=>this.onTabKeyDown(e)}
                          className="import-from-dropdown">
                            <Dropdown.Toggle  
                            
                            
                            ref={this.toggleRef} id="ddlDocType">
                              {!typeID ? (
                                <span className="placeholder">Select Type</span>
                              ) : (
                                <EllipsisWithTooltip placement="bottom">
                                  <span className="selected-item">
                                    {
                                      (
                                        docTypes.find(
                                          (item: any) =>
                                            +item?.uniqueno === +typeID
                                        ) 
                                      )?.typeName
                                    }
                                  </span>
                                </EllipsisWithTooltip>
                              )}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {docTypes?.length > 0 && (
                                <>
                                  {docTypes.map((item: any) => {
                                    return (
                                     
                                        <Dropdown.Item
                                        key={item.uniqueno}
                                          className="dropdown-item"
                                          onClick={() =>
                                            this.onFieldChange(
                                              Utils.BuildCustomEventObject(
                                                "typeID",
                                                item.uniqueno
                                              ),
                                              1
                                            )
                                          }
                                        >
                                           <EllipsisWithTooltip placement="bottom">
                                           {item.typeName}
                                           </EllipsisWithTooltip>
                                       
                                        </Dropdown.Item>
                                    );
                                  })}
                                </>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>

                          {isEditButton && (
                            <button
                              className="edit-document-type btn btn-primary"
                              type="button"
                              onClick={() => this.addEditNewType(true)}
                            >
                              <FontAwesomeIcon
                                icon={faEdit}
                              />
                            </button>
                          )}
                        </div>
                        {this.getFieldError("typeID", 1)}
                      </Col>
                    }
                    <Col
                      sm={"12"}
                      onBlur={() => this.validationOnClick(2)}
                      className={
                        errors?.date && inputPosition >= 2
                          ? "validation-error"
                          : ""
                      }
                    >
                      <Form.Label>Date</Form.Label>
                      <div className="validate-input d-flex flex-column">
                        <div className="calender-container">
                          <div
                            className={
                              this.state.calenderShow
                                ? "calender-display open"
                                : "calender-display"
                            }
                            onClick={this.calenderShow}
                          >
                            <input
                              onFocus={this.calenderShow}
                              // type="text"
                              placeholder="MM/DD/YY"
                              value={date}
                              className="form-control single-date-pick"
                              name="date"
                              autoComplete="date"
                              onInput={(e) => {
                                let value = e.currentTarget.value;
  
                                value = value
                                  ?.toString()
                                  ?.replace(/[^0-9\/]+/gi, "");
  
                                  this.onFieldChange(
                                  Utils.BuildCustomEventObject("date", value),
                                  2
                                );
                              }}


                              maxLength={8}
                            
                              onBlur={(e) => {
                                let value = e.currentTarget.value;
  
                                value = value
                                  ?.toString()
                                  ?.replace(/[^0-9\/]+/gi, "");
  
                                  this.onFieldChange(
                                  Utils.BuildCustomEventObject("date", value),
                                  2,
                                  true
                                 
                                );
  
                              
                              }}
                            />
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z" />
                              <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z" />
                            </svg>
                          </div>
                          {this.state.calenderShow && (
                            <div className="calender">
                              <OutsideClickHandler
                                onOutsideClick={this.calenderHide}
                              >
                                <Calendar
                                  calendarType={"US"}
                                  onChange={(date) => {
                                    const value =
                                      moment(date).format("MM/DD/YY");
                                    this.calenderHide();
                                    this.onFieldChange(
                                      Utils.BuildCustomEventObject(
                                        "date",
                                        value
                                      ),
                                      2
                                    );
                                    this.calenderHide();
                                  }}
                                  value={
                                    date
                                      ? moment(date)?.format(
                                        "MM/DD/YY"
                                      ) === "Invalid date" ? new Date():  new Date(
                                        date
                                      )
                                      : new Date()
                                  }
                                  //  minDate = {userGeneralInfo}
                                />
                              </OutsideClickHandler>
                            </div>
                          )}
                        </div>
                      </div>
                      {this.getFieldError("date", 2)}
                    </Col>
                  </Form.Group>


                  <Form.Group
                    as={Row}
                    controlId="fileUpload"
                    onClick={() => this.validationOnClick(3)}
                    className={
                      errors?.fileName && inputPosition >= 3
                        ? "validation-error-file"
                        : ""
                    }
                  >
                    <Dropzone onDrop={this.onFileDrop} multiple={false}
                    >
                      {({
                        getRootProps,
                        getInputProps,
                        isDragActive,
                        isDragAccept,
                        isDragReject,
                      }) => (
                        <section className="upload-area">
                          <aside className="files">
                            <div
                              {...getRootProps({
                                className: "dropzone",
                              })}
                            >
                              <input {...getInputProps()} />
                              <div>
                                {fileName ? (
                                  <EllipsisWithTooltip placement="bottom">
                                    <p>
                                      {fileName}{" "}
                                    </p>
                                  </EllipsisWithTooltip>
                                ) : (
                                  <p>
                                    Drag &amp; drop here or{" "}
                                    <span >browse files</span> to upload
                                  </p>
                                )}
                              </div>
                            </div>
                          </aside>
                        </section>
                      )}
                    </Dropzone>
                    {this.getFieldError("fileName", 3)}
                  </Form.Group>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn-ghost"
               onClick={() => this.props?.handleShowHideDocumentForm()}
              >
                Cancel
              </Button>
              <Button
                disabled={isFormNotValid || isDataSaving}
                className="btn btn-primary"
                onClick={this.saveUserUploadDoc}
              >
                Save
              </Button>
            </Modal.Footer>
          </Modal>


        {/* </div> */}
      </>
    );
  }
}
