import * as React from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ModuleReports } from "../../Common/Services/ModuleReports";

export class GroupBySelector extends React.Component<any, any> {
  private sortParameters = [
    { label: "Employee", value: "Employee" },
    { label: "EHID", value: "EHID" },
  ];

  constructor(props: any) {
    super(props);

    this.state = {
      selectedItem: { label: "Employee", value: "Employee" },
      sortParameters: this.sortParameters,
    };
  }

  componentDidMount = () => {
    const { defaultValue } = this.props;
    if (!defaultValue) {
      this.handleDropdownChange(this.state.selectedItem);
    } else {
      this.handleDropdownChange(
        this.sortParameters.filter((x) => x?.label === defaultValue)[0]
      );
    }
  };

  handleDropdownChange = (item: any) => {
    this.setState({ selectedItem: item });
    //this.props?.handleCOAType(item.value);
    this.props?.handleDropdownChange(item.value);
  };

  componentDidUpdate = (prevprops, prevState) => {
    const { defaultValue } = this.props;
    if (prevprops.defaultValue !== defaultValue && defaultValue?.length > 0) {
      let arr = this.state.sortParameters.filter(
        (e) => e.value === defaultValue
      );
      this.setState({
        selectedItem:
          arr.length > 0 ? arr[0] : { label: "Employee", value: "Employee" },
      });
    }
    if (!defaultValue && prevprops?.defaultValue !== defaultValue) {
      this.setState({ selectedItem: { label: "Employee", value: "Employee" } });
    }
  };

  render() {
    return (
      <Dropdown className="single-select">
        <Dropdown.Toggle id="Modal-empType">
          <span
            style={{
              width: "95%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "block",
            }}
          >
            {this.state.selectedItem.label}
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div className="list-area">
            {this.state.sortParameters.map((item: any, idx: any) => (
              <Dropdown.Item onClick={() => this.handleDropdownChange(item)}>
                {item.label}
              </Dropdown.Item>
            ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}
