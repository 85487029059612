import Instense from "./Axios-config";
import {
  ILaborPTOListDTO,
  ILaborPTOListRequestDTO,
  ILaborPTORequestDTO,
  ILaborPTOCalculationDTO,
  ILaborPTOTypeFrequencyListDTO,
} from "../Contracts/ILaborPTOCalculation";
import { ITimeOffChangeLogRequest,ITimeOffLogResponse } from "../Contracts/ITimeOffChangeLog";
export class LaborPTOCalculation {

    public static GetTimeOffChangeLog = async (
      request : ITimeOffChangeLogRequest 
    ): Promise<ITimeOffLogResponse[] | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.tenantId = tenantID;
        const url = `/LaborPTOCalculation/GetTimeOffChangeLog`;
        return Instense.post(url,request).then((response) => {
            return response.data?.result as ITimeOffLogResponse[];
        });
    };

    public static DownloadChangeLog = async (
      request : ITimeOffChangeLogRequest , fileName: string
  ): Promise<any | null> => {
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : (storage.tenantID as any);
      request.tenantId = tenantID;
   

      const url = `/LaborPTOCalculation/DownloadChangeLog`;
      return Instense.post(url, request,  { responseType: "blob" }).then(
        
          (response: any) => {
              ;
            let csvURL = window.URL.createObjectURL(response.data);
            let tempLink = document.createElement("a");
            tempLink.href = csvURL;
            tempLink.setAttribute("download", fileName + ".xlsx");
            tempLink.click();
          }
        );
  };

  public static GetLaborPTOList = async (): Promise<
    ILaborPTOListDTO[] | null
  > => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request = {} as ILaborPTOListRequestDTO;
    request.tenantID = tenantID;
    const url = `/LaborPTOCalculation/GetLaborPTOList`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as ILaborPTOListDTO[];
      return result;
    });
  };

  public static GetLaborPTODetails = async (
    ptoID: number
  ): Promise<ILaborPTOCalculationDTO[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request = {} as ILaborPTORequestDTO;
    request.tenantID = tenantID;
    request.ptoID = ptoID;
    const url = `/LaborPTOCalculation/GetLaborPTODetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as ILaborPTOCalculationDTO[];
      return result;
    });
  };

  public static GetLaborPTOSubTypes =
    async (): Promise<ILaborPTOTypeFrequencyListDTO | null> => {
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : (storage.tenantID as any);
      let request = {} as ILaborPTOListRequestDTO;
      request.tenantID = tenantID;
      const url = `/LaborPTOCalculation/GetLaborPTOType`;
      return Instense.get(url, { params: request }).then((response) => {
        let result = response.data.result as ILaborPTOTypeFrequencyListDTO;
        return result;
      });
    };

  public static saveLaborPTODetails = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborPTOCalculation/SaveLaborPTODetails`;
    return Instense.post(url, request).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static UpdatePTOStatus = async (
    ptoID: number,
    status: string
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request = {} as any;
    request.tenantID = tenantID;
    request.ptoID = ptoID;
    request.status = status;
    const url = `/LaborPTOCalculation/UpdatePTOStatus`;
    return Instense.post(url, request).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static loadWeeklyHour = async (): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request = {} as any;
    request.tenantID = tenantID;
    const url = `/Hotel/WorkingHourDetail`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static getPTOBalance = async (
    hotelID: Number,
    userName: String,
    startDate: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request = {} as any;
    request.tenantID = tenantID;
    request.hotelID = hotelID;
    request.userName = userName;
    request.startDate = startDate;
    const url = `/LaborPTO/GetPTOBalance`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };
}
