export const metricsData = [
  {
    id: 1,
    type: "percentage",
    name: "Occupancy %",
    title: "Occupancy % title",
    description: "The percentage of available rooms that are occupied.",
    value: "75.6%",
    defaultType: "number",
  },
  {
    id: 2,
    type: "number",
    name: "RevPAR",
    title: "RevPAR title",
    description: "Revenue per available room.",
    value: "$123.45",
    defaultType: "bar",
  },
  {
    id: 3,
    type: "currency",
    name: "GOP",
    title: "GOP title",
    description: "Gross Operating Profit.",
    value: "$234,567",
    defaultType: "line",
  },
  {
    id: 4,
    type: "currency",
    name: "ADR",
    title: "Average Daily Rate",
    description: "Gross Operating Profit.",
    value: "$234,567",
    defaultType: "line",
  },
  // Add more metrics as needed
];

export const dummyMetricDataNumberCard = [
  { value: "385304", subvalue: "12%", type: "currency" },
  { value: "50", subvalue: "12%", type: "percentage" },
  { value: "385", subvalue: "12%", type: "number" },
];
