
import Instense from "./Axios-config";
import axios from "axios";
export class InvoiceApprovalS {
    private static ajaxRequestFindData: any = null;
    private static ajaxInvoiceApprovalCount: any = null;
    private static ajaxRequestPaymentDetails: any = null;    
    private static ajaxGetCountsForPayment: any = null;
    private static ajaxWaitingOnMe: any = null;
    private static ajaxDataAllInvoices: any = null;
    public static GetHotelCOAlist = async (
        request: any
    ): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID: any = storage === null ? 0 : storage.tenantID;
        request.TenantID = tenantID;
        const url = `/InvoiceApproval/GetCOAlistAppRule`;
        return Instense.get(url, { params: request }).then((response) => {
            let result: any = response.data.result
            return result;
        });
    };

    public static GetVendorlist = async (
        request: any
    ): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID: any = storage === null ? 0 : storage.tenantID;
        request.TenantID = tenantID;
        const url = `/InvoiceApproval/GetVendorListAppRule`;
        return Instense.get(url, { params: request }).then((response) => {
            let result: any = response.data.result
            return result;
        });
    };

    public static GetInvoiceApprovalList = async (
    ): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID: any = storage === null ? 0 : storage.tenantID;
        let request = {} as any;
        request.TenantID = tenantID;
        const url = `/InvoiceApproval/GetInvoiceAppRule`;
        return Instense.get(url, { params: request }).then((response) => {
            let result: any = response.data.result
            return result;
        });
    };

    public static GetInvoiceAppRuleDetails = async (
        request: any
    ): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID: any = storage === null ? 0 : storage.tenantID;
        request.TenantID = tenantID;
        const url = `/InvoiceApproval/GetInvoiceAppRuleDetails`;
        return Instense.get(url, { params: request }).then((response) => {
            let result: any = response.data.result
            return result;
        });
    };

    public static SaveUpdateInvoiceAppRule = async (
        request: any
    ): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID: any = storage === null ? 0 : storage.tenantID;
        request.TenantID = tenantID;
        const url = `/InvoiceApproval/SaveUpdateInvoiceAppRule`;
        const response = await Instense.post(url, request);
        let result: any = response.data;
        return result;
    };

    public static GetInvoiceWaitingOnMe = async (request: any): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID: any = storage === null ? 0 : storage.tenantID;
        request.TenantID = tenantID;
        const url = `/InvoiceApproval/GetInvoiceWaitingOnMe?EHID=${request.EHID}&TenantID=${tenantID}`;
        if (InvoiceApprovalS.ajaxWaitingOnMe) {
            InvoiceApprovalS.ajaxWaitingOnMe.cancel("Cancel");
        }
        InvoiceApprovalS.ajaxWaitingOnMe = axios.CancelToken.source();
        return Instense.post(url, request, { cancelToken: InvoiceApprovalS.ajaxWaitingOnMe.token }).then((response) => {
            let result: any = response.data.result
            return result;
        });
    };

    public static GetInvoiceDataAllInvoices = async (request: any): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID: any = storage === null ? 0 : storage.tenantID;
        request.TenantID = tenantID;
        const url = `/InvoiceApproval/GetInvoiceDataAllInvoices?EHID=${request.EHID}&TenantID=${tenantID}`;
        if (InvoiceApprovalS.ajaxDataAllInvoices) {
            InvoiceApprovalS.ajaxDataAllInvoices.cancel("Cancel");
        }
        InvoiceApprovalS.ajaxDataAllInvoices = axios.CancelToken.source();
        return Instense.post(url, request, { cancelToken: InvoiceApprovalS.ajaxDataAllInvoices.token }).then((response) => {
            let result: any = response.data.result
            return result;
        });
    };

    public static GetApproveInvoiceRule = async (request: any): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID: any = storage === null ? 0 : storage.tenantID;
        request.TenantID = tenantID;
        const url = `/InvoiceApproval/GetApproveInvoiceRule`;
        return Instense.post(url, request).then((response) => {
            let result: any = response.data.result
            return result;
        });
    };

    public static ApproveInvoiceWaitingOnMe = async (request: any): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID: any = storage === null ? 0 : storage.tenantID;
        request.TenantID = tenantID;
        const url = `/InvoiceApproval/ApproveInvoiceWaitingOnMe`;
        const response = await Instense.post(url, request);
        let result: any = response.data.result
        return result;
    };

    public static deleteRule = async (request: any): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID: any = storage === null ? 0 : storage.tenantID;
        request.TenantID = tenantID;
        const url = `/InvoiceApproval/DeleteInvoiceRule`;
        return Instense.get(url, { params: request }).then((response) => {
            let result: any = response.data.result
            return result;
        });
    };

    public static GetMainGroup = async (): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID: any = storage === null ? 0 : storage.tenantID;
        let request: any = {};
        request.TenantID = tenantID;
        const url = `/InvoiceApproval/GetMainGroup`;
        return Instense.get(url, { params: request }).then((response) => {
            let result: any = response.data.result
            return result;
        });
    };

    public static GetCOASubGroup = async (request: any): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID: any = storage === null ? 0 : storage.tenantID;
        request.TenantID = tenantID;
        const url = `/InvoiceApproval/GetCOASubGroup`;
        return Instense.get(url, { params: request }).then((response) => {
            let result: any = response.data.result
            return result;
        });
    };

    public static GetUnApproveVendors = async (request: any): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID: any = storage === null ? 0 : storage.tenantID;
        request.TenantID = tenantID;
        const url = `/InvoiceApproval/GetUnApproveVendors`;
        return Instense.get(url, { params: request }).then((response) => {
            let result: any = response.data.result
            return result;
        });
    };

    public static VendorApprove = async (
        request: any
    ): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID: any = storage === null ? 0 : storage.tenantID;
        let userName: any = storage === null ? 0 : storage.userName;
        request.TenantID = tenantID;
        request.userName = userName;
        const url = `/Register/VendorApprove`;
        const response = await Instense.post(url, request);
        let result: any = response.data;
        return result;
    };

    public static denyUnApproveVendor = async (
        request: any
    ): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID: any = storage === null ? 0 : storage.tenantID;
        let userName: any = storage === null ? 0 : storage.userName;
        request.TenantID = tenantID;
        request.userName = userName;
        const url = `/Register/DenyUnApproveVendor`;
        const response = await Instense.post(url, request);
        let result: any = response.data;
        return result;
    };

    public static GetPaymentDetails = async (
        HID: any, Type: string
    ): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID: any = storage === null ? 0 : storage.tenantID;
        let request = {} as any;
        request.TenantID = tenantID;
        request.HID = HID;
        request.Type = Type;
        const url = `/InvoiceApproval/GetDataForPayment?TenantID=${tenantID}&HID=${HID}&Type=${Type}`;
        if (InvoiceApprovalS.ajaxRequestPaymentDetails) {
            InvoiceApprovalS.ajaxRequestPaymentDetails.cancel("Cancel");
        }
        InvoiceApprovalS.ajaxRequestPaymentDetails = axios.CancelToken.source();
        return Instense.post(url, request, { cancelToken: InvoiceApprovalS.ajaxRequestPaymentDetails.token }).then((response) => {
            let result: any = response.data.result
            return result;
        });
    };

    public static GetCountsForPayment = async (
        HID: any
    ): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID: any = storage === null ? 0 : storage.tenantID;
        let request = {} as any;
        request.TenantID = tenantID;
        request.HID = HID;
        const url = `/InvoiceApproval/GetCountsForPayment?TenantID=${tenantID}&HID=${HID}`;
        if (InvoiceApprovalS.ajaxGetCountsForPayment) {
            InvoiceApprovalS.ajaxGetCountsForPayment.cancel("Cancel");
        }
        InvoiceApprovalS.ajaxGetCountsForPayment = axios.CancelToken.source();
        return Instense.post(url, request, { cancelToken: InvoiceApprovalS.ajaxGetCountsForPayment.token }).then((response) => {
            let result: any = response.data.result
            return result;
        });
    };

    public static ConfirmInvoicePayment = async (
        request: any
    ): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID);
        request.forEach((items) => { // 
            items.tenantID = tenantID;
        });
        const url = `/register/ConfirmInvoicePayment`;
        return Instense.post(url, request).then((response) => {
            let result: any = response.data;
            return result;
        });
    };

    public static UnApproveInvoiceBulk = async (
        request: any
    ): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID);
        request.TenantID = tenantID;
        const url = `/InvoiceApproval/UnApproveInvoiceBulk`;
        return Instense.post(url, request).then((response) => {
            let result: any = response.data;
            return result;
        });
    };

    public static ConfirmTransferPayment = async (
        request: any
    ): Promise<any | null> => {
        const url = `/Register/ConfirmTransferPayment`;
        return Instense.post(url, request).then((response) => {
            let result: any = response.data;
            return result;
        });
    };

    public static confirmPrintCheck = async (
        request: any
    ): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID);
        request.TenantID = tenantID;
        const url = `/InvoiceApproval/ConfirmCheckPrint`;
        return Instense.post(url, request).then((response) => {
            let result: any = response.data;
            return result;
        });
    };

    public static InvoiceCkGenerate = async (request: any[]): Promise<any[] | null> => {
        const url = `/InvoicePayment/InvoiceCkGenerate`;
        return Instense.post(url, request).then((response) => {
            let result: any = response.data.result
            return result;
        });
    };

    public static InvoiceDAchPayment = async (request: any[]): Promise<any[] | null> => {
        const url = `/InvoicePayment/InvoiceDAchPayment`;
        return Instense.post(url, request).then((response) => {
            let result: any = response.data.result
            return result;
        });
    };

    public static PrintActualCheck = async (request: any): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID);
        request.tenantID = tenantID;
        const url = `/InvoicePayment/PrintActualCheck`;
        return Instense.post(url, request, { responseType: "blob" }).then(
            (response: any) => {
                let file = new Blob([response.data], { type: "application/pdf" });
                let fileURL = URL.createObjectURL(file);
                InvoiceApprovalS.popupCenter({ url: fileURL, title: "Print Check", w: 800, h: 600 });
            }
        );
    };

    public static popupCenter = ({ url, title, w, h }) => {
        const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;
        const width = window.innerWidth;
        const height = window.innerHeight;
        const systemZoom = width / window.screen.availWidth;
        const left = (width - w) / 2 / systemZoom + dualScreenLeft
        const top = (height - h) / 2 / systemZoom + dualScreenTop
        const newWindow = window.open(url, title, `scrollbars=yes,width=${w / systemZoom},height=${h / systemZoom},top=${top}, left=${left}`)
        newWindow?.window.print();
    }

    public static UpdatePrintConfirm = async (request: any): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID);
        request.TenantID = tenantID;
        const url = `/InvoicePayment/UpdatePrintConfirm`;
        return Instense.post(url, request).then((response) => {
            let result: any = response.data;
            return result;
        });
    };

    public static getInvoiceApprovalCount = async (request: any): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID: any = storage === null ? 0 : storage.tenantID;
        request.TenantID = tenantID;
        const url = `/InvoiceApproval/GetInvoiceApprovalCount?HID=${request.HID}&TenantID=${tenantID}`;
        if (InvoiceApprovalS.ajaxInvoiceApprovalCount) {
            InvoiceApprovalS.ajaxInvoiceApprovalCount.cancel("Cancel");
        }
        InvoiceApprovalS.ajaxInvoiceApprovalCount = axios.CancelToken.source();
        return Instense.post(url, request, { cancelToken: InvoiceApprovalS.ajaxInvoiceApprovalCount.token }).then((response) => {
            let result: any = response.data.result
            return result;
        });
    };

    public static UnApproveInvoice = async (
        request: any
    ): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID);
        request.TenantID = tenantID;
        const url = `/InvoiceApproval/UnApproveInvoice`;
        return Instense.post(url, request).then((response) => {
            let result: any = response.data;
            return result;
        });
    };

    public static GetInvoiceRuleforUnApprove = async (request: any): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID: any = storage === null ? 0 : storage.tenantID;
        request.TenantID = tenantID;
        const url = `/InvoiceApproval/GetInvoiceRuleforUnApprove`;
        return Instense.get(url, { params: request }).then((response) => {
            let result: any = response.data;
            return result;
        });
    };

    public static savePartialPayment = async (
        request: any
    ): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID: any = storage === null ? 0 : storage.tenantID;
        let userName: any = storage === null ? 0 : storage.userName;
        request.TenantID = tenantID;
        request.UserName = userName;
        const url = `/InvoiceApproval/SavePartialPayment`;
        const response = await Instense.post(url, request);
        let result: any = response.data;
        return result;
    };

    public static CheckACPeriodStatusByDate = async (
        request: any
    ): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID);
        request.TenantID = tenantID;
        const url = `/Transfer/CheckACPeriodStatusByDate`;
        return Instense.post(url, request).then((response) => {
            let result: any = response.data;
            return result;
        });
    };

    public static CheckACCOpeningDateStatusByDate = async (
        request: any
    ): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID);
        request.TenantID = tenantID;
        const url = `/Transfer/CheckACCOpeningDateStatusByDate`;
        return Instense.post(url, request).then((response) => {
            let result: any = response.data;
            return result;
        });
    };

    public static MakeEpayInvoicePayment = async (
        request: any
    ): Promise<any | null> => {
        const url = `/ACHPayment/MakeEpayInvoicePayment`;
        return Instense.post(url, request).then((response) => {
            let result: any = response.data;
            return result;
        });
    };

    public static CancelEpayInvoicePayment = async (
        request: any
    ): Promise<any | null> => {
        const url = `/ACHPayment/CancelEpayInvoicePayment`;
        return Instense.post(url, request).then((response) => {
            let result: any = response.data.result
            return result;
        });
    };

    public static CheckEpayEnabled = async (): Promise<any | null> => {
        let request: any = {};
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID: any = storage === null ? 0 : storage.tenantID;
        request.TenantID = tenantID;
        const url = `/Vendor/CheckEpayEnabled`;
        return Instense.get(url, { params: request }).then((response) => {
            let result: any = response.data.result
            return result;
        });
    };

    public static CheckACPeriodStatusByDateBank = async (
        request: any
    ): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID);
        request.TenantID = tenantID;
        const url = `/BankSync/CheckACPeriodStatusByDate`;
        return Instense.post(url, request).then((response) => {
            let result: any = response.data;
            return result;
        });
    };

    public static GetEpayCheckImageURL = async (
        request: any
    ): Promise<any | null> => {
        const url = `/ACHPayment/GetEpayPayment?degitalID=${request.degitalID}`;
        return Instense.get(url, { params: request }).then((response) => {
            let result: any = response.data.result
            return result;
        });
    };

    public static CheckAmountInGroup = async (request: any): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID);
        request.TenantID = tenantID;
        const url = `/ACHPayment/CheckAmountInGroup`;
        return Instense.post(url, request).then((response) => {
            let result: any = response.data.result
            return result;
        });
    };
}