import * as React from "react";
import {
  Form,
} from "react-bootstrap";
import { DropDownMultiList } from "./common-components/dropdown-multi-list";

export class DataSourceSelector extends React.Component<any, any> {
  private checkBoxRef: any;
  constructor(props: any) {
    super(props);
    this.checkBoxRef = React.createRef();
    this.state = {
      dataSourceList: [{ label: "ADR", value: "ADR", isChecked: true }, { label: "OCC", value: "OCC", isChecked: true }, { label: "RM REV", value: "RM REV" }, { label: "REVPAR", value: "REVPAR" }]
      , defaultValue: "",
    }
  }


  componentDidUpdate(prevProps, prevState) {
    const { defaultValue } = this.props;
    let dataSourceList = [...this.state.dataSourceList];
    if (prevProps.defaultValue !== defaultValue) {
      let groupNames = defaultValue.split(",")?.map(r => r);
      const selectedGroups = dataSourceList?.filter(r => groupNames?.includes(r.label))
      groupNames = groupNames.filter(r => selectedGroups?.map(r => r?.label)?.includes(r));
      dataSourceList = dataSourceList?.map(item => {
        if (groupNames?.includes(item?.label)) {
          item.isChecked = true;
        } else {
          item.isChecked = false;
        }
        return item;
      })
      this.setState({ dataSourceList, defaultValue });
    }
  }

  componentDidMount = () => {

    const { defaultValue } = this.props;
    let dataSourceList = [...this.state.dataSourceList];
    if (defaultValue === undefined) {
      this.props?.onDataSourceChange(this.state.dataSourceList);
    } else {
      let groupNames = defaultValue.split(",")?.map(r => r);
      const selectedGroups = dataSourceList?.filter(r => groupNames?.includes(r.label))
      groupNames = groupNames.filter(r => selectedGroups?.map(r => r?.label)?.includes(r));
      dataSourceList = dataSourceList?.map(item => {
        if (groupNames?.includes(item?.label)) {
          item.isChecked = true;
        } else {
          item.isChecked = false;
        }
        return item;
      })
      this.setState({ dataSourceList, defaultValue }, () => {
        this.props?.onDataSourceChange(dataSourceList);
      });
    }
  };

  updateMultiSelectItem = (selectedItem: any) => {
    this.props?.onDataSourceChange(selectedItem);
  };

  render() {
    return (
      <DropDownMultiList
        defaultValue={this.state?.defaultValue}
        label={"label"}
        value={"value"}
        data={[...this.state.dataSourceList]}
        updateMultiSelectItem={this.updateMultiSelectItem}
        isSelectAll={true}
        placeHolder={"Select Data Source 1"}
        filtertitle={"Filter Data Source 1"}
      />
    );
  }
}
