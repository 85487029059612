import React from "react";
import { Button, ButtonGroup, Dropdown, Form, Row } from "react-bootstrap";
import moment from "moment";
import _ from "lodash";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
export class PeriodSelector extends React.Component<any, any> {
  toggleRef: any = React.createRef();
  constructor(props: any) {
    super(props);
    this.state = {
      selectedPeriod: { label: "Select", value: 0 },
      currentIndex: -1,
    };
  }

  componentDidMount() {
    const selectedPeriod = this.props.periods.find(
      (r) => r.value === this.props?.defaultValue
    );
    this.setState({ selectedPeriod });
  }

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.defaultValue != this.props.defaultValue ||
      !_.isEqual(prevProps.periods, this.props.periods)
    ) {
      const selectedPeriod = this.props.periods.find(
        (r) => r.value === this.props?.defaultValue
      );
      this.setState({ selectedPeriod });
    }
  };

  onTabKeyDown = (e) => {
    e.preventDefault();
    if (this.toggleRef.current.ariaExpanded === "false" && e?.keyCode === 9) {
      this.toggleRef.current.click();
    }
  };

  onPeriodChange = (selectedItem, currentIndex = 0) => {
    this.setState({ selectedPeriod: selectedItem, currentIndex });
    this.props.onChange(selectedItem);
  };
  render() {
    const { currentIndex } = this.state;
    return (
      <div className="day-year-picker-container single-select calender-container">
        <Dropdown
         
          tabIndex={0}
          onKeyUp={(e) => this.onTabKeyDown(e)}
          className="single-select calender-display"
        >
          <Dropdown.Toggle
            ref={this.toggleRef}
            id="ddlPeriod"
            className={this.props?.showBlueBg ? "selected-blue-bg" : ""}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M4.66663 7.33325H5.99996V8.66659H4.66663V7.33325ZM4.66663 9.99992H5.99996V11.3333H4.66663V9.99992ZM7.33329 7.33325H8.66663V8.66659H7.33329V7.33325ZM7.33329 9.99992H8.66663V11.3333H7.33329V9.99992ZM9.99996 7.33325H11.3333V8.66659H9.99996V7.33325ZM9.99996 9.99992H11.3333V11.3333H9.99996V9.99992Z" />
              <path d="M3.33333 14.6666H12.6667C13.402 14.6666 14 14.0686 14 13.3333V5.33325V3.99992C14 3.26459 13.402 2.66659 12.6667 2.66659H11.3333V1.33325H10V2.66659H6V1.33325H4.66667V2.66659H3.33333C2.598 2.66659 2 3.26459 2 3.99992V5.33325V13.3333C2 14.0686 2.598 14.6666 3.33333 14.6666ZM12.6667 5.33325L12.6673 13.3333H3.33333V5.33325H12.6667Z" />
            </svg>

            <div className="d-inline-block">
              <EllipsisWithTooltip placement="bottom">
                {this.state.selectedPeriod?.label}
              </EllipsisWithTooltip>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {this.props.periods.map((item, index) => {
              return (
                <Dropdown.Item
                  key={item.value}
                  onClick={(e: any) => this.onPeriodChange(item, index)}
                >
                  {item?.label}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}
