import * as React from "react";
import {
  Button,
  Container,
  Dropdown,
  Form,
  // Form,
  Modal,
  Tab,
  Tabs,
} from "react-bootstrap";
import { SingleSearchDropdownList } from "../../Common/Components/SingleSearchDropdownList";
import { UserManagement } from "../../Common/Services/UserManagement";
import { IUserRole } from "../../Common/Contracts/IUserRole";
import _ from "lodash";
import { toast } from "react-toastify";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { DropDownList } from "../Reports/common-components/dropdown-list";
export class ResetPassword extends React.Component<any, any> {
  private primaryContacts: any[] = [
    {
      label: "Email",
      value: "Email",
    },
    { label: "Mobile Phone", value: "Phone" },
  ];
  constructor(props: any) {
    super(props);

    this.state = {
      showRoleModal: this.props.showModal,
      disableSave: false,
      primaryMethodForSelectedUser: this.props?.selectedUsers[0]?.primaryContact || "",
      primaryMethodForLoginUser:  JSON.parse(localStorage.getItem("storage")!)?.primaryContact || "",
      primaryContactsUser: [],
      primaryContactsLoginUser: []

    };
  }
  componentDidMount() {
    let {    primaryContactsUser,  primaryContactsLoginUser} = this.state;
    primaryContactsUser = _.cloneDeep(this.primaryContacts);
    primaryContactsLoginUser = _.cloneDeep(this.primaryContacts);
    if(!this.props?.selectedUsers[0]?.phone1 &&  !this.props?.selectedUsers[0]?.email) {
      primaryContactsUser.push({ label: "None", value: "None" });
      this.setState({primaryMethodForSelectedUser:"None",primaryContactsUser})
    } else if(!this.props?.selectedUsers[0]?.phone1 ) {
      primaryContactsUser= primaryContactsUser.filter(x=>x?.value!== "Phone");
      this.setState({primaryContactsUser, primaryMethodForSelectedUser:"Email"})
    }
    else if( !this.props?.selectedUsers[0]?.email) {
      primaryContactsUser= primaryContactsUser.filter(x=>x?.value!== "Email");
      this.setState({primaryContactsUser,primaryMethodForSelectedUser:"Phone"})
    } 
    else {
      this.setState({primaryContactsUser})
    }
   const userData = JSON.parse(localStorage.getItem("storage")!);

    if(!userData?.phone1 &&  !userData?.email) {
      primaryContactsLoginUser.push({ label: "None", value: "None" });
      this.setState({primaryMethodForLoginUser:"None",primaryContactsLoginUser})
    } else if(!userData?.phone1 ) {
      primaryContactsLoginUser= primaryContactsLoginUser.filter(x=>x?.value!== "Phone");
      this.setState({primaryContactsLoginUser, primaryMethodForLoginUser:"Email"})
    }
    else if( !userData?.email) {
      primaryContactsLoginUser= primaryContactsLoginUser.filter(x=>x?.value!== "Email");
      this.setState({primaryContactsLoginUser,primaryMethodForLoginUser:"Phone"})
    } 
    else {
      this.setState({primaryContactsLoginUser})
    }

 }

  resetPassword = () => {
    const { primaryMethodForSelectedUser,
      primaryMethodForLoginUser } = this.state;
      let request: any = {};
      request.username =this.props?.selectedUsers[0]?.username;
      request.primaryMethodForUser = primaryMethodForSelectedUser;
      request.assitanceUsername = this.props?.loginUserName;
      request.primaryMethodForAssitance = primaryMethodForLoginUser;
      this.setState({disableSave:true});
    UserManagement.SendPasswordToUserandAssitance(request).then(
      (response: any) => {
        if (response?.result.success == true) {
          toast.success("Reset password link sent successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            // containerId: "scheduleMain",
          });
          this.hideModal();
        } else {
          toast.success(response.result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
            // containerId: "scheduleMain",
          });
        }
      }
    ).finally(()=>  this.setState({disableSave:false}));
  }


  onFieldChange = (fieldName: string, value: string) => {
    this.setState({ [fieldName]: value });
  };

  hideModal = () => {
    this.props.hideModal();
  };
  render() {
    const { primaryMethodForSelectedUser,
      primaryMethodForLoginUser ,disableSave,
      primaryContactsUser,
      primaryContactsLoginUser} = this.state;

    return (
      <div>
        <Modal
          className="reset-password-user-modal"
          show={this.state.showRoleModal}
          onHide={() => this.hideModal()}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Reset Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Form.Group>
              <div className="column-rows d-flex">
                <div className="left-col mr-auto">
                  <Form.Label>
                    <div className="d-flex flex-column">
                      <div>Reset Password For:</div>
                      <EllipsisWithTooltip placement="bottom">
                        <div className="user-name">{this.props?.selectedUsers[0]?.username}</div>
                      </EllipsisWithTooltip>
                    </div>
                  </Form.Label>
                </div>
                <div className="right-col">
                  <Form.Label>Method of delivery:</Form.Label>
                  {primaryContactsUser?.length>0&&
                  <DropDownList
                    placeHolder={"Select Primary Contact"}
                    data={primaryContactsUser}
                    defaultValue={primaryMethodForSelectedUser}
                    isSearchRequired={false}
                    label={"label"}
                    value={"value"}
                    disabled={primaryMethodForSelectedUser==="None"}
                    onDropDownChange={(item) => {
                      if (!item) {
                        return;
                      }
                      this.onFieldChange(
                        "primaryMethodForSelectedUser",
                        item.value)
                    }}
                    selectedItem={[
                      ...[{ value: "", label: "Select Primary Contact" }],
                      ...primaryContactsUser,
                    ].find(
                      (r: any) => r?.value === primaryMethodForSelectedUser
                    )}
                  />
                    }
                </div>
              </div>
            </Form.Group>

            <Form.Group>
              <div className="column-rows d-flex">
                <div className="left-col mr-auto">
                  <Form.Label>
                    <div>Password Assist User:</div>
                    <EllipsisWithTooltip placement="bottom">
                      <div className="user-name">{this.props?.loginUserName}</div>
                    </EllipsisWithTooltip>
                  </Form.Label>
                </div>
                <div className="right-col">
                  <Form.Label>Method of delivery:</Form.Label>
                  {primaryContactsLoginUser?.length>0&&
                  <DropDownList
                    placeHolder={"Select Primary Contact"}
                    data={primaryContactsLoginUser}
                    defaultValue={primaryMethodForLoginUser}
                    isSearchRequired={false}
                    label={"label"}
                    value={"value"}
                    onDropDownChange={(item) => {
                      if (!item) {
                        return;
                      }
                      this.onFieldChange(
                        "primaryMethodForLoginUser",
                        item.value)
                    }}
                    selectedItem={[
                      ...[{ value: "", label: "Select Primary Contact" }],
                      ...primaryContactsLoginUser,
                    ].find(
                      (r: any) => r?.value === primaryMethodForLoginUser
                    )}
                  />
                    }
                </div>
              </div>
            </Form.Group>

          </Modal.Body>
          <Modal.Footer>
            <Button
          
              className="btn-ghost"
              onClick={this.hideModal}
              disabled={disableSave}
            >
              Cancel
            </Button>
            <Button
              disabled={(!primaryMethodForLoginUser || !primaryMethodForSelectedUser) || disableSave || 
              ( primaryMethodForSelectedUser === "None"&&
              primaryMethodForLoginUser === "None")
              }
              className=""
              onClick={this.resetPassword}
            >
              Send
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
