import { AnyIfEmpty } from "react-redux";

export class schConversions {
  public static convertHourToMInute = (hourValue: string) => {
    let minutesOfHour: any;
    if (hourValue !== "" && hourValue.includes(":")) {
      let tempSplitted = hourValue.split(":");
      minutesOfHour = Number(tempSplitted[0]) * 60 + Number(tempSplitted[1]);
    }
    return minutesOfHour;
  };

  public static convertMinuteToHour = (minuteValue: any) => {
    let hourOfMinutes: any;
    if (minuteValue !== null) {
      let hours = Number(minuteValue) / 60;
      let rhours = Math.floor(hours);
      let minutes = (hours - rhours) * 60;
      let rminutes: any = Math.round(minutes);
      if (rminutes.toString().length === 1) {
        rminutes = `0${rminutes}`;
      }
      hourOfMinutes = `${rhours}:${rminutes}`;
    }
    return hourOfMinutes;
  };

  public static tweleveToTwentyFourConvert = (value: any) => {
    let twentyFourHourTime: any;
    if (value !== "" && value.includes(":")) {
      const [time, modifier] = value.split(" ");
      let [hours, minutes] = time.split(":");
      if (hours === "12") {
        hours = "00";
      }
      if (modifier === "PM") {
        hours = parseInt(hours, 10) + 12;
      }
      if (hours.length === 1) {
        hours = `0${hours}`;
      }
      twentyFourHourTime = `${hours}:${minutes}`;
    }
    return twentyFourHourTime;
  };

  public static twentyFourToTwelveConvert = (value: any) => {
    let twelveHourTime: any;
    if (value !== "" && value.includes(":")) {
      const [hour, minutes] = value.split(":");
      let appendHour = hour % 12 || 12;
      let appendAmpm = hour < 12 || hour === 24 ? "AM" : "PM";
      let appendMinutes = minutes;
      twelveHourTime = `${appendHour}:${appendMinutes} ${appendAmpm}`;
    }
    return twelveHourTime;
  };

  public static numberToHHMM = (value: any) => {
    
    value = Number(value).toFixed(2);

    let valueArray: Array<number> = value.split(".");
    let hour: string | number = valueArray[0];
    let minute: string | number = (valueArray[1] / 100) * 60;
    minute = Math.round(minute);
    if (minute < 10) {
      minute = `0${minute}`;
    }

    return `${hour}:${minute}`;

    // let hour = Math.floor(value);
    // let decpart = value - hour;

    // let min = 1 / 60;
    // decpart = min * Math.round(decpart / min);

    // let minute = Math.floor(decpart * 60) + "";
    // if (minute.length < 2) {
    //   minute = "0" + minute;
    // }

    // return `${hour}:${minute}`;
  };

  public static hhmmToNumber = (value: any) => {
    var hoursMinutes = value.split(":");
    var hours = parseInt(hoursMinutes[0], 10);
    var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    return (hours + minutes / 60).toFixed(2);
  
  };

  public static GetUTCTime = (value: any) => {
    let dt: any = new Date();
   // if (JSON.parse(localStorage.storage).currentUtcTime !== "" && JSON.parse(localStorage.storage).currentUtcTime !== null && JSON.parse(localStorage.storage).currentUtcTime !== undefined) {
    //   dt = new Date(
    //     JSON.parse(localStorage.storage).currentUtcTime
    //   );
    // }
    dt.setDate(dt.getUTCDate());
    dt.setMonth(dt.getUTCMonth());
    dt.setFullYear(dt.getUTCFullYear());
    dt.setHours(dt.getUTCHours());
    dt.setMinutes(dt.getUTCMinutes());

    let dateDay: any = dt.getDate();
    let mnth: any = dt.getMonth() + 1;
    let nyear: any = dt.getFullYear();
    let nhrs: any = dt.getHours();
    let nminut: any = dt.getMinutes();
    let ndate: any =
      mnth + "/" + dateDay + "/" + nyear + " " + nhrs + ":" + nminut;
    let dtnew: any = new Date(ndate);
    dtnew.setHours(dtnew.getHours() + value);
    return dtnew;
  };

  public static getNextPayPeriodByEndDate = (EndDate) =>{
    // let tempDate = new Date(EndDate);
    let weekDayName:any = [];
    let dates:any = [];
    let days:any = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    for(let x=1;x<=7;x++){
      let tempDate = new Date(EndDate);
      let date = tempDate.getDate() + 1 ;
      let month = tempDate.getMonth() + 1;
      let year = tempDate.getFullYear();
      let newDate = `${month}/${date}/${year}`
      let d = new Date(newDate);
      let dayName = days[d.getDay()];
      let tdate = d.getDate().toString();
      if (tdate.toString().length === 1) {
        tdate = `0${tdate}`;
      }
      let tmonth = (d.getMonth() + 1).toString();
      if (tmonth.toString().length === 1) {
        tmonth = `0${tmonth}`;
      }
      let tyear = d.getFullYear().toString();
      let tnewDate = `${tmonth}/${tdate}/${tyear.slice(2)}`
      weekDayName.push(dayName)
      dates.push(tnewDate);
      EndDate = newDate;
    }
    

    return [dates,weekDayName]
  }

  public static replaceInvalidChar = (strStringValue: any) => {
    strStringValue = strStringValue.replace("&nbsp;", "");
    strStringValue = strStringValue.replace("&#160;", "");
    strStringValue = strStringValue.replace("&amp;", "");
    strStringValue = strStringValue.replace("&#39;", "");
    strStringValue = strStringValue.replace("'", "");
    strStringValue = strStringValue.replace("`", "");
    return strStringValue;
  };
}
