import * as React from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ModuleReports } from "./../../Common/Services/ModuleReports";

export class LaborEmployeeSelector extends React.Component<any, any> {
  private inputElement: any;
  constructor(props: any) {
    super(props);
    this.inputElement = React.createRef();
    this.state = {
      placeHolder: "Select",
      filterData: [],
      Employees: [],
      inputValue: "",
    };
  }
  getLaborEmployees = async (hotelIds: string = "", status: number) => {
    try {
      const result: string[] = await ModuleReports.GetLaborEmployees(hotelIds, status);
      if (result?.length > 0) {
        this.setState({ Employees: result, filterData: result });

        const { defaultValue } = this.props;
        if (result.includes(defaultValue)) {
          this.employeeSelectModal(defaultValue);
          this.props.onLaborEmployeeChange(defaultValue);
        } else {
          this.employeeSelectModal("Select");
          this.props.onLaborEmployeeChange("");
        }
      }
    } catch (error) {
      // Need to handle this
      console.log(error);
    }
  };

  componentDidMount = () => {
    if (this.props?.hotelIds && this.props?.status) {
      this.updateEmployeeDDL(this.props?.hotelIds, this.props?.status);
    }
  };


  updateEmployeeDDL(hotelIds: string, status: number) {
    if (!hotelIds) {
      this.setState({ Employees: [], filterData: [] });
      this.employeeSelectModal("Select");
      this.props.onLaborEmployeeChange("");
    }
    else {
      this.getLaborEmployees(hotelIds, status);

    }
  }



  componentDidUpdate = (prevProps) => {
    const { defaultValue } = this.props;
    if (prevProps?.defaultValue !== defaultValue && !defaultValue) {
      this.employeeSelectModal("Select");
    }

  }

  handleLaborEmployeeSearch = (e: { target: { value: string } }) => {
    const updatedList = this.state.Employees.filter((item) => {
      return (
        item?.toString().toLowerCase().indexOf(e.target.value.toLowerCase()) >
        -1
      );
    });
    this.setState({ filterData: updatedList, inputValue: e.target.value });
  }

  handleDropdownChange = (e: any) => {
    this.props.onLaborEmployeeChange(e);
    this.resetSerchBox();
  }

  resetSerchBox = () => {
    this.setState({ inputValue: "" });
    this.setState({ filterData: this.state.Employees });
  };
  employeeSelectModal = (eventKey: any) => {
    this.setState({ inputValue: "" });
    this.setState({ placeHolder: eventKey });
  };
  render() {


    const onFocusInput = (eventKey: any) => {
      setTimeout(() => {
        this.inputElement.current.focus();
      }, 100);
    };

    return (
      <Dropdown
        className="single-select"
        onSelect={this.employeeSelectModal}
        onClick={onFocusInput}
      >
        <Dropdown.Toggle id="Modal-LaborEmployees">
          {this.state.placeHolder === "Select Employees" && (
            <span className="placeholder">{this.state.placeHolder}</span>
          )}
          {this.state.placeHolder !== "Select Employees" && (
            <OverlayTrigger
              placement="bottom-start"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip id={"Employee" + this.state.placeHolder}>
                  {this.state.placeHolder}
                </Tooltip>
              }
            >
              <span
                style={{
                  width: " 95%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "inline-block",
                }}
              >
                {this.state.placeHolder}
              </span>
            </OverlayTrigger>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div className="search-sec">
            <input
              type="text"
              className="form-control"
              onChange={this.handleLaborEmployeeSearch}
              placeholder="Filter Employee"
              value={this.state.inputValue}
              ref={this.inputElement}
            />
          </div>
          <div className="list-area">
            {this.state.filterData.map((item: any, idx: any) => (
              <OverlayTrigger
                placement="bottom-start"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip id={"Employeedata" + idx}>{item}</Tooltip>
                }
                key={idx}
              >
                <Dropdown.Item
                  eventKey={item}
                  onClick={() => this.handleDropdownChange(item)}
                >
                  {item}
                </Dropdown.Item>
              </OverlayTrigger>
            ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}
