import * as React from "react";
import {
  Button,
  Container,
  Dropdown,
  Form,
  // Form,
  Modal,
  Tab,
  Tabs,
} from "react-bootstrap";
import { SingleSearchDropdownList } from "../../Common/Components/SingleSearchDropdownList";
import { UserManagement } from "./../../Common/Services/UserManagement";
import { IUserRole } from "../../Common/Contracts/IUserRole";
import _ from "lodash";
import { toast } from "react-toastify";
import { Utils } from "../../Common/Utilis";
export class ChangeRoleModal extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
 
    this.state = {
      role: 0,
      showRoleModal: this.props.showModal,
      userRoles: [],
      disableSave:true
    };
  }
  componentDidMount() {
    this.getUserRoleList();
  }
  getUserRoleList = () => {
    UserManagement.GetUserRoleList().then((response: IUserRole[]) => {
      const userRoles = 
        // ..._.sortBy(
          response.map((item) => ({
            id: item.roleID,
            name: item.roleName,
            isLowSecurity:item.isLowSecurity
          }));
         // "name"
        // ),
      

      this.setState({ userRoles });
    });
  };

  UpdateUserRole = () => {
    let UpdateUserRoles = [] as any;
    this.setState({disableSave:true});
    this.props.SelectedUsers.map((user) => {
      let usertoupdate: any = {};
      usertoupdate.Username = user.username;
      usertoupdate.RoleId = this.state.role;
      usertoupdate.TenantId = user.tenantId;
      UpdateUserRoles.push(usertoupdate);
    });
    UserManagement.UpdateUserRole(UpdateUserRoles).then((response: any) => {
      this.setState({disableSave:false});
      if (response?.message === 'Success') {
        if(this.props.SelectedUsers?.length > 1){
          if(response.result.result?.length >= 1){
            this.setState({showWarningMessage:true});
          }else{
            toast.success("User's role updated successfully", {
              position: toast.POSITION.BOTTOM_RIGHT,
             // containerId: "scheduleMain",
           });
           this.hideRoleModal();
           this.props.getUserList();
          }
        }else{
          if(response.result.result?.length == 1){
            Utils.toastError("Prior to making this change, please ensure that all entities are covered by a Password Assistance Role.", {
              position: toast.POSITION.BOTTOM_RIGHT,
             // containerId: "scheduleMain",
           });
          }else{
            toast.success("User role updated successfully", {
              position: toast.POSITION.BOTTOM_RIGHT,
             // containerId: "scheduleMain",
           });
           this.hideRoleModal();
           this.props.getUserList();
          }
          
         
        } 
        
        
      } else {
        toast.success(response.result?.result, {
           position: toast.POSITION.BOTTOM_RIGHT,
          // containerId: "scheduleMain",
        });
      }
    });
  };

  onRoleChange = (controlID: any, id: any) => {
    this.setState({ role: id,disableSave:(id != 0) ? false : true });
  };

  hideRoleModal = () => {
    this.props.hideRoleModal();
  };
  onHideAllModal = ()=>{
    this.props.hideRoleModal();
    this.props.getUserList();
    this.setState({showWarningMessage:false});
   }
  render() {
    const { userRoles, role } = this.state;
    
    return (
      <div>
        <Modal
          className="change-ehid-access-warning-modal"
          show={this.state.showWarningMessage}
          onHide={() => this.onHideAllModal()}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-section">
              <div className="body-section">
                <div className="description">
                  One or more users could not be updated as they fall into one or more of the below mentioned categories
                  <ul>
                    <li>Is the last user in the EHID as Password Assitance User</li>
                  </ul>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="reset-password-btn"
              onClick={(e) => this.onHideAllModal()}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          className="change-role-modal"
          show={this.state.showRoleModal}
          onHide={() => this.hideRoleModal()}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Change Role</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {userRoles?.length > 0 && (
              <Form.Group>
                <Form.Label>New Role</Form.Label>
                <SingleSearchDropdownList
                  itemList={userRoles}
                  handleSelectedItem={this.onRoleChange.bind(this, "ddlRole")}
                  defaultText={"Search"}
                  defaultName={
                    +role > 0
                      ? userRoles.find((item) => +item?.id === +role)?.name
                      : "Select a Role"
                  }
                  controlID="ddlRole"
                />
              </Form.Group>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={this.state.disableSave}
              className="reset-password-btn"
              onClick={(e) => this.UpdateUserRole()}
            >
              Change Role
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
