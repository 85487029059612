import axios, { CancelTokenSource } from "axios";
import Instense from "./Axios-config";

interface ICardType {
  cardTypeId: number;
  cardName: string;
  cardDisplayName: string | null;
  cardWidth: number;
  cardHeight: number;
  tenantId: number;
}

interface IParamComparison {
  comparisonId: number;
  cardId: number;
  comparisonName: string;
  comparisonCode: string | null;
  codeValue: string | null;
  calculationSp: string | null;
  tenantId: number;
}

interface IDashboardParameter {
  id: number;
  paramName: string;
  paramDisplayName: string;
  paramDescrp: string | null;
  paramCardTypeMapping: ICardType[];
  paramComparisonMapping: IParamComparison[];
}

export class PortfolioDashboard {
  private static ajaxRequestDashboardParameters: CancelTokenSource | null =
    null;

  public static GetDashboardParameters = async (): Promise<
    IDashboardParameter[]
  > => {
    const url = `/Dashboard/GetDashboardParameters`;

    if (PortfolioDashboard.ajaxRequestDashboardParameters) {
      PortfolioDashboard.ajaxRequestDashboardParameters.cancel(
        "Cancel previous request"
      );
    }

    PortfolioDashboard.ajaxRequestDashboardParameters =
      axios.CancelToken.source();

    try {
      const response = await Instense.get(url, {
        cancelToken: PortfolioDashboard.ajaxRequestDashboardParameters.token,
      });

      return response.data as IDashboardParameter[];
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        console.error("Error fetching dashboard parameters", error);
      }
      return [];
    }
  };

  public static SaveDashboard = async (requestData) => {
    try {
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : storage.tenantID;
      requestData.tenantID = tenantID;
      const response = await Instense.post(
        "/Dashboard/SaveDashboardCard",
        requestData
      );

      return response.data;
    } catch (error) {
      console.error("Error saving dashboard:", error);
      throw error;
    }
  };

  public static UpdateWidgetPositions = async (requestData) => {
    try {
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let TenantId = storage === null ? 0 : storage.tenantID;
      requestData.TenantId = TenantId;
      const response = await Instense.post(
        "/Dashboard/UpdateWidgetPositions",
        requestData
      );

      return response.data;
    } catch (error) {
      console.error("Error saving dashboard:", error);
      throw error;
    }
  };

  public static GetDashboardWidgetMapping = async (dashboardId) => {
    try {
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : storage.tenantID;
     
      const response = await Instense.get(
        "/Dashboard/GetDashboardWidgetMapping",
        {
          params: {
            DashboardId: dashboardId,
            tenantID:tenantID
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error saving dashboard:", error);
      throw error;
    }
  };

  public static GetDashboardWidgetData = async (request) => {
    try {
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : storage.tenantID;
  
      const response = await Instense.post("/Dashboard/GetDashboardWidgetData", {
          ...request,
          tenantID: tenantID, // Include tenantID if applicable
      });
  
      return response.data;
    } catch (error) {
      console.error("Error fetching dashboard widget data:", error);
      throw error;
    }
  };
  
}
