import React from "react";
import { Line } from "react-chartjs-2";
import { isValidChartData } from "../MetricChart";
import { getCommonOptions } from "./cardConfig";
import CustomLegends from "./CustomLegends";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";

const LineChartCard = ({ data, chartTitle, formatType, metric,isDummyData }) => {
  if (!data || !isValidChartData(data)) {
    return <></>;
  }

  const datasets = data.datasets?.map((x: any) => x);
  const commonOptions: any = getCommonOptions(formatType,metric,isDummyData);

  const lineOptions: any = {
    ...commonOptions,
    cubicInterpolationMode: "monotone",
    pointRadius: 0,
    plugins: {
      ...commonOptions.plugins,
      tooltip: {
        mode: "nearest",
        intersect: false,
        ...commonOptions.plugins.tooltip,
      },
    },
    scales: {

      x: {
        ticks: {
          autoSkip: true,
          maxTicksLimit: 12, // Maximum number of ticks to display
        },
        grid: { color: "#F4F6F8" },
        title: {
          display: true,
          text: "Months",
          font: { size: 12 },
          color: "#84888C",
        },
      },
      y: {
        ...commonOptions.scales.y
      },
    },
  };
  return (
    <div
      className="line-chart-metric"
      style={{ width: "100%", margin: "0 auto" }}
    >
      <h2 className="chart-heading" style={{ whiteSpace: "pre-wrap" }}>
      <EllipsisWithTooltip placement="top">{chartTitle}</EllipsisWithTooltip>
      </h2>
      <Line data={data} options={lineOptions} />
      <CustomLegends isLine={true} datasets={datasets} />
    </div>
  );
};

export default LineChartCard;
