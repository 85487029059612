import * as React from "react";
import { Dropdown } from "react-bootstrap";
// import { read } from "fs";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";

export const DropDownListWithDescription: React.FC<any> = (props: any) => {
  const inputElement: any = React.createRef();
  const toggleRef: any = React.createRef();

  const [placeHolder, setPlaceholder] = React.useState(props?.placeHolder);
  const [selectedItem, setSelectedItem] = React.useState(props?.selectedItem || {});
  const [data, setData] = React.useState(props?.data || []);
  const [filterData, setFilterData] = React.useState(props?.data || []);
  const [disabled, setdisabled] = React.useState(props?.disabled);
  const [inputValue, setInputValue] = React.useState("");

  React.useEffect(() => {

    const { defaultValue, value, label, shouldToggle } = props;
    if (!selectedItem && props?.selectedItem) {
      handleDropdownChange(props?.selectedItem);
    }
    else if (selectedItem) {
      if (
        defaultValue &&
        selectedItem[value] !== defaultValue &&
        data.length > 0
      ) {
        const selectedItem = data?.find((r) => r[value] === defaultValue);
        handleDropdownChange(selectedItem);
      }
    } else {
      if (data?.length > 0 && defaultValue) {
        const selectedItem = data?.find((r) => r[value] === defaultValue);
        handleDropdownChange(selectedItem);
      }
    }


  });


  const handleDropdownSearch = (e: { target: { value: string } }) => {
    const { label } = props;
    const updatedList = data.filter(
      (item) =>
        item[label]
          ?.toString()
          .toLowerCase()
          .indexOf(e.target.value.toLowerCase()) > -1
    );
    setFilterData(updatedList);
    setInputValue(e.target.value);
  };

  const hoverOutEllipsis = () => {
    $(".tooltip").removeClass("show");
  };
  const handleDropdownChange = (item: any) => {
    setSelectedItem(item);

    if (typeof props?.onDropDownChange === "function") {
      props?.onDropDownChange(item);
    }
    resetSerchBox();
  };

  const resetSerchBox = () => {
    setFilterData(data);
    setInputValue("");
  };

  const ddlSelectModal = (eventKey: any) => {
    setPlaceholder(eventKey);
  };

  const onTabKeyDown = (e) => {
    e.preventDefault();
    if (toggleRef.current.ariaExpanded === "false" && e?.keyCode === 9) {
      toggleRef.current.click();
      setTimeout(() => {
        $(`#ddlSpecailSearchText`)?.focus();
      }, 100);
    }
  };

  const onFocusInput = (eventKey: any) => {
    resetSerchBox();
    setTimeout(() => {
      inputElement?.current?.focus();
    }, 100);
  };

  const { label, value, showBlueBg, tabIndex } = props;

  return (
    <Dropdown
      tabIndex={tabIndex}
      className="single-select"
      onSelect={ddlSelectModal}
      onKeyUp={(e) => onTabKeyDown(e)}
      onClick={onFocusInput} >

      <Dropdown.Toggle ref={toggleRef} id={`Modal-ddl${tabIndex}`} className={showBlueBg ? "selected-blue-bg" : ""} disabled={disabled}>
        <EllipsisWithTooltip placement="top">
          {selectedItem &&
            <span
              style={{
                width: "95%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "block",
              }}
            >
              {selectedItem[label]}
            </span>
          }
        </EllipsisWithTooltip>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {props?.isSearchRequired &&
          <div className="search-sec">
            <input
              id="ddlSpecailSearchText"
              type="text"
              className="form-control"
              onChange={handleDropdownSearch}
              placeholder={placeHolder}
              autoComplete={"ddlSpecailSearchText" + placeHolder}
              value={inputValue}
              ref={inputElement}
            />
          </div>
        }
        {filterData?.length > 0 && (
          <div className="list-area">
            {filterData.map((item: any, idx: any) => (

              <Dropdown.Item onClick={() => handleDropdownChange(item)}
                className={`${item.disabled ? "disabled" : selectedItem[label] === item.label ? "active" : ""}`}
                disabled={item.disabled} onWheel={() => hoverOutEllipsis()}
                >
                <div className="d-flex">
                  <div className="mr-auto d-flex flex-column">
                    <span className="label">{item[label]}</span>
                    <span className="description">{item.disabled ? item.description : ""}</span>
                  </div>
                  {selectedItem[label] ===
                    item.label && (
                      <span className="">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
                            fill="#2CAF92"
                          />
                        </svg>
                      </span>
                    )}
                </div>
              </Dropdown.Item>
            ))}
          </div>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};
DropDownListWithDescription.defaultProps = {
  isSearchRequired: true,
  showBlueBg: false,
  shouldToggle: false
};