import Instense from "./Axios-config";
import {
    IWorkOrderRequestDto,IWorkOrderUsers,IWorkOrderSummaryRequestDto,IWorkOrderDeleteRequestDto,
    IWorkOrderCommentRequestDto,IWorkOrderCommentStatusRequestDto,IWorkOrderStatus,IWorkOrderByLocation
    ,IWorkOrderPermissionRequestDto,
    IWorkOrderByAsset
  } from "../Contracts/IWorkOrder";

  export class WorkOrder {

    public static GetFcUserDetailsList = async (
        request: IWorkOrderRequestDto
      ): Promise<IWorkOrderUsers | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.tenantID = tenantID;
        const url = `/WorkOrder/Get_FcUserDetailsList`;
        return Instense.get(url, { params: request }).then((response) => {
          let result = response.data.result as IWorkOrderUsers;
          return result;
        });
      };


      public static getWorkOrderSummaryDetails = async (
        request: IWorkOrderSummaryRequestDto
      ): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.tenantID = tenantID;
        const url = `/WorkOrder/Get_WorkOrderSummary`;
        return Instense.get(url, { params: request }).then((response) => {
          let result = response.data.result as IWorkOrderUsers;
          return result;
        });
      };

      public static SaveWorkOrder = async (
        request: any
      ): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : storage.tenantID as any;
        request.tenantID = tenantID;
          const url = `/WorkOrder/SaveWorkOrder`;
          return Instense.post(url, request).then((response) => {
            let result = response.data.result as any;
            return result;
          });
        
      };
      public static DeleteWorkOrderSummaryDetails = async (
        request: any
      ): Promise<any | null> => {           
        const url = `/WorkOrder/DeleteWorkOrderSummary`;
        const response = await Instense.post(url, request);
        let result = response.data as any;
        return result;
      }; 

      public static GetFacilityRooms = async (hid)=> {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
    
        const url = `/WorkOrder/GetFacilityRoom?Hid=${hid}&TenantId=${tenantID}`;
        return Instense.get(url).then((response) => {
          let result = response?.data?.result as any[];
          return result;
        });
      };

      public static UpdateeWorkOrderStatus = async (
        request: any
      ): Promise<any | null> => {           
        const url = `/WorkOrder/UpdateeWorkOrderStatus`;
        const response = await Instense.post(url, request);
        let result = response.data as any;
        return result;
      }; 


      public static SaveWorkOrderFile = async (
        request: any, files: any[]
      ): Promise<any | null> => {
       
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : storage.tenantID as any;
        request.tenantID = tenantID;
    
    
    
    
        const url = `/WorkOrder/SaveWorkOrderFile`;
        const formData: FormData = new FormData();
        files.forEach(element => {
          formData.append('file', element);
        });
    
        formData.append('formField', JSON.stringify(request));
        return Instense.post(url, formData).then((response) => {
          let result = response.data.result as any;       
          return result;
        });
    
    
    
      };


      public static GetUploadFileList = async (request: any): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        // let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
        // debugger
    
        request.tenantID = tenantID;
        const url = `/WorkOrder/GetFacilitiesUploadfile`;
        return Instense.get(url, { params: request }).then((response) => {
          let result = response.data.result as any;
          return result;
        });
      };

      public static GetFacilityAsset = async (hid)=> {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
    
        const url = `/WorkOrder/GetAssetName?Hid=${hid}&TenantId=${tenantID}`;
        return Instense.get(url).then((response) => {
          let result = response?.data?.result as any[];
          return result;
        });
      };

      public static DownloadWorkUploadFile = async (
        request: any
      ): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
    
        request.tenantId = tenantID;
    
        const url = `/WorkOrder/DownloadWorkUploadFile`;
        return Instense.post(url, request, { responseType: "blob" }).then(
          (response: any) => {
    
            let csvURL = window.URL.createObjectURL(response.data);
            let tempLink = document.createElement("a");
            tempLink.href = csvURL;
            tempLink.setAttribute("download", request.filename);
            tempLink.click();
          }
        );
      };

      public static DeleteWorkOrderFiles = async (request: any): Promise<any[] | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        // let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
        // debugger
    
        request.tenantID = tenantID;
        const url = `/WorkOrder/DeleteWorkOrderFiles`;
        return Instense.post(url, request).then((response) => {
          let result = response.data.result as any;
          return result;
        });
    
      };


      public static UsernameDetails = async (
        request: IWorkOrderRequestDto
      ): Promise<IWorkOrderUsers | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.tenantID = tenantID;
        const url = `/WorkOrder/UsernameDetails`;
        return Instense.get(url, { params: request }).then((response) => {
          let result = response.data.result as IWorkOrderUsers;
          return result;
        });
      };

      public static SaveWorkOrderComment = async (
        request: any
      ): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : storage.tenantID as any;
        request.tenantID = tenantID;
          const url = `/WorkOrder/SaveWorkOrderComment`;
          return Instense.post(url, request).then((response) => {
            let result = response.data.result as any;
            return result;
          });
        
      };

      public static getCommentWorkOrder = async (
        request: IWorkOrderCommentRequestDto
      ): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.tenantID = tenantID;
        const url = `/WorkOrder/GetWorkOrderAssignedComment`;
        return Instense.get(url, { params: request }).then((response) => {
          let result = response.data.result as IWorkOrderUsers;
          //debugger;
          return result;
        });
      };

    

      public static GetWorkOrderPermission = async (
        request: IWorkOrderPermissionRequestDto
      ): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.tenantID = tenantID;
        const url = `/WorkOrder/GetWorkOrderPermission`;
        return Instense.get(url, { params: request }).then((response) => {
          let result = response.data.result as IWorkOrderUsers;
          return result;
        });
      };

      public static WorkOrderCommentStatus = async (
        request: IWorkOrderCommentStatusRequestDto
      ): Promise<IWorkOrderStatus | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.tenantid = tenantID;
        const url = `/WorkOrder/GetWorkOrdeStatus`;
      //   return Instense.get(url, { params: request }).then((response) => {
      //     let result = response.data.result as IWorkOrderStatus;
      //   });
      // };
      return Instense.get(url, { params: request }).then((response) => {
        let result = response.data.result as IWorkOrderStatus;
        //debugger;
        return result;
      });
    };


    

 
    public static GetWorkOrderByAsset =  async (
      request: IWorkOrderByAsset
    ): Promise<any | null> => {
      
      const url = `/Assets/GetWorkOrderByAssetId`;
      return Instense.get(url, { params: request }).then((response) => {
        let result = response.data.result as any;
        return result;
      });

    }
    
    public static GetWorkOrder =  async (
      request: IWorkOrderByLocation
    ): Promise<any | null> => {
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : (storage.tenantID as any);
      request.tenantID = tenantID;
      const url = `/WorkOrder/Get_WorkOrderByLocation`;
      return Instense.get(url, { params: request }).then((response) => {
        let result = response.data.result as any;
        return result;
      });
    };

  }
  