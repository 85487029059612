import * as React from "react";
import {
  // Container,
  Form,
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Button,
  Spinner,
  Modal,
  Table,

} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import cellEditFactory from "react-bootstrap-table2-editor";
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { resolve, reject } from "q";
import PageLoader from "../../Common/Components/PageLoader";
import {
  ILaborDepartmentDto,
  ILaborPositionMappingDto,
} from "../../Common/Contracts/ILaborDeptPosition";
import { LaborDeptPosition as laborDeptPosition } from "../../Common/Services/LaborDeptPosition";
import { Hotel } from "../../Common/Services/Hotel";
import { Chart as chart } from "../../Common/Services/Chart";
import TableAutocomplete from "../../Common/Components/TableAutocomplete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ConfirmationModal } from "../../Common/Components/ConfirmationModal";
import { InfoTooltip } from "../../Common/Components/InfoTooltip";
import OutsideClickHandler from "react-outside-click-handler";
import TableSearch from "../../Common/Components/TableSearch";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { isTemplateMiddle, setSyntheticLeadingComments } from "typescript";
import { Utils } from "../../Common/Utilis";
import { ReactPageLoader } from "../../Common/Components/ReactPageLoader";
import "./LaborPosition.scss";
import { faLessThanEqual } from "@fortawesome/free-solid-svg-icons";
//import { setTimeout } from "timers";

let timeId: any = 0;
//let isActiveButton=false;
export class LaborPosition extends React.Component<any, any> {
  private input: any;
  private child: any;
  constructor(props: any) {
    super(props);
    this.input = React.createRef();
    this.child = React.createRef();
    this.state = {
      departmentList: [],
      chartList: [],
      tableData: [],
      actualPositionData: [],
      SearchData: [],
      actualtableData: [],
      columns: [],
      tablekey: "text",
      isMountDone: false,
      loaderTrue: false,
      selectedChart: "",
      isBreakTime: false,
      isNewPosition: false,
      currentIndex: 0,
      newIndex: 0,
      newRow: {
        id: 0,
        positionID: 0,
        positionName: "",
        departmentID: 0,
        departmentName: "",
        coa: "",
        isBreakTime: false,
        newPositionName: "",
        missPunchOutAlert: false,
        autoTrueUpBreak: false,
      },
      showDeactivatedPos: false,
      isBudgetsExits: false,
      isDeactiveRow: {},
      isStatus: "",
      isActiveButton: false,
      isLoader: false,
      isAutoScheduleBreakLoader: false,
      isShowPostionMapping: false,
      isHidePostionMapping: false,
      tablePayrollMappingData: [],
      parollMappingRow: {},
      tablePostionWiseIntegrationType: [],
    };
    this.isBreakTime = this.isBreakTime.bind(this);
    this.handleNewPosition = this.handleNewPosition.bind(this);
    this.changeNewPosition = this.changeNewPosition.bind(this);
    this.onBlurNewPosition = this.onBlurNewPosition.bind(this);
    this.handleRemoveNewRow = this.handleRemoveNewRow.bind(this);
    this.FocusTextbox = this.FocusTextbox.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.rebindTable = this.rebindTable.bind(this);
  }

  onKeyPress(event) {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  }

  componentDidMount() {
    debugger;
    this.getlaborPositionDept();
    this.getChart();
    this.getlaborPositionDeptMapping();
    this.setState({
      tablePayrollMappingData: this.props.parentState.tblPayrollIntegrationType
    }, () => {
      this.getExportPayrollType();
    })
    this.getPostionWiseIntegrationType();

  }

  rebindTable() {
    this.setState({ showDeactivatedPos: false }, () => {
      this.props.togglePosition(false);
    });
    if (this.child.current !== null) {
      if (
        this.child.current.CleanSearch !== null ||
        typeof this.child.current.CleanSearch === "function"
      ) {
        this.child.current.CleanSearch();
      }
    }
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.actualtableData))
    );
    this.setState({ tableData: [], isMountDone: false }, () => {
      this.setState({ tableData: data, isMountDone: true });
    });
    this.getlaborPositionDept();
  }

  FocusTextbox() {
    Utils.toastError("Mandatory details of Position are missing.", {
      // // position: toast.POSITION.BOTTOM_RIGHT,
      // // containerId: "LaborPosition",
    });
  }

  //For remove new unsave row from table
  handleNewPosition = () => {
    //
    if (!this.state.isNewPosition) {
      this.getExportPayrollType();
      let newtableData: any = [];
      let datalen = [...this.state.actualtableData].length;
      newtableData = Object.assign([], this.state.actualtableData);
      let newRow = {} as any;
      newRow.id = 0;
      newRow.positionID = 0;
      newRow.positionName = "";
      newRow.departmentID = 0;
      newRow.departmentName = "";
      newRow.coa = "";
      newRow.isBreakTime = false;
      newRow.earlyPunch = false;
      newtableData.push(newRow);
      this.setState(
        {
          tableData: [],
          isNewPosition: true,
          newIndex: datalen,
          isMountDone: false,
        },
        () => {
          this.setState(
            {
              tableData: newtableData,
              isNewPosition: true,
              newIndex: datalen,
              isMountDone: true,
            },
            () => {
              this.input.current.click();
              this.props.handleNewPosition(false);
            }
          );
        }
      );
    }

    // else {
    //
    //   if (this.state.newPositionName.length === 0) {
    //     setTimeout(() => {
    //       this.input.current.focus();
    //     }, 100);
    //   }
    // }
  };

  PositionCleanSearch = () => {
    this.child.current.CleanSearch();
    setTimeout(() => {
      this.handleNewPosition();
    }, 200);
  };

  handleRemoveNewRow = () => {

    let newtableData: any = [];
    newtableData = Object.assign([], this.state.tableData);
    newtableData.splice(-1, 1);
    this.setState({ tableData: [], isNewPosition: false }, () => {
      this.setState({ tableData: newtableData, isNewPosition: false });
    });
    this.props.handleNewPosition(true);
    this.props.togglePosition(false);
  };

  isBreakTime = (e: any, row: any, rowIndex: number) => {
    if (this.props.ManagePosition) {
      row.isBreakTime = e.target.checked;
      row.IsChangedAutoScheduleBreak = true;
      this.setState({ isMountDone: true, isAutoScheduleBreakLoader: true });
      if (row.departmentID > 0 && row.positionID > 0) {
        this.handleSavePosition(row);
      } else {
        this.setState({ isAutoScheduleBreakLoader: false })
      }
    }
  };
  TrueUpChange = (e: any, row: any, rowIndex: number) => {
    if (this.props.ManagePosition) {
      row.autoTrueUpBreak = e.target.checked ? "1" : "0";
      this.setState({ isMountDone: true });
      if (row.departmentID > 0 && row.positionID > 0) {
        this.handleSavePosition(row);
      }
    }
  };
  isBreakTimeheaderFormatter = (column, colIndex) => {
    return (
      <div className={!this.props.ManagePosition ? "allignRight" : ""}>
        {column.text}
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id={"tooltip-Assign"}>
              Auto break is enabled/disabled within Enterprise Settings
            </Tooltip>
          }
        >
          <span className="toolTip-header">
            <InfoTooltip />
          </span>
        </OverlayTrigger>
      </div>
    );
  };
  isAutoTrueUpheaderFormatter = (column, colIndex) => {
    return (
      <div className={!this.props.ManagePosition ? "allignRight" : ""}>
        <div className="auto-trueup-breaks"><EllipsisWithTooltip>{column.text}</EllipsisWithTooltip></div>
        {/* <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id={"tooltip-Assign"}>
              Auto break is enabled/disabled within Enterprise Settings
            </Tooltip>
          }
        >
          <span className="toolTip-header">
            <InfoTooltip />
          </span>
        </OverlayTrigger> */}
      </div>
    );
  };

  isMissPunchOutheaderFormatter = (column, colIndex) => {
    return (
      <div className="">
        <div className="missed-punchout"><EllipsisWithTooltip>{column.text}</EllipsisWithTooltip></div>
      </div>
    );
  };

  isBreakTimeFormatter = (cell, row, rowIndex) => {
    return (
      <div>
        <Form.Check
          id={rowIndex + 1 + "schBreak"}
          key={rowIndex + 1 + "schBreak"}
          type="switch"
          disabled={this.state.showDeactivatedPos || !this.props.ManagePosition}
          label=""
          checked={row.isBreakTime}
          onChange={(e: any) => this.isBreakTime(e, row, rowIndex)}
        />
      </div>
    );
  };

  isAutoTrueFormatter = (cell, row, rowIndex) => {
    return (
      <div>
        <Form.Check
          id={rowIndex + 1 + "autoTrueUp"}
          key={rowIndex + 1 + "autoTrueUp"}
          type="switch"
          disabled={this.state.showDeactivatedPos || !this.props.ManagePosition}
          label=""
          checked={row.autoTrueUpBreak === "1" ? true : false}
          onChange={(e: any) => this.TrueUpChange(e, row, rowIndex)}
        />
      </div>
    );
  };
  missPunchOutFormatter = (cell, row, rowIndex) => {
    return (
      <div>
        <Form.Check
          id={rowIndex + 1 + "missPunchOut"}
          key={rowIndex + 1 + "missPunchOut"}
          type="switch"
          label=""
          disabled={this.state.showDeactivatedPos}
          checked={row.missPunchOutAlert}
          onChange={(e: any) => this.onMissPunchOutChange(e, row, rowIndex)}
        />
      </div>
    );
  };
  onMissPunchOutChange = (e: any, row: any, rowIndex: number) => {
    row.missPunchOutAlert = e.target.checked;
    this.setState({ isMountDone: true });
    if (row.departmentID > 0 && row.positionID > 0) {
      this.handleSavePosition(row);
    }
  };



  getlaborPositionDept = () => {
    this.setState({ loaderTrue: true });
    laborDeptPosition
      .getlaborDepartmentList()
      .then(async (result: ILaborDepartmentDto[] | null) => {
        this.setState({ loaderTrue: false });
        if (result != null && result.length > 0) {
          let departments: any[] = [];
          result.forEach((element) => {
            let data = {} as any;
            data.value = element.departmentID;
            data.label = element.departmentName;
            departments.push(data);
          });

          await this.setState({ departmentList: departments });
        }
        resolve();
      })
      .catch((error) => {
        reject();
        this.setState({ loaderTrue: false });
      });
  };

  getlaborPositionDeptMapping = () => {
    this.setState({ loaderTrue: true });
    laborDeptPosition
      .getlaborPositionDeptMapping()
      .then(async (result: ILaborPositionMappingDto[] | null) => {
        if (result != null && result.length > 0) {
          //
          let showTrueUp = result[0].isAutoTrueUpBreak;
          this.setState({ actualPositionData: result, showTrueUpColumn: showTrueUp });
          result = result.filter(
            (items) => items.status.toString().toLowerCase() === "active"
          );
          this.setState({
            actualtableData: result,
            tableData: result,
            SearchData: result,
          });
          this.setState({ isMountDone: true });
        }
        setTimeout(() => {
          this.setState({ loaderTrue: false, isLoader: false });
        }, 100);

        resolve();
      })
      .catch((error) => {
        reject();
        this.setState({ loaderTrue: false });
      });
  };

  getChart = () => {
    chart
      .getChartDetails("PositionChart")
      .then(async (result: any | null) => {
        if (result != null) {
          let charts: any[] = [];
          result.forEach((element) => {
            let data = {} as any;
            data.value = element.chartNo;
            data.label = element.description;
            charts.push(data);
          });
          await this.setState({ chartList: charts });
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  };

  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      this.setState({ currentIndex: rowIndex });
    },
    onBlur: (e: any, row: any, rowIndex: any) => { },
  };
  cellEdit = cellEditFactory({

    mode: "click",
    blurToSave: true,
    afterSaveCell: (oldValue: any, newValue: any, row: any, column: any) => {
      debugger;
      if (oldValue !== newValue) {
        if (row.positionName.trim().length === 0) {
          Utils.toastError(
            "Please enter the Position Name, and has been truncated.",
            {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "LaborPosition",
            }
          );
        } //else if (row.positionName.length > 100) {
        else if (newValue.length > 100) {
          Utils.toastError("Position length should be maximum 100 characters.", {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "LaborPosition",
          });
          return;
        } else {
          if (
            this.state.isNewPosition &&
            this.state.newIndex !== this.state.currentIndex
          ) {
            Utils.toastError("Mandatory details for the Position is missing.", {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "LaborPosition",
            });
          } else {
            if (
              row.positionName !== "" &&
              row.departmentName !== "" &&
              row.coa !== ""
            ) {
              let isDept;
              let isCoa;
              isDept = this.state.departmentList.filter(
                (item) =>
                  item.label.toLowerCase() === row.departmentName.toLowerCase()
              );
              isCoa = this.state.chartList.filter(
                (item) => item.label.toLowerCase() === row.coa.toLowerCase()
              );
              if (isDept !== null && isCoa !== null) {
                let columnObject: any = [];
                columnObject = Object.assign([], column);
                let colName = columnObject.dataField;
                if (colName === "departmentName") {
                  this.setState({ deptOldValue: [colName, oldValue] }, () => {
                    row[colName] = newValue;
                    row.IsChangedDepartment = true;
                    this.handleSavePosition(row);
                  })
                } else {
                  if (row.positionID > 0) {
                    row[colName] = newValue;
                    this.handleSavePosition(row);
                  } else {

                    if (this.props.parentState.tblPayrollIntegrationType.length > 0) {
                      let tablePayrollMappingData = [...this.state.tablePayrollMappingData];
                      tablePayrollMappingData.map((d, index) => {
                        d.positionsMapValidation = false;
                        d.payrollPosition = "";
                      });

                      this.setState({
                        isShowPostionMapping: true, parollMappingRow: row,
                        tablePayrollMappingData: tablePayrollMappingData
                      });
                    } else {
                      row[colName] = newValue;
                      this.handleSavePosition(row);
                    }


                  }

                }
              }
            }
          }
        }
      }
    },
  });

  handleSavePosition = (row: any, type: any = "") => {
    //debugger;
    this.setState({ loaderTrue: true, isLoader: true });
    let DeptID, chartNo;
    let saveRequest: any = {};
    DeptID = this.state.departmentList.filter(
      (item) => item.label.toLowerCase() === row.departmentName.toLowerCase()
    )[0].value;
    if (
      this.state.chartList.filter(
        (item) => item.label.toLowerCase() === row.coa.toLowerCase()
      ).length > 0
    ) {
      chartNo = this.state.chartList.filter(
        (item) => item.label.toLowerCase() === row.coa.toLowerCase()
      )[0].value;
      saveRequest.id = row.id;
      saveRequest.positionID = row.positionID;
      saveRequest.positionName = row.positionName;
      saveRequest.departmentID = DeptID;
      saveRequest.coa = chartNo;
      saveRequest.isBreakTime = row.isBreakTime;
      saveRequest.autoTrueUpBreak = row.autoTrueUpBreak;
      saveRequest.missPunchOutAlert = row.missPunchOutAlert;
      saveRequest.IsChangedDepartment = row.IsChangedDepartment ? row.IsChangedDepartment : false;
      saveRequest.IsChangedAutoScheduleBreak = row.IsChangedAutoScheduleBreak ? row.IsChangedAutoScheduleBreak : false;

      //saveRequest.EarlyPunch = row.earlyPunch;
      laborDeptPosition
        .savePositionDeptMapping(saveRequest)
        .then(async (result: any | null) => {
          if (result != null) {
            //debugger;
            if (result.status === "Success") {
              if (type === "postionMappingClick") {
                let postionid = result.laborPosition.positionID;
                this.handleSaveParollSetting("handleSavePosition", postionid);
              }


              toast.success(result.message, {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "LaborPosition",
              });
              // this.setState({isLoader : false});
              // console.log("isLoader",this.state.isLoader);

              this.setState({ isNewPosition: false, isLoader: false, isAutoScheduleBreakLoader: false, isShowPostionMapping: false }, () => {
                this.getlaborPositionDeptMapping();
                this.props.handleNewPosition(true);
              });

              if (this.child.current !== null) {
                this.child.current.CleanSearch();
              }
            } else {
              Utils.toastError(result.message, {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "LaborPosition",
              });

              this.state.deptOldValue && (row[this.state.deptOldValue[0]] = this.state.deptOldValue[1]);
              this.setState({ loaderTrue: false, isLoader: false, isAutoScheduleBreakLoader: false });
            }
          } else {
            Utils.toastError("Internal server error.", {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "LaborPosition",
            });
            this.setState({ loaderTrue: false, isLoader: false, isAutoScheduleBreakLoader: false });
          }

          resolve();
        })
        .catch((error) => {
          reject();
          this.setState({ loaderTrue: false, isAutoScheduleBreakLoader: false });
        });
    } else {
      Utils.toastError("Invalid COA selected.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "LaborPosition",
      });
    }
  };

  ishandleDeletePosition(row: any, mode: any) {
    if (mode) {
      this.handleDeletePosition(row);
    }
  }
  handleDeletePosition = (row: any) => {
    this.setState({ loaderTrue: true });
    let deleteRequest: any = {};
    deleteRequest.positionID = row.positionID;
    laborDeptPosition
      .DeletePositionDeptMapping(deleteRequest)
      .then(async (result: any | null) => {
        if (result != null) {
          if (result.status === "Success") {
            if (
              result.message.toString().toLowerCase() ===
              "position deleted successfully"
            ) {
              let tableDataNew = this.state.actualPositionData;
              const index = tableDataNew.findIndex(
                (item) =>
                  item.positionID === row.positionID &&
                  item.departmentID === row.departmentID &&
                  item.tenantID === row.tenantID
              );
              if (index > -1) {
                tableDataNew.splice(index, 1);
              }
              if (this.state.showDeactivatedPos) {
                this.setState({
                  tableData: tableDataNew.filter(
                    (items) =>
                      items.status.toString().toLowerCase() === "inactive"
                  ),
                });
              } else {
                this.setState({
                  tableData: tableDataNew.filter(
                    (items) =>
                      items.status.toString().toLowerCase() === "active"
                  ),
                });
              }
            }

            // this.getlaborPositionDeptMapping();
            // this.setState({ showDeactivatedPos: false, loaderTrue: false ,isNewPosition: false }, () => {
            //   this.props.togglePosition(false);
            //  // this.getlaborDepartment();
            // });
            toast.success(result.message, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "LaborPosition",
            });
            this.setState({ loaderTrue: false, isNewPosition: false });
          } else {
            Utils.toastError(result.message, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "LaborPosition",
            });
          }
        } else {
          Utils.toastError("Internal server error.", {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "LaborPosition",
          });
        }
        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error) => {
        reject();
        this.setState({ loaderTrue: false });
      });
  };

  changeNewPosition = (e: any) => {

    if (
      this.state.isNewPosition &&
      this.state.newIndex !== this.state.currentIndex
    ) {
    } else {
      this.setState({ newPositionName: e.target.value });
    }
  };

  onOutsideNewPosition = (e: any, newPosition, row: any) => {
    //debugger;
    let newtableData: any = [];
    newtableData = Object.assign([], this.state.tableData);
    let tableIndex = newtableData.length as number;
    newtableData[tableIndex - 1].positionName = this.input.current.value;
    this.setState({ tableData: newtableData });
  };

  onBlurNewPosition = (e: any, row: any) => {

    if (this.state.newPositionName !== undefined) {
      let newtableData: any = [];
      newtableData = Object.assign([], this.state.tableData);
      let tableIndex = newtableData.length as number;
      newtableData[tableIndex - 1].positionName = this.state.newPositionName;
      this.setState({ tableData: newtableData });
    }
  };

  isPositionBlank = (cell, row, rowIndex) => {

    // if(this.state.isLoader === true) {
    //   return <Spinner animation="border" variant="success" size="sm" /> 
    // }
    if (row.positionName === "") {
      return (
        <div>
          <OutsideClickHandler
            onOutsideClick={(e: any) => {
              this.onOutsideNewPosition(e, this.state.newPositionName, row);
            }}
          >
            <Form.Group>
              <Form.Control
                ref={this.input}
                maxLength={100}
                placeholder="Add Position"
                type="text"
                key="121"
                id="positiontxt"
                value={this.state.newPositionName}
                onChange={this.changeNewPosition}
                onBlur={(e: any) => {
                  this.onBlurNewPosition(e, row);
                }}
                onFocus={(e: any) => {
                  this.onBlurNewPosition(e, row);
                }}
              />
            </Form.Group>
          </OutsideClickHandler>
        </div>
      );
    } else {
      return (
        <div
          className={`${row.status === "Inactive" && this.state.showDeactivatedPos
            ? "hasNoPointer"
            : ""
            } pos-name`}
        >
          <EllipsisWithTooltip placement="bottom">
            {row.positionName}
          </EllipsisWithTooltip>
          {/* <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id={"PositionName"}>{row.positionName}</Tooltip>} ><span>{row.positionName}</span></OverlayTrigger> */}
        </div>
      );
    }
  };

  isDepartmentBlank = (cell, row, rowIndex) => {

    if (this.state.isLoader) {
      return <Spinner animation="border" variant="success" size="sm" />
    }
    if (row.departmentName === "" && row.positionName === "") {
      return <div style={{ color: "gray" }}>Department</div>;
    } else if (row.departmentName === "" && row.positionName !== "") {
      return (
        <div className="required-cell-text">
          Department <div>Required</div>
        </div>
      );
    } else {
      return (
        <div
          className={`${row.status === "Inactive" && this.state.showDeactivatedPos
            ? "hasNoPointer"
            : ""
            } pos-name`}
        >
          <EllipsisWithTooltip placement="bottom">
            {row.departmentName}
          </EllipsisWithTooltip>
          {/* <OverlayTrigger key={"DepartmentName" + rowIndex} placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id={"DepartmentName"}>{row.departmentName}</Tooltip>}><span>{row.departmentName}</span></OverlayTrigger> */}
        </div>
      );
    }
  };

  isCOABlank = (cell, row, rowIndex) => {
    if (row.coa === "" && row.positionName === "") {
      return <div style={{ color: "gray" }}>COA</div>;
    } else if (row.coa === "" && row.positionName !== "") {
      return (
        <div className="required-cell-text">
          COA <div>Required</div>
        </div>
      );
    } else {
      return (
        <div
          className={`${row.status === "Inactive" && this.state.showDeactivatedPos
            ? "hasNoPointer"
            : ""
            } pos-name`}
        >
          <EllipsisWithTooltip placement="bottom">
            {row.coa}
          </EllipsisWithTooltip>
        </div>
      );
    }
  };

  formatColumn(cell: any, row: any, rowIndex: any) {
    let confirmModalContent = [
      {
        title: "Delete Position",
        desc: "Are you sure you want to delete '" + row.positionName + "' ?",
        cancleAction: "Cancel",
        ActionTrue: "Delete Position",
      },
    ];
    const tableDataLength = [...this.state.tableData].length - 1;
    if (rowIndex === tableDataLength && this.state.isNewPosition === true) {
      return (
        <button
          type="button"
          className="btn btn-primary fa-trash"
          onClick={this.handleRemoveNewRow}
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
              fill="#2caf92"
            />
          </svg>
        </button>
      );
    } else {
      return (
        <Dropdown className="more-action hasDropShadow" alignRight>
          <Dropdown.Toggle
            className="btn-outline-primary btn btn-primary more fa-trash"
            id="dropdown-more"
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M3.75226 15C3.75226 15.8273 4.42501 16.5 5.25226 16.5H12.7523C13.5795 16.5 14.2523 15.8273 14.2523 15V6H15.7523V4.5H13.5023H12.7523V3C12.7523 2.17275 12.0795 1.5 11.2523 1.5H6.75226C5.92501 1.5 5.25226 2.17275 5.25226 3V4.5H4.50226H2.25226V6H3.75226V15ZM6.75226 3H11.2523V4.5H6.75226V3ZM6.00226 6H12.0023H12.7523L12.753 15H5.25226V6H6.00226Z" />
              <path d="M6.75226 7.5H8.25226V13.5H6.75226V7.5ZM9.75226 7.5H11.2523V13.5H9.75226V7.5Z" />
            </svg>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <ConfirmationModal
              confirmModalContent={confirmModalContent}
              ishandleDeletePosition={this.ishandleDeletePosition.bind(
                this,
                row
              )}
            />
          </Dropdown.Menu>
        </Dropdown>
      );
    }
  }

  renderTooltip = (props) => (
    <Tooltip className="LaborPositionTooltip" id="button-tooltip" {...props}>
      <div className="p-2 text-center">
        This position is part of a department that is disabled. You must enable
        the department before you can enable the position.
      </div>
    </Tooltip>
  );
  deactivateDepartment = (cell: any, row: any, rowIndex: any) => {

    let confirmModalContent = [
      {
        title:
          this.state.isStatus === "Active"
            ? "Deactivate Position"
            : "Reactivate Position",
        desc:
          this.state.isStatus === "Active"
            ? "This position is used in one or more budgets. Deactivating the position will also deactivate the associated budgets."
            : "This position is associated with one or more deactivated budgets. Reactivating the position will also reactivate the associated budgets.",
        cancleAction: "Cancel",
        ActionTrue:
          this.state.isStatus === "Active" ? "Deactivate" : "Reactivate",
      },
    ];

    return (
      <>
        <div className="popmenu-wrapper">
          {(!this.state.isNewPosition && row.deptStatus !== "isBudget") && (
            <div className="pop-menu">
              <Dropdown
                // onClick={(e) => {
                //   e.stopPropagation();
                // }}
                className="more-action hasDropShadow"
                alignRight
              >
                <Dropdown.Toggle
                  className="btn-outline-primary more fa-trash dropdown-toggle btn btn-primary"
                  id="dropdown-more"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu className="hasDropdownMenu">
                  {row.deptStatus === "Inactive" && (
                    <Dropdown.Item>
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={this.renderTooltip}
                      >
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          viewBox="0 0 1024 1024"
                          height="20"
                          width="20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                          <path d="M464 336a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm72 112h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V456c0-4.4-3.6-8-8-8z"></path>
                        </svg>
                      </OverlayTrigger>{" "}
                      Cannot activate
                    </Dropdown.Item>
                  )}
                  {row.deptStatus.toLowerCase() === "active" && (
                    <Dropdown.Item onClick={(e) => this.actionPosition(e, row)}>
                      {row.status === "Active" ? <>Deactivate</> : <>Activate</>}
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}

          {row.deptStatus === "isBudget" && (
            <div className="pop-menu-confirm">
              <Dropdown className="more-action hasDropShadow deactivePositionDropdown" alignRight show>
                <Dropdown.Toggle
                  className="btn-outline-primary btn btn-primary more fa-trash"
                  id="dropdown-more"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <OutsideClickHandler
                    onOutsideClick={() => this.confirmOutsideClickHandler(row)}
                  >
                    <ConfirmationModal
                      confirmModalContent={confirmModalContent}
                      ishandleDeletePosition={this.ishandleBudgetDeletion.bind(
                        this,
                        row
                      )}

                    />
                  </OutsideClickHandler>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </div>


      </>
    );
  };

  actionPosition = (e, row) => {
    debugger
    let checkflag = e?.currentTarget?.innerText;

    if (checkflag === "Activate" && this.state.showDeactivatedPos && this.props.parentState.tblPayrollIntegrationType.length > 0) {
      let tblPostioniseType = [...this.state.tablePostionWiseIntegrationType];

      let filterByPostion = tblPostioniseType.filter((items) => items.positionID === row.positionID);


      if (filterByPostion.length > 0) {
       
       
        filterByPostion.forEach(function(item) {
          item.positionsMapValidation = false;
        });

        this.setState({
          isShowPostionMapping: true, parollMappingRow: row,
          tablePayrollMappingData: filterByPostion
        });
      } else {

        let tablePayrollMappingData = [...this.state.tablePayrollMappingData];
       

        tablePayrollMappingData.forEach(function(item) {
          item.positionsMapValidation = false;
          item.payrollPosition = "";
        });

        this.setState({
          isShowPostionMapping: true, parollMappingRow: row,
          tablePayrollMappingData: tablePayrollMappingData
        });
      }


    } else {

      this.setState({ loaderTrue: true });
      let request: any = {};
      request.DeptPosID = row.positionID;
      request.TenantID = row.tenantID;
      request.IsActive = row.status === "Active" ? 0 : 1;
      Hotel.UpdateStatusEmploymentTitle(request)
        .then(async (result: any | null) => {
          let currentStatus =
            row.status === "Active" ? "Deactivated" : "Activated";
          let data = result.result;
          if (data != null) {
            if (data.result === "Success") {
              let tableDataNew = this.state.actualPositionData;

              let affectedRow = tableDataNew.filter(
                (item) =>
                  item.positionID === row.positionID &&
                  item.departmentID === row.departmentID &&
                  item.tenantID === row.tenantID
              );
              affectedRow[0].status =
                affectedRow[0].status === "Active" ? "Inactive" : "Active";

              if (this.state.showDeactivatedPos) {
                this.setState({
                  tableData: tableDataNew.filter(
                    (items) =>
                      items.status.toString().toLowerCase() === "inactive"
                  ),
                });
              } else {
                this.setState({
                  tableData: tableDataNew.filter(
                    (items) => items.status.toString().toLowerCase() === "active"
                  ),
                });
              }




              if (e === "actionclick" && this.state.tablePayrollMappingData.length > 0) {
                let postionid = row.positionID;
                this.handleSaveParollSetting("handleSavePosition", postionid);

              }

              toast.success(`Position ${currentStatus} Successfully`, {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "LaborPosition",
              });
            } else if (data.result === "Schedule-Exists") {
              Utils.toastError("Future Schedules for this position exists.", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "LaborPosition",
              });
              this.setState({ loaderTrue: false });
            } else if (data.result === "Position-Exists") {
              Utils.toastError(
                "This Position exists as a default position for an employee.",
                {
                  // // position: toast.POSITION.BOTTOM_RIGHT,
                  // // containerId: "LaborPosition",
                }
              );
              this.setState({ loaderTrue: false });
            } else if (data.result === "Not-Exists") {
              Utils.toastError("Position dose not exist.", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "LaborPosition",
              });
              this.setState({ loaderTrue: false });
            } else if (data.result === "Budgets-Exists") {
              let data = Object.assign(
                [],
                JSON.parse(JSON.stringify(this.state.tableData))
              );

              data.map((item) => {
                if (item.id === row.id) item.deptStatus = "isBudget";
              });

              this.setState({
                isBudgetsExits: true,
                isDeactiveRow: request,
                isStatus: row.status,
                tableData: data,
                isActiveButton: false,
                isShowPostionMapping: false
              });

              // Utils.toastError("This position exists in budget.", {
              //   // // position: toast.POSITION.BOTTOM_RIGHT,
              //   // // containerId: "LaborPosition",
              // });
              this.setState({ loaderTrue: false });
            } else {
              Utils.toastError(data.result, {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "LaborPosition",
              });
              this.setState({ loaderTrue: false });
            }
          } else {
            Utils.toastError("Internal server error.", {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "LaborPosition",
            });
            this.setState({ loaderTrue: false });
          }
          resolve();
        })
        .catch((error) => {
          reject();
          this.setState({ loaderTrue: false });
        });
    }
  };

  searchedData = (newResult: any) => {
    this.setState({ SearchData: newResult });
  };

  deativatedPosChange = (e) => {
    //let { tableData, actualtableData } = this.state;
    let { checked } = e.target;
    this.setState({ showDeactivatedPos: checked, isNewPosition: false }, () => {
      this.props.togglePosition(checked);
      this.props.handleNewPosition(true);
      this.getPostionWiseIntegrationType();
    });
    let tableDataNew = this.state.actualPositionData;
    if (checked === true) {
      this.setState({
        tableData: tableDataNew.filter(
          (items) => items.status.toString().toLowerCase() === "inactive"
        ),
      });
    } else {
      this.setState({
        tableData: tableDataNew.filter(
          (items) => items.status.toString().toLowerCase() === "active"
        ),
      });
    }
  };

  budgetDeactive = (row: any) => {
    debugger;
    this.setState({ loaderTrue: true, isActiveButton: true });
    ///isActiveButton=true;
    if (this.state.isActiveButton) {

      return;
    }

    // let row=this.state.isDeactiveRow;
    let deleteRequest: any = {};
    deleteRequest.positionID = row.positionID;
    deleteRequest.type = row.status === "Inactive" ? "deactive" : "Active";
    deleteRequest.hotelID = 0;
    laborDeptPosition
      .DeactiveLaborPosition(deleteRequest)
      .then(async (result: any | null) => {
        if (result != null) {
          let currentStatus =
            row.status === "Inactive" ? "Reactivate" : "Deactiveted";
          if (result.status === "Success") {
            //this.setState({isActiveButton:true});
            let tableDataNew = this.state.actualPositionData;

            let affectedRow = tableDataNew.filter(
              (item) =>
                item.positionID === row.positionID &&
                item.departmentID === row.departmentID &&
                item.tenantID === row.tenantID
            );
            affectedRow[0].status =
              affectedRow[0].status === "Active" ? "Inactive" : "Active";

            if (this.state.showDeactivatedPos) {
              this.setState({
                tableData: tableDataNew.filter(
                  (items) =>
                    items.status.toString().toLowerCase() === "inactive"
                ),
              });
            } else {
              this.setState({
                tableData: tableDataNew.filter(
                  (items) => items.status.toString().toLowerCase() === "active"
                ),
              });
            }

            if (currentStatus === "Reactivate" && this.state.tablePayrollMappingData > 0) {
              let postionid = deleteRequest.positionID;
              this.handleSaveParollSetting("handleSavePosition", postionid);
            }


            toast.success(`Position ${currentStatus} Successfully`, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "LaborPosition",
            });
          } else {
            Utils.toastError("Internal server error.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "LaborPosition",
            });
          }
          // this.callcount=1;
          //  isActiveButton=false;
          this.setState({
            loaderTrue: false,
            isDeactiveRow: {},
            isActiveButton: true,
          });
          resolve();
        }
      })
      .catch((error) => {
        reject();
        // this.callcount=1;
        this.setState({ loaderTrue: false, isActiveButton: false });
      });
  };

  confirmOutsideClickHandler = (row: any) => {

    let tableDataNew = this.state.actualPositionData;
    let affectedRow = tableDataNew.filter((item) => item.id === row.id);
    let deptStatus = affectedRow[0].deptStatus;

    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableData))
    );

    data.map((item) => {
      if (item.id === row.id) item.deptStatus = deptStatus;
    });
    this.setState({ tableData: data });
  };

  debounceFuntion = (row) => {
    clearTimeout(timeId);
    timeId = setTimeout(() => {
      this.budgetDeactive(row);
    }, 1000);
  };
  ishandleBudgetDeletion(row: any, mode: any) {
    if (mode) {
      //this.handleDeletePosition(row);
      //this.debounceFuntion(row)

      this.budgetDeactive(row);
    } else {
      this.confirmOutsideClickHandler(row);
    }
  }


  getExportPayrollType = () => {
    let result =this.props.parentState.tblPayrollIntegrationType;
    let listArray: any = [];
    for (let i = 0; i < result.length; i++) {
      let dynamicObject: any = {};

      dynamicObject.displayName = result[i].displayName;
      dynamicObject.name = result[i].name;
      dynamicObject.payrollID = result[i].payrollID;
      dynamicObject.payrollPosition = "";

      dynamicObject.positionsMapValidation = false;
      listArray.push(dynamicObject)

    }
    this.setState({ tablePayrollMappingData: listArray });



  }

  getPostionWiseIntegrationType = () => {


    laborDeptPosition
      .GetLaborPostionWiseIntegrationType()
      .then(async (result: any[] | null) => {
        debugger;
        if (result != null && result.length > 0) {
          debugger;
          let listArray: any = [];
          for (let i = 0; i < result.length; i++) {
            let dynamicObject: any = {};

            dynamicObject.displayName = result[i].displayName;
            dynamicObject.positionID = result[i].positionID;
            // dynamicObject.name = result[i].name;
            dynamicObject.payrollID = result[i].payrollID;
            dynamicObject.payrollPosition = result[i].payrollPosition;

            dynamicObject.positionsMapValidation = false;
            listArray.push(dynamicObject)

          }
          this.setState({ tablePostionWiseIntegrationType: listArray });



        }

        resolve();
      })
      .catch((error) => {
        // this.setState({ loaderTrue: false });
        reject();
      });




  }



  onDescriptionChange = (index: string | number, e: any) => {
    debugger;
    let inputId = `${index}payrollcompanypositions`;
    let tablePayrollMappingDataCopy = [...this.state.tablePayrollMappingData];
    let payrollID = tablePayrollMappingDataCopy[index].payrollID;

    let value = e.target.value;
    let cursorIndex = e.target.selectionStart;

    let tablePayrollMappingData = [...this.state.tablePayrollMappingData];
    tablePayrollMappingData = tablePayrollMappingData.map((d) => {
      if (d.payrollID === payrollID) {
        return {
          ...d,
          payrollPosition: value,
          positionsMapValidation: false,
        };
      } else {
        return d;
      }
    });
    this.setState(
      (curr: any) => ({
        ...curr,
        tablePayrollMappingData: tablePayrollMappingData,
      }),
      () => {
        $("#" + inputId)
          .focus()
          .prop("selectionEnd", cursorIndex);
      }
    );
  }

  getTableRowsWithData = () => {


    const { tablePayrollMappingData } = this.state;

    return (
      <tbody>
        {tablePayrollMappingData.map((row: any, i: any) => {
          return (
            <>

              <tr key={i}>
                <td>
                  <div className="ehid-formatter">{row.displayName}</div>
                </td>
                <td>

                  <div className="description-formatter">
                    <Form.Group>
                      <Form.Control autoComplete="Off"
                        className={row.positionsMapValidation ? "validation-error" : ""}
                        id={`${i}payrollcompanypositions`}
                        value={row.payrollPosition}
                        onChange={(e: any) => this.onDescriptionChange(i, e)}

                      />
                      {row.positionsMapValidation ? <div className="message">Position mapping required</div> : ""}
                    </Form.Group>
                  </div>


                </td>




              </tr>
            </>
          )
        })}

      </tbody>
    );
  };

  handleSaveParollSetting = (type: any, postionID: any) => {
    debugger;
    let validateResult: boolean = false;
    let validateMessage: string = "";
    let validatior: string = "";
    let tablePayrollMappingData = [...this.state.tablePayrollMappingData];
    tablePayrollMappingData.map((d, index) => {

      let regx = /^\s*$/;

      if (regx.test(d.payrollPosition)) {
        d.positionsMapValidation = true;
        validateResult = true;
      }
    });

    if (validateResult) {

      this.setState({ tablePayrollMappingData: [] }, () => {
        this.setState({ tablePayrollMappingData: tablePayrollMappingData });
      });

      validateMessage = "Payroll company positions can not be blank";
      Utils.toastError(validateMessage, {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "payrollProcessorIndex",
      });
    } else {

      if (type === "addnewpostion") {
        this.handleSavePosition(this.state.parollMappingRow, "postionMappingClick");
      }
      else if (type === "actionclick") {
        this.actionPosition("actionclick", this.state.parollMappingRow);
      }
      else {

        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : storage.tenantID as any;

        let request = [...this.state.tablePayrollMappingData];
        let listArrayRequest: any = [];
        for (let i = 0; i < request.length; i++) {
          let requestObject: any = {};
          requestObject.ifPosition = postionID;
          requestObject.payrollID = request[i].payrollID;
          requestObject.tenantID = tenantID;
          requestObject.payrollPosition = request[i].payrollPosition;
          requestObject.saveType = "";
          listArrayRequest.push(requestObject)

        }


        laborDeptPosition.SavePayrollPositionMappingDetails(listArrayRequest)
          .then(async (result: any | null) => {
            if (result === "Success") {
              this.setState({ parollMappingRow: {}, isShowPostionMapping: false });
              // toast.success("Payroll company positions mapped Successfully", {
              //   // // position: toast.POSITION.BOTTOM_RIGHT,
              //   // // containerId: "payrollProcessorIndex",
              // });
            } else {
              Utils.toastError("Save Error, please try again", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "payrollProcessorIndex",
              });
            }
            resolve();
          })
          .catch((err: any) => {
            Utils.toastError(`Server Error, ${err}`, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "payrollProcessorIndex",
            });
            reject();
          });
      }
    }
  }

  onOutsidePostionMappingClick = () => {
    let tableNew = [...this.state.tableData];
    let letIndex = tableNew.findIndex(x => x.positionID === 0);
    if (letIndex > 0) {
      tableNew[letIndex].coa = "";
      this.setState({ tableData: tableNew });
    }
    this.setState({ isShowPostionMapping: false });
  }

  closeButtonPostionMappingClick = () => {
    let tableNew = [...this.state.tableData];
    let letIndex = tableNew.findIndex(x => x.positionID === 0);
    if (letIndex > 0) {
      tableNew[letIndex].coa = "";
      this.setState({ tableData: tableNew });
    }
    this.setState({ isShowPostionMapping: false });
  }

  render() {
    let columns = [
      {
        dataField: "positionName",
        text: "Position",
        editable: !this.state.showDeactivatedPos,
        formatter: this.isPositionBlank,
      },
      {
        dataField: "departmentName",
        text: "Department",
        editable: !this.state.showDeactivatedPos,
        formatter: this.isDepartmentBlank,
        editorRenderer: (
          editorProps,
          value,
          row,
          column,
          rowIndex,
          columnIndex
        ) => (
          <div className="auto-complete">
            <>
              <TableAutocomplete
                key={"department-1" + rowIndex}
                {...editorProps}
                value={value}
                items={this.state.departmentList}
                inputProps={{ placeholder: "Department" }}
              />
            </>
          </div>
        ),
      },
      {
        dataField: "coa",
        text: "COA",
        editable: !this.state.showDeactivatedPos,
        formatter: this.isCOABlank,
        editorRenderer: (
          editorProps,
          value,
          row,
          column,
          rowIndex,
          columnIndex
        ) => (
          <React.Fragment>
            <div className="auto-complete">
              <TableAutocomplete
                key={"chart-1" + rowIndex}
                {...editorProps}
                value={value}
                items={this.state.chartList}
                inputProps={{ placeholder: "COA" }}
              />
            </div>
          </React.Fragment>
        ),
      },
      {
        dataField: "isBreakTime",
        text: "Auto Schedule Breaks",
        formatter: this.isBreakTimeFormatter,
        headerFormatter: this.isBreakTimeheaderFormatter,
        searchable: false,
        editable: false,
      },
      {
        dataField: "autoTrueUpBreak",
        text: "Auto True-Up Breaks",
        formatter: this.isAutoTrueFormatter,
        headerFormatter: this.isAutoTrueUpheaderFormatter,
        searchable: false,
        editable: false,
        hidden: !this.state.showTrueUpColumn,
      },
      {
        dataField: "missPunchOutAlert",
        text: "Missed Punch-out Alert",
        formatter: this.missPunchOutFormatter,
        headerFormatter: this.isMissPunchOutheaderFormatter,
        searchable: false,
        editable: false,
      },
      // {
      //   dataField: "", //"EarlyPunch-in",
      //   // text: "Allow Early Punch-in",
      //   // formatter: this.allowEarlyPunchinFormatter,
      //   // searchable: false,
      //   // editable: false,
      // },
      {
        dataField: "action",
        text: "",
        hidden: !this.props.ManagePosition,
        editable: false,
        formatter: (cell: any, row: any, rowIndex: any) =>
          this.deactivateDepartment(cell, row, rowIndex),
      },

      {
        dataField: "action",
        text: "",
        hidden: !this.props.ManagePosition,
        editable: false,
        formatter: (cell: any, row: any, rowIndex: any) =>
          this.formatColumn(cell, row, rowIndex),
      },
    ];

    var loaderTrue = this.state.loaderTrue;
    var loadShow;
    if (loaderTrue) {
      loadShow = <PageLoader />;
    }
    return (
      <div>
        <div className="body-sec position position-tab dept-pos" onKeyPress={this.onKeyPress}>
          {/* <ToastContainer
          autoClose={3000}
          containerId={"LaborPosition"}
          enableMultiContainer
        /> */}
          {loadShow}
          <div className="">
            <Form>
              {this.state.isMountDone && (
                <ToolkitProvider
                  keyField="id"
                  data={this.state.tableData}
                  columns={columns}
                  rowEvents={this.rowEvents}
                  search={{
                    afterSearch: (newResult: any) => {
                      this.searchedData(newResult);
                    },
                  }}
                >
                  {(props: {
                    searchProps: JSX.IntrinsicAttributes;
                    baseProps: JSX.IntrinsicAttributes;
                  }) => (
                    <div className="pos-rel">
                      <div className="search-filter d-flex justifyBetween">
                        <label id="labeltableSearch" className="search-label">
                          <TableSearch
                            id="tableSearch"
                            key="tableSearch"
                            ref={this.child}
                            {...props.searchProps}
                            handleNewPosition={this.handleNewPosition.bind(this)}
                            CleanSearch={this.PositionCleanSearch.bind(this)}
                            placeholder={"Search..."}
                          />
                        </label>
                        <Form.Check
                          id="showDeativated"
                          key="showDeativated"
                          type="switch"
                          label="Show Deactivated"
                          checked={this.state.showDeactivatedPos}
                          onChange={this.deativatedPosChange}
                        />
                      </div>
                      <div className="cusPos">
                        <div className="position-relative">
                          {
                            this.state.isAutoScheduleBreakLoader ? <div className="table-overlay"><Spinner animation="border" variant="success" /></div> : <></>
                          }
                          <div className={`position-list LDPsetlastW9`}>
                            <div
                              className={
                                this.state.SearchData.length > 5
                                  ? "maxlength pos-rel"
                                  : "minlength pos-rel"
                              }
                            >
                              {this.state.isNewPosition && (
                                <div
                                  className="bck-drop"
                                  onClick={this.FocusTextbox}
                                ></div>
                              )}
                              <BootstrapTable
                                {...props.baseProps}
                                keyField="id"
                                cellEdit={
                                  this.props.ManagePosition ? this.cellEdit : ""
                                }
                                rowEvents={this.rowEvents}
                                hover
                                condensed
                              />
                            </div>
                          </div>
                        </div>
                        {!this.state.isNewPosition && this.props.ManagePosition && (
                          <button
                            type="button"
                            className="btn wht-bg txt-green rm-pad"
                            disabled={this.state.showDeactivatedPos}
                            onClick={this.PositionCleanSearch}
                          >
                            Add Position
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </ToolkitProvider>
              )}
            </Form>
          </div>

        </div>

        <div>

          {/*=========================== Save postion mapping required =================================*/}
          <Modal
            className="payroll-position-modal "
            show={this.state.isShowPostionMapping}
            onHide={this.closeButtonPostionMappingClick}
            aria-labelledby="contained-modal-title-vcenter"
            animation={true}
            centered
            backdrop="static"
            keyboard={false}
          >
            <OutsideClickHandler onOutsideClick={() => this.onOutsidePostionMappingClick()}>
              <Modal.Header closeButton>
                <Modal.Title>Please add a payroll mapping field to activate this position</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group controlId="payroll-position-table">
                  <div className="react-bootstrap-table">
                    <table id="payroll-position-table-container">
                      <thead>
                        <tr className="bg-light">
                          <th>Payroll Type</th>
                          <th>Payroll Company Positions</th>

                        </tr>
                      </thead>
                      {this.getTableRowsWithData()}
                    </table>
                  </div>
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => this.handleSaveParollSetting(this.state.isNewPosition ? "addnewpostion" : "actionclick", "")}
                >
                  Save
                </Button>
              </Modal.Footer>


            </OutsideClickHandler>
          </Modal>


        </div>

      </div>




    );




  }
}
