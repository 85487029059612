import * as React from "react";
import { Container, Dropdown, Button, Form, Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OutsideClickHandler from "react-outside-click-handler";
import { HidSelector } from "../../../../Common/Components/HidSelector";
import { LaborSchedule } from "../../../../Common/Services/LaborSchedule";
import { LaborForecast } from "../../../../Common/Services/LaborForecast";
import {
  ILaborScheduleDeptPositionRequestDto,
  ILaborSaveAsDefaultViewRequestDto,
  ILaborPublishSceduleRequestDto,
} from "../../../../Common/Contracts/ILaborSchedule";
import { IExitsLaborEmpScheduleRequestDto } from "../../../../Common/Contracts/ILaborForecast";
import {
  RoleSubPermissionSetting,
  IPageSubPermissionRole,
} from "../../../../Common/Services/RoleSubPermissionSetting";
import { ScheduleUserView } from "./ScheduleUserView";
import { DepartmentViewMain } from "./DepartmentViewMain";
import { PositionViewMain } from "./PositionViewMain";
import { AddEditShiftModal } from "./Modals & SlideOut/AddEditShiftModal";
import { AddEditTimeOffShiftModal } from "./Modals & SlideOut/AddEditTimeOffShiftModal";
import { EditApprovedPTOModal } from "./Modals & SlideOut/EditApprovedPTOModal";
import { SetForecastModal } from "./Modals & SlideOut/SetForecastModal";
import { CopyScheduleModal } from "./Modals & SlideOut/CopyScheduleModal";
import { ManageTemplateModal } from "./Modals & SlideOut/ManageTemplateModal";
import { SaveTemplateModal } from "./Modals & SlideOut/SaveTemplateModal";
import { SchedulePTOSlideOut } from "./Modals & SlideOut/SchedulePTOSlideOut";
import { resolve, reject } from "q";
import { ReportExport } from "../../../../Common/Services/ReportExport";
import { IReportParameter } from "../../../../Common/Contracts/IReportParameter";
import { ScheduleLogHistory } from "./Modals & SlideOut/ScheduleLogHistory";
import hotjar from "../../../../Common/ThirdPartyServices/Hotjar";

import RouteLeavingGuardBudget from "../../../../Common/Components/RouteLeavingGuardBudget";
import { confirmAlert } from "react-confirm-alert";
import { BulkEntryModal } from "./Modals & SlideOut/BulkEntryModal";
import "./ScheduleMain.scss";
import { LaborPerformance } from "../../../../Common/Services/LaborPerfomance";
import { Utils } from "../../../../Common/Utilis";

let routeLeavingGuard: any = null;
let hotelDetailsDataOnChangeValue: any;
let ClanderOnChangeValue: any = null;
const poupmsg = (
  <div className="recalculate-budget-desc">
    <div className="bold">
      Are you sure you want to leave the page without publishing changes ?
    </div>
    <div>
      You have made changes to the schedule that have not been published.
    </div>
  </div>
);

export class ScheduleMain extends React.Component<any, any> {
  private linkRef: any;
  private scheduleUserViewRef: any;
  private scheduleDeptViewMainRef: any;
  private schedulePosViewMainRef: any;
  private childHID: any;
  constructor(props: any) {
    super(props);
    this.scheduleUserViewRef = React.createRef();
    this.scheduleDeptViewMainRef = React.createRef();
    this.schedulePosViewMainRef = React.createRef();
    this.childHID = React.createRef();
    this.linkRef = React.createRef();
    this.state = {
      isDeepVisit: 1,
      hidValue: "Select",
      hotelName: "Select",
      navigationCalenderDates: [],
      currentDate: {},
      departmentList: [],
      positionList: [],
      viewType: "Users",
      todayDate: "",
      calenderSelectDate: {},
      importForecastBanner: false,
      isPageLock: false,
      isBulkSelect: false,
      headerDates: [],
      headerDays: [],
      tempSaveAsDefaultData: [],
      addEditShiftModal: false,
      editApprovedPTOModal: false,
      editApprovedPTOModalData: {},
      addEditTimeOffShiftModal: false,
      setForecastModal: false,
      copyScheduleModal: false,
      saveTemplateModal: false,
      manageTemplateModal: false,
      addEdiShiftValues: {},
      flyingMenuList: [
        "Copy Schedule",
        "Save as Template",
        "Manage Templates",
        "Change Log",
      ],
      pageLoadDate: "",
      openSlideOutdata: {},
      isSlideOutShow: false,
      exitsSchedule: true,
      publishButtonSpinner: false,
      viewPublishDropdown: false,
      printScheduleToggle: false,
      permissionManageSchedule: false,
      permissionManageTemplates: false,
      permissionManageTimeOff: false,
      permissionPublishSchedule: false,
      permissionManageForecast: false,
      permissionExportForecast: false,
      tempSelectedDate: "",
      showChangeLogModal: false,
      shiftId: 0,
      shiftDate: "",
      isStrictChildView: false,
      fromUrl: "ScheduleMain",
      // isPublishButton: false,
      // isChangeSchudle: false,
      isMultypleshift: "",
      isPublishButton: false,
      isChangeSchudle: false,
      showBulkEntryModal: false,
      hidwiseDate: "",
     
    };
  }

  componentDidMount() {
    // integrate hotjar
    hotjar(2825873, 6);

    window.addEventListener("beforeunload", this.beforeunload.bind(this));
    // Role Permission for Schedule
    localStorage.setItem("mainMenu", "labor");
    localStorage.setItem("subMenu", "Schedule");
    this.getSchudlePermission();

    // Role Permission for Forecast Page for Import Banner Conditions
    this.getForcastPermission();

    // Error in Forecast and redirected from forecast
    const location = this.props.location;
    // uses else condition in normal cases
    if (location.state !== undefined) {
      const stateLength = Object.keys(location.state).length;
      if (stateLength > 0) {
        const { pageLoadDate, scheduleViewType } = location.state;
        const scheduleDate: any = new Date(pageLoadDate);
        let currentdate = scheduleDate.getDate();
        let currentMonth = scheduleDate.getMonth() + 1;
        let currentYear = scheduleDate.getFullYear();
        let tempDate = currentMonth + "/" + currentdate + "/" + currentYear;
        this.setState(
          {
            viewType: scheduleViewType,
            tempSelectedDate: tempDate,
            todayDate: tempDate,
            calenderSelectDate: scheduleDate,
          },
          () => {
            window.history.replaceState(null, "");
          }
        );
      }
    } else {
      let todayDate: any = new Date();
      if (this.state.hidwiseDate === "") {
        if (
          JSON.parse(localStorage.storage).currentUtcTime !== "" &&
          JSON.parse(localStorage.storage).currentUtcTime !== null &&
          JSON.parse(localStorage.storage).currentUtcTime !== undefined
        ) {
          todayDate = new Date(JSON.parse(localStorage.storage).currentUtcTime);
        }
      } else {
        todayDate = new Date(this.state.hidwiseDate);
      }

      let currentdate = todayDate.getDate();
      let currentMonth = todayDate.getMonth() + 1;
      let currentYear = todayDate.getFullYear();
      let tempDate = currentMonth + "/" + currentdate + "/" + currentYear;

      // this.setState({
      //   todayDate: tempDate,
      //   tempSelectedDate: tempDate,
      //   calenderSelectDate: todayDate,
      // });
    }
  }

  beforeunload(e: any) {
    if (
      this.state.isChangeSchudle &&
      routeLeavingGuard !== "routeLeavingGuardTrue"
    ) {
      e.preventDefault();
      e.returnValue = true;
    }
  }

  routeLeavingGuardConfirm = (childState: any) => {
    if (childState === true) {
      routeLeavingGuard = "routeLeavingGuardTrue";
    } else {
      routeLeavingGuard = null;
    }
  };

  // beforeunload(e: any) {
  // beforeunload(e: any) {
  //   if (
  //     this.state.isChangeSchudle &&
  //     routeLeavingGuard !== "routeLeavingGuardTrue"
  //   ) {
  //     e.preventDefault();
  //     e.returnValue = true;
  //   }
  // }

  // routeLeavingGuardConfirm = (childState: any) => {
  //   if (childState === true) {
  //     routeLeavingGuard = "routeLeavingGuardTrue";
  //   } else {
  //     routeLeavingGuard = null;
  //   }
  // };

  showHideChangeLogModal = (shiftId: any, shiftDate) => {
    this.setState({
      showChangeLogModal: !this.state.showChangeLogModal,
      shiftId,
      shiftDate,
      isStrictChildView: +shiftId > 0 ? true : false,
    });
  };

  getSchudlePermission = () => {
    let requestObject = {} as IPageSubPermissionRole;
    requestObject.permissionID = 10006;
    requestObject.Module = "LaborManagement";
    requestObject.Type = "page";

    RoleSubPermissionSetting.GetPageSubPermissionDetails(requestObject)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          result.forEach((element: any) => {
            switch (element.permissionID) {
              case 10021:
                this.setState({ permissionManageSchedule: true });
                break;
              case 10022:
                this.setState({ permissionManageTemplates: true });
                break;
              case 10023:
                this.setState({ permissionManageTimeOff: true });
                break;
              case 10024:
                this.setState({ permissionPublishSchedule: true });
                break;
            }
          });
        } else {
          Utils.toastError(result.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "scheduleMain",
          });
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "scheduleMain",
        });
        reject();
      });
  };

  getForcastPermission = () => {
    let requestObject = {} as IPageSubPermissionRole;
    requestObject.permissionID = 10005;
    requestObject.Module = "LaborManagement";

    requestObject.Type = "page";
    RoleSubPermissionSetting.GetPageSubPermissionDetails(requestObject)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          result.forEach((element: any) => {
            switch (element.permissionName) {
              case "Manage Forecast":
                this.setState({ permissionManageForecast: true });
                break;
              case "Manage Templates":
                this.setState({ permissionExportForecast: true });
                break;
            }
          });
        } else {
          Utils.toastError(result.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "scheduleMain",
          });
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "scheduleMain",
        });
        reject();
      });
  };
  selectHotelDropDown = (hotelDetails: any) => {
    this.GetCurrentDateTimeByHid(
      hotelDetails.hotelID,
      hotelDetails,
      "HotelChange"
    );
  };

  selectHotelDropDownNew = (hotelDetails: any, hotelWiseDate: any) => {
    debugger;
    let tempDate: any;
    let currentDate: any;
    let temprotyDate = this.state.tempSelectedDate;
    let tempCurrentDate = this.state.calenderSelectDate;

    // let todayDate_new =new Date(hotelWiseDate);
    // let currentdate = todayDate_new.getDate();
    // let currentMonth = todayDate_new.getMonth() + 1;
    // let currentYear = todayDate_new.getFullYear();
    // let todayDate_new1 = currentMonth + "/" + currentdate + "/" + currentYear;

    // let temprotyDate =  todayDate_new1;
    // let tempCurrentDate =  new Date(hotelWiseDate);

    const location = this.props.location;

    if (this.state.isChangeSchudle) {
      hotelDetailsDataOnChangeValue = hotelDetails;
      this.isValidateScheduleTab();
    } else {
      this.childHID?.current?.updateChildState(hotelDetails);
      hotelDetailsDataOnChangeValue = null;
      // Error in Forecast and redirected from forecast
      // uses else condition in normal cases
      if (location.state !== undefined && this.state.pageLoadDate === "") {
        const stateLength = Object.keys(location.state).length;
        if (stateLength > 0) {
          const { pageLoadDate } = location.state;
          const scheduleDate: any = new Date(pageLoadDate);
          let currentdate = scheduleDate.getDate();
          let currentMonth = scheduleDate.getMonth() + 1;
          let currentYear = scheduleDate.getFullYear();
          let newTempDate =
            currentMonth + "/" + currentdate + "/" + currentYear;
          currentDate = temprotyDate === "" ? scheduleDate : tempCurrentDate; //scheduleDate;
          tempDate = temprotyDate === "" ? newTempDate : temprotyDate; //newTempDate;
        }
      } else {
        let scheduleDate: any = new Date();
        if (hotelWiseDate === "") {
          if (
            JSON.parse(localStorage.storage).currentUtcTime !== "" &&
            JSON.parse(localStorage.storage).currentUtcTime !== null &&
            JSON.parse(localStorage.storage).currentUtcTime !== undefined
          ) {
            scheduleDate = new Date(
              JSON.parse(localStorage.storage).currentUtcTime
            );
          }
        } else {
          scheduleDate = new Date(hotelWiseDate);
        }
        let currentdate = scheduleDate.getDate();
        let currentMonth = scheduleDate.getMonth() + 1;
        let currentYear = scheduleDate.getFullYear();
        let newTempDate = currentMonth + "/" + currentdate + "/" + currentYear;
        currentDate = temprotyDate === "" ? scheduleDate : tempCurrentDate; //scheduleDate;
        tempDate = temprotyDate === "" ? newTempDate : temprotyDate; //newTempDate;
      }

      let request = {} as ILaborSaveAsDefaultViewRequestDto;
      request.pageName = "Schedule";
      LaborSchedule.GetLaborSaveAsDefaultViewData(request)
        .then(async (result: any) => {
          if (result.message === "Success" && result.result.length > 0) {
            let apiresult: any = result.result;

            let pushDataSaveAsDefault: any = [];

            for (let i = 0; i < apiresult.length; i++) {
              let tempRowObject: any = {};
              tempRowObject.fieldName = apiresult[i].fieldName;
              tempRowObject.fieldValue = apiresult[i].fieldValue;
              pushDataSaveAsDefault.push(tempRowObject);
            }
            let defaultValue = pushDataSaveAsDefault.filter(
              (items: any) => items.fieldName === "DefaultView"
            );
            if (defaultValue.length > 0) {
              this.setState(
                { tempSaveAsDefaultData: pushDataSaveAsDefault },
                () => {
                  if (this.state.hidValue !== "Select") {
                    this.viewChange(defaultValue[0].fieldValue, "ehidChange");
                  }
                }
              );
            }
          } else {
            this.viewChange(this.state.viewType, "ehidChange");
          }
        })
        .catch((err: any) => {
          Utils.toastError(`Server Error, ${err}`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "scheduleMain",
          });
          reject();
        });

      this.setState(
        {
          hotelName: hotelDetails.lettercode,
          hidValue: hotelDetails.hotelID,
          todayDate: tempDate,
          calenderSelectDate: currentDate,
          departmentList: [],
          positionList: [],
          isBulkSelect: false,
        },
        () => {
          this.setState({ pageLoadDate: "true" });
          if (this.state.viewType !== "Users") {
            let requestPosDept = {} as ILaborScheduleDeptPositionRequestDto;
            requestPosDept.hotelID = this.state.hidValue;
            this.getPostionDepartment(requestPosDept);
            if (
              this.state.viewType === "Departments" &&
              this.scheduleDeptViewMainRef.current !== null
            ) {
              this.scheduleDeptViewMainRef.current.clearSearch();
            } else if (
              this.state.viewType === "Positions" &&
              this.schedulePosViewMainRef.current !== null
            ) {
              this.schedulePosViewMainRef.current.clearSearch();
            }
          }
        }
      );
    }
  };

  GetCurrentDateTimeByHid = (hotelID: any, hotelDetails: any, type: any) => {
    LaborPerformance.GetCurrentDateTimeByHid(hotelID)
      .then(async (result: any | null) => {
        if (result != null) {
          this.setState({ hidwiseDate: result.currentDate });
          if (type === "HotelChange") {
            this.selectHotelDropDownNew(hotelDetails, result.currentDate);
          }
        }
        resolve();
      })
      .catch((err) => {
        this.setState({ pageLoader: false });
        Utils.toastError(`Server Error: ${err}`);
        reject();
      });
  };

  refreshPositionDeptId = () => {
    this.setState({ departmentList: [], positionList: [] }, () => {
      let requestPosDept = {} as ILaborScheduleDeptPositionRequestDto;
      requestPosDept.hotelID = this.state.hidValue;
      this.getPostionDepartment(requestPosDept);
    });
  };

  changeCalenderDate = (newDate: any) => {
    if (this.state.isChangeSchudle) {
      ClanderOnChangeValue = newDate;
      this.isValidateScheduleTab();
    } else {
      let currentDate = new Date(newDate);
      if (ClanderOnChangeValue != null) {
        ClanderOnChangeValue = null;
        if (
          this.state.viewType === "Users" &&
          this.scheduleUserViewRef.current !== null
        ) {
          this.scheduleUserViewRef.current.calenderChange(currentDate);
        }
        if (
          this.state.viewType === "Departments" &&
          this.scheduleDeptViewMainRef.current !== null
        ) {
          this.scheduleDeptViewMainRef.current.calenderChange(currentDate);
        }
        if (
          this.state.viewType === "Positions" &&
          this.schedulePosViewMainRef.current !== null
        ) {
          this.schedulePosViewMainRef.current.calenderChange(currentDate);
        }
      }
       
      
      this.setState({
        todayDate: newDate,
        tempSelectedDate: newDate,
        calenderSelectDate: currentDate,
      });
      
    }
    
  };

  checkIfSheduleExist = () => {
    const { hidValue, navigationCalenderDates, importForecastBanner } =
      this.state;
    if (importForecastBanner) {
      return false;
    }
    const laborEmpScheduleRequest = {} as IExitsLaborEmpScheduleRequestDto;
    laborEmpScheduleRequest.hotelID = hidValue;
    laborEmpScheduleRequest.WeekStartDate = navigationCalenderDates[0];
    laborEmpScheduleRequest.WeekEndDate = navigationCalenderDates[1];
    if (
      !laborEmpScheduleRequest.WeekStartDate ||
      !laborEmpScheduleRequest.WeekEndDate
    ) {
      return false;
    }

    LaborForecast.ExitsLaborEmpScheduleDetails(laborEmpScheduleRequest)
      .then(async (result: any) => {
        //
        if (result.message === "Success") {
          if (result.result.message === "exits") {
            this.setState({ exitsSchedule: true });
          } else {
            this.setState({ exitsSchedule: false });
          }
          if (result.result.isPublish === "Yes") {
            this.setState({ isPublishButton: false });
          } else {
            this.setState({ isPublishButton: true });
          }
        }
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "scheduleMain",
        });
        reject();
      });
  };

  printScheduleToggle = (e: any) => {
    this.setState({ printScheduleToggle: e.target.checked });
  };

  showPublishDropdown = () => {
    this.setState({ viewPublishDropdown: true });
  };

  hidePublishDropdown = () => {
    this.setState({ viewPublishDropdown: false });
  };

  viewChange = (item: any, type: any) => {
    this.setState({ viewType: item }, () => {
      if (item === "Departments") {
        if (this.scheduleDeptViewMainRef.current !== null) {
          setTimeout(() => {
            this.scheduleDeptViewMainRef.current.getDeptMainData(type);
          }, 0);
        }
      }
      if (item === "Positions") {
        if (this.schedulePosViewMainRef.current !== null) {
          setTimeout(() => {
            this.schedulePosViewMainRef.current.getPosMainData(type);
          }, 0);
        }
      }

      if (item === "Users") {
        if (this.scheduleUserViewRef.current !== null) {
          setTimeout(() => {
            this.scheduleUserViewRef.current.getScheduleData(type);
          }, 0);
        }
      }
    });
  };

  getPostionDepartment = (request: ILaborScheduleDeptPositionRequestDto) => {
    LaborSchedule.GetLaborPositionDept(request)
      .then(async (result: any) => {
        if (result.message === "Success") {
          let apiResult = result.result;
          if (apiResult.length > 0) {
            let positonDetails: any = [{ id: -1, name: "All" }];
            let departMentDetails: any = [{ id: -1, name: "All" }];
            apiResult[0].laborSchedulePosition.forEach((res: any) => {
              let positonObject: any = {};
              positonObject.id = res.positionID;
              positonObject.name = res.positionName;
              positonObject.deptid = res.deptID;
              positonDetails.push(positonObject);
            });
            apiResult[0].laborScheduleDepartment.forEach((res: any) => {
              let departMentObject: any = {};
              departMentObject.id = res.deptID;
              departMentObject.name = res.departmentName;
              departMentDetails.push(departMentObject);
            });
            this.setState({
              positionList: positonDetails,
              departmentList: departMentDetails,
            });
          } else {
            Utils.toastError(
              `No departments have been assigned to the EHID ${this.state.hotelName}. Please assign the departments.`,
              {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "scheduleMain",
              }
            );
          }
        } else {
          Utils.toastError(result.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "scheduleMain",
          });
        }
        resolve();
      })
      .catch((err) => {
        Utils.toastError(`Server Error, ${err}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "scheduleMain",
        });
        reject();
      });
  };

  childDataLoaded = (childState: any) => {
    //
    this.setState(
      {
        navigationCalenderDates: childState.navigationCalenderDates,
        importForecastBanner: childState.importForecastBanner,
        currentDate: childState.currentDate,
        headerDates: childState.headerDates,
        headerDays: childState.headerDays,
        isPageLock: childState.isPageLock,
        lockDays: childState.lockDays,
        
      },
      () => this.checkIfSheduleExist()
    );
  };

  addShiftRefresh = () => {
    if (this.state.viewType === "Departments") {
      this.scheduleDeptViewMainRef.current.changeInDeptData("addShiftRefresh");
    } else if (this.state.viewType === "Positions") {
      this.schedulePosViewMainRef.current.changeInPosData("addShiftRefresh");
    } else if (this.state.viewType === "Users") {
      this.scheduleUserViewRef.current.getScheduleData("addShiftRefresh");
    } else {
      return false;
    }
  };

  refreshViewOnPublish = () => {
    if (this.state.viewType === "Departments") {
      this.scheduleDeptViewMainRef.current.refreshViewOnPublish(
        "publishChanges"
      );
    } else if (this.state.viewType === "Positions") {
      this.schedulePosViewMainRef.current.refreshViewOnPublish(
        "publishChanges"
      );
    } else if (this.state.viewType === "Users") {
      this.scheduleUserViewRef.current.refreshViewOnPublish("publishChanges");
    } else {
      return false;
    }
  };

  // sendEmailOnPublish = () => {
  //   const urlParameters = [
  //     {
  //       hid: this.state.hidValue,
  //       date: this.state.navigationCalenderDates[0],
  //       username: JSON.parse(localStorage.storage).userName,
  //     },
  //   ];
  //   let deepUrl = this.generateDeepLink(
  //     window.location.origin,
  //     "labor-management/MySchedule",
  //     urlParameters
  //   );
  //   if (deepUrl !== "") {
  //     let request = {} as any;
  //     request.HID = this.state.hidValue;
  //     request.LoginUserName = JSON.parse(localStorage.storage).userName;
  //     request.Url = deepUrl;
  //     LaborSchedule.GetShortenURL(request)
  //       .then(async (result: any) => {
  //         if (result.message === "Success") {
  //           let request = {} as ILaborPublishSceduleRequestDto;
  //           request.hotelID = this.state.hidValue;
  //           request.startDate = this.state.navigationCalenderDates[0];
  //           request.endDate = this.state.navigationCalenderDates[1];
  //           request.urlLink = result.result.link;
  //           // No message or action needs to be perofrmed
  //           LaborSchedule.LaborSchedulePublishEmailNotification(request)
  //             .then(async () => {
  //               resolve();
  //             })
  //             .catch(() => {
  //               reject();
  //             });
  //         }
  //         else
  //         {
  //           let request = {} as ILaborPublishSceduleRequestDto;
  //           request.hotelID = this.state.hidValue;
  //           request.startDate = this.state.navigationCalenderDates[0];
  //           request.endDate = this.state.navigationCalenderDates[1];
  //           request.urlLink = deepUrl;
  //           LaborSchedule.LaborSchedulePublishEmailNotification(request)
  //             .then(async () => {
  //               resolve();
  //             })
  //             .catch(() => {
  //               reject();
  //             });
  //         }

  //         this.setState({ loaderTrue: false });
  //         resolve();
  //       })
  //       .catch((err) => {
  //         Utils.toastError(`Server Error, ${err}`, {
  //           position: toast.POSITION.BOTTOM_RIGHT,
  //         });
  //         this.setState({ loaderTrue: false });
  //         reject();
  //       });
  //   }

  // };

  sendEmailOnPublish = () => {
    const urlParameters = [
      {
        hid: this.state.hidValue,
        date: this.state.navigationCalenderDates[0],
        username: JSON.parse(localStorage.storage).userName,
      },
    ];

    let deepUrl = this.generateDeepLink(
      window.location.origin,
      "labor-management/MySchedule",
      urlParameters
    );

    let request = {} as ILaborPublishSceduleRequestDto;
    request.hotelID = this.state.hidValue;
    request.startDate = this.state.navigationCalenderDates[0];
    request.endDate = this.state.navigationCalenderDates[1];
    request.urlLink = deepUrl;
    LaborSchedule.LaborSchedulePublishEmailNotification(request)
      .then(async () => {
        resolve();
      })
      .catch(() => {
        reject();
      });
  };

  getUrlParameter = (fullURL: any, name: any) => {
    name = name.replace(/[\\[]/, "\\[").replace(/[\]]/, "\\]");
    let regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    let results = regex.exec(fullURL);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  generateDeepLink = (domain: any, folderPath: any, urlParameters: any) => {
    let fullUrl = domain + "/" + folderPath;
    let paraMeters = "";
    if (urlParameters.length > 0) {
      for (let index = 0; index < urlParameters.length; index++) {
        Object.keys(urlParameters[index]).map((key) => {
          paraMeters += key + "=" + urlParameters[index][key] + "&";
        });
      }
      paraMeters = paraMeters.substring(0, paraMeters.length - 1);
      fullUrl = fullUrl + "?" + btoa(paraMeters);
    }
    return fullUrl;
  };

  publishSchedule = () => {
    this.hidePublishDropdown();
    this.setState({ publishButtonSpinner: true });
    let request = {} as ILaborPublishSceduleRequestDto;
    request.hotelID = this.state.hidValue;
    request.startDate = this.state.navigationCalenderDates[0];
    request.endDate = this.state.navigationCalenderDates[1];

    LaborSchedule.LaborSchedulePublishSchedule(request)
      .then(async (result: any) => {
        let apiResult = result.result;
        if (apiResult.saveStatus === "Success") {
          this.setState({ isPublishButton: false, isChangeSchudle: false });
          this.sendEmailOnPublish();
          this.refreshViewOnPublish();
          toast.success(`Schedule Published Successfully`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "scheduleMain",
          });
        } else {
          Utils.toastError(apiResult.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "scheduleMain",
          });
        }
        this.setState({ publishButtonSpinner: false });
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "scheduleMain",
        });
        this.setState({ publishButtonSpinner: false });
        reject();
      });

    if (this.state.printScheduleToggle === true) {
      this.downoadSechdule();
    }
  };

  downoadSechdule() {
    const currentDate = new Date(this.state.calenderSelectDate);
    const date =
      (currentDate.getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      currentDate.getDate().toString().padStart(2, "0") +
      "/" +
      currentDate.getFullYear().toString().substr(2, 2);
    const reportParameters: IReportParameter[] = [
      {
        name: "tenantId",
        value: JSON.parse(localStorage.getItem("storage")!).tenantID,
      },
      {
        name: "reportId",
        value: "12",
      },
      {
        name: "writerFormat",
        value: "PDF",
      },
      {
        name: "Hotelid",
        value: this.state.hidValue,
      },
      {
        name: "StartDate",
        value: date,
      },
      {
        name: "deptID",
        value: 0,
      },
      {
        name: "description",
        value: this.state.hotelName,
      },
      {
        name: "ExportType",
        value: "View",
      },
      {
        name: "IsPageBreak",
        value: 0,
      },
      {
        name: "userName",
        value: JSON.parse(localStorage.getItem("storage")!).userName,
      },
    ];
    ReportExport.reportDownload(JSON.stringify(reportParameters)).then(
      (result) => {
        const href = window.URL.createObjectURL(result);
        const a = this.linkRef.current;
        a.download = "Schedule.pdf";
        a.href = href;
        a.click();
        a.href = "";
      }
    );
  }

  editApprovedPTOModalShow = (schedule: any, row: any) => {
    let editApprovedPTOModalData: any = {};
    editApprovedPTOModalData.schedule = schedule;
    editApprovedPTOModalData.row = row;

    this.setState({ editApprovedPTOModalData }, () => {
      this.setState({
        editApprovedPTOModal: true,
      });
    });
  };

  editApprovedPTOModalHide = () => {
    this.setState({
      editApprovedPTOModalData: {},
      editApprovedPTOModal: false,
    });
  };

  addEditTimeOffShiftModalShow = () => {
    this.setState({
      addEditTimeOffShiftModal: true,
    });
  };

  addEditTimeOffShiftModalHide = (successMessage = "") => {
    if (successMessage) {
      this.viewChange(this.state.viewType, "addTimeOff");
      toast.success(successMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "scheduleMain",
      });
    }
    this.setState({
      addEditTimeOffShiftModal: false,
    });
  };

  addEditShiftModalShow = () => {
    let addEditObject: any = {};
    addEditObject.formType = "add";
    addEditObject.selectedSchedule = "addSchedule";
    addEditObject.scheduleAssignedType = "Assigned";
    addEditObject.selectedRow = "";
    addEditObject.selectedDay = "";
    this.setState({
      addEditShiftModal: true,
      addEdiShiftValues: addEditObject,
    });
  };

  bulkEntryModalShow = () => {
    this.setState({
      showBulkEntryModal: !this.state.showBulkEntryModal,
    });
  };

  addEditShiftModalHide = () => {
    this.setState({
      addEditShiftModal: false,
      addEdiShiftValues: {},
    });
  };

  openAddEditForm = (
    formType: any,
    cellValues: any,
    assignedType: any,
    row: any,
    dayName: any,
    editableStatus: string
  ) => {
    let addEditObject: any = {};

    addEditObject.formType = formType;
    addEditObject.selectedSchedule = cellValues;
    addEditObject.scheduleAssignedType = assignedType;
    addEditObject.selectedRow = row;
    addEditObject.selectedDay = dayName;
    addEditObject.editableStatus = editableStatus;

    this.setState({
      addEditShiftModal: true,
      addEdiShiftValues: addEditObject,
    });
  };

  successForecast = () => {
    this.setForecastModalHide();
    this.copyScheduleModalHide();

    if (this.state.viewType === "Departments") {
      this.scheduleDeptViewMainRef.current.changeInDeptData("addShiftRefresh");
    } else if (this.state.viewType === "Positions") {
      this.schedulePosViewMainRef.current.changeInPosData("addShiftRefresh");
    } else if (this.state.viewType === "Users") {
      this.scheduleUserViewRef.current.getScheduleData("addShiftRefresh");
    }
  };

  childBulkSelect = (type: any) => {
    this.setState({ isBulkSelect: type });
  };

  setForecastModalShow = () => {
    this.setState({ setForecastModal: true });
  };

  setForecastModalHide = () => {
    this.setState({ setForecastModal: false });
  };

  copyScheduleModalHide = () => {
    this.setState({ copyScheduleModal: false });
  };

  saveTemplateModalHide = () => {
    this.setState({ saveTemplateModal: false });
  };

  manageTemplateModalHide = () => {
    this.setState({ manageTemplateModal: false });
  };

  flyOutMenuClick = (item: any) => {
    if (item === "Copy Schedule") {
      this.setState({ copyScheduleModal: true });
    }
    if (item === "Save as Template") {
      this.setState({ saveTemplateModal: true });
    }
    if (item === "Manage Templates") {
      this.setState({ manageTemplateModal: true });
    }
    if (item === "Change Log") {
      this.showHideChangeLogModal(0, "");
    }
  };

  openSlideOut = (schedule: any, row: any) => {
    let ptoScheduleObjet: any = {};
    ptoScheduleObjet.schedule = schedule;
    ptoScheduleObjet.row = row;

    this.setState({ isSlideOutShow: true, openSlideOutdata: ptoScheduleObjet });
  };

  closeSlideOut = () => {
    if (this.state.viewType === "Departments") {
      this.scheduleDeptViewMainRef.current.refreshViewOnPTO("ptoChanges");
    } else if (this.state.viewType === "Positions") {
      this.schedulePosViewMainRef.current.refreshViewOnPTO("ptoChanges");
    } else if (this.state.viewType === "Users") {
      this.scheduleUserViewRef.current.refreshViewOnPTO("ptoChanges");
    } 
    this.setState({ isSlideOutShow: false });
  };

  GetLaborSaveAsDefaultViewData = () => {
    let request = {} as ILaborSaveAsDefaultViewRequestDto;
    request.pageName = "Schedule";
    LaborSchedule.GetLaborSaveAsDefaultViewData(request)
      .then(async (result: any) => {
        if (result.message === "Success" && result.result.length > 0) {
          let apiresult: any = result.result;

          let pushDataSaveAsDefault: any = [];

          for (let i = 0; i < apiresult.length; i++) {
            let tempRowObject: any = {};
            tempRowObject.fieldName = apiresult[i].fieldName;
            tempRowObject.fieldValue = apiresult[i].fieldValue;
            pushDataSaveAsDefault.push(tempRowObject);
          }
          let defaultValue = pushDataSaveAsDefault.filter(
            (items: any) => items.fieldName === "DefaultView"
          );
          if (defaultValue.length > 0) {
            this.setState(
              { tempSaveAsDefaultData: pushDataSaveAsDefault },
              () => {
                if (this.state.hidValue !== "Select") {
                }
              }
            );
          }
        }
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "scheduleMain",
        });
        reject();
      });
  };

  isValidateScheduleTab = () => {
    let bodyCss: string = "recalculate-budget-desc";
    let boldFont: string = "bold";
    let Message: any = "";
    Message = (
      <div
        className={
          bodyCss === "recalculate-budget-desc" ? "recalculate-budget-desc" : ""
        }
      >
        <div className={boldFont === "bold" ? "bold" : ""}>
          Are you sure you want to leave the page without publishing changes ?
        </div>
        <div>
          You have made changes to the schedule that have not been published.
        </div>
      </div>
    );

    confirmAlert({
      title: "Publish Schedule",
      message: Message,
      buttons: [
        {
          className: "leave-without-recalc",
          label: "Yes",
          onClick: () => this.isSchudleChange(),
        },
        {
          className: "return-to-settings",
          label: "No",
          onClick: () => reject(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
      overlayClassName: "recalculate-modal",
    });
  };

   
  isSchudleChange = () => {
    this.setState({ isChangeSchudle: false }, () => {
      if (ClanderOnChangeValue !== null) {
        this.changeCalenderDate(ClanderOnChangeValue);
      } else {
        this.selectHotelDropDown(hotelDetailsDataOnChangeValue);
      }
    });
  };

  isPublishOrNot = (childstate: any) => {
    this.setState({ isPublishButton: childstate, isChangeSchudle: true });
  };

  isPublishmaltishift = (childstate: any) => {
    this.setState({ isMultypleshift: childstate });
  };
  mainonOutsideClick = () => {
    this.setState({ isMultypleshift: "" });
  };
  
  

  render() {
    return (
      <>
        
        {this.state.isMultypleshift !== "multipleshift" && (
          <RouteLeavingGuardBudget
            when={this.state.isChangeSchudle}
            Title={"Publish Schedule"}
            Message={poupmsg}
            okButton={"Yes"}
            cancleButton={"No"}
            routeLeavingGuardConfirm={this.routeLeavingGuardConfirm}
            shouldBlockNavigation={(location: { pathname: any }) => {
              if (this.state.isChangeSchudle) {
                if (location.pathname) {
                  return true;
                }
              }
              return false;
            }}
          />
        )}
         

        <RouteLeavingGuardBudget
          when={this.state.isChangeSchudle}
          Title={"Publish Schedule"}
          Message={poupmsg}
          okButton={"Yes"}
          cancleButton={"No"}
          routeLeavingGuardConfirm={this.routeLeavingGuardConfirm}
          shouldBlockNavigation={(location: { pathname: any }) => {
            if (this.state.isChangeSchudle) {
              if (location.pathname) {
                return true;
              }
            }
            return false;
          }}
        />

        
        <ToastContainer
          enableMultiContainer
          autoClose={3000}
          containerId={"scheduleMain"}
        />

        <OutsideClickHandler onOutsideClick={() => this.mainonOutsideClick()}>
          <div className="schedule-main schedule-page-UI">
            <Container fluid className="body-sec">
              <div className="page-heading underline d-flex">
                <a ref={this.linkRef}></a>
                <HidSelector
                  onClickEvent={this.selectHotelDropDown}
                  ref={this.childHID}
                  alertComponent={this.state.isChangeSchudle}
                />
                <div className="mr-auto">Schedule</div>
                {(this.state.permissionManageTimeOff ||
                  this.state.permissionManageSchedule) && (
                  <div>
                    {this.state.hidValue !== "Select" &&
                      !this.state.isBulkSelect && (
                        <Button
                          className="add-time-off-shift btn-outline-primary"
                          onClick={() => this.addEditTimeOffShiftModalShow()}
                        >
                          Add Time Off Shift
                        </Button>
                      )}
                  </div>
                )}

                {this.state.hidValue !== "Select" && (
                  <>
                    {!this.state.importForecastBanner &&
                    !this.state.isPageLock &&
                    !this.state.isBulkSelect ? (
                      <div className="action-group d-flex flex-row">
                        {this.state.permissionManageSchedule && (
                          <Button
                            className="add-shift btn-outline-primary"
                            onClick={() => this.addEditShiftModalShow()}
                          >
                            Add Shift
                          </Button>
                        )}
                        {this.state.permissionManageSchedule &&
                          this.state.lockDays?.length === 0 && (
                            <Button
                              className="add-shift btn-outline-primary"
                              onClick={() => this.bulkEntryModalShow()}
                            >
                              Bulk Entry
                            </Button>
                          )}

                        {!this.state.exitsSchedule &&
                          this.state.permissionManageSchedule && (
                            <Button
                              className="publish-button"
                              onClick={() =>
                                this.flyOutMenuClick("Copy Schedule")
                              }
                            >
                              Copy Schedule
                            </Button>
                          )}

                        
                        {this.state.permissionPublishSchedule &&
                          (this.state.isPublishButton ||
                            this.state.isChangeSchudle) && (
                            // {this.state.permissionPublishSchedule && (this.state.isPublishButton || this.state.isChangeSchudle)&& (
                            <>
                              <span className="publish-changes-wrapper">
                                <span className="pip-icon red"></span>
                                <Button
                                  className={
                                    this.state.viewPublishDropdown
                                      ? "publish-button-show"
                                      : this.state.publishButtonSpinner
                                      ? "publish-button p-none"
                                      : "publish-button"
                                  }
                                  onClick={() => this.showPublishDropdown()}
                                  disabled={this.state.isBtnDisable}
                                >
                                  {this.state.publishButtonSpinner && (
                                    <Spinner
                                      className="mr-2"
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                  )}
                                  Publish Changes
                                </Button>

                                <div className="publishChanges">
                                  {this.state.viewPublishDropdown && (
                                    <OutsideClickHandler
                                      onOutsideClick={() =>
                                        this.hidePublishDropdown()
                                      }
                                    >
                                      <div className="publishDropdown">
                                        <div className="publishBody">
                                          <div className="publishHeading">
                                            Are you sure you're ready to publish
                                            ?
                                          </div>
                                          <div className="publishTxt">
                                            Your employees who have signed up
                                            for the notifications will receive
                                            them immediately
                                          </div>
                                          <div className="publishToggle">
                                            <Form>
                                              <Form.Check
                                                type="switch"
                                                id="sw_copy-shifts-assignments"
                                                label="Print Schedule"
                                                checked={
                                                  this.state.printScheduleToggle
                                                }
                                                onChange={(e: any) =>
                                                  this.printScheduleToggle(e)
                                                }
                                              />
                                            </Form>
                                          </div>
                                        </div>
                                        <div className="publishFooter action-link">
                                          <button
                                            className="publishCancel btn wht-bg"
                                            onClick={() =>
                                              this.hidePublishDropdown()
                                            }
                                          >
                                            Cancel
                                          </button>
                                          <button
                                            className="publishSchedule btn btn-primary"
                                            onClick={() =>
                                              this.publishSchedule()
                                            }
                                          >
                                            {this.state.printScheduleToggle
                                              ? "Publish and Print Schedule"
                                              : "Publish Schedule"}
                                          </button>
                                        </div>
                                      </div>
                                    </OutsideClickHandler>
                                  )}
                                </div>
                              </span>
                            </>
                          )}

                        <Dropdown className="more-action" alignRight>
                          <Dropdown.Toggle
                            className="btn-outline-primary btn btn-primary more"
                            id="dropdown-more"
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                              <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                              <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                            </svg>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {this.state.flyingMenuList.map(
                              (data: any, id: any) => {
                                if (data === "Copy Schedule") {
                                  if (this.state.permissionManageSchedule) {
                                    return (
                                      <Dropdown.Item
                                        key={id}
                                        onClick={() =>
                                          this.flyOutMenuClick(data)
                                        }
                                      >
                                        {data}
                                      </Dropdown.Item>
                                    );
                                  } else {
                                    return false;
                                  }
                                } else if (
                                  data === "Save as Template" ||
                                  data === "Manage Templates"
                                ) {
                                  if (this.state.permissionManageTemplates) {
                                    return (
                                      <Dropdown.Item
                                        key={id}
                                        onClick={() =>
                                          this.flyOutMenuClick(data)
                                        }
                                      >
                                        {data}
                                      </Dropdown.Item>
                                    );
                                  } else {
                                    return false;
                                  }
                                } else {
                                  return (
                                    <Dropdown.Item
                                      key={id}
                                      onClick={() => this.flyOutMenuClick(data)}
                                    >
                                      {data}
                                    </Dropdown.Item>
                                  );
                                }
                              }
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    ) : (
                      <div className="action-group d-flex flex-row">
                        <Dropdown className="more-action" alignRight>
                          <Dropdown.Toggle
                            className="btn-outline-primary btn btn-primary more"
                            id="dropdown-more"
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                              <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                              <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                            </svg>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {this.state.flyingMenuList
                              ?.filter(
                                (item: string) => item === "Save as Template"
                              )
                              .map((data: any, id: any) => {
                                if (this.state.permissionManageTemplates) {
                                  return (
                                    <Dropdown.Item
                                      key={id}
                                      onClick={() => this.flyOutMenuClick(data)}
                                    >
                                      {data}
                                    </Dropdown.Item>
                                  );
                                } else {
                                  return false;
                                }
                              })}

                            {this.state.flyingMenuList
                              ?.filter(
                                (item: string) => item === "Manage Templates"
                              )
                              .map((data: any, id: any) => {
                                if (this.state.permissionManageTemplates) {
                                  return (
                                    <Dropdown.Item
                                      key={id}
                                      onClick={() => this.flyOutMenuClick(data)}
                                    >
                                      {data}
                                    </Dropdown.Item>
                                  );
                                } else {
                                  return false;
                                }
                              })}

                            {this.state.flyingMenuList
                              ?.filter((item: string) => item === "Change Log")
                              .map((data: any, id: any) => {
                                return (
                                  <Dropdown.Item
                                    key={id}
                                    onClick={() => this.flyOutMenuClick(data)}
                                  >
                                    {data}
                                  </Dropdown.Item>
                                );
                              })}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    )}
                  </>
                )}
              </div>

              {this.state.hidValue !== "Select" &&
                this.state.viewType === "Users" && (
                  <ScheduleUserView
                    ref={this.scheduleUserViewRef}
                    parentState={this.state}
                    changeCalenderDate={this.changeCalenderDate}
                    openAddEditForm={this.openAddEditForm}
                    setForecastModalShow={this.setForecastModalShow}
                    childDataLoaded={this.childDataLoaded}
                    refreshPositionDeptId={this.refreshPositionDeptId}
                    viewChange={this.viewChange}
                    parentBulkSelect={this.childBulkSelect}
                    openSlideOut={this.openSlideOut}
                    openEditPTOModal={this.editApprovedPTOModalShow}
                    GetLaborSaveAsDefaultViewData={
                      this.GetLaborSaveAsDefaultViewData
                    }
                    showHideChangeLogModal={this.showHideChangeLogModal}
                    
                    isPublishOrNot={this.isPublishOrNot}
                    isPublishmaltishift={this.isPublishmaltishift}
                    
                  />
                )}

              {this.state.hidValue !== "Select" &&
                this.state.viewType === "Departments" && (
                  <DepartmentViewMain
                    ref={this.scheduleDeptViewMainRef}
                    parentState={this.state}
                    changeCalenderDate={this.changeCalenderDate}
                    openAddEditForm={this.openAddEditForm}
                    setForecastModalShow={this.setForecastModalShow}
                    childDataLoaded={this.childDataLoaded}
                    refreshPositionDeptId={this.refreshPositionDeptId}
                    viewChange={this.viewChange}
                    parentBulkSelect={this.childBulkSelect}
                    openSlideOut={this.openSlideOut}
                    openEditPTOModal={this.editApprovedPTOModalShow}
                    showHideChangeLogModal={this.showHideChangeLogModal}
                    GetLaborSaveAsDefaultViewData={
                      this.GetLaborSaveAsDefaultViewData
                    }
                     
                    isPublishOrNot={this.isPublishOrNot}
                    isPublishmaltishift={this.isPublishmaltishift}
                     
                    // isPublishOrNot={this.isPublishOrNot}
                    
                  />
                )}

              {this.state.hidValue !== "Select" &&
                this.state.viewType === "Positions" && (
                  <PositionViewMain
                    ref={this.schedulePosViewMainRef}
                    parentState={this.state}
                    changeCalenderDate={this.changeCalenderDate}
                    openAddEditForm={this.openAddEditForm}
                    setForecastModalShow={this.setForecastModalShow}
                    childDataLoaded={this.childDataLoaded}
                    refreshPositionDeptId={this.refreshPositionDeptId}
                    viewChange={this.viewChange}
                    parentBulkSelect={this.childBulkSelect}
                    openSlideOut={this.openSlideOut}
                    openEditPTOModal={this.editApprovedPTOModalShow}
                    showHideChangeLogModal={this.showHideChangeLogModal}
                    GetLaborSaveAsDefaultViewData={
                      this.GetLaborSaveAsDefaultViewData
                    }
                    
                    isPublishOrNot={this.isPublishOrNot}
                    isPublishmaltishift={this.isPublishmaltishift}
                    // isPublishOrNot={this.isPublishOrNot}
                    
                  />
                )}
              {this.state.hidValue !== "Select" &&
                this.state.addEditShiftModal && (
                  <AddEditShiftModal
                    parentState={this.state}
                    addEditShiftModalHide={this.addEditShiftModalHide}
                    addShiftRefresh={this.addShiftRefresh}
                    isPublishOrNot={this.isPublishOrNot}
                  />
                )}

              {this.state.hidValue !== "Select" &&
                this.state.addEditTimeOffShiftModal && (
                  <AddEditTimeOffShiftModal
                    parentState={this.state}
                    addEditTimeOffShiftModalHide={
                      this.addEditTimeOffShiftModalHide
                    }
                    addShiftRefresh={this.addShiftRefresh}
                    isPublishOrNot={this.isPublishOrNot}
                  />
                )}

              {this.state.hidValue !== "Select" &&
                this.state.navigationCalenderDates?.length > 1 &&
                this.state.showChangeLogModal && (
                  <ScheduleLogHistory
                    payPeriodStartDate={this.state.navigationCalenderDates[0]}
                    payPeriodEndDate={this.state.navigationCalenderDates[1]}
                    showChangeLogModal={this.state.showChangeLogModal}
                    showHideChangeLogModal={this.showHideChangeLogModal}
                    hotelName={this.state.hotelName}
                    hotelId={this.state.hidValue}
                    hideCrossAndClearFilters={this.state.isStrictChildView}
                    shiftId={this.state.shiftId}
                    shiftDate={this.state.shiftDate}
                    accType={""}
                  />
                )}

              {this.state.hidValue !== "Select" &&
                this.state.navigationCalenderDates?.length > 1 &&
                this.state.showBulkEntryModal && (
                  <BulkEntryModal
                    parentState={this.state}
                    payPeriodStartDate={this.state.navigationCalenderDates[0]}
                    payPeriodEndDate={this.state.navigationCalenderDates[1]}
                    showChangeLogModal={this.state.showBulkEntryModal}
                    showHideChangeLogModal={this.bulkEntryModalShow}
                    hotelName={this.state.hotelName}
                    hotelId={this.state.hidValue}
                    isStrictChildView={this.state.isStrictChildView}
                    positionList={this.state.positionList}
                    addShiftRefresh={this.addShiftRefresh}
                    // shiftId={this.state.shiftId}
                    // shiftDate={this.state.shiftDate}
                    // accType={""}
                  />
                )}

              

              {this.state.hidValue !== "Select" &&
                this.state.editApprovedPTOModal && (
                  <EditApprovedPTOModal
                    parentState={this.state}
                    editApprovedPTOModalHide={this.editApprovedPTOModalHide}
                    addShiftRefresh={this.addShiftRefresh}
                  />
                )}

              {this.state.hidValue !== "Select" &&
                this.state.setForecastModal && (
                  <SetForecastModal
                    parentState={this.state}
                    setForecastModalHide={this.setForecastModalHide}
                    successForecast={this.successForecast}
                  />
                )}

              {this.state.hidValue !== "Select" &&
                this.state.copyScheduleModal && (
                  <CopyScheduleModal
                    parentState={this.state}
                    copyScheduleModalHide={this.copyScheduleModalHide}
                    successForecast={this.successForecast}
                    isPublishOrNot={this.isPublishOrNot}
                  />
                )}

              {this.state.hidValue !== "Select" &&
                this.state.saveTemplateModal && (
                  <SaveTemplateModal
                    parentState={this.state}
                    saveTemplateModalHide={this.saveTemplateModalHide}
                  />
                )}

              {this.state.hidValue !== "Select" &&
                this.state.manageTemplateModal && (
                  <ManageTemplateModal
                    parentState={this.state}
                    manageTemplateModalHide={this.manageTemplateModalHide}
                  />
                )}
            </Container>
          </div>
        </OutsideClickHandler>
        {this.state.hidValue !== "Select" && this.state.isSlideOutShow && (
          <SchedulePTOSlideOut
            parentState={this.state}
            closeSlideOut={this.closeSlideOut}
          />
        )}
      </>
    );
  }
}
