import * as React from "react";
import {
  Form,
  Row,
  Col,
  Dropdown,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-input-range/lib/css/index.css";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { resolve, reject } from "q";
import Duplicate from "../../../Common/Assets/Images/icons/duplicate.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { InvoiceApprovalS } from "../../../Common/Services/InvoiceApprovals";
import Spinner from "react-bootstrap/Spinner";
import { Roles } from "../../../Common/Services/RolePermissionSetting";
import OutsideClickHandler from "react-outside-click-handler";
import { Utils } from "../../../Common/Utilis";
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import _ from "lodash";
import Delete from "../../../Common/Assets/Images/icons/delete.svg";
import { confirmAlert } from "react-confirm-alert";
import { MultiSelectSearch } from "../../../Common/Components/MultiSelectSearch";
import { statSync } from "fs";

let columnsPrimary = [] as any;
let columnsSecondary = [] as any;
let columnsSelectedCOA = [] as any;
export class InvoiceApprovalRightPanel extends React.Component<any, any> {
  private multiselectChild: any;
  constructor(props: any) {
    super(props);
    this.multiselectChild = React.createRef();
    this.state = {
      isSaved: false,
      loaderTrue: false,
      isActionFooter: false,
      isRPanelHide: false,
      isNewApproval: false,
      isDuplicate: false,
      isRActions: false,
      RolesList: [],
      allRolesList: [],
      COAList: [],
      allCOAList: [],
      vendorList: [],
      vendorListMain: [],
      defaultVendor: [],
      approvalTypeList: [
        { dataFromID: "COA", dataFrom: "COA", status: false },
        { dataFromID: "Department", dataFrom: "Department", status: false },
        { dataFromID: "Invoice Amount", dataFrom: "Invoice Amount", status: false },
        { dataFromID: "Vendor", dataFrom: "Vendor", status: false },
      ],
      mainGroupList: [],
      subGroupOneList: [],
      subGroupTwoList: [],
      selectedCOACount: "0",
      fieldData: {
        ruleID: 0,
        ruleStatus: "",
        approvalName: "",
        approvalType: "Select rule type",
        threshold: "",
        mainGroup: "Select Main Group",
        mainGroupID: "0",
        subGroupOne: "All",
        subGroupOneID: -1,
        subGroupTwo: "All",
        subGroupTwoID: -1,
        vendor: "",
        coa: "",
        internalNotes: "",
        primaryApprover: "",
        secondaryApprover: "",
      },
      isSaveDisaled: false,
      isTextBoxShow: false,
      tableDataPrimary: [],
      tableDataSecondary: [],
      tableSelectedCOA: [],
      filteredSelectedCOA: [],
      roleListShowP: false,
      roleListShowS: false,
      roleListOpenFor: "",
      roleFilter: "",
      isConfiCOAPerm: false,
      isConfiCOA: false,
      isInActiveCOA: false,
      isInactVendor: false,
      isStatusChanged: false,
      coaSelect: [{}],
      isSelectedCOATableShow: false,
    };
    this.approvalTypeDDChange = this.approvalTypeDDChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleDiscard = this.handleDiscard.bind(this);
    this.handleDuplicate = this.handleDuplicate.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleAddApproval = this.handleAddApproval.bind(this);
    this.handleApprovalLeft = this.handleApprovalLeft.bind(this);
  }

  componentDidMount() {

    this.getRoleData();
    this.GetHotelCOAlist();
    this.GetVendorList();
    this.GetMainGroup();
    console.log("right"+this.props.AAPermission.toString());
  }

  handleRowClick(data: any) {

    this.loadState(); // for clear all previous state
    this.getRowClickData(data)
  }

  // Fun for getting the MainGroup to fillup the dropdown
  GetMainGroup = () => {
    this.setState({
      loaderTrue: true,
    });
    InvoiceApprovalS.GetMainGroup()
      .then(async (result: any | null) => {
        if (result !== null) {
          this.setState({ mainGroupList: result });
        }
        resolve();
      })
      .catch((error) => {
        reject();
        this.setState({
          loaderTrue: false,
        });
      });
  };

  // Fun for getting the COA list to fillup the dropdown
  GetHotelCOAlist = () => {
    this.setState({
      loaderTrue: true,
    });
    let request: any = {};
    request.vid = 0;
    InvoiceApprovalS.GetHotelCOAlist(request)
      .then(async (result: any | null) => {
        if (result !== null) {
          this.changeNodeKey(result, "1");
        }
        resolve();
      })
      .catch((error) => {
        reject();
        this.setState({
          loaderTrue: false,
        });
      });
  };

  // change the vendor & COA list json data according to the typeahead
  changeNodeKey = (result: any, Type: any) => {
    if (Type === "1") {
      let isConfiPerm = result.some((item: any) => item.isCOAPer === "Yes");
      result = result.map((item: any, id: any) => {
        return {
          value: item.id + ' ' + item.coaName,
          chartNo: item.id,
          chartName: item.coaName,
          description: item.id + ' ' + item.coaName,
          isChecked: false,
          id: item.id,
          isCOAPer: item.isCOAPer,
          coaName: item.coaName,
          status: item.status?.toLowerCase(),
          //!isConfiPerm && item.isconfidentialcoa !== "" ? 'inactive' : item.status?.toLowerCase(),
          isconfidentialcoa: item.isconfidentialcoa,
        };
      });
      result = _.sortBy(result, "chartNo");

      // if (!isConfiPerm)
      //   result = _.sortBy(result, "isconfidentialcoa");
      result = _.sortBy(result, "status");

      this.setState({ COAList: result, allCOAList: result, isConfiCOAPerm: isConfiPerm });
    } else if (Type === "2") {
      result = result.map((item: any, id: any) => {
        return {
          id: item.vid,
          label: item.vendor,
          value: item.vid,
          status: item.status,
        };
      });
      this.setState({ vendorList: result, vendorListMain: result });
    } else if (Type === "3") {
      result = result.map((item: any, id: any) => {
        return {
          id: id,
          coa: item.cNo + ' - ' + item.cName,
          subGroupId: item.subGroupId,
          subGroupID2: item.subGroupID2,
          isExclude: false,
        };
      });
      this.setState({ tableSelectedCOA: result, filteredSelectedCOA: result })
    }
  };

  // Fun for getting the vendor list to fillup the dropdown
  GetVendorList = () => {
    this.setState({
      loaderTrue: true,
    });
    let request: any = {};
    request.HID = 0;
    InvoiceApprovalS.GetVendorlist(request)
      .then(async (result: any) => {
        //result= result.filter((o)=> o?.vendorAprroval?.toLowerCase() == "app");
        if (result) {
          this.changeNodeKey(result, "2");
        }
        resolve();
      })
      .catch((error) => {
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
        this.setState({
          loaderTrue: false,
        });
      });
  };

  // Fun for bind the right panel data based on the ruleID
  getRowClickData = (data) => {
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    fieldData.mainGroup = 'Select Main Group';
    fieldData.subGroupOne = "All";
    fieldData.subGroupTwo = "All";
    fieldData.mainGroupID = "0";
    fieldData.subGroupOneID = -1;
    fieldData.subGroupTwoID = -1;

    this.setState({
      isActionFooter: false,
      loaderTrue: true,
      isNewApproval: false,
      isRPanelHide: false,
      isDuplicate: false,
      isRActions: true,
      isTextBoxShow: false,
      roleListOpenFor: "",
      vendorList: this.state.vendorListMain,
      isStatusChanged: false,
      isConfiCOA: false,
      isSelectedCOATableShow: false,
      fieldData,
    }, () => {
      $('#IARightBody').scrollTop(0);
    });
    //Set check icon on select type in approval list DD
    this.setState({ fieldData: data }, () => {
      let approvalList = this.state.approvalTypeList;
      for (let i = 0; i < approvalList.length; i++) {
        if (approvalList[i].dataFromID === data.approvalType) {
          approvalList[i].status = true;
        } else {
          approvalList[i].status = false;
        }
      }
    });

    sessionStorage.setItem("fieldDataIA", JSON.stringify(data));
    let request = {} as any;
    request.RuleID = data.ruleID;
    this.GetInvoiceAppRuleDetails(request);
  }

  filterVendorList = () => {
    //Filter Vendor List
    const activeVendorList = _.filter(this.state.vendorListMain, function (o) {
      return o.status.trim().toLowerCase() === 'active';
    });
    this.setState({ vendorList: activeVendorList });
  }

  //Fun to call when click on the left panel row
  GetInvoiceAppRuleDetails(request: any) {

    InvoiceApprovalS.GetInvoiceAppRuleDetails(request)
      .then(async (result: any[] | null) => {
        if (result != null) {
          let new_state = Object.assign({}, this.state);
          let fieldData = new_state.fieldData;
          if (this.state.fieldData.approvalType === "Invoice Amount") {
            fieldData.threshold = result[0]?.threshold;
          } else if (this.state.fieldData.approvalType === "Department") {
            fieldData.threshold = result[0]?.threshold;
            this.setState({ fieldData });
            const selMainGroup = _.filter(this.state.mainGroupList, function (o) {
              return o.groupID === result[0]?.threshold.split(',')[0];
            });
            this.mainGroupDDChange(selMainGroup[0], 'mainGroup', 'RowClick');
          } else if (this.state.fieldData.approvalType === "Vendor") {
            fieldData.vendor = result[0]?.threshold;
            const selVendor = _.filter(this.state.vendorListMain, function (o) {
              return o.label.toString().toLowerCase() === result[0]?.threshold.trim().toString().toLowerCase();
            });

            //Check weather the selected Vendor active or not for set disabled controls
            const isInactiveVend = selVendor.some((item: any) => (item.status.toLowerCase() === "inactive"));

            if (this.state.fieldData.ruleStatus?.toLowerCase() === "active" || this.state.fieldData.ruleStatus === "") {
              const activeVendorList = _.filter(this.state.vendorListMain, function (o) {
                return o.status.trim().toLowerCase() === 'active';
              });
              this.setState({ vendorList: activeVendorList });
            }

            this.setState({ defaultVendor: selVendor, isInactVendor: isInactiveVend });

          } else if (this.state.fieldData.approvalType === "COA") {

            let chartItems: any[] = [];
            let savedchartItems = result[0]?.threshold.split(',') as any;

            let new_state = Object.assign({}, this.state);
            let fieldData = new_state.fieldData;
            let allCOAs = "";

            let filteredActiveCOA = InvoiceApprovalRightPanel.filterCOAList(this.state.allCOAList, this.state.isConfiCOAPerm);

            savedchartItems.forEach((item) => {
              let newItem = {} as any;
              const selCOA = _.filter(this.state.allCOAList, (o) =>
                _.includes(
                  o.id, item
                )
              );

              newItem.value = selCOA[0].value;
              newItem.chartNo = selCOA[0].chartNo;
              newItem.chartName = selCOA[0].chartName;
              newItem.description = selCOA[0].description;
              newItem.isChecked = true;
              newItem.id = selCOA[0].id;
              newItem.isCOAPer = selCOA[0].isCOAPer;
              newItem.coaName = selCOA[0].coaName;
              newItem.status = selCOA[0].status;
              newItem.isconfidentialcoa = selCOA[0].isconfidentialcoa;
              allCOAs += selCOA[0].id + ',';
              chartItems.push(newItem);
              filteredActiveCOA.push(newItem);
            });

            fieldData.coa = allCOAs.replace(/,(?=\s*$)/, '');
            filteredActiveCOA = _.uniqBy(filteredActiveCOA, 'chartNo');
            this.setState({ COAList: filteredActiveCOA });
            console.log(chartItems)

            this.setState({ coaSelect: chartItems, fieldData, COAList: filteredActiveCOA }, () => {
              this.multiselectChild?.current?.updateSavedItem(chartItems);
            });
            const inactiveCoaLen: any = chartItems.filter((item) => item.status?.toLowerCase() === 'inactive');
            if (inactiveCoaLen.length === chartItems.length) {
              fieldData.ruleStatus = "Inactive";
            } else {
              fieldData.ruleStatus = "";
            }
          }

          fieldData.internalNotes = result[0]?.internalNote;
          fieldData.primaryApprover = result[0]?.roleID;
          if (result.length === 2) {
            fieldData.secondaryApprover = result[1]?.roleID;
          } else {
            fieldData.secondaryApprover = "";
          }

          this.setState({ fieldData });

          let primaryRoleIDs = [] as any;
          let secondaryRoleIDs = [] as any;
          primaryRoleIDs = result[0]?.roleID.split(',');
          if (result.length === 2) {
            secondaryRoleIDs = result[1]?.roleID.split(',')
          } else {
            secondaryRoleIDs = [];
          }

          let selectedListP = [] as any;
          let selectedListS = [] as any;

          if (result[0]?.appLavel !== 0) {
            for (let i = 0; i < primaryRoleIDs.length; i++) {
              const roleInfo = this.state.RolesList.filter((item) => item.roleID === primaryRoleIDs[i])
              if (roleInfo != null && roleInfo.length > 0) {
                let lst = {} as any;
                lst.roleName = roleInfo[0].roleName;
                lst.roleID = roleInfo[0].roleID;
                selectedListP.push(lst)
              }
            }

            for (let i = 0; i < secondaryRoleIDs.length; i++) {
              const roleInfo = this.state.RolesList.filter((item) => item.roleID === secondaryRoleIDs[i])
              if (roleInfo != null && roleInfo.length > 0) {
                let lst = {} as any;
                lst.roleName = roleInfo[0].roleName;
                lst.roleID = roleInfo[0].roleID;
                selectedListS.push(lst)
              }
            }
          }
          this.setState({ tableDataPrimary: selectedListP, tableDataSecondary: selectedListS });
        }
        setTimeout(() => {
          this.setState({ loaderTrue: false });
        }, 500);
        resolve();
      })
      .catch((error) => {
        this.setState({ loaderTrue: false }, () => {
          Utils.toastError("Something went wrong.", {
          });
        });
        reject();
      });
  }

  public static filterCOAList = (allCOAList: any, isConfiCOAPerm: any) => {
    let filteredCOA = allCOAList.filter((item) => item.status === 'active');
    if (!isConfiCOAPerm) {
      filteredCOA = filteredCOA.filter((item) => item.isconfidentialcoa === '');
    }
    return filteredCOA;
  }

  // Trigger when user click on new approval on left panel
  handleAddApproval = (data: any) => {
    this.BindNewApproval(data);
  };

  handleApprovalLeft = (data: any) => {
    sessionStorage.removeItem("fieldDataIA");
    $('#IARightBody').scrollTop(0);
    this.BindNewApproval(data);
  };

  //Load the new state for new approval
  BindNewApproval(data: any) {
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    fieldData.ruleID = 0;
    fieldData.approvalName = "";
    fieldData.approvalType = "Select rule type";
    fieldData.mainGroup = 'Select Main Group';
    fieldData.subGroupOne = "All";
    fieldData.subGroupTwo = "All";
    fieldData.mainGroupID = "0";
    fieldData.subGroupOneID = -1;
    fieldData.subGroupTwoID = -1;
    fieldData.threshold = "";
    fieldData.vendor = "";
    fieldData.coa = "";
    fieldData.internalNotes = "";
    fieldData.primaryApprover = "";
    fieldData.secondaryApprover = "";

    let approvalList = this.state.approvalTypeList;
    for (let i = 0; i < approvalList.length; i++) {
      approvalList[i].status = false;
    }

    // Remove Inactive Vendor from List
    const filterVendorList = this.state.vendorListMain.filter(x => x.status?.toLowerCase() === "active");
    this.setState({ vendorList: filterVendorList });

    let activeCOA = InvoiceApprovalRightPanel.filterCOAList(this.state.allCOAList, this.state.isConfiCOAPerm);

    for (let index = 0; index < activeCOA.length; index++) {
      activeCOA[index].isChecked = false;
    }

    this.setState(
      {
        fieldData,
        loaderTrue: false,
        isDuplicate: false,
        isActionFooter: true,
        isRPanelHide: false,
        isNewApproval: true,
        isTextBoxShow: true,
        roleListOpenFor: "",
        tableDataPrimary: [],
        tableDataSecondary: [],
        isConfiCOA: false,
        coaSelect: [{}],
        isSelectedCOATableShow: false,
        subGroupOneList: [],
        subGroupTwoList: [],
        COAList: activeCOA
      }, () => {
        this.props.changeValueFuntion(true);
      });
  }

  // Approval type dropdown change event
  approvalTypeDDChange(e: any) {
    let data = this.state.approvalTypeList;
    for (let i = 0; i < data.length; i++) {
      if (data[i].dataFromID === e.dataFromID) {
        data[i].status = true;
      } else {
        data[i].status = false;
      }
    }
    for (let index = 0; index < this.state.COAList.length; index++) {
      this.state.COAList[index].isChecked = false;
    }
    this.setState({
      isActionFooter: true,
      coaSelect: [{}],
      defaultVendor: [],
      isSelectedCOATableShow: false,
    }, () => {
      this.props.changeValueFuntion(true);
    });
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    fieldData.approvalType = e.dataFrom;
    fieldData.threshold = "";
    fieldData.mainGroup = 'Select Main Group';
    fieldData.mainGroup = "Select Main Group";
    fieldData.subGroupOne = "All";
    fieldData.subGroupTwo = "All";
    fieldData.mainGroupID = "0";
    fieldData.subGroupOneID = -1;
    fieldData.subGroupTwoID = -1;
    fieldData.vendor = "";
    fieldData.coa = "";
    this.setState({ fieldData, isStatusChanged: true, selectedCOACount: "0", isSelectedCOATableShow: false, subGroupOneList: [], subGroupTwoList: [] });
  }

  //Main group DD change
  mainGroupDDChange = (e: any, ddType, ddCallType) => {
    this.setState({ selectedCOACount: "0", isSelectedCOATableShow: false, })
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    if (ddType === 'mainGroup') {
      fieldData.mainGroup = e.groupName;
      fieldData.mainGroupID = e.groupID;
      fieldData.subGroupOne = 'All';
      fieldData.subGroupTwo = 'All';
      this.setState({ loaderTrue: true, fieldData, subGroupOneList: [], subGroupTwoList: [] });
      let request: any = {};
      request.AccountUniqueNo = e.accountUniqueno;
      request.GroupID = e.groupID;
      InvoiceApprovalS.GetCOASubGroup(request)
        .then(async (result: any | null) => {
          if (result !== null) {
            let { subGroupOneList, subGroupTwoList } = this.state;
            let all = {} as any;
            all.rno = 0;
            all.groupName = "All";
            all.groupID = -1;
            all.mainGroupID = -1;
            all.accountUniqueNo = -1;
            let resultData1 = [] as any;
            resultData1 = result?.subGroupList?.filter((item) => item.groupType === 'Sub1');
            if (resultData1.length > 0) {
              all.groupType = "Sub1";
              subGroupOneList.push(all);
            }
            // setting the sub group 1 dropdown items
            resultData1.forEach(element => {
              subGroupOneList.push(element);
            });

            let resultData2 = [] as any;
            resultData2 = result?.subGroupList?.filter((item) => item.groupType === 'Sub2');
            if (resultData2.length > 0) {
              all.groupType = "Sub2";
              subGroupTwoList.push(all);
            }

            // setting the sub group 2 dropdown items
            resultData2.forEach(element => {
              subGroupTwoList.push(element);
            });
            this.setState({
              subGroupOneList,
              subGroupTwoList,
              selectedCOACount: result?.coaListByCtype.length,
              loaderTrue: false,
            }, () => {
              this.changeNodeKey(result?.coaListByCtype, '3');
              if (ddCallType === 'Change')
                this.setState({ isActionFooter: true }, () => {
                  this.props.changeValueFuntion(true);
                })
              if (ddCallType === 'RowClick') {
                this.setState({ loaderTrue: true });
                const filterSub1 = this.state.subGroupOneList.filter((item) => item.groupID === Number(this.state.fieldData.threshold.split(',')[1]))
                if (filterSub1.length > 0) {
                  this.setState({ subGroupOneID: filterSub1[0]?.groupID });
                  console.log(filterSub1[0])
                  this.mainGroupDDChange(filterSub1[0], 'sub1', 'RowClick');
                }
                else {
                  this.setState({ subGroupOneID: -1, subGroupOne: "All" });
                  let arrSub = {} as any;
                  arrSub.accountUniqueNo = -1;
                  arrSub.groupID = -1;
                  arrSub.groupName = "All";
                  arrSub.groupType = "Sub1";
                  arrSub.mainGroupID = -1;
                  arrSub.rno = 0;
                  this.mainGroupDDChange(arrSub, 'sub1', 'RowClick');
                }

                setTimeout(() => {
                  const filterSub2 = this.state.subGroupTwoList.filter((item) => item.groupID === Number(this.state.fieldData.threshold.split(',')[2]))
                  if (filterSub2.length > 0) {
                    this.setState({ subGroupTwoID: filterSub2[0]?.groupID });
                    this.mainGroupDDChange(filterSub2[0], 'sub2', 'RowClick');
                  }
                  else {
                    this.setState({ subGroupTwoID: -1, subGroupTwo: "All" });
                    let arrSub = {} as any;
                    arrSub.accountUniqueNo = -1;
                    arrSub.groupID = -1;
                    arrSub.groupName = "All";
                    arrSub.groupType = "Sub2";
                    arrSub.mainGroupID = -1;
                    arrSub.rno = 0;
                    this.mainGroupDDChange(arrSub, 'sub2', 'RowClick');
                  }

                }, 0);
              }
              setTimeout(() => {
                this.setState({ loaderTrue: false });
              }, 100);
            });
          }
          resolve();
        })
        .catch((error) => {
          reject();
          this.setState({
            loaderTrue: false,
          });
        });
    } else if (ddType === 'sub1') {
      fieldData.subGroupOne = e.groupName;
      fieldData.subGroupOneID = e.groupID;
      fieldData.subGroupTwo = "All";
      fieldData.subGroupTwoID = -1;
      let allCoaList = [] as any;
      allCoaList = this.state.tableSelectedCOA;
      let filteredSubGroup1, countFromTwo;

      if (e.groupID === -1)
        filteredSubGroup1 = allCoaList;
      else
        filteredSubGroup1 = allCoaList.filter((item) => item.subGroupId === e.groupID);

      // if (this.state.fieldData.subGroupTwoID !== -1) {
      //   countFromTwo = allCoaList.filter((item) => item.subGroupID2 === this.state.fieldData.subGroupTwoID);
      //   countFromTwo?.forEach(element => {
      //     filteredSubGroup1.push(element);
      //   });
      // } else {
      //   allCoaList?.forEach(element => {
      //     filteredSubGroup1.push(element);
      //   });
      // }

      filteredSubGroup1 = _.uniqBy(filteredSubGroup1, 'coa');
      filteredSubGroup1 = _.sortBy(filteredSubGroup1, 'coa');

      this.setState({ fieldData, filteredSelectedCOA: filteredSubGroup1, selectedCOACount: filteredSubGroup1.length }, () => {
        if (ddCallType === "Change") {
          this.setState({ isActionFooter: true }, () => {
            this.props.changeValueFuntion(true);
          })
        }
      });
    }
    else if (ddType === 'sub2') {
      fieldData.subGroupTwo = e.groupName;
      fieldData.subGroupTwoID = e.groupID;
      let allCoaList = [] as any;
      allCoaList = this.state.tableSelectedCOA;
      let filteredSubGroup2, countFromOne;
      if (e.groupID === -1)
        filteredSubGroup2 = allCoaList;
      else
        filteredSubGroup2 = allCoaList.filter((item) => item.subGroupID2 === e.groupID);

      if (this.state.fieldData.subGroupOneID !== -1) {
        if (e.groupID !== -1)
          filteredSubGroup2 = allCoaList.filter((item) => item.subGroupID2 === e.groupID && item.subGroupId === this.state.fieldData.subGroupOneID);
        else
          filteredSubGroup2 = allCoaList.filter((item) => item.subGroupId === this.state.fieldData.subGroupOneID);
        // countFromOne = allCoaList.filter((item) => item.subGroupId === this.state.fieldData.subGroupOneID);
        // countFromOne?.forEach(element => {
        //   filteredSubGroup2.push(element);
        // });
      }
      // else {
      //   allCoaList?.forEach(element => {
      //     filteredSubGroup2.push(element);
      //   });
      // }

      filteredSubGroup2 = _.uniqBy(filteredSubGroup2, 'coa');
      filteredSubGroup2 = _.sortBy(filteredSubGroup2, 'coa');

      this.setState({ fieldData, filteredSelectedCOA: filteredSubGroup2, selectedCOACount: filteredSubGroup2.length }, () => {
        if (ddCallType === "Change") {
          this.setState({ isActionFooter: true }, () => {
            this.props.changeValueFuntion(true);
          })
        }
      });
    }
  }

  //Thresold and notes change event
  onchangeInput(event: any, type: any) {
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    if (type === "notes") {
      fieldData.internalNotes = event.target.value;
    }
    else {
      const rx_live = /^[-]?\d*(?:[.]\d*)?$/;
      let val = event.target.value.replace(/[@\/\\^\/\\&\/\\#,+()$~%'":*?<>{}-]/g, '');
      if (rx_live.test(val)) {
        if (val.includes(".")) {
          const timeArray = (val)?.split(".");
          if (timeArray[0].length <= 8 && timeArray[1].length <= 2) {
            fieldData.threshold = val;
          }
        } else {
          if (val.length <= 8) {
            fieldData.threshold = val;
          }
        }
      } else if (val.includes(".") && val.length === 1) {
        fieldData.threshold = val;
      }
    }

    this.setState({ fieldData, isActionFooter: true }, () => {
      this.props.changeValueFuntion(true);
    });
  }

  handleAmountOnBlur = (e) => {
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    let val = e.target.value.replace(/[@\/\\^\/\\&\/\\#,+()$~%'":*?<>{}-]/g, '');
    if (val.includes(".")) {
      const timeArray = (val)?.split(".");
      if (timeArray[1].length <= 0) {
        let newVal = Number(timeArray[0]).toFixed(2);
        fieldData.threshold = Utils.amountFormatted(newVal);
      } else if (timeArray[1].length == 2) {
        let newVal: any = Number(val);
        newVal = newVal.toFixed(2);
        fieldData.threshold = Utils.amountFormatted(newVal);
      }
      else {
        let newVal: any = Number(val);
        newVal = newVal.toFixed(2);
        fieldData.threshold = Utils.amountFormatted(newVal);
      }
      this.setState({ fieldData })
    } else {
      //fieldData.threshold = val === '' ? '' : !isNaN(val) ? Number(val).toFixed(2) : 0;
      let newVal = val === '' ? '' : !isNaN(val) ? Number(val).toFixed(2) : 0;
      fieldData.threshold = Utils.amountFormatted(newVal);
      this.setState({ fieldData });
    }
  }

  //remove special char from invoice amnt thresold value
  handleOnFocus = (e) => {
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    let val = e.target.value.replace(/[@\/\\^\/\\&\/\\#,+()$~%'":*?<>{}-]/g, '');
    fieldData.threshold = val;
    this.setState({ fieldData });
  }

  handleDiscard = (e: any) => {
    this.setState({ isActionFooter: false }, () => {
      this.props.changeValueFuntion(false);
    });
    if (this.state.isNewApproval || this.state.isDuplicate) {
      this.setState({
        isTextBoxShow: false,
        tableDataPrimary: [],
        tableDataSecondary: [],
        roleListShowP: false,
        roleListShowS: false,
        roleListOpenFor: "",
        isRPanelHide: true,
        isNewApproval: false,
      }, () => {
        this.props.refreshleftpanel();
        this.props.removeBlankRow();
      })
    } else {
      const oldData = JSON.parse(
        sessionStorage.getItem("fieldDataIA")!
      );
      this.handleRowClick(oldData);
    }

  };

  // Fun to save the new approval or update the existing one rule
  handleSave = (e: any) => {
    let request = {} as any;
    if (this.state.fieldData.approvalName.trim() === "") {
      Utils.toastError("Please enter approval name.", {
      });
      return;
    }
    if (this.state.fieldData.approvalName?.toLowerCase().trim() === "standard approval" && this.state.fieldData.approvalType?.toLowerCase().trim() !== "standard") {
      Utils.toastError("Standard rule can not be created.", {
      });
      return;
    }
    if (this.state.fieldData.approvalType === "" || this.state.fieldData.approvalType === "Select rule type") {
      Utils.toastError("Please select approval type.", {
      });
      return;
    }

    if ((this.state.fieldData.threshold?.trim() === "" || this.state.fieldData.threshold?.trim() === "0.00" || Number(this.state.fieldData.threshold) === 0) && this.state.fieldData.approvalType === "Invoice Amount") {
      Utils.toastError("Please enter threshold value.", {
      });
      return;
    }

    if ((this.state.fieldData.coa === "") && this.state.fieldData.approvalType === "COA") {
      Utils.toastError("Please select a COA.", {
      });
      return;
    }

    // if ((this.state.fieldData.coa !== "") && this.state.fieldData.approvalType === "COA") {
    //   const inactiveCoaLen = this.state.coaSelect.filter((item) => item.status?.toLowerCase() === 'inactive');
    //   if (inactiveCoaLen === this.state.coaSelect.length) {
    //     Utils.toastError("All selected COAs are inactive.", {
    //     });
    //     return;
    //   }
    //   return;
    // }

    if ((this.state.fieldData.mainGroup === "" || this.state.fieldData.mainGroup === "Select Main Group") && this.state.fieldData.approvalType === "Department") {
      Utils.toastError("Please select main group.", {
      });
      return;
    }

    if ((this.state.fieldData.vendor === "") && this.state.fieldData.approvalType === "Vendor") {
      Utils.toastError("Please select a vendor.", {
      });
      return;
    }

    if ((this.state.fieldData.threshold === "" || this.state.fieldData.threshold === "0.00" || Number(this.state.fieldData.threshold) === 0) && this.state.fieldData.approvalType === "Invoice Amount") {
      Utils.toastError("Please enter threshold value.", {
      });
      return;
    }

    if (this.state.fieldData.primaryApprover === "") {
      Utils.toastError("Please select atleast one role for primary approvers.", {
      });
      return;
    }

    request.RuleID = this.state.fieldData.ruleID;
    request.RuleName = this.state.fieldData.approvalName.substring(0, 50)?.trim();
    request.AppType = this.state.fieldData.approvalType;
    request.Threshold = this.state.fieldData.approvalType.toLowerCase() === "standard" ? "" : this.state.fieldData.approvalType === "Vendor" ?
      this.state.fieldData.vendor : this.state.fieldData.approvalType === "COA" ? this.state.fieldData.coa :
        this.state.fieldData.approvalType === "Department" ? this.state.fieldData.mainGroupID + ','
          + this.state.fieldData.subGroupOneID + ',' + this.state.fieldData.subGroupTwoID :
          this.state.fieldData.threshold.replace(/[@\/\\^\/\\&\/\\#,+()$~%'":*?<>{}-]/g, '');

    request.InternalNote = this.state.fieldData.internalNotes;
    request.AppLavel1 = this.state.fieldData.primaryApprover.replace(/,*$/, '');
    request.AppLavel2 = this.state.fieldData.secondaryApprover.replace(/,*$/, '');

    console.log(request)
    this.setState({ isSaveDisaled: true, loaderTrue: true });
    InvoiceApprovalS.SaveUpdateInvoiceAppRule(request)
      .then(async (result: any | null) => {
        if (result != null) {
          let msg = "";
          let data = result.result as any;
          if (data.message.toLowerCase() === "success") {
            let requestRuleID = request.RuleID;

            if (requestRuleID === 0) {
              msg = "Setting saved successfully.";
            } else {
              msg = "Setting updated successfully";
            }
            toast.success(msg);

            if (requestRuleID === 0) {
              this.setState({
                isNewApproval: false,
                isRPanelHide: true,
                isRActions: true,
                isDuplicate: false,
                isActionFooter: false,
                coaSelect: [{}]
              }, () => {
                this.props.removeBlankRow();
              });
            } else {
              let new_state = Object.assign({}, this.state);
              let fieldData = new_state.fieldData;
              // if (this.state.fieldData.approvalType === "COA") {
              //   const inactiveCoaLen = this.state.coaSelect.filter((item) => item.status?.toLowerCase() === 'inactive');
              //   if (inactiveCoaLen === this.state.coaSelect.length) {
              //     fieldData.ruleStatus = "Inactive";
              //   }else{
              //     fieldData.ruleStatus = "";
              //   }
              // }
              if (this.state.isStatusChanged)
                fieldData.ruleStatus = "";
              this.getRowClickData(fieldData);

            }
            this.props.changeValueFuntion(false);
            this.props.refreshleftpanel();

          } else if (data.message.toLowerCase() === "ruleexist") {
            this.setState({ isSaveDisaled: false, loaderTrue: false, });
            Utils.toastError("This rule name already exist.", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else if (data.message.toLowerCase() === "coa" || data.message.toLowerCase() === "vendor" || data.message.toLowerCase() === "invoice amount") {
            this.setState({ isSaveDisaled: false, loaderTrue: false, });
            Utils.toastError(`A rule already exists with the same ${data.message}.`, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else if (data.message.toLowerCase() === "coainactive") {
            this.setState({ isSaveDisaled: false, loaderTrue: false, });
            Utils.toastError("Inactive COA.", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            this.filterVendorList();
          } else if (data.message.toLowerCase() === "vendorinactive") {
            this.setState({ isSaveDisaled: false, loaderTrue: false, });
            Utils.toastError("Inactive vendor.", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            this.filterVendorList();
          } else {
            this.setState({ isSaveDisaled: false, loaderTrue: false, });
            Utils.toastError(data.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
        this.setState({ isSaveDisaled: false, loaderTrue: false, });
        resolve();
      })
      .catch((error) => {
        this.setState({ loaderTrue: false, isSaveDisaled: false });
        reject();
      });
  };

  HideRightPanel() {
    this.setState({ isRPanelHide: true });
  }

  loadState() {
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    fieldData.ruleID = 0;
    fieldData.approvalName = "";
    fieldData.approvalType = "";
    fieldData.threshold = "";
    fieldData.internalNotes = "";
    fieldData.primaryApprover = "";
    fieldData.secondaryApprover = "";
    this.setState({
      fieldData,
      isNewApproval: true,
      isRPanelHide: true,
      tableDataPrimary: [],
      tableDataSecondary: [],
      defaultCOA: [],
      defaultVendor: [],
    });
  }

  // Fun to create the duplicate as the rule selected from left panel
  handleDuplicate = (event: any) => {
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    fieldData.approvalName = `Copy of ${this.state.fieldData.approvalName}`;
    fieldData.ruleID = 0;
    this.props.isDuplicateRow();

    if (fieldData.approvalType?.toLowerCase() === "coa") {
      fieldData.coa = "";
      let activeCOA = InvoiceApprovalRightPanel.filterCOAList(this.state.allCOAList, this.state.isConfiCOAPerm);
      let filterData = this.state.coaSelect.filter((item) => item.status.toLowerCase() === 'active');
      if (!this.state.isConfiCOAPerm) {
        filterData = filterData.filter((item) => item.isconfidentialcoa === '');
      }
      this.setState({ COAList: activeCOA });

      this.setState({ coaSelect: filterData }, () => {
        this.multiselectChild?.current?.updateSavedItem(filterData);
        let selectedCOA = "";
        filterData.forEach(element => {
          selectedCOA += element.chartNo + ","
        });
        fieldData.coa = selectedCOA.replace(/,(?=\s*$)/, '');
      });
    }
    else if (fieldData.approvalType?.toLowerCase() === "vendor" && this.state.isInactVendor) {
      fieldData.vendor = "";
      this.setState({ defaultVendor: [] })
    }
    this.setState(
      {
        isDuplicate: true,
        fieldData: fieldData,
        isRPanelHide: false,
        isActionFooter: true,
        isTextBoxShow: true,
      },
      () => {
        this.props.changeValueFuntion(true);
      }
    );
  };

  sortByKey = (array: any, key: any) => {
    return array.sort((a, b) => {
      let x = parseInt(a[key]);
      let y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  };

  //Fun to bimd the role dropdown where user can choose the primary & secondary role
  getRoleData = () => {

    this.setState({ loaderTrue: true });
    let RoleData = [];
    Roles.GetUserRoleList()
      .then(async (result: any[] | null) => {
        if (result != null) {
          result.map(
            (item, index) => (
              (item["isChecked"] = false),
              (item["isDisabled"] = false)
            )
          );

          //Remove low security role from list
          result = result.filter(
            (item) => item.roleName.toLowerCase() !== 'low security' && item.isViewInvoiceApproval.toLowerCase() == 'yes'
          );

          let roleData = result as [];

          roleData.map((res, index) => RoleData.push(res));
          this.sortByKey(RoleData, "orderNo");
          this.setState({ RolesList: RoleData, allRolesList: RoleData });
        }
        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error) => {
        this.setState({ loaderTrue: false });
        reject();
      });
  };

  showTextBox = (appType) => {
    if (appType?.toLowerCase() !== "standard")
      this.setState(
        { /*isActionFooter: true,*/ isTextBoxShow: true, },
        () => {
          //this.props.changeValueFuntion(true);
          $('#newRoleName').focus();
        }
      );
  };

  hideTextBox = () => {
    this.setState(
      { /*isActionFooter: false,*/ isTextBoxShow: false },
      () => {
        //this.props.changeValueFuntion(false);
      }
    );
  };

  //Rule name input chnage event
  handleRuleInput = (e: any) => {
    const { value } = e.target;
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    fieldData.approvalName = value;
    this.setState({ fieldData, isActionFooter: true }, () => {
      this.props.changeValueFuntion(true);
    });
  };

  renderTextBox = () => {
    if (this.state.isNewApproval || this.state.isTextBoxShow) {
      return (
        <OutsideClickHandler onOutsideClick={this.hideTextBox}>
          <span className="search-header mr-auto auto-complete d-flex">
            <Form.Control
              type="text"
              name="newRole"
              id="newRoleName"
              value={this.state.fieldData.approvalName}
              placeholder="Enter Name"
              onChange={this.handleRuleInput}
              maxLength={50}
              autoComplete="Off"
            />
            {this.state.fieldData.ruleStatus.toLowerCase() === "inactive" && !this.state.isDuplicate && !this.state.isNewApproval && (
              <span className="red-badges gray-bg" style={{ marginTop: 8 }}>{this.state.fieldData.ruleStatus}</span>
            )}
          </span>
        </OutsideClickHandler>
      );
    }
  };

  //Fun to open up the roles dropdown with disabled mode whose selected from other approval type 
  toggleDropdown(state, callType) {
    let { tableDataPrimary, tableDataSecondary, allRolesList, RolesList } = this.state;

    RolesList.map(
      (item, index) => (
        (item["isChecked"] = false),
        (item["isDisabled"] = false)
      )
    );

    if (callType === "P")
      this.setState({ roleListShowP: !state, roleListShowS: false }, () => {
        RolesList.map((items, index) => (
          tableDataPrimary.forEach((ccd: any, idx: any) => {
            if (ccd.roleID === items.roleID?.toString()) {
              items.isChecked = true;
            }
          })
        ));
        // For Disable The selected 
        RolesList.map((items, index) => (
          tableDataSecondary.forEach((ccd: any, idx: any) => {
            if (ccd.roleID === items.roleID?.toString()) {
              items.isDisabled = true;
            }
          })
        ));
      });
    if (callType === "S")
      this.setState({ roleListShowS: !state, roleListShowP: false }, () => {
        RolesList.map((items, index) => (
          tableDataSecondary.forEach((ccd: any, idx: any) => {
            if (ccd.roleID === items.roleID?.toString()) {
              items.isChecked = true;
            }
          })
        ));
        // For Disable The selected 
        RolesList.map((items, index) => (
          tableDataPrimary.forEach((ccd: any, idx: any) => {
            if (ccd.roleID === items.roleID?.toString()) {
              items.isDisabled = true;
            }
          })
        ));
      });
    setTimeout(() => {
      this.setState({ tableDataPrimary, tableDataSecondary, RolesList, roleListOpenFor: callType })
    }, 0);
  }

  // Filter fun for filter the role list
  searchRoleItems = (event: any) => {
    const { RolesList, allRolesList } = this.state;
    let result = event.target.value;
    this.setState({ roleFilter: result });
    if (result !== "") {
      this.setState({
        RolesList: allRolesList.filter((items) =>
          items.roleName.toLowerCase().includes(result.toLowerCase().trim())
        ),
      });
    } else {
      this.setState({ RolesList: this.state.allRolesList });
    }
  };

  // Fun for role list for primary approver
  renderRolesDDP = () => {
    if (this.state.roleListShowP)
      return (
        <div className="roleListIA">
          <Dropdown show={this.state.roleListShowP} onToggle={() => this.toggleDropdown(this.state.roleListShowP, "P")} className="more-action bg-blue dropdown rolePermissiondropdown">
            <Dropdown.Toggle id="rolesList" className="toggleBtnIA"
            >

            </Dropdown.Toggle>
            <OutsideClickHandler
              onOutsideClick={() => {
                this.setState({ roleFilter: "", RolesList: this.state.allRolesList })
              }}
            >
              <Dropdown.Menu className="rolePermissionSelector IARoleList">
                <div className="roleFilter">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Control
                      type="text"
                      name="searchRoles"
                      value={this.state.roleFilter}
                      autoComplete="off"
                      onChange={this.searchRoleItems}
                      placeholder="Filter Roles"
                    />
                  </Form.Group>
                </div>
                <div className="dropdownItems">
                  {this.state.RolesList.length > 0 &&
                    this.state.RolesList.map((items, index) => (
                      <div
                        className="dropdown-item"
                        key={index}
                      >
                        <div>
                          <EllipsisWithTooltip placement="bottom">
                            <Form.Check
                              type="checkbox"
                              id={`list_${items.roleID}`}
                              name={items.roleName}
                              label={items.roleName}
                              value={items.roleID}
                              onChange={(event) =>
                                items.isDisabled ? null : this.handleChangeRole(event, items)
                              }
                              disabled={items.isDisabled}
                              checked={items.isChecked}
                            />
                          </EllipsisWithTooltip>
                        </div>
                      </div>
                    ))}
                </div>
              </Dropdown.Menu>
            </OutsideClickHandler>
          </Dropdown>
        </div>
      )
  }

  //Fun for role list for Secondary approver
  renderRolesDDS = () => {
    if (this.state.roleListShowS)
      return (
        <div className="roleListIA">
          <Dropdown show={this.state.roleListShowS} onToggle={() => this.toggleDropdown(this.state.roleListShowS, "S")} className="more-action bg-blue dropdown rolePermissiondropdown">
            <Dropdown.Toggle id="rolesList" className="toggleBtnIA"
            >

            </Dropdown.Toggle>
            <OutsideClickHandler
              onOutsideClick={() => {
                this.setState({ roleFilter: "", RolesList: this.state.allRolesList })
              }}
            >
              <Dropdown.Menu className="rolePermissionSelector IARoleList">
                <div className="roleFilter">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Control
                      type="text"
                      name="searchRoles"
                      value={this.state.roleFilter}
                      autoComplete="off"
                      onChange={this.searchRoleItems}
                      placeholder="Filter Roles"
                    />
                  </Form.Group>
                </div>
                <div className="dropdownItems">
                  {this.state.RolesList.length > 0 &&
                    this.state.RolesList.map((items, index) => (
                      <div
                        className="dropdown-item"
                        key={index}
                      >
                        <div>
                          <EllipsisWithTooltip placement="bottom">
                            <Form.Check
                              type="checkbox"
                              id={`list_${items.roleID}`}
                              name={items.roleName}
                              label={items.roleName}
                              value={items.roleID}
                              onChange={(event) =>
                                items.isDisabled ? null : this.handleChangeRole(event, items)
                              }
                              disabled={items.isDisabled}
                              checked={items.isChecked}
                            />
                          </EllipsisWithTooltip>
                        </div>
                      </div>
                    ))}
                </div>
              </Dropdown.Menu>
            </OutsideClickHandler>
          </Dropdown>
        </div>
      )
  }

  // Change event for selecting a role from DD
  handleChangeRole = (event, items) => {
    let id = event.target.value;
    let isChecked = event.target.checked;
    this.changeDeleteRoles(id, isChecked, "");
  };

  //Fun to delete a selected role from primary or secondary table
  deleteCurrentRow = (row: any, tableType) => {
    this.setState({ roleListOpenFor: tableType });
    setTimeout(() => {
      this.changeDeleteRoles(row.roleID, false, tableType);
    }, 0);
  }

  //Fun to used add or delete the role as primary or secondary approval
  changeDeleteRoles = (id, isChecked, tableType) => {
    let { tableDataPrimary, tableDataSecondary, allRolesList, RolesList } = this.state;

    const roleInfo = this.state.RolesList.filter((item) => item.roleID === id)
    let lst = {} as any;
    lst.roleName = roleInfo[0].roleName;
    lst.roleID = roleInfo[0].roleID;

    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;

    if (this.state.roleListOpenFor === "P" || tableType === "P") {
      fieldData.primaryApprover = "";
      if (isChecked)
        tableDataPrimary.push(lst);
      else
        tableDataPrimary = tableDataPrimary.filter(x => x.roleID !== id);

      this.setState({ tableDataPrimary });

      setTimeout(() => {
        this.setState({ tableDataPrimary, RolesList, allRolesList }, () => {
          if (tableDataPrimary.length > 0)
            allRolesList.map((items, index) => (
              tableDataPrimary.forEach((ccd: any, idx: any) => {
                if (ccd.roleID === items.roleID?.toString()) {
                  items.isChecked = true;
                  fieldData.primaryApprover += ccd.roleID + ","
                }
                else if (items.roleID?.toString() === id) {
                  items.isChecked = isChecked;
                }
              })
            ));
          else
            RolesList.map((items, index) => (
              items.isChecked = isChecked
            ));
        });
        this.setState({ tableDataPrimary, RolesList, fieldData, isActionFooter: true, allRolesList }, () => {
          this.props.changeValueFuntion(true);
        });
      }, 0);
    } else if (this.state.roleListOpenFor === "S" || tableType === "S") {
      fieldData.secondaryApprover = "";

      if (isChecked)
        tableDataSecondary.push(lst);
      else
        tableDataSecondary = tableDataSecondary.filter(x => x.roleID !== id);

      this.setState({ tableDataSecondary });

      setTimeout(() => {
        this.setState({ tableDataSecondary, RolesList, allRolesList }, () => {
          if (tableDataSecondary.length > 0)
            allRolesList.map((items, index) => (
              tableDataSecondary.forEach((ccd: any, idx: any) => {
                if (ccd.roleID === items.roleID?.toString()) {
                  items.isChecked = true;
                  fieldData.secondaryApprover += ccd.roleID + ","
                }
                else if (items.roleID?.toString() === id) {
                  items.isChecked = isChecked;
                }
              })
            ));
          else
            RolesList.map((items, index) => (
              items.isChecked = isChecked
            ));
        });
        this.setState({ tableDataSecondary, RolesList, fieldData, isActionFooter: true, allRolesList }, () => {
          this.props.changeValueFuntion(true);
        });
      }, 0);
    }
  }

  // Typeahed event for COA and vendor
  handleSelectedItem = (event: any, type: any) => {
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;

    if (type === "coa") {
      if (event.length > 0) {
        let defaultCOA: any = [];
        let objCOA: any = {};
        objCOA.id = event[0].id;
        objCOA.label = event[0].label;
        fieldData.coa = event[0].id;
        defaultCOA.push(objCOA);
        this.setState(
          {
            fieldData,
            defaultCOA: defaultCOA,
          });
      } else {
        fieldData.coa = "";
        this.setState({
          defaultCOA: [],
          fieldData,
        });
      }
    }
    else {
      if (event.length > 0) {
        let defaultVen: any = [];
        let objveN: any = {};
        objveN.id = event[0].id;
        objveN.label = event[0].label;
        fieldData.vendor = event[0].label;
        defaultVen.push(objveN);
        this.setState(
          {
            fieldData,
            defaultVendor: defaultVen,
          });
      } else {
        fieldData.vendor = "";
        this.setState({
          defaultVendor: []
        });
      }
    }
    this.setState({ fieldData, isActionFooter: true, }, () => {
      this.props.changeValueFuntion(true);
    });
  };

  //Filter the Inactive vendor list so that the user can't select inactive vendro from the list
  filterVendorListOnFocus = () => {
    let { vendorList } = this.state;
    if (this.state.isInactVendor) {
      const filterVendorList = _.filter(vendorList, function (o) {
        return o.status.trim().toLowerCase() !== 'inactive';
      });
      let new_state = Object.assign({}, this.state);
      let fieldData = new_state.fieldData;
      fieldData.vendor = "";
      this.setState({ fieldData, vendorList: filterVendorList, defaultVendor: [], isStatusChanged: true, isActionFooter: true }, () => {
        this.props.changeValueFuntion(true);
      });
    }
  }

  deleteRule = (eventType, ruleIds, approvalList: any) => {
    let request: any = {};
    if (eventType === "multiple") {
      let allRuleIds = "";
      let filterInactive: any;
      if (this.state.isConfiCOAPerm) {
        filterInactive = approvalList.filter((item) => item.status.toLowerCase() === "inactive")
      } else {
        filterInactive = approvalList.filter((item) => item.status.toLowerCase() === "inactive" && item.isconfidentialcoa.toLowerCase() === "no")
      }
      if (filterInactive.length > 0) {
        for (let index = 0; index < filterInactive.length; index++) {
          allRuleIds += filterInactive[index].ruleID + ",";
        }
        request.RuleIDs = allRuleIds.replace(/,(?=\s*$)/, '');
      } else {
        Utils.toastError('No inactive rule found to delete.');
        return;
      }
    }
    else {
      request.RuleIDs = String(this.state.fieldData.ruleID);
    }
    console.log(request)

    confirmAlert({
      title: "Confirm to Delete",
      message: eventType === "multiple" ? "Are you sure you want to delete all inactive rules?" : "Are you sure you want to delete this rule?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.deleteRuleYes(request),
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
    });
  }

  //Fun to delete the inactive rules (Only mark as inactive in DB)
  deleteRuleYes = (request: any) => {
    this.setState({
      loaderTrue: true,
    });
    InvoiceApprovalS.deleteRule(request)
      .then(async (result: any | null) => {
        if (result.message === "success") {
          toast.success("Rule(s) deleted successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          this.setState({
            isNewApproval: false,
            isRPanelHide: true,
            isRActions: true,
            isDuplicate: false,
            isActionFooter: false,
          }, () => {
            this.props.changeValueFuntion(false);
            this.props.refreshleftpanel();
            this.props.removeBlankRow();
          });
        } else {
          Utils.toastError("Something went wrong.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        this.setState({
          loaderTrue: false,
        });
        resolve();
      })
      .catch((error) => {
        reject();
        this.setState({
          loaderTrue: false,
        });
      });
  }

  handleNoClick = () => { }
  dataChange = () => { };

  updateMultiSelectItem = (coaSelectedItem: any) => {
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    let allCOAs = "";
    for (let index = 0; index < coaSelectedItem.length; index++) {
      allCOAs += coaSelectedItem[index].id + ',';
    }
    fieldData.coa = allCOAs.replace(/,(?=\s*$)/, '');
    let filteredActiveCOA = this.state.allCOAList.filter((item) => item.status === 'active');

    let newItem = {} as any;
    coaSelectedItem.forEach(element => {

      newItem.value = element.value;
      newItem.chartNo = element.chartNo;
      newItem.chartName = element.chartName;
      newItem.description = element.description;
      newItem.isChecked = true;
      newItem.id = element.id;
      newItem.isCOAPer = element.isCOAPer;
      newItem.coaName = element.coaName;
      newItem.status = element.status;
      newItem.isconfidentialcoa = element.isconfidentialcoa;
      filteredActiveCOA.push(newItem);
    });

    filteredActiveCOA = _.uniqBy(filteredActiveCOA, 'chartNo');

    this.setState({ fieldData, isActionFooter: true, coaSelect: coaSelectedItem, isStatusChanged: true, COAList: filteredActiveCOA }, () => {
      this.props.changeValueFuntion(true);
    });
  };

  showHideTable = () => {
    this.setState({ isSelectedCOATableShow: !this.state.isSelectedCOATableShow });
  }

  render() {
    const approvalTypeSelect = (eventKey: any) => {
      this.setState({ approvalType: eventKey }, () => {
        this.filterVendorList();
      });
    };

    let isPermissionForEdit = true;
    if (this.state.fieldData.approvalType === "COA") {
      let isConfiFound = this.state.coaSelect.some((item: any) => item.isconfidentialcoa !== "");
      if (!this.state.isConfiCOAPerm && isConfiFound) {
        isPermissionForEdit = false;
      }
    }

    const renderItemChildren = (option, props, index) => {
      return (
        <>
          <div key={option.id}>
            <div className="vendor-name">
              <EllipsisWithTooltip placement="bottom">
                {option.label}
              </EllipsisWithTooltip>
            </div>
          </div>
        </>
      );
    };

    columnsPrimary = [
      {
        dataField: "roleName",
        text: "Role",
      }, {
        dataField: "action",
        text: "",
        editable: false,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="DeleteRow">Delete Role</Tooltip>}
            >
              <button type="button"
                tabIndex={-1}
                onClick={(e) => { this.deleteCurrentRow(row, "P") }}
                className="btn btn-primary fa-trash"
              >
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="trashSVGIcon">
                  <path d="M3.75226 15C3.75226 15.8273 4.42501 16.5 5.25226 16.5H12.7523C13.5795 16.5 14.2523 15.8273 14.2523 15V6H15.7523V4.5H13.5023H12.7523V3C12.7523 2.17275 12.0795 1.5 11.2523 1.5H6.75226C5.92501 1.5 5.25226 2.17275 5.25226 3V4.5H4.50226H2.25226V6H3.75226V15ZM6.75226 3H11.2523V4.5H6.75226V3ZM6.00226 6H12.0023H12.7523L12.753 15H5.25226V6H6.00226Z"></path><path d="M6.75226 7.5H8.25226V13.5H6.75226V7.5ZM9.75226 7.5H11.2523V13.5H9.75226V7.5Z"></path>
                </svg>
              </button>
            </OverlayTrigger>
          );
        },
      },
    ];

    columnsSecondary = [
      {
        dataField: "roleName",
        text: "Role",
      }, {
        dataField: "action",
        text: "",
        editable: false,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="DeleteRow">Delete Role</Tooltip>}
            >
              <button type="button"
                onClick={(e) => { this.deleteCurrentRow(row, "S") }}
                className="btn btn-primary fa-trash"
              >
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="trashSVGIcon">
                  <path d="M3.75226 15C3.75226 15.8273 4.42501 16.5 5.25226 16.5H12.7523C13.5795 16.5 14.2523 15.8273 14.2523 15V6H15.7523V4.5H13.5023H12.7523V3C12.7523 2.17275 12.0795 1.5 11.2523 1.5H6.75226C5.92501 1.5 5.25226 2.17275 5.25226 3V4.5H4.50226H2.25226V6H3.75226V15ZM6.75226 3H11.2523V4.5H6.75226V3ZM6.00226 6H12.0023H12.7523L12.753 15H5.25226V6H6.00226Z"></path><path d="M6.75226 7.5H8.25226V13.5H6.75226V7.5ZM9.75226 7.5H11.2523V13.5H9.75226V7.5Z"></path>
                </svg>
              </button>
            </OverlayTrigger>
          );
        },
      },
    ];

    columnsSelectedCOA = [
      // {
      //   dataField: "",
      //   text: "Sr",
      //   formatter: (cell: any, row: any, rowIndex: any) => {
      //     return (
      //       <>{rowIndex + 1}</>
      //     )

      //   }
      // },
      {
        dataField: "coa",
        text: "COA",
      },
      // {
      //   dataField: "action",
      //   text: "Exclude",
      //   editable: false,
      //   formatter: (cell: any, row: any, rowIndex: any) => {
      //     return (
      //       <Form.Check
      //         type="switch"
      //         className="sm-controll toggleButtonRP"
      //         id="custom-switch1"
      //         style={{ marginTop: 1 }}
      //         label=""
      //         checked={row.isExclude}
      //       />
      //     );
      //   },
      // },
    ];

    return (
      <>
        {!this.state.isRPanelHide && (
          <div className="right-panel-IA">
            {this.state.loaderTrue && (
              <div className="matrixSpinnerCenter">
                <Spinner animation="border" variant="secondary" />
              </div>
            )}
            <div
              className={isPermissionForEdit ? "pos-header d-flex align-content-center flex-wrap" : "pos-header d-flex align-content-center flex-wrap"} /* disabled-area */ >
              <div className="search-header mr-auto auto-complete">
                {this.renderTextBox()}
                {!this.state.isNewApproval && !this.state.isTextBoxShow && (
                  <span className="pos-ttl mr-auto" onClick={() => { this.showTextBox(this.state.fieldData.approvalType) }}>
                    <span className="d-flex">
                      <EllipsisWithTooltip placement="bottom">
                        {this.state.fieldData.approvalName}

                      </EllipsisWithTooltip>
                      {this.state.fieldData.ruleStatus.toLowerCase() === "inactive" && !this.state.isDuplicate && (
                        <span className="red-badges gray-bg" style={{ marginTop: 9 }}>{this.state.fieldData.ruleStatus}</span>
                      )}
                    </span>
                  </span>
                )}

              </div>

              {!this.state.isNewApproval && !this.state.isDuplicate && this.state.fieldData.approvalType.toLowerCase() !== "standard" && (
                <>
                  <Dropdown className="more-action" alignRight>
                    <Dropdown.Toggle
                      className="btn-outline-primary btn btn-primary more"
                      id="dropdown-more"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={this.handleDuplicate}>
                        <img src={Duplicate} alt="Duplicate" /> Duplicate
                      </Dropdown.Item>
                      {isPermissionForEdit && (
                        <Dropdown.Item onClick={(e) => { this.deleteRule("single", "0", "") }}>
                          <img src={Delete} alt="Delete" /> Delete
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              )}

            </div>
            <div id="IARightBody"
              className={
                this.state.isActionFooter ? "pos-body dataChange" : "pos-body"
              }
            >
              <Form>
                {this.state.fieldData.approvalType.toLowerCase() !== "standard" && (
                  <>
                    <Form.Group as={Row} controlId="appType">
                      <Form.Label column sm="3">
                        Approval Type
                      </Form.Label>
                      <Col sm="7">
                        <Dropdown onSelect={approvalTypeSelect}>
                          <Dropdown.Toggle id="dropdown-approvalType"
                          >
                            {this.state.fieldData.approvalType ===
                              "Select rule type" ? (
                              <span className="placeholder">
                                {this.state.fieldData.approvalType}
                              </span>
                            ) : <>{this.state.fieldData.approvalType}</>}

                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {this.state.approvalTypeList.map(
                              (item: any, idx: any) => (
                                <Dropdown.Item
                                  eventKey={item.dataFrom}
                                  onClick={() =>
                                    this.approvalTypeDDChange(item)
                                  }
                                >
                                  {item.dataFrom}
                                  {item.status && (<span className="IA-ItemCheck">
                                    <svg
                                      width="20"
                                      height="20"
                                      viewBox="0 0 20 20"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
                                        fill="#2CAF92"
                                      />
                                    </svg>
                                  </span>)}
                                </Dropdown.Item>
                              )
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    </Form.Group>

                    {this.state.fieldData.approvalType === "Invoice Amount" && (
                      <Form.Group as={Row} controlId="Threshold">
                        <Form.Label column sm="3">
                          Threshold
                        </Form.Label>
                        <Col sm="3">
                          <Form.Control
                            className="IA-threshold removeBorder"
                            value={this.state.fieldData.threshold}
                            onChange={(e) => this.onchangeInput(e, 'threshold')}
                            placeholder={"Enter threshold"}
                            pattern="[+-]?\d+(?:[.,]\d+)?"
                            onBlur={this.handleAmountOnBlur}
                            onFocus={this.handleOnFocus}
                            maxLength={12}
                            autoComplete="Off"
                          />
                        </Col>
                      </Form.Group>
                    )}
                    {this.state.fieldData.approvalType === "COA" && (
                      <Form.Group as={Row} controlId="COA" >
                        <Form.Label column sm="3">
                          COA
                        </Form.Label>
                        <Col sm="7" className="more-action single-select-image dropdown coa-select jecoaSelect IATypeheaddropdown">
                          <MultiSelectSearch
                            ref={this.multiselectChild}
                            itemList={this.state.COAList}
                            updateMultiSelectItem={this.updateMultiSelectItem.bind(
                              this
                            )}
                            dataChange={this.dataChange.bind(this)}
                            isSelectAll={false}
                            filtertitle={"Filter COAs"}
                            pageName="InvoiceApproval"
                            isConfiCOAPerm={this.state.isConfiCOAPerm}
                          />
                        </Col>
                      </Form.Group>
                    )}
                    {this.state.fieldData.approvalType === "Vendor" && (
                      <Form.Group as={Row} controlId="Vendor">
                        <Form.Label column sm="3">
                          Vendor
                        </Form.Label>
                        <Col sm="7" className="more-action single-select-image dropdown coa-select jecoaSelect IATypeheaddropdown">
                          <Typeahead
                            inputProps={{
                              className: "ConditionalInput",
                            }}
                            id="vendorSelected"
                            options={this.state.vendorList}
                            placeholder={"Select vendor"}
                            renderMenu={(results: any, menuProps) => (
                              <Menu {...menuProps}>
                                {results.map((result: any, index: any) => (
                                  <>
                                    <MenuItem option={result} position={index}>
                                      <EllipsisWithTooltip placement="bottom">
                                        {result.label}
                                      </EllipsisWithTooltip>
                                    </MenuItem>
                                    {result.label == "" && (
                                      <MenuItem
                                        className="load-more"
                                        option={result}
                                        position={index}
                                      >
                                        Load More
                                      </MenuItem>
                                    )}
                                    {this.state.vendorList.length > 0 &&
                                      results[index - 1]?.orderno === 1 &&
                                      result?.orderno === 2 && <Dropdown.Divider />}
                                  </>
                                ))}
                              </Menu>
                            )}
                            renderMenuItemChildren={renderItemChildren}
                            onChange={(e) => this.handleSelectedItem(e, "Vendor")}
                            selected={this.state.defaultVendor}
                            onFocus={this.filterVendorListOnFocus}
                          />
                        </Col>
                      </Form.Group>
                    )}
                    {this.state.fieldData.approvalType === "Department" && (
                      <React.Fragment>
                        <Form.Group as={Row} controlId="departMent">
                          <Form.Label column sm="3">
                            Main Group
                          </Form.Label>
                          <Col sm="7">
                            <Dropdown>
                              <Dropdown.Toggle id="dropdown-Department"
                              >
                                {this.state.fieldData.mainGroup ===
                                  "Select Main Group" ? (
                                  <span className="placeholder">
                                    {this.state.fieldData.mainGroup}
                                  </span>
                                ) : <>{this.state.fieldData.mainGroup}</>}

                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {this.state.mainGroupList.map(
                                  (item: any, idx: any) => (
                                    <Dropdown.Item
                                      eventKey={item.groupName}
                                      onClick={() =>
                                        this.mainGroupDDChange(item, 'mainGroup', 'Change')
                                      }
                                    >
                                      <EllipsisWithTooltip placement="bottom">
                                        {item.groupName}
                                      </EllipsisWithTooltip>
                                    </Dropdown.Item>
                                  )
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </Col>
                        </Form.Group>
                        {this.state.fieldData.mainGroup !== "Select Main Group" && (
                          <React.Fragment>
                            <Form.Group as={Row} controlId="subGrp1">
                              <Form.Label column sm="3">
                                Sub Group 1
                              </Form.Label>
                              <Col sm="7">
                                <Dropdown>
                                  <Dropdown.Toggle id="dropdown-SubGrp1"
                                  >
                                    {this.state.fieldData.subGroupOne ===
                                      "All" ? (
                                      <span className="placeholder">
                                        {this.state.fieldData.subGroupOne}
                                      </span>
                                    ) : <>{this.state.fieldData.subGroupOne}</>}

                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {this.state.subGroupOneList.map(
                                      (item: any, idx: any) => (
                                        <Dropdown.Item
                                          eventKey={item.groupName}
                                          onClick={() =>
                                            this.mainGroupDDChange(item, 'sub1', 'Change')
                                          }
                                        >
                                          <EllipsisWithTooltip placement="bottom">
                                            {item.groupName}
                                          </EllipsisWithTooltip>
                                        </Dropdown.Item>
                                      )
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="subGrp2">
                              <Form.Label column sm="3">
                                Sub Group 2
                              </Form.Label>
                              <Col sm="7">
                                <Dropdown>
                                  <Dropdown.Toggle id="dropdown-SubGrp2"
                                  >
                                    {this.state.fieldData.subGroupTwo ===
                                      "All" ? (
                                      <span className="placeholder">
                                        {this.state.fieldData.subGroupTwo}
                                      </span>
                                    ) : <>{this.state.fieldData.subGroupTwo}</>}

                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {this.state.subGroupTwoList.map(
                                      (item: any, idx: any) => (
                                        <Dropdown.Item
                                          eventKey={item.groupName}
                                          onClick={() =>
                                            this.mainGroupDDChange(item, 'sub2', 'Change')
                                          }
                                        >
                                          <EllipsisWithTooltip placement="bottom">
                                            {item.groupName}
                                          </EllipsisWithTooltip>
                                        </Dropdown.Item>
                                      )
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </Col>
                            </Form.Group>
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    )}
                    <Form.Group as={Row} controlId="notes">
                      <Form.Label column sm="3">
                        Internal Note
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          className="removeBorder"
                          as="textarea"
                          value={this.state.fieldData.internalNotes}
                          onChange={(e) => this.onchangeInput(e, 'notes')}
                          placeholder={"Add internal note"}
                          maxLength={200}
                        />
                      </Col>
                    </Form.Group>
                    {this.state.fieldData.mainGroup !== "Select Main Group" && this.state.fieldData.approvalType === "Department" && (
                      <Form.Group as={Row} controlId="subGrp2">
                        <Form.Label column sm="6">
                          <div className="upload-link">
                            <button type="button" className="btn wht-bg link-btn mr-auto" onClick={this.showHideTable}>
                              {this.state.isSelectedCOATableShow ? "Hide" : "Show"} {this.state.selectedCOACount} Selected COAs
                            </button>
                          </div>
                        </Form.Label>
                      </Form.Group>
                    )}
                    {this.state.isSelectedCOATableShow && (
                      <div className="selectedCOAList-IA">
                        <ToolkitProvider
                          keyField="coaID"
                          data={this.state.filteredSelectedCOA}
                          columns={columnsSelectedCOA}
                        >
                          {(props: {
                            searchProps: JSX.IntrinsicAttributes;
                            baseProps: JSX.IntrinsicAttributes;
                          }) => (
                            <div className="pos-rel">
                              <div className="cusPos">
                                <div className="IA-SelCOAlist depart">
                                  <div>
                                    <BootstrapTable
                                      {...props.baseProps}
                                      keyField="coaID"
                                      noDataIndication={'No record found.'}
                                      hover
                                      condensed
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </ToolkitProvider>
                      </div>
                    )}
                    <div className="border-line-IA"></div>
                  </>
                )}

                <div className="tableLabel mrgn-tp5">Primary Approvers <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id="button-tooltip-helpText">Primary approvers will be able to approve this rule, which will appear in the “Waiting on Me” tab within the Invoice Approvals page.</Tooltip>}
                >
                  <span className="help-icon">
                    <svg
                      stroke="currentColor"
                      fill="#6a6e73"
                      stroke-width="0"
                      version="1.1"
                      viewBox="0 0 16 16"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M7 4.75c0-0.412 0.338-0.75 0.75-0.75h0.5c0.412 0 0.75 0.338 0.75 0.75v0.5c0 0.412-0.338 0.75-0.75 0.75h-0.5c-0.412 0-0.75-0.338-0.75-0.75v-0.5z"></path>
                      <path d="M10 12h-4v-1h1v-3h-1v-1h3v4h1z"></path>
                      <path d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM8 14.5c-3.59 0-6.5-2.91-6.5-6.5s2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5-2.91 6.5-6.5 6.5z"></path>
                    </svg>{" "}
                  </span>
                </OverlayTrigger>
                  <div className={isPermissionForEdit ? "body-sec position dept-pos IA-table" : "body-sec position dept-pos IA-table"} /* disabled-area*/>
                    <ToolkitProvider
                      keyField="deptID"
                      data={this.state.tableDataPrimary}
                      columns={columnsPrimary}
                    >
                      {(props: {
                        searchProps: JSX.IntrinsicAttributes;
                        baseProps: JSX.IntrinsicAttributes;
                      }) => (
                        <div className="pos-rel">
                          <div className="cusPos">
                            <div className="IA-list depart">
                              <div>
                                <BootstrapTable
                                  {...props.baseProps}
                                  keyField="deptID"
                                  noDataIndication={'No role added.'}
                                  hover
                                  condensed
                                />
                              </div>
                              <div className="pos-rel">
                                <button
                                  type="button"
                                  className="btn wht-bg txt-green rm-pad"
                                  onClick={(e) => { this.toggleDropdown(this.state.roleListShowP, "P") }}
                                >
                                  Add Role
                                </button>

                                {this.renderRolesDDP()}
                              </div>

                            </div>
                          </div>
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </div>

                <div className="tableLabel">Secondary Approvers <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id="button-tooltip-helpText">Secondary Approvers have the ability to approve this rule, but will not be notified to do so.  Use this for any role that can approve the rule, but is not their main responsibility.</Tooltip>}
                >
                  <span className="help-icon">
                    <svg
                      stroke="currentColor"
                      fill="#6a6e73"
                      stroke-width="0"
                      version="1.1"
                      viewBox="0 0 16 16"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M7 4.75c0-0.412 0.338-0.75 0.75-0.75h0.5c0.412 0 0.75 0.338 0.75 0.75v0.5c0 0.412-0.338 0.75-0.75 0.75h-0.5c-0.412 0-0.75-0.338-0.75-0.75v-0.5z"></path>
                      <path d="M10 12h-4v-1h1v-3h-1v-1h3v4h1z"></path>
                      <path d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM8 14.5c-3.59 0-6.5-2.91-6.5-6.5s2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5-2.91 6.5-6.5 6.5z"></path>
                    </svg>{" "}
                  </span>
                </OverlayTrigger>
                  <div className={isPermissionForEdit ? "body-sec position dept-pos IA-table" : "body-sec position dept-pos IA-table"}>
                    <ToolkitProvider
                      keyField="deptID"
                      data={this.state.tableDataSecondary}
                      columns={columnsSecondary}
                    >
                      {(props: {
                        searchProps: JSX.IntrinsicAttributes;
                        baseProps: JSX.IntrinsicAttributes;
                      }) => (
                        <div className="pos-rel">
                          <div className="cusPos">
                            <div className="IA-list depart">
                              <div>
                                <BootstrapTable
                                  {...props.baseProps}
                                  keyField="deptID"
                                  noDataIndication={'No role added.'}
                                  hover
                                  condensed
                                />
                              </div>
                              <div className="pos-rel">
                                <button
                                  type="button"
                                  className="btn wht-bg txt-green rm-pad"
                                  onClick={(e) => { this.toggleDropdown(this.state.roleListShowS, "S") }}
                                >
                                  Add Role
                                </button>

                                {this.renderRolesDDS()}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </div>
              </Form>

              {this.state.isActionFooter && (
                <div className="fixed-action">
                  <div className="d-flex align-content-center flex-wrap">
                    <div className="mr-auto message">
                      <span>You have unsaved changes</span>
                    </div>
                    <button
                      type="button"
                      className="btn btn-primary btn-discard"
                      onClick={this.handleDiscard}
                    >
                      Discard
                    </button>

                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={this.handleSave}
                      disabled={this.state.isSaveDisaled}
                    >
                      {this.state.isNewApproval || this.state.isDuplicate ? "Save Rule" : "Save Approval"}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </>
    );
  }
}
