import { GridOptions } from "ag-grid-community";
import { AgGridReact, ChangeDetectionStrategyType } from "ag-grid-react";
import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from "react";
import { DropDownList } from "../Reports/common-components/dropdown-list";
import { PeriodSelector } from "./Components/PeriodSelector";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { ReactPageLoader } from "../../Common/Components/ReactPageLoader";
import {
  Breadcrumb,
  Dropdown,
  Form,
  FormLabel,
  Accordion,
  Card,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { LaborDashBoard } from "../../Common/Services/LaborDashBoard";
import { Utils } from "../../Common/Utilis";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import _ from "lodash";
import LaborCustomHeader from "./LaborCustomHeader";
import laborIcon from "../../Common/Assets/Images/icons/labor-icon-2.svg";
import { LaborDashboardChart } from "./LaborDashboardChart";
import "../../Common/Assets/Styles/Modules/labor-dashboard.scss";
import { PerformanceSlideout } from "../LaborManagement/Labor/PerformanceViews/PerformanceSlideout";
import { SortSelector } from "./Components/SortSelector";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import OutsideClickHandler from "react-outside-click-handler";
import profile from "../../Common/Assets/Images/profile.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faClose,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import ContentLoader from "react-content-loader";
import { HotelGroupSelector } from "./Components/HotelGroupSelector";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { TableColumnSelector } from "./Components/Common-Components/TableColumnSelector";

const MAXLIMIT = 10;
const sortDropdDownData: any[] = [
  { label: "Column Sort", value: "Column Sort" },
  { label: "Sort by Variance", value: "Variance" },
  { label: "Sort by Value", value: "Value" },
];

//Dropdown Values
const dataComparisonList: any[] = [
  { label: "Last Year Variance", value: 1, type: 1 },
  { label: "Budget Variance", value: 2, type: 1 },
];

const getPeriods = (date = new Date()) => {
  return [
    { label: `Daily (${moment(date).format("MM/DD")})`, value: 1 },
    {
      label: `Last 7 Days (${moment(date)
        .subtract(6, "days")
        .format("MM/DD")} - ${moment(date).format("MM/DD")})`,
      value: 2,
    },
    {
      label: `Last 14 Days (${moment(date)
        .subtract(13, "days")
        .format("MM/DD")} - ${moment(date).format("MM/DD")})`,
      value: 3,
    },
    { label: `Pay Period to Date`, value: 4 },
    {
      label: `Month To Date (${moment(date)
        .startOf("month")
        .format("MM/DD")} - ${moment(date).format("MM/DD")})`,
      value: 5,
    },
    {
      label: `Year To Date  (${moment(date)
        .startOf("year")
        .format("MM/DD")} - ${moment(date).format("MM/DD")})`,
      value: 6,
    },
    { label: "Trailing 12 Months", value: 7 },
  ];
};
const hideColumnsList = [
  "roomsOccupied",
  "Rooms Occupied",
  "occupied%",
  "Occupancy %",
  "roomRevenue",
  "Room Revenue",
];

const numberComparator = (aVal, bVal, isInverted) => {
  if (aVal == bVal) {
    return 0;
  }
  if (isInverted == true) {
    if (_.isNil(aVal) || _.isNaN(aVal)) return -1;
    if (_.isNil(bVal) || _.isNaN(bVal)) return 1;
  } else {
    if (_.isNil(aVal) || _.isNaN(aVal)) return 1;
    if (_.isNil(bVal) || _.isNaN(bVal)) return -1;
  }
  return aVal < bVal ? -1 : 1;
};
const NumberComparatorValue = (
  aVal,
  bVal,
  nodeA,
  nodeB,
  isInverted,
  columnName
) => {
  aVal = parseFloat(nodeA.data[columnName]);
  bVal = parseFloat(nodeB.data[columnName]);
  const result = numberComparator(aVal, bVal, isInverted);
  return result;
};

const NumberComparatorVariance = (
  aVal,
  bVal,
  nodeA,
  nodeB,
  isInverted,
  columnName
) => {
  aVal = parseFloat(nodeA.data[columnName]);
  bVal = parseFloat(nodeB.data[columnName]);

  let aVal_Variance = parseFloat(nodeA.data[columnName + "Variance"] || 0);
  let bVal_Variance = parseFloat(nodeB.data[columnName + "Variance"] || 0);

  let aVal_diff, bVal_diff;

  aVal_diff = aVal - aVal_Variance;
  bVal_diff = bVal - bVal_Variance;

  const result = numberComparator(aVal_diff, bVal_diff, isInverted);
  return result;
};

const HHMMComparatorValue = (
  aVal,
  bVal,
  nodeA,
  nodeB,
  isInverted,
  columnName
) => {
  aVal = nodeA.data[columnName] || 0;
  bVal = nodeB.data[columnName] || 0;

  aVal =
    aVal == 0
      ? aVal
      : aVal?.toString()?.replaceAll(":", ".")?.replaceAll(",", "");
  bVal =
    bVal == 0
      ? bVal
      : bVal?.toString()?.replaceAll(":", ".")?.replaceAll(",", "");

  let aValMinutes = Utils.getMinutesFromHHMMDOT(
    Number(aVal).toFixed(2)?.toString()
  );
  let bValMinutes = Utils.getMinutesFromHHMMDOT(
    Number(bVal).toFixed(2)?.toString()
  );

  const result = numberComparator(aValMinutes, bValMinutes, isInverted);
  return result;
};

const HHMMComparatorVariance = (
  aVal,
  bVal,
  nodeA,
  nodeB,
  isInverted,
  columnName,
  varianceValue
) => {
  const isTimeHouseKeeper = ["totalCleanTime", "mpcr"].includes(columnName);

  aVal = nodeA.data[columnName] || 0;
  bVal = nodeB.data[columnName] || 0;

  let aVal_Variance = nodeA.data[columnName + "Variance"] || 0;
  let bVal_Variance = nodeB.data[columnName + "Variance"] || 0;

  aVal =
    aVal == 0
      ? aVal
      : aVal?.toString()?.replaceAll(":", ".")?.replaceAll(",", "");
  bVal =
    bVal == 0
      ? bVal
      : bVal?.toString()?.replaceAll(":", ".")?.replaceAll(",", "");

  aVal_Variance =
    aVal_Variance == 0
      ? aVal_Variance
      : aVal_Variance?.toString()?.replaceAll(":", ".")?.replaceAll(",", "");
  bVal_Variance =
    bVal_Variance == 0
      ? bVal_Variance
      : bVal_Variance?.toString()?.replaceAll(":", ".")?.replaceAll(",", "");

  let aValMinutes = Utils.getMinutesFromHHMMDOT(
    Number(aVal).toFixed(2)?.toString()
  );
  let bValMinutes = Utils.getMinutesFromHHMMDOT(
    Number(bVal).toFixed(2)?.toString()
  );

  let aValVarianceMinutes = Utils.getMinutesFromHHMMDOT(
    Number(aVal_Variance).toFixed(2)?.toString()
  );
  let bValVarianceMinutes = Utils.getMinutesFromHHMMDOT(
    Number(bVal_Variance).toFixed(2)?.toString()
  );

  let aVal_diff = aValMinutes - aValVarianceMinutes;
  let bVal_diff = bValMinutes - bValVarianceMinutes;

  const result = numberComparator(aVal_diff, bVal_diff, isInverted);
  return result;
};

const StringComparator = (valueA, valueB, nodeA, nodeB, isDescending) => {
  if (valueA?.toLowerCase() == valueB?.toLowerCase()) return 0;
  return (valueA?.toLowerCase()  > valueB?.toLowerCase()) ? 1 : -1;
}

const getNewValuesWithOldHighligedValue = (
  oldData,
  newData,
  key = "hotelid"
) => {
  const newArray = newData.map((newItem) => {
    const oldDatawithKey = oldData.find((item) => item[key] == newItem[key]);

    if (oldDatawithKey) {
      return {
        ...newItem,
        highlightedColumnValue: oldDatawithKey?.highlightedColumnValue,
        manuallyChartActive: oldDatawithKey?.manuallyChartActive,
      };
    } else {
      return newItem;
    }
  });

  return newArray;
};

const findChangedData = (oldArray, newArray) => {
  const changedData: any[] = [];
  // let maxCount=20
  // let changesCount = 0
  _.takeWhile(newArray, (newObj) => {
    // if(changesCount >= maxCount)
    // {
    //     return false
    // }
    const matchingOldObj = _.find(oldArray, { id: newObj.id });
    if (
      !_.isEqual(
        _.pick(matchingOldObj, ["id", "highlightedColumnValue"]),
        _.pick(newObj, ["id", "highlightedColumnValue"])
      )
    ) {
      changedData.push(newObj);
      // changesCount++
    }

    return true;
  });

  return changedData;
};

let gridApi, gridColumnApi;

function LaborDashboardTable({
  hotelIds,
  moduleName,
  handleHotelChange,
  calendarDate,
  subGroupSelectValue,
  varianceValue,
  periodValue,
  handleSaveDefaultView,
  onChartPeriodChange,
  onComparisonPeriodChange,
  columnSelectorData,
  isDefaultSaveDataLoaded,
  dailyFlashSortingData,
  isChartLoadingMain,
  handleMainChartLoading,
  isCellClicked,
  handleIsCellClicked,
}) {
  const columnDefs: any[] = [
    {
      headerName: "Hotel Name",
      field: "hotelNameAbbr",
      colId: "hotelNameAbbr",
      // sortingOrder: ['asc', 'desc', null],
      //suppressMovable: true,
      lockPosition: true,
      hide: true,
      // checkboxSelection: true,
      // maxWidth: 100,
      minWidth: 148,
      width: 150,
      flex: 1,
      //pinned: true,
      cellRenderer: "nameRender",
      tooltip: (params) => params?.data?.hotelName,
      headerComponentParams: {
        getLastSortedColumnDataFromHeader: getLastSortedColumnDataFromHeader,
      },
    },
    {
      headerName: "EHID",
      field: "lettercode",
      colId: "lettercode",
      // sortingOrder: ['asc', 'desc', null],
      //suppressMovable: true,
      lockPosition: true,
      // checkboxSelection: true,
      // maxWidth: 100,
      // minWidth: 100,
      width: 100,
      flex: 1,
      //pinned: true,
      cellRenderer: "nameRender",
      tooltip: (params) => params?.data?.hotelName,
      headerComponentParams: {
        getLastSortedColumnDataFromHeader: getLastSortedColumnDataFromHeader,
      },
    },
    {
      headerName: "Total Hours",
      colId: "totalHours",
      field: "highlightedColumnValue",
      // field: "totalHours",
      cellRenderer: "valueRender",
      // maxWidth: 230,
      // minWidth: 70,
      flex: 1,
      cellClass: ["h-totalHours", "cursorPointer"],
      lockPosition: true,
      isChart: true,
      headerComponentParams: {
        getLastSortedColumnDataFromHeader: getLastSortedColumnDataFromHeader,
      },
    },
    {
      headerName: "Total Wages",
      colId: "totalWages",
      field: "highlightedColumnValue",
      // field: "totalWages",
      cellRenderer: "valueRender",
      cellClass: "cursorPointer",
      hide: true,
      // maxWidth: 230,
      // minWidth: 100,
      flex: 1,
      headerComponentParams: {
        tooltip: "Only Hourly Wage Employees",
        getLastSortedColumnDataFromHeader: getLastSortedColumnDataFromHeader,
      },
      lockPosition: true,

      isChart: true,
    },

    {
      headerName: "CPOR",
      colId: "cpor",
      field: "highlightedColumnValue",
      // field: "cpor",
      cellRenderer: "valueRender",
      cellClass: "cursorPointer",
      // maxWidth: 130,
      // minWidth: 70,
      flex: 1,
      lockPosition: true,
      headerComponentParams: {
        getLastSortedColumnDataFromHeader: getLastSortedColumnDataFromHeader,
      },
      isChart: true,
    },
    {
      headerName: "Rooms Occupied",
      colId: "roomsOccupied",
      field: "highlightedColumnValue",
      // field: "roomsOccupied",
      cellRenderer: "valueRender",
      cellClass: "cursorPointer",
      // maxWidth: 230,
      // minWidth: 100,
      flex: 1,
      hide: true,
      lockPosition: true,
      headerComponentParams: {
        getLastSortedColumnDataFromHeader: getLastSortedColumnDataFromHeader,
      },
      isChart: true,
    },
    {
      headerName: "Occupancy %",
      colId: "occupied%",
      field: "highlightedColumnValue",
      // field: "occupied%",
      cellRenderer: "valueRender",
      // maxWidth: 130,
      // minWidth: 80,
      flex: 1,
      cellClass: ["occupancy-per", "cursorPointer"],
      lockPosition: true,
      headerComponentParams: {
        getLastSortedColumnDataFromHeader: getLastSortedColumnDataFromHeader,
      },
      isChart: true,
    },

    {
      headerName: "Room Revenue",
      colId: "roomRevenue",
      field: "highlightedColumnValue",
      // field: "roomRevenue",
      cellRenderer: "valueRender",
      // maxWidth: 230,
      // minWidth: 100,
      flex: 1,
      cellClass: ["payroll-id", "cursorPointer"],
      lockPosition: true,
      headerComponentParams: {
        getLastSortedColumnDataFromHeader: getLastSortedColumnDataFromHeader,
      },
      isChart: true,
    },

    {
      headerName: "Labor as % of Rev.",
      colId: "laborCostas%ofRevenue",
      field: "highlightedColumnValue",
      // field: "laborCostas%ofRevenue",
      cellRenderer: "valueRender",
      // maxWidth: 150,
      minWidth: 160,
      flex: 1,
      cellClass: ["payroll-id", "cursorPointer"],
      headerClass: "labor-cost-revenu-col",
      headerComponentParams: {
        tooltip: "Only Hourly Wage Employees",
        getLastSortedColumnDataFromHeader: getLastSortedColumnDataFromHeader,
      },
      lockPosition: true,
      isChart: true,
    },
    {
      headerName: "Daily Approval",
      colId: "approvalStatus",
      field: "dsd",
      // field: "laborCostas%ofRevenue",
      cellRenderer: "notesRender",
      // maxWidth: 200,
      // minWidth: 100,
      flex: 1,
      cellClass: ["payroll-id", "cursorPointer", "daily-approval-col"],
      headerClass: ["labor-cost-revenu-col", "daily-approval-col-header"],
      // headerComponentParams: { tooltip: "Only Hourly Wage Employees" },
      lockPosition: true,
    },

    {
      headerName: "Total Hours",
      colId: "",
      field: "highlightedColumnValue",
      // field: "totalHours",
      cellRenderer: "checkBoxRender",
      // maxWidth: 50,
      // minWidth: 50,
      flex: 0.3,
      headerClass: ["checkBoxHeader", "checkbox-header-column"],
      cellClass: ["checkBox-header", "checkbox-header-column"],
      type: "rightAligned",
      headerComponentParams: { isIcon: true },
      lockPosition: true,
      // pinned: 'right'
    },
  ];

  const departmentColumnDefs: any[] = [
    {
      headerName: "Department",
      field: "department",
      colId: "department",
      //  sortingOrder: ['asc', 'desc', null],
      //suppressMovable: true,
      lockPosition: true,
      // checkboxSelection: true,
      // maxWidth: 250,
      // minWidth: 200,
      width: 200,
      flex: 1.1,
      // pinned: true,
      cellRenderer: "nameRender",
      headerComponentParams: {
        getLastSortedColumnDataFromHeader: getLastSortedColumnDataFromHeader,
      },
      tooltip: (params) => params?.data?.hotelName,
    },
    {
      headerName: "Total Hours",
      colId: "totalHours",
      field: "highlightedColumnValue",
      // field: "totalHours",
      cellRenderer: "valueRender",
      // maxWidth: 230,
      // minWidth: 100,
      flex: 1,
      headerComponentParams: {
        getLastSortedColumnDataFromHeader: getLastSortedColumnDataFromHeader,
      },

      cellClass: ["totalHours", "cursorPointer"],
      lockPosition: true,
      isChart: true,
    },
    {
      headerName: "Total Wages",
      colId: "totalWages",
      field: "highlightedColumnValue",
      // field: "totalWages",
      cellRenderer: "valueRender",
      // maxWidth: 230,
      // minWidth: 100,
      flex: 1,
      hide: true,
      headerComponentParams: {
        tooltip: "Only Hourly Wage Employees",
        getLastSortedColumnDataFromHeader: getLastSortedColumnDataFromHeader,
      },
      lockPosition: true,
      cellClass: "cursorPointer",

      isChart: true,
    },

    {
      headerName: "CPOR",
      colId: "cpor",
      field: "highlightedColumnValue",
      // field: "cpor",
      cellRenderer: "valueRender",
      // maxWidth: 230,
      // minWidth: 100,
      flex: 1,
      lockPosition: true,
      cellClass: "cursorPointer",
      headerComponentParams: {
        getLastSortedColumnDataFromHeader: getLastSortedColumnDataFromHeader,
      },
      isChart: true,
    },
    {
      headerName: "Rooms Occupied",
      colId: "roomsOccupied",
      field: "highlightedColumnValue",
      // field: "roomsOccupied",
      cellRenderer: "valueRender",
      // maxWidth: 230,
      // minWidth: 100,
      flex: 1,
      lockPosition: true,
      hide: true,
      cellClass: "cursorPointer",
      headerComponentParams: {
        getLastSortedColumnDataFromHeader: getLastSortedColumnDataFromHeader,
      },
      isChart: true,
    },
    {
      headerName: "Occupancy %",
      colId: "occupied%",
      field: "highlightedColumnValue",
      // field: "occupied%",
      cellRenderer: "valueRender",
      // maxWidth: 230,
      // minWidth: 100,
      flex: 1,
      lockPosition: true,
      cellClass: "cursorPointer",
      headerComponentParams: {
        getLastSortedColumnDataFromHeader: getLastSortedColumnDataFromHeader,
      },
      isChart: true,
    },

    {
      headerName: "Room Revenue",
      colId: "roomRevenue",
      field: "highlightedColumnValue",
      // field: "roomRevenue",
      cellRenderer: "valueRender",
      // maxWidth: 230,
      // minWidth: 100,
      flex: 1,
      cellClass: ["payroll-id", "cursorPointer"],
      lockPosition: true,
      headerComponentParams: {
        getLastSortedColumnDataFromHeader: getLastSortedColumnDataFromHeader,
      },
      isChart: true,
    },

    {
      headerName: "Labor as % of Rev.",
      colId: "laborCostas%ofRevenue",
      field: "highlightedColumnValue",
      // field: "laborCostas%ofRevenue",
      cellRenderer: "valueRender",
      // maxWidth: 150,
      minWidth: 160,
      flex: 1,
      cellClass: ["payroll-id", "cursorPointer"],
      headerComponentParams: {
        tooltip: "Only Hourly Wage Employees",
        getLastSortedColumnDataFromHeader: getLastSortedColumnDataFromHeader,
      },
      lockPosition: true,
      isChart: true,
    },
    {
      headerName: "Daily Approval",
      colId: "approvalStatus",
      field: "dsd",
      // field: "laborCostas%ofRevenue",
      cellRenderer: "notesRender",
      // maxWidth: 200,
      // minWidth: 100,
      flex: 1,
      cellClass: [
        "payroll-id",
        "cursorPointer",
        "daily-approval-col",
        "justify-content-center",
      ],
      headerClass: ["labor-cost-revenu-col", "daily-approval-col-header"],
      // headerComponentParams: { tooltip: "Only Hourly Wage Employees" },
      lockPosition: true,
    },
    {
      headerName: "Total Hours",
      colId: "",
      field: "highlightedColumnValue",
      // field: "totalHours",
      cellRenderer: "checkBoxRender",
      // maxWidth: 100,
      flex: 0.3,
      headerClass: "checkBoxHeader",
      cellClass: "checkBox-header",
      type: "rightAligned",
      headerComponentParams: { isIcon: true },
      lockPosition: true,
      // pinned: 'right'
    },
  ];

  const positionColumnDefs: any[] = [
    {
      headerName: "Position",
      field: "position",
      colId: "position",
      //  sortingOrder: ['asc', 'desc', null],
      //suppressMovable: true,
      lockPosition: true,
      // checkboxSelection: true,
      // maxWidth: 250,
      // minWidth: 200,
      width: 200,
      flex: 1.1,
      // pinned: true,
      cellRenderer: "nameRender",
      tooltip: (params) => params?.data?.hotelName,
    },
    {
      headerName: "Total Hours",
      colId: "totalHours",
      field: "highlightedColumnValue",
      // field: "totalHours",
      cellRenderer: "valueRender",
      // maxWidth: 230,
      // minWidth: 100,
      flex: 1,
      lockPosition: true,
      cellClass: "cursorPointer",
      headerComponentParams: {
        getLastSortedColumnDataFromHeader: getLastSortedColumnDataFromHeader,
      },
      isChart: true,
    },
    {
      headerName: "Total Wages",
      colId: "totalWages",
      field: "highlightedColumnValue",
      // field: "totalWages",
      cellRenderer: "valueRender",
      // maxWidth: 230,
      // minWidth: 100,
      flex: 1,
      headerComponentParams: {
        tooltip: "Only Hourly Wage Employees",
        getLastSortedColumnDataFromHeader: getLastSortedColumnDataFromHeader,
      },
      lockPosition: true,
      hide: true,
      cellClass: "cursorPointer",
      isChart: true,
    },

    {
      headerName: "CPOR",
      colId: "cpor",
      field: "highlightedColumnValue",
      // field: "cpor",
      cellRenderer: "valueRender",
      // maxWidth: 230,
      // minWidth: 100,
      flex: 1,
      lockPosition: true,
      headerComponentParams: {
        getLastSortedColumnDataFromHeader: getLastSortedColumnDataFromHeader,
      },
      isChart: true,
    },
    {
      headerName: "Rooms Occupied",
      colId: "roomsOccupied",
      field: "highlightedColumnValue",
      // field: "roomsOccupied",
      cellRenderer: "valueRender",
      // maxWidth: 230,
      // minWidth: 100,
      flex: 1,
      lockPosition: true,
      hide: true,
      cellClass: "cursorPointer",
      headerComponentParams: {
        getLastSortedColumnDataFromHeader: getLastSortedColumnDataFromHeader,
      },
      isChart: true,
    },
    {
      headerName: "Occupancy %",
      colId: "occupied%",
      field: "highlightedColumnValue",
      // field: "occupied%",
      cellRenderer: "valueRender",
      // maxWidth: 230,
      // minWidth: 100,
      flex: 1,
      lockPosition: true,
      cellClass: "cursorPointer",
      headerComponentParams: {
        getLastSortedColumnDataFromHeader: getLastSortedColumnDataFromHeader,
      },
      isChart: true,
    },

    {
      headerName: "Room Revenue",
      colId: "roomRevenue",
      field: "highlightedColumnValue",
      // field: "roomRevenue",
      cellRenderer: "valueRender",
      // maxWidth: 230,
      // minWidth: 100,
      flex: 1,
      lockPosition: true,
      cellClass: ["payroll-id", "cursorPointer"],
      headerComponentParams: {
        getLastSortedColumnDataFromHeader: getLastSortedColumnDataFromHeader,
      },
      isChart: true,
    },

    {
      headerName: "Labor as % of Rev.",
      colId: "laborCostas%ofRevenue",
      field: "highlightedColumnValue",
      // field: "laborCostas%ofRevenue",
      cellRenderer: "valueRender",
      // maxWidth: 150,
      minWidth: 160,
      flex: 1,
      cellClass: ["payroll-id", "cursorPointer"],
      headerComponentParams: {
        tooltip: "Only Hourly Wage Employees",
        getLastSortedColumnDataFromHeader: getLastSortedColumnDataFromHeader,
      },
      lockPosition: true,
      isChart: true,
    },
    {
      headerName: "Daily Approval",
      colId: "approvalStatus",
      field: "dsd",
      // field: "laborCostas%ofRevenue",
      cellRenderer: "notesRender",
      // maxWidth: 200,
      // minWidth: 100,
      flex: 1,
      cellClass: [
        "payroll-id",
        "cursorPointer",
        "daily-approval-col",
        "justify-content-center",
      ],
      headerClass: ["labor-cost-revenu-col", "daily-approval-col-header"],
      // headerComponentParams: { tooltip: "Only Hourly Wage Employees" },
      lockPosition: true,
    },
    {
      headerName: "Total Hours",
      colId: "",
      field: "highlightedColumnValue",
      // field: "totalHours",
      cellRenderer: "checkBoxRender",
      // maxWidth: 50,
      // minWidth: 50,
      flex: 0.3,
      headerClass: "checkBoxHeader",
      cellClass: "checkBox-header",
      type: "rightAligned",
      headerComponentParams: { isIcon: true },
      lockPosition: true,
      // pinned: 'right'
    },
  ];

  const [toggleColumnData, setToggleColumnData] = useState<any[]>([]);
  const [gridColumns, setGridColumns] = useState(
    columnDefs.map((item, index) => {
      item.order = index;
      return item;
    })
  );
  const [originTableData, setOriginTableData] = useState<any[]>([]);
  const [filteredTableData, setFilteredTableData] = useState<any[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [levelWiseTableData, setLevelWiseTableData] = useState<any[]>([]);
  const [pathArray, setPathArray] = useState<any[]>([]);
  const [showChart, setShowChart] = useState(false);
  const [positionDetailsRow, setPositionDetailsRow] = useState({});
  const [isSlideOutShow, setIsSlideOutShow] = useState(false);
  const [isInitialDataDataLoading, setIsInitialDataDataLoading] =
    useState(true);
  const [lastClickedColumn, setLastClickedColumn] = useState("");
  const [Periods, setPeriods] = useState<any[]>([]);
  const [selectedColumn, setSelectedColumn] = useState<any>("");
  const [selectedHids, setSelectedHids] = useState<any>("");
  const [selectedSorting, setSelectedSorting] = useState<any>("Variance");
  const [lastSortedColumnData, setLastSortedColumnData] = useState<any>({
    columnName: "",
    sortingOrder: "",
  });
  const [departmentTotalRow, setDepartmentTotalRow] = useState<any>(null);
  const [positionTotalRow, setPositionTotalRow] = useState<any>(null);

  const [columnDropdownToggle, setColumnDropdownToggle] = useState(false);
  let [currentColumnSelected, setCurrentColumnSelected] = useState(6);

  const [maxColumnSelected, setMaxColumnSelected] = useState(7);
  const [isSelectAllCols, setIsSelectAllCols] = useState(false);
  const [resetCalled, setResetCalled] = useState(false);
  const [accordionIcon, setAccordionIcon] = useState(false);
  let [laborTableNotesData, setLaborTableNotesData] = useState<any[]>([]);
  let [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [accordionBtn, setAccordionBtn] = useState<string | null>("");
  const [_columnSelectorData, _setColumnSelectorData] = useState([]);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipContent, setTooltipContent] = useState<any>(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [isAbbrEHIDNameChecked, setIsAbbrEHIDNameChecked] = useState(false);
  const [Processed, setProcessed] = useState(false);

  const agGridRef = useRef<any>(null);

  const getData = () => {
    if (calendarDate && hotelIds != 0) {
      getDashbaordTableData();
    } else {
      setOriginTableData([]);
      setFilteredTableData([]);
      setTableData([]);
      setLevelWiseTableData([]);
      // setIsInitialDataDataLoading(false)
      setShowChart(false);
    }
  };

  useEffect(() => {
    if (isDefaultSaveDataLoaded) {
      buildToggleColumnData();
    }
  }, [periodValue, gridColumns, isDefaultSaveDataLoaded]);

  useEffect(() => {
    if (isDefaultSaveDataLoaded) {
      setLastSortedColumnData({
        ...dailyFlashSortingData.lastSortedColumnData,
      });
      setSelectedSorting(
        dailyFlashSortingData.selectedSorting || "Variance"
      );
    }

    return ()=>{
      setProcessed(true)
    }
  }, [isDefaultSaveDataLoaded, dailyFlashSortingData]);

  useEffect(() => {
    if(Processed)
    sortingOnColumns();
  }, [selectedSorting, varianceValue, pathArray,Processed]);

  useEffect(() => {
    //handle Filter Change
    resetDrillDown();
  }, [hotelIds]);

  useEffect(() => {
    //handle Filter Change
    getData();
  }, [varianceValue, periodValue, resetCalled, calendarDate]);

  useEffect(() => {
    if (calendarDate) {
      const _periods = getPeriods(calendarDate);
      setPeriods(_periods);
    }
  }, [calendarDate]);

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
    };
  }, []);

  const sizeToFit = useCallback(() => {
    if (agGridRef) {
      agGridRef?.current?.api?.sizeColumnsToFit({
        //   defaultMinWidth: 100,
        //   columnLimits: [{ key: 'country', minWidth: 900 }],
      });
    }
  }, []);

  useEffect(() => {
    sizeToFit();
    window.addEventListener("resize", sizeToFit);
  }, [sizeToFit]);



  function sortingOnColumns(
    columns = gridColumns,
    isResetToFirstColumn = false,
    toggleData=toggleColumnData
  ) {

    if(!Processed)
    {
      return
    }
    const enableSort =
      selectedSorting === "Value" || selectedSorting === "Variance";
      
      const _columns=getGridColums(columns,toggleData)
      const VisibleColumns= _columns.filter(x=>!x.hide).map(x=>x.colId)

    const isSort = (colId) => {
      let sort;
      if (pathArray.length == 0) {
        sort = VisibleColumns.includes(colId) && colId === "lettercode"  && enableSort ? 'asc' : VisibleColumns.includes(colId) && colId === "hotelNameAbbr" && enableSort ? "desc": null
      } else if (pathArray.length == 2) {
        sort = colId === "department" && enableSort ? "desc" : null;
      } else {
        sort = colId === "position" && enableSort ? "desc" : null;
      }
      return sort;
    };

    const cols = columns.map((item) => {
      if (
        (pathArray.length != 0 && !VisibleColumns.includes(item.colId)) ||
        item.cellRenderer === "checkBoxRender" ||
        item.cellRenderer === "notesRender"
      ) {
        return {
          ...item,
          enableSorting: false,
          sortable: false,
          sort: null,
        };
      } else if (
        varianceValue === 2 &&
        selectedSorting === "Variance" &&
        item.colId === "laborCostas%ofRevenue"
      ) {
        return {
          ...item,
          enableSorting: false,
          sortable: false,
          sort: null,
        };
      } else {
        const numbersData = [
          "totalWages",
          "cpor",
          "roomsOccupied",
          "occupied%",
          "roomRevenue",
          "laborCostas%ofRevenue",
        ];
        const timeData = ["totalHours"];

        let comparator = StringComparator;

        //For Value
        if (selectedSorting === "Value") {
          if (numbersData.includes(item.colId)) {
            comparator = (aVal, bVal, nodeA, nodeB, isInverted) => {
              return NumberComparatorValue(
                aVal,
                bVal,
                nodeA,
                nodeB,
                isInverted,
                item.colId
              );
            };
          } else if (timeData.includes(item.colId)) {
            comparator = (aVal, bVal, nodeA, nodeB, isInverted) => {
              return HHMMComparatorValue(
                aVal,
                bVal,
                nodeA,
                nodeB,
                isInverted,
                item.colId
              );
            };
          }
        } else if (selectedSorting === "Variance") {
          if (numbersData.includes(item.colId)) {
            comparator = (aVal, bVal, nodeA, nodeB, isInverted) => {
              return NumberComparatorVariance(
                aVal,
                bVal,
                nodeA,
                nodeB,
                isInverted,
                item.colId
              );
            };
          } else if (timeData.includes(item.colId)) {
            comparator = (aVal, bVal, nodeA, nodeB, isInverted) => {
              return HHMMComparatorVariance(
                aVal,
                bVal,
                nodeA,
                nodeB,
                isInverted,
                item.colId,
                varianceValue
              );
            };
          }
        }

        let sort = isSort(item.colId);

        if (
          lastSortedColumnData?.columnName &&
          (lastSortedColumnData?.columnName !== "lettercode" &&  lastSortedColumnData?.columnName !== "hotelNameAbbr") &&
          lastSortedColumnData?.columnName !== "department" &&
          lastSortedColumnData?.columnName !== "position"
        ) {
          sort = null;
          if (lastSortedColumnData?.columnName === item.colId) {
            sort = lastSortedColumnData.sortingOrder;
          }
        }

        return {
          ...item,
          comparator: comparator,
          enableSorting: enableSort,
          sortable: enableSort,
          sort: sort,
        };
      }
    });

    setGridColumns(cols);
    let sort = enableSort ? "desc" : null;
    if (agGridRef.current) {
      if (!isResetToFirstColumn) {
        const sortedColumn = cols.filter(x => !x.hide).find((x) => x.sort);

        if (sortedColumn) {
          agGridRef.current!.columnApi.applyColumnState({
            state: [{ colId: sortedColumn.colId, sort: sortedColumn.sort }],
          });
          setLastSortedColumnData({
            columnName: sortedColumn.colId,
            sortingOrder: sortedColumn.sort,
          });
          return;
        }
      }

      if (lastSortedColumnData?.columnName) {
        sort = null;
      }
      const colId=toggleColumnData.filter(x=>x.checked && x.disabled)[0]?.colName;
      if (pathArray.length == 0) {
      
        agGridRef.current!.columnApi.applyColumnState({
          state: [{ colId, sort: sort }],
        });
        setLastSortedColumnData({
          columnName:colId,
          sortingOrder: sort,
        });
      } else if (pathArray.length == 2) {
        agGridRef.current!.columnApi.applyColumnState({
          state: [{ colId: "department", sort: sort }],
        });
        setLastSortedColumnData({
          columnName: "department",
          sortingOrder: sort,
        });
      } else {
        agGridRef.current!.columnApi.applyColumnState({
          state: [{ colId: "position", sort: sort }],
        });
        setLastSortedColumnData({
          columnName: "position",
          sortingOrder: sort,
        });
      }
    } else {
      const sortedColumn = cols.filter(x => !x.hide).find((x) => x.sort);
      if (sortedColumn) {
        setLastSortedColumnData({
            columnName: sortedColumn.colId,
            sortingOrder: sortedColumn.sort
        })
        return
      }

        const colId=toggleColumnData.filter(x=>x.checked && x.disabled)[0]?.colName;
      if (pathArray.length == 0) {
        setLastSortedColumnData({
          columnName: colId,
          sortingOrder: sort,
        });
      } else if (pathArray.length == 2) {
        setLastSortedColumnData({
          columnName: "department",
          sortingOrder: sort,
        });
      } else {
        setLastSortedColumnData({
          columnName: "position",
          sortingOrder: sort,
        });
      }
    }
  }

  const buildToggleColumnData = () => {
    if (periodValue !== 1) {
      const index = gridColumns.findIndex(
        (x) => x.headerName === "Daily Approval"
      );
      gridColumns[index].hide = true;
    }
    let _toggleColumnData = gridColumns
      .filter((item) => item?.colId)
      .map((item: any, index) => {
        let toggleColumnObject: any = {};
        toggleColumnObject.colDispalyName = item?.headerName;
        toggleColumnObject.colName = item?.colId;
        toggleColumnObject.checked = item?.hide ? false : true;
        toggleColumnObject.order = index;
        toggleColumnObject.disabled =
          (item?.field === "lettercode" ||
            item?.field === "hotelNameAbbr" ||
            item?.field === "department" ||
            item?.field === "position") &&
          !item?.hide
            ? true
            : false;
        return toggleColumnObject;
      });
    if (columnSelectorData?.length > 0 && toggleColumnData.length == 0) {
      _toggleColumnData = _toggleColumnData.map((x) => {
        const data = columnSelectorData.find((l) => l?.colName === x?.colName);
        if (data?.colName) {
          x.checked = data.checked;

          x.disabled = data?.disabled || false;
        }
        return x;
      });
    }

    if (toggleColumnData?.length > 0) {
      _toggleColumnData = _toggleColumnData.map((x) => {
        const data = toggleColumnData.find((l) => l?.colName === x?.colName);
        if (data?.colName) {
          x.checked = data.checked;
          x.disabled = data?.disabled || false;
        }
        return x;
      });
    }

    if (periodValue !== 1) {
      _toggleColumnData = _toggleColumnData.filter(
        (x) => x.colDispalyName !== "Daily Approval"
      );
    }
    setToggleColumnData(_toggleColumnData);
    setCurrentColumnSelected(
      _toggleColumnData.filter((item) => item.checked).length
    );
    setMaxColumnSelected(_toggleColumnData?.length);
    // this.setState({
    //   toggleColumnData,
    //   currentColumnSelected: toggleColumnData.filter((item) => item.checked)
    //     .length,
    //   maxColumnSelected: toggleColumnData?.length,
    // });
  };

  const getFormattedTableData = (data, orderBy = "lettercode") => {
    data = data.map((item) => {
      item.totalHours = Number(item.totalHours || 0);
      item.totalWages = Number(item.totalWages || 0);
      item.roomsOccupied = Number(item.roomsOccupied || 0)?.toFixed(0);
      item.roomRevenue = Number(item.roomRevenue || 0);

      item["occupied%"] = Number(item["occupied%"] || 0);
      item.cpor = Number(item.cpor || 0);
      item["laborCostas%ofRevenue"] = Number(
        item["laborCostas%ofRevenue"] || 0
      );

      item["occupied%"] =
        Number(item.roomsOccupied || 0) === 0 || item.roomCount == 0
          ? 0
          : ((Number(item.roomsOccupied || 0) / item.roomCount) * 100)?.toFixed(
              2
            );
      item.cpor = (
        item.roomsOccupied != 0 || isNaN(item.roomsOccupied)
          ? Number(Number(item.totalWages) / Number(item.roomsOccupied) || 0)
          : 0.0
      )?.toFixed(2);
      item["laborCostas%ofRevenue"] = (
        item.roomRevenue != 0 || isNaN(item.roomRevenue)
          ? Number(
              (Number(item.totalWages) / Number(item.roomRevenue)) * 100 || 0
            )
          : 0.0
      )?.toFixed(2);

      item.totalHoursVariance = Number(item.totalHoursVariance || 0);
      item.totalWagesVariance = Number(item.totalWagesVariance || 0);
      item.roomsOccupiedVariance = Number(item.roomsOccupiedVariance || 0)?.toFixed(0);
      item.roomRevenueVariance = Number(item.roomRevenueVariance || 0);

      item["occupied%Variance"] = Number(item["occupied%Variance"] || 0);
      item.cporVariance = Number(item.cporVariance || 0);
      item["laborCostas%ofRevenueVariance"] = Number(
        item["laborCostas%ofRevenueVariance"] || 0
      );

      item["occupied%Variance"] =
        Number(item.roomsOccupiedVariance || 0) === 0 ||
        item.roomCountVariance == 0
          ? 0
          : (
              (Number(item.roomsOccupiedVariance || 0) /
                item.roomCountVariance) *
              100
            )?.toFixed(2);
      item.cporVariance = (
        item.roomsOccupiedVariance != 0 || isNaN(item.roomsOccupiedVariance)
          ? Number(
              Number(item.totalWagesVariance) /
                Number(item.roomsOccupiedVariance) || 0
            )
          : 0.0
      )?.toFixed(2);
      item["laborCostas%ofRevenueVariance"] = (
        item.roomRevenueVariance != 0 || isNaN(item.roomRevenueVariance)
          ? Number(
              (Number(item.totalWagesVariance) /
                Number(item.roomRevenueVariance)) *
                100 || 0
            )
          : 0.0
      )?.toFixed(2);

      return item;
    });

    data.sort(function (a, b) {
      if (a[orderBy].toLowerCase() < b[orderBy].toLowerCase()) {
        return -1;
      }
      if (a[orderBy].toLowerCase() > b[orderBy].toLowerCase()) {
        return 1;
      }
      return 0;
    });

    return data;
  };

  const getDrillDownLevel = () => {
    return pathArray.length == 0 ? 0 : pathArray.length - 1;
  };

  const downloadLaborDashbord = () => {
    // const EXPORTHIDECOLUMNS=["RoomRevenue","RoomOccupied","TotalHours","TotalWages","CPOR","Occupany%","Labor%PerRev"]

    const EXPORTHIDECOLUMNSValue = {
      hotelNameAbbr: "hotelNameAbbr",
      lettercode: "lettercode",
      department: "department",
      position: "position",
      totalHours: "TotalHours",
      totalWages: "TotalWages",
      cpor: "CPOR",
      roomsOccupied: "RoomOccupied",
      "occupied%": "Occupany%",
      roomRevenue: "RoomRevenue",
      "laborCostas%ofRevenue": "Labor%PerRev",
    };

    const SelectedColumn = toggleColumnData
      .filter((x) => x?.checked)
      .map((x) => EXPORTHIDECOLUMNSValue[x.colName]);

    // ettercode		RoomCount	RoomCountVariance	RoomRevenue	RoomRevenueVariance	RoomsOccupied	RoomsOccupiedVariance	TotalHours	TotalHoursVariance	TotalWages	TotalWagesVariance
    const EXPORTSORTCOLUMNSValue = {
      hotelNameAbbr: "hotelNameAbbr",
      lettercode: "lettercode",
      department: "department",
      position: "position",
      totalHours: "TotalHours",
      totalWages: "TotalWages",
      cpor: "CPOR",
      roomsOccupied: "RoomsOccupied",
      "occupied%": "Occupancy %",
      roomRevenue: "RoomRevenue",
      "laborCostas%ofRevenue": "Labor as % of Rev",
    };

    const EXPORTSORTCOLUMNSVariamce = {
      hotelNameAbbr: "hotelNameAbbr",
      lettercode: "lettercode",
      department: "department",
      position: "position",
      totalHours: "TotalHoursVariance",
      totalWages: "TotalWagesVariance",
      cpor: "CPOR VAR",
      roomsOccupied: "RoomsOccupiedVariance",
      "occupied%": "OCCUPANCY VAR %",
      roomRevenue: "RoomRevenueVariance",
      "laborCostas%ofRevenue": "Labor as % of Rev var",
    };

    const level = getDrillDownLevel();
    let defaultColumnName = "lettercode";
    if (level == 0) {
      defaultColumnName = "lettercode";
    } else if (level == 1) {
      defaultColumnName = "department";
    } else {
      defaultColumnName = "position";
    }

    // agGridRef.current.columnApi.columnController.sortController.getColumnsWithSortingOrdered()

    const enableSort =
      selectedSorting === "Value" || selectedSorting === "Variance";
    const EXPORTSORTCOLUMNS = enableSort
      ? selectedSorting === "Value"
        ? EXPORTSORTCOLUMNSValue
        : EXPORTSORTCOLUMNSVariamce
      : null;
    const columnName =
      enableSort && EXPORTSORTCOLUMNS
        ? EXPORTSORTCOLUMNS[lastSortedColumnData?.columnName]
        : defaultColumnName;
    const sortDirection = enableSort
      ? lastSortedColumnData.sortingOrder
        ? lastSortedColumnData.sortingOrder === "asc"
          ? "ASC"
          : "DESC"
        : "DESC"
      : "ASC";

    let request: any = {
      HID: pathArray.length == 0 ? hotelIds : pathArray[1].id,
      DataDate: calendarDate,
      varianceType: varianceValue,
      TimePeriod: periodValue,
      drilldownlevel: getDrillDownLevel(),
      DeptId: pathArray.length > 2 ? pathArray[2].id : 0,
      columnName: columnName,
      sortDirection: sortDirection,
      showColumns: SelectedColumn,
      totalRow:
        pathArray.length == 0
          ? null
          : pathArray.length == 2
            ? departmentTotalRow
            : positionTotalRow,
    };

    const fileName = `LaborDashboard-${moment().format("YY-MMDD hh.mm.ss")}`;
    LaborDashBoard.downloadLaborDashboardData(request, fileName)
      .then((res: any | null) => {
        toast.success(`Daily flash data downloaded Successfully`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "laborDashBoard",
        });
      })
      .catch((err) => {
        Utils.toastError(`Something Went Wrong, Please Try Again`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "laborDashBoard",
        });
      });
  };


  const scrollToSelectedRow = (_filteredTableData) =>{
    const _tableData = agGridRef?.current ? agGridRef?.current?.api?.getRenderedNodes().map(x =>x.data) : _filteredTableData
    
    const selectedItem= _filteredTableData.find(item => item.highlightedColumnValue != "" && item.highlightedColumnValue != null && item.highlightedColumnValue != undefined)
  
    const index= _tableData.findIndex(item => item?.id == selectedItem?.id)
    const filteredHighlighted= _filteredTableData.filter(item => item.highlightedColumnValue != "" && item.highlightedColumnValue != null && item.highlightedColumnValue != undefined)
   
    if(filteredHighlighted.length == 1)
    {

      setTimeout(() => {
        gridApi?.ensureIndexVisible(index,null);
      }, 100);
    }
  }


  const getDashbaordTableData = async () => {
    let params: any = {
      HID: pathArray.length == 0 ? hotelIds : pathArray[1].id,
      DataDate: calendarDate,
      varianceType: varianceValue,
      TimePeriod: periodValue,
      drilldownlevel: getDrillDownLevel(),
      DeptId: pathArray.length > 2 ? pathArray[2].id : 0,
    };

    try {
      const previousDataTable = getCurrentTableData();
      handleIsCellClicked(false);
      setIsInitialDataDataLoading(true);

      let _data = await Promise.all([
        LaborDashBoard.getLaborDashboardData(params),
        LaborDashBoard.GetLaborDayApprovalDetail(hotelIds, calendarDate),
      ]);
      let data = _data[0];
      let notesData = _data[1];
      setLaborTableNotesData(notesData);

      data = (data || [])?.map((item) => {
        const filterData = (notesData || [])?.find(
          (x) => x.lettercode === item.lettercode
        );

        return { ...item, ...filterData };
      });
      // data=data.filter(item => {
      //             let d=moment(item.datadate).format("MM/DD/YY")
      //             return moment(d).isSame(calendarDate)})
      if (pathArray.length == 0) {
        let formattedData =
          data && data.length > 0
            ? getFormattedTableData([...data])
            : [...data];

        setFilteredTableData((prev) => {
          const oldData = _.cloneDeep(tableData);
          let _formattedData = getNewValuesWithOldHighligedValue(
            oldData,
            formattedData
          );
          _formattedData = updateShowChartToggle(
            _formattedData,
            true,
            previousDataTable
          );
          setTableData(_formattedData);

          return [..._formattedData];
        });
        setLevelWiseTableData((prev) => {
          const oldData = _.cloneDeep(prev);
          let _formattedData = getNewValuesWithOldHighligedValue(
            oldData,
            formattedData
          );
          // _formattedData=updateShowChartToggle(_formattedData)
          return [_formattedData];
        });
        setOriginTableData([...data]);
      } else if (pathArray.length == 2) {
        let _filteredData = data.filter(
          (item) => item?.hotelid === pathArray[1].id
        );

        _filteredData = getFormattedTableData([..._filteredData], "department");

        // setGridColumns(departmentColumnDefs)
        // sortingOnColumns(departmentColumnDefs)

        setFilteredTableData((prev) => {
          const oldData = _.cloneDeep(tableData);
          let _formattedData = getNewValuesWithOldHighligedValue(
            oldData,
            _filteredData,
            "departmentID"
          );
          _formattedData = updateShowChartToggle(
            _formattedData,
            true,
            previousDataTable
          );
          setTableData(_formattedData);

          return [..._formattedData];
        });

        // const formattedData0 = data && data.length > 0 ? getFormattedTableData([...data]) : [...data]
        let params: any = {
          HID: hotelIds,
          DataDate: calendarDate,
          varianceType: varianceValue,
          TimePeriod: periodValue,
          drilldownlevel: 0,
          DeptId: 0,
        };

        let data0 = await LaborDashBoard.getLaborDashboardData(params);

        data0 = (data0 || [])?.map((item) => {
          const filterData = (notesData || [])?.find(
            (x) => x.lettercode === item.lettercode
          );

          return { ...item, ...filterData };
        });

        const formattedData0 =
          data0 && data0.length > 0
            ? getFormattedTableData([...data0])
            : [...data0];

        //Total Row
        let totalRow = formattedData0.find(
          (item) => item.hotelid === pathArray[1].id
        );
        totalRow = { totalRow: true, department: "Total", ...totalRow };
        setDepartmentTotalRow(totalRow);

        setLevelWiseTableData((prev) => {
          const levelData = [...prev];
          levelData.splice(0, 0, formattedData0);
          levelData.splice(1, 0, _filteredData);
          return levelData;
        });
        setOriginTableData([...data]);
      } else {
        let _filteredData = data.filter(
          (item) =>
            item?.hotelid === pathArray[1].id &&
            item.departmentID === pathArray[2].id
        );

        _filteredData = getFormattedTableData([..._filteredData], "position");

        // _filteredData=updateShowChartToggle(_filteredData)

        // setGridColumns(positionColumnDefs)
        // sortingOnColumns(positionColumnDefs)

        setFilteredTableData((prev) => {
          const oldData = _.cloneDeep(tableData);
          let _formattedData = getNewValuesWithOldHighligedValue(
            oldData,
            _filteredData,
            "positionID"
          );
          _formattedData = updateShowChartToggle(
            _formattedData,
            true,
            previousDataTable
          );
          setTableData(_formattedData);

          return [..._formattedData];
        });

        // const formattedData0 = data && data.length > 0 ? getFormattedTableData([...data]) : [...data]
        let params0: any = {
          HID: hotelIds,
          DataDate: calendarDate,
          varianceType: varianceValue,
          TimePeriod: periodValue,
          drilldownlevel: 0,
          DeptId: 0,
        };

        let data0 = await LaborDashBoard.getLaborDashboardData(params0);

        data0 = (data0 || [])?.map((item) => {
          const filterData = (notesData || [])?.find(
            (x) => x.lettercode === item.lettercode
          );

          return { ...item, ...filterData };
        });

        const formattedData0 =
          data0 && data0.length > 0
            ? getFormattedTableData([...data0])
            : [...data0];

        //Total Row
        let totalRow0 = formattedData0.find(
          (item) => item.hotelid === pathArray[1].id
        );
        totalRow0 = { totalRow: true, department: "Total", ...totalRow0 };
        setDepartmentTotalRow(totalRow0);

        // let _filteredData1 = data.filter(item => item?.hotelid === pathArray[1].id)
        // _filteredData1 = getFormattedTableData([..._filteredData1], "departmentID")

        let params1: any = {
          HID: pathArray[1].id,
          DataDate: calendarDate,
          varianceType: varianceValue,
          TimePeriod: periodValue,
          drilldownlevel: 1,
          DeptId: 0,
        };

        let data1 = await LaborDashBoard.getLaborDashboardData(params1);

        data1 = (data1 || [])?.map((item) => {
          const filterData = (notesData || [])?.find(
            (x) => x.lettercode === item.lettercode
          );

          return { ...item, ...filterData };
        });

        const _filteredData1 =
          data1 && data1.length > 0
            ? getFormattedTableData([...data1], "departmentID")
            : [...data1];

        //Total Row
        let totalRow = _filteredData1.find(
          (item) =>
            item.hotelid === pathArray[1].id &&
            item.departmentID === pathArray[2].id
        );
        totalRow = { totalRow: true, position: "Total", ...totalRow };
        setPositionDetailsRow(totalRow);

        setLevelWiseTableData((prev) => {
          return [formattedData0, _filteredData1, _filteredData];
        });
        setOriginTableData([...data]);
      }
      // setFilteredTableData([...formattedData])
      // setLevelWiseTableData([formattedData])

      setIsInitialDataDataLoading(false);
    } catch (error) {
      if ((error as any)?.message === "Cancel") {
        setIsInitialDataDataLoading(true);
      } else {
        Utils.toastError(`Something Went Wrong, Please Try Again`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "laborDashBoard",
        });
        setIsInitialDataDataLoading(false);
      }
    }
  };

  const onGridReady = (params: any) => {
    gridApi = params.api;
    gridColumnApi = params.columnApi;
    // columnWidthSizeToFit();

    const headerSelector = ".ag-header";
    const scrollSelector = ".ag-body-horizontal-scroll";

    const scrollContainerSelector = ".ag-body-horizontal-scroll-container";

    // get scrollbar elements
    const scrollElement: any = document.querySelector(scrollSelector);

    const scrollContainerElement: any = document.querySelector(
      scrollContainerSelector
    );

    let data = getCurrentTableData();
    if (data.length > 0 && pathArray.length == 2) {
      agGridRef.current!.api.setPinnedTopRowData([departmentTotalRow]);
    } else if (data.length > 0 && pathArray.length == 3) {
      agGridRef.current!.api.setPinnedTopRowData([positionTotalRow]);
    }

    const enableSort =
      selectedSorting === "Value" || selectedSorting === "Variance";

    let sort = enableSort ? "desc" : null;
    // if(lastSortedColumnData?.columnName)
    // {
    //     sort=null
    // }

    // if (pathArray.length == 0) {
    //     agGridRef.current!.columnApi.applyColumnState({
    //         state: [{ colId: 'lettercode', sort: lastSortedColumnData?.sortingOrder || null }]
    //     });
    // }
    // else if (pathArray.length == 2) {
    //     agGridRef.current!.columnApi.applyColumnState({
    //         state: [{ colId: 'department', sort: lastSortedColumnData?.sortingOrder || null }]
    //     });
    // }
    // else {
    //     agGridRef.current!.columnApi.applyColumnState({
    //         state: [{ colId: 'position', sort: lastSortedColumnData?.sortingOrder || null }]
    //     });
    // }
  };

  const columnWidthSizeToFit = () => {
    gridApi?.sizeColumnsToFit();
  };

  function TotalRowRender({ params }) {
    if (
      params?.colDef?.field === "department" ||
      params?.colDef?.field === "position"
    ) {
      return (
        <EllipsisWithTooltip>
          <span className="Total">Total</span>
        </EllipsisWithTooltip>
      );
    }

    let field = params?.colDef?.colId;

    const isDollarSign = ["totalWages", "cpor", "roomRevenue"].includes(field);
    const isPercentage = ["occupied%", "laborCostas%ofRevenue"].includes(field);
    const isDecimal = ["totalHours"].includes(field);

    const isPositiveGreen = [
      "roomsOccupied",
      "roomRevenue",
      "occupied%",
    ].includes(field);

    const customField = field;

    let value = params?.data[customField];
    let diff = params?.data?.[customField + "Variance"] || 0;
    let isPositive;

    if (field === "totalHours") {
      let _val = Number(value);
      let _diff = Number(diff);

      _val = Utils.getMinutesFromHHMMDOT(_val?.toFixed(2)?.toString());
      _diff = Utils.getMinutesFromHHMMDOT(_diff?.toFixed(2)?.toString());

      diff = Number(_val - _diff)?.toFixed(2);
      isPositive = diff > 0;
      diff = Utils.getMinutesInHHMMFormat(diff);
      value = Utils.getMinutesInHHMMFormat(
        Utils.getMinutesFromHHMMDOT(
          Number(value || 0)
            ?.toFixed(2)
            ?.toString()
        )
      );
      diff = isPositive || diff == "00:00" ? diff : `-${diff}`;
    } else {
      diff = Number(value - diff)?.toFixed(2);
      isPositive = diff > 0;
    }

    if (isPercentage) {
      value = Number(value || 0)?.toFixed(2);
      diff = Number(diff)?.toFixed(2);
    } else if (isDollarSign) {
      value = Utils.currencyFormatWithOutdoller(Number(value || 0)?.toFixed(2));
      diff = Utils.currencyFormatWithOutdoller(Number(diff)?.toFixed(2));
    } else if (field === "roomsOccupied") {
      value = Number(value || 0)?.toFixed(0);
      diff = Number(diff)?.toFixed(0);
    }
    const valueFormatted = `${isDollarSign ? "$" : ""}${value}${
      isPercentage ? "%" : ""
    }`;
    const diffvalueFormatted = `${isPositive ? `+${diff}` : diff || "0"}${
      isPercentage ? "%" : ""
    }`;

    return (
      <div className="variance-neg-pos-value">
        <div>
          <EllipsisWithTooltip>
            <div className="data-sets d-flex">
              <div>
                <span className="value">{valueFormatted}</span>
                {varianceValue === 2 &&
                customField === "laborCostas%ofRevenue" ? (
                  <></>
                ) : (
                  <span
                    className={
                      isPositiveGreen
                        ? isPositive
                          ? "show-pos-variance"
                          : "show-neg-variance"
                        : isPositive
                          ? "show-neg-variance"
                          : "show-pos-variance"
                    }
                  >
                    {diffvalueFormatted}
                  </span>
                )}
              </div>
              <span className="block-el col-2 p-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width="19"
                    height="19"
                    rx="2.5"
                    fill="white"
                  ></rect>
                  <path
                    d="M11 12.414C11.0928 12.5069 11.203 12.5807 11.3243 12.631C11.4456 12.6813 11.5757 12.7072 11.707 12.7072C11.8383 12.7072 11.9684 12.6813 12.0897 12.631C12.211 12.5807 12.3212 12.5069 12.414 12.414L17.414 7.414L16 6L11.707 10.293L9.414 8C9.32121 7.90705 9.21101 7.83332 9.08969 7.78301C8.96838 7.73269 8.83833 7.7068 8.707 7.7068C8.57567 7.7068 8.44562 7.73269 8.32431 7.78301C8.20299 7.83332 8.09279 7.90705 8 8L3 13L4.414 14.414L8.707 10.121L11 12.414Z"
                    fill="#2CAF92"
                  ></path>
                  <rect
                    x="0.5"
                    y="0.5"
                    width="19"
                    height="19"
                    rx="2.5"
                    stroke="#E8EAED"
                  ></rect>
                </svg>
              </span>
            </div>
          </EllipsisWithTooltip>
        </div>
      </div>
    );
  }

  const CheckBoxRender = React.memo((params: any) => {
    const [isLoading, setIsLoading] = useState(false);
    if (params?.data?.totalRow) return <></>;
    const currentLevelData = getLevelKeys(params);
    let field = params?.colDef?.colId;

    let checked = false;
    if (
      (currentLevelData.level === 1 || currentLevelData.level === 2) &&
      params.data.highlightedColumnValue
    ) {
      let headerlist = params.data.highlightedColumnValue?.split("_");
      let headerName = headerlist[0];
      if (hideColumnsList.includes(headerName)) {
        // setLastClickedColumn("")
        checked = false;
      } else {
        checked = true;
      }
    } else {
      checked = params.data.highlightedColumnValue ? true : false;
    }

    const handleColumnClick = (params, status) => {
      if (status) {
        let clickedHeaderName = params?.colDef?.headerName;

        const currentData = getCurrentTableData();
        if (isLimitValidation(currentData, clickedHeaderName, true)) {
          return;
        }
      }

      setIsLoading(true);
      onColumnClick(params, status, true);
      setTimeout(() => {
        setIsLoading(false);
      }, 10);
    };
    if (isLoading) {
      return (
        <ContentLoader viewBox="0 0 380 70" style={{ width: "100%" }}>
          {/* Only SVG shapes */}
          <rect x="0" y="0" rx="5" ry="5" width="50" height="70" />
        </ContentLoader>
      );
    }

    return (
      <Form.Check
        onChange={(e) => handleColumnClick(params, e.target.checked)}
        type={"checkbox"}
        id={`default`}
        checked={checked}
        label={``}
      />
    );
  });

  const getLevelKeys = (params) => {
    const cols = params?.columnApi?.columnController?.columnDefs;
    if (cols?.length > 0) {
      switch (cols[0]?.headerName) {
        case "EHID":
          return {
            level: 0,
            field: "lettercode",
          };
        case "Department":
          return {
            level: 1,
            field: "departmentID",
          };
        case "Position":
          return {
            level: 2,
            field: "positionID",
          };

        default:
          return {
            level: 0,
            field: "lettercode",
          };
      }
    } else {
      return {
        level: 0,
        field: "lettercode",
      };
    }
  };

  const getCurrentTableData = () => {
    const currentData: any[] = [];
    agGridRef?.current?.api?.forEachNode((node) => {
      currentData.push(node?.data);
    });

    return currentData;
  };

  const updateTableData = (data) => {
    if (data.length > 0) {
      const oldData = getCurrentTableData();
      const newData = findChangedData(oldData, data);
      agGridRef?.current?.api?.applyTransactionAsync({
        update: newData,
      });

      setTableData(data);
      const column = getSelectedColumn(data);
      const selectedids = getSelectedHIds(data);
      setSelectedHids(selectedids);
      setSelectedColumn(column);

      //   setTimeout(()=>{
      //     agGridRef.current.api.refreshCells({
      //         force: true
      //       });
      //   })
    }
  };

  const isLimitValidation = (data, clickedColumn, isFromCheckbox = false) => {
    const filterData = data.filter((x) => x?.highlightedColumnValue);
    const column = getSelectedColumn(filterData);

    if (
      (column === clickedColumn || isFromCheckbox) &&
      filterData.length === MAXLIMIT
    ) {
      Utils.toastError("Please limit chart selections to 10 properties.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "laborDashBoard",
      });

      return true;
    }

    return false;
  };

  const setSelectedRowData = (params, isChecked = true) => {
    const data = params.data;
    if (isChecked) {
      setSelectedRows((prev) => [...prev, data]);
    } else {
      const currentLevelData = getLevelKeys(params);

      setSelectedRows((prev) => {
        return [...prev].filter(
          (x) => x[currentLevelData.field] == data[currentLevelData.field]
        );
      });
    }
  };

  const onColumnClick = (params, isChecked = true, isFromCheckbox = false) => {
    // setFilteredTableData(prev => {
    const prev = getCurrentTableData();
    let headerName = params?.colDef?.headerName;

    // if(isChecked)
    // {   if(isLimitValidation(prev,headerName,isFromCheckbox))
    //     {
    //         return
    //     }

    // }
    // setSelectedRowData(params)
    handleIsCellClicked(true);
    const currentLevelData = getLevelKeys(params);

    if (
      prev?.filter((x) => x.highlightedColumnValue)?.length == 0 &&
      isFromCheckbox &&
      agGridRef
    ) {
      headerName =
        agGridRef?.current?.columnApi?.columnController?.columnDefs?.filter(
          (x) => !x.hide && x?.isChart
        )[0]?.headerName;
    }

    let firstColumnUniqueField = params?.data[currentLevelData.field];

    let highlightedColumnValue = `${headerName}_${currentLevelData.level}_${firstColumnUniqueField}`;

    let _filteredTableData = _.cloneDeep(prev);
    if (hideColumnsList.includes(headerName) && currentLevelData.level > 0) {
      _filteredTableData = _filteredTableData?.map((x) => {
        if (
          !isChecked &&
          x.highlightedColumnValue?.toString()?.startsWith(headerName)
        ) {
          x.highlightedColumnValue = "";
        }
        return x;
      });
    }

    //When changing columns
    setLastClickedColumn((prevLastCLickedColumn) => {
      if (isFromCheckbox) {
        if (
          prev?.filter((x) => x.highlightedColumnValue)?.length == 0 &&
          isFromCheckbox &&
          agGridRef
        ) {
          prevLastCLickedColumn = headerName;
        }

        highlightedColumnValue = `${
          prevLastCLickedColumn !== "" ? prevLastCLickedColumn : headerName
        }_${currentLevelData.level}_${firstColumnUniqueField}`;

        if (prevLastCLickedColumn !== "") {
          headerName = prevLastCLickedColumn;
          return prevLastCLickedColumn;
        } else return headerName;
      } else if (
        prevLastCLickedColumn !== "" &&
        prevLastCLickedColumn !== headerName &&
        !isFromCheckbox
      ) {
        _filteredTableData = _filteredTableData.map((item) => {
          item.highlightedColumnValue = "";

          return item;
        });
        return headerName;
      }

      return headerName;
    });

    _filteredTableData = _filteredTableData.map((item) => {
      if (isChecked) {
        if (
          item[currentLevelData.field] === params?.data[currentLevelData.field]
        ) {
          item.highlightedColumnValue = highlightedColumnValue;
        } else {
          item.highlightedColumnValue =
            item.highlightedColumnValue &&
            item.highlightedColumnValue.split("_")[0] === headerName
              ? item?.highlightedColumnValue || ""
              : null;
        }
      } else {
        if (
          item[currentLevelData.field] === params?.data[currentLevelData.field]
        ) {
          item.highlightedColumnValue = "";
        }
      }

      return item;
    });

    _filteredTableData = _filteredTableData.map((x) => ({
      ...x,
      manuallyChartActive: false,
    }));
    _filteredTableData = updateShowChartToggle(_filteredTableData, true, prev);

    setLevelWiseTableData((prev) => {
      const levelData = [...prev];
      levelData[currentLevelData.level] = _filteredTableData;
      return levelData;
    });

    updateTableData(_filteredTableData);
    if (!isChecked) {
      if (
        _filteredTableData.filter((item) => item.highlightedColumnValue)
          .length == 0
      ) {
        setIsInitialDataDataLoading(true)
        setFilteredTableData(_filteredTableData)
        agGridRef.current?.api?.refreshCells({
          force: true,
        });
        setIsInitialDataDataLoading(false)

      }
    }

    if (_filteredTableData.length > 20) {
      setTimeout(() => {
        handleIsCellClicked(false);
      }, 20);
    } else {
      handleIsCellClicked(false);
    }

    //     return _filteredTableData
    // })

    // setOriginTableData(prev => {

    //     const currentLevelData = getLevelKeys(params)

    //     let headerName = params?.colDef?.headerName
    //     let firstColumnUniqueField=params?.data[currentLevelData.field]

    //     let highlightedColumnValue = `${headerName}_${currentLevelData.level}_${firstColumnUniqueField}`;

    //     let _filteredTableData = _.cloneDeep(prev)

    //     _filteredTableData = _filteredTableData.map(item => {
    //         if (isChecked) {

    //             if (item[currentLevelData.field] === params?.data[currentLevelData.field]) {
    //                 item.highlightedColumnValue = highlightedColumnValue
    //             }
    //             else {
    //                 item.highlightedColumnValue = item?.highlightedColumnValue || ""

    //             }

    //         }
    //         else {
    //             if (item[currentLevelData.field] === params?.data[currentLevelData.field]) {
    //                 item.highlightedColumnValue = ""
    //             }

    //         }

    //         return item
    //     })

    //     _filteredTableData=_filteredTableData.map(x=>({...x,manuallyChartActive: false}))
    //     return _filteredTableData
    // })
  };
  const hoverOutEllipsis = () => {
    $(".tooltip")?.removeClass("show");
    $(".tooltip")?.removeClass("fade");
  };
  const handleVisualize = (isChecked, paths) => {
    setShowChart((prev) => !prev);

    if (!isChecked) {
      setLastClickedColumn("");
    }
    const prev = getCurrentTableData();
    // setFilteredTableData(prev => {
    let _filteredTableData = _.cloneDeep(prev);

    _filteredTableData = _filteredTableData.map((item) => ({
      ...item,
      manuallyChartActive: isChecked,
      isChartActive: isChecked,
      highlightedColumnValue: "",
    }));

    setLevelWiseTableData((prev) => {
      const levelData = [...prev];
      if (paths.length == 2) {
        levelData[1] = _filteredTableData;
      } else if (paths.length == 3) {
        levelData[2] = _filteredTableData;
      } else if (paths.length == 0) {
        levelData[0] = _filteredTableData;
      }

      return levelData;
    });

    updateTableData(_filteredTableData);
    //     return _filteredTableData

    // })
  };
  const ValueRender = React.memo((params: any) => {
    const [isLoading, setIsLoading] = useState(false);

    if (params?.data?.totalRow) return <TotalRowRender params={params} />;

    let field = params?.colDef?.colId;

    const isDollarSign = ["totalWages", "cpor", "roomRevenue"].includes(field);
    const isPercentage = ["occupied%", "laborCostas%ofRevenue"].includes(field);
    const isDecimal = ["totalHours"].includes(field);

    const isPositiveGreen = [
      "roomsOccupied",
      "roomRevenue",
      "occupied%",
    ].includes(field);

    const customField = field;

    let value = params?.data[customField];
    let diff = params?.data?.[customField + "Variance"] || 0;
    let isPositive;

    if (field === "totalHours") {
      let _val = value;
      let _diff = diff;

      _val = Utils.getMinutesFromHHMMDOT(_val?.toFixed(2)?.toString() || 0);
      _diff = Utils.getMinutesFromHHMMDOT(_diff?.toFixed(2)?.toString() || 0);

      diff = (_val - _diff)?.toFixed(2);
      isPositive = diff > 0;
      diff = Utils.getMinutesInHHMMFormat(diff);
      value = Utils.getMinutesInHHMMFormat(
        Utils.getMinutesFromHHMMDOT(value?.toFixed(2)?.toString() || 0)
      );
      diff = isPositive || diff == "00:00" ? diff : `-${diff}`;
    } else {
      diff = (value - diff)?.toFixed(2);
      isPositive = diff > 0;
    }

    if (isPercentage) {
      value = Number(value || 0).toFixed(2);
      diff = Number(diff).toFixed(2);
    } else if (isDollarSign) {
      value = Utils.currencyFormatWithOutdoller(Number(value || 0).toFixed(2));
      diff = Utils.currencyFormatWithOutdoller(Number(diff).toFixed(2));
    } else if (field === "roomsOccupied") {
      value = Number(value).toFixed(0);
      diff = Number(diff).toFixed(0);
    }

    // if(field === "cpor"){
    //     value= params?.data["roomsOccupied"] ? Number(params?.data["totalWages"] || 0)/Number(params?.data["roomsOccupied"] || 0) : "0"
    //     value=Utils.currencyFormatWithOutdoller(Number(params?.data[customField] || 0).toFixed(2))

    //     diff=params?.data["roomsOccupied_diff"] ? Number(params?.data["totalWages_diff"] || 0)/Number(params?.data["roomsOccupied_diff"] || 0) : "0"
    //     diff=Utils.currencyFormatWithOutdoller(Number(diff).toFixed(2))
    // }

    const valueFormatted = `${isDollarSign ? "$" : ""}${value}${
      isPercentage ? "%" : ""
    }`;
    const diffvalueFormatted = `${isPositive ? `+${diff}` : diff || "0"}${
      isPercentage ? "%" : ""
    }`;

    const currentLevelData = getLevelKeys(params);
    let headerName = params?.colDef?.headerName;
    let firstColumnUniqueField = params?.data[currentLevelData.field];
    const highlightedColumnValue = `${headerName}_${currentLevelData.level}_${firstColumnUniqueField}`;

    if (
      (currentLevelData.level === 1 || currentLevelData.level === 2) &&
      hideColumnsList.includes(customField)
    )
      return <></>;

    const handleColumnClick = (params, status = true) => {
      if (status) {
        const currentData = getCurrentTableData();
        if (isLimitValidation(currentData, headerName)) {
          return;
        }
      }

      setIsLoading(true);
      onColumnClick(params, status);
      setTimeout(() => {
        setIsLoading(false);
      }, 10);
    };

    if (isLoading) {
      return (
        <ContentLoader viewBox="0 0 380 70" style={{ width: "100%" }}>
          {/* Only SVG shapes */}
          <rect x="0" y="0" rx="5" ry="5" width="300" height="70" />
        </ContentLoader>
      );
    }

    return (
      <div
        className="variance-neg-pos-value"
        key={highlightedColumnValue}
        defaultValue={params.data.highlightedColumnValue}
      >
        <div
          onClick={() =>
            highlightedColumnValue === params.data.highlightedColumnValue
              ? () => {}
              : handleColumnClick(params)
          }
        >
          <EllipsisWithTooltip>
            <div className="data-sets d-flex">
              <div className="pr-0">
                <span className="value">{valueFormatted}</span>
                {varianceValue === 2 &&
                customField === "laborCostas%ofRevenue" ? (
                  <></>
                ) : (
                  <span
                    className={
                      isPositiveGreen
                        ? isPositive
                          ? "show-pos-variance"
                          : "show-neg-variance"
                        : isPositive
                          ? "show-neg-variance"
                          : "show-pos-variance"
                    }
                  >
                    {diffvalueFormatted}
                  </span>
                )}
              </div>
              {highlightedColumnValue === params.data.highlightedColumnValue ? (
                <span
                  onClick={(event) => {
                    event.stopPropagation();
                    setTimeout(() => {
                      handleColumnClick(params, false);
                    }, 0);
                  }}
                  className="remove-highlight-icon"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="19"
                      height="19"
                      rx="2.5"
                      fill="white"
                    />
                    <path
                      d="M14.2425 4.34424L9.9995 8.58624L5.7575 4.34424L4.34351 5.75824L8.5855 10.0002L4.34351 14.2422L5.7575 15.6562L9.9995 11.4142L14.2425 15.6562L15.6565 14.2422L11.4145 10.0002L15.6565 5.75824L14.2425 4.34424Z"
                      fill="#84888C"
                    />
                    <rect
                      x="0.5"
                      y="0.5"
                      width="19"
                      height="19"
                      rx="2.5"
                      stroke="#E8EAED"
                    />
                  </svg>
                </span>
              ) : !params.data.isChartActive ? (
                <span className="hover-icon col-2 p-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="19"
                      height="19"
                      rx="2.5"
                      fill="white"
                    />
                    <path
                      d="M11 12.414C11.0928 12.5069 11.203 12.5807 11.3243 12.631C11.4456 12.6813 11.5757 12.7072 11.707 12.7072C11.8383 12.7072 11.9684 12.6813 12.0897 12.631C12.211 12.5807 12.3212 12.5069 12.414 12.414L17.414 7.414L16 6L11.707 10.293L9.414 8C9.32121 7.90705 9.21101 7.83332 9.08969 7.78301C8.96838 7.73269 8.83833 7.7068 8.707 7.7068C8.57567 7.7068 8.44562 7.73269 8.32431 7.78301C8.20299 7.83332 8.09279 7.90705 8 8L3 13L4.414 14.414L8.707 10.121L11 12.414Z"
                      fill="#2CAF92"
                    />
                    <rect
                      x="0.5"
                      y="0.5"
                      width="19"
                      height="19"
                      rx="2.5"
                      stroke="#E8EAED"
                    />
                  </svg>
                </span>
              ) : (
                <></>
              )}
            </div>
          </EllipsisWithTooltip>
        </div>

        {highlightedColumnValue === params.data.highlightedColumnValue ? (
          <div className="highlights">
            <div className="highlight-green">{/* Highlight */}</div>
          </div>
        ) : params.data.isChartActive ? (
          <div className="highlights">
            <div className="highlight-grey">{/* Highlight */}</div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  });

  function getLastSortedColumnDataFromHeader(data) {
    const colId = data?.props?.column?.colId;
    const order = data?.order;

    setLastSortedColumnData({
      columnName: colId,
      sortingOrder: order,
    });
  }

  const NotesRender = React.memo((params: any) => {
    let field = params?.colDef?.colId;
    const data = (laborTableNotesData || [])?.filter(
      (x) => x.lettercode === params?.data?.lettercode
    );

    let value = params?.data?.approvalStatus;

    let status = "Completed";
    if (value == "Unapprove") {
      status = "Overdue";
    } else if (value == "Approve") {
      status = "Completed";
    } else {
      status = "";
    }

    if (params?.data?.totalRow || pathArray.length === 0) {
      return (
        <div className="notes-sec text-center daily-approval">
          {data?.length > 0 &&
            status &&
            (status == "Overdue" ? (
              <div className="daily-approval text-center">
                <div className={`d-inline-block dropdown-btn status-overdue`}>
                  <span>{`${status} ${
                    params?.data?.dateDiff?.toString()?.replace("null", "")
                      ? +params?.data?.dateDiff > 1
                        ? params?.data?.dateDiff + " days"
                        : params?.data?.dateDiff + " day"
                      : ""
                  }`}</span>
                </div>
              </div>
            ) : params?.data?.commentBy ? (
              <div
                className="d-inline-block"
                id="dropdown-more"
                //   onClick={(e)=>handleShowTooltip(e,data)}
                onClick={(e) => handleTooltipPosition(e, data)}
              >
                <div
                  className={`d-flex dropdown-btn ${
                    status === "Completed" &&
                    params?.data?.isHotelUnderBudget > 0
                      ? "status-uncompleted"
                      : "status-completed"
                  }`}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M13.5556 2.4H11.3889V1H9.94444V2.4H7.05556V1H5.61111V2.4H3.44444C2.64783 2.4 2 3.0279 2 3.8V13.6C2 14.3721 2.64783 15 3.44444 15H13.5556C14.3522 15 15 14.3721 15 13.6V3.8C15 3.0279 14.3522 2.4 13.5556 2.4ZM3.44444 13.6V4.5H5.61111H7.05556H9.94444H11.3889H13.5556V3.8L13.557 13.6H3.44444Z"></path>
                    <path d="M4.6665 6H11.3332V7.33333H4.6665V6ZM4.6665 8.66667H7.99984V10H4.6665V8.66667Z"></path>
                  </svg>
                  <span>{status}</span>
                </div>
              </div>
            ) : (
              <div className="d-inline-block" id="dropdown-more">
                <div
                  className={`d-flex dropdown-btn ${
                    status === "Completed" &&
                    params?.data?.isHotelUnderBudget > 0
                      ? "status-uncompleted"
                      : "status-completed"
                  }`}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M13.5556 2.4H11.3889V1H9.94444V2.4H7.05556V1H5.61111V2.4H3.44444C2.64783 2.4 2 3.0279 2 3.8V13.6C2 14.3721 2.64783 15 3.44444 15H13.5556C14.3522 15 15 14.3721 15 13.6V3.8C15 3.0279 14.3522 2.4 13.5556 2.4ZM3.44444 13.6V4.5H5.61111H7.05556H9.94444H11.3889H13.5556V3.8L13.557 13.6H3.44444Z"></path>
                    <path d="M4.6665 6H11.3332V7.33333H4.6665V6ZM4.6665 8.66667H7.99984V10H4.6665V8.66667Z"></path>
                  </svg>
                  <span>{status}</span>
                </div>
              </div>
            ))}
        </div>
      );
    } else {
      return <></>;
    }
  });

  const handleTooltipPosition = (event, rowData) => {
    const buttonRect = event.currentTarget.getBoundingClientRect();
    // Calculate the tooltip position based on the button's position
    const tooltipTop = buttonRect.top + buttonRect.height;
    const tooltipLeft = buttonRect.left;
    setTooltipPosition({ x: tooltipTop, y: tooltipLeft });
    setTooltipContent(rowData);

    setTooltipVisible(true);
  };

  const onOutsideClick = (e) => {
    setTooltipPosition({ x: 0, y: 0 });
    setTooltipContent(null);
    setTooltipVisible(false);
  };
  const removeTooltip = (event) => {
    if (event.deltaY != 0) {
      setTooltipPosition({ x: 0, y: 0 });
      setTooltipContent(null);
      setTooltipVisible(false);
    }
  };

  const handlePath = (value, tooltip = null, id) => {
    setPathArray((prev) => {
      let _path = [...prev];
      let _tooltipValue = tooltip ? tooltip : value;

      if (prev.length === 0) {
        _path = [
          { name: "Properties", level: 0, tooltip: "Properties" },
          { name: value, level: 1, id: id, tooltip: _tooltipValue },
        ];
      } else if (prev.length === 2) {
        _path = [
          ..._path,
          { name: value, level: 2, id: id, tooltip: _tooltipValue },
        ];
      }
      return _path;
    });
  };

  const getLastClickedColumn = (formattedData) => {
    const highlightedColumnValueList = formattedData
      .filter((item) => item.highlightedColumnValue)
      .map((item) => item.highlightedColumnValue);

    const highlistedColumn =
      highlightedColumnValueList?.length > 0
        ? highlightedColumnValueList[0]?.split("_")[0]
        : "";
    setLastClickedColumn(highlistedColumn);
  };

  const handlePreviousPath = (index) => {
    const previousDataTable = getCurrentTableData();
    setIsInitialDataDataLoading(true);
    setPathArray((prev) => {
      let _path = [...prev];

      //disable click on current level
      if (index == _path.length - 1) return _path;

      //remove paths
      _path = _path.slice(0, index + 1);

      if (_path.length == 2) {
        setGridColumns(departmentColumnDefs);
        sortingOnColumns(departmentColumnDefs);

        setLevelWiseTableData((prev) => {
          const levelData = [...prev];

          const _filteredTableData = updateShowChartToggle(
            levelData[1],
            true,
            previousDataTable
          );
          setFilteredTableData([..._filteredTableData]);
          setTableData(_filteredTableData);
          const column = getSelectedColumn(_filteredTableData);
          setSelectedColumn(column);

          levelData[1] = _filteredTableData;
          levelData.pop();

          getLastClickedColumn(_filteredTableData);
          return levelData;
        });
      } else if (_path.length == 1) {
        //if only properties then don't show any thing
        _path = [];
        if (isAbbrEHIDNameChecked) {
          const index1 = columnDefs.findIndex((x) => x.field === "lettercode");
          if (index1 >= 0) {
            columnDefs[index1].hide = true;
          }
          const index2 = columnDefs.findIndex(
            (x) => x.field === "hotelNameAbbr"
          );
          if (index2 >= 0) {
            columnDefs[index2].hide = false;
          }
        } else {
          const index1 = columnDefs.findIndex((x) => x.field === "lettercode");
          if (index1 >= 0) {
            columnDefs[index1].hide = false;
          }
          const index2 = columnDefs.findIndex(
            (x) => x.field === "hotelNameAbbr"
          );
          if (index2 >= 0) {
            columnDefs[index2].hide = true;
          }
        }

        setGridColumns(columnDefs);
        sortingOnColumns(columnDefs);

        setLevelWiseTableData((prev) => {
          const levelData = [...prev];

          const _filteredTableData = updateShowChartToggle(
            levelData[0],
            true,
            previousDataTable
          );
          setFilteredTableData([..._filteredTableData]);
          setTableData(_filteredTableData);
          getLastClickedColumn(_filteredTableData);
          const column = getSelectedColumn(_filteredTableData);
          setSelectedColumn(column);

          return [_filteredTableData];
        });
      }

      return _path;
    });

    // setIsInitialDataDataLoading(false)
    setTimeout(() => {
      setIsInitialDataDataLoading(false);
    }, 5);
    // setLastClickedColumn("")
  };

  function resetDrillDown() {
    if (isAbbrEHIDNameChecked) {
      const index1 = columnDefs.findIndex((x) => x.field === "lettercode");
      if (index1 >= 0) {
        columnDefs[index1].hide = true;
      }
      const index2 = columnDefs.findIndex((x) => x.field === "hotelNameAbbr");
      if (index2 >= 0) {
        columnDefs[index2].hide = false;
      }
    } else {
      const index1 = columnDefs.findIndex((x) => x.field === "lettercode");
      if (index1 >= 0) {
        columnDefs[index1].hide = false;
      }
      const index2 = columnDefs.findIndex((x) => x.field === "hotelNameAbbr");
      if (index2 >= 0) {
        columnDefs[index2].hide = true;
      }
    }

    setIsAbbrEHIDNameChecked(false);

    setPathArray([]);
    setGridColumns(columnDefs);
    sortingOnColumns(columnDefs);
    setFilteredTableData([]);
    setLevelWiseTableData([]);
    setDepartmentTotalRow(null);
    setPositionTotalRow(null);
    setTableData([]);
    setLastClickedColumn("");
    setShowChart(false);
    setPositionDetailsRow({});
    if (agGridRef?.current) {
      agGridRef.current!.api.setPinnedTopRowData(null);
    }

    setIsSlideOutShow(false);
    setResetCalled((prev) => !prev);
  }

  const updateShowChartToggle = (
    _filteredTableData,
    isDefaultLoad = false,
    previousData
  ) => {

    
    const isShowChart =
      _filteredTableData?.filter(
        (x) => x?.manuallyChartActive || x?.highlightedColumnValue
      ).length > 0;
    _filteredTableData = _filteredTableData?.map((item) => ({
      ...item,
      isChartActive: isShowChart,
    }));
    if (isDefaultLoad) {
      setShowChart(isShowChart);
    } else {
      // const prev = getCurrentTableData()
      const prev = previousData;
      // setFilteredTableData(prev =>{
      const _prevFilterTable = _.cloneDeep(prev);
      const previsShowChart =
        _prevFilterTable?.filter(
          (x) => x?.manuallyChartActive || x?.highlightedColumnValue
        ).length > 0;
      setShowChart(previsShowChart);

      //     return prev
      // })
    }
    if (!isShowChart) {
      setLastClickedColumn("");
    }
    
    scrollToSelectedRow(_filteredTableData)
    return _filteredTableData;
  };

  const handleDrillDown = async (data, level) => {
    //write code to filter depeneding on column

    const previousDataTable = getCurrentTableData();

    setIsInitialDataDataLoading(true);
    if (level === 0) {
      setIsAbbrEHIDNameChecked(
        agGridRef?.current?.columnApi?.columnController?.columnDefs?.filter(
          (x) => x?.field === "hotelNameAbbr" && !x?.hide
        ).length === 1
      );

      setGridColumns(departmentColumnDefs);
      sortingOnColumns(departmentColumnDefs, true);

      const totalRowData = previousDataTable.find(
        (x) => x.hotelid == data.hotelid
      );
      const totalRow = {
        totalRow: true,
        department: "Total",
        ...totalRowData,
      };
      setDepartmentTotalRow(totalRow);

      // _filteredData = originTableData.filter(item => item?.hotelid === data?.hotelid)

      // _filteredData = getFormattedTableData([..._filteredData], "departmentID", "department")

      let params: any = {
        HID: data.hotelid?.toString() + ",",
        DataDate: calendarDate,
        varianceType: varianceValue,
        TimePeriod: periodValue,
        drilldownlevel: 1,
        DeptId: 0,
      };
      let _filteredData = await LaborDashBoard.getLaborDashboardData(
        params
      ).catch((error) => {
        if ((error as any)?.message === "Cancel") {
          setIsInitialDataDataLoading(true);
        } else {
          Utils.toastError(`Something Went Wrong, Please Try Again`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "laborDashBoard",
          });
          setIsInitialDataDataLoading(false);
        }
      });
      _filteredData =
        _filteredData && _filteredData.length > 0
          ? getFormattedTableData([..._filteredData], "department")
          : [..._filteredData];

      handlePath(data.lettercode, data.hotelName, data.hotelid);

      setFilteredTableData((_prev) => {
        // const prev = getCurrentTableData()
        let filteredDataPrev = _.cloneDeep(previousDataTable);
        filteredDataPrev = filteredDataPrev.filter(
          (item) => item.hotelid == data?.hotelid
        );
        const headerName = getSelectedColumn(filteredDataPrev);
        if (headerName) {
          _filteredData = _filteredData.map((item, index) => ({
            ...item,
            highlightedColumnValue:
              index < MAXLIMIT
                ? `${headerName}_${level + 1}_${item.departmentID}`
                : null,
          }));
          setLastClickedColumn(headerName);
        } else {
          setLastClickedColumn("");
        }

        if (_filteredData.length > 0) {
          //   agGridRef?.current?.api?.applyTransaction({
          //     update:_filteredData
          //   })
          _filteredData = updateShowChartToggle(
            _filteredData,
            false,
            previousDataTable
          );
          setTableData(_filteredData);

          const selectedids = getSelectedHIdsChart(_filteredData, level);
          setSelectedHids(selectedids);
          if (!headerName) {
            const _headerName = getSelectedColumn(
              _.cloneDeep(_prev).filter((x) => x?.highlightedColumnValue)
            );
            setSelectedColumn(_headerName);
          } else {
            setSelectedColumn(headerName);
          }
        }

        setLevelWiseTableData((prev) => {
          const levelData = [...prev];
          levelData.splice(level + 1, 0, _filteredData);
          return levelData;
        });

        return [..._filteredData];
      });
    } else if (level === 1) {
      setGridColumns(positionColumnDefs);
      sortingOnColumns(positionColumnDefs, true);
      const totalRowData = previousDataTable.find(
        (x) => x.hotelid == data.hotelid && x.departmentID == data.departmentID
      );
      const totalRow = {
        totalRow: true,
        position: "Total",
        ...totalRowData,
      };
      setPositionTotalRow(totalRow);

      let params: any = {
        HID: pathArray[1].id?.toString() + ",",
        DataDate: calendarDate,
        varianceType: varianceValue,
        TimePeriod: periodValue,
        drilldownlevel: 2,
        DeptId: data.departmentID,
      };
      let _filteredData = await LaborDashBoard.getLaborDashboardData(
        params
      ).catch((error) => {
        if ((error as any)?.message === "Cancel") {
          setIsInitialDataDataLoading(true);
        } else {
          Utils.toastError(`Something Went Wrong, Please Try Again`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "laborDashBoard",
          });
          setIsInitialDataDataLoading(false);
        }
      });
      _filteredData =
        _filteredData && _filteredData.length > 0
          ? getFormattedTableData([..._filteredData], "position")
          : [..._filteredData];

      handlePath(data.department, null, data.departmentID);

      setFilteredTableData((_prev) => {
        // const prev = getCurrentTableData()
        let filteredDataPrev = _.cloneDeep(previousDataTable);
        filteredDataPrev = filteredDataPrev.filter(
          (item) =>
            item.hotelid == data?.hotelid &&
            item.departmentID == data?.departmentID
        );
        const headerName = getSelectedColumn(filteredDataPrev);
        if (headerName) {
          _filteredData = _filteredData.map((item, index) => ({
            ...item,
            highlightedColumnValue:
              index < MAXLIMIT
                ? `${headerName}_${level + 1}_${item.positionID}`
                : null,
          }));
          setLastClickedColumn(headerName);
        } else {
          setLastClickedColumn("");
        }

        setLevelWiseTableData((prev) => {
          const levelData = [...prev];
          levelData.splice(level + 1, 0, _filteredData);
          return levelData;
        });

        if (_filteredData.length > 0) {
          _filteredData = updateShowChartToggle(
            _filteredData,
            false,
            previousDataTable
          );
          setTableData(_filteredData);

          const selectedids = getSelectedHIdsChart(_filteredData, level);
          setSelectedHids(selectedids);

          if (!headerName) {
            const _headerName = getSelectedColumn(
              _.cloneDeep(_prev).filter((x) => x?.highlightedColumnValue)
            );
            setSelectedColumn(_headerName);
          } else {
            setSelectedColumn(headerName);
          }
        }
        return [..._filteredData];
      });
    }

    setTimeout(() => {
      setIsInitialDataDataLoading(false);
    }, 50);
  };

  const handlePerformanceSlideOut = (data, date = calendarDate) => {
    const _positionSlideoutData = {
      HotelId: data?.hotelid,
      HotelName: data.lettercode,
      PositionId: data.positionID,
      positionName: data.position,
      DeptId: data.departmentID,
      deptName: data.department,
      showBudgetedHours: true,

      Date: date,

      breadDepartments: { id: data.departmentID, name: data.department },
      breadPositions: { id: data.positionID, name: data.position },
      budgetedHours: Utils.getMinutesFromHHMMDOT(
        data?.totalHoursVariance
          ?.toString()
          .replace(":", ".")
          .replaceAll(",", "") || 0
      ),
      Employee: "",
    };
    setPositionDetailsRow(_positionSlideoutData);
    setIsSlideOutShow(true);
  };

  const onChangeMetricParameter = (item) => {
    setIsInitialDataDataLoading(true);

    const prev = getCurrentTableData();

    let headerName = item?.label;

    setLastClickedColumn(headerName);

    let _filteredTableData = _.cloneDeep(prev);

    _filteredTableData = _filteredTableData.map((item) => {
      if (item.highlightedColumnValue) {
        let highlightedcolValue = item.highlightedColumnValue;
        highlightedcolValue = highlightedcolValue.split("_");
        highlightedcolValue[0] = headerName;

        highlightedcolValue = highlightedcolValue.join("_");
        return {
          ...item,
          highlightedColumnValue: highlightedcolValue,
        };
      } else {
        return item;
      }
    });

    _filteredTableData = _filteredTableData.map((item) => ({
      ...item,
      isChartActive:
        _filteredTableData?.filter((x) => x?.highlightedColumnValue).length > 0,
    }));

    updateTableData(_filteredTableData);

    setIsInitialDataDataLoading(false);
  };

  const updateTableSelectedData = (selectedHids, selectedHeaderName) => {
    const previousDataTable = getCurrentTableData();
    setIsInitialDataDataLoading(true);

    const prev = getCurrentTableData();
    let _filteredTableData = _.cloneDeep(prev);
    let keyName = "";
    let highlightKey = "";
    let level = 0;
    if (pathArray?.length == 2) {
      keyName = "departmentID";
      highlightKey = "departmentID";
      level = 1;
    } else if (pathArray?.length == 3) {
      keyName = "positionID";
      highlightKey = "positionID";
      level = 2;
    } else {
      keyName = "hotelid";
      highlightKey = "lettercode";
    }

    _filteredTableData = _filteredTableData.map((item, index) => {
      if (selectedHids?.includes(+item[keyName])) {
        let highlightedcolValue = `${selectedHeaderName}_${level}_${item[highlightKey]}`;
        if (!item.highlightedColumnValue) {
          highlightedcolValue = highlightedcolValue;
        } else {
          highlightedcolValue = item.highlightedColumnValue;
        }

        return {
          ...item,
          highlightedColumnValue: highlightedcolValue,
        };
      } else {
        return { ...item, highlightedColumnValue: null };
      }
    });

    _filteredTableData = updateShowChartToggle(
      _filteredTableData,
      true,
      previousDataTable
    );

    updateTableData(_filteredTableData);

    if (selectedHids.length === 0) {
      if (
        _filteredTableData.filter((item) => item.highlightedColumnValue)
          .length == 0
      ) {
        agGridRef.current.api.refreshCells({
          force: true,
        });
      }
    }

    if (_filteredTableData.length > 20) {
      setFilteredTableData(_filteredTableData);
      setTimeout(() => {
        setIsInitialDataDataLoading(false);
      }, 20);
    } else {
      setIsInitialDataDataLoading(false);
    }
  };

  const NameRender = React.memo((params: any) => {
    if (params?.data?.totalRow) return <TotalRowRender params={params} />;

    let field = params?.colDef?.field;
    const currentLevelData = getLevelKeys(params);

    return (
      <div
        onClick={() => {
          if (currentLevelData.level === 2) {
            handlePerformanceSlideOut(params?.data);
          } else {
            handleDrillDown(params?.data, currentLevelData.level);
          }
        }}
      >
        {field === "lettercode" ? (
          <OverlayTrigger
            placement="bottom"
            defaultShow={false}
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip id={"ehid-name"}>
                {field === "lettercode"
                  ? params?.data["hotelName"]
                  : params?.data[field]}
              </Tooltip>
            }
          >
            <span
              className="ehid-name width-auto  inline-block "
              onWheel={hoverOutEllipsis}
            >
              {params?.data[field]}
            </span>
          </OverlayTrigger>
        ) : (
          <EllipsisWithTooltip placement="bottom">
            <span
              className="ehid-name  inline-block w-100"
              onWheel={hoverOutEllipsis}
            >
              {params?.data[field]}
            </span>
          </EllipsisWithTooltip>
        )}
      </div>
    );
  });

  const getSelectedHotelsIds = (data) => {
    const _tableData = _.cloneDeep(data);

    const filteredData = _tableData.filter((x) => x?.highlightedColumnValue);
    let res: any[] = [];

    if (filteredData.length > 0) {
      if (pathArray?.length == 2) {
        res = [pathArray[1].id?.toString()];
      } else if (pathArray?.length == 3) {
        res = [pathArray[1].id?.toString()];
      } else {
        res = filteredData.map((item) => item.hotelid);
      }
    } else {
      res = [];
    }

    return res.join(",");
  };

  const getSelectedHIds = (data) => {
    const _tableData = _.cloneDeep(data);

    const filteredData = _tableData.filter((x) => x?.highlightedColumnValue);
    let res: any[] = [];

    if (filteredData.length > 0) {
      if (pathArray?.length == 2) {
        res = filteredData.map((item) => item.departmentID);
      } else if (pathArray?.length == 3) {
        res = filteredData.map((item) => item.positionID);
      } else {
        res = filteredData.map((item) => item.hotelid);
      }
    } else {
      res = [];
    }

    return res.join(",");
  };

  const getSelectedRowIds = (data) => {
    const filteredData = _.cloneDeep(data);

    let res: any[] = [];

    if (filteredData.length > 0) {
      if (pathArray?.length == 2) {
        res = filteredData.map((item) => item.departmentID);
      } else if (pathArray?.length == 3) {
        res = filteredData.map((item) => item.positionID);
      } else {
        res = filteredData.map((item) => item.hotelid);
      }
    } else {
      res = [];
    }

    return res.join(",");
  };

  const getSelectedHIdsChart = (data, level = 0) => {
    const _tableData = _.cloneDeep(data);

    const filteredData = _tableData.filter((x) => x?.highlightedColumnValue);
    let res = [];

    if (filteredData.length > 0) {
      if (level == 0) {
        res = filteredData.map((item) => item.departmentID);
      } else if (level == 1) {
        res = filteredData.map((item) => item.positionID);
      } else {
        res = filteredData.map((item) => item.hotelid);
      }
    } else {
      res = [];
    }

    return res.join(",");
  };

  const getSelectedColumn = (data): any => {
    const _tableData = _.cloneDeep(data);

    const filteredItem = _tableData.find((x) => x?.highlightedColumnValue);
    let res = null;
    if (filteredItem) {
      res = filteredItem.highlightedColumnValue.split("_")[0];
    }

    return res;
  };

  const getAgGridOptions = () => {
    const gridOptions: GridOptions = {
      rowData: [],
      overlayNoRowsTemplate: "No data found",
      rowClass: "user-table-row",

      getRowHeight: (params) => {
        return 36;
      },
      headerHeight: 48,
      frameworkComponents: {
        valueRender: ValueRender,
        checkBoxRender: CheckBoxRender,
        nameRender: NameRender,
        notesRender: NotesRender,
        agColumnHeader: React.memo(LaborCustomHeader),
      },

      rowSelection: "single",
    };
    return gridOptions;
  };

  const TripleMenu = () => {
    return (
      <Dropdown className="more-action" alignRight>
        <Dropdown.Toggle
          className="btn-outline-primary btn btn-primary more"
          id="dropdown-more"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
            <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
            <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
          </svg>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              handleSaveDefaultView(false, toggleColumnData, {
                selectedSorting,
                lastSortedColumnData,
              });
            }}
          >
            Save as Default
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              downloadLaborDashbord();
            }}
          >
            Download as Excel
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const getRowNodeId = (data, path) => {
    return data.id;
  };

  const toggleColumnCheck = (e: any, data: any, idx: any) => {
    let checked = e.target.checked;
    let _gridColumns = _.cloneDeep(gridColumns);
    for (let i = 0; i < _gridColumns.length; i++) {
      if (_gridColumns[i].colId === data.colName) {
        _gridColumns[i].hide = !checked;
        break;
      }
    }

    if (checked) {
      currentColumnSelected = currentColumnSelected + 1;
    } else {
      currentColumnSelected = currentColumnSelected - 1;
    }

    let _toggleColumnData = _.cloneDeep(toggleColumnData);

    _toggleColumnData[idx].checked = checked;
    let isSelectAllCols = false;
    if (
      _toggleColumnData?.filter((item) => item?.checked)?.length ===
      maxColumnSelected
    ) {
      isSelectAllCols = true;
    }

    const toggleIndex = _gridColumns.findIndex((x) => x.colId === data.colName);
    if (toggleIndex < 2 && getDrillDownLevel() == 0) {
      if (toggleIndex === 0) {
        _toggleColumnData[0].disabled = true;
        _gridColumns[1].hide = true;
        _toggleColumnData[1].disabled = false;
        _toggleColumnData[1].checked = false;
      } else {
        _toggleColumnData[1].disabled = true;
        _gridColumns[0].hide = true;
        _toggleColumnData[0].disabled = false;
        _toggleColumnData[0].checked = false;
      }
    }

    if (
      _toggleColumnData?.filter((x) => x.checked)?.length < 2 ||
      (_toggleColumnData?.filter(
        (x) => x.checked && x?.colDispalyName === "Daily Approval"
      )?.length > 0 &&
        _toggleColumnData?.filter((x) => x.checked)?.length < 3)
    ) {
      Utils.toastError("At least one chart data column should be selected", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "laborDashBoard",
      });

      return;
    }

    if (_toggleColumnData?.filter((x) => x.checked)?.length > 7) {
      Utils.toastError("You can select a maximum of 7 columns at one time", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "laborDashBoard",
      });
      return;
    }

    setGridColumns(_gridColumns);
    sortingOnColumns(_gridColumns,false,_toggleColumnData);
    setCurrentColumnSelected(currentColumnSelected);
    setToggleColumnData(_toggleColumnData);
    setIsSelectAllCols(isSelectAllCols);

    if (!checked) {
      const tableData = getCurrentTableData();
      const _filteredTableData = tableData.map((item) => {
        if (
          item?.highlightedColumnValue?.startsWith(
            _toggleColumnData[idx]?.colDispalyName
          )
        ) {
          item.highlightedColumnValue = "";
        }
        return item;
      });

      updateTableData(_filteredTableData);
    }
  };

  const onSelectAllCols = (event) => {
    let colData = _.cloneDeep(toggleColumnData);
    if (event.target.checked) {
      colData = colData.map((item) => {
        item.checked = true;
        return item;
      });
    } else {
      colData = colData.map((item) => {
        if (!item?.disabled) {
          item.checked = false;
        }
        return item;
      });
    }

    let _gridColumns = _.cloneDeep(gridColumns);
    for (let i = 0; i < _gridColumns.length; i++) {
      if (
        colData.find(
          (item) => item?.colName === gridColumns[i].colId && !item?.disabled
        )
      ) {
        _gridColumns[i].hide = !event.target.checked;
      }
    }

    currentColumnSelected = colData.filter((item) => item?.checked).length;
    setGridColumns(_gridColumns);
    setCurrentColumnSelected(currentColumnSelected);
    setToggleColumnData(colData);
    setIsSelectAllCols(event.target.checked);
  };

  const toggleColumnDropdownToggle = (value: boolean) => {
    setColumnDropdownToggle(value);
  };

  const getGridColums = (columns=gridColumns,toggleData=toggleColumnData) => {
    let _gridColumns = _.cloneDeep(columns);

    if (toggleData?.length > 0) {
      toggleData.forEach((data) => {
        for (let i = 0; i < _gridColumns.length; i++) {
          if (_gridColumns[i].colId === data.colName) {
            _gridColumns[i].hide = !data.checked;
          }
        }
      });

      if (periodValue !== 1) {
        _gridColumns = _gridColumns.filter(
          (x) => x.headerName !== "Daily Approval"
        );
      }
    }

    return _gridColumns;
  };
  return (
    <>
      {isSlideOutShow && (
        <div id="performanceSlideOut">
          <PerformanceSlideout
            hotelName={pathArray[1]?.name}
            DataParameter={positionDetailsRow}
            hideSlideOut={() => {
              setIsSlideOutShow(false);
            }}
          />
        </div>
      )}
      <div
        className="labor-daily-flash-container main-page labor-dashboard-tbl"
        onWheel={(e) => removeTooltip(e)}
      >
        <div className="navigation-menus dailyLaborFlashTableNavigation">
          <div className="d-flex table-navigation-items align-items-center flex-wrap">
            <div className="date-column">
              <div className="period-selector period-selector-dropdwn">
                {Periods?.length > 0 && (
                  <PeriodSelector
                    id={"chartPeriodSelector"}
                    defaultValue={periodValue}
                    onChange={onChartPeriodChange}
                    periods={Periods}
                  ></PeriodSelector>
                )}
              </div>
            </div>
            <div className="varience-column">
              {dataComparisonList?.length > 0 && (
                <div className="period-selector mr-auto">
                  <DropDownList
                    placeholderLabel={"Data Comparison"}
                    data={dataComparisonList}
                    label={"label"}
                    value={"value"}
                    isSearchRequired={false}
                    defaultValue={varianceValue}
                    dependent={varianceValue}
                    onDropDownChange={onComparisonPeriodChange}
                    selectedItem={dataComparisonList.find(
                      (x) => x.value === varianceValue
                    )}
                  />
                </div>
              )}
            </div>

            <div className="d-flex group-selector mr-3">
              {isDefaultSaveDataLoaded && (
                <>
                  <HotelGroupSelector
                    isDashboard={true}
                    hotelIds={hotelIds}
                    subGroupSelectValue={subGroupSelectValue}
                    onHotelChange={handleHotelChange}
                    notIncludeEnterpriseAccounts={false} // default Props
                    showOnlyHotelAccounts={false} // default Props
                    moduleName={moduleName} // default Props
                  ></HotelGroupSelector>
                </>
              )}
            </div>
            <div className="sorting-column px-3">
              <div className="period-selector period-selector-dropdwn ">
                {sortDropdDownData?.length > 0 && (
                  <SortSelector
                    id={"chartPeriodSelector"}
                    defaultValue={selectedSorting}
                    onChange={(item) => setSelectedSorting(item.value)}
                    data={sortDropdDownData}
                  ></SortSelector>
                )}
              </div>
            </div>

            <div className="visualize-column d-flex align-items-center justify-content-end">
              <FormLabel className="mb-0">Visualize</FormLabel>
              <Form.Check
                checked={showChart}
                onChange={(e) => {
                  if (!isInitialDataDataLoading) {
                    handleVisualize(e.target.checked, pathArray);
                  }
                }}
                type="switch"
                className="sm-controll toggleButtonRP"
                label=""
                id="daily-flash-switch"
              />
            </div>
            <TableColumnSelector
              toggleColumnData={toggleColumnData}
              drillDownLevel={getDrillDownLevel()}
              toggleColumnCheck={toggleColumnCheck}
            />

            <TripleMenu />
          </div>
        </div>

        {pathArray?.length > 0 && (
          <div className="heading-breadcrumbs d-flex flex-direction-column">
            <Breadcrumb>
              {pathArray.map((item, index) => (
                <Breadcrumb.Item
                  key={item?.name}
                  active={index === pathArray.length - 1}
                  onClick={() =>
                    index === pathArray.length - 1
                      ? () => {}
                      : handlePreviousPath(index)
                  }
                >
                  {item.level === 1 ? (
                    <OverlayTrigger
                      placement="bottom"
                      defaultShow={false}
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip id={"ehid-name"}>{item.tooltip}</Tooltip>
                      }
                    >
                      <span className="ehid-name">{item.name}</span>
                    </OverlayTrigger>
                  ) : (
                    <span className="ehid-name">{item.name}</span>
                  )}
                </Breadcrumb.Item>
              ))}
            </Breadcrumb>
          </div>
        )}

        <div
          className={`user-management-table userMgtDailyFlash ${
            pathArray?.length > 0 ? "table-height-with-breadcrumb" : ""
          }`}
        >
          <div
            id="myGrid"
            className={"ag-theme-balham daily-flash-tbl-wrapper"}
          >
            {isInitialDataDataLoading ? (
              <ReactPageLoader useas={"dashboardTable"} />
            ) : (
              <div className={showChart ? "default-table-height" : ""}>
                <AgGridReact
                  ref={agGridRef}
                  getRowNodeId={(data) => getRowNodeId(data, pathArray)}
                  onGridReady={onGridReady}
                  domLayout={"autoHeight"}
                  rowData={hotelIds == 0 ? [] : [...filteredTableData]}
                  columnDefs={getGridColums()}
                  gridOptions={getAgGridOptions()}
                  animateRows={false}
                  defaultColDef={defaultColDef}
                />

                {tooltipVisible && tooltipContent != "" && (
                  <OutsideClickHandler
                    onOutsideClick={(e) => {
                      setAccordionBtn("");
                      onOutsideClick(e);
                    }}
                  >
                    <div
                      className="approval-dropdown daily-approval daily-approval-popup"
                      style={{
                        position: "fixed",
                        top: tooltipPosition.x + "px",
                        left: tooltipPosition.y - 178 + "px",
                        zIndex: 99,
                      }}
                    >
                      {/* comment below code */}

                      <>
                        {" "}
                        {tooltipContent?.length > 1 ? (
                          <Accordion
                            onSelect={(e) => setAccordionBtn(e)}
                            onWheel={(e) => e.stopPropagation()}
                          >
                            <>
                              {" "}
                              {tooltipContent.map((item, i) => {
                                let _status = "Completed";
                                if (item.approvalStatus == "Unapprove") {
                                  _status = "Overdue";
                                } else if (item.approvalStatus == "Approve") {
                                  _status = "Completed";
                                } else {
                                  _status = "";
                                }

                                return (
                                  <Card>
                                    <Card.Header>
                                      <Accordion.Toggle
                                        eventKey={i.toString()}
                                        className={`accordionBtn ${
                                          accordionBtn === i?.toString()
                                            ? "active-icon"
                                            : ""
                                        }`}
                                      >
                                        <FontAwesomeIcon
                                          icon={faChevronRight}
                                        />
                                        <div className="dropdown-header d-flex py-0">
                                          <div className="profile-img d-flex align-items-center">
                                            <img
                                              src={profile}
                                              className={"default"}
                                              width="40"
                                              height="40"
                                            />
                                            <div className="text-left">
                                              <h4 className="main-title">
                                                {item.commentBy}
                                              </h4>
                                              <span>
                                                {item.createdDate
                                                  ? moment(
                                                      item.createdDate
                                                    ).format(
                                                      "MMMM Do [at] h:mm a"
                                                    )
                                                  : ""}
                                              </span>
                                            </div>
                                          </div>

                                          <div>
                                            <div
                                              className={`d-flex dropdown-btn ${
                                                _status === "Completed" &&
                                                item?.budget == "Over"
                                                  ? "status-uncompleted"
                                                  : "status-completed"
                                              }`}
                                            >
                                              <svg
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path d="M13.5556 2.4H11.3889V1H9.94444V2.4H7.05556V1H5.61111V2.4H3.44444C2.64783 2.4 2 3.0279 2 3.8V13.6C2 14.3721 2.64783 15 3.44444 15H13.5556C14.3522 15 15 14.3721 15 13.6V3.8C15 3.0279 14.3522 2.4 13.5556 2.4ZM3.44444 13.6V4.5H5.61111H7.05556H9.94444H11.3889H13.5556V3.8L13.557 13.6H3.44444Z"></path>
                                                <path d="M4.6665 6H11.3332V7.33333H4.6665V6ZM4.6665 8.66667H7.99984V10H4.6665V8.66667Z"></path>
                                              </svg>
                                              <span>{_status}</span>
                                            </div>
                                          </div>
                                        </div>
                                      </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey={i.toString()}>
                                      <Card.Body>
                                        <div className="d-flex dropdown-row justify-content-between budget-status">
                                          <div>
                                            <h3>Budget</h3>
                                            <div
                                              className={
                                                item?.budget == "Under"
                                                  ? "text-green"
                                                  : "text-red"
                                              }
                                            >
                                              <FontAwesomeIcon
                                                icon={
                                                  item?.budget == "Under"
                                                    ? faCheck
                                                    : faClose
                                                }
                                              />
                                              <span>{item?.budget}</span>
                                            </div>
                                          </div>
                                          <div>
                                            <h3>Streak</h3>
                                            <span>
                                              {item?.streak}{" "}
                                              {+item?.streak > 1
                                                ? " days"
                                                : " day"}{" "}
                                            </span>
                                          </div>
                                          <div>
                                            <h3>Monthly</h3>
                                            <span>
                                              {item?.monthly?.toFixed(2)}%
                                            </span>
                                          </div>
                                        </div>

                                        {item?.explainInRedComment && (
                                          <div className="content">
                                            <h5 className="main-title mb-2">
                                              Explanation
                                            </h5>
                                            <span className="text">
                                              {item?.explainInRedComment}
                                            </span>
                                          </div>
                                        )}
                                        {item?.actionItemComment && (
                                          <div className="content">
                                            <h5 className="main-title mb-2">
                                              Next Step
                                            </h5>
                                            <span className="text">
                                              {item?.actionItemComment}
                                            </span>
                                          </div>
                                        )}
                                        {item?.additionalNotes && (
                                          <div className="content">
                                            <h5 className="main-title mb-2">
                                              Additional Notes
                                            </h5>
                                            <span className="text">
                                              {item?.additionalNotes}
                                            </span>
                                          </div>
                                        )}
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                );
                              })}
                            </>
                          </Accordion>
                        ) : tooltipContent?.length > 0 ? (
                          <div className="dropdown_menu daily-approval-popup">
                            <div>
                              <div className="d-flex dropdown-header">
                                <div className="profile-img">
                                  <img
                                    src={profile}
                                    className={"default"}
                                    width="40"
                                    height="40"
                                  />
                                </div>
                                <div>
                                  <h4 className="main-title">
                                    {tooltipContent[0]?.commentBy}
                                  </h4>
                                  <span>
                                    {tooltipContent[0]?.createdDate
                                      ? moment(
                                          tooltipContent[0]?.createdDate
                                        ).format("MMMM Do [at] h:mm a")
                                      : ""}
                                  </span>
                                </div>
                                <div></div>
                              </div>
                            </div>
                            <div className="d-flex dropdown-row justify-content-between budget-status">
                              <div>
                                <h3>Budget</h3>
                                <div
                                  className={
                                    tooltipContent[0]?.budget == "Under"
                                      ? "text-green"
                                      : "text-red"
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={
                                      tooltipContent[0]?.budget == "Under"
                                        ? faCheck
                                        : faClose
                                    }
                                  />
                                  <span>{tooltipContent[0]?.budget}</span>
                                </div>
                              </div>
                              <div>
                                <h3>Streak</h3>
                                <span>
                                  {tooltipContent[0]?.streak}{" "}
                                  {+tooltipContent[0]?.streak > 1
                                    ? " days"
                                    : " day"}{" "}
                                </span>
                              </div>
                              <div>
                                <h3>Monthly</h3>
                                <span>
                                  {tooltipContent[0]?.monthly?.toFixed(2)}%
                                </span>
                              </div>
                            </div>
                            {tooltipContent[0]?.explainInRedComment && (
                              <div className="content">
                                <h5 className="main-title mb-2">Explanation</h5>
                                <span className="text">
                                  {tooltipContent[0]?.explainInRedComment}
                                </span>
                              </div>
                            )}
                            {tooltipContent[0]?.actionItemComment && (
                              <div className="content">
                                <h5 className="main-title mb-2">Next Step</h5>
                                <span className="text">
                                  {tooltipContent[0]?.actionItemComment}
                                </span>
                              </div>
                            )}
                            {tooltipContent[0]?.additionalNotes && (
                              <div className="content">
                                <h5 className="main-title mb-2">
                                  Additional Notes
                                </h5>
                                <span className="text">
                                  {tooltipContent[0]?.additionalNotes}
                                </span>
                              </div>
                            )}
                          </div>
                        ) : (
                          ""
                        )}{" "}
                      </>
                    </div>
                  </OutsideClickHandler>
                )}

                {tooltipVisible && tooltipContent != "" && (
                  <OutsideClickHandler
                    onOutsideClick={(e) => {
                      setAccordionBtn("");
                      onOutsideClick(e);
                    }}
                  >
                    <div
                      className="approval-dropdown daily-approval daily-approval-popup"
                      style={{
                        position: "fixed",
                        top: tooltipPosition.x + "px",
                        left: tooltipPosition.y - 178 + "px",
                        zIndex: 99,
                      }}
                    >
                      {/* comment below code */}

                      <>
                        {" "}
                        {tooltipContent?.length > 1 ? (
                          <Accordion
                            onSelect={(e) => setAccordionBtn(e)}
                            onWheel={(e) => e.stopPropagation()}
                          >
                            <>
                              {" "}
                              {tooltipContent.map((item, i) => {
                                let _status = "Completed";
                                if (item.approvalStatus == "Unapprove") {
                                  _status = "Overdue";
                                } else if (item.approvalStatus == "Approve") {
                                  _status = "Completed";
                                } else {
                                  _status = "";
                                }

                                return (
                                  <Card>
                                    <Card.Header>
                                      <Accordion.Toggle
                                        eventKey={i.toString()}
                                        className={`accordionBtn ${
                                          accordionBtn === i?.toString()
                                            ? "active-icon"
                                            : ""
                                        }`}
                                      >
                                        <FontAwesomeIcon
                                          icon={faChevronRight}
                                        />
                                        <div className="dropdown-header d-flex py-0">
                                          <div className="profile-img d-flex align-items-center">
                                            <img
                                              src={profile}
                                              className={"default"}
                                              width="40"
                                              height="40"
                                            />
                                            <div className="text-left">
                                              <h4 className="main-title">
                                                {item.commentBy}
                                              </h4>
                                              <span>
                                                {item.createdDate
                                                  ? moment(
                                                      item.createdDate
                                                    ).format(
                                                      "MMMM Do [at] h:mm a"
                                                    )
                                                  : ""}
                                              </span>
                                            </div>
                                          </div>

                                          <div>
                                            <div
                                              className={`d-flex dropdown-btn ${
                                                _status === "Completed" &&
                                                item?.budget == "Over"
                                                  ? "status-uncompleted"
                                                  : "status-completed"
                                              }`}
                                            >
                                              <svg
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path d="M13.5556 2.4H11.3889V1H9.94444V2.4H7.05556V1H5.61111V2.4H3.44444C2.64783 2.4 2 3.0279 2 3.8V13.6C2 14.3721 2.64783 15 3.44444 15H13.5556C14.3522 15 15 14.3721 15 13.6V3.8C15 3.0279 14.3522 2.4 13.5556 2.4ZM3.44444 13.6V4.5H5.61111H7.05556H9.94444H11.3889H13.5556V3.8L13.557 13.6H3.44444Z"></path>
                                                <path d="M4.6665 6H11.3332V7.33333H4.6665V6ZM4.6665 8.66667H7.99984V10H4.6665V8.66667Z"></path>
                                              </svg>
                                              <span>{_status}</span>
                                            </div>
                                          </div>
                                        </div>
                                      </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey={i.toString()}>
                                      <Card.Body>
                                        <div className="d-flex dropdown-row justify-content-between budget-status">
                                          <div>
                                            <h3>Budget</h3>
                                            <div
                                              className={
                                                item?.budget == "Under"
                                                  ? "text-green"
                                                  : "text-red"
                                              }
                                            >
                                              <FontAwesomeIcon
                                                icon={
                                                  item?.budget == "Under"
                                                    ? faCheck
                                                    : faClose
                                                }
                                              />
                                              <span>{item?.budget}</span>
                                            </div>
                                          </div>
                                          <div>
                                            <h3>Streak</h3>
                                            <span>
                                              {item?.streak}{" "}
                                              {+item?.streak > 1
                                                ? " days"
                                                : " day"}{" "}
                                            </span>
                                          </div>
                                          <div>
                                            <h3>Monthly</h3>
                                            <span>
                                              {item?.monthly?.toFixed(2)}%
                                            </span>
                                          </div>
                                        </div>

                                        {item?.explainInRedComment && (
                                          <div className="content">
                                            <h5 className="main-title mb-2">
                                              Explanation
                                            </h5>
                                            <span className="text">
                                              {item?.explainInRedComment}
                                            </span>
                                          </div>
                                        )}
                                        {item?.actionItemComment && (
                                          <div className="content">
                                            <h5 className="main-title mb-2">
                                              Next Step
                                            </h5>
                                            <span className="text">
                                              {item?.actionItemComment}
                                            </span>
                                          </div>
                                        )}
                                        {item?.additionalNotes && (
                                          <div className="content">
                                            <h5 className="main-title mb-2">
                                              Additional Notes
                                            </h5>
                                            <span className="text">
                                              {item?.additionalNotes}
                                            </span>
                                          </div>
                                        )}
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                );
                              })}
                            </>
                          </Accordion>
                        ) : tooltipContent?.length > 0 ? (
                          <div className="dropdown_menu daily-approval-popup">
                            <div>
                              <div className="d-flex dropdown-header">
                                <div className="profile-img">
                                  <img
                                    src={profile}
                                    className={"default"}
                                    width="40"
                                    height="40"
                                  />
                                </div>
                                <div>
                                  <h4 className="main-title">
                                    {tooltipContent[0]?.commentBy}
                                  </h4>
                                  <span>
                                    {tooltipContent[0]?.createdDate
                                      ? moment(
                                          tooltipContent[0]?.createdDate
                                        ).format("MMMM Do [at] h:mm a")
                                      : ""}
                                  </span>
                                </div>
                                <div></div>
                              </div>
                            </div>
                            <div className="d-flex dropdown-row justify-content-between budget-status">
                              <div>
                                <h3>Budget</h3>
                                <div
                                  className={
                                    tooltipContent[0]?.budget == "Under"
                                      ? "text-green"
                                      : "text-red"
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={
                                      tooltipContent[0]?.budget == "Under"
                                        ? faCheck
                                        : faClose
                                    }
                                  />
                                  <span>{tooltipContent[0]?.budget}</span>
                                </div>
                              </div>
                              <div>
                                <h3>Streak</h3>
                                <span>
                                  {tooltipContent[0]?.streak}{" "}
                                  {+tooltipContent[0]?.streak > 1
                                    ? " days"
                                    : " day"}{" "}
                                </span>
                              </div>
                              <div>
                                <h3>Monthly</h3>
                                <span>
                                  {tooltipContent[0]?.monthly?.toFixed(2)}%
                                </span>
                              </div>
                            </div>
                            {tooltipContent[0]?.explainInRedComment && (
                              <div className="content">
                                <h5 className="main-title mb-2">Explanation</h5>
                                <span className="text">
                                  {tooltipContent[0]?.explainInRedComment}
                                </span>
                              </div>
                            )}
                            {tooltipContent[0]?.actionItemComment && (
                              <div className="content">
                                <h5 className="main-title mb-2">Next Step</h5>
                                <span className="text">
                                  {tooltipContent[0]?.actionItemComment}
                                </span>
                              </div>
                            )}
                            {tooltipContent[0]?.additionalNotes && (
                              <div className="content">
                                <h5 className="main-title mb-2">
                                  Additional Notes
                                </h5>
                                <span className="text">
                                  {tooltipContent[0]?.additionalNotes}
                                </span>
                              </div>
                            )}
                          </div>
                        ) : (
                          ""
                        )}{" "}
                      </>
                    </div>
                  </OutsideClickHandler>
                )}
              </div>
            )}

            {(isChartLoadingMain || isCellClicked) && (
              <div className="table-overlay">
                <ReactPageLoader useas={"dashboardTable"} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={pathArray?.length > 0 ? "drilldown-chart-height" : ""}>
        {showChart && (
          <LaborDashboardChart
            drillDownArray={pathArray}
            hotelIds={getSelectedHotelsIds(tableData)}
            calendarDate={calendarDate}
            comparisonPeriodValue={varianceValue}
            periodValue={periodValue}
            selectedhotelIds={getSelectedHIds(tableData)}
            selectedColumn={selectedColumn}
            onComparisonPeriodChange={onComparisonPeriodChange}
            onChangeMetricParameter={onChangeMetricParameter}
            updateTableSelectedData={updateTableSelectedData}
            handleDrillDown={handleDrillDown}
            handlePerformanceSlideOut={handlePerformanceSlideOut}
            toggleColumnData={toggleColumnData}
            handleMainChartLoading={handleMainChartLoading}
            isInitialDataDataLoading={isInitialDataDataLoading}
          />
        )}
      </div>
    </>
  );
}

export default React.memo(LaborDashboardTable);
