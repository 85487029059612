import Instense from "./Axios-config";
import { IVendorList } from "../Contracts/IVendor";

export class VendorService {
    public static getVendorList = async (
        request: any
    ): Promise<IVendorList[]> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.TenantID = tenantID;

        const url = "/Vendor/GetVendorList";
        return Instense.post(url, request).then((response) => {
            let result = response.data.result.result as IVendorList[];
            return result;
        });
    };
    public static GetVendorGeneralInfo = async (VID: number): Promise<any> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        let request = {} as any;
        request.TenantID = tenantID;
        request.VID = VID;

        const url = `/Vendor/GetVendorDetailsByVID`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data?.result as any;
            return result;
        });
    };
    public static ActiveDeactiveVendor = async (
        request: any
    ): Promise<any> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.TenantID = tenantID;

        const url = "/Vendor/ActiveDeactiveVendor";
        return Instense.post(url, request).then((response) => {
            let result = response.data.result as any[];
            return result;
        });
    };

    public static UpdateVendorContractStatus = async (
        request: any
    ): Promise<any> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.TenantID = tenantID;

        const url = "/Vendor/UpdateVendorContractStatus";
        return Instense.post(url, request).then((response) => {
            let result = response.data.result as any[];
            return result;
        });
    };

    public static exportVendorSheet = async (
        request: any,
        fileName: string
    ): Promise<any | null> => {
        const hoteldateTime = new Date(JSON.parse(localStorage.storage).currentUtcTime);
        let date = hoteldateTime.getDate();
        let year = hoteldateTime.getFullYear();
        let month = (hoteldateTime.getMonth() + 1);
        let hour = hoteldateTime.getHours();
        let min = hoteldateTime.getMinutes();
        let sec = (hoteldateTime.getSeconds());
        fileName = `ViewAllVendor-${month}-${date}-${year}-${hour}-${min}-${sec}`;
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.TenantID = tenantID;
        request.FileName = fileName;

        const url = `/Vendor/ExportVendorSheet`;
        return Instense.post(url, request, { responseType: "blob" }).then(
            (response: any) => {
                let csvURL = window.URL.createObjectURL(response.data);
                let tempLink = document.createElement("a");
                tempLink.href = csvURL;
                tempLink.setAttribute("download", fileName + ".xlsx");
                tempLink.click();
            }
        );
    };

    public static ExistingVendorList = async (): Promise<any> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        let request = {} as any;
        request.TenantID = tenantID;

        const url = `/Vendor/ExistingVendorList`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data?.result as any;
            return result;
        });
    };

    public static AddModifyVendorAddress = async (
        request: any
    ): Promise<any> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        let userName = storage === null ? "" : (storage.userName as any);
        request.TenantID = tenantID;
        request.UserName = userName;
        const url = "/Vendor/AddModifyVendorAddress";
        return Instense.post(url, request).then((response) => {
            let result = response.data.result as any[];
            return result;
        });
    };

    public static UpdateVendorDetails = async (
        request: any
    ): Promise<any> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        // let userName = storage === null ? "" : (storage.userName as any);
        request.TenantID = tenantID;
        // request.UserName = userName;
        const url = "/Vendor/UpdateVendorDetails";
        return Instense.post(url, request).then((response) => {
            let result = response.data.result as any[];
            return result;
        });
    };

    public static GetVendorTaxDocuments = async (request: any): Promise<any> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.TenantID = tenantID;

        const url = `/Vendor/GetVendorTaxDocuments`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data?.result as any;
            return result;
        });
    };

    public static SaveVendorTaxDocuments = async (
        request: any
    ): Promise<any> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        let userName = storage === null ? "" : (storage.userName as any);
        request.userName = userName;
        request.tenantID = tenantID;
        const url = "/Vendor/SaveVendorTaxDocumentsWithFile";
        const formData: FormData = new FormData();
        request.forEach((items) => {
            if (items.attachedFile.length > 0) {
                items.attachedFile.forEach((item, index) => {
                    formData.append(items.fileIndex, item);
                })
            }
        });
        formData.append("formField", JSON.stringify(request));
        return Instense.post(url, formData).then((response) => {
            let result = response.data.result as any;
            return result;
        });
    };

    public static DownloadVendorTaxDocuments = async (
        request: any
    ): Promise<any> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        let userName = storage === null ? "" : (storage.userName as any);
        request.TenantID = tenantID;
        request.UserName = userName;
        const url = "/Vendor/DownloadVendorTaxDocuments";
        return Instense.post(url, request, { responseType: "blob" }).then(
            (response: any) => {
                //debugger;
                let csvURL = window.URL.createObjectURL(response.data);
                let tempLink = document.createElement("a");
                tempLink.href = csvURL;
                tempLink.setAttribute("download", request.Name);
                tempLink.click();
            }
        );
    };

    public static DeleteVendorTaxDocuments = async (
        request: any
    ): Promise<any> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        // let userName = storage === null ? "" : (storage.userName as any);
        request.TenantID = tenantID;
        // request.UserName = userName;
        const url = "/Vendor/DeleteVendorTaxDocuments";
        return Instense.post(url, request).then((response) => {
            let result = response.data.result as any[];
            return result;
        });
    };

    public static GetVendorContract = async (VID: number): Promise<any> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        let request = {} as any;
        request.TenantID = tenantID;
        request.VID = VID;

        const url = `/Vendor/GetVendorContract`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data?.result as any;
            return result;
        });
    };
    public static GetVendorDwollaCustomer = async (VID: number): Promise<any> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        let request = {} as any;
        request.TenantID = tenantID;
        request.VID = VID;
        const url = `/Vendor/GetVendorDwollaCustomer`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data as any;
            return result;
        });
    };

    public static GetVendorProjectList = async (VID: number): Promise<any> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        let request = {} as any;
        request.TenantID = tenantID;
        request.VID = VID;

        const url = `/Vendor/GetVendorProjectList`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data?.result as any;
            return result;
        });
    };
    
    public static SaveVendorOrgContract = async (request: any): Promise<any> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.TenantID = tenantID;

        const url = `/Vendor/SaveVendorOrgContract`;
        return Instense.post(url, request).then((response) => {
            let result = response.data.result as any[];
            return result;
        });
    };

    public static DeleteOrgContract = async (request: any): Promise<any> => {
        const url = `/Vendor/DeleteOrgContract?MainUniqueno=${request}`;
        return Instense.post(url).then((response) => {
            let result = response.data.result as any[];
            return result;
        });
    };

    public static SaveVendorData = async (request: any): Promise<any> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        let userName = storage === null ? "" : (storage.userName as any);
        request.TenantID = tenantID;
        request.UserName = userName;
        const url = `/Vendor/SaveVendorData `;
        return Instense.post(url, request).then((response) => {
            let result = response.data.result as any[];
            return result;
        });
    };

    public static SaveVendorContractFile = async (request: any, files: any): Promise<any> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        // let userName = storage === null ? "" : (storage.userName as any);
        // request.userName = userName;
        request.TenantID = tenantID;
        const url = "/Vendor/SaveVendorContractFile";
        const formData: FormData = new FormData();
        files.forEach((element) => {
            formData.append("file", element);
        });
        formData.append("formField", JSON.stringify(request));
        return Instense.post(url, formData).then((response) => {
            let result = response.data.result as any;
            return result;
        });
    };

    public static DownloadVendorContractFile = async (request: any): Promise<any> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        let userName = storage === null ? "" : (storage.userName as any);
        request.TenantID = tenantID;
        request.UserName = userName;
        const url = `/Vendor/DownloadVendorContractFile `;
        return Instense.post(url, request, { responseType: "blob" }).then(
            (response: any) => {
                //debugger;
                let csvURL = window.URL.createObjectURL(response.data);
                let tempLink = document.createElement("a");
                tempLink.href = csvURL;
                tempLink.setAttribute("download", request.Name);
                tempLink.click();
            }
        );
    };

    public static DeleteVendorContractFile = async (request: any): Promise<any> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        let userName = storage === null ? "" : (storage.userName as any);
        request.TenantID = tenantID;
        request.UserName = userName;
        const url = `/Vendor/DeleteVendorContractFile `;
        return Instense.post(url, request).then((response) => {
            let result = response.data.result as any[];
            return result;
        });
    };
    public static CreateVendorDwollaCustomer = async (
        request: any
    ): Promise<any> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.TenantID = tenantID;

        const url = "/Vendor/CreateVendorDwollaCustomer";
        return Instense.post(url, request).then((response) => {
            let result = response.data.result as any[];
            return result;
        });
    };

    public static UpdateVendorContract = async (request: any): Promise<any> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.TenantID = tenantID;

        const url = `/Vendor/UpdateVendorContract`;
        return Instense.post(url, request).then((response) => {
            let result = response.data?.result as any;
            return result;
        });
    };
    public static changeDwollaVendorStatus = async (request: any): Promise<any> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.TenantID = tenantID;

        const url = `/Vendor/DisableEnableDwollaVendor`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data?.result as any;
            return result;
        });
    };

    public static MergeVendor = async (request: any): Promise<any> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        let userName = storage === null ? "" : (storage.userName as any);
        request.TenantID = tenantID;
        request.UserName = userName;

        const url = `/Vendor/MergeVendor`;
        return Instense.post(url, request).then((response) => {
            let result = response.data?.result as any;
            return result;
        });
    };

    public static MergeContract = async (request: any): Promise<any> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        let userName = storage === null ? "" : (storage.userName as any);
        request.TenantID = tenantID;
        request.UserName = userName;

        const url = `/Vendor/MergeContract`;
        return Instense.post(url, request).then((response) => {
            let result = response.data?.result as any;
            return result;
        });
    };

}
