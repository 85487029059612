import Instense from "./Axios-config";
import {
  IBudgetSettingRequestDto,
  IBudgetSettingDto,
  IBudgeSettingtDeleteRequestDto,
  IGetStatiscsMasterDto, IBudgetServiceRequestDto, IGetServiceLevelDto, IBudgetCopyBudgetRequestDto
} from "../Contracts/ILaborBudgetSettings";

export class LaborBudgetSetting {

  public static LaborBudgetSettingDetail = async (
    request: IBudgetSettingRequestDto
  ): Promise<IBudgetSettingDto[] | null> => {
    // Need to put this and all future URLs into a config js file
    let storage:any = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;

    request.tenantID = tenantID;
    const url = `/LaborBudgetSetting/LaborBudgetSettingDetail`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IBudgetSettingDto[];
      return result;
    });
  };

  public static GetLaborBudgetSummary = async (
    hotelId: number
  ): Promise<IBudgetSettingDto | null> => {

    // Need to put this and all future URLs into a config js file

    let storage:any = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;


    let request = {} as IBudgetSettingRequestDto;
    request.hotelID = hotelId;
    request.tenantID = tenantID;
    const url = `/LaborBudgetSetting/GetLaborBudgetSummary`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IBudgetSettingDto;
      return result;
    });
  };

  public static SaveLaborBudgetSetting = async (
    request: IBudgetSettingDto
  ): Promise<any | null> => {
    let storage:any = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;


    request.tenantID = tenantID;

    const url = `/LaborBudgetSetting/SaveLaborBudgetSetting`;
    const response = await Instense.post(url, request);
    let result:any = response.data;
    return result;
  };

  public static DeleteBudgetSetting = async (
    request: IBudgeSettingtDeleteRequestDto
  ): Promise<any | null> => {
    // Need to put this and all future URLs into a config js file
    let storage:any = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;

    request.tenantID = tenantID;
    const url = `/LaborBudgetSetting/DeleteBudgetSetting`;
    return Instense.delete(url, { params: request }).then((response) => {
      let result:any = response.data;
      return result;
    });
  };

  public static getlaborStatistic = async (): Promise<IGetStatiscsMasterDto | null> => {
    // Need to put this and all future URLs into a config js file 
    let storage:any = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;

    let request = {} as IBudgetSettingRequestDto;
    request.tenantID = tenantID;
    const url = `/LaborBudgetSetting/GetStatiscsMaster`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IGetStatiscsMasterDto;
      return result;
    })
  };



  public static GetBudgetServiceLevel = async (request: IBudgetServiceRequestDto)
    : Promise<IGetServiceLevelDto | null> => {
    // Need to put this and all future URLs into a config js file

    // let request = {} as IBudgetServiceRequestDto;
    let storage:any = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;

    request.tenantID = tenantID;
    const url = `/LaborBudgetSetting/GetBudgetServiceLevel`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IGetServiceLevelDto;
      return result;
    })
  };
  public static SaveBudgetServiceLevel = async (
    request: IGetServiceLevelDto
  ): Promise<any | null> => {
    let storage:any = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;

    request.tenantID = tenantID;

    const url = `/LaborBudgetSetting/SaveBudgetServiceLevel`;
    let response = await Instense.post(url, request);
    let result:any = response.data;
    return result;
  };


  public static SaveBudgetCopy = async (
    request: IBudgetCopyBudgetRequestDto
  ): Promise<any | null> => {
    let storage:any = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;

    request.tenantID = tenantID;
    const url = `/LaborBudgetSetting/SaveLaborCopyAllBudgetSetting`;
    let response = await Instense.post(url, request);
    let result:any = response.data;

    return result;

  };

  public static SaveBudgetCalculation = async (
    request: any
  ): Promise<any | null> => {
debugger;

    let storage:any = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantID;
    const url = `/LaborBudgetSetting/SaveBudgetCalculation`;
    let response = await Instense.post(url, request);
    let result:any = response.data;

    return result;

  };
 

  public static budgetCalculationDetails = async ( hotelID: number ): Promise<any | null> => {
    // Need to put this and all future URLs into a config js file
    let storage:any = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    let request = {} as any;
    request.tenantID = tenantID;
    request.hotelID = hotelID;
    const url = `/LaborBudgetSetting/BudgetCalculationDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result:any = response.data.result;
      return result;
    });
  };
}
