import * as React from "react";
import Autocomplete from "react-autocomplete";
import OutsideClickHandler from 'react-outside-click-handler';
import profile from "../Assets/Images/profile.png";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";


class UserListAutoComplete extends React.Component<any, any> {
  private inputRef: any;
  constructor(props: any) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {};
    this.renderItem = this.renderItem.bind(this);
    this.onAutocompleteSelect = this.onAutocompleteSelect.bind(this);
    this.onAutocompleteChange = this.onAutocompleteChange.bind(this);
    this.onBlurComplete = this.onBlurComplete.bind(this);
    this.getValue = this.getValue.bind(this);
  }

  renderItem(item: any, isHighlighted: any) {
    return (
      <div className="dropdownItem" style={{ background: isHighlighted ? "lightgray" : "white" }}>
        {<div>
       <div className="user-details d-flex">
         <img
           src={
               item.imagePath === null ||
                 item.imagePath.trim() === ""
                 ? profile
                 : item.imagePath
             }
             className="profileimg"
             width="36"
             height="36"
             alt=""
           />
           <div className="name-position mr-auto">
             <EllipsisWithTooltip placement="bottom">
               <div className="name">{item.label}</div>
             </EllipsisWithTooltip>
             <EllipsisWithTooltip placement="bottom">
               <div className="pos-name">
                 {item.positionName}
               </div>
             </EllipsisWithTooltip>
           </div>
         </div>
   </div>}</div>
    );
  }

  onBlurComplete(e: any) {
    return ""
  }
  onAutocompleteSelect(item: any) {
    return item;
  }
  onAutocompleteChange(e: any) {
    let selectedChart = e.target.value;
    this.setState({ selectedChart: selectedChart })
    return this.props.value;
  }
  componentDidMount() {
    this.inputRef.current.focus();
  }
  getItemValue(item: { label: any }) {
       return `${item.label}`;
  }
  static defaultProps = {
    value: 0
  }
  getValue() {
    return this.props.value;
  }
  renderMenu(items, value) {
    return <div className="autocomplete-dropdown-menu" children={items} />
  }
  render() {
    const { value, onUpdate, items, ...rest } = this.props;
    console.log("props values::", { ...rest })
    return [
      <div id="autocomplete2">
        <OutsideClickHandler key="outsideclick" onOutsideClick={() => onUpdate(this.getValue())}>
          <div className="autocompleteWrapper">
            <Autocomplete id="autocomplete1"
              {...rest}
              open={true}
              key={"autocomplete1"}
              getItemValue={this.getItemValue}
              items={items}
              shouldItemRender={(item: { label: string }, value: string) => item.label.toLowerCase().indexOf(value.toLowerCase()) > -1}
              renderMenu={item => (<div key={item} className="reactAutocomplete">{item}</div>)}
              renderItem={this.renderItem}
              value={this.state.selectedChart}
              onSelect={(e: any) => {onUpdate(this.onAutocompleteSelect(e))}}
              onChange={this.onAutocompleteChange}
              ref={this.inputRef}              
            />
          </div>
        </OutsideClickHandler>
      </div >
    ];
  }
}
export default UserListAutoComplete;
