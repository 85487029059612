import * as React from "react";
// import { Form } from "react-bootstrap";
import { Hotel } from '../../../Common/Services/Hotel';
import { IHotelResponseDto } from "../../../Common/Contracts/IHotel";
import { MultiSelectSearchAllHidUM } from "./MultiSelectSearchAllHidUM";
import _ from "lodash";

export class BusinessAccountSelectorGeneralTab extends React.Component<any, any> {
  private multiselectChild: any;
  private SingleselectChild: any;

  constructor(props: any) {
    super(props);
    this.multiselectChild = React.createRef();
    this.state = {
      hotelList: [],
      selectedLetterCode: [],
      mainData: []
    };
  }
  componentDidMount() {
    let getUserHotelAccess: any;
    if (this.props?.shouldGetAllActiveHotels) {
      getUserHotelAccess = Hotel.getAllActiveInactiveHotelAccessNew(this.props?.notIncludeEnterpriseAccounts);
    }



    else {
      if (this.props?.isAll) {
        if (this.props?.notIncludeEnterpriseAccounts) {
          getUserHotelAccess = Hotel.getUserHotelAccessAll("Labor Management", true);
        } else {
          getUserHotelAccess = Hotel.getUserHotelAccessAll("Labor Management");
        }
      } else {
        if (this.props?.notIncludeEnterpriseAccounts) {
          getUserHotelAccess = Hotel.getUserHotelAccess("Labor Management", true);
        }
        else {
          getUserHotelAccess = Hotel.getUserHotelAccess("Labor Management");
        }
      }
    }
    Hotel.getUserHotelAccessUM(this.props?.notIncludeEnterpriseAccounts)
      .then(async (umResult: IHotelResponseDto[] | null) => {
        getUserHotelAccess.then(async (result: IHotelResponseDto[] | null) => {
          this.setState({mainData:umResult});
          if (result != null && result.length > 0) {
            let hotelList: any[] = [];
            result.forEach((element) => {
              let newItem = {} as any;
              newItem.hotelID = element.hotelID;
              newItem.value = element.lettercode;
              newItem.description = element.hotelName;
              newItem.isChecked = this.props.role == 1 ? true : false;
              newItem.hotelType = element.hotelType;
              newItem.status = element.status;
              // if(newItem.status?.toLowerCase() === "inactive"){
              //   newItem.enabled = false;
              // }else{
                
                if ((umResult || [])?.map(x => x.hotelID)?.includes(newItem.hotelID)) {
                  newItem.enabled = this.props.role == 1 ? false : true
                } 
               
                else {
                  newItem.enabled = false;
                }

                if(newItem.enabled && this.props.payrollIntegrationHids?.includes(newItem.hotelID))
                {
                  newItem.enabled = false;
                  newItem.payrollIntegrated =true
                }

                
              //}

              hotelList.push(newItem);
            });

            hotelList = _.sortBy(hotelList, ['status','hotelType' ,'value']);
            this.setState({ hotelList: hotelList });
          }
        })
          .catch((error) => {
            // Need to handle this
            console.log(error);
          });
      });
  }


  updateHotelList = (newHotelList, isSelectAll: boolean = false) => {
    let hotelList = [...this.state.hotelList]
    if (isSelectAll && newHotelList.length === 0) {
      hotelList = hotelList.map(hotel => {
        hotel.isChecked = true;
        return hotel
      });
    } else {
      const newHotelIds = newHotelList.map(r => r.hotelID);
      hotelList = hotelList.map(hotel => {
        if (newHotelIds.includes(hotel.hotelID)) {
          hotel.isChecked = true;
        } else {
          hotel.isChecked = false;
        }
        return hotel
      });
    }


    this.setState({ hotelList }, () => {
      this.props.handleBusinessAccountChange(hotelList?.filter(r => r?.isChecked));
    });


  };


  removalChips = (removalChips) => {
    let hotelList = [...this.state.hotelList]
    // let tempHotelList: any = [...this.state.hotelList];
    if (removalChips) {
      let parentId = removalChips.uniqueno;
      for (let i = this.state.hotelList.length - 1; i >= 0; i--) {
        if (Number(this.state.hotelList[i].rGroupID) === Number(parentId)) {
          hotelList.splice(i, 1);
        }
      }
      // hotelList.pop();
      this.setState({ hotelList });
    } else {
      this.setState({ hotelList: [] })
    }

  };


  componentDidUpdate = (prevprops, prevState) => {
    let hotelList = [...this.state.hotelList];
    const { defaultValue } = this.props;
    if (((prevState?.hotelList?.length !== hotelList?.length) || (prevprops.defaultValue !== defaultValue)) && hotelList?.length > 0 && defaultValue?.length > 0) {
      let hotelIDs = defaultValue.split(",")?.map(r => +r);
      const selectedHotels = hotelList?.filter(r => hotelIDs?.includes(r.hotelID))
      hotelIDs = hotelIDs.filter(r => selectedHotels?.map(r => r?.hotelID)?.includes(r));
      const selectLength = selectedHotels?.filter(r => r?.isChecked)?.length;
      //if(hotelIDs?.length !== selectLength) {
      hotelList = hotelList?.map(item => {
        if (hotelIDs?.includes(item?.hotelID)) {
          item.isChecked = true;
        } else {
          item.isChecked = false;
        }
        return item;
      })
      this.setState({ hotelList });
      //}
    }

    if (prevprops?.defaultValue !== defaultValue && !defaultValue) {
      let hotelList = [...this.state.hotelList];
      hotelList = hotelList?.map(item => {
        item.isChecked = false;
        return item;
      })
      this.setState({ hotelList });

    }

    if(prevprops.role !== this.props.role  && hotelList?.length > 0 ) {

      let hotelIDs = this.props.userGeneralInfoHids?.split(",")?.map(r => +r) ||[];
      const selectedHotels = hotelList?.filter(r => hotelIDs?.includes(r.hotelID))
      hotelIDs = hotelIDs.filter(r => selectedHotels?.map(r => r?.hotelID)?.includes(r));
      const selectLength = selectedHotels?.filter(r => r?.isChecked)?.length;
      //if(hotelIDs?.length !== selectLength) {
     

      hotelList = hotelList?.map(item => {
        if (this.props.role ===1) {
          item.isChecked = true;
          item.enabled = false;
      }else{
        
        if (hotelIDs?.includes(item?.hotelID)) {
          item.isChecked = true;
        } else {
          item.isChecked = false;
        }
         if ((this.state.mainData || [])?.map(x => x.hotelID)?.includes(item.hotelID)) {
          item.enabled = item.status?.toLowerCase() === "inactive" ? false : true;
        } else {
          item.enabled = false;
        }

      }
      if(item.enabled && this.props.payrollIntegrationHids?.includes(item.hotelID))
        {
          item.enabled = false;
          item.payrollIntegrated =true
        }
       


      return item;
    });
      this.setState({ selectedLetterCode: hotelList.filter(x=>x?.isChecked) }, () => {
        this.props.handleBusinessAccountChange(hotelList.filter(x=>x?.isChecked), true);
      });
    

      this.setState({ hotelList });
    }
  }


  updateMultiSelectItem = (selectedItem: any) => {

    this.setState({ selectedLetterCode: selectedItem }, () => {
      this.props.handleBusinessAccountChange(selectedItem, true);
    });
  };

  updateSingleSearchItem = (selectedItem: any) => {
    this.setState({ selectedLetterCode: selectedItem }, () => {
      this.props.handleBusinessAccountChange(selectedItem, true);
    });
  };

  dataChange = () => {
    this.setState({ isUnsavedChanges: true });
  };

  // disablebuton=(e: React.MouseEvent<HTMLElement>)=>
  // {
  //   
  //   const target = e.target as HTMLElement;
  //   let attr = target.getAttribute("btnselection");
  //   console.log(attr);
  // style={{visibility: this.state.show ? "visible" : "hidden",}}
  // }

  render() {
    return (
      <div
        id="businessAccountSelector"
        className={this.props.disabled ? "account-selector noPointer" : "account-selector"}>
        {/* <Form.Label className="sec-ttl">Select Accounts</Form.Label> */}

        {/* <Form.Group controlId="hotelAccountSelector">
            <Form.Label className="sub-ttl">Hotel Accounts</Form.Label>
          </Form.Group> */}

        <MultiSelectSearchAllHidUM
          role={this.props.role}
          ref={this.SingleselectChild}
          disabled={this.props?.disabled}
          itemList={this.state.hotelList}
          updateMultiSelectItem={this.updateMultiSelectItem.bind(this)}
          dataChange={this.dataChange.bind(this)}
          // disablebuton={this.disablebuton}
          isSelectAll={true}
          filtertitle={"Filter EHIDs"}
        />
      </div>
    );
  }
}
