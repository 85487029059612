import React from "react";
import { Form } from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import OutsideClickHandler from "react-outside-click-handler";
//import ReactTooltip from "react-tooltip";
import profile from "../../../../../Common/Assets/Images/profile.png";
// import { ReactComponentLoader } from "../../../../../Common/Components/ReactComponentLoader";

export class MultiSearchSelectUser extends React.Component<any, any> {
  private inputSearch: any;
  constructor(props: any) {
    super(props);
    this.inputSearch = React.createRef();
    this.state = {
      selectedItems: [],
      itemLists: [],
      positionEmployeeList: [],
      allEmployeeList: [],
      filterData: [],
      opened: false,
      isSelect: true,
      inputValue: "",
      positionID: 0,
      positionName: "",
      isRemove: true,
      isEdit: "",
    };
  }

  static getDerivedStateFromProps(props: any, state: any) {
    return {
      itemLists: props.parentState.employeeList,
      positionID: props.parentState.positionId,
      positionName: props.parentState.positionSelected,
      isRemove: true,
    };
  }

  updateFromParentList = (parentState: any) => {
    this.setState({
      selectedItems: parentState.selectedEmployes,
      isEdit: parentState.addEditShiftValue,
    });
  };

  componentDidUpdate(prevProps: any) {
    if (
      prevProps.parentState.employeeList !==
        this.props.parentState.employeeList ||
      prevProps.parentState.positionSelected !==
        this.props.parentState.positionSelected ||
      prevProps.parentState.dateSelected !==
        this.props.parentState.dateSelected ||
      prevProps.parentState.inTime !== this.props.parentState.inTime ||
      prevProps.parentState.outTime !== this.props.parentState.outTime
    ) {
      this.updateLists();
    }
  }

  updateLists = () => {
    let { filterData, itemLists, positionID } = this.state;
    //
    if (filterData.length === 0 && this.state.inputValue === "") {
      filterData = itemLists;
    }

    let positionList = filterData.filter(
      (data: any) => data.positionid === positionID
    );

    let remainingList = filterData.filter(
      (data: any) => data.positionid !== positionID
    );
    this.setState({
      positionEmployeeList: positionList,
      allEmployeeList: remainingList,
    });

    if (this.state.inputValue === "" && this.state.isRemove === true) {
      this.removeExitingList(filterData, positionID);
    }
  };

  removeExitingList = (filterData: any, positionID: any) => {
    let selectedData = [...this.state.selectedItems];
    if (selectedData.length > 0 && filterData.length > 0) {
      let selectedItems = [...this.state.selectedItems];
      for (let i = selectedData.length - 1; i >= 0; i--) {
        if (selectedData[i].employee !== undefined) {
          const updatedList = filterData.filter((item: any) => {
            return (
              item.isconflict === "No" &&
              item.employee
                .toLowerCase()
                .indexOf(selectedData[i].employee.toLowerCase()) > -1
            );
          });
          if (updatedList.length === 0) {
            let rowIndex = selectedItems.findIndex(
              (obj: any) =>
                obj.employee.toLowerCase().trim() ===
                selectedData[i].employee.toLowerCase().trim()
            );
            if (rowIndex !== -1) {
              selectedItems.splice(rowIndex, 1);
            }

            let filterIndex = filterData.findIndex(
              (obj: any) =>
                obj.employee.toLowerCase().trim() ===
                selectedData[i].employee.toLowerCase().trim()
            );

            if (filterIndex !== -1) {
              filterData[filterIndex].isChecked = false;
            }
          } else {
            let rowIndex = filterData.findIndex(
              (obj: any) =>
                obj.employee.toLowerCase().trim() ===
                selectedData[i].employee.toLowerCase().trim()
            );

            if (rowIndex !== -1) {
              filterData[rowIndex].isChecked = true;
            }
          }
        }
      }

      let positionList = filterData.filter(
        (data: any) => data.positionid === positionID
      );

      let remainingList = filterData.filter(
        (data: any) => data.positionid !== positionID
      );

      this.setState(
        {
          positionEmployeeList: positionList,
          allEmployeeList: remainingList,
          selectedItems: selectedItems,
        },
        () => {
          this.props.employeeSelection(this.state);
        }
      );
    }
  };

  openDropdown = () => {
    let parentPropsItems = this.props.parentState;
    if (
      parentPropsItems.dateSelected === "Select" ||
      parentPropsItems.inTime === "" ||
      parentPropsItems.outTime === ""
    ) {
      this.props.multiSelectErrorValidation();
      return false;
    } else {
      if (this.state.inputValue === "") {
        this.setState(
          {
            opened: true,
            filterData: [],
            inputValue: "",
          },
          () => {
            this.inputSearch.current.focus();
            this.updateLists();
          }
        );
      }
    }
  };

  handleOutsideClickForAddShift = () => {
    this.setState({ opened: false, inputValue: "" });
  };

  handleChange = (e: any) => {
    const updatedList = this.state.itemLists.filter((item: any) => {
      return (
        item.employee.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
      );
    });

    this.setState(
      { filterData: updatedList, inputValue: e.target.value },
      () => {
        this.updateLists();
      }
    );
  };

  handleRemoveSpecificChip = (idx: number) => () => {
    const itemLists = [...this.state.itemLists];
    const selectedItems = [...this.state.selectedItems];
    if (selectedItems[0].employee !== undefined) {
      const rowIndex = itemLists.findIndex(
        (obj) =>
          obj.employee.toLowerCase().trim() ===
          selectedItems[idx].employee.toLowerCase().trim()
      );
      itemLists[rowIndex].isChecked = false;
      selectedItems.splice(idx, 1);

      this.setState({ selectedItems, itemLists }, () => {
        this.props.employeeSelection(this.state);
      });
    }
  };

  handleCheckCheckbox = (idx: any, e: any) => {
    const itemLists = [...this.state.itemLists];
    const selectedItems = [...this.state.selectedItems];

    const index = itemLists.findIndex((xvalue) => xvalue.rowno === idx);

    itemLists[index].isChecked = e.target.checked;

    idx = index;
    this.setState({ itemLists });

    if (e.target.checked === true) {
      const item = {
        value: itemLists[idx].rowno,
        employee: itemLists[idx].employee,
        isChecked: e.target.checked,
      };

      this.setState(
        {
          selectedItems: [...this.state.selectedItems, item],
        },
        () => {
          this.props.employeeSelection(this.state);
        }
      );
    } else {
      const removeIndex = selectedItems.findIndex(
        (xvalue) =>
          xvalue.employee.toLowerCase().trim() ===
          itemLists[index].employee.toLowerCase().trim()
      );
      selectedItems.splice(removeIndex, 1);
      this.setState({ selectedItems, isRemove: false }, () => {
        this.props.employeeSelection(this.state);
      });
    }
  };

  getIndex = (val: any, type: string) => {
    if (type === "removeChip") {
      let itemLists = [...this.state.itemLists];
      return itemLists.findIndex((obj) => obj.rowno === val);
    } else {
      let selectedItems = [...this.state.selectedItems];
      return selectedItems.findIndex((obj) => obj.rowno === val);
    }
  };

  render() {
    const tooltipMessage =
      this.props.conflictCheck === "Schedule"
        ? "This user is already scheduled for another shift within the selected hours."
        : "This user have already Actual entries in the selected duration.";

    return (
      <OutsideClickHandler onOutsideClick={this.handleOutsideClickForAddShift}>
        <div
          className={
            this.state.opened ? "multiSelectSearch opened" : "multiSelectSearch"
          }
        >
          {/* {this.props.parentState.loaderTrue ? (
            <></>
            // <ReactComponentLoader useas={"schedleAddShiftDDN"} />
          ) : ( */}
          <div className="fieldArea" onClick={this.openDropdown}>
            {this.state.selectedItems.length === 0 ? (
              <span className="areaPlaceholder placeholder">
                Select one or more users
              </span>
            ) : (
              <div className="chip">
                {this.state.selectedItems.map((item: any, idx: any) => (
                  <div className="chipSelect" id={idx} key={idx}>
                    <div className="chipVal">{item.employee}</div>
                    <button
                      id="btnremove"
                      type="button"
                      className="cross"
                      onClick={this.handleRemoveSpecificChip(idx)}
                    >
                      <svg
                        width="8"
                        height="8"
                        viewBox="0 0 8 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.79465 0.229492L3.96598 3.05749L1.13798 0.229492L0.195312 1.17216L3.02331 4.00016L0.195312 6.82816L1.13798 7.77083L3.96598 4.94282L6.79465 7.77083L7.73731 6.82816L4.90931 4.00016L7.73731 1.17216L6.79465 0.229492Z"
                          fill="#6A6E73"
                        />
                      </svg>
                    </button>
                  </div>
                ))}
              </div>
            )}

            {/* {this.props.parentState.loaderTrue ? (
              <> </>
            ) : ( */}
            {/* // <ReactComponentLoader useas={"schedleAddShiftDDN"} />
              // <ReactComponentLoader useas={"schedleAddShiftDDN"} /> */}
            <div className="coaSearch">
              <div className="dropDownPanel inner-label">
                <div className="SearchInput">
                  <input
                    ref={this.inputSearch}
                    key={"text-Filter"}
                    type="text"
                    className="form-control"
                    onChange={this.handleChange}
                    placeholder="Search"
                    value={this.state.inputValue}
                  />
                </div>
                <ul>
                  {this.state.positionEmployeeList.length > 0 && (
                    <div>
                      <div className="dropdown-header">
                        {this.state.positionName}
                      </div>
                      {this.state.positionEmployeeList.map(
                        (item: any, idx: any) => (
                          <div
                            data-tip={
                              item.isconflict === "Yes" ? tooltipMessage : ""
                            }
                          >
                            {/* <ReactTooltip
                              effect="solid"
                              event="mouseover mouseenter"
                              eventOff="mouseleave mouseout scroll mousewheel blur"
                              place="bottom"
                              multiline={true}
                            /> */}

                            <li
                              id={idx}
                              key={idx}
                              className={
                                item.isconflict === "Yes" ? "disabled" : ""
                              }
                            >
                              <Form.Check
                                custom
                                type="checkbox"
                                key={"custom-" + idx}
                                id={"custom-" + idx}
                                checked={item.isChecked ? true : false}
                                onChange={(e: any) =>
                                  this.handleCheckCheckbox(item.rowno, e)
                                }
                              />
                              <div className="user-details">
                                <img
                                  src={
                                    item.imagePath === null ||
                                    item.imagePath.trim() === ""
                                      ? profile
                                      : item.imagePath
                                  }
                                  className="profileimg"
                                  width="36"
                                  height="36"
                                  alt=""
                                  onError={(e) =>
                                    (e.currentTarget.src = profile)
                                  }
                                />
                                <div className={`name-position mr-auto ${item.isconflict === "Yes" ? "w-65" : ""}`}>
                                  <EllipsisWithTooltip placement="bottom">
                                    <div className="name">{item.employee}</div>
                                  </EllipsisWithTooltip>
                                  <EllipsisWithTooltip placement="bottom">
                                    <div className="pos-name">
                                      {item.positionName}
                                    </div>
                                  </EllipsisWithTooltip>
                                </div>
                                {item.isconflict === "Yes" && (
                                  <div className="red-badges">Conflict</div>
                                )}
                              </div>
                            </li>
                          </div>
                        )
                      )}
                    </div>
                  )}
                  {this.state.allEmployeeList.length > 0 && (
                    <div>
                      <div className="dropdown-header divider">ALL USERS</div>
                      {this.state.allEmployeeList.map((item: any, idx: any) => {
                        if (item.isconflict.toLowerCase() !== "incomplete")
                          return (
                            <div
                              data-tip={
                                item.isconflict === "Yes" ? tooltipMessage : ""
                              }
                            >
                              {/* <ReactTooltip
                            effect="solid"
                            event="mouseover mouseenter"
                            eventOff="mouseleave mouseout scroll mousewheel blur"
                            place="bottom"
                            multiline={true}
                          /> */}

                              <li
                                id={idx}
                                key={idx}
                                className={
                                  item.isconflict === "Yes" ? "disabled" : ""
                                }
                              >
                                <Form.Check
                                  custom
                                  type="checkbox"
                                  key={"custom-" + idx}
                                  id={"custom-" + idx}
                                  checked={item.isChecked ? true : false}
                                  onChange={(e: any) =>
                                    this.handleCheckCheckbox(item.rowno, e)
                                  }
                                />
                                <div className="user-details">
                                  <img
                                    src={
                                      item.imagePath === null ||
                                      item.imagePath.trim() === ""
                                        ? profile
                                        : item.imagePath
                                    }
                                    className="profileimg"
                                    width="36"
                                    height="36"
                                    alt=""
                                    onError={(e) =>
                                      (e.currentTarget.src = profile)
                                    }
                                  />
                                  <div className={`name-position mr-auto ${item.isconflict === "Yes" ? "w-65" : ""}`}>
                                    <EllipsisWithTooltip placement="bottom">
                                      <div className="name">
                                        {item.employee}
                                      </div>
                                    </EllipsisWithTooltip>
                                    <EllipsisWithTooltip placement="bottom">
                                      <div className="pos-name">
                                        {item.positionName}
                                      </div>
                                    </EllipsisWithTooltip>
                                  </div>
                                  {item.isconflict === "Yes" && (
                                    <div className="red-badges">Conflict</div>
                                  )}
                                </div>
                              </li>
                            </div>
                          );
                      })}
                      <div className="dropdown-header divider">
                        INCOMPLETE USERS
                      </div>
                      {this.state.allEmployeeList.map((item: any, idx: any) => {
                        if (item.isconflict.toLowerCase() === "incomplete")
                          return (
                            <div
                            // data-tip={
                            //   tooltipIncompleteMessage
                            // }
                            >
                              <li id={idx} key={idx} className={"disabled"}>
                                <Form.Check
                                  custom
                                  type="checkbox"
                                  key={"custom-" + idx}
                                  id={"custom-" + idx}
                                  // checked={item.isChecked ? true : false}
                                  // onChange={(e: any) =>
                                  //   this.handleCheckCheckbox(item.rowno, e)
                                  // }
                                />
                                <div className="user-details">
                                  <img
                                    src={
                                      item.imagePath === null ||
                                      item.imagePath.trim() === ""
                                        ? profile
                                        : item.imagePath
                                    }
                                    className="profileimg"
                                    width="36"
                                    height="36"
                                    alt=""
                                    onError={(e) =>
                                      (e.currentTarget.src = profile)
                                    }
                                  />
                                  <div className="name-position mr-auto w-65">
                                    <EllipsisWithTooltip placement="bottom">
                                      <div className="name">
                                        {item.employee}
                                      </div>
                                    </EllipsisWithTooltip>
                                    <EllipsisWithTooltip placement="bottom">
                                      <div className="pos-name">
                                        {item.positionName}
                                      </div>
                                    </EllipsisWithTooltip>
                                  </div>
                                  {/* {item.isconflict === "Yes" && ( */}
                                  <div className="red-badges">Incomplete</div>
                                  {/* )} */}
                                </div>
                              </li>
                            </div>
                          );
                      })}
                    </div>
                  )}
                </ul>
              </div>
            </div>
            {/* // )} */}

            {/* //Test */}
          </div>
          {/* // )} */}
        </div>
      </OutsideClickHandler>
    );
  }
}
