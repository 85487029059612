import React, { useRef, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Button, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { reject } from "q";
import { ModuleReports } from "../../../Common/Services/ModuleReports";
import { toast, ToastContainer } from "react-toastify";
import { isGlobalSettingParams } from "../models/ParameterSelectionState";
import { ReactPageLoader } from "../../../Common/Components/ReactPageLoader";
import { InfoTooltip } from "../../../Common/Components/InfoTooltip";
import { CashFlowGlobalSetting } from "./CashFlowGlobalSetting";
import { StatisticsRevenueSummary } from "./StatisticsRevenueSummary";
import { Utils } from "../../../Common/Utilis";
export const ReportGlobalSetting: React.FC<any> = (props: any) => {
  let htmlControls: any[] = [];
  let reportInputParameters: any[] = [];
  const [visibleHtmlControls, setVisibleHtmlControls] = React.useState<any[]>(
    []
  );
  const [state, setCompState] = React.useState<isGlobalSettingParams>(
    {} as isGlobalSettingParams
  );
  const [dataFetched, SetDataFetched] = React.useState(false);
  const [StatisticData, SetStatisticData] = React.useState([]);
  const [CashFlowData, SetCashFlowData] = React.useState([]);

  React.useEffect(() => {
    if (props.ParantID === 2080) {
      getRevenueValueasync();
    } else {
      getPercentageValueasync();
    }

    let _htmlControls: any[] = [];
    ModuleReports.getGlobalSettingParams(props.moduleReportId).then(
      (parameters) => {
        localStorage.removeItem("reportParameters");
        SetDataFetched(true);
        console.log(parameters);
        if (parameters[0]?.paramName) {
          reportInputParameters = parameters;
          buildFilterControls(state);
          setCompState(state);
          _htmlControls = htmlControls
            .map((r) => {
              if (controlshouldBeVisible(r?.controlName)) {
                const control = getControl(r?.controlName);
                r.displayOrder = control?.displayOrder;
                r.isRequired = control?.isRequired;
                r.isVisible = true;
                r.isSameDateRangeYear = control?.isSameDateRangeYear;
              }
              return r;
            })
            ?.filter((x) => x?.isVisible);

          if (_htmlControls.length > 1) {
            _htmlControls = _htmlControls?.sort(
              (a, b) => a?.displayOrder - b?.displayOrder
            );
          }

          setVisibleHtmlControls(_htmlControls);
          const tabindexDivs = document?.querySelectorAll('[role="dialog"]');
          if (
            tabindexDivs?.length > 0 &&
            _htmlControls?.filter(
              (r) =>
                r?.controlName === "txtDateRange" ||
                r?.controlName === "txtDate"
            )?.length > 0
          ) {
            if (tabindexDivs?.length > 1) {
              document
                .querySelectorAll('[role="dialog"]')[1]
                .removeAttribute("tabIndex");
            } else {
              document
                ?.querySelectorAll('[role="dialog"]')[0]
                ?.removeAttribute("tabIndex");
            }
          }
        }
      }
    );
  }, []);

  const getRevenueValueasync = async () => {
    let globalid = props.ParantID > 0 ? props.ParantID : props.moduleReportId;
    ModuleReports.getRevenueData(globalid).then((result) => {
      if (result?.length > 0) {
        state.highlightVariance = Number(result[0].percentage).toFixed(2);
      } else {
        state.highlightVariance = "0.00";
      }
      updateHtmlControlState(state);
    });
  };

  const getPercentageValueasync = async () => {
    let globalid = props.ParantID > 0 ? props.ParantID : props.moduleReportId;
    ModuleReports.GETreprotPageSetup(globalid).then((result) => {
      if (result?.length > 0) {
        state.overBudget = result[0].percentage;
        state.overVariance = result[0].variance;
        state.underBudget = result[0].underPercentage;
        state.underVariance = result[0].underVariance;
        state.reportHeader = result[0].reportHeader;
        state.bottomMessage = result[0].bottomMessage;
        updateHtmlControlState(state);
      }
    });
  };

  const updateHtmlControlState = (state: isGlobalSettingParams) => {
    setCompState(state);
    buildFilterControls(state);
    if (reportInputParameters?.length > 0) {
      let _htmlControls = htmlControls
        .map((r) => {
          if (controlshouldBeVisible(r?.controlName)) {
            const control = getControl(r?.controlName);
            r.displayOrder = control?.displayOrder;
            r.isRequired = control?.isRequired;
            r.isVisible = true;
            r.isSameDateRangeYear = control?.isSameDateRangeYear;
          }
          return r;
        })
        ?.filter((x) => x?.isVisible);
      if (_htmlControls.length > 1) {
        _htmlControls = _htmlControls?.sort(
          (a, b) => a?.displayOrder - b?.displayOrder
        );
      }

      setVisibleHtmlControls(_htmlControls);
    }

    if (visibleHtmlControls?.length > 0) {
      let _htmlControls = htmlControls

        .map((r) => {
          if (controlshouldBeVisible(r?.controlName)) {
            const control = getControl(r?.controlName);
            r.displayOrder = control?.displayOrder;
            r.isRequired = control?.isRequired;
            r.isVisible = true;
          }
          return r;
        })
        ?.filter((x) => x?.isVisible);

      if (_htmlControls.length > 1) {
        _htmlControls = _htmlControls?.sort(
          (a, b) => a?.displayOrder - b?.displayOrder
        );
      }

      setVisibleHtmlControls(_htmlControls);
    }
  };

  const controlshouldBeVisible = (controlName: string): boolean => {
    if (
      (reportInputParameters?.length === 0 &&
        visibleHtmlControls?.length === 0) ||
      !controlName
    ) {
      return false;
    }
    if (reportInputParameters?.length > 0) {
      return reportInputParameters?.filter(
        (item: any) =>
          item?.paramName?.toLowerCase()?.trim() ===
          controlName?.toLowerCase()?.trim()
      ).length > 0
        ? true
        : false;
    } else if (visibleHtmlControls?.length > 0) {
      return visibleHtmlControls?.filter(
        (item: any) =>
          item?.controlName?.toLowerCase()?.trim() ===
          controlName?.toLowerCase()?.trim()
      ).length > 0
        ? true
        : false;
    } else {
      return false;
    }
  };

  const getControl = (paramName: string): any => {
    if (
      (reportInputParameters?.length === 0 &&
        visibleHtmlControls?.length === 0) ||
      !paramName
    ) {
      return {};
    }
    let item: any = {};
    if (reportInputParameters?.length > 0) {
      item = reportInputParameters.find(
        (r) =>
          r?.paramName?.toLowerCase()?.trim() ===
          paramName.toLowerCase()?.trim()
      );
    }
    if (visibleHtmlControls?.length > 0) {
      item = visibleHtmlControls.find(
        (r) =>
          r?.controlName?.toLowerCase()?.trim() ===
          paramName.toLowerCase()?.trim()
      );
    }
    return item;
  };

  const buildFilterControls = (state: isGlobalSettingParams) => {
    htmlControls = [
      ...getTextBoxControls(state),
      ...getlistControls(state),
    ];
  };

  const getlistControls = (state: isGlobalSettingParams) => {
    return [
      {
        controlName: "listCashFlow",
        control: (
          <CashFlowGlobalSetting
            updateState={(data) => SetCashFlowData(data)}
          />
        )
      },
      {
        controlName: "listStatisticRevenueSummary",
        control: (
          <StatisticsRevenueSummary
            updateState={(data) => SetStatisticData(data)}
          />
        )
      }
    ]

  }

  const getTextBoxControls = (state: isGlobalSettingParams) => {
    return [
      {
        controlName: "txtreportHeader",
        control: (
          <Form.Group>
            <label htmlFor="name" className="">
              Report header
            </label>
            <div style={{ textTransform: "capitalize" }}>
              <input
                type="text"
                className="form-control"
                value={state.reportHeader}
                onChange={(e) => txtpercentageValuese(e, "reportHeader")}
              />
            </div>
          </Form.Group>
        ),
      },
      {
        controlName: "txtoverBudget",
        control: (
          <Form.Group>
            <label
              htmlFor="name"
              className="labels d-flex align-items-center"
              style={{ textTransform: "none" }}
            >
              <div> Over Budget </div>
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip id={"Labor-Cost"}>
                    Items are considered over budget if the Adjusted Budget %
                    exceeds the given % and the variance is over the given $.
                  </Tooltip>
                }
              >
                <span style={{ marginLeft: "2px" }}>
                  <InfoTooltip />{" "}
                </span>
              </OverlayTrigger>
            </label>
            <div className="mr-auto d-flex">
              <div className="variance-highlight-box">
                <input
                  type="text"
                  className="form-control"
                  value={state.overBudget}
                  onChange={(e) => txtpercentageValuese(e, "overBudget")}
                  onBlur={(e) => onblur(e, "overBudget")}
                />
              </div>
              <div className="dollar-box margin-left20">
                <input
                  type="text"
                  className="form-control"
                  value={state.overVariance}
                  onChange={(e) => txtpercentageValuese(e, "overVariance")}
                  onBlur={(e) => onblur(e, "overVariance")}
                />
              </div>
            </div>
          </Form.Group>
        ),
      },
      {
        controlName: "txtunderBudget",
        control: (
          <Form.Group>

            <label
              htmlFor="name"
              className="labels d-flex align-items-center"
              style={{ textTransform: "none" }}
            >
              <div> Under Budget </div>
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip id={"Labor-Cost"}>
                    Items are considered under budget if the Adjusted Budget %
                    exceeds the given % and the variance is over the given $.
                  </Tooltip>
                }
              >
                <span style={{ marginLeft: "2px" }}>
                  <InfoTooltip />{" "}
                </span>
              </OverlayTrigger>
            </label>
            <div className="mr-auto d-flex">
              <div className="variance-highlight-box">
                <input
                  type="text"
                  className="form-control"
                  value={state.underBudget}
                  onChange={(e) => txtpercentageValuese(e, "underBudget")}
                  onBlur={(e) => onblur(e, "underBudget")}
                />
              </div>
              <div className="dollar-box margin-left20">
                <input
                  type="text"
                  className="form-control"
                  value={state.underVariance}
                  onChange={(e) => txtpercentageValuese(e, "underVariance")}
                  onBlur={(e) => onblur(e, "underVariance")}
                />
              </div>
            </div>
          </Form.Group>
        ),
      },
      {
        controlName: "txtVarianceHighlighting",
        control: (
          <Form.Group>
            <label
              htmlFor="name"
              className="labels d-flex align-items-center"
              style={{ textTransform: "none" }}
            >
              <div>Variance Threshold </div>
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip id={"Labor-Cost"}>
                    Variance greater than the given % will be shown in
                    Red.
                  </Tooltip>
                }
              >
                <span style={{ marginLeft: "2px" }}>
                  <InfoTooltip />{" "}
                </span>
              </OverlayTrigger>
            </label>

            <div className="variance-highlight-box">
              <input
                type="text"
                className="form-control"
                value={props.ParantID === 2080 ? state.highlightVariance : state.overBudget}
                onChange={(e) => txtpercentageValuese(e, "highlight")}
                onBlur={(e) => onblur(e, "highlight")}
              />
            </div>
          </Form.Group>
        ),
      },
      {
        controlName: "txtbottomMessage",
        control: (
          <Form.Group>
            <label
              htmlFor="name"
              className="labels"
              style={{ textTransform: "none" }}
            >
              Bottom Message
            </label>
            <div>
              <textarea
                style={{ minHeight: "120px" }}
                className="form-control"
                value={state.bottomMessage}
                onChange={(e) => txtpercentageValuese(e, "bottomMessage")}
              />
            </div>
          </Form.Group>
        ),
      },
    ];
  };

  const txtpercentageValuese = (e, mode) => {
    switch (mode) {
      case "reportHeader":
        state.reportHeader = e.target.value;
        break;
      case "bottomMessage":
        state.bottomMessage = e.target.value;
        break;
    }
    if (
      !e.target.value ||
      e.target.value.match(/^-?(?:\d{1,4}(?:\.\d{0,2})?)?$/)
    )
      switch (mode) {
        case "overBudget":
          state.overBudget = e.target.value;
          break;
        case "overVariance":
          state.overVariance = e.target.value;
          break;
        case "underBudget":
          state.underBudget = e.target.value;
          break;
        case "underVariance":
          state.underVariance = e.target.value;
          break;
        case "highlight":
          state.highlightVariance = e.target.value;
          break;
      }
    updateHtmlControlState(state);
  };

  const onblur = (e, type) => {
    if (type === "overBudget") {
      state.overBudget = isNaN(state.overBudget) ? 0.00 : state.overBudget;
    } else if (type === "overVariance") {
      state.overVariance = isNaN(state.overVariance) ? 0.00 : state.overVariance;
    } else if (type === "underBudget") {
      state.underBudget = isNaN(state.underBudget) ? 0.00 : state.underBudget;
    } else if (type === "underVariance") {
      state.underVariance = isNaN(state.underVariance) ? 0.00 : state.underVariance;
    } else if (type === "highlight") {
      state.overBudget = isNaN(state.overBudget) ? 0.00 : state.overBudget;
    }
    updateHtmlControlState(state);
  }

  const cancelreportsetup = (e) => {
    props.cancelreportsetup();
  };

  const savereportSetting = async () => {
    if (state.highlightVariance === "" && props.ParantID === 2080) {
      Utils.toastError("Please enter variance threshold.", {});
      return false;
    }
    confirmAlert({
      title: " ",
      message: "Are you sure you want to make the changes permanent?",
      buttons: [
        {
          label: "Yes",
          onClick: () => savesetting(),
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };


  const savesetting = async () => {
    if (props.ParantID === 2137) {
      const result: any[] = await ModuleReports.updateCashFlowOrder(CashFlowData)
      if (result !== null) {
        getPercentageValueasync();
        toast.success("Records has been updated successfully.", {});
      }
    } else if (props.ParantID === 2080) {
      let request: any = {};
      request.StatisticData = StatisticData;
      request.objRevenue = { percentage: state.highlightVariance, reportId: props.moduleReportId }
      const result: any[] = await ModuleReports.updateStatisticRevenueData(request)
      if (result !== null) {
        getRevenueValueasync();
        toast.success("Records has been updated successfully.", {});
      }

    } else {
      let ModuleReportId = props.moduleReportId;
      let Budget: any = (state.overBudget === null || state.overBudget === 0) ? 0 : state.overBudget;
      let Variance: any = (state.overVariance === null || state.overVariance === 0) ? 0 : state.overVariance;
      let uBudget: any = (state.underBudget === null || state.underBudget === 0) ? 0 : state.underBudget;
      let uVariance: any = (state.underVariance === null || state.underVariance === 0) ? 0 : state.underVariance;
      let reportName: any = state.reportHeader === null ? "" : state.reportHeader;
      let bottomMessage: any = state.bottomMessage === null ? "" : state.bottomMessage;
      debugger;
      const result: any[] = await ModuleReports.InsertreprotPageSetup(
        ModuleReportId,
        Budget,
        uBudget,
        Variance,
        uVariance,
        reportName,
        bottomMessage
      );
      if (result !== null) {
        getPercentageValueasync();
        toast.success("Record successfully saved.", {});
      }
    }
  };

  return (
    <div>
      {!dataFetched ? (
        <ReactPageLoader
          useas={"reportsParaSelection reportsScheduleLeftPanel"}
        />
      ) : (
        <div className="global-settings">
          <div className="section-body">
            <div className="sec-group">
              <div className="section">
                {visibleHtmlControls?.length > 0 && (
                  <div className="frm-section">
                    {visibleHtmlControls?.map((item) => (
                      <>{item.control}</>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <Modal.Footer>
            <Button
              className="cancel-btn btn-ghost ml-auto"
              onClick={(e) => cancelreportsetup(e)}
            >
              Cancel
            </Button>
            <Button
              className="reset-password-btn"
              onClick={() => savereportSetting()}
            >
              Save
            </Button>
          </Modal.Footer>
        </div>
      )}
    </div>
  );
};
