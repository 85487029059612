import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { showOnlySections } from "../Data/options";
import ChartTypeSelector from "./ChartTypeSelector";
import MetricChart from "./MetricChart";
import ShowOnlyDropdown from "./ShowOnlyDropdown";
import metricIcon from "../../../Common/Assets/Images/metrics-portfolio.png";
import _ from "lodash";
import { DropDownList } from "../../Reports/common-components/dropdown-list";
import { MultiSelectSearchCustomCheck } from "../../../Common/Components/MultiSelectSearchCustomCheck";

const MetricRightPanel = ({
  selectedMetric,
  defaultChartType = "number",
  setCardData,
}) => {
  const [chartType, setChartType] = useState<any>(null);
  const [cardId, setCardId] = useState<any>(null);
  const [chartTitle, setChartTitle] = useState("");
  const [chartComparison, setChartComparison] = useState<any>([]);
  const [chartComparisonList, setChartComparisonList] = useState<any>([]);
  const [cardTypeList, setCardTypeList] = useState<any>([]);
  const [showOnly, setShowOnly] = useState<any>(null);
  const [error, setError] = useState("");
  const [comparionError, setComparionError] = useState("");
  const [inputPosition, setInputPosition] = useState(0);

  const handleBlur = (inputPos) => {
    if(inputPos == 1){
      setChartTitle((prev) => prev.trim());
      if (chartTitle.trim() === "") {
        setError("Chart Title is required.");
      } else {
        setError("");
      }
    }
    else{
      
      if (chartComparison.length == 0) {
        setComparionError("Comparison is required.");
      } else {
        setComparionError("");
      }
    }
    

    setInputPosition(inputPos)
  };

  // useEffect(() => {
  //   setChartType(defaultChartType || "number");
  // }, [defaultChartType]);

  useEffect(() => {
    if (selectedMetric) {
      const DynamicCardType = selectedMetric?.paramCardTypeMapping?.map(
        (item) => ({
          ...item,
          name: item.cardName.toLowerCase(),
          value: item.cardName.toLowerCase(),
          icon: item.cardName,
        })
      );
      
      setShowOnly(null)
      setCardTypeList(DynamicCardType);
      setChartTitle(selectedMetric.paramDisplayName);

      const paramCardTypeMapping = selectedMetric?.paramCardTypeMapping;

      const selectedCardType = paramCardTypeMapping?.find(
        (item) => item?.isDefault == 1
      );

      if (selectedCardType) {
        setChartType(selectedCardType?.cardName?.toLowerCase());
        setCardId(selectedCardType?.cardId);
      }
    }
  }, [selectedMetric]);

  useEffect(() => {
    if (selectedMetric && cardId) {
      setChartComparison([])
      const DynamicComparisonList =
        _.uniqBy(
          selectedMetric?.paramComparisonMappingResponse
            .filter((item) => item.cardId == cardId)
            .map((item) => ({
              // ...item,
              id: item.comparisonId,
              description: item.comparisonName,
              value: item.comparisonName,
              label: item.comparisonName,
              isChecked: false,
            })),
          "value"
        ) || [];

      setChartComparisonList(DynamicComparisonList);
    }
  }, [selectedMetric, cardId]);

  useEffect(() => {
    setCardData((prev) => ({
      ...prev,
      cardId,
      chartType,
      chartTitle,
      chartComparison,
      showOnly,
    }));
  }, [chartType, chartTitle, chartComparison, showOnly]);

  const handleChartType = (item) => {
    setChartType(item.value);
    setCardId(item.cardId); //  check here debug
    setChartTitle(selectedMetric?.paramDisplayName);
    setChartComparison([]);
    setShowOnly(null);
    setInputPosition(1)
    setComparionError("")
    setError("")
    setChartComparisonList((prev) =>
      prev.map((prevItem) => ({
        ...prevItem,
        isChecked: false,
      }))
    );
  };

  return (
    <div style={{ flex: 1 }}>
      {selectedMetric ? (
        <Row className="ff" style={{ justifyContent: "space-evenly" }}>
          <Col md={4} className="chart-selector-btn">
            {cardTypeList?.length > 0 && (
              <ChartTypeSelector
                chartOptions={cardTypeList}
                chartType={chartType}
                handleChartType={handleChartType}
              />
            )}
            <Form.Group
              controlId="chartTitle"
              className={`mb-3 ${error && inputPosition >=1 ? "error" : ""}`}
            >
              <Form.Label className="field-title">Chart Title <span>*</span></Form.Label>
              <Form.Control
                value={chartTitle}
                onChange={(e) => {
                  setChartTitle(e.target.value.replace(/^\s+/, ""));
                  setError("");
                }}
                type="text"
                placeholder="Enter Chart Title"
                defaultValue={selectedMetric?.paramDisplayName}
                onBlur={()=>handleBlur(1)}
                maxLength={50}
                className={error ? "error-invalid" : ""}
              />
              {error && inputPosition >=1 && <div className="error-message">{error}</div>}
            </Form.Group>

            {chartType &&
              chartType != "progress" &&
              chartComparisonList?.length > 0 && (
                <Form.Group
                  key={`${selectedMetric.paramDisplayName}-${chartType}-comparison`}
                  controlId="comparison"
                  className="mb-3"
                >
                  <Form.Label className="field-title">Comparison</Form.Label>

                  <MultiSelectSearchCustomCheck
                    isSearch={false}
                    hidofgroup={[]}
                    // itemList={DynamicComparisonList}
                    itemList={chartComparisonList}
                    updateMultiSelectItem={(item: any) => {
                      if (!item) {
                        return;
                      }
                      setChartComparison(item);
                    }}
                    dataChange={() => {}}
                    isSelectAll={true}
                    placeholder={"Select Comparison"}
                    filtertitle={"Select Comparison"}
                  />
                </Form.Group>
              )}

            {chartType &&
              chartType == "progress" &&
              chartComparisonList?.length > 0 && (
                <Form.Group controlId="showOnly" className={`mb-3 ${comparionError && inputPosition >=2 ? "error" : ""}`}>
                  <Form.Label className="field-title">Comparison <span>*</span></Form.Label>
                  <DropDownList
                    placeHolder={"Search Comparison"}
                    data={chartComparisonList}
                    isSearchRequired={false}
                    label={"label"}
                    value={"value"}
                    id="Comparison"
                    name="Comparison"
                    onBlur={()=>handleBlur(2)}
                    onDropDownChange={(item) => {
                      if (!item) {
                        return;
                      }
                      setChartComparisonList((prev) =>
                        prev.map((prevItem) => ({
                          ...prevItem,
                          isChecked: false,
                        }))
                      );
                      setChartComparison([item]);
                      setInputPosition(2)
                    }}
                    selectedItem={[
                      ...[{ value: "", label: "Select Comparison" }],
                      ...chartComparisonList,
                    ].find((r: any) =>
                      chartComparison?.length == 0
                        ? r?.value == ""
                        : r?.value === chartComparison[0]?.value
                    )}
                  />
                   {comparionError && inputPosition >=2 && <div className="error-message">{comparionError}</div>}
                </Form.Group>
              )}

            {chartType && chartType == "bar" && (
              <Form.Group controlId="showOnly" className="mb-3">
                <Form.Label className="field-title">
                  Show Only (optional)
                </Form.Label>
                <ShowOnlyDropdown
                  sections={showOnlySections}
                  onSelect={(item) => {
                    setShowOnly(item);
                  }}
                />
              </Form.Group>
            )}

            <div>
              <Form.Label className="field-title">Description</Form.Label>
              <p>{selectedMetric.paramDescrp || ""}</p>
            </div>
          </Col>

          {chartType && (
            <Col
              md={6}
              className="chart-column"
              style={{ alignSelf: "center" }}
            >
              <MetricChart
                isDummyData
                chartType={chartType}
                chartTitle={chartTitle}
                chartComparison={chartComparison}
                showOnly={showOnly?.item || 5}
                metric={selectedMetric}
              />
            </Col>
          )}
        </Row>
      ) : (
        <div className="default-view">
          <h5 className="sub-heading">Select a metric to start</h5>
          <img
            className="img-fluid"
            src={metricIcon}
            alt="Select a metric to start"
          />
        </div>
      )}
    </div>
  );
};

export default MetricRightPanel;
