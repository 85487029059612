import * as React from "react";
import { Container, Dropdown, Accordion, Card, Button } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { HeaderMenu } from "../../Common/Services/HeaderMenu";
import { resolve, reject } from "q";
import { Player } from "video-react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import {
  ILaborDayApproveRequestDto,
  //  ILaborSmileIdRequestDto,
} from "../../Common/Contracts/ILaborDayApprove";
import { LaborDayApprove } from "../../Common/Services/LaborDayApprove";
import { Hotel as hotel } from "../../Common/Services/Hotel";
import {
  RoleSubPermissionSetting,
  IPageSubPermissionRole,
} from "../../Common/Services/RoleSubPermissionSetting";
import { Register as registersevice } from "../../Common/Services/Register";

import { TransactionSlideout } from "../Register/TransactionSlideout";
import { InvoiceEntrySlideout } from "../Register/InvoiceEntrySlideout";
import { TransferSlideout } from "../Register/TransferSlideout";
import { TransactionSlideout as JournalSlideOut } from '../Accounting/JournalEntry/transactionSlideout';
import { CreditCardSlideout } from '../Register/CreditCardSlideout';
import { accountService } from "../../Common/Services/account";
import _ from "lodash";
import { LockInUserDetailSlideOut } from './../UserManagement/components/user-lockin-slideout/LockInUserDetailSlideOut';
import createDOMPurify from "dompurify";
import { Utils } from "../../Common/Utilis";
import moment from "moment";
import { FreshDeskChat } from "../../Common/ThirdPartyServices/FreshDeskChat";
import { IframeSpike } from "../Reports/ConsolidatedReports/IframeSpike";
const DOMPurify = createDOMPurify(window);

export class Home extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      cardData: [],
      htmlItem: [],
      htmlErrorItem: [],
      htmlWarningItem: [],
      dayApprove: 0,
      gmDayApprove: 0,
      roomAssignment: 0,
      housekeppingApp: 0,
      overrisk: 0,
      smileID: 0,
      payrollExport: 0,
      timeoffrequestCount: 0,
      isLaborOpen: false,
      isAccountOpen: false,
      isChangePwd: false,
      tabName: "OverView",
      activeToggle: false,
      activeToggleAC: true,
      isPayrollPermission: false,
      isToffPPermission: true,
      isOtrPermission: false,
      isSmileIDPermission: false,
      isHouseKeepingPermission: false,
      LaborManagementPanel: false,
      hasLaborMenu: false,
      htmlMobileTaskItem: [],
      JERequest: { UniqueNo: 0 },
      selectedRow: {},
      hidValue: "Select",
      hotelName: "Select",

      registerRequest: { hotelID: -1, lettercode: '', oprID: -1, acctType: "" },
      transferRequest: { hasRecouncil: false, acctType: '' },
      isSlideOut: false,
      isCCSlideout: false,
      trUniqueNo: 0,
      slideOutRequest: {},
      sildeoutHid: -1,
      sildeoutCode: '',
      pageType: "",
      oprId: -1,
      vid: 0,
      isTransferSlideOut: false,
      accountingOverviewData: [],
      accountingOverviewCounts: [],
      unpaidTableData: [],
      hiddenBoxes: "",
      isApproveDayHidden: false,
      isGMApproveDayHidden: false,
      isSmileIDHidden: false,
      isRoomAssignmentsHidden: false,
      isHousekeepingApprovalsHidden: false,
      isotarHidden: false,
      isHolidayCalendarHidden: false,
      isPEHidden: false,
      isToffRHidden: false,
      isBreakExpHidden: false,
      hasAccountingMenu: false,
      callFrom: "",
    };
    this.toggleClass = this.toggleClass.bind(this);
    this.toggleClassAC = this.toggleClassAC.bind(this);
    this.handleDismissCard = this.handleDismissCard.bind(this);
    this.openOverView = this.openOverView.bind(this);
  }

  componentDidMount() {
    this.getPayrollPermission();
    this.getHomeCard();
    this.getMenuItems();
    this.handleDeepLinking();
   
    // this.getAccountOverviewStat();
    // HeaderMenu.SetAlertpip();

    // if(!this.props.location?.state?.showIncompleteSlideOut ) {
    //   localStorage.setItem("showIncompleteSlideOut",  "false");
    //  } 
  }
  

  getAccountOverviewStat = () => {
    accountService.GetAccountOverviewStat().then((data) => {
      let accountingOverviewCounts: any[] = [];
      const unpaidTableData: any[] = [];
      let hotelIds = "";

      if (data?.length > 0) {
        hotelIds = _.uniq(data?.filter(x => x?.type === 2)
          ?.map((x) => x.hotelId)).join(",");
        const flags = data[0];

        const draftInvoiceSum: number = data
          ?.map((x) => +x.draftInvoice)
          ?.reduce((a, b) => a + b, 0);
        const draftInvoiceObj: any = {
          label: "Draft Invoices",
          counts: draftInvoiceSum,
          name: "draftInvoice",
          isVisible: flags?.showDraftInvoice
        };
        accountingOverviewCounts.push(draftInvoiceObj);

        const unapprovedInvoiceSum: number = data
          ?.map((x) => +x.unapprovedInvoice)
          ?.reduce((a, b) => a + b, 0);
        const unapprovedInvoiceObj: any = {
          label: "Unapproved Invoices",
          counts: unapprovedInvoiceSum,
          name: "unapprovedInvoice",
          isVisible: flags?.showUnapprovedInvoice
        };
        accountingOverviewCounts.push(unapprovedInvoiceObj);

        const unapprovedVendorsSum = data
          ?.map((x) => +x.unapprovedVendors)[0];
        const unapprovedVendorsObj: any = {
          label: "Unapproved Vendors",
          counts: unapprovedVendorsSum,
          name: "unapprovedVendors",
          isVisible: flags?.showUnapprovedVendors
        };


        accountingOverviewCounts.push(unapprovedVendorsObj);

        const unpaidInvoiceSum = data
          ?.map((x) => +x.unpaidInvoiceACH + +x.unpaidInvoiceCheck)
          ?.reduce((a, b) => a + b, 0);
        const unpaidInvoiceObj: any = {
          label: "Unpaid Invoices",
          counts: unpaidInvoiceSum,
          name: "unpaidInvoice",
          isVisible: flags?.showUnpaidInvoice

        };

        unpaidTableData.push({
          id: 1,
          name: "Unpaid Digital ACH",
          counts: data
            ?.map((x) => +x.unpaidInvoiceACH)
            ?.reduce((a, b) => a + b, 0),
        });
        unpaidTableData.push({
          id: 2,
          name: "Unpaid Checks",
          counts: data
            ?.map((x) => +x.unpaidInvoiceCheck)
            ?.reduce((a, b) => a + b, 0),
        });
        accountingOverviewCounts.push(unpaidInvoiceObj);
        const untrustedVendorsSum = data
          ?.map((x) => +x.untrustedVendors)[0];
        const untrustedVendorsObj: any = {
          label: "Untrused Vendors",
          counts: untrustedVendorsSum,
          name: "untrustedVendors",
          isVisible: flags?.showUntrustedVendors
        };
        accountingOverviewCounts.push(untrustedVendorsObj);

        const flagTransactionSum: number = data
          ?.map((x) => +x.flaggedTransaction)
          ?.reduce((a, b) => a + b, 0);
        const flagTransactionObj: any = {
          label: "Flagged Transactions",
          counts: flagTransactionSum,
          name: "flaggedTransactions",
          isVisible: flags?.showFlaggedTransaction
        };
        accountingOverviewCounts.push(flagTransactionObj);

      }
      accountingOverviewCounts = accountingOverviewCounts.filter(x => x?.counts > 0 && x?.isVisible)
      this.setState({ accountingOverviewCounts }, () => {
        this.getMobileShowTab();
      });
    })
  };

  getMenuItems() {
    HeaderMenu.GetHeaderData()
      .then(async (result: any[] | null) => {
        if (result != null) {
          let menuItemTop: any[] = [];
          let { hasLaborMenu, hasAccountingMenu } = this.state;
          menuItemTop = result.filter((e: { itemPosition: string }) => e.itemPosition === "Top");
          hasLaborMenu = menuItemTop.some((e: { name: string }) => e.name === "Labor");
          hasAccountingMenu = menuItemTop.some((e: { name: string }) => e.name === "Accounting");
          this.setState({ hasLaborMenu, hasAccountingMenu });
          if (hasLaborMenu) {
            this.bindDailyAlert();
          }
          if (hasAccountingMenu) {
            this.getAccountOverviewStat();
          }
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`);
        reject();
      });
  }

  getPayrollPermission = () => {
    let requestObject = {} as IPageSubPermissionRole;
    requestObject.permissionID = 1503;
    requestObject.Module = "LaborManagement";
    requestObject.Type = "page";
    RoleSubPermissionSetting.GetPageSubPermissionDetails(requestObject).then(async (result: any | null) => {
      if (result !== null && result.length > 0) {
        result.forEach((element: any) => {
          this.setState({ LaborManagementPanel: true })
          switch (element.permissionID) {
            // case 10008:
            //   this.setState({ isToffPPermission: true });
            //   break;
            case 10010:
              this.setState({ isPayrollPermission: true });
              break;
            case 10000:
              this.setState({ isOtrPermission: true });
              break;
            case 10004:
              this.setState({ isSmileIDPermission: true });
              break;
            case 10007:
              this.setState({ isHouseKeepingPermission: true });
              break;
          }
        });
      } else {
        this.setState({ LaborManagementPanel: false })
      }
      resolve();
    })
      .catch((err: any) => {
        reject();
      });
  };
  toggleClass() {
    const currentStateAC = this.state.activeToggleAC;
    const currentState = this.state.activeToggle;
    if (!currentStateAC) {
      this.setState({ activeToggleAC: !currentStateAC })
    }
    this.setState({ activeToggle: !currentState }, () => {
      this.getMobileShowTab();
    });
  }

  toggleClassAC() {
    const currentStateAC = this.state.activeToggleAC;
    const currentState = this.state.activeToggle;
    if (!currentState) {
      this.setState({ activeToggle: !currentState })
    }
    this.setState({ activeToggleAC: !currentStateAC }, () => {
      this.getMobileShowTab();
    });
  }

  redirectToResetPassword = () => {
    ;
    this.setState({ isChangePwd: true });
    var btnProfile = $("[id*=defaultSet-Profile]");
    if (btnProfile?.length > 0) {
      btnProfile[0]?.click();
    }

    setTimeout(() => {
      var btnChangePassword = $("[id*=Change-Password]");
      if (btnChangePassword?.length > 0) {
        btnChangePassword[0]?.click();
      }
    }, 500);

    // btnChangePassword[0].className ="nav-link active";
    //localStorage.setItem("subMenu", "Change Password");
  };

  openOverView(tabName) {
    this.setState({ isLaborOpen: true, isAccountOpen: false, tabName: tabName });
    var btnlabor = $("[id*=defaultSet-Labor]");
    var btnHome = $("[id*=defaultSet-Home]");
    btnlabor[0].className = "nav-link active";
    btnHome[0].className = "nav-link";
    localStorage.setItem("mainMenu", "Labor");
  }

  openAccounting(tabName) {
    this.setState({ isAccountOpen: true, isLaborOpen: false, tabName: tabName });
    var btnlabor = $("[id*=defaultSet-Accounting]");
    var btnHome = $("[id*=defaultSet-Home]");
    btnlabor[0].className = "nav-link active";
    btnHome[0].className = "nav-link";
    localStorage.setItem("mainMenu", "Accounting");
  }

  payrollExportFirstApi = () => {
    let request: any = {};
    let localStorageUser = JSON.parse(localStorage.getItem("storage")!);
    request.tenantID = localStorageUser.tenantID;
    request.hotelID = 0;
    request.LoginUserName = localStorageUser.userName;
    request.callType = "HotelWiseCount";
    hotel
      .GetPayrollExportData(request)
      .then(async (result) => {
        if (result.message === "Success") {
          let dayCount = 0;
          let apiResult = result.result;
          for (let i = 0; i < apiResult.length; i++) {
            dayCount = dayCount + apiResult[i].payPeriodCount;
          }
          this.setState({ payrollExport: dayCount }, () => {
            this.getMobileShowTab();
          });
        }

        resolve();
      })
      .catch((error) => {
        this.setState({ loaderTrue: false });
        Utils.toastError(`Server Error: ${error}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "laborDayOverviewContainer",
        });
        reject();
      });
  };


  TimeOffRequestFirstApi = () => {
    let request: any = {};
    let localStorageUser = JSON.parse(localStorage.getItem("storage")!);
    request.tenantID = localStorageUser.tenantID;
    request.hotelID = 0;
    request.LoginUserName = localStorageUser.userName;
    request.callType = "HotelWiseCount";
    hotel.GetPendingTimeoffCount(request).then(async (result) => {
      if (result.success) {
        let dayCount = 0;
        let apiResult = result.result;
        for (let i = 0; i < apiResult.length; i++) {
          dayCount = dayCount + apiResult[i].countData;
        }
        this.setState({ timeoffrequestCount: dayCount }, () => {
          this.getMobileShowTab();
        });
      }
      resolve();
    })
      .catch((error) => {
        this.setState({ loaderTrue: false });
        Utils.toastError(`Server Error: ${error}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "timeoffrequestCountContainer",
        });
        reject();
      });
  };



  bindOverRisk(response: any) {
    hotel
      .GetOverTimeRiskData(response)
      .then(async (result) => {
        if (result != null) {
          let data = result.result;
          this.setState({ overrisk: data[0].result }, () => {
            this.getMobileShowTab();
          });
        }
      })
      .catch((error) => {
        reject();
      });
  }

  bindDailyAlert() {
    let localStorageUser = JSON.parse(localStorage.getItem("storage")!);
    let request = {} as ILaborDayApproveRequestDto;
    request.hotelID = "All";
    request.userUniqueNo = localStorageUser.user_UniqueID;
    this.binDayApproveData(request);

    let response1: any = {};
    response1.tenantID = localStorageUser.tenantID;
    response1.hotelID = 0;
    response1.loginUserName = localStorageUser.userName;
    response1.callType = "AllHotelCount";
    response1.userName = "";
    this.bindOverRisk(response1);

    this.payrollExportFirstApi();
    this.TimeOffRequestFirstApi();
    this.getMobileShowTab();
  }

  binDayApproveData(request: ILaborDayApproveRequestDto) {
    LaborDayApprove.GetLaborDayApprovalDetails(request)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          // console.log(result);
          //debugger;
          // let dayApprove = 0, roomAssignment = 0, housekeppingApp = 0, overrisk = 0
          for (let x = 0; x < result.length; x++) {
            let dayType = result[x].dayType;
            let dayCount = result[x].dayCount;

            switch (dayType) {
              case "DailyApproveDay":
                this.setState({ dayApprove: dayCount });
                break;
              case "GMApproveDay":
                this.setState({ gmDayApprove: dayCount });
                break;
              case "SmileID":
                this.setState({ smileID: dayCount });
                break;
              case "RoomAssignments":
                this.setState({ roomAssignment: dayCount });
                break;
              case "HousekeepingApprovals":
                this.setState({ housekeppingApp: dayCount });
                break;
              case "OverTimeRisk":
                this.setState({ overrisk: dayCount });
                break;
              case "HolidayCalendar":
                this.setState({ HolidayCalendar: dayCount });
                break;
              case "BreakException":
                this.setState({ breakExceptionCount: dayCount });
                break;
            }
          }
          this.setState({ hiddenBoxes: result[0].hideCol }, () => {
            let hiddenBoxesArray = this.state.hiddenBoxes.split(",");
            for (let i = 0; i < hiddenBoxesArray.length; i++) {
              //debugger;
              switch (hiddenBoxesArray[i].trim()) {
                case "ApproveDay":
                  this.setState({ isApproveDayHidden: true });
                  break;
                case "GMApproveDay":
                  this.setState({ isGMApproveDayHidden: true });
                  break;
                case "SmileID":
                  this.setState({ isSmileIDHidden: true });
                  break;
                case "RoomAssignments":
                  this.setState({ isRoomAssignmentsHidden: true });
                  break;
                case "HousekeepingApprovals":
                  this.setState({ isHousekeepingApprovalsHidden: true });
                  break;
                case "otar":
                  this.setState({ isotarHidden: true });
                  break;
                case "HolidayCalendar":
                  this.setState({ isHolidayCalendarHidden: true });
                  break;
                case "PE":
                  this.setState({ isPEHidden: true });
                  break;
                case "ToffR":
                  this.setState({ isToffRHidden: true });
                  break;
                case "BreakException":
                  this.setState({ isBreakExpHidden: true });
                  break;
                default:
                  break;
              }
            }
          });
          this.getMobileShowTab();
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  }

  handleDismissCard(ID: number, cardID: number) {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);

    let saveData = {} as any;
    saveData.id = ID;
    saveData.cardID = cardID;
    saveData.tenantID = tenantID;

    HeaderMenu.saveDismissCard(saveData)
      .then(async (result: any | null) => {
        if (result != null) {
          if (result.saveStatus === "Success") {
            this.getHomeCard();

            toast.success(result.message, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "HomeCard",
            });
            this.setState({ loaderTrue: false, isNewPosition: false });
            this.setState({ selectedItems: [] });
          } else {
            Utils.toastError(result.message, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "HomeCard",
            });
          }
        } else {
          Utils.toastError("Internal server error.", {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "HomeCard",
          });
        }
        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error) => {
        reject();
        this.setState({ loaderTrue: false });
      });
  }

  getHomeCard() {
    HeaderMenu.CardDetails()
      .then(async (result: any[] | null) => {
        if (result != null) {
          this.setState(
            {
              cardData: result,
              htmlItem: [],
            },
            () => {
              let item: any[] = [];
              let itemError: any[] = [];
              let itemWarning: any[] = [];
              let dataArr: any[] = [];
              let dataArrError: any[] = [];
              let dataArrWarnning: any[] = [];
              dataArr = result.filter((e) => e.orderno === 3);
              dataArrError = result.filter((e) => e.orderno === 1);
              dataArrWarnning = result.filter((e) => e.orderno === 2);

              for (let x = 0; x < dataArrError.length; x++) {
                // let ID = dataArrError[x].id;
                // let CardID = dataArrError[x].cardID;
                // let CardType = dataArrError[x].cardType;
                let Title = dataArrError[x].title;
                // let Title2 = dataArrError[x].title2;
                let Content = dataArrError[x].content;
                // let IconURL = dataArrError[x].iconURL;
                // let BgImageURL = dataArrError[x].bgImageURL;
                // let VideoURL = dataArrError[x].videoURL;
                let Cta1Text = dataArrError[x].cta1Text;
                // let Cta1Link = dataArrError[x].cta1Link;
                // let Cta2Text = dataArrError[x].cta2Text;
                // let Cta2Link = dataArrError[x].cta2Link;
                // let AlwaysonTop = dataArrError[x].alwaysonTop;
                // let Dismiss = dataArrError[x].dismiss;

                itemError.push(
                  <li className="error d-flex align-items-center">
                    <div className="icon">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g
                          // clip-path="url(#clip0)"
                          clipPath="url(#clip0)"
                        >
                          <path
                            d="M31.5877 25.3996L18.4618 3.63137C17.9447 2.77381 17.0014 2.24106 16 2.24106C14.9985 2.24106 14.0552 2.77381 13.538 3.63143L0.412215 25.3996C-0.122222 26.286 -0.138035 27.3965 0.370903 28.2977C0.879965 29.199 1.83909 29.7589 2.87409 29.7589H29.1258C30.1608 29.7589 31.12 29.199 31.6291 28.2977C32.138 27.3964 32.1222 26.2859 31.5877 25.3996ZM29.815 27.2732C29.6748 27.5213 29.4108 27.6755 29.1258 27.6755H2.87409C2.58915 27.6755 2.32509 27.5214 2.18503 27.2733C2.0449 27.0252 2.04928 26.7194 2.19634 26.4755L15.3223 4.70725C15.4647 4.47118 15.7243 4.3245 16 4.3245C16.2757 4.3245 16.5353 4.47118 16.6777 4.70725L29.8035 26.4755C29.9507 26.7195 29.9551 27.0252 29.815 27.2732Z"
                            fill="#B12A18"
                          />
                          <path
                            d="M16.0096 10.8128C15.2171 10.8128 14.5986 11.2381 14.5986 11.9919C14.5986 14.2921 14.8692 17.5973 14.8692 19.8975C14.8693 20.4967 15.3912 20.7479 16.0097 20.7479C16.4736 20.7479 17.1308 20.4967 17.1308 19.8975C17.1308 17.5974 17.4013 14.2921 17.4013 11.9919C17.4013 11.2381 16.7635 10.8128 16.0096 10.8128Z"
                            fill="#B12A18"
                          />
                          <path
                            d="M16.0291 22.0816C15.1787 22.0816 14.5408 22.7581 14.5408 23.57C14.5408 24.3625 15.1786 25.0584 16.0291 25.0584C16.8216 25.0584 17.4982 24.3625 17.4982 23.57C17.4982 22.7581 16.8216 22.0816 16.0291 22.0816Z"
                            fill="#B12A18"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0">
                            <rect width="32" height="32" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div className="description mr-auto">
                      {Title}
                      <div
                        className="sub-desc"
                        //dangerouslySetInnerHTML={{ __html: Content }}
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(Content) }}
                      ></div>
                    </div>
                    <div className="action">
                      <Button
                        className="error d-flex align-items-center"
                        href="#"
                      >
                        {Cta1Text}
                      </Button>
                    </div>
                  </li>
                );
              }

              for (let x = 0; x < dataArrWarnning.length; x++) {
                // let ID = dataArrWarnning[x].id;
                // let CardID = dataArrWarnning[x].cardID;
                // let CardType = dataArrWarnning[x].cardType;
                let Title = dataArrWarnning[x].title;
                let Title2 = dataArrWarnning[x].title2;
                let Content = dataArrWarnning[x].content;
                // let IconURL = dataArrWarnning[x].iconURL;
                // let BgImageURL = dataArrWarnning[x].bgImageURL;
                // let VideoURL = dataArrWarnning[x].videoURL;
                let Cta1Text = dataArrWarnning[x].cta1Text;
                // let Cta1Link = dataArrWarnning[x].cta1Link;
                // let Cta2Text = dataArrWarnning[x].cta2Text;
                // let Cta2Link = dataArrWarnning[x].cta2Link;
                // let AlwaysonTop = dataArrWarnning[x].alwaysonTop;
                // let Dismiss = dataArrWarnning[x].dismiss;

                //let resetPassword =<a target="_blank" onClick = {this.redirectToResetPassword}> set a new password </a>;

                // Content = "Your password will expire in  5 days. Please"+ <a target="_blank">+" set a new password"+ </a>  +"before it expires"

                if (Title2 === null) {
                  Title2 = "";
                }

                itemWarning.push(
                  <li className="error d-flex align-items-center warning">
                    <div className="icon">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M30.125 18.2656V2.875C30.125 2.35718 29.7053 1.9375 29.1875 1.9375H24.4375V0.9375C24.4375 0.419678 24.0178 0 23.5 0C22.9822 0 22.5625 0.419678 22.5625 0.9375V1.9375H18.8125V0.9375C18.8125 0.419678 18.3928 0 17.875 0C17.3572 0 16.9375 0.419678 16.9375 0.9375V1.9375H13.1875V0.9375C13.1875 0.419678 12.7678 0 12.25 0C11.7322 0 11.3125 0.419678 11.3125 0.9375V1.9375H7.5625V0.9375C7.5625 0.419678 7.14282 0 6.625 0C6.10718 0 5.6875 0.419678 5.6875 0.9375V1.9375H0.9375C0.419678 1.9375 0 2.35718 0 2.875V27.3125C0 27.8303 0.419678 28.25 0.9375 28.25H16.5503C18.0659 30.5095 20.6433 32 23.5625 32C28.2148 32 32 28.2148 32 23.5625C32 21.5581 31.2969 19.7148 30.125 18.2656ZM5.6875 3.8125V4.75C5.6875 5.26782 6.10718 5.6875 6.625 5.6875C7.14282 5.6875 7.5625 5.26782 7.5625 4.75V3.8125H11.3125V4.75C11.3125 5.26782 11.7322 5.6875 12.25 5.6875C12.7678 5.6875 13.1875 5.26782 13.1875 4.75V3.8125H16.9375V4.75C16.9375 5.26782 17.3572 5.6875 17.875 5.6875C18.3928 5.6875 18.8125 5.26782 18.8125 4.75V3.8125H22.5625V4.75C22.5625 5.26782 22.9822 5.6875 23.5 5.6875C24.0178 5.6875 24.4375 5.26782 24.4375 4.75V3.8125H28.25V7.5625H1.875V3.8125H5.6875ZM1.875 26.375V9.4375H28.25V16.5503C26.9084 15.6506 25.2957 15.125 23.5625 15.125C20.6001 15.125 17.9897 16.6599 16.4839 18.9758C16.3572 18.9119 16.2144 18.875 16.0625 18.875H14.1875C13.6697 18.875 13.25 19.2947 13.25 19.8125C13.25 20.3303 13.6697 20.75 14.1875 20.75H15.6077C15.396 21.3472 15.2495 21.9751 15.1775 22.625H14.1875C13.6697 22.625 13.25 23.0447 13.25 23.5625C13.25 24.0803 13.6697 24.5 14.1875 24.5H15.1775C15.2495 25.1499 15.3958 25.7778 15.6077 26.375H1.875ZM23.5625 30.125C19.9438 30.125 17 27.1812 17 23.5625C17 19.9438 19.9438 17 23.5625 17C27.1812 17 30.125 19.9438 30.125 23.5625C30.125 27.1812 27.1812 30.125 23.5625 30.125Z"
                          fill="#863902"
                        />
                        <path
                          d="M27.3125 22.625H24.5V19.8125C24.5 19.2947 24.0803 18.875 23.5625 18.875C23.0447 18.875 22.625 19.2947 22.625 19.8125V23.5625C22.625 24.0803 23.0447 24.5 23.5625 24.5H27.3125C27.8303 24.5 28.25 24.0803 28.25 23.5625C28.25 23.0447 27.8303 22.625 27.3125 22.625Z"
                          fill="#863902"
                        />
                        <path
                          d="M8.5 11.375H6.625C6.10718 11.375 5.6875 11.7947 5.6875 12.3125C5.6875 12.8303 6.10718 13.25 6.625 13.25H8.5C9.01782 13.25 9.4375 12.8303 9.4375 12.3125C9.4375 11.7947 9.01782 11.375 8.5 11.375Z"
                          fill="#863902"
                        />
                        <path
                          d="M8.5 15.125H6.625C6.10718 15.125 5.6875 15.5447 5.6875 16.0625C5.6875 16.5803 6.10718 17 6.625 17H8.5C9.01782 17 9.4375 16.5803 9.4375 16.0625C9.4375 15.5447 9.01782 15.125 8.5 15.125Z"
                          fill="#863902"
                        />
                        <path
                          d="M8.5 18.875H6.625C6.10718 18.875 5.6875 19.2947 5.6875 19.8125C5.6875 20.3303 6.10718 20.75 6.625 20.75H8.5C9.01782 20.75 9.4375 20.3303 9.4375 19.8125C9.4375 19.2947 9.01782 18.875 8.5 18.875Z"
                          fill="#863902"
                        />
                        <path
                          d="M14.1875 13.25H16.0625C16.5803 13.25 17 12.8303 17 12.3125C17 11.7947 16.5803 11.375 16.0625 11.375H14.1875C13.6697 11.375 13.25 11.7947 13.25 12.3125C13.25 12.8303 13.6697 13.25 14.1875 13.25Z"
                          fill="#863902"
                        />
                        <path
                          d="M14.1875 17H16.0625C16.5803 17 17 16.5803 17 16.0625C17 15.5447 16.5803 15.125 16.0625 15.125H14.1875C13.6697 15.125 13.25 15.5447 13.25 16.0625C13.25 16.5803 13.6697 17 14.1875 17Z"
                          fill="#863902"
                        />
                        <path
                          d="M8.5 22.625H6.625C6.10718 22.625 5.6875 23.0447 5.6875 23.5625C5.6875 24.0803 6.10718 24.5 6.625 24.5H8.5C9.01782 24.5 9.4375 24.0803 9.4375 23.5625C9.4375 23.0447 9.01782 22.625 8.5 22.625Z"
                          fill="#863902"
                        />
                        <path
                          d="M21.6875 13.25H23.5625C24.0803 13.25 24.5 12.8303 24.5 12.3125C24.5 11.7947 24.0803 11.375 23.5625 11.375H21.6875C21.1697 11.375 20.75 11.7947 20.75 12.3125C20.75 12.8303 21.1697 13.25 21.6875 13.25Z"
                          fill="#863902"
                        />
                      </svg>
                    </div>
                    <div className="details">
                      <div className="description mr-auto">
                        {Title}
                        <div
                          className="sub-desc"
                          //dangerouslySetInnerHTML={{ __html: Content }}
                          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(Content) }}
                        ></div>
                      </div>

                      {Cta1Text !== "" && (
                        <div className="action">
                          {Title2.toLowerCase() === "password".toLowerCase() && (
                            <Button
                              className="error warning d-flex align-items-center"
                              href="#"
                              onClick={this.redirectToResetPassword}
                            >
                              {Cta1Text}
                            </Button>
                          )}
                          {Title2.toLowerCase() !== "password".toLowerCase() && (
                            <Button
                              className="error warning d-flex align-items-center"
                              href="#"
                            >
                              {Cta1Text}
                            </Button>
                          )}
                        </div>
                      )}
                    </div>
                  </li>
                );
              }

              for (let x = 0; x < dataArr.length; x++) {
                let ID = dataArr[x].id;
                let CardID = dataArr[x].cardID;
                let CardType = dataArr[x].cardType;
                let Title = dataArr[x].title;
                let Title2 = dataArr[x].title2;
                let Content = dataArr[x].content;
                let IconURL = dataArr[x].iconURL;
                let BgImageURL = dataArr[x].bgImageURL;
                let VideoURL = dataArr[x].videoURL;
                let Cta1Text = dataArr[x].cta1Text;
                let Cta1Link = dataArr[x].cta1Link;
                let Cta2Text = dataArr[x].cta2Text;
                let Cta2Link = dataArr[x].cta2Link;
                // let AlwaysonTop = dataArr[x].alwaysonTop;
                let Dismiss = dataArr[x].dismiss;
                let isFreshdeskLink = dataArr[x].isFreshdeskLink;

                switch (CardType) {
                  case "Left-aligned Icon card":
                    item.push(
                      <li className="card left-aligned">
                        <div className="d-flex card-content-wrapper">
                          <div className="icon">
                            <img src={IconURL} alt={Title} />
                          </div>
                          <div className="body-part">
                            <div className="title">{Title}</div>
                            <div
                              className="description"
                              //dangerouslySetInnerHTML={{ __html: Content }}
                              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(Content) }}
                            ></div>
                            <div className="action">
                              {
                                isFreshdeskLink ?


                                  <a className="d-flex align-items-center wht-bg-outline btn btn-primary" onClick={() => FreshDeskChat.redirectToFreshDeskURL(Cta1Link)} target="_blank">{Cta1Text}</a>

                                  : <a className="d-flex align-items-center wht-bg-outline btn btn-primary" href={Cta1Link} target="_blank">{Cta1Text}</a>
                              }
                              {/* <Link
                                className="d-flex align-items-center wht-bg-outline btn btn-primary"
                                to={Cta1Link}
                                target="_blank"
                              >
                                {Cta1Text}
                              </Link> */}
                            </div>
                          </div>
                        </div>
                        {Dismiss === "Yes" && (
                          <div className="dismiss">
                            <Dropdown alignRight>
                              <Dropdown.Toggle
                                className="btn-outline-primary  more"
                                id="dropdown-basic"
                              >
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                  <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                  <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                </svg>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  id="dismiss1"
                                  onClick={() =>
                                    this.handleDismissCard(ID, CardID)
                                  }
                                >
                                  Dismiss
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        )}
                      </li>
                    );
                    break;
                  case "Event card":
                    item.push(
                      <li
                        className="card right-aligned"
                        style={{
                          backgroundImage: "url(" + BgImageURL + ")",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <div className="body-part mr-auto">
                            <div className="title">{Title}</div>
                            <div
                              className="description"
                              //dangerouslySetInnerHTML={{ __html: Content }}
                              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(Content) }}
                            ></div>
                            <div className="action">
                              {
                                isFreshdeskLink ?
                                  <a className="d-flex align-items-center wht-bg-outline btn btn-primary"
                                    onClick={() => FreshDeskChat.redirectToFreshDeskURL(Cta1Link)} target="_blank">{Cta1Text}</a>
                                  : <a className="d-flex align-items-center wht-bg-outline btn btn-primary" href={Cta1Link} target="_blank">{Cta1Text}</a>
                              }

                              <a className="d-flex align-items-center wht-bg-outline btn btn-primary remove-bdr" href={Cta2Link} target="_blank">{Cta2Text}</a>

                            </div>
                          </div>
                          <div className="icon">
                            <img src={IconURL} alt={Title} />
                          </div>
                        </div>
                        {Dismiss === "Yes" && (
                          <div className="dismiss">
                            <Dropdown alignRight>
                              <Dropdown.Toggle
                                className="btn-outline-primary  more"
                                id="dropdown-basic"
                              >
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                  <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                  <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                </svg>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  id="dismiss2"
                                  onClick={() =>
                                    this.handleDismissCard(ID, CardID)
                                  }
                                >
                                  Dismiss
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        )}
                      </li>
                    );
                    break;
                  case "Text Only card":
                    item.push(
                      <li className="card left-aligned">
                        <div className="d-flex align-items-center">
                          <div className="body-part">
                            <div className="title">{Title}</div>
                            <div
                              className="description"
                              //dangerouslySetInnerHTML={{ __html: Content }}
                              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(Content) }}
                            ></div>
                            <div className="action">
                              {isFreshdeskLink ?

                                <a className="d-flex align-items-center wht-bg-outline btn btn-primary" onClick={() => FreshDeskChat.redirectToFreshDeskURL(Cta1Link)} target="_blank">{Cta1Text}</a>
                                :
                                <a className="d-flex align-items-center wht-bg-outline btn btn-primary" href={Cta1Link} target="_blank">{Cta1Text}</a>
                              }<a className="d-flex align-items-center wht-bg-outline btn btn-primary remove-bdr" href={Cta2Link} target="_blank">{Cta2Text}</a>
                            </div>
                          </div>
                        </div>
                        {Dismiss === "Yes" && (
                          <div className="dismiss">
                            <Dropdown alignRight>
                              <Dropdown.Toggle
                                className="btn-outline-primary  more"
                                id="dropdown-basic"
                              >
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                  <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                  <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                </svg>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  id="dismiss3"
                                  onClick={() =>
                                    this.handleDismissCard(ID, CardID)
                                  }
                                >
                                  Dismiss
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        )}
                      </li>
                    );
                    break;
                  case "Tall Video card":
                    item.push(
                      <li className="card video">
                        <Player>
                          <source src={VideoURL} />
                        </Player>
                        <div className="footer">
                          <div className="title">{Title}</div>
                          <div
                            className="desc"
                            //dangerouslySetInnerHTML={{ __html: Content }}
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(Content) }}
                          ></div>
                        </div>
                        {Dismiss === "Yes" && (
                          <div className="dismiss">
                            <Dropdown alignRight>
                              <Dropdown.Toggle
                                className="btn-outline-primary  more"
                                id="dropdown-basic"
                              >
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                  <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                  <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                </svg>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  id="dismiss4"
                                  onClick={() =>
                                    this.handleDismissCard(ID, CardID)
                                  }
                                >
                                  Dismiss
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        )}
                      </li>
                    );
                    break;
                  case "Tall Image Background card":
                    item.push(
                      <li
                        className="card center-aligned-bg"
                        style={{
                          backgroundImage: "url(" + BgImageURL + ")",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        {" "}
                        <div className="d-flex align-items-center justify-content-center height-100">
                          <div className="body-part">
                            <div className="title">{Title}</div>
                            <div className="title heading">{Title2}</div>
                            <div
                              className="description"
                              //dangerouslySetInnerHTML={{ __html: Content }}
                              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(Content) }}
                            ></div>
                            <div className="action">
                              {isFreshdeskLink ?

                                <a className="d-flex align-items-center wht-bg-outline btn btn-primary" onClick={() => FreshDeskChat.redirectToFreshDeskURL(Cta1Link)} target="_blank">{Cta1Text}</a>

                                : <a className="d-flex align-items-center wht-bg-outline btn btn-primary" href={Cta1Link} target="_blank">{Cta1Text}</a>
                              }
                            </div>
                          </div>
                        </div>
                        {Dismiss === "Yes" && (
                          <div className="dismiss">
                            <Dropdown alignRight>
                              <Dropdown.Toggle
                                className="btn-outline-primary  more"
                                id="dropdown-basic"
                              >
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                  <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                  <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                </svg>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  id="dismiss5"
                                  onClick={() =>
                                    this.handleDismissCard(ID, CardID)
                                  }
                                >
                                  Dismiss
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        )}
                      </li>
                    );
                    break;
                  case "Tall Center-aligned Icon card":
                    item.push(
                      <li className="card center-aligned">
                        <div className="icon">
                          <img src={IconURL} alt={Title} />
                        </div>
                        <div className="body-part">
                          <div className="title">{Title}</div>
                          <div
                            className="description"
                            //dangerouslySetInnerHTML={{ __html: Content }}
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(Content) }}
                          ></div>
                          <div className="action">
                            {isFreshdeskLink ?
                              <a className="d-flex align-items-center wht-bg-outline btn btn-primary" onClick={() => FreshDeskChat.redirectToFreshDeskURL(Cta1Link)} target="_blank">{Cta1Text}</a>
                              : <a className="d-flex align-items-center wht-bg-outline btn btn-primary" href={Cta1Link} target="_blank">{Cta1Text}</a>
                            }
                            <a className="d-flex align-items-center wht-bg-outline btn btn-primary remove-bdr" href={Cta2Link} target="_blank">{Cta2Text}</a>
                          </div>
                        </div>
                        {Dismiss === "Yes" && (
                          <div className="dismiss">
                            <Dropdown alignRight>
                              <Dropdown.Toggle
                                className="btn-outline-primary  more"
                                id="dropdown-basic"
                              >
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                  <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                  <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                </svg>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  id="dismiss6"
                                  onClick={() =>
                                    this.handleDismissCard(ID, CardID)
                                  }
                                >
                                  Dismiss
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        )}
                      </li>
                    );
                    break;
                }
              }

              this.setState({
                htmlItem: item,
                htmlErrorItem: itemError,
                htmlWarningItem: itemWarning,
              });
            }
          );
        }
        resolve();
      })
      .catch((error: any) => {
        this.setState({ loaderTrue: false });
        reject();
      });
  }



  getMobileShowTab = () => {
    let itemMobileShow: any[] = [];

    itemMobileShow.push(
      <li className="task-list d-flex align-items-center">
        {this.state.LaborManagementPanel && (
          <div className="sec-name d-none">Tasks</div> // Hidden 
        )}
        {this.state.LaborManagementPanel && (
          <>

            {/* {this.state.hasLaborMenu && ( */}
            <div className="Module-list">
              <Accordion defaultActiveKey="0">
                {this.state.hasLaborMenu && (
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle
                        as={Button}
                        className={this.state.activeToggle ? "activeToggle" : ""}
                        onClick={this.toggleClass}
                        variant="link"
                        eventKey="0"
                      >
                        <div className="sec-name">Tasks</div>
                        <div className="d-flex">
                          <div className="mr-auto">Labor Management</div>
                          <div>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.29297 10.707L12 16.414L17.707 10.707L16.293 9.293L12 13.586L7.70697 9.293L6.29297 10.707Z"
                                fill="#84888C"
                              />
                            </svg>
                          </div>
                        </div>
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <ul style={{ cursor: "pointer" }}>

                          {this.state.dayApprove > 0 && (
                            <li>
                              <a
                                className="d-flex"
                                onClick={() => this.openOverView("DailyApproveDay")}
                              >
                                <div className="mr-auto">
                                  <EllipsisWithTooltip placement="bottom">
                                    Daily Labor Approval
                                  </EllipsisWithTooltip>
                                </div>
                                <div className="badge">{this.state.dayApprove}</div>
                              </a>
                            </li>
                          )}
                          {this.state.gmDayApprove > 0 && (
                            <li>
                              <a
                                className="d-flex"
                                onClick={() => this.openOverView("GmApproveDay")}
                              >
                                <div className="mr-auto">
                                  <EllipsisWithTooltip placement="bottom">
                                    Daily GM Approvals
                                  </EllipsisWithTooltip>
                                </div>
                                <div className="badge">{this.state.gmDayApprove}</div>
                              </a>
                            </li>
                          )}
                          {this.state.smileID > 0 && this.state.isSmileIDPermission && (
                            <li>
                              <a
                                className="d-flex"
                                onClick={() => this.openOverView("SmileID")}
                              >
                                <div className="mr-auto">
                                  <EllipsisWithTooltip placement="bottom">
                                    Smile iD
                                  </EllipsisWithTooltip>
                                </div>
                                <div className="badge">{this.state.smileID}</div>
                              </a>
                            </li>
                          )}
                          {this.state.roomAssignment > 0 && this.state.isHouseKeepingPermission && (
                            <li>
                              <a
                                className="d-flex"
                                onClick={() => this.openOverView("RoomAssignments")}
                              >
                                <div className="mr-auto">
                                  <EllipsisWithTooltip placement="bottom">
                                    Room Assignments
                                  </EllipsisWithTooltip>
                                </div>
                                <div className="badge">
                                  {this.state.roomAssignment}
                                </div>
                              </a>
                            </li>
                          )}
                          {this.state.housekeppingApp > 0 && this.state.isHouseKeepingPermission &&
                            (
                              <li>
                                <a
                                  className="d-flex"
                                  onClick={() =>
                                    this.openOverView("HousekeepingApprovals")
                                  }
                                >
                                  <div className="mr-auto">
                                    <EllipsisWithTooltip placement="bottom">
                                      Housekeeping Approvals
                                    </EllipsisWithTooltip>
                                  </div>
                                  <div className="badge">
                                    {this.state.housekeppingApp}
                                  </div>
                                </a>
                              </li>
                            )}
                          {this.state.overrisk > 0 && this.state.isOtrPermission && (
                            <li>
                              <a
                                className="d-flex"
                                onClick={() => this.openOverView("otar")}
                              >
                                <div className="mr-auto">
                                  <EllipsisWithTooltip placement="bottom">
                                    Overtime at Risk
                                  </EllipsisWithTooltip>
                                </div>
                                <div className="badge">{this.state.overrisk}</div>
                              </a>
                            </li>
                          )}
                          {this.state.payrollExport > 0 &&
                            this.state.isPayrollPermission && (
                              <li>
                                <a
                                  className="d-flex"
                                  onClick={() => this.openOverView("PE")}
                                >
                                  <div className="mr-auto">
                                    <EllipsisWithTooltip placement="bottom">
                                      Payroll Export
                                    </EllipsisWithTooltip>
                                  </div>
                                  <div className="badge">
                                    {this.state.payrollExport}
                                  </div>
                                </a>
                              </li>
                            )}

                          {this.state.timeoffrequestCount > 0 &&
                            this.state.isToffPPermission && (
                              <li>
                                <a
                                  className="d-flex"
                                  onClick={() => this.openOverView("ToffR")}
                                >
                                  <div className="mr-auto">
                                    <EllipsisWithTooltip placement="bottom">
                                      Time off Requests
                                    </EllipsisWithTooltip>
                                  </div>
                                  <div className="badge">
                                    {this.state.timeoffrequestCount}
                                  </div>
                                </a>
                              </li>
                            )}
                          {this.state.HolidayCalendar > 0 && (
                            <li>
                              <a
                                className="d-flex"
                                onClick={() => this.openOverView("holidayCalender")}
                              >
                                <div className="mr-auto">
                                  <EllipsisWithTooltip placement="bottom">
                                    Holiday Calendar to Approve
                                  </EllipsisWithTooltip>
                                </div>
                                <div className="badge">{this.state.HolidayCalendar}</div>
                              </a>
                            </li>
                          )}
                          {this.state.breakExceptionCount > 0 && (
                            <li>
                              <a
                                className="d-flex"
                                onClick={() => this.openOverView("BreakException")}
                              >
                                <div className="mr-auto">
                                  <EllipsisWithTooltip placement="bottom">
                                    Break Exceptions
                                  </EllipsisWithTooltip>
                                </div>
                                <div className="badge">{this.state.breakExceptionCount}</div>
                              </a>
                            </li>
                          )}
                          <li className="static">
                            <a onClick={() => this.openOverView("OverView")}>
                              <EllipsisWithTooltip placement="bottom">
                                Labor Overview
                              </EllipsisWithTooltip>
                            </a>
                          </li>
                        </ul>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                )}
                {this.state.hasAccountingMenu && (
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button}
                        className={this.state.activeToggleAC ? "activeToggle" : ""}
                        onClick={this.toggleClassAC}
                        variant="link"
                        eventKey="1">
                        <div className="d-flex">
                          <div className="mr-auto">Accounting</div>
                          <div>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M6.29297 10.707L12 16.414L17.707 10.707L16.293 9.293L12 13.586L7.70697 9.293L6.29297 10.707Z"
                                fill="#84888C" />
                            </svg>
                          </div>
                        </div>

                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>
                        <ul style={{ cursor: "pointer" }}>
                          {this.state.accountingOverviewCounts.map((item: any, idx: number) => (
                            <li>
                              <a className="d-flex" onClick={() => this.openAccounting(item.name)}>
                                <div className="mr-auto">
                                  <EllipsisWithTooltip placement="bottom">
                                    {item.label}
                                  </EllipsisWithTooltip>
                                </div>
                                <div className="badge">{item.counts}</div>
                              </a>
                            </li>
                          ))
                          }
                          <li className="static">
                            <a onClick={() => this.openAccounting("Overview")}>
                              <EllipsisWithTooltip placement="bottom">
                                Accounting Overview
                              </EllipsisWithTooltip>
                            </a>
                          </li>
                        </ul>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                )}
              </Accordion>
            </div>
            {/* )} */}

          </>
        )}
      </li>
    );

    this.setState({ htmlMobileTaskItem: itemMobileShow });
  }




  hideSlideOut = (isSave) => {
    this.setState({
      isSlideOut: false,
      isTransferSlideOut: false,
      isTRSideOut: false
    })
  }

  hideTrSlideOut(hasEvent) {
    this.setState({
      isTransferSlideOut: false
    })
  }


  hideCCSlideOut = () => {
    this.setState({ isCCSlideout: false });
  }





  OpenInvoiceSlideOut = (e, newrow, rowIndex, rowType) => {
    if (!this.state.isBgBlue) {
      let row: any = {};
      row.hid = newrow.hotelid;
      row.etype = "Invoice";
      row.vendor = newrow.vendor;
      row.backHIDCode = newrow.lettercode;
      row.vid = newrow.vid;
      row.oprId = newrow.oprID;
      row.uniqueno = newrow.uniqueno;
      row.checkno = newrow.ckno;
      row.series = newrow.series;
      row.invPostFix = newrow.postFix;
      this.hideSlideOut(false);
      this.handlerowEvents(e, row, rowIndex, rowType);
    }
  }


  handlerowEvents = (e: any, row: any, rowIndex: any, rowType) => {




    let registerRequest1 = { ...this.state.registerRequest }
    let etype = '';
    etype = this.getEtype(row.etype, rowType);

    this.setState({
      selectedRow: row, pageType: etype,
      trUniqueNo: row.uniqueno, registerRequest: registerRequest1, vid: row.vid, oprId: row.oprId,
      isSlideOut: true, sildeoutHid: row.hid, sildeoutCode: row.backHIDCode
    })



  }


  getParamValueByParamName(paramName: string): any {
    let valuesArray = decodeURIComponent(this.props.history.location.search)?.replace('?', '')?.split('&');
    window.history.replaceState(null, '');
    return valuesArray;
    // const item = valuesArray?.find(r => r?.includes(paramName));
    // return     item ? window.atob(item?.split('=')[1] as any) : "";
  }

  getEtype(mtype, rowType) {
    let etype = '';
    etype = registersevice.getEtype(mtype, rowType);
    return etype;
  }


  IsInvoiceComplete = (uniqueNo, backHid, oprID, pageType, acctType, backPage, etype) => {
    let request: any = {};
    request.uniqueNo = uniqueNo;
    request.eType = etype.toLowerCase() === "invoices" ? "invoice" : etype;

    registersevice.IsInvoiceComplete(request)
      .then(async (result: any | null) => {
        if (result !== null) {
          if (result.length > 0) {
            let isDraftUpdate = "No";
            if (result[0].isComplete === 'Yes' && etype.toLowerCase() === 'Incompleteinvoice'.toLowerCase()) {
              uniqueNo = result[0].completeUniqueno;
              pageType = "Invoice";
            }
            else if (etype.toLowerCase() === 'DraftTransaction'.toLowerCase()) {
              uniqueNo = result[0].isComplete === 'Yes' ? result[0].completeUniqueno : uniqueNo;
              if (result[0].transType.toLowerCase() === 'misc') {
                pageType = 'Withdrawal'
              }else if (result[0].transType.toLowerCase() === 'miscdeposit') {
                pageType = 'Deposit'
              } else if (result[0].transType.toLowerCase() === 'payrollwithdrawal') {
                pageType = 'Withdrawal'
              } else if (result[0].transType.toLowerCase() === 'payrolldeposit') {
                pageType = 'Deposit'
              }else{
                pageType = result[0].transType;
              }
              isDraftUpdate = result[0].isComplete === 'Yes' ? "No" : "Yes";
            }

            if (etype.toLowerCase() === 'DraftTransaction'.toLowerCase()) {
              acctType = this.getAccttype(pageType);
            }

            if (pageType.toLowerCase() === 'CCWithdrawal'.toLowerCase()) {
              etype = "CreditCardWithdrawal";
            }
            if (pageType.toLowerCase() === 'CCDeposit'.toLowerCase()) {
              etype = "CreditCardDeposit";
            }



            backHid = result[0].backHID;
            let registerRequest1 = { ...this.state.registerRequest }
            registerRequest1.oprID = Number(oprID);
            registerRequest1.acctType = acctType;
            let selectedRow: any = { checkno: "", hid: backHid, groupID: 0, oprId: oprID, series: "", invPostFix: "", isDraftUpdate: isDraftUpdate };

            if (etype.toLowerCase() === 'CreditCardWithdrawal'.toLowerCase() || etype.toLowerCase() === 'CreditCardDeposit'.toLowerCase()) {
              let cctype = etype.toLowerCase() === 'CreditCardWithdrawal'.toLowerCase() ? "CCDEBIT" : "CCCREDIT";
              this.setState({
                ccSlideType: cctype, trUniqueNo: uniqueNo, registerRequest: registerRequest1, callFrom: "Draft",
                vid: "0", oprId: Number(oprID), isCCSlideout: true, sildeoutHid: backHid, sildeoutCode: "", selectedRow: selectedRow
              })

            }
            else {
              this.setState({
                trUniqueNo: uniqueNo, pageType: pageType, sildeoutHid: Number(backHid), callFrom: "Draft",
                registerRequest: {}, backPage: backPage, selectedRow: selectedRow
              }, () => {
                this.setState({ registerRequest: registerRequest1 }, () => {
                  this.setState({ isSlideOut: true });
                })
              });
            }
          }

        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  };

  getAccttype(mtype) {
    let accttype = '';
    accttype = registersevice.getAccttype(mtype);
    return accttype;
  }

 

  handleDeepLinking = () => {
    const valuesArray = this.getParamValueByParamName("");
    if (valuesArray.length > 1) {
      const { location, history } = this.props;
      //use the state via location.state
      //and replace the state via
      history.replace();
      const uniqueNo = valuesArray ? window.atob(valuesArray[0]?.split('=')[1] as any) : "";
      const etype = valuesArray ? window.atob(valuesArray[1]?.split('=')[1] as any) : "";
      // const backHid = valuesArray ? window.atob(valuesArray[2]?.split('=')[1] as any) : "";
      // const oprID = valuesArray ? window.atob(valuesArray[3]?.split('=')[1] as any) : "";
      // const backPage = valuesArray ? window.atob(valuesArray[4]?.split('=')[1] as any) : "";

      const backHid = -1
      const oprID = -1
      const backPage = -1
      let pageType = this.getEtype(etype, 'child');
      let acctType = this.getAccttype(etype);
      let registerRequest1 = { ...this.state.registerRequest }
      let transferRequest = { ...this.state.transferRequest }
      registerRequest1.oprID = Number(oprID);

      if (etype.toLowerCase() === 'Incompleteinvoice'.toLowerCase()
        || etype.toLowerCase() === 'invoice'.toLowerCase() || etype.toLowerCase() === 'invoices'.toLowerCase()) {
        this.IsInvoiceComplete(uniqueNo, backHid, oprID, pageType, acctType, backPage, etype);
      }
      else if (etype.toLowerCase() === 'DraftTransaction'.toLowerCase()) {
        this.IsInvoiceComplete(uniqueNo, backHid, oprID, pageType, acctType, backPage, etype);
      }
      else if (etype.toLowerCase() === 'CreditCardWithdrawal'.toLowerCase() || etype.toLowerCase() === 'CreditCardDeposit'.toLowerCase()) {
        let cctype = etype.toLowerCase() === 'CreditCardWithdrawal'.toLowerCase() ? "CCDEBIT" : "CCCREDIT";
        this.setState({
          selectedRow: {}, ccSlideType: cctype, trUniqueNo: uniqueNo, registerRequest: registerRequest1,
          vid: "0", oprId: Number(oprID), isCCSlideout: true, sildeoutHid: backHid, sildeoutCode: ""
        })

      }
      else {

        // isTRSideOut
        if (etype === "Journal") {
          let JERequest = { ...this.state.JERequest }
          JERequest.UniqueNo = uniqueNo;
          this.setState({
            JERequest, pageType: "Journal", sildeoutHid: Number(backHid),
            isTRSideOut: true
          })

        }
        else {
          if (this.getEtype(etype, 'child') === "Transfer") {
            transferRequest.acctType = acctType;
            transferRequest.hasRecouncil = false;
            this.setState({
              trUniqueNo: uniqueNo, registerRequest: registerRequest1, transferRequest,
              isTransferSlideOut: true, sildeoutHid: backHid, sildeoutCode: Number(backHid)
            })
          } else {
            let registerRequest1 = { ...this.state.registerRequest }
            registerRequest1.oprID = Number(oprID);
            registerRequest1.acctType = acctType;

            this.setState({
              trUniqueNo: uniqueNo, pageType: pageType, sildeoutHid: Number(backHid),
              registerRequest: {}, backPage: backPage
            }, () => {
              this.setState({ registerRequest: registerRequest1 }, () => {
                this.setState({ isSlideOut: true });
              })
            });
          }
        }
      }
    }
  }

  updatepip = () => {
    HeaderMenu.GetAlertpip("Accounting", "lbl-Accounting");
    let tempLink = document.getElementById("btnpip") as any;
    tempLink.click();
  }






  render() {

    const popUpState = localStorage?.getItem("PopUpState") ? JSON.parse(localStorage?.getItem("PopUpState") || "") : {};
    if (this.state.isLaborOpen) {
      // return <Redirect to="/labor-management/approval" />;
      return (
        <Redirect
          to={{
            pathname: "/labor-management/approval",
            state: {
              tabName: this.state.tabName,
            },
          }}
        />
      );
    }

    if (this.state.isAccountOpen) {
      return (
        <Redirect
          to={{
            pathname: "/Account-management/Overview",
            state: {
              tabName: this.state.tabName,
            },
          }}
        />
      );
    }

    if (this.state.isChangePwd) {
      return <Redirect to="/change-password" />;
    }

    //let html ='<li className =''>      </li>'

    return (
      <>
        {popUpState?.showIncompleteSlideOut ?
          <LockInUserDetailSlideOut
            isChangeEHIDAccess={popUpState?.isChangeEHIDAccess}
            isChangeUserRole={popUpState?.isChangeUserRole}
            isEditUserInfo={popUpState?.isChangeUserRole}
            isPageCreateNewUser={popUpState?.isPageCreateNewUser}
            userData={popUpState?.userData}
            isNewUserAdded={popUpState?.isNewUserAdded}
            closeSlideOut={() => { }}
            isDuplicatePhone={popUpState?.isDuplicatePhone}
            isDuplicateEmail={popUpState?.isDuplicateEmail}
          ></LockInUserDetailSlideOut>
          :

          <div className="home d-flex">
            {/* <ToastContainer
          autoClose={3000}
          containerId={"HomeCard"}
          enableMultiContainer
        /> */}

            {this.state.isSlideOut && (
              <>
                {(this.state.pageType === "Invoice" || this.state.pageType === "Incompleteinvoice") ?
                  <InvoiceEntrySlideout
                    hidValue={this.state.sildeoutHid}
                    hotelName={""}
                    uniqueNo={this.state.trUniqueNo}
                    pageType={this.state.pageType}
                    oprId={this.state.oprId}
                    vid={this.state.vid}
                    hideSlideOut={this.hideSlideOut.bind(this)}
                    OpenInvoiceSlideOut={this.OpenInvoiceSlideOut.bind(this)}
                    selectedRow={this.state.selectedRow}
                  />
                  :
                  <TransactionSlideout
                    accDetails={this.state.registerRequest}
                    hidValue={this.state.sildeoutHid}
                    hotelName={""}
                    uniqueNo={this.state.trUniqueNo}
                    pageType={this.state.pageType}
                    hideSlideOut={this.hideSlideOut.bind(this)}
                    OpenInvoiceSlideOut={this.OpenInvoiceSlideOut.bind(this)}
                    selectedRow={this.state.selectedRow}
                    callFrom={this.state.callFrom}
                  />
                }
              </>

            )}


            {this.state.isTransferSlideOut && (
              <TransferSlideout
                accDetails={this.state.registerRequest}
                hidValue={this.state.sildeoutHid}
                hotelName={""}
                uniqueNo={this.state.trUniqueNo}
                pageType={this.state.pageType}
                // valuesArray={this.state.valuesArray}
                transferRequest={this.state.transferRequest}
                hideTrSlideOut={this.hideTrSlideOut.bind(this)}
              />
            )}

            {this.state.isTRSideOut && (
              <JournalSlideOut
                JERequest={this.state.JERequest}
                hidValue={this.state.sildeoutHid}
                hotelName={""}
                hideSlideOut={this.hideSlideOut}
              />

            )}

            {this.state.isCCSlideout && (
              <CreditCardSlideout
                accDetails={this.state.registerRequest}
                hidValue={this.state.sildeoutHid}
                hotelName={this.state.hotelName}
                uniqueNo={this.state.trUniqueNo}
                oprId={this.state.oprId}
                vid={this.state.vid}
                pageType={this.state.ccSlideType}
                hideCCSlideOut={this.hideCCSlideOut.bind(this)}
                selectedRow={this.state.selectedRow}
                callFrom={this.state.callFrom}
              />
            )}


            <div className="left-sec mr-auto">
              <Container fluid className="body-sec">
                <div className="page-heading underline d-flex">
                  <div className="mr-auto align-items-center">
                    Home
                    <div className="tag-line">
                      Here’s what’s happening for you today.
                    </div>
                  </div>
                  <Button
                    as={Link}
                    to="/archive"
                    className="btn btn-primary d-flex align-items-center"
                  >
                    Archive
                  </Button>

                  {/* <Button className="btn btn-primary d-flex align-items-center"  onClick={this.updatepip}>
                update pip
              </Button> */}
                </div>
                <div className="body-list">
                  {/* {(this.state.htmlErrorItem.length > 0 || this.state.htmlWarningItem > 0 && */}
                  <div className="error-list-sec">
                    <ul>
                      {this.state.htmlErrorItem}
                      {this.state.htmlWarningItem}
                      {this.state.htmlMobileTaskItem}
                    </ul>
                  </div>
                  {/* )} */}
                  <div className="add-list">
                    <ul>{this.state.htmlItem}</ul>
                  </div>
                </div>
              </Container>
            </div>
            <div className="right-sec">
              {this.state.LaborManagementPanel && (
                <div className="sec-name">Tasks</div>
              )}
              {this.state.LaborManagementPanel && (
                <>

                  {/* {this.state.hasLaborMenu && ( */}
                  <div className="Module-list">
                    <Accordion defaultActiveKey="1">
                      {this.state.hasAccountingMenu && (
                        <Card>
                          <Card.Header>
                            <Accordion.Toggle as={Button}
                              className={this.state.activeToggleAC ? "activeToggle" : ""}
                              onClick={this.toggleClassAC}
                              variant="link"
                              eventKey="1">
                              <div className="d-flex">
                                <div className="mr-auto">Accounting</div>
                                <div>
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M6.29297 10.707L12 16.414L17.707 10.707L16.293 9.293L12 13.586L7.70697 9.293L6.29297 10.707Z"
                                      fill="#84888C" />
                                  </svg>
                                </div>
                              </div>

                            </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey="1">
                            <Card.Body>
                              <ul style={{ cursor: "pointer" }}>
                                {this.state.accountingOverviewCounts.map((item: any, idx: number) => (
                                  <li>
                                    <a className="d-flex" onClick={() => this.openAccounting(item.name)}>
                                      <div className="mr-auto">
                                        <EllipsisWithTooltip placement="bottom">
                                          {item.label}
                                        </EllipsisWithTooltip>
                                      </div>
                                      <div className="badge">{item.counts}</div>
                                    </a>
                                  </li>
                                ))
                                }
                                <li className="static">
                                  <a onClick={() => this.openAccounting("Overview")}>
                                    <EllipsisWithTooltip placement="bottom">
                                      Accounting Overview
                                    </EllipsisWithTooltip>
                                  </a>
                                </li>
                              </ul>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      )}
                      {this.state.hasLaborMenu && (
                        <Card>
                          <Card.Header>
                            <Accordion.Toggle
                              as={Button}
                              className={this.state.activeToggle ? "activeToggle" : ""}
                              onClick={this.toggleClass}
                              variant="link"
                              eventKey="0"
                            >
                              <div className="d-flex">
                                <div className="mr-auto">Labor Management</div>
                                <div>
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M6.29297 10.707L12 16.414L17.707 10.707L16.293 9.293L12 13.586L7.70697 9.293L6.29297 10.707Z"
                                      fill="#84888C"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey="0">
                            <Card.Body>
                              <ul style={{ cursor: "pointer" }}>
                                {this.state.dayApprove > 0 && !this.state.isApproveDayHidden && (
                                  <li>
                                    <a
                                      className="d-flex"
                                      onClick={() => this.openOverView("DailyApproveDay")}
                                    >
                                      <div className="mr-auto">
                                        <EllipsisWithTooltip placement="bottom">
                                          Daily Labor Approval
                                        </EllipsisWithTooltip>
                                      </div>
                                      <div className="badge">{this.state.dayApprove}</div>
                                    </a>
                                  </li>
                                )}
                                {this.state.gmDayApprove > 0 && !this.state.isGMApproveDayHidden && (
                                  <li>
                                    <a
                                      className="d-flex"
                                      onClick={() => this.openOverView("GMApproveDay")}
                                    >
                                      <div className="mr-auto">
                                        <EllipsisWithTooltip placement="bottom">
                                          Daily GM Approvals
                                        </EllipsisWithTooltip>
                                      </div>
                                      <div className="badge">{this.state.gmDayApprove}</div>
                                    </a>
                                  </li>
                                )}
                                {this.state.smileID > 0 && this.state.isSmileIDPermission && !this.state.isSmileIDHidden && (
                                  <li>
                                    <a
                                      className="d-flex"
                                      onClick={() => this.openOverView("SmileID")}
                                    >
                                      <div className="mr-auto">
                                        <EllipsisWithTooltip placement="bottom">
                                          Smile iD
                                        </EllipsisWithTooltip>
                                      </div>
                                      <div className="badge">{this.state.smileID}</div>
                                    </a>
                                  </li>
                                )}
                                {this.state.roomAssignment > 0 && this.state.isHouseKeepingPermission && !this.state.isRoomAssignmentsHidden && (
                                  <li>
                                    <a
                                      className="d-flex"
                                      onClick={() => this.openOverView("RoomAssignments")}
                                    >
                                      <div className="mr-auto">
                                        <EllipsisWithTooltip placement="bottom">
                                          Room Assignments
                                        </EllipsisWithTooltip>
                                      </div>
                                      <div className="badge">
                                        {this.state.roomAssignment}
                                      </div>
                                    </a>
                                  </li>
                                )}
                                {this.state.housekeppingApp > 0 && this.state.isHouseKeepingPermission && !this.state.isHousekeepingApprovalsHidden &&
                                  (
                                    <li>
                                      <a
                                        className="d-flex"
                                        onClick={() =>
                                          this.openOverView("HousekeepingApprovals")
                                        }
                                      >
                                        <div className="mr-auto">
                                          <EllipsisWithTooltip placement="bottom">
                                            Housekeeping Approvals
                                          </EllipsisWithTooltip>
                                        </div>
                                        <div className="badge">
                                          {this.state.housekeppingApp}
                                        </div>
                                      </a>
                                    </li>
                                  )}
                                {this.state.overrisk > 0 && this.state.isOtrPermission && !this.state.isotarHidden && (
                                  <li>
                                    <a
                                      className="d-flex"
                                      onClick={() => this.openOverView("otar")}
                                    >
                                      <div className="mr-auto">
                                        <EllipsisWithTooltip placement="bottom">
                                          Overtime at Risk
                                        </EllipsisWithTooltip>
                                      </div>
                                      <div className="badge">{this.state.overrisk}</div>
                                    </a>
                                  </li>
                                )}
                                {this.state.payrollExport > 0 &&
                                  this.state.isPayrollPermission && !this.state.isPEHidden && (
                                    <li>
                                      <a
                                        className="d-flex"
                                        onClick={() => this.openOverView("PE")}
                                      >
                                        <div className="mr-auto">
                                          <EllipsisWithTooltip placement="bottom">
                                            Payroll Export
                                          </EllipsisWithTooltip>
                                        </div>
                                        <div className="badge">
                                          {this.state.payrollExport}
                                        </div>
                                      </a>
                                    </li>
                                  )}

                                {this.state.timeoffrequestCount > 0 &&
                                  this.state.isToffPPermission && !this.state.isToffRHidden && (
                                    <li>
                                      <a
                                        className="d-flex"
                                        onClick={() => this.openOverView("ToffR")}
                                      >
                                        <div className="mr-auto">
                                          <EllipsisWithTooltip placement="bottom">
                                            Time off Requests
                                          </EllipsisWithTooltip>
                                        </div>
                                        <div className="badge">
                                          {this.state.timeoffrequestCount}
                                        </div>
                                      </a>
                                    </li>
                                  )}
                                {this.state.HolidayCalendar > 0 && !this.state.isHolidayCalendarHidden && (
                                  <li>
                                    <a
                                      className="d-flex"
                                      onClick={() => this.openOverView("holidayCalender")}
                                    >
                                      <div className="mr-auto">
                                        <EllipsisWithTooltip placement="bottom">
                                          Holiday Calendar to Approve
                                        </EllipsisWithTooltip>
                                      </div>
                                      <div className="badge">{this.state.HolidayCalendar}</div>
                                    </a>
                                  </li>
                                )}
                                {this.state.breakExceptionCount > 0 && !this.state.isBreakExpHidden && (
                                  <li>
                                    <a
                                      className="d-flex"
                                      onClick={() => this.openOverView("BreakException")}
                                    >
                                      <div className="mr-auto">
                                        <EllipsisWithTooltip placement="bottom">
                                          Break Exceptions
                                        </EllipsisWithTooltip>
                                      </div>
                                      <div className="badge">{this.state.breakExceptionCount}</div>
                                    </a>
                                  </li>
                                )}
                                <li className="static">
                                  <a onClick={() => this.openOverView("OverView")}>
                                    <EllipsisWithTooltip placement="bottom">
                                      Labor Overview
                                    </EllipsisWithTooltip>
                                  </a>
                                </li>
                              </ul>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      )}


                    </Accordion>
                  </div>
                  {/* )} */}

                </>
              )}
            </div>
          </div>
        }
      </>
    );
  }
}
