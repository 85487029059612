import Instense from "./Axios-config";

export class supportServices {

  public static SupportCustomerdetails = async (): Promise<any | null> => {
    let request: any = {};
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.TenantID = tenantID;
    const url = `/SupportCustomer/SupportCustomerdetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static sendSupportMail = async (request: any, files: any[]): Promise<any | null> => {
    if (files.length === 0) {
      const url = `/SupportCustomer/SupportCustomerSave`;
      return Instense.post(url, request).then((response) => {
        let result = response.data.result as any;
        return result;
      });
    }
    else {
      const url = `/SupportCustomer/SupportCustomerWithSaveFiles`;
      const formData: FormData = new FormData();
      formData.append('file', files[0], files[0].name);
      formData.append('formField', JSON.stringify(request));
      return Instense.post(url, formData).then((response) => {
        let result = response.data.result as any;
        return result;
      });
    }
  }

}