import React from "react";
import { Redirect } from "react-router-dom";
import {
  Container,
  Button,
  OverlayTrigger,
  Tooltip,
  Dropdown,
  FormLabel,
  Form,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import OutsideClickHandler from "react-outside-click-handler";
import filterFactory from "react-bootstrap-table2-filter";
import { FiChevronDown } from "react-icons/fi";
import $ from "jquery";
import { HidSelector } from "../../Common/Components/HidSelector";
import { SingleSearchDropdownList } from "../../Common/Components/SingleSearchDropdownList";
import { ReactPageLoader } from "../../Common/Components/ReactPageLoader";
import { TableCustomSearch } from "../../Common/Components/TableCustomSearch";
import { Facility } from "../../Common/Services/Facility";
import { EntityManagementService } from "../../Common/Services/EntityManagementService";
import "./Location.scss";
import "./facilities.scss";
import _ from "lodash";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { IGetAssetsListRequest } from "../../Common/Contracts/IFacilities";
import { FacilityMaintenance } from "../../Common/Services/FacilityMaintenance";
import {
  IFacilityMaintenanceListRequest,
  IFacilityMaintenanceScheduleResponse,
} from "../../Common/Contracts/IMaintenanceSchedule";
import { FacilityPreventativeMaintenance } from "../../Common/Services/FacilityPreventativeMaintenance";
import {
  IFacilityPreventativeMaintenanceRequest,
  IFacilityPreventativeMaintenanceResponse,
} from "../../Common/Contracts/IPreventativeMaintenance";
import moment from "moment";
import "./PreventativeMaintenance.scss";
import { PreventativeMaintenanceSlideOut } from "./PreventativeMaintenanceSlideOut";
import { Hotel } from "../../Common/Services/Hotel";
import { IHotelResponseDto } from "../../Common/Contracts/IHotel";
import { resolve, reject } from "q";
import { Utils } from "../../Common/Utilis";

const statusList = [
  { id: null, name: "All" },
  { id: "In Progress", name: "In Progress" },
  { id: "Overdue", name: "Overdue" },
  { id: "Upcoming", name: "Upcoming" },
];

export class PreventativeMaintenance extends React.Component<any, any> {
  private child: any;
  private childHID: any;
  private departmentSearch: any;
  private empTypeSearch: any;
  private tableTypeSearch: any;
  private scheduleUserViewRef: any;
  constructor(props: any) {
    super(props);
    this.scheduleUserViewRef = React.createRef();
    this.child = React.createRef();
    this.childHID = React.createRef();
    this.departmentSearch = React.createRef();
    this.empTypeSearch = React.createRef();
    this.tableTypeSearch = React.createRef();
    this.state = {
      pageLoader: false,
      isDataLoading: false,
      showAddAssetModal: false,
      hidValue: "Select",
      hotelName: "Select",
      tableData: [],
      frequencies: [],
      priorities: [],
      createEditPermission: false,
      searchText: "",
      status: "",
      frequency: "",
      priority: "",
      sortingOrder: "asc",
      sortingField: "status",
      activeStatus: "Active",
      showSlideout: false,
      parentRowData: [],
      commentUserType: "PreventiveCommentType",
      isPMCycleType:"",
      lastcycleId:"",
      lastroomId:"",
      ParmaworkOrdrValue:"",
    };
  }

  componentDidMount() {
    let hotelId = this.getURLParamsValues()?.hotelId;
    let status = this.getURLParamsValues()?.status;
    let source = this.getURLParamsValues()?.source;

    //debugger;
    let storage = JSON.parse(localStorage.getItem("hotelSelected")!);
    let storagehotelid = storage === null ? -1 : (storage.hotelID as any);
    let deepParam = localStorage.getItem("deepParam");
    let param = "";
    if (source == "overview") {
      hotelId = atob(hotelId);
      this.setState({status:status == "Open" ? "In Progress" : "Overdue",isRefresh:true},()=>{
        this.loadHotelsDetails(hotelId);
        this.setState({isRefresh:false})
      })
    } 
    else if (deepParam !== "") {
     
      try {
        param = atob(String(window.location.href.split("?")[1]));
        
        let paramValue =param.split('&')[0];
        let paramValue1 =param.split('&')[1];
        let paramValue2 = param.split('&')[2];
        let hid =paramValue.split('=')[1];
        let cycleId =paramValue1.split('=')[1];
        let roomId =paramValue2.split('=')[1];
      //  this.GetWorkOrdeStatus(hid,workOrdrid);
        this.loadHotelDetails(hid,cycleId,roomId,"redirectLink");   
        
      } catch {
        this.setState({ isLoader: false, mobileLoader: false });
        window.location.href = "/unauthorize-access";
      }
    }
    
    this.getPriority();
    this.getFrequency();

    this.CheckAddEditPermission();
  }

  loadHotelsDetails(hid) {
    Hotel.getUserHotelAccess("Facility Maintenance")
        .then(async (result: IHotelResponseDto[] | null) => {
            if (result != null) {

                const hotelDetail: any = result.find(
                    (r) => +r?.hotelID === +hid);

                localStorage.setItem("hotelSelected", JSON.stringify(hotelDetail));
                sessionStorage.setItem(
                    "hotelSelected",
                    JSON.stringify(hotelDetail)
                );

                this.childHID?.current?.updateChildState(hotelDetail)
                this.setState({ hidValue: hotelDetail.hotelID }, () => {
                    this.selectHotelDropDown(hotelDetail);
                });
                //  this.selectHotelDropDown(hotelDetail);
            }
        })
        .catch((error) => {
            reject();
        });
}


getParamValueByParamName(paramName: string): any {
  const valuesArray = decodeURIComponent(this.props.history.location.search)?.replace('?', '')?.split('&');
  const item = valuesArray?.find(r => r?.includes(paramName));
  return item?.split('=')[1];
}
getURLParamsValues = (): any => {
  const paramObject: any = {
      hotelId: this.getParamValueByParamName("hotelId"),
      status: this.getParamValueByParamName("status"),
      source: this.getParamValueByParamName("source"),
  }
  return paramObject;
}

  CheckAddEditPermission = () => {
    Facility.FacilityMaintenanceCheckEditStatusPermission().then((res) => {
      this.setState({ createEditPermission: res?.result });
    });
  };

  loadHotelDetails(hid:any,cycleId:any,roomId:any,type:any) {
   // debugger;
     Hotel
       .getUserHotelAccess("Facility Maintenance")
       .then(async (result: IHotelResponseDto[] | null) => {
     //    debugger;
         if (result != null) {
           this.setState({ hidList: result});
           if (hid) {
             const hotelDetail = result.find(
               (r) => +r?.hotelID === +hid
             );
             localStorage.setItem("hotelSelected", JSON.stringify(hotelDetail));
             sessionStorage.setItem(
               "hotelSelected",
               JSON.stringify(hotelDetail)
             );
             this.setState({  lastcycleId:cycleId,lastroomId:roomId,
               isPMCycleType:  "ParamcycleIdType",ParmaworkOrdrValue:"Parmavalue"});
           
            
            // this.selectHotelDropDown(hotelDetail,"ParmaworkOrdridType",workOrdrid,type);
           }
        
         }
        //  resolve();
       })
       .catch((error) => {
        //  reject();
       });
   }

  selectHotelDropDown = (hotelDetails: any) => {
    this.setState({ pageLoader: true });

    this.setState(
      {
        hotelName: hotelDetails.lettercode,
        hidValue: hotelDetails.hotelID,
      },
      () => {
        this.getAllPreventativeMaintenance();
      }
    );
  };

  getAllPreventativeMaintenance = () => {
    const { searchText, status, frequency, priority, hidValue, activeStatus } =
      this.state;
    const request: IFacilityPreventativeMaintenanceRequest = {
      query: searchText,
      status: status == "Status" ? "" :status,
      frequency,
      priority,
      MaintenanceStatus: activeStatus == "Inactive" ? "" : activeStatus,
      Hid: hidValue,
    };

    this.setState({ isDataLoading: true });

    FacilityPreventativeMaintenance.GetAllPreventativeMaintenance(request)
      .then((tableData: IFacilityPreventativeMaintenanceResponse[] | null) => {
        let _tableData = _.orderBy(tableData, ["name"], ["asc", "asc"]);
        _tableData = _tableData.map((x) => ({
          ...x,
          noOfLocations:
            x.status.toLowerCase() == "upcoming" ? "" : x.noOfLocations,
        }));
        this.setState({
          isDataLoading: false,
          tableData: _.orderBy(
            _tableData,
            [this.state.sortingField],
            ["asc", "asc"]
          ),
        });
       // debugger;
        if (this.state.isPMCycleType == "ParamcycleIdType") {
          this.handleShowSlideout("", "row", "", "ParamcycleIdType", Number(this.state.lastcycleId));
        }
        
      })
      .catch((err) => {
        this.setState({ isDataLoading: false });
      });
  };

  startEarly = (id) => {
    FacilityPreventativeMaintenance.StartEarly({ CycleId: id })
      .then((result) => {
        if (result.success) {
          toast.success(result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          Utils.toastError(result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        this.getAllPreventativeMaintenance();
      })
      .catch((err) => {
        Utils.toastError(`Server Error, ${err}`, {});
      });
  };

  getFrequency = () => {
    FacilityMaintenance.GetFrequency().then((frequencies: any[] | null) => {
      let _frequencies = _.cloneDeep(frequencies);
      if (_frequencies?.length > 0) {
        _frequencies = _frequencies.map((item) => {
          return { id: item, name: item };
        });
      }
      this.setState({
        frequencies: [{ id: "", name: "All" }, ..._frequencies],
      });
    });
  };

  getPriority = () => {
    FacilityMaintenance.GetPriority().then((priorities: any[] | null) => {
      let _priorities = _.cloneDeep(priorities);
      if (_priorities?.length > 0) {
        _priorities = _priorities.map((item) => {
          return { id: item, name: item };
        });
      }
      this.setState({ priorities: [{ id: "", name: "All" }, ..._priorities] });
    });
  };

  onSearchChange = (searchText: string) => {
    this.setState({ searchText: searchText?.toString()?.trimStart() }, () => {
      // if (searchText?.length > 1 || !searchText) {
      // this.getAllPreventativeMaintenance();
      var debounce = _.debounce(this.getAllPreventativeMaintenance, 1000);
      debounce();
      // }
    });
  };

  onDDlFiltersChange = (controlID: any, id: any) => {
    let { frequency, status, priority } = this.state;
    switch (controlID) {
      case "ddlFrequency":
        frequency = id || "";
        break;
      case "ddlStatus":
        status = id || "";
        break;
      case "ddlPriority":
        priority = id || "";
        break;
      default:
        break;
    }

    this.setState({ frequency, status, priority }, () => {
      this.getAllPreventativeMaintenance();
    });
  };

  handleSortByColumn = (field) => {
    this.setState(
      {
        sortingField: field,
        sortingOrder: this.state.sortingOrder === "asc" ? "desc" : "asc",
      },
      () => {
        this.sortTableData(this.state.tableData, this.state.sortingField);
      }
    );
  };

  sortTableData = (data: any, fieldName) => {
    if (this.state.sortingOrder === "asc") {
      data = _.orderBy(data, [fieldName], ["asc", "asc"]);
    } else if (this.state.sortingOrder === "desc") {
      data = _.orderBy(data, [fieldName], ["desc", "asc"]);
    }
    this.setState({ tableData: data });
  };

  sortImage = (order) => {
    if (!order)
      return (
        <span className="ascending">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
              fill="#1D1E20"
            />
          </svg>
        </span>
      );
    else if (order === "asc")
      return (
        <span className="ascending">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
              fill="#1D1E20"
            />
          </svg>
        </span>
      );
    else if (order === "desc")
      return (
        <span className="descending">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
              fill="#1D1E20"
            />
          </svg>
        </span>
      );

    return null;
  };

  onActiveStatusChange = (val) => {
    const newStatus =
      this.state.activeStatus === "Active" ? "Inactive" : "Active";

    this.setState({ activeStatus: newStatus }, () => {
      this.getAllPreventativeMaintenance();
    });
  };

  rowClasses = (row: any, rowIndex: any) => {
    if (row.status === "Upcoming") {
      return "row-grayout";
    }
  };

  handleShowSlideout = (
    e: any,
    rowData: any,
    rowIndex: any,
    type: any,
    lastcycleId: any,
   ) => {
      if(type === "ParamcycleIdType"){
        let data = this.state.tableData;
        let row1 = data.filter((item) => item.cycleId === lastcycleId);    
        if (row1.length > 0) {
          row1[0].seletedColor = "yes";
          this.setState({ tableData: data, showSlideout: false }, () => {
            this.setState({ lastcycleId: "", lastroomId:"",showSlideout: true, parentRowData: row1[0] });
          });
        }
      }
      if (rowData.hasStarted) {
      
      this.setState({ parentRowData: rowData }, () => {
        this.setState({
          showSlideout: true,
        });
      });
    // }
    }
  };

  handleHideSlideout = () => {
    this.setState({ showSlideout: false }, () => {
      this.getAllPreventativeMaintenance();
    });
  };

  render() {
    const {
      frequencies,
      priorities,
      searchText,
      status,
      frequency,
      activeStatus,
      isDataLoading,
      priority,
      createEditPermission,
    } = this.state;

    const tableColumnEvent = {
      onClick: (e, column, columnIndex, row, rowIndex) => {
       //debugger;
        // if (this.state.isPMCycleType == "ParamcycleIdType") {
        //   this.handleShowSlideout("", "row", "", "ParamcycleIdType", Number(this.state.lastcycleId));
        // }
        // else{
          this.handleShowSlideout("",row,"","","");
        // }
      },
    };

    const columns = [
      {
        dataField: "name",
        text: "Name",
        events: tableColumnEvent,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <>
              <div className="maintenance-name">
                <EllipsisWithTooltip placement="bottom">
                  {row.name}
                </EllipsisWithTooltip>
                {row.maintenanceStatus == "Inactive" && (
                  <span className="badge color-grey-beta">
                    {row.maintenanceStatus}
                  </span>
                )}
              </div>
            </>
          );
        },
      },
      {
        dataField: "status",
        text: "Status",
        events: tableColumnEvent,
        headerFormatter: (column) => {
          return (
            <div
              className={`d-inline-block ${
                this.state.sortingField === "status" ? "active" : ""
              }`}
              onClick={() => this.handleSortByColumn("status")}
            >
              {column.text}
              {this.sortImage(this.state.sortingOrder)}
            </div>
          );
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <>
              <EllipsisWithTooltip placement="bottom">
                <div
                  className={`badge active ${
                    row.status.toLowerCase() == "in progress"
                      ? "progress"
                      : row.status.toLowerCase() == "overdue"
                      ? "overdue"
                      : row.status.toLowerCase() == "complete"
                      ? "completed"
                      : ""
                  }`}
                >
                  {row.status}{" "}
                </div>{" "}
              </EllipsisWithTooltip>
            </>
          );
        },
      },

      {
        dataField: "frequency",
        text: "Frequency",
        events: tableColumnEvent,
        headerFormatter: (column) => {
          return (
            <div
              className={`d-inline-block ${
                this.state.sortingField === "frequency" ? "active" : ""
              }`}
              onClick={() => this.handleSortByColumn("frequency")}
            >
              {column.text}
              {this.sortImage(this.state.sortingOrder)}
            </div>
          );
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <>
              <EllipsisWithTooltip placement="bottom">
                <div className="">{row.frequency} </div>{" "}
              </EllipsisWithTooltip>
            </>
          );
        },
      },
      {
        dataField: "noOfLocations",
        text: "Locations",
        events: tableColumnEvent,
        headerFormatter: (column) => {
          return (
            <div
              className={`d-inline-block ${
                this.state.sortingField === "noOfLocations" ? "active" : ""
              }`}
              onClick={() => this.handleSortByColumn("noOfLocations")}
            >
              {column.text}
              {this.sortImage(this.state.sortingOrder)}
            </div>
          );
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <>
              {row.noOfLocations == 0 ? (
                <></>
              ) : (
                <EllipsisWithTooltip placement="bottom">
                  <div className="">{row.noOfLocations} </div>{" "}
                </EllipsisWithTooltip>
              )}
            </>
          );
        },
      },

      {
        dataField: "priority",
        text: "Priority",
        events: tableColumnEvent,
        headerFormatter: (column) => {
          return (
            <div
              className={`d-inline-block ${
                this.state.sortingField === "priority" ? "active" : ""
              }`}
              onClick={() => this.handleSortByColumn("priority")}
            >
              {column.text}
              {this.sortImage(this.state.sortingOrder)}
            </div>
          );
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <>
              {" "}
              {row.priority ? (
                <EllipsisWithTooltip placement="bottom">
                  <div className="priority-status">
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill= {row.priority ==="Low" ? "blue"  :  (row.priority ==="High" ? "red" : "orange") } className={`bi bi-dot `} viewBox="0 0 16 16">
              <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
            </svg> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      className={`bi bi-dot `}
                    >
                      <circle
                        cx="4"
                        cy="4"
                        r="4"
                        fill={
                          row.priority === "Low"
                            ? "#15a4fa"
                            : row.priority === "High"
                            ? "#d3412e"
                            : "#fca147"
                        }
                      />
                    </svg>
                    {row.priority}{" "}
                  </div>
                </EllipsisWithTooltip>
              ) : (
                <></>
              )}
            </>
          );
        },
      },

      {
        dataField: "startDate",
        text: "Start Date",
        events: tableColumnEvent,
        headerFormatter: (column) => {
          return (
            <div
              className={`d-inline-block ${
                this.state.sortingField === "startDate" ? "active" : ""
              }`}
              onClick={() => this.handleSortByColumn("startDate")}
            >
              {column.text}
              {this.sortImage(this.state.sortingOrder)}
            </div>
          );
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <>
              <EllipsisWithTooltip placement="bottom">
                <div className="">
                  {moment(row.startDate).format("MM/DD/YY")}{" "}
                </div>{" "}
              </EllipsisWithTooltip>
            </>
          );
        },
      },

      {
        dataField: "dueDate",
        text: "Due Date",
        events: tableColumnEvent,
        headerFormatter: (column) => {
          return (
            <div
              className={`d-inline-block ${
                this.state.sortingField === "dueDate" ? "active" : ""
              }`}
              onClick={() => this.handleSortByColumn("dueDate")}
            >
              {column.text}
              {this.sortImage(this.state.sortingOrder)}
            </div>
          );
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <>
              <EllipsisWithTooltip placement="bottom">
                <div className="">
                  {moment(row.dueDate).format("MM/DD/YY")}{" "}
                </div>{" "}
              </EllipsisWithTooltip>
            </>
          );
        },
      },

      {
        dataField: "",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <>
              <Dropdown className="more-action ml-3">
                <DropdownToggle
                  className={`btn-outline-primary btn btn-primary more ${
                    row.status === "In Progress" ? "disabled" : ""
                  }`}
                  id="dropdown-more"
                  disabled={
                    row.canStart !== 1 ||
                    !this.state?.createEditPermission || row.status === "In Progress"
                  }
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                  </svg>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => this.startEarly(row.cycleId)}>
                    Start Early
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </>
          );
        },
      },
    ];

    const tableRowEvents = {
      onClick: (e, row, rowIndex) => {
        // this.handleShowSlideout(row);
      },
    };

    return (
      <>
        <div className="performance-position performance timesheet location-page-wrapper preventative-maintenance-page">
          <Container fluid className="body-sec">
            <div className="page-heading underline d-flex">
              <HidSelector
                onClickEvent={this.selectHotelDropDown}
                ref={this.childHID}
                modulename={"Facility Maintenance"}
              />
              <div className="mr-auto d-flex align-items-center">
                Preventative Maintenance
              </div>

              <Dropdown className="more-action ml-3">
                <DropdownToggle
                  disabled={!createEditPermission}
                  className="btn-outline-primary btn btn-primary more"
                  id="dropdown-more"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                  </svg>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem></DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
            {/* {this.state.pageLoader ? (
              <ReactPageLoader useas={"timesheetMain"} />
            ) : ( */}
            {this.state.hidValue !== "Select" && (
              <div className="main-Page">
                <div className="bdy-sec">
                  <ToolkitProvider
                    keyField="userUniqueno"
                    data={this.state.tableData}
                    columns={columns}
                    hover
                  >
                    {(props: {
                      searchProps: JSX.IntrinsicAttributes;
                      baseProps: JSX.IntrinsicAttributes;
                    }) => (
                      <div>
                        <div className="search-header d-flex">
                          <div className="schedule-main">
                            <div className="schedule-department-view-table">
                              <div className="departmentViewNavigation">
                                <div className="navigation-menus d-flex">
                                  <div className="schedule-table-search">
                                    <input
                                      type="text"
                                      onChange={(e) =>
                                        this.onSearchChange(e.target.value)
                                      }
                                      value={searchText}
                                      className="search-box form-control"
                                      placeholder="Search..."
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="room-type-dropdown">
                            {statusList?.length > 0 && (
                              <SingleSearchDropdownList
                                id={"ddlStatus"}
                                isRefresh={this.state.isRefresh}
                                itemList={statusList}
                                handleSelectedItem={this.onDDlFiltersChange.bind(
                                  this,
                                  "ddlStatus"
                                )}
                                defaultItem={status}
                                defaultText={"Search Status"}
                                defaultName={status == ""? "Status" :status}
                                controlID="2"
                              />
                            )}
                          </div>{" "}
                          <div className="room-type-dropdown">
                            {frequencies?.length > 0 && (
                              <SingleSearchDropdownList
                                id={"ddlFrequency"}
                                isRefresh={this.state.isRefresh}
                                itemList={frequencies}
                                handleSelectedItem={this.onDDlFiltersChange.bind(
                                  this,
                                  "ddlFrequency"
                                )}
                                defaultItem={frequency}
                                defaultText={"Search Frequency"}
                                defaultName={"Frequency"}
                                controlID="3"
                              />
                            )}
                          </div>
                          <div className="room-type-dropdown">
                            {priorities.length > 0 && (
                              <SingleSearchDropdownList
                                id={"ddlPriority"}
                                isRefresh={this.state.isRefresh}
                                itemList={priorities}
                                handleSelectedItem={this.onDDlFiltersChange.bind(
                                  this,
                                  "ddlPriority"
                                )}
                                defaultItem={priority}
                                defaultText={"Search Priority"}
                                defaultName={"Priority"}
                                controlID="1"
                              />
                            )}
                          </div>
                          {/* <div className="ml-auto mt-2 mt-xl-0">
                            <div className="d-flex align-items-center justify-content-end toggle">
                              <FormLabel className="mb-0">
                                Show Inactive
                              </FormLabel>
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                label=""
                                checked={activeStatus === "Inactive"}
                                // disabled={isDataLoading}
                                onChange={(event: any) =>
                                  this.onActiveStatusChange(event.target)
                                }
                              />
                            </div>
                          </div> */}
                        </div>

                        <div className="hasCursorPointer position-relative hide-header-scrollbar">
                          <div className="block-bar"></div>
                          <BootstrapTable
                            id={"preventative-maintenance-tbl"}
                            {...props.baseProps}
                            keyField="cycleId"
                            rowClasses={this.rowClasses}
                            hover
                            // rowEvents={tableRowEvents}
                          />
                        </div>
                      </div>
                    )}
                  </ToolkitProvider>
                </div>
              </div>
            )}
            {/* )} */}

            {this.state.showSlideout && (
              <PreventativeMaintenanceSlideOut
                parentRowData={this.state.parentRowData}
                parentState={this.state}
                handleHideSlideout={this.handleHideSlideout}
                createEditPermission={createEditPermission}
              />
            )}
          </Container>
        </div>
      </>
    );
  }
}
