import React, {
  // useRef,
  useState, useRef,
  //  useEffect
} from "react";
import { ModuleReports } from "./../../Common/Services/ModuleReports";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

const EmployeeAutoSelector: React.FunctionComponent<any> = (props: any) => {
  const typeahead: any = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const getAutoCompleteEmployeeData = async (prefix) => {
    try {
      setIsLoading(true);
      const response = (await ModuleReports.GetAutoCompleteEmployeeData(
        prefix
      )) as never[];
      setOptions(response);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleTextChange = (value): void => {
    setSelectedValue(value);
    props?.onEmployeeChange(value.join());

  };
  
  const filterBy = () => true;
 if(!props?.defaultValue && selectedValue?.length > 0) {
    typeahead?.current?.clear()        
 }

  return (
    
      !props?.defaultValue ? 
      (<AsyncTypeahead
      filterBy={filterBy}
      ref={typeahead}
      id="employee-selector"
      isLoading={isLoading}
      minLength={1}
      onChange={handleTextChange}
      onSearch={getAutoCompleteEmployeeData}
      options={options}
    />) : 
    (<AsyncTypeahead
    filterBy={filterBy}
    ref={typeahead}
    defaultSelected={[(!props?.defaultValue ? "" : props?.defaultValue )]}
    id="employee-selector"
    isLoading={isLoading}
    minLength={1}
    onChange={handleTextChange}
    onSearch={getAutoCompleteEmployeeData}
    options={options}
  />)
  )
 
  
  
};

export default EmployeeAutoSelector;
