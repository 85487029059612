/* eslint-disable array-callback-return */
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {
    // Link, 
    Redirect
} from "react-router-dom";
import { Container, Dropdown, Form, Row, Col } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PageLoader from "../../../../Common/Components/PageLoader";
import chevronRight from "../../../../Common/Assets/Images/icons/bx-chevron-right.svg";
import profile from "../../../../Common/Assets/Images/profile1.png";
import notesIcon from "../../../../Common/Assets/Images/icons/notes-icon.png";
import PerformanceScheduleDetails from "./PerformanceScheduleDetails"
import PerformanceActualDetails from "./PerformanceActualDetails"
import { LaborPerformance } from "../../../../Common/Services/LaborPerfomance";
import DatePicker from "react-datepicker";
import OutsideClickHandler from "react-outside-click-handler";
import { resolve, reject } from "q";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { SingleSelectSearchWithImage } from "../../../../Common/Components/SingleSelectSearchWithImage";
import filterFactory from 'react-bootstrap-table2-filter';
import { confirmAlert } from "react-confirm-alert";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import ForecastLock from "../../../../Common/Assets/Images/icons/forecast-lock.svg";
import { ReactPageLoader } from "../../../../Common/Components/ReactPageLoader";
import { Utils } from "../../../../Common/Utilis";
const { SearchBar } = Search;
export class PerformancePositionDetail extends React.Component<any, any> {
    private singleSelect: any;
    private firstSingleSelect: any;
    private childActual: any;
    private childSchedule: any;
    constructor(props: any) {
        super(props);
        this.singleSelect = React.createRef();
        this.firstSingleSelect = React.createRef();
        this.childActual = React.createRef();
        this.childSchedule = React.createRef();
        this.state = {
            hotelID: this.props.data.hotelID,
            hotelName: this.props.data.letterCode,
            positionID: this.props.data.positionID,
            positionName: this.props.data.positionName,
            departmentID: this.props.data.deptID,
            parentName: this.props.data.parentName,
            date: this.props.data.date,
            exportedDate: "",
            payPeriodDates: [],
            tableExpended: [],
            userRow: [],
            alluserDetails: [],
            UserDetails: [],
            UserDetailsWithPTO: [],
            UserDetailsWithOutPTO: [],
            isExpand: false,
            loaderTrue: false,
            selectedColumn: "Variances",
            columnName: [{ id: "Variances", value: "Variances" }, { id: "Totals", value: "Totals" }],
            totalScheduleMinuts: 0,
            field: "variance",
            order: "asc",
            actualvariance: 0,
            isPTOdata: false,
            isOption: false,
            employeelist: [],
            allEmployeelist: [],
            minDate: JSON.parse(localStorage.storage).currentUtcTime !== "" ? new Date(
                JSON.parse(localStorage.storage).currentUtcTime
              ):new Date(),
            maxDate: JSON.parse(localStorage.storage).currentUtcTime !== "" ? new Date(
                JSON.parse(localStorage.storage).currentUtcTime
              ):new Date(),
            isDateapprove: 'Unapprove',
            isDateChange: false,
            btnEventName: '',
            empSmileID: [],
            pageloader: false,
            isedit: "Yes",
            managesmileid: "Yes",
            isRedirect: false,
            lockStatus: "",
        };
        this.handleSelectDate = this.handleSelectDate.bind(this);
        this.decimalHoursToString = this.decimalHoursToString.bind(this);
        this.minutestoHoursMinuts = this.minutestoHoursMinuts.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleActualFilter = this.handleActualFilter.bind(this);
        this.handleShowPTOemp = this.handleShowPTOemp.bind(this);
        this.closeSlideOut = this.closeSlideOut.bind(this);
        this.handleSelectedItem = this.handleSelectedItem.bind(this);

    }

    closeSlideOut() {
        if (this.childActual.current === null) {
            this.props.hideSlideOut();
        }
        else {
            let isDataChanged = this.childActual.current.isDateChanged();
            if (isDataChanged) {
                this.setState({ btnEventName: 'CloseSlideOut' })
                this.isValidateModelPoupTab([]);
            }
            else {

                this.props.hideSlideOut();
            }
        }
    }

    closeSlideOutWindow() {
        ;
        this.props.hideSlideOut();
    }

    componentDidMount() {
        this.getUserDetails(this.state.hotelID, this.state.positionID, this.state.date);
        this.getCalendarMaxMinDate();
        this.getPerformanceSlideOutPermission();
    }

    getPerformanceSlideOutPermission() {
        let request = {} as any;
        request.hotelID = this.state.hotelID;
        request.date = this.state.date;
        this.setState({ loaderTrue: true })
        LaborPerformance.PerformanceSlideOutPermission(request).then(async (result: any | null) => {
            if (result != null) {
                this.setState({
                    isedit: result[0].isEdit,
                    isDateapprove: result[0].isDateApprove,
                    lockStatus: result[0].lockStatus,
                    payPeriodDates: [result[0].payStartDate, result[0].payEndDate],
                    exportedDate: result[0].exportedDateMMMMDDYYYY,
                })
            }
            this.setState({ loaderTrue: false })
            resolve();
        })
            .catch((error) => {
                this.setState({ loaderTrue: false })
                reject();
            });
    }

    resetList() {
        this.getUserDetails(this.state.hotelID, this.state.positionID, this.state.date);
        this.getCalendarMaxMinDate();
    }

    getCalendarMaxMinDate = () => {
        this.setState({ loaderTrue: true })
        this.setState({ minDate: "", maxDate: "" })
        LaborPerformance.getCalendarMaxMinDate(this.state.hotelID).then(async (result: any | null) => {
            if (result != null) {
                this.setState({ minDate: new Date(result.mindate), maxDate: new Date(result.foreCastdate) })
            }
            this.setState({ loaderTrue: false })
            resolve();
        })
            .catch((error) => {
                this.setState({ loaderTrue: false })
                reject();
            });

    }

    handleSelectDate = (date: any) => {
        if (this.childActual.current === null) {
            this.handleselectedDateChange(date)
        }
        else {
            let isDataChanged = this.childActual.current.isDateChanged();
            if (isDataChanged === false) {
                this.handleselectedDateChange(date)
            }
            else {
                this.setState({ btnEventName: 'ChangeDate' })
                this.isValidateModelPoupTab(date);
            }
        }
    }

    handleChangeDate(date: any) {
        let today = new Date(date);
        let netDate = today.getMonth() + 1 + '/' + today.getDate() + '/' + today.getFullYear();
        this.setState({ date: netDate, isOption: false, isPTOdata: false })
        this.getUserDetails(this.state.hotelID, this.state.positionID, netDate);
        if (this.childActual.current != null) {
            this.childActual.current.resetChangeState();
        }
        if (this.childSchedule.current != null) {
            this.childSchedule.current.resetChangeState();
        }
    }

    handleselectedDateChange(date: any) {
        let today = new Date(date);
        let netDate = today.getMonth() + 1 + '/' + today.getDate() + '/' + today.getFullYear();
        this.setState({ date: netDate, isOption: false, isPTOdata: false })
        this.getUserDetails(this.state.hotelID, this.state.positionID, netDate);
    }

    isChangeActualData = (data: any) => {
        this.setState({ isDataChange: false });
        switch (this.state.btnEventName) {
            case 'ChangeDate':
                return this.handleChangeDate(data);
            case 'CloseSlideOut':
                return this.closeSlideOutWindow();

        }
    };

    isValidateModelPoupTab(date: any) {
        confirmAlert({
            title: "Unsaved confirmation",
            message:
                "You have unsaved changes on this page. Do you want to leave this page and discard your changes?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => this.isChangeActualData(date),
                },
                {
                    label: "No",
                    onClick: () => reject(),
                },
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
        });
    }

    decimalHoursToString(numHours: number, numMinutes: number) {
        return `${numHours}:${numMinutes < 10 ? "0" : ""}${numMinutes}`;
    }

    minutestoHoursMinuts(totalMinutes: number) {
        if (totalMinutes >= 0) {
            return `${""}${(Math.floor(totalMinutes / 60))}:${(Number(totalMinutes % 60)) < 10 ? "0" : ""}${(Number(totalMinutes % 60))}`;
        } else {
            let hours = String(Math.floor((totalMinutes * -1) / 60))
            let minutes = String(Math.floor((totalMinutes * -1) % 60))
            return `${hours}:${minutes.length === 1 ? "0" : ""}${minutes}`;
        }
    }

    getUserDetails(hotelID: Number, positionID: Number, date: any) {
        this.setState({ pageloader: false })
        let DatawithoutPTO: any[] = [];
        let filterResult: any[] = [];

        LaborPerformance.GetLaborEmpSmileIDByPosition(hotelID, positionID, date).then(async (result: any[] | null) => {
            if (result != null) {
                this.setState({ empSmileID: result });
            } else {
                this.setState({ empSmileID: [] });
            }
            resolve();

            LaborPerformance.laborPerformancePositionDetail(hotelID, positionID, date).then(async (result: any[] | null) => {
                if (result != null) {
                    if (result.length > 0) {
                        if (this.state.parentName === "autoPunchOut") {
                            filterResult = result.filter((itemList: { userName: string; }) => itemList.userName === this.props.data.userName);
                        }
                        else {
                            filterResult = result;
                        }
                        ;
                        DatawithoutPTO = filterResult.filter((itemList: { timeOff: number; timeOffRow: number; }) => itemList.timeOff !== 1 && itemList.timeOffRow !== 1);
                        this.setState({ actualvariance: result[0].actualvariance, isDateapprove: result[0].isDateapprove, isedit: result[0].isedit, managesmileid: result[0].managesmileid });
                    }
                    this.setState({ alluserDetails: [], UserDetails: [] })
                    this.setState({ alluserDetails: filterResult, UserDetails: DatawithoutPTO }
                        , () => {
                            this.getAllEmployee(this.state.hotelID, this.state.positionID, this.state.date, DatawithoutPTO);
                        });
                    this.getFilterData(DatawithoutPTO);

                    this.setState({ pageloader: true })
                }
                this.setState({ loaderTrue: false })
                resolve();
                if (this.state.parentName === "autoPunchOut") {
                    this.handleOnExpand(DatawithoutPTO[0], true, "", "");
                }
            })
                .catch((error: any) => {
                    this.setState({ loaderTrue: false })
                    reject();
                });
        })
            .catch((error: any) => {
                this.setState({ loaderTrue: false })
                reject();
            });


    }

    getAllEmployee(hotelID: Number, positionID: Number, date: any, DatawithoutPTO: any[]) {
        this.setState({ loaderTrue: true })
        LaborPerformance.laborPerformanceEmployee(hotelID, positionID, date).then(async (result: any[] | null) => {
            if (result != null) {
                this.setState({ allEmployeelist: result })
                this.compareArray(result, DatawithoutPTO);
            }
            else {
                this.setState({ loaderTrue: false })
            }
            resolve();
        })
            .catch((error: any) => {
                this.setState({ loaderTrue: false })
                reject();
            });
    }

    compareArray(array1: any[], array2: any[]) {
        let isExist = false;
        this.setState({ loaderTrue: true })
        let newArray: any[] = [];
        array1.forEach(function (items) {
            isExist = false;
            array2.forEach(function (item) {
                if (items.employee === item.userName) {
                    isExist = true;
                }
            });
            if (!isExist) {
                newArray.push(items)
            }
        });
        this.setState({ employeelist: newArray, loaderTrue: false }, () => {
            if (this.singleSelect.current !== null) {
                this.singleSelect.current.updateEmpData(newArray);
                this.firstSingleSelect.current.updateEmpData(newArray);
            }
        })
    }

    getFilterData(result: any[]) {
        this.setState({ loaderTrue: true })
        let total = 0;
        result.forEach((element: any, index: number) => {
            if (element.scheduleTotalhours !== undefined) {
                if (element.scheduleTotalhours !== null) {
                    if (element.scheduleTotalhours.trim().length > 0) {
                        if (element.scheduleTotalhours.includes(":")) {
                            const dataArray = element.scheduleTotalhours.split(":");
                            let HH = Number(dataArray[0]);
                            let MM = Number(dataArray[1]);
                            total = total + (HH * 60 + MM)
                        }
                    }
                }
            }
        })
        this.setState({ totalScheduleMinuts: Number(total) })
        this.setState({ loaderTrue: false })
    }

    handleFilterChange = (event: any, value: string) => {
        this.setState(() => ({
            tableExpended: [],
        }));
        this.setState({
            selectedColumn: value,
            field: value === "Variances" ? "varianceInmin" : "actualDaytotal",
            order: 'desc',
        })
    }

    handlePTO(isPTO: boolean) {
        let actualuserDetails: any[] = [];
        let TimeOffuserDetails: any[] = [];
        let PTOuserDetails: any[] = [];
        let UTOuserDetails: any[] = [];
        let filterUsers: any[] = [];
        actualuserDetails = Object.assign([], JSON.parse(JSON.stringify(this.state.alluserDetails)));
        filterUsers = actualuserDetails.filter((itemList: { timeOff: number; timeOffRow: number; }) => itemList.timeOff !== 1 && itemList.timeOffRow !== 1);
        TimeOffuserDetails = actualuserDetails.filter((itemList: { timeOff: string }) => itemList.timeOff.toString() === '1');

        PTOuserDetails = actualuserDetails.filter((itemList: { pto: string }) => itemList.pto === 'PTO');
        UTOuserDetails = actualuserDetails.filter((itemList: { uto: string }) => itemList.uto === 'UTO');

        if (isPTO) {
            TimeOffuserDetails.forEach((item) => {
                let newItem: any = {};
                let findIndex: number;
                findIndex = filterUsers.findIndex((itemList: { userName: string }) => itemList.userName === item.userName);
                newItem = filterUsers.filter((itemList: { userName: string }) => itemList.userName === item.userName);
                if (newItem.length > 0) {
                    if (PTOuserDetails.length > 0) {
                        filterUsers[findIndex].pto = 'PTO'
                    }
                    if (UTOuserDetails.length > 0) {
                        filterUsers[findIndex].uto = 'UTO'
                    }
                }
                else {
                    filterUsers.push(item)
                }
            })
            return filterUsers;
        }
        else {
            return filterUsers;
        }
    }

    handleShowPTOemp = (event: any) => {
        this.setState({ loaderTrue: true })
        let userDetails: any[] = [];
        userDetails = this.handlePTO(event.target.checked);
        this.getFilterData(userDetails);
        this.setState({ isPTOdata: event.target.checked, UserDetails: userDetails, loaderTrue: false })
        this.compareArray(this.state.allEmployeelist, userDetails);
    }

    handleActualFilter = (event: any, value: string) => {
        this.setState({ loaderTrue: true })
        let userDetails: any[] = [];
        userDetails = this.handlePTO(this.state.isPTOdata);
        this.getFilterData(userDetails);
        this.setState({ isOption: event.target.checked, UserDetails: [], loaderTrue: false }
            , () => { this.setState({ UserDetails: userDetails }) });
    }

    handleSelectedItem(item: any) {
        if (this.state.isedit.toString().toLowerCase() === "yes") {
            this.setState({ loaderTrue: true })
            let newemployee: any = {};
            let userDetails: any[] = [];
            let isPTO = item.isPTO;
            if (this.state.isPTOdata) {
                userDetails = this.handlePTO(this.state.isPTOdata);
            }
            else {
                this.setState({ isPTOdata: isPTO === 'Yes' ? true : false })
                userDetails = this.handlePTO(isPTO === 'Yes' ? true : false);
            }
            this.getFilterData(userDetails);
            let filterlist = userDetails.filter((itemList: { userName: string; }) => itemList.userName === item.employee);
            let newUserList: any[] = [];
            newUserList = this.state.employeelist.filter((e: { employee: any; }) => e.employee !== item.employee)
            if (filterlist.length === 0) {
                // add new
                newemployee = this.addNewEmployee(item);
                userDetails.push(newemployee);
                this.setState({ UserDetails: [] }, () => {
                    if (this.singleSelect.current !== null) {
                        this.singleSelect.current.updateEmpData(newUserList);
                        this.firstSingleSelect.current.updateEmpData(newUserList);
                    }
                    this.setState({ UserDetails: userDetails })
                })
            }
            else if (filterlist.length >= 0 && isPTO === "Yes") {
                this.setState({ UserDetails: filterlist })
                this.setState({ isPTOdata: true, UserDetails: userDetails }, () => {
                    if (this.singleSelect.current !== null) {
                        this.singleSelect.current.updateEmpData(newUserList);
                        this.firstSingleSelect.current.updateEmpData(newUserList);
                    }
                })
            }
            this.setState({ userRow: newemployee, isExpand: true, tableExpended: [newemployee.userName] });
        }
        else {
            Utils.toastError("Date is approved.", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
    }

    addNewEmployee(item: any) {
        let newemployee: any = {};
        newemployee = {
            actualDaytotal: 0, actualNotes: 0, actualTotalhours: "0:00", actualvariance: 0, apo: "", breakTotalhours: "", breakVariance: "",
            date: this.state.date, dayStatus: "", doubleOverTime: "0:00", imagePath: item.imagePath = null ? "" : item.imagePath, isDateapprove: this.state.isDateapprove, isedit: "Yes", managesmileid: "Yes",
            isPto: 0, isPtorow: 0, isUto: 0, isUtorow: 0,
            manuals: "Manual", notesStatus: "No", nowShow: "", overTime: "", positionID: this.state.positionID, positionName: item.positionName, pto: "", uto: "", scheduleDaytotal: 0,
            scheduleTotalhours: "0:00", totalSchedulerow: 1, userName: item.employee, userUniqueno: 0, variance: "0:00", varianceFlag: "", varianceInmin: ""
            , varianceInper: "", opens: "", timeOff: 0, timeOffRow: 0, otf: ""
        }
        return newemployee;
    }

    SmileIdBedge(cell: any, row: any, rowIndex: any, formatExtraData: any) {
        let isReturn = "";
        let smilID: any[] = [];
        let filterempSmileID: any[] = [];
        smilID = Object.assign([], JSON.parse(JSON.stringify(formatExtraData)));
        if (smilID.length > 0) {
            isReturn = "";
            filterempSmileID = smilID.filter((itemList: { employee: string }) => itemList.employee === row.userName);
            filterempSmileID.forEach(function (items) {
                if (items.inImageStatus === "Pending" || items.outImageStatus === "Pending") {
                    isReturn = "Pending";
                } else if (items.inImageStatus === "Fail" || items.outImageStatus === "Fail") {
                    isReturn = isReturn === "Pending" ? isReturn : "Fail";
                } else {
                    isReturn = isReturn === "Pending" ? isReturn : isReturn === "Fail" ? isReturn : "";
                }
            });
        }
        return (
            <div className="d-flex">
                {(isReturn === "Pending" || isReturn === "Fail") && (
                    <div className={isReturn === "Pending" ? "smile-id orange-btn" : isReturn === "Fail" ? "smile-id red-btn" : "smile-id"}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.1661 4.928C13.5656 4.928 13.893 4.792 14.148 4.52267C14.4022 4.25511 14.5307 3.90133 14.5307 3.464C14.5307 3.02667 14.4022 2.67378 14.148 2.40622C13.893 2.13422 13.5656 2 13.1661 2C12.7722 2 12.4496 2.13422 12.1955 2.40533C11.9423 2.67289 11.8147 3.02667 11.8147 3.46311C11.8147 3.90044 11.9432 4.25422 12.1955 4.52178C12.4496 4.792 12.7722 4.928 13.1661 4.928ZM10.453 2.27022H5.48432V4.64089H10.4539V2.27022H10.453ZM3 6.32889V11.0453C3 12.4853 3.29448 13.7307 3.8825 14.776C4.46865 15.824 5.29301 16.6213 6.3537 17.1724C7.41064 17.7227 8.63264 18 10.0188 18C11.4218 18 12.6475 17.7227 13.6988 17.1724C14.7501 16.6213 15.5614 15.8231 16.1372 14.776C16.714 13.7307 17 12.4853 17 11.0453V6.32889H3ZM14.5635 10.8222C14.5635 12.248 14.1621 13.3547 13.3715 14.1369C12.5781 14.92 11.4611 15.312 10.0197 15.312C8.59325 15.312 7.48285 14.92 6.68944 14.1369C5.89416 13.3538 5.50027 12.248 5.50027 10.8222V8.97689H14.5635V10.8222Z" />
                        </svg>
                    </div>
                )}
            </div>
        );
    }

    handleOnExpand = (row: any, isExpand: any, rowIndex: any, e: any) => {
        if (row) {
            if (this.state.tableExpended.length > 0) {
                if (row.userName === this.state.tableExpended[0]) {
                    isExpand = false;
                }
            }
            if (isExpand) {
                this.setState({ userRow: row, isExpand: true });
                this.setState(() => ({
                    tableExpended: [row.userName],
                }));
            } else {
                this.setState({ userRow: row, isExpand: false });
                this.setState(() => ({
                    tableExpended: [],
                }));
            }
        }
    };

    onOutsideClick = (e) => {
        if (e.path !== undefined &&(e.path[0].className === "calender-display date-picker d-flex align-items-center"
            || e.path[1].className === "calender-display date-picker d-flex align-items-center"
            || e.path[2].className === "calender-display date-picker d-flex align-items-center"
            || e.path[2].className === "react-calendar__month-view "
            || e.path[1].className === "react-calendar__month-view__weekdays__weekday"
            || e.path[1].className === "drop-arrow"
            || e.path[2].className === "drop-arrow"
            || e.path[0].className === "removeBorder form-control react-datepicker-ignore-onclickoutside"
            || e.path[1].className === "removeBorder form-control react-datepicker-ignore-onclickoutside"
            || e.path[0].className === "react-datepicker__navigation react-datepicker__navigation--next"
            || e.path[0].className === "react-datepicker__navigation react-datepicker__navigation--previous"
            || e.path[0].className === "form-control search-area"
            || e.path[0].className === "btn-primary dropdown-toggle btn btn-primary"
            || e.path[0].className === "btn wht-bg cross"
            || e.path[1].className === "btn wht-bg cross"
            || e.path[2].className === "btn wht-bg cross"
            || e.path[0].className === "search-sec"
            || e.path[1].className === "search-sec"
            || e.path[0].className === "list-area"
            || e.path[0].className === "blue-bg dropdown-toggle btn btn-primary"
            || e.path[0].className === "scroll-area height")
        ) {
            return;
        }
        if (e.currentTarget.activeElement.className === "dropdown-toggle btn btn-primary" ||
            e.currentTarget.activeElement.className === "filter-btn dropdown-toggle btn btn-primary"
            || e.currentTarget.activeElement.className === "add-shift btn-outline-primary btn btn-primary"
            || e.currentTarget.activeElement.className === "close"
            || e.currentTarget.activeElement.className === "btn-outline-primary btn btn-primary more dropdown-toggle btn btn-primary"
            || e.currentTarget.activeElement.className === "dropdown-item"
            || e.currentTarget.activeElement.className === "bdr-bottom dropdown-item"
        ) {
            return;
        }
        if (this.state.isExpand) {
            let row = this.state.UserDetails.filter((x: any) => x.userName === this.state.tableExpended[0])
            this.handleOnExpand(row[0], false, "", "");
        }
    };

    openOverView() {
        this.setState({ isRedirect: true });
        localStorage.setItem("mainMenu", "Labor");
        localStorage.setItem("subMenu", "Payroll");
    }

    hideExportedDate() {
        this.setState({ exportedDate: "" }, () => { 
             if(typeof this.props["hideExportedDate"] === 'function') {
            this.props.hideExportedDate()
        } });
    }

    render() {

        if (this.state.isRedirect) {
            return <Redirect to={{
                pathname: "/labor-management/payroll-export",
                state: {
                    payPeriod: this.state.payPeriodDates,
                    pageTitle: "performance",
                },
            }} />;
        }

        const rowClasses = (row: any, rowIndex: any) => {
            if (this.state.isExpand === true && row.userName === this.state.userRow.userName) {
                return "row-Expended";
            } else {
                return "row-Action";
            }
        };

        const expandRow = {
            onlyOneExpanding: true,
            parentClassName: 'parent-expand-foo',
            renderer: (row: { userName: any; manuals: any; isDateapprove: any; isedit: any, opens: any, managesmileid: any }, rowIndex: any) => (
                <div>
                    {/* {row.manuals !== "Manual" && ( */}
                        <PerformanceScheduleDetails
                            ref={this.childSchedule}
                            hotelID={this.state.hotelID}
                            positionID={this.state.positionID}
                            date={this.state.date}
                            departmentID={this.state.departmentID}
                            userName={row.userName}
                            rowIndex={rowIndex}
                        />
                    {/* )} */}
                    <PerformanceActualDetails
                        ref={this.childActual}
                        hotelID={this.state.hotelID}
                        positionID={this.state.positionID}
                        departmentID={this.state.departmentID}
                        date={this.state.date}
                        userName={row.userName}
                        manuals={row.manuals}
                        rowIndex={rowIndex}
                        opens={row.opens}
                        isDateapprove={this.state.isDateapprove}
                        isedit={row.isedit}
                        managesmileid={row.managesmileid}
                        resetList={this.resetList.bind(this)}
                        hideExportedDate={this.hideExportedDate.bind(this)}
                    />
                </div>
            ),
            showExpandColumn: true,
            onExpand: this.handleOnExpand,
            expandByColumnOnly: true,
            expanded: this.state.tableExpended,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                if (isAnyExpands) {
                    return <div></div>;
                }
                return <div></div>;
            },
            expandColumnRenderer: ({ expanded }) => {
                if (expanded) {
                    return (
                        <span className="chevronExpended">
                            <img src={chevronRight} alt="chevron Right" />
                        </span>
                    );
                }
                return (
                    <span className="chevronCollpsed">
                        <img src={chevronRight} alt="chevron Right" />
                    </span>
                );
            },
        };

        const columns = [
            {
                dataField: "varianceInmin",
                text: "Variance",
                sort: true,
                hidden: true
            },
            {
                dataField: "actualDaytotal",
                text: "Actual",
                sort: true,
                hidden: true
            },
            {
                dataField: "userName",
                text: "User",
                footer: 'Totals',
                formatter: (cell: any, row: any, rowIndex: any) => (
                    <div className="user-profile">
                        <div className="d-flex">
                            {(row.imagePath !== null) && (
                                <div>
                                    {row.imagePath.length === 0 && (
                                        <img src={profile} className="profileimg" width="36" height="36" alt="" />
                                    )}
                                    {row.imagePath.length > 0 && (
                                        <img src={row.imagePath} className="profileimg" width="36" height="36" alt="" />
                                    )}
                                </div>
                            )}
                            {(row.imagePath === null) && (
                                <div>
                                    <img src={profile} className="profileimg" width="36" height="36" alt="" />
                                </div>
                            )}
                            <div className="d-flex align-items-center">
                                <div className="main-ttl width-set">
                                    <EllipsisWithTooltip placement="bottom">
                                        {row.userName}
                                    </EllipsisWithTooltip>
                                </div>
                                {/* <div className="sub-ttl fnt-normal width-set">
                                    <EllipsisWithTooltip placement="bottom">
                                        {row.positionName}
                                    </EllipsisWithTooltip>
                                </div> */}
                            </div>
                        </div>
                    </div>
                ),
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        if (row.action === "Expended")
                            this.handleOnExpand(row, false, rowIndex, e);
                        else
                            this.handleOnExpand(row, true, rowIndex, e);
                    }
                }
            },
            {
                dataField: "notesStatus",
                text: "",
                footer: '',
                formatter: (cell: any, row: any, rowIndex: any) => (
                    <>
                        {row.notesStatus === "Yes" && (
                            <img src={notesIcon} alt=""></img>
                        )}
                    </>
                ),
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        if (row.action === "Expended")
                            this.handleOnExpand(row, false, rowIndex, e);
                        else
                            this.handleOnExpand(row, true, rowIndex, e);
                    }
                }
            },
            {
                dataField: "dayStatus",
                text: "",
                footer: '',
                formatter: (cell: any, row: any, rowIndex: any) => (
                    <div className="d-flex flex-wrap">
                        {row.apo.trim().length > 0 && (
                            <>
                                <div className="red-badges orange-bg">{row.apo}</div>
                            </>
                        )}
                        {row.pto.trim().length > 0 && (
                            <>
                                <div className="red-badges blue-bg">{row.pto}</div>
                            </>
                        )}
                        {row.uto.trim().length > 0 && (
                            <>
                                <div className="red-badges blue-bg">{row.uto}</div>
                            </>
                        )}
                        {row.nowShow.trim().length > 0 && (
                            <>
                                <div className="red-badges">{row.nowShow}</div>
                            </>
                        )}
                        {row.manuals.trim().length > 0 && (
                            <>
                                <div className="red-badges blue-bg">{row.manuals}</div>
                            </>
                        )}
                        {(row.opens !== "" && row.opens !== null) && (
                            <>
                                <div className="red-badges">{row.opens}</div>
                            </>
                        )}
                        {(row.otf !== "" && row.otf !== null) && (
                            <>
                                <div className="red-badges blue-bg">{row.otf}</div>
                            </>
                        )}
                    </div>
                ),
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        if (row.action === "Expended")
                            this.handleOnExpand(row, false, rowIndex, e);
                        else
                            this.handleOnExpand(row, true, rowIndex, e);
                    }
                }
            },
            {
                dataField: "",
                text: "",
                footer: '',
                formatter: this.SmileIdBedge,
                formatExtraData: this.state.empSmileID,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        if (row.action === "Expended")
                            this.handleOnExpand(row, false, rowIndex, e);
                        else
                            this.handleOnExpand(row, true, rowIndex, e);
                    }
                }
            },
            {
                dataField: "actualTotalhours",
                text: "Totals",
                formatter: (cell: any, row: { varianceFlag: React.ReactNode; varianceInper: React.ReactNode; scheduleTotalhours: React.ReactNode; actualTotalhours: React.ReactNode; }, rowIndex: any) => {
                    if (row.varianceFlag === "Actual" && Number(row.varianceInper) <= Number(this.state.actualvariance) && this.state.isOption) {
                        return <div className="bdr-right"><div className="main-ttl color-red">{row.actualTotalhours}</div> <div className="sub-ttl mdy-fnt">{row.scheduleTotalhours}</div></div>
                    }
                    if (row.varianceFlag === "Actual" && Number(row.varianceInper) <= Number(this.state.actualvariance) && !this.state.isOption) {
                        return <div className="bdr-right"><div className="main-ttl">{row.actualTotalhours}</div> <div className="sub-ttl mdy-fnt">{row.scheduleTotalhours}</div></div>
                    }

                    if (row.varianceFlag === "Actual" && Number(row.varianceInper) > Number(this.state.actualvariance)) {
                        return <div className="bdr-right"><div className="main-ttl color-red">{row.actualTotalhours}</div> <div className="sub-ttl mdy-fnt">{row.scheduleTotalhours}</div></div >
                    }

                    if (row.varianceFlag === "Schedule") {
                        return <div className="bdr-right"><div className="main-ttl">{row.actualTotalhours}</div> <div className="sub-ttl mdy-fnt">{row.scheduleTotalhours}</div></div >
                    }
                    if (row.varianceFlag === "") {
                        return <div className="bdr-right"><div className="main-ttl">{""}</div> <div className="sub-ttl mdy-fnt">{""}</div></div >
                    }
                },
                footer: (columnData: any[]) => {
                    let total = 0;
                    columnData.map((data: any) => {
                        if (data !== null) {
                            if (data !== undefined) {
                                if (data.trim().length > 0) {
                                    if (data.includes(":")) {
                                        let subTotal = 0;
                                        const dataArray = data.split(":");
                                        let HH = Number(dataArray[0]);
                                        let MM = Number(dataArray[1]);
                                        if (dataArray[0].includes("-") || dataArray[1].includes("-")) {
                                            HH = HH < 0 ? (HH * -1) : HH
                                            MM = MM < 0 ? (MM * -1) : MM
                                            subTotal = ((HH * 60 + MM) * -1);
                                        } else {
                                            subTotal = (HH * 60 + MM)
                                        }
                                        total = total + subTotal
                                    }
                                }
                            }
                        }
                    })
                    let perrow = Number(Number(total) - Number(this.state.totalScheduleMinuts)) / Number(this.state.totalScheduleMinuts) * 100
                    if (Number(perrow > 0)) {
                        if (Number(this.state.totalScheduleMinuts) < Number(total) && Number(perrow) <= Number(this.state.actualvariance) && this.state.isOption) {
                            return (<div className="bdr-right">
                                <div className="main-ttl color-red">{this.decimalHoursToString(Math.floor(Number(total) / 60), Math.round(Number(total) % 60))}</div>
                                <div className="sub-ttl mdy-fnt">{this.decimalHoursToString(Math.floor(Number(this.state.totalScheduleMinuts) / 60), Math.round(Number(this.state.totalScheduleMinuts) % 60))}</div>
                            </div>);
                        } else if (Number(this.state.totalScheduleMinuts) < Number(total) && Number(perrow) <= Number(this.state.actualvariance) && !this.state.isOption) {
                            return (<div className="bdr-right">
                                <div className="main-ttl">{this.decimalHoursToString(Math.floor(Number(total) / 60), Math.round(Number(total) % 60))}</div>
                                <div className="sub-ttl mdy-fnt">{this.decimalHoursToString(Math.floor(Number(this.state.totalScheduleMinuts) / 60), Math.round(Number(this.state.totalScheduleMinuts) % 60))}</div>
                            </div>);
                        } else if (Number(this.state.totalScheduleMinuts) < Number(total) && Number(perrow) > Number(this.state.actualvariance)) {
                            return (<div className="bdr-right">
                                <div className="main-ttl color-red">{this.decimalHoursToString(Math.floor(Number(total) / 60), Math.round(Number(total) % 60))}</div>
                                <div className="sub-ttl mdy-fnt">{this.decimalHoursToString(Math.floor(Number(this.state.totalScheduleMinuts) / 60), Math.round(Number(this.state.totalScheduleMinuts) % 60))}</div>
                            </div>);
                        }
                        else {
                            return (<div className="bdr-right">
                                <div className="main-ttl">{this.decimalHoursToString(Math.floor(Number(total) / 60), Math.round(Number(total) % 60))}</div>
                                <div className="sub-ttl mdy-fnt">{this.decimalHoursToString(Math.floor(Number(this.state.totalScheduleMinuts) / 60), Math.round(Number(this.state.totalScheduleMinuts) % 60))}</div>
                            </div>);
                        }
                    }
                    else {
                        return (<div className="bdr-right">
                            <div className="main-ttl">{this.decimalHoursToString(Math.floor(Number(total) / 60), Math.round(Number(total) % 60))}</div>
                            <div className="sub-ttl mdy-fnt">{this.decimalHoursToString(Math.floor(Number(this.state.totalScheduleMinuts) / 60), Math.round(Number(this.state.totalScheduleMinuts) % 60))}</div>
                        </div>);
                    }
                },
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        if (row.action === "Expended")
                            this.handleOnExpand(row, false, rowIndex, e);
                        else
                            this.handleOnExpand(row, true, rowIndex, e);
                    }
                }
            },
            {
                dataField: "actualTotalhours",
                text: "Variances",
                footer: (columnData: any[]) => {
                    let total = 0;
                    columnData.map((data: any) => {
                        if (data !== null) {
                            if (data !== undefined) {
                                if (data.trim().length > 0) {
                                    if (data.includes(":")) {
                                        let subTotal = 0;
                                        const dataArray = data.split(":");
                                        let HH = Number(dataArray[0]);
                                        let MM = Number(dataArray[1]);
                                        if (HH < 0 || MM < 0) {
                                            HH = HH < 0 ? (HH * -1) : HH
                                            MM = MM < 0 ? (MM * -1) : MM
                                            subTotal = ((HH * 60 + MM) * -1);
                                        } else {
                                            subTotal = (HH * 60 + MM)
                                        }
                                        total = total + subTotal
                                    }
                                }
                            }
                        }
                    })
                    let TotalVarianceMinuts = Number(Number(total) - Number(this.state.totalScheduleMinuts))
                    let actualdata = this.minutestoHoursMinuts(TotalVarianceMinuts)
                    if (actualdata !== "0:00") {
                        if (TotalVarianceMinuts >= 0) {
                            return (
                                <div>
                                    <div className="main-ttl color-red">{actualdata}</div>
                                    <div className="sub-ttl mdy-fnt">vs. Actual</div>
                                </div>
                            )
                        }
                        else {
                            return (
                                <div>
                                    <div className="main-ttl">{actualdata}</div>
                                    <div className="sub-ttl mdy-fnt">vs. Actual</div>
                                </div>
                            )
                        }

                    }
                },
                formatter: (cell: any, row: any, rowIndex: any) => (
                    <>
                        {(row.variance !== "0:00" && row.variance !== "") && (
                            <div className="">
                                {row.varianceFlag === "Actual" && (
                                    <div className="main-ttl color-red">{row.variance}</div>
                                )}
                                {row.varianceFlag === "Schedule" && (
                                    <div className="main-ttl">{row.variance}</div>
                                )}
                                <div className="sub-ttl mdy-fnt fnt-normal">vs. Actual</div>
                            </div>
                        )}
                    </>
                ),
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        if (row.action === "Expended")
                            this.handleOnExpand(row, false, rowIndex, e);
                        else
                            this.handleOnExpand(row, true, rowIndex, e);
                    }
                }
            },
            {
                dataField: "breakTotalhours",
                text: "",
                footer: (columnData: any[]) => {
                    let total = 0;
                    columnData.map((data: any) => {
                        if (data !== null) {
                            if (data !== undefined) {
                                if (data.trim().length > 0) {
                                    if (data.includes(":")) {
                                        let subTotal = 0;
                                        const dataArray = data.split(":");
                                        let HH = Number(dataArray[0]);
                                        let MM = Number(dataArray[1]);
                                        if (HH < 0 || MM < 0) {
                                            HH = HH < 0 ? (HH * -1) : HH
                                            MM = MM < 0 ? (MM * -1) : MM
                                            subTotal = ((HH * 60 + MM) * -1);
                                        } else {
                                            subTotal = (HH * 60 + MM)
                                        }
                                        total = total + subTotal
                                    }
                                }
                            }
                        }
                    })
                    let breakdata = this.minutestoHoursMinuts(total)
                    if (breakdata !== "0:00") {
                        return (
                            <div className="bdr-right">
                                {total >= 0 && (
                                    <div className="main-ttl">{breakdata}</div>
                                )}
                                {total < 0 && (
                                    <div className="main-ttl  color-red">{breakdata}</div>
                                )}
                                <div className="sub-ttl mdy-fnt">Break</div>
                            </div>
                        )
                    }
                    else {
                        return (
                            <div className="bdr-right">
                                <div className="main-ttl"></div>
                                <div className="sub-ttl mdy-fnt"></div>
                            </div>
                        )
                    }
                },
                formatter: (cell: any, row: any, rowIndex: any) => {
                    let isNeg = false;
                    let subTotal = 0;
                    if (row.breakTotalhours.trim().length > 0) {
                        if (row.breakTotalhours.includes(":")) {
                            subTotal = 0;
                            const dataArray = row.breakTotalhours.split(":");
                            let HH = Number(dataArray[0]);
                            let MM = Number(dataArray[1]);
                            if (HH < 0 || MM < 0) {
                                HH = HH < 0 ? (HH * -1) : HH
                                MM = MM < 0 ? (MM * -1) : MM
                                isNeg = true
                            } else {
                                isNeg = false
                            }
                            subTotal = ((HH * 60 + MM));
                        }
                    }
                    let breakdata = this.minutestoHoursMinuts(subTotal)
                    if (breakdata !== "0:00") {
                        if (isNeg) {
                            return <div className="bdr-right"> <div className="main-ttl color-red">{breakdata}</div>
                                <div className="sub-ttl mdy-fnt fnt-normal">Break</div></div>
                        } else {
                            return <div className="bdr-right"> <div className="main-ttl">{breakdata}</div>
                                <div className="sub-ttl mdy-fnt fnt-normal">Break</div></div>
                        }

                    }
                    else {
                        return <div className="bdr-right"> <div className="main-ttl"></div>
                            <div className="sub-ttl mdy-fnt fnt-normal"></div></div>
                    }
                },
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        if (row.action === "Expended")
                            this.handleOnExpand(row, false, rowIndex, e);
                        else
                            this.handleOnExpand(row, true, rowIndex, e);
                    }
                }
            },
            {
                dataField: "overTime",
                text: "Overtime",
                footer: (columnData: any[]) => {
                    let total = 0;
                    if (columnData.length > 0) {
                        columnData.map((data: any) => {
                            if (data !== null) {
                                if (data !== undefined) {
                                    if (data.trim().length > 0) {
                                        if (data.includes(":")) {
                                            let subTotal = 0;
                                            const dataArray = data.split(":");
                                            let HH = Number(dataArray[0]);
                                            let MM = Number(dataArray[1]);
                                            if (HH < 0 || MM < 0) {
                                                HH = HH < 0 ? (HH * -1) : HH
                                                MM = MM < 0 ? (MM * -1) : MM
                                                subTotal = ((HH * 60 + MM) * -1);
                                            } else {
                                                subTotal = (HH * 60 + MM)
                                            }
                                            total = total + subTotal
                                        }
                                    }
                                }
                            }
                        })
                    }
                    let OT = this.minutestoHoursMinuts(total)
                    if (OT !== "") {
                        if (OT !== "0:00") {
                            return (
                                <div>
                                    <div className="main-ttl color-red">{OT}</div>
                                    <div className="sub-ttl mdy-fnt">OT</div>
                                </div>
                            )
                        }
                    }
                },
                formatter: (cell: any, row: any, rowIndex: any) => (
                    <>
                        {(row.overTime !== "0:00" && row.overTime !== "" && row.overTime !== null) && (

                            <div>
                                <div className="main-ttl color-red">{row.overTime}</div>
                                <div className="sub-ttl mdy-fnt fnt-normal">OT</div>
                            </div>
                        )}

                    </>
                ),
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        if (row.action === "Expended")
                            this.handleOnExpand(row, false, rowIndex, e);
                        else
                            this.handleOnExpand(row, true, rowIndex, e);
                    }
                }
            },
            {
                dataField: "doubleOverTime",
                text: "",
                footer: (columnData: any[]) => {
                    let total = 0;
                    if (columnData.length > 0) {
                        columnData.map((data: any) => {
                            if (data !== null) {
                                if (data !== undefined) {
                                    if (data.trim().length > 0) {
                                        if (data.includes(":")) {
                                            let subTotal = 0;
                                            const dataArray = data.split(":");
                                            let HH = Number(dataArray[0]);
                                            let MM = Number(dataArray[1]);
                                            if (HH < 0 || MM < 0) {
                                                HH = HH < 0 ? (HH * -1) : HH
                                                MM = MM < 0 ? (MM * -1) : MM
                                                subTotal = ((HH * 60 + MM) * -1);
                                            } else {
                                                subTotal = (HH * 60 + MM)
                                            }
                                            total = total + subTotal
                                        }
                                    }
                                }
                            }
                        })
                    }
                    let DOT = this.minutestoHoursMinuts(total)
                    if (DOT !== "") {
                        if (DOT !== "0:00") {
                            return (
                                <div>
                                    <div className="main-ttl color-red">{DOT}</div>
                                    <div className="sub-ttl mdy-fnt">DOT</div>
                                </div>
                            )
                        }
                    }

                },
                formatter: (cell: any, row: any, rowIndex: any) => (
                    <>
                        {(row.doubleOverTime !== "0:00" && row.doubleOverTime !== "" && row.doubleOverTime !== null) && (
                            <div>
                                <div className="main-ttl color-red">{row.doubleOverTime}</div>
                                <div className="sub-ttl mdy-fnt fnt-normal">DOT</div>
                            </div>
                        )}
                    </>
                ),
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        if (row.action === "Expended")
                            this.handleOnExpand(row, false, rowIndex, e);
                        else
                            this.handleOnExpand(row, true, rowIndex, e);
                    }
                }
            },
            {
                dataField: "action",
                text: "",
                footer: '',
                editable: false,
                formatter: (cell: any, row: any, rowIndex: any) => (
                    <></>
                    // <Dropdown className="more-action" alignRight>
                    //     <Dropdown.Toggle
                    //         className="btn-outline-primary btn btn-primary more"
                    //         id="dropdown-more"
                    //     >
                    //         <svg
                    //             width="20"
                    //             height="20"
                    //             viewBox="0 0 20 20"
                    //             fill="none"
                    //             xmlns="http://www.w3.org/2000/svg"
                    //         >
                    //             <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                    //             <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                    //             <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                    //         </svg>
                    //     </Dropdown.Toggle>
                    //     {/* <Dropdown.Menu>
                    //         <Dropdown.Item eventKey={"Dummy Text"}>Dummy Text</Dropdown.Item>
                    //     </Dropdown.Menu> */}
                    // </Dropdown>
                )
            },
        ];

        let loadShow: any;
        if (this.state.loaderTrue) {
            loadShow = <PageLoader />;
        }

        return (
            <div className="pos-fxd index-1000">
                {/* <ToastContainer autoClose={3000} /> */}
                {loadShow}
                <div className="d-flex">
                    <div className="back-drop" onClick={this.closeSlideOut}></div>
                    <Container fluid className="body-sec performance-position justify-content-end">
                        <div className="page-heading underline d-flex">
                            <div className="hid-set d-flex align-items-center">{this.state.hotelName}</div>
                            <div className="mr-auto d-flex align-items-center">{this.state.positionName}</div>
                            <div className="action-group d-flex flex-row">
                                {this.state.employeelist.length > 0 && this.state.parentName !== "autoPunchOut" && this.state.isedit.toString().toLowerCase() === "yes" && (
                                    <SingleSelectSearchWithImage
                                        ref={this.firstSingleSelect}
                                        employeeList={this.state.employeelist}
                                        handleSelectedItem={this.handleSelectedItem.bind(this)}
                                        mode="primary"
                                        defaultItem={this.state.positionName}

                                    />
                                )}
                                <button type="button" className="btn wht-bg cross" onClick={this.closeSlideOut}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z" fill="#84888C" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className="bdy-sec">
                            {(!this.state.pageloader &&
                                <ReactPageLoader useas={"performanceSlideout"} />
                            )}

                            {(this.state.pageloader &&
                                <ToolkitProvider
                                    keyField="userUniqueno"
                                    data={this.state.UserDetails}
                                    columns={columns}
                                    sort={{
                                        dataField: this.state.field,
                                        order: this.state.order
                                    }}
                                    search>
                                    {(props: {
                                        searchProps: JSX.IntrinsicAttributes;
                                        baseProps: JSX.IntrinsicAttributes;
                                    }) => (
                                        <div>

                                            <div className="search-header d-flex">
                                                {this.state.parentName !== "autoPunchOut" && (
                                                    <SearchBar className="search-area" {...props.searchProps} placeholder={"Search..."} />
                                                )}
                                                <div className="mr-auto date-picker calender-picker">
                                                    <div className="calender-icon">
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z" fill="#3F4347" />
                                                            <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z" fill="#3F4347" />
                                                        </svg>
                                                    </div>
                                                    {(this.state.parentName === "autoPunchOut" || this.state.parentName === "underBudget" || this.state.parentName === "overBudget") && (
                                                        <DatePicker key="datePicker" selected={new Date(this.state.date)}
                                                            //maxDate={new Date(this.state.maxDate)}
                                                            maxDate={JSON.parse(localStorage.storage).currentUtcTime !== "" ? new Date(
                                                                JSON.parse(localStorage.storage).currentUtcTime
                                                              ):new Date()}
                                                            placeholderText={"Select date"} dateFormat="M/dd/yy" minDate={new Date(this.state.minDate)}
                                                            className="removeBorder form-control" disabled />
                                                    )}
                                                    {(this.state.parentName !== "autoPunchOut" && this.state.parentName !== "underBudget" && this.state.parentName !== "overBudget") && (
                                                        <div>
                                                            <DatePicker key="datePicker" selected={new Date(this.state.date)}
                                                                //maxDate={new Date(this.state.maxDate)}
                                                                maxDate={JSON.parse(localStorage.storage).currentUtcTime !== "" ? new Date(
                                                                    JSON.parse(localStorage.storage).currentUtcTime
                                                                  ):new Date()}
                                                                placeholderText={"Select date"} dateFormat="M/dd/yy" minDate={new Date(this.state.minDate)}
                                                                className="removeBorder form-control" onSelect={this.handleSelectDate} />
                                                            <div className="drop-arrow">
                                                                <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M3.28122e-05 1.19724C3.28122e-05 0.889538 0.253262 0.653982 0.543288 0.653982C0.688297 0.653982 0.833313 0.708449 0.923855 0.816677L4.0023 4.0217L7.08074 0.816677C7.2979 0.599519 7.6424 0.599519 7.84116 0.816677C8.04064 1.01616 8.05831 1.34153 7.85955 1.55941L4.40046 5.18112C4.20098 5.39827 3.85721 5.39828 3.64005 5.19951L3.62166 5.18112L0.145009 1.55941C0.0544669 1.46887 0 1.34226 0 1.19724L3.28122e-05 1.19724Z" fill="#3F4347" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {this.state.lockStatus.toLowerCase() === "locked" && (
                                                        <div className="lock-date">
                                                            <img src={ForecastLock} alt="ForecastLock" />
                                                        </div>
                                                    )}
                                                </div>
                                                {this.state.parentName !== "autoPunchOut" && (
                                                    <div>
                                                        <Dropdown className="more-action bg-blue">
                                                            <Dropdown.Toggle id="dropdown-ptoType">
                                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M4.5 15L7.5 12H5.25V3H3.75V12H1.5L4.5 15ZM8.25 6H15V7.5H8.25V6ZM8.25 9H13.5V10.5H8.25V9ZM8.25 3H16.5V4.5H8.25V3ZM8.25 12H12V13.5H8.25V12Z" />
                                                                </svg>
                                                                {this.state.selectedColumn}
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                {this.state.columnName.map(
                                                                    (item: any, idx: any) => (
                                                                        <Dropdown.Item
                                                                            key={item.id}
                                                                            eventKey={item.value}
                                                                            onClick={(e: any) => this.handleFilterChange(e, item.value)}
                                                                        >
                                                                            {item.value}
                                                                        </Dropdown.Item>
                                                                    )
                                                                )}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                )}
                                                {this.state.parentName !== "autoPunchOut" && (
                                                    <div>
                                                        <Dropdown className="more-action bg-blue">
                                                            <Dropdown.Toggle id="dropdown-ptoType">
                                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M1.56286 10.1808L2.89619 12.4875C3.08019 12.8062 3.48819 12.9155 3.80686 12.7308L4.73752 12.1935C5.12419 12.4982 5.55152 12.7482 6.00086 12.9348V14.0002C6.00086 14.3682 6.29886 14.6668 6.66752 14.6668H9.33419C9.70286 14.6668 10.0009 14.3682 10.0009 14.0002V12.9348C10.4502 12.7482 10.8769 12.4982 11.2642 12.1935L12.1949 12.7308C12.5129 12.9142 12.9222 12.8042 13.1055 12.4875L14.4389 10.1808C14.6222 9.8635 14.5122 9.4535 14.1955 9.27016L13.2809 8.7415C13.3155 8.4955 13.3335 8.2475 13.3335 8.00016C13.3335 7.75283 13.3155 7.50483 13.2795 7.25883L14.1942 6.73016C14.5115 6.54683 14.6215 6.13683 14.4375 5.8195L13.1042 3.51283C12.9202 3.19416 12.5122 3.08483 12.1935 3.2695L11.2629 3.80683C10.8762 3.50216 10.4495 3.2515 10.0002 3.0655V2.00016C10.0002 1.63216 9.70219 1.3335 9.33352 1.3335H6.66686C6.29819 1.3335 6.00019 1.63216 6.00019 2.00016V3.0655C5.55086 3.25216 5.12419 3.50216 4.73686 3.80683L3.80686 3.2695C3.48752 3.08483 3.08019 3.19416 2.89619 3.51283L1.56286 5.8195C1.37953 6.13683 1.48953 6.54683 1.80619 6.73016L2.72086 7.25883C2.68486 7.50483 2.66686 7.75283 2.66686 8.00016C2.66686 8.2475 2.68486 8.4955 2.72086 8.7415L1.80619 9.27016C1.48886 9.4535 1.37886 9.8635 1.56286 10.1808ZM8.00019 5.3335C9.47086 5.3335 10.6669 6.5295 10.6669 8.00016C10.6669 9.47083 9.47086 10.6668 8.00019 10.6668C6.52952 10.6668 5.33352 9.47083 5.33352 8.00016C5.33352 6.5295 6.52952 5.3335 8.00019 5.3335Z" />
                                                                </svg>

                                                        View Options
                                                    </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <div className="variance-modal">
                                                                    <div className="header">
                                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                                            <Form.Label column sm="8">Show Employees with Time Off</Form.Label>
                                                                            <Col sm="4">
                                                                                <Form.Check
                                                                                    type="switch"
                                                                                    className="sm-controll"
                                                                                    id="custom-switch"
                                                                                    label=""
                                                                                    checked={this.state.isPTOdata}
                                                                                    onChange={(event: any) => this.handleShowPTOemp(event)}
                                                                                />
                                                                            </Col>
                                                                        </Form.Group>
                                                                    </div>
                                                                    <div className="bdy-container">
                                                                        <div className="ttl">Actuals Highlighting</div>
                                                                        <Form.Group as={Row} controlId="">
                                                                            <Form.Label column sm="8">0 - {Number(this.state.actualvariance)}%</Form.Label>
                                                                            <Col sm="4">
                                                                                <Form.Check
                                                                                    type="switch"
                                                                                    className="sm-controll"
                                                                                    id="custom-switch1"
                                                                                    label=""
                                                                                    checked={this.state.isOption}
                                                                                    onChange={(event: any) => this.handleActualFilter(event, this.state.actualvariance)}
                                                                                />
                                                                            </Col>
                                                                        </Form.Group>
                                                                        <Form.Group as={Row} controlId="">
                                                                            <Form.Label column sm="8">{Number(this.state.actualvariance)}% +</Form.Label>
                                                                            <Col sm="4">
                                                                                <Form.Check
                                                                                    type="switch"
                                                                                    className="sm-controll"
                                                                                    id="custom-switch2"
                                                                                    label=""
                                                                                    checked
                                                                                />
                                                                            </Col>
                                                                        </Form.Group>
                                                                    </div>
                                                                </div>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                )}
                                            </div>
                                            {this.state.exportedDate.length > 0 &&
                                                this.state.exportedDate !== "0" && (
                                                    <div className="info-badges exported-date">
                                                        <div className="d-flex">
                                                            <div>This week was exported as part of the </div>
                                                            <a className="d-flex" onClick={() => this.openOverView()}>{this.state.payPeriodDates[0]} {"-"} {this.state.payPeriodDates[1]} {"payroll"}</a>
                                                            <span>on</span> <div className="bold">{this.state.exportedDate}</div>
                                                        </div>
                                                    </div>
                                                )}
                                             <div id="scrollarea" className={
                                                    (this.state.exportedDate.length > 0 && this.state.exportedDate !== "0")
                                                    ? "scroll-area height"                                                                                              
                                                    : "scroll-area height-without-banner"
                                                }>
                                                <OutsideClickHandler
                                                    onOutsideClick={(e) => {
                                                        this.onOutsideClick(e);
                                                    }}>
                                                    <BootstrapTable
                                                        {...props.baseProps}
                                                        keyField="userName"
                                                        expandRow={expandRow}
                                                        rowClasses={rowClasses}
                                                        hover
                                                        sort={{
                                                            dataField: this.state.field,
                                                            order: this.state.order
                                                        }}
                                                        filter={filterFactory()}
                                                    /></OutsideClickHandler>
                                                {this.state.employeelist.length > 0 && this.state.parentName !== "autoPunchOut" && this.state.isedit.toString().toLowerCase() === "yes" && (
                                                    <SingleSelectSearchWithImage
                                                        ref={this.singleSelect}
                                                        employeeList={this.state.employeelist}
                                                        handleSelectedItem={this.handleSelectedItem.bind(this)}
                                                        mode="default"
                                                        defaultItem={this.state.positionName}
                                                    />
                                                )}
                                            </div>

                                        </div>
                                    )}
                                </ToolkitProvider>
                            )}
                        </div>
                    </Container>
                </div>
            </div>
        );
    }

}
