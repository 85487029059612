import * as React from 'react';
// import { Form } from 'react-bootstrap'
// import { Hotel } from '../../Common/Services/Hotel';
// import { IHotelResponseDto } from '../../Common/Contracts/IHotel';
import {COAAccountSelector} from "./COAAccountSelector"
import { AccountingPeriodStart } from './AccountingPeriodStart';
import { PeriorYearSelector } from './PeriorYearSelector';


export class PeriorYearSelect extends React.Component<any, any> {
  private SingleselectChild: any;
  private singleSerch: any;
  constructor(props: any) {
    super(props);
    this.SingleselectChild = React.createRef();
    this.singleSerch = React.createRef();
    this.state = {
      hidValue: "Select",
      hotelName: "Select",
      copyFromHid: 0,
      hotelList: [],
      defaultValue: this.props?.defaultValue
    };
    this.isHotelid = this.isHotelid.bind(this);
  }

  isHotelid = (hotelList: any) => {
    this.setState({ copyFromHid: hotelList });
    this.props.handlePeriorYear(hotelList);
  };

  resetValue = () => {
    this.singleSerch.current.resetSerchBox();
  };

  componentDidUpdate(prevProps){
    if(prevProps?.defaultValue!==this.props?.defaultValue) {
      this.setState({defaultValue:this.props.defaultValue});
    }
  }

  render() {
    return (
      <PeriorYearSelector
      ddlId= {this.props?.ddlId}
        isAll={this.props?.isAll}
        rptid= {this.props?.reportid}
        ddlValue={this.props?.startvalue}
        notIncludeEnterpriseAccounts={this.props?.notIncludeEnterpriseAccounts}
        defaultValue={this.state?.defaultValue}
        ref={this.singleSerch}
        isHotelid={this.isHotelid.bind(this)}
      />
    );
  }
}
