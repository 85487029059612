import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { LaborTimeSheet } from "../../../Common/Services/LaborTimeSheet";
import {
    // Dropdown, 
    Tabs, Tab,
    //  Form, Row, Col, Button
} from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { Utils } from '../../../Common/Utilis';

export class LaborTimesheetResolvedDisputeUserView extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            hotelID: this.props.hotelID,
            userName: this.props.userName,
            date: this.props.date,
            correctedIn: "",
            correctedOut: "",
            disputedDetails: [],
            allpositionlist: []
        }
    }

    formatDate(time: string) {
        const dataArray = time.split(":");
        let hh = Number(dataArray[0]);
        let m = Number(dataArray[1]);
        let dd = "AM";
        let h = hh;
        if (h >= 12) {
            h = hh - 12;
            dd = "PM";
        }
        if (h === 0) {
            h = 12;
        }
        let min = m < 10 ? "0" + m : m;
        let replacement = h + ":" + min;
        replacement += " " + dd;
        return replacement;
    }

    componentDidMount() {
        this.loadDisputeDetails(this.props.hotelID, this.props.date, this.props.userName);
    }

    loadDisputeDetails(hotelID: any, selectedDate: any, userName: any) {
        LaborTimeSheet.LaborDisputePunchDetails(hotelID, selectedDate, userName, "MyTimeSheet").then(async (result: any | null) => {
            if (result.disputedDetails != null) {
                this.setState({ disputedDetails: [] });
                let data = Object.assign([], JSON.parse(JSON.stringify(result.disputedDetails)));
                data = data.filter(item => item.status.toLowerCase() !== "open");
                data.map((item) => {
                    item.correctedPunche[0].newDepartmentId = 0;
                    item.correctedPunche[0].newDepartment = "Select";
                    item.correctedPunche[0].newPositionId = 0;
                    item.correctedPunche[0].newPosition = "Select";
                });
                this.setState({ disputedDetails: data })
            }
        })
    }

    render() {

        const disputedColumns = (index: any) => [
            {
                dataField: "disputedIn",
                text: "In",
                formatter: (cell: any, row: any, rowIndex: any) => (
                    <>
                        {this.state.disputedDetails[index].disputType.toLowerCase() === "in" && (
                            <div className="badge color-orange statics">{this.formatDate(row.disputedIn)}</div>
                        )}
                        {this.state.disputedDetails[index].disputType.toLowerCase() !== "in" && (
                            <div className="rm-bold">{this.formatDate(row.disputedIn)}</div>
                        )}
                    </>
                )
            },
            {
                dataField: "disputedOut",
                text: "Out",
                formatter: (cell: any, row: any, rowIndex: any) => (
                    <>
                        {this.state.disputedDetails[index].disputType.toLowerCase() === "out" && (
                            <div className="badge color-orange statics">{this.formatDate(row.disputedOut)}</div>
                        )}
                        {this.state.disputedDetails[index].disputType.toLowerCase() !== "out" && (
                            <div className="rm-bold">{this.formatDate(row.disputedOut)}</div>
                        )}
                    </>
                )
            },
            {
                dataField: "disputedPosition",
                text: "Position",
                formatter: (cell: any, row: any, rowIndex: any) => (
                    <>
                        {this.state.disputedDetails[index].disputType.toLowerCase() === "position" && (
                            <div className="badge color-orange statics width-150">
                                <EllipsisWithTooltip placement="bottom">
                                    {row.disputedPosition}
                                </EllipsisWithTooltip>
                            </div>
                        )}
                        {this.state.disputedDetails[index].disputType.toLowerCase() !== "position" && (
                            <div>
                                <EllipsisWithTooltip placement="bottom">
                                    {row.disputedPosition}
                                </EllipsisWithTooltip>
                            </div>
                        )}
                    </>
                )
            },
            {
                dataField: "",
                text: "",
            }
        ]
        const correctedColumns = (index: any) => [
            {
                dataField: "correctedIn",
                text: "In",
                formatter: (cell: any, row: any, rowIndex: any) => (
                    <>
                        {this.state.disputedDetails[index].disputType.toLowerCase() === "in" && (
                            <div className="badge color-green statics">{this.formatDate(row.correctedInOrg)}</div>
                        )}
                        {this.state.disputedDetails[index].disputType.toLowerCase() !== "in" && (
                            <div className="rm-bold">{this.formatDate(row.correctedInOrg)}</div>
                        )}
                    </>
                )
            },
            {
                dataField: "correctedOut",
                text: "Out",
                formatter: (cell: any, row: any, rowIndex: any) => (
                    <>
                        {this.state.disputedDetails[index].disputType.toLowerCase() === "out" && (
                            <div className="badge color-green statics">{this.formatDate(row.correctedOutOrg)}</div>
                        )}
                        {this.state.disputedDetails[index].disputType.toLowerCase() !== "out" && (
                            <div className="rm-bold">{this.formatDate(row.correctedOutOrg)}</div>
                        )}
                    </>
                )
            },
            {
                dataField: "correctedPosition",
                text: "Position",
                formatter: (cell: any, row: any, rowIndex: any) => (
                    <>
                        {this.state.disputedDetails[index].disputType.toLowerCase() === "position" && (
                            <div className="badge color-green statics width-150">
                                <EllipsisWithTooltip placement="bottom">
                                    {row.correctedPositionOrg}
                                </EllipsisWithTooltip>
                            </div>
                        )}
                        {this.state.disputedDetails[index].disputType.toLowerCase() !== "position" && (
                            <div>
                                <EllipsisWithTooltip placement="bottom">
                                    {row.correctedPositionOrg}
                                </EllipsisWithTooltip>
                            </div>
                        )}
                    </>
                )
            },
            {
                dataField: "",
                text: "",
            }
        ]

        return (
            <>
                {/* <ToastContainer autoClose={3000} /> */}
                {this.state.disputedDetails.length > 0 && (
                    <>
                        <div className="ttl">Resolved Disputes</div>
                        {this.state.disputedDetails.map((item: any, index: any) => (
                            <div className="detail-view" key={item.disputeID}>
                                <div className="header-sec d-flex align-items-center">
                                    {item.status.toLowerCase() === "resolve" && (
                                        <div className="tick-mark">
                                            <svg width="43" height="44" viewBox="0 0 43 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="21.5" cy="22" r="21.5" fill="#DEFFF8" />
                                                <path d="M18.2505 26.0347L14.5458 22.3301L12.9551 23.9208L18.2505 29.2162L29.1708 18.2958L27.5801 16.7051L18.2505 26.0347Z" fill="#2CAF92" />
                                            </svg>
                                        </div>
                                    )}
                                    {item.status.toLowerCase() === "reject" && (
                                        <div className="tick-mark">
                                            <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="21.5" cy="21.5" r="21.5" fill="#FFEFED" />
                                                <rect x="14.9287" y="27.3848" width="18" height="2" transform="rotate(-45 14.9287 27.3848)" fill="#B12A18" />
                                                <rect x="16.3428" y="14.6562" width="18" height="2" transform="rotate(45 16.3428 14.6562)" fill="#B12A18" />
                                            </svg>
                                        </div>
                                    )}
                                    <div className="forgot-ttl mr-auto">
                                        <div className="main-ttl">{item.reason}</div>
                                        <div className="sub-ttl d-flex">
                                            <div>Punch Dispute</div>
                                            <div className="separator">.</div>
                                            <div>Submitted {Utils.getLocalDateUtc(item.submitted1)}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex dispute-detail">
                                    <div>
                                        <div className="sec-ttl">Disputed Punch</div>
                                        <div className="day-list">
                                            <div className="ttl">{Utils.getLocalDateFromUtc(item.disputedPunche[0].disputedDate1)}</div>
                                            <div >
                                                <BootstrapTable
                                                    id={"disputed"}
                                                    keyField="1"
                                                    data={item.disputedPunche}
                                                    columns={disputedColumns(index)}
                                                    hover
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="sec-ttl">Corrected Punch</div>
                                        <div className="day-list">
                                            <div className="ttl"> {Utils.getLocalDateFromUtc(item.correctedPunche[0].correctedDate1)}</div>
                                            <div>
                                                <BootstrapTable
                                                    id={"corrected"}
                                                    keyField="2"
                                                    data={item.correctedPunche}
                                                    columns={correctedColumns(index)}
                                                    hover
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="view-shift">
                                    <button type="button" onClick={() => this.props.handleComparisonClick(-1, "All")} className="btn green-text-btn-blue">View Shift </button>
                                </div>
                                <div className="comment-sec">
                                    <Tabs defaultActiveKey="Comments" id="uncontrolled-tab-example">
                                        <Tab eventKey="Comments" title="Comments">
                                            <ul>
                                                {item.disputeComment.length > 0 && (
                                                    <>
                                                        {item.disputeComment.map((items: any) => (
                                                            <li>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="profile">
                                                                        <img src={items.imagePath} width="40" height="40" alt={items.userName} />
                                                                    </div>
                                                                    <div className="details">
                                                                        <div className="userName">{items.userName}<span> {Utils.getLocalDateTimeFromUtc(items.commentDate1)}</span></div>
                                                                        <div className="comment-text">{items.comment}</div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                        )}
                                                    </>
                                                )}
                                                {item.status.toLowerCase() === "open" && (
                                                    <li>
                                                        <div className="d-flex align-items-center">
                                                            <div className="profile">
                                                                <img src={item.logInUserImage} width="40" height="40" alt={item.logInUser} />
                                                            </div>
                                                        </div>
                                                    </li>
                                                )}
                                            </ul>
                                        </Tab>
                                        <Tab eventKey="History" title="History">
                                            <ul>
                                                {item.disputeComment.length > 0 && (
                                                    <>
                                                        <li>
                                                            <div className="d-flex align-items-center">
                                                                <div className="profile">
                                                                    <img src={item.disputeComment[0].imagePath} width="40" height="40" alt={item.disputeComment[0].userName} />
                                                                </div>
                                                                <div className="details">
                                                                    <div className="userName">{item.disputeComment[0].userName + " created the dispute"}<span> {Utils.getLocalDateTimeFromUtc(item.disputeComment[0].commentDate1)}</span></div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        {item.disputeComment.map((item: any) => (
                                                            <li>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="profile">
                                                                        <img src={item.imagePath} width="40" height="40" alt={item.userName} />
                                                                    </div>
                                                                    <div className="details">
                                                                        <div className="userName">{item.userName}<span> {Utils.getLocalDateTimeFromUtc(item.commentDate1)} </span></div>
                                                                        <div className="comment-text">{item.comment}</div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                        )}
                                                    </>
                                                )}
                                                {item.status.toLowerCase() !== "open" && (
                                                    <li>
                                                        <div className="d-flex align-items-center">
                                                            <div className="profile">
                                                                <img src={item.resolvedUserImage} width="40" height="40" alt={item.resolvedUser} />
                                                            </div>
                                                            <div className="details">
                                                                <div className="userName">{item.resolvedUser}<span> {Utils.getLocalDateTimeFromUtc(item.resolvedDate1)}</span></div>
                                                                <div className="d-flex">
                                                                    <div className="badge">Open</div>
                                                                    <div className="arrow">
                                                                        <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M9.16667 4L4.5 0V3.33333H0.5V4.66667H4.5V8L9.16667 4Z" fill="#6A6E73" />
                                                                        </svg>
                                                                    </div>
                                                                    <div className={item.status.toLowerCase() === "resolve" ? "badge color-green" : "badge color-orange"}>{item.status.toLowerCase() === "resolve" ? "Resolved" : "Rejected"}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )}
                                                {item.status.toLowerCase() !== "open" && (
                                                    <li>
                                                        <div className="d-flex align-items-center">
                                                            <div className="profile">
                                                                <img src={item.resolvedUserImage} width="40" height="40" alt={item.resolvedUser} />
                                                            </div>
                                                            <div className="details">
                                                                <div className="userName">{item.resolvedUser + " edited punch data "}<span> {Utils.getLocalDateTimeFromUtc(item.resolvedDate1)} </span></div>
                                                                <div className="action-link">
                                                                    <a href="javascript:void(0);" onClick={() => this.props.handleComparisonClick(-1, "All")}>View Punch</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )}
                                                {item.status.toLowerCase() === "open" && (
                                                    <li>
                                                        <div className="d-flex align-items-center">
                                                            <div className="profile">
                                                                <img src={item.logInUserImage} width="40" height="40" alt={item.logInUser} />
                                                            </div>
                                                        </div>
                                                    </li>
                                                )}
                                            </ul>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        )
                        )}
                    </>
                )}
            </>
        );
    }
}