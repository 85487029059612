import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { isValidChartData } from "../MetricChart";
import CustomLegends from "./CustomLegends";
import { getCommonOptions } from "./cardConfig";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);



const BarChartCard = ({ data, chartTitle, formatType,metric, isDummyData }) => {
  if (!data || !isValidChartData(data)) {
    return <></>;
  }

  const datasets = data.datasets?.map((x: any) => x);
  const commonOptions = getCommonOptions(formatType,metric,isDummyData);
  

  const barOptions:any = {
    ...commonOptions,
    categoryPercentage: 1,
    barPercentage: 0.75,
    // maintainAspectRatio:false
   
  };
  return (
    <div
      className="bar-chart-metric"
      style={{ width: "100%", margin: "0 auto" }}
    >
      <h3 className="chart-heading" style={{ whiteSpace: "pre-wrap" }}>
        <EllipsisWithTooltip placement="top">{chartTitle}</EllipsisWithTooltip>
      </h3>
      <Bar data={data} options={barOptions} />

      <CustomLegends datasets={datasets} />
    </div>
  );
};

export default BarChartCard;
