import React from "react";
import { Button,Form, Modal} from "react-bootstrap";
import { Utils } from "../../../Common/Utilis";
import { UserManagement } from "../../../Common/Services/UserManagement";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import Calendar from "react-calendar";
import moment from "moment";
import OutsideClickHandler from "react-outside-click-handler";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { DropDownList } from "../../Reports/common-components/dropdown-list";
import { IUserReviewSchedule } from "../../../Common/Contracts/IUserReviewSchedule";
import Dropzone from "react-dropzone";

export class AddPerformanceReview extends React.Component<any, any> {
  private reviewTypes: any[] = [
    { id: 1, name: "30 Day", frequency: "One Time" },
    { id: 2, name: "60 Day", frequency: "One Time" },
    { id: 3, name: "90 Day", frequency: "One Time" },
    { id: 12, name: "Custom", frequency: "Custom" },
  ];

  private performanceReviewFormSchema = Joi.object({

    reviewType: Joi.string()
      .required()
      .min(0)
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select Performance Review Type" };
        });
      }),

    startingDate: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select Date" };
        });
      }),
    scheduleId: Joi.number().allow(0),
    userUniqueno: Joi.number(),
    noOfDays: Joi.number(),
    file: Joi.object(),
    fileName: Joi.string().allow("")
  });

  state: any = {
    performanceReview: {
      reviewType: "",
      startingDate: "",
      scheduleId: 0,
      userUniqueno: this.props?.userUniqueNo,
      noOfDays: 0,
    },
    calenderShow: false,
    errors: {},
    isDataSaving: false,
    isFormNotValid: true,
    inputPosition: 0,
    reviewTypes: this.reviewTypes,
    isReviewDateLoaded: true,
    reviewDate: "",
  };

  componentDidMount = () => {
    //   this.getReviewStartDate();
  };

  resetState = () => {
    this.setState({
      performanceReview: {
        reviewType: "",
        startingDate: "",
        scheduleId: 0,
        userUniqueno: this.props?.userUniqueNo,
        noOfDays: 0,
        fileName: "",
        file: {},
      },
      calenderShow: false,
      errors: {},
      isDataSaving: false,
      isFormNotValid: true,
      inputPosition: 0,
      reviewTypes: this.reviewTypes,
      isReviewDateLoaded: true,
      reviewDate: "",
    }
    );
  }
  componentWillUnmount = () => {
    // setTimeout(()=> {
    //   toast.dismiss();
    // },1000)
  }
  onFieldChange = (event, inputPosition, isBlur = false) => {
    const { performanceReview } = this.state;
    let value = event.target.value;
    const fieldName = event.target.name;


    if (fieldName === "startingDate") {
      const _formattedDate = Utils.AddSlashINInputDateFormat(
        value,
        performanceReview?.startingDate,
        false,
        4,
        isBlur
      );
      if (_formattedDate === "invalid") {
        this.setState({ isFormNotValid: true });
        return;
      } else {
        value = _formattedDate;
      }
      //   if(value?.toString()?.length ===10){
      //     const minDate =   moment(this.getCalenderMinDate()).format("MM/DD/YYYY");
      //     if(moment(value).isBefore(minDate)
      //       ) {
      //         this.setState({isFormNotValid:true});
      //         return;
      //     }
      // }

    }

    performanceReview[fieldName] = value;

    if (fieldName === "noOfDays") {
      // let curentDate: any = JSON.parse(localStorage.storage).currentUtcTime !== "" ? new Date(
      //   JSON.parse(localStorage.storage).currentUtcTime
      // ) : new Date();

      performanceReview.startingDate = moment(new Date())?.format("MM/DD/YYYY");
      // if(value != "12"){
      //   curentDate  = moment(curentDate).add(-value, 'M');
      //   if(curentDate?.isAfter(this.props?.hireDate)){
      //     performanceReview.startingDate = curentDate?.format("MM/DD/YYYY");
      //   }else{
      //     performanceReview.startingDate = moment(new Date(this.props?.hireDate))?.format("MM/DD/YYYY");
      //   }
      // }else{
      //   performanceReview.startingDate =moment(new Date())?.format("MM/DD/YYYY");
      // }

    }
    if (inputPosition > this.state.inputPosition) {
      this.setState({ inputPosition });
    }
    this.setState({ performanceReview }, () => {
      this.validatePerformanceReviewFormSchema();
    });




    if (fieldName === "reviewType") {
      $("#ddlReviewType")?.click();
    }
  };

  savePerformanceReview = () => {
    const { reviewType,
      startingDate,
      scheduleId,
      userUniqueno,
      noOfDays,
      fileName } = this.state.performanceReview;

    const request: IUserReviewSchedule = {
      ScheduleId: scheduleId,
      UserUniqueno: userUniqueno,
      FrequencyName: "",
      ReviewType: reviewType,
      NoOfDays: noOfDays,
      StartingDate: startingDate,
      NextDueDate: "",
      HotelId: "0",
      Status: "Active",
      TenantId: 0,
      Createdby: "",
      FileCode: "0",
      FileName: fileName

    } as IUserReviewSchedule;


    request.FrequencyName = "One Time";
    if (request.NoOfDays == 12) {
      request.NextDueDate = request.StartingDate;
      request.StartingDate = null;
    } else {
      request.NextDueDate =
        +request.NoOfDays > 0
          ? moment(request.StartingDate)
            .add(request.NoOfDays, "M")
            .format("MM/DD/YYYY")
          : "";
      let futureMonthEndDate = moment(request.NextDueDate).endOf('month').format('MM/DD/YYYY');

      if (moment(futureMonthEndDate)
        .diff(request.NextDueDate, "d") === 1) {
        request.NextDueDate = futureMonthEndDate;
      }

    }
    delete request["file"];
    this.setState({ isDataSaving: true });
    UserManagement.SaveUserReviewSchedule(request, this.state.performanceReview?.file)
      .then((result) => {
        this.setState({ isDataSaving: false });
        if (result?.message === "Success") {
          toast.success(result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "labortabcontainer",
          });
          this.hideModal(true);
          $("#ddlUserReview")?.click();
        } else {
          Utils.toastError(result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "labortabcontainer",
          });
        }
      })
      .catch((error) => {
        this.setState({ isDataSaving: false });
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "addPerformanceReview",
        });
      });
  };
  validatePerformanceReviewFormSchema = () => {
    const valid = Joi.validate(
      this.state.performanceReview,
      this.performanceReviewFormSchema,
      {
        abortEarly: false,
      }
    );

    const newErrorObject = {};
    if (valid.error) {
      valid.error.details.forEach((err) => {
        newErrorObject[err.path.join(".")] = err.message;
      });
    }
    const minDate = moment(this.getCalenderMinDate()).format("MM/DD/YYYY");
    if (moment(this.state.performanceReview.startingDate).isBefore(minDate)
      || this.state.performanceReview.startingDate?.toString()?.length !== 10) {
      const minDate = moment(this.getCalenderMinDate()).format("MM/DD/YYYY");
      newErrorObject["startingDate"] = `Invalid Starting Date. It should be minimum ${minDate}`;
    } else {
      if (newErrorObject["startingDate"]) {
        delete newErrorObject["startingDate"];
      }
    }


    if (Object.keys(newErrorObject).length === 0) {
      this.setState({ isFormNotValid: false });
    } else {
      this.setState({ isFormNotValid: true });
    }
    this.setState({ errors: newErrorObject });
    return newErrorObject;
  };

  getFieldError = (fieldName: string, fieldPosition: number) => {
    const { errors, inputPosition } = this.state;

    return (
      <>
        {Object.keys(errors).length > 0 && fieldPosition <= inputPosition && (
          <span className="validation-message">{errors[fieldName]}</span>
        )}
      </>
    );
  };
  calenderHide = () => {
    this.setState({ calenderShow: false });
  };

  calenderShow = () => {
    this.setState({ calenderShow: true });
  };

  getReviewStartDate = () => {
    this.setState({ isReviewDateLoaded: false });
    UserManagement.GetReviewStartDate(this.props?.userName)
      .then((data) => {
        const performanceReview = { ...this.state.performanceReview };
        const reviewDate = data?.result?.result
          ? moment(data?.result?.result).format("MM/DD/YYYY")
          : "";
        performanceReview.startingDate =
          reviewDate || this.props?.hireDate || "";

        let reviewTypes: any[] = [];
        reviewTypes = this.reviewTypes;
        this.setState({ reviewTypes });


        this.setState({ reviewDate, performanceReview });
      })
      .finally(() => this.setState({ isReviewDateLoaded: true }));
  };

  openModal = (e) => {
    this.reset();
  };
  validationOnClick = (inputPosition) => {
    if (inputPosition > this.state.inputPosition) {
      this.setState({ inputPosition }, () => this.validatePerformanceReviewFormSchema());
    }
  };

  onFileDrop = (files) => {

    //  if(files?.length>0){
    //   return false;
    //  }

    // const fileSize = files[0]?.size / 1024 / 1024;
    // const { errors,performanceReview } = this.state;
    // if (fileSize > 5) {

    //   errors["fileName"] = "File size should not exceed 5 mb";
    //   performanceReview.file={};
    //   performanceReview.fileName="";
    //   this.setState({ errors, performanceReview,inputPosition: 3 });
    //   return;
    // }
    // else {
    //   if (errors["fileName"]) {
    //     delete errors["fileName"];
    //     this.setState({ errors });
    //   }
    // }

    this.onFieldChange(
      Utils.BuildCustomEventObject("file", files[0]),

      5
    );
    this.onFieldChange(
      Utils.BuildCustomEventObject("fileName", files[0]?.name),

      5
    );
  };
  reset = () => {
    this.setState({
      performanceReview: {
        reviewType: "",
        startingDate: "",
        scheduleId: 0,
        userUniqueno: this.props?.userUniqueNo,
        noOfDays: 0,
        fileName: "",
        file: {}
      },
      calenderShow: false,
      errors: {},
      isDataSaving: false,
      isFormNotValid: true,
      inputPosition: 0,
      reviewDate: "",
    });
  };

  getCalenderMinDate = () => {
    // let reviewDate = this.state?.reviewDate || this.props?.hireDate || "";
    let curentDate: any = JSON.parse(localStorage.storage).currentUtcTime !== "" ? new Date(
      JSON.parse(localStorage.storage).currentUtcTime
    ) : new Date();

    const performanceReview: any = this.state.performanceReview;
    if (performanceReview.noOfDays != "12") {
      curentDate = moment(curentDate).add(-performanceReview.noOfDays, 'M');
      if (curentDate?.isAfter(this.props?.hireDate)) {
        return new Date(curentDate?.format("MM/DD/YYYY"));
      } else {
        return new Date(this.props?.hireDate);
      }
    } else {
      return new Date();
    }
  };

  hideModal = (isReload = false) => {
    this.resetState();
    this.props?.hidePerformanceReviewModal(isReload);
  }

  render() {
    const {
      performanceReview: {
      
        reviewType,
    
        startingDate,
        noOfDays,
        fileName
      },
      errors,
      inputPosition,
      isFormNotValid,
      isDataSaving,
      reviewTypes,
   
      isReviewDateLoaded,
    } = this.state;
    const { addPerformanceReview, } = this.props;
    const acceptedFiles = `image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.odt,`;

    return (
      <>
        <Modal
          className="add-performance-review-modal"
          show={addPerformanceReview}
          onHide={this.hideModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Review Schedule</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-section">
              <div className="body-section">
                <Form.Group
                  controlId="type"
                  className={
                    errors?.reviewType && inputPosition >= 3
                      ? "validation-error"
                      : ""
                  }
                  onBlur={() => this.validationOnClick(3)}
                >
                  <Form.Label>Type</Form.Label>
                  {reviewTypes.length > 0 && (
                        <DropDownList
                          placeHolder={"Search Review Type"}
                          data={reviewTypes}
                          isSearchRequired={false}
                          label={"name"}
                          value={"id"}
                          onDropDownChange={(item) => {
                            if (!item) {
                              return;
                            }
                            this.onFieldChange(
                              Utils.BuildCustomEventObject(
                                "reviewType",
                                item.name
                              ),
                              3
                            );
                            this.onFieldChange(
                              Utils.BuildCustomEventObject(
                                "noOfDays",
                                item.id
                              ),
                              3
                            );
                           
                          }}
                         
                          selectedItem={[
                            ...[{ id: 0, name: "Select Review Type" }],
                            ...reviewTypes,
                          ].find((r) => +r.id === noOfDays)}
                        />
                      )}
                  

                  {this.getFieldError("reviewType", 3)}
                </Form.Group>
                {isReviewDateLoaded && (
                  <Form.Group
                    controlId="time-picker"
                    className={
                      errors?.startingDate && inputPosition >= 4
                        ? "validation-error"
                        : ""
                    }
                    onBlur={() => this.validationOnClick(4)}
                  >
                    <Form.Label>{reviewType == "Custom" ? "Date" : "Starting On"}</Form.Label>

                    <div className="validate-input d-flex flex-column">
                      <div className="calender-container">
                        <div
                          className={
                            this.state.calenderShow
                              ? "calender-display open"
                              : "calender-display"
                          }
                        >
                          <input
                            type="text"
                            onClick={this.calenderShow}
                            onFocus={this.calenderShow}
                            placeholder="MM/DD/YYYY"
                            maxLength={10}
                            onInput={(e) => {
                              let value = e.currentTarget.value;

                              value = value
                                ?.toString()
                                ?.replace(/[^0-9\/]+/gi, "");
                              this.onFieldChange(
                                Utils.BuildCustomEventObject(
                                  "startingDate",
                                  value
                                ),

                                4
                              );
                            }}
                            onBlur={(e) => {
                              let value = e.currentTarget.value;

                              value = value
                                ?.toString()
                                ?.replace(/[^0-9\/]+/gi, "");
                              this.onFieldChange(
                                Utils.BuildCustomEventObject(
                                  "startingDate",
                                  value
                                ),

                                4, true
                              );
                            }}
                            value={startingDate}
                            className="form-control single-date-pick"
                            name="dob"
                            autoComplete="dob"
                          />
                          <svg
                            onClick={() =>
                              (this.props.isEditLaborandPayrollInfo || this.props?.isNewUserAdded)
                                ? this.calenderShow()
                                : {}
                            }
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z" />
                            <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z" />
                          </svg>
                        </div>
                        {this.state.calenderShow && (
                          <div className="calender">
                            <OutsideClickHandler
                              onOutsideClick={this.calenderHide}
                            >
                              <Calendar
                                calendarType={"US"}

                                onChange={(date) => {
                                  const value =
                                    moment(date).format("MM/DD/YYYY");

                                  this.onFieldChange(
                                    Utils.BuildCustomEventObject(
                                      "startingDate",
                                      value
                                    ),

                                    4
                                  );

                                  this.calenderHide();
                                }}
                                value={
                                  startingDate
                                    ? moment(startingDate)?.format(
                                      "MM/DD/YYYY"
                                    ) === "Invalid date"
                                      ? new Date()
                                      : new Date(startingDate)
                                    : new Date()
                                }
                                minDate={this.getCalenderMinDate()}

                              />
                            </OutsideClickHandler>
                          </div>
                        )}
                      </div>

                      {this.getFieldError("startingDate", 4)}
                    </div>

                  </Form.Group>
                )}{" "}

                <Form.Group
                  //as={Row}
                  controlId="fileUpload"
                  onClick={() => this.validationOnClick(5)}
                  className={
                    errors?.fileName && inputPosition >= 5
                      ? "validation-error-file"
                      : ""
                  }
                >
                  <Form.Label>Attachments</Form.Label>

                  <Dropzone onDrop={this.onFileDrop} multiple={false} accept={acceptedFiles}>
                    {({
                      getRootProps,
                      getInputProps,
                      isDragActive,
                      isDragAccept,
                      isDragReject,
                    }) => (
                      <section className="upload-area">
                        <aside className="files">
                          <div
                            {...getRootProps({
                              className: "dropzone",
                            })}
                          >
                            <input {...getInputProps()} />
                            <div>
                              {fileName ? (
                                <EllipsisWithTooltip placement="bottom">
                                  <p>
                                    {fileName}{" "}
                                  </p>
                                </EllipsisWithTooltip>
                              ) : (
                                <p>
                                  Drag &amp; drop here or{" "}
                                  <span >browse files</span> to upload
                                </p>
                              )}
                            </div>
                          </div>
                        </aside>
                      </section>
                    )}
                  </Dropzone>
                  {this.getFieldError("fileName", 5)}
                </Form.Group>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={isFormNotValid || isDataSaving}
              className="btn btn-primary"
              onClick={this.savePerformanceReview}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
