import React from "react";
import { Dropdown, Modal, Button, Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { MultiSearchSelectUser } from "../components/MultiSearchSelectUser";
import { schConversions } from "../commonFunctions/conversions";
import { LaborSchedule } from "../../../../../Common/Services/LaborSchedule";
import { WebTimeClockService } from "../../../../../Common/Services/WebTimeClock";
import { User } from "../../../../../Common/Services/User";
import { ILaborScheduleEmployeeRequestDto, ILaborSaveScheduleDetails } from "../../../../../Common/Contracts/ILaborSchedule";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { resolve, reject } from "q";
import { Utils } from "../../../../../Common/Utilis";

let localStorageUser: any;
let timerId:any;

export class AddEditShiftModal extends React.Component<any, any> {
  static defaultProps: { component: string };
  private multiSelectUser: any;
  constructor(props: any) {
    super(props);
    this.multiSelectUser = React.createRef();
    this.state = {
      loaderTrue: false,
      schedule: {},
      scheduleAssignedType: "",
      addEditShiftValue: "",
      formEditableBlock: false,
      departmentList: [],
      departmentId: "",
      departmentSelected: "Select",
      departmentDangerWell: false,
      positionListMaster: [],
      positionList: [],
      positionSelected: "Select",
      positionId: "",
      positionDangerWell: false,
      employeeList: [],
      selectedEmployes: [],
      shiftDate: "",
      uniqueno: 0,
      dateList: [],
      dateSelected: "Select",
      dateDangerWell: false,
      inTime: "",
      inTimeDangerWell: false,
      outTime: "",
      outTimeDangerWell: false,
      earlyPunchinChecked: "",
      selectedInTime: "",
      selectedOutTime: "",
    };
  }

  componentDidMount() {
    // 
    localStorageUser = JSON.parse(localStorage.getItem("storage")!);
    let daterange: any = [];
    if (this.props.component === "webTimeClock" || this.props.component === "TimeClockOverviewOTF" || this.props.component === "laborDayOverviewSchedule") {
      let currentDate: any = new Date(this.props.parentState.displayCurrentDay);
      currentDate.setDate(currentDate.getDate());
      let mnths: any = new Date(currentDate).getMonth() + 1; // date form today only
      let days: any = new Date(currentDate).getDate();
      let year: any = new Date(currentDate)
        .getFullYear()
        .toString()
        .substring(2, 4);
      if (mnths.toString().length === 1) {
        mnths = "0" + mnths;
      }
      if (days.toString().length === 1) {
        days = "0" + days;
      }
      let tempdate1 = `${mnths}/${days}/${year}`;
      daterange.push(tempdate1);
      this.setState({ dateSelected: tempdate1 });
    } else {
      let startDate: any = new Date(
        this.props.parentState.navigationCalenderDates[0]
      );
      for (let i = 0; i < 7; i++) {
        let currentDate: any = new Date(startDate);
        currentDate.setDate(currentDate.getDate() + i);
        let mnths: any = new Date(currentDate).getMonth() + 1; // date form today only
        let days: any = new Date(currentDate).getDate();
        let year: any = new Date(currentDate)
          .getFullYear()
          .toString()
          .substring(2, 4);

        if (mnths.toString().length === 1) {
          mnths = "0" + mnths;
        }
        if (days.toString().length === 1) {
          days = "0" + days;
        }
        let tempdate1 = `${mnths}/${days}/${year}`;
        let lockDays = this.props.parentState.lockDays?.length;
        // let compareDate = new Date(tempdate1);
        if (i >= lockDays) {
          daterange.push(tempdate1);
        }
      }
    }

    let addEdiShiftValues = this.props.parentState.addEdiShiftValues;
    //this.setState({ earlyPunchinChecked: addEdiShiftValues.selectedSchedule.earlyPunchin })
    if (addEdiShiftValues.selectedSchedule !== "addSchedule") {
      let parentHeaderDates = [...this.props.parentState.headerDates];

      let indexOfDay = Number(
        addEdiShiftValues.selectedDay.slice(
          addEdiShiftValues.selectedDay.length - 1,
          addEdiShiftValues.selectedDay.length
        ) - 1
      );

      let dateOfIndex = parentHeaderDates[indexOfDay];
      let selectedDateValue: any = "";
      for (let i = 0; i < daterange.length; i++) {
        let data = daterange[i];
        if (data.substring(0, 5) === dateOfIndex) {
          selectedDateValue = data;
          break;
        }
      }
      if (selectedDateValue === "") {
        selectedDateValue = "Select";
      }
      this.setState({
        dateSelected: selectedDateValue,
        uniqueno: addEdiShiftValues.selectedSchedule.uniqueno,
        shiftDate: addEdiShiftValues.selectedSchedule.shiftDate,
      });
      if (addEdiShiftValues.formType !== "add") {
        let employeeSelection = [...this.state.selectedEmployes];
        let employeeList = [...this.state.employeeList];
        let selectedPushObject: any = {};
        selectedPushObject.employee =
          addEdiShiftValues.selectedRow.employeeName;
        selectedPushObject.value = 1;
        selectedPushObject.isChecked = true;
        if (
          addEdiShiftValues.selectedRow.employeeName !== "Unassigned Shifts" &&
          addEdiShiftValues.selectedRow.employeeName !== undefined
        ) {
          employeeSelection.push(selectedPushObject);
        }
        this.setState(
          {
            selectedEmployes: employeeSelection,
            employeeList: employeeList,
            addEditShiftValue: addEdiShiftValues.formType,
            earlyPunchinChecked: addEdiShiftValues.selectedSchedule.earlyPunchin
          },
          () => {
            this.getEmployeeData();
            this.multiSelectUser.current.updateFromParentList(this.state);
          }
        );

        if (addEdiShiftValues.selectedSchedule.shiftPosition !== "+") {
          let inTime = addEdiShiftValues.selectedSchedule.shiftInTime;
          let outTime = addEdiShiftValues.selectedSchedule.shiftOutTime;
          if (inTime !== "") {
            let tempInTime = inTime;
            const [time, modifier] = tempInTime.split(" ");
            let [hours, minutes] = time.split(":");
            if (hours === "12") {
              hours = "00";
            }
            if (modifier === "PM") {
              hours = parseInt(hours, 10) + 12;
            }
            if (hours.length === 1) {
              hours = "0" + hours;
            }
            inTime = `${hours}:${minutes}`;
          }
          if (outTime !== "") {
            let tempOutTime = outTime;
            const [time, modifier] = tempOutTime.split(" ");
            let [hours, minutes] = time.split(":");
            if (hours === "12") {
              hours = "00";
            }
            if (modifier === "PM") {
              hours = parseInt(hours, 10) + 12;
            }
            if (hours.length === 1) {
              hours = "0" + hours;
            }
            outTime = `${hours}:${minutes}`;
          }

          let editdeptId: any = addEdiShiftValues.selectedSchedule.deptId;
          let editdeptName: any;
          let departmentNameFilter = this.props.parentState.departmentList.filter(
            (items: any) => Number(items.id) === Number(editdeptId)
          );
          if (departmentNameFilter != null) {
            if (departmentNameFilter.length > 0) {
              editdeptName = departmentNameFilter[0].name;
            }
          }

          this.setState({
            inTime: inTime,
            outTime: outTime,
            selectedInTime: inTime,
            selectedOutTime: outTime,
            departmentSelected: editdeptName,
            departmentId: Number(addEdiShiftValues.selectedSchedule.deptId),
            positionSelected: addEdiShiftValues.selectedSchedule.shiftPosition,
            positionId: Number(addEdiShiftValues.selectedSchedule.positionId),
            earlyPunchinChecked: addEdiShiftValues.selectedSchedule.earlyPunchin
          });
        }
      }

      if (addEdiShiftValues.formType === "add") {
        let employeeSelection = [...this.state.selectedEmployes];
        let employeeList = [...this.state.employeeList];
        let selectedPushObject: any = {};
        selectedPushObject.employee =
          addEdiShiftValues.selectedRow.employeeName;
        selectedPushObject.value = 1;
        selectedPushObject.isChecked = true;
        if (
          addEdiShiftValues.selectedRow.employeeName !== "Unassigned Shifts" &&
          addEdiShiftValues.selectedRow.employeeName !== undefined
        ) {
          employeeSelection.push(selectedPushObject);
        }
        this.setState(
          {
            selectedEmployes: employeeSelection,
            employeeList: employeeList,
            addEditShiftValue: addEdiShiftValues.formType,
          },
          () => {
            this.getEmployeeData();
            this.multiSelectUser.current.updateFromParentList(this.state);
          }
        );
      }
    }
    // Removing all from position and department list
    let departmentListArray = [...this.props.parentState.departmentList];
    let positionListArray = [...this.props.parentState.positionList];
    if (departmentListArray.length > 0) {
      if (departmentListArray[0].id === -1) {
        departmentListArray.shift();
      }
    }
    if (positionListArray.length > 0) {
      if (positionListArray[0].id === -1) {
        positionListArray.shift();
      }
    }
    this.setState(
      {
        dateList: daterange,
        addEditShiftType: addEdiShiftValues.addEditShiftValue,
        schedule: addEdiShiftValues.selectedSchedule,
        scheduleAssignedType: addEdiShiftValues.scheduleAssignedType,
        departmentList: departmentListArray,
        positionListMaster: positionListArray,
      },
      () => {
        if (this.props.parentState.viewType !== "Users") {
          if (this.state.addEditShiftValue === "add") {
            if (this.props.parentState.viewType === "Positions") {
              let positionId = "";
              let positionName = "";
              if (addEdiShiftValues.selectedSchedule.positionId === "No") {
                positionId = addEdiShiftValues.selectedRow.posId;
                let posName = this.props.parentState.positionList.filter(
                  (items: any) => Number(items.id) === Number(positionId)
                );
                if (posName.length > 0) {
                  positionName = posName[0].name;
                }
              } else {
                positionId = addEdiShiftValues.selectedSchedule.positionId;
                positionName = addEdiShiftValues.selectedSchedule.shiftPosition;
                if (positionName === "+") {
                  let posName = this.props.parentState.positionList.filter(
                    (items: any) => Number(items.id) === Number(positionId)
                  );

                  positionName = posName[0].name;
                }
              }

              let editdeptId: any = null;
              editdeptId = addEdiShiftValues.selectedSchedule.deptId;
              if (editdeptId === "No") {
                if (addEdiShiftValues.selectedRow.deptId === undefined) {
                  editdeptId = addEdiShiftValues.selectedRow.deptID;
                } else {
                  editdeptId = addEdiShiftValues.selectedRow.deptId;
                }
              }
              let editdeptName: any;

              let departmentNameFilter = this.props.parentState.departmentList.filter(
                (items: any) => Number(items.id) === Number(editdeptId)
              );
              if (departmentNameFilter != null) {
                if (departmentNameFilter.length > 0) {
                  editdeptName = departmentNameFilter[0].name;
                }
              }

              this.setState(
                {
                  positionId: Number(positionId),
                  positionSelected: positionName,
                  departmentSelected: editdeptName,
                },
                () => {
                  let editdeptId: any = null;
                  editdeptId = addEdiShiftValues.selectedSchedule.deptId;
                  if (editdeptId === "No") {
                    if (addEdiShiftValues.selectedRow.deptId === undefined) {
                      editdeptId = addEdiShiftValues.selectedRow.deptID;
                    } else {
                      editdeptId = addEdiShiftValues.selectedRow.deptId;
                    }
                  }
                  let departmentNameFilter = this.props.parentState.departmentList.filter(
                    (items: any) => Number(items.id) === Number(editdeptId)
                  );
                  departmentNameFilter.map((item: any, idx: any) =>
                    this.bindPositionList(item)
                  );
                }
              );
            } else {
              let editdeptId: any = null;
              editdeptId = addEdiShiftValues.selectedSchedule.deptId;
              if (editdeptId === "No") {
                if (addEdiShiftValues.selectedRow.deptId === undefined) {
                  editdeptId = addEdiShiftValues.selectedRow.deptID;
                } else {
                  editdeptId = addEdiShiftValues.selectedRow.deptId;
                }
              }
              let departmentNameFilter = this.props.parentState.departmentList.filter(
                (items: any) => Number(items.id) === Number(editdeptId)
              );
              departmentNameFilter.map((item: any, idx: any) =>
                this.bindPositionList(item)
              );
            }
          }
          if (this.state.addEditShiftValue !== "add") {
            if (this.props.parentState.viewType === "Positions") {
              let positionId = "";
              let positionName = "";
              if (addEdiShiftValues.selectedSchedule.positionId !== "No") {
                positionId = addEdiShiftValues.selectedSchedule.positionId;
                let posName = this.props.parentState.positionList.filter(
                  (items: any) => Number(items.id) === Number(positionId)
                );
                if (posName.length > 0) {
                  positionName = posName[0].name;
                }
              } else {
                positionId = addEdiShiftValues.selectedRow.posId;
                let posName = this.props.parentState.positionList.filter(
                  (items: any) => Number(items.id) === Number(positionId)
                );
                positionName = posName[0].name;
              }

              let editdeptId: any = null;
              editdeptId = addEdiShiftValues.selectedSchedule.deptId;
              if (editdeptId === "No" || editdeptId === "") {
                if (addEdiShiftValues.selectedRow.deptId === undefined) {
                  editdeptId = addEdiShiftValues.selectedRow.deptID;
                } else {
                  editdeptId = addEdiShiftValues.selectedRow.deptId;
                }
              }
              let editdeptName: any;

              let departmentNameFilter = this.props.parentState.departmentList.filter(
                (items: any) => Number(items.id) === Number(editdeptId)
              );
              if (departmentNameFilter != null) {
                if (departmentNameFilter.length > 0) {
                  editdeptName = departmentNameFilter[0].name;
                }
              }

              if (positionName !== "") {
                this.setState({
                  positionSelected: positionName,
                  earlyPunchinChecked: addEdiShiftValues.selectedSchedule.earlyPunchin
                });
              }
              if (this.state.addEditShiftValue !== "") {
                this.setState(
                  {
                    positionId: Number(positionId),
                    departmentSelected: editdeptName,
                    earlyPunchinChecked: addEdiShiftValues.selectedSchedule.earlyPunchin
                  },
                  () => {
                    let editdeptId: any = null;
                    editdeptId = addEdiShiftValues.selectedSchedule.deptId;
                    if (editdeptId === "") {
                      editdeptId = addEdiShiftValues.selectedRow.deptId;
                    }
                    let departmentNameFilter = this.props.parentState.departmentList.filter(
                      (items: any) => Number(items.id) === Number(editdeptId)
                    );
                    departmentNameFilter.map((item: any, idx: any) =>
                      this.bindPositionList(item)
                    );
                  }
                );
              }
            } else {
              let editdeptId: any = null;
              editdeptId = addEdiShiftValues.selectedSchedule.deptId;
              if (editdeptId === "") {
                editdeptId = addEdiShiftValues.selectedRow.deptId;
              }
              let departmentNameFilter = this.props.parentState.departmentList.filter(
                (items: any) => Number(items.id) === Number(editdeptId)
              );
              departmentNameFilter.map((item: any, idx: any) =>
                this.bindPositionList(item)
              );
            }
          }
        } else {
          let editdeptId: any = null;
          editdeptId = addEdiShiftValues.selectedSchedule.deptId;
          if (editdeptId === "") {
            editdeptId = addEdiShiftValues.selectedRow.deptId;
          }
          let departmentNameFilter = this.props.parentState.departmentList.filter(
            (items: any) => Number(items.id) === Number(editdeptId)
          );
          departmentNameFilter.map((item: any, idx: any) =>
            this.bindPositionList(item)
          );
        }
      }
    );

    // Setting the editable and non editable form properties
    let editableStatus = this.props.parentState.addEdiShiftValues.editableStatus;
    if (editableStatus === "canNotEdit") {
      this.setState({ formEditableBlock: true });
    } else {
      this.setState({ formEditableBlock: false });
    }
  }

  addEditShiftModalClose = () => {
    this.props.addEditShiftModalHide();
    this.setState({ earlyPunchinChecked: '' })
  };

  employeeSelection = (childState: any) => {
    // 
    this.setState({ selectedEmployes: childState.selectedItems });
  };

  multiSelectErrorValidation = () => {
    if (
      this.state.dateSelected === "Select" ||
      this.state.dateSelected === ""
    ) {
      this.setState({ dateDangerWell: true });
    }
    if (this.state.inTime === "") {
      this.setState({ inTimeDangerWell: true });
    }
    if (this.state.outTime === "") {
      this.setState({ outTimeDangerWell: true });
    }
  };

  bindPositionList = (item: any) => {
    this.setState({ departmentDangerWell: false, });

    let positionListdata: any = [];
    let positionFilterDetails = this.state.positionListMaster.filter(
      (Positem: any) => Positem.deptid === item.id
    );
    positionFilterDetails.forEach((res: any) => {
      let posobject: any = {};
      posobject.id = res.id;
      posobject.name = res.name;
      positionListdata.push(posobject);
    });
    if (this.state.positionId !== "") {
      if (this.state.departmentSelected === item.name) {
        this.setState({
          positionList: positionListdata,
          departmentSelected: item.name,
          departmentId: item.id,
        });
      } else {
        if (this.props.parentState.viewType === "Positions") {
          if (this.state.departmentSelected === item.name) {
            this.setState({
              positionList: positionListdata,
              departmentSelected: item.name,
              departmentId: item.id,
            });
          } else {
            this.setState({
              positionList: positionListdata,
              departmentSelected: item.name,
              departmentId: item.id,
              positionSelected: "Select",
              positionId: "",
            });
          }
        } else {
          this.setState({
            positionList: positionListdata,
            departmentSelected: item.name,
            departmentId: item.id,
            positionSelected: "Select",
            positionId: "",
          });
        }
      }
    } else {
      this.setState({
        positionList: positionListdata,
        departmentSelected: item.name,
        departmentId: item.id,
        positionSelected: "Select",
        positionId: "",
      });
    }
  };

  positionChange = (item: any) => {
    // if (this.props.component === "schedule") {
    //   if (this.props.parentState.positionList !== null && this.props.parentState.positionList !== undefined) {
    //     let positionList = this.props.parentState.positionList;
    //     let hasEarlyPunch = positionList.filter((items: any) => (items.id === item.id));
    //     let checkResult = hasEarlyPunch[0].earlyPunch;
    //     this.setState({ earlyPunchinChecked: checkResult })
    //   }
    // }
    this.setState({
      positionSelected: item.name,
      positionId: item.id,
      positionDangerWell: false,
    });
  };

  // onEarlyPunchin = (e: any) => {
  //   let { checked } = e.target;
  //   this.setState({ earlyPunchinChecked: checked ? "Yes" : "No" })
  // }

  importFromListSelect = (item: any, selectType: any) => {
    if (selectType === "date") {
      this.setState(
        {
          dateSelected: item,
          dateDangerWell: false,
        },
        () => {
          this.getEmployeeData();
        }
      );
    } else {
      return false;
    }
  };

  getEmployeeData = () => {
    // 
    if (
      this.state.dateSelected === "Select" ||
      this.state.dateSelected === "" ||
      this.state.inTime === "" ||
      this.state.outTime === ""
    ) {
      return false;
    } else {
      this.setState({ loaderTrue: true });

      let request = {} as ILaborScheduleEmployeeRequestDto;

      request.inTime = schConversions.twentyFourToTwelveConvert(
        this.state.inTime
      );

      request.outTime = schConversions.twentyFourToTwelveConvert(
        this.state.outTime
      );

      request.hotelID = this.props.parentState.hidValue;
      request.date = this.state.dateSelected;
      request.type = "add";
      request.position = this.state.positionId;
      request.shiftDate = this.state.dateSelected;
      request.uniqueno = 0;
      if (this.state.positionId === "Select" || this.state.positionId === "") {
        request.position = -1;
      }

      if (
        this.state.addEditShiftValue === "edit" &&
        this.state.shiftDate !== ""
      ) {
        request.uniqueno = this.state.uniqueno;
        request.shiftDate = this.state.shiftDate;
      }

      if (this.props.component === "webTimeClock") {
        WebTimeClockService.GetLaborScheduleEmployee(request)
          .then(async (result: any) => {
            this.setState({ employeeList: result.result, loaderTrue: false });
            resolve();
          })
          .catch((error: any) => {
            this.setState({ loaderTrue: false });
            reject();
          });
      } else {
        LaborSchedule.GetLaborScheduleEmployee(request)
          .then(async (result: any) => {
            this.setState({ employeeList: result.result, loaderTrue: false });
            resolve();
          })
          .catch((error: any) => {
            this.setState({ loaderTrue: false });
            reject();
          });
      }
    }
  };

  inTimeChange = (e: any) => {
    this.setState(
      {
        inTime: e.target.value,
        inTimeDangerWell: false,
        outTimeDangerWell: false,
      }
    );
    this.debounceFunction(this.getEmployeeData,500)
  };

  
  outTimeChange = (e: any) => {
    this.setState(
      {
        outTime: e.target.value,
        inTimeDangerWell: false,
        outTimeDangerWell: false,
      }
    );
    this.debounceFunction(this.getEmployeeData,500)
  };

  debounceFunction  =  (func, delay) =>{
    clearTimeout(timerId)
    timerId  =  setTimeout(func, delay)
  }

  // onOutTimeKeyUp = (e: any) => {
  //   this.setState(
  //     {
  //       outTime: e.target.value,
  //       inTimeDangerWell: false,
  //       outTimeDangerWell: false,
  //     },
  //     () => {
  //       this.getEmployeeData();
  //     }
  //   );
  // };

  Isvalidate = () => {
    // 
    let isValid = true;
    let toastMessage: string = "";
    let toastContainerId = "scheduleMain";
    if (this.props.component === "webTimeClock") {
      toastContainerId = "timeClockIndex";
    } else if (this.props.component === "TimeClockOverviewOTF") {
      toastContainerId = "laborDayOverviewContainer";
    } else if (this.props.component === "laborDayOverviewSchedule") {
      toastContainerId = "laborDayOverviewContainer";
    }
    if ((this.props.component === "webTimeClock" || this.props.component === "TimeClockOverviewOTF") &&
      this.state.selectedEmployes.length === 0
    ) {
      isValid = false;
      toastMessage = "Please Select an User";
    }

    if (this.state.inTime !== "" && this.state.outTime !== "") {
      if (this.state.outTime === this.state.inTime) {
        this.setState({ outTimeDangerWell: true, inTimeDangerWell: true });
        isValid = false;
        toastMessage = "Start Time and End Time can not be same";
      }
    }

    if (this.state.inTime === "" || this.state.outTime === "") {
      if (this.state.outTime === "") {
        this.setState({ outTimeDangerWell: true });
        isValid = false;
        toastMessage = "Please select the End Time";
      }
      if (this.state.inTime === "") {
        this.setState({ inTimeDangerWell: true });
        isValid = false;
        toastMessage = "Please select the Start Time";
      }
    }

    if (
      this.state.dateSelected === "Select" ||
      this.state.dateSelected === ""
    ) {
      this.setState({ dateDangerWell: true });
      isValid = false;
      toastMessage = "Please select the Date";
    }

    if (this.state.positionId === "Select" || this.state.positionId === "") {
      this.setState({ positionDangerWell: true });
      isValid = false;
      toastMessage = "Please select the Position";
    }

    if (
      this.state.departmentId === "Select" ||
      this.state.departmentId === ""
    ) {
      this.setState({ departmentDangerWell: true });
      isValid = false;
      toastMessage = "Please select the Department";
    }

    if (isValid === false) {
      Utils.toastError(toastMessage, {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: toastContainerId,
      });
    }

    return isValid;
  };

  addEditShiftSave = () => {
    // /
    let isValid = this.Isvalidate();
    if (!isValid) {
      return false;
    } else {
      let employeeList: any = [];
      this.state.selectedEmployes.forEach((data: any) =>
        employeeList.push(data.employee)
      );
      let employeeListString = employeeList.join(",");
      let requestItem = {} as ILaborSaveScheduleDetails;
      if (this.state.schedule !== "addSchedule" && this.state.schedule.uniqueno !== "") {
        requestItem.scheduleId = parseInt(this.state.schedule.uniqueno);
      } else {
        requestItem.scheduleId = 0;
      }
      let addEdiShiftValues = this.props.parentState.addEdiShiftValues;
      if (addEdiShiftValues.formType === "add") {
        requestItem.scheduleId = 0;
      }
      requestItem.hotelID = this.props.parentState.hidValue;
      if (this.props.component === "webTimeClock"
        //|| this.props.component === "TimeClockOverviewOTF"
      ) {
        let timeClockTemp = JSON.parse(localStorage.getItem("timeClockTemp")!);
        let tenantID = timeClockTemp === null ? 0 : (timeClockTemp.tenantID as any);
        requestItem.tenantID = tenantID;
      } else {
        requestItem.tenantID = localStorageUser.tenantID;
      }
      requestItem.departmentID = this.state.departmentId;
      requestItem.positionID = this.state.positionId;
      requestItem.date = this.state.dateSelected;
      requestItem.inTime = schConversions.twentyFourToTwelveConvert(
        this.state.inTime
      );
      requestItem.outTime = schConversions.twentyFourToTwelveConvert(
        this.state.outTime
      );
      requestItem.notes = "";
      if (this.props.component === "webTimeClock") {
        let timeClockTemp = JSON.parse(localStorage.getItem("timeClockTemp")!);
        let user_UniqueID = timeClockTemp === null ? 0 : (timeClockTemp.user_UniqueID as any);
        requestItem.LoggedInUser = user_UniqueID;
        requestItem.entryType = "webTimeClock";
      } else if (this.props.component === "TimeClockOverviewOTF") {
        requestItem.entryType = "webTimeClock";
      } else {
        requestItem.entryType = "Schedule";
      }
      requestItem.inDate = this.state.dateSelected;
      requestItem.outDate = this.state.dateSelected;
      requestItem.isAssignedShift = "Yes";
      if (employeeListString === "") {
        requestItem.isAssignedShift = "No";
      }
      requestItem.isPublishShift = this.state.schedule.shiftType === "published" || this.state.schedule.shiftType === "hideShowPublished" ? "Yes" : "No";
      requestItem.employeeName = employeeListString === "" ? "" : employeeListString;
      requestItem.offRepeat = "No";
      requestItem.ptoType = -1;
      if (this.props.component === "webTimeClock" || this.props.component === "TimeClockOverviewOTF") {
        requestItem.weekStartDate = this.props.parentState.weekStartDate;
        requestItem.weekEndDate = this.props.parentState.weekEndDate;
      } else {
        requestItem.weekStartDate = this.props.parentState.navigationCalenderDates[0];
        requestItem.weekEndDate = this.props.parentState.navigationCalenderDates[1];
      }
      if (this.state.addEditShiftValue === "edit" && this.state.shiftDate !== "") {
        let addEdiShiftValues = this.props.parentState.addEdiShiftValues;
        if (addEdiShiftValues.selectedRow.employeeName !== "Unassigned Shifts") {
          requestItem.editEmployee = addEdiShiftValues.selectedRow.employeeName;
        } else {
          requestItem.editEmployee = "";
        }
        requestItem.editDate = this.state.shiftDate;
      } else {
        requestItem.editDate = this.state.dateSelected;
        requestItem.editEmployee = "";
      }
      requestItem.EarlyPunch = this.props.component === "schedule" ? this.state.earlyPunchinChecked : "No";
      requestItem.PageSource = this.props.parentState.isPageSource;
      let requestArray: any = [];
      requestArray.push(requestItem);
      if (this.props.component === "webTimeClock") {
        this.setState({ loaderTrue: true });
        WebTimeClockService.SaveLaborScheduleDetails(requestArray)
          .then(async (result: any | null) => {
            if (result != null && result.saveStatus === "Success") {
              this.props.addEditShiftModalHide();
              toast.success(result.message, {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "timeClockIndex",
              });
              this.props.sendEmail(
                requestItem.employeeName,
                this.state.dateSelected
              );
            } else {
              Utils.toastError(result.message, {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "timeClockIndex",
              });
            }
            this.setState({ loaderTrue: false });
            resolve();
          })
          .catch((err: any) => {
            Utils.toastError(`Server Error, ${err}`, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "timeClockIndex",
            });
            this.setState({ loaderTrue: false });
            reject();
          });
      } else if (this.props.component === "TimeClockOverviewOTF") {
        User.SaveUserLaborScheduleDetails(requestArray).then(async (result: any | null) => {
          if (result != null && result.saveStatus === "Success") {
            this.props.addEditShiftModalHide();
            toast.success(result.message, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "laborDayOverviewContainer",
            });
            this.props.getLaborManagerTimeClockData();
          } else {
            Utils.toastError(result.message, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "laborDayOverviewContainer",
            });
          }
          this.setState({ loaderTrue: false });
          resolve();
        })
          .catch((err: any) => {
            Utils.toastError(`Server Error, ${err}`, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "laborDayOverviewContainer",
            });
            this.setState({ loaderTrue: false });
            reject();
          });
      }
      else {
        // 
        this.setState({ loaderTrue: true });
        let cntrId = this.props.component === "laborDayOverviewSchedule" ? "laborDayOverviewContainer" : "scheduleMain"
        LaborSchedule.SaveLaborScheduleDetails(requestArray).then(async (result: any | null) => {
          if (result != null && result.saveStatus === "Success") {
            // 
            this.addEditShiftModalClose();
            toast.success(result.message, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: cntrId,
            });
            if (this.props.component === "laborDayOverviewSchedule") {
              this.props.getLaborManagerTimeClockData();
              return;
            } else {
              // 
              this.props.addShiftRefresh();

              let addEdiShiftValues = this.props.parentState.addEdiShiftValues;
              if (addEdiShiftValues.formType === "edit") {
                if (Number(this.state.departmentId) !== Number(addEdiShiftValues.selectedSchedule.deptId)) {
                  this.props.isPublishOrNot(true);
                } else if (Number(this.state.positionId) !== Number(addEdiShiftValues.selectedSchedule.positionId)) {
                  this.props.isPublishOrNot(true);
                } else if (this.state.inTime !== this.state.selectedInTime) {
                  this.props.isPublishOrNot(true);
                } else if (this.state.outTime !== this.state.selectedOutTime) {
                  this.props.isPublishOrNot(true);
                } else if (this.state.dateSelected !== addEdiShiftValues.selectedSchedule.shiftDate) {
                  this.props.isPublishOrNot(true);
                } else {
                  if (this.state.selectedEmployes.length > 1) {
                    this.props.isPublishOrNot(true);
                  } else {
                    if (this.state.selectedEmployes.length === 1) {
                      if (this.state.selectedEmployes[0].employee !== addEdiShiftValues.selectedRow.employeeName) {
                        this.props.isPublishOrNot(true);
                      }
                    }

                    if (this.state.selectedEmployes.length === 0 && addEdiShiftValues.selectedRow.employeeName !== "Unassigned Shifts") {

                      this.props.isPublishOrNot(true);

                    }

                  }

                }
              }
              if (addEdiShiftValues.formType !== "edit") {
                if (this.state.selectedEmployes.length >= 1) {
                  this.props.isPublishOrNot(true);
                }
              }


            }


          } else {
            Utils.toastError(result.message, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: cntrId,
            });
          }
          this.setState({ loaderTrue: false });
          resolve();
        })
          .catch((err: any) => {
            Utils.toastError(`Server Error, ${err}`, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: cntrId,
            });
            this.setState({ loaderTrue: false });
            reject();
          });
      }
    }
  };

  renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div className="p-1 text-center">This will allow employees to punch in early to this shift and override any early punch restrictions.</div>
    </Tooltip>
  );

  render() {
    return (
      <div>
        <Modal
          className="add-edit-shift-modal SOS"
          show={this.props.parentState.addEditShiftModal}
          onHide={this.addEditShiftModalClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.component === "webTimeClock" || this.props.component === "TimeClockOverviewOTF" ? "Schedule Employee" :
                this.state.addEditShiftValue === "edit" && this.state.uniqueno === "" ? "Add Shift" : this.state.addEditShiftValue === "add" ||
                  this.state.addEditShiftValue === "" ? "Add Shift" : "Edit Shift"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="dept-pos d-flex">
              <Form.Group controlId="select-department">
                <Form.Label>
                  Department <b>*</b>
                </Form.Label>
                <Dropdown
                  className={
                    this.state.formEditableBlock
                      ? "import-from-dropdown disabled p-none"
                      : this.state.departmentSelected === "Select"
                        ? "import-from-dropdown select"
                        : "import-from-dropdown"
                  }
                >
                  <Dropdown.Toggle
                    id="dropdown-importFrom"
                    className={
                      this.state.departmentDangerWell ? "alert-danger" : ""
                    }
                  >
                    {this.state.departmentSelected === "Select" ? (
                      <span className="placeholder">Select Department</span>
                    ) : (
                      <span className="selected">
                        {this.state.departmentSelected}
                      </span>
                    )}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div>
                      {this.state.departmentList.map((item: any, idx: any) => (
                        <Dropdown.Item
                          key={idx}
                          eventKey={item.id}
                          onClick={() => this.bindPositionList(item)}
                        >
                          {item.name}
                        </Dropdown.Item>
                      ))}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>

              <Form.Group controlId="select-position">
                <Form.Label>
                  Position <b>*</b>
                </Form.Label>
                <Dropdown
                  className={
                    this.state.formEditableBlock
                      ? "import-from-dropdown disabled p-none"
                      : this.state.departmentSelected === "Select"
                        ? "import-from-dropdown disabled p-none"
                        : this.state.positionSelected === "Select"
                          ? "import-from-dropdown select"
                          : "import-from-dropdown"
                  }
                >
                  <Dropdown.Toggle
                    id="dropdown-importFrom"
                    className={
                      this.state.positionDangerWell ? "alert-danger" : ""
                    }
                  >
                    {this.state.positionSelected === "Select" ? (
                      <span className="placeholder">Select a Position</span>
                    ) : (
                      <span className="selected">
                        {this.state.positionSelected}
                      </span>
                    )}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div>
                      {this.state.positionList.map((item: any, idx: any) => (
                        <Dropdown.Item
                            key={idx}
                            eventKey={item.id}
                            onClick={() => this.positionChange(item)}
                          >
                          <EllipsisWithTooltip placement="bottom">{item.name}</EllipsisWithTooltip>
                            
                        </Dropdown.Item>
                      ))}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
                
              </Form.Group>
            </div>

            <div className="day-date-time d-flex">
              <div className="date">
                <Form.Group controlId="date-selection">
                  <Form.Label>
                    Date <b>*</b>
                  </Form.Label>
                  <Dropdown
                    className={
                      this.state.formEditableBlock
                        ? "import-from-dropdown disabled p-none"
                        : this.props.component === "webTimeClock" || this.props.component === "TimeClockOverviewOTF"
                          ? "import-from-dropdown p-none"
                          : "import-from-dropdown"
                    }
                  >
                    <Dropdown.Toggle
                      id="dropdown-importFrom"
                      className={
                        this.state.dateDangerWell ? "alert-danger" : ""
                      }
                    >
                      <span
                        className={
                          this.props.component === "webTimeClock" || this.props.component === "TimeClockOverviewOTF"
                            ? "placeholder"
                            : this.state.dateSelected === "Select"
                              ? "placeholder"
                              : "selected"
                        }
                      >
                        {this.state.dateSelected}
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div>
                        {this.state.dateList.map((item: any, idx: any) => (
                          <Dropdown.Item
                            key={idx}
                            eventKey={item.id}
                            onClick={() =>
                              this.importFromListSelect(item, "date")
                            }
                          >
                            {item}
                          </Dropdown.Item>
                        ))}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </Form.Group>
              </div>
              <div className="start-end-time d-flex">
                <Form.Group controlId="start-time">
                  <Form.Label>
                    Start Time <b>*</b>
                  </Form.Label>
                  <input
                    className={
                      this.state.formEditableBlock
                        ? "html-time-picker disabled p-none"
                        : this.state.inTimeDangerWell
                          ? "html-time-picker alert-danger"
                          : "html-time-picker"
                    }
                    type="time"
                    id="startTime"
                    name="startTime"
                    value={this.state.inTime}
                    onChange={(e: any) => this.inTimeChange(e)}
                  />
                </Form.Group>

                <Form.Group controlId="end-time">
                  <Form.Label>
                    End Time <b>*</b>
                  </Form.Label>
                  <input
                    className={
                      this.state.outTimeDangerWell
                        ? "html-time-picker alert-danger"
                        : "html-time-picker"
                    }
                    type="time"
                    id="endTime"
                    name="endTime"
                    value={this.state.outTime}
                    onChange={(e: any) => this.outTimeChange(e)}
                    // onKeyUp={(e: any) => this.onOutTimeKeyUp(e)}
                  />
                </Form.Group>
              </div>
            </div>

            <div className="bottomSepetatorLine"></div>

            <div
              className={
                this.state.formEditableBlock
                  ? "select-users disabled p-none"
                  : "select-users"
              }
            >
              <Form.Group controlId="set-Forecast">
                <Form.Label>User</Form.Label>
                <MultiSearchSelectUser
                  ref={this.multiSelectUser}
                  conflictCheck="Schedule"
                  parentState={this.state}
                  employeeSelection={this.employeeSelection}
                  multiSelectErrorValidation={this.multiSelectErrorValidation}
                />
              </Form.Group>
            </div>
            {/* {this.props.component === "schedule" && (
              <div className="scheduleEarlyPunchin">
                <Form.Check
                  id="rowIndex"
                  key="rowIndex"
                  type="switch"
                  label="Allow early punch-in"
                  disabled={this.state.formEditableBlock || this.state.departmentSelected === "Select" || this.state.positionSelected === "Select"}
                  checked={this.state.earlyPunchinChecked !== "" && this.state.earlyPunchinChecked.toString().toLowerCase() === "Yes".toLowerCase() ? true : false}
                  onChange={(e: any) => this.onEarlyPunchin(e)}
                />
                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={this.renderTooltip}>
                  <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                    <path d="M464 336a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm72 112h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V456c0-4.4-3.6-8-8-8z"></path>
                  </svg>
                </OverlayTrigger>
              </div>
            )} */}

          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={this.state.loaderTrue}
              className="import-forecast-button"
              onClick={() => this.addEditShiftSave()}
            >
              {this.state.addEditShiftValue === "edit" &&
                this.state.uniqueno === ""
                ? "Add Shift"
                : this.state.addEditShiftValue === "add" ||
                  this.state.addEditShiftValue === ""
                  ? "Add Shift"
                  : "Save Changes"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

AddEditShiftModal.defaultProps = {
  component: "schedule",
};