import React from "react";
import { Redirect } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ReactPageLoader } from "../../../../Common/Components/ReactPageLoader";
import BootstrapTable from "react-bootstrap-table-next";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import "react-confirm-alert/src/react-confirm-alert.css";
let payRollColumns: any;
export class PayrollExportOverview extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      tableData: this.props.parentState.payrollExportHolelList,
      loaderCountTrue: false,
      isPayrollRedicet: false,
      redirectState: {},
    };
  }

  checkPayrollRediect = () => {
    if (this.state.isPayrollRedicet) {
      localStorage.setItem("mainMenu", "Labor");
      localStorage.setItem("subMenu", "Payroll");
      return (
        <Redirect
          to={{
            pathname: "/labor-management/payroll-export",
            state: this.state.redirectState,
          }}
        />
      );
    }
  };

  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      this.setState({ isPayrollRedicet: true, redirectState: row });
    },
  };

  render() {
    payRollColumns = [
      {
        dataField: "letterCode",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="hotelDetailsData">
            <span className="hotelLetterAbbr">{row.lettercode}</span>
            <EllipsisWithTooltip placement="bottom">
              <span className="hotelFullName">
                {row.pname}
              </span>
            </EllipsisWithTooltip>
          </div>
        ),
      },
      {
        dataField: "roomTotal",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="smileIdCountData">
            <span className="smileIdCount">{row.payPeriodCount}</span>
            <span className="smileIdLabel">Payroll Export</span>
          </div>
        ),
      },
      {
        dataField: "action",
        text: "",
      },
    ];

    return (
      <>
        {this.checkPayrollRediect()}
        <div className="overTimeMain">
          <div className="budget-settings smileIdOverview otarOverView">
            <div className="smileIdOverviewTable">
              {this.state.loaderCountTrue ? (
                <ReactPageLoader useas={"hotelsListOTAR"} />
              ) : (
                  <>
                    <h1>Payroll Export</h1>
                    <BootstrapTable
                      keyField="keyIndex"
                      data={this.state.tableData}
                      columns={payRollColumns}
                      rowEvents={this.rowEvents}
                      hover
                    />
                  </>
                )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
