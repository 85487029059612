import Instense from "./Axios-config";
export class SalesService {

    public static getSalesEHID = async (request: any): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.TenantId = tenantID;
        const url = `/SalesMaster/GetSalesHotels`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data as any;
            return result;
        });
    };

    public static getSalesStatus = async (): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        let request: any = {};
        request.TenantId = tenantID;
        const url = `/SalesMaster/GetSalesStatusMaster`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data as any;
            return result;
        });
    };

    public static getSalesAgent = async (request: any): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.TenantId = tenantID;
        const url = `/SalesMaster/GetSalesAgent`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data as any;
            return result;
        });
    };

    public static getSalesSource = async (): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        let request: any = {};
        request.TenantId = tenantID;
        const url = `/SalesMaster/GetSalesSourceMaster`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data as any;
            return result;
        });
    };

    public static getSalesMarketSegment = async (): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        let request: any = {};
        request.TenantId = tenantID;
        const url = `/SalesMaster/GetSalesMarketSegmentMaster`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data as any;
            return result;
        });
    };

    public static SaveSalesAccount = async (request: any): Promise<any | null> => {
        // debugger;

        const url = `/SalesAccount/SaveSalesAccount`;
        return Instense.post(url, request).then(
            (response: any) => {
                let result = response.data.result as any;
                return result;
            }
        );
    }

    public static getSalesAccountsList = async (request: any): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
        request.TenantID = tenantID;
        request.LogInUserID = userUniqueID;
        const url = `/SalesAccount/GetSalesAccountList`;
        // const url = `/SalesAccount/GetSalesAccountList`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data as any;
            return result;
        });
    };

    public static GetSalesPermission = async (): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        let request: any = {};
        request.TenantId = tenantID;
        const url = `/SalesMaster/GetSalesPermission`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data as any;
            return result;
        });
    };

    public static GetSalesVesrionPermission = async (): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        let request: any = {};
        request.TenantId = tenantID;
        const url = `/SalesMaster/GetSalesVersionPermission`;
        return Instense.get(url, { params: request }).then((response) => {
            //debugger;
            let result = response?.data as any ;
            return result;
        });
    };

    public static getSalesAccountDetails = async (request: any): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.TenantId = tenantID;
        const url = `/SalesAccount/GetSalesAccountDetailsById`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data as any;
            return result;
        });
    };

    public static getSalesAccountAddressDetails = async (request: any): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.TenantId = tenantID;
        const url = `/SalesAccount/GetSalesAccountAddressById`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data as any;
            return result;
        });
    };

    public static GetSalesContactById = async (request: any): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.TenantID = tenantID;
        const url = `/SalesAccount/GetSalesContactById`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data as any;
            return result;
        });
    };
    public static GetSalesTaskById = async (request: any): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.TenantID = tenantID;
        const url = `/SalesAccount/GetSalesTaskById`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data as any;
            return result;
        });
    };


    public static UpdateSalesAccount = async (request: any): Promise<any | null> => {
        // debugger;
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.TenantID = tenantID;
        const url = `/SalesAccount/UpdateSalesAccount`;
        return Instense.post(url, request).then(
            (response: any) => {
                let result = response.data.result as any;
                return result;
            }
        );
    }

    public static UpdateSalesAccountAddress = async (request: any): Promise<any | null> => {
        // debugger;
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.TenantID = tenantID;
        const url = `/SalesAccount/UpdateSalesAccountAddress`;
        return Instense.post(url, request).then(
            (response: any) => {
                let result = response.data.result as any;
                return result;
            }
        );
    }

    public static MarkCompleteTask = async (request: any): Promise<any | null> => {
        // debugger;
        const url = `/SalesAccount/MarkCompleteTask`;
        return Instense.post(url, request).then(
            (response: any) => {
                let result = response.data.result as any;
                return result;
            }
        );
    }

    public static SaveSalesAccountContact = async (request: any): Promise<any | null> => {
        // debugger;
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.TenantID = tenantID;
        const url = `/SalesAccount/SaveSalesAccountContact`;
        return Instense.post(url, request).then(
            (response: any) => {
                let result = response.data.result as any;
                return result;
            }
        );
    }

    public static UpdateSalesContactStatus = async (request: any): Promise<any | null> => {
        // debugger;
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.TenantId = tenantID;
        const url = `/SalesAccount/UpdateSalesContactStatus`;
        return Instense.post(url, request).then(
            (response: any) => {
                let result = response.data.result as any;
                return result;
            }
        );
    }

    public static GetContactDetails = async (request: any): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.TenantID = tenantID;
        const url = `/SalesAccount/GetSalesAccountContactById`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data as any;
            return result;
        });
    };

    public static getSalesAccountListById = async (request: any): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
        request.TenantID = tenantID;
        request.LogInUserID = userUniqueID;
        const url = `/SalesAccount/GetSalesAccountListById`;
        // const url = `/SalesAccount/GetSalesAccountList`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data as any;
            return result;
        });
    };

}