import * as React from "react";

import {
  Col,
  Button,
  OverlayTrigger,
  Tooltip,
  Dropdown,
  Form,
  Spinner,
  Tab,
  Tabs,
} from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-calendar/dist/Calendar.css";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { resolve, reject } from "q";
import Dropzone from "react-dropzone";
import {
  faFile,
  faFileImage,
  faFileExcel,
  faFilePdf,
  faFileCsv,
  faFileWord,
  faClose
} from "@fortawesome/free-solid-svg-icons";
import { confirmAlert } from "react-confirm-alert";
import OutsideClickHandler from "react-outside-click-handler";
import { Utils } from "../../Common/Utilis";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { schConversions } from "../LaborManagement/Labor/Schedule/commonFunctions/conversions";
import _ from "lodash";
import {
  ISaveFacilityDailyTaskFileRequestDto,
  IDownloadFacilityDailyTaskFileRequest,
  IDailyTaskCommentRequest,
  IDailyTasKDto,
  IUpdatFacilityDailyTaskStatusRequest,
} from "../../Common/Contracts/IDailyTask";
import { FacilityDailyTask } from "../../Common/Services/FacilityDailyTask";
import { CommonCommentBox } from "../../Common/Components/CommonCommentBox";
import moment from "moment";
import { DropDownListWithImage } from "../../Common/Components/DropDownListWithImage";
import { InfoTooltip } from "../../Common/Components/InfoTooltip";

const RepeatList = [{ id: "Daily", name: "Daily" }];
let routeLeavingGuard: any = null;

export default class DailyTaskManagementSlideOut extends React.Component<
  any,
  any
> {
  private slideoutRef: any;
  private parentScroll: any;
  private btnSave: any;
  private input: any;
  private childComment: any;

  constructor(props: any) {
    super(props);

    this.slideoutRef = React.createRef();
    this.parentScroll = React.createRef();
    this.btnSave = React.createRef();
    this.input = React.createRef();
    this.childComment = React.createRef();

    this.state = {
      isSpinner: false,
      pageLoader: false,
      hidValue: "Select",
      hotelName: "Select",
      createEditPermission: this.props.createEditPermission,
      parentRowData: _.cloneDeep(this.props.parentRowData),
      savedfiles: [],
      newSelectfiles: [],
      files: [],
      fileSize: 5242880,
      moreActionFileUpload1: [
        { name: "Delete", eventKey: "delete" },
        { name: "Download", eventKey: "download" },
      ],
      changeValue: false,
      isRefresh: false,
      bdrValue: false,
      locationId: 0,
      inTime: this.props.parentRowData.time
        ? schConversions.tweleveToTwentyFourConvert(
            this.props.parentRowData.time
          ) || ""
        : "",
      name: "",
      assignedTo: 0,
      description: "",
      errors: false,
      inputPosition: 0,
      htmlcomment: [],
      descritionBox: "",
      deleteFile: "No",
      userList: [],
      isUserListLoading: false,
      taskStatus: this.props?.parentRowData?.status,
      isUpdating: false,
      isCommentSaved: false,
      oldDescription: "",
      oldAssignedTo: 0,
    };

    this.onDrop = this.onDrop.bind(this);
  }

  static defaultProps = {
    locationDisable: false,
    assetDisable: false,
  };

  componentDidMount() {
    this.setState({
      oldDescription: this.props.parentRowData?.description,
      oldAssignedTo:
        this.props.parentRowData.userStatus == "Inactive"
          ? 0
          : this.props.parentRowData.assignedTo,

      createEditPermission: this.props.createEditPermission,
      description: this.props.parentRowData?.description,
      name: this.props.parentRowData?.taskName,
      locationId: this.props.parentRowData.roomID,
      assignedTo:
        this.props.parentRowData.userStatus == "Inactive"
          ? 0
          : this.props.parentRowData.assignedTo,
    });
    this.getUploadFileList();
    this.getCommentList();
    this.getFacilityAssignedTo();
  }
  getCommentList = () => {
    let commentRequest: any = {};
    commentRequest.taskId = this.props.parentRowData?.taskId;
    FacilityDailyTask.GetFacilityDailyTaskAssignedComment(commentRequest)
      .then(async (result: any | null) => {
        if (result !== null) {
          this.setState({ htmlcomment: [], isComment: false }, () => {
            this.setState({ htmlcomment: result, isComment: true });
          });
          if (this.props?.scrollToComment) {
            this.scrollToBottom();
          }
        }
      })
      .catch((error) => {
        reject();
      });
  };

  getFacilityAssignedTo = () => {
    this.setState({ isUserListLoading: true });
    FacilityDailyTask.GetFacilityAssignedTo(
      this.props.parentRowData?.roleId ? this.props.parentRowData?.roleId : 0,
      this.props?.hid
    )
      .then((userList: any | null) => {
        // debugger;
        this.setState({ userList }, () => {
          this.setState({ isUserListLoading: false });
        });
      })
      .catch((error) => {
        this.setState({ isUserListLoading: false });
        reject();
      });
  };

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.beforeunload.bind(this));
  }

  beforeunload(e: { preventDefault: () => void; returnValue: boolean }) {
    if (
      this.state.changeValue &&
      routeLeavingGuard !== "routeLeavingGuardTrue"
    ) {
      e.preventDefault();
      e.returnValue = true;
    }
  }

  closeSlideOut = (isReload = false) => {
    this.props.handleHideSlideout(
      this.isFormDataChange() || this.state.isCommentSaved
    );
  };

  isFormDataChange = () => {
    const prevData = {
      description: this.props?.parentRowData?.description,
      assignedTo:
        this.props?.parentRowData?.userStatus == "Inactive"
          ? 0
          : this.props.parentRowData.assignedTo,
      status: this.props.parentRowData.status,
    };

    const currentData = {
      description: this.state?.description,
      assignedTo: this.state.assignedTo,
      status: this.state.taskStatus,
    };

    return !_.isEqual(prevData, currentData);
  };
  onTitleCheckBoxChange = (isSelect: boolean) => {
    this.setState({ taskStatus: isSelect ? "Completed" : "Not Started" });
    this.updateFacilityDailyTaskStatus(isSelect);
  };

  updateFacilityDailyTaskStatus = (isSelect) => {
   

    const request: IUpdatFacilityDailyTaskStatusRequest = {
      hid: this.props.parentRowData?.hid,
      tenantId: 0,
      taskId: this.props.parentRowData?.taskId,
      status: isSelect ? "Completed" : "Not Started",
    };
    FacilityDailyTask.UpdateFacilityDailyTaskStatus([request])
      .then((data) => {})
      .catch((error) => {
        if ((error)?.message !== "Cancel") {
          Utils.toastError(error?.response?.data, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .finally(() => this.setState({ isUpdating: false }));
  };

  onDrop = (filelist: any, mode: any) => {
    let size = this.state.fileSize; //5242880//5MB
    let newfiles: any = [];
    let files: any = [];
    let isReturn = true;
    let filestext = "";
    let filesizeval = Number(Number(this.state.fileSize) / (1024 * 1024));
    filelist.forEach((element) => {
      if (element.size > size) {
        filestext = filestext + element.name + ",";
        isReturn = false;
      }
    });

    if (!isReturn) {
      let msg = "One or more files are greater than " + filesizeval + "MB.";
      Utils.toastError(msg, {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "InvoiceSlideout",
      });
    }
    let isInvalidFile = false;
    const filExtList = [
      "pdf",
      "doc",
      "docx",
      "xls",
      "xlsx",
      "csv",
      "txt",
      "bmp",
      "tif",
      "ppt",
      "pptx",
      "rtf",
      "jpg",
      "jpeg",
      "png",
      "msg",
      "eml",
    ];
    filelist.forEach((element) => {
      if (
        filExtList.filter(
          (extentionName) =>
            extentionName ===
            element?.name
              ?.substring(
                element?.name.lastIndexOf(".") + 1,
                element?.name.length
              )
              .toLowerCase()
        )?.length > 0 &&
        element.size <= size
      ) {
        newfiles.push(element);
        files.push(element);
      } else {
        isInvalidFile = true;
      }
    });
    if (isInvalidFile && isReturn) {
      let msg = "";
      if (filelist.length === 1) {
        msg = "This file type is not supported.";
      } else {
        msg = "Some files are not supported.";
      }
      Utils.toastError(msg, {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "InvoiceSlideout",
      });
    }

    let newfileData = [...this.state.newSelectfiles];
    for (let file of newfiles) {
      file.etype = "newfile";  // Add or modify the 'etype' property of each file object
      newfileData.push(file);  // Push the modified file into the newfileData array
    }

    this.setState({ files, newSelectfiles: newfileData }, () => {
      // debugger;

      if (mode === 2) {
        //this.isHandleAction();
      } else {
        if (files.length > 0) {
          this.handleSaveFiles();
        }
      }
    });
  };

  handleSaveFiles = () => {
    this.setState({ isSpinner: true });
    let request: ISaveFacilityDailyTaskFileRequestDto = {
      hotelID: Number(this.state.parentRowData?.hid),
      taskId: this.state.parentRowData?.taskId,
    };
    let newfileData = [...this.state.newSelectfiles];

    FacilityDailyTask.SaveFacilityDailyTaskFile(request, newfileData)
      .then(async (result: any | null) => {
        console.log({ result });
        if (result !== null) {
          if (result.success) {
            this.getUploadFileList();
            this.setState({ isSpinner: false });
            toast.success("Files saved successfully.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "InvoiceSlideout",
            });
          } else {
            this.setState({ isSpinner: false });
            Utils.toastError(result.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "InvoiceSlideout",
            });
          }
        }

        resolve();
      })
      .catch((error) => {
        this.setState({ changeValue: false, isSpinner: false });
        reject();
      });
  };

  handleSave = () => {
    let request: IDailyTasKDto = {
      taskId: Number(this.state.parentRowData?.taskId),
      hid: Number(this.state.parentRowData?.hid),
      name: this.state.parentRowData?.name,
      taskTemplateId: this.state.parentRowData?.taskTemplateId,
      roomId:
        this.state.locationId == 0
          ? Number(this.state.parentRowData.roomID)
          : Number(this.state.locationId) || 0,

      AssignedTo: Number(this.state.assignedTo) || 0,

      description: this.state.description?.trim() || "",
    };

    this.btnSave.current.disabled = true;
    // let newfileData = [...this.state.newSelectfiles];
    let newfileData: any = [];

    FacilityDailyTask.SaveDailyTaskManagementData(request)
      .then(async (result: any | null) => {
        if (result !== null) {
          if (result.success) {
            toast.success("Daily task updated successfully.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "InvoiceSlideout",
            });

            this.btnSave.current.disabled = false;
            this.setState({
              changeValue: false,
              oldDescription: _.cloneDeep(this.state.description?.trim() || ""),
              oldAssignedTo: _.cloneDeep(Number(this.state.assignedTo) || 0),
            });

            // this.GetUploadFileList();
          } else {
            Utils.toastError(result.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "InvoiceSlideout",
            });
            this.setState({ isDisabled: false });
            this.btnSave.current.disabled = false;
          }
        }

        resolve();
      })
      .catch((error) => {
        Utils.toastError(error?.response?.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "InvoiceSlideout",
        });
        this.btnSave.current.disabled = false;
        this.setState({ bdrValue: true });

        reject();
      });
  };

  handleSelectFileUpload = (eventKey: any, file, index) => {
    if (eventKey === "delete") {
      this.setState({ deleteFile: "Yes" });
      let request: any = {};
      // request = Object.assign({}, JSON.parse(JSON.stringify(this.state.formData)));
      let masg = "";
      masg = "Are you sure you want to delete this Attachment?";
      confirmAlert({
        title: "Delete Attachment",
        message: masg,
        buttons: [
          {
            label: "Yes",
            onClick: () => this.deleteFile(file),
          },
          {
            label: "No",
            onClick: () => reject(),
          },
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    } else if (eventKey === "download") {
      this.downloadFacilityDailyTaskUploadFile(file);
    } else if (eventKey === "TempDelete") {
      let masg = "";
      masg = "Are you sure you want to delete this Attachment?";
      confirmAlert({
        title: "Delete Attachment",
        message: masg,
        buttons: [
          {
            label: "Yes",
            onClick: () => this.temporaryDeleteDailyTaskFile(file),
          },
          {
            label: "No",
            onClick: () => reject(),
          },
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    }
  };

  downloadFacilityDailyTaskUploadFile = (file: any) => {
    const request: IDownloadFacilityDailyTaskFileRequest = {
      hid: file.hid,
      uploadfile: file.uploadfile,
      filename: file.fileName,
    };
    FacilityDailyTask.DownloadFacilityDailyTaskUploadFile(request)
      .then(async (result: any | null) => {
        // toast.success(`${file.fileName} downloaded successfully.`, {
        //   position: toast.POSITION.BOTTOM_RIGHT,
        // });
        resolve();
      })
      .catch((error) => {
        reject();
      });
  };

  deleteFile = (file: any) => {
    let deleteRequest: any = {};
    deleteRequest.hotelID = Number(this.state.parentRowData?.hid);

    deleteRequest.filenameUniqno = file.fileUniqueNo;

    FacilityDailyTask.DeleteFacilityDailyTaskFile(deleteRequest)
      .then(async (result: any | null) => {
        if (result.saveStatus === "Success") {
          toast.success(result.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        this.getUploadFileList();
        resolve();
      })
      .catch((error) => {
        reject();
      });
  };
  temporaryDeleteDailyTaskFile = (file: any) => {
    let data = [...this.state.newSelectfiles];
    let index = data.findIndex((item) => item.etype === "newfile");
    data.splice(index, 1);
    this.setState({ newSelectfiles: data });
  };

  onFileChange = (event: any, mode) => {
    let newfiles: any = [];
    if (event.target.files.length > 0) {
      for (let fileRow of event.target.files) {
        newfiles.push(fileRow);
      }
      this.onDrop(newfiles, mode);
      let fileList = document.getElementById("formControlsFile") as any;
      fileList.value = "";
    }
  };

  getUploadFileList = () => {
    let request: ISaveFacilityDailyTaskFileRequestDto = {
      hotelID: Number(this.state.parentRowData?.hid),
      taskId: this.state.parentRowData?.taskId,
    };

    FacilityDailyTask.GetFacilityDailyTaskUploadfile(request)
      .then(async (result: any | null) => {
        if (result !== null) {
          for (let i = 0; i < result.length; i++) {
            this["myRef" + i] = React.createRef();
          }
          this.setState({ savedfiles: [] }, () => {
            // debugger;
            this.setState({
              savedfiles: result,
              files: [],
              newSelectfiles: [],
              isSpinner: false,
            });
          });
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  };

  hideModal = (value = "click") => {
    if (value == "outSideClick") {
      return;
    }

    if (this.state.changeValue) {
      this.validateModelPoupTab();
    } else {
      this.closeSlideOut();
    }
  };

  onAssignedTochange = (id) => {
    this.setState({ assignedTo: id }, () => this.checkIfFormDataModified());
  };

  routeLeavingGuardConfirm = (childState: any) => {
    if (childState === true) {
      routeLeavingGuard = "routeLeavingGuardTrue";
    } else {
      routeLeavingGuard = null;
    }
  };

  validateModelPoupTab() {
    confirmAlert({
      title: "Unsaved confirmation",
      message:
        "You have unsaved changes on this page. Do you want to leave this page and discard your changes?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.discardChanges(true),
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }

  discardChanges = (isFromModal = false) => {
    this.setState(
      {
        isRefresh: true,
        changeValue: false,
        description: _.cloneDeep(this.state.oldDescription),
        name: this.props.parentRowData?.taskName,
        assignedTo: _.cloneDeep(this.state.oldAssignedTo),
        isUserListLoading: true,
      },
      () => {
        this.setState({ isRefresh: false, isUserListLoading: false });
        // let data = [...this.state.newSelectfiles]
        // let filterData = data.filter((item) => item.etype === "newfile");
        // for (let i = 0; i < filterData.length; i++) {

        //     let index = data.indexOf(filterData[i]);
        //     data.splice(index, 1);
        // }

        // this.setState({ newSelectfiles: data });
      }
    );

    if (isFromModal) {
      this.closeSlideOut();
    }
    // this.props.changesOfSlideOut(false);
    // this.setState({ changeValue: false },()=>{
    // this.props.changesOfSlideOut(false);
    //  });
  };

  descriptionChange = (e: any) => {
    let value = e.target.value;

    let inputValue =
      typeof value === "string" ? value?.toString()?.trimLeft() : value;

    this.setState({ description: inputValue }, () =>
      this.checkIfFormDataModified()
    );

    // e.target.style.height = 'auto';
    // e.target.style.height = e.target.scrollHeight + 'px';
  };

  checkIfFormDataModified = () => {
    const previousData = {
      Description: this.props.parentRowData.description?.trim() || "",
      assignedTo:
        this.props.parentRowData.userStatus == "Inactive"
          ? 0
          : this.props.parentRowData?.assignedTo,
    };

    const newData = {
      Description: this.state.description?.trim() || "",
      assignedTo: this.state?.assignedTo,
    };

    const isChanged = !_.isEqual(previousData, newData);

    this.setState({ changeValue: isChanged });
  };

  saveCommentData = (commentData: any) => {
    const request: IDailyTaskCommentRequest = {
      taskId: this.props?.parentRowData?.taskId,
      hid: this.props?.parentRowData?.hid,
      toUsers: commentData?.toUsers,
      comment: commentData?.comment,
      logInuser: commentData?.logInuser,
      htmlcomment: commentData?.htmlcomment,
      commentLink: this.props?.commentLink,
    };
    this.setState({ isSave: true });
    FacilityDailyTask.SaveFacilityDailyTaskComment(request)
      .then(async (result: any | null) => {
        if (result !== null) {
          // debugger;
          if (result.saveStatus === "Success") {
            this.setState({ isCommentSaved: true });
          } else {
            if (result.messageCode === "Validation") {
              Utils.toastError(result.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "InvoiceSlideout",
              });

              this.props.commentModalClose();
            }
          }
        }
        resolve();
      })
      .catch((error) => {
        reject();
      })
      .finally(() => this.setState({ isSave: true }));
  };

  descriptionBox = () => {};
  scrollToBottom = () => {
    this.parentScroll.current.scrollTo(
      0,
      this.parentScroll.current.scrollHeight,
      "smooth"
    );
  };

  getParamValueByParamName(paramName: string): any {
    const valuesArray = decodeURIComponent(this.props.history.location.search)
      ?.replace("?", "")
      ?.split("&");
    const item = valuesArray?.find((r) => r?.includes(paramName));
    return item ? window.atob(item?.split("=")[1] as any) : "";
  }

  render() {
    const iconList = [
      { ext: "pdf", icon: faFilePdf },
      { ext: "xlsx", icon: faFileExcel },
      { ext: "csv", icon: faFileCsv },
      { ext: "word", icon: faFileWord },
    ];

    const files1 = this.state.savedfiles.map((file, index) => (
      <>
        <li key={file.fileName}>
          <div className="d-flex align-items-center justify-content-start">
            <div className="icon">
              {file?.type?.split("/")[0] === "image" && (
                <FontAwesomeIcon icon={faFileImage} />
              )}
              {iconList?.filter(
                (item) =>
                  item.ext ===
                  file?.fileName?.substring(
                    file?.fileName?.lastIndexOf(".") + 1,
                    file?.fileName.length
                  )
              )?.length > 0 && (
                <FontAwesomeIcon
                  icon={
                    iconList.find(
                      (item) =>
                        item.ext ===
                        file?.fileName?.substring(
                          file?.fileName?.lastIndexOf(".") + 1,
                          file?.fileName.length
                        )
                    )?.icon || faFile
                  }
                />
              )}
            </div>
            <div className="file-info d-flex align-items-center">
              <div className="name-loader d-flex align-items-center">
                <div className="name">
                  <EllipsisWithTooltip placement="bottom">
                    <div className="full-name">{file.fileName}</div>
                  </EllipsisWithTooltip>
                  {/* <div className="details">{file.createDate} - {file.pageno} Page</div> */}
                </div>
              </div>
              <div className="formate-percentage">
                <span className="formate d-flex justify-content-center align-items-center">
                  {file?.fileName?.substring(
                    file?.fileName?.lastIndexOf(".") + 1,
                    file?.fileName.length
                  )}
                </span>
              </div>
            </div>
            <div className="action">
              {/* {!this.state.bulkSelect && ( */}
              <Dropdown
                className="more-action"
                alignRight
                onSelect={(event: any) =>
                  this.handleSelectFileUpload(event, file, index)
                }
              >
                <Dropdown.Toggle
                  className="btn-outline-primary btn btn-primary more"
                  id="dropdown-more"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {this.state.moreActionFileUpload1.map(
                    (item: any, idx: any) => (
                      <>
                        <Dropdown.Item
                          // className={}

                          eventKey={
                            this.state.moreActionFileUpload1[idx].eventKey
                          }
                          key={idx}
                        >
                          {this.state.moreActionFileUpload1[idx].name}
                        </Dropdown.Item>
                      </>
                    )
                  )}
                </Dropdown.Menu>
              </Dropdown>
              {/* )} */}
            </div>
          </div>
        </li>
      </>
    ));

    const files = this.state.newSelectfiles.map((file, index) => (
      <>
        <li key={file.name}>
          <div className="d-flex align-items-center justify-content-start">
            <div className="icon">
              {file?.type?.split("/")[0] === "image" && (
                <FontAwesomeIcon icon={faFileImage} />
              )}
              {iconList?.filter(
                (item) =>
                  item.ext ===
                  file?.fileName?.substring(
                    file?.fileName?.lastIndexOf(".") + 1,
                    file?.fileName.length
                  )
              )?.length > 0 && (
                <FontAwesomeIcon
                  icon={
                    iconList.find(
                      (item) =>
                        item.ext ===
                        file?.fileName?.substring(
                          file?.fileName?.lastIndexOf(".") + 1,
                          file?.fileName.length
                        )
                    )?.icon || faFile
                  }
                />
              )}
            </div>
            <div className="file-info d-flex align-items-center">
              <div className="name-loader d-flex align-items-center">
                <div className="name">
                  <EllipsisWithTooltip placement="bottom">
                    {/* <div className="full-name" onClick={() => { this.handlePreview(file) }}>{file.name}</div> */}
                    <div className="full-name">{file.name}</div>
                  </EllipsisWithTooltip>
                </div>
              </div>
              <div className="formate-percentage">
                <span className="formate d-flex justify-content-center align-items-center">
                  {file?.name?.substring(
                    file?.name?.lastIndexOf(".") + 1,
                    file?.name.length
                  )}
                </span>
              </div>
            </div>
            <div className="action">
              {/* <button className="btn-outline-primary remove-pdf more btn btn-primary" onClick={() => { this.handleSelectFileUpload("TempDelete", file, 0) }} >
                                <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"></path></svg>
                               
                            </button> */}

              {this.state.isSpinner && (
                <button className="btn-outline-primary remove-pdf more btn btn-primary">
                  <Spinner
                    className="mr-2"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </button>
              )}
            </div>
          </div>
        </li>
      </>
    ));

    // const attachmentheader = (column: any, colIndex: any) => {
    //   return (
    //     <div className="paperClipBtn">
    //       <svg
    //         stroke="currentColor"
    //         fill="currentColor"
    //         stroke-width="0"
    //         viewBox="0 0 448 512"
    //         height="1em"
    //         width="1em"
    //         xmlns="http://www.w3.org/2000/svg"
    //       >
    //         <path d="M43.246 466.142c-58.43-60.289-57.341-157.511 1.386-217.581L254.392 34c44.316-45.332 116.351-45.336 160.671 0 43.89 44.894 43.943 117.329 0 162.276L232.214 383.128c-29.855 30.537-78.633 30.111-107.982-.998-28.275-29.97-27.368-77.473 1.452-106.953l143.743-146.835c6.182-6.314 16.312-6.422 22.626-.241l22.861 22.379c6.315 6.182 6.422 16.312.241 22.626L171.427 319.927c-4.932 5.045-5.236 13.428-.648 18.292 4.372 4.634 11.245 4.711 15.688.165l182.849-186.851c19.613-20.062 19.613-52.725-.011-72.798-19.189-19.627-49.957-19.637-69.154 0L90.39 293.295c-34.763 35.56-35.299 93.12-1.191 128.313 34.01 35.093 88.985 35.137 123.058.286l172.06-175.999c6.177-6.319 16.307-6.433 22.626-.256l22.877 22.364c6.319 6.177 6.434 16.307.256 22.626l-172.06 175.998c-59.576 60.938-155.943 60.216-214.77-.485z"></path>
    //       </svg>
    //     </div>
    //   );
    // };

    const {
      createEditPermission,
      assignedTo,
      userList,
      isUserListLoading,
      taskStatus,
      isUpdating,
    } = this.state;

    return (
      <>
        <OutsideClickHandler
          onOutsideClick={(e: any) => this.hideModal("outSideClick")}
        >
          <div className="daily-task-slideout pos-fxd index-1000">
            <div className="d-flex">
              <div
                className="back-drop"
                onClick={() => this.hideModal()}
                //  onClick={() => this.hideApprovalModal()}
              ></div>
              <div className="slideout-UI" ref={this.parentScroll}>
                <div className="wrapper">
                  <div className="slideout-header">
                    {/* {createEditPermission ? ( */}
                    <div className="pos-ttl mr-auto">
                      <h3 className="heading mr-auto">
                        <div
                          className={`d-flex align-items-center ${
                            taskStatus === "Completed" ? "completed-task" : ""
                          }`}
                        >
                          <EllipsisWithTooltip placement="bottom">
                            <Form.Check
                              disabled={isUpdating || !createEditPermission}
                              onChange={(event) =>
                                this.onTitleCheckBoxChange(
                                  taskStatus === "Completed" ? false : true
                                )
                              }
                              label=""
                              checked={true}
                            />
                          </EllipsisWithTooltip>
                          <span className="task-title">
                            <EllipsisWithTooltip placement="bottom">
                              {" "}
                              {this.state.parentRowData?.name}
                            </EllipsisWithTooltip>
                          </span>
                        </div>
                      </h3>

                      <Dropdown className="more-action ml-3">
                        <DropdownToggle
                          className="btn-outline-primary btn btn-primary more"
                          id="dropdown-more"
                          disabled={!createEditPermission}
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                            <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                            <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                          </svg>
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem></DropdownItem>
                        </DropdownMenu>
                      </Dropdown>

                      <Button onClick={() => this.hideModal()}>
                        <FontAwesomeIcon icon={faClose} />
                      </Button>
                    </div>
                  </div>
                  <div
                    className={`slideout-body ${
                      this.state.changeValue && "bottom-spacer"
                    } ${!createEditPermission ? "controls-disabled" : ""}`}
                    ref={this.slideoutRef}
                  >
                    <div className="order-info">
                      <Form.Group
                        controlId="Repeats"
                        className="Repeats-dropdown"
                      >
                        <Form.Label className="item-label">Due</Form.Label>

                        <Form.Label className="item-value">
                          {this.props?.parentRowData?.dueDate
                            ? moment(
                                this.props?.parentRowData?.dueDate
                              )?.format("MM/DD/YYYY") ===
                              moment()?.format("MM/DD/YYYY")
                              ? "Today"
                              : moment(
                                  this.props?.parentRowData?.dueDate
                                )?.format("MM/DD/YYYY")
                            : ""}
                        </Form.Label>
                      </Form.Group>
                      <Form.Group
                        controlId="Repeats"
                        className="Repeats-dropdown"
                      >
                        <Form.Label className="item-label">Repeats</Form.Label>

                        <Form.Label className="item-value">Daily</Form.Label>
                      </Form.Group>
                      <Form.Group
                        controlId="Repeats"
                        className="Repeats-dropdown"
                      >
                        <Form.Label className="item-label">Time</Form.Label>

                        <Form.Label className="item-value">
                          {this.props.parentRowData.time
                            ? schConversions.tweleveToTwentyFourConvert(
                                this.props.parentRowData.time
                              )
                            : "N/A"}
                        </Form.Label>
                      </Form.Group>

                      <Form.Group
                        controlId="location"
                        className="location-dropdown"
                      >
                        <Form.Label className="item-label">Location</Form.Label>
                        <Form.Label className="item-value">
                          {this.state.parentRowData.roomName}
                        </Form.Label>
                      </Form.Group>
                      <Form.Group controlId="assignedTo">
                        <Form.Label className="item-label assigned-to">
                          Assigned To
                          {this.state.parentRowData.userStatus == "Inactive" &&
                            this.state?.assignedTo == 0 && (
                              <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                  <Tooltip id={"tooltip-nickname"}>
                                    Assigned User has been made Inactive.
                                  </Tooltip>
                                }
                              >
                                <span
                                  style={{
                                    marginLeft: "4px",
                                    display: "inline-block",
                                  }}
                                >
                                  {/* <InfoTooltipRed />{" "} */}
                                  <InfoTooltip />{" "}
                                </span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                        <div className="item-value">
                          {!isUserListLoading && (
                            <DropDownListWithImage
                              defaultValue={assignedTo}
                              placeHolder={"Search for a user"}
                              data={[
                                ...[
                                  {
                                    uniqueno: 0,
                                    username: "select a user",
                                    profileimage: "",
                                  },
                                ],
                                ...userList,
                              ]}
                              disabled={!createEditPermission}
                              isSearchRequired={true}
                              label={"username"}
                              value={"uniqueno"}
                              imageUrl={"profileimage"}
                              onDropDownChange={(item) => {
                                this.onAssignedTochange(item?.uniqueno || 0);
                              }}
                              selectedItem={[
                                ...[
                                  {
                                    uniqueno: 0,
                                    username: "select a user",
                                    profileimage: "",
                                  },
                                ],
                                ...userList?.filter((item) => item?.uniqueno),
                              ].find((r) => r.uniqueno === assignedTo)}
                            />
                          )}
                        </div>
                      </Form.Group>
                    </div>
                    <div className="py-4">
                      <div className="description-box">
                        <h5 className="label">Description</h5>
                        <Form.Group controlId="description" className="d-flex">
                          <Col sm={"12"} className={`mb-0 p-0`}>
                            <Form.Control
                              disabled={!createEditPermission}
                              as="textarea"
                              type="textarea"
                              className="form-control"
                              maxLength={200}
                              id="description"
                              name="description"
                              autoComplete="description"
                              value={this.state?.description}
                              placeholder="Give as much detail as possible about the Daily Task, Location and anything else relevant"
                              onChange={(e) => this.descriptionChange(e)}
                              onBlur={() => {
                                this.setState({
                                  description:
                                    this.state?.description?.trimRight(),
                                });
                              }}
                              // onBlur={(e) => this.descriptionChange(e)}
                            />
                          </Col>
                        </Form.Group>
                      </div>
                    </div>

                    <div className="filter-sec upload-section">
                      <Form.Group controlId="exampleForm.ControlTextarea1">
                        <div className="d-flex">
                          <Form.Label className="mr-auto label">
                            Attachments
                          </Form.Label>
                          {this.state.savedfiles.length > 0 && (
                            <div className="upload-link">
                              {/* <button type="button" className="btn wht-bg link-btn mr-auto" onClick={this.OpenViewer}>
                                                                    {this.state.isOpenViewer ? 'Close Viewer' : 'Open Viewer'}
                                                                </button>  */}
                            </div>
                          )}
                        </div>
                        <div
                          className={files1.length >= 1 ? "remove-border" : ""}
                        >
                          <Dropzone
                            disabled={!createEditPermission}
                            onDrop={this.onDrop}
                            // accept="image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.txt,.bmp,.tif,.ppt,.pptx,.rtf,.jpg,.png,.msg,.eml"
                          >
                            {({
                              getRootProps,
                              getInputProps,
                              isDragActive,
                              isDragReject,
                            }) => (
                              <>
                                <section
                                  className={
                                    isDragActive
                                      ? "container upload-area default drag-active "
                                      : "container upload-area default"
                                  }
                                >
                                  {!this.state.bulkSelect && (
                                    <div
                                      {...getRootProps({
                                        className:
                                          "dropzone d-flex align-items-center",
                                        onClick:
                                          this.state.newSelectfiles.length >
                                            0 ||
                                          this.state.savedfiles.length > 0
                                            ? (event) => event.stopPropagation()
                                            : (event) => event,
                                      })}
                                    >
                                      <input {...getInputProps()} />
                                      {this.state.newSelectfiles.length < 1 &&
                                        this.state.savedfiles.length < 1 && (
                                          <p>
                                            Drag and drop files or{" "}
                                            <span>browse files</span> to upload.
                                          </p>
                                        )}
                                      {(this.state.newSelectfiles.length > 0 ||
                                        this.state.savedfiles.length > 0) &&
                                        isDragActive && (
                                          <p>
                                            Drag and drop files here to upload.
                                          </p>
                                        )}
                                      {/* {!isDragActive && (<p>Drag and drop or <span>browse files</span> to upload</p>)} */}
                                    </div>
                                  )}

                                  {/* {this.state.bulkSelect && ( 
                                                                            <div className="dropzone d-flex align-items-center">
                                                                                <p>Drag and drop or <span>browse files</span> to upload</p>
                                                                            </div>
                                                                      )}   */}
                                  {(this.state.newSelectfiles.length > 0 ||
                                    this.state.savedfiles.length > 0) && (
                                    <aside className="d-flex flex-column">
                                      <ul className="p-0">{files1}</ul>
                                      <ul className="p-0">{files}</ul>
                                    </aside>
                                  )}
                                </section>
                                {!this.state.bulkSelect &&
                                  (this.state.newSelectfiles.length > 0 ||
                                    this.state.savedfiles.length > 0) &&
                                  createEditPermission && (
                                    <div className="upload-link mrgn-top">
                                      <button
                                        type="button"
                                        className="btn p-0 wht-bg link-btn mr-auto"
                                      >
                                        Add Attachment
                                      </button>
                                      <Form.Control
                                        id="formControlsFile"
                                        type="file"
                                        multiple
                                        onChange={(event: any) =>
                                          this.onFileChange(event, "1")
                                        }
                                        accept="image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.txt,.bmp,.tif,.ppt,.pptx,.rtf,.jpg,.png,.msg,.eml"
                                      />
                                    </div>
                                  )}
                              </>
                            )}
                          </Dropzone>
                        </div>
                      </Form.Group>
                    </div>
                    <div className="comments">
                      <Tabs defaultActiveKey="Comments">
                        <Tab eventKey="Comments" title="Comments">
                          {/* <div className="add-comment">
                                                <img src={profile} className="user" alt="user" />
                                                <Form.Control type="text" placeholder="Write a comment..." />
                                            </div> */}
                          <div
                            className={
                              !createEditPermission ? "comment-disabled" : ""
                            }
                          >
                            {this.state.isComment && (
                              <CommonCommentBox
                                addCommentDisabled={!createEditPermission}
                                ref={this.childComment}
                                hotelid={this.state.parentRowData?.hid}
                                timezone={this.props.timezone}
                                precommentlist={this.state.htmlcomment}
                                parentScrollMethod={this.scrollToBottom}
                                saveCommentData={this.saveCommentData}
                                parentState={this.state}
                              />
                            )}
                          </div>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>

                  {createEditPermission && (
                    <div>
                      {this.state.changeValue && (
                        <div className="strip-wrapper">
                          <div className="fixed-action px-3 pb-2 h-auto">
                            <div className="d-flex align-items-center flex-wrap forecast-action-strip">
                              <div className="mr-auto message">
                                <span>
                                  You have made changes to this daily task. Do
                                  you want to save and continue ?{" "}
                                </span>
                              </div>

                              <button
                                type="button"
                                className="btn btn-primary border-0 btn-discard btn-changes-discard"
                                onClick={() => this.discardChanges()}
                              >
                                Discard
                              </button>

                              <button
                                ref={this.btnSave}
                                type="button"
                                className="save-btn btn btn-primary mr-0"
                                onClick={this.handleSave}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      </>
    );
  }
}
