import * as React from "react";
import { Modal, Form } from "react-bootstrap";
import OtpInput from "react-optinput";
import { resolve, reject } from "q";
import { toast } from "react-toastify";
import { accountService } from "../../Common/Services/account";
import { PassCodeService as OTPService } from "../../Common/Services/PassCodeService";
import "./otpModal.scss";
import { UserManagement } from "../../Common/Services/UserManagement";


export class OtpValidator extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      otpID: 0,
      ispopupshow: true,
      errorMessage: "",
      otp: 0,      
      currentStep: 1,
      selectedOption: "sms",
      isgoogleAuth: false,
      textMessage: "",
      userMobile: "",
      resend: false,
    };
    this.handleContinue = this.handleContinue.bind(this);
    this.handleReSend = this.handleReSend.bind(this);

  }

  componentDidMount() {
    this.GoogleAuthFeatureFlag();
    this.getUserGeneralInfo();
  }

  getUserGeneralInfo = () => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let user_UniqueId = storage === null ? 0 : (storage.user_UniqueID as any);
    UserManagement.GetUserGeneralInfo(user_UniqueId)
      .then(async (response: any | null) => {
        if (response !== null) {
          let PhoneNo = this.formatPhoneNumber(response.phone1)?.slice(-4);
          this.setState({ userMobile: response.phone1.trim().length === 0 ? "" : PhoneNo });
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ userMobile: "" });
        reject();
      });

  }

  formatPhoneNumber = (phoneNumberString) => {
    let cleaned = phoneNumberString.replace(/\D/g, "").slice(-10);
    let match = cleaned.match(/^(91|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      let intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", "•••", ") ", "•••", "-", match[4]].join("");
    }
    return null;
  };

  handleReSend = (event: any) => {
    this.setState({ resend: true }, () => {
      this.handleContinue(event);
    })

    setTimeout(() => {
      this.setState({ resend: false });
  }, 60000)

  }

  handleContinue(event: any) {
    event.preventDefault();
    // this.setState({currentStep: 2});
    this.SendPassCode();

  }
  onInputChange = (value: number) => {
    if (isNaN(value)) {
      let errorMessage = "Invalid input, accept only numeric value.";
      this.setState({ errorMessage: errorMessage });
    } else {
      this.setState({ otp: value });
      this.setState({ errorMessage: "" });
    }
  };



  onRadioButtonChange = (event) => {
    this.setState({
      selectedOption: event.target.value
    });

  }

  SendPassCode = () => {

    if (this.state.selectedOption === "sms") {
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let userName = storage === null ? 0 : (storage.userName as any);

      let request: any = {};
      request.otpID = 0;
      request.userName = userName;
      request.userEmail = "";
      request.userPhone = "";
      request.purpose = this.props?.PageName;
      request.otpCode = "";
      request.primaryContact = "";
      request.source = this.props?.Source;
      request.HID = -1;
      request.otpMode = this.state.selectedOption;


      OTPService.SendPassCode(request)
        .then(async (result: any | null) => {
          if (result !== null) {
            console.log(result);

            if (result.status === "Success") {
              toast.success(result.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "passCode1",
              });
              this.setState({ currentStep: 2, otpID: result.otpID });
            }
            else {
              toast.success('Something went wrong', {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "passCode2",
              });
            }

          }
        });
    }
    else {
      this.setState({ currentStep: 2, otpID: 0 });
    }

  }

  handleVerificationPassCode = (event: any) => {
    event.preventDefault();
    let errorMessage = "";

    if (this.state.otp === 0 || this.state.otp === "") {
      errorMessage = "Please enter passcode";
      this.setState({ errorMessage: errorMessage });
    } else {
      this.setState({ loaderTrue: true });

      let storage = JSON.parse(localStorage.getItem("storage")!);
      let userName = storage === null ? 0 : (storage.userName as any);

      let request: any = {};
      request.otpID = this.state.otpID;
      request.userName = userName;
      request.userEmail = "";
      request.userPhone = "";
      request.purpose = this.props?.PageName;
      request.otpCode = this.state.otp;
      request.primaryContact = "";
      request.source = this.props?.Source;
      request.HID = -1;
      request.otpMode = this.state.selectedOption;

      OTPService.ValidatePassCode(request)
        .then(async (result: any | null) => {
          console.log(result);
          if (result.status !== "") {
            if (result.status === "InvalidPassCode") {
              errorMessage = "Incorrect passcode entered. Please try again.";
            } else if (result.status === "PasscodeExpired") {
              errorMessage =
                "Passcode expired. Please click 'Resend Code' to send a new passcode.";
            } else if (result.status === "ValidPassCode") {
              this.props.ValidateOTP(true, this.props?.PageName);
            }
          }
          this.setState({ errorMessage: errorMessage });
          this.setState({ loaderTrue: false });
          resolve();
        })
        .catch((error) => {
          this.setState({ loaderTrue: false });
          reject();
        });
    }

  }

  GoogleAuthFeatureFlag = () => {


    OTPService.GoogleAuthFeatureFlag()
      .then(async (result: any | null) => {

        if (result?.result.length > 0) {
          let googleAuth = result?.result[0].googleAuth;
          let userGoogleFA = result?.result[0].userGoogleFA;
          let primaryMethod = result?.result[0].primaryMethod;
          let textMessage = result?.result[0].textMessage;

          this.setState({
            selectedOption: primaryMethod,
            textMessage: textMessage,
            isgoogleAuth: (googleAuth === "Yes" && userGoogleFA === "Yes")
          })
        }

        resolve();
      })
      .catch((error) => {

        reject();
      });
  }




  render() {
    console.log(this.state.resend);
    
    return (
      <Modal className="otp-modal" show={this.state.ispopupshow} onHide={this.props.closePopup} centered>
        <Modal.Header closeButton>
          <Modal.Title>2-Factor Authentication</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.currentStep == 1 && (
            <div className="step-1">
              {(this.state.isgoogleAuth && this.state.textMessage === "Yes") && (
                <div className="description">
                  <p>Please choose a 2-FA method to receive a verification code:</p>
                </div>
              )}
              {(!this.state.isgoogleAuth && this.state.textMessage === "Yes") && (
                <div className="description">
                  <p>You will receive your 2FA verification code via:</p>
                </div>
              )}
              {(this.state.isgoogleAuth && this.state.textMessage != "Yes") && (
                <div className="description">
                  <p>You will receive your 2FA verification code via:</p>
                </div>
              )}
              <div className="frm-section">
                {this.state.isgoogleAuth && (
                  <Form.Group className="mb-3" controlId="googleAuthenticator">
                    <Form.Check custom type="radio" label="Google Authenticator App"
                      value="google"
                      checked={this.state.selectedOption === "google"}
                      onChange={this.onRadioButtonChange}
                    />
                  </Form.Group>
                )}
                {this.state.textMessage === "Yes" && (
                  <Form.Group className="mb-3" controlId="textMessage">
                    <Form.Check custom type="radio"
                      label={"Text Message (SMS) to ***" + this.state.userMobile}
                      value="sms"
                      checked={this.state.selectedOption === "sms"}
                      onChange={this.onRadioButtonChange}
                    />
                  </Form.Group>
                )}
              </div>
            </div>
          )}
          {this.state.currentStep != 1 && (
            <div className="step-2">

              <div className="description">
                <p>We have sent a verification code to your chosen method.</p>
                <p>Please enter the 6-digit code below:</p>
              </div>

              <Form.Group className="mb-3" controlId="formOtp">
                {/* <Form.Label>Code</Form.Label> */}
                <OtpInput
                  codeLength={6}
                  onInputChange={(value: any) => this.onInputChange(value)}
                />
                {this.state.selectedOption === "sms" && (
                  <div
                    className={!this.state.resend ? "reSendOtp" : "disabledReSendOtp"}
                    onClick={this.handleReSend}
                  >
                    Resend Code
                  </div>
                )}
                <div className="invalid-feedback">
                  {this.state.errorMessage}
                </div>
              </Form.Group>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn wht-bg" onClick={this.props.closePopup}>Cancel</button>
          {this.state.currentStep === 1 && (
            <button type="button" className="btn btn-primary" onClick={this.handleContinue} disabled={this.state.selectedOption.length === 0} >Continue</button>
          )}
          {this.state.currentStep !== 1 && (
            <button type="button" className="btn btn-primary" onClick={this.handleVerificationPassCode} disabled={this.state.otp.length !== 6} >Verify</button>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}
