import * as React from "react";
import { Form, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import "./multiSelectCoaWithSearch.scss";


export class MultiSelectCoaWithSearch extends React.Component<any, any> {
    private inputElement: any;
    private scrollPoint: any;
    constructor(props: any) {
        super(props);
        this.inputElement = React.createRef();
        this.scrollPoint = React.createRef();
        this.state = {
            coaList: this.props?.coaList,
            coaListCopy: this.props?.coaList,
            filterList: this.props?.coaList,
            selectedList: this.props?.selectedMultiCOAs,
            filterText: "",
            filterType: this.props?.filterType,
        };

    }
    componentDidMount() {
        // console.log("props " + JSON.stringify(this.props));
        // console.log(JSON.stringify(this.state));
    }
    
    filterCOA = (event) => {
        // debugger;
        let input = event.target.value;
        let coaList = Object.assign([], JSON.parse(JSON.stringify(this.state.coaList)));
        let filterList = coaList.filter(e => (e.label.toString().toLowerCase().includes(input.toLowerCase())));
        if (input.length > 0) {
            this.setState({ filterText: input, filterList: filterList });
        } else {
            this.setState({ filterText: input, filterList: coaList });
        }
    }
    onChangeCheckbox = (event, item) => {
        //debugger;
        let selectedList = Object.assign([], JSON.parse(JSON.stringify(this.state.selectedList)));
        if (event.target.checked) {
            selectedList.push(item);
        }
        else {
            if (selectedList.filter((x) => x.id === item.id).length > 0) {
                const index = selectedList.findIndex((x) => x.id === item.id);
                selectedList.splice(index, 1);
            }
        }
        this.setState({ selectedList }, () => {
            this.props.onSelectCOAs(this.state.selectedList, this.state.filterType);
        });
    };

    isItemChecked = (id) => {
        let selectedList = Object.assign([], JSON.parse(JSON.stringify(this.state.selectedList)));
        if (selectedList.filter((x) => x.id === id).length > 0) {
            return true;
        }
        else {
            return false;
        }
    }

    handleClearAll = () => {
        this.setState({ selectedList: [] }, () => {
            this.props.onSelectCOAs(this.state.selectedList, this.state.filterType);
        });
    }

    handleToggle = (isOpen, event) => {
        if(isOpen) {
            let coaList = Object.assign([], JSON.parse(JSON.stringify(this.state.coaListCopy)));
            let selectedList = Object.assign([], JSON.parse(JSON.stringify(this.state.selectedList)));
            if(selectedList.length > 0) {
                for(let i = selectedList.length - 1; i >= 0; i--) {
                    for(let j = 0; j < coaList.length; j++) {
                        if(coaList[j].id === selectedList[i].id) {
                            coaList.splice(j, 1);
                            coaList.unshift(selectedList[i]);
                            break;
                        }
                    }
                }
            }
            this.setState({coaList: coaList, filterList: coaList});
            setTimeout(() => {
                this.scrollPoint.current.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            }, 50)
        }
        else {
            this.setState({filterText: ""});
        }
        
        if(!isOpen && this.state.filterType === "main") {
            this.props?.autoSelectDateForCOA();
        }
    }

    render() {
        const onFocusInput = (eventKey: any) => {

            setTimeout(() => {
                this.inputElement.current.focus();
            }, 100)
        };
        return (
            <Dropdown className="multiSelectCoaWithSearch" onClick={onFocusInput} onToggle={this.handleToggle} >
                <Dropdown.Toggle className="inpute-btn" id="SelectCoa">
                    {this.state.selectedList.length > 0 ?
                        <div>
                            {this.state.selectedList.length === 1 && (
                                <div className="value d-flex align-items-center">
                                    <EllipsisWithTooltip placement="bottom">
                                        {this.state.selectedList[0].label}
                                    </EllipsisWithTooltip>
                                </div>
                            )}

                            {this.state.selectedList.length !== 1 && (
                                <div className="value d-flex align-items-center">
                                    <span className="txt d-flex">
                                        <span className="badges">{this.state.selectedList.length}</span>
                                        <EllipsisWithTooltip placement="bottom">
                                            COAs Selected
                                        </EllipsisWithTooltip>
                                    </span>
                                </div>
                            )}
                        </div>
                        : <div className="value d-flex align-items-center placeholder">Select COA</div>
                    }
                    <div className="inpute-icon">
                        <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.28122e-05 1.19724C3.28122e-05 0.889538 0.253262 0.653982 0.543288 0.653982C0.688297 0.653982 0.833313 0.708449 0.923855 0.816677L4.0023 4.0217L7.08074 0.816677C7.2979 0.599519 7.6424 0.599519 7.84116 0.816677C8.04064 1.01616 8.05831 1.34153 7.85955 1.55941L4.40046 5.18112C4.20098 5.39827 3.85721 5.39828 3.64005 5.19951L3.62166 5.18112L0.145009 1.55941C0.054467 1.46887 0 1.34226 0 1.19724L3.28122e-05 1.19724Z" fill="#3F4347" />
                        </svg>
                    </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <div className="search-sec" >
                        <input
                            type="text"
                            className="form-control search-feild"
                            onChange={(e) => this.filterCOA(e)}
                            placeholder="Search"
                            value={this.state.filterText}
                            ref={this.inputElement}
                        />
                    </div>
                    <div className="list-area" ref={this.scrollPoint}>
                        <ul>
                            {this.state.filterList.map((item: any, idx: any) => (
                                <li id={idx} key={idx}>
                                    <Form.Check
                                        custom
                                        type="checkbox"
                                        key={"custom-" + idx}
                                        id={"custom-" + idx}
                                        label={<EllipsisWithTooltip placement="bottom">{item.label}</EllipsisWithTooltip>}
                                        checked={this.isItemChecked(item.id)}
                                        onChange={(e) => this.onChangeCheckbox(e, item)}
                                    />
                                </li>
                            ))}
                        </ul>
                    </div>
                    {this.state.selectedList.length > 0 &&
                        <div className="footer">
                            <button id="ClearAll" type="button" className="btn wht-bg txt-green" onClick={this.handleClearAll}>Clear All</button>
                        </div>
                    }
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}
