import * as React from "react";
import { UserListSpecialPermission } from "../../../Common/Components/UserListSpecialPermission";
import "react-input-range/lib/css/index.css";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"; // {  Search }
import BootstrapTable from "react-bootstrap-table-next";
import { Roles } from "../../../Common/Services/RolePermissionSetting";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { ConfirmationModal } from "../../../Common/Components/ConfirmationModal";
import "../../LaborManagement/RolePermission/table.scss";
import { Dropdown } from "react-bootstrap";
import { Utils } from "../../../Common/Utilis";
export class SpecialPermissionRightPanel extends React.Component<any, any> {
  private listTypeSearch: any;
  constructor(props) {
    super(props);
    this.listTypeSearch = React.createRef();
    this.state = {
      hidValue: 0,
      searchableUserList: [],
      loaderTrue: false,
      isRPanelHide: true,
      specialUserList: [],
      filteredSpecialUserList: [],
      searchedUser: [],
      fieldData: {
        permissionID: 0,
        permissionName: "",
      },
    };
  }

  handleRowClick = (data: any) => {
    this.setState({
      fieldData: data,
      isRPanelHide: false,
      specialUserList: [],
    });
    this.GetSpecialPermissionEmployeeList(data.permissionID);
  };

  GetSpecialPermissionEmployeeList = (permissionID) => {
    Roles.GetSpecialPermissionEmployeeList(permissionID)
      .then(async (result: any[] | null) => {
        if (result != null) {
          this.setState({ specialUserList: result });
        }
        this.setState({ loaderTrue: false });
        this.listTypeSearch.current?.SearchEmployeeForSpecialPermission(
          permissionID
        );
        const removeSelectedChip =
          this.listTypeSearch.current?.handleRemoveSpecificChip();
        removeSelectedChip(0);
        resolve();
      })
      .catch((error) => {
        this.setState({ loaderTrue: false });
        reject();
      });
  };

  selectFromSearchBar = (item: any) => {
    this.setState({ searchedUser: item });
    // 
    //   this.setState({ searchedUser: item }, () => {
    //     let CopyData: any[] = [];
    //     CopyData = Object.assign(
    //       [],
    //       JSON.parse(JSON.stringify(this.state.specialUserList))
    //     );
    //     this.setState({ filteredSpecialUserList: CopyData });
    //   });

    // if (this.empTypeSearch.current != null) {
    //   this.empTypeSearch.current.resetddlItem("Employee Type", false);
    // }

    // if (this.tableTypeSearch.current != null) {
    //   this.tableTypeSearch.current.resetddlItem("Timesheet Status", false);
    // }
  };

  handleSave = () => {
    this.setState({ loaderTrue: true });
    if (
      this.state.searchedUser.uniqueno === "" ||
      this.state.searchedUser.uniqueno === undefined
    ) {
      Utils.toastError("Please select a user", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      this.setState({ loaderTrue: false });
    } else {
      let request = {} as any;
      request.Uniqueno = this.state.searchedUser.uniqueno;
      request.PermissionID = this.state.fieldData.permissionID;
      request.CallType = "INS";

      Roles.SaveDeleteSpecialPermissionUsers(request)
        .then(async (result: any | null) => {
          if (result != null) {
            if (result.message === "Save") {
              this.props?.getVCMPer();
              this.props?.getRoleData();
              toast.success("Permission added successfully.", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
              this.GetSpecialPermissionEmployeeList(
                this.state.fieldData.permissionID
              );
              this.listTypeSearch.current?.SearchEmployeeForSpecialPermission(
                this.state.fieldData.permissionID
              );
              const removeSelectedChip =
                this.listTypeSearch.current?.handleRemoveSpecificChip();
              removeSelectedChip(0);              
            } else if (result.message?.split("_")[0].trim() === "NotAllow") {
              this.setState({ loaderTrue: false });
              Utils.toastError(
                "You can't add this user within " +
                result.result[0].id +
                " hour of removal.",
                {
                  position: toast.POSITION.BOTTOM_RIGHT,
                }
              );
            }
          }
          resolve();
        })
        .catch((error) => {
          this.setState({ loaderTrue: false });
          reject();
        });
    }
  };

  formatColumn(cell: any, row: any, rowIndex: any) {
    let confirmModalContent = [
      {
        title: "Remove Permission",
        desc: "Are you sure you want to remove '" + row.result + "' ?",
        cancleAction: "Cancel",
        ActionTrue: "Remove Permission",
      },
    ];
    return (
      <Dropdown className="more-action hasDropShadow" alignRight>
        <div className="specialClass">
          <Dropdown.Toggle
            className="btn-outline-primary btn btn-primary more fa-trash"
            id="dropdown-more"
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M3.75226 15C3.75226 15.8273 4.42501 16.5 5.25226 16.5H12.7523C13.5795 16.5 14.2523 15.8273 14.2523 15V6H15.7523V4.5H13.5023H12.7523V3C12.7523 2.17275 12.0795 1.5 11.2523 1.5H6.75226C5.92501 1.5 5.25226 2.17275 5.25226 3V4.5H4.50226H2.25226V6H3.75226V15ZM6.75226 3H11.2523V4.5H6.75226V3ZM6.00226 6H12.0023H12.7523L12.753 15H5.25226V6H6.00226Z" />
              <path d="M6.75226 7.5H8.25226V13.5H6.75226V7.5ZM9.75226 7.5H11.2523V13.5H9.75226V7.5Z" />
            </svg>
          </Dropdown.Toggle>
          <Dropdown.Menu className="specialPermissionDeletePanel">
            <ConfirmationModal
              confirmModalContent={confirmModalContent}
              ishandleDeletePosition={this.ishandleDeletePosition.bind(
                this,
                row
              )}
            />
          </Dropdown.Menu>
        </div>
      </Dropdown>
    );
  }

  ishandleDeletePosition(row: any, mode: any) {
    if (mode) {
      this.handleRemoveUser(row);      
    }
  }

  handleRemoveUser = (row: any) => {
    this.setState({ loaderTrue: true });
    let request = {} as any;
    request.Uniqueno = row.id;
    request.PermissionID = this.state.fieldData.permissionID;
    request.CallType = "DEL";

    Roles.SaveDeleteSpecialPermissionUsers(request)
      .then(async (result: any | null) => {
        if (result != null) {
          if (result.result[0].result === "Delete") {
            this.props?.getVCMPer();
            this.props?.getRoleData();
            toast.success("Permission removed successfully.", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            this.GetSpecialPermissionEmployeeList(
              this.state.fieldData.permissionID
            );
            this.listTypeSearch.current?.SearchEmployeeForSpecialPermission(
              this.state.fieldData.permissionID
            );
            const removeSelectedChip =
              this.listTypeSearch.current?.handleRemoveSpecificChip();
            removeSelectedChip(0);           
            this.setState({ loaderTrue: false });
          } else {
            Utils.toastError(result.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        } else {
          Utils.toastError("Internal server error.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error) => {
        reject();
        this.setState({ loaderTrue: false });
      });
  };

  render() {
    const SpecialUsercolumns = [
      {
        dataField: "id",
        text: "",
        hidden: true,
      },
      {
        dataField: "result",
        text: "User Name",
      },

      {
        dataField: "action",
        text: "",
        editable: false,
        formatter: (cell: any, row: any, rowIndex: any) =>
          this.formatColumn(cell, row, rowIndex),
      },
    ];

    return (
      <>
        <ToastContainer enableMultiContainer autoClose={3000} />

        {!this.state.isRPanelHide && (
          <div className="right-panel">
            <div className="pos-header d-flex align-content-center flex-wrap">
              <div className="pos-ttl mr-auto">
                <EllipsisWithTooltip placement="bottom">
                  <div>{this.state.fieldData.permissionName}</div>
                </EllipsisWithTooltip>
              </div>
            </div>

            <div className="pos-body">
              <div className="rolePerRPContent">
                <ToolkitProvider
                  keyField="userUniqueno"
                  data={this.state.specialUserList}
                  columns={SpecialUsercolumns}
                  search
                >
                  {(props: {
                    searchProps: JSX.IntrinsicAttributes;
                    baseProps: JSX.IntrinsicAttributes;
                  }) => (
                    <div className="search-header">
                      <div className="schedule-main">
                        <div className="schedule-department-view-table">
                          <div className="departmentViewNavigation">
                            <div className="navigation-menus d-flex">
                              <div className="schedule-table-search">
                                <UserListSpecialPermission
                                  id="mainView"
                                  ref={this.listTypeSearch}
                                  component="rolePermission"
                                  parentState={this.state}
                                  selectFromSearchBar={this.selectFromSearchBar}
                                />
                              </div>
                              <div style={{ margin: 23 }}>
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={this.handleSave}
                                  disabled={this.state.loaderTrue}
                                >
                                  + Add User
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {this.state.specialUserList.length > 0 && (
                        <div
                          className="position-list LDPsetlastW8"
                          style={{ position: "sticky" }}
                        >
                          <div className="SpecialPermission">
                            <div className="maxlength pos-rel">
                              <BootstrapTable
                                id={"timesheet"}
                                {...props.baseProps}
                                keyField="userUniqueno"
                                hover
                                condensed
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="scroll-area hasCursorPointer"></div>
                    </div>
                  )}
                </ToolkitProvider>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
