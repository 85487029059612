import React from "react";
import { Dropdown, Modal, Button, Form, Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { resolve, reject } from "q";
import { FiChevronDown } from "react-icons/fi";
import "../Register/creditCardStatement/CCPayment.scss";
import DatePicker from "react-datepicker";
import { CCPaymentService } from "../../Common/Services/CCPayment";
import { Register as registersevice } from "../../Common/Services/Register";
import { User } from "../../Common/Services/User";
import { Utils } from "../../Common/Utilis";
import moment from "moment";

let isProcessStart: any = false;
export class CreditCardPayment extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      loaderTrue: false,
      bankList: [],
      acList: [],
      isPMACH: "",
      formPostData: {
        HID: 0,
        vid: 0,
        aid: 0,
        custNo: "",
        cardNo: "",
        vendor: "Loading...",
        invoiceNo: "",
        paymentMethod: "Check",
        acPer: "Loading...",
        selectedAccount: "",
        accountOPrID: "",
        paymentDate: "",
        notes: "",
        tranDate: "",
        payMent: "",
        uniqueno: "",
        hotelCurrentDateTime: "",
        hotelCurrentDate: "",
      },
      acOpeningDate: "",
      isInvoiceExists: false,
    };
  }
  modalClose = () => {
    this.props.hideCCPaymentModal();
  };
  componentDidMount() {
    this.setState({ loaderTrue: true }, () => {
      this.GetCCVendorName();
      this.GetCCBankList();
      this.GetAccountingPeriod();
      this.GetAccountOpeningDate('Load', "");
    });
  }

  GetCCVendorName = () => {
    let request: any = {};
    request.HID = this.props.parentState.registerRequest.hotelID;
    request.CCUniqueNo = this.props.parentState.registerRequest.oprID;
    CCPaymentService.GetCCVendorName(request)
      .then(async (result: any | null) => {
        if (result.message === "Success" && result.result.length > 0) {
          let formPostData = { ...this.state.formPostData };
          formPostData.vendor = result.result[0].vendor;
          formPostData.vid = result.result[0].vid;
          formPostData.aid = result.result[0].aid;
          formPostData.custNo = result.result[0].vendorContract;
          formPostData.cardNo = result.result[0].cardNo;
          formPostData.uniqueno = result.result[0].uniqueno;
          this.setState({
            loaderTrue: false,
            formPostData,
          });
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ loaderTrue: false });
        reject();
      });
  };

  GetHotelCurrentDateTime = () => {
    let request: any = {};
    request.HID = this.props.parentState.registerRequest.hotelID;
    User.GetHotelCurrentDateTime(request).then(async (result: any | null) => {
      if (result != null) {
        let data = result.result;
        let Hours = data.split("T")[1];
        let currentTime = this.formatDate(Hours);
        let HDate = data.split("T")[0];
        let day = HDate.split("-")[1];
        let month = HDate.split("-")[2];
        let year = HDate.split("-")[0];
        let calcdate = day + "/" + month + "/" + year;
        let formPostData = { ...this.state.formPostData };
        formPostData.hotelCurrentDate = moment(calcdate).format("DD-MMM-YYYY")//calcdate;
        formPostData.hotelCurrentDateTime = calcdate + " " + currentTime;
        this.setState({ formPostData });
        console.log("1", this.state.formPostData);
      }
    });
  };

  GetAccountOpeningDate = (callType, e) => {
    let request: any = {};
    request.HID = this.props.parentState.registerRequest.hotelID;
    request.oprID = callType === "Load" ? this.props.parentState.registerRequest.oprID : e.oprID;
    request.Type = callType === "Load" ? "CreditCard" : e.acctType;
    CCPaymentService.GetAccountOpeningDate(request)
      .then(async (result: any | null) => {
        if (result.message === "Success" && result.result.length > 0) {
          this.setState({ acOpeningDate: result.result[0].openingDate });
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ loaderTrue: false });
        reject();
      });
  };

  formatDate(time: string) {
    const dataArray = time.split(":");
    let hh = Number(dataArray[0]);
    let m = Number(dataArray[1]);
    let dd = "AM";
    let h = hh;
    if (h >= 12) {
      h = hh - 12;
      dd = "PM";
    }
    if (h === 0) {
      h = 12;
    }
    let min = m < 10 ? "0" + m : m;
    let replacement = h + ":" + min;
    replacement += " " + dd;
    return replacement;
  }

  GetCCBankList = () => {
    let request: any = {};
    request.HID = this.props.parentState.registerRequest.hotelID;
    CCPaymentService.GetCCBankList(request)
      .then(async (result: any | null) => {
        if (result.message === "Success" && result.result.length > 0) {
          this.setState(
            {
              bankList: result.result,
              loaderTrue: false,
            },
            () => {
              let formPostData = { ...this.state.formPostData };

              let defaultAccount = result.result.filter(
                (nis) => nis.setDefault?.toString().trim().toLowerCase() === "yes"
              );
              formPostData.selectedAccount = defaultAccount[0]?.name;
              this.setState({ formPostData, isPMACH: defaultAccount[0]?.oprID });
            }
          );
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ loaderTrue: false });
        reject();
      });
  };

  GetAccountingPeriod = () => {
    let requestlist: any = {};
    requestlist.hotelID = this.props.parentState.registerRequest.hotelID;
    requestlist.etype = "Operating";
    registersevice
      .GetAccountingPeriod(requestlist)
      .then(async (result: any | null) => {
        if (result !== null) {
          let formPostData = { ...this.state.formPostData };
          formPostData.acPer = result[0].defaultPeriod;
          this.setState({ acList: result, loaderTrue: false, formPostData });
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ loaderTrue: false });
        reject();
      });
    this.GetHotelCurrentDateTime();
  };

  render() {
    const highlightDates = [
      new Date(), // Current date
    ];
    return (
      <div>
        <Modal
          className="SOS CCPaymentModal"
          show={this.props.parentState.CCPaymentShow}
          onHide={this.modalClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          keyboard={false}
          backdrop="static"
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Credit Card Payment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="dept-pos d-flex">
              <Form.Group controlId="select-department">
                <Form.Label>Vendor Name</Form.Label>
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id={"VendorName"}>
                      {this.state.formPostData.vendor}
                    </Tooltip>
                  }>
                  <input
                    className="form-control"
                    type="text"
                    disabled={true}
                    value={this.state.formPostData.vendor}
                  />
                </OverlayTrigger>
              </Form.Group>

              <Form.Group controlId="select-position">
                <Form.Label>
                  Invoice No. <strong className="text-danger">*</strong>
                </Form.Label>
                <input
                  className="form-control"
                  type="text"
                  value={this.state.formPostData.invoiceNo}
                  id="Invoice"
                  maxLength={50}
                  onBlur={() => this.checkExistInvoice()}
                  onChange={(e: any) => this.onInputChange(e, "Invoice")}
                />
              </Form.Group>
            </div>
            <div className="dept-pos d-flex">
              <Form.Group controlId="select-department">
                <Form.Label>Payment Method</Form.Label>
                <div className="singleSelect">
                  <Dropdown className="more-action bg-blue single-select-image">
                    <Dropdown.Toggle id="Modal-hid" className="filter-btn">
                      <div className="d-flex align-items-center">
                        <div className="txt-area">
                          {this.state.formPostData.paymentMethod}
                        </div>
                        <div className="drop-arrow ml-0 d-flex">
                          <FiChevronDown />
                        </div>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="CCPAymentDDL">
                      <Dropdown.Item
                        onClick={(e) =>
                          this.onDropDownClick(e, "paymentMethod")
                        }
                      >
                        Check
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) =>
                          this.onDropDownClick(e, "paymentMethod")
                        }
                      >
                        Manual ACH
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Form.Group>

              <Form.Group controlId="select-position">
                <Form.Label>Accounting Period</Form.Label>
                <div className="singleSelect">
                  <Dropdown className="more-action bg-blue single-select-image">
                    <Dropdown.Toggle id="Modal-hid" className="filter-btn">
                      <div className="d-flex align-items-center">
                        <div className="txt-area">
                          {this.state.formPostData.acPer}
                        </div>
                        <div className="drop-arrow ml-0 d-flex">
                          <FiChevronDown />
                        </div>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="CCPAymentDDL">
                      {this.state.acList.map((item: any, idx: any) => (
                        <>
                          <Dropdown.Item
                            eventKey={item.id}
                            key={idx}
                            onClick={(e) => this.onDropDownClick(e, "acPer")}
                          >
                            <EllipsisWithTooltip placement="bottom">
                              {item.accperiod}
                            </EllipsisWithTooltip>
                          </Dropdown.Item>
                        </>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Form.Group>
            </div>

            {(this.state.formPostData.paymentMethod === "ACH" || this.state.formPostData.paymentMethod === "Manual ACH") && (
              <div className="dept-pos d-flex">
                <Form.Group controlId="select-department">
                  <Form.Label>Account</Form.Label>
                  <div className="singleSelect">
                    <Dropdown className="more-action bg-blue single-select-image">
                      <Dropdown.Toggle id="Modal-hid" className="filter-btn">
                        <div className="d-flex align-items-center">
                          <div className="txt-area">
                            {this.state.formPostData.selectedAccount}
                          </div>
                          <div className="drop-arrow ml-0 d-flex">
                            <FiChevronDown />
                          </div>
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="CCPAymentDDL">
                        {this.state.bankList.map((item: any, idx: any) => (
                          <>
                            <Dropdown.Item
                              eventKey={item.id}
                              key={idx}
                              onClick={(e) =>
                                this.onDropDownClick(item, "selectedAccount")
                              }
                            >
                              <EllipsisWithTooltip placement="bottom">
                                {item.name}
                              </EllipsisWithTooltip>
                            </Dropdown.Item>
                          </>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Form.Group>

                <Form.Group controlId="select-position">
                  <Form.Label>
                    Payment Date <strong className="text-danger">*</strong>
                  </Form.Label>
                  <div className="CCPaymentDate">
                    <div className="date-picker calender-picker">
                      <div className="drop-arrow">
                        <svg
                          width="8"
                          height="6"
                          viewBox="0 0 8 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.28122e-05 1.19724C3.28122e-05 0.889538 0.253262 0.653982 0.543288 0.653982C0.688297 0.653982 0.833313 0.708449 0.923855 0.816677L4.0023 4.0217L7.08074 0.816677C7.2979 0.599519 7.6424 0.599519 7.84116 0.816677C8.04064 1.01616 8.05831 1.34153 7.85955 1.55941L4.40046 5.18112C4.20098 5.39827 3.85721 5.39828 3.64005 5.19951L3.62166 5.18112L0.145009 1.55941C0.0544669 1.46887 0 1.34226 0 1.19724L3.28122e-05 1.19724Z"
                            fill="#3F4347"
                          />
                        </svg>
                      </div>
                      <DatePicker
                        id="paymentDate"
                        className="form-control CCPaymentDatePicker"
                        placeholderText={"MM/DD/YY"}
                        dateFormat="MM/dd/yy"
                        autoComplete="off"
                        selected={
                          this.state.formPostData.paymentDate !== ""
                            ? new Date(this.state.formPostData.paymentDate)
                            : this.state.formPostData.paymentDate
                        }
                        highlightDates={
                          this.state.formPostData.paymentDate ===
                          "" && highlightDates
                        }
                        onChange={(event: any) =>
                          this.onDropDownClick(event, "paymentDate")
                        }
                      />
                    </div>
                  </div>
                </Form.Group>
              </div>
            )}

            <Form.Group controlId="addtimeoff-reason">
              <Form.Label
                style={{
                  fontSize: 14,
                  color: "#1d1e20",
                  fontFamily: "OpenSans-Semibold",
                }}
              >
                Notes
              </Form.Label>
              <Form.Control
                className="form-control"
                as="textarea"
                maxLength={200}
                required
                value={this.state.formPostData.notes}
                id="notes"
                onChange={(e: any) => this.onInputChange(e, "notes")}
              />
            </Form.Group>
            <div className="react-bootstrap-table">
              <Table id="tblSplit" className="CCPaymentTable react-bootstrap-table table-hover table-condensed">
                <thead>
                  <tr className="bg-light">
                    <th>EHID</th>
                    <th>
                      Statement Date <strong className="text-danger">*</strong>
                    </th>
                    <th>Balance</th>
                    <th>
                      Payment <strong className="text-danger">*</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-light">
                    <td>
                      <Form.Control
                        type="text"
                        className="form-control CCRow12"
                        readOnly
                        value={this.props.parentState.hotelName}
                      />
                    </td>
                    <td>
                      <div className="date-picker calender-picker">
                        <div className="drop-arrow">
                          <svg
                            width="8"
                            height="6"
                            viewBox="0 0 8 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.28122e-05 1.19724C3.28122e-05 0.889538 0.253262 0.653982 0.543288 0.653982C0.688297 0.653982 0.833313 0.708449 0.923855 0.816677L4.0023 4.0217L7.08074 0.816677C7.2979 0.599519 7.6424 0.599519 7.84116 0.816677C8.04064 1.01616 8.05831 1.34153 7.85955 1.55941L4.40046 5.18112C4.20098 5.39827 3.85721 5.39828 3.64005 5.19951L3.62166 5.18112L0.145009 1.55941C0.0544669 1.46887 0 1.34226 0 1.19724L3.28122e-05 1.19724Z"
                              fill="#3F4347"
                            />
                          </svg>
                        </div>
                        <DatePicker
                          id="tranDate"
                          className="form-control CCRow1"
                          placeholderText={"MM/DD/YY"}
                          dateFormat="MM/dd/yy"
                          autoComplete="off"
                          selected={
                            this.state.formPostData.tranDate !== ""
                              ? new Date(this.state.formPostData.tranDate)
                              : this.state.formPostData.tranDate
                          }
                          highlightDates={
                            this.state.formPostData.tranDate ===
                            "" && highlightDates
                          }
                          onChange={(event: any) =>
                            this.onDropDownClick(event, "tranDate")
                          }
                        />
                      </div>
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        className="form-control CCRow2"
                        readOnly
                        value={this.props.parentState.totalAcctBal}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        id="payMent"
                        className="form-control CCRow2"
                        value={this.state.formPostData.payMent}
                        onChange={(e: any) => this.onInputChange(e, "payMent")}
                        onBlur={(e: any) => this.amountBlur(e)}
                        onFocus={(e: any) => this.onSplitAmountFocus(e, "payMent")}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={this.modalClose}
              className="CCPaymentCancel btn wht-bg"
            >
              Cancel
            </button>
            <Button
              disabled={this.state.loaderTrue || isProcessStart}
              className="import-forecast-button"
              onClick={() => this.handleSave()}
              onKeyDown={(e) => { this.startProcess(e) }}
            >
              Save Entry
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  startProcess = (e: any) => {
    isProcessStart = true;
  }

  checkExistInvoice = () => {
    this.setState({ loaderTrue: true });
    let request: any = {};
    request.HID = this.props.parentState.registerRequest.hotelID;
    request.InvoiceNo = this.state.formPostData.invoiceNo;
    request.Vid = this.state.formPostData.vid;
    if (
      this.state.formPostData.invoiceNo.trim() !== "" &&
      this.state.formPostData.vid != 0
    ) {
      CCPaymentService.CheckExistInvoice(request)
        .then(async (result: any | null) => {
          if (result.message === "Success" && result.result.length > 0) {
            if (
              result.result[0].isDiffHIDInvoice.toLowerCase() === "yes" ||
              result.result[0].isSameHIDInvoice.toLowerCase() === "yes"
            ) {
              this.setState(
                {
                  isInvoiceExists: true,
                  isCheckInvoice: true,
                  loaderTrue: false,
                },
                () => {
                  $("#Invoice").addClass("alert-danger");
                }
              );
              Utils.toastError("This invoice no. already exists.", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            } else {
              this.setState({
                isInvoiceExists: false,
                isCheckInvoice: true,
                loaderTrue: false,
              });
            }
          }
          resolve();
        })
        .catch((error) => {
          this.setState({ loaderTrue: false });
          reject();
        });
    } else {
      this.setState({ loaderTrue: false });
    }
  };
  compareAcOpeningDate = () => {
    if (this.state.formPostData.paymentMethod === "ACH" || this.state.formPostData.paymentMethod === "Manual ACH") {
      if (
        new Date(this.state.formPostData.paymentDate) <
        new Date(this.state.acOpeningDate)
      ) {
        Utils.toastError(
          "Please select the Payment date greater than Account Opening date.",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
        $("#paymentDate").addClass("alert-danger");
      }
    }
  };

  onInputChange = (e: any, inputType: any) => {
    let formPostData = { ...this.state.formPostData };
    if (inputType === "Invoice") {
      this.setState({ loaderTrue: true });
      $("#Invoice").removeClass("alert-danger");
      let inPutText = e.currentTarget.value;
      if (inPutText.trim() !== "") {
        inPutText = inPutText.trimLeft()
      }
      formPostData.invoiceNo = inPutText;
      this.setState({ formPostData });
    }
    if (inputType === "notes") {
      formPostData.notes = e.currentTarget.value;
    }
    // if (inputType === "payMent" && Number(e.currentTarget.value.replace(/[@\/\\^\/\\&\/\\#,+()$~%'":*?<>{}-]/g, '')) >= 0) {
    if (inputType === "payMent") {
      // let val = e.currentTarget.value.replace(/[@\/\\^\/\\&\/\\#,+()$~%'":*?<>{}-]/g, '')
      // if (val.includes(".")) {
      //   const arr = val.split(".");
      //   if (arr.length <= 2 && arr[1].length <= 2) {
      //     formPostData.payMent = val;
      //   }
      // } else {
      //   formPostData.payMent = val;
      // }


      let newInputVal = Utils.removeInvaildCharFromAmount(e.currentTarget.value);
      e.currentTarget.value = newInputVal;

      const rx_live = /^[-]?\d*(?:[.]\d*)?$/;
      let lenbeforePoint = 8;
      if (e.currentTarget.value.includes("-")) {
        lenbeforePoint = 9;
      }

      if (rx_live.test(e.currentTarget.value)) {
        if (e.currentTarget.value.includes(".")) {
          const timeArray = (e.currentTarget.value)?.split(".");
          if (timeArray[0].length <= lenbeforePoint && timeArray[1].length <= 2) {
            formPostData.payMent = e.currentTarget.value;
          }
        } else {
          if (e.currentTarget.value.length <= lenbeforePoint) {
            formPostData.payMent = e.currentTarget.value;
          }
        }
      } else if (e.currentTarget.value.includes(".") && e.currentTarget.value.length === 1) {
        formPostData.payMent = e.currentTarget.value;
      }

      $("#payMent").removeClass("alert-danger");
    }
    this.setState({ formPostData });
  };

  onSplitAmountFocus = (e, txtID) => {
    let formPostData = { ...this.state.formPostData };
    let newval = Utils.removecurrencyFormat(e.target.value);
    formPostData.payMent = newval;
    this.setState({ formPostData }, () => {
      $("#tblSplit").find("input[id^='" + txtID + "']").focus();
    });
  }

  amountBlur = (e) => {
    let formPostData = { ...this.state.formPostData };
    if (e.target.value.includes(".")) {
      const timeArray = (e.target.value)?.split(".");
      if (timeArray[1].length <= 0) {
        formPostData.payMent = Utils.currencyFormatWithOutdoller(timeArray[0]);
      }
      else {
        formPostData.payMent = Utils.currencyFormatWithOutdoller(e.target.value);
      }

    } else {
      formPostData.payMent = Utils.currencyFormatWithOutdoller(e.target.value); //timeArray[0];
    }
    this.setState({ formPostData })
  }

  onDropDownClick = (e: any, ddnType: any) => {
    let formPostData = { ...this.state.formPostData };
    if (ddnType === "paymentMethod") {
      formPostData.paymentMethod = e.currentTarget.text;
      if (e.currentTarget.text === "ACH" || e.currentTarget.text === "Manual ACH") {
        formPostData.accountOPrID = this.state.isPMACH;
        let { bankList } = this.state;
        let arrObj = {} as any;
        arrObj.acctType = bankList[0].acctType;
        arrObj.oprID = bankList[0].oprID;
        this.GetAccountOpeningDate("Dropdown", arrObj);
      } else {
        formPostData.accountOPrID = this.props.parentState.registerRequest.oprID
      }
    }
    if (ddnType === "acPer") {
      formPostData.acPer = e.currentTarget.text;
    }
    if (ddnType === "selectedAccount") {
      formPostData.selectedAccount = e.name;
      formPostData.accountOPrID = e.oprID;
      this.GetAccountOpeningDate("Dropdown", e);
    }
    if (ddnType === "paymentDate" && e !== "") {
      formPostData.paymentDate = e;
      //this.setState({ formPostData });
      //this.compareAcOpeningDate();
    }
    if (ddnType === "tranDate" && e !== "") {
      formPostData.tranDate = e;
    }

    this.setState({ formPostData });
  };

  checkValidation = () => {
    let isValid: any = [];
    let focusSet = false;
    if (this.state.formPostData.invoiceNo?.trim() === "") {
      $("#Invoice").focus();
      $("#Invoice").addClass("alert-danger");
      focusSet = true;
      isValid.push(false);
    } else {
      $("#Invoice").blur();
      isValid.push(true);
      focusSet = focusSet ? true : false;
    }
    if (this.state.formPostData.paymentMethod === "ACH" || this.state.formPostData.paymentMethod === "Manual ACH") {
      if (this.state.formPostData.paymentDate === "") {
        if (!focusSet) {
          //$("#paymentDate").focus();
          focusSet = true;
        }
        $("#paymentDate").addClass("alert-danger");
        isValid.push(false);
      } else {
        focusSet = focusSet ? true : false;
        $("#paymentDate").blur();
        isValid.push(true);
      }
    }
    if (this.state.formPostData.tranDate === "") {
      if (!focusSet) {
        //$("#tranDate").focus();
        focusSet = true;
      }
      $("#tranDate").addClass("alert-danger");
      isValid.push(false);
    } else {
      focusSet = focusSet ? true : false;
      $("#tranDate").blur();
      isValid.push(true);
    }
    if (this.state.formPostData.payMent === "") {
      if (!focusSet) {
        $("#payMent").focus();
        focusSet = true;
      }
      $("#payMent").addClass("alert-danger");
      isValid.push(false);
    } else {
      focusSet = focusSet ? true : false;
      $("#payMent").blur();
      isValid.push(true);
    }

    const returnValue = isValid.some((item) => item === false);

    if (!returnValue) {
      if (
        $("#Invoice").hasClass("alert-danger") &&
        this.state.vendorForCreate !== ""
      ) {
        return "Invoice";
      } else {
        $("#Invoice").removeClass("alert-danger");
      }
    }
    return returnValue ? "Required" : "Succeed";
  };

  handleSave = () => {
    this.setState({ loaderTrue: true });
    let isValid = "";
    isValid = this.checkValidation();
    if (isValid === "Succeed") {
      if (this.state.formPostData.paymentMethod === "ACH" || this.state.formPostData.paymentMethod === "Manual ACH") {
        if (this.state.formPostData.paymentDate !== "") {
          if (
            new Date(this.state.formPostData.paymentDate) <
            new Date(this.state.acOpeningDate)
          ) {
            Utils.toastError(
              "Please select the Payment date greater than Account Opening date.",
              {
                position: toast.POSITION.BOTTOM_RIGHT,
              }
            );
            $("#paymentDate").addClass("alert-danger");
            this.setState({ loaderTrue: false });
            isProcessStart = false;
            return false;
          }
        }
      }
      let request: any = {};
      request.invoiceNo =
        this.state.formPostData.invoiceNo?.trim().length > 50
          ? this.state.formPostData.invoiceNo.substring(0, 50)?.trim()
          : this.state.formPostData.invoiceNo?.trim();
      request.amount = Utils.removecurrencyFormat(this.state.formPostData.payMent);
      request.acPeriod = this.state.formPostData.acPer;
      request.statementDate = moment(this.state.formPostData.tranDate).format("DD-MMM-YYYY");//this.state.formPostData.tranDate;
      request.dueDate = moment(this.state.formPostData.tranDate).format("DD-MMM-YYYY");//this.state.formPostData.tranDate;
      request.internalNotes = this.state.formPostData.notes;
      request.backHid = this.props.parentState.registerRequest.hotelID;
      request.payType = this.state.formPostData.paymentMethod;
      request.payType = request.payType.toString().toLowerCase() === "Manual ACH".toLowerCase() ? "ACH" : request.payType;
      request.uniqueNo = this.state.formPostData.uniqueno;
      request.invoiceStatus = "";
      request.custNo = this.state.formPostData.custNo;
      request.oprID =
        (this.state.formPostData.paymentMethod === "ACH" || this.state.formPostData.paymentMethod === "Manual ACH")
          ? this.state.formPostData.accountOPrID
          : this.props.parentState.registerRequest.oprID;
      request.aid = this.state.formPostData.aid;
      request.vid = this.state.formPostData.vid;
      request.approve = "Not";
      request.entrydate = moment(new Date()).format("DD-MMM-YYYY");
      request.checkNo = "";
      request.chkDate = "";
      request.pvrNo = "";
      request.pvrDate = "";
      request.pyear = "";
      request.gdate =
        (this.state.formPostData.paymentMethod === "ACH" || this.state.formPostData.paymentMethod === "Manual ACH")
          ? moment(this.state.formPostData.paymentDate).format("DD-MMM-YYYY")
          : this.state.formPostData.hotelCurrentDateTime;
      // request.entryType = "";
      // request.fileName = "";
      // request.uploadFile = "";
      request.series = "";
      request.repUniqueNo = "";
      request.repType = "";
      request.startDate = "";
      request.enddate = "";
      request.startDate1 = "";
      request.aDate = this.state.formPostData.hotelCurrentDate;
      request.firstAccPeriod = "";
      request.lastAccPeriod = "";
      request.ruleID = "";

      CCPaymentService.SaveCCPayment(request)
        .then(async (result: any | null) => {
          if (result.message.toLowerCase() === "success") {
            this.modalClose();
            isProcessStart = false;
            toast.success("Credit card payment saved successfully.", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else if (result.message.toLowerCase() === "notactive") {
            Utils.toastError("Accounting period is not active.", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else if (result.message.toLowerCase() === "nocontrcat") {
            Utils.toastError("There is no contact for this vendor.", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else if (result.message.toLowerCase() === "fail") {
            Utils.toastError("Something went wrong.", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          this.setState({ loaderTrue: false });

          resolve();
        })
        .catch((error) => {
          this.setState({ loaderTrue: false });
          isProcessStart = false;
          reject();
        });
    } else if (isValid === "Required") {
      Utils.toastError("Please fill the required fields.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      isProcessStart = false;
      this.setState({ loaderTrue: false });
    } else if (isValid === "Invoice") {
      Utils.toastError("This invoice no. already exists.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      isProcessStart = false;
      this.setState({ loaderTrue: false });
    }
  };
}
