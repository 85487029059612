import * as React from "react";
import { ReactPageLoader } from "../../../Common/Components/ReactPageLoader";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { AddRoomConfiguration } from "./AddRoomConfiguration";
import {
  Button,
  Col,
  Dropdown,
  Form,
  OverlayTrigger,
  Tooltip,
  Row,
} from "react-bootstrap";
import { AddRoomType } from "./AddRoomType";
import { AddConferenceRoom } from "./AddConferenceRoom";

import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import moment from "moment";
import Calendar from "react-calendar";
import chevronRight from "../../../Common/Assets/Images/icons/bx-chevron-right.svg";
import OutsideClickHandler from "react-outside-click-handler";
import { EntityManagementService } from "../../../Common/Services/EntityManagementService";
import { toast } from "react-toastify";
import { EntityGroupMaster } from "./EntityGroupMaster";
import { AddGroup } from "./AddGroup";
import { EditGroup } from "./EditGroup";
import ReactTooltip from "react-tooltip";
import { DropDownMultiListPopUp } from "../../Reports/common-components/dropdown-multi-list-pop-up";
import { ConfirmationModal } from "../../../Common/Components/ConfirmationModal";
import { IHotelResponseDto } from "../../../Common/Contracts/IHotel";
import { Hotel } from "../../../Common/Services/Hotel";
import _ from "lodash";
import { DuplicateGroup } from "./DuplicateGroup";
import { ChangeCategory } from "./ChangeCategory";
import MasterRoleLock from "../../../Common/Assets/Images/icons/forecast-lock.svg";
import { Utils } from "../../../Common/Utilis";

const { SearchBar } = Search;
function checkCommonHids(newHids, oldHids) {
  // Check if any `hid` in newHids exists in oldHids
  for (let hid of newHids) {
    if (oldHids.includes(hid)) {
      return true; // Common `hid` found, return false
    }
  }
  return false; // No common `hid` found, return true
}

export class EntityGroups extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      leftPanelLoader: false,
      rightPanelLoader: false,
      showAddRoomTypeModal: false,
      showCategoryModal: false,
      leftPanelDates: [],
      showAddCategoryTextBox: false,
      categoryName: "",
      categoryId: 0,
      isSelected: false,
      selected: [],
      isManual: "",
      selectedCategoryName: "",
      entityGroupsData: [],
      entityGroupsTableExpanded: [],
      isEntityGroupsExpand: false,
      showDuplicateGroupModal: false,
      showChangeCategoryModal: false,
      entityGroupsRowEdit: {},
      editGroupRow: {},
      rightTableData: [],
      rightTableChildData: [],
      isGroupMappingModified: false,
      isDataSubmitting: false,
      showEditGroupModal: false,
      hotels: [],
      hotelsData: [],
    };
  }

  componentDidMount() {
    this.getHotelGroupMapping();
    this.getHotels();
  }
  getHotelGroupMapping = () => {
    this.setState({ leftPanelLoader: true });
    EntityManagementService.GetHotelGroupMapping()
      .then((entityGroupsData: any) => {
        const _entityGroupsData = [
          ..._.sortBy(
            entityGroupsData?.result?.filter((x) => x?.isManual === "No") || [],
            "categroyName"
          ),
          ..._.sortBy(
            entityGroupsData?.result?.filter((x) => x?.isManual === "Yes") ||
              [],
            "categroyName"
          ),
        ];

        this.setState({ entityGroupsData: [] }, () =>
          this.setState({ entityGroupsData: _entityGroupsData })
        );
        if (+this.state.selected[0]) {
          const rightTableData: any[] =
            _entityGroupsData?.find(
              (x) => +x?.categoryId === +this.state.selected[0]
            )?.groupWithHotels || [];
          this.setState({ rightTableData });
          if (+this.state.entityGroupsTableExpanded[0]) {
            this.setState(
              {
                rightTableChildData:
                  rightTableData?.find(
                    (x) =>
                      +x?.rGoupId === +this.state.entityGroupsTableExpanded[0]
                  )?.hotels || [],
              },
              () => {
                const hotels = [...this.state.hotelsData];
                let hotelsData = (hotels || [])?.map((l: any) => {
                  if (
                    (this.state?.rightTableChildData || [])
                      .map((x) => x.hotelID)
                      .includes(l.id)
                  ) {
                    l.isChecked = true;
                  } else {
                    l.isChecked = false;
                  }
                  return l;
                });

                if (hotelsData?.length > 0) {
                  const hids = _.uniq(
                    [].concat.apply(
                      [],
                      [].concat.apply(
                        [],
                        this.state?.entityGroupsData
                          .filter(
                            (x) => +x?.categoryId === +this.state?.selected[0]
                          )
                          ?.map(
                            (x) =>
                              x?.groupWithHotels?.map((x) =>
                                (x?.hotels || [])?.map((x) => x?.hotelID)
                              ) || []
                          )
                      )
                    )
                  );
                  hotelsData = hotelsData?.filter(
                    (x) => !hids?.includes(x.id) || x.isChecked
                  );
                }

                this.setState(() => ({
                  hotels: hotelsData,
                }));
              }
            );
          }
        }
      })
      .finally(() => {
        this.setState({ leftPanelLoader: false });
      });
  };

  //sujan
  discard = () => {
    const hotels = [...this.state.hotelsData];
    const hotelsData = hotels.map((l: any) => {
      l.isChecked = false;
      return l;
    });

    this.setState({
      entityGroupsTableExpanded: [],
      hotels: hotelsData,
      entityGroupsRowEdit: {},
      //  speacialRates: [],
      isEntityGroupsExpand: false,
      isGroupMappingModified: false,
      showAddCategoryTextBox: false,
      categoryName: "",
      rightTableChildData: [],
    });
    this.getHotelGroupMapping();
  };
  getHotels = () => {
    Hotel.getUserHotelAccessUM().then(
      async (response: IHotelResponseDto[] | null) => {
        let hotels = [
          ..._.sortBy(
            response?.map((item) => ({
              id: item.hotelID,
              name: item.hotelName,
              status: item?.status,
              legalName: item.hotelName?.split("-")[1]?.toString()?.trim(),
              isChecked: false,
            })),
            "name"
          ),
        ];

        this.setState({ hotels, hotelsData: hotels });
      }
    );
  };

  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      if(this.state.isGroupMappingModified)
      {
        this.getHotelGroupMapping();
      }
      
      this.setState({
        showAddCategoryTextBox: false,
        categoryName: "",
        rightTableData: row.groupWithHotels,
        isSelected: true,
        isManual: row?.isManual,
        selected: [row?.categoryId],
        selectedCategoryName: row?.categroyName,
        isGroupMappingModified: false,
      });
    },
  };

  duplicateCategory = (categoryName) => {
    this.setState({
      categoryName: "Copy - " + categoryName,
      categoryId: 0,
      showAddCategoryTextBox: true,
      isGroupMappingModified: true,
    });
  };

  editCategory = (categoryName) => {
    this.setState({
      categoryName,
      categoryId: this.state.selected[0],
      showAddCategoryTextBox: true,
      isGroupMappingModified: true,
    });
  };

  saveData = (saveType) => {
    if (saveType === "category") {
      const { categoryName, categoryId } = this.state;
      if (this.checkIfCategoryNameAlreadyExsit(categoryName)) {
        Utils.toastError("Please enter different category name.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "addDocument",
        });
        return;
      }
      this.setState({ isDataSubmitting: true });
      EntityManagementService.AddHotelCategory(categoryName, categoryId)
        .then((result) => {
          if (result?.message === "Success") {
            this.setState(
              {
                showAddCategoryTextBox: false,
                categoryName: "",
                isSelected: true,
                isManual: result?.result?.is_Manual,
                selected: [result?.result?.uniqueno],
                selectedCategoryName: categoryName,
                isGroupMappingModified: false,
              },
              () => this.getHotelGroupMapping()
            );
            toast.success(
              categoryId > 0
                ? "Category changed successfully"
                : "Category saved successfully",
              {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "addGarnishment",
              }
            );
          } else {
            Utils.toastError(result?.result, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "addGarnishment",
            });
          }
        })
        .catch((error) => {
          Utils.toastError(error?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addGarnishment",
          });
        })
        .finally(() => {
          this.setState({ isDataSubmitting: false });
        });
    }
    if (saveType === "groupEntities") {
      const { selected, rightTableData } = this.state;

      const groupWithHotels = rightTableData?.map((item) => ({
        rGoupId: item?.rGoupId || 0,
        groupName: item.groupName,
        hids: (item?.hotels || []).map((x) => x.hotelID),
      }));

      const request: any = {};
      request.categoryId = selected[0];
      request.groupWithHotels = groupWithHotels;
      this.setState({ isDataSubmitting: true });
      EntityManagementService.SaveGroupWithHotel(request)
        .then((result) => {
          this.getHotelGroupMapping();
          if (result?.message === "Success") {
            toast.success("Group data saved successfully.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "addGarnishment",
            });
            this.setState({ isGroupMappingModified: false });
          } else {
            Utils.toastError(result?.result, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "addGarnishment",
            });
          }
        })
        .catch((error) => {
          Utils.toastError(error?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addGarnishment",
          });
        })
        .finally(() => {
          this.setState({ isDataSubmitting: false });
        });
    }
  };

  editRoomType = (row) => {
    this.setState({ entityGroupsRowEdit: row }, () => {
      this.setState({ showAddRoomTypeModal: true });
    });
  };

  showHideCategoryTextBox = () => {
    if (!this.state.showAddCategoryTextBox) {
      this.setState({
        categoryId: 0,
        selected: [],
        isSelected: false,
        rightTableData: [],
        selectedCategoryName: "",
      });
    }
    this.setState({
      showAddCategoryTextBox: !this.state.showAddCategoryTextBox,
    });
  };

  onCategoryChange = (e) => {
    let value = e?.target?.value?.toString()?.trimStart();
    if (value?.toString().length >= 100) {
      return;
    }
    if (value) {
      value = Utils.CapitalizeFirstLetter(value);
    }
    value = Utils.AllowOnlyAlphabetsWithRightSpace(value);
    value = typeof value === "string" ? value?.toString()?.trimLeft() : value;
    this.setState({ categoryName: value, isGroupMappingModified: true });
    if (!value) {
      this.setState({ isGroupMappingModified: false });
    } else {
      this.setState({ isGroupMappingModified: true });
    }
  };

  checkIfCategoryNameAlreadyExsit = (value) => {
    const categoryNames =
      this.state?.categoryId > 0
        ? this.state?.entityGroupsData
            ?.filter((x) => x.categoryId !== this.state?.categoryId)
            ?.map((l: any) => l?.categroyName)
        : this.state?.entityGroupsData?.map((l: any) => l?.categroyName) || [];
    return (
      categoryNames.filter(
        (x) => x?.toString()?.toLowerCase() === value?.toString()?.toLowerCase()
      )?.length > 0
    );
  };

  onRightTableOptionsSelection = (e: any, rowIndex: any, value) => {
    const rightTableData = [...this.state.rightTableData];
    if (value === "changeCategory") {
      rightTableData[rowIndex].dropDownListName = "changeCategory";
    } else {
      rightTableData[rowIndex].dropDownListName = "options";
    }
    this.setState((curr: any) => ({ ...curr, rightTableData }));
  };

  checkIfGroupNameAlreadyExist = (value, groupId = 0) => {
    const groupNames =
      groupId > 0
        ? [...this.state?.rightTableData]
            ?.filter((x) => x?.rGoupId !== groupId)
            ?.map((l: any) => l?.groupName)
        : [...this.state?.rightTableData]?.map((l: any) => l?.groupName) || [];
    return (
      groupNames?.filter(
        (x) => x?.toString()?.toLowerCase() === value?.toString()?.toLowerCase()
      )?.length > 0
    );
  };
  addGroup = (groupName) => {
    if (this.checkIfGroupNameAlreadyExist(groupName)) {
      Utils.toastError("Please enter different group name.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "addDocument",
      });
      return;
    }

    const rightTableData = [...this.state?.rightTableData];
    const data: any = {
      rGoupId: 0,
      groupName,
      hotels: [],
    };
    rightTableData.push(data);
    this.setState({
      rightTableData,
      rightTableChildData: [],
      isGroupMappingModified: true,
    });
  };

  editGroup = (groupName, groupId) => {
    if (this.checkIfGroupNameAlreadyExist(groupName, groupId)) {
      Utils.toastError("Please enter different group name.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "addDocument",
      });
      return;
    }
    const rightTableData = [...this.state?.rightTableData];
    const index = rightTableData.findIndex((x) => x?.rGoupId === groupId);
    if (index >= 0) {
      rightTableData[index].groupName = groupName;
    }
    this.setState({ rightTableData, isGroupMappingModified: true });
  };

  deleteConfereneRoom = (row) => {
    EntityManagementService.DeleteConferenceRooms(row.roomID)
      .then((result) => {
        if (result?.message === "Success") {
          toast.success("Conference room deleted successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addGarnishment",
          });
        } else {
          Utils.toastError(result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addGarnishment",
          });
        }
      })
      .catch((error) => {
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "addGarnishment",
        });
      });
  };

  handleOnEntityGroupsExpand = (
    row: any,
    isExpand: any,
    rowIndex: any,
    e: any
  ) => {
    if (this.state?.entityGroupsTableExpanded?.length > 0) {
      const hotels = [...this.state.hotelsData];
      const hotelsData = hotels.map((l: any) => {
        l.isChecked = false;
        return l;
      });

      this.setState({
        hotels: hotelsData,
        rightTableChildData: [],
        entityGroupsRowEdit: {},
        //  speacialRates: [],
        isEntityGroupsExpand: false,
      });
      this.setState(() => ({ entityGroupsTableExpanded: [] }));
    } else {
      const hotels = [...this.state.hotelsData];
      let hotelsData = (hotels || [])?.map((l: any) => {
        if ((row?.hotels || []).map((x) => x.hotelID).includes(l.id)) {
          l.isChecked = true;
        }
        return l;
      });

      if (hotelsData?.length > 0) {
        const hids = _.uniq(
          [].concat.apply(
            [],
            [].concat.apply(
              [],
              this.state?.entityGroupsData
                .filter((x) => +x?.categoryId === +this.state?.selected[0])
                ?.map(
                  (x) =>
                    x?.groupWithHotels?.map((x) =>
                      (x?.hotels || [])?.map((x) => x?.hotelID)
                    ) || []
                )
            )
          )
        );
        hotelsData = hotelsData?.filter(
          (x) => !hids?.includes(x.id) || x.isChecked
        );
      }

      this.setState(() => ({
        hotels: hotelsData,
        isEntityGroupsExpand: true,
        entityGroupsRowEdit: row,
        rightTableChildData: row?.hotels || [],
        entityGroupsTableExpanded: [row?.rGoupId],
      }));
      //  this.getUserSpecialRates(row?.hid);
    }
  };

  dataChange = () => {
    this.setState({ isUnsavedChanges: true });
  };

  updateMultiSelectItem = (selectedItems: any) => {
    // debugger;
    const hotelNeedsToPush = selectedItems.map((item) => ({
      accountName: "",
      address1: "",
      astreet: "",
      bCountry: "",
      bPostalCode: "",
      bState: "",
      bankAdd: "",
      bankAdd2: "",
      bname: "",
      city: "",
      company_Code: null,
      country: "",
      daAutoImport: null,
      displayHotelid: "",
      doubleOverTime: "",
      fax: "",
      holidayRate: "",
      hotelID: item?.id,
      lastchkno: "",
      legalName: item?.legalName,
      lettercode: item?.name.split("-")[0]?.toString()?.trim(),
      mBCity: null,
      mBCountry: null,
      mBPostalCode: null,
      mBState: null,
      machineID: null,
      mbAddress: null,
      mbName: null,
      mlastchkno: null,
      modifiedByUserId: -1,
      modifiedDate: "",
      nonUS: "",
      overTime: "",
      payAddress: null,
      payCity: null,
      payCountry: null,
      payLastchkno: null,
      payName: null,
      payPostalCode: null,
      payState: null,
      phone: "",
      pname: "",
      postalCode: "",
      province: "",
      reserves: 0,
      reservesType: "",
      rooms: "",
      salesTaxRate: null,
      startingDate: "",
      state: "",
      status: "",
      strCode: "",
      taxWithholding: "",
      tenantID: "",
      timeZone: null,
      tripAdvisorLink: "",
      type: "",
    }));

    const { entityGroupsRowEdit, rightTableData } = this.state;
    const tableIndex = rightTableData?.findIndex(
      (x) => x.groupName === entityGroupsRowEdit?.groupName
    );
    let hotels = [
      ...(rightTableData[tableIndex]?.hotels || []),
      ...hotelNeedsToPush,
    ];
    hotels = _.uniqBy(
      hotels.filter((x) =>
        hotelNeedsToPush.map((l) => l?.hotelID)?.includes(x?.hotelID)
      ),
      "hotelID"
    );
    rightTableData[tableIndex].hotels = hotels;
    const rightTableChildData = hotels;

    this.setState({
      rightTableData,
      rightTableChildData,
      isHotelChanges: true,
      isGroupMappingModified: true,
    });
    // entityGroupsRowEdit
  };

  deleteHotelCategory = () => {
    EntityManagementService.DeleteHotelCategory(this.state?.selected[0])
      .then((result) => {
        if (result?.message === "Success") {
          this.getHotelGroupMapping();

          this.setState({
            categoryId: 0,
            selected: [],
            isSelected: false,
            selectedCategoryName: "",
          });
          toast.success("Category deleted successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addGarnishment",
          });
        } else {
          Utils.toastError(result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addGarnishment",
          });
        }
      })
      .catch((error) => {
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "addGarnishment",
        });
      });
  };

  deleteHotelCategoryGroup = (row) => {
    if (row?.rGoupId === 0) {
      const { rightTableData } = this.state;
      const _rightTableData = rightTableData?.filter(
        (x) => x.groupName !== row?.groupName
      );

      const hotels = [...this.state.hotelsData];
      const hotelsData = hotels.map((l: any) => {
        l.isChecked = false;
        return l;
      });

      this.setState(
        {
          rightTableData: [],
          hotels: [],
          entityGroupsTableExpanded: [],
          entityGroupsRowEdit: {},
          //  speacialRates: [],
          isEntityGroupsExpand: false,
        },
        () =>
          this.setState({
            hotels: hotelsData,
            rightTableData: _rightTableData,
            rightTableChildData: [],
          })
      );
      toast.success("Group deleted successfully.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "addGarnishment",
      });
      return;
    }

    EntityManagementService.DeleteHotelCategoryGroup(row?.id)
      .then((result) => {
        if (result?.message === "Success") {
          this.getHotelGroupMapping();
          toast.success("Group deleted successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addGarnishment",
          });
        } else {
          Utils.toastError(result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addGarnishment",
          });
        }
      })
      .catch((error) => {
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "addGarnishment",
        });
      });
  };

  deleteGroupHotel = (row) => {
    const _hotels = _.cloneDeep(this.state.hotels);
    const hotelIndex = _hotels?.findIndex((x) => x?.id === row?.hotelID);
    if (+row?.tenantID === 0 && !row?.pname) {
      const { entityGroupsRowEdit, rightTableData } = this.state;
      const tableIndex = rightTableData?.findIndex(
        (x) => x.groupName === entityGroupsRowEdit?.groupName
      );
      let hotels = (rightTableData[tableIndex]?.hotels || []).filter(
        (x) => x?.lettercode !== row?.lettercode
      );
      rightTableData[tableIndex].hotels = hotels;
      const rightTableChildData = hotels;
      _hotels[hotelIndex].isChecked = false;
      this.setState({ hotels: [] }, () => this.setState({ hotels: _hotels }));
      this.setState({
        rightTableData,
        rightTableChildData,
        isHotelChanges: true,
        isGroupMappingModified: true,
      });
      toast.success("Entity deleted successfully.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "addGarnishment",
      });
      return;
    }
    this.setState({ leftPanelLoader: true });
    EntityManagementService.DeleteGroupHotel(
      this.state.entityGroupsRowEdit?.id,
      row?.hotelID
    )
      .then((result) => {
        if (result?.message === "Success") {
          this.getHotelGroupMapping();
          _hotels[hotelIndex].isChecked = false;
          this.setState({ hotels: [] }, () => this.setState({ _hotels }));
          toast.success("Entity deleted successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addGarnishment",
          });
        } else {
          Utils.toastError(result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addGarnishment",
          });
        }
      })
      .catch((error) => {
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "addGarnishment",
        });
      });
  };

  handleShowGroupModal = (row: any = {}) => {
    this.setState({ editGroupRow: row }, () => {
      this.setState({ showDuplicateGroupModal: true });
    });
  };

  handleHideGroupModal = () => {
    this.setState({ showDuplicateGroupModal: false });
  };

  handleShowCategoryModal = (row: any = {}) => {
    this.setState({ editGroupRow: row }, () => {
      this.setState({ showChangeCategoryModal: true });
    });
  };

  handleShowGroupEditModal = (row: any = {}) => {
    this.setState({ editGroupRow: row }, () => {
      this.setState({ showEditGroupModal: true });
    });
  };

  handleHideGroupEditModal = (row: any = {}) => {
    this.setState({ showEditGroupModal: false });
  };

  handleHideCategoryModal = () => {
    this.setState({ showChangeCategoryModal: false });
  };

  handleCategoryEdit = (
    newCategoryId: number,
    oldCategoryId: number,
    groupId: number
  ) => {
    const entityGroupsData = [...this.state.entityGroupsData];
    if (newCategoryId !== oldCategoryId) {
      const newIndex = entityGroupsData.findIndex(
        (x) => +x.categoryId === newCategoryId
      );
      const oldIndex = entityGroupsData.findIndex(
        (x) => +x.categoryId === oldCategoryId
      );
      let oldGroupWithHotels = entityGroupsData[oldIndex].groupWithHotels;
      const groupWithHotels = entityGroupsData[newIndex].groupWithHotels;
      const newIndexHids=groupWithHotels.map(group => group?.hotels?.map(hotel => hotel?.hotelID)).flat();
      const oldIndexHids=oldGroupWithHotels?.find((x) => x?.id === groupId)?.hotels?.map(x => x?.hotelID)
      if(checkCommonHids(newIndexHids,oldIndexHids)){
        return
      }
      const groupToPush = {
        ...oldGroupWithHotels.find((x) => x.id === groupId),
      };
    
      groupWithHotels.push(groupToPush);
      oldGroupWithHotels = oldGroupWithHotels.filter((x) => x.id !== groupId);
      entityGroupsData[newIndex].groupWithHotels = groupWithHotels;
      entityGroupsData[oldIndex].groupWithHotels = oldGroupWithHotels;
      this.setState({
        entityGroupsData,
        rightTableData: groupWithHotels,
        selected: [newCategoryId],
        selectedCategoryName: entityGroupsData[newIndex]?.categroyName,
        isGroupMappingModified: true,
      });
    }
  };

  handleGroupEdit = (groupName: string, groupId: number) => {
    const rightTableData = [...this.state.rightTableData];
    const index = rightTableData.findIndex((x) => +x.id === +groupId);
    rightTableData[index].groupName = groupName;
    this.setState({ rightTableData, isGroupMappingModified: true });
  };

  renderToolTipSystemRole = (e) => (
    <Tooltip id="button-tooltip" {...e}>
      <span>System Role</span>
    </Tooltip>
  );

  render() {
    const {
      leftPanelLoader,
      rightTableData,
      leftPanelDates,
      currentSelectedDate,
      showCategoryModal,
      rightTableChildData,
      showAddRoomTypeModal,
      isDataSubmitting,
      entityGroupsTableExpanded,
      isGroupMappingModified,
      isEntityGroupsExpand,
      categoryName,
      entityGroupsData,
      showAddCategoryTextBox,
      selectedCategoryName,
      hotels,
      isManual,
      editGroupRow,
      showDuplicateGroupModal,
      showChangeCategoryModal,
      selected,
      showEditGroupModal,
    } = this.state;

    const rowClasses = (row: { action: string }, rowIndex: any) => {
      if (this.state.isExpand === true && row.action === "Expended") {
        return "row-Expended";
      } else if (this.state.isExpand === true) {
        return "row-Collapsed";
      } else {
        return "row-Action";
      }
    };

    const rowEntityGroupsClasses = (row: any, rowIndex: any) => {
      if (isEntityGroupsExpand === true && row?.action === "Expended") {
        return "row-Expended";
      } else if (row?.approveButtonSpinner) {
        return "p-none";
      } else if (isEntityGroupsExpand === true) {
        return "row-Collapsed";
      } else {
        return "row-Action";
      }
    };

    const leftPanelGridColumns: any[] = [
      {
        dataField: "categroyName",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="category-details d-flex flex-column">
            <div className="title">
              <EllipsisWithTooltip placement="bottom">
                {cell}
              </EllipsisWithTooltip>
            </div>
            <div className="desc">Category</div>
          </div>
        ),
      },
      {
        dataField: "groupWithHotels",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="groups-details">
            <div className="title1">
              <EllipsisWithTooltip placement="bottom">
                {cell?.length}
              </EllipsisWithTooltip>
            </div>
            <div className="desc grey">Groups</div>
          </div>
        ),
      },
      {
        dataField: "lock",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) =>
          row?.isManual === "No" ? (
            <div className="MasterRoleLock">
              <div>
                <OverlayTrigger
                  placement="bottom"
                  overlay={this.renderToolTipSystemRole}
                >
                  <img src={MasterRoleLock} alt="ScheduleLock" />
                </OverlayTrigger>
              </div>
            </div>
          ) : (
            ""
          ),
      },
    ];
    const selectRow = {
      mode: "radio",
      hideSelectColumn: true,
      classes: "active-row",
      selected: this.state.isSelected ? this.state.selected : "",
    };

    const hideRow = {
      mode: "radio",
      hideSelectColumn: true,
      classes: "",
    };

    const rightTableGridColumns: any[] = [
      {
        dataField: "groupName",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="hotel-details d-flex flex-column">
            <div className="hotel-name">
              <EllipsisWithTooltip placement="bottom">
                {cell}
              </EllipsisWithTooltip>
            </div>
            <span className="entity-count">
              {(row?.hotels || [])?.length} Entities
            </span>
          </div>
        ),
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            if (row.action === "Expended")
              this.handleOnEntityGroupsExpand(row, false, rowIndex, e);
            else this.handleOnEntityGroupsExpand(row, true, rowIndex, e);
          },
        },
      },
      {
        dataField: "",
        text: "",
        hidden: isManual === "Yes" ? false : true,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <>
            {" "}
            {isManual === "Yes" && (
              <Dropdown
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="more-action"
                //style={{ position: 'static' }}
                alignRight
              >
                <Dropdown.Toggle
                  className="btn-outline-primary more"
                  id="dropdown-more"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                  </svg>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {+row?.rGoupId > 0 && (
                    <>
                      <Dropdown.Item
                        key={"changeCategory"}
                        eventKey={"changeCategory"}
                        onClick={(e) => this.handleShowCategoryModal(row)}
                      >
                        Change Category
                      </Dropdown.Item>
                      <Dropdown.Item
                        key={"editGroup"}
                        eventKey={"editGroup"}
                        onClick={(e) => this.handleShowGroupEditModal(row)}
                      >
                        Edit Group
                      </Dropdown.Item>
                      <Dropdown.Item
                        key={"edit"}
                        eventKey={"edit"}
                        onClick={() => this.handleShowGroupModal(row)}
                      >
                        Duplicate
                      </Dropdown.Item>
                    </>
                  )}
                  <Dropdown.Item
                    key={"delete"}
                    onClick={() => this.deleteHotelCategoryGroup(row)}
                    eventKey={"delete"}
                  >
                    Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </>
        ),
      },
    ];

    const rightTableChildColumns = [
      {
        dataField: "lettercode",
        text: "EHID",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="EHID">{cell}</div>
        ),
      },
      {
        dataField: "legalName",
        text: "Name",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <EllipsisWithTooltip>
            <div className="entity-name">{cell}</div>
          </EllipsisWithTooltip>
        ),
      },
      {
        dataField: "",
        text: "",

        formatter: (cell: any, row: any, rowIndex: any) => (
          <div onClick={() => this.deleteGroupHotel(row)}>
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 11.8334C10.5392 11.8334 10.1667 11.8334 10.1667 11.8334V22.6667C10.1667 23.5867 10.9133 24.3334 11.8333 24.3334H20.1667C21.0867 24.3334 21.8333 23.5867 21.8333 22.6667V11.8334C21.8333 11.8334 21.4608 11.8334 21 11.8334H11ZM14.3333 21.8334H12.6667V14.3334H14.3333V21.8334ZM19.3333 21.8334H17.6667V14.3334H19.3333V21.8334ZM19.8483 9.33341L18.5 7.66675H13.5L12.1517 9.33341H8.5V11.0001H12.6667H19.3333H23.5V9.33341H19.8483Z"
                fill="#2CAF92"
              />
            </svg>
          </div>
        ),
      },
    ];

    const expandRowEntityGroup = {
      renderer: (row: any, rowIndex: any) => (
        <div className="rowExpended" id="laborDayDetails">
          <div>
            {leftPanelLoader ? (
              <ReactPageLoader useas="timeOffSettingsLeftPanel" />
            ) : (
              <div className="entity-group-expanded-details-table">
                <BootstrapTable
                  keyField="rowNo"
                  data={rightTableChildData || []}
                  columns={rightTableChildColumns}
                  hover
                />
              </div>
            )}
          </div>

          <div>
            {/* <Button
              className="btn-ghost"
            //   onClick={() => showSpecialRateAddEditModalForm()}
            >
              Add Entity
            </Button> */}

            {this.state?.isEntityGroupsExpand && hotels?.length && (
              <DropDownMultiListPopUp
                // defaultValue={[rightTableChildData]}
                label={"name"}
                value={"id"}
                data={[...hotels]}
                updateMultiSelectItem={this.updateMultiSelectItem}
                dataChange={this.dataChange.bind(this)}
                isSelectAll={false}
                placeHolder={"Add Entity"}
                filtertitle={"Filter Entity"}
              />
            )}

            {/* {showSpecialRateAddEditModal && isSpecialRateLoaded && (
                  <AddSpecialRate
                    showSpecialRateAddEditModal={showSpecialRateAddEditModal}
                    hideSpecialRateAddEditModalForm={
                      hideSpecialRateAddEditModalForm
                    }
                    userName={userGeneralInfo?.userName}
                    userUniqueNo={userUniqueNo}
                    speacialRates={speacialRates}
                    rowPositionId={row?.coa}
                    hid={row?.hid}
                    dateList={ row?.dateList?.map((x) => ({ label: x, value: x }))}
                    specialRateRowEdit={specialRateRowEdit}
                    positionList= {positionList}
                  />
                )} */}
          </div>
        </div>
      ),
      expanded: entityGroupsTableExpanded,
      onExpand: this.handleOnEntityGroupsExpand,
      showExpandColumn: true,
      expandByColumnOnly: false,
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <span className="chevronExpended">
              <img src={chevronRight} alt="chevron Right" />
            </span>
          );
        }
        return (
          <span className="chevronCollpsed">
            <img src={chevronRight} alt="chevron Right" />
          </span>
        );
      },
    };

    return (
      <div className="groups-main">
        <div className="budget-settings d-flex justify-content-start">
          <div className="left-panel">
            {showDuplicateGroupModal && (
              <DuplicateGroup
                entityGroupsData={rightTableData}
                showDuplicateGroupModal={showDuplicateGroupModal}
                handleHideGroupModal={this.handleHideGroupModal}
                addGroup={this.addGroup}
                groupName={editGroupRow?.groupName}
                groupId={editGroupRow?.rGoupId}
              />
            )}

            {showChangeCategoryModal && (
              <ChangeCategory
                showChangeCategoryModal={showChangeCategoryModal}
                categories={entityGroupsData.map((x) => ({
                  label: x.categroyName,
                  value: x.categoryId,
                }))}
                handleHideCategoryModal={this.handleHideCategoryModal}
                handleCategoryEdit={this.handleCategoryEdit}
                categoryId={selected[0]}
                groupId={editGroupRow?.id}
              />
            )}
            <ToolkitProvider
              keyField="uniqueno"
              data={entityGroupsData}
              columns={leftPanelGridColumns}
              search
            >
              {(props: {
                searchProps: JSX.IntrinsicAttributes;
                baseProps: JSX.IntrinsicAttributes;
              }) => (
                <div>
                  <div className="search-header sos">
                    <div className="search-area">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.33317 15.0001C9.8715 15.0001 11.2857 14.4709 12.414 13.5934L16.0773 17.2567L17.2557 16.0784L13.5923 12.4151C14.4707 11.2859 14.9998 9.87175 14.9998 8.33342C14.9998 4.65758 12.009 1.66675 8.33317 1.66675C4.65734 1.66675 1.6665 4.65758 1.6665 8.33342C1.6665 12.0092 4.65734 15.0001 8.33317 15.0001ZM8.33317 3.33341C11.0907 3.33341 13.3332 5.57591 13.3332 8.33342C13.3332 11.0909 11.0907 13.3334 8.33317 13.3334C5.57567 13.3334 3.33317 11.0909 3.33317 8.33342C3.33317 5.57591 5.57567 3.33341 8.33317 3.33341Z"
                          fill="#84888C"
                        />
                      </svg>
                      <SearchBar
                        {...props.searchProps}
                        placeholder={"Filter by category or group"}
                        autoComplete="off"
                      />
                    </div>
                  </div>

                  {leftPanelLoader ? (
                    <ReactPageLoader useas="timeOffSettingsLeftPanel" />
                  ) : (
                    <div className="left-body">
                      <BootstrapTable
                        {...props.baseProps}
                        keyField="categoryId"
                        rowEvents={this.rowEvents}
                        hover
                        condensed
                        selectRow={this.state.isSelected ? selectRow : hideRow}
                      />
                      <div
                        className="add-Budget"
                        onClick={() => this.showHideCategoryTextBox()}
                      >
                        Add Category
                      </div>
                      {/* </div> */}

                      {/* <AddRoomConfiguration
                        setLeaftPanelDates={this.setLeaftPanelDates}
                        leftPanelDates={leftPanelDates}
                        showCategoryModal={showCategoryModal}
                        handleHideRoomConfigurationModal={
                          this.handleHideRoomConfigurationModal
                        }
                      /> */}
                    </div>
                  )}
                </div>
              )}
            </ToolkitProvider>
          </div>
          <div className="right-panel codehere">
            {this.state.rightPanelLoader ? (
              <ReactPageLoader useas="timeOffSettingsRightPanel" />
            ) : (
              <div>
                <div className="pos-header d-flex align-content-center flex-wrap">
                  {showAddCategoryTextBox ? (
                    <>
                      {/* <ReactTooltip id="categoryName" effect="solid" place="bottom">
                    {categoryName}
                  </ReactTooltip>  */}
                      <Form.Control
                        type="text"
                        className="form-control"
                        tabIndex={1}
                        placeholder="Please enter category"
                        id="txtcategoryName"
                        data-tip
                        data-for="categoryName"
                        name="categoryName"
                        autoComplete="off"
                        value={categoryName}
                        maxLength={100}
                        onChange={(e) => this.onCategoryChange(e)}
                      />
                    </>
                  ) : (
                    <div className="pos-ttl mr-auto">
                      {selectedCategoryName ? selectedCategoryName : ""}
                    </div>
                  )}

                  {selectedCategoryName && isManual === "Yes" && (
                    <Dropdown className="more-action" alignRight>
                      <Dropdown.Toggle
                        className="btn-outline-primary btn btn-primary more"
                        id="dropdown-more"
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                          <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                          <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                        </svg>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() =>
                            this.editCategory(selectedCategoryName)
                          }
                        >
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            this.duplicateCategory(selectedCategoryName)
                          }
                        >
                          Duplicate
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => this.deleteHotelCategory()}
                        >
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </div>
                <div className="pos-body">
                  <>
                    {" "}
                    {this.state.isSelected && (
                      <ToolkitProvider
                        keyField="rGoupId"
                        data={rightTableData}
                        search
                      >
                        {(props: {
                          searchProps: JSX.IntrinsicAttributes;
                          baseProps: JSX.IntrinsicAttributes;
                        }) => (
                          <div>
                            <div className="search-header">
                              <div className="search-area">
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8.33317 15.0001C9.8715 15.0001 11.2857 14.4709 12.414 13.5934L16.0773 17.2567L17.2557 16.0784L13.5923 12.4151C14.4707 11.2859 14.9998 9.87175 14.9998 8.33342C14.9998 4.65758 12.009 1.66675 8.33317 1.66675C4.65734 1.66675 1.6665 4.65758 1.6665 8.33342C1.6665 12.0092 4.65734 15.0001 8.33317 15.0001ZM8.33317 3.33341C11.0907 3.33341 13.3332 5.57591 13.3332 8.33342C13.3332 11.0909 11.0907 13.3334 8.33317 13.3334C5.57567 13.3334 3.33317 11.0909 3.33317 8.33342C3.33317 5.57591 5.57567 3.33341 8.33317 3.33341Z"
                                    fill="#84888C"
                                  />
                                </svg>
                                <SearchBar
                                  {...props.searchProps}
                                  placeholder={"Filter by Name"}
                                  autoComplete="off"
                                />
                              </div>
                            </div>

                            {/* {   leftPanelLoader ? (
                     <ReactPageLoader useas="timeOffSettingsLeftPanel" />
                   ) : ( */}
                            <div className="right-body">
                              {rightTableData?.length > 0 && (
                                <BootstrapTable
                                  {...props.baseProps}
                                  columns={rightTableGridColumns}
                                  rowClasses={rowEntityGroupsClasses}
                                  expandRow={expandRowEntityGroup}
                                  hover
                                  condensed
                                  selectRow={
                                    this.state.isSelected ? selectRow : hideRow
                                  }
                                />
                              )}

                              {/* </div> */}

                              {/* <AddRoomConfiguration
                        setLeaftPanelDates={this.setLeaftPanelDates}
                        leftPanelDates={leftPanelDates}
                        showCategoryModal={showCategoryModal}
                        handleHideRoomConfigurationModal={
                          this.handleHideRoomConfigurationModal
                        }
                      /> */}
                            </div>
                            {/* )} */}
                          </div>
                        )}
                      </ToolkitProvider>
                    )}
                  </>

                  {/* { && <BootstrapTable
                    keyField="uniqueno"
                    data={rightTableData || []}
                    columns={rightTableGridColumns}
                    rowClasses={rowClasses}
                    hover
                  />
                } */}

                  {selectedCategoryName && isManual === "Yes" && (
                    <AddGroup
                      entityGroupsData={rightTableData}
                      heading="Add Group"
                      addGroup={this.addGroup}
                    />
                  )}
                  {showEditGroupModal &&
                    isManual === "Yes" &&
                    editGroupRow?.id > 0 && (
                      <EditGroup
                        entityGroupsData={rightTableData}
                        hideGroupEditModal={this.handleHideGroupEditModal}
                        showEditGroupModal={showEditGroupModal}
                        groupName={editGroupRow?.groupName}
                        groupId={editGroupRow?.rGoupId}
                        heading="Edit Group"
                        editGroup={this.editGroup}
                      />
                    )}

                  {isGroupMappingModified && (
                    <div className="fixed-action ml-auto">
                      <div className="d-flex align-content-center flex-wrap">
                        <div className="mr-auto message">
                          {rightTableData?.length > 0 && (
                            <span>You have unsaved changes</span>
                          )}
                        </div>
                        <button
                          disabled={isDataSubmitting}
                          type="button"
                          className="btn btn-primary btn-discard"
                          onClick={() => this.discard()}
                        >
                          Discard
                        </button>
                        <button
                          disabled={isDataSubmitting}
                          type="button"
                          className="btn btn-primary"
                          onClick={() =>
                            this.saveData(
                              showAddCategoryTextBox
                                ? "category"
                                : "groupEntities"
                            )
                          }
                        >
                          {"Save"}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
