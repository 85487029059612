import * as React from "react";
import {
  Button,
  Container,
  Dropdown,
  Form,
  // Form,
  Modal,
  Tab,
  Tabs,
} from "react-bootstrap";
import { SingleSearchDropdownList } from "../../Common/Components/SingleSearchDropdownList";
import { UserManagement } from "./../../Common/Services/UserManagement";
import { IUserRole } from "../../Common/Contracts/IUserRole";
import _ from "lodash";
import { toast } from "react-toastify";
export class GMResetPassword extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      role: 0,
      showModal: this.props.showModal,
      SelectedUsers: this.props.SelectedUsers,
      disablePhone: true,
      disableEmail:true,
      disableSave: true,
    };
  }
  componentDidMount() {
    if (this.props.SelectedUsers?.[0].phone1) {
      this.setState({ disablePhone: false });
    }
    if (this.props.SelectedUsers?.[0].email) {
      this.setState({ disableEmail: false });
    }
  }

  saveGMResetPassword = (mode) => {
    let UpdateUserRoles = [] as any;
    this.state.SelectedUsers.map((user) => {
      let usertoupdate: any = {};
      usertoupdate.Username = user.username;
      usertoupdate.RoleId = 0;
      usertoupdate.TenantId = user.tenantId;
      usertoupdate.mode = mode;
      UpdateUserRoles.push(usertoupdate);
    });
    UserManagement.ResetPasswordNotEmailed(UpdateUserRoles).then(
      (response: any) => {

        if (response?.result.success == true) {
          this.props.hideStatusModal();
          toast.success("Password reset URL sent successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            // containerId: "scheduleMain",
          });
        } else {
          toast.success(response.result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
            // containerId: "scheduleMain",
          });
        }
      }
    );
  };

  hideGMResetModal = () => {
    this.props.hideStatusModal();
  };
  render() {
    const { userRoles, role } = this.state;

    return (
      <div>
        <Modal
          className="reset-password-modal"
          show={this.state.showModal}
          onHide={() => this.hideGMResetModal()}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Reset Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="description">
              Password reset instructions will be sent to the user by the method
              selected below.
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="action-group d-flex">
              <div className="action-item d-flex flex-column align-items-center">
                <Button
                  disabled={this.state.disablePhone}
                  className="reset-password-btn"
                  onClick={(e) => this.saveGMResetPassword("phone")}
                >
                  Reset by Mobile Phone
                </Button>
                
                {
                  this.state.disablePhone == true && <div className="status-label red">No Mobile Phone Number</div>
                }
                {
                  this.state.disablePhone == false && <div className="status-label">Recommended</div>
                }
                
              </div>
              <div className="action-item d-flex flex-column align-items-center">
                <Button
                  disabled={this.state.disableEmail}
                  className="reset-password-btn"
                  onClick={(e) => this.saveGMResetPassword("email")}
                >
                  Reset by Email
                </Button>
                {
                  this.state.disableEmail == true && <div className="status-label red">No Email</div>
                }
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
