import React from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { Utils } from "../../../Common/Utilis";
import { UserManagement } from "../../../Common/Services/UserManagement";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import { DropDownList } from "../../Reports/common-components/dropdown-list";
import { IUserDeductionCriteria } from "../../../Common/Contracts/IDeductionCriteria";
import _ from "lodash";

export class AddDeduction extends React.Component<any, any> {
  private deductionFormSchema = Joi.object({
    uniqueno: Joi.number().min(0),
    status: this.props?.deductionRowEdit?.status || "Active",
    deductUniqueNo: Joi.number()
      .min(1)
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select Deduction" };
        });
      }),
    taxType: Joi.string().required().allow(""),
    exempt: Joi.string().allow(""),
    deductionType: Joi.string().optional().allow(""),
    deductionCriteria: Joi.string().allow(""),
    amount: Joi.number()
      .min(0)
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please enter Amount" };
        });
      }),
    payeeName: Joi.string().allow(""),
    hid: Joi.number()
      .min(1)
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select HID" };
        });
      }),
  });

  state: any = {
    deductionFormData: {
      uniqueno: this.props?.deductionRowEdit?.uniqueno || 0,
      status: this.props?.deductionRowEdit?.status || "Active",
      deductUniqueNo: this.props?.deductionRowEdit?.deductUniqueNo || 0,
      taxType: this.props?.deductionRowEdit?.taxType || "",
      exempt: this.props?.deductionRowEdit?.exempt || "",
      deductionType: this.props?.deductionRowEdit?.deductionType || "",
      deductionCriteria: this.props?.deductionRowEdit?.deductionCriteria || "",
      amount: this.props?.deductionRowEdit?.amount || 0,
      payeeName: this.props?.deductionRowEdit?.payeeName || "",
      hid: (+this.props?.deductionRowEdit?.hid || 0),
    },
    deductions: [],
    deductionFilters: [],
    deductionName: "",
    errors: {},
    isDataSaving: false,
    isFormNotValid: true,
    inputPosition: 0,
    hotelsLoaded: false,
    hotelList: [],
  };

  componentDidMount = () => {
    this.getHotels();
    this.getAllDeductionCriteria();
  };

  saveDeduction = () => {
    const request: IUserDeductionCriteria = {
      ...this.state.deductionFormData,
    } as IUserDeductionCriteria;

   if ( (+this.props?.deductionRowEdit?.uniqueno|| 0) <=0 &&
        this.props?.deductionsData?.filter(
          (x) => x.hid === request.hid && x.deductUniqueNo === request.deductUniqueNo).length>0
      ) {
        toast.success("This Deduction Type is already added for selected EHID. Please select another", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "laborTabUM",
        });
        return;
      }

    
    request.userUniqueId = this.props?.userUniqueId;
    this.setState({ isDataSaving: true });
    UserManagement.SaveUserDeductionCriteria(request)
      .then((result) => {
        this.setState({ isDataSaving: false });
        if (result?.message === "Success") {
          toast.success(
            this.props?.deductionRowEdit?.uniqueno > 0
              ? "User Deduction Updated Successfully"
              : "User Deduction Saved Successfully",
            {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "addDeduction",
            }
          );
          this.hideModal(true);
        } else {
          Utils.toastError(result?.result?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addDeduction",
          });
        }
      })
      .catch((error) => {
        this.setState({ isDataSaving: false });
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "addDeduction",
        });
      });
  };

  onSearchChange = (value) => {
    const deductionFilters = this.state?.deductions?.filter((x) =>
      x?.deductionName?.toString()?.toLowerCase()?.includes(value)
    );
    this.setState({ deductionFilters });
  };

  filterBy = () => true;

  onFieldChange = (event, inputPosition) => {
    const { deductionFormData } = this.state;
    let value = event.target.value;
    const fieldName = event.target.name;
    if(fieldName==="amount" &&  (value?.toString()?.split('.')[0]?.length >10 ||  value?.toString()?.split('.')[1]?.length >2)) {
      return;
    
    }
    deductionFormData[fieldName] = value;
    if(inputPosition> this.state.inputPosition) 
    {
      this.setState({inputPosition});
    }
    this.setState({ deductionFormData }, () =>
      this.validateDeductionFormSchema()
    );
  };
  componentWillUnmount=()=> {
    // setTimeout(()=> {
    //   toast.dismiss();
    // },1000)
  }
  onDeductionNameChange = (item, inputPosition) => {
    const { deductionFormData } = this.state;
    deductionFormData.deductUniqueNo = item?.uniqueno;
    deductionFormData.taxType = item.taxType;
    deductionFormData.exempt = item.exempt;
    deductionFormData.deductionType = item.deductionType;
    deductionFormData.deductionCriteria = item.deductionCri;
    deductionFormData.payeeName = item.payName;
    deductionFormData.amount = item.amount;
    if(inputPosition> this.state.inputPosition) 
    {
      this.setState({inputPosition});
    }
    this.setState({ deductionFormData, }, () =>
      this.validateDeductionFormSchema()
    );
  };

  validateDeductionFormSchema = () => {
    const valid = Joi.validate(
      this.state.deductionFormData,
      this.deductionFormSchema,
      {
        abortEarly: false,
      }
    );

    const newErrorObject = {};
    if (valid.error) {
      valid.error.details.forEach((err) => {
        newErrorObject[err.path.join(".")] = err.message;
      });
    }

    if (Object.keys(newErrorObject).length === 0) {
      this.setState({ isFormNotValid: false });
    } else {
      this.setState({ isFormNotValid: true });
    }
    this.setState({ errors: newErrorObject });
    return newErrorObject;
  };

  validationOnClick = (inputPosition) => {
    if(inputPosition> this.state.inputPosition) 
    {
      this.setState({ inputPosition }, () => this.validateDeductionFormSchema());
    }
  };

  getFieldError = (fieldName: string, fieldPosition: number) => {
    const { errors, inputPosition } = this.state;

    return (
      <>
        {Object.keys(errors).length > 0 && fieldPosition <= inputPosition && (
          <span className="validation-message">{errors[fieldName]}</span>
        )}
      </>
    );
  };

  hideModal = (isReload = false) => {
    const deductionFormData = {
      uniqueno: this.props?.deductionRowEdit?.uniqueno || 0,
      status: this.props?.deductionRowEdit?.status || "Active",
      deductUniqueNo: this.props?.deductionRowEdit?.deductUniqueNo || 0,
      taxType: this.props?.deductionRowEdit?.taxType || "",
      exempt: this.props?.deductionRowEdit?.exempt || "",
      deductionType: this.props?.deductionRowEdit?.deductionType || "",
      deductionCriteria: this.props?.deductionRowEdit?.deductionCriteria || "",
      amount: this.props?.deductionRowEdit?.amount || 0,
      payeeName: this.props?.deductionRowEdit?.payeeName || "",
      hid: this.props?.deductionRowEdit?.hid || 0,
    };
    this.props?.hideDeductionModal(isReload);
    this.setState({ deductionFormData, isFormNotValid: true });
  };

  getAllDeductionCriteria = (isDefaultLoad = false) => {
    this.setState({ isEnterpriseWideDataLoaded: false });
    UserManagement.GetAllDeductionCriteria()
      .then((deductions: any) => {
        if (deductions?.length > 0) {
          deductions = deductions.map((x) => {
            x.deductionName = `${x.deductionName}(${x.taxType}-${x.exempt}-${x.deductionType})`;
            return x;
          }).sort((a,b)=>a.deductionName - b.deductionName);
        }
        this.setState({ deductions });
      })
      .finally(() => this.setState({ isEnterpriseWideDataLoaded: true }));
  };

  getHotels = () => {
    if(this.props?.userHotels?.length ===1 && (+this.props?.deductionRowEdit?.hid===0 || !this.props?.deductionRowEdit?.hid) ) {
     const   deductionFormData = _.cloneDeep(this.state.deductionFormData);
     deductionFormData.hid = this.props?.userHotels[0]?.hotelID;
     this.setState({deductionFormData});
    }
    this.setState({ hotelList: this.props?.userHotels });
  };

  //    filterBy = () => {
  //     if(!props?.defaultValue && selectedValue?.length > 0) {
  //     typeahead?.current?.clear()
  //  }

  render() {
    const {
      deductionFormData: {
        deductUniqueNo,
        taxType,
        exempt,
        deductionType,
        deductionCriteria,
        amount,
        payeeName,
        hid,
      },
      deductions,
      hotelList,
      errors,
      isDataSaving,
      isFormNotValid,
      inputPosition,
    } = this.state;
    const { showDeductionModal } = this.props;
    return (
      <>
        <div className="">
        
          <Modal
            className="add-deduction-modal"
            show={showDeductionModal}
            onHide={this.hideModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={this.props?.showDeductionModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {this.props?.deductionRowEdit?.uniqueno > 0
                  ? "Edit Deduction"
                  : "Add Deduction"}{" "}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-section">
                <div className="body-section">
                  <Form.Group controlId="deductUniqueNo">
                    <Col
                      sm={"12"}
                      className={`mb-3 ${
                        errors?.deductUniqueNo && inputPosition >= 1
                          ? "validation-error"
                          : ""
                      }`}
                      onBlur={() => this.validationOnClick(1)}
                    >
                      <Form.Label>Name</Form.Label>
                      {deductions?.length > 0 && (
                        <DropDownList
                          placeHolder={"Search Name"}
                          data={deductions}
                          defaultValue={deductUniqueNo}
                          isSearchRequired={true}
                          label={"deductionName"}
                          value={"uniqueno"}
                          disabled={+this.props?.deductionRowEdit?.uniqueno > 0}
                          onDropDownChange={(item) => {
                            if (!item) {
                              return;
                            }
                            this.onDeductionNameChange(item, 7);
                          }}
                          selectedItem={[
                            ...[{ uniqueno: 0, deductionName: "Select Name" }],
                            ...deductions,
                          ].find((r: any) => r?.uniqueno === deductUniqueNo)}
                          autoComplete="off"
                        />
                      )}
                      {/* <AsyncTypeahead
                        labelKey='deductionName'
                        filterBy={this.filterBy}
                        defaultSelected={[deductionName || ""]}
                        id="deduction-selector"
                        isLoading={false}
                        minLength={1}
                        onInputChange={(event) => {
                          this.onDeductionNameChange(
                              event,
                            3
                          );
                        }}
                        onSearch={this.onSearchChange}
                        options={deductionFilters|| []}
                      /> */}
                      {this.getFieldError("deductUniqueNo", 1)}
                    </Col>
                  </Form.Group>

                  <Form.Group controlId="taxType" className="d-flex">
                    <Col
                      sm={"6"}
                      className={`mb-0 ${
                        errors?.taxType && inputPosition >= 2
                          ? "validation-error"
                          : ""
                      }`}
                      onBlur={() => this.validationOnClick(2)}
                    >
                      <Form.Label>Tax Type</Form.Label>
                      <input
                        type="text"
                        className="form-control"
                        id="txtAmount"
                        name="taxType"
                        autoComplete="off"
                        value={taxType}
                        disabled={true}
                        onChange={(e) => this.onFieldChange(e, 3)}
                      />
                      {this.getFieldError("taxType", 2)}
                    </Col>
                    <Col
                      sm={"6"}
                      className={`mb-0 ${
                        errors?.exempt && inputPosition >= 3
                          ? "validation-error"
                          : ""
                      }`}
                      onBlur={() => this.validationOnClick(3)}
                    >
                      <Form.Label>Exempt</Form.Label>
                      <input
                        type="text"
                        className="form-control"
                        id="txtdeductionAmount"
                        name="exempt"
                        autoComplete="off"
                        value={exempt}
                        disabled={true}
                        onChange={(e) => this.onFieldChange(e, 3)}
                      />
                      {this.getFieldError("exempt", 3)}
                    </Col>
                  </Form.Group>

                  <Form.Group controlId="deductionType" className="d-flex">
                    <Col
                      sm={"6"}
                      className={`mb-0 ${
                        errors?.deductionType && inputPosition >= 4
                          ? "validation-error"
                          : ""
                      }`}
                      onBlur={() => this.validationOnClick(4)}
                    >
                      <Form.Label>Deduction Type</Form.Label>
                      <input
                        type="text"
                        className="form-control"
                        id="txtAmount"
                        name="deductionType"
                        autoComplete="off"
                        value={deductionType}
                        disabled={true}
                        onChange={(e) => this.onFieldChange(e, 4)}
                      />
                      {this.getFieldError("deductionType", 4)}
                    </Col>
                    <Col
                      sm={"6"}
                      className={`mb-0 ${
                        errors?.deductionCriteria && inputPosition >= 5
                          ? "validation-error"
                          : ""
                      }`}
                      onBlur={() => this.validationOnClick(5)}
                    >
                      <Form.Label>Deduction Criteria</Form.Label>
                      <input
                        type="text"
                        className="form-control"
                        id="txtAmount"
                        name="deductionCriteria"
                        autoComplete="off"
                        value={deductionCriteria}
                        disabled={true}
                        onChange={(e) => this.onFieldChange(e, 5)}
                      />
                      {this.getFieldError("deductionCriteria", 5)}
                    </Col>
                  </Form.Group>

                  <Form.Group controlId="amount" className="d-flex">
                    <Col
                      sm={"6"}
                      className={`mb-0 ${
                        errors?.amount && inputPosition >= 6
                          ? "validation-error"
                          : ""
                      }`}
                      onBlur={() => this.validationOnClick(6)}
                    >
                      <Form.Label>Amount</Form.Label>
                      <input
                        type="text"
                        onKeyDown= {Utils.AllowOnlyPositiveNumnberInput}
                        className="form-control"
                        id="txtAmount"
                        name="amount"
                        autoComplete="off"
                        value={amount}
                        onChange={(e) => this.onFieldChange(e, 6)}
                      />
                      {this.getFieldError("amount", 6)}
                    </Col>
                    <Col
                      sm={"6"}
                      className={`mb-0 ${
                        errors?.hid && inputPosition >= 7
                          ? "validation-error"
                          : ""
                      }`}
                      onBlur={() => this.validationOnClick(7)}
                    >
                      <Form.Label>EHID</Form.Label>
                      {hotelList?.length > 0 && (
                        <DropDownList
                          placeHolder={"Select HID"}
                          data={hotelList}
                          defaultValue={hid}
                          isSearchRequired={false}
                          label={"lettercode"}
                          value={"hotelID"}
                          disabled={hotelList.length ===1}
                          onDropDownChange={(item) => {
                            if (!item) {
                              return;
                            }
                            this.onFieldChange(
                              Utils.BuildCustomEventObject("hid", item.hotelID),
                              7
                            );
                          }}
                          selectedItem={[
                            ...[{ hotelID: 0, lettercode: "Select HID" }],
                            ...hotelList,
                          ].find((r: any) => r?.hotelID === hid)}
                        />
                      )}
                      {this.getFieldError("hid", 7)}
                    </Col>
                  </Form.Group>

                  <Form.Group controlId="payeeName">
                    <Col
                      sm={"12"}
                      onBlur={() => this.validationOnClick(8)}
                      className={
                        errors?.payeeName && inputPosition >= 8
                          ? "validation-error"
                          : ""
                      }
                    >
                      <Form.Label>Payee Name</Form.Label>
                      <input
                        type="text"
                        className="form-control"
                        id="txtBankName"
                        name="payeeName"
                        disabled={true}
                        autoComplete="off"
                        value={payeeName}
                        onChange={(e) => this.onFieldChange(e, 8)}
                      />
                      {this.getFieldError("payeeName", 8)}
                    </Col>
                  </Form.Group>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn-ghost"
                onClick={() => this.hideModal(false)}
              >
                Cancel
              </Button>
              <Button
                disabled={isFormNotValid || isDataSaving}
                className="btn btn-primary"
                onClick={this.saveDeduction}
              >
                Save
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}
