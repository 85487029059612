import React from "react";
import { Spinner, Dropdown, Modal, Button, Form } from "react-bootstrap";
import { MultiSelectDepartment } from "../components/MultiSelectDepartment";
import { resolve, reject } from "q";
import { toast } from "react-toastify";
import {
  ILaborImportScheduleRequestDto,
  IExitsLaborEmpScheduleRequestDto,
  ILaborImportScheduleTemplateRequestDto,
} from "../../../../../Common/Contracts/ILaborForecast";
import { GetLaborScheduleTemplateRequest } from "../../../../../Common/Contracts/ILaborSchedule";
import { LaborForecast } from "../../../../../Common/Services/LaborForecast";
import { LaborSchedule } from "../../../../../Common/Services/LaborSchedule";
import { Utils } from "../../../../../Common/Utilis";

const lastWeekVarName: string = "Last Week's Schedule";
const lastTwoWeekVarName: string = "Last Two Week's Schedule";
export class CopyScheduleModal extends React.Component<any, any> {
  private isCopyScheduleScroll: any;
  constructor(props: any) {
    super(props);
    this.isCopyScheduleScroll = React.createRef();
    this.state = {
      buttonSpinner: true,
      copyDepartmentList: this.props.parentState.departmentList,
      copyUsers: true,
      copyUsersList: [],
      copyUsersSelected: "Select",
      usersSelectionType: "",
      copyShifts: true,
      copyShiftsList: [],
      copyShiftsSelected: "Select",
      shiftsSelectionType: "",
      copyShiftsAssignments: false,
      selectedDepartmentID: [],
      exitsSchedule: false,
    };
  }

  componentDidMount() {
    this.getTemplateList();
  }

  getTemplateList = () => {
    let requestObject = {} as GetLaborScheduleTemplateRequest;

    requestObject.hotelID = this.props.parentState.hidValue;

    LaborSchedule.GetLaborScheduleTemplates(requestObject)
      .then(async (result: any) => {
        if (result.message === "Success") {
          let apiResult = result.result;

          apiResult.forEach((data: any) => (data.listType = "Template"));

          let resultObject: any = {};
          resultObject.hid = this.props.parentState.hidValue;
          resultObject.id = -1;
          resultObject.listType = "Schedule";
          resultObject.message = null;
          resultObject.success = null;
          resultObject.templateName = "";
          resultObject.tenantID = 101;
          resultObject.type = null;

          let object1 = { ...resultObject };
          object1.templateName = lastWeekVarName;
          apiResult.push(object1);
          let object2 = { ...resultObject };
          object2.templateName = lastTwoWeekVarName;
          apiResult.push(object2);
          this.setState({
            copyUsersList: apiResult,
            copyShiftsList: apiResult,
          });
        } else {
          Utils.toastError(`${result.message}`, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "scheduleMain",
          });
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "scheduleMain",
        });
        reject();
      });
  };

  copyScheduleModalClose = () => {
    this.props.copyScheduleModalHide();
  };

  employeeSelection = (childState: any) => {
    this.setState({ selectedDepartmentID: childState.selectedItems }, () => {
      this.enableDisableButton();
    });
  };

  copyUsers = (item: any) => {
    this.setState(
      {
        copyUsersSelected: item.templateName,
        usersSelectionType: item.listType,
      },
      () => {
        if (this.state.copyUsersSelected === this.state.copyShiftsSelected) {
          this.setState({ copyShiftsAssignments: true });
        }
        this.enableDisableButton();
      }
    );
  };

  copyShifts = (item: any) => {
    this.setState(
      {
        copyShiftsSelected: item.templateName,
        shiftsSelectionType: item.listType,
      },
      () => {
        if (this.state.copyUsersSelected === this.state.copyShiftsSelected) {
          this.setState({ copyShiftsAssignments: true });
        }
        this.enableDisableButton();
      }
    );
  };

  copyUsersToggle = () => {
    this.setState(
      {
        copyUsers: !this.state.copyUsers,
        copyUsersSelected: "Select",
      },
      () => {
        if (this.state.copyUsers && this.state.copyShifts) {
          this.setState({ copyShiftsAssignments: true });
        } else {
          this.setState({ copyShiftsAssignments: false });
        }
        this.enableDisableButton();
      }
    );
  };

  copyShiftsToggle = () => {
    this.setState(
      {
        copyShifts: !this.state.copyShifts,
        copyShiftsSelected: "Select",
      },
      () => {
        if (this.state.copyUsers && this.state.copyShifts) {
          this.setState({ copyShiftsAssignments: true });
        } else {
          this.setState({ copyShiftsAssignments: false });
        }
        this.enableDisableButton();
      }
    );
  };

  copyShiftsAssignmentsToggle = () => {
    this.setState(
      { copyShiftsAssignments: !this.state.copyShiftsAssignments },
      () => {
        this.enableDisableButton();
      }
    );
  };

  enableDisableButton = () => {
    
    let isValid = true;
    // Condition for checking departmelt list empty
    if (this.state.selectedDepartmentID.length === 0) {
      isValid = false;
    }

    // For checkig both toggles off
    if (this.state.copyUsers === false && this.state.copyShifts === false) {
      isValid = false;
    }

    // For checking if template name is selected
    if (
      this.state.copyUsers === true &&
      this.state.copyUsersSelected === "Select"
    ) {
      isValid = false;
    }

    // For checking if template name is selected
    if (
      this.state.copyShifts === true &&
      this.state.copyShiftsSelected === "Select"
    ) {
      isValid = false;
    }

    // Checking if template is selected and if both of them are not same
    if (
      (this.state.usersSelectionType === "Template" ||
        this.state.shiftsSelectionType === "Template") &&
      this.state.copyUsersSelected !== this.state.copyShiftsSelected &&
      this.state.copyShifts === true &&
      this.state.copyUsers === true
    ) {
      isValid = false;
    }

    // Setting State
    if (isValid) {
      this.setState({ buttonSpinner: false });
    } else {
      this.setState({ buttonSpinner: true });
    }

    return isValid;
  };

  onExitsSchudleCancle = () => {
    this.setState({ exitsSchedule: false });
  };

  onExitsSchudleYes = () => {
    
    if (
      this.state.usersSelectionType === "Template" ||
      this.state.shiftsSelectionType === "Template"
    ) {
      this.laborImportTemplate();
    } else {
      this.laborImportSchedule();
    }
  };

  Isvalidate = () => {
    let isValid = true;
    if (this.state.selectedDepartmentID.length === 0) {
      Utils.toastError("Please select department", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "scheduleMain",
      });
      isValid = false;
      return isValid;
    }
    if (this.state.copyUsers === false && this.state.copyShifts === false) {
      Utils.toastError("Please select copy users or copy shifts", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "scheduleMain",
      });
      isValid = false;
      return isValid;
    }
    if (
      this.state.copyUsers === true &&
      this.state.copyUsersSelected === "Select"
    ) {
      Utils.toastError("Please select Schedule", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "scheduleMain",
      });
      isValid = false;
      return isValid;
    }
    if (
      this.state.copyShifts === true &&
      this.state.copyShiftsSelected === "Select"
    ) {
      Utils.toastError("Please select Schedule", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "scheduleMain",
      });
      isValid = false;
      return isValid;
    }
    return isValid;
  };

  setForecast = () => {
    let isValid = this.Isvalidate();
    if (!isValid) {
      return false;
    } else {
      this.setState({ buttonSpinner: true });
      if (
        this.state.copyShiftsAssignments === true &&
        this.state.copyUsers === true &&
        this.state.copyShifts === true
      ) {
        let laborEmpScheduleRequest = {} as IExitsLaborEmpScheduleRequestDto;
        laborEmpScheduleRequest.hotelID = this.props.parentState.hidValue;
        laborEmpScheduleRequest.WeekStartDate =
          this.props.parentState.navigationCalenderDates[0];
        laborEmpScheduleRequest.WeekEndDate =
          this.props.parentState.navigationCalenderDates[1];

        LaborForecast.ExitsLaborEmpScheduleDetails(laborEmpScheduleRequest)
          .then(async (result: any) => {
            if (result.message === "Success") {
              if (result.result.message === "exits") {
                this.setState({ exitsSchedule: true }, () => {
                  this.isCopyScheduleScroll.current.scrollIntoView();
                });
                this.setState({ buttonSpinner: false });
              } else if (result.result.message === "Success") {
                
                if (
                  this.state.usersSelectionType === "Template" ||
                  this.state.shiftsSelectionType === "Template"
                ) {
                  this.laborImportTemplate();
                } else {
                  this.laborImportSchedule();
                }
              } else {
                this.setState({ buttonSpinner: false });
              }
            } else {
              this.setState({ buttonSpinner: false });
            }

            resolve();
          })
          .catch((err: any) => {
            this.setState({ buttonSpinner: false });
            Utils.toastError(`Server Error, ${err}`, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "scheduleMain",
            });
            reject();
          });
      } else {
        
        if (
          this.state.usersSelectionType === "Template" ||
          this.state.shiftsSelectionType === "Template"
        ) {
          this.laborImportTemplate();
        } else {
          this.laborImportSchedule();
        }
      }
    }
  };

  laborImportTemplate = () => {
    this.setState({ exitsSchedule: false, buttonSpinner: true });
    let laborImportRequest = {} as ILaborImportScheduleTemplateRequestDto;
    laborImportRequest.date = this.props.parentState.navigationCalenderDates[0];
    laborImportRequest.hotelID = this.props.parentState.hidValue;
    laborImportRequest.day = 7;
    laborImportRequest.day2 = 7;
    laborImportRequest.isBlankShift = this.state.copyUsers ? "Yes" : "No";
    laborImportRequest.copyShift = this.state.copyShifts ? "Yes" : "No";
    laborImportRequest.importType = "Template";
    laborImportRequest.TemplateName = this.state.copyUsersSelected;
    if (this.state.copyShifts === true && this.state.copyUsers === false) {
      laborImportRequest.TemplateName = this.state.copyShiftsSelected;
    }
    let departMentArray = [...this.state.selectedDepartmentID];
    let departMentID = "";
    departMentArray.forEach((element) => {
      departMentID += element.id + ",";
    });
    laborImportRequest.selectedDept = departMentID.replace(/,(?=\s*$)/, "");
    laborImportRequest.isAssignShift =
      this.state.copyShiftsAssignments &&
      this.state.copyUsersSelected === this.state.copyShiftsSelected
        ? "Yes"
        : "No";

    LaborForecast.LaborImportScheduleTemplate(laborImportRequest)
      .then(async (result: any) => {
        if (result.message === "Success") {
          if (result.result.length > 0) {
            const newResult1 = result.result[0];
            if (newResult1.message === "Success") {
              toast.success("Schedule imported successfully", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "scheduleMain",
              });
              this.props.isPublishOrNot(true);              
              this.props.successForecast();
              this.props.isPublishOrNot(true);  
            } else {
              let errorMessage: any = "Error in copy schedule, try again";
              if (newResult1.message === "blank") {
                errorMessage =
                  "Imported week with selected department have no data, schedule cannot be imported";
              } else if (newResult1.message === "blankshift") {
                errorMessage =
                  "Imported week with selected department have no data, schedule cannot be imported";
              } else if (newResult1.message === "blankuser") {
                errorMessage =
                  "Imported week with selected department have no data, schedule cannot be imported";
              }
              Utils.toastError(errorMessage, {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "scheduleMain",
              });
            }
          }
        }
        this.setState({ buttonSpinner: false });
        resolve();
      })
      .catch((err: any) => {
        this.setState({ buttonSpinner: false });
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "scheduleMain",
        });
        reject();
      });
  };

  laborImportSchedule = () => {
    this.setState({ exitsSchedule: false, buttonSpinner: true });
    let laborImportRequest = {} as ILaborImportScheduleRequestDto;
    laborImportRequest.date = this.props.parentState.navigationCalenderDates[0];
    laborImportRequest.hotelID = this.props.parentState.hidValue;
    laborImportRequest.ImportType = "Schedule";
    laborImportRequest.day = 7;
    laborImportRequest.day2 = 7;

    if (this.state.copyUsersSelected === lastTwoWeekVarName) {
      laborImportRequest.day = 14;
    }
    if (this.state.copyShiftsSelected === lastTwoWeekVarName) {
      laborImportRequest.day2 = 14;
    }

    laborImportRequest.copyShift = this.state.copyShifts ? "Yes" : "No";
    laborImportRequest.isBlankShift = this.state.copyUsers ? "Yes" : "No";
    laborImportRequest.isAssignShift =
      this.state.copyShiftsAssignments &&
      this.state.copyUsersSelected === this.state.copyShiftsSelected
        ? "Yes"
        : "No";
    let departMentArray = [...this.state.selectedDepartmentID];
    let departMentID = "";
    departMentArray.forEach((element) => {
      departMentID += element.id + ",";
    });
    laborImportRequest.selectedDept = departMentID.replace(/,(?=\s*$)/, "");

    LaborForecast.LaborImportSchedule(laborImportRequest)
      .then(async (result: any) => {
        if (result.message === "Success") {
          if (result.result.length > 0) {
            const newResult1 = result.result[0];
            if (newResult1.message === "Success") {
              toast.success("Schedule imported successfully", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "scheduleMain",
              });
              this.props.isPublishOrNot(true);   
              this.props.successForecast();
            } else {
              let errorMessage: any = "Error in copy schedule, try again";
              if (newResult1.message === "blank") {
                errorMessage =
                  "Imported week with selected department have no data, schedule cannot be imported";
              } else if (newResult1.message === "blankshift") {
                errorMessage =
                  "Imported week with selected department have no data, schedule cannot be imported";
              } else if (newResult1.message === "blankuser") {
                errorMessage =
                  "Imported week with selected department have no data, schedule cannot be imported";
              }
              Utils.toastError(errorMessage, {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "scheduleMain",
              });
            }
          }
        }
        this.setState({ buttonSpinner: false });
        resolve();
      })
      .catch((err: any) => {
        this.setState({ buttonSpinner: false });
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "scheduleMain",
        });
        reject();
      });
  };

  render() {
    return (
      <div>
        <Modal
          className="copy-schedule-modal page-schedule-copy-modal"
          show={this.props.parentState.copyScheduleModal}
          onHide={this.copyScheduleModalClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Copy Schedule</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="copySchedule">
              <div className="copySchedule details">
                <div className="importFrom">
                  <Form.Group controlId="set-Forecast">
                    <Form.Label>Department to Copy</Form.Label>
                    <MultiSelectDepartment
                      parentState={this.state}
                      employeeSelection={this.employeeSelection}
                    />
                  </Form.Group>
                </div>

                <div className="bottomSepetatorLine"></div>
                <div className="copyUsers">
                  <Form>
                    <Form.Check
                      type="switch"
                      id="sw_copy-users"
                      label="Copy Users"
                      checked={this.state.copyUsers}
                      onChange={() => this.copyUsersToggle()}
                    />
                  </Form>
                  {this.state.copyUsers && (
                    <Dropdown className="import-from-dropdown">
                      <Dropdown.Toggle id="dropdown-importFrom">
                        <span
                          className={
                            this.state.copyUsersSelected === "Select"
                              ? "placeholder"
                              : "selected"
                          }
                        >
                          {this.state.copyUsersSelected}
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div>
                          {this.state.copyUsersList.map(
                            (item: any, idx: any) => (
                              <Dropdown.Item
                                key={idx}
                                eventKey={item.id}
                                onClick={() => this.copyUsers(item)}
                              >
                                {item?.templateName}
                              </Dropdown.Item>
                            )
                          )}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </div>

                <div className="copyShifts">
                  <Form>
                    <Form.Check
                      type="switch"
                      id="sw_copy-shifts"
                      label="Copy Shifts"
                      checked={this.state.copyShifts}
                      onChange={() => this.copyShiftsToggle()}
                    />
                  </Form>
                  {this.state.copyShifts && (
                    <Dropdown className="import-from-dropdown">
                      <Dropdown.Toggle id="dropdown-importFrom">
                        <span
                          className={
                            this.state.copyShiftsSelected === "Select"
                              ? "placeholder"
                              : "selected"
                          }
                        >
                          {this.state.copyShiftsSelected}
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div>
                          {this.state.copyShiftsList.map(
                            (item: any, idx: any) => (
                              <Dropdown.Item
                                key={idx}
                                eventKey={item.id}
                                onClick={() => this.copyShifts(item)}
                              >
                                {item?.templateName}
                              </Dropdown.Item>
                            )
                          )}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </div>
                <div className="copyShiftAssignment">
                  {!(
                    this.state.copyShiftsSelected === "Select" &&
                    this.state.copyUsersSelected === "Select"
                  ) && (
                    <>
                      {this.state.copyShifts &&
                        this.state.copyUsers &&
                        this.state.copyShiftsSelected ===
                          this.state.copyUsersSelected && (
                          <Form>
                            <Form.Check
                              type="switch"
                              id="sw_copy-shifts-assignments"
                              label="Copy Shift Assignments"
                              checked={this.state.copyShiftsAssignments}
                              onChange={() =>
                                this.copyShiftsAssignmentsToggle()
                              }
                            />
                          </Form>
                        )}
                    </>
                  )}
                </div>
              </div>
            </div>
            {this.state.exitsSchedule && (
                <div
                  className="confirmModalNonTable"
                  ref={this.isCopyScheduleScroll}
                >
                  <div className="title">Alert </div>
                  <div className="description">
                    Some of the departments already have shifts scheduled.
                    Performing this import will overwrite these shifts, do you
                    want to proceed ?
                  </div>

                  <div className="action-link">
                    <button
                      onClick={this.onExitsSchudleCancle}
                      className={
                        this.state.buttonSpinner
                          ? "btn wht-bg p-none"
                          : "btn wht-bg"
                      }
                    >
                      No
                    </button>
                    <button
                      onClick={this.onExitsSchudleYes}
                      className={
                        this.state.buttonSpinner
                          ? "btn btn-danger p-none"
                          : "btn btn-danger"
                      }
                    >
                      {this.state.buttonSpinner && (
                        <Spinner
                          className="mr-2"
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      Yes
                    </button>
                  </div>
                </div>
              )}
          </Modal.Body>
          <Modal.Footer>
            <div className="footer-wrapper">
              <Button
                disabled={this.state.buttonSpinner}
                className={
                  this.state.exitsSchedule
                    ? "importForecastAlertButton"
                    : "import-forecast-button"
                }
                onClick={() => this.setForecast()}
              >
                Copy Schedule
              </Button> 
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
