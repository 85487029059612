import * as React from "react";
import { Redirect } from "react-router-dom";
import {
  Container,
  Button,
  OverlayTrigger,
  Tooltip,
  Dropdown,
  Form,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import OutsideClickHandler from "react-outside-click-handler";
import filterFactory from "react-bootstrap-table2-filter";
import { FiChevronDown } from "react-icons/fi";
import { HidSelector } from "../../Common/Components/HidSelector";
import { SingleSearchDropdownList } from "../../Common/Components/SingleSearchDropdownList";
import { Facility } from "../../Common/Services/Facility";
import "./Location.scss";
import "./facilities.scss";
import _ from "lodash";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";
import "./MaintenanceSchedule.scss";
import { IFacilityMaintenanceListRequest, IGetProceureDetailsRequestDto } from "../../Common/Contracts/IMaintenanceSchedule";
import { FacilityMaintenance } from "../../Common/Services/FacilityMaintenance";
import { MaintenanceSchudleSlieOut } from "./MaintenanceSchudleSlieOut";
import { AddMaintenanceSchedule } from "./addMaintenanceSchedule";
import { Utils } from "../../Common/Utilis";



export class MaintenanceSchedule extends React.Component<any, any> {
  private child: any;
  private childHID: any;
  private departmentSearch: any;
  private empTypeSearch: any;
  private tableTypeSearch: any;
  private scheduleUserViewRef: any;
  private checkAllRef: any = {};
  constructor(props: any) {
    super(props);
    this.scheduleUserViewRef = React.createRef();
    this.child = React.createRef();
    this.childHID = React.createRef();
    this.departmentSearch = React.createRef();
    this.empTypeSearch = React.createRef();
    this.tableTypeSearch = React.createRef();
    this.checkAllRef = React.createRef();
    this.state = {
      pageLoader: false,
      showAddAssetModal: false,
      hidValue: "Select",
      hotelName: "Select",
      tableData: [],
      statusList: [],
      frequencies: [],
      priorities: [],
      createEditPermission: false,
      searchText: "",
      status: "",
      frequency: "",
      priority: "",
      sortingOrder: "asc",
      sortingField: "",

      selectedRows: [],

      isNewPmProject: false,
      isSlideOut: false,
      parentRowData: [],
      procedureData: [],
      locationData: [],
      prioritiList: [],
    };
  }

  componentDidMount() {
    this.getPriority();
    this.getFrequency();
    this.getStatusList();
    this.CheckAddEditPermission();
    //this.GetMLocationDetails();

  }

  CheckAddEditPermission = () => {
    Facility.FacilityMaintenanceCheckAddEditPermission().then((res) => {
      this.setState({ createEditPermission: res?.result });
    });
  };

  selectHotelDropDown = (hotelDetails: any) => {
    this.setState({ pageLoader: true });

    this.setState(
      {
        hotelName: hotelDetails.lettercode,
        hidValue: hotelDetails.hotelID,
      },
      () => {
        this.getAllMaintenanceSchedule();
      }
    );
  };

  getAllMaintenanceSchedule = () => {
    const { searchText, status, frequency, priority, hidValue } = this.state;
    const request: IFacilityMaintenanceListRequest = {
      query: searchText,
      priority,
      status,
      frequency,
      hid: hidValue,
    };

    FacilityMaintenance.GetAllMaintenanceSchedule(request)
      .then((tableData: any[] | null) => {
        debugger;
        if ((tableData || [])?.length > 0) {
          tableData = (tableData || [])?.map((x) => {
            x.nextOccurrence = x?.nextOccurrence
              ? moment(x.nextOccurrence).format("MM/DD/YYYY")
              : "";

            if (x.priority?.toString()?.includes("High")) {
              x.priorityOrder = 1;
            } else if (x.priority?.toString()?.includes("Medium")) {
              x.priorityOrder = 2;
            } else if (x.priority?.toString()?.includes("Low")) {
              x.priorityOrder = 3;
            }

            if (x.frequency?.toString()?.includes("Day")) {
              x.frequencyOrder = 1;
            }
            if (x.frequency?.toString()?.includes("Days")) {
              x.frequencyOrder = 2;
            }
            if (x.frequency?.toString()?.includes("Month")) {
              x.frequencyOrder = 3;
            }
            if (x.frequency?.toString()?.includes("Months")) {
              x.frequencyOrder = 4;
            }
            if (x.frequency?.toString()?.includes("Year")) {
              x.frequencyOrder = 5;
            }
            if (x.frequency?.toString()?.includes("Year")) {
              x.frequencyOrder = 6;
            }
            return x;
          });
        }
        this.setState({ tableData: _.orderBy(tableData, "projectName") });
      })
      .catch((error) => {
        if ((error as any)?.message !== "Cancel") {
          Utils.toastError(error?.response?.data, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      });
  };

  getStatusList = () => {
    FacilityMaintenance.GetStatusFacilityMaintenanceSchedule().then(
      (statusList: any[] | null) => {
        let _statusList = _.cloneDeep(statusList);
        if (_statusList?.length > 0) {
          _statusList = _statusList.map((item) => {
            return { id: item, name: item };
          });
        }
        this.setState({
          statusList: [{ id: "", name: "All" }, ..._statusList],
        });
      }
    );
  };

  getFrequency = () => {
    FacilityMaintenance.GetFrequency().then((frequencies: any[] | null) => {
      let _frequencies = _.cloneDeep(frequencies);
      if (_frequencies?.length > 0) {
        _frequencies = _frequencies.map((item) => {
          return { id: item, name: item };
        });
      }
      this.setState({
        frequencies: [{ id: "", name: "All" }, ..._frequencies],
      });
    });
  };

  getPriority = () => {
    FacilityMaintenance.GetPriority().then((priorities: any[] | null) => {
      let _priorities = _.cloneDeep(priorities);
      if (_priorities?.length > 0) {
        _priorities = _priorities.map((item) => {
          return { id: item, name: item };
        });
      }
      this.setState({ priorities: [{ id: "", name: "All" }, ..._priorities] });
      this.setState({ prioritiList: [{ id: "", name: "Select" }, ..._priorities] });

    });
  };

  deleteMaintenanceSchedule = (id) => {
    FacilityMaintenance.DeleteMaintenanceSchedule(id)
      .then((response: any) => {
        if (response?.success) {
          toast.success(response.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          this.getAllMaintenanceSchedule();
        } else {
          Utils.toastError(response.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((error) => {
        Utils.toastError(error.response.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  archiveMaintenanceSchedules = (ids: number[]) => {
    FacilityMaintenance.ArchiveMaintenanceSchedules(ids)
      .then((response: any) => {
        if (response?.success) {
          toast.success(response.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          this.getAllMaintenanceSchedule();
          this.closeBulkAction();
        } else {
          Utils.toastError(response.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((error) => {
        Utils.toastError(error.response.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  updateScheduleStatus = (status) => {
    FacilityMaintenance.UpdateScheduleStatus(this.state.selectedRows, status)
      .then((response: any) => {
        if (response?.success) {
          toast.success(response.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          this.getAllMaintenanceSchedule();
          this.closeBulkAction();
        } else {
          Utils.toastError(response.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((error) => {
        Utils.toastError(error.response.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  onSearchChange = (searchText: string) => {
    this.setState({ searchText: searchText?.toString()?.trimStart() }, () => {
      // if (searchText?.length > 1 || !searchText) {
      this.getAllMaintenanceSchedule();
      // }
    });
  };

  onDDlFiltersChange = (controlID: any, id: any) => {
    let { frequency, status, priority } = this.state;
    switch (controlID) {
      case "ddlFrequency":
        frequency = id || "";
        break;
      case "ddlStatus":
        status = id || "";
        break;
      case "ddlPriority":
        priority = id || "";
        break;
      default:
        break;
    }

    this.setState({ frequency, status, priority }, () => {
      this.getAllMaintenanceSchedule();
    });
  };

  handleSortByColumn = (field) => {
    this.setState(
      {
        sortingField: field,
        sortingOrder: this.state.sortingOrder === "asc" ? "desc" : "asc",
      },
      () => {
        this.sortTableData(this.state.tableData, this.state.sortingField);
      }
    );
  };

  sortTableData = (data: any, fieldName) => {
    if (this.state.sortingOrder === "asc") {
      data = _.orderBy(data, [fieldName], ["asc", "asc"]);
    } else if (this.state.sortingOrder === "desc") {
      data = _.orderBy(data, [fieldName], ["desc", "asc"]);
    }
    this.setState({ tableData: data });
  };
  addNewAsset = () => {
    if (this.state.locationList.length == 0) {
      Utils.toastError("Please Add Locations for this Entity.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "addGarnishment",
      });
    } else {
      this.setState({ showAddAssetModal: true });
    }
  };

  hideAddAsset = () => {
    this.setState({ showAddAssetModal: false }, () => {
      //Call Get Data
      this.getAllMaintenanceSchedule();
    });
  };

  sortImage = (order) => {
    if (!order)
      return (
        <span className="ascending">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
              fill="#1D1E20"
            />
          </svg>
        </span>
      );
    else if (order === "asc")
      return (
        <span className="ascending">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
              fill="#1D1E20"
            />
          </svg>
        </span>
      );
    else if (order === "desc")
      return (
        <span className="descending">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
              fill="#1D1E20"
            />
          </svg>
        </span>
      );

    return null;
  };

  getLocationList = () => {
    Facility.GetDistinctRoomsList(this.state.hidValue)
      .then((res) => {
        this.setState({ locationList: res });
      })
      .catch((res) => {
        this.setState({ locationList: [] });
      });
  };

  handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      this.setState(
        () => ({
          selectedRows: [...this.state.selectedRows, row.id],
        }),
        () => this.refreshTable(this.state.tableData)
      );
    } else {
      this.setState(
        () => ({
          selectedRows: this.state.selectedRows.filter((x) => x !== row.id),
        }),
        () => this.refreshTable(this.state.tableData)
      );
    }
  };

  handleOnSelectAll = (isSelect, rows) => {
    const ids = this.state.tableData.map((r) => r.id);
    if (
      isSelect ||
      (this.state?.selectedRows?.length > 0 &&
        this.state.tableData?.length != this.state?.selectedRows?.length)
    ) {
      this.setState({ selectedRows: [] }, () =>
        this.setState(
          {
            selectedRows: ids,
          },
          () => this.refreshTable(this.state.tableData)
        )
      );
    } else {
      this.setState(
        {
          selectedRows: [],
        },
        () => this.refreshTable(this.state.tableData)
      );
    }
  };

  refreshTable = (data) => {
    this.setState(
      {
        tableData: [],
      },
      () => this.setState({ tableData: _.cloneDeep(data) })
    );
  };

  footerBulkSelectAll = (e) => {
    let allUsers = [] as any;
    let ischecked = e.target.checked;
    const ids = this.state.tableData.map((r) => r.id);
    let selectedRows: any[] = [];
    if (ischecked) {
      this.setState({ selectedRows: [] }, () =>
        this.setState(
          {
            selectedRows: ids,
          },
          () => this.refreshTable(this.state.tableData)
        )
      );
    } else {
      this.closeBulkAction();
    }

    this.setState({ selectedRows });
  };

  closeBulkAction = () => {
    this.setState(
      {
        selectedRows: [],
      },
      () => this.refreshTable(this.state.tableData)
    );
  };

  hanldeDeleteConfirmation = (id: number) => {
    confirmAlert({
      title: "Are you sure?",
      message:
        "This will permanently delete the selected PM project. Are you sure you want to continue?",
      buttons: [
        {
          label: "No",
          onClick: () => { },
        },
        {
          label: "Yes",
          onClick: () => this.deleteMaintenanceSchedule(id),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  hanldeArchiveConfirmation = (ids: number[]) => {
    confirmAlert({
      title: "Are you sure?",
      message: `Archiving will remove PM ${this.state.selectedRows?.length > 1 ? "projects" : "project"
        } from this list. Are you sure you want to continue?`,
      buttons: [
        {
          label: "No",
          onClick: () => { },
        },
        {
          label: "Yes",
          onClick: () => this.archiveMaintenanceSchedules(ids),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  hanldeStatusUpdateConfirmation = (action) => {
    confirmAlert({
      title: "Are you sure?",
      message: `This will make the selected PM ${this.state.selectedRows?.length > 1 ? "projects" : "project"
        } ${action}. Are you sure you want to continue?`,
      buttons: [
        {
          label: "No",
          onClick: () => { },
        },
        {
          label: "Yes",
          onClick: () => this.updateScheduleStatus(action),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  newPmProject = () => {
    this.setState({ isNewPmProject: true });
  }
  iscloseModulePopup = () => {
    this.setState({ isNewPmProject: false });
  }
  isRequestSave = (item: any) => {
  
    let Data: any = [];
    Data.push(item);

    this.setState({ isSlideOut: false, parentRowData: Data }, () => {
      this.setState({ isSlideOut: true });
    });
  }

  closeWorkSlideOut = () => {
    this.setState({ isSlideOut: false });
  }
  saveCloseWorkSlideOut = () => {
    this.setState({ isSlideOut: false }, () => {
      this.getAllMaintenanceSchedule();
    });
  }

  onRowClick = (
    e: any,
    row: any,
    rowIndex: any,
    type: any,
    lastworkOrderID: any
  ) => {
   


    const sdate = new Date(row.startDate);

    let calenderYeare = sdate.getFullYear().toString().substr(2, 4);
    let calenderDatee =
      sdate.getDate() > 9 ? sdate.getDate() : 0 + "" + sdate.getDate();
    let calenderMonthe =
      sdate.getMonth() + 1 > 9
        ? sdate.getMonth() + 1
        : 0 + "" + (sdate.getMonth() + 1);
    let tempsDate = calenderMonthe + "/" + calenderDatee + "/" + calenderYeare;

    const eDate = new Date(row.endDate);
    let calenderYeare1 = eDate.getFullYear().toString().substr(2, 4);
    let calenderDatee1 =
      eDate.getDate() > 9 ? eDate.getDate() : 0 + "" + eDate.getDate();
    let calenderMonthe1 =
      eDate.getMonth() + 1 > 9
        ? eDate.getMonth() + 1
        : 0 + "" + (eDate.getMonth() + 1);
    let tempEDate = calenderMonthe1 + "/" + calenderDatee1 + "/" + calenderYeare1;


    let NextDueDate = "";
    let frequency_value = "t";
    let maintenceData: any = {};
    maintenceData.id = row.id;
    maintenceData.name = row.projectName;
    maintenceData.startDate =moment(tempsDate).format("MM/DD/YYYY"); ;

    // let arrfrequency=row.frequency.split(" ");
    // if(arrfrequency.length>0){
    //   frequency_value=arrfrequency[1];
    // }
    frequency_value = row.frequencyOrginalValue;
    if (frequency_value.toLowerCase() === "day" || frequency_value.toLowerCase() === "days") {
      NextDueDate = moment(row.nextOccurrence == "" ? tempsDate : row.nextOccurrence).add(Number(row.frequencyValue), "days").format("MM/DD/YYYY");;
    }
    if (frequency_value.toLowerCase() == "month" || frequency_value.toLowerCase() == "months") {
      NextDueDate = moment(row.nextOccurrence == "" ? tempsDate : row.nextOccurrence).add(Number(row.frequencyValue), "months").format("MM/DD/YYYY");;
    }
    if (frequency_value.toLowerCase() === "year" || frequency_value.toLowerCase() === "years") {
      NextDueDate = moment(row.nextOccurrence == "" ? tempsDate : row.nextOccurrence).add(Number(row.frequencyValue), "years").format("MM/DD/YYYY");;
    }

    maintenceData.dueDate = moment(NextDueDate).add(-1, "days").format("MM/DD/YYYY");


    //maintenceData.dueDate=tempEDate;    
    maintenceData.frequency = row.frequency;
    maintenceData.frequenId = row.frequencyValue;
    maintenceData.nextOccurrenceDate = row.nextOccurrence==="" ? moment(tempsDate).format("MM/DD/YYYY"):moment(row.nextOccurrence).format("MM/DD/YYYY"); ;;
    maintenceData.priority = row.priority===null ? "" :  row.priority;
    maintenceData.isChanges=false;
    maintenceData.isNew = false;

    let Data: any = [];

    Data.push(maintenceData);


    this.setState({ isSlideOut: false, parentRowData: Data }, () => {
      this.setState({ isSlideOut: true });
    });

    // this.setState({ isSlideOut: true});
  }


  render() {
    const {
      statusList,
      frequencies,
      priorities,
      searchText,
      status,
      frequency,
      priority,
      tableData,
      selectedRows,
    } = this.state;

    const tableRowEvents = {
      onClick: (e, row, rowIndex) => {
        this.onRowClick(e, row, rowIndex, "table", "");
      },

    }

    const tableColumnEvent = {
      onClick: (e, column, columnIndex, row, rowIndex) => {
       //debugger;
        // if (this.state.isPMCycleType == "ParamcycleIdType") {
        //   this.handleShowSlideout("", "row", "", "ParamcycleIdType", Number(this.state.lastcycleId));
        // }
        // else{
          this.onRowClick(e, row, rowIndex, "table", "");
        // }
      },
    };

    const columns = [
      {
        dataField: "projectName",
        text: "Name",
        events: tableColumnEvent,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <>
              <EllipsisWithTooltip placement="bottom">
                {row.projectName}
              </EllipsisWithTooltip>
            </>
          );
        },
      },
      {
        dataField: "status",
        text: "Status",
        events: tableColumnEvent,
        headerFormatter: (column) => {

          return (
            <div
              className={`d-inline-block ${this.state.sortingField === "status" ? "active" : ""
                }`}
              onClick={() => this.handleSortByColumn("status")}
            >
              {column.text}
              {this.sortImage(this.state.sortingOrder)}
            </div>
          );
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <>
              <EllipsisWithTooltip placement="bottom">
                {row.status?.toString()?.toLowerCase() == "active" && (
                  <span className="badge color-green">{row.status}</span>
                )}
                {row.status?.toString()?.toLowerCase() == "inactive" && (
                  <span className="badge color-grey">{row.status}</span>
                )}{" "}
              </EllipsisWithTooltip>
            </>
          );
        },
      },

      {
        dataField: "frequency",
        text: "Frequency",
        events: tableColumnEvent,
        headerFormatter: (column) => {
          console.log(column);
          return (
            <div
              className={`d-inline-block ${this.state.sortingField === "frequencyOrder" ? "active" : ""
                }`}
              onClick={() => this.handleSortByColumn("frequencyOrder")}
            >
              {column.text}
              {this.sortImage(this.state.sortingOrder)}
            </div>
          );
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <>
              <EllipsisWithTooltip placement="bottom">
                <div className="">{row.frequency} </div>{" "}
              </EllipsisWithTooltip>
            </>
          );
        },
      },
      {
        dataField: "noOfLocations",
        text: "Locations",
        events: tableColumnEvent,
        headerFormatter: (column) => {

          return (
            <div
              className={`d-inline-block ${this.state.sortingField === "noOfLocations" ? "active" : ""
                }`}
              onClick={() => this.handleSortByColumn("noOfLocations")}
            >
              {column.text}
              {this.sortImage(this.state.sortingOrder)}
            </div>
          );
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <>
              <EllipsisWithTooltip placement="bottom">
                <div className="">{row.noOfLocations} </div>{" "}
              </EllipsisWithTooltip>
            </>
          );
        },
      },

      {
        dataField: "nextOccurrence",
        text: "Next Occurrence",
        events: tableColumnEvent,
        headerFormatter: (column) => {

          return (
            <div
              className={`d-inline-block ${this.state.sortingField === "nextOccurrence" ? "active" : ""
                }`}
              onClick={() => this.handleSortByColumn("nextOccurrence")}
            >
              {column.text}
              {this.sortImage(this.state.sortingOrder)}
            </div>
          );
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <>
              <EllipsisWithTooltip placement="bottom">
                <div className="">{row.nextOccurrence} </div>{" "}
              </EllipsisWithTooltip>
            </>
          );
        },
      },

      {
        dataField: "priority",
        text: "Priority",
        events: tableColumnEvent,
        headerFormatter: (column) => {

          return (
            <div
              className={`d-inline-block ${this.state.sortingField === "priorityOrder" ? "active" : ""
                }`}
              onClick={() => this.handleSortByColumn("priorityOrder")}
            >
              {column.text}
              {this.sortImage(this.state.sortingOrder)}
            </div>
          );
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <>
              {row.priority ? (
                <EllipsisWithTooltip placement="bottom">
                  <div className="priority-status">
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill={row.priority === "Low" ? "blue" : (row.priority === "High" ? "red" : "orange")} className={`bi bi-dot `} viewBox="0 0 16 16">
                  <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                </svg> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      className={`bi bi-dot `}
                    >
                      <circle
                        cx="4"
                        cy="4"
                        r="4"
                        fill={
                          row.priority === "Low"
                            ? "#15a4fa"
                            : row.priority === "High"
                            ? "#d3412e"
                            : "#fca147"
                        }
                      />
                    </svg>
                    {row.priority}{" "}
                  </div>{" "}
                </EllipsisWithTooltip>
              ) : (
                <></>
              )}
            </>
          );
        },
      },
      {
        dataField: "",
        text: "",
        formatExtraData: {
          createEditPermission: this.state?.createEditPermission,
          selectedRows,
        },
        formatter: (cell: any, row: any, rowIndex: any, extraData) => (
          <Dropdown
            // onClick={(e) => {
            //   e.stopPropagation();
            // }}
            className="more-action position-static"
            alignRight
          >
            <Dropdown.Toggle
              className="btn-outline-primary btn btn-primary btn-ghost more"
              id="dropdown-more"
              disabled={
                !extraData?.createEditPermission ||
                extraData.selectedRows?.length > 0
              }
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {Number(row.inUse) !== 1 && (
                <Dropdown.Item
                  onClick={() => this.hanldeDeleteConfirmation(row.id)}
                >
                  Delete
                </Dropdown.Item>
              )}
              <Dropdown.Item
                onClick={() => this.hanldeArchiveConfirmation([row.id])}
              >
                Archive
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ),
      },
    ];

    const selectRow = {
      mode: "checkbox",
      nonSelectable: !this.state?.createEditPermission
        ? tableData?.map((r) => r?.id)
        : [],
      selected: this.state.selectedRows,
      onSelect: this.handleOnSelect,
      onSelectAll: (isSelect, rows) =>
        !this.state?.createEditPermission
          ? {}
          : this.handleOnSelectAll(isSelect, rows),
      selectionHeaderRenderer: ({ mode, ...rest }) => (
        <Form.Check
          type={mode}
          {...rest}
          label=""
          custom
          disabled={!this.state?.createEditPermission}
          ref={(chkRef) => {
            if (chkRef) {
              this.checkAllRef = chkRef;
              if (
                this.state.selectedRows?.length === tableData.length &&
                tableData?.length > 0
              ) {
                chkRef.checked = true;
              } else {
                chkRef.checked = false;
              }

              if (this.state.selectedRows?.length > 0 && !chkRef?.checked) {
                chkRef.indeterminate = true;
              } else {
                chkRef.indeterminate = false;
              }
            }
          }}
        />
      ),
      selectionRenderer: ({ mode, ...rest }) => (
        <>
          {/* <input type={ mode } { ...rest } /> */}
          <Form.Check
            type={mode}
            {...rest}
            label=""
            custom
            disabled={!this.state?.createEditPermission}
          />
        </>
      ),
    };

    const shouldDisaplayActiveInactiveBulkButton = () => {
      let label = "";
      let action = "";
      let shouldDisplay = false;
      if (
        tableData.filter(
          (x) => selectedRows?.includes(x?.id) && x?.status === "Active"
        )?.length === selectedRows?.length
      ) {
        label = "Make Inactive";
        shouldDisplay = true;
        action = "Inactive";
      } else if (
        tableData.filter(
          (x) => selectedRows?.includes(x?.id) && x?.status === "Inactive"
        )?.length === selectedRows?.length
      ) {
        shouldDisplay = true;
        label = "Make Active";
        action = "Active";
      }
      return { label, shouldDisplay, action };
    };


    return (
      <>
        {this.state.isNewPmProject && (
          <AddMaintenanceSchedule
            //  hid={this.state.hidValue}
            // priorityList={this.state.priorityList}
            showAddNewPmProject={this.state.isNewPmProject}
            isRequestSave={this.isRequestSave}
            // assignToList={this.state.assignToListToChild}
            iscloseModulePopup={this.iscloseModulePopup}
          // locationTypesList={this.state.locationTypesList}

          />
        )}


        <div className="performance-position performance timesheet location-page-wrapper maintenance-schedule-page">
          <Container fluid className="body-sec">
            <div className="page-heading underline d-flex">
              <HidSelector
                onClickEvent={this.selectHotelDropDown}
                ref={this.childHID}
                modulename={"Facility Maintenance"}
                disabled={selectedRows?.length > 0}
              />
              <div className="mr-auto d-flex align-items-center">
                Maintenance Schedules
              </div>


 
       
 

             
              {/* {this.state?.createEditPermission && (
                <Button disabled={selectedRows?.length > 0}>
                  New PM Project
                </Button>
              )} */}

              {this.state?.createEditPermission &&
 
               <Button onClick={() => this.newPmProject()} disabled={selectedRows?.length > 0} >New PM Project</Button>
 
              } 
 

              <Dropdown className="more-action ml-3">
                <DropdownToggle
                  disabled={
                    !this.state.createEditPermission || selectedRows?.length > 0
                  }
                  className="btn-outline-primary btn btn-primary more"
                  id="dropdown-more"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                  </svg>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem></DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
            {/* {this.state.pageLoader ? (
              <ReactPageLoader useas={"timesheetMain"} />
            ) : ( */}
            {this.state.hidValue !== "Select" && (
              <div className="main-Page">
                <div className="bdy-sec">
                  <ToolkitProvider
                    keyField="userUniqueno"
                    data={tableData}
                    columns={columns}
                    hover
                  >
                    {(props: {
                      searchProps: JSX.IntrinsicAttributes;
                      baseProps: JSX.IntrinsicAttributes;
                    }) => (
                      <div>
                        <div className="search-header d-flex">
                          <div className="schedule-main">
                            <div className="schedule-department-view-table">
                              <div className="departmentViewNavigation">
                                <div className="navigation-menus d-flex">
                                  <div className="schedule-table-search">
                                    <input
                                      type="text"
                                      onChange={(e) =>
                                        this.onSearchChange(e.target.value)
                                      }
                                      value={searchText}
                                      disabled={selectedRows?.length > 0}
                                      className="search-box form-control"
                                      placeholder="Search..."
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="room-type-dropdown">
                            {statusList?.length > 0 && (
                              <SingleSearchDropdownList
                                id={"ddlStatus"}
                                isRefresh={this.state.isRefresh}
                                itemList={statusList}
                                allowSearch={false}
                                handleSelectedItem={this.onDDlFiltersChange.bind(
                                  this,
                                  "ddlStatus"
                                )}
                                defaultItem={status}
                                defaultText={"Search Status"}
                                defaultName={"Status"}
                                controlID="2"
                                isdisable={selectedRows?.length > 0}
                              />
                            )}
                          </div>{" "}
                          <div className="room-type-dropdown">
                            {frequencies?.length > 0 && (
                              <SingleSearchDropdownList
                                id={"ddlFrequency"}
                                isRefresh={this.state.isRefresh}
                                allowSearch={false}
                                itemList={frequencies}
                                handleSelectedItem={this.onDDlFiltersChange.bind(
                                  this,
                                  "ddlFrequency"
                                )}
                                defaultItem={frequency}
                                defaultText={"Search Frequency"}
                                defaultName={"Frequency"}
                                controlID="3"
                                isdisable={selectedRows?.length > 0}
                              />
                            )}
                          </div>
                          <div className="room-type-dropdown">
                            {priorities.length > 0 && (
                              <SingleSearchDropdownList
                                id={"ddlPriority"}
                                isRefresh={this.state.isRefresh}
                                allowSearch={false}
                                itemList={priorities}
                                handleSelectedItem={this.onDDlFiltersChange.bind(
                                  this,
                                  "ddlPriority"
                                )}
                                defaultItem={priority}
                                defaultText={"Search Priority"}
                                defaultName={"Priority"}
                                controlID="1"
                                isdisable={selectedRows?.length > 0}
                              />
                            )}
                          </div>
                          <Dropdown
                            className="setting-dropdown more-action"
                            alignRight
                          >
                            <Dropdown.Toggle
                              disabled={selectedRows?.length > 0}
                              className=""
                              id="dropdown-more"
                            >
                              <div className="d-flex align-items-center">
                                <svg
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M0.562614 9.18059L1.89595 11.4873C2.07995 11.8059 2.48795 11.9153 2.80661 11.7306L3.73728 11.1933C4.12395 11.4979 4.55128 11.7479 5.00061 11.9346V12.9999C5.00061 13.3679 5.29861 13.6666 5.66728 13.6666H8.33395C8.70261 13.6666 9.00062 13.3679 9.00062 12.9999V11.9346C9.44995 11.7479 9.87661 11.4979 10.2639 11.1933L11.1946 11.7306C11.5126 11.9139 11.9219 11.8039 12.1053 11.4873L13.4386 9.18059C13.6219 8.86325 13.5119 8.45325 13.1953 8.26992L12.2806 7.74125C12.3153 7.49525 12.3333 7.24725 12.3333 6.99992C12.3333 6.75259 12.3153 6.50459 12.2793 6.25858L13.1939 5.72992C13.5113 5.54659 13.6213 5.13659 13.4373 4.81925L12.1039 2.51259C11.9199 2.19392 11.5119 2.08459 11.1933 2.26925L10.2626 2.80659C9.87595 2.50192 9.44928 2.25125 8.99995 2.06525V0.999919C8.99995 0.631919 8.70195 0.333252 8.33328 0.333252H5.66661C5.29795 0.333252 4.99995 0.631919 4.99995 0.999919V2.06525C4.55061 2.25192 4.12395 2.50192 3.73661 2.80659L2.80661 2.26925C2.48728 2.08459 2.07995 2.19392 1.89595 2.51259L0.562614 4.81925C0.379281 5.13659 0.489281 5.54659 0.805948 5.72992L1.72061 6.25858C1.68461 6.50459 1.66661 6.75259 1.66661 6.99992C1.66661 7.24725 1.68461 7.49525 1.72061 7.74125L0.805948 8.26992C0.488614 8.45325 0.378614 8.86325 0.562614 9.18059ZM6.99995 4.33325C8.47061 4.33325 9.66661 5.52925 9.66661 6.99992C9.66661 8.47059 8.47061 9.66659 6.99995 9.66659C5.52928 9.66659 4.33328 8.47059 4.33328 6.99992C4.33328 5.52925 5.52928 4.33325 6.99995 4.33325Z" />
                                </svg>
                                <div className="drop-arrow ml-0 d-flex">
                                  <FiChevronDown />
                                </div>
                              </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item></Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>

                        <div
                          className={`hasCursorPointer position-relative hide-header-scrollbar ${selectedRows?.length > 0 ? "bottom-space" : ""
                            }`}
                        >
                          <div className="block-bar"></div>
                          <BootstrapTable
                            id={"maitenanceScheduleTable"}
                            {...props.baseProps}
                            keyField="id"
                            hover
                            selectRow={selectRow}
                            // rowEvents={tableRowEvents}
                          />
                        </div>





                        {selectedRows?.length > 0 && (
                          <div className="tableFooter TabFooter-Pay">
                            <div className="bulkSelectFooter">
                              {/* this.footerchk.current.indeterminate = false; */}
                              <Form.Check
                                type="checkbox"
                                label=""
                                id="footerBulkCheckBox"
                                ref={(chkRef) => {
                                  if (chkRef) {
                                    this.checkAllRef = chkRef;
                                    if (
                                      this.state.selectedRows?.length ===
                                      tableData.length
                                    ) {
                                      chkRef.checked = true;
                                    } else {
                                      chkRef.checked = false;
                                    }

                                    if (
                                      this.state.selectedRows?.length > 0 &&
                                      !chkRef?.checked
                                    ) {
                                      chkRef.indeterminate = true;
                                    } else {
                                      chkRef.indeterminate = false;
                                    }
                                  }
                                }}
                                onClick={(e: any) => {
                                  e.stopPropagation();
                                  this.footerBulkSelectAll(e);
                                }}
                              />
                              <span className="shift-selected-count">
                                You have selected {selectedRows?.length} PM{" "}
                                {selectedRows?.length <= 1 && "project"}
                                {selectedRows?.length > 1 && "projects"}
                              </span>
                              <div className="action-group d-flex flex-row mr-auto">
                                {shouldDisaplayActiveInactiveBulkButton()
                                  ?.shouldDisplay && (
                                    <Button
                                      disabled={selectedRows?.length <= 0}
                                      className="unassign btn-outline-primary"
                                      onClick={() =>
                                        this.hanldeStatusUpdateConfirmation(
                                          shouldDisaplayActiveInactiveBulkButton()
                                            .action
                                        )
                                      }
                                    >
                                      {
                                        shouldDisaplayActiveInactiveBulkButton()
                                          ?.label
                                      }
                                    </Button>
                                  )}

                                <Button
                                  disabled={selectedRows?.length <= 0}
                                  className="remove btn-outline-primary"
                                  onClick={() =>
                                    this.hanldeArchiveConfirmation(selectedRows)
                                  }
                                >
                                  Archive
                                </Button>
                              </div>
                              <Button
                                className="closeButton btn-ghost-light"
                                onClick={() => this.closeBulkAction()}
                              >
                                &times;
                              </Button>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </ToolkitProvider>


                  {this.state.isSlideOut && (
                    <div>
                      <MaintenanceSchudleSlieOut
                        //  ref={this.wororderSlideOut}
                        closeWorkSlideOut={this.closeWorkSlideOut}
                        parentState={this.state}
                        saveCloseWorkSlideOut={this.saveCloseWorkSlideOut}
                      // parentRowData={this.state.parentRowData}
                      //workOrderStatusUpdate={this.workOrderStatusUpdate}
                      //changesOfSlideOut={this.changesOfSlideOut}
                      //refeshTable={this.refeshTable}                               
                      />
                    </div>

                  )}


                </div>
              </div>
            )}
            {/* )} */}
          </Container>
        </div>
      </>
    );
  }
}
