import React from "react";
import { Dropdown, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import OutsideClickHandler from "react-outside-click-handler";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import scrollIntoView from "scroll-into-view";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { resolve, reject } from "q";
import chevronRight from "../../../../../Common/Assets/Images/icons/bx-chevron-right.svg";
import { ApproveDepartmentDetails } from "../../../../../Common/Components/ApproveDepartmentDetails";
// import PageLoader from "../../../../../Common/Components/PageLoader";
import { LaborDayApprove } from "../../../../../Common/Services/LaborDayApprove";
import {
  IOverUnderDeptsDaysDto,
  IApproveSaveRequestDto,
} from "../../../../../Common/Contracts/ILaborDayApprove";
import { ReactPageLoader } from "../../../../../Common/Components/ReactPageLoader";
import { Utils } from "../../../../../Common/Utilis";
const approveDepartmentDetailsContent = [
  {
    title: "Approve Over Budget",
    cancelButtonName: "Cancel",
    actionButtonName: "Approve",
    table: "overBudget",
  },
];
let localStorageUser: any;
export default class autoPunchOut extends React.Component<any, any> {
  private overBudgetDivRef: any;
  private approveDepartment: any;
  private expendedOverBudgetRef: any;
  constructor(props: any) {
    super(props);
    this.approveDepartment = React.createRef();
    this.overBudgetDivRef = React.createRef();
    this.expendedOverBudgetRef = React.createRef();
    this.state = {
      parentRow: props.row,
      overBudgetDeptsData: [],
      multipleRowSelected: false,
      selectedRows: [],
      preSelectedRows: [],
      tableExpended: [],
      loaderOverBudget: false,
      flyOutMenuApprove: false,
      expendedRowIndex: 0,
      isExpand: false,
    };
  }

  componentDidMount() {
    this.overBudgetsDeptApprovalData();
  }

  overBudgetsDeptApprovalData() {
    this.setState({ loaderOverBudget: true });

    localStorageUser = JSON.parse(localStorage.getItem("storage")!);

    let parentRow = this.state.parentRow;
    let request = {} as IOverUnderDeptsDaysDto;
    request.hotelID = Number(parentRow.hotelID);
    request.user_uniqueno = localStorageUser.user_UniqueID;
    request.date = parentRow.date;
    request.entryType = "OverBudget";

    LaborDayApprove.GetOverUnderDeptApprovalDays(request)
      .then(async (result: any | null) => {
        let DayApproveData: any = [];
        if (result != null && result.length > 0) {
          DayApproveData = result;
          for (let i = 0; i < DayApproveData.length; i++) {
            DayApproveData[i].rowNo = i + 1;
            DayApproveData[i].action = "";
            DayApproveData[i].flyOutAction = "";
            DayApproveData[i].hoverOutAction = "";
          }
          this.setState({
            overBudgetDeptsData: DayApproveData,
            loaderOverBudget: false,
          });
        } else {
          this.setState({ loaderOverBudget: false });
        }

        scrollIntoView(this.overBudgetDivRef.current, {
          align: {
            top: 0,
            left: 0,
            topOffset: 259,
            leftOffset: 0,
          },
        });
        // this.setState({ loaderOverBudget: false });
        resolve();
        //this.setState({ loaderOverBudget: false });
      })
      .catch((error) => {
        this.setState({ loaderOverBudget: false });
        Utils.toastError(`Server Error: ${error}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "laborDayOverviewContainer",
        });
        reject();
      });
  }

  rowApprove = (row: any, actionItem: any, childState: any) => {
    ;
    this.setState({ loaderOverBudget: true });
    let data = this.state.overBudgetDeptsData;
    let parentRow = this.state.parentRow;
    let requestArray: any = [];

    if (actionItem === "singleApprove") {
      let request = {} as IApproveSaveRequestDto;
      request.hotelID = Number(parentRow.hotelID);
      request.user_uniqueno = localStorageUser.user_UniqueID;
      request.userName = localStorageUser.userName;
      request.date = parentRow.date;
      request.explanation = childState.approvalReason;
      request.tenantID = localStorageUser.tenantID;
      request.entryType = "detail";
      request.deptId = row.deptID;
      request.UnApproveDeptList="-1";
      requestArray.push(request);
    } else {
      for (let i = 0; i < row.length; i++) {
        let request = {} as IApproveSaveRequestDto;
        request.hotelID = Number(parentRow.hotelID);
        request.user_uniqueno = localStorageUser.user_UniqueID;
        request.userName = localStorageUser.userName;
        request.date = parentRow.date;
        request.explanation = childState.approvalReason;
        request.tenantID = localStorageUser.tenantID;
        request.entryType = "detail";
        request.deptId = row[i].deptID;
        request.UnApproveDeptList="-1";
        requestArray.push(request);
      }
    }

    LaborDayApprove.ApproveLaborBudgetSetting(requestArray)
      .then(async (result: any | null) => {
        if (result != null) {
          let resultData = result.result as any;
          if (resultData.saveStatus === "Success") {
            if (actionItem === "singleApprove") {
              let indexOf = data.indexOf(row);
              data.splice(indexOf, 1);
              this.props.overBudgetApprove("Approved", "overBudget");
            } else if (actionItem === "bulkApprove") {
              for (let i = 0; i < row.length; i++) {
                let indexOf = data.indexOf(row[i]);
                data.splice(indexOf, 1);
                this.props.overBudgetApprove("Approved", "overBudget");
              }
            }
            this.setState({
              overBudgetDeptsData: data,
              selectedRows: [],
              preSelectedRows: [],
              multipleRowSelected: false,
            });
            for (let i = 0; i < data.length; i++) {
              let index = data.indexOf(data[i]);
              data[index].hoverOutAction = "";
              data[index].flyOutAction = "";
            }
            this.setState({ overBudgetDeptsData: data });

            toast.success(`Department(s) approved successfully`, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "laborDayOverviewContainer",
            });
          } else {
            Utils.toastError(resultData.message, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "laborDayOverviewContainer",
            });
          }
        }
        this.setState({
          loaderOverBudget: false,
        });
        resolve();
      })
      .catch((error) => {
        Utils.toastError(`Server Error: ${error}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "laborDayOverviewContainer",
        });
        this.setState({ loaderOverBudget: false });
        reject();
      });
  };

  approveModalAction = (
    row: any,
    actionItem: any,
    flag: any,
    childState: { approvalReason: string }
  ) => {
    if (flag) {
      if (childState.approvalReason === "") {
        Utils.toastError(`Please enter the explanation`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "laborDayOverviewContainer",
        });
      } else {
        if (childState.approvalReason.length > 200) {
          Utils.toastError(`Explanation should not exceed 200 characters`, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "laborDayOverviewContainer",
          });
        } else {
          this.rowApprove(row, actionItem, childState);
        }
      }
    } else {
      if (actionItem === "singleApprove") {
        let data = this.state.overBudgetDeptsData;
        let index = data.indexOf(row);
        data[index].flyOutAction = "";
        this.setState({ overBudgetDeptsData: data });
      }
    }
  };

  ApproveSetFocus = (e: any) => {
    setTimeout(() => {
      if (this.approveDepartment.current != null) {
        this.approveDepartment.current.setFocus();
      }
    }, 0);
    if (e !== "text") {
      e.stopPropagation();
    }
  };

  refreshOverBudgetChild() {
    this.overBudgetsDeptApprovalData();
  }

  overBudgetDetails = (row: any, rowIndex: any, parentName) => {
    ;
    if(!parentName) {
   parentName = "overBudget";
    }
    // this.setState({ showDetails: true }, () => {
    row.parentName = parentName;
    this.props.positionDetail(row);
    // });
  };

  handleOnExpand = (row: any, isExpand: any, rowIndex: any, e: any) => {
    if (this.state.tableExpended.length > 0) {
      // this.setState(() => ({
      //   tableExpended: this.state.tableExpended.filter(
      //     (x: any) => x !== row.rowNo
      //   ),
      // }));

      this.setState(
        { expendedRowIndex: rowIndex, isExpand: false, loaderOverBudget: true },
        () => {
          this.setState(() => ({
            tableExpended: [],
          }));

          let data = this.state.overBudgetDeptsData;
          for (let i = 0; i < data.length; i++) {
            let index = data.indexOf(data[i]);
            data[index].action = "";
          }
          this.setState({ overBudgetDeptsData: data, loaderOverBudget: false });
        }
      );
      // row.action = "Expended";
    } else {
      row.action = "Expended";
      this.setState(() => ({
        tableExpended: [...this.state.tableExpended, row.rowNo],
      }));
      this.setState(
        { expendedRowIndex: rowIndex, isExpand: true, loaderOverBudget: false },
        () => {
          this.scrollExpendedInView();
        }
      );
    }
  };

  headerFormat = (column: { text: React.ReactNode }, colIndex: any) => {
    if (this.state.multipleRowSelected === true) {
      return (
        <div className="headerApproveDropDown">
          <Dropdown
            className="more-action"
            alignRight
            onClick={this.ApproveSetFocus}
          >
            <Dropdown.Toggle
              className="btn-outline-primary overBudgetApprove more"
              id="dropdown-more"
            >
              Approve
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <ApproveDepartmentDetails
                approveDepartmentDetailsContent={
                  approveDepartmentDetailsContent
                }
                table="overBudget"
                ref={this.approveDepartment}
                approveModalAction={this.approveModalAction.bind(
                  this,
                  this.state.selectedRows,
                  "bulkApprove"
                )}
              />
            </Dropdown.Menu>
          </Dropdown>
        </div>
      );
    } else {
      return <span className="overBudgetDepartment">{column.text}</span>;
    }
  };

  handlePositionDetails = (row: any, rowIndex: any) => {
    ;
    this.overBudgetDetails(row, rowIndex,"");
    // alert("ok");
  };

  selectRow = (selectedRow: any, isSelect: boolean, rowIndex: any, e: any) => {
    let data = this.state.overBudgetDeptsData;
    let selectedRows = this.state.selectedRows;
    let preSelectedRows = this.state.preSelectedRows;
    if (isSelect === true) {
      if (
        selectedRow.isAutoPunch === "No" &&
        selectedRow.isNotPunchOut === "No"
      ) {
        selectedRows.push(selectedRow);
        preSelectedRows.push(selectedRow.rowNo);
      }
      if (selectedRows.length > 0) {
        this.setState({
          multipleRowSelected: true,
        });

        for (let i = 0; i < data.length; i++) {
          let index = data.indexOf(data[i]);
          data[index].hoverOutAction = "selected";
          data[index].flyOutAction = "rowSeclected";
        }
        this.setState({ overBudgetDeptsData: data });
      } else {
        this.setState({
          multipleRowSelected: false,
        });
      }
      this.setState({
        selectedRows: selectedRows,
        preSelectedRows: preSelectedRows,
      });
    } else {
      let indexOf = selectedRows.indexOf(selectedRow);
      selectedRows.splice(indexOf, 1);
      preSelectedRows.splice(indexOf, 1);
      if (selectedRows.length > 0) {
        this.setState({
          multipleRowSelected: true,
        });
      } else {
        this.setState({
          multipleRowSelected: false,
        });

        for (let i = 0; i < data.length; i++) {
          let index = data.indexOf(data[i]);
          data[index].hoverOutAction = "";
          data[index].flyOutAction = "";
        }
        this.setState({ overBudgetDeptsData: data });
      }
      this.setState({
        selectedRows: selectedRows,
        preSelectedRows: preSelectedRows,
      });
    }
  };

  selectRowAll = (isSelect: boolean, rows: any, e: any) => {
    let data = this.state.overBudgetDeptsData;
    if (isSelect === true) {
      let tempSelectedRows: any = [];
      let selectedRows = this.state.selectedRows;
      let preSelectedRows = this.state.preSelectedRows;
      selectedRows = tempSelectedRows;
      for (let i = 0; i < rows.length; i++) {
        if (rows[i].isAutoPunch === "No" && rows[i].isNotPunchOut === "No") {
          selectedRows.push(rows[i]);
          preSelectedRows.push(rows[i].rowNo);
        }
        let index = data.indexOf(rows[i]);
        data[index].hoverOutAction = "selected";
        data[index].flyOutAction = "rowSeclected";
      }

      if (selectedRows.length > 0) {
        this.setState({
          multipleRowSelected: true,
        });

        this.setState({ overBudgetDeptsData: data });
      } else {
        this.setState({ multipleRowSelected: false });
      }
      this.setState({
        selectedRows: selectedRows,
        preSelectedRows: preSelectedRows,
      });
    } else {
      this.setState({
        multipleRowSelected: false,
        selectedRows: [],
        preSelectedRows: [],
      });

      for (let i = 0; i < data.length; i++) {
        let index = data.indexOf(data[i]);
        data[index].hoverOutAction = "";
        data[index].flyOutAction = "";
      }
      this.setState({ overBudgetDeptsData: data });
    }
  };

  showCommentComponent = (row: any) => {
    let data = this.state.overBudgetDeptsData;
    let index = data.indexOf(row);
    data[index].flyOutAction = "show";
    this.setState({ overBudgetDeptsData: data }, () => {
      this.setState({ flyOutMenuApprove: true }, () => {
        this.ApproveSetFocus("text");
      });
    });
  };

  onOutsideClick = (e: any, row: any) => {
    if (document?.getElementById("performanceSlideOut")) {
      return;
  }
    let data = this.state.overBudgetDeptsData;
    let index = data.indexOf(row);
    data[index].flyOutAction = "";
    this.setState({ overBudgetDeptsData: data, flyOutMenuApprove: false });
  };

  scrollExpendedInView = () => {
    if (this.state.tableExpended.length > 0) {
      setTimeout(() => {
        //
        scrollIntoView(this.expendedOverBudgetRef.current, {
          align: {
            top: 0,
            left: 0,
            topOffset: 259,
            leftOffset: 0,
          },
        });
      }, 0);
    } else {
      return false;
    }
  };



  render() {
    const overBudgetDeptsselectRow = {
      mode: "checkbox",
      classes: "selection-row",
      clickToExpand: true,
      selected: this.state.preSelectedRows,
      onSelect: (
        selectedRow: any,
        isSelect: boolean,
        rowIndex: any,
        e: any
      ) => {
        this.selectRow(selectedRow, isSelect, rowIndex, e);
      },
      onSelectAll: (isSelect: boolean, rows: any, e: any) => {
        this.selectRowAll(isSelect, rows, e);
      },
    };

    const rowClasses = (row: any, rowIndex: any) => {
      if (row.isAutoPunch === "Yes" || row.isNotPunchOut === "Yes") {
        return "row-disabled";
      }
      // if (
      //   (this.state.isExpand === true && row.action === "Expended") ||
      //   Number(this.state.expendedRowIndex) === rowIndex
      // ) {
      //   return "row-Expended";
      // } else if (this.state.isExpand === true) {
      //   return "row-Collapsed";
      // } else {
      //   return "row-Action";
      // }
    };

    const expandRow = {
      onlyOneExpanding: true,
      renderer: (row: any) => {
        // this.scrollExpendedInView();
        const rowColumns = [
          {
            dataField: "",
            formatter: (cell: any, row: any, rowIndex: any) => (
              <span className="rowBlank"></span>
            ),
          },
          {
            dataField: "",
            formatter: (cell: any, row: any, rowIndex: any) => (
              <span className="rowBlank"></span>
            ),
          },
          {
            dataField: "position",
            formatter: (cell: any, row: any, rowIndex: any) => (
              <EllipsisWithTooltip placement="bottom">
                <span className="rowPostion">{row.positionName}</span>
              </EllipsisWithTooltip>
            ),
          },
          {
            dataField: "budgeted",
            formatter: (cell: any, row: any, rowIndex: any) => (
              <span className="rowBudgeted">{row.budgetTotal}</span>
            ),
          },
          {
            dataField: "actual",
            formatter: (cell: any, row: any, rowIndex: any) => {
              let actualTotal = Number(row.actualTotal.replace(":", "."));
              let budgetTotal = Number(row.budgetTotal.replace(":", "."));
              if (rowIndex >= 0) {
                return (
                  <span
                    className={
                      actualTotal > budgetTotal ? "rowActual red" : "rowActual"
                    }
                  >
                    {row.actualTotal}
                  </span>
                );
              }
            },
          },
          {
            dataField: "",
            formatter: (cell: any, row: any, rowIndex: any) => (
              <Button
                className="btn-details rowDetails"
                onClick={() => this.overBudgetDetails(row, rowIndex, "")}
              >
                Details
              </Button>
            ),
          },
          {
            dataField: "",
            formatter: (cell: any, row: any, rowIndex: any) => (
              <Dropdown className="more-action" alignRight>
                <Dropdown.Toggle
                  className="btn-outline-primary btn btn-primary more"
                  id="dropdown-more"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    eventKey={"Details"}
                    onClick={() => this.handlePositionDetails(row, rowIndex)}
                  >
                    Details
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ),
          },
        ];
        return (
          <div className="rowExpand" ref={this.expendedOverBudgetRef}>
            <BootstrapTable
              keyField="rowNo"
              data={row.positionExpended}
              columns={rowColumns}
              hover
            />
          </div>
        );
      },
      expanded: this.state.tableExpended,
      onExpand: this.handleOnExpand,
      // onlyOneExpanding: true,
    };

    const overBudgetDeptscolumns = [
      {
        dataField: "action",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => {
          if (row.action === "Expended") {
            return (
              <span className="chevronExpended">
                <img src={chevronRight} alt="chevron Right" />
              </span>
            );
          } else {
            return (
              <span className="chevronCollpsed">
                <img src={chevronRight} alt="chevron Right" />
              </span>
            );
          }
        },
      },
      {
        dataField: "departMentName",
        text: "Department",
        headerFormatter: this.headerFormat,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="overBudgetDeptsDepartment">
            <EllipsisWithTooltip placement="bottom">
              <span className="overBudgetDeptsDepartment-name font-weight-bold">
                {row.departMentName}
              </span>
            </EllipsisWithTooltip>
            {row.isAutoPunch === "Yes" && (
              <span className="autoPunch-status">APO</span>
            )}
            {row.isNotPunchOut === "Yes" && (
              <span className="notPunch-status">Open</span>
            )}
          </div>
        ),
      },
      {
        dataField: "bgtDeptTotal",
        text: "Budgeted",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <span className="overBudgetDeptsBudgeted">{row.bgtDeptTotal}</span>
        ),
      },
      {
        dataField: "actualDeptTotal",
        text: "Actual",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <span className="overBudgetDeptsActual">{row.actualDeptTotal}</span>
        ),
      },
      {
        dataField: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <Button
            className="btn-details overBudgetDetails"
            onClick={() => this.overBudgetDetails(row, rowIndex ,"overBudgetDepartment")}
          >
            Details
          </Button>
        ),
      },
      {
        dataField: "hoverOutAction",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => {
          if (row.hoverOutAction !== "selected") {
            return (
              <div className="overBudgetDeptAction">
                <Dropdown
                  className="more-action"
                  alignRight
                  onClick={this.ApproveSetFocus}
                >
                  <Dropdown.Toggle
                    className="btn-outline-primary overBudgetApprove more"
                    id="dropdown-more"
                  >
                    Approve
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <ApproveDepartmentDetails
                      approveDepartmentDetailsContent={
                        approveDepartmentDetailsContent
                      }
                      table="overBudget"
                      ref={this.approveDepartment}
                      approveModalAction={this.approveModalAction.bind(
                        this,
                        row,
                        "singleApprove"
                      )}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            );
          } else {
            return <div className="overBudgetDeptAction"></div>;
          }
        },
      },
      {
        dataField: "flyOutAction",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => {
          const stopPropagation = (e: { stopPropagation: () => any }) =>
            e.stopPropagation();
          if (row.flyOutAction === "") {
            return (
              <Dropdown
                className="more-action"
                alignRight
                onClick={stopPropagation}
              >
                <Dropdown.Toggle
                  className="btn-outline-primary btn btn-primary more"
                  id="dropdown-more"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    eventKey={"Approve"}
                    onClick={() => this.showCommentComponent(row)}
                  >
                    Approve
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            );
          } else if (row.flyOutAction === "rowSeclected") {
            return <div onClick={stopPropagation}></div>;
          } else {
            return (
              <OutsideClickHandler
                onOutsideClick={(e) => {
                  this.onOutsideClick(e, row);
                }}
              >
                <div className="flayoutApproveMenu" onClick={stopPropagation}>
                  <Dropdown className="more-action show" alignRight>
                    <Dropdown.Toggle
                      className="btn-outline-primary btn btn-primary more"
                      id="dropdown-more"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu rootCloseEvent="click" show={true}>
                      <ApproveDepartmentDetails
                        approveDepartmentDetailsContent={
                          approveDepartmentDetailsContent
                        }
                        table="overBudget"
                        ref={this.approveDepartment}
                        approveModalAction={this.approveModalAction.bind(
                          this,
                          row,
                          "singleApprove"
                        )}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </OutsideClickHandler>
            );
          }
        },
      },
    ];

    return (
      <>
        {this.state.loaderOverBudget ? (
          <ReactPageLoader useas={"daysToApproveExpand"} />
        ) : (
          <div className="overBudgetDeptsTable" ref={this.overBudgetDivRef}>
            <BootstrapTable
              keyField="rowNo"
              data={this.state.overBudgetDeptsData}
              columns={overBudgetDeptscolumns}
              hover
              selectRow={overBudgetDeptsselectRow}
              expandRow={expandRow}
              rowClasses={rowClasses}
            />
          </div>
        )}
      </>
    );
  }
}
