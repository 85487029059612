import React from "react";
import { Button, Card } from "react-bootstrap";
import { Redirect } from "react-router-dom";



export class ResetPasswordComplete extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      navigate: false,
    };
  }
  cancel = () => {
    this.setState({ navigate: true });
    if (this.props.page === "timeclock") {
      return this.props.cancelResetPassword();
    }
  };
  render() {
    const { navigate } = this.state;
    if (navigate) {
      return <Redirect to="/login" push={true} />;
    }
    return (
      <div className="reset-password-complete">
        <div className="header-sec">
          <div className="sec-icn">
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="48" height="48" rx="24" fill="#CCFBEF" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M34.193 14.7803L19.873 28.6003L16.073 24.5403C15.373 23.8803 14.273 23.8403 13.473 24.4003C12.693 24.9803 12.473 26.0003 12.953 26.8203L17.453 34.1403C17.893 34.8203 18.653 35.2403 19.513 35.2403C20.333 35.2403 21.113 34.8203 21.553 34.1403C22.273 33.2003 36.013 16.8203 36.013 16.8203C37.813 14.9803 35.633 13.3603 34.193 14.7603V14.7803Z" fill="#15B79E" />
            </svg>
          </div>
          <div className="sec-ttl"> {this.props.type == "welcomeEmail" ? "User Setup Complete!" : "Password Created Successfully!"}</div>
          <div className="sec-desc fnt-14">
            {this.props.type == "welcomeEmail" &&(
              <div className="txt-center">
                <div>Your password has been successfully setup.</div>
                <div>Use it to login into Inn-Flow now.</div>
              </div>
            )}
            {this.props.type != "welcomeEmail" &&(
              <div className="txt-center">
                <div>Your Inn-Flow password has been created!</div>
                <div>You can now login with your new password.</div>
              </div>
            )}
          </div>
        </div>
        <div className="frm-section">
          <div className="frm-action">
            <Button variant="primary" type="button" onClick={() => this.cancel()}>
              {this.props.page === "timeclock" ? "Return to Web Time Clock" : "Return to Login"}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
