import axios from "axios";
import Instense from "./Axios-config";
import { API_ROOT } from "./Api-config";

export interface IPageSubPermissionRole {
  permissionID: number;
  Module: string;
  RoleID: number;
  TenantID: number;
  Type: string;
}

export interface ISaveEmployeeForcePunch {
  Hotelid: number;
  TenantID: number;
  ScheduleNo: number;
  UserName: string;
  DateTime24Hrs: string;
  ForceRemarks: string;
  EnterpriseID:string;
}

export class RoleSubPermissionSetting {
  public static GetPageSubPermissionDetails = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let rolId = storage === null ? 0 : (storage.rolId as any);

    request.RoleID = Number(rolId);
    request.TenantID = tenantID;

    const url = `/RolePermission/GetPageSubPermissionDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  //Web time clock Permission (Pls don't change anything in this)
  public static GetPageSubPermissionWebClockDetails = async (
    request: any
  ): Promise<any | null> => {
    const url = `/RolePermission/GetPageSubPermissionDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static GetMenuPermissionDetails = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let rolId = storage === null ? 0 : (storage.rolId as any);

    request.RoleID = Number(rolId);
    request.TenantID = tenantID;

    const url = `/RolePermission/GetPageSubPermissionDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static SaveEmployeeForcePunchIn = async (
    request: ISaveEmployeeForcePunch
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    request.EnterpriseID = storage === null ? 0 : storage.tenantID;
    // const url = `/LaborSchedule/SaveEmployeeForcePunchIn`;
    //const url = `/LaborSchedule/SavePunchInData`;//
    // const url = `/LaborSchedule/SavePunchDataWithPassword`;
    const url = `/LaborSchedule/SavePunchDataWithPasswordNew`;
    return Instense.post(url, request).then((response) => {
      let result = response.data as any;
      return result;
    });
  };

  public static SaveEmployeeForcePunchOut = async (
    request: ISaveEmployeeForcePunch
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    request.EnterpriseID = storage === null ? 0 : storage.tenantID;
    // const url = `/LaborSchedule/SaveEmployeeForcePunchOut`;
  //  const url = `/LaborSchedule/SavePunchOutData`;
  // const url = `/LaborSchedule/SavePunchDataWithPassword`;
  const url = `/LaborSchedule/SavePunchDataWithPasswordNew`;
    return Instense.post(url, request).then((response) => {
      let result = response.data as any;
      return result;
    });
  };

  public static GetLaborManagerTimeClockOverView = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    request.LoginUser = storage.user_UniqueID;
    //${API_ROOT.backendHost}
    const url = `/LaborSchedule/GetLaborManagerTimeClockOverView`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as any;
      return result;
    });
  };

  public static CheckForecast = async (request: any): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantId = tenantID;
    const url = `/LaborActual/CheckForecast`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };
}
