import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Dropdown } from "react-bootstrap";
import { LaborDayApprove } from "../../../../Common/Services/LaborDayApprove";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import profile from "../../../../Common/Assets/Images/profile.png";
import { resolve, reject } from "q";
import { Utils } from "../../../../Common/Utilis"
export class HousekeepingApprovedList extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            tableData: [],
            tblComment: [],
        }
    }
    componentDidMount() {
        this.bindHouseKeepingComments();
        this.bindApprovedList();
    }

    rowEvents: any = {
        onClick: (e, row, rowIndex) => {
            console.log(e.target.tagName);
           if(e.target.tagName?.toLowerCase() ==='button' ||e.target.tagName?.toLowerCase() === 'path'||e.target.tagName?.toLowerCase() === 'svg'){
            return;
           }
            this.getChildDetails(row);

        }
    }

    getChildDetails = (rowdata: any) => {
        this.props.getChildDetails(rowdata);
    };

    bindApprovedList() {
        this.setState({ loaderTrue: true, tableData: [] });
        let request = {} as any;
        request.hotelID = this.props.hotelID;
        request.selectedDate = this.props.date;
        request.type = "Housekeeping";

        LaborDayApprove.houseKeepingApprovedUser(request)
            .then(async (result: any[] | null) => {
                if (result != null) {
                    if (result.length > 0) {
                        this.setState({ tableData: result, loaderTrue: false });
                    }
                    else {
                        this.setState({ loaderTrue: false });
                    }
                }
                else {
                    this.setState({ loaderTrue: false });
                }
                resolve();
            })
            .catch((error) => {
                reject();
                this.setState({ loaderTrue: false });
            });
    }

    bindHouseKeepingComments() {
        //this.setState({ loaderTrue: true, tableData: [] });
        let request = {} as any;
        request.hotelID = this.props.hotelID;
        request.selectedDate = this.props.date;
        request.type = "Housekeeping";

        LaborDayApprove.bindHouseKeepingComments(request)
            .then(async (result: any[] | null) => {
                if (result != null) {
                    if (result.length > 0) {

                        let departmentpositionList: any[] = [];
                        departmentpositionList = Object.assign(
                            [],
                            JSON.parse(JSON.stringify(this.state.tableData))
                        );

                        this.setState({ tableData: [] }, () => {
                            // debugger;
                            this.setState({ tblComment: result, tableData: departmentpositionList, loaderTrue: false });
                        });




                    }
                    else {
                        //this.setState({ loaderTrue: false });
                    }
                }
                else {
                    // this.setState({ loaderTrue: false });
                }
                resolve();
            })
            .catch((error) => {
                reject();
                this.setState({ loaderTrue: false });
            });
    }


    render() {
        const compareShift = (topVal: any, bottomVal: any) => {
            let topMM = 0;
            let bottomMM = 0;
            if (topVal.includes(":")) {
                const dataArray = topVal.split(":");
                let HH = Number(dataArray[0]);
                let MM = Number(dataArray[1]);
                if (dataArray[0].includes("-") || dataArray[1].includes("-")) {
                    HH = HH < 0 ? (HH * -1) : HH
                    MM = MM < 0 ? (MM * -1) : MM
                    topMM = ((HH * 60 + MM) * -1);
                } else {
                    topMM = (HH * 60 + MM)
                }
            }

            if (bottomVal.includes(":")) {
                const dataArray = bottomVal.split(":");
                let HH = Number(dataArray[0]);
                let MM = Number(dataArray[1]);
                if (dataArray[0].includes("-") || dataArray[1].includes("-")) {
                    HH = HH < 0 ? (HH * -1) : HH
                    MM = MM < 0 ? (MM * -1) : MM
                    bottomMM = ((HH * 60 + MM) * -1);
                } else {
                    bottomMM = (HH * 60 + MM)
                }
            }
            if (topMM > bottomMM) {
                return ("main-ttl color-red red");
            } else {
                return ("main-ttl");
            }
        }

        const columns = [
            {
                dataField: "",
                text: "",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="d-flex">
                            <div className="profile-img">
                                <img
                                    src={profile}
                                    className="default"
                                    width="40"
                                    height="40"
                                    alt={row.userName}
                                />
                            </div>
                            <div>
                                <div className="main-ttl">
                                    <EllipsisWithTooltip placement="bottom">
                                        {row.userName}
                                    </EllipsisWithTooltip>
                                </div>
                                <div className="sub-ttl">
                                    <EllipsisWithTooltip placement="bottom">
                                        {"Housekeeper"}
                                    </EllipsisWithTooltip>
                                </div>
                            </div>
                            <div className="hide-area"></div>
                        </div>
                    );
                },
            },
            {
                dataField: "occupiedRoom",
                text: "",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="main-ttl normal">{row.occupiedRoom}</div>
                    );
                },
            },
            {
                dataField: "roomDenied",
                text: "",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="main-ttl normal">{row.roomDenied}</div>
                    );
                },
            },
            {
                dataField: "roomClean",
                text: "",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="main-ttl normal">{row.roomClean}</div>
                    );
                },
            },
            {
                dataField: "",
                text: "",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="">
                            <div className={compareShift(row.mpor, row.mpor1) + " normal"}>
                                <EllipsisWithTooltip placement="bottom">
                                    {row.mpor}
                                </EllipsisWithTooltip>
                            </div>
                            <div className="sub-ttl-sml">{row.mpor1}</div>
                        </div>
                    );
                },
            },
            {
                dataField: "",
                text: "",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="">
                            <div className={compareShift(row.total, row.total1) + " normal"}>
                                <EllipsisWithTooltip placement="bottom">
                                    {row.total}
                                </EllipsisWithTooltip>
                            </div>
                            <div className="sub-ttl-sml">{row.total1}</div>
                        </div>
                    );
                },
            },
            {
                dataField: "",
                text: "",
                headerClasses: 'notes-sec',
                classes: 'notes-sec',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    const stopPropagation = (e) =>
                        e.preventDefault();      
                    return (
                        <div className="d-flex">
                            {(row.notes.length > 0 &&
                                <Dropdown className="more-action notes-btn" alignRight 
                                onClick={stopPropagation}
                                >
                                    <Dropdown.Toggle
                                        className="btn-outline-primary  more"
                                        id="dropdown-more"   
                                    >
                                        <div className="d-flex">
                                            <svg
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M13.5556 2.4H11.3889V1H9.94444V2.4H7.05556V1H5.61111V2.4H3.44444C2.64783 2.4 2 3.0279 2 3.8V13.6C2 14.3721 2.64783 15 3.44444 15H13.5556C14.3522 15 15 14.3721 15 13.6V3.8C15 3.0279 14.3522 2.4 13.5556 2.4ZM3.44444 13.6V4.5H5.61111H7.05556H9.94444H11.3889H13.5556V3.8L13.557 13.6H3.44444Z" />
                                                <path d="M4.6665 6H11.3332V7.33333H4.6665V6ZM4.6665 8.66667H7.99984V10H4.6665V8.66667Z" />
                                            </svg>
                                            {/* <span>5</span> */}
                                        </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {/* <div className="notes-sec">                    
                                    <div className="notes-list">                                   
                                        <div className="comment-list">
                                        <div className="d-flex profile-details">
                                            <div className="profile-img">
                                                <img
                                                    src={row.noteimageUrl ==="" ?  profile : row.noteimageUrl}
                                                    className="default"
                                                    width="40"
                                                    height="40"                                                                                            
                                                />
                                            </div>
                                            <div>
                                                <div className="main-ttl">{row.noteUser}</div>
                                                <div className="sub-ttl">{Utils.getLocalDateTimeFromUtc(row.noteDate)}</div>
                                            </div>
                                        </div>
                                            <ul>
                                                <li>
                                                    <div className="d-flex">
                                                        <div className="detail-sec">
                                                            <div className="comments">
                                                            {row.notes}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>                                  
                                    </div>
                                </div> */}

                                        {this.state.tblComment.map((item: any, idx: any) => {

                                            //   debugger;
                                            let hasMultipleComment = Number(item.userUniqueno) === Number(row.user_UniqueID)
                                            return (
                                                <>
                                                    {hasMultipleComment && (
                                                        <div className="notes-sec">
                                                            <div className="notes-list">
                                                                <div className="comment-list">
                                                                    <div className="d-flex profile-details">
                                                                        <div className="profile-img">
                                                                            <img
                                                                                src={item.imageUrl === "" ? profile : item.imageUrl}
                                                                                className="default testClass"
                                                                                width="40"
                                                                                height="40"
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <div className="main-ttl">{item.username}</div>
                                                                            <div className="sub-ttl">{item.createdDate}</div>
                                                                            {/* <div className="sub-ttl">{item.Date}</div> */}
                                                                        </div>
                                                                    </div>
                                                                    <ul>
                                                                        <li>
                                                                            <div className="d-flex">
                                                                                <div className="detail-sec">
                                                                                    <div className="comments">
                                                                                        {item.notes}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </>
                                            )
                                        })}

                                    </Dropdown.Menu>
                                </Dropdown>
                            )}
                        </div>
                    );
                },
            },
            {
                dataField: "",
                text: "",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <></>
                    );
                },
            },
        ]
        return (
            <div className="approved-list">

                {(this.state.loaderTrue &&
                    <div className="loader-responsive">
                        <div className="performanceList housekeeping-standalone approval">
                            <div className="tableList">
                                <ul>
                                    <li className="d-flex">
                                        <div className="list d-flex align-self-center profile">
                                            <div className="profile loadingAnimation"></div>
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                        <div className="list d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                        <div className="list d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                        <div className="list d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                        <div className="list d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                        <div className="list d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                        <div className="list d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                        <div className="list comments d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="d-flex">
                                        <div className="list d-flex align-self-center profile">
                                            <div className="profile loadingAnimation"></div>
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                        <div className="list d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                        <div className="list d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                        <div className="list d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                        <div className="list d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                        <div className="list d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                        <div className="list d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                        <div className="list comments d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>)}
                {(!this.state.loaderTrue &&
                    <BootstrapTable keyField='id'
                        data={this.state.tableData}
                        rowEvents={this.rowEvents}
                        columns={columns} />
                )}
            </div>
        )
    }
}