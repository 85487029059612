import React from "react";
import { Button, Card, Form } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import OtpInput from "react-optinput";
import "react-optinput/bundle.css";
import PageLoader from "../Common/Components/PageLoader";
import { ForgotPassword as PwdService } from "../Common/Services/ForgotPasswordService";
import { resolve, reject } from "q";
import { toast } from "react-toastify";
export class EnterCode extends React.Component<any, any> {
  private continueBtnRef:any;
  constructor(props: any) {
    super(props);
    this.continueBtnRef = React.createRef();
    this.state = {
      errorMessage: "",
      mode: 3,
      userName: this.props.changeModule[1].userName,
      //passCode: this.props.changeModule[1].passCode,
      resetMode: this.props.changeModule[1].resetMode,
      logAuditId : this.props.changeModule[1].logAuditId,
      otp: 0,
      navigate: false,
      loaderTrue: false,
      reSendOtp: true,
    };
    this.handleContinue = this.handleContinue.bind(this);
    this.resendPasscode = this.resendPasscode.bind(this);
  }

  handleContinue(event: any) {
    event.preventDefault();
    let otp = parseInt(this.state.otp);

    let errorMessage = "";

    if (this.state.otp === 0 || this.state.otp === "") {
      errorMessage = "Please enter passcode";
      this.setState({ errorMessage: errorMessage });
    } else {
      this.setState({ loaderTrue: true });
      this.continueBtnRef.current.disabled = true;
      PwdService.ValidatePassCode(this.state.userName, otp)
        .then(async (passCodeStatus: string | null) => {
          if (passCodeStatus !== "") {
            if (passCodeStatus === "InvalidPassCode") {
              this.continueBtnRef.current.disabled = false;
              errorMessage = "Incorrect passcode entered. Please try again.";
            } else if (passCodeStatus === "PasscodeExpired") {
              this.continueBtnRef.current.disabled = false;
              errorMessage =
                "Passcode expired. Please click 'Resend Code' to send a new passcode.";
            } else if (passCodeStatus === "ValidPassCode") {
              this.continueBtnRef.current.disabled = false;
              this.props.changeModule[0](this.state);
            } else if (passCodeStatus === "InvalidUser") {
              this.continueBtnRef.current.disabled = false;
              errorMessage = "Invalid User. Please try again.";
            }
            else if (passCodeStatus === "PasscodeExpiredDueToRetry") {
              errorMessage = "Max retry exceeded, Please generate new passcode.";
            
              setTimeout(()=>{
                window.location.href = "/login"
              },2000)
              
            }
          }
          else{
            this.continueBtnRef.current.disabled = false;
          }
          this.setState({ errorMessage: errorMessage });
          
          this.setState({ loaderTrue: false });
          resolve();
        })
        .catch((error) => {
          this.setState({ loaderTrue: false });
          reject();
        });
    }
  }

  resendPasscode(event: any) {
    
    if (this.state.reSendOtp) {
      this.setState({ loaderTrue: true });
      PwdService.generatePassCode(this.state.userName, this.state.resetMode,1,this.props.platform)
        .then(async (response: any | null) => {
          if (response.status === "Success") {
            this.setState({
             // passCode: response.passcode,
             passCode: this.state.otp,
              loaderTrue: false,
              reSendOtp: false,
            });
            toast.success("New Pass code has been resend", {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "timeClockIndex",
            });
          } else {
            this.setState({
              passCode: "0",
              loaderTrue: false,
              reSendOtp: false,
            });
          }
          resolve();
        })
        .catch((error) => {
          this.setState({ loaderTrue: false, reSendOtp: true });
          reject();
        });
    }

    setTimeout(() => {
      this.setState({ reSendOtp: true });
    }, 60000);
  }

  onInputChange = (value: number) => {
    if (isNaN(value)) {
      let errorMessage = "Invalid input, accept only numeric value.";
      this.setState({ errorMessage: errorMessage });
    } else {
      //this.setState({ otp: value });
      this.setState({ otp: value,passCode:value });
      this.setState({ errorMessage: "" });
    }
  };
  cancel = () => {
    this.setState({ navigate: true });
    if (this.props.page === "timeclock") {
      return this.props.cancelResetPassword();
    }
  };
  render() {
    const { navigate, otp } = this.state;
    var selectOtpMethod = "";
    const isEnabled = otp.length === 6;
    if (this.state.resetMode === "sms") {
      selectOtpMethod = "mobile phone";
    } else {
      selectOtpMethod = "email";
    }
    if (navigate && this.props.page !== "timeclock") {
      return <Redirect to={this.state.redirect} />;
    }
    var loaderTrue = this.state.loaderTrue;
    var loadShow;
    if (loaderTrue) {
      loadShow = <PageLoader />;
    }
    
    return (
      <div className="enter-code">
        {loadShow}
        <div className="header-sec under-line">
          <div className="sec-ttl">Enter Code</div>
          <div className="sec-desc">
            <p>
              We've sent a code to your {selectOtpMethod}. Please enter it below
              to continue. The code will expire in 20 minutes.
            </p>
            <p>
              If you didn't receive the code within a few minutes, you can have
              us{" "}
              <span
                className={this.state.reSendOtp ? "enableOtp" : "disabledOtp"}
                onClick={this.resendPasscode}
              >
                resend the code
              </span>
              , or you may contact your manager for further help.
            </p>
          </div>
        </div>
        <Form
          className="frm-section was-validated"
          name="forgotForm"
          onSubmit={this.handleContinue}
        >
          <div className="form-lbl">Code</div>
          <Form.Group controlId="formBasicEmail">
            <OtpInput
              codeLength={6}
              onInputChange={(value: any) => this.onInputChange(value)}
            />
            <span className="invalid-feedback">
              {this.state.errorMessage}
            </span>
          </Form.Group>
          <div className="frm-action">
          <Button variant="primary" type="submit" disabled={!isEnabled} ref={this.continueBtnRef}>
              Continue
          </Button>
            <Button variant="primary" type="button" className="white-bg" onClick={() => this.cancel()}>
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}
