import React, { useEffect, useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import moment from "moment";
import Spinner from 'react-bootstrap/Spinner';

import "../../../Common/Assets/Styles/Modules/reports.scss";

const QueuedReports = (props) => {
    const [allReportsData, setAllReportsData] = useState(props.allReportsData || []);

    useEffect(() => {
        setAllReportsData(props.allReportsData || []);
    }, [props.allReportsData]);

    function reportFormatter(cell: any, row: any, rowIndex: any) {
        const maxLength = 45; // Maximum length to show in the span

        const truncatedText = row.reportName.length > maxLength ?
            row.reportName.substring(0, maxLength) + '...' :
            row.reportName;

        return (
            <OverlayTrigger
                placement="bottom"
                delay={{ show: 100, hide: 200 }}
                overlay={
                    <Tooltip id={"ddd"}>
                        {row.reportName}
                    </Tooltip>
                }>
                <span>{truncatedText}</span>
            </OverlayTrigger>
        );
    }

    function versionFormatter(cell: any, row: any, rowIndex: any) {
        const maxLength = 45; // Maximum length to show in the span

        const truncatedText = row.versionName?.length > maxLength ?
            row.versionName?.substring(0, maxLength) + '...' :
            row.versionName;

        return (
            <OverlayTrigger
                placement="bottom"
                delay={{ show: 100, hide: 200 }}
                overlay={
                    <Tooltip id={"ddd"}>
                        {row.versionName}
                    </Tooltip>
                }>
                <span>{truncatedText}</span>
            </OverlayTrigger>
        );
    }

    function dateFormatter(cell: any, row: any, rowIndex: any) {
        return (
            <div>{moment(row.runDate).format('MM/DD/YYYY')}</div>
        );
    }

    function ehidFormatter(cell: any, row: any, rowIndex: any) {
        const hids = row.ehid.toString()?.split(",");
        if (hids?.length > 2) {
            hids?.shift();
            const hidsToolTipData = hids?.join(",  ");
            return (
                <div className="ag-react-container">
                    <div className="ehid-tag rm-bg d-flex align-items-center">
                        <div className="hids">
                            {row.ehid.toString()?.split(",")[0]}
                        </div>
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Tooltip className="data-ehids" id="hidTool">
                                    {hidsToolTipData}
                                </Tooltip>
                            }
                        >
                            <button type="button" className="showalltags d-flex">
                                <div className="more-hids" style={{ paddingRight: "2px" }}>
                                    <span>
                                        +{row.ehid.toString()?.split(",").length - 1}{" "}
                                        More
                                    </span>
                                </div>
                            </button>
                        </OverlayTrigger>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="ag-react-container">
                    <div className="ehid-tag rm-bg d-flex align-items-center">
                        <span className="hids">{hids?.join(",  ")}</span>
                    </div>
                </div>
            );
        }
    }

    function productFormatter(cell: any, row: any, rowIndex: any) {
        const modules = row.module?.split(",");
        return (
            <div className="d-flex">
                {modules.map((product, index) => (
                    <div key={product} className="badges">
                        {product}
                    </div>
                ))}
            </div>
        );
    }

    function statusFormatter(cell: any, row: any, rowIndex: any) {
        const statusClass = {
            "In Progress": "badge color-blue d-flex align-items-center",
            "Ready to View": "badge color-green d-flex align-items-center",
            default: "badge color-gray d-flex align-items-center"
        };

        return (
            <div className="d-flex">
                <div className={statusClass[row.reportStatus] || statusClass.default}>
                    <div>{row.reportStatus}</div>
                    {row.reportStatus === "In Progress" && <Spinner animation="border" />}
                </div>
            </div>
        );
    }

    function expiresFormatter(cell: any, row: any, rowIndex: any) {
        return (
            <EllipsisWithTooltip placement="bottom">
                {row.expireIn}
            </EllipsisWithTooltip>
        );
    }

    function actionFormatter(cell: any, row: any, rowIndex: any) {
        return (
            <div className="d-flex align-items-center justify-content-end">
                <div className="action-btn">
                    <Button className={row.reportStatus === "In Progress" ? "disabled" : "btn-outline-primary review-btn"} >
                        View
                    </Button>
                </div>
            </div>
        )
    }

    function rowClasses(row, rowIndex) {
        let classes = "";

        if (row.reportStatus === "Viewed") {
            classes = 'disabled';
        }

        return classes;
    };

    const columns = [
        {
            dataField: "reportName",
            text: "Report Name",
            headerClasses: 'reportName-cell show-45',
            classes: 'reportName-cell show-45',
            formatter: reportFormatter
        }, {
            dataField: "versionName",
            text: "Version Name",
            headerClasses: 'versionName-cell show-45',
            classes: 'versionName-cell show-45',
            formatter: versionFormatter
        }, {
            dataField: "runDate",
            text: "Run Date",
            headerClasses: 'runDate-cell',
            classes: 'runDate-cell',
            formatter: dateFormatter
        }, {
            dataField: "ehid",
            text: "EHID",
            headerClasses: 'ehid-cell',
            classes: 'ehid-cell',
            formatter: ehidFormatter
        }, {
            dataField: "module",
            text: "Products",
            headerClasses: 'products-cell',
            classes: 'products-cell',
            formatter: productFormatter
        }, {
            dataField: "reportStatus",
            text: "Status",
            headerClasses: 'status-cell',
            classes: 'status-cell',
            formatter: statusFormatter
        }, {
            dataField: "expireIn",
            text: "Expires",
            headerClasses: 'expires-cell',
            classes: 'expires-cell',
            formatter: expiresFormatter
        }, {
            dataField: '',
            text: '',
            headerClasses: 'action-cell',
            classes: 'action-cell',
            formatter: actionFormatter
        }
    ];

    return (
        <div className="ScrollArea queue-tbl">
            <ToolkitProvider
                keyField="id"
                remote
                data={allReportsData}
                columns={columns}
                search
            >
                {(toolkitprops) => [
                    <BootstrapTable {...toolkitprops.baseProps}
                        rowClasses={rowClasses}
                        noDataIndication={"No Reports Found."}
                    />,
                ]}
            </ToolkitProvider>
        </div>
    );
};

export default QueuedReports;