import React from "react";
import { ToastContainer, toast } from "react-toastify";
import BootstrapTable from "react-bootstrap-table-next";
import { BankAccountSlideout } from "./BankAccountSlideout";
import { CreditCardAccountSlideout } from "./CreditCardAccountSlideout";
import "react-toastify/dist/ReactToastify.css";
import {
  Container,
  Dropdown,
  Form,
  OverlayTrigger,
  Tooltip,
  Button,
  Spinner,
} from "react-bootstrap";
import { FiChevronDown } from "react-icons/fi";
import { Hotel as hotel } from "../../Common/Services/Hotel";
import { IHotelResponseDto } from "../../Common/Contracts/IHotel";
import { resolve, reject } from "q";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import TableSearch from "../../Common/Components/TableSearch";
import { accountService } from "../../Common/Services/account";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import _ from "lodash";
import { ReactPageLoader } from "../../Common/Components/ReactPageLoader";
import { LaborPerformance as laborPerformance } from "../../Common/Services/LaborPerfomance";
import "../Register/slideout.scss";
import { Register as registersevice } from "../../Common/Services/Register";
import { Utils } from "../../Common/Utilis";
import * as XLSX from 'xlsx';
let localStorageUser: any;

let exportDataSet: any =
{
  columns: [
    "Account Number",
    "Bank",
    "COA",
    "Nickname",
    "EHID",
    "Account Type",
    "Balance",
    "Status",
  ],
  data: []
}
  ;

const colCounter: number = 5;
let currentLen: number = -1;

export class account extends React.Component<any, any> {
  private tableSearch: any;
  private pageName = "Accounts";
  constructor(props: any) {
    super(props);
    this.tableSearch = React.createRef();
    this.state = {
      isLimitedsubs: "No",
      isShowSlideout: false,
      isShowCreditCardSlideout: false,
      hidList: [],
      hidFilterList: [],
      hidValue: "Select",
      hotelName: "Select",
      accountsTableData: [],
      accountsExportTableData: [],
      mainAccountsTableData: [],
      tableDataFilter: [],
      search: "",
      accountTypeList: [],
      accountTypeName: 0,
      selectedAcType: "",
      isLoadComplete: false,
      showInactiveAcc: false,
      isRefresh: false,
      defaultSelectOPtion: "All",
      showLoader: true,
      sortingOrderList: ["Bank", "EHID", "Nickname", "Type"],
      sortingOrder: "",
      tableClass: "accounts-tableSmall",
      isAddAccountDisbaled: true,
      isAllViewBankGLBal: false,
      isBalLoading: true,
    };
  }

  componentDidMount() {
    this.advancedAccounting();
    localStorageUser = JSON.parse(localStorage.getItem("storage")!);
    const hotelid = JSON.parse(localStorage.getItem("hotelid")!);
    const hotelName = JSON.parse(localStorage.getItem("hotelName")!);
    this.getDefaultView();
    hotel
      .getUserHotelAccess("Accounting")
      .then(async (result: IHotelResponseDto[] | null) => {
        if (result != null && result.length > 0) {
          const allAppendedHotel = {
            ehidName: "All",
            hotelID: "All",
            hotelName: "All",
            hotelType: "0",
            lettercode: "All",
            tenantID: localStorageUser.tenantID,
          };
          this.setState({ hidList: result }, () => {
            if (result.length > 1) {
              this.state.hidList.unshift(allAppendedHotel);
            }
          });
          this.setState({ hidFilterList: result });
          if (hotelName === null) {
            if (result.length > 1) {
              this.setState(
                {
                  hotelName: this.state.hidList[0].lettercode,
                  hidValue: this.state.hidList[0].hotelID,
                },
                () => {
                  let request = {} as any;
                  request.HID = this.state.hidList[0].hotelID;
                  this.getAccountsData(request);
                }
              );
            }
          } else {
            this.setState({ hotelName: hotelName });
          }
          if (hotelid === null) {
            if (result.length === 1) {
              this.setState(
                {
                  hidValue: this.state.hidList[0].hotelID,
                  hotelName: this.state.hidList[0].lettercode,
                },
                () => {
                  let request = {} as any;
                  request.HID = this.state.hidList[0].hotelID;
                  this.getAccountsData(request);
                  this.getSignatureImgPerm();
                }
              );
            }
          } else {
            this.setState({ hidValue: hotelid }, () => {
              let request = {} as any;
              request.HID = this.state.hidList[0].hotelID;
              this.getAccountsData(request);
            });
          }
        }
        resolve();
      })
      .catch((error) => {
        Utils.toastError(`Server Error: ${error}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "AccountsHome"
        });
        reject();
      });

  }

  advancedAccounting = () => {
    let isBankSyncFeatureFlag: boolean = false;
    accountService.AdvancedAccounting()
      .then(async (result: any | null) => {
        if (result !== null) {
          isBankSyncFeatureFlag = result.some((o: any) => o?.name?.toString().toLowerCase() === "banksync");
        }
        if (isBankSyncFeatureFlag) {
          window.location.href = "/unauthorize-access";
        }
      });
  }

  getDefaultView() {
    this.setState({ pageloader: true });
    laborPerformance
      .getDefaultViewProperties(this.pageName)
      .then(async (response: any | null) => {
        if (response?.length > 0) {
          const result = response;
          const sortingOrder = result.find(
            (item) => item.fieldName === "sort"
          )?.fieldValue;
          const showInactiveAcc = result.find(
            (item) => item.fieldName === "toggle"
          )?.fieldValue;
          this.setState({
            sortingOrder,
            showInactiveAcc: showInactiveAcc === "true" ? true : false,
            tableClass: showInactiveAcc === "true" ? "accounts-tableWide" : "accounts-tableSmall",
          });
        } else {
          this.setDefaultFilterValues();
        }
      })
      .catch((error) => {
        Utils.toastError(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "AccountsHome"
        });
        this.setDefaultFilterValues();
      });
  }

  getSignatureImgPerm() {
    let request: any = {};
    request.hotelID = this.state.hidValue;
    accountService.DigitalSignPermission(request)
      .then(async (result: any | null) => {
        if (result !== null) {
          if (result[0].isEditOperating === "Yes" || result[0].isEditMisc === "Yes" || result[0].isEditPayroll === "Yes") {
            this.setState({ isAddAccountDisbaled: false, isLimitedsubs: result[0].isLimitedSubscription });
          } else {
            this.setState({ isAddAccountDisbaled: true, isLimitedsubs: result[0].isLimitedSubscription });
          }
        }
      })
  }
  setDefaultFilterValues = (): void => {
    this.setState({
      sortingOrder: "Bank",
      showInactiveAcc: false,
      tableClass: "accounts-tableSmall",
    });
  };

  searchClear = () => {
    this.setState({ search: "" });
    this.setState({ hidList: this.state.hidFilterList });
  };

  searchItems = (e: any) => {
    const { hidFilterList } = this.state;
    let result = e.target.value;
    this.setState({ search: result });
    if (result !== "") {
      this.setState({
        hidList: hidFilterList.filter((hName: any) =>
          hName.hotelName.toLowerCase().includes(result.toLowerCase().trim())
        ),
      });
    } else {
      this.setState({ hidList: this.state.hidFilterList });
    }
  };

  hotelChange = (hotelDetails: any) => {
    if (sessionStorage.getItem("hotelSelected"))
      sessionStorage.removeItem("hotelSelected");
    sessionStorage.setItem("hotelSelected", JSON.stringify(hotelDetails));

    if (localStorage.getItem("hotelSelected"))
      localStorage.removeItem("hotelSelected");
    localStorage.setItem("hotelSelected", JSON.stringify(hotelDetails));

    this.setState(
      {
        hotelName: hotelDetails.lettercode,
        hidValue: hotelDetails.hotelID,
        selectedAcType: "",
        accountTypeName: 0,
        accountTypeList: [],
        mainAccountsTableData: [],
        accountsTableData: [],
        accountsExportTableData: [],
        isRefresh: true,
        showLoader: true,
        defaultSelectOPtion: "All",
        isBalLoading: true
      },
      () => {
        let request = {} as any;
        request.HID = hotelDetails.hotelID;
        currentLen = -1;
        this.CleanSearch();
        this.getAccountsData(request);
        this.getSignatureImgPerm();
      }
    );
  };

  handleAccountError = (message: any) => {
    this.setState({ accountsTableData: [], accountsExportTableData: [] }, () => {
      setTimeout(() => {
        this.setState({ accountsTableData: [], accountsExportTableData: [], showLoader: false }, () => {
          currentLen = 0;
          this.nextList([]);
        });
      }, 1500);
      Utils.toastError(message, { position: toast.POSITION.BOTTOM_RIGHT, containerId: "AccountsHome" });
    });
  }

  sortOrderAcc = (data: any) => {
    let item = this.state.sortingOrder;
    if (item === "Bank") {
      data = _.sortBy(data, ["bNameSort", "dsort"]);
    } else if (item === "EHID") {
      data = _.sortBy(data, ["letterCodeSort", "dsort"]);
    } else if (item === "Nickname") {
      data = _.sortBy(data, ["displayNameSort", "dsort"]);
    } else if (item === "Type") {
      data = _.sortBy(data, "dsort");
    }
    return data;
  };
  getAccountsData = (request: any) => {
    this.getAccountBal([], []);
    accountService.GetAccountsData(request)
      .then(async (result: any) => {
        if (result.message === "Success") {
          if (result.result.length > 0) {
            let data = result.result;
            data.forEach(item => {
              item["bNameSort"] = item.bName.toString().toLowerCase().trim();
              item["letterCodeSort"] = item.letterCode.toString().toLowerCase().trim();
              item["displayNameSort"] = item.displayName.toString().toLowerCase().trim();
            })
            data = this.sortOrderAcc(data);
            let updatedList: any = [];
            data.forEach((item: any, id: any) => {
              if (item.acctType === "Operating") {
                item.dsort = "a";
              } else if (item.acctType === "Payroll") {
                item.dsort = "b";
              } else if (item.acctType === "Miscellaneous") {
                item.dsort = "c";
              } else if (item.acctType === "Credit Card") {
                item.dsort = "d";
              } else {
                item.dsort = "";
              }
              updatedList.push(item);
            });
            this.setState({ accountTypeList: [], mainAccountsTableData: updatedList });
            const isAllGLBalFalse = updatedList.filter((item) => item.isViewBankGLBalances === false);
            if (isAllGLBalFalse.length === updatedList.length) {
              this.setState({ isAllViewBankGLBal: true });
            }
            if (!this.state.showInactiveAcc) {
              let filterData = updatedList.filter((items) => items.status.toString().toLowerCase() === "active");
              filterData.map((item: any) => item["balanceLoader"] = true);
              this.setState({ accountsTableData: [], accountsExportTableData: [] }, () => {
                setTimeout(() => {
                  this.setState({ accountsTableData: filterData, accountsExportTableData: filterData, showLoader: false }, () => {
                    currentLen = 0;
                    this.nextList(updatedList);
                  });
                }, 1500);
              });
            } else {
              updatedList.map((item: any) => item["balanceLoader"] = true);
              this.setState({ accountsTableData: [], accountsExportTableData: [] }, () => {
                setTimeout(() => {
                  this.setState({ accountsTableData: updatedList, accountsExportTableData: updatedList, showLoader: false }, () => {
                    currentLen = 0;
                    this.nextList(updatedList);
                  });
                }, 1500);
              });
            }
            this.setState({
              tableDataFilter: updatedList.filter((items) => items.status.toString().toLowerCase() === "active"),
              isLoadComplete: true,
            });
            let allType: any = { id: -1, name: "All", sort: "a" };
            this.state.accountTypeList.push(allType);
            let newdata: any = _.uniqBy(updatedList, "acctType");
            let { accountTypeList } = this.state;
            newdata.forEach((item: any, id: any) => {
              let data: any = {};
              if (item.acctType === "Operating") {
                data = { id: id, name: item.acctType, sort: "b" }
              } else if (item.acctType === "Payroll") {
                data = { id: id, name: item.acctType, sort: "c" }
              } else if (item.acctType === "Miscellaneous") {
                data = { id: id, name: item.acctType, sort: "d" }
              } else if (item.acctType === "Credit Card") {
                data = { id: id, name: item.acctType, sort: "e" }
              }
              accountTypeList.push(data);
            });
            accountTypeList = _.sortBy(accountTypeList, "sort");
            this.setState({ accountTypeList });
            this.sortChange(this.state.sortingOrder);
          } else {
            this.handleAccountError("No data found.");
          }
        } else {
          this.handleAccountError(`Server Error: ${result.message}`);
        }
        resolve();
      })
      .catch((error: any) => {
        this.handleAccountError(`Server Error: ${error}`)
        reject();
      });
  };

  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      this.GetDefaultHotelByAccount(row.oprid, row.acctType);
    },
  };

  GetDefaultHotelByAccount = (oprId, acctype) => {
    let defaultrequest: any = {};
    defaultrequest.OprID = oprId;
    defaultrequest.AcctType = acctype === 'Credit Card' ? 2 : 1;
    registersevice.GetDefaultHotelByAccount(defaultrequest)
      .then(async (result: any[] | null) => {
        if (result !== null) {
          let hotelArray: any = {};
          hotelArray.lettercode = result[0].lettercode;
          hotelArray.hotelID = result[0].hotelID;
          sessionStorage.getItem("hotelSelected") && sessionStorage.removeItem("hotelSelected");
          localStorage.getItem("hotelSelected") && localStorage.removeItem("hotelSelected");

          sessionStorage.setItem("hotelSelected", JSON.stringify(hotelArray));
          localStorage.setItem("hotelSelected", JSON.stringify(hotelArray));
          localStorage.setItem("subMenu", "Register");
          this.props.history.push('/register', { oprid: oprId, acctType: acctype, pageName: 'Account' })
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  };

  getNewArr = (data: any) => {
    return data.slice(colCounter * currentLen - colCounter, colCounter * currentLen)
  }

  nextList(data: any) {
    if (data.length > 0) {
      if (currentLen >= 0 && currentLen < data.length / colCounter) {
        currentLen++;
        let newArr = this.getNewArr(data)
        this.getAccountBal(newArr, data);
      }
    }
  }

  getAccountBal = (newArr: any, data: any) => {
    let oprIds: any = [];
    let request: any = {};
    newArr.length > 0 && newArr.forEach(item => {
      oprIds.push(item.oprid)
    })
    request.OPRIDs = newArr.length > 0 ? oprIds.toString() : "";
    registersevice.GetAccountNoBalanceList(request).then(async (result: any[] | null) => {
      if (result !== null) {
        result.forEach(resItem => {
          data.forEach(accItem => {
            if (accItem.oprid === resItem.id) {
              let currentBalance = Number(resItem.result) === 0 ? 0 : resItem.result;
              accItem.balanceLoader = false;
              accItem.opnBal = currentBalance;
            }
          });
        });

        let { showInactiveAcc } = this.state;
        let filteredData: any[] = [];
        if (showInactiveAcc) {
          filteredData = data;
        } else {
          filteredData = data.filter((item: any) => item.status?.toString().toLowerCase() === 'active');
        }
        this.setState({
          mainAccountsTableData: data,
          accountsTableData: filteredData,
          accountsExportTableData: filteredData,
          isBalLoading: (currentLen < data.length / colCounter)
        }, () => {
          this.nextList(data);
          this.readyForExport(this.state.accountsTableData);
        })
      }
      resolve();
    }).catch((error: any) => {
      reject();
    });
  }

  searchResult = (resultAfterSearch: any) => {
    this.readyForExport(resultAfterSearch);
  }

  readyForExport = (resultAfterSearch: any) => {
    resultAfterSearch = resultAfterSearch.map((item: any, id: any) => {
      const _item: any = {};
      _item["Account Number"] = item.oBaccno;
      _item["Bank"] = item.bName;
      _item["COA"] = item.coa;
      _item["Nickname"] = item.displayName;
      _item["EHID"] = item.letterCodeToolTip;
      _item["Entity Name"] = item.entityName;
      _item["Account Type"] = item.acctType;
      _item["Balance"] = Utils.amountFormatted(Number(item.opnBal).toFixed(2));
      _item["Status"] = item.status;
      return _item;
    });
    exportDataSet.data = resultAfterSearch;

  }

  renderLetterCode = (cell: any, row: any, rowIndex) => {
    return (
      <div>
        {row.letterCode.toString().toLowerCase().includes("shared (") === true ? (
          <React.Fragment>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id={String(row.id)}>{row.letterCodeToolTip}</Tooltip>}
            >
              <span>{row.letterCode}</span>
            </OverlayTrigger>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <EllipsisWithTooltip placement="top">
              <span>{row.letterCode}</span>
            </EllipsisWithTooltip>
          </React.Fragment>
        )}
      </div>
    );
  };

  handleSelectedItem = (selected) => {
    this.setState({ isMountDone: false });
    let acntDataFilter: any[] = [];
    if (selected === "fromMethod") {
      let data = this.state.accountTypeList.filter(
        (item) => item.name === this.state.defaultSelectOPtion
      );
      selected = data[0];
    }

    this.setState({
      selectedAcType: selected.name,
      defaultSelectOPtion: selected.name,
    });
    if (selected.id >= 0) {
      if (this.state.showInactiveAcc) {
        acntDataFilter = _.cloneDeep(this.state.mainAccountsTableData);
      } else {
        acntDataFilter = _.cloneDeep(this.state.tableDataFilter);
      }
      acntDataFilter = acntDataFilter.filter(
        (item) => item.acctType === selected.name
      );
      this.setState({ accountsTableData: acntDataFilter, accountsExportTableData: acntDataFilter }, () => {
        this.sortChange(this.state.sortingOrder);
      });
    }
    if (selected.id < 0) {
      if (this.state.showInactiveAcc)
        this.setState(
          { accountsTableData: this.state.mainAccountsTableData, accountsExportTableData: this.state.mainAccountsTableData },
          () => {
            this.sortChange(this.state.sortingOrder);
          }
        );
      else
        this.setState({ accountsTableData: this.state.tableDataFilter, accountsExportTableData: this.state.tableDataFilter }, () => {
          this.sortChange(this.state.sortingOrder);
        });
    }
  };

  showInactiveAccount = (e) => {
    let { checked } = e.target;
    let acntDataFilter: any[] = [];

    this.setState({ showInactiveAcc: checked });
    if (checked) {
      acntDataFilter = _.cloneDeep(this.state.mainAccountsTableData);
    } else {
      acntDataFilter = _.cloneDeep(this.state.tableDataFilter);
    }
    if (
      this.state.selectedAcType !== "" &&
      this.state.selectedAcType !== "All"
    ) {
      acntDataFilter = acntDataFilter.filter(
        (item) => item.acctType === this.state.selectedAcType
      );
    }
    let ifInacFount = acntDataFilter.some(item => item.status.toString().toLowerCase() === "inactive");
    this.setState({ accountsTableData: acntDataFilter, tableClass: ifInacFount === true ? "accounts-tableWide" : "accounts-tableSmall" }, () => {
      this.sortChange(this.state.sortingOrder);
    });
  };

  formatAmmount = (cell: any, row: any, rowIndex) => {
    if (row.balanceLoader) {
      return (<div className="text-right pr-3"><Spinner size="sm" animation="border" variant="secondary" /></div>);
    } else if (row.isViewBankGLBalances) {
      return (<div className="text-right"><EllipsisWithTooltip placement="top">{Utils.amountFormatted(Number(row.opnBal).toFixed(2))}</EllipsisWithTooltip></div>);
    } else {
      return (<div className="text-right"><EllipsisWithTooltip placement="top">xxxx</EllipsisWithTooltip></div>);
    }
  };

  CleanSearch = () => {
    if (this.tableSearch.current !== null)
      this.tableSearch.current.CleanSearch();
  };

  sortChange = (item: any) => {
    let tableData = [...this.state.accountsTableData];
    let data = tableData;
    if (item === "Bank") {
      data = _.sortBy(data, ["bNameSort", "dsort"]);
    } else if (item === "EHID") {
      data = _.sortBy(data, ["letterCodeSort", "dsort"]);
    } else if (item === "Nickname") {
      data = _.sortBy(data, ["displayNameSort", "dsort"]);
    } else if (item === "Type") {
      data = _.sortBy(data, "dsort");
    }
    this.setState({ accountsTableData: data, sortingOrder: item, accountsExportTableData: data, isMountDone: true }, () => {
      this.readyForExport(data);
    });
  };

  saveDefaultView = (): void => {
    const laborSaveAsDefaultdtos: any[] = [];
    const { sortingOrder, showInactiveAcc } = this.state;
    laborSaveAsDefaultdtos.push({
      fieldName: "toggle",
      fieldValue: showInactiveAcc,
      pageName: this.pageName,
    });
    laborSaveAsDefaultdtos.push({
      fieldName: "sort",
      fieldValue: sortingOrder,
      pageName: this.pageName,
    });
    
    laborPerformance
      .saveDefaultViewProperties(laborSaveAsDefaultdtos)
      .then(async (result: any | null) => {
        if (result != null) {
          if (result.message === "Success") {
            toast.success(result?.result?.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "AccountsHome"
            });
          }
        }
      })
      .catch((error) => {
        Utils.toastError(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "AccountsHome"
        });
        this.setState({ showLoader: false });
      });
  };

  showSlideout = () => {
    this.setState({ isShowSlideout: true });
  }
  showCreditCardSlideout = () => {
    this.setState({ isShowCreditCardSlideout: true });
  }

  hideSlideOut(mode) {
    if (mode === 1) {
      this.CleanSearch();
      this.setState({ isShowSlideout: false, showLoader: true }, () => {
        let request = {} as any;
        request.HID = this.state.hidValue;
        this.getAccountsData(request);
      });
    } else {
      this.setState({ isShowSlideout: false })
    }
  }
  hideCreditCardSlideOut(mode) {
    if (mode === 1) {
      let request = {} as any;
      request.hotelID = this.state.hidValue;
      request.lettercode = this.state.hotelName;
      this.hotelChange(request);
    }
    this.setState({ isShowCreditCardSlideout: false })
  }

  downloadExcel = () => {
    const fileName = `${"accounts"}-${JSON.parse(localStorage.storage).currentUtcTime.replace(' PM', '').replace(' AM', '').replace(' ', '-').replace(':', '-').replace(':', '-')}`;
    const worksheet = XLSX.utils.json_to_sheet(exportDataSet.data);
    const header = Object.keys(exportDataSet.data[0]);
    let wscols: any = [];
    for (let i = 0; i < header.length; i++) { 
      wscols.push({ wch: header[i].length + 10 })
    }
    worksheet["!cols"] = wscols;
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, fileName);
    XLSX.writeFile(workbook, fileName + ".xlsx", {});
  };

  render() {
    const columns = [
      {
        dataField: "oBaccno",
        text: "Account Number",
        searchable: true,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div>
            <EllipsisWithTooltip placement="top">
              <div className="hasInactiveBatch">
                {row.oBaccno}
                {row.status.toString().toLowerCase() === "inactive" && (
                  <span className="badge badge-light"> Inactive</span>
                )}
              </div>
            </EllipsisWithTooltip>
          </div>
        ),
      },
      {
        dataField: "bName",
        text: "Bank",
        searchable: true,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div>
            <EllipsisWithTooltip placement="top">
              {row.bName}

            </EllipsisWithTooltip>
          </div>
        ),
      },
      {
        dataField: "coa",
        text: "COA",
        searchable: true,
        formatter: (cell: any, row: any, rowIndex: any) => <div>
          {row.coa}
        </div>
      },
      {
        dataField: "displayName",
        text: "Nickname",
        searchable: true,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div>
            <EllipsisWithTooltip placement="top">
              {row.displayName}

            </EllipsisWithTooltip>
          </div>
        ),
      },
      {
        dataField: "letterCode",
        text: "EHID",
        searchable: false,
        formatter: (cell: any, row: any, rowIndex: any) =>

          this.renderLetterCode(cell, row, rowIndex),
      },
      {
        dataField: "acctType",
        text: "Account Type",
        searchable: false,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div>
            <EllipsisWithTooltip placement="top">
              {row.acctType}

            </EllipsisWithTooltip>
          </div>
        ),
      },
      {
        dataField: "opnBal",
        text: "Balance",
        searchable: false,
        hidden: this.state.isAllViewBankGLBal,
        formatter: (cell: any, row: any, rowIndex: any) =>
          this.formatAmmount(cell, row, rowIndex),
      },
    ];

    return (
      <>
        <ToastContainer enableMultiContainer
          autoClose={3000}
          containerId={"AccountsHome"} />
        <div className="accountsPage" onClick={this.searchClear}>
          <Container fluid className="body-sec">
            <div className="page-heading underline d-flex">
              <Dropdown className="hid-select">
                <Dropdown.Toggle
                  id="dropdown-hid"
                  disabled={this.state.showLoader}
                >
                  <div className="d-flex align-items-center justify-content-center">
                    {this.state.hotelName}
                    <div className="drop-arrow d-flex">
                      <FiChevronDown />
                    </div>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu className="cusEhidSelector">
                  <div className="EhidSearch">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Control
                        type="text"
                        name="search"
                        value={this.state.search}
                        autoComplete="off"
                        onChange={this.searchItems}
                        placeholder="Filter EHIDs"
                      />
                    </Form.Group>
                  </div>
                  <div className="dropdownItems">
                    {this.state.hidList.length > 0 ? (
                      this.state.hidList.map(
                        (item: any, idx: number) => (
                          <React.Fragment key={item?.hotelID}>
                            {(idx > 0
                              && item?.hotelType !== this.state.hidList[idx - 1]?.hotelType) && (
                                <Dropdown.Divider />

                              )}
                            <Dropdown.Item
                              eventKey={item.lettercode}
                              onClick={() => this.hotelChange(item)}
                            >
                              <div
                                className={
                                  item.lettercode === this.state.hotelName
                                    ? "dropDownListTitle hasCheck"
                                    : "dropDownListTitle"
                                }
                              >
                                <span className="name">
                                  {item.hotelName.split(" - ")[0]}
                                </span>
                                <span className="title">
                                  {
                                    item.hotelName
                                      .replace(" - ", ".")
                                      .split(".")[1]
                                  }
                                </span>
                                {item.lettercode === this.state.hotelName && (
                                  <span className="itemcheck">
                                    <svg
                                      width="20"
                                      height="20"
                                      viewBox="0 0 20 20"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
                                        fill="#2CAF92"
                                      />
                                    </svg>
                                  </span>
                                )}
                              </div>
                            </Dropdown.Item>
                          </React.Fragment>
                        )
                      )
                    ) : (
                      <Dropdown.Item>
                        <b>No Item Found</b>
                      </Dropdown.Item>
                    )}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
              <div className="mr-auto">Accounts</div>
              {(this.state.hotelName !== "All" && !this.state.isAddAccountDisbaled) && (
                <div className="action-group d-flex">
                  {this.state.isLimitedsubs === "No" && (
                    <Button className="btn-outline-primary" type="button" onClick={() => { this.showCreditCardSlideout() }}>
                      Add Credit Card Account
                    </Button>
                  )}
                  <Button type="button" onClick={() => { this.showSlideout() }}>
                    Add Account
                  </Button>

                </div>
              )}
              {this.state.hotelName !== "Select" && !this.state.showLoader &&
                <>
                  <div className="action-group d-flex">

                    <Dropdown className="more-action" alignRight>
                      <Dropdown.Toggle className="btn-outline-primary btn btn-primary more" id="dropdown-more">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                          <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                          <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                        </svg>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {this.state.accountsExportTableData.length > 0 && (
                          <>
                            {exportDataSet.data.length === 0 || this.state.isBalLoading ? (
                              <Dropdown.Item className="disabled-area">Export</Dropdown.Item>
                            ) : (
                              <Dropdown.Item eventKey="export" key="export" onClick={this.downloadExcel}>Export</Dropdown.Item>
                            )}
                          </>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                </>
              }

            </div>

            {this.state.showLoader ? (
              <ReactPageLoader useas={"accounts"} />
            ) : (
              <>
                {this.state.isMountDone && (
                  <ToolkitProvider
                    keyField="id"
                    data={this.state.accountsTableData}
                    columns={columns}
                    search={{
                      afterSearch: (newResult) => this.searchResult(newResult)
                    }}
                    hover

                  >
                    {(props: {
                      searchProps: JSX.IntrinsicAttributes;
                      baseProps: JSX.IntrinsicAttributes;
                    }) => (
                      <React.Fragment>
                        <div className="navigation-menus d-flex">
                          <div className="navigationSort">
                            <Dropdown className="more-action bg-blue" alignRight>
                              <Dropdown.Toggle id="dropdown-more">
                                <div className="d-flex align-items-center">
                                  <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    stroke-width="0"
                                    viewBox="0 0 24 24"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g>
                                      <path fill="none" d="M0 0h24v24H0z"></path>
                                      <path d="M2 20h20v2H2v-2zm2-8h2v7H4v-7zm5 0h2v7H9v-7zm4 0h2v7h-2v-7zm5 0h2v7h-2v-7zM2 7l10-5 10 5v4H2V7zm10 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path>
                                    </g>
                                  </svg>

                                  {this.state.defaultSelectOPtion}
                                  <div className="drop-arrow d-flex">
                                    <FiChevronDown />
                                  </div>
                                </div>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Header>Account Type</Dropdown.Header>
                                {this.state.accountTypeList.map(
                                  (item: any, idx: any) => (
                                    <Dropdown.Item
                                      key={item?.id}
                                      className={this.state.selectedAcType === item.name ? `${this.state.isBalLoading ? "disabled-area" : "active"}` : `${this.state.isBalLoading ? "disabled-area" : ""}`}
                                      eventKey={item}
                                      onClick={() =>
                                        //this.handleSelectedItem(item.id)
                                        this.handleSelectedItem(item)
                                      }
                                    >
                                      <div className="d-flex">
                                        <div className="mr-auto">
                                          {" "}
                                          {item.name}
                                        </div>
                                        <span className="itemcheck">
                                          <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
                                              fill="#2CAF92"
                                            />
                                          </svg>
                                        </span>
                                      </div>
                                    </Dropdown.Item>
                                  )
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>

                          <TableSearch
                            id="tableSearch"
                            key="tableSearch"
                            ref={this.tableSearch}
                            {...props.searchProps}
                            CleanSearch={this.CleanSearch.bind(this)}
                            placeholder={"Search"}
                          />

                          <div
                            className="navigationSort ml-auto"
                            style={{ paddingTop: 10, paddingLeft: 10 }}
                          >
                            <Dropdown className="more-action bg-blue" alignRight>
                              <Dropdown.Toggle id="dropdown-more">
                                <div className="d-flex align-items-center">
                                  <svg
                                    width="16"
                                    height="12"
                                    viewBox="0 0 16 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="svgIcon"
                                  >
                                    <path d="M3.5 12L6.5 9H4.25V0H2.75V9H0.5L3.5 12ZM7.25 3H14V4.5H7.25V3ZM7.25 6H12.5V7.5H7.25V6ZM7.25 0H15.5V1.5H7.25V0ZM7.25 9H11V10.5H7.25V9Z" />
                                  </svg>

                                  {this.state.sortingOrder}

                                  <div className="drop-arrow d-flex">
                                    <FiChevronDown />
                                  </div>
                                </div>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Header>SORT</Dropdown.Header>
                                {this.state.sortingOrderList.map(
                                  (item: any, idx: any) => (
                                    <Dropdown.Item
                                      key={item}
                                      className={this.state.sortingOrder === item ? `${this.state.isBalLoading ? "disabled-area" : "active"}` : `${this.state.isBalLoading ? "disabled-area" : ""}`}
                                      eventKey={item}
                                      onClick={() => this.sortChange(item)}
                                    >
                                      <div className="d-flex">
                                        <div className="mr-auto"> {item}</div>
                                        <svg
                                          width="18"
                                          height="18"
                                          viewBox="0 0 18 18"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
                                            fill="#2CAF92"
                                          />
                                        </svg>
                                      </div>
                                    </Dropdown.Item>
                                  )
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          <Form.Check disabled={this.state.isBalLoading} id="showInactive" key="showInactive" type="switch" label="Show Inactive" checked={this.state.showInactiveAcc} onChange={this.showInactiveAccount} />
                          <div
                            className="navigationSettings"
                            style={{ paddingTop: 8, paddingLeft: 10 }}
                          >
                            <Dropdown className="more-action bg-blue">
                              <Dropdown.Toggle id="dropdown-ptoType">
                                <div className="d-flex align-items-center">
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M1.56286 10.1808L2.89619 12.4875C3.08019 12.8062 3.48819 12.9155 3.80686 12.7308L4.73752 12.1935C5.12419 12.4982 5.55152 12.7482 6.00086 12.9348V14.0002C6.00086 14.3682 6.29886 14.6668 6.66752 14.6668H9.33419C9.70286 14.6668 10.0009 14.3682 10.0009 14.0002V12.9348C10.4502 12.7482 10.8769 12.4982 11.2642 12.1935L12.1949 12.7308C12.5129 12.9142 12.9222 12.8042 13.1055 12.4875L14.4389 10.1808C14.6222 9.8635 14.5122 9.4535 14.1955 9.27016L13.2809 8.7415C13.3155 8.4955 13.3335 8.2475 13.3335 8.00016C13.3335 7.75283 13.3155 7.50483 13.2795 7.25883L14.1942 6.73016C14.5115 6.54683 14.6215 6.13683 14.4375 5.8195L13.1042 3.51283C12.9202 3.19416 12.5122 3.08483 12.1935 3.2695L11.2629 3.80683C10.8762 3.50216 10.4495 3.2515 10.0002 3.0655V2.00016C10.0002 1.63216 9.70219 1.3335 9.33352 1.3335H6.66686C6.29819 1.3335 6.00019 1.63216 6.00019 2.00016V3.0655C5.55086 3.25216 5.12419 3.50216 4.73686 3.80683L3.80686 3.2695C3.48752 3.08483 3.08019 3.19416 2.89619 3.51283L1.56286 5.8195C1.37953 6.13683 1.48953 6.54683 1.80619 6.73016L2.72086 7.25883C2.68486 7.50483 2.66686 7.75283 2.66686 8.00016C2.66686 8.2475 2.68486 8.4955 2.72086 8.7415L1.80619 9.27016C1.48886 9.4535 1.37886 9.8635 1.56286 10.1808ZM8.00019 5.3335C9.47086 5.3335 10.6669 6.5295 10.6669 8.00016C10.6669 9.47083 9.47086 10.6668 8.00019 10.6668C6.52952 10.6668 5.33352 9.47083 5.33352 8.00016C5.33352 6.5295 6.52952 5.3335 8.00019 5.3335Z" />
                                  </svg>
                                  <div className="drop-arrow d-flex">
                                    <FiChevronDown />
                                  </div>
                                </div>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => this.saveDefaultView()}
                                >
                                  Save as Default View
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>

                        <div className={this.state.tableClass}>
                          <BootstrapTable
                            {...props.baseProps}
                            keyField="id"
                            rowEvents={this.rowEvents}
                            hover
                            condensed
                          />
                        </div>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}

              </>
            )}
          </Container>
          {this.state.isShowSlideout && (
            <BankAccountSlideout
              hidValue={this.state.hidValue}
              hotelName={this.state.hotelName}
              actionType={"new"}
              hideSlideOut={this.hideSlideOut.bind(this)}
            ></BankAccountSlideout>
          )}
          {this.state.isShowCreditCardSlideout && (
            <CreditCardAccountSlideout
              hidValue={this.state.hidValue}
              hotelName={this.state.hotelName}
              actionType={"new"}
              hideCreditCardSlideOut={this.hideCreditCardSlideOut.bind(this)}
            ></CreditCardAccountSlideout>
          )}
        </div>
      </>
    );
  }
}