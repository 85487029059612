import React, { Component } from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import _ from "lodash";
import WorkOrderSlideOut from '../Workorder/WorkOrderSlideOut';
import { WorkOrder } from '../../Common/Services/WorkOrder';
import { resolve, reject } from "q";
import { toast } from "react-toastify";
import { IWorkOrderByLocation, IWorkOrderRequestDto } from '../../Common/Contracts/IWorkOrder';
import moment from 'moment';

export class LocationWorkOrder extends React.Component<any, any> {
  private wororderSlideOut: any;
  constructor(props) {
    super(props);
    this.wororderSlideOut = React.createRef();
    this.state = {
        tableData: [],
        tableDataLength : 0,
      //   tableData: [{
      //     "workOrderID": 47,
      //     "name": null,
      //     "tenantid": 101,
      //     "hid": 1430,
      //     "locationID": 1430,
      //     "assetID": 0,
      //     "status": "In Progress",
      //     "dueDate": null,
      //     "priorityType": "Urgent",
      //     "assignedTo": "144628",
      //     "title": "AC Repair",
      //     "description": "Test washing mashine 1 ghfdhgfh",
      //     "createdon": "2023-10-12T08:01:26.83",
      //     "createdBy": "3913",
      //     "completedDate": null
      // },],
          sortingOrder: "asc",
          sortingField: "title",
          PageType:"locationSlideOut"
    };

    // Define your column configuration (e.g., this.columns)
  }

  componentDidMount() {
    this.getWorkOrder();
  }

  getWorkOrder = () => {
    let request = {} as IWorkOrderByLocation;
    request.hid = Number(this.props.parentData.hidValue);
    request.locationId=this.props.parentData.locationId;
    WorkOrder.GetWorkOrder(
    request
    ).then((result: any) => {
      let data:any=[];
      let tempNotcompleted = result.filter((o) => o.status !== "Completed");
      let tempcompleted = result.filter((o) => o.status == "Completed");
      if (tempNotcompleted.length > 0) {
        tempNotcompleted.map((item: any) => {
          return data.push(item)
        });
      }
      if (tempcompleted.length > 0) {
        tempcompleted.map((item: any) => {
          return data.push(item)
        });
      }
      this.props.countUpdate(0,true);

      this.setState({tableData : data,tableDataLength : tempNotcompleted.length},()=>{
        this.props.countUpdate(this.state.tableDataLength,false);
        if (this.props.parentData.type == "saved") {
        this.onRowClick("", "row", "", "saved", Number(this.props.parentData.lastWorkId));
        }
      });
      // if (this.props.parentData.type == "saved") {
       // this.onRowClick("", "row", "", "saved", Number(this.props.parentData.lastWorkId));
      // }
      // const filteredData = result.filter((item: any) => item.status !== "Completed");
      // this.setState({tableData : result,tableDataLength : filteredData.length},()=>{
      //    if (this.props.parentData.type == "saved") {
      //   this.onRowClick("", "row", "", "saved", Number(this.props.parentData.lastWorkId));
      //    }
      // });
    }
    );
  };


//   getrooms = () => {
//     let hotelID = Number(this.state.hidValue);
//    WorkOrder.GetFacilityRooms(hotelID)
//        .then(async (result: any | null) => {
//            let DayApproveData: any = [];
           
//            if (result != null && result.length > 0) {
//                let Data = result.map(x => {
//                    return { id: x?.roomID, name: x?.roomName };
//                })

//                this.setState({
//                  locationTypesList: Data
//                });
//                this.setState({locationSearchTypesList:[]},()=>{
//                  this.setState({ locationSearchTypesList: [{ id: -1, name: "All" },...Data] });
//                })
              
//            } else {
//                this.setState({
//                  locationTypesList: []
//                });
//            }
//            resolve();
//        })
//        .catch((err) => {
//            Utils.toastError(`Server Error, ${err}`, {
//            });

//            reject();
//        });
// }

// GetFacilityAsset = () => {
//   let hotelID = Number(this.state.hidValue);
//     WorkOrder.GetFacilityAsset(hotelID)
//         .then(async (result: any | null) => {

//             if (result != null && result.length > 0) {
//                 let Data = result.map(x => {
//                     return { id: x?.roomID, name: x?.roomName };
//                 })
//                 this.setState({ assetTypesList: Data });
//               // this.setState({ assetTypesList: [{ id: 0, name: "Select an asset" }, ...Data] });
//             } else {
//                 //this.setState({ assetTypesList: [{ id: 0, name: "Select an asset" }] });
//                 this.setState({ assetTypesList: [] });
//             }
//             resolve();
//         })
//         .catch((err) => {
//             Utils.toastError(`Server Error, ${err}`, {
//             });

//             reject();
//         });
// }

// getAssignUserList() {

//   let request = {} as IWorkOrderRequestDto;
//   request.hotelID = Number(this.state.hidValue);
//   request.type = "AssignUsers";
//   WorkOrder.GetFcUserDetailsList(request)
//     .then(async (result: any | null) => {
     
//       if (result != null && result.length > 0) {
//         let Data = result.map(x => {
//           return { id: x?.user_UniqueID, name: x?.employee };
//         })

//         this.setState({ assignToList: [] }, () => {
//           this.setState({ assignToList: [{ id: -1, name: "All" }, ...Data] });
//           this.setState({assignToListRightPanel:Data});
//           // this.setState({ assignToListToChild: [{ id: -1, name: "Select a technician" }, ...Data] });
//         })

//       } else {
//         this.setState({ assignToList: [{ id: -1, name: "All" }],assignToListRightPanel:[]});
        
//       }
//       resolve();
//     })
//     .catch((err) => {
//       Utils.toastError(`Server Error, ${err}`, {
//       });

//       reject();
//     });
// }


  handleSortByColumn = (field) => {
    this.setState(
      {
        sortingField: field,
        sortingOrder: this.state.sortingOrder === "asc" ? "desc" : "asc",
      },
      () => {
        this.sortTableData(this.state.tableData, this.state.sortingField);
      }
    );
  };

  // sortTableData = (data: any, fieldName) => {
  //   if (this.state.sortingOrder === "asc") {
  //     data = _.orderBy(data, [fieldName], ["asc", "asc"]);
  //   } else if (this.state.sortingOrder === "desc") {
  //     data = _.orderBy(data, [fieldName], ["desc", "asc"]);
  //   }
  //   this.setState({ tableData: data });
  
  // };


  sortTableData = (data: any, fieldName) => {
    if (fieldName === "title") {
      if (this.state.sortingOrder === "asc") {
        let tempNotcompleted = data.filter((o) => o.status !== "Completed");
        let tempcompleted = data.filter((o) => o.status == "Completed");
        tempNotcompleted = _.orderBy(tempNotcompleted, [fieldName === "title" ? "title" : fieldName, "title"], ["asc", "asc"]);
        tempcompleted = _.orderBy(tempcompleted, [fieldName === "title" ? "title" : fieldName, "title"], ["asc", "asc"]);
        data = tempNotcompleted;
        if (tempcompleted.length > 0) {
          tempcompleted.map((item: any) => {
            return data.push(item)
          });
        }
      } else if (this.state.sortingOrder === "desc") {
        let tempNotcompleted = data.filter((o) => o.status !== "Completed");
        let tempcompleted = data.filter((o) => o.status == "Completed");
        tempNotcompleted = _.orderBy(tempNotcompleted, [fieldName === "title" ? "title" : fieldName, "title"], ["desc", "asc"]);
        tempcompleted = _.orderBy(tempcompleted, [fieldName === "title" ? "title" : fieldName, "title"], ["desc", "desc"]);
        data = tempNotcompleted;
        if (tempcompleted.length > 0) {
          tempcompleted.map((item: any) => {
            return data.push(item)
          });
        }
      }
    }
    if (fieldName === "status") {
      if (this.state.sortingOrder === "asc") {

        let tempNotcompleted = data.filter((o) => o.status !== "Completed");
        let tempcompleted = data.filter((o) => o.status == "Completed");
        tempNotcompleted = _.orderBy(tempNotcompleted, [fieldName === "status" ? "status" : fieldName, "status"], ["asc", "asc"]);
        tempcompleted = _.orderBy(tempcompleted, [fieldName === "status" ? "status" : fieldName, "status"], ["asc", "asc"]);
        data = tempNotcompleted;
        if (tempcompleted.length > 0) {
          tempcompleted.map((item: any) => {
            return data.push(item)
          });
        }
      } else if (this.state.sortingOrder === "desc") {

        let tempNotcompleted = data.filter((o) => o.status !== "Completed");
        let tempcompleted = data.filter((o) => o.status == "Completed");
        tempNotcompleted = _.orderBy(tempNotcompleted, [fieldName === "status" ? "status" : fieldName, "status"], ["desc", "asc"]);
        tempcompleted = _.orderBy(tempcompleted, [fieldName === "status" ? "status" : fieldName, "status"], ["desc", "asc"]);
        data = tempNotcompleted;
        if (tempcompleted.length > 0) {
          tempcompleted.map((item: any) => {
            return data.push(item)
          });
        }
      }
    }
    if (fieldName === "createdon") {
      if (this.state.sortingOrder === "asc") {
        let tempNotcompleted = data.filter((o) => o.status !== "Completed");
        let tempcompleted = data.filter((o) => o.status == "Completed");
        tempNotcompleted = _.orderBy(tempNotcompleted, [fieldName === "createdon" ? "createdon" : fieldName, "createdon"], ["asc", "asc"]);
        tempcompleted = _.orderBy(tempcompleted, [fieldName === "createdon" ? "createdon" : fieldName, "createdon"], ["asc", "asc"]);
        data = tempNotcompleted;
        if (tempcompleted.length > 0) {
          tempcompleted.map((item: any) => {
            return data.push(item)
          });
        }
       
      } else if (this.state.sortingOrder === "desc") {
        let tempNotcompleted = data.filter((o) => o.status !== "Completed");
        let tempcompleted = data.filter((o) => o.status == "Completed");
        tempNotcompleted = _.orderBy(tempNotcompleted, [fieldName === "createdon" ? "createdon" : fieldName, "createdon"], ["desc", "asc"]);
        tempcompleted = _.orderBy(tempcompleted, [fieldName === "createdon" ? "createdon" : fieldName, "createdon"], ["desc", "asc"]);
        data = tempNotcompleted;
        if (tempcompleted.length > 0) {
          tempcompleted.map((item: any) => {
            return data.push(item)
          });
        }
        
      }
    }
    if (fieldName === "completedDate") {
      if (this.state.sortingOrder === "asc") {

        let tempNotcompleted = data.filter((o) => o.status !== "Completed");
        let tempcompleted = data.filter((o) => o.status == "Completed");
        tempNotcompleted = _.orderBy(tempNotcompleted, [fieldName === "completedDate" ? "completedDate" : fieldName, "completedDate"], ["asc", "asc"]);
        tempcompleted = _.orderBy(tempcompleted, [fieldName === "completedDate" ? "completedDate" : fieldName, "completedDate"], ["asc", "asc"]);
        data = tempNotcompleted;
        if (tempcompleted.length > 0) {
          tempcompleted.map((item: any) => {
            return data.push(item)
          });
        }
      
      } else if (this.state.sortingOrder === "desc") {

        let tempNotcompleted = data.filter((o) => o.status !== "Completed");
        let tempcompleted = data.filter((o) => o.status == "Completed");
        tempNotcompleted = _.orderBy(tempNotcompleted, [fieldName === "completedDate" ? "completedDate" : fieldName, "completedDate"], ["desc", "asc"]);
        tempcompleted = _.orderBy(tempcompleted, [fieldName === "completedDate" ? "completedDate" : fieldName, "completedDate"], ["desc", "asc"]);
        data = tempNotcompleted;
        if (tempcompleted.length > 0) {
          tempcompleted.map((item: any) => {
            return data.push(item)
          });
        }

       
      }
    }

    if (fieldName === "priorityType") {
      if (this.state.sortingOrder === "asc") {

        let tempNotcompleted = data.filter((o) => o.status !== "Completed");
        let tempcompleted = data.filter((o) => o.status == "Completed");
        tempNotcompleted = _.orderBy(tempNotcompleted, [fieldName === "priorityType" ? "priorityType" : fieldName, "priorityType"], ["asc", "asc"]);
        tempcompleted = _.orderBy(tempcompleted, [fieldName === "priorityType" ? "priorityType" : fieldName, "priorityType"], ["asc", "asc"]);
        data = tempNotcompleted;
        if (tempcompleted.length > 0) {
          tempcompleted.map((item: any) => {
            return data.push(item)
          });
        }


        
      } else if (this.state.sortingOrder === "desc") {

        let tempNotcompleted = data.filter((o) => o.status !== "Completed");
        let tempcompleted = data.filter((o) => o.status == "Completed");
        tempNotcompleted = _.orderBy(tempNotcompleted, [fieldName === "priorityType" ? "priorityType" : fieldName, "priorityType"], ["desc", "asc"]);
        tempcompleted = _.orderBy(tempcompleted, [fieldName === "priorityType" ? "priorityType" : fieldName, "priorityType"], ["desc", "asc"]);
        data = tempNotcompleted;
        if (tempcompleted.length > 0) {
          tempcompleted.map((item: any) => {
            return data.push(item)
          });
        }

        
      }
    }
    this.setState({ tableData: data });
  };


  sortImage = (order) => {

    if (!order)
      return (
        <span className="ascending">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
              fill="#1D1E20"
            />
          </svg>
        </span>
      );
    else if (order === "asc")
      return (
        <span className="ascending">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
              fill="#1D1E20"
            />
          </svg>
        </span>
      );
    else if (order === "desc")
      return (
        <span className="descending">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
              fill="#1D1E20"
            />
          </svg>
        </span>
      );
    return null;
  };

  onRowClick = (
    e: any,
    row: any,
    rowIndex: any,
    type: any,
    lastworkOrderID: any
  ) => {
  if(type === "table"){
    let rowData: any = {
      title: row.title,
      user_UniqueID: Number(row.assignedTo),
      assetID: row.assetID,
      completedDate: row.completedDate,
      createdBy: row.createdBy,
      createdon: row.createdon,
      description: row.description,
      dueDate: row.dueDate === undefined ? "" : row.dueDate,
      hid: row.hid,
      locationID: row.locationID,
      name: row.name,
      priorityType: row.priorityType,
      status: row.status,
      tenantid: row.tenantid,
      workOrderID: row.workOrderID
    };

    this.setState({parentRowData: rowData} , () => {      
      this.props.closeLocationSlideOut(this.state.parentRowData)
      
    })
  }
    if(type === "saved"){
      let data = this.state.tableData;
      let row1 = data.filter((item) => item.workOrderID === lastworkOrderID);    
      if (row1.length > 0) {
      //  this.setState({ tableData: data}, () => {
          this.setState({ lastWorkId: "",parentRowData: row1[0] },()=>{
            let rowData2: any = {
              title: row1[0].title,
              user_UniqueID: Number(row1[0].assignedTo),
              assetID: row1[0].assetID,
              completedDate: row1[0].completedDate,
              createdBy: row1[0].createdBy,
              createdon: row1[0].createdon,
              description: row1[0].description,
              dueDate: row1[0].dueDate === undefined ? "" : row1[0].dueDate,
              hid: row1[0].hid,
              locationID: row1[0].locationID,
              name: row1[0].name,
              priorityType: row1[0].priorityType,
              status: row1[0].status,
              tenantid: row1[0].tenantid,
              workOrderID: row1[0].workOrderID
            };
            this.setState({parentRowData: rowData2} , () => {
              this.props.changesAfterSave(this.state.parentRowData)
              
            })
          });
      }
      
    }
  };

 

  render() {
   
    const rowClasses = (row: any, rowIndex: any) => {
      if (row.status.toLowerCase() === "completed") {
         if(row.status.toLowerCase() === "completed"){
        return "work-order-completed table-Row-Selected"
         }else{
          return "work-order-completed"
         }
      }
    
      if (row.seletedColor === "yes") {
        if(this.state.isWorkOrderDetailsChange){
        return "row-disabled table-Row-Selected"
      }else{
        return "table-Row-Selected"
      }
      }else{
        if(this.state.isWorkOrderDetailsChange){
          return "row-disabled"
        }
      }
      

    };
    let dateFormat="MM/DD/YY";
    const columns = [
      {
        dataField: "title",
        text: "Name",
        headerFormatter: (column, colIndex) => {
          return (
            <div
              className={`d-inline-block ${this.state.sortingField === "title" ? "active" : ""
                }`}
              onClick={() => this.handleSortByColumn("title")}
            >
              {column.text}
              {this.sortImage(this.state.sortingOrder)}
            </div>
          );
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <>
              <EllipsisWithTooltip placement="bottom">
                <div className="work-title"> {row.title}</div>
              </EllipsisWithTooltip>
            </>
          );
        },

      },
        {
          dataField: "status",
          searchable: false,
          text: "Status",
          headerFormatter: (column, colIndex) => {
            return (
              <div
                className={`d-inline-block ${this.state.sortingField === "status" ? "active" : ""
                  }`}
                onClick={() => this.handleSortByColumn("status")}
              >
                {column.text}
                {this.sortImage(this.state.sortingOrder)}
              </div>
            );
          },
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <>
                <EllipsisWithTooltip><div className={`badge ${row.status.toLowerCase() === "on hold" ? "hold-badge" : row.status.toLowerCase() === "in progress" ? "progress-badge" : row.status.toLowerCase() === "completed" ? "completed" : ""}`}>{row.status}</div></EllipsisWithTooltip>
              </>
            )
          },
  
  
        },
        {
          dataField: "createdon",
          text: "Creation Date",
          headerFormatter: (column, colIndex) => {
            return (
              <div
                className={`d-inline-block ${this.state.sortingField === "createdon" ? "active" : ""
                  }`}
                onClick={() => this.handleSortByColumn("createdon")}
              >
                {column.text}
                {this.sortImage(this.state.sortingOrder)}
              </div>
            );
          },

          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <>
                <EllipsisWithTooltip placement="bottom">
                  <div className="work-name"> {row.createdon !== null ? moment(row.createdon).format(dateFormat) : ""}</div>
                </EllipsisWithTooltip>
              </>
            );
          },
  
        },
        {
          dataField: "completedDate",
          text: "Completion Date",
          headerFormatter: (column, colIndex) => {
            return (
              <div
                className={`d-inline-block ${this.state.sortingField === "completedDate" ? "active" : ""
                  }`}
                onClick={() => this.handleSortByColumn("completedDate")}
              >
                {column.text}
                {this.sortImage(this.state.sortingOrder)}
              </div>
            );
          },
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <>
                <EllipsisWithTooltip placement="bottom">
                  <div className="work-name"> {row.completedDate !== null ? moment(row.completedDate).format(dateFormat) : ""}</div>
                </EllipsisWithTooltip>
              </>
            );
          },
  
        },
       
        {
          dataField: "priorityType",
          searchable: false,
          text: "Priority",
          headerFormatter: (column, colIndex) => {
            return (
              <div
                className={`d-inline-block ${this.state.sortingField === "priorityType" ? "active" : ""
                  }`}
                onClick={() => this.handleSortByColumn("priorityType")}
              >
                {column.text}
                {this.sortImage(this.state.sortingOrder)}
              </div>
            );
          },
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <>
                <EllipsisWithTooltip>
                  <div className={`priority ${row.priorityType.toLowerCase()}`}>{row.priorityType}</div>
                </EllipsisWithTooltip>
              </>
            )
          },
  
  
        },
  
      ];

      const tableRowEvents = {
        onClick: (e, row, rowIndex) => {
          this.onRowClick(e, row, rowIndex, "table", "");
        },
  
      }

      return (
      <div className='hide-header-scrollbar'>
        <BootstrapTable
          keyField="workOrderID"
          data={this.state.tableData}
          columns={columns}
          rowEvents={tableRowEvents}
          search
          hover
          rowClasses={rowClasses}
        />
      </div>
      );
  }
}
