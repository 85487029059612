import * as React from 'react';
import {Form, Button, Modal, Spinner, Row, Col,Container, Dropdown, OverlayTrigger, Tooltip} from "react-bootstrap";
import { DropDownSelect } from "./DropDownSelect";
import { User } from "../../../Common/Services/User";
import { SalesService } from "../../../Common/Services/SalesService";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { Utils } from '../../../Common/Utilis';


export class ContactSlideout extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            selectedAccount: props?.selectedAccount,
            preferredContactList: [
                { name: "Email", eventKey: "email", id: 1 },
                { name: "Phone", eventKey: "phone", id: 2 },
                { name: "Text", eventKey: "text", id: 3 },
            ],
            accountContactDetails: {
                contactId: -1, accountId: props?.accountId, name: "", email: "", phone: "", title: "", preferredMethod: "", notes: "", isPrimary: "no", isEdit: "no", createdBy: "", createdByName: "", createdDate: "", status: "",
            },
            accountContactDetailsCopy: {
                contactId: -1, accountId: props?.accountId, name: "", email: "", phone: "", title: "", preferredMethod: "", notes: "", isPrimary: "no", isEdit: "no", createdBy: "", createdByName: "", createdDate: "", status: "",
            },
            errors: {
                name: "", contact: "", email: "", phone: "",
            },
            selectedContactId: props?.selectedContactId,
            isContactEdited: false,
            accountsPermissionArray: [],
            isRebindDropdown: true,
        };
    }

    componentDidMount() {
        this.GetSalesPermission();
    }

    GetSalesPermission = () => {       
        SalesService.GetSalesPermission()
            .then(async (result: any | null) => {               
                if (result != null && result.result.length > 0) {
                    // debugger;
                    let perm: any = result.result;
                     this.setState({ 
                        accountsPermissionArray: perm,
                    }, () => {
                        this.getContactDetails();
                    });
                }
                resolve();
            })
            .catch((err: any) => {
                Utils.toastError("Something went wrong.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "slideout-cont-val-1a",
                });
                reject();
            });
    }

    getContactDetails = () => {
        //API to get Contact Details for Slideout
        let request: any = {};
        request.ContactId = this.state.selectedContactId;
        SalesService.GetContactDetails(request)
            .then(async (result: any | null) => {
                // debugger;
                if (result != null) {
                    this.setState({ accountContactDetails: result.result, accountContactDetailsCopy: result.result, isRebindDropdown: false}, () => {
                        this.setState({ isRebindDropdown: true });
                    });
                }
                resolve();
            })
            .catch((err: any) => {
                Utils.toastError("Something went wrong. Failed to fetch Contact Details.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "slideout-cont-val-2a",
                });
                reject();
            });
    }
    
    onInputFieldChange = (event: any, type: any) => {
        //debugger;
        let errors = Object.assign({}, JSON.parse(JSON.stringify(this.state.errors)));
        let accountContactDetails = Object.assign({}, JSON.parse(JSON.stringify(this.state.accountContactDetails)));
        let value = event.target.value;
        if (type === "contactName") {
            accountContactDetails.name = value?.toString()?.replace(/[^a-zA-Z0-9 ]+/ig, '');
            accountContactDetails.isEdit = "yes";
            errors.name = value.trim() == '' ? 'Name is required.' : '';
        }
        if (type === "contactTitle") {
            accountContactDetails.title = value;
            accountContactDetails.isEdit = "yes";
        }
        if (type === "contactEmail") {
            accountContactDetails.email = value;
            accountContactDetails.isEdit = "yes";
            errors.email = value == '' ? 'Email is required.' : '';
        }
        if (type === "contactPhone") {
            let phone = value?.toString()?.replace(/[^0-9\+]+/gi, "");
            if (phone) {
                if (phone.indexOf("-") >= 0) {
                  phone = phone?.replaceAll("-", "");
                }
        
                if (phone?.toString()?.length > 1) {
                  phone = phone?.toString().substr(0, 1) + phone?.toString()?.substr(1)?.replaceAll("+", "");
                }
            }
            accountContactDetails.phone = phone;
            accountContactDetails.isEdit = "yes";
            errors.phone = (phone == '' && (accountContactDetails.preferredMethod === "Text" || accountContactDetails.preferredMethod === "Phone")) ? 'Phone Number is required when Preferred Contact Method is Phone or Text.' : '';
        }
        if (type === "contactnotes") {
            accountContactDetails.notes = value;
            accountContactDetails.isEdit = "yes";
        }
        this.setState({ accountContactDetails, errors, isContactEdited: true });
    }

    onDropdownChange = (item, type) => {
        let isDropDownChanged = false;
        let errors = Object.assign({}, JSON.parse(JSON.stringify(this.state.errors)));
        let accountContactDetails = Object.assign({}, JSON.parse(JSON.stringify(this.state.accountContactDetails)));
        if (type === "preferredContact") {
            if(accountContactDetails.preferredMethod !== item.name) {
                isDropDownChanged = true;
                accountContactDetails.preferredMethod = item.name;
                accountContactDetails.isEdit = "yes";
                if (accountContactDetails.phone == '' && (item.name === "Text" || item.name === "Phone")) {
                    errors.phone = 'Phone Number is required when Preferred Contact Method is Phone or Text.';
                }
                else if (accountContactDetails.phone == '' && (item.name === "Email")) {
                    errors.phone = '';
                }
            }
        }
        this.setState({ accountContactDetails, errors }, () => {
            if (isDropDownChanged) {
                let formValid = this.isFormValid();
                if(formValid) {
                    this.setState({ accountContactDetailsCopy: accountContactDetails });
                    this.editContactDetails(type);
                }
            }
        });
    }

    isPrimaryContact = (event: any) => {
        let accountContactDetails = Object.assign({}, JSON.parse(JSON.stringify(this.state.accountContactDetails)));
        if (event.target.checked) {
            accountContactDetails.isPrimary = "yes";
        }
        else {
            accountContactDetails.isPrimary = "no";
        }
        this.setState({ accountContactDetails }, () => {
            let formValid = this.isFormValid();
            if(formValid) {
                this.setState({ accountContactDetailsCopy: accountContactDetails });
                this.editContactDetails("isPrimary");
            }
        });
    }

    
    onInputBlur = (event: any, type: any) => {
        // debugger;
        let accountContactDetails = Object.assign({}, JSON.parse(JSON.stringify(this.state.accountContactDetails)));
        let isContactChanged = JSON.stringify(accountContactDetails) !== JSON.stringify(this.state.accountContactDetailsCopy);

        if(isContactChanged) {
            let formValid = this.isFormValid();
            if(formValid) {
                this.setState({ accountContactDetailsCopy: accountContactDetails });
                this.editContactDetails(type);
            }
        }
    }

    editContactDetails = (type) => {
        let request: any = Object.assign({}, JSON.parse(JSON.stringify(this.state.accountContactDetails)));
        SalesService.SaveSalesAccountContact(request)
            .then(async (result: any | null) => {
                // debugger;
                this.setState({ isContactEdited: true, });
                if (result.saveStatus.toLowerCase() === "success") {
                    if (type === "isPrimary") {
                        this.getContactDetails();   // API call to know to updated Status when IsPrimary is changed.
                    }
                    toast.success(result.message,
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            containerId: "SalesAcc1a",
                        });
                } else {
                    Utils.toastError(result.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        containerId: "SalesAcc1b",
                    });
                }
                resolve();
            })
            .catch((error) => {
                this.setState({ isContactEdited: true, });
                Utils.toastError("Something went wrong.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "SalesAcc1",
                });
                reject();
            });
    }

    validatePhone = (event) => {
        let errors = Object.assign({}, JSON.parse(JSON.stringify(this.state.errors)));
        let accountContactDetails = Object.assign({}, JSON.parse(JSON.stringify(this.state.accountContactDetails)));
        let phone = event.target.value;
        let isContactChanged = JSON.stringify(accountContactDetails) !== JSON.stringify(this.state.accountContactDetailsCopy);
        if (!isContactChanged) {
            return;
        }
        if (phone?.toString()?.length === 0 && (accountContactDetails.preferredMethod !== "Text" && accountContactDetails.preferredMethod !== "Phone")) {
            errors.phone = "";
            this.setState({ errors }, () => {
                let formValid = this.isFormValid();
                if(formValid) {
                    this.setState({ accountContactDetailsCopy: accountContactDetails });
                    this.editContactDetails("contactPhone");
                }
            });
            return;
        }
        else if (phone?.toString()?.length === 0 && (accountContactDetails.preferredMethod === "Text" || accountContactDetails.preferredMethod === "Phone")) {
            errors.phone = "Phone Number is required when Preferred Contact Method is Phone or Text.";
            this.setState({ errors, }, () => {
                let formValid = this.isFormValid();
                if(formValid) {
                    this.setState({ accountContactDetailsCopy: accountContactDetails });
                    this.editContactDetails("contactPhone");
                }
            });
            return;
        }
        if (phone?.toString()?.includes("+") && phone?.toString()?.substr(1, 1) !== "1") {
            if (phone?.toString()?.length < 9) {
                errors.phone = "Mobile Phone Number is not valid";
            } else {
                errors.phone = "";
            }
            this.setState({ errors }, () => {
                let formValid = this.isFormValid();
                if(formValid) {
                    this.setState({ accountContactDetailsCopy: accountContactDetails });
                    this.editContactDetails("contactPhone");
                }
            });
            return;
        }
        User.ValidatePhoneNumber(phone).then((response: any) => {
            // debugger;
            if (response?.fakeNumber === "YES") {
                errors.phone = "Mobile Phone Number is not valid";
            } else if (response?.lineType !== "CELL PHONE") {
                errors.phone = response?.lineType && response?.lineType !== "FAKE" ? `${this.CapitalizeFirstLetter(response?.lineType)} Phone Numbers are not supported` : `Mobile Phone Number is not valid`;
            } else {
                errors.phone = "";
            }
            this.setState({ errors }, () => {
                let formValid = this.isFormValid();
                if(formValid) {
                    this.setState({ accountContactDetailsCopy: accountContactDetails });
                    this.editContactDetails("contactPhone");
                }
            });
        });
    }

    isFormValid = () => {
        let errors = Object.assign({}, JSON.parse(JSON.stringify(this.state.errors)));
        let accountContactDetails = Object.assign({}, JSON.parse(JSON.stringify(this.state.accountContactDetails)));

        if (accountContactDetails.name.trim() === "") {
            errors.name = 'Name is required.';
        }
        if (accountContactDetails.email === "") {
            errors.email = 'Email is required.';
        }
        if (accountContactDetails.phone === "" && (accountContactDetails.preferredMethod === "Text" || accountContactDetails.preferredMethod === "Phone")) {
            errors.phone = "Phone Number is required when Preferred Contact Method is Phone or Text.";
        }
        if(accountContactDetails.email?.trim() !== "") {
            const emailRegex =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if(!emailRegex.test(accountContactDetails.email?.toLowerCase()?.trim())) {
                errors.email = 'Please enter a valid email address.';
            }
            else {
                errors.email = ""
            }
        }
        this.setState({ errors });
        // debugger;
        let fields = Object.keys(errors);
        if(fields.length > 0) {
            for(let i = 0; i < fields.length; i++) {
                if(errors[fields[i]] !== "") {
                    Utils.toastError(errors[fields[i]], {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        containerId: "add-contact-validation"
                    });
                    return false;
                }
            }
            return true;
        }
        return false;
    }

    CapitalizeFirstLetter = (string) => {
        if (string) {
            return string?.charAt(0).toUpperCase() + string?.slice(1).toLowerCase();
        } else {
            return string;
        }
    }

    closeSlideout = () => {
        let type = this.state.isContactEdited ? "refresh" : "close";
        this.props.closeSlideout("contactSlideout", type);
    }

    UpdateSalesContactStatus = () => {

        let ContactItem: any = {};
        ContactItem.ContactId = this.state.selectedContactId;

        SalesService.UpdateSalesContactStatus(ContactItem)
            .then(async (result: any | null) => {
                // debugger;
                this.setState({isContactEdited: true });
                if (result.saveStatus.toLowerCase() === "success") {
                    toast.success(result.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        containerId: "SalesAcc1",
                    });
                    this.getContactDetails();
                }
                resolve();
            })
            .catch((error) => {
                Utils.toastError("Something went wrong.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "SalesAcc1",
                });
                reject();
            });
    }
    renderTooltip = (value) => {
        if (value.length > 22) {
            return (
                <Tooltip id={`${value}`}>
                    {value}
                </Tooltip>
            )
        } else {
            return (
                <Tooltip style={{ opacity: 0, }} id={`${value}`}>
                    {value}
                </Tooltip>
            )
        }
    }

    render() {
        const { errors, accountsPermissionArray } = this.state;
        let isViewOnlyAccount = this.props?.selectedAccount?.viewPermission.toLowerCase() === "view" ? true : false;  // Is this a view only account from Accounts Page. view only accounts have view only contact.
        let isCreateAccountPerm = accountsPermissionArray[0]?.createSalesAccount.toLowerCase() === "yes" ? true : false; // same perm for edit/create contact
        let isInactive = this.state.accountContactDetails?.status?.toLowerCase() === "inactive"; // if the contact is inactive then disabled/readonly editing
        return (
            <div className='slideout'>
                <div className='d-flex'>
                    <div className='back-drop'></div>
                    <Container fluid className='body-sec viewer-panel header-sticky'>
                        <div className="page-heading underline d-flex">
                            <div className="align-items-center ttl-sec">
                                <div className="d-flex">
                                    <div className='ttl'><EllipsisWithTooltip placement="bottom">{this.state.accountContactDetailsCopy.name}</EllipsisWithTooltip></div>
                                    <div className={`status 
                                        ${this.state.accountContactDetails?.status.toLowerCase() == "inactive" ? 'grey' : ''}
                                        ${this.state.accountContactDetails?.status.toLowerCase() == "primary" ? 'blue' : ''}
                                    `}
                                    >{this.state.accountContactDetails?.status}</div>
                                </div>
                                <div className="tag-line">Contact</div>
                            </div>
                            <div className="action-group d-flex ml-auto">
                                {(!isViewOnlyAccount && isCreateAccountPerm) &&
                                    <Dropdown
                                        id="more-action"
                                        className="more-action"
                                        alignRight
                                    >
                                        <Dropdown.Toggle
                                            className="btn-outline-primary btn btn-primary btn-ghost more"
                                            id="dropdown-more"
                                        >
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                                <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                                <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                            </svg>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item eventKey={"deactivate"} key={"deactivate"} onClick={() => this.UpdateSalesContactStatus()}>
                                                {this.state.accountContactDetails?.status?.toLowerCase() === "inactive" ? "Activate" : "Deactivate"}</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                }
                                <button type="button" className="btn wht-bg cross" onClick={() => this.closeSlideout()}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                                            fill="#84888C"
                                        />
                                    </svg>
                                </button>

                            </div>
                        </div>
                        <div className={!isViewOnlyAccount && isCreateAccountPerm && !isInactive ? 'body-sec' : 'body-sec control-readonly'}>
                            <Form.Group as={Row} className={errors.name.length > 0 ? "error" : ""}>
                                <Form.Label column sm="2">Name</Form.Label>
                                <Col sm="3">
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={this.renderTooltip(this.state.accountContactDetails.name)}
                                    >
                                        <Form.Control
                                            id="name"
                                            type="text"
                                            name="name"
                                            value={this.state.accountContactDetails.name}
                                            autoComplete="off"
                                            onChange={(event: any) => this.onInputFieldChange(event, "contactName")}
                                            onBlur={(e) => this.onInputBlur(e, "contactName")}
                                            placeholder="Name"
                                            maxLength={100}
                                            readOnly={isViewOnlyAccount || !isCreateAccountPerm || isInactive}
                                        />
                                    </OverlayTrigger>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label column sm="2">Title</Form.Label>
                                <Col sm="3">
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={this.renderTooltip(this.state.accountContactDetails.title)}
                                    >
                                        <Form.Control
                                            id="title"
                                            type="text"
                                            name="title"
                                            value={this.state.accountContactDetails.title}
                                            autoComplete="off"
                                            onChange={(event: any) => this.onInputFieldChange(event, "contactTitle")}
                                            onBlur={(e) => this.onInputBlur(e, "contactTitle")}
                                            placeholder="Title"
                                            maxLength={50}
                                            readOnly={isViewOnlyAccount || !isCreateAccountPerm || isInactive}
                                        />
                                    </OverlayTrigger>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className={errors.email.length > 0 ? "error" : ""}>
                                <Form.Label column sm="2">Email</Form.Label>
                                <Col sm="3">
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={this.renderTooltip(this.state.accountContactDetails.email)}
                                    >
                                        <Form.Control
                                            id="email"
                                            type="text"
                                            name="email"
                                            value={this.state.accountContactDetails.email}
                                            autoComplete="off"
                                            onChange={(event: any) => this.onInputFieldChange(event, "contactEmail")}
                                            onBlur={(e) => this.onInputBlur(e, "contactEmail")}
                                            placeholder="Email"
                                            readOnly={isViewOnlyAccount || !isCreateAccountPerm || isInactive}
                                        />
                                    </OverlayTrigger>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className={errors.phone.length > 0 ? "error" : ""}>
                                <Form.Label column sm="2">Phone Number</Form.Label>
                                <Col sm="3">
                                    <Form.Control
                                        id="phoneNumber"
                                        type="text"
                                        name="phoneNumber"
                                        value={this.state.accountContactDetails.phone}
                                        autoComplete="off"
                                        maxLength={16}
                                        onChange={(event: any) => this.onInputFieldChange(event, "contactPhone")}
                                        onBlur={(e) => this.validatePhone(e)}
                                        placeholder="Phone Number"
                                        readOnly={isViewOnlyAccount || !isCreateAccountPerm || isInactive}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label column sm="2">Preferred Contact Method</Form.Label>
                                <Col sm="3">
                                    {this.state.isRebindDropdown &&
                                        <DropDownSelect
                                            itemList={this.state.preferredContactList}
                                            selectedItem={this.state.accountContactDetails.preferredMethod}
                                            onDropdownChange={this.onDropdownChange}
                                            type="preferredContact"
                                            placeholder="Select Preferred Contact"
                                            isDisabled={isViewOnlyAccount || !isCreateAccountPerm || isInactive}
                                        >
                                        </DropDownSelect>
                                    }
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label column sm="2">Primary Contact</Form.Label>
                                <Col sm="3">
                                    <Form.Check
                                        custom
                                        type="checkbox"
                                        key={'primaryContact'}
                                        id={'primaryContact'}
                                        label=""
                                        checked={this.state.accountContactDetails.isPrimary === "yes"}
                                        onChange={(event: any) => { this.isPrimaryContact(event); }}
                                        disabled={isViewOnlyAccount || !isCreateAccountPerm || isInactive}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label column sm="2">Created By</Form.Label>
                                <Col sm="3">
                                    <Form.Control
                                        id="createdBy"
                                        type="text"
                                        name="createdBy"
                                        value={this.state.accountContactDetails.createdByName}
                                        autoComplete="off"
                                        placeholder="Created By"
                                        readOnly={true}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label column sm="2">Created On</Form.Label>
                                <Col sm="3">
                                    <Form.Control
                                        id="createdOn"
                                        type="text"
                                        name="createdOn"
                                        value={this.state.accountContactDetails.createdDate}
                                        autoComplete="off"
                                        placeholder="Created On"
                                        readOnly={true}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label column sm="2">Notes</Form.Label>
                                <Col sm="5">
                                    <Form.Control 
                                        as="textarea" 
                                        rows={2}
                                        id="notes"
                                        name="notes"
                                        value={this.state.accountContactDetails.notes}
                                        autoComplete="off"
                                        onChange={(event: any) => this.onInputFieldChange(event, "contactnotes")}
                                        onBlur={(e) => this.onInputBlur(e, "contactnotes")}
                                        readOnly={isViewOnlyAccount || !isCreateAccountPerm || isInactive}
                                    />
                                </Col>
                            </Form.Group>
                        </div>
                    </Container>
                </div>
            </div>
        );
    }
}