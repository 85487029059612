import React from "react";
import { Container, Dropdown, Button, Spinner } from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "../Accounting/PayrollImport/additionalPayrollImports.scss"
import { FiChevronDown } from "react-icons/fi";
import OutsideClickHandler from "react-outside-click-handler";
import { Hotel as  HotelService } from "../../Common/Services/Hotel";
import { resolve, reject } from "q";
import { Utils } from "../../Common/Utilis";
import { ToastContainer, toast } from "react-toastify";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export class DownloadInvoices extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {            
            loader: false,
            selectHID: -1,
            selectTenentIdValue: "Select Tenant",
            selectEhidValue: "Select EHID",
            alltenant: [],
            allEhid: [],
            selectedType: "Select Type",
            selectedTypeValue: "",
            allTypes: [],       
        };
    }


    componentDidMount() {
        this.GetAllTenant();
        this.getDownloadInvoiceType();
    }

    downloadInvoices = () => {
        this.setState({ loader: true });
        let request: any = {};
        request.selectedtenantID = this.state.selectTenentIdValue;
        request.HID = this.state.selectHID;
        request.Type = this.state.selectedTypeValue;
        HotelService.DownloadInvoiceFileAttachments(request)
            .then(async (result: any | null) => {
                if (result !== null && result.length > 0) {
                    const zip = new JSZip();
                    await Promise.all(
                        result.map(async (file) => {
                            const response = await fetch(file.fileCode);
                            const blob = await response.blob();
                            zip.file(file.fileName, blob);
                        })
                    );

                    // Generate the zip file and trigger download
                    const zipBlob = await zip.generateAsync({ type: 'blob' });
                    saveAs(zipBlob, this.state.selectEhidValue + "-Invoices.zip");
                    setTimeout(() => {
                        Utils.toastSuccess("Invoices downloaded successfully.", { position: toast.POSITION.BOTTOM_RIGHT });
                      }, 500);
                    this.setState({ loader: false });
                } else {
                    Utils.toastSuccess("No invoices available for this Hotel.", { position: toast.POSITION.BOTTOM_RIGHT });
                    this.setState({ loader: false });
                }
                resolve();
            })
            .catch((error) => {
                Utils.toastError(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
                this.setState({ loader: false });
                reject();
            });
    }

    handleSelectTenent = (eventKey: any) => {
        this.setState({ selectTenentIdValue: eventKey, allEhid: [], selectEhidValue: "Select EHID" }, () => {
            this.getHotelForSelectedTenant(eventKey);
        });

    }


    handleSelectEhid = (eventKey: any) => {
        //debugger;
        let hotelDet = this.state.allEhid.filter(x => x.hotelID.toString() === eventKey);
        let lettercode = hotelDet[0].lettercode

        this.setState({ selectHID: eventKey, selectEhidValue: lettercode});
    }

    handleSelectType = (item: any) => {
        this.setState({ selectedType: item.displayInvoiceType, selectedTypeValue: item.invoiceType});
    }


    GetAllTenant = () => {
        HotelService.GetAllTenant()
            .then(async (result: any | null) => {
                if (result !== null) {
                this.setState({ alltenant: result })
                } else {
                    
                }
            });
    }

    getDownloadInvoiceType = () => {
        HotelService.getDownloadInvoiceType()
            .then(async (result: any | null) => {
                if (result !== null) {
                    this.setState({ allTypes: result })
                } else {

                }
            });
    }

    getHotelForSelectedTenant = (selectedTenant) => {
        HotelService.ALLHotelForSelectedTenant(Number(selectedTenant))
            .then(async (result: any | null) => {
                if (result !== null) {                  
                    this.setState({ allEhid: result });
                } else {

                }
            });
    }

    render() {
        return (
            <div className="additional-payroll-imports">
                <Container fluid className="body-sec">

                    <ToastContainer
                        autoClose={3000}
                        containerId={"downlaodinvoices"}
                        enableMultiContainer
                    />

                    <>
                        <div className="page-heading underline d-flex">
                            <div className="mr-auto d-flex align-items-center">Download Invoices</div>
                        </div>
                        <div className="main-Page">
                            <div className="bdy-sec">
                                <div className="filters d-flex">
                                    <div className="margin-righ-15">
                                        <label className="title">Tenant</label>
                                        <Dropdown onSelect={this.handleSelectTenent}>
                                            <Dropdown.Toggle id="dropdown-tenantID" className="d-flex align-items-center">
                                                <div className="d-flex">
                                                    <div className="select-val"><EllipsisWithTooltip placement="bottom">{this.state.selectTenentIdValue}</EllipsisWithTooltip></div>
                                                    <div className="chevron-down"><FiChevronDown /></div>
                                                </div>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {this.state.alltenant.map(
                                                    (item: any, index: any) => (
                                                        <Dropdown.Item
                                                            className="ellipsis"
                                                            key={item.tenantid}
                                                            eventKey={item.tenantid}
                                                        >
                                                            {item.tenantid}
                                                        </Dropdown.Item>
                                                    )
                                                )}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div className="margin-righ-15">
                                        <label className="title">EHID</label>
                                        <Dropdown onSelect={this.handleSelectEhid}>
                                            <Dropdown.Toggle id="dropdown-ehid" className="d-flex align-items-center">
                                                <div className="d-flex">
                                                    <div className="select-val"><EllipsisWithTooltip placement="bottom">{this.state.selectEhidValue}</EllipsisWithTooltip></div>
                                                    <div className="chevron-down"><FiChevronDown /></div>
                                                </div>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {this.state.allEhid.map(
                                                    (item: any, index: any) => (
                                                        <Dropdown.Item key={item.hotelID} eventKey={item.hotelID}>
                                                            <div className="ehid-item d-flex">
                                                                <div className="keycode">{item.lettercode}</div>
                                                                {/* <div className="keyvalue"><EllipsisWithTooltip placement="bottom">{item.lettercode+''+item.pname}</EllipsisWithTooltip></div> */}
                                                            </div>
                                                        </Dropdown.Item>
                                                    )
                                                )}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div className="margin-righ-15">
                                        <label className="title">Type</label>
                                        <Dropdown>
                                            <Dropdown.Toggle id="dropdown-ehid" className="d-flex align-items-center">
                                                <div className="d-flex">
                                                    <div className="select-val"><EllipsisWithTooltip placement="bottom">{this.state.selectedType}</EllipsisWithTooltip></div>
                                                    <div className="chevron-down"><FiChevronDown /></div>
                                                </div>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {this.state.allTypes.map(
                                                    (item: any, index: any) => (
                                                        <Dropdown.Item key={item.id} eventKey={item.id} onClick={() => this.handleSelectType(item)}>
                                                            <div className="ehid-item d-flex">
                                                                <div className="keycode">{item.displayInvoiceType}</div>
                                                                {/* <div className="keyvalue"><EllipsisWithTooltip placement="bottom">{item.lettercode+''+item.pname}</EllipsisWithTooltip></div> */}
                                                            </div>
                                                        </Dropdown.Item>
                                                    )
                                                )}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>


                                    <div className="margin-righ-15">
                                        <Button className="btn-outline-primary margin-top-34 "
                                            disabled={this.state.selectTenentIdValue === "Select Tenant" || this.state.selectEhidValue === "Select EHID"}
                                            variant="primary" onClick={(e) => { this.downloadInvoices() }}>
                                            Download Invoices
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.loader && (
                            <div className="loader-spinner d-flex justify-content-center align-items-center">
                                <Spinner animation="border" variant="success" />
                            </div>
                        )}
                    </>
                </Container>
            </div>
        );
    }
}