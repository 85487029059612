import * as React from "react";
import {
  Button,
  Container,
  Dropdown,
  Form,
  // Form,
  Modal,
  Tab,
  Tabs,
} from "react-bootstrap";
import { SingleSearchDropdownList } from "../../Common/Components/SingleSearchDropdownList";
import { UserManagement } from "./../../Common/Services/UserManagement";
import { IUserRole } from "../../Common/Contracts/IUserRole";
import _ from "lodash";
import { toast } from "react-toastify";
import { SingleBusinessAccountSelectorGeneralTab } from "./common-components/SingleBusinessAccountSelectorGeneralTab";
import { DropDownList } from "../Reports/common-components/dropdown-list";
import { Utils } from "../../Common/Utilis";
import { confirmAlert } from "react-confirm-alert";
import { IChangeEHIDAccessRequest } from "../../Common/Contracts/IChangeEHIDAccessRequest";
import moment from "moment";
import OutsideClickHandler from "react-outside-click-handler";
import Calendar from "react-calendar";
import Joi from "joi-browser";
export class ChangeEHIDAccessModal extends React.Component<any, any> {

  actionList = [{ label: "Add", value: "Add" }, { label: "Remove", value: "Remove" }];
  private ehidAccessFormSchema = Joi.object({
    ehid: Joi.number()
      .min(1)
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please Select EHID" };
        });
      }),
    action: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please Select Action" };
        });
      }),
    terminationDate: Joi.string().optional().allow("")
  });



  constructor(props: any) {
    super(props);

    this.state = {
      errors: {},
      isValidForm: false,
      userRoles: [],
      inputPosition: 1,
      disableSave: false,
      moduleWithHids:[],
      calenderTerminationDateShow: false,
      isModuleHidLoaded: false,
      //payrollIntegrationHids:[],
      //payrollIntegrationLoading:true,
      ehidAccessForm: {
        ehid: 0,
        terminationDate: '',
        action: 'Add'
      },
      hotelsForActions:[]
    };
  }




  componentDidMount() 
  {
    //this.GetPayrollIntegrationHidsByTenant();   
    this.getModuleWithHids();
    const hotels = this.props.selectedRows.map(x=>x.hiDs?.toString()?.trim()?.replace(/\s/g,"")?.split(','));
    // let actionHotels = [];
   
  const commonHotels = _.intersectionBy(...hotels)?.map(x=>x?.trim());
  // actionHotels= _.flatten(hotels)?.map(x=>x?.trim());
  this.setState({actionHotels:commonHotels});
  }
  

   getModuleWithHids = ()=> {
    UserManagement.GetModuleWithHids().then((result: any)=> {
       this.setState({moduleWithHids:result?.result, isModuleHidLoaded:true});
    });
  }
  allHidsExist(userHids, allHids) {
    for (let i = 0; i < userHids?.length; i++) {
        if (!allHids?.includes(userHids[i])) {
            return false;
        }
    }
    return true;
  }

  //  GetPayrollIntegrationHidsByTenant = () => {
  //   this.setState({payrollIntegrationLoading :true});
  //   UserManagement.GetPayrollIntegrationHidsByTenant().then((data: any[]) => {
  //      const hids= this.props.userGeneralInfo?.hids?.map(x=>x.hotelID)
  //      const IspayrollIntegration=this.allHidsExist(hids,data);
  //     this.setState({payrollIntegrationLoading :false,payrollIntegrationHids:data});
  //   }).catch(()=>{
  //     this.setState({payrollIntegrationLoading :false,payrollIntegrationHids:[]});
  //   });
  // };

  getHotelWisePayrollData = () => {
    // setIsPayrollDataLoaded(false);
    // (userGeneralInfo?.employeeType !== "ExcludefromPayroll"
    //   ? UserManagement.GetHotelWisePayroll(+props?.uniqueno,props?.isNewUserAdded)
    //   : UserManagement.GetHotelWisePayrollForExcludeFromPayroll(
    //       +props?.uniqueno,props?.isNewUserAdded
    //     )
    // ).then((hotelWideTableData) => {
    //   setHotelWideTableData(
    //     hotelWideTableData?.filter((x) => x.status === "Active")
    //   );
    //   setIsPayrollDataLoaded(true);
    // });
  };


 checkIfOnlyAccountingAccess = ()=> {
  let result = false;
  const {moduleWithHids,ehidAccessForm} =this.state;
  if(+ehidAccessForm.ehid>0) {
  
    
   result = moduleWithHids?.filter((item:any)=>   +item?.hotelID === +ehidAccessForm.ehid &&
     item.moduleName?.toString() !== "Accounting" && item.moduleName?.toString() !== "Accounting Services"
     && item.moduleName?.toString() !== "Segmentation"
&& item.moduleName?.toString() !== "Procurement"

    )?.length === 0;
    
  }

  return result;
 }


 checkIfLaborAccess = ()=> {
  let result = false;
  const {moduleWithHids,ehidAccessForm} =this.state;
  if(+ehidAccessForm.ehid>0) {
  
   result = moduleWithHids?.filter((item:any)=>   +item?.hotelID === +ehidAccessForm.ehid &&
     (item.moduleName?.toString() === "Labor Management" || item.moduleName?.toString() === "LaborManagement"
||item.moduleName?.toString() === "Labor")

    )?.length > 0;
    
  }

  return result;
 }


  validateEHIDAccessFormSchema = () => {
    const valid = Joi.validate(
      this.state.ehidAccessForm,
      this.ehidAccessFormSchema,
      {
        abortEarly: false,
      }
    );

    const newErrorObject = {};
    if (valid.error) {
      valid.error.details.forEach((err) => {
        newErrorObject[err.path.join(".")] = err.message;
      });
    }


    if (
      (!this.state.ehidAccessForm?.terminationDate &&
      this.state.ehidAccessForm?.action !== "Add")
      && !this.checkIfOnlyAccountingAccess()
      && this.checkIfLaborAccess() 
    ) {
      newErrorObject["terminationDate"] = "Please select termination date";
    } else {
      if (newErrorObject["terminationDate"]) {
        delete newErrorObject["terminationDate"];
      }
    }

    if (Object.keys(newErrorObject).length === 0) {
      this.setState({ isValidForm: true });
    } else {
      this.setState({ isValidForm: false });
    }
    this.setState({ errors: newErrorObject });
    return newErrorObject;
  };

  validationOnClick = (inputPosition) => {
    if(inputPosition> this.state.inputPosition) {
    this.setState({ inputPosition }, () => this.validateEHIDAccessFormSchema());
    }
  };

  getFieldError = (fieldName: string, fieldPosition: number) => {
    const { errors, inputPosition } = this.state;
    return (
      <>
        {Object.keys(errors).length > 0 && fieldPosition <= inputPosition && (
          <span className="validation-message">{errors[fieldName]}</span>
        )}
      </>
    );
  };


  calenderTerminationDateHide = () => {
    this.setState({ calenderTerminationDateShow: false });
  };

  calenderTerminationDateDisplay = () => {
    this.setState({ calenderTerminationDateShow: true });

  };

  changeEHIDAccess = () => {
    this.setState({ disableSave: true });
    const { ehidAccessForm } = this.state;
    const { userNames } = this.props;
    
    const request: IChangeEHIDAccessRequest = {
      tenantId: 0,
      ehid: ehidAccessForm.ehid,
      terminationDate: ehidAccessForm?.terminationDate,
      isRemove: ehidAccessForm.action === 'Remove' ? true : false,
      changeEHIDUsers: userNames?.map(username => ({ username: username }))
    };

    UserManagement.ChangeEHIDAccess(request).then((response: any) => {
      this.setState({ disableSave: false });
      if (response?.message === 'success') {
        if(userNames?.length > 1){
          if(response.result.result?.length >= 1){
            this.setState({showWarningMessage:true});
          }else{
            toast.success("EHID Access changed successfully", {
              position: toast.POSITION.BOTTOM_RIGHT,
             // containerId: "scheduleMain",
           });
           this.props.hideEHIDModal()
           this.props.getUserList();
          }
        }else{
          if(response.result.result?.length == 1){
            Utils.toastError("Prior to making this change, please ensure that all entities are covered by a Password Assistance Role or user has atleast 1 active EHID.", {
              position: toast.POSITION.BOTTOM_RIGHT,
             // containerId: "scheduleMain",
           });
          }else{
            toast.success("EHID Access changed successfully", {
              position: toast.POSITION.BOTTOM_RIGHT,
             // containerId: "scheduleMain",
           });
           this.props.hideEHIDModal()
           this.props.getUserList();
          }
          
        } 
        
        
      } else {
        toast.success(response.result?.result, {
           position: toast.POSITION.BOTTOM_RIGHT,
          // containerId: "scheduleMain",
        });
      }
    }).catch((error) => {
      this.setState({ disableSave: false });
    })
  };

 onHideAllModal = ()=>{
  this.props.hideEHIDModal()
  this.props.getUserList();
  this.setState({showWarningMessage:false});
 }

  onFieldChange = (event, inputPosition) => {
    const { ehidAccessForm } = this.state;
    
    let value = event.target.value;
    const fieldName = event.target.name;
    let commonHotels
    if(fieldName ==="action") {
      const hotels =  this.props.selectedRows.map(x=>x.hiDs?.toString()?.trim()?.replace(/\s/g,"")?.split(','));
      // let actionHotels = [];
      if(value==="Remove") {
        ehidAccessForm["ehid"] =0;
        commonHotels= _.flatten(hotels)?.map(x=>x?.trim());
      }else {
    // actionHotels= _.flatten(hotels)?.map(x=>x?.trim());
   commonHotels = _.intersectionBy(...hotels)?.map(x=>x?.trim());

      }
      this.setState({actionHotels:commonHotels})
    }

    ehidAccessForm[fieldName] = value;
    if(inputPosition> this.state.inputPosition) 
    {
      this.setState({inputPosition});
    }
    this.setState({ ehidAccessForm }, () => {
      this.validateEHIDAccessFormSchema();
    });


  };

  showConfirmationOnSubmit = () => {
    confirmAlert({
      title: "Are you ready to make changes?",
      message:
        `This will ${this.state.ehidAccessForm?.action} EHID access for selected users.`,
      buttons: [
        {
          label: "Yes",
          onClick: () => this.changeEHIDAccess(),
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  showErrorMessage = (errormessage) => {
    confirmAlert({
      title: "Result",
      message:errormessage,
      buttons: [
        {
          label: "Ok",
          onClick: () => { this.onHideAllModal() },
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };


  render() {
    const { calenderTerminationDateShow,
      ehidAccessForm: {
        ehid,
        action,
        terminationDate
      },
      isValidForm,
      errors,
      inputPosition,isModuleHidLoaded } = this.state;
    return (
      <div> {isModuleHidLoaded &&
        <>
          <Modal
            className="change-ehid-access-warning-modal"
            show={this.state.showWarningMessage}
            onHide={() => this.onHideAllModal()}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Warning</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-section">
                <div className="body-section">
                  <div className="description">
                    One or more users could not be updated as they fall into one or more of the below mentioned categories
                    <ul>
                      <li>Have access to only 1 EHID</li>
                      <li>Termination date is earlier than the last actual punch or PTO</li>
                      <li>Termination date is earlier than the last pay period exported date</li>
                      <li>Is the last user in the EHID as Password Assitance User</li>
                    </ul>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="reset-password-btn"
                onClick={(e) => this.onHideAllModal()}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>



        <Modal
          className="change-ehid-access-modal"
          show={this.props.showModal}
          onHide={() => this.props.hideEHIDModal()}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Change EHID Access</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-section">
              <div className="body-section">

               

                <Form.Group className={`${errors?.action && inputPosition >= 2
                  ? "validation-error"
                  : ""
                  }`}
                  onBlur={() => this.validationOnClick(2)}>
                  <div className="account-selector">
                    <Form.Label>Action</Form.Label>
                    <DropDownList
                      defaultValue={action}
                      data={this.actionList}
                      isSearchRequired={false}
                      label={"label"}
                      value={"value"}
                      onDropDownChange={(item) => {
                        if (!item) {
                          return;
                        }
                        this.onFieldChange(
                          Utils.BuildCustomEventObject(
                            "action",
                            item.value
                          ),
                          2
                        );
                      }}
                      selectedItem={this.actionList.find(
                        (r) => r.value === action
                      )
                      }
                    />
                    </div>
                    {this.getFieldError("action", 2)}
                </Form.Group>
                  <Form.Group className={`${errors?.ehid && inputPosition >= 1
                  ? "validation-error"
                  : ""
                  }`}
                  onBlur={() => this.validationOnClick(1)}>
                  <Form.Label>EHID</Form.Label>
                  <div className="account-selector">
                    <SingleBusinessAccountSelectorGeneralTab
                      defaultValue={ehid}
                      payrollIntegrationHids={this.props.payrollIntegrationHids}
                      notIncludeEnterpriseAccounts={false}
                      shouldGetAllActiveHotelsOfTenant={true}
                      custom
                      selectedHids={this.state.actionHotels}
                      userAction={action}
                      handleBusinessAccountChange={(item) => {
                        this.onFieldChange(
                          Utils.BuildCustomEventObject("ehid", item?.hotelID),
                          1
                        );
                      }}
                    />
                  </div>
                  {this.getFieldError("ehid", 1)}
                </Form.Group> 

              {action === 'Remove'  && !this.checkIfOnlyAccountingAccess() &&
              this.checkIfLaborAccess() &&
                <Form.Group
                  controlId="date-selection"
                  className={`d-flex flex-column date-picker ${errors?.terminationDate && inputPosition >= 3
                    ? "validation-error"
                    : ""
                    }`}
                    onBlur={() => this.validationOnClick(3)}>



                  <Form.Label>Termination Date</Form.Label>

                  <div className="validate-input d-flex flex-column">
                    <div className="calender-container">
                      <div
                        className={
                          calenderTerminationDateShow
                            ? "calender-display open"
                            : "calender-display"
                        }
                        onClick={this.calenderTerminationDateDisplay}
                      >
                        <input
                          type="text"
                          placeholder="MM/DD/YY"
                          name="terminationDate"
                          autoComplete="terminationDate"
                          value={terminationDate}
                          className="form-control single-date-pick"
                        />
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z" />
                          <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z" />
                        </svg>
                      </div>
                      {calenderTerminationDateShow && (
                        <div className="calender">
                          <OutsideClickHandler
                            onOutsideClick={this.calenderTerminationDateHide}
                          >
                            <Calendar
                              calendarType={"US"}
                              maxDate={new Date()}
                              onChange={(date) => {
                                const value = moment(date).format("MM/DD/YY");
                                this.onFieldChange(
                                  Utils.BuildCustomEventObject("terminationDate", value),
                                  3
                                );
                                this.calenderTerminationDateHide();
                              }}
                              value={
                                terminationDate
                                  ? new Date(terminationDate)
                                  : new Date()
                              }
                            />
                          </OutsideClickHandler>
                        </div>
                      )}
                    </div>
                  </div>
                  {this.getFieldError("terminationDate", 3)}
                </Form.Group>
              }
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={!isValidForm || this.state.disableSave}
            className="reset-password-btn"
            onClick={(e) => this.showConfirmationOnSubmit()}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      </>
  }
      </div >
    );
  }
}
