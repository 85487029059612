import React from "react";
import { Form } from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import OutsideClickHandler from "react-outside-click-handler";

export class SearchMultiSelectItems extends React.Component<any, any> {
  private inputSearch: any;
  constructor(props: any) {
    super(props);
    this.inputSearch = React.createRef();
    this.state = {
      // selectedItems: props.selectedItems,
      selectedItems: [],
      duplicateArray: [],
      filterData: [],
      opened: false,
      isSelect: true,
      inputValue: "",
      isSelectAll: true,
      itemsList: props.itemsList,
    };

    this.getValue = this.getValue.bind(this);
  }

  componentDidMount() {
    let arrayList: any[] = [];
    if (this.props.selectedItems != null) {
      arrayList = this.props.selectedItems.split(","); //Number(arrayList[0])
      let data = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.props.itemsList))
      );
      data.map((val: any) => {
        for (let i = 0; i < arrayList.length; i++) {
          if (Number(arrayList[i]) === val.value) {
            val.isChecked = true;
          }
        }
      });

      let selectedFilter = data.filter((e) => e.isChecked === true);
      this.setState({ itemsList: data, selectedItems: selectedFilter });
      this.setState(
        {
          duplicateArray: data,
          opened: true,

          filterData: [],
          inputValue: "",
        },
        () => {
          this.inputSearch.current.focus();
        }
      );
      if (data.length === selectedFilter.length) {
        this.setState({ isSelect: false });
      }
    }
  }

  updateLists = () => {
    let { filterData, duplicateArray } = this.state;
    if (filterData.length === 0 && this.state.inputValue === "") {
      this.setState({ itemsList: duplicateArray });
    }
  };

  openDropdown = () => {

  };

  handleChange = (e: any) => {
    const updatedList = this.state.duplicateArray.filter((item: any) => {
      return item.text.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1;
    });

    this.setState(
      { itemsList: updatedList, inputValue: e.target.value },
      () => {
        // this.updateLists();
      }
    );
  };

  handleCheckCheckbox = (idx: any, e: any) => {
    const itemsList = [...this.state.itemsList];
    const selectedItems = [...this.state.selectedItems];
    let dataLen = itemsList.filter((items) => !items.isChecked);
    if (dataLen.length > 1) {
      this.setState({ isSelect: true });
    } else {
      this.setState({ isSelect: false });
    }
    const index = itemsList.findIndex((xvalue) => xvalue.value === idx);
    itemsList[index].isChecked = e.target.checked;
    idx = index;
    //this.setState({ itemsList: [...this.state.itemsList, itemsList] });
    if (e.target.checked === true) {
      const item = {
        value: itemsList[idx].value,
        text: itemsList[idx].text,
        isChecked: e.target.checked,
      };
      this.setState(
        {
          selectedItems: [...this.state.selectedItems, item],
        },
        () => {
          if (this.state.itemsList.length === this.state.selectedItems.length) {
            this.setState({ isSelect: false });
          }
          //console.log("2::", this.state.itemsList);
          this.props.itemsSelected(this.state.selectedItems);
        }
      );
    } else {
      const removeIndex = selectedItems.findIndex(
        (xvalue) =>
          xvalue.text.toLowerCase().trim() ===
          itemsList[index].text.toLowerCase().trim()
      );
      selectedItems.splice(removeIndex, 1);

      this.setState(
        // { selectedItems: [...this.state.selectedItems, selectedItems] },
         { selectedItems: [] },
         () => {
           if (this.state.itemsList.length !== selectedItems.length) {
             this.setState({ isSelect: true,selectedItems: selectedItems });
           }
           this.props.itemsSelected(selectedItems);
         }
       );
    }
  };

  getIndex = (val: any, type: string) => {
    if (type === "removeChip") {
      let itemsList = [...this.state.itemsList];
      return itemsList.findIndex((obj) => obj.value === val);
    } else {
      let selectedItems = [...this.state.selectedItems];
      return selectedItems.findIndex((obj) => obj.value === val);
    }
  };

  handleSelectAll = () => {
    let allItemList = [...this.state.itemsList];
    allItemList.forEach((elem) => (elem.isChecked = true));
    this.setState(
      {
        itemsList: allItemList,
        isSelect: false,
        selectedItems: allItemList,
      },
      () => {
        this.props.itemsSelected(allItemList);
      }
    );
  };

  handleClearAll = () => {
    let allItemList = [...this.state.itemsList];
    allItemList.forEach((elem) => (elem.isChecked = false));
    this.setState(
      { itemsList: allItemList, isSelect: true, selectedItems: [] },
      () => {
        this.props.itemsSelected([]);
      }
    );
  };

  getValue() {
    this.setState({ opened: false, inputValue: "" });
    return this.props.value;
  }

  render() {
    const { value, onUpdate, items, ...rest } = this.props;
    return (
      <div
        className={
          this.state.opened ? "multiSelectSearch opened" : "multiSelectSearch"
        }
      >
        <div className="fieldArea">
          {value}
          <OutsideClickHandler
            key="outsideclick"
            onOutsideClick={() => onUpdate(this.getValue())}
          >
            <div className="coaSearch">
              <div className="dropDownPanel">
                <div className="SearchInput" onClick={this.openDropdown}>
                  <input
                    ref={this.inputSearch}
                    key={"text-Filter"}
                    type="text"
                    className="form-control"
                    onChange={this.handleChange}
                    placeholder={this.props.placeholder}
                    value={this.state.inputValue}
                  />
                </div>
                <ul>
                  {this.state.itemsList.length > 0 && (
                    <div>
                      {this.state.itemsList.map((item: any, idx: any) => (
                        <li id={idx} key={idx}>
                          <EllipsisWithTooltip placement="bottom">
                            <Form.Check
                              custom
                              type="checkbox"
                              key={"custom-" + idx}
                              id={"custom-" + idx}
                              label={item.text}
                              checked={item.isChecked ? true : false}
                              onChange={(e: any) =>
                                this.handleCheckCheckbox(item.value, e)
                              }
                            />
                          </EllipsisWithTooltip>
                        </li>
                      ))}
                    </div>
                  )}
                </ul>
                {this.state.isSelectAll && this.state.itemsList.length > 1 && (
                  <div className="footer">
                    {this.state.isSelect && (
                      <button id="selectAll" type="button" className="btn wht-bg txt-green" onClick={this.handleSelectAll}>{" "}Select All{" "}</button>
                    )}
                    {!this.state.isSelect && (
                      <button id="ClearAll" type="button" className="btn wht-bg txt-green" onClick={this.handleClearAll}>{" "}Clear All{" "}</button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </OutsideClickHandler>
        </div>
      </div>
    );
  }
}
