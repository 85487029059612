import React, { useRef, useState } from "react";
import {
  Button,
  Row,
  Col,
  Form,
  Tab,
  Tabs,
  Modal,
  Nav,
  FormGroup,
} from "react-bootstrap";
import { IReportParameter } from "../../Common/Contracts/IReportParameter";
import { toast, ToastContainer } from "react-toastify";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { BusinessAccountSelector } from "./BusinessAccountSelector";
import { ReportGroupSelector } from "./ReportGroupSelector";
import { YearSelector } from "./YearSelector";
import { LaborEmployeeSelector } from "./LaborEmployeeSelector";
import { EmployementTypeSelector } from "./EmployementTypeSelector";
import { LookBackPeriodSelector } from "./LookBackPeriodSelector";
import { ReportTypeSelector } from "./ReportTypeSelector";
import { TotalFormat } from "./TotalFormat";
import EmployeeAutoSelector from "./EmployeeAutoSelector";
import { ACACodeSelector } from "./ACACodeSelector";
import { DepartmentSelector } from "./DepartmentSelector";
import { SingleAllDepartmentSelect } from "../../Common/Components/SingleAllDepartmentSelect";
import { LaborSummarySubTypeSelector } from "./LaborSummarySubTypeSelector";
import { AllHotelBrandSelector } from "../../Common/Services/ReportGroup";
import { SelectAccount } from "../Reports/SelectAccount";
import { ModuleReports } from "../../Common/Services/ModuleReports";
import { IParameterSelectionState } from "./models/ParameterSelectionState";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { ReactPageLoader } from "../../Common/Components/ReactPageLoader";
// import moment from "moment";
import moment from "moment-timezone";
import { Schedule } from "../../Common/Services/Schedule";
import { LaborMultiEmployeeSelector } from "./LaborMultiEmployeeSelector";
import { LaborResponseStatus } from "./LaborResponseStatus";
import { AccountingCOAType } from "./AccountingCOAType";
import { COASelector } from "./COASelector";
import { AccountTOSelector } from "./AccountTOSelector";
import { SelectSingleCOA } from "./SelectSingleCOA";
import { DateLayOutSelector } from "./DateLayOutSelector";
import { ReportGroupBrandSelector } from "./ReportGroupBrandSelector";
import { DataSourceOperating } from "./DataSourceOperating";
import { DataSourceSelector } from "./DataSourceSelector";
import { RowTittle } from "./RowTittle";
import { MultiTabls } from "./MultiTabls";
import { AccotingPeriodSector } from "./AccotingPeriodSector";
import { DDLReportType } from "./DDLReportType";
import { DataSource2Selectore } from "./DataSource2Selectore";
import { AccountingPeriodEndSector } from "./AccountingPeriodEndSector";
import { PeriorYearSelect } from "./PeriorYearSelect";
import { BankAccountSelector } from "./BankAccountSelector";
import { PaymentRegisterSelector } from "./PaymentRegisterSelector";
import { DataSource1Periods } from "./DataSource1Periods";
import { DataSource2Periods } from "./DataSource2Periods";
import { AgingSelector } from "./AgingSelector";
import { DesignLayOut } from "./DesignLayOut";
import { DisputeStatus } from "./DisputeStatus";
import { Utils } from "../../Common/Utilis";
import { StatusSelector } from "./StatusSelector";
export const ReportParameterSelectionForSchedule: React.FC<any> = (
  props: any
) => {
  let htmlControls: any[] = [];
  let reportInputParameters: any[] = [];

  // const parentDivRef = React.createRef();
  const [reportParameters, setReportParameters] = React.useState<
    IReportParameter[]
  >([]);
  const [visibleHtmlControls, setVisibleHtmlControls] = React.useState<any[]>(
    []
  );
  const [state, setCompState] = React.useState<IParameterSelectionState>({
    isAcaCodes: true,
    status: "1",
    chkCompareActuals: 0,
    reportType: "",
  } as IParameterSelectionState);
  const employeeDDLRef: any = useRef(null);
  const [laborSummaryTypeTooltip, setLaborSummaryTypeTooltip] =
    React.useState("");
  const [group, setgroups] = React.useState();
  const buisnessAccountRef = useRef(null);
  const [reportData, setReportData] = React.useState(props.reportData);
  const [dataFetched, SetDataFetched] = React.useState(false);
  const [chkSummSec, setChkSummSec] = React.useState(true);
  const activeCheckBox: any = React.createRef();
  const includeAllEmpCheckBox: any = React.createRef();

  //======================================================Default Load Page============================================================
  React.useEffect(() => {
    runDefaultForgroup();
    getCurrentUser();
  }, []);

  React.useEffect(() => {
    IsPageBreakData();
  }, [state?.isPageBreak]);

  React.useEffect(() => {
    let _htmlControls: any[] = [];
    ModuleReports.GetReportInputParamters(props.reportData.moduleReportId).then(
      (parameters) => {
        SetDataFetched(true);
        if (parameters[0]?.paramName) {
          reportInputParameters = parameters;
          setSavedReportParameter();

          buildFilterControls(state);
          setCompState(state);
          _htmlControls = htmlControls
            .map((r) => {
              if (controlshouldBeVisible(r?.controlName)) {
                const control = getControl(r?.controlName);
                r.displayOrder = control?.displayOrder;
                r.isRequired = control?.isRequired;
                r.isVisible = true;
                r.isSameDateRangeYear = control?.isSameDateRangeYear;
              }
              return r;
            })
            ?.filter((x) => x?.isVisible);

          if (_htmlControls.length > 1) {
            _htmlControls = _htmlControls?.sort(
              (a, b) => a?.displayOrder - b?.displayOrder
            );
          }

          setVisibleHtmlControls(_htmlControls);
          const tabindexDivs = document?.querySelectorAll('[role="dialog"]');
          if (
            tabindexDivs?.length > 0 &&
            _htmlControls?.filter(
              (r) =>
                r?.controlName === "txtDateRange" ||
                r?.controlName === "txtDate"
            )?.length > 0
          ) {
            if (tabindexDivs?.length > 1) {
              document
                .querySelectorAll('[role="dialog"]')[1]
                .removeAttribute("tabIndex");
            } else {
              document
                ?.querySelectorAll('[role="dialog"]')[0]
                ?.removeAttribute("tabIndex");
            }
          }
        } else {
          const e: any = {};
          assignReportParameters(e);
          return;
        }
      }
    );
  }, []);

  React.useEffect(() => {
    if (props.callParamValidation) {
      assignReportParameters(null);
      props.saveReportWithParameters();
    }
  }, props.callParamValidation);

  const IsPageBreakData = () => {
    debugger;
    let currentReportParameters = JSON.parse(
      JSON.stringify(localStorage.getItem("schedulereportParameters"))
    );
    currentReportParameters = JSON.parse(currentReportParameters);
    const isPageBreak = currentReportParameters?.find(
      (p) => p.name == "IsPageBreak"
    )?.value;

    if (state?.isPageBreak !== null || isPageBreak) {
      let IsPageBreak;
      let type;
      if (state?.isPageBreak === true || isPageBreak === "1") {
        if (isPageBreak === "1") {
          state.isPageBreak = true;
          updateHtmlControlState(state);
        }
        type = "IsPageBreak";
        IsPageBreak = "1";
      } else {
        type = "IsPageBreak";
        IsPageBreak = "0";
      }
      setNewParameterValue(type, IsPageBreak);
    }
    let ExportType;
    let type;
    if (state?.isPageBreak === true) {
      type = "ExportType";
      ExportType = "PDF";
    } else {
      type = "ExportType";
      ExportType = "View";
    }
    setNewParameterValue(type, ExportType);
  };

  const getCurrentUser = () => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let username = storage === null ? 1 : (storage.userName as any);
    let type = "userName";
    setNewParameterValue(type, username);
  };

  const runDefaultForgroup = () => {
    if (group == null) {
      let type = "Type";
      let rGroupid = "0";
      let Validation: any = rGroupid;
      setgroups(Validation);
      setNewParameterValue(type, rGroupid);
    }
  };
  //============================Run Reports on Run=========================================================================

  const assignReportParameters = (event) => {
    const validationMessage = reportParametersValidationMessage();
    if (validationMessage) {
      event?.preventDefault();
      Utils.toastError(validationMessage, {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "reportSelection",
      });
      return;
    }

    setNewParameterValue(
      "tenantId",
      JSON.parse(localStorage.getItem("storage")!).tenantID
    );
    setNewParameterValue("reportId", reportData.moduleReportId);
    let currentReportParameters = JSON.parse(
      JSON.stringify(localStorage.getItem("schedulereportParameters"))
    );
    currentReportParameters = JSON.parse(currentReportParameters);
    const jsonReportParameters = JSON.stringify(currentReportParameters);
    localStorage.setItem("schedulereportParameters", jsonReportParameters);
    return jsonReportParameters;
  };

  const isRequiredControl = (controlName: string): boolean => {
    const control = visibleHtmlControls?.find(
      (r) => r?.controlName?.toLowerCase() === controlName?.toLocaleLowerCase()
    );
    return control?.isRequired ? true : false;
  };

  const reportParametersValidationMessage = (): string => {
    let message = "";
    if (visibleHtmlControls?.length === 0) {
      return message;
    }

    if (
      isRequiredControl("chkStatus") &&
      state.reportType !== "ByEmployee" &&
      +state.status <= 0
    ) {
      return (message = "Please select status");
    }
    if (isRequiredControl("ddlReportType") && !state.reportType) {
      return (message = "Please select Report Type.");
    }

    if (
      isRequiredControl("txtAutoEmployee") &&
      !state.employee &&
      (state.reportType === "ByEmployee" ||
        !state.reportType ||
        isRequiredControl("ddlPunchHistoryReportType"))
    ) {
      return (message = "Please enter Employee Name.");
    }
    if (isRequiredControl("ddlAccountingYears") && !state.accountingYear) {
      return (message = "Please select Year");
    }
    if (isRequiredControl("ddlEmployee") && !state.employee) {
      return (message = "Please select Employee");
    }

    if (isRequiredControl("chkStatus") && reportParameters?.length <= 0) {
      return (message = "Please select at least one parameter.");
    }
    if (
      isRequiredControl("ddlReportGroup") &&
      state.groupSelectValue !== "0" &&
      (state.reportType !== "ByEmployee" || !state.reportType)
    ) {
      if (!state.hotelIds?.toString()?.trim()) {
        return (message = "Please Select Hotel Account.");
      }
    }

    if (isRequiredControl("ddlLookBackPeriod") && !state.lookBackPeriod) {
      return (message = "Please select Lookback Period.");
    }

    if (isRequiredControl("ddlEmployementType") && !state.employementType) {
      return (message = "Please select Employement Type.");
    }

    if (isRequiredControl("ddlEmployementType") && !state.employementType) {
      return (message = "Please select Employement Type.");
    }

    if (isRequiredControl("ddlACACoverageCodes") && !state.acaCoverageCodes) {
      return (message = "Please select ACA Coverage Codes.");
    }
    if (isRequiredControl("ddlACAHarborCodes") && !state.acaACASHarborCodes) {
      return (message = "Please select ACA Harbor Codes.");
    }

    if (isRequiredControl("ddlDataSource") && !state.IsdataSoureSelect) {
      return (message = `Please select DataSource 1.`);
    }

    if (isRequiredControl("ddlDataSource") && state.dataSourceforPanL >= 4) {
      return (message = `Data source 1 can not have more than 3 values selected at a time.`);
    }

    if (
      (isRequiredControl("ddlDepartmentMulti") ||
        isRequiredControl("ddlDepartmentSingle")) &&
      !state.departmentIds?.toString()?.trim()
    ) {
      return (message = "Please select Department.");
    }
    if (
      isRequiredControl("chkIncludeEnterpriseEmployees") &&
      !state.isIncludeEnterpriseEmployees
    ) {
      return (message = "Please Include Enterprise Empolyees.");
    }
    if (isRequiredControl("chkPageBreak") && !state.isPageBreak) {
      return (message = "Please Select Page Break.");
    }

    if (isRequiredControl("ddlHoursFormat") && !state.hoursFormat) {
      return (message = "Please Select Hours Format.");
    }

    if (
      isRequiredControl("ddlPunchHistoryReportType") &&
      !state.punchHistoryType
    ) {
      return (message = "Please Select Punch History Type.");
    }

    if (isRequiredControl("ddlReportType") && !state.reportType) {
      return (message = "Please Select Report Type.");
    }

    if (
      (isRequiredControl("ddlSubReportGroupMulti") ||
        isRequiredControl("ddlSubReportGroupSingle")) &&
      !state.subGroupSelectValue?.toString()?.trim()
    ) {
      return (message = "Please select Sub-Group.");
    }

    if (
      isRequiredControl("txtAvgHoursWorked") &&
      !state.avgHours?.toString()?.trim()
    ) {
      return (message = "Please Enter Average Hours Worked.");
    }

    if (
      isRequiredControl("txtHireDate") &&
      !state.hireDate?.toString()?.trim()
    ) {
      return (message = "Please Enter Hire Days.");
    }

    return message;
  };

  const updateEmployeeDDL = () => {
    if (employeeDDLRef?.current) {
      if (typeof employeeDDLRef?.current["updateEmployeeDDL"] === "function") {
        employeeDDLRef?.current?.updateEmployeeDDL(state.hotelIds);
      }
    }
  };

  const setSavedReportParameter = (): void => {
    if (reportData.reportParameter) {
      if (reportData.reportParameter !== "") {
        let savedParameter = JSON.parse(reportData.reportParameter);
        savedParameter?.forEach((element) => {
          setNewParameterValue(element.name, element.value);
          switch (element?.name) {
            case "Status":
              state.status = element?.value ? element?.value : 1;
              break;
            case "Year":
              state.accountingYear = element?.value;
              break;
            case "ReportType":
              state.reportType = element?.value;
              break;
            case "StartDate":
              state.startDate = element?.value ? element?.value : new Date();
              break;
            case "EndDate":
              state.endDate = element?.value ? element?.value : new Date();
              break;
            case "Hotelid":
              state.hotelIds = element?.value;
              break;
            case "HotelBrand":
              state.subGroupSelectValue = element?.value;
              break;
            case "SubGroupMulti":
              state.subGroupSelectValue = element?.value;
              break;
            case "ACACoverageCode":
              state.acaCoverageCodes = element?.value;
              break;
            case "DataSource1":
              state.IsdataSoureSelect = element?.value;
              break;
            case "DataSource2":
              state.IsdataSoureSelect2 = element?.value;
              break;
            case "ACASaferCode":
              state.acaACASHarborCodes = element?.value;
              break;
            case "AvgHrsWorked":
              state.avgHours = element?.value;
              break;
            case "HireDate":
              state.hireDate = element?.value;
              break;
            case "FileSize":
              state.fileSize = element?.value;
              break;
            case "IsSalaried":
              state.IsSalaried = element?.value === "Yes" ? true : false;
              break;
            case "isPosition":
              state.laborSummarySubType = element?.value;
              break;
            case "IsExcludePayroll":
              state.IsExcludePayroll = element?.value === "Yes" ? true : false;
              break;
            case "IsVariance":
              state.IsVariance = element?.value === "Yes" ? true : false;
              break;

            case "IsDollars":
              state.IsDollars = element?.value === "Yes" ? true : false;
              break;
            case "ActualCompared":
              state.chkCompareActuals = +element?.value;
              break;
            case "ChkSummSec":
              state.isAcaCodes = element?.value === "Yes" ? true : false;
              break;
            case "ChkIsCompSet":
              state.isCompSet = element?.value === "Yes" ? true : false;
              break;

            case "ChkIncludeBudget":
              state.isIncludeBudget = element?.value === "Yes" ? true : false;
              break;
            case "ChkHideZeroActualValues":
              state.isZeroActualValues =
                element?.value === "Yes" ? true : false;
              break;
            case "ChkCheckCOA":
              state.IsCheckCOA = element?.value === "Yes" ? true : false;
              break;
            case "ChkHideStatistics":
              state.IsHideStatistics = element?.value === "Yes" ? true : false;
              break;
            case "ChkUnapprovedtransactions":
              state.IsUnapprovedtransactions =
                element?.value === "Yes" ? true : false;
              break;
            case "IsPageBreak":
              state.isPageBreak = +element?.value === 1 ? true : false;
              break;
            case "IsExclude":
              state.isIncludeEnterpriseEmployees =
                element?.value === "Yes" ? true : false;
              break;
            case "GroupName":
              state.groupSelectValue = element?.value;
              break;
            case "EmpType":
              state.employementType = element?.value;
              break;
            case "LookBack":
              state.lookBackPeriod = element?.value;
              break;
            case "Employee":
              state.employee = element?.value;
              break;
            case "timeFormat":
              state.hoursFormat = element?.value;
              break;
            case "deptID":
              state.departmentIds = element?.value;
              break;
            case "userUniquenId":
              state.userUniquenIds = element?.value;
              break;
            case "response8Status":
              state.responseStatus = element?.value;
              break;
            case "dateLayOutSelector":
              state.dateLayOutSelector = element?.value || 0;
              break;
            case "ReportName":
              state.ReportName = element?.value;
              break;
            case "BankAccount":
              state.BankAccount = element?.value;
              break;
            case "Aging":
              state.IsAgingSelect = element?.value;
              break;
            case "CalculatedStatus":
              state.CalculatedStatus = element?.value;
              break;
            case "LayoutDesign":
              state.TwelveAccountPeriod = element?.value;
              break;
            case "AccountingPeriodFrom":
              state.lastActiveDate = element?.value;
              break;
            case "AccountingPeriodThrough":
              state.accountsPeriodSelectStartEnd = element?.value;
              state.serverDateTime = element?.value;
              break;
            case "DisputeStatus":
              state.disputueStatus = element?.value;
              break;
            case "PriorYear":
              state.defaultYear = element?.value;
              break;
            case "StatusString":
              state.statusString = element?.value;
              break;

            default:
              break;
          }
        });
      }
    }
    if (!state.subGroupSelectValue && !state.groupSelectValue) {
      state.subGroupSelectValue = "Select Group";
    }

    if (!state.subGroupSelectValue && state.groupSelectValue === "All") {
      state.subGroupSelectValue = "All";
    }
    // return savedParameters;
  };

  const handleParameterChange = (target, statusValue) => {
    setStatusValue(statusValue);
    setNewParameterValue(target.name, target.value);
    props.onParameterChange();
  };
  const handleCompareActualChange = (paramName, value) => {
    state.chkCompareActuals = +value;
    setNewParameterValue(paramName, value);
    updateHtmlControlState(state);
    props.onParameterChange();
  };

  const setStatusValue = (statusValue) => {
    state.status = statusValue;
    updateHtmlControlState(state);
    setNewParameterValue("Status", statusValue);
  };

  const handleSelectAllSingleDepartments = (value: any) => {
    state.departmentIds = value;
    updateHtmlControlState(state);
    let setDepartmentid = value;
    let type = "deptID";
    setNewParameterValue(type, setDepartmentid);
    props.onParameterChange();
  };

  const handleParameterIsPagebreakIsCheck = (event) => {
    let checked = event.target.checked;
    const isPageBreak = checked ? "1" : "0";
    const exportType = checked ? "pdf" : "View";
    state.isPageBreak = checked;
    updateHtmlControlState(state);
    setNewParameterValue("IsPageBreak", isPageBreak);
    setNewParameterValue("ExportType", exportType);
    props.onParameterChange();
  };

  const handleParameterChangeIsCheck = (event) => {
    let checked = event.target.checked;
    state.isIncludeEnterpriseEmployees = checked;
    updateHtmlControlState(state);
    const isExclude = checked ? "Yes" : "No";
    setNewParameterValue("IsExclude", isExclude);
    props.onParameterChange();
  };

  const setNewParameterValue = (name: string, value: any) => {
    const newParameter: IReportParameter = { name, value };
    let currentReportParameters = JSON.parse(
      JSON.stringify(localStorage.getItem("schedulereportParameters"))
    );
    currentReportParameters = JSON.parse(currentReportParameters);
    const nameIndex = currentReportParameters.map((p) => p.name).indexOf(name);
    if (nameIndex > -1) {
      currentReportParameters.splice(nameIndex, 1);
    }
    currentReportParameters.push(newParameter);
    const jsonReportParameters = JSON.stringify(currentReportParameters);
    localStorage.setItem("schedulereportParameters", jsonReportParameters);
    setReportParameters(currentReportParameters);
  };

  const handleEmployementTypeChange = (value) => {
    state.employementType = value;
    updateHtmlControlState(state);
    setNewParameterValue("EmpType", value);
    props.onParameterChange();
  };
  const handleLookBackPeriodChange = (value) => {
    state.lookBackPeriod = value;
    updateHtmlControlState(state);
    setNewParameterValue("LookBack", value);
    props.onParameterChange();
  };

  const handleStatusStringSelectorChange = (value): void => {
    state.statusString = value;
    updateHtmlControlState(state);
    setNewParameterValue("StatusString", value);
    props.onParameterChange();
  };

  const handleEmployeeChange = (value) => {
    state.employee = value;
    updateHtmlControlState(state);
    setNewParameterValue("Employee", value);
    props.onParameterChange();
  };

  const handleBankAccountChange = (value) => {
    state.BankAccount = value.name;
    updateHtmlControlState(state);
    setNewParameterValue("BankAccount", value.oprID);
    setNewParameterValue("BankAccountName", value.name);
    props.onParameterChange();
  };

  const handleReportTypeChange = (value) => {
    state.reportType = value;
    state.startDate = "";
    setNewParameterValue("StartDate", "");
    if (value !== "ByEmployee") {
      state.employee = "";
      const type = "Employee";
      setNewParameterValue(type, "All Employees");
    } else {
      state.subGroupSelectValue = "All";
      state.hotelIds = "";

      setNewParameterValue("Hotelid", "");
      setNewParameterValue("HotelBrand", "");
      setNewParameterValue("GroupName", "");
    }

    const type = "ReportType";
    setNewParameterValue(type, value);
    updateHtmlControlState(state);
    props.onParameterChange();
  };

  const handleMultiEmployeeChange = (value: any) => {
    if (value.selectedItems.length > 0) {
      let selectedDepartments: any = [];
      let newItem = "";
      let gethides;
      let valu1 = value.selectedItems;
      valu1.forEach((element: any) => {
        newItem += element.value + ",";
      });
      selectedDepartments.push(newItem);
      gethides = selectedDepartments[0];
      let lastcomaadep = gethides.replace(/,(\s+)?$/, "");
      state.userUniquenIds = lastcomaadep;
      updateHtmlControlState(state);
      setNewParameterValue("userUniquenId", lastcomaadep);
    } else {
      state.userUniquenIds = "All";
      setNewParameterValue("userUniquenId", "All");
      updateHtmlControlState(state);
    }
    props.onParameterChange();
  };

  const handelResponseStatus = (value): void => {
    state.responseStatus = value;
    setNewParameterValue("responseStatus", value);
    updateHtmlControlState(state);
    props.onParameterChange();
  };

  const handleCOAType = (value): void => {
    state.coaAccounts = "";
    state.coaAccountsOne = "";
    state.coaAccountsTwo = "";
    state.coAaccountType = value;
    updateHtmlControlState(state);
    setNewParameterValue("ddlcoaType", value);
    props.onParameterChange();
  };

  const handleDateLayOut = (value: number): void => {
    state.dateLayOutSelector = +value;
    state.hotelIds = "";
    state.isLayOutSelector = true;
    updateHtmlControlState(state);
    setNewParameterValue("ddlReportLayoutforDates", value);
    props.onParameterChange();
  };

  const handleRowTittle = (value): void => {
    state.rowTitles = value;
    updateHtmlControlState(state);
    setNewParameterValue("RowTittle", value);
    props.onParameterChange();
  };

  const handleDisputeStauts = (value): void => {
    state.disputueStatus = value;
    updateHtmlControlState(state);
    setNewParameterValue("DisputeStatus", value);
    props.onParameterChange();
  };

  const handleddlType = (value): void => {
    state.reportSubTypes = value;
    updateHtmlControlState(state);
    setNewParameterValue("DDLType", value);
    props.onParameterChange();
  };

  const handleMultiTabls = (value): void => {
    state.multiTabs = value;
    updateHtmlControlState(state);
    setNewParameterValue("ddlMultiTabls", value);
    props.onParameterChange();
  };

  const onDataSourceOperating = (value: any) => {
    let codes;
    const getPriorYear = value.filter(
      (itemList: any) => itemList.value === "Prior"
    );
    if (getPriorYear.length > 0) {
      state.IsPrayerYear = getPriorYear[0].value;
    } else {
      state.IsPrayerYear = "";
    }

    if (value.length > 0) {
      let selectedDataSources: any = [];
      let newItem = "";

      value.forEach((element: any) => {
        if (element.isChecked === true) {
          newItem += element.value + ",";
        }
      });
      selectedDataSources.push(newItem);
      codes = selectedDataSources[0];
      state.IsdataSoureSelect = codes;
      let split = codes.split(",");
      const arr = split.filter((item) => item);
      if (arr.length <= 3) {
        setNewParameterValue("DataSource1", codes.replace(/,\s*$/, ""));
        setNewParameterValue("Actual", "");
        setNewParameterValue("AdjustedBudget", "");
        setNewParameterValue("OriginalBudget", "");
        setNewParameterValue("Prior", "");
        setNewParameterValue("Forecast", "");
        state.dataSourceforPanL = arr.length;
      } else {
        state.dataSourceforPanL = arr.length;
      }
    } else {
      state.IsdataSoureSelect = "";
      setNewParameterValue("DataSource1", "");
    }
    state.dataSourceValueCount = 0;
    state.dataSourceType = "opr";
    setNewParameterValue("dataSourceType", "opr");
    updateHtmlControlState(state);
    props.onParameterChange();
  };

  const onDataSourcePeriods = (value: any) => {
    let codes;
    const getPriorYear = value.filter(
      (itemList: any) => itemList.value === "Prior"
    );
    if (getPriorYear.length > 0) {
      state.IsPrayerYear = getPriorYear[0].value;
    } else {
      state.IsPrayerYear = "";
    }

    if (value.length > 0) {
      let selectedDataSources: any = [];
      let newItem = "";

      value.forEach((element: any) => {
        if (element.isChecked === true) {
          newItem += element.value + ",";
        }
      });
      selectedDataSources.push(newItem);
      codes = selectedDataSources[0];
      state.IsdataSoureSelect = codes;
      let split = codes.split(",");
      const arr = split.filter((item) => item);
      if (arr.length <= 3) {
        setNewParameterValue("DataSource1", codes.replace(/,\s*$/, ""));
        setNewParameterValue("Actual", "");
        setNewParameterValue("AdjustedBudget", "");
        setNewParameterValue("OriginalBudget", "");
        setNewParameterValue("Prior", "");
        setNewParameterValue("Forecast", "");
        state.dataSourceforPanL = arr.length;
      } else {
        state.dataSourceforPanL = arr.length;
      }
    } else {
      state.IsdataSoureSelect = "";
    }
    state.dataSourceValueCount = 0;
    state.dataSourceType = "Periods";
    setNewParameterValue("dataSourceType", "Periods");
    updateHtmlControlState(state);
    props.onParameterChange();
  };

  const handleDataSourceChang = (value: any) => {
    let codes;
    if (value.length > 0) {
      let selectedDataSources: any = [];
      let newItem = "";
      value.forEach((element: any) => {
        if (element.isChecked === true) {
          newItem += element.value + ",";
        }
      });
      selectedDataSources.push(newItem);
      codes = selectedDataSources[0];
      state.IsdataSoureSelect = codes;
      let split = codes.split(",");
      const arr = split.filter((item) => item);

      setNewParameterValue("DataSource1", codes);
      setNewParameterValue("IsADR", "");
      setNewParameterValue("IsOCC", "");
      setNewParameterValue("IsRMREV", "");
      setNewParameterValue("IsREVPAR", "");
      state.dataSourceValueCount = arr.length;
    } else {
      state.IsdataSoureSelect = "";
    }
    state.dataSourceforPanL = 0;
    state.dataSourceType = "";
    updateHtmlControlState(state);
    props.onParameterChange();
  };

  const handleDataSource2Chang = (value: any) => {
    let codes;
    if (value.length > 0) {
      let selectedDataSources: any = [];
      let newItem = "";
      value.forEach((element: any) => {
        if (element.isChecked === true) {
          newItem += element.value + ",";
        }
      });
      selectedDataSources.push(newItem);
      codes = selectedDataSources[0];
      state.IsdataSoureSelect2 = codes.replace(/,\s*$/, "");
      let split = codes.split(",");
      const arr = split.filter((item) => item);
      if (arr.length > 0) {
        setNewParameterValue("DataSource2", codes.replace(/,\s*$/, ""));
        updateHtmlControlState(state);
        state.dataSourceValueCount = 0;
      } else {
        state.dataSourceValueCount = arr.length;
        state.IsdataSoureSelect2 = "";
        setNewParameterValue("DataSource2", "");
        updateHtmlControlState(state);
      }
    } else {
      state.IsdataSoureSelect2 = "";
      setNewParameterValue("DataSource2", "");
      updateHtmlControlState(state);
    }
    props.onParameterChange();
  };

  const handleReportChange = (item) => {
    state.Reportdesc = item.description;
    state.ReportName = item.value;
    setNewParameterValue("ReportName", item.value);
    updateHtmlControlState(state);
    props.onParameterChange();
  };

  const handleAgingSelectChange = (value: any) => {
    let codes;
    if (value.length > 0) {
      let selectedDataSources: any = [];
      let newItem = "";

      value.forEach((element: any) => {
        if (element.isChecked === true) {
          newItem += element.value + ",";
        }
      });
      selectedDataSources.push(newItem);
      codes = selectedDataSources[0];
      state.IsAgingSelect = codes;
      // let split = codes.split(',');
      // const arr = split.filter(item => item);
      setNewParameterValue("Aging", codes.replace(/,\s*$/, ""));
      updateHtmlControlState(state);
      props.onParameterChange();
    } else {
      state.IsAgingSelect = value.length;
      // updateHtmlControlState(state);
    }
  };

  const handleCOAAccountSelectortow = (value: any) => {
    state.coaAccountsOne = value.hotelID;
    updateHtmlControlState(state);
    setNewParameterValue("AccountTo", value.value);
    props.onParameterChange();
  };

  const handleSingleCoaSelect = (value: any) => {
    state.coaAccountsTwo = value.hotelID;
    updateHtmlControlState(state);
    setNewParameterValue("SelectSingleCoa", value.value);
    props.onParameterChange();
  };

  const handleAccotingSelector = (value: any) => {
    if (value !== "") {
      let currDate = new Date();
      let currYear = currDate.getFullYear();
      let Selectdate = new Date(value.value);
      let year = Selectdate.getFullYear();
      let month = Selectdate.getMonth() + 1;
      let day = new Date(year, month, 0).getDate();
      let finalSeverDatee =
        month.toString().padStart(2, "0") +
        "/" +
        day.toString().padStart(2, "0") +
        "/" +
        year?.toString()?.substr(2, 2);
      if (currYear >= year) {
        let date = new Date(value.value);
        let dt = date.getFullYear() - 1;
        state.defaultYear = dt.toString();
        state.aacountingPeriorYear = dt.toString();
        state.accountsPeriodSelectStart = value.hotelID;
        state.lastActiveDate = value.value;
        state.serverDateTime = finalSeverDatee;
        setNewParameterValue("AccountingPeriodFrom", value.value);
        setNewParameterValue("AccountingPeriodThrough", finalSeverDatee);
        setNewParameterValue("PriorYear", dt.toString());
        updateHtmlControlState(state);
      } else {
        ModuleReports.GetlastActivePeriods(props.reportData.tenantId).then(
          (result: any[]) => {
            if (result?.length > 0) {
              let newLastActiveDate = new Date(result[0].lastClosedPeriod);
              let det = newLastActiveDate.getFullYear() - 1;
              state.defaultYear = det.toString();
              state.aacountingPeriorYear = det.toString();
              state.accountsPeriodSelectStart = value.hotelID;
              state.lastActiveDate = value.value;
              state.serverDateTime = finalSeverDatee;
              setNewParameterValue("AccountingPeriodFrom", value.value);
              setNewParameterValue("AccountingPeriodThrough", finalSeverDatee);
              setNewParameterValue("PriorYear", det.toString());
              updateHtmlControlState(state);
            }
          }
        );
      }
      props.onParameterChange();
    }
  };
  const getStatusDropDwonLablelNameBasedOnReport = () => {
    let label = "";
    if (props?.reportData?.uniqueReportId == 2175) {
      label = "Deduction Status";
    } else {
      label = "Status";
    }
    return label;
  };
  const getPageBreakCheckBoxLablelNameBasedOnReport = () => {
    let label = "";
    if (props?.reportData?.reportName === "Employee Wage Per Hours: VER.02") {
      label = "Page Break By Hotel on Export";
    } else if (
      props?.reportData?.uniqueReportId == 2175 ||
      props?.reportData?.uniqueReportId == 2210 ||
      props?.reportData?.uniqueReportId == 2213 ||
      props?.reportData?.reportName === "Daily Labor Flash - Labor Hours Variance"
    ) {
      label = "Page Break By EHID";
    } else if (props?.reportData?.uniqueReportId == 49) {
      label = "Page Break by Vendor/Agency";
    } else {
      label = "Page Break By Department on Export";
    }
    return label;
  };

  const handleAccotingSelectorEnd = (value: any) => {
    state.accountsPeriodSelectStartEnd = value.hotelID;
    state.serverDateTime = value.hotelID;
    updateHtmlControlState(state);
    setNewParameterValue("AccountingPeriodThrough", value.value);
    props.onParameterChange();
  };

  const handleCOAAccountSelector = (value: any) => {
    state.coaAccounts = value.hotelID;
    updateHtmlControlState(state);
    setNewParameterValue("AccountFrom", value.value);
    props.onParameterChange();
  };

  const handleReportGroupChange = (item: any) => {
    if (item?.groupName) {
      let type = "GroupName";
      let typee = "OnlyGroupName";
      let OwnershipIDorGroupid = "OwnershipIDorGroupid";
      if (item?.groupName != "All") {
        setNewParameterValue(
          type,
          item?.groupName === "Select Group"
            ? ""
            : item?.groupName + " : " + item?.subGroupName.toUpperCase()
        );
        setNewParameterValue(OwnershipIDorGroupid, item?.groupID);
        setNewParameterValue(
          typee,
          item?.groupName === "Select Group" ? "" : item?.groupName
        );
        setNewParameterValue("ReportType", "");
      } else {
        setNewParameterValue(
          type,
          item?.groupName === "Select Group" ? "" : item?.groupName
        );
        setNewParameterValue(OwnershipIDorGroupid, item?.groupID);
        setNewParameterValue("ReportType", "");
      }
    }
    handleGroupItemChange(item);
    props.onParameterChange();
  };

  const handleGroupItemChange = (item: any, initial = false) => {
    //state.hotelIds = "";

    if (!item) {
      return false;
    }
    const childcall: any = buisnessAccountRef.current;
    if (+item?.groupID === 0 || +item?.groupID === -1) {
      setNewParameterValue("HotelBrand", "");
      if (+item?.groupID === 0) {
        childcall.updateHotelList([], true);
      } else {
        childcall.updateHotelList([]);
      }
      state.subGroupSelectValue = item.groupName;
      updateHtmlControlState(state);
      return;
    }

    state.subGroupSelectValue = item.subGroupName;
    updateHtmlControlState(state);
    setNewParameterValue("HotelBrand", item.subGroupName);

    AllHotelBrandSelector.GetHidSelectbyGroup(
      item.subGroupID,
      controlshouldBeVisible("showAllHotels")
    ).then(async (result: any | null) => {
      if (result != null) {
        const hotelList: any = [];
        result.forEach((element) => {
          let newItem = {} as any;
          newItem.hotelID = element.hotelID;
          newItem.isChecked = true;
          hotelList.push(newItem);
        });

        if (buisnessAccountRef.current !== null) {
          if (initial && state.hotelIds?.length > 0) {
            let hotelIDs = state.hotelIds.split(",")?.map((r) => +r);
            const selectedHotels = hotelList?.map((r) => {
              if (hotelIDs?.includes(r.hotelID)) {
                r.isChecked = true;
              } else {
                r.isChecked = false;
              }
              return r;
            });

            childcall.updateHotelList(selectedHotels);
          }
          if (!initial) {
            childcall.updateHotelList(hotelList);
          }
        }
      }
    });
  };

  const handleMultiReportGroupChange = (value: any, reset = false) => {
    if (reset) {
      setNewParameterValue("GroupName", "");
      setNewParameterValue("OnlyGroupName", "");
      //setNewParameterValue("OwnershipIDorGroupid", "0");
      state.subGroupSelectValue = "Select Group";
      setNewParameterValue("ReportType", "");
      updateHtmlControlState(state);
    }
    let OwnershipIDorGroupid;
    if (value.length > 0) {
      let selectedGrpNames: any = [];
      let newItem = "";
      let getgrpnames;
      value.forEach((element: any) => {
        newItem += element.subGroupName + ",";
      });
      selectedGrpNames.push(newItem);
      getgrpnames = selectedGrpNames[0];
      let lastcomaaGroups = getgrpnames.replace(/,(\s+)?$/, "");
      let setGrpNames = lastcomaaGroups;
      state.subGroupSelectValue = setGrpNames;
      updateHtmlControlState(state);
      setNewParameterValue("ReportType", "");
      setNewParameterValue("HotelBrand", lastcomaaGroups);
      value.forEach((element: any) => {
        setNewParameterValue("OnlyGroupName", element.groupName);
        if (element.subGroupName !== "") {
          let subgroupname;
          // let hotelcode;
          let setsubGroupName = element.subGroupName;
          const stringToRemove = setsubGroupName?.toString()?.split("-")[0];
          subgroupname = setsubGroupName.replace(stringToRemove, "");
          while (subgroupname.charAt(0) === "-") {
            subgroupname = subgroupname.substring(1)?.trim();
          }
          setNewParameterValue(
            "GroupName",
            element.groupName === "Select Group"
              ? ""
              : element.groupName + " : " + element.subGroupName.toUpperCase()
          );
          setNewParameterValue("OwnershipIDorGroupid", element?.groupID);
        }
      });
      handleMultiGroupItemChange(value);
    } else {
      //state.groupIds = "";
      handleMultiGroupItemChange(value);
      // updateEmployeeDDL();
      state.subGroupSelectValue = "";
      updateHtmlControlState(state);
      props.onParameterChange();
    }
  };

  const handleMultiGroupItemChange = (item: any, initial = false) => {
    //state.hotelIds = "";

    if (!item || item.length === 0) {
      const childcall: any = buisnessAccountRef.current;
      state.hotelIds = "";
      const hotelList: any = [];
      updateHtmlControlState(state);
      childcall.updateHotelList(hotelList);
      return false;
    }
    const childcall: any = buisnessAccountRef.current;
    if (+item?.groupID === 0 || +item?.groupID === -1) {
      setNewParameterValue("HotelBrand", "");
      if (+item?.groupID === 0) {
        childcall.updateHotelList([], true);
      } else {
        childcall.updateHotelList([]);
      }
      state.subGroupSelectValue = item.groupName;
      updateHtmlControlState(state);
      return;
    }

    const hotelList: any = [];
    item.forEach((value: any) => {
      AllHotelBrandSelector.GetHidSelectbyGroup(
        value.subGroupID,
        controlshouldBeVisible("showAllHotels")
      ).then(async (result: any | null) => {
        if (result != null) {
          result.forEach((element) => {
            let newItem = {} as any;
            newItem.hotelID = element.hotelID;
            newItem.isChecked = true;
            hotelList.push(newItem);
          });
          if (buisnessAccountRef.current !== null) {
            if (initial && state.hotelIds?.length > 0) {
              let hotelIDs = state.hotelIds.split(",")?.map((r) => +r);
              const selectedHotels = hotelList?.map((r) => {
                if (hotelIDs?.includes(r.hotelID)) {
                  r.isChecked = true;
                } else {
                  r.isChecked = false;
                }
                return r;
              });
              childcall.updateHotelList(selectedHotels);
            }
            if (!initial) {
              childcall.updateHotelList(hotelList);
            }
          }
        }
      });
    });
  };

  const handlePeriorYear = (value: any) => {
    state.defaultYear = value.hotelID;
    state.aacountingPeriorYear = value.hotelID;
    updateHtmlControlState(state);
    setNewParameterValue("PriorYear", value.value);
    props.onParameterChange();
  };

  const handleSingleBusinessAccountChange = (value: any) => {
    if (props.reportData.reportName === "GENERAL LEDGER REPORT") {
    }

    if (controlshouldBeVisible("showDateRangeAsPerPayPeriod")) {
      state.lastDateOfPayPeriodWeekOfHotel = "";
      state.startDate = "";
      setNewParameterValue("StartDate", "");
      state.endDate = "";
      setNewParameterValue("EndDate", "");
    }
    if (value.hotelID !== "") {
      state.hotelIds = value.hotelID;
      updateHtmlControlState(state);
      if (employeeDDLRef?.current) {
        if (
          typeof employeeDDLRef?.current["updateEmployeeDDL"] === "function"
        ) {
          employeeDDLRef?.current?.updateEmployeeDDL(
            value.hotelID,
            state.status
          );
        }
      }
      setNewParameterValue("Hotelid", value.hotelID);
      setNewParameterValue("HotelType", value?.hotelType);
    } else if (value.description !== "") {
      state.hotelName = value.description;
      updateHtmlControlState(state);
    }
    let hotename;
    let hotelcode;

    let sethotelName = value.description;
    const stringToRemove = sethotelName?.toString()?.split("-")[0];
    hotename = sethotelName.replace(stringToRemove, "");
    while (hotename.charAt(0) === "-") {
      hotename = hotename.substring(1)?.trim();
    }
    hotelcode = value?.value;

    //hotename.toUpperCase()
    let type = "description";
    setNewParameterValue(type, hotename + " (" + hotelcode + ")");

    if (controlshouldBeVisible("showDateRangeAsPerPayPeriod")) {
      Schedule.GetPayPeriodWeekByDateAndHotel(
        value.hotelID,
        moment.tz(value?.timeZone).format("MM/DD/YYYY")
      ).then((result) => {
        if (result?.dateTo) {
          if (
            result?.weekLayOut === "bi-weekly" &&
            moment(moment().tz(value?.timeZone).format("MM/DD/YY")).diff(
              moment(result.dateFrom).tz(value?.timeZone),
              "days"
            ) >= 7
          ) {
            state.lastDateOfPayPeriodWeekOfHotel = moment(result.dateTo)
              .subtract(6, "days")
              .tz(value?.timeZone)
              ?.format("MM/DD/YY");
          } else {
            state.lastDateOfPayPeriodWeekOfHotel = moment(result.dateFrom)
              .tz(value?.timeZone)
              ?.format("MM/DD/YY");
          }
        }

        updateHtmlControlState(state);
        props.onParameterChange();
      });
    }
  };

  const handleBusinessAccountChange = (value: any, reset = false) => {
    state.BankAccount = "";
    setNewParameterValue("BankAccount", -1);
    if (reset) {
      setNewParameterValue("GroupName", "");
      setNewParameterValue("HotelBrand", "");
      setNewParameterValue("OwnershipIDorGroupid", "0");
      state.subGroupSelectValue = "Select Group";
      updateHtmlControlState(state);
    }
    if (value.length > 0) {
      let selectedHIDs: any = [];
      let newItem = "";
      let gethides;
      value.forEach((element: any) => {
        newItem += element.hotelID + ",";
      });
      selectedHIDs.push(newItem);
      gethides = selectedHIDs[0];
      let lastcomaaHid = gethides.replace(/,(\s+)?$/, "");
      if (employeeDDLRef?.current) {
        if (
          typeof employeeDDLRef?.current["updateEmployeeDDL"] === "function"
        ) {
          employeeDDLRef?.current?.updateEmployeeDDL(
            lastcomaaHid,
            state.status
          );
        }
      }

      state.hotelIds = lastcomaaHid;
      updateHtmlControlState(state);
      let setEhid = lastcomaaHid;
      let type = "Hotelid";
      setNewParameterValue(type, setEhid);
      setNewParameterValue("HotelType", value?.hotelType);
      if (value[0].description !== "") {
        state.hotelName = value[0].description;
        updateHtmlControlState(state);
        let hotename;
        let hotelcode;
        let sethotelName = value[0].description;
        const stringToRemove = sethotelName?.toString()?.split("-")[0];
        hotename = sethotelName.replace(stringToRemove, "");
        while (hotename.charAt(0) === "-") {
          hotename = hotename.substring(1)?.trim();
        }
        hotelcode = value[0]?.value;
        let type = "description";
        setNewParameterValue(
          type,
          hotename + " (" + hotelcode + ")" //.toUpperCase()
        );
      }
      props.onParameterChange();
    } else {
      state.hotelIds = "";
      updateEmployeeDDL();
      state.hotelName = "";
      updateHtmlControlState(state);
    }
  };

  const onFormatTypeChange = (value): void => {
    state.hoursFormat = value;
    updateHtmlControlState(state);
    setNewParameterValue("timeFormat", value);
    props.onParameterChange();
  };

  const handleAccountingYearChange = (value) => {
    state.accountingYear = value;
    updateHtmlControlState(state);
    setNewParameterValue("Year", value);
    props.onParameterChange();
  };

  const handleACACoverageCodesChange = (value: any) => {
    const codes = value.map((r) => r?.code).join(",");

    setNewParameterValue("ACACoverageCode", codes);
    state.acaCoverageCodes = codes;
    updateHtmlControlState(state);
    props.onParameterChange();
  };

  const handleACASaferCodesChange = (value: any) => {
    // setACACoverageCodes(value);
    const codes = value.map((r) => r?.code).join(",");
    setNewParameterValue("ACASaferCode", codes);
    state.acaACASHarborCodes = codes;
    updateHtmlControlState(state);
    props.onParameterChange();
  };

  const handleACACodeChange = (e: any) => {
    const checked = e.currentTarget.checked;
    // e.currentTarget.checked = checked;
    setChkSummSec(checked);
    const type = "ChkSummSec";
    const _value = checked ? "Yes" : "No";
    setNewParameterValue(type, _value);
    state.isAcaCodes = checked;
    updateHtmlControlState(state);
    props.onParameterChange();
  };

  const handleIsCompSetChange = (e: any) => {
    const checked = e.currentTarget.checked;
    // e.currentTarget.checked = checked;
    const type = "ChkIsCompSet";
    const _value = checked ? "Yes" : "No";
    setNewParameterValue(type, _value);
    state.isCompSet = checked;
    updateHtmlControlState(state);
    props.onParameterChange();
  };

  const handleCheckbyCOA = (e: any) => {
    const checked = e.currentTarget.checked;
    const type = "ChkCheckCOA";
    const _value = checked ? "Yes" : "No";
    setNewParameterValue(type, _value);
    state.IsCheckCOA = checked;
    updateHtmlControlState(state);
    props.onParameterChange();
  };

  const handleIncludeBudget = (e: any) => {
    const checked = e.currentTarget.checked;
    const type = "ChkIncludeBudget";
    const _value = checked ? "Yes" : "No";
    setNewParameterValue(type, _value);
    state.isIncludeBudget = checked;
    updateHtmlControlState(state);
    props.onParameterChange();
  };

  const handleZeroActualValues = (e: any) => {
    const checked = e.currentTarget.checked;
    const type = "ChkHideZeroActualValues";
    const _value = checked ? "Yes" : "No";
    setNewParameterValue(type, _value);
    state.isZeroActualValues = checked;
    updateHtmlControlState(state);
    props.onParameterChange();
  };

  const handleHideStatistics = (e: any) => {
    const checked = e.currentTarget.checked;
    const type = "ChkHideStatistics";
    const _value = checked ? "Yes" : "No";
    setNewParameterValue(type, _value);
    state.IsHideStatistics = checked;
    updateHtmlControlState(state);
    props.onParameterChange();
  };

  const handleUnapprovedtransactions = (e: any) => {
    const checked = e.currentTarget.checked;
    const type = "ChkUnapprovedtransactions";
    const _value = checked ? "Yes" : "No";
    setNewParameterValue(type, _value);
    state.IsUnapprovedtransactions = checked;
    updateHtmlControlState(state);
    props.onParameterChange();
  };

  const handleParameterChange12Account = (target, statusValue) => {
    setStatusValue12Account(statusValue);
  };

  const handleParameterCalculatedColumn = (target, statusValue) => {
    setStatusValueCal(statusValue);
  };

  const setStatusValueCal = (statusValue) => {
    state.CalculatedStatus = statusValue;
    updateHtmlControlState(state);
    setNewParameterValue("CalculatedStatus", statusValue);
    props.onParameterChange();
  };
  const setStatusValue12Account = (statusValue) => {
    state.TwelveAccountPeriod = statusValue;
    updateHtmlControlState(state);
    setNewParameterValue("LayoutDesign", statusValue);
    props.onParameterChange();
  };

  const handleHireDateChange = ({ currentTarget: { value } }) => {
    // setACACoverageCodes(value);
    const type = "HireDate";
    state.hireDate = value;
    updateHtmlControlState(state);
    setNewParameterValue(type, value);
    props.onParameterChange();
  };

  const handleFileSizeChange = ({ currentTarget: { value } }) => {
    const type = "FileSize";
    state.fileSize = value;
    updateHtmlControlState(state);
    setNewParameterValue(type, value);
    props.onParameterChange();
  };

  const handleAvgHoursChange = ({ currentTarget: { value } }) => {
    // setACACoverageCodes(value);
    const type = "AvgHrsWorked";
    state.avgHours = value;
    updateHtmlControlState(state);
    setNewParameterValue(type, value);
    props.onParameterChange();
  };

  const handleDatesChange = (date, type) => {
    if (type === "StartDate") {
      state.startDate = date;
      updateHtmlControlState(state);
      let newVaribaleDate = new Date(date);
      let tempYear = newVaribaleDate.getFullYear()?.toString()?.substr(2, 2);
      let tempDate = newVaribaleDate.getDate();
      let tempMonth = newVaribaleDate.getMonth() + 1;
      let finalDate =
        tempMonth.toString().padStart(2, "0") +
        "/" +
        tempDate.toString().padStart(2, "0") +
        "/" +
        tempYear;
      setNewParameterValue(type, finalDate);
    }

    if (type === "EndDate") {
      state.endDate = date;
      if (new Date(state.startDate) > new Date(state.endDate)) {
        state.endDate = "";
        updateHtmlControlState(state);
        Utils.toastError(
          "Please enter the End Date greater than or equal to Start Date.",
          {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "reportParameterSelection",
          }
        );

        return false;
      }

      updateHtmlControlState(state);
      let newVaribaleDate = new Date(date);
      let tempYear = newVaribaleDate.getFullYear()?.toString()?.substr(2, 2);
      let tempDate = newVaribaleDate.getDate();
      let tempMonth = newVaribaleDate.getMonth() + 1;
      let finalDate =
        tempMonth.toString().padStart(2, "0") +
        "/" +
        tempDate.toString().padStart(2, "0") +
        "/" +
        tempYear;
      setNewParameterValue(type, finalDate);
    }
    props.onParameterChange();
  };

  const handleDepartmentChange = (value: any) => {
    if (value.length > 0) {
      let selectedDepartments: any = [];
      let newItem = "";
      let gethides;
      value.forEach((element: any) => {
        newItem += element.deptID + ",";
      });
      selectedDepartments.push(newItem);
      gethides = selectedDepartments[0];
      let lastcomaadep = gethides.replace(/,(\s+)?$/, "");
      state.departmentIds = lastcomaadep;
      updateHtmlControlState(state);
      setNewParameterValue("deptID", lastcomaadep);
    } else {
      state.departmentIds = "";
      setNewParameterValue("deptID", "");
      updateHtmlControlState(state);
    }
    props.onParameterChange();
  };

  const onSummarySubTypeChange = (value): void => {
    state.laborSummarySubType = value;
    setNewParameterValue("isPosition", value);
    props.onParameterChange();
  };

  const setLaborSummaryCheckBoxValue = (type, e): void => {
    const _value = e.currentTarget.checked ? "Yes" : "No";

    state[type] = e.currentTarget.checked;
    updateHtmlControlState(state);
    setNewParameterValue(type, _value);
    props.onParameterChange();
  };

  const getControl = (paramName: string): any => {
    if (
      (reportInputParameters?.length === 0 &&
        visibleHtmlControls?.length === 0) ||
      !paramName
    ) {
      return {};
    }
    let item: any = {};
    if (reportInputParameters?.length > 0) {
      item = reportInputParameters.find(
        (r) =>
          r?.paramName?.toLowerCase()?.trim() ===
          paramName.toLowerCase()?.trim()
      );
    }
    if (visibleHtmlControls?.length > 0) {
      item = visibleHtmlControls.find(
        (r) =>
          r?.controlName?.toLowerCase()?.trim() ===
          paramName.toLowerCase()?.trim()
      );
    }

    return item;
  };

  const controlshouldBeVisible = (controlName: string): boolean => {
    if (
      (reportInputParameters?.length === 0 &&
        visibleHtmlControls?.length === 0) ||
      !controlName
    ) {
      return false;
    }
    if (reportInputParameters?.length > 0) {
      return reportInputParameters?.filter(
        (item: any) =>
          item?.paramName?.toLowerCase()?.trim() ===
          controlName?.toLowerCase()?.trim()
      ).length > 0
        ? true
        : false;
    } else if (visibleHtmlControls?.length > 0) {
      return visibleHtmlControls?.filter(
        (item: any) =>
          item?.controlName?.toLowerCase()?.trim() ===
          controlName?.toLowerCase()?.trim()
      ).length > 0
        ? true
        : false;
    } else {
      return false;
    }
  };

  const getSwitchButtonControls = (state: IParameterSelectionState) => {
    return [
      {
        controlName: "chkStatus",
        control: (
          <>
            {state.reportType !== "ByEmployee" && (
              <Form.Group controlId="chkStatus">
                <Form.Label className="sec-ttl ">
                  {reportData?.uniqueReportId === 1 ? "COA Type" : "Status"}
                </Form.Label>
                <Form.Check
                  type="switch"
                  custom
                  className="sm-controll rm-index"
                  label="Active"
                  name="coaType"
                  id="chkActive"
                  value="Active"
                  ref={activeCheckBox}
                  onChange={(
                    event: React.ChangeEvent<HTMLInputElement>
                  ): void => handleParameterChange(event.target, 1)}
                  checked={+state.status === 1}
                  disabled={props.disabled}
                />
                <Form.Check
                  custom
                  className="sm-controll rm-index"
                  type="switch"
                  label="Inactive"
                  name="coaType"
                  id="chkInactive"
                  value="Inactive"
                  onChange={(
                    event: React.ChangeEvent<HTMLInputElement>
                  ): void => handleParameterChange(event.target, 2)}
                  checked={+state.status === 2}
                  disabled={props.disabled}
                />
                <Form.Check
                  custom
                  className="sm-controll rm-index"
                  type="switch"
                  label="Both"
                  name="coaType"
                  id="chkBoth"
                  value="Both"
                  onChange={(
                    event: React.ChangeEvent<HTMLInputElement>
                  ): void => handleParameterChange(event.target, 3)}
                  checked={+state.status === 3}
                  disabled={props.disabled}
                />
              </Form.Group>
            )}
          </>
        ),
      },
      {
        controlName: "chkDesignLayOut",
        control: (
          <>
            {state.reportType !== "ByEmployee" && (
              <Form.Group controlId="chkDesignLayOut">
                <Form.Label className="sec-ttl ">Layout Design</Form.Label>
                <Form.Check
                  type="switch"
                  custom
                  className="sm-controll rm-index"
                  label="Trailing 12 accounting periods"
                  name="coaType"
                  id="chkTrailing12accountingperiods"
                  value="1"
                  onChange={(
                    event: React.ChangeEvent<HTMLInputElement>
                  ): void => handleParameterChange12Account(event.target, 1)}
                  checked={+state.TwelveAccountPeriod === 1}
                  disabled={props.disabled}
                />
                <Form.Check
                  custom
                  className="sm-controll rm-index"
                  type="switch"
                  label="Year to Date (YTD)"
                  name="coaType"
                  id="chkYeartoDate(YTD)"
                  value="2"
                  onChange={(
                    event: React.ChangeEvent<HTMLInputElement>
                  ): void => handleParameterChange12Account(event.target, 2)}
                  checked={+state.TwelveAccountPeriod === 2}
                  disabled={props.disabled}
                />
              </Form.Group>
            )}
          </>
        ),
      },
      {
        controlName: "chkCalculatedColumn",
        control: (
          <>
            {state.reportType !== "ByEmployee" && (
              <Form.Group controlId="chkCalculatedColumn">
                <Form.Label className="sec-ttl ">Calculated Column</Form.Label>
                <Form.Check
                  type="switch"
                  //custom
                  className="sm-controll rm-index"
                  label="Average"
                  name="Average"
                  id="chkAverage"
                  value="Average"
                  onChange={(
                    event: React.ChangeEvent<HTMLInputElement>
                  ): void =>
                    handleParameterCalculatedColumn(event.target, "Average")
                  }
                  checked={state.CalculatedStatus === "Average"}
                  disabled={props.disabled}
                />
                <Form.Check
                  //custom
                  className="sm-controll rm-index"
                  type="switch"
                  label="Total"
                  name="Total"
                  id="chkTotal"
                  value="Total"
                  onChange={(
                    event: React.ChangeEvent<HTMLInputElement>
                  ): void =>
                    handleParameterCalculatedColumn(event.target, "Total")
                  }
                  checked={state.CalculatedStatus === "Total"}
                  disabled={props.disabled}
                />
              </Form.Group>
            )}
          </>
        ),
      },
      {
        controlName: "chkIncludeSalariedEmployees",
        control: (
          <>
            <Form.Group
              controlId="chkIsSalaried"
              className="includeSalariedEmployees"
            >
              <Form.Label className="sec-ttl">{/* COA Type */}</Form.Label>
              <Form.Check
                custom
                disabled={props.disabled}
                className="sm-controll rm-index"
                type="switch"
                name="chkIsSalaried"
                checked={state.IsSalaried}
                label={"Include Salaried Employees"}
                onChange={(e) => setLaborSummaryCheckBoxValue("IsSalaried", e)}
              />
            </Form.Group>
          </>
        ),
      },
      {
        controlName: "chkIsExcludePayroll",
        control: (
          <>
            <Form.Group
              controlId="chkIsExcludePayroll"
              className="includeExcludeFromPayroll"
            >
              <Form.Label className="sec-ttl">{/* COA Type */}</Form.Label>
              <Form.Check
                custom
                className="sm-controll rm-index"
                type="switch"
                name="chkIsExcludePayroll"
                disabled={props.disabled}
                checked={state.IsExcludePayroll}
                label={"Include Exclude From Payroll"}
                onChange={(e) =>
                  setLaborSummaryCheckBoxValue("IsExcludePayroll", e)
                }
              />
            </Form.Group>
          </>
        ),
      },
      {
        controlName: "chkVariance",
        control: (
          <>
            <Form.Group controlId="chkVariance" className="showVariance">
              <Form.Label className="sec-ttl">{/* COA Type */}</Form.Label>
              <Form.Check
                custom
                className="sm-controll rm-index"
                type="switch"
                name="chkVariance"
                disabled={props.disabled}
                checked={state.IsVariance}
                label={"Show Variance"}
                onChange={(e) => setLaborSummaryCheckBoxValue("IsVariance", e)}
              />
            </Form.Group>
          </>
        ),
      },
      {
        controlName: "chkDollars",
        control: (
          <>
            <Form.Group controlId="chkDollars" className="showDollars">
              <Form.Label className="sec-ttl">{/* COA Type */}</Form.Label>
              <Form.Check
                custom
                className="sm-controll rm-index"
                type="switch"
                name="chkDollars"
                disabled={props.disabled}
                checked={state.IsDollars}
                label={"Show Dollars"}
                onChange={(e) => setLaborSummaryCheckBoxValue("IsDollars", e)}
              />
            </Form.Group>
          </>
        ),
      },
      {
        controlName: "chkPageBreak",
        control: (
          <>
            <Form.Group
              controlId="IsPageBreak"
              className="pageBreakAfterEachDepartment"
            >
              <Form.Label className="sec-ttl">{/* COA Type */}</Form.Label>
              <Form.Check
                custom
                className="sm-controll rm-index"
                type="switch"
                name="PageBreakAfterEachDepartment"
                disabled={props.disabled}
                checked={state.isPageBreak}
                label={getPageBreakCheckBoxLablelNameBasedOnReport()}
                onChange={handleParameterIsPagebreakIsCheck}
              />
            </Form.Group>
          </>
        ),
      },
      {
        controlName: "chkIncludeEnterpriseEmployees",
        control: (
          <>
            <Form.Group
              controlId="HRAnniversary"
              className="includeEnterpriseEmployees"
            >
              <Form.Label className="sec-ttl">{/* COA Type */}</Form.Label>
              <Form.Check
                custom
                className="sm-controll rm-index"
                type="switch"
                name="IncludeEnterpriseEmployees"
                disabled={props.disabled}
                checked={state.isIncludeEnterpriseEmployees}
                label={"Include Enterprise Employees"}
                onChange={handleParameterChangeIsCheck}
              />
            </Form.Group>
          </>
        ),
      },
      {
        controlName: "chkCompareActuals",
        control: (
          <>
            <Form.Group controlId="chkStatus">
              <Form.Label className="sec-ttl">Compare Actuals to</Form.Label>
              <Form.Check
                type="switch"
                custom
                className="sm-controll rm-index"
                label="Scheduled"
                name="ActualCompared"
                id="chkScheduled"
                value="Scheduled"
                onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                  handleCompareActualChange("ActualCompared", "1")
                }
                disabled={props.disabled}
                checked={+state?.chkCompareActuals === 1}
              />
              <Form.Check
                custom
                className="sm-controll rm-index"
                type="switch"
                label="Budgeted"
                name="ActualCompared"
                id="chkBudgeted"
                value="Budgeted"
                onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                  handleCompareActualChange("ActualCompared", "0")
                }
                defaultChecked
                disabled={props.disabled}
                checked={+state?.chkCompareActuals === 0}
              />
            </Form.Group>
          </>
        ),
      },
      {
        controlName: "chkACACodes",
        control: (
          <>
            <Form.Group controlId="">
              <Form.Label className="sec-ttl">
                ACA Codes{" "}
                <Form.Check
                  type="switch"
                  className="sm-controll rm-index"
                  ref={includeAllEmpCheckBox}
                  onChange={handleACACodeChange}
                  checked={state.isAcaCodes}
                  disabled={props.disabled}
                  inline
                  id={`inline-42`}
                  label="Include all users with unassigned ACA Codes"
                />
              </Form.Label>
            </Form.Group>
          </>
        ),
      },
      {
        controlName: "chkIsCompSet",
        control: (
          <>
            <Form.Group
              controlId="chkIsCompSet"
              className="includeSalariedEmployees"
            >
              <Form.Label className="sec-ttl">{/* COA Type */}</Form.Label>
              <Form.Check
                custom
                className="sm-controll rm-index"
                type="switch"
                name="chkIsCompSet"
                id={`inline-42`}
                label={"Include Comp Set"}
                onChange={handleIsCompSetChange}
                checked={state.isCompSet}
                disabled={props.disabled}
              />
            </Form.Group>
          </>
        ),
      },
      {
        controlName: "chkIncludeBudget",
        control: (
          <>
            <Form.Group
              controlId="chkIsSalaried"
              className="includeSalariedEmployees"
            >
              <Form.Label className="sec-ttl">{/* COA Type */}</Form.Label>
              <Form.Check
                custom
                className="sm-controll rm-index"
                type="switch"
                name="chkIsSalaried"
                id={`inline-42`}
                label={"Include Budget"}
                onChange={handleIncludeBudget}
                checked={state.isIncludeBudget}
                disabled={props.disabled}
              />
            </Form.Group>
          </>
        ),
      },
      {
        controlName: "chkZeroActualValues",
        control: (
          <>
            <Form.Group
              controlId="chkIsSalaried"
              className="includeSalariedEmployees"
            >
              <Form.Label className="sec-ttl">{/* COA Type */}</Form.Label>
              <Form.Check
                custom
                className="sm-controll rm-index"
                type="switch"
                name="chkIsSalaried"
                id={`inline-43`}
                label={"Hide Rows with Zero Actual Values"}
                onChange={handleZeroActualValues}
                checked={state.isZeroActualValues}
                disabled={props.disabled}
              />
            </Form.Group>
          </>
        ),
      },
      {
        controlName: "chkByCOA",
        control: (
          <>
            <Form.Group
              controlId="chkByCOA"
              className="includeSalariedEmployees"
            >
              <Form.Label className="sec-ttl">{/* COA Type */}</Form.Label>
              <Form.Check
                custom
                className="sm-controll rm-index"
                type="switch"
                name="chkByCOA"
                id={`inline-44`}
                label={"By COA"}
                onChange={handleCheckbyCOA}
                checked={state.IsCheckCOA}
                disabled={props.disabled}
              />
            </Form.Group>
          </>
        ),
      },
      {
        controlName: "chkHideStatistics",
        control: (
          <>
            <Form.Group
              controlId="chkHideStatistics"
              className="includeSalariedEmployees"
            >
              <Form.Label className="sec-ttl">{/* COA Type */}</Form.Label>
              <Form.Check
                custom
                className="sm-controll rm-index"
                type="switch"
                name="chkHideStatistics"
                id={`inline-45`}
                label={"Hide Statistics"}
                onChange={handleHideStatistics}
                checked={state.IsHideStatistics}
                disabled={props.disabled}
              />
            </Form.Group>
          </>
        ),
      },
      {
        controlName: "chkUnapprovedtransactions",
        control: (
          <>
            <Form.Group
              controlId="chkUnapprovedtransactions"
              className="includeSalariedEmployees"
            >
              <Form.Label className="sec-ttl">{/* COA Type */}</Form.Label>
              <Form.Check
                custom
                className="sm-controll rm-index"
                type="switch"
                name="chkUnapprovedtransactions"
                id={`inline-60`}
                label={"Unapproved transactions"}
                onChange={handleUnapprovedtransactions}
                checked={state.IsUnapprovedtransactions}
                disabled={props.disabled}
              />
            </Form.Group>
          </>
        ),
      },
    ];
  };

  const buildFilterControls = (savedParameters: IParameterSelectionState) => {
    htmlControls = [
      ...getSwitchButtonControls(savedParameters),
      ...getTextBoxControls(savedParameters),
      ...getDropDownControls(savedParameters),
    ];
  };

  const getTextBoxControls = (state: IParameterSelectionState): any[] => {
    let defaultDate: any = new Date();
    if (JSON.parse(localStorage.storage).currentUtcTime !== "") {
      defaultDate = new Date(JSON.parse(localStorage.storage).currentUtcTime);
    }
    defaultDate = moment(defaultDate).format("MM/DD/YY");
    return [
      {
        controlName: "txtAvgHoursWorked",
        control: (
          <>
            <Form.Group controlId="" className="numPicker">
              <Form.Label className="sec-ttl">
                Average Hours Worked (Equal or Greater than - In Hours)
              </Form.Label>
              <Form.Control //custom
                type="number"
                min="0"
                onInput={(e) => {
                  const value = e.currentTarget.value;
                  e.currentTarget.value = value?.toString().includes("-")
                    ? null
                    : value;
                }}
                value={state.avgHours}
                onChange={handleAvgHoursChange}
                disabled={props.disabled}
              />
            </Form.Group>
          </>
        ),
      },
      {
        controlName: "txtHireDate",
        control: (
          <>
            <Form.Group controlId="" className="numPicker">
              <Form.Label className="sec-ttl">
                Hire Date (Greater than - In Days)
              </Form.Label>
              <Form.Control //custom
                type="number"
                min="0"
                onInput={(e) => {
                  const value = e.currentTarget.value;
                  e.currentTarget.value = value?.toString().includes("-")
                    ? null
                    : value;
                }}
                value={state.hireDate}
                name="txtsdshireDate"
                autoComplete="txtsdshireDate"
                onChange={handleHireDateChange}
                disabled={props.disabled}
              />
            </Form.Group>
          </>
        ),
      },
      {
        controlName: "txtFileSize",
        control: (
          <>
            <Form.Group controlId="" className="numPicker">
              <Form.Label className="sec-ttl">
                Show File Sizes Larger than
              </Form.Label>
              <Form.Control //custom
                type="number"
                min="0"
                onInput={(e) => {
                  const value = e.currentTarget.value;
                  e.currentTarget.value = value?.toString().includes("-")
                    ? null
                    : value;
                }}
                value={state.fileSize}
                name="fileSize"
                onChange={handleFileSizeChange}
                disabled={props.disabled}
              />{" "}
              (MB)
            </Form.Group>
          </>
        ),
      },
    ];
  };

  const getDropDownControls = (state: IParameterSelectionState): any[] => {
    return [
      {
        controlName: "ddlHoursFormat",
        control: (
          <>
            <Form.Group as={Row} controlId="">
              <Form.Label column sm="3" className="sec-ttl">
                {"Total"}
              </Form.Label>
              <div className="col-sm-9">
                <TotalFormat
                  defaultValue={state.hoursFormat}
                  onFormatTypeChange={onFormatTypeChange}
                />
              </div>
            </Form.Group>
          </>
        ),
      },
      {
        controlName: "ddlAccountingYears",
        control: (
          <>
            <Form.Group as={Row} controlId="">
              <Form.Label column sm="3" className="sec-ttl">
                Select Year
              </Form.Label>
              <div className="col-sm-9">
                <YearSelector
                  defaultValue={state.accountingYear}
                  onYearChange={handleAccountingYearChange}
                />
              </div>
            </Form.Group>
          </>
        ),
      },
      {
        controlName: "ddlEmployee",
        control: (
          <>
            <Form.Group as={Row} controlId="">
              <Form.Label column sm="3" className="sec-ttl">
                Select Employee
              </Form.Label>
              <div className="col-sm-9">
                <LaborEmployeeSelector
                  hotelIds={state.hotelIds}
                  status={state.status}
                  ref={employeeDDLRef}
                  defaultValue={state.employee}
                  onLaborEmployeeChange={handleEmployeeChange}
                />
              </div>
            </Form.Group>
          </>
        ),
      },
      {
        controlName: "ddlBankAccount",
        control: (
          <>
            {state.hotelIds !== undefined && (
              <>
                {state.hotelIds.length > 0 && (
                  <>
                    {state.hotelIds?.split(",").length === 1 && (
                      <Form.Group as={Row} controlId="">
                        <Form.Label column sm="3" className="sec-ttl">
                          Bank Account
                        </Form.Label>
                        <div className="col-sm-9">
                          <BankAccountSelector
                            hotelIds={state.hotelIds}
                            status={state.status}
                            defaultValue={state.BankAccount}
                            onBankAccountChange={handleBankAccountChange}
                          />
                        </div>
                      </Form.Group>
                    )}
                  </>
                )}
              </>
            )}
          </>
        ),
      },
      {
        controlName: "ddlLaborSummarySubType",
        control: (
          <>
            <Form.Group as={Row} controlId="">
              <Form.Label column sm="3" className="sec-ttl">
                Labor Summary SubType
              </Form.Label>
              <>
                <div className="col-sm-9">
                  <LaborSummarySubTypeSelector
                    disabled={props.disabled}
                    defaultValue={state.laborSummarySubType}
                    onSummarySubTypeChange={onSummarySubTypeChange}
                  />
                </div>
              </>
            </Form.Group>
          </>
        ),
      },

      {
        controlName: "ddlDepartmentMulti",
        control: (
          <>
            <Form.Group as={Row} controlId="departmentSelector">
              <Form.Label column sm="3" className="sec-ttl">
                Department
              </Form.Label>
              <div className="col-sm-9">
                <DepartmentSelector
                  defaultValue={state?.departmentIds}
                  handleDepartmentChange={handleDepartmentChange}
                />
              </div>
            </Form.Group>
          </>
        ),
      },
      {
        controlName: "ddlDepartmentSingle",
        control: (
          <>
            <Form.Group as={Row} controlId="">
              {/* <Form.Label className="sec-ttl">Departments</Form.Label> */}
              <Form.Label column sm="3" className="sec-ttl">
                Departments
              </Form.Label>
              <div className="col-sm-9">
                <SingleAllDepartmentSelect
                  defaultValue={state?.departmentIds}
                  isCopyBudgetId={handleSelectAllSingleDepartments}
                />
              </div>
            </Form.Group>
          </>
        ),
      },
      {
        controlName: "txtAutoEmployee",
        control: (
          <>
            {state.reportType !== "ByEHID" && (
              <Form.Group as={Row} controlId="">
                <Form.Label column sm="3" className="sec-ttl">
                  Employee
                </Form.Label>
                <div className="col-sm-9">
                  <EmployeeAutoSelector
                    defaultValue={state.employee}
                    onEmployeeChange={handleEmployeeChange}
                  />
                </div>
              </Form.Group>
            )}
          </>
        ),
      },

      {
        controlName: "ddlPeriorYear",
        control: (
          <>
            {state.reportType !== "ByEmployee" &&
              state.IsPrayerYear === "Prior" &&
              props.reportData.uniqueReportId !== 2128 && (
                <Form.Group as={Row} controlId="">
                  {/* <Form.Label className="sec-ttl"></Form.Label> */}
                  <Form.Label column sm="3" className="sec-ttl"></Form.Label>
                  <div className="col-sm-9">
                    <PeriorYearSelect
                      reportid={props.reportData.moduleReportId}
                      startvalue={"PriorYear"}
                      defaultValue={state.defaultYear}
                      paramterValue={props?.paramterValue}
                      handlePeriorYear={handlePeriorYear}
                    />
                  </div>
                </Form.Group>
              )}
          </>
        ),
      },
      {
        controlName: "ddlAccountingStart",
        control: (
          <>
            {props.reportData.uniqueReportId !== 2128 && (
              <Form.Group controlId="">
                <Form.Label className="sec-ttl">Accounting Period</Form.Label>
                <Form.Group as={Row} controlId="">
                  <Form.Label column sm="3" className="sec-ttl">
                    {props.reportData.uniqueReportId === 2044 ? "" : "Starting"}
                  </Form.Label>
                  <div className="col-sm-9">
                    <AccotingPeriodSector
                      defaultValue={state.lastActiveDate}
                      reportid={props.reportData.moduleReportId}
                      startvalue={"Start"}
                      handleAccotingSelector={handleAccotingSelector}
                    />
                  </div>
                </Form.Group>
              </Form.Group>
            )}
          </>
        ),
      },
      {
        controlName: "ddlAccountingPeriodEnd",
        control: (
          <>
            {props.reportData.uniqueReportId !== 2128 && (
              <Form.Group as={Row} controlId="">
                <Form.Label column sm="3" className="sec-ttl">
                  Thru
                </Form.Label>
                <div className="col-sm-9">
                  <AccountingPeriodEndSector
                    reportid={props.reportData.moduleReportId}
                    startvalue={"End"}
                    defaultValue={state.serverDateTime} //{state.hotelIds}
                    paramterValue={props?.paramterValue}
                    handleAccotingSelectorEnd={handleAccotingSelectorEnd}
                  />
                </div>
              </Form.Group>
            )}
          </>
        ),
      },
      {
        controlName: "ddlChartNofrom",
        control: (
          <>
            {state.coAaccountType === "3" && (
              <Form.Group as={Row} controlId="">
                <Form.Label column sm="3" className="sec-ttl">
                  Account From
                </Form.Label>
                <div className="col-sm-9">
                  <COASelector
                    defaultValue={state.hotelIds}
                    handleCOAAccountSelector={handleCOAAccountSelector}
                  />
                </div>
              </Form.Group>
            )}
          </>
        ),
      },
      {
        controlName: "ddlAgingSelect",
        control: (
          <>
            <Form.Group as={Row} controlId={"ACACODES"}>
              <Form.Label column sm="3" className="sec-ttl">
                Aging
              </Form.Label>
              <div className="col-sm-9">
                <AgingSelector
                  reportid={props.reportData.moduleReportId}
                  defaultValue={state.IsAgingSelect}
                  onAgingSelectChange={handleAgingSelectChange}
                />
              </div>
            </Form.Group>
          </>
        ),
      },
      {
        controlName: "ddlChartNoTo",
        control: (
          <>
            {state.coAaccountType === "3" && (
              <Form.Group as={Row} controlId="">
                <Form.Label column sm="3" className="sec-ttl">
                  Account To
                </Form.Label>
                <div className="col-sm-9">
                  <AccountTOSelector
                    defaultValue={state.hotelIds}
                    handleCOAAccountSelectortow={handleCOAAccountSelectortow}
                  />
                </div>
              </Form.Group>
            )}
          </>
        ),
      },
      {
        controlName: "ddlChartNofrom",
        control: (
          <>
            {state.coAaccountType === "2" && (
              <Form.Group as={Row} controlId="">
                <Form.Label column sm="3" className="sec-ttl"></Form.Label>
                <div className="col-sm-9">
                  <SelectSingleCOA
                    defaultValue={state.hotelIds}
                    handleSingleCoaSelect={handleSingleCoaSelect}
                  />
                </div>
              </Form.Group>
            )}
          </>
        ),
      },

      {
        controlName: "ddlDataSource",
        control: (
          <>
            <Form.Group as={Row} controlId={"ACACODES"}>
              <Form.Label column sm="3" className="sec-ttl">
                Data Source 1
              </Form.Label>
              <div className="col-sm-9">
                <DataSourceOperating
                  reportid={props.reportData.moduleReportId}
                  defaultValue={state.IsdataSoureSelect}
                  onDataSourceOperating={onDataSourceOperating}
                  label={"Data Source"}
                />
              </div>
            </Form.Group>
          </>
        ),
      },
      {
        controlName: "ddlDataSource2NoValue",
        control: (
          <>
            <Form.Group as={Row} controlId={"ACACODES"}>
              <Form.Label column sm="3" className="sec-ttl">
                Data Source 2
              </Form.Label>
              <div className="col-sm-9">
                <DataSource2Selectore
                  reportid={props.reportData.moduleReportId}
                  defaultValue={state.IsdataSoureSelect2}
                  onDataSourceChange2={handleDataSource2Chang}
                  label={"Data Source"}
                />
              </div>
            </Form.Group>
          </>
        ),
      },
      {
        controlName: "ddlDataSource1Periods",
        control: (
          <>
            <Form.Group as={Row} controlId={"ACACODES"}>
              <Form.Label column sm="3" className="sec-ttl">
                Data Source 1
              </Form.Label>
              <div className="col-sm-9">
                <DataSource1Periods
                  reportid={props.reportData.uniqueReportId}
                  defaultValue={state.IsdataSoureSelect}
                  onDataSourceOperating={onDataSourcePeriods}
                  label={"Data Source"}
                />
              </div>
            </Form.Group>
          </>
        ),
      },
      {
        controlName: "ddlDataSource1",
        control: (
          <>
            <Form.Group as={Row} controlId={"ACACODES"}>
              <Form.Label column sm="3" className="sec-ttl">
                Data Source 1
              </Form.Label>
              <div className="col-sm-9">
                <DataSourceSelector
                  reportid={props.reportData.moduleReportId}
                  defaultValue={state.IsdataSoureSelect}
                  onDataSourceChange={handleDataSourceChang}
                  label={"Data Source"}
                />
              </div>
            </Form.Group>
          </>
        ),
      },
      {
        controlName: "ddlDataSource2",
        control: (
          <>
            <Form.Group as={Row} controlId={"ACACODES"}>
              <Form.Label column sm="3" className="sec-ttl">
                Data Source 2
              </Form.Label>
              <div className="col-sm-9">
                <DataSource2Selectore
                  reportid={props.reportData.uniqueReportId}
                  defaultValue={state.IsdataSoureSelect2}
                  onDataSourceChange2={handleDataSource2Chang}
                  label={"Data Source"}
                />
              </div>
            </Form.Group>
          </>
        ),
      },
      {
        controlName: "ddlDataSource2Periods",
        control: (
          <>
            <Form.Group as={Row} controlId="">
              <Form.Label column sm="3" className="sec-ttl">
                Data Source 2
              </Form.Label>
              <div className="col-sm-9">
                <DataSource2Periods
                  reportid={props.reportData.moduleReportId}
                  defaultValue={state.IsdataSoureSelect2}
                  onDataSourceChange2={handleDataSource2Chang}
                  label={"Data Source"}
                />
              </div>
            </Form.Group>
          </>
        ),
      },
      {
        controlName: "ddlCheckRegister",
        control: (
          <>
            <Form.Group as={Row} controlId="">
              <Form.Label column sm="3" className="sec-ttl">
                Report
              </Form.Label>
              <div className="col-sm-9">
                <PaymentRegisterSelector
                  defaultValue={state.ReportName}
                  onReportTypeChange={handleReportChange}
                />
                <span>{state.Reportdesc}</span>
              </div>
            </Form.Group>
          </>
        ),
      },
      {
        controlName: "ddlACACoverageCodes",
        control: (
          <>
            <Form.Group as={Row} controlId="ACACODES">
              <Form.Label column sm="3" className="sec-ttl">
                ACA Coverage Codes
              </Form.Label>
              <div className="col-sm-9">
                <ACACodeSelector
                  accTypeID={1}
                  defaultValue={state.acaCoverageCodes}
                  onCoverageCodesChange={handleACACoverageCodesChange}
                  //label={"ACA Coverage Codes"}
                />
              </div>
            </Form.Group>
          </>
        ),
      },
      {
        controlName: "ddlACAHarborCodes",
        control: (
          <>
            <Form.Group as={Row} controlId="ACAHARBOURCODES">
              <Form.Label column sm="3" className="sec-ttl">
                ACA HARBOUR CODES
              </Form.Label>
              <div className="col-sm-9">
                <ACACodeSelector
                  defaultValue={state.acaACASHarborCodes}
                  onSaferCodesChange={handleACASaferCodesChange}
                  accTypeID={2}
                  //label={"ACA Safe Harbor Codes"}
                />
              </div>
            </Form.Group>
          </>
        ),
      },
      {
        controlName: "ddlStatusString",
        control: (
          <>
            <Form.Group as={Row} controlId="">
              <Form.Label column sm="3" className="sec-ttl">
                {getStatusDropDwonLablelNameBasedOnReport()}
              </Form.Label>
              <div className="col-sm-9">
                <StatusSelector
                  defaultValue={state.statusString}
                  handleDropdownChange={handleStatusStringSelectorChange}
                />
              </div>
            </Form.Group>
          </>
        ),
      },
      {
        controlName: "ddlLookBackPeriod",
        control: (
          <>
            <Form.Group as={Row} controlId="">
              <Form.Label column sm="3" className="sec-ttl">
                Look Back Period (For calculating Average Hours Worked)
              </Form.Label>
              <div className="col-sm-9">
                <LookBackPeriodSelector
                  defaultValue={state.lookBackPeriod}
                  onLookBackPeriodChange={handleLookBackPeriodChange}
                />
              </div>
            </Form.Group>
          </>
        ),
      },
      {
        controlName: "ddlEmployementType",
        control: (
          <>
            <Form.Group as={Row} controlId="">
              <Form.Label column sm="3" className="sec-ttl">
                Employment Type
              </Form.Label>
              <div className="col-sm-9">
                <EmployementTypeSelector
                  defaultValue={state.employementType}
                  onEmpTypeChange={handleEmployementTypeChange}
                />
              </div>
            </Form.Group>
          </>
        ),
      },

      {
        controlName: "ddlReportType",
        control: (
          <>
            <Form.Group as={Row} controlId="">
              <Form.Label column sm="3" className="sec-ttl">
                Report Type
              </Form.Label>
              <div className="col-sm-9">
                <ReportTypeSelector
                  defaultValue={state.reportType}
                  onReportTypeChange={handleReportTypeChange}
                />
              </div>
            </Form.Group>
          </>
        ),
      },

      {
        controlName: "ddlEmployeeMulti",
        control: (
          <>
            <Form.Group as={Row} controlId="">
              <Form.Label column sm="3" className="sec-ttl">
                Employee
              </Form.Label>
              <div className="col-sm-9 employee-multipicker">
                <LaborMultiEmployeeSelector
                  ref={employeeDDLRef}
                  hotelIds={state.hotelIds}
                  handleMultiEmployeeChange={handleMultiEmployeeChange}
                />
              </div>
            </Form.Group>
          </>
        ),
      },

      {
        controlName: "ddlStatus",
        control: (
          <>
            <Form.Group as={Row} controlId="">
              <Form.Label column sm="3" className="sec-ttl">
                Response
              </Form.Label>
              <div className="col-sm-9">
                <LaborResponseStatus
                  handelResponseStatus={handelResponseStatus}
                />
              </div>
            </Form.Group>
          </>
        ),
      },
      {
        controlName: "ddlCOAType",
        control: (
          <>
            <Form.Group as={Row} controlId="">
              <Form.Label column sm="3" className="sec-ttl">
                COA
              </Form.Label>
              <div className="col-sm-9">
                <AccountingCOAType handleCOAType={handleCOAType} />
              </div>
            </Form.Group>
          </>
        ),
      },
      {
        controlName: "ddlReportLayoutforDates",
        control: (
          <>
            <Form.Group as={Row} controlId="">
              <Form.Label column sm="3" className="sec-ttl">
                Layout
              </Form.Label>
              <div className="col-sm-9">
                <DateLayOutSelector
                  defaultValue={state.dateLayOutSelector}
                  handleDateLayOut={handleDateLayOut}
                />
              </div>
            </Form.Group>
          </>
        ),
      },
      {
        controlName: "ddlRowTittle",
        control: (
          <>
            {state.reportType !== "ByEmployee" &&
              props.reportData.uniqueReportId !== 2128 && (
                <Form.Group as={Row} controlId="">
                  <Form.Label column sm="3" className="sec-ttl">
                    Row Titles
                  </Form.Label>
                  <div className="col-sm-9">
                    <RowTittle
                      defaultValue={state.rowTitles}
                      handleRowTittle={handleRowTittle}
                    />
                  </div>
                </Form.Group>
              )}
          </>
        ),
      },
      {
        controlName: "ddlDisputeStatus",
        control: (
          <>
            {state.reportType !== "ByEmployee" && (
              <Form.Group as={Row} controlId="">
                <Form.Label column sm="3" className="sec-ttl">
                  Dispute Status
                </Form.Label>
                <div className="col-sm-9">
                  <DisputeStatus
                    defaultValue={state.disputueStatus}
                    handleDisputeStauts={handleDisputeStauts}
                  />
                </div>
              </Form.Group>
            )}
          </>
        ),
      },
      {
        controlName: "ddlType",
        control: (
          <>
            {props.reportData.uniqueReportId !== 2128 && (
              <Form.Group as={Row} controlId="">
                {/* <Form.Label className="sec-ttl"></Form.Label> */}
                <Form.Label column sm="3" className="sec-ttl"></Form.Label>
                <div className="col-sm-9">
                  <DDLReportType
                    defaultValue={state.reportSubTypes}
                    handleddlType={handleddlType}
                  />
                </div>
              </Form.Group>
            )}
          </>
        ),
      },
      {
        controlName: "ddlMultiTab",
        control: (
          <>
            {props.reportData.uniqueReportId !== 2128 && (
              <Form.Group as={Row} controlId="">
                {/* <Form.Label className="sec-ttl"></Form.Label> */}
                <Form.Label column sm="3" className="sec-ttl"></Form.Label>
                <div className="col-sm-9">
                  <MultiTabls
                    defaultValue={state.multiTabs}
                    handleMultiTabls={handleMultiTabls}
                  />
                </div>
              </Form.Group>
            )}
          </>
        ),
      },
    ];
  };

  const updateHtmlControlState = (state: IParameterSelectionState) => {
    setCompState(state);
    buildFilterControls(state);
    if (reportInputParameters?.length > 0) {
      let _htmlControls = htmlControls

        .map((r) => {
          if (controlshouldBeVisible(r?.controlName)) {
            const control = getControl(r?.controlName);
            r.displayOrder = control?.displayOrder;
            r.isRequired = control?.isRequired;
            r.isVisible = true;
            r.isSameDateRangeYear = control?.isSameDateRangeYear;
          }
          return r;
        })
        ?.filter((x) => x?.isVisible);

      if (_htmlControls.length > 1) {
        _htmlControls = _htmlControls?.sort(
          (a, b) => a?.displayOrder - b?.displayOrder
        );
      }

      setVisibleHtmlControls(_htmlControls);
    }

    if (visibleHtmlControls?.length > 0) {
      let _htmlControls = htmlControls

        .map((r) => {
          if (controlshouldBeVisible(r?.controlName)) {
            const control = getControl(r?.controlName);
            r.displayOrder = control?.displayOrder;
            r.isRequired = control?.isRequired;
            r.isVisible = true;
          }
          return r;
        })
        ?.filter((x) => x?.isVisible);

      if (_htmlControls.length > 1) {
        _htmlControls = _htmlControls?.sort(
          (a, b) => a?.displayOrder - b?.displayOrder
        );
      }

      setVisibleHtmlControls(_htmlControls);
    }
  };

  return (
    <div className="report-parameter-selection report-parameter-selection-schedule">
      {!dataFetched ? (
        <ReactPageLoader
          useas={"reportsParaSelection reportsScheduleLeftPanel"}
        />
      ) : (
        <>
          <div className="section-body">
            {visibleHtmlControls?.length > 0 && (
              <div className="sec-group">
                <div className="section">
                  {visibleHtmlControls?.length > 0 && (
                    <div className="frm-section">
                      {visibleHtmlControls.map((item) => (
                        <>{item.control}</>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            )}
            {dataFetched && visibleHtmlControls?.length === 0 && (
              <div className="no-parameter-msg">
                This report does not require settings
              </div>
            )}

            <div className="horizontal-separator"></div>
          </div>
        </>
      )}
    </div>
  );
};
