import React, { useEffect, useState, memo, useMemo } from "react";
import { Card } from "react-bootstrap";
import { Bar, Line, Pie } from "react-chartjs-2";
import { Utils } from "../../../Common/Utilis";
import BarChartCard from "./Cards/BarChartCard";
import LineChartCard from "./Cards/LineChartCard";
import NumberCard from "./Cards/NumberCard";
import ProgressChart from "./Cards/ProgressChartCard";
import { dummyMetricDataNumberCard } from "../Data/metricsData";
import ContentLoader from "react-content-loader";
import "../../../../node_modules/react-grid-layout/css/styles.css";
import "../../../../node_modules/react-resizable/css/styles.css";
import moment from "moment";
import _ from "lodash";
import { PortfolioDashboard } from "../../../Common/Services/PortfolioDashboard";

const BRACHARTCOLORS = [`#065A8C`, "#FCA147", "#875BF7", "#B12A18", "#0ff"];
function getShortMonthName(monthNumber) {
  if (monthNumber < 1) {
    return "Invalid month number. Please provide a positive number.";
  }
  const adjustedIndex = (monthNumber - 1) % 12; // Wrap around using modulo
  return moment.monthsShort()[adjustedIndex];
}

function generateChartData(
  numPoints,
  numBars = 1,
  chartComparison,
  xLabel,
  chartType,
  Hids: any = []
) {
  const lineChartConfig = (index) =>
    chartType.toLowerCase() === "line" &&
    chartComparison[index]?.value?.toLowerCase() !== "actual"
      ? {
          borderDash: [5, 5], // Makes the line dotted
          fill: false,
        }
      : {};

  const data = {
    labels: Array.from({ length: numPoints }, (_, i) =>
      chartType.toLowerCase() === "line"
        ? getShortMonthName(i + 1)
        : Hids.length === 0
        ? xLabel
        : chartType.toLowerCase() === "bar"
        ? Hids[i]?.value
        : getShortMonthName(i + 1)
    ),
    datasets: Array.from({ length: numBars }, (_, i) => ({
      display: false,
      label: chartComparison[i].value, // Each dataset will have its own label
      data: Array.from({ length: numPoints }, (__, j) => {
        // Example of using a sine wave to generate curved data
        const sineWaveValue =
          Math.sin(((j + 1) * Math.PI) / 5) * 50 + 50 + Hids.length; // Curved data using sine
        return sineWaveValue + i * 20; // Offset by dataset index to give variations between bars
      }),
      backgroundColor: BRACHARTCOLORS[i], // Different color for each bar
      borderColor: BRACHARTCOLORS[i], // Different color for each bar
      ...lineChartConfig(i),
    })),
  };

  return data;
}

// function generateChartData(
//   numPoints,
//   numBars = 1,
//   chartComparison,
//   xLabel,
//   chartType,
//   Hids: any = []
// ) {
//   const lineChartConfig = (index) =>
//     chartType.toLowerCase() == "line" &&
//     chartComparison[index]?.value?.toLowerCase() != "actual"
//       ? {
//           borderDash: [5, 5], // Makes the line dotted
//           fill: false,
//         }
//       : {};

//   const data = {
//     labels: Array.from({ length: numPoints }, (_, i) =>
//       chartType.toLowerCase() == "line"
//         ? getShortMonthName(i + 1)
//         : Hids.length == 0
//         ? xLabel
//         : chartType.toLowerCase() == "bar"
//         ? Hids[i]?.value
//         : getShortMonthName(i + 1)
//     ),
//     datasets: Array.from({ length: numBars }, (_, i) => ({
//       display: false,
//       label: chartComparison[i].value, // Each dataset will have its own label
//       data: Array.from(
//         { length: numPoints },
//         () => Math.floor(Math.random() * 100) + 1
//       ),
//       backgroundColor: BRACHARTCOLORS[i], // Different color for each bar
//       borderColor: BRACHARTCOLORS[i], // Different color for each bar
//       ...lineChartConfig(i),
//       // visible: true,
//       //   barThickness: 20,
//       //   categoryPercentage : 1,
//       // barPercentage: 0.5,
//     })),
//   };

//   return data;
// }

export function isValidChartData(data: any): boolean {
  // Check if 'data' has 'labels' and 'datasets' properties
  if (!data || typeof data !== "object") return false;

  const hasLabels = Array.isArray(data.labels);
  const hasDatasets =
    Array.isArray(data.datasets) &&
    data.datasets.every(
      (dataset: any) =>
        dataset &&
        typeof dataset === "object" &&
        Array.isArray(dataset.data) &&
        typeof dataset.label === "string"
    );

  return hasLabels && hasDatasets;
}

const actual = 60;
const budget = 100;

function MetricChartRender({
  chartTitle,
  chartType,
  metric,
  isDummyData,
  chartComparison,
  showOnly,
  layoutItem,
  WidgetData
}) {
  const [data, setData] = useState<any>(null);

  const formatType = metric?.paramFormat || "currency";

  useEffect(() => {
    // Helper function to get dummy data for "number" type
    const getDummyNumberData = () => {
      const dataActual = WidgetData.find(item  => item.type == "Actual" )
      let dataComparisons = WidgetData.filter(item  => item.type != "Actual" )

      const actualValue=dataActual.dataValue

      // dataComparisons=dataComparisons.map(item  =>  ({...item, subvalue :}))
        // dummyMetricDataNumberCard.find(
        //   (item: any) => item.type?.toLowerCase() === formatType
        // ) || dummyMetricDataNumberCard[0];
      // if (!isDummyData && (metric?.hids?.length || 0) === 0) {
        // data.value = "0";
        // data.subvalue = "0";
      // }
      // return data;

      const data={
        value:actualValue
        // comparisonSubValue:
      }

      return data
    };

    const getWidgetNumberData = () => {
      const data =
        dummyMetricDataNumberCard.find(
          (item: any) => item.type?.toLowerCase() === formatType
        ) || dummyMetricDataNumberCard[0];
      if (!isDummyData && (metric?.hids?.length || 0) === 0) {
        data.value = "0";
        data.subvalue = "0";
      }
      return data;
    };
  
    // Helper function to generate progress chart data
    const getProgressData = () => {
      const HidCount = metric?.hids?.length || 0;
      return {
        actual: HidCount === 0 ? 0 : actual,
        budget: HidCount === 0 ? 0 : budget,
      };
    };
  
    // Helper function to generate other chart data
    const getOtherChartData = () => {
      const HidCount = showOnly
        ? Math.min(metric?.hids?.length || 0, showOnly)
        : metric?.hids?.length || 0;
      return generateChartData(
        HidCount,
        chartComparison?.length + 1 || 1,
        [{ value: "Actual", description: "Actual" }, ...(chartComparison || [])],
        chartType.toLowerCase() === "bar" ? "Hotel" : "Month",
        chartType,
        metric?.hids
      );
    };
  
    // Main logic
    if (chartType?.toLowerCase() === "number") {
      setData(isDummyData ?  getWidgetNumberData(): getWidgetNumberData());
    } else if (chartType?.toLowerCase() === "progress") {
      setData(isDummyData ? { actual, budget } : getProgressData());
    } else {
      const HidCount = isDummyData
        ? metric?.hids?.length || showOnly
        : metric?.hids?.length || 0;
      setData(
        isDummyData
          ? generateChartData(
              HidCount,
              chartComparison?.length + 1 || 1,
              [{ value: "Actual", description: "Actual" }, ...(chartComparison || [])],
              chartType.toLowerCase() === "bar" ? "Hotel" : "Month",
              chartType
            )
          : getOtherChartData()
      );
    }
  }, [
    formatType,
    showOnly,
    chartType,
    chartComparison?.length,
    metric,
    metric?.hids?.length,
    actual,
    budget,
  ]);
  

  if (!data) {
    return <></>;
  }

  switch (chartType?.toLowerCase()) {
    case "bar":
      // return <></>
      return (
        <BarChartCard
          chartTitle={chartTitle}
          data={data}
          formatType={formatType}
          metric={metric}
          isDummyData={isDummyData}
        />
      );
    case "progress":
      // return <></>
      return (
        <ProgressChart
          layoutItem={layoutItem}
          chartTitle={chartTitle}
          budget={chartComparison.length == 0 ? data.actual : data.budget}
          actual={data.actual}
          formatType={formatType}
          chartComparison={chartComparison}
        />
      );
    case "line":
      // return <></>
      return (
        <LineChartCard
          chartTitle={chartTitle}
          data={data}
          formatType={formatType}
          metric={metric}
          isDummyData={isDummyData}
        />
      );
    case "number":
    default:
      return (
        <NumberCard
          formatType={formatType}
          chartTitle={chartTitle}
          data={data}
          chartComparison={chartComparison}
          layoutItem={layoutItem}
        />
      );
  }
}

const MetricChart = memo(
  ({
    chartType,
    metric,
    chartTitle,
    chartComparison,
    showOnly = null,
    isDummyData = false,
    isDummyCard=true,
    layoutItem,
  }: any) => {
    const [WidgetData, setWidgetData] = useState<any>(null);
    const [loading, setLoading] = useState(false);

    const getWidgetData=()=>{
      const hidsList = metric.hids.map((item) => item.value);
      const requestData = {
        WidgetId: metric.id,
        Hids: hidsList.join(","),
        FromDate: "11/01/2024",
        ToDate: "11/01/2024",
        Period: 1,
      };
      setLoading(true);
      PortfolioDashboard.GetDashboardWidgetData(requestData)
        .then((res) => {
          setWidgetData(res.result)
          console.log({ res });
        })
        .catch((err) => {
          console.log({ err });
        })
        .finally(() => {
          setWidgetData(null)
          setLoading(false);
        });
    }

    // Dummy promise to simulate data fetching
    useEffect(() => {
      if(!isDummyData){
        // getWidgetData()
      }
    }, []);

    // Memoize the MetricChartRender output
    const memoizedChartRender = useMemo(() => {
      return (
        <MetricChartRender
          chartTitle={chartTitle}
          chartType={chartType}
          metric={metric}
          isDummyData={isDummyData}
          chartComparison={chartComparison}
          showOnly={showOnly}
          layoutItem={layoutItem}
          WidgetData={WidgetData}
        />
      );
    }, [
      chartTitle,
      chartType,
      metric,
      isDummyData,
      chartComparison,
      showOnly,
      layoutItem,
    ]);

    if (loading) {
      return (
        <ContentLoader
          height={300}
          width={580}
          speed={2}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          className="m-2"
        >
          <rect x="0" y="0" rx="5" ry="5" width="580" height="300" />
        </ContentLoader>
      );
    }

    const style = isDummyCard
      ? {}
      : {
          aspectRatio: "4 / 3", // Adjust this ratio as needed (e.g., "16 / 9" for a widescreen look)
          width: "100%", // This will make it responsive within its container
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        };

    return (
      <div key={metric?.paramDisplayName} style={style}>
        {memoizedChartRender}
      </div>
    );
  }, // Custom comparison function (optional)
  (prevProps, nextProps) => {
    // Only re-render if props have actually changed

    return (
      prevProps.chartType === nextProps.chartType &&
      prevProps.metric === nextProps.metric &&
      prevProps.metric?.hids?.length === nextProps.metric?.hids?.length &&
      prevProps.chartTitle === nextProps.chartTitle &&
      prevProps.chartComparison === nextProps.chartComparison &&
      prevProps.showOnly === nextProps.showOnly &&
      prevProps.isDummyData === nextProps.isDummyData &&
      prevProps.isDummyCard === nextProps.isDummyCard
    );
  }
);

export default MetricChart;
