import Instense from "./Axios-config";


export class CommonService {
  
  public static GetInnflowConfigKeyValue = async (
    KeyType: string
  ): Promise<any | null> => {
    // Need to put this and all future URLs into a config js file    
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID as any; 
    let request : any ={};
    request.KeyType = KeyType;
    const url = `/Utility/GetInnflowConfigKeyValue`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };
}
