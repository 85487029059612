import React from "react";
import { toast, ToastContainer } from "react-toastify";
import {
  Container,
  Dropdown,
  Form,
  Tab,
  Tabs,
  Row,
  Nav,
  Button,
  Modal,
} from "react-bootstrap";
import moment from "moment";
import { FundingSourceModal } from "./FundingSourceModal";

export class FundingOptionModal extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      showSourceModal: false,
    };
  }

  componentDidMount() {}

  openPlaid = () => {
    this.props?.handleShowHideChoiceModal();
    this.props?.plaidFunction();
  };

  handleShowHideChoiceModal = () => {
    this.props?.handleShowHideChoiceModal();
  };

  handleSourceModal = () => {
    this.setState({ showSourceModal: !this.state.showSourceModal });
  };

  render() {
    return (
      <div id="" className="entity-management">
        <ToastContainer containerId={"userSlideOut"} autoClose={3000} />
        {this.state?.showSourceModal && (
          <FundingSourceModal
            handleSourceModal={this.handleSourceModal}
            showSourceModal={this.state?.showSourceModal}
            handleShowHideChoiceModal={this.props.handleShowHideChoiceModal}
            bankName={this.props?.bankName}
            accountNo={this.props?.accountNo}
            accountName={this.props?.accountName}
            hotelID={this.props?.hotelID}
            bankRefID={this.props?.bankRefID}
            PlaiddSetupStatus={this.props?.PlaiddSetupStatus}
            oprID={this.props.oprID}
            bindFormData={this.props?.bindFormData}
          />
        )}
        <Container fluid className="body-sec">
          <Modal
            className="add-entity-modal"
            show={this.props.showChoiceModal}
            onHide={() => this.handleShowHideChoiceModal()}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Choose Verification Mode</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="add-entity-title">
                Kindly proceed how do you want to verify your account details ?
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => this.openPlaid()}
              >
                Instant Verification
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => this.handleSourceModal()}
              >
                Delayed Verification
              </button>
            </Modal.Footer>
          </Modal>
        </Container>
      </div>
    );
  }
}
