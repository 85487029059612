import React from "react";
import {
  Container,
} from "react-bootstrap";
import { HotelGroupSelector } from "./Components/HotelGroupSelector";
import _ from "lodash";
import { LaborDashBoard } from "../../Common/Services/LaborDashBoard";
import { ILaborSaveAsDefaultDto } from "../../Common/Contracts/ILaborSaveAsDefaultDto";
import { LaborPerformance } from "../../Common/Services/LaborPerfomance";
import { ToastContainer, toast } from "react-toastify";
import "./addRgbColor.scss";
import { CalendarSelector } from "./Components/CalendarSelector";
import { Hotel } from "../../Common/Services/Hotel";
import LaborDashboardTable from "./LaborDashboardTable";
import LaborHouseKeepingDashboardTable from "./LaborHouseKeepingDashboardTable";
import moment from "moment";
import { Utils } from "../../Common/Utilis";


const scrollRef: any = React.createRef();

export class HousekeepingDashboard extends React.Component<any, any> {
  private pageName: string = "LaborHouseKeepingDashboard";


  constructor(props: any) {
    super(props);

    this.state = {
      isLoadingDefaultValue: true,
      isLoadingDefaultDate:true,
      moduleName: "Labor Management",

      //Common Filter
      hotelIds: 0,
      subGroupSelectValue: "Group",
      chartPeriodValue: 1,
      chartPeriodValueHouseKeeping: 1,
      comparisonPeriodValue: 2,
      comparisonPeriodValueHouseKeeping: 2,

      
      hotelName: "",
      calendarDate: null,
      pageHeading: "Labor",
   

      parameterId: 1,
   
      defaultView: {},
      columnSelectorData:[],
      dailyFlashSortingData:{},
      houseKeepingSortingData:{},

      isChartLoadingMain: false,
      isCellClicked:false,

      isChartLoadingMainHouseKeeping: false,
      isCellClickedHousekeeping:false

    };
  }

  componentDidMount(): void {
  
    this.setDate();
    this.getDefaultView();

  }
  setDate = ()=>{
    this.setState({ isLoadingDefaultDate: true })
    Hotel.getGblGetNow().then((res)=> {
      if(res)
      {
        const value=moment(res).subtract(1, 'days').format("MM/DD/YY")  
        this.setState({calendarDate:value,isLoadingDefaultDate:false})
      }
      else{
        this.setState({isLoadingDefaultDate:false})
      }
    }).catch(err =>{
      this.setState({isLoadingDefaultDate:false})
    })
  }
  

  handleCalendarDate = (date) => {
    if (this.state?.calendarDate !== date) {
      this.setState({ calendarDate: date });
    }
  }


  handleHotelChange = (
    hotelIds: any,
    subGroupSelectValue: any,
    hotelName: any
  ) => {


    this.setState(
      {
        hotelIds,
        hotelName,
        subGroupSelectValue
      }
    );
  };



  getDefaultView() {
    this.setState({ isLoadingDefaultValue: true })
    LaborPerformance.getDefaultViewProperties(this.pageName + this.state.pageHeading)
      .then(async (response: any | null) => {

        if (response?.length > 0) {
          const result = response;

          const subGroupSelectValue = result.find(
            (item) => item.fieldName === "subGroupSelectValue"
          )?.fieldValue;

          const hotelIds = result.find(
            (item) => item.fieldName === "hotelIds"
          )?.fieldValue || 0;


          const chartPeriodValue = +result.find(
            (item) => item.fieldName === "chartPeriodValue"
          )?.fieldValue || 1;

          const chartPeriodValueHouseKeeping = +result.find(
            (item) => item.fieldName === "chartPeriodValueHouseKeeping"
          )?.fieldValue || 1;


          const comparisonPeriodValue = +result.find(
            (item) => item.fieldName === "comparisonPeriodValue"
          )?.fieldValue || 1;

          const comparisonPeriodValueHouseKeeping = +result.find(
            (item) => item.fieldName === "comparisonPeriodValueHouseKeeping"
          )?.fieldValue || 1;

          let columnSelectorData = result.find(
            (item) => item.fieldName === "columnSelectorData"
          )?.fieldValue || ""
          if(columnSelectorData) {
            columnSelectorData = JSON.parse(columnSelectorData) || [];
            columnSelectorData= Array.isArray(columnSelectorData) ?columnSelectorData:[];
          }
          else {
            columnSelectorData = [];

          }
          let dailyFlashSortingData = result.find(
            (item) => item.fieldName === "dailyFlashSortingData"
          )?.fieldValue || ""
          if(dailyFlashSortingData) {
            dailyFlashSortingData = JSON.parse(dailyFlashSortingData) || {};
          }
          else {
            dailyFlashSortingData = {};

          }

          let houseKeepingSortingData = result.find(
            (item) => item.fieldName === "houseKeepingSortingData"
          )?.fieldValue || ""
          if(houseKeepingSortingData) {
            houseKeepingSortingData = JSON.parse(houseKeepingSortingData) || {};
          }
          else {
            houseKeepingSortingData = {};

          }

            this.setState(
              {
                subGroupSelectValue,
                hotelIds,
                chartPeriodValue,
                chartPeriodValueHouseKeeping,
                comparisonPeriodValue,
                comparisonPeriodValueHouseKeeping,
                columnSelectorData,
                dailyFlashSortingData,
                houseKeepingSortingData,
                defaultView: {
                  subGroupSelectValue,
                  hotelIds,
                  chartPeriodValue,
                  chartPeriodValueHouseKeeping,
                  comparisonPeriodValue,
                  comparisonPeriodValueHouseKeeping,
                }
              },()=>  {
              this.setState({isLoadingDefaultValue: false});
              }
            );


        } else {
          this.setState({ isLoadingDefaultValue: false });
        }
      })
      .catch((error) => {
        this.setState({ isLoadingDefaultValue: false })

        Utils.toastError(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      })

  }


  handleSaveDefaultView = (isHouseKeeping = false, columnSelectorData =null , houseKeepingSortingData =null): void => {
    const laborSaveAsDefaultdtos: ILaborSaveAsDefaultDto[] = [];
    const {
      subGroupSelectValue,
      hotelIds,
      hotelName,
      calendarDate,
      chartPeriodValue,
      comparisonPeriodValue,
      comparisonPeriodValueHouseKeeping,
      chartPeriodValueHouseKeeping,
      defaultView
    } = this.state;

    const pageName = this.pageName + this.state.pageHeading;
    laborSaveAsDefaultdtos.push({
      fieldName: "subGroupSelectValue",
      fieldValue: subGroupSelectValue,
      pageName: pageName
    });
    laborSaveAsDefaultdtos.push({
      fieldName: "hotelIds",
      fieldValue: hotelIds,
      pageName: pageName,
    });

    laborSaveAsDefaultdtos.push({
      fieldName: "hotelName",
      fieldValue: hotelName,
      pageName: pageName,
    });

    laborSaveAsDefaultdtos.push({
      fieldName: "calendarDate",
      fieldValue: calendarDate,
      pageName: pageName,
    });
   
if(columnSelectorData && columnSelectorData!=='none'){

  laborSaveAsDefaultdtos.push({
    fieldName: "columnSelectorData",
    fieldValue:  JSON.stringify(columnSelectorData),
    pageName: pageName,
  });
}


if(houseKeepingSortingData!=='none') {
laborSaveAsDefaultdtos.push({
  fieldName: "houseKeepingSortingData",
  fieldValue:  JSON.stringify(houseKeepingSortingData),
  pageName: pageName,
});
}



    laborSaveAsDefaultdtos.push({
      fieldName: "chartPeriodValueHouseKeeping",
      fieldValue: isHouseKeeping? chartPeriodValueHouseKeeping : defaultView?.chartPeriodValueHouseKeeping,
      pageName: pageName,
    });

    laborSaveAsDefaultdtos.push({
      fieldName: "comparisonPeriodValueHouseKeeping",
      fieldValue: isHouseKeeping ? comparisonPeriodValueHouseKeeping : defaultView?.comparisonPeriodValueHouseKeeping,
      pageName: pageName,
    });


    laborSaveAsDefaultdtos.push({
      fieldName: "chartPeriodValue",
      fieldValue: isHouseKeeping ? defaultView?.chartPeriodValue : chartPeriodValue,
      pageName: pageName,
    });


    laborSaveAsDefaultdtos.push({
      fieldName: "comparisonPeriodValue",
      fieldValue: isHouseKeeping ?  defaultView?.comparisonPeriodValue : comparisonPeriodValue,
      pageName: pageName,
    });




    LaborPerformance.saveDefaultViewProperties(laborSaveAsDefaultdtos)
      .then(async (result: any | null) => {
        this.setState({ loaderTrue: false });
        if (result != null) {
          if (result.message === "Success") {
            toast.success(result?.result?.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
      })
      .catch((error) => {
        Utils.toastError(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };



  onChartPeriodChange = (item) => {
    this.setState({ chartPeriodValue: item.value });
  }

  handleMainChartLoading = (value) => {
    this.setState({ isChartLoadingMain: value });
  }
  handleMainChartLoadingHouseKeeping = (value) => {
    this.setState({ isChartLoadingMainHouseKeeping: value });
  }

  handleIsCellClicked = (value) => {
    this.setState({ isCellClicked: value });
  }
  handleIsCellClickedHouseKeeping = (value) => {
    this.setState({ isCellClickedHousekeeping: value });
  }


  onComparisonPeriodChange = (item) => {
    this.setState({ comparisonPeriodValue: item.value });
  }

  onChartPeriodHousekeepingChange = (item) => {
    this.setState({ chartPeriodValueHouseKeeping: item.value });
  }

  onComparisonPeriodHousekeepingChange = (item) => {
    this.setState({ comparisonPeriodValueHouseKeeping: item.value });
  }



  render() {
    const { hotelIds, isLoadingDefaultValue, isLoadingDefaultDate,subGroupSelectValue,
      chartPeriodValue, comparisonPeriodValue, chartPeriodValueHouseKeeping, comparisonPeriodValueHouseKeeping, moduleName, calendarDate,isChartLoadingMain,isChartLoadingMainHouseKeeping,isCellClicked,isCellClickedHousekeeping
      ,columnSelectorData  } = this.state


    

    return (

      <div className="labor-dashboard userManagementModule labor-dashboard-wrapper" >
             
        <Container fluid className="body-sec px-0 pt-0" ref={scrollRef} onScroll={() => {$(".daily-approval-popup,.notes-sec-popup").hide()} }>
        
        <ToastContainer
                    autoClose={3000}
                    containerId={"laborDashBoard"}
                    enableMultiContainer
                />
          <div className="header-area">
            <div className="position-relative pt-1">
              {hotelIds !=0 && hotelIds?.split(",").length > 50 ? <span className="performance-notification">Selecting more than 50 hotels will decrease dashboard performance.</span> : <></>}
              <div className="page-heading page-header">
                <div>
                  <h5 className="title-badge">Labor</h5>
                  <div className="mr-auto d-flex align-items-center">
                    Housekeeping
                    <span className="badge color-grey-beta">BETA</span>
                  </div>
                </div>
                
                
                <div className="navigation-menus">
                { !isLoadingDefaultDate && <CalendarSelector
                        id={"chartPeriodSelector"}
                        defaultValue={calendarDate}
                        onChange={this.handleCalendarDate}
                        minDate={new Date(moment().subtract(90, 'days').format("MM/DD/YY"))}
                      ></CalendarSelector>}
                
                </div>
              </div>
            </div>
            {/* <div className="navigation-menus">
              <div className="d-flex">
                {(!isLoadingDefaultValue) && (
                  <>
                    <HotelGroupSelector
                      hotelIds={hotelIds}
                      subGroupSelectValue={subGroupSelectValue}
                      onHotelChange={this.handleHotelChange}
                      notIncludeEnterpriseAccounts={false} // default Props
                      showOnlyHotelAccounts={false} // default Props
                      moduleName={moduleName}  // default Props
                    ></HotelGroupSelector>


                  { !isLoadingDefaultDate && <CalendarSelector
                      id={"chartPeriodSelector"}
                      defaultValue={calendarDate}
                      onChange={this.handleCalendarDate}
                      minDate={new Date(moment().subtract(90, 'days').format("MM/DD/YY"))}
                    ></CalendarSelector>}
                  </>
                )
                }
              </div>
            </div> */}
          </div>

          <div className="widget-area">
             
          {!this.state.isLoadingDefaultValue && <LaborHouseKeepingDashboardTable
              hotelIds={hotelIds} 
              calendarDate={calendarDate}
              subGroupSelectValue={subGroupSelectValue}
              moduleName={moduleName}
              handleHotelChange={this.handleHotelChange}
              periodValue={chartPeriodValueHouseKeeping}
              onChartPeriodChange={this.onChartPeriodHousekeepingChange}
              varianceValue={comparisonPeriodValueHouseKeeping}
              onComparisonPeriodChange={this.onComparisonPeriodHousekeepingChange}
              handleSaveDefaultView={this.handleSaveDefaultView}
              isDefaultSaveDataLoaded ={!this.state.isLoadingDefaultValue}
              houseKeepingSortingData ={this.state.houseKeepingSortingData}

              isChartLoadingMain={isChartLoadingMainHouseKeeping}
              handleMainChartLoading={this.handleMainChartLoadingHouseKeeping}

              isCellClicked={isCellClickedHousekeeping}
              handleIsCellClicked={this.handleIsCellClickedHouseKeeping}
              columnSelectorData={columnSelectorData}
            />
          }

          </div>

        </Container>
      </div>
    )

  }
}
