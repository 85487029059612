import * as React from "react";
import {
  //  Container,
  Form,
  // Dropdown, 
  OverlayTrigger, Tooltip,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import cellEditFactory from "react-bootstrap-table2-editor"; 
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { resolve, reject } from "q";
import PageLoader from "../../Common/Components/PageLoader";
import {
  ILaborDepartmentDto,
  // ILaborPositionMappingDto
} from "../../Common/Contracts/ILaborDeptPosition";
import { LaborDeptPosition as laborDeptPosition } from "../../Common/Services/LaborDeptPosition";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
 
import TableSearch from "../../Common/Components/TableSearch";
import { SearchMultiSelectItems } from "../../Common/Components/SearchMultiSelectItems";
import { SingleSearchDropdownList } from "../../Common/Components/SingleSearchDropdownList";
import { Utils } from "../../Common/Utilis";

let selectedarray: any[];
export class LaborDepartmentAccess extends React.Component<any, any> {
  private input: any;
  private child: any;
  private ddlDepartment: any;
  constructor(props: any) {
    super(props);
    this.input = React.createRef();
    this.child = React.createRef();
    this.ddlDepartment = React.createRef();
    this.state = {
      actualtableData: [],
      tableData: [],
      SearchData: [],
      departmentList: [],
      columns: [],
      selectedItems: [],
      roleList: [],
      tablekey: "text",
      isMountDone: false,
      loaderTrue: false,
      isNewPosition: false,
      currentIndex: 0,
      newIndex: 0,
      defaultRoleName: "Role",
      selectedRoleId: -1,
      isClearAll:false,
    };

    this.handleRemoveNewRow = this.handleRemoveNewRow.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.handleSelectedItem = this.handleSelectedItem.bind(this);
    this.rebindTable = this.rebindTable.bind(this);
  }

  onKeyPress(event) {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  }

  componentDidMount() {
    this.getlaborPositionDept();
    this.getlaborPositionDeptMapping();
    this.CleanSearch();
  }

  rebindTable(roleID: any, isFilter: any) {
    this.CleanSearch();
    this.getlaborPositionDept();
    this.getlaborPositionDeptMapping();
    let roles = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.roleList))
    );
    if (isFilter) {
      let filterdata = roles.filter((e) => e.id === Number(roleID));
      this.handleSelectedItem(2, roleID);
      this.setState({ roleList: [] }, () => {
        this.setState({
          roleList: roles,
          defaultRoleName: filterdata.length > 0 ? filterdata[0].name : "Role",
        });
      });
    } else {
      let data = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.actualtableData))
      );

      this.setState({ tableData: [], roleList: [], isMountDone: false }, () => {
        this.setState({ tableData: data, isMountDone: true });
        this.setState({
          roleList: roles,
          defaultRoleName: "Role",
          selectedRoleId: -1,
        });
      });
    }
  }

  handleSelectedItem = (controlID: any, id: any) => {
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.actualtableData))
    );
    if (id === Number(-1)) {
      this.setState({ tableData: [], isMountDone: true }, () => {
        this.setState({ tableData: data });
      });
    } else {
      let filterdata = data.filter((e) => e.roleID === Number(id));

      this.setState(
        { tableData: [], isMountDone: true, selectedRoleId: id },
        () => {
          this.setState({ tableData: filterdata });
        }
      );
    }
  };

  getlaborPositionDeptMapping = () => {
    this.setState({ loaderTrue: true });
    laborDeptPosition
      .LaborDepartmentRoleMapping()
      .then(async (result: any[] | null) => {
        if (result != null && result.length > 0) {
          
          this.setState({ tableData: [] }, () => {
            if (this.state.selectedRoleId > 0) {
              let filterdata = result.filter(
                (e) => e.roleID === Number(this.state.selectedRoleId)
              );
              this.setState({
                actualtableData: result,
                tableData: filterdata,
                isMountDone: true,
              });
            } else {
              this.setState({
                actualtableData: result,
                tableData: result,
                isMountDone: true,
                defaultRoleName: "Role",
              });
            }
          });

          let roles: any[] = [];

          let data1 = {} as any;
          data1.id = -1;
          data1.name = "All";

          roles.push(data1);

          result.forEach((element) => {
            let data = {} as any;
            data.id = element.roleID;
            data.name = element.roleName;

            roles.push(data);
          });
          this.setState({ roleList: roles }); 
        }

        resolve();
      })
      .catch((error) => {
        reject();
      });
  };

  sortDeptName = (result) => {
    result.sort(function (a, b) {
      if (a.text.toLowerCase() < b.text.toLowerCase()) {
        return -1;
      }
      if (a.text.toLowerCase() > b.text.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  };

  getlaborPositionDept = () => {
    this.setState({ loaderTrue: true });
    laborDeptPosition
      .getlaborDepartmentList()
      .then(async (result: ILaborDepartmentDto[] | null) => {
        this.setState({ loaderTrue: false });
        if (result != null && result.length > 0) {
          let departments: any[] = [];
          result.forEach((element) => {
            let data = {} as any;
            data.value = element.departmentID;
            data.text = element.departmentName;
            data.isconflict = false;
            data.isChecked = false;
            departments.push(data);
          });
          await this.setState({ departmentList: departments }, () => {
            this.sortDeptName(departments);
          });
        }
        resolve();
      })
      .catch((error) => {
        reject();
        this.setState({ loaderTrue: false });
      });
  };

  //For remove new unsave row from table

  CleanSearch = () => {
    if (this.child.current != null) {
      this.child.current.CleanSearch();
    }
  };

  itemsSelected = async (Chieldstate: any) => {
    let data = Object.assign([], JSON.parse(JSON.stringify(Chieldstate)));
    await this.setState({ selectedItems: data});
    if(data.length === 0){
      this.setState({isClearAll:true}); 
    }
  };

  handleRemoveNewRow = () => {
    let newtableData: any = [];
    newtableData = Object.assign([], this.state.tableData);
    newtableData.splice(-1, 1);
    this.setState({ tableData: newtableData, isNewPosition: false });
  };

  rowEvents = {
    onClick: async (e: any, row: any, rowIndex: any) => {
      let selectedList: any[] = [];
      let arrayList: any[] = [];
      arrayList = row.selectedIDs.split(","); //Number(arrayList[0])
      this.state.departmentList.map((val: any) => {
        for (let i = 0; i < arrayList.length; i++) {
          if (Number(arrayList[i]) === val.value) {
            let data = {} as any;
            data.value = val.value;
            data.text = val.text;
            data.isChecked = true;
            selectedList.push(data);
          }
        }
      });
      this.setState({ selectedItems: selectedList });
      this.setState({ currentIndex: rowIndex });
    },
    onBlur: (e: any, row: any, rowIndex: any) => {},
  };

  cellEdit = cellEditFactory({
    mode: "click",
    blurToSave: true,
    afterSaveCell: (oldValue: any, newValue: any, row: any, column: any) => {
      this.handleRoleMapping(row);
    },
  });

  handleRoleMapping(row: any) {
    let roleId = row.roleID;
    let result = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.selectedItems))
    );
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID);

    let saveData: any[] = [];
    let isData = false;
    result.forEach((element) => {
      let data = {} as any;
      data.roleID = roleId;
      data.deptID = element.value;
      data.tenantID = tenantID;

      let filter = saveData.filter((e) => e.deptID === element.value);
      if (filter.length === 0) {
        saveData.push(data);
      }
      isData = true;
    });
    if (isData) {
      laborDeptPosition
        .saveRoleDeptMapping(saveData)
        .then(async (result: any | null) => {
          if (result != null) {
            if (result.status === "Success") {
              this.getlaborPositionDeptMapping();

              toast.success(result.message, {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "LaborRoleDepartment",
              });
              this.setState({ loaderTrue: false, isNewPosition: false });
              this.setState({ selectedItems: [] });
            } else {
              Utils.toastError(result.message, {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "LaborRoleDepartment",
              });
            }
          } else {
            Utils.toastError("Internal server error.", {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "LaborRoleDepartment",
            });
          }
          this.setState({ loaderTrue: false });
          resolve();
        })
        .catch((error) => {
          reject();
          this.setState({ loaderTrue: false });
        });
    } else {
    if(this.state.isClearAll){
      Utils.toastError("At least 1 department should be selected.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "LaborRoleDepartment",
      });
      return;
    }
    }
  }
  isDepartmentBlank = (cell, row, rowIndex) => {
    return (


      <div className="pos-name" onWheel={this.hoverOutEllipsis.bind(this)}>
          {this.props.manageDept ? (<span>{row.deptName.replace("&amp;","&")}</span>) :
            (<>{!row.deptName.includes("+") ?(<span>{row.deptName.replace("&amp;","&")}</span>):
            (
            
            <OverlayTrigger placement="top" delay={{ show: 0, hide: 0 }} overlay={this.renderTooltip(row)}>
              <span>{row.deptName.replace("&amp;","&")}</span>
            </OverlayTrigger>)
            
            }</>)}
        </div>

     
    );
  };

  renderTooltip = (ehids) => {
    return (
      <Tooltip className="LDAtoolTipLettercodes" id="button-tooltip" {...ehids}>
        {ehids.toolTipDeptNames.replaceAll(",",", ")}
      </Tooltip>
    )
  };

  hoverOutEllipsis = () => {
    $(".tooltip").removeClass("show");
  };

  searchedData = (newResult: any) => {
    this.setState({ SearchData: newResult });
  };

  render() {
    let columns = [
      {
        dataField: "roleID",
        text: "RoleID",
        hidden: true,
      },
      {
        dataField: "roleName",
        text: "Role",
        editable: false,
        classes: (cell, row, rowIndex, colIndex) => {
          if (row.roleName === "Administrator") return "firstclass";
          return "secondclass";
        },
      },
      {
        dataField: "deptName",
        text: "Department",
        formatter: this.isDepartmentBlank,
        editable: (cell, row, rowIndex, colIndex) => {
          if (row.roleName === "Administrator") return false;
          return true;
        },
        editorRenderer: (
          editorProps,
          value,
          row,
          column,
          rowIndex,
          columnIndex
        ) =>
          row.roleName === "Administrator" ? (
            value
          ) : (
            <div className="department-filter">
              <SearchMultiSelectItems
                key={"department-1" + rowIndex}
                {...editorProps}
                value={value}
                itemsList={this.state.departmentList}
                itemsSelected={this.itemsSelected}
                selectedItems={row.selectedIDs}
                placeholder={"Search Department"}
              />
            </div>
          ),
        classes: (cell, row, rowIndex, colIndex) => {
          if (row.roleName === "Administrator") return "firstclass";
          return "secondclass";
        },
      },
    ];

    var loaderTrue = this.state.loaderTrue;
    var loadShow;
    if (loaderTrue) {
      loadShow = <PageLoader />;
    }

    return (
      <div className="body-sec position dept-pos" onKeyPress={this.onKeyPress}>
        {/* <ToastContainer
          autoClose={3000}
          containerId={"LaborRoleDepartment"}
          enableMultiContainer
        /> */}
        {loadShow}

        <div className="">
          <Form>
            {this.state.isMountDone && (
              <ToolkitProvider
                keyField="roleID"
                data={this.state.tableData}
                columns={columns}
                rowEvents={this.rowEvents}
                search={{
                  afterSearch: (newResult: any) => {
                    this.searchedData(newResult);
                  },
                }}
              >
                {(props: {
                  searchProps: JSX.IntrinsicAttributes;
                  baseProps: JSX.IntrinsicAttributes;
                }) => (
                  <div className="pos-rel">
                    {this.state.isNewPosition && (
                      <div className="bck-drop"></div>
                    )}
                    <div className="search-filter d-flex">
                      <label id="labeltableSearch" className="search-label">
                        <TableSearch
                          id="tableSearch"
                          key="tableSearch"
                          ref={this.child}
                          {...props.searchProps}
                          CleanSearch={this.CleanSearch.bind(this)}
                          placeholder={"Search..."}
                        />
                      </label>
                      <div className="mr-auto department-filter">
                        {this.state.roleList.length > 0 && (
                          <SingleSearchDropdownList
                            ref={this.ddlDepartment}
                            itemList={this.state.roleList}
                            handleSelectedItem={this.handleSelectedItem.bind(
                              this,
                              2
                            )}
                            defaultItem={"Role"}
                            defaultText={"Search Role"}
                            defaultName={this.state.defaultRoleName}
                            controlID="2"
                          />
                        )}
                      </div>
                    </div>

                    <div className="cusPos">
                      <div className="position-list labor-dept">
                        <div
                          className={
                            this.state.tableData.length > 5 &&
                            this.state.SearchData.length > 5
                              ? "maxlength"
                              : "minlength"
                          }
                        >
                          <BootstrapTable
                            {...props.baseProps}
                            keyField="roleID"
                            cellEdit={
                              this.props.manageDept ? this.cellEdit : ""
                            }
                            rowEvents={this.rowEvents}
                            hover
                            condensed
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </ToolkitProvider>
            )}
          </Form>
        </div>
      </div>
    );
  }
}
