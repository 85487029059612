import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { Utils } from "../../../../Common/Utilis";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";

function NumberCard({ chartTitle, data, chartComparison, formatType, layoutItem }) {
const aspectRatio=layoutItem ? layoutItem?.w/layoutItem.cols : 1;
  const value =
    formatType == "currency"
      ? Utils.currencyFormat(Number(data.value || 0).toFixed(0))
      : formatType == "percentage"
      ? `${data.value}%`
      : data.value;

  const isPositive = formatType == "currency" ? true : false; // temp condition
  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className + " d-flex justify-content-center align-items-center"} onClick={onClick}>
        <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.03029 9.28026L6.31054 5.00001L2.03029 0.719757L0.969788 1.78026L4.18954 5.00001L0.969788 8.21976L2.03029 9.28026Z" fill="#2CAF92" />
        </svg>
      </div>
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className + " d-flex justify-content-center align-items-center"} onClick={onClick}>
        <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.03029 9.28026L6.31054 5.00001L2.03029 0.719757L0.969788 1.78026L4.18954 5.00001L0.969788 8.21976L2.03029 9.28026Z" fill="#2CAF92" />
        </svg>
      </div>
    );
  }
const settings = {
  // className: "slider variable-width",
  // variableWidth: true,
  autoplay: false,
  dots: false,
  infinite: false,
  arrows: true,
  speed: 500,
  slidesToShow: aspectRatio >= 0.5 ? 3 :  aspectRatio == 0.3 ? 2 : 1,
  slidesToScroll: aspectRatio == 0.5 ? 2 : 1,
  // centerPadding: "20px",
  // centerMode: true,
  
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
}

  // const chartComparisonValue =
  //   chartComparison?.length > 0 ? chartComparison : null;
  return (
    <Card className="number-card-metric">
      <Card.Body>
        <Card.Title style={{ whiteSpace: "pre-wrap" }}><EllipsisWithTooltip placement="top">{chartTitle}</EllipsisWithTooltip></Card.Title>
        <div className="card-text">
          {/* {JSON.stringify(aspectRatio)} */}
          <h3>{value}</h3>

          {/* {chartComparisonValue && (
            <p
              key={chartComparisonValue.value}
              className={`${isPositive ? "green" : "red"}`}
            >{`${data.subvalue} to ${chartComparisonValue.value}`}</p>
          )} */}

          <div className="scroll-wrapper">
            <Slider {...settings}>
              {/* {chartComparison && chartComparison?.length > 0 && chartComparison?.map(item => (
                <div className="scroll-item">
                  <p
                  key={item.value}
                  className={`${isPositive ? "green" : "red"}`}
                >{
                  isPositive ? <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                  <path d="M8.32367 3.52656L9.04367 4.24656L6.60367 6.68656L4.95867 5.04156C4.76367 4.84656 4.44867 4.84656 4.25367 5.04156L1.25367 8.04656C1.05867 8.24156 1.05867 8.55656 1.25367 8.75156C1.44867 8.94656 1.76367 8.94656 1.95867 8.75156L4.60367 6.10156L6.24867 7.74656C6.44367 7.94156 6.75867 7.94156 6.95367 7.74656L9.74867 4.95656L10.4687 5.67656C10.6237 5.83156 10.8937 5.72156 10.8937 5.50156V3.35156C10.8987 3.21156 10.7887 3.10156 10.6487 3.10156H8.50367C8.27867 3.10156 8.16867 3.37156 8.32367 3.52656Z" fill="#003E30"/>
                  </svg> :
                   <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                  <path d="M8.32367 8.47562L9.04367 7.75562L6.60367 5.31562L4.95867 6.96062C4.76367 7.15562 4.44867 7.15562 4.25367 6.96062L1.25367 3.95562C1.05867 3.76062 1.05867 3.44562 1.25367 3.25062C1.44867 3.05562 1.76367 3.05562 1.95867 3.25062L4.60367 5.90062L6.24867 4.25562C6.44367 4.06062 6.75867 4.06062 6.95367 4.25562L9.74867 7.04562L10.4687 6.32562C10.6237 6.17062 10.8937 6.28062 10.8937 6.50062V8.64562C10.8937 8.78562 10.7837 8.89562 10.6437 8.89562H8.49867C8.27867 8.90062 8.16867 8.63062 8.32367 8.47562Z" fill="#D3412E"/>
                  </svg>
                }
                  
                {`${data.subvalue} to ${item.value}`}</p>
                </div>
              )) } */} 
               {
                chartComparison?.map(item => (
                <div className="test">
                <p
                  key={item.value}
                  className={`d-inline-flex align-items-center ${isPositive ? "green" : "red"}`}
                >{
                  isPositive ? <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                  <path d="M8.32367 3.52656L9.04367 4.24656L6.60367 6.68656L4.95867 5.04156C4.76367 4.84656 4.44867 4.84656 4.25367 5.04156L1.25367 8.04656C1.05867 8.24156 1.05867 8.55656 1.25367 8.75156C1.44867 8.94656 1.76367 8.94656 1.95867 8.75156L4.60367 6.10156L6.24867 7.74656C6.44367 7.94156 6.75867 7.94156 6.95367 7.74656L9.74867 4.95656L10.4687 5.67656C10.6237 5.83156 10.8937 5.72156 10.8937 5.50156V3.35156C10.8987 3.21156 10.7887 3.10156 10.6487 3.10156H8.50367C8.27867 3.10156 8.16867 3.37156 8.32367 3.52656Z" fill="#003E30"/>
                  </svg> :
                   <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                  <path d="M8.32367 8.47562L9.04367 7.75562L6.60367 5.31562L4.95867 6.96062C4.76367 7.15562 4.44867 7.15562 4.25367 6.96062L1.25367 3.95562C1.05867 3.76062 1.05867 3.44562 1.25367 3.25062C1.44867 3.05562 1.76367 3.05562 1.95867 3.25062L4.60367 5.90062L6.24867 4.25562C6.44367 4.06062 6.75867 4.06062 6.95367 4.25562L9.74867 7.04562L10.4687 6.32562C10.6237 6.17062 10.8937 6.28062 10.8937 6.50062V8.64562C10.8937 8.78562 10.7837 8.89562 10.6437 8.89562H8.49867C8.27867 8.90062 8.16867 8.63062 8.32367 8.47562Z" fill="#D3412E"/>
                  </svg>
                }
                   
                  {`${isPositive ? "+" :"-"}${data.subvalue} to ${item.value}`}
                  
                  </p> 
                  </div>
              )) }

              
            </Slider>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

export default NumberCard;
