import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Container, Dropdown, Form, Modal, Button } from "react-bootstrap";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { toast } from "react-toastify";
import filterFactory from "react-bootstrap-table2-filter";
import "react-toastify/dist/ReactToastify.css";
import PageLoader from "../../../../Common/Components/PageLoader";
import { HidSelector } from "../../../../Common/Components/HidSelector";
import { IHotelResponseDto } from "../../../../Common/Contracts/IHotel";
import { Hotel as hotel } from "../../../../Common/Services/Hotel";
import chevronRight from "../../../../Common/Assets/Images/icons/bx-chevron-right.svg";
import { RoomAssignmentsDetails } from "../Overview/RoomAssignmnetOverview/RoomAssignmentsDetails";
import { HousekeepingApprovalsDetails } from "../Overview/HouseKeepingOverview/HousekeepingApprovalsDetails";
import { LaborDayApprove } from "../../../../Common/Services/LaborDayApprove";
import { SingleDropdownListWithoutSearch } from "../../../../Common/Components/SingleDropdownListWithoutSearch";
import { HousekeepingApprovedList } from "../HouseKeeping/HousekeepingApprovedList";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import OutsideClickHandler from "react-outside-click-handler";
import DatePicker from "react-datepicker";
import { resolve, reject } from "q";
import HouseKeepingSlideOut from "./HouseKeepingSlideOut";
import profile from "../../../../Common/Assets/Images/profile.png";
import { Utils } from "../../../../Common/Utilis"; 
import { LaborBudgetSetting } from "../../../../Common/Services/LaborBudgetSetting";
export class HousekeepingList extends React.Component<any, any> {
  private childHID: any;
  private tableTypeSearch: any;
  private approveDepartment: any;
  constructor(props: any) {
    super(props);
    this.childHID = React.createRef();
    this.tableTypeSearch = React.createRef();
    this.approveDepartment = React.createRef();
    this.state = {
      hidValue: "Select",
      hotelName: "Select",
      pageloader: false,
      isHotelLoaded: false,
      isExpand: false,
      tableExpended: [],
      isBulkSelect: false,
      show: false,
      checkCount: 0,
      checkAll: false,
      skipNotes: "",
      allData: [],
      tableData: [],
      tableDataDup: [],
      date:
        JSON.parse(localStorage.storage).currentUtcTime !== ""
          ? new Date(JSON.parse(localStorage.storage).currentUtcTime)
          : new Date(),
      field: "date",
      order: "desc",
      assignColumns: [],
      approveColumns: [],
      dayFilter: [
        { id: 7, name: "Past week" },
        { id: 14, name: "Past 2 weeks" },
        { id: 30, name: "Past 30 days" },
      ],
      dayText: "Past week",
      dayValue: 7,
      housekeepingStatus: [
        { id: "All", name: "All", isChecked: true },
        { id: "No Assignments", name: "No Assignments", isChecked: true },
        { id: "Assignments", name: "Assignments", isChecked: true },
        { id: "Unapproved", name: "Unapproved", isChecked: true },
        { id: "Approved", name: "Approved", isChecked: true },
        { id: "Skipped", name: "Skipped", isChecked: true },
      ],
      statusText: "",
      statusValue: "",
      isSlideOutShow: false,
      slideOutData: {},
      noDailyActivity: false,
    };
    this.handleSelectDate = this.handleSelectDate.bind(this);
    this.bulkSelectClick = this.bulkSelectClick.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleFooterClose = this.handleFooterClose.bind(this);
    this.handleMultiSkip = this.handleMultiSkip.bind(this);
  }

  handleClose() {
    this.setState({ show: false, skipNotes: "" });
  }

  handleShow() {
    this.setState({ show: true });
  }

  handleSelectedItem = (controlID: any, id: any) => {
    this.setState({ dayValue: id });
    let weekValue = this.state.dayFilter.filter((i) => i.id === id);
    this.setState({ dayText: weekValue[0]["name"] });

    this.setState({ housekeepingStatus: [] }, () =>
      this.setState({
        statusText: "",
        statusValue: "",
        date:
          JSON.parse(localStorage.storage).currentUtcTime !== ""
            ? new Date(JSON.parse(localStorage.storage).currentUtcTime)
            : new Date(),
        tableExpended: [],
        isExpand: false,
        isBulkSelect: false,
        show: false,
        checkCount: 0,
        checkAll: false,
        skipNotes: "",
        housekeepingStatus: [
          { id: "All", name: "All", isChecked: true },
          { id: "No Assignments", name: "No Assignments", isChecked: true },
          { id: "Assignments", name: "Assignments", isChecked: true },
          { id: "Unapproved", name: "Unapproved", isChecked: true },
          { id: "Approved", name: "Approved", isChecked: true },
          { id: "Skipped", name: "Skipped", isChecked: true },
        ],
      })
    );
    let request = {} as any;
    request.hotelID = this.state.hidValue;
    request.days = id;
    request.type = "Housekeeping";
    this.bindHousekeepingSummary(request);
  };

  getURLParamsValues = (): any => {
    const paramObject: any = {
      date: this.getParamValueByParamName("date"),
      hotelId: this.getParamValueByParamName("hotelId"),
      departmentId: this.getParamValueByParamName("departmentId"),
      positionId: this.getParamValueByParamName("positionId"),
    };
    return paramObject;
  };

  getParamValueByParamName(paramName: string): any {
    const valuesArray = decodeURIComponent(this.props.history.location.search)
      ?.replace("?", "")
      ?.split("&");
    const item = valuesArray?.find((r) => r?.includes(paramName));
    return item?.split("=")[1];
  }

  componentDidMount() {
    this.loadHotelsDetails();
  }

  loadHotelsDetails() {
    const params = this.getURLParamsValues();
    hotel
      .getUserHotelAccess("Labor Management")
      .then(async (result: IHotelResponseDto[] | null) => {
        if (result != null) {
          this.setState({ hidList: result }, () => {});
          if (params?.hotelId) {
            const hotelDetail = result.find(
              (r) => +r?.hotelID === +params?.hotelId
            );
            localStorage.setItem("hotelSelected", JSON.stringify(hotelDetail));
          }
        }
        resolve();
        this.setState({ loaderTrue: false, isHotelLoaded: true });
      })
      .catch((error) => {
        this.setState({ loaderTrue: false, isHotelLoaded: true });
        reject();
      });
  }

  selectHotelDropDown = (hotelDetails: any) => {
    this.setState({ housekeepingStatus: [], dayFilter: [] }, () =>
      this.setState({
        hidValue: hotelDetails.hotelID,
        hotelName: hotelDetails.lettercode,
        dayText: "Past week",
        dayValue: 7,
        statusText: "",
        statusValue: "",
        isExpand: false,
        tableExpended: [],
        isBulkSelect: false,
        show: false,
        checkCount: 0,
        checkAll: false,
        skipNotes: "",
        date:
          JSON.parse(localStorage.storage).currentUtcTime !== ""
            ? new Date(JSON.parse(localStorage.storage).currentUtcTime)
            : new Date(),
        housekeepingStatus: [
          { id: "All", name: "All", isChecked: true },
          { id: "No Assignments", name: "No Assignments", isChecked: true },
          { id: "Assignments", name: "Assignments", isChecked: true },
          { id: "Unapproved", name: "Unapproved", isChecked: true },
          { id: "Approved", name: "Approved", isChecked: true },
          { id: "Skipped", name: "Skipped", isChecked: true },
        ],
        dayFilter: [
          { id: 7, name: "Past week" },
          { id: 14, name: "Past 2 weeks" },
          { id: 30, name: "Past 30 days" },
        ],
      })
    );

    let request = {} as any;
    request.hotelID = hotelDetails.hotelID;
    request.days = 7;
    request.type = "Housekeeping";
    this.bindRoomAssignmentColumns(request);
    this.bindHousekeepingSummary(request);
  };

  bindHousekeepingSummary(request: any) {
    this.setState({ loaderTrue: true, tableData: [], pageloader: true });
    LaborDayApprove.housekeepingSummary(request)
      .then(async (result: any[] | null) => {
        if (result != null) {
         
          if (result.length > 0) {
            let data = [];
            let noDailyActivity = false;
            data = Object.assign([], JSON.parse(JSON.stringify(result)));
            data.map((item: any) => {
              item.isCheck = false;
              item.isBtnSkip = true;
              item.skipNotes = "";
              item.action = "";
              item.roomOccupiedChange = false;
              if (!noDailyActivity) {
                // noDailyActivity = item.isActualEntry === "No"
                if (
                  item.isActualEntry === "No" &&
                  (item.occupiedRoom === "" || item.occupiedRoom === null)
                ) {
                  noDailyActivity = true;
                }
              }
            });
            this.setState({ allData: data, noDailyActivity });
            this.filterData(this.state.housekeepingStatus, data);

            this.setState({ pageloader: false });
          } else {
            this.setState({ pageloader: false, noDailyActivity: false });
          }
        } else {
          this.setState({ pageloader: false, noDailyActivity: false });
        }
        resolve();
      })
      .catch((error) => {
        reject();
        this.setState({ loaderTrue: false, noDailyActivity: false });
      });
  }

  handleSelectDate = (date: any) => {
    this.setState({ housekeepingStatus: [], dayFilter: [] }, () =>
      this.setState({
        dayText: "Custom",
        dayValue: 0,
        statusText: "",
        statusValue: "",
        tableExpended: [],
        isExpand: false,
        housekeepingStatus: [
          { id: "All", name: "All", isChecked: true },
          { id: "No Assignments", name: "No Assignments", isChecked: true },
          { id: "Assignments", name: "Assignments", isChecked: true },
          { id: "Unapproved", name: "Unapproved", isChecked: true },
          { id: "Approved", name: "Approved", isChecked: true },
          { id: "Skipped", name: "Skipped", isChecked: true },
        ],
        dayFilter: [
          { id: 7, name: "Past week" },
          { id: 14, name: "Past 2 weeks" },
          { id: 30, name: "Past 30 days" },
        ],
      })
    );

    this.setState({ date: date });
    let request = {} as any;
    request.hotelID = this.state.hidValue;
    request.selectedDate = date;
    request.days = this.state.dayValue;
    request.type = "Housekeeping";
    this.bindHousekeepingListDateWise(request);
  };

  bindHousekeepingListDateWise(request: any) {
    this.setState({ loaderTrue: true, tableData: [], pageloader: true });
    LaborDayApprove.houseKeepingDaySummary(request)
      .then(async (result: any[] | null) => {
        if (result != null) {
          if (result.length > 0) {
            let data = [];
            let noDailyActivity = false;
            data = Object.assign([], JSON.parse(JSON.stringify(result)));
            data.map((item: any) => {
              item.isCheck = false;
              item.isBtnSkip = true;
              item.skipNotes = "";
              item.action = "";
              item.roomOccupiedChange = false;
              if (!noDailyActivity) {
                // noDailyActivity = item.isActualEntry === "No"
                if (
                  item.isActualEntry === "No" &&
                  (item.occupiedRoom === "" || item.occupiedRoom === null)
                ) {
                  noDailyActivity = true;
                }
              }
            });
            this.setState({ allData: data, noDailyActivity });
            this.filterData(this.state.housekeepingStatus, data);
            this.setState({ pageloader: false });
          } else {
            this.setState({ pageloader: false, noDailyActivity: false });
          }
        } else {
          this.setState({ pageloader: false, noDailyActivity: false });
        }
        resolve();
      })
      .catch((error) => {
        reject();
        this.setState({ loaderTrue: false });
        this.setState({ pageloader: false, noDailyActivity: false });
      });
  }

  bindRoomAssignmentColumns(request: any) {
    LaborDayApprove.LaborServiceList(request)
      .then(async (result: any[] | null) => {
        if (result != null) {
       
          if (result.length > 0) {
            this.setState({ assignColumns: result });
          }
        }
        resolve();
      })
      .catch((error) => {
        reject();
        this.setState({ loaderTrue: false });
      });

    LaborDayApprove.LaborApproveServiceList(request)
      .then(async (result: any[] | null) => {
        if (result != null) {
          debugger;
          if (result.length > 0) {
            this.setState({ approveColumns: result });
          }
          this.setState({ loaderTrue: false });
        } else {
          this.setState({ loaderTrue: false });
        }

        resolve();
      })
      .catch((error) => {
        reject();
        this.setState({ loaderTrue: false });
      });
  }

  handleOnExpand = (row: any, isExpand: any, rowIndex: any, e: any) => {
    debugger;
      if (this.state.tableExpended[0] === row.id) {
        this.setState({ isExpand: false });
        row.action = "";
        this.setState(() => ({
          tableExpended: [],
        }));
      } else {
        this.setState({ isExpand: false });
        this.setState(() => ({
          tableExpended: [],
        }));
        row.action = "Expended";
        this.setState({ isExpand: true });
        this.setState(() => ({
          tableExpended: [row.id],
        }));
      }
  };

  refreshBindApproveData() {
    this.setState(() => ({
      tableExpended: [],
    }));
    this.setState({ isExpand: false });
   
    let request = {} as any;
    request.hotelID = this.state.hidValue;
    request.days = this.state.dayValue;
    request.type = "Housekeeping";
    this.bindRoomAssignmentColumns(request);
    this.bindHousekeepingSummary(request);
  }

  onChangeStatus = (e: any, items: any) => {
   
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.housekeepingStatus))
    );
    data.map((item: any) => {
      if (items.id === "All") {
        item.isChecked = e.target.checked;
      } else if (item.id === items.id) {
        item.isChecked = e.target.checked;
      }
    });

    if (data.filter((i) => i.isChecked === true && i.id !== "All").length < 5) {
      data.map((item: any) => {
        if (item.id === "All") {
          item.isChecked = false;
        }
      });
    } else {
      data.map((item: any) => {
        if (item.id === "All") {
          item.isChecked = true;
        }
      });
    }
    this.setState({
      housekeepingStatus: data,
      isExpand: false,
      tableExpended: [],
    });
    this.filterData(data, this.state.allData);
  };

  filterData(filterData: any, Data: any) {
    let status: any = [];
    filterData.map((item: any, i: any) => {
      if (item.isChecked) {
        status.push(item.name.toLowerCase());
      }
    });

    if (status.length > 0) {
      let data = Data.filter((d) => status.includes(d.status.toLowerCase()));
      this.setState({ tableData: data });
    } else {
      this.setState({ tableData: [] });
    }
  }

  skipClick(e: any, row: any, rowIndex: any) {
    this.setState(() => ({
      tableExpended: [],
    }));
    this.setState({ isExpand: false });
    row.action = "";
    let data = this.state.tableData;
    data[rowIndex].isBtnSkip = false;
    this.setState({ tableData: [] }, () => {
      this.setState({ tableData: data });
      this.ApproveSetFocus(rowIndex);
    });
  }

  ApproveSetFocus = (rowIndex: any) => {
    $("#dropdown-more" + rowIndex).click();
  };

  unApproveClick(e: any, row: any, rowIndex: any) {
    this.setState({ loaderTrue: true });
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID);
    let newItem: any = {};
    newItem.hotelID = this.state.hidValue;
    newItem.date = row.date;
    newItem.tenantid = tenantID;
    LaborDayApprove.LaborUnApprovedDay(newItem)
      .then(async (result: any | null) => {
        if (result != null) {
          if (result.message === "Success") {
            toast.success("Housekeeping successfully unapproved.", {
              // // position: toast.POSITION.BOTTOM_RIGHT,
            });

            this.saveBudgetCalculation(row.date, this.state.hidValue);

            this.setState({
              tableExpended: [],
              isExpand: false,
              isBulkSelect: false,
              show: false,
              checkCount: 0,
              checkAll: false,
              skipNotes: "",
            });
            this.refreshBindApproveData();
          } else {
            Utils.toastError(result.message, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error: any) => {
        this.setState({ loaderTrue: false });
        reject();
      });
  }

  bulkSelectClick(e: any, row: any, rowIndex: any) {
    this.setState(() => ({
      tableExpended: [],
    }));
    this.setState({ isExpand: false });
    row.action = "";
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableData))
    );
    // eslint-disable-next-line array-callback-return
    data.map((item: any) => {
      item.isCheck = false;
    });
    this.setState({ tableDataDup: data });
    this.setState(
      { isBulkSelect: !this.state.isBulkSelect, tableData: [] },
      () => {
        this.setState({
          tableData: this.state.tableDataDup,
          checkCount: 0,
          checkAll: false,
        });
      }
    );
  }

  onOutsideClick = (e, row, rowIndex) => {
    let data = this.state.tableData;
    data[rowIndex].isBtnSkip = true;
    data[rowIndex].skipNotes = "";
    this.setState({ tableData: [] }, () => {
      this.setState({ tableData: data, tableExpended: [], isExpand: false });
    });
  };

  skipNotesChangeRow(e: any, row: any, rowIndex: any) {
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableData))
    );
    data[rowIndex].skipNotes = e.target.value;
    this.setState({ tableData: data });
  }

  skipNotesChange(e: any) {
    this.setState({ skipNotes: e.target.value });
  }

  onSkipSubmit(e: any, row: any, rowIndex: any) {
    if (row.skipNotes.trim().length > 0) {
      let skipDetail: any[] = [];
      let newItem: any = {};
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : (storage.tenantID);
      newItem.hid = row.hotelid;
      newItem.date = row.date;
      newItem.tenantid = tenantID;
      newItem.notes = row.skipNotes;
      skipDetail.push(newItem);
      this.updateSkipDetails(skipDetail);
      let data = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.tableData))
      );
      data[rowIndex].skipNotes = "";
      this.setState({ tableData: data });
    } else {
      Utils.toastError("Please enter skip notes.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }

  updateSkipDetails(skipDetail: any[]) {
    this.setState({ loaderTrue: true });
    LaborDayApprove.HousekeepingApprovalsSkip(skipDetail)
      .then(async (result: any | null) => {
        if (result != null) {
          if (result.message === "Success") {
            toast.success("Housekeeping approvals successfully skip.", {
              // // position: toast.POSITION.BOTTOM_RIGHT,
            });
            this.setState({
              tableExpended: [],
              isExpand: false,
              isBulkSelect: false,
              show: false,
              checkCount: 0,
              checkAll: false,
              skipNotes: "",
            });
            this.refreshBindApproveData();
          } else {
            Utils.toastError(result.message, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error: any) => {
        this.setState({ loaderTrue: false });
        reject();
      });
  }

  handleMultiSkip() {
    if (this.state.skipNotes.trim().length > 0) {
      let skipDetail: any[] = [];
      let array = this.state.tableData.filter(
        (row: { isCheck: boolean }) => row.isCheck === true
      );
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : (storage.tenantID);
      // eslint-disable-next-line array-callback-return
      array.map((item: any) => {
        let newItem: any = {};
        newItem.hid = item.hotelid;
        newItem.date = item.date;
        newItem.tenantid = tenantID;
        newItem.notes = this.state.skipNotes;
        skipDetail.push(newItem);
      });
      this.updateSkipDetails(skipDetail);
    } else {
      Utils.toastError("Please enter skip notes.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }

  checkAllEvent(e: any) {
    let array = [];
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableData))
    );
    // eslint-disable-next-line array-callback-return
    data.map((item: any) => {
      if (item.totalAssign === 0) {
        item.isCheck = e.target.checked;
      }
    });
    array = data.filter((row) => row.isCheck === true);
    this.setState(
      { tableData: [], checkCount: array.length, checkAll: e.target.checked },
      () => {
        this.setState({ tableData: data });
      }
    );
  }

  handleFooterClose() {
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableData))
    );
    // eslint-disable-next-line array-callback-return
    data.map((item: any) => {
      item.isCheck = false;
    });
    this.setState(
      { tableData: [], checkCount: 0, checkAll: false, isBulkSelect: false },
      () => {
        this.setState({ tableData: data });
      }
    );
  }

  checkBoxEvent(e: any, row: any, rowIndex: any) {
    let array = [];
    let arrayTotal = [];
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableData))
    );
    data[rowIndex].isCheck = e.target.checked;
    data[rowIndex].action = "";
    array = data.filter((row) => row.isCheck === true);
    arrayTotal = data.filter((row) => row.totalAssign === 0);
    if (array.length > 0 && array.length !== arrayTotal.length) {
      $("#checkAll").prop("checked", false).prop("indeterminate", true);
    } else {
      $("#checkAll").prop("checked", false).prop("indeterminate", false);
    }
    this.setState({
      tableData: data,
      checkCount: array.length,
      checkAll: array.length === arrayTotal.length ? true : false,
    }); 
  }

  changeOccupiedRoows(e: any, row: any, rowIndex: any) {
    let val = e.target.value;
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableData))
    );

    if (/^\d+$/.test(val) === false) {
      val = val.replace(/[^\d]/g, "");
    }
    if (val.length > 3) {
      let a = val.replace(/[^\d]/g, "");
      val = a.substring(0, val.length - 1);
    }
    data[rowIndex].occupiedRoom = val;
    data[rowIndex].roomOccupiedChange = true;
    this.setState(
      {
        tableData: data,
      },
      () => {
        $("#housekeeping")
          .find(`input[id^='occupiedRooms-${rowIndex}']`)
          .focus();
      }
    );
  }

  saveHousekeepingManualOccupiedRooms = (e: any, row: any, rowIndex: any) => {
    let oldData = this.state.allData;
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableData))
    );
    if (!row.roomOccupiedChange) {
      return;
    }
    if (row.occupiedRoom === "") {
      Utils.toastError(
        "Please enter rooms occupied. Rooms Occupied can't be blank."
      );
      data[rowIndex].occupiedRoom = oldData[rowIndex].occupiedRoom;
      data[rowIndex].roomOccupiedChange = false;
      this.setState({
        tableData: data,
      });
      return;
    }
    let nullOccupiedRoom = data.filter((item) => item.occupiedRoom === null);
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID);
    let requestArray: any = [];
    let request: any = {};
    request.Hid = this.state.hidValue;
    request.Tenantid = tenantID;
    request.Date = row.date;
    request.RoomTotal = row.occupiedRoom;
    request.type = "";
    requestArray.push(request);

    LaborDayApprove.saveHousekeepingManualOccupiedRooms(requestArray)
      .then(async (result: any | null) => {
        if (result != null) {
          if (result.message === "Success") {
            toast.success("Total occupied rooms saved successfully.", {
              // // position: toast.POSITION.BOTTOM_RIGHT,
            });
            this.saveBudgetCalculation(row.date, this.state.hidValue);
          }
          let showBanner = false;
          showBanner = nullOccupiedRoom.length > 0;
          data[rowIndex].roomOccupiedChange = false;
          data[rowIndex].action = "";
          this.setState({
            noDailyActivity: showBanner,
            tableData: data,
            allData: data,
            tableExpended: [],
            isExpand: false,
          });
          // this.selectHotelDropDown({hotelID:this.state.hidValue,lettercode:this.state.hotelName})
        }
      })
      .catch((error) => {
        Utils.toastError(`Server Error, ${error}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "scheduleMain",
        });
        reject(); 
      });
  };

  hideSlideOut = (data: any) => {
    this.setState({ isSlideOutShow: false });
  };

  getDataAndShowSlideOut = (rowdata: any) => {
    let newItem: any = {};
    newItem = { ...newItem, ...rowdata };
    this.setState({ slideOutData: newItem, isSlideOutShow: true });
  };

  saveBudgetCalculation = (date: any, hidValue: any) => {
    let request: any = {};
    let currentDate: any = null;
    currentDate = date;
    request.hotelID = hidValue;
    request.layout = "2";
    request.date = currentDate;
    request.type = "Housekeeping";
    LaborBudgetSetting.SaveBudgetCalculation(request)
      .then(async (result: any | null) => {})
      .catch((err: any) => {});
  };
  render() {
    const rowClasses = (row: { action: string }, rowIndex: any) => {
      debugger;
      if (this.state.isExpand === true && row.action === "Expended") {
        return "row-Collapsed";
      } else if (this.state.isExpand === true ) {
        return "row-Collapsed";
      } else {
        return "row-Action";
      }
    };

    var loadShow: any;
    if (this.state.loaderTrue) {
      loadShow = <PageLoader />;
    }

    const compareShift = (topVal: any, bottomVal: any) => {
      let topMM = 0;
      let bottomMM = 0;
      if (topVal.includes(":")) {
        const dataArray = topVal.split(":");
        let HH = Number(dataArray[0]);
        let MM = Number(dataArray[1]);
        if (dataArray[0].includes("-") || dataArray[1].includes("-")) {
          HH = HH < 0 ? HH * -1 : HH;
          MM = MM < 0 ? MM * -1 : MM;
          topMM = (HH * 60 + MM) * -1;
        } else {
          topMM = HH * 60 + MM;
        }
      }

      if (bottomVal.includes(":")) {
        const dataArray = bottomVal.split(":");
        let HH = Number(dataArray[0]);
        let MM = Number(dataArray[1]);
        if (dataArray[0].includes("-") || dataArray[1].includes("-")) {
          HH = HH < 0 ? HH * -1 : HH;
          MM = MM < 0 ? MM * -1 : MM;
          bottomMM = (HH * 60 + MM) * -1;
        } else {
          bottomMM = HH * 60 + MM;
        }
      }
      if (topMM > bottomMM) {
        return "main-ttl color-red red";
      } else {
        return "main-ttl";
      }
    };

    const columns = [
      {
        dataField: "",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => {
          const stopPropagation = (e: { stopPropagation: () => any }) =>
            e.stopPropagation();
          if (this.state.isBulkSelect && row.totalAssign === 0) {
            return (
              <div className="overBudgetDeptsData" onClick={stopPropagation}>
                <Form.Check
                  custom
                  type="checkbox"
                  key={"custom" + rowIndex}
                  id={"custom" + rowIndex}
                  label=""
                  checked={row.isCheck}
                  onChange={(e: any) => this.checkBoxEvent(e, row, rowIndex)}
                />
              </div>
            );
          }
        },
      },
      {
        dataField: "daysName",
        text: "Day",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="">
              <div className="main-ttl">
                <EllipsisWithTooltip placement="bottom">
                  {row.daysName}
                </EllipsisWithTooltip>
              </div>
              <div className="sub-ttl mdy-fnt-14">{row.formatedDate}</div>
            </div>
          );
        },
      },
      {
        dataField: "status",
        text: "Status",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="d-flex flex-wrap">
              {row.status.toLowerCase() === "assignments" && (
                <div className="red-badges blue-bg">{row.status}</div>
              )}
              {row.status.toLowerCase() === "no assignments" && (
                <div className="red-badges blue-bg">{row.status}</div>
              )}
              {row.status.toLowerCase() === "unapproved" && (
                <div className="red-badges">{row.status}</div>
              )}
              {row.status.toLowerCase() === "approved" && (
                <div className="red-badges green-bg">{row.status}</div>
              )}
              {row.status.toLowerCase() === "skipped" && (
                <div className="red-badges green-bg">{row.status}</div>
              )}
            </div>
          );
        },
      },
      {
        dataField: "occupiedRoom",
        text: "Occupied",
        formatExtraData: { otherRowExpanede: this.state.tableExpended },
        formatter: (cell: any, row: any, rowIndex: any, extraData) => {
          let readOnly = false;
          if (
            extraData.otherRowExpanede.length > 0 &&
            extraData.otherRowExpanede[0] !== row.id
          ) {
            readOnly = true;
          }
          const stopPropagation = (e: { stopPropagation: () => any }) =>
            e.stopPropagation();
          if (
            row.isActualEntry !== "Yes" &&
            row.status.toLowerCase() !== "approved"
          ) {
            return (
              <div className="main-ttl" onClick={stopPropagation}>
                <input
                  id={`occupiedRooms-${rowIndex}`}
                  type="text"
                  onFocus={() => {
                    this.setState({ inputFocus: true });
                  }}
                  readOnly={readOnly}
                  className="form-control"
                  style={{ width: "60%" }}
                  value={row.occupiedRoom}
                  onChange={(e) => this.changeOccupiedRoows(e, row, rowIndex)}
                  onBlur={(e) => {
                    this.saveHousekeepingManualOccupiedRooms(e, row, rowIndex);
                  }}
                  autoComplete="off"
                />
              </div>
            );
          } else {
            return <div className="main-ttl">{row.occupiedRoom}</div>;
          }
        },
      },
      {
        dataField: "roomDenied",
        text: "Denied",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return <div className="main-ttl">{row.roomDenied}</div>;
        },
      },
      {
        dataField: "roomClean",
        text: "Cleaned",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return <div className="main-ttl">{row.roomClean}</div>;
        },
      },
      {
        dataField: "",
        text: "MPCR",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="">
              <div className={compareShift(row.mpor, row.mpor1)}>
                <EllipsisWithTooltip placement="bottom">
                  {row.mpor}
                </EllipsisWithTooltip>
              </div>
              <div className="sub-ttl mdy-fnt-14">{row.mpor1}</div>
            </div>
          );
        },
      },
      {
        dataField: "",
        text: "Total",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="">
              <div className={compareShift(row.total, row.total1)}>
                <EllipsisWithTooltip placement="bottom">
                  {row.total}
                </EllipsisWithTooltip>
              </div>
              <div className="sub-ttl mdy-fnt-14">{row.total1}</div>
            </div>
          );
        },
      },
      {
        dataField: "",
        text: "",
        headerClasses: "notes-sec",
        classes: "notes-sec",
        formatter: (cell: any, row: any, rowIndex: any) => {
          const stopPropagation = (e: { stopPropagation: () => any }) =>
            e.stopPropagation();
          return (
            <div className="d-flex">
              
              {row.notes.length > 0 && (
                <Dropdown
                  className="more-action notes-btn"
                  alignRight
                  // onClick={stopPropagation}
                >
                  <Dropdown.Toggle
                    className="btn-outline-primary  more"
                    id="dropdown-more"
                  >
                    <div className="d-flex">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M13.5556 2.4H11.3889V1H9.94444V2.4H7.05556V1H5.61111V2.4H3.44444C2.64783 2.4 2 3.0279 2 3.8V13.6C2 14.3721 2.64783 15 3.44444 15H13.5556C14.3522 15 15 14.3721 15 13.6V3.8C15 3.0279 14.3522 2.4 13.5556 2.4ZM3.44444 13.6V4.5H5.61111H7.05556H9.94444H11.3889H13.5556V3.8L13.557 13.6H3.44444Z" />
                        <path d="M4.6665 6H11.3332V7.33333H4.6665V6ZM4.6665 8.66667H7.99984V10H4.6665V8.66667Z" />
                      </svg>
                      {/* <span>5</span> */}
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div className="notes-sec">
                      <div className="notes-list">
                        <div className="comment-list">
                          <div className="d-flex profile-details">
                            <div className="profile-img">
                              <img
                                src={
                                  row.noteimageUrl === ""
                                    ? profile
                                    : row.noteimageUrl
                                }
                                className="default test"
                                width="40"
                                height="40"
                              />
                            </div>
                            <div>
                              <div className="main-ttl">{row.noteUser}</div>
                              <div className="sub-ttl">
                                {Utils.getLocalDateTimeFromUtc(row.noteDate)}
                              </div>
                            </div>
                          </div>
                          <ul>
                            <li>
                              <div className="d-flex">
                                <div className="detail-sec">
                                  <div className="comments">{row.notes}</div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          );
        },
      },
      {
        dataField: "",
        text: "",
        editable: false,
        formatter: (cell: any, row: any, rowIndex: any) => {
          const stopPropagation = (e: { stopPropagation: () => any }) =>
            e.stopPropagation();
          if (row.isBtnSkip) {
            return (
              <div className="d-flex justify-content-end">
                {row.status.toLowerCase() !== "approved" &&
                  row.status.toLowerCase() !== "skipped" && (
                    <button
                      type="button"
                      className="btn-outline-primary btn btn-primary"
                      onClick={(e)=>this.handleOnExpand(row, row.action==="",rowIndex,e)}
                    >
                      {row.status === "No Assignments" ||
                      row.status === "Assignments"
                        ? "Enter Assignments"
                        : "Approve"}{" "}
                    </button>
                  )}
                <>
                  <Dropdown
                    //className="more-action"
                    className={
                      ((row.status.toLowerCase() === "unapproved" &&
                        row.totalAssign === 0) ||
                        row.status.toLowerCase() === "approved" ||
                        row.status.toLowerCase() === "skipped") &&
                      row.isedit.toLowerCase() === "yes"
                        ? "more-action"
                        : "more-action opacity-none"
                    }
                    alignRight
                    // onClick={stopPropagation}
                  >
                    <Dropdown.Toggle
                      className="btn-outline-primary btn btn-primary more"
                      id="dropdown-more"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {row.totalAssign === 0 &&
                        row.status.toLowerCase() !== "skipped" && (
                          <>
                            <Dropdown.Item
                              eventKey={"Skip"}
                              onClick={(e: any) =>
                                this.skipClick(e, row, rowIndex)
                              }
                            >
                              Skip
                            </Dropdown.Item>

                            <Dropdown.Item
                              eventKey={"Skip"}
                              onClick={(e: any) =>
                                this.bulkSelectClick(e, row, rowIndex)
                              }
                            >
                              Bulk Select
                            </Dropdown.Item>
                          </>
                        )}
                      {(row.status.toLowerCase() === "approved" ||
                        row.status.toLowerCase() === "skipped") && (
                        <Dropdown.Item
                          eventKey={"Skip" + rowIndex}
                          onClick={(e: any) =>
                            this.unApproveClick(e, row, rowIndex)
                          }
                        >
                          Unapprove
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              </div>
            );
          } else {
            return (
              <OutsideClickHandler
                onOutsideClick={(e) => {
                  this.onOutsideClick(e, row, rowIndex);
                }}
              >
                <div className="d-flex justify-content-end">
                  {row.status.toLowerCase() !== "approved" &&
                    row.status.toLowerCase() !== "skipped" && (
                      <button
                        type="button"
                        className="btn-outline-primary btn btn-primary"
                        onClick={(e)=>this.handleOnExpand(row, row.action==="",rowIndex,e)}
                      >
                        {row.status === "No Assignments" ||
                        row.status === "Assignments"
                          ? "Enter Assignments"
                          : "Approve"}{" "}
                      </button>
                    )}
                  {row.status.toLowerCase() === "unapproved" && (
                    <>
                      {" "}
                      <Dropdown
                        className="more-action"
                        alignRight
                        // onClick={stopPropagation}
                      >
                        <Dropdown.Toggle
                          className="btn-outline-primary btn btn-primary more"
                          id={"dropdown-more" + rowIndex}
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                            <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                            <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                          </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu rootCloseEvent="click" show={true}>
                          <div
                            className="flayoutApproveMenu"
                            ref={this.approveDepartment}
                          >
                            <div className="approveDepartmentDetails">
                              <div className="entry-body">
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                  <Form.Label>
                                    Skip Housekeeping Approval
                                  </Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    value={row.skipNotes}
                                    onChange={(e: any) =>
                                      this.skipNotesChangeRow(e, row, rowIndex)
                                    }
                                    placeholder="Explain why you are skipping this approval"
                                    rows={3}
                                    maxLength={200}
                                  />
                                </Form.Group>
                              </div>
                              <div className="action-link">
                                <Dropdown.Item
                                  className="btn wht-bg dropdown-item cancel"
                                  onClick={(e) => {
                                    this.onOutsideClick(e, row, rowIndex);
                                  }}
                                >
                                  Cancel
                                </Dropdown.Item>
                                <Button
                                  className="btn btn-primary dropdown-item approve"
                                  onClick={(e) => {
                                    this.onSkipSubmit(e, row, rowIndex);
                                  }}
                                >
                                  {" "}
                                  Submit{" "}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </>
                  )}
                </div>
              </OutsideClickHandler>
            );
          }
        },
      },
    ];

    const expandRow = {
      onlyOneExpanding: true,
      parentClassName: "parent-expand-foo",
      renderer: (row: any, rowIndex: any) => (
        <>
          {(row.status.toLowerCase() === "assignments" ||
            row.status.toLowerCase() === "no assignments") && (
            <RoomAssignmentsDetails
              hidValue={row.hotelid}
              columnDetails={this.state.assignColumns}
              date={row.date}
              refreshBindApproveData={this.refreshBindApproveData.bind(this)}
              row={row}
            />
          )}

          {row.status.toLowerCase() === "unapproved" && (
            <HousekeepingApprovalsDetails
              hidValue={row.hotelid}
              columnDetails={this.state.approveColumns}
              date={row.date}
              assigned={row.totalAssign}
              refreshData={this.refreshBindApproveData.bind(this)}
              row={row}
            />
          )}
          {(row.status.toLowerCase() === "approved" ||
            row.status.toLowerCase() === "skipped") && (
            <HousekeepingApprovedList
              hotelID={row.hotelid}
              date={row.date}
              getChildDetails={this.getDataAndShowSlideOut}
            />
          )}
        </>
      ),
      expanded: this.state.tableExpended,
      onExpand: this.handleOnExpand,
      showExpandColumn: true,
      expandByColumnOnly: true,
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return <div></div>;
        }
        return <div></div>;
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <span className="chevronExpended">
              <img src={chevronRight} alt="chevron Right" />
            </span>
          );
        }
        return (
          <span className="chevronCollpsed">
            <img src={chevronRight} alt="chevron Right" />
          </span>
        );
      },
    };

    return (
      <>
        {this.state.isSlideOutShow && (
          <HouseKeepingSlideOut
            data={this.state.slideOutData}
            hideSlideOut={this.hideSlideOut}
          />
        )}

        <div className="performance-position performance HouseKeeping housekeeping-standalone">
          {/* <ToastContainer autoClose={3000} /> */}
          {loadShow}
          <Container fluid className="body-sec">
            <div className="page-heading underline d-flex">
              {this.state.isHotelLoaded && (
                <HidSelector
                  hotelList={this.state.hidList}
                  onClickEvent={this.selectHotelDropDown}
                  ref={this.childHID}
                />
              )}
              <div className="mr-auto d-flex align-items-center">
                {" "}
                Housekeeping{" "}
              </div>
            </div>
            {this.state.hidValue !== "Select" && (
              <div className="main-Page">
                <div className="bdy-sec">
                  {this.state.pageloader && (
                    <div className="loader-responsive">
                      <div className="performanceList housekeeping-standalone">
                        <div className="header d-flex">
                          <div className="loadingAnimation"></div>
                          <div className="loadingAnimation"></div>
                          <div className="loadingAnimation"></div>
                        </div>
                        <div className="tableHeader loadingAnimation"></div>
                        <div className="tableList">
                          <ul>
                            <li className="d-flex">
                              <div className="list d-flex align-self-center">
                                <div className="group">
                                  <div className="loadingAnimation"></div>
                                  <div className="loadingAnimation"></div>
                                </div>
                              </div>
                              <div className="list d-flex align-self-center">
                                <div className="group">
                                  <div className="loadingAnimation"></div>
                                </div>
                              </div>
                              <div className="list d-flex align-self-center">
                                <div className="group">
                                  <div className="loadingAnimation"></div>
                                </div>
                              </div>
                              <div className="list d-flex align-self-center">
                                <div className="group">
                                  <div className="loadingAnimation"></div>
                                </div>
                              </div>
                              <div className="list d-flex align-self-center">
                                <div className="group">
                                  <div className="loadingAnimation"></div>
                                </div>
                              </div>
                              <div className="list d-flex align-self-center">
                                <div className="group">
                                  <div className="loadingAnimation"></div>
                                  <div className="loadingAnimation"></div>
                                </div>
                              </div>
                              <div className="list d-flex align-self-center">
                                <div className="group">
                                  <div className="loadingAnimation"></div>
                                  <div className="loadingAnimation"></div>
                                </div>
                              </div>
                              <div className="list comments d-flex align-self-center">
                                <div className="group">
                                  <div className="loadingAnimation"></div>
                                </div>
                              </div>
                              <div className="list blank-space d-flex align-self-center"></div>
                              <div className="list d-flex align-self-center mr-auto">
                                <div className="group">
                                  <div className="loadingAnimation"></div>
                                </div>
                              </div>
                              <div className="list d-flex align-self-center">
                                <div className="group">
                                  <div className="loadingAnimation"></div>
                                </div>
                              </div>
                            </li>
                            <li className="d-flex">
                              <div className="list d-flex align-self-center">
                                <div className="group">
                                  <div className="loadingAnimation"></div>
                                  <div className="loadingAnimation"></div>
                                </div>
                              </div>
                              <div className="list d-flex align-self-center">
                                <div className="group">
                                  <div className="loadingAnimation"></div>
                                </div>
                              </div>
                              <div className="list d-flex align-self-center">
                                <div className="group">
                                  <div className="loadingAnimation"></div>
                                </div>
                              </div>
                              <div className="list d-flex align-self-center">
                                <div className="group">
                                  <div className="loadingAnimation"></div>
                                </div>
                              </div>
                              <div className="list d-flex align-self-center">
                                <div className="group">
                                  <div className="loadingAnimation"></div>
                                </div>
                              </div>
                              <div className="list d-flex align-self-center">
                                <div className="group">
                                  <div className="loadingAnimation"></div>
                                  <div className="loadingAnimation"></div>
                                </div>
                              </div>
                              <div className="list d-flex align-self-center">
                                <div className="group">
                                  <div className="loadingAnimation"></div>
                                  <div className="loadingAnimation"></div>
                                </div>
                              </div>
                              <div className="list comments d-flex align-self-center">
                                <div className="group">
                                  <div className="loadingAnimation"></div>
                                </div>
                              </div>
                              <div className="list blank-space d-flex align-self-center"></div>
                              <div className="list d-flex align-self-center mr-auto">
                                <div className="group">
                                  <div className="loadingAnimation"></div>
                                </div>
                              </div>
                              <div className="list d-flex align-self-center">
                                <div className="group">
                                  <div className="loadingAnimation"></div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                  {!this.state.pageloader && (
                    <ToolkitProvider
                      keyField="id"
                      data={this.state.tableData}
                      columns={columns}
                      sort={{
                        dataField: this.state.field,
                        order: this.state.order,
                      }}
                      search
                    >
                      {(props: {
                        searchProps: JSX.IntrinsicAttributes;
                        baseProps: JSX.IntrinsicAttributes;
                      }) => (
                        <div>
                          <div className="search-header d-flex">
                            <div className="date-picker calender-picker">
                              <div className="drop-arrow">
                                <svg
                                  width="8"
                                  height="6"
                                  viewBox="0 0 8 6"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M3.28122e-05 1.19724C3.28122e-05 0.889538 0.253262 0.653982 0.543288 0.653982C0.688297 0.653982 0.833313 0.708449 0.923855 0.816677L4.0023 4.0217L7.08074 0.816677C7.2979 0.599519 7.6424 0.599519 7.84116 0.816677C8.04064 1.01616 8.05831 1.34153 7.85955 1.55941L4.40046 5.18112C4.20098 5.39827 3.85721 5.39828 3.64005 5.19951L3.62166 5.18112L0.145009 1.55941C0.0544669 1.46887 0 1.34226 0 1.19724L3.28122e-05 1.19724Z"
                                    fill="#3F4347"
                                  />
                                </svg>
                              </div>
                              <DatePicker
                                key="datePicker"
                                selected={new Date(this.state.date)}
                                maxDate={
                                  JSON.parse(localStorage.storage)
                                    .currentUtcTime !== ""
                                    ? new Date(
                                        JSON.parse(
                                          localStorage.storage
                                        ).currentUtcTime
                                      )
                                    : new Date()
                                }
                                placeholderText={"Select date"}
                                dateFormat="M/dd/yy"
                                className="form-control"
                                onSelect={this.handleSelectDate}
                              />
                            </div>
                            <div className="position-filter">
                              {this.state.dayFilter.length > 0 && (
                                <SingleDropdownListWithoutSearch
                                  id={"tabletypeddl"}
                                  ref={this.tableTypeSearch}
                                  isRefresh={this.state.isRefresh}
                                  itemList={this.state.dayFilter}
                                  handleSelectedItem={this.handleSelectedItem.bind(
                                    this,
                                    1
                                  )}
                                  defaultItem={this.state.dayFilter}
                                  defaultText={"Search day Status"}
                                  defaultName={this.state.dayText}
                                  controlID="1"
                                  calIcon={true}
                                />
                              )}
                            </div>
                            <div className="mr-auto department-filter">
                              <Dropdown className="more-action bg-blue dropdown rolePermissiondropdown">
                                <Dropdown.Toggle id="rolesList">
                                  Status
                                  <span className="cusBadge badgePrimary">
                                    {
                                      this.state.housekeepingStatus.filter(
                                        (i: any) => i.isChecked === true
                                      ).length
                                    }
                                  </span>
                                  <svg
                                    stroke="currentColor"
                                    fill="none"
                                    strokeWidth="2"
                                    viewBox="0 0 24 24"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <polyline points="6 9 12 15 18 9"></polyline>
                                  </svg>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="rolePermissionSelector">
                                  <div className="dropdownItems">
                                    {this.state.housekeepingStatus.length > 0 &&
                                      this.state.housekeepingStatus.map(
                                        (items, index) => (
                                          <div
                                            className="dropdown-item"
                                            key={items.id}
                                          >
                                            <EllipsisWithTooltip placement="bottom">
                                              <Form.Check
                                                type="checkbox"
                                                id={`list_${items.id}`}
                                                name={items.name.toLowerCase()}
                                                label={items.name}
                                                value={items.id}
                                                onChange={(event) =>
                                                  this.onChangeStatus(
                                                    event,
                                                    items
                                                  )
                                                }
                                                checked={items.isChecked}
                                              />
                                            </EllipsisWithTooltip>
                                          </div>
                                        )
                                      )}
                                  </div>
                                </Dropdown.Menu>

                                <div className="cal-icon">
                                  <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    {/* <path d="M1.79541 10.3063L5.35266 13.0213L11.0647 6.49335L9.93516 5.50635L5.14716 10.9783L2.70441 9.11385L1.79541 10.3063ZM16.3147 6.49335L15.1852 5.50635L10.4084 10.9656L9.84366 10.5141L8.90616 11.6856L10.5914 13.0341L16.3147 6.49335Z" /> */}
                                  </svg>
                                </div>
                              </Dropdown>
                            </div>
                          </div>
                          {this.state.noDailyActivity && (
                            <div className="notification-banners red-bg">
                              <div className="mr-auto">
                                Daily Activity Entry data is unavailable. Please
                                manually enter the total occupied rooms count.
                                {/* <span className="bold"></span> */}
                              </div>
                            </div>
                          )}
                          <div className="scroll-area hasCursorPointer">
                            <div className="housekeeping-list">
                              <BootstrapTable
                                id={"housekeeping"}
                                {...props.baseProps}
                                keyField="id"
                                expandRow={expandRow}
                                hover
                                sort={{
                                  dataField: this.state.field,
                                  order: this.state.order,
                                }}
                                rowClasses={rowClasses}
                                filter={filterFactory()}
                                noDataIndication={
                                  "No activity for the selected time period"
                                }
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </ToolkitProvider>
                  )}
                </div>
              </div>
            )}
          </Container>
          <div>
            <Modal
              className="flayoutApproveMenu skipModal"
              show={this.state.show}
              onHide={this.handleClose}
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Skip Housekeeping Approvals</Modal.Title>
              </Modal.Header>
              <Modal.Body className="approveDepartmentDetails">
                <div className="entry-body">
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Skip Housekeeping Approval</Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Explain why you are skipping these approvals"
                      maxLength={200}
                      id="skipNotes"
                      rows={3}
                      value={this.state.skipNotes}
                      onChange={(e: any) => this.skipNotesChange(e)}
                    />
                  </Form.Group>
                </div>
              </Modal.Body>
              <Modal.Footer className="approveDepartmentDetails">
                <div className="action-link">
                  <Button
                    className="btn btn-primary dropdown-item approve"
                    onClick={this.handleMultiSkip}
                  >
                    Skip Approvals
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>
          </div>
          {this.state.isBulkSelect && (
            <div className="footer-sec">
              <div className="d-flex align-items-center height-60">
                <div>
                  <div className="d-flex">
                    <Form.Check
                      custom
                      type="checkbox"
                      key={"checkAll"}
                      id={"checkAll"}
                      label=""
                      checked={this.state.checkAll}
                      onChange={(e: any) => this.checkAllEvent(e)}
                    />
                    <div className="selected-count">
                      {this.state.checkCount}{" "}
                      {this.state.checkCount > 1 ? "Days" : "Day"} Selected
                    </div>
                  </div>
                </div>
                <div className="mr-auto approve-btn">
                  {this.state.checkCount > 0 && (
                    <button
                      type="button"
                      className="btn-outline-primary btn btn-primary"
                      onClick={this.handleShow}
                    >
                      Skip Approvals
                    </button>
                  )}
                </div>
                <div>
                  <button
                    type="button"
                    className="cross-btn"
                    onClick={this.handleFooterClose}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}
