import * as React from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import BootstrapTable from "react-bootstrap-table-next";

export const AssetBulkUploadTable = (props) => {
  const columns = [
    {
      dataField: "assetType",
      text: "Asset Type",
      editorStyle: {
        width: "100%",
        overflow: "scroll",
        padding: "inherit",
        height: "36px",
        textAlign: "left",
        paddingBottom: "10px",
        fontFamily: "OpenSans",
        fontSize: "14px",
      },
      editCellClasses: "dropdown-editor",
      editor: {
        type: Type.SELECT,
        options: (props?.assetTypesList || [])?.map((x) => ({
          value: x?.assetTypeName,
          label: x?.assetTypeName,
        })),
      },

      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <div
            className={`rol-cole ${!row.assetType ? "validation-error" : ""}`}
          >
            <EllipsisWithTooltip placement="bottom">
              {row.assetType}
            </EllipsisWithTooltip>
          </div>
        );
      },
    },
    {
      dataField: "assetName",
      text: "Asset Name",
      editorStyle: {
        width: "100%",
        overflow: "scroll",
        padding: "inherit",
        height: "36px",
        textAlign: "left",
        paddingBottom: "10px",
        fontFamily: "OpenSans",
        fontSize: "14px",
      },
      editCellClasses: "asset-name-editor",
      validator: (newValue, row, column) => {
        if (newValue?.toString()?.trimLeft()?.length > 100) {
          return {
            valid: false,
            message: "Asset Name should be not more than 100 characters",
          };
        }

        return true;
      },

      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <div
            className={`rol-cole ${
              !row.assetName ||
              row.exceptions?.toString()?.includes("Duplicate")
                ? "validation-error"
                : ""
            }`}
          >
            <EllipsisWithTooltip placement="bottom">
              {row.assetName}
            </EllipsisWithTooltip>
          </div>
        );
      },
    },
    {
      dataField: "location",
      text: "Location",
      editorStyle: {
        width: "100%",
        overflow: "scroll",
        padding: "inherit",
        height: "36px",
        textAlign: "left",
        paddingBottom: "10px",
        fontFamily: "OpenSans",
        fontSize: "14px",
      },
      editCellClasses: "dropdown-editor",

      editor: {
        type: Type.SELECT,
        options: (props?.locationList || [])?.map((x) => ({
          value: x?.roomName,
          label: x?.roomName,
        })),
      },

      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <div
            className={`rol-cole ${!row.location ? "validation-error" : ""}`}
          >
            <EllipsisWithTooltip placement="bottom">
              {row.location}
            </EllipsisWithTooltip>
          </div>
        );
      },
    },
    {
      dataField: "notes",
      text: "Notes",
      editorStyle: {
        width: "100%",
        overflow: "scroll",
        padding: "inherit",
        height: "36px",
      },
      editor: {
        type: Type.TEXTAREA,
      },
      validator: (newValue, row, column) => {
        if (newValue?.toString()?.trimLeft()?.length > 200) {
          return {
            valid: false,
            message: "Notes should be not more than 200 characters",
          };
        }

        return true;
      },
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <div className="role-col">
            <EllipsisWithTooltip placement="bottom">
              {row.notes}
            </EllipsisWithTooltip>
          </div>
        );
      },
    },
    {
      dataField: "exceptions",
      text: "Exceptions",
      editable: false,
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <div className={`rol-cole`}>
            {row?.exceptions
              ?.trim()
              ?.split(",")
              .map((exception) => {
                let checkException = exception
                  ?.toString()
                  ?.replace(".", "")
                  ?.trim()
                  .toLowerCase();
                return checkException ? (
                  <span
                    className={`badge ${
                      checkException == "missing info"
                        ? "missing"
                        : checkException == "duplicate"
                        ? "duplicate"
                        : ""
                    }`}
                  >
                    <EllipsisWithTooltip placement="bottom">
                      {exception?.toString()?.trim()}
                    </EllipsisWithTooltip>
                  </span>
                ) : (
                  ""
                );
              })}
          </div>
        );
      },
    },
  ];

  const selectRow = {
    mode: "checkbox",
    selected: props.selectedAssets,
    onSelect: props.handleOnSelect,
    onSelectAll: (isSelect, rows) => props.handleOnSelectAll(isSelect, rows),
    selectionHeaderRenderer: ({ mode, ...rest }) => (
      <Form.Check
        type={mode}
        {...rest}
        label=""
        custom
        ref={(chkRef) => {
          if (chkRef) {
            if (
              props.selectedAssets?.length === props?.tableData.length &&
              props?.tableData?.length > 0
            ) {
              chkRef.checked = true;
            } else {
              chkRef.checked = false;
            }

            if (props.selectedAssets?.length > 0 && !chkRef?.checked) {
              chkRef.indeterminate = true;
            } else {
              chkRef.indeterminate = false;
            }
          }
        }}
      />
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <>
        {/* <input type={ mode } { ...rest } /> */}
        <Form.Check type={mode} {...rest} label="" custom />
      </>
    ),
  };

  const rowClasses = (row, rowIndex) => {
    let classes = "";

    // if (props?.showCompleted && row?.status === "Completed") {
    //   classes = classes + " row-grey";
    //   classes = classes + " checkbox-disabled task-completed";
    // } else {
    //   if (props?.selectedRowIDs[0] === row?.taskId) {
    //     classes = classes + " row-grey";
    //   }
    // }

    return classes;
  };

  return (
    <>
      <Modal
        className="add-new-asset upload-files-table-modal"
        show={props?.showTemplateModal}
        onHide={props.toggleBulkTemplateModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // keyboard={this.props?.showDeductionModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Asset Bulk Upload</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <div className="block-bar"></div>
            {/* {isDataLoading ? (
            <ReactPageLoader useas={"daily-task-loader"} />
          ) : ( */}
            <>
              {" "}
              {props.tableData?.length > 0 ? (
                <BootstrapTable
                  id={"assetBulk"}
                  {...props.baseProps}
                  keyField="id"
                  data={props.tableData}
                  columns={columns}
                  selectRow={selectRow}
                  hover
                  rowClasses={rowClasses}
                  cellEdit={cellEditFactory(props?.tableCellEdit)}
                  //  rowEvents={tableRowEvents}
                />
              ) : (
                <></>
              )}
            </>
            {/* )} */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {props?.showBulkOptions ? (
            <div className="tableFooter TabFooter-Pay">
              <div className="bulkSelectFooter">
                {/* this.footerchk.current.indeterminate = false; */}
                <Form.Check
                  type="checkbox"
                  label=""
                  id="footerBulkCheckBox"
                  disabled={props?.validatingData}
                  ref={(chkRef) => {
                    if (chkRef) {
                      if (
                        props.selectedAssets?.length === props?.tableData.length
                      ) {
                        chkRef.checked = true;
                      } else {
                        chkRef.checked = false;
                      }

                      if (
                        props.selectedAssets?.length > 0 &&
                        !chkRef?.checked
                      ) {
                        chkRef.indeterminate = true;
                      } else {
                        chkRef.indeterminate = false;
                      }
                    }
                  }}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    props?.footerBulkSelectAll(e);
                  }}
                />
                <span className="shift-selected-count">
                  {props?.selectedAssets?.length}{" "}
                  {props?.selectedAssets?.length > 1 ? "Rows" : "Row"} selected
                </span>
                <Button
                  disabled={
                    props?.selectedAssets?.length == 0 || props?.validatingData
                  }
                  onClick={() => props?.removeSelectedRows()}
                  className="btn-outline-primary text-white"
                >
                  Remove
                </Button>

                <Button
                  disabled={props?.validatingData}
                  className="closeButton btn-ghost-light"
                  onClick={() => props.closeBulkAction()}
                >
                  &times;
                </Button>
              </div>
            </div>
          ) : (
            <div className="back-strip">
              <Button
                type="button"
                disabled={props.isImportData || props.isChanging}
                onClick={() => {
                  props?.toggleBulkUploadModal();
                  props?.toggleBulkTemplateModal();
                }}
                className="btn-outline-primary btn btn-primary mr-auto ml-0"
              >
                Back
              </Button>
              <Button
                variant="primary"
                disabled={
                  props.isImportData ||
                  props.isChanging ||
                  props?.tableData?.length === 0 ||
                  props?.tableData?.filter(
                    (x) =>
                      !x?.assetName ||
                      !x?.assetType ||
                      !x?.location ||
                      x?.exceptions?.toString()?.replace(".", "")?.trim()
                  )?.length > 0
                }
                onClick={() => props?.bulkSaveAssets()}
              >
                {props?.isImportData && (
                  <>
                    <Spinner size="sm" animation="border" />{" "}
                  </>
                )}{" "}
                Import Assets
              </Button>
            </div>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};
