import React, { Component } from 'react';
import { Utils } from '../../../Common/Utilis';

const KEY_BACKSPACE = 'Backspace';
const KEY_DELETE = 'Delete';
const KEY_ENTER = 'Enter';
const KEY_TAB = 'Tab';
function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charStr = event.key;
    return isCharNumeric(charStr);
}
function isLeftOrRight(event) {
    return ['ArrowLeft', 'ArrowRight'].indexOf(event.key) > -1;
}
function deleteOrBackspace(event) {
    return [KEY_DELETE, KEY_BACKSPACE].indexOf(event.key) > -1;
}

interface INumericProps {
    charPress?: string;
    colDef?:any;
    data?:any;
}

interface INumericState {
    value?: number;
}


class NumericEditor extends Component<INumericProps,INumericState> {
    public editorRef
    constructor(props) {
      super(props);
      this.editorRef = React.createRef();
      let initialState =
        props.keyPress === KEY_BACKSPACE || props.keyPress === KEY_DELETE
          ? ""
          : props.value;
      this.state = { value: initialState };
    }
  
    afterGuiAttached() {
      this.editorRef.current.focus();
    }
  
    getValue() {
        
        return this.state.value;
    }
  
    onKeyDown = (event) => {
        if (isLeftOrRight(event) || deleteOrBackspace(event)) {
            event.stopPropagation();
            return;
          }

      
          if (!isKeyPressedNumeric(event)) {
              if (event.preventDefault) event.preventDefault();
          }

          if (isKeyPressedNumeric(event) && !['1','2'].includes(event.key)) {
            if (event.preventDefault) event.preventDefault();
        }
    };
  
    handleChange = (event) => {
      let newValue=event.target.value

      if(this.props.colDef.field == "ddAmount" ){
        if(!newValue?.toString()?.includes(".") && newValue?.toString()?.length > 10)
        {
          return false
        }
        if(this.props.data.ddAmountType !==  "% of Paycheck")
        {
          newValue = newValue?.toString()?.trim()?.replace(/[^0-9\.\+]+/gi, "");
          if (newValue?.toString()?.split(".")?.length> 0) {
            if (!isNaN(newValue) && newValue?.toString()?.split(".")[0]?.length > 10 ) {
              return false;
            }
    
            if (
              newValue?.toString()?.split(".")?.length> 1 && newValue?.toString()?.split(".")[1]?.length > 2
            ) {
              newValue = +newValue >= 0 ? Number(newValue)?.toFixed(2) : newValue;
            }
          }
        }
        else if(this.props.data.ddAmountType ===  "% of Paycheck"){
          newValue = newValue?.toString()?.trim()?.replace(/[^0-9\+]+/gi, "");
        }
      
        this.setState({ value: newValue });
      }
      else{
        newValue = newValue?.toString()?.replace(/[^0-9\+]+/gi, "");
        this.setState({ value:newValue });

      }
     
      
    };
   
  
    render() {
       console.log({props:this.props})
      return (
        <>
        {/* <div className={`${!this.state.value
                          ? "validation-error"
                          : ""
                        }`}  > */}
        <input
          maxLength={this.props.colDef.field == "rowNo" ? 1: this.props.data.ddAmountType === "% of Paycheck" ? 10 :13}
          ref={this.editorRef}
          onKeyDown={this.props.colDef.field == "rowNo"  ? this.onKeyDown : this.props.data.ddAmountType === "% of Paycheck"? Utils.AllowOnlyPositiveNumnberInputWithoutDot: Utils.AllowOnlyPositiveNumnberInput }
          value={this.state.value}
          onChange={this.handleChange}
          style={{ width: "100%" }}
     
        />
        {/* {!this.state.value && <span className="validation-message">{"Please Enter Value"}</span> } */}
        {/* </div> */}
        </>
      );
    }
  }
  
  export default NumericEditor;