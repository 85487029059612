import React from "react";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { Dropdown, Form, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import OutsideClickHandler from "react-outside-click-handler";
import { FiChevronDown } from "react-icons/fi";
import { resolve, reject } from "q";
import { DepartmentAfterExpend } from "./DepartmentAfterExpend";
import { DepartmentSearchView } from "./components/DepartmentSearchView";
import { schConversions } from "./commonFunctions/conversions";
import { schAPIcall } from "./commonFunctions/ScheduleAPIcalls";
import chevronRight from "../../../../Common/Assets/Images/icons/bx-chevron-right.svg";
import ForecastLock from "../../../../Common/Assets/Images/icons/forecast-lock.svg";
import { ReactPageLoader } from "../../../../Common/Components/ReactPageLoader";
import { SingleSearchDropdownList } from "../../../../Common/Components/SingleSearchDropdownList";
import { ILabourScheduleDepartmentViewRequestDto } from "../../../../Common/Contracts/ILaborSchedule";
import { LaborSchedule } from "../../../../Common/Services/LaborSchedule";
import "./DepartmentViewMain.scss";
import { Utils } from "../../../../Common/Utilis";
const tooltipMessage =
  "The schedule cannot be changed because a shift from this day has already begun.";

export class DepartmentViewMain extends React.Component<any, any> {
  private expendedChild: any;
  private departmentSearch: any;
  private departmentSearchScroll: any;
  constructor(props: any) {
    super(props);
    this.expendedChild = React.createRef();
    this.departmentSearch = React.createRef();
    this.departmentSearchScroll = React.createRef();

    this.state = {
      loaderTrue: true,
      tableLoaderTrue: false,
      calenderShow: false,
      calenderSelectDate: this.props.parentState.calenderSelectDate,
      calenderLoadDate: this.props.parentState.todayDate,
      currentDate: {},
      staticHeaderShow: false,
      scrollHeaderHeight: "",
      navigationCalenderDates: [],
      isPageLock: false,
      positionName: "Position",
      departmentName: "Department",
      sortingOrderList: ["Alphabetical", "Overtime"],
      sortingOrder: "Alphabetical",
      scheduleViewList: ["Departments", "Positions", "Users"],
      scheduleView: "Departments",
      importForecastBanner: false,
      importForecastBannerType: "",
      isBulkSelect: false,
      offSetHour: "0",
      tableData: [],
      tableExpended: [],
      lockDays: [],
      headerDates: [],
      headerFullDates: [],
      headerDays: [],
      hideDeptsFlag: false,
      actualHighlight: 0,
      isOptionHighlight: false,
      isExpand: false,
      expendedRow: {},
      expendedRowIndex: -1,
      expendedRowDept: "",
      searchedUser: "",
      searchSelectionList: [],
     
    };
  }

  componentDidMount = () => {
    window.addEventListener("scroll", this.handleScroll, true);
  };

  componentWillUnmount = () => {
    document.removeEventListener("scroll", this.handleScroll, true);
  };

  handleScroll = (event: any) => {
    if (event.target.className === "body-sec container-fluid") {
      let scrollPostion = event.target.scrollTop;

      if (scrollPostion > Number(this.state.scrollHeaderHeight)) {
        this.setState({ staticHeaderShow: true });
      } else {
        this.setState({ staticHeaderShow: false });
      }
    }
  };

  getSaveAsViewData = (type: any) => {
    this.props.refreshPositionDeptId();
    let data = this.props.parentState.tempSaveAsDefaultData;
    if (data.length > 0) {
      let defaultValue = data.filter(
        (items: any) => items.fieldName === "DefaultView"
      );
      if (defaultValue[0].fieldValue === "Departments") {
        data.forEach((element: any) => {
          switch (element.fieldName) {
            case "Sort":
              this.sortingOrderChange(element.fieldValue);
              break;

            case "SchduleVariance":
              if (element.fieldValue === "Yes") {
                this.handleActualFilter("checked");
              }
              if (element.fieldValue === "No") {
                this.handleActualFilter("false");
              }
              break;
          }
        });
      }
    }
  };

  getRequestParameters = (type: string) => {
    let request = {} as ILabourScheduleDepartmentViewRequestDto;

    request.HotelID = this.props.parentState.hidValue;
    request.ViewType = this.state.scheduleView;
    request.DayLayOut = 7;
    request.Date = this.state.calenderLoadDate;
    request.filterType = "All";
    request.filterValue = "All";

    if (type === "ehidChange") {
      request.Date = this.props.parentState.todayDate;
      this.setState({
        calenderSelectDate: this.props.parentState.calenderSelectDate,
        hideDeptsFlag: false,
      });
    }
    if (this.state.searchedUser !== "") {
      request.filterType = "Users";
      request.filterValue = this.state.searchedUser;
    }

    return request;
  };

  getDeptMainData = (type: string) => {
    if (type === "ehidChange" || type === "calenderDropDown") {
      this.setState({ loaderTrue: true });
    }
    if (type === "searchValue") {
      this.setState({ tableLoaderTrue: true });
    }

    let request = this.getRequestParameters(
      type
    ) as ILabourScheduleDepartmentViewRequestDto;

    LaborSchedule.GetLaborScheduleDeptViewData(request)
      .then(async (result: any) => {
        if (result.message === "Success" && result.result.length > 0) {
          let apiresult: any = result.result;

          let tempScheduleData: any = [];
          let calnderDatesDispaly: Array<any> = [];
          let isPageLock: boolean = false;
          let lockDays: Array<string> = [];
          let headerDates: Array<string> = [];
          let headerFullDates: Array<string> = [];
          let headerDays: Array<string> = [];
          let isBannerShow: boolean = false;
          let bannerType: string = "";
          let currentDate: any = {};
          let actualHighlight: any;
          for (let i = 0; i < apiresult.length; i++) {
            let tempRowObject: any = {};
            tempRowObject.rowNo = i;
            tempRowObject.deptID = apiresult[i].deptID;
            tempRowObject.deptName = apiresult[i].departmentName.replace(
              /\w\S*/g,
              function (txt: any) {
                return (
                  txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
                );
              }
            );
            let newOt = apiresult[i].ot.replace(".", ":");
            let newestOt = newOt.replace(".", ":");
            let otArray = newestOt.split("/");
            tempRowObject.deptOT = `${otArray[0]} / ${otArray[1]}`;
            tempRowObject.action = "";
            let tempPayValues = apiresult[i].payPeriodDate.split("_");
            if (tempPayValues[5] === "Yes") {
              tempRowObject.hasOT = true;
            } else {
              tempRowObject.hasOT = false;
            }
            if (tempPayValues[6] === "Yes") {
              tempRowObject.hasUnassigned = true;
            } else {
              tempRowObject.hasUnassigned = false;
            }

            for (let j = 1; j <= 7; j++) {
              let day = `day${j}`;
              let dayValues: any = [];
              let dayObject: any = {};
              if (apiresult[i][day] !== null) {
                dayValues = apiresult[i][day].split("||");

                dayObject.dayDate = dayValues[0];
                dayObject.dayName = dayValues[1];
                dayObject.dayScheduleOT = dayValues[2].replace(".", ":");
                dayObject.dayTotalOT = dayValues[3].replace(".", ":");
                if (Number(dayValues[2]) > Number(dayValues[3])) {
                  dayObject.dayStatus = "Overtime";
                } else {
                  dayObject.dayStatus = "Undertime";
                }
              }

              tempRowObject[day] = dayObject;

              if (dayValues.length > 0 && apiresult[i].deptID === -1) {
                let tempDate = apiresult[i].payPeriodDate.split("_");
                calnderDatesDispaly = tempDate;
                currentDate = new Date(apiresult[i].currentDate);
                let testDate = new Date(dayValues[0]);
                if (testDate < currentDate) {
                  lockDays.push(day);
                }

                let tempHeaderDate = dayValues[0].substring(0, 5);
                headerDates.push(tempHeaderDate);
                headerFullDates.push(dayValues[0]);
                headerDays.push(dayValues[1]);
              }
            }
            if (apiresult[i].deptID !== -1) {
              tempScheduleData.push(tempRowObject);
            }
          }

          const uniqueLockDays: any = new Set(lockDays);
          const arrayLockDays = [...uniqueLockDays];
          const uniqueHeaderDates: any = new Set(headerDates);
          const arrayHeaderDates = [...uniqueHeaderDates];
          const uniqueHeaderFullDates: any = new Set(headerFullDates);
          const arrayHeaderFullDates = [...uniqueHeaderFullDates];
          const uniqueHeaderDays: any = new Set(headerDays);
          const arrayHeaderDays = [...uniqueHeaderDays];
          if (arrayLockDays.length >= 7) {
            isPageLock = true;
          }

          if (calnderDatesDispaly[2] === "Yes") {
            isBannerShow = false;
            bannerType = "";
          } else {
            isBannerShow = true;
            if (calnderDatesDispaly[2] === "No") {
              bannerType = "noForecast";
            } else {
              bannerType = "errorForecast";
            }
          }

          let offSetHour = 0;

          if (calnderDatesDispaly.length > 2) {
            offSetHour = calnderDatesDispaly[3];
            actualHighlight = calnderDatesDispaly[4];
          }

          
          this.setState(
            {
              tableData: tempScheduleData,
              tableDataFilter: tempScheduleData,
              isPageLock: isPageLock,
              lockDays: arrayLockDays,
              headerDates: arrayHeaderDates,
              headerFullDates: arrayHeaderFullDates,
              headerDays: arrayHeaderDays,
              navigationCalenderDates: calnderDatesDispaly,
              offSetHour: offSetHour,
              actualHighlight: actualHighlight,
              currentDate: currentDate,
              importForecastBanner: isBannerShow,
              importForecastBannerType: bannerType,
              isBulkSelect: false,
              loaderTrue: false,
              tableLoaderTrue: false,
             
            },
            () => {
              this.getStaticHeaderHeight();
              this.props.childDataLoaded(this.state);
              this.departmentSearch.current?.getSearchUsersList();
              this.departmentSearchScroll.current?.getSearchUsersList();

              if (type === "viewChange") {
                this.getSaveAsViewData(type);
              }
              if (type === "searchValue") {
                if (this.state.departmentId > 0) {
                  this.FilterByDropDownList(this.state.departmentId);
                }
              }
              if (type === "ehidChange") {
                this.getSaveAsViewData(type);
                this.setState({ departmentId: 0, hideDeptsFlag: false });
                this.handleOnExpand(this.state.expendedRow, false, 0, "Filter");
              }
              if (type === "addShiftRefresh") {
                if (this.state.departmentId > 0) {
                  this.FilterByDropDownList(this.state.departmentId);
                }
              }
              if (type === "calenderDropDown") {
                this.handleOnExpand(this.state.expendedRow, false, 0, "Filter");
                if (this.state.departmentId > 0) {
                  this.FilterByDropDownList(this.state.departmentId);
                }
              }
              if (
                type === "reasignSingleShift" ||
                type === "reassignAllShifts" ||
                type === "deleteShifts" ||
                type === "removeAllShifts" ||
                type === "dragDrop" ||
                type === "bulkSelect" ||
                type === "publishChanges" ||
                type === "ptoChanges"
              ) {
                if (this.state.departmentId > 0) {
                  this.FilterByDropDownList(this.state.departmentId);
                }
              }
            }
          );
        } else {
          Utils.toastError(result.message, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "scheduleMain",
          });
          this.setState({ loaderTrue: false, tableLoaderTrue: false });
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "scheduleMain",
        });
        this.setState({ loaderTrue: false, tableLoaderTrue: false });
        reject();
      });
  };

  getStaticHeaderHeight = () => {
    let fixedHeight = this.state.importForecastBanner ? 249 : 200;
    let scrollHeight = fixedHeight;

    this.setState({
      scrollHeaderHeight: scrollHeight,
    });
  };

  changeInDeptData = (type: any) => {
    this.getDeptMainData(type);

    this.childGetData(type);
  };

  childGetData = (type: string) => {
    if (this.state.isExpand) {
      this.expendedChild?.getDeptWiseScheduleData(type);
    } else {
      this.expendedChild?.current?.getDeptWiseScheduleData(type);
    }
  };

  childBulkFooterHandle = () => {
    if (this.state.isExpand) {
      this.expendedChild?.footerBulkClose("e");
    } else {
      this.expendedChild?.current?.footerBulkClose("e");
    }
  };

  refreshMainDepartmentView = (type: any) => {   
    this.getDeptMainData(type);
  };

  handleActualFilter = (e: any) => {
    let dataTable: any[] = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableData))
    );
    if (e === "checked" || e === "false") {
      this.setState({
        isOptionHighlight: e === "checked" ? true : false,
        tableData: dataTable,
      });
    } else {
      this.setState({
        isOptionHighlight: e.target.checked,
        tableData: dataTable,
      });
    }
  };

  getActualHighlight = (schedule: any, budget: any) => {
    const scheduleInMinute = schConversions.convertHourToMInute(schedule);
    const budgetInMinute = schConversions.convertHourToMInute(budget);

    if (budgetInMinute >= scheduleInMinute) {
      return "daySchedule";
    } else {
      const perRow: any =
        ((scheduleInMinute - budgetInMinute) / budgetInMinute) * 100;
      const highlight: any = Number(this.state.actualHighlight);
      const ishighlight: any = this.state.isOptionHighlight;

      if (perRow >= highlight) {
        return "daySchedule red";
      } else if (perRow <= highlight && ishighlight) {
        return "daySchedule red";
      } else if (perRow <= highlight && !ishighlight) {
        return "daySchedule";
      } else if (perRow > highlight) {
        return "daySchedule red";
      } else {
        return "daySchedule";
      }
    }
  };

  handleSelectedItem = (id: any) => {
    this.setState({ departmentId: id });
    this.FilterByDropDownList(id);
  };

  FilterByDropDownList = (departmentId: any) => {
    this.childBulkFooterHandle();

    const expendedRow = this.state.expendedRow;
    if (Object.keys(expendedRow).length === 0) {
      this.handleOnExpand(this.state.expendedRow, false, 0, "");
    } else {
      const expendedRowDept = Number(expendedRow.deptID);
      if (expendedRowDept !== departmentId) {
        this.handleOnExpand(this.state.expendedRow, false, 0, "Filter");
      }
    }

    let departmentpositionList: any[] = [];
    departmentpositionList = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableDataFilter))
    );
    if (departmentId > 0) {
      departmentpositionList = departmentpositionList.filter(
        (item) => Number(item.deptID) === departmentId
      );
      this.setState({ tableData: departmentpositionList });
    }
    if (departmentId <= 0) {
      this.setState({ tableData: this.state.tableDataFilter });
    }
  };

  scrollSearch = (type: any, item: any) => {
    this.selectFromSearchBar(type, item);
  };

  selectFromSearchBar = (type: any, item: any) => {
    let itemLength = Object.keys(item).length;
    this.handleOnExpand(this.state.expendedRow, false, 0, "Filter");

    if (itemLength === 0) {
      this.setState({ searchedUser: "" }, () => {
        this.getDeptMainData("searchValue");
      });
    } else {
      this.setState({ searchedUser: item.employee }, () => {
        this.getDeptMainData("searchValue");
      });
    }

    let searchSelectionList: any = [];

    if (item.length !== 0 && Object.keys(item).length !== 0) {
      searchSelectionList.push(item);
    }
    this.setState({ searchSelectionList }, () => {
      this.departmentSearch.current?.getSelectedUser();
      this.departmentSearchScroll.current?.getSelectedUser();
    });
  };

  clearSearch = () => {
    this.setState({ searchedUser: "" }, () => {
      this.departmentSearch.current?.clearSearch();

      this.departmentSearchScroll.current?.clearSearch();
    });
  };

  showCalender = () => {
    this.setState({ calenderShow: true });
  };

  hideCalender = () => {
    this.setState({ calenderShow: false });
  };

  calenderChange = (e: any) => {

    if(this.props.parentState.isChangeSchudle===false){
    this.childBulkFooterHandle();

    let calenderYear = e.getFullYear();
    let calenderDate = e.getDate();
    let calenderMonth = e.getMonth() + 1;
    let tempDate = calenderMonth + "/" + calenderDate + "/" + calenderYear;

    this.props.changeCalenderDate(tempDate);

    this.setState(
      {
        calenderSelectDate: e,
        calenderShow: false,
        calenderLoadDate: tempDate,
        searchedUser: "",
      },
      () => {
        this.getDeptMainData("calenderDropDown");
        this.clearSearch();
      }
    );
    }else{
      let calenderYear = e.getFullYear();
      let calenderDate = e.getDate();
      let calenderMonth = e.getMonth() + 1;
      let tempDate = calenderMonth + "/" + calenderDate + "/" + calenderYear;
  
      this.props.changeCalenderDate(tempDate);
    }
  };

  sortingOrderChange = (item: any) => {
    this.setState({ sortingOrder: item }, () => {
      this.childGetData("sortingOrederChnages");
    });
  };

  scheduleViewListChange = (item: any) => {
    this.setState({ scheduleView: item }, () => {
      this.props.viewChange(item, "viewChange");
    });
  };

  saveAsDefaultView = () => {
    let isOptionHighlight = "No";
    if (this.state.isOptionHighlight) {
      isOptionHighlight = "Yes";
    }

    let requestArray = schAPIcall.saveAsDefaultView(
      "Department",
      this.state.sortingOrder,
      "Departments",
      isOptionHighlight
    );

    LaborSchedule.LaborSaveAsDefaultView(requestArray)
      .then(async (result: any | null) => {
        if (result != null && result.saveStatus === "Success") {
          this.props.GetLaborSaveAsDefaultViewData();
          toast.success("Default view saved successfully", {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "scheduleMain",
          });
        } else {
          Utils.toastError(result.message, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "scheduleMain",
          });
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "scheduleMain",
        });
        reject();
      });
  };

  hideShowDeptUpdate = () => {
    this.setState({ hideDeptsFlag: !this.state.hideDeptsFlag }, () => {
      this.childGetData("hideShowDept");
    });
  };

  openAddEditForm = (
    formType: any,
    cellValues: any,
    assignedType: any,
    row: any,
    dayName: any,
    editableStatus: string
  ) => {
    this.props.openAddEditForm(
      formType,
      cellValues,
      assignedType,
      row,
      dayName,
      editableStatus
    );
  };

  handleOnExpand = (row: any, isExpand: any, rowIndex: any, e: any) => {
    this.childBulkFooterHandle();

    // table data management
    let tableData = [...this.state.tableData];

    tableData = tableData.map((d) => {
      if (d.rowNo === row.rowNo) {
        return {
          ...d,
          action: "Expended",
        };
      } else {
        return {
          ...d,
          action: "",
        };
      }
    });
    this.setState((curr: any) => ({
      ...curr,
      tableData,
    }));

    // Table Expend Management and More State Management setting
    let tableExpended: Array<number> = [];

    if (isExpand) {
      tableExpended.push(row.rowNo);

      this.setState({
        expendedRow: row,
        expendedRowIndex: rowIndex,
        expendedRowDept: row.deptID,
      });
    } else {
      this.setState({
        expendedRow: {},
        expendedRowIndex: -1,
        expendedRowDept: "",
      });
    }

    this.setState({ tableExpended, isExpand });
  };

  headerFormatter = (column: any, colIndex: any, row: any) => {
    let scheduleLockDisplay = false;
    let lockDays = this.state.lockDays;
    let headerDates = this.state.headerDates;
    let headerDays = this.state.headerDays;

    if (lockDays.includes(column.dataField)) {
      scheduleLockDisplay = true;
    }

    return (
      <div className="column-header">
        <div className="header-data">
          {scheduleLockDisplay && (
            <span className="schedule-lock">
              <img src={ForecastLock} alt="ScheduleLock" />
              <span className="customToolTip">
                The schedule cannot be changed because a shift from this day has
                already begun.
              </span>
            </span>
          )}

          <span className="header-day">{headerDays[colIndex - 1]}</span>
        </div>
        <p className="header-date">{headerDates[colIndex - 1]}</p>
      </div>
    );
  };

  deptNameFormatter = (cell: any, row: any, rowIndex: any) => {
    return (
      <div className="deptNameFormatter d-flex align-items-center">
        <div className="deptDetails">
          <EllipsisWithTooltip placement="bottom">
            <div className="deptName" draggable={false}>
              {row.deptName}
            </div>
          </EllipsisWithTooltip>
          <div className="deptTime">{row.deptOT}</div>
        </div>
        <div className="badgeContainer d-flex">
          {row.hasOT && (
            <div className="deptHasOT red-badges" draggable={false}>
              <span>OT</span>
            </div>
          )}
          {row.hasUnassigned && (
            <div
              className="deptHasUnassigned red-badges blue-bg"
              draggable={false}
            >
              <span>UN</span>
            </div>
          )}
        </div>
      </div>
    );
  };

  dayFormatter = (cell: any, row: any, rowIndex: any, dayType: any) => {
    let dayLocked = false;
    if (this.state.lockDays.includes(dayType)) {
      dayLocked = true;
    }
    const rowDay = row[dayType];
    const hilightClassName = this.getActualHighlight(
      rowDay.dayScheduleOT,
      rowDay.dayTotalOT
    );

    return (
      <div className={dayLocked ? "dayFormatter dayLocked" : "dayFormatter"}>
        <div className={hilightClassName}>{rowDay.dayScheduleOT}</div>
        <div className={"dayTotal"}>{rowDay.dayTotalOT}</div>
      </div>
    );
  };

  onOutsideClick = (e: any) => {
    let isChildClick = false;

    e.path?.map((x: any) => {
      if (
        x.className === "fade copy-schedule-modal modal show" ||
        x.className === "fade set-forecast-modal modal show" ||
        x.className === "fade add-edit-shift-modal modal show" ||
        x.className === "departmentViewNavigation"
      ) {
        isChildClick = true;
        
      }  
        return false;
       
    });
    if (isChildClick) {
      return false;
    }

    if (e.path !== undefined &&
     ( e.path[1]?.className === "multiSelectSearch" ||
      e.path[1]?.className === "calender-display" ||
      e.path[1]?.className === "multiSelectSearch opened" ||
      e.path[1]?.className === "calender-display open" ||
      e.path[1]?.className === "modal-content" ||
      e.path[1]?.className === "custom-control custom-checkbox" ||
      e.path[1]?.className === "form-group row" ||
      e.path[1]?.className === "variance-modal" ||
      e.path[1]?.className === "bdy-container bdr-bottom bdr-top" ||
      e.path[1]?.className === "bdy-container" ||
      e.path[1]?.className === "sm-controll custom-control custom-switch" ||
      e.path[1]?.className === "search-sec" ||
      e.path[1]?.className === "dropdown-menu" ||
      e.path[1]?.className === "dropdown-menu show" ||
      e.path[1]?.className === "SearchInput" ||
      e.path[1]?.className === "dropDownPanel" ||
      e.path[1]?.className === "alert-danger dropdown-toggle btn btn-primary" ||
      e.path[1]?.className === "import-from-dropdown select dropdown" ||
      e.path[1]?.className === "import-from-dropdown dropdown" ||
      e.path[1]?.className === "modal-header" ||
      e.path[1]?.className === "form-group" ||
      e.path[1]?.className === "copySchedule" ||
      e.path[1]?.className === "importFrom" ||
      e.path[1]?.className === "fieldArea" ||
      e.path[1]?.className === "footer" ||
      e.path[1]?.className === "confirmModalNonTable" ||
      e.path[1]?.className === "action-link" ||
      e.path[1]?.className === "notification-banners orange-bg" ||
      e.path[1]?.className === "react-calendar__navigation" ||
      e.path[2]?.className === "react-calendar__year-view__months" ||
      e.path[2]?.className === "react-calendar__month-view " ||
      e.path[1]?.className ===
        "react-calendar__month-view__weekdays__weekday" ||
      e.path[1]?.className === "react-calendar__navigation__label" ||
      e.path[1]?.className === "react-calendar__month-view__weekdays" ||
      e.path[2]?.className === "react-confirm-alert-body" ||
      e.path[6]?.className === "react-confirm-alert-body-element")
    ) {
      return;
    }
    if (
      e.currentTarget?.activeElement?.className ===
        "dropdown-toggle btn btn-primary" ||
      e.currentTarget?.activeElement?.className ===
        "filter-btn dropdown-toggle btn btn-primary" ||
      e.currentTarget?.activeElement?.className ===
        "add-shift btn-outline-primary btn btn-primary" ||
      e.currentTarget?.activeElement?.className === "close" ||
      e.currentTarget?.activeElement?.className ===
        "btn-outline-primary btn btn-primary more dropdown-toggle btn btn-primary" ||
      e.currentTarget?.activeElement?.className === "dropdown-item" ||
      e.currentTarget?.activeElement?.className ===
        "react-time-picker__inputGroup__input react-time-picker__inputGroup__hour" ||
      e.currentTarget?.activeElement?.className ===
        "react-time-picker__inputGroup__input react-time-picker__inputGroup__amPm" ||
      e.currentTarget?.activeElement?.className ===
        "react-time-picker__inputGroup__input react-time-picker__inputGroup__minute" ||
      e.currentTarget?.activeElement?.className ===
        "react-time-picker__inputGroup__input react-time-picker__inputGroup__hour react-time-picker__inputGroup__input--hasLeadingZero" ||
      e.currentTarget?.activeElement?.className ===
        "fade add-edit-shift-modal modal show" ||
      e.currentTarget?.activeElement?.className ===
        "import-forecast-button btn btn-primary" ||
      e.currentTarget?.activeElement?.className ===
        "bdr-bottom dropdown-item" ||
      e.currentTarget?.activeElement?.className ===
        "fade copy-schedule-modal modal show" ||
      e.currentTarget?.activeElement?.className ===
        "fade set-forecast-modal modal show" ||
      e.currentTarget?.activeElement?.className === "cross"
    ) {
      return;
    }
    if (this.state.isExpand) {
      this.handleOnExpand(
        this.state.expendedRow,
        true,
        this.state.expendedRowIndex,
        ""
      );
    }
  };

  refreshViewOnPublish = (type: any) => {
    this.getDeptMainData(type);

    this.childGetData(type);
  };

  refreshViewOnPTO = (type: any) => {
    this.getDeptMainData(type);
    this.childGetData(type);
  };

  parentBulkSelect = (type: any) => {
    this.setState({ isBulkSelect: type });
    this.props.parentBulkSelect(type);
  };

  openSlideOut = (schedule: any, row: any) => {
    this.props.openSlideOut(schedule, row);
  };

  openEditPTOModal = (schedule: any, row: any) => {
    this.props.openEditPTOModal(schedule, row);
  };

  
isPublishChange= (childstate:any) => {
  this.props.isPublishOrNot(true);
};


isPublishmaltishiftChange= (childstate:any) => {
  this.props.isPublishmaltishift("multipleshift")
};

  
 



  render() {
    const rowClasses = (row: any, rowIndex: any) => {
      if (
        (this.state.isExpand === true && row.action === "Expended") ||
        this.state.expendedRowIndex === rowIndex
      ) {
        return "row-Expended";
      } else if (this.state.isExpand === true) {
        return "row-Collapsed";
      } else {
        return "row-Action";
      }
    };

    const expandRow = {
      renderer: (row: any) => (
        <div id="departmentExpendedRow">
          <DepartmentAfterExpend
            ref={(ref) => {
              this.expendedChild = ref;
            }}
            row={row}
            mainViewState={this.state}
            mainParentState={this.props.parentState}
            openAddEditForm={this.openAddEditForm}
            refreshMainDepartmentView={this.refreshMainDepartmentView}
            scrollSearch={this.scrollSearch}
            parentBulkSelect={this.parentBulkSelect}
            openSlideOut={this.openSlideOut}
            openEditPTOModal={this.openEditPTOModal}
            showHideChangeLogModal={this.props.showHideChangeLogModal}

            isPublishChange={this.isPublishChange}
            isPublishmaltishiftChange={this.isPublishmaltishiftChange}

            // isPublishChange={this.isPublishChange}

          />
        </div>
      ),
      expanded: this.state.tableExpended,
      onExpand: this.handleOnExpand,
      showExpandColumn: true,
      expandByColumnOnly: false,
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <span className="chevronExpended">
              <img src={chevronRight} alt="chevron Right" />
            </span>
          );
        }
        return (
          <span className="chevronCollpsed">
            <img src={chevronRight} alt="chevron Right" />
          </span>
        );
      },
    };

    const columns = [
      {
        dataField: "departmentName",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) =>
          this.deptNameFormatter(cell, row, rowIndex),
      },
      {
        dataField: "day1",
        text: "",
        headerFormatter: this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) =>
          this.dayFormatter(cell, row, rowIndex, "day1"),
      },
      {
        dataField: "day2",
        text: "",
        headerFormatter: this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) =>
          this.dayFormatter(cell, row, rowIndex, "day2"),
      },
      {
        dataField: "day3",
        text: "",
        headerFormatter: this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) =>
          this.dayFormatter(cell, row, rowIndex, "day3"),
      },
      {
        dataField: "day4",
        text: "",
        headerFormatter: this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) =>
          this.dayFormatter(cell, row, rowIndex, "day4"),
      },
      {
        dataField: "day5",
        text: "",
        headerFormatter: this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) =>
          this.dayFormatter(cell, row, rowIndex, "day5"),
      },
      {
        dataField: "day6",
        text: "",
        headerFormatter: this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) =>
          this.dayFormatter(cell, row, rowIndex, "day6"),
      },
      {
        dataField: "day7",
        text: "",
        headerFormatter: this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) =>
          this.dayFormatter(cell, row, rowIndex, "day7"),
      },
    ];

    return (
      <>
        {this.state.loaderTrue ? (
          <ReactPageLoader useas={"deptViewMain"} />
        ) : (
          <div className="departmentViewTable">
            <div className="schedule-department-view-table">
              <ToolkitProvider
                keyField="rowNo"
                data={this.state.tableData}
                columns={columns}
              >
                {(props: {
                  searchProps: JSX.IntrinsicAttributes;
                  baseProps: JSX.IntrinsicAttributes;
                }) => (
                  <div>
                    {/* Static Header Display Starts */}
                    {this.state.staticHeaderShow && (
                      <div
                        className={
                          this.state.staticHeaderShow
                            ? "scrollHeader display"
                            : "scrollHeader hide"
                        }
                      >
                        <div className="headerDays">
                          <div className="headerDates d-flex">
                            <div className="schedule-table-search">
                              <DepartmentSearchView
                                id="mainViewScroll"
                                ref={this.departmentSearchScroll}
                                parentState={this.state}
                                hidValue={this.props.parentState.hidValue}
                                selectFromSearchBar={this.selectFromSearchBar.bind(
                                  this,
                                  "mainView"
                                )}
                              />
                            </div>

                            {this.state.headerDates.map(
                              (date: string, idx: any) => (
                                <div className="column-header" key={idx}>
                                  <div className="header-data">
                                    {this.state.lockDays.includes(
                                      `day${idx + 1}`
                                    ) && (
                                      <span className="schedule-lock">
                                        <img
                                          src={ForecastLock}
                                          alt="ScheduleLock"
                                        />
                                        <span className="customToolTip">
                                          The schedule cannot be changed because
                                          a shift from this day has already
                                          begun.
                                        </span>
                                      </span>
                                    )}
                                    <span className="header-day">
                                      {this.state.headerDays[idx]}
                                    </span>
                                  </div>
                                  <div className="header-date">{date}</div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {/* Static Header Display Ends */}
                    <div className="departmentViewNavigation">
                      <div className="navigation-menus d-flex">
                        <div
                          className={
                            this.state.isBulkSelect
                              ? "schedule-table-search p-none"
                              : "schedule-table-search"
                          }
                        >
                          <DepartmentSearchView
                            id="mainViewScroll"
                            ref={this.departmentSearch}
                            parentState={this.state}
                            hidValue={this.props.parentState.hidValue}
                            selectFromSearchBar={this.selectFromSearchBar.bind(
                              this,
                              "mainView"
                            )}
                          />
                        </div>

                        <div className="calender-container">
                          <div
                            className={
                              this.state.calenderShow
                                ? "calender-display open"
                                : "calender-display"
                            }
                            onClick={this.showCalender}
                          >
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z" />
                              <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z" />
                            </svg>

                            <span className="prev-Date">
                              {this.state.navigationCalenderDates[0]}
                            </span>
                            <span className="seperator"> - </span>
                            <span className="next-Date">
                              {this.state.navigationCalenderDates[1]}
                            </span>
                            <span className="chevron-down">
                              <FiChevronDown />
                            </span>
                          </div>
                          {this.state.calenderShow && (
                            <div className="calender">
                              <OutsideClickHandler
                                onOutsideClick={this.hideCalender}
                              >
                                <Calendar
                                  calendarType={"US"}
                                  onChange={this.calenderChange}
                                  value={this.state.calenderSelectDate}
                                />
                              </OutsideClickHandler>
                            </div>
                          )}
                        </div>

                        {this.state.isPageLock && (
                          <div className="page-lock">
                            <span
                              className="forecast-lock"
                              data-tip={tooltipMessage}
                            >
                              <img src={ForecastLock} alt="TipsPage Lock" />
                              <span className="customToolTip">
                                The schedule cannot be changed because a shift
                                from this day has already begun.
                              </span>
                            </span>
                          </div>
                        )}

                        {this.props.parentState.departmentList.length > 0 && (
                          <div className="department-filter mr-auto">
                            <SingleSearchDropdownList
                              itemList={this.props.parentState.departmentList}
                              handleSelectedItem={this.handleSelectedItem.bind(
                                this
                              )}
                              defaultItem={this.state.departmentName}
                              defaultText={"Search Department"}
                              defaultName={"Department"}
                              controlID="2"
                            />
                          </div>
                        )}

                        <div className="navigationSort ml-auto">
                          {!this.state.isBulkSelect && (
                            <Dropdown
                              className="more-action bg-blue"
                              alignRight
                            >
                              <Dropdown.Toggle className="" id="dropdown-more">
                                <div className="d-flex align-items-center">
                                  <svg
                                    width="16"
                                    height="12"
                                    viewBox="0 0 16 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M3.5 12L6.5 9H4.25V0H2.75V9H0.5L3.5 12ZM7.25 3H14V4.5H7.25V3ZM7.25 6H12.5V7.5H7.25V6ZM7.25 0H15.5V1.5H7.25V0ZM7.25 9H11V10.5H7.25V9Z" />
                                  </svg>

                                  {this.state.sortingOrder}

                                  <div className="drop-arrow d-flex">
                                    <FiChevronDown />
                                  </div>
                                </div>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Header>SORT</Dropdown.Header>
                                {this.state.sortingOrderList.map(
                                  (item: any, idx: any) => (
                                    <Dropdown.Item
                                      key={idx}
                                      className={
                                        this.state.sortingOrder === item
                                          ? "active"
                                          : ""
                                      }
                                      eventKey={item}
                                      onClick={() =>
                                        this.sortingOrderChange(item)
                                      }
                                    >
                                      <div className="d-flex">
                                        <div className="mr-auto"> {item}</div>
                                        <svg
                                          width="18"
                                          height="18"
                                          viewBox="0 0 18 18"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
                                            fill="#2CAF92"
                                          />
                                        </svg>
                                      </div>
                                    </Dropdown.Item>
                                  )
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          )}
                        </div>

                        <div className="scheduleViewDropdown">
                          <Dropdown className="more-action bg-blue" alignRight>
                            <Dropdown.Toggle className="" id="dropdown-more">
                              <div className="d-flex align-items-center">
                                <svg
                                  width="8"
                                  height="8"
                                  viewBox="0 0 8 8"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M0 0H3.2V3.2H0V0ZM0 4.8H3.2V8H0V4.8ZM4.8 0H8V3.2H4.8V0ZM4.8 4.8H8V8H4.8V4.8Z" />
                                </svg>

                                {this.state.scheduleView}

                                <div className="drop-arrow d-flex">
                                  <FiChevronDown />
                                </div>
                              </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Header>GROUP BY</Dropdown.Header>
                              {this.state.scheduleViewList.map(
                                (item: any, idx: any) => (
                                  <Dropdown.Item
                                    key={idx}
                                    className={
                                      this.state.scheduleView === item
                                        ? "active"
                                        : ""
                                    }
                                    eventKey={item}
                                    onClick={() =>
                                      this.scheduleViewListChange(item)
                                    }
                                  >
                                    <div className="d-flex">
                                      <div className="mr-auto"> {item}</div>
                                      <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
                                          fill="#2CAF92"
                                        />
                                      </svg>
                                    </div>
                                  </Dropdown.Item>
                                )
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>

                        <div className="navigationSettings">
                          <Dropdown className="more-action bg-blue" alignRight>
                            <Dropdown.Toggle className="" id="dropdown-more">
                              <div className="d-flex align-items-center">
                                <svg
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M0.562614 9.18059L1.89595 11.4873C2.07995 11.8059 2.48795 11.9153 2.80661 11.7306L3.73728 11.1933C4.12395 11.4979 4.55128 11.7479 5.00061 11.9346V12.9999C5.00061 13.3679 5.29861 13.6666 5.66728 13.6666H8.33395C8.70261 13.6666 9.00062 13.3679 9.00062 12.9999V11.9346C9.44995 11.7479 9.87661 11.4979 10.2639 11.1933L11.1946 11.7306C11.5126 11.9139 11.9219 11.8039 12.1053 11.4873L13.4386 9.18059C13.6219 8.86325 13.5119 8.45325 13.1953 8.26992L12.2806 7.74125C12.3153 7.49525 12.3333 7.24725 12.3333 6.99992C12.3333 6.75259 12.3153 6.50459 12.2793 6.25858L13.1939 5.72992C13.5113 5.54659 13.6213 5.13659 13.4373 4.81925L12.1039 2.51259C11.9199 2.19392 11.5119 2.08459 11.1933 2.26925L10.2626 2.80659C9.87595 2.50192 9.44928 2.25125 8.99995 2.06525V0.999919C8.99995 0.631919 8.70195 0.333252 8.33328 0.333252H5.66661C5.29795 0.333252 4.99995 0.631919 4.99995 0.999919V2.06525C4.55061 2.25192 4.12395 2.50192 3.73661 2.80659L2.80661 2.26925C2.48728 2.08459 2.07995 2.19392 1.89595 2.51259L0.562614 4.81925C0.379281 5.13659 0.489281 5.54659 0.805948 5.72992L1.72061 6.25858C1.68461 6.50459 1.66661 6.75259 1.66661 6.99992C1.66661 7.24725 1.68461 7.49525 1.72061 7.74125L0.805948 8.26992C0.488614 8.45325 0.378614 8.86325 0.562614 9.18059ZM6.99995 4.33325C8.47061 4.33325 9.66661 5.52925 9.66661 6.99992C9.66661 8.47059 8.47061 9.66659 6.99995 9.66659C5.52928 9.66659 4.33328 8.47059 4.33328 6.99992C4.33328 5.52925 5.52928 4.33325 6.99995 4.33325Z" />
                                </svg>
                                <div className="drop-arrow ml-0 d-flex">
                                  <FiChevronDown />
                                </div>
                              </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                className="bdr-bottom"
                                onClick={() => this.saveAsDefaultView()}
                              >
                                Save as Default View
                              </Dropdown.Item>
                              {!this.state.isBulkSelect && (
                                <div className="variance-modal">
                                  <div className="bdy-container bdr-bottom bdr-top">
                                    <div className="ttl">
                                      Hide/Show Department Shifts
                                    </div>
                                    <Form.Group as={Row} controlId="">
                                      <Form.Label column sm="8">
                                        {this.state.hideDeptsFlag
                                          ? `Show Departments`
                                          : `Hide Departments`}
                                      </Form.Label>
                                      <Col sm="4">
                                        <Form.Check
                                          type="switch"
                                          className="sm-controll"
                                          id="custom-switch1"
                                          label=""
                                          checked={!this.state.hideDeptsFlag}
                                          onChange={() => {
                                            this.hideShowDeptUpdate();
                                          }}
                                        />
                                      </Col>
                                    </Form.Group>
                                  </div>

                                  <div className="bdy-container">
                                    <div className="ttl">Schedule Variance</div>
                                    <Form.Group as={Row} controlId="">
                                      <Form.Label column sm="8">
                                        0 - {Number(this.state.actualHighlight)}
                                        %
                                      </Form.Label>
                                      <Col sm="4">
                                        <Form.Check
                                          type="switch"
                                          className="sm-controll"
                                          id="custom-switch2"
                                          label=""
                                          checked={this.state.isOptionHighlight}
                                          onChange={(e: any) =>
                                            this.handleActualFilter(e)
                                          }
                                        />
                                      </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="">
                                      <Form.Label column sm="8">
                                        {Number(this.state.actualHighlight)}% +
                                      </Form.Label>
                                      <Col sm="4">
                                        <Form.Check
                                          type="switch"
                                          className="sm-controll"
                                          id="custom-switch3"
                                          label=""
                                          checked
                                        />
                                      </Col>
                                    </Form.Group>
                                  </div>
                                </div>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>

                    {!this.state.isPageLock &&
                      this.state.importForecastBanner &&
                      this.state.importForecastBannerType === "noForecast" && (
                        <div className="notification-banners orange-bg">
                          <div >
                            <span className="bold">
                              No forecast set for this week.
                            </span>{" "}
                            You must first create a forecast before you can
                            create a schedule.
                          </div>
                          {this.props.parentState.permissionManageForecast && (
                            <button
                              type="button"
                              className="btn btn-primary btn-ghost"
                              onClick={this.props.setForecastModalShow}
                            >
                              Set Forecast
                            </button>
                          )}
                        </div>
                      )}

                    {!this.state.isPageLock &&
                      this.state.importForecastBanner &&
                      this.state.importForecastBannerType ===
                        "errorForecast" && (
                        <div className="notification-banners orange-bg">
                          <div className="mr-auto">
                            <span className="bold">
                              No forecast set for this week.
                            </span>{" "}
                            An error occurred during a previous forecast import
                            attempt.
                          </div>
                          {this.props.parentState.permissionManageForecast && (
                            <Link
                              to={{
                                pathname: "/labor-management/forecast",
                                state: {
                                  scheduleError: true,
                                  pageLoadDate:
                                    this.props.parentState.todayDate,
                                  viewType: this.props.parentState.viewType,
                                },
                              }}
                            >
                              <button
                                type="button"
                                className="btn btn-primary btn-ghost"
                              >
                                Fix Forecast
                              </button>
                            </Link>
                          )}
                        </div>
                      )}
                    {this.state.tableLoaderTrue ? (
                      <ReactPageLoader useas={"deptViewMainTableLoad"} />
                    ) : (
                      <OutsideClickHandler
                        onOutsideClick={(e) => {
                          this.onOutsideClick(e);
                        }}
                      >
                        <div className="table scheduleTable">
                          <BootstrapTable
                            {...props.baseProps}
                            keyField="rowNo"
                            hover
                            condensed
                            rowClasses={rowClasses}
                            expandRow={expandRow}
                          />
                        </div>
                      </OutsideClickHandler>
                    )}
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </div>
        )}
      </>
    );
  }
}
