import React from "react";
import {
  Roles,
  RolesforTreeView,
} from "../../../Common/Services/RolePermissionSetting";
import {
  IRolePermissionResponseDto,
  IUpdateRoleOrderDto,
} from "../../../Common/Contracts/IRolePermission";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import { ToastContainer, toast } from "react-toastify";
import { Utils } from "../../../Common/Utilis";
// const SortableItem = SortableElement(({ value, idx }) => (
//   <div className="row" style={{ cursor: "move" }}>
//     <div className="col-sm-1 td1">
//       <div className="tdBody">{idx + 2}</div>
//     </div>
//     <div className="col-sm-11 td2">
//       <div className="tdBody">{value.roleName}</div>
//     </div>
//   </div>
// ));

const SortableItem = SortableElement<{ value: any, idx: any }>(({ value, idx }) => (
  <div className="row" style={{ cursor: "move" }}>
    <div className="col-sm-1 td1">
      <div className="tdBody">{idx + 2}</div>
    </div>
    <div className="col-sm-11 td2">
      <div className="tdBody">{value.roleName}</div>
    </div>
  </div>
));



const SortableList = SortableContainer<{ userManagement: [] }>(({ userManagement }) => {
  userManagement = userManagement || [];
  return (
    <div className="tableTbody">
      {(userManagement || [])?.map((value, index) => (
        <SortableItem key={index} idx={index} index={index} value={value} />
      ))}
    </div>
  );
});

export default class UserManagementHierarchy extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      userManagement: [],
      isSaveOpen: false,
      loading: false,
      isWait: false,
    };
  }
  componentDidMount() {
    this.rolePermissionList();
  }
  rolePermissionList = () => {
    this.setState({ loading: true });
    Roles.GetUserRoleList().then(
      async (result: IRolePermissionResponseDto[] | null) => {
        if (result != null) {
          let data = this.sortByKey(result, "orderNo");
          this.setState({ userManagement: data.filter((o: any) => o?.roleName !== "Administrator") });
          this.setState({ loading: false });
        }
      }
    );
  };

  sortByKey = (array: any, key: any) => {
    return array.sort((a, b) => {
      let x = parseInt(a[key]);
      let y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  };
  startState = () => { };
  onSortMove = () => { };
  onSortEnd = ({ oldIndex, newIndex }) => {
    if (newIndex !== oldIndex) {
      this.setState({ isSaveOpen: true }, () => {
        this.props.changeValueFuntion(true);
      });
    }
    this.setState({ isWait: false });
    this.setState(({ userManagement }) => ({
      userManagement: arrayMove(userManagement, oldIndex, newIndex),
    }));
  };
  saveApi = () => {
    let { userManagement } = this.state;
    let RoleIDs: any = [];
    let OldOrderIDs: any = [];
    let NewOrderIDs: any = [];
    this.setState({ isWait: true });
    userManagement.forEach((items: any, index: any) => {
      let newIndex = index + 1
      RoleIDs.push(items.roleID);
      OldOrderIDs.push(items.orderNo);
      NewOrderIDs.push(newIndex + 1);
    });
    let request = {} as IUpdateRoleOrderDto;
    request.RoleIDs = RoleIDs.toString();
    request.OldOrderIDs = OldOrderIDs.toString();
    request.NewOrderIDs = NewOrderIDs.toString();
    RolesforTreeView.UpdateRoleOrder(request).then(
      async (result: any | null) => {
        if (result.toLowerCase() === "success") {
          this.rolePermissionList();
          this.props.getRoleData();
          this.setState({ isSaveOpen: false }, () => {
            this.props.changeValueFuntion(false);
          });
        } else if (result.toLowerCase() === "lowsecurityrole") {
          Utils.toastError("You cannot change Hierarchy to ensure that all Low Security users can receive password assistance.",
            { position: toast.POSITION.BOTTOM_RIGHT, containerId: "userManagementHierarchy" });
          this.discardApi();
        }
      }
    );
  };

  discardApi = () => {
    this.rolePermissionList();
    this.setState({ isSaveOpen: false }, () => {
      this.props.changeValueFuntion(false);
    });
  };
  render() {
    let { userManagement, isSaveOpen, loading, isWait, d } = this.state;
    return (
      <>
        <ToastContainer
          enableMultiContainer
          autoClose={3000}
          containerId={"userManagementHierarchy"}
        />
        <div className="hierarchyCusAlert">
          <div className="cusAlert">
            <p>
              <strong>
                The order of the roles determines who can create, view and edit
                what level of accounts. Each role can manage the details for
                those below its level, but not equal to or above.
              </strong>
            </p>
          </div>
        </div>
        <div className="hierarchyDragableTable">
          <div className="tableThead">
            <div className="row hasBorder">
              <div className="col-sm-1 th">
                <strong>Order</strong>
              </div>
              <div className="col-sm-11 th">
                <strong>Role</strong>
              </div>
            </div>
          </div>

          <div
            className={`${isSaveOpen ? "hasFixedAction hasTable" : "hasTable"}`}
          >
            {loading ? (
              ""
            ) : (
              <>
                <div className="tableTbody">
                  <div className="row">
                    <div className="col-sm-1">
                      <div className="tdBody">1</div>
                    </div>
                    <div className="col-sm-11">
                      <div className="tdBody">Administrator</div>
                    </div>
                  </div>
                </div>

                <SortableList
                  helperClass="sortableHelper"
                  axis="y"
                  lockAxis="y"
                  userManagement={userManagement}
                  onSortEnd={this.onSortEnd}
                  onSortStart={this.startState}
                  onSortMove={this.onSortMove}
                />
                {/* <SortableList
                  helperClass="sortableHelper"
                  axis="y"
                  lockAxis="y"
                  userManagement= {...userManagement}
                  onSortEnd={this.onSortEnd}
                  onSortStart={this.startState}
                  onSortMove={this.onSortMove}
                  lockToContainerEdges={true}
                /> */}
              </>
            )}
          </div>
          {isSaveOpen && (
            <div className="fixed-action">
              <div className="d-flex align-content-center flex-wrap">
                <div className="mr-auto message">
                  <span>You have unsaved changes</span>
                </div>
                <button
                  type="button"
                  onClick={this.discardApi}
                  className="btn btn-primary btn-discard"
                >
                  Discard
                </button>
                <button
                  type="button"
                  disabled={isWait}
                  onClick={this.saveApi}
                  className="btn btn-primary"
                >
                  {isWait ? "Loading..." : "Save Changes"}
                </button>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}
