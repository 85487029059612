import React from "react";
import {
    Container,
    Dropdown,
    Tabs,
    Tab,
    Form,
    Button,
    Modal,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { Redirect } from "react-router-dom";
import { LaborTimeSheet } from "../../../../../Common/Services/LaborTimeSheet";
import { toast, ToastContainer } from "react-toastify";
import { LaborPerformance as laborPerformance } from "../../../../../Common/Services/LaborPerfomance";
import { LaborSmileID } from "../../../../../Common/Services/LaborSmileId";
import { resolve, reject } from "q";
import profile from "../../../../../Common/Assets/Images/profile.png";
import { LaborDeptPosition as laborDeptPosition } from "../../../../../Common/Services/LaborDeptPosition";
import ForecastLock from "../../../../../Common/Assets/Images/icons/forecast-lock.svg";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import OutsideClickHandler from "react-outside-click-handler";
import $ from "jquery";
import { MyImage } from "../../../../../Common/Components/MyImage";
import { LaborTimesheetDispute } from "../../../../LaborManagement/TimeSheet/LaborTimesheetDispute";
import { LaborTimesheetResolvedDispute } from "../../../../LaborManagement/TimeSheet/LaborTimesheetResolvedDispute";
import { Utils } from "../../../../../Common/Utilis";
import { ConfirmationModal } from "../../../../../Common/Components/ConfirmationModal";

export class OtrTimeSheetSlideOut extends React.Component<any, any> {
    private divRef: any;
    private positionRef: any;
    private breakpositionRef: any;
    private btnhHandleSave: any;
    private btnSaveNewEntry: any;
    private btnSaveBreak: any;
    private btnSplitShift: any;
    private btnChangeShift: any;
    private btnUpdateBreak: any;
    private btnEditShiftIn: any;
    private btnEditShiftOut: any;
    constructor(props: any) {
        super(props);
        this.divRef = React.createRef();
        this.positionRef = React.createRef();
        this.breakpositionRef = React.createRef();
        this.btnhHandleSave = React.createRef();
        this.btnSaveNewEntry = React.createRef();
        this.btnSaveBreak = React.createRef();
        this.btnChangeShift = React.createRef();
        this.btnSplitShift = React.createRef();
        this.btnUpdateBreak = React.createRef();
        this.btnEditShiftIn = React.createRef();
        this.btnEditShiftOut = React.createRef();
        this.state = {
            activetab: "details",
            hotelID: this.props.data.hotelID,
            hotelName: this.props.data.hotelName,
            userUniqueno: this.props.data.userUniqueno,
            userName: this.props.data.userName,
            payPeriod: this.props.data.payPeriod,
            date: this.props.data.date,
            ruleType: this.props.data.ruleType,
            activeClass: -1,
            allData: [],
            total: [],
            data: [],
            positionWiseList: [],
            positionList: [],
            dayDetails: [],
            alldepartmentlist: [],
            allpositionlist: [],
            breakPositionlist: [],
            actionText: "",
            adjustments: "0:00",
            dispute: 0,
            isDiputeOpen: "",
            condition: "All",
            exportedDate: "",
            forseApprovalModal: false,
            addEntry: {
                newInTime: "00:00",
                newOutTime: "00:00",
                breakHH: "",
                breakMM: "",
                newDepartmentId: 0,
                newDepartment: "Select",
                newPositionId: 0,
                newPosition: "Select",
                newNotes: "",
            },
            forseApprovalValue: "",
            forseApprovalError: "",
            isDeepLinking: false,
            shiftID: 0,
            pageloader: true,
            pageloader1: true,
            startdate: "",
            enddate: "",
            forceapproval: "No",
            managedispute: "No",
            isRedirect: false,
            payPeriodDates: [],
        };
        this.closeSlideOut = this.closeSlideOut.bind(this);
        this.handleComparisonClick = this.handleComparisonClick.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.noteChange = this.noteChange.bind(this);
        this.noteOutChange = this.noteOutChange.bind(this);
        this.departmentDropdownChange = this.departmentDropdownChange.bind(this);
        this.positionDropdownChange = this.positionDropdownChange.bind(this);
        this.addNewEntryNotesChange = this.addNewEntryNotesChange.bind(this);
        this.positionDropdownChangeBreak = this.positionDropdownChangeBreak.bind(this);

        this.saveNewEntry = this.saveNewEntry.bind(this);
        this.handleAddBreak = this.handleAddBreak.bind(this);
        this.handleAddEntry = this.handleAddEntry.bind(this);
        this.breakHHChange = this.breakHHChange.bind(this);
        this.breakMMChange = this.breakMMChange.bind(this);
        this.saveBreak = this.saveBreak.bind(this);
        this.updateBreak = this.updateBreak.bind(this);
        this.changebreakHH = this.changebreakHH.bind(this);
        this.changebreakMM = this.changebreakMM.bind(this);

        this.departmentSplitChange = this.departmentSplitChange.bind(this);
        this.positionSplitChange = this.positionSplitChange.bind(this);
        this.saveSplitEntry = this.saveSplitEntry.bind(this);
        this.saveChangePosition = this.saveChangePosition.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.refreshTimesheetDetails = this.refreshTimesheetDetails.bind(this);
    }

    componentDidMount() {
        let currentURL = window.location.href;
        if (currentURL.includes("?")) this.setState({ isDeepLinking: true });
        else this.setState({ isDeepLinking: false });
        this.loadLaborTimesheetDetails();
        this.loadTimesheetDateWise();
        this.getlaborPositionDeptByemployee();
        this.getDisputeCount();
        this.getHidPayPeriod(this.props.data.date, this.props.data.hotelID);
    }

    refreshTimesheetDetails() {
        this.setState({ condition: "" });
        this.setState({ condition: "Dispute" }, () => {
            this.loadLaborTimesheetDetails();
            this.loadTimesheetDateWise();
            this.getDisputeCount();
        });
    }

    getHidPayPeriod(selectedDate: any, hotelID: Number) {
        laborPerformance
            .getPayPeriodDates(hotelID, selectedDate, "Weekly")
            .then(async (result: any[] | null) => {
                if (result != null) {
                    this.setState({
                        payPeriodDates: [result[0].paystartdate, result[0].payenddate],
                        exportedDate: result[0].exporteddate,
                        startdate: result[0].startdate,
                        payPeriod: result[0].startEndDate,
                        enddate: result[0].enddate
                    });
                }
            });
    }

    getDisputeCount() {
        LaborTimeSheet.LaborDisputeCount(
            this.props.data.hotelID,
            this.props.data.date,
            this.props.data.userName,
            "TimeSheet"
        ).then(async (result: any | null) => {
            if (result != null) {
                this.setState({
                    dispute: result.totalDispute !== null ? result.totalDispute : 0,
                    isDiputeOpen: result.openBedge,
                });
            }
        });
    }

    closeSlideOut() {
        this.props.hideSlideOut();
    }

    handleAddEntry = (e: any, index: any) => {
        this.setState({ addEntry: {}, allpositionlist: [] }, () =>
            this.setState({
                addEntry: {
                    newInTime: "00:00",
                    newOutTime: "00:00",
                    breakHH: "",
                    breakMM: "",
                    newDepartmentId: 0,
                    newDepartment: "Select",
                    newPositionId: 0,
                    newPosition: "Select",
                    newNotes: "",
                    allpositionlist: [],
                },
            })
        );
    };

    handleAddBreak = (e: any, index: string | number) => {
        this.setState({ addEntry: {}, breakPositionlist: [] }, () =>
            this.setState({
                addEntry: {
                    newInTime: "00:00",
                    newOutTime: "00:00",
                    breakHH: "",
                    breakMM: "",
                    newDepartmentId: 0,
                    newDepartment: "Select",
                    newPositionId: 0,
                    newPosition: "Select",
                    newNotes: "",
                    breakPositionlist: [],
                },
            })
        );

        let dataArr: any[] = [];
        let dataArr1: any[] = [];
        let breakPosition: any[] = [];
        dataArr = Object.assign(
            [],
            JSON.parse(JSON.stringify(this.state.dayDetails[index].timesheet.filter(a => a.entryType !== "TimeOff")))
        );
        dataArr1 = Object.assign(
            [],
            JSON.parse(JSON.stringify(this.state.dayDetails[index].timesheet.filter(a => a.entryType !== "TimeOff")))
        );
        if (dataArr.length > 0) {
            dataArr.forEach((element) => {
                let items: any = {};
                items.positionID = element.positionID;
                items.positionName = element.positionName;
                let breakPoslen = dataArr1.filter(
                    (item) =>
                        item.positionID === element.positionID && (item.entryType === "Break")
                ).length;
                let isExist = breakPosition.filter(
                    (item) => item.positionID === element.positionID
                );
                if (isExist.length === 0 && breakPoslen === 0) {
                    breakPosition.push(items);
                }
            });
            this.setState({ breakPositionlist: breakPosition });
        } else {
            this.setState({ breakPositionlist: breakPosition });
        }
    };

    updateSmileIdStatus(type: string, row: any, mode: number) {
        this.setState({ loaderTrue: true });
        let request = {} as any;
        request.hotelID = this.state.hotelID;
        request.Uniqueno = mode === 1 ? row.inUniqueno : row.outUniqueno;
        request.isFalsePunches = type;
        LaborSmileID.SimeIdAction(request)
            .then(async (result: any | null) => {
                if (result.saveStatus === "Success") {
                    this.loadTimesheetDateWise();
                } else {
                    Utils.toastError(result.message, {
                        // // position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
                resolve();
                this.setState({ loaderTrue: false });
            })
            .catch((error) => {
                reject();
                this.setState({ loaderTrue: false });
            });
    }

    intimeChange = (e: any, row: any, rowIndex: any) => {
        let data = Object.assign(
            [],
            JSON.parse(JSON.stringify(this.state.dayDetails))
        );
        data[row.groupID - 1].timesheet[rowIndex].inTimedup = e.target.value;
        this.setState({ dayDetails: data });
    };

    outtimeChange = (e: any, row: any, rowIndex: any) => {
        let data = Object.assign(
            [],
            JSON.parse(JSON.stringify(this.state.dayDetails))
        );
        data[row.groupID - 1].timesheet[rowIndex].outTimedup = e.target.value;
        this.setState({ dayDetails: data });
    };

    noteChange = (e: any, row: any, rowIndex: any) => {
        let data = Object.assign(
            [],
            JSON.parse(JSON.stringify(this.state.dayDetails))
        );
        data[row.groupID - 1].timesheet[rowIndex].lastInNotesdup = e.target.value;
        this.setState({ dayDetails: data });
    };

    noteOutChange = (e: any, row: any, rowIndex: any) => {
        let data = Object.assign(
            [],
            JSON.parse(JSON.stringify(this.state.dayDetails))
        );
        data[row.groupID - 1].timesheet[rowIndex].lastOutNotesdup = e.target.value;
        this.setState({ dayDetails: data });
    };

    resetPopupData = (row: any, rowIndex: any, mode: any, index: any) => {
        let data = Object.assign(
            [],
            JSON.parse(JSON.stringify(this.state.dayDetails))
        );
        if (mode === "In") {
            data[row.groupID - 1].timesheet[rowIndex].lastInNotesdup = "";
            data[row.groupID - 1].timesheet[rowIndex].inTimedup = row.inTime;
        } else {
            data[row.groupID - 1].timesheet[rowIndex].lastOutNotesdup = "";
            data[row.groupID - 1].timesheet[rowIndex].outTimedup = row.outTime;
        }

        this.setState({ dayDetails: data });
        if (mode === "In" || mode === "Out") { this.getSimleIDProfileImage(mode, row) }
        $(
            "#punch" + mode + "-tab-" + rowIndex + "-tab-details" + rowIndex + index
        ).removeClass("active");
        $(
            "#punch" +
            mode +
            "-tab-" +
            rowIndex +
            "-tab-edit-punch" +
            rowIndex +
            index
        ).removeClass("active");
        $(
            "#punch" + mode + "-tab-" + rowIndex + "-tab-smile-id" + rowIndex + index
        ).removeClass("active");
        $(
            "#punch" +
            mode +
            "-tab-" +
            rowIndex +
            "-tabpane-details" +
            rowIndex +
            index
        ).removeClass("active show");
        $(
            "#punch" +
            mode +
            "-tab-" +
            rowIndex +
            "-tabpane-edit-punch" +
            rowIndex +
            index
        ).removeClass("active show");
        $(
            "#punch" +
            mode +
            "-tab-" +
            rowIndex +
            "-tabpane-smile-id" +
            rowIndex +
            index
        ).removeClass("active show");

        if (
            $(
                "#punch" +
                mode +
                "-tab-" +
                rowIndex +
                "-tabpane-details" +
                rowIndex +
                index
            ).length > 0
        ) {
            $(
                "#punch" +
                mode +
                "-tab-" +
                rowIndex +
                "-tabpane-details" +
                rowIndex +
                index
            ).addClass("active show");
            $(
                "#punch" + mode + "-tab-" + rowIndex + "-tab-details" + rowIndex + index
            ).addClass("active");
        } else if (
            $(
                "#punch" +
                mode +
                "-tab-" +
                rowIndex +
                "-tabpane-edit-punch" +
                rowIndex +
                index
            ).length > 0
        ) {
            $(
                "#punch" +
                mode +
                "-tab-" +
                rowIndex +
                "-tabpane-edit-punch" +
                rowIndex +
                index
            ).addClass("active show");
            $(
                "#punch" +
                mode +
                "-tab-" +
                rowIndex +
                "-tab-edit-punch" +
                rowIndex +
                index
            ).addClass("active");
        } else if (
            $(
                "#punch" +
                mode +
                "-tab-" +
                rowIndex +
                "-tabpane-smile-id" +
                rowIndex +
                index
            ).length > 0
        ) {
            $(
                "#punch" +
                mode +
                "-tab-" +
                rowIndex +
                "-tabpane-smile-id" +
                rowIndex +
                index
            ).addClass("active show");
            $(
                "#punch" +
                mode +
                "-tab-" +
                rowIndex +
                "-tab-smile-id" +
                rowIndex +
                index
            ).addClass("active");
        }
    };

    resetActiveTab = (rowIndex, selectedtab, index, mode) => {
        if (
            $(
                "#punch" +
                mode +
                "-tab-" +
                rowIndex +
                "-tabpane-details" +
                rowIndex +
                index
            ).length > 0
        ) {
            $(
                "#punch" + mode + "-tab-" + rowIndex + "-tab-details" + rowIndex + index
            ).removeClass("active");
            $(
                "#punch" +
                mode +
                "-tab-" +
                rowIndex +
                "-tabpane-details" +
                rowIndex +
                index
            ).removeClass("active show");
        } else if (
            $(
                "#punch" +
                mode +
                "-tab-" +
                rowIndex +
                "-tabpane-edit-punch" +
                rowIndex +
                index
            ).length > 0
        ) {
            $(
                "#punch" +
                mode +
                "-tab-" +
                rowIndex +
                "-tab-edit-punch" +
                rowIndex +
                index
            ).removeClass("active");
            $(
                "#punch" +
                mode +
                "-tab-" +
                rowIndex +
                "-tabpane-edit-punch" +
                rowIndex +
                index
            ).removeClass("active show");
        } else if (
            $(
                "#punch" +
                mode +
                "-tab-" +
                rowIndex +
                "-tabpane-smile-id" +
                rowIndex +
                index
            ).length > 0
        ) {
            $(
                "#punch" +
                mode +
                "-tab-" +
                rowIndex +
                "-tab-smile-id" +
                rowIndex +
                index
            ).removeClass("active");
            $(
                "#punch" +
                mode +
                "-tab-" +
                rowIndex +
                "-tabpane-smile-id" +
                rowIndex +
                index
            ).removeClass("active show");
        }

        if (selectedtab === "details" + rowIndex + index) {
            $(
                "#punch" +
                mode +
                "-tab-" +
                rowIndex +
                "-tabpane-details" +
                rowIndex +
                index
            ).addClass("active show");
            $(
                "#punch" + mode + "-tab-" + rowIndex + "-tab-details" + rowIndex
            ).addClass("active");
        } else if (selectedtab === "edit-punch" + rowIndex + index) {
            $(
                "#punch" +
                mode +
                "-tab-" +
                rowIndex +
                "-tabpane-edit-punch" +
                rowIndex +
                index
            ).addClass("active show");
            $(
                "#punch" +
                mode +
                "-tab-" +
                rowIndex +
                "-tab-edit-punch" +
                rowIndex +
                index
            ).addClass("active");
        } else if (selectedtab === "smile-id" + rowIndex + index) {
            $(
                "#punch" +
                mode +
                "-tab-" +
                rowIndex +
                "-tabpane-smile-id" +
                rowIndex +
                index
            ).addClass("active show");
            $(
                "#punch" +
                mode +
                "-tab-" +
                rowIndex +
                "-tab-smile-id" +
                rowIndex +
                index
            ).addClass("active");
        }
    };

    getSimleIDProfileImage = (mode: any, row: any) => {
        let request = {
            hotelID: this.state.hotelID,
            uniqueID: mode === "In" ? row.inUniqueno : row.outUniqueno,
        };

        LaborSmileID.GetSimleIDProfile(request).then(async (result: any | null) => {
            let staticPath = ""
            let basePath;
            if (result.success) {
                if (result.result !== '') {
                    staticPath = "data:image/jpeg;base64,"
                    basePath = result.result;
                } else {
                    staticPath = ""
                    basePath = profile;
                }
            } else {
                staticPath = ""
                basePath = profile;
            }
            let smileId = (mode === "In" ? row.inUniqueno : row.outUniqueno);
            $("#" + smileId).attr('src', staticPath + basePath);
        })
    }

    handleSave = (e: any, row: any, rowIndex: any, punchtype: string) => {
        let currentPunchInMins = 0;
        let totalDayMin = 24 * 60;
        if (punchtype === "In") {
            if (row.lastInNotesdup.trim().length === 0) {
                Utils.toastError("Please enter explanation", {
                    // // position: toast.POSITION.BOTTOM_RIGHT,
                });
                return false;
            }
            if (row.lastInNotesdup.trim().length > 200) {
                Utils.toastError("Notes length can not exceed 200 characters", {
                    // // position: toast.POSITION.BOTTOM_RIGHT,
                });
                return false;
            }

            let punchHrs =
                Number(row.outTime.split(":")[0]) - Number(row.inTimedup.split(":")[0]);
            currentPunchInMins =
                punchHrs * 60 +
                Number(row.outTime.split(":")[1]) -
                Number(row.inTimedup.split(":")[1]);
        } else if (punchtype === "Out") {
            if (row.lastOutNotesdup.trim().length === 0) {
                Utils.toastError("Please enter explanation", {
                    // // position: toast.POSITION.BOTTOM_RIGHT,
                });
                return false;
            }
            if (row.lastOutNotesdup.trim().length > 200) {
                Utils.toastError("Notes length can not exceed 200 characters", {
                    // // position: toast.POSITION.BOTTOM_RIGHT,
                });
                return false;
            }

            let punchHrs = 0;
            if (
                Number(row.outTimedup.split(":")[0]) < Number(row.inTime.split(":")[0])
            ) {
                punchHrs =
                    24 -
                    Number(row.inTime.split(":")[0]) +
                    Number(row.outTimedup.split(":")[0]);
            } else {
                punchHrs =
                    Number(row.outTimedup.split(":")[0]) -
                    Number(row.inTime.split(":")[0]);
            }
            currentPunchInMins =
                punchHrs * 60 +
                Number(row.outTimedup.split(":")[1]) -
                Number(row.inTime.split(":")[1]);
        }

        if (currentPunchInMins < 0) {
            currentPunchInMins = currentPunchInMins + totalDayMin;
        }
        let dataArr = Object.assign([], JSON.parse(JSON.stringify(this.state.dayDetails[row.groupID - 1].timesheet)));
        let totalbreakDetail = dataArr.filter(
            (element: { positionID: any }) => element.positionID === row.positionID
        );
        let total = 0;
        // eslint-disable-next-line array-callback-return
        totalbreakDetail.map((arr: any) => {
            if (arr.total.length > 0) {
                let subTotal = 0;
                if (arr.total.includes(":")) {
                    const dataArray = arr.total.split(":");
                    let HH = Number(dataArray[0]);
                    let MM = Number(dataArray[1]);
                    if (dataArray[0].includes("-") || dataArray[1].includes("-")) {
                        HH = HH < 0 ? HH * -1 : HH;
                        MM = MM < 0 ? MM * -1 : MM;
                        subTotal = (HH * 60 + MM) * -1;
                    } else {
                        subTotal = HH * 60 + MM;
                    }
                    total = total + subTotal;
                }
            }
        });

        let breaktotal = 0;
        // eslint-disable-next-line array-callback-return
        totalbreakDetail.map((arr: any) => {
            if (arr.breakTime.length > 0) {
                let subTotal = 0;
                if (arr.breakTime.includes(":")) {
                    const dataArray = arr.breakTime.split(":");
                    let HH = Number(dataArray[0]);
                    let MM = Number(dataArray[1]);
                    if (dataArray[0].includes("-") || dataArray[1].includes("-")) {
                        HH = HH < 0 ? HH * -1 : HH;
                        MM = MM < 0 ? MM * -1 : MM;
                        subTotal = (HH * 60 + MM) * -1;
                    } else {
                        subTotal = HH * 60 + MM;
                    }
                    breaktotal = breaktotal + subTotal;
                }
            }
        });

        let currentrowOldTotal = row.total;
        const dataArray = row.total.split(":");
        let HH = Number(dataArray[0]);
        let MM = Number(dataArray[1]);
        if (dataArray[0].includes("-") || dataArray[1].includes("-")) {
            HH = HH < 0 ? HH * -1 : HH;
            MM = MM < 0 ? MM * -1 : MM;
            currentrowOldTotal = (HH * 60 + MM) * -1;
        } else {
            currentrowOldTotal = HH * 60 + MM;
        }

        if (row.entryType !== "TimeOff") {
            if (total - currentrowOldTotal + currentPunchInMins < breaktotal) {
                Utils.toastError("Actual hours should be greater than Break duration.", {
                    // // position: toast.POSITION.BOTTOM_RIGHT,
                });
                return false;
            }

            if (Number(total) <= Number(row.breakHH * 60) + Number(row.breakMM)) {
                Utils.toastError("Break duration should be less than Actual hours.", {
                    // // position: toast.POSITION.BOTTOM_RIGHT,
                });
                return false;
            }
        }


        if (row.manuals === "UTO" || row.manuals === "PTO") {
            let saveActualDetail: any[] = [];
            let storage = JSON.parse(localStorage.getItem("storage")!);
            let tenantID = storage === null ? 0 : (storage.tenantID as any);
            let newItem: any = {};
            newItem.tenantID = tenantID;
            newItem.hotelID = this.state.hotelID;
            newItem.userName = row.employee;
            newItem.inTime = row.inTimedup;
            newItem.outTime = row.outTimedup;
            if (punchtype === "In") {
                newItem.Memo = row.lastInNotesdup;
            } else {
                newItem.Memo = row.lastOutNotesdup;
            }
            newItem.scheduleId = row.scheduleNo;
            newItem.StartDate = row.inDate;
            newItem.outDate = row.outDate;
            newItem.entryType = punchtype;
            newItem.WeekStartDate = this.state.startdate;
            newItem.WeekEndDate = this.state.enddate;
            newItem.userUniqueno = 0;
            newItem.PTOUniqueno = row.ptouniqueno;
            newItem.isRequestEntry = "No"
            saveActualDetail.push(newItem);
            this.updatePTOUTODetails(saveActualDetail);
        } else {
            let saveActualDetail: any[] = [];
            let storage = JSON.parse(localStorage.getItem("storage")!);
            let tenantID = storage === null ? 0 : (storage.tenantID as any);
            let newItem: any = {};
            newItem.tenantID = tenantID;
            newItem.hotelID = this.state.hotelID;
            newItem.positionID = Number(row.positionID);
            newItem.selectDate = row.date;
            newItem.employeeName = row.employee;
            newItem.departmentID = Number(row.departmentID);
            newItem.inTime = this.formatDate(row.inTimedup);
            newItem.outTime = this.formatDate(row.outTimedup);
            newItem.actualId = row.actualUniqueno;
            if (punchtype === "In") {
                newItem.notes = row.lastInNotesdup;
            } else {
                newItem.notes = row.lastOutNotesdup;
            }
            newItem.entryType = row.entryType;
            newItem.flag = row.flag;
            newItem.scheduleId = row.scheduleNo;
            newItem.scheduleBreaktime = row.outTime !== "" ? "0.00" : row.breakTime === "" ? "0.00" : row.breakTime.toString().replace(":", ".");
            newItem.entryType = row.entryType;
            newItem.inDate = row.inDate;
            newItem.outDate = row.outDate;
            newItem.inUniqueNo = row.inUniqueno === null ? 0 : row.inUniqueno;
            newItem.outUniqueNo = row.outUniqueno === null ? 0 : row.outUniqueno;
            newItem.actualChildId = row.actualUniqueno;
            newItem.punchtype = punchtype;
            newItem.moduleName = "TimeSheet";
            saveActualDetail.push(newItem);
            this.updateActualDetails(saveActualDetail);
        }

    };

    updatePTOUTODetails(saveActualDetail: any[]) {
        this.setState({ loaderTrue: true });
        this.btnhHandleSave.current.disabled = true;
        laborPerformance
            .SaveLaborPTOUTO(saveActualDetail)
            .then(async (result: any | null) => {
                if (result != null) {
                    if (result.success) {
                        this.btnhHandleSave.current.disabled = false;
                        this.setState({ isDataChange: false });
                        toast.success("Time Off edited successfully.", {
                            // // position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        this.loadLaborTimesheetDetails();
                        this.loadTimesheetDateWise();
                    } else {
                        this.btnhHandleSave.current.disabled = false;
                        Utils.toastError(result.message, {
                            // // position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }
                } else {
                    this.btnhHandleSave.current.disabled = false;
                }

                this.setState({ loaderTrue: false });
                resolve();
            })
            .catch((error: any) => {
                this.btnhHandleSave.current.disabled = false;
                this.setState({ loaderTrue: false });
                reject();
            });
    }

    updateActualDetails(saveActualDetail: any[]) {
        this.setState({ loaderTrue: true });
        this.btnhHandleSave.current.disabled = true;
        laborPerformance
            .SaveLaborActual(saveActualDetail)
            .then(async (result: any | null) => {
                if (result != null) {
                    if (result.result.messageCode === "Success") {
                        this.btnhHandleSave.current.disabled = false;
                        this.setState({ isDataChange: false });
                        toast.success("Manual entry successfully edited.", {
                            // // position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        this.loadLaborTimesheetDetails();
                        this.loadTimesheetDateWise();
                    } else {
                        this.btnhHandleSave.current.disabled = false;
                        Utils.toastError(result.result.message, {
                            // // position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }
                } else {
                    this.btnhHandleSave.current.disabled = false;
                }

                this.setState({ loaderTrue: false });
                resolve();
            })
            .catch((error: any) => {
                this.btnhHandleSave.current.disabled = false;
                this.setState({ loaderTrue: false });
                reject();
            });
    }

    IsFormValid(newActualDetails: any[]) {
        let isValid = true;
        for (let x = 0; x < newActualDetails.length; x++) {
            if (newActualDetails[x].notes.trim().length === 0) {
                return false;
            } else {
                isValid = true;
            }
        }
        return isValid;
    }

    formatDate(time: string) {
        const dataArray = time.split(":");
        let hh = Number(dataArray[0]);
        let m = Number(dataArray[1]);
        let dd = "AM";
        let h = hh;
        if (h >= 12) {
            h = hh - 12;
            dd = "PM";
        }
        if (h === 0) {
            h = 12;
        }
        let min = m < 10 ? "0" + m : m;
        let replacement = h + ":" + min;
        replacement += " " + dd;
        return replacement;
    }

    loadLaborTimesheetDetails() {
        this.setState({ pageloader: true })
        LaborTimeSheet.getLaborTimesheetDetails(
            this.props.data.hotelID,
            this.props.data.date,
            this.props.data.userName,
            "TimeSheet"
        ).then(async (result: any | null) => {
            if (result != null) {
                if (result.data != null) {
                    this.setState({
                        total: result.total,
                        positionWiseList: result.positionWiseList,
                        positionList: result.total.positionList,
                    });

                    let filterData: any[] = [];
                    let utoData: any[] = [];
                    filterData = Object.assign(
                        [],
                        JSON.parse(JSON.stringify(result.data))
                    );
                    if (this.props.data.ruleType === "Standard Rule") {
                        filterData = filterData.filter(
                            (item) => item.title.toUpperCase() !== "DOT"
                        );
                    }
                    if (result.total.isHolidayshow === false) {
                        filterData = filterData.filter(
                            (item) => item.title.toUpperCase() !== "HOLIDAY"
                        );
                    }
                    utoData = filterData.filter((item) => item.title.toUpperCase() === "UTO");
                    if (utoData[0].total === "00:00") {
                        filterData = filterData.filter((item) => item.title.toUpperCase() !== "UTO");
                    }
                    this.setState({ data: [], allData: [] });
                    this.setState({ data: filterData, allData: filterData });
                } else {
                    this.setState({ total: { totlhrs: "0:00" } });
                }
                //this.setState({ pageloader: false })
            }
        });
    }

    loadTimesheetDateWise() {
        this.setState({ pageloader: true });
        LaborTimeSheet.dayWiseTimeSheetDetails(
            this.props.data.hotelID,
            this.props.data.date,
            this.props.data.userName,
            "TimeSheet"
        ).then(async (result: any | null) => {
            if (result != null) {
                // 
                this.setState({ dayDetails: [] });
                this.setState({
                    dayDetails: result,
                    forceapproval: result[0].timesheet[0].forceapproval,
                    managedispute: result[0].timesheet[0].managedispute,
                });
                if (this.state.isDeepLinking)
                    this.setState({
                        shiftID: this.getUrlParameterValue(window.location.href, "S"),
                    });
                // alert($("#4").html());
                $("#deepToTop").scrollTop(100);

                //$("#4").animate({ scrollTop: "100px" }, 1000);
                this.setState({ pageloader: false });
            }
        });
    }
    getUrlParameterValue = (fullURL, keyName) => {
        keyName = keyName.replace(/[\\[]/, "\\[").replace(/[\]]/, "\\]");
        let regex = new RegExp("[\\?&]" + keyName + "=([^&#]*)");
        let results = regex.exec(fullURL);
        return results === null
            ? ""
            : decodeURIComponent(results[1].replace(/\+/g, " "));
    };

    handleComparisonClick(index: any, filteredBy: any) {
        let condition = "";
        this.setState({ activeClass: index });
        let tableData: any[] = [];
        if (filteredBy === "All") {
            tableData = Object.assign(
                [],
                JSON.parse(JSON.stringify(this.state.allData))
            );
            condition = "All";
        } else if (filteredBy === "Adjustments") {
            condition = "Adjustments";
        } else if (filteredBy === "Dispute") {
            condition = "Dispute";
        } else {
            tableData = Object.assign(
                [],
                JSON.parse(JSON.stringify(this.state.positionWiseList))
            );
            tableData = tableData.filter((item) => item.position === filteredBy);
            condition = "All";
        }
        this.setState({ data: tableData, condition: condition });
    }

    headerFormatter = (column: any, colIndex: any) => {
        let header = this.state.data;
        if (header.length > 0) {
            let header1 = "";
            let header2 = "";
            switch (column.text) {
                case "total":
                    header1 = "Total";
                    header2 = "";
                    break;
                case "days1":
                    header1 = header[0].days1.dayname;
                    header2 = header[0].days1.date;
                    break;
                case "days2":
                    header1 = header[0].days2.dayname;
                    header2 = header[0].days2.date;
                    break;
                case "days3":
                    header1 = header[0].days3.dayname;
                    header2 = header[0].days3.date;
                    break;
                case "days4":
                    header1 = header[0].days4.dayname;
                    header2 = header[0].days4.date;
                    break;
                case "days5":
                    header1 = header[0].days5.dayname;
                    header2 = header[0].days5.date;
                    break;
                case "days6":
                    header1 = header[0].days6.dayname;
                    header2 = header[0].days6.date;
                    break;
                case "days7":
                    header1 = header[0].days7.dayname;
                    header2 = header[0].days7.date;
                    break;
            }
            return (
                <>
                    {column.text !== "" && (
                        <div className="column-header">
                            <div className="header-data">
                                <span className="header-day">{header1}</span>
                            </div>
                            <div className="header-date">{header2}</div>
                        </div>
                    )}
                    {column.text === "" && <div className="vertical-line-th"></div>}
                </>
            );
        }
    };

    FormatHoursMinutesDuration(HoursMints: number, totalBreak: number) {
        HoursMints = HoursMints - Number(totalBreak);
        if (HoursMints < 0) {
            HoursMints = HoursMints * -1;
            let numHours = Math.floor(Number(HoursMints) / 60);
            let numMinutes = Math.round(Number(HoursMints) % 60);
            return `${"-"}${numHours}:${numMinutes < 10 ? "0" : ""}${numMinutes}`;
        } else {
            let numHours = Math.floor(Number(HoursMints) / 60);
            let numMinutes = Math.round(Number(HoursMints) % 60);
            return `${numHours}:${numMinutes < 10 ? "0" : ""}${numMinutes}`;
        }
    }

    getlaborPositionDeptByemployee() {
        this.setState({ loaderTrue: true });

        let storage = JSON.parse(localStorage.getItem("storage")!);
        let userName = storage === null ? 0 : storage.userName as any;
        laborDeptPosition.getlaborPositionDeptByemployee(this.state.hotelID, userName)
            .then(async (result: any[] | null) => {
                if (result != null) {
                    this.setState({ alldepartmentlist: result });
                } else {
                    this.setState({ alldepartmentlist: [] });
                }
                this.setState({ loaderTrue: false });
                resolve();
            })
            .catch((error: any) => {
                this.setState({ loaderTrue: false });
                reject();
            });
    }

    departmentDropdownChange(event: any, item: any, index: any) {
        let data = Object.assign(
            {},
            JSON.parse(JSON.stringify(this.state.addEntry))
        );
        data.newDepartmentId = item.departmentID;
        data.newDepartment = item.departmentName;
        data.newPositionId = 0;
        data.newPosition = "Select";
        this.setState({ addEntry: data, allpositionlist: item.laborPositionlist });
        $("#ddladdentrydept" + index).click();
    }

    positionDropdownChange(event: any, item: any, index: any) {
        let data = Object.assign(
            {},
            JSON.parse(JSON.stringify(this.state.addEntry))
        );
        data.newPositionId = item.positionID;
        data.newPosition = item.positionName;
        this.setState({ addEntry: data });
        $("#ddladdentryposition" + index).click();
    }

    positionDropdownChangeBreak(event: any, item: any) {
        let data = Object.assign(
            {},
            JSON.parse(JSON.stringify(this.state.addEntry))
        );
        data.newPositionId = item.positionID;
        data.newPosition = item.positionName;
        this.setState({ addEntry: data });
        this.breakpositionRef.current.classList.remove("show");
    }

    addNewEntryNotesChange(e: any) {
        let data = Object.assign(
            {},
            JSON.parse(JSON.stringify(this.state.addEntry))
        );
        data.newNotes = e.target.value;
        this.setState({ addEntry: data });
    }

    newInTimeChange = (e: any) => {
        let data = Object.assign(
            {},
            JSON.parse(JSON.stringify(this.state.addEntry))
        );
        data.newInTime = e.target.value;
        this.setState({ addEntry: data });
    };

    newOutTimeChange = (e: any) => {
        let data = Object.assign(
            {},
            JSON.parse(JSON.stringify(this.state.addEntry))
        );
        data.newOutTime = e.target.value;
        this.setState({ addEntry: data });
    };

    saveNewEntry = (index: any) => {
        let dataArr: any[] = [];
        dataArr = Object.assign(
            [],
            JSON.parse(JSON.stringify(this.state.dayDetails[index].timesheet))
        );
        let data = Object.assign(
            {},
            JSON.parse(JSON.stringify(this.state.addEntry))
        );
        if (data.newDepartmentId === 0) {
            Utils.toastError("Please select department.", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
            });
            return false;
        }
        if (data.newPositionId === 0) {
            Utils.toastError("Please select position.", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
            });
            return false;
        }
        if (data.newNotes.trim().length === 0) {
            Utils.toastError("Please enter explanation.", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
            });
            return false;
        }
        if (data.newNotes.trim().length > 200) {
            Utils.toastError("Notes length can not exceed 200 characters.", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
            });
            return false;
        }

        let saveActualDetail: any[] = [];
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        let newItem: any = {};
        newItem.tenantID = tenantID;
        newItem.hotelID = this.state.hotelID;
        newItem.positionID = Number(data.newPositionId);
        newItem.selectDate = dataArr[0].date;
        newItem.employeeName = dataArr[0].employee;
        newItem.departmentID = Number(data.newDepartmentId);
        newItem.inTime = this.formatDate(data.newInTime);
        newItem.outTime = this.formatDate(data.newOutTime);
        newItem.actualId = 0;
        newItem.notes = data.newNotes;
        newItem.flag = dataArr[0].flag;
        newItem.scheduleId = 0;
        newItem.scheduleBreaktime = "";
        newItem.entryType = dataArr[0].entryType;
        newItem.inDate = dataArr[0].date;
        newItem.outDate = dataArr[0].date;
        newItem.inUniqueNo = 0;
        newItem.outUniqueNo = 0;
        newItem.actualChildId = 0;
        newItem.punchtype = "";
        newItem.moduleName = "TimeSheet";
        saveActualDetail.push(newItem);
        let laborActualmain: any = {};
        laborActualmain.laborActualDetails = saveActualDetail;
        this.saveActualDetails(saveActualDetail);
    };

    saveActualDetails(saveActualDetail: any[]) {
        this.setState({ loaderTrue: true });
        this.btnSaveNewEntry.current.disabled = true;
        laborPerformance
            .SaveLaborActual(saveActualDetail)
            .then(async (result: any | null) => {
                if (result != null) {
                    if (result.result.messageCode === "Success") {
                        this.btnSaveNewEntry.current.disabled = false;
                        this.setState({ isDataChange: false });
                        toast.success("Manual entry successfully added.", {
                            // // position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        this.loadLaborTimesheetDetails();
                        this.loadTimesheetDateWise();
                    } else {
                        this.btnSaveNewEntry.current.disabled = false;
                        Utils.toastError(result.result.message, {
                            // // position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }
                } else {
                    this.btnSaveNewEntry.current.disabled = false;
                }

                this.setState({ loaderTrue: false });
                resolve();
            })
            .catch((error: any) => {
                this.btnSaveNewEntry.current.disabled = false;
                this.setState({ loaderTrue: false });
                reject();
            });
    }

    ///// Add Break
    breakHHChange(e: any) {
        if (Number(e.target.value) < 60) {
            let data = Object.assign(
                {},
                JSON.parse(JSON.stringify(this.state.addEntry))
            );
            data.breakHH = e.target.value;
            this.setState({ addEntry: data });
        }
    }

    breakMMChange(e: any) {
        if (Number(e.target.value) < 60) {
            let data = Object.assign(
                {},
                JSON.parse(JSON.stringify(this.state.addEntry))
            );
            data.breakMM = e.target.value;
            this.setState({ addEntry: data });
        }
    }

    saveBreak = (index: any) => {
        let departmentpositionList: any[] = [];
        let dataArr: any[] = [];
        dataArr = Object.assign([], JSON.parse(JSON.stringify(this.state.dayDetails[index].timesheet.filter(a => a.entryType !== "TimeOff"))));
        let data = Object.assign({}, JSON.parse(JSON.stringify(this.state.addEntry))
        );

        if (data.breakHH !== "" && data.breakMM !== "") {
            if (Number(data.breakHH) <= 0 && Number(data.breakMM) <= 0) {
                Utils.toastError("Please enter Break duration in HH:MM format", {
                    // // position: toast.POSITION.BOTTOM_RIGHT,
                });
                return false;
            }
            if (data.breakMM.length !== 2) {
                Utils.toastError("Please enter Break duration in HH:MM format", {
                    // // position: toast.POSITION.BOTTOM_RIGHT,
                });
                return false;
            }
            if (Number(data.breakMM) > 60) {
                Utils.toastError("Minutes should be less than 60", {
                    // // position: toast.POSITION.BOTTOM_RIGHT,
                });
                return false;
            }
        } else {
            Utils.toastError("Please enter Break duration in HH:MM format", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
            });
            return false;
        }

        if (data.newPositionId === 0) {
            Utils.toastError("Please select position", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
            });
            return false;
        }
        if (data.newNotes.trim().length === 0) {
            Utils.toastError("Please enter Break details.", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
            });
            return false;
        }
        if (data.newNotes.trim().length > 200) {
            Utils.toastError("Break details length can not exceed 200 characters", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
            });
            return false;
        }

        let totalbreakDetail = dataArr.filter(
            (element) => element.positionID === data.newPositionId
        );
        let total = 0;
        // eslint-disable-next-line array-callback-return
        totalbreakDetail.map((arr: any) => {
            if (arr.total.length > 0) {
                let subTotal = 0;
                if (arr.total.includes(":")) {
                    const dataArray = arr.total.split(":");
                    let HH = Number(dataArray[0]);
                    let MM = Number(dataArray[1]);
                    if (dataArray[0].includes("-") || dataArray[1].includes("-")) {
                        HH = HH < 0 ? HH * -1 : HH;
                        MM = MM < 0 ? MM * -1 : MM;
                        subTotal = (HH * 60 + MM) * -1;
                    } else {
                        subTotal = HH * 60 + MM;
                    }
                    total = total + subTotal;
                }
            }
        });
        if (Number(total) <= Number(data.breakHH * 60) + Number(data.breakMM)) {
            Utils.toastError("Break duration should be less than Actual hours.", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
            });
            return false;
        }

        let breakTime = data.breakHH + "." + data.breakMM;
        departmentpositionList = Object.assign(
            [],
            JSON.parse(JSON.stringify(this.state.alldepartmentlist))
        );
        let deptID = departmentpositionList.filter((element) =>
            element.laborPositionlist.some(
                (subElement: { positionID: any }) =>
                    subElement.positionID === data.newPositionId
            )
        )[0].departmentID;
        let saveActualDetail: any[] = [];
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        let newItem: any = {};
        newItem.tenantID = tenantID;
        newItem.hotelID = this.state.hotelID;
        newItem.positionID = Number(data.newPositionId);
        newItem.selectDate = dataArr[0].date;
        newItem.employeeName = dataArr[0].employee;
        newItem.departmentID = Number(deptID);
        newItem.inTime = "";
        newItem.outTime = "";
        newItem.actualId = dataArr.filter(
            (element) => element.departmentID === deptID
        )[0].actualUniqueno;
        newItem.notes = data.newNotes;
        newItem.flag = "";
        newItem.scheduleId = dataArr.filter(
            (element) => element.departmentID === deptID
        )[0].scheduleNo;
        newItem.scheduleBreaktime = breakTime;
        newItem.entryType = "Break";
        newItem.inDate = "";
        newItem.outDate = "";
        newItem.inUniqueNo = 0;
        newItem.outUniqueNo = 0;
        newItem.actualChildId = 0;
        newItem.punchtype = "";
        newItem.moduleName = "TimeSheet";
        saveActualDetail.push(newItem);
        let laborActualmain: any = {};
        laborActualmain.laborActualDetails = saveActualDetail;
        this.saveBreakDetails(saveActualDetail);
    };

    saveBreakDetails(saveActualDetail: any[]) {
        this.setState({ loaderTrue: true });
        this.btnSaveBreak.current.disabled = true;
        laborPerformance
            .SaveLaborActual(saveActualDetail)
            .then(async (result: any | null) => {
                this.btnSaveBreak.current.disabled = false;
                if (result != null) {
                    if (result.result.messageCode === "Success") {
                        this.setState({ isDataChange: false });
                        toast.success("Manual Break successfully added.", {
                            // // position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        this.loadLaborTimesheetDetails();
                        this.loadTimesheetDateWise();
                    } else {
                        Utils.toastError(result.result.message, {
                            // // position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }
                }

                this.setState({ loaderTrue: false });
                resolve();
            })
            .catch((error: any) => {
                this.btnSaveBreak.current.disabled = false;
                this.setState({ loaderTrue: false });
                reject();
            });
    }

    changebreakHH = (e: any, row: any, rowIndex: any) => {
        if (Number(e.target.value) < 60) {
            let data = Object.assign(
                [],
                JSON.parse(JSON.stringify(this.state.dayDetails))
            );
            data[row.groupID - 1].timesheet[rowIndex].breakHH = e.target.value;
            this.setState({ dayDetails: data });
        }
    };

    changebreakMM = (e: any, row: any, rowIndex: any) => {
        if (Number(e.target.value) < 60) {
            let data = Object.assign(
                [],
                JSON.parse(JSON.stringify(this.state.dayDetails))
            );
            data[row.groupID - 1].timesheet[rowIndex].breakMM = e.target.value;
            this.setState({ dayDetails: data });
        }
    };

    updateBreak = (e: any, row: any, rowIndex: any) => {
        if (row.breakHH !== "" && row.breakMM !== "") {
            if (Number(row.breakHH) <= 0 && Number(row.breakMM) <= 0) {
                Utils.toastError("Please enter Break duration in HH:MM format", {
                    // // position: toast.POSITION.BOTTOM_RIGHT,
                });
                return false;
            }
            if (row.breakMM.length !== 2) {
                Utils.toastError("Please enter Break duration in HH:MM format", {
                    // // position: toast.POSITION.BOTTOM_RIGHT,
                });
                return false;
            }
            if (Number(row.breakMM) > 60) {
                Utils.toastError("Minutes should be less than 60", {
                    // // position: toast.POSITION.BOTTOM_RIGHT,
                });
                return false;
            }
        } else {
            Utils.toastError("Please enter Break duration in HH:MM format", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
            });
            return false;
        }

        if (row.lastOutNotesdup.trim().length === 0) {
            Utils.toastError("Please enter Break details.", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
            });
            return false;
        }
        if (row.lastOutNotesdup.trim().length > 200) {
            Utils.toastError("Break details length can not exceed 200 characters", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
            });
            return false;
        }
        let dataArr = Object.assign(
            [],
            JSON.parse(
                JSON.stringify(this.state.dayDetails[row.groupID - 1].timesheet)
            )
        );
        let totalbreakDetail = dataArr.filter(
            (element: { positionID: any }) => element.positionID === row.positionID
        );
        let total = 0;
        // eslint-disable-next-line array-callback-return
        totalbreakDetail.map((arr: any) => {
            if (arr.total.length > 0) {
                let subTotal = 0;
                if (arr.total.includes(":")) {
                    const dataArray = arr.total.split(":");
                    let HH = Number(dataArray[0]);
                    let MM = Number(dataArray[1]);
                    if (dataArray[0].includes("-") || dataArray[1].includes("-")) {
                        HH = HH < 0 ? HH * -1 : HH;
                        MM = MM < 0 ? MM * -1 : MM;
                        subTotal = (HH * 60 + MM) * -1;
                    } else {
                        subTotal = HH * 60 + MM;
                    }
                    total = total + subTotal;
                }
            }
        });
        if (Number(total) <= Number(row.breakHH * 60) + Number(row.breakMM)) {
            Utils.toastError("Break duration should be less than Actual hours.", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
            });
            return false;
        }

        let breakTime = row.breakHH + "." + row.breakMM;
        let saveActualDetail: any[] = [];
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        let newItem: any = {};
        newItem.tenantID = tenantID;
        newItem.hotelID = this.state.hotelID;
        newItem.positionID = Number(row.positionID);
        newItem.selectDate = row.date;
        newItem.employeeName = row.employee;
        newItem.departmentID = Number(row.departmentID);
        newItem.inTime = "";
        newItem.outTime = "";
        newItem.actualId = row.actualUniqueno;
        newItem.notes = row.lastOutNotesdup;
        newItem.flag = row.flag;
        newItem.scheduleId = row.scheduleNo;
        newItem.scheduleBreaktime = breakTime;
        newItem.entryType = "Break";
        newItem.inDate = "";
        newItem.outDate = "";
        newItem.inUniqueNo = 0;
        newItem.outUniqueNo = 0;
        newItem.actualChildId = row.actualUniqueno;
        newItem.punchtype = "";
        newItem.moduleName = "TimeSheet";
        saveActualDetail.push(newItem);
        let laborActualmain: any = {};
        laborActualmain.laborActualDetails = saveActualDetail;
        this.updateBreakDetails(saveActualDetail);
    };

    updateBreakDetails(saveActualDetail: any[]) {
        this.setState({ loaderTrue: true });
        this.btnUpdateBreak.current.disabled = true;
        laborPerformance
            .SaveLaborActual(saveActualDetail)
            .then(async (result: any | null) => {
                this.btnUpdateBreak.current.disabled = false;
                if (result != null) {
                    if (result.result.messageCode === "Success") {
                        this.setState({ isDataChange: false });
                        toast.success("Manual Break successfully edited.", {
                            // // position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        this.loadLaborTimesheetDetails();
                        this.loadTimesheetDateWise();
                    } else {
                        Utils.toastError(result.result.message, {
                            // // position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }
                }

                this.setState({ loaderTrue: false });
                resolve();
            })
            .catch((error: any) => {
                this.btnUpdateBreak.current.disabled = false;
                this.setState({ loaderTrue: false });
                reject();
            });
    }

    actionButton = (row: any, rowIndex: number, mode: any) => {
        let data = Object.assign([], JSON.parse(JSON.stringify(this.state.dayDetails)));
        data[row.groupID - 1].timesheet[rowIndex].actions = mode;
        data[row.groupID - 1].timesheet[rowIndex].splitDepartment = "Select";
        data[row.groupID - 1].timesheet[rowIndex].splitDepartmentId = 0;
        data[row.groupID - 1].timesheet[rowIndex].splitNotes = "";
        data[row.groupID - 1].timesheet[rowIndex].splitPosition = "Select";
        data[row.groupID - 1].timesheet[rowIndex].splitPositionId = 0;
        data[row.groupID - 1].timesheet[rowIndex].splitTime = "00:00";
        this.setState({ dayDetails: data });
    };

    //---------------------- Split Entry--------------------------//
    splitTimeChange = (e: any, row: any, rowIndex: any) => {
        let data = Object.assign(
            [],
            JSON.parse(JSON.stringify(this.state.dayDetails))
        );
        data[row.groupID - 1].timesheet[rowIndex].splitTime = e.target.value;
        this.setState({ dayDetails: data });
    };

    departmentSplitChange(event: any, row: any, item: any, rowIndex: any) {
        let data = Object.assign(
            [],
            JSON.parse(JSON.stringify(this.state.dayDetails))
        );
        data[row.groupID - 1].timesheet[rowIndex].splitDepartmentId =
            item.departmentID;
        data[row.groupID - 1].timesheet[rowIndex].splitDepartment =
            item.departmentName;
        data[row.groupID - 1].timesheet[rowIndex].splitPosition = "Select";
        data[row.groupID - 1].timesheet[rowIndex].splitPositionId = 0;
        this.setState({
            dayDetails: data,
            allpositionlist: item.laborPositionlist,
        });
    }

    positionSplitChange(event: any, row: any, item: any, rowIndex: any) {
        let data = Object.assign(
            [],
            JSON.parse(JSON.stringify(this.state.dayDetails))
        );
        data[row.groupID - 1].timesheet[rowIndex].splitPosition = item.positionName;
        data[row.groupID - 1].timesheet[rowIndex].splitPositionId = item.positionID;
        this.setState({ dayDetails: data });
    }

    noteSplitChange = (e: any, row: any, rowIndex: any) => {
        let data = Object.assign(
            [],
            JSON.parse(JSON.stringify(this.state.dayDetails))
        );
        data[row.groupID - 1].timesheet[rowIndex].splitNotes = e.target.value;
        this.setState({ dayDetails: data });
    };

    hoursMinutesToMinutes(data: any) {
        let total = 0;
        if (data.trim().length > 0) {
            if (data.includes(":")) {
                const dataArray = data.split(":");
                let HH = Number(dataArray[0]);
                let MM = Number(dataArray[1]);
                if (dataArray[0].includes("-") || dataArray[1].includes("-")) {
                    HH = HH < 0 ? HH * -1 : HH;
                    MM = MM < 0 ? MM * -1 : MM;
                    total = (HH * 60 + MM) * -1;
                } else {
                    total = HH * 60 + MM;
                }
            } else {
                total = 0;
            }
        } else {
            total = 0;
        }
        return total;
    }

    isSplitTimeValid(inTime: any, splitTime: any, outTime: any) {
        let isNextDayShift = false;
        isNextDayShift =
            this.hoursMinutesToMinutes(inTime) > this.hoursMinutesToMinutes(outTime)
                ? true
                : false;
        if (isNextDayShift) {
            if (
                this.hoursMinutesToMinutes(inTime) <
                this.hoursMinutesToMinutes(splitTime) &&
                this.hoursMinutesToMinutes(splitTime) <=
                this.hoursMinutesToMinutes("24:00")
            ) {
                return true;
            } else if (
                this.hoursMinutesToMinutes("00:00") <=
                this.hoursMinutesToMinutes(splitTime) &&
                this.hoursMinutesToMinutes(splitTime) <
                this.hoursMinutesToMinutes(outTime)
            ) {
                return true;
            } else {
                return false;
            }
        } else {
            if (
                this.hoursMinutesToMinutes(inTime) <
                this.hoursMinutesToMinutes(splitTime) &&
                this.hoursMinutesToMinutes(splitTime) <
                this.hoursMinutesToMinutes(outTime)
            ) {
                return true;
            } else {
                return false;
            }
        }
    }

    saveSplitEntry = (row: any, index: any) => {
        if (row.splitTime.length === 0) {
            Utils.toastError("Please enter Split Time.", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
            });
            return false;
        }
        if (row.splitDepartmentId === 0) {
            Utils.toastError("Please select department.", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
            });
            return false;
        }
        if (row.splitPositionId === 0) {
            Utils.toastError("Please select position.", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
            });
            return false;
        }
        if (row.splitNotes.trim().length === 0) {
            Utils.toastError("Please enter notes.", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
            });
            return false;
        }
        if (row.splitNotes.trim().length > 200) {
            Utils.toastError("Notes length can not exceed 200 characters.", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
            });
            return false;
        }

        let dataArr: any[] = [];
        dataArr = Object.assign([], JSON.parse(JSON.stringify(this.state.dayDetails[index].timesheet.filter(a => a.entryType !== "TimeOff"))));

        let saveActualDetail: any[] = [];
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        for (let i = 0; i < dataArr.length; i++) {
            if (row.actualUniqueno === dataArr[i].actualUniqueno) {
                if (
                    this.isSplitTimeValid(
                        dataArr[i].inTime,
                        dataArr[i].splitTime,
                        dataArr[i].outTime
                    )
                ) {
                    //-Existing Split Row
                    let newItem: any = {};
                    newItem.actualId = dataArr[i].actualUniqueno;
                    newItem.actualChildId = dataArr[i].actualUniqueno;
                    newItem.tenantID = tenantID;
                    newItem.hotelID = this.state.hotelID;
                    newItem.positionID = Number(dataArr[i].positionID);
                    newItem.selectDate = dataArr[i].date;
                    newItem.employeeName = dataArr[i].employee;
                    newItem.departmentID = Number(dataArr[i].departmentID);
                    newItem.inTime = this.formatDate(dataArr[i].inTime);
                    newItem.outTime = this.formatDate(dataArr[i].splitTime);
                    newItem.notes = dataArr[i].notes;
                    newItem.flag = dataArr[i].flag;
                    newItem.scheduleId = dataArr[i].scheduleNo;
                    newItem.scheduleBreaktime = "";
                    newItem.entryType = "split"; //dataArr[i].entryType;
                    newItem.inDate = dataArr[i].inDate;
                    newItem.outDate = dataArr[i].outDate;
                    newItem.inUniqueNo = dataArr[i].inUniqueno;
                    newItem.outUniqueNo = 0;
                    newItem.punchtype = "out";
                    newItem.moduleName = "TimeSheet";
                    saveActualDetail.push(newItem);

                    //-New Split Row
                    let newSplitItem: any = {};
                    newSplitItem.actualId = 0;
                    newSplitItem.actualChildId = 0;
                    newSplitItem.tenantID = tenantID;
                    newSplitItem.hotelID = this.state.hotelID;
                    newSplitItem.positionID = Number(dataArr[i].splitPositionId);
                    newSplitItem.selectDate = dataArr[i].date;
                    newSplitItem.employeeName = dataArr[i].employee;
                    newSplitItem.departmentID = Number(dataArr[i].splitDepartmentId);
                    newSplitItem.inTime = this.formatDate(dataArr[i].splitTime);
                    newSplitItem.outTime = this.formatDate(dataArr[i].outTime);
                    newSplitItem.notes = dataArr[i].splitNotes;
                    newSplitItem.flag = dataArr[i].flag;
                    newSplitItem.scheduleId = 0;
                    newSplitItem.scheduleBreaktime = "";
                    newSplitItem.entryType = "split";
                    newSplitItem.inDate = dataArr[i].inDate;
                    newSplitItem.outDate = dataArr[i].outDate;
                    newSplitItem.inUniqueNo = 0;
                    newSplitItem.outUniqueNo = dataArr[i].outUniqueno;
                    newSplitItem.punchtype = "";
                    newSplitItem.moduleName = "TimeSheet";
                    saveActualDetail.push(newSplitItem);
                } else {
                    Utils.toastError(
                        "Please enter split time between Punch In and Punch Out time.",
                        {
                            // // position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
                    return false;
                }
            } else {
                //-Existing Row in same position
                if (row.positionID === dataArr[i].positionID) {
                    let newItem: any = {};
                    newItem.actualId = dataArr[i].actualUniqueno;
                    newItem.actualChildId = dataArr[i].actualUniqueno;
                    newItem.tenantID = tenantID;
                    newItem.hotelID = this.state.hotelID;
                    newItem.positionID = Number(dataArr[i].positionID);
                    newItem.selectDate = dataArr[i].date;
                    newItem.employeeName = dataArr[i].employee;
                    newItem.departmentID = Number(dataArr[i].departmentID);
                    newItem.inTime = this.formatDate(dataArr[i].inTime);
                    newItem.outTime = this.formatDate(dataArr[i].outTime);
                    newItem.notes = dataArr[i].notes;
                    newItem.flag = dataArr[i].flag;
                    newItem.scheduleId = dataArr[i].scheduleNo;
                    newItem.scheduleBreaktime = dataArr[i].breakTime
                        .toString()
                        .replace(":", ".");
                    newItem.entryType = dataArr[i].entryType;
                    newItem.inDate = dataArr[i].inDate;
                    newItem.outDate = dataArr[i].outDate;
                    newItem.inUniqueNo = dataArr[i].inUniqueno;
                    newItem.outUniqueNo = dataArr[i].outUniqueno;
                    newItem.punchtype = dataArr[i].punchtype;
                    newItem.moduleName = "TimeSheet";
                    saveActualDetail.push(newItem);
                }
            }
        }
        this.splitEntaryDetails(saveActualDetail);
    };

    splitEntaryDetails(saveActualDetail: any[]) {
        this.setState({ loaderTrue: true });
        this.btnSplitShift.current.disabled = true;
        laborPerformance
            .SaveLaborActual(saveActualDetail)
            .then(async (result: any | null) => {
                this.btnSplitShift.current.disabled = false;
                if (result != null) {
                    if (result.result.messageCode === "Success") {
                        this.setState({ isDataChange: false });
                        toast.success("Shift successfully split.", {
                            // // position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        this.loadLaborTimesheetDetails();
                        this.loadTimesheetDateWise();
                    } else {
                        Utils.toastError(result.result.message, {
                            // // position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }
                }

                this.setState({ loaderTrue: false });
                resolve();
            })
            .catch((error: any) => {
                this.btnSplitShift.current.disabled = false;
                this.setState({ loaderTrue: false });
                reject();
            });
    }

    saveChangePosition = (row: any, index: any) => {
        if (row.splitDepartmentId === 0) {
            Utils.toastError("Please select department.", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
            });
            return false;
        }
        if (row.splitPositionId === 0) {
            Utils.toastError("Please select position.", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
            });
            return false;
        }
        if (row.splitNotes.trim().length === 0) {
            Utils.toastError("Please enter notes.", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
            });
            return false;
        }
        if (row.splitNotes.trim().length > 200) {
            Utils.toastError("Notes length can not exceed 200 characters.", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
            });
            return false;
        }
        let dataArr: any[] = [];
        dataArr = Object.assign(
            [],
            JSON.parse(JSON.stringify(this.state.dayDetails[index].timesheet))
        );
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        let saveActualDetail: any[] = [];
        for (let i = 0; i < dataArr.length; i++) {
            if (row.actualUniqueno === dataArr[i].actualUniqueno) {
                let newItem: any = {};
                newItem.actualId = row.actualUniqueno;
                newItem.actualChildId = row.actualUniqueno;
                newItem.tenantID = tenantID;
                newItem.hotelID = this.state.hotelID;
                newItem.positionID = Number(row.splitPositionId);
                newItem.selectDate = row.date;
                newItem.employeeName = row.employee;
                newItem.departmentID = Number(row.splitDepartmentId);
                newItem.inTime = this.formatDate(row.inTime);
                newItem.outTime = this.formatDate(row.outTime);
                newItem.notes = row.splitNotes;
                newItem.flag = row.flag;
                newItem.scheduleId = row.scheduleNo;
                newItem.scheduleBreaktime = "";
                newItem.entryType = "changeposition";
                newItem.inDate = row.inDate;
                newItem.outDate = row.outDate;
                newItem.inUniqueNo = row.inUniqueNo;
                newItem.outUniqueNo = row.outUniqueNo;
                newItem.punchtype = row.punchtype;
                newItem.moduleName = "TimeSheet";
                saveActualDetail.push(newItem);
            } else {
                if (row.positionID === dataArr[i].positionID) {
                    let newItem: any = {};
                    newItem.actualId = dataArr[i].actualUniqueno;
                    newItem.actualChildId = dataArr[i].actualUniqueno;
                    newItem.tenantID = tenantID;
                    newItem.hotelID = this.state.hotelID;
                    newItem.positionID = Number(dataArr[i].positionID);
                    newItem.selectDate = dataArr[i].date;
                    newItem.employeeName = dataArr[i].employee;
                    newItem.departmentID = Number(dataArr[i].departmentID);
                    newItem.inTime = this.formatDate(dataArr[i].inTime);
                    newItem.outTime = this.formatDate(dataArr[i].outTime);
                    newItem.notes = dataArr[i].notes;
                    newItem.flag = dataArr[i].flag;
                    newItem.scheduleId = dataArr[i].scheduleNo;
                    newItem.scheduleBreaktime = dataArr[i].breakTime
                        .toString()
                        .replace(":", ".");
                    newItem.entryType = dataArr[i].entryType;
                    newItem.inDate = dataArr[i].inDate;
                    newItem.outDate = dataArr[i].outDate;
                    newItem.inUniqueNo = dataArr[i].inUniqueno;
                    newItem.outUniqueNo = dataArr[i].outUniqueno;
                    newItem.punchtype = dataArr[i].punchtype;
                    newItem.moduleName = "TimeSheet";
                    saveActualDetail.push(newItem);
                }
            }
        }
        this.saveChangePositionDetails(saveActualDetail);
    };

    saveChangePositionDetails(saveActualDetail: any[]) {
        this.setState({ loaderTrue: true });
        this.btnChangeShift.current.disabled = true;
        laborPerformance
            .ShiftPositionMove(saveActualDetail)
            .then(async (result: any | null) => {
                this.btnChangeShift.current.disabled = false;
                if (result != null) {
                    if (result.result.messageCode === "Success") {
                        this.setState({ isDataChange: false });
                        toast.success("Shift successfully reassigned.", {
                            // // position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        this.loadLaborTimesheetDetails();
                        this.loadTimesheetDateWise();
                    } else {
                        Utils.toastError(result.result.message, {
                            // // position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }
                }

                this.setState({ loaderTrue: false });
                resolve();
            })
            .catch((error: any) => {
                this.btnChangeShift.current.disabled = false;
                this.setState({ loaderTrue: false });
                reject();
            });
    }

    handleOutsideClick() {
        let dayDetails: any[] = [];
        dayDetails = Object.assign(
            [],
            JSON.parse(JSON.stringify(this.state.dayDetails))
        );
        for (let i = 0; i < dayDetails.length; i++) {
            for (let j = 0; j < dayDetails[i].timesheet.length; j++) {
                dayDetails[i].timesheet[j].actions = "";
            }
        }
        this.setState({ dayDetails: dayDetails });
    }

    dotsSVG = () => {
        return (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
            </svg>
        );
    };

    forseApprovalValidate = (value) => {
        if (value === "") {
            this.setState({ forseApprovalError: "Please provide an explanation." });
        } else if (value.length > 500) {
            this.setState({ forseApprovalError: "Maximum 500 characters allowed." });
        } else {
            return value;
        }
    };

    forseApprovalClose = () => {
        this.setState({
            forseApprovalModal: false,
            forseApprovalValue: "",
            forseApprovalError: "",
        });
    };
    forseApprovalShow = () => {
        this.setState({
            forseApprovalModal: true,
            forseApprovalValue: "",
            forseApprovalError: "",
        });
    };

    forseApprovalChange = (event: any) => {
        let { value } = event.target;
        this.setState({ forseApprovalValue: value.trimStart() });
    };
    forseApprovalSubmit = (event: any) => {
        //  
        let { forseApprovalValue } = this.state;
        event.preventDefault();
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let request = {} as any;
        request.HotelID = this.props.data.hotelID;
        request.StartDate = this.props.data.fullDate[0];
        request.EndDate = this.props.data.fullDate[1];
        request.UserName = this.props.data.userName;
        request.ApprovalComment = forseApprovalValue;
        request.ModifyedBy = storage.userName;
        if (this.forseApprovalValidate(forseApprovalValue)) {
            this.setState({ forseApprovalError: "" });
            laborPerformance
                .saveForceApproval(request)
                .then(async (result: any | null) => {
                    // console.log(result);
                    if (result.success) {
                        this.setState({ forseApprovalModal: false });
                        this.props.hideSlideOut();
                        this.setState({
                            forseApprovalModal: true,
                            forseApprovalValue: "",
                            forseApprovalError: "",
                        });
                    }
                });
        }
    };

    formatColumn(cell: any, row: any, rowIndex: any) {
        let confirmModalContent = [
            {
                title: "Delete Time Off",
                desc: "Are you sure you want to delete Time Off ?",
                cancleAction: "Cancel",
                ActionTrue: "Delete Time Off",
            },
        ];

        return (
            <OutsideClickHandler
                onOutsideClick={this.handleOutsideClick}
            >
                <div className="delete-Confirm">
                    <ConfirmationModal
                        confirmModalContent={confirmModalContent}
                        ishandleDeletePosition={this.ishandleDeletePosition.bind(
                            this,
                            row
                        )}
                    />
                </div></OutsideClickHandler>
        );

    }

    ishandleDeletePosition(row: any, mode: any) {
        if (mode) {
            this.handleDeletePosition(row);
        } else {
            this.handleOutsideClick();
        }
    }

    handleDeletePosition = (row: any) => {
        this.setState({ loaderTrue: true });
        let deleteRequest: any = {};
        deleteRequest.scheduleID = row.scheduleNo;
        deleteRequest.hotelID = this.state.hotelID;
        deleteRequest.date = row.date;
        deleteRequest.username = this.state.userName;

        laborPerformance.DeleteSchedulePtoUto(deleteRequest)
            .then(async (result: any | null) => {
                if (result.success) {
                    toast.success("Time Off deleted successfully", {
                        // // position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    this.loadLaborTimesheetDetails();
                    this.loadTimesheetDateWise();
                } else {
                    Utils.toastError(result.message, {
                        // // position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
                this.setState({ loaderTrue: false });
                resolve();
            })
            .catch((error) => {
                reject();
                this.setState({ loaderTrue: false });
            });
    };

    openOverView() {
        this.setState({ isRedirect: true });
        var btnlabor = $('[id*=defaultSet-Labor]');
        var btnHome = $('[id*=Payroll]');
        btnlabor[0].className = "nav-link active";
        btnHome[0].className = "d-flex align-items-center transition nav-link active";
        localStorage.setItem("mainMenu", "Labor");
        localStorage.setItem("subMenu", "Payroll");
    }

    render() {

        if (this.state.isRedirect) {
            return <Redirect to={{
                pathname: "/labor-management/payroll-export",
                state: {
                    payPeriod: this.state.payPeriodDates,
                },
            }} />;
        }
        let calanderDate = new Date(this.props.data.fullDate[1]);
        let lToday = new Date();
        if (JSON.parse(localStorage.storage).currentUtcTime !== "" && JSON.parse(localStorage.storage).currentUtcTime !== null && JSON.parse(localStorage.storage).currentUtcTime !== undefined) {
            lToday = new Date(
              JSON.parse(localStorage.storage).currentUtcTime
            );
          }
        let weektoDate = calanderDate.setHours(0, 0, 0, 0);
        let currentDate = lToday.setHours(0, 0, 0, 0);
        let isActualRow = false;
        let isBreak = false;
        let isActual = false;
        const isActualExist = (index: any) => {
            let dataArr: any[] = [];
            dataArr = Object.assign(
                [],
                JSON.parse(JSON.stringify(this.state.dayDetails[index].timesheet.filter(a => a.entryType !== "TimeOff")))
            );
            if (dataArr.length > 0) {
                for (let x = 0; x < dataArr.length; x++) {
                    isBreak =
                        dataArr.filter(
                            (item) =>
                                item.entryType === "Break" &&
                                item.positionID === dataArr[x].positionID
                        ).length > 0
                            ? true
                            : false;
                    isActual =
                        dataArr.filter((item) => item.entryType === "Actual").length > 0
                            ? true
                            : false;
                    if (!isBreak) {
                        break;
                    }
                }
            }

            if (dataArr.length > 0 && isActual === true && isBreak === false) {
                isActualRow = true;
            } else if (dataArr.length > 0 && isBreak === true) {
                isActualRow = false;
            } else {
                isActualRow = false;
            }
        };

        const isTabShow = (row: any) => {
            return row.entryType.toLowerCase() === "timeoff" ? row.managetimeoff.toString().toLowerCase() === "yes" ? true : false :
                row.isedit.toString().toLowerCase() === "yes" ? true : false;
        }

        const columns = [
            {
                dataField: "title",
                text: "",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="d-flex flex-wrap">
                            <div className="main-ttl">{row.title}</div>
                        </div>
                    );
                },
            },
            {
                dataField: "",
                text: "total",
                headerFormatter: this.headerFormatter,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="d-flex flex-wrap">
                            {row.total !== "00:00" && (
                                <div className="main-ttl">{row.total}</div>
                            )}
                            {row.total === "00:00" && <div className="main-ttl">{"-"}</div>}
                        </div>
                    );
                },
            },
            {
                dataField: "",
                text: "",
                headerFormatter: this.headerFormatter,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return <div className="vertical-line-td"></div>;
                },
            },
            {
                dataField: "",
                text: "days1",
                headerFormatter: this.headerFormatter,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="d-flex flex-wrap">
                            {row.days1.value !== "0:00" && (
                                <div className="main-ttl">{row.days1.value}</div>
                            )}
                            {row.days1.value === "0:00" && (
                                <div className="main-ttl">{"-"}</div>
                            )}
                        </div>
                    );
                },
            },
            {
                dataField: "",
                text: "days2",
                headerFormatter: this.headerFormatter,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="d-flex flex-wrap">
                            {row.days2.value !== "0:00" && (
                                <div className="main-ttl">{row.days2.value}</div>
                            )}
                            {row.days2.value === "0:00" && (
                                <div className="main-ttl">{"-"}</div>
                            )}
                        </div>
                    );
                },
            },
            {
                dataField: "",
                text: "days3",
                headerFormatter: this.headerFormatter,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="d-flex flex-wrap">
                            {row.days3.value !== "0:00" && (
                                <div className="main-ttl">{row.days3.value}</div>
                            )}
                            {row.days3.value === "0:00" && (
                                <div className="main-ttl">{"-"}</div>
                            )}
                        </div>
                    );
                },
            },
            {
                dataField: "",
                text: "days4",
                headerFormatter: this.headerFormatter,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="d-flex flex-wrap">
                            {row.days4.value !== "0:00" && (
                                <div className="main-ttl">{row.days4.value}</div>
                            )}
                            {row.days4.value === "0:00" && (
                                <div className="main-ttl">{"-"}</div>
                            )}
                        </div>
                    );
                },
            },
            {
                dataField: "",
                text: "days5",
                headerFormatter: this.headerFormatter,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="d-flex flex-wrap">
                            {row.days5.value !== "0:00" && (
                                <div className="main-ttl">{row.days5.value}</div>
                            )}
                            {row.days5.value === "0:00" && (
                                <div className="main-ttl">{"-"}</div>
                            )}
                        </div>
                    );
                },
            },
            {
                dataField: "",
                text: "days6",
                headerFormatter: this.headerFormatter,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="d-flex flex-wrap">
                            {row.days6.value !== "0:00" && (
                                <div className="main-ttl">{row.days6.value}</div>
                            )}
                            {row.days6.value === "0:00" && (
                                <div className="main-ttl">{"-"}</div>
                            )}
                        </div>
                    );
                },
            },
            {
                dataField: "",
                text: "days7",
                headerFormatter: this.headerFormatter,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="d-flex flex-wrap">
                            {row.days7.value !== "0:00" && (
                                <div className="main-ttl">{row.days7.value}</div>
                            )}
                            {row.days7.value === "0:00" && (
                                <div className="main-ttl">{"-"}</div>
                            )}
                        </div>
                    );
                },
            },
        ];

        const columnsFields = (index: any) => [
            {
                dataField: "inTime",
                text: "In",
                formatter: (cell: any, row: any, rowIndex: any) => (
                    <>
                        {row.isActualentry !== "No" && row.entryType.toLowerCase() !== "break" && (
                            <div className="d-flex align-items-center">
                                {row.maxCountin > 1 && (
                                    <div className="dot margin-right"></div>
                                )}
                                {((isTabShow(row) && row.opens.toLowerCase() !== "open") || (row.lastInNotes.length > 0 || row.inImage.length > 0)) && (<>
                                    {(row.lastInNotes.length > 0 || row.inImage.length > 0) && (
                                        <Dropdown className="more-action grey" alignRight>
                                            <Dropdown.Toggle
                                                className="btn-outline-primary inner-sec-btn more pos-abs"
                                                id="dropdown-more"
                                            >
                                                <div
                                                    className="d-flex align-items-center"
                                                    onClick={() =>
                                                        this.resetPopupData(row, rowIndex, "In", index)
                                                    }
                                                >
                                                    {this.formatDate(row.inTime)}
                                                    {row.inImage.length > 0 && (
                                                        <div>
                                                            {row.inimagestatus === "Pending" && (
                                                                <div className="orange-bg">
                                                                    <svg
                                                                        width="16"
                                                                        height="16"
                                                                        viewBox="0 0 16 16"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            fill-rule="evenodd"
                                                                            clip-rule="evenodd"
                                                                            d="M7.86669 4.00039H4.66669V2.40039H7.86669V4.00039Z"
                                                                        />
                                                                        <path
                                                                            fill-rule="evenodd"
                                                                            clip-rule="evenodd"
                                                                            d="M11.2 7.04043H4.8V8.32043C4.8 10.0877 6.23269 11.5204 8 11.5204C9.76731 11.5204 11.2 10.0877 11.2 8.32043V7.04043ZM3.2 5.44043V8.32043C3.2 10.9714 5.34903 13.1204 8 13.1204C10.651 13.1204 12.8 10.9714 12.8 8.32043V5.44043H3.2Z"
                                                                        />
                                                                        <path d="M11.0667 3.20039C11.0667 3.64222 10.7085 4.00039 10.2667 4.00039C9.82487 4.00039 9.4667 3.64222 9.4667 3.20039C9.4667 2.75856 9.82487 2.40039 10.2667 2.40039C10.7085 2.40039 11.0667 2.75856 11.0667 3.20039Z" />
                                                                    </svg>
                                                                </div>
                                                            )}
                                                            {row.inimagestatus === "Pass" && (
                                                                <div className="grey-bg">
                                                                    <svg
                                                                        width="16"
                                                                        height="16"
                                                                        viewBox="0 0 16 16"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            fill-rule="evenodd"
                                                                            clip-rule="evenodd"
                                                                            d="M7.86669 4.00039H4.66669V2.40039H7.86669V4.00039Z"
                                                                        />
                                                                        <path
                                                                            fill-rule="evenodd"
                                                                            clip-rule="evenodd"
                                                                            d="M11.2 7.04043H4.8V8.32043C4.8 10.0877 6.23269 11.5204 8 11.5204C9.76731 11.5204 11.2 10.0877 11.2 8.32043V7.04043ZM3.2 5.44043V8.32043C3.2 10.9714 5.34903 13.1204 8 13.1204C10.651 13.1204 12.8 10.9714 12.8 8.32043V5.44043H3.2Z"
                                                                        />
                                                                        <path d="M11.0667 3.20039C11.0667 3.64222 10.7085 4.00039 10.2667 4.00039C9.82487 4.00039 9.4667 3.64222 9.4667 3.20039C9.4667 2.75856 9.82487 2.40039 10.2667 2.40039C10.7085 2.40039 11.0667 2.75856 11.0667 3.20039Z" />
                                                                    </svg>
                                                                </div>
                                                            )}
                                                            {row.inimagestatus === "Fail" && (
                                                                <div className="red-badges">
                                                                    <svg
                                                                        width="16"
                                                                        height="16"
                                                                        viewBox="0 0 16 16"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            fill-rule="evenodd"
                                                                            clip-rule="evenodd"
                                                                            d="M7.86669 4.00039H4.66669V2.40039H7.86669V4.00039Z"
                                                                        />
                                                                        <path
                                                                            fill-rule="evenodd"
                                                                            clip-rule="evenodd"
                                                                            d="M11.2 7.04043H4.8V8.32043C4.8 10.0877 6.23269 11.5204 8 11.5204C9.76731 11.5204 11.2 10.0877 11.2 8.32043V7.04043ZM3.2 5.44043V8.32043C3.2 10.9714 5.34903 13.1204 8 13.1204C10.651 13.1204 12.8 10.9714 12.8 8.32043V5.44043H3.2Z"
                                                                        />
                                                                        <path d="M11.0667 3.20039C11.0667 3.64222 10.7085 4.00039 10.2667 4.00039C9.82487 4.00039 9.4667 3.64222 9.4667 3.20039C9.4667 2.75856 9.82487 2.40039 10.2667 2.40039C10.7085 2.40039 11.0667 2.75856 11.0667 3.20039Z" />
                                                                    </svg>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <div className="pos-name">
                                                    <Dropdown.Item className="cross-btn" href="">
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 20 20"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M13.4933 5.28662L9.95747 8.82162L6.42247 5.28662L5.24414 6.46495L8.77914 9.99995L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 9.99995L14.6716 6.46495L13.4933 5.28662Z"
                                                                fill="#84888C"
                                                            />
                                                        </svg>
                                                    </Dropdown.Item>
                                                </div>
                                                <Tabs id={"punchIn-tab-" + rowIndex}
                                                    onSelect={(selectedtab) => this.resetActiveTab(rowIndex, selectedtab, index, "In")}>
                                                    {row.lastInNotes.length > 0 && (
                                                        <Tab
                                                            eventKey={"details" + rowIndex + index}
                                                            title="Details"
                                                        >
                                                            <div className="section">
                                                                <div className="pading">
                                                                    <div className="d-flex start">
                                                                        <div className="profile-img">
                                                                            <img
                                                                                src={
                                                                                    row.lastInImage === null
                                                                                        ? profile
                                                                                        : row.lastInImage.trim() === ""
                                                                                            ? profile
                                                                                            : row.lastInImage
                                                                                }
                                                                                className={
                                                                                    row.lastInImage === null
                                                                                        ? "default"
                                                                                        : row.lastInImage.trim() === ""
                                                                                            ? "default"
                                                                                            : ""
                                                                                }
                                                                                width="40"
                                                                                height="40"
                                                                                alt={row.lastInUsername}
                                                                            />
                                                                        </div>
                                                                        <div className="details">
                                                                            <div className="name">
                                                                                {row.lastInUsername}
                                                                            </div>
                                                                            <div className="date">
                                                                                {row.lastInDate}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="notes">{row.lastInNotes}</div>
                                                                    <div className="time d-flex start">
                                                                        <div className="current-status">
                                                                            <div className="ttl">Original</div>
                                                                            <div className="time">
                                                                                {row.firstPunchinTime}
                                                                            </div>
                                                                        </div>
                                                                        <div className="last-status">
                                                                            <div className="ttl">Updated</div>
                                                                            <div className="time">
                                                                                {this.formatDate(row.inTime)}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {row.intimeDispute !== "" && (
                                                                    <div className="related-link">
                                                                        <div className="title">Related</div>
                                                                        <ul>
                                                                            <li>
                                                                                <a href="#" onClick={this.handleComparisonClick.bind(this, 1002, "Dispute")}>
                                                                                    Punch In Dispute
                                        </a>
                                                                            </li>

                                                                        </ul>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </Tab>
                                                    )}

                                                    {row.inImage.length > 0 && (
                                                        <Tab
                                                            eventKey={"smile-id" + rowIndex + index}
                                                            edit-punch
                                                            title="Smile iD"
                                                        >
                                                            <div className="section">
                                                                <div className="pading edit-pad">
                                                                    <div className="currentimg">
                                                                        <MyImage
                                                                            src={row.inImage}
                                                                            fallbackSrc={profile}
                                                                            alt="User SmileId"
                                                                            id={row.inUniqueno}
                                                                        ></MyImage>
                                                                        {row.inimagestatus === "Pending" && (
                                                                            <div className="badges red-badges orange-bg">
                                                                                {row.inimagelevel} %
                                                                            </div>
                                                                        )}
                                                                        {row.inimagestatus === "Pass" && (
                                                                            <div className="badges red-badges green-bg">
                                                                                {row.inimagelevel} %
                                                                            </div>
                                                                        )}
                                                                        {row.inimagestatus === "Fail" && (
                                                                            <div className="badges red-badges red-badges">
                                                                                {row.inimagelevel} %
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <div className="profile-details">
                                                                        {row.inimagestatus === "Pending" && (
                                                                            <div className="q-details">
                                                                                Is this {row.employee}?
                                                                            </div>
                                                                        )}
                                                                        {row.inimagestatus !== "Pending" && (
                                                                            <div className="q-details">
                                                                                {row.employee}
                                                                            </div>
                                                                        )}
                                                                        <div className="punch-details">
                                                                            Punched in at {row.inImageTime} on{" "}
                                                                            {row.inDate}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </Tab>
                                                    )}
                                                </Tabs>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    )}</>)}

                                {row.lastInNotes.length === 0 && row.inImage.length === 0 && (
                                    <div className="rm-bold margin-top">
                                        {this.formatDate(row.inTime)}
                                    </div>
                                )}

                            </div>
                        )}
                        {row.entryType.toLowerCase() === "break" && (
                            <div className="d-flex align-items-center">Manual Break</div>
                        )}
                    </>
                ),
                footer: "Totals",
            },
            {
                dataField: "outTime",
                text: "Out",
                formatter: (cell: any, row: any, rowIndex: any) => (
                    <>
                        {row.isActualentry !== "No" && row.entryType.toLowerCase() !== "break" &&
                            (
                                <div className="d-flex align-items-center">
                                    {row.maxCountout > 1 && (
                                        <div className="dot margin-right"></div>
                                    )}
                                    {(row.opens.toLowerCase() !== "open" && (row.lastOutNotes.length > 0 || row.outImage.length > 0)) && (
                                        <Dropdown className="more-action grey" alignRight>
                                            <Dropdown.Toggle
                                                className="btn-outline-primary inner-sec-btn more pos-abs"
                                                id="dropdown-more"
                                            >
                                                <div
                                                    className="d-flex align-items-center"
                                                    onClick={() =>
                                                        this.resetPopupData(row, rowIndex, "Out", index)
                                                    }
                                                >
                                                    {this.formatDate(row.outTime)}
                                                    {row.outImage.length > 0 && (
                                                        <div>
                                                            {row.outImageStatus === "Pending" && (
                                                                <div className="orange-bg">
                                                                    <svg
                                                                        width="16"
                                                                        height="16"
                                                                        viewBox="0 0 16 16"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            fill-rule="evenodd"
                                                                            clip-rule="evenodd"
                                                                            d="M7.86669 4.00039H4.66669V2.40039H7.86669V4.00039Z"
                                                                        />
                                                                        <path
                                                                            fill-rule="evenodd"
                                                                            clip-rule="evenodd"
                                                                            d="M11.2 7.04043H4.8V8.32043C4.8 10.0877 6.23269 11.5204 8 11.5204C9.76731 11.5204 11.2 10.0877 11.2 8.32043V7.04043ZM3.2 5.44043V8.32043C3.2 10.9714 5.34903 13.1204 8 13.1204C10.651 13.1204 12.8 10.9714 12.8 8.32043V5.44043H3.2Z"
                                                                        />
                                                                        <path d="M11.0667 3.20039C11.0667 3.64222 10.7085 4.00039 10.2667 4.00039C9.82487 4.00039 9.4667 3.64222 9.4667 3.20039C9.4667 2.75856 9.82487 2.40039 10.2667 2.40039C10.7085 2.40039 11.0667 2.75856 11.0667 3.20039Z" />
                                                                    </svg>
                                                                </div>
                                                            )}
                                                            {row.outImageStatus === "Pass" && (
                                                                <div className="grey-bg">
                                                                    <svg
                                                                        width="16"
                                                                        height="16"
                                                                        viewBox="0 0 16 16"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            fill-rule="evenodd"
                                                                            clip-rule="evenodd"
                                                                            d="M7.86669 4.00039H4.66669V2.40039H7.86669V4.00039Z"
                                                                        />
                                                                        <path
                                                                            fill-rule="evenodd"
                                                                            clip-rule="evenodd"
                                                                            d="M11.2 7.04043H4.8V8.32043C4.8 10.0877 6.23269 11.5204 8 11.5204C9.76731 11.5204 11.2 10.0877 11.2 8.32043V7.04043ZM3.2 5.44043V8.32043C3.2 10.9714 5.34903 13.1204 8 13.1204C10.651 13.1204 12.8 10.9714 12.8 8.32043V5.44043H3.2Z"
                                                                        />
                                                                        <path d="M11.0667 3.20039C11.0667 3.64222 10.7085 4.00039 10.2667 4.00039C9.82487 4.00039 9.4667 3.64222 9.4667 3.20039C9.4667 2.75856 9.82487 2.40039 10.2667 2.40039C10.7085 2.40039 11.0667 2.75856 11.0667 3.20039Z" />
                                                                    </svg>
                                                                </div>
                                                            )}
                                                            {row.outImageStatus === "Fail" && (
                                                                <div className="red-badges">
                                                                    <svg
                                                                        width="16"
                                                                        height="16"
                                                                        viewBox="0 0 16 16"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            fill-rule="evenodd"
                                                                            clip-rule="evenodd"
                                                                            d="M7.86669 4.00039H4.66669V2.40039H7.86669V4.00039Z"
                                                                        />
                                                                        <path
                                                                            fill-rule="evenodd"
                                                                            clip-rule="evenodd"
                                                                            d="M11.2 7.04043H4.8V8.32043C4.8 10.0877 6.23269 11.5204 8 11.5204C9.76731 11.5204 11.2 10.0877 11.2 8.32043V7.04043ZM3.2 5.44043V8.32043C3.2 10.9714 5.34903 13.1204 8 13.1204C10.651 13.1204 12.8 10.9714 12.8 8.32043V5.44043H3.2Z"
                                                                        />
                                                                        <path d="M11.0667 3.20039C11.0667 3.64222 10.7085 4.00039 10.2667 4.00039C9.82487 4.00039 9.4667 3.64222 9.4667 3.20039C9.4667 2.75856 9.82487 2.40039 10.2667 2.40039C10.7085 2.40039 11.0667 2.75856 11.0667 3.20039Z" />
                                                                    </svg>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <div className="pos-name">
                                                    <Dropdown.Item className="cross-btn" href="">
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 20 20"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M13.4933 5.28662L9.95747 8.82162L6.42247 5.28662L5.24414 6.46495L8.77914 9.99995L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 9.99995L14.6716 6.46495L13.4933 5.28662Z"
                                                                fill="#84888C"
                                                            />
                                                        </svg>
                                                    </Dropdown.Item>
                                                </div>
                                                <Tabs
                                                    id={"punchOut-tab-" + rowIndex}
                                                    onSelect={(selectedtab) =>
                                                        this.resetActiveTab(
                                                            rowIndex,
                                                            selectedtab,
                                                            index,
                                                            "Out"
                                                        )
                                                    }
                                                >
                                                    {row.lastOutNotes.length > 0 && (
                                                        <Tab
                                                            eventKey={"details" + rowIndex + index}
                                                            title="Details"
                                                        >
                                                            <div className="section">
                                                                <div className="pading">
                                                                    <div className="d-flex start">
                                                                        <div className="profile-img">
                                                                            <img
                                                                                src={
                                                                                    row.lastOutImage === null
                                                                                        ? profile
                                                                                        : row.lastOutImage.trim() === ""
                                                                                            ? profile
                                                                                            : row.lastOutImage
                                                                                }
                                                                                className={
                                                                                    row.lastOutImage === null
                                                                                        ? "default"
                                                                                        : row.lastOutImage.trim() === ""
                                                                                            ? "default"
                                                                                            : ""
                                                                                }
                                                                                width="40"
                                                                                height="40"
                                                                                alt={row.lastOutUsername}
                                                                            />
                                                                        </div>
                                                                        <div className="details">
                                                                            <div className="name">
                                                                                {row.lastOutUsername}
                                                                            </div>
                                                                            <div className="date">
                                                                                {row.lastOutDate}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="notes">
                                                                        {row.lastOutNotes}
                                                                    </div>
                                                                    <div className="time d-flex start">
                                                                        <div className="current-status">
                                                                            <div className="ttl">Original</div>
                                                                            <div className="time">
                                                                                {row.firstPunchoutTime}
                                                                            </div>
                                                                        </div>
                                                                        <div className="last-status">
                                                                            <div className="ttl">Updated</div>
                                                                            <div className="time">
                                                                                {this.formatDate(row.outTime)}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {row.outtimeDispute !== "" && (
                                                                    <div className="related-link">
                                                                        <div className="title">Related</div>
                                                                        <ul>
                                                                            <li>
                                                                                <a
                                                                                    href="#"
                                                                                    onClick={this.handleComparisonClick.bind(
                                                                                        this,
                                                                                        1002,
                                                                                        "Dispute"
                                                                                    )}
                                                                                >
                                                                                    Punch Out Dispute
                                        </a>
                                                                            </li>
                                                                            {/* <li><a href="#">Adjustment of -0:45</a></li>
                                                                <li><a href="#">Adjustment of +0:45 on Timesheet 3/27/20</a></li> */}
                                                                        </ul>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </Tab>
                                                    )}

                                                    {row.outImage.length > 0 && (
                                                        <Tab
                                                            eventKey={"smile-id" + rowIndex + index}
                                                            title="Smile iD"
                                                        >
                                                            <div className="section">
                                                                <div className="pading edit-pad">
                                                                    <div className="currentimg">
                                                                        <MyImage
                                                                            src={row.outImage}
                                                                            fallbackSrc={profile}
                                                                            alt="User SmileId"
                                                                            id={row.outUniqueno}
                                                                        ></MyImage>
                                                                        {row.outImageStatus === "Pending" && (
                                                                            <div className="badges red-badges orange-bg">
                                                                                {row.outImageLevel} %
                                                                            </div>
                                                                        )}
                                                                        {row.outImageStatus === "Pass" && (
                                                                            <div className="badges red-badges green-bg">
                                                                                {row.outImageLevel} %
                                                                            </div>
                                                                        )}
                                                                        {row.outImageStatus === "Fail" && (
                                                                            <div className="badges red-badges red-badges">
                                                                                {row.outImageLevel} %
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <div className="profile-details">
                                                                        {row.outImageStatus === "Pending" && (
                                                                            <div className="q-details">
                                                                                Is this {row.employee}?
                                                                            </div>
                                                                        )}
                                                                        {row.outImageStatus !== "Pending" && (
                                                                            <div className="q-details">
                                                                                {row.employee}
                                                                            </div>
                                                                        )}
                                                                        <div className="punch-details">
                                                                            Punched out at {row.outImageTime} on{" "}
                                                                            {row.outDate}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {row.outImageStatus === "Pending" && row.managesmileid.toLowerCase() === "yes" && (
                                                                    <div className="footer d-flex justify-content-end">
                                                                        <button
                                                                            type="button"
                                                                            className="btn-danger btn btn-primary"
                                                                            onClick={(e: any) =>
                                                                                this.updateSmileIdStatus("No", row, 2)
                                                                            }
                                                                        >
                                                                            No
                                    </button>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-primary"
                                                                            onClick={(e: any) =>
                                                                                this.updateSmileIdStatus("Yes", row, 2)
                                                                            }
                                                                        >
                                                                            Yes
                                    </button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </Tab>
                                                    )}
                                                </Tabs>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    )}
                                    {(row.opens.toLowerCase() !== "open" && row.lastOutNotes.length === 0 && row.outImage.length === 0) && (
                                        <div className="rm-bold margin-top">
                                            {this.formatDate(row.outTime)}
                                        </div>
                                    )}
                                    {row.opens.toLowerCase() === "open" && (
                                        <div className="rm-bold margin-top">
                                        </div>
                                    )}
                                </div>
                            )}{" "}
                    </>
                ),
                footer: "",
            },
            {
                dataField: "breakTime",
                text: "Break",
                formatter: (cell: any, row: any, rowIndex: any) => (
                    <>
                        {row.entryType.toLowerCase() === "break" && (
                            <div className="d-flex align-items-center">
                                {row.maxBreakcount > 1 && (
                                    <div className="dot margin-right"></div>
                                )}
                                {(row.isedit.toString().toLowerCase() === "yes" ||
                                    row.lastBreakNotes.length > 0) && (
                                        <Dropdown className="more-action grey" alignRight>
                                            <Dropdown.Toggle
                                                className="btn-outline-primary inner-sec-btn more pos-abs"
                                                id="dropdown-more"
                                            >
                                                <div
                                                    className="d-flex align-items-center"
                                                    onClick={() =>
                                                        this.resetPopupData(row, rowIndex, "Break", index)
                                                    }
                                                >
                                                    {row.breakTime}
                                                </div>
                                            </Dropdown.Toggle>
                                            {row.maxBreakcount > 1 && (
                                                <Dropdown.Menu>
                                                    <div className="pos-name">
                                                        <Dropdown.Item className="cross-btn" href="">
                                                            <svg
                                                                width="20"
                                                                height="20"
                                                                viewBox="0 0 20 20"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M13.4933 5.28662L9.95747 8.82162L6.42247 5.28662L5.24414 6.46495L8.77914 9.99995L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 9.99995L14.6716 6.46495L13.4933 5.28662Z"
                                                                    fill="#84888C"
                                                                />
                                                            </svg>
                                                        </Dropdown.Item>
                                                    </div>
                                                    <Tabs
                                                        id={"punchBreak-tab-" + rowIndex}
                                                        onSelect={(selectedtab) =>
                                                            this.resetActiveTab(
                                                                rowIndex,
                                                                selectedtab,
                                                                index,
                                                                "Break"
                                                            )
                                                        }
                                                    >
                                                        {row.lastBreakNotes.length > 0 && (
                                                            <Tab
                                                                eventKey={"details" + rowIndex + index}
                                                                title="Details"
                                                            >
                                                                <div className="section">
                                                                    <div className="pading">
                                                                        <div className="d-flex start">
                                                                            <div className="profile-img">
                                                                                <img
                                                                                    src={row.breakUserImage}
                                                                                    width="40"
                                                                                    height="40"
                                                                                    alt={row.lastBreakUser}
                                                                                />
                                                                            </div>
                                                                            <div className="details">
                                                                                <div className="name">
                                                                                    {row.lastBreakUser}
                                                                                </div>
                                                                                <div className="date">
                                                                                    {row.lastBreakDate}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="notes">
                                                                            {row.lastBreakNotes}
                                                                        </div>
                                                                        <div className="time d-flex start">
                                                                            <div className="current-status">
                                                                                <div className="ttl">Original</div>
                                                                                <div className="time">
                                                                                    {row.firstBreakHrs
                                                                                        .toString()
                                                                                        .replace(".", ":")}
                                                                                </div>
                                                                            </div>
                                                                            <div className="last-status">
                                                                                <div className="ttl">Updated</div>
                                                                                <div className="time">
                                                                                    {row.breakTime
                                                                                        .toString()
                                                                                        .replace(".", ":")}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Tab>
                                                        )}
                                                        {row.isedit.toString().toLowerCase() ===
                                                            "yes" && (
                                                                <Tab
                                                                    eventKey={"edit-punch" + rowIndex + index}
                                                                    title="Edit Break"
                                                                >
                                                                    <div className="section">
                                                                        <div className="pading">
                                                                            <div className="list">
                                                                                <div className="ttl">Break Duration</div>
                                                                                <div className="d-flex modf-input justify-content-start align-items-center">
                                                                                    <Form.Control
                                                                                        type="text"
                                                                                        pattern="\d*"
                                                                                        maxLength={2}
                                                                                        placeholder="HH"
                                                                                        id="HH"
                                                                                        value={row.breakHH}
                                                                                        onChange={(event: any) =>
                                                                                            this.changebreakHH(event, row, rowIndex)
                                                                                        }
                                                                                    />
                                                                                    <span>:</span>
                                                                                    <Form.Control
                                                                                        type="text"
                                                                                        pattern="\d*"
                                                                                        maxLength={2}
                                                                                        placeholder="MM"
                                                                                        id="MM"
                                                                                        value={row.breakMM}
                                                                                        onChange={(event: any) =>
                                                                                            this.changebreakMM(event, row, rowIndex)
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="list">
                                                                                <div className="ttl">Break Details</div>
                                                                                <div className="control">
                                                                                    <Form.Control
                                                                                        className="input-notes"
                                                                                        as="textarea"
                                                                                        value={row.lastOutNotesdup}
                                                                                        placeholder="Explanation required..."
                                                                                        onChange={(e: any) =>
                                                                                            this.noteOutChange(e, row, rowIndex)
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="footer d-flex justify-content-end">
                                                                            {row.isedit.toString().toLowerCase() ===
                                                                                "yes" && (
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-primary"
                                                                                        ref={this.btnUpdateBreak}
                                                                                        onClick={(e: any) =>
                                                                                            this.updateBreak(e, row, rowIndex)
                                                                                        }
                                                                                    >
                                                                                        Save
                                                                                    </button>
                                                                                )}
                                                                        </div>
                                                                    </div>
                                                                </Tab>
                                                            )}
                                                    </Tabs>
                                                </Dropdown.Menu>
                                            )}
                                        </Dropdown>
                                    )}
                                {row.isedit.toString().toLowerCase() !== "yes" &&
                                    row.lastBreakNotes.length === 0 && (
                                        <div className="rm-bold margin-top">{row.breakTime}</div>
                                    )}
                            </div>
                        )}
                    </>
                ),
                footer: "",
            },
            {
                dataField: "total",
                text: "Total",
                formatter: (cell: any, row: any, rowIndex: any) => (
                    <>{row.entryType.toLowerCase() !== "break" && <>{row.total}</>}</>
                ),
                footer: (columnData: any[]) => {
                    let total = 0;
                    let breakTime = 0;
                    let dataArr: any[] = [];
                    dataArr = Object.assign(
                        [],
                        JSON.parse(JSON.stringify(this.state.dayDetails[index].timesheet))
                    );
                    // eslint-disable-next-line array-callback-return
                    columnData.map((data: any) => {
                        if (data !== null) {
                            if (data !== undefined) {
                                if (data.trim().length > 0) {
                                    if (data.includes(":")) {
                                        let subTotal = 0;
                                        const dataArray = data.split(":");
                                        let HH = Number(dataArray[0]);
                                        let MM = Number(dataArray[1]);
                                        if (
                                            dataArray[0].includes("-") ||
                                            dataArray[1].includes("-")
                                        ) {
                                            HH = HH < 0 ? HH * -1 : HH;
                                            MM = MM < 0 ? MM * -1 : MM;
                                            subTotal = (HH * 60 + MM) * -1;
                                        } else {
                                            subTotal = HH * 60 + MM;
                                        }
                                        total = total + subTotal;
                                    }
                                }
                            }
                        }
                    });
                    if (dataArr.length > 0) {
                        dataArr = dataArr.filter((item) => item.entryType === "Break");
                        if (dataArr.length > 0) {
                            // eslint-disable-next-line array-callback-return
                            dataArr.map((dataArr: any) => {
                                if (dataArr.breakTime.length > 0) {
                                    let subbreakTotal = 0;
                                    if (dataArr.breakTime.includes(":")) {
                                        const dataArray = dataArr.breakTime.split(":");
                                        let HH = Number(dataArray[0]);
                                        let MM = Number(dataArray[1]);
                                        if (
                                            dataArray[0].includes("-") ||
                                            dataArray[1].includes("-")
                                        ) {
                                            HH = HH < 0 ? HH * -1 : HH;
                                            MM = MM < 0 ? MM * -1 : MM;
                                            subbreakTotal = (HH * 60 + MM) * -1;
                                        } else {
                                            subbreakTotal = HH * 60 + MM;
                                        }
                                        breakTime = breakTime + subbreakTotal;
                                    }
                                }
                            });
                        }
                    } else {
                        breakTime = 0;
                    }
                    return this.FormatHoursMinutesDuration(total, breakTime);
                },
            },
            {
                dataField: "position",
                text: "Position",
                formatter: (cell: any, row: any, rowIndex: any) => (
                    <EllipsisWithTooltip placement="bottom">
                        {row.position}
                    </EllipsisWithTooltip>
                ),
                footer: "",
            },
            {
                dataField: "",
                text: "",
                footer: "",
                formatter: (cell: any, row: any, rowIndex: any) => (
                    <div className="d-flex flex-wrap">
                        {row.apo.length > 0 && (
                            <>
                                {" "}
                                <div className="red-badges">{row.apo}</div>{" "}
                            </>
                        )}
                        {row.manuals.length > 0 && (
                            <>
                                {" "}
                                <div className="red-badges blue-bg">{row.manuals}</div>{" "}
                            </>
                        )}
                        {row.opens !== "" && row.opens !== null && (
                            <>
                                {" "}
                                <div className="red-badges">{row.opens}</div>{" "}
                            </>
                        )}
                        {row.otf !== "" && row.otf !== null && (
                            <>
                                <div className="red-badges blue-bg">{row.otf}</div>
                            </>
                        )}
                    </div>
                ),
            },
            {
                dataField: "",
                text: "",
                formatter: (cell: any, row: any, rowIndex: any) => (
                    <div className="d-flex">
                        {row.laborActualCommentRes?.length > 0 && (
                            <Dropdown className="more-action notes-btn" alignRight>
                                <Dropdown.Toggle
                                    className="btn-outline-primary  more"
                                    id="dropdown-more"
                                >
                                    <div className="d-flex">
                                        <svg
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M13.5556 2.4H11.3889V1H9.94444V2.4H7.05556V1H5.61111V2.4H3.44444C2.64783 2.4 2 3.0279 2 3.8V13.6C2 14.3721 2.64783 15 3.44444 15H13.5556C14.3522 15 15 14.3721 15 13.6V3.8C15 3.0279 14.3522 2.4 13.5556 2.4ZM3.44444 13.6V4.5H5.61111H7.05556H9.94444H11.3889H13.5556V3.8L13.557 13.6H3.44444Z" />
                                            <path d="M4.6665 6H11.3332V7.33333H4.6665V6ZM4.6665 8.66667H7.99984V10H4.6665V8.66667Z" />
                                        </svg>
                                        <span>{row.laborActualCommentRes?.length}</span>
                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>

                                    <div className="notes-sec">
                                        {/* <div className="notes-ttl">Edit History</div> */}
                                        <div className="notes-list">
                                            {row.laborActualCommentRes.map((item: any, idx: any) => (
                                                <div className="comment-list">
                                                    <div className="d-flex profile-details">
                                                        <div className="profile-img">
                                                            <img
                                                                src={
                                                                    item.imagepath === null
                                                                        ? profile
                                                                        : item.imagepath.trim() === ""
                                                                            ? profile
                                                                            : item.imagepath
                                                                }
                                                                className={
                                                                    item.imagepath === null
                                                                        ? "default"
                                                                        : item.imagepath.trim() === ""
                                                                            ? "default"
                                                                            : ""
                                                                }
                                                                width="40"
                                                                height="40"
                                                                alt={item.username}
                                                            />
                                                        </div>
                                                        <div>
                                                            <div className="main-ttl">{item.username}</div>
                                                            <div className="sub-ttl">
                                                                {Utils.getDateTimeMddyyFromUtc(
                                                                    item.commentdate
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ul>
                                                        <li>
                                                            <div className="d-flex">
                                                                <div className="detail-sec">
                                                                    <div className="comments">
                                                                        {item.comments}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        )}
                    </div>
                ),
                footer: "",
            },
            {
                dataField: "",
                text: "",
                formatter: (cell: any, row: any, rowIndex: any) => (
                    <>

                    </>
                ),
                footer: (columnData: any[]) => { },
                footerFormatter: (column: any, colIndex: any) => (
                    <>
                        {isActualExist(index)}

                        <div className="d-flex justify-content-end">

                        </div>
                    </>
                ),
            },
        ];

        let disputeStatus = this.props.data.disputeStatus;
        let { forseApprovalModal } = this.state;
        return (
            <div className="pos-fxd index-1000">
                {/* <ToastContainer autoClose={3000} /> */}
                <div className="d-flex">
                    <div className="back-drop" onClick={this.closeSlideOut}></div>
                    <Container
                        fluid
                        className="body-sec performance-position timesheet-details justify-content-end rm-padding"
                    >
                        <div className="stickey">
                            <div className="page-heading underline d-flex">
                                <div className="hid-set d-flex align-items-center">
                                    {this.state.hotelName}
                                </div>
                                <div className="mr-auto d-flex align-items-center">
                                    Timesheet
                </div>
                                <div className="action-group d-flex flex-row">
                                    <button
                                        type="button"
                                        className="btn wht-bg cross"
                                        onClick={this.closeSlideOut}
                                    >
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                                                fill="#84888C"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="add-padding" id="deepToTop">
                            {this.state.exportedDate.length > 0 &&
                                this.state.exportedDate !== "0" && (
                                    <div className="info-badges exported-date">
                                        <div className="d-flex">
                                            <div>This week was exported as part of the </div>
                                            <div><a className="d-flex" onClick={() => this.openOverView()}>{this.state.payPeriodDates[0]} {"-"} {this.state.payPeriodDates[1]} payroll</a></div>
                                            <div>on {this.state.exportedDate}.</div>
                                        </div>
                                    </div>
                                )}
                            {(this.state.pageloader &&
                                <div className="loader-responsive">
                                    <div className="timesheetSlideout">

                                        <div className="tab-list">
                                            <div className="tab-header loadingAnimation"></div>
                                            <ul>
                                                <li>
                                                    <div className="d-flex align-items-center">
                                                        <div className="profile-bg loadingAnimation"></div>
                                                        <div className="sec-details">
                                                            <div className="loadingAnimation"></div>
                                                            <div className="loadingAnimation"></div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="d-flex align-items-center">
                                                        <div className="sec-details">
                                                            <div className="loadingAnimation"></div>
                                                            <div className="loadingAnimation"></div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="divider"></li>
                                                <li>
                                                    <div className="d-flex align-items-center">
                                                        <div className="sec-details">
                                                            <div className="loadingAnimation"></div>
                                                            <div className="loadingAnimation"></div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="divider"></li>
                                                <li>
                                                    <div className="d-flex align-items-center">
                                                        <div className="sec-details">
                                                            <div className="loadingAnimation"></div>
                                                            <div className="loadingAnimation"></div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {(!this.state.pageloader &&
                                <div className="overFlow-hide">
                                    <div className="d-flex details-sec">
                                        <div
                                            className="flex-fill align-content-center box"
                                            style={{ marginBottom: "20px" }}
                                        >
                                            <div className="title" style={{ marginBottom: "0" }}>
                                                {this.state.payPeriod}
                                                {weektoDate < currentDate && this.state.forceapproval.toLowerCase() === "yes" &&
                                                    (disputeStatus === "Waiting for Review" ||
                                                        disputeStatus === "Disputed") && (
                                                        <Dropdown className="more-action hasPrimaryBorder float-right">
                                                            <Dropdown.Toggle
                                                                className="btn-outline-primary btn btn-primary more"
                                                                id="dropdown-more"
                                                            >
                                                                {this.dotsSVG()}
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item onClick={this.forseApprovalShow}>
                                                                    Force Approval
                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex details-sec overFlow-scroll">
                                        <div
                                            className="flex-fill align-content-center box"
                                            style={{ marginTop: "0" }}
                                        >
                                            <div className="list">
                                                {/* {this.state.positionList.length > 0 && ( */}
                                                <ul>
                                                    <li>
                                                        <div className="d-flex">
                                                            <div className="profile-img">
                                                                <img
                                                                    src={
                                                                        this.props.data.imagePath === null
                                                                            ? profile
                                                                            : this.props.data.imagePath.trim() === ""
                                                                                ? profile
                                                                                : this.props.data.imagePath
                                                                    }
                                                                    className={
                                                                        this.props.data.imagePath === null
                                                                            ? "default"
                                                                            : this.props.data.imagePath.trim() === ""
                                                                                ? "default"
                                                                                : ""
                                                                    }
                                                                    width="40"
                                                                    height="40"
                                                                    alt={this.state.userName}
                                                                />
                                                            </div>
                                                            <div className="profile-detail">
                                                                <div className="main-ttl">
                                                                    {this.state.userName}
                                                                </div>
                                                                <div className="sub-ttl">
                                                                    {this.props.data.position}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li
                                                        className={
                                                            this.state.activeClass === -1 ? "active" : ""
                                                        }
                                                        key={-1}
                                                        onClick={this.handleComparisonClick.bind(
                                                            this,
                                                            -1,
                                                            "All"
                                                        )}
                                                    >
                                                        <div className="ttl">{this.state.total.totlhrs}</div>
                                                        <div className="desc">Total Hours</div>
                                                    </li>
                                                    {this.state.positionList.length > 0 && (
                                                        <li>
                                                            <div className="separator"></div>
                                                        </li>
                                                    )}
                                                    {this.state.positionList.map(
                                                        (item: any, index: any) => (
                                                            <li
                                                                key={index}
                                                                className={
                                                                    this.state.activeClass === index ? "active" : ""
                                                                }
                                                                onClick={this.handleComparisonClick.bind(
                                                                    this,
                                                                    index,
                                                                    item.position
                                                                )}
                                                            >
                                                                <div className="ttl">{item.value}</div>
                                                                <div className="desc">{item.position}</div>
                                                            </li>
                                                        )
                                                    )}
                                                    {this.state.positionList.length > 0 && (
                                                        <li>
                                                            <div className="separator"></div>
                                                        </li>
                                                    )}
                                                    {/* {this.state.positionList.length > 0 && (
                                                      <li className={this.state.activeClass === 1001 ? 'active' : ''} key={-1} onClick={this.handleComparisonClick.bind(this, 1001, "Adjustments")}>
                                                          <div className="ttl">{this.state.adjustments}</div>
                                                          <div className="desc">Adjustments</div>
                                                      </li>
                                                      )} */}
                                                    {this.state.dispute !== 0 && (
                                                        <li
                                                            className={
                                                                this.state.activeClass === 1002
                                                                    ? "active dispute"
                                                                    : "dispute"
                                                            }
                                                            key={-1}
                                                            onClick={this.handleComparisonClick.bind(
                                                                this,
                                                                1002,
                                                                "Dispute"
                                                            )}
                                                        >
                                                            <div className="ttl">
                                                                <div className="pos-rel">
                                                                    {this.state.dispute}
                                                                    {this.state.isDiputeOpen.toLowerCase() ===
                                                                        "yes" && <div className="dot-abs"></div>}
                                                                </div>
                                                            </div>
                                                            <div className="desc">Dispute</div>
                                                        </li>
                                                    )}
                                                </ul>
                                                {/* )}*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {this.state.condition === "All" && (
                                <>
                                    {(this.state.pageloader &&
                                        <div className="loader-responsive">
                                            <div className="timesheetSlideout common-table">
                                                <div className="table-header loadingAnimation"></div>
                                                <div className="table-body">
                                                    <div className="d-flex align-items-center row-table">
                                                        <div className="flex-fill loadingAnimation"></div>
                                                        <div className="flex-fill loadingAnimation"></div>
                                                        <div className="flex-fill loadingAnimation"></div>
                                                        <div className="flex-fill loadingAnimation"></div>
                                                        <div className="flex-fill loadingAnimation"></div>
                                                        <div className="flex-fill loadingAnimation"></div>
                                                        <div className="flex-fill loadingAnimation"></div>
                                                        <div className="flex-fill loadingAnimation"></div>
                                                        <div className="flex-fill loadingAnimation"></div>
                                                    </div>
                                                    <div className="d-flex align-items-center row-table">
                                                        <div className="flex-fill loadingAnimation"></div>
                                                        <div className="flex-fill loadingAnimation"></div>
                                                        <div className="flex-fill loadingAnimation"></div>
                                                        <div className="flex-fill loadingAnimation"></div>
                                                        <div className="flex-fill loadingAnimation"></div>
                                                        <div className="flex-fill loadingAnimation"></div>
                                                        <div className="flex-fill loadingAnimation"></div>
                                                        <div className="flex-fill loadingAnimation"></div>
                                                        <div className="flex-fill loadingAnimation"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {(!this.state.pageloader &&
                                        <div className="scroll-area">
                                            <div className="detail-view without-bdr">
                                                <BootstrapTable
                                                    id={"timesheetdtlList"}
                                                    keyField="title"
                                                    data={this.state.data}
                                                    columns={columns}
                                                    hover
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {(this.state.pageloader &&
                                        <div className="loader-responsive">
                                            <div className="week-list timesheetSlideout">
                                                <div className="ttl">Details by Day</div>
                                                <div className="detail-view">
                                                    <div className="day-list timesheetSlideout">
                                                        <div className="ttl loadingAnimation"></div>
                                                        <div className="common-table">
                                                            <div className="table-header loadingAnimation"></div>
                                                            <div className="table-body">
                                                                <div className="d-flex align-items-center row-table">
                                                                    <div className="flex-fill loadingAnimation"></div>
                                                                    <div className="flex-fill loadingAnimation"></div>
                                                                    <div className="flex-fill loadingAnimation"></div>
                                                                    <div className="flex-fill loadingAnimation"></div>
                                                                    <div className="flex-fill loadingAnimation"></div>
                                                                    <div className="flex-fill loadingAnimation"></div>
                                                                    <div className="flex-fill loadingAnimation"></div>
                                                                    <div className="flex-fill loadingAnimation"></div>
                                                                </div>
                                                                <div className="d-flex align-items-center row-table">
                                                                    <div className="flex-fill loadingAnimation"></div>
                                                                    <div className="flex-fill"></div>
                                                                    <div className="flex-fill"></div>
                                                                    <div className="flex-fill loadingAnimation"></div>
                                                                    <div className="flex-fill "></div>
                                                                    <div className="flex-fill "></div>
                                                                    <div className="flex-fill loadingAnimation"></div>
                                                                    <div className="flex-fill loadingAnimation"></div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                            {this.state.dayDetails.length > 0 &&
                                this.state.condition === "All" && (
                                    <>

                                        <div className="week-list">
                                            <div className="">
                                                <div className="ttl">Details by Day</div>
                                                <div className="detail-view">
                                                    {this.state.dayDetails.map((item: any, index: any) => (
                                                        <div className="day-list" id={this.state.dayDetails[index].timesheet[0].groupID}
                                                            style={{
                                                                backgroundColor: Number(this.state.dayDetails[index].timesheet[0].groupID)
                                                                    === Number(this.state.shiftID) ? "ghostwhite" : "",
                                                            }} >
                                                            <div className="ttl">
                                                                {this.state.dayDetails[index].timesheet[0].dayName}{" "}
                                                                {this.state.dayDetails[index].timesheet[0].lockstatus.toString().toLowerCase() === "locked" && (
                                                                    <img src={ForecastLock} alt="ForecastLock" />
                                                                )}
                                                            </div>

                                                            <div
                                                                className={
                                                                    this.state.dayDetails[index].timesheet
                                                                        .length === 1
                                                                        ? this.state.dayDetails[index].timesheet[0]
                                                                            .isActualentry === "No"
                                                                            ? "hideRow"
                                                                            : ""
                                                                        : ""
                                                                }
                                                            >
                                                                <BootstrapTable
                                                                    id={"timesheetDetails"}
                                                                    keyField="titlee"
                                                                    data={this.state.dayDetails[index].timesheet}
                                                                    columns={columnsFields(index)}
                                                                    hover
                                                                />
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>

                                    </>
                                )}
                            {this.state.condition === "Adjustments" && <div></div>}
                            {this.state.condition === "Dispute" && (
                                <>
                                    <div className="week-list dispute">
                                        <LaborTimesheetDispute
                                            hotelID={this.state.hotelID}
                                            userName={this.state.userName}
                                            date={this.state.date}
                                            isOTAR={this.props.isOTAR}
                                            managedispute={this.state.managedispute}
                                            handleComparisonClick={this.handleComparisonClick}
                                            alldepartmentlist={this.state.alldepartmentlist}
                                            refreshTimesheetDetails={this.refreshTimesheetDetails.bind(this)}
                                        />
                                    </div>
                                    <div className="week-list dispute">
                                        <LaborTimesheetResolvedDispute
                                            hotelID={this.state.hotelID}
                                            userName={this.state.userName}
                                            date={this.state.date}
                                            handleComparisonClick={this.handleComparisonClick}
                                            alldepartmentlist={this.state.alldepartmentlist}
                                            refreshTimesheetDetails={this.refreshTimesheetDetails.bind(this)}
                                        />
                                    </div>
                                </>
                            )}
                        </div>

                        <Modal
                            className="forseApprovalModal"
                            show={forseApprovalModal}
                            centered
                            onHide={this.forseApprovalClose}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Approval Comments</Modal.Title>
                            </Modal.Header>
                            <Form noValidate onSubmit={this.forseApprovalSubmit}>
                                <Modal.Body>
                                    <Form.Group controlId="forseApprovalForm">
                                        <Form.Control
                                            as="textarea"
                                            required={true}
                                            rows={3}
                                            value={this.state.forseApprovalValue}
                                            onChange={this.forseApprovalChange}
                                            placeholder="Explanation required..."
                                        />
                                    </Form.Group>
                                    {this.state.forseApprovalError && (
                                        <span className="text-danger">
                                            {this.state.forseApprovalError}
                                        </span>
                                    )}
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button type="submit" variant="primary">
                                        Submit
                  </Button>
                                </Modal.Footer>
                            </Form>
                        </Modal>
                    </Container>
                </div>
            </div>
        );
    }
}
