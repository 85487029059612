import React from "react";
import { toast, ToastContainer } from "react-toastify";
import {
  Container,
  Dropdown,
  Form,
  Tab,
  Tabs,
  Row,
  Nav,
  Button,
  Modal,
  Col,
  Spinner,
} from "react-bootstrap";
import moment from "moment";
// import { FundingSourceModal } from "./FundingSourceModal";
import { SingleSearchDropdownList } from "../../../Common/Components/SingleSearchDropdownList";
import { Utils } from "../../../Common/Utilis";
import { DropDownList } from "../../Reports/common-components/dropdown-list";
import { StaticArrays } from "../../../Common/StaticArrays";
import { accountService } from "../../../Common/Services/account";
import { PlaidSetup } from "../../../Common/ThirdPartyServices/PlaidSetup";
import { Register } from "../../../Common/Services/Register";
import _ from "lodash";
import { ReactPageLoader } from "../../../Common/Components/ReactPageLoader";
import { confirmAlert } from "react-confirm-alert";

export class AddBankAccountSetup extends React.Component<any, any> {
  private continueBtnRef ;
  private cancelBtnRef ;
  private submitBtnRef ;
  private instantVerfityBtnRef;
  constructor(props: any) {
    super(props);
    this.continueBtnRef = React.createRef()
    this.cancelBtnRef = React.createRef()
    this.submitBtnRef = React.createRef()
    this.instantVerfityBtnRef = React.createRef()
    this.state = {
      showSourceModal: false,
      step: "1",
      formData: {
        bankName: "",
        accountType: "",
        routingNumber: "",
        accountNo: "",
        accountName: "",
        hotelID: "",
        bankRefID: "",
      },
      registerRequest: {
        hotelID: -1,
        oprID: -1,
        selectType: "30",
        isDepositHide: "Yes",
        startDate: "",
        endDate: "",
        acctType: "M",
      },
      accountName: "Select...",
      // bankName: this.props.bankName,
      // accountType: "",
      // routingNumber: "",
      // accountNo: this.props.accountNo,
      // accountName: this.props.accountName,
      // hotelID: this.props?.hotelID,
      // bankRefID: this.props?.bankRefID,
      errors: {
        bankName: "",
        accountType: "",
        routingNumber: "",
        accountNo: "",
        accountName: "",
      },
      isDataLoaded: false,
      selectedBank:{},
      isFundingSourceExist:false,
      spinner:false,
    };
  }

  componentDidMount() {
    let registerRequest1 = { ...this.state.registerRequest };
    const { lettercode, hotelID } = this.props.hotelDetails?.hotelDetail;
    registerRequest1.hotelID = hotelID;
    registerRequest1.lettercode = lettercode;
    //this.getRegisterlist(registerRequest);
    this.getRegisterNames(registerRequest1);
  }

  getRegisterNames = (registerRequest: any) => {
    let regularlist: any = [];
    let splitlist: any = [];
    let selectedaccount = "";
    let selectedStatus = "";

    const {fundingSources} = this.props;
    let fundingSourceObj:any = {};
    fundingSources.length > 0 && fundingSources.map((item)=>{
      fundingSourceObj[item.oprID] = item;
    })
    Register.RegisterAccount(registerRequest)
      .then(async (result: any[] | null) => {
        if (result !== null) {
          let sharedHIDs: any[] = [];
          let accountNames: any[] = [];

          result.sort((a, b) =>
            a.status !== b.status ? (a.status < b.status ? -1 : 1) : 0
          ); //_.sortBy(result, "status");

          result.forEach((element) => {
            let hidCode: any = [];
            if (element.status === "Active" && element.acctType !== "C") {
              let acctName: any = {};
              let oprID = element.oprID;
              let name = element.name;
              acctName.id = oprID;
              acctName.sort = element.orderBy;
              acctName.name = name;
              acctName.status = element.status;
              // accountNames.push(acctName);

              if(fundingSourceObj[element.oprID] === undefined){
                accountNames.push(acctName);
              }
              if (Number(this.state.selectedOprID) === Number(oprID)) {
                selectedaccount = name;
                selectedStatus = element.status;
              }
            }
          });
          accountNames = _.sortBy(accountNames, "sort");
          this.setState({ accountNamelist: result });
          const location: any = {};
          // if (location.state !== undefined) {
          //   const stateLength = Object.keys(location.state).length;
          //   if (stateLength > 0) {
          //     let arr: any = {};
          //     arr.id = this.state.selectedOprID;
          //     arr.status = selectedStatus;
          //     this.setState(
          //       {
          //         accountNames: accountNames,
          //         accountName: selectedaccount,
          //         isDataLoaded: true,
          //       },
          //       () => {
          //         this.handleaccountNameSelectedItem(1, arr);
          //       }
          //     );
          //   }
          // } else {
          //   if (accountNames.length === 1) {
          //     this.handleaccountNameSelectedItem(1, accountNames[0]);
          //     this.setState({ accountName: accountNames[0].name }, () => {
          //       this.setState({ accountNames: accountNames });
          //     });
          //   } else {
          //     this.setState({ accountNames: accountNames });
          //   }
          // }
          this.setState({ isDataLoaded: true,accountNames:accountNames });
        }

        // resolve();
      })
      .catch((error) => {
        // reject();
      });
  };

  handleaccountNameSelectedItem = (controlID: any, arr: any) => {
    this.setState({
      istableloader: true,
      isloader: true,
      accountStatus: arr.status,
    });
    this.getBankAccDetailsRes(arr.id);
    this.setState({ customDate: false});
    let sharedHIDs: any[] = [];
    let totalsharedHIDCount = 0;
    let acctType = "";
    let sharedcode = "";
    let name = "";
    let isCCA = false;
    this.state.accountNamelist
      .filter((x) => x.oprID === arr.id)
      .forEach((element) => {
        let hidCode: any = [];
        hidCode = element.sharedHID.split("[");
        totalsharedHIDCount = element.hidCount;
        acctType = element.acctType;
        sharedcode = element.sharedcode;
        name = element.name;
        isCCA = element.acctType.toString().toLocaleLowerCase() === "c";
        hidCode.forEach((item) => {
          let sharedHID: any = {};
          let lettercodes: any = [];
          lettercodes = item.split("_");
          let splitHid = lettercodes[0];
          let splitcode = lettercodes[1];
          sharedHID.hid = splitHid;
          sharedHID.lettercode = splitcode;
          sharedHID.isChecked = false;
          sharedHIDs.push(sharedHID);
        });
      });

    let transferRequest = { ...this.state.transferRequest };
    transferRequest.acctType = acctType;
    let registerRequest = { ...this.state.registerRequest };
    registerRequest.oprID = arr.id;
    registerRequest.acctType = acctType;
    registerRequest.hotelID = this.state.hidValue;
    registerRequest.hotelName = this.state.hotelName;
    registerRequest.accountName = name;
    registerRequest.selectType = "30";
    this.setState(
      {
        CardUniqueNo: arr.id,
        accountName: name,
        transferRequest,
        isCCAcc: isCCA,
        accountNameValue: arr.id,
        totalsharedHIDCount: totalsharedHIDCount,
        dayText: "Last 30 Days",
        totalsharedHID: sharedHIDs,
        totalsharedHIDTemp: sharedHIDs,
        registerRequest: registerRequest,
        sharedcode: sharedcode,
        tableData: [],
        registerList: [],
        dateRange: "",
        start: new Date(),
        end: new Date(),
        isSelect: true,
        ehidSected: 0,
        isFilter: false,
        viewType: "regular",
      },
      () => {
        // this.CleanSearch();
        // this.getRegisterlist(registerRequest);
        // this.getRegisterBal(registerRequest);
        //this.getRegisterRepeatlist(registerRequest);
        // this.getRegisterPermission();
      }
    );

    // this.setState({ moreAction });
  };
  getBankAccDetailsRes = (oprID) => {
    this.continueBtnRef.current.disabled = true;
    this.cancelBtnRef.current.disabled = true;
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let reqObj: any = {};
    reqObj.oprID = oprID;
    reqObj.hotelID = this.props.hotelId;
    reqObj.tenantID = tenantID;
    reqObj.obaccno = ""; //id: -1, hot
    accountService
      .BankAccDetailsRes(reqObj)
      .then(async (result: any | null) => {
        if (result !== null) {
          let datee = new Date(result[0].openingDate);
          let calenderYeare = datee.getFullYear().toString().substr(2, 4);
          let calenderDatee =
            datee.getDate() > 9 ? datee.getDate() : 0 + "" + datee.getDate();
          let calenderMonthe =
            datee.getMonth() + 1 > 9
              ? datee.getMonth() + 1
              : 0 + "" + (datee.getMonth() + 1);
          let tempDatee =
            calenderMonthe + "/" + calenderDatee + "/" + calenderYeare;
          result[0].openingDate = tempDatee;
          result[0].accountType = "";
          result[0].routingNumber = "";
          this.setState({
            calenderSelectDate: datee,
            formData: result[0],
            selectedBank:result[0],
            isSpinner: false,
            lastActiveStatus: result[0].status,
          });
    this.continueBtnRef.current.disabled = false;
    this.cancelBtnRef.current.disabled = false;
          
          // if (result[0].digitalSignatureImg.length > 0) {
          //   this.setState({
          //     files: [
          //       {
          //         preview:
          //           "data:image/png;base64," + result[0].digitalSignatureImg,
          //         name: "Uploaded by " + result[0].imageUsername,
          //         uploaddatetime: result[0].uploaddatetime,
          //       },
          //     ],
          //   });
          //   this.setState({ fileName: result[0].fileName });
          // }
          // if (this.state.actionType === "edit") {
          //   this.PlaiddSetupStatus(result[0].hotelID, result[0].id);
          //   this.PlaiddSetupButtonPermission(result[0].hotelID);
          //   this.setState({
          //     selectedExistAcc: {
          //       oprID: result[0].oprID,
          //       hotelID: result[0].hotelID,
          //       tenantID: result[0].tenantID,
          //       obaccno:
          //         this.state.isShowSensitiveFields === "Yes"
          //           ? result[0].obaccno
          //           : result[0].accountNo,
          //     },
          //   });
          // }
          // this.handleCOA([{ id: result[0].coa, label: result[0].coa }]);
          // this.bindFundingSource();
        } else {
          this.setState({ isSpinner: false });
        }
      });
  }


  validateRoutingNumber = (num: string): boolean => {
    // Run through each digit and calculate the total.
    let n = 0;
    for (let i = 0; i < num.length; i += 3) {
      n +=
        parseInt(num.charAt(i), 10) * 3 +
        parseInt(num.charAt(i + 1), 10) * 7 +
        parseInt(num.charAt(i + 2), 10);
    }
    // If the resulting sum is an even multiple of ten (but not zero),
    // the aba routing number is good.
    if (n !== 0 && n % 10 === 0) {
      return true;
    } else {
      return false;
    }
  };

  isFormValid() {
    let errors = this.state.errors;
    let formData = { ...this.state.formData };
    let isForm = true;
    if (formData.bankName.trim() === "") {
      errors.bankName = "Bank Name is required.";
      isForm = false;
    }
    if (formData.accountType === "") {
      errors.accountType = "Please select Account Type.";
      isForm = false;
    }
    if (formData.routing.trim() === "") {
      errors.routingNumber = "Routing Number is required.";
      isForm = false;
    }
    if (!this.validateRoutingNumber(formData.routing.trim())) {
      errors.routingNumber = "Routing Number is invalid.";
      isForm = false;
    }
    if (formData.accountNo.trim().length === 0) {
      errors.accountNo = "Account Number is required.";
      isForm = false;
    }
    if (formData.accountName === 0) {
      errors.accountName = "Account Name(Business Name) is required.";
      isForm = false;
    }
    this.setState({ errors });
    if (isForm === true) {
      return true;
    } else {
      return false;
    }
  }

  //   openPlaid = () => {
  //     this.props?.handleShowHideChoiceModal();
  //     this.props?.plaidFunction();
  //   };

  handleCancelSetupAccount = (state) => {
    this.props?.handleCancelSetupAccount(state);
  };

  handleContinueManualSetup = () => {
    this.setState({ step: "manualVerificationForm" });
  };

  isFundingSourceVerified() {
    confirmAlert({
      title: "Funding Source Exists",
      message:
        "Bank account already exists do you want to enable again?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.props.enableFundingSource(this.state.selectedBank,"reconnect"),
        },
        {
          label: "No",
          onClick: () => this.setState({no:true}),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }
  handleContinue = (step) => {
    if (step === "1") {
      const { accountName,selectedBank } = this.state;
      const {fundingSources} = this.props;
      if (accountName.includes("Select")) {
        Utils.toastError("Please select a bank.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "bankDetailsSlideout",
        });
        return;
      }
      let isFundingSourceExist = false;
      fundingSources.length > 0 && fundingSources.map(item=>{
        if(accountName.includes(item.displayNo)){
          isFundingSourceExist = true;
          return;
        }
      })
      if(isFundingSourceExist){
        Utils.toastError("Bank already exist, Please select different bank.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "bankDetailsSlideout",
        });
        return;
      }
     this.continueBtnRef.current.disabled = true;
     this.cancelBtnRef.current.disabled = true;
     this.setState({spinner:true})
        let request: any = {};
        request.hotelID = this.props.hotelId; //ExistingFundingSource
        request.bankRefID = selectedBank.id;
        accountService
          .GetFundingSource(request)
          .then(async (result: any | null) => {
            if (result !== null) {
              // debugger;
              // console.log(result);
            
                this.isFundingSourceVerified();
             
               
              this.continueBtnRef.current.disabled = false;
              this.cancelBtnRef.current.disabled = false;
              this.setState({
                isFundingSourceExist:true,
                existingfundingSource: result,
                spinner:false
              });
            }else{
              this.continueBtnRef.current.disabled = false;
              this.cancelBtnRef.current.disabled = false;
              this.setState({ step: "2",spinner:false });
            }
          })
          .catch((error) => {
            Utils.toastError(error?.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "bankDetailsSlideout",
            });
            this.continueBtnRef.current.disabled = false;
            this.cancelBtnRef.current.disabled = false;
            this.setState({spinner:false})
          });
      
    } else if (step === "2") {

      this.instantVerfityBtnRef.current.disabled = true;
      this.setState({spinner:true})
      this.props?.plaidFunction(this.state);
      // this.props?.handleCancelSetupAccount(true);
      // this.setState({ step: "manualVerificationForm" });
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    let errors = this.state.errors;
    let formData = { ...this.state.formData };
    switch (name) {
      case "routingNumber":
        errors.routingNumber =
          value.trim().length === 0 ? "Please enter Routing Number." : "";
        formData.routing = value;
        break;
      case "accountType":
        errors.accountType = value.trim() === "" ? "" : "";
        formData.accountType = value;
        break;
      default:
        break;
    }

    this.setState({ errors, formData });
  };

  validationOnClick = (inputPosition) => {
    this.setState({ inputPosition }, () => this.validateDepositFormSchema());
  };

  validateDepositFormSchema = () => {
    let errors = this.state.errors;
    let formData = { ...this.state.formData };
    let isForm = true;

    if (formData.accountType && errors.accountType) {
      errors.accountType = "";
    }
    this.setState({ errors });
  };

  onFieldChange = (event, inputPosition) => {
    const { formData } = this.state;
    let value = event.target.value;
    const fieldName = event.target.name;
    formData[fieldName] = value;

    this.setState({ formData, inputPosition }, () =>
      this.validateDepositFormSchema()
    );
  };

  addFundingSource = () => {
    debugger;
    // this.props.handleSubmitBankSetup();

    if (this.isFormValid()) {
      this.setState({ isDisabled: true });
      this.setState({ isSpinner: true });
      const { accountName, obaccno, accountType, bankName, hotelID, id, routing } = this.state.formData;
      const reqObj: any = {};
      reqObj.accountName = accountName;
      reqObj.accountNo = obaccno;
      reqObj.accountType = accountType;
      reqObj.bankName = bankName;
      reqObj.bankRefID = id;
      reqObj.routingNumber = routing;
      reqObj.hotelID = hotelID;
      this.submitBtnRef.current.disabled = true;
      accountService
        .SaveFundingSourceDetails(reqObj)
        .then(async (result: any | null) => {
          if (result !== null) {
            if (result.status === "Success") {
              toast.success("Manual Verification Submitted", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "bankDetailsSlideout",
              });
              setTimeout(() => {
                this.props.handleSubmitBankSetup(this.state, "manual");
                // this.closePopupFinal();
                // this.refreshPage();
              }, 1000);
            } else {
              this.setState({ isSpinner: false });
              this.setState({ isDisabled: false });
              Utils.toastError(result.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "bankDetailsSlideout",
              });
            }
          }
            this.submitBtnRef.current.disabled = false;
          //    resolve();
        })
        .catch((error) => {
      this.submitBtnRef.current.disabled = false;
          this.setState({ isSpinner: false });
          this.setState({ isDisabled: false });
          Utils.toastError(error.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "bankDetailsSlideout",
          });
        });
    }
  };

  refreshPage = () => {
    this.props?.PlaiddSetupStatus(this.props?.hotelID, this.props?.bankRefID);
    // this.refreshBankSlidOut();
  };

  refreshBankSlidOut() {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let selectedExistAcc = { ...this.state.selectedExistAcc };
    selectedExistAcc.oprID = this.props.oprID;
    selectedExistAcc.hotelID = this.props?.hotelID;
    selectedExistAcc.tenantID = tenantID;
    selectedExistAcc.obaccno = this.props.accountNo;
    this.props?.bindFormData(selectedExistAcc);
  }

  render() {
    const { step, errors, formData } = this.state;
    // const { errors, formData } = this.state;

    return (
      <div id="" className="digital-ach-add-bank-account">
        <ToastContainer containerId={"bankDetailsSlideout"} autoClose={1500} />
        {!this.state.isDataLoaded ? (
          // <ReactPageLoader useas={"timesheetSlideout"} />
          <></>
        ) : (
          <div className="dwolla-bank-account-setup">
            <div className="setup-header d-flex align-items-center">
              <div className="heading">Add Bank Account</div>
              <div className="steps ml-auto">
                Step {step === "manualVerificationForm" ? "2" : step} of 2
              </div>
            </div>
            {step === "1" && (
              <div className="setup-body">
                <div className="body-heading">Select an Inn-Flow Bank Account </div>
                <div className="body-description">
                  Once configured, you will be able to make payments through Digital ACH from this account for this property.
                </div>
                <div className="body-section">
                  <div className="form-section d-flex flex-row">
                    <Form.Group>
                      <Form.Label>
                        Inn-Flow Bank Account
                      </Form.Label>
                      <div className="singleSearchDropdownListWithIcon">
                        <SingleSearchDropdownList
                          id={"tableAccountName"}
                          //   ref={this.accountName}
                          itemList={this.state.accountNames}
                          handleSelectedItem={this.handleaccountNameSelectedItem.bind(
                            this,
                            1
                          )}
                          defaultItem={this.state.HIDToNM}
                          defaultText={"Search..."}
                          defaultName={this.state.accountName}
                          calHomeIcon={false}
                          currentChecked={this.state.accountName}
                          controlID="2"
                          pageType={"Register"}
                          isStatusbadge={true}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
              </div>
            )}
            {step === "2" && (
              <div className="setup-body">
                <div className="body-heading">Verify Bank Account</div>
                <div className="body-description mb-0">
                  To enable this account to make Digital ACH payments, we need
                  to verify it.
                  <br /><br /> You can verify your account in 2 days: Instantly,
                  using your online banking username and password, or
                  manually, with a routing and account number.
                  <br /><br /> Manual verification can take 1-3 business days to
                  complete and requires placing 2 small deposits in your
                  account.
                </div>
              </div>
            )}
            {step === "manualVerificationForm" && (
              <>
                <div className="setup-body">
                  <div className="body-heading">Manual Verification</div>
                  <div className="body-description">
                    2 deposits of less than $.10 will be made to the account below for verification.
                  </div>
                  <div className="form-section">
                    <div className="body-section d-flex justify-content-between">
                      <Form.Group controlId="BankName">
                        <div onBlur={() => this.validationOnClick(1)}>
                          <Form.Label>Bank Name</Form.Label>
                          <input
                            type="text"
                            className="form-control"
                            id="txtBankName"
                            name="bankName"
                            autoComplete="txtBankName"
                            value={this.state.formData.bankName}
                            disabled={true}
                          />
                        </div>
                      </Form.Group>
                      <Form.Group controlId="accountType">
                        <div
                          className={
                            errors.accountType.length > 0
                              ? "validation-error"
                              : ""
                          }
                        >
                          <Form.Label>Account Type</Form.Label>
                          <DropDownList
                            placeHolder={"Search Account Type"}
                            data={StaticArrays.UserAccountTypes}
                            isSearchRequired={false}
                            defaultValue={this.state.formData.accountType}
                            label={"label"}
                            value={"value"}
                            id="accountType"
                            name="accountType"
                            onDropDownChange={(item) => {
                              if (!item) {
                                return;
                              }

                              this.onFieldChange(
                                Utils.BuildCustomEventObject(
                                  "accountType",
                                  item.value
                                ),
                                3
                              );
                            }}
                            selectedItem={[
                              ...[{ value: "", label: "Select Account Type" }],
                              ...StaticArrays.UserAccountTypes,
                            ].find(
                              (r: any) =>
                                r?.value === this.state.formData.accountType
                            )}
                          />
                          {errors.accountType.length > 0 && (
                            <span className="validation-message">
                              {errors.accountType}
                            </span>
                          )}
                        </div>
                      </Form.Group>
                    </div>
                    <div className="body-section d-flex justify-content-between">
                      <Form.Group controlId="routingNumber">
                        <div
                          className={
                            errors.routingNumber.length > 0
                              ? "validation-error"
                              : ""
                          }
                          onBlur={() => this.validationOnClick(2)}
                        >
                          <Form.Label>Routing Number</Form.Label>
                          <input
                            type="text"
                            className="form-control"
                            id="routingNumber"
                            name="routingNumber"
                            onKeyDown={
                              Utils.AllowOnlyPositiveNumnberInputWithoutDot
                            }
                            autoComplete="routingNumber"
                            onChange={this.handleChange}
                            // disabled={true}
                            value={this.state.formData.routing}
                          />
                          {errors.routingNumber.length > 0 && (
                            <span className="validation-message">
                              {errors.routingNumber}
                            </span>
                          )}
                        </div>
                      </Form.Group>
                      <Form.Group controlId="accountNumber">
                        <div onBlur={() => this.validationOnClick(3)}>
                          <Form.Label>Account Number</Form.Label>
                          <input
                            type="text"
                            className="form-control"
                            id="txtaccountNumber"
                            name="accountNumber"
                            onKeyDown={
                              Utils.AllowOnlyPositiveNumnberInputWithoutDot
                            }
                            autoComplete="txtaccountNumber"
                            value={this.state.formData.obaccno}
                            disabled={true}
                          />
                        </div>
                      </Form.Group>
                    </div>
                    <div className="body-section d-flex justify-content-between">
                      <Form.Group controlId="businessName">
                        <div onBlur={() => this.validationOnClick(4)}>
                          <Form.Label>Business Name</Form.Label>
                          <input
                            type="text"
                            className="form-control"
                            id="txtbusinessName"
                            name="businessName"
                            autoComplete="txtbusinessName"
                            value={this.state.formData.accountName}
                            disabled={true}
                          />
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="setup-footer">
              <div>
                <Button
                  className="btn-ghost"
                  onClick={() => {
                    this.handleCancelSetupAccount(false);
                  }}
                  ref={this.cancelBtnRef}
                >
                  Cancel
                </Button>

                {step === "2" && (
                  <Button
                    className="btn-outline-primary btn btn-primary"
                    onClick={() => {
                      this.handleContinueManualSetup();
                    }}
                  >
                    Verify Manually
                  </Button>
                )}
                {step === "1" && (
                  <Button
                    className="btn btn-primary"
                    onClick={() => this.handleContinue(step)}
                    ref={this.continueBtnRef}
                  >
                  Continue
                  {this.state.spinner && (

                    <Spinner style={{marginLeft:"5px"}} size="sm" animation="border" />
                  )}
                  </Button>
                )}
                 {step === "2" && (
                  <Button
                    className="btn btn-primary"
                    onClick={() => this.handleContinue(step)}
                    ref={this.instantVerfityBtnRef}                    
                  //disabled={}
                  >
                  Verify Instantly
                  {this.state.spinner && (
                    <Spinner style={{marginLeft:"5px"}} size="sm" animation="border" />
                  )}
                  </Button>
                )}
                {step === "manualVerificationForm" && (
                  <Button
                    className="btn btn-primary"
                    onClick={this.addFundingSource}
                    ref={this.submitBtnRef}
                  >
                    Submit
                  </Button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
