import React from "react";
import { Container, Dropdown, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { FiChevronDown } from "react-icons/fi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import ReactTooltip from "react-tooltip";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import OutsideClickHandler from "react-outside-click-handler";
import { resolve, reject } from "q";
import ForecastLock from "../../../../Common/Assets/Images/icons/forecast-lock.svg";
import RouteLeavingGuard from "../../../../Common/Components/RouteLeavingGuard";
import { ConfirmationModal } from "../../../../Common/Components/ConfirmationModal";
import { SingleSearchDropdownList } from "../../../../Common/Components/SingleSearchDropdownList";
import TableSearch from "../../../../Common/Components/TableSearch";
import { HidSelector } from "../../../../Common/Components/HidSelector";
import { Hotel } from "../../../../Common/Services/Hotel";
import { LaborTips } from "../../../../Common/Services/LaborTips";
import { TipsEmployee } from "../../../../Common/Components/TipsEmployee";
import {
  ITipDetailsTempalteRequestDto,
  ITipDetailsDeptPositionRequestDto,
  ITipsEmployeRequestDto,
  ILabourDeleteTipsRequestDto,
  ILabourSaveTipsRequestDto,
  ITipEntryPermission,
} from "../../../../Common/Contracts/ILaborForecast";
import { ReactPageLoader } from "../../../../Common/Components/ReactPageLoader";
import _ from "lodash";

import { Utils } from "../../../../Common/Utilis";
import { TimeClockCommonFunc } from "../../../../WebTimeClock/TimeClockCommonFunc";
import moment from "moment-timezone";

import * as XLSX from 'xlsx';

const tooltipMessage =
  "The Tips cannot be changed because the payroll has already been exported.";
let routeLeavingGuard: any = null;
let calenderMaxDate = new Date();
export class TipEntry extends React.Component<any, any> {
  private newDate: any;
  private tableSearch: any;
  private childHID: any;
  constructor(props: any) {
    super(props);
    this.newDate = React.createRef();
    this.tableSearch = React.createRef();
    this.childHID = React.createRef();
    this.state = {
      loaderTrue: false,
      hidList: [],
      hidValue: "Select",
      hotelName: "Select",
      currentDate: "",
      calenderSelectDate: {},
      navigationCalenderDates: [],
      laborMgmtMinDate: {},
      laborMgmtMaxDate: {},
      positionList: [],
      departmentList: [],
      positionName: "",
      departmentName: "",
      tableData: [],
      calenderShow: false,
      isPageLock: false,
      tableRowDates: [],
      tableDataFilter: [],
      tipsEmployeeList: [],
      tipColumns_cashTips: "",
      tipColumns_chargedTips: "",
      tipColumns_tipsPaidOut: "",
      isNewTip: false,
      pageHasError: false,
      rowEditNo: null,
      isEnterClick: false,
      selectedDepartment: 0,
      selectedPosition: 0,
      tipsRowNo: "",
      rowToSave: {},
      isTipPermission: false,
      initialTableData: [],
    };
  }

  componentDidMount = () => {
    this.getTipEntryPermission();
    sessionStorage.removeItem("hotelSelected");
    localStorage.removeItem("hotelSelected");
    window.addEventListener("beforeunload", this.beforeunload.bind(this));
    let currentdate = "";
    let currentMonth = "";
    let currentYear = "";
    let todayDate: any = new Date();
    if (JSON.parse(localStorage.storage).currentUtcTime !== "" && JSON.parse(localStorage.storage).currentUtcTime !== null && JSON.parse(localStorage.storage).currentUtcTime !== undefined) {
      todayDate = new Date(
        JSON.parse(localStorage.storage).currentUtcTime
      );
    }
    calenderMaxDate = JSON.parse(localStorage.storage).currentUtcTime !== "" ? new Date(JSON.parse(localStorage.storage).currentUtcTime) : new Date();
    currentdate = todayDate.getDate();
    currentMonth = todayDate.getMonth() + 1;
    currentYear = todayDate.getFullYear();
    let tempDate = currentMonth + "/" + currentdate + "/" + currentYear;
    this.setState({ currentDate: tempDate, calenderSelectDate: todayDate });
  };

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.beforeunload.bind(this));
  }

  beforeunload(e: { preventDefault: () => void; returnValue: boolean }) {
    if (
      (this.state.isNewTip || this.state.pageHasError) &&
      routeLeavingGuard !== "routeLeavingGuardTrue"
    ) {
      e.preventDefault();
      e.returnValue = true;
    }
  }

  routeLeavingGuardConfirm = (childState: any) => {
    if (childState === true) {
      routeLeavingGuard = "routeLeavingGuardTrue";
    } else {
      routeLeavingGuard = null;
    }
  };

  getTipEntryPermission = () => {
    let requestObject = {} as ITipEntryPermission;
    requestObject.permissionID = 10009;
    requestObject.Module = "LaborManagement";
    requestObject.Type = "page";

    Hotel.GetTipEntryPermissionData(requestObject)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          result.forEach((element: any) => {
            if (
              element.permissionName === "Manage Tips" ||
              element.permissionID === "10026"
            ) {
              this.setState({ isTipPermission: true });
            }
          });
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
        });
        reject();
      });
  };

  selectHotelDropDown = (hotelDetails: any) => {
    let todayDate: any = new Date();
    if (JSON.parse(localStorage.storage).currentUtcTime !== "" && JSON.parse(localStorage.storage).currentUtcTime !== null && JSON.parse(localStorage.storage).currentUtcTime !== undefined) {
      todayDate = new Date(
        JSON.parse(localStorage.storage).currentUtcTime
      );
    }
    this.setState(
      {
        hotelName: hotelDetails.lettercode,
        hidValue: hotelDetails.hotelID,
        calenderSelectDate: todayDate,
        isEnterClick: false,
        departmentList: [],
        positionList: [],
        selectedPosition: 0,
        selectedDepartment: 0,
      },
      () => {
        let request = {} as ITipDetailsTempalteRequestDto;
        let request2 = {} as ITipDetailsDeptPositionRequestDto;
        request.userType = "All";
        request.hotelID = this.state.hidValue;
        request.date = this.state.currentDate;
        request.PageType = "Tips";
        ;
        this.getTipsEntryData(request, "ehidDropdown");
        request2.hotelID = this.state.hidValue;
        this.getPostionDepartment(request2);
      }
    );
  };

  getPostionDepartment = (request: ITipDetailsDeptPositionRequestDto) => {
    LaborTips.GetLaborPositionDept(request)
      .then(async (result: any) => {
        if (result.message === "Success") {
          let positonDetails: any = [{ id: -1, name: "All" }];
          let departMentDetails: any = [{ id: -1, name: "All" }];
          result.result.forEach((res: any) => {
            let positonObject: any = {};
            positonObject.id = res.positionID;
            positonObject.name = res.position;
            let isExistPosition = positonDetails.filter(
              (item: any) => item.id === res.positionID
            );
            if (isExistPosition.length === 0) {
              positonDetails.push(positonObject);
            }
            let departMentObject: any = {};
            departMentObject.id = res.deptID;
            departMentObject.name = res.department;
            let isExistDepartment = departMentDetails.filter(
              (item: any) => item.id === res.deptID
            );
            if (isExistDepartment.length === 0) {
              departMentDetails.push(departMentObject);
            }
          });

          this.setState({
            positionList: positonDetails,
            departmentList: departMentDetails,
          });
        }
        resolve();
      })
      .catch((error) => {
        Utils.toastError(`Server Error: ${error}`, {
        });
        reject();
      });
  };

  getTipsEntryData = (request: ITipDetailsTempalteRequestDto, type: string) => {
    if (type === "calenderChange" || type === "ehidDropdown") {
      this.setState({ loaderTrue: true });
    }

    LaborTips.GetLaborTipsData(request)
      .then(async (result: any) => {
        if (result.result.length > 0) {
          let payPeriodDate: any = [];
          let tempTableRowDate: any = [];
          let tempPageLock: any;
          let data: any = [];
          let laborMgmtMinDate = {};
          let laborMgmtMaxDate = {};
          let periodStartDate: any;
          let tipColumns: any = [];
          let currentServerDate: any = "";
          for (const element of result.result) {
            if (element.username !== "") {
              element.old_Date = element.date;
              element.old_userUniqueno = element.userUniqueno;
              element.cashTips = Utils.currencyFormatWithOutdoller(element.cashTips);
              element.chargedTips = Utils.currencyFormatWithOutdoller(element.chargedTips);
              element.tipsPaidOut = Utils.currencyFormatWithOutdoller(element.tipsPaidOut);
              element.total = Utils.currencyFormatWithOutdoller(element.total);
              element.status = "update";
              element.blockEdit = false;
              element.dateValidation = false;
              element.userValidation = false;
              element.cashTipRedBox = false;
              element.cashTipBlueSpan = false;
              element.cashTipRedSpan = false;
              element.chargedTipRedBox = false;
              element.chargedTipRedSpan = false;
              element.tipsPaidRedBox = false;
              element.tipsPaidRedSpan = false;
              element.action = "refreshTable";

              data.push(element);
            }
            debugger;
            let displayDates: any = element.payPeriodDate.split("-");
            payPeriodDate = displayDates;
            if (element.isLockPeriod === "No") {
              tempPageLock = false;
            } else {
              tempPageLock = true;
            }
            periodStartDate = element.payPeriodStart;
            tipColumns = element.tipColumn;
            currentServerDate = element.currentdate;
          }
          laborMgmtMinDate = new Date(periodStartDate);
          let todayDate: any = new Date();
          if (JSON.parse(localStorage.storage).currentUtcTime !== "" && JSON.parse(localStorage.storage).currentUtcTime !== null && JSON.parse(localStorage.storage).currentUtcTime !== undefined) {
            todayDate = new Date(
              JSON.parse(localStorage.storage).currentUtcTime
            );
          }
          laborMgmtMaxDate = todayDate;

          let tempTipsColumn = tipColumns.split(",");
          let tipColumns_cashTips = tempTipsColumn[0];
          let tipColumns_chargedTips = tempTipsColumn[1];
          let tipColumns_tipsPaidOut = tempTipsColumn[2];

          let startDate: any = payPeriodDate[0];
          for (let i = 0; i < 7; i++) {
            let currentDate: any = new Date(startDate);
            currentDate.setDate(currentDate.getDate() + i);

            let mnths: any = new Date(currentDate).getMonth() + 1;
            let days: any = new Date(currentDate).getDate();
            let year: any = new Date(currentDate)
              .getFullYear()
              .toString()
              .substring(2, 4);

            if (mnths.toString().length === 1) {
              mnths = "0" + mnths;
            }
            if (days.toString().length === 1) {
              days = "0" + days;
            }

            let tempdate1 = `${mnths}/${days}/${year}`;
            let tempdateCompartion: any = new Date(tempdate1);
            let serverComparionDate: any = new Date(currentServerDate);

            if (tempdateCompartion <= serverComparionDate) {
              tempTableRowDate.push(tempdate1);
            }
          }

          data = data.map((row) => {
            row.cashTipsref = React.createRef();
            row.chargedTipsref = React.createRef();
            row.tipsPaidOutref = React.createRef();
            return row;
          });

          this.setState(
            {
              tableData: data,
              initialTableData: data,
              tableDataFilter: data,
              navigationCalenderDates: payPeriodDate,
              tableRowDates: tempTableRowDate,
              isPageLock: tempPageLock,
              isNewTip: false,
              laborMgmtMinDate: laborMgmtMinDate,
              laborMgmtMaxDate: laborMgmtMaxDate,
              tipColumns_cashTips: tipColumns_cashTips,
              tipColumns_chargedTips: tipColumns_chargedTips,
              tipColumns_tipsPaidOut: tipColumns_tipsPaidOut,
              tipsRowNo: "",
              rowToSave: {},
            },
            () => {
              let request = {} as ITipsEmployeRequestDto;
              request.hotelID = this.state.hidValue;
              request.payPeriodStartDate = this.state.navigationCalenderDates[0];
              request.payPeriodEndDate = this.state.navigationCalenderDates[1];
              this.getTipsEmployeeData(request);
            }
          );

          this.FilterByDropDownList(
            this.state.selectedPosition,
            this.state.selectedDepartment
          );
          if (this.state.isEnterClick) {
            this.addTips();
          }
        }
        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error: any) => {
        this.setState({ loaderTrue: false });
        Utils.toastError(`Server Error: ${error}`, {
        });
        reject();
      });
  };

  getTipsEmployeeData = (request: ITipsEmployeRequestDto) => {

    LaborTips.GetLaborEmployeeTipsData(request)
      .then(async (result: any) => {
        ;
        this.setState({ tipsEmployeeList: result.result });
        resolve();
      })
      .catch((error: any) => {
        Utils.toastError(`Server Error: ${error}`, {
        });
        reject();
      });
  };

  calenderChange = (e: any) => {
    let calenderYear = e.getFullYear();
    let calenderDate = e.getDate();
    let calenderMonth = e.getMonth() + 1;
    let tempDate = calenderMonth + "/" + calenderDate + "/" + calenderYear;

    let request = {} as ITipDetailsTempalteRequestDto;
    request.userType = "All";
    request.hotelID = this.state.hidValue;
    request.date = tempDate;
    request.PageType = "Tips";
    this.getTipsEntryData(request, "calenderChange");
    
    this.setState({
      calenderSelectDate: e,
      calenderShow: false,
      isEnterClick: false,
    });
  };

  calenderShow = () => {
    this.setState({ calenderShow: true });
  };

  calenderHide = () => {
    this.setState({ calenderShow: false });
  };

  savDatafinal = () => {
    if (
      !_.isEqual(
        this.state.initialTableData.map((r) => {
          const newRow: any = {};
          newRow.cashTips = r.cashTips;
          newRow.chargedTips = r.chargedTips;
          newRow.tipsPaidOut = r.tipsPaidOut;
          return newRow;
        }),
        this.state.tableData.map((r) => {
          const newRow: any = {};
          newRow.cashTips = r.cashTips;
          newRow.chargedTips = r.chargedTips;
          newRow.tipsPaidOut = r.tipsPaidOut;
          return newRow;
        })
      )
    ) {
      this.tableOutClickHandler();
    }
  };

  tableOutClickHandler = () => {
    let saveRow = this.state.rowToSave;
    let saveRowLength = Object.keys(saveRow).length;
    let data = this.state.tableData;
    let newRow = data[data.length - 1];

    if (
      saveRowLength !== 0 &&
      Number(saveRow.total) > 0 &&
      saveRow.username !== "" &&
      saveRow.date !== ""
    ) {
      this.handleSave(saveRow);
      this.setState({ pageHasError: false });
      return;
    }

    if (saveRowLength !== 0 && Number(saveRow.total) <= 0) {
      let newTableData = [...this.state.tableData];
      newTableData = newTableData.map((d) => {
        if (d.rowNo !== saveRow.rowNo) {
          return {
            ...d,
            blockEdit: true,
          };
        } else {
          if (saveRow.date === "") {
            return {
              ...d,
              action: "",
              dateValidation: true,
              userValidation: true,
              cashTipRedBox: true,
              cashTipBlueSpan: false,
              cashTipRedSpan: false,
              chargedTipRedBox: true,
              chargedTipRedSpan: false,
              tipsPaidRedBox: true,
              tipsPaidRedSpan: false,
            };
          } else if (saveRow.username === "") {
            return {
              ...d,
              action: "",
              dateValidation: false,
              userValidation: true,
              cashTipRedBox: true,
              cashTipBlueSpan: false,
              cashTipRedSpan: false,
              chargedTipRedBox: true,
              chargedTipRedSpan: false,
              tipsPaidRedBox: true,
              tipsPaidRedSpan: false,
            };
          } else {
            if (
              Number(saveRow.cashTips) +
              Number(saveRow.chargedTips) +
              Number(saveRow.tipsPaidOut) ===
              0
            ) {
              return {
                ...d,
                cashTipRedBox: true,
                cashTipRedSpan: true,
                chargedTipRedBox: true,
                tipsPaidRedBox: true,
              };
            } else
              return {
                ...d,
                cashTipRedBox: false,
                chargedTipRedBox: false,
                chargedTipRedSpan: false,
                tipsPaidRedBox: false,
              };
          }
        }
      });
      this.setState((curr: any) => ({ ...curr, tableData: newTableData }));
      this.setState({ pageHasError: true });
      return;
    }

    if (this.state.isNewTip) {

      if (saveRowLength === 0 && newRow.status === "new") {
        let newTableData = [...this.state.tableData];
        newTableData = newTableData.map((d) => {
          if (d.rowNo !== newRow.rowNo) {
            return {
              ...d,
              blockEdit: true,
            };
          } else {
            if (newRow.date === "") {
              return {
                ...d,
                action: "",
                dateValidation: true,
                userValidation: true,
                cashTipRedBox: true,
                cashTipBlueSpan: false,
                cashTipRedSpan: false,
                chargedTipRedBox: true,
                chargedTipRedSpan: false,
                tipsPaidRedBox: true,
                tipsPaidRedSpan: false,
              };
            } else if (newRow.username === "") {
              return {
                ...d,
                action: "",
                dateValidation: false,
                userValidation: true,
                cashTipRedBox: true,
                cashTipBlueSpan: false,
                cashTipRedSpan: false,
                chargedTipRedBox: true,
                chargedTipRedSpan: false,
                tipsPaidRedBox: true,
                tipsPaidRedSpan: false,
              };
            } else {
              return {
                ...d,
                action: "",
                dateValidation: false,
                userValidation: false,
                cashTipRedBox: true,
                cashTipBlueSpan: false,
                cashTipRedSpan: true,
                chargedTipRedBox: true,
                chargedTipRedSpan: false,
                tipsPaidRedBox: true,
                tipsPaidRedSpan: false,
              };
            }
          }
        });
        this.setState((curr: any) => ({ ...curr, tableData: newTableData }));
        this.setState({ pageHasError: true });
        return;
      }
    }
  };

  handleSelectedItem = (controlID: any, id: any) => {
    if (controlID === 1) {
      this.setState({ selectedPosition: id });
      this.FilterByDropDownList(id, this.state.selectedDepartment);
    } else {
      this.setState({ selectedDepartment: id });
      this.FilterByDropDownList(this.state.selectedPosition, id);
    }
  };

  FilterByDropDownList(positionId: number, departmentId: number) {
    let tipsSummery: any[] = [];
    tipsSummery = _.cloneDeep(this.state.tableDataFilter);
    if (positionId > 0) {
      tipsSummery = tipsSummery.filter(
        (item) => item.positionID === positionId
      );
      this.setState({ tableData: tipsSummery });
    }
    if (departmentId > 0) {
      tipsSummery = tipsSummery.filter((item) => item.deptID === departmentId);
      this.setState({ tableData: tipsSummery });
    }
    if (positionId <= 0 && departmentId <= 0) {
      this.setState(prevState => ({
        tableData: prevState.tableDataFilter
      }));      
    }
  }

  addTips = () => {
    let tableData = [...this.state.tableData];
    const item = {
      action: "selectDate",
      cashTips: "0.00",
      cashTipsID: 5,
      chargedTips: "0.00",
      chargedTipsID: 9,
      date: "",
      department: "",
      deptID: -2,
      isLockPeriod: "No",
      payPeriodDate: "",
      position: "",
      positionID: -2,
      rowNo:
        tableData.length > 0
          ? Number(tableData[tableData.length - 1].rowNo) + 1
          : 1,
      tenantid: -1,
      tipsPaidID: 17,
      tipsPaidOut: "0.00",
      total: "0.00",
      userUniqueno: -1,
      username: "",
      status: "new",
      blockEdit: false,
      dateValidation: false,
      userValidation: false,
      cashTipRedBox: false,
      cashTipBlueSpan: false,
      cashTipRedSpan: false,
      chargedTipRedBox: false,
      chargedTipRedSpan: false,
      tipsPaidRedBox: false,
      tipsPaidRedSpan: false,
      cashTipsref: React.createRef(),
      chargedTipsref: React.createRef(),
      tipsPaidOutref: React.createRef(),
    };
    this.setState(
      prevState => ({
        tableData: [...prevState.tableData, item],
        isNewTip: true,
        isEnterClick: false,
      }),
      () => {
        this.newDate.current.scrollIntoView();
        let newTableData = [...this.state.tableData];
        let data = this.state.tableData;
        let row = data[data.length - 1];
        newTableData = newTableData.map((d) => {
          if (d.rowNo !== row.rowNo) {
            return {
              ...d,
              blockEdit: true,
            };
          }
          return d;
        });
        this.setState((curr: any) => ({ ...curr, tableData: newTableData }));
      }
    );
  };

  selectDateDropDown = (item: any, row: any) => {
    let newTableData = [...this.state.tableData];
    row.rowNo = newTableData.length
    newTableData = newTableData.map((d) => {
      if (d.rowNo === row.rowNo) {
        return {
          ...d,
          date: item,
          action: "selectUsername",
          dateValidation: false,
        };
      } 
      return d;
    });
    this.setState((curr: any) => ({ ...curr, tableData: newTableData }));
  };

  employeeSelected = (item: any, row: any) => {
    let newTableData = [...this.state.tableData];
    newTableData = newTableData.map((d) => {
      if (d.rowNo === row.rowNo) {
        return {
          ...d,
          action: "",
          position: item.positionName,
          userUniqueno: item.userUniqueno,
          username: item.employee,
          userValidation: false,
          cashTipBlueSpan: true,
        };
      } else {
        return d;
      }
    });
    this.setState((curr: any) => ({ ...curr, tableData: newTableData }));
  };

  removeNewRow = (row: any, rowIndex: any) => {
    ;
    let data: any = [];

    data = Object.assign([], this.state.tableData);
    data.splice(-1, 1);
    this.setState(
      {
        tableData: data,
        isNewTip: false,
        pageHasError: false,
        rowToSave: {},
        isEnterClick: false,
      },
      () => {
        this.setState({ pageHasError: false });
        let newTableData = [...data];
        newTableData = newTableData.map((d) => {
          return {
            ...d,
            blockEdit: false,
          };
        });
        this.setState((curr: any) => ({ ...curr, tableData: newTableData }));
      }
    );
  };

  ishandleDeletePosition = (row: any, mode: any) => {
    if (mode) {
      let request = {} as ILabourDeleteTipsRequestDto;
      request.Date = row.date;
      request.UserUniqueno = Number(row.userUniqueno);
      request.Employee = row.username;
      request.Hid = this.state.hidValue;
      request.Datefrom = this.state.navigationCalenderDates[0];
      request.Dateto = this.state.navigationCalenderDates[1];
      request.allTipsID = "5_9_17";
      LaborTips.deleteLaborTips(request)
        .then(async (result: any) => {
          if (result.result.saveStatus === "Success") {
            const data = this.state.tableData.filter((item) => item.rowNo !== row.rowNo);
            this.setState(
              {
                tableData: data,
                tableDataFilter: data,
                isNewTip: false,
                pageHasError: false,
                rowToSave: {},
                isEnterClick: false,
              },
              () => {
                let newTableData = [...data];
                newTableData = newTableData.map((d) => {
                  return {
                    ...d,
                    blockEdit: false,
                  };
                });
                this.setState((curr: any) => ({
                  ...curr,
                  tableData: newTableData,
                }));
              }
            );
            Utils.toastError("Tip deleted successfully", {
            });
          }
          resolve();
        })
        .catch((error: any) => {
          Utils.toastError(`Server Error: ${error}`, {
          });
          reject();
        });
    }
  };

  handleSave = (row: any) => {
    debugger;
    let request = {} as ILabourSaveTipsRequestDto;

    const storage = JSON.parse(localStorage.getItem("storage")!);
    const tenantID = storage === null ? 0 : (storage.tenantID as any);
    let userName = storage === null ? 0 : (storage.userName as any);

    request.Tenantid = tenantID;
    request.loginUser = userName;
    request.Date = row.date;
    request.old_Date = row.old_Date;
    request.UserUniqueno = row.userUniqueno;
    request.old_UserUniqueno = row.old_userUniqueno;
    request.Employee = row.username;
    request.CashTipID = Number(row.cashTipsID);
    request.CashTipValue = Number(Utils.removeInvaildCharFromAmount(row.cashTips));
    request.CatTipID = Number(row.chargedTipsID);
    request.CatTipValue = Number(Utils.removeInvaildCharFromAmount(row.chargedTips));
    request.PcTipID = Number(row.tipsPaidID);
    request.PcTipValue = Number(Utils.removeInvaildCharFromAmount(row.tipsPaidOut));
    request.Hid = this.state.hidValue;
    request.Datefrom = this.state.navigationCalenderDates[0];
    request.Dateto = this.state.navigationCalenderDates[1];
    request.entryType = row.status;
    request.TipPageSource = "Tips";

    let requestArray: any = [];
    requestArray.push(request);

    LaborTips.saveLaborTips(requestArray)
      .then(async (result: any) => {
        if (result.result.saveStatus === "Success") {
          this.setState({ rowToSave: {} });
          let request = {} as ITipDetailsTempalteRequestDto;

          let todayDate: any = new Date(this.state.calenderSelectDate);
          let currentdate = todayDate.getDate();
          let currentMonth = todayDate.getMonth() + 1;
          let currentYear = todayDate.getFullYear();
          let tempDate = currentMonth + "/" + currentdate + "/" + currentYear;
          request.userType = "All";
          request.hotelID = this.state.hidValue;
          request.date = tempDate;
          request.PageType = "Tips";
          this.getTipsEntryData(request, "saveTip");
          toast.success("Tip saved successfully", {
          });
        } else {
          if (result.result.message === "Already Exist") {
            let newTableData = [...this.state.tableData];
            newTableData = newTableData.map((d) => {
              if (d.rowNo !== row.rowNo) {
                return {
                  ...d,
                  blockEdit: true,
                };
              } else {
                return {
                  ...d,
                  userValidation: true,
                  dateValidation: true,
                  cashTipRedBox: true,
                  chargedTipRedBox: true,
                  tipsPaidRedBox: true,
                };
              }
            });
            this.setState((curr: any) => ({
              ...curr,
              tableData: newTableData,
            }));
            
            this.setState(prevState => ({
              tableDataFilter: prevState.tableData,
              pageHasError: true
            }));

            Utils.toastError("Employee already exists for selected date", {
            });
          }
        }
        resolve();
      })
      .catch((error: any) => {
        Utils.toastError(`Server Error: ${error}`, {
        });
        reject();
      });
  };

  formatNewValue = (value: any) => {
    let newValue = Utils.removecurrencyFormat(value);

    if (newValue.includes(".")) {
      debugger;
      const timeArray = (newValue)?.split(".");
      if (timeArray[1].length <= 0) {
        newValue = Utils.currencyFormatWithOutdoller(timeArray[0]); 
      }
      else {
        newValue = Utils.currencyFormatWithOutdoller(newValue);
      }
    } else {
      newValue = Utils.currencyFormatWithOutdoller(newValue);
    }



    return newValue;
  };

  formatInput = (e: any, row: any, rowIndex: any, fieldName: any) => {
    debugger;
    this.setState({ rowEditNo: rowIndex });
    let newValue = this.formatNewValue(e.target.value);
    let newTableData = [...this.state.tableData];

    let rowtotal = (
      Number(Utils.removeInvaildCharFromAmount(row.cashTips)) +
      Number(Utils.removeInvaildCharFromAmount(row.chargedTips)) +
      Number(Utils.removeInvaildCharFromAmount(row.tipsPaidOut))
    ).toFixed(2);
    newTableData = newTableData.map((d) => {
      if (d.rowNo === row.rowNo) {
        if (+rowtotal === 0) {
          return {
            ...d,
            [fieldName]: newValue,
            cashTipRedBox: true,
            cashTipBlueSpan: false,
            cashTipRedSpan: true,
            chargedTipRedBox: true,
            chargedTipRedSpan: true,
            tipsPaidRedBox: true,
            tipsPaidRedSpan: true,
            total: Utils.currencyFormatWithOutdoller(rowtotal),
          };
        } else {
          return {
            ...d,
            [fieldName]: newValue,
            cashTipRedBox: false,
            cashTipBlueSpan: false,
            cashTipRedSpan: false,
            chargedTipRedBox: false,
            chargedTipRedSpan: false,
            tipsPaidRedBox: false,
            tipsPaidRedSpan: false,
            total: Utils.currencyFormatWithOutdoller(rowtotal),
          };
        }
      } else {
        return {
          ...d,
          cashTipRedBox: false,
          cashTipBlueSpan: false,
          cashTipRedSpan: false,
          chargedTipRedBox: false,
          chargedTipRedSpan: false,
          tipsPaidRedBox: false,
          tipsPaidRedSpan: false,
        };
      }
    });

    this.setState(
      (curr: any) => ({ ...curr, tableData: newTableData }),
      () => {
        let column = { dataField: fieldName };
        this.afterSaveCell(newValue, row, column);
      }
    );
  };

  onTipChange = (e: any, row: any, rowIndex: any, fieldName: any, txtID?: string) => {
    debugger;
    let start = 0;
    let end = 0;
    this.setState({ rowEditNo: rowIndex });
    let value = Utils.removeInvaildCharFromAmount(e.target.value);
    e.target.value = value;
    start = e.target.selectionStart;
    end = e.target.selectionEnd;

    const rx_live = /^[0-9]*([.][0-9]?[0-9]?)?$/gm;
    let lenbeforePoint = 8;
    if (rx_live.test(e.target.value)) {
      if (e.target.value.includes(".")) {
        const timeArray = (e.target.value)?.split(".");
        if (timeArray[0].length <= lenbeforePoint && timeArray[1].length <= 2) {
          value = e.target.value;
        }
      } else {
        if (e.target.value.length <= lenbeforePoint) {
          value = e.target.value;
        }
      }
    } else if (e.target.value.includes(".") && e.target.value.length === 1) {
      value = e.target.value;
    }else {
      return false;
    }

    e.target.value = value;







    let newValue = e.target.value === "" ? "0.00" : e.target.value;
    let newTableData = [...this.state.tableData];
    newTableData = newTableData.map((d) => {
      if (d.rowNo === row.rowNo) {
        debugger;
        row[fieldName] = newValue;
        let rowtotal = (
          Number(Utils.removeInvaildCharFromAmount(row.cashTips)) +
          Number(Utils.removeInvaildCharFromAmount(row.chargedTips)) +
          Number(Utils.removeInvaildCharFromAmount(row.tipsPaidOut))
        )
          .toFixed(2)
          .toString();

        if (+rowtotal > 0) {
          return {
            ...d,
            [fieldName]: newValue,
            cashTipRedBox: false,
            cashTipBlueSpan: false,
            cashTipRedSpan: false,
            chargedTipRedBox: false,
            chargedTipRedSpan: false,
            tipsPaidRedBox: false,
            tipsPaidRedSpan: false,
            total: Utils.currencyFormatWithOutdoller(rowtotal),
          };
        } else {
          return {
            ...d,
            [fieldName]: newValue,
            cashTipRedBox: true,
            cashTipBlueSpan: false,
            cashTipRedSpan: true,
            chargedTipRedBox: true,
            chargedTipRedSpan: false,
            tipsPaidRedBox: true,
            tipsPaidRedSpan: false,
            total: Utils.currencyFormatWithOutdoller(rowtotal),
          };
        }
      } 
      return d;
    });

    this.setState(
      (curr: any) => ({ ...curr, tableData: newTableData }),
      () => {
        if (fieldName === "cashTips") {
          if (row.cashTipsref?.current) {
            row.cashTipsref.current.focus();
          }
        }
        if (fieldName === "chargedTips") {
          if (row.chargedTipsref?.current) {
            row.chargedTipsref.current.focus();
          }
        }
        if (fieldName === "tipsPaidOut") {
          if (row.tipsPaidOutref?.current) {
            row.tipsPaidOutref.current.focus();
          }
        }

        let txtamt = $("#tableSearchNew").find("input[id^='" + txtID + "']")[0] as any;
        txtamt.selectionStart = start;
        txtamt.selectionEnd = end;
      }
    );
  };

  saveOnKeyPress = (e: any) => {
    if (e.keyCode === 9) {
      this.savDatafinal();
    }
  };

  rowEvents = {
    onKeyPress: (e: any) => {
      let data = this.state.tableData.length;
      let index = e.currentTarget.rowIndex;

      if (this.state.isNewTip) {
        if (e.charCode === 13 && data === index) {
          this.setState({ isEnterClick: true });
        }
      }
    },
  };

  CleanSearch = () => {
    this.tableSearch.current.CleanSearch();
    setTimeout(() => {
      this.addTips();
    }, 200);
  };

  afterSaveCell = (newValue: any, row: any, column: any) => {
    if (
      column.dataField === "cashTips" ||
      column.dataField === "chargedTips" ||
      column.dataField === "tipsPaidOut"
    ) {
      row.action = "";
      row.cashTipRedBox = false;
      row.cashTipBlueSpan = false;
      row.cashTipRedSpan = false;
      row.chargedTipRedBox = false;
      row.chargedTipRedSpan = false;
      row.tipsPaidRedBox = false;
      row.tipsPaidRedSpan = false;
      if (newValue === "") {
        if (column.dataField === "cashTips") {
          row.cashTips = "0.00";
        }
        if (column.dataField === "chargedTips") {
          row.chargedTips = "0.00";
        }
        if (column.dataField === "tipsPaidOut") {
          row.tipsPaidOut = "0.00";
        }
      } else {
        if (!newValue.includes(".")) {
          if (column.dataField === "cashTips") {
            row.cashTips = `${newValue}.00`;
          }
          if (column.dataField === "chargedTips") {
            row.chargedTips = `${newValue}.00`;
          }
          if (column.dataField === "tipsPaidOut") {
            row.tipsPaidOut = `${newValue}.00`;
          }
        } else {
          let trimValue = newValue.split(".");
          if (column.dataField === "cashTips") {
            if (trimValue[1].length === 0) {
              if (trimValue[0].length === 0) {
                row.cashTips = `0.00`;
              } else {
                row.cashTips = `${trimValue[0]}.00`;
              }
            } else if (trimValue[1].length === 1) {
              if (trimValue[0].length === 0) {
                row.cashTips = `0.${trimValue[1]}0`;
              } else {
                row.cashTips = `${trimValue[0]}.${trimValue[1]}0`;
              }
            } else {
              if (trimValue[0].length === 0) {
                row.cashTips = `0.${trimValue[1]}`;
              }
            }
          }
          if (column.dataField === "chargedTips") {
            if (trimValue[1].length === 0) {
              if (trimValue[0].length === 0) {
                row.chargedTips = `0.00`;
              } else {
                row.chargedTips = `${trimValue[0]}.00`;
              }
            } else if (trimValue[1].length === 1) {
              if (trimValue[0].length === 0) {
                row.chargedTips = `0.${trimValue[1]}0`;
              } else {
                row.chargedTips = `${trimValue[0]}.${trimValue[1]}0`;
              }
            } else {
              if (trimValue[0].length === 0) {
                row.chargedTips = `0.${trimValue[1]}`;
              }
            }
          }
          if (column.dataField === "tipsPaidOut") {
            if (trimValue[1].length === 0) {
              if (trimValue[0].length === 0) {
                row.tipsPaidOut = `0.00`;
              } else {
                row.tipsPaidOut = `${trimValue[0]}.00`;
              }
            } else if (trimValue[1].length === 1) {
              if (trimValue[0].length === 0) {
                row.tipsPaidOut = `0.${trimValue[1]}0`;
              } else {
                row.tipsPaidOut = `${trimValue[0]}.${trimValue[1]}0`;
              }
            } else {
              if (trimValue[0].length === 0) {
                row.tipsPaidOut = `0.${trimValue[1]}`;
              }
            }
          }
        }
      }
    }

    row.total = (
      Number(Utils.removeInvaildCharFromAmount(row.cashTips)) +
      Number(Utils.removeInvaildCharFromAmount(row.chargedTips)) +
      Number(Utils.removeInvaildCharFromAmount(row.tipsPaidOut))
    )
      .toFixed(2)
      .toString();

    let newTableData = [...this.state.tableData];
    newTableData = newTableData.map((d) => {
      if (d.rowNo !== row.rowNo) {
        return {
          ...d,
          blockEdit: true,
        };
      }
      return d;
    });
    this.setState((curr: any) => ({
      ...curr,
      tableData: newTableData,
    }));

    this.setState({ rowToSave: row });
    debugger;
    if (
      this.state.isEnterClick &&
      Number(row.total) > 0 &&
      row.username !== "" &&
      row.date !== ""
    ) {
      this.handleSave(row);
    }

    setTimeout(() => {
      if (Number(row.total) > 0) {

        this.setState({ pageHasError: false, rowEditNo: null }, () => {
        });
      } else {

        this.setState({ pageHasError: true, rowEditNo: null }, () => {
        });
      }
    }, 100);
  };

  headerFormatter = (column: any, colIndex: any, row: any) => {
    if (column.dataField === "cashTips") {
      return (
        <div>
          <EllipsisWithTooltip placement="bottom">
            <span>{this.state.tipColumns_cashTips}</span>
          </EllipsisWithTooltip>
        </div>
      );
    } else if (column.dataField === "chargedTips") {
      return (
        <div>
          <EllipsisWithTooltip placement="bottom">
            <span>{this.state.tipColumns_chargedTips}</span>
          </EllipsisWithTooltip>
        </div>
      );
    } else if (column.dataField === "tipsPaidOut") {
      return (
        <div>
          <EllipsisWithTooltip placement="bottom">
            <span>{this.state.tipColumns_tipsPaidOut}</span>
          </EllipsisWithTooltip>
        </div>
      );
    }
  };

  onAmountFocus = (e: any, row: any, rowIndex: any, fieldName: any) => {

    let newval = Utils.removecurrencyFormat(e.target.value);
    let newTableData = [...this.state.tableData];

    newTableData = newTableData.map((d) => {

      if (d.rowNo === row.rowNo) {
        row[fieldName] = newval;

      }
      return d;
    });

    this.setState(
      (curr: any) => ({ ...curr, tableData: newTableData }),
      () => {

        if (fieldName === "cashTips") {
          if (row.cashTipsref?.current) {
            row.cashTipsref.current.focus();
          }
        }
        if (fieldName === "chargedTips") {
          if (row.chargedTipsref?.current) {
            row.chargedTipsref.current.focus();
          }
        }
        if (fieldName === "tipsPaidOut") {
          if (row.tipsPaidOutref?.current) {
            row.tipsPaidOutref.current.focus();
          }
        }
      }
    );
  }

  getTipEntryExcelFileName = () => {
    console.log("this",this.state);
    console.log("localStorage",localStorage)
    
    let fileName = "Tips";
    if (this.state.hidTimeZone) {
      let timeZoneValue = TimeClockCommonFunc.getCurrentTimeZone(
        this.state.hidTimeZone
      );
      let zoneDate: any = moment.tz(timeZoneValue).format("DD");
      let zoneMonth: any = moment.tz(timeZoneValue).format("MM");
      let zoneYear: any = moment.tz(timeZoneValue).format("YYYY");
      fileName = fileName + zoneMonth + "_" + zoneDate + "_" + zoneYear;
    } else {
      fileName = fileName + "-" + this.state.hotelName 
      + " (" + this.state.navigationCalenderDates[0] + " - " + this.state.navigationCalenderDates[1] + ")"
    }

    return fileName;
  };

DownloadExcelClick = (e: any) => {
  let tableDatas: any[] = [];
  if(this.tableSearch.current.props.searchText !== ""){
    const searchText = this.tableSearch.current.props.searchText.toLowerCase();
    tableDatas = this.state.tableData.filter(datas => {
      return datas.username.toLowerCase().includes(searchText);
    })
  }
  else{
    tableDatas = this.state.tableData;
  }
  const selectedColumns = tableDatas.map(entry => {
    const Date = 'Date' 
    const Employee = "Employee"
    const CashTips = this.state.tipColumns_cashTips
    const PaycheckTips = this.state.tipColumns_chargedTips
    const CashTips2 =  this.state.tipColumns_tipsPaidOut
    const Total = "Total"

    return {
      [Date]: entry.date,
      [Employee] : entry.username,
      [CashTips]: entry.cashTips,
      [PaycheckTips]: entry.chargedTips,
      [CashTips2]: entry.tipsPaidOut,
      [Total]: entry.total
    };
  });
  
  const worksheet = XLSX?.utils.json_to_sheet(selectedColumns);
  const workbook = XLSX?.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1'); 
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download =  this.getTipEntryExcelFileName();
  link.click();
  
 }



  render() {

    const columns = [
      {
        dataField: "date",
        text: "Date",
        searchable: false,
        editable: (content: any, row: any) => {
          if (
            row.isLockPeriod === "Yes" ||
            row.action === "selectDate" ||
            row.action === "selectUsername" ||
            row.blockEdit
          ) {
            return false;
          } else {
            return true;
          }
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          if (row.action === "selectDate") {
            return (
              <div ref={this.newDate}>
                <Dropdown className="tips-date single-select-image" show>
                  <Dropdown.Toggle className="" id="dropdown-tips-date">
                    Select Date
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div className="list-area">
                      {this.state.tableRowDates.map((item: any, idx: any) => (
                        <Dropdown.Item
                          eventKey={item}
                          onSelect={(event: any) =>
                            this.selectDateDropDown(event, row)
                          }
                          key={idx}
                        >
                          {item}
                        </Dropdown.Item>
                      ))}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            );
          } else if (row.dateValidation) {
            return (
              <div className="tip-date-error">
                {row.date !== "" ? row.date : "Select Date"}
              </div>
            );
          } else {
            return (
              <div className="tip-date">
                {row.date !== "" ? row.date : "Select Date"}
              </div>
            );
          }
        },
      },
      {
        dataField: "username",
        text: "Employee",
        editable: (content: any, row: any) => {
          if (
            row.isLockPeriod === "Yes" ||
            row.action === "selectUsername" ||
            row.action === "selectDate" ||
            row.blockEdit
          ) {
            return false;
          } else {
            return true;
          }
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          if (row.action === "selectUsername" && row.username === "") {
            return (
              <TipsEmployee
                row={row}
                tableData={this.state.tableDataFilter}
                employeeList={this.state.tipsEmployeeList}
                employeeSelected={this.employeeSelected}
              />
            );
          } else if (row.userValidation) {
            return (
              <div className="tip-username-red">
                <span className="tip-username">
                  {row.username !== "" ? row.username : "Select Employee"}
                </span>
              </div>
            );
          } else {
            return (
              <div
                className={
                  row.date !== "" && row.username === ""
                    ? "tip-username-red"
                    : "tip-date"
                }
              >
                <EllipsisWithTooltip placement="bottom">
                  <span>
                    {row.username !== "" ? row.username : "Select Employee"}
                  </span>
                </EllipsisWithTooltip>
              </div>
            );
          }
        },
      },
      {
        dataField: "cashTips",
        text: "",
        headerFormatter: this.headerFormatter,
        searchable: false,
        editable: (content: any, row: any) => {
          if (
            row.isLockPeriod === "Yes" ||
            row.action === "selectUsername" ||
            row.action === "selectDate" ||
            row.blockEdit
          ) {
            return false;
          } else {
            return true;
          }
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          let id = "cashTips" + rowIndex + "txt";
          if (row.cashTipBlueSpan) {
            return (
              <div className="ready-save-tip">
                <input
                  id={id}
                  className="form-control"
                  ref={row.cashTipsref}
                  onBlur={(e) => this.formatInput(e, row, rowIndex, "cashTips")}
                  onChange={(e) =>
                    this.onTipChange(e, row, rowIndex, "cashTips", id)
                  }
                  onFocus={(e) => this.onAmountFocus(e, row, rowIndex, "cashTips")}
                  type="text"
                  pattern="[+-]?\d+(?:[.,]\d+)?"
                  maxLength={20}
                  autoComplete='off'
                  value={row.cashTips}
                ></input>

                <span className="error-message-blue">
                  Add at least one tip for {row.username}
                </span>
              </div>
            );
          } else if (row.cashTipRedSpan) {
            return (
              <div className="ready-save-tip tip-cash-red">
                <input
                  id={id}
                  className="form-control"
                  ref={row.cashTipsref}
                  onBlur={(e) => this.formatInput(e, row, rowIndex, "cashTips")}
                  onChange={(e) =>
                    this.onTipChange(e, row, rowIndex, "cashTips", id)
                  }
                  type="text"

                  pattern="[+-]?\d+(?:[.,]\d+)?"
                  maxLength={20}
                  autoComplete='off'
                  value={row.cashTips}
                  onFocus={(e) => this.onAmountFocus(e, row, rowIndex, "cashTips")}
                ></input>

                <span className="error-message-red">
                  Add at least one tip for {row.username}
                </span>
              </div>
            );
          } else if (row.cashTipRedBox) {
            return (
              <div className="ready-save-tip tip-cash-red">
                <input
                  id={id}
                  className="form-control"
                  ref={row.cashTipsref}
                  onBlur={(e) => this.formatInput(e, row, rowIndex, "cashTips")}
                  onChange={(e) =>
                    this.onTipChange(e, row, rowIndex, "cashTips", id)
                  }
                  type="text"
                  pattern="[+-]?\d+(?:[.,]\d+)?"
                  maxLength={20}
                  autoComplete='off'
                  value={row.cashTips}
                  onFocus={(e) => this.onAmountFocus(e, row, rowIndex, "cashTips")}
                ></input>
              </div>
            );
          } else {
            return (
              <>
                <div className="ready-save-tip tip-cash">
                  <input
                    id={id}
                    className="form-control"
                    type="text"
                    pattern="[+-]?\d+(?:[.,]\d+)?"
                    maxLength={20}
                    autoComplete='off'
                    ref={row.cashTipsref}
                    onBlur={(e) =>
                      this.formatInput(e, row, rowIndex, "cashTips")
                    }
                    onChange={(e) =>
                      this.onTipChange(e, row, rowIndex, "cashTips", id)
                    }
                    value={row.cashTips}
                    onFocus={(e) => this.onAmountFocus(e, row, rowIndex, "cashTips")}

                  ></input>
                </div>
              </>
            );
          }
        },
      },
      {
        dataField: "chargedTips",
        text: "",
        headerFormatter: this.headerFormatter,
        searchable: false,
        editable: (content: any, row: any) => {
          if (
            row.isLockPeriod === "Yes" ||
            row.action === "selectUsername" ||
            row.action === "selectDate" ||
            row.blockEdit
          ) {
            return false;
          } else {
            return true;
          }
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          let id = "chargedTips" + rowIndex + "txt";
          if (row.chargedTipRedSpan) {
            return (
              <div className="ready-save-tip tip-cash-red">
                <input
                id={id}
                  className="form-control"
                  type="text"
                  pattern="[+-]?\d+(?:[.,]\d+)?"
                  maxLength={20}
                  autoComplete='off'
                  ref={row.chargedTipsref}
                  onBlur={(e) =>
                    this.formatInput(e, row, rowIndex, "chargedTips")
                  }
                  onChange={(e) =>
                    this.onTipChange(e, row, rowIndex, "chargedTips",id)
                  }
                  value={row.chargedTips}
                  onFocus={(e) => this.onAmountFocus(e, row, rowIndex, "chargedTips")}
                ></input>

                {/* <span className="error-message-red">
                  Add at least one tip for {row.username}
                </span> */}
              </div>
            );
          } else if (row.chargedTipRedBox) {
            return (
              <div className="ready-save-tip tip-cash-red">
                <input
                id={id}
                  className="form-control"
                  type="text"
                  ref={row.chargedTipsref}
                  onBlur={(e) =>
                    this.formatInput(e, row, rowIndex, "chargedTips")
                  }
                  onChange={(e) =>
                    this.onTipChange(e, row, rowIndex, "chargedTips",id)
                  }
                  value={row.chargedTips}
                  pattern="[+-]?\d+(?:[.,]\d+)?"
                  maxLength={20}
                  autoComplete='off'
                  onFocus={(e) => this.onAmountFocus(e, row, rowIndex, "chargedTips")}
                ></input>
              </div>
            );
          } else {
            return (
              <div className="ready-save-tip tip-cash">
                <input
                id={id}
                  className="form-control"
                  type="text"
                  ref={row.chargedTipsref}
                  onBlur={(e) =>
                    this.formatInput(e, row, rowIndex, "chargedTips")
                  }
                  onChange={(e) =>
                    this.onTipChange(e, row, rowIndex, "chargedTips",id)
                  }
                  value={row.chargedTips}
                  pattern="[+-]?\d+(?:[.,]\d+)?"
                  maxLength={20}
                  autoComplete='off'
                  onFocus={(e) => this.onAmountFocus(e, row, rowIndex, "chargedTips")}
                ></input>
              </div>
            );
          }
        },
      },
      {
        dataField: "tipsPaidOut",
        text: "",
        headerFormatter: this.headerFormatter,
        searchable: false,
        editable: (content: any, row: any) => {
          if (
            row.isLockPeriod === "Yes" ||
            row.action === "selectUsername" ||
            row.action === "selectDate" ||
            row.blockEdit
          ) {
            return false;
          } else {
            return true;
          }
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          let id = "tipsPaidOut" + rowIndex + "txt";
          if (row.tipsPaidRedSpan) {
            return (
              <div className="ready-save-tip tip-cash-red">
                <input
                   id={id}
                  className="form-control"
                  type="text"
                  ref={row.tipsPaidOutref}
                  onBlur={(e) =>
                    this.formatInput(e, row, rowIndex, "tipsPaidOut")
                  }
                  onChange={(e) =>
                    this.onTipChange(e, row, rowIndex, "tipsPaidOut",id)
                  }
                  value={row.tipsPaidOut}
                  pattern="[+-]?\d+(?:[.,]\d+)?"
                  maxLength={20}
                  autoComplete='off'
                  onFocus={(e) => this.onAmountFocus(e, row, rowIndex, "tipsPaidOut")}
                ></input>

                {/* <span className="error-message-red">
                  Add at least one tip for {row.username}
                </span> */}
              </div>
            );
          } else if (row.tipsPaidRedBox) {
            return (
              <div className="ready-save-tip tip-cash-red">
                <input
                id={id}
                  className="form-control"
                  type="text"
                  ref={row.tipsPaidOutref}
                  onBlur={(e) =>
                    this.formatInput(e, row, rowIndex, "tipsPaidOut")
                  }
                  onChange={(e) =>
                    this.onTipChange(e, row, rowIndex, "tipsPaidOut",id)
                  }
                  value={row.tipsPaidOut}
                  pattern="[+-]?\d+(?:[.,]\d+)?"
                  maxLength={20}
                  autoComplete='off'
                  onFocus={(e) => this.onAmountFocus(e, row, rowIndex, "tipsPaidOut")}
                ></input>
              </div>
            );
          } else {
            return (
              <div className="ready-save-tip tip-cash">
                <input
                id={id}
                  className="form-control"
                  type="text"
                  ref={row.tipsPaidOutref}
                  onBlur={(e) =>
                    this.formatInput(e, row, rowIndex, "tipsPaidOut")
                  }
                  onChange={(e) =>
                    this.onTipChange(e, row, rowIndex, "tipsPaidOut",id)
                  }
                  value={row.tipsPaidOut}
                  pattern="[+-]?\d+(?:[.,]\d+)?"
                  maxLength={20}
                  autoComplete='off'
                  onFocus={(e) => this.onAmountFocus(e, row, rowIndex, "tipsPaidOut")}
                ></input>
              </div>
            );
          }
        },
      },
      {
        dataField: "total",
        text: "Total",
        editable: false,
        searchable: false,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="tip-total">{row.total}</div>
        ),
      },
      {
        dataField: "ation",
        text: "",
        editable: false,
        searchable: false,
        hidden: !this.state.isTipPermission,
        formatter: (cell: any, row: any, rowIndex: any) => {
          if (row.status === "new") {
            return (
              <button
                type="button"
                onKeyUp={this.saveOnKeyPress}
                className="btn btn-primary fa-trash"
                onClick={() => this.removeNewRow(row, rowIndex)}
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                    fill="#2caf92"
                  />
                </svg>
              </button>
            );
          }
          else {
            if (!this.state.isPageLock) {
              const confirmModalContent = [
                {
                  title: "Delete Tip",
                  desc: "Are you sure you want to delete this Tip?",
                  cancleAction: "Cancel",
                  ActionTrue: "Delete Tip",
                },
              ];
              return (
                <Dropdown className="more-action" alignRight>
                  <Dropdown.Toggle
                    onKeyUp={this.saveOnKeyPress}
                    className="btn-outline-primary btn btn-primary more fa-trash"
                    id="dropdown-more"
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M3.75226 15C3.75226 15.8273 4.42501 16.5 5.25226 16.5H12.7523C13.5795 16.5 14.2523 15.8273 14.2523 15V6H15.7523V4.5H13.5023H12.7523V3C12.7523 2.17275 12.0795 1.5 11.2523 1.5H6.75226C5.92501 1.5 5.25226 2.17275 5.25226 3V4.5H4.50226H2.25226V6H3.75226V15ZM6.75226 3H11.2523V4.5H6.75226V3ZM6.00226 6H12.0023H12.7523L12.753 15H5.25226V6H6.00226Z" />
                      <path d="M6.75226 7.5H8.25226V13.5H6.75226V7.5ZM9.75226 7.5H11.2523V13.5H9.75226V7.5Z" />
                    </svg>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <ConfirmationModal
                      confirmModalContent={confirmModalContent}
                      ishandleDeletePosition={this.ishandleDeletePosition.bind(
                        this,
                        row
                      )}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              );
            }
          }
        },
      },
    ];

    return (
      <>
        <RouteLeavingGuard
          when={this.state.isNewTip || this.state.pageHasError}
          routeLeavingGuardConfirm={this.routeLeavingGuardConfirm}
          shouldBlockNavigation={(location: { pathname: any }) => {
            if (this.state.isNewTip || this.state.pageHasError) {
              if (location.pathname) {
                return true;
              }
            }
            return false;
          }}
        />
        {/* <ToastContainer
          enableMultiContainer
          autoClose={3000}
          containerId={"tipEntry"}
        /> */}
        <div className="tip-entry forecastModule">
          <Container fluid className="body-sec">
            {(this.state.isNewTip || this.state.pageHasError) && (
              <div className="bck-drop"></div>
            )}
            <div className="page-heading underline d-flex">
              <HidSelector
                onClickEvent={this.selectHotelDropDown}
                ref={this.childHID}
              />

              <div className="mr-auto">Tips</div>
              {this.state.hidValue !== "Select" && this.state.isMoreDrop && (
                <div className="header-popout">
                  <Dropdown className="more-action" alignRight>
                    <Dropdown.Toggle
                      className="btn-outline-primary btn btn-primary more"
                      id="dropdown-more"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item>Dummy Text</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
            </div>
            {this.state.loaderTrue ? (
              <ReactPageLoader useas={"tipsEntryMain"} />
            ) : (
              <>
                {this.state.hidValue !== "Select" && (
                  <div className="main-page">
                    <ToolkitProvider
                      keyField="rowNo"
                      data={this.state.tableData}
                      columns={columns}
                      search
                      hover
                      rowEvents={this.rowEvents}
                    >
                      {(props: {
                        searchProps: JSX.IntrinsicAttributes;
                        baseProps: JSX.IntrinsicAttributes;
                      }) => (
                        <div>
                          <div className="navigation-menus d-flex">
                            <TableSearch
                              id="tableSearch"
                              key="tableSearch"
                              ref={this.tableSearch}
                              {...props.searchProps}
                              handleNewPosition={this.addTips.bind(this)}
                              CleanSearch={this.CleanSearch.bind(this)}
                              placeholder={"Search..."}
                            />

                            <div className="calender-container">
                              <div
                                className={
                                  this.state.calenderShow
                                    ? "calender-display open"
                                    : "calender-display"
                                }
                                onClick={this.calenderShow}
                              >
                                <svg
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z" />
                                  <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z" />
                                </svg>
                                <span className="prev-Date">
                                  {this.state.navigationCalenderDates[0]}
                                </span>
                                <span className="seperator"> - </span>
                                <span className="next-Date">
                                  {this.state.navigationCalenderDates[1]}
                                </span>
                                <span className="chevron-down">
                                  <FiChevronDown />
                                </span>
                              </div>
                              {this.state.calenderShow && (
                                <div className="calender">
                                  <OutsideClickHandler
                                    onOutsideClick={this.calenderHide}
                                  >
                                    <Calendar
                                      calendarType={"US"}
                                      onChange={this.calenderChange}
                                      value={this.state.calenderSelectDate}
                                      minDate={this.state.laborMgmtMinDate}
                                      maxDate={calenderMaxDate}
                                    />
                                  </OutsideClickHandler>
                                </div>
                              )}
                            </div>

                            {this.state.isPageLock && (
                              <div className="page-lock">
                                <span
                                  className="forecast-lock"
                                  data-tip={tooltipMessage}
                                >
                                  <ReactTooltip
                                    effect="solid"
                                    event="mouseover mouseenter"
                                    eventOff="mouseleave mouseout scroll mousewheel blur"
                                    place="top"
                                    multiline={true}
                                  />
                                  <img src={ForecastLock} alt="TipsPage Lock" />
                                </span>
                              </div>
                            )}

                            <div className="position-filter">
                              {this.state.positionList.length > 0 && (
                                <SingleSearchDropdownList
                                  itemList={this.state.positionList}
                                  handleSelectedItem={this.handleSelectedItem.bind(
                                    this,
                                    1
                                  )}
                                  defaultItem={this.state.positionName}
                                  defaultText={"Search Position"}
                                  defaultName={"Position"}
                                  controlID="1"
                                />
                              )}
                            </div>
                            <div className="mr-auto department-filter">
                              {this.state.departmentList.length > 0 && (
                                <SingleSearchDropdownList
                                  itemList={this.state.departmentList}
                                  handleSelectedItem={this.handleSelectedItem.bind(
                                    this,
                                    2
                                  )}
                                  defaultItem={this.state.departmentName}
                                  defaultText={"Search Department"}
                                  defaultName={"Department"}
                                  controlID="2"
                                />
                              )}
                            </div>
                            {!this.state.isPageLock &&
                              !this.state.isNewTip &&
                              !this.state.pageHasError && (
                                <div className="addTip-button">
                                  {this.state.isTipPermission ? (
                                    <Button
                                      className="add-tip"
                                      onClick={() => this.CleanSearch()}
                                    >
                                      Add Tips
                                    </Button>
                                  ) : null}
                                </div>
                              )}
                          {
                            this.state.tableData.length > 0 && !this.state.isNewTip &&
                            !this.state.pageHasError ?
                            <Dropdown className="more-action ml-4" alignRight>
                            <Dropdown.Toggle
                              className='btn-outline-primary btn btn-primary more'
                              id="dropdown-more"
                            >
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                              </svg>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item onClick={(e: any) =>
                                      this.DownloadExcelClick(e)
                                    }>Download as Excel</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                          : <></>
                            
                          }
                          </div>

                          <div
                            className={
                              this.state.tableData.length > 10
                                ? "tips-table scroll "
                                : "tips-table"
                            }
                          >
                            <OutsideClickHandler
                              onOutsideClick={this.savDatafinal}
                            >
                              <div className="forecast-table">
                                <BootstrapTable
                                  {...props.baseProps}
                                  keyField="rowNo"
                                  id="tableSearchNew"
                                  hover
                                  condensed
                                  rowEvents={this.rowEvents}
                                />
                              </div>
                            </OutsideClickHandler>
                          </div>
                          {!this.state.isPageLock &&
                            !this.state.isNewTip &&
                            !this.state.pageHasError && (
                              <div className="addRowEtry">
                                {this.state.isTipPermission && (
                                  <Button
                                    className="btn-ghost"
                                    onClick={() => this.CleanSearch()}
                                  >
                                    Add Tips
                                  </Button>
                                )}
                              </div>
                            )}
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                )}
              </>
            )}
          </Container>
        </div>
      </>
    );
  }
}
