import React from "react";
import { Dropdown, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { resolve, reject } from "q";
import $ from "jquery";
import { ReactPageLoader } from "../../../Common/Components/ReactPageLoader";
import { PayrollProcessor } from "../../../Common/Services/PayrollProcessor";
import {
  IGetPayrollExportReciepentData,
  ISavePayrollExportReciepentData,
} from "../../../Common/Contracts/IPayrollProcessor";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { Utils } from "../../../Common/Utilis";

export class PayrollExportReciepent extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      loaderTrue: false,
      oldTableData: [],
      tableData: [],
      exportTypeFormat: this.props.parentState.exportTypeFormat,
      columnHidden: true,
      columnHideForContractor : true
    };
  }

  componentDidMount() {
    this.getExportReciepentData("componentMount");
  }

  getExportReciepentData = (type: string) => {
    
    if (type === "componentMount") {
      this.setState({ loaderTrue: true });
    }

    let requestObject = {} as IGetPayrollExportReciepentData;

    requestObject.Hotelid = -1;
    requestObject.Type = "";

    PayrollProcessor.getExportReciepentData(requestObject)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          let tableData: any = [];
          let columnHidden = true;
          let columnHideForContractor = true;

          result.forEach((data: any, idx: any) => {
            if (columnHidden === true) {
              if (data.exportFormat === "ADP2") {
                columnHidden = false;
              }
            }

            let tableDataObject: any = {};

            tableDataObject.id = idx;
            tableDataObject.batchId = data.batchID === null ? "" : data.batchID;
            tableDataObject.companyCode =
              data.company_Code === null ? "" : data.company_Code;
            tableDataObject.email = data.eMail === null ? "" : data.eMail;

            tableDataObject.exportFormatName =
              data.exportFormat === null ? "" : data.exportName;
            tableDataObject.exportFormatType =
              data.exportFormat === null ? "" : data.exportFormat;
            tableDataObject.hotelId = data.hotelid === null ? "" : data.hotelid;
            tableDataObject.letterCode =
              data.lettercode === null ? "" : data.lettercode;
            tableDataObject.setup =
              data.setupforFLSAStatus === "True" ? true : false;
            tableDataObject.email_validation = false;
            tableDataObject.compCode_validation = false;
            tableDataObject.contractorEmail_validation = false;
            tableDataObject.contractorEmail = data.contractorEMail === null ? "" : data.contractorEMail;
            tableDataObject.isContractorExist = data.isContractorExist === null ? "Yes" : data.isContractorExist;
            tableData.push(tableDataObject);

            if(tableDataObject.isContractorExist === "No"){
              columnHideForContractor = true;
            } else {
              columnHideForContractor = false;
            }
            
          });

          this.setState({ tableData, oldTableData: tableData, columnHidden,columnHideForContractor });
        } else {
          Utils.toastError("No Data found", {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "payrollProcessorIndex",
          });
        }
        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "payrollProcessorIndex",
        });
        this.setState({ loaderTrue: false });
        reject();
      });
  };

  handleSave = (row: any, rowIndex: any) => {
    debugger;
    let oldRowData = this.state.oldTableData[rowIndex];
    let newRowData = this.state.tableData[rowIndex];   
    

    if (JSON.stringify(oldRowData) === JSON.stringify(newRowData)) {  
         if(newRowData.batchId==="" && oldRowData.batchId==="" ){          
          this.enterBatchIddefaultValue(row); 
         } 
      return false;
    } else {
      let validateResult: boolean = false;
      let validateMessage: string = "";
      let validatior: string = "";

      if (row.email !== "") {
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        let emailArray = row.email.split(",");
        for (let i = 0; i < emailArray.length; i++) {
          if (!emailRegex.test(emailArray[i].toLowerCase().trim())) {
            validateResult = true;
            validateMessage = "Employee Payroll Email format is not correct";
            validatior = "email";
            break;
          }
        }
      }

      if(!validateResult){
      if (row.contractorEmail !== "") {
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        let emailArray = row.contractorEmail.split(",");
        for (let i = 0; i < emailArray.length; i++) {
          if (!emailRegex.test(emailArray[i].toLowerCase().trim())) {
            validateResult = true;
            validateMessage = "Contractor Payroll Email format is not correct";
            validatior = "contractorEmail";
            break;
          }
        }
      }
    }

    if(!validateResult){
      if (row.companyCode === "") {
        validateResult = true;
        validateMessage = "Company Code can not be blank";
        validatior = "compCode";
      }
    }

      if (validateResult) {
        let newTableData = [...this.state.tableData];
        newTableData = newTableData.map((d) => {
          if (d.id === row.id) {
            if (validatior === "email") {
              return { ...d, email_validation: true };
            } if (validatior === "contractorEmail") {
              return { ...d, contractorEmail_validation: true };
            } else if (validatior === "compCode") {
              return { ...d, compCode_validation: true };
            } else {
              return d;
            }
          } else {
            return d;
          }
        });
        this.setState((curr: any) => ({
          ...curr,
          tableData: newTableData,
        }));

        Utils.toastError(validateMessage, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "payrollProcessorIndex",
        });
      } else {
        let callbackForBlankBatch = false;

        if (row.exportFormatType === "ADP2" && row.batchId === "") {
          callbackForBlankBatch = true;
        }

        this.setState({ oldTableData: this.state.tableData });

        const storage = JSON.parse(localStorage.getItem("storage")!);
        const tenantID = storage === null ? 0 : (storage.tenantID as any);
        let userName = storage === null ? 0 : (storage.userName as any);

        let requestObject = {} as ISavePayrollExportReciepentData;

        requestObject.Hid = row.hotelId;
        requestObject.TenantID = tenantID;
        requestObject.Email = row.email;
        requestObject.CompanyCode = row.companyCode;
        requestObject.PayrollFormat = row.exportFormatType;
        requestObject.FLSAStatus =
        row.exportFormatType === "ADP2" ? (row.setup ? 1 : 0) : 0;
        requestObject.BatchID =
          row.exportFormatType === "ADP2" ? row.batchId : "";
        requestObject.loginUser = userName;
        requestObject.ContractorEMail = row.contractorEmail;
        PayrollProcessor.saveExportReciepentData(requestObject)
          .then(async (result: any | null) => {
            if (result.saveStatus === "Success") {
              if (callbackForBlankBatch) {
                this.enterBatchIdFLSA(row);
              }
              toast.success("Settings Saved Successfully", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "payrollProcessorIndex",
              });
            } else {
              Utils.toastError("Error in saving, please try again", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "payrollProcessorIndex",
              });
            }
            resolve();
          })
          .catch((err: any) => {
            Utils.toastError(`Server Error, ${err}`, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "payrollProcessorIndex",
            });
            reject();
          });
      }
    }
  };

  onEmailChange = (e: any, row: any, rowIndex: any) => {
    let inputId = `${rowIndex}email`;
    let value = e.target.value;
    let cursorIndex = e.target.selectionStart;

    let newTableData = [...this.state.tableData];
    newTableData = newTableData.map((d) => {
      if (d.id === row.id) {
        return {
          ...d,
          email: value,
          email_validation: false,
        };
      } else {
        return d;
      }
    });
    this.setState(
      (curr: any) => ({
        ...curr,
        tableData: newTableData,
      }),
      () => {
        $("#" + inputId)
          .focus()
          .prop("selectionEnd", cursorIndex);
      }
    );
  };

  onEmailBlur = (e: any, row: any, rowIndex: any) => {
    this.handleSave(row, rowIndex);
  };

  
  onContractorEmailChange = (e: any, row: any, rowIndex: any) => {
    let inputId = `${rowIndex}contractorEmail`;
    let value = e.target.value;
    let cursorIndex = e.target.selectionStart;

    let newTableData = [...this.state.tableData];
    newTableData = newTableData.map((d) => {
      if (d.id === row.id) {
        return {
          ...d,
          contractorEmail: value,
          contractorEmail_validation: false,
        };
      } else {
        return d;
      }
    });
    this.setState(
      (curr: any) => ({
        ...curr,
        tableData: newTableData,
      }),
      () => {
        $("#" + inputId)
          .focus()
          .prop("selectionEnd", cursorIndex);
      }
    );
  };

  onContractorEmailBlur = (e: any, row: any, rowIndex: any) => {
    this.handleSave(row, rowIndex);
  };

  onCompanyCodeChange = (e: any, row: any, rowIndex: any) => {
    let inputId = `${rowIndex}companyCode`;
    let value = e.target.value.toUpperCase();
    let cursorIndex = e.target.selectionStart;

    let compnayCodeRegex = /^[A-Za-z0-9\-_() ]+$/;

    if (compnayCodeRegex.test(value) === false) {
      value = value.replace(/[^A-Za-z0-9\-_() ]/g, "");
    }
    if (value.length > 10) {
      let a = value.replace(/[^A-Za-z0-9\-_() ]/g, "");
      value = a.substring(0, value.length - 1);
    }

    let newTableData = [...this.state.tableData];
    newTableData = newTableData.map((d) => {
      if (d.id === row.id) {
        return {
          ...d,
          companyCode: value,
          compCode_validation: false,
        };
      } else {
        return d;
      }
    });
    this.setState(
      (curr: any) => ({
        ...curr,
        tableData: newTableData,
      }),
      () => {
        $("#" + inputId)
          .focus()
          .prop("selectionEnd", cursorIndex);
      }
    );
  };

  onCompanyCodeBlur = (e: any, row: any, rowIndex: any) => {
    let value = e.target.value;
    value = value.trim();
    value = value.replace(/\s\s+/g, " ");

    let newTableData = [...this.state.tableData];
    newTableData = newTableData.map((d) => {
      if (d.id === row.id) {
        return {
          ...d,
          companyCode: value,
        };
      } else {
        return d;
      }
    });
    this.setState(
      (curr: any) => ({
        ...curr,
        tableData: newTableData,
      }),
      () => {
        this.handleSave(newTableData[rowIndex], rowIndex);
      }
    );
  };

  payrollExportFormatSelection = (row: any, rowIndex: any, item: any) => {
    let newTableData = [...this.state.tableData];
    newTableData = newTableData.map((d) => {
      if (d.id === row.id) {
        if (item.exportType === "ADP2") {
          return {
            ...d,
            setup: true,
            exportFormatName: item.exportName,
            exportFormatType: item.exportType,
          };
        } else {
          return {
            ...d,
            batchId: "",
            setup: false,
            exportFormatName: item.exportName,
            exportFormatType: item.exportType,
          };
        }
      } else {
        return d;
      }
    });
    this.setState(
      (curr: any) => ({
        ...curr,
        tableData: newTableData,
      }),
      () => {
        this.hideColumnConditionCheck();
        this.handleSave(newTableData[rowIndex], rowIndex);
      }
    );
  };

  hideColumnConditionCheck = () => {
    let columnHidden = true;

    for (let i = 0; i < this.state.tableData.length; i++) {
      if (this.state.tableData[i].exportFormatType === "ADP2") {
        columnHidden = false;
        break;
      }
    }
    this.setState({ columnHidden });
  };

  enterBatchIdFLSA = (row: any) => {
    let value = "100";

    let newTableData = [...this.state.tableData];
    newTableData = newTableData.map((d) => {
      if (d.id === row.id) {
        return {
          ...d,
          batchId: value,
          setup: true,
        };
      } else {
        return d;
      }
    });
    this.setState((curr: any) => ({
      ...curr,
      tableData: newTableData,
    }));
  };


  enterBatchIddefaultValue = (row: any) => {
    let value = "100";

    let newTableData = [...this.state.tableData];
    newTableData = newTableData.map((d) => {
      if (d.id === row.id) {
        return {
          ...d,
          batchId: value,
         // setup: true,
        };
      } else {
        return d;
      }
    });
    this.setState((curr: any) => ({
      ...curr,
      tableData: newTableData,
    }));
  };


  onBatchIdChange = (e: any, row: any, rowIndex: any) => {    
    let inputId = `${rowIndex}batchId`;
    let value = e.target.value;
    let cursorIndex = e.target.selectionStart;

    if (/^\d+$/.test(value) === false) {
      value = value.replace(/[^\d]/g, "");
    }
    if (value.length > 5) {
      let a = value.replace(/[^\d]/g, "");
      value = a.substring(0, value.length - 1);
    }

    let newTableData = [...this.state.tableData];
    newTableData = newTableData.map((d) => {
      if (d.id === row.id) {
        return {
          ...d,
          batchId: value,
        };
      } else {
        return d;
      }
    });
    this.setState(
      (curr: any) => ({
        ...curr,
        tableData: newTableData,
      }),
      () => {
        $("#" + inputId)
          .focus()
          .prop("selectionEnd", cursorIndex);
      }
    );
  };

  onBatchIdBlur = (e: any, row: any, rowIndex: any) => {   
    this.handleSave(row, rowIndex);
  };

  setUpChange = (e: any, row: any, rowIndex: any) => {
    let value = e.target.checked;

    let newTableData = [...this.state.tableData];
    newTableData = newTableData.map((d) => {
      if (d.id === row.id) {
        return {
          ...d,
          setup: value,
        };
      } else {
        return d;
      }
    });
    this.setState(
      (curr: any) => ({
        ...curr,
        tableData: newTableData,
      }),
      () => {
        this.handleSave(newTableData[rowIndex], rowIndex);
      }
    );
  };

  ehidFormatter = (cell: any, row: any, rowIndex: any) => {
    return <div className="ehid-formatter">{row.letterCode}</div>;
  };

  emailFormatter = (cell: any, row: any, rowIndex: any) => {
    return (
      <div className="email-formatter">
        <Form.Group>
          {row.email.length >= 33 &&(
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                    <Tooltip id={`email-formatter ${rowIndex}`}>
                        {row.email}
                    </Tooltip>
                }
                >
                <Form.Control
                  className={row.email_validation ? "alert-danger" : ""}
                  id={`${rowIndex}email`}
                  //placeholder="-"
                  value={row.email}
                  disabled={!this.props.parentState.permissionManageSettings}
                  onChange={(e: any) => this.onEmailChange(e, row, rowIndex)}
                  onBlur={(e: any) => this.onEmailBlur(e, row, rowIndex)}
                  autoComplete="Off"
              />
            </OverlayTrigger>
          )}
          {row.email.length < 33 &&(
              <Form.Control
                  className={row.email_validation ? "alert-danger" : ""}
                  id={`${rowIndex}email`}
                  //placeholder="-"
                  value={row.email}
                  disabled={!this.props.parentState.permissionManageSettings}
                  onChange={(e: any) => this.onEmailChange(e, row, rowIndex)}
                  onBlur={(e: any) => this.onEmailBlur(e, row, rowIndex)}
                  autoComplete="Off"
              />
          )}
          
          {/* {row.email.length} */}
        </Form.Group>
      </div>
    );
  };

  contEmailFormatter = (cell: any, row: any, rowIndex: any) => {
    return (
      <div className="email-formatter">
        <Form.Group>
          {row.contractorEmail.length >= 33 &&(
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                    <Tooltip id={`contractor-email ${rowIndex}`}>
                        {row.contractorEmail}
                    </Tooltip>
                }
                >
                <Form.Control
                  className={row.contractorEmail_validation ? "alert-danger" : ""}
                  id={`${rowIndex}contractorEmail`}
                // placeholder="-"
                  value={row.contractorEmail}
                  disabled={!this.props.parentState.permissionManageSettings}
                  onChange={(e: any) => this.onContractorEmailChange(e, row, rowIndex)}
                  onBlur={(e: any) => this.onContractorEmailBlur(e, row, rowIndex)}
                  autoComplete="Off"

                />
            </OverlayTrigger>
          )}
          {row.contractorEmail.length < 33 &&(
            <Form.Control
                className={row.contractorEmail_validation ? "alert-danger" : ""}
                id={`${rowIndex}contractorEmail`}
              // placeholder="-"
                value={row.contractorEmail}
                disabled={!this.props.parentState.permissionManageSettings}
                onChange={(e: any) => this.onContractorEmailChange(e, row, rowIndex)}
                onBlur={(e: any) => this.onContractorEmailBlur(e, row, rowIndex)}
                autoComplete="Off"

              />
        )}
          
        </Form.Group>
      </div>
    );
  };

  companyCode = (cell: any, row: any, rowIndex: any) => {
    return (
      <div className="companyCode-formatter">
        <Form.Group>
          <Form.Control
            className={row.compCode_validation ? "alert-danger" : ""}
            id={`${rowIndex}companyCode`}
            placeholder="-"
            value={row.companyCode}
            disabled={!this.props.parentState.permissionManageSettings}
            onChange={(e: any) => this.onCompanyCodeChange(e, row, rowIndex)}
            onBlur={(e: any) => this.onCompanyCodeBlur(e, row, rowIndex)}
            autoComplete="off"
          />
        </Form.Group>
      </div>
    );
  };

  payrollExportFormat = (cell: any, row: any, rowIndex: any) => {
    return (
      <div className="payrollExport">
        <Dropdown className="">
          <Dropdown.Toggle
            id="dropdown-importFrom"
            className=""
            disabled={!this.props.parentState.permissionManageSettings}
          >
            <EllipsisWithTooltip placement="bottom">{row.exportFormatName}</EllipsisWithTooltip>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {this.state.exportTypeFormat.map((item: any, idx: any) => (
              <Dropdown.Item
                key={idx}
                onClick={() => {
                  this.payrollExportFormatSelection(row, rowIndex, item);
                }}
              >
                <EllipsisWithTooltip placement="bottom">{item.exportName}</EllipsisWithTooltip>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };

  bachIdFormatter = (cell: any, row: any, rowIndex: any) => {
    if (row.exportFormatType === "ADP2") {
      return (
        <div className="batchId-formatter">
          <Form.Group>
            <Form.Control
              id={`${rowIndex}batchId`}
              placeholder="-"
              value={row.batchId}
              disabled={!this.props.parentState.permissionManageSettings}
              onChange={(e: any) => this.onBatchIdChange(e, row, rowIndex)}
              onBlur={(e: any) => this.onBatchIdBlur(e, row, rowIndex)}
            />
          </Form.Group>
        </div>
      );
    } else {
      return <div className="batchId-formatter"></div>;
    }
  };

  setupFormatter = (cell: any, row: any, rowIndex: any) => {
    if (row.exportFormatType === "ADP2") {
      return (
        <div className="companyCode-formatter">
          <Form.Check
            type="checkbox"
            label=""
            checked={row.setup}
            disabled={!this.props.parentState.permissionManageSettings}
            onChange={(e: any) => {
              this.setUpChange(e, row, rowIndex);
            }}
          />
        </div>
      );
    } else {
      return <div className="companyCode-formatter"></div>;
    }
  };

  render() {
    const columns = [
      {
        dataField: "letterCode",
        text: "EHID",
        editable: false,
        headerClasses: 'width-100',
        classes: 'width-100',
        formatter: this.ehidFormatter,
      },
      {
        dataField: "email",
        text: "Employee Payroll Email",
        headerClasses: 'width-300',
        classes: 'width-300',
        editable: false,
        formatter: this.emailFormatter,
      },
      {
        dataField: "contractorEmail",
        text: "Contractor Payroll Email",
        editable: false,
        headerClasses: 'width-300',
        classes: 'width-300',
        formatter: this.contEmailFormatter,
        hidden: this.state.columnHideForContractor,
      },
      {
        dataField: "companyCode",
        text: "Company Code",
        headerClasses: 'width-150',
        classes: 'width-150',
        editable: false,
        formatter: this.companyCode,
      },
      {
        dataField: "exportFormatName",
        text: "Payroll Export Format",
        headerClasses: 'width-200',
        classes: 'width-200 overflow-visible',
        editable: false,
        formatter: this.payrollExportFormat,
      },
      {
        dataField: "batchId",
        text: "Batch ID",
        editable: false,
        headerClasses: 'width-100 batch-id',
        classes: 'width-100 batch-id',
        formatter: this.bachIdFormatter,
        hidden: this.state.columnHidden,
      },
      {
        dataField: "setup",
        text: "Setup for FLSA",
        headerClasses: 'width-150 text-align-center setup',
        classes: 'width-150 text-align-center setup',
        editable: false,
        formatter: this.setupFormatter,
        hidden: this.state.columnHidden,
      },
    ];

    return (
      <>
        {this.state.loaderTrue ? (
          <ReactPageLoader useas={"payrollProcessorExportReciepeint"} />
        ) : (
          <div className="reciepent-table payroll-processor">
            <BootstrapTable
              id="tablePayrollProcessorReciepent"
              keyField="id"
              data={this.state.tableData}
              columns={columns}
              hover
            />
          </div>
        )}
      </>
    );
  }
}
