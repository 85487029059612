import React from "react";
import { Container, Dropdown, Button, Spinner } from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./additionalPayrollImports.scss";
import { FiChevronDown } from "react-icons/fi";
import OutsideClickHandler from "react-outside-click-handler";
import { cashMgmtService } from "../../../Common/Services/cashManagement";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";

export class AdditionalPayrollImports extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            checkWise: "No",
            coaWise: "No",
            isPermission: "No",
            loader: false,
            selectHID: -1,
            handleTenentIdValue: "Select TenantID",
            handleEhidValue: "Select EHID",
            alltenant: [],
            allEhid: [],
            calenderStartDatePicker: false,
            calenderStartDates: "Start Date",
            calenderSelectStartDate: new Date(),
            calenderEndDatePicker: false,
            calenderEndDates: "End Date",
            calenderSelectEndDate: new Date(),
            calenderPayrollDatePicker: false,
            calenderPayrollDates: "Payroll Date",
            calenderSelectPayrollDate: new Date(),
            DateFrom: "",
            DateTo: "",
            PayDate: "",
        };
    }


    componentDidMount() {
        this.payrollTenant();
    }

    calenderHideStartdate = () => {
        this.setState({ calenderStartDatePicker: false });
    };
    calenderHideEnddate = () => {
        this.setState({ calenderEndDatePicker: false });
    };
    calenderHidePayrolldate = () => {
        this.setState({ calenderPayrollDatePicker: false });
    };
    calenderShowStartdate = () => {
        this.setState({ calenderStartDatePicker: true });
    };
    calenderShowEnddate = () => {
        this.setState({ calenderEndDatePicker: true });
    };
    calenderShowPayrolldate = () => {
        this.setState({ calenderPayrollDatePicker: true });
    };
    calenderStartDateChange = (date: any) => {

        let today = new Date(date);
        let dateNew = today.getMonth() + 1 + '/' + today.getDate() + '/' + (today.getFullYear()).toString();


        this.setState({ DateFrom: dateNew, calenderStartDates: dateNew, calenderSelectStartDate: dateNew }, () => {
            this.calenderHideStartdate();
        });
    };
    calenderEndDateChange = (date: any) => {
        let today = new Date(date);
        let dateNew = today.getMonth() + 1 + '/' + today.getDate() + '/' + (today.getFullYear()).toString();

        this.setState({ DateTo: dateNew, calenderEndDates: dateNew, calenderSelectEndDate: dateNew }, () => {
            this.calenderHideEnddate();
        });
    };
    calenderPayrollDateChange = (date: any) => {
        let today = new Date(date);
        let dateNew = today.getMonth() + 1 + '/' + today.getDate() + '/' + (today.getFullYear()).toString();

        this.setState({ PayDate: dateNew, calenderPayrollDates: dateNew, calenderSelectPayrollDate: dateNew }, () => {
            this.calenderHidePayrolldate();
        });
    };
    handleImport = (serviceType) => {

        let request: any = {};
        request.PayTenantID = this.state.handleTenentIdValue;
        request.HID = this.state.selectHID;
        request.DateFrom = this.state.DateFrom;
        request.DateTo = this.state.DateTo;
        request.PayDate = this.state.PayDate;
        request.ServiceType = serviceType;
        this.setState({ loader: true });
        cashMgmtService.importAdditionalPayroll(request)
            .then(async (result: any | null) => {
                if (result !== null) {
                    this.setState({ loader: false });
                    if (result[0].message === "Saved" || result[0].message === "Success") {
                        toast.success("Payroll import Succsessfully", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            containerId: "payrollImport",
                        });
                    }
                    else {
                        toast.success(result[0].message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            containerId: "payrollImport",
                        });
                    }

                } else {
                    this.setState({ loader: false });
                }
                resolve();
            }).catch((error) => {
                this.setState({ loader: false });
                reject();
            });


    }

    handleSelectTenent = (eventKey: any) => {
        this.setState({ handleTenentIdValue: eventKey, allEhid: [], handleEhidValue: "Select EHID" }, () => {
            this.payrollHotel(eventKey);
        });

    }


    handleSelectEhid = (eventKey: any) => {
        //debugger;
        let hotelDet = this.state.allEhid.filter(x => x.hotelId.toString() === eventKey);
        let lettercode = hotelDet[0].lettercode
        let coawise = hotelDet[0].coaWise;
         let checkWise = hotelDet[0].checkWise;
                    

        this.setState({ selectHID: eventKey, handleEhidValue: lettercode, checkWise: checkWise, coaWise: coawise });
    }


    payrollTenant = () => {
        cashMgmtService.payrollTenant()
            .then(async (result: any | null) => {
                if (result !== null) {


                    if (result.result[0].pagePermission.toLowerCase() === "no") {
                        window.location.href = "/unauthorize-access";
                    }
                    else {
                        this.setState({ isPermission: "Yes" }, () => {
                            this.setState({ alltenant: [] }, () => {
                                this.setState({ alltenant: result.result })
                            });
                        })

                    }



                } else {

                }
            });
    }

    payrollHotel = (tenantID) => {
        let request: any = {};
        request.PayTenantID = tenantID;
        cashMgmtService.payrollHotel(request)
            .then(async (result: any | null) => {
                if (result !== null) {
                    console.log(result.result);
                    this.setState({ allEhid: [], checkWise: "No", coaWise: "No" }, () => {
                        this.setState({ allEhid: result.result })
                    });

                } else {

                }
            });
    }

    render() {


        return (
            <div className="additional-payroll-imports">
                <Container fluid className="body-sec">

                    <ToastContainer
                        autoClose={3000}
                        containerId={"addpayrollImport"}
                        enableMultiContainer
                    />
                    {this.state.isPermission === "Yes" && (
                        <>
                            <div className="page-heading underline d-flex">
                                <div className="mr-auto d-flex align-items-center">Additional Payroll Imports</div>
                            </div>
                            <div className="main-Page">
                                <div className="bdy-sec">
                                    <div className="filters d-flex">
                                        <div className="margin-righ-15">
                                            <label className="title">Tenant</label>
                                            <Dropdown onSelect={this.handleSelectTenent}>
                                                <Dropdown.Toggle id="dropdown-tenantID" className="d-flex align-items-center">
                                                    <div className="d-flex">
                                                        <div className="select-val"><EllipsisWithTooltip placement="bottom">{this.state.handleTenentIdValue}</EllipsisWithTooltip></div>
                                                        <div className="chevron-down"><FiChevronDown /></div>
                                                    </div>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {this.state.alltenant.map(
                                                        (item: any, index: any) => (
                                                            <Dropdown.Item
                                                                className="ellipsis"
                                                                key={item.tenantID}
                                                                eventKey={item.tenantID}
                                                            >
                                                                {item.tenantID}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="margin-righ-15">
                                            <label className="title">EHID</label>
                                            <Dropdown onSelect={this.handleSelectEhid}>
                                                <Dropdown.Toggle id="dropdown-ehid" className="d-flex align-items-center">
                                                    <div className="d-flex">
                                                        <div className="select-val"><EllipsisWithTooltip placement="bottom">{this.state.handleEhidValue}</EllipsisWithTooltip></div>
                                                        <div className="chevron-down"><FiChevronDown /></div>
                                                    </div>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {this.state.allEhid.map(
                                                        (item: any, index: any) => (
                                                            <Dropdown.Item key={item.hotelId} eventKey={item.hotelId}>
                                                                <div className="ehid-item d-flex">
                                                                    <div className="keycode">{item.lettercode}</div>
                                                                    {/* <div className="keyvalue"><EllipsisWithTooltip placement="bottom">{item.lettercode+''+item.pname}</EllipsisWithTooltip></div> */}
                                                                </div>
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="margin-righ-15">
                                        <label className="title">Start Date</label>
                                            <div className="calender-container">
                                                <div className={this.state.calenderStartDatePicker ? "calender-display d-flex align-items-center open" : "calender-display d-flex align-items-center"} onClick={this.calenderShowStartdate}>
                                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z" />
                                                        <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z" />
                                                    </svg>
                                                    <span className="prev-Date">
                                                        {this.state.calenderStartDates}
                                                    </span>
                                                    <span className="chevron-down">
                                                        <FiChevronDown />
                                                    </span>
                                                </div>
                                                {this.state.calenderStartDatePicker && (
                                                    <div className="calender">
                                                        <OutsideClickHandler onOutsideClick={this.calenderHideStartdate}>
                                                            <Calendar
                                                                calendarType={"US"}
                                                                onChange={this.calenderStartDateChange}
                                                                value={new Date(this.state.calenderSelectStartDate)}
                                                            />
                                                        </OutsideClickHandler>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="margin-righ-15">
                                        <label className="title">End Date</label>
                                            <div className="calender-container">
                                                <div className={this.state.calenderEndDatePicker ? "calender-display d-flex align-items-center open" : "calender-display d-flex align-items-center"} onClick={this.calenderShowEnddate}>
                                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z" />
                                                        <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z" />
                                                    </svg>
                                                    <span className="prev-Date">
                                                        {this.state.calenderEndDates}
                                                    </span>
                                                    <span className="chevron-down">
                                                        <FiChevronDown />
                                                    </span>
                                                </div>
                                                {this.state.calenderEndDatePicker && (
                                                    <div className="calender">
                                                        <OutsideClickHandler onOutsideClick={this.calenderHideEnddate}>
                                                            <Calendar
                                                                calendarType={"US"}
                                                                onChange={this.calenderEndDateChange}
                                                                value={new Date(this.state.calenderSelectEndDate)}
                                                            />
                                                        </OutsideClickHandler>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="margin-righ-15">
                                        <label className="title">Pay Date</label>
                                            <div className="calender-container">
                                                <div className={this.state.calenderPayrollDatePicker ? "calender-display d-flex align-items-center open" : "calender-display d-flex align-items-center"} onClick={this.calenderShowPayrolldate}>
                                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z" />
                                                        <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z" />
                                                    </svg>
                                                    <span className="prev-Date">
                                                        {this.state.calenderPayrollDates}
                                                    </span>
                                                    <span className="chevron-down">
                                                        <FiChevronDown />
                                                    </span>
                                                </div>
                                                {this.state.calenderPayrollDatePicker && (
                                                    <div className="calender">
                                                        <OutsideClickHandler onOutsideClick={this.calenderHidePayrolldate}>
                                                            <Calendar
                                                                calendarType={"US"}
                                                                onChange={this.calenderPayrollDateChange}
                                                                value={new Date(this.state.calenderSelectPayrollDate)}
                                                            />
                                                        </OutsideClickHandler>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        
                                       
                                        
                                       
                                        
                                        {this.state.coaWise === "Yes" && (
                                            <div className="margin-righ-15">
                                                <Button className="btn-outline-primary margin-top-34 " variant="primary" onClick={(e) => { this.handleImport('COA') }}>COA-Wise Import</Button>
                                            </div>
                                        )}
                                        {this.state.checkWise === "Yes" && (
                                            <div>
                                                <Button className="margin-top-34" variant="primary" onClick={(e) => { this.handleImport('Check') }}>Check-Wise Import</Button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {this.state.loader && (
                                <div className="loader-spinner d-flex justify-content-center align-items-center">
                                    <Spinner animation="border" variant="success" />
                                </div>
                            )}

                        </>
                    )}
                </Container>
            </div>
        );
    }
}