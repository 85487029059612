import React from "react";
import { Dropdown } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import { ITableColumnSelectorProps } from "../../../../Common/Contracts/ITableColumnSelectorProps";



export const TableColumnSelector: React.FC<ITableColumnSelectorProps>  = ({toggleColumnData, drillDownLevel ,toggleColumnCheck} ) => {


 return ( <div>
 <Dropdown className='custom-column-selector'>
<Dropdown.Toggle>
    <span className="dropdown-icon">
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M16.3914 2H3.60861C2.72146 2 2 2.79733 2 3.77778V16.2222C2 17.2027 2.72146 18 3.60861 18H16.3914C17.2785 18 18 17.2027 18 16.2222V6.44356V5.55467V4.66578V3.77778V3.77689C17.9992 2.79644 17.2769 2 16.3914 2ZM6.82582 16.2222H3.60861V6.44356H6.82582V16.2222ZM11.6516 16.2222H8.43442V6.44356H11.6516V16.2222ZM13.2602 16.2222V6.44356H16.3914L16.3922 16.2222H13.2602Z" />
        </svg>
    </span>
    <span className='arrow'>
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="7" viewBox="0 0 10 7" fill="none">
        <path d="M5 6.31266L0 1.31266L1.16667 0.145996L5 3.97933L8.83333 0.145996L10 1.31266L5 6.31266Z" fill="#1D1E20"/>
        </svg>
    </span>
</Dropdown.Toggle>
<DropdownMenu>
    <div className="dropdown-header d-flex">
        <span className="header-name">Column Configuration</span>
        <DropdownItem className="close-icon">&times;</DropdownItem>
    </div>
    <div className="show-hide-column">
            {toggleColumnData.map(
                (data: any, idx: any) => (
                    <div
                        className={`dropdown-col-items d-flex form-check ${(idx == 1 && Number(drillDownLevel) === 0 || idx === 0 && Number(drillDownLevel) > 0) ? "divider" : ""} `}
                        key={idx}
                    >
                        <input
                            className="form-check-input"
                            key={idx}
                            type="checkbox"
                            checked={data.checked}
                            disabled={data.disabled}
                            onChange={(e: any) =>
                                toggleColumnCheck(e, data, idx)
                            }
                        />

                        <label
                            className={
                                data.disabled
                                    ? "form-check-label disabled"
                                    : data.checked
                                        ? "form-check-label active"
                                        : "form-check-label"
                            }
                        >
                            {data.colDispalyName}
                        </label>
                    </div>

                )
            )}
        </div> 
</DropdownMenu>
</Dropdown>
</div>);
}
