import React from "react";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { Dropdown, Form } from "react-bootstrap";
import { FiChevronDown } from "react-icons/fi";
import Calendar from "react-calendar";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import "react-calendar/dist/Calendar.css";
import OutsideClickHandler from "react-outside-click-handler";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { resolve, reject } from "q";
import { TimeClockCommonFunc } from "./TimeClockCommonFunc";
import moment from "moment-timezone";
import { WebTimeClockService } from "../Common/Services/WebTimeClock";
import { SingleSearchDropdownList } from "../Common/Components/SingleSearchDropdownList";
import profile from "../Common/Assets/Images/profile.png";
import { AddEditShiftModal } from "../Modules/LaborManagement/Labor/Schedule/Modals & SlideOut/AddEditShiftModal";
import { ILaborScheduleDeptPositionRequestDto } from "../Common/Contracts/ILaborSchedule";
import {
  ILaborLaborManagerTimeClockRequestDto,
  IsendEmailInAddShiftInTimeClockRequestDto,
} from "../Common/Contracts/IWebTimeClock";
import {
  RoleSubPermissionSetting,
  IPageSubPermissionRole,
} from "../Common/Services/RoleSubPermissionSetting";
import TableSearch from "../Common/Components/TableSearch";
import { Utils } from "../Common/Utilis";
const { SearchBar } = Search;

export class ManagerTimeClock extends React.Component<any, any> {
  private saveBtnRef:any;
  constructor(props: any) {
    super(props);
    this.saveBtnRef = React.createRef();
    this.state = {
      hidValue: '',
      isSchedulingPermission: false,
      calenderSelectDate: {},
      calenderDisplayDate: "",
      calenderShow: false,
      tableData: [],
      tableDataFilter: [],
      positionName: "Position",
      departmentName: "Department",
      positionList: [],
      departmentList: [],
      addEdiShiftValues: {},
      addEditShiftModal: false,
      selectedDepartment: 0,
      selectedPosition: 0,
      displayCurrentDay: '',
      weekStartDate: "",
      weekEndDate: "",
      showForcePunch: false,
      currentHotelTime: '',
    };
  }

  componentDidMount() {
    this.getMenuPermission();
  }

  setPropState = () => {
    let { hidValue, displayCurrentDay, currentHotelTime } = this.state;
    hidValue = this.props.parentState.hidValue;
    displayCurrentDay = this.props.parentState.displayDate;
    currentHotelTime = this.props.parentState.currentHotelTime;
    this.setState(() => ({
      hidValue,
      displayCurrentDay,
      currentHotelTime
    }))
  }

  static getDerivedStateFromProps(props: any, state: any) {
    return {
      hidValue: props.parentState.hidValue,
    };
  }

  getMenuPermission = () => {
    let timeClockTemp = JSON.parse(localStorage.getItem("ML")!);
    let isPermission = false;

    let requestObject = {} as any;
    requestObject.permissionID = 10035;
    requestObject.Module = "LaborManagement";
    requestObject.Type = "page";
    requestObject.RoleID = timeClockTemp !== null && timeClockTemp.RoleID;
    requestObject.TenantID = timeClockTemp !== null && timeClockTemp.TenantID;

    RoleSubPermissionSetting.GetPageSubPermissionWebClockDetails(requestObject)

      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          result.forEach((element: any) => {
            if (element.permissionID === 10048) {
              isPermission = true;
              this.setState({ isPagePermission: true });
            }
          });
          if (isPermission) {
            // Set Local and Session Storage for Manager View on refresh

            this.getWebTimeClockManagerPermission(
              timeClockTemp.TenantID,
              timeClockTemp.RoleID
            );

            let timeClock: any = {};
            timeClock.viewType = "Manager";

            timeClock.managerName = this.props.parentState.managerName;
            timeClock.managerId = this.props.parentState.managerId;

            localStorage.setItem("timeClock", JSON.stringify(timeClock));
            sessionStorage.setItem("timeClock", JSON.stringify(timeClock));

            // Get Date for Calender accrding to zone

            let timeZoneValue = TimeClockCommonFunc.getCurrentTimeZone(
              this.props.parentState.hidTimeZone
            );
            let zoneDate: any = moment.tz(timeZoneValue).format("DD");
            let zoneMonth: any = moment.tz(timeZoneValue).format("MM");
            let zoneYear: any = moment.tz(timeZoneValue).format("YYYY");
            // let curretnzoneDate = `${zoneMonth}/${zoneDate}/${zoneYear}`;
            let curretnzoneDate = this.props.parentState.displayDate;

            const todayDate = new Date(curretnzoneDate);

            this.setState(
              {
                calenderDisplayDate: curretnzoneDate,
                calenderSelectDate: todayDate,
              },
              () => {
                this.setPropState();
                this.getLaborManagerTimeClockData("componentMount");

              }
            );
            this.getPostionDepartment("componentMount");
          } else {
            this.props.managerLogout();
          }
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "timeClockIndex",
        });
        reject();
      });
  };

  getWebTimeClockManagerPermission = (tenantID: any, roleId: any) => {
    let requestObject = {} as IPageSubPermissionRole;
    requestObject.permissionID = 10048;
    requestObject.Module = "LaborManagement";
    requestObject.Type = "page";
    requestObject.RoleID = roleId;
    requestObject.TenantID = tenantID;
    RoleSubPermissionSetting.GetPageSubPermissionWebClockDetails(requestObject)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          for (let index = 0; index < result.length; index++) {
            if (result[index].permissionID === 10049) {
              this.setState({ isSchedulingPermission: true });
            }
            if (result[index].displayName.toString().toLowerCase() === "allow forced punches" && result[index].moduleName === "LaborManagement") {
              this.setState({ showForcePunch: true });
            }

          }
          // result.forEach((element: any) => {
          //   switch (element.permissionID) {
          //     case 10049:
          //       this.setState({ isSchedulingPermission: true });
          //       break;
          //       case 10057:
          //       this.setState({ showForcePunch: true })
          //       break;
          //   }
          // });
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "timeClockIndex",
        });
        reject();
      });
  };

  componentWillUnmount() {
    // Remove Local and Session Storage for Manager View on refresh
    localStorage.removeItem("timeClock");
    localStorage.removeItem("storage");
    localStorage.removeItem("token");
    localStorage.removeItem("expirationTime");
    localStorage.removeItem("logoutPopupTime");
    sessionStorage.removeItem("timeClock");
    sessionStorage.removeItem("storage");
    sessionStorage.removeItem("token");
  }

  getPostionDepartment = (type: string) => {
    if (this.props.parentState.hidValue === "") {
      return false;
    } else {
      let requestPosDept = {} as ILaborScheduleDeptPositionRequestDto;
      requestPosDept.hotelID = this.props.parentState.hidValue;
      WebTimeClockService.GetLaborPositionDept(requestPosDept)
        .then(async (result: any) => {
          if (result.message === "Success") {
            let positonDetails: any = [{ id: -1, name: "All" }];
            let departMentDetails: any = [{ id: -1, name: "All" }];
            result.result[0].laborSchedulePosition.forEach((res: any) => {
              let positonObject: any = {};
              positonObject.id = res.positionID;
              positonObject.name = res.positionName;
              positonObject.deptid = res.deptID;
              positonDetails.push(positonObject);
            });
            result.result[0].laborScheduleDepartment.forEach((res: any) => {
              let departMentObject: any = {};
              departMentObject.id = res.deptID;
              departMentObject.name = res.departmentName;
              departMentDetails.push(departMentObject);
            });
            this.setState({
              positionList: positonDetails,
              departmentList: departMentDetails,
            });
          }
          resolve();
        })
        .catch((err) => {
          Utils.toastError(`Server Error, ${err}`, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "timeClockIndex",
          });
          reject();
        });
    }
  };

  getLaborManagerTimeClockData = (type: any) => {
    if (
      this.props.parentState.hidValue === "" ||
      this.state.calenderDisplayDate === ""
    ) {
      return false;
    } else {
      let requestTimeClock = {} as ILaborLaborManagerTimeClockRequestDto;
      requestTimeClock.Hid = this.props.parentState.hidValue;
      // requestTimeClock.WeekStartDate = this.state.displayCurrentDay;
      // requestTimeClock.WeekEndDate = this.state.displayCurrentDay;
      requestTimeClock.WeekStartDate = this.state.calenderDisplayDate;
      requestTimeClock.WeekEndDate = this.state.calenderDisplayDate;
      requestTimeClock.Date = this.state.calenderDisplayDate;
      WebTimeClockService.GetLaborManagerTimeClock(requestTimeClock)
        .then(async (result: any) => {
          if (result.message === "Success") {
            let apiResult = result.result;
            apiResult.map((items) => ((items["modalShow"] = false, items["punchTimeValue"] = "", items["punchCommentValue"] = "")))
            let weekStartDateList = apiResult.filter(
              (items: any) => items.employee === "Header"
            );
            let weekStartDate = null;
            let weekEndDate = null;
            if (weekStartDateList !== null) {
              weekStartDate = weekStartDateList[0].weekStartDate;
              weekEndDate = weekStartDateList[0].weekEndDate;
            }
            let apiResultList = apiResult.filter(
              (items: any) => items.employee !== "Header"
            );

            this.setState(
              {
                tableData: apiResultList,
                tableDataFilter: apiResultList,
                weekStartDate: weekStartDate,
                weekEndDate: weekEndDate,
              },
              () => {
                if (
                  type === "scheduleEmployee" ||
                  type === "calenderDropDown"
                ) {
                  this.FilterByDropDownList(
                    this.state.selectedPosition,
                    this.state.selectedDepartment
                  );
                }
              }
            );
          }
          resolve();
        })
        .catch((err) => {
          Utils.toastError(`Server Error, ${err}`, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "timeClockIndex",
          });
          reject();
        });
    }
  };

  handleSelectedItem = (controlID: any, id: any) => {
    if (controlID === 1) {
      this.setState({ selectedPosition: id });
      this.FilterByDropDownList(id, this.state.selectedDepartment);
    } else {
      this.setState({ selectedDepartment: id });
      this.FilterByDropDownList(this.state.selectedPosition, id);
    }
  };

  FilterByDropDownList(positionId: number, departmentId: Number) {
    let tableDataSummery: any[] = [];
    tableDataSummery = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableDataFilter))
    );
    if (positionId > 0) {
      tableDataSummery = tableDataSummery.filter(
        (item) => item.position === positionId.toString()
      );
      this.setState({ tableData: tableDataSummery });
    }
    if (departmentId > 0) {
      tableDataSummery = tableDataSummery.filter(
        (item) => item.deptID === departmentId
      );
      this.setState({ tableData: tableDataSummery });
    }
    if (positionId <= 0 && departmentId <= 0) {
      this.setState({ tableData: this.state.tableDataFilter });
    }
  }

  managerLogOut = () => {
    this.props.managerLogout();
  };

  showCalender = () => {
    this.setState({ calenderShow: true });
  };

  hideCalender = () => {
    this.setState({ calenderShow: false });
  };

  calenderChange = (e: any) => {
    let calenderYear = e.getFullYear();
    let calenderDate = e.getDate();
    let calenderMonth = e.getMonth() + 1;
    let tempDate = calenderMonth + "/" + calenderDate + "/" + calenderYear;
    this.setState(
      {
        calenderDisplayDate: tempDate,
        calenderSelectDate: e,
        calenderShow: false,
      },
      () => {
        this.getLaborManagerTimeClockData("calenderDropDown");
      }
    );
  };

  addEditShiftModalShow = () => {
    let addEditObject: any = {};
    addEditObject.formType = "add";
    addEditObject.selectedSchedule = "addSchedule";
    addEditObject.scheduleAssignedType = "Assigned";
    addEditObject.selectedRow = "";
    addEditObject.selectedDay = "";
    addEditObject.editableStatus = "canEdit";
    this.setState({
      addEdiShiftValues: addEditObject,
      addEditShiftModal: true,
    });
  };

  addEditShiftModalHide = () => {
    this.getLaborManagerTimeClockData("scheduleEmployee");
    this.setState({ addEdiShiftValues: {}, addEditShiftModal: false });
  };

  sendEmail = (userName: any, date: any) => {
    let timeClockTemp = JSON.parse(localStorage.getItem("ML")!);
    let requestEmailTimeClock = {} as IsendEmailInAddShiftInTimeClockRequestDto;
    requestEmailTimeClock.userName = userName;
    requestEmailTimeClock.date = date;
    requestEmailTimeClock.hid = Number(this.props.parentState.hidValue);
    requestEmailTimeClock.tenantID= Number(timeClockTemp.TenantID);
    WebTimeClockService.SendEmailInAddShiftInTimeClock(requestEmailTimeClock)
      .then(async (result: any) => {
        resolve();
      })
      .catch((err) => {
        reject();
      });
  };


  showUserPunch(e: any, row: any) {
    let { tableData } = this.state;
    tableData = tableData.map((items) => {
      if (items.id === row.id) {
        return { ...items, modalShow: true };
      } else {
        return items;
      }
    });
    this.setState((curr: any) => ({ ...curr, tableData }))
  }

  getCurrentTime = (timeZone: string) => {
    let timeZoneValue = TimeClockCommonFunc.getCurrentTimeZone(
      timeZone
    );

    switch (timeZone) {
      case "EST":
        timeZoneValue = "America/New_York";
        break;
      case "PST":
        timeZoneValue = "America/Los_Angeles";
        break;
      case "MST":
        timeZoneValue = "America/Phoenix";
        break;
      case "CST":
        timeZoneValue = "America/Chicago";
        break;
      default:
        timeZoneValue = "America/New_York";
        break;
    }

    let hours: string = moment.tz(timeZoneValue).format("h");
    let minutes: string = moment.tz(timeZoneValue).format("m");
    let seconds: string = moment.tz(timeZoneValue).format("s");
    let am_pm: string = moment.tz(timeZoneValue).format("A");

    if (Number(hours) < 10) {
      hours = `0${hours}`;
    }
    if (Number(minutes) < 10) {
      minutes = `0${minutes}`;
    }
    if (Number(seconds) < 10) {
      seconds = `0${seconds}`;
    }
    return `${hours}:${minutes}:${seconds} ${am_pm}`;

  };

  isPunchOutTimeExceedCurrentTime = (outDate, outTime) => {
    let currentHotelTime = this.props.parentState.currentHotelTime;
    let currentTime;
    let formattedOutTime;
    let formattedCurrentTime;
    if (currentHotelTime !== '') {
      currentTime = new Date(currentHotelTime).toLocaleTimeString();
      formattedOutTime = moment(moment(outDate).format("DD/MM/YYYY") + " " + outTime, 'DD/MM/YYYY h:mma');
      formattedCurrentTime = moment(moment(currentHotelTime).format("DD/MM/YYYY") + " " + currentTime, 'DD/MM/YYYY h:mma');
      let isShow = formattedOutTime.isAfter(formattedCurrentTime);
      return isShow
    } else {
      return 0
    }
  }

  hideUserPunch(row: any) {
    let { tableData } = this.state;
    tableData = tableData.map((items) => {
      if (items.id === row.id) {
        return {
          ...items,
          modalShow: false,
          punchTimeValue: "",
          punchCommentValue: "",
        };
      } else {
        return items;
      }
    });
    this.setState((curr: any) => ({ ...curr, tableData }))
  }

  punchTimeChange(e: any, row: any, rowIndex: any) {
    let { value } = e.target;
    let { tableData } = this.state;
    tableData = tableData.map((items) => {
      if (items.id === row.id) {
        return { ...items, punchTimeValue: value };
      } else {
        return items;
      }
    });
    this.setState((curr: any) => ({ ...curr, tableData }))
  }

  commentChange(e: any, row: any, rowIndex: any) {
    let { value } = e.target;
    let { tableData } = this.state;
    tableData = tableData.map((items) => {
      if (items.id === row.id) {
        return { ...items, punchCommentValue: value.trimStart() };
      } else {
        return items;
      }
    });
    this.setState((curr: any) => ({ ...curr, tableData }))
  }

  commentValidate(row) {
    if (row.punchCommentValue === "" || row.punchCommentValue === undefined) {
      Utils.toastError('Please enter explanation.', {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "timeClockIndex"
      });
    } else {
      return row.punchCommentValue;
    }
  };

  submitPunch(event, row, type) {
    event.preventDefault();
    if (this.commentValidate(row)) {
      this.saveBtnRef.current.disabled = true;
      let requestObject: any = {};
      requestObject.Hotelid = this.state.hidValue;
      requestObject.UserName = row.employee;
      requestObject.DateTime24Hrs = this.props.parentState.displayDate +" "+ row.punchTimeValue;
      requestObject.CallingPage = "force";
      requestObject.ForceRemarks = "Forced " + this.setDefaultPunch(row) + ": " + row.punchCommentValue;
      if (type === "Punch In") {
        requestObject.IsPunchCall="Yes";
        requestObject.ScheduleNo = row.uniqueno;
        requestObject.PageSource = "ManagerTimeClockForcePunchIn";
        this.SaveEmployeeForcePunchIn(requestObject, row);
      } else {
        requestObject.IsPunchCall="No";
        requestObject.ScheduleNo = row.mainUniqueno;
        requestObject.PageSource = "ManagerTimeClockForcePunchOut";
        this.SaveEmployeeForcePunchOut(requestObject, row);
      }
    }
  }

  SaveEmployeeForcePunchIn(requestObject, row) {
    let { tableData } = this.state;
    WebTimeClockService
      //RoleSubPermissionSetting
      .SaveEmployeeForcePunchIn(requestObject)
      .then(async (resultPermission: any | null) => {
        if (resultPermission.message.toLowerCase() === "success") {
          if (resultPermission.result !== null) {
            let statusResponse = resultPermission.result.result.status;
            if (statusResponse.toLowerCase() === "success") {
              toast.success("Punch in successfully", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "timeClockIndex",
              });
              this.getLaborManagerTimeClockData("d");
            } else if (statusResponse.toLowerCase() === "invalidintime") {
              Utils.toastError("Punch-in time can not be less than actual time", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "timeClockIndex"
              });
            } else if (statusResponse.toLowerCase() === "noschedule") {
              Utils.toastError(statusResponse, {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "timeClockIndex",
              });
            } else if (statusResponse.toLowerCase() === "already punchin") {
              Utils.toastError(statusResponse, {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "timeClockIndex",
              });
            } else if (statusResponse.toLowerCase() === "invalidhid") {
              Utils.toastError(statusResponse, {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "timeClockIndex",
              });
            } else if (statusResponse.toLowerCase() === "invalidemployee") {
              Utils.toastError(statusResponse, {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "timeClockIndex",
              });
            } else if (statusResponse.toLowerCase() === "overlapped") {
              Utils.toastError(statusResponse, {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "timeClockIndex",
              });
            } else {
              Utils.toastError(statusResponse, {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "timeClockIndex",
              });
            }
            tableData = tableData.map((items) => {
              if (items.id === row.id) {
                return { ...items, punchTimeValue: "", punchCommentValue: "", modalShow: false };
              } else {
                return items;
              }
            });
            this.setState((curr: any) => ({ ...curr, tableData }));
            $(".dropdown.show").trigger("click");
          }
        } else {
          this.saveBtnRef.current.disabled = false;
          Utils.toastError(resultPermission.message, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "timeClockIndex",
          });
        }
      })
      .catch((err: any) => {
        this.saveBtnRef.current.disabled = false;
        this.setState({ buttonSpinner: false });
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "timeClockIndex"
        });
        reject();
      });
  }
  SaveEmployeeForcePunchOut(requestObject, row) {
    let { tableData } = this.state;
    WebTimeClockService
      //RoleSubPermissionSetting
      .SaveEmployeeForcePunchOut(requestObject)
      .then(async (resultPermission: any | null) => {
        if (resultPermission.message.toLowerCase() === "success") {
          if (resultPermission.result !== null) {
            let statusResponse = resultPermission.result.result.status;
            if (statusResponse.toLowerCase() === "success") {
              toast.success("Punch Out successfully", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "timeClockIndex",
              });
              this.getLaborManagerTimeClockData("d");
            } else if (statusResponse.toLowerCase() === "invalidouttime") {
              Utils.toastError("Punch-Out time can not be greater than actual time", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "timeClockIndex"
              });
            } else if (statusResponse.toLowerCase() === "lessthanintime") {
              Utils.toastError("Punch-Out time can not be less than punch-in time", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "timeClockIndex"
              });
            } else if (statusResponse.toLowerCase() === "noschedule") {
              Utils.toastError(statusResponse, {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "timeClockIndex",
              });
            } else if (statusResponse.toLowerCase() === "already punchin") {
              Utils.toastError(statusResponse, {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "timeClockIndex",
              });
            } else if (statusResponse.toLowerCase() === "invalidhid") {
              Utils.toastError(statusResponse, {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "timeClockIndex",
              });
            } else if (statusResponse.toLowerCase() === "invalidemployee") {
              Utils.toastError(statusResponse, {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "timeClockIndex",
              });
            } else if (statusResponse.toLowerCase() === "overlapped") {
              Utils.toastError(statusResponse, {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "timeClockIndex",
              });
            } else {
              Utils.toastError(statusResponse, {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "timeClockIndex",
              });
            }
            tableData = tableData.map((items) => {
              if (items.id === row.id) {
                return { ...items, punchTimeValue: "", punchCommentValue: "", modalShow: false };
              } else {
                return items;
              }
            });
            this.setState((curr: any) => ({ ...curr, tableData }));
            $(".dropdown.show").trigger("click");
          }
        } else {
          this.saveBtnRef.current.disabled = false;
          Utils.toastError(resultPermission.message, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "timeClockIndex",
          });
        }
      })
      .catch((err: any) => {
        this.saveBtnRef.current.disabled = false;
        this.setState({ buttonSpinner: false });
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "timeClockIndex"
        });
        reject();
      });
  }

  setDefaultPunch(items) {
    if (items.actIntime === null && items.actOutTime === null) {
      return "Punch In"
    } else if (items.actIntime !== null && items.actOutTime === null) {
      return "Punch Out"
    } else if (items.actIntime !== null && items.actOutTime !== null) {
      return "Punch In"
    }
  }

  render() {
    const columns = [
      {
        dataField: "employee",
        text: "Name",
        editable: false,
        searchable: true,
        formatter: (cell: any, row: any, rowIndex: any) => {
          let className: any = "";
          switch (row.status) {
            case "Punched In":
              className = "hasLiveGreenCircle";
              break;
            case "Punched In Late":
              className = "hasLiveGreenCircle";
              break;
            default:
              className = "";
              break;
          }
          return (
            <div className="employee-card d-flex">
              <div className={`${className} employee-img`}>
                <img
                  src={
                    row.imagePath === null || row.imagePath.trim() === ""
                      ? profile
                      : row.imagePath
                  }
                  className={
                    row.imagePath === null || row.imagePath.trim() === ""
                      ? "default"
                      : ""
                  }
                  alt={row.employee}
                />
                {className && (
                  <span className="hasLiveIcon">
                    <svg width="25" height="25" viewBox="0 0 20 20" fill="#ffffff" xmlns="http://www.w3.org/2000/svg"><path d="M10.8332 5.83331H9.1665V10.345L11.9107 13.0891L13.089 11.9108L10.8332 9.65498V5.83331Z"></path></svg>
                  </span>
                )}
              </div>
              <div className="employee-details d-flex">
                <EllipsisWithTooltip placement="bottom">
                  <span className="employee-name">{row.employee}</span>
                </EllipsisWithTooltip>
                <EllipsisWithTooltip placement="bottom">
                  <span className="employee-position">{row.positionName}</span>
                </EllipsisWithTooltip>
              </div>
            </div>
          )
        },
      },
      {
        dataField: "status",
        text: "Status",
        editable: false,
        searchable: false,
        formatter: (cell: any, row: any, rowIndex: any) => {
          let className: any = "";
          switch (row.status) {
            case "Scheduled":
              className = "badge color-grey";
              break;
            case "Punched In":
              className = "badge color-green";
              break;
            case "No Show":
              className = "badge color-orange";
              break;
            //case "Running Long":
            case "Late":
              className = "badge color-orange";
              break;
            case "Punched Out":
              className = "badge color-blue";
              break;
            default:
              className = "badge color-grey";
              break;
          }

          return <span className={className}>{row.status}</span>;
        },
      },
      {
        dataField: "displaySchedule",
        text: "Shift Info",
        editable: false,
        searchable: false,
        // formatter: (cell: any, row: any, rowIndex: any) => {
        //   return (
        //     <div className="shift-info d-flex">
        //       <span className="shift-info-time">{row.displaySchedule}</span>
        //       <span className="shift-info-subtitle">Schedule</span>
        //     </div>
        //   );
        // },
        formatter: (cell: any, row: any, rowIndex: any) => {
          let className: any = "";
          switch (row.status) {
            case "Late":
              className = "colorB12A18";
              break;
            default:
              className = "";
              break;
          }
          return (
            <div className="shift-info d-flex">
              <span className={`${className} shift-info-time`}>{row.displaySchedule}</span>
              <span className="shift-info-subtitle">Schedule</span>
            </div>
          );
        },
      },
      {
        dataField: "displayActual",
        text: "",
        editable: false,
        searchable: false,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="shift-info-actual d-flex">
              <span className="shift-info-actual-total">
                {row.displayActual}
              </span>
              {row.displayActual !== "-" && (
                <span className="shift-info-actual-subtitle">Actual</span>
              )}
            </div>
          );
        },
      },

      {
        dataField: "hrs",
        text: "Totals",
        editable: false,
        searchable: false,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="total-hours d-flex">
              <span className="total-hours-shift">{row.hrs}</span>
              <span className="total-hours-subtitle">Scheduled</span>
            </div>
          );
        },
      },

      {
        dataField: "actHours",
        text: "",
        editable: false,
        searchable: false,
        formatter: (cell: any, row: any, rowIndex: any) => {
          const actualNumber = Number(row.actHours.replace(":", "."));
          const sceduleNumber = Number(row.hrs.replace(":", "."));
          return (
            <div className="total-hours-actual d-flex">
              <span
                className={
                  actualNumber > sceduleNumber
                    ? "total-hours-actual-hours red"
                    : "total-hours-actual-hours"
                }
              >
                {actualNumber > 0 ? row.actHours : "-"}
              </span>
              {actualNumber > 0 && (
                <span className="total-hours-actual-hours-subtitle">
                  Actual
                </span>
              )}
            </div>
          );
        },
      },

      {
        dataField: "action",
        text: "",
        editable: false,
        searchable: false,
        hidden: !this.state.showForcePunch,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (<>{((this.isPunchOutTimeExceedCurrentTime(row?.outDate, row?.displaySchedule?.split("-")[1]?.trim()) || (row.status === "Punched In")) ||
            (this.isPunchOutTimeExceedCurrentTime(row?.outDate, row?.displaySchedule?.split("-")[1]?.trim()) || (row.status === "Late"))) &&
            <div className="OverviewUserPunchedDetails">
              {row.status !== "No Show" && (
                <Dropdown
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className="more-action"
                  alignRight
                >
                  <Dropdown.Toggle className="btn-outline-primary btn btn-primary btn-ghost more" id="dropdown-more">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                      <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                      <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                    </svg>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>

                    {!row.modalShow ? (<span onClick={(e: any) => this.showUserPunch(e, row)}>{this.setDefaultPunch(row)}</span>) : (
                      <div className="OverviewUserPunched">
                        <OutsideClickHandler onOutsideClick={(e: any) => this.hideUserPunch(row)}>
                          <form noValidate onSubmit={(e) => this.submitPunch(e, row, this.setDefaultPunch(row))}>
                            <div className="list list-header">
                              <div className="ttl">
                                Enter explanation for forced {this.setDefaultPunch(row)?.toLocaleLowerCase()}
                              </div>
                            </div>
                            <div className="pading">

                              <div className="list">
                                <div className="ttl">{this.setDefaultPunch(row)} Time</div>
                                <div className="control">
                                  <input
                                    type="time"
                                    id="inTimedupappt"
                                    name="inTimedupappt"
                                    className="html-time-picker"
                                    value={row.punchTimeValue}
                                    onChange={(e: any) =>
                                      this.punchTimeChange(e, row, rowIndex)
                                    }
                                    required
                                  />
                                </div>
                              </div>
                              <div className="list">
                                <div className="ttl">Notes</div>
                                <div className="control">
                                  <Form.Control
                                    className="input-notes"
                                    as="textarea"
                                    placeholder="Explanation required..."
                                    value={row.punchCommentValue}
                                    onChange={(e: any) =>
                                      this.commentChange(e, row, rowIndex)
                                    }

                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="footer d-flex justify-content-end">
                              <Dropdown.Item className="btn btnClose" >Close</Dropdown.Item>
                              &nbsp;
                              <button ref={this.saveBtnRef} type="submit" className="btn btn-primary">Save</button>
                            </div>
                          </form>
                        </OutsideClickHandler>
                      </div>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          }
          </>
          )
        },
      },
    ];


    return (
      <>
        <div className="manager-logout-notification d-flex">
          <span className="manager-name">
            Logged in as Manager{" "}
            <span className="name-bold">
              {this.props.parentState.managerName}
            </span>
          </span>
          <button
            className="log-out-btn btn btn-primary btn-outline-primary"
            onClick={() => this.managerLogOut()}
          >
            Logout
          </button>
        </div>
        <div className="time-clock-container container-fluid">
          <div className="manager-view">
            <div className="time-clock-heading d-flex">
              <span className="schedule-heading">
                Employees Scheduled to Work
              </span>
              {this.state.isSchedulingPermission && (
                <button
                  className="schedule-employee-btn btn btn-primary"
                  onClick={() => this.addEditShiftModalShow()}
                >
                  Schedule Employee
                </button>
              )}
            </div>

            <div className="manager-table-view tdHasDropdown">
              <ToolkitProvider
                keyField="rowNo"
                data={this.state.tableData}
                columns={columns}
                search
              >
                {(props: {
                  searchProps: JSX.IntrinsicAttributes;
                  baseProps: JSX.IntrinsicAttributes;
                }) => (
                  <>
                    <div className="manager-navigation d-flex">
                      <div className="manager-table-search">
                        {/* <SearchBar
                          autoComplete="off"
                          {...props.searchProps}
                          placeholder="Search..."
                        /> */}
                         <TableSearch
                          id="tableSearch"
                          key="tableSearch"
                          {...props.searchProps}
                          placeholder={"Search..."}
                        />
                      </div>

                      <div className="calender-container">
                        <div
                          className={
                            this.state.calenderShow
                              ? "calender-display open"
                              : "calender-display"
                          }
                          onClick={this.showCalender}
                        >
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z" />
                            <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z" />
                          </svg>

                          <span className="current-Date">
                            {this.state.calenderDisplayDate}
                          </span>
                          <span className="chevron-down">
                            <FiChevronDown />
                          </span>
                        </div>

                        {this.state.calenderShow && (
                          <div className="calender">
                            <OutsideClickHandler
                              onOutsideClick={this.hideCalender}
                            >
                              <Calendar
                                calendarType={"US"}
                                onChange={this.calenderChange}
                                value={this.state.calenderSelectDate}
                              />
                            </OutsideClickHandler>
                          </div>
                        )}
                      </div>

                      <div className="department-filter">
                        {this.state.departmentList.length > 0 && (
                          <SingleSearchDropdownList
                            itemList={this.state.departmentList}
                            handleSelectedItem={this.handleSelectedItem.bind(
                              this,
                              2
                            )}
                            defaultItem={this.state.departmentName}
                            defaultText={"Search Department"}
                            defaultName={"Department"}
                            controlID="2"
                          />
                        )}
                      </div>
                      <div className="mr-auto position-filter">
                        {this.state.positionList.length > 0 && (
                          <SingleSearchDropdownList
                            itemList={this.state.positionList}
                            handleSelectedItem={this.handleSelectedItem.bind(
                              this,
                              1
                            )}
                            defaultItem={this.state.positionName}
                            defaultText={"Search Position"}
                            defaultName={"Position"}
                            controlID="1"
                          />
                        )}
                      </div>
                    </div>

                    <div className="table tccWebtimeTable">
                      <BootstrapTable
                        {...props.baseProps}
                        keyField="id"
                        hover
                        condensed
                      />
                    </div>
                  </>
                )}
              </ToolkitProvider>
            </div>
          </div>
        </div>

        {this.state.addEditShiftModal && (
          <AddEditShiftModal
            parentState={this.state}
            addEditShiftModalHide={this.addEditShiftModalHide}
            sendEmail={this.sendEmail}
            component="webTimeClock"
          />
        )}
      </>
    );
  }
}