import React from "react";
import GreyTickMark from "../../../../../Common/Assets/Images/icons/grey_tick_mark.svg";
import AutoPunchOut from "./AutoPunchOut";
import OverBudgetsDept from "./OverBudgetsDept";
import UnderBudgetsDept from "./UnderBudgetsDept";
import { Button } from "react-bootstrap";
export default class LaborDayApprovalDetails extends React.Component<any, any> {
  private laborDayDetails: any;
  private laborAPOChild: any;
  private laborUnderBudgetChild: any;
  private laborOverBudgetChild: any;

  constructor(props: any) {
    super(props);
    this.laborDayDetails = React.createRef();
    this.laborAPOChild = React.createRef();
    this.laborUnderBudgetChild = React.createRef();
    this.laborOverBudgetChild = React.createRef();
    this.state = {
      renderTable: "",
      row: props.row,
      isBackVisible: true,
      isContinueVisible: true,
    };
  }

  componentDidMount() {
    if (Number(this.state.row.autoPunchOut) === 0) {
      if (Number(this.state.row.overBudgetDept) === 0) {
        this.setState({
          renderTable: "underBudgetDepts",
          isBackVisible: false,
        });
      } else {
        if (Number(this.state.row.underBudgetDept) === 0) {
          this.setState({ isContinueVisible: false });
        }
        this.setState({ renderTable: "overBudgetDepts", isBackVisible: false });
      }
    } else {
      this.setState({ renderTable: "autoPunchOut" });
    }
  }

  labirDayNavigation = (event: string) => {
    if (event === "continue") {
      if (this.state.renderTable === "autoPunchOut") {
        if (Number(this.state.row.overBudgetDept) !== 0) {
          this.setState({ renderTable: "overBudgetDepts" });
          if (Number(this.state.row.autoPunchOut) !== 0) {
            this.setState({ isBackVisible: true });
          } else {
            this.setState({ isBackVisible: false });
          }
        } else {
          this.setState({ renderTable: "underBudgetDepts" });
          if (
            Number(this.state.row.autoPunchOut) !== 0 ||
            Number(this.state.row.overBudgetDept) !== 0
          ) {
            this.setState({ isBackVisible: true });
          } else {
            this.setState({ isBackVisible: false });
          }
        }
      } else if (this.state.renderTable === "overBudgetDepts") {
        this.setState({ renderTable: "underBudgetDepts" });
        if (
          Number(this.state.row.autoPunchOut) !== 0 ||
          Number(this.state.row.overBudgetDept) !== 0
        ) {
          this.setState({ isBackVisible: true });
        } else {
          this.setState({ isBackVisible: false });
        }
      } else {
      }
    } else {
      if (this.state.renderTable === "overBudgetDepts") {
        this.setState({ renderTable: "autoPunchOut" });
        if (
          Number(this.state.row.overBudgetDept) !== 0 ||
          Number(this.state.row.underBudgetDept) !== 0
        ) {
          this.setState({ isContinueVisible: true });
        } else {
          this.setState({ isContinueVisible: false });
        }
      } else if (this.state.renderTable === "underBudgetDepts") {
        if (Number(this.state.row.overBudgetDept) !== 0) {
          this.setState({ renderTable: "overBudgetDepts" });
          if (Number(this.state.row.underBudgetDept) !== 0) {
            this.setState({ isContinueVisible: true });
          } else {
            this.setState({ isContinueVisible: false });
          }
          if (Number(this.state.row.autoPunchOut) !== 0) {
            this.setState({ isBackVisible: true });
          } else {
            this.setState({ isBackVisible: false });
          }
        } else {
          this.setState({ renderTable: "autoPunchOut" });
          if (
            Number(this.state.row.overBudgetDept) !== 0 ||
            Number(this.state.row.underBudgetDept) !== 0
          ) {
            this.setState({ isContinueVisible: true });
          } else {
            this.setState({ isContinueVisible: false });
          }
        }
      } else {
      }
    }
  };

  dataApprove = (status: string, renderTable: string) => {
    let row = this.state.row;
    if (renderTable === "autoPunchOut" && status === "Approved") {
      row.autoPunchOut = row.autoPunchOut - 1;
      if (Number(row.autoPunchOut) === 0 && Number(row.overBudgetDept) === 0) {
        row.status = "Ready to Approve";
      }
      this.props.childApprove(row, renderTable);
      this.setState({ row: row });
      if (Number(row.autoPunchOut) === 0) {
        if (Number(row.overBudgetDept) !== 0) {
          this.setState({
            renderTable: "overBudgetDepts",
            isBackVisible: false,
          });
        } else if (Number(row.underBudgetDept) !== 0) {
          if (Number(row.autoPunchOut) !== 0) {
            this.setState({
              isBackVisible: true,
            });
          } else {
            this.setState({
              isBackVisible: false,
            });
          }
          this.setState({ renderTable: "underBudgetDepts" });
        } else {
          this.props.removeRow(row.rowNo);
        }
      }
    } else if (renderTable === "overBudget" && status === "Approved") {
      row.overBudgetDept = row.overBudgetDept - 1;
      if (Number(row.autoPunchOut) === 0 && Number(row.overBudgetDept) === 0) {
        row.status = "Ready to Approve";
      }
      this.props.childApprove(row, renderTable);
      this.setState({ row: row });
      if (Number(row.overBudgetDept) === 0) {
        if (Number(row.underBudgetDept) !== 0) {
          if (Number(row.autoPunchOut) !== 0) {
            this.setState({
              isBackVisible: true,
            });
          } else {
            this.setState({
              isBackVisible: false,
            });
          }
          this.setState({
            renderTable: "underBudgetDepts",
          });
        } else if (Number(row.autoPunchOut) !== 0) {
          this.setState({
            renderTable: "autoPunchOut",
            isContinueVisible: false,
          });
        } else {
          this.props.removeRow(row.rowNo);
        }
      }
    } else if (renderTable === "underBudget" && status === "Approved") {
      row.underBudgetDept = row.underBudgetDept - 1;
      if (Number(row.autoPunchOut) === 0 && Number(row.overBudgetDept) === 0) {
        row.status = "Ready to Approve";
      }
      this.props.childApprove(row, renderTable);
      this.setState({ row: row });
      if (Number(row.underBudgetDept) === 0) {
        if (Number(row.overBudgetDept) !== 0) {
          if (Number(row.autoPunchOut) !== 0) {
            this.setState({
              isBackVisible: true,
            });
          } else {
            this.setState({
              isBackVisible: false,
            });
          }
          this.setState({
            renderTable: "overBudgetDepts",
            isContinueVisible: false,
          });
        } else if (Number(row.autoPunchOut) !== 0) {
          this.setState({
            renderTable: "autoPunchOut",
            isContinueVisible: false,
          });
        } else {
          this.props.removeRow(row.rowNo);
        }
      }
    }
  };

  refreshDayAppChild() {
    if (this.laborAPOChild.current !== null) {
      this.laborAPOChild.current.refreshAPOChild();
    }

    if (this.laborUnderBudgetChild.current !== null) {
      this.laborUnderBudgetChild.current.refreshUnderBudgetChild();
    }

    if (this.laborOverBudgetChild.current !== null) {
      this.laborOverBudgetChild.current.refreshOverBudgetChild();
    }
  }

  handlePositionDetail(row: any) {
    let newrow = this.state.row;
    row.date = newrow.date;
    row.hotelID = newrow.hotelID;
    row.letterCode = newrow.letterCode;
    this.props.positionDetail(row);
  }

  render() {
    return (
      <>
        <div className="laborDayApprovalDetails" ref={this.laborDayDetails}>
          <div className="laborDayNavigation">
            <div className="labordayNavigationlabels">
              <span
                className={
                  this.state.renderTable === "autoPunchOut"
                    ? "labordayNavigationSpan active"
                    : Number(this.state.row.autoPunchOut) === 0
                    ? "labordayNavigationSpan disable"
                    : "labordayNavigationSpan"
                }
              >
                <span className="labordayNavigationNumber">
                  {Number(this.state.row.autoPunchOut) === 0 ? (
                    <span className="tickMark">
                      <img src={GreyTickMark} alt="GreyTickMark" />
                    </span>
                  ) : (
                    "1"
                  )}
                </span>
                <span className="labordayNavigationLabel">Auto Punch Outs</span>
              </span>
              <span className="laborDayApprovalDesign"></span>
              <span
                className={
                  this.state.renderTable === "overBudgetDepts"
                    ? "labordayNavigationSpan active"
                    : Number(this.state.row.overBudgetDept) === 0
                    ? "labordayNavigationSpan disable"
                    : "labordayNavigationSpan"
                }
              >
                <span className="labordayNavigationNumber">
                  {Number(this.state.row.overBudgetDept) === 0 ? (
                    <span className="tickMark">
                      <img src={GreyTickMark} alt="GreyTickMark" />
                    </span>
                  ) : (
                    "2"
                  )}
                </span>
                <span className="labordayNavigationLabel">
                  Over Budget Depts
                </span>
              </span>
              <span className="laborDayApprovalDesign"></span>
              <span
                className={
                  this.state.renderTable === "underBudgetDepts"
                    ? "labordayNavigationSpan active"
                    : Number(this.state.row.underBudgetDept) === 0
                    ? "labordayNavigationSpan disable"
                    : "labordayNavigationSpan"
                }
              >
                <span className="labordayNavigationNumber">
                  {Number(this.state.row.underBudgetDept) === 0 ? (
                    <span className="tickMark">
                      <img src={GreyTickMark} alt="GreyTickMark" />
                    </span>
                  ) : (
                    "3"
                  )}
                </span>
                <span className="labordayNavigationLabel">
                  Under Budget Depts
                </span>
              </span>
            </div>
            <div className="labordayNavigationbuttons">
              {this.state.renderTable !== "autoPunchOut" &&
                this.state.isBackVisible === true && (
                  <Button
                    className="btn-back laborDayApprovalBack"
                    onClick={() => this.labirDayNavigation("back")}
                  >
                    Back
                  </Button>
                )}
              {this.state.renderTable !== "underBudgetDepts" &&
                this.state.isContinueVisible === true && (
                  <Button
                    className="btn-outline-primary laborDayApprovalContinue"
                    onClick={() => this.labirDayNavigation("continue")}
                  >
                    Continue
                  </Button>
                )}
            </div>
          </div>
          {this.state.renderTable === "autoPunchOut" && (
            <AutoPunchOut
              ref={this.laborAPOChild}
              autoPunchoutApprove={this.dataApprove}
              row={this.state.row}
              positionDetail={this.handlePositionDetail.bind(this)}
            />
          



          )}
          {this.state.renderTable === "overBudgetDepts" && (
            <OverBudgetsDept
              ref={this.laborOverBudgetChild}
              overBudgetApprove={this.dataApprove}
              row={this.state.row}
              positionDetail={this.handlePositionDetail.bind(this)}
            />

        

          )}
          {this.state.renderTable === "underBudgetDepts" && (
            <UnderBudgetsDept
              ref={this.laborUnderBudgetChild}
              underBudgetApprove={this.dataApprove}
              row={this.state.row}
              positionDetail={this.handlePositionDetail.bind(this)}
            />

            
          )}
        </div>
      </>
    );
  }
}
