import React from "react";
import Joi from "joi-browser";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Row,
  Spinner,
  Modal,
} from "react-bootstrap";
import _ from "lodash";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { DropDownList } from "../../../Reports/common-components/dropdown-list";
import Calendar from "react-calendar";
import { Utils } from "../../../../Common/Utilis";
import { StaticArrays } from "../../../../Common/StaticArrays";
import { HotelWideSettings } from "../HotelWideSettings";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OutsideClickHandler from "react-outside-click-handler";
import BootstrapTable from "react-bootstrap-table-next";
import { AddPayrollAddress } from "../AddPayrollAddress";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import chevronRight from "../../../../Common/Assets/Images/icons/bx-chevron-right.svg";
import { LockInAddDepositAccount } from "./LockInAddDepositAccount";
import { UserManagement } from "../../../../Common/Services/UserManagement";
import { IEnterPriseWisePayroll } from "../../../../Common/Contracts/IEnterpriseWisePayroll";
import { AddDeduction } from "../AddDeduction";
import { AddGarnishment } from "../AddGarnishment";
import { IUserDeductionCriteria } from "../../../../Common/Contracts/IDeductionCriteria";
import { AnyNaptrRecord } from "dns";
import { confirmAlert } from "react-confirm-alert";
import ReactTooltip from "react-tooltip";
import { IHotelWideSettingProps } from "../../../../Common/Contracts/IHotelWideSettingProps";
import { IIncompleteUserRequest } from "../../../../Common/Contracts/IIncompleteUserRequest";
import { AgGridReact } from "ag-grid-react";
import { GridOptions } from "ag-grid-community";
import NumericEditor from "../NumericEditor";

export class LockInPayroll extends React.Component<any, any> {
  private enterpriseWideSettingFormSchema = Joi.object({
    ssn: Joi.string()
      .required()
      .min(11)
      .regex(/^\(?\d{3}-\)?\d{2}-?\d{4}$/i, "ssn")
      .error((errors) => {
        const _errors = errors.map((error) => {
          if (error?.message) {
            return;
          }
          switch (error.type) {
            case "any.empty":
              return { message: "Please enter SSN" };
            case "string.min":
              return {
                message:
                  "Incorrect SSN format. SSN should not start with 9 format should include nine digits, for example 123-45-7689",
              };
            case "string.regex.name":
              return {
                message:
                  "Incorrect SSN format. SSN should not start with 9 format should include nine digits, for example 123-45-7689",
              };
          }
        });

        return [_errors[0]];
      }),
    dob: Joi.string().allow(""),
    i9SubmittedOn: Joi.string().allow(""),
    w4Date: Joi.string().allow(""),
    gender: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select Gender" };
        });
      }),
    address: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please enter Address" };
        });
      }),
    jobType: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select Job Type" };
        });
      }),
    federalFiling: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select Federal Filing Status" };
        });
      }),
  });

  private excemptionsFormSchema = Joi.object({
    federalExemption: Joi.number()
      .min(0)
      .required()
      .error((errors) => {
        const _errors = errors.map((error) => {
          if (error?.message) {
            return;
          }
          switch (error.type) {
            case "number.base":
              return {
                message: "Please enter Federal Exemptions in numeric only",
              };
            case "number.min":
              return {
                message:
                  "Federal Exemptions value should be greater than or equal to 0",
              };
          }
        });
        return [_errors[0]];
      }),
    additionalFederal: Joi.number()
      .min(0)
      .required()
      .error((errors) => {
        const _errors = errors.map((error) => {
          if (error?.message) {
            return;
          }
          switch (error.type) {
            case "number.base":
              return {
                message: "Please enter Additional Withholdings in numeric only",
              };
            case "number.min":
              return {
                message:
                  "Additional Withholdings value should be greater than or equal to 0",
              };
          }
        });
        return [_errors[0]];
      }),
    futa: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select FUTA" };
        });
      }),
    fica: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select FICA" };
        });
      }),
    stateExemption: Joi.number()
      .min(0)
      .required()
      .error((errors) => {
        const _errors = errors.map((error) => {
          if (error?.message) {
            return;
          }
          switch (error.type) {
            case "number.base":
              return {
                message: "Please enter State Exemptions in numeric only",
              };
            case "number.min":
              return {
                message:
                  "State Exemptions value should be greater than or equal to 0",
              };
          }
        });
        return [_errors[0]];
      }),
  });

  private payrollWithholdingSettingsFormSchema = Joi.object({
    additionalState: Joi.number()
      .min(0.01)
      .required()
      .error((errors) => {
        const _errors = errors.map((error) => {
          if (error?.message) {
            return;
          }
          switch (error.type) {
            case "any.required":
              return {
                message:
                  "Please enter Additional State Withholdings in numeric only",
              };
            case "number.base":
              return {
                message:
                  "Please enter Additional State Withholdings in numeric only",
              };
            case "number.min":
              return {
                message:
                  "Additional State Withholdings value should be greater than or equal to 0",
              };
          }
        });
        return [_errors[0]];
      }),
    suta: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select SUTA Exempt" };
        });
      }),
    isJobs: Joi.boolean().optional(),
    otherIncome: Joi.number()
      .min(0.01)
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please enter Other Income in numeric only" };
        });
      }),

    creditAmt: Joi.number()
      .required()
      .min(0.01)
      .error((errors) => {
        return errors.map((error) => {
          return {
            message:
              "Please enter Dependents Credit Adjustment in numeric only",
          };
        });
      }),
    wksht: Joi.number()
      .required()
      .min(0.01)
      .error((errors) => {
        const _errors = errors.map((error) => {
          if (error?.message) {
            return;
          }
          switch (error.type) {
            case "number.base":
              return {
                message: "Please enter Deductions in numeric only",
              };
            case "number.min":
              return {
                message:
                  "Deductions value should be greater than or equal to 0",
              };
          }
        });
        return [_errors[0]];
      }),
    extraAmt: Joi.number()
      .required()
      .min(0.01)
      .error((errors) => {
        const _errors = errors.map((error) => {
          if (error?.message) {
            return;
          }
          switch (error.type) {
            case "number.base":
              return {
                message: "Please enter Extra Withholdings in numeric only",
              };
            case "number.min":
              return {
                message:
                  "Extra Withholdings value should be greater than or equal to 0",
              };
          }
        });
        return [_errors[0]];
      }),
  });

  private payrollWithholdingSettingsFormSchemaOptional = Joi.object({
    additionalState: Joi.number()
      .min(0)
      .error((errors) => {
        const _errors = errors.map((error) => {
          if (error?.message) {
            return;
          }
          switch (error.type) {
            case "number.base":
              return {
                message:
                  "Please enter Additional State Withholdings in numeric only",
              };
            case "number.min":
              return {
                message:
                  "Additional State Withholdings value should be greater than or equal to 0",
              };
          }
        });
        return [_errors[0]];
      }),
    suta: Joi.string().error((errors) => {
      return errors.map((error) => {
        return { message: "Please select SUTA Exempt" };
      });
    }),
    isJobs: Joi.boolean().optional(),
    otherIncome: Joi.number()
      .min(0)
      .allow(0)
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please enter Other Income in numeric only" };
        });
      }),

    creditAmt: Joi.number()
      .min(0)
      .allow(0)
      .error((errors) => {
        return errors.map((error) => {
          return {
            message:
              "Please enter Dependents Credit Adjustment in numeric only",
          };
        });
      }),
    wksht: Joi.number()
      .min(0)
      .allow(0)
      .error((errors) => {
        const _errors = errors.map((error) => {
          if (error?.message) {
            return;
          }
          switch (error.type) {
            case "number.base":
              return {
                message: "Please enter Deductions in numeric only",
              };
            case "number.min":
              return {
                message:
                  "Deductions value should be greater than or equal to 0",
              };
          }
        });
        return [_errors[0]];
      }),
    extraAmt: Joi.number()
      .min(0)
      .allow(0)
      .error((errors) => {
        const _errors = errors.map((error) => {
          if (error?.message) {
            return;
          }
          switch (error.type) {
            case "number.base":
              return {
                message: "Please enter Extra Withholdings in numeric only",
              };
            case "number.min":
              return {
                message:
                  "Extra Withholdings value should be greater than or equal to 0",
              };
          }
        });
        return [_errors[0]];
      }),
  });

  private sectionNames: any[] = [
    {
      id: 1,
      sectionName: "Direct Deposit",
    },
    {
      id: 2,
      sectionName: "Exemptions",
    },
    {
      id: 3,
      sectionName: "Withholdings",
    },
  ];

  gridApi: any;
  gridColumnApi: any;

  constructor(props: any) {
    super(props);
    this.state = {
      isFormValid: false,
      isEnterpriseWideDataLoaded: false,
      isDataSubmitting: false,
      showPayrollAddressModal: false,
      showDepositModal: false,
      showDeductionModal: false,
      deductionTableExpandend: [],
      tableExpended: this.props?.userGeneralInfo?.allowDeposit > 0 ? [1] : [],
      isExpand: this.props?.userGeneralInfo?.allowDeposit > 0 ? true : false,
      calenderShow: false,
      isEnterpriseWideSettingNotChanged: false,
      isAllowDeposit:
        +this.props?.userGeneralInfo?.allowDeposit > 0 ? true : false,
      isExemptionsNotChanged: false,
      inputPosition: 0,
      enterpriseWideSettingErrors: {},
      exemptionsErrors: {},
      enterPriseWisePayrollData: {},
      prevEnterPriseWisePayrollData: {},
      depositAccountsData: [],
      depositRowForAddEdit: 1,
      depositRowEdit: {},
      enterpriseWideSetting: {
        ssn: "",
        dob: "",
        gender: "",
        address: "",
        jobType: "",
        federalFiling: "",
        i9SubmittedOn: "",
        w4Date: "",
      },
      excemptions: {
        federalExemption: 0,
        additionalFederal: 0,
        futa: "",
        fica: "",
        stateExemption: 0,
      },
      payrollWithholdingSettings: {
        additionalState: "",
        suta: "",
        isJobs: false,
        otherIncome: "",
        creditAmt: "",
        wksht: "",
        extraAmt: "",
      },
      payrollWithholdingSettingsErrors: {},
      sectionNames: this.sectionNames,
      deductions: [],
      deductionRowEdit: {},
      garnishments: [],
      ganishmentTableExpandend: [],
      isGarnishmentExpand: false,
      showGarnishmentModal: false,
      isDeductionLoaded: false,
      showPayrollSettingBanner: false,
      garnishmentRowEdit: {},
      hotelWideTableData: [],
      showHotelWiseAddEditModal: false,
      showSpecialRateAddEditModal: false,
      hotelWisePayrollRowEdit: {},
      specialRateRowEdit: {},
      hotelWideTableExpended: [],
      spacialRates: [],
      isHotelWisePayrollLoaded: false,
      isSpecialRateLoaded: false,
      isHotelWideExpand: false,
      hotelWideRow: {},
      ptoRow: {
        id: 1,
        sectionName: "Direct Deposit",
      },
      enterpriseWideSettingNotChangedForDate: false,
    };
  }
  componentDidMount() {
    this.getDeductionsData();

    // this.setState(
    //   {
    //     enterPriseWisePayrollData: this.props?.enterPriseWisePayrollData,
    //     prevEnterPriseWisePayrollData: {
    //       ...this.props?.enterPriseWisePayrollData,
    //     },
    //   },
    //   () => {
    //     this.setPayrollData();

    //     this.updatePayrollSettingBannerFlag(
    //       this.props?.enterPriseWisePayrollData
    //     );
    //   }
    // );
    this.getGarnishmentsData();
    this.getHotelWisePayrollData();
    this.getEnterPriseWisePayrollData();
  }
  getHotelWisePayrollData = (tabName = "") => {
    this.setState({ hotelWideTableData: [], isHotelWisePayrollLoaded: false });
    UserManagement.GetHotelWisePayroll(+this.props?.userUniqueNo)
      .then((hotelWideTableData) => {
        if (hotelWideTableData?.length > 0) {
          hotelWideTableData = hotelWideTableData.map((x) => {
            x.empType1 = this.props?.userGeneralInfo?.employeeTypeDisplay;
            return x;
          });
        }

        this.setState({ hotelWideTableData }, () =>
          this.setState({ isHotelWisePayrollLoaded: true })
        );
      })
      .catch(() => this.setState({ isHotelWisePayrollLoaded: true }));
  };

  getEnterPriseWisePayrollData = (isDefaultLoad = false) => {
    this.setState({ isEnterpriseWideDataLoaded: false });
    UserManagement.GetEnterPriseWisePayroll(
      this.props?.userUniqueNo,
      "lockslideout"
    )
      .then((enterPriseWisePayrollData: IEnterPriseWisePayroll) => {
        enterPriseWisePayrollData.i9SubmittedOn =
          enterPriseWisePayrollData?.i9SubmittedOn
            ? moment(enterPriseWisePayrollData?.i9SubmittedOn)?.format(
                "MM/DD/YYYY"
              )
            : "";
        enterPriseWisePayrollData.w4Date = enterPriseWisePayrollData?.w4Date
          ? moment(enterPriseWisePayrollData?.w4Date)?.format("MM/DD/YYYY")
          : "";

        this.setState(
          {
            enterPriseWisePayrollData,
            prevEnterPriseWisePayrollData: { ...enterPriseWisePayrollData },
          },
          () => {
            this.setPayrollData();

            this.updatePayrollSettingBannerFlag(enterPriseWisePayrollData);
            this.checkEnterpriseWideSettingFieldFormModified();
          }
        );
      })
      .finally(() => this.setState({ isEnterpriseWideDataLoaded: true }));
  };

  updatePayrollSettingBannerFlag = (enterPriseWisePayrollData) => {
    this.setState({
      showPayrollSettingBanner: !enterPriseWisePayrollData?.ssn,
    });
  };

  updatePayrollTabCount = () => {
    const { enterPriseWisePayrollData, isAllowDeposit, depositAccountsData } =
      this.state;
    const payrollValue = !enterPriseWisePayrollData?.ssn ? 1 : 0;
    const depositValue =
      isAllowDeposit && depositAccountsData?.length === 0 ? 1 : 0;

    this.props?.updatePayrollCount(payrollValue, depositValue);
  };

  getDeductionsData = (isDefaultLoad = false) => {
    this.setState({ isDeductionLoaded: false });
    UserManagement.GetUserDeduction(this.props?.userGeneralInfo?.user_UniqueId)
      .then((deductions: any) => {
        if (deductions?.length > 0) {
          deductions = deductions.map((x) => {
            x.createdDate = x.createdDate
              ? moment(x.createdDate)?.format("MM/DD/YYYY")
              : "";
            return x;
          });
        }
        this.setState(
          {
            deductions,
          },
          () => {
            this.setState({ isDeductionLoaded: true });
          }
        );
      })
      .catch(() => this.setState({ isDeductionLoaded: true }));
  };

  getGarnishmentsData = (isDefaultLoad = false) => {
    UserManagement.GetUserGarnishment(this.props?.userUniqueNo).then(
      (garnishments: any) => {
        if (garnishments?.length > 0) {
          garnishments = garnishments.map((x) => {
            x.date = x.date ? moment(x.date)?.format("MM/DD/YYYY") : "";
            return x;
          });
        }
        this.setState({
          garnishments,
        });
      }
    );
  };

  setPayrollData = () => {
    this.setEnteriseWideSettingValues();
    this.setExemptionsValues();
    this.setPayrollWithholdingSettingsValues();

    this.buildDepositTableData();
  };

  setEnteriseWideSettingValues = () => {
    const data = this.state.enterPriseWisePayrollData as IEnterPriseWisePayroll;
    const enterpriseWideSetting: any = {
      ssn: data.ssn,
      dob: data.dob,
      gender: data.gender || "",
      address: this.buildAddress(data),
      jobType: data.jobType || "",
      federalFiling: data.federalFiling || "",
      i9SubmittedOn: data?.i9SubmittedOn || "",
      w4Date: data?.w4Date || "",
    };

    this.setState({ enterpriseWideSetting });
  };

  buildDepositTableData = () => {
    const data = this.state.enterPriseWisePayrollData as IEnterPriseWisePayroll;
    let depositAccountsData: any[] = [];

    const row1: any = {
      directDeposit: data.directDeposit,
      bankName: data.bankName,
      accountType: data.accountType,
      routingNumber: data.routingNumber,
      accountNumber: data.accountNumber,
      ddAmountType:
        data.ddAmountType === "Total Payroll"
          ? "Entire Paycheck"
          : data.ddAmountType === "$Amount of Paycheck"
          ? "$ Amount of Paycheck"
          : data.ddAmountType,
      ddAmount: data.ddAmount,
      ddAmountRaw: data.ddAmountRaw,
      rowNo: 1,
    };

    const row2: any = {
      directDeposit: data.directDeposit2,
      bankName: data.bankName2,
      accountType: data.accountType2,
      routingNumber: data.routingNumber2,
      accountNumber: data.accountNumber2,
      ddAmountType:
        data.ddAmountType2 === "Total Payroll"
          ? "Entire Paycheck"
          : data.ddAmountType2 === "$Amount of Paycheck"
          ? "$ Amount of Paycheck"
          : data.ddAmountType2,
      ddAmount: data.ddAmount2,
      ddAmountRaw: data.ddAmount2Raw,
      rowNo: 2,
    };

    if (data?.directDeposit && data.accountType) {
      depositAccountsData.push(row1);
    }
    if (data?.directDeposit2 && data.accountType2) {
      depositAccountsData.push(row2);
    }
    depositAccountsData = depositAccountsData.map((row) => {
      row.isRowDataSubmitting = false;
      row.dataLength = depositAccountsData.length;
      return row;
    });
    this.setState({ depositAccountsData }, () => this.updatePayrollTabCount());
  };

  setExemptionsValues = () => {
    const data = this.state.enterPriseWisePayrollData as IEnterPriseWisePayroll;
    const excemptions: any = {
      federalExemption: data.federalExemption,
      additionalFederal: data.additionalFederal,
      futa: data.futa || "",
      fica: data.fica || "",
      stateExemption: data.stateExemption,
    };

    this.setState({ excemptions });
  };

  reAttachExemptionsValues = () => {
    const { enterPriseWisePayrollData, excemptions } = this.state;
    enterPriseWisePayrollData.federalExemption = excemptions.federalExemption;
    enterPriseWisePayrollData.additionalFederal = excemptions.additionalFederal;
    enterPriseWisePayrollData.futa = excemptions.futa;
    enterPriseWisePayrollData.fica = excemptions.fica;
    enterPriseWisePayrollData.stateExemption = excemptions.stateExemption;
    this.setState({ excemptions });
  };

  setPayrollWithholdingSettingsValues = () => {
    const data = this.state.enterPriseWisePayrollData as IEnterPriseWisePayroll;
    const payrollWithholdingSettings: any = {
      additionalState: data.additionalState,
      suta: data.suta || "",
      isJobs: (data.isJobs === "true" ? true : false) || false,
      otherIncome: data.otherIncome,
      creditAmt: data.creditAmt,
      wksht: data.wksht,
      extraAmt: data.extraAmt,
    };
    this.setState({ payrollWithholdingSettings });
  };

  reAttachPayrollWithholdingSettingsValues = () => {
    const { enterPriseWisePayrollData, payrollWithholdingSettings } =
      this.state;
    enterPriseWisePayrollData.additionalState =
      payrollWithholdingSettings.additionalState;
    enterPriseWisePayrollData.suta = payrollWithholdingSettings.suta;
    enterPriseWisePayrollData.isJobs =
      payrollWithholdingSettings.isJobs.toString() || "false";
    enterPriseWisePayrollData.otherIncome =
      payrollWithholdingSettings.otherIncome;
    enterPriseWisePayrollData.creditAmt = payrollWithholdingSettings.creditAmt;
    enterPriseWisePayrollData.wksht = payrollWithholdingSettings.wksht;
    enterPriseWisePayrollData.extraAmt = payrollWithholdingSettings.extraAmt;
    this.setState({ enterPriseWisePayrollData });
  };

  reAttachEnteriseWideSettingValuesToMainObject = () => {
    const { enterPriseWisePayrollData, enterpriseWideSetting } = this.state;
    enterPriseWisePayrollData.ssn = enterpriseWideSetting.ssn;
    enterPriseWisePayrollData.dob = enterpriseWideSetting.dob;
    enterPriseWisePayrollData.gender = enterpriseWideSetting.gender;
    enterPriseWisePayrollData.jobType = enterpriseWideSetting.jobType;
    enterPriseWisePayrollData.federalFiling =
      enterpriseWideSetting.federalFiling;
    enterPriseWisePayrollData.i9SubmittedOn =
      enterpriseWideSetting.i9SubmittedOn;
    enterPriseWisePayrollData.w4Date = enterpriseWideSetting.w4Date;
    this.setState({ enterPriseWisePayrollData });
  };

  reAttachEnteriseWideSettingAddressToMainObject = (data) => {
    const { enterPriseWisePayrollData, enterpriseWideSetting } = this.state;
    enterPriseWisePayrollData.street = data.street;
    enterPriseWisePayrollData.city = data.city;
    enterPriseWisePayrollData.state = data.state;
    enterPriseWisePayrollData.zip = data.zip;
    enterPriseWisePayrollData.country = data.country;
    enterPriseWisePayrollData.school = data.school;
    enterpriseWideSetting.address = this.buildAddress(data);
    enterpriseWideSetting.i9SubmittedOn = data?.i9SubmittedOn;
    enterpriseWideSetting.w4Date = data?.w4Date;
    this.setState({ enterPriseWisePayrollData, enterpriseWideSetting }, () => {
      this.validateEnterpriseWideSettingFormSchema();
      this.checkEnterpriseWideSettingFieldFormModified();
    });
  };

  buildAddress = (data: any) => {
    return data.state
      ? `${data.street}, ${data.city}, ${data.state}, ${data.zip}`
      : "";
  };

  onEnterpriseWideSettingFieldChange = (
    event,
    inputPosition,
    isBlur = false
  ) => {
    let value = event.target.value;
    const fieldName = event.target.name;

    const enterpriseWideSetting = _.cloneDeep(this.state.enterpriseWideSetting);

    if (
      fieldName === "dob" ||
      fieldName === "w4Date" ||
      fieldName === "i9SubmittedOn"
    ) {
      const _formattedDate = Utils.AddSlashINInputDateFormat(
        value,
        enterpriseWideSetting?.dob,
        true,
        4,
        isBlur
      );
      if (_formattedDate === "invalid") {
        return;
      } else {
        value = _formattedDate;
      }
    }

    enterpriseWideSetting[fieldName] = value;

    this.setState({ enterpriseWideSetting, inputPosition }, () => {
      this.validateEnterpriseWideSettingFormSchema();
      this.reAttachEnteriseWideSettingValuesToMainObject();
      if (!isBlur) {
        this.checkEnterpriseWideSettingFieldFormModified();
      }
    });
  };

  checkEnterpriseWideSettingFieldFormModified = (isLoadedFromApi = false) => {
    const {
      enterPriseWisePayrollData,
      prevEnterPriseWisePayrollData,
      enterpriseWideSetting,
    } = this.state;
    let _isEnterpriseWideSettingNotChanged = _.isEqual(
      enterPriseWisePayrollData,
      prevEnterPriseWisePayrollData
    );
    const main = {
      federalFiling: enterPriseWisePayrollData?.federalFiling,
      additionalState: enterPriseWisePayrollData?.additionalState,
      suta: enterPriseWisePayrollData?.suta,
      isJobs: enterPriseWisePayrollData?.isJobs,
      otherIncome: enterPriseWisePayrollData?.otherIncome,
      creditAmt: enterPriseWisePayrollData?.creditAmt,
      wksht: enterPriseWisePayrollData?.wksht,
      extraAmt: enterPriseWisePayrollData?.extraAmt,
    };

    const comparison = {
      federalFiling: prevEnterPriseWisePayrollData?.federalFiling,
      additionalState: prevEnterPriseWisePayrollData?.additionalState,
      suta: prevEnterPriseWisePayrollData?.suta,
      isJobs: prevEnterPriseWisePayrollData?.isJobs,
      otherIncome: prevEnterPriseWisePayrollData?.otherIncome,
      creditAmt: prevEnterPriseWisePayrollData?.creditAmt,
      wksht: prevEnterPriseWisePayrollData?.wksht,
      extraAmt: prevEnterPriseWisePayrollData?.extraAmt,
    };

    let _isEnterpriseWideSettingNotChangedForDate = _.isEqual(main, comparison);
    if (_isEnterpriseWideSettingNotChangedForDate === false) {
      enterPriseWisePayrollData.w4Date = moment().format("MM/DD/YYYY");
      enterpriseWideSetting.w4Date = moment().format("MM/DD/YYYY");
      prevEnterPriseWisePayrollData.w4Date = moment().format("MM/DD/YYYY");
      this.setState({
        enterPriseWisePayrollData,
        prevEnterPriseWisePayrollData,
      });
      _isEnterpriseWideSettingNotChanged = _.isEqual(
        enterPriseWisePayrollData,
        prevEnterPriseWisePayrollData
      );
    }
    this.setState({
      isEnterpriseWideSettingNotChanged: _isEnterpriseWideSettingNotChanged,
      enterpriseWideSettingNotChangedForDate:
        _isEnterpriseWideSettingNotChangedForDate,
    });
    this.props.getPayrollTabModificationStatus(
      !_isEnterpriseWideSettingNotChanged
    );
  };

  getEnterpriseWideSettingFieldFieldError = (
    fieldName: string,
    fieldPosition: number
  ) => {
    const { enterpriseWideSettingErrors } = this.state;

    return (
      <>
        {Object.keys(enterpriseWideSettingErrors).length > 0 &&
          fieldPosition <= this.state.inputPosition && (
            <span className="validation-message">
              {enterpriseWideSettingErrors[fieldName]}
            </span>
          )}
      </>
    );
  };

  validateEnterpriseWideSettingFormSchema = () => {
    const { enterpriseWideSetting } = this.state;

    const newErrorObject: any = {};
    this.setState({ enterpriseWideSettingErrors: {} });

    this.setState({ isFormValid: true });

    return newErrorObject;
  };

  validationEnterpriseWideSettingOnBlur = (inputPosition) => {
    this.setState({ inputPosition });
    this.validateEnterpriseWideSettingFormSchema();
  };

  onExcemptionsFieldChange = (event, inputPosition, isBlur = false) => {
    let value = event.target.value;
    const fieldName = event.target.name;
    if (
      (fieldName === "federalExemption" ||
        fieldName === "additionalFederal" ||
        fieldName === "stateExemption") &&
      value?.toString()?.split(".")[1]?.length > 2
    ) {
      return;
    }

    const excemptions = _.cloneDeep(this.state.excemptions);
    excemptions[fieldName] = value;
    this.setState({ excemptions, inputPosition }, () => {
      this.validateExemptionsFormSchema();
      this.reAttachExemptionsValues();
      if (!isBlur) {
        this.checkEnterpriseWideSettingFieldFormModified();
      }
      if (!this.props?.isNewUserAdded) {
      }
    });
  };

  validateExemptionsFormSchema = () => {
    const { excemptions } = this.state;
    const valid = Joi.validate(excemptions, this.excemptionsFormSchema, {
      abortEarly: false,
    });
    const newErrorObject = {};
    if (valid.error) {
      valid.error.details.forEach((err) => {
        newErrorObject[err.path.join(".")] = err.message;
      });
    }
    this.setState({ exemptionsErrors: newErrorObject });
    if (Object.keys(newErrorObject).length === 0) {
      this.setState({ isFormValid: true });
    } else {
      this.setState({ isFormValid: false });
    }
    return newErrorObject;
  };
  componentWillUnmount = () => {
    // setTimeout(()=> {
    //   toast.dismiss();
    // },1000)
  };
  validationExcemptionsOnBlur = (inputPosition) => {
    this.setState({ inputPosition });
    this.validateExemptionsFormSchema();
  };

  getExcemptionsFieldError = (fieldName: string, fieldPosition: number) => {
    const { exemptionsErrors } = this.state;

    return (
      <>
        {Object.keys(exemptionsErrors).length > 0 &&
          fieldPosition <= this.state.inputPosition && (
            <span className="validation-message">
              {exemptionsErrors[fieldName]}
            </span>
          )}
      </>
    );
  };

  onPayrollWithholdingSettingsFieldChange = (
    event,
    inputPosition,
    isBlur = false
  ) => {
    let value = event.target.value;
    if (value < 0) {
      return;
    }
    const fieldName = event.target.name;

    if (
      (fieldName === "additionalState" ||
        fieldName === "creditAmt" ||
        fieldName === "otherIncome" ||
        fieldName === "wksht" ||
        fieldName === "extraAmt") &&
      value?.toString()?.split(".")[1]?.length > 2
    ) {
      return;
    }

    const payrollWithholdingSettings = _.cloneDeep(
      this.state.payrollWithholdingSettings
    );
    payrollWithholdingSettings[fieldName] = value;
    this.setState({ payrollWithholdingSettings, inputPosition }, () => {
      this.validateExemptionsFormSchema();
      this.reAttachPayrollWithholdingSettingsValues();
      if (!isBlur) {
        this.checkEnterpriseWideSettingFieldFormModified();
      }
    });
  };

  validatePayrollWithholdingSettingsFormSchema = () => {
    const { payrollWithholdingSettings } = this.state;
    const {
      excemptions,
      enterpriseWideSetting,
      prevEnterPriseWisePayrollData,
    } = this.state;

    let schema = this.payrollWithholdingSettingsFormSchemaOptional;
    if (
      !this.props?.isNewUserAdded &&
      (enterpriseWideSetting?.federalFiling !==
        prevEnterPriseWisePayrollData?.federalFiling ||
        excemptions?.federalExemption !==
          prevEnterPriseWisePayrollData?.federalExemption)
    ) {
      schema = this.payrollWithholdingSettingsFormSchema;
    }

    const valid = Joi.validate(payrollWithholdingSettings, schema, {
      abortEarly: false,
    });
    const newErrorObject = {};
    if (valid.error) {
      valid.error.details.forEach((err) => {
        newErrorObject[err.path.join(".")] = err.message;
      });
    }
    this.setState({ payrollWithholdingSettingsErrors: newErrorObject });
    if (Object.keys(newErrorObject).length === 0) {
      this.setState({ isFormValid: true });
    } else {
      this.setState({ isFormValid: false });
    }
    return newErrorObject;
  };

  validationPayrollWithholdingSettingsOnBlur = (inputPosition) => {
    this.setState({ inputPosition });
    this.validatePayrollWithholdingSettingsFormSchema();
  };

  getPayrollWithholdingSettingsFieldError = (
    fieldName: string,
    fieldPosition: number
  ) => {
    const { payrollWithholdingSettingsErrors } = this.state;

    return (
      <>
        {Object.keys(payrollWithholdingSettingsErrors).length > 0 &&
          fieldPosition <= this.state.inputPosition && (
            <span className="validation-message">
              {payrollWithholdingSettingsErrors[fieldName]}
            </span>
          )}
      </>
    );
  };

  calenderHide = () => {
    this.setState({ calenderShow: false });
  };

  calenderShow = () => {
    this.setState({ calenderShow: true });
  };
  calenderHideI9 = () => {
    this.setState({ calenderShowI9: false });
  };

  calenderShowI9 = () => {
    this.setState({ calenderShowI9: true });
  };

  calenderHideW4 = () => {
    this.setState({ calenderShowW4: false });
  };

  calenderShowW4 = () => {
    this.setState({ calenderShowW4: true });
  };

  openAddressEntryPanel = () => {
    this.setState({ inputPosition: 4 });
    this.setState({ showPayrollAddressModal: true });
  };
  hidePayrollAddressModal = (isReloadRequired: false) => {
    this.setState({ showPayrollAddressModal: false });
  };

  openDepositEntryPanel = (isEdit: boolean = false, row: any = {}) => {
    if (isEdit) {
      if (row?.rowNo === 1) {
        const data = this.state.enterPriseWisePayrollData;
        row.ddAmountType =
          data.ddAmountType === "Entire Paycheck"
            ? "Total Payroll"
            : data.ddAmountType == "$ Amount of Paycheck"
            ? "$Amount of Paycheck"
            : data.ddAmountType;
        row.ddAmountType2 = data?.ddAmountType2 || "";
        row.ddAmount2 = data?.ddAmount2;
      }

      this.setState(
        { depositRowEdit: row, depositRowForAddEdit: row?.rowNo },
        () => {
          this.setState({ showDepositModal: true });
        }
      );
    } else {
      this.setState(
        {
          depositRowEdit: {},
          depositRowForAddEdit: this.state.depositAccountsData?.length + 1,
        },
        () => {
          this.setState({ showDepositModal: true });
        }
      );
    }
  };
  hideDepositModal = (isReloadRequired: false) => {
    this.setState({ showDepositModal: false, deductionRowEdit: {} });
  };

  openDeductionEntryPanel = (isEdit: boolean = false, row: any = {}) => {
    if (isEdit) {
      this.setState({ deductionRowEdit: row }, () => {
        this.setState({ showDeductionModal: true });
      });
    } else {
      if (
        this.props?.userGeneralInfo?.hids.filter(
          (x) =>
            !this.state?.deductions
              ?.map((l) => l?.letterCode)
              ?.includes(x?.lettercode)
        ).length === 0
      ) {
        toast.success("Deductions added for all assigned EHID's", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "laborTabUM",
        });
        return;
      }

      this.setState(
        {
          deductionRowEdit: {},
        },
        () => {
          this.setState({ showDeductionModal: true });
        }
      );
    }
  };

  hideDeductionModal = (isReloadRequired: false) => {
    if (isReloadRequired) {
      this.props?.getUserGeneralInfo();
      this.getDeductionsData();
    }
    this.setState({ showDeductionModal: false, deductionRowEdit: {} });
  };

  openGarnishmentEntryPanel = (isEdit: boolean = false, row: any = {}) => {
    if (isEdit) {
      this.setState({ garnishmentRowEdit: row }, () => {
        this.setState({ showGarnishmentModal: true });
      });
    } else {
      this.setState(
        {
          garnishmentRowEdit: {},
        },
        () => {
          this.setState({ showGarnishmentModal: true });
        }
      );
    }
  };

  hideGarnishmentModal = (isReloadRequired: false) => {
    if (isReloadRequired) {
      this.getGarnishmentsData();
    }
    this.setState({ showGarnishmentModal: false, garnishmentRowEdit: {} });
  };

  saveEnterpriseWiseSetting = (
    message = "User data saved successfully",
    handleDirectDepositError = () => {},
    PreviousEnterPriseWisePayrollData = null
  ) => {
    const enterPriseWisePayrollData: IEnterPriseWisePayroll = this.state
      .enterPriseWisePayrollData as IEnterPriseWisePayroll;
    const { userGeneralInfo } = this.props;
    const request: IIncompleteUserRequest = {
      userUniqueno: userGeneralInfo?.uniqueno,
      username: userGeneralInfo?.userName,
      email: userGeneralInfo?.email,
      phone: userGeneralInfo?.isDuplicatePhone ? "" : userGeneralInfo?.phone1,
      primaryContact: userGeneralInfo?.primaryContact,
      directDeposit: enterPriseWisePayrollData.directDeposit,
      bankName: enterPriseWisePayrollData.bankName,
      accountType: enterPriseWisePayrollData.accountType,
      routingNumber: enterPriseWisePayrollData.routingNumber,
      accountNumber: enterPriseWisePayrollData.accountNumber,
      dDAmountType: enterPriseWisePayrollData.ddAmountType,
      dDAmount: enterPriseWisePayrollData.ddAmount,
      bankName2: enterPriseWisePayrollData.bankName2,
      accountType2: enterPriseWisePayrollData.accountType2,
      routingNumber2: enterPriseWisePayrollData.routingNumber2,
      accountNumber2: enterPriseWisePayrollData.accountNumber2,
      dDAmountType2: enterPriseWisePayrollData.ddAmountType2,
      dDAmount2: enterPriseWisePayrollData.ddAmount2,
      directDeposit2: enterPriseWisePayrollData.directDeposit2,
      mode: "directdeposit",
    } as IIncompleteUserRequest;

    this.setState({ isDataSubmitting: true });
    UserManagement.SaveIncompleteUser(request)

      .then((result: any) => {
        this.setState({ isDataSubmitting: false });
        if (
          result?.message === "Success" &&
          result?.result.message === "InvalidAmount"
        ) {
          this.setState({
            enterPriseWisePayrollData: PreviousEnterPriseWisePayrollData,
          });

          handleDirectDepositError();
          // Utils.toastError(result?.result.result, {
          //       position: toast.POSITION.BOTTOM_RIGHT,
          //       containerId: "laborTabUM",
          //  });

          return;
        } else if (result?.result?.success) {
          toast.success(result?.result?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "userSlideOut",
          });

          this.props?.getUserGeneralInfo(true);
          this.getEnterPriseWisePayrollData();
          this.hideDepositModal(false);
          this.props.getPayrollTabModificationStatus(false);
        } else {
          Utils.toastError(result?.result?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "userSlideOut",
          });
        }
      })
      .catch((error) => {
        let _depositAccountsData = [...this.state.depositAccountsData];
        _depositAccountsData = _depositAccountsData.map((row) => {
          row.isRowDataSubmitting = false;
          return row;
        });

        this.setState({
          isDataSubmitting: false,
          depositAccountsData: [..._depositAccountsData],
        });

        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "userSlideOut",
        });
      })
      .finally(() => this.setState({ isFormValid: false }));
  };

  getStatusClassName(status: any) {
    let className = "";
    switch (status) {
      case "Active":
        className = "color-green";
        break;
      case "Inactive":
        className = "color-grey";
        break;
      default:
        className = "color-orange";
        break;
    }
    return className;
  }
  addUpdateDeposit = (
    data,
    isEdit = false,
    allData = false,
    handleDirectDepositError = () => {}
  ) => {
    const {
      enterPriseWisePayrollData,
      depositRowForAddEdit,
      depositAccountsData,
    } = this.state;
    const PreviousEnterPriseWisePayrollData = _.cloneDeep(
      enterPriseWisePayrollData
    );
    if (allData) {
      const data1 = data[0];
      const data2 = data[1];

      //Row 1
      enterPriseWisePayrollData.directDeposit = data1.directDeposit;
      enterPriseWisePayrollData.bankName = data1.bankName;
      enterPriseWisePayrollData.accountType = data1.accountType;
      enterPriseWisePayrollData.routingNumber = data1.routingNumber;
      enterPriseWisePayrollData.accountNumber = data1.accountNumber;
      enterPriseWisePayrollData.ddAmountType =
        data1.ddAmountType === "Entire Paycheck"
          ? "Total Payroll"
          : data1.ddAmountType == "$ Amount of Paycheck"
          ? "$Amount of Paycheck"
          : data1.ddAmountType;
      enterPriseWisePayrollData.ddAmount =
        data1.ddAmountType === "Entire Paycheck" ||
        data1.ddAmountType === "Total Payroll"
          ? ""
          : data1.ddAmount;

      //Row 2
      enterPriseWisePayrollData.directDeposit2 = data2.directDeposit;
      enterPriseWisePayrollData.bankName2 = data2.bankName;
      enterPriseWisePayrollData.accountType2 = data2.accountType;
      enterPriseWisePayrollData.routingNumber2 = data2.routingNumber;
      enterPriseWisePayrollData.accountNumber2 = data2.accountNumber;
      enterPriseWisePayrollData.ddAmountType2 =
        data2.ddAmountType == "$ Amount of Paycheck"
          ? "$Amount of Paycheck"
          : data2.ddAmountType;
      enterPriseWisePayrollData.ddAmount2 =
        data1.ddAmountType === "Entire Paycheck" ||
        data1.ddAmountType === "Total Payroll"
          ? ""
          : data2.ddAmount;
    } else {
      if (depositRowForAddEdit === 1) {
        enterPriseWisePayrollData.directDeposit = data.directDeposit;
        enterPriseWisePayrollData.bankName = data.bankName;
        enterPriseWisePayrollData.accountType = data.accountType;
        enterPriseWisePayrollData.routingNumber = data.routingNumber;
        enterPriseWisePayrollData.accountNumber = data.accountNumber;
        enterPriseWisePayrollData.ddAmountType =
          data.ddAmountType === "Entire Paycheck"
            ? "Total Payroll"
            : data.ddAmountType == "$ Amount of Paycheck"
            ? "$Amount of Paycheck"
            : data.ddAmountType;
        enterPriseWisePayrollData.ddAmount =
          data.ddAmountType === "Entire Paycheck" ||
          data.ddAmountType === "Total Payroll"
            ? ""
            : data.ddAmount;
        enterPriseWisePayrollData.ddAmountType2 = data?.ddAmountType2
          ? data.ddAmountType2 == "$ Amount of Paycheck"
            ? "$Amount of Paycheck"
            : data?.ddAmountType2
          : depositAccountsData[1]?.ddAmountType == "$ Amount of Paycheck"
          ? "$Amount of Paycheck"
          : depositAccountsData[1]?.ddAmountType;
        enterPriseWisePayrollData.ddAmount2 =
          data.ddAmountType === "Entire Paycheck" ||
          data.ddAmountType === "Total Payroll"
            ? ""
            : data?.ddAmount2
            ? data?.ddAmount2
            : depositAccountsData[1]?.ddAmount;
      }
      if (depositRowForAddEdit === 2) {
        enterPriseWisePayrollData.directDeposit2 = data.directDeposit;
        enterPriseWisePayrollData.bankName2 = data.bankName;
        enterPriseWisePayrollData.accountType2 = data.accountType;
        enterPriseWisePayrollData.routingNumber2 = data.routingNumber;
        enterPriseWisePayrollData.accountNumber2 = data.accountNumber;
        enterPriseWisePayrollData.ddAmountType2 =
          data.ddAmountType == "$ Amount of Paycheck"
            ? "$Amount of Paycheck"
            : data.ddAmountType;
        enterPriseWisePayrollData.ddAmount2 =
          data.ddAmountType === "Entire Paycheck" ||
          data.ddAmountType === "Total Payroll"
            ? ""
            : data.ddAmount;
      }
    }

    let message = "Deposit data saved successfully";
    if (isEdit) {
      message = "Deposit data updated successfully";
    }
    // if ((enterPriseWisePayrollData?.ddAmountType === '% of Paycheck' || enterPriseWisePayrollData?.ddAmountType2 === '% of Paycheck') &&
    //   (+enterPriseWisePayrollData.ddAmount > 100  || +enterPriseWisePayrollData.ddAmount2 > 100 || (+enterPriseWisePayrollData.ddAmount + +enterPriseWisePayrollData.ddAmount2) > 100)) {

    //   //  this.setState({enterPriseWisePayrollData: this.state?.prevEnterPriseWisePayrollData}, ()=> {
    //   //   this.buildDepositTableData();
    //   //  });
    //   let _depositAccountsData = [...depositAccountsData]
    //   _depositAccountsData = _depositAccountsData.map((row) => {
    //     row.isRowDataSubmitting = false;
    //     return row;
    //   });

    //   this.setState({ depositAccountsData: [..._depositAccountsData] })

    //   Utils.toastError("Total percentage cannot be greater than 100%", {
    //     position: toast.POSITION.BOTTOM_RIGHT,
    //     containerId: "laborTabUM",
    //   });
    //   return;

    // }

    this.setState({ enterPriseWisePayrollData }, () => {
      this.saveEnterpriseWiseSetting(
        message,
        handleDirectDepositError,
        PreviousEnterPriseWisePayrollData
      );
    });
  };

  addUpdateDepositWithConfirmation = (
    data,
    isEdit = false,
    handleDirectDepositError = () => {}
  ) => {
    const { enterPriseWisePayrollData, depositRowForAddEdit } = this.state;

    if (depositRowForAddEdit === 1) {
      enterPriseWisePayrollData.directDeposit = data.directDeposit;
      enterPriseWisePayrollData.bankName = data.bankName;
      enterPriseWisePayrollData.accountType = data.accountType;
      enterPriseWisePayrollData.routingNumber = data.routingNumber;
      enterPriseWisePayrollData.accountNumber = data.accountNumber;
      enterPriseWisePayrollData.ddAmountType = data.ddAmountType;
      enterPriseWisePayrollData.ddAmount = data.ddAmount;
      enterPriseWisePayrollData.ddAmountType2 = data.ddAmountType2;
      enterPriseWisePayrollData.ddAmount2 = data.ddAmount2;
    }
    if (depositRowForAddEdit === 2) {
      enterPriseWisePayrollData.directDeposit2 = data.directDeposit;
      enterPriseWisePayrollData.bankName2 = data.bankName;
      enterPriseWisePayrollData.accountType2 = data.accountType;
      enterPriseWisePayrollData.routingNumber2 = data.routingNumber;
      enterPriseWisePayrollData.accountNumber2 = data.accountNumber;
      enterPriseWisePayrollData.ddAmountType2 = data.ddAmountType;
      enterPriseWisePayrollData.ddAmount2 = data.ddAmount;
    }

    if (!isEdit) {
      this.addUpdateDeposit(data, isEdit, false, handleDirectDepositError);
      return;
    }

    confirmAlert({
      title: "Are you ready to make changes?",
      message:
        "Changing your direct deposit information changes where your paycheck is deposited. Are you sure you want to make this change?",
      buttons: [
        {
          label: "Confirm",
          onClick: () =>
            this.addUpdateDeposit(
              data,
              isEdit,
              false,
              handleDirectDepositError
            ),
        },
        {
          label: "Cancel",
          onClick: () => {},
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  onIsAllowDepositChange = (event) => {
    this.setState({ isAllowDeposit: event.target.checked }, () => {
      this.updateDepositToggle();
    });
  };

  updateDepositToggle = () => {
    const { isAllowDeposit } = this.state;
    UserManagement.UpdateToggle(
      this.props?.userGeneralInfo?.userName,
      this.props?.userGeneralInfo?.isSalesAgent || 0,
      this.props?.userGeneralInfo?.allowPTO || 0,
      this.props?.userGeneralInfo?.allowACATracking || 0,
      this.props?.userGeneralInfo?.allowPerformance || 0,
      isAllowDeposit ? 1 : 0
    ).then((result) => {
      this.updatePayrollTabCount();
      toast.success(result.result?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "userSlideOut",
      });
    });
  };
  handleDeductionRowExpand = (
    row: any,
    isExpand: any,
    rowIndex: any,
    e: any
  ) => {
    if (this.state?.deductionTableExpandend?.length > 0) {
      this.setState({
        deductionRow: {},
        isExpand: false,
      });
      this.setState(() => ({ deductionTableExpandend: [] }));
    } else {
      this.setState(() => ({
        isExpand: true,
        deductionRow: row,
        deductionTableExpandend: [row.scheduleId],
      }));
      //  this.getPTOAdjustedDetails(row.ScheduleId);
    }
  };

  handleOnGarnishmentRowExpand = (
    row: any,
    isGarnishmentExpand: any,
    rowIndex: any,
    e: any
  ) => {
    if (this.state?.ganishmentTableExpandend?.length > 0) {
      this.setState({
        garnishmentRow: {},
        isGarnishmentExpand: false,
      });
      this.setState(() => ({ ganishmentTableExpandend: [] }));
    } else {
      this.setState(() => ({
        isGarnishmentExpand: true,
        garnishmentRow: row,
        ganishmentTableExpandend: [row.iF_RecordId],
      }));
    }
  };

  handleOnExpand = (row: any, isExpand: any, rowIndex: any, e: any) => {
    if (
      +row?.id === 2 ||
      +row?.id === 3 ||
      (+row?.id === 1 && this.state.isAllowDeposit)
    ) {
      if (this.state.tableExpended.length > 0) {
        this.setState({ laborTimeOffSubData: [], ptoRow: {}, isExpand: false });
        this.setState(() => ({ tableExpended: [] }));
      } else {
        this.setState(() => ({
          isExpand: true,
          ptoRow: row,
          tableExpended: [row.id],
        }));
        //   this.getPTOAdjustedDetails(row.ptoUniqueNo);
      }
    } else {
      // this.setState({ laborTimeOffSubData: [], ptoRow: {}, isExpand: false });
      this.setState(() => ({ tableExpended: [] }));
    }
  };

  handleDiscard = () => {
    this.getEnterPriseWisePayrollData();
    this.props.getPayrollTabModificationStatus(false);
  };

  getUserSpecialRates = (hid) => {
    this.setState({ isSpecialRateLoaded: false });
    UserManagement.GetUserSpecialRates(this.props?.userUniqueNo, hid)
      .then((spacialRates) => {
        const data = spacialRates.map((item: any) => {
          item.wefDate = moment(item.wefDate)?.format("MM/DD/YYYY");
          return item;
        });
        this.setState({ spacialRates: data }, () => {
          this.setState({ isSpecialRateLoaded: true });
        });
      })
      .catch(() => this.setState({ isSpecialRateLoaded: true }));
  };
  handleOnHotelWideExpand = (
    row: any,
    isExpand: any,
    rowIndex: any,
    e: any
  ) => {
    if (this.state?.hotelWideTableExpended?.length > 0) {
      this.setState({
        hotelWideRow: {},
        spacialRates: [],
        isHotelWideExpand: false,
      });
      this.setState(() => ({ hotelWideTableExpended: [] }));
    } else {
      this.setState(() => ({
        isHotelWideExpand: true,
        hotelWideRow: row,
        hotelWideTableExpended: [row.rowNo],
      }));
      this.getUserSpecialRates(row?.hid);
    }
  };

  hideHotelWiseAddEditModalForm = (isReload = false) => {
    this.setState({ showHotelWiseAddEditModal: false }, () => {
      if (isReload) {
        this.getHotelWisePayrollData();
        this.props?.getUserGeneralInfo();
      }
    });
  };

  showHotelWiseAddEditModalForm = (isEdit: boolean = false, row: any = {}) => {
    if (isEdit) {
      if (
        this.props?.userGeneralInfo.hids?.filter(
          (item) => +item?.hotelID === +row?.hid
        )?.length === 0
      ) {
        toast.success(
          "You are unable to open and edit the information because you do not have access to the E/H/NID",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "userSlideOut",
          }
        );

        return;
      }

      this.getUserSpecialRates(this.state?.hotelWideRow?.hid);

      this.setState({ hotelWisePayrollRowEdit: row }, () => {
        this.setState({ showHotelWiseAddEditModal: true });
      });
    } else {
      this.setState(
        {
          hotelWisePayrollRowEdit: {},
        },
        () => {
          this.setState({ showHotelWiseAddEditModal: true });
        }
      );
    }
  };

  hideSpecialRateAddEditModalForm = (isReload = false) => {
    this.setState({ showSpecialRateAddEditModal: false }, () => {
      if (isReload) {
        this.getUserSpecialRates(this.state?.hotelWideRow?.hid);
      }
    });
  };
  showSpecialRateAddEditModalForm = (
    isEdit: boolean = false,
    row: any = {}
  ) => {
    if (isEdit) {
      this.setState({ specialRateRowEdit: row }, () => {
        this.setState({ showSpecialRateAddEditModal: true });
      });
    } else {
      this.setState(
        {
          specialRateRowEdit: {},
        },
        () => {
          this.setState({ showSpecialRateAddEditModal: true });
        }
      );
    }
  };

  updateDeductionStatus = (row: any, status) => {
    const request: any = {
      uniqueno: row?.uniqueno,
      status: status,
      deductUniqueNo: row?.deductUniqueNo,
      taxType: row?.taxType,
      exempt: row?.exempt,
      deductionType: row?.deductionType,
      deductionCriteria: row?.deductionCriteria,
      amount: row?.amount,
      payeeName: row?.payeeName,
      hid: +row?.hid,
    };
    request.username = this.props?.userGeneralInfo.userName;
    this.setState({ isDataSaving: true });
    UserManagement.SaveUserDeductionCriteria(request)
      .then((result) => {
        this.setState({ isDataSaving: false });
        if (result?.message === "Success") {
          toast.success("Status Updated Successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "userSlideOut",
          });
          this.getDeductionsData();
        } else {
          Utils.toastError(result?.result?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "userSlideOut",
          });
        }
      })
      .catch((error) => {
        this.setState({ isDataSaving: false });
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "userSlideOut",
        });
      });
  };

  deletePayrollSpecialRate = (row) => {
    UserManagement.DeletePayrollSpecialRate(row?.uniqueno).then((result) => {
      this.getUserSpecialRates(this.state?.hotelWideRow?.hid);
      toast.success("Special Rate deleted successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "userSlideOut",
      });
    });
  };

  deleteUserGranishment = (row) => {
    UserManagement.DeleteUserGranishment(
      row?.uniqueNo,
      this.props?.userUniqueNo
    ).then((result) => {
      this.getGarnishmentsData();
      toast.success("Garnishment deleted successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "userSlideOut",
      });
    });
  };

  deleteDeduction = (row) => {
    UserManagement.DeleteDeduction(
      row?.iF_RecordId,
      this.props?.userGeneralInfo.userName
    ).then((result) => {
      this.props?.getUserGeneralInfo();
      this.getDeductionsData();
      toast.success("Deduction deleted successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "userSlideOut",
      });
    });
  };

  deleteDirectDepositAcc = (row: any) => {
    if (this.state?.depositAccountsData?.length > 1 && row.rowNo === 1) {
      Utils.toastError(
        "You must delete second account in order to delete first account",
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "laborTabUM",
        }
      );
      return;
    }
    UserManagement.DeleteDirectDepositAcc(
      this.props?.userUniqueNo,
      row.rowNo
    ).then((result) => {
      this.getEnterPriseWisePayrollData();
      toast.success("Deposit Account deleted successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "laborTabUM",
      });
    });
  };

  downloadGranismentDocument = (row) => {
    UserManagement.DownloadGranismentDocument(
      row?.uniqueNo,
      row?.fileName
    ).then((result) => {});
  };

  render() {
    const {
      hasAddPermission,
      hasEditPermission,
      hasViewSensitiveDataPermission,
    } = this.props;
    const {
      inputPosition,
      enterpriseWideSettingErrors,
      exemptionsErrors,
      isFormValid,
      isDataSubmitting,
      sectionNames,
      showDepositModal,
      depositAccountsData,
      isEnterpriseWideDataLoaded,
      isAllowDeposit,
      depositRowEdit,
      depositRowForAddEdit,
      isEnterpriseWideSettingNotChanged,
      enterpriseWideSetting: {
        ssn,
        dob,
        gender,
        address,
        jobType,
        federalFiling,
        i9SubmittedOn,
        w4Date,
      },
      excemptions: {
        federalExemption,
        additionalFederal,
        futa,
        fica,
        stateExemption,
      },
      payrollWithholdingSettings: {
        additionalState,
        suta,
        isJobs,
        otherIncome,
        creditAmt,
        wksht,
        extraAmt,
      },
      showPayrollAddressModal,
      enterPriseWisePayrollData,
      showDeductionModal,
      deductions,
      garnishments,
      showGarnishmentModal,
      isDeductionLoaded,
      showPayrollSettingBanner,
      payrollWithholdingSettingsErrors,
      hotelWideTableData,
      showHotelWiseAddEditModal,
      showSpecialRateAddEditModal,
      hotelWisePayrollRowEdit,
      specialRateRowEdit,
      hotelWideTableExpended,
      spacialRates,
      isHotelWisePayrollLoaded,
      isSpecialRateLoaded,
      isHotelWideExpand,
      enterpriseWideSettingNotChangedForDate,
    } = this.state;

    const { userGeneralInfo } = this.props;

    const hotelWideSettingProps: IHotelWideSettingProps = {
      hotelWideTableData,
      showSpecialRateAddEditModal,
      showSpecialRateAddEditModalForm: this.showSpecialRateAddEditModalForm,
      userGeneralInfo,
      deletePayrollSpecialRate: this.deletePayrollSpecialRate,
      spacialRates,
      isSpecialRateLoaded,
      hideSpecialRateAddEditModalForm: this.hideSpecialRateAddEditModalForm,
      hotelWideTableExpended,
      specialRateRowEdit,
      handleOnHotelWideExpand: this.handleOnHotelWideExpand,
      isHotelWideExpand,
      showHotelWiseAddEditModal,
      showHotelWiseAddEditModalForm: this.showHotelWiseAddEditModalForm,
      hideHotelWiseAddEditModalForm: this.hideHotelWiseAddEditModalForm,
      isHotelWisePayrollLoaded,
      hotelWisePayrollRowEdit,
      isPayrollTab: true,
      userUniqueNo: this.props?.userUniqueNo,
      clientSetupData: this.props?.clientSetupData,
      hasHWPSetting: userGeneralInfo?.groupHWPPayrollSetting,
      hasViewSensitiveDataPermission:
        this.props?.hasViewSensitiveDataPermission,
      isFromPayroll: true,
      isEditLaborandPayrollInfo: this.props.isEditLaborandPayrollInfo,
    };

    const AmountValidation = (rowData) => {
      let amountError;
      if (
        (rowData?.ddAmountType === "% of Paycheck" ||
          rowData?.ddAmountType === "$Amount of Paycheck") &&
        !rowData?.ddAmount
      ) {
        amountError["ddAmount"] = "Please enter value";
      } else {
        if (amountError["ddAmount"]) {
          delete amountError["ddAmount"];
        }
      }
    };

    const changeAmount = ({ value, rowIndex, data, event }) => {
      let _depositAccountsData = [...depositAccountsData];
      _depositAccountsData[rowIndex].ddAmount = value;
      _depositAccountsData = _depositAccountsData.map((row) => {
        row.isRowDataSubmitting = true;
        return row;
      });

      this.setState((current) => ({
        ...current,
        depositAccountsData: [..._depositAccountsData],
        depositRowForAddEdit: _depositAccountsData[rowIndex].rowNo,
      }));
    };

    const changeSerialNo = (newValue, oldValue, rowIndex) => {
      if (newValue == oldValue) {
        return;
      }
      if (newValue == 1 || newValue == 2) {
        let _depositAccountsData = [...depositAccountsData];

        // const tempValue=_depositAccountsData[0].rowNo
        // _depositAccountsData[0].rowNo=_depositAccountsData[1].rowNo
        // _depositAccountsData[1].rowNo=tempValue
        if (rowIndex == 0) {
          _depositAccountsData[0].rowNo = newValue;
          _depositAccountsData[1].rowNo = newValue == 2 ? 1 : 2;
        } else {
          _depositAccountsData[1].rowNo = newValue;
          _depositAccountsData[0].rowNo = newValue == 2 ? 1 : 2;
        }

        if (
          _depositAccountsData[0].ddAmountType == "Entire Paycheck" &&
          _depositAccountsData[1].ddAmountType == "None"
        ) {
          _depositAccountsData[1].ddAmountType = "Entire Paycheck";
          _depositAccountsData[0].ddAmountType = "None";
        }
        _depositAccountsData[0].ddAmount = _depositAccountsData[0].ddAmountRaw;
        _depositAccountsData[1].ddAmount = _depositAccountsData[1].ddAmountRaw;

        _depositAccountsData = [
          _depositAccountsData[1],
          _depositAccountsData[0],
        ];
        _depositAccountsData = _depositAccountsData.map((row) => {
          row.isRowDataSubmitting = true;
          return row;
        });

        this.setState({ depositAccountsData: [..._depositAccountsData] });
      }
    };

    const valueRender = (params) => {
      let field = params?.colDef?.field;
      let rowIndex = params?.rowIndex;
      let editable = true;
      if (
        params.data.dataLength == 2 &&
        (field == "ddAmount" || field == "rowNo")
      ) {
        editable = false;
      } else {
        if (params.data.dataLength == 1 && field == "ddAmount") {
          editable = false;
        }
      }

      return (
        <div
        // onClick={() =>
        //   !params.data.isRowDataSubmitting && editable
        //     ? this.openDepositEntryPanel(true, params.data)
        //     : {}
        // }
        >
          <EllipsisWithTooltip>{params?.data[field]}</EllipsisWithTooltip>
        </div>
      );
    };

    const tripleDotRender = (params) => {
      const row = params?.data;
      let rowIndex = params?.rowIndex;

      return (
        <Dropdown
          // onClick={(e) => {
          //   e.stopPropagation();
          // }}
          className={`more-action ${
            this.state.depositAccountsData.length - 1 == rowIndex
              ? "set-dropdown-inset"
              : ""
          }`}
          alignRight
        >
          <Dropdown.Toggle
            className="btn-outline-primary btn btn-primary btn-ghost more"
            id="dropdown-more"
            disabled={params.data.isRowDataSubmitting}
            // disabled={params.data.isRowDataSubmitting ||!(this.props.isEditLaborandPayrollInfo || this.props?.isNewUserAdded)}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
              <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
              <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
            </svg>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              key={row?.sectionName + "Edit"}
              onClick={() => this.openDepositEntryPanel(true, row)}
              eventKey={"edit"}
            >
              Edit
            </Dropdown.Item>
            <Dropdown.Item
              key={row?.sectionName + "Delete"}
              onClick={() => this.deleteDirectDepositAcc(row)}
              eventKey={"status"}
            >
              Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      );
    };

    const depositTableColumns: any[] = [
      {
        field: "rowNo",
        headerName: "Sr. No.",
        cellRenderer: "valueRender",
        // cellEditor: "numericCellEditor",
        // editable: (params) => params.data.dataLength == 1 ? false : params.data.isRowDataSubmitting ? false : true ,
        rowDrag: (params) => {
          return params.data.dataLength == 1
            ? false
            : params.data.isRowDataSubmitting
            ? false
            : true;
        },
        maxWidth: 80,
        minWidth: 80,
        cellStyle: { overflow: "visible" },
        cellClass: (params) => {
          const editable =
            params.data.dataLength == 1
              ? false
              : params.data.isRowDataSubmitting
              ? false
              : hasEditPermission;
          return editable
            ? "serial-number serial-number-edit"
            : "serial-number";
        },
        valueGetter: (params) => {
          return params.data.rowNo;
        },
        valueSetter: (params) => {
          const newValue = params.newValue;
          var data = params.data;

          if (newValue == 1 || newValue == 2) {
            data.rowNo = newValue;
            return true;
          }
          return false;
        },
      },
      {
        field: "bankName",
        headerName: "Name",
        cellRenderer: "valueRender",
        maxWidth: 140,
        minWidth: 140,
        //cellStyle: { overflow: "visible" },
        cellClass: "bank-name",
      },
      {
        field: "accountType",
        headerName: "Type",
        cellRenderer: "valueRender",
        maxWidth: 140,
        minWidth: 140,
        cellStyle: { overflow: "visible" },
        cellClass: "account-type",
      },
      {
        field: "routingNumber",
        headerName: "Routing",
        cellRenderer: "valueRender",
        maxWidth: 140,
        minWidth: 140,
        cellStyle: { overflow: "visible" },
        cellClass: "routing-number",
      },
      {
        field: "accountNumber",
        headerName: "Account",
        cellRenderer: "valueRender",
        maxWidth: 140,
        minWidth: 140,
        cellStyle: { overflow: "visible" },
        cellClass: "account-number",
      },
      {
        field: "ddAmountType",
        headerName: "Amount Type",
        cellRenderer: "valueRender",
        maxWidth: 180,
        minWidth: 180,
        cellStyle: { overflow: "visible" },
        cellClass: "dd-amount-type",
      },
      {
        field: "ddAmount",
        headerName: "Amount",
        cellRenderer: "valueRender",
        // cellEditor: "numericCellEditor",
        // editable: (params) => params.data.isRowDataSubmitting ? false : true,
        // hide: params => params.data?.ddAmountType === "Entire Paycheck" || params.data?.ddAmountType === "None",
        maxWidth: 140,
        minWidth: 140,
        cellStyle: { overflow: "visible" },
        cellClass: (params) => {
          const editable =
            params.data.dataLength == 1
              ? false
              : params.data.isRowDataSubmitting
              ? false
              : hasEditPermission;
          return editable ? "ehid-cells" : "ehid-cells";
        },
        valueGetter: (params) => {
          return params.data.ddAmount;
        },
        valueSetter: (params) => {
          const newValue = params.newValue;
          var data = params.data;
          this.setState({ depositRowForAddEdit: data.rowNo });
          var rowIndex = data.rowNo - 1 == 1 ? 0 : 1;
          if (newValue) {
            if (data.ddAmountType === "% of Paycheck") {
              if (
                (depositAccountsData.length == 1 && +newValue < 100) ||
                (depositAccountsData.length == 2 &&
                  +depositAccountsData[rowIndex].ddAmountRaw + +newValue <= 100)
              ) {
                data.ddAmount = newValue;
                return true;
              } else {
                return false;
              }
            } else {
              data.ddAmount = newValue;
            }
            return true;
          }
          data.ddAmount = 0;
          return true;
        },
      },
      {
        field: "",
        headerName: "",
        cellRenderer: "tripleDotRender",
        editable: false,
        lockPinned: true,
        pinned: "right",
        maxWidth: 60,
        minWidth: 60,
        cellStyle: { overflow: "visible", width: "auto" },
        cellClass: "triple-dot",
      },
    ];

    const duplicateSSNTableColumns: any[] = [
      {
        dataField: "employeeName",
        text: "Employee",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <EllipsisWithTooltip placement="bottom">
            <div className="employee-name">{cell}</div>
          </EllipsisWithTooltip>
        ),
      },
      {
        dataField: "username",
        text: "Username",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <EllipsisWithTooltip placement="bottom">
            <div className="user-name">{cell}</div>
          </EllipsisWithTooltip>
        ),
      },
      {
        dataField: "userId",
        text: "User ID",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <EllipsisWithTooltip placement="bottom">
            <div className="user-id">{cell}</div>
          </EllipsisWithTooltip>
        ),
      },
      {
        dataField: "rolePermission",
        text: "Role Permission",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <EllipsisWithTooltip placement="bottom">
            <div className="role-permission">{cell}</div>
          </EllipsisWithTooltip>
        ),
      },
      {
        dataField: "ehid",
        text: "EHID",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <EllipsisWithTooltip placement="bottom">
            <div className="ehid">{cell}</div>
          </EllipsisWithTooltip>
        ),
      },
      {
        dataField: "status",
        text: "Status",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="status">{cell}</div>
        ),
      },
    ];

    const onGridReady = (params: any) => {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;

      //condition to hide ddAmount column
      if (
        depositAccountsData &&
        depositAccountsData.length > 0 &&
        depositAccountsData[0]?.ddAmountType === "Entire Paycheck"
      ) {
        this.gridColumnApi.setColumnsVisible(["ddAmount"], false);
      }

      columnWidthSizeToFit();

      const headerSelector = ".ag-header";
      const scrollSelector = ".ag-body-horizontal-scroll";
      // const scrollViewportSelector = ".ag-body-horizontal-scroll-viewport";
      const scrollContainerSelector = ".ag-body-horizontal-scroll-container";

      // get scrollbar elements
      const scrollElement: any = document.querySelector(scrollSelector);
      // const scrollViewportElement: any = document.querySelector(
      //   scrollViewportSelector
      // );
      const scrollContainerElement: any = document.querySelector(
        scrollContainerSelector
      );
    };

    const columnWidthSizeToFit = () => {
      this.gridApi?.sizeColumnsToFit();
    };

    const onCellValueChanged = (params) => {
      const { value, newValue, oldValue, rowIndex, data, event, colDef } =
        params;
      if (colDef.field === "ddAmount") {
        changeAmount({ value, rowIndex, data, event });
      } else {
        changeSerialNo(newValue, oldValue, rowIndex);
      }
    };

    const onCellEditingStopped = (params) => {
      const { newValue, oldValue, rowIndex, colDef, data } = params;
      if (newValue == oldValue) {
        return;
      }
      const _rowIndex = rowIndex == 1 ? 0 : 1;

      if (colDef.field === "ddAmount") {
        if (data.ddAmountType === "% of Paycheck") {
          if (
            (depositAccountsData.length == 1 && +newValue < 100) ||
            (depositAccountsData.length == 2 &&
              +depositAccountsData[_rowIndex].ddAmountRaw + +newValue <= 100)
          ) {
            this.addUpdateDeposit(depositAccountsData[rowIndex], true, false);
          } else {
            let _depositAccountsData = [...depositAccountsData];
            _depositAccountsData = _depositAccountsData.map((row) => {
              row.isRowDataSubmitting = false;
              return row;
            });

            this.setState({ depositAccountsData: [..._depositAccountsData] });

            Utils.toastError("Total percentage cannot be greater than 100%", {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "laborTabUM",
            });
          }
        } else {
          this.addUpdateDeposit(depositAccountsData[rowIndex], true, false);
        }
      } else {
        this.addUpdateDeposit(depositAccountsData, true, true);
      }
    };

    const onRowDragMove = (params) => {
      // const overIndex=params.overIndex //0
      // const rowData1=params.overNode.data
      // if(rowData1.rowNo == overIndex+1 )
      // {
      //   return
      // }
      // let _depositAccountsData=depositAccountsData
      // const rowData2=_depositAccountsData[overIndex== 0 ?1:0]
      // const value=rowData1.rowNo // 2
      // // Manage Row Number
      // if (overIndex == 0) {
      //   _depositAccountsData[0].rowNo = value
      //   _depositAccountsData[1].rowNo = value == 2 ? 1 : 2
      // }
      // else {
      //   _depositAccountsData[1].rowNo = value
      //   _depositAccountsData[0].rowNo = value == 2 ? 1 : 2
      // }
      // // Manage Entire Paycheck
      // if (_depositAccountsData[0].ddAmountType == "Entire Paycheck" && _depositAccountsData[1].ddAmountType == "None") {
      //   _depositAccountsData[1].ddAmountType = "Entire Paycheck"
      //   _depositAccountsData[0].ddAmountType = "None"
      // }
      // _depositAccountsData=[_depositAccountsData[1],_depositAccountsData[0]]
      // this.setState({ depositAccountsData: [..._depositAccountsData] })
    };

    const onRowDragEnd = (params) => {
      const overIndex = params.overIndex; //0
      const rowData1 = params.overNode.data;
      if (rowData1.rowNo == overIndex + 1) {
        return;
      }
      let _depositAccountsData = depositAccountsData;

      const rowData2 = _depositAccountsData[overIndex == 0 ? 1 : 0];
      const value = rowData1.rowNo; // 2
      // Manage Row Number
      if (overIndex == 0) {
        _depositAccountsData[0].rowNo = value;
        _depositAccountsData[1].rowNo = value == 2 ? 1 : 2;
      } else {
        _depositAccountsData[1].rowNo = value;
        _depositAccountsData[0].rowNo = value == 2 ? 1 : 2;
      }

      // Manage Entire Paycheck
      if (
        _depositAccountsData[0].ddAmountType == "Entire Paycheck" &&
        _depositAccountsData[1].ddAmountType == "None"
      ) {
        _depositAccountsData[1].ddAmountType = "Entire Paycheck";
        _depositAccountsData[0].ddAmountType = "None";
      }
      _depositAccountsData[0].ddAmount = _depositAccountsData[0].ddAmountRaw;
      _depositAccountsData[1].ddAmount = _depositAccountsData[1].ddAmountRaw;

      _depositAccountsData = [_depositAccountsData[1], _depositAccountsData[0]];
      _depositAccountsData = _depositAccountsData.map((row) => {
        row.isRowDataSubmitting = true;
        return row;
      });

      this.setState({ depositAccountsData: [..._depositAccountsData] }, () => {
        this.addUpdateDeposit(_depositAccountsData, true, true);
      });
    };
    const getAgGridOptions = () => {
      const gridOptions: GridOptions = {
        rowData: [],
        rowClass: "user-table-row",
        // singleClickEdit:true,
        getRowHeight: (params) => {
          return 48;
        },
        headerHeight: 48,
        frameworkComponents: {
          valueRender: valueRender,
          tripleDotRender: tripleDotRender,
          numericCellEditor: NumericEditor,

          // cellEditorSelector:cellEditorSelector,

          // rowNoRender:rowNoRender,
          // amountRender:amountRender,
        },

        rowSelection: "single",
      };
      return gridOptions;
    };

    const rowClasses = (row: any, rowIndex: any) => {
      if (this.state.isExpand === true && row.action === "Expended") {
        return "row-Expended";
      } else if (row.approveButtonSpinner) {
        return "p-none";
      } else if (this.state.isExpand === true) {
        return "row-Collapsed";
      } else {
        return "row-Action";
      }
    };
    const rowClassesGarnishment = (row: any, rowIndex: any) => {
      if (
        this.state.isGarnishmentExpand === true &&
        row.action === "Expended"
      ) {
        return "row-Expended";
      } else if (row.approveButtonSpinner) {
        return "p-none";
      } else if (this.state.isGarnishmentExpand === true) {
        return "row-Collapsed";
      } else {
        return "row-Action";
      }
    };

    const expandRow = {
      renderer: (row: any) => (
        <>
          {row.id === 1 && (
            <div className="rowExpended" id="laborDayDetails">
              <div className="heading">Deposit Accounts</div>
              {(isAllowDeposit ||
                this.props?.userGeneralInfo?.allowDeposit === null) && (
                <>
                  <div className="adjustments-enterprise-wise-payroll-table">
                    {depositAccountsData?.length === 0 && (
                      <span className="notification-banners blue-bg">
                        Add Direct Deposit information in order to recieve
                        paychecks directly into your account.
                      </span>
                    )}
                    {depositAccountsData?.length > 1 && (
                      <span className="notification-banners blue-bg">
                        Remaining Direct Deposit funds will deposit into Account
                        #
                        {+enterPriseWisePayrollData?.remainingBalanceAcc === 2
                          ? depositAccountsData[1]?.accountNumber
                          : depositAccountsData[0]?.accountNumber}{" "}
                      </span>
                    )}

                    {depositAccountsData?.length > 0 && (
                      <AgGridReact
                        // stopEditingWhenGridLosesFocus={true}
                        onGridReady={onGridReady}
                        domLayout={"autoHeight"}
                        rowData={depositAccountsData || []}
                        columnDefs={depositTableColumns}
                        rowDragManaged={true}
                        animateRows={true}
                        // suppressMoveWhenRowDragging={true}
                        // suppressRowDrag={true}
                        // onCellValueChanged={(params) => onCellValueChanged(params)}
                        // onCellEditingStopped={(params) => onCellEditingStopped(params)}
                        onRowDragEnd={(params) => onRowDragEnd(params)}
                        onRowDragMove={(params) => onRowDragMove(params)}
                        gridOptions={getAgGridOptions()}
                        frameworkComponents={{
                          valueRender: valueRender,
                          tripleDotRender: tripleDotRender,
                          numericCellEditor: NumericEditor,
                        }}
                        //  defaultColDef={{sortable: true,
                        //  filter: true}}
                        //  onDragStopped={this.columnDragStop}
                        //style ={{minHeight: 200}}
                        //  onCellKeyDown={(e) => {

                        //    if(e.colDef.field == "ddAmount") {
                        //     const handleKeyEvent=e?.data?.ddAmountType === "% of Paycheck"? Utils.AllowOnlyPositiveNumnberInputWithoutDot: Utils.AllowOnlyPositiveNumnberInput
                        //      handleKeyEvent(e.event)

                        //   }
                        //   }
                        // }
                        //  onCellEditingStarted = {({value})=>{ return [1,2,""].includes(value) && value   }}

                        //  suppressDragLeaveHidesColumns={true}
                        //  onCellClicked={(e: any) => {
                        //    if (
                        //      e.column &&
                        //      (e.column.colDef.cellClass === "triple-dot" ||
                        //        e.event.target.type === "checkbox")
                        //    ) {
                        //      e.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection =
                        //        true;
                        //    } else {
                        //      e.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection =
                        //        false;
                        //      this.handleShowSlideout(e);
                        //    }
                        //  }}
                      />
                    )}
                    {depositAccountsData?.length < 2 && (
                      <Button
                        className="btn-ghost"
                        onClick={() => this.openDepositEntryPanel()}
                      >
                        Add Deposit Account
                      </Button>
                    )}
                  </div>

                  {showDepositModal && (
                    <LockInAddDepositAccount
                      depositRowForAddEdit={depositRowForAddEdit}
                      depositRowEdit={depositRowEdit}
                      hideDepositModal={this.hideDepositModal}
                      showDepositModal={showDepositModal}
                      addUpdateDeposit={this.addUpdateDepositWithConfirmation}
                      isDataSubmitting={this.state.isDataSubmitting}
                      enterPriseWisePayrollData={enterPriseWisePayrollData}
                      hasViewSensitiveDataPermission={
                        hasViewSensitiveDataPermission
                      }
                      //   parentData={row}
                    />
                  )}
                </>
              )}
            </div>
          )}
          {row.id === 2 && (
            <div className="rowExpended" id="laborDayDetails">
              <Form.Group
                controlId="ssn-selection"
                className={"d-flex flex-row"}
              >
                <Form.Label>Federal Exemptions</Form.Label>
                <div className="validate-input d-flex flex-column">
                  <input
                    type="text"
                    onKeyDown={Utils.AllowOnlyPositiveNumnberInput}
                    min="0"
                    className="form-control"
                    id="txtfederalExemptions"
                    name="federalExemption"
                    autoComplete="federalExemption"
                    disabled={true}
                    value={federalExemption}
                  />
                </div>
              </Form.Group>
              <Form.Group
                controlId="additionalFederal-selection"
                className={"d-flex flex-row"}
              >
                <Form.Label>Additional Withholdings</Form.Label>
                <div className="validate-input d-flex flex-column">
                  <input
                    type="text"
                    onKeyDown={Utils.AllowOnlyPositiveNumnberInput}
                    min="0"
                    className="form-control"
                    id="txtadditionalWithholdings"
                    name="additionalFederal"
                    autoComplete="additionalFederal"
                    disabled={true}
                    value={additionalFederal}
                  />
                </div>
              </Form.Group>

              <Form.Group
                controlId="futa-selection"
                className={"d-flex flex-row"}
              >
                <Form.Label>FUTA Exempt</Form.Label>
                <div className="validate-input d-flex flex-column">
                  <DropDownList
                    placeHolder={"FUTA Exempt"}
                    data={StaticArrays.yesNoOptions}
                    defaultValue={futa}
                    isSearchRequired={false}
                    label={"label"}
                    value={"value"}
                    disabled={true}
                    selectedItem={
                      [
                        ...[{ value: "", label: "Select" }],
                        ...StaticArrays.yesNoOptions,
                      ].find((r: any) => r?.value === futa) || {}
                    }
                  />
                </div>
              </Form.Group>

              <Form.Group
                controlId="ssn-selection"
                className={"d-flex flex-row"}
              >
                <Form.Label>FICA Exempt</Form.Label>
                <div className="validate-input d-flex flex-column">
                  <DropDownList
                    placeHolder={"FICA Exempt"}
                    data={StaticArrays.yesNoOptions}
                    defaultValue={fica}
                    isSearchRequired={false}
                    label={"label"}
                    value={"value"}
                    disabled={true}
                    selectedItem={
                      [
                        ...[{ value: "", label: "Select" }],
                        ...StaticArrays.yesNoOptions,
                      ].find((r: any) => r?.value === fica) || {}
                    }
                  />
                </div>
              </Form.Group>

              <Form.Group
                controlId="stateExemption-selection"
                className={"d-flex flex-row"}
              >
                <Form.Label>State Exemptions</Form.Label>
                <div className="validate-input d-flex flex-column">
                  <input
                    type="text"
                    onKeyDown={Utils.AllowOnlyPositiveNumnberInput}
                    min="0"
                    className="form-control"
                    id="txtstateExemptions"
                    name="stateExemption"
                    autoComplete="stateExemptions"
                    disabled={true}
                    value={stateExemption}
                  />
                </div>
              </Form.Group>
            </div>
          )}

          {row.id === 3 && (
            <div className="rowExpended" id="laborDayDetails">
              <Form.Group
                controlId="ssn-selection"
                onBlur={() =>
                  this.validationPayrollWithholdingSettingsOnBlur(20)
                }
                className={"d-flex flex-row"}
              >
                <Form.Label>Additional State Withholdings</Form.Label>
                <div className="validate-input d-flex flex-column">
                  <input
                    type="text"
                    onKeyDown={Utils.AllowOnlyPositiveNumnberInputWithoutDot}
                    min="0"
                    className="form-control"
                    id="txtadditionalState"
                    name="additionalState"
                    autoComplete="additionalState"
                    disabled={true}
                    value={additionalState}
                  />
                </div>
              </Form.Group>

              <Form.Group
                controlId="suta-selection"
                className={"d-flex flex-row"}
              >
                <Form.Label>SUTA Exempt</Form.Label>
                <div className="validate-input d-flex flex-column">
                  <DropDownList
                    placeHolder={"SUTA Exempt"}
                    data={StaticArrays.yesNoOptions}
                    defaultValue={suta}
                    isSearchRequired={false}
                    label={"label"}
                    value={"value"}
                    disabled={true}
                    selectedItem={
                      [
                        ...[{ value: "", label: "Select" }],
                        ...StaticArrays.yesNoOptions,
                      ].find((r: any) => r?.value === suta) || {}
                    }
                  />

                  {this.getPayrollWithholdingSettingsFieldError("suta", 21)}
                </div>
              </Form.Group>

              <Form.Group
                controlId="stateExemption-selection"
                className={"d-flex flex-row"}
              >
                <Form.Label>Step 2(c) Multiple Jobs</Form.Label>
                <div className="validate-input d-flex flex-column">
                  <Form.Check
                    type="switch"
                    id="isJobs"
                    label=""
                    value={isJobs}
                    checked={isJobs}
                    disabled={true}
                    name="chkIsJobs"
                  />
                </div>
              </Form.Group>
              <Form.Group
                controlId="ssn-selection"
                className={"d-flex flex-row"}
              >
                <Form.Label>Step 3 Dependents Credit Adjustment</Form.Label>
                <div className="validate-input d-flex flex-column">
                  <input
                    type="text"
                    onKeyDown={Utils.AllowOnlyPositiveNumnberInput}
                    min="0"
                    className="form-control"
                    id="txtcreditAmt"
                    name="creditAmt"
                    autoComplete="creditAmt"
                    disabled={true}
                    value={creditAmt}
                  />
                </div>
              </Form.Group>

              <Form.Group
                controlId="additionalFederal-selection"
                className={"d-flex flex-row"}
              >
                <Form.Label>Step 4 (Optional) Other Adjustment:</Form.Label>
              </Form.Group>

              <Form.Group
                controlId="additionalFederal-selection"
                className={"d-flex flex-row"}
              >
                <Form.Label>(a) Other Income (not from jobs)</Form.Label>
                <div className="validate-input d-flex flex-column">
                  <input
                    type="text"
                    onKeyDown={Utils.AllowOnlyPositiveNumnberInput}
                    min="0"
                    className="form-control"
                    id="txtotherIncome"
                    name="otherIncome"
                    autoComplete="otherIncome"
                    disabled={true}
                    value={otherIncome}
                  />
                </div>
              </Form.Group>

              <Form.Group
                controlId="stateExemption-selection"
                className={"d-flex flex-row"}
              >
                <Form.Label>(b) Deductions (deductions wksht)</Form.Label>
                <div className="validate-input d-flex flex-column">
                  <input
                    type="text"
                    onKeyDown={Utils.AllowOnlyPositiveNumnberInput}
                    min="0"
                    className="form-control"
                    id="txtwksht"
                    name="wksht"
                    autoComplete="wksht"
                    disabled={true}
                    value={wksht}
                  />
                </div>
              </Form.Group>
              <Form.Group
                controlId="stateExemption-selection"
                className={
                  payrollWithholdingSettingsErrors?.extraAmt &&
                  inputPosition >= 26
                    ? "validation-error d-flex flex-row"
                    : "d-flex flex-row"
                }
              >
                <Form.Label>(c) Extra Withholding (per check)</Form.Label>
                <div className="validate-input d-flex flex-column">
                  <input
                    type="text"
                    onKeyDown={Utils.AllowOnlyPositiveNumnberInput}
                    min="0"
                    className="form-control"
                    id="txtextraAmt"
                    name="extraAmt"
                    autoComplete="extraAmt"
                    disabled={true}
                    value={extraAmt}
                  />
                </div>
              </Form.Group>
            </div>
          )}
        </>
      ),
      expanded: this.state.tableExpended,
      onExpand: this.handleOnExpand,
      showExpandColumn: true,
      expandByColumnOnly: false,
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <span className="chevronExpended">
              <img src={chevronRight} alt="chevron Right" />
            </span>
          );
        }
        return (
          <span className="chevronCollpsed">
            <img src={chevronRight} alt="chevron Right" />
          </span>
        );
      },
    };

    const sectionTableColumns: any[] = [
      {
        dataField: "sectionName",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => <div>{cell}</div>,
      },
      // {
      //   dataField: "banner",
      //   text: "",
      //   formatter: (cell: any, row: any, rowIndex: any) =>
      //  cell?<div className="notification-banners orange-bg">{cell}</div> :"",
      // },

      // {
      //   dataField: "",
      //   text: "",
      //   formatter: (cell: any, row: any, rowIndex: any) => (
      //     <Dropdown
      //       onClick={(e) => {
      //         e.stopPropagation();
      //       }}
      //       className="more-action"
      //       alignRight
      //     >
      //       <Dropdown.Toggle
      //         className="btn-outline-primary btn btn-primary btn-ghost more"
      //         id="dropdown-more"
      //       >
      //         <svg
      //           width="20"
      //           height="20"
      //           viewBox="0 0 20 20"
      //           fill="none"
      //           xmlns="http://www.w3.org/2000/svg"
      //         >
      //           <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
      //           <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
      //           <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
      //         </svg>
      //       </Dropdown.Toggle>
      //       <Dropdown.Menu>
      //         {/* <Dropdown.Item key={row?.sectionName} eventKey={"status"}>
      //           test
      //         </Dropdown.Item> */}
      //       </Dropdown.Menu>
      //     </Dropdown>
      //   ),
      // },
    ];

    const deductionsMainColumns = [
      {
        dataField: "letterCode",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div
            onClick={() =>
              hasEditPermission ? this.openDeductionEntryPanel(true, row) : {}
            }
            className="deduction-ehid-date-details d-flex flex-column"
          >
            <span className="deduction-ehid">{row?.letterCode}</span>
            <span className="deduction-date">{row?.createdDate}</span>
          </div>
        ),
      },
      {
        dataField: "status",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div
            onClick={() =>
              hasEditPermission ? this.openDeductionEntryPanel(true, row) : {}
            }
            className={`badge ${this.getStatusClassName(cell)}`}
          >
            {cell}
          </div>
        ),
      },
      {
        dataField: "amount",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div
            onClick={() =>
              hasEditPermission ? this.openDeductionEntryPanel(true, row) : {}
            }
            className="deduction-amount-details d-flex flex-column"
          >
            <span className="deduction-amount">${row?.amount}</span>
            <span className="deduction-amount-label">Amount</span>
          </div>
        ),
      },
      {
        dataField: "deductionCriteria",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div
            onClick={() =>
              hasEditPermission ? this.openDeductionEntryPanel(true, row) : {}
            }
            className="deduction-criteria-details d-flex flex-column"
          >
            <span className="deduction-criteria">{row?.deductionCriteria}</span>
            <span className="deduction-criteria-label">Criteria</span>
          </div>
        ),
      },
      {
        dataField: "deductionType",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div
            onClick={() =>
              hasEditPermission ? this.openDeductionEntryPanel(true, row) : {}
            }
            className="deduction-type-details d-flex flex-column"
          >
            <span className="deduction-type">{row?.deductionType}</span>
            <span className="deduction-type-label">Type</span>
          </div>
        ),
      },

      {
        dataField: "",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <Dropdown
            // onClick={(e) => {
            //   e.stopPropagation();
            // }}
            className="more-action"
            alignRight
          >
            <Dropdown.Toggle
              className="btn-outline-primary btn btn-primary btn-ghost more"
              id="dropdown-more"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                key={"Delete"}
                onClick={() => this.deleteDeduction(row)}
                eventKey={"Delete"}
              >
                Delete
              </Dropdown.Item>
              <Dropdown.Item
                key={row?.status}
                onClick={() =>
                  this.updateDeductionStatus(
                    row,
                    row?.status === "Active" ? "Inactive" : "Active"
                  )
                }
                eventKey={"status"}
              >
                Active/Inactive
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ),
      },
    ];

    const expandRowDeductions = {
      renderer: (row: any) => (
        <div className="rowExpended" id="laborDayDetails">
          Some text
          <div className="laborDayApprovalTable cuslaborDayApprovalTable"></div>
        </div>
      ),
      expanded: this.state.tableExpended,
      onExpand: this.handleDeductionRowExpand,
      showExpandColumn: true,
      expandByColumnOnly: false,
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <span className="chevronExpended">
              <img src={chevronRight} alt="chevron Right" />
            </span>
          );
        }
        return (
          <span className="chevronCollpsed">
            <img src={chevronRight} alt="chevron Right" />
          </span>
        );
      },
    };

    const garnishmentsMainColumns = [
      {
        dataField: "letterCode",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div
            onClick={() =>
              hasEditPermission ? this.openGarnishmentEntryPanel(true, row) : {}
            }
            className="garnishment-ehid-date-details d-flex flex-column"
          >
            <span className="garnishment-ehid">{row?.lettercode}</span>
            <span className="garnishment-date">{row?.date}</span>
          </div>
        ),
      },
      {
        dataField: "status",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div
            onClick={() =>
              hasEditPermission ? this.openGarnishmentEntryPanel(true, row) : {}
            }
            className={`badge ${this.getStatusClassName(cell)}`}
          >
            {cell}
          </div>
        ),
      },
      {
        dataField: "amount",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div
            onClick={() =>
              hasEditPermission ? this.openGarnishmentEntryPanel(true, row) : {}
            }
            className="garnishment-amount-details d-flex flex-column"
          >
            <span className="garnishment-amount">${row?.totalAmount}</span>
            <span className="garnishment-amount-label">To be paid</span>
          </div>
        ),
      },
      {
        dataField: "",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <Dropdown
            // onClick={(e) => {
            //   e.stopPropagation();
            // }}
            className="more-action"
            alignRight
          >
            <Dropdown.Toggle
              className="btn-outline-primary btn btn-primary btn-ghost more"
              id="dropdown-more"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                key={row?.status}
                onClick={() => this.deleteUserGranishment(row)}
                eventKey={"status"}
              >
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ),
      },
    ];

    const expandRowGarnishments = {
      renderer: (row: any) => (
        <div className="rowExpended" id="laborDayDetails">
          <div className="section-heading">Garnishment Details</div>
          <div className="comments-section">
            <div className="heading">Comments</div>
            <div className="description">{row?.comments}</div>
          </div>
          <div className="attachments-section">
            {row?.fileName && (
              <div>
                <div className="heading">Attachments</div>
                <div className="attached-file d-flex align-items-center">
                  <div className="file-type">
                    {row?.fileName
                      ?.toString()
                      ?.substr(row?.fileName?.toString()?.lastIndexOf(".") + 1)
                      .toUpperCase()}
                  </div>
                  <div className="file-name mr-auto">{row?.fileName}</div>
                  <div>
                    {" "}
                    <Dropdown
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      className="more-action"
                      alignRight
                    >
                      <Dropdown.Toggle
                        className="btn-outline-primary btn btn-primary btn-ghost more"
                        id="dropdown-more"
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                          <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                          <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                        </svg>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => this.downloadGranismentDocument(row)}
                          eventKey={"status"}
                        >
                          Download
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ),
      expanded: this.state.ganishmentTableExpandend,
      onExpand: this.handleOnGarnishmentRowExpand,
      showExpandColumn: true,
      expandByColumnOnly: false,
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <span className="chevronExpended">
              <img src={chevronRight} alt="chevron Right" />
            </span>
          );
        }
        return (
          <span className="chevronCollpsed">
            <img src={chevronRight} alt="chevron Right" />
          </span>
        );
      },
    };

    const sectionNamesData = _.cloneDeep(this.sectionNames).map((x) => {
      return x;
    });

    return (
      <>
        <div className="payroll-tab">
          <div className="body-section">
            <>
              <div className="heading">Enterprise Wide</div>
              <div className="form-section d-flex flex-column">
                <Form.Group
                  controlId="employee-type"
                  className={"d-flex flex-row"}
                >
                  <Form.Label>Employee Type</Form.Label>
                  <div className="validate-input d-flex flex-column">
                    <input
                      type="text"
                      className="form-control"
                      id="employeetype"
                      name="employeetype"
                      autoComplete="employeetype"
                      value={this.props?.userGeneralInfo?.employeeTypeDisplay}
                      disabled={true}
                    />
                  </div>
                </Form.Group>

                <Form.Group
                  onBlur={() => this.validationEnterpriseWideSettingOnBlur(2)}
                  controlId="dob-selection"
                  className={
                    enterpriseWideSettingErrors?.dob && inputPosition >= 2
                      ? "validation-error d-flex flex-row date-picker"
                      : "d-flex flex-row date-picker"
                  }
                >
                  <Form.Label>DOB</Form.Label>

                  <div className="validate-input d-flex flex-column">
                    <div className="calender-container">
                      <div
                        className={
                          this.state.calenderShow
                            ? "calender-display open"
                            : "calender-display"
                        }
                      >
                        <input
                          type="text"
                          onClick={this.calenderShow}
                          placeholder="MM/DD/YYYY"
                          maxLength={10}
                          onInput={(e) => {
                            let value = e.currentTarget.value;

                            value = value
                              ?.toString()
                              ?.replace(/[^0-9\/]+/gi, "");
                            this.onEnterpriseWideSettingFieldChange(
                              Utils.BuildCustomEventObject("dob", value),
                              2
                            );
                          }}
                          disabled={true}
                          onBlur={(e) => {
                            let value = e.currentTarget.value;
                            value = value
                              ?.toString()
                              ?.replace(/[^0-9\/]+/gi, "");
                            this.onEnterpriseWideSettingFieldChange(
                              Utils.BuildCustomEventObject("dob", value),
                              2
                            );
                          }}
                          value={dob}
                          className="form-control single-date-pick"
                          name="dob"
                          autoComplete="dob"
                        />
                        <svg
                          // onClick={this.calenderShow}
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z" />
                          <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z" />
                        </svg>
                      </div>
                      {this.state.calenderShow && (
                        <div className="calender">
                          <OutsideClickHandler
                            onOutsideClick={this.calenderHide}
                          >
                            <Calendar
                              calendarType={"US"}
                              onChange={(date) => {
                                const value = moment(date).format("MM/DD/YYYY");
                                this.onEnterpriseWideSettingFieldChange(
                                  Utils.BuildCustomEventObject("dob", value),
                                  2
                                );
                                this.calenderHide();
                              }}
                              value={
                                dob
                                  ? moment(dob)?.format("MM/DD/YY") ===
                                    "Invalid date"
                                    ? new Date()
                                    : new Date(dob)
                                  : new Date()
                              }
                              minDate={
                                this.props?.hireDate
                                  ? new Date(
                                      moment(this.props?.hireDate).format(
                                        "MM/DD/YYYY"
                                      )
                                    )
                                  : undefined
                              }
                              maxDate={new Date()}
                            />
                          </OutsideClickHandler>
                        </div>
                      )}
                    </div>

                    {this.getEnterpriseWideSettingFieldFieldError("dob", 2)}
                  </div>
                </Form.Group>

                <Form.Group
                  onBlur={() => this.validationEnterpriseWideSettingOnBlur(3)}
                  controlId="date-selection"
                  className={
                    enterpriseWideSettingErrors?.gender && inputPosition >= 3
                      ? "validation-error d-flex flex-row"
                      : "d-flex flex-row"
                  }
                >
                  <Form.Label>Gender</Form.Label>
                  <div className="validate-input d-flex flex-column">
                    <DropDownList
                      placeHolder={"Select"}
                      data={StaticArrays.genderList}
                      defaultValue={gender}
                      isSearchRequired={false}
                      label={"label"}
                      value={"value"}
                      disabled={true}
                      onDropDownChange={(item) => {
                        if (!item) {
                          return;
                        }
                        this.onEnterpriseWideSettingFieldChange(
                          Utils.BuildCustomEventObject("gender", item.value),
                          3
                        );
                      }}
                      selectedItem={
                        [
                          ...[{ value: "", label: "Select" }],
                          ...StaticArrays.genderList,
                        ].find((r: any) => r?.value === gender) || {}
                      }
                    />
                    {this.getEnterpriseWideSettingFieldFieldError("gender", 3)}
                  </div>
                </Form.Group>
                <Form.Group
                  controlId="ssn-selection"
                  className={
                    enterpriseWideSettingErrors?.address && inputPosition >= 4
                      ? "validation-error d-flex flex-row"
                      : "d-flex flex-row"
                  }
                >
                  <Form.Label
                    onBlur={() => this.validationEnterpriseWideSettingOnBlur(4)}
                  >
                    Address
                  </Form.Label>
                  <div className="validate-input d-flex flex-column">
                    <ReactTooltip id="addressBar" effect="solid" place="bottom">
                      {address}
                    </ReactTooltip>
                    <input
                      onClick={() => this.openAddressEntryPanel()}
                      onKeyPress={() => this.openAddressEntryPanel()}
                      type="text"
                      data-tip
                      data-for="addressBar"
                      className="form-control"
                      id="txtAddresssPayroll"
                      name="address"
                      autoComplete="txtAddresssPayroll"
                      disabled={true}
                      value={address}
                    />
                    {this.getEnterpriseWideSettingFieldFieldError("address", 4)}
                  </div>
                </Form.Group>
                <Form.Group
                  onBlur={() => this.validationEnterpriseWideSettingOnBlur(5)}
                  controlId="date-selection"
                  className={
                    enterpriseWideSettingErrors?.jobType && inputPosition >= 5
                      ? "validation-error d-flex flex-row"
                      : "d-flex flex-row"
                  }
                >
                  <Form.Label>Full/Part Time</Form.Label>
                  <div className="validate-input d-flex flex-column">
                    <DropDownList
                      placeHolder={"Select Full/Part Time"}
                      data={StaticArrays.jobTypes}
                      defaultValue={jobType}
                      isSearchRequired={false}
                      label={"label"}
                      value={"value"}
                      disabled={true}
                      onDropDownChange={(item) => {
                        if (!item) {
                          return;
                        }
                        this.onEnterpriseWideSettingFieldChange(
                          Utils.BuildCustomEventObject("jobType", item.value),
                          5
                        );
                      }}
                      selectedItem={
                        [
                          ...[{ value: "", label: "Select" }],
                          ...StaticArrays.jobTypes,
                        ].find((r: any) => r?.value === jobType) || {}
                      }
                    />
                    {this.getEnterpriseWideSettingFieldFieldError("jobType", 5)}
                  </div>
                </Form.Group>
                <Form.Group
                  onBlur={() => this.validationEnterpriseWideSettingOnBlur(7)}
                  controlId="dob-selection"
                  className={
                    enterpriseWideSettingErrors?.i9SubmittedOn &&
                    inputPosition >= 7
                      ? "validation-error d-flex flex-row date-picker"
                      : "d-flex flex-row date-picker"
                  }
                >
                  <Form.Label>
                    <div className="d-flex align-items-center">
                      <div
                        className={
                          enterpriseWideSettingErrors?.i9SubmittedOn
                            ? "pips"
                            : ""
                        }
                      >
                        <div className="pip light-red"></div>
                      </div>
                      <div>I-9 Submitted On</div>
                    </div>
                  </Form.Label>

                  <div className="validate-input d-flex flex-column">
                    <div className="calender-container">
                      <div
                        className={
                          this.state.calenderShowI9
                            ? "calender-display open"
                            : "calender-display"
                        }
                      >
                        <input
                          type="text"
                          onClick={this.calenderShowI9}
                          onFocus={this.calenderShowI9}
                          placeholder="MM/DD/YYYY"
                          maxLength={10}
                          disabled
                          onInput={(e) => {
                            let value = e.currentTarget.value;
                            value = value
                              ?.toString()
                              ?.replace(/[^0-9\/]+/gi, "");
                            this.onEnterpriseWideSettingFieldChange(
                              Utils.BuildCustomEventObject(
                                "i9SubmittedOn",
                                value
                              ),
                              7
                            );
                          }}
                          onBlur={(e) => {
                            let value = e.currentTarget.value;
                            value = value
                              ?.toString()
                              ?.replace(/[^0-9\/]+/gi, "");
                            this.onEnterpriseWideSettingFieldChange(
                              Utils.BuildCustomEventObject(
                                "i9SubmittedOn",
                                value
                              ),
                              7
                            );
                          }}
                          value={i9SubmittedOn}
                          className="form-control single-date-pick"
                          name="i9SubmittedOn"
                          autoComplete="i9SubmittedOn"
                        />
                        <svg
                          // onClick={this.calenderShow}
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z" />
                          <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z" />
                        </svg>
                      </div>
                      {this.state.calenderShowI9 && (
                        <div className="calender">
                          <OutsideClickHandler
                            onOutsideClick={this.calenderHideI9}
                          >
                            <Calendar
                              calendarType={"US"}
                              onChange={(date) => {
                                const value = moment(date).format("MM/DD/YYYY");
                                this.onEnterpriseWideSettingFieldChange(
                                  Utils.BuildCustomEventObject(
                                    "i9SubmittedOn",
                                    value
                                  ),
                                  7
                                );
                                this.calenderHideI9();
                              }}
                              value={
                                i9SubmittedOn
                                  ? moment(i9SubmittedOn)?.format(
                                      "MM/DD/YYYY"
                                    ) === "Invalid date"
                                    ? ""
                                    : new Date(i9SubmittedOn)
                                  : ""
                              }
                              // minDate={
                              //   this.props?.hireDate
                              //     ? new Date(
                              //       moment(this.props?.hireDate).format(
                              //         "MM/DD/YYYY"
                              //       )
                              //     )
                              //     : undefined
                              // }
                              // maxDate={new Date()}
                            />
                          </OutsideClickHandler>
                        </div>
                      )}
                    </div>

                    {this.getEnterpriseWideSettingFieldFieldError(
                      "i9SubmittedOn",
                      7
                    )}
                  </div>
                </Form.Group>
                <Form.Group
                  onBlur={() => this.validationEnterpriseWideSettingOnBlur(6)}
                  controlId="date-selection"
                  className={
                    enterpriseWideSettingErrors?.federalFiling &&
                    inputPosition >= 6
                      ? "validation-error d-flex flex-row"
                      : "d-flex flex-row"
                  }
                >
                  <Form.Label>Federal Filing Status</Form.Label>
                  <div className="validate-input d-flex flex-column">
                    <DropDownList
                      placeHolder={"Select Federal Filing Status"}
                      data={StaticArrays.fillingStatusList}
                      defaultValue={federalFiling}
                      isSearchRequired={false}
                      label={"label"}
                      value={"value"}
                      disabled={true}
                      onDropDownChange={(item) => {
                        if (!item) {
                          return;
                        }
                        this.onEnterpriseWideSettingFieldChange(
                          Utils.BuildCustomEventObject(
                            "federalFiling",
                            item.value
                          ),
                          6
                        );
                      }}
                      selectedItem={
                        [
                          ...[
                            {
                              value: "",
                              label: "Select",
                            },
                          ],
                          ...StaticArrays.fillingStatusList,
                        ].find((r: any) => r?.value === federalFiling) || {}
                      }
                    />
                    {this.getEnterpriseWideSettingFieldFieldError(
                      "federalFiling",
                      6
                    )}
                  </div>
                </Form.Group>

                <Form.Group
                  onBlur={() => this.validationEnterpriseWideSettingOnBlur(9)}
                  controlId="dob-selection"
                  className={
                    enterpriseWideSettingErrors?.w4Date && inputPosition >= 9
                      ? "validation-error d-flex flex-row date-picker"
                      : "d-flex flex-row date-picker"
                  }
                >
                  <Form.Label>
                    <div className="d-flex align-items-center">
                      <div
                        className={
                          enterpriseWideSettingErrors?.w4Date ? "pips" : ""
                        }
                      >
                        <div className="pip light-red"></div>
                      </div>
                      <div>W-4 Submitted On</div>
                    </div>
                  </Form.Label>

                  <div className="validate-input d-flex flex-column">
                    <div className="calender-container">
                      <div
                        className={
                          this.state.calenderShowW4
                            ? "calender-display open"
                            : "calender-display"
                        }
                      >
                        <input
                          type="text"
                          onClick={this.calenderShowW4}
                          onFocus={this.calenderShowW4}
                          placeholder="MM/DD/YYYY"
                          maxLength={10}
                          disabled
                          onInput={(e) => {
                            let value = e.currentTarget.value;

                            value = value
                              ?.toString()
                              ?.replace(/[^0-9\/]+/gi, "");
                            this.onEnterpriseWideSettingFieldChange(
                              Utils.BuildCustomEventObject("w4Date", value),
                              9
                            );
                          }}
                          onBlur={(e) => {
                            let value = e.currentTarget.value;
                            value = value
                              ?.toString()
                              ?.replace(/[^0-9\/]+/gi, "");
                            this.onEnterpriseWideSettingFieldChange(
                              Utils.BuildCustomEventObject("w4Date", value),
                              9
                            );
                          }}
                          value={w4Date}
                          className="form-control single-date-pick"
                          name="w4Date"
                          autoComplete="w4Date"
                        />
                        <svg
                          // onClick={this.calenderShow}
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z" />
                          <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z" />
                        </svg>
                      </div>
                      {this.state.calenderShowW4 && (
                        <div className="calender">
                          <OutsideClickHandler
                            onOutsideClick={this.calenderHideW4}
                          >
                            <Calendar
                              calendarType={"US"}
                              onChange={(date) => {
                                const value = moment(date).format("MM/DD/YYYY");
                                this.onEnterpriseWideSettingFieldChange(
                                  Utils.BuildCustomEventObject("w4Date", value),
                                  9
                                );
                                this.calenderHideW4();
                              }}
                              value={
                                w4Date
                                  ? moment(w4Date)?.format("MM/DD/YYYY") ===
                                    "Invalid date"
                                    ? ""
                                    : new Date(w4Date)
                                  : ""
                              }
                              // minDate={
                              //   this.props?.hireDate
                              //     ? new Date(
                              //       moment(this.props?.hireDate).format(
                              //         "MM/DD/YYYY"
                              //       )
                              //     )
                              //     : undefined
                              // }
                              // maxDate={new Date()}
                            />
                          </OutsideClickHandler>
                        </div>
                      )}
                    </div>

                    {this.getEnterpriseWideSettingFieldFieldError("w4Date", 9)}
                  </div>
                </Form.Group>
              </div>

              {/* <div className="separator-line"></div> */}

              <div className="enterprise-wise-payroll-table">
                <div className="switch-heading d-flex align-items-center">
                  <div className="heading">Deposit Accounts</div>
                </div>
                <BootstrapTable
                  keyField="id"
                  data={
                    // isAllowDeposit
                    sectionNamesData
                    //  : sectionNamesData.filter((x) => x.id !== 1)
                  }
                  columns={sectionTableColumns}
                  expandRow={expandRow}
                  rowClasses={rowClasses}
                  hover
                />
              </div>
            </>
          </div>
        </div>
        {isFormValid &&
          !isEnterpriseWideSettingNotChanged &&
          (hasAddPermission || hasEditPermission) && (
            <div className="fixed-action ml-auto">
              <div className="d-flex align-content-center flex-wrap">
                <div className="mr-auto message">
                  <span>You have unsaved changes</span>
                </div>
                <button
                  disabled={isDataSubmitting}
                  type="button"
                  className="btn btn-primary btn-discard"
                  onClick={this.handleDiscard}
                >
                  Discard
                </button>

                <button
                  disabled={isDataSubmitting}
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.saveEnterpriseWiseSetting()}
                >
                  Save
                </button>
              </div>
            </div>
          )}
      </>
    );
  }
}
