import React from 'react';
import { Modal, Form, Spinner, Button } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import _ from "lodash";
import { confirmAlert } from "react-confirm-alert";
import { resolve, reject } from "q";
import { RolesforTreeView } from "../../../Common/Services/RolePermissionSetting";
import { toast } from "react-toastify";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "../RolePermission/LowSecurity.scss";
import { Utils } from '../../../Common/Utilis';

export class LowSecurityRolesModal extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            totalHotelCount: "0",
            totalEntityCount: "0",
            selectedLowRole: [],
            lowRoleOptions: [],
            loader: false,
            loaderSubmit: false,
            disableSave: true,
            defaultList: [],
        }
    }

    componentDidMount(): void {
        let { lowRoleOptions } = this.state;
        lowRoleOptions = _.sortBy(this.props?.lowRoleOptions, 'roleName')
        let LowSecurityList = lowRoleOptions.filter((item) => (item.isLowSecurityRole === "No"));
        this.setState({ lowRoleOptions: LowSecurityList }, () => {
            this.GetPasswordAssistanceRole();
        });
    }

    GetPasswordAssistanceRole = () => {
        let { lowRoleOptions } = this.state;
        RolesforTreeView.GetPasswordAssistanceRole()
            .then(async (result: any | null) => {
                if (result != null) {
                    let roleData = result.result[0]?.result?.split(',');
                    let selectedList = [] as any;
                    let defList = [] as any;
                    let request = {} as any;
                    if (roleData[0] !== "0") {
                        for (let i = 0; i < roleData.length; i++) {
                            const selectedName = lowRoleOptions.filter((item) => item.roleID === roleData[i])
                            let lst = selectedName[0];
                            lst.label = selectedName[0].roleName;
                            selectedList.push(lst)
                            defList.push(Number(lst.roleID));
                        }
                        selectedList = _.sortBy(selectedList, 'label');
                        this.setState({ selectedLowRole: selectedList, defaultList: defList }, () => {
                            request.RoleID = result.result[0]?.result;
                        });
                    } else {
                        this.setState({ selectedLowRole: [], defaultList: [] }, () => {
                            request.RoleID = 0;
                        })
                    }
                    this.lowRoleSelectorChange(this.state.selectedLowRole);
                    this.getLowSecurityCountHCovered(request);
                }
                resolve();
            })
            .catch((error) => {
                reject();
            });
    }

    handleSaveAlert = () => {
        confirmAlert({
            title: "Alert",
            message: 'Low Security Roles are now enabled.  To turn this on for a certain role, enable the “Low Security Role” toggle on that role’s page.',
            buttons: [{ label: "OK", className: "confirmAlert btnSuccess", onClick: () => { this.props?.hide(); reject() } }],
            closeOnEscape: false,
            closeOnClickOutside: false,
        });
    }

    handleDisable = () => {
        confirmAlert({
            title: "Are you sure you want to disable low security user roles?",
            message: 'Disabling this will remove password assistance for each role that is currently marked as "Low Security".',
            buttons: [
                { label: "Confirm", className: "confirmAlert btnSuccess", onClick: () => { this.handleSaveLowSecurity(); reject() } },
                { label: "Cancel", className: "confirmAlert btnDiscard", onClick: () => reject() },
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
        });
    }

    handleSaveLowSecurity = () => {
        this.setState({ loader: true });
        RolesforTreeView.DisableLowSecurityRole()
            .then(async (result: any | null) => {
                if (result != null) {
                    if (result === "success") {
                        toast.success("Low Security Roles disabled successfully.", { position: toast.POSITION.BOTTOM_RIGHT, containerId: "RolePermissionRightPanel" });
                        this.props.getRoleData();
                        let fieldData = this.props?.fieldData
                        fieldData.isLowSecurityRole = "No";
                        setTimeout(() => {
                            if (this.props?.leftRowSelected) {
                                this.props.refreshleftpanelforNewRole();
                                this.props.updateState(fieldData);
                            }
                            this.setState({ loader: false });
                            this.props.hide();
                        }, 500);
                    }
                }
                resolve();
            })
            .catch((error) => {
                this.setState({ loader: false });
                reject();
            });
    }

    handleSave = () => {
        let { selectedLowRole } = this.state;
        let RoleID: any[] = [];
        selectedLowRole.forEach(item => RoleID.push(item.roleID));
        this.updateRoles(RoleID);
    }

    updateRoles = (RoleID: any) => {
        this.setState({ loaderSubmit: true });
        let request: any = {};
        request.RoleIDs = RoleID.toString();
        RolesforTreeView.SavePasswordAssistanceRole(request)
            .then(async (result: any | null) => {
                if (result != null) {
                    if (result.message === "Success") {
                        if (this.props?.callType === "Enable") {
                            this.handleSaveAlert();
                        } else {
                            toast.success("Password Assistance Roles updated successfully.",
                                { position: toast.POSITION.BOTTOM_RIGHT, containerId: "RolePermissionRightPanel" });
                            this.props?.hide();
                        }
                        this.props.getRoleData();
                        setTimeout(() => {
                            if (this.props?.leftRowSelected) {
                                this.props.refreshleftpanelforNewRole();
                                this.props.updateState(this.props?.fieldData);
                            }
                            this.setState({ loaderSubmit: false });
                        }, 500);
                    } else if (result.message === "LowSecurityRole") {
                        Utils.toastError("The user should not be able to select a Password Assistance role that is lower in Hierarchy than any Low Security role to ensure that all users can receive password assistance.",
                            { position: toast.POSITION.BOTTOM_RIGHT, containerId: "RolePermissionRightPanel" });
                        this.setState({ loaderSubmit: false });
                    }
                }
                resolve();
            })
            .catch((error) => {
                this.setState({ loaderSubmit: false });
                reject();
            });
    };

    getLowSecurityCountHCovered = (request: any) => {
        let { totalHotelCount, totalEntityCount, disableSave } = this.state;
        RolesforTreeView.GetCountHCovered(request)
            .then(async (result: any | null) => {
                if (result != null) {
                    let count = result.result[0].result;
                    totalHotelCount = count.split(':')[1];
                    totalEntityCount = count.split(':')[0];
                    disableSave = (Number(totalHotelCount) !== Number(totalEntityCount));
                    this.setState({ totalHotelCount, totalEntityCount, disableSave });
                }
                resolve();
            })
            .catch((error) => {
                Utils.toastError(`Error: " ${error}`, { position: toast.POSITION.BOTTOM_RIGHT, containerId: "RolePermissionRightPanel" });
                reject();
            });
    }

    lowRoleSelectorChange(e: any) {
        this.setState({ selectedLowRole: e });
        let request = {} as any;
        let lowRoles = "";
        e.map((item, index) => (lowRoles += item.roleID + ","));
        let roleIDs = e.length > 0 ? String(lowRoles.replace(/,\s*$/, '')) : '0';
        request.RoleID = roleIDs;
        this.getLowSecurityCountHCovered(request);
    }

    render() {
        let { totalHotelCount, totalEntityCount, loader, disableSave, loaderSubmit, lowRoleOptions, selectedLowRole, defaultList } = this.state
        let isEditProps = this.props?.callType === "Edit";
        let isDiffFromList: boolean = false;
        let roleids: any = [];
        selectedLowRole.forEach(item => roleids.push(Number(item.roleID)));
        isDiffFromList = (JSON.stringify(_.sortBy(roleids)) === JSON.stringify(_.sortBy(defaultList)));
        return (
            <>
                <Modal className="otp-modal customModalShaddow"
                    show={this.props?.show}
                    backdrop="static"
                    keyboard={false}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    onHide={() => this.props.hide()} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Password Assistance Roles</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <React.Fragment>
                            <div className="pt-2 pb-3">
                                <strong>{totalEntityCount} of {totalHotelCount} Entities Covered by Password Assistance</strong>
                            </div>
                            <div className="row">
                                <Form.Label className="col-xl-4 col-sm-4">Password Assistance Roles</Form.Label>
                                <div className="col-xl-8 col-sm-8">
                                    <div className="LowSecuritySection">
                                        <div className="LowSecuritycontrolls">
                                            <div className="LowSecuritytypeahead">
                                                <Form>
                                                    <Typeahead
                                                        flip={true}
                                                        id="roles"
                                                        multiple={true}
                                                        inputProps={{ className: "lowSecurityMultiSelect" }}
                                                        onChange={(event) => { this.lowRoleSelectorChange(event) }}
                                                        options={lowRoleOptions}
                                                        placeholder="Choose..."
                                                        selected={selectedLowRole}
                                                    />
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    </Modal.Body>
                    <Modal.Footer>
                        {(isEditProps/* || this.props?.isLowSecurityRole*/) && (
                            <div className="mr-auto">
                                <Button className="btn-discard" onClick={() => this.handleDisable()}>{loader && (<Spinner size="sm" animation="border" />)}{" "}Disable Low Security Roles</Button>
                            </div>
                        )}
                        <Button className="btn-discard" onClick={() => this.props.hide()}>Cancel</Button>
                        <Button disabled={isDiffFromList ? true : disableSave || loaderSubmit} onClick={this.handleSave.bind(this)}>{loaderSubmit && (<Spinner size="sm" animation="border" />)}{" "}
                            {isEditProps ? "Save" : "Enable"}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}