import * as React from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import OutsideClickHandler from "react-outside-click-handler";

export class MultiSelectReportTags extends React.Component<any, any> {
  private btnSection: any;
  private searchSection: any;
  constructor(props: any) {
    super(props);
    this.btnSection = React.createRef();
    this.searchSection = React.createRef();
    this.state = {
      selectedItems: [],
      filterData: [],
      filterDataCopy: [],
      opened: false,
      itemList: props.itemList,
      isSelectAll: props.isSelectAll,
      filtertitle: props.filtertitle,
      isSelect: true,
      inputValue: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleClearAll = this.handleClearAll.bind(this);
  }
  componentDidMount() {
    // 

    // let selectedItems = [...this.state.selectedItems];
    const activeChip = this.state.itemList.filter(
      (itemList: { isChecked: boolean }) => itemList.isChecked === true
    );
    this.setState({
      selectedItems: activeChip,
      filterData: this.state.itemList,
    });
    // this.props.updateMultiSelectItem(activeChip);
  }
  /**For remove COA(s) list **/
  handleRemoveSpecificChip = (idx: number) => () => {
    this.props.dataChange();
    const itemList = [...this.state.itemList];
    const selectedItems = [...this.state.selectedItems];
    const removeSelect = this.getIndex(selectedItems[idx].value, "removeChip");
    itemList[removeSelect].isChecked = false;
    selectedItems.splice(idx, 1);
    this.setState({ selectedItems });
    this.setState({ itemList });
    const coaSelectedItem = this.state.itemList.filter(
      (itemList: { isChecked: boolean }) => itemList.isChecked === true
    );
    this.props.updateMultiSelectItem(coaSelectedItem);
  };

  /************checkbox check unchek********/
  handleCheckCheckbox = (idx: any) => (event: any) => {
    this.props.dataChange();
    const itemList = [...this.state.itemList];
    const selectedItems = [...this.state.selectedItems];
    
    this.setState({
      filterDataCopy: this.state.itemList,
    });
    const index = itemList.findIndex((xvalue) => xvalue.value === idx);
    itemList[index].isChecked = event.target.checked;

    idx = index;
    this.setState({ itemList });

    //Item push and remove
    const item = {
      value: itemList[idx].value,
      isChecked: event.target.checked,
    };
    if (event.target.checked === true) {
      this.setState({
        selectedItems: [...this.state.selectedItems, item],
      });
    } else {
      const removeIndex = this.getIndex(itemList[idx].value, "selectbox");
      selectedItems.splice(removeIndex, 1);
      this.setState({ selectedItems });
    }
    const coaSelectedItem = this.state.itemList.filter(
      (itemList: { isChecked: boolean }) => itemList.isChecked === true
    );
    this.props.updateMultiSelectItem(coaSelectedItem);
    if(coaSelectedItem.length === itemList.length){
      this.setState({isSelect :false});
    }
  };
  //Get array Index
  getIndex(val: any, type: string) {
    if (type === "removeChip") {
      let itemList = [...this.state.itemList];
      return itemList.findIndex((obj) => obj.value === val);
    } else {
      let selectedItems = [...this.state.selectedItems];
      return selectedItems.findIndex((obj) => obj.value === val);
    }
  }
  handleChange(e: { target: { value: string } }) {
    const updatedList = this.state.itemList.filter(
      (item: { description: string }) => {
        return (
          item.description.toLowerCase().indexOf(e.target.value.toLowerCase()) >
          -1
        );
      }
    );

    this.setState({ filterData: updatedList, inputValue: e.target.value });
  }

  updateSavedItem(data: any) {
    let allCoaList = [...this.state.itemList];
    allCoaList.forEach((element) => {
      element.isChecked = false;
    });
    this.setState({ itemList: allCoaList });
    data.forEach((element: { value: any }) => {
      this.state.itemList.filter(
        (itemList: { value: any }) => itemList.value === element.value
      )[0].isChecked = true;
    });
    this.setState({ selectedItems: data });
  }
  handleClick() {
    // if (!this.state.opened) {
    //   document.addEventListener('click', this.handleOutsideClick, false);
    // } else {
    //   document.removeEventListener('click', this.handleOutsideClick, false);
    // }

    this.setState((prevState) => ({
      opened: !prevState.opened,
    }));

    this.setState({
      filterData: this.state.itemList,
    });
    this.setState({ inputValue: "" });
  }

  handleOutsideClick(e) {
    this.setState((prevState) => ({
      opened: false,
    }));
    this.setState({ inputValue: "" });
  }

  showClearAll(){
    this.setState({isSelect:false});
  }

  handleSelectAll(e: any) {
    this.props.dataChange();
    let allItemList = [...this.state.itemList];
    allItemList.forEach((element) => {
      element.isChecked = true;
    });
    this.setState({ itemList: allItemList, isSelect: false });
    this.setState({ selectedItems: allItemList });
    this.props.updateMultiSelectItem(allItemList);
  }

  handleClearAll(e: any) {
    this.props.dataChange();
    let allItemList = [...this.state.itemList];
    allItemList.forEach((element) => {
      element.isChecked = false;
    });
    this.setState({ itemList: allItemList, isSelect: true });
    this.setState({ selectedItems: [] });
    this.props.updateMultiSelectItem([]);
  }

  openDropdown = () => {
    this.setState({
      filterData: this.state.itemList,
      opened: true,
      inputValue: ""
    });
  };

  render() {
    return (
      <OutsideClickHandler onOutsideClick={this.handleOutsideClick}>
        <div
          className={
            this.state.opened
              ? "multiSelectSearch ReportTags opened"
              : "multiSelectSearch ReportTags"
          }
        >
          <div className="fieldArea" onClick={this.openDropdown}>
            {this.state.selectedItems.length === 0 ? (
              <span className="areaPlaceholder placeholder">
                Report Tags
              </span>
            ) : (
            <div className="chip">
              {this.state.selectedItems.length === 1 && 
              this.state.selectedItems.map((item: any, idx: any) => (
                <OverlayTrigger
                  key={"overlay1-" + idx}
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id={"coa" + idx}>
                      {
                        this.state.itemList.filter(
                          (row) => row.value === item.value
                        )[0].description
                      }
                    </Tooltip>
                  }
                >
                  <div className="chipSelect" id={idx} key={idx}>
                    <div className="chipVal">{item.value}</div>
                    <button
                      id="btnremove"
                      type="button"
                      className="cross"
                      onClick={this.handleRemoveSpecificChip(idx)}
                    >
                      <svg
                        width="8"
                        height="8"
                        viewBox="0 0 8 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.79465 0.229492L3.96598 3.05749L1.13798 0.229492L0.195312 1.17216L3.02331 4.00016L0.195312 6.82816L1.13798 7.77083L3.96598 4.94282L6.79465 7.77083L7.73731 6.82816L4.90931 4.00016L7.73731 1.17216L6.79465 0.229492Z"
                          fill="#6A6E73"
                        />
                      </svg>
                    </button>
                  </div>
                
                </OverlayTrigger>
              ))}
              {this.state.selectedItems.length >1 &&(
                <div className="chipSelect">
                      <div className="chipVal">
                        {this.state.selectedItems.length} Tags selected
                </div>                
                </div>
              )}
            </div>
            )}
            
            <div
              className="coaSearch"
              ref={(searchSection) => {
                this.searchSection = searchSection;
              }}
            >
              <div className="dropDownPanel">
                <div className="SearchInput">
                  <input
                    key={"text-Filter"}
                    type="text"
                    className="form-control"
                    onChange={this.handleChange}
                    placeholder={this.state.filtertitle}
                    value={this.state.inputValue}
                  />
                </div>
                <ul>
                  {this.state.filterData.map((item: any, idx: any) => (
                    // <OverlayTrigger
                    //   key={"overlay-" + idx}
                    //   placement="bottom"
                    //   delay={{ show: 250, hide: 400 }}
                    //   overlay={
                    //     <Tooltip id={"coa" + idx}>
                    //       {
                    //         this.state.itemList.filter(
                    //           (row) => row.value === item.value
                    //         )[0].description
                    //       }
                    //     </Tooltip>
                    //   }
                    // >
                      <li id={idx} key={idx}>
                        <Form.Check
                          //custom
                          type="checkbox"
                          key={"custom-" + idx}
                          id={"custom-" + idx}
                          label={item.description}
                          checked={item.isChecked}
                          onChange={this.handleCheckCheckbox(item.value)}
                        />
                      </li>
                    // </OverlayTrigger>
                  ))}
                </ul>
                {this.state.isSelectAll && (
                  <div className="footer">
                    {this.state.isSelect && (
                      <button
                        id="selectAll"
                        type="button"
                        className="btn wht-bg txt-green"
                        onClick={this.handleSelectAll}
                      >
                        Select All
                      </button>
                    )}
                    {!this.state.isSelect && (
                      <button
                        id="ClearAll"
                        type="button"
                        className="btn wht-bg txt-green"
                        onClick={this.handleClearAll}
                      >
                        Clear All
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
           
          </div>
        </div>
      </OutsideClickHandler>
    );
  }
}
